<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" :class="this.disabledJobTitle && this.disabledJobTitle === true ? 'modal-third' : '' " data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                    <h5 class="modal-title py-2" v-text="this.title ? this.title : $t('message.modalConfirm.title')"></h5>
                    <button type="button" class="close pt-3" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        <h6 v-text="this.content ? this.content : $t('message.modalConfirm.content')"></h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()"><i class="fa fa-check mr-1"></i>{{ $t('message.modalConfirm.button.confirm') }}</button>
                        <button class="btn btn-secondary text-white" @click="skip()"><i class="fa fa-ban mr-1"></i>{{ $t('message.modalConfirm.button.skip') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
export default {
    name: "ModalConfirmRemoveHistoryPayment",
    data(){
        return {
        }
    },
    props: ['message', 'title', 'content','disabledJobTitle'],
    methods: {
        show() {            
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            this.$emit('skip', false);
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        confirm(){
            this.$emit('confirm', true);
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        hide(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

