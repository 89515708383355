<template>
 <div ref="modal" class="modal fade mx-0" tabindex="-1" role="dialog" id="modalConnectTransporter" data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 mx-2 border-none">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-2 my-4 d-flex">
                <div class="col-lg-6">
                    <img src="../../../public/img/ahamove.png" class="w-100">
                </div>
                <div class="col-lg-6">
                    <h3 class="text-primary text-bold">
                        {{$t('message.common.loginAhamove')}}
                    </h3>
                    <div class="row m-0 p-0 my-4 border-bottom">
                        <div class="col-3 text-bold d-flex align-items-center">
                            {{$t('message.common.vietnam')}}
                        </div> 
                        <div class="col-9 border-left">
                            <input type="text" v-model="mobile" id="phone" name="phone" class="border-none w-100 py-2" 
                            :placeholder="$t('message.supplierIndex.modal.enterPhone')" autocomplete="false" autofocus>
                        </div>
                    </div>
                    <div class="row m-0 p-0 my-4 border-bottom"> 
                        <div class="col-3 text-bold d-flex align-items-center">
                            {{$t('message.common.apiKey')}}
                        </div>
                        <div class="col-9">
                            <input type="text" id="api_key" v-model="apiKey" name="phone"  class="border-none w-100 py-2" 
                            :placeholder="$t('message.common.enterApiKey')" autocomplete="false">
                        </div>
                    </div>
                    <div class="row m-0 p-0 my-4"> 
                        <div class="col-12 text-center">
                            <button class="btn btn-save" @click="submit()">
                                {{$t('message.LoginScreen.submit')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { $post } from '@/lib/utils';
import toastr from 'toastr';
import store from "../../store.ts";

export default {
    name: "ModalConnectTransporter",
    data(){
        return {
            mobile: null,
            apiKey: null
        }
    },
    props: [],
    methods: {
        show() {
            window.jQuery(this.$refs.modal).modal('show');
        },
        closed() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async submit () {
            const result = await $post('/v1/ahamove/login-acc', {
                mobile: this.mobile,
                apiKey: this.apiKey
            });
            if (result.code == 422) {
                this.isMessageError(result.messages.errors);
            }else {
                store.commit('setAhamoveAccount', result.result.token);
                toastr.success(this.$t('message.ModalLoginZalo.message.login_success'));
                this.$emit('reload');
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        isMessageError(errMss){ 

                if(errMss['mobile']) {
                    toastr.error(errMss['mobile']);                
                    return;
                }
                if(errMss['provinceId']) {
                    toastr.error(errMss['apiKey']);
                    return;
                }
              
            },
    },
    beforeUnmount() {
        this.closed();
    },
    unmounted() {
        this.closed();
    },
    
}
</script>

