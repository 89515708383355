<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
           <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
               <div class="modal-content">
                   <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                       <div class="d-flex">
                           <h5 style="fotn" class="modal-title py-2" v-if="this.isEdit">Cập nhật người dùng</h5>
                           <h5 style="fotn" class="modal-title py-2" v-else-if="!this.isEdit && !this.isCopy">Thêm mới người dùng</h5>
                           <h5 style="fotn" class="modal-title py-2" v-else>Sao chép người dùng</h5>
                       </div>
                       <button type="button" class="close" @click="closeModal">
                           <span aria-hidden="true">&times;</span>
                       </button>
                   </div>
                   <div class="modal-body py-0 mx-4 my-4 px-0">
                      <TabInfo ref="tab"></TabInfo>
                   </div>
                   <div class="modal-footer">
                       <button class="btn btn-primary" @click="update(this.entry.id)" :disabled="requestData" v-if="this.isEdit">
                           <i class="fa fa-save"></i>
                           Lưu lại 
                       </button>
                       <button class="btn btn-primary"  @click="save" :disabled="requestData" v-else>
                           <i class="fa fa-save"></i>
                           Lưu lại 
                       </button>
                       <button type="button" class="btn btn-secondary text-white  mr-2"
                         @click="closeModal">
                         <i class="fa fa-ban mr-1"></i>
                         Bỏ qua
                       </button>
                   </div>
               </div>
           </div>
       </div>
       <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
   </template>
   <script>
   import TabInfo from "@/views/users/tabs/TabInfo";
   
   import emitter from "@/lib/emitter";
   import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
   import store from "@/store";
   import ModalConfirm from "@/components/ModalConfirm.vue";
   import toastr from 'toastr';
   
   export default {
       name: "ModalAddInfoSuppliers",
       components: { TabInfo, ModalConfirm},
       props: {
           openModal: {
               type: Boolean,
               default: false,
           },
       },
       methods: {
         clearInput(){
           this.entry = {
                name: '',
                username: '',
                password: '',
                same_password: '',
                role_id: '',
                branch_id: null,
                phone: '',
                email: '',
                note: ''
             };
           emitter.emit('removeSupplierData');
         },

         isMessageError(errMss){ 
                if(errMss['entry.name']) {
                    toastr.error(errMss['entry.name']);                
                    return;
                }
                if(errMss['entry.username']) {
                    toastr.error(errMss['entry.username']);                
                    return;
                }

                if(errMss['entry.password']) {
                    toastr.error(errMss['entry.password']);                
                    return;
                }
                if(errMss['entry.same_password']) {
                    toastr.error(errMss['entry.same_password']);                
                    return;
                }
                if(errMss['entry.role_id']) {
                    toastr.error(errMss['entry.role_id']);                
                    return;
                }
                if(errMss['entry.branch_id']) {
                    toastr.error(errMss['entry.branch_id']);                
                    return;
                }
                if(errMss['entry.phone']) {
                    toastr.error(errMss['entry.phone']);                
                    return;
                }
                if(errMss['entry.email']) {
                    toastr.error(errMss['entry.email']);                
                    return;
                }
                if(errMss['entry.note']) {
                    toastr.error(errMss['entry.note']);                
                    return;
                }
        },
         async save() {
           this.requestData = true;
           const tab = this.$refs.tab
           let param={
             entry: tab.entry,
             currentBranchID:tab.currentBranchID,
           }
           const res = await $post('/v1/users/store', param);
           if (res.code == 422) {
             this.requestData = false;
             scrollToElement('.error-label');
             this.isMessageError(res.messages.errors);
           }
           
           if(res.status.code == 200){
             toastr.success('Thêm mới người dùng thành công');
             this.$emit('updated', '')
             this.hide();
           }
         },
         async update (id) {
            const tab = this.$refs.tab
            let param={
                entry: tab.entry,
                currentBranchID:tab.currentBranchID,
            }
            const result = await $post(`/v1/users/update/${id}`, param);
            if (result.code == 422) {
                this.requestData = false;
                scrollToElement('.error-label');
                this.isMessageError(result.messages.errors);
            }
            if (result.status.code == 200) {
                toastr.success('Cập nhật người dùng thành công');
                this.$emit('updated', '')
                this.hide();
            }
         },
         async show(id, type = '') {
           if (id && type == ''){
             this.isEdit = true;
             this.id = id;
             await this.load(id);
           }else if (id && type == 'copy'){
              this.isEdit = false;
              this.isCopy = true; 
              await this.load(id, 'copy');
           }
           else {
             this.isEdit=false;
           }
           this.default = {...this.entry};
           this.$refs.tab.entry = this.entry;
           window.jQuery(this.$refs.modal).modal('show');
         },
         hide(){
           this.requestData = false;
           this.$emit('inputData','1');
           this.clearInput();
           emitter.emit('clearOptionsQselect');
           window.jQuery(this.$refs.modal).modal('hide');
         },
         async load(id, type = '') {
           const res = await $get(`/v1/users/show/${id}`);
           if (res.code === 404) {
               location.replace('/404');
               return;
           }
           this.entry = res.result;
            if (type === 'copy') {
              this.entry.name = this.entry.name + '_1';
              this.entry.username = this.entry.username + '_1';    
            }       
         },
         async loadDataLocation(){
             const res = await $get('/v1/locations/provinces', {
                 provinceId: this.entry.provinceId,
                 districtId: this.entry.districtId
             });
             this.$refs.tab.provinces = res.data.provinces;
             this.$refs.tab.districts = res.data.districts;
             this.$refs.tab.wards = res.data.wards;
         },
         closeModal(){
           const tab = this.$refs.tab;
           const isChange = isChangeData(tab.entry, this.default);
           if(isChange == true){
               this.hide();
           }else{
               this.titleConfirm = 'Xác nhận';
               if(this.id === null){
                 this.contentConfirm = 'Bạn có muốn hủy thêm người dùng?';
               }else{
                 this.contentConfirm = 'Bạn có muốn hủy cập nhật người dùng?';
               }
               this.$refs.ModalConfirm.show();
           }
         },
       },
       data () {
           return {
             isEdit:false,
             isCopy: false,
             id: null,
             entry:{
                name: '',
                username: '',
                password: '',
                same_password: '',
                role_id: '',
                branch_id: null,
                phone: '',
                email: '',
                note: ''
             },
             supplierGroups: [],
             default: {},
             contentConfirm: '',
             titleConfirm: '',
             requestData: false,
           }
       }
   }
   </script>
   <style>
   .action-bar {
       display: none !important;
   }
   </style>