<template>
    <div class="form-group mb-0" :class="cardTransaction ? 'border-bottom-1' : ''">
        <div data-toggle="dropdown" class="d-flex align-items-center custom__show-time mb-0"> 
            <input ref="input" :placeholder="placeholder ? placeholder : null" v-on:blur="onInput($event)" v-model="time" class="w-100">
        </div>
        <ul class="dropdown-menu format__list-time">
            <template  v-for="(n, i) in 24" :key="i">
                <li style="padding-left: 10px;" @click="setTimeStart(('0' + i ).slice(-2) + ':00')">{{ ('0' + i ).slice(-2) + ':00' }}</li> 
                <li style="padding-left: 10px;" @click="setTimeStart(('0' + i ).slice(-2) + ':30')">{{ ('0' + i ).slice(-2) + ':30' }}</li>
            </template>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
import {$alert} from "@/lib/utils";

export default {
    name: "InputDatePicker",
    props: ['placeholder', 'default', 'cardTransaction'],
    data() {
        return {
            time: null,
        }
    },
    methods: {
        onInput(){
            var isValid = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(this.time)
            if (isValid === false) {
                this.time = moment().format('HH:mm');
                $alert({code: 500, message: this.$t('message.common.inValidFormatTime')});
            } 
            this.$emit('input', this.time);
        },
        setTimeStart (time) {
            this.time = time;
            this.$emit('input', this.time);
        },
    },
    watch: {
        default(newTime) {
            var isValid = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(newTime)
            if (isValid !== false) {
                this.time = this.default;
            }   
        }
    },
    mounted: function () {
        if (this.default) {
            this.time = this.default;
        }
    },

}
</script>

<style scoped>
#icoTimes {
    position: absolute;
    top:15%;
    right:23%;
}
.custom__show-time {
    height: 32px;
    margin-bottom: 15px;
}
.custom__show-time input, .custom__show-time input:focus{
    outline:none;
    border:none;
    color: #495057;
}
</style>
