<template>
 <div ref="modal" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h6 class="modal-title py-2">
                        {{ $t('message.common.addSubmitter') }}
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <!-- Người nộp -->
                    <div class="row">
                        <div class="col-3 divLabel">
                            <span>
                                {{ $t('message.CashBookIndex.payment_by') }} 
                            </span>
                        </div>
                        <div class="col-9">
                            <input type="text" v-model="customer.name" class="form-control inputVoucher pl-0"> 
                        </div>
                    </div>
                    <!-- Điện thoại -->
                    <div class="row my-2">
                        <div class="col-3 divLabel">
                            <span>{{ $t('message.DeliveryIndex.receiver_phone') }} </span>
                        </div>
                        <div class="col-9">
                            <input type="text" v-model="customer.phone" class="form-control inputVoucher pl-0"> 
                        </div>
                    </div>
                     <!-- Địa chỉ -->
                    <div class="row">
                        <div class="col-3 divLabel">
                            <span>{{ $t('message.DeliveryIndex.receiver_address') }} </span>
                        </div>
                        <div class="col-9">
                            <input type="text" v-model="customer.address" class="form-control inputVoucher pl-0"> 
                        </div>
                    </div>
                    
                    <!-- Khu vực -->
                    <InputLocationPicker class="mt-4" v-model="this.location" :voucherClass="true"></InputLocationPicker>
                    <!-- Người nộp -->
                    <div class="row">
                        <div class="col-3 divLabel">
                            <span>{{ $t('message.ProductIndex.note') }} </span>
                        </div>
                        <div class="col-9">
                            <i class="fa fa-pencil-alt ml-2 text-secondary" id="iconVoucher"></i>
                            <input type="text" v-model="customer.description" class="form-control inputVoucher px-4"> 
                        </div>
                    </div>
                    
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <button class="btn btn-primary mr-2" @click="save()">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.save')}}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.cancel')}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
    import InputLocationPicker from "@/components/forms/InputLocationPicker";
    import {$alert} from "@/lib/utils";
    export default {
        name: "AddCustomerForVoucher",
        components: {
            InputLocationPicker
        },
        data() {
            return {
                customer: {
                    id: null,
                    name: null,
                    phone: null,
                    address: null,                                        
                    description: null
                },
                location: null
            }
        },
        methods: {
            show(data) {  
                this.clearData();   
                if(data){
                    this.customer = {
                        id: data.id,
                        name: data.name,
                        phone: data.phone,
                        address: data.address,
                        description: data.description,
                    }
                    this.location = {
                        districtId: data?.districtId ?? null,
                        provinceId: data?.provinceId ?? null,
                        wardId: data?.wardId ?? null,
                    }
                }           
                window.jQuery(this.$refs.modal).modal('show');
            },
            save(){
                if(!this.customer.name){
                    $alert({code:422, message: this.$t('message.common.emptyName')});
                    return;
                }
                if(!this.customer.phone){
                    $alert({code:422, message: this.$t('message.common.emptyPhone')});
                    return;
                }
                this.$emit('getCustomer',this.customer, this.location );
                $alert({code:200, message: this.$t('message.common.addPartnerSuccess')});
                window.jQuery(this.$refs.modal).modal('hide');
            },
            clearData(){
                this.customer = {
                    id: null,
                    name: null,
                    phone: null,
                    address: null,                                        
                    description: null
                },
                this.location = null
            }
        },
    }
</script>

<style scoped>
.inputVoucher {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.divLabel{
    display: flex;
    align-items: center;
    font-weight: 600;
}
#iconVoucher {
    position: absolute;
    bottom: 10px;
    left: 10px;
}
</style>