<template>
    <table class="table table-index table-striped">
        <thead>
        <tr>
            <th>ID</th>
            <th>Tên</th>
            <th>{{$t('message.customerIndex.email')}}</th>
            <th>{{$t('message.customerIndex.phone')}}</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="entry in tabUsers" :key="entry.id">
            <td v-text="entry.id"></td>
            <td>
                <a :href="'/users/form?id=' + entry.id" v-text="entry.name"></a>
            </td>
            <td v-text="entry.email"></td>
            <td v-text="entry.phone"></td>
        </tr>
        </tbody>
    </table>
</template>

<script>
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";

    export default {
        name: "TabUser",
        components: {},
        data() {
            return {tabUsers: []}
        },
        mounted() {

        },
        methods: {
        }
    }
</script>

<style scoped>


</style>
