<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h3 class="modal-title py-2">{{ $t('message.customerIndex.confirm') }}</h3>
                    <button type="button" class="close pt-3 " aria-label="Close" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <div class="col-12 pb-5">
                        <!-- <h6>{{ $t('message.customerIndex.contentConfirm') }} <span v-text="this.message ? this.message : $t('message.customerIndex.change')"></span></h6> -->
                        <h6 v-text="this.message ? this.message : $t('message.customerIndex.change')"></h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.customerIndex.button.agree') }}
                        </button>
                        <button class="btn btn-secondary text-white" @click="skip()">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.customerIndex.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.table thead th
#modalConfirm .modal-header {
    border-bottom: 0 !important;
}
</style>
<script>

export default {
    name: "ModalConfirm",
    
    data(){

        return {
        }
    },
    props: ['message'],
    methods: {
        show() {
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        confirm(){
            this.$emit('confirm', 1);
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        }
    }
}
</script>