const messages = {
    en: {
        message: {
            // Header + common
            headers: {
                branch: "Branch",
                setupShop: "Shop configurations",
                print_template_manage: "Print templates",
                branch_manage: "Branch Management",
                promotion_manage: "Promotion Management",
                coupon_manage: "Coupon Management",
                voucher_manage: "Voucher Management",
                role_manage: "Role Management",
                permission_manage: "Permission Management",
                history: "Audit Trail",
                email_template_manage: "Manage Email Templates",
                logout: "Logout",
                dashboard: "Dashboard",
                list: "Inventory",
                products: "Products",
                product_list: "Product List",
                categories: "Product Group",
                units: "Product Unit",
                customers: "Customer",
                customer_list: "Customer List",
                customer_group: "Customer Group",
                suppliers: "Supplier",
                supplier_list: "Supplier List",
                supplier_group: "Supplier Group",
                employees: "User",
                employee_list: "User List",
                departments: "Department",
                transporters: "Delivery partner",
                sale_channels: "Sale Channel",
                price_list: "Price List",
                setting_price: "Price Setting",
                price_list_detail: "Price List Details",
                location_list: "Location Directory",
                transaction: "Transaction",
                purchase_products: "Purchase Receipt",
                plan_purchase_order: "Inventory Forecasting",
                inventory_plan: "Detailed Estimate",
                plan_purchase: "Inventory Forecasting",
                order_suppliers: "Purchase order",
                purchase_orders: "Purchase Receipt",
                return_purchase_orders: "Purchase Return",
                settlement_advances: "Request for an Advance Payment",
                sales: "Selling",
                buy: "Purchasing",
                orders: "Orders",
                invoices: "Invoices",
                returns: "Return Invoice",
                request_refund: "Return Request",
                warehouse_transfer: "Transfer",
                transfers: "Transfers", //x
                requests: "Requests", //x
                locations: "Location",
                ticket_import_location: "Put The Product In Place",
                ticket_export_location: "Remove The Product From The Location",
                ticket_transfer_location: "Relocate Position",
                cash_book: "General Pedger",
                cash_book_money: "General Ledger Cash",
                bank_book: "General Ledger Bank",
                reports: "Report",
                sell_online: "Sell Online",
                delivery: "Bill of lading",
                ReportSaleDetails: "Detailed Sales Ledger",
                ReportSaleChannel: "Sales Report By Sales Channel",
                ReportSaleEmployee: "Sales Report By Sales Employee",
                ReportSaleBranch: "Sales Report By Sales Branch",
                ReportRevenueProduct: "Detailed analysis of product/product group revenue over time",
                ReportRevenueInBranch: "Detailed analysis of product/product group revenue by branch",
                ReportRevenuePriceList: "Detailed analysis of product/product group revenue according to price list",
                ReportRevenueCustomerGroup: "Detailed analysis of product/product group revenue by customer group",
                ReportPurchaseDetail: "Detailed Purchase Ledger",
                ReportStockDetail: "Detailed Inventory Ledger",
                ReportInventories: "Inventory Report",
                ReportInventoriesDetail: "Detailed Inventory Report",
                ReportValueBranch: "Inventory Valuation Report",
                ReportConsignment: "Expiry Inventory Report",
                ReportStockLocation: "Inventory Location Report",
                ReportStockLocationConsignment: "Inventory Batch And Location Report",
                manage: "Management"
            },
            button: {
                confirm: "OK",
                skip: "Skip",
                cancel: "Cancel",
                save: "Save",
                remove: "Delete",
                removeFilter: "Refresh",
                action: "Option",
                import:{
                    name: "Import",                    
                    selectFile: "Select Data File",
                    action: "Upload",
                },
                export: {
                    name: "Export",
                },
                copy: {
                    name: "Copy",
    
                },
                print: {
                    name: "Print",
                },
                sendMailText: "Send email",                
                export_all: "Export overview list file",
                export_detail: "Export detailed list file",
                file_all: "Overview file",
                file_detail: "Detailed files",
                invoiceAdd: "Create invoices",
                close: "Close",
                choose: "Select",
                saveDraft: "Draft Save",
                addInvoice: "Add new invoice",
                addOrder: "Add new order",
                selectInvoiceForReturn: "Select invoice",
                returnFast: "Quick return",
                finished: "Done",
                detail: "Detail",
            },
            common: {
                quantityVoucherNoti: "Quantity voucher: ",
                quantityPromotionNoti: "Quantity promotion: ",
                quantityCouponNoti: "Quantity coupon: ",
                quantityProductNoti: "Quantity product: ",
                notFound: "Search results do not exist",
                notData: "No data",
                branch: "Branch",
                numberRecord: "Record",
                placeholder: {
                    branch: "Select branch"
                },
                time: "Time",
                status: "Status",
                created_by: "Created by",
                male: "Male",
                female: "Female",
                inValidFormatFile: "The uploaded file must be in the correct xlsx, xls format",
                inValidFormatTime: "Time is not in correct format",
                vnd: "VND",
                percent: "%",
                choose: "Select",
                chooseImage: "Select photo",
                upload: "Upload",
                download: "Download",
                up: "Up",
                uploadFromUrl: "Upload images from URL",
                chooseFile: "Select file from library",
                manageFile: "File management",
                enterUrl: "Enter the URL",
                chooseUrl: "Please select the url",
                maxThreeImage: "Please select up to 3 photos",
                chooseImagePlease: "Please select a photo",
                maxNumberImage: "Please select up to {number} photos",
                import: "Import",
                export: "Export",
                date: "date",
                downloadDefaultFile: "Download sample file: ",
                excelFile: "Excel file",
                have: "Have",
                selected: "Selected",
                image: "Image",
                errorServer: "Server error",
                confirm: "Confirm",
                confirmCancelChange: "Do you want to cancel changes?",
                product: "Products",
                applyFormulaForAll: "Apply formula to products that already have prices in the price list?",
                draft: "Draft",
                planPurchase: "Inventory forecasting",
                planDetail: "Forecast",
                existProduct: "Products already exist!",
                existPlanDetail: "Detailed estimate form exists!",
                searchProduct: "By product code or name",
                searchPlanDetail: "By code or detailed estimate name",
                service: "Service",
                combo: "Combo",
                productValue: "Product value",
                all: "All",
                active: "Active",
                inActive: "Inactive",
                province: "Province",
                district: "District",
                ward: "Ward",
                effect: "Valid",
                expire: "Invalid",
                createdAt: "Created at",
                statusCancel: "Cancelled",
                statusReturned: "Returned",
                unpaid: "Unpaid",
                paid: "Complete payment",
                partialPayment: "Partial payment",
                ticket: "EN_phiếu", //chưa dịch
                STT: "No.",
                searchSupplier: "Find suppliers",
                autocompleteCode: "Generated automatically",
                cash: "Cash",
                card: "Card",
                transferPayment: "Transfer",
                none: "None",
                received: "Transfering",
                notReceived: "Not received",
                choosePrintTemplate: "Select print template",
                receiverDefault: "Default recipient",
                sendToGroup: "To group",
                chooseZaloTemplate: "Select zalo template",
                sendZNS: "ZNS",
                cc: "CC",
                searchOrderSupplier: "Find the purchase order ",

                createPurchaseOrderSuccess: "Created a new purchase receipt successfully",
                updatePurchaseOrderSuccess: "Updated a new purchase receipt successfully",
                emptyBatch: "Product {name} has not been choose LOT.",
                invalidPurchaseQuantity: "The quantity of product {name} cannot exceed the order quantity.",
                invalidQuantity: "The quantity of product {name} cannot be less than or equal to zero.",
                isEmptyPO: "The purchase receipt is empty",
                havePODraft: "A purchase order can only have one temporary draft.",
                haveReturnPODraft: "A purchase receipt can only have one temporary draft return.",
                confirmChangePo: "This PO has not been saved. Do you want to change another one?",
                searchPO: "By purchase receipt code",
                invalidReturnPOQuantity: "The return quantity of product {name} cannot be greater than the import quantity.",
                createReturnPOSuccess: "Created a new return successfully",
                updateReturnPOSuccess: "Updated a new return successfully",
                saleModeFast: "Quick sales",
                saleModeTransfer: "Delivery sales",
                returned: "The bill has been paid!",
                confirmEndOrder: "You just created an invoice from an order {code}. Will you not proceed with uncollected products and end the order now?",
                processOrder: "Processing",
                orderNote: "By note",
                confirmCloseTab: "The information of {tab} will not be saved. Are you sure you want to close?",
                stamp: "Labels",
                returnQuantity: "Return quantity",
                shortQuantity: "Quantity",
                orderQuantity: "Order quantity",
                displayHSCode: "Display HS code",
                displayTax: "Show Tax Rate",
                promotionInvoice: "Invoice promotion",
                discountProduct: "Discount on products",
                noProduct: "No products",
                businessMan: "Sales employees",
                selectPriceBook: "Select price list",
                totalCostPrice: "Sub-total",
                KM: "Promotion",
                couponCode: "Coupon code",
                voucherCode: "Voucher code",
                returnCustomer: "Total refund ",
                customerPays: "Customer pay",
                ExcessMoneyPaidByCustomer: "Give change to seller",
                ExcessMoneyReturnedToCustomers: "Excess money to customer",
                RemainingUnpaid: "Unpaid",
                ExpectedDelivery: "Delivery time",
                changeInforInvoice: "Change electronic invoice information",
                soldByName: "Buyer's full name",
                enterSoldByName: "Enter buyer's full name",
                enterUnitName: "Enter the company name",
                noteForDriver: "Notes for the driver",
                collection: "COD",
                cm: "cm",
                searchTransporter: "Search delivery partner",
                normal: "Normal",
                fast: "Fast",
                duringTheDay: "Today",
                linkToGHN: "Need to link with Fast Delivery.",
                linkToAhaMove: "Need to link with Ahamove.",
                linkToViettelPost: "Need to link with ViettelPost.",
                loginNow: "Log in now!",
                deliveryPromotion: "Delivery promotion",
                theSenderPaysTheFee: "The sender pays the fee",
                paymentViaApp: "Payment via App",
                heavyThings: "Bulky goods",
                declarePrice: "Declaring goods prices",
                maximumDeclaredValue: "The maximum declared value is",
                million: "million",
                sendSMS: "Send SMS to recipient",
                fragile: "Fragile products",
                atThePostOffice: "Send goods at the post office",
                cod: "COD",
                cannotBeSmaller: "Cannot be less than",
                cannotBeLarger: "Cannot be more than",
                descriptionDetail: "Detailed description",
                canSell: "Can be sold",
                totalCustomerOrder: "Total customer order",
                listPromotion: "List of promotions",
                promotions: "Promotional program",
                formOfPromotion: "Form of promotion",
                selectPromotionItems: "Choose promotional products/gifts",
                buy: "Buy",
                selectProductPromotion: "Choose promotional products",
                searchProductPromotion: "Find promotional products",
                haveNotSelectProduct: "Product has not been selected",
                cannotMergePromotion: "Combining promotions is not allowed, please do not select more.",
                selectProductQuantity: "Please select product quantity",
                appliedPromotionOfCustomer: "This customer has been applied the following promotional programs",
                confirmApplyContinue: "Do you want to continue applying those promotions?",
                loginAhamove: "Login Ahamove",
                vietnam: "VN",
                apiKey: "API Key",
                enterApiKey: "Enter the API code",
                newAddress: "New address",
                showProduct: "Allow checking goods",
                notShowProduct: "Do not allow checking goods",
                notEnoughInventory: "Insufficient inventory of product {name}.",
                emptyOrder: "Order is empty.",
                cannotApplyVoucher: "Voucher code does not exist or is not eligible to be applied.",
                usedVoucher: "Voucher has been used",
                cannotApplyVouchers: "Do not apply multiple voucher codes in the same issuance.",
                cannotApplyCoupon: "Coupon code does not exist or is not eligible to be applied.",
                confirmPayment: "You have not entered customer information. The system does not track debt with retail customers. Are you sure you want to pay?",
                notEnoughMoney: "Payment is not enough",
                emptyInvoice: "Invoice is empty.",
                selectWeightProduct: "Please select weight unit",
                notExceed30Kg: "Weight must not exceed 30kg",
                notExceed10Million: "COD amount cannot exceed 10,000,000",
                cannotLessThan0: "COD amount cannot be less than 0",
                notExceed5Million: "Order value cannot exceed 5,000,000",
                notExceed150Cm: "Size must not exceed 150cm",
                addInvoiceSuccess: "Created invoice successfully",
                emptyReturn: "No products selected.",
                addOrderSuccess: "Created the order successfully ",
                updateOrderSuccess: "Updated the order successfully ",
                updateInvoiceSuccess: "Updated the invoice successfully ",
                confirmReturn: "You have not entered customer information. The system does not track debt with retail customers. Are you sure you want to return the products?",
                quantityReturnGreaterThan0: "The quantity of returned goods must be greater than 0",
                addReturnSuccess: "Created the return successfully ",
                notEnoughInventorySystem: "Sales quantity exceeds system inventory!",
                first: "First",
                last: "Last",
                netRevenue: "By net revenue",
                accordingToQuantity: "By quantity",
                selectCoupon: "Select coupon",
                selectCouponCode: "Select Coupon code",
                usedQuantity: "Quantity used",
                remainingQuantity: "The remaining quantity",
                fromQuantity: "Quantity from",
                quantityStartFrom1: "The number must start from 1",
                inValidQuantity: "Invalid quantity",
                orderCode: "Orders code",
                orderDate: "Time",
                addOrderWhenNotEnoughInventory: "Orders are allowed when out of stock",
                sellWhenOrdered: "Sales, transfer once the product has been ordered",
                loginMisaTitle: "Set up electronic invoice connection",
                loginMisa: "LOG IN MISA",
                inValidInforLogin: "Login information is incorrect",
                loginMisaSuccess: "Log in misa successfully",
                existMisaAccount: "The system already has a misa account",
                level: "Limit",
                maxDebt: "Maximum amount a customer owed",
                warningWhenTransaction: "If the customer owes more than the specified amount, the system will warn or block the transaction",
                numberOfDebtDate: "Days past due",
                maxNumberOfDebtDate: "Maximum amount a customer owed",
                warningWhenTransaction2: "If the customer owes more than the specified time, the system will warn or block the transaction",
                dayFrom: "From",
                addDebt: "Allow customers to owe more if they exceed the limit",
                addDebtTooltip: "When transactions occur that cause the customer's debt to exceed the limit (such as creating Invoices, making Payment Vouchers), the system will block further transactions.",
                addDebtNote: "Note: The system does not block when synchronizing offline transactions, synchronizing transactions from e-commerce platforms, invoices with COD, canceling invoices, canceling payment slips",
                updatePromotion: "Update promotional programs",
                addPromotion: "Add promotions",
                addConditional: "Add conditions",
                back: "Back",
                cancelChange: "Cancel changes",
                enterBatch: "Enter batch and expiration date",
                addBatch: "Add new batch, expiry date",
                selectBatch: "Select lot",
                addBatchFor: "Add lot for",
                batch: "Lot",
                selectBatchExpire: "Select batch and expiration date",
                searchBatch: "Search batch and expiration date",
                notFoundBatch: "No matching Lots were found",
                quantityNotGreaterThan: "The quantity cannot be larger",
                emptyProductBatch: "You have not entered a lot yet",
                inValidExpire: "Invalid expiration date",
                exprieNotLessThanToday: "Expiration date cannot be less than the current date",
                existBatch: "This lot already exists",
                uploadDate: "Upload date",
                fileName: "File name",
                fileExtension: "Format",
                purchaseDate: "Purchase date",
                searchInventoryPlan: "Search products by name or detailed estimate code",
                searchInvoiceCode: "Search invoice code",
                selectBranches: "Please select a branch",
                addAttribute: "Create variations",
                attributeName: "Attribute name",
                addAttributeSuccess: "Attribute created successfully",
                addDeliverySuccess: "Successfully created bill of lading",
                bank: "Bank",
                totalRecords: "Total of records",
                numberNewRecords: "New records",
                numberUpdatedRecords: "Updated records",
                numberErrorRecords: "Error records",
                noPermission: "You can not access this page",
                backToHome: "Back to home",
                needPermission: "You need permission to access",
                selectCategories: "Select product group",
                productQuantities: "Product quantity",
                action: "Action",
                select: "Option",
                addNewGroup: "Create a new group",
                searchReturnPO: "Search the imported return ",
                searchBAS: "Search BAS",
                inValidEmail: "Incorrect email format",
                exist: "already exist",
                max20Attribute: "The maximum value is 20 attributes",
                removeAllSelected: "Delete select all",
                major: "Operation",
                setting: "Setting",
                printTemplate: "Print templates",
                atBranch: "At Branch",
                cancelCouponError: "You only cancel unissued coupons. The following coupon is not valid",
                confirmCancelCoupon: "Are you sure to release the selected coupon list?",
                cancelCouponSuccess: "Coupon cancellation successful",
                cancelCouponFailed: "Coupon cancellation failed",
                addCoupons: "Add coupon list",
                quantityCoupon: "Coupon quantity",
                quantityCouponWillCreate: "Number of coupon codes generated",
                couponCodeLength: "Code length",
                couponCodeLengthNote: "The length cannot be less than 8 characters and greater than 15 characters",
                reduce: "reduce",
                forInvoice: "for invoice",
                removeCoupon: "Delete coupon issuance",
                confirmRemoveCoupon: "The system will completely delete this coupon issuance, do you want to do it?",
                removeCouponSuccess: "Deleted coupon issuance successfully",
                removeCouponFailed: "Coupon has been issued or has been used. You cannot delete.",
                releaseCouponError: "You can only issue unreleased coupons. The following coupons are not valid",
                confirmReleaseCoupon: "Are you sure to release the selected coupon list?",
                releaseCouponSuccess: "Successfully issued coupons",
                releaseCouponFailed: "Coupon issuance failed",
                fullname: "Full name",
                addressDetail: "Detail address",
                home: "Private house",
                enterGroupName: "Enter the group name",
                processingUpload: "Handling file uploads",
                uploadFile: "Upload file",
                row: "Row",
                uploadFileSuccess: "Uploaded successfully",
                cancelDeliverySuccess: "Successfully canceled bill of lading",
                cancelDeliveryFailed: "Order status cannot be canceled",
                updateBASSuccess: "The BAS {code} was updated successfully",
                updateBASFailed: "The BAS {code} was updated unsuccessfully",
                cancelBASFailed: "BAS cancellation failed",
                removeBASSuccess: "BAS deleted successfully",
                removeBASFailed: "BAS deleted failed",
                confirmRemoveBAS: "Are you sure you want to delete the BAS {code}?",
                confirmCancelBAS: "Are you sure you want to cancel the BAS {code}?",
                notFoundTicket: "No found",
                totalQuantityOrder: "Total quantity",
                reportCode: "Report code",
                confirmCancelInvoice: "Are you sure you want to cancel the invoice {code}?",
                cancelInvoiceSuccess: "Cancel invoice {code} successfully",
                cancelInvoiceFailed: "Cancel invoice {code} failed",
                uploadFileInvoice: "Create invoices from data files",
                delivery: "Delivery",
                firstCharacterInvoice: "Invoice codes always begin with the 'HD'. If you do not enter, the system will automatically add it to ensure the above principles.",
                maxLengthInvoiceUpload: "The system allows importing up to 1,000 lines at a time.",
                ensureInventoryWhenUpdload: "Ensure inventory of goods is still sufficient.",
                completeOrder: "Complete the order",
                confirmCompleteOrder: "Do you want to complete the order {code} ?",
                completeOrderSuccess: "Completed the order successfully",
                updateInvoiceFailed: "Updated invoice failed",
                confirmCancelOrderSupplier: "Are you sure to cancel the purchase order {code}?",
                cancelOrderSupplierSuccess: "Cancel purchase order {code} successfully",
                cancelOrderSupplierFailed: "Cancel purchase order {code} failed",
                closeOrderSupplier: "You will not be able to continue processing the purchase order even though there is still unfinished stock. Are you sure you want to end the purchase order?",
                closeOrderSupplierSuccess: "Finished the purchase order {code} successfully",
                closeOrderSupplierFailed: "Finished the purchase order {code} failed",
                emptyUploadFile: "There are no records in the uploaded file",
                selectMethodDiscount: "Choose a discount method",
                priceDiscount: "Reduced price",
                methodDiscount: "Reduction method",
                confirmCancelOrder: "Are you sure to cancel the order {code}?",
                cancelOrderSuccess: "Cancel order {code} successfully",
                cancelOrderFailed: "Cancel order {code} failed",
                selectStatus: "Select status",
                maxLengthProcessOrder: "The system only allows processing a maximum of 10 orders ", 
                inValidExpectedDateOfTransfer: "Estimated delivery date cannot be less than today",
                updateOrderFailed: "Updated order failed",
                updateOrderSuccessfully: "Updated order successful",
                roleName: "Role name",
                confirmCancelUpdateRole: "Do you want to cancel role updates?",
                confirmCancelAddRole: "Do you want to cancel adding new roles?",
                emptyPermission: "You have not selected permissions",
                permission: "Decentralization",
                role: "Role",
                searchEmployee: "By code, employee name, login name",
                perrmissionUser: "Assign permissions to users",
                roles: "List of roles",
                addRole: "Add new roles",
                searchRole: "Search roles",
                searchBranch: "Search branches",
                assignRole: "Assign additional roles",
                confirmLogoutUser: "If user {name} is using the system, he will be logged out immediately to update his role. Do you want to continue performing the update?",
                emptyBranchRole: "Please select branch and role for permissions ",
                duplicateBranchRole: "Role and branch data are duplicated",
                addPermissionSuccess: "Added new permissions successfully",
                addPermissionFailed: "Adding new permissions failed",
                updatePermissionSuccess: "Update permissions successfully",
                updatePermissionFailed: "Update permissions failed",
                selectBranchRole: "Row {row}: Please select a role and branch",
                selectBranchPermission: "Row {row}: Please select a branch",
                selectRolePermission: "Row {row}: Please select a role and branch",
                confirmCopyProduct: "Are you sure you want to copy this product?",
                copyProductSuccess: "Copy product successfully",
                emptyProductSelected: "No products have been selected yet",
                paper3: "3-label roll paper sample (Label paper size 104x22mm / 4.2 x 0.9 inch)",
                paper12: "Paper sample of 12 labels (Tomy 103 label printing paper size 202x162mm)",
                paper2: "Paper roll sample of 2 labels (label printing paper size 72x22mm)",
                paper65: "Paper sample of 65 labels (A4 Tomy 145 label printing paper size)",
                paper2_1: "Paper roll sample of 2 labels (label printing paper size 74x22mm)",
                paperJewelry: "Jewelry label sample (label paper size 75x10mm / 2.75in x 0.4in)",
                printNote: "Note: In case the printed barcode is unreadable, please use a larger Paper Form or shorten the product code",
                noPrintPrice: "No price printing",
                priceWithVND: "Price includes VND",
                priceNonVND: "Price does not include VND",
                priceWithUnit: "Price includes unit of measure",
                priceNonUnit: "Price does not include unit of measure",
                printShopName: "Print the store name",
                noPrintShopName: "Do not print the store name",
                inventorySystem: "System inventory",
                inventory: "Actual inventory",
                existAttribute: "The attributes already exist",
                removeVarriant: "Delete attributes",
                removeUnit: "Delete units",
                productInformation: "Product information",
                quantityTransfer: "Transferred quantity",
                quantityReceive: "Receive quantity",
                productSelected: "Selected product",
                inActiveProduct: "You want to stop trading in goods with code {code} on:",
                reActiveProduct: "You want to allow trading of goods with code {code} on:",
                keepInforationInventory: "Inventory information and transaction history are still kept",
                discontinued: "Related converted goods will be discontinued",
                customer: "Customers",
                applyBranch: "Applicable branch",
                allBraches: "All branches",
                promotionBy: "Promotions follow",
                selectPromotionType: "Select promotion type",
                invoiceWithProduct: "Invoices and goods",
                giveProduct: "Donate goods",
                giveVoucher: "Give away vouchers",
                buyProductDiscountProduct: "Buy discounted items",
                buyProductGiveProduct: "Buy and get free goods",
                priceByQuantity: "Price depends on quantity purchased",
                buyProductGiveVoucher: "Buy and get a voucher",
                removePromotion: "Delete promotion",
                startTime: "Start time",
                endTime: "End time",
                promotionInformation: "Promotional information",
                form: "Form",
                productByQuantityBuy: "Gift items are multiplied according to the quantity purchased",
                productByQuantityBuyNote: "For example: Buy 2 men's T-shirts and get 1 pair of socks for free",
                productByQuantityBuyNote2: "Don't multiply by quantity purchased: if you buy 4 t-shirts, you'll still only get 1 pair of socks for free",
                productByQuantityBuyNote3: "Multiply by quantity purchased: if you buy 4 t-shirts, you will get 2 pairs of socks for free",
                promotionValue: "Promotional value",
                totalGift: "Total donation",
                giftGroup: "Gift group",
                giftProduct: "Gift goods",
                categoriesAreDiscounted: "Discounted product groups",
                productsAreDiscounted: "Goods are discounted",
                purchaseProductCategories: "Purchased goods/groups of goods",
                discountedProductCategories: "Goods/groups of goods are discounted",
                withGiftCategories: "Attached to group of purchased products",
                withGiftProduct: "Included with purchase",
                withPurchaseProductCategories: "Include purchased items/groups of items",
                giftProductCategories: "Gift items/groups of items",
                selectGiftCategories: "Select a gift group",
                noteSelectCategories: "HSupport adding product lists by copying/pasting the product code list. Product codes must be separated by 'Enter' or ','. <br> Example <br>SP000123<br>SP000125<br>Or: SP000123, SP000125",
                selectGiftProduct: "Choose gift items",
                selectDiscountCategories: "Select a discounted product group",
                otherOption: "Other options",
                warningWhenUsedPromotion: "Warning if the customer has taken advantage of this promotion.",
                warningWhenUsedPromotionNote: "Promotions will not be applied automatically. If the customer has previously enjoyed a promotion, the next time the promotion is applied, a warning will be displayed on the sales screen.",
                allSeller: "All sellers",
                emptyConditionPromotion: "Promotion type: You have not added promotion conditions.",
                addPromotionSuccess: "Add successful promotions",
                updatePromotionSuccess: "Updated successful promotions",
                removePromotions: "Delete promotions",
                confirmRemovePromotion: "Are you sure you want to delete the {code} promotion?",
                searchPromotion: "Code name, program name",
                historyPromotionOrder: "Promotion history - Order",
                historyPromotionInvoice: "Promotion history - Invoice",
                totalAmountFrom: "Total cost of goods from",
                quantityBuy: "Quantity purchased",
                quantityDiscount: "Quantity decreased",
                quantityGift: "Donation quantity",
                pricePromotion: "Promotional price",
                confirmCancelPO: "Are you sure to cancel the goods receipt {code}?",
                cancelPOSuccess: "Cancellation of goods receipt {code} successfully",
                cancelPOFailed: "Cancel order receipt {code} failed",
                confirmCancelRetunrPO: "Are you sure to cancel the return slip entered {code}?",
                cancelReturnPOSuccess: "Cancel return {code} successfully",
                cancelReturnPOFailed: "Cancel return {code} failed",
                receiveInvoice: "Receive invoice",
                invoiceDate: "Bill date",
                invoiceAmount: "Amount on invoice",
                nonInvoice: "No bills",
                hasInvoice: "Have invoice",
                enterInvoice: "Enter invoice",
                enterInvoiceDate: "Enter the invoice date",
                enterAmount: "Enter the amount",
                addInvoiceForPOSuccess: "Create invoice for products receipt successfully.",
                updatePOSuccess: "Products receipt {code} updated successfully",
                pdf: "PDF",
                excel: "Excel",
                mix: "Synthetic",
                confirmCancelTicket: "Are you sure you want to process this ticket?",
                approveRequestRefund: "Approve the return request form",
                confirmApproveRequestRefund: "Are you sure you want to approve this request {code}?",
                refuseRequestRefund: "Refuse to approve the return request",
                confirmRefuseRequestRefund: "Are you sure you want to refuse to approve this request {code}?",
                approveSuccess: "Application approved successfully",
                approveFailed: "Application approval failed",
                refuseSuccess: "Proposal rejected successfully",
                refuseFailed: "Reject the unsuccessful proposal",
                cancelRequestRefund: "Cancel the request for refund",
                confirmCancelRequestRefund: "Are you sure you want to cancel the return request {code}?",
                cancelRequestRefundSuccess: "Cancellation of return request {code} successful",
                cancelRequestRefundFailed: "Cancellation of return request {code} failed",
                searchInvoice: "According to the invoice",
                requestRefundCode: "Return request code",
                approveTime: "Approval time",
                updateRequestRefundSuccess: "The return request form {code} has been successfully updated",
                updateRequestRefundFailed: "The update request for refund {code} failed",
                addNewRole: "Save as new role",
                removeRole: "Delete role",
                confirmRemoveRole: "Are you sure you want to delete this role?",
                lastUpdateDay: "Last updated date",
                emailTemplate: "Email template",
                selectTemplate: "Sampling",
                templateKeys: "Printable sample token list",
                importPosition: "Enter location",
                exportPosition: "Export location",
                transferPosition: "Change position",
                bas: "BAS",
                selectOrderSync: "Select orders to synchronize",
                onlySyncOrder: "Only orders can be synchronized",
                from: "From",
                confirmConnectNote: "Note: When these orders are synchronized, the corresponding orders and invoices will be created and the order quantity and inventory of related products will be changed.",
                understood: "Understood",
                fifteenDay: "Last 15 days",
                afterSetting: "After setup",
                create: "Create",
                createOrUpdate: "Create or update",
                selectBranchBeforeSave: "Please select a branch before saving!",
                disconnect: "Delete connection",
                confirmDisconnect: "Are you sure you want to DELETE the booth connection to",
                confirmCancelRequests: "Are you sure you want to cancel the order {code}?",
                cancelRequestsSuccess: "Order cancellation {code} successful",
                cancelRequestsFailed: "Cancel order request {code} failed",
                confirmCancelTransfer: "Are you sure you want to cancel the delivery note {code}?",
                cancelTransferSuccess: "Cancellation of delivery note {code} successful",
                cancelTransferFailed: "Cancellation of delivery note {code} failed",
                selectBranchRequest: "Select the request branch",
                joint: "Matched",
                noJoint: "Unmatched",
                deviated: "Discrepancy",
                notGiven: "Not given yet",
                requestQuantity: "Requested quantity",
                transferQuantity: "Quantity given",
                requestPrice: "Price",
                waitForConfirmation: "Wait for confirmation",
                totalRequestQuantity: "Total request quantity",
                totalTransferQuantity: "Total quantity given",
                createTransfer: "Create inventory transfer",
                emptyRequests: "The order form is empty",
                inValidRequestBranch: "Invalid calling branch",
                inValidTransferQuantityConsignment: "The transfer quantity of batch {consignment} of product {name} cannot be greater than inventory",
                inValidTransferQuantityProduct: "The transfer quantity of product {name} cannot be greater than the inventory",
                inValidTransferQuantityProductForRequest: "The transfer quantity of product {name} cannot be greater than the order quantity",
                updateRequestSuccess: "The request form has been updated successfully",
                updateConfirmRequestSuccess: "The order confirmation slip has been successfully updated",
                confirmRequestSuccess: "Call confirmation successful! The corresponding delivery note has been created",
                addRequestSuccess: "The request was successfully created",
                inValidRequestedBranch: "Invalid branch called!",
                maxLengthRequestBranchNote: "Calling branch notes cannot be larger than 255 characters",
                maxLengthRequestedBranchNote: "The referred branch note cannot be larger than 255 characters",
                transferPrice: "Price",
                moving: "Moving",
                transferBranch: "Sending branch",
                totalSendQuantity: "Total amount transferred",
                totalReceiveQuantity: "Total quantity received",
                receive: "Receive",
                outOfStock: "The product is out of stock",
                emptyTransfer: "The shipping slip is empty",
                inValidReceiveBranch: "Invalid receiving branch",
                emptyNote: "Notes cannot be left blank",
                emptyConsignment: "Product {name} cannot have an empty batch",
                inValidReceiveQuantity: "The quantity received of product {name} cannot be greater than the quantity shipped",
                updateTransferSuccess: "Delivery note updated successfully",
                confirmTransferSuccess: "Delivery confirmation has been successfully updated",
                receiveSuccess: "Received goods successfully! The quantity of goods in stock will be updated",
                addTransferSuccess: "The delivery note was successfully created",
                maxLengthTransferBranchNote: "Transfer branch notes cannot be larger than 255 characters",
                maxLengthReceiveBranchNote: "Receiving branch notes cannot be larger than 255 characters",
                removeDebtAdjustmentSuccess: "Delete debt adjustment successfully",
                debtAdjustmentSuccess: "Adjust debt successfully",
                debtAdjustmentFailed: "Debt adjustment failed",
                updateDeliveryInforSuccess: "Update delivery information",
                sellTime: "Sales time",
                updateHistoryDeliverySuccess: "Update successful delivery history",
                addSubmitter: "Add submitter",
                emptyName: "You have not entered a name",
                emptyPhone: "You did not enter a phone number",
                addPartnerSuccess: "Add partners successfully",
                uploadVoucher: "Import vouchers from data file",
                maxLengthVoucher: "The system allows receiving up to 5,000 vouchers at a time from the file",
                cancelVoucherError: "The system only allows cancellation of unissued vouchers. The following vouchers are not valid",
                confirmCancelVoucher: "Are you sure you want to cancel these vouchers?",
                cancelVoucherSuccess: "Voucher canceled successfully",
                cancelVoucherFailed: "Voucher cancellation failed",
                releaseVoucherError: "You can only issue unissued vouchers. The following vouchers are not valid",
                give: "Donate",
                releaseVoucherSuccess: "Voucher issued successfully",
                releaseVoucherFailed: "Voucher issuance failed",
                confirmReleaseVoucher: "You have not entered the Buyer/Receiver Name. Are you sure you want to publish?",
                inValidVoucherValue: "Voucher value is not valid",
                confirmAddReceiptsForVoucher: "The system will generate receipts for the above vouchers. Are you sure you want to publish?",
                returnVoucher: "Return the voucher",
                returnVoucherError: "The system only allows returns of issued vouchers. The following vouchers are not valid",
                returnDate: "Pay day",
                returnVoucherSuccess: "Voucher returned successfully",
                returnVoucherFailed: "Voucher return failed",
                inValidReturnBy: "You have not entered the Payer Name. Are you sure you want to pay?",
                confirmReturnVoucher: "The system will generate receipts for the above vouchers. Are you sure you want to pay?",
                enterBAScode: "Enter BAS code",
                searchSuppliers: "Find suppliers by code and name",
                confirmCancelInforSaved: "Do you want to cancel the information you created?",
                notFoundRecord: "This record was not found",
                existSupplier: "The provider already exists",
                deliveryDetail: "Delivery note details",
                long: "Length",
                width: "Width",
                height: "Height",
                pickUpAtTheBranch: "Pick up goods at the Branch",
                approve: "Approve",
                refuse: "Refuse",
                emptyRequestRefund: "The return request form is empty",
                addRequestRefundSuccess: "Created a successful return request",
                updatedRequestRefundSuccess: "Updated the return request form successfully",
                intoStorage: "Enter warehouse",
                takeOutStorage: "Export from warehouse",
                existDocumentOrigin: "Original documents already exist",
                poWithReturnPOCode: "Import Order Code/Import Return",
                statusPO: "Status",
                totalAdvance: "Total advance payment",
                totalSettlement: "Settlement amount",
                totalDebtInvoice: "Number of outstanding invoices",
                updateTicketSuccess: "Updated ticket successfully",
                addTicketSuccess: "Ticket created successfully",
                searchImportPosition: "Find the location entry coupon code",
                searchExportPosition: "Find the location export ticket code",
                confirmSaveInforSaved: "Do you want to save the updated information?",
                outputLocation: "Output location",
                entryLocation: "Entry location",
                locationCode: "Location code",
                totalQuantityProducts: "Total number of products",
                integrateSalesChannels: "Integrate sales channels",
                linkToECommerce: "Link to e-commerce platform",
                linkToProduct: "Commodity links",
                addStall: "Add stall",
                stallName: "Store name",
                syncOrder: "Synchronize orders",
                syncQuantity: "Synchronize sales quantity",
                syncPrice: "Synchronized selling price",
                noLinkStall: "There are no linked Shops yet",
                linkToStallSuccess: "Connected booth successfully.",
                retry: "An error occurred, please try again",
                existStall: "The booth has been connected, try a customer booth",
                linkToStall: "Floor booth connection",
                linkProductToOmipos: "Link goods on the floor to Omipos goods",
                settingSync: "Set up sync feature",
                loginStallToOmpos: "Log in and connect the store on {stall} with Omipos to synchronize goods and order information on Omipos",
                noAccount: "No account",
                register: "Register",
                syncError: "Synchronization error",
                enterCodeOrName: "Enter code and product name",
                enterIDOrSKU: "Enter item ID, SKU code or item name",
                stall: "Booth",
                productOnStall: "Goods on the floor",
                id: "ID",
                sku: "SKU",
                branchSync: "Synchronous branch",
                branchSyncDesc: "Select a branch to synchronize order information, inventory, and selling prices.",
                syncOrderDesc: "Automatically synchronize orders from the floor to Omipos for centralized processing and management of revenue and profits",
                onlySyncOrderWhen: "Only sync order when",
                syncOrderNote: "Note: When these orders are synchronized, orders will be created and corresponding invoices will change the order quantity and inventory of related products.",
                productNoLink: "When an order has unlinked goods",
                confirmDelivery: "Confirm return shipping bill",
                confirmDeliveryDesc: "The store's return bills of lading need to be confirmed to move to the 'Refunded' status.",
                syncSaleQuantity: "Synchronize sales quantity",
                autoSyncInventory: "Automatically synchronize inventory from Omipos",
                formulaSyncQuantity: "Formula to synchronize sales quantity",
                setValueQuantity: "Set the above sales quantity value",
                fromFormuala: "according to formula",
                distributionOfSaleQuantity: "Distribution of sales volume of multi-linked products",
                ecommerceLevel: "platform",
                error: "Error",
                syncAll: "Sync all",
                products: "Product",
                removeSyncError: "Removed from error list",
                sync: "Synchronized",
                productSync: "Synchronous goods",
                syncContent: "Synchronized content",
                inValidInventoryProduct: "The product's inventory is less than the total quantity ordered by the customer or participating in the above promotion program",
                reSync: "Syncing again...",
                syncSuccess: "Synchronization successful",
                currentPrice: "Current price",
                lastPurchasePrice: "Last entry price",
                month1: "January",
                month2: "February",
                month3: "March",                    
                month4: "April",                    
                month5: "May",                    
                month6: "June",                    
                month7: "July",                    
                month8: "August",                    
                month9: "September",                    
                month10: "October",                    
                month11: "November",                    
                month12: "December",
                day1: "Day 1",
                day2: "Day 2",
                day3: "Day 3",
                day4: "Day 4",
                day5: "Day 5",
                day6: "Day 6",
                day7: "Day 7",
                day8: "Day 8",
                day9: "Day 9",
                day10: "Day 10",
                day11: "Day 11",
                day12: "Day 12",
                day13: "Day 13",
                day14: "Day 14",
                day15: "Day 15",
                day16: "Day 16",
                day17: "Day 17",
                day18: "Day 18",
                day19: "Day 19",
                day20: "Day 20",
                day21: "Day 21",
                day22: "Day 22",
                day23: "Day 23",
                day24: "Day 24",
                day25: "Day 25",
                day26: "Day 26",
                day27: "Day 27",
                day28: "Day 28",
                day29: "Day 29",
                day30: "Day 30",
                day31: "Day 31",
                confirmationTime: "Confirmation time",
                manualLink: "Wait for manual link",
                inventoryMinusOrdered: "In stock - Order",
                productOnLazada: "Products on Lazada",
                productOnShopee: "Products on Shopee",
                productOnTiki: "Products on Tiki",
                productOnTiktok: "Products on Tiktok",
                productOnSendo: "Products on Sendo",
                searchOrderCode: "By order code",
                enterOrderCode: "Enter order code",
                searchCustomerInfor: "By code, name, customer phone number",
                enterCustomerCodeNamePhone: "Enter customer code, name, and phone number",
                searchProductCodeName: "By code, product name",
                enterProductCodeName: "Enter code and product name",
                productOnECommerce: "Products on the platforms",
                notRelease: "Not released",
                released: "Released",
                used: "Used",
                cancelled: "Canceled",
                promotionInvoiceDiscountDesc: "For example: <br/>- Invoices with a total price of 500,000 or more receive a 5% discount <br/> - Invoices with a total price of 1,000,000 or more receive a 10% discount",
                promotionInvoiceDonateProductDesc: "For example: <br/>- Invoices with a total amount of goods from 500,000 will receive 1 pair of socks <br/> - Invoices with a total amount of goods from 1,000,000 will receive 1 free product of the Accessories group",
                promotionInvoiceDiscountProductDesc: "For example: <br/>- Invoices with a total amount of goods from 100,000 receive a 5% discount for t-shirts <br/> - Invoices with a total amount of goods from 200,000 receive a 10% discount for t-shirts",
                promotionProductDiscount: "When purchasing enough quantities, customers will receive a discount on subsequent purchases.",
                promotionProductDonateProductDesc: "When purchasing enough  quantities, customers will receive additional products.",
                promotionProductAllowProductDesc: "For example: <br/> - Buy 3 or more T-shirts and get a discount of 250,000 per shirt <br/> - Buy 5 or more T-shirts and get a discount of 50,000 for each shirt.",
                promotionAllInvoiceDiscountDesc: "For example: <br />- Invoices with a total amount of goods from 2,000,000 (VND) and purchase of at least 1 Mango Bag product will receive a 5% discount. <br/>-Purchases of at least 3 Mango Bag products will receive a 5% discount.",
                promotionAllDonateProductDesc: "For example: <br />- Invoices with a total purchase price of 2,000,000 (VND) and purchasing at least 1 Mango Bag product will receive 2 free Women's Accessories. <br/>- Purchases of at least 3 Mango Bag products will receive 2 free Women's Accessories.",
                promotionAllProductDiscountDesc: "For example: <br />- Invoices with a total amount of goods from 2,000,000 (VND) and purchase at least 1 product of Mango Bag will receive 50% discount on 2 products of Women's Accessories. <br/>- Purchases of at least 3 Mango Bag products will receive a discount of 50,000 (VND) on 2 Women's Accessories products.",
                warning: "Warning",
                ban: "Block",
                stocking: "In stock",
                productOutOfStock: "Out of stock",
                zeroPercent: "Not taxable (0%)",
                fivePercent: "5%",
                eightPercent: "8%",
                tenPercent: "10%",
                removed: "Deleted",
                delivering: "Delivering",
                waitingForProgressing: "Waiting for progressing",
                selectTypePaper: "Select the type of paper to print the code",
                previewTemplate: "See print",
                multipleOptions: "Many choices",
                receiver: "Receiver",
                accountant: "Accountant",

                // Oce từ bên trên


                selectProductDiscount: "Choose discounted items",
                whenBuy: "When buying",
                readyToShip: "Ready to ship",
                process: "Handle",
                transport: "Transport",
                inCancel: "Canceling",
                waitingInvoice: "Waiting for invoice",
                onHold: "Pause",
                waitingShip: "Waiting for shipment",
                waitingCollection: "Waiting for collection",
                partiallyShipping: "Partial delivery",
                delivered: "Delivered",
                queueing: "Waiting for seller confirmation",
                successfulDelivery: "Successful delivery",
                processing: "Processing",
                waitingPayment: "Wait for pay",
                handoverToPartner: "The order has been delivered to the delivery partner",
                closed: "Order closed",
                packaging: "Order is being packed",
                picking: "Receiving goods",
                holded: "Orders are being held to handle arising issues",
                finishedPacking: "Order has been packed",
                printTemplateName: "Print model name",
                suggestPrintTemplate: "Suggested print samples",
                previewPrintTemplate: "Preview the print sample",
                titleAddPrintTemplate: "Add new print template",
                titleUpdatePrintTemplate: "Update print template",
                addPrintTemplateSuccess: "Newly printed sample was successful",
                addPrintTemplateFailed: "The new printing sample failed",
                emptyPrintTemplateName: "Please enter the model name",
                maxPrintTemplates: "This print cannot be added! The system only supports a maximum of 3 print templates",
                updatePaper: "Edit print paper size",
                addPaper: "Add new paper size",
                enterPrintTemplateName: "Enter the print template name",
                emptyPaper: "Please enter the name of the paper size",
                removePrintTemplate: "Delete print template",
                confirmRemovePrintTemplate: "Do you want to delete this print?",
                branchRequest: "Request branch",
                voucherSelected: "voucher is selected",
                paymentSchedule: "Payment schedule",
                noSetup: "Not yet set up",
                connected: "Connected",
                removeLink: "Remove link",
                uploadImage: "Upload {type}",
                sendZaloSuccess: "Sending zalo successfully",
                sendZaloFailed: "Sending zalo failed",
                numberCouponSelected: "There are {number} coupons selected",
                numberVoucherSelected: "There are {number} vouchers selected",
                numberRecordSelected: "There are {number} records selected",
                discountCouponValue: "Coupon code {code}: {value} discount ({percent}% discount on invoice)",
                monday: "Mon",
                tuesday: "Tue",
                wednesday: "Wed",
                thursday: "Thu",
                friday: "Fri",
                saturday: "Sat",
                sunday: "Sun",
                searchDeliveryStatus: "Search delivery status",
                transactionOccurred: "Transaction occurred",
                noData: "No data",
                selectCreatorApplied: "Select creator applied",
                messageImportSuccess: "Import file successfully",
                selectVouchers: "Select the issuance batch...",
                donateVoucher: "Donate vouchers from the issuance batch",
                promotionInvoiceDonateVoucherDesc: "Example: <br/>- Invoices with a total amount of 500,000 or more will be awarded 1 voucher",
                promotionProductDonateVoucherDesc: "When purchasing the required quantity of products, a voucher will be awarded",
                promotionProductDonateVoucherNote: "The number of vouchers does not multiply based on the quantity purchased.",
                maxVoucherDetails: "You can only receive a maximum of {number} vouchers",
                enterVoucherCode: "Enter voucher code",
                giftVoucher: "Gift voucher"

            },
            permissions: {   
                titleUpdate: "Notification",
                contentUpdate: "If user {name} is using the system, he will be logged out immediately to update his role. Do you want to continue making updates?",
                messageSuccess: "Delete permissions successfully",
                messageError: "Deletion of permissions failed",
                modal: {
                    titleEditRole: "Edit roles",
                    save: "Save",
                    cancel: "Skip",
                    delete: "Delete",
                }
            },
            InputQuickSearch:{
                placeholder: {
                    request_refund_code: "By return request code",
                    settlement_advance_code: "By advance request code",
                    transfer_position_code: "By transfer code",
                    import_position_code: "By location import code",
                    export_position_code: "By location remove code",
                    original_documents_code: "By original documents",
                    original_documents_type: "By document type",
                    coupon_voucher_code_name: "By code, release name",
                    coupon_code: "By coupon code",
                    voucher_code: "By voucher code",
                    returns_code_return_search: "By return code",
                    returns_code_refund_search: "By the returned code",
                    order_code: "By order code",
                    code_transfer: "By the transfer code",
                    code_request: "By request code",
                    product_code: "By product code",
                    product_name: "By product name",
                    code_name_phone: "By customer code, name, phone number",
                    username: "Username",
                    phone: "By phone number",
                    email: "By email",
                    return_purchase_order: "By the return code",
                    order_supplier: "By purchase order code",
                    purchase_order: "By purchase receipt code",
                    product_code_name: "By code, product name",
                    supplier_code_name: "By code, supplier name",
                    code_invoice: "By invoice code",
                    delivery_code: "By bill of lading code",
                    note: "By note",
                    note_product: "By products notes",
                    note_description: "By notes, describe the goods",
                    invoice_supplier_code: "By invoice number", 
                    product_omipos: "Enter product code and name (OmiPOS)",
                    product_medicine: "Enter drug code and name (Department of Pharmacy)",
                    control_history: "By reconciliation code",
                    note_delivery: "By delivery notes",
                    inventory_plan_code: "By name or detailed estimate code",
                    plan_purchase_code: "By code, the name of inventory forecasting",
                    discount_bas_code: "By BAS code",
                },
                button: {
                    extend: "Extend",
                    collapse: "Collapse",
                    search: "Search"
                },
                title: {
                    code_transfer: "Transfer code",
                    code_request: "Request code",
                    product_name: "Product name",
                    product_code: "Product code",
                    order_supplier: "Purchase order code",
                    purchase_order: "Purchase receipt code",
                    return_purchase_order: "Return code",
                    product_code_name: "Products",
                    supplier_code_name: "Supplier",
                    note: "Note",
                    note_product: "Product notes",
                    username: "User",
                    phone: "Phone number",
                    email: "Email",
                    note_description: "Notes - description",
                    code_name_phone: "Customer",
                    code_invoice: "Invoice Code",
                    order_code: "Order code",
                    delivery_code: "Bill of lading code",
                    returns_code: "Return code",
                    coupon_voucher_code_name: "Release batch",
                    coupon_code: "Coupon code",
                    voucher_code: "Voucher code",
                    keyword: "Code",
                    import_position_code: "By location import code",
                    export_position_code: "By location remove code",
                    original_documents_code: "By original documents",
                    original_documents_type: "By document type",
                    transfer_position_code: "Transfer code",
                    request_refund_code: "By return request code",
                    settlement_advance_code: "By advance request code",
                    invoice_supplier_code: "Invoice Code",
                    cash_book_code: "Cash code",
                    bank_book_code: "Bank code",
                    medicine_name: "Drug name",
                    medicine_number: "Registration number",
                    medicine_code: "Drug code",
                    medicine_content: "Content",
                    medicine_country: "Producer",
                    product_omipos: "Products name",
                    product_medicine: "Drug code",
                    transporter_code: "Partner code",
                    control_history: "Reconciliation code",
                    note_delivery: "Note",
                    inventory_plan_code: "Detailed Estimate Code",
                    plan_purchase_code: "Code planned to import and purchase",
                    discount_bas_code: "BAS code"
                }
            },
            LoginScreen: {
                title: "OmiPos Login",
                header: "Login",
                remember: "Memorize",
                submit: "Login",
                password: "Password",
                username: "Username"
            },
            Pagination: {
                show: "Display ",
                total: "Total",
                record: "Records",
            },
            SelectDateTime: {
                otherOption: "Another choice",
            },
            DateRangePicker: {
                byDay: "By date",
                byWeek: "By week",
                byMonth: "Monthly",
                byQuarter: "Quarterly",
                byYear: "By year",
                today: "Today",
                yesterday: "Yesterday",
                thisWeek: "This week",
                lastWeek: "Last week",
                twoDay: "Last 2 days",
                threeDay: "Last 3 days",
                sevenDay: "Last 7 days",
                month: "This month",
                lastMonth: "Last month",
                thirtyDay: "Last 30 days",
                quarter: "This quarter",
                lastQuarter: "Last quarter",
                year: "This year",
                lastYear: "Last year",
                all: "All the time"
            },
            ImportExcel: {
                title: "Add products from excel file",
                defaultFile: "Download sample file: ",
                file: "Template files",
                chooseFile: "Select data file",
                action: "Upload",
                orderSupplierFile: "Import order file",
                purchaseOrderFile: "Import file",
            },
            ProductUnitSearch: {
                orderFromCustomer: "Customer order",
                orderSupplier: "Supplier order",
                price: "Price",
                inventory: "Inventory",
            },

        /* Dashboard */

            DateRangerDashboardPicker: {
                today: "Today",
                last_day: "Yesterday",
                seven_day: "Last 7 days",
                month: "This month",
                last_month: "Last month",
            },
            RevenueFromBranch: {
                title: "NET REVENUE BY BRANCH {label}",
            },
            RevenueOriginal: {
                title: "NET REVENUE",
                dayOfWeek: 'By day',
                day: 'By date',
                hour: 'By hourly',
            },
            StatisticalInvoice: {
                title: "TODAY'S SALES RESULTS",
                revenue: "Revenue",
                invoice: "Invoice",
                promissory: 'Invoice',
                return: "Returns",
                compared_to_yesterday: "Compared to yesterday",
                compared_same_period_last_month: "Compared to the same period last month",
            },
            notice: {
                title_notice: "NOTIFICATION",
                title_action: "RECENT ACTIVITIES",
                action_by_user: "Just created a order",
                value: "with value",
                content_transfer: 'from {fromBranch} to {toBranch}',
                have: "Have",
                expired: "is about to expire",
                three_month: "in 3 months",
                seven_day: "in this week",
                product: "items",
                voucher: "voucher issuance",
                coupon: "coupon issuance",
                promotion: "promotional program",
                noticeForProduct: 'There are <span class="text-primary">{number} items</span> nearing expiration <span class="text-primary">within 3 months</span>',
                noticeForCoupon: 'There are <span class="text-primary">{number} coupon issuances</span> nearing expiration',
                noticeForVoucher: 'There are <span class="text-primary">{number} voucher issuances</span> nearing expiration',
                noticeForPromotion: 'There are <span class="text-primary">{number} promotion programs</span> nearing expiration',
                noticeActionInvoice: '<a class="text-primary text-decoration-none" target="_blank" href="{href}">{employee}</a> just created the <a class="text-primary text-decoration-none" target="_blank" href="{hrefAction}">{action}</a> <span class="{showBranch}">from {branchNameSend} to {branchNameReceive}</span> <span class="{showAmount}">with a value of <span class="text-bold">{amount}</span></span>',
            },
            noticeDetail: {
                time: "From date {from} to date {to}",
                branch: "Branch: {branch}",
                title_product: "LIST OF PRODUCTS EXPIRING IN THE NEXT 3 MONTHS",
                title_voucher: "VOUCHER LIST EXPIRES IN THE NEXT 7 DAYS",
                title_coupon: "COUPON LIST EXPIRES IN THE NEXT 7 DAYS",
                title_promotion: "LIST OF PROMOTION PROGRAMS EXPIRATION IN THE NEXT 7 DAYS",
                max_value: "max",
                tables: {
                    product: {
                        code: 'Product code',
                        name: 'Product name',
                        consignment: 'Lot',
                        expired: 'Expiry',
                        daysLeft: 'Number of days remaining',
                    },
                    voucher: {
                        code: 'Voucher code',
                        release_code: 'Voucher issue code',
                        amount: 'Par value',
                        release_date: 'Effective from',
                        expired_date: 'Expiry date',
                    },
                    coupon: {
                        code: 'Coupon code',
                        release_code: 'Coupon issue code',
                        amount: 'Par value',
                        release_date: 'Effective from',
                        expired_date: 'Expiry date',
                    },
                    promotion: {
                        code: 'Promotion code',
                        form: 'Promotion method',
                        release_date: 'From date',
                        expired_date: 'To',
                    },
                }
            },
            ProductStatistical: {
                title: 'TOP 10 BEST-SELLING PRODUCTS THIS MONTH',
            },

        /* Hết dashboard */


        /* Danh mục */

            // Danh sách hàng hóa
            ProductIndex: {
                title: "Products",
                document_title: "Products | OmiPos",
                product_type: "Category",
                categories: "Group of products",
                all: "All",
                created_at: "Created date",
                choose_show: "Display options",
                placeholder: {
                    categories: "Search for product groups",
                    search: "By code, product name",
                    branch: "Search branch name",
                    batch: "By batch and expiration date"
                },
                info: "Information",
                maxInventory: "Inventory norms",
                stockCard: "Warehouse card",
                inventory: "Inventory",
                batch: "Lot date",
                combo: "Ingredient",
                code: "Product code",
                price: "Price",
                weight: "Weight (Gram)",
                location: "Location",
                manufacturer: "Manufacturing country",
                country: "Country",
                unit: "Packing",
                description: "Describe",
                note: "Note",
                supplier: "Supplier",
                status: "Status",
                quantity: "Quantity",
                no_field: "There is no display field",
                branch: "Branch",
                component_product: "Component goods",
                name: "Product's name",
                reason: "Reason",
                active: "Active products",
                inActive: "Product stopped trading",
                table:{
                    stockCard: {
                        code: "Document",
                        time: "Time",
                        type: "Transaction type",
                        price: "Transaction prices",
                        price_tooltip_1: "Transaction price is the price of the goods on the transaction (invoice, return, import and return). To view transaction prices, you need to be authorized to view the import price and cost price of the goods.",
                        price_tooltip_2: "The transaction price will be reallocated when costs or discounts are incurred associated with that transaction.",
                        last_inventory: "Ending inventory",
                        transporter: "Partner"
                    },
                    maxInventory: {
                        on_show_quantity: "Number of exhibits",
                        min_inventory: "Inventory Min",
                        max_inventory: "Inventory Max",
                        value: "Value",
                    },
                    inventory: {
                        quantity_order: "Supplier order quantity",
                        customer_order: "Customer order quantity",
                        expected_out_of_stock: "Expected out of stock",
                    },
                    batch: {
                        batch_number: "Lot number",
                        expired: "Expiry",
                    },
                    combo: {
                        STT: "No.",
                        component_row_name: "Product component name"
                    }
                },
                button: {
                    save: "Save",
                    export_excel: "Export excel",
                    export_file: "Export file",
                    add: "Add",
                    add_product: "More manual products",
                    upload: "File Upload",
                    option: "Option",
                    update: "Update",
                    copy: "Copy",
                    print: "Print code stamp",
                    in_active: "Stop business",
                    active: "Business allowed",
                    remove: "Delete",
                    skip: "Skip",
                    fix: "Update",
                    purchase_product: "Purchase receipt",
                    order_supplier: "Purchase order",
                    add_order: "Add order",
                    add_invoice: "Add invoice",
                    inventory_plan: "Detailed estimate",
                    confirm: "Confirm",
                    close: "Close",
                    agree: "Appove"
                }, 
                alerts: {
                    valid_max_inventory: "Max inventory cannot be less than or equal to min inventory",
                },
                modal: {
                    title_create: "Add products",
                    title_update: "Update products",
                    title_update_multiple: "Bulk update of products",
                    title_create_combo: "Add atrribute products",
                    title_remove: "Delete products",
                    title_remove_multiple: "Delete multiple products",
                    content_remove_multiple: "Are you sure you want to remove {number} products from your Shopping List?",
                    content_remove: "The system will completely delete {name} productsacross the entire branch.",
                    content_remove_confirm: "Are you sure you want to delete?",
                    product_name: "Commodity {name}",
                    have_transaction: "business has arisen",
                    note_remove: "(Note: The system only deletes products that have not yet had any transactions)",
                    product_manage: "Commodity management: ",
                    search: "Search",
                    button: {
                        save: "Save",
                        skip: "Skip",
                    },
                    confirm_remove_attribute: "Do you want to delete the attribute?",
                    least_one_unit: "Please add at least 1 unit of the products",
                    choose_enough_unit: "Please select enough units",
                    choose_enter_unit: "Please enter enough units",
                    enter_price: "Please enter the product's selling price",
                    duplicate_unit: "Unit {name} is duplicated",
                    max_length: "The length limit is 255 characters. Please re-enter.",
                    confirm_remove_product: "Delete product: {name} ?",
                    processed_quantity: "Number of categories processed: ",
                    removed_quantity: "Number of categories successfully deleted: ",
                    not_remove_quantity: "Number of categories that have not been deleted: ",
                    categories_have_transaction: "List of transactions that have occurred",
                    remove_success: "All selected products have been deleted",
                    not_remove: ". You cannot delete this product!",
                    reason: "The system only deletes products that have not yet generated any transactions",
                }
            },
            ModalFileUploadProducts:{
                title: "Import products from data file",
                titleTemplate: "Download sample file: ",
                titleExcel: "Excel files",
                notifyRow1: "The system allows importing up to 5000 products at a time from the file.",
                notifyRow2: "Product codes do not contain special characters (@, #, $, *, /, ...) and accented letters",
                titleActionExistCode: "Handling duplicate product codes",
                titleErrorStop: "Report errors and stop importing when product codes overlap",
                titleCreateProductNew: "Add new products",
                titleUpdateProduct: "Update products information",
                updateCreateProduct: "Update and add new products",
                titleUpdateInventory: "Update inventory",
                titleUpdateInventoryYes: "Have",
                titleUpdateInventoryNo: "Are not",
                titleStatusAppliesAbove: "Business status applies above",
                notifyRow3: "The business status in the import file only applies to selected branches.",
                notifyRow4: "Note that when adding new products , branches that do not select will have the opposite status",
                titleAllBranch: "The whole system",
                titleSelectBranch: "Branch",
                titlePlaceHolderSelectBranch: "Select the applicable branch",
            },
            ModalProductFormTabMain: {
                code: "Product code",
                name: "Product name",
                category: "Group of products",
                type: "Type",
                hs_code: "HS code",
                tax_rate: "Tax",
                image: "Product image",
                batch: "Lot, expiration date",
                medicine: "Medicine",
                medicine_name: "Drug name",
                medicine_code: "Drug code",
                medicine_short_name: "Abbreviations",
                medicine_road_for_use: "Route of use",
                ingredient: "Ingredient",
                manufacturer: "Manufacturer",
                country: "Manufacturing country",
                packing: "Packing",
                description: "Describe",
                branch: "Business warehouse",
                register_number: "Registration number",
                content: "Content",
                unit: "Unit of product",
                auto_price: "Calculate the price automatically based on the converted value",
                attribute: "Attribute",
                listVariant: "List of  products of the same type",
                lifeTime: "Number of days of use",
                table: {
                    stt: "No.",
                    unit_name: "Unit name",
                    conversion_value: "Conversion value",
                    conversion_calculation: "Conversion calculation",
                    price: "Price",
                    unit_main: "Main unit",
                    unit_conversion: "Conversion Unit",
                    placeholder: {
                        unit_name: "Select unit",
                        price: "Please enter selling price",
                    }
                },
                button:{
                    remove: "Delete",
                    add_unit: "Add units",
                    multiplication: "Multiplication",
                    division: "Division",
                    attribute_plus: "Add properties",
                },
                placeholder: {
                    code: "Automatic product code",
                    name: "Enter the product name",
                    category: "Group of goods",
                    type: "Select product type",
                    hs_code: "Enter HS Code",
                    tax_rate: "Select export tax on goods",
                    image: "Image product",
                    batch: "Lot, expiration date",
                    medicine: "Medicine",
                    medicine_name: "Enter the drug name",
                    medicine_code: "Drug code",
                    medicine_short_name: "Enter an abbreviation",
                    medicine_road_for_use: "Choose the route of use",
                    ingredient: "Enter active ingredients of products",
                    manufacturer: "Enter the manufacturer of the products",
                    country: "Enter the country of production of products",
                    packing: "Enter the products' packaging specifications",
                    description: " Enter a description of the products",
                    branch: "Choose a business warehouse",
                    attribute: "Select properties",
                    lifeTime: "Enter the number of days the products will be used",
                }
            },
            ModalCreateRouteOfUse: {
                title_create: "Add route of use",
                title_update: "Update route of use",
                name: "Route name used",
                placeholder: {
                    name: "Enter the route of use",
                },
                button: {
                    create: "Add",
                    update: "Update",
                    skip: "Skip",
                    remove: "Delete"
                },
                alert: {
                    delete_success: "Deleted successfully",
                    delete_error: "Delete failed",
    
                    create_success: "Added successfully",
                    create_error: "New add failed",
                    update_success: "Update successful",
                    update_error: "Update failed"
                }
            },

            // Nhóm hàng hóa
            CategoriesIndex: {
                title: "Products group",
                document_title: "List of products | OmiPos",
                placeholder: {
                    search: "Search",
                },
                button: {
                    add: "Add",
                },
                modal:{
                    title_create: "Add product groups",
                    title_update: "Edit product groups",
                    title_remove: "Delete products group",
                    name: "Group name",
                    parent_category: "Parents' group",
                    content_remove: "Are you sure you want to delete this group?",
                    contentCancelUpdate: "Do you want to cancel the products group update?",
                    contentCancelCreate: "Do you want to cancel more product groups?",
                    placeholder: {
                        name: "Enter the product group name",
                        parent_category: "Select the parent group",
                    },
                    button: {
                        add: "Add new",
                        update: "Update",
                        skip: "Skip",
                        remove: "Delete",
                        confirm: "Approve"
                    }
                }
            },
            ModalFileUploadCategories:{
                title: "Add products groups from data file",
                titleTemplate: "Download sample file: ",
                titleExcel: "Excel files",
                notifyRow1: "The system allows importing up to 5000 records at a time from the file.",
                notifyRow2: "Product codes do not contain special characters (@, #, $, *, /, ...) and accented letters",
                titleActionExistCode: "Handling groups of products",
                titleErrorStop: "Report errors and stop importing when there are new and duplicate product groups",
                titleCreateCategoryNew: "Add new product group",
                titleUpdateProduct: "Update products information",
                updateCreateProduct: "Update and add new products",
                titleUpdateInventory: "Update inventory",
                titleUpdateInventoryYes: "Have",
                titleUpdateInventoryNo: "Are not",
                titleStatusAppliesAbove: "Business status applies above",
                notifyRow3: "The business status in the import file only applies to selected branches.",
                notifyRow4: "Note that when adding new products , branches that do not select will have the opposite status",
                titleAllBranch: "The whole system",
                titleSelectBranch: "Branch",
                titlePlaceHolderSelectBranch: "Select the applicable branch",
            },

            // Đơn vị hàng hóa
            UnitIndex: {
                title: "Products unit",
                document_title: "Unit of products | OmiPos",
                placeholder: {
                    search: "Search",
                },
                button: {
                    add: "Add",
                },
                modal:{
                    title_remove: "Delete unit of products",
                    content_remove: "Are you sure you want to delete this unit?",
                    button: {
                        skip: "Skip",
                        confirm: "Approve"
                    }
                }
            },
            modalCreateUpdateUnit: {                
                title_create: "Add products units",
                title_update: "Edit products units",
                title_confirm: "Confirm",
                unit_name: "Unit name",
                message_confirm_create: "Do you want to cancel adding units?",
                message_confirm_update: "Do you want to cancel the unit update?",
                placeholder: {
                    name: "Unit name"
                },
            },

            // Hàng hóa liên kết
            medicineProductMapping: {
                title: "Linked products",
                title_document: "Linked products | OmiPos",
                placeholder: {
                    search: "By drug name",
                    chooseCategories: "Select product group"
                },
                filter: {
                    title: {
                        category: "Group of products",
                        status: "Status"
                    }
                },
                message_connect_success: "Successful drug connection",
                message_connect_error: "No linked products found",
                message_disconnect_success: "Disconnect successfully",
                err: "Server error",
                all: "All",
                linked: "Linked",
                noLink: "Not linked yet",
                productOmipos: "Products on Omipos",
                medicineCode: "National pharmaceutical drug code",
                removeLinked: "Disconnect",
            },

            // Danh mục thuốc
            medicine: {
                title: "Drug list",
                title_index: "Link to Drug List",
                title_document: "Drug list | OmiPos",
                import: "Import",
                export: "Export file",
                placeholder: {
                    search: "By drug name",
                    number: "By registration number",
                    code: "By drug code",
                    content: "By active ingredients and content",
                    country: "By manufacturer, country of manufacture"
                },
                modal: {
                    title: "Import national pharmaceutical drug list from data file",
                    button: "Select data file"
                }
            },
            MedicineSearch: {
                placeholder: {
                    name: "Enter the drug name"
                },
                table: {
                    name: "Drug name",
                    registerNumber: "Registration number",
                    ingredient: "Active",
                    content: "Content",
                    packing: "Packing",
                    manufacturer: "Manufacturer",
                }
            },

            // Danh sách khách hàng
            customerIndex: {
                title: "Customer",
                documentTitle: "Customer Management | OMIPOS",
                customerGroup: "Customer group",
                createdAt: "Created date",
                createdBy: "Created by",
                birthDate: "Birthday",
                lastTransactionDate: "Last trading day",
                totalSale: "Total sale",
                type: "Customer type",
                all: "All",
                individual: "Individual",
                company: "Company",
                gender: "Sex",
                male: "Male",
                female: "Female",
                deliveryArea: "Delivery area",
                status: "Status",
                active: "Active",
                inActive: "Inactive",
                reActive: "Active",
                quantity: "Quantity",
                discount: "Discount",
                note: "Note",
                code: "Customer's code",
                name: "Customer name",
                birthDay: "Date of birth",
                companyName: "Company name",
                taxCode: "Tax code",
                email: "Email",
                facebook: "Facebook",
                accountNumber: "Account number",
                accountOwner: "Account owner",
                bankName: "Bank name",
                bankAddress: "Bank branch",
                phone: "Phone number",
                address: "Address",
                ward: "Wards",
                debtDate: "Debt term",
                employee: "Employee in charge",
                addressName: "Address name",
                receiverName: "Recipient's name",
                receiverPhone: "Receiving phone number",
                receiverAddress: "Receiving address",
                orderCode: "Order code",
                time: "Time",
                sellerName: "Seller",
                branch: "Branch",
                total: "Total",
                invoiceCode: "Code Bill",
                confirm: "Confirm",
                contentInActive: "Are you sure you want to deactivate this customer? This customer's transaction information will still be kept.",
                contentReActive: "Are you sure you want to allow this customer to become active again?",
                contentConfirmSkip: "Are you sure you want to cancel changes?",
                contentConfirm: "You definitely want to cancel editing change?",
                change: "Change",
                branchActive: "Branch active",
                button: {
                    add: "Add",
                    addCustomer: "Customer",
                    file: "File",
                    import: "Import",
                    export: "Export file",
                    update: "Update",
                    remove: "Delete",
                    new: "Add new",
                    agree: "Approve",
                    skip: "Skip",
                    action: "Execute",
                },
                placeholder:{
                    search: "Search",
                    searchCustomer: "By code, name, phone",
                    chooseArea: "Select province/city - District"
                },
                tabs: {
                    info: "Information",
                    address: "Delivery address",
                    historyOrder: "Order history",
                    historySale: "Sales/return history"
                },
                values: {
                    info: "EN_info",
                    address: "EN_addressGetOrder",
                    historyOrder: "EN_historyOrder",
                    historySale: "EN_historySale"
                },
                modal: {
                    titleCreate: "Add new customers",
                    titleUpdate: "Update customers",
                    branch: "Branch created",
                    customerCode: "Customer's code",
                    customerName: "Customer name",
                    customerPhoneNumber: "Phone number",
                    customerBirthDate: "Date of birth",
                    customerGender: "Sex",
                    customerAddress: "Address",
                    customerAccountNumber: "Account number",
                    customerAccountName: "Account owner",
                    customerBankName: "Bank name",
                    customerBankAddress: "Bank branch",
                    customerType: "Type",
                    personal: "Individual",
                    company: "Company",
                    customerCompanyName: "Company name",
                    customerTaxCode: "Tax code",
                    customerEmail: "Email",
                    customerFacebook: "Facebook",
                    customerGroup: "Group",
                    customerNote: "Note",
                    customerDebtDate: "Debt term",
                    user: "Officer in charge",
                    paymentTerms: "Terms of payment",
                    maxDebtNumber: "Maximum debt amount",
                    isSupplier: "Also supplier",
                    button: {
                        save: "Save",
                        skip: "Skip"
                    },
                    defaultCode: "Default code",
                    enterCustomerName: "Enter the customer's name",
                    enterPhone1: "Enter phone number 1",
                    enterPhone2: "Enter phone number 2",
                    enterAddress: "Enter the address",
                    enterAccountNumber: "Enter account number",
                    enterAccountOwner: "Enter the account owner's name",
                    enterBankName: "Enter the bank name",
                    enterBankAddress: "Enter the bank branch name",
                    enterCompanyName: "Enter the company name",
                    enterTaxCode: "Enter tax code",
                    enterEmail: "Enter email",
                    enterFacebook: "Enter facebook",
                    chooseCustomerGroup: "Select customer group",
                    enterNote: "Enter notes",
                    enterDebtDate: "Enter the number of days owed",
                    choosePaymentDate: "Choose a recurring payment date",
                    enterEmployee: "Officer in charge",
                    enterPaymentCondition: "Enter terms of payment",
                    enterMaxDebt: "Enter the maximum debt amount",
                    notFoundInfoTax: "No data found on the tax department",
                    titleImport: "Import customers from data file",
                    downloadDefaultFile: "Download sample file: ",
                    excelFile: "Excel files",
                    allowUpdateWhenDuplicateCode: "Allows updating information when the customer code already exists on the system.",
                    allowDuplicateEmail: "Allow customers to duplicate emails",
                    chooseFile: "Select data file",
                    inValidFormatFile: "The uploaded file must be in the correct xlsx, xls format",
                    titleRemove: "Delete customer",
                    contentRemove: "The system will completely delete customer {name} but still keep historical transactions, if any. Are you sure you want to delete?",
                    alertRemoveSuccess: "Successfully deleted customer {name}"
                }
    
            },

            // Nhóm khách hàng
            customerGroupIndex: {
                title: "Customer group",
                documentTitle: "List of customer groups",
                quantity: "Quantity",
                discount: "Discount",
                note: "Note",
                button: {
                    add: "Add",
                    save: "Save",
                    skip: "Skip",
                    remove: "Delete",
                    agree: "Agree",
                },
                placeholder:{
                    search: "Search",
                },
                titleCreate: "Add customer groups",
                titleUpdate: "Edit customer group",
                info: "Information",
                advanceSetting: "Advanced settings",
                name: "Group name",
                enterName: "Enter the customer group name",
                chooseDiscount: "Select discount",
                vnd: "VND",
                percent: "%",
                settingConditional: "Set conditions for adding customers to the group",
                add: "Add customers to the condition group.",
                update: "Update the list according to conditions.",
                notUpdate: "Do not update customer list.",
                confirm: "Confirm",
                confirmContent: "Do you want to cancel adding a new customer group?",
                contentSkip: "Are you sure you want to cancel editing this customer group?",
                alertSuccess: "Successfully added new customer group",
                alertCreateSuccess: "Added successfully",
                alertUpdateSuccess: "Update customer group successfully",
                titleRemove: "Delete customer group",
                contentRemove: "Are you sure you want to delete this customer group?",
                description: "Description",
            },

            // Danh sách nhà cung cấp
            supplierIndex: {
                title: "Supplier",
                documentTitle: "Supplier Management | OMIPOS",
                quantity: "Quantity",
                discount: "Discount",
                note: "Note",
                supplierGroup: "Supplier group",
                allGroup: "All groups",
                totalBuy: "Total purchase",
                status: "Status",
                all: "All",
                active: "Active",
                inActive: "Inactive",
                reActive: "Active",
                searchSupplier: "Supplier code",
                code: "Supplier code",
                name: "Supplier name",
                address: "Address",
                deliveryArea: "Delivery area",
                ward: "Wards",
                phone: "Phone",
                email: "Email",
                accountNumber: "Account number",
                accountOwner: "Account owner",
                bankName: "Bank name",
                bankAddress: "Bank branch",
                company: "Company",
                taxCode: "Tax code",
                createdBy: "Created by",
                createdAt: "Created date",
                debtDate: "Debt term",
                employee: "Employee in charge",
                numberSKU: "SKU quantity receipted from supplier",
                amountImport: "Imported sales",
                openingFee: "Fee to open supplier code",
                paymentDiscount: "Payment discount",
                confirm: "Confirm",
                contentConfirmCancelCreate: "Do you want to cancel more providers?",
                contentConfirmCancelUpdate: "Do you want to cancel the carrier update?",
                button: {
                    add: "Add",
                    addSupplier: "Supplier",
                    update: "Update",
                    remove: "Delete",
                },
                placeholder:{
                    search: "Search",
                },
                tabs: {
                    info: "Information",
                    paymentForSupplier: "Debt needs to be paid to suppliers",
                    historyOrder: "Order history imported",
                    historyPurchaseReturn: "Import/return history"
                },
                values: {
                    info: "Code",
                    address: "EN_addressGetOrder",
                    historyOrder: "EN_historyOrder",
                },
                table: {
                    code: "Code",
                    time: "Time",
                    createBy: "Created by",
                    branch: "Branch",
                    total: "Total",
                    status: "Status"
                },
                modal: {
                    titleCreate: "Add supplier",
                    titleUpdate: "Edit supplier",
                    branch: "Branch",
                    supplierCode: "Supplier code",
                    supplierName: "Supplier name",
                    supplierPhoneNumber: "Phone number",
                    supplierBirthDate: "Date of birth",
                    supplierGender: "Sex",
                    supplierAddress: "Address",
                    supplierProvince: "Province/City",
                    supplierDistrict: "District",
                    supplierWard: "Wards",
                    supplierAccountNumber: "Account number",
                    supplierAccountName: "Account owner",
                    supplierBankName: "Bank name",
                    supplierBankAddress: "Bank branch",
                    supplierType: "Type",
                    personal: "Individual",
                    company: "Company",
                    supplierCompanyName: "Company name",
                    supplierTaxCode: "Tax code",
                    supplierEmail: "Email",
                    supplierFacebook: "Facebook",
                    supplierGroup: "Supplier group",
                    supplierNote: "Note",
                    supplierDebtDate: "Debt term",
                    user: "Employee in charge",
                    paymentTerms: "Terms of payment",
                    maxDebtNumber: "Maximum debt amount",
                    isCustomer: "Also customer",
                    openingFee: "Fee to open supplier code",
                    paymentDiscount: "Payment discount",
                    enterName: "Enter the supplier name",
                    enterPhone: "Enter partner phone number",
                    enterAddress: "Enter the address",
                    chooseProvince: "Select province/city",
                    chooseDistrict: "Select district",
                    chooseWard: "Select ward/commune",
                    enterAccountNumber: "Enter account number",
                    enterAccountOwner: "Enter the account owner's name",
                    enterBankName: "Enter the bank name",
                    enterBankAddress: "Enter the bank branch name",
                    enterEmail: "Enter email",
                    enterCompanyName: "Enter company",
                    enterTaxCode: "Enter tax code",
                    chooseSupplierGroup: "Select supplier group",
                    enterNote: "Enter notes",
                    enterDebtDate: "Enter the number of days owed",
                    choosePaymentDate: "Choose a recurring payment date",
                    enterPaymentTerm: "Enter payment terms",
                    enterMaxDebt: "Enter the maximum debt amount",
                    tooltip1: "The system automatically checks and links in case the customer already exists",
                    tooltip2: "In case a customer does not exist, the system automatically creates a new customer",
                    titleUpload: "Enter the data file provider",
                    replaceAndUpdate: "Replace and update information when supplier codes overlap",
                    notReplaceAndUpdate: "Replacement and updating of information is not allowed when the supplier code is duplicated",
                    chooseFile: "Select data file",
                    titleRemove: "Delete provider",
                    contentRemove: "The system will completely delete the Supplier but still keep historical transactions, if any. Are you sure you want to delete?",
                    button: {
                        save: "Save",
                        skip: "Skip",
                        action: "Execute"
                    }
                }
            },

            // Nhóm nhà cung cấp
            SupplierGroupIndex: {
                title: "Supplier group",
                documentTitle: "Supplier group list | OMIPOS",
                search: "Search",
                add: "Add",
                titleCreate: "Add a supplier group",
                name: "Group name",
                enterName: "Enter the supplier group name",
                note: "Note",
                confirm: "Confirm",
                confirmCancelChange: "Do you want to cancel additional supplier groups?",
                titleCancelEdit: "Cancel updating the supplier group",
                contentCancelEdit: "Do you want to unupdate the supplier group?",
                titleUpdate: "Update supplier group",
                titleRemove: "Delete the supplier group",
                contentRemove: "Are you sure you want to delete this supplier group?",
                contentInActive: "Are you sure you want to stop this supplier? This supplier's transaction information will still be kept.",
                contentReActive: "Are you sure you want to allow this supplier to operate again?",
                quantity: "Quantity",
            },

            // Danh sách nhân viên
            employeeIndex: {
                title: "List of employee",
                employee_code: "Employee code",
                employee_name: "Employee's name",
                employee_birthday: "Date of birth",
                employee_gender: "Sex",
                employee_department: "Department",
                employee_job_title: "Title",
                employee_branch_work: "Working branch",
                employee_user_name: "User name",
                employee_phone: "Phone number",
                employee_email: "Email",
                employee_status: "Status",
                employee_created_at: "Creation time",
                note: "Note...",
                chooseBranch: "Select branch",
                chooseJobTitle: "Choose a job title",
                chooseDepartment: "Select department",
                chooseRole: "Choose a role",
                tab: {
                    info: "Information"
                },
                button: {
                    update: "Update",
                    inActive: "Inactive",
                    active: "Active",
                    remove: "Delete employee",
                    add: "Employee",
                    file: "File",
                    import: "Import",
                    export: "Export file",
                    skip: "Skip",
                    agree: "Approve",
                },
                input:{
                    filter: "By code, name, phone number",
                },
                titlRemove: "Delete employee",
                contentRemove: "The system will completely delete this employee but still keep the salary slips, if any. Are you sure you want to delete?",
                alertRemoveSuccess: "Successfully deleted employee {name}.",                
                alertRemoveError: "Deleting employee {name} failed.",
                confirmReActive: "Do you want this employee to work again?",
                confirmInActive: "Are you sure you want to stop this employee?",
                alertUpdateSuccess: "User updated successfully",            
            },
            modalCreateEmployee: {
                title_create: "Add new employee",
                title_update: "Update employee",
                code: "Employee code",
                name: "Employee's name",
                birth_date: "Date of birth",
                gender: "Sex",
                male: "Male",
                female: "Female",
                phone_number: "Phone number",
                email: "Email",
                department: "Department",
                job_title: "Title",
                can_login: "Is an employee on omipos",
                branch: "Working branch",
                username: "User name",
                password: "Password",
                re_password: "Enter the password",
                note: "Note",
                placeholder: {
                    code: "Enter employee code",
                    name: "Enter employee name",
                    birth_date: "Enter date of birth",
                    phone_number: "Enter partner phone number",
                    email: "Enter email",
                    department: "Select department",
                    job_title: "Choose a job title",
                    branch: "Select branch",
                    username: "Enter your login name",
                    password: "Enter password",
                    note: "Enter notes"
                },
                button: {
                    save: "Save",
                    skip: "Skip",
                },
                confirmCancelChange: "Are you sure you want to cancel changes?",
                confirm: "Confirm",
                emptyName: "You have not entered an Employee Name",
                emptyDepartment: "You have not selected a Department",
                emptyJobTitle: "You have not selected a Job Title",
                emptyWorkingBranch: "You have not selected a branch to work at",
                emptyPassword: "You have not entered a password",
                emptyRePassword: "You have not entered your password. Re-enter your password",
                regexPassword: "Password must be at least 8 characters, with uppercase letters, lowercase letters, numbers, and special characters",
                invalidPassword: "The two passwords do not match",
                alertCreateSuccess: "Successfully added new employee",
                alertUpdateSuccess: "Employee update successful",
                alertCreateError: "Adding new employees failed"
            },
            ModalImportEmployees:{
                headerTitle: "Import employees from data file",
                templateTitle: "Download sample file: ",
                urlTitle: "Excel files",
                contentTitle: "Handling duplicate employee codes and different employee information: ",
                errStopTitle: "Report errors and stop importing",
                changeUpdateTitle: "Replace old employee information with new employee information"
            },

            // Phòng ban
            DepartmentIndex: {
                title: "Department",
                document_title: "Departments | OmiPos",
                placeholder: {
                    search: "Search",
                },
                button: {
                    create: "Add",
                },
                table: {
                    name: "Department name",
                    quantity: "Quantity",
                    description: "Describe",
                    status: "Status",
                    code: "Department code",
                },
                status: {
                    active: "Work",
                    in_active: "Inactive",
                },
                modal: {
                    createOrUpdate: {
                        title_create: "Add new departments", 
                        title_update: "Update departments", 
                        name: "Department name",
                        description: "Describe",
                        status: "Status",
                        active: "Work",
                        in_active: "Inactive",
                        placeholder: {
                            name: "Enter the department name",
                            code: "Enter the department code",
                        },
                        button: {
                            save: "Save",
                            skip: "Skip",
                            remove: "Delete"
                        },
                        title_confirm: "Confirm",
                        content_confirm_create: "Do you want to cancel more departments?",
                        content_confirm_update: "this department?",
                        alerts: {
                            create_success: "Added new department successfully",
                            update_success: "Updated departments successfully",
                        }
                    },
                    modalRemove: {
                        title: "Confirm",
                        content: "The system will completely delete this department. Are you sure?",
                        button: {
                            confirm: "Confirm",
                            skip: "Close"
                        }
                    }
                }
            },

            // Chức danh
            JobTitle: {
                titleCreate: "Add new job title",
                titleUpdate: "Update job title",
                name: "Job title",
                description: "Describe",
                status: "Status",
                active: "Active",
                inActive: "Inactive",
                save: "Save",
                skip: "Close",
                remove: "Delete",
                confirm: "Confirm",
                agree: "Agree",
                cancelUpdate: "Do you want to cancel updating the job title?",
                cancelCreate: "Do you want to cancel adding a new job?",
                contentRemove: "The system will completely delete this job title. Are you sure?",
            },

            // Đối tác giao hàng
            TransporterIndex: {
                personal: "Individual",
                company: "Company",
                title: "Delivery partner",
                title_document: "Delivery partner | OmiPos",
                integration: "Integration",
                other: "Other",
                transporter_group: "Delivery partner group",                
                total_delivery_fee: "Total delivery fee",
                fee_from: "From",
                fee_to: "To",
                current_debt: "Current debt",
                select_show: "Display options",
                type: "Partner type",
                code: "Partner code",
                name: "Partner name",
                address: "Address",
                phone: "Phone",
                email: "Email",
                area_delivery: "Delivery area",
                ward: "Wards",
                transporter_group_name: "Partner group",
                note: "Note",
                feeAdjustments: "Adjust",
                invoice_code: "Code Bill",
                delivery_code: "Bill of lading code",
                time: "Time",
                invoice_value: "Bill invoice",
                cod: "Still need to collect (COD)",
                fee: "Fee pay to delivery partner",
                status: "Status",
                tiket_code: "Code",
                type_fee: "Type",
                value: "Value",
                debt: "Debt needs to be paid to partner",
                transporterCode: "Delivery partner code",
                placeholder: {
                    search: "By code, name, phone",
                    transporter_group: "Select partner group",
                    value: "Value",
                    code: "Default code",
                    name: "Enter partner name",
                    phone: "Enter partner phone number",
                    email: "Enter email",
                    group: "Select partner group",
                    address: "Enter the address",
                    groupName: "Enter the delivery partner group name"
                    
                },
                button: {
                    feeAdjustments: "Adjust",
                    create: "Partner delivery",
                    import: "Import",
                    export: "Export file",
                    update: "Update",
                    in_active: "Inactive",
                    remove: "Delete",
                    active: "Active",
                    update_delivery: "Delivery updates",
                    payment: "Pay",
                },
                tabs: {
                    info: "Information",
                    history: "Delivery history",
                    return_fee: "Fees need to be paid for the transaction",
                    control_history: "Fees need to be paid for the transaction",
                },
                modal: {
                    // Modal xóa transport
                    modalRemove: {
                        title: "Confirm",
                        content: "The system is only allowed to delete undetected transactions of the counterparty. Are you sure you want to delete?",
                        button: {
                            confirm: "Agree",
                            skip: "Skip"
                        }
                    },
                    // Modal ngừng hoạt động
                    modalStop: {
                        title: "Confirm",
                        content: "Are you sure you want to Deactivate this delivery partner?",
                        button: {
                            confirm: "OK",
                            skip: "Skip"
                        }
                    },
                    modalAddTransporters: {
                        name: "Group name",
                        namePlaceholder: "Enter the product group name",
                        note: "Note",
                        notePlaceholder: "Note",
                        buttonAddUpdate: {
                            confirm: "Save",
                            skip: "Skip"
                        },
                        buttonRemove: "Delete",
                        add: {
                            title: "Add new delivery partners",
                            button: {
                                confirm: "Save",
                                skip: "Skip"
                            },  
                            msgSuccess: "Successfully added new delivery partners",
                            msgError: "Adding a new delivery partner failed",                      
                        },
                        update: {
                            title: "Update delivery partners", 
                            button: {
                                confirm: "Save",
                                skip: "Skip"
                            },
                            msgSuccess: "Successfully updated new delivery partners",
                            msgError: "Updating new delivery partners failed",  
                        },
                        remove: {
                            title: "Confirm", 
                            content: "Are you sure you want to delete this {name} delivery partner group?",
                            button: {
                                confirm: "Agree",
                                skip: "Skip"
                            },
                            msgSuccess: "Successfully deleted the new delivery partner group",
                            msgError: "Deleting the new delivery partner group failed",  
                        },
                    },
                    modalAddTransporterGroups: {
                        name: "Group name",
                        namePlaceholder: "Enter the product group name",
                        note: "Note",
                        notePlaceholder: "Note",
                        buttonAddUpdate: {
                            confirm: "Save",
                            skip: "Skip"
                        },
                        buttonRemove: "Delete",
                        add: {
                            title: "Add a new group of delivery partners",
                            button: {
                                confirm: "Save",
                                skip: "Skip"
                            },  
                            msgSuccess: "Successfully added a new group of delivery partners",
                            msgError: "Adding a new delivery partner group failed",                      
                        },
                        update: {
                            title: "Update delivery partner group", 
                            button: {
                                confirm: "Save",
                                skip: "Skip"
                            },
                            msgSuccess: "Successfully updated new delivery partner groups",
                            msgError: "Updating new delivery partner groups failed",  
                        },
                        remove: {
                            title: "Confirm", 
                            content: "Are you sure you want to delete this {name} delivery partner group?",
                            button: {
                                confirm: "Agree",
                                skip: "Skip"
                            },
                            msgSuccess: "Successfully deleted the new delivery partner group",
                            msgError: "Deleting the new delivery partner group failed",  
                        },
                    },
                    modelSelectInvoicePayment: {
                        title: "Payment with delivery partner",
                        search: "Search",
                        placeholder: {
                            invoice: "By invoice code",
                            delivery: "By bill of lading code",
                            control: "By reconciliation code",
                        },
                        branch: "Branch",
                        status_delivery: "Delivery status",
                        time: "Time",
                        start_date: "Since",
                        end_date: "To date",
                        invoice_code: "Code Bill",
                        delivery_code: "Bill of lading code",
                        control_code: "Reconciliation code",
                        customer: "Client",
                        transporter: "Delivery partner",
                        invoice_value: "Bill invoice",
                        cod: "Need to collect",
                        receive_cod: "Cash On Delivery",
                        fee_payment: "Fee pay to delivery partner",
                        debt_fee_payment: "Delivery fee owed",
                        cod_fee_payment: "COD - Fee pay to delivery partner",
                        button: {
                            payment: "Pay",
                            skip: "Skip",
                        }
                    }
                }
            },

            // Kênh bán
            SaleChannelIndex: {
                title: "Sales channel",
                status: "Status",
                search: "Search",
                add: "Add",
                documentTitle: "List of sales channels | OMIPOS",
                all: "All",
                active: "Active",
                inActive: "Inactive",
                titleCreate: "Add sales channels",
                titleUpdate: "Edit sales channels",
                name: "Sales channel name",
                description: "Describe",
                confirm: "Confirm",
                contentConfirmCancelCreate: "Do you want to cancel adding a title?",
                contentConfirmCancelUpdate: "Do you want to cancel the title update?",
                titleRemove: "Delete sales channel",
                contentRemove: "The sales channel is only deleted when there are no transactions. Are you sure you want to delete channel {name}?",
                alertRemoveSuccess: "Successfully deleted sales channel {name}.",
            },

            // Danh mục bảng giá
            PriceListsIndex: {
                title: "General price list",
                document_title: "General price list | OmiPos",
                time: "Time",
                branch: "Branch",
                status: "Status",
                effect: "Effect",
                placeholder: {
                    search: "Enter the price list name",
                    branch: "Select branch",
                },
                button: {
                    create: "Add price list",
                    file: "File",
                    import: "Import",
                    export: "Export file",
                },
                modal: {
                    title: "Select the price list list from the excel file",
                    title_import_detail: "Select product list from excel file",
                    button: "Select data file",
                    download_file: "Download sample file",
                    button_action: "Execute",
                    alert_error_validate: "The uploaded file must be in the correct xlsx, xls format",
                    alert_error_status: "Report errors and stop importing",
                    update_status: "Replace and update price list information",
                    titleCreate: "Add price list",
                    titleUpdate: "Edit price list",
                    info: "Information",
                    applyArea: "Scope of application",
                    name: "Price list name",
                    startDate: "Effective from date",
                    to: "arrive",
                    apply: "Apply",
                    notApply: "Not yet applied",
                    allBranch: "The whole system",
                    branch: "Branch",
                    chooseBranch: "Select the applicable branch",
                    allUser: "All traders",
                    createdBy: "Transaction created by",
                    chooseUser: "Select applicable merchant",
                    allCustomer: "All customers",
                    customerGroup: "Customer group",
                    chooseCustomerGroup: "Select the applicable customer group",
                    guest: "Fit",
                    chooseCustomer: "Select applicable customers",
                    price: "Price",
                    chooseProduct: "Select goods on the sales screen",
                    chooseProductDifferentPrice: "Allows you to choose goods other than the price list",
                    chooseProductAndWarning: "Allows you to choose goods other than the price list with warnings",
                    notChooseProductDifferentPrice: "It is not allowed to choose goods other than the price list",
                    confirm: "Confirm",
                    contentCancelCreate: "Do you want to cancel adding a new price list?",
                    contentCancelUpdate: "Do you want to cancel updating the price list?",
                    alertCreateSuccess: "Added new price list successfully",
                    alertUpdateSuccess: "Updated price list successfully",
                    choosePriceList: "--Select price list--",
                    contentRemove: "The system will completely delete {name} price list. Are you sure?",
                    alertRemoveSuccess: "Successfully deleted price list {name}",
                    alertRemoveError: "Deleting price list {name} failed",
                    time: "Effective time"
                }
            },

            // Chi tiết bảng giá
            PriceListDetailIndex: {
                title: "Price list details",
                priceList: "Price list",
                choosePriceList: "Select price list",
                categories: "Group of goods",
                searchCategories: "Search for product groups",
                all: "All",
                search: "Enter the product code or product name",
                documentTitle: "Price setup | OMIPOS",
                productCode: "Product code",
                productName: "Product name",
                unitName: "Unit",
                inventories: "Inventory",
                lastEntryPrice: "Final entry price",
                price: "Common price",
                comparePrice: "-- Comparative price --",
                costPrice: "Capital price",
                alertUpdateSuccess: "Update successful",
                contentConfirmClone: "Would you like to add the remaining products to {name}'s price list?",
                alertCreateSuccess: "Added price list successfully",
                alertCreateError: "Adding price list failed",
                salePrice: "Price",
                details: {
                    code: 'Code',
                    time: 'Time',
                    seller: 'Seller',
                    customer: 'customer',
                    sum: 'Total',
                    revenue: 'Revenue',
                    recipientPays: 'Employee',
                    returnedGuests: 'Returned',
                }
            },

            // Danh mục vị trí
            LocationIndex: {
                title: "Product location",
                documentTitle: "Location category | OMIPOS",
                title_location: "Location list",
                title_confirm: "Confirm",
                content_confirm: "Are you sure you want to delete the location category?",
                document_title: "Location category | OMIPOS",
                button: {
                    filter: "Filter",
                    create: "Add new",
                    create_location_category: "Add categories",
                    create_fast_location_category: "Quickly add locations",
                    action: "Operation",
                    export: "Export excel",
                },
                table_location_category: {
                    code: "Code",
                    name: "Name",
                    number_location: "Position number",
                    created_at: "Created date",
                    button: {
                        update: "Edit",
                        add_location: "Add location",
                        remove: "Delete",
                    }
                },
                table_location: {
                    barcode: "Barcode",
                    name: "Name",
                    code: "Code",
                    created_at: "Created date",
                    button: {
                        update: "Edit",
                        remove: "Delete"
                    }
                },
                titleCreate: "Add categories",
                info: "Information",
                branch: "Branch",
                chooseBranch: "- Select branch -",
                parentCategory: "Parent category",
                categories: "Category",
                categoriesName: "Name list",
                enterCategoriesName: "Enter a category name",
                categoriesCode: "Category code",
                enterCategoriesCode: "Enter the category code",
                addRow: "Add line",
                confirmCancelCreate: "Are you sure you want to cancel adding a new location category?",
                maxRow: "Only a maximum of 20 lines are allowed.",
                existCategoriesName: "Location category {name} already exists",
                existCategoriesCode: "Location category code {name} already exists",
                emptyCategoriesName: "Please enter the location category name",
                emptyCategoriesCode: "Please enter the location category code",
                titleQuickAdd: "Quickly add location categories",
                categoriesLevel1: "Category level 1",
                examLevel1: "Example: 1st floor",
                categoriesLevel2: "Level 2 category",
                examLevel2: "Example: Area [1-3]",
                categoriesLevel3: "Level 3 category",
                examLevel3: "Example: Shelves [1-3]",
                categoriesLevel4: "Level 4 category",
                examLevel4: "Example: Floor [1-3]",
                locations: "Locations",
                examLocations: "For example: Box [1-4], Document box",
                confirmCancelQuickAdd: "Are you sure you want to quickly cancel adding a location category?",
                emptyCategoriesLevel1: " Please enter a level 1 category name",
                titleCreateLocation: "Add new location in inventory",
                addInfo: "More information",
                note: "Note",
                contentNote: "The combined location barcode [Category code 1] - [Category code 2] .... - [Location code]",
                addLocationName: "Add location name",
                addLocationCode: "Add location code",
                addBarcode: "Add barcodes",
                addMaxQuantity: "Add maximum quantity",
                enterLocationName: " Enter a location name",
                enterLocationCode: "Enter the location code",
                enterBarcode: "Enter the barcode",
                enterMaxQuantity: "Maximum number of products",
                locationInFolder: "Locations in the area",
                barcode: "Barcode",
                name: "Name",
                code: "Code",
                totalProducts: "Item number",
                totalQuantity: "The number of products",
                maxTotalQuantity: "Quantity the product can contain",
                confirmCancelCreateLocation: "Are you sure you want to cancel additional location listings?",
                existLocationName: "Location name already exists",
                duplicateLocationName: "Location name is duplicated",
                existLocationCode: "The product location code already exists",
                duplicateLocationCode: "Product location code is duplicated",
                row: "Row ",
                emptyLocationName: " Please enter a location name",
                emptyLocationCode: "Please enter the product location code",
                emptyLocationBarCode: "Please enter the barcode",
                emptyData: "Please enter data",
                titleChooseParentCate: "Select the parent category",
                titleUpdateLocation: "Edit location",
                total: "Total",
                confirmCancelUpdateLocation: "Are you sure you want to cancel location updates?",
                alertUpdateSuccess: "Location updated successfully",
                alertUpdateError: "Location update failed",
                confirmRemoveLocation: "Are you sure you want to delete the location?",
                noteRemoveLocation: "Note: You cannot delete locations that already contain products",
                alertRemoveSuccess: "Location deleted successfully",
                alertRemoveError: "This location currently contains locations with products",
                titleUpdateLocationCategories: "Edit location categories",
                cancelUpdateLocationCategories: "You definitely want to cancel location category updates"
            },

        /* Hết danh mục */
        
        /* Giao dịch */
            // Dự trù chi tiết
            PlanIndex: {
                title: "Detailed estimate",
                record: "Record number",
                branch: "Branch",
                time: "Time",
                createdBy: "Created by",
                status: "Status",
                plansAdd: "New estimate",
                total_products: "Total number of estimated product codes",
                total_quantity_products: "Total number of expected products",
                chooseBranch: "Select branch",
                chooseCreatedBy: "Select created by",
                search: "According to code, expected report name",
                documentTitle: "Detailed estimates | OMIPOS",
                note: "Note",
                autoCreateCode: "Automatic reporting code",
                name: "Report name",
                chooseUnit: "Select unit",
                detail: {
                    code: "Code",
                    name: "Name",
                    branch: "Branch",
                    time: "Time to calculate average sales volume/day",
                    estimate: "Estimated number of days to sell",
                    status: "Status",
                    createdBy: "Created by",
                    description: "Note",
                    plan_purchase: "Inventory forecasting",
                },
                button: {
                    save: "Save",
                    open: "Open",
                    process: "Processing tickets",
                    print: "Print",
                    export: "Export file",
                    delete: "Delete",
                    cancel: "Cancel",
                    create: "Create inventory forecasting",
                    exportOverview: "Export overview file",
                    exportDetail: "Export detailed files",
                    fileOverview: "Overview file",
                    fileDetails: "Detailed files",
                    new: "New forecast"
                },
                modal: {
                    title: "Confirm",
                    content: "Are you sure you want to cancel the forecast ticket?",
                    content_multiple: "The system only allows the cancel that are in draft status and are not on any purchase planning table. Are you sure to cancel the detailed estimate?",
                    content_delete_multiple: "The system only allows the delete that are in draft status and are not on any purchase planning table. Are you sure to cancel the detailed estimate?",
                    content_delete: "Are you sure you want to delete the estimate?",
                    nextStep: "Skip",
                    confirm:  "Approve",
                    success: "Cancelled data successfully",
                    success_delete: "Deleted data successfully"
                },
                alerts: {
                    update_error: "Data Update Failed",
                    update_success: "Updated data successfully",
                }
            },

            // Dự trù nhập mua
            PlanPurchaseIndex: {
                title: "Inventory forecasting",
                record: "Record number",
                filter_time: "Time",
                filter_user: "Created by",
                total_products: "Total estimated product codes",
                total_quantity_products: "Total estimated products",
                total_supplier: "Total suppliers",
                chooseBranch: "Select branch",
                chooseCreatedBy: "Select created by",
                search: "By code, estimated report name",
                documentTitle: "Purchase forecast | OMIPOS",
                detail: {
                    branchCreate: "Branch creates",
                    branch: "Branch is planned",
                    plan: "Detailed estimate code",
                },
                modal: {
                    content_cancel: "Are you sure you can cancel the inventory forecastingr?",
                    content_delete: "The system only allows deleting Draft. Are you sure you want to delete the inventory forecasting?",
                    content_cancel_multiple: "Are you sure you can cancel multiple inventory forecasting?",
                    content_delete_multiple: "The system only allows deleting Draft. Are you sure you want to delete the inventory forecasting?"
                },
                alerts: {
                    error: "The inventory forecasting cannot be left blank",
                    create_success: "Create inventory forecasting successfully",
                    create_error: "Create inventory forecasting failed",
                    update_success: "Update inventory forecasting successfully",
                    update_error: "Update inventory forecasting failed",
                    create_order: 'Created purchase order successfully'
                },
                button: {
                    process: "Processing",
                    create_po: "Create purchase order",
                }
            },
            planCreateUpdate: {
                title: "Detailed estimates",
                title_plan_purchase: "Inventory forecasting",
                table: {
                    stt: "No.",
                    code: "Product code",
                    name: "Product name",
                    unit: "Unit",
                    min_inventory: "Min. inventory level",
                    max_inventory: "Max. inventory level",
                    estimate_number: "The number of forecast fluctuations",
                    avg_number_of_sale: "Average number sold per day",
                    sys_estimate_number: "Forecasting systems sell",
                    available_inventory: "Sellable inventory",
                    total_quantity_plan: "Estimate quantity",
                    total_quantity_order: "Total estimate quantity",
                    total_quantity_real_order: "Actual quantity ordered",
                    price: "Unit price",
                    supplier: "Supplier",
                },
                created_by: "Created by",
                plan_code: "Code",
                plan_name: "Name",
                plan_branch: "Estimated branch",
                plan_time: "Time to calculate average sales volume/day",
                to: "To ",
                plan_estimate: "Estimated number of days to sell",
                plan_status: "Status",
                note: "Note...",
                total_product: "Total estimated product codes",
                total_quantity_product: "Total expected products",
                total_supplier: "Total supplier",
                button: {
                    success: "Completed",
                    draft: "Draft",
                    create_order: "Create purchase orders",
                },
                status_draft: "Draft",
                alerts: {
                    error: "The inventory forecasting cannot be left blank",
                    create_success: "Create a detailed estimate successfully",
                    update_success: "Create a detailed estimate successfully",
                }
            },

            // Đặt hàng nhập
            OrderSupplierIndex: {
                title: "Purchase order",
                document_title: "Purchase order | OmiPOS",
                filter: {
                    branch: "Branch",
                    time: 'Time',
                    status: "Status",
                    status_payment: 'Status payment',
                    created_by: "Created by",
                    order_by: "Orderer",
                    placeholder: {
                        branch: "Choose branch",
                        created_by: "Select created by",
                        order_by: "Select installation recipient",
                        search: "By order code",
                    }
                },
                code: "Purchase order code",
                time: "Time",
                supplier: "Supply",
                customer: "Passenger goods",
                price_list: "Price list",
                purchase_code: "Purchase receipt code",
                status: "Status",
                status_complete: "Complete",
                status_cancel: "Abort",
                status_purchase_a_part: "Partially imported",
                status_draft: "temporary",
                statusConfirmed: "Supplier identified",
                branch: "Branch",
                employee: "Business man",
                created_by: "Created by",
                order_by: "Orderer",
                sale_channel: "Sell channels",
                note: "Note...",
                method: "Payment methods",
                total_quantity: "Total quantity",
                total_products: "Total number of items",
                total_origin: "Total cost of goods",
                discount: "Discount",
                total: "Total plus",
                payment_supplier: "Need to pay supplier",
                total_payment: "Supplier paid",
                expected_date: "Expected import date",
                tabs:{
                    info: "Information",
                    history_purchase: "Historical imports",
                    history_payment: "Payment history",
                    history_delivery: "Delivery history",
                    table:{
                        history_payment: {
                            code: "Voting code",
                            time: "Time",
                            created_by: "Created by",
                            method: "Method",
                            status: "Status",
                            total_price: "Spending money",
                        },
                        history_purchase: {
                            code: "Code",
                            time: "Time",
                            create_by: "Created by",
                            status: "Status",
                        }
                    }
                },
                button: {
                    add: "Purchase order",
                    action: "Operation",
                    export: "Export file",
                    export_all: "Export overview list file",
                    export_detail: "Export detailed list file",
                    import: "Import File",
                    file: "File",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    cancel: "Cancel",
                    print: "Print",
                    print_barcode: "Print the code stamp",
                    show: "Open",
                    save: "Save",
                    update: "Update",
                    create_purchase: "Create a purchase receipt",
                    copy: "Copy",
                    import_invoice_supplier: "Receive supplier invoices",
                    process_ticket: "Handle",
                    close: "End",
                },
                titleCreate: "Purchase order",
                paymentForSupplier: "Paid to supplier",
                needToPaySupplier: "Need to pay supplier",
                paidSupplier: "Payment to supplier",
                supplierReturn: "Supplier returns",
                estimatedImportDate: "Estimated delivery date",
                emptyOrder: "Order is empty",
                invalidETImportDate: "The expected import date must be greater than the creation date",
                alertCreateSuccess: "Successfully created purchase order",
                alertUpdateSuccess: "Successfully updated purchase order",
            },
            DetailSupplierCard: {
                title: "Purchase order",
                orderCode: "Purchase order code",
                orderDate: "Time",
                supllierName: "Supplier",
                createdBy: "Created by",
                statusName: "Status",
                branchName: "Branch",
                orderBy: "Orderer",
                expectedDateOfImport: "Expected import date",
                note: "Note",
                prodCode: "Product code",
                prodName: "Product name",
                prodQuantity: "Quantity",
                prodPrice: "Unit price",
                prodDiscount: "Discount",
                prodImportPrice: "Purchase price",
                totalPrice: "Total amount",
                totalQuantity: "Total quantity",
                totalProduct: "Total number of items",
                total: "Total product cost",
                discount: "Discount",
                sumTotal: "Total",
                paymentForSupplier: "Money paid to supplier"
            },
            ReportPayToDebt: {
                placeholder: {
                    order_import: "Enter purchase order code",
                    import: "Enter purchase code",
                    supplier: "By name, supplier code"
                },
                title: {
                    supplier: "Supplier",
                    order_import: "Number purchase order",
                    import: "Enter purchase code"
                }
            }, 

            // Nhập hàng
            PurchaseOrderIndex: {
                title: "Purchase",
                document_title: "Purchase | OmiPos",
                filter: {
                    branch: "Branch",
                    time: 'Time',
                    status: "Status",
                    status_invoice: 'Invoice status',
                    created_by: "Created by",
                    import_by: 'Importer',
                    priceList: 'Price list',
                    placeholder: {
                        branch: "Select branch",
                        created_by: "Select created by",
                        import_by: 'Select importer',
                        priceList: 'Select price list',
                        search: 'According to import code',
                        sold_by: 'Choose a seller',
                    }
                },
                code: "Purchase receipt code",
                time: "Time",
                supplier: "Supplier",
                customer: "Client",
                price_list: "Price list",
                order_code: "Purchase order code",
                status: "Status",
                statusPurchased: "Completed",
                branch: "Branch",
                employee: "Business man",
                created_by: "Created by",
                import_by: "Importer",
                sale_channel: "Sales channel",
                note: "Note...",
                method: "Payment methods",
                total_quantity: "Total",
                total_products: "Total number of items",
                total_origin: "Total cost of goods",
                discount: "Discount",
                total: "Total",
                total_payment: "Money paid to supplier",
                tabs:{
                    info: "Information",
                    history_return: "Return history",
                    history_payment: "Payment history",
                    table:{
                        history_payment: {
                            code: "Code",
                            time: "Time",
                            created_by: "Created by",
                            method: "Method",
                            status: "Status",
                            total_price: "Spending money",
                        },
                        history_return: {
                            code: "Return code",
                            time: "Time",
                            receive_by: "Employee",
                            total_price: "Total",
                            status: "Status",
                        }
                    }
                },
                button: {
                    add: "Import goods",
                    action: "Operation",
                    export: "Export file",
                    export_all: "Export overview list file",
                    export_detail: "Export detailed list file",
                    import: "Import File",
                    file: "File",
                    file_all: "Overview file",
                    file_detail: "Detail file",
                    cancel: "Cancel",
                    print: "Print",
                    print_barcode: "Print the code stamp",
                    show: "Open",
                    save: "Save",
                    update: "Update",
                    return: "Return",
                    copy: "Copy",
                    import_invoice_supplier: "Receive supplier invoices",
                    process_ticket: "Handle",
                    sendZalo: "Send zalo"
                },
            },

            // Trả hàng nhập (0)
            ReturnPurchaseOrder: {
                title: "Purchase Return",
                selectReturnBy: "Select employee",
                totalAmountReturn: "Total amount paid",
                supplierNeedToPay: "Supplier needs to pay",
                supplierPaid: "Supplier paid",
                returnPrice: "Return price",
                returnDiscount: "Return discount",
                updateSuccess: "The return receipt entered {code} was successfully updated",
                includedInDebt: "Included in debt",
                advanceRefund: "Refund the advance",
                amountSupplierPaid: "Payment by supplier",
                amountReturnSupplier: "Money returned to supplier"
            },

            // Tạm ứng quyết toán
            SettlementAdvanceIndex: {
                title: "Advance settlement",
                document_title: "Advance Request | OmiPos",
                branch: "Branch",
                time: "Time",
                created_by: "Created by",
                status: "Status",
                code: "Settlement advance code",
                created_at: "Created date",
                note: "Note",
                po_code: "Purchase order code",
                po_status: "Status of Purchase order",
                payment_date: "Payment schedule according to contract",
                supplier_code: "Supplier code",
                supplier_name: "Supplier name",
                payment_method: "Payments",
                payment_info: "Billing Information",
                cash: "Amount of money",
                advance_info: "Settlement information",
                account_number: "Account number",
                account_owner: "Account owner",
                bank_address: "Bank + Branch",
                settlement: "Advance",
                advances: "Settlement",
                compare: "Difference",
                import_number: "Purchase receipt code",
                invoice_number: "Invoice code",
                invoice_info: "Invoice information",
                invoice_cash: "Amount on invoice",
                invoice_status: "Invoice status",
                placeholder: {
                    branch: "Select branch",
                    created_by: "Select created by",
                    search: "By advance request code"
                },
                button: {
                    add: "Add",
                    export: "Export file",
                    print: "Print",
                    save: "Save",
                    update: "Update",
                    cancel: "Cancel"
                },
                tabs: {
                    info: "Information"
                },
                alertUpdateSuccess: "Successfully to update settlement advance {code}",
                alertUpdateError: "Failed to update settlement advance {code}",
                alertCancelSuccess: "BAS canceled successfully",
                confirmCancel: "Are you sure you want to cancel the settlement advance {code}?",
            },

            // BAS
            DiscountsBAS: {
                title: "Discounts after BAS",
                document_title: "Discounts after BAS | OmiPos",
                branch: "Branch",
                time: "Creation time",
                created_by: "Created by",
                status: "Status",
                code: "Code",
                supplierCode: "Supplier code",
                supplierName: "Supplier name",
                created_at: "Creation time",
                updated_at: "Update time",
                note: "Note",
                totalPrice: "Total amount",
                button: {
                    add: "Add new",
                    export: "Export file",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    export_all: "Export overview list file",
                    export_detail: "Export detailed list file",
                    print: "Print",
                    save: "Save",
                    update: "Update",
                    open: "Open",
                    cancel: "Cancel",
                    delete: "Delete",
                    success: "Complete",
                    draft: "Save temporarily",
                    action: "Operation",
                },
                alerts: {
                    error: "Please select a supplier",
                    errorMount: "Please enter the discount amount",
                    create_success: "Create successful Inventory forecasting",
                    update_discount_bas_error: 'Updating BAS failed',
                    update_discount_bas_success: "Updated BAS successfully",
                    create_discount_bas_error: 'Added new BAS failed',
                    create_discount_bas_success: 'Added new BAS successfully'
                    
                },
                tabs: {
                    info: "Information"
                },
                table: {
                    STT: "No.",
                    supplierId: "Supplier code",
                    supplierName: "Supplier name",
                    content: "Content",
                    amount: "Total",                    
                    deductionLimit: "Set-off period",
                },
                placeholder: {
                    search: "By BAS code",
                    created_by: "Select created by",
                    content: "Enter content", 
                    deductionLimit: "Enter set-off period",
                    branch: "Select branch"
                }
            },

            // Đặt hàng bán
            OrderIndex: {
                title: "Order",
                document_title: "Order | OmiPos",
                filter: {
                    branch: "Branch",
                    time: 'Time',
                    status: "Status",
                    created_by: "Created by",
                    ordered_by: "Sales employees",
                    saleChannel: 'Sales channel',
                    placeholder: {
                        branch: "Select branch",
                        created_by: "Select created by",
                        ordered_by: "Select sales employees",
                        saleChannel: "Select sales channel",
                        search: 'By order code',
                    }
                },
                code: "Order code",
                time: "Time",
                customer: "Customer",
                price_list: "Price list",
                expected_date_of_transfer: "Estimated delivery date",
                invoice_code: "Invoice code",
                status: "Status",
                branch: "Branch",
                created_by: "Created by",
                ordered_by: "Sales employees",
                status_on_ecommerce: "Status on the platform",
                sale_channel: "Sales channel",
                note: "Note",
                total_quantity: "Total quantity",
                total_origin: "Sub-total",
                discount: "Discount",
                surcharge: "Surcharge",
                total: "Total",
                total_payment: "Customer has paid",
                transaction_fee: "Transaction fees",
                shipping_fee_collected_by_customer: "Actual shipping fee (from customer)",
                fees_paid_to_delivery_partners: "Fee paid to delivery partner",
                tabs:{
                    info: "Information",
                    history_invoice: "Invoice history",
                    history_payment: "Payment history",
                    table:{
                        history_payment: {
                            code: "Code",
                            time: "Time",
                            created_by: "Created by",
                            method: "Method",
                            status: "Status",
                            total_price: "Revenue",
                        },
                        history_invoice: {
                            code: "Invoice code",
                            time: "Time",
                            created_by: "Created by",
                            value: "Value",
                            status: "Status",
                        },
                    },
                },
                button: {
                    order: "Order",
                    export: "Export file",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    end: "Finish",
                    save: "Save",
                    process_order: "Order processing",
                    print: "Print",
                    copy: "Copy",
                    cancel: "Cancel",
                },
            },
            modalEndOrder: {
                title: "Finish order form",
                content: "You will not be able to process the order even though the products have not yet been delivered. Are you sure to finish and change the order status to completed?",
                messageSuccess: "Order completed successfully.",
                messageError: "Order completed failed"
            },
            ModalCreateInvoiceMultiple:{
                status_draft: "Draft",
                status_confirm: "Confirmed",
                content: "The system only processes sales orders that are in status",
                add_success: "Add successfully: ",
                add_errors: "Add failed: ",
                continue: "Continue",
                inogre: "Skip",
            },
            DetailOrdersCard: {
                title: "Order",
                orderCode: "Order code",
                orderDate: "Time",
                customerName: "Customer",
                priceLists: "Price list",
                createdBy: "Created by",
                statusName: "Status",
                branchName: "Processing branch",
                branchNameCreate: "Created branch",
                orderBy: "Sales employees",                
                expectedDateOfImport: "Estimated delivery date",
                note: "Note",
                prodCode: "Product code",
                prodName: "Product name",
                prodQuantity: "Quantity",
                prodUnit: "Unit",
                prodPrice: "Unit price",
                prodDiscount: "Discount",
                prodImportPrice: "Purchase price",
                totalPrice: "Sub-total",
                totalQuantity: "Total quantity",
                totalProduct: "Total number of items",
                total: "Total amount",
                discount: "Discount",
                sumTotal: "Final total",
                paymentForSupplier: "Paid",
                SaleChannelName: "Sales channel",
                notFound: "No matching results",
                btnOpen: "Process order",
                promotion: "Promotion",
                codeDiscount: "Discount code",
                attribute: "Attribute",
                searchProductCode: "Search product code",
                searchProductName: "Search product name",
                dvt: "Unit",
                chooseUnit: "Select unit",
                chooseAttribute: "Select properties",
            },

            // Hóa đơn
            InvoiceIndex: {
                title: "Invoice",
                document_title: "Invoice | OmiPos",
                filter: {
                    branch: "Branch",
                    time: 'Time',
                    status: "Status",
                    saleChannel: 'Sales channel',
                    method: 'Method',
                    priceList: 'Price list',
                    placeholder: {
                        branch: "Select branch",
                        saleChannel: "Select sales channel",
                        method: 'Select a payment method',
                        priceList: 'Select price list',
                        search: 'According to invoice code',
                        sold_by: 'Choose a seller',
                    }
                },
                code: "Invoice code",
                time: "Time",
                customer: "Customer",
                price_list: "Price list",
                order_code: "Order code",
                status: "Status",
                branch: "Branch",
                employee: "Sales employees",
                created_by: "Created by",
                sale_channel: "Sales channel",
                note: "Note",
                method: "Payment method",
                total_quantity: "Total quantity",
                total_origin: "Sub-total",
                discount: "Invoice discount",
                surcharge: "Surcharge",
                total: "Total",
                total_payment: "Paid",
                shipping_fee_collected_by_customer: "Actual shipping fee (from customer)",
                fees_paid_to_delivery_partners: "Fee paid to delivery partner",
                tabs:{
                    info: "Information",
                    history_return: "Return history",
                    history_payment: "Payment history",
                    history_delivery: "Delivery history",
                    detail_delivery: "Shipment Details",
                    table:{
                        history_payment: {
                            code: "Code",
                            time: "Time",
                            created_by: "Created by",
                            method: "Method",
                            status: "Status",
                            total_price: "Payment amount",
                            value: "Value",
                            type_payment: "Type"
                        },
                        history_return: {
                            code: "Return code",
                            time: "Time",
                            receive_by: "Employee",
                            total_price: "Total",
                            status: "Status",
                        }
                    },
                    delivery: {
                        title: "Shipment Details",
                        address_receive: "Pick-up address",
                        receive_by: "Receiver",
                        paid_by: "Sender",
                        contact_number: "Phone",
                        address: "Address",
                        area: "Area",
                        ward: "Ward",
                        note: "Delivery note",
                        delivery_code: "Bill of lading code",
                        weight: "Weight",
                        size: "Size",
                        price_declaration: "Declare price",
                        exchange: "Conversion",
                        conversion_exchange: "Conversion rate",
                        take_to_office: "Take it to the post office",
                        pay_fee_by: "Paying party",
                        service: "Service",
                        delivery_by: "Delivery person",
                        using_price_cod: "Cash on delivery",
                        using_price_cod_still: "COD remaining",
                        price: "Fee pay to delivery partner",
                        price_still: "Remaining",
                        delivery_time: "Delivery time",
                        delivery_status: "Delivery status",
                        note_cancel: 'Reason for cancellation',
                        note_delivery: "Note",
                        discount_code: "Discount code",
                        estimate_delivery: "Expected delivery",
                        control_code: "Reconciliation code",
                        total_fee: "Total",
                        total_receive: "Total revenue of recipient",
                        titleCreate: 'Create another bill of lading',
                        paid_by_receiver_value: "The recipient pays the fee",
                    }
                },
                button: {
                    invoice: "Invoice",
                    add: "Add",
                    action: "Option",
                    export: "Export file",
                    export_all: "Export overview file",
                    export_detail: "Export detailed file",
                    import: "Import File",
                    file: "File",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    cancel: "Cancel",
                    print: "Print",
                    save: "Save",
                    update: "Update",
                    refund: "Return request",
                    return: "Returns",
                    copy: "Copy",
                    print_delivery: "Print shipping info",
                    cancel_delivery: "Cancel bill of lading",
                    create_delivery: "Create bill of lading",
                    payment_with_transporter: "Payment with delivery partner",
                },
            },

            // Trả hàng bán (0)
            ReturnIndex: {
                selectReturnBy: "Select payee",
                returnTotal: "Total amount paid",
                returnDiscount: "Discount",
                returnFee: "Return fee",
                returnCustomer: "Total return",
                totalPayment: "Returned",
                reImportPrice: "Re-import price",
                alertUpdateSuccess: "Return {code} has been successfully updated",
                alertUpdateError: "Return receipt {code} failed to update",
            },

            // Đề nghị hoàn hàng (0)

            // Chuyển hàng đi
            TransferIndex: {
                title: "Transfer",
                record: "Record",
                filter_time: "Time",
                transfer_date: "Transferred date",
                receive_date: "Received date",
                filter_user: "Created by",
                type: "Type",
                type_transfer: "Move",
                type_receive: "Import",
                from_branch: "From branch",
                to_branch: "Go to branch",
                status: "Status",
                status_receive: "Delivery status",
                placeholder: {
                    search: "According to the transfer code"
                },
                button: {
                    action: "Option",
                    export_all: "Export overview file",
                    export_detail: "Export detailed file",
                    create: "Transfer",
                    export: "Export file",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    save: "Save",
                    open: "Open",
                    process: "Processing",
                    print: "Print",
                    print_barcode: "Print the code stamp",
                    copy: "Copy",
                    cancel: "Cancel"
                },
                tabs: {
                    info: "Information",
                    detail_receive: "Delivery details"
                },
                transfer_code: "Transaction code",
                request_code: "Request code",
                from_branch_note: "Note (transfer branch)...",
                to_branch_note: "Note (received branch)...",
                total_products: "Total number of items",
                total_send_quantity: "Total number of Transfers",
                total_send_price: "Value",
                total_receive_quantity: "Total quantity received",
                total_receive_price: "Total value received",
            },

            // Gọi hàng về
            RequestIndex: {
                title: "Request order",
                record: "Record number",
                filter_time: "Time",
                request_date: "Request date",
                receive_date: "Received date",
                filter_user: "Created by",
                type: "Type",
                type_transfer: "Move",
                type_receive: "Import",
                status: "Status",
                status_confirm: "Status confirmed",
                placeholder: {
                    search: "By request code",
                    from_branch: "From branch",
                    to_branch: "To branch",
                },
                button: {
                    action: "Option",
                    export_all: "Export overview file",
                    export_detail: "Export detailed file",
                    create: "Request products",
                    export: "Export file",
                    file_all: "Overview file",
                    file_detail: "Detailed files",
                    save: "Save",
                    open: "Open",
                    process: "Processing",
                    print: "Print",
                    print_barcode: "Print the code stamp",
                    copy: "Copy",
                    cancel: "Cancel"
                },
                tabs: {
                    info: "Information",
                    detail: "Request details"
                },
                request_code: "Request code",
                from_branch: "Creates branch",
                to_branch: "Requested branch",
                transfer_code: "Transaction code",
                from_branch_note: "Note (request branch)...",
                to_branch_note: "Note (branch received request)...",
                total_products: "Total number of items",
                total_request_quantity: "Total request quantity",
                total_request_price: "Value (request total)",
                total_transfer_quantity: "Total transfer quantity: ",
                total_confirm_quantity: "Total confirmed quantity: ",
                total_transfer_price: "Value: ",
                total_confirm_price: "Confirmed value: ",
                sendQuantity: "Approved quantity",
                sendAmount: "Approved total",
                requestPriceOrSendPrice: "Price",
            },

            // Xếp sản phẩm vào vị trí
            ImportPositionIndex: {
                title: "Put the product in place",
                title_confirm: "Confirm",
                content_confirm: "Are you sure you want to cancel the warehouse receipt {code}?",
                document_title: "Place products in position | OmiPos",
                time: "Time",
                status: "Status",
                code: "Location import code",
                created_at: "Created time",
                created_by: "Created by",
                original_document: "Original documents",
                note: "Note",
                totalProducts: "Total quantity",
                totalTypeProducts: "Total number of items",
                status_draft: "Draft",
                status_confirm: "Confirmed",
                placeholder: {
                    search: "By location import code",
                },
                tabs: {
                    info: "Information",
                },
                button: {
                    save: "Save",
                    process: "Processing",
                    update: "Update",
                    print: "Print",
                    cancel: "Cancel",
                },
                table: {
                    code: "Product code",
                    name: "Product name",
                    unit: "Unit",
                    attribute: "Attribute",
                    quantity: "Quantity",
                    import_position: "Import location"
                },
                alerts: {
                    validate_note: "Notes cannot be larger than 255 characters",
                    update_success: "{code} was updated successfully",
                    update_error: "{code} update failed",
                    cancel_success: "Canceled successfully",
                    error_server: "Server error"
                }
            },

            // Lấy sản phẩm khỏi vị trí
            ExportPositionIndex: {
                title: "Remove product from location",
                title_confirm: "Confirm",
                content_confirm: "Are you sure you want to cancel the delivery note {code}?",
                document_title: "Remove product from location | OmiPos",
                time: "Time",
                status: "Status",
                code: "Export location code",
                created_at: "Creation time",
                created_by: "Created by",
                original_document: "Original documents",
                note: "Note",
                totalProducts: "Total",
                totalTypeProducts: "Total number of items",
                status_draft: "Draft",
                status_confirm: "Confirmed",
                placeholder: {
                    search: "By export location code",
                },
                tabs: {
                    info: "Information",
                },
                button: {
                    save: "Save",
                    process: "Processing",
                    update: "Update",
                    print: "Print",
                    cancel: "Cancel",
                },
                table: {
                    code: "Product code",
                    name: "Product name",
                    unit: "Unit",
                    quantity: "Quantity",
                    attribute: "Attribute",
                    export_position: "Exported location"
                },
                alerts: {
                    validate_note: "Notes cannot be larger than 255 characters",
                    update_success: "{code} was updated successfully",
                    update_error: "{code} update failed",
                    cancel_success: "Canceled successfully",
                    error_server: "Server error"
                }
            },
            ExportPositionProduct: {
                button: {
                    export: "Export file"
                }
            },

            // Điều chuyển vị trí
            TransferPositionIndex: {
                title: "Change position",
                title_confirm: "Confirm",
                content_confirm: "Are you sure you want to cancel the transfer {code}?",
                document_title: "Change position | OmiPos",
                time: "Time",
                status: "Status",
                code: "Location transfer code",
                created_at: "Creation time",
                created_by: "Created by",
                note: "Note",
                totalProducts: "Total",
                totalTypeProducts: "Total number of items",
                status_draft: "Draft",
                status_confirm: "Confirmed",
                placeholder: {
                    search: "By transfer code",
                },
                tabs: {
                    info: "Information",
                },
                button: {
                    save: "Save",
                    open: "Open",
                    create: "Add",
                    update: "Update",
                    print: "Print",
                    cancel: "Cancel",
                    copy: "Copy"
                },
                table: {
                    code: "Product code",
                    name: "Product name",
                    unit: "Unit",
                    quantity: "Quantity",
                    from_location: "From",
                    attribute: "Attribute",
                    to_location: "To"
                },
                alerts: {
                    validate_note: "Notes cannot be larger than 255 characters",
                    update_success: "{code} was updated successfully",
                    update_error: "{code} update failed",
                    cancel_success: "Canceled successfully",
                    error_server: "Server error"
                }
            },
            print_template_convert: {
                ticket_import: {
                    title: "INPUT PRODUCT LOCATION"
                },
                ticket_export: {
                    title: "EXPORT PRODUCT FROM LOCATION"
                },
                ticket_transfer: {
                    title: "TRANSFER PRODUCT LOCATION"
                }
            },

            // Sổ quỹ tiền mặt
            CashBookIndex: {
                title: "General ledger cash",
                document_title: "General ledger cash | OmiPos",
                filter: {
                    branch: "Branch",
                    time: 'Time',
                    document_type: 'Document type',
                    payment_receipt_type: 'Type',
                    status: "Status",
                    created_by: "Created by",
                    user: "Employee",
                    object_payment_receipt: 'Object',
                    placeholder: {
                        all: "All",
                        user: "Select employee",
                        created_by: "Select created by",
                        payment_receipt_type: 'Type',
                        branch: "Select branch",
                        name: 'Name and code of sender/receiver',
                        phone: 'Phone',
                    }
                },
                tooltip:{
                    totalAmountReserve: "Ending balance = Beginning balance + Debit - Credit"
                },
                code: "Code",
                earlyTermFund: "Beginning balance",
                totalAmountRevenue: "Debit",
                totalAmountSpending: "Credit",
                totalAmountReserve: "Ending balance",
                created_at: "Time",
                value: "Value",
                receipt_by: "Receiver",
                payment_by: "Submitter",
                phone_number: "Phone number",
                address: "Address",
                branch: "Branch",
                type_receipt_payment: "Type",
                status: "Status",
                created_by: "Created by",
                user: "Employee",
                object_payment: "Submitting object",
                object_receipt: "Receiving object",
                supplier: "Supplier",
                customer: "Client",
                guest: "Retail customer",
                note: "Note",
                method: "Method",
                cash: "Cash",
                transporter: "Delivery partner",
                tabs:{
                    info: "Information",
                },
                button: {
                    add_receipt_ticket: "Add receipt",
                    add_payment_ticket: "Add payment",
                    action: "Option",
                    export: "Export file",
                    show: "Open",
                    cancel: "Cancel",
                    print: "Print",
                    save: "Save",
                    update: "Update"
                },
                placeholder:{
                    search: "By code",
                },
                alerts: {
                    update_success: "{code} has been updated successfully",
                    update_error: "{code} failed to update",
                    validate_error_note: "Notes cannot be larger than 255 characters",
                },
                modal: {
                    showDetail: {
                        title_receipt: "Receipts",
                        title_payment: "Payment",
                        code_receipt: "Receipt code",
                        code_payment: "Payment code",
                        table: {
                            order_code: "Order code",
                            invoice_code: "Invoice code",
                            return_code: "Return code",
                            order_supplier_code: "Order supplier code",
                            purchase_code: "Purchase order code",
                            return_purchase_code: "Return code",
                            time: "Time",
                            value: "Value",
                            receipted: "Pre-recorded",
                            paymented: "Paid in advance",
                            amount_receipt_payment: "Payment amount",
                            status: "Status",
                        }
                    }
                },
                toLink:{
                    invoice_receipt: "Receipts are automatically created associated with invoices",
                    order_receipt: "Receipts are automatically created associated with orders",
                    return_receipt: "A receipt automatically created associated with a return",
                    order_for_supplier_receipt: "Receipts automatically created associated with purchase order",
                    import_for_supplier_receipt: "A receipt is automatically created associated with the products receipt",
                    return_import_for_supplier_receipt: "A receipt is automatically created associated with the imported product return",
                    delivery_receipt: "Receipts are automatically created associated with bills of lading",
                    invoice_payment: "Payment are automatically created associated with invoices",
                    order_payment: "Payment are automatically created associated with sales order slips",
                    return_payment: "Payment are automatically created associated with return vouchers",
                    order_for_supplier_payment: "Payment are automatically created associated with the entered order vouchers",
                    import_for_supplier_payment: "Payment are automatically created associated with goods receipts",
                    return_import_for_supplier_payment: "Payment are automatically created associated with imported goods return",
                    delivery_payment: "Payment are automatically created associated with bill of lading",
                }
            },
            modalCreateTicketForCash:{
                title_create_receipt: "Add receipt",
                title_update_receipt: "Update receipt",
                title_create_payment: "Add payment",
                title_update_payment: "Update payment",
                title_confirm_close: "Confirm",
                content_confirm_close_receipt: "Are you sure you can cancel a new receipt?",
                content_confirm_close_update_receipt: "Are you sure you can cancel the receipt update?",
                content_confirm_close_payment: "Are you sure you can cancel a new payment?",
                content_confirm_close_update_payment: "Are you sure you can cancel the payment update?",
                info_common: 'General information',
                document: 'Document',
                objects: 'Object',
                amount: 'Value',
                reason_receipt: 'Rceipt reason',
                reason_payment: 'Payment reason',
                user_receipt: 'Payment employee',
                user_payment: "Employee",
                note: 'Note',
                planning_day: 'Accounting day',
                document_number: 'Document code',
                placeholder: {
                    amount_receipt: "Enter the receipt amount",
                    amount_payment: "Enter the payment amount",
                    reason: "Enter reason",
                    note: "Enter notes",
                    document_number: "Enter the document code",
                    user: "Select employee",
                    objects: "Select object",
                },
                button: {
                    save: "Save",
                    saveAndPrint: "Save and print",
                    skip: "Skip",
                    cancel: "Cancel",
                },
                alert: {
                    create_receipt_success: "Added new receipt successfully",
                    update_receipt_success: "Update receipt successfully",
                    create_payment_success: "Added new payment successfully",
                    update_payment_success: "Payment updated successfully",
    
                    create_receipt_error: "Adding a new receipt failed",
                    update_receipt_error: "Updating receipt failed",
                    create_payment_error: "Adding new payment failed",
                    update_payment_error: "Update failed",
                }
            },
            modalCashCancel:{
                title: "Cancel",
                content: "Are you sure you want to cancel the {code}?",
                btnSubmit: "Approve",
                btnAbort: "Cancel",
                messageReceiveSuccess: "Receipt {code} has been canceled",
                messagePaymentSuccess: "Payment {code} has been canceled",
                messageReceiveError: "Receipt {code} failed to cancel",
                messagePaymentError: "Receipt {code} failed to cancel"
            },

            // Sổ quỹ ngân hàng
            BankBookIndex: {
                title: "General ledger bank",
                document_title: "General ledger bank | OmiPos",
                numberRecord: "Records",
                note: "Note",
                totalAmountRevenue: "Total revenue",
                supplier: "Supplier",
                customer: "Customer",
                transporter: "Delivery partner",
                filter: {
                    payment_type: "Payment type",
                    branch: "Branch",
                    time: "Time",
                    created_by: "Created by",
                    user: "Employee",
                    object_payment_receive: 'Receiving object',
                    placeholder: {
                        all: "All",
                        user: "Select employee",
                        created_by: "Select created by",
                        payment_receipt_type: 'Type',
                        branch: "Select branch",
                        name: 'Name and code of sender/receiver',
                        phone: 'Phone',
                    }
                },
                placeholder:{
                    search: "By code",
                },
                method: {
                    title: "Method",
                    value: {
                        card: "Card",
                        banking: "Transfer"
                    }
                },
                button: {
                    clearFilter: "Remove filter",
                    operation: "Option",
                    receipts: "Add receipt",
                    export: "Export file",
                    save: "Save",
                },
                detail: {
                    code: "Code",
                    time: "Time",
                    value: "Value",
                    method: "Method",
                    payer: "Submitter",
                    phone: "Phone number",
                    address: "Address",
                    branch: "Branch",
                    created_by: "Created by",
                    user: "Employee",
                    object_payer: "Submitting object"
                },
                alerts: {
                    update_success: "Receipt {code} has been updated successfully",
                    update_error: "Receipt {code} update failed",
                    validate_error_note: "Notes cannot be larger than 255 characters",
                },
                toLink:{
                    invoice_receipt: "Receipts are automatically created associated with invoices",
                    order_receipt: "Receipts are automatically created associated with orders",
                    return_import_for_supplier_receipt: "A receipt is automatically created associated with the imported products return",
                    delivery_receipt: "Receipts are automatically created associated with bills of lading",
                }
            },
            modalBankBook: {
                title: "Add receipt: ",
                titleContentLeft: "General information: ",
                titleContentRight: "Document: ",
                applicableObject: "Object",
                amount: "Value",
                reasonForPayment: "Reason for submission: ",
                employee: "Employee",
                method: "Method",
                note: "Note: ",
                planningDay: "Accounting date: ",
                documentNumber: "Document number: ",
                planning: "Accounting into business results",
                //placeholder
                placeholder: {
                    object: "Select object",
                    amount: "Enter the value",
                    reason: "Reason for submission",
                    employee: "Select employee",
                    method: "Choose a submission method",
                    description: "Enter notes",
                    planningDay: "Enter the accounting date",
                    documentNumber: "Enter the document number"
                }
            },

            // Vận đơn
            DeliveryIndex: {
                title: "Bill of lading",
                title_document: "Bill of lading | OmiPos",
                created_at: "Creation time",
                completed_at: "Completion time",
                status: "Delivery status",
                transporter: "Delivery partner",
                area_delivery: "Delivery area",
                cod: "Collect payment on behalf (COD)",
                receiver_info: "Receiver's information",
                invoice_info: "Invoice information",
                delivery_info: "Shipping information",
                receiver_name: "Receiver",
                receiver_phone: "Phone",
                receiver_address: "Address",
                receiver_area: "Area",
                receiver_ward: "Wards",
                invoice_code: "Invoice code",
                branch: "Branch",
                customer_name: "Customer",
                total_products: "Product quantity",
                values: "Value",
                note: "Note",
                delivery_code: "Bill of lading code",
                created_by: "Created by",
                weight: "Weight",
                size: "Size",
                delivery_by: "Deliver",
                service: "Service",
                delivery_time: "Delivery time",
                cod_still: "Still need to collect (COD)",
                price: "Fee paid to delivery partner",
                price_still: "Rest to pay delivery partner",
                column_status: "Status",
                control_code: "Reconciliation code",
                conversion_rate: "Conversion rate",
                expected_delivery: "Expected delivery",
                price_declaration: "Declare price",
                take_to_post_office: "Bring it to the post office yourself",
                paying_by: "Paying party",
                attention_when_delivering: "Note",
                discount_code: "Discount code",
                total_fare: "Total fare",
                receipt_paid_the_fee: "The recipient pays the fee",
                total_revenue_of_the_recipient: "Total revenue of recipient",
                infoTitle: "Display a list of payment (Collection and fees via Delivery Partners)",
                position: "Location",
                infoUpdate: "Updated information",
                placeholder: {
                    search: "According to bill of lading code",
                    transporter: "Choose delivery person",
                    area_delivery: "Select Province/City - District",
                    value: "Value",
                },
                button: {
                    create: "Delivery partner",
                    import: "Import",
                    export: "Export file",
                    update: "Update",
                    in_active: "InActive",
                    remove: "Delete",
                    print: "Print",
                    payment: "Payment",
                    save: "Save",
                    support: "Submit a support request",
                    cancel: "Cancel"
                },
                tabs: {
                    info: "Information",
                    history: "Delivery history",
                    paymentTrans: "Delivery partner payment history"
                },
                modal: {
                    modalCancel: {
                        title: "Cancel bill of lading",
                        content: "Are you sure you want to cancel this bill of lading?",
                        button: {
                            confirm: "OK",
                            skip: "Close"
                        }
                    },
                    modelPaymentAndCreateRefund: {
                        title: "Payment with delivery partner",
                        invoice_code: "Code Bill",
                        delivery_code: "Bill of lading code",
                        transporter: "Delivery partner",
                        total_fee: "Total (COD - Fee paid to Delivery Partner)",
                        cod: "Collection fee (COD)",
                        fee_ship: "Fee paid to Delivery Partner",
                        time: "Time",
                        method: "Method",
                        button: {
                            type_receipt: 'Create receipt',
                            type_payment: 'Create payment',
                            type_all: 'Create receipts and payments',
                            skip: "Skip"
                        },
                        message_success: "Payment for invoice {code} successful",
                        message_create_multiple_success: "Payment for invoice successful",
                        message_error: "Payment for invoice {code} failed",
                        message_validate_time_error: "Voucher time exceeds current time.",
                        message_validate_created_at: "Voucher time exceeds invoice time {code}.",
                    }
                },
                alert: {
                    update_success: "Updated data successfully",
                    payment_error: "The bill of lading has been paid or not"
                }
            },

        /* Hết giao dịch */

        /* Báo cáo */
            // Báo cáo chi tiết bán hàng
            ReportSaleDetails: {
                title: "Sell",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Product group",
                customer: "Customer",
                customer_group: "Customer group",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                type_currency: "Currency",
                invoice: "Invoice code",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search for customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                    price_list: "Select price list",
                    invoice: "By invoice code"
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo bán hàng theo kênh bán
            ReportBySaleChannel: {
                title: "Sales",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Product group",
                customer: "Customer",
                customer_group: "Customer groups",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo bán hàng theo nhân viên
            SaleReportByEmployee: {
                title: "Sales",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Product group",
                customer: "Customer",
                customer_group: "Customer groups",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo bán hàng theo chi nhánh
            SaleReportByBranch: {
                title: "Sales",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Product group",
                customer: "Customer",
                customer_group: "Customer groups",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Phân tích chi tiết danh thu theo thời gian
            ReportRevenuesTime: {
                title: 'Sales',
                title_document: 'Analyze revenue over time'
            },

            // Phân tích chi tiết danh thu theo chi nhánh
            ReportRevenuesBranch: {
                title: 'Sales',
                title_document: 'Analyze revenue by branch'
            },

            // Phân tích chi tiết danh thu theo bảng giá
            SaleReportRevenuePriceList: {
                title: "Sales",
                title_document: "Sales report | OmiPos",
                time: "Time",
                options: "Display options",
                product: "Product",
                category: "Group of products",
                customer: "Customer",
                customer_group: "Customer groups",
                sale_channel: "Sales channel",
                branch: "Branch",
                priceList: "Price list",
                productUnit: "Unit: Main unit",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Phân tích chi tiết danh thu theo nhóm khách hàng
            SaleReportRevenueCustomerGroup: {
                title: "Sales",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Product",
                category: "Group of products",
                customer: "Customer",
                customer_group: "Customer groups",
                sale_channel: "Sales channel",
                productUnit: "Unit: Main unit",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },                
                button: {
                    export: "Export file"
                },
                table: {
                    STT: "No.",
                    productCode: "Product code",
                    productName: "Product name",
                    productUnit: "Unit",
                    regionNorth: "NORTH",
                    regionCenter: "CENTRAL REGION",
                    regionSouth: "SOUTHERN",
                    quantity: "Quantity",
                    price: "Value",
                    total: "Total"
                }
            },

            // Sổ mua hàng chi tiết
            ReportPurchaseDetails: {
                title: "Purchase",
                title_document: "Purchase report | OmiPos",
                time: "Time",
                purchase_order: "Purchase receipt",
                product: "Product",
                category: "Group of products",
                customer: "Customer",
                customer_group: "Customer group",
                supplier_group: "Supplier group",
                supplier: "Supplier",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                type_currency: "Currency",
                invoice: "Invoice code",
                placeholder: {
                    purchase_order: "By the number of votes entered",
                    product: "By  code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    supplier_group: "Search supplier groups",
                    supplier: "Search supplier",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                    price_list: "Select price list",
                    invoice: "Invoice code"
                },
                button: {
                    export: "Export file"
                }
            },

            // Sổ chi tiết hàng hóa
            ReportStockDetails: {
                title: "Products",
                title_document: "Commodity report | OmiPos",
                time: "Time",
                product: "Product",
                category: "Group of products",
                customer: "Customer",
                customer_group: "Customer group",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                invoice: "Invoice code",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                    price_list: "Select price list",
                    invoice: "Invoice code"
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo nhập xuất tồn
            ReportInventoriesIndex: {
                titleHeader: 'Commodity report',
                title: 'Import and export report | OmiPos'
            },

            // Báo cáo nhập xuất tồn chi tiết
            ReportInventoriesIndexDetail: {
                titleHeader: 'Commodity report',
                title: 'Detailed import and export reports | OmiPos'
            },

            // Báo cáo giá trị kho
            ReportValueBranch: {
                title: "Products",
                title_document: "Sales report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Products group",
                customer: "Customer",
                customer_group: "Customer group",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo tồn kho theo hạn sử dụng
            SaleReportByConsignment: {
                title: "Products",
                title_document: "Commodity report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Products group",
                customer: "Customer",
                customer_group: "Customer group",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo tồn kho theo vị trí
            ReportByLocation: {
                title: "Products",
                title_document: "Commodity report | OmiPos",
                time: "Time",
                product: "Products",
                category: "Products group",
                customer: "Customer",
                customer_group: "Customer group",
                sale_channel: "Sales channel",
                branch: "Branch",
                created_date: "Date",
                from_date: "From",
                to_date: "To",
                price_list: "Price list",
                placeholder: {
                    product: "By code, product name",
                    category: "Search product groups",
                    customer: "By code, customer name",
                    customer_group: "Search customer groups",
                    sale_channel: "Choose sales channel",
                    branch: "Select branch",
                },
                button: {
                    export: "Export file"
                }
            },

            // Báo cáo tồn kho theo lô date và vị trí

        /* Hết báo cáo */

        /* Thiết lập */
            // Thiết lập cửa hàng
            SettingPage: {
                title_left_top: 'Management setup',
                title_left_bot: 'Shop configurations',
                success: 'Settings updated successfully',
                product: {
                    title: 'Products',
                },
                partner: {
                    title: 'Partner',
                    modal: {
                        debt: {
                            title: 'Set up customer debt alerts'
                        },
                        transporter: {
                            title: 'Connect with transportation partners'
                        }
                    }
                    
                },
                transaction: {
                    title: 'Transaction',
                    modal: {
                        setting_time_return: {
                            title: 'Set up delivery time',
                            title_value_time_return: 'Return after purchase',
                            date: 'day',
                            note_value_time_return: 'The system only allows returns within the set number of days from the date of purchase',
                            process_option: 'Handling overdue returns: ',
                            note_warning: 'If the return deadline is overdue, the system will display a warning but still allow the user to return the products',
                            button_save: 'Save',
                            button_skip: 'Skip',
                        },
                    }
                },
                store: {
                    title: 'Store information'
                },
                security: {
                    title: 'Security settings'
                },
                webhook: {
                    title: 'Kiotviet synchronization'
                }
            },

            // Quản lý mẫu in
            

            // Quản lý chi nhánh
            branchIndex: {
                title: "Branch",
                branch_status: "Status",
                branch_name: "Branch name",
                branch_phone: "Phone",
                branch_email: "Email",
                branch_work_day: "Working day",
                branch_address: "Address",
                branch_area_delivery: "Delivery area",
                branch_ward: "Wards",
                tabs:{
                    info: "Information",
                    user: "User",
                    address_get_order: "Pick-up address",
                },
                button: {
                    add: "Branch",
                    update: "Update",
                    inActive: "InActive",
                    active: "Active",
                    remove: "Delete",
                },
                status:{
                    all: "All",
                    active: "Active",
                    inActive: "InActive",
                },
                placeholder:{
                    search: "Branch name",
                    province: "Select province/city",
                    district: "Select district",
                    ward: "Select ward/commune",
                    address: "Enter the address",
                    email: "Enter email",
                    phone: "Enter partner phone number",
                    name: "Enter branch name",
                },
                modal: {
                    title_create: "Add new branch",
                    title_update: "Branch update",
                    title_confirm: "Confirm",
                    branch_province: "Province/City",
                    branch_district: "District",
                    branch_ward: "Wards",
                    button: {
                        save: "Save",
                        skip: "Skip",
                        confirm: "Agree",
                    },
                    alerts: {
                        confirm_in_active: "Are you sure you want to stop operations of this branch.",
                        confirm_active: "Do you want to reopen this branch?",
                        success_create: "Added new branch successfully",
                        success_update: "Branch update successful",
                        confirm_skip_create: "Do you want to cancel adding branches?",
                        confirm_skip_update: "Do you want to cancel branch updating?",
                        success_remove: "Delete branch {name} successfully"
                    },
                    title_remove: "Confirm",
                    content_remove: "The system will completely delete this branch",
                }
            },

            // Quản lý khuyến mại

            // Quản lý coupon
            CouponIndex: {
                title: "Coupon",
                document_title: "Coupons | OmiPOS",
                status: "Status",
                code: "Release code",
                time: "Time",
                categories: "Purchase group",
                group: "Group",
                all_categories: "All product groups",
                product: "Purchased products",
                name: "Program name",
                value: "Coupon value",
                max: "Max",
                min_total_price: "Minimum total amount",
                note: "Note",
                placeholder: {
                    search: 'By code, release name',
                },
                button: {
                    add: "Add new releases",
                    update: "Update",
                    copy: "Copy",
                    remove: "Delete",
                },
                tabs:{
                    info: "Information",
                    list_coupon: "Coupon list",
                    history: "Usage history",
                },
                table: {
                    list_coupon: {
                        code: "Coupon codes",
                        release_date: "Release date",
                        use_date: "Date",
                        number_coupon: "Number of coupons displayed on the page",
                        placeholder: {
                            search: "Search coupon code"
                        },
                        button: {
                            export: "Export file"
                        }
                    },
                    history: {
                        code: "Code",
                        time: "Time",
                        sold_by: "Seller",
                        customer: "Customer",
                        branch: "Branch",
                        revenue: "Revenue",
                        value_coupon: "Coupon value",
                        placeholder: {
                            search: "Search coupon code"
                        },
                        button: {
                            export: "Export file"
                        }
                    }
                },
                modal:{
                    create_or_update: {
                        title_create: "Add coupon issuance",
                        title_update: "Update coupon issuance",
                        code: "Release code",
                        name: "Name of release",
                        value_coupon: "Coupon value",
                        max: "Max",
                        currency_vnd: "VNĐ",
                        currency_percent: "%",
                        effect: "Effect",
                        to: "To",
                        status: "Status",
                        status_active: "Activated",
                        status_inactive: "Inactived",
                        categories: "Purchase group",
                        choose_categories: "Select a purchase group",
                        group: "Group",
                        all_categories: "All product groups",
                        products: "Purchased products",
                        total_price: "Total cost of products",
                        tooltip: {
                            code: "The release code is unique information",
                            categories: "Set the conditions for applying the coupon to be that the invoice requires a purchase of a specific item or group of items",
                            total_price: "When the invoice has a total amount equal to this minimum amount, it will be paid by coupon",
                        },
                        placeholder: {
                            code: "Automatic code",
                            name: "Name of promotional program",
                            products: "Select purchase",
                            note: "Note",
                        },
                        tabs: {
                            info: "Information",
                            list_coupon: "Coupon list",
                        },
                        button:{
                            save: "Save",
                            skip: "Skip",
                            action: "Option",
                            release_coupon: "Issuing coupons",
                            cancel_coupon: "Cancel coupon",
                            add: "Coupon list"
                        },
                        table: {
                            code: "Coupon codes",
                            release_date: "Release date",
                            use_date: "Date",
                            status: "Status",
                            number_coupon: "Number of coupons displayed on the page",
                            placeholder: {
                                search: "Search coupon code",
                                choose_method: "Choose form"
                            },
                            button: {
                                export: "Export file"
                            }
                        },
                        alert: {
                            title_confirm: "Add coupon issuance",
                            content_confirm: "You need to save a coupon release before creating a coupon list. Do you want to save?",
                            create_list_coupon_success: "Add coupon list successfully",
                            create_success: "Add new release successfully",
                            update_success: "Successful release update",
                        }
                    }
                }
            },

            // Quản lý voucher
            VoucherIndex: {
                title: "Voucher",
                document_title: "Vouchers | OmiPOS",
                branch: "Branch",
                status: "Status",
                code: "Release code",
                time: "Time",
                categories: "Purchase group",
                group: "Group",
                all_categories: "All product groups",
                product: "Purchased products",
                name: "Name of release",
                value: "Denominations",
                min_total_price: "Minimum total amount",
                note: "Note",
                placeholder: {
                    search: 'By code, release name',
                    branch: "Select branch",
                },
                button: {
                    add: "Add new releases",
                    update: "Update",
                    copy: "Copy",
                    remove: "Delete",
                },
                tabs:{
                    info: "Information",
                    list_voucher: "Voucher list",
                    history: "Usage history",
                },
                table: {
                    list_voucher: {
                        code: "Voucher code",
                        release_date: "Release date",
                        end_date: "Expiration date",
                        number_voucher: "The number of vouchers displayed on the page",
                        placeholder: {
                            search: "Search voucher code"
                        },
                        button: {
                            export: "Export file"
                        }
                    },
                    history: {
                        code: "Code",
                        time: "Time",
                        sold_by: "Seller",
                        customer: "Customer",
                        branch: "Branch",
                        revenue: "Revenue",
                        value_voucher: "Voucher value",
                        placeholder: {
                            search: "Search voucher code"
                        },
                        button: {
                            export: "Export file"
                        }
                    }
                },
                modal:{
                    create_or_update: {
                        title_create: "Add voucher issuance",
                        title_update: "Update voucher issuance",
                        code: "Release code",
                        name: "Name of release",
                        value_voucher: "Denominations",
                        max: "Max",
                        effect: "Effective from",
                        to: "To",
                        status: "Status",
                        status_active: "Activated",
                        status_inactive: "Inactived",
                        categories: "Purchase group",
                        choose_categories: "Select a purchase group",
                        group: "Group",
                        all_categories: "All product groups",
                        products: "Purchased products",
                        total_price: "Total cost of products",
                        apply_multiple_voucher: "Apply multiple vouchers on one invoice",
                        apply_time: "Time application",
                        apply_area: "Scope of application",
                        all_system: "The whole system",
                        branch: "Branch",
                        all_customer: "All customers",
                        customer_group: "Customer group",
                        all_created_by: "All creators",
                        created_by: "Created by",
                        tooltip: {
                            code: "The release code is unique information",
                            value: "Is the value for customers to pay when making a purchase",
                            categories: "Invoices containing these groups of products will be paid with vouchers",
                            products: "Invoices containing these products will be paid by voucher",
                            total_price: "Minimum total amount to be able to pay with voucher",
                            apply_multiple_voucher: "Allow/Disallow using multiple vouchers to pay one invoice.",
                        },
                        placeholder: {
                            code: "Automatic code",
                            name: "Name of release",
                            products: "Select products",
                            note: "Note",
                        },
                        tabs: {
                            info: "Information",
                            apply: "Apply",
                            list_voucher: "List of vouchers",
                        },
                        button:{
                            save: "Save",
                            skip: "Skip",
                            action: "Option",
                            release_voucher: "Issuing vouchers",
                            cancel_voucher: "Cancel voucher",
                            add: "Voucher list",
                            voucher: "Voucher",
                            import: "Import",
                        },
                        table: {
                            code: "voucher code",
                            release_date: "Release date",
                            end_date: "Expiration date",
                            use_date: "Date",
                            status: "Status",
                            number_voucher: "The number of vouchers displayed on the page",
                            placeholder: {
                                search: "Search for voucher code",
                                choose_method: "Choose form"
                            },
                            button: {
                                export: "Export file"
                            }
                        },
                        alert: {
                            title_confirm: "Add voucher issuance",
                            content_confirm: "You need to save the voucher issuance before creating the voucher list. Do you want to save?",
                            create_list_voucher_success: "Add voucher list successfully",
                            create_success: "Add new release successfully",
                            update_success: "Successful release update",
                        }
                    },
                    create_list_voucher: {
                        title: "Add voucher list",
                        number_voucher: "Number of vouchers",
                        voucher_code_length: "Voucher code length",
                        first_character: "Start character",
                        last_character: "End character",
                        tooltip: {
                            number_voucher: "A new number of vouchers will be added",
                            voucher_code_length: "Note that the length of the voucher code when subtracting the starting and ending characters must be greater than or equal to 6 to ensure the minimum length and uniqueness of the voucher.",
                            first_character: "The generated voucher code will start with these characters",
                            last_character: "The generated voucher codes will end with these characters"
                        },
                        button: {
                            save: "Save",
                            skip: "Skip",
                        },
                        alerts: {
                            validate_number_voucher: "You have not entered the number of vouchers",
                            validate_voucher_code_length: "The length of the voucher code when subtracting the starting and ending characters must be greater than or equal to 6",
                            success: "Created a new voucher code successfully"
                        }
                    },
                    create_voucher_code: {
                        title: "Add vouchers",
                        voucher_code: "Voucher code",
                        button: {
                            add: "Add vouchers",
                            save: "Save",
                            skip: "Skip",
                        },
                        alerts: {
                            validate_voucher_code: "Voucher code cannot be blank",
                            validate_voucher_code_is_exist: "Voucher code {code} already exists",
                            success: "New voucher added successfully"
                        }
                    },
                    remove: {
                        title: "Confirm",
                        content: "The system will completely delete this voucher issuance, do you want to do?",
                        button: {
                            confirm: "Approve",
                            skip: "Skip",
                        },
                        alerts:{
                            success: "Deleted voucher issuance successfully",
                            error: "Voucher has been issued or has been used. You cannot delete!"
                        }
                    }
                }
            },

            // Quản lý vai trò

            // Quản lý phân quyền

            // Lịch sử thao tác
            HistoryIndex: {
                title: "Operation history",
                user: "Staff",
                function: "Function",
                branch: "Branch",
                action: "Operation",
                time: "Time",
                document_title: "Operation history | OmiPos",
                today: "Today",
                week: "This week",
                month: "This month",
                three_month_ago: "Last 3 months",
                other: "Another choice",
                from_date: "Since",
                to_date: "To date",
                placeholder: {
                    search: "Enter content",
                    branch: "Select branch",
                    user: "Select employee",
                    function: "Select function",
                },
                button: {
                    save: "Save",
                    process: "Processing tickets",
                    update: "Update",
                    print: "Print",
                    cancel: "Cancel",
                    export: "Export file",
                },
                tabs: {
                    content: "Details"
                }
            },

            // Quản lý mẫu email/zalo
            SendMailZaloIndex: {
                title: "Email/zalo templates",
                title_document: "Email/zalo template | OmiPos",
                modal: {
                    title_send: "Choose an email template",
                    title_create: "Add new email/zalo template",
                    title_update: "Update email/zalo templates",
                    title_remove: "Delete email/zalo templates",
                    remove_content: "Do you want to delete this email/zalo template?",
                    template_name: "Email/zalo template name: ",
                    file: "Attached files: ",
                    placeholder: {
                        file: "Select attached file"
                    },
                    read_before: "Preview email/zalo templates",
                    button: {
                        save: "Save",
                        skip: "Skip",
                        remove: "Delete",
                        confirm: "Agree",
                        send: "Send",
                    },
                    alerts:{
                        success_create: "Successfully added new email/zalo template",
                        success_update: "Updated email/zalo template successfully",
                        success_remove: "Successfully deleted email/zalo template",
                        error_create: "Adding a new email/zalo template failed",
                        error_update: "Updating email/zalo templates failed",
                        error_remove: "Deleting email/zalo template failed",
                    }
                }
            },

            // Danh sách đối tác zalo
            zaloGroups: {
                name: "Zalo group",
                templateTitle: "Zalo template",
                zaloTitle: "List of zalo partners",
                zalo: "Zalo group list",
                numberRecord: "Number of records",
                logOut: "Logout",
                placeholder: "By group name"
            },
            ModalLoginZalo: {
                title: "LOG IN TO ZALO OA",
                refresh_token: "Refresh Token code",
                app_id: "Application ID",
                app_secret: "Application Secret",
                webhook_secret: "Webhook Secret",
                placeholder: {
                    refresh_token: "Enter the refresh token code",
                    app_id: "Enter the application ID",
                    app_secret: "Enter the secret code from the administrator",
                    webhook_secret: "Enter the webhook code from the admin",
                },
                button: {
                    login: "Log in",
                    skip: "Skip"
                },
                message: {
                    login_success: "Logged in successfully",
                    login_failed: "Login failed",
                }
            },
            ModalLogoutZalo: {
                title: "Confirm",
                content: "Are you sure you want to log out of your Zalo OA account from the system?",
                button: {
                    logout: "Log out",
                    skip: "Skip"
                },
                message: {
                    logout_success: "Signed out successfully",
                    logout_failed: "Logout failed",
                }
            },
            ModalSendZalo: {
                title: 'Select the form to send to zalo'
    
            },


        /* Hết thiết lập */

        /* Modal */
            modalConfirm: {
                title: "Confirm",
                titleCancelOrderSupplier: "Cancel the entered order form",
                titleCancelOrder: "Cancel order form",
                titleCancelInvoice: "Cancellation invoice",
                titleCancelTicketPayment: "Cancel payment slip",
                content: "Do you want to cancel changes?",
                contentCancelHistoryPaymentOrderSupplier: "Do you want to cancel the related deposit?",
                contentCancelTicketPayment: "Do you want to cancel the related payment slips?",
                button: {
                    confirm: "Agree",
                    skip: "Skip",
                },
            },
            modalPaymentSupplier: {
                title_order_for_supplier: "Prepayment for order form entering {code}",
                title_import_for_supplier: "Prepayment for goods receipt {code}",
                code: "Payment code",
                created_at: "Time",
                amount: "Spending money",
                method: "Method",
            },
            modalCustomerPaid: {
                title_order: "Prepayment for orders {code}",
                code: "Receive code",
                created_at: "Time",
                amount: "Revenue",
                method: "Method",
            },
            ModalConnectVTP: {
                title: "LOG IN VIETTEL POST",
                username: "Account",
                password: "Password",
                placeholder: {
                    username: "Account",
                    password: "Password",
                },
                button: {
                    login: "Log in"
                }
            },
            ModalCreateUpdatePickup: {
                title: "Add new pickup address",
                title_update: "Update delivery address",
                address: "Pick-up address",
                province: "Province/City",
                district: "District",
                ward: "Wards",
                phone: "Phone number",
                placeholder_address: "Number of houses, buildings, alleys, roads",
                placeholder_province: "Select Province/City",
                placeholder_district: "Select District",
                placeholder_ward: "Select Ward/Commune",
                placeholder_phone: "Enter partner phone number",
                message_success: "Successfully created new pickup address",
                message_success_update: "Updated pickup address successfully",
                confirm_title: "Confirm",
                confirm_content: "Are you sure you want to delete the pickup address?",
                message_success_delete: "Successfully deleted the pickup address"
            },
            ModalCreateUpdateDelivery: {
                title: "Add new receiving address",
                title_update: "Update receiving address",
                name_address: "Address name",
                name: "Recipient's name",
                phone: "Phone number",
                address_value: "Receiving address",
                provinceId: "Province/City",
                district: "District",
                ward: "Wards",
                message_success: "Successfully created new delivery address",
                confirm_title: "Confirm",
                confirm_content: "Are you sure you want to remove the shipping address?",
            },
            ModalFeeAdjustment: {
                title: "Adjust",
                current: "Current debt needs to be paid",
                updatedAt: "Adjustment date",
                adjustedValue: "Adjusted debt value",
                description: "Note",
                createdBy: "Created by",
                cancel: "Cancel",
                update: "Update",
                content: "Are you sure you want to delete the adjustment"
            },
            ModalcreateDelivery: {
                titleCreate: 'Create another bill of lading',
                description: "Note",
            },
            
            ModalSelectOptionExport: {
                title: "File export options",
                content: "Page options (Total {totalNumbers} of records)",
                option: "Export files of records from {from} - {to}",
                export: "Export file",
                skip: "Skip"
            },
            ModalConnectGHN: {
                title: "LOG IN Giao hàng nhanh ",
                token: "Token code",
                IDShop: "Shop ID",
                name: "Shop name",
                placeholder: {
                    token: "Enter token code",
                    IDShop: "Enter the store ID",
                    name: "Enter the store name",
                },
                button: {
                    login: "Log in"
                }
            },

        /* Hết modal */

        /* Bán hàng */
            orderFromTab: {
                message_create_address : "Add shipping address to check shipping rates",
                service_type: "Type of service",
                shipping_fee: "Delivery charges",
                delivery_code: "Bill of lading code",
                delivery_time: "Delivery time",
                delivery_status: "Delivery status",
                placeholder: {
                    delivery_code: "Bill of lading code",
                }

            },

        /* Hết bán hàng */
            sendMail: {
                success: "Email sent successfully!",
                failing: "Email send failed!"
            },

            ModalCreateTemporaryConfirm: {
                title: "The requested quantity is greater than the quantity of the requested branch",
                confirm: "Do you want to temporarily save this order?"
            },

            ModalImportDepartment: {
                title: "Import departments from data file"
            }
        }
    }
};

export default messages;