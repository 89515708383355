<template>
    <div ref="modalConfirm" class="modal fade mx-0 modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2 mx-3">
                        {{ $t('message.common.confirm') }}
                    </h5>
                    <button type="button" class="close" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        <h6>
                            {{ $t('message.common.confirmCopyProduct') }}
                        </h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        <button class="btn btn-secondary text-white" @click="skip()"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.table thead th
#modalConfirm .modal-header {
    border-bottom: 0 !important;
}
</style>
<script>
import {$post, $alert} from "@/lib/utils";
import Paginate from "@/components/Paginate";
import toastr from 'toastr';
export default {
    name: "ModalConfirmCopyProduct",
    components: {Paginate},
    data(){
        return {
            id: null,
        }
    },
    props: ['message'],
    methods: {
        show(id) {
            if (id) {
                this.id = id;
                window.jQuery(this.$refs.modalConfirm).modal('show');
            } 
        },
        skip() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        async confirm() {
            const result = await $post('/v1/products/copy', {id: this.id});
            if (result.result.status == true) {
                toastr.success(this.$t('message.common.copyProductSuccess'));
                this.$emit('updated', '');
                window.jQuery(this.$refs.modalConfirm).modal('hide');
            }
        }
    }
}
</script>

