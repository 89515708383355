<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalStopCustomer" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header my-0 pt-2 mx-2 head-remove">
                    <h5 class="modal-title py-0">{{ $t('message.TransporterIndex.modal.modalStop.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2 py-0">
                    {{ $t('message.TransporterIndex.modal.modalStop.content') }}
                    <div class="col-12 d-flex justify-content-end my-3">
                        <button class="btn btn-danger mr-2" @click="submit">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.TransporterIndex.modal.modalStop.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white py-2" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.TransporterIndex.modal.modalStop.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $get, $post, $alert } from "@/lib/utils";
import emitter from "@/lib/emitter";
import toastr from 'toastr';
export default {
    name: 'ModalTransporterIndexStop',
    props: {},
    data() {
        return {
            id: '',
            action: ''
        };
    },
    methods: {
        show(id, action) {
            this.id = id;
            this.action = action         
            window.jQuery(this.$refs.modal).modal('show');
        },
        async submit() {           
            const res = await $post(`/v1/transporters/stop`, {id: this.id, action: this.action});            
            if(res && res.status?.code && res.status.code == 200 && res.result?.code && res.result.code==200){
                toastr.success(res.result.message);
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('loadData');                
            }
            else {
                if(res.result?.code && res.result.code==422){
                    toastr.error(res.result.message);
                    window.jQuery(this.$refs.modal).modal('hide');            
                    this.$emit('loadData');
                }                
            }           
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>