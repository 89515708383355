<template>
    <div ref="ModalCheckSettingReturnTime" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="ModalCheckSettingReturnTime" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-body" >
                    <div class="col-12 py-4">
                        <h6 v-text="this.content"></h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="hide()">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import { $alert, $post } from '@/lib/utils';

export default {
    name: "ModalCheckSettingReturnTime",
    data(){
        return {
            content: null,
            id: null,
            type: null,
            entry: null,
            emit: false,
        }
    },
    methods: {
        show(entry, type, emit = false) {
            this.content = null;
            this.entry = entry;
            this.id = entry.id;
            this.type = type;
            this.emit = emit;
            if(!this.id || !this.type){
                this.hide();
            }
            this.load();
        },
        async load(){
            const res = await $post('/v1/invoices/check-return-time', {
                id: this.id,
                type: this.type
            });
            if(res && res.result.status){
                this.confirm();
            }else if(res && !res.result.status && res.result.warning){
                this.content = res.result.message;
                window.jQuery(this.$refs.ModalCheckSettingReturnTime).modal('show');
            }else if(res && !res.result.status && res.result.notAllowReturn){
                $alert({code: 500, message: res.result.message});
            }
        },
        confirm(){                      
            this.hide();
            if(this.emit){
                this.$emit('confirm', this.entry);
                return;
            }
            switch (this.type) {
                case this.$constant.settingReturnTimeTypeInvoice:
                    this.$router.replace({
                        name: 'CreateOrder',
                        query: {
                            returnInvoice: this.entry.code
                        }
                    });
                    break;
            
                case this.$constant.settingReturnTimeTypeRefund:
                    this.$router.replace({
                        name: 'CreateOrder',
                        query: {
                            returnRefunds: this.entry.code
                        }
                    });
                    break;
            }
        },
        hide() {
            window.jQuery(this.$refs.ModalCheckSettingReturnTime).modal('hide');
        },
    }
}
</script>

