<template>
    <div class="row">
        <ActionBar back-url="/products/index"
                   v-if="currentTab.showActionBar && this.$route.query.action!='detail'"
                   @reset="load"
                   @action="save()" :showResetButton="true"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.id ? 'Quản lý hàng hóa: ' + entry.name : 'Thêm hàng hóa'"></h1>
            </div>

            <TabNavigation @tabChange="tabChange"
                           @onValidate="onValidate"
                           ref="tab" :tabs="this.type == 2 ? tabProduct2 : tabs"/>
        </div>
    </div>
</template>

<script>
import ActionBar from "../../components/ActionBar";
import TabNavigation from "../../components/TabNavigation";
import ProductFormTabMain from "./tabs/ProductFormTabMain";
import ProductFormTabThreshold from "./tabs/ProductFormTabThreshold";
import ProductFormTabProductCombo from "./tabs/ProductFormTabProductCombo";
import ProductFormTabInventory from "./tabs/ProductFormTabInventory";
import ProductFormTabBatches from "./tabs/ProductFormTabBatches";

import {markRaw} from "vue";
import {$alert, $get, $post, scrollToErrors, setArray} from "../../lib/utils";
import toastr from 'toastr';
import store from "../../store";

export default {
    name: "ProductForm",
    components: {TabNavigation, ActionBar},
    data() {
        const unitNameMap = {};
        store.state.GlobalConfig.units.forEach(a => {
            unitNameMap[a.id] = a.name;
        });

        return {
            unitNameMap: markRaw(unitNameMap),
            isDataLoaded: false,
            currentTab: {},
            entry: {
                categoryId: []
            },
            type: '',
            errors: {},
            tabs: [
                {id: 'TabMain',showActionBar: true, name: 'Thông tin', component: markRaw(ProductFormTabMain)},
                {id: 'TabThreshold', showActionBar: true, name: 'Định mức tồn' , component: markRaw(ProductFormTabThreshold)},
                {id: 'TabCombo', showActionBar: true, name: 'Hàng hóa thành phần' , component: markRaw(ProductFormTabProductCombo)},
                {id: 'TabInventory', showActionBar: false,name: 'Tồn kho' , component: markRaw(ProductFormTabInventory)},
                {id: 'TabBatches', showActionBar: false, name: 'Lô-Date' , component: markRaw(ProductFormTabBatches)},
            ],

            tabProduct2: [
                {id: 'TabMain',showActionBar: true, name: 'Thông tin', component: markRaw(ProductFormTabMain)},
                {id: 'TabCombo', showActionBar: true, name: 'Hàng hóa thành phần' , component: markRaw(ProductFormTabProductCombo)},
                {id: 'TabBatches', showActionBar: false, name: 'Lô-Date' , component: markRaw(ProductFormTabBatches)},
            ],

        }
    },
    methods: {
        tabChange(tab) {
            this.currentTab = tab;
            if (this.$route.query.id) {
                this.load();
            } else {
                this.setTabData({
                    isDataLoaded: true
                })
            }
        },
        setTabData(values) {
            this.$refs.tab.setTabData(values);
        },
        getTabData(key) {
            return this.$refs.tab.getTabData(key)
        },
        onValidate() {
            const productUnits = this.$refs.tab.getTabData('productUnits');
            this.validateProductUnits(productUnits);
        },
        //----------------------------------------------------------------
        // Validate mã hàng
        isEmptyQSelect(name, strInogre){            
            let result = false;
            name.forEach(value => {                
                if(value.innerText !== "" && strInogre !== value.innerText) result = false;
                else result = true
            });
            return result;
        },
        isEmptyInput(name, strInogre){            
            let result = false;            
            name.forEach(value => {                
                if(value.children[0].value !== "" && strInogre !== value.children[0].value) result = false;
                else result = true
            });
            return result;
        },
        //----------------------------------------------------------------
        validateProductUnits(productUnits) {
            let lengthDel = 0;
            productUnits.forEach(a => {
                a.errors = {};
                if (!a.deleted) {
                    lengthDel++;
                }
            });

            if (lengthDel === 0) {
                toastr.error('Vui lòng thêm ít nhất 1 đơn vị của hàng hóa');
                return;
            }

            let idMap = {}, conversionMap = {};
            let validateOK  = true;
            productUnits.forEach(productUnit => {
                if (productUnit.deleted) {
                    return;
                }

                if (!productUnit.unitId) {
                    validateOK = false;
                    productUnit.errors.unitId = [`Vui lòng chọn đủ đơn vị`];
                }

                if (!productUnit.conversionValue) {
                    validateOK = false;
                    productUnit.errors = ['Vui lòng nhập đủ đơn vị'];
                }

                if (!productUnit.price) {
                    validateOK = false;
                    productUnit.errors.price = ['Vui lòng nhập đủ giá'];
                }

                if (productUnit.isBase && productUnit.conversionValue !== 1) {
                    validateOK = false;
                    productUnit.errors.conversionValue = ['Đơn vị cơ bản phải có giá trị quy đổi bằng 1'];
                }

                if ( idMap[productUnit.unitId] ) {
                    validateOK = false;
                    const name = this.unitNameMap[productUnit.unitId];
                    productUnit.errors.unitId = [`Đơn vị ${name} bị trùng`];

                }

                if (conversionMap[productUnit.conversionValue]) {
                    validateOK = false;
                    productUnit.errors.conversionValue = [`Giá trị quy đổi ${productUnit.conversionValue} bị trùng`]
                }

                idMap[productUnit.unitId] = true;
                conversionMap[productUnit.conversionValue] = true;
            })

            return validateOK;
        },
        async save() {
            const method = 'save' + this.currentTab.id;
            if (!this[method]) {
                alert('Method: ' + method + ' not exists');
                return;
            }

            this[method]();
        },
        async saveTabThreshold() {
            const productBranchMeta = this.getTabData('productBranchMeta');
            const res = await $post('/v1/products/saveProductBranchMeta', {
                entry: productBranchMeta,
                productId: this.entry.id
            });

            $alert(res);
        },
        async saveTabCombo() {
            const comboProducts = this.getTabData('comboProducts');
            const res = await $post('/v1/products/saveComboProducts', {
                products: comboProducts,
                parentProductId: this.entry.id
            });

            $alert(res);
        },
        async saveTabMain() {
            
            this.errors = {};
            const tab = this.$refs.tab;
            const value = tab.getTabData('entry');                
            //-------------------------------------------------
                let name = '';
                let cat_name = '';  
                let categoryId = document.getElementsByName('categoryId');
                let isEmpty = this.isEmptyQSelect(categoryId, "Vui lòng chọn");                                
                if (isEmpty == false) {
                    name = document.getElementsByClassName('q-tree-tag');                
                    name.forEach(item => {                              
                    if(item.innerText!= "") cat_name = JSON.stringify(item.innerText);
                    });     
                    value.categoryName = JSON.parse(cat_name);   
                }
                
            //-------------------------------------------------   
            
            // value.type = value.type ? value.type : this.type;
            
            const productUnits = tab.getTabData('productUnits');
            if (!this.validateProductUnits(productUnits)) {
                toastr.error('Vui lòng kiểm tra dữ liệu');
                scrollToErrors();
                return;
            } 
            if(value.categoryId.length === 0){
                value.categoryName = '';
            }            
            const res = await $post('/v1/products/save', {
                entry: value,
                productUnits,
            });

            if (res.code === -1) {
                this.setTabData({
                    errors: res.errors
                });
                scrollToErrors();
            }

            if (res.redirect) {
                location.replace(res.redirect);
                return;
            }

            $alert(res);
            if (res.code === 200) {
                localStorage.removeItem('productID');              
                this.setProductUnits(res.data.productUnits);
                location.reload();
            }
        },
        setProductUnits(productUnits) {
            setArray(productUnits, {
                deleted: false,
                isBase: false,
                errors: {}
            });

            const baseAttribute = productUnits.find(pa => pa.conversionValue == 1);

            if (baseAttribute) {
                baseAttribute.isBase = true;
            }

            this.setTabData({
                productUnits: productUnits,
            })
        },
        async load() {
            const res = await $get('/v1/products/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.setTabData({
                entry: res.data.entry,
                isDataLoaded: true,
                comboProducts: res.data.comboProducts,
                inventories: res.data.inventories,
                inventoryWithBatches: res.data.inventoryWithBatches,
                productUnits: res.data.productUnits,
                productBranchMeta: res.data.productBranchMeta,
                actualInv: res.data.actualInv
            });

            document.title = res.data.entry.name;

            this.setProductUnits(res.data.productUnits);
        },

        async loadTreeCategory() {
            const res = await $get('/v1/categories/getTreeCategories', {});

            this.categoryTree = res.result.tree ? res.result.tree : [];

        },
    },
    mounted() {

        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        } else {
            this.type = this.entry.type ? this.entry.type : 1;

        }
        this.loadTreeCategory();
    },

    watch: {
        entry: function (newValue) {
            if (newValue) {
                this.type = this.entry.type ? this.entry.type : 1;
            }
        }
    }

}
</script>

<style scoped>

</style>
