<template>
    <div class="position-relative" v-click-outside="hidePopup">
        <input type="text" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_address')" v-model.trim="keyword" @input="onSearchProduct" class="form-control pl-0 input-custom" autocomplete="off" :readonly="readonly">
        <div class="popup-product-search" v-if="isShow">
            <ul class="wrap-products" v-if="products.length">
                <li v-for="(location, index) in products" :key="index" @click="onSelect(location)">
                    <div class="product-info">
                        <p class="name-item">
                            <span v-text="location.description"></span>
                            <!-- <span v-text="' (' + product.productUnit.unitName + ')'" v-if="product.productUnit"></span> -->
                        </p>
                    </div>
                </li>
            </ul>
            <div v-else class="products-not-found">
                <span>{{$t('message.common.notFound')}}</span>
            </div>
        </div>
    </div>
    <ModalProductsAdd ref="ModalProductsAdd" @getInfoProduct="getInfoProduct" :typeEditAdd="typeEditAdd"/>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import store from "@/store";
    import ModalProductsAdd from "@/views/products/tabs/ModalProductsAdd.vue";
    let app;

    export default {
        name: "SearchLocation",
        emits: ['select'],
        components: {ModalProductsAdd},
        props: ['readonly', 'action', 'notAdd', 'toBranchId', 'priceListId', 'getAll', 'addressValue'],
        data() {
            app = this;
            return {
                products: [],
                keyword: '',
                typeEditAdd: 'ADD',
                branchId: store.state.CurrentBranch.id,
                all: this.getAll ? true : false,
                isShow: false,
            }
        },
        watch: {
            toBranchId(value) {
                if (!value) {
                    this.keyword = '';
                }
            },
            addressValue(newValue) {
                // if (newValue) {
                //     this.keyword = newValue;
                // }
                this.keyword = newValue;
            }
        },
        created(){
            if (this.addressValue) {
                this.keyword = this.addressValue;
            }
        },
        methods: {
            getInfoProduct(product) {
                if (product.data.entry && product.data.entry.type == this.$constant.productCommon && product.data.entry.branchIDs && product.data.entry.branchIDs.includes(this.branchId.toString())) {
                    this.$emit('getInfoProduct', product);
                }
            },
            onSearchProduct: debounce(async () => {
                app.callOnSearchProduct();
            }),
            async callOnSearchProduct() {
                if (this.keyword && this.keyword.length >= 3) {
                    this.isShow = true;
                    const res = await $get('/v1/suggest-address', {
                        input: this.keyword,
                    });
                    if (res.status.code == 200) {
                        this.products = res.result.predictions;
                    } else {
                        $alert(res);
                    }
                }else{
                    this.products = [];
                }
            },
            async onSelect(location) {
                this.$emit('select', location);
                this.products.length = 0;
                this.keyword = location.description ? location.description : '';
                this.isShow = false;
            },
            //  async detail (location) {
            //     const result = await $get('/v1/suggest-address', {
            //             input: this.keyword,
            //         });
            // },
            hidePopup() {
                // this.keyword = '';
                this.isShow = false;
                this.products.length = 0;
            },
            showModalProductsAdd(){
                if (!this.readonly) {
                    this.$refs.ModalProductsAdd.show();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;
        /*padding-bottom: 50px;*/

        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-products {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .products-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
</style>
