<template>
  <div ref="modal" class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog"  role="document" style="min-width: 75%; min-height: 70%">
      <div class="modal-content">
        <div class="modal-header border-0 py-2">
          <h6 class="modal-title">
            <a class="pr-3 text-dark" href="#" @click="showTab(this.$constant.printUpdateTab)">
              <span v-if="this.typeUpdate == this.$constant.typeUpdate">{{ $t('message.common.titleAddPrintTemplate') }}</span>
              <span v-else>{{ $t('message.common.titleUpdatePrintTemplate') }}</span>
       
            </a>
            <a href="#" @click="showTab(this.$constant.printInfoTab)"
              ><i class="fa fa-info-circle fa-xs text-dark"></i
            ></a>
          </h6>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-0 px-4" >  
          <ModalEditPrint
            v-if="this.type == this.$constant.printUpdateTab"
            ref="ModalEditPrint"        
            :action="this.action"
            :printTemplateId="this.printTemplateId"
            :branchIds="this.branchIds"
            @updateContent="changeContent"
            :printTemplateName="this.prtTempName"
            :printPaperID="this.printPaperId"
            @changePrtTempName="changePrtTempName"
            @changePrintPaper="changePrintPaper" 
            @getBranchList="getBranchList"            
            />
          <ModalEditPrintInfomation
            v-if="this.type == this.$constant.printInfoTab"
            ref="ModalEditPrintInfomation"
            :entry="this.entry"
            :data="this.data"
            :action="this.action" />
        </div>
        <div class="modal-footer border-0" v-if="this.type == this.$constant.printUpdateTab">
          <AppPermission :permission="[this.$permissions.printTemplates.update ?? '', this.$permissions.printTemplates.create ?? '']">
            <button type="button" class="btn btn-primary px-2" @click="save()">
              <i class="fa fa-check mr-2"></i>
              {{ $t('message.button.save') }}
            </button>
          </AppPermission>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            <i class="fa fa-ban mr-1"></i> 
            {{ $t('message.button.skip') }}
          </button>
          <AppPermission :permission="this.$permissions.printTemplates.remove?? '' ">
              <button v-if="this.typeUpdate != this.$constant.typeUpdate" type="button" class="btn btn-danger" @click="showModalRemovePrintTemplate(printTemplateId)">
                <i class="fa fa-trash-alt mr-1"></i> 
                {{ $t('message.button.remove') }}
              </button>
          </AppPermission>
        </div>
      </div>
    </div>
  </div>
  <ModalRemovePrintTemplate ref="ModalRemovePrintTemplate" @loadData="loadData"/>
</template>

<script>
import ModalEditPrint from "./ModalEditPrint";
import ModalEditPrintInfomation from "./ModalEditPrintInfomation";
import ModalRemovePrintTemplate from './ModalRemovePrintTemplate';
import { $get, $post} from "@/lib/utils";
import { markRaw } from "vue";
import toastr from "toastr";
import AppPermission from '@/components/AppPermission.vue';
export default {
  components: { ModalEditPrint, ModalEditPrintInfomation, ModalRemovePrintTemplate,AppPermission },
  mounted() {    
    this.load();
  },
  props: ["action", "templateId", "printTemplateName", "printTemplate"],
  name: "ModalEditPrintTemplate",
  data() {
    return {
      printPaperId: 2,
      type: 0,
      tabs: [
        {
          id: "TabEdit",
          showActionBar: true,
          name: "Cập nhật mẫu in",
          component: markRaw(ModalEditPrint),
        },
      ],
      entry: {},
      data: {},
      content: null,
      typeUpdate: null,
      prtTempName: null,
      printTemplateId: null,
      isChangeName:false,
      branchIds: []
    };
  },
  methods: {    
    async showModalRemovePrintTemplate(printTemplateId){        
        this.$refs.ModalRemovePrintTemplate.show(printTemplateId);       
    },
    getBranchList(branchIds){      
      this.branchIds = branchIds;
    },
    async addPrintTemplate(){
       let params = {
            name: this.prtTempName,
            status: this.$constant.printTemplateActive,
            code: this.action,
            action: this.action,
            content: this.content,
            printPaperId: this.printPaperId,            
            typeUpdate: this.typeUpdate,  
            branchIds: this.branchIds
        }      
        const res = await $post( `/v1/print-templates/save-print-template`, params);
        if (res && res?.status && res.status.code == 200 && res.result) {
          toastr.success(this.$t('message.common.addPrintTemplateSuccess'));
        } else {
          toastr.error(this.$t('message.common.addPrintTemplateFailed'));
        }       
        this.$emit("addLoadData", this.content);        
    },
    async updatePrintTemplate(){
       // Cập nhật tên của mẫu in

      if(this.branchIds.length <=0) {
        toastr.error(this.$t('message.common.selectBranches'));
        return;
      }

      let params = {
        id: this.printTemplateId,
        name: this.prtTempName,
        isChangeName: this.isChangeName,
        action: this.action,
        content: this.content,
        printPaperId: this.printPaperId,            
        typeUpdate: this.typeUpdate,
        branchIds: this.branchIds
      }      
      const res = await $post( `/v1/print-templates/update-print-template-name`, params);
      if(res && res?.status && res.status.code == 200 && res?.result && res.result.code == 200) {
        toastr.success(res.result.message);
        this.$emit("loadData", this.content);     
      }else {        
        if(res.code == 422) {          
          if(res.messages && res.messages.errors.name) toastr.error(res.messages.errors.name);
          if(res.messages && res.messages.errors.content) toastr.error(res.messages.errors.content);
          if(res.messages && res.messages.errors.branchIds) toastr.error(res.messages.errors.branchIds);
        }else{
          toastr.error(res.result.message);
        }
      }     
    },
    async save() {      
      // // Kiểm tra có nhập tên mẫu in hay không?
      if (this.prtTempName == null) {
        toastr.error(this.$t('message.common.emptyPrintTemplateName'));
        return;
      }
      if(this.branchIds.length <=0) {
        toastr.error(this.$t('message.common.selectBranches'));
        return;
      }
      // Thêm mới mẫu in
      
      switch (this.typeUpdate) {
        case this.$constant.typePrintTemplateAdd:
          if (this.printTemplate.length > 2) {
            toastr.error(this.$t('message.common.maxPrintTemplates'));
            return;
          }              
          await this.addPrintTemplate();
          break;
        case this.$constant.typePrintTemplateUpdate:
          await this.updatePrintTemplate()
          break;       
      }
      this.$emit('loadData');

      window.jQuery(this.$refs.modal).modal("hide");
    },

    showTab(type) {
      switch (type) {
        case 0:
          this.type = 0;
          break;
        case 1:
          this.type = 1;
          break;
        default:
          this.type = 0;
          break;
      }
    },
    // Chỗ gán lại content khi thay đổi nội dung
    changeContent(dataContent) {
      this.content = dataContent;
      localStorage.setItem('dataContent', dataContent);      
    },
    async getPrintPaperID(printTemplateId) {      
      //Lấy mã print paper id
       const resp = await $get(`/v1/print-templates/get-print-paper-id`, {
        printTemplateId: printTemplateId,
      });   
      if (resp && resp.status.code == 200) {
        if (resp.result) {
          this.printPaperId = resp.result;
        }
      }    
      
    },
    async show(typeUpdate, printTemplateId){
        const res = await $get(`/v1/print-templates/get-content-by-id`,{id: printTemplateId});                       
        if(res && res.status.code ==200 && res.result && res.result.name!='') { 
            this.printPaperId = res.result.printPaperId?? null;
            this.prtTempName = res.result.name?? null;
            this.printTemplateId = printTemplateId?? null;
            this.typeUpdate = typeUpdate;    
            this.branchIds = res.result.branchIds;   
        }        
        this.getPrintPaperID(printTemplateId);      
        this.load();    
        window.jQuery(this.$refs.modal).modal("show");
    },
    async load() {
      const res = await $get(`/v1/print-templates/get-code-by-id`, {
          id: this.printTemplateId ?? 1
      });
      if (res && res.status.code == 200) {
        if (res.result) {
          this.entry = res.result;
        }
      }
      //Get value
      const response = await $get(`/v1/print-templates/value`, {
        action: this.action,
      });
      if (response && response.status.code == 200) {
        if (response.result) {
          this.data = response.result;
        }
      }
    

    },
    changePrtTempName(input) {
      this.prtTempName = input;
      this.isChangeName = true;
    },
    changePrintPaper(input) {      
      this.printPaperId = Number(input);
    },
    loadData(){
      window.jQuery(this.$refs.modal).modal("hide");
      this.$emit('delLoadData');
      this.$emit('loadData', this.content)
    }
  },
  watch: {
     
  },
};
</script>

<style lang="css" scoped>
a {
  text-decoration: none;
}
</style>
