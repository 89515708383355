import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}

const fields = {
    'Mã hàng hóa': 'code',
    'Tên hàng hóa': 'name',
    'Đơn vị tính': 'unitName',
    'SL chuyển': {
        field: 'sendQuantity',
        callback: (value) => {
            return n(value);
        }
    },
    'SL nhận': {
        field: 'receiveQuantity',
        callback: (value) => {
            return n(value);
        }
    },
    'Lô': 'batchName',
    'Hạn sử dụng': 'batchDate',

};

export default fields;
