<template>
  <template v-for="(item, index) in this.info" :key="item.prdId">
    <template v-if="index === 0">
      <div
        class="col-sm-12 text-center"
        v-for="(n, ind) in parseInt(item.prdQuantity)"
        :key="n">
        <template v-if="ind === 0">
          <div class="card mx-3 my-3">
            <div class="card-content text-center mx-3 my-3">             
              <div class="row">
                
                  <div class="col-5 mr-0 pr-0 col-units" style=" font-size:12px;padding-top:4px;min-width:170px;">                        
                    <div class="productBarcode__name"  v-if="this.status.nameOptions != 0" style="font-weight: 700; padding:15px 0;"> {{item.branchName ? item.branchName : 'Omipharma'}}</div>
                    <div v-if="this.status.priceOptions != 0" style="min-width:100%;" :class="this.status.nameOptions == 0 ? 'pt-5' : '' ">
                      <label
                        class="priceInfo"
                        v-text="
                          $filters.formatPrice(item.prdPrice)
                            ? $filters.formatPrice(item.prdPrice)
                            : ''
                        "></label>
                      <label
                        class="priceInfo pl-1"
                        v-if="this.status.currentOptions != 0"
                        >{{ item.prdCurrency ? item.prdCurrency : "" }}</label
                      >
                      <label
                        class="priceInfo"
                        v-if="this.status.unitOptions != 0"
                        >{{ item.unitName ? "/" + item.unitName : "" }}</label
                      >
                    </div>
                  </div>
                  <div class="col-7 pl-0" style="max-width:44.333333% !important;">
                    <div class="py-0 my-0">
                      <label> {{ item.prdName ? item.prdName : "" }}</label>
                    </div>
                    <BarcodeGenerator
                        :value="item.prdCode"
                        :width="1"
                        :height="30"
                        :fontSize="16"
                        :margin="2"
                        :fontFamily="'Arial'"
                        :font="'Arial'">
                    </BarcodeGenerator>
                  </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </template>
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator";

export default {
  name: "JewelryPreview",
  created() {},
  props: {
    info: {},
    status: {},
  },
  components: { BarcodeGenerator },
  data() {
    return {
      data: {},
    };
  },
  methods: {},
  watch: {
    info(newData) {
      this.data = newData;
    },
  },
};
</script>

<style lang="css" scoped>
  #priceOptions{
    display: flex !important;
    align-items: end !important;
  }
</style>

