<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{ $t('message.customerGroupIndex.titleUpdate') }}
                    </h5>
                    <button type="button" class="close"  @click="showModalCancelEdit">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active outline-none" id="home-tab" data-toggle="tab" data-target="#homeInfoCustomer" type="button" role="tab"
                            aria-controls="homeInfoCustomer" aria-selected="true">
                            {{ $t('message.customerGroupIndex.info') }}
                        </button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#editAdvanced" type="button" role="tab"
                            aria-controls="editAdvanced" aria-selected="false">
                            {{ $t('message.customerGroupIndex.advanceSetting') }}
                        </button>
                    </li> -->
                </ul>
            
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.customerGroupIndex.name') }}<label class="text-danger px-0">*</label> </b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="entry.name" :placeholder="$t('message.customerGroupIndex.enterName')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.customerGroupIndex.discount') }}</b>
                                        </div>
                                        <div class="col-8">
                                            <div class="form-group">
                                                <input type="number" v-if="entry.discountBy == '1'" v-model="entry.price" :placeholder="$t('message.customerGroupIndex.chooseDiscount')" class="form-control">
                                                <input type="number" v-if="entry.discountBy == '2'" v-model="entry.voucher" :placeholder="$t('message.customerGroupIndex.chooseDiscount')" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-check">
                                                <input class="form-check-input" v-model="entry.discountBy" type="radio" name="exampleRadios" id="1"
                                                    value="1" >
                                                <label class="form-check-label" for="exampleRadios1">
                                                    {{$t('message.customerGroupIndex.vnd')}}
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" v-model="entry.discountBy" type="radio" name="exampleRadios" id="2"
                                                    value="2">
                                                <label class="form-check-label" for="exampleRadios2">
                                                    {{$t('message.customerGroupIndex.percent')}}
                                                </label>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{$t('message.customerGroupIndex.note')}}</b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <textarea class="form-control" name="" v-model="entry.note" id="" cols="30" rows="7" :placeholder="$t('message.customerGroupIndex.note')"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                     <AppPermission :permission="this.$permissions.customerGroups.update">
                                        <button type="button" class="btn btn-save mr-2" @click="update(entry.id)">
                                            <i class="fa fa-save mr-1"></i>
                                            {{ $t('message.customerGroupIndex.button.save') }}
                                        </button>
                                    </AppPermission>
                                    <button type="button" class="btn btn-secondary text-white  mr-2"
                                        @click="showModalCancelEdit">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.skip') }}
                                    </button>
                                    <AppPermission :permission="this.$permissions.customerGroups.remove">
                                        <button type="button" class="btn btn-danger mr-2" @click="removeCustomerGroup(entry.id)">
                                            <i class="fa fa-trash-alt mr-1"></i>
                                            {{ $t('message.customerGroupIndex.button.remove') }}
                                        </button>
                                    </AppPermission>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <div class="tab-pane fade" id="editAdvanced" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <span>{{ $t('message.customerGroupIndex.settingConditional') }}</span>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                                        checked>
                                    <label class="form-check-label" for="exampleRadios1">
                                        {{ $t('message.customerGroupIndex.add') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                    <label class="form-check-label" for="exampleRadios2">
                                        {{ $t('message.customerGroupIndex.update') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3">
                                    <label class="form-check-label" for="exampleRadios3">
                                        {{ $t('message.customerGroupIndex.notUpdate') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <button type="button" class="btn btn-save mr-2" @click="submitForm">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.save') }}
                                    </button>
                                    <button type="button" class="btn btn-default text-white  mr-2" data-dismiss="modal" aria-label="Close"
                                        @click="clearInput">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>      
    </div>
    <ModalRemoveCustomerGroup ref="ModalRemoveCustomerGroup" @input="hide" />
    <ModalCancelEdit ref="ModalConfirm" @confirm="hide()" :message="$t('message.customerGroupIndex.contentSkip')" />
    <!-- <ModalConfirm ref="ModalConfirm" @confirm="hide()" :message="'nhóm khách hàng này không?'"/> -->
</template>
<script>
// import InputText from "@/components/forms/InputText";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import toastr from 'toastr';
import ModalRemoveCustomerGroup from '@/components/customers/ModalRemoveCustomerGroup';
import ModalCancelEdit from '@/components/customers/ModalCancelEdit';
import AppPermission from '../AppPermission';
// import ModalConfirm from "@/components/ModalConfirm.vue";
export default {
    name: "ModalEditCustomer",
    components: { ModalRemoveCustomerGroup, ModalCancelEdit,AppPermission },
    props: {
        customerGroupDetail: {
            type: Object,
            default: () => ({})
        },
        flag: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
          active: 1,
          form: {
            'name': '',
            'note': '',
            'discount_by': 1,
            'price': ''
          },
          errors: {},
          entry: {},
          defaultEntry: {},
          customerGroupList: [],
        }
    },
    methods: {
        async show (id = null) {
            window.jQuery(this.$refs.modal).modal('show');
            await this.load(id);
            this.defaultEntry = {...this.entry}  
        },
        async submitForm () {
            this.errors = {}
            const res = await $post('/v1/customer-group/create', this.form);
            if (res.code === 512) {
                this.errors = res.errors;
                scrollToElement('.error-label')
                $alert(res);
            }
            if(res.code == 200){
                toastr.success(this.$t('message.customerGroupIndex.alertCreateSuccess'));
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        async load(id = null) {
            const customerGroup = await $get('/v1/customer-group', {...this.$route.query, numberRecord: this.numberRecord});
            this.customerGroupList = customerGroup.data;
            // const customerGroupCustom = await $get('/v1/customer-group/list-of-customer');
            // this.customerGroupListCustom = customerGroup;
 
            const res = await $get(`/v1/customer-group/${id}`)
            if (res.code === 404) {
                location.replace('/404');
                return;
            }        
            this.entry = res;
        },
        removeCustomerGroup (id) 
        {
            // window.jQuery(this.$refs.modal).modal('hide');
            this.$refs.ModalRemoveCustomerGroup.show(id);
            
        },
        showModalCancelEdit () {
            if ( this.defaultEntry.name !== this.entry.name || this.defaultEntry.note !== this.entry.note || this.defaultEntry.price !== this.entry.price || this.defaultEntry.discountBy !== this.entry.discountBy){ 
                this.$refs.ModalConfirm.show();
            } else {
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        hide () {
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('input','1');
            this.$emit('inputData', '1');
        },
        async update (id) 
        {
            const data = await $post(`/v1/customer-group/update/${id}`, this.entry);
            // if (data.code === 512) {
            //     this.errors = data.errors;
            //     scrollToElement('.error-label')
            //     $alert(data);
            // }
            if(data.code === 422){
                if(data.messages.errors.name){
                    toastr.error(data.messages.errors.name[0]);
                }
                if(data.messages.errors.price){
                    toastr.error(data.messages.errors.price[0]);
                }
            }
            else {
                if (this.flag == 1) {
                    toastr.success(this.$t('message.customerGroupIndex.alertUpdateSuccess'));
                    window.jQuery(this.$refs.modal).modal('hide');
                    this.$emit('updated', data.data);
                }
                else if (this.flag == 2) {
                    toastr.success(this.$t('message.customerGroupIndex.alertUpdateSuccess'));
                    window.jQuery(this.$refs.modal).modal('hide');
                    this.$emit('updated', data.customeData);
                }

            }
           
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
</style>