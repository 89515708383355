import {numberFormat} from "@/lib/utils";

export default {
    methods: {
        toVietnamWord(numString) {    
            var bigNumArry = new Array('', ' nghìn', ' triệu', ' tỷ', ' nghìn tỷ', ' triệu tỷ', ' tỷ tỷ');
            var output = '';                    
            var finlOutPut = new Array();
            if (numString == 0) {
                return 'Không đồng';
            }
            let i = 0;
            if(numString) {
                i = numString.toString().length;
                i = i - 1;
                //cut the number to grups of three digits and add them to the Arry
                while (numString.toString().length > 3) {
                    var triDig = new Array(3);
                    triDig[2] = numString.toString().charAt(numString.toString().length - 1);
                    triDig[1] = numString.toString().charAt(numString.toString().length - 2);
                    triDig[0] = numString.toString().charAt(numString.toString().length - 3);

                    var varToAdd = triDig[0] + triDig[1] + triDig[2];
                    finlOutPut.push(varToAdd);
                    i--;
                    numString = numString.toString().substring(0, numString.toString().length - 3);
                }
            }
            finlOutPut.push(numString);
            finlOutPut.reverse();
            for (let j = 0; j < finlOutPut.length; j++) {
                finlOutPut[j] = this.triConvert(parseInt(finlOutPut[j]));
                
            }
            var bigScalCntr = 0;
            for (let b = finlOutPut.length - 1; b >= 0; b--) {
                if (finlOutPut[b] != "dontAddBigSufix") {
                    
                    finlOutPut[b] = finlOutPut[b] + bigNumArry[bigScalCntr];
                    bigScalCntr++;
                } else {
                    
                    finlOutPut[b] = '';
                    bigScalCntr++; //advance the counter
                }
            }
            for (let n = 0; n < finlOutPut.length; n++) {
                output += finlOutPut[n];
            }

        return output + ' đồng'
        },

        //simple function to convert from numbers to words from 1 to 999
        triConvert(num) {
            var ones = new Array('', ' một', ' hai', ' ba', ' bốn', ' năm', ' sáu', ' bảy', ' tám', ' chín', ' mười', ' mười một', ' mười hai', ' mười ba', ' mười bốn', ' mười lăm', ' mười sáu', ' mười bảy', ' mười tám', ' mười chín');
            var tens = new Array('', '', ' hai mươi', ' ba mươi', ' bốn mươi', ' năm mươi', ' sáu mươi', ' bảy mươi', ' tám mươi', ' chín mươi');
            var hundred = ' trăm';
            var output = '';
            var numString = num.toString();

            if (num == 0) {
                return 'dontAddBigSufix';
            }
            //the case of 10, 11, 12 ,13, .... 19
            if (num < 20) {
                output = ones[num];
                return output;
            }

            //100 and more
            if (numString.toString().length == 3) {
                output = ones[parseInt(numString.charAt(0))] + hundred;
                output += tens[parseInt(numString.charAt(1))];
                output += ones[parseInt(numString.charAt(2))];
                return output;
            }

            output += tens[parseInt(numString.charAt(0))];
            output += ones[parseInt(numString.charAt(1))];         
            return output;
        },
    },
}
  