<template>
    <div ref="modal" class="modal mx-0 fade" :class="this.isFirstModal ? '' : 'modal-second'" tabindex="-1" role="dialog" id="modalUnitAdd" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{(typeEditAdd === 'EDIT' && this.id != null) ? $t('message.modalCreateUpdateUnit.title_update') : $t('message.modalCreateUpdateUnit.title_create')}}</h5>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-2">
                                    <b>{{ $t('message.modalCreateUpdateUnit.unit_name') }}<label class="text-danger px-0">*</label> </b>
                                </div>
                                <div class="col-10">
                                    <InputText v-model.trim="entry.name"
                                       :errors="errors.name"
                                       :placeholder="this.$t('message.modalCreateUpdateUnit.placeholder.name')"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <AppPermission :permission="[this.$permissions.units.update ?? '', this.$permissions.units.create ?? '']">
                                <button type="button" class="btn btn-save mr-2" @click="save">
                                    <i class="fa fa-save mr-1"></i>
                                    {{ $t('message.button.save') }}
                                </button>
                            </AppPermission>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.button.skip') }}
                            </button>
                            <AppPermission :permission="this.$permissions.units.remove">
                            <button type="button" class="btn btn-danger mr-2" v-if="(typeEditAdd === 'EDIT' && this.id != null)" @click="removeUnit(entry.id)">
                                <i class="fa fa-trash-alt mr-1"></i>
                                {{ $t('message.button.remove') }}
                            </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <ModalUnitRemove ref="ModalUnitRemove" @inputData="hide()"/>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
</template>
<style>
</style>
<script>
import ActionBar from "../../../components/ActionBar";
import InputText from "../../../components/forms/InputText";

import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import InputSwitchButton from "../../../components/forms/InputSwitchButton";
import QSelect from "@/components/QSelect";
import emitter from "@/lib/emitter";
import ModalUnitRemove from "./ModalUnitRemove.vue";
// import { valueToNode } from "@babel/types";
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalUnitAdd",
    components: { ActionBar, InputText, InputSwitchButton, QSelect, ModalConfirm, ModalUnitRemove, AppPermission },
    props: {
        typeEditAdd: String,
        idUnit: Number,
        quickAdd: Boolean,
        isFirstModal: Boolean,
    },
    data() {

        return {
            // isDataLoaded: false,
            entry: {
                name: ''
            },
            errors: {},
            default: {
                name: ''
            },
            contentConfirm: '',
            titleConfirm: '',
            id: null,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: ''
            }
            this.errors = {}
            this.default = {
                name: ''
            }
            this.id = null;
        },
        async show(id = null) {   
            this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
                this.id = id;
            }
        },
        hide() {     
            this.clearInput();
            this.$emit('inputData','1');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.modalCreateUpdateUnit.title_confirm');
                if(this.id != null){
                    this.contentConfirm = this.$t('message.modalCreateUpdateUnit.message_confirm_update');
                }else{
                    this.contentConfirm = this.$t('message.modalCreateUpdateUnit.message_confirm_create');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async load(id = null) {
            const res = await $get('/v1/units/show', {
                id: id
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
        },
        async save() {
            this.errors = {};
            let res = null;
            if(this.id){
                res = await $post('/v1/units/update', {
                    ...this.entry
                });
            }else{
                res = await $post('/v1/units/save', {
                    ...this.entry
                });
            }

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }
            if(res.code === 422){
                $alert({code: 422, message: res.messages.errors.name[0]});
            }
            else{
                $alert(res);
            }
            if(res.code == 200 && this.quickAdd == false){
                this.hide();
            }else if(res.code == 200 && this.quickAdd == true){
                const unit = await $get('/v1/units/get-unit', {
                    mdsUnitId: res?.data?.id,
                });
                const new_unit = {
                    id: unit?.data?.entry?.id,
                    name: unit?.data?.entry?.name,
                };
                emitter.emit("quickUpdateUnit", new_unit);
                this.hide();
            }
        },
        removeUnit(id){
            this.$refs.ModalUnitRemove.show(id);
        },
    },
    mounted() {
        emitter.on('modalUnitAdd',() => this.show())
    },
    beforeUnmount() {
        this.hide();
    },
    unmounted() {
        this.hide();
    }
}
</script>

