<template>
  <div
    ref="modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header border-bottom-0 pb-0">
          <h6 class="modal-title" id="exampleModalLabel">
              <span v-if="this.inputSrc == this.$constant.typeImportForSupplier || this.inputSrc == this.$constant.typeReturn || this.inputSrc == this.$constant.typeOrderForSupplier">{{$t('message.CashBookIndex.modal.showDetail.title_payment')}}</span>
              <span v-else-if="this.inputSrc == this.$constant.typeInvoice || this.inputSrc == this.$constant.typeOrder">{{$t('message.CashBookIndex.modal.showDetail.title_receipt')}}</span>
          </h6>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row mx-3">
            <div class="col-4">
              <div class="row">
                <div class="col-6 titleModal">
                  <span v-if="this.inputSrc == this.$constant.typeInvoice || this.inputSrc == this.$constant.typeOrder">
                    {{$t('message.CashBookIndex.modal.showDetail.code_receipt')}}: 
                  </span>
                  <span v-else-if="this.inputSrc == this.$constant.typeImportForSupplier || this.inputSrc == this.$constant.typeReturn || this.inputSrc == this.$constant.typeOrderForSupplier">
                    {{$t('message.CashBookIndex.modal.showDetail.code_payment')}}:
                  </span>                </div>
                <div class="col-6">{{ this.entry?.code ? this.entry.code : "" }}</div>               
              </div>
              <div class="row py-3">
                <div class="col-6 titleModal">{{$t('message.common.time')}}:</div>
                <div class="col-6">
                  {{ this.entry?.created_at ? this.entry.created_at : "" }}                  
                </div>
              </div>
              <div class="row">
                <div class="col-6 titleModal">{{$t('message.common.created_by')}}:</div>
                <div class="col-6">
                  {{ this.entry?.created_by && this.entry.created_by?.name ? this.entry.created_by.name : "" }}
                </div>
              </div>
              <div class="row py-3">
                <template  v-if="this.inputSrc == this.$constant.typeImportForSupplier || this.inputSrc == this.$constant.typeOrderForSupplier">
                  <div class="col-6 titleModal">{{$t('message.headers.suppliers')}}:</div>
                  <div class="col-6">
                      <a class="text-decoration-none text-primary" target="_blank" 
                        :href="this.entry?.supplierCode ? '/suppliers/index?keyword=' + this.entry.supplierCode : ''">
                        {{ this.entry?.supplierName ? this.entry.supplierName : '' }}
                      </a>
                  </div>
                </template>
                <template  v-if="this.inputSrc == this.$constant.typeInvoice || this.inputSrc == this.$constant.typeReturn || this.inputSrc == this.$constant.typeOrder">
                  <div class="col-6 titleModal">{{$t('message.headers.customers')}}:</div>
                  <div class="col-6">
                      <a class="text-decoration-none text-primary" target="_blank" 
                        :href="this.entry?.customer && this.entry.customer?.code ? '/customers/index?code=' + this.entry.customer.code : ''">
                        {{ this.entry?.customer && this.entry.customer?.name ? this.entry.customer.name : "" }}
                      </a>
                  </div>
                </template>
                
              </div>
            </div>
            <div class="col-4">
              <div class="row">
                <div class="col-6 titleModal">{{$t('message.headers.employees')}}:</div>
                <div class="col-6"> {{ this.entry?.user && this.entry.user?.name ? this.entry.user.name : "" }}</div>
              </div>
              <div class="row py-3">
                <div class="col-6 titleModal">{{$t('message.OrderSupplierIndex.tabs.table.history_payment.method')}}:</div>
                <div class="col-6">
                  {{ this.entry?.methodName ? this.entry.methodName : "" }}
                </div>
              </div>
            </div>
            <div class="col-4">
              <div class="col-4">
                <textarea :placeholder="$t('message.PurchaseOrderIndex.note')" class="noteStyle px-4 text-gray font-italic" v-model.trim="this.entry.note" :readonly="this.entry.status == this.$constant.BANK_STATUS_CANCEL || this.entry.status == this.$constant.CASH_STATUS_CANCEL ? true : false"></textarea>
                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
              </div>
            </div>
          </div>
          <div class="row my-4">
            <div class="col-12" id="divTable">
              <table class="table table-index table-striped w-100">
                <thead>
                  <tr class="backgroup__detail table-header border-1">
                    <th scope="col" class="checkbox-table text-center">{{$t('message.PurchaseOrderIndex.tabs.table.history_payment.code')}}</th>
                    <th scope="col" class="checkbox-table text-center">{{$t('message.common.time')}}</th>
                    <th scope="col" class="checkbox-table text-center">{{$t('message.InvoiceIndex.tabs.table.history_payment.value')}}</th>
                    <th scope="col" class="checkbox-table text-center">
                      <span v-if="this.inputSrc == this.$constant.typeInvoice || this.inputSrc == this.$constant.typeOrder">{{$t('message.CashBookIndex.modal.showDetail.table.receipted')}}</span>
                      <span v-else-if="this.inputSrc == this.$constant.typeImportForSupplier || this.inputSrc == this.$constant.typeReturn || this.inputSrc == this.$constant.typeOrderForSupplier">
                        {{$t('message.CashBookIndex.modal.showDetail.table.paymented')}}
                      </span>                      
                    </th>
                    <th scope="col" class="checkbox-tabler text-center">
                      <span v-if="this.inputSrc == this.$constant.typeInvoice || this.inputSrc == this.$constant.typeOrder">{{$t('message.modalCustomerPaid.amount')}}</span>
                      <span v-else-if="this.inputSrc == this.$constant.typeImportForSupplier || this.inputSrc == this.$constant.typeReturn || this.inputSrc == this.$constant.typeOrderForSupplier">
                        {{$t('message.modalPaymentSupplier.amount')}}
                      </span>    
                    </th>
                    <th scope="col" class="checkbox-table text-center">{{$t('message.common.status')}}</th>                    
                  </tr>
                </thead>
                <tbody>
                  <tr class="border border-top-0 text-center" style="height: 35px">
                    <td scope="col" class="checkbox-table text-primary">
                        {{this.entry?.inputCode ? this.entry.inputCode : ''}}
                    </td>
                    <td scope="col" class="checkbox-table">
                     {{this.entry?.inputCreatedAt ? this.entry.inputCreatedAt : ''}}
                    </td>
                    <td scope="col" class="checkbox-table">
                         {{this.entry?.inputTotalPrice ? this.entry.inputTotalPrice : ''}}                      
                    </td>
                    <td scope="col" class="checkbox-table text-center">                      
                      0
                    </td>
                    <td scope="col" class="checkbox-table">
                      {{ this.entry?.amount ? this.entry.amount  : "" }}
                    </td>
                    <td scope="col" class="checkbox-table">
                      {{ this.entry?.statusName ? this.entry.statusName : "" }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="modal-footer border-top-0 py-2">
          <button type="button" class="btn btn-primary" @click="update()" v-if="this.entry.status != this.$constant.CASH_STATUS_CANCEL || this.entry.status != this.$constant.BANK_STATUS_CANCEL">
            <i class="fa fa-check-square"></i>
            {{$t('message.ProductIndex.button.update')}}
          </button>
          <button type="button" class="btn btn-primary">
            <i class="fa fa-print"></i>
            {{$t('message.button.print.name')}}
          </button>
          <button type="button" class="btn btn-danger" @click="cancel()" v-if="this.entry.status != this.$constant.CASH_STATUS_CANCEL || this.entry.status != this.$constant.BANK_STATUS_CANCEL">
            <i class="fa fa-times"></i>
            {{$t('message.button.cancel')}}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalCashCancel ref="ModalCashCancel" @loadData="loadData"/>
  <ModalBankCancel ref="ModalBankCancel" @loadData="loadData"/>
</template>

<script>
import store from "../store";
import {$get, numberFormat, $alert, $post} from "@/lib/utils";
import moment from 'moment';
import ModalCashCancel from '@/views/cashbook/tabs/ModalCashCancel.vue';
import ModalBankCancel from '@/views/bankbook/tabs/ModalBankCancel.vue';

export default {
  name: "ModalDataPayment",
  components: {ModalCashCancel, ModalBankCancel}, 
  watch: {
    input(data){      
      if(data) this.inputSrc = data;
      else this.inputSrc == null;
    }
  },
  mounted() {
    // setTimeout(this.checkCurrentBranch, 1000);
  },
  data() {
    return {
      entry: {},
      type: null,
      inputSrc: null,
      isCloseModal: false,
    };
  },
  computed: {
    branches: () => store.state.GlobalConfig?.userBranches ?? [],
  },
  methods: {
    checkCurrentBranch() {
      if (store.state.Auth) {
        if (!store.state.CurrentBranch) {
          this.show();
        } else {
          const found = this.branches.find((b) => b.id === store.state.CurrentBranch.id);
          if (!found) {            
            this.show();
          }
        }
      }
    },
    selectBranch(branchId) {
      if (!branchId) {
        this.errors.branchId = [this.$t('message.common.selectBranches')];
        return;
      }
      const branch = this.branches.find((b) => b.id === branchId);
      store.commit("SetCurrentBranch", branch);
      location.reload();
      window.jQuery(this.$refs.modal).modal("hide");
    },
    getAddEntry(input){
      switch (input) {
          case this.$constant.typeImportForSupplier:
            this.entry.inputCode = this.entry?.import_for_supplier ? this.entry.import_for_supplier.code : null;
            this.entry.inputCreatedAt = this.entry?.import_for_supplier &&  this.entry.import_for_supplier?.created_at  ? moment(this.entry.import_for_supplier.created_at).format('DD/MM/YYYY HH:mm') : '';
            this.entry.inputTotalPrice = this.entry?.import_for_supplier &&  this.entry.import_for_supplier?.totalPrice  ? numberFormat(this.entry.import_for_supplier.totalPrice):'';
            this.entry.supplierName = this.entry?.import_for_supplier && this.entry.import_for_supplier?.supplier? this.entry.import_for_supplier.supplier.name : null;
            this.entry.supplierCode = this.entry?.import_for_supplier && this.entry.import_for_supplier?.supplier? this.entry.import_for_supplier.supplier.code : null;
            break;
          case this.$constant.typeOrderForSupplier:
            this.entry.inputCode = this.entry.order_for_supplier ? this.entry.order_for_supplier.code : null;
            this.entry.inputCreatedAt = this.entry?.order_for_supplier &&  this.entry.order_for_supplier?.created_at  ? moment(this.entry.order_for_supplier.created_at).format('DD/MM/YYYY HH:mm') : '';
            this.entry.inputTotalPrice = this.entry?.order_for_supplier &&  this.entry.order_for_supplier?.totalPrice  ? numberFormat(this.entry.order_for_supplier.totalPrice):'';
            this.entry.supplierName = this.entry?.order_for_supplier && this.entry.order_for_supplier?.supplier? this.entry.order_for_supplier.supplier.name : null;
            this.entry.supplierCode = this.entry?.order_for_supplier && this.entry.order_for_supplier?.supplier? this.entry.order_for_supplier.supplier.code : null;
            break;
          case this.$constant.typeInvoice:
            if(this.entry.get_delivery){
              this.entry.invoice = this.entry.get_delivery.invoice ?? [];
            }else if(this.entry.delivery){
              this.entry.invoice = this.entry.delivery.invoice ?? [];
            }
            this.entry.inputCode = this.entry.invoice ? this.entry.invoice.code : null;
            this.entry.inputCreatedAt = this.entry?.invoice &&  this.entry.invoice?.created_at  ? moment(this.entry.invoice.created_at).format('DD/MM/YYYY HH:mm') : '';
            this.entry.inputTotalPrice = this.entry?.invoice &&  this.entry.invoice?.total  ? numberFormat(this.entry.invoice.total):'';
            this.entry.supplierName = this.entry?.invoice && this.entry.invoice?.supplier? this.entry.invoice.supplier.name : null;
            this.entry.supplierCode = this.entry?.order_for_supplier && this.entry.order_for_supplier?.supplier? this.entry.order_for_supplier.supplier.code : null;
            break;
          case this.$constant.typeOrder:
            this.entry.inputCode = this.entry.order ? this.entry.order.code : null;
            this.entry.inputCreatedAt = this.entry?.order &&  this.entry.order?.created_at  ? moment(this.entry.order.created_at).format('DD/MM/YYYY HH:mm') : '';
            this.entry.inputTotalPrice = this.entry?.order &&  this.entry.order?.total  ? numberFormat(this.entry.order.total):'';
             this.entry.supplierName = this.entry?.order && this.entry.order?.supplier? this.entry.order.supplier.name : null;
             this.entry.supplierCode = this.entry?.order_for_supplier && this.entry.order_for_supplier?.supplier? this.entry.order_for_supplier.supplier.code : null;
            break;
          case this.$constant.typeReturn:              
              this.entry.inputCode = this.entry?.return ? this.entry?.return.code : null;
              this.entry.inputCreatedAt = this.entry?.return &&  this.entry.return?.createdAt  ? moment(this.entry.return.createdAt).format('DD/MM/YYYY HH:mm') : '';
              this.entry.inputTotalPrice = this.entry?.return &&  this.entry.return?.returnTotal  ? numberFormat(this.entry.return.returnTotal):'';  
               this.entry.supplierName = this.entry?.return && this.entry.return?.supplier? this.entry.return.supplier.name : null;    
               this.entry.supplierCode = this.entry?.order_for_supplier && this.entry.order_for_supplier?.supplier? this.entry.order_for_supplier.supplier.code : null;     
          break;                  
        }
    },
    async show(id, type, input, data = null) {               
          switch (type) {        
            case this.$constant.bankBook:
              this.type= type;
              this.entry = await this.loadBank(id);          
              break;
            case this.$constant.cashBook:
              this.type= type;
             this.entry = await this.loadCash(id);          
              break;
          }              
        this.isCloseModal = false;
        if (data) {
          this.inputSrc = data.typeOrigin == 1 ? this.$constant.typeInvoice : this.$constant.typeOrderForSupplier;
          this.isCloseModal = true;
        }else {
         this.inputSrc = input;      
        }
        this.entry.created_at = this.entry?.created_at  ? moment(this.entry.created_at).format('DD/MM/YYYY HH:mm') : '';
        this.entry.amount = numberFormat(this.entry.amount)?? 0;                 
        this.getAddEntry(input);        
        if(type == this.$constant.bankBook) this.entry.methodName = this.$t('message.common.bank');
        if(type == this.$constant.cashBook) this.entry.methodName = this.$t('message.CashBookIndex.cash');            
        window.jQuery(this.$refs.modal).modal({
            backdrop: "static",
            keyboard: false,
          });
    },
    async loadBank(id) {
      let data = null;
      const res = await $get("/v1/bank-book/get-data-by-id", {id: id });      
      if(res && res.status.code == 200) {
        data = res.result;        
      }
      return data;
    },
    async loadCash(id) {
      let data = null;
      const res = await $get("/v1/cash-book/get-data-by-id", {id: id });      
      if(res && res.status.code == 200) {
        data = res.result;
      }
      return data;
    },
    async update(){
      switch (this.type) {        
        case this.$constant.bankBook:
          this.updateBankBook();                 
          break;
        case this.$constant.cashBook:
          this.updateCashBook();        
        break;
      }
    },
    async updateCashBook(){
      let entry = this.entry;
      if(entry.note && entry.note.length > 255){
          $alert({code: 422, message: this.$t('message.CashBookIndex.alerts.validate_error_note')})
      }
      const res = await $post('/v1/cash-book/quick-save', {
          id: entry.id,
          note: entry.note,
      });
      if(res.result.status == true){
          $alert({
              code: 200,
              message: this.$t('message.CashBookIndex.alerts.update_success', {code: entry.code}),
          });
          this.loadCash(entry.id);
      }else{
          $alert({
              code: 403,
              message: this.$t('message.CashBookIndex.alerts.update_error', {code: entry.code}),
          });
      }
    },
    async updateBankBook(){
      let entry = this.entry;
      if(entry.note && entry.note.length > 255){
          $alert({code: 422, message: this.$t('message.BankBookIndex.alerts.validate_error_note')})
      }
      const res = await $post('/v1/bank-book/quick-save', {
          id: entry.id,
          note: entry.note,
      });
      if(res.result.status == true){
          $alert({
              code: 200,
              message: this.$t('message.BankBookIndex.alerts.update_success', {code: entry.code}),
          });
          this.loadBank(entry.id);
      }else{
          $alert({
              code: 403,
              message: this.$t('message.BankBookIndex.alerts.update_error', {code: entry.code}),
          });
      }
    },
    async cancel(){
      switch (this.type) {        
        case this.$constant.bankBook:
          this.cancelBankBook();                 
          break;
        case this.$constant.cashBook:
          this.cancelCashBook();        
        break;
      }
    },
    cancelCashBook(){
      this.$refs.ModalCashCancel.show(this.entry);
    },
    cancelBankBook(){
      this.$refs.ModalBankCancel.show(this.entry.id, this.entry.code);
    },
    loadData(){
      if(this.isCloseModal){
        this.closeModal();
      }else{
        switch (this.type) {        
          case this.$constant.bankBook:
            this.loadBank(this.entry.id);              
            break;
          case this.$constant.cashBook:
            this.loadCash(this.entry.id);       
          break;
        }
      }
    },
    closeModal(){
      this.$emit('updated');
      window.jQuery(this.$refs.modal).modal("hide");
    }
  },
  beforeUnmount() {
    this.closeModal();
  },
};
</script>

<style scoped>
.titleModal {
  font-weight: 700;
}
#fa_edit {
  position: absolute;
  left: 20px;
  top: 4px;
  color: #a7a2a2;
}
.noteStyle {
  width: 280px;
  height: 150px !important;
  border: 0;
  border-bottom: 1px solid #ccc;
  border-radius: 5;
}
.noteStyle:focus {
  outline: none !important;
  border-color: none;
  box-shadow: 0 none;
}
</style>
