<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none">
                    <h6 class="modal-title">
                        {{$t('message.common.addCoupons')}}
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-4 d-flex align-items-center text-bold">
                            {{$t('message.common.quantityCoupon')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.common.quantityCouponWillCreate')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group mb-0">
                                <input type="number" class="form-control input-custom" v-model="slot" autocomplete="off">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-4 d-flex align-items-center text-bold">
                            {{$t('message.common.couponCodeLength')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.common.couponCodeLengthNote')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="form-group mb-0">
                                <input type="number" class="form-control input-custom" v-model="length" autocomplete="off">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none">
                     <AppPermission :permission="this.$permissions.coupons.create?? '' ">
                    <button type="button" class="btn btn-save mr-2" @click="save()">
                        <i class="fa fa-save mr-1"></i>
                        {{$t('message.button.save')}}
                    </button>
                    </AppPermission>
                    <button type="button" class="btn btn-secondary text-white mr-2" data-dismiss="modal" @click="cancelCreate()">
                        <i class="fa fa-ban mr-1"></i>
                        {{$t('message.button.skip')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import AppPermission from '@/components/AppPermission.vue';
    export default {
        name: "CouponCodeModal",
        components: {InputDateRangePicker, AppPermission},
        props: [],
        data() {
            return {
              length: null,
              slot: null,
            }
        },
        mounted() {

        },
        methods: {
            show() {
                this.length = this.slot = null;
                window.jQuery(this.$refs.modal).modal('show');
            },
            save() {
                this.$emit('confirm', {
                    length: this.length,
                    slot: this.slot,
                });
                // window.jQuery(this.$refs.modal).modal('hide');
            },
            cancelCreate() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            hide() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
        }
    }
</script>

