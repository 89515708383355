<template>
    <div class="position-relative" v-click-outside="closeDropDownSearch">
        <div class="select-option border-gray rounded bg-white" @click="showDropDownList">
            <template v-if="!checkedAll && this.arrayChecked?.length > 0">
                <template :key="n" v-for="(n, index) in this.arrayChecked">     
                    <template :key="value.id" v-for="(value) in this.options.filter">
                        <template v-if="n === value.id && index != (this.arrayChecked?.length - 1)">
                            <span>{{ value.name + ', '}}</span>
                        </template>
                        <template v-if="n === value.id && index === (this.arrayChecked?.length - 1)">
                            <span>{{ value.name }}</span>
                        </template>
                    </template>
                </template>
            </template>
            <template v-else>
                <span v-text="$t('message.common.all')"></span>
            </template>
        </div>
        <div class="options-dt" v-show="showDropDown">
            <div class="d-flex align-items-center form-control-search mb-1">
                <span class="form-control-icon"><i class="fas fa-search text-gray"></i></span>
                <input type="text" class="min-w-100" v-model="this.options.search" @input="onInputOption">
            </div>
            <template v-if="this.options.filter?.length > 0">
                <label class="form-check form-check-block pl-1">
                    <label class="container-checkbox mb-0">{{$t('message.common.all')}}
                        <input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)">
                        <span class="checkmark-checkbox"></span>
                    </label>
                </label>
                <label class="form-check form-check-block pl-1" v-for="option in this.options.filter" :key="option.id">
                    <label class="container-checkbox mb-0">{{option.name}}
                        <input type="checkbox" @change.stop="countChecked(option.id)" :checked="isExistInArrayChecked(option, this.arrayChecked)" v-model="option.checked">
                        <span class="checkmark-checkbox"></span>
                    </label>
                </label>
            </template>
            <template v-else>
                <span class="ow-break-word d-flex align-items-center justify-content-center mt-2" href="javascript:;">
                    {{$t('message.common.notData')}}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import {removeVietnameseTones} from "@/lib/utils";
export default {
    name: "MultiSelectList",
    props:  ['defaultValue', 'modelValue', 'defaultOption', 'noSearch'],
    methods: {
        onInputOption(){
            if(this.options.search == ''){
                this.options.filter = this.options.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.options.all.length; index++) {
                if (removeVietnameseTones(this.options.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }
            }
            this.options.filter = arr;
        },
        showDropDownList(){
            this.showDropDown = !this.showDropDown;
        },
        closeDropDownSearch(){
            this.showDropDown = false;
        },
        onCheckedAll(value) {
            if(value == true){
                this.options.all.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.options.all.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.$emit('update:modelValue', this.arrayChecked);
            this.isCheckedAll();
        },
        isCheckedAll(){
            let getChecked = 0;
            this.options.all.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.options.all.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
            this.$emit('update:modelValue', this.arrayChecked);
        },
    },
    watch: {
        defaultOption: {                
            handler(newDefaultOption){                          
                this.options.filter = this.options.all = newDefaultOption;
            },
            deep: true
        },
        modelValue: {                
            handler(newModelValue){                          
                this.arrayChecked = newModelValue;
                this.isCheckedAll();
            },
            deep: true
        },
    },
    mounted() {
    },
    data() {
        return {
            arrayChecked: this.modelValue ?? [],
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
                search: '',
            },
            showDropDown: false,
            checkedAll: false,
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 12px;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
.dropdown-menu{
    z-index: 100 !important;
}
.dropdown-menu input:focus{
    border: none;
    border-bottom: 1px solid $base-color;
}
.options-dt{
    overflow: auto;
    max-height: 200px;
}
.option-value{
    white-space: pre-wrap !important;
}
.options-dt{
    width: 100% !important;
    transform: translate3d(0px, 35px, 0px) !important;
}
.border-gray{
    border: 1px solid #ccc !important;
}
.options-dt input {
    width: 100%;
    border: 1px solid #ccc !important;
    outline: none;
    height: 30px;
    padding-right: 30px;
    padding-left: 30px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}
.form-control-search {
    position: relative;
}
.form-control-search .form-control-icon {
    cursor: pointer;
    font-size: 0.8rem;
    transform: translateY(-50%);
    position: absolute;
    left: 8px;
    top: 50%;
}
.form-check {
    display: block;
    min-height: 1.96rem;
    padding-left: 1.5em;
    margin-bottom: 0.125rem;
}
.form-check {
    position: relative;
    margin: 0;
}
.form-check-block {
    padding: 0.5rem 0;
}
input[type="checkbox"]{
    width: 100%;
    height: 16px;
    left: 0px;
}
.options-dt{
    width: 100% !important;
    transform: translate3d(0px, 5px, 0px) !important;
}
.select-option::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.select-option::after {
    position: absolute;
    right: 15px;
    top: 12px;
}
</style>
