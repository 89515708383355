<template>
  <div class="row">    
    <div class="col-lg-6 px-0 mx-0" style="border: 1px solid #ccc" id="design">  
      <DesignTemplates
        ref="DesignTemplates"
        :action="this.action"
        :entry="this.entry"
        :html="this.html"
        :content="this.content"   
        :printTempId="this.printTempId"
        :printTempData="this.printTemplate"
        @changeContent="this.changeContent"  
        @loadData="loadData"
        />      
         
    </div>
    <div class="col-lg-6 px-0 mx-0" style="background-color: #eef3f7">
      <ViewTemplates ref="ViewTemplates" 
        :entry="this.data"
        :contenOrigin="contenOrigin" 
        :action="this.action"/>
    </div>
  </div>
</template>
<script>
import DesignTemplates from "./tabs/DesignTemplates";
import ViewTemplates from "./tabs/ViewTemplates";
import {$get} from "@/lib/utils";
export default {
  name: "DiscountsBasTemplate",
  components: { DesignTemplates, ViewTemplates },
  mounted() {   
      this.loadFirst();      
  },
  created() {    
    this.loadFirst();      
  },
  data() {
    return {      
      printPaperId: null,
      action: "TabDiscountBas",
      entry:{},      
      data:{},
      html:'',
      printTemplate: null,      
      content:null,
      contenOrigin: null,
      printTempId: this.$constant.defaultDiscountBasTemplateId,      
    };
  },
  methods: {
    changeContent(newContent){        
      this.contenOrigin = newContent;
    },   
    async loadFirst(){
        const res = await $get(`/v1/print-templates/data`,{action: this.action});                   
        if(res && res.status.code == 200) {
          let data = res.result;          
          this.printTempId = data[0].id; // Lấy luôn phần tử đầu tiên của mảng              
          localStorage.setItem('defaultID', this.printTempId )
        }
    }
  },
  watch: {    
    content: {                
        handler(newData){                                    
          this.content = newData;
        },
        deep: true
    },            
    printTemplate: {                
        handler(newData){                  
          this.printTemplate = newData;                
        },
        deep: true
        
    },
  }
};
</script>
