<template>
    <div class="prd-search-box1" v-click-outside="hideDropDown" :class="[(disabled ? 'disabled' : ''), readonly ? 'cursor-ban' : '']" @click="readonly ? null : showDropDown()">
        <div class="form-control multiselect p-0 border-none">
            <template v-if="listItemSelected?.length > 0">
                <ul class="p-0 m-0">
                    <template v-for="item in listItemSelected" :key="item">
                        <li class="item-select px-2 py-1 mb-1 d-flex flex-row align-items-center ml-1">
                            <span>{{ renderFieldById(item, this.defaultOption) }}</span>
                            <span @click="removeItem(item)" v-if="!readonly">
                                <i class="fa fa-times mx-2 text-gray"></i>
                            </span>
                            <span v-else>
                                <i class="fa fa-times mx-2 text-gray"></i>
                            </span>
                        </li>
                    </template>
                </ul>
            </template>
            <input type="text" :placeholder="placeholder" v-model.trim="keyword" @input="onInputFilter" :readonly="this.readonly" :class="this.readonly ? 'cursor-ban' : ''" :disabled="disabled" class="bg-transparent text-wrap"/>
        </div>
        <div class="dropdown-menu dropdown-menu-content" :id="'dropdownContent' + this.label">            
            <template v-if="this.options.filter?.length > 0">
                <template v-for="o in this.options.filter" :key="o.id">
                    <a class="dropdown-item text-wrap py-2" href="javascript:;" :value="o.id" @click.stop="chooseOption(o)">
                        {{o.name ? o.name : (o.label ? o.label : null)}}
                        <i class="fa fa-check" v-if="o.checked"></i>
                    </a>
                </template>
            </template>
            <template v-else>
                <a class="dropdown-item" href="javascript:;">
                    {{$t('message.common.notFound')}}
                </a>
            </template>
        </div>
    </div>
</template>

<script>
import {removeVietnameseTones} from "@/lib/utils";
import emitter from "@/lib/emitter";
export default {
    name: "SelectLocationTime",
    props:  ['placeholder', 'modelValue', 'defaultOption', 'label', 'disabled', 'action', 'readonly'],
    methods: {
        hideDropDown(){
            var dropDown = document.getElementById('dropdownContent' + this.label);
            dropDown.classList.remove('d-block');
        },
        showDropDown(){     
            this.checkExistValues();
            var dropDown = document.getElementById('dropdownContent' + this.label);
            dropDown.classList.add('d-block');
        },
        clearValue(){            
            if (!this.disabled) {
                this.model = null;
                this.keyword = null;
                this.options.filter = this.options.all;
                this.listItemSelected = [];
                this.$emit('update:modelValue', {'data': this.listItemSelected, 'label': this.label});
            }
            this.hideDropDown();
        },  
        chooseOption(o){ 
            this.model = o.id ?? null;
            this.options.filter = this.options.all;
            if(this.listItemSelected?.length == 0){
                this.listItemSelected.push(o.id);
                this.isChecked(o, true)
            }else{
                const found = this.listItemSelected.find(item => item == o.id);
                if(found != undefined){
                    this.listItemSelected.splice(this.listItemSelected.findIndex(v => v == o.id), 1);
                    this.isChecked(o, false)
                }else{
                    this.listItemSelected.push(o.id);
                    this.isChecked(o, true)
                }
            }
            this.$emit('update:modelValue', {'data': this.listItemSelected, 'label': this.label});
            this.hideDropDown();
        },
        isChecked(o, checked = false){
            if (this.options.filter) {
                this.options.filter.forEach(option => {
                    if(option.id == o.id){
                        option.checked = checked;
                    }
                });
            }
        },
        checkExistValues(){
            this.options.filter.forEach(option => {
                const found = this.listItemSelected.find(item => item == option.id);
                if(found != undefined){
                    option.checked = true;
                }else{
                    option.checked = false;
                }
            });
        },
        setValue(){  
            // if (this.options.filter) {
            //     for (let index = 0; index < this.options.filter.length; index++) {
            //         const element = this.options.filter[index];
            //         if(element.id == this.model){
            //             this.keyword = element.name ? element.name : (element.label ? element.label : null);
            //             return;
            //         }
            //     }
            //     this.keyword = null;
            // }
        },
        onInputFilter(){            
            this.showDropDown();
            if(!this.keyword){
                this.options.filter = this.options.all;
                this.clearValue();
                return;
            }
            let arr = [];
            for (let index = 0; index < this.options.all.length; index++) {
                if (this.options.all[index]?.name && removeVietnameseTones(this.options.all[index]?.name.toLowerCase()).indexOf(removeVietnameseTones(this.keyword.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }else if(this.options.all[index]?.label && removeVietnameseTones(this.options.all[index]?.label.toLowerCase()).indexOf(removeVietnameseTones(this.keyword.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }
            }
            this.options.filter = arr;
        },
        removeItem(id){
            this.listItemSelected.splice(this.listItemSelected.findIndex(v => v == id), 1);
            this.$emit('update:modelValue', {'data': this.listItemSelected, 'label': this.label});
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                return true;
            }else{
                return false;
            }
        },
    },
    watch: {
        // modelValue: {                
        //     handler(newModelValue){                          
        //         this.model = newModelValue;
        //         this.setValue();
                
        //     },        
        //     deep: true,
            
        // },
        // defaultOption: {                
        //     handler(newDefaultOption){                        
        //         this.options.filter = this.options.all = newDefaultOption;                
        //         this.setValue();
               
        //     },
        //     deep: true
        // },        
    },
    data() {
        return {
            model: this.modelValue ?? [],
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
            },
            keyword: '',
            listItemSelected: this.modelValue ?? [],
        }
    },
    created(){
        // emitter.on("clearSelectSearchValue", () => {
        //     this.keyword = '';
        //     this.model = null;
        // });
    },
    mounted(){
        this.setValue(); 
    }
}
</script>

<style scoped>
.dropdown-menu-content{
    width: 100%;
    max-height: 20rem;
    /* max-width: 12rem; */
    overflow: auto;
    z-index: 1002;
    transform: translate3d(0px, 0px, 0px) !important;
    background: white;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
input[type = 'text']{
    padding-left: 10px;
    padding-top: 2px;
}
.disabled{
    background-color: #e9ecef;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.multiselect{
    height: auto;
    max-height: 84px;
    overflow: auto;
    padding-bottom: 5px !important;
}
.item-select{
    background: #e5e5e5 !important;
    min-height: 30px !important;
    width:fit-content !important;
    float: left;
    margin-right: 10px;
}
.prd-search-box1:hover, .prd-search-box1:active {
    border-bottom: 1px solid #ccc !important;
}
</style>
