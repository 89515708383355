<template>
<!-- :class="this.voucherClass == true? 'voucherStyle' : '' " -->
    <div >
        <div class="form-group row">
            <div class="col-3 pr-0 divTitle" >
                <b>{{ $t('message.common.province') }}</b>
            </div>
            <div class="col-9 pl-1">
                <q-select name="provinces" :default-options="provinces" :multiple="false" v-model.trim="this.provinceId" :selectAll="false" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_province')" @getProvince="changeProvince"/>              
            </div>
        </div>
        <div class="form-group row">
            <div class="col-3 divTitle">
                <b>{{ $t('message.common.district') }}</b>
            </div>
            <div class="col-9 pl-1">
                <q-select name="districts" :default-options="districts" :multiple="false" v-model.trim="this.districtId" :selectAll="false" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_district')" @getDistrict="changeDistrict"/>                
            </div>
        </div>
        <div class="form-group row">
            <div class="col-3 divTitle">
                <b>{{ $t('message.common.ward') }}</b>
            </div>
            <div class="col-9 pl-1">                
                <q-select name="wards" :default-options="wards" :multiple="false" v-model.trim="this.wardId" :selectAll="false" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_ward')" @getWard="changeWard"/>                
            </div>
        </div>
    </div>
</template>

<script>
    import {$get} from "@/lib/utils";
    import Treeselect from 'vue3-treeselect'    
    import QSelect from '../QSelectMuliple.vue';
    export default {
        name: "InputLocationPicker",
        props: ['modelValue', 'readonly', 'voucherClass'],
        components: {
            Treeselect, QSelect
        },
        data() {
            let v = this.modelValue || {};              
            return {
                provinceId: v.provinceId || null,
                districtId: v.districtId || null,
                wardId: v.wardId || null,
                provinces: [],
                wards: [],
                districts: [],
            }
        },
        watch: {
            modelValue: {                
                handler(newValue){                     
                    let v = newValue || {};
                    this.provinceId = v.provinceId;
                    this.districtId = v.districtId;
                    this.wardId = v.wardId;
                    this.load();
                },
                deep: true
            }
        },
        methods: {
            changeWard(node) {                                
                this.wardId = node?.id ? node.id : node;                    
                this.emit();
            },
            emit() {                
                const modelValue =  {                    
                    provinceId: this.provinceId,
                    districtId: this.districtId,
                    wardId: this.wardId,
                };                             
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('input', modelValue)
                })
            },
            async changeDistrict() {
                const res = await $get('/v1/locations/wards', {districtId: this.districtId});
                this.wards = res.data;                   
                this.emit();
            },
            async changeProvince() {                               
                const res = await $get('/v1/locations/districts', {provinceId: this.provinceId});
                this.districts = res.data;
                this.emit();
            },
            async load() {
                const res = await $get('/v1/locations/provinces', {
                    provinceId: this.provinceId,
                    districtId: this.districtId
                });
                this.provinces = res.data.provinces;
                this.districts = res.data.districts;
                this.wards = res.data.wards;
            }
        },
        mounted() {            
            this.load();
        },
    }
</script>

<style scoped>
/* .voucherStyle  */
   .vue-treeselect__control {
        border: 0 !important;
    }
</style>
