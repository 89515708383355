<template>
    <div class="sale-screen" @keyup.enter="processBarcodeScanner">
        <div id="header-sale">
            <div class="row header-main-menu">
                <div class="col-4 search-box__wrapper">
                    <InvoiceSearch @select="onSelectInvoiceProcessing" v-if="currentTab.type == TYPE_RETURN && !currentTab.fastReturn"></InvoiceSearch>
                    <ProductSearch @select="onSelectProduct" :getAll="true" :notAdd="true" :action="$constant.actionGetPriceList" :priceListId="priceListId" :readonly="this.currentTab.isCreateInvoice" v-else></ProductSearch>
                </div>

                <div class="col-5 p-0">
                    <div class="sale-tabs__root" ref="saleTabRoot">
                        <template v-if="showLeftRight" >
                            <div class="sale-tabs__scroller sale-tabs__left" @click.prevent="onClickScroll('left')" >
                                <div>
                                    <i class="fa fa-chevron-left"/>
                                </div>
                            </div>
                            <div class="sale-tabs__scroller sale-tabs__right"  @click.prevent="onClickScroll('right')">
                                <div>
                                    <i class="fa fa-chevron-right"/>
                                </div>
                            </div>
                        </template>

                        <div class="sale-tabs__btn" :style="{left: btnLeftFloat+'px'}" >                             
                            <!-- Thêm mới hóa đơn -->
                            <button type="button" @click.prevent="addTab()" class="sale-tabs__btn--add" data-toggle="tooltip" data-placement="top" :title="$t('message.button.addInvoice')"
                            v-if="hasPermission(this.$permissions.invoices.create) || user.id == this.$constant.isAdmin">
                                <i class="fa fa-plus-circle" ></i>
                            </button>
                            <!-- Thêm mới đặt hàng bán -->
                            <a class="nav-link" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                v-if="hasPermission(this.$permissions.orders.create) || user.id == this.$constant.isAdmin">
                                <i class="fas fa-caret-down text-white" ata-toggle="tooltip" data-placement="top" :title="$t('message.button.addOrder')"></i>
                            </a>                            
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                <a class="dropdown-item" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px" @click.prevent="addTab(null, 'order')">
                                    {{$t('message.button.addOrder')}}
                                </a>
                            </div>
                        </div>
                        <draggable
                            v-model="tabs"
                            class="sale-tabs"
                            group="tabs"
                            @start="drag=true"
                            :wrap="false"
                            @end="drag=false"
                            id="sale-tabs"
                            item-key="id">
                            <template #item="{element}">
                                <div v-if="!element.branchId || element.branchId == currentBranch.id"
                                     class="sale-tabs__tab" :class="{'sale-tabs__tab--active': element.id === currentTab.id}">
                                    <div class="sale-tabs__tab-content" @click.prevent="changeTab(element)">
                                        <div>
                                            <a class="sale-tabs__link" href="">{{ element.name }}</a>
                                            <a class="sale-tabs__close " href="" @click.stop.prevent="confirmCloseTab(element)" style="margin-left: 10px" data-toggle="tooltip" data-placement="top" :title="$t('message.button.close')">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>

                <div class="col-3 float-right">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <ul class="navbar-nav ml-auto mr-4">
                                <!-- <li class="nav-item">
                                    <a class="nav-link" href="#" role="button" @click="onShowModalOrderProcessing"
                                       aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0" title="Xử lý đơn hàng">
                                        <i class="fas fa-recycle text-white"></i>
                                    </a>
                                </li> -->
                                <li class="nav-item">
                                    <a class="nav-link" href="#" @click="onReturn" role="button" aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0" :title="$t('message.InvoiceIndex.button.return')"
                                    v-if="hasPermission(this.$permissions.returns.create) || user.id == this.$constant.isAdmin">
                                        <i class="fas fa-reply text-white"></i>
                                    </a>
                                </li>
                                <!-- <li class="nav-item">
                                    <a class="nav-link" href="#" @click="onReturn" role="button" aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0" title="Trả hàng">
                                        <i class="fas fa-undo text-white"></i>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#" @click="onReturn" role="button" aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0" title="In phiếu">
                                        <i class="fas fa-print text-white"></i>
                                    </a>
                                </li> -->
                                <li class="nav-item">
                                    <a class="nav-link text-white" href="javascript:void(0);">
                                        {{ this.user.name }}
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-bars text-white"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                        <!-- <a class="dropdown-item" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-chart-pie"></i> Xem báo cáo cuối ngày
                                        </a>
                                        <a class="dropdown-item" href="#" @click="onShowModalOrderProcessing"
                                           aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-recycle"></i> Xử lý đặt hàng
                                        </a> -->
                                        <a class="dropdown-item" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px" @click="onShowModalInvoiceProcessing">
                                            <i class="fas fa-undo-alt"></i> 
                                            {{$t('message.button.selectInvoiceForReturn')}}
                                        </a>
                                        <a class="dropdown-item" href="/customers/index" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-user-shield"></i> 
                                            {{$t('message.headers.manage')}}
                                        </a>
                                        <a class="dropdown-item" @click="logout" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-sign-out-alt"></i> 
                                            {{$t('message.headers.logout')}}
                                        </a>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-screen__main">
<!--            <keep-alive>-->
                <component :is="currentComponent"
                           v-if="currentTab && currentTab.id"
                           v-model="currentTab"
                           :key="currentTab.id"    
                           :action="this.$constant.typeMultiOrder"                       
                           @removeTab="removeCurrentTab"
                           @orderProcessing="addTab"
                           @print="onPrint"
                           @SetPriceListId="SetPriceListId"
                           @setSaleChannelId="setSaleChannelId"
                           @setSoldById="setSoldById"
                           @setCustomerId="setCustomerId"
                           @setDescription="setDescription"
                           @setCashMoney="setCashMoney"
                           @setCardMoney="setCardMoney"
                           @setTransferMoney="setTransferMoney"
                           @setVoucher="setVoucher"
                           @setDebtTerm="setDebtTerm"
                           @setProductGift="setProductGift"
                ></component>
<!--            </keep-alive>-->
        </div>

        <div id="footer-sale" v-if="currentTab.type != TYPE_RETURN">
            <div class="row footer-sale-content">
                <div class="col-9 p-0 tabs-pos">
                    <ul class="nav nav-tabs border-none pl-4">
                        <li class="nav-item cursor-pointer">
                            <span>
                                <a class="nav-link d-flex align-items-center justify-content-center" :class="this.saleMode == this.$constant.sale_mode_fast ? 'active' : ''" @click="toggleSaleMode(this.$constant.sale_mode_fast)">
                                    <i class="fa fa-bolt d-flex align-items-center justify-content-center"></i>
                                    <span class="p-2 text-bold">
                                        {{$t('message.common.saleModeFast')}}
                                    </span>
                                </a>
                            </span>
                        </li>
                        <li class="nav-item cursor-pointer" v-if="isUseDelivery">
                            <span>
                                <a class="nav-link d-flex align-items-center justify-content-center" :class="this.saleMode == this.$constant.sale_mode_trans ? 'active' : ''" @click="toggleSaleMode(this.$constant.sale_mode_trans)">
                                    <i class="fa fa-truck d-flex align-items-center justify-content-center"></i>
                                    <span class="p-2 text-bold">
                                        {{$t('message.common.saleModeTransfer')}}
                                    </span>
                                </a>
                            </span>
                        </li>
                    </ul>
                </div>
                <div class="col-3 p-0 float-left">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <a class="text-dark m-0 p-0 pr-2" href="#" role="button" :title="currentBranch.name" style="padding-bottom: 0">
                                <i class="fas fa-map-marker-alt"></i>
                            </a>
                            <div class="dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="m-tb-auto p-0" v-text="currentBranch.name"></span>
                            </div>
                            <div class="dropdown-menu footer-dropdown-menu" style="margin-top: 0">
                                <a v-for="branch in branches"
                                    :key="branch.id"
                                    @click.prevent="setBranch(branch)"
                                    class="dropdown-item"
                                    :class="branch.id == currentBranch.id ? 'active' : ''"
                                    href="#">{{ branch.name }}</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-modal">
            <ModalSelectOrderProcessing ref="modalSelectOrderProcessing" @select="onSelectOrderProcessing"></ModalSelectOrderProcessing>
            <ModalSelectInvoiceProcessing ref="ModalSelectInvoiceProcessing" @select="onSelectInvoiceProcessing"  @fastReturn="value => handleTabInput(true)"></ModalSelectInvoiceProcessing>
            <ModalConfirmCancel ref="ModalCancel" @closeTab="closeTab"/>
        </div>
    </div>
    <div class="sale-form__print">
        <ModalPrintInvoiceOrder v-if="printType == TYPE_INVOICE || printType == TYPE_ORDER" :data="currentTab"></ModalPrintInvoiceOrder>
        <ModalPrintDelivery v-if="printType == TYPE_DELIVERY" :data="currentTab"></ModalPrintDelivery>
        <ModalPrintReturnInvoice v-if="printType == TYPE_RETURN" :data="currentTab"></ModalPrintReturnInvoice>
    </div>
</template>

<script>

import OrderFormTab from "./OrderFormTab";
import draggable from 'vuedraggable'
import store from "../../store";
import ProductSearch from "@/components/ProductSearch.vue";
import InvoiceSearch from "@/components/InvoiceSearch.vue";
import * as toastr from 'toastr';
import {
  $alert,
  $get, $post, ACTION_CREATE, ACTION_ORDER_PROCESSING,
  ACTION_UPDATE_INVOICE,
  ACTION_UPDATE_ORDER,
  isEmpty,
  TYPE_INVOICE,
  TYPE_ORDER,
  TYPE_MULTI_ORDER,
  TYPE_RETURN,
  TYPE_DELIVERY, ACTION_RETURN_INVOICE,
  TYPE_REFUND,
  forEach
} from "../../lib/utils";
import ModalSelectOrderProcessing from "./ModalSelectOrderProcessing";
import { useLoading } from 'vue3-loading-overlay';
import ModalPrintInvoiceOrder from "./ModalPrintInvoiceOrder";
import ModalPrintDelivery from "./ModalPrintDelivery";
import ModalPrintReturnInvoice from "@/views_sale/sales/ModalPrintReturnInvoice";
import ModalSelectInvoiceProcessing from "./ModalSelectInvoiceProcessing";
import emitter from "@/lib/emitter";
import ModalConfirmCancel from './ModalConfirm.vue';
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from "@/permissionMixin";
import { mapGetters, mapActions } from 'vuex';
import storeModul from "@/storeModul";
import {clone} from "@/lib/utils";
export default {
    name: "SaleForm",
    mixins: [permissionMixin],
    components: {
        AppPermission,
        ModalConfirmCancel,
        ModalPrintInvoiceOrder,
        ModalPrintDelivery,
        ModalSelectOrderProcessing, ProductSearch, OrderFormTab, draggable, ModalPrintReturnInvoice, ModalSelectInvoiceProcessing, InvoiceSearch},
    data() {
        return {
            currentComponent: 'OrderFormTab',
            priceListId: this.$constant.priceListIdCommon,
            channelId: null,
            soldById: null,
            customerId: null,
            description: null,
            debtTerm: null,
            voucher: 0,
            transfer: 0,
            card: 0,
            cash: 0,
            drag: false,
            showLeftRight: false,
            btnLeftFloat: -30,
            currentTab: {},
            tabs: [],
            productGifts: [],
            branches: store.state.GlobalConfig?.userBranches,
            currentBranch: store.state.CurrentBranch,
            user: store.state.Auth ? store.state.Auth.user : {},
            barcode: '',
            printType: '',
            saleMode: this.$constant.sale_mode_fast,
            TYPE_INVOICE, TYPE_ORDER, TYPE_DELIVERY, TYPE_RETURN,
            ids: []
            // fastReturn: false,
        }
    },
    created() {
        this.handleTabInput();
        storeModul.dispatch('productStore/setProductIds', []);        
    },
    mounted() {
        document.title = this.$t('message.headers.branch') + ' ' + this.currentBranch.name + ' - ' + this.$t('message.headers.sales');
        this.rootWidth = this.$refs.saleTabRoot.offsetWidth;
        this.divSaleTab = window.jQuery('#sale-tabs');
        this.updateStyle();
        this.checkHandleTabInput();
    },
    computed: {
        getIndexTabNext() {
            const mapTabs = {};
            this.tabs.forEach(function (tab) {
                mapTabs[tab.id] = true;
            });

            let tabNext = 0;
            do {
                ++tabNext;
            } while (mapTabs[tabNext]);

            return tabNext;
        }, 
        ...mapGetters({
            productIds: 'productStore/productIds',
            orderIds: 'orderStore/orderIds',
            isUseDelivery: 'settingStore/isUseDelivery'
        }),
    },
    methods: {
        SetPriceListId(id) {
            this.priceListId = id;
        },
        setSaleChannelId(channelId) {
            this.channelId = channelId;
        },
        setSoldById(id) {
            this.soldById = id;
        },
        setCustomerId(id) {
            this.customerId = id;
        },
        setDescription(description) {
            this.description = description;
        },

        setVoucher(value) {
            this.voucher = value;
        },
        setTransferMoney(value) {
            this.transfer = value;
        },
        setCardMoney(value) {
            this.card = value;
        },
        setCashMoney(value) {
            this.cash = value;
        },
        setDebtTerm(value) {
            this.debtTerm = value;
        },
        setProductGift(value) {
            this.productGifts = value;
        },
        toggleSaleMode(mode){
            this.saleMode = mode;
        },
       
        async handleTabInput(fastReturn = false) {                  
            const tabs = localStorage.getItem('saleTabData_KH' + this.user.id);                          
            if (tabs) {
                this.tabs = JSON.parse(tabs);
            }                        
            const tabNext = this.getIndexTabNext;
            let activeTabNext = false;
            const query = this.$route.query;
            if (query.type) {  
                const type = query.type;                
                if (type === 'Invoice') {                   
                    this.tabs.unshift({id: tabNext, name: this.$t('message.headers.invoices') + " " + tabNext, type: TYPE_INVOICE});
                    activeTabNext = true;
                }
                if (type === 'Order') {
                    this.tabs.unshift({id: tabNext, name: this.$t('message.OrderIndex.button.order') + " " + tabNext, type: TYPE_ORDER});
                    activeTabNext = true;
                }
                if (type === 'multiOrder') {   
                    let orderIds = JSON.parse(localStorage.getItem('orderIds'));                                   
                    const res = await $post('/v1/orders/get-order-detail-by-id', {id: orderIds}); 
                    let data = res.result;
                    
                    let invoiceData = []; 
                    let totalCustomerPaid = 0;
                   
                    data.forEach((value, index) => {   
                        const orderCode = value.code;   
                        const originOrder = this.tabs.find(el => (el.name == orderCode));
                        
                        if (originOrder) {                                       
                            this.currentTab = originOrder;                                              
                        } else {
                            value.promotion = value.promotions;                                            
                            if(value.paymentsInfo){
                                value.paymentsInfo.forEach(el => {
                                    totalCustomerPaid += (+el.amount) ?? 0;
                                });
                            }
                            value.totalCustomerPaid = totalCustomerPaid;                          
                            invoiceData.push({
                                name: value.code,
                                id: +(tabNext+index),                              
                                orderId: value.id,                                                        
                                type: TYPE_ORDER,
                                method: this.$constant.typeMultiOrder,
                                products: value.order_items,                            
                                action: ACTION_UPDATE_ORDER, 
                                customerName: value.customerName,
                                channelId: value.saleChannelId,                            
                                couponCode: value.couponCode?? null,
                                couponCodes: value.couponCode?? null,
                                soldById: value.soldById ?? null,
                                valueCoupon: value.valueCoupon,                                                        
                                infoVouchers: value.voucher_detail,                                 
                                isShowCodeActive: value.couponCode != null ? true : false, 
                                customerPayment: value.total,
                                totalPriceAfterDiscount: value.total,                            
                                promotions: value.promotion,
                                promotion: value.promotion,
                                surchargeAmount: value.surchargeAmount,                            
                                discountValue: value.discountValue,
                                discount: value.discountValue,
                                promotionValue:value.promotionValue,
                                cash: value.cash,
                                card: value.card,
                                transfer: value.transfer,
                                totalCustomerPaid: value.totalCustomerPaid,
                                ExpectedDateOfTransfer: value.ExpectedDateOfTransfer,
                                priceListId: value.priceListId,
                                discountRatio: value.discountRatio,     
                                discountType: value.discountType, 
                                paymentsInfo: value.paymentsInfo,
                                valuePercent: value.discountRatio,
                                isUpdated: true,
                                // Bán giao hàng
                                from_address_id: value.from_address_id,     
                                branchAddressId: value.branchAddressId,                       
                                informationCustomer: value.informationCustomer,
                                to_name:value.to_name,
                                to_phone: value.to_phone,
                                customerAddressValue: value.customerAddressValue,
                                customerProvinceId: value.customerProvinceId,
                                customerDistrictId: value.customerDistrictId,
                                customerWardId: value.customerWardId,
                                weight: value.weight,
                                weightUnit: value.weightUnit,
                                long: value.long,
                                width: value.width,
                                hight: value.hight,
                                description: value.description,
                                shippingNote: value.shippingNote,
                                customerPayments: value.customerPayments,
                                usingPriceCod: value.usingPriceCod,                            
                                voucher: value.voucher,
                                serviceTypeId: value.serviceTypeId,
                                isTransporterIntegration: value.isTransporterIntegration,
                                price: value.price,
                                deliveryCode: value.deliveryCode,
                                shipping_price: value.shipping_price,
                                deliveryTime: value.deliveryTime,
                                soldByName: value.soldByName,
                                soldByCompany: value.soldByCompany,
                                soldByTaxCode: value.soldByTaxCode,
                                soldByAddress: value.soldByAddress,
                                isChangeInformationInvoice: value.isChangeInformationInvoice,
                                customerId: value.customerId,
                                _transporterId: value.transporterId,
                                transporterName: value.transporterName,
                                hasShipping: value.hasShipping,
                                promotionId: value.promotionId,
                                shippingMode: value.shippingMode,                                                        
                                shopee_status: value.shopee_status,
                                shopee_value: value.shopee_value,
                                currentServiceId: value.currentServiceId,
                                serviceId: value.serviceId,
                                from_address: value.from_address,
                                to_address: value.to_address,
                            });
                            if (value.hasShipping) {
                                this.toggleSaleMode(this.$constant.sale_mode_trans);
                            } 
                        }
                    });              
                    invoiceData.forEach(ivData => {
                        this.tabs.unshift(clone(ivData));                       
                        activeTabNext = true; 
                        this.currentTab = clone(ivData);                        
                    });   
                    this.updateStyle();                    
                }        
                        
                if (type == 'short-create-invoice'){
                    let params = {
                        branchId: this.currentBranch.id,
                        ids: this.productIds
                    }
                    let data = await $post('/v1/order-suppliers/get-products-id', params);                      
                    let invoiceData = {
                        name: this.$t('message.headers.invoices') + " " + tabNext,
                        id: +tabNext,
                        type: TYPE_INVOICE,
                        products: data.result
                    };
                    this.tabs.unshift(invoiceData);
                    activeTabNext = true; 
                    this.currentTab = invoiceData;                    
                }

                if (type == 'short-create-order'){
                    let params = {
                        branchId: this.currentBranch.id,
                        ids: this.productIds
                    }
                    let data = await $post('/v1/order-suppliers/get-products-id', params);                     
                    let invoiceData = {
                        name: this.$t('message.OrderIndex.button.order') + " " + tabNext,
                        id: +tabNext,
                        type: TYPE_ORDER,
                        products: data.result
                    };
                   
                    this.tabs.unshift(invoiceData);
                    activeTabNext = true; 
                    this.currentTab = invoiceData;
                }
            }
           
            if (query.order) {                               
                const orderCode = query.order;                                                
                const originOrder = this.tabs.find(el => (el.name == orderCode));
                const isUpdateOrder = query.isUpdate ? true : false;
                const isCreateInvoice = query.createInvoice ? true : false;
                if (originOrder) {                                       
                    this.currentTab = originOrder;   
                    if(query.isCoppy) {
                        this.currentTab.action = query.isCoppy                        
                        this.currentTab.code = 'DH' + Math.floor(100000 + Math.random() * 900000);
                        this.currentTab.name = this.currentTab.code;
                        this.currentTab.isUpdated = false;
                        this.currentTab.isUpdateOrder = true;
                        this.currentTab.isCreateInvoice = false;
                    }else {
                        this.currentTab.isUpdateOrder = isUpdateOrder;
                        this.currentTab.isCreateInvoice = isCreateInvoice;
                    }                    
                }else {                                    
                    let orderData = await this.getData(orderCode, TYPE_ORDER);                    
                    if (!isEmpty(orderData)) {                        
                        orderData.type = TYPE_ORDER;                                               
                        orderData.id = +tabNext;
                        orderData.isUpdateOrder = isUpdateOrder;
                        orderData.isCreateInvoice = isCreateInvoice;                        
                        orderData.channelId = orderData.saleChannelId;
                        orderData._transporterId = orderData.transporterId; // bổ sung tránh trường hợp bị null ảo
                        orderData.valuePercent = orderData.discountRatio;
                        orderData.discount = orderData.discountValue;
                        orderData.valuePercent = orderData.discountRatio;
                        let totalCustomerPaid = 0;
                        if(orderData.paymentsInfo){
                            orderData.paymentsInfo.forEach(el => {
                                totalCustomerPaid += (+el.amount) ?? 0;
                            });
                        }
                        orderData.totalCustomerPaid = totalCustomerPaid;  
                        orderData.isChangeInformationInvoice = orderData.isChangeInformationInvoice ? true : false;  
                        if (query.isCoppy){                            
                            orderData.action = 'copy';
                            orderData.name = 'DH' + Math.floor(100000 + Math.random() * 900000);
                            orderData.code = orderData.name;
                            orderData.isUpdated = false;
                        } else {                            
                            orderData.action = ACTION_UPDATE_ORDER;
                            orderData.name = orderData.code;
                            orderData.isUpdated = true;
                            if (orderData.couponDetail) {
                                orderData.couponDetailId = orderData.couponDetail?.id;
                                if (orderData.couponDetailId) {
                                    orderData.isShowCodeActive = true;
                                }
                                // orderData.couponCode = orderData.couponDetail?.code;
                            } 
                            if (orderData.voucherDetail) {
                                let infoVouchers = [];
                                let voucherDetailIds = [];
                                let valueVoucher = 0;
                                orderData.voucherDetail.forEach(voucher => {
                                    infoVouchers.push({
                                        code: voucher.code,
                                        value: +voucher.coupon_voucher.value,
                                        id: voucher.id,
                                        voucherId: voucher.coupon_voucher.id,
                                        applyMultipleVoucher: voucher.coupon_voucher.applyMultipleVoucher,
                                    });
                                    voucherDetailIds.push(voucher.id);
                                    valueVoucher = +valueVoucher + +voucher.coupon_voucher.value;
                                });
                                orderData.infoVouchers = infoVouchers;
                                orderData.voucherDetailIds = voucherDetailIds;
                                orderData.valueVoucher = valueVoucher;
                            }
                        }                               
                        if (orderData.hasShipping) {
                            this.toggleSaleMode(this.$constant.sale_mode_trans);
                        }
                        this.tabs.unshift(orderData);                        
                    }
                }
                activeTabNext = true;                
            }

            if (query.updateInvoice) {              
                const invoiceCode = query.updateInvoice;
                const invoiceTabName = query.isCoppy === 'copy' ? 'HD' + invoiceCode :'Update_' + invoiceCode;
                const originInvoice = this.tabs.find(el => (el.name == invoiceTabName));
                if (originInvoice) {
                    this.currentTab = originInvoice;
                } else {     
                    const invoiceData = await this.getData(invoiceCode, TYPE_INVOICE);
                    if (!isEmpty(invoiceData)) {
                        invoiceData.name = invoiceTabName;
                        if (query.isCoppy){                            
                            invoiceData.action = 'copy';
                            invoiceData.name = 'HD' + Math.floor(100000 + Math.random() * 900000);
                            invoiceData.code = invoiceData.name;
                            invoiceData.isUpdated = false;
                        } else {                            
                            invoiceData.action = ACTION_UPDATE_INVOICE;
                            invoiceData.name = invoiceData.code;
                            invoiceData.isUpdated = true;
                            invoiceData.isFirst = true;
                            if (invoiceData.couponDetail) {
                                invoiceData.couponDetailId = invoiceData.couponDetail?.id;
                                if (invoiceData.couponDetailId) {
                                    invoiceData.isShowCodeActive = true;
                                }
                                // invoiceData.couponCode = invoiceData.couponDetail?.code;
                            } 
                            if (invoiceData.voucherDetail) {
                                let infoVouchers = [];
                                let voucherDetailIds = [];
                                let valueVoucher = 0;
                                invoiceData.voucherDetail.forEach(voucher => {
                                    infoVouchers.push({
                                        code: voucher.code,
                                        value: +voucher.coupon_voucher.value,
                                        id: voucher.id,
                                        voucherId: voucher.coupon_voucher.id,
                                        applyMultipleVoucher: voucher.coupon_voucher.applyMultipleVoucher,
                                    });
                                    voucherDetailIds.push(voucher.id);
                                    valueVoucher = +valueVoucher + +voucher.coupon_voucher.value
                                });
                                invoiceData.infoVouchers = infoVouchers;
                                invoiceData.voucherDetailIds = voucherDetailIds;
                                invoiceData.valueVoucher = valueVoucher;
                            }
                        }
                        invoiceData.type = TYPE_INVOICE;
                        // invoiceData.isUpdated = true;
                        // invoiceData.action = ACTION_UPDATE_INVOICE;
                        invoiceData.invoiceId = invoiceData.id;
                        invoiceData.id = tabNext;
                        invoiceData.products = invoiceData.invoice_items;
                        invoiceData.channelId = invoiceData.saleChannelId;
                        invoiceData.valuePercent = invoiceData.discountRatio;                        
                        invoiceData.discount = invoiceData.discountValue;
                        invoiceData.valuePercent = invoiceData.discountRatio;
                        invoiceData.surchargeAmount = invoiceData.invoiceOrderSurcharges;
                        this.tabs.unshift(invoiceData);
                    }
                }
                activeTabNext = true;
            }
            // Trả hàng hóa đơn
            //--------------------------------------------------------------------------
            if (query.returnInvoice) {
                const invoiceCode = query.returnInvoice;
                const returnTabName = this.$t('message.InvoiceIndex.button.return') + " " + invoiceCode;
                const originReturn = this.tabs.find(el => (el.name == returnTabName));
              if (originReturn) {
                this.currentTab = originReturn;
              } else {
                const returnData = await this.getDataInvoice(invoiceCode, TYPE_INVOICE);
                if (!isEmpty(returnData)) {
                    returnData.name = returnTabName;
                    returnData.type = TYPE_RETURN;
                    returnData.isUpdated = false;
                    if (returnData.coupon_detail) {
                        returnData.couponDetailId = returnData.coupon_detail?.id;
                        if (returnData.coupon_detail) {
                            returnData.isShowCodeActive = true;
                        }
                        // returnData.couponCode = returnData.coupon_detail?.code;
                    } 
                    if (returnData.voucher_detail) {
                        let infoVouchers = [];
                        let voucherDetailIds = [];
                        let valueVoucher = 0;
                        returnData.voucher_detail.forEach(voucher => {
                            infoVouchers.push({
                                code: voucher.code,
                                value: +voucher.coupon_voucher.value,
                                id: voucher.id,
                                voucherId: voucher.coupon_voucher.id,
                                applyMultipleVoucher: voucher.coupon_voucher.applyMultipleVoucher,
                            });
                            voucherDetailIds.push(voucher.id);
                            valueVoucher = +valueVoucher + +voucher.coupon_voucher.value
                        });
                        returnData.infoVouchers = infoVouchers;
                        returnData.voucherDetailIds = voucherDetailIds;
                        returnData.valueVoucher = valueVoucher;
                    }
                    if(returnData.products){
                        returnData.products.forEach(product => {
                            if(product.consignments && product.listConsignments && product.consignments.length && product.listConsignments.length){
                                product.consignments = product.consignments.filter(consignment => product.listConsignments.includes(consignment.id));
                            }
                            if(product.plusOrder){
                                product.plusOrder.forEach(plus => {
                                    if(plus.consignments && product.listConsignments && plus.consignments.length && product.listConsignments.length){
                                        plus.consignments = plus.consignments.filter(consignment => product.listConsignments.includes(consignment.id));
                                    }
                                })
                            }
                        })
                    }
                    returnData.action = ACTION_RETURN_INVOICE;
                    returnData.invoiceId = returnData.id;
                    returnData.id = tabNext;
                    this.tabs.unshift(returnData);
                    this.currentTab = returnData;
                    document.title = this.$t('message.InvoiceIndex.button.return');
                }
              }
              activeTabNext = true;
            }
            //--------------------------------------------------------------------------
            // Trả hàng theo đề nghị hoàn hàng
            //--------------------------------------------------------------------------
            if (query.returnRefunds) {
                const refundCode = query.returnRefunds;                
                const returnTabName = this.$t('message.InvoiceIndex.button.return') + " " + refundCode;
                const originReturn = this.tabs.find(el => (el.name == returnTabName));
              if (originReturn) {
                this.currentTab = originReturn;
              } else {
                const returnData = await this.getDataRefund(refundCode);                
                if (!isEmpty(returnData)) {
                    returnData.name = returnTabName;
                    returnData.type = TYPE_RETURN;
                    returnData.isRefund = true;
                    returnData.isUpdated = false;
                    if (returnData.coupon_detail) {
                        returnData.couponDetailId = returnData.coupon_detail?.id;
                        if (returnData.coupon_detail) {
                            returnData.isShowCodeActive = true;
                        }                        
                    } 
                    if (returnData.voucher_detail) {
                        let infoVouchers = [];
                        let voucherDetailIds = [];
                        let valueVoucher = 0;
                        returnData.voucher_detail.forEach(voucher => {
                            infoVouchers.push({
                                code: voucher.code,
                                value: +voucher.coupon_voucher.value,
                                id: voucher.id,
                                voucherId: voucher.coupon_voucher.id,
                                applyMultipleVoucher: voucher.coupon_voucher.applyMultipleVoucher,
                            });
                            voucherDetailIds.push(voucher.id);
                            valueVoucher = +valueVoucher + +voucher.coupon_voucher.value
                        });
                        returnData.infoVouchers = infoVouchers;
                        returnData.voucherDetailIds = voucherDetailIds;
                        returnData.valueVoucher = valueVoucher;
                    }
                    returnData.action = ACTION_RETURN_INVOICE;
                    returnData.refundId = returnData.id;
                    returnData.code = null;
                    returnData.invoiceCode = refundCode;
                    // returnData.id = tabNext;
                    this.tabs.unshift(returnData);
                    this.currentTab = returnData;
                    document.title = this.$t('message.InvoiceIndex.button.return');
                }
              }
              activeTabNext = true;
            }
            //--------------------------------------------------------------------------
            if (query.typeTab == 'newReturn' || fastReturn) {
                // this.fastReturn = fastReturn ? true : false;
                const returnTabName = this.$t('message.InvoiceIndex.button.return') + " " + tabNext;
                const originReturn = this.tabs.find(el => (el.name == returnTabName));
                if (originReturn) {
                this.currentTab = originReturn;
                } else {
                const returnData = {};
                    returnData.name = returnTabName;
                    returnData.type = TYPE_RETURN;
                    returnData.isUpdated = true;
                    returnData.action = ACTION_RETURN_INVOICE;
                    returnData.invoiceId = null;
                    returnData.id = tabNext;
                    returnData.fastReturn = fastReturn;
                    returnData.branchId = store.state.CurrentBranch.id,
                    this.currentTab = returnData;
                    this.tabs.unshift(returnData);
                }
                activeTabNext = true;
            }

            if (!this.tabs.length) {
                this.addTab();
                activeTabNext = true;
            }

            if (activeTabNext) {
                if (!this.currentTab || !this.currentTab.id) {
                    this.currentTab = this.tabs[0];              
                }
            } else {                
                this.setCurrentTab();
            }                        
            await this.$router.replace({params: {}});
        },
        async checkHandleTabInput(){            
            const tabs = localStorage.getItem('saleTabData_KH' + this.user.id);            
            if (tabs) {
                this.tabs = JSON.parse(tabs);
            }
            const query = this.$route.query;
            if (query.type) {
                const type = query.type;
                if (type === 'Return') {
                    this.onShowModalInvoiceProcessing();
                }
            }
            await this.$router.replace({params: {}});
        },
        onShowModalInvoiceProcessing(){
            this.$refs.ModalSelectInvoiceProcessing.show();
        },
        async onSelectInvoiceProcessing(code) {
            emitter.emit("offLoading");

            await this.$router.push({query: {returnInvoice: code}});
            await this.handleTabInput();

            emitter.emit("offLoading");
        },
        async onReturn() {
            let tabNext = this.getIndexTabNext;
            const returnTabName = this.$t('message.InvoiceIndex.button.return') + " " + tabNext;
            const returnData = {};
            returnData.name = returnTabName;
            returnData.type = TYPE_RETURN;
            returnData.isUpdated = true;
            returnData.action = ACTION_RETURN_INVOICE;
            returnData.invoiceId = null;
            returnData.id = tabNext;
            this.tabs.unshift(returnData);
            this.currentTab = this.tabs[0];

        },
        async getData(code, type = TYPE_INVOICE) {
                 
            var res;
            let isCopy = this.$route.query.isCoppy; 
           
            if(isCopy && isCopy == 'copy')
            {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code, isCopy: isCopy});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code, isCopy: isCopy});     
                }
            }
            else {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code});
                }
                            
            }            
             if (res.status && res.status.code == 200) {
                    return res.result;
                } else {
                    $alert(res);
                    return false;
                }
        },
        async getDataInvoice(code, type = TYPE_INVOICE) {
            const res = await $get('/v1/invoices/get-invoice-detail', {type: type, code: code, branchId: this.currentBranch.id});
            if (res.status && res.status.code == 200) {
                if(res.result.status == false && res.result.message){
                    toastr.error(res.result.message);
                    return false;
                }else if(res.result.status == false){
                    toastr.warning(this.$t('message.common.returned'));
                    return false;
                }else{
                    if(res.result.message){
                        toastr.warning(res.result.message);
                    }
                    return res.result.data;
                }
            } else {
                $alert(res);
                return false;
            }
        },
         async getDataRefund(code) {
            const res = await $get('/v1/refunds/show', {code: code, branchId: this.currentBranch.id});
            if (res.status && res.status.code == 200) {
                if(res.result.status == false && res.result.message){
                    toastr.error(res.result.message);
                    return false;
                }else if(res.result.status == false){
                    toastr.warning(this.$t('message.common.returned'));
                    return false;
                }else{
                    if(res.result.message){
                        toastr.warning(res.result.message);
                    }
                    return res.result.data;
                }
            } else {
                $alert(res);
                return false;
            }
        },
        setCurrentTab() {            
            const currentTabIndex = localStorage.getItem('currentSaleTab_KH' + this.user.id);
            const branchTabs = this.tabs.filter((el) => (!el.branchId || el.branchId == this.currentBranch.id));
            if (branchTabs && branchTabs.length) {
                this.currentTab = branchTabs.find(el => (el.id == currentTabIndex)) || branchTabs[0];
            } else {
                this.currentTab = {};
            }
        },
        addTab(tab = null, order = null) {
            let id = this.getIndexTabNext;
            if (tab) {                
                tab.id = id;
                tab.name = this.$t('message.headers.invoices') + " " + id;
                this.tabs.push(tab);
            } else {
                if(order){
                    tab = {
                        id,
                        name: this.$t('message.OrderIndex.button.order') + " " + id,
                        type: TYPE_ORDER,
                        action: ACTION_CREATE
                    };
                }else{                    
                    tab = {
                        id,
                        name: this.$t('message.headers.invoices') + " " + id,
                        type: TYPE_INVOICE,
                        action: ACTION_CREATE
                    };
                }

                this.tabs.unshift(tab);
            }
            if(!tab.saleMode){
                tab.saleMode = this.$constant.shipping_mode_default;
            }
            this.currentTab = tab;

            this.updateStyle();
            this.$nextTick(() => {
                this.scrollLeftTab(0);
            })
        },
        updateStyle() {
            const width = this.tabs.length * 120;
            if (width  >= this.rootWidth) {
                this.showLeftRight = true;
                this.btnLeftFloat = -55;
            } else {
                this.showLeftRight = false;
                this.btnLeftFloat = -30;
            }
        },
        changeTab(tab) { 
            localStorage.setItem('currentSaleTab_KH' + this.user.id, tab.id);
            this.currentTab = tab;
            if (this.currentTab.hasShipping) {     
                this.toggleSaleMode(this.$constant.sale_mode_trans);
            }else{       
                this.toggleSaleMode(this.$constant.sale_mode_fast);
            }       
        },
        async removeCurrentTab(isPartialOrderProcessing) {
            const currentTab = this.currentTab;
            this.closeTab(currentTab, false);            
            if (currentTab.orderId && currentTab.action == ACTION_ORDER_PROCESSING ){                              
                const orderRelatedTab = this.tabs.find((t) => (t.orderId == currentTab.orderId && t.id != currentTab.id));
                if (orderRelatedTab) {                  
                    if (isPartialOrderProcessing) {                   
                        this.currentTab = orderRelatedTab;
                        if (confirm(this.$t('message.common.confirmEndOrder', {code: currentTab.code}))) {                            
                            const res = await $post('/v1/orders/saveStatus', {
                                id: currentTab.orderId,
                                action: 'complete'
                            });
                            $alert(res);
                        }
                    }
                    this.closeTab(orderRelatedTab, false);
                }
            }            
            else {                
                if(currentTab.orderId && currentTab.action == ACTION_UPDATE_ORDER){                
                    const orderRelatedTab = this.tabs.find((t) => (t.orderId == currentTab.orderId && t.id == currentTab.id));
                    if (orderRelatedTab) {                    
                        if (isPartialOrderProcessing) {                        
                            this.currentTab = orderRelatedTab;
                            if (confirm(this.$t('message.common.confirmEndOrder', {code: currentTab.code}))) {                            
                                const res = await $post('/v1/orders/saveStatus', {
                                    id: currentTab.orderId,
                                    action: 'complete'
                                });
                                $alert(res);
                            }
                        }
                        this.closeTab(orderRelatedTab, false);
                    }       
                }
            }

        },
        onClickScroll(direction) {

            const offset = direction === 'right' ? this.divSaleTab[0].scrollLeft + 120 : this.divSaleTab[0].scrollLeft - 120;
            this.scrollLeftTab(offset)
        },
        scrollLeftTab(offset) {
            this.divSaleTab.animate({scrollLeft: offset}, 100);
        },
        confirmCloseTab(tab, confirm = true){            
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {                
                if(tab.products && tab.products.length > 0){
                    if (confirm) {                        
                        if (!this.$refs.ModalCancel.show(tab)) {
                            return;
                        }
                    }
                }else{                    
                    this.closeTab(tab);
                }
            }
        },
        closeTab(tab) {            
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                this.tabs.splice(index, 1);
                localStorage.setItem('saleTabData_KH' + this.user.id, JSON.stringify(this.tabs));
                this.updateStyle();
                if (this.tabs.length > 0) {
                    this.setCurrentTab();
                } else {
                    this.addTab();
                }
            }

        },
        onSelectProduct(product) {            
            if (!this.currentTab.products) {
                this.currentTab.products = [];
            } else {
                if (this.currentTab.products.length) {
                    if (this.currentTab.products.find(p => (p.id === product.id))) {
                        toastr.warning(this.$t('message.common.existProduct'));
                        return false;
                    }
                }
            }
            product.productUnitId = product.product_units[0].id;
            product.price = product.product_units[0].price;
            this.currentTab.products.unshift(product);
        },
        setBranch(branch) {
            store.commit('SetCurrentBranch', branch);
            this.$nextTick(() => {
                location.reload();
            })
        },
        logout() {
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        processBarcodeScanner(e) {
            const barcode = e.target.value; // ??
            this.$nextTick(async () => {
                // //temporarily hide

                // const res = await $get('/v1/sale/productBarcodeScanner', {product_code: barcode});
                //
                // if (res.code === 200) {
                //     const product = res.data;
                //
                //     if (product) {
                //         this.onSelectProduct(product);
                //     }
                // }
                // $alert(res);
            });
        },
        onShowModalOrderProcessing() {
            this.$refs.modalSelectOrderProcessing.show();
        },
        async onSelectOrderProcessing(orderCode) {
            let loader = useLoading();
            loader.show({
                zIndex: 9999,
                color: '#008080',
            });

            await this.$router.push({query: {order: orderCode}});

            await this.handleTabInput();
            loader.hide();
        },
        onPrint(type, tabData = null) {
            this.printType = type;

            if (tabData) {
                this.currentTab.code = tabData.code;
                this.currentTab.purchaseDate = tabData.purchaseDate;
                this.currentTab.delivery = tabData.delivery;
            }

            this.$nextTick(() => {
                window.print();
            });
        }
    },
    watch: {
        tabs: {
            handler: function (val) { 
                localStorage.setItem('saleTabData_KH' + this.user.id, JSON.stringify(val))
            },
            deep: true
        },
        saleMode: {
            handler: function (val){
                if(this.currentTab){
                    this.currentTab.saleMode = val;
                }
            },  
            deep: true
        },
        'currentTab.saleMode'(newValue){            
            if(newValue != this.saleMode){
                this.saleMode = this.currentTab.saleMode;
            }
        }
    },
}
</script>

<style  lang="scss">
@import '../../assets/css/vars';
    .sale-screen__main {
        padding-top: 5px;
        font-size: 14px;
    }
    .sale-tabs__root {
        position: relative;
        // padding-left: 20px !important;
        padding-left: 60px !important;
        padding-right: 20px;
        .sale-tabs__scroller {
            z-index: 1;
            font-size: 22px;
            cursor: pointer;
            opacity: 0.3;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }

        .sale-tabs__btn {
            position: absolute;
            left: -55px;
            display: flex;
            height: 100%;
            align-items: center;
            z-index: 1;
        }

        .sale-tabs__btn--add {
            background-color: $base-color;
            color: #fff;
            border: 0;
            height: 100%;
            width: 50px;
            outline: 0;
            &:hover,&:focus {
                outline: 0;
                background-color: #168080;
            }
        }

        .sale-tabs__left {
            left: 50px;
        }
        .sale-tabs__right {
            right: 0;
        }
    }
    .sale-tabs {
        list-style: none;
        // padding-left: 50px;
        padding-left: 10px !important;
        height: 45px;
        overflow-x: hidden;
        white-space: nowrap;
        overflow-y: hidden;

        .sale-tabs__tab {
            cursor: pointer;
            color: white;
            display: inline-block;
            margin-left: 1px;
            height: 100%;
            min-width: 120px;
            background-color: #168080;
            .sale-tabs__tab-content {
                padding: 5px 10px;
                justify-content: center;
                flex-direction: column;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .sale-tabs__tab--active {
            background-color: white;
            .sale-tabs__link {
                color: $base-color;
                font-weight: bold;
            }
        }
        .sale-tabs__link {
            text-decoration: none;
            color: #ccc;
            font-size: 14px;
        }

        .sale-tabs__close {
            color: #ccc;
            &:hover {
                color: darkred;
            }
        }
    }

    .sale-form__print {
        display: none;
    }
    @media print {
        .sale-screen {
            display: none;
        }
        .sale-form__print {
            display: block;
        }
    }
</style>
