import moment from 'moment';
export default {
  methods: {
    employeeFields(){
        let fields = [
            {
                name: this.$t('message.employeeIndex.employee_code'),
                field: 'code',                
                selected: true,
                
            },
            {
                name: this.$t('message.employeeIndex.employee_name'),
                field: 'name',                
                selected: true,                
            },
            {
                name: this.$t('message.customerIndex.birthDay'),
                field: 'birthDate',                
                selected: true,
            },
            {
                name: this.$t('message.customerIndex.gender'),
                field: 'gender',                
                selected: true,
            },
            {
                name: this.$t('message.customerIndex.phone'),
                field: 'phone',                
                selected: true,
            },
            {
                name: this.$t('message.customerIndex.email'),
                field: 'email',                
                selected: true,
            },

            {
                name: this.$t('message.employeeIndex.employee_department'),
                field: 'depamentName',                
                selected: true,
            },
            {
                name: this.$t('message.employeeIndex.employee_job_title'),
                field: 'jobTitle',                
                selected: true,
            },

            {
                name: this.$t('message.employeeIndex.employee_branch_work'),
                field: 'workingbranchId',                
                selected: true,
            },
            {
                name: this.$t('message.employeeIndex.employee_user_name'),
                field: 'userName',                
                selected: true,
            },
            {
                name: this.$t('message.common.status'),
                field: 'status',                
                selected: true,
            },
            {
                name: this.$t('message.employeeIndex.employee_created_at'),
                field: 'createdAt',                
                selected: true,
            },            
        ];
        return fields;
    },
    getStatus(id){
        let lstStatus = this.$constant.lstStatusUser;
        let name = "";
        lstStatus.forEach(item => {
            if(item.id == id) name = item.name;
        });
        return name;
    },
    convertDatetime(value){
        return  moment(value).format('DD/MM/YYYY HH:mm');
    },


  }
}
  