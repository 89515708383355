<template>
    <div class="col-12 py-2 text-right">
        <label class="label-text"> {{ $t('message.SendMailZaloIndex.modal.read_before') }}</label>
    </div>
    <View ref="View" :action="this.action" :data="this.content" :editorDataClone="this.editorDataClone"/>
</template>
<script>
import { $get, $post } from "@/lib/utils";
import View from './View';
import emitter from '@/lib/emitter';
import { mapGetters } from "vuex";

export default {
    name: "ViewTemplates",
    props: ['entry','isShow', 'contentOrigin'],
    components: {View},
    created() {
        emitter.on('reloadContentDataPdfTemplate', (id) => {
            this.pdfTemplateId = id;
            this.renderData();
        });
    },
    data() {
        return {
            printTemplate: [],
            editorDataClone: null,
            pdfTemplateId: null,
        }
    },
    mounted() {},
    watch: {
        contentOrigin(newData) {
            if (!this.dataValue) return;
            this.editorDataClone = newData;
            this.mapData();
        },
        content(newData){
                this.renderData();
        }
    },
    methods: {
        mapData(){
            let image = '';
            let str = '';
            let barcode = '';
            if(this.dataValue && this.editorDataClone){
                this.dataValue.forEach(data => {
                    const image = '<img src="' + data.value + '" style=width:200px;>';
                    switch (data.key) {
                        case '{Logo_Cua_Hang}':
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
                            break;
                        case '{Sort_Code}':
                            barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=` + data.value + `&amp;'
                                alt="" title=`+ data.value + ` width="80" height="80" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Van_Don_Ma_Vach}':
                            barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                            alt="" title=`+ data.value + ` width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Vach_Khach_Hang}':
                            if (data?.value && data.value != null) {
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                                    alt="" title=`+ data.value + ` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', '');
                            }
                            break;
                        case '{Ma_Vach_Hoa_Don}':
                            if (data?.value && data.value != null) {
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                                    alt="" title=`+ data.value + ` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', '');
                            }
                            break;
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
                            break;
                    }
                });
            }
        },

        async renderData() {
            this.editorDataClone = this.content;
            await this.mapData();
        },
    },
    computed: {
        ...mapGetters({
            action: 'sendMailTemplateStore/action',
            dataValue: 'sendMailTemplateStore/dataValueKeys',
            content: 'sendMailTemplateStore/sendMailZaloContent',
            pdfTemplateId: 'sendMailTemplateStore/setSendMailZaloTemplateId',
        }),
    },
};
</script>
<style scoped>
.label-text{
    color: #666666;
    padding-right: 5px;
}
</style>
