<template>
    <div class="row">
        <div class="col-lg-12 mt-4 mb-4">
            <table class="table table-index table-striped text-center">
                <thead>
                <tr>
                    <th>Thời gian tạo</th>
                    <th>{{$t('message.TransporterIndex.title')}}</th>
                    <th>{{$t('message.common.created_by')}}</th>
                    <th>{{$t('message.common.status')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in deliveryStatus" :key="item.id">
                    <td v-text="d(item.createdAt)"></td>
                    <td v-text="entry.transporter ? entry.transporter.name : ''"></td>
                    <td v-text="item.updatedBy"></td>
                    <td v-text="item.name"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DeliveryTabHistory",
        data() {
            return {
                entry: {},
                deliveryStatus: []
            }
        }
    }
</script>

<style scoped>

</style>
