<template>
<!-- :class="noSearch ? 'border-gray rounded bg-white' : ''"  -->
    <div class="position-relative">
        <div class="select-option dropdown-toggle border-none" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"             
            :class="[(noSearch ? 'border-gray rounded bg-white' : ''), (styleBorder ? 'border-none' : '')]">
            <template v-if="this.value != null">
                <span v-text="this.value" class="option-value mr-4 text-overflow-ellipsis"></span>
            </template>
            <template v-else>
                <span v-text="this.defaultValue ? this.defaultValue : ''"></span>
            </template>
            <button type="button" class="border-none bg-transparent btn-icon" v-show="this.value != null" @click.stop="clearValue()">
                <!-- <i class="fa fa-times text-danger"></i> -->
            </button>
        </div>
        <div class="dropdown-menu options-dt" aria-labelledby="dropdownMenuButton">
            <input type="text" class="min-w-100" v-model="this.options.search" @input="onInputOption" v-if="!noSearch">
            <template v-if="this.options.filter?.length > 0">
                <a class="dropdown-item pl-0 text-wrap" href="javascript:;" v-for="option in this.options.filter" :key="option.id" :value="option.id" @click="chooseOption(option)">
                    {{option.name}}
                    <i class="fa fa-check" v-if="this.model == option.id"></i>
                </a>
            </template>
            <template v-else>
                <span class="ow-break-word" href="javascript:;">
                    {{$t('message.common.notData')}}
                </span>
            </template>
        </div>
    </div>
</template>

<script>
import {removeVietnameseTones} from "@/lib/utils";
export default {
    name: "InputSelectSearch",
    props:  ['defaultValue', 'modelValue', 'defaultOption', 'noSearch', 'styleBorder'],
    methods: {
        clearValue(){
            this.model = null;
            this.value = null;
            this.$emit('update:modelValue', this.model);
        },  
        chooseOption(o){
            this.model = o.id ?? null;
            this.value = o.name ?? null;
            this.options.search = '';
            this.onInputOption();
            this.$emit('update:modelValue', this.model);
        },
        setValue(){
            if (this.options.filter && this.model != null) {
                this.options.filter.forEach(o => {
                    if(o.id == this.model){
                        this.value = o.name ?? null;
                    }
                });
            }
        },
        onInputOption(){
            if(this.options.search == ''){
                this.options.filter = this.options.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.options.all.length; index++) {
                if (removeVietnameseTones(this.options.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }
            }
            this.options.filter = arr;
        },
    },
    watch: {
        defaultOption: {                
            handler(newDefaultOption){                          
                this.options.filter = this.options.all = newDefaultOption;
            },
            deep: true
        },
        modelValue: {                
            handler(newModelValue){                          
                this.model = newModelValue;
                this.setValue();
            },
            deep: true
        },
    },
    mounted() {
        this.setValue();
    },
    data() {
        return {
            model: this.modelValue ?? null,
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
                search: '',
            },
        }
    }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
.dropdown-toggle::after {
    position: absolute;
    right: 5px;
    top: 12px;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
.dropdown-menu{
    z-index: 100 !important;
}
.dropdown-menu input:focus{
    border: none;
    border-bottom: 1px solid $base-color;
}
.options-dt{
    overflow: auto;
    max-height: 200px;
}
.option-value{
    white-space: pre-wrap !important;
}
.options-dt{
    width: 100% !important;
    transform: translate3d(0px, 35px, 0px) !important;
}
.border-gray{
    border: 1px solid #ccc !important;
}
.border-none {
    border: 0 !important;
}
</style>
