<template>
    <div class="wrap">
        <div class="header">
            <div class="mt-3">
                <img src="/img/logo-text.png" alt="">
            </div>
            <h4>Omipharma</h4>
            <div>
                <span>Địa chỉ: </span>
                <span v-text="currentBranch.addressDecode"></span>
            </div>
            <div>
                <span>Điện thoại: </span>
                <span v-text="currentBranch.phone"></span>
            </div>
            <div>
                <h3 class="mt-2" v-if="entry.type == TYPE_RETURN">HÓA ĐƠN TRẢ HÀNG</h3>
            </div>
            <div>
                <span>Số HĐ: </span>
                <span v-text="entry.code"></span>
            </div>
            <div v-text="'Ngày ' + d(entry.purchaseDate, 'DD') + ' tháng ' + d(entry.purchaseDate, 'MM') + ' năm ' + d(entry.purchaseDate, 'YYYY')"></div>
        </div>
        <div class="content">
            <div class="customer">
                <div>
                    <span>{{$t('message.headers.customers')}}: </span>
                    <span v-text="customer.name"></span>
                </div>
                <div>
                    <span>SĐT: </span>
                    <span v-text="delivery.receiverPhone"></span>
                </div>
                <div>
                    <span>Địa chỉ: </span>
                    <span v-text="delivery.addressDecode || ''"></span>
                </div>
            </div>
            <div class="items">
                <table v-if="entry.items" class="table-items">
                    <thead>
                    <tr>
                        <th>{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                        <th>{{$t('message.common.shortQuantity')}}</th>
                        <th class="text-right">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="item in entry.items" :key="item.id">
                        <td>
                            <div>
                                <span v-text="item.name"></span>
                                <span v-if="item.productUnit" v-text="' (' + item.productUnit.unitName + ')'"></span>
                            </div>
                            <div>
                                <span v-text="n(item.subTotal)"></span>
                                <span class="pl-2" style="text-decoration: line-through"
                                      v-if="item.subTotal != item.price" v-text="n(item.price)"></span>
                            </div>
                        </td>
                        <td v-text="item.quantity"></td>
                        <td class="text-right" v-text="n(item.subTotal * item.quantity) || 0"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="total-payment">
                <div>
                    <span>{{$t('message.DetailOrdersCard.total')}}: </span>
                    <span v-text="n(entry.totalOrigin)"></span>
                </div>
                <div>
                    <span>Giảm giá: </span>
                    <span v-text="n(getDiscountPrice)"></span>
                </div>
                <div>
                    <span>Tổng tiền đã trả:</span>
                    <span v-text="n(entry.total)"></span>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="tks">Cảm ơn và hẹn gặp lại</div>
            <div class="powered">Powered by OMICARE</div>
        </div>
    </div>
</template>

<script>
    import store from "../../store";
    import {DISCOUNT_TYPE_PERCENT, parseIntEx, TYPE_RETURN} from "../../lib/utils";

    export default {
        name: "ModalPrintInvoiceOrder",
        props: ['data'],
        data() {
            return {
                entry: this.data,
                customer: this.data.customer || {},
                delivery: this.data.delivery || {},
              TYPE_RETURN
            }
        },
        computed: {
            currentBranch: () => store.state.CurrentBranch,
            getDiscountPrice() {
                const entry = this.entry;
                let discountPrice = 0;
                if (entry.discountType == DISCOUNT_TYPE_PERCENT) {
                    discountPrice = Math.ceil(entry.totalOrigin * entry.discountValue / 100);
                } else {
                    discountPrice = entry.discountValue;
                }

                return discountPrice + parseIntEx(entry.discount);
            }
        }
    }
</script>

<style scoped lang="scss">
    .wrap {
        font-size: 16px;
        font-weight: 600;
    }

    .header {
        text-align: center;
    }

    .footer {
        margin-top: 20px;
        text-align: center;

        .tks {
            font-size: 18px;
            font-weight: 600;
        }

        .powered {
            font-style: italic;
        }
    }

    .table-items {
        margin: 20px 0;
        width: 100%;

        thead tr {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }

        tbody {
            tr {
                border-bottom: 1px dashed #000;
            }
        }
    }

    .total-payment {
        text-align: right;
        font-weight: bold;

        div {
            margin: 5px 0;

            span:last-child {
                min-width: 150px;
                display: inline-block;
            }
        }
    }
</style>
