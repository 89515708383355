<template>
    <ul class="q-tree-root"
        :class="{collapsed: collapsed, 'q-tree-sub': (level ||0) > 0}"
    >
        <li v-for="e in items" :key="e.id">
            <a class="btn-expand"
               :class="{'q-tree-highlight': e.highlight}"
               @click.prevent="expand(e)" v-if="e.children && e.children.length">
                <i class="fa" :class="e.expanded ? 'fa-minus-square' : 'fa-plus-square'"></i>
            </a>

            <label v-if="showAction">
                <a href="javascript:;" @click="onUpdate(e)"><i class="fa fa-edit"></i></a>
                <a href="javascript:;" @click="onCreate(e)" v-if="!level || level < 2"><i class="fa fa-plus-circle"></i></a>
                <a href="javascript:;" @click="onDelete(e)"><i class="fa fa-trash"></i></a>
            </label>
            <label v-else >
                <input v-model="e.checked"
                       @input="doCheck(e, $event)"
                       type="checkbox" style="transform: none">
            </label>
            <span class="q-tree-label" @click="doCheck(e)">
                 {{e.name}}
            </span>


            <template v-if="e.children && e.children.length && e.expanded">
                <q-tree-list @ontreechange="onTreeChange"
                             :level="(level || 0) + 1"
                             :items="e.children"
                             :showAction="showAction"
                             :on-create="onCreate"
                             :on-update="onUpdate"
                             :on-delete="onDelete"
                             :collapsed="!e.expanded"/>
            </template>
        </li>
    </ul>
</template>

<script>

import {clone, treeFindPath, treeIterate} from "../../lib/utils";
    window.qtree = null;
    export default {
        name: "QTreeList",
        props: ['items', 'collapsed', 'level', 'showAction', 'onCreate', 'onUpdate', 'onDelete'],
        mounted() {
            window.qtree = this;
        },
        methods: {
            expand(e) {
                e.expanded = !e.expanded;
            },
            onTreeChange(node) {
                this.$emit('ontreechange', node);
            },

            doCheck(node, $event) {
                if ($event) {
                    $event.stopPropagation();
                }

                node.checked = !node.checked;
                treeIterate(node, (entry) => {
                    entry.checked = node.checked;
                    entry.highlight = node.checked;
                });

                this.$emit('ontreechange', node);
            }
        },
        watch: {
            items: function (newValue) {
                if (newValue) {
                    return newValue;
                }
            }
        }
    }
</script>

<style >
    .q-tree-root {
        list-style: none;
        padding-left: 0;
        position: relative;
    }
    .q-tree-label {
        cursor: pointer;
        padding-left: 5px;
    }
    .q-tree-sub li {
        margin:0;
        padding:0 1em;
        line-height:2em;
        position:relative
    }

    .q-tree-sub li:last-child:before {
        background:#fff;
        height:auto;
        top:1em;
        bottom:0
    }

    .q-tree-sub li:before {
        content:"";
        display:block;
        width:10px;
        height:0;
        border-top:1px solid;
        margin-top:-1px;
        position:absolute;
        top:1em;
        left:0
    }
    .q-tree-sub:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid;
    }
    .q-tree-sub {
        margin-left: 25px;
    }
    .collapsed {
        display: none;
    }

    .btn-expand {
        cursor: pointer;
        padding-right: 5px;
    }

    .q-tree-highlight .fa{
        color: #008080;
    }


</style>
