<template>
    <AppPermission :permission="this.$permissions.orderSuppliers.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
            <div class="mainLeft pt-3">
                <div class="main-title">
                <span>{{ $t('message.OrderSupplierIndex.title') }}</span>
                </div>
                <div>
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row">
                    <div class="col-8 text-title">
                        <label>{{ $t('message.common.numberRecord') }}:</label>
                    </div>
                    <div class="col-4">
                        <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                    </div>
                </div>
                </div>
                <!-- Chi nhánh -->
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.branch') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <!-- <SelectSearch :placeholder="$t('message.OrderSupplierIndex.filter.placeholder.branch')" :modelValue="filter.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"></SelectSearch> -->
                                <q-select v-model="filter.branches" 
                                    :modelValue="filter.branches" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.PlanIndex.chooseBranch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thời gian -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.time') }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="time">
                        <div class="row mb-2">
                            <div class="col-1 p-0 d-flex align-items-center">
                                <label class="container-radio ml-2 min-vw-100">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios1" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-11">
                                <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-1 p-0 d-flex align-items-center">
                                <label class="container-radio ml-2 min-vw-100">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios2" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-11">
                                <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
                                @input="filterTime(filter.createdPicker, 1)" v-model="filter.createdPicker"/>
                                <!-- <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true"></i> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Trạng thái -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.status') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                    <i class="fa fa-chevron-up" id="statusUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="statusBody">
                        <div class="row" v-for="status in this.statusOptions" :key="status.id">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{$t(status.name)}}
                                    <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :id="`key${status.id}`" :checked="isExistInArrayChecked(status, this.filter.status)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.status_payment') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusPaymentBody', 'statusPaymentDown', 'statusPaymentUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="statusPaymentDown"></i>
                                    <i class="fa fa-chevron-up" id="statusPaymentUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-2" id="statusPaymentBody">
                        <div class="row" v-for="status in this.listStatusPayment" :key="status.id">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{$t(status.name)}}
                                    <input type="checkbox" class="chkType" @click="doFilter('statusPayment', status.id)" :checked="isExistInArrayChecked(status, this.filter.statusPayment)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Người tạo -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.created_by') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                    <i class="fa fa-chevron-up" id="creatorUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="creatorBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.OrderSupplierIndex.filter.placeholder.created_by')" :modelValue="filter.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Người nhân đặt -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.OrderSupplierIndex.filter.order_by') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('orderBody', 'orderDown', 'orderUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="orderDown"></i>
                                    <i class="fa fa-chevron-up" id="orderUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="orderBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.OrderSupplierIndex.filter.placeholder.order_by')" :modelValue="filter.orderBy" :defaultOption="this.orderBy.all" @update:modelValue="doFilterSearch" :label="'orderBy'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Chi phí nhập trả NCC -->
                <!-- <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">Chi phí nhập trả NCC</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('payingSupplierBody', 'payingSupplierDown', 'payingSupplierUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="payingSupplierDown"></i>
                                    <i class="fa fa-chevron-up" id="payingSupplierUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="payingSupplierBody">
                        <div class="row">
                            <div class="col-12">
                                <div class="prd-search-box1" id="dropdownMenuButton4" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <input type="text" placeholder="Chọn loại chi phí nhập trả NCC">
                                    <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton4">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>

            <div class="mainRight">
            <!-- <div class="col-md-10" id="col10"> -->
                <div class="sticky-top pt-3">
                    <div class="row">
                        <div class="col-lg-5">
                            <InputQuickSearch :placeholder="$t('message.OrderSupplierIndex.filter.placeholder.search')" @filterSearch="changeQuickSearch" :orderSupplierSearch="true"></InputQuickSearch>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{ $t('message.button.removeFilter') }}
                            </button>
                           
                            <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                    style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v px-2"></i>
                                    {{$t('message.OrderSupplierIndex.button.action')}}
                                </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <AppPermission :permission="this.$permissions.orderSuppliers.export?? '' ">                                        
                                            <a class="dropdown-item" @click="exSelectOverview()">
                                                <i class="fa fa-share"></i>
                                                {{$t('message.OrderSupplierIndex.button.export_all')}}
                                            </a>
                                            <a class="dropdown-item" @click="exSelectDetails()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.OrderSupplierIndex.button.export_detail')}}
                                            </a>
                                        </AppPermission>
                                        <a class="dropdown-item" @click="printMultiple(this.arrayChecked)">
                                            <i class="fas fa-print"></i>
                                            {{$t('message.OrderSupplierIndex.button.print')}}
                                        </a>
                                        <a class="dropdown-item" @click="receiveInvoiceSupplier(this.arrayChecked)">
                                            <i class="fas fa-file"></i>
                                            {{$t('message.OrderSupplierIndex.button.import_invoice_supplier')}}
                                        </a>
                                        <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.button.sendMailText') }}
                                        </a>

                                        <a class="dropdown-item" @click="sendZalo()" v-if="this.statusEmail">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                        </a>
                                </div>
                            </div>
                            
                            <AppPermission :permission="this.$permissions.orderSuppliers.create?? '' ">
                                <button @click="showModalOrderSupplierCreateUpdate()" class="btn btn-save ml-1">
                                    <i class="fa fa-plus"></i>
                                    {{$t('message.OrderSupplierIndex.button.add')}}
                                </button>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.orderSuppliers.export?? '' ">
                                <div class="dropdown">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-file-export px-2"></i>
                                        {{$t('message.OrderSupplierIndex.button.export')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click="exOverview()">
                                            {{$t('message.OrderSupplierIndex.button.file_all')}}
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="exDetails()">
                                            {{$t('message.OrderSupplierIndex.button.file_detail')}}
                                        </a>
                                    </div>
                                </div>
                            </AppPermission>
                            <div id="loc" v-on:mouseleave="onMouseLeave">
                                <SelectField :fields="fields" label="orderSupplier"></SelectField>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                            <a href="javascript:;" @click="removeChecked()">
                                <i class="fa fa-times text-danger" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row mx-0 my-2" v-if="entries.length > 0" id="divTable">
                        <table class="table table-index table-striped w-100" >
                            <thead>
                                <tr>
                                    <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                    <template v-for="field in fields" :key="field.field">
                                    
                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                    </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                    <tr class="master-row" :class="entry.id === this.orderSupplierDetail ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                        <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td>
                                        <template v-for="field in fields" :key="field.field">
                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailOrderSupplier(entry.id, entry)">
                                                <div v-if="field.field==='supplierId'" :style="field.style" class="description"><span v-text="entry.supplier?.name"></span></div>                                            
                                                <div v-else-if="field.field==='status'" :style="field.style" class="description"><span v-text="entry.statusName"></span></div>
                                                <div v-else-if="field.field==='note'" :style="field.style" class="description" :title="entry.note"><span v-text="entry.note"></span></div>
                                                <div v-else-if="field.field==='branchId'" :style="field.style" class="description"><span v-text="entry.branch?.name"></span></div>
                                                <div v-else-if="field.field==='userId'" :style="field.style" class="description"><span v-text="entry.order_by?.name"></span></div>
                                                <div v-else-if="field.field==='createdBy'" :style="field.style" class="description"><span v-text="entry.created_by?.name"></span></div>
                                                <div v-else-if="field.field==='created_at'" :style="field.style" class="description"><span v-text="entry.created_at"></span></div>                                            
                                                <div v-else-if="field.field==='updated_at'" :style="field.style" class="description"><span v-text="entry.updated_at"></span></div>
                                                <div v-else-if="field.field==='ExpectedDateOfImport'" :style="field.style" class="description"><span v-text="entry.ExpectedDateOfImport"></span></div>
                                                <div v-else-if="field.field==='orderDate'" :style="field.style" class="description"><span v-text="entry.orderDate"></span></div>
                                                <div v-else-if="field.field==='totalPrice'" :style="field.style"><span v-text="n(entry.totalPrice)"></span></div>
                                                <div v-else-if="field.field==='totalQuantity'" :style="field.style"><span v-text="n(entry.totalQuantity)"></span></div>
                                                <div v-else-if="field.field==='totalProductType'" :style="field.style"><span v-text="n(entry.totalProductType)"></span></div>
                                                <div v-else-if="field.field==='priceDiscount'" :style="field.style"><span v-text="n(entry.priceDiscount)"></span></div>
                                                <div v-else-if="field.field==='importCode'" :style="field.style" class="description">
                                                    <template :key="n" v-for="(n, index) in entry.import_supplier">     
                                                        <template v-if="index != (entry.import_supplier.length - 1)">
                                                            <span :class="(n.status && n.status == this.$constant.statusPurchaseCancelled) ? 'text-danger' : ''">{{ n.code ? (n.code + ', ' ) : '' }}</span>
                                                        </template>
                                                        <template v-if="index === (entry.import_supplier.length - 1)">
                                                            <span :class="(n.status && n.status == this.$constant.statusPurchaseCancelled) ? 'text-danger' : ''">{{ n.code ? n.code : '' }}</span>
                                                        </template>
                                                    </template>
                                                </div>
                                                <!-- <div v-else-if="field.field==='supplierPayment'" :style="field.style"><span v-text="n(entry.supplierPayment)"></span></div> -->
                                                <!-- <div v-else-if="field.field==='paymentForSupplier'" :style="field.style"><span v-text="n(entry.paymentForSupplier)"></span></div> -->
                                                <!-- <div v-else-if="field.field==='otherCosts'" :style="field.style"><span v-text="n(entry.otherCosts)"></span></div> -->
                                                <div v-else-if="field.field==='waitDay'" :style="field.style">
                                                    <span v-if="entry.waitDay == null"></span>
                                                    <span v-text="n(entry.waitDay)" v-else-if="entry.waitDay >= 0"></span>
                                                    <span v-text="n(entry.waitDay)" v-else-if="entry.waitDay < 0" class="text-danger"></span>
                                                </div>
                                                <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class"></div>
                                                </a>
                                            </td>
                                        </template>
                                    </tr>
                                    <tr v-if="orderSupplierDetail === entry.id" :class="[ entry.id === orderSupplierDetail ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                        <td></td>
                                        <td :colspan="fields.length" class="pb-0">
                                            <div class="row detail-prd-row">
                                                <div class="col-2 mt-2"  @click="showContent(this.$constant.tabInformationValue)" >
                                                    <span :class="this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                        {{$t('message.OrderSupplierIndex.tabs.info')}}
                                                    </span>
                                                </div>                                                
                                                 <div class="col-2 mt-2"  @click="showContent(this.$constant.tabSupplierValue)" v-if="entry.import_supplier.length > 0">
                                                    <span :class="this.tabActive == this.$constant.tabHistorySupplier ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                        {{$t('message.OrderSupplierIndex.tabs.history_purchase')}}
                                                    </span>
                                                </div>                                                
                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabPaymentValue)" v-if="(entry.cash_book && entry.cash_book.length > 0)  || (entry.bank_book && entry.bank_book.length > 0)" >
                                                    <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                        {{$t('message.OrderSupplierIndex.tabs.history_payment')}}
                                                    </span>
                                                </div>
                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabDeliveryValue)" v-if="(entry.cash_book && entry.cash_book.length > 0)  || (entry.bank_book && entry.bank_book.length > 0)" >
                                                    <span :class=" this.tabActive == this.$constant.tabHistoryDelivery ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                        {{$t('message.OrderSupplierIndex.tabs.history_delivery')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <!-- Hiển thị tab thông tin -->
                                    <template v-if="this.tabActive == this.$constant.tabInformation">
                                        <!-- Chi tiết phiếu đặt hàng -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="orderSupplierDetail === entry.id">
                                            <td></td>
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4 detail__user">
                                                    <div class="row mt-2 detail-prd-row">
                                                        <div class="col-4">
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{$t('message.OrderSupplierIndex.code')}}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">                                                            
                                                                    {{ entry.code }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 py-1">
                                                                <div class="col-5 lableOS">
                                                                    {{$t('message.OrderSupplierIndex.time')}}:
                                                                </div>
                                                                <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                    <InputDateTimePicker :modelValue="entry.dateTimePicker" @update:modelValue="changeDateTimePicker" v-if="entry.status !== this.$constant.statusOrderCancelled"/>
                                                                    <span v-else>
                                                                        {{dt(entry.dateTimePicker)}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{$t('message.OrderSupplierIndex.supplier')}}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.supplier?.name }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{$t('message.OrderSupplierIndex.created_by')}}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.created_by?.name }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{$t('message.OrderSupplierIndex.purchase_code')}}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    <template :key="n" v-for="(n, index) in entry.import_supplier">     
                                                                        <template v-if="index != (entry.import_supplier.length - 1)">
                                                                            <span :class="(n.status && n.status == this.$constant.statusPurchaseCancelled) ? 'text-danger' : ''">{{ n.code ? (n.code + ', ' ) : '' }}</span>
                                                                        </template>
                                                                        <template v-if="index === (entry.import_supplier.length - 1)">
                                                                            <span :class="(n.status && n.status == this.$constant.statusPurchaseCancelled) ? 'text-danger' : ''">{{ n.code ? n.code : '' }}</span>
                                                                        </template>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-4">
                                                            <div class="mx-1">
                                                                <div class="col-12">
                                                                    <!-- Chi tiết - Trạng thái phiếu -->
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5 lableOS">
                                                                            {{$t('message.OrderSupplierIndex.status')}}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word">     
                                                                                                   
                                                                                <!-- Đối với phiếu Hoàn thành-->
                                                                                <span v-if="entry.status == this.$constant.statusOrderSupplier.STATUS_FINISHED" > 
                                                                                    {{$t('message.OrderSupplierIndex.status_complete')}}
                                                                                    <input type="hidden" :id="'status_' + entry.id" v-model="entry.status">
                                                                                </span>
                                                                                <!-- Đối với phiếu đã hủy-->                                                                                
                                                                                <span v-else-if="entry.status == this.$constant.statusOrderSupplier.STATUS_CANCELLED">
                                                                                    {{$t('message.OrderSupplierIndex.status_cancel')}}
                                                                                    <input type="hidden" :id="'status_' + entry.id" v-model="entry.status">
                                                                                </span>
                                                                                <!-- Đối với phiếu Đã nhập một phần-->   
                                                                                <span v-else-if="entry.status == this.$constant.statusOrderSupplier.STATUS_IMPORTED">
                                                                                    {{$t('message.OrderSupplierIndex.status_purchase_a_part')}}
                                                                                    <input type="hidden" :id="'status_' + entry.id" v-model="entry.status">
                                                                                </span>
                                                                                <!-- Trường hợp còn lại: Phiếu tạm và Đã xác nhận NCC --> 
                                                                                <!-- Set trường hợp Không được phân quyền -->
                                                                                <template v-else>                                                                                    
                                                                                    <template v-if="this.hasPermission(this.$permissions.orderSuppliers.update) || this.userId == this.$constant.isAdmin">                                                                                    
                                                                                        <select class="form-control inputDatetime" :id="'status_' + entry.id">
                                                                                            <option v-for="n in this.statusOptionsTicket" :key="n.id" :value="n.id" v-text="n.name" :selected=" entry.status == n.id ? true : false"></option>
                                                                                        </select>
                                                                                    </template>
                                                                                    <template v-else>                                                                                        
                                                                                        <span>                                                                                                                     
                                                                                            {{$t('message.OrderSupplierIndex.status_draft')}}
                                                                                            <input type="hidden" :id="'status_' + entry.id" v-model="entry.status">
                                                                                        </span>
                                                                                    </template>                                                                                    
                                                                                </template>                                                                               
                                                                        </div>
                                                                    </div>
                                                                    <!-- Chi tiết - Chi nhánh -->
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{$t('message.OrderSupplierIndex.branch')}}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word">
                                                                            {{ entry.branch?.name }}
                                                                        </div>
                                                                    </div>
                                                                    <!-- Chi tiết - Người đặt -->
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{$t('message.OrderSupplierIndex.order_by')}}:
                                                                        </div>                                                                
                                                                        <div class="col-7 ow-break-word">                                                                    
                                                                            <InputSelectSearch :defaultValue="'Chọn người đặt'" :modelValue="entry.orderById" :defaultOption="this.lstUsers" @update:modelValue="resetValue" 
                                                                            v-if="entry.status !== this.$constant.statusOrderCancelled"
                                                                            />                                                                    
                                                                            <template v-else>
                                                                                <span v-for="n in this.lstUsers" :key="n.id">
                                                                                    {{ n.id == entry.orderById ? n.name : '' }}
                                                                                </span> 
                                                                            </template>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <!-- Chi tiết - Dự kiến nhập hàng -->
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{$t('message.OrderSupplierIndex.expected_date')}}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word">
                                                                                <input  v-if="entry.status !== this.$constant.statusOrderCancelled" type="date" name="" :id="'expectedAt_' + entry.id" :value="entry.ExpectedDate" class="form-control inputDatetime" @blur="exAtChange(entry.id)">
                                                                                <span v-else>
                                                                                    {{ d(entry.ExpectedDate) }}
                                                                                </span>
                                                                                <!-- <input type="hidden" name="" :id="'exAt_' + entry.id" :value="this.expectedAt" class="form-control inputDatetime"> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-4" :id="entry.status == this.$constant.statusOrderCancelled ? 'noteOS' : ''">                                                   
                                                            <textarea v-model="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.OrderSupplierIndex.note')" class="noteStyle px-4 text-gray font-italic"
                                                            v-if="entry.status !== this.$constant.statusOrderCancelled"></textarea>
                                                            <span class="px-4 text-gray font-italic" v-else style="color:#6c757d">
                                                                    {{ entry.note ? entry.note : $t('message.OrderSupplierIndex.note')}}
                                                            </span>
                                                            <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Chi tiet san pham va gia -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="orderSupplierDetail === entry.id">
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4 detail__user">
                                                    <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                        <div class="col-12 pl-0">
                                                            <table class="w-100">
                                                                <thead>
                                                                    <tr class="backgroup__detail">
                                                                        <th scope="col" class="pl-4" v-for="fieldDetail in this.fieldOrderSupplierDetail" :key="fieldDetail" v-text="$t(fieldDetail.name)"></th>
                                                                    </tr>
                                                                    
                                                                </thead>
                                                                <tbody>
                                                                    <tr class="bg-white">
                                                                        <th scope="col" class="pl-4"> 
                                                                            <input type="text" v-model="this.defaultOrderSupplier.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                        </th>
                                                                        <th scope="col" :colspan="fieldOrderSupplierDetail.length - 1" class="pl-4"> 
                                                                            <input type="text" v-model="this.defaultOrderSupplier.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                        </th>
                                                                    </tr>
                                                                    <template v-if="this.defaultOrderSupplier.filter?.length > 0">
                                                                        <template v-for="(orderSupplierDetail, index) in this.defaultOrderSupplier.filter" :key="orderSupplierDetail.id">
                                                                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                        <td v-text="orderSupplierDetail.product?.code" class="pl-4"></td>
                                                                                        <td class="pl-4">
                                                                                            <div class="row p-0 m-0">
                                                                                                <div class="col-12" v-text="orderSupplierDetail.product?.name"></div>
                                                                                                <div class="col-12 description font-italic text-gray" v-text="orderSupplierDetail.note" :title="orderSupplierDetail.note"></div>
                                                                                            </div>
                                                                                        </td>                                                                                        
                                                                                        <td class="pl-4 text-left" v-text="orderSupplierDetail.unit ? orderSupplierDetail.unit.unitName : ''"></td>
                                                                                        <td class="pl-4 text-left" v-text="orderSupplierDetail?.attributeName ? orderSupplierDetail.attributeName : ''"></td>
                                                                                        <td class="pl-4">{{n(orderSupplierDetail.quantity)}}/<span class="text-danger" v-text="n(this.getTotalPurchase(entry, orderSupplierDetail))"></span></td>
                                                                                        <td v-text="n(orderSupplierDetail.price)" class="pl-4"></td>
                                                                                        <td v-text="n(orderSupplierDetail.discount / orderSupplierDetail.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(orderSupplierDetail.price - (orderSupplierDetail.discount / orderSupplierDetail.quantity))" class="pl-4"></td>
                                                                                        <td v-text="n(orderSupplierDetail.subTotal)" class="pl-4"></td>
                                                                                </tr>
                                                                                <template v-if="orderSupplierDetail.plus && orderSupplierDetail.plus?.length">
                                                                                    <tr v-for="(plusItem, indexPlus) in orderSupplierDetail.plus" :key="indexPlus">
                                                                                        <td class="pl-4" v-text="plusItem.product?.code"></td>
                                                                                        <td class="pl-4">
                                                                                            <div class="row p-0 m-0">
                                                                                                <div class="col-12" v-text="plusItem.product?.name"></div>
                                                                                            </div>
                                                                                        </td>                                                                                        
                                                                                        <td class="pl-4 text-left" v-text="plusItem.unit ? plusItem.unit.unitName : ''"></td>                                                                                        
                                                                                        <td class="pl-4 text-left" v-text="plusItem?.attributeName ? plusItem.attributeName : ''"></td>
                                                                                        <td class="pl-4">{{ n(plusItem.quantity) }}/<span class="text-danger" v-text="n(this.getTotalPurchase(entry, plusItem))"></span></td>
                                                                                        <td v-text="n(plusItem.price)" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.discount / plusItem.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.price - (plusItem.discount / plusItem.quantity))" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.subTotal)" class="pl-4"></td>
                                                                                    </tr>
                                                                                </template>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                    <template v-else>
                                                                        <tr class="text-center bg-light">
                                                                            <td :colspan="fieldOrderSupplierDetail.length">
                                                                                {{$t('message.common.notFound')}}
                                                                            </td>
                                                                        </tr>
                                                                    </template>
                                                                </tbody>
                                                            </table>
                                                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                        </div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-4 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_quantity') }}:</div>
                                                        <div class="col-2 flex-end">{{n(entry.totalQuantity ?? 0)}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_products') }}:</div>
                                                        <div class="col-2 flex-end">{{n(entry.totalProductType ?? 0)}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_origin') }}:</div>
                                                        <div class="col-2 flex-end">{{n(entry.totalPrice ?? 0)}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.discount') }}:</div>
                                                        <div class="col-2 flex-end">{{n(entry.priceDiscount ?? 0)}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.payment_supplier') }}:</div>
                                                        <div class="col-2 flex-end">{{n((entry.totalPrice ?? 0) - (entry.priceDiscount ?? 0))}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                        <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_payment') }}:</div>
                                                        <div class="col-2 flex-end">{{n(entry.paymentForSupplier ?? 0)}}</div>
                                                    </div>
                                                    <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                        <button type="button" class="btn btn-save mx-1" v-if="this.statusEmail" @click="sendZalo(entry)">
                                                                <i class="fas fa-sms"></i>
                                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                        </button>
                                                        <template v-if="entry.status !== this.$constant.status_cancel_order_supplier">
                                                            <AppPermission :permission="this.$permissions.orderSuppliers.update?? '' ">
                                                                <button v-if="entry.status !== this.$constant.status_success" type="button" class="btn btn-save mx-1" @click="ShowModalOrderSupplierEnd(entry.id, entry.code)">
                                                                    <i class="fa fa-check"></i>
                                                                    {{ $t('message.OrderSupplierIndex.button.close') }}
                                                                </button>
                                                            </AppPermission>
                                                            <!--Level 1: Set quyền thêm mới nhập hàng nhà cung cấp -->
                                                            <AppPermission :permission="this.$permissions.purchaseOrders.create?? '' ">
                                                                <AppPermission :permission="this.$permissions.orderSuppliers.createImportSupplier?? '' ">
                                                                    <button v-if="entry.status !== this.$constant.status_success" type="button" class="btn btn-save mx-1" @click="goCreateImport(entry.id, entry.import_count)">
                                                                        <i class="fa fa-plus"></i>
                                                                        {{ $t('message.OrderSupplierIndex.button.create_purchase') }}
                                                                    </button>
                                                                </AppPermission>
                                                            </AppPermission>
                                                            <!-- Nút lưu nhanh -->
                                                            <AppPermission :permission="this.$permissions.orderSuppliers.update?? '' ">
                                                                <button type="button" class="btn btn-save mx-1" @click="updateChange(entry.id, entry.code)">
                                                                    <i class="fa fa-save"></i>
                                                                    {{ $t('message.OrderSupplierIndex.button.save') }}
                                                                </button>
                                                            </AppPermission>
                                                            <!-- Nút mở phiếu -->
                                                            <!-- <AppPermission :permission="this.$permissions.orderSuppliers.update?? '' "> -->
                                                            <AppPermission :permission="this.$constant.orderSupplierPermissionStatus">
                                                                <button v-if="entry.status == this.$constant.status_confirm_supplier || entry.status == this.$constant.status_draft" 
                                                                    type="button" class="btn btn-save mx-1" @click="editOrderSupplier(entry.id)">
                                                                    <i class="fa fa-share"></i>
                                                                    {{ $t('message.OrderSupplierIndex.button.show') }}
                                                                </button>
                                                            </AppPermission>
                                                            <AppPermission :permission="this.$permissions.orderSuppliers.cancel?? '' ">
                                                            <button type="button" class="btn btn-danger mx-1" @click="ShowModalOrderSupplierAbort(entry)" v-if="entry.status == this.$constant.status_confirm_supplier || entry.status == this.$constant.status_draft">
                                                                <i class="fa fa-trash-alt"></i>
                                                                {{ $t('message.OrderSupplierIndex.button.cancel') }}
                                                            </button>
                                                            </AppPermission>
                                                        </template> 
                                                        <!-- Xuất file và sao chép -->
                                                        <div class="dropdown">
                                                            <button class="btn btn-default " type="button" id="dropdownMenuButton"
                                                                style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                <i class="fa fa-ellipsis-v px-2" aria-hidden="true"></i>
                                                            </button>
                                                            <div class="dropdown-menu drp-menu" aria-labelledby="dropdownMenuButton">
                                                                <!-- Xuất file 1 bản ghi -->
                                                                <AppPermission :permission="this.$permissions.orderSuppliers.export?? '' ">
                                                                    <a class="dropdown-item" href="#" @click="exportOrderSupplierById(entry.id)" >
                                                                        <i class="fa fa-file-download px-2" aria-hidden="true"></i>
                                                                        {{$t('message.button.export.name')}}
                                                                    </a>
                                                                </AppPermission>
                                                                <!-- Sao chep -->
                                                                <AppPermission :permission="this.$permissions.orderSuppliers.create?? '' ">
                                                                    <a class="dropdown-item" href="#" @click="copyOrderSupplierById(entry.id)">
                                                                        <i class="fa fa-copy px-2" aria-hidden="true"></i>
                                                                        {{$t('message.button.copy.name')}}
                                                                    </a>
                                                                </AppPermission>
                                                                    <a class="dropdown-item" href="#" @click="print(entry)">
                                                                        <i class="fa fa-print px-2" aria-hidden="true"></i>
                                                                        {{$t('message.button.print.name')}}
                                                                    </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                    <!-- Hiển thị tab lịch sử thanh toán -->
                                    <template v-if="this.tabActive == this.$constant.tabHistoryPayment">
                                        <tr class="detail-row bg-white border__body-detail" v-if="orderSupplierDetail === entry.id">
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                    <div class="col-12">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.code') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.time') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.created_by') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.method') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.status') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table text-center">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_payment.total_price') }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody> 
                                                                <template v-if="entry.historyPayment">                                                                                                                                       
                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                        <td scope="col" class="checkbox-table">
                                                                            <a href="#" class="text-decoration-none text-primary" @click.stop="showModalDataPayment(history.id, history.type, this.$constant.typeOrderForSupplier)">
                                                                                <span>{{history?.code ? history.code : ''}}</span>
                                                                            </a>
                                                                        </td>
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdAt ? history.createdAt : ''}}</span></td>
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                        <td scope="col" class="checkbox-table text-center"><span>{{history?.payment ? history.payment : ''}}</span></td>
                                                                    </tr>
                                                                </template>
                                                                <template v-else>
                                                                    <tr >
                                                                        <td colspan="6" class="text-center">
                                                                            {{$t('message.common.notFound')}}
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        </tr>                                            
                                    </template>
                                    <!-- Hiển thị tab lịch sử nhập hàng -->
                                    <template v-if="this.tabActive == this.$constant.tabHistorySupplier">
                                        <tr class="detail-row bg-white border__body-detail" v-if="orderSupplierDetail === entry.id">
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                    <div class="col-12">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_purchase.code') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_purchase.time') }}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_purchase.create_by') }}
                                                                    </th>                                                                    
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{ $t('message.OrderSupplierIndex.tabs.table.history_purchase.status') }}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody> 
                                                                <template v-if="entry.import_supplier">                                                                                                                                       
                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.import_supplier" :key="index">                                                                 
                                                                        <td scope="col" class="checkbox-table text-primary" @click="showDetailStockCard(history.id, this.$constant.stockCardImportForSupplier)" v-text="history && history.code ? history.code : ''"></td>                                                                    
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.created_at ? dt(history.created_at) : ''}}</span></td>
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.created_by ? history.created_by.name : ''}}</span></td>                                                                        
                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                        
                                                                    </tr>
                                                                </template>
                                                                <template v-else>
                                                                    <tr >
                                                                        <td colspan="6" class="text-center">
                                                                            {{$t('message.common.notFound')}}
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                                
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                </div>
                                            </td>
                                        </tr>                                            
                                    </template>
                                    <!-- Hiển thị tab lịch sử giao hàng -->
                                    
                                    <tr class="detail-row bg-white border__footer-detail" v-if="orderSupplierDetail === entry.id"></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="row ml-auto mr-auto" v-if="entries.length == 0 && !this.loading" id="notFound">
                        <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                            <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                            <!-- <p>{{$t('message.common.notFound')}}</p> -->
                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="load()" :typeEditAdd="typeEditAdd"/>
        <ModalRemoveSaleChannels ref="ModalRemoveSaleChannels" @inputData="load()"/>
        <ModalOrderSupplierEnd ref="ModalOrderSupplierEnd" @inputData="load()"/>
        <ModalOrderSupplierAbort ref="ModalOrderSupplierAbort"  @inputData="load()"/>
        <PrintTemplate ref="PrintTemplate" v-show="false" />    
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalDataPayment ref="ModalDataPayment"  @updated="load()" />
        <DetailStockCard ref="DetailStockCard" />
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalReceiveInvoiceSupplier ref="ModalReceiveInvoiceSupplier" @updated="load()"/>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </AppPermission>

</template>
<script>

import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones, numberFormat, forEach} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import DateRangePicker from "@/components/DateRangePicker";
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
import store from "@/store";
import SelectField from "../../components/SelectField";
import emitter from "@/lib/emitter";
import InputDatePicker from "@/components/forms/InputDatePicker";
import InputDateTimePicker from "@/components/forms/InputDateTimePicker";
import http from '@/services/api';
import ModalOrderSupplierEnd from './tabs/ModalOrderSupplierEnd';
import ModalOrderSupplierAbort from './tabs/ModalOrderSupplierAbort';
import InputDate from "@/components/forms/InputDate.vue";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import moment from 'moment';
import router from "@/router/index";
import toastr from 'toastr';
import InputTextArea from "@/components/forms/InputTextArea";
import SelectSearch from "@/components/SelectSearch.vue";
import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
import PrintTemplate from '@/components/PrintTemplate';
import orderForSupplierMixin from "@/orderForSupplierMixin";
import AppPermission from '@/components/AppPermission.vue';
import ModalDataPayment from '@/components/ModalDataPayment.vue';
import DetailStockCard from '../products/DetailStockCard';
import exportMixin from "@/exportMixin";
import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
import ModalReceiveInvoiceSupplier from '../purchase_orders/ModalReceiveInvoiceSupplier.vue';
import permissionMixin from "@/permissionMixin";
import QSelect from '@/components/QSelect';

import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
import { mapGetters } from "vuex";
    export default {
        name: "OrderSupplierIndex",
        mixins: [orderForSupplierMixin,exportMixin, permissionMixin],        
        components: 
        {
            ModalSelectSendZaloGroupTemplate,
            TableSortField, SelectSearch, Pagination, InputDateRangePicker, SelectField, InputDatePicker, 
            InputDateTimePicker, ModalOrderSupplierEnd,ModalOrderSupplierAbort, InputDate,InputTextArea, 
            InputQuickSearch, DateRangePicker, InputSelectSearch, PrintTemplate,ModalSelectPrintTemplate, 
            AppPermission,ModalDataPayment, DetailStockCard, ModalSelectSendMailAndZaloTemplate, ModalReceiveInvoiceSupplier, QSelect
        },
        mounted() {
            document.title = this.$t('message.OrderSupplierIndex.document_title');
            const fields = localStorage.getItem('orderSupplierSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            this.paginate.currentPage = this.$route.query.page || 1;
            const query = this.$route.query;
            if(query['order_supplier'] != null){
                this.loadDetail();
            }else{
                this.clearFilter();
            }
            window.addEventListener('scroll', this.onScroll);
        },
        data() {
            const query = getSearchQuery();
            this.$router.replace({
                path: '/order-suppliers/index',
                query: {}
            });
            const globalConfig = store.state.GlobalConfig;
            return {
                currentBranchID: store.state.CurrentBranch.id,         
                tabActive: this.$constant.tabInformation,
                exEntries:[],
                userId: store.state.Auth.user.id,
                orderTime: null,
                expectedAt: null,
                loading: false,
                filter: {
                    keyword: query.keyword,
                    order_supplier: '',
                    purchase_order: '',
                    product_code_name: '',
                    supplier_code_name: '',
                    note: '',
                    created: null,
                    createdPicker: null,
                    status: [],
                    branches: [store.state.CurrentBranch.id],
                    suppliers: explode(query.suppliers),
                    createdBy: explode(query.createdBy),
                    orderBy: explode(query.orderBy),
                    statusPayment: [],
                },
                checkedAll: false,
                fields: [],
                entries: [],                
                statusOptions: this.$constant.listStatusOrderSupplier,
                statusOptionsTicket: this.$constant.statusOptionsTicket,
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },               
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                numberRecord: 15,
                user: store.state.Auth.user,
                lstUsers:globalConfig.users,
                orderSupplierDetail: null,
                fieldOrderSupplierDetail : [
                    { name: 'message.DetailOrdersCard.prodCode', field: 'code', },
                    { name: 'message.DetailOrdersCard.prodName', field: 'name', },
                    { name: 'message.DetailOrdersCard.prodUnit', field: 'unitName', },
                    { name: 'message.DetailOrdersCard.attribute', field: 'attributeName', },
                    { name: 'message.DetailOrdersCard.prodQuantity', field: 'number', },
                    { name: 'message.DetailOrdersCard.prodPrice', field: 'price', },
                    { name: 'message.DetailOrdersCard.prodDiscount', field: 'priceDiscount', },
                    { name: 'message.DetailOrdersCard.prodImportPrice', field: 'orderPrice', },
                    { name: 'message.DetailOrdersCard.totalPrice', field: 'subTotal', },
                ],
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                orderBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                createdBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                fixed: false,
                defaultOrderSupplier:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                    
                },
                arrayChecked: [],
                sortTime: 0,   
                content: null,   
                listStatusPayment: [
                    { "id": 0, "name": "message.common.unpaid" },
                    { "id": 1, "name": "message.common.partialPayment" },
                    { "id": 2, "name": "message.common.paid" }
                ],    
                isFilter: false  
            }
        },
        methods: {
            async updateFilterBranch(values) {
                this.doFilterSearch({
                    label: 'branches',
                    model: (values && values.length) ? values.toString() : '',
                });
            },
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabOrderSuppliers, this.$constant.typeImportProducts, this.arrayChecked);
            },
            showModalDataPayment(id, method, input){                
                this.$refs.ModalDataPayment.show(id, method, input);
            },
            async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = entry && entry.supplier ? entry.supplier.contactNumber : null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabOrderSuppliers, this.$constant.typeImportProducts, this.arrayChecked, typeSend, phone);
            }, 
            showContent(tab){            
                switch (tab) {
                    case this.$constant.tabInformationValue:                    
                        this.tabActive = this.$constant.tabInformation;
                        break;
                    case this.$constant.tabPaymentValue:
                        this.tabActive = this.$constant.tabHistoryPayment;                    
                        break;
                    case this.$constant.tabSupplierValue:                    
                        this.tabActive = this.$constant.tabHistorySupplier;
                        break;  
                    case this.$constant.tabDeliveryValue:                    
                        this.tabActive = this.$constant.tabHistoryDelivery;
                        break;  
                }
            },     
            async changeQuickSearch(object){
                const query = clone(this.$route.query);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },  
            goCreateImport(id, importCount) {
                if (importCount != 0) {
                    $alert({code: 500 , message: this.$t('message.common.havePODraft')});
                    return;
                }
                this.$router.push({name: 'ImportSupplierCreate', query: {orderId: id}});
            },
            timeChange(time){
                this.orderTime = time;
                this.entries.forEach(entry => {
                    entry.orderTime = time;
                    entry.ExpectedDate = this.expectedAt;
                });
            },      
            exAtChange(id){
                let expectedAt = document.getElementById('expectedAt_' + id).value;
                this.expectedAt = expectedAt;
                this.entries.forEach(entry => {                    
                    entry.ExpectedDate = this.expectedAt;
                });
            },
            resetValue(uid){
                this.entries.forEach(entry => {
                    if(entry.id === this.orderSupplierDetail){
                        entry.orderById = uid;
                    }
                })
            },
            changeDateTimePicker(time){
                this.entries.forEach(entry => {
                    if(entry.id == this.orderSupplierDetail){
                        entry.dateTimePicker = time;
                    }
                });
            },
            async updateChange(id,code){
                let orderDate, orderBy;
                this.entries.forEach(entry => {
                    if(entry.id == id){
                        orderDate = entry.dateTimePicker;
                        orderBy = entry.orderById;
                    }
                })
                
                let expectedAt = document.getElementById('expectedAt_' + id).value;
                let status =  document.getElementById('status_' + id).value ;
                let note = document.getElementById('note_' + id).value;
                let data = {
                    note: note,
                    orderDate : orderDate,
                    expectedAt: expectedAt,
                    orderBy   : orderBy,
                    status    : status,
                    id: id                
                }                
                const res = await $post('/v1/order-suppliers/updateList/' + id, {...data});
                if(res.status.code == 200) {                    
                    toastr.success(this.$t('message.OrderSupplierIndex.alertUpdateSuccess'));
                    this.load();
                }
                else {
                    if(res.code == 422){
                       if(res.messages.errors.orderDate) toastr.error(res.messages.errors.orderDate);
                       if(res.messages.errors.expectedAt) toastr.error(res.messages.errors.expectedAt);
                       if(res.messages.errors.note) toastr.error(res.messages.errors.note);
                    }                   
                }

            },          
            showModalOrderSupplierCreateUpdate(){
                router.push({ name: 'orderSupplierCreate'});
            },
            
            ShowModalOrderSupplierEnd(id, code){                
                this.$refs.ModalOrderSupplierEnd.show(id, code);
            },
            ShowModalOrderSupplierAbort(entry){
                this.$refs.ModalOrderSupplierAbort.show(entry);                
            },
            detailOrderSupplier(id, entry){                
                if (id === this.orderSupplierDetail) {
                    this.orderSupplierDetail = null;
                } else {
                    this.orderSupplierDetail = id;
                }                
                this.defaultOrderSupplier.filter = this.defaultOrderSupplier.all = entry.details;
                this.setPaginate();
                this.tabActive = this.$constant.tabInformation;
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/order-suppliers/index',
                    query: {
                        status: '0,1,2,3',
                        branches: store.state.CurrentBranch.id,
                    }
                });
                Object.keys(this.filter).forEach(key => {
                    if(key == 'status'){
                        this.filter[key] = [0,1,2,3];
                    }else if(key == 'statusPayment'){
                        this.filter[key] = [0,1];
                    }else if(key == 'branches'){
                        this.filter[key] = [store.state.CurrentBranch.id];
                    }else{
                        this.filter[key] = '';
                    }
                });
                emitter.emit("clearQuickSearch");
                emitter.emit("clearDateRangePicker");
                emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.branches.search = null;
                this.orderBy.search = null;
                this.createdBy.search = null;
                this.sortTime = 0;
                this.numberRecord = 15;
                this.orderSupplierDetail = null;
                this.isFilter = false;
                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            async filterTime(time = null, option){
                const query = clone(this.$route.query);
                delete query['created'];
                if(time){
                    query['created'] = time;
                    query['page'] = 1;
                    if(option == 1){
                        this.sortTime = 1;
                        this.filter.createdPicker = time;
                    }else{
                        this.sortTime = 0;
                        this.filter.created = time;
                    }
                }
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async doFilterSearch(v){
                const query = clone(this.$route.query);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async doFilter(field, value, name) {
                const query = clone(this.$route.query);
                if(field == 'status'){
                    let arr = this.filter.status;
                    if(arr.includes(value)){
                        arr = arr.filter(item => item !== value)
                    }else{
                        arr.push(value)
                    }
                    this.filter.status = arr;
                    if (Object.keys(arr).length === 0) {
                        delete query[field];
                    }else{
                        query[field] = arr.toString();
                    }
                }else if(field == 'statusPayment'){
                    let arr = this.filter.statusPayment;
                    if(arr.includes(value)){
                        arr = arr.filter(item => item !== value)
                    }else{
                        arr.push(value)
                    }
                    this.filter.statusPayment = arr;
                    if (Object.keys(arr).length === 0) {
                        delete query[field];
                    }else{
                        query[field] = arr.toString();
                    }
                }else{
                    // if(query[field] == value){
                    //     delete query[field];
                    //     name = null;
                    // }else{
                        query[field] = value;
                    // }
                }
                query['page'] = 1;
                await this.$router.push({query: query});
                switch (field) {
                    case 'branches':
                        this.branches.search = name;
                        break;
                    case 'orderBy':
                        this.orderBy.search = name;
                        break;
                    case 'createdBy':
                        this.createdBy.search = name;
                        break;
                    default:
                        break;
                }
                this.isFilter = true;
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;
                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.$route.query);
                query['page'] = page;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            sumQuantity(details){
                let totalquantity = 0;
                details.forEach(value => {
                    totalquantity = totalquantity + value.quantity;
                    if (value.plus && value.plus?.length > 0) {
                        value.plus.forEach(itemPlus => {
                            totalquantity = totalquantity + itemPlus.quantity;
                        });
                    }
                });
                return totalquantity;
            },
            countQuantity(details){
                let dem = 0;
                details.forEach(value => {
                    dem = dem + 1;
                });
                return dem;
            },
            totalPrices(details){
                let sum = 0;
                details.forEach(value => {
                    sum = sum + Number(value.price);
                });
                return numberFormat(sum);
            },
            Subtraction(minDate, maxDate){
                if(maxDate == null){
                    return null;
                }else{
                    const date1 = new Date(minDate);
                    const date2 = new Date(maxDate);
                    const diffInTime = date2.getTime() - date1.getTime();
                    const diffInDays = diffInTime / (1000 * 3600 * 24);                
                    return diffInDays;
                }                
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/order-suppliers/data', {...this.$route.query, record: this.numberRecord});                                     
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }                   
                //Phục vụ cho export tổng quang không qua phân trang
                // this.exEntries = JSON.parse(res.data.exEntries)?? null; 
                // setArray(res.data.entries, {
                //     checked: false
                // });
                if (!this.fields || !this.fields.length) {
                    this.fields = res.data.fields;
                }
                // if (res.data.listStatusPayment) {
                //     this.listStatusPayment = res.data.listStatusPayment;
                // }
                // this.paginate = res.data.paginate;
                // this.entries = res.data.entries;
                this.entries = res.data.data.data;       
                this.paginate.totalRecord = res.data.data.total;
                this.paginate.currentPage = res.data.data.current_page;
                this.paginate.lastPage = res.data.data.last_page;
                let ordDatetime = null;
                let ExpectedDateOfImport = null;
                let today = moment(new Date()).format('YYYY-MM-DD');                
                this.entries.forEach(item => { 
                    item.importCode = item.import?.code ? item.import.code : null;
                    item.orderById = item.order_by?.id ? item.order_by?.id : null;
                    item.created_at =  item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm'): null;
                    item.updated_at =  item.updated_at ? moment(item.updated_at).format('DD/MM/YYYY HH:mm'): null;
                    ordDatetime = item.orderDate ? item.orderDate : null;
                    item.dateTimePicker = ordDatetime;
                    ExpectedDateOfImport = item.ExpectedDateOfImport ? item.ExpectedDateOfImport : null;
                    item.ExpectedDateOfImport = item.ExpectedDateOfImport ? moment(ExpectedDateOfImport).format('DD/MM/YYYY'): null;
                    item.ExpectedDate = item.ExpectedDateOfImport ? moment(ExpectedDateOfImport).format('YYYY-MM-DD'): null;
                    this.expectedAt = item.ExpectedDateOfImport ? moment(ExpectedDateOfImport).format('YYYY-MM-DD'): null;
                    item.orderDate = item.orderDate ? moment(ordDatetime).format('DD/MM/YYYY HH:mm') : null;
                    item.date = item.orderDate ? moment(ordDatetime).format('YYYY-MM-DD') : null;
                    item.orderTime = item.orderDate ? moment(ordDatetime).format('HH:mm'): null;
                    this.orderTime = item.orderDate ? moment(ordDatetime, 'HH:mm').format('HH:mm'): null;
                    item.totalQuantity = this.sumQuantity(item.details);
                    item.totalProductType = this.countQuantity(item.details);                   
                    item.waitDay = this.Subtraction(today,ExpectedDateOfImport);
                    //Bổ sung lịch sử thanh toán
                    if(item.historyPayment){
                        item.historyPayment.sort( function(a, b) { return new Date(b.createdAt) - new Date(a.createdAt) });
                        item.historyPayment.forEach(history => {
                            history.createdAt = history?.createdAt ? moment(history.createdAt).format('DD/MM/YYYY HH:mm'): null;    
                            history.payment = history?.payment ? numberFormat(history.payment): 0;  
                        });                                                                       
                    }
                    else{item.historyPayment = null;}    
                });
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;                
            },          
            async loadDetail(){
                emitter.emit("clearQuickSearch");
                emitter.emit("clearDateRangePicker");
                emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.branches.search = null;
                this.orderBy.search = null;
                this.createdBy.search = null;
                this.sortTime = 0;
                this.numberRecord = 15;
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailOrderSupplier(entry.id, entry);
                });
            },
            onInputFilterDetailOrderSupplier(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultOrderSupplier.searchCode.length == 0 && this.defaultOrderSupplier.searchName.length == 0){
                    this.defaultOrderSupplier.filter = this.defaultOrderSupplier.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultOrderSupplier.all.length; index++) {
                            if (this.defaultOrderSupplier.all[index].product != null && removeVietnameseTones(this.defaultOrderSupplier.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultOrderSupplier.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultOrderSupplier.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultOrderSupplier.all.length; index++) {
                            if (this.defaultOrderSupplier.all[index].product != null && removeVietnameseTones(this.defaultOrderSupplier.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultOrderSupplier.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultOrderSupplier.all[index]);
                            }
                        }
                        break;
                }
                this.defaultOrderSupplier.filter = arr;
                this.setPaginate();
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultOrderSupplier.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultOrderSupplier.filter.length;
            },
            async clearSearch(field){
                const query = clone(this.$route.query);
                delete query[field];
                query['page'] = 1;
                await this.$router.push({query: query});
                switch (field) {
                    case 'branches':
                        this.branches.search = null;
                        this.branches.filter = this.branches.all;
                        break;
                    case 'orderBy':
                        this.orderBy.search = null;
                        this.orderBy.filter = this.orderBy.all;
                        break;
                    case 'createdBy':
                        this.createdBy.search = null;
                        this.createdBy.filter = this.createdBy.all;
                        break;
                    default:
                        break;
                }
                this.load();
            },
            onScroll() {
                let height = window.scrollY;
                if(height > 120){
                    //Giữ vị trí cho thông báo lỗi    
                    this.fixed = true;
                }else{
                    this.fixed = false;
                }
                emitter.emit("position", 0);      
            },  
            async copyOrderSupplierById(id){
                 router.push({ name: 'orderSupplierUpdate', query: { orderId: id, type: 'copy' }});               
            },
            async editOrderSupplier(id){
                 router.push({ name: 'orderSupplierUpdate', query: { orderId: id}});
            },  
            async exportOrderSupplierById(id){
                emitter.emit("onLoading");   
                const res = await http.download('/v1/order-suppliers/exOSById/' + id, {...this.fields, branchID: this.currentBranchID});                
                this.returnDataExport(res);  
            },      
            async exSelectOverview() {
                emitter.emit("onLoading");    
                let id = this.arrayChecked;                            
                const res = await http.download('/v1/order-suppliers/exOverview', {fields:this.fields, id:id, branchID: this.currentBranchID});              
                this.returnDataExport(res);  
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");  
                let id = this.arrayChecked;                
                const res = await http.download('/v1/order-suppliers/exDetails', {id:id, branchID: this.currentBranchID});
                this.returnDataExport(res);
            },
            async exOverview() {
                emitter.emit("onLoading");    
                let id = this.getOSByID(this.exEntries);                                
                const res = await http.download('/v1/order-suppliers/exOverview', {fields:this.fields, id:id,  branchID: this.currentBranchID, params: {...this.$route.query}, all: true});
                this.returnDataExport(res);     
            },         
            getOSByID(entries){
               return entries;
            },
            async exDetails() {                
                emitter.emit("onLoading");                            
                const res = await http.download('/v1/order-suppliers/exDetails', {id: this.exEntries, branchID: this.currentBranchID, params: {...this.$route.query}, all: true});
                this.returnDataExport(res);          
            },
            getTotalPurchase(entry, detail) {
                if (entry.import_supplier) {
                    let total = 0;
                    entry.import_supplier.forEach((imp) => {
                    if (imp.details && imp.orderForSupplierId == entry.id && imp.status == this.$constant.status_imported_order) {
                        imp.details.forEach((item) => {
                            if (item.productId === detail.productId && item.productUnitId === detail.productUnitId && item.variantId === detail.variantId) {
                                total += item.quantity;
                            }
                        })
                    }
                    });
                    return total;
                } else {
                    return 0
                }
            },
            getPriceImport(entry, detail) {
                if (entry.import_supplier) {
                    let price = 0;
                    entry.import_supplier.forEach((imp) => {
                    if (imp.details && imp.code == entry.importCode) {
                        imp.details.forEach((item) => {
                            if (item.productId === detail.productId) {
                                price = item.price;
                            }
                        })
                    }
                    });
                    return price;
                } else {
                    return 0
                }
            },
            refreshDatePicker(option){
                switch (option) {
                    case 0:
                        this.filterTime(this.filter.created);
                        break;
                    case 1:
                        this.filterTime(this.filter.createdPicker, 1);
                        break;
                }
            },       
            
            // In phiếu
            //----------------------------------------------------------------------------------------
            async printTemplate(entry) {                        
                entry.typePrint = this.$constant.TypeOrderSuppliers;                
                let dataPrint = await this.dataPrint(entry);
                this.editorDataClone = await this.renderData(dataPrint);
                await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);
            },
            async updateContent(data){
                this.editorDataClone = '';
                this.content = data.content;
                if(data.dataPrint && Array.isArray(data.dataPrint)){
                    let breakPage = `<div style="page-break-before: always"></div>`
                    for (let index = 0; index < data.dataPrint.length; index++) {
                            const print = this.formatproductPrintCommon(data.dataPrint[index], this.$constant.type_print_multiple);                            
                            print.typePrint = this.$constant.TypeOrderSuppliers;
                            let dataPrint = await this.dataPrint(print);                            
                            let orderItem = await this.renderData(dataPrint);
                            
                            if (index != data.dataPrint.length - 1) {
                                this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                            } else {
                                this.editorDataClone = this.editorDataClone + orderItem;
                            }
                        }                 
                                     
                    if(this.editorDataClone !== null) await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);         
                }
                else{
                    let dataPrint = await this.formatproductPrintCommon(data.dataPrint, this.$constant.type_print_single);
                    await this.printTemplate(dataPrint);
                }
            },
            async printMultiple(ids = null){                
                //Lấy data theo mang ids
                const res = await $post('/v1/order-suppliers/data-by-ids', {
                        ids: ids ?? this.arrayChecked,
                        branchId: this.currentBranchID,
                });                          
                if(res && res.status.code == 200 && res.result.length > 0) {
                    await this.print(res.result);                
                }
            },
            async print(entry){                      
                // Chọn mẫu in
                entry.typePrint = this.$constant.TypeOrderSuppliers;  
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabOrderSuppliers});                
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }                                                             
            },
            showDetailStockCard(id, type){
                this.$refs.DetailStockCard.show(id, type);
            },
            receiveInvoiceSupplier(arrayChecked){
                this.$refs.ModalReceiveInvoiceSupplier.show(arrayChecked);
            },

            async loadSetting ()
            {
                const res = await $get('/v1/setting-store/get-options', {action: 'product'});
                this.statusOption.consignment = this.statusOption.variant = false;
                res.result.forEach(element => {
                    
                    if (element.id == 2 && element.status == this.$constant.active) {
                        this.statusOption.variant = true;
                    }
                    if (element.id == 7 && element.status == this.$constant.active) {
                        this.statusOption.consignment = true;
                    }
                });
            },
        },

        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            })
        }
    }
</script>
<style scoped>
 .noteStyle {
        width: 280px;
        height: 100% !important;
        border: 0;
        border-left: 1px solid #ccc;
        border-radius: 0;
        
    }
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.label__time {
    position: absolute;
    top: 0.45rem;
    right: 4.5rem;
    color: #000;
}
.format__list-time {
    top: 10px !important;
    /* left: -150px !important; */
    width: 100px !important;
    height: 160px;
    overflow: auto;
}
select option {
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.lableOS {
    display: flex;
    align-items: center;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.far, .fa-clock {
    position: absolute;
    right: 16px;
    top: 11px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.detail__user {
    max-width: 1400px;
}

.no-boder {
    border-top: none !important;
    padding-bottom: 0 !important;
}

.backgroup__detail {   
    background: #D8EEF7 !important;    
}

.head-detail {
    min-width: 100px;
    display: block;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroup__detail-active {
    background: #fff !important;
}
.border-top-none{
    border-top: none;
}
.drp-menu{
    position: absolute;
    left:-125px;
    top:-102px;
}
 #fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
 }
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
#noteOS{
    border-left: 1px solid #ccc;
    color:#000;    
}
</style>