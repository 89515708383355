<template>
    <div v-if="isDataLoaded" class="tab-order">
        <div class="content" >
            <div class="row">
                <div class="col-lg-4">
                    <InputText v-model="entry.code"
                               :errors="errors.code"
                               :readonly="true"
                               label="Mã đặt hàng"/>

                    <InputDatePicker v-model="entry.purchaseDate"
                                     :errors="errors.purchaseDate"
                                     :timepicker="true"
                                     :readonly="entry.status >= 3"
                                     label="Thời gian"/>

                    <div class="form-group">
                        <label>{{$t('message.headers.customers')}}</label>
                        <div class="form-control" v-text="entry.customer ? entry.customer.name : ''" readonly></div>
                        
                    </div>

                    <InputSelect v-model="entry.saleChannelId"
                                 :errors="errors.saleChannelId"
                                 :options="saleChannels"
                                 :readonly="isCancel"
                                 label="Kênh bán"/>
                    <InputText v-model="entry.createdByName"
                               :errors="errors.createdByName"
                               :readonly="true"
                               label="Người tạo"/>
                </div>
                <div class="col-lg-4">
                    <InputSelect v-model="entry.status"
                                 :errors="errors.status"
                                 :options="listStatus"
                                 :readonly="false"
                                 label="Trạng thái"/>

                    <InputSelect v-if="entry.shopee_status" v-model="entry.shopee_status"
                                 :errors="errors.shopee_status"  
                                 :options="shopee_value"                               
                                 :readonly="true"
                                 label="Trạng thái trên shopee"/>                  
                    <div class="form-group">
                        <label>{{$t('message.common.branch')}}</label>
                        <div class="form-control" v-text="entry.branch ? entry.branch.name : ''" readonly></div>
                    </div>
                    <InputSelect v-if="entry.retailerId" v-model="entry.retailerId"
                                 :errors="errors.retailerId"  
                                 :options="shopee_name"                               
                                 :readonly="true"
                                 label="Tên shop"/>
                    <InputSelect v-model="entry.soldById"
                                 :options="branchAdmins"
                                 :errors="errors.soldById"
                                 :readonly="entry.status >= 3"
                                 label="Người nhận đặt"/>

                    
                </div>
                <div class="col-lg-4" style="border-left: 1px solid">
                    <InputTextArea v-model="entry.description"
                                   :errors="errors.description"
                                   :readonly="isCancel"
                                   height="295px"
                                   :label="$t('message.ProductIndex.note')"/>
                </div>
            </div>
            <!-- Thông tin hóa đơn -->
            <div class="row mt-4" id="ordRow">
                <div class="col-lg-12" id="ordTitle">
                    <div id="ordDiv">
                        <label id="ordText">Trạng thái hóa đơn</label>
                    </div>
                </div>
                <div class="col-lg-4 mt-2">                    
                            <div class="form-group">
                                <label>Trạng thái trên Omipos:</label>
                                <InputSelect v-if="entry.status" v-model="entry.status"
                                    :errors="errors.status"  
                                    :options="omipos_value"
                                    :readonly="true"/> 
                            </div>
                        </div>
                        <div class="col-lg-4 mt-2">                    
                            <div class="form-group">
                                    <label>Trạng thái trên Shopee</label>
                                    <InputSelect v-if="entry.shopee_status" v-model="entry.shopee_status"
                                    :errors="errors.shopee_status"  
                                    :options="shopee_value"                               
                                    :readonly="true"/> 
                            </div>
                        </div>
            </div>

            <!-- Hiển thị thông tin vận chuyển -->
            <div class="row" v-if="delivery" >
                <div class="col-lg-12 mt-4 mb-4" style="background: rgb(232 232 232 / 52%); border-radius: 5px;">
                    <div style="margin: 15px 0; font-weight: 600; font-size: 18px">
                        <span>{{$t('message.InvoiceIndex.tabs.delivery.address_receive')}}: </span>
                        <span v-text="delivery.decode"></span>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <InputText v-model="delivery.receiverName"
                                       :errors="deliveryErrors.receiverName"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.InvoiceIndex.tabs.delivery.receive_by')"/>

                            <InputText v-model="delivery.receiverPhone"
                                       :errors="deliveryErrors.receiverPhone"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.InvoiceIndex.tabs.delivery.contact_number')"/>

                            <InputText v-model="delivery.receiverAddress"
                                       :errors="deliveryErrors.receiverAddress"
                                       :readonly="isCancel || isComplete"
                                       label="Địa chỉ"/>

                            <InputLocationPicker v-model="receiverLocation" :readonly="isCancel || isComplete"></InputLocationPicker>
                        </div>
                        <div class="col-lg-4">
                            <InputText v-model="delivery.code"
                                       :errors="deliveryErrors.code"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.orderFromTab.delivery_code')"/>

                            <div class="form-group mb-0">
                                <label>{{$t('message.InvoiceIndex.tabs.delivery.weight')}}</label>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <InputText v-model="delivery.weight"
                                                   :errors="deliveryErrors.weight"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.InvoiceIndex.tabs.delivery.weight')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputSelect v-model="delivery.weightUnit"
                                                     :readonly="isCancel || isComplete"
                                                     :options="listWeightUnit"
                                        ></InputSelect>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <label>{{$t('message.InvoiceIndex.tabs.delivery.size')}}</label>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.long"
                                                   :errors="deliveryErrors.long"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.long')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.width"
                                                   :errors="deliveryErrors.width"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.width')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.height"
                                                   :errors="deliveryErrors.height"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.height')"
                                        ></InputText>
                                    </div>
                                </div>
                            </div>

                            <InputSelect v-model="delivery.serviceTypeId"
                                         :errors="deliveryErrors.serviceTypeId"
                                         :readonly="isCancel || isComplete"
                                         :options="listServiceType"
                                         label="Dịch vụ"
                            ></InputSelect>
                        </div>
                        <div class="col-lg-4">
                            <InputSelect v-model="delivery.transporterId"
                                         :errors="deliveryErrors.transporterId"
                                         :readonly="isCancel || isComplete"
                                         :options="listTransporter"
                                         label="Người giao"
                            ></InputSelect>

                            <InputSwitchButton v-model="delivery.usingPriceCod"
                                               label="Thu hộ tiền (COD)"
                                               :readonly="isCancel || isComplete"
                                               :detail="n(delivery.priceCodPayment)"
                            ></InputSwitchButton>
                            <!-- <InputText v-model="delivery.price"
                                       :errors="deliveryErrors.price"
                                       :readonly="true"  
                                       label="Phí trả ĐTGH"/> -->
                            <InputDatePicker v-model="delivery.deliveryTime"
                                             :readonly="isCancel || isComplete"
                                             :errors="deliveryErrors.deliveryTime"
                                             :timepicker="true"
                                             :label="$t('message.orderFromTab.delivery_time')"
                            ></InputDatePicker>

                            <InputTextArea v-model="delivery.note"
                                           :errors="deliveryErrors.note"
                                           :readonly="isCancel || isComplete"
                                           :label="$t('message.ProductIndex.note')"
                                           height="212px"
                            ></InputTextArea>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Bảng sản phẩm -->
            <div class="row">
                <div class="col-lg-12 mt-4 mb-4">
                    <table class="table table-index table-striped">
                        <thead>
                        <tr>
                            <th>{{$t('message.DetailOrdersCard.prodCode')}}</th>
                            <th>{{$t('message.DetailOrdersCard.prodName')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                            <th class="text-center">{{$t('message.ProductIndex.price')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in orderItems" :key="item.productCode">
                            <td v-text="item.productCode"></td>
                            <td>
                                <span v-text="item.productName"></span>
                                <span v-if="getDiscountPrice(item)" class="discount-icon"></span>
                            </td>
                            <td class="text-center" v-text="item.product_unit ? item.product_unit.unitName : ''"></td>
                            <td class="text-center">
                                <span v-text="item.quantity"></span>
                                <span> / </span>
                                <span :style="item.invoicedQuantity == item.quantity ? 'color: teal' : 'color: red'"
                                      v-text="item.invoicedQuantity"></span>
                            </td>
                            <td class="text-center" v-text="n(item.price)"></td>
                            <td class="text-center" v-text="n(getDiscountPrice(item))"></td>
                            <td class="text-center"
                                v-text="n(parseInt(item.subTotal))"></td>
                            <td class="text-center"
                                v-text="n(parseInt(item.subTotal) * parseInt(item.quantity))"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-lg-5">
                    <div class="discount-content">
                        <h5>Chương trình khuyến mãi</h5>
                        <div class="pl-2">
                            Nội dung chương trình khuyến mãi
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-3 order-bill">
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderSupplierIndex.total_quantity')}}</span>
                        <span v-text="calcTotalQuantity"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderSupplierIndex.total_origin')}}</span>
<!--                        <span v-text="n(calcTotalAmount)"></span>-->
                        <span v-text="n(entry.totalOrigin)"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderIndex.discount')}}</span>
                        <span v-text="n(getDiscountPrice(entry))"></span>
                    </div>
                    <!-- <div class="order-bill__item">
                        <span>Thu lệch vận chuyển</span>
                        <span v-text="n(getDiscountPrice(entry))"></span>
                    </div> -->
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderIndex.total')}}</span>
                        <span v-text="n(entry.total)"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>Khách đã trả</span>
                        <span v-text="n(entry.totalPayment)"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/orders/index" class="btn btn-warning">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        Quay lại
                    </router-link>

                    <button v-if="!isComplete && !isCancel" class="btn btn-save" type="button" @click="complete()">
                        <i class="fa fa-check-circle"></i>
                        Kết thúc
                    </button>
                    <button v-if="!isCancel" class="btn btn-save" type="button" @click="save()">
                        <i class="fa fa-save"/>
                        Lưu lại
                    </button>
                    <a v-if="!isCancel && !isComplete" :href="'/sale?order=' + entry.code" target="_blank" class="btn btn-save" type="button">
                        <i class="fa fa-recycle"></i>
                        Xử lý đơn hàng
                    </a>
                    <button class="btn btn-save" type="button" @click="onPrint()">
                        <i class="fa fa-print"/>
                        In phiếu
                    </button>
                    <button class="btn btn-save" type="button">
                        <i class="fas fa-file-export"></i>
                        Xuất file
                    </button>
                    <button v-if="!isCancel" class="btn btn-danger" type="button" @click="cancel()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Hủy bỏ
                    </button>

                    <button v-if="isWait" class="btn btn-warning" @click="approve">
                        <i class="fa fa-check" aria-hidden="true"></i>
                        Duyệt đơn hàng
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="order-detail__print">
        <ModalPrintOrder v-if="printType == TYPE_ORDER" :data="entry"></ModalPrintOrder>
    </div>
</template>

<script>
    import {$post, $alert, scrollToElement} from "@/lib/utils";
    import InputDatePicker from "../../../components/forms/InputDatePicker";
    import InputSelect from "../../../components/forms/InputSelect";
    import InputTextArea from "../../../components/forms/InputTextArea";
    import InputText from "../../../components/forms/InputText";
    import InputLocationPicker from "../../../components/forms/InputLocationPicker";
    import InputSwitchButton from "../../../components/forms/InputSwitchButton";
    import ModalPrintOrder from "@/views/orders/ModalPrintOrder";
    import ModalPrintDelivery from "@/views_sale/sales/ModalPrintDelivery";
    import {DISCOUNT_TYPE_PERCENT, isEmpty, TYPE_INVOICE,
        TYPE_ORDER,
        TYPE_DELIVERY} from "../../../lib/utils";
    import store from "../../../store";

    export default {
        name: "OrderTabOrderInfo",
        components: {InputSwitchButton, InputLocationPicker, InputDatePicker, InputSelect, InputTextArea, InputText,
        ModalPrintOrder, ModalPrintDelivery},
        data() {
            return {
                entry: {},
                orderItems: [],
                saleChannels: store.state.GlobalConfig.saleChannels,
                branchAdmins: store.state.GlobalConfig.branchAdmins,
                listStatus: [],
                shopee_value: [],
                omipos_value:[],
                shopee_name: [],
                errors: {},
                delivery: {},
                receiverLocation: {
                    provinceId: 0,
                    districtId: 0,
                    wardId: 0
                },
                deliveryErrors: {},
                listWeightUnit: store.state.GlobalConfig.listWeightUnit,
                listServiceType: store.state.GlobalConfig.listServiceType,
                listTransporter: store.state.GlobalConfig.listTransporter,

                isDataLoaded: false,
                printType: '',
                TYPE_INVOICE, TYPE_ORDER, TYPE_DELIVERY
            }
        },
        computed: {
            calcTotalQuantity() {
                let quantity = 0;

                this.orderItems.forEach((item) => {
                    quantity += parseInt(item.quantity);
                });

                return quantity;
            },
            calcTotalAmount() {
                let amount = 0;

                this.orderItems.forEach((item) => {
                    amount += Math.max(parseInt(item.quantity) * parseInt(item.subTotal), 0);
                });

                return amount;
            },
            isComplete() {
                return parseInt(this.entry.status) === 4;
            },
            isCancel() {
                return parseInt(this.entry.status) === 5;
            },
            isWait() {
                return parseInt(this.entry.status) === 1;
            }
        },
        methods: {
            async save() {
                this.errors = {};

                if (!isEmpty(this.delivery)) {
                    this.delivery.receiverProvinceId = this.receiverLocation.provinceId;
                    this.delivery.receiverDistrictId = this.receiverLocation.districtId;
                    this.delivery.receiverWardId = this.receiverLocation.wardId;
                }

                const res = await $post('/v1/orders/save', {
                    entry: this.entry,
                    delivery: this.delivery,
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            },
            async complete() {
                if (!window.confirm('Bạn sẽ không thể xử lý đơn hàng mặc dù hàng hóa chưa giao hết. Bạn có chắc chắn kết thúc và chuyển trạng thái đơn hàng sang hoàn thành không?')) {
                    return false;
                }

                const res = await $post('/v1/orders/saveStatus', {
                    id: this.entry.id,
                    action: 'complete'
                });

                $alert(res);

                if (res.code === 200) {
                    window.location.reload();
                }
            },
            async cancel() {
                if (!window.confirm('Bạn có chắc chắn muốn hủy phiếu đặt hàng ' + this.entry.code + ' này không?')) {
                    return false;
                }

                const res = await $post('/v1/orders/saveStatus', {
                    id: this.entry.id,
                    action: 'cancel'
                });

                $alert(res);

                if (res.code === 200) {
                    window.location.reload();
                }
            },
            getDiscountPrice(entry) {
                let discountPrice = 0;
                if (entry.discountType == DISCOUNT_TYPE_PERCENT) {
                    discountPrice = Math.ceil(entry.totalOrigin * entry.discountValue / 100);
                } else {
                    discountPrice = entry.discountValue;
                }

                return discountPrice;
            },
            async approve() {
                if (!window.confirm('Xác nhận phê duyệt phiếu đặt hàng ' + this.entry.code)) {
                    return false;
                }

                const res = await $post('/v1/orders/saveStatus', {
                    id: this.entry.id,
                    action: 'approve'
                });

                $alert(res);

                if (res.code === 200) {
                    window.location.reload();
                }
            },
            async onPrint() {
                this.printType = TYPE_ORDER;
                this.entry.items = this.orderItems;
                this.$nextTick(() => {
                    window.print();
                });
            },
        }
    }
</script>

<style scoped lang="scss">
.order-detail__print {
    display: none;
}
@media print {
    .tab-order {
        display: none;
    }
    .order-detail__print {
        display: block;
    }
}
#ordTitle {
    // border-bottom: 1px solid #ccc;
    padding: 0;
    height: 35px;
}
#ordTitle #ordText {
    font-size:17px;
    font-weight: bold;
    padding:6px 6px ;
}
// #ordDiv {
//     border: 1px solid #ccc;
//     border-bottom: 0;
//     width: 15%;
//     height: 100%;
//     border-top-left-radius: 5px;
//     border-top-right-radius: 5px;
// }
</style>
