<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 pl-4" :class="entry.products && entry.products.length ? 'col-left' : ''">            
            <table class="table table-index table-striped">
                <thead class="text-center">
                    <tr>
                        <th scope="col" style="width: 2%" class="cell-check"></th>
                        <th scope="col" style="width: 3%" class="cell-auto">{{ $t('message.planCreateUpdate.table.stt') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.code') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.name') }}</th>                
                        <th scope="col" style="width: 9%" class="cell-auto">{{ $t('message.planCreateUpdate.table.unit') }}</th>                
                        <th scope="col" style="width: 8%" class="cell-auto">{{ $t('message.planCreateUpdate.table.min_inventory') }}</th>                
                        <th scope="col" style="width: 8%" class="cell-auto">{{ $t('message.planCreateUpdate.table.max_inventory') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.estimate_number') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.avg_number_of_sale') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.sys_estimate_number') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.available_inventory') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.total_quantity_plan') }}</th>                    
                    </tr>
                </thead>
                <tbody class="font-weight-bold">
                    <tr class="bg-light-warning">
                        <td scope="col" class="cell-check"></td>
                        <td scope="col" class="cell-auto">[1]</td>                
                        <td scope="col" class="cell-auto">[2]</td>                
                        <td scope="col" class="cell-auto">[3]</td>                
                        <td scope="col" class="cell-auto">[4]</td>                
                        <td scope="col" class="cell-auto">[5]</td>                
                        <td scope="col" class="cell-auto">[6]</td>                
                        <td scope="col" class="cell-auto">[7]</td>                
                        <td scope="col" class="cell-auto">[8]</td>                
                        <td scope="col" class="cell-auto">[9]</td>                
                        <td scope="col" class="cell-auto">[10]</td>                
                        <td scope="col" class="cell-auto">[11=5+9+7-10]</td>                    
                    </tr>
                    <template v-if="entry.products && entry.products.length">
                        <template v-for="(product, index) in entry.products" :key="index">
                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*this.numberRecordDetail && index < this.paginateDetail.currentPage*this.numberRecordDetail">
                                <tr>
                                    <td scope="col" class="cell-check">
                                        <i class="fa fa-trash-alt text-danger" @click="removeItem(index)"></i>
                                    </td>
                                    <td scope="col" class="cell-auto" v-text="index + 1"></td>                
                                    <td scope="col" class="cell-auto" v-text="product.code"></td>                
                                    <td scope="col" class="cell-auto" v-text="product.name"></td>                
                                    <td scope="col" class="cell-auto">
                                        <InputSelect v-model="product.productUnitId"
                                            :options="product.product_units"
                                            :cardTransaction="true"
                                            :marginAuto="true"
                                            :placeholder="$t('message.PlanIndex.chooseUnit')"
                                            option-label="unitName"
                                        ></InputSelect>
                                    </td>                
                                    <td scope="col" class="cell-auto" v-text="n(product.minimalQuantity)"></td>                
                                    <td scope="col" class="cell-auto" v-text="n(product.maximumQuantity)"></td>                
                                    <td scope="col" class="cell-auto">
                                        <InputNumberFormat :pxNone="true" v-model="product.estimateNumber" :bgTransparent="true" :cardTransaction="true" class="mb-0" :max="10000000" :min="-10000000"/>
                                    </td>                
                                    <td scope="col" class="cell-auto" v-text="n(product.avgNumberOfSale)"></td>                
                                    <td scope="col" class="cell-auto" v-text="n(product.sysEstimateNumber)"></td>                
                                    <td scope="col" class="cell-auto" v-text="n(product.availableInventory)"></td>                
                                    <td scope="col" class="cell-auto text-danger" v-text="n(product.totalQuantityOrder)"></td>                    
                                </tr>
                            </template>
                        </template>
                    </template>

                    <template v-else>
                        <div class="row d-flex align-items-center importArea">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="main-title">
                                    <span>
                                        {{$t('message.ImportExcel.title')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div>
                                    (
                                        {{$t('message.ImportExcel.defaultFile')}}
                                        <a :href="'../DuTruChiTiet_' + this.language + '.xlsx'" target="_blank" class="text-primary">
                                            {{$t('message.ImportExcel.file')}}
                                        </a>
                                    )
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3">
                                <button class="p-2 button-import" :title="$t('message.ImportExcel.chooseFile')">
                                    <form ref="form">
                                        <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" :title="$t('message.ImportExcel.chooseFile')"/>
                                    </form>
                                    
                                    <i class="fa fa-file mr-2"></i>
                                    {{$t('message.ImportExcel.chooseFile')}}
                                </button>
                            </div>
                            <div class="col-12 w-100 mt-3 upload-files" v-if="this.fileName != ''">
                                <div class="row h-100">
                                    <div class="col-6 d-flex align-items-center pl-5">
                                        {{ this.fileName }}
                                    </div>
                                    <div class="col-6 d-flex justify-content-end align-items-center">
                                        <a href="javascript:;" class="text-danger" @click="removeFile(false)">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 w-100 mt-3">
                                <div class="alert alert-danger row py-2" v-if="this.messageFile">
                                       {{ this.messageFile }}
                                </div>
                            </div>

                            <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="this.fileName != ''">
                                <button v-show="this.showBtn" class="p-2 button-import" :title="$t('message.ImportExcel.action')" @click="uploadExcel(this.patient)">
                                    <i class="fa fa-file mr-2"></i>
                                    {{$t('message.ImportExcel.action')}}
                                </button>
                            </div>
                        </div>
                    </template>
                </tbody>
                <Pagination :value="paginateDetail" :record="this.numberRecordDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
            </table>
        </div>

        <div class="col-4 col-xl-3 px-0 col-right">
            <div class="col-right__content">
                <div class="overflow-a-part">
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_code') }}:
                        </div>
                        <div class="col-8 pl-0">
                            <input type="text" v-model.trim="entry.code" :placeholder="$t('message.PlanIndex.autoCreateCode')" class="form-control w-100 border-bottom-1 rounded-0 pl-0">
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_name') }}:
                        </div>
                        <div class="col-8 pl-0">
                            <textarea class="border-0 bg-transparent border-bottom-1 rounded-0 w-100 textarea-outline-none" :placeholder="$t('message.PlanIndex.name')" v-model.trim="entry.planName"></textarea>
                            <!-- <input type="text" v-model.trim="entry.planName" placeholder="Tên báo cáo" class="form-control w-100 border-bottom-1 rounded-0 pl-0"> -->
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_branch') }}: <span class="text-danger">*</span>
                        </div>
                        <div class="col-8 pl-0">
                            <SelectSearch :vModel="true" v-model="entry.planBranchId" 
                                            :modelValue="entry.planBranchId" 
                                            :defaultOption="branches" :label="'areaDelivery_'" 
                                            :classCustom="'py-2'"
                                            :readonly="true"
                                            :plNone="true"
                                            :disabled="entry.isUpdate ? true : false">
                            </SelectSearch>
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0 d-flex align-items-end">
                        <div class="col-lg-4">
                            {{ $t('message.planCreateUpdate.plan_time') }}: <span class="text-danger">*</span>
                        </div>
                        <div class="col-lg-3 px-0">
                            <input type="date" v-model="entry.startDate" class="form-control w-100 border-bottom-1 rounded-0 px-0">
                        </div>
                        <div class="col-lg-1 px-1 mb-2">{{ $t('message.planCreateUpdate.to') }}</div>
                        <div class="col-lg-3 px-0">
                            <input type="date" v-model="entry.endDate" class="form-control w-100 border-bottom-1 rounded-0 px-0">
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_estimate') }}: <span class="text-danger">*</span>
                        </div>
                        <div class="col-8 pl-0">
                            <InputNumberFormat :pxNone="true" :max="365" v-model="entry.estimatedDate" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_status') }}:
                        </div>
                        <div class="col-8 pl-0" v-text="entry.statusName"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-12 mt-3">
                            <InputTextArea :placeholder="$t('message.PlanIndex.note') + '...'" v-model.trim="entry.note" class="input__textArea mb-0"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right__save row mx-0 px-0">
                <div class="col-12 text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submit(this.$constant.plan_status_wait)">
                                <div>{{ $t('message.planCreateUpdate.button.success') }}</div>
                            </button>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-primary" @click="submit(this.$constant.plan_status_draft)">
                                <div>{{ $t('message.planCreateUpdate.button.draft') }}</div>
                            </button>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalDetailTicket ref="ModalDetailTicket" />
    <ModalErrorsMessage ref="ModalErrorsMessageImport" />
</template>

<script>
    import InputSelect from "@/components/forms/InputSelect";
    import store from "../../store";
    import {$alert, $get, $post, $upload, cloneObject, parseIntEx, clone} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputTextArea from "@/components/forms/InputTextArea";
    import storage from "@/lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalDetailTicket from './ModalDetailTicket';
    import InputNumber from "@/components/forms/InputNumberCustom";    
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    import ModalErrorsMessage from '@/views/order_suppliers/ModalErrorsMessage.vue';
    import Pagination from "@/components/Pagination";
    export default {
        name: "PlansTab",
        emits: ['changeEntryBranch', 'changeStartDate', 'changeEndDate', 'removeTab', 'orderProcessing', 'print'],
        mixins: [currencyMixin, permissionMixin],
        components: {
            InputTextArea,
            InputNumberFormat,
            InputSelect,
            SelectSearch,
            ModalDetailTicket,
            InputNumber,
            AppPermission,
            ModalErrorsMessage,
            Pagination
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            const globalConfig = store.state.GlobalConfig;
            return {
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                customers: [],
                branchName: store.state.CurrentBranch.name ?? null,
                user,
                createdByOption: globalConfig.users,
                paymentChannelCashMoney: 0,
                paymentChannelTransferMoney: 0,
                paymentChannelCardMoney: 0,
                paymentChannelCash: false,
                paymentChannelCard: false,
                paymentChannelTransfer: false,
                branches: globalConfig.branches,
                ids: [],
                fileName: '',
                ssageFile: '',
                patient: {
                    file: [],
                    branchId: '',
                    startDate: '',
                    endDate: ''
                },
                isButtonDisabled: false,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                numberRecordDetail: 10,
                language: localStorage.getItem('language') ?? 'vi'
            }
        },
        async mounted() {
            document.title = this.$t('message.planCreateUpdate.title'); 
            if(!this.entry.planBranchId){
                this.entry.planBranchId = store.state.CurrentBranch ? store.state.CurrentBranch.id : null;
            }
            if(!this.entry.endDate){
                this.entry.endDate = moment().format('YYYY-MM-DD');
            }
            if(!this.entry.startDate){
                this.entry.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
            }      
            if(!this.entry.statusName){
                this.entry.statusName = this.$t('message.planCreateUpdate.status_draft');
            }
            if(this.entry.ids){
                this.ids = this.entry.ids;
                this.loadData();
            }
        },
        async created() {
            this.setPaginateDataDetail();
        },
        methods: {
            removeItem(index){
                this.entry.products.splice(index, 1);
            },
            async submit(status){
                this.isButtonDisabled = true;
                let data = {...this.entry};
                data.status = status;
                data.branchId = store.state.CurrentBranch ? store.state.CurrentBranch.id : null;
                data.name = data.planName ?? null;
                data.id = data.originId ? data.originId : data.id;
                if(!data.products || (data.products && data.products.length <= 0)){
                    $alert({code: 422, message: this.$t('message.planCreateUpdate.alerts.error')});
                    this.isButtonDisabled = false;
                    return;
                }
                let result;
                if(this.entry.isUpdate){
                    result = await $post('/v1/plans/update', {...data});
                }else{
                    result = await $post('/v1/plans/create', {...data});
                }
                if(result.code === 422){
                    let errors = result.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: result.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                    return;
                }else if(result.result && result.result.status){
                    if(this.entry.isUpdate){
                        $alert({code: 200, message: this.$t('message.planCreateUpdate.alerts.update_success')});
                    }else{
                        $alert({code: 200, message: this.$t('message.planCreateUpdate.alerts.create_success')});
                    }
                    // if(result.result.message_error){
                    //     $alert({code: 500, message: result.result.message_error});
                    // }
                    this.rollbackToScreenIndex();
                }else if(result.result && !result.result.status && !result.result.message){
                    $alert({code: 500, message: this.$t('message.PlanIndex.alerts.update_error')});
                    this.isButtonDisabled = false;
                    return;
                }
            },
            rollbackToScreenIndex(){
                this.$router.replace({
                    name: 'InventoryPlanIndex',
                    query: {}
                });
            },
            async loadData(){
                if(this.ids && this.ids.length){
                    const res = await $post('/v1/plans/get-product-for-plan', {
                        branchId: this.entry.planBranchId,
                        startDate: this.entry.startDate,
                        endDate: this.entry.endDate,
                        ids: this.ids,
                    });
                    if (res.status.code == 200 && res.result) {
                        res.result.forEach(data => {
                            const found = this.entry.products.find(product => product.productId == data.id);
                            if(found){
                                data.estimateNumber = found.estimateNumber;
                                data.productUnitId = found.productUnitId;
                            }
                        })
                        this.entry.products = res.result;
                    }
                }
                this.setPaginateDataDetail();
            },
            onFileChange(event) {
                var files = event.target.files;
                if (!files.length) {  
                    return;
                }
                this.fileName = files[0].name;
                this.patient.file = files[0];
               
                this.patient.branchId = store.state.CurrentBranch.id;
                this.patient.startDate = this.entry.startDate;
                this.patient.endDate = this.entry.endDate;
                if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
                    this.showBtn = true;
                    this.messageFile = '';
                } else {
                    this.showBtn = false;
                    this.messageFile = this.$t('message.common.inValidFormatFile')
                }
                this.$refs.form.reset();
            },

            async uploadExcel (file) {
                emitter.emit("onLoading");
                const result = await $upload('/v1/plans/import', [], file);
                if (result.result.code == 422) {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                } else {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    this.entry.products = result.result.data;
                }
                emitter.emit("offLoading");
            },
            removeFile(status) {
            this.patient.files = [];
            this.fileName = '';
                if (status == false) {
                    this.$refs.fileupload.value = '';
                    this.messageFile = ''
                }
            },
            setPaginateDataDetail(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.entry.products.length / 10);
                this.paginateDetail.totalRecord = this.entry.products.length;
            },
        },
        watch: {
            'entry.startDate'(value){
                if(!value){
                    this.entry.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
                }
                this.$emit('changeStartDate', value);
                this.loadData();
            },
            'entry.endDate'(value){
                if(!value){
                    this.entry.endDate = moment().format('YYYY-MM-DD');
                }
                this.$emit('changeEndDate', value);
                this.loadData();
            },
            'entry.planBranchId'(value){
                if(!value){
                    this.entry.planBranchId = store.state.CurrentBranch ? store.state.CurrentBranch.id : null;
                }
                this.$emit('changeEntryBranch', value);
                this.loadData();
            },
            entry: {
                handler: function(dataNew) {
                    let diffDate = 0;
                    if(dataNew.startDate && dataNew.endDate){
                        diffDate = (moment(dataNew.endDate).diff(moment(dataNew.startDate), "days") + 1);
                    }
                    let totalProducts = 0;
                    let totalQuantityProducts = 0;
                    if (dataNew.products) {
                        this.ids = [];
                        dataNew.products.forEach(product => {
                            product.productId = product.productId ? product.productId : (product.id ?? null);
                            this.ids.push(product.productId);
                            totalProducts += 1;
                            if(!product.productUnitId){
                                product.productUnitId = product.product_units && product.product_units[0] && product.product_units[0].id ? product.product_units[0].id : null;
                            }
                            if(product.productUnitId && product.product_branch_meta){
                                let currentUnit = product.product_branch_meta.find(p => p.productUnitId == product.productUnitId);
                                if(currentUnit){
                                    product.minimalQuantity = currentUnit.minimalQuantity ?? 0;
                                    product.maximumQuantity = currentUnit.maximumQuantity ?? 0;
                                    product.inventories = currentUnit.inventories ?? 0;
                                    product.quantityOrder = currentUnit.quantityOrder ?? 0;
                                    product.avgNumberOfSale = !diffDate ? 0 : Math.ceil(((+currentUnit.totalQuantitySold) - (+currentUnit.totalQuantityReturned)) / diffDate);
                                    product.availableInventory = ((+currentUnit.inventories ?? 0) - (+currentUnit.quantityOrder ?? 0) + (+currentUnit.receiveQuantity ?? 0) > 0) ? ((+currentUnit.inventories ?? 0) - (+currentUnit.quantityOrder ?? 0) + (+currentUnit.receiveQuantity ?? 0)) : 0;
                                    product.sysEstimateNumber = !dataNew.estimatedDate ? 0 : Math.ceil(+product.avgNumberOfSale * (+dataNew.estimatedDate));
                                    product.totalQuantityOrder = ((+currentUnit.minimalQuantity ?? 0) + (+product.sysEstimateNumber ?? 0) + (+product.estimateNumber ?? 0) - (+product.availableInventory ?? 0) > 0) ? ((+currentUnit.minimalQuantity ?? 0) + (+product.sysEstimateNumber ?? 0) + (+product.estimateNumber ?? 0)  - (+product.availableInventory ?? 0)) : 0;
                                    totalQuantityProducts += product.totalQuantityOrder;
                                }
                            }
                        });
                    }
                    this.entry.totalProducts = totalProducts;
                    this.entry.totalQuantityProducts = totalQuantityProducts;
                },
                deep: true
            },
            modelValue: {
                handler: function(dataNew) {
                    if (!dataNew) return;
                    this.entry = dataNew;
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    tbody th, tbody td {
        padding: 0.75rem !important;
        text-align: center;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            overflow: hidden auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: $background-white;
        overflow: hidden;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;
            .btn-save, .btn-primary {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;
            }
            .btn-save {
                &:hover {
                    background-color: $base-color !important;
                }
            }

            .btn-primary {
                background-color: $primary-color !important;
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .transpoter-options{
        overflow: auto;
        max-height: 300px;
    }
    .transpoter-options .disable{
        cursor: not-allowed !important;
        pointer-events: all !important;
    }
    .border-active{
        border: 1px solid $base-color;
    }

    .cart-footer-info {
        padding: 10px;
        background-color: $background-white;
        margin: 5px 0px;
        border-radius: 5px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
    }

    .cart-footer-info .total-quantity {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        margin: 0 5px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        font-size: 14px;
        display: inline-block;
        padding: 0 5px;
        text-align: center;
    }

    .cart-container-content table{
        height: calc(100vh - 300px) !important;
    }
    .position-relative .fa-address-book{
        position: absolute;
        top: 35%;
        right: 0px;
    }
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .icon-un-extend, .icon-extend{
        position: absolute;
        top: calc(100vh - 100px - 60%);
        left: 5px;
    }
    .overflow-a-part{
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .overflow-all{
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
    }

    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .importArea{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
    }
    .button-import{
        border-radius: 3px;
        border:none;
        outline: none;
        background: $base-color;
        color: #ffffff;
    }
    .button-import:focus{
        outline: none;
        border: none;
    }
    .button-import input{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        font: 170px monospace;
        filter: alpha(opacity=0);
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
    }
    .upload-files{
        overflow: hidden;
        padding: 1px 5px 2px;
        background: #ebf7fb;
        border: 1px solid #c5e7f2!important;
        min-height: inherit;
        line-height: inherit;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        height: 40px;
    }
</style>
