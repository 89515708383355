<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document" style="min-width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2" v-text="this.title ? this.title : $t('message.common.choosePrintTemplate')"></h5>
                    <button type="button" class="close pt-3" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 text-center my-3">
                        <div class="row">
                            <!-- Chọn mẫu in -->
                            <div class="col-lg-4" v-for="entry in entries" :key="entry.id">
                                <a href="javascript:void(0)"  @click="selectTemplate(entry)" style="min" 
                                :class="entryActive.id == entry.id ? 'btn btn-primary active' : 'btn btn-outline-secondary notActive' ">
                                    {{entry.name}}
                                </a>
                            </div>  
                                                                                                          
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-success mr-2" @click="confirm()" >
                            <i class="fa fa-print mx-2"></i>
                            {{$t('message.SettlementAdvanceIndex.button.print')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="hide()">
                            <i class="fa fa-ban mx-2"></i>
                            {{ $t('message.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
export default {
    name: "ModalSelectPrintTemplate",
    
    data(){
        return {
            entries: [],
            content: null,
            entryActive: null,
            status: false,
            dataPrint: null,
            typePrints: null,
        }
    },
    // props: ['typePrints'],
    methods: {
        selectTemplate(entry){
            this.entryActive = entry;
            this.content = entry.print_template_relationship_has_one.content;            
        },
        show(data, dataPrint, typePrints = null) {                   
            this.entries = data;
            this.dataPrint = dataPrint;
            this.entryActive =  data[0];     
            this.typePrints = typePrints;
            this.content = this.entryActive?.print_template_relationship_has_one.content ? this.entryActive.print_template_relationship_has_one.content : null; 
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        confirm(){            
            this.status = true;  
            switch (this.typePrints) {
                case this.$constant.typeInvoice:                    
                    this.$emit('invoiceUpdateContent', {'content': this.content, 'dataPrint': this.dataPrint});    
                    break;            
                default:                    
                    this.$emit('updateContent', {'content': this.content, 'dataPrint': this.dataPrint});
                    break;
            }  
            this.hide();
        },
        
    }
}
</script>

<style scoped>
    .notActive{
        color:#000;
    }
    .notActive:hover {
        background-color: #ddd;
        color: #000;
    }
</style>