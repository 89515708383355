<template>
    <div class="page-loading" v-if="this.loading === true">
        <i class="fa fa-spinner fa-spin"></i>
    </div>
</template>

<script>
import emitter from "@/lib/emitter";
export default {
    name: "LoadingData",
    data(){
        return {
            loading: false,
        }
    },
    created(){
        emitter.on('onLoading',() => {this.loading = true});
        emitter.on('offLoading',() => {this.loading = false});
    },
}
</script>

<style lang="scss" scoped>
@import "../assets/css/vars.scss";
.fa-spin{
    color: $base-color;
    font-size: 40px;
}
.page-loading{
    position: fixed;
    top:35%;
    left:50%;
    opacity: 1;
}
</style>
