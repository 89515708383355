<template>
    <input :disabled="disabled" 
        type="text" 
        class="input-search__voucher" 
        :placeholder="placeholder" 
        :id="'input-search-voucher__' + label" 
        v-model="keyword" 
        @change="onSearchVoucherDetail"/>
</template>

<script>
    import {$alert, $post} from "@/lib/utils";
    export default {
        name: "VoucherDetailSearch",
        components: {},
        props: ['label', 'placeholder', 'disabled', 'voucherIds', 'totalVoucher', 'modelValue'],
        data() {
            return {
                keyword: '',
                voucherDetails: this.modelValue ?? [],
            }
        },
        watch: {
            modelValue: {
                handler: function(newData) {
                    this.voucherDetails = newData;
                },
                deep: true
            }
        },
        methods: {
            async onSearchVoucherDetail(){
                if(!this.keyword){
                    return
                }
                if(this.voucherDetails?.length >= this.totalVoucher){
                    $alert({code: 500, message: this.$t('message.common.maxVoucherDetails', {number: this.totalVoucher})})
                    return
                }
                const res = await $post('/v1/vouchers/search-voucher-details', {
                    voucherIds: this.voucherIds,
                    keyword: this.keyword
                })
                this.keyword = null;
                if(res.result && res.result.code == 200){
                    if(!this.voucherDetails){
                        this.voucherDetails = []
                        this.voucherDetails.push(res.result.data)
                    }else{
                        const findExist = this.voucherDetails.find(v => v.id == res.result.data.id)
                        if(!findExist){
                            this.voucherDetails.push(res.result.data)
                        }
                    }
                    this.onChangeVoucherDetails()
                    return
                }
                if(res.result && res.result.code == 400){
                    $alert({code: 500, message: res.result.message})
                }
            },
            onChangeVoucherDetails(){
                this.$emit('onChangeVoucherDetails', {
                    data: this.voucherDetails,
                    label: this.label
                })
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
.input-search__voucher{
    background-color: transparent;
    outline: none;
    border: none;
    border-bottom: 1px solid #ccc !important;
    padding: 5px 0px;
}
.input-search__voucher:focus{
    border-bottom: 2px solid $base-color !important;
}
</style>
