
<template>
    <div >
        <ActionBar type="edit"
                   icon="fa fa-edit"
                   label="Chỉnh sửa"
                   back-url="/sale-channels/index" @action="edit()"/>
        <div class="main-title">
            <h1>
                Chi tiết kênh bán
            </h1>

        </div>
        <div class="row mt-3">
            <table class="table table-index table-striped">
                <tbody>
                    <tr>
                        <th>Tên kênh bán</th>
                        <td v-text="entry.name"></td>
                    </tr>
                    <tr>
                        <th>{{$t('message.common.status')}}</th>
                        <td v-text="r(entry, 'isActive', 'status')"></td>
                    </tr>
                    <tr>
                        <th>Thumbnail</th>
                        <td>
                            <img v-if="entry.img" :src="entry.img.url" alt="" width="500">
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

    </div>
</template>

<script>
    import {$alert, $get} from "@/lib/utils";
    import ActionBar from "@/components/ActionBar";

    export default {
        name: "CustomerDetail",
        components: {ActionBar},
        mounted() {
            document.title = 'CustomerDetails';
            this.load()
        },
        data() {
            return {
                fields: [],
                entry: {}
            }
        },
        methods: {
            edit() {
                location.href = '/sale_channels/form?id=' + this.entry.id;
            },
            async load() {
                const res = await $get('/v1/sale-channels/show', this.$route.query);
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                this.fields = res.data.fields;
                this.entry = res.data.entry;

            }
        }
    }
</script>
