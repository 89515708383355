<template>
    <button class="btn btn-save h-100" v-on:mouseover="toggleSelectFields = true">
        <i class="fas fa-bars pr-2"></i>
        <i class="fa fa-caret-up" v-if="toggleSelectFields"></i>
        <i class="fa fa-caret-down" v-else></i>
    </button>
    <div class="dropdown-menu dropdown-menu-right d-block mt-0"  v-if="toggleSelectFields && fields.length > 10"  style="max-width: 400px">
        <!-- <div class="col-sm-12 d-flex flex-wrap mt-2 p-0">
            <div v-for="field in fields" :key="field.field" class="col-sm-6">
                <label class="container-checkbox">{{field.name}}
                    <input :id="'selected_' + field.field" type="checkbox" v-model="field.selected"
                    @change="onChangeSelectedFields" class="mr-2 cursor-pointer">
                    <span class="checkmark-checkbox"></span>
                </label>
            </div>
        </div> -->
        <div class="row mt-2 mx-0 w-100">
            <div class="col-6 p-0">
                <div v-for="(field, index) in fields" :key="field.field" class="col-sm-12" style="width: 200px">
                    <template v-if="index < (fields.length / 2)">
                        <label class="container-checkbox">{{field.name}}
                            <input :id="'selected_' + field.field" type="checkbox" v-model="field.selected"
                            @change="onChangeSelectedFields" class="mr-2 cursor-pointer">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </template>
                </div>
            </div>
            <div class="col-6 p-0">
                <div v-for="(field, index) in fields" :key="field.field" class="col-sm-12" style="width: 200px">
                    <template v-if="index >= (fields.length / 2)">
                        <label class="container-checkbox">{{field.name}}
                            <input :id="'selected_' + field.field" type="checkbox" v-model="field.selected"
                            @change="onChangeSelectedFields" class="mr-2 cursor-pointer">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="dropdown-menu dropdown-menu-right d-block mt-0"  v-if="toggleSelectFields && fields.length <= 10"  style="max-width: 200px">
        <div class="col-sm-12 d-flex flex-wrap mt-2 p-0">
            <div v-for="field in fields" :key="field.field" class="col-sm-12">
                <label class="container-checkbox">{{field.name}}
                    <input :id="'selected_' + field.field" type="checkbox" v-model="field.selected"
                    @change="onChangeSelectedFields" class="mr-2 cursor-pointer">
                    <span class="checkmark-checkbox"></span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "../store";
    import emitter from "@/lib/emitter";

    export default {
        name: "SelectField",
        props: ['fields', 'label'],
        data() {
            return {
                toggleSelectFields: false,
                user: store.state.Auth ? store.state.Auth.user : {},
            }
        },
        created(){
            emitter.on('mouseleave',() => this.toggleSelectFields = false)
        },
        methods: {
            onChangeSelectedFields() {
                const label = this.label ? this.label : '';
                localStorage.setItem(label + 'SelectedFields_' + this.user.id, JSON.stringify(this.fields));                             
            }
        }
    }
</script>

<style scoped>

</style>
