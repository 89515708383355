<template>
    <div>
        <h1>Không tìm thấy trang</h1>
    </div>
</template>

<script>
export default {
    name: "PageNotFound",
    mounted() {
        document.title = 'Không tìm thấy trang';
    }
}
</script>

<style scoped>

</style>
