import emitter from "@/lib/emitter";
export default {
    methods: {
        returnDataExport (res) {
            const fileName = res.headers['content-disposition'].split('filename=')[1];
            var _blob = new Blob([res.data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();
            emitter.emit("offLoading");
        }
    }

}
