
<template>
    <AppPermission :permission="this.$permissions.units.data">
        <div class="container my-4">
            <div class="row m-0 p-0">
            <div class="mainLeft">
            <!-- <div class="col-md-2" id="col2"> -->
                <div class="main-title">
                <span>{{ $t('message.UnitIndex.title')}}</span>
                </div>
                <div mt-4>
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row mx-2">
                    <div class="col-8 text-title">
                        <label>{{ $t('message.common.numberRecord') }}:</label>
                    </div>
                    <div class="col-4">
                        <select :value="numberRecord" style="border: none;outline: none;" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        </select>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="mainRight">
            <!-- <div class="col-md-10" id="col10"> -->
                <div class="sticky-top">
                    <div class="row">
                        <div class="col-5">
                        <div class="search-box">
                            <i class="fa fa-search mr-2"></i>
                            <input type="text" class="search-input"
                                @keydown.enter="doFilter('keyword', filter.keyword)"
                                v-model="filter.keyword" :placeholder="$t('message.UnitIndex.placeholder.search')"/>
                        </div>
                        </div>
                        <div class="col-7 d-flex justify-content-end">
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{ $t('message.button.removeFilter') }}
                            </button>                        
                            <AppPermission :permission="this.$permissions.units.create">
                                <button @click="showModalUnitAdd()" class="btn  btn-save ml-1">
                                    <i class="fa fa-plus"/>
                                    {{$t('message.UnitIndex.button.add')}}
                                </button>
                            </AppPermission>
                        
                        </div>
                    </div>
                    <div>
                        <Paginate :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mx-0 my-2" id="divTable">
                        <table class="table table-index table-striped w-100">
                            <thead>
                                <tr class="w-100">
                                    <th scope="col" class="pl-5" v-for="field in fields" :key="field.field" :class="field.field == 'id' ? 'cell-check' : ''">
                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort" :name="field.name" :field="field.field" />
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="entries?.length > 0">
                                    <template v-for="(entry, index) in entries" :key="entry.id">                                                    
                                        <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :id="'row_' + (index + 1)" 
                                        @click.stop="this.hasPermission(this.$permissions.units.update) || this.hasPermission(this.$permissions.units.remove) ? showModalUnitEdit(entry.id) : ''">
                                            <td scope="col" class="pl-5" v-for="field in fields" :key="field.field" v-text="r(entry, field.field, field.render)" :class="field.field == 'id' ? 'cell-check' : ''"></td>
                                            <th class="text-right">
                                                <AppPermission :permission="this.$permissions.units.update">
                                                    <button class="btn" @click.stop="showModalUnitEdit(entry.id)">
                                                        <i class="fa fa-edit"/>
                                                    </button>
                                                </AppPermission>
                                                <AppPermission :permission="this.$permissions.units.remove">
                                                    <button class="btn text-danger" @click.stop="removeUnit(entry.id)">
                                                        <i class="fa fa-trash-alt"/>
                                                    </button>
                                                </AppPermission>
                                            </th>
                                        </tr>
                                    </template>
                                </template>
                                <template v-if="entries.length == 0 && !this.loading">
                                    <tr>
                                        <td :colspan="this.fields.length + 1" class="pb-0 bg-light">
                                            <div class="row detail-prd-row mx-0">
                                                <div class="col-12 text-center text-secondary mt-5">
                                                    <h3>
                                                        <i class="fa fa-file mr-2 text-secondary"></i>
                                                        <i class="fa fa-exclamation"></i>
                                                    </h3>
                                                </div>
                                                <div class="col-12 text-center my-10 text-secondary mb-5">
                                                    <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                    <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        <ModalUnitRemove ref="ModalUnitRemove" @inputData="load()"/>
        <ModalUnitAdd ref="ModalUnitAdd" @inputData="load()" :typeEditAdd="typeEditAdd" :isFirstModal="true"/>
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import TableSortField from "@/components/TableSortField";
    import ModalUnitAdd from "./tabs/ModalUnitAdd.vue";
    import ModalUnitRemove from "./tabs/ModalUnitRemove.vue";
    import emitter from "@/lib/emitter";    
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    export default {
  created () {
  },
        name: "UnitIndex",
        mixins: [permissionMixin],
        components: {TableSortField, Paginate, ModalUnitAdd, ModalUnitRemove, AppPermission},
        mounted() {
            document.title = this.$t('message.UnitIndex.document_title');
            const query = this.$route.query;
            this.filter = {...this.filter, ...query};
            this.paginate.currentPage = this.$route.query.page || 1;
            this.load()
        },
        data() {
            return {
                filter: {
                    keyword: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                numberRecord: '15',
                typeEditAdd: "ADD",
                idUnit: null,
                isFilter: false,
            }
        },
        methods: {
            showModalUnitAdd(){
                this.typeEditAdd = "ADD";
                this.idUnit = null;
                this.$refs.ModalUnitAdd.show();
            },
            showModalUnitEdit(id){
                this.typeEditAdd = "EDIT";
                this.idUnit = id;
                this.$refs.ModalUnitAdd.show(id);
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/units/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.sortData).forEach(key => {
                    this.sortData[key] = '';
                });
                this.numberRecord = 15;
                this.isFilter = false;
                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                query['page'] = 1;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            async load() {
                emitter.emit("onLoading");
                const res = await $get('/v1/units/data', {...this.$route.query, numberRecord: this.numberRecord});
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                setArray(res.data.entries, {
                    checked: false
                });

                this.fields = res.data.fields;
                this.paginate = res.data.paginate;
                this.entries = res.data.entries;
                emitter.emit("offLoading");
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                query.numberRecord = v.target.value;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            removeUnit(id){
                this.$refs.ModalUnitRemove.show(id);
            },
        }
    }
</script>
<style scoped>
tbody {
    /* display: block;
    max-height: 600px;
    overflow: auto; */
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cell-check{
    width: 100px !important;
    min-width: 100px;
    max-width: 100px;
}
</style>