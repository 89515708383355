
<template>
    <AppPermission :permission="this.$permissions.priceDetails.data?? '' ">
        <div class="container my-4">
            <div class="row">
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{$t('message.PriceListDetailIndex.title')}}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8 text-title">
                                    <label>{{$t('message.common.numberRecord')}}:</label>
                                </div>
                                <div class="col-4">
                                    <select v-model="numberRecord" name="number_records" id="number_records" class="number_records">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Bảng giá -->
                    <div class="card " id="typeCard">
                        <div class="card-header mx-0 px-0 py-2" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{$t('message.PriceListDetailIndex.priceList')}} </label>
                                </div>
                                <div class="col-1 pl-0 ml-0">
                                    <AppPermission :permission="this.$permissions.settingPrice.create ?? ''">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showModalCreateEditPriceList">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                        </a>
                                    </AppPermission>
                                </div>
                                <div class="col-3">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('priceListBody', 'iconDown', 'iconUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="iconDown"></i>
                                        <i class="fa fa-chevron-up" id="iconUp"></i>
                                    </a>
                                </div>
                            </div>
                            <!-- Chọn bảng giá -->
                            <div class="row mx-2 my-2 py-2" id="priceListBody">
                                <div class="col-12">                                   
                                    <q-select v-model="priceList.search" :modelValue="priceList.search" :default-options="priceList.filter" :multiple="true" :placeholder="$t('message.PriceListDetailIndex.choosePriceList')" 
                                    @update:modelValue="updateFields()" @updateItem="showModalEditPriceList" class="border-bottom-1" 
                                    :isFilter="true" :isPermission="this.hasPermission(this.$permissions.settingPrice.update) || this.hasPermission(this.$permissions.settingPrice.remove) || this.currentUser.id == this.$constant.isAdmin"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Nhóm hàng -->
                    <div class="card mt-4" id="GrpProduct">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">
                                        {{$t('message.PriceListDetailIndex.categories')}}
                                    </label>
                                </div>
                                <div class="col-1 pl-0 ml-0">

                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('prdGroup','gDown','gUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="gDown"></i>
                                        <i class="fa fa-chevron-up" id="gUp"></i>
                                    </a>
                                </div>
                            </div>
                            <!-- Tìm kiếm -->
                            <div class="row mx-2 my-2 py-2 ">
                                <div class="col-12">
                                    <div class="prd-search-box">
                                        <i class="fa fa-search mr-2 text-secondary"></i>
                                        <input type="text" class="filter-search-input w-100" v-model="categories.search" @input="onInputFilterCategories" :placeholder="$t('message.PriceListDetailIndex.searchCategories')" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-0" id="prdGroup">
                            <!-- Danh sách -->
                            <div class="row my-2">
                                <template v-if="categories.filter && categories.filter.length">
                                    <div class="col-12 py-2 bg-white">
                                        <span :class="{'font-weight-bold': categories.filter.length == 0}" @click="handleCheckCategories(null)" >
                                            {{$t('message.PriceListDetailIndex.all')}}
                                        </span>
                                    </div>
                                    <div class="col-12 py-2 bg-white" v-for="category in categories.filter" :key="category.id">
                                        <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = category.id" v-on:mouseleave="isEdit = null">
                                            <div :class="this.searchSidebar.categoryId === category.id ? 'text-bold' : ''">
                                                <i v-if="category.children.length" :class="{
                                                        'fa':true,
                                                        'fa-plus': !choosedParentCategories.includes(category.id),
                                                        'px-1':true,
                                                        'fa-minus': choosedParentCategories.includes(category.id),
                                                    }"
                                                    @click="showHideChildrenCategories(category.id)"
                                                />
                                                <span class="px-2 my-0" :for="`key${category.id}`" @click="handleCheckCategories(category.id, category?.name)">{{category.name}}</span>
                                            </div>
                                            <!-- <i class="fa fa-pencil-alt" v-if="category?.id === isEdit" @click="showModalCategoryEdit(category.id)"></i> -->
                                        </div>
                                        <div v-if="choosedParentCategories.includes(category.id)" class="child-category-container">
                                            <div  v-for="child in category?.children" :key="child?.id" :ref="`${child?.id}`" :id="`key${category.id}`" >
                                                <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = child?.id" v-on:mouseleave="isEdit = null">
                                                    <div :class="this.searchSidebar.categoryId === child.id ? 'text-bold' : ''">
                                                        <i v-if="child.children && child.children.length" :class="{
                                                                'fa':true,
                                                                'fa-plus': !choosedParentCategories.includes(child?.id),
                                                                'px-1':true,
                                                                'fa-minus': choosedParentCategories.includes(child?.id),
                                                            }"
                                                            @click="showHideChildrenCategories(child.id)"
                                                        />
                                                        <!-- <span class="px-2 my-0" :for="`key${child?.id}`" :class="{'font-weight-bold': filterCategories.includes(child?.id)}" @click="handleCheckCategories(child?.id)">{{child?.name}}</span> -->
                                                        <span class="px-2 my-0" @click="handleCheckCategories(child?.id, child?.name)">{{child?.name}}</span>

                                                    </div>
                                                    <i class="fa fa-pencil-alt" v-if="child?.id === isEdit" @click="showModalCategoryEdit(child?.id)"></i>
                                                </div>
                                                <div v-if="choosedParentCategories.includes(child?.id)" class="child-category-container">
                                                    <div v-for="child2 in child?.children" :key="child2.id">
                                                        <div class="d-flex flex-row justify-content-lg-between" v-on:mouseover="isEdit = child2?.id" v-on:mouseleave="isEdit = null" :class="this.searchSidebar.categoryId === child2.id ? 'text-bold' : ''">
                                                            <!-- <p class="px-2 my-0" :for="`key${child2?.id}`" :class="{'font-weight-bold': filterCategories.includes(child2?.id)}" @click="handleCheckCategories(child2?.id)">{{child2?.name}}</p> -->
                                                            <p class="px-2 my-0" @click="handleCheckCategories(child2?.id, child2?.name)">{{child2?.name}}</p>
                                                            <!-- <i class="fa fa-pencil-alt" v-if="child2?.id === isEdit" @click="showModalCategoryEdit(child2?.id)"></i> -->
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>

                    <!-- giá bán -->
                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.ProductIndex.price')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('sBody', 'sDown', 'sUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="sDown"></i>
                                        <i class="fa fa-chevron-up" id="sUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="sBody">
                            <div class="row">
                                <div class="col-4">
                                    <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <template v-for="optCal in this.optionCalculator" :key="optCal.id">
                                            <span v-if="optCal.selected === true" v-text="optCal.label"></span>
                                        </template>
                                    </div>
                                    <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="javascript:;" v-for="optCal in this.optionCalculator" :key="optCal.id" :value="optCal.id" @click="handleChooseOptionCalculator(optCal.id)">
                                            {{optCal.label}}<i class="fa fa-check" v-if="optCal.selected === true"></i>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-8">
                                    <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <template v-for="cPrice in this.comparePrice" :key="cPrice.id">
                                            <span v-if="cPrice.selected === true" v-text="cPrice.label"></span>
                                        </template>
                                    </div>
                                    <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="javascript:;" v-for="cPrice in this.comparePrice" :key="cPrice.id" :value="cPrice.id" @click="handleChooseComparePrice(cPrice.id)">
                                            {{cPrice.label}}<i class="fa fa-check" v-if="cPrice.selected === true"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
        
                </div>
                <div class="mainRight">
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-5">
                                <div class="search-box">
                                    <i class="fa fa-search mr-2"></i>
                                    <input type="text" class="search-input" :placeholder="$t('message.PriceListDetailIndex.search')" id="search-header" @keydown.enter="searchHeadProduct($event)"/>
                                </div>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                                <!-- Xóa lọc -->
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <!-- Thêm mới -->
                                <!-- <AppPermission :permission="this.$permissions.priceDetails.create ?? ''">
                                    <button type="button" class="btn btn-save" @click="showModalCreateEditPriceList">
                                        <i class="fas fa-plus"></i>
                                        {{ $t('message.PriceListsIndex.button.create') }}
                                    </button>
                                </AppPermission> -->
                                <AppPermission :permission="this.$constant.PriceDetailsFilePermission">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton" style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-2"></i>
                                            {{ $t('message.PriceListsIndex.button.file') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <AppPermission :permission="this.$permissions.priceDetails.export?? '' ">
                                                <a class="dropdown-item" @click="importDetailPriceLists">
                                                    <i class="fas fa-file-import"></i>
                                                    {{ $t('message.PriceListsIndex.button.import') }}
                                                </a>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.priceDetails.export?? '' ">
                                                <a class="dropdown-item" @click="selectOptionExport('exportExcel')">
                                                    <i class="fas fa-file-export"></i>
                                                    {{ $t('message.PriceListsIndex.button.export') }}
                                                </a>
                                            </AppPermission>
                                        </div>
                                    </div>`
                                </AppPermission>
                                <div v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="settingPrice"></SelectField>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="numberRecord" @change="pageChange" />
                        </div>

                        <div class="row my-2">
                            <div class="col-sm-10" style="align-self:center" v-if="this.count >= 1">
                                {{ $t('message.common.have') }} 
                                <a href="#" v-text="count + ' ' + $t('message.common.product') " id="lnkCount" @click="showModalProductsChecked()"></a> 
                                {{ $t('message.common.selected') }}
                                <a href="javascript:;" @click="unChecked('prdChecked')">
                                    <i class="fa fa-times-circle text-danger" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="row mx-0 my-2" v-if="products && products.length" id="divTable">
                            <table class="table table-index table-striped lstProduct" v-if="!noField">
                                <thead>
                                    <tr>
                                        <th style="min-width:40px;" scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox" id="chkAll" /></th>
                                        <th scope="col" style="vertical-align: middle !important; white-space: nowrap; text-overflow: ellipsis;" v-for="(item, index) in fields" :key="index" v-show="item.selected">
                                            <TableSortField :current="sortData.field" :no-sort="!item.noSort" @onsort="sort" :name="item.name" :field="item.field" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(product, key) in products" :key="key" >
                                        <tr class="master-row" :style="key % 2 ? 'background-color: white' : null" :id="'row_' + (key + 1)" >                                
                                            <td><input type="checkbox"></td>
                                            <td v-show="(fields.find(item => item.field === 'code')).selected">
                                                <div class="d-flex justify-content-center">
                                                    <span class="description">{{ product.code }}</span>
                                                    <i class="fa fa-chevron-up" id="typeUp" v-if="product.id == detailForProductId" @click="closeProductActive(product.id)"></i>
                                                    <i class="fa fa-chevron-down" id="typeDown" v-else @click="chooseProductActive(product.id)"></i>
                                                </div>
                                            </td>
                                            <td v-show="(fields.find(item => item.field === 'name')).selected"><span class="description">{{ product.name }}</span></td>
                                            <td v-show="(fields.find(item => item.field === 'unitId')).selected">
                                                <span class="description" v-if="product.product_units && product.product_units.length">{{ product.product_units[0].unitName }}</span>
                                            </td>
                                            <td v-show="(fields.find(item => item.field === 'inventories')).selected"><span class="description">{{ product.product_branch_meta[0] ? numberFloat(product.product_branch_meta[0].inventories) : '' }}</span></td>
                                            <td v-show="(fields.find(item => item.field === 'LastEntryPrice')).selected"><span class="description">{{ $filters.formatPrice(product.price_import[0] ? (product.price_import[0].price - product.price_import[0].discount/product.price_import[0].quantity) : 0) }}</span></td>
                                            <!-- <td v-show="(fields.find(item => item.field === 'LastEntryPrice')).selected"><span class="description">{{ $filters.formatPrice(product.order_for_supplier_detail[0]?.price) }}</span></td> -->

                                            <td v-show="(fields.find(item => item.field === 'price')).selected">
                                                <span class="description" v-if="product.product_units && product.product_units.length">{{ $filters.formatPrice(product.product_units[0].price) }}</span>
                                            </td>
                                            <template v-for="(field, i) in fields" :key="i">
                                                <td v-if="field.id && (fields.find(item => item.id === field.id).selected)" class="position-relative">
                                                    <template v-if="product.product_unit_main">                                            
                                                        <template v-if="(product.product_units[0].product_unit_price_lists.findIndex(item => item.priceListId == field.id)) > -1">
                                                            <template v-for="(item, index) in product.product_units[0].product_unit_price_lists" :key="index">
                                                                <!-- Sửa giá -->
                                                                <div class="input__price text-right border-none" v-if="field.id == item.priceListId && item.price != null" @click.stop="showUpdatePriceModal(item, field.id, product.product_units[0].id)">                                                                   
                                                                    <InputNumber :id="item.id + '_' + field.id + '_' + item.productUnitId" v-model="item.price" 
                                                                        @changeData="updatePriceList(product.id, field.id, item.productUnitId, item.id, item.price)" 
                                                                        class="mb-0" :formatNumber="true" :settingPrice="true" 
                                                                        :readonly="!this.hasPermission(this.$permissions.priceDetails.update) && this.currentUser.id != this.$constant.isAdmin"
                                                                        :active="this.updatePriceModal == item.id && this.updatePriceField == field.id ? true : false"
                                                                    />
                                                                </div>
                                                                <i v-else-if="field.id == item.priceListId" class="fa fa-plus-circle fa-md text-secondary" @click.stop="clonePrice(field.id, products, product.id, field.name, product.product_units[0].id)"></i>    
                                                                <ModalUpdatePrice v-if="product.product_units[0].id == productIdActiveUpdate && this.updatePriceModal == item.id && field.id == this.updatePriceField" @getProducts="getProducts" :searchSidebar="searchSidebar" :item="item" :field="field" :productId="product.id" :productData="product" :updatePriceListsData="updatePriceLists" :priceUpdatedData="priceUpdated" @hideFormUpdate="hideFormUpdate"/>
                                                                
                                                                <!-- <div class="row mx-4 my-4 mt-1 popup-update-price" 
                                                                        v-if="(this.updatePriceModal == item.id && this.updatePriceField == field.id) && (this.hasPermission(this.$permissions.priceDetails.update) || this.currentUser.id == this.$constant.isAdmin)" 
                                                                        v-click-outside="closeUpdatePriceModal">
                                                                    <div class="col-3 p-0 d-flex align-items-center">
                                                                        <span class="mb-0 ow-break-word">{{$t('message.ProductIndex.price')}}<span class="text-omi">{{'[' + n(+this.priceUpdated?? 0) + ']'}}</span> =</span> 
                                                                    </div>
                                                                    <div class="col-3 p-0">
                                                                        <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                            <template v-for="updatePriceLists in this.updatePriceLists.all" :key="updatePriceLists.id">
                                                                                <span v-if="updatePriceLists.id === this.updatePriceLists.selected" v-text="updatePriceLists.name"></span>
                                                                            </template>
                                                                        </div>
                                                                        <div class="dropdown-menu options-dt" aria-labelledby="dropdownMenuButton">
                                                                            <input type="text" class="min-w-100" v-model="this.updatePriceLists.search" @input="onInputPriceList">
                                                                            <i class="fa fa-search btn-search"></i>
                                                                            <template v-if="this.updatePriceLists.filter?.length > 0">
                                                                                <a class="dropdown-item pl-0" href="javascript:;" v-for="updatePriceLists in this.updatePriceLists.filter" :key="updatePriceLists.id" :value="updatePriceLists.id" @click="choosePriceList(updatePriceLists, item, product)">
                                                                                    {{updatePriceLists.name}}<i class="fa fa-check" v-if="this.updatePriceLists.selected == updatePriceLists.id"></i>
                                                                                </a>
                                                                            </template>
                                                                            <template v-else>
                                                                                <span class="ow-break-word" href="javascript:;">
                                                                                    Không có kết quả phù hợp
                                                                                </span>
                                                                            </template>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-2 p-0 d-flex align-items-center">
                                                                        <button type="button" class="btn btn-secondary ml-2 text-white" 
                                                                        v-for="opt in this.$constant.calculatorOptions" :key="opt.id" :disabled="this.updatePriceLists.selected === 0"
                                                                        :class="this.updatePriceLists.selected != 0 && this.calculatorOption == opt.id ? 'btn btn-save' : ''"
                                                                        @click="changeCalculatorOption(opt.id)">{{opt.name}}</button> 
                                                                    </div>
                                                                    <div class="col-2 p-0 d-flex align-items-center">
                                                                        <input type="text" :maxlength="maxInputPrice" class="input-custom min-w-100 h-100 text-right bg-transparent"
                                                                        @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"
                                                                        :min="0" :disabled="this.updatePriceLists.selected === 0" v-model="this.calcPriceType" @input="onInputPriceUpdate()"> 
                                                                    </div>
                                                                    <div class="col-2 p-0 d-flex align-items-center">
                                                                        <button type="button" class="btn btn-secondary ml-2 text-white" 
                                                                        v-for="opt in this.$constant.discountOptions" :key="opt.id" 
                                                                        :disabled="this.updatePriceLists.selected === 0" :class="this.updatePriceLists.selected != 0 && this.discountOption == opt.id ? 'btn btn-save' : ''"
                                                                        @click="changeDiscountOption(opt.id)">{{opt.name}}</button> 
                                                                    </div>
                                                                    <div class="col-12 pl-0 mt-4 d-flex align-items-center" v-if="this.updatePriceLists.selected != 0">
                                                                        <input type="checkbox" class="chkType" v-model="this.applyAll" id="applyAll">
                                                                        <label class="px-2 my-0" for="applyAll">Áp dụng công thức cho các sản phẩm đã có giá trong bảng giá?</label>
                                                                    </div>
                                                                    <div class="col-12 mt-4 flex-end">
                                                                        <button type="button" class="btn btn-save mr-2" @click="updatePriceList(product.id, field.id, item.productUnitId, item.id)">
                                                                            <i class="fa fa-save mr-1"></i>
                                                                            Lưu
                                                                        </button>
                                                                        <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeUpdatePriceModal()">
                                                                            <i class="fa fa-ban mr-1"></i>
                                                                            Bỏ qua
                                                                        </button>
                                                                    </div>
                                                                </div> -->
                                                            </template>
                                                        </template>
                                                        <template v-else>
                                                            <AppPermission :permission="this.$permissions.priceDetails.update">
                                                                <i class="fa fa-plus-circle fa-md text-secondary" @click="clonePrice(field.id, products, product.id, field.name, product.product_units[0].id)"></i>
                                                            </AppPermission>
                                                        </template>
                                                    </template>
                                                    <template v-else>
                                                        <AppPermission :permission="this.$permissions.priceDetails.update">
                                                            <i class="fa fa-plus-circle fa-md text-secondary" @click="clonePrice(field.id, products, product.id, field.name, product.product_units[0].id)"></i>
                                                        </AppPermission>
                                                    </template>
                                                </td>
                                            </template>
                                        </tr>
                                        <template v-if="product.id == detailForProductId">
                                            <template v-for="(productUnit, key) in product.product_units" :key="productUnit.id">
                                                <template v-if="productUnit.type == $constant.type_variant">
                                                    <template v-for="(variant) in productUnit.product_unit_variants" :key="variant.id">
                                                        <tr class="master-row" :style="key % 2 ? 'background-color: white' : null" :id="'row_' + (key + 1)">                                
                                                            <td><input type="checkbox"></td>
                                                            <td v-show="(fields.find(item => item.field === 'code')).selected"><span class="description"></span></td>
                                                            <td v-show="(fields.find(item => item.field === 'name')).selected"><span class="description product-name">{{ product.name + ' ' + variant.name }}</span></td>
                                                            <td v-show="(fields.find(item => item.field === 'unitId')).selected">
                                                                <span class="description" v-if="product.product_units && product.product_units.length">{{ variant.unitName }}</span>
                                                            </td>
                                                            <td v-show="(fields.find(item => item.field === 'inventories')).selected"><span class="description">0</span></td>
                                                            <td v-show="(fields.find(item => item.field === 'LastEntryPrice')).selected"><span class="description">{{ $filters.formatPrice(variant.price) }}</span></td>
                                                            <td v-show="(fields.find(item => item.field === 'price')).selected">
                                                                <span class="description" v-if="product.product_units && product.product_units.length">{{ $filters.formatPrice(variant.price) }}</span>
                                                            </td>
                                                            <template v-for="(field, i) in fields" :key="i">
                                                                <td v-if="field.id && (fields.find(item => item.id === field.id).selected)" class="position-relative">
                                                                    <template v-if="(variant.product_unit_price_lists.findIndex(item => item.priceListId == field.id)) > -1">
                                                                        <template v-for="(item, index) in variant.product_unit_price_lists" :key="index">
                                                                            <div v-if="field.id == item.priceListId && item.price != null" class="input__price text-right border-none" @click.stop="showUpdatePriceModal(item, field.id, variant.id)">                                                                   
                                                                                <InputNumber :id="item.id + '_' + field.id + '_' + item.productUnitId" v-model="item.price" 
                                                                                    @changeData="updatePriceList(product.id, field.id, item.productUnitId, item.id, item.price)" 
                                                                                    class="mb-0" :formatNumber="true" :settingPrice="true" 
                                                                                    :readonly="!this.hasPermission(this.$permissions.priceDetails.update) && this.currentUser.id != this.$constant.isAdmin"
                                                                                    :active="this.updatePriceModal == item.id && this.updatePriceField == field.id ? true : false"
                                                                                />
                                                                            </div>
                                                                            <i v-else-if="field.id == item.priceListId" class="fa fa-plus-circle fa-md text-secondary" @click.stop="clonePrice(field.id, products, product.id, field.name, variant.id)"></i>                                                                                                                          
                                                                            <ModalUpdatePrice  v-if="variant.id == productIdActiveUpdate && this.updatePriceModal == item.id && field.id == this.updatePriceField" @getProducts="getProducts" :productId="product.id" :searchSidebar="searchSidebar" :item="item" :field="field" :productData="variant" :updatePriceListsData="updatePriceLists" :priceUpdatedData="priceUpdated" @hideFormUpdate="hideFormUpdate"/>
                                                                        </template>
                                                                    </template>
                                                                        <template v-else>
                                                                            <AppPermission :permission="this.$permissions.priceDetails.update">
                                                                                <i class="fa fa-plus-circle fa-md text-secondary" @click="clonePrice(field.id, products, product.id, field.name, variant.id)"></i>
                                                                            </AppPermission>
                                                                        </template>
                                                                    <!-- <template v-else>
                                                                        <AppPermission :permission="this.$permissions.priceDetails.update">
                                                                            <i class="fa fa-plus-circle fa-md text-secondary" @click="clonePrice(field.id, products, product.id, field.name, variant.id)"></i>
                                                                        </AppPermission>
                                                                    </template> -->
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </template>
                                                </template>
                                                <template v-else-if="!productUnit.type && key">
                                                    <tr class="master-row" :style="key % 2 ? 'background-color: white' : null" :id="'row_' + (key + 1)">                                
                                                        <td><input type="checkbox"></td>
                                                        <td v-show="(fields.find(item => item.field === 'code')).selected"><span class="description"></span></td>
                                                        <td v-show="(fields.find(item => item.field === 'name')).selected"><span class="description product-name">{{ product.name + ' ' + productUnit.unitName }}</span></td>
                                                        <td v-show="(fields.find(item => item.field === 'unitId')).selected">
                                                            <span class="description" v-if="product.product_units && product.product_units.length">{{ productUnit.unitName }}</span>
                                                        </td>
                                                        <td v-show="(fields.find(item => item.field === 'inventories')).selected"><span class="description">Ơ</span></td>
                                                        <td v-show="(fields.find(item => item.field === 'LastEntryPrice')).selected"><span class="description">{{ $filters.formatPrice(productUnit.price) }}</span></td>
                                                        <td v-show="(fields.find(item => item.field === 'price')).selected">
                                                            <span class="description" v-if="product.product_units && product.product_units.length">{{ $filters.formatPrice(productUnit.price) }}</span>
                                                        </td>
                                                        <template v-for="(field, i) in fields" :key="i">
                                                            <td v-if="field.id && (fields.find(item => item.id === field.id).selected)" class="position-relative">
                                                                <template v-if="(productUnit.product_unit_price_lists.findIndex(item => item.priceListId == field.id)) > -1">
                                                                    <template v-for="(item, index) in productUnit.product_unit_price_lists" :key="index">
                                                                        <div v-if="field.id == item.priceListId && item.price != null" class="input__price text-right border-none"
                                                                            @click.stop="showUpdatePriceModal(item, field.id, productUnit.id)">                                                                   
                                                                            <InputNumber :id="item.id + '_' + field.id + '_' + item.productUnitId" v-model="item.price" 
                                                                            @changeData="updatePriceList(productUnit.productId, field.id, item.productUnitId, item.id, item.price)" 
                                                                            class="mb-0" :formatNumber="true" :settingPrice="true" 
                                                                            :readonly="!this.hasPermission(this.$permissions.priceDetails.update) && this.currentUser.id != this.$constant.isAdmin"
                                                                            :active="this.updatePriceModal == item.id && this.updatePriceField == field.id ? true : false"/>
                                                                        </div>
                                                                        <i v-else-if="field.id == item.priceListId" class="fa fa-plus-circle fa-md text-secondary" @click.stop="clonePrice(field.id, productUnit, product.id, field.name, productUnit.id)"></i>                                                                                                                         
                                                                        <ModalUpdatePrice  v-if="productUnit.id == productIdActiveUpdate && this.updatePriceModal == item.id && field.id == this.updatePriceField" 
                                                                            @getProducts="getProducts" 
                                                                            :productId="product.id" 
                                                                            :searchSidebar="searchSidebar" 
                                                                            :item="item" :field="field" 
                                                                            :productData="variant" 
                                                                            :updatePriceListsData="updatePriceLists" 
                                                                            :priceUpdatedData="priceUpdated" @hideFormUpdate="hideFormUpdate"
                                                                        />
                                                                    </template>
                                                                </template>
                                                                <template v-else>
                                                                    <AppPermission :permission="this.$permissions.priceDetails.update">
                                                                        <i class="fa fa-plus-circle fa-md text-secondary" @click="clonePrice(field.id, productUnit, product.id, field.name, productUnit.id)"></i>
                                                                    </AppPermission>
                                                                </template>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <div v-if="noField" class="col-sm-12 text-center my-10" id="notFound">
                                <p> {{ $t('message.common.notData') }}</p>
                            </div>
                        </div>
                        <div class="row" v-else id="notFound">
                            <div v-if="!products" class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <p>{{ $t('message.common.notData') }}</p>
                            </div>
                            <div v-else class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <p>{{ $t('message.common.notFound') }}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalCreateEditPriceList ref="ModalCreateEditPriceList" @updated="getPriceList" @delete="removeColum"/>
        <ModalConfirmClonePrice ref="ModalConfirmClonePrice" @updated="getProducts" />
        <ImportDetailPriceLists ref="ImportDetailPriceLists" @reload="getPriceList"/>
        <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
    </AppPermission>
</template>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.img-container {
    display: flex;
    flex-direction: row;
    gap: 1rem
}

.img-list {
    display: flex;
    flex-direction: column;
}

.img-main-item {
    width: 15vw;
    object-fit: cover;
}

.text-title {
    line-height: 52px;
}

#number_records {
    margin-top: 11px;
}

#typeCard {
    margin-top: -8px;
}

.child-category-container {
    margin-left: 1rem
}

.child-category-item {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem
}
// .dropdown-toggle::after {
//     position: absolute;
//     right: 15px;
//     top: 12px;
// }
.dropdown-item> .fa-check{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
// .dropdown-toggle{
//   white-space: normal;
// }
.dropdown-item{
  white-space: normal;
  overflow-wrap: break-word;
}
.input__price{
    border: none;
    background: no-repeat;
    border-bottom: 1px solid #C7C5C5;
}
.input__price-focus{
    border-bottom: 2px solid $base-color;
}
.popup-update-price{
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 99;
    width: 585px;
    background: white;
    box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
    padding: 1.4rem;
    border: none;
    border-radius: 7px;
}
#divTable {
    min-height: 260px;
}
.border-bottom-focus:hover{
    border-bottom: 2px solid $base-color !important;
}
.table-index tbody td{
    padding: 10px !important;
}
</style>
<script>
import { $get, $post, $alert, removeVietnameseTones, cloneObject } from "@/lib/utils";
import TableSortField from "@/components/TableSortField";
import Pagination from "@/components/Pagination";
import emitter from "@/lib/emitter";
import SelectField from "../../components/SelectField";
import ModalCreateEditPriceList from './ModalCreateEditPriceList.vue';
import ModalConfirmClonePrice from './ModalConfirmClonePrice.vue';
import QSelect from "@/components/QSelect";
import currencyMixin from "@/currencyMixin";
import http from '@/services/api';
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from '@/permissionMixin';
import checkPermissionMixin from '@/checkPermissionMixin';
import store from "@/store";
import InputNumber from "@/components/forms/InputNumberCustom";
import ImportDetailPriceLists from './ImportDetailPriceLists.vue';
import ModalUpdatePrice from "@/components/ModalUpdatePrice";
import exportMixin from '@/exportMixin';
import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
export default {
    name: "SettingPriceIndex",
    mixins: [currencyMixin, permissionMixin, checkPermissionMixin, exportMixin],
    components: {
        TableSortField,
        Pagination,
        SelectField,
        ModalCreateEditPriceList,
        ModalConfirmClonePrice,
        QSelect,
        AppPermission,
        InputNumber,
        ImportDetailPriceLists,
        ModalUpdatePrice,
        ModalSelectOptionExport
    },
    mounted() {
        document.title = this.$t('message.PriceListDetailIndex.documentTitle');
        window.addEventListener('scroll', this.onScroll);
    },
    computed: {
        permissionInfo: () => store.state.PermissionInfo,   
        authData: () => store.state.Auth
    },
    data() {
        const fields = [
            {
                name: this.$t('message.PriceListDetailIndex.productCode'),
                field: 'code',
                // noSort: true,
                selected: true,
            },
            {
                name: this.$t('message.PriceListDetailIndex.productName'),
                field: 'name',
                // noSort: true,
                selected: true,
            },
            {
                name: this.$t('message.PriceListDetailIndex.unitName'),
                field: 'unitId',
                // noSort: false,
                selected: true,
            },
            {
                name: this.$t('message.PriceListDetailIndex.inventories'),
                field: 'inventories',
                // noSort: true,
                selected: true,
            },
            {
                name: this.$t('message.PriceListDetailIndex.lastEntryPrice'),
                field: 'LastEntryPrice',
                // noSort: false,
                selected: true,
            },
            {
                name: this.$t('message.PriceListDetailIndex.price'),
                field: 'price',
                // noSort: false,
                selected: true,
            },
        ];
        return {        
            fields: fields,
            defaultFields: fields,
            sortData: {
                field: null,
                direction: null,
            },
            products: null,
            paginate: {
                totalRecord: null,
                currentPage: null,
                lastPage: null,
            },
            searchHead: null,
            numberRecord: 15,
            units: [],
            noField: false,
            choosedParentCategories: [],
            categories: {
                all: null,
                search: null,
                filter: null,
            },
            searchSidebar: {
                categoryId: null
            },
            priceList: {
                all: null,
                search: [],
                filter: null,
            },
            priceListId: [],
            optionCalculator: [
                {id: 0, label: '--', value: null, selected: true},
                {id: 1, label: '<', value: '<', selected: false},
                {id: 2, label: '<=', value: '<=', selected: false},
                {id: 3, label: '=', value: '==', selected: false},
                {id: 4, label: '>', value: '>', selected: false},
                {id: 5, label: '>=', value: '>=', selected: false},
            ],
            comparePrice: [
                {id: 0, label: this.$t('message.PriceListDetailIndex.comparePrice'), value: null, selected: true},
                {id: 1, label: this.$t('message.PriceListDetailIndex.costPrice'), value: 'costPrice', selected: false},
                {id: 2, label: this.$t('message.PriceListDetailIndex.lastEntryPrice'), value: 'lastEntryPrice', selected: false},
            ],
            updatePriceModal: null,
            updatePriceField: null,
            updatePriceLists: {
                selected: 0,
                search: null,
                filter: this.$constant.priceListOptions,
                all: this.$constant.priceListOptions,
            },
            priceUpdated: null,
            priceUpdateDefault: null,
            calculatorOption: 0,
            discountOption: 1,
            calcPriceType: 0,
            fixed: false,
            maxInputPrice: 3,
            applyAll: false,
            currentUser: store.state.Auth.user,
            currentChangePriceUnit: null,
            productIdActiveUpdate: null,
            detailForProductId: null,
        }
    },
    methods: {
        closeProductActive() {
            this.detailForProductId = null;
        },
        chooseProductActive(poductId) {
            if (this.detailForProductId == poductId) {
                this.detailForProductId = null;
                return;
            }
            this.detailForProductId = poductId;
        },
        selectOptionExport(method = 'exportExcel', totalRecord = 0){
            let params = { method: method };
            totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
            this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
        },
        async exportFiles(params){
            if (params.method) {
                this[params.method](params.firstRecordIndex);
            }
        },
        async exportExcel (firstRecordIndex = 0) {
            emitter.emit("onLoading");
            const params = {
                inputSearch: this.searchHead,
                categoryId: this.searchSidebar.categoryId,
                firstRecordIndex: firstRecordIndex
            };
            const res = await http.download('/v1/setting-price/export-excel', {fields: this.fields, ...params});
            this.returnDataExport(res);
        },
        handleChooseOptionCalculator(id){
            this.optionCalculator.forEach( opt => {
                if(opt.id === id){
                    opt.selected = true;
                }else{
                    opt.selected = false;
                }
            });
        },
        handleChooseComparePrice(id){
            this.comparePrice.forEach( cp => {
                if(cp.id === id){
                    cp.selected = true;
                }else{
                    cp.selected = false;
                }
            });
        },
        clonePrice(priceListId, products, productCurrentId, priceListName, productUnitId) {
            let status = this.checkPermission(this.$permissions.settingPrice.create);
            if (!status && this.currentUser && this.currentUser.id != this.$constant.isAdmin) {
                return;
            }
            let productIds = [];
            // products.forEach(product => {
            //     if (product.product_unit_price_lists && product.product_unit_price_lists.length) {
            //         let item = product.product_unit_price_lists.find(item => item.priceListId === priceListId);
            //         if (!item) {
            //             productIds.push(product.id);
            //         }
            //     } else {
            //         productIds.push(product.id);
            //     }
            // });

            let params = {
                inputSearch: this.searchHead,
                categoryId: this.searchSidebar.categoryId,
                priceListId: this.priceListId,
                productUnitId: productUnitId,
            }
            this.$refs.ModalConfirmClonePrice.show(priceListId, productCurrentId, priceListName, params, this.paginate.totalRecord);
        },
        async updatePriceList(productId, priceListId, productUnitId, id = null, price = null) {
            this.hideFormUpdate();
            if(this.updatePriceField == priceListId && this.updatePriceModal == id && this.currentChangePriceUnit == productUnitId){
                const params = {
                    productId: productId,
                    priceListIdSelect: priceListId,
                    price: price ? price : this.priceUpdated,
                    productUnitId: productUnitId,
                    id: id,
                    applyAll: this.applyAll,
                    calcPriceType: this.calcPriceType,
                    // 1 vnd , 2 %
                    type: this.discountOption,
                    // 0 là + , 1 la -
                    calculatorOption: this.calculatorOption,
                    //use search product
                    categoryId: this.searchSidebar.categoryId,
                    priceListId: this.priceListId,
                    inputSearch: this.searchHead,
    
                    updatePriceLists: this.updatePriceLists.selected
                }
                const res = await $post('/v1/setting-price/update/product-unit-price-list' , {...params});
                if (res.code == 422) {
                    $alert({code: 422, message: res.messages.errors.price[0]});
                    return;
                } else {
                    $alert({code: 200, message: this.$t('message.PriceListDetailIndex.alertUpdateSuccess')});
                    this.getProducts();
                    this.closeUpdatePriceModal();
                    this.applyAll = false;
                }
            }
        },
        updateFields(){
            this.fields = cloneObject(this.defaultFields);
            if (this.priceList.search) {
                this.priceList.search.forEach(id => {
                    this.priceList.filter.forEach( item => {
                        if(id === item.id){
                            this.selectPriceListId(item.id, item.name);
                        }
                    })
                })
            }
        },
        selectPriceListId(id, name) {
            let index = this.fields.findIndex(item => item.id == id);
            if (index !== -1) {
                this.fields.splice(index, 1);
                let i = this.priceListId.indexOf(id);
                if (i !== -1) {
                    this.priceListId.splice(i, 1);
                }
            } else {
                const field = {
                    name: name,
                    field: '',
                    noSort: false,
                    selected: true,
                    id: id,
                }
                this.fields.push(field);
                this.priceListId.push(id)
            }
            this.getProducts();
        },
        async getProducts() {
            const params = {
                page: this.paginate.currentPage,
                field: this.sortData.field,
                direction: this.sortData.direction,
                numberRecord: this.numberRecord,
                inputSearch: this.searchHead,
                categoryId: this.searchSidebar.categoryId,
                priceListId: this.priceListId,
            };
            const res = await $post('/v1/setting-price', params);
            if (res.status.code != 200) {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
                return;
            } 
            
            this.products = res.result.data;       
            this.paginate.totalRecord = res.result.total;
            this.paginate.currentPage = res.result.current_page;
            this.paginate.lastPage = res.result.last_page;
            this.numberRecord = res.result.per_page;
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },
        async sort(field, direction) {
            this.sortData.field = field;
            this.sortData.direction = direction;
            this.getProducts();
        },
        searchHeadProduct(event) {
            this.paginate.currentPage = 1;
            this.searchHead = event.target.value.trim();
            this.getProducts();
        },
        async pageChange(page) {
            this.paginate.currentPage = page;
            this.getProducts();
        },
        clearFilter() {
            // this.searchSibar.departmentId = null;
            // this.searchSibar.jobTitleId = null;
            // this.searchSibar.payrollBranch = null;
            // this.department.search = null;
            // this.payrollBranch.search = null;
            // this.jobTitle.search = null;
            this.sortData.field = null,
            this.sortData.direction = null,
            this.searchHead = null;
            this.paginate.currentPage = 1;
            this.searchSidebar.categoryId = null,
            this.numberRecord = 15;
            this.priceList.search = null;
            this.updateFields();
            document.getElementById('search-header').value = null;
            this.getProducts();
        },
        async getCategories() {
            const res = await $get('/v1/setting-price/get-categories');
            if (res.status.code != 200) {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
                return;
            } 
            this.categories.all = res.result;       
            this.categories.filter = res.result;
        },
        async getPriceList() {
            const res = await $get('/v1/setting-price/get-price-lists');
            if (res.status.code != 200) {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
                return;
            } 
            this.priceList.all = res.result;       
            this.priceList.filter = res.result;
            this.updateFields();
        },
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        showHideChildrenCategories(id) {
            let index = this.choosedParentCategories.indexOf(id);
            if (index > -1) {
                this.choosedParentCategories.splice(index, 1);
            } else {
                this.choosedParentCategories.push(id);
            }
        },
        handleCheckCategories(id, name = null) {
            this.searchSidebar.categoryId = id;
            this.categories.search = null;
            if(name){
                this.categories.search = name;
            }
            this.getProducts();
        },
        onInputFilterCategories(){
            const categories = this.categories.all;
            if(this.categories.search == ''){
                this.categories.filter = this.categories.all
                this.choosedParentCategories = []
                return
            }
            let arr = []
            let idList = []
            categories.forEach(item => {
                if(item.children && item.children.length){
                    let newItem
                    let dataSearch = [];
                    item.children.forEach(child => {
                        let newChild
                        if(child.children && child.children.length){
                            let newChildChildren = child.children.filter(e => removeVietnameseTones(e.name.toLowerCase()).includes(removeVietnameseTones(this.categories.search.toLowerCase())))
                            
                            if(newChildChildren.length && !(arr.includes(item))){
                                idList.push(child?.id);
                                newChild = [{...child,children: newChildChildren}];
                                newItem = {...item,children: newChild};
                            }else if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.categories.search.toLowerCase())) && !(arr.includes(item))){
                                arr.push(item)
                            }
                            if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.categories.search.toLowerCase())) && !(arr.includes(item))){
                                dataSearch.push(child)
                                newItem = {...item, children: dataSearch}
                                idList.push(item.id)
                                arr.push(newItem)
                            }
                        }else{
                            if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.categories.search.toLowerCase())) && !(arr.includes(item))){
                                arr.push(item)
                            }
                            if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.categories.search.toLowerCase())) && !(arr.includes(item))){
                                dataSearch.push(child)
                                newItem = {...item, children: dataSearch}
                                idList.push(item.id)
                                arr.push(newItem)
                            }
                        }
                    })
                    if(newItem && !(arr.includes(newItem))){
                        idList.push(newItem.id)
                        arr.push(newItem)
                    }
                    
                }else if(removeVietnameseTones(item.name.toLowerCase()).indexOf(removeVietnameseTones(this.categories.search.toLowerCase())) > -1 && !(arr.includes(item))){
                    arr.push(item)
                }
            })
            this.choosedParentCategories = [...idList]
            // arr = arr.filter((value, index, self) =>
            //     index === self.findIndex((t) => (
            //         t.id === value.id
            //     ))
            // )
            this.categories.filter = [...arr];
        },
        showModalCreateEditPriceList() {
            this.$refs.ModalCreateEditPriceList.show()
        },
        showModalEditPriceList(item)  {
            this.$refs.ModalCreateEditPriceList.show(item)
        },
        onInputFilterPriceList(){
            if(this.priceList.search == ''){
                this.priceList.filter = this.priceList.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.priceList.all.length; index++) {
                if (removeVietnameseTones(this.priceList.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.priceList.search.toLowerCase())) > -1){
                    arr.push(this.priceList.all[index]);
                }
            }
            this.priceList.filter = arr;
        },
        removeColum(id) {
            this.getPriceList();
            var index = (this.fields.findIndex(item => item.id === id))
            if (index !== -1) {
                this.fields.splice(index, 1);
            }
            var i = this.priceList.search.indexOf(id)
            if (i !== -1) {
                this.priceList.search.splice(i, 1);
                this.$forceUpdate();
            }
        },
        hideFormUpdate() {
            this.productIdActiveUpdate = null;
        },
        showUpdatePriceModal(entry, field, productUnitId){
            let status = this.checkPermission(this.$permissions.settingPrice.update);
            if (!status && this.currentUser && this.currentUser.id != this.$constant.isAdmin) {
                return;
            }
            this.productIdActiveUpdate = productUnitId;
            this.updatePriceModal = entry.id;
            this.currentChangePriceUnit = entry.productUnitId ? entry.productUnitId : null;
            this.priceUpdated = entry.price;
            this.priceUpdateDefault = entry.price;
            this.updatePriceField = field;
            this.updatePriceLists.selected = 0;
            this.calcPriceType = 0;
            this.calculatorOption = 0;
            this.discountOption = 1;
        },
        closeUpdatePriceModal(){
            this.updatePriceModal = null;
            this.updatePriceField = null;
            this.currentChangePriceUnit = null;
        },
        // choosePriceList(entry, item, product){
        //     this.updatePriceLists.selected = entry.id;
        //     let option;
        //     this.$constant.priceListOptions.forEach(opt => {
        //         if(opt.id == this.updatePriceLists.selected){
        //             option = opt.name;
        //         }
        //     })
        //     switch (option) {
        //         case this.$constant.text_current_price:
        //             this.priceUpdated = Number(item.price);
        //             this.priceUpdateDefault = Number(item.price);
        //             break;
        //         case this.$constant.text_common_price:
        //             this.priceUpdated = Number(product.product_units[0].price);
        //             this.priceUpdateDefault = Number(product.product_units[0].price);
        //             break;
        //         case this.$constant.text_last_price:
        //             this.priceUpdated = Number(product.price_import[0] ? (product.price_import[0].price - product.price_import[0].discount/product.price_import[0].quantity) : 0);
        //             this.priceUpdateDefault = Number(product.price_import[0] ? (product.price_import[0].price - product.price_import[0].discount/product.price_import[0].quantity) : 0);
        //             break;
        //     }
        // },
        // onInputPriceList(){
        //     if(this.updatePriceLists.search == ''){
        //         this.updatePriceLists.filter = this.updatePriceLists.all;
        //         return
        //     }
        //     let arr = [];
        //     for (let index = 0; index < this.updatePriceLists.all.length; index++) {
        //         if (removeVietnameseTones(this.updatePriceLists.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.updatePriceLists.search.toLowerCase())) > -1){
        //             arr.push(this.updatePriceLists.all[index]);
        //         }
        //     }
        //     this.updatePriceLists.filter = arr;

        // },
        // changeCalculatorOption(id){
        //     this.calculatorOption = id;
        //     this.onInputPriceUpdate();
        // },
        // changeDiscountOption(id){
        //     if(id != this.discountOption){
        //         this.calcPriceType = 0;
        //         this.discountOption = id;
        //     }
        //     this.onInputPriceUpdate();
        // },
        // onInputPriceUpdate(){
        //     let calc;
        //     let discount;
        //     let price;
        //     this.$constant.calculatorOptions.forEach(calcOpt => {
        //         if(calcOpt.id == this.calculatorOption){
        //             calc = calcOpt.name;
        //         }
        //     });
        //     this.$constant.discountOptions.forEach(disOpt => {
        //         if(disOpt.id == this.discountOption){
        //             discount = disOpt.name;
        //         }
        //     })
        //     if(this.calcPriceType?.length > 0){
        //         this.calcPriceType = this.calcPriceType.replace(/,/g,'');
        //     }
        //     switch (discount) {
        //         case '%':
        //             price = (this.priceUpdateDefault * (+this.calcPriceType / 100));
        //             this.maxInputPrice = 3;
        //             break;
        //         case 'VNĐ':
        //             price = (+this.calcPriceType);
        //             this.maxInputPrice = 15;
        //             break;
        //     }
        //     switch (calc) {
        //         case '+':
        //             this.priceUpdated = (this.priceUpdateDefault + price);
        //             break;
        //         case '-':
        //             this.priceUpdated = (this.priceUpdateDefault - price);
        //             break;
        //     }
        //     if(this.priceUpdated < 0){
        //         this.priceUpdated = 0;
        //     }
        // },
        onScroll() {
            let height = window.scrollY;
            if(height > 120){  
                this.fixed = true;
            }else{
                this.fixed = false;
            }
            emitter.emit("position", 0);
        },
        importDetailPriceLists(){
            this.$refs.ImportDetailPriceLists.show()
        }
    },
    created () {
        this.getProducts();
        this.getCategories();
        this.getPriceList();
    },
    watch: {
        numberRecord() {
            this.getProducts();
        },
        fields: {
            handler(newValue){
                let noField = true;
                for (let index = 0; index < newValue.length; index++) {
                    if (newValue[index].selected) {
                        noField = false;
                    }
                }
                this.noField = noField;
            },
            deep: true
        }
    }
}
</script>
