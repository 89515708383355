import moment from 'moment';
import store from './store';
export default {    
    computed: {
        permissionInfo: () => store.state.PermissionInfo,   
        authData: () => store.state.Auth
    },
    methods: {
    employeeFields(){
        let fields = [
            {
                name: this.$t('message.employeeIndex.employee_code'),
                field: 'code',                
                selected: true,
                
            },
            {
                name: this.$t('message.employeeIndex.employee_name'),
                field: 'name',                
                selected: true,                
            },
            {
                name: this.$t('message.LoginScreen.username'),
                field: 'userName',                
                selected: true,
            },          
            {
                name: this.$t('message.employeeIndex.employee_job_title'),
                field: 'jobTitleName',                
                selected: true,
            },

            // {
            //     name: 'Vai trò',
            //     field: 'roleName',                
            //     selected: true,
            // },           
            {
                name: this.$t('message.common.status'),
                field: 'statusName',                
                selected: true,
            }
                   
        ];
        return fields;
    },
    getStatus(id){
        let lstStatus = this.$constant.lstStatusUser;
        let name = "";
        lstStatus.forEach(item => {
            if(item.id == id) name = item.name;
        });
        return name;
    },
    convertDatetime(value){
        return  moment(value).format('DD/MM/YYYY HH:mm');
    },
    hasPermission(permissionName) {            
        if (permissionName && this.authData && this.authData.user) {
            // if (this.permissionInfo && (this.permissionInfo.isAdmin || this.permissionInfo.isRoot)) {
            if (this.permissionInfo && this.permissionInfo.isRoot) {
                return true;
            }
            return !!this.permissionInfo.permissionNameMap[permissionName]
        }            
        return false;            
    },

  }
}
  