import i18n from '@/setup/i18n';
import moment from 'moment';

export default { 
    formatDay(date) {
        if (date) {
            return moment(date).format('DD');
        }
        return '';
    },
    formatDepartments(departments) {
        if (!departments) return;
        let label = [];
        for (let index = 0; index < departments.length; index++) {
            label.push(departments[index].name);
        }
        return label.join(', ');
    },

    formatDate(date) {
        if (date) {
            return moment(date).format('DD/MM/YYYY');
        }
        return '';
    },
    formatDateHour(dateTime) {
        if (dateTime) {
            return moment(dateTime).format('DD/MM/YYYY H:mm ');
        }
        return '';
    },

    formatSex(code) {        
        let gender;
        switch (code) {
            case 1:
                gender = i18n.t('message.common.male');
                break;
            case 0:
                gender = i18n.t('message.common.female');
                break;                        
            default:
                gender = "";
                break;
        }        
        return gender;
    },

    formatAddress(user) {
        let address = [];
        if (user.address) {
            address.push(user.address);
        }
        if (user.ward) {
            address.push(user.ward.name);
        }
        if (user.district) {
            address.push(user.district.name);
        }
        if (user.province) {
            address.push(user.province.name);
        }
        return address.join(', ');
    },

    formatPrice(number, label = '', signDisplay = false) {
        if (number) {
            const locale = 'ja-JP';
            const options = {
                style: 'currency',
                currency: 'JPY',
            };
            if (signDisplay) {
                options.signDisplay = 'never';
            }
            const formatter = new Intl.NumberFormat(locale, options);

            return formatter.formatToParts(+number).map(({ type, value }) => {
                switch (type) {
                case 'currency': return `${label}`;
                default: return value;
                }
            }).reduce((string, part) => string + part);
        }
        return 0;
    },

    calculatePrice(Total, discount) {
        if (Total && discount) {
            return  this.formatPrice(Total - +discount);
        } else if (Total) {
            return this.formatPrice(Total);
        }
        return 0;
    },

    formatStatusShopee(status) {
        if (!status) return;
        switch (status) {
            case 'UNPAID':
                return 'CHƯA THANH TOÁN'; 
            case 'READY_TO_SHIP':
                return 'SẴN SÀNG CHUYỂN HÀNG';   
            case 'PROCESSED':
                return 'XỬ LÝ'; 
            case 'SHIPPED':
                return 'VẬN CHUYỂN';
            case 'IN_CANCEL':
                return 'ĐANG HỦY';
            case 'CANCELLED':
                return 'ĐÃ HỦY'; 
            case 'INVOICE_PENDING':
                return 'ĐANG CHỜ HÓA ĐƠN';
            case 'ON_HOLD':
                return 'TẠM DỪNG'; 
            case 'AWAITING_SHIPMENT':
                return 'CHỜ GIAO HÀNG';
            case 'AWAITING_COLLECTION':
                return 'CHỜ LẤY HÀNG';
            case 'PARTIALLY_SHIPPING':
                return 'GIAO 1 PHẦN';
            case 'IN_TRANSIT':
                return 'ĐANG GIAO';
            case 'DELIVERED':
                return 'ĐÃ GIAO';
            case 'COMPLETED':
                return 'HOÀN THÀNH';  
            case 'queueing':
                return 'ĐANG CHỜ NHÀ BÁN XÁC NHẬN';
            case 'canceled':
                return 'ĐÃ HỦY';
            case 'complete':
                return 'HOÀN THÀNH';
            case 'successful_delivery':
                return 'GIAO HÀNG THÀNH CÔNG';
            case 'processing':
                return 'ĐANG XỬ LÝ';
            case 'waiting_payment':
                return 'CHỜ THANH TOÁN';
            case 'handover_to_partner':
                return 'ĐƠN HÀNG ĐÃ GIAO ĐỐI TÁC GIAO HÀNG';
            case 'closed':
                return 'ĐƠN HÀNG ĐÃ ĐÓNG';
            case 'packaging':
                return 'ĐƠN HÀNG ĐANG ĐƯỢC ĐÓNG GÓI';
            case 'picking':
                return 'ĐANG LẤY HÀNG';
            case 'shipping':
                return 'ĐANG GIAO HÀNG';
            case 'paid':
                return 'ĐÃ THANH TOÁN';
            case 'delivered':
                return 'GIAO HÀNG THÀNH CÔNG';
            case 'holded':
                return 'ĐƠN HÀNG ĐANG ĐƯỢC GIỮ LẠI ĐỂ XỬ LÝ CÁC VẤN ĐỀ PHÁT SINH';
            case 'ready_to_ship	':
                return 'CHỜ GIAO HÀNG';
            case 'returned':
                return 'ĐÃ HOÀN HÀNG';
            case 'finished_packing':
                return 'ĐƠN HÀNG ĐÃ ĐÓNG GÓI';
            default:
                return status;
        }
    }
}