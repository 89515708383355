<template>
    <div ref="modalConfirm" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content px-4">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.modalConfirm.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h6 style="line-height:30px;">
                                {{ $t('message.common.confirmCancelRetunrPO', {code: this.code}) }}
                            </h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click="AbortOS()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.modalConfirm.button.confirm') }}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.modalConfirm.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
   
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';
import Paginate from "@/components/Paginate";
export default {
    name: "ModalReturnAbort",
    components: {Paginate},
    data(){

        return {
            id:null,
            code:null,
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(id, code) {
            if(id != null){
                this.id = id;
                this.code = code;
                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        async AbortOS(){
            const res = await $post('/v1/purchase-orders/return/abortPOrd', { id: this.id});
            if(res.status.code == 200){
                if(res.result.code && res.result.code == 201){
                    toastr.error(res.result.message);
                    this.$emit('inputData','1');
                }
                else{
                    toastr.success(this.$t('message.common.cancelReturnPOSuccess', {code: this.code}));
                    this.$emit('inputData',res.result);
                }
            }else{                
                toastr.error(this.$t('message.common.cancelReturnPOFailed', {code: this.code}));
                this.$emit('inputData',res.result);
            }
            this.clearData();
        },
        clearData(){
            this.id = null;
            this.code = null;
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

