const getDefaultState = () => {
    return {
        sendMailZaloContent: null,
        sendMailZaloContentOriginal: null,
        sendMailZaloTemplate: null,
        sendMailZaloTemplateName: null,
        sendMailZaloTemplateNameOrigin: null,
        action: null,
        sendMailZaloTemplateId: null,
        pdfFileId: null,
        pdfFileIdOrigin: null,
        dataValueKeys: null,
        printTemplates: null,
        currentTabName: null,
    };
};
const state = getDefaultState();
const getters = {
    sendMailZaloContent(state){
        return state.sendMailZaloContent;
    },
    sendMailZaloContentOriginal(state){
        return state.sendMailZaloContentOriginal;
    },
    sendMailZaloTemplate(state){
        return state.sendMailZaloTemplate;
    },
    action(state){
        return state.action;
    },
    sendMailZaloTemplateId(state){
        return state.sendMailZaloTemplateId;
    },
    pdfFileId(state){
        return state.pdfFileId;
    },
    pdfFileIdOrigin(state){
        return state.pdfFileIdOrigin;
    },
    dataValueKeys(state){
        return state.dataValueKeys;
    },
    printTemplates(state){
        return state.printTemplates;
    },
    sendMailZaloTemplateName(state){
        return state.sendMailZaloTemplateName;
    },
    sendMailZaloTemplateNameOrigin(state){
        return state.sendMailZaloTemplateNameOrigin;
    },
    currentTabName(state){
        return state.currentTabName;
    },
};
const mutations = {
    SET_SEND_MAIL_ZALO_CONTENT(state, sendMailZaloContent) {
        state.sendMailZaloContent = sendMailZaloContent;
    },
    SET_SEND_MAIL_ZALO_CONTENT_ORIGINAL(state, sendMailZaloContentOriginal) {
        state.sendMailZaloContentOriginal = sendMailZaloContentOriginal;
    },
    SET_SEND_MAIL_ZALO_TEMPLATE(state, sendMailZaloTemplate) {
        state.sendMailZaloTemplate = sendMailZaloTemplate;
    },
    SET_ACTION(state, action) {
        state.action = action;
    },
    SET_SEND_MAIL_ZALO_TEMPLATE_ID(state, sendMailZaloTemplateId) {
        state.sendMailZaloTemplateId = sendMailZaloTemplateId;
    },
    SET_PDF_FILE_ID(state, pdfFileId) {
        state.pdfFileId = pdfFileId;
    },
    SET_PDF_FILE_ID_ORIGIN(state, pdfFileIdOrigin) {
        state.pdfFileIdOrigin = pdfFileIdOrigin;
    },
    SET_DATA_VALUE_KEYS(state, dataValueKeys) {
        state.dataValueKeys = dataValueKeys;
    },
    SET_PRINT_TEMPLATES(state, printTemplates) {
        state.printTemplates = printTemplates;
    },
    SET_SEND_MAIL_ZALO_TEMPLATE_NAME(state, sendMailZaloTemplateName) {
        state.sendMailZaloTemplateName = sendMailZaloTemplateName;
    },
    SET_SEND_MAIL_ZALO_TEMPLATE_NAME_ORIGIN(state, sendMailZaloTemplateNameOrigin) {
        state.sendMailZaloTemplateNameOrigin = sendMailZaloTemplateNameOrigin;
    },
    SET_CURRENT_TAB_NAME(state, currentTabName) {
        state.currentTabName = currentTabName;
    },
};
const actions = {
    async setSendMailZaloContent({commit}, sendMailZaloContent) {
        commit('SET_SEND_MAIL_ZALO_CONTENT', sendMailZaloContent);
    },
    async setSendMailZaloContentOriginal({commit}, sendMailZaloContentOriginal) {
        commit('SET_SEND_MAIL_ZALO_CONTENT_ORIGINAL', sendMailZaloContentOriginal);
    },
    async setSendMailZaloTemplate({commit}, sendMailZaloTemplate) {
        commit('SET_SEND_MAIL_ZALO_TEMPLATE', sendMailZaloTemplate);
    },
    async setAction({commit}, action) {
        commit('SET_ACTION', action);
    },
    async setSendMailZaloTemplateId({commit}, sendMailZaloTemplateId) {
        commit('SET_SEND_MAIL_ZALO_TEMPLATE_ID', sendMailZaloTemplateId);
    },
    async setPdfFileId({commit}, pdfFileId) {
        commit('SET_PDF_FILE_ID', pdfFileId);
    },
    async setPdfFileIdOrigin({commit}, pdfFileIdOrigin) {
        commit('SET_PDF_FILE_ID_ORIGIN', pdfFileIdOrigin);
    },
    async setDataValueKeys({commit}, dataValueKeys) {
        commit('SET_DATA_VALUE_KEYS', dataValueKeys);
    },
    async setPrintTemplates({commit}, printTemplates) {
        commit('SET_PRINT_TEMPLATES', printTemplates);
    },
    async setSendMailZaloTemplateName({commit}, sendMailZaloTemplateName) {
        commit('SET_SEND_MAIL_ZALO_TEMPLATE_NAME', sendMailZaloTemplateName);
    },
    async setSendMailZaloTemplateNameOrigin({commit}, sendMailZaloTemplateNameOrigin) {
        commit('SET_SEND_MAIL_ZALO_TEMPLATE_NAME_ORIGIN', sendMailZaloTemplateNameOrigin);
    },
    async setCurrentTabName({commit}, currentTabName) {
        commit('SET_CURRENT_TAB_NAME', currentTabName);
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
