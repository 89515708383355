<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" :class="isTab === $constant.tab.first ? 'modal-dialog-scrollable' : ''" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h6 v-if="isEdit" class="modal-title py-2 px-4">
                        {{$t('message.common.updatePromotion')}}
                    </h6>
                    <h6 v-else class="modal-title py-2 px-4">
                        {{$t('message.common.addPromotion')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header custom__list-tab bg-light px-3 pt-3 pb-0 text-center">
                    <!-- <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.first)">MISA</div> -->
                    <!-- <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.second)">Thiết lập zalo</div> -->
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                            <div class="row mx-4 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    {{$t('message.ProductIndex.info')}}
                                </div>
                            </div>
                            <div class="row mx-4 my-4 pb-4 mt-1 border-bottom-dotted-2">
                               
                            </div>
                            <div class="row mx-4 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    {{$t('message.common.formOfPromotion')}}
                                </div>
                            </div>
                           
                        </div>

                        <div class="tab-pane fade show active" id="homeTime" role="tabpanel" aria-labelledby="time-tab" v-if="isTab === $constant.tab.second">
                            <div class="row mx-4 my-4 pb-4 mt-1">
                                <div class="col-6 pl-0">
                                  
                                </div>
                                <div class="col-6 pr-0">
                                  
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeLocation" role="tabpanel" aria-labelledby="location-tab" v-if="isTab === $constant.tab.third">
                            <div class="row mx-4 my-4 pb-2 mt-1">
                              
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0" v-if="this.isTab === $constant.tab.first">
                        <div class="col-12 flex-start p-0">
                            <button type="button" class="btn btn-white mr-2 border" @click="!this.notEdit ? addConditional() : null">
                                <i class="fa fa-plus mr-1"></i>
                                {{$t('message.common.addConditional')}}
                            </button>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="col-12 flex-end p-0">
                            <button type="button" class="btn btn-save mr-2" @click="beforeSubmit">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    name: "ModalSocialite",
    data () {
        return {
        }
    },
    created() {
    },
    methods: {
        async show(id = null) {              
            // this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
                this.id = id;
            }
        },
    },
}
</script>
<style scoped lang="scss">
@import "@/assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}

.custom__list-tab {
    justify-content: flex-start;
    align-items: end;
    padding-bottom: 8px;
}
.tab__active {
    border-bottom: 2px solid $base-color !important;
}
.tab__item {
    cursor: pointer;
    padding: 0 15px;
    border-bottom: 2px solid #f8f9fa;
}

.input:focus-visible {
    border: none !important;
}

.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.currencyUnit{
    position: absolute;
    right: 0;
    top: 20%;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search{
    height: 50px !important;
    background-color: #FEFCED;
}
.cell-check{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}
.cell-code-coupon{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-release-coupon{
    width: 190px;
    min-width: 190px;
    max-width: 190px;
}
.cell-auto{
    min-width: 160px;
}
.cell-status-coupon{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}

.listIcon{
    outline: none !important;
    background: none !important;
    height: 20px;
    width: 25px;
}
.listIcon:hover{
    background-color: #e5e5e5 !important;
    outline: 0;
    box-shadow: none;
    border-radius: 50%;
}
.cate_selected{
    background: #e5e5e5 !important;
    min-height: 30px !important;
    width:fit-content !important;
    font-weight: 400!important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.area_obj_selected::-webkit-scrollbar {
  display: none;
}

.area_obj_selected {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.dropdown-menu-content{
    width: 100vh;
    max-height: 20rem;
    max-width: 12rem;
    overflow: auto;
    z-index: 1000;
    transform: translate3d(15px, 0px, 0px) !important;
    background: white;
    position: absolute;
}
.fa-times:hover{
    background-color: #e5e5e5;
    box-shadow: none;
    border-radius: 999px;
}
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50% !important;
}
.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: -7px;
  left: 40px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
}

/* .icon-circle-custom:hover+.content-circle {
  display: block;
} */
.inputDate_picker, .inputDate_picker:focus, .inputDate_picker:active{
    width: 90%;
    border: none;
    position: absolute;
    outline: none;
}
.time_picker{
    position: absolute;
    right: 0px;
}
input[type="date"]:focus, input[type="date"]:active{
    outline: none;
    border: none;
}
.inputText_picker{
    width: 85% !important;
    z-index: 1;
}
.fa-trash-alt:hover{
    background-color: #ccc;
    border-radius: 999px ;
}
.form-group-promotion {
    min-height: 30px;
    padding: 10px 20px 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    border: 1px solid #d7d7d7;
    -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.15);
    -moz-box-shadow: 0 5px 10px rgba(0,0,0,.15);
    box-shadow: 0 5px 10px rgba(0,0,0,.15);
    margin-bottom: 0;
}
.form-group-promotion .form-label {
    width: 130px;
    float: left;
    cursor: default;
    padding: 0 0.5rem 0;
    line-height: 1.8rem;
    min-height: 1.8rem;
    word-break: break-word;
}
.form-group-promotion .form-wrap {
    margin-left: 15rem;
}
.form-group-promotion .form-wrap .parent-row {
    position: relative;
    display: flex;
    justify-content: space-between;
}
.wrap-autocomplete{
    min-width: 450px;
}
.remove-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
}
.remove-btn:hover{
    background-color: #e47885;
    border-color: #e47885;
}
.remove-btn:hover i{
    color: #fff !important;
}
.input-location{
    width: 50%;
    max-width: 50%;
    min-width: 50%;
}
.prd-search-box1:hover, .prd-search-box1:active {
    border-bottom: 1px solid #ccc !important;
}
.dropdown-toggle-limitPromotion:after{
    display: none;
}
</style>