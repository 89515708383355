<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="modalConfirm" :class="this.disabledJobTitle && this.disabledJobTitle === true ? 'modal-third' : '' " data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                    <h5 class="modal-title py-2" v-text="this.title ? this.title : $t('message.common.confirm')"></h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        <h6 v-text="this.content ? this.content : $t('message.common.confirmCancelChange')"></h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="skip()">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import storeModul from "@/storeModul";

export default {
    name: "ModalConfirm",
    data(){
        return {
            action: null,
        }
    },
    props: ['message', 'title', 'content','disabledJobTitle', 'dataSearch', 'dataBranch'],
    methods: {
        show(action = null) {              
            this.action = action;            
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            this.$emit('cancel', 1);
            this.hide();
            storeModul.dispatch('productStore/setShowModalConfirnRemoveAtrribute', false);
        },
        async confirm(){                      
            switch (this.action) {
                case this.$constant.cancelDiscountBas:
                    this.$emit('confirmCancel');
                    break;
                case this.$constant.deleteDiscountBas:                    
                    this.$emit('confirmDelete');        
                    break;
                case this.$constant.searchDiscountBas:                    
                    this.$emit('confirmSearch', this.dataSearch);        
                    break;
                case this.$constant.branchDiscountBas:                    
                    this.$emit('confirmBranch', this.dataBranch);        
                    break;
                default:
                    this.$emit('confirm');        
                    break;        
            }
            storeModul.dispatch('productStore/setStatusConfirmRemoveAtrribute', true);
            this.hide();
        },
        async hide() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
            storeModul.dispatch('productStore/setShowModalConfirnRemoveAtrribute', false);
        },
    }
}
</script>

