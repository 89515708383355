<template>
    <AppPermission :permission="this.$permissions.departments.data?? '' ">
        <div>
            <div class="container my-4">
                <div class="row m-0 p-0">
                    <div class="mainLeft">
                    <!-- <div class="col-md-2" id="col2"> -->
                        <div class="main-title">
                            <span>{{ $t("message.DepartmentIndex.title") }}</span>
                        </div>
                        <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-8 text-title">
                                        <label>{{$t("message.BankBookIndex.numberRecord") }}:</label>
                                    </div>
                                    <div class="col-4">
                                        <select :value="numberRecord" style="border: none;outline: none;" class="number_records" id="number_records"
                                            @change="onChange($event)">
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mainRight">
                    <!-- <div class="col-md-10" id="col10"> -->
                        <div class="sticky-top">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="search-box">
                                        <i class="fas fa-search mr-2"></i>
                                        <input type="text" class="search-input" v-model="params.keyword"
                                            @keyup.enter="search()" :placeholder="$t('message.DepartmentIndex.placeholder.search')" />
                                    </div>
                                </div>
                                <div class="col-md-7 d-flex justify-content-end">
                                    <button type="button" class="btn btn-default" @click="clearFilter">
                                        <i class="fa fa-filter mr-1"></i>
                                        {{ $t('message.button.removeFilter') }}
                                    </button>
                                    <AppPermission :permission="this.$permissions.departments.create?? '' ">
                                        <button class="btn  btn-save ml-1" @click="showModalCreateDepartment(1)">
                                            <i class="fa fa-plus" /> 
                                            {{ $t('message.DepartmentIndex.button.create') }}
                                        </button>
                                    </AppPermission>

                                    <button type="button" class="btn btn-save" @click="showModalImport">
                                        <i class="fa fa-filter mr-2"></i>
                                        {{ $t('message.button.import.name') }}
                                    </button>
                                </div>
                            </div>
                            <div>
                                    <Paginate :value="paginate" :record="numberRecord" @change="pageChange" />
                            </div>
                            <div class="row mx-0 my-2" id="divTable">
                                <table v-if="this.departments.length > 0" class="table table-index table-striped w-100">
                                    <thead>
                                        <tr style="width:100%">
                                            <th class="name-customer-group pl-3">
                                                {{ $t('message.DepartmentIndex.table.code') }}
                                            </th>
                                            <th class="discount-customer-group pl-5">
                                                {{ $t('message.DepartmentIndex.table.name') }}
                                            </th>
                                            <th class="note-customer-group pl-5">
                                                {{ $t('message.DepartmentIndex.table.quantity') }}
                                            </th>
                                            <th class="discount-customer-group">
                                                {{ $t('message.DepartmentIndex.table.description') }}
                                            </th>
                                            <th class="note-customer-group">
                                                {{ $t('message.DepartmentIndex.table.status') }}
                                            </th>
                                            <th class="action"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item, index in departments" :key="index" >
                                            <td @click="showModalEditDepartment(item.id)" class="pl-3 py-0">{{ item.code }}</td>
                                            <td @click="showModalEditDepartment(item.id)" class="pl-5 py-0">{{ item.name }}</td>
                                            <td @click="showModalEditDepartment(item.id)" class="pl-5 py-0">{{ item.totalEmployee }}</td>
                                            <td @click="showModalEditDepartment(item.id)" class="py-0">
                                                <p class="text-overflow-ellipsis p-0 m-0" :title="item.note">
                                                    {{ item.note }}
                                                </p>
                                            </td>
                                            <td @click="showModalEditDepartment(item.id)" class="py-0">
                                                {{ item.status === this.$constant.statusActive ? $t('message.DepartmentIndex.status.active') : $t('message.DepartmentIndex.status.in_active') }}
                                            </td>
                                            <td class="d-flex justify-content-end py-0">
                                                <AppPermission :permission="this.$permissions.departments.update?? ''">
                                                    <button class="btn" @click="showModalEditDepartment(item.id)">
                                                        <i class="fa fa-edit"></i>
                                                    </button>
                                                </AppPermission>
                                                <AppPermission :permission="this.$permissions.departments.remove?? ''">
                                                    <button class="btn text-danger" @click="showModalRemoveDepartment(item.id, item.name)">
                                                        <i class="fa fa-trash-alt"></i>
                                                    </button>
                                                </AppPermission>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div v-else class="row ml-auto mr-auto" id="notFound" >
                                    <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                        <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                        <p v-if="params.keyword">{{ $t('message.common.notFound') }}</p>
                                        <p v-else-if="departments.length == 0">{{ $t('message.common.notData') }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirmDelete ref="ModalConfirmDelete" @updated="renderData" @inputData="search"/>
        <ModalImportDepartment ref="ModalImportDepartment" @updated="renderListData" @input="load()" />
        <ModalCreateDepartment ref="ModalCreateDepartment" @updated="renderListDataCreate" @inputData="load" />
        <ModalEditDepartment ref="ModalEditDepartment" @updated="renderListData" @input="load()" />
        <ModalErrorsMessageImport ref="ModalErrorsMessageImport" />
    </AppPermission>
</template>
<script>
// Import
import TableSortField from "@/components/TableSortField";
import Paginate from "@/components/Paginate";
import { $get, clone } from "@/lib/utils";
import ModalConfirmDelete from './ModalConfirmDelete.vue';
import ModalCreateDepartment from './ModalCreateDepartment.vue';
import ModalImportDepartment from './ModalImportDepartment.vue';
import ModalEditDepartment from './ModalEditDepartment.vue';
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from '@/permissionMixin';
import ModalErrorsMessageImport from './ModalErrorsMessageImport.vue';
// Function
export default {
    name: 'Department',
    mixins: [permissionMixin],
    components: {
        Paginate,
        TableSortField,
        ModalConfirmDelete,
        ModalCreateDepartment,
        ModalEditDepartment,
        AppPermission,
        ModalImportDepartment,
        ModalErrorsMessageImport
    },
    data() {
        return {
            params: {
                keyword: ''
            },
            sortData: {
                field: 'name',
                direction: 'desc',
            },
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            numberRecord: 15,
            departments: [],
            fixed: false,
            loading: false,
        }
    },
    mounted () {
        document.title = this.$t('message.DepartmentIndex.document_title');
        this.paginate.currentPage = this.$route.query.page || 1;
        this.numberRecord = this.$route.query.numberRecord || "15";
        this.load();
        // window.addEventListener('scroll', this.onScroll);
    },
    methods: {
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const departments = await $get('/v1/departments', {...this.$route.query, numberRecord: this.numberRecord});
            this.departments = departments.data;
            this.paginate = departments.paginate;
            emitter.emit("offLoading");
            this.loading = false
        },
        async pageChange(page) {
            let query = clone(this.$route.query);
            query.page = page;
            await this.$router.push({ query: { page: page } });
            this.load();
        },
        async sort(field, direction) {
            this.sortData = {
                field, direction
            };

            const query = clone(this.$route.query);
            query.sort_field = field;
            query.sort_direction = direction;

            await this.$router.push({ query: query });
            this.load();
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/departments',
                query: {}
            });
            this.params.keyword = '';
            this.numberRecord = 15;
            this.load();
        },
        showModalRemoveDepartment (id, name) {
            this.$refs.ModalConfirmDelete.show(id, name);
        },
        showModalCreateDepartment () {
            this.$refs.ModalCreateDepartment.show()
        },
        showModalEditDepartment (id) {
            this.$refs.ModalEditDepartment.show(id);
        },
        renderListDataCreate(newData) {
            this.departments.unshift(newData.data);
        },
        renderListData (newData) 
        {
            this.departments = this.departments.map(department => department.id === newData.id ? newData : department)
        }, 
        renderData(id) {
            const listUpdate = this.departments.filter(item => item.id !== id);
            this.departments = listUpdate;
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query.numberRecord = v.target.value;
            await this.$router.push({ query: { page: 1, ...this.params } });
            this.load();
        },
        async search() {
            let query = clone(this.$route.query);
            this.params = {
                ...this.params,
            }
            const data = await $get('/v1/departments', { ...this.params, numberRecord: this.numberRecord, query: query });
            // this.load();
            this.departments = data.data;
            this.paginate = data.paginate;
            this.entries = data.entries;
        },
        onScroll() {
            let height = window.scrollY;
            if(height > 100){
                this.fixed = true;
            }else{
                this.fixed = false;
            }
        },
        showModalImport () {
            this.$refs.ModalImportDepartment.show()
        }
    }
}
</script>
<style scoped>
.name-customer-group {
    width: 180px !important;
}
.note-customer-group {
    width: 150px !important;
}
.discount-customer-group {
    width: 600px !important;
}
.id-customer-group {
    width: 100px !important;
}
table.flex-table tr {
    width: 100%;
    display: flex;
    min-height: 40px !important;
    height: auto !important;
}
</style>