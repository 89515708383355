<template>
    <div class="row">
        <ActionBar back-url="/users/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.id ? 'Chỉnh sửa tài khoản' : 'Thêm tài khoản'"></h1>
            </div>

            <TabNavigation @tabChange="tabChange"
                           @onValidate="onValidate"
                           ref="tab" :tabs="tabs"
                           :id="id"/>
        </div>
    </div>
</template>

<script>
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement, scrollToErrors} from "@/lib/utils";
    import toastr from "toastr";
    import {markRaw} from "vue";
    import TabInfo from "@/views/users/tabs/TabInfo";
    import TabRole from "@/views/users/tabs/TabRole";
    import TabNavigation from "@/components/TabNavigation";

    export default {
        name: "UserForm",
        components: {ActionBar, TabNavigation},
        data() {
            const tabs = this.$route.query.id ? [
                {id: 'TabInfo', name: 'Thông tin', component: markRaw(TabInfo)},
                {id: 'TabRole', name: 'Phân quyền', component: markRaw(TabRole)},
                ] : [
                {id: 'TabInfo', name: 'Thông tin', component: markRaw(TabInfo)},
            ]
            return {
                isDataLoaded: false,
                tabs,                
                entry: {
                    branchIDs: [],
                    passwordConfirm: '',                    
                },
                errors: {},
                
            }
        },
        mounted() {
            document.title = 'Quản lý người dùng | OmiPos';
            if (this.$route.query.id) {
                this.load();
            } else {
                this.isDataLoaded = true;
            }
        },
        methods: {

            tabChange(tab) {
                this.currentTab = tab;
                if (this.$route.query.id) {
                    this.load();
                } else {
                    this.setTabData({
                        isDataLoaded: true
                    })
                }
            },
            setTabData(values) {
                this.$refs.tab.setTabData(values);
            },
            getTabData(key) {
                return this.$refs.tab.getTabData(key)
            },
            onValidate1() {
                const entry = this.$refs.tab.getTabData('entry');
                const location = this.$refs.tab.getTabData('location');
                this.validateTabInfo(entry, location);
            },
            validateTabInfo1(entry, location) {
                entry.errors = {};
                if (!location.provinceId) {
                    entry.errors.provinceId = ['Vui lòng nhập tỉnh'];
                    return false;
                }

                if (!location.districtId) {
                    entry.errors.districtId = ['Vui lòng nhập Quận/Huyện'];
                    return false;
                }

                if (!location.wardId) {
                    entry.errors.wardId = ['Vui lòng nhập Phường/Xã'];
                    return false;
                }

                return true;
            },

            onValidate(){
                const entry = this.$refs.tab.getTabData('entry');                
                this.validateTabInfo(entry);
            },
          
            validateTabInfo(entry) {                

                if (entry?.name !== null) {
                    this.errors.name = [`Vui lòng nhập tên người dùng`];
                }

            },
            async saveTabInfo() {
                    let id = this.$route.query.id;
                    this.errors = {};
                    const tab = this.$refs.tab;
                    this.entry = tab.getTabData('entry');    
                    
                    if (this.entry.passwordConfirm !== this.entry.password) {                        
                        toastr.error("Hai mật khẩu không khớp");                       
                        return;
                    }
                    else
                    {
                      
                        if (this.entry.branchIDs && this.entry.branchIDs.length === 0) {
                        toastr.error("Vui lòng chọn ít nhất một Chi nhánh");
                        return;
                        }

                            const res = await $post('/v1/users/save', {
                                entry: this.entry,
                                id: id
                            });
                            if (res.code === -1) {
                            this.setTabData({
                                errors: res.errors
                            });
                            scrollToErrors();
                        }

                        if (res.redirect) {
                            location.replace(res.redirect);
                        }
                        $alert(res);
                    }
                    
            },
           
            async save() {
                this.saveTabInfo();                
            },
            async load() {
                const res = await $get('/v1/users/show', {
                    id: this.$route.query.id
                });
                
                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }
                this.entry = res.data.entry;      
                
                
                this.isDataLoaded = true;
                this.setTabData({
                    entry: res.data.entry,
                    arrUserPermission: res.data.arrUserPermission,
                    branchMapName: res.data.branchMapName,
                    branchMaps: res.data.branchMaps,
                    isDataLoaded: true
                });
                this.entry.branchIDs = this.entry.branchIDs.split(',');                
            },
        }
    }
</script>

<style scoped>

</style>
