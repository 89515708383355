<template>
    <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">                    
                    <h5 class="modal-title py-2 px-2">
                        {{ $t('message.common.confirm') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <div class="row">
                        <div class="col-12">
                            <table id="modalTableChecked" class="w-100">
                                <thead>
                                <tr>
                                    <td class="pb-4">
                                        <span v-if="this.prdStatus==1" style="font-size:16px;font-weight: 500;color: black;">
                                            {{$t('message.common.inActiveProduct', {code: this.prdCode})}}
                                        </span>                                        
                                        <span v-if="this.prdStatus==0" style="font-size:16px;font-weight: 500;color: black;">
                                            {{$t('message.common.reActiveProduct', {code: this.prdCode})}}
                                        </span>                                        
                                    </td>
                                </tr>
                                </thead>                                
                            </table>
                        </div>
                       
                    </div>
                     <div class="row">
                        <div class="col-1">
                            <label class="container"> 
                                <input type="radio" name="rdoStore" :checked="this.selected == 1 ? true : false" id="rdoSltBranch" class="chkType" @click="allBranch()" value="1">                                
                            </label>
                        </div>
                       <div class="col-11 py-1">
                            <span>{{$t('message.ModalFileUploadProducts.titleAllBranch')}}</span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-1">
                            <label class="container ml-0">
                                <input type="radio" name="rdoStore" id="rdoSltBranch" class="chkType"  @click="arrBranch()" value="0"  :checked="this.selected == 0 ? true : false">
                            </label>
                        </div>
                       <div class="col-2 py-1">
                            <span class="">{{ $t('message.common.branch') }}</span>
                            
                        </div>
                        <div class="col-9">
                            <q-select name="branchIDs" :readonly="this.selected == this.$constant.is_all_branch" :default-options="branches" :multiple="true" v-model="entry.branchIDs" :selectAll="true" 
                            :placeholder="$t('message.ModalProductFormTabMain.placeholder.branch')" :prdStyle="true"  :styleInput="'min-w-100'"/>  
                        </div>

                    </div>
                    <div class="row py-3" id="textNote">
                        <div class="col-12">
                              <span>{{ $t('message.LocationIndex.note') }}: </span>      
                        </div>
                       <div class="col-12 pt-1">
                            <span>- {{ $t('message.common.keepInforationInventory') }} </span>
                            <br class="py-2">
                            <span>- {{ $t('message.common.discontinued') }} </span>
                        </div>
                    </div>
                     <div class="row">
                        <div class="col-12 text-right">
                            <button class="btn btn-save mx-2" @click="submit(this.prdId, this.entry.branchIDs, this.prdStatus)">
                                <i class="fa fa-check mr-1" />
                                {{ $t('message.button.confirm') }}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.button.skip') }}
                            </button>    
                        </div>
                       
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.table thead th
#modalConfirm .modal-header {
    border-bottom: 0 !important;
}
.align{
    width: 100%;
    text-align: center !important;
}
#textNote{
    color:#6e6b6bb0;
    font-style: italic;
}
</style>
<script>
import {$post, $get} from "@/lib/utils";
import store from "@/store";
import QSelect from "@/components/QSelect";
import toastr from 'toastr';
import emitter from '@/lib/emitter';
export default {
    name: "ModalProductStopWorking",
    components: {QSelect},
    data(){
        const globalConfig = store.state.GlobalConfig;
        return {           
            data: [],
            afterRemove: false,
            isAlign: false,
            doneRemove: null,
            falseRemove: null,
            dataFalseRemove: [],
            paginate: null,
            multi: true,
            prdId:null,
            prdCode: null,
            prdStatus:null,
            branches:globalConfig.branches,
            entry:{
                branchIDs:[]
            },
            selected:1
        }
    },
    props: {

    },
    methods: {
        async show(id, code, status) {
            this.prdId = id;
            this.prdCode = code;
            this.prdStatus = status;
            await this.getBranches();
            window.jQuery(this.$refs.modal).modal('show');
        },
        async getBranches(){
            const res = await $get('/v1/products/get-branches', {id: this.prdId});
            if(res && res.data){
                this.branches = res.data;
            }
        },
        allBranch(){
            this.selected = this.$constant.is_all_branch;
            this.entry.branchIDs = null;
            emitter.emit('clearOptionsQselect');
        },
        arrBranch(){
            this.selected = 0;
        },
       async submit(prdId, branchIDs, status){           
            let res;
            if(status == 1) {
                res = await $post('/v1/products/inActive', {
                    id:prdId, branchId:branchIDs, type:this.selected
                });
            }
            else {
                res = await $post('/v1/products/Active', {
                id:prdId, branchId:branchIDs, type:this.selected
                });
            }
            
            if(res.code == 200) {
                toastr.success(res.message);
            }
            else{
                toastr.error(res.message);
            }
            this.$emit('inputData');
            window.jQuery(this.$refs.modal).modal('hide');
        }
   
    }
}
</script>

