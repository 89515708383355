<template>
 <div ref="modal" class="modal fade mx-0" tabindex="-1" role="dialog" id="modalConnectTransporter" data-backdrop="static">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header py-1 mx-2 border-none">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body mx-2 my-4 d-flex">
                <div class="col-lg-6">
                    <img src="../../../public/img/GHN.png" class="w-100">
                </div>
                <div class="col-lg-6">
                    <h3 class="text-primary text-bold">{{ $t('message.ModalConnectGHN.title') }}</h3>
                    <div class="row m-0 p-0 my-4 border-bottom">
                        <div class="col-3 text-bold d-flex align-items-center">
                            {{ $t('message.ModalConnectGHN.token') }}
                        </div> 
                        <div class="col-9 border-left">
                            <input type="text" v-model="token" id="token" name="token" class="border-none w-100 py-2" :placeholder="$t('message.ModalConnectGHN.placeholder.token')" autocomplete="false" autofocus>
                        </div>
                    </div>
                    <div class="row m-0 p-0 my-4 border-bottom">
                        <div class="col-3 text-bold d-flex align-items-center">
                            {{ $t('message.ModalConnectGHN.IDShop') }}
                        </div> 
                        <div class="col-9 border-left">
                            <input type="text" v-model="IDShop" id="IDShop" name="IDShop" class="border-none w-100 py-2" :placeholder="$t('message.ModalConnectGHN.placeholder.IDShop')" autocomplete="false" autofocus>
                        </div>
                    </div><div class="row m-0 p-0 my-4 border-bottom">
                        <div class="col-3 text-bold d-flex align-items-center">
                            {{ $t('message.ModalConnectGHN.name') }}
                        </div> 
                        <div class="col-9 border-left">
                            <input type="text" v-model="name" id="name" name="name" class="border-none w-100 py-2" :placeholder="$t('message.ModalConnectGHN.placeholder.name')" autocomplete="false" autofocus>
                        </div>
                    </div>
                    <div class="row m-0 p-0 my-4"> 
                        <div class="col-12 text-center">
                            <button class="btn btn-save" @click="submit()">
                                {{ $t('message.ModalConnectGHN.button.login') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
import { $alert, $post } from '@/lib/utils';
import toastr from 'toastr';
import store from "../../store.ts";

export default {
    name: "ModalConnectGHN",
    data(){
        return {
            token: null,
            IDShop: null,
            name: null
        }
    },
    emits: ['reload'],
    methods: {
        show() {
            window.jQuery(this.$refs.modal).modal('show');
        },
        closed() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async submit () {
            const result = await $post('/v1/giao-hang-nhanh/add-info', {
                token: this.token,
                shopId: this.IDShop,
                userName: this.name,
            });
            if (result.code === 422) {
                const errors = result.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: result.code, message: errors[key][0]});
                });
            }else{
                $alert({code: 200, message: this.$t('message.ModalLoginZalo.message.login_success')});
                this.$emit('reload');
                this.closed();
            }
        },
    },
    beforeUnmount() {
        this.closed();
    },
    unmounted() {
        this.closed();
    },
    
}
</script>

