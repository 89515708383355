<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h6 class="modal-title py-2">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.title') }}</h6>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row m-0 p-0">
                        <div class="col-lg-3">
                            <b>{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.invoice_code') }}: {{ this.entry.invoiceCode }}</b>
                        </div>
                        <div class="col-lg-3">
                            <b>{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.delivery_code') }}: {{ this.entry.deliveryCode }}</b>
                        </div>
                        <div class="col-lg-6">
                            <b>{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.transporter') }}: {{ this.entry.transporterName }}</b>
                        </div>
                    </div>
                    <div class="row m-0 p-0 pt-4">
                        <div class="col-lg-4">
                            <div class="row m-0 p-0" v-if="this.entry.price && this.hasCod">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.total_fee') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_total" 
                                        :checked="entry.type == this.$constant.type_payment_total ? true : false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0">
                                    <InputNumberFormat v-model="entry.total" :max="this.max_price" :noAlert="true" 
                                    :readonly="entry.type != this.$constant.type_payment_total ? true : false" :pxNone="true" 
                                    :bgTransparent="true" :cardTransaction="true" 
                                    class="mb-0"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0 pt-2" v-if="this.hasCod">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.cod') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_cod" 
                                        :checked="entry.type == this.$constant.type_payment_cod ? true : false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0">
                                    <InputNumberFormat :readonly="entry.type != this.$constant.type_payment_cod ? true : false"
                                    v-model="entry.priceCodPayment" :pxNone="true" :bgTransparent="true" :cardTransaction="true" 
                                    :max="this.max_price_cod" :noAlert="true"
                                    class="mb-0"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0 pt-2" v-if="this.entry.price">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.fee_ship') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_fee_ship"
                                        :checked="entry.type == this.$constant.type_payment_fee_ship ? true : false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0 text-right" v-text="n(entry.price)"></div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row m-0 p-0">
                                <div class="col-lg-4">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.time') }}:</div>
                                <div class="col-lg-8 p-0 border-bottom-1">
                                    <SelectDateTimePicker :modelValue="entry.createdAt" v-model="entry.createdAt" :borderActive="true" :backgroundColor="'bg-white'"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0 pt-4">
                                <div class="col-lg-4">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.method') }}:</div>
                                <div class="col-lg-8 p-0">
                                    <SelectSearch :readonly="true" :vModel="true" v-model="entry.method" :modelValue="entry.method" :defaultOption="this.$constant.optionMethodPayment"></SelectSearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <textarea v-model="entry.note" :placeholder="$t('message.DeliveryIndex.note')" class="noteStyle px-4 text-gray font-italic border-bottom border-bottom-focus-active"></textarea>
                            <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                        </div>
                    </div>
                    <div class="row m-0 p-0 mt-4">
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_cod">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_receipt') }}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_fee_ship">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_payment') }}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_total">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_all') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mx-1" @click="hide()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$post, $alert} from "@/lib/utils";
import toastr from 'toastr';
import AppPermission from '@/components/AppPermission.vue';
import SelectDateTimePicker from "@/components/SelectDateTimePicker";
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import moment from 'moment';
import emitter from '@/lib/emitter';
export default {
    name: "ModalPaymentAndCreateRefund",
    components: {AppPermission, SelectDateTimePicker, SelectSearch, InputNumberFormat},
    data(){
        return {
            entry: {},
            max_price: 0,
            max_price_cod: 0,
            hasCod: false,
        }
    },
    methods: {
        show(entry) {
            if(entry){
                this.entry.invoiceCode = entry.invoice && entry.invoice.code ? entry.invoice.code : null;
                this.entry.invoiceId = entry.invoice && entry.invoice.id ? entry.invoice.id : null;
                this.entry.invoiceCreatedAt = entry.invoice && entry.invoice.created_at ? entry.invoice.created_at : null;
                this.entry.deliveryId = entry.id ?? null;
                this.entry.deliveryCode = entry.code ?? "--";
                this.entry.transporterId = entry.transporter && entry.transporter.id ? entry.transporter.id : null;
                this.entry.transporterName = entry.transporter && entry.transporter.name ? entry.transporter.name : null;
                this.entry.price = entry.priceStill ? +entry.priceStill : null;
                this.entry.priceCodPayment = entry.priceCodPaymentStill ? +entry.priceCodPaymentStill : null;
                this.hasCod = this.entry.transporterId == this.$constant.ghn_transporter_id ? false : (this.entry.priceCodPayment ? true : false);
                this.max_price_cod = this.entry.priceCodPayment;
                this.entry.method = 1;
                this.entry.type = this.entry.price ? this.$constant.type_payment_fee_ship : this.$constant.type_payment_cod;
                this.entry.total = this.entry.transporterId == this.$constant.ghn_transporter_id ? null : (+this.entry.priceCodPayment - +this.entry.price);
                this.max_price = this.entry.total;
                this.entry.branchId = entry.branchId ?? null;
                this.entry.customerId = entry.invoice && entry.invoice.customerId ? entry.invoice.customerId : null;

                if(!this.entry.total && !this.entry.price){
                    $alert({code: 422, message: this.$t('message.DeliveryIndex.alert.payment_error')});
                    return;
                }

                emitter.emit('clearSelectDateTimePicker');
                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        hide(){
            this.clear();
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        clear(){
            this.entry = {
                note: null,
                invoiceCode: null,
                invoiceId: null,
                deliveryId: null,
                deliveryCode: null,
                transporterId: null,
                transporterName: null,
                price: null,
                priceCodPayment: null,
                method: null,
                type: null,
                total: null,
                branchId: null,
                customerId: null,
            }
        },
        async submit(){
            let now = moment().format('YYYY-MM-DD hh:mm:ss');
            if(now < moment(this.entry.createdAt).format('YYYY-MM-DD hh:mm:ss')){
                $alert({code: 422, message: this.$t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.message_validate_time_error')});
                return;
            }
            // if(moment(this.entry.invoiceCreatedAt).format('YYYY-MM-DD hh:mm:ss') > moment(this.entry.createdAt).format('YYYY-MM-DD hh:mm:ss')){
            //     $alert({code: 422, message: this.$t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.message_validate_created_at', {code: this.entry.invoiceCode ? this.entry.invoiceCode : ''})});
            //     return;
            // }
            this.entry.cod = this.entry.priceCodPayment;
            this.entry.priceFee = +this.entry.price;
            if(this.entry.type == this.$constant.type_payment_total){
                this.entry.cod = +this.entry.total + +this.entry.price;
            }
            if(this.entry.type == this.$constant.type_payment_cod){
                this.entry.priceFee = 0;
            }
            if(this.entry.type == this.$constant.type_payment_fee_ship){
                this.entry.cod = 0;
            }
            const res = await $post('/v1/deliveries/payment-and-create-refund', {...this.entry});
            if(res.result && res.result.status){
                $alert({code: 200, message: this.$t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.message_success', {code: this.entry.deliveryCode})});
                this.$emit('reload');
                this.hide();
            }
            if(res.result && !res.result.status && res.result.message){
                $alert({code: 500, message: res.result.message});
            }
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>

<style scoped>
.noteStyle {
    width: 100% !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.container-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
</style>
