<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog" :class="(errors?.length > 0) ? 'modal-dialog-scrollable' : 'modal-lg'" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4" v-text="(errors?.length > 0) ? $t('message.CouponIndex.modal.create_or_update.button.cancel_coupon') : $t('message.common.confirm')"></h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3" :class="this.errors?.length > 0 ? 'bodyModal' : '' ">
                    <div class="tab-content" id="myTabContent" v-if="this.errors?.length > 0">
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-12 pl-0">
                                    {{$t('message.common.cancelCouponError')}}:
                                </div>
                            </div>
                            <div class="row mx-4 my-0 mt-2" v-for="error in this.errors" :key="error.id">
                                <div class="col-12 p-0">
                                    - {{ error.code }}
                                </div>
                            </div>                          
                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent" v-else>
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-3 mt-1">
                                <div class="col-12 pl-0">
                                    {{$t('message.common.confirmCancelCoupon')}}
                                </div>
                            </div>
                            <div class="row mx-4 my-4 pb-0">
                                <div class="col-12 d-flex justify-content-end p-0">
                                    <button class="btn btn-danger" @click="cancelCoupon">
                                        <i class="fa fa-check mr-2"></i>
                                        {{$t('message.button.confirm')}}
                                    </button>
                                    <button class="btn btn-secondary ml-3" @click="hide">
                                        <i class="fa fa-ban mr-2"></i>
                                        {{$t('message.button.skip')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- footer -->
                <div class="modal-footer my-0 py-0 border-top-0" v-if="this.errors?.length > 0" style="height:50px;">
                    <div class="row mx-4 my-4 pb-0">
                        <div class="col-12 d-flex justify-content-end p-0">
                            <button class="btn btn-save" @click="hide">
                                <i class="fa fa-check-square mr-2"></i>
                                {{$t('message.button.confirm')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import Pagination from "@/components/Pagination";
import {$post, clone} from "@/lib/utils";
import toastr from 'toastr';
import moment from 'moment';
export default {
    name: "CancelCoupons",
    components: {Pagination, InputNumberFormat, SelectSearch},
    data () {
        return {
            invoiceData: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            errors: [],
            releaseVoucher: {},
            isShow: false,
            couponId: null,
        }
    },
    created() {
    },
    methods: {
        async show (id = null) {
            this.couponId = id;
            this.clearData();
            if (id) {
                const res = await $post('/v1/vouchers/check-status-vouchers', {ids: id, status: this.$constant.statusNotUsedYetVoucherCoupon});
                if(res && res.result && res.result?.length > 0){
                    this.errors = res.result;
                }
                if(this.errors?.length == 0){
                    this.releaseVoucher.quantity = id?.length;
                }
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        hide () {            
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(){
            let arr = [];
            this.invoiceData.forEach(item => {
                if(item.invoice_items){
                    arr = item.invoice_items;
                }
            });
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        clearData(){
            this.invoiceData = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.errors = [];
            this.releaseVoucher = {
                quantity: 0,
                status: this.$constant.statusActive,
                releaseDate: moment().locale('vi').format('dd/MM/yyyy'),
                userCreate: 0,
                userName: null,
                value: 0,
                payment: 0,
                method: 1,
                note: null,
                numberCard: null,
            };
        },
        async doFilterSearch(v){
            if(v.model){
                if(v.label == 'method'){
                    this.releaseVoucher.method = v.model;
                }
            }
        },
        openInvoice(code){
            let route = this.$router.resolve({
                path: '/invoices/index',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },       
        async cancelCoupon(){
            const res = await $post('/v1/coupons/cancel-vouchers', {ids: this.couponId});  
            if(res && res.status.code == 200 && res.result.code == 200){                
               toastr.success(this.$t('message.common.cancelCouponSuccess'));
               window.jQuery(this.$refs.modal).modal('hide');
               this.$emit('reloadData');
               this.$emit('removeChecked');
            }
            else {                
                if(res && res.result.code == 201) {
                    toastr.error(res.result.message);
                }
                else {
                    toastr.error(this.$t('message.common.cancelCouponFailed'));
                }
            }
        }
    },
    watch: {
        'releaseVoucher.value': {                
            handler(newValue){                          
                this.releaseVoucher.payment = newValue;
            },        
            deep: true,
            
        },   
        'releaseVoucher.method': {                
            handler(newMethod){                          
                this.isShow = (newMethod != this.$constant.methodCashId) ? true : false;
            },        
            deep: true,
            
        },    
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.fa-pencil-alt, .fa-search{
    position: absolute;
    left: 0px;
    bottom: 10px;
}
</style>