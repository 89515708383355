<template>

    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" :class="isTab === $constant.tab.first ? 'modal-dialog-scrollable' : ''"
            role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h6 class="modal-title py-2 px-4">
                        {{$t('message.common.loginMisaTitle')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- <div class="modal-header custom__list-tab bg-light px-3 pt-3 pb-0 text-center">
                    <div :class="[isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.first)">Thiết lập email</div>
                    <div :class="[isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.second)">Thiết lập zalo</div>
                </div> -->

                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel"
                            aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                            <div class="row mx-4 my-4 pb-4 mt-1">
                                <div class="col-12 pl-0">
                                    <div class="d-flex d-flex align-items-center">
                                        <div class="col-lg-6 pl-5 justify-content-center">
                                            <img src="../../../../public/img/misa.png" class="w-100">
                                        </div>
                                        <div class="col-lg-6">
                                            <h3 class="text-custom text-bold">
                                                {{$t('message.common.loginMisa')}}
                                            </h3>
                                            <div class="row m-0 p-0 my-4 border-bottom">
                                                <div class="col-3 text-bold d-flex align-items-center">{{$t('message.customerIndex.taxCode')}} <label
                                                        class="text-danger px-0">*</label></div>
                                                <div class="col-9">
                                                    <input type="text" v-model="model.taxCode" id="phone" name="taxcode"
                                                        class="border-none w-100 py-2" :placeholder="$t('message.customerIndex.taxCode')"
                                                        autocomplete="off" autofocus>
                                                </div>
                                            </div>
                                            <div class="row m-0 p-0 my-4 border-bottom">
                                                <div class="col-3 text-bold d-flex align-items-center">
                                                    {{$t('message.LoginScreen.username')}}
                                                    <label class="text-danger px-0">*</label>
                                                </div>
                                                <div class="col-9">
                                                    <input type="text" id="api_key" v-model="model.userName"
                                                        name="username" class="border-none w-100 py-2"
                                                        :placeholder="$t('message.LoginScreen.username')" autocomplete="off">
                                                </div>
                                            </div>
                                            <div class="row m-0 p-0 my-4 border-bottom">
                                                <div class="col-3 text-bold d-flex align-items-center">{{$t('message.LoginScreen.password')}} <label
                                                        class="text-danger px-0">*</label></div>
                                                <div class="col-9">
                                                    <input type="password" v-model="model.password" name="password"
                                                        class="border-none w-100 py-2 form-control"
                                                        :placeholder="$t('message.LoginScreen.password')" autocomplete="off">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeTime" role="tabpanel" aria-labelledby="time-tab" v-if="isTab === $constant.tab.second"></div>

                        <div class="tab-pane fade show active" id="homeLocation" role="tabpanel"
                            aria-labelledby="location-tab" v-if="isTab === $constant.tab.third">
                            <div class="row mx-4 my-4 pb-2 mt-1"></div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 flex-end p-0">
                            <button type="button" class="btn btn-save mr-2" @click="submit">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>
<script>
import { $post, $get } from "@/lib/utils";
import toastr from 'toastr';
import emitter from "@/lib/emitter";
export default {
    name: "LoginMisa",
    data() {
        return {
            model: {
                taxCode: '',
                userName: '',
                password: ''
            },
            isTab: this.$constant.tab.first,
        }
    },
    methods: {
        async submit() {
            const res = await $post('/v1/misa/login', this.model);
            if (res.result == false) {
                toastr.error(this.$t('message.common.inValidInforLogin'));
            } else {
                if (res.code == 422) {
                    this.isMessageError(res.messages.errors);
                } else {
                    toastr.success(this.$t('message.common.loginMisaSuccess'));
                    this.hide();
                }
            }
        },
        async show(id = null) {

            const result = await $post('/v1/setting-store/count');
            if (result && result.result) {
                toastr.error(this.$t('message.common.existMisaAccount'));
                return;
            }
            this.clearInput();
            if (id === null) {
                window.jQuery(this.$refs.modal).modal('show');
            } else {
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = { ...this.entry };
                this.id = id;
            }
        },
        isMessageError(errMss) {
            if (errMss.userName) {
                toastr.error(errMss.userName);
                return;
            }
            if (errMss.taxCode) {
                toastr.error(errMss.taxCode);
                return;
            }
            if (errMss.password) {
                toastr.error(errMss.password);
                return;
            }
        },
        clearInput() {
            this.entry = {
                name: '',
                description: '',
                status: 1,
            };
            this.default = {
                name: '',
                description: '',
                status: 1,
            };
            this.id = null;
        },
        choseTab(tab) {
            this.isTab = tab;
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            emitter.emit('reloadDataEmployee');
            window.jQuery(this.$refs.modal).modal('hide');
        },
    },
}
</script>
<style scoped></style>