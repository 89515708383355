<template>  
  <!-- Loader -->
  <div ref="modal" class="modal pr-0" tabindex="-1" role="dialog">
    <tr id="loadingaa">
        <div id="test" class="spinner-border text-info d-flex justify-content-center"  role="status"  :class="this.loadingaa ? 'dp-hide' : ''" v-if="loadingaa">
          <span class="sr-only">loading...</span>
        </div>
    </tr>
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header py-3">
          <h5 class="modal-title d-flex">
            <div class="p-0 m-0">
                {{$t('message.ModalFileUploadProducts.title')}}
                <span class="ml-1" style="font-weight: 400;"> ( {{$t('message.ModalFileUploadProducts.titleTemplate')}}
                  <a target="_blank" :href="'../Files/products_template_' + this.language + '.xlsx'">{{$t('message.ModalFileUploadProducts.titleExcel')}}</a>)
                </span>
            </div>
            <div class="container-circle ">
              <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
              <div class="content-circle font-weight-normal">
                <p>
                  {{$t('message.ModalFileUploadProducts.notifyRow1')}}
                </p>
                <label>
                   {{$t('message.ModalFileUploadProducts.notifyRow2')}}
                </label>
              </div>
            </div>
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body my-2 mx-2" @dragover.prevent @drop.prevent>
          <div class="row">
            <div class="col-12 mx-0">
              <!-- Xử lý trùng mã hàng, khác tên hàng -->
              <div>
                <b>{{$t('message.ModalFileUploadProducts.titleActionExistCode')}}</b>
                <label class="container-radio mt-3 ml-2">          
                  {{$t('message.ModalFileUploadProducts.titleErrorStop')}}    
                  <input  v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off" v-bind:value="this.$constant.msgErrorStop" checked @click="existValue(this.$constant.msgErrorStop)">                  
                  <span class="checkmark"></span>
                </label>              
                <label class="container-radio ml-2">
                    {{$t('message.ModalFileUploadProducts.titleCreateProductNew')}}   
                  <input v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" v-bind:value="this.$constant.createProductNew" @click="existValue(this.$constant.createProductNew)">
                  <span class="checkmark"></span>
                </label>
                <label class="container-radio ml-2">
                 
                   {{$t('message.ModalFileUploadProducts.titleUpdateProduct')}}   
                  <input v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" v-bind:value="this.$constant.updateProduct" @click="existValue(this.$constant.updateProduct)">
                  <span class="checkmark"></span>
                </label>
                <!-- <label class="container ml-2">
                  {{$t('message.ModalFileUploadProducts.updateCreateProduct')}}                     
                  <input v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" v-bind:value="this.$constant.updateCreateProduct" @click="existValue(this.$constant.updateCreateProduct)">
                  <span class="checkmark"></span>
                </label> -->
                <hr class="mb-3" />
              </div>
              <!-- Cập nhật tồn kho -->
              <!-- <div>
                <b>
                   {{$t('message.ModalFileUploadProducts.titleUpdateInventory')}}   
                </b>
                <label class="container mt-3 ml-2">
                    {{$t('message.ModalFileUploadProducts.titleUpdateInventoryNo')}}                   
                    <input  v-model="rdoInventory" name="rdoInventory" type="radio" class ="cheker" autocomplete="off" v-bind:value="'0'" checked @click="InventoryValue('0')">                  
                  <span class="checkmark"></span>
                </label>
                <label class="container ml-2">
                  {{$t('message.ModalFileUploadProducts.titleUpdateInventoryYes')}}                   
                  <input v-model="rdoInventory" name="rdoInventory" type="radio" class ="cheker" v-bind:value="'1'" @click="InventoryValue('1')">                  
                  <span class="checkmark"></span>
                </label>
              </div>               -->
            </div>
          </div>

          <div class="row my-2 mx-2">
            <div class="col-12 text-right mx-0 px-0" id="divUpload">
              <label class="custom-file-upload">                
                <!-- Button Chọn file -->
                <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload"  accept=".xls, .xlsx" />
                <i class="fa fa-cloud-upload"></i> {{$t('message.button.import.selectFile')}}
              </label>
            </div>                        
          </div>
          <div class="row py-2 mx-2" id = "rowMessage" v-if="this.actionShow">
            <div class="col-6">              
              <label>{{ this.fileName }}</label>              
            </div>
            <div  class="col-6 text-danger text-right">
              <a href="javascript:;" @click="showAction(false)" class="text-danger">
                <i class="fa fa-times"></i>
              </a>              
            </div>            
          </div>
          <div class="row mt-3 mx-2" >            
            <div class="col-12 text-right px-0 mx-0" v-if="this.actionShow">
              <!-- Button thực hiện -->
              <input type="button" class="btn btn-primary" :value="$t('message.button.import.action')" id="btnAction" @click="UpdateFile()"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>   

</template>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#loadingaa {
  position: fixed;
  top:25%;
  left:45%;
  z-index: 100;
}
input[type="file"] {
  display: none;
}

#btnAction{
  width: 100px;  
}
#rowMessage{
  background-color: rgb(224 241 241);
  border-radius: 5px;
  
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-flex;
  padding: 6px 12px;
  cursor: pointer;
  background: $base-color;
  color: #ccffff;
  margin-left: 5px;
  height: 40px;
  border-radius: 5px;
  align-items: center;
}

.container input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal-dialog {
  max-width: 800px !important;
}

.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: 0;
  left: 45px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
}

.icon-circle-custom:hover+.content-circle {
  display: block;
}

.status-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: -5px;
  left: 257px;
  z-index: 1;
  border-radius: 5px;
  line-height: 25px;
  text-align: justify;
}

.status-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
  
}

.icon-circle-custom:hover+.status-circle {
  display: block;
}
</style>
<script>
import QSelect from "../../components/QSelect.vue";
import { $upload, toastr, $alert} from "@/lib/utils";
import store from "../../store";
import emitter from "@/lib/emitter";
export default {
  name: "ModalFileUploadProducts",
  props: ["multiple", "type", "group"],
  
  components: {
    QSelect,
    // ModalErrorMessage
  },
  data() {
    const globalConfig = store.state.GlobalConfig;
    return {
      currentBranchID: store.state.CurrentBranch.id,
      rdoCodeExist: '0' ,
      rdoInventory: '0',
      rdoStatus:'all',
      branchIDsShow: false,
      actionShow: false,
      patient: {
        file: "",
        data:"",
        branchIDs:[]
      },
      branches: globalConfig.branches,
      picked: "1",
      pickedYesNo: "no",
      pickedStatus: "no",
      loadingaa:false,
      entry:
      {
        branchIDs: [],
      },
      fileName: "",
      language: localStorage.getItem('language') ?? 'vi'
    };
  },
  methods: {
    showAction(status) {
      this.actionShow = status;  
      if(status == false )    {
        this.$refs.fileupload.value = '';
      }
    },
    showBranch(value) {
      this.branchIDsShow = value;
    },
    async show() {
      this.showAction(false);
      this.rdoCodeExist = '0';
      this.rdoInventory = '0';
      this.rdoStatus = 'all';
      this.branchIDsShow = false;
      this.entry.branchIDs = [];
      window.jQuery(this.$refs.modal).modal("show");      
    },
    existValue(value){      
      this.rdoCodeExist = value;      
      let data = {
          'rdoCodeExist': this.rdoCodeExist,
          'rdoInventory': this.rdoInventory,
          'rdoStatus': this.rdoStatus,  
          // 'branchID': this.entry.branchIDs.length > 0 ? this.entry.branchIDs : null
      }      
      this.patient.data = JSON.stringify(data);
    },
    InventoryValue(value){           
      this.rdoInventory= value;      
      let data = {
          'rdoCodeExist': this.rdoCodeExist,
          'rdoInventory': this.rdoInventory,
          'rdoStatus': this.rdoStatus,   
          // 'branchID': this.entry.branchIDs.length > 0 ? this.entry.branchIDs : null
       }      
      this.patient.data = JSON.stringify(data);     
    },
    branchValue(status, value){
      this.branchIDsShow = status;      
      this.rdoStatus=value;
      let data = {
          'rdoCodeExist': this.rdoCodeExist,
          'rdoInventory': this.rdoInventory,
          'rdoStatus': this.rdoStatus,         
          // 'branchID': this.entry.branchIDs.length > 0 ? this.entry.branchIDs : null
       }
      
      this.patient.data = JSON.stringify(data);
      
    },
    async UpdateFile() {       
      this.loadingaa = true;
      this.patient.type = this.rdoCodeExist;
      // const res = await $upload("/v1/products/importExcel", [], this.patient);
      const res = await $upload("/v1/products/import-excel", [], this.patient);
      let content = {
        title:res.title,
        code: res.code,
        message: res.message,
        error: res.errors,
        totalRecord: res.totalRecord,
        totalAddnew: res.totalAddnew,
        totalUpdate: res.totalUpdate,
        totalError: res.totalError
      }
      if (res.code == 200) {  
        this.loadingaa =false      
        emitter.emit("errMsgShow", true);
        emitter.emit("dataMsgShow", content);
        // emitter.emit("loadDataProducts");
        emitter.emit("reloadData");
        this.$emit('reloadData');        
        // this.$refs.ModalErrorMessage.show(content);
      } else {
        this.loadingaa =false;
        emitter.emit("errMsgShow", true);
        emitter.emit("dataMsgShow", content);        
        // this.$refs.ModalErrorMessage.show(content);
        this.$emit('reloadData');
      }
      window.jQuery(this.$refs.modal).modal("hide");
    },

    onFileChange(event) {   
      let data = {
          rdoCodeExist: this.rdoCodeExist,
          rdoInventory: this.rdoInventory,
          rdoStatus: this.rdoStatus,    
          // branchID:this.entry.branchIDs.length > 0 ? this.entry.branchIDs : null
       }
      
      this.patient = {
        file: "",
        data: "",
      };
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) {        
        return;
      }
      
      this.fileName = files[0].name;
      this.patient.file = files[0];
      this.patient.data = JSON.stringify(data);      
      if(files[0].name == "") {
         this.actionShow = false;         
      }
      else {
        this.actionShow = true;
      }
      
      
      // this.$refs.fileupload.value = null;
    },
    dragFile(e) {
      this.onFileChange(e);
      // this.patient.file = e.dataTransfer.files;
    },
  },
};
</script>
