<template>
  <div class="row">
    <div class="col-lg-6 px-0 mx-0" style="border: 1px solid #ccc" id="design">        
      <DesignTemplates
        ref="DesignTemplates"
        :action="this.action"
        :entry="this.entry"
        :html="this.html"
        :content="this.content"
        :printTempId="this.printTemplateId"
        :printTempData="this.printTemplate"
        @changeContent="this.changeContent"  
        />               
    </div>
    <div class="col-lg-6 px-0 mx-0" style="background-color: #eef3f7">
      <ViewTemplates ref="ViewTemplates" 
        :entry="this.data"
        :contenOrigin="contenOrigin" 
        :action="this.action"/>
    </div>
  </div>
</template>
<script>
import {$get} from "@/lib/utils";
import DesignTemplates from "./tabs/DesignTemplates";
import ViewTemplates from "./tabs/ViewTemplates";
export default {
  name: "ReturnTemplate",
  components: { DesignTemplates, ViewTemplates },
  mounted(){    
    this.getPrintTemplateIds();
  },
  data() {
    return {      
      printPaperId: 2,
      action: "TabRefunds",
      entry:{},      
      data:{},
      html:'',
      printTemplate: null,      
      content:null,
      contenOrigin: null,
      printTemplateId: this.$constant.defaultRefundTemplateId,
    };
  },  
  methods: {     
        async getPrintTemplateIds(){            
            const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabRefunds'});
            if(res && res.status.code == 200 && res.result.length > 0){
                res.result.forEach(item => {
                  if(!this.printTemplateId){
                    this.printTemplateId = item.id;
                  }
                });
            }                  
        },
        changeContent(newContent){        
          this.contenOrigin = newContent;
        },
  },
  watch: {    
      content: {                
          handler(newData){                  
            this.content = newData;                  
          },
          deep: true
      },            
      printTemplate: {                
          handler(newData){                 
            this.printTemplate = newData;            
          },
          deep: true                
      },
  }
};
</script>
