<script>
    import {$get} from "@/lib/utils";  
    import toastr from 'toastr';      
    export default {
        name: "ProductCharateristicsIndex",
        components: {},
        created(){
            this.update();
        },
        data() {           
            return {
                
            }
        },
       
        methods: {
           async update(){                 
                const res = await $get('/v1/product-charateristics/update', {});                 
                if(res.status.code == 200){
                    toastr.success('Cập nhập loại hàng hóa thành công');
                    window.location.href = '/'
                }
                else{
                    toastr.success('Cập nhập loại hàng hóa không thành công');
                }
           }
        },

        
    
    }
</script>