
<template>
    <div class=" row mx-4 my-4 mt-1 popup-update-price">
        <div class="col-3 p-0 d-flex align-items-center">
            <span class="mb-0 ow-break-word">
                {{$t('message.PriceListDetailIndex.salePrice')}}
                <span class="text-omi">{{'[' + n(+this.priceUpdated?? 0) + ']'}}</span> =
            </span> 
        </div>
        <div class="col-3 p-0">
            <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <template v-for="updatePriceLists in this.updatePriceLists.all" :key="updatePriceLists.id">
                    <span v-if="updatePriceLists.id === this.updatePriceLists.selected" v-text="updatePriceLists.name"></span>
                </template>
            </div>
            <div class="dropdown-menu options-dt" aria-labelledby="dropdownMenuButton">
                <input type="text" class="min-w-100" v-model="this.updatePriceLists.search" @input="onInputPriceList">
                <i class="fa fa-search btn-search"></i>
                <template v-if="this.updatePriceLists.filter?.length > 0">
                    <a class="dropdown-item pl-0" href="javascript:;" v-for="updatePriceLists in this.updatePriceLists.filter" :key="updatePriceLists.id" :value="updatePriceLists.id" @click="choosePriceList(updatePriceLists, item, product)">
                        {{updatePriceLists.name}}<i class="fa fa-check" v-if="this.updatePriceLists.selected == updatePriceLists.id"></i>
                    </a>
                </template>
                <template v-else>
                    <span class="ow-break-word" href="javascript:;">
                        {{$t('message.common.notFound')}}
                    </span>
                </template>
            </div>
        </div>
        <div class="col-2 p-0 d-flex align-items-center justify-content-around">
            <button type="button" class="btn btn-secondary text-white" 
            v-for="opt in this.$constant.calculatorOptions" :key="opt.id" :disabled="this.updatePriceLists.selected === 0"
            :class="this.updatePriceLists.selected != 0 && this.calculatorOption == opt.id ? 'btn btn-save' : ''"
            @click="changeCalculatorOption(opt.id)">{{opt.name}}</button> 
        </div>
        <div class="col-2 p-0 d-flex align-items-center">
            <input type="text" :maxlength="maxInputPrice" class="input-custom min-w-100 h-100 text-right bg-transparent"
            @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"
            :min="0" :disabled="this.updatePriceLists.selected === 0" v-model="this.calcPriceType" @input="onInputPriceUpdate()"> 
        </div>
        <div class="col-2 p-0 d-flex align-items-center justify-content-around">
            <button type="button" class="btn btn-secondary text-white" 
            v-for="opt in this.$constant.discountOptions" :key="opt.id" 
            :disabled="this.updatePriceLists.selected === 0" :class="this.updatePriceLists.selected != 0 && this.discountOption == opt.id ? 'btn btn-save' : ''"
            @click="changeDiscountOption(opt.id)">{{opt.name}}</button> 
        </div>
        <div class="col-12 pl-0 mt-4 d-flex align-items-center" v-if="this.updatePriceLists.selected != 0">
            <input type="checkbox" class="chkType" v-model="this.applyAll" id="applyAll">
            <label class="px-2 my-0" for="applyAll">
                {{$t('message.common.applyFormulaForAll')}}
            </label>
        </div>
        <div class="col-12 mt-4 flex-end">
            <button type="button" class="btn btn-save mr-2" @click="updatePriceList(field.id, item.productUnitId, item.id)">
                <i class="fa fa-save mr-1"></i>
                {{$t('message.button.save')}}
            </button>
            <button type="button" class="btn btn-secondary text-white  mr-2" @click="hideFormUpdate()">
                <i class="fa fa-ban mr-1"></i>
                {{$t('message.button.skip')}}
            </button>
        </div>
    </div>
</template>

<style scoped lang="scss">
@import "@/assets/css/vars.scss";

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.img-container {
    display: flex;
    flex-direction: row;
    gap: 1rem
}

.img-list {
    display: flex;
    flex-direction: column;
}

.img-main-item {
    width: 15vw;
    object-fit: cover;
}

.text-title {
    line-height: 52px;
}

#number_records {
    margin-top: 11px;
}

#typeCard {
    margin-top: -8px;
}

.child-category-container {
    margin-left: 1rem
}

.child-category-item {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem
}
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 12px;
}
.dropdown-item> .fa-check{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.dropdown-toggle{
  white-space: normal;
}
.dropdown-item{
  white-space: normal;
  overflow-wrap: break-word;
}
.input__price{
    border: none;
    background: no-repeat;
    border-bottom: 1px solid #C7C5C5;
}
.input__price-focus{
    border-bottom: 2px solid $base-color;
}
.popup-update-price{
    position: absolute;
    top: 20px;
    right: 60px;
    z-index: 99;
    width: 700px !important;
    background: white;
    box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
    padding: 1.4rem;
    border: none;
    border-radius: 7px;
}
#divTable {
    min-height: 260px;
}
.border-bottom-focus:hover{
    border-bottom: 2px solid $base-color !important;
}
</style>
<script>
import { $get, $post, $alert, removeVietnameseTones, cloneObject } from "@/lib/utils";
import currencyMixin from "@/currencyMixin";
export default {
    name: "ModalUpdatePrice",
    mixins: [currencyMixin,],
    props: ['priceUpdatedData', 'updatePriceListsData', 'productData', 'field', 'item', 'searchSidebar', 'productId'],
    data() {
        return {        
            updatePriceLists: this.updatePriceListsData,
            priceUpdated: this.priceUpdatedData,
            product: this.productData,
            applyAll: false,
            priceUpdateDefault: this.priceUpdatedData,
            discountOption: this.$constant.discountTypeMoney,
            calculatorOption: this.$constant.calculatorTypePlus,
            calcPriceType: 0,
            maxInputPrice: 3,
        }
    },
    methods: {
        hideFormUpdate() {
            this.$emit('hideFormUpdate');
        },
        getProducts() {
            this.$emit('getProducts');
        },
        onInputPriceList(){
            if(this.updatePriceLists.search == ''){
                this.updatePriceLists.filter = this.updatePriceLists.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.updatePriceLists.all.length; index++) {
                if (removeVietnameseTones(this.updatePriceLists.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.updatePriceLists.search.toLowerCase())) > -1){
                    arr.push(this.updatePriceLists.all[index]);
                }
            }
            this.updatePriceLists.filter = arr;
        },
        choosePriceList(entry, item, product){
            this.updatePriceLists.selected = entry.id;
            let option;
            this.$constant.priceListOptions.forEach(opt => {
                if(opt.id == this.updatePriceLists.selected){
                    option = opt.name;
                }
            })            
            switch (option) {
                case this.$constant.text_current_price:
                    this.priceUpdated = Number(item.price);
                    this.priceUpdateDefault = Number(item.price);
                    break;
                case this.$constant.text_common_price:
                    this.priceUpdated = Number((product.product_units && product.product_units[0] ? product.product_units[0].price :  (product.price ? product.price : null)));
                    this.priceUpdateDefault = Number((product.product_units && product.product_units[0] ? product.product_units[0].price :  (product.price ? product.price : null)));
                    break;
                case this.$constant.text_last_price:
                    this.priceUpdated = Number(product.price_import[0] ? (product.price_import[0].price - product.price_import[0].discount/product.price_import[0].quantity) : 0);
                    this.priceUpdateDefault = Number(product.price_import[0] ? (product.price_import[0].price - product.price_import[0].discount/product.price_import[0].quantity) : 0);
                    break;
            }
        },
        changeCalculatorOption(id){
            this.calculatorOption = id;
            this.onInputPriceUpdate();
        },
        onInputPriceUpdate(){
            let price = 0;
            if(this.calcPriceType?.length > 0){
                this.calcPriceType = this.calcPriceType.replace(/,/g,'');
            }
            switch (this.discountOption) {
                case this.$constant.discountTypePercent:
                    price = (this.priceUpdateDefault * (+this.calcPriceType / 100));
                    this.maxInputPrice = 3;
                    break;
                case this.$constant.discountTypeMoney:
                    price = (+this.calcPriceType);
                    this.maxInputPrice = 15;
                    break;
            }
            switch (this.calculatorOption) {
                case this.$constant.calculatorTypePlus:
                    this.priceUpdated = (+this.priceUpdateDefault + +price);
                    break;
                case this.$constant.calculatorTypeMinus:
                    this.priceUpdated = (+this.priceUpdateDefault - +price);
                    break;
            }
            if(this.priceUpdated < 0){
                this.priceUpdated = 0;
            }
        },
        changeDiscountOption(id){
            if(id != this.discountOption){
                this.calcPriceType = 0;
                this.discountOption = id;
            }
            this.onInputPriceUpdate();
        },
        async updatePriceList(priceListId, productUnitId) {
            const params = {
                productId: this.productId,
                priceListIdSelect: priceListId,
                price: this.priceUpdated,
                productUnitId: productUnitId,
                applyAll: this.applyAll,
                calcPriceType: this.calcPriceType,
                // 1 vnd , 2 %
                type: this.discountOption,
                // 0 là + , 1 la -
                calculatorOption: this.calculatorOption,
                //use search product
                categoryId: this.searchSidebar.categoryId,
                priceListId: priceListId,
                inputSearch: this.searchHead,
                updatePriceLists: this.updatePriceLists.selected
            }
            const res = await $post('/v1/setting-price/update/product-unit-price-list' , {...params});
            if (res.code == 422) {
                $alert({code: 422, message: res.messages.errors.price[0]});
                return;
            } else {
                $alert({code: 200, message: this.$t('message.PriceListDetailIndex.alertUpdateSuccess')});
                this.applyAll = false;
                this.getProducts();
                this.hideFormUpdate();
            }
        }
    },
}
</script>
