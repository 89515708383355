<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">
                        {{$t('message.OrderIndex.button.order')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0 list_categories">
                        <div class="col-12 position-relative p-0">
                            <label class="container-checkbox mb-0">{{$t('message.common.addOrderWhenNotEnoughInventory')}}
                                <input type="checkbox" v-model="options.canOrderOutStock">
                                <span class="checkmark-checkbox"></span>
                            </label>
                            <label class="container-checkbox mb-0 mt-2">{{$t('message.common.sellWhenOrdered')}}
                                <input type="checkbox" v-model="options.canInvoiceOutStock">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div class="modal-footer  border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-save mr-2" @click="submit">
                                <i class="fa fa-save mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.finished')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {$post} from "@/lib/utils";
    import toastr from 'toastr';
    import storeModul from "@/storeModul";
    export default {
        name: "SelectCategories",
        components: {},
        props: ['placeholder','disabled', 'label'],
        data() {
            return {
                options: {
                    canOrderOutStock: true,
                    canInvoiceOutStock: true,
                    IdCanOrderOutStock: null,
                    IdCanInvoiceOutStock: null,
                }
            }
        },
        mounted() {
        },
        methods: {
            async show(options) {
                options.forEach(option => {
                    if (option.id == this.$constant.settingStore.can_Order_out_Stock_detail_Id) {
                        this.options.canOrderOutStock = option.status ? true : false;
                        this.options.IdCanOrderOutStock = option.id;
                    } else {
                        this.options.canInvoiceOutStock = option.status ? true : false;
                        this.options.IdCanInvoiceOutStock = option.id;
                    }
                });
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            async submit(){
                let dataSubmit = [
                    {
                        id: this.options.IdCanOrderOutStock,
                        status: this.options.canOrderOutStock,
                    },
                    {
                        id: this.options.IdCanInvoiceOutStock,
                        status: this.options.canInvoiceOutStock,
                    }
                ];
                const result = await $post('/v1/setting-store/update-multiple-option-details', {options: dataSubmit});
                if (result.result == true) {
                    toastr.success(this.$t('message.SettingPage.success'));
                }
                this.hide();
                storeModul.dispatch('settingStore/statusGetDataSettingTransports', true);
            },
        },
    }
</script>
<style scoped>
.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.list_categories{
    max-height: 300px !important;
    overflow-y: auto;
}
</style>
