<template>    
    <slot v-if="hasPermission(permission) || this.userId == 1"/>    
</template>

<script>
import store from "../store";
export default {
    name: "AppPermission",
    props: ['permission'],
    computed: {
        permissionInfo: () => store.state.PermissionInfo,
        authData: () => store.state.Auth
    },
    mounted() {
        if (!this.permission) {
            console.error('AppPermission: permission is required')
        }
    },
    data(){
        return {
            userId: store.state.Auth.user.id,
        }
    },
    methods: {
        hasPermission(permissionName) {       
           if (permissionName && this.authData && this.authData.user) {
                // if (this.permissionInfo && (this.permissionInfo.isAdmin || this.permissionInfo.isRoot)) {
                if (this.permissionInfo && this.permissionInfo.isRoot) {
                    return true;
                }                 
                if (permissionName &&  Array.isArray(permissionName)) {
                    for (let index = 0; index < permissionName.length; index++) {
                        const per = permissionName[index];
                        if (this.permissionInfo.permissionNameMap[per]){
                            return true;
                        }
                    }
                }
                return !!this.permissionInfo.permissionNameMap[permissionName];
            }   
            return false;
        }
    }
}
</script>

<style scoped>

</style>
