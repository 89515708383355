<template>
    <div class="row p-0 m-0 d-flex align-items-center">
        <div class="col-2 d-flex align-items-center">
            <label class="pl-3 pr-0 pt-2"> {{ $t('message.common.emailTemplate') }}: </label>
        </div>
        <div class="col-3 py-2">
            <SelectSearch :placeholder="$t('message.common.selectTemplate')" :modelValue="this.pdfTemplateId" :defaultOption="this.pdfTemplateData"
                :label="'purchaseBy' + this.action" :readonly="true" :action="'print'" @update:modelValue="changeTemplate">
            </SelectSearch>
        </div>
        <div class="col-2">
            <AppPermission
                :permission="[this.$permissions.printTemplates.update ?? '', this.$permissions.printTemplates.remove]">
                <a v-if="this.content" href="javascript:0;" class="pr-4"
                    @click="showModalEditPdtTemplate(true)">
                    <i class="fa fa-pencil-alt text-dark" style="font-size:14px"></i>
                </a>
            </AppPermission>
            <AppPermission :permission="this.$permissions.printTemplates.create ?? ''">
                <a v-if="this.content" href="#" @click="showModalEditPdtTemplate(false)"
                    class="pr-4">
                    <i class="fa fa-plus text-dark" style="font-size:14px"></i>
                </a>
            </AppPermission>
        </div>
    </div>
    <Design ref="Design" :content="this.content" />
    <!-- <ModalEditPdtTemplate ref='ModalEditPdfTemplate' 
        :action="this.action" 
        :html="this.html" 
        :content="this.content"
        :pdfTemplateId="this.pdfTemplateId" 
        :pdfTemplateName="this.pdfTemplateName" 
        :pdfTemplate="this.pdfTemplateData" 
        :file="this.file" 
        :dataValue="this.dataValue" 
        :printTemplates="this.printTemplates"/> -->
    <ModalEditPdtTemplate ref='ModalEditPdfTemplate'/>
</template>
<script>
import SelectSearch from "@/components/SelectSearch";
import Design from './Design';
import ModalEditPdtTemplate from './ModalEditPdfTemplate';
import { $get, $post } from "@/lib/utils";
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from '@/permissionMixin';
// In tem mã
import BarcodeGenerator from '@/components/products/BarcodeGenerator.vue';
import { mapGetters } from "vuex";
import storeModul from "@/storeModul";
export default {
    name: "DesignTemplates",
    emits: ['changeContent'],
    mixins: [permissionMixin],
    components: {
        SelectSearch, 
        Design,
        ModalEditPdtTemplate,
        AppPermission,
        BarcodeGenerator
    },
    props: ['data', 'entry', 'isShow', 'html'],
    created() {
    },
    data() {
        return {
            editorDataClone: null,
            templateId: null,
        }
    },
    watch: {
        pdfTemplateId(value){
            if(value){
                this.getDataTemplate();
            }
        }
    },
    methods: {
        changeTemplate(template){
            if(template && template.model){
                emitter.emit("loadDataPdfTemplateContent", template.model);
            }
        },
        async showModalEditPdtTemplate(typeUpdate) {
            await this.getDataTemplate();
            this.$refs.ModalEditPdfTemplate.show(typeUpdate);
        },
        async getDataTemplate(){
            let pdfTemplateName;
            const res = await $get('/v1/pdf-templates/get-pdf-template-detail', { id: this.pdfTemplateId });
            if (res && res.status.code == 200 && res.result && res.result.data) {
                pdfTemplateName = res.result.data.name ?? null;
            }
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplateName', pdfTemplateName);
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplateNameOrigin', pdfTemplateName);
        }
    },
    computed: {
        ...mapGetters({
            content: 'sendMailTemplateStore/sendMailZaloContent',
            pdfTemplateData: 'sendMailTemplateStore/sendMailZaloTemplate',
            pdfTemplateId: 'sendMailTemplateStore/sendMailZaloTemplateId',
            action: 'sendMailTemplateStore/action',
            file: 'sendMailTemplateStore/pdfFileId',
            dataValue: 'sendMailTemplateStore/dataValueKeys',
            printTemplates: 'sendMailTemplateStore/printTemplates',
        }),
    },
};
</script>

<style scoped>
.printBox {
    font-family: Arial, sans-serif;
    font-size: 11px;
}

table {
    page-break-inside: auto;
    border-collapse: collapse;
}

tr {
    page-break-inside: avoid;
    page-break-after: auto
}

img {
    max-width: 100%;
    height: auto;
}

.wrap-logo {
    /*border-bottom: 1px solid #0b0b0b;*/
    margin-bottom: 30px;
    text-align: center;
}

.footer-delivery {
    position: fixed;
    text-align: center;
    bottom: 0px;
    width: 100%;
}</style>