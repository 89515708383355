<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <div class="d-flex">
            <label class="switch-button" :class="isCenter ? 'm-auto' : ''">
                <input ref="input" v-model="isChecked" @change="toggle()" type="checkbox" :disabled="readonly">
                <span class="slider round"></span>
            </label>
            <span v-if="detail && isChecked" v-text="detail" style="margin: auto 0 auto 1rem"></span>
        </div>

    </div>

</template>

<script>
    export default {
        name: "InputSwitchButtonCustom",
        data: function () {
            const v = typeof this.modelValue==='boolean' ? this.modelValue : parseInt(this.modelValue);
            return {
                isChecked: !!v
            }
        },
        mounted: function () {
           // new Switchery(this.$el);
        },
        props: ['modelValue', 'change', 'input_id', 'label', 'detail', 'readonly', 'isCenter'],
        watch: {
            modelValue: function (v) {
                this.isChecked = !!v;

            }
        },
        methods: {
            toggle: function() {
                const modelValue = this.$refs.input.checked ? 1 : 0;
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('change', modelValue);
                    this.$emit('input', modelValue);
                })

            }
        }
    }
</script>

<style scoped>
    .switch-button {
        position: relative;
        display: inline-block;
        width: 30px;
        height: 14px;
        margin-bottom: 0;
    }
    input:focus+.slider {
        box-shadow: 0 0 1px #008080;
    }

    input:checked+.slider {
        background-color: #008080;
    }
    .slider.round {
        border-radius: 34px;
    }
    .switch-button input {
        display: none;
    }
    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;
    }
    input:checked+.slider:before {
        -webkit-transform: translateX(16px);
        -ms-transform: translateX(16px);
        transform: translateX(16px);
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
    }
    .slider.round:before {
        border-radius: 50%;
    }
    .slider:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        left: 0px;
        top: -3px;
        background-color: #fff;
        -webkit-transition: .4s;
        transition: .4s;
    }

</style>
