<template>
    <div class="row">
        <ActionBar :back-url="'/tags/index?tagTypeId=' + tagType.id" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1  v-text="entry.id ? 'Sửa: ' + tagType.name : ' Thêm: ' + tagType.name"></h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên nhóm"/>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.description"
                                       :errors="errors.description"
                                       label="Mô tả"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import ActionBar from "@/components/ActionBar";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import InputSwitchButton from "../../components/forms/InputSwitchButton";
import toastr from 'toastr';
import {getSearchQuery} from "../../lib/utils";
import store from "../../store";

export default {
    name: "TagForm",
    components: {ActionBar, InputText, InputSwitchButton},
    data() {
        const query = getSearchQuery();

        return {
            isDataLoaded: false,
            tagType: {},
            entry: {
                status: 1,
                tagTypeId: query.tagTypeId,
            },
            errors: {}
        }
    },
    mounted() {
        this.load();
    },
    methods: {

        async load() {
            const res1 = await $get('/v1/tags/show-tag-type', {tagTypeId: this.$route.query.tagTypeId});

            if (res1.code === 404) {
                store.commit('SetIsPageNotFound', true);
                return;
            }

            this.tagType = res1.data;

            if (this.$route.query.id) {
                const res = await $get('/v1/tags/show', {
                    id: this.$route.query.id
                });

                if (res.code !== 200) {

                    toastr.error(res.message);
                    return;
                }

                this.entry = res.data;
                this.isDataLoaded = true;
            } else {
                this.isDataLoaded = true;
            }


        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/tags/save-entry', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        }
    }
}
</script>

<style scoped>

</style>
