<template>
    <div ref="modalConfirm" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.modalEndOrder.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h6 style="line-height:30px;">{{ $t('message.modalEndOrder.content') }}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click="abortOrders()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.button.confirm') }}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';

export default {
    name: "ModalEndOrder",
    components: {},
    data(){
        return {
            id:null,
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(id) {
            if(id != null){
                this.id = id;
                window.jQuery(this.$refs.modalConfirm).modal('show');
            } 
        },
        async abortOrders(){
            const res = await $post('/v1/orders/update-status-completed', { id: this.id});  
            if(res.status.code == 200){
                const message = this.$t('message.modalEndOrder.messageSuccess');
                toastr.success(message);
            }else{ 
                const message = this.$t('message.modalEndOrder.messageError');               
                toastr.error(message);
            }
            this.$emit('updated');
            this.clearData();
        },
        clearData(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

