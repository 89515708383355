<template>
    <ul class="q-tree-root" :class="{collapsed: collapsed, 'q-tree-sub': (level ||0) > 0}">
        <li v-for="e in items" :key="e.id" class="">
            <div class="row d-block ml-1">
                <a class="btn-expand" :class="{'q-tree-highlight': e.highlight}" @click.prevent="expand(e)" v-if="e.children && e.children.length">
                    <i class="fa" :class="e.expanded ? 'fa-minus-square' : 'fa-plus-square'"></i>
                </a>
                <label class="container-checkbox" :class="e.children && e.children.length ? 'm-0' : 'ml-0'">                    
                    <input type="checkbox" v-model="e.checked" @input="doCheck(e, $event)" style="transform: none" v-if="!this.readonly">
                    <input type="checkbox" :checked="e.checked" disabled style="transform: none" v-else>
                    <span class="checkmark-checkbox"></span>
                </label>
                <span class="q-tree-label roleName" @click="!this.readonly ? doCheck(e) : null" >
                    {{e.name}}
                </span>
            </div>
            <div class="row px-2">
                <template v-if="e.children && e.children.length && e.expanded">
                        <QTreeListCustom @ontreechange="onTreeChange"
                                    :level="(level || 0) + 1"
                                    :items="e.children"
                                    :collapsed="!e.expanded"
                                    :readonly="this.readonly"
                                    :modelValue="this.modelValue"
                                    :showAction="this.showAction"
                                    :onCreate="this.onCreate"
                                    :onUpdate="this.onUpdate"/>
                </template>
            </div>
        </li>
    </ul>
</template>

<script>

    import {clone, treeFindPath, treeIterate} from "../../lib/utils";
    import emitter from "@/lib/emitter";
    export default {
        name: "QTreeListCustom",
        props: ['items', 'modelValue', 'collapsed', 'level', 'showAction', 'onCreate', 'onUpdate', 'onDelete', 'readonly'],
        data(){
            return{
            }
        },
        mounted() {
            this.isExistInArrayChecked(this.items);
        },
        methods: {
            expand(e) {
                e.expanded = !e.expanded;
            },
            async onTreeChange(node) {
                await this.$emit('ontreechange', node);
                let item = this.findItemParent(this.items, node.parentId);
                if(item.children){
                    const found = item.children.find((child) => child.checked == true);
                    item.checked = item.highlight = found ? true : false;
                    this.$emit('ontreechange', item);
                }
            },

            findItemParent(array, id){
                var result;
                array.some(o => result = o.id == id ? o : this.findItemParent(o.children || [], id));
                return result;
            },

            async doCheck(node, $event) {
                if ($event) {
                    $event.stopPropagation();
                }
                node.checked = !node.checked;
                node.highlight = !node.highlight;
                this.$emit('ontreechange', node);
                this.setValueCheckedChild(node);
            },
            setValueCheckedChild(entry){
                let node = clone(entry);
                if(node.children && node.children.length){
                    node.children.forEach(child => {
                        child.checked = node.checked;
                        child.highlight = node.highlight;
                        this.$emit('ontreechange', child);
                        this.setValueCheckedChild(child);
                    });
                }
            },
            isExistInArrayChecked(entries){
                if(entries){
                    entries.forEach(entry => {
                        if(this.modelValue.includes(entry.id)){
                            entry.checked = true;
                            entry.highlight = true;
                        }else{
                            entry.checked = false;
                            entry.highlight = false;
                        }
                        if(entry.children){
                            if(this.showAction){
                                this.haveChildrenActive(null, entry);
                            }
                            this.isExistInArrayChecked(entry.children);
                        }
                    });
                }
            },
            haveChildrenActive(parent = null, entry= null){
                if(entry){
                    if(entry.children){
                        entry.children.forEach(el => {
                            if(this.modelValue.includes(el.id)){
                                entry.expanded = true;
                                if(parent){
                                    parent.expanded = true;
                                }
                            }
                            if(el.children){
                                this.haveChildrenActive(entry, el);
                            }
                        });
                    }
                }
            },
            removeData(entries){
                if(entries){
                    entries.forEach(entry => {
                        entry.checked = false;
                        entry.highlight = false;
                        entry.expanded = false;
                        if(entry.children){
                            this.removeData(entry.children);
                        }
                    });
                }
            },
        },
        watch: {
            items: function (newValue) {
                if (newValue) {
                    this.isExistInArrayChecked(newValue);
                    return newValue;
                }
            },
            modelValue: function (newData){
                this.isExistInArrayChecked(this.items);
            }
        },
        created(){
            emitter.on('removeDataQTreeListCustom', () => {
                this.removeData(this.items);
            })
        }
    }
</script>

<style lang="scss" scoped>
@import "../../assets/css/vars.scss";
    .q-tree-root {
        list-style: none;
        padding-left: 0;
        position: relative;
    }
    .q-tree-label {
        cursor: pointer;
        padding-left: 5px;
    }
    .q-tree-sub li {
        margin:0;
        padding:0 1em;
        line-height:2em;
        position:relative
    }

    .q-tree-sub li:last-child:before {
        background:#fff;
        height:auto;
        // top:1em;
        top:5em;
        bottom:0
    }

    .q-tree-sub li:before {
        content:"";
        display:block;
        width:10px;
        height:0;
        border-top:1px solid;
        margin-top:-1px;
        position:absolute;
        top:1em;
        left:0
    }
    .q-tree-sub:before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        border-left: 1px solid;
    }
    .q-tree-sub {
        margin-left: 25px;
    }
    .collapsed {
        display: none;
    }

    .btn-expand {
        cursor: pointer;
        padding-right: 5px;
    }

    .q-tree-highlight .fa{
        color: #008080;
    }

    .q-tree-sub:before {
        border-left: 0 !important;
    }
    .q-tree-sub li:before {
        border-top: 0 !important;
    }
    .container-checkbox{
        width: 16px !important;
        display: inline !important;
    }
    .roleName {
        width: 230px;
    }
    @media screen and (max-width: 1366px) and (min-width: 768px){
       .roleName {
            width: 170px !important;
        }
    }
</style>
