<template>
 <!-- style="background-color: #f8f8f8" -->
  <div class="row">
    <div class="col-lg-6 px-0 pl-2 mx-0">
       <!-- style="position: absolute; top: 5px; z-index: 1" -->       
      <div class="row" >
        <!-- Tên mẫu in --> 
          <div class="col-lg-5 col-md-12" style="display: flex; align-items: center">
            <span class="px-0" style="width:130px">{{ $t('message.common.printTemplateName') }}: </span>
            <input type="text" name="inputPrint" id="inputPrint" class="form-control pr-0" :value="this.printTemplateName" @change="changeName()"/>
          </div>
        <!-- Chọn khổ giấy -->
          <div class="col-lg-7 col-md-12 px-2" style="display: flex; align-items: center">
            <span class="" style="width: 100px">{{ $t('message.common.suggestPrintTemplate') }}: </span>         
            <SelectSearch :placeholder="$t('message.common.choosePrintTemplate')" :label="'paperPrint_' + this.printPaperID" 
            :modelValue="this.printPaperID" :defaultOption="this.printPaper" 
            @loadPrintTemplateId="this.loadPrintTemplateId"> 
            </SelectSearch>           
            <span>
              <a href="javascript:0;" @click="editPrintPaper(this.printPaperID)" class="pr-2">
                      <i class="fa fa-pencil-alt text-dark" style="font-size:14px"></i>  
              </a>
              <a href="javascript:0" @click="addPrintPaper(null)" class="pl-1">
                      <i class="fa fa-plus text-dark" style="font-size:14px"></i>            
              </a>   
            </span>
          </div>        
      </div>
      <div class="row mx-0 px-0 my-3">
      <!-- Chọn chi nhánh -->
        <div class="col-lg-12 pl-0" style="display: flex; align-items: center">
          <span class="px-0" style="width: 98px">{{ $t('message.common.branch') }}: </span>
          <q-select :default-options="branches" :multiple="true" v-model="this.branchIDs" :selectAll="true" 
            :placeholder="$t('message.ModalProductFormTabMain.placeholder.branch')"
            :printTemplateStatus="true" />
        </div> 
      </div>
      <div class="row">
        <div class="col-12">
            <ckeditor class="" type="classic" v-model="this.editorData"
            :editor-url="'https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js'"></ckeditor>             
            <!-- https://cdn.ckeditor.com/4.21.0/full-all/ckeditor.js -->
            <!-- https://cdn.ckeditor.com/4.14.1/full-all/ckeditor.js -->
        </div>
      </div>
    </div>
    
    <div class="col-lg-6 py-2 mx-0 px-0">
      <!-- style="background-color: #f8f8f8" -->
      <div class="editor-preview"  style="background-color: #eef3f7">
            <div class="col-12 py-2 text-right mb-4">
              <label class="text-secondary"> {{ $t('message.common.previewPrintTemplate') }} </label>
            </div>
            <div v-html="editorDataClone" class="d-none"></div>
            <iframe
              :srcdoc="editorDataClone"
              type="application/pdf"
              scrolling="yes"
              width="100%"
              height="830"
              frameborder="0"
              style="position: relative; z-index: 999"
              ref="frame">
            </iframe>
          </div>
    </div>
  </div>

  <!-- Nhap -->

  <ModalPrintPaper ref="ModalPrintPaper" @loadData="this.loadData"/>
</template>

<script>


import SelectSearch from "@/components/SelectSearch";
import { $get, $post } from "@/lib/utils";
import ModalPrintPaper from './ModalPrintPaper';
import QSelect from '@/components/QSelect.vue';
import store from '@/store';

export default {
  name: "ModalEditPrint",
  mounted() {
      
  },
  components: {
    SelectSearch, ModalPrintPaper, QSelect
  },
  props: [ "action", 'printTemplateName', 'printPaperID', 'printTemplateId','branchIds'],
  data() {
    const globalConfig = store.state.GlobalConfig;
    return {
      printPaperId: 1,
      printPaperName: null,
      printPaper: [],      
      dataValue: [],
      editorData: null,
      printTemplateIdCurent: null,
      branches: globalConfig.branches,
      branchIDs: [],
    };
  },
  created() {
    this.loadData();    
  },
  methods: {    
    async editPrintPaper(id){
      this.$refs.ModalPrintPaper.show(id, 'edit')
    },
    async addPrintPaper(id){      
      this.$refs.ModalPrintPaper.show(id, 'add');
    },
    loadPrintTemplateId(id,name){           
        this.$emit('changePrintPaper',id)
    },
    changeName(){  
      let name = document.getElementById('inputPrint').value;      
      this.$emit('changePrtTempName',name);
    },
    changePrintPaper(){
      let printPaperId = document.getElementById('printPaper').value;            
      this.$emit('changePrintPaper',printPaperId);
    },    
    async loadData() {        
      if(localStorage.getItem('dataContent') != null){        
            this.editorDataClone = localStorage.getItem('editorDataClone')?? null;
            this.editorData = localStorage.getItem('dataContent')?? null;  
      }     
      else {
          this.printPaperId = this.printPaperID?? 1;      
          const resValues = await $post(
            "/v1/print-templates/get-value-print-template"
          );         
          if (resValues.result) {
            this.dataValue = resValues.result;
          }
         
          const res = await $get(`/v1/print-templates/get-content-print-templates`, {
            id: this.printTemplateId 
          });
          
          if (res && res.status.code == 200) {
            if (res.result) {
              this.editorData = res.result.content;
            }
          }

          
      }
      // Load print paper data
      const resPrint = await $get(`/v1/print-templates/get-data-print-paper`);
      if(resPrint && resPrint.result)
      {
        this.printPaper = resPrint.result;        
      }
      this.renderData(this.editorData);
    },
    async getNewContent() {
       const res = await $get(`/v1/print-templates/get-content-print-templates`, {
        id: this.printTemplateIdCurent 
      });      
      
      if (res && res.status.code == 200) {
        if (res.result) {
          this.editorData = res.result.content;
          
        }
      }
    },
    getPhoneHide(phone){
      let phoneConvert = '';
      for (let index = 0; index < phone.length; index++) {
          if(index <= phone.length - 3){
                phoneConvert = phoneConvert + '*';
          }
          else {
                phoneConvert = phoneConvert + phone[index];
          }    
        }
      return phoneConvert;
    },
    async renderData(content){
        if(this.dataValue?.length == 0){
            const resValues = await $post('/v1/print-templates/get-value-print-template');
            if(resValues.result){
                this.dataValue = resValues.result;
            }      
        }
      this.editorDataClone = content;
      let barcode = '';
      this.dataValue.forEach(data => {        
          const image =  '<img src="' + data.value + '" style=width:200px;>';           
          let newPhone = null; 
          switch (data.key) {
            case '{Logo_Cua_Hang}':
              this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
              break;
            case '{Sort_Code}':                   
              barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=`+ data.value +`&amp;'
                    alt="" title=`+ data.value +` width="80" height="80" />`
              this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
              break;
            case '{Ma_Van_Don_Ma_Vach}':                 
              barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                    alt="" title=`+ data.value +` width="150"  height="100" />`
              this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
              break;
            case '{Ma_Vach_Hoa_Don}':   
                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                alt="" title=`+ data.value +` width="150"  height="100" />`
                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);            
                break;  
            case '{Ma_Vach_Khach_Hang}':   
                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                  alt="" title=`+ data.value +` width="150"  height="100" />`
                  this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);   
                break;
            case '{So_Dien_Thoai_An}':   
                 newPhone = this.getPhoneHide(data.value);
                 this.editorDataClone = this.editorDataClone.replaceAll(data.key, newPhone);
                break;  
            default:
              this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
              break;
          }       
      });          
    },
  },
  watch: {
    branchIds(id) {
      this.branchIDs = id; 
    },

    editorData(newData) { 
      this.renderData(newData);
      this.$emit('updateContent', newData);  
    },
    printTemplateId(newData) {
      this.printTemplateIdCurent = newData;
      this.getNewContent();
    },
    branchIDs(newBranch) {
       this.$emit('getBranchList', newBranch);  
    },
  },
};
</script>
<style scoped>
.cke_top {
  padding: 50px 8px 2px !important;
}
#rowSelect {
  position: absolute;
  top: 5px;
}
#inputPrint {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #ccc;
  background-color: #f8f8f8;
}
#inputPrint:focus,
#inputPrint:hover,
.prd-search-box1:hover,
.prd-search-box1:active {
  border-bottom-color: #0dac50;
}
#orderTemplate {
  display: block !important;
}

#printPaper{
  border:0; 
  border-bottom:1px solid #ccc;
  border-radius:0; 
  background-color:#f8f8f8; 
}
#printPaper:hover, #printPaper:focus {
  border-bottom:1px solid #0dac50;
}

.prd-search-box1 {
  height: 60% !important;
}
</style>
