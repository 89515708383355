<template>
  <div class="row productBarcode__item" style="  min-height: 100px; min-width: 170px;min-height:120px" >
    <div class="col-1 px-2">
      <div
        v-if="item.priceOptions != 0"
        class="productBarcode__info py-2">
        <!-- Giá của sản phẩm -->
        <label
          style="
            font-size: 13px;
            position: absolute;
            top: 65% !important;
            left: 10px !important;
            -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
            transform: translateX(-50%) translateY(-50%) rotate(-90deg);
          ">
          {{ item.priceOptions!==0 && item.prdPrice ? n(item.prdPrice) : "" }}
          {{ item.currentOptions!==0 && item.prdCurrency ? item.prdCurrency : "" }}
          {{ item.unitOptions!==0 && item.unitName ? '/' + item.unitName : "" }}
        </label>
      </div>
    </div>
    <div
      class="col-11"
      style="position: absolute; top: 15px !important; left: 15px !important">
      <div
        class="productBarcode__item"
        :style="{ height: this.height + 'mm', width: this.width + 'mm' }">
        <div class="productBarcode__name"  v-if="item.nameOptions != 0" style="font-weight: 700; font-size:13px "> {{item.branchName ? item.branchName : 'Omipharma'}}</div>
        <div
          class="productBarcode__name"
          style="font-size: 15px; font-weight: 500; font-family: Arial; ">
          {{ item.prdName ? item.prdName : "" }}
        </div>
        <BarcodeGenerator
          :value="item.prdCode"
          :width="1"
          :height="30"
          :fontSize="12"
          :margin="10">
        </BarcodeGenerator>
        <div
          class="productBarcode__name"
          style="font-size: 13px; font-weight: normal; font-family: Arial">
          {{ item.categoryName ? item.categoryName : "" }}
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator";

export default {
  name: "TommyPaperBarcode",
  created() {},
  props: {
    item: {},
    products: {},
    status: {},
    number: null,
    width: null,
    height: null,
  },
  components: { BarcodeGenerator },
  data() {
    return {};
  },
  methods: {},
  watch: {
    info(newData) {
      this.data = newData;
    },
  },
};
</script>
<style scoped></style>
