<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 pl-4 overflow-x-auto" :class="entry.tickets && entry.tickets.length ? 'col-left' : ''">            
            <table class="w-100">
                <thead class="text-center min-w-100">
                    <tr class="w-100">
                        <th scope="col" class="cell-check"></th>
                        <th scope="col" class="cell-auto border">
                            {{ $t('message.common.poWithReturnPOCode') }}
                        </th>                
                        <th scope="col" class="cell-auto border">
                            {{$t('message.common.statusPO')}}
                        </th>                
                        <th scope="col" class="cell-auto border">
                            {{$t('message.SettlementAdvanceIndex.payment_date')}}
                        </th>                
                        <th scope="col" class="cell-auto border">
                            {{ $t('message.SettlementAdvanceIndex.supplier_code') }}
                        </th>                
                        <th scope="col" class="cell-auto border">
                            {{ $t('message.SettlementAdvanceIndex.supplier_name') }}
                        </th>                
                        <th scope="col" class="cell-auto border">
                            {{ $t('message.SettlementAdvanceIndex.payment_method') }}
                        </th>                
                        <th scope="col" class="border p-0">
                            <table class="h-100">
                                <tr>
                                    <th :colspan="3" class="border-bottom">
                                        {{ $t('message.SettlementAdvanceIndex.payment_info') }}
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.account_number') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.account_owner') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-none">
                                        {{ $t('message.SettlementAdvanceIndex.bank_address') }}
                                    </th>
                                </tr>
                            </table>
                        </th>
                        <th scope="col" class="border p-0">
                            <table class="h-100">
                                <tr>
                                    <th :colspan="3" class="border-bottom">
                                        {{ $t('message.SettlementAdvanceIndex.cash') }}
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.settlement') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.advances') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-none">
                                        {{ $t('message.SettlementAdvanceIndex.compare') }}
                                    </th>
                                </tr>
                            </table>
                        </th>
                        <th scope="col" class="border p-0">
                            <table class="h-100">
                                <tr>
                                    <th :colspan="5" class="border-bottom">
                                        {{ $t('message.SettlementAdvanceIndex.advance_info') }}
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.import_number') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.invoice_number') }}
                                    </th>
                                    <th scope="col" class="cell-large border-right">
                                        {{ $t('message.SettlementAdvanceIndex.invoice_info') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-right">
                                        {{ $t('message.SettlementAdvanceIndex.invoice_cash') }}
                                    </th>
                                    <th scope="col" class="cell-auto border-none">
                                        {{ $t('message.SettlementAdvanceIndex.invoice_status') }}
                                    </th>
                                </tr>
                            </table>
                        </th>                
                    </tr>
                </thead>
                <tbody class="text-center">
                    <template v-if="entry.tickets && entry.tickets.length">
                        <template v-for="(ticket, index) in entry.tickets" :key="index">
                            <tr>
                                <td scope="col" class="cell-check">
                                    <i  v-if="(ticket.typeSrc == $constant.TYPE_SRC_DISCOUNT_BAS && !index) || ticket.typeSrc != $constant.TYPE_SRC_DISCOUNT_BAS" class="fas fa-trash-alt" @click="deleteTicket(index, ticket.discountBasId)"></i>
                                </td>
                                <td scope="col" class="cell-auto border text-primary cursor-pointer" v-text="ticket.code" @click="showDetailTicket(ticket.id, ticket.typeSrc, ticket)"></td>                
                                <td scope="col" class="cell-auto border" v-text="ticket.statusName"></td>                
                                <td scope="col" class="cell-auto border px-2">
                                    <input type="date" v-model="ticket.planPayment" :placeholder="$t('message.common.paymentSchedule')" class="format__input bg-transparent text-left mb-0">
                                </td>                
                                <td scope="col" class="cell-auto border text-primary" v-text="ticket.supplierCode" @click="showDetailTicket((ticket.supplier ? ticket.supplier.id : null), this.$constant.TYPE_SRC_SUPPLIER, ticket.supplier)"></td>                
                                <td scope="col" class="cell-auto border" v-text="ticket.supplierName"></td>                
                                <td scope="col" class="cell-auto border px-2">
                                    <SelectSearch :placeholder="$t('message.SettlementAdvanceIndex.payment_method')" :heightNormal="true" :modelValue="ticket.typePayment" :defaultOption="this.$constant.methodPayments" @update:modelValue="selectMethodPayment" :label="'methodPayment' + ticket.code" :readonly="true"></SelectSearch>
                                </td>                
                                <td scope="col" class="border p-0">
                                    <table class="h-100">
                                        <tr>
                                            <td scope="col" class="cell-auto border-right px-2">
                                                <input type="text" v-model.trim="ticket.accountNumber" class="format__input bg-transparent text-left mb-0" :placeholder="$t('message.SettlementAdvanceIndex.account_number') + '...'">
                                            </td>
                                            <td scope="col" class="cell-auto border-right px-2">
                                                <input type="text" v-model.trim="ticket.accountOwner" class="format__input bg-transparent text-left mb-0" :placeholder="$t('message.SettlementAdvanceIndex.account_owner') + '...'">
                                            </td>
                                            <td scope="col" class="cell-auto border-none px-2">
                                                <input type="text" v-model.trim="ticket.bankBranch" class="format__input bg-transparent text-left mb-0" :placeholder="$t('message.common.branch') + '...'">
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td scope="col" class="border p-0">
                                    <table class="h-100">
                                        <tr>
                                            <td scope="col" class="cell-auto border-right px-2">
                                                <InputNumberFormat v-if="entry.status != this.$constant.ticketProcessing || !ticket.isUpdate" :cardTransaction="true" v-model="ticket.advance" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                                <span v-if="entry.status == this.$constant.ticketProcessing && ticket.isUpdate" scope="col" class="cell-auto border-right" v-text="n(ticket.advance)"></span>                    
                                            </td>
                                            <td scope="col" class="cell-auto border-right" v-text="n(ticket.settlement)"></td>
                                            <td scope="col" class="cell-auto border-none" v-text="n(ticket.difference)"></td>
                                        </tr>
                                    </table>
                                </td>
                                <td scope="col" class="border p-0">
                                    <table class="h-100">
                                        <tr>
                                            <!-- <td scope="col" class="cell-auto border-right text-primary cursor-pointer" v-text="ticket.importSupplierCode" @click="showDetailTicket(ticket.importForSupplierId, this.$constant.TYPE_SRC_IMPORT_SUPPLIER)"> </td>-->
                                            <td scope="col" class="cell-auto border-right cursor-pointer">
                                                <!-- <template v-if="ticket.import_for_supplier && ticket.import_for_supplier.length">
                                                    <template v-for="(importSupplier, importKey) in ticket.import_for_supplier" :key="importKey">
                                                        <a href="javascript:void(0);" class="text-primary text-decoration-none d-block" v-text="importSupplier.code" @click="showDetailTicket(importSupplier.id, this.$constant.TYPE_SRC_IMPORT_SUPPLIER)"></a>
                                                    </template>
                                                </template>
                                                <template v-else-if="ticket.import_supplier && ticket.import_supplier.length">
                                                    <template v-for="(importSupplier, importKey) in ticket.import_supplier" :key="importKey">
                                                        <a href="javascript:void(0);" class="text-primary text-decoration-none d-block" v-text="importSupplier.code" @click="showDetailTicket(importSupplier.id, this.$constant.TYPE_SRC_IMPORT_SUPPLIER)"></a>
                                                    </template>
                                                </template>
                                                <template v-else>
                                                    <a href="javascript:void(0);" class="text-primary text-decoration-none d-block" v-text="ticket.importSupplierCode[0]" @click="showDetailTicket(ticket.importForSupplierId, this.$constant.TYPE_SRC_IMPORT_SUPPLIER)"></a>
                                                </template> -->

                                                <template v-if="ticket.importSupplierCode && ticket.importSupplierCode.length">
                                                    <template v-for="(importSupplier, importKey) in ticket.importSupplierCode" :key="importKey">
                                                        <a href="javascript:void(0);" class="text-primary text-decoration-none d-block" v-text="importSupplier.code" @click="showDetailTicket(importSupplier.id, this.$constant.TYPE_SRC_IMPORT_SUPPLIER)"></a>
                                                    </template>
                                                </template>
                                            </td>
                                            <td scope="col" class="cell-auto border-right">
                                                <div class="row w-100 m-0">
                                                    <div class="col-12 d-flex align-items-center w-100 p-0">
                                                        <label class="container-radio ml-2 w-100 text-left">{{$t('message.common.nonInvoice')}}
                                                            <input :name="'statusInvoice_' + index" :checked="ticket.statusHasInvoice == this.$constant.statusInActive" type="radio" v-model="ticket.statusHasInvoice" :value="this.$constant.statusInActive" class="mr-2" @click="changeStatusInvoice(ticket)">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row w-100 m-0 mb-1">
                                                    <div class="col-12 d-flex align-items-center w-100 p-0">
                                                        <label class="container-radio ml-2 w-100 text-left">{{$t('message.common.hasInvoice')}}
                                                            <input :name="'statusInvoice_' + index" :checked="ticket.statusHasInvoice == this.$constant.statusActive" type="radio" v-model="ticket.statusHasInvoice" :value="this.$constant.statusActive" class="mr-2">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <td scope="col" class="cell-large border-right">
                                                <table class="h-100 w-100">
                                                    <tr v-for="(info, infoIndex) in ticket.infoInvoiceSupplier" :key="infoIndex">
                                                        <td scope="col" class="cell-check border-none px-2">
                                                            <i class="fa fa-trash-alt" v-if="infoIndex > 0" @click="ticket.statusHasInvoice !== this.$constant.statusActive ? null : removeInvoiceSupplier(index, infoIndex)"></i>
                                                        </td>
                                                        <td scope="col" class="cell-auto border-right px-2">
                                                            <input type="text" v-model.trim="info.numberInvoice" :readonly="ticket.statusHasInvoice !== this.$constant.statusActive" :placeholder=" $t('message.common.enterInvoice') + '...'" class="format__input bg-transparent text-left mb-0" :class="ticket.statusHasInvoice !== this.$constant.statusActive ? 'cursor-ban' : ''">
                                                        </td>
                                                        <td scope="col" class="cell-auto border-right px-2">
                                                            <input type="date" v-model="info.billDate" :readonly="ticket.statusHasInvoice !== this.$constant.statusActive" :placeholder="$t('message.common.enterInvoiceDate')" class="format__input bg-transparent text-left mb-0" :class="ticket.statusHasInvoice !== this.$constant.statusActive ? 'cursor-ban' : ''">
                                                        </td>
                                                        <td scope="col" class="cell-auto border-right px-2">
                                                            <input type="text" v-model="info.amountForInvoice" :readonly="ticket.statusHasInvoice !== this.$constant.statusActive" :placeholder="$t('message.common.enterAmount')" class="format__input bg-transparent text-left mb-0" :formatNumber="true">
                                                            <!-- <InputNumber :min="-1000" v-model="info.amountForInvoice" :readonly="ticket.statusHasInvoice !== this.$constant.statusActive" :cardTransaction="true" class="mb-2" :placeholder="$t('message.common.enterAmount')" :formatNumber="true"/> -->
                                                        </td>
                                                        <td scope="col" class="cell-check border-none px-2">
                                                            <i class="fa fa-plus" v-if="infoIndex == 0" @click="ticket.statusHasInvoice !== this.$constant.statusActive ? null : addInvoiceSupplier(index)"></i>
                                                        </td>
                                                    </tr>
                                                </table>
                                            </td>
                                            <td scope="col" class="cell-auto border-right px-2" v-text="n(ticket.totalAmountForInvoice)"></td>
                                            <td scope="col" class="cell-auto border-none" v-text="ticket.statusInvoiceName"></td>
                                        </tr>
                                    </table>
                                </td> 
                            </tr>
                        </template>
                    </template>
                    <template v-if="(!entry.tickets || !entry.tickets.length)">
                        <tr>
                            <td :colspan="18" class="text-center p-4">{{ $t('message.common.notData') }}</td>                            
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="col-4 col-xl-3 col-right">
            <div class="col-right__content">
                <div class="row">
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.SettlementAdvanceIndex.created_by')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0 pt-2">
                                <SelectSearch :placeholder="$t('message.SettlementAdvanceIndex.created_by')" :modelValue="entry.createdBy" :defaultOption="createdByOption" @update:modelValue="selectCreatedBy" :label="'createdby'" :readonly="true" :disabled="true"></SelectSearch>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.SettlementAdvanceIndex.created_at')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <div class="form-control" readonly v-text="entry.purchaseDate ? entry.purchaseDate : null"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{ $t('message.modalCreateTicketForCash.document_number') }}
                    </div>
                    <div class="col-6 mt-4 text-right">
                        <input type="text" v-model="entry.code" :placeholder="$t('message.common.autocompleteCode')" class="form-control">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.common.status')}}
                    </div>
                    <div class="col-6 mt-4 text-right">
                        {{ entry.statusName }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.common.totalAdvance')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalAmountAdvance)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.common.totalSettlement')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalAmountSettlement)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.SettlementAdvanceIndex.compare')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalAmountCompare)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.common.totalDebtInvoice')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalDebtInvoice)">
                    </div>
                </div>
                <div class="row d-flex">
                    <div class="col-4 mt-4">
                        <label class="container-checkbox cursor-ban">{{ $t('message.common.cash') }}
                            <input type="checkbox" class="chkType" :checked="paymentChannelCash ? true : false" :disabled="true">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </div>
                    <div class="col-4 mt-4">
                        <label class="container-checkbox cursor-ban">{{$t('message.common.transferPayment')}}
                            <input type="checkbox" class="chkType" :checked="paymentChannelTransfer ? true : false" :disabled="true">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </div>
                    <div class="col-4 mt-4">
                        <label class="container-checkbox cursor-ban">{{ $t('message.common.card') }}
                            <input type="checkbox" class="chkType" :checked="paymentChannelCard ? true : false" :disabled="true">
                            <span class="checkmark-checkbox"></span>
                        </label>
                    </div>
                </div>
                <div class="row d-flex">
                    <div class="col-4">
                        <div class="input__order">
                            <InputNumberFormat :cardTransaction="true" v-model="entry.cash" :readonly="true" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input__order">
                            <InputNumberFormat :cardTransaction="true" v-model="entry.transfer" :readonly="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="input__order">
                            <InputNumberFormat :cardTransaction="true" v-model="entry.card" :readonly="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="entry.description" class="input__textArea mb-0"/>
                    </div>
                </div>
            </div>
            <div class="col-right__save row">
                <div class="col-12 text-center">
                    <div class="row justify-content-center">
                            <AppPermission :permission="this.$constant.settlementAdvance">
                                <div class="col-lg-6" v-if="!entry.originId || (entry.originId && entry.status == this.$constant.temporary)">
                                    <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submitTicket(this.$constant.temporary)">
                                        <div><i class="fa fa-save"></i></div>
                                        <div>
                                            {{ $t('message.button.saveDraft') }}
                                        </div>
                                    </button>
                                </div>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.settlementAdvance.update">
                                <div class="col-lg-6">
                                    <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submitTicket(this.$constant.ticketProcessing)">
                                        <div><i class="fa fa-check"></i></div>
                                        <div>
                                            {{ $t('message.OrderSupplierIndex.status_complete') }}
                                        </div>
                                    </button>
                                </div>
                            </AppPermission>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalDetailTicket ref="ModalDetailTicket" />
</template>

<script>
    import InputSelect from "../../components/forms/InputSelect";
    import store from "../../store";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputTextArea from "../../components/forms/InputTextArea";
    import storage from "../../lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalDetailTicket from './ModalDetailTicket';
    import InputNumber from "@/components/forms/InputNumberCustom";    
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    export default {
        name: "SettlementAdvanceTab",
        emits: ['removeTab', 'orderProcessing', 'print'],
        mixins: [currencyMixin, permissionMixin],
        components: {
            InputTextArea,
            InputNumberFormat,
            InputSelect,
            SelectSearch,
            ModalDetailTicket,
            InputNumber,
            AppPermission
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            const globalConfig = store.state.GlobalConfig;
            return {
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                customers: [],
                branchName: store.state.CurrentBranch.name ?? null,
                user,
                createdByOption: globalConfig.users,
                paymentChannelCashMoney: 0,
                paymentChannelTransferMoney: 0,
                paymentChannelCardMoney: 0,
                paymentChannelCash: false,
                paymentChannelCard: false,
                paymentChannelTransfer: false,
                isButtonDisabled: false
            }
        },
        async mounted() {
            document.title = this.$t('message.SettlementAdvanceIndex.title');       
        },
        async created() {
            await this.setValue();

        },
        methods: {
            deleteTicket(index, discountBasId){
                if (discountBasId) {
                    let newArray = this.entry.tickets.filter(function(item) {
                        return item.discountBasId != discountBasId;
                    });
                    this.entry.tickets = newArray;
                } else {
                    this.entry.tickets.splice(index, 1);
                }
            },
            async submitTicket(status) {
                this.isButtonDisabled = true;
                let form = clone(this.entry);
                form.status = status;
                let res = null;
                if (this.entry.originId) {
                    res = await $post('/v1/settlement-advance/update', {... form});
                } else {
                    res = await $post('/v1/settlement-advance/create', {... form});
                }
                if (res && res.result && res.result.status) {
                    if (this.entry.originId) {
                        $alert({code: 200, message: this.$t('message.common.updateTicketSuccess')});
                    } else {
                        $alert({code: 200, message: this.$t('message.common.addTicketSuccess')});
                    }
                    this.$router.push({name: 'SettlementAdvanceIndex'})
                } else if (res && res.code == 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            setValue() {
                this.entry.purchaseDate = moment().format('DD-MM-YYYY');
            },
            selectMethodPayment(data){
                this.entry.tickets.forEach(ticket => {
                    if(('methodPayment' + ticket.code) == data.label){
                        ticket.typePayment = data.model ?? null;
                    }
                })
            },
            showDetailTicket(id, type, content = null){
                this.$refs.ModalDetailTicket.show(id, type, content);
            },
            changeStatusInvoice(ticket){
                ticket.infoInvoiceSupplier = [
                    {
                        'numberInvoice': null,
                        'billDate': null,
                        'amountForInvoice': null,
                    }
                ];
            },
            addInvoiceSupplier(index){
                this.entry.tickets[index].infoInvoiceSupplier.push(
                {
                    'numberInvoice': null,
                    'billDate': null,
                    'amountForInvoice': null,
                });
            },
            removeInvoiceSupplier(index, invoiceSupplierIndex){
                this.entry.tickets[index].infoInvoiceSupplier.splice(invoiceSupplierIndex, 1);
            }
        },
        watch: {
            entry: {
                handler: function(dataNew) {
                    this.paymentChannelCash = false;
                    this.paymentChannelCard = false;
                    this.paymentChannelTransfer = false;
                    let totalAmountAdvance = 0;
                    let totalAmountSettlement = 0;
                    let paymentChannelCashMoney = 0;
                    let paymentChannelTransferMoney = 0;
                    let paymentChannelCardMoney = 0;
                    let totalDebtInvoice = 0;
                    if (dataNew.tickets) {
                        dataNew.tickets.forEach(ticket => {
                            let isEmptyInvoice = true;
                            if(!ticket.infoInvoiceSupplier || (ticket.infoInvoiceSupplier && ticket.infoInvoiceSupplier.length === 0)){
                                ticket.infoInvoiceSupplier = [
                                    {
                                        'numberInvoice': null,
                                        'billDate': null,
                                        'amountForInvoice': null,
                                    }
                                ];
                            }else{
                                let totalAmount = 0;
                                ticket.infoInvoiceSupplier.forEach(invoice => {
                                    totalAmount += +invoice.amountForInvoice;
                                    if(isEmptyInvoice && invoice.numberInvoice && invoice.billDate && +invoice.amountForInvoice){
                                        isEmptyInvoice = false;
                                    }
                                });
                                ticket.totalAmountForInvoice = totalAmount;
                            }
                            // ticket.statusHasInvoice = !ticket.numberInvoice ? this.$constant.statusInActive : this.$constant.statusActive;
                            let statusInvoice = this.$constant.status_not_invoice_supplier;
                            if(!ticket.statusHasInvoice && ticket.statusHasInvoice !== null){
                                ticket.statusHasInvoice = !ticket.numberInvoice ? this.$constant.statusInActive : this.$constant.statusActive;
                            }
                            if(ticket.statusHasInvoice == this.$constant.statusInActive){
                                ticket.statusInvoiceName = this.$constant.not_invoice_supplier;
                            }else{
                                ticket.statusInvoiceName = isEmptyInvoice ? this.$constant.not_receive_invoice_supplier : this.$constant.received_invoice_supplier;
                                statusInvoice = isEmptyInvoice ? this.$constant.status_not_receive_invoice_supplier : this.$constant.status_received_invoice_supplier;
                            }
                            if(ticket.statusHasInvoice === null){
                                ticket.statusInvoiceName = this.$constant.not_receive_invoice_supplier;
                            }
                            if (ticket.typeSrc == this.$constant.TYPE_SRC_ORDER_SUPPLIER && ticket.statusHasInvoice == this.$constant.statusActive && ticket.statusInvoiceName == this.$constant.not_receive_invoice_supplier) {
                                totalDebtInvoice += 1; 
                            }
                            if(ticket.advance){
                                totalAmountAdvance += +ticket.advance;
                            }
                            if(ticket.settlement){
                                totalAmountSettlement += +ticket.settlement;
                            }
                            if(ticket.typePayment == this.$constant.paymentSettlementAdvanceCashMoney){
                                paymentChannelCashMoney += +ticket.advance;
                            }
                            if(ticket.typePayment == this.$constant.paymentSettlementAdvanceCardMoney){
                                paymentChannelCardMoney += +ticket.advance;
                            }
                            if(ticket.typePayment == this.$constant.paymentSettlementAdvanceTransferMoney){
                                paymentChannelTransferMoney += +ticket.advance;
                            }
                            ticket.difference = +ticket.settlement - +ticket.advance
                            ticket.statusInvoice = statusInvoice;
                        });
                    }
                    this.entry.totalAmountAdvance = totalAmountAdvance;
                    this.entry.totalAmountSettlement = totalAmountSettlement;
                    this.entry.totalAmountCompare = this.entry.totalAmountAdvance - this.entry.totalAmountSettlement;
                    this.entry.cash = paymentChannelCashMoney;
                    this.entry.card = paymentChannelCardMoney;
                    this.entry.transfer = paymentChannelTransferMoney;
                    this.entry.totalDebtInvoice = totalDebtInvoice;
                    if (this.entry.cash) {
                        this.paymentChannelCash = true;
                    }
                    if (this.entry.card) {
                        this.paymentChannelCard = true;
                    }
                    if (this.entry.transfer) {
                        this.paymentChannelTransfer = true;
                    }
                    this.$forceUpdate();
                },
                deep: true
            },
            modelValue: {
                handler: function(dataNew) {
                    if (!dataNew) return;
                    this.entry = dataNew;
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
        outline: none !important;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .overflow-x-auto{
        overflow-x: auto !important;
    }
    table tr th, table tr td{
        vertical-align: middle !important;
        height: 40px;
        min-height: 40px !important;
        align-items: center !important;
    }
    .col-left {
        background-color: #fff;

        // table {
        //     height: calc(100vh - 150px);
        //     overflow: hidden auto;
        //     display: block;
        //     margin-bottom: 0;

        //     .popover-wrap {
        //         font-weight: 400;
        //         margin-bottom: 5px;
        //         position: relative;

        //         .popover-wrap__content {
        //             padding-left: 10rem;
        //             .arrow-up {
        //                 width: 0;
        //                 height: 0;
        //                 border-left: 10px solid transparent;
        //                 border-right: 10px solid transparent;
        //                 border-bottom: 10px solid teal;

        //                 position: absolute;
        //                 top: -10px;
        //                 left: calc(10rem + 50px);

        //                 &:after {
        //                     margin-left: -10px;
        //                     content: " ";
        //                     top: 1px;
        //                     border-width: 10px;
        //                     border-top-width: 0;
        //                     border-color: transparent;
        //                     border-bottom-color: #fff;
        //                     position: absolute;
        //                     display: block;
        //                     width: 0;
        //                     height: 0;
        //                     border-style: solid;
        //                     z-index: 11;
        //                 }
        //             }

        //             .content-tooltip {
        //                 border: 1px solid teal;
        //                 box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
        //                 width: max-content;
        //                 padding: 10px;
        //                 background-color: white;
        //                 font-weight: 600;

        //                 .price-item {
        //                     padding: 0 5px;
        //                 }
        //             }

        //             .inventory-by-branch {
        //                 border: 1px solid teal;
        //                 box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
        //                 padding: 10px;
        //                 background-color: white;
        //                 font-weight: 600;
        //                 min-width: 500px;

        //                 table {
        //                     height: auto;
        //                     width: 100%;
        //                     display: table;
        //                 }
        //             }

        //             textarea {
        //                 border-color: teal;
        //                 box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
        //                 font-size: 13px;

        //                 height: auto;
        //                 min-height: 50px;
        //                 max-height: 112px;
        //                 width: 300px;

        //                 &:focus {
        //                     outline: none;
        //                 }
        //             }
        //         }
        //     }
        // }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .ticket-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-ticket-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-ticket-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: #fff;
        overflow: hidden auto;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;

            .btn-save {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;

                &:hover {
                    background-color: #168080 !important;
                }
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    
    .custom_format {
        width: 50% !important;
    }
    
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .cell-check{
        width: 30px;
        min-width: 30px;
        max-width: 30px;
    }
    .cell-name{
        width: 232px;
        min-width: 232px;
        max-width: 232px;
    }
    .cell-auto{
        width: 160px;
        min-width: 160px;
        max-width: 160px;
    }
    .cell-large{
        width: 540px;
        min-width: 540px;
        max-width: 540px;
    }
    .col-left table{
        overflow-x: auto;
    }
    .trSearch{
        height: 40px !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
</style>
