<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2" v-text="$t('message.ModalSendZalo.title')"></h5>
                    <button type="button" class="close pt-3" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >

                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-12 d-flex align-items-center pl-0">
                            <label class="container-radio m-0 d-flex align-items-center min-w-100">
                                {{$t('message.common.sendToGroup')}}
                                <input type="radio" name="typeSend" v-model="typeSend" :value="this.$constant.typeSendZaloGroup" class="mr-2">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row mx-4 mt-4 pl-4" v-if="typeSend == this.$constant.typeSendZaloGroup">
                        <div class="col-12">
                            <div class="row mb-2">
                                <div class="col-lg-12 py-2">
                                    {{$t('message.common.chooseZaloTemplate')}}
                                </div>  
                            </div>
                            <div class="row mb-2">
                                <div class="col-lg-3" v-for="entry in entries" :key="entry.id">
                                    <a href="javascript:void(0)"  @click="selectTemplate(entry.id)" :class="[(templateId == entry.id ? 'btn btn-primary active' : 'btn btn-outline-secondary notActive'), ('mx-0') ]">
                                        {{entry.name}}
                                    </a>
                                </div>  
                            </div>
                        </div>
                    </div>
                    <div class="row mx-4 mb-4 pl-4 py-2 d-flex align-items-center" v-if="typeSend == this.$constant.typeSendZaloGroup">
                        <QSelectZaloGroups @updateOption="updateOptionGroups"></QSelectZaloGroups>
                    </div>
                    <div class="row mx-4 my-4 mt-1" v-if="this.sendZns">
                        <div class="col-12 d-flex align-items-center pl-0">
                            <label class="container-radio m-0 d-flex align-items-center min-w-100">
                                {{$t('message.common.sendZNS')}}
                                <input type="radio" name="typeSend" v-model="typeSend" :value="this.$constant.typeSendZaloZNS" class="mr-2">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row mx-4 mb-4 pl-4 py-2 d-flex align-items-center" v-if="typeSend == this.$constant.typeSendZaloZNS && this.sendZns">
                        <QSelectPhone :optionsPhone="this.phones" @updateOption="updateOption"></QSelectPhone>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-save mr-2" @click="confirm()" :disabled="this.isCallApi" >
                                <i class="fa fa-share mx-2"></i>
                                {{$t('message.SendMailZaloIndex.modal.button.send')}}
                            </button>
                            <button class="btn btn-secondary text-white" @click="hide()">
                                <i class="fa fa-ban mx-2"></i>
                                {{$t('message.SendMailZaloIndex.modal.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import {$get, $post, $alert} from "@/lib/utils";
import QSelect from "./QSelect";
import QSelectPhone from "./QSelectPhone";
import QSelectZaloGroups from "./QSelectZaloGroups";
import emitter from "@/lib/emitter";
export default {
    name: "ModalSelectSendZaloGroupTemplate",
    components: {QSelect, QSelectPhone, QSelectZaloGroups},
    data(){
        return {
            entries: [],
            templateId: null,
            type: null,
            object: null,
            ids: null,
            isCallApi: false,

            groups: [],
            groupIds: [],
            typeSend: null,
            phone: null,
            phones: [],
            sendZns: false,
        }
    },
    methods: {
        updateListMailCc(mails) {
            this.listMailsCC = mails;
        },
        updateListMailTo(mails) {
            this.listMailsTo = mails;
        },
        selectTemplate(id){
            this.templateId = id;            
        },
        show(type, object, ids, typeSend, phone, sendZns = false) {            
            this.type = type;
            this.object = object;
            this.ids = ids;
            this.sendZns = sendZns;
            this.typeSend = !this.sendZns ? this.$constant.typeSendZaloGroup : typeSend;
            this.groupIds = [];
            this.phone = phone;
            this.phones = phone ? [phone] : [];
            this.getZaloGroups();
            this.getTemplates();
            window.jQuery(this.$refs.modal).modal('show');
        },
       
        async getTemplates(){
            const response = await $get('/v1/pdf-templates/get-templates',{code: this.type});             
            if(response.status.code == 200){
                if(response.result) {                    
                    this.entries = response.result.data;
                    this.templateId = (response.result.id ? response.result.id : null);
                }
            }
        },
        async getZaloGroups(){
            const response = await $get('/v1/zalo/get-zalo-groups');             
            this.groups = response.status.code == 200 && response.result ? response.result : [];
        },
        hide() {
            this.showSendObj = false;
            this.hasSendMailObj = false;
            this.listMailsCC = [];
            this.listMailsTo = [];
            this.isCallApi = false;
            this.groupIds = [];
            this.typeSend = null;
            this.sendZns = false;
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async confirm(){
            emitter.emit("onLoading");            
            let params = {
                type: this.type,
                ids: this.ids,
                templateId: this.templateId,
                groupIds: this.groupIds,
                typeZalo: true,
                typeZaloZNS: this.typeSend == this.$constant.typeSendZaloZNS ? true : false,
                phone: this.phone
            }
            let response;
            this.isCallApi = true;
            switch (this.object) {
                case this.$constant.typeImportProducts:
                    response = await $post('/v1/order-suppliers/send-zalo', {...params});
                    break;
                case this.$constant.typeSellProducts:
                    response = await $post('/v1/invoices/send-zalo', {...params});
                    break;
                case this.$constant.typeTransferProducts:
                    response = await $post('/v1/transfers/send-zalo', {...params});
                    break;
            }
            if(response && response.result){
                $alert({code: 200, message: this.$t('message.common.sendZaloSuccess')});
                this.hide();
            } else if (response && response.code == 422) {
                const errors = response.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: response.code, message: errors[key][0]});
                });
                this.isCallApi = false;
            } else {
                $alert({code: 500, message: this.$t('message.common.sendZaloFailed')});
                this.isCallApi = false;
            }
            emitter.emit("offLoading");
        },
        updateOption(value){
            this.phone = value;
        },
        updateOptionGroups(value){
            this.groupIds = value;
        }
    },
    beforeUnmount() {
        this.hide();
    }
}
</script>

<style scoped>
    .notActive{
        color:#000;
    }
    .notActive:hover {
        background-color: #ddd;
        color: #000;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
</style>