<template>
    <AppPermission :permission="permission">
        <a :class="this.class" v-if="this.typeClick" @click="openLink">
            {{label}}
        </a>
        <a :class="this.class" :href="href" v-else>
            {{label}}
        </a>
    </AppPermission>
</template>

<script>
import AppPermission from "./AppPermission";
export default {
    name: "PermissionLink",
    components: {AppPermission},
    props: ['class', 'href', 'permission', 'label', 'typeClick', 'routeName'],
    methods: {
        openLink(){
            this.$emit('click', this.routeName)
        }
    }
}
</script>

<style scoped>

</style>
