<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h5 class="modal-title py-2 px-2">
                        {{$t('message.LocationIndex.titleQuickAdd')}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-0 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    <i class="fa fa-info-circle mr-2"></i>
                                    <label class="border-bottom-1-omi">
                                        {{$t('message.LocationIndex.info')}}
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-3 mt-1">
                                <div class="col-6 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.branch')}}:<label class="text-danger px-0">*</label></div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <SelectSearch :placeholder="$t('message.LocationIndex.chooseBranch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" :border="true" :readonly="true" @update:modelValue="doFilterSearch" :label="'branches'"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-12 pl-0 text-bold">
                                    <i class="fa fa-list mr-2"></i>
                                    <label class="border-bottom-1-omi">
                                        {{$t('message.LocationIndex.categories')}}
                                    </label>
                                </div>
                            </div>
                            <div class="row detail-prd-row mt-3 m-0 w-100">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.LocationIndex.categoriesLevel1')}} <label class="text-danger">*</label>
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.LocationIndex.categoriesLevel2')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.LocationIndex.categoriesLevel3')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.LocationIndex.categoriesLevel4')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-auto p-2">
                                                    {{$t('message.LocationIndex.locations')}}
                                                </th>
                                                <th scope="col" class="pl-4 cell-status p-2">
                                                    <i class="fas fa-cog text-omi"></i>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="this.entries?.length > 0">
                                                <template v-for="(entry, index) in this.entries" :key="index">
                                                    <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                        <th scope="col" class="pl-4 cell-auto p-2">
                                                            <InputText v-model.trim="entry.cate1" :placeholder="$t('message.LocationIndex.examLevel1')" class="mb-0"></InputText>
                                                        </th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">
                                                            <InputText v-model.trim="entry.cate2" :placeholder="$t('message.LocationIndex.examLevel2')" class="mb-0"></InputText>
                                                        </th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">
                                                            <InputText v-model.trim="entry.cate3" :placeholder="$t('message.LocationIndex.examLevel3')" class="mb-0"></InputText>
                                                        </th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">
                                                            <InputText v-model.trim="entry.cate4" :placeholder="$t('message.LocationIndex.examLevel4')" class="mb-0"></InputText>
                                                        </th>
                                                        <th scope="col" class="pl-4 cell-auto p-2">
                                                            <InputText v-model.trim="entry.position" :placeholder="$t('message.LocationIndex.examLocations')" class="mb-0"></InputText>
                                                        </th>
                                                        <th scope="col" class="pl-4 cell-status p-2" @click="removeRow(index)">
                                                            <i class="fa fa-trash-alt text-danger"></i>
                                                        </th>
                                                    </tr>
                                                </template>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mx-4 my-4">
                        <div class="col-12 flex-start p-0">
                            <button type="button" class="btn btn-white mr-2 border" @click="addRow">
                                <i class="fa fa-plus mr-1"></i>
                                {{$t('message.LocationIndex.addRow')}}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide" :title="$t('message.common.confirm')" :content="$t('message.LocationIndex.confirmCancelQuickAdd')"/>
    <ModalSelectLocations ref="ModalSelectLocations" @updateLocationsName="getLocationName" :addLocationCategory="true"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import ModalConfirm from "@/components/ModalConfirm.vue";
import InputNumber from '@/components/forms/InputNumber';
import store from "@/store";
import SelectSearch from "@/components/SelectSearch";
import ModalSelectLocations from '../ModalSelectLocations';
import { $get, $post, $alert, clone, removeVietnameseTones} from "@/lib/utils";
import emitter from '@/lib/emitter';
export default {
    name: "ModalQuickAddLocations",
    components: { InputText, InputNumber , ModalConfirm, SelectSearch, ModalSelectLocations},
    props: {
       
    },
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            entries:[],
            filterQuery: {
                branches: '',
            },
            currentBranchId: store.state.CurrentBranch.id,
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },  
            locationName: null,   
            locationActiveId: 0,
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        show() {
            this.setDefaultValue();
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide () {            
            this.$emit('reload');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setDefaultValue(){
            this.filterQuery.branches = this.currentBranchId;
            this.entries = [
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
                {cate1: null, cate2: null, cate3: null, cate4: null, position: null},
            ];
            this.locationActiveId = 0;
            this.locationName = null;
        },
        closeModal(){
            let hide = true;
            this.entries.forEach(entry => {
                if(entry.cate1 || entry.cate2 || entry.cate3 || entry.cate4 || entry.position){
                    hide = false;
                    this.$refs.ModalConfirm.show();
                    return;
                }
            });
            if(hide){
                this.hide();
            }
        },
        addRow(){
            if(this.entries.length < 20){
                this.entries.push({cate1: null, cate2: null, cate3: null, cate4: null, position: null})
            }else{
                $alert({code: 422, message: this.$t('message.LocationIndex.maxRow')});
            }
        },
        removeRow(index){
            this.entries.splice(index, 1);
            if(this.entries.length <= 0){
                this.addRow();
            }
        },
        showModalSelectLocations(){
             this.$refs.ModalSelectLocations.show();
        },
        getLocationName(input){
            this.locationActiveId = input.id ? input.id : 0;
            this.locationName = null;
            this.locationName = (input.nameLv1 ? input.nameLv1 : '' ) + (input.nameLv2 ? ' > ' + input.nameLv2 : '' ) + (input.nameLv3 ? ' > ' + input.nameLv3 : '' ) + (input.nameLv4 ? ' > ' + input.nameLv4: '' );
        },
        doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            this.filterQuery = {...query};
        },
        async save(){
            let data = [];
            this.entries.forEach(entry => {
                if(entry.cate1 || entry.cate2 || entry.cate3 || entry.cate4 || entry.position){
                    data.push(entry);
                }
            });
            if(data.length){
                let save = true;
                data.forEach(element => {
                    if(!element.cate1){
                        $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesLevel1')});
                        save = false;
                        return;
                    }
                })
                if(save){
                    let branchId = this.filterQuery.branches ? this.filterQuery.branches : this.currentBranchId;
                    const res = await $post('/v1/locations/createFast-location-category', {branchId: branchId, data: data});
                    if(res.result && !res.result.status && res.result.message){
                        $alert({code: 422, message: res.result.message});
                    }else if(res.result && res.result.status && res.result.message){
                        $alert({code: 200, message: res.result.message});
                        this.hide();
                    }
                }
            }else{
                $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesLevel1')});
            }
        },
    }
}
</script>
<style scoped lang="scss">
@import "../../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.border-bottom-1-omi{
    border: none;
    border-bottom: 1px solid $base-color;
}
.icon-arrow{
    background-color: none;
    outline: none;
    position: absolute;
    top: 5px;
    right: 5px;
}
.icon-arrow svg{
    fill: #212529;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.icon-arrow svg{
    width: 9px;
    height: 9px;
    fill: #666
}
</style>