<template>
    <AppPermission :permission="this.$permissions.users.data ? this.$permissions.users.data : ''">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <!-- <div class="col-xl-3 col-lg-3 col-md-4"> -->
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{$t('message.employeeIndex.title')}}</span>
                        
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader" >
                            <div class="row mx-2" >
                                <div class="col-8 text-title">
                                    <label>{{$t('message.common.numberRecord')}}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.common.branch')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showHide('payingBranch','payingBranchDown','payingBranchDownUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="payingBranchDown"></i>
                                        <i class="fa fa-chevron-up" id="payingBranchDownUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="payingBranch">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.employeeIndex.chooseBranch')" :modelValue="searchSidebar.payrollBranchId" :defaultOption="payrollBranch.filter" @update:modelValue="doFilterSearch" :label="'payrollBranchId'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{$t('message.employeeIndex.employee_job_title')}}</label>
                                </div>
                                <div class="col-1 pl-0 ml-0">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showModalJobTitleAdd">
                                        <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                    </a>
                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showHide('jobTitle','jobTitleDown','jobTitleDownUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="jobTitleDown"></i>
                                        <i class="fa fa-chevron-up" id="jobTitleDownUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="jobTitle">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="text" :placeholder="$t('message.employeeIndex.chooseJobTitle')" v-model="jobTitle.search" @input="onInputFilterJobTitle">
                                        <button type="button" class="border-none bg-transparent btn-icon" v-show="jobTitle.search" @click.stop="clearValue('jobTitle')"><i class="fa fa-times text-danger"></i></button>
                                        <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton">
                                            <template v-if="this.jobTitle.filter?.length > 0">
                                                <a class="dropdown-item" href="javascript:;" v-for="jobTitle in this.jobTitle.filter" :key="jobTitle.id" :value="jobTitle.id" @click="searchUserForJobTitle(jobTitle.id, jobTitle.name)" :class="this.searchSidebar.jobTitleId === jobTitle.id ? 'text-bold' :''">
                                                    {{jobTitle.name}}<i class="fa fa-pencil-alt ml-2 text-secondary" @click.stop="showModalJobTitleEdit(jobTitle.id)"></i>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a class="dropdown-item" href="javascript:;">
                                                    {{$t('message.common.notData')}}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{$t('message.employeeIndex.employee_department')}}</label>
                                </div>
                                <div class="col-1 pl-0 ml-0">
                                        <AppPermission :permission="this.$permissions.departments.create ? this.$permissions.departments.create : ''    ">
                                            <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showModalCreateDepartment">
                                                <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                            </a>
                                        </AppPermission>
                                </div>
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('depament','depamentDown','depamentUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="depamentDown"></i>
                                        <i class="fa fa-chevron-up" id="depamentUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-0 block__depament" id="depament">
                            <!-- Danh sách -->
                            <div class="row my-0 py-2">
                                <div class="col-12">
                                    <div class="prd-search-box1" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="text" :placeholder="$t('message.employeeIndex.chooseDepartment')" v-model="department.search" @input="onInputFilterDepartment">
                                        <button type="button" class="border-none bg-transparent btn-icon" v-show="department.search" @click.stop="clearValue('department')"><i class="fa fa-times text-danger"></i></button>
                                        <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButtonDepartment">
                                            <template v-if="department.filter?.length > 0">
                                                <a class="dropdown-item" href="javascript:;" v-for="departmentItem in department.filter" :key="departmentItem.id" :value="departmentItem.id" @click="searchEmployeeForDepartment(departmentItem.id, departmentItem.name)" :class="this.searchSidebar.departmentId === departmentItem.id ? 'text-bold' :''">
                                                    {{departmentItem.name}}
                                                    <AppPermission :permission="this.$permissions.departments.update ? this.$permissions.departments.update : ''    ">
                                                        <i class="fa fa-pencil-alt ml-2 text-secondary"  @click.stop="showModalEditDepartment(departmentItem.id)"></i>
                                                    </AppPermission>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a class="dropdown-item" href="javascript:;">
                                                    {{$t('message.common.notData')}}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tìm kiếm theo trạng thái: -->
                    <div class="card mt-4" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-9">
                                            <label class="typeTitle">{{$t('message.employeeIndex.employee_status')}}</label>
                                        </div>
                                        <div class="col-1">
                                            <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                                @click="showHide('status', 'dDown', 'dUp')">
                                                <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                                <i class="fa fa-chevron-up" id="dUp"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-2 py-0" id="status">
                                    <div class="card-body my-0 py-2" id="sBody">
                                        <div class="row">
                                            <div class="col-12" v-for="item in this.$constant.listStatusUser" :key="item.id">
                                                <label class="container-radio ml-0">{{$t(item.name)}}
                                                    <input type="radio" name="sttActive" @click="doFilter('sttActive', item.id)" class="chkType" :checked="this.emStatus.search === item.id ? true: false">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-8"> -->
                <div class="mainRight">
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-5">
                                <div class="search-box">
                                    <i class="fa fa-search mr-2"></i>                                                   
                                    <input type="text" class="search-input" id="search-header" v-model.trim="this.searchHead" 
                                    :placeholder="$t('message.employeeIndex.input.filter')" @keydown.enter="searchHeadEmployee($event)" autocomplete="off"/>                            
                                </div>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                                <button type="button" class="btn btn-default" @click="clearFilter">
                                    <i class="fa fa-filter"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <AppPermission :permission="this.$permissions.users.create ? this.$permissions.users.create : ''">
                                    <button class="btn btn-save" type="button" @click="showModalAddInfoEmployee">
                                        <i class="fa fa-plus px-2" />{{ $t('message.employeeIndex.button.add') }}
                                    </button>
                                </AppPermission>
                                 
                                    <button class="btn btn-save" type="button" id="dropdownMenuButton" v-on:mouseleave="toggleSelectFile = false"
                                        style="padding:7px" v-on:mouseover="toggleSelectFile = true" aria-expanded="false">
                                        <i class="fa fa-file px-2"></i>
                                        {{ $t('message.employeeIndex.button.file') }}
                                        <div class="dropdown-menu dropdown-menu-right d-block mt-0"  v-if="toggleSelectFile" style="max-width: 300px">
                                            <div class="px-3">                                               
                                                <AppPermission :permission="this.$permissions.users.import ? this.$permissions.users.import : ''">
                                                    <div class="label__file py-2" @click="showModalUploadFileExcel">
                                                        <span style="width: 30px;"><i class="fas fa-file-import px-2"></i></span>
                                                        {{ $t('message.employeeIndex.button.import') }}
                                                    </div>
                                                </AppPermission>
                                                
                                                <AppPermission :permission="this.$permissions.users.export ? this.$permissions.users.export : ''">
                                                    <div class="py-2" @click="selectOptionExport('exportExcel')">
                                                        <i class='fas fa-file-export px-2'></i>
                                                        {{ $t('message.employeeIndex.button.export') }}
                                                    </div>
                                                </AppPermission>
                                            </div>
                                        </div>
                                    </button>                   
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="employees"></SelectField>
                                </div>
                            </div>
                        </div>
                        <div>
                            <!-- <Paginate :value="paginate" :record="numberRecord" @change="pageChange" /> -->
                            <Pagination :value="paginate" :record="numberRecord" @change="pageChange" />
                        </div>
                        <div class="row my-2"></div>
                        <div class="row mx-0 my-2" id="divTable">
                            <table class="table table-index table-striped lstProduct" v-if="!noField">
                                <thead>
                                    <tr>
                                        <th style="min-width:40px;max-width:200px" scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox" id="chkAll" /></th>
                                        <th scope="col" style="vertical-align: middle !important;" v-for="(item, index) in fields" :key="index" v-show="item?.selected">
                                            <TableSortField :current="sortData.field" :no-sort="!item.noSort" @onsort="sort" :name="item.name" :field="item.field" class="description"/>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="users?.length > 0">
                                        <template v-for="(user, key) in users" :key="key" >
                                            <tr class="master-row" :class="[ user.id === userIdDetail ? 'backgroup__detail' : '', errorClass]" :id="'row_' + (key + 1)">                                
                                                <td><input type="checkbox"></td>
                                                <td v-show="(fields.find(item => item.field === 'code'))?.selected" class="py-0" style="max-width:150px;">
                                                    <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description">{{ user.code }}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'name'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description">{{ user.name }}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'birthDate'))?.selected" class="py-0" style="max-width:150px;">
                                                    <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description">{{user.birthDate?  $filters.formatDate(user.birthDate) : '' }}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'gender'))?.selected" class="py-0" style="max-width:150px;">
                                                    <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description">{{ $filters.formatSex(user.gender) }}</span>
                                                    </a>
                                                </td>             
                                                <td v-show="(fields.find(item => item.field === 'phone'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)" >
                                                        <span class="description">{{ user.phone }}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'email'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class=" text-dark tagA" @click="showDetail(user.id)" :title="user.email ? user.email : ''">
                                                        <span class="description">{{ user.email }}</span>
                                                    </a>
                                                </td>                       
                                                <td v-show="(fields.find(item => item.field === 'depamentName'))?.selected" class=" py-0">
                                                    <a :href="'#row_' + key" class=" text-dark tagA" @click="showDetail(user.id)" :title="user.departmentName ? user.departmentName : ''">
                                                        <span class="description">{{ user.departmentName ? user.departmentName : '' }}</span>
                                                    </a>                                       
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'jobTitle'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class=" text-dark tagA" @click="showDetail(user.id)" :title="user.job_title ? user.job_title.name : ''">
                                                        <span class="description">{{ user.job_title ? user.job_title.name : '' }}</span>
                                                    </a>
                                                </td>                                  
                                                
                                                <td v-show="(fields.find(item => item.field === 'workingbranchId'))?.selected" class=" py-0">
                                                    <a :href="'#row_' + key" class=" text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description" :title="user.workingBranch ? user.workingBranch : ''">{{ user.workingBranch ? user.workingBranch: ''}}</span>
                                                    </a>
                                                </td>                                    
                                                <td v-show="(fields.find(item => item.field === 'userName'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class=" text-dark tagA" @click="showDetail(user.id)">
                                                        <span class="description">{{ user.userName ? user.userName : '' }}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'status'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class="description text-dark tagA">
                                                        <span class="description">{{this.getStatus(user.status)}}</span>
                                                    </a>
                                                </td>
                                                <td v-show="(fields.find(item => item.field === 'createdAt'))?.selected" class="py-0">
                                                    <a :href="'#row_' + key" class="description text-dark tagA">
                                                        <span class="description"> {{this.convertDatetime(user.createdAt)}}</span>
                                                    </a>
                                                </td>
                                            </tr>
    
                                            <tr v-if="userIdDetail === user.id" :class="[ user.id === userIdDetail ? 'backgroup__detail' : '', errorClass]">
                                                <td class="no-boder"></td>
                                                <td :colspan="fields.length" class="no-boder">
                                                    <div class="row detail-prd-row">
                                                        <div class="col-2 mt-2" @click="showContent('info')">
                                                            <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{ $t('message.employeeIndex.tab.info') }}
                                                            </span>
                                                        </div>
                                                        <!-- <div class="col-2 mt-2" @click="showContent('plan')">
                                                            <span :class="[ headerActive === 'plan' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                Lịch làm việc
                                                            </span>
                                                        </div>
                                                        <div class="col-2 mt-2" @click="showContent('setSalary')">
                                                            <span :class="[ headerActive === 'setSalary' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                Thiết lập lương
                                                            </span>
                                                        </div>
                                                        <div class="col-2 mt-2" @click="showContent('employeeSalaryBebt')">
                                                            <span :class="[ headerActive === 'employeeSalaryBebt' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                Nợ lương nhân viên
                                                            </span>
                                                        </div> -->
                                                    </div>
                                                </td>
                                            </tr>
    
                                            <!-- chi tiet nhan vien -->
                                            <tr class="detail-row bg-white" v-if="userIdDetail === user.id">
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4 detail__user">
                                                        <div class="row mt-2">
                                                            <div class="col-2 d-flex justify-content-around">
                                                                <div class="img-container mt-4">
                                                                    <div class="img-main w-100" >
                                                                        <img v-if="user.imageUrl!== null" :src=" user.imageUrl!== null ? user.imageUrl : ''" alt="" class="img-main-item">
                                                                        <img v-else src="../../../public/img/default-product.png" alt="" class="img-main-item">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 px-0">
                                                                <div class="mx-1">
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_code') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                                
                                                                                {{ user.code }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_name') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                                {{ user.name }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_birthday') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                            {{user.birthDate?  $filters.formatDate(user.birthDate) : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_gender') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                                {{ $filters.formatSex(user.gender) }}                                                                    
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                       
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_department') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                                <div class="row">
                                                                                    <div class="col-12" :title="user.departmentName ? user.departmentName : '' ">{{ user.departmentName ? user.departmentName : '' }}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <template v-if="user.history && user.history.length">
                                                                            <div class="row my-2" v-for="h in user.history" :key="h.id">                                                                        
                                                                                <div class="col-12 text-danger pb-1"> {{h.endDate != null ? ' - ' + h.department?.name : '' }} </div>                                                                    
                                                                            </div>
                                                                        </template>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-4">
                                                                                {{ $t('message.employeeIndex.employee_job_title') }}:
                                                                            </div>
                                                                            <div class="col-8 ow-break-word">
                                                                                {{ user.job_title ? user.job_title.name : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                         
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class=" mx-1">  
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_branch_work') }}:
                                                                            </div>
                                                                            <div class="col-6 ow-break-word">
                                                                                <div class="row">
                                                                                    <div class="col-12" :title=" user.workingBranch ? user.workingBranch : '' ">{{ user.workingBranch ? user.workingBranch : '' }}</div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_user_name') }}:
                                                                            </div>                                                                
                                                                            <div class="col-6 ow-break-word">
                                                                                <a :href="'/permissions/index?code='+ user.code" class="text-primary text-decoration-none" target="_blank">{{ user.userName ? user.userName : ''}}</a>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_phone') }}:
                                                                            </div>
                                                                            <div class="col-6 ow-break-word">
                                                                                {{ user.phone }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_email') }}:
                                                                            </div>
                                                                            <div class="col-6 ow-break-word">
                                                                                {{ user.email }}
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                      
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_status') }}:
                                                                            </div>
                                                                            <div class="col-6 ow-break-word">
                                                                                {{this.getStatus(user.status)}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-6">
                                                                                {{ $t('message.employeeIndex.employee_created_at') }}:
                                                                            </div>
                                                                            <div class="col-6 ow-break-word">
                                                                                <!-- {{moment(user.createdAt).format("DD/MM/YYYY HH:mm")}} -->
                                                                                {{this.convertDatetime(user.createdAt)}}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-3 border-left">
                                                                <div class="note-detail ow-break-word">
                                                                    <div v-if="user.note">{{ user.note }}</div>
                                                                    <div v-else class="label__note">{{ $t('message.employeeIndex.note') }}</div>
                                                                </div>
                                                            </div>
                                                        </div>         
                                                        <!-- v-if="user.id != this.$constant.isAdmin"                                                 -->
                                                        <div class="row detail-prd-row flex-end mt-4 pr-5" >
                                                            <AppPermission :permission="this.$permissions.users?.update ? this.$permissions.users.update : ''" >
                                                                <button type="button" class="btn btn-save mx-1" @click="showModalUpdateEmployee(user.id)">
                                                                    <i class="fa fa-check"></i>
                                                                    {{ $t('message.employeeIndex.button.update') }}
                                                                </button>
                                                            </AppPermission>
                                                            <AppPermission :permission="this.$permissions.users?.inActive ? this.$permissions.users.inActive : ''">
                                                                <template v-if="user.id != this.$constant.isAdmin" >
                                                                    <button type="button" class="btn btn-danger mx-1" 
                                                                        v-if="user.status == this.$constant.statusActive" @click="modalStopOrActiveUser(user, this.$constant.statusInActive)">
                                                                        <i class="fa fa-lock mr-2"></i>
                                                                        {{ $t('message.employeeIndex.button.inActive') }}
                                                                    </button>
                                                                    <button type="button" class="btn btn-primary mx-1" v-else @click="modalStopOrActiveUser(user, this.$constant.statusActive)">
                                                                        <i class="fa fa-unlock"></i> 
                                                                        {{  $t('message.employeeIndex.button.active') }}
                                                                    </button>
                                                                </template>
                                                               
                                                            </AppPermission>
                                                            <AppPermission :permission="this.$permissions.users?.remove ? this.$permissions.users.remove : ''">
                                                                <button type="button" class="btn btn-danger mx-1" :href="'javascript:;'"  
                                                                v-if="user.id != this.$constant.isAdmin" 
                                                                @click="showModalRemoveEmployees(user.id, user.name)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                    {{ $t('message.employeeIndex.button.remove') }}
                                                                </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <p v-text="!users ? $t('message.common.notData') : $t('message.common.notFound')"></p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalRemoveEmployees ref="ModalRemoveEmployees" @inputData="renderData"/>
        <ModalAddInfoEmployee ref="ModalAddInfoEmployee" :action="this.action" @updated="renderData"/>
        <ModalCreateDepartment ref="ModalCreateDepartment" @updated="renderData" />
        <!-- renderListDataCreate -->
        <ModalEditDepartment  ref="ModalEditDepartment"  @updated="renderListData"/>
        <ModalAddJobTitle ref="ModalAddJobTitle" @inputData="renderData" :typeEditAdd="typeEditAdd"/>
        <ModalStopOrActiveUser ref="ModalStopOrActiveUser" :title="this.title" :content="this.content" :type="this.type" @updated="getUsers()"  />
        <ModalImportExcel ref="ModalImportExcel" @updated="getUsers()"/>
        <ModalErrorMessages ref="ModalErrorMessages" :update="true"/>
        <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
    </AppPermission>
</template>

<script>
import { $get, $alert, removeVietnameseTones, isEmpty} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import emitter from "@/lib/emitter";
import SelectField from "../../components/SelectField";
import ModalRemoveEmployees from '@/components/employees/ModalRemoveEmployees.vue';
import ModalAddInfoEmployee from '@/components/employees/ModalAddInfoEmployees.vue';
import ModalAddJobTitle from "@/components/employees/ModalAddJobTitle";
import ModalCreateDepartment from '../department/ModalCreateDepartment.vue';
import ModalEditDepartment from '../department/ModalEditDepartment.vue';
import http from '@/services/api';
import store from "../../store";
import SelectSearch from "@/components/SelectSearch.vue";
import employeeMixin from '@/employeeMixin';
import ModalStopOrActiveUser from '../users/ModalStopUser.vue';
import AppPermission from '@/components/AppPermission';
import ModalImportExcel from '@/components/employees/ModalImportExcel.vue';
import ModalErrorMessages from '@/components/ModalErrorMessages.vue';
import exportMixin from '@/exportMixin';
import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
export default {
name: "EmployeeIndex",
mixins: [employeeMixin, exportMixin],
components: {
    SelectField,
    Pagination,
    TableSortField,
    ModalRemoveEmployees,
    ModalAddInfoEmployee,
    ModalAddJobTitle,
    ModalCreateDepartment,
    ModalEditDepartment,
    SelectSearch,
    ModalStopOrActiveUser,
    AppPermission,
    ModalImportExcel,
    ModalErrorMessages,
    ModalSelectOptionExport
},
data() {
    return {  
        title: null,
        toggleSelectFile: false,
        content: null,
        type: null,
        user: store.state.Auth.user,      
        users: null,
        isEdit: null,
        
        sortData: {
            field: null,
            direction: null,
        },
        fields: this.employeeFields(),
        paginate: {
            totalRecord: null,
            currentPage: null,
            lastPage: null,
        },
        numberRecord: 15,
        noField: false,
        userIdDetail: null,
        headerActive: 'info',
        typeEditAdd: 'ADD',
        focus: false,
        jobTitle: {
            all: null,
            filter: null,
            search: null,
        },
        jobTitleEditId: null,
        // dung cho filter sibar
        department : {
            all: null,
            filter: null,
            search: null,
        },
        payrollBranch : {
            all: null,
            filter: null,
            search: null,
        },
        searchSidebar: {
            departmentId: null,
            jobTitleId: null,
            payrollBranchId: null
        },
        emStatus: {
            all: null,
            filter: null,
            search: null,
        },
        searchHead: null,
        // departments: []
    }
},
methods: {
    showModalUploadFileExcel () {
            this.$refs.ModalImportExcel.show();
        },
    searchUserForJobTitle(jobTitleId, name) {
        this.paginate.currentPage = 1;
        if(jobTitleId === this.searchSidebar.jobTitleId){
            jobTitleId = null;
            name= null;
        }
        this.searchSidebar.jobTitleId = jobTitleId;
        this.jobTitle.search = name;
        this.paginate.currentPage = 1;
        this.getUsers();
    },
    search(value){
        this.emStatus.search =  value;
        this.paginate.currentPage = 1;
        this.getUsers();  
    },
    reloadData() {
        this.getUsers();
        this.getJobTitle();
    },
    renderData(){
        this.getUsers();
        this.getDepartments();
        this.getJobTitle();
        this.getPayrollBranches();
    },
    onMouseLeave(){
        emitter.emit("mouseleave");
    },
    async pageChange(page) {
        this.paginate.currentPage = page;
        this.getUsers();
    },
    async sort(field, direction) {
        this.sortData.field = field;
        this.sortData.direction = direction;
        this.getUsers();
    },
    async onChange(v) {
        this.numberRecord = v.target.value
        this.paginate.currentPage = 1;
        await this.getUsers();
    },
    async getUsers() {
        emitter.emit("onLoading");
        const params = {
            page: this.paginate.currentPage,
            field: this.sortData.field,
            direction: this.sortData.direction,
            numberRecord: this.numberRecord,
            departmentId: this.searchSidebar.departmentId,
            inputSearch: this.searchHead,
            jobTitleId: this.searchSidebar.jobTitleId,
            payrollBranchId: this.searchSidebar.payrollBranchId !== null ? this.searchSidebar.payrollBranchId : null,
            status: this.emStatus.search            
        };                
        const res = await $get('/v1/employee', params);              
        if (res.status.code != 200) {
            $alert({code: 500, message: this.$t('message.common.errorServer')});
            return;
        } 
        
        this.users = this.getDataUser(res.result.data);       
        this.paginate.totalRecord = res.result.total;
        this.paginate.currentPage = res.result.current_page;
        this.paginate.lastPage = res.result.last_page;
        this.numberRecord = res.result.per_page;
        emitter.emit("offLoading");
    },
    getDataUser(data){
        data.forEach(item => {
            item.workingBranch = this.getWorkBranch(item.user_branch);
            item.departmentName = this.getDepartmentName(item.user_deparment);
            if(item.avatar && JSON.parse(item.avatar)) {
                item.imageUrl = JSON.parse(item.avatar).url;
            }
            else {
                item.imageUrl = null
            }
        });                
        return data;
    },
    getWorkBranch(data){       
        let branchName = '';
        data.forEach(value => {
            if(!isEmpty(value.branch) && !isEmpty(value.branch.name)) {
                branchName = (branchName != '' ? branchName + ' , ' : '' ) + (value.branch.name ?  value.branch.name : '');
            }            
        });        
        return branchName;
    },
    getDepartmentName(data){
        let name = '';
        data.forEach(value => {
            if(!isEmpty(value.department) && !isEmpty(value.department.name)){               
                name = (name != '' ? name + ' , ' : '' ) + (value.department.name ?  value.department.name : '');
            }            
        });
        return name;
    },
    async getJobTitle(){
        const resJobTitle = await $get('/v1/job-title/data');
        this.jobTitle.all = resJobTitle.data.entries;
        this.jobTitle.filter = resJobTitle.data.entries;
        this.jobTitle.all.forEach(jt => {
            if(jt.id === this.jobTitleEditId && this.searchSidebar.jobTitleId === jt.id){
                this.jobTitle.search = jt.name
            }
        });
        const foundJobTitle = this.jobTitle.all.find(jt => jt.id === this.searchSidebar.jobTitleId);
        if(!foundJobTitle){
            this.jobTitle.search = null;
        }
    },
    showHide(name,iDown, iUp){
        var dropdownContent = document.getElementById(name);
        dropdownContent.classList.toggle("dp-hide");
        var down = document.getElementById(iDown);
        down.classList.toggle("dp-hide");
        var up = document.getElementById(iUp);
        up.classList.toggle("dp-hide");
    },
    showModalAddInfoEmployee () {        
        this.action = this.$t('message.modalCreateEmployee.title_create');        
        this.$refs.ModalAddInfoEmployee.show();            
    },
    showModalUpdateEmployee(id){        
        this.action = this.$t('message.modalCreateEmployee.title_update');
        this.$refs.ModalAddInfoEmployee.show(id);
    },
    showModalRemoveEmployees(id, name) {
            this.$refs.ModalRemoveEmployees.show(id, name);
        },
    renderDataUsers() {
        this.getUsers();
    },
    showDetail(id) {
        if (id === this.userIdDetail) {
            this.userIdDetail = null;
        } else {
            this.userIdDetail = id;
            this.headerActive = 'info';
        }
    },
    showContent(name) {
        this.headerActive = name;
    },
    showModalJobTitleAdd(){
        this.typeEditAdd = 'ADD';
        this.$refs.ModalAddJobTitle.show();
    },
    showModalJobTitleEdit(id){
        this.jobTitleEditId = id;
        this.typeEditAdd = 'EDIT';
        this.$refs.ModalAddJobTitle.show(id);
    },
    showDropDown(){
        this.focus = true;
    },
    hideDropDown(){
        this.focus = false;
    },
    async getDepartments() {
        const res = await $get('/v1/employee/departments');
        if (res.status.code != 200) {
            $alert({code: 500, message: this.$t('message.common.errorServer')});
            return;
        } 
        this.department.all = res.result;
        this.department.filter = res.result;
    },
    searchEmployeeForDepartment(departmentId, name) {
        this.paginate.currentPage = 1;
        if(departmentId === this.searchSidebar.departmentId){
            departmentId = null;
            name= null;
        }
        this.searchSidebar.departmentId = departmentId;
        this.department.search = name;
        this.paginate.currentPage = 1;
        // this.showHide('depament','depamentDown','depamentUp');
        this.getUsers();
    },
    
    searchHeadEmployee(event) {
        this.paginate.currentPage = 1;
        // this.searchHead = event.target.value.trim();
        this.getUsers();
    },

    onInputFilterDepartment(){
        if(this.department.search == ''){
            this.department.filter = this.department.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.department.all.length; index++) {
            if (removeVietnameseTones(this.department.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.department.search.toLowerCase())) > -1){
                arr.push(this.department.all[index]);
            }
        }
        this.department.filter = arr;

    },
    onInputFilterPayrollBranch(){
        if(this.payrollBranch.search == ''){
            this.payrollBranch.filter = this.payrollBranch.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.payrollBranch.all.length; index++) {
            if (removeVietnameseTones(this.payrollBranch.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.payrollBranch.search.toLowerCase())) > -1){
                arr.push(this.payrollBranch.all[index]);
            }
        }
        this.payrollBranch.filter = arr;
    },
    onInputFilterJobTitle(){
        if(this.jobTitle.search == ''){
            this.jobTitle.filter = this.jobTitle.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.jobTitle.all.length; index++) {
            if (removeVietnameseTones(this.jobTitle.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.jobTitle.search.toLowerCase())) > -1){
                arr.push(this.jobTitle.all[index]);
            }
        }
        this.jobTitle.filter = arr;

    },
    showModalCreateDepartment() {
        this.$refs.ModalCreateDepartment.show()
    },
    showModalEditDepartment(id) {
        this.$refs.ModalEditDepartment.show(id);
    },
    renderListDataCreate(newData) {
        this.department.filter.unshift(newData.data);
    },
    renderListData(newDepartment) {
        this.department.filter = this.department.filter.map(department => department.id != newDepartment.id ? newDepartment : newDepartment);
    },

    clearFilter() {
        this.searchSidebar.departmentId = null;
        this.searchSidebar.jobTitleId = null;
        this.searchSidebar.payrollBranchId = null;
        this.department.search = null;
        this.payrollBranch.search = null;
        // document.getElementById('search-header').value = null;
        this.jobTitle.search = null;
        this.sortData.field = null,
        this.sortData.direction = null,
        this.searchHead = null,
        this.payrollBranch.filter = this.payrollBranch.all,
        this.department.filter = this.department.all,
        this.jobTitle.filter = this.jobTitle.all,
        this.paginate.currentPage = 1,
        this.emStatus.search = null,
        this.numberRecord = 15;
        this.getUsers();
        emitter.emit("clearSelectSearchValue");
    },
    selectOptionExport(method = 'exportExcel', totalRecord = 0){
        let params = { method: method };
        totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
        this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
    },
    async exportFiles(params){
        if (params.method) {
            this[params.method](params.firstRecordIndex);
        }
    },
    async exportExcel(firstRecordIndex = 0) {
        emitter.emit("onLoading");
        const params = {
            departmentId: this.searchSidebar.departmentId,
            inputSearch: this.searchHead,
            jobTitleId: this.searchSidebar.jobTitleId,
            payrollBranchId: this.searchSidebar.payrollBranchId,
            status: this.emStatus.search,
            firstRecordIndex: firstRecordIndex,
        };
        
        const res = await http.download('/v1/employee/export', {fields: this.fields, ...params});
        this.returnDataExport(res);
    },
    getPayrollBranches() {
        const globalConfig = store.state.GlobalConfig;
        this.payrollBranch.all = globalConfig.branches;
        this.payrollBranch.filter = globalConfig.branches;
    },
    searchUserForPayrollBranch(id, name) 
    {
        this.paginate.currentPage = 1;
        if(id === this.searchSidebar.payrollBranchId){
            id = null;
            name= null;
        }
        this.searchSidebar.payrollBranchId = id;
        this.payrollBranch.search = name;
        this.getUsers();
    },
    async doFilterSearch(v){
        switch (v.label) {
            case 'payrollBranchId':
                this.searchSidebar.payrollBranchId = v.model;
                break;
        }
        this.paginate.currentPage = 1;
        this.getUsers();
    },
    async doFilter(field, value) {
        if(field == 'sttActive'){
            this.emStatus.search = value;
            this.paginate.currentPage = 1;
        }
        this.getUsers();
    },
    clearValue(options){
        switch (options) {
            case 'jobTitle':
                this.jobTitle.search = '';
                this.onInputFilterJobTitle();
                this.searchSidebar.jobTitleId = '';
                this.getUsers();
                break;
            case 'department':
                this.department.search = '';
                this.onInputFilterDepartment();
                this.searchSidebar.departmentId = '';
                this.getUsers();
                break;
        }
    },
    modalStopOrActiveUser(entry, status) {
        if(status){
            this.title = this.$t('message.common.confirm');
            this.content = this.$t('message.employeeIndex.confirmReActive');
            this.type = this.$constant.statusActive;
        }else{
            this.title = this.$t('message.common.confirm');
            this.content = this.$t('message.employeeIndex.confirmInActive');
            this.type = this.$constant.statusInActive;
        }
        this.$refs.ModalStopOrActiveUser.show(entry.id, entry.name, status);
    },
    async loadDetail(){
        await emitter.emit("clearSelectSearchValue");
        this.numberRecord = 15;
        await this.getUsers();
        this.users.forEach((entry) => {
            this.showDetail(entry.id);
        });
    },
  
},

created () {
    // this.clearFilter();
    this.getDepartments();
    this.getJobTitle();
    this.getPayrollBranches();
    emitter.on("deleted", () => {
           location.reload();
        });
    emitter.on("reloadDataEmployee", () => {
        this.renderData();
    });
},
mounted() {
    
    document.title = 'Quản lý nhân viên';
    const fields = localStorage.getItem('employeesSelectedFields_' + this.user.id);
    if (fields) {
        this.fields = JSON.parse(fields);
    }
    const query = this.$route.query;
    if(query['code'] != null){
        this.searchHead = query['code'];
        this.loadDetail();
    }else{
        this.clearFilter();
    }

},

watch: {
    fields: {
        handler(newValue){
            let noField = true;
            for (let index = 0; index < newValue.length; index++) {
                if (newValue[index]?.selected) {
                    noField = false;
                }
            }
            this.noField = noField;
        },
        deep: true
    }
},
computed:{
},
}
</script>
<style scoped>
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#typeCard {
  margin-top: -8px;
}
#number_records {
  margin-top: 11px;
}
.detail__user {
    max-width: 1400px;
}

.no-boder {
    border-top: none !important;
    padding-bottom: 0 !important;
}

/* .backgroup__detail {   
    background: #B1DDF0 !important;    
} */

.head-detail {
    min-width: 100px;
    display: block;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroup__detail-active {
    background: #fff !important;
}

.border-left {
    border-left: 1px solid #D9D9D9;
}
.note-detail {
    margin-left: 20px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize:none;
    width: 400px;
}

.label__note {
  color: #C7C5C5;
  padding: 10px;
}

.detail-row-img {
    width: 8vw;
}

.dropdown-item>i{
  display: none;
  position: absolute;
  right: 0.5rem;
}
.dropdown-item:hover > i{
  display: inline-block;
}
.dropdown-toggle{
  white-space: normal;
}
.dropdown-toggle::after{
  position: absolute;
  right: 1.5rem;
  top: 0.75rem;
}
.dropdown-item{
  white-space: normal;
  overflow-wrap: break-word;
}
.dropdownJobTitle{
    max-height: 250px;
}
.list__departments {
    max-height: 300px !important;
    overflow-y: scroll;
    z-index: 1000;
    position: absolute;
    top: 36px;
    width: 220px;
    left: 24px;
    box-shadow: 1px 2px 4px #bbb;
}
.img-container {
    display: flex;
    flex-direction: row;
    gap: 1rem
}
.img-main-item {
    width: 100% !important;
    object-fit: cover;
}
.block__depament {
    position: relative;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
</style>
