<template>
    <div class="row w-50 m-0 p-0" style="position: absolute; top: 5px; z-index: 1">
        <div class="col-lg-6 d-flex align-items-center">
            <span class="px-0">
                {{ $t("message.SendMailZaloIndex.modal.template_name") }}
            </span>
            <input type="text" :name="'inputTemplateName'" :id="'inputTemplateName'" class="form-control" v-model="this.templateName"/>
        </div>
        <div class="col-lg-2 d-flex align-items-center p-0">
            <span class="p-0">
                {{ $t("message.SendMailZaloIndex.modal.file") }}
            </span>           
        </div>
        <div class="col-lg-4">
            <SelectSearch :placeholder="$t('message.SendMailZaloIndex.modal.placeholder.file')" 
                :label="'paperPrint_'" :modelValue="this.fileId" 
                :readonly="true"
                :editTemplate="true"
                :defaultOption="this.printTemplates" @update:modelValue="changeFile"> 
            </SelectSearch>           
        </div>
    </div>

    <div class="row m-0 p-0 pt-5" style="background-color: #f8f8f8">
        <div class="col-6 px-1 mx-0">
            <ckeditor type="classic" v-model="this.editorData" :editor-url="'https://cdn.ckeditor.com/4.22.1/full-all/ckeditor.js'"></ckeditor>
        </div>
        <div class="col-6 px-1 mx-0" style="background: #eef3f7">
            <div class="editor-preview">
                <div class="col-12 py-2 text-right mb-4">
                    <label class="text-secondary">
                        {{ $t("message.SendMailZaloIndex.modal.read_before") }}
                    </label> 
                </div>
                <div v-html="editorDataClone" class="d-none"></div>
                <iframe :srcdoc="editorDataClone" type="application/pdf" scrolling="yes" width="100%" height="650"
                    frameborder="0" style="position: relative; z-index: 999" ref="frame">
                </iframe>
            </div>
        </div>
    </div>
</template>

<script>


import SelectSearch from "@/components/SelectSearch";
import { $get, $post } from "@/lib/utils";
import storeModul from "@/storeModul";
import { mapGetters } from "vuex";
export default {
    name: "ModalEditPdf",
    emits: ['updateContent', 'updateTemplateName', 'updateFileId'],
    mounted() {
        this.renderData();
        this.templateName = this.pdfTemplateName;
        this.fileId = this.file;
    },
    components: { SelectSearch },
    props: [],
    data() {
        return {
            editorData: null,
            fileId: this.file,
            templateName: this.pdfTemplateName,
        };
    },
    created() {},
    methods: {
        getPhoneHide(phone) {
            let phoneConvert = '';
            for (let index = 0; index < phone.length; index++) {
                if (index <= phone.length - 3) {
                    phoneConvert = phoneConvert + '*';
                }
                else {
                    phoneConvert = phoneConvert + phone[index];
                }
            }
            return phoneConvert;
        },
        async renderData(content = null) {
            this.editorData = content ? content : this.content;
            this.editorDataClone = content ? content : this.content;
            let barcode = '';
            if (this.dataValue) {
                this.dataValue.forEach(data => {
                    const image = '<img src="' + data.value + '" style=width:200px;>';
                    let newPhone = null;
                    switch (data.key) {
                        case '{Logo_Cua_Hang}':
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
                            break;
                        case '{Sort_Code}':
                            barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=` + data.value + `&amp;'
                      alt="" title=`+ data.value + ` width="80" height="80" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Van_Don_Ma_Vach}':
                            barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                      alt="" title=`+ data.value + ` width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Vach_Hoa_Don}':
                            barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                  alt="" title=`+ data.value + ` width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            break;
                        case '{Ma_Vach_Khach_Hang}':
                            barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=` + data.value + `%0A&code=Code128'
                    alt="" title=`+ data.value + ` width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            break;
                        case '{So_Dien_Thoai_An}':
                            newPhone = this.getPhoneHide(data.value);
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, newPhone);
                            break;
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
                            break;
                    }
                });
            }
        },
        changeFile(data){
            if(data && data.model){
                this.$emit('updateFileId', data.model);
            }
        },
    },
    watch: {
        editorData(newData) { 
            if(newData){
                this.renderData(newData);
                this.$emit('updateContent', newData);  
            }
        },
        templateName(newData){
            this.$emit('updateTemplateName', newData);
        },
        pdfTemplateName(newData){
            this.templateName = newData;
        },
        file(newData){
            this.fileId = newData;
            this.$emit('updateFileId', newData);
        }
    },
    computed: {
        ...mapGetters({
            content: 'sendMailTemplateStore/sendMailZaloContent',
            pdfTemplate: 'sendMailTemplateStore/sendMailZaloTemplate',
            pdfTemplateId: 'sendMailTemplateStore/sendMailZaloTemplateId',
            action: 'sendMailTemplateStore/action',
            file: 'sendMailTemplateStore/pdfFileId',
            dataValue: 'sendMailTemplateStore/dataValueKeys',
            printTemplates: 'sendMailTemplateStore/printTemplates',
            pdfTemplateName: 'sendMailTemplateStore/sendMailZaloTemplateName',
        }),
    },
};
</script>
<style scoped>
.cke_top {
    padding: 50px 8px 2px !important;
}

#rowSelect {
    position: absolute;
    top: 5px;
}

#inputPrint {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    background-color: #f8f8f8;
}

#inputPrint:focus,
#inputPrint:hover,
.prd-search-box1:hover,
.prd-search-box1:active {
    border-bottom-color: #0dac50;
}

#orderTemplate {
    display: block !important;
}

#printPaper {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    background-color: #f8f8f8;
}

#printPaper:hover,
#printPaper:focus {
    border-bottom: 1px solid #0dac50;
}
</style>
