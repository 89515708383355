<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 px-4 text-center" :class="entry.tickets && entry.tickets.length ? 'col-left' : ''">            
            <table class="table table-index table-striped" style="max-width: 90%;display: inline-block;border-radius: 10px;">
                <thead class="text-center">
                    <tr>
                        <th scope="col" style="width: 0.5%;"></th>
                        <th scope="col" style="width: 1%"  class="cell-auto">{{ $t('message.DiscountsBAS.table.STT') }}</th>
                        <th scope="col" style="width: 3%" class="cell-auto">{{ $t('message.DiscountsBAS.table.amount') }}</th>
                        <th scope="col" style="width: 9%" class="cell-auto text-left">{{ $t('message.DiscountsBAS.table.content') }}</th>     
                        <th scope="col" style="width: 8%" class="cell-auto text-left">{{ $t('message.DiscountsBAS.table.deductionLimit') }}</th>
                        <th scope="col" style="width: 1%"  class="cell-auto"></th>
                    </tr>
                </thead>
                <tbody class="font-weight-bold">
                    <template v-if="entry.details && entry.details.length">
                        <template v-for="(item, index) in entry.details" :key="index">
                            <tr>                                
                                <td scope="col" class="cell-auto" >
                                    <i  @click="removeDiscountsBasDetails(index)" class="fa fa-trash text-secondary" v-if="this.entry.details.length > 1" ></i>
                                </td>
                                
                                <td scope="col" class="cell-auto" v-text="index + 1"></td>               
                                <td scope="col" class="cell-auto">                                         
                                    <InputNumberFormat :pxNone="true" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" 
                                    v-model="item.amount" :min="1" :max="$constant.maxNumber" @update:modelValue="getTotalAmount" :wStyle="true"
                                    @changeData="changeData"
                                    />
                                </td>                
                                <td scope="col" class="cell-auto">                                    
                                    <InputText v-model.trim="item.content" :id="item.id" :placeholder="$t('message.DiscountsBAS.placeholder.content')" 
                                    :max="255" :sCustomer="true" :indexStyle="index" :bgTransparent="true" @changeData="changeData"/>
                                </td> 
                                <td>
                                    <InputText v-model.trim="item.deductionLimit" :placeholder="$t('message.DiscountsBAS.placeholder.deductionLimit')" :sCustomer="true" :bgTransparent="true"
                                    :max="255"  @changeData="changeData"/>
                                </td> 
                                <template v-if="index == 0">
                                    <td @click="addRow()">
                                        <i class="fa fa-plus"></i>
                                    </td>
                                </template>
                                <template v-else>
                                    <td></td>
                                </template>
                            </tr>
                        </template>
                    </template>                   
                </tbody>
            </table>
        </div>
        <div class="col-4 col-xl-3 px-0 col-right">
            <div class="col-right__content">
                <div class="overflow-a-part">
                    <!-- Dòng 1: Nhãn người tạo và Nhãn người tạo -->
                    <div class="row my-3 mx-0 p-0">
                        <div class="col-6">
                            <i class="fa fa-user-circle"></i>                            
                            <span class="px-2" style="font-weight: 400">{{ $t('message.DiscountsBAS.created_by') }}</span>                            
                        </div>                        
                        <div class="col-6" >
                            <i class="fa fa-clock"></i>                                                                                                          
                            <span  class="px-2" style="font-weight: 400">{{ $t('message.DiscountsBAS.created_at') }}</span>
                            
                        </div>
                    </div>
                    <!-- Dòng 2: Giá trị người tạo và Giá trị người tạo -->
                    <div class="row mt-2 mx-0 py-2">                        
                        <div class="col-6" v-text="this.entry.createdByName"> </div>                        
                        <div class="col-6" >
                            <span  class="px-2" style="font-weight: 400" v-if="entry.isUpdate">{{dt(this.entry.created_at)}}</span>
                            <span  class="px-2" style="font-weight: 400" v-else>{{this.entry.planPurchaseDate}}</span>
                        </div>        
                    </div>
                   
                    <div class="row mx-0 p-0">
                        <div class="col-6" style="display:flex; align-items: center;">                            
                            <span class="">{{ $t('message.DiscountsBAS.code') }}</span>
                        </div>                        
                        <div class="col-6" >
                            <InputText v-model.trim="entry.code" :placeholder="$t('message.common.enterBAScode')" :sCustomer="true" />
                        </div>
                    </div>
                     <!-- Dòng 3: Mã nhà cung cấp -->                    
                    <div class="row mx-0 my-3">
                        <div class="col-6" style="display:flex; align-items: center;">                            
                            <span class="">{{ $t('message.DiscountsBAS.supplierCode') }}</span>
                        </div>                        
                        <div class="col-6" >
                            <span>{{entry && entry?.supplier ? entry.supplier.code : (entry?.supplierCode ? entry.supplierCode : null)}}</span>
                        </div>
                    </div>
                    <!-- Dòng 4: Tên nhà cung cấp -->
                    <div class="row mx-0 p-0 pt-2 mb-3">
                        <div class="col-6" style="display:flex; align-items: center;">                            
                            <span class="">{{ $t('message.DiscountsBAS.supplierName') }}</span>
                        </div>                        
                        <div class="col-6" >
                             <span>{{entry && entry?.supplier ? entry.supplier.name : (entry?.supplierCode ? entry.supplierName : null)}}</span>
                        </div>
                    </div>                    
                    <div class="row mx-0 p-0 pt-2 my-3">
                        <div class="col-6">                            
                            {{ $t('message.DiscountsBAS.totalPrice') }}
                        </div>                        
                        <div class="col-6 text-right" >
                           <span>{{  n(this.entry.amounts) ?? 0 }}</span>
                        </div>
                    </div>
                    
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-12 mt-3">                            
                            <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="entry.note" class="input__textArea mb-0" :customerStyle="true" :pencil="true" :style="'135px;'" :max="255"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right__save row mx-0 px-0">
                <div class="col-12 text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-secondary" @click="submit(this.$constant.discount_bas_status_draft)" v-if="!this.entry.createPO">
                                <div>{{ $t('message.planCreateUpdate.button.draft') }}</div>
                            </button>
                        </div>
                        <div class="col-lg-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submit(this.$constant.discount_bas_status_no_payment)" v-if="!this.entry.createPO">
                                <div>{{ $t('message.planCreateUpdate.button.success') }}</div>
                            </button>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalDetailTicket ref="ModalDetailTicket" />
</template>

<script>
    import InputSelect from "@/components/forms/InputSelect";
    import store from "../../store";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone, numberFormat} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputTextArea from "@/components/forms/InputTextArea";
    import storage from "@/lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalDetailTicket from './ModalDetailTicket';
    import InputNumber from "@/components/forms/InputNumberCustom";    
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    import QSelect from "@/components/QSelect";    
    import InputText from '@/components/forms/InputText.vue';
    import mixins from '@/mixin';
    export default {
        name: "DiscountBasTab",
        emits: ['changeEntryBranch', 'changeStartDate', 'changeEndDate'],
        mixins: [currencyMixin, permissionMixin, mixins],
        components: {
            InputTextArea,
            InputNumberFormat,
            InputSelect,
            SelectSearch,
            ModalDetailTicket,
            InputNumber,
            AppPermission,
            QSelect,
            InputText
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            const globalConfig = store.state.GlobalConfig;
            return {
                isChange:false,
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                customers: [],
                branchName: store.state.CurrentBranch.name ?? null,
                user,
                createdByOption: globalConfig.users,
                paymentChannelCashMoney: 0,
                paymentChannelTransferMoney: 0,
                paymentChannelCardMoney: 0,
                paymentChannelCash: false,
                paymentChannelCard: false,
                paymentChannelTransfer: false,
                branches: globalConfig.branches,
                ids: [],
                listSupplier: [],
                isButtonDisabled: false
            }
        },
        async mounted() {
            document.title = this.$t('message.DiscountsBAS.title');            
            this.setDefault();
        },
        async created() {
            emitter.on('closeDiscountBas', () => {               
               this.setDefault();
            })
        },
        methods: {
            setDefault(){
                this.entry.planPurchaseDate = !this.entry.planPurchaseDate ? moment().format('DD/MM/YYYY H:mm') : moment(this.entry.planPurchaseDate).format('DD/MM/YYYY H:mm');
                this.entry.createdByName = !this.entry.createdByName ? this.user.name : this.entry.createdByName;           
            },
            addRow() {
                this.entry.details.unshift({
                    amount: 0,
                    content: null,
                    deductionLimit: null,
                });    
            },
            changeData(){                
                this.$emit('changeData', true)
            },
            async submit(status){
                this.isButtonDisabled = true;
                let data = {...this.entry};                               
                data.status = status;
                data.branchId = store.state.CurrentBranch ? store.state.CurrentBranch.id : null;
                data.name = data.planName ?? null;
                // data.id = data.originId ? data.originId : (data.id ?? null);
                if(!data.details || (data.details && data.details.length <= 0)){
                    $alert({code: 422, message: this.$t('message.DiscountsBAS.alerts.error')});
                    this.isButtonDisabled = false;
                    return;
                }
                if(data.amounts == '' ||data.amounts <= 0 || data.amount <= 0) {
                    $alert({code: 422, message: this.$t('message.DiscountsBAS.alerts.errorMount')});                    
                    this.isButtonDisabled = false;
                    return;
                }                                

                let result;
                this.entry.isUpdate = this.entry.isUpdate ? true : false;                
                if(this.entry.isUpdate){                                
                    result = await $post('/v1/discounts-bas/update', {...data});
                }else{                    
                    result = await $post('/v1/discounts-bas/create', {...data});
                }
                // Trả kết quả
                if(result && result.status && result.status.code == 200 && result.result){
                    if(this.entry.isUpdate) {
                        $alert({code: 200, message: this.$t('message.DiscountsBAS.alerts.update_discount_bas_success')});                        
                    }else {
                        $alert({code: 200, message: this.$t('message.DiscountsBAS.alerts.create_discount_bas_success')});                        
                    }
                    this.$router.replace({
                    name: 'DiscountsBasIndex'
                    });
                }
                else {
                    if(result.code === 422){
                        let errors = result.messages.errors;
                        Object.keys(errors).forEach(function(key) {
                            $alert({code: result.code, message: errors[key][0]});
                        });
                        this.isButtonDisabled = false;
                        return;
                    }else{
                        if(this.entry.isUpdate) {
                            $alert({code: 500, message: this.$t('message.DiscountsBAS.alerts.update_discount_bas_error')});
                        }else {
                            $alert({code: 500, message: this.$t('message.DiscountsBAS.alerts.create_discount_bas_error')});
                        }
                    }
                }
            },
            removeDiscountsBasDetails(index){                      
                this.entry.details.splice(index, 1);              
            },             
        },
        watch: {
            'entry.branchIDs'(value){
                if(!value?.length){
                    this.entry.details = [];                    
                }
                if(value){
                    this.$emit('changedEntryBranch', value);
                    // this.loadData();
                }
            },
            entry: {
                handler: function(dataNew) {                    
                    let totalAmount = 0;
                    let suppliers = [];
                    if (dataNew.details) {
                        this.ids = [];
                        dataNew.details.forEach(product => {                            
                            totalAmount += product?.amount ? +product.amount : 0;
                            product.productId = product.productId ? product.productId : (product.id ?? null);
                            this.ids.push(product.productId);                          
                            if(product.supplierId && !suppliers.includes(product.supplierId)){
                                suppliers.push(product.supplierId);
                            }
                        });
                       
                    }                    
                    this.entry.amount = totalAmount;
                    this.entry.amounts = totalAmount;
                },
                deep: true
            },
            modelValue: {
                handler: function(dataNew) {                    
                    if (!dataNew) return;
                    this.entry = dataNew;
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    tbody th, tbody td {
        padding: 0.75rem !important;
        text-align: center;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            overflow: hidden auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: $background-white;
        overflow: hidden;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;
            .btn-save, .btn-primary, .btn-secondary {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;
            }
            .btn-save {
                &:hover {
                    background-color: $base-color !important;
                }
            }

            .btn-primary {
                background-color: $primary-color !important;
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .transpoter-options{
        overflow: auto;
        max-height: 300px;
    }
    .transpoter-options .disable{
        cursor: not-allowed !important;
        pointer-events: all !important;
    }
    .border-active{
        border: 1px solid $base-color;
    }

    .cart-footer-info {
        padding: 10px;
        background-color: $background-white;
        margin: 5px 0px;
        border-radius: 5px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
    }

    .cart-footer-info .total-quantity {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        margin: 0 5px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        font-size: 14px;
        display: inline-block;
        padding: 0 5px;
        text-align: center;
    }

    .cart-container-content table{
        height: calc(100vh - 300px) !important;
    }
    .position-relative .fa-address-book{
        position: absolute;
        top: 35%;
        right: 0px;
    }
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .icon-un-extend, .icon-extend{
        position: absolute;
        top: calc(100vh - 100px - 60%);
        left: 5px;
    }
    .overflow-a-part{
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .overflow-all{
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
    }    

</style>
