import {createRouter, createWebHistory} from "vue-router";
import store from "../store";
import PageNotFound from "../views/errors/PageNotFound.vue";

import mainRoutes from "./main.routes";
import saleRoutes from './sale.routes';

const routes: Array<any> = [
    ...mainRoutes
    , ...saleRoutes,
    {  path: "/:catchAll(.*)", component: PageNotFound, meta: {noRequiredAuth: true} }
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        // return desired position
        const pos: any = {x: 0, y: 0}
        return pos;
    },
    routes,
});

router.beforeEach((to, from, next) => {

    if (!to.meta.noRequiredAuth) {

        if (!store.state.Auth) {
            next({name: 'Login'});
            return;
        }
    }

    next();
});

export default router;
