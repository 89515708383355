
<template>
 <AppPermission :permission="this.$permissions.roles.data?? '' ">
    <div class="container">
        <div class="row m-0 p-0">
            <div class="mainLeft pt-3">
                <div class="main-title">
                    <span>{{$t('message.common.role')}}</span>
                </div>
                <div>
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row">
                        <div class="col-8 text-title">
                            <label>{{$t('message.common.numberRecord')}}:</label>
                        </div>
                        <div class="col-4">
                            <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                            <option value="15">15</option>
                            <option value="20">20</option>
                            <option value="30">30</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                            </select>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.common.createdAt') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                    <i class="fa fa-chevron-up" id="timeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="timeBody">
                        <div class="row">
                            <div class="col-12">
                                <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.common.createdAt')" class="w-100 mb-0 custom-input"
                                            @input="doFilter" v-model="filterQuery.createdAt"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{$t('message.common.lastUpdateDay')}}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                    <i class="fa fa-chevron-up" id="timeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="timeBody">
                        <div class="row">
                            <div class="col-12">
                                <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.common.lastUpdateDay')" class="w-100 mb-0 custom-input"
                                            @input="doFilter" v-model="filterQuery.updatedAt"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainRight">
                <div class="sticky-top pt-3">
                    <div class="row m-0">
                        <div class="col-lg-5 pl-0">
                            <div class="search-box">
                                <i class="fa fa-search mr-2"></i>
                                <input type="text" class="search-input"
                                    @keydown.enter="doFilter" v-model.trim="filterQuery.keyword"
                                    :placeholder="$t('message.common.searchRole')" />
                            </div>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{$t('message.button.removeFilter')}}
                            </button>
                            <AppPermission :permission="this.$permissions.roles.create?? '' ">
                                <button class="btn btn-save" type="button" @click="createUpdateRole()">
                                    <i class="fa fa-plus mr-1"></i>
                                    {{ $t('message.common.addRole') }}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mt-2 mx-0">
                        <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                            <a href="javascript:;" @click="removeChecked()">
                                <i class="fa fa-times text-danger" aria-hidden="true"></i>
                            </a>
                        </div>
                        <div class="row mx-0 my-2 w-100" id="divTable">
                            <table class="table table-index table-striped w-100" v-show="isShow()">
                                <thead>
                                    <tr>
                                        <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                        <template v-for="field in fields" :key="field.field">
                                            <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                :class="field.class" :name="field.name" :field="field.field" :style="field.style"/>
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="entries.length > 0">
                                        <template v-for="(entry, index) in entries" :key="entry.id" >
                                            <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.roleActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                <td scope="col" class="checkbox-table">
                                                    <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                            :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                </td>
                                                <template v-for="field in fields" :key="field.field">
                                                    <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;">
                                                        <a :href="'#row_' + index" class="cell-auto text-dark tagA" @click="detailRole(entry.id, entry)">
                                                            <div v-if="field.field==='createdAt'" :title="entry.createdAt" :style="field.style" class="cell-auto" :class="field.class">
                                                                <span>{{ dt(entry.createdAt) }}</span>
                                                            </div><div v-else-if="field.field==='name'" :title="entry.name" :style="field.style" class="cell-auto description" :class="field.class">
                                                                <span>{{ entry.name }}</span>
                                                            </div>
                                                            <div v-else-if="field.field==='updatedAt'" :title="entry.updatedAt" :style="field.style" class="cell-auto" :class="field.class">
                                                                <span>{{ dt(entry.updatedAt) }}</span>
                                                            </div>
                                                            <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="cell-auto" :class="field.class"></div>
                                                        </a>
                                                    </td>
                                                </template>
                                            </tr>
                                            <tr v-if="roleActive === entry.id" :class="[ entry.id === roleActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                <td scope="col" class="checkbox-table"></td>
                                                <td :colspan="fields.length" class="pb-0">
                                                    <div class="row detail-prd-row">
                                                        <div class="col-2 mt-2">
                                                            <span class="backgroup__detail-active head-detail">
                                                                <span>
                                                                    {{ $t('message.ProductIndex.info') }}
                                                                </span>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="roleActive === entry.id">
                                                <td scope="col" class="checkbox-table border-top-0"></td>
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4">
                                                        <div class="row mt-2 m-0">
                                                            <div class="col-4">
                                                                <div class="row mt-2 py-2">
                                                                    <div class="col-3">
                                                                        {{$t('message.common.roleName')}}:
                                                                    </div>
                                                                    <div class="col-9">                                                            
                                                                        <input type="text" v-model="entry.name" readonly class="w-100 border-0 border-bottom-1">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                            </div>
                                                            <div class="col-4">  
                                                                <div class="row mt-2 py-2">
                                                                    <div class="col-3">
                                                                        {{$t('message.ProductIndex.note')}}:
                                                                    </div>
                                                                    <div class="col-9 position-relative">   
                                                                        <i class="fa fa-pencil-alt" id="fa_edit"></i>                                                         
                                                                        <input type="text" v-model="entry.note" readonly class="pl-4 w-100 border-0 border-bottom-1">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row m-0 w-100">
                                                            <div class="col-12">
                                                                <TabPermission :defaultValues="entry.permissionIds" @update:modelValue="updatePermission" :label="entry.id" :readonly="true" :showAction="true"></TabPermission>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__body-detail" v-if="roleActive === entry.id">
                                                <td scope="col" class="checkbox-table border-top-0"></td>
                                                <td :colspan="fields.length" class="border-top-0">
                                                    <div class="mt-2 pb-4">
                                                        <div class="row flex-end mt-4 pr-4" >
                                                             <AppPermission :permission="this.$permissions.roles.update?? '' ">
                                                                <button type="button" class="btn btn-save mx-1" @click="createUpdateRole(entry)">
                                                                    <i class="fa fa-check-square"></i>
                                                                    {{$t('message.ProductIndex.button.update')}}
                                                                </button>
                                                            </AppPermission>
                                                            <AppPermission :permission="this.$permissions.roles.remove?? '' ">
                                                            <button type="button" class="btn btn-danger mr-2" @click="removeRole(entry.id)">
                                                                <i class="fa fa-trash-alt mr-1"></i>
                                                                {{$t('message.ProductIndex.button.remove')}}
                                                            </button>
                                                            </AppPermission>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr class="detail-row bg-white border__footer-detail" v-if="roleActive === entry.id"></tr>
                                        </template>
                                    </template>
                                    <template v-if="entries.length == 0 && !this.loading">
                                        <tr>
                                            <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                        <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalCreateRole ref="ModalCreateRole" @inputData="load"/>
    <ModalRemoveRole ref="ModalRemoveRole" @inputData="load"/>
</AppPermission>
</template>
<script>
    import {$alert, clone, setArray, getSearchQuery, $post, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import {explode} from "@/lib/utils";
    import store from "@/store";
    import emitter from "@/lib/emitter";
    // import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import TabPermission from "@/components/TabPermission.vue";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import ModalCreateRole from "./ModalCreateRole.vue";
    import ModalRemoveRole from "./ModalRemoveRole.vue";
    
    import AppPermission from '@/components/AppPermission.vue';
    export default {
        name: "RoleIndex",
        components: {
            TableSortField, 
            Pagination, 
            // InputQuickSearch, 
            InputDatePicker,
            TabPermission,
            ModalCreateRole,
            ModalRemoveRole,
            AppPermission
        },
        data() {
            const query = getSearchQuery();
            return {
                filter: {
                    import_position_code: '',
                    original_documents_code: '',
                    original_documents_type: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    createdAt: '',
                    updatedAt: '',
                    keyword: '',
                },
                arrayChecked: [],
                loading: false,
                roleActive: null,
                noteEdit: null,
                isFilter: false,
            }
        },
        mounted() {
            document.title = this.$t('message.common.role') + ' | OmiPos';
            this.clearFilter();
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/roles/index',
                    query: {}
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [1,2];
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branches = store.state.CurrentBranch.id;
                this.roleActive = null;
                this.isFilter = false;
                await this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async sort(field, direction) {                
                this.sortData = {
                    field, direction
                };
                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;
                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/roles/data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});
                if (res.result.code !== 200 && res.status) {
                    $alert(res.status);
                    return;
                }       
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }         
                // this.paginate = res.result.data.paginate;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            detailRole(id, entry){
                if (id === this.roleActive) {
                    this.roleActive = null;
                } else {
                    this.roleActive = id;
                }
                if(entry.note){
                    this.noteEdit = entry.note;
                }
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            updatePermission(data){
                this.entries.forEach(entry => {
                    if(entry.id == data.label){
                        entry.permissionIds = data.values;
                    }
                })
            },
            // submit(entry){
                
            // },
            createUpdateRole(entry = null){
                this.$refs.ModalCreateRole.show(entry);
            },
            removeRole(id){
                this.$refs.ModalRemoveRole.show(id);
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.noteStyle {
    width: 100% !important;
    height: 80px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}

tbody {
    /* display: block;
    max-height: 600px;
    overflow: auto; */
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>