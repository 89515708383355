<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="ModalDeleteLocationDetails">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-4 head-remove">
                    <h5 class="modal-title py-2">
                        {{ $t('message.common.confirm') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4">
                    <h6><b>{{ $t('message.LocationIndex.confirmRemoveLocation') }}</b></h6>
                    <span style="font-size:14px;color:gray">
                        <i>
                            {{ $t('message.LocationIndex.noteRemoveLocation') }}
                        </i>
                    </span>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $post } from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalDeleteLocationDetails',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {            
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide(){
                this.$emit('reload', this.id);
                window.jQuery(this.$refs.modal).modal("hide");    
        },
        async deleteData() {
            const res = await $post('/v1/location-details/delete', {id: this.id}); 
            if(res && res.status.code == 200 && res.result) {
                toastr.success(this.$t('message.LocationIndex.alertRemoveSuccess'));
                this.hide();
             }
            else {
                toastr.error(this.$t('message.LocationIndex.alertRemoveError'));
                window.jQuery(this.$refs.modal).modal("hide");    
            }
            
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>