
<template>    
    <AppPermission :permission="this.$permissions.categories.data">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{ $t('message.CategoriesIndex.title') }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                            <div class="col-8 text-title">
                                <label>{{ $t('message.common.numberRecord') }}:</label>
                            </div>
                            <div class="col-4">
                                <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mainRight">
                <!-- <div class="col-md-10" id="col10"> -->
                    <div>
                        <div class="row">
                            <div class="col-5">
                            <div class="search-box">
                                <i class="fa fa-search mr-2"></i>
                                <input type="text" class="search-input"
                                    @keydown.enter="doFilter('keyword', filter.keyword)"
                                    v-model="filter.keyword" :placeholder="$t('message.CategoriesIndex.placeholder.search')"/>
                            </div>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                                <!-- Nút xóa lọc -->
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                <!-- Nút import -->
                                <button type="button" @click="showModalFileUploadCategory()" class="btn btn-save"><i class="fa fa-plus mr-1"></i>{{ $t('message.button.import.name') }}</button>
                                <!-- Nút Thêm mới -->
                                <AppPermission :permission="this.$permissions.categories.create">
                                    <button @click="showModalCategoryAdd()" class="btn  btn-save ml-1"><i class="fa fa-plus"></i> {{ $t('message.CategoriesIndex.button.add') }} </button>
                                </AppPermission>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mx-0 my-2" v-if="entries?.length > 0" id="divTable">
                            <table class="table flex-table table-striped" >
                            <thead>
                                <tr>
                                    <th scope="col" v-for="field in fields" :key="field.field" class="pl-2">
                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort" :name="field.name" :field="field.field"/>
                                    </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(entry) in entries" :key="entry.id" >
                                    <tr :class="isExistInArrayChecked(entry, this.arrayChecked) ? 'backgroup__detail border__master' : ''">
                                        <template v-for="field in fields" :key="field.field">
                                            <td class="ow-break-word pl-2" style="vertical-align:middle !important;" :style="'min-width:110px'" @click="showModalCategoryEdit(entry.id)">
                                                <div v-if="field.field==='nameLv1'" class="description" :title="entry.nameLv1">
                                                    <i v-if="entry.children && entry.children.length" :class="{
                                                        'fa':true,
                                                        'fa-plus': !isExistInArrayChecked(entry, this.arrayChecked),
                                                        'px-1':true,
                                                        'fa-minus': isExistInArrayChecked(entry, this.arrayChecked),
                                                        'text-gray':true,
                                                        }"
                                                        @click.stop="countChecked(entry.id)">
                                                    </i>
                                                    <span v-text="entry.nameLv1"></span>
                                                </div>
                                                <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style"></div>
                                            </td>
                                        </template>
                                        <th class="d-flex justify-content-end">
                                            <AppPermission :permission="this.$permissions.categories.update">
                                                <button class="btn" @click="showModalCategoryEdit(entry.id)">
                                                    <i class="fa fa-edit"></i>
                                                </button>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.categories.remove">
                                                <button class="btn text-danger" @click.stop="removeCate(entry.id)">
                                                    <i class="fa fa-trash-alt"></i>
                                                </button>
                                            </AppPermission>
                                        </th>
                                    </tr>
                                    <template v-if="isExistInArrayChecked(entry, this.arrayChecked) && entry.children?.length > 0">
                                        <template v-for="(children) in entry.children" :key="children.id">
                                            <tr :class="isExistInArrayChecked(entry, this.arrayChecked) ? 'backgroup__detail border__body-detail' : ''">
                                                <template v-for="field in fields" :key="field.field">
                                                    <td class="ow-break-word pl-2" style="vertical-align:middle !important;" :style="'min-width:110px'" @click="showModalCategoryEdit(children.id)">
                                                        <div v-if="field.field==='nameLv2' && isExistInArrayChecked(entry, this.arrayChecked)" class="description" :title="children.name">
                                                            <i v-if="children.children && children.children.length" :class="{
                                                                'fa':true,
                                                                'fa-plus': !isExistInArrayChecked(children, this.arrayChecked),
                                                                'px-1':true,
                                                                'fa-minus': isExistInArrayChecked(children, this.arrayChecked),
                                                                'text-gray':true,
                                                                }"
                                                                @click.stop="countChecked(children.id)">
                                                            </i>
                                                            <span>{{ children.name }}</span>
                                                        </div> 
                                                        <div v-else-if="field.field==='countLv2' && isExistInArrayChecked(entry, this.arrayChecked)">
                                                            <span>{{ children.children?.length ?? '' }}</span>
                                                        </div>
                                                    </td>
                                                </template>
                                                <th class="d-flex justify-content-end">
                                                    <AppPermission :permission="this.$permissions.categories.update">
                                                        <button class="btn" @click="showModalCategoryEdit(children.id)">
                                                            <i class="fa fa-edit"></i>
                                                        </button>
                                                    </AppPermission>
                                                    <!-- <button class="btn" @click="showModalCategoryEdit(children.id)">
                                                        <i class="fa fa-edit"></i>
                                                    </button> -->
                                                    <!-- <button class="btn text-danger" @click.stop="removeCate(children.id)">
                                                        <i class="fa fa-trash-alt"></i>
                                                    </button> -->
                                                      <AppPermission :permission="this.$permissions.categories.remove">
                                                        <button class="btn text-danger" @click.stop="removeCate(children.id)">
                                                            <i class="fa fa-trash-alt"></i>
                                                        </button>
                                                     </AppPermission>
                                                </th>
                                            </tr>
                                            <template v-if="isExistInArrayChecked(children, this.arrayChecked) && children.children?.length > 0">
                                                <template v-for="(child) in children.children" :key="child.id">
                                                    <tr :class="isExistInArrayChecked(children, this.arrayChecked) ? 'backgroup__detail border__body-detail' : ''">
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word pl-2" style="vertical-align:middle !important;" :style="'min-width:110px'" @click="showModalCategoryEdit(child.id)">
                                                                <div v-if="field.field==='nameLv3' && isExistInArrayChecked(children, this.arrayChecked)" class="description" :title="child.name">
                                                                    <span>{{ child.name }}</span>
                                                                </div>
                                                            </td>
                                                        </template>
                                                        <th class="d-flex justify-content-end">
                                                            <AppPermission :permission="this.$permissions.categories.update">
                                                                <button class="btn" @click="showModalCategoryEdit(child.id)">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                            </AppPermission>
                                                            <!-- <button class="btn" @click="showModalCategoryEdit(child.id)">
                                                                <i class="fa fa-edit"></i>
                                                            </button> -->
                                                            <!-- <button class="btn text-danger" @click.stop="removeCate(child.id)">
                                                                <i class="fa fa-trash-alt"></i>
                                                            </button> -->
                                                            <AppPermission :permission="this.$permissions.categories.remove">
                                                                <button class="btn text-danger" @click.stop="removeCate(child.id)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                </button>
                                                            </AppPermission>
                                                        </th>
                                                    </tr>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                    <tr class="border__footer-detail" v-if="isExistInArrayChecked(entry, this.arrayChecked)"></tr>
                                </template>
                            </tbody>
                            </table>
                        </div>
                        <div class="row ml-auto mr-auto" v-if="entries?.length <= 0" id="notFound">
                            <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalCategoryAdd ref="ModalCategoryAdd" @inputData="load()" :typeEditAdd="typeEditAdd" :isFirstModal="true"/>
        <ModalCategoryRemove ref="ModalCategoryRemove" @inputData="load()" :typeEditAdd="typeEditAdd"/>
        <ModalFileUploadCategory ref="ModalFileUploadCategory" @loadData="load()" />
        <ModalErrorMessages ref="ModalErrorMessages" :update="false"/>
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import ModalCategoryAdd from "./tabs/ModalCategoryAdd.vue";
    import ModalCategoryRemove from "./tabs/ModalCategoryRemove.vue";
    import emitter from "@/lib/emitter";
    import AppPermission from '@/components/AppPermission';    
    import ModalFileUploadCategory from './ModalFileUploadCategory.vue';        
    import ModalErrorMessages from '@/components/ModalErrorMessages.vue';
    export default {
        name: "CategoryIndex",
        components: 
        {
            TableSortField, Pagination, ModalCategoryAdd, ModalCategoryRemove,ModalFileUploadCategory,ModalErrorMessages,
            AppPermission
        },
        mounted() {
            document.title = this.$t('message.CategoriesIndex.document_title');
            this.$router.replace({
                path: '/categories/index',
                query: {}
            });
            this.paginate.currentPage = this.$route.query.page || 1;
            this.numberRecord = this.$route.query.numberRecord || "15";
            this.load()
        },
        data() {
            return {
                filter: {
                    keyword: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                numberRecord: '15',
                typeEditAdd: "ADD",
                idCategory: null,
                arrayChecked: [],
                isFilter: false,
            }
        },
        methods: {
            showModalFileUploadCategory() {
                this.$refs.ModalFileUploadCategory.show();
            },
            showModalCategoryAdd(){
                this.typeEditAdd = "ADD";
                this.idCategory = null;
                this.$refs.ModalCategoryAdd.show();
            },
            showModalCategoryEdit(id){
                this.typeEditAdd = "EDIT";
                this.idCategory = id;
                this.$refs.ModalCategoryAdd.show(id);
            },
            removeCate(id){
                this.$refs.ModalCategoryRemove.show(id);
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/categories/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.sortData).forEach(key => {
                    this.sortData[key] = '';
                });
                this.numberRecord = 15;
                this.isFilter = false;
                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                query['page'] = 1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async load() {
                emitter.emit("onLoading");
                const res = await $get('/v1/categories/data', {...this.$route.query, numberRecord: this.numberRecord});
                
                this.entries = res.result.data.data;       
                this.paginate.totalRecord = res.result.data.total;
                this.paginate.currentPage = res.result.data.current_page;
                this.paginate.lastPage = res.result.data.last_page;
                this.fields = res.result.fields;
                emitter.emit("offLoading");
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                this.isFilter = true;
                query.numberRecord = v.target.value;
                await this.$router.push({query: query});
                this.load();
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
            },
        },
    }
</script>
<style scoped>
.border__footer-detail{
    height: 2px !important;
}
</style>