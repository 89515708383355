<template>
    <ul class="pagination" v-if="totalRecord > 0">
        <li class="page-item" @click="changePage(1)"><a href="javascript:;" class="page-link" :class="this.hiddenTotalRecord ? 'bg-transparent' : ''">‹‹</a></li>
        <li class="page-item" @click="prevPage()"><a href="javascript:;" class="page-link" :class="this.hiddenTotalRecord ? 'bg-transparent' : ''">‹</a></li>
        <li v-for="page in getPages()" :key="page.key" @click="changePage(page.key)" :class="page.class"><a class="page-link"
                                                                                            href="javascript:;"
                                                                                            v-text="page.key"></a></li>
        <li class="page-item" @click="nextPage()"><a class="page-link" href="javascript:;" :class="this.hiddenTotalRecord ? 'bg-transparent' : ''">›</a></li>
        <li class="page-item" @click="changePage(value.lastPage)"><a class="page-link" href="javascript:;" :class="this.hiddenTotalRecord ? 'bg-transparent' : ''">››</a></li>
        <span class="page-item d-flex align-items-center" v-if="this.record && this.totalRecord && !this.hiddenTotalRecord">
            {{$t('message.Pagination.show')}}
            {{currentPage === 1 ? 1 : Number(currentPage -1) * Number(this.record) + 1 }} - {{this.record > this.totalRecord ? this.totalRecord : this.record * currentPage > this.totalRecord ? this.totalRecord : this.record * currentPage}}
            / {{$t('message.Pagination.total')}}
            {{this.totalRecord}} 
            {{$t('message.Pagination.record')}}
        </span>
    </ul>
</template>

<script>

    export default {
        props: ['value', 'pagechange', 'record', 'hiddenTotalRecord'],
        name: "Pagination",
        data() {
            return {
                model: this.value,
                totalRecord: this.value.totalRecord,
                currentPage: this.value.currentPage,
                lastPage: this.value.lastPage
            }
        },
        watch: {
            value: {
                handler(newValue) {  // here having access to the new and old value
                    this.model = newValue;
                    this.totalRecord = newValue.totalRecord;
                    this.currentPage = newValue.currentPage;
                    this.lastPage = newValue.lastPage;
                },
                deep: true,
            }
        },
        methods: {
            created: function () {
                this.pages = this.getPages();
            },
            getPages: function () {
                const $lastPage = this.value.lastPage;
                const $linkLimit = 7;
                const $currentPage = this.value.currentPage;

                const $halfTotalLinks = Math.floor($linkLimit / 2);
                let $from = $currentPage - $halfTotalLinks;
                let $to = $currentPage + $halfTotalLinks;
                if ($currentPage < $halfTotalLinks) {
                    $to += $halfTotalLinks - $currentPage;
                }
                if ($lastPage - $currentPage < $halfTotalLinks) {
                    $from -= $halfTotalLinks - ($lastPage - $currentPage) - 1;
                }

                const pages = [];

                for (let $i = 1; $i <= $lastPage; $i++) {
                    if ($from < $i && $i < $to) {
                        let item = {
                            key: $i,
                            class: 'page-item'
                        };
                        if($i === $currentPage){
                            item = {
                                key: $i,
                                class: 'page-item active'
                            };
                        }
                        pages.push(item);
                    }
                }

                return pages;
            },
            prevPage: function () {
                if (this.value.currentPage > 1) {
                    this.model.currentPage--;
                }

                this.$emit('change', this.value.currentPage);
            },
            nextPage: function () {

                if (this.value.currentPage < this.value.lastPage) {
                    this.model.currentPage++;
                }

                this.$emit('change', this.value.currentPage);
            },
            changePage: function (page) {
                this.model.currentPage = page;
                this.$emit('change', this.value.currentPage);
            }
        }
    }
</script>

<style scoped>

</style>
