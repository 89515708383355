<template>
  <template v-for="(item, index) in this.info" :key="item.prdId">
    <template v-if="index === 0">
      <div
        class="col-sm-12 text-center"
        v-for="(n, ind) in parseInt(item.prdQuantity)"
        :key="n">
        <template v-if="ind === 0">
          <div class="card mx-3 my-3 py-3">
            <div class="card-content text-center mx-3 my-3">
              <div class="row pl-4">
                <div class="col-1" >
                    <!-- Hiển thị giá, đơn vị -->
                    <div v-if="this.status.priceOptions != 0" id="priceShow">
                      <!-- <label
                        class="priceInfo"
                        v-if="this.status.currentOptions != 0"
                        v-text="
                          $filters.formatPrice(item.prdPrice)
                            ? $filters.formatPrice(item.prdPrice) + item.prdCurrency
                            : ''
                        ">                        
                        </label>                        
                          <label class="priceInfo" v-if="this.status.unitOptions != 0">{{
                            item.unitName ? "/" + item.unitName : ""
                          }}
                        </label> -->
                        <label
                        class="priceInfo"
                        v-text="
                          $filters.formatPrice(item.prdPrice)
                            ? $filters.formatPrice(item.prdPrice)
                            : ''
                        "></label>
                      <label
                        class="priceInfo pl-1"
                        v-if="this.status.currentOptions != 0"
                        >{{ item.prdCurrency ? item.prdCurrency : "" }}</label
                      >
                      <label
                        class="priceInfo"
                        v-if="this.status.unitOptions != 0"
                        >{{ item.unitName ? "/" + item.unitName : "" }}</label
                      >
                    </div>
                </div>
                <div class="col-11">
                  <!-- Tên chi nhánh -->
                  <div class="py-0 my-0">
                    <label
                      v-if="this.status.nameOptions != 0"
                      style="font-weight: 700">
                      {{
                        item.branchName ? item.branchName : "Omipharma"
                      }}</label
                    >
                  </div>
                  <!-- Tên sản phẩm -->
                  <div class="py-0 my-0">
                    <label> {{ item.prdName ? item.prdName : "" }}</label>
                  </div>
                  <!-- Hiển thị barcode mã sản phẩm -->
                  <BarcodeGenerator                    
                    :value="item.prdCode"
                    :width="1"
                    :height="30"
                    :fontSize="16"
                    :margin="2"
                    :fontFamily="'Arial'"
                    :font="'Arial'">
                  </BarcodeGenerator>
                   <!-- Nhóm sản phẩm -->
                  <div class="py-0 my-0">
                    <label> {{ item.categoryName ? item.categoryName : "" }}</label>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </template>
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator";

export default {
  name: "TommyPaperPreview",
  created() {},
  props: {
    info: {},
    status: {},
  },
  components: { BarcodeGenerator },
  data() {
    return {
      data: {},
    };
  },
  methods: {},
  watch: {
    info(newData) {
      this.data = newData;
    },
  },
};
</script>
<style scoped>
    #priceShow {    
      width: 150px;  
      position: absolute;
      top: 50px !important;
      left: 90% !important;
      /* transform: rotate(-90deg); */
      /* rotate */
      -moz-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      -webkit-transform: translateX(-50%) translateY(-50%) rotate(-90deg);
      transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
    }
</style>