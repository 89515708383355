<template>
    <div class="row">
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Thông tin khách hàng</h1>
            </div>
            <TabNavigation :tabs="tabs" :id="id"></TabNavigation>
        </div>
    </div>
</template>

<script>
import TabNavigation from "@/components/TabNavigation";
import {markRaw} from "vue";
import TabInfo from "@/views/customers/tabs/TabInfo";
import TabAddress from "@/views/customers/tabs/TabAddress";
import TabPointHistory from "@/views/customers/tabs/TabPointHistory";
import TabOrderHistory from "@/views/customers/tabs/TabOrderHistory";
import TabCartHistory from "@/views/customers/tabs/TabCartHistory";
import TabInvoiceHistory from "@/views/customers/tabs/TabInvoiceHistory";

export default {
    name: "CustomerForm",
    components: {
        TabNavigation,
    },
    data() {
        const tabs = [
            {id: 'TabInfo', name: 'Thông tin khách hàng', component: markRaw(TabInfo)},
            {id: 'TabAddress', name: 'Địa chỉ nhận hàng', component: markRaw(TabAddress)},
            {id: 'TabInvoiceHistory', name: 'Lịch sử bán/trả hàng', component: markRaw(TabInvoiceHistory)},
            {id: 'TabOrderHistory', name: 'Lịch sử đơn hàng', component: markRaw(TabOrderHistory)},
            {id: 'TabCartHistory', name: 'Lịch sử giỏ hàng', component: markRaw(TabCartHistory)},
            {id: 'TabPointHistory', name: 'Lịch sử tích điểm', component: markRaw(TabPointHistory)},
        ];

        return {
            errors: {},
            tabs: tabs,
            id: this.$route.query.id || null
        }
    },
    mounted() {
        document.title = 'Chỉnh sửa khách hàng | OmiPos';

    },
    methods: {

    }
}
</script>

<style scoped>

</style>
