import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules/modules';
// import createPersistedState from 'vuex-persistedstate';

// Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        isCallingAPI: false,
    },
    getters: {
        isCallingAPI: state => state.isCallingAPI,
    },
    mutations: {
        SET_IS_CALLING_API: (state, payload) => {
        state.isCallingAPI = payload;
        },
    },
    actions: {
        setIsCallingAPI: ({ commit }, payload) => {
        commit('SET_IS_CALLING_API', payload);
        },
    },
    modules,
    // plugins: [createPersistedState({
    //     paths: ['userStore', 'cartStore', 'orderStore'],
    //     key: 'ec_data',
    // })],
});
