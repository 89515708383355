<template>
    <div class="search-box position-relative" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input :readOnly="readOnly" type="text" class="search-input" :placeholder="$t('message.common.searchPO')"
               v-model.trim="keyword" @input="onSearchImportSupplier"/>
        <div class="popup-product-search" v-if="keyword">
            <ul class="wrap-products" v-if="imports.length">
                <li v-for="item in imports" :key="item.id" @click="onSelect(item)">
                    <div class="product-info d-flex justify-content-between">
                        <p class="name-item">
                            <span>{{ item.code }}</span>
                        </p>
                        <p>
                            <span class="price-label">{{$t('message.common.purchaseDate')}}: </span>
                            <span class="price-value">{{ item.importDate }}</span>
                        </p>
                    </div>
                </li>
            </ul>
            <div v-else class="products-not-found">
                <span>
                    {{$t('message.common.notFound')}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {$alert, $get} from "@/lib/utils";
    import store from "@/store";

    export default {
        name: "ImportForSupplierSearch",
        emits: ['select'],
        props: ['readOnly'],
        data() {
            return {
                imports: [],
                keyword: '',
            }
        },
        methods: {
            async onSearchImportSupplier() {
                if (this.keyword) {
                    const res = await $get('/v1/order-suppliers/get-imports-supplier', {
                        keyword: this.keyword,
                        branchId: store.state.CurrentBranch.id,
                    });
                    if (res.status.code == 200) {
                        this.imports = res.result;
                    } else {
                        $alert(res);
                    }
                }
            },
            onSelect(product) {
                product.quantity = 1;
                this.$emit('select', product);
                this.imports.length = 0;
                this.keyword = '';
                // localStorage.setItem('details', product);
            },
            hidePopup() {
                this.keyword = '';
                this.imports.length = 0;
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;

        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-products {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .products-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
</style>
