
<template>
    <AppPermission :permission="this.$permissions.settlementAdvance.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{ $t('message.SettlementAdvanceIndex.title') }}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for="">{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t('message.SettlementAdvanceIndex.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branchId" 
                                    :modelValue="filterQuery.branchId" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.SettlementAdvanceIndex.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.SettlementAdvanceIndex.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branchId'"/> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.SettlementAdvanceIndex.time')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.SettlementAdvanceIndex.created_by')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                        <i class="fa fa-chevron-up" id="creatorUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.SettlementAdvanceIndex.placeholder.created_by')" :modelValue="filterQuery.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.SettlementAdvanceIndex.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.SettlementAdvanceIndex.placeholder.search')" @filterSearch="changeQuickSearch" :settlementAdvanceSearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter mr-1"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle mr-1" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>
                                        {{ $t('message.button.action') }}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.button.sendMailText') }}
                                        </a>
                                        <a class="dropdown-item" @click="sendZalo()">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                        </a>
                                    </div>
                                </div>
                                <AppPermission :permission="this.$permissions.settlementAdvance.create?? '' ">
                                    <button class="btn btn-save ml-1" @click="create()">
                                        <i class="fa fa-plus px-1"></i>
                                        {{ $t('message.SettlementAdvanceIndex.button.add') }}
                                    </button>
                                </AppPermission>
                                <AppPermission :permission="this.$permissions.settlementAdvance.export?? '' ">
                                <button class="btn btn-save ml-1" @click="exportSettlementAdvances()">
                                    <i class="fas fa-file-export px-1"></i>
                                    {{$t('message.SettlementAdvanceIndex.button.export')}}
                                </button>
                                </AppPermission>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.requestRefundActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="showDetail(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="requestRefundActive === entry.id" :class="[ entry.id === requestRefundActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2" @click="showContent('info')">
                                                                    <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                        {{$t('message.SettlementAdvanceIndex.tabs.info')}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiết phiếu-->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="requestRefundActive === entry.id">
                                                        <td class="border-top-0"></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4 detail__user">
                                                                <div class="row mt-2 detail-prd-row">
                                                                    <div class="col-4">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t('message.SettlementAdvanceIndex.code') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">                                                            
                                                                                <b>{{ entry.code }}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{ $t('message.SettlementAdvanceIndex.created_at') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word lableOS pr-0"> 
                                                                                {{ dt(entry.createdAt) }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{ $t('message.SettlementAdvanceIndex.status') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{ entry.status }}
                                                                            </div>
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="mx-1">
                                                                            <div class="col-12">
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.SettlementAdvanceIndex.branch') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        {{ entry.branchName }}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 lableOS">
                                                                                        {{ $t('message.SettlementAdvanceIndex.created_by') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                        {{ entry.createdBy }}
                                                                                        <!-- <SelectSearch :placeholder="$t('message.PlanIndex.chooseCreatedBy')" :modelValue="entry.createdByOrigin" :defaultOption="this.createdBy.all" @update:modelValue="changeCreatedBy" :readonly="true" :label="'createdBy_' + entry.id"></SelectSearch> -->
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4" id="noteRO">  
                                                                        <div class="row p-0 font-italic">
                                                                            <!-- <div class="col-12 text-gray">
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                <span class="noteStyle px-4 text-gray font-italic">
                                                                                    {{ entry.description ? entry.description : $t('message.ProductIndex.note')}}
                                                                                </span>
                                                                            </div> -->
                                                                            <div class="col-12" v-if="entry.statusOrigin == $constant.STATUS_TEMPORARY || entry.statusOrigin == $constant.STATUS_PROCESSING">
                                                                                <textarea v-model.trim="entry.description" :id="'note_'+ entry.id" :placeholder="$t('message.SettlementAdvanceIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                            </div>
                                                                            <div class="col-12 text-gray" v-else>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                <span class="noteStyle px-4 text-gray font-italic">
                                                                                    {{ entry.description ? entry.description : $t('message.SettlementAdvanceIndex.note')}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiet san pham va gia -->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="requestRefundActive === entry.id">
                                                        <td class="border-top-0"></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4 detail__user">
                                                                <div class="row mt-2 pl-2">
                                                                    <div class="col-12 pl-0">
                                                                        <table cellpadding="3" class="w-100 text-center">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td colspan="1" rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.po_code')}}
                                                                                    </strong></td>
                                                                                    <td rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.po_status')}}
                                                                                    </strong></td>
                                                                                    <td rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.payment_date')}}
                                                                                    </strong></td>
                                                                                    <td rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.supplier_code')}}
                                                                                    </strong></td>
                                                                                    <td rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.supplier_name')}}
                                                                                    </strong></td>
                                                                                    <td rowspan="2" class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.payment_method')}}
                                                                                    </strong></td>
                                                                                    <td colspan="3" rowspan="1" class="border-left text-center"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.payment_info')}}
                                                                                    </strong></td>
                                                                                    <td colspan="3" rowspan="1" class="border-left text-center"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.cash')}}
                                                                                    </strong></td>
                                                                                    <td colspan="7" rowspan="1" class="border-left text-center border-right"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.advance_info')}}
                                                                                    </strong></td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.account_number')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.account_owner')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.bank_address')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.settlement')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.advances')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.compare')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.import_number')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.invoice_number')}}
                                                                                    </strong></td>
                                                                                    <td colspan="3" class="border-left text-center"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.invoice_info')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.invoice_cash')}}
                                                                                    </strong></td>
                                                                                    <td class="border-left border-right"><strong>
                                                                                        {{$t('message.SettlementAdvanceIndex.invoice_status')}}
                                                                                    </strong></td>
                                                                                </tr>
                                                                                <template v-if="entry.details && entry.details.length">
                                                                                    <template v-for="(detail, index) in entry.details" :key="index">
                                                                                        <tr>
                                                                                            <td class="text-primary border-left border-bottom">
                                                                                                <template v-if="detail.typeSrc == this.$constant.TYPE_SRC_ORDER_SUPPLIER && detail.src">
                                                                                                    <a :href="'/order-suppliers/index?order_supplier=' + detail?.src?.code" target="_blank" class="text-decoration-none text-primary">{{ detail.src.code }}</a>
                                                                                                </template>
                                                                                                <template v-else-if="detail.typeSrc == this.$constant.TYPE_SRC_RETURN_IMPORT_SUPPLIER && detail.src">
                                                                                                    <a :href="'/purchase-orders/return?return_purchase_order=' + detail?.src?.code" target="_blank" class="text-decoration-none text-primary">{{ detail.src.code }}</a>
                                                                                                </template>
                                                                                                <template v-else-if="detail.typeSrc == this.$constant.TYPE_SRC_SETTLEMENT_ADVANCE && detail.src">
                                                                                                    <a :href="'/settlement-advance/index?code=' + detail?.src?.code" target="_blank" class="text-decoration-none text-primary">{{ detail.src.code }}</a>
                                                                                                </template>
                                                                                                <template v-else-if="detail.typeSrc == this.$constant.TYPE_SRC_DISCOUNT_BAS && detail.src">
                                                                                                    <a :href="'/settlement-advance/index?code=' + detail?.src?.code" target="_blank" class="text-decoration-none text-primary">{{ detail.src.code }}</a>
                                                                                                </template>
                                                                                            </td>
                                                                                            <td v-text="detail.src ? detail.src.statusName : null" class="border-left border-bottom"></td>
                                                                                            <td v-text="dt(detail.planPayment)" class="border-left border-bottom"></td>
                                                                                            <td class="text-primary border-left border-bottom">
                                                                                                <a :href="'/suppliers/index?keyword=' + detail?.supplier?.code" target="_blank" class="text-decoration-none text-primary">{{ detail?.supplier?.code }}</a>
                                                                                            </td>
                                                                                            <td v-text="detail?.supplier?.name" class="border-left border-bottom"></td>
                                                                                            <td v-text="detail.typePaymentName" class="border-left border-bottom"></td>
                                                                                            <td v-text="detail.accountNumber" class="border-left border-bottom"></td>
                                                                                            <td v-text="detail.accountOwner" class="border-left border-bottom"></td>
                                                                                            <td v-text="detail.bankBranch" class="border-left border-bottom"></td>
                                                                                            <td v-text="n(detail.advance)" class="border-left border-bottom"></td>
                                                                                            <td v-text="n(detail.settlement)" class="border-left border-bottom"></td>
                                                                                            <td v-text="n((detail.settlement) - (detail.advance ?? 0))" class="border-left border-bottom"></td>
                                                                                            <td class="border-left border-bottom">
                                                                                                <template v-if="detail.importSupplierCode && detail.importSupplierCode.length">
                                                                                                    <template v-for="(importSupplier, importKey) in detail.importSupplierCode" :key="importKey">
                                                                                                        <a href="javascript:void(0);" class="text-primary text-decoration-none d-block" v-text="importSupplier" @click="showDetailImportSupplier(importSupplier)"></a>
                                                                                                    </template>
                                                                                                </template>
                                                                                            </td>
                                                                                            <td v-text="statusInvoiceSupplier(detail.statusHasInvoice)" class="border-left border-bottom"></td>
                                                                                            <td colspan="3" rowspan="1" class="p-0 border-left border-bottom">
                                                                                                <table class="h-100 w-100 p-0 m-0" v-if="detail.src">
                                                                                                    <tr v-for="(info, infoIndex) in detail.src.invoice_suppliers" :key="infoIndex">
                                                                                                        <td scope="col" class="cell-auto border-right px-2" :class="infoIndex == 0 ? 'border-top-0' : ''" v-text="info.code"></td>
                                                                                                        <td scope="col" class="cell-auto border-right px-2" :class="infoIndex == 0 ? 'border-top-0' : ''" v-text="dt(info.billDate)"></td>
                                                                                                        <td scope="col" class="cell-auto border-right px-2" :class="infoIndex == 0 ? 'border-top-0' : ''" v-text="+info.amountForInvoice ? n(+info.amountForInvoice) : null"></td>
                                                                                                    </tr>
                                                                                                </table>
                                                                                            </td>
                                                                                            <td v-text="detail.totalAmountForInvoice ? n(detail.totalAmountForInvoice) : null" class="border-bottom"></td>
                                                                                            <td v-text="detail.statusInvoiceName" class="border-left border-bottom border-right"></td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-if="(!entry.details || !entry.details.length)">
                                                                                    <tr>
                                                                                        <td :colspan="19" class="text-center p-4">{{$t('message.common.notData')}}</td>                            
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                                <div class="row detail-prd-row flex-end mt-4 pr-4">
                                                                    <AppPermission :permission="this.$permissions.settlementAdvance.print?? '' ">
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print" aria-hidden="true"></i>
                                                                            {{$t('message.SettlementAdvanceIndex.button.print')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                     <AppPermission :permission="this.$permissions.settlementAdvance.update?? '' ">
                                                                        <button v-if="entry.statusOrigin == $constant.STATUS_TEMPORARY || entry.statusOrigin == $constant.STATUS_PROCESSING" type="button" class="btn btn-save mx-1" @click="quickSave(entry)">
                                                                            <i class="fa fa-save"></i>
                                                                            {{$t('message.SettlementAdvanceIndex.button.save')}}
                                                                        </button>
                                                                        <button v-if="entry.statusOrigin == $constant.STATUS_TEMPORARY || entry.statusOrigin == $constant.STATUS_PROCESSING" type="button" class="btn btn-save text-white mx-1" @click="updateTicket(entry.id)">
                                                                            <i class="fa fa-share" aria-hidden="true"></i>
                                                                            {{$t('message.SettlementAdvanceIndex.button.update')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                    <AppPermission :permission="this.$permissions.settlementAdvance.cancel?? '' ">
                                                                        <button v-if="entry.statusOrigin == $constant.STATUS_TEMPORARY || entry.statusOrigin == $constant.STATUS_PROCESSING || entry.statusOrigin == $constant.STATUS_COMPLETED" type="button" class="btn btn-danger mx-1" @click="cancelTicket(entry.id, entry.code)">
                                                                            <i class="fa fa-times"></i>
                                                                            {{$t('message.SettlementAdvanceIndex.button.cancel')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="requestRefundActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @updateContent="updateContent"/>
        <OrderPrint ref="OrderPrint" :editorDataClone="this.editorDataClone"/>
        <ModalConfirm ref="ModalConfirm" @confirm="confirmCancel"  :title="$t('message.common.confirm')" :content="this.contentCancel"/>
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </AppPermission>
</template>

<script>
    import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import http from '@/services/api';
    import TableSortField from "@/components/TableSortField";
    import CreateReturn from "@/views/returns/CreateReturn";
    import emitter from "@/lib/emitter";
    import store from "../../store";
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import router from "@/router/index";
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import SelectField from "../../components/SelectField";
    import printTransactionMixin from "@/printTransactionMixin";
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
    import OrderPrint from '../orders/OrderPrint';    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalConfirm from '@/components/ModalConfirm.vue';
    import exportMixin from '@/exportMixin';
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
    import { mapGetters } from "vuex";
    import QSelect from "@/components/QSelect";
    export default {
        name: "SettlementAdvanceIndex",
        mixins: [printTransactionMixin, exportMixin],
        components: {
            CreateReturn, 
            TableSortField, 
            Pagination, 
            SelectSearch, 
            SelectDateTime, 
            InputQuickSearch, 
            InputSelectSearch, 
            ModalAddInfoSaleChannels, 
            SelectField,
            ModalSelectPrintTemplate,
            OrderPrint,AppPermission,
            ModalConfirm,
            ModalSelectSendMailAndZaloTemplate,
            ModalSelectSendZaloGroupTemplate,
            QSelect
        },
        mounted() {
            document.title = this.$t('message.SettlementAdvanceIndex.document_title');
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['code'] = query['code'];
                this.loadDetail();
            }else{
                this.clearFilter();
            }
        },
        data() {
            const query = getSearchQuery();            
            const globalConfig = store.state.GlobalConfig;
            return {
                exEntries:[],
                filter: {
                    product_code_name: '',
                    code_name_phone: '',
                    note: '',
                    note_product: '',
                    delivery_code: '',
                    code: '',
                    code_invoice: '',
                    settlement_advance_code: '',
                    order_supplier: '',
                    return_purchase_order: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth ? store.state.Auth.user : {},
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    type: [],
                    branchId: [],
                    createdBy: '',
                    receiverBy: '',
                    saleChannel: '',
                    paymentMethod: '',
                    priceList: '',
                },
                currentBranchId: store.state.CurrentBranch.id,
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                receiverBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                createdBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                priceLists: [],
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                users: globalConfig.users,
                arrayChecked: [],
                loading: false,
                requestRefundActive: null,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                defaultSettlementAdvance:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                fieldInvoiceDetail : [
                    {
                        name: 'Mã hàng',
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: 'Tên hàng',
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: 'Đơn vị',
                        field: 'unitName',
                        selected: true,
                    },
                    {
                        name: 'Số lượng',
                        field: 'quantity',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'Đơn giá',
                        field: 'basePrice',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },     
                    {
                        name: 'Giảm giá',
                        field: 'discount',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'Giá bán',
                        field: 'price',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'Thành tiền',
                        field: 'subTotal',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                ],
                listStatus: [],
                listTypeReturn: [],
                receiverByEdit: null,
                headerActive: null,
                contentCancel: null,
                isFilter: false,
            }
        },
        methods: {
            async updateFilterBranch(values) {
                this.filterQuery.branchId = [...values];
                this.filterQuery.page = 1;
                this.isFilter = true;
                await this.load();
            },
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabSettlementAdvance, this.$constant.typeImportProducts, this.arrayChecked);
            },
            async sendZalo () {
                const typeSend = this.$constant.typeSendZaloGroup;
                const phone = null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabSettlementAdvance, this.$constant.typeImportProducts, this.arrayChecked, typeSend, phone);
            },
            async cancelTicket(ticketId, code) {
                this.ticketId = ticketId;
                this.contentCancel = this.$t('message.SettlementAdvanceIndex.confirmCancel', {code: code});
                this.$refs.ModalConfirm.show();
            },
            async confirmCancel() {
                const res = await $post('/v1/settlement-advance/cancel', {
                    id: this.ticketId,
                });
                if (res && res.result) {
                    $alert({code: 200, message: this.$t('message.SettlementAdvanceIndex.alertCancelSuccess')});
                    this.load()
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
                this.ticketId = null;
            },
            updateTicket(ticketId){
                this.$router.push({name: 'CreateSettlementAdvanceScreen', query: {
                    type: 'update',
                    id: ticketId,
                }})
            },
            showModalAddInfoSaleChannels(){
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            async printMultiple(ids = null) {
                this.editorDataClone = '';
                const res = await $post('/v1/returns/data', {
                    ids: ids ?? this.arrayChecked,
                });
                if (res.result && res.result.length) {
                    await this.getTemplatePrint(res.result);
                }
            },
            async print(entry) { 
                entry.typePrint = this.$constant.TypePrintSettlementAdvance;
                let dataPrint = this.dataPrint(entry);  
                this.editorDataClone = await this.renderData(dataPrint);
                this.$refs.OrderPrint.multiplePrint(this.editorDataClone);   
            },
            async getTemplatePrint(entry) {  
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabSettlementAdvance'});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){
                this.content = data.content;
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {
                    await this.print(data.dataPrint);          
                }           
            },
            async startPrintMultiple(returns) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < returns.length; index++) {
                    let order = returns[index];
                    order.typePrint = this.$constant.TypePrintSettlementAdvance;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint);
                    if (index != returns.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.OrderPrint.multiplePrint(this.editorDataClone); 
            },

            async clearFilter() {
                await this.$router.replace({
                    path: '/settlement-advance/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [0];
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branchId = [this.currentBranchId];
                this.requestRefundActive = null;
                this.isFilter = false;
                await this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            showDetail(id, entry){
                if (id === this.requestRefundActive) {
                    this.requestRefundActive = null;
                } else {
                    this.requestRefundActive = id;
                }
                if(entry && entry.details){
                    entry.details.forEach(detail => {
                        let isEmptyInvoice = true;
                        let totalAmount = 0;
                        if(detail.src && detail.src.invoice_suppliers){
                            detail.src.invoice_suppliers.forEach(invoice => {
                                totalAmount += +invoice.amountForInvoice;
                                if(isEmptyInvoice && invoice.code && invoice.billDate && (+invoice.amountForInvoice)){
                                    isEmptyInvoice = false;
                                }
                            });
                        }
                        detail.totalAmountForInvoice = totalAmount;
                    });
                }
                this.headerActive = 'info';
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else if(field == 'type'){
                    if(query['type'].includes(value)){
                        query['type'] = query['type'].filter(item => item !== value)
                    }else{
                        query['type'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/settlement-advance/get-settlement-advances', {...this.filterQuery, record: this.numberRecord});
                
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }

                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branchId = [this.currentBranchId];
                this.requestRefundActive = null;
                await this.load();
                this.entries.forEach((entry) => {
                    this.showDetail(entry.id, entry);
                });
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            showContent(name) {
                this.headerActive = name;
            },
            changeCreatedBy(v){
                this.entries.forEach(entry => {
                    if(('createdBy_' + entry.id) == v.label){
                        entry.createdByOrigin = v.model;
                    }
                });
            },
            async quickSave(entry){
                const res = await $post('/v1/settlement-advance/quick-save', {
                    id: entry.id, 
                    createdBy: entry.createdByOrigin, 
                    created_at: entry.created_at,
                    description: entry.description,
                });
                if(res.result.status == true){
                    $alert({
                        code: 200,
                        message: this.$t('message.SettlementAdvanceIndex.alertUpdateSuccess', {code: entry?.code}),
                    });
                    this.load();
                }else{
                    $alert({
                        code: 403,
                        message: this.$t('message.SettlementAdvanceIndex.alertUpdateError', {code: entry?.code}),
                    });
                }
            },
            async create(){
                await this.$router.replace({
                    path: '/settlement-advance',
                    query: {
                        type: 'create',
                    }
                });
            },
            async exportSettlementAdvances() {
                emitter.emit("onLoading");                             
                const res = await http.download('/v1/settlement-advance/export', {...this.filterQuery, branchID: this.currentBranchId});
                this.returnDataExport(res);     
            },
        },
        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            }),
        },
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.detail__user table thead tr th, .detail__user table tbody tr td{
    background: #fff !important;
}

.cell-check{
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}
.cell-name{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-auto{
    width: 160px;
    min-width: 160px;
    max-width: 160px;
}
.cell-large{
    width: 480px;
    min-width: 480px;
    max-width: 480px;
}
</style>