<template>
    <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
           <div class="modal-dialog modal-lg" role="document" style="width: 700px">
               <div class="modal-content">
                   <div class="modal-header py-1 mx-2">                    
                       <h3 class="modal-title py-2">{{ this.title? this.title : '' }}</h3>
                       <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                           <span aria-hidden="true">&times;</span>
                       </button>
                   </div>
                   <div class="modal-body mx-2" >
                      {{ this.content? this.content : '' }}
                      <div class="col-12 d-flex justify-content-end">
                            <button v-if="this.type == 0" class="btn btn-danger mr-2" @click="stopOrActive()">
                                <i class="fa fa-check mr-1"></i>
                                {{$t('message.button.confirm')}}
                            </button>
                            <button v-if="this.type == 1" class="btn btn-danger mr-2" @click="stopOrActive()">
                                <i class="fa fa-check mr-1"></i>
                                {{$t('message.button.confirm')}}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                       </div>
                   </div>
           </div>
       </div>
   </div>
   </template>
   <script>
   import {$post} from "@/lib/utils";
   import toastr from 'toastr';
   import store from "@/store";
   export default {
       data () {
           return {
               id: '',
               name: '',
               status: ''
           };
       },
       name: "ModalStopAndActiveBranch",
       props: ['title', 'content', 'type'],    
       methods: {
           show (id, name, status) {            
               this.id = id;
               this.name = name,
               this.status = status
               window.jQuery(this.$refs.modal).modal('show');
           },
           async stopOrActive() {          
             const res = await $post(`/v1/branches/stop-or-active/${this.id}/${this.type}`, {branchId: store.state.CurrentBranch.id});
             toastr.success(this.$t('message.branchIndex.modal.alerts.success_update'));
             this.$emit('updated', this.id);
             window.jQuery(this.$refs.modal).modal('hide');   
           },
           async startCustomers() {  
             const res = await $post(`/v1/customers/start/${this.id}`);
             if(res.code === 200){            
               toastr.success(res.message);
               this.$emit('updated' , this.id)
               window.jQuery(this.$refs.modal).modal('hide');
               setTimeout(() => {                
                   window.location.reload();  
               }, 1000);
             }
             else {
               toastr.error(res.message);
             } 
           }
       }
   }
   </script>
   <style>
   </style>