<template>
    <div class="row mt-2">
        <div class="col-lg-8">
            <fieldset>
                <legend>{{ $t('message.common.customer') }}</legend>
            </fieldset>
            <div class="form-radio-group">
                <div class="form-radio-group__item">
                    <label>
                        <input type="radio" v-model="entry.applyForCustomerGroup" value="0"> {{$t('message.PriceListsIndex.modal.allCustomer')}}
                    </label>
                </div>
                <div class="form-radio-group__item">
                    <label>
                        <input type="radio" v-model="entry.applyForCustomerGroup" value="1"> {{$t('message.PriceListsIndex.modal.customerGroup')}}
                    </label>
                </div>
            </div>

            <hr>
            <fieldset>
                <legend>{{$t('message.common.applyBranch')}}</legend>
            </fieldset>
            <div class="form-radio-group">
                <div class="form-radio-group__item">
                    <label>
                        <input type="radio" v-model="entry.applyForBranch" value="0"> {{$t('message.common.allBraches')}}
                    </label>
                </div>
                <div class="form-radio-group__item">
                    <label>
                        <input type="radio" v-model="entry.applyForBranch" value="1"> {{ $t('message.employeeIndex.chooseBranch') }}
                    </label>
                </div>
            </div>
            <div v-if="entry.applyForBranch==1">
                <QSelect datasource="branches" v-model="entry.branchIDs" :multiple="true"/>
            </div>
        </div>

    </div>

</template>

<script>
import QSelect from "../../../components/QSelect";
export default {
    name: "PromotionTabApply",
    components: {QSelect},
    data() {
        return {
            entry: {}
        }
    }
}
</script>

