<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static" id="modalChecked">
        <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                    <div class="d-flex">
                        <h5 style="fotn" class="modal-title py-2">{{ this.id ? $t('message.modalCreateEmployee.title_update') : $t('message.modalCreateEmployee.title_create') }}
                        </h5>                        
                    </div>
                    <button type="button" class="close" aria-label="Close" @click="confirmClose()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0">                    
                    <div class="customer-tab tab-info">
                        <template v-if="!isModal">
                            <ActionBar back-url="/employee/index" @action="save()" />                            
                        </template>
                        <div class="content">
                            <div class="row">
                                <div class="col-2" id="imageAction">
                                    <span @click="removeImg()" class="fmi-close" v-if="this.entry.avatar">
                                        <i class="fa fa-times"/>
                                    </span>
                                    <QImage
                                        :src="entry.avatar && entry.avatar.url ? entry.avatar.url : '/img/default-product.593ae091.png'"
                                        :style="'width: 100%'" class="py-2 text-center" />
                                    <!-- <QImage
                                        :src="entry.avatar ? JSON.parse(entry.avatar).url : '/img/default-product.593ae091.png'"
                                        style="width: 100%;" class="py-2 text-center" /> -->
                                    <InputFileManager :multiple="false" v-model="entry.avatar" :cusStyle="true"
                                        :hidePreview="this.hidePreview"></InputFileManager>
                                </div>
                                <div class="col-5 pr-4">
                                    <!-- Start mã nhân viên -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.code') }}</b>
                                            </div>
                                            <div class="col-8 px-0">
                                                <InputText v-model="entry.code" :errors="errors.code" :placeholder="$t('message.modalCreateEmployee.placeholder.code')" />                                                
                                            </div>
                                        </div>
                                    <!-- End mã nhân viên -->
                                    <!-- Start Name -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.name') }}<label class="text-danger px-0">*</label> </b>
                                            </div>
                                            <div class="col-8 px-0">
                                                <InputText v-model="entry.name" :errors="errors.name" :placeholder="$t('message.modalCreateEmployee.placeholder.name')" />
                                            </div>
                                        </div>
                                    <!-- End name -->
                                    <!-- Start birthday -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.birth_date') }}</b>
                                            </div>
                                            <div class="col-8 px-0">                                
                                                <InputDate v-model="entry.birthDate" :errors="errors.birthDate"
                                                :placeholder="$t('message.modalCreateEmployee.placeholder.birth_date')" :dateData="true" :max="10"/>
                                                <i class="far fa-calendar fa-md" aria-hidden="true"></i>                                                
                                            </div>
                                        </div>
                                    <!-- End birthday -->
                                    <!-- Start Gender -->
                                        <div class="row mb-2">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.gender') }}</b>
                                            </div>
                                            <div class="col-4 pr-0 pt-1 px-0">
                                                <div class="form-check">
                                                    <input class="form-check-input" v-model="entry.gender" type="radio"
                                                        name="exampleRadios" id="exampleRadios1" value="1" checked>
                                                    <label class="form-check-label" for="exampleRadios1">
                                                        {{ $t('message.modalCreateEmployee.male') }}
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-4 pr-0 pt-1">
                                                <div class="form-check">
                                                    <input class="form-check-input" v-model="entry.gender" type="radio"
                                                        name="exampleRadios" id="exampleRadios2" value="0">
                                                    <label class="form-check-label" for="exampleRadios2">
                                                        {{ $t('message.modalCreateEmployee.female') }}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    <!-- End Gender -->                                   
                                   
                                     <!-- Start phone number -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.phone_number') }}</b>
                                            </div>
                                            <div class="col-8 px-0">
                                                <InputText v-model="entry.phone" :errors="errors.phone"
                                                    :placeholder="$t('message.modalCreateEmployee.placeholder.phone_number')" :max="12" :cusNumber="true" />
                                            </div>
                                        </div>
                                    <!-- End phone number -->                                  
                                     <!-- Start email -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.email') }}</b>
                                            </div>
                                            <div class="col-8 px-0">
                                                <InputText v-model="entry.email" :errors="errors.email" :placeholder="$t('message.modalCreateEmployee.placeholder.email')" />
                                            </div>
                                        </div>
                                    <!-- End email -->
                                    <!-- start Department -->
                                        <div class="row mb-3">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.department') }}<label class="text-danger px-0">*</label></b>
                                            </div>                                            
                                            <div class="col-8 px-0 ">                                                
                                                <q-select name="departmentId"  :default-options="department" :multiple="true" v-model="this.departmentId" :selectAll="true"  
                                                :placeholder="$t('message.modalCreateEmployee.placeholder.department')" ref="deparment"/>
                                                <a href="#" class="text-dark linkIcon"  @click="showModalCreateDepartment">
                                                    <i class="fa fa-plus fa-md text-secondary"></i>
                                                </a>
                                            </div>
                                        </div>
                                    <!-- End Department -->
                                    <!-- start Chức danh -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.job_title') }}<label class="text-danger px-0">*</label></b>
                                            </div>
                                            <div class="col-8 px-0">
                                                <q-select name="jobTitleId" :default-options="jobTitle" :multiple="false" v-model="entry.jobTitleId" 
                                                :placeholder="$t('message.modalCreateEmployee.placeholder.job_title')" 
                                                @click="renderJobTitleData"/>
                                                <a href="#" class="text-dark linkIcon" @click="showModalJobTitleAdd">
                                                    <i class="fa fa-plus fa-md text-secondary"></i>
                                                </a>
                                            </div>

                                        </div>
                                    <!-- End Chức danh -->
                                                             
                                </div>
                                <div class="col-5">
                                    <div class="row py-3">
                                        <div class="col-12">
                                            <label class="container-checkbox d-flex m-0" :class="entry.id && entry.isCanLogin && !this.editUserName ? 'cursor-ban' : ''">
                                                <span class="text-bold">{{ $t('message.modalCreateEmployee.can_login') }}</span>
                                                <input type="checkbox" class="chkType" :disabled="entry.id && entry.isCanLogin && !this.editUserName ? true : false" v-model="entry.isCanLogin" :checked="entry.isCanLogin ? true : false" @change="changeStatusCanLogin">
                                                <span class="checkmark-checkbox"></span>
                                            </label>
                                        </div>
                                    </div>
                                     <!-- start Chi nhánh trả lương -->
                                        <div class="row py-2">
                                            <div class="col-4 pr-0">
                                                <b>{{ $t('message.modalCreateEmployee.branch') }}<label class="text-danger px-0">*</label></b>
                                            </div>
                                            <div class="col-8">                                                
                                                <q-select name="departmentId"  :default-options="branches" :multiple="true" v-model="this.workingbranchId" 
                                                :selectAll="true"  :placeholder="$t('message.modalCreateEmployee.placeholder.branch')" ref="branches"
                                                :height="60" :width="200" :class="entry.id == this.$constant.isAdmin ? 'cursor-ban' : '' "
                                                :readonly="entry.id == this.$constant.isAdmin ? 'cursor-ban' : '' "
                                                @updateFullBranch="updateFullBranch"
                                                />
                                            </div>
                                        </div>
                                    <!-- End Chi nhánh trả lương -->         
                                    <!-- Start Người dùng hệ thống -->
                                        <div class="row pt-3" v-if="entry.isCanLogin">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.username') }}</b>
                                            </div>
                                            <div class="col-8">                                                              
                                                 <InputText v-model="entry.userName" 
                                                 :readonly="entry.id == this.$constant.isAdmin ||(this.currentUser.id && this.currentUser.id !== this.$constant.isAdmin && entry.id && this.currentUser.id !== entry.id && !this.editUserName) ? true : false "                                                 
                                                 :errors="errors.userName" 
                                                 :placeholder="$t('message.modalCreateEmployee.placeholder.username')" />                                                
                                            </div>
                                        </div>
                                    <!-- End Người dùng hệ thống -->                                   
                                 
                                    <!-- Start facebook -->
                                        <div class="row" v-if="entry.isCanLogin">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{$t('message.modalCreateEmployee.password')}}</b>
                                            </div>
                                            <div class="col-8">
                                                <form  @submit.prevent="null">
                                                    <input :type="this.type" v-model="entry.password" :errors="errors.password" 
                                                      :placeholder="$t('message.modalCreateEmployee.placeholder.password')" class="form-control" 
                                                      v-on:keypress.enter="onKeyPress" autocomplete="off">
                                                    <i class="fa fa-eye iconEye" aria-hidden="true" @click="isShowIcon" :style="!this.isShowPass ? 'display:none' : '' "></i>
                                                    <i class="fa fa-eye-slash iconEye" aria-hidden="true"  @click="isShowIcon" :style="this.isShowPass ? 'display:none' : '' "></i>
                                                </form>                                            
                                            </div>
                                        </div>
                                    <!-- End facebook -->
                                    <!-- start Địa chỉ -->
                                        <div class="row py-3" v-if="entry.isCanLogin">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.re_password') }}</b>
                                            </div>
                                            <div class="col-8">
                                                <form @submit.prevent="null">
                                                    <input :type="this.typeRepeat" v-model="this.againPassword" :errors="errors.password" :placeholder="$t('message.modalCreateEmployee.placeholder.password')" class="form-control" 
                                                     v-on:keypress.enter="onKeyPress" autocomplete="off">
                                                    <i class="fa fa-eye iconEyes" aria-hidden="true" @click="isShowIconRepeat" :style="!this.isShowRePass ? 'display:none' : '' "></i>
                                                    <i class="fa fa-eye-slash iconEyes" aria-hidden="true"  @click="isShowIconRepeat" :style="this.isShowRePass ? 'display:none' : '' "></i>
                                                </form>
                                            </div>                                        
                                        </div>                                    
                                        
                                    <!-- End facebook -->
                                        <div class="row">
                                            <div class="col-4 pr-0 empTitle">
                                                <b>{{ $t('message.modalCreateEmployee.note') }}</b>
                                            </div>
                                            <div class="col-8">
                                                <InputTextArea v-model="entry.note" :placeholder="$t('message.modalCreateEmployee.placeholder.note')" :rowNote="4"/>
                                            </div>
                                        </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                     <AppPermission :permission="this.$permissions.users.create ? this.$permissions.users.create : ''">
                        <button class="btn btn-primary" back-url="/employees/index" @click="save()">
                            <i class="fa fa-save"></i>
                            {{ $t('message.modalCreateEmployee.button.save') }}
                        </button>                    
                    </AppPermission>  

                    <!-- <AppPermission :permission="this.$permissions.users.create ? this.$permissions.users.create : ''"> -->
                        <button v-if="entry.isCanLogin" class="btn btn-primary" back-url="/employees/index" @click="save('permission')">
                            <i class="fa fa-save"></i>
                            {{ $t('message.modalCreateEmployee.button.quickSave') }}
                        </button>                    
                    <!-- </AppPermission>  -->
                    
                    
                    <button type="button" class="btn btn-secondary text-white" aria-label="Close" @click="confirmClose">
                        <i class="fa fa-ban mr-1" data-v-76ef515c=""></i>
                        <span aria-hidden="true">{{ $t('message.modalCreateEmployee.button.skip') }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :title="this.title" :content="this.content"  />
    <ModalCreateDepartment ref="ModalCreateDepartment" @updated="renderDeparmentData" :disabledDeparment="true"/>
</template>
<script>
    import InputText from "@/components/forms/InputText";
    import InputDate from "@/components/forms/InputDate.vue";    
    import {$get, $post, isEmpty, $alert} from "@/lib/utils";
    import ActionBar from "@/components/ActionBar";
    import InputLocationPicker from "@/components/forms/InputLocationPicker";
    import InputFileManager from "@/components/forms/InputFileManager";
    import QImage from "@/components/QImage";
    import InputTextArea from "@/components/forms/InputTextArea";
    import store from "../../store";
    import QSelect from "../../components/QSelect.vue";
    import ModalConfirm from "@/components/ModalConfirm.vue"
    import Datepicker from 'vue3-datepicker';
    import ModalCreateDepartment from '@/views/department/ModalCreateDepartment.vue';
    import moment from 'moment';
    import toastr from 'toastr';
    import emitter from "@/lib/emitter";
    export default {
        name: "ModalAddInfoEmployee",
        mounted() {
            // if (this.id) {
            //     this.load();
            // }             
        },        
        components: {
            InputTextArea,
            QSelect,
            QImage,        
            InputFileManager,        
            InputLocationPicker,
            InputText, ActionBar,InputDate,
            ModalConfirm,
            Datepicker,
            ModalCreateDepartment
        },
        props: {
            openModal: {
                type: Boolean,
                default: false,
            },
            action: {
                type: String,
            }
        },
        watch: {
            // 'entry.isCanLogin': function () {
            //     this.entry.password = null;
            //     this.entry.userName = null;
            //     this.againPassword = null;
            // }
        },
    
        methods: {
            isShowIcon(){
                this.isShowPass = !this.isShowPass;
                if(this.isShowPass == false){
                    this.type = 'text';
                }
                else {
                    this.type = 'password';
                }
            },
            isShowIconRepeat(){
                this.isShowRePass = !this.isShowRePass;
                    if(this.isShowRePass == false){
                this.typeRepeat = 'text';
                }
                else {
                    this.typeRepeat = 'password';
                }
            },
            async onKeyPress(){               
               await this.save();
            },
            updateFullBranch(input){
                this.isFullBranch = input;                
            },
            removeImg(){
                this.entry.avatar = null;
                this.entry.avatar.url = null;
            },
            hide() {
                this.entry =this.getEntryNull();
                this.departmentId = [];
                this.workingbranchId = [];
                this.againPassword = null;
                this.location = null;            
                window.jQuery(this.$refs.modal).modal('hide');
            },
            async show(EmployeeId) {
                this.departmentId = [];
                this.workingbranchId = [];
                this.editUserName = true;
                emitter.emit('clearOptionsQselect');
                if (EmployeeId == store.state.Auth.user.id) this.isChangePassword = true;
                else this.isChangePassword = false;
                this.currentBranchID = store.state.CurrentBranch.id;                
                this.currentBranchName = store.state.CurrentBranch.name;
                if (EmployeeId) {
                    this.id = EmployeeId;
                    await this.load();
                    this.dEntry = { ...this.entry };
                    if(this.entry && this.entry.userName){
                        this.editUserName = false;
                    }
                    this.entry.isCanLogin = this.entry.isCanLogin ? true : (this.entry.userName ? true : false);
                    window.jQuery(this.$refs.modal).modal('show');
                    this.isAdd = false;                    
                }
                else {        
                    this.isAdd = true;                 
                    this.entry = this.getEntryNull();                     
                    this.againPassword = null;
                    this.hidePreview = true;
                    window.jQuery(this.$refs.modal).modal('show');
                    this.workingbranchId = [store.state.CurrentBranch?.id];
                }
                       
            },
            async renderDeparmentData(){
                const departments = await $get('/v1/employee/departments');
                this.department = departments.result;
                
            },
            async renderJobTitleData(){
                const res = await $get('/v1/employee/job-titles');
                this.jobTitle = res.result;                
            },
            showModalCreateDepartment() {
                this.$refs.ModalCreateDepartment.show()
            },
            showModalJobTitleAdd(){
                emitter.emit('showPopupAddJobTitle', true);       
            },
            companyShow(status) {
                this.isCompany = status;
            },
            isEmpty(entry) {
                let status = true;
                if (entry.code && entry.code !== null && entry.code !== "") status = false;
                if (entry.name && entry.name !== null && entry.name !== "") status = false;
                if (entry.contactNumber && entry.contactNumber !== null && entry.contactNumber !== "") status = false;
                if (entry.contactNumber1 && entry.contactNumber1 !== null && entry.contactNumber1 !== "") status = false;
                if (entry.birthDate && entry.birthDate !== null && entry.birthDate !== "") status = false;
                if (entry.gender && entry.gender !== null && entry.gender !== "") status = false;
                if (entry.taxCode && entry.taxCode !== null && entry.taxCode !== "") status = false;
                if (entry.email && entry.email !== null && entry.email !== "") status = false;
                if (entry.facebookUrl && entry.facebookUrl !== null && entry.facebookUrl !== "") status = false;            
                if (entry.customerGroupId && entry.customerGroupId !== null && entry.customerGroupId !== "") status = false;
                if (entry.comments && entry.comments !== null && entry.comments !== "") status = false;               
                return status;
            },
            isChange(entry, dEntry) {
                let status = false;
                if (entry.code && dEntry.code && entry.code !== dEntry.code) status = true;
                if (entry.name && dEntry.name && entry.name !== dEntry.name) status = true;
                if (entry.contactNumber && dEntry.contactNumber && entry.contactNumber !== dEntry.contactNumber) status = true;
                if (entry.contactNumber1 && dEntry.contactNumber1 && entry.contactNumber1 !== dEntry.contactNumber1) status = true;
                if (entry.birthDate && dEntry.birthDate && entry.birthDate !== dEntry.birthDate) status = true;
                if (entry.gender && dEntry.gender && entry.gender !== dEntry.gender) status = true;
                if (entry.taxCode && dEntry.taxCode && entry.taxCode !== dEntry.taxCode) status = true;
                if (entry.email && dEntry.email && entry.email !== dEntry.email) status = true;
                if (entry.facebookUrl && dEntry.facebookUrl && entry.facebookUrl !== dEntry.facebookUrl) status = true;
                if (entry.name && dEntry.name && entry.name !== dEntry.name) status = true;
                if (entry.customerGroupId && dEntry.customerGroupId && entry.customerGroupId !== dEntry.customerGroupId) status = true;
                if (entry.comments && dEntry.comments && entry.comments !== dEntry.comments) status = true;
                
                return status;
            },
            confirmClose() {
                let isEmpty = this.isEmpty(this.entry);
                let cData = this.isChange(this.entry, this.dEntry);
                this.content = this.$t('message.modalCreateEmployee.confirmCancelChange');
                this.title = this.$t('message.modalCreateEmployee.confirm');
                if (this.id && this.id != "") {
                    if (cData === true) {
                        this.$refs.ModalConfirm.show();
                    }
                    else {
                        
                        window.jQuery(this.$refs.modal).modal('hide');
                    }
                }
                else {
                    if (isEmpty === false) {
                        this.$refs.ModalConfirm.show();
                    }
                    else {
                        window.jQuery(this.$refs.modal).modal('hide');
                    }
                }
                  this.$emit('updated');
            },        
        
            getAddressNull() {
                let data = {
                    addressValue: null,
                }
                return data;
            },
            getEntryNull() {
                this.id = null;
                let data = {                
                    code:null,
                    name:null,
                    phone:null,
                    email:null,                    
                    birthDate:null,
                    gender:null,
                    note:null,
                    status:null,
                    avatar:null,                    
                }
             
                return data;
            },
            async load() {            
                const res = await $get(`/v1/employee/show`, {
                    id: this.id
                });                     
                if (res.status.code === 404) {
                    location.replace('/404');
                    return;
                }            
                this.getDepartmentID(res.result);
                this.getBranchID(res.result);
                if(res.result) {                                
                    this.entry = res.result;                    
                    // this.location = res.result;
                    if(res.result.birthDate && res.result.birthDate !== null) this.entry.birthDate = moment(this.entry.birthDate).format('DD/MM/YYYY');
                    else this.entry.birthDate = null;
                }                           

            },        
            getDepartmentID(data){                
                data.user_deparment.forEach(item => {
                    this.departmentId.push(item.departmentId);
                });
            },
            getBranchID(data){                
                data.user_branch.forEach(item => {
                    this.workingbranchId.push(item.branch_id);
                });
            },
            isMessageError(errMss){    
                if(errMss.name) {
                    toastr.error(errMss.name);                
                    return;
                }
                if(errMss.code) {
                    toastr.error(errMss.code);                
                    return;
                }
                if(errMss.birthDate) {
                    toastr.error(errMss.birthDate);                
                    return;
                }
                if(errMss.idCard) {
                    toastr.error(errMss.idCard);                
                    return;
                }
                if(errMss.phone) {
                    toastr.error(errMss.phone);                
                    return;
                }
                if(errMss.email) {
                    toastr.error(errMss.email);                
                    return;
                }
                  if(errMss.userName) {
                    toastr.error(errMss.userName);                
                    return;
                }
            },
            validation(data){                  
                if(isEmpty(data.name)) {                                                         
                     toastr.error(this.$t('message.modalCreateEmployee.emptyName'));                   
                     return true;
                }
                if(isEmpty(this.departmentId)) {                    
                     toastr.error(this.$t('message.modalCreateEmployee.emptyDepartment'));
                     return true;
                }

                if(data.jobTitleId == null) {                    
                     toastr.error(this.$t('message.modalCreateEmployee.emptyJobTitle'));
                     return true;
                }
                if(this.workingbranchId == null) {                    
                     toastr.error(this.$t('message.modalCreateEmployee.emptyWorkingBranch'));
                     return true;
                }     
                if(data.isCanLogin){
                    if(!data.userName) {                                       
                        if(this.id == null && isEmpty(data.password)){
                                toastr.error(this.$t('message.modalCreateEmployee.emptyPassword'));
                                return true;
                        }
                        if(this.id == null && isEmpty(this.againPassword)){
                                toastr.error(this.$t('message.modalCreateEmployee.emptyRePassword'));
                                return true;
                        }
                        if(this.id == null && data.password.length < 8){
                                toastr.error(this.$t('message.modalCreateEmployee.regexPassword'));
                                return true;
                        }
                        if(this.id == null && this.validatePassword(data.password) == false){
                                toastr.error(this.$t('message.modalCreateEmployee.regexPassword'));
                                return true;
                        }
                        if(this.id == null && this.againPassword !== data.password){
                                toastr.error(this.$t('message.modalCreateEmployee.invalidPassword'));
                                return true;
                        }
    
                    } else {
                        if(isEmpty(data.password)){
                            toastr.error(this.$t('message.modalCreateEmployee.emptyPassword'));
                            return true;
                        }
                        if (isEmpty(this.againPassword)) {
                            toastr.error(this.$t('message.modalCreateEmployee.emptyRePassword'));
                            return true;
                        }
                        if(data.password && this.againPassword !== data.password){
                            toastr.error(this.$t('message.modalCreateEmployee.invalidPassword'));
                            return true;
                        }
                      
                        if(data.password && data.password.length < 8){
                            toastr.error(this.$t('message.modalCreateEmployee.regexPassword'));
                            return true;
                        }
                        if( data.password && this.validatePassword(data.password) == false){
                            toastr.error(this.$t('message.modalCreateEmployee.regexPassword'));
                            return true;
                        }
                    }
                }
                
            },
            validatePassword(value){
                let status = false;
                let paswd =  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,30}$/;
                if(value.match(paswd)) 
                {         
                     status = true;
                }        
                else
                {         
                     status = false;
                }       
                return status; 
            },
            
            async save(permission = null) {  
                let str = '';
                if(this.id) str = '/v1/employee/update'; // áp dụng cho update
                else str = '/v1/employee/save'; // áp dụng cho thêm mới
                let validation = this.validation(this.entry)    
                if(validation == true){
                    return;
                }
                let message = '';
                if(this.entry.id) message = this.$t('message.modalCreateEmployee.alertUpdateSuccess');
                else message = this.$t('message.modalCreateEmployee.alertCreateSuccess');
                if(this.entry?.userName) this.entry.userName = this.entry.userName.trim();
                if(this.entry?.email) this.entry.email = this.entry.email = this.entry.email.trim();
                this.entry.isFullBranch = this.isFullBranch;                
                const res = await $post(str , {
                    ...this.entry, workingbranch: this.workingbranchId, department: this.departmentId
                });  
                if(res.status.code == 200){                
                        toastr.success(message);
                        if (this.entry.isCanLogin && permission != null) {
                            this.$router.replace({name: 'PermissionIndex', query: { code: res.result.code }});
                            this.hide();
                        } else {
                            this.$emit('updated', res.result);
                            this.hide();
                        }   
                }
                else{                  
                    if(res.result !== null) {
                        // this.isMessageError(res.messages.errors);
                        if(res.code === 422){
                            let errors = res.messages.errors;
                            Object.keys(errors).forEach(function(key) {
                                $alert({code: res.code, message: errors[key][0]});
                            });
                        }
                    }
                    else {
                        toastr.error(this.$t('message.modalCreateEmployee.alertCreateError'));
                    }
                }
            },
            changeStatusCanLogin(){
                this.entry.password = null;
                this.entry.userName = null;
                this.againPassword = null;
            },
        },
        data() {
            const globalConfig = store.state.GlobalConfig;      
            return {
                isShowPass: true,
                isShowRePass: true,
                type:'password',
                typeRepeat:'password',                
                isAdd: false,
                isFullBranch: 0,
                isChangePassword: false,
                id: null,
                againPassword: '',
                isSelectAll: false,
                currentBranchID: store.state.CurrentBranch.id,
                currentBranchName: store.state.CurrentBranch.name,
                name: '',
                department: globalConfig.department,
                jobTitle: globalConfig.jobTitle,
                branches:globalConfig.branches,
                users:globalConfig.users,
                dEntry: {},
                entry: {     },
                departmentId:[],
                workingbranchId:[store.state.CurrentBranch?.id],                
                title: "",
                content: "",
                hidePreview: false,
                address: {
                    is_default: 1
                },
                errors: {
                    // apartmentName: []
                },
                errorGroups: {
                    name: []
                },
                group: {
                    name: '',
                    description: ''
                },
                shown: false,
                location: {},
                customerGroup: [],
                typeAddress: '',
                showHistory: false,
                isCompany: false,
                currentUser: store.state.Auth ? store.state.Auth.user : {},
                editUserName: true,
            }
        }
    }
</script>
<style scoped>
.action-bar {
    display: none !important;
}
.fmi-close {
    position: absolute;
    top: 0.5rem;
    right:1.5rem;
    color: #8b0000;
    cursor: pointer;
    font-size: 1.2em;
}

#imageAction {
    display: fixed;
    align-items: center;
}

.far, .linkIcon {
    position: absolute;
    right: 15px;
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.linkUser {
    position: absolute;
    right: 25px;
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.empTitle {
    display: flex;
    align-items: center;
    height: 35px !important;

}
/* #typeCard #linkIcon i {

} */
    .iconEye{
        position: absolute;
        top: 30%;
        right: 8%;
    }

    .iconEyes{
        position: absolute;
        top: 30%;
        right: 8%;
    }

</style>