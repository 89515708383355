<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h3 class="modal-title py-2">
                        {{ $t('message.customerIndex.modal.titleRemove') }}
                    </h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{ $t('message.customerIndex.modal.contentRemove', {name: this.name}) }}
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="deleteData()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.customerIndex.button.agree') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.customerIndex.button.skip') }}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemoveCustomer',
    props: {},
    data () {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData() {
          await $post(`/v1/customers/remove/${this.id}`);
          const message = this.$t('message.customerIndex.modal.alertRemoveSuccess', {name: this.name});
          toastr.success(message);
          window.jQuery(this.$refs.modal).modal('hide');
          this.$emit('updated' , this.id)
        }
    }
}
</script>
<style>
</style>