<template>
    <div class="container">
        <div class="row my-4">
            <div class="col-lg-12 text-center">                
                <!-- <h3>Bạn chưa được phân quyền</h3> -->
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotPermissionPage",
    mounted() {
        document.title = 'Không tìm thấy trang';        
    }
}
</script>

<style scoped>
    .page-loading{ display: none;}
</style>
