<template>
	<div class="container mt-0">
		<div class="row m-0 p-0">
			<div class="mainRight pr-0">				
				<div class="row">
					<div class="col-lg-2 col-md-5 py-2">
						<span class="px-2 h5"><strong>{{ $t("message.zaloGroups.zalo") }}</strong></span>
					</div>
					<div class="col-lg-8 col-md-5 py-2">
						<div class="ml-0 w-100">
							<div class="d-flex align-items-center form-control-search">
								<span class="form-control-icon"><i class="fas fa-search"></i></span>
								<input type="text" name="" class="form-control ng-valid ng-touched ng-dirty" v-model="keyword" @change="searchProducts()" 
								:placeholder="$t('message.zaloGroups.placeholder')">
								<div class="input-group-append pointer" v-if="keyword?.length > 0" @click="removeKeyword()"><i class="fas fa-times text-danger"></i></div>
							</div>
						</div>
					</div>
					<div class="col-lg-2 col-md-2 py-2 my-0 text-right">
						<button class="btn btn-save px-2" v-if="!this.statusLoginZaloOa" @click="loginZaloOa">{{ $t("message.ModalLoginZalo.button.login") }}</button>					
						<button class="btn btn-danger px-2" v-if="this.statusLoginZaloOa" @click="logoutZaloOa">{{ $t("message.zaloGroups.logOut") }}</button>					
					</div>
				</div>		
				<div class="row paginate-space my-0 py-0">
					<div class="col-lg-2 col-md-3 d-flex align-items-center">
						<span class="filter-title">{{$t("message.zaloGroups.numberRecord")}}</span>
						<InputSelectSearch :modelValue="this.numberRecord" :defaultOption="this.$constant.numberRecords"
						:noSearch="true" @update:modelValue="setRecords" :styleBorder="true"></InputSelectSearch>
					</div>
					<div class="col-lg-8 col-md-8">
						<Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
					</div>
					<div class="col-lg-2 col-md-1 my-2 text-right"></div>
				</div>		
				<div class="row">
					<div class="col-lg-2">						
					</div>
					<div class="col-lg-8">
						<div class="mapping-grid-container">
							<div class="setting-mapping-grid">
								<div class="table-content">
									<div class="table-content-head">
										<table class="table mb-0">
											<thead>
											<tr>
												<th class="">{{ $t("message.zaloGroups.name") }}</th>
											</tr>
											</thead>
										</table>
									</div>
									<div class="table-content-body">
										<table class="table mb-0">
											<tbody>
											<template v-if="this.entries?.length > 0">
												<template
												v-for="(entry, index) in this.entries"
												:key="entry.id"
												>
												<tr :class="index % 2 == 0 ? 'background__detail' : ''">
													<td class="shop-name py-2">
													<span
														class="mapping-booth-title"
														v-text="entry.name"
													></span>
													</td>
												</tr>
												</template>
											</template>
											<template v-else>
												<tr class="empty-wrapper">
												<div class="row detail-prd-row mx-0">
													<div class="col-12 text-center text-secondary mt-5">
													<img src="../../../public/img/No-record.png" />
													</div>
													<div class="col-12 text-center my-10 text-secondary mb-5">
													<span class="border-none">{{$t('message.common.notData')}}</span>
													</div>
												</div>
												</tr>
											</template>
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="col-lg-2"></div>
				</div>
			</div>
		</div>
	</div>
    <ModalLoginZaloOa ref="ModalLoginZaloOa" @reload="reload"></ModalLoginZaloOa>
    <ModalConfirmLogout ref="ModalConfirmLogout" @reload="reload"></ModalConfirmLogout>
</template>

<script>
import { $post, $alert } from "../../lib/utils";
import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
import Pagination from "@/components/Pagination";
import ModalLoginZaloOa from "@/components/zalo/ModalLoginZaloOa.vue";
import ModalConfirmLogout from "@/components/zalo/ModalConfirmLogout.vue";
import emitter from "@/lib/emitter";

export default {
	props: {},
	name: "ZaloTemplate",
	components: {
		InputSelectSearch,
		Pagination,
        ModalLoginZaloOa,
        ModalConfirmLogout
	},
	mounted() {
		document.title = this.$t('message.zaloGroups.zalo');
	},
	created() {
		this.$router.replace({
		name: "ZaloTemplate",
		query: {},
		});
        this.checkLoginZaloOa();
		this.load();
	},
	data() {
		return {
			entries: [],
			paginate: {
			currentPage: 1,
			lastPage: 1,
			totalRecord: 10,
			},
			numberRecord: 10,
			page: 1,
			arrayChecked: [],
			keyword: '',
			checkedAll: false,	 
            statusLoginZaloOa: false,
            zaloOaId: null,
		};
	},
	methods: {
		searchProducts(){
			this.page = 1;
			this.load();
		},
		async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post("/v1/zalo/get-list-zalo-groups", {
                record: this.numberRecord,
                page: this.page,
                keyword: this.keyword
            });

            if (res && res.status && res.status.code != 200) {
                $alert(res);
                return;
            }
            this.entries = res.result.data.data;
            this.paginate.totalRecord = res.result.data.total;
            this.paginate.currentPage = res.result.data.current_page;
            this.paginate.lastPage = res.result.data.last_page;
            emitter.emit("offLoading");
            this.loading = false;
		},
		pageChange(page) {
            this.page = page;
            this.load();
        },
		setRecords(records) {
            this.numberRecord = records;
            this.page = 1;
            this.load();
		},
		removeKeyword(){
			this.keyword= '';
			this.page = 1;
			this.getSyncProduct();
		},
        async checkLoginZaloOa(){
            const res = await await $post("/v1/zalo/check-login-zalo-oa");
            this.statusLoginZaloOa = res && res.result && res.result.status ? true : false;
            this.zaloOaId = res && res.result ? res.result.id : null;
        },
        loginZaloOa(){
            this.$refs.ModalLoginZaloOa.show();
        },
        logoutZaloOa(){
            this.$refs.ModalConfirmLogout.show(this.zaloOaId);
        },
        reload(){
            this.checkLoginZaloOa();
            this.load();
        }
	},
};
</script>
<style scoped>
	#number_records {
		margin-top: 11px;
	}
	.container input[type="radio"] {
		position: absolute;
		opacity: 0;
		cursor: pointer;
	}
	.text-title {
		line-height: 52px;
	}

	#typeCard {
		margin-top: -8px;
	}
	.sidebar-item {
		font-size: 1rem;
		padding-bottom: 10px;
		padding-top: 10px;
		cursor: pointer;
		border-radius: 8px;
		transition: all 0.2s ease-in-out;
	}
	.sidebar-item.active {
		color: rgb(47, 161, 161);
		background-color: #e7f7ee;
	}
	.mainRight {
		padding: 1.2rem 1rem;
	}
	.setting-mapping-wrapper {
		flex-direction: column;
		flex: 1;
		-ms-box-orient: horizontal;
		display: -moz-flex;
		display: flex;
	}
	.mapping-header {
		background-color: #f5f6f8;
		padding: 1.5rem;
		border-radius: 10px;
	}
	.filter-block:first-child {
		margin-top: 0;
	}
	.filter-block {
		margin-top: 1.6rem;
		align-items: center;
		-ms-box-orient: horizontal;
		display: -moz-flex;
		display: flex;
	}
	.filter-block .filter-title {
		margin-right: 1rem;
	}
	.filter-title {
		min-width: 170px !important;
	}
	.filter-block .filter-list-choose {
		flex: 1;
		align-items: center;
		-ms-box-orient: horizontal;
		display: -moz-flex;
		display: flex;
	}
	.btn:not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon) {
		padding: 0 calc(1.1rem + 1px);
		height: 32px;
	}
	.filter-block .filter-list-choose .btn.filter-active {
		border-color: rgb(47, 161, 161);
	}
	.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg) {
		box-shadow: none !important;
	}
	.filter-block .filter-list-choose .btn:first-child {
		margin-left: 0;
	}
	.btn.btn-outline-default {
		border: 1px solid #bebfc2;
	}
		button:not(:disabled) {
		cursor: pointer;
	}
	.btn-radius-cicrle {
		border-radius: 999px;
	}
	.btn {
		outline: none !important;
		margin: 0;
		align-items: center;
		border: none;
		-ms-box-orient: horizontal;
		display: -moz-flex;
		display: flex;
	}
	.setting-sync-option {
		min-width: 150px !important;
	}
	.filter-search-block {
		border-top: 1px solid #d7d9de;
		margin-top: 1rem;
		padding-top: 1rem;
	}
	.form-control-search {
		position: relative;
	}
	.form-control-search .form-control-icon {
		cursor: pointer;
		color: rgba(31, 32, 33, 0.52);
		transform: translateY(-50%);
		position: absolute;
		left: 8px;
		top: 50%;
	}
	.mapping-header .form-control {
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
	}
	.form-control-search input {
		border-color: #d7d9de;
		padding: 0.4rem 2rem;
	}
	.form-control {
		border-color: transparent;
		padding: 0.43rem 1.2rem;
		height: 32px;
		border-width: 1px;
		border-radius: 5px;
		transition: all 0.2s ease-in-out;
	}
	.form-control-search .input-group-append {
		color: rgba(31, 32, 33, 0.86);
		transform: translateY(-50%);
		width: 16px;
		height: 16px;
		border-radius: 999px;
		position: absolute;
		top: 55%;
		right: 8px;
	}
	.mapping-connection-filter .mapping-filter-tab {
		flex: 1;
	}
	.mapping-connection-filter,
	.mapping-connection-filter .mapping-filter-tab,
	.mapping-filter-button {
		align-items: center;
		-ms-box-orient: horizontal;
		display: -moz-flex;
		display: flex;
	}
	.toolbar-item {
		cursor: pointer;
		padding: 0.5rem 1rem;
		margin-right: 1rem;
		position: relative;
		border-radius: 25px;
		transition: all 0.2s ease-in-out;
	}
	.mapping-connection-filter .toolbar-item {
		font-weight: 600;
	}
	.toolbar-item.active,
	.toolbar-item:active,
	.toolbar-item:hover {
		color: rgb(47, 161, 161);
		background-color: #e7f7ee;
	}
	.btn-group,
	.btn-group-vertical {
		position: relative;
		display: inline-flex;
		vertical-align: middle;
	}
	.mapping-grid-container {
		position: relative;
	}

	.setting-mapping-grid {
		flex: 1;
		margin-top: 1rem;
	}
	.table-content-head {
		background-color: #f5f6f8;
		border: 1px solid #d7d9de;
		border-bottom: none !important;
		border-top-right-radius: 10px;
		border-top-left-radius: 10px;
	}
	.table-content-body {
		background-color: #fff;
		border: 1px solid #d7d9de;
		border-top: none !important;
		border-bottom-right-radius: 10px;
		border-bottom-left-radius: 10px;
		height: 366px;
		overflow: auto;
	}
	.table thead tr th,
		tbody tr td {
		border-top: none !important;
		border-bottom: none !important;
	}
	.table tbody tr td {
		border-top: 1px solid #d7d9de !important;
		border-right: 1px dashed #d7d9de;
	}
	.paginate-space {
		border: none;
		padding: 10px;
	}
	.input-checkbox {
		width: 3% !important;
		border-right: none !important;
	}
	.shop-name {
		width: 17% !important;
	}
	.shop-product,
	.omi-product {
		width: 35% !important;
	}
	.operation {
		width: 10% !important;
	}
	.logo-tmdt img {
		width: 24px;
		height: 24px;
		margin-right: 5px;
	}
	.dropdown-toggle::after {
		display: none;
	}
	.empty-wrapper {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -60%);
	}
	.btn-icon:hover {
		color: rgb(47, 161, 161);
	}
</style>
