<template > 
        <div v-show="false" id="productBarcode">
            <div class="productBarcode_wrap" >     
                <template v-for="(item,inds)  in this.products" :key="item.prdId  + '-' + inds">                          
                        <PaperRollLabelBarcode v-if="item.tempType !== 2 && item.tempType !== 6" ref="PaperRollLabelBarcode" :products="this.products" :status="this.status" :item="item"/>
                        <TommyPaperBarcode v-if="item.tempType == 2" ref="TommyPaperBarcode" :products="this.products" :status="this.status" :item="item"/>
                        <JewelryBarcode v-if="item.tempType == 6" ref="JewelryBarcode" :products="this.products" :status="this.status" :item="item"/>                        
                       
                        <!-- Ngat dong -->
                        <div v-if="item.tempType == 6 && (inds + 1) != products.length" style="page-break-before: always"></div>
                        <div v-else-if="item.tempType == 2 && inds === 12 && ((inds + 1)%item.number) == 0 && (inds + 1) != products.length" style="page-break-after: always"></div>                        
                        <div v-else-if="((inds + 1)%item.number) == 0 && (inds + 1) != products.length" style="page-break-before: always"></div>
                        
                </template>
            </div>
        </div>
</template>


<script>        
    import {cloneObject} from "../../../lib/utils";
    import emitter from '@/lib/emitter';
    import PaperRollLabelBarcode from '@/components/products/PaperRollLabelBarcode';    
    import TommyPaperBarcode from '@/components/products/TommyPaperBarcode';        
    import JewelryBarcode from '@/components/products/JewelryBarcode';   
    
    export default {
        name: "ProductBarcode",    
        components: {PaperRollLabelBarcode, TommyPaperBarcode, JewelryBarcode},   
        props: {
            status:{},
            number: {},
            width: {},
            height: {},
            tempType: {},       
        } ,
        data() {
            return {
                products:[],
                show: false,
                numbers: null,

            }
        },
        created(){            
            emitter.on('changeQuantityProductBarcode', (item) => {            
                this.setQuantityProduct(item.quantity, item.data) 
                this.products = item.data
            });
            emitter.on('loadDataProducts', (item) => {                              
                this.products = item ? item.data : null;
            })
        },
        watch: {
            products(newData){                                
                 emitter.emit('loadDataProducts', {data: newData}); 
            },
            number(newData){                
                this.numbers = newData;                
            },
            width(newData){
                this.widths = newData;
            },
            height(newData){
                this.heights = newData;
            },       
        },
        methods: {
            print(product) {                
                if (!product.quantity) {
                    product.quantity = 1;
                }
                this.products = [product];
                this.launch();
            },
            multiplePrint(products) {    
                
                if (products && products.length) {
                    const data = [];
                    products.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = 1;
                        }
                        const item = cloneObject(p);
                        for (let i = 0; i < p.prdQuantity; i++) {
                            item.number = this.number;
                            item.priceOptions = this.status.priceOptions;
                            item.currentOptions =  this.status.currentOptions;
                            item.unitOptions =  this.status.unitOptions;
                            item.nameOptions =  this.status.nameOptions;
                            item.tempType = this.tempType;
                            data.push(item);
                        }
                    });
                   
                    this.products = data;                                   
                }
                this.launch();                
            },
            setQuantityProduct(quantity, data){                   
                if (this.products && this.products.length) {
                    this.products.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = quantity;
                        }else{
                            p.prdQuantity = quantity;
                        }
                    });
                }else if(data && data.length){
                    data.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = quantity;
                        }else{
                            p.prdQuantity = quantity;
                        }
                    });
                    this.products = data;
                }
            },
            launch() {                   
                this.show = true;                
                setTimeout(() => {
                    this.$htmlToPaper('productBarcode', {
                        name: '_blank',
                        specs: [
                            'fullscreen=no',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        autoClose: true,
                        windowTitle: window.document.title,
                        styles: [
                            '/assets/css/barcode.css'
                        ]
                    }, () => {
                        this.show = false;
                    });
                }, 500);
                // emitter.emit('resetTotal');
                 
            }
        },
        mounted(){     
            
        }
    }
</script>