<template>
    <div class="row">
        <ActionBar back-url="/order-suppliers/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.id ? 'Chỉnh sửa phiếu đặt hàng nhập: ' + entry.code : 'Thêm phiếu đặt hàng nhập'"></h1>
            </div>
            <div class="row">
                <div class="col-lg-8">
                    <div class="row">
                        <div class="col-6">
                            <q-select
                                    name="AddProduct"
                                    datasource="products"
                                    v-model="searchModel"
                                    placeholder="Tìm hàng hóa"
                                    @onselected="onSelectProduct"

                            />
                        </div>
                    </div>
                    <table class="table table-index table-striped" style="margin-top: 15px">
                        <thead>
                        <tr >
                            <th></th>
                            <th width="2%">{{$t('message.common.STT')}}</th>
                            <th width="10%">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                            <th width="23%">{{$t('message.DetailOrdersCard.prodName')}}</th>
                            <th width="12%">{{$t('message.DetailOrdersCard.dvt')}}</th>
                            <th width="10%">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                            <th width="15%">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                            <th width="15%">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                            <th width="15%">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(product, index) in details" :key="product.id" >
                            <td>
                                <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                    <i class="fas fa-trash"/>
                                </a>
                            </td>
                            <td>{{ index + 1 }}</td>
                            <td v-text="product.code"></td>
                            <td v-text="product.name"></td>
                            <td class="pt-3">
                                <InputSelect
                                        option-label="unitName"
                                        option-value="id"
                                        :errors="detailErrors[index]"
                                        :defaultOption="{id: 0, unitName: 'Chọn đơn vị'}"
                                        @update:modelValue="(value) => onUnitChange(value, product)"
                                        v-model="product.productUnitId"
                                        :options="product.productUnits"/>
                            </td>
                            <td class="pt-3">
                                <InputNumber :min="1" v-model="product.quantity" @input="onPriceUpdated"/>
                            </td>
                            <td class="pt-3">
                                <InputNumberFormat v-model="product.price" @input="onPriceUpdated"/>
                            </td>
                            <td class="pt-3">
                                <a @click.prevent="showModalDiscountForm(product)">
                                    <InputNumberFormat :min="0" v-model="product.discount" @input="onPriceUpdated" :readonly="true"/>
                                </a>
                            </td>
                            <td v-text="n(product.subTotal)"></td>
                        </tr>

                        </tbody>
                    </table>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Người đặt
                                </div>
                                <div class="purchase-form__input">
                                    <QSelect name="User"
                                             v-model="entry.orderBy"
                                             datasource="users"/>
                                    <ErrorLabel :errors="errors.orderBy"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Ngày tạo
                                </div>
                                <div class="purchase-form__input">
                                    <InputDatePicker v-model="entry.createdAt" placeholder="Ngày tạo"/>
                                    <ErrorLabel :errors="errors.createdAt"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Nhà cung cấp
                                </div>
                                <div class="purchase-form__input">
                                    <q-select name="supplierId" datasource="suppliers" v-model="entry.supplierId"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Mã đặt hàng nhập
                                </div>
                                <div class="purchase-form__input">
                                    <InputText v-model="entry.code"
                                               :errors="errors.code"
                                               :placeholder="$t('message.common.autocompleteCode')"
                                              />
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Trạng thái
                                </div>
                                <div class="purchase-form__input">
                                    <InputSelect name="status" v-model="entry.status" :options="statusOptions"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Tổng tiền hàng
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.totalAmt" readonly="true"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Giảm giá
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.discount" @input="onPriceUpdated"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Cần trả nhà cung cấp
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.total" readonly="true"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Tiền nhà cung cấp trả lại
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.supplierReturn" readonly="true"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Dự kiến ngày nhập hàng
                                </div>
                                <div class="purchase-form__input">
                                    <InputDatePicker v-model="entry.expectedAt" placeholder="Ngày nhập dự kiến" :errors="errors.expectedAt"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Ghi chú
                                </div>
                                <div class="purchase-form__input">
                                    <InputTextArea v-model="entry.description" :errors="errors.description"/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ModalProductDiscount ref="modal"/>
        </div>
    </div>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement, intVal} from "@/lib/utils";
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import QSelect from "../../components/QSelect";
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumber from "../../components/forms/InputNumber";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    import ModalProductDiscount from "./ModalProductDiscount";
    import ErrorLabel from "../../components/ErrorLabel";
    import moment from "moment";
    import store from "../../store";

    export default {
        name: "OrderSupplierForm",
        components: {ActionBar, InputText, InputSwitchButton, QSelect, InputTextArea, InputSelect, InputNumberFormat, InputNumber, InputDatePicker, ModalProductDiscount, ErrorLabel},
        data() {
            return {
                isDataLoaded: false,
                searchModel: null,
                entry: {
                    status: 0,
                    createdBy: store.state.Auth.user.id,
                    orderBy: store.state.Auth.user.id,
                    createdAt: moment().format('YYYY-MM-DD'),
                    branchId: store.state.CurrentBranch.id,
                    total: 0
                },
                errors: {},
                statusOptions: [
                    {id: 0, name: 'Phiếu tạm'},
                    {id: 1, name: 'Đã xác nhận NCC'},
                ],
                detailErrors: {},
                details: []
            }
        },
        mounted() {
            document.title = 'Đặt hàng nhập | OmiPos';
            if (this.$route.query.code) {
                this.load();
            } else {
                this.isDataLoaded = true;
            }            
            if(JSON.parse(localStorage.getItem('details'))!==null) this.details = JSON.parse(localStorage.getItem('details'));
            
        },
        methods: {
            async load() {
                const res = await $get('/v1/order-suppliers/show', {
                    code: this.$route.query.code
                });

                if (res.code === 404) {

                    location.replace('/404');
                    return;
                }

                this.entry = res.data.entry;
                this.isDataLoaded = true;

                if (res.data.details.length > 0) {
                    let details = [];
                    res.data.details.forEach((product) => {
                        details.push({...product,...{
                                name: product.product?.name || '',
                                code: product.product?.code || '',
                                productUnits: product.product?.product_units || [],
                            }})
                    })
                    this.details = details
                }
            },
            async save() {
                this.errors = {};

                if (this.details.length === 0) {
                    alert('Vui lòng chọn sản phẩm để nhập');
                    return;
                }

                if (this.entry.expectedAt) {
                    let date1 = moment(this.entry.expectedAt).format("YYYY-MM-DD");
                    let date2 = moment(this.entry.createdAt).format("YYYY-MM-DD");
                    if (date1 < date2) {
                        alert('Ngày nhập dự kiến phải lớn hơn ngày tạo');
                        return;
                    }
                }

                if (!this.entry.supplierId) {
                    if (!confirm('Bạn chưa nhập nhà cung cấp, có chắc chắn muốn tạo phiếu đặt hàng nhập?')) {
                        return;
                    }
                }
                
                const res = await $post('/v1/order-suppliers/save', {
                    entry: this.entry,
                    details: this.details
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    $alert(res);
                    localStorage.removeItem('details');
                    location.replace(res.redirect);
                } else {
                    $alert(res);
                }


            },
            async onSelectProduct(product) {
               
                if (!product) {
                    return;
                }

                const priceDataRes = await $post('/v1/order-suppliers/detail-data', {
                    productId: product.id
                });                
                let price = product.capitalPrice;
                let productUnitId = product.product_units.length ? product.product_units[0].id : null;
                let basePrice = price;

                if (priceDataRes.code === 200) {
                    price = parseInt(priceDataRes.data.price);
                    productUnitId = priceDataRes.data.productUnitId;
                }

                const currentProductUnit = product.product_units.find(pu => pu.id === productUnitId);
                if (currentProductUnit) {
                    basePrice = price/currentProductUnit.conversionValue;
                }


                let checkMulti = false;
                this.details.forEach((item) => {
                    if (item.id === product.id) {
                        checkMulti = true
                    }
                });


                if (!checkMulti) {
                    this.details.push({...product,...{
                            productId: product.id,
                            productUnitId,
                            price,
                            basePrice,
                            productUnits: product.product_units,
                            discount: 0,
                            quantity: 1,
                            subTotal: 0,
                            id: null
                        }})
                }
                this.$nextTick(() => {
                    this.searchModel = null;
                });
                localStorage.setItem('details',JSON.stringify(this.details));
            },

            onUnitChange(value, product) {
                const option = product.productUnits.find(o => o.id === value);
                if (option) {
                    product.price = product.basePrice * option.conversionValue;
                }

            },
           
            showModalDiscountForm(product) {
                this.$refs.modal.show(product, async (value) => {
                    this.updateDiscountProduct(value)
                });
            },
            updateDiscountProduct(value) {
                this.details.forEach((item) => {
                    if (item.id === value.id) {
                        if (value.discountType === 1) {
                            item.discount = Math.round(item.price * value.discount /100);
                            item.discountType = 1
                        } else {
                            item.discount = value.discount;
                            item.discountType = 0
                        }
                    }
                });
                this.onPriceUpdated()
            },
            totalAmt(details){
                let totalAmt = 0;
                details.forEach(product => {
                    let totalPrice = (product.price - product.discount)*product.quantity;
                    if (totalPrice < 0) totalPrice = 0;
                    product.subTotal = totalPrice;
                    totalAmt += totalPrice;
                });                             
                return totalAmt;
            },
            total(totalAmt){
                let total = intVal(totalAmt) - intVal(this.entry.discount);
                if (total < 0) total = 0;
                return total;
            },
            onPriceUpdated1() {
                let totalAmt = 0;
                this.details.forEach(product => {
                    let totalPrice = (product.price - product.discount)*product.quantity;
                    if (totalPrice < 0) totalPrice = 0;
                    product.subTotal = totalPrice;
                    totalAmt += totalPrice;
                });
                let total = intVal(totalAmt) - intVal(this.entry.discount);
                if (total < 0) total = 0;
                this.entry.total = total;
                this.entry.totalAmt = totalAmt;
            },
            onPriceUpdated(){
                // let total = intVal(totalAmt) - intVal(this.entry.discount);
                // if (total < 0) total = 0;
                this.entry.totalAmt = this.totalAmt(this.details);
                this.entry.total = this.total(this.totalAmt(this.details));                
            },
            removeDetailProduct(product) {
                if (!confirm('Xóa sản phẩm: ' + product.name)) {
                    return;
                }
                let itemIndex = -1;
                this.details.forEach((item, index) => {
                    if (item.productId === product.productId) {
                        itemIndex = index
                    }
                });

                if (itemIndex > -1) {
                    this.details.splice(itemIndex, 1);
                }  
                //Set lại giá sau khi xoá 1 item
                this.entry.totalAmt = this.totalAmt(this.details);
                this.entry.total = this.total(this.totalAmt(this.details));   

                localStorage.removeItem('details');
                localStorage.setItem('details',JSON.stringify(this.details));
            },
        }
    }
</script>

<style>
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    /* .purchase-form .form-group {
        margin-bottom: 0 !important;
    } */

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
</style>
