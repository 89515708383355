<template>
    <div :class="wrapperClass ? wrapperClass : 'form-group'">
        <label v-if="label" v-text="label"></label>
        <div class="d-flex">
            <div class="flex-1">
                <input :readonly="!!readonly" ref="input" v-model="model" @input="onInput(true)"
                       :class="[(errors && errors.length ? 'error-input': ''), (cardTransaction ? 'border-bottom-1 text-right bg-transparent rounded-0' : ''), (settingPrice ? 'px-0 border-bottom-1 text-left bg-transparent rounded-0' : ''), (active ? 'border-bottom-focus' : '')]"
                       :min="minValue"
                       :max="this.max ? maxValue : null"
                       @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"
                       @keydown.enter="changeData"
                       class="form-control" type="text" :placeholder="placeholder || label" :id="id ? id :''">
            </div>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>
<style scoped lang="scss">
    @import "../../assets/css/vars.scss";
    .form-group{
        position: relative;
    }
    .error-label{
        position: absolute;
    }
    .border-bottom-focus{
        border-bottom: 2px solid $base-color !important;
    }
</style>
<script>
import {parseIntEx} from "../../lib/utils";
import currencyMixin from "@/currencyMixin";
export default {
    name: "InputNumberCustom",
    mixins: [currencyMixin],
    props: ['label', 'placeholder', 'modelValue', 'errors', 'min', 'max', 'readonly', 'showAdjust', 'wrapperClass', 'formatNumber', 'cardTransaction', 'settingPrice', 'active', 'id', 'isOrigin'],
    data() {
        return {
            model: parseIntEx(this.modelValue) != 0 ? parseIntEx(this.modelValue) : (this.isOrigin ? 0 : '' ),
            minValue : this.min !== undefined ? parseIntEx(this.min) : 0,
            maxValue : this.max !== undefined ? parseIntEx(this.max) : 1000000000000,
        }
    },
    watch: {
        modelValue: function (newValue) {
            // if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.model = parseIntEx(newValue);
            if(this.formatNumber && this.model){
                this.model = this.formatCurrency(this.model.toString());
            }
        },
        max: function (newValue) {
            if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.maxValue = parseIntEx(newValue)
            
        },
        min: function (newValue) {
            if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.minValue = parseIntEx(newValue)
            
        }
    },
    mounted() {
        this.formatNumberCurrency();
    },
    methods: {
        formatNumberCurrency(){
            if(this.formatNumber && this.model){
                this.model = this.formatCurrency(this.model.toString());
            }
        },
        onInput() {
            if (!this.$refs.input.value) {
                this.$emit('update:modelValue', '');
                this.$nextTick(() => {
                    this.$emit('input', '');
                })
                return;
            }
            if(this.formatNumber && this.model?.length > 0){
                this.model = this.model.replace(/,/g,'');
            }
            let value = parseIntEx(this.model);
            if (this.minValue !== false) {
                if (value <= this.minValue) {
                    value = this.minValue;
                }
                this.model = value;
            }

            if (this.maxValue !== false) {
                if (value >= this.maxValue) {
                    value = this.maxValue;
                }
                this.model = value;
            }

            this.$emit('update:modelValue', value);
            this.$nextTick(() => {
                this.$emit('input', value);
            })
        },
        changeData(){
            if (!this.$refs.input.value) {
                this.$emit('changeData', '');
                return;
            }
            if(this.formatNumber && this.model?.length > 0){
                this.model = this.model.replace(/,/g,'');
            }
            let value = parseIntEx(this.model);
            if (this.minValue !== false) {
                if (value <= this.minValue) {
                    value = this.minValue;
                }
                this.model = value;
            }

            if (this.maxValue !== false) {
                if (value >= this.maxValue) {
                    value = this.maxValue;
                }
                this.model = value;
            }

            this.$emit('changeData', value);
        }
    }
}
</script>


