<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal" data-backdrop="static">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">
                        {{$t('message.common.selectProductPromotion')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0">
                        <div class="col-12 d-flex align-items-center position-relative p-0 border-bottom-1 input-search-hover">
                            <i class="fa fa-search ml-2 text-secondary"></i>
                            <input type="text" v-model.trim="search" class="form-control border-none pl-2 h-100" autocomplete="off" :placeholder="$t('message.common.searchProductPromotion')">
                        </div>
                    </div>
                    <div class="row mt-3 mx-0 list_categories">
                        <template v-if="productUnits?.length > 0">
                            <div class="col-12 position-relative p-3 row-product-info mb-1" :class="isExistInArrayChecked(unit, this.arrayChecked) == true ? 'row-product-info_active' : ''" v-for="unit in productUnits" :key="unit.id" @click="countChecked(unit.id)">
                                <div class="row m-0">
                                    <div class="col-2">
                                        <template v-if="unit.product.image?.length > 0">
                                            <div class="product-img">
                                                <img v-if="unit.product.image" :src="unit.product.image[0]?.url" alt="" class="detail-row-img">
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div class="product-img">
                                                <img src="../../../public/img/default-product.png" alt="" class="detail-row-img">
                                            </div>
                                        </template>
                                    </div>
                                    <div class="col-7 product-info">
                                        <p class="name-item text-bold px-1 pb-1 m-0">
                                            <span v-text="unit.product.name + ' ' + unit.unitName"></span>
                                        </p>
                                        <p class="p-1 pt-0 m-0">
                                            <span class="price-label">{{ unit.product.code }}</span>
                                        </p>
                                        <p class="p-1 pt-0 m-0">
                                            <span class="price-label">
                                                {{$t('message.ProductUnitSearch.inventory')}}: {{ n(unit.product_branch_meta_has_one.inventories - unit.product_branch_meta_has_one.quantityOrder) }}
                                                <!-- | Đặt NCC: <span v-text="product.product_unit ? n(product.product_unit.product_branch_meta_has_one.quantityOrder) : null"></span> -->
                                                <!-- | KH đặt: <span v-text="product.quantityOrderSupplier ? n(product.quantityOrderSupplier) : null"></span> -->
                                            </span>
                                        </p>
                                    </div>
                                    <div class="col-2">
                                        <span class="text-bold text-omi">{{ n(unit.price) }}</span>
                                    </div>
                                    <div class="col-1 d-flex align-items-center justify-content-end p-0 m-0">
                                        <i class="fa fa-check text-omi" :class="isExistInArrayChecked(unit, this.arrayChecked) == true ? 'd-block' : 'd-none'"></i>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-white border-1 text-white mr-2 px-3 py-2" data-dismiss="modal">
                                <span class="text-omi">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-save px-3 py-2" @click="apply()">
                                <span>
                                    {{$t('message.PriceListsIndex.modal.apply')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { toDisplayString } from "vue";
    import {$post, removeVietnameseTones} from "../../lib/utils";
    import store from "../../store";
    export default {
    name: "ModalCreateCustomer",
    emits: ["created"],
    data() {
        return {
            promotions: [],
            productUnits: [], 
            productUnitsDefault: [], 
            listId: [],
            search: null,
            arrayChecked: [],
            productUnitIds: null, 
            categoriesIds: null,
            isFullCategories: null,
            listOptionChecked: [],
        };
    },
    methods: {
        show(productUnitIds, categoriesIds, isFullCategories) {
            this.clearData();
            this.productUnitIds = productUnitIds, 
            this.categoriesIds = categoriesIds,
            this.isFullCategories = isFullCategories,
            this.getProductsInPromotion(productUnitIds, categoriesIds, isFullCategories);
            window.jQuery(this.$refs.modal).modal("show");
        },
        hide(){
            window.jQuery(this.$refs.modal).modal("hide");
        },
        clearData(){
            this.promotions = [];
            this.productUnits = []; 
            this.productUnitsDefault = []; 
            this.listId = [];
            this.search = null;
            this.arrayChecked = [];
            this.listOptionChecked = [];
        },
        save() {
            this.$refs.tabCreatedCustomer.save();
        },
        apply(){
            // let productUnits = [];
            // this.productUnitsDefault.forEach((entry) => {
            //     const found = this.arrayChecked.find(id => id === entry.id);
            //     if(found){
            //         productUnits.push(entry);
            //     }
            // });
            this.$emit("onSelectProductActive", this.listOptionChecked);
            this.hide();
        },
        onSelect(product) {
            this.$emit("onSelectProductActive", product);
        },
        async getProductsInPromotion(productUnitIds, categoriesIds, isFullCategories) {
            const res = await $post('/v1/promotions/get-product-in-promotion-active', {
                productUnitIds: this.productUnitIds,
                categoriesIds: this.categoriesIds,
                isFullCategories: this.isFullCategories,
                branchId: store.state.CurrentBranch.id,
                search: this.search,
            });
            if (res.result) {
                this.productUnits = this.productUnitsDefault = res.result;
            }            
        },
        onInputFilter(){
            if(this.search?.length == 0 || !this.search){
                this.productUnits = this.productUnitsDefault;
                return;
            }
            let arr = [];
            for (let index = 0; index < this.productUnitsDefault.length; index++) {
                if (this.productUnitsDefault[index] != null && removeVietnameseTones(this.productUnitsDefault[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.search.toLowerCase())) > -1){
                    arr.push(this.productUnitsDefault[index]);
                }
            }
            this.productUnits = arr;
        },
        onCheckedAll(value) {
            if(value == true){
                this.productUnitsDefault.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.productUnitsDefault.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        removeChecked(){
            this.arrayChecked = [];
            this.listOptionChecked = [];
            this.isCheckedAll();
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.productUnitsDefault.forEach((entry) => {
                if(entry.id == id){
                    const found = this.listOptionChecked.find(option => option.id == id);
                    if(!found){
                        this.listOptionChecked.push(entry);
                    }else{
                        this.listOptionChecked.splice(this.listOptionChecked.findIndex(option => option.id == id), 1);
                    }
                }
            });
            this.isCheckedAll();
        },
        isCheckedAll(){
            let getChecked = 0;
            this.productUnitsDefault.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.productUnitsDefault.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
    },
    watch: {
        search(newValue) {
            if ((newValue && newValue.length >= 3) || newValue?.length == 0) {
                this.getProductsInPromotion();
            }else{
                return;
            }
        }
    },
    components: { toDisplayString }
}
</script>

<style scoped>
.row-product-info:hover, .row-product-info_active{
    background-color: #DEECF8;
    border-radius: 15px;
}
.input-search-hover:hover{
    border-bottom: 2px solid #2FA1A1 !important;
}
</style>
