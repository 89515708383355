<template>
    <div>
        <div class="modal" ref="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Duyệt đề xuất</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="max-height: 600px; overflow-y: auto; margin-bottom: 5px;">
                            <table class="table__mini">
                                <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Người yêu cầu</th>
                                    <th>Thông tin duyệt</th>
                                    <th>Chi tiết</th>
                                    <th>{{$t('message.common.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="e in entries" :key="e.id">
                                    <td v-text="e.id"></td>
                                    <td >
                                        <ul class="ul--normal">
                                            <li>
                                                Người yêu cầu: {{e.requestedBy}}
                                            </li>
                                            <li>
                                                Thời gian: {{dt(e.requestedAt)}}
                                            </li>
                                            <li>
                                                Lý do: {{e.reason}}
                                            </li>
                                        </ul>
                                    </td>
                                    <td >
                                        <ul class="ul--normal">
                                            <li>
                                                Người duyệt: {{e.reviewedBy}}
                                            </li>
                                            <li>
                                                Duyệt lúc: {{dt(e.reviewedAt)}}
                                            </li>
                                        </ul>
                                    </td>

                                    <td>
                                        <a href="" @click.prevent="showDetails(e)">Xem chi tiết</a>
                                    </td>
                                    <td>
                                        <span :class="e.statusClass">
                                            {{e.statusName}}
                                        </span>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <Paginate :value="paginate" @change="pageChange"/>
                    </div>

                </div>
            </div>
        </div>

        <div class="modal" ref="modalDetails" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document" style="max-width: 80vw">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Thay đổi</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div style="max-height: 600px; overflow-y: auto">
                            <table class="table__mini">
                                <thead>
                                <tr>
                                    <th style="width: 20%">Tên thay đổi</th>
                                    <th style="width: 20%">Lý Do</th>
                                    <th class="text-center">Thay đổi</th>
                                </tr>
                                </thead>
                                <tbody v-if="currentItem">
                                <tr v-for="(c,index) in currentItem.applyData" :key="index">
                                    <td v-text="c.name"></td>
                                    <td v-text="c.reason"></td>
                                    <td class="text-center">
                                        <ul class="ul--normal data-change-list" v-if="c.data && c.data.length">
                                            <template v-for="(d,dIndex) in c.data" :key="dIndex">
                                                <li class="d-flex" v-if="d.from !== d.to" >
                                                    <div class="flex-1">
                                                        {{d.name}}
                                                    </div>
                                                    <div class="flex-3">
                                                        {{d.from}}  → {{d.to}}
                                                    </div>

                                                </li>
                                            </template>

                                        </ul>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div class="modal-footer" v-if="currentItem">
                        <button type="button"
                                v-if="currentItem.status==0"
                                class="btn btn-primary" @click="doApprove(currentItem)">
                            <i class="fa fa-check"/>
                            Duyệt thay đổi</button>

                        <button type="button"
                                v-if="currentItem.status==0"
                                class="btn btn-danger"  @click="doReject(currentItem)">
                            <i class="fa fa-times-circle"/>
                            Từ chối</button>

                        <button type="button" class="btn btn-secondary" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-close"/>
                            Đóng</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Paginate from "./Paginate";
import {$alert, $get, $post} from "../lib/utils";
export default {
    name: "ModalDataChangeRequest",
    components: {Paginate},
    props: ['type'],
    data() {
        return {
            currentItem: null,
            entries: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
        }
    },
    methods: {
        showDetails(e) {
            this.currentItem = e;
            window.jQuery(this.$refs.modalDetails).modal( {
                backdrop: 'static',
                keyboard: false
            });
        },
        async doReject(e) {
            if (!confirm('Từ chối thay đổi này?')) {
                return;
            }

            const res = await $post('/v1/data-change-requests/apply', {
                id: e.id,
                action: 'reject'
            });

            $alert(res)
        },
        async doApprove(e) {
            if (!confirm('Duyệt thay đổi này?')) {
                return;
            }

            const res = await $post('/v1/data-change-requests/apply', {
                id: e.id,
                action: 'approve'
            });

            $alert(res)
        },
        async load(page) {
            const res = await $get('/v1/data-change-requests/data', {
                type: this.type,
                page: page
            });

            this.entries = res.data.entries;
            this.paginate = res.data.paginate;
        },
        pageChange(page) {
            this.load(page);
        },
        show(onHiddenCallback) {
            window.jQuery(this.$refs.modal).modal( {
                backdrop: 'static',
                keyboard: false
            }).on('hidden.bs.modal', () => {
                if (onHiddenCallback) {
                    onHiddenCallback();
                }
            });
            this.load();
        }
    }
}
</script>

<style scoped>
    .data-change-list li {
        border-bottom: 1px dotted #ccc;
    }
</style>
