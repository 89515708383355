<template>
    <div ref="root" class="form-group">
        <label v-if="label" v-text="label"></label>
        <div class="fmi-dropdown" ref="dropdown" v-if="!hideInput" :class="cusStyle && cusStyle == true ? 'text-center' : '' ">
            <input @change="fileChanged(this.type)" :accept="this.type ==='excel' ? '.xlsx, .xls, .csv' : 'image/*'" type="file" ref="uploader" 
                   style="display: none;width: 1px;height: 1px;"/>
            <button ref="button" @click="toggleTooltip()" :class="defaultButton ? 'btn btn-default' : 'btn btn-save'" class="btn-sm dropdown-toggle" type="button">
                {{ this.type ? $t('message.common.choose') + ' ' + this.type : $t('message.common.chooseImage') }}
            </button>
            <div>
                <span class="qinput-error-label" v-if="errorMessage">{{errorMessage}}</span>
            </div>
            <div class="fmi-dropdown-menu" v-if="showToolTip" ref="tooltip">
                <a class="fmi-dropdown-item" href="#" @click.prevent="chooseFile()">
                    {{ $t('message.common.uploadImage', {type: (this.type ? this.type : $t('message.common.image'))}) }}
                </a>
                <a v-if="type !== 'excel'" class="fmi-dropdown-item" href="#" @click.prevent="chooseFileFromURL()">
                    {{ $t('message.common.uploadFromUrl') }}
                </a>
                <a class="fmi-dropdown-item" href="#" @click.prevent="showModal()">
                    {{ $t('message.common.chooseFile') }}
                </a>
            </div>
        </div>

        <div class="fmi-preview" v-if="!hidePreview && files.length" >
            <!-- <ul class="fmi-preview-ul"> -->
                <div v-for="(file, index) in files" :key="file.id" class="fmi-preview-ul" :class="this.smallSize ? 'image-size-50' : ''">
                    <div v-if="file.isImage" class="image-contain">
                        <span @click="remove(file, index)" class="fmi-close">
                            <i class="fa fa-times"/>
                        </span>
                        <q-image :src="file.url"/>
                    </div>

                    <template v-else>
                        <div>
                            <a :href="file.url">
                                <i :class="getIconClass(file.extension)" ></i>
                            </a>
                        </div>
                        <div style="word-break: break-all">
                            <span v-text="file.name"></span>
                        </div>
                    </template>


                </div>
            <!-- </ul> -->
        </div>
    </div>
    <ModalManageFile ref="ModalManageFile" :showFileManager="this.showFileManager" :isMultiple="isMultiple" :group="group" :type="this.type" @fileClicked="fileClicked"></ModalManageFile>
</template>

<script>
import {$upload, arr, $post, isValidHttpUrl} from "../../lib/utils";
import FileManager from "../FileManager";
import QImage from "../QImage";
import ModalManageFile from "./ModalManageFile";
import toastr from 'toastr';
import emitter from "@/lib/emitter";


const fileExtensionIcons = {
    'xlsx': 'fa fa-file-excel',
    'xls': 'fa fa-file-excel',
    'docx': 'fa fa-file-word',
    'doc': 'fa fa-file-word',
    'pdf': 'fa fa-file-pdf'
};

export default {
    name: "InputFileManagerInsideTemplate",
    props: ['modelValue', 'multiple', 'type', 'required', 'error', 'group',
        'hidePreview', 'hideInput', 'label', 'max','cusStyle','showHide','uploadType', 'defaultButton', 'smallSize', 'zIndex'],
    data() {
        const isMultiple = this.multiple;
        let modelValue = this.modelValue;
        if (modelValue && typeof modelValue === 'string') {
            modelValue = JSON.parse(modelValue);
        }

        const files = arr(modelValue).map((v => {
            return {
                isImage: v.isImage ?? 1,
                id: v.id,
                url: v.url,
                name: v.name ?? '',
            }
        }));

        return {
            isMultiple,
            files,
            showFileManager: false,
            showToolTip: false,
            errorMessage: '',
            maxNumberImage: parseInt(this.max) || 5,
            canUpload: true
        }
    },
    mounted() {
        document.addEventListener('click', this.docClicked)
    },
    beforeUnmount() {
        document.removeEventListener('click', this.docClicked)
    },
    watch: {
        modelValue: function (newValue) {
            if (newValue) {
                let modelValue = newValue;
                if (modelValue && typeof modelValue === 'string') {
                    modelValue = JSON.parse(modelValue);
                    // if(modelValue.length > 2) return this.canUpload = false
                    // if(modelValue.length > 3) return this.files.shift()
                }
                const files = arr(modelValue).map((v => {
                    return {
                        isImage: v.isImage ?? 1,
                        id: v.id,
                        url: v.url,
                        name: v.name ?? '',
                    }
                }));
                this.files = files
                if(this.files.length > 2){
                    return this.canUpload = false
                }
            }
        },
    },
    components: {
        QImage,
        FileManager,
        ModalManageFile
    },
    methods: {
        docClicked(ev) {
            if (!this.$refs.button.contains(ev.target)) {
                this.showToolTip = false;
            }

        },
        toggleTooltip() {
            this.showToolTip = !this.showToolTip;
        },
      clearInput(){
        window.jQuery(this.$refs.modal).modal('hide');
        // emitter.emit("clearMainAll");
      },
        async chooseFileFromURL() {
            const url = prompt(this.$t('message.common.enterUrl'));
            if (url == null) {
                return;
            }

            if (!isValidHttpUrl(url)) {
                alert(this.$t('message.common.chooseUrl'));
                return;
            }


            const res = await $post('/v1/files/uploadFromURL', {
                url: url,
                group: this.group
            })

            if (res.code !== 200) {
                toastr.error(res.message);
            } else {
                toastr.success(res.message);
                if (this.isMultiple) {
                    this.files.unshift(res.file);
                } else {
                    this.files = [res.file];
                }
                this.emitInput();
            }

        },
        async fileChanged(type) { 
            const files = this.$refs.uploader.files;
            if(!this.canUpload){
                toastr.error(this.$t('message.common.maxThreeImage'));                
                return;
            }            
            switch (type) {
                case 'ảnh':
                    if (files.length > 0) {
                        const res = await $upload('/v1/files/uploadImages', files);
                        this.$refs.uploader.value = '';
                        if (res.code !== 200) {
                            toastr.error(res.message);
                        } else {
                            toastr.success(res.message);
                            if (this.isMultiple) {
                                this.files.unshift(res.file);
                            } else {
                                this.files = [res.file];
                            }
                            this.emitInput();
                        }
                    }
                    break;
            
                default:
                    if (files.length > 0) {
                        const res = await $upload('/v1/files/upload', files);
                        this.$refs.uploader.value = '';
                        if (res.code !== 200) {
                            toastr.error(res.message);
                        } else {
                            toastr.success(res.message);
                            if (this.isMultiple) {
                                this.files.unshift(res.file);
                            } else {
                                this.files = [res.file];
                            }
                            this.emitInput();
                        }
                    }
                    break;
            }
        },
        chooseFile() {
           this.$refs.uploader.click();
        },
        getIconClass(ext) {
            return 'fmi-file ' +  (fileExtensionIcons[ext] || '');
        },
        remove(file, index) {
            this.files.splice(index, 1);
            this.emitInput();
            if(this.files.length <= 2){
                this.canUpload = true
            }
        },
        validate() {
            this.emitInput();
            return !this.errorMessage;
        },
        emitInput() {
            let model, hasError = false;
            if (this.isMultiple) {
                model = this.files;
                hasError = model.length === 0;
            } else {
                model = this.files.length ? this.files[0]: null;
                hasError = model === null;
            }

            if (this.required != null) {
                if (hasError) {
                    this.errorMessage = this.error || this.$t('message.common.chooseImagePlease');
                } else {
                    this.errorMessage = '';
                }
            }

            this.$emit('update:modelValue', model);
            this.$nextTick(() => {
                this.$emit('input', model);
            })
        },
        fileClicked(file) {

            if (this.isMultiple) {
                this.files = file;
                if (this.files.length > this.maxNumberImage) {
                    alert(this.$t('message.common.maxNumberImage', {number: this.maxNumberImage}));
                    // emitter.emit("clearMainAll");
                    this.files = [];
                    // return;
                }
            } else {
                this.files = [file[0]];
            }

            this.emitInput();
            window.jQuery(this.$refs.modal).modal('hide');
            this.showFileManager = false;
        },
        showModal() {
            this.showFileManager = true;
            // window.jQuery(this.$refs.modal).modal({keyboard: false});
            // this.$nextTick(() => {
            //     this.$refs.fileManager.load();
            // })
            this.$emit('showModal', {
                showFileManager: this.showFileManager,
                isMultiple: this.isMultiple,
                group: this.group,
                type: this.type,
            })
            // this.$refs.ModalManageFile.show();
        }
    },
    created() {
        emitter.on("removeDataInputFileManager", () => {
            this.files = [];
        });
    },
}
</script>

<style scoped>
    .fmi-preview {
        margin: .5rem;
        display: flex;
        flex-wrap: wrap;
    }
    .fmi-dropdown {
        position: relative;
        margin-bottom: 5px;
    }
    .fmi-dropdown-menu {
        z-index: 1000;
        position: absolute;
        top: 32px;
        left: 0;
        min-width: 10rem;
        padding: .5rem 0;
        margin: .125rem 0 0;
        font-size: 1rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid rgba(0,0,0,.15);
        border-radius: .25rem;
    }
    .fmi-dropdown-item {
        display: block;
        width: 100%;
        padding: .25rem 1.5rem;
        clear: both;
        font-weight: 400;
        color: #212529;
        text-align: inherit;
        white-space: nowrap;
        background-color: transparent;
        border: 0;
        font-size: 13px;
    }
    .fmi-dropdown-item:hover {
        text-decoration: none;
        background-color: #008080;
        color: white;
    }
    .fmi-preview-ul {
        padding-left: 0;
        margin-left: 0;
        list-style: none;
        margin: 0.5rem
        /* width: 128px;
        height: 128px; */
        /* display: flex; */
        /* flex-wrap: wrap; */
    }
    /* .fmi-preview-ul {
        display: inline-block;
        margin-left: 5px;
        position: relative;
    } */

    .fmi-preview-ul img {
        width: 128px;
        height: 128px;
    }

    .image-size-50 img {
        width: 50px !important;
        height: 50px !important;
    }

    .image-contain {
        position: relative;
    }

    .fmi-close {
        position: absolute;
        top: -.5rem;
        right: -.5rem;
        color: #8b0000;
        cursor: pointer;
    }
    .fmi-file {
        font-size: 64px;
    }
</style>
