<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 1250px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{$t('message.common.processOrder')}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="title-search">
                                {{$t('message.InputQuickSearch.button.search')}}
                            </div>

                            <InputText v-model="filter.code"
                                       class="sale-input-number full-left"
                                       :placeholder="$t('message.InputQuickSearch.title.order_code')"
                                       @update:modelValue="search"
                            ></InputText>
                            <InputText v-model="filter.customerName"
                                       class="sale-input-number full-left"
                                       :placeholder="$t('message.customerIndex.title')"
                                       @update:modelValue="search"
                            ></InputText>
                            <InputText v-model="filter.description"
                                       class="sale-input-number full-left"
                                       :placeholder="$t('message.common.orderNote')"
                                       @update:modelValue="search"
                            ></InputText>
                            <InputText v-model="filter.productCode"
                                       class="sale-input-number full-left"
                                       :placeholder="$t('message.InputQuickSearch.placeholder.product_code')"
                                       @update:modelValue="search"
                            ></InputText>
                            <InputText v-model="filter.productName"
                                       class="sale-input-number full-left"
                                       :placeholder="$t('message.InputQuickSearch.placeholder.product_name')"
                                       @update:modelValue="search"
                            ></InputText>
                            <InputDateRangePicker v-model="filter.purchaseDate"
                                                  class="sale-input-number full-left"
                                                  :placeholder="$t('message.common.time')"
                                                  @input="search"
                            ></InputDateRangePicker>`
                        </div>
                        <div class="col-lg-9">
                            <table class="table table-index table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        {{$t('message.InputQuickSearch.title.order_code')}}
                                    </th>
                                    <th>
                                        {{$t('message.common.time')}}
                                    </th>
                                    <th>
                                        {{$t('message.customerIndex.title')}}
                                    </th>
                                    <th>
                                        {{$t('message.OrderIndex.total')}}
                                    </th>
                                    <th>
                                        {{$t('message.common.status')}}
                                    </th>
                                    <th>
                                        {{$t('message.OrderIndex.note')}}
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="order in data" :key="order.id">
                                    <td v-text="order.code"></td>
                                    <td v-text="dt(order.purchaseDate)"></td>
                                    <td v-text="order.customer ? order.customer.name : ''"></td>
                                    <td v-text="n(order.total)"></td>
                                    <td v-text="order.statusLabel"></td>
                                    <td v-text="order.description"></td>
                                    <td>
                                        <button class="btn btn-sm btn-outline-dark" @click="onSelectOrder(order)">
                                            {{ $t('message.button.choose') }}
                                        </button>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <Paginate :value="paginate" @change="load"/>
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            <i class="fa fa-ban"></i>&nbsp;
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputText from "../../components/forms/InputText";
    import {$get, debounce} from "../../lib/utils";
    import { useLoading } from 'vue3-loading-overlay';
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import Paginate from "../../components/Paginate";

    let app;

    export default {
        name: "ModalSelectOrderProcessing",
        emits: ['select'],
        components: {Paginate, InputDateRangePicker, InputText, InputNumberFormat},
        data() {
            return {
                data: [],
                filter: {
                    code: '',
                    customerName: '',
                    description: '',
                    productCode: '',
                    productName: '',
                    purchaseDate: ''
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                }
            }
        },
        mounted() {
            app = this;
        },
        methods: {
            show() {
                this.filter = {};
                this.load();

                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            },
            async load(page = 1) {
                let loader = useLoading();
                loader.show({
                    zIndex: 9999,
                    color: '#008080',
                });

                const res = await $get('/v1/sale/getOrderProcessing', {page: page, ...this.filter});

                if (res.code === 200) {
                    this.data = res.data;
                    this.paginate = res.paginate;
                }

                loader.hide();
            },
            onSelectOrder(order) {
                this.$emit('select', order.code);
                window.jQuery(this.$refs.modal).modal('hide');
            },
            search() {
                app.load();
            }
        }
    }
</script>

<style scoped>
    .title-search {
        background-color: #b1ddf0;
        padding: 5px 10px;
        font-weight: bold;
        margin-bottom: 1rem;
    }
</style>
