<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <input ref="input"  :placeholder="placeholder"
                autocomplete="off"
               :class="[(errors && errors.length ? 'error-input': ''),(this.sortTimeHeight ? 'select-option' : '')]"
                class="form-control" >
        <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true" v-if="this.sortTimeHeight" @click="showCalendar()"></i>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
import {DATE_LOCALES} from "../../lib/locale";

function parseModelValue(modelValue, dDate) {
    const tmp = modelValue ? modelValue.split('_') : [];
    const startDate = tmp[0] ? moment(tmp[0]) :moment(dDate);
    const endDate =  tmp[1] ? moment(tmp[1]) : moment();
    return [
        startDate, endDate
    ];
}

export default {
    name: "InputDateRangePicker",
    props: ['modelValue', 'placeholder', 'defaultClass', 'label', 'errors', 'sortTimeHeight', 'defaultDate'],
    watch: {
        modelValue: function () {
            if (!this.modelValue) {
                this.$refs.input.value = '';
                this.getData();
            } else {
                const dDate = this.defaultDate != null ? moment().format('YYYY-MM-DD') : moment().startOf('year').format('YYYY-MM-DD');
                const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
                if ( startDate.format('YYYY-MM-DD') === dDate && endDate.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && !this.sortTimeHeight) {
                    this.$refs.input.value = 'Tất cả';
                }
                else if (this.$refs.input.placeholder === 'Sinh nhật') {
                    this.$refs.input.value = startDate.format('DD/MM') + ' -- ' + endDate.format('DD/MM');
                }
                else {
                    this.$refs.input.value = startDate.format('DD/MM/YYYY') + ' -- ' + endDate.format('DD/MM/YYYY');
                }
                
            }
       },
    },
    mounted() {
        this.getData();
    },
    methods: {
        getData(){
            const format = 'DD/MM/YYYY';
            const input = this.$refs.input;
            const dDate = this.defaultDate != null ? moment().format('YYYY-MM-DD') : moment().startOf('year').format('YYYY-MM-DD');
            // const ranges =  {
            //     'Hôm nay': [moment(), moment()],
            //     'Hôm qua': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            //     '3 ngày trước': [moment().subtract(2, 'days'), moment()],
            //     '7 ngày trước': [moment().subtract(6, 'days'), moment()],
            //     '30 ngày trước': [moment().subtract(29, 'days'), moment()],
            //     'Tháng này': [moment().startOf('month'), moment().endOf('month')],
            //     'Tất cả': [moment(dDate), moment()]
            // };

            const ranges = {};
            ranges[this.$t('message.DateRangePicker.today')] = [moment(), moment()];
            ranges[this.$t('message.DateRangePicker.yesterday')] = [moment().subtract(1, 'days'), moment().subtract(1, 'days')];
            ranges[this.$t('message.DateRangePicker.threeDay')] = [moment().subtract(2, 'days'), moment()];
            ranges[this.$t('message.DateRangePicker.sevenDay')] = [moment().subtract(6, 'days'), moment()];
            ranges[this.$t('message.DateRangePicker.thirtyDay')] = [moment().subtract(29, 'days'), moment()];
            ranges[this.$t('message.DateRangePicker.month')] = [moment().startOf('month'), moment().endOf('month')];
            ranges[this.$t('message.common.all')] = [moment(dDate), moment()];


            const [startDate, endDate] = parseModelValue(this.modelValue, dDate);
            const $input = window.jQuery(input);
            if(this.sortTimeHeight){
                $input.daterangepicker({
                    autoUpdateInput: false,
                    startDate:  startDate ,
                    endDate:  endDate,
                    locale: DATE_LOCALES,
                });
            }else{
                $input.daterangepicker({
                    autoUpdateInput: false,
                    startDate:  startDate ,
                    endDate:  endDate,
                    locale: DATE_LOCALES,
                    ranges: ranges
                });
            }
            const changeHandler = (ev, picker) => {
                const start = $input.data('daterangepicker').startDate;
                const end = $input.data('daterangepicker').endDate;
                if (start.format('YYYY-MM-DD') === dDate && end.format('YYYY-MM-DD') === moment().format('YYYY-MM-DD') && !this.sortTimeHeight) {
                    input.value = 'Tất cả';
                } else if (this.$refs.input.placeholder === 'Sinh nhật') {
                    input.value = start.format('DD/MM') + ' -- ' + end.format('DD/MM');
                }
                else {
                    input.value = start.format(format) + ' -- ' + end.format(format);
                }

                const modelValue = start.format('YYYY-MM-DD') + '_' + end.format('YYYY-MM-DD');
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('input', modelValue);
                    this.$emit('changed', modelValue);
                });
            };

            $input.on('apply.daterangepicker', changeHandler);
            $input.on('change', changeHandler);

            if (this.modelValue) {
                input.value = startDate.format(format) + ' -- ' + endDate.format(format);
            }
        },
        showCalendar(){
            window.jQuery(this.$refs.input).focus()
        }
    }
}
</script>

<style scoped>
.far{     
    position: absolute;
    right: 16px;     
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;     
    -moz-osx-font-smoothing: grayscale;
    /* pointer-events: none; */
}
/* input::placeholder {
    color: #212529 !important;
} */
</style>
