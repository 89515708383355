<template>
  <div
    ref="ModalPreviewBarcode"
    class="modal mx-0 fade modal-second"
    tabindex="-1"
    role="dialog"
    id="ModalPreviewBarcode"
    data-backdrop="static">
    <div
      class="modal-dialog modal-lg"
      role="document"
      style="width: 360px !important; height: 480px !important">
      <div class="modal-content">
        <div class="modal-header py-1">
            <label class="modal-title py-2" style="font-weight: 400">
                {{ $t('message.ProductIndex.button.print') }}
            </label>
          <button
            type="button"
            class="close"
            data-dismiss="ModalPreviewBarcode"
            aria-label="Close"
            @click="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div
          class="modal-body my-0 py-0 mx-0 px-0"
          style="background-color: #6c757d">
          <div class="container-fluid">
            <div class="row text-center">
              <div class="col-12 dropdown my-2 text-center">
                <!-- <i class="fa fa-retweet text-center fa-lg text-white" aria-hidden="true"></i> -->
                <!-- <a
                  class="dropdown-toggle mx-4"
                  type="button"
                  id="download"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <i class="fas fa-download text-center fa-lg text-white"></i>
                </a>
                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                  <a class="dropdown-item" href="#" @click="printDocument">
                    <i class="far fa-file-pdf pr-2 fa-lg text-danger"></i>
                    Acrobat (PDF) file
                  </a>

                  <export-excel
                    :fetch="exportExcel"
                    :fields="excelData.fields"
                    :name="excelData.fileName">
                    <a
                      type="button"
                      class="px-4 py-1"
                      style="padding-bottom: 0.5rem">
                      <i class="far fa-file-excel text-dark pr-2 fa-lg"></i>
                      Xuất Excel
                    </a>
                  </export-excel>
                </div> -->
              
                <a class="" href="javascript:;" @click="InBarcode()">
                  <i class="fa fa-print text-center fa-lg text-white"></i>
                </a>
              </div>
            </div>
            <div class="row" ref="print" >
              <PaperRollLabelPreview
                v-if="this.tempType !== 2 && this.tempType !== 6"
                ref="PaperRollLabelPreview"
                :info="this.info"
                :status="this.status">
              </PaperRollLabelPreview>
              <JewelryPreview
                v-if="this.tempType == 6"
                ref="PaperRollLabelPreview"
                :info="this.info"
                :status="this.status">
              </JewelryPreview>
              <TommyPaperPreview
                v-if="this.tempType == 2"
                ref="TommyPaperPreview"
                :info="this.info"
                :status="this.status">
              </TommyPaperPreview>
            </div>


            <ProductBarcode
              ref="ProductBarcode"
              :status="this.status"
              :number="this.number"
              :width="this.width"
              :height="this.height"
              :tempType="this.tempType">
            </ProductBarcode>
              

            <PrintProductBarcode
              ref="PrintProductBarcode"
              :status="this.status"
              :number="this.number"
              :width="this.width"
              :height="this.height"
              :tempType="this.tempType"></PrintProductBarcode>
            
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProductBarcode from "./ProductBarcode";
import PaperRollLabelPreview from "@/components/products/PaperRollLabelPreview";
import TommyPaperPreview from "@/components/products/TommyPaperPreview.vue";
import JewelryPreview from "@/components/products/JewelryPreview";
import {rand} from "@/lib/utils";
//download pdf
import pdfMake from "pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import htmlToPdfmake from "html-to-pdfmake";
import PrintProductBarcode from "./PrintProductBarcode";
export default {
  name: "ModalPreviewBarcode",
  props: {
    status: {},
    info: null,
    parent: null,
    multiParent: null,
  },
  components: {
    ProductBarcode,
    PaperRollLabelPreview,
    TommyPaperPreview,
    JewelryPreview,

    //pdf
    pdfMake,
    pdfFonts,
    htmlToPdfmake,
    PrintProductBarcode,
  },
  data() {
    return {
      // info: [],
      total: 0,
      excelData: {
        fields: null,
        data: [],
        fileName: "",
      },
      number: null,
      width: null,
      height: null,
      tempType: null,
    };
  },
  mounted() {},
  methods: {
    printDocument1() {
      //get table html
      const pdfTable = document.getElementById("divToPrint");
      //html to pdf format
      var html = htmlToPdfmake(pdfTable.innerHTML);
      const documentDefinition = { content: html };
      pdfMake.vfs = pdfFonts.pdfMake.vfs;
      pdfMake.createPdf(documentDefinition).download(`Ma_Vach_` +  rand(100000,999999)  + `.pdf`);
      pdfMake;
    },
    
    show(type, number, width, height) {         
      this.tempType = type;
      this.number = number;
      this.width = width;
      this.height = height;
      window.jQuery(this.$refs.ModalPreviewBarcode).modal("show");
    },
    hide() {
      window.jQuery(this.$refs.ModalPreviewBarcode).modal("hide");
    },
    //test
    InBarcode() {      
      this.$refs.ProductBarcode.multiplePrint(this.info);
    },

    printDocument(){
         this.$refs.PrintProductBarcode.multiplePrint(this.info);
    }
  },
  watch: {},
};
</script>

<style>
#download {
  border: 0;
}
.priceInfo {
  font-weight: bold !important;
}
</style>
