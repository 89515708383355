<template>
    <div class="row">
        <div class="col-lg-6">
            <div class="row">
                <strong>Danh sách mã voucher</strong>
            </div>
        </div>
    </div>

</template>

<script>
export default {
    name: "PromotionTabVoucher"
}
</script>

<style scoped>

</style>
