export default {
    methods: {     
        checkPermission(permissionName) {
            if (permissionName && this.authData && this.authData.user) {
                // if (this.permissionInfo && (this.permissionInfo.isAdmin || this.permissionInfo.isRoot)) {
                if (this.permissionInfo && this.permissionInfo.isRoot) {
                    return true;
                }                 
                if (permissionName &&  Array.isArray(permissionName)) {
                    for (let index = 0; index < permissionName.length; index++) {
                        const per = permissionName[index];
                        if (this.permissionInfo.permissionNameMap[per]){
                            return true;
                        }
                    }
                }
                return !!this.permissionInfo.permissionNameMap[permissionName];
            }   
            return false;
        },   
    }
}
  