<template>
 <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-4">
                    
                    <h5 class="modal-title py-2">
                        {{ $t('message.supplierIndex.modal.titleRemove') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4" >
                    <h6>
                        {{ $t('message.supplierIndex.modal.contentRemove') }}
                    </h6>
                    <div class="col-12 d-flex justify-content-end pt-2">
                        <button class="btn btn-danger mr-2" @click="deleteData()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.button.skip') }}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$alert, $post} from "@/lib/utils";

export default {
    name: 'ModalRemoveSuppliers',
    props: {},
    data () {
        return {
          data: [],
        };
    },
    methods: {
        show(data) {
          if(data != null){
            this.data = [data];
            this.multi = false;
            this.dataFalseRemove = [];
          } else{
            this.data =  this.getDataCheck();
            this.multi = true;
            this.dataFalse = [];
            this.afterRemove = false;
            if(this.isAlign === false){
              this.isAlign = !this.isAlign;
            }
          }
          window.jQuery(this.$refs.modal).modal('show');
        },

      async deleteData(page = null){
        this.isAlign = !this.isAlign;
        const res = await $post('/v1/suppliers/remove', { id: this.data, multi: true, page: page});
        // this.doneRemove = res.removed.length;
        // this.falseRemove = this.data.length - this.doneRemove;
        // this.paginate = res.paginate;
        // this.dataFalseRemove = res.entry.data;
        // this.afterRemove = true;
        // localStorage.removeItem('productID');
        // localStorage.removeItem('details');
        if(this.dataFalseRemove.length === 0){
          $alert(res);
          this.$emit('inputData','1');
          window.jQuery(this.$refs.modal).modal('hide');
        }
      },
    }
}
</script>
<style>
</style>