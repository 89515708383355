<template>
    <div class="search-box position-relative" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input" :placeholder="$t('message.common.searchProduct')" autocomplete="off" 
        v-model.trim="keyword" v-on:keyup.enter="quickSearch(this.$constant.TYPE_PRODUCT)" :readonly="readonly"/>
        <div class="d-inline-flex" v-click-outside="closeDropDownSearch">
            <button type="button" id="idDropdownBtnSearch" class="border-none bg-transparent btn-icon" @click="showDropDownSearch()" v-if="!promotionSearch"><i class="fas fa-caret-down"></i></button>
            <div id="idDropdownMenuSearch" class="dropdown-content" v-show="showDropDown">
                <div class="form-group p-0 mx-2 mb-1">
                    <input type="text" v-model.trim="product_keyword" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.common.searchProduct')"
                    v-on:keyup.enter="quickSearch(this.$constant.TYPE_PRODUCT)" :readonly="readonly">
                </div>
                <div class="form-group p-0 mx-2 mb-1">
                    <input type="text" v-model.trim="inventory_plan_keyword" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.common.searchPlanDetail')"
                    v-on:keyup.enter="quickSearch($constant.TYPE_INVENTORY_PLAN)" :readonly="readonly">
                </div>
            </div>
        </div>
        <div class="popup-product-search" v-if="(product_keyword || inventory_plan_keyword || keyword) && search">
            <ul class="wrap-products" v-if="entries.length">
                <li v-for="entry in entries" :key="entry.code" @click="onSelect(entry)">
                    <div class="product-info">
                        <p class="name-item">
                            <span v-text="entry.name"></span>
                        </p>
                        <p>
                            <span class="code-item" v-text="entry.code"></span>
                        </p>
                    </div>
                </li>
            </ul>
            <div v-else class="products-not-found">
                <span>{{ $t('message.common.notFound') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import {$alert, $post, debounce} from "@/lib/utils";
    import store from "@/store";

    export default {
        name: "InventoryPlanAndProductSearch",
        emits: ['select'],
        props: ['readonly', 'branchIDs'],
        data() {
            return {
                entries: [],
                keyword: '',
                showDropDown: false,
                search: false,
                inventory_plan_keyword: '',
                product_keyword: '',
                typeSrc: null,
                branchId: store.state.CurrentBranch.id,
            }
        },
        watch: {
            branchIDs(value) {
                if (!value) {
                    this.keyword = '';
                }
            }
        },
        methods: {
            onSelect(entry) {
                entry.typeSrc = this.typeSrc;
                this.$emit('select', entry);
                this.hidePopup();
            },
            hidePopup() {
                this.keyword = '';
                this.product_keyword = '';
                this.inventory_plan_keyword = '';
                this.entries.length = 0;
                this.search = false;
            },
            closeDropDownSearch(){
                this.showDropDown = false;
            },
            showDropDownSearch(){
                this.showDropDown = !this.showDropDown;
            },
            async quickSearch(type){
                this.entries = [];
                if (!this.product_keyword && !this.inventory_plan_keyword && !this.keyword) {
                    return;
                }
                let keyword_search = '';
                let product_search = false;
                let inventory_plan_search = false;
                if (type == this.$constant.TYPE_PRODUCT) {
                    product_search = true;
                    keyword_search = this.product_keyword && this.product_keyword?.length ? this.product_keyword : (this.keyword && this.keyword?.length ? this.keyword : '');
                } else if (type == this.$constant.TYPE_INVENTORY_PLAN) {
                    inventory_plan_search = true;
                    keyword_search = this.inventory_plan_keyword;
                }
                const res = await $post('/v1/plan-purchases/search-plan-details', {
                    keyword: keyword_search,
                    branchIDs: this.branchIDs,
                    product_search: product_search,
                    inventory_plan_search: inventory_plan_search,
                    currentBranchId: this.branchId
                });
                this.typeSrc = type;
                this.closeDropDownSearch();
                this.search = true;
                if (res && res.result) {
                    this.entries = res.result;
                }
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .btn-icon:focus{
        outline: none;
        border: none;
    }
    .dropdown-content{
        z-index: 999;
        margin: 0.5rem 0 0;
        width: 100%;
        background-color: #fff;
        padding: 0.5rem;
        border: none;
        background-color: #fff;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        -moz-box-shadow: 0 5px 25px rgba(0,0,0,.25);
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        transform: translate3d(0px, 28px, 0px) !important;

        position: absolute;
        left: 0;
        top: 0;
        float: left;
        min-width: 10rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
    }
    .input-quick-search:focus{
        border-bottom: 1px solid $base-color !important;
        border-radius: 0;
    }
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;
        /*padding-bottom: 50px;*/

        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-invoices {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .invoices-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
    .search-input[readonly]{
        background-color: transparent !important;
        cursor: pointer;
        pointer-events: all !important;
    }
</style>
