<template>
    <div class="customer-create ml-auto">
        <!-- Button trigger modal -->
        <button type="button" class="add-btn" data-toggle="modal" :data-target="'#createReturn'">
            <i class="fa fa-plus"/> Thêm
        </button>

        <!-- Modal -->
        <div class="modal fade" id="createReturn" tabindex="-1" role="dialog" aria-labelledby="createReturnLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createAddressLabel">Chọn hóa đơn trả hàng</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-6">
                                    <div class="search-box">
                                        <i class="fas fa-search mr-2"></i>
                                        <input type="text" class="search-input"
                                               @keydown.enter="load()"
                                               v-model="keyword" placeholder="Tìm kiếm"/>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <InputDateRangePicker v-model="purchaseDate"
                                                          @update:modelValue="value => load()"
                                                          placeholder="Thời gian"/>
                                </div>
                            </div>
                            <div class="row mt-3">
                                <table class="table table-index table-striped">
                                    <thead>
                                    <tr>
                                        <th scope="col">{{$t('message.InputQuickSearch.title.code_invoice')}}</th>
                                        <th scope="col">Ngày tạo</th>
                                        <th scope="col">Nhân viên bán</th>
                                        <th scope="col">{{$t('message.headers.customers')}}</th>
                                        <th scope="col">Tổng tiền</th>
                                        <th scope="col">{{$t('message.button.action')}}</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="entry in entries" :key="entry.id">
                                        <td v-text="entry.code"></td>
                                        <td v-text="dt(entry.purchaseDate)"></td>
                                        <td v-text="entry.user_created?.name"></td>
                                        <td v-text="entry.customerName"></td>
                                        <td v-text="n(entry.totalPayment)"></td>
                                        <th>
                                            <button type="button" class="btn btn-primary" @click="onReturn(entry)">Trả hàng</button>
                                        </th>
                                    </tr>
                                    </tbody>
                                </table>
                                <Paginate :value="paginate" @change="pageChange"/>
                            </div>

                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="onFastReturn()">Trả hàng nhanh</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import Paginate from "@/components/Paginate";
import InputDateRangePicker from "@/components/forms/InputDateRangePicker";
import toastr from 'toastr';
export default {
    name: "CreateReturn",
    props: ['id', 'customerId'],
    components: {
        Paginate, InputDateRangePicker
    },
    mounted() {
            this.load();


    },
    data() {
        return {
            name: '',
            keyword: '',
            purchaseDate: '',
            entries: [],
            errors: {},
            location: {
                provinceId: '',
                districtId: '',
                wardId: ''
            },
            page: 1,
            typeAddress: '',
            paginate: {
                currentPage: parseInt(this.page) || 1,
                lastPage: 1
            }
        }
    },
    methods: {
        async load() {
            const res = await $get('/v1/invoices/dataReturn', { keyword: this.keyword, purchaseDate: this.purchaseDate, page: this.page});

            if (res.code === 404) {

                toastr.error('Không tìm thấy trang này');
                return;
            }

            this.entries = res.data.entries;
        },
        async pageChange(page) {
            this.page = page;
            await this.load();
        },
        async save() {
            this.errors = {};
            this.address.provinceId = this.location.provinceId;
            this.address.districtId = this.location.districtId;
            this.address.wardId = this.location.wardId;
            const res = await $post('/v1/customers/saveAddress', {
                customer_id: this.customerId, address: this.address
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            if (res.code === 200) {
                window.$(this.id ? '#formAddress_' + this.id : '#createAddress').modal('hide');
                await this.$parent.loadAddress();
            }

            $alert(res);
        },
        onReturn(invoice) {
            window.$('#createReturn').modal('hide');
            window.open('/sale?returnInvoice=' + invoice.code, '_blank');
        },
        onFastReturn() {
            window.$('#createReturn').modal('hide');
            window.open('/sale?typeTab=newReturn', '_blank');
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/css/customer";
.modal-lg {
    max-width: 1000px;
}
.modal-title {
    font-weight: bold;
    color: #000000;
}

</style>