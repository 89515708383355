<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{ $t('message.common.selectMethodDiscount') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <InputNumberFormat  v-if="model.discountType === this.$constant.discountTypeMoney" :min="0" v-model="model.discount" :label="$t('message.common.priceDiscount')" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                    <InputNumberFormat  v-else :min="0" v-model="model.valuePercent" :label="$t('message.common.priceDiscount')"/>
                    <InputSelect
                            name="status"
                            v-model="model.discountType"
                            :options="$constant.discountOptions"
                            :label="$t('message.common.methodDiscount')"
                    />
                </div>
                <div class="modal-footer">
                    <button type="button" @click="save()" class="btn btn-primary">
                        {{ $t('message.button.save') }}
                    </button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">
                        {{ $t('message.button.skip') }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import currencyMixin from "@/currencyMixin";

    export default {
        name: "ModalProductDiscount",
        components: {InputSelect, InputNumberFormat},
        mixins: [currencyMixin],
        data() {
            return {
                model: {
                    discount: 0,
                    discountType: this.$constant.discountTypeMoney,
                    valuePercent: 0,
                },
            }
        },
        methods: {
            clearData() {
                this.model.discount = 0;
                this.model.discountType = this.$constant.discountTypeMoney;
                this.model.valuePercent = 0;
            },
            async save() {
                if (this.model.discountType === this.$constant.discountTypePercent && this.model.valuePercent > 100) {
                   this.model.valuePercent = 100;
                }
                if (this.onSave) {
                    this.onSave(this.model);
                }
                this.model.discount = 0;
                this.model.discountType = this.$constant.discountTypeMoney;
                this.model.valuePercent = 0;
                window.jQuery(this.$refs.modal).modal('hide');
            },
            show(discount = null, discountType = null, valuePercent = null, onSave) {
                this.clearData();
                if (discountType != null) {
                    this.model.discountType = discountType;
                    if (discountType == this.$constant.discountTypePercent) {
                        this.model.valuePercent = valuePercent;
                    } else {
                        this.model.discount = discount;
                    }
                }
                this.onSave = onSave;
                window.jQuery(this.$refs.modal).modal();
            }
        },
    }
</script>

<style scoped>

</style>
