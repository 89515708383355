<template>
     <AppPermission :permission="this.$permissions.branches.data?? '' ">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{ $t('message.branchIndex.title') }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                            <div class="col-8 text-title">
                                <label>{{ $t('message.common.numberRecord') }}:</label>
                            </div>
                            <div class="col-4">
                                <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>
                    <!-- Filter trạng thái -->
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.branchIndex.branch_status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="#" class="text-dark" id="linkIcon"
                                    @click="showHide('status', 'sttDown', 'sttUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="sttDown"></i>
                                    <i class="fa fa-chevron-up" id="sttUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="status">
                            <template v-if="this.optionSttBranches">
                                <div class="row mb-2 ml-0" v-for="option in this.optionSttBranches" :key="option.id">
                                    <label class="container-radio ml-2">{{option.label}}
                                        <input type="radio" name="sttSaleChannel" v-model="this.filter.status" :checked="this.filter.status === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('status', option.value)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>

                <div class="mainRight">
                    <div>
                        <div class="row">
                            <div class="col-lg-5">
                            <div class="search-box">
                                <i class="fa fa-search mr-2"></i>
                                <input type="text" class="search-input"
                                    @keydown.enter="doFilter('keyword', filter.keyword)"
                                    v-model="filter.keyword" :placeholder="$t('message.branchIndex.placeholder.search')"/>
                            </div>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                <AppPermission :permission="this.$permissions.branches.create?? '' ">
                                 <button @click="showModalCreate()" class="btn  btn-save ml-1"><i class="fa fa-plus"/> {{ $t('message.branchIndex.button.add') }} </button>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="branches"></SelectField>
                                </div>
                            </div>
                        </div>
                        <div v-if="entries.length > 0 && this.show">
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2"></div>
                        <div class="row mx-0 my-2" v-if="entries.length > 0 && this.show" id="divTable">
                            <table class="table table-index table-striped lstProduct" >
                                <thead>
                                    <tr>
                                        <th scope="col" class="checkbox-table"></th>
                                        <th scope="col" v-for="field in fields" :key="field.field" v-show="field.selected">
                                            <TableSortField :current="sortData.field" @onsort="sort" :no-sort="!field.isColumn" :name="field.name" :field="field.field" :class="field.class"/>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(entry, index) in entries" :key="entry.id" >
                                        <tr class="master-row" :class="entry.id === this.branchDetailId ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                            <td></td>
                                            <template v-for="field in fields" :key="field.field">
                                                <td scope="col" class="ow-break-word py-0" style="vertical-align:middle !important;" :style="'min-width:110px'" v-if="field.selected">
                                                    <a :href="'#row_' + index" class="text-dark tagA" @click="detailBranch(entry.id)">
                                                        <div v-if="field.field==='status'" :style="field.style" class="description" :class="field.class"><span v-text="status(entry.status)"></span></div>
                                                        <div v-else-if="field.field==='numberUser'" :style="field.style" class="description" :class="field.class"><span v-text="n(entry.numberUser)"></span></div>
                                                        <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :class="field.class" class="description">
                                                        </div>
                                                    </a>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr v-if="this.branchDetailId === entry.id" :class="[ entry.id === this.branchDetailId ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                            <td></td>
                                            <td :colspan="fields.length" class="pb-0">
                                                <div class="row detail-prd-row">
                                                    <div class="col-2 mt-2" @click="showContent('info')">
                                                        <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{ $t('message.branchIndex.tabs.info') }}
                                                        </span>
                                                    </div>
                                                    <!-- <div class="col-2 mt-2" @click="showContent('user')">
                                                        <span :class="[ headerActive === 'user' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{ $t('message.branchIndex.tabs.user') }}
                                                        </span>
                                                    </div> -->
                                                    <div class="col-2 mt-2" @click="showContent('addressGetOrder')">
                                                        <span :class="[ headerActive === 'addressGetOrder' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{ $t('message.branchIndex.tabs.address_get_order') }}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.branchDetailId === entry.id && this.headerActive == 'info'">
                                            <td></td>
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4 detail__user">
                                                    <div class="row mt-2 ml-0 detail-prd-row">
                                                        <div class="col-5 mr-4">
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{ $t('message.branchIndex.branch_name') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">                                                            
                                                                    {{ entry.name }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5 d-flex align-items-center">
                                                                    {{ $t('message.branchIndex.branch_phone') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.phone }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{ $t('message.branchIndex.branch_email') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.email }}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{ $t('message.branchIndex.branch_work_day') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    <template :key="n" v-for="(n, index) in entry.workDays">     
                                                                        <template :key="day" v-for="(day) in this.$constant.dayOfWeeks">
                                                                            <template v-if="n === day.id && index != (entry.workDays.length - 1)">
                                                                                <span>{{ day.name + ', '}}</span>
                                                                            </template>
                                                                            <template v-if="n === day.id && index === (entry.workDays.length - 1)">
                                                                                <span>{{ day.name }}</span>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-5 ml-4">
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{ $t('message.branchIndex.branch_address') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.address }}                                                                                                                                 
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5 d-flex align-items-center">
                                                                    {{ $t('message.branchIndex.branch_area_delivery') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.areaDelivery }}                                                                                                                                 
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-5">
                                                                    {{ $t('message.branchIndex.branch_ward') }}:
                                                                </div>
                                                                <div class="col-7 ow-break-word">
                                                                    {{ entry.wardName }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.branchDetailId === entry.id && this.headerActive == 'info'">
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="row detail-prd-row flex-end pr-4 mb-2"> 
                                                    <AppPermission :permission="this.$permissions.branches.update?? '' ">
                                                        <button type="button" class="btn btn-save mx-1" @click="showModalUpdate(entry.id)">
                                                            <i class="fa fa-check mr-2"></i>
                                                            {{ $t('message.branchIndex.button.update') }}
                                                        </button>
                                                    </AppPermission>
                                                     <AppPermission :permission="this.$permissions.branches.inActive?? '' ">
                                                    <button type="button" class="btn btn-danger mx-1" v-if="entry.status == 1" @click="showModalStopBranch(entry.id, entry.name)">
                                                        <i class="fa fa-lock mr-2"></i>
                                                        {{ $t('message.branchIndex.button.inActive') }}
                                                    </button>
                                                    <button type="button" class="btn btn-primary mx-1" v-else @click="showModalActiveBranch(entry.id, entry.name)">
                                                        <i class="fa fa-unlock"></i> 
                                                        {{ $t('message.branchIndex.button.active') }}
                                                    </button>
                                                    </AppPermission>
                                                    <AppPermission :permission="this.$permissions.branches.remove?? '' ">
                                                    <button type="button" class="btn btn-danger mx-1" @click="showModalRemoveBranch(entry.id, entry.name)">
                                                        <i class="fa fa-trash-alt mr-2"></i>
                                                        {{ $t('message.branchIndex.button.remove') }}
                                                    </button>
                                                    </AppPermission>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="detail-row bg-white border__body-detail" v-if="this.branchDetailId === entry.id && this.headerActive == 'addressGetOrder'">
                                            <td :colspan="fields.length" class="border-top-0 pt-2">
                                                <div class="row mx-0 my-12" v-if="entries.length > 0" id="divTable">
                                                    <div class="col-12">
                                                        <table class="w-100" style="max-width:100%;">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="checkbox-table"></th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.common.STT')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.addressName')}}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody> 
                                                                <template v-if="entry.pickupAddress">
                                                                    <tr class="table-header border-1" v-for="(item, index) in entry.pickupAddress" :key="index">
                                                                        <template v-if="item.key != 0">
                                                                            <td  @click="showModalDeletePickup(item.id)" class="fa fa-times text-danger"></td>
                                                                        </template>
                                                                        <template v-else>
                                                                            <td></td>
                                                                        </template>
                                                                        <td class="col-3" v-text="item.key == 0 ? $t('message.common.atBranch') : item.key"></td>
                                                                        <template v-if="item.key != 0">
                                                                            <td class="text-primary" @click="showModalUpdatePickup(item.id, 'isUpdate')">{{ item.name }}</td>
                                                                        </template>
                                                                        <template v-else>
                                                                            <td >{{ item.name }}</td>
                                                                        </template>
                                                                    </tr>
                                                                </template>            
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="detail-row bg-white border__body-detail" v-if="this.branchDetailId === entry.id && this.headerActive == 'addressGetOrder'">
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="row detail-prd-row flex-end pr-4 mb-2"> 
                                                        <button type="button" class="btn btn-save mx-1" @click="showModalCreatePickup(entry.id, 'isCreate')">
                                                            <i class="fa fa-plus mr-2"></i>
                                                            {{$t('message.ModalCreateRouteOfUse.button.create')}}
                                                        </button>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr class="detail-row bg-white border__footer-detail" v-if="this.branchDetailId === entry.id"></tr>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                        <div class="row ml-auto mr-auto" v-if="entries.length == 0 || !this.show" id="notFound">
                            <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalRemoveBranch ref="ModalRemoveBranch" @updated="load()" />
        <ModalStopBranch ref="ModalStopBranch" :title="this.title" :content="this.content" :type="this.type" @updated="load()"/>
        <ModalCreateBranch ref="ModalCreateBranch" @updated="load()" />
        <ModalCreateUpdatePickup ref="modalCreateUpdatePickup" @updated="load()"></ModalCreateUpdatePickup>

        <ModalConfirm ref="ModalConfirm" @confirm="hide()" @updated="load()"/>
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray, getSearchQuery} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import emitter from "@/lib/emitter";
    import store from "@/store";
    import SelectField from "@/components/SelectField";
    import ModalRemoveBranch from './ModalConfirmDelete.vue';
    import ModalStopBranch from './ModalStopBranch.vue';
    import ModalCreateBranch from './ModalCreateBranch.vue';
    import AppPermission from '@/components/AppPermission.vue';
    import ModalCreateUpdatePickup from "@/views/pickupaddress/ModalCreateUpdate";
    import ModalConfirm from "@/views/pickupaddress/ModalRemove.vue";
    export default {
        name: "BranchesIndex",
        components: {TableSortField, Pagination, SelectField, ModalConfirm, ModalRemoveBranch, ModalStopBranch, ModalCreateBranch, AppPermission, ModalCreateUpdatePickup},
        mounted() {
            document.title = this.$t('message.branchIndex.title');
            const fields = localStorage.getItem('branchesSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            // this.clearFilter();
            this.load();
        },
        data() {
            const query = getSearchQuery();
            return {
                filter: {
                    keyword: query.keyword,
                    status: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                optionSttBranches: [
                    { id: 0, label: this.$t('message.branchIndex.status.all'), value: '' },
                    { id: 1, label: this.$t('message.branchIndex.status.active'), value: 1 },
                    { id: 2, label: this.$t('message.branchIndex.status.inActive'), value: 2 },
                ],
                numberRecord: 15,
                user: store.state.Auth.user,
                branchDetailId: null,
                headerActive: 'info',
                show: true,
                title: '',
                content: '',
                type: '',
                isFilter: false,
            }
        },
        watch: {
            fields: {
                handler(newValue) {
                    let show = false;
                    newValue.forEach(v => {
                        if(v.selected == true){
                            show = true;
                        }
                    })
                    this.show = show;
                },
                deep: true,
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/branches/index',
                    query: {}
                });
                this.numberRecord = 15;
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.sortData).forEach(key => {
                    this.sortData[key] = '';
                });
                this.isFilter = false;
                this.branchDetailId = null;
                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                if(value == null || value == ''){
                    delete query[field];
                }
                query['page'] = 1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                this.isFilter = true;
                await this.$router.push({query: query});
                this.load();
            },
            async load() {
                emitter.emit("onLoading");
                const res = await $get('/v1/branches/index', {...this.$route.query, numberRecord: this.numberRecord});
                this.entries = res.result.data.data;       
                this.paginate.totalRecord = res.result.data.total;
                this.paginate.currentPage = res.result.data.current_page;
                this.paginate.lastPage = res.result.data.last_page;
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.fields;
                }
                emitter.emit("offLoading");
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            detailBranch(id){
                if (id === this.branchDetailId) {
                    this.branchDetailId = null;
                } else {
                    this.branchDetailId = id;
                }
                this.headerActive = 'info';
            },
            showContent(name) {
                this.headerActive = name;
            },
            showModalRemoveBranch (id, name) {
                this.$refs.ModalRemoveBranch.show(id, name);
            },
            showModalStopBranch(id, name) {
                this.title = this.$t('message.branchIndex.modal.title_confirm');
                this.content = this.$t('message.branchIndex.modal.alerts.confirm_in_active');
                this.type = 0;
                this.$refs.ModalStopBranch.show(id, name, 0);
            },
            showModalActiveBranch(id, name) {
                this.title = this.$t('message.branchIndex.modal.title_confirm');
                this.content = this.$t('message.branchIndex.modal.alerts.confirm_active');
                this.type = 1;
                this.$refs.ModalStopBranch.show(id, name, 1);
            },
            showModalCreate () {
                this.$refs.ModalCreateBranch.show();
            },
            showModalUpdate (id) {
                this.$refs.ModalCreateBranch.show(id);
            },
            showModalCreatePickup (id, type){
                this.$refs.modalCreateUpdatePickup.show(id, type);
            },
            showModalUpdatePickup (id, type){
                this.$refs.modalCreateUpdatePickup.show(id, type);
            },
            showModalDeletePickup (id)
            {
                this.$refs.ModalConfirm.show(id);
            }
            
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
</style>