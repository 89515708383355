<template>
    <div class="container">
        <div class="row">
            <ActionBar back-url="/order-suppliers/index" @action="save()"/>
            <div class="col-12 pb-100">
                <div class="row">
                    <div class="col-9 p-0 card-transaction-left">
                        <div class="row d-flex align-items-center" style="margin-top: 10px">
                            <div class="col-md-3 d-flex align-items-center justify-content-lg-between">
                                <svg width="17" height="17" viewBox="0 0 17 17" @click="back()" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1666 7.54183H4.50373L9.86081 2.18475L8.49998 0.833496L0.833313 8.50016L8.49998 16.1668L9.85123 14.8156L4.50373 9.4585H16.1666V7.54183Z" fill="#666666"/>
                                </svg>
                                <div class="main-title">
                                    <span>
                                        {{$t('message.headers.return_purchase_orders')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <!-- <ImportForSupplierSearch @select="onSelectImport" :readOnly="isEdit || isLinkReturn"></ImportForSupplierSearch> -->
                                <MultipleSearchReturn @select="onSelectImport" :placeholder="this.$t('message.common.searchPO')" :currentTab="currentTab"></MultipleSearchReturn>
                            </div>
                        </div>
                        <Pagination v-if="form?.products?.length && form?.products?.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <table class="table table-index table-striped table-transaction" style="margin-top: 15px">
                            <thead>
                            <tr >
                                <th></th>
                                <th width="2%">
                                    {{$t('message.common.STT')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodCode')}}
                                </th>
                                <th width="23%">
                                    {{$t('message.DetailOrdersCard.prodName')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.dvt')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.attribute')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodQuantity')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.prodImportPrice')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.ReturnPurchaseOrder.returnPrice')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodDiscount')}}
                                </th>
                                <th width="13%">
                                    {{$t('message.DetailOrdersCard.totalPrice')}}
                                </th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in form.products" :key="product.id" >
                                <tr v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                    <td>
                                        <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                            <i class="fas fa-trash"/>
                                        </a>
                                    </td>
                                    <td>{{ index + 1 }}</td>
                                    <td v-text="product.code"></td>
                                    <td>
                                        {{ product.name }}
                                        <div class="position-relative">
                                            <input type="text" v-model.trim="product.note" class="format__input bg-transparent text-left" placeholder="Ghi chú ... ">
                                            <span class="noteOrder text-gray" v-if="product.note == null || product.note == ''"><i class="fa fa-pencil-alt"></i></span>
                                        </div>
                                    </td>
                                    <td class="title">{{ product.productUnit ? product.productUnit.unitName : null }}</td>
                                    <td class="title">{{ product.attributeName }}</td>
                                    <td class="pt-3 block__quantity">
                                        <input type="number" class="format__input" min="1" v-model="product.quantity" @keyup="checkQuantity(product.quantity, product.id, $event)" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null">
                                        <div v-if="product.maxQuantity && this.type == 1" class="text-right block__quantity-max">{{ '/' + product.maxQuantity }}</div>
                                    </td>
                                    <td class="pt-2">
                                        {{ $filters.formatPrice(product.price) }}
                                    </td>
                                    <td class="pt-0">
                                        <InputNumberFormat :max="product.price" :cardTransaction="true" v-model="product.priceReturn" :inputTable="true" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" @input="changePriceReturn(product)"/>
                                    </td>
                                    <td class="pt-3">
                                        <ProductDiscount :modelValue="product" @update:modelValue="updateDiscount" :price="product.price" :ref="'discountProduct' + product.id"/>
                                    </td>
                                    <td>{{ $filters.formatPrice((+product.priceReturn)*product.quantity) }}</td>
                                    <td></td>
                                </tr>
                                <tr v-if="product.isConsignment">
                                    <td></td>
                                    <td :colspan="9">
                                        <template v-if="this.type === 2">
                                            <ChooseConsignment :object="product" @update:value="updateConsignment" :noAddConsignment="true"/>
                                        </template> 
                                        <template v-else>
                                            <ChooseConsignment :object="product" @update:value="updateConsignment" :noAddConsignment="true" :noChooseOtherConsignment="true" :returnSupplier="true"/>
                                        </template>
                                    </td>
                                </tr>
                                <!-- Sản phẩm plus -->
                                <!-- Detail nhiều đơn vị -->
                                <template v-for=" (plus, plusIndex) in  product.plus" :key="plusIndex">
                                    <tr v-if=" index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                        <td colspan="4"></td>
                                        <td class="title">
                                            {{ plus.productUnit ? plus.productUnit.unitName : null }}
                                        </td>
                                        <td class="title">{{ plus.attributeName }}</td>
                                        <td class="pt-3 block__quantity">
                                            <input type="number" class="format__input" :min="1" v-model="plus.quantity" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null">
                                            <div v-if="plus.maxQuantity" class="text-right block__quantity-max">{{ '/' + plus.maxQuantity }}</div>
                                        </td>
                                        <td class="">
                                            {{ $filters.formatPrice(plus.price) }}
                                        </td>
                                        <td>
                                            <div class="position-relative">
                                                <InputNumberFormat :max="plus.price" :cardTransaction="true" v-model="plus.priceReturn" :inputTable="true" class="mb-0" @input="changePriceReturn(plus)"/>
                                            </div>
                                        </td>
                                        <td>
                                            <ProductDiscount :modelValue="plus" :indexDiscount="plusIndex" @update:modelValue="updateDiscountPlus" :ref="'discountProductPlus' + plusIndex + '_' + index" :price="plus.price"/>
                                        </td>
                                        <td> 
                                            {{ $filters.formatPrice((+plus.priceReturn)*plus.quantity) }}
                                        </td>
                                        <td>
                                            <a class="btn-remove" @click.prevent="removeDetailProductPlus(index, plusIndex)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td> 
                                    </tr>

                                    <!-- consignment từng đơn vị -->
                                    <tr v-if="product.isConsignment">
                                        <td></td>
                                        <td :colspan="9">
                                            <ChooseConsignment :object="plus" @update:value="updateConsignmentProductUnit"  :index="index" :plusIndex="plusIndex" :noAddConsignment="true" :noChooseOtherConsignment="true" :returnSupplier="true"/>
                                        </td>
                                    </tr>
                                </template>

                            </template>
                            </tbody>
                        </table>
                    </div>

                    <div class="col-3 pt-2 card-transaction-right">
                        <div class="row card-transaction-right-content">
                            <div class="col-12">
                                <div class="row pb-2">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-2 p-0 d-flex justify-content-end align-items-center">
                                                <h5 class="mb-0"><i class="fa fa-user-circle"></i></h5>
                                            </div>
                                            <div class="col-10 pl-2">
                                                <SelectSearch :placeholder="$t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by')" :modelValue="form.userId" :defaultOption="this.employees.all" @update:modelValue="selectEmployee" :label="'orderBy'"></SelectSearch>
                                            </div> 
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-7 pl-0 pr-3">
                                                <InputDatePicker :cardTransaction="true" v-model="form.returnDate" :placeholder="$t('message.common.createdAt')" class="mb-0"/>
                                            </div>
                                            <div class="col-5 pl-0 pr-3">
                                                <InputTimePicker :cardTransaction="true" :default="form.returnTime" @input="setTimeStart"></InputTimePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.PurchaseOrderIndex.code')}}
                                    </div>
                                    <div class="col-6 text-left form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ this.form.ImportCode }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.PurchaseOrderIndex.tabs.table.history_return.code')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none pt-0">
                                            <input type="text" v-model.trim="form.code" class="form-control input__order min-w-100 border-bottom-1" :placeholder="$t('message.common.autocompleteCode')">
                                        </div>
                                    </div>
                                </div>                                
                                <div class="row pb-2 mb-3" :style="'height:' + this.form?.supplierName?.length +'px;'"> 
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.headers.suppliers')}}
                                    </div>
                                    <div class="col-6 text-left form-group" id="supplierName">
                                        <div class="form-control border-none">
                                            {{ this.form.supplierName }}
                                        </div>
                                    </div>
                                </div>
                                <!-- <div class="row pb-2">
                                    <div class="col-12">
                                        <SupplierSearch class="form-control" @select="onSelectSupplier" :supplierName="form.supplierName"></SupplierSearch>
                                    </div>
                                </div> -->
                                <div class="row py-2 " id="styleStatus">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.common.status')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="form-control border-none">
                                            {{$t('message.common.draft')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.OrderSupplierIndex.total_origin')}}
                                        <button class="border ml-2 text-dark" disabled v-text="this.form.TotalQuantity"></button>
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(form.totalPrice) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.OrderSupplierIndex.discount')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="border-bottom form-discount text-right" @click.stop="showDiscountForm">{{ n(this.form.discountValue ?? 0) }}</div>
                                        <!-- <ProductDiscount :modelValue="form" @update:modelValue="updateDiscount" :price="form.totalPrice"/> -->
                                    </div>
                                </div>
                                <div class="row pb-2 text-bold">
                                    <div class="col-6 d-flex align-items-center">
                                        {{$t('message.ReturnPurchaseOrder.supplierNeedToPay')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary">
                                            {{ $filters.formatPrice(+form.totalPrice - +form.discountValue) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6 d-flex align-items-center">
                                        <div>
                                            {{$t('message.ReturnPurchaseOrder.amountSupplierPaid')}}
                                        </div> 
                                    </div>
                                    <div class="col-6">
                                        <div class="form-control text-right border-none text-primary">
                                            {{ $filters.formatPrice(form.supplierPay) }}
                                        </div>
                                    </div>
                                </div>                        
                                <div class="row pb-2">
                                    <div class="col-4 pr-0">
                                        <label class="container-checkbox">{{$t('message.common.cash')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCashMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox">{{$t('message.common.card')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCardMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox">{{$t('message.common.transferPayment')}}
                                            <input type="checkbox" :value="$constant.paymentChannelTransferMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-4 pr-0">
                                        <div class="input__order">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="form.cashMoney" class="mb-0" :readonly="!paymentChannel.includes($constant.paymentChannelCashMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="form.cardMoney" :readonly="!paymentChannel.includes($constant.paymentChannelCardMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="form.transferMoney" :readonly="!paymentChannel.includes($constant.paymentChannelTransferMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-8">
                                        <label class="container-radio pr-0 min-w-100">
                                            {{$t('message.ReturnPurchaseOrder.amountReturnSupplier')}}
                                            <input type="radio" id="return" :value="$constant.return" v-model="form.typeReturns">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(+form.supplierPay - (+form.totalPrice - +form.discountValue)) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="form.supplierId">
                                    <div class="col-8">
                                        <label class="container-radio pr-0 min-w-100">
                                            {{$t('message.ReturnPurchaseOrder.includedInDebt')}}
                                            <input type="radio" id="debt" :value="$constant.debt" v-model="form.typeReturns">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(+form.supplierPay - (+form.totalPrice - +form.discountValue)) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <InputTextArea :placeholder="$t('message.OrderSupplierIndex.note')" v-model.trim="form.note" class="input__textArea mb-0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                            <div class="row pb-2 card-transaction-button w-100">                            
                                    <div class="col-5">
                                        <button class="mt-2 btn_submit btn btn-save" :disabled="isButtonDisabled" @click="submit($constant.statusOptionsTicketTemporary)">
                                            <i class="fa fa-save"></i>
                                            <div>
                                                {{$t('message.button.saveDraft')}}
                                            </div>
                                        </button>
                                    </div>
                                    <div class="col-7">
                                        <button class="btn btn-success mt-2 btn_submit" :disabled="isButtonDisabled" @click="submit($constant.statusOptionsTicketConfirm)">
                                            <i class="fa fa-check"></i>
                                            <div>
                                                {{$t('message.OrderSupplierIndex.status_complete')}}
                                            </div>
                                        </button>
                                    </div>                                
                            </div>                        
                    </div>
                </div>
                <ModalProductDiscount ref="modal"/>
            </div>
        </div>
    </div>
    <OrderDiscount ref="orderDiscount" :modelValue="form" @update:modelValue="updateDiscount" :discountPercent="form.valuePercent" :discountMoney="form.discountValue" :price="form.totalPrice"/>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement, intVal, rand, removeVietnameseTones} from "@/lib/utils";
    import toastr from 'toastr';
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import QSelect from "../../components/QSelect";
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumber from "../../components/forms/InputNumber";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    import ModalProductDiscount from "./ModalProductDiscount";
    import ErrorLabel from "../../components/ErrorLabel";
    import moment from "moment";
    import ImportForSupplierSearch from "@/components/ImportForSupplierSearch.vue";
    import InputTimePicker from "../../components/forms/InputTimePicker.vue";
    import SupplierSearch from "@/components/SupplierSearch.vue";
    import router from "@/router/index";
    import currencyMixin from "@/currencyMixin";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import store from "../../store";
    import Pagination from "@/components/Pagination";
    import SelectSearch from "@/components/SelectSearch.vue";
    import ChooseConsignment from "@/components/ChooseConsignment.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import MultipleSearchReturn from "@/components/MultipleSearchReturn.vue";
    import OrderDiscount from "@/components/OrderDiscount.vue";
    export default {
        name: "OrderSupplierForm",
        components: {
            ActionBar, InputText, AppPermission, InputSwitchButton, ChooseConsignment, QSelect,
            InputTextArea, InputSelect, InputNumberFormat, InputNumber, InputDatePicker, ModalProductDiscount,
            ErrorLabel, ImportForSupplierSearch, SupplierSearch, InputTimePicker, ProductDiscount, Pagination, SelectSearch, MultipleSearchReturn, OrderDiscount
        },
        mixins: [currencyMixin],
        data() {
            let currentDate = new Date().toJSON().slice(0, 10);
            return {
                form: {
                    products: [],
                    userId: store.state.Auth.user.id,
                    returnDate: currentDate,
                    returnTime: moment().format('HH:mm'),
                    supplierId: null,
                    code: null,
                    ImportCode: null,
                    status: this.$constant.statusOptionsTicketTemporary,
                    TotalQuantity: 0,
                    totalPrice: 0,
                    discountType: null,
                    discountValue: 0,
                    valuePercent: 0,
                    supplierPay: 0,
                    note: null,
                    supplierName: null,
                    importId: null,
                    importForSupplierId: null,
                    cashMoney: 0,
                    cardMoney: 0,
                    transferMoney: 0,
                    branchId: store.state.CurrentBranch.id,
                    typeReturns: this.$constant.return,
                },
                paymentChannel: [this.$constant.paymentChannelCashMoney],
                isEdit: false,
                employees: {
                    all: null,
                    search: store.state.Auth.user.name,
                    filters: null,
                },
                isCopy: false,
                isLinkReturn: false,
                paginate: {
                    totalRecord: 0,
                    currentPage: 1,
                    lastPage: 0,
                },
                numberRecord: 15,
                listItemSelected: this.defaultValue ?? [],
                type: null,
                isButtonDisabled: false
            }
        },
        mounted() {
            document.title = this.$t('message.headers.return_purchase_orders');
        },
        watch: {
            form: {
                handler(dataNew){
                    let TotalPrice = 0;
                    let TotalQuantity = 0;
                    dataNew.products.forEach((product, index) => {
                        if(product.isChangePriceReturn){
                            product.discount = product.discountValue = product.price ? ( product.price - product.priceReturn < 0 ? 0 :  product.price - product.priceReturn) : 0;
                            let changeDiscount = this.$refs["discountProduct" + product.id][0];
                            if(changeDiscount){
                                changeDiscount.updateDiscountParams(product.discountValue);
                            }
                        }else if(product.isChangeDiscount){
                            product.priceReturn =  product.price ? ( product.price - product.discount < 0 ? 0 :  product.price - product.discount) : 0;
                        }else if(product.discountType == this.$constant.discountTypePercent) {
                            product.discount = product.priceReturn * product.valuePercent/100;
                        }
                        // TotalPrice = TotalPrice + product.priceReturn * product.quantity - (product.discount ? product.discount * product.quantity : 0);
                        TotalPrice = TotalPrice + product.priceReturn * product.quantity;
                        TotalQuantity = TotalQuantity + product.quantity;
                        product.productUnitId = product.productUnitId ? product.productUnitId : null;
                        if(product.isConsignment && (!product.consignmentActive || product.consignmentActive?.length == 0)){
                            product.quantity = 0;
                        }else if (product.consignmentActive && product.consignmentActive?.length > 0) {
                            let quantity = 0;
                            product.consignmentActive.forEach(item => {
                               if (item.quantity) {
                                  quantity = +item.quantity + +quantity;
                                  item.checkMaxQuantity = true;
                               }
                            });
                            product.quantity = quantity;
                            product.consignmentsActive = product.consignmentActive;
                        }
                        if (product.plus && product.plus?.length > 0) {
                            product.plus.forEach((plusItem, plusIndex) => {
                                if(product.isConsignment && (!plusItem.consignmentActive || plusItem.consignmentActive?.length == 0)){
                                    plusItem.quantity = 0;
                                }else if (plusItem.consignmentActive && plusItem.consignmentActive?.length > 0) {
                                    let quantity = 0;
                                    plusItem.consignmentActive.forEach(item => {
                                    if (item.quantity) {
                                        quantity = +item.quantity + +quantity
                                    }
                                    });
                                    plusItem.quantity = quantity;
                                    plusItem.consignmentsActive = plusItem.consignmentActive;
                                }

                                if (plusItem.isChangePriceReturn) {
                                    plusItem.discount = plusItem.discountValue = plusItem.price ? (plusItem.price - plusItem.priceReturn < 0 ? 0 : plusItem.price - plusItem.priceReturn) : 0;
                                    let changeDiscount = this.$refs["discountProductPlus" + plusIndex + '_' + index][0] ;
                                    if (changeDiscount) {
                                        changeDiscount.updateDiscountParams(plusItem.discountValue);
                                    }
                                } else if (plusItem.isChangeDiscount) {
                                    plusItem.priceReturn = plusItem.price ? (plusItem.price - plusItem.discount < 0 ? 0 : plusItem.price - plusItem.discount) : 0;
                                } else if (plusItem.discountType == this.$constant.discountTypePercent) {
                                    plusItem.discount = plusItem.priceReturn * plusItem.valuePercent / 100;
                                }

                                //  TotalPrice = TotalPrice + (plusItem.priceReturn - (plusItem.discount ?? 0))* plusItem.quantity;
                                 TotalPrice = TotalPrice + (plusItem.priceReturn)* plusItem.quantity;
                                 TotalQuantity += plusItem.quantity;
                            });
                        }
                        
                    });
                    this.form.totalPrice = TotalPrice;
                    this.form.TotalQuantity = TotalQuantity;
                    if (this.form.discountType == this.$constant.discountTypePercent) {
                        this.form.discountValue = TotalPrice * this.form.valuePercent/100;
                    }
                    this.calculatePayment();
                    this.paginate.totalRecord = dataNew.products.length;
                    this.paginate.lastPage = Math.ceil(dataNew.products.length / this.numberRecord);
                },
                deep: true
            },
            paymentChannel: {
                handler(){
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCashMoney)) {
                        this.form.cashMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCardMoney)) {
                        this.form.cardMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelTransferMoney)) {
                        this.form.transferMoney = 0;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getEmployees();
            if (this.$route.query && this.$route.query.id && this.$route.query.type && this.$route.query.type == 'copy') {                                                
                this.isCopy =  true;
                this.getCopyReturnSupplier  (this.$route.query.id,this.$route.query.type);
            }
            else if (this.$route.query && this.$route.query.id) { 
                // edit return               
                this.form.id = this.$route.query.id;
                this.isEdit = true;                    
                this.getReturnSupplier(this.form.id);                    
            } else if (this.$route.query && this.$route.query.codeImport) {
                // link return
                this.isLinkReturn = true;
                this.getImportSupplierByCode(this.$route.query.codeImport);
            }
        },
        methods: {
            showDiscountForm($event){
                let top = $event.clientY ?? 0;
                this.$refs.orderDiscount.showDiscountForm(top);
            },
            async getCopyReturnSupplier(id, type) {
                const res = await $get('/v1/order-suppliers/return-suppliers/detail', {
                    id: id, type:type
                });
                
                if (res.status.code == 200) {
                    this.form.importForSupplierId = res.result.importForSupplierId ? res.result.importForSupplierId : res.result.id
                    this.form.branchId = res.result.branchId;
                    this.form.supplierId = res.result.supplierId;
                    this.form.ImportCode =  res.result.import_for_supplier.code;
                    this.form.valuePercent = res.result.valuePercent;
                    this.form.supplierName = null;
                    this.form.cashMoney = 0;
                    this.form.cardMoney = res.result.card;
                    this.form.transferMoney = res.result.transfer;
                    this.form.products = res.result.products;
                    this.form.products.forEach(product => {
                        // product.priceReturn = product.product_units[0].price;
                        product.priceReturn = product.price;
                    });
                    this.paymentChannel = [];
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            checkQuantity(quantity, id, e) {
                if(e.key === 'Backspace') return;
                let product = this.form.products.find(item => item.id === id);
                if (typeof quantity != 'number') {
                    product.quantity = 1;
                }
                if (quantity < 0) {
                    product.quantity = 1;
                }
            },
            removeDetailProductPlus(productIdex, plusIndex) {
                this.form.products[productIdex].plus.splice(plusIndex, 1);
            },
            pageChange(page) {
               this.paginate.currentPage = page;
            },
            clearEmployee() {
                this.form.userId = null;
                this.employees.search = null;
            },
            onInputFilterEmployees(){
                if(this.employees.search == ''){
                    this.employees.filters = this.employees.all;
                    this.form.userId = null;
                    return
                }
                let arr = [];
                for (let index = 0; index < this.employees.all.length; index++) {
                    if (removeVietnameseTones(this.employees.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.employees.search.toLowerCase())) > -1){
                        arr.push(this.employees.all[index]);
                    }
                }
                this.employees.filters = arr;

            },
            calculatePayment() {
                this.form.supplierPay = +this.form.cashMoney + +this.form.cardMoney + +this.form.transferMoney;
            },
            updateDiscount(value){
                if(value.dEntry.products){
                    this.form.discountValue = value.model.discountValue;
                    this.form.valuePercent = value.model.valuePercent;
                    this.form.discountType = value.model.discountType;
                }else{
                    this.form.products.forEach(product => {
                        if(product.id === value.dEntry?.id){
                            product.discount = value.model.discountValue;
                            product.valuePercent = value.model.valuePercent;
                            product.discountType = value.model.discountType;
                            product.isChangePriceReturn = false;
                            product.isChangeDiscount = true;
                        }
                    })
                }
            },
            updateDiscountPlus(value) { 
                this.form.products.forEach(product => {
                    if (product.id === value.dEntry?.id) {
                        product.plus[value.index].discount = value.model.discountValue;
                        product.plus[value.index].valuePercent = value.model.valuePercent;
                        product.plus[value.index].discountType = value.model.discountType;
                        product.plus[value.index].isChangePriceReturn = false;
                        product.plus[value.index].isChangeDiscount = true;
                    }
                });  
            },
            changePriceReturn(product){
                product.isChangePriceReturn = true;
                product.isChangeDiscount = false;
            },
            async getReturnSupplier(id) {
                const res = await $get('/v1/order-suppliers/return-suppliers/detail', {
                    id: id,
                });
                
                if (res.status.code == 200) {
                    this.setInfoImport(res);
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            setInfoImport(res) {
                this.form.importForSupplierId = res.result.importForSupplierId ? res.result.importForSupplierId : ''
                this.form.branchId = res.result.import_for_supplier ? res.result.import_for_supplier.branchId: res.result.branchId;
                this.form.supplierId = res.result.supplierId;
                // this.form.ImportCode = this.isCopy === true ? 'TH' + rand(100000,999999) : res.result.code;
                this.form.ImportCode = res.result.import_for_supplier ? res.result.import_for_supplier.code : null;
                this.form.valuePercent = res.result.valuePercent;
                this.form.supplierName = res.result.supplier?.name;
                this.form.cashMoney = res.result.cash;
                this.form.cardMoney = res.result.card;
                this.form.transferMoney = res.result.transfer;
                this.form.products = res.result.products;
                this.form.returnDate = res.result.returnDate,
                this.form.returnTime = moment(this.form.returnDate).format('HH:mm'),
                this.form.note = res.result.note,
                this.form.userId = res.result.userId,
                this.form.discountType = res.result.typeDiscount,
                this.form.discountValue = res.result.priceDiscount,   
                this.form.code = res.result.code,      
                this.form.products.forEach(product => {
                    this.type = product.type;
                    // product.priceReturn = product.product_units[0].price;
                    // product.maxQuantity = product.quantity;
                    product.priceReturn = product.price !== null ? product.price : (product.productUnit ? product.productUnit.price : 0);
                    product.maxQuantity = product.importQuantity ? product.importQuantity : product.quantity;
                    if (product.plus) {
                        product.plus.forEach(plusItem => {
                            plusItem.priceReturn = plusItem.price !== null ? plusItem.price : (plusItem.productUnit ? plusItem.productUnit.price : 0);
                            plusItem.maxQuantity = plusItem.importQuantity ? plusItem.importQuantity : plusItem.quantity;
                        });
                    }
                });
                this.form.typeReturns = res.result.debt === null ? this.$constant.return : this.$constant.debt;
                this.paymentChannel = [];
                if (this.form.cashMoney && this.form.cashMoney != 0 ) {
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                } 
                if (this.form.cardMoney && this.form.cardMoney != 0) {
                    this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                } 
                if (this.form.transferMoney && this.form.transferMoney != 0) {
                    this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                }
            },
            selectEmployee(v) {
                this.form.userId = v.model;
            },
            back() {
                router.push({ name: 'ReturnPurchaseOrder'});
            },
            async getEmployees() {
                const res = await $post('/v1/order-suppliers/get-employees');
                if (res.status.code == 200) {
                    this.employees.all = res.result;
                    this.employees.filters = res.result;
                }
            },
            async getImportSupplierByCode(code) {
                const res = await $get('/v1/order-suppliers/get-import-supplier-by-code', {
                    code: code,
                    // action: this.isLinkReturn ? this.$constant.actionLinkReturnImport : null,
                });
                if (res.status.code == 200) {
                    if ( res.result && res.result.return_imports_count > 0) {
                        $alert({code: 500 , message: this.$t('message.common.haveReturnPODraft')});
                        return;
                    }
                    this.form.importForSupplierId = res.result.importForSupplierId ? res.result.importForSupplierId : res.result.id
                    this.form.branchId = res.result.branchId;
                    this.form.supplierId = res.result.supplierId;
                    this.form.supplierName = res.result.supplier?.name;
                    this.form.products = res.result.products;
                    this.form.ImportCode = res.result.code;
                    this.form.products.forEach(product => {
                        product.priceReturn = product.price !== null ? product.price : (product.productUnit ? product.productUnit.price : 0);
                        product.maxQuantity = product.quantity;
                        if (product.plus) {
                            product.plus.forEach(plusItem => {
                                plusItem.priceReturn = plusItem.price !== null ? plusItem.price : (plusItem.productUnit ? plusItem.productUnit.price : 0);
                                plusItem.maxQuantity = plusItem.quantity;
                            });
                        }
                    });
                }
            },
            onSelectImport(item) {
                this.type = item.type;
                if (item.type == this.$constant.TYPE_SRC_ORDER_SUPPLIER) {
                    this.form.importId = item.entry.id;
                    this.getImportSupplierByCode(item.entry.code);
                } else {
                    let apply = true;
                    if(this.form.products?.length == 0){
                        this.form.products.push(item.entry);
                        this.form.products.forEach(element => {
                            element.id = element.productId;
                            element.quantity = 1;
                        });
                    }else{
                        this.form.products.forEach(value => {
                            if (value.hasVariant) {
                                if(value.variantId == item.entry.variantId){
                                    apply = false;
                                }
                            } else {
                                if(value.productUnitId == item.entry.productUnitId){
                                    apply = false;
                                }
                            }
                            value.id = value.productId;
                        });
                        if(apply){
                            this.form.products.push(item.entry);
                        }
                    }
                }
            },
            showModalDiscountProduct(product) {
                this.$refs.modal.show(product.discount, product.discountType, product.valuePercent, async (value) => {
                    this.updateDiscountProduct(value, product.id)
                });
            },
            showModalDiscountForm(form) {
                this.$refs.modal.show(form.discountValue , form.discountType, form.valuePercent, async (value) => {
                    this.updateDiscountForm(value, form.totalPrice);
                });
            },
            removeDetailProduct(product) {
                if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                    return;
                }
                let itemIndex = -1;
                this.form.products.forEach((item, index) => {
                    if (item.id === product.id) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.form.products.splice(itemIndex, 1);
                }  
            },
            onSelectSupplier(supplier) {
                this.form.supplierId = supplier.id;
            },
            setTimeStart (time) {
                this.form.returnTime = time;
            },
            updateDiscountForm(value, price) {
                this.form.discountType = value.discountType;
                if (value.discountType == this.$constant.discountTypePercent) {
                    this.form.discountValue = price*value.valuePercent/100;
                    this.form.valuePercent = value.valuePercent;
                } else {
                    this.form.discountValue = value.discount;
                }
            },
            async submit(status) {
                if (this.form.products.length === 0) {
                    toastr.error(this.$t('message.common.isEmptyPO'));
                    return;
                }
                for (let index = 0; index < this.form.products.length; index++) {
                    const products = this.form.products;
                    if (this.type == 1) {
                        if (products[index].quantity > products[index].maxQuantity) {
                            toastr.error(this.$t('message.common.invalidReturnPOQuantity', {name: products[index].name}));
                            return;
                        } 
                        if (products[index].plus) {
                            for (let plusIndex = 0; plusIndex < products[index].plus.length; plusIndex++) {
                                const plus = products[index].plus[plusIndex];
                                if (plus.quantity > plus.maxQuantity) {
                                    toastr.error(this.$t('message.common.invalidReturnPOQuantity', {name: products[index].name}));
                                    return;
                                }
                            }
                        }   
                    }
                }
                this.form.returnDateFormat = moment(this.form.returnDate).format("YYYY-MM-DD") + ' ' + this.form.returnTime;
                const params = {
                    importId: this.form.importId,
                    products: this.form.products,
                    userId: this.form.userId,
                    returnDate: this.form.returnDateFormat,
                    branchId : this.form.branchId,
                    supplierId: this.form.supplierId,
                    code: this.form.code?.trim(),
                    ImportCode: this.form.ImportCode,
                    status: status,
                    TotalQuantity: this.form.TotalQuantity,
                    totalPrice: this.form.totalPrice,
                    typeDiscount: this.form.discountType,
                    discountValue: this.form.discountValue,
                    supplierPay: this.form.supplierPay,
                    note: this.form.note,
                    importForSupplierId: this.form.importForSupplierId,
                    cash: this.form.cashMoney,
                    card: this.form.cardMoney,
                    transfer: this.form.transferMoney,
                    typeReturns: this.form.typeReturns,
                };
                this.isButtonDisabled = true;
                let res;
                if (!this.isEdit) {
                    res = await $post('/v1/order-suppliers/create-return-import', {...params});
                } else {
                    params.id = this.form.id;
                    res = await $post('/v1/order-suppliers/update-return-import', {...params});
                }
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } else if (res.status.code == 200 && res.result.status) {
                    $alert({code: 200, message: !this.isEdit ? this.$t('message.common.createReturnPOSuccess') : this.$t('message.common.updateReturnPOSuccess')});
                    this.$router.push({name: 'ReturnPurchaseOrder'});
                } else if (!res.result.status) {
                    $alert({code: 500, message: res.result.message});
                    this.isButtonDisabled = false;
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            updateDiscountProduct(value, productId) {
                this.form.products.forEach((item) => {
                    if (item.id === productId) {
                        item.discountType = value.discountType;
                        if (value.discountType == this.$constant.discountTypePercent) {
                            item.discount = Math.round(item.product_units[0].price * item.quantity * value.valuePercent /100);
                            item.valuePercent = value.valuePercent;
                        } else {
                            item.discount = value.discount;
                        }
                    }
                });
            },
            updateConsignment(data){
                this.form.products.forEach(product => {
                    if (product.id == data.object.id) {
                        product.consignmentActive = data.data;
                        if(product.consignmentActive?.length == 0){
                            product.quantity = 0;
                        }
                    }
                });
            },
            updateConsignmentProductUnit (data) {
                this.form.products[data.index].plus[data.plusIndex].consignmentActive = data.data;
            },
            updateProductUnit(product) {
                if (product.product_units && product.product_units.length) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = null;
                    product.discountValue = 0;
                    product.price = product.productUnit.price;  
                }
            },
            addProductPlus (productIndex, product) {
                let plus = {
                    productUnitId: null,
                    quantity: 0,
                    price: 0,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    isConsignment: product.isConsignment,
                    consignmentActive: product.consignmentActive
                }
                if (!this.form.products[productIndex].plus) {
                    this.form.products[productIndex].plus = [];
                }
                plus.index = this.form.products[productIndex].plus.length;
                this.form.products[productIndex].plus.push(plus);
            }
        },
    }
</script>

<style scoped>
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .custom__select {
        height: 35px;
        border: none;
        border-bottom: 1px solid #C7C5C5;
        color: #495057;
    }
    .format__list-time {
        top: 0 !important;
        left: 0 !important;
        width: 220px !important;
        height: 160px;
        overflow: auto;
    }
    .input__order .form-control {
        border: none;
        border-bottom: solid 1px #ccc;
    }
    .custom__show-time {
        height: 32px;
        border-bottom: solid 1px #ccc;
        margin-bottom: 15px;
    }
    .main__content {
        margin: 10px 30px;
    }
    .input__order, input {
        width: 90%;
    }
    .input__order {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    input[type=checkbox], input[type=radio] {
        width: 10% !important;
    }
    .input__textArea textarea {
        border: none;
        resize: none;
        border-bottom: solid 1px #ccc;
    }
    .far {
        position: absolute;
        right: 25px;
        top: 11px;
        font: normal normal normal 14px/1;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    /* .noteOrder{
        position: absolute;
        top: 0px;
        left: 0px;
    } */
    #typeCard {
        border: none;
        box-shadow: none;
    }
    .block__quantity-max {
        position: absolute;
        right: 15px;
        bottom: -6px;
    }
    .block__quantity {
        position: relative;
    }
    .main-title {
        width: 75%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .table-transaction thead th, .table-transaction tbody td{
        padding-top: 10px !important;
        vertical-align: top !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    /* .title{
        display: flex;
        align-items: center;
    } */
   

    @media screen and (max-width: 1366px) and (min-width: 768px){
        #supplierName {
            height: 89px !important;
            width: 120px !important;
            padding: 0 !important;
            /* text-align: justify !important; */
            padding-right: 2px !important;
        }
        #styleStatus {
            margin-top: 50px !important ;
        }
    }
</style>
