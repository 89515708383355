<template>
 <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog" :class="(errors?.length > 0) ? 'modal-dialog-scrollable' : 'modal-lg'" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h5 class="modal-title py-2">
                        {{ $t('message.VoucherIndex.modal.create_or_update.button.cancel_voucher') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2"  :class="this.errors?.length > 0 ? 'bodyModal' : '' " >
                    <!-- Bạn có chắc chắn muốn hủy các voucher này không? -->
                     <div class="tab-content" id="myTabContent" v-if="this.errors?.length > 0">
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-12 pl-0">
                                    {{ $t('message.common.cancelVoucherError') }}:
                                </div>
                            </div>
                            <div class="row mx-4 my-0 mt-2" v-for="error in this.errors" :key="error.id">
                                <div class="col-12 p-0">
                                    - {{ error.code }}
                                </div>
                            </div>
                          
                        </div>
                    </div>
                     <div class="tab-content" id="myTabContent" v-else>
                        <div class="tab-pane fade show active">
                            {{$t('message.common.confirmCancelVoucher')}}
                            <div class="row mx-4 my-4 pb-0">
                                <div class="col-12 d-flex justify-content-end mt-2">
                                    <button class="btn btn-danger mr-2" @click="cancelVoucher()"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                                    <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                     <!-- footer -->
                <div class="modal-footer my-0 py-0 border-top-0" v-if="this.errors?.length > 0" style="height:50px;">
                    <div class="row mx-4 my-4 pb-0">
                        <div class="col-12 d-flex justify-content-end p-0">
                            <button class="btn btn-save" @click="hide">
                            <i class="fa fa-check-square mr-2"></i
                            >{{$t('message.button.confirm')}}
                            </button>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalCancelVoucher',
    props: {},
    data () {
        return {
            id: '',
            name: '',
            errors: [],
        };
    },
    methods: {
        async show(id = null) {
            this.clearData();
            this.id = id;            
            this.voucherId =  id;
            if (id) {
                const res = await $post('/v1/vouchers/check-status-vouchers', {ids: id, status: this.$constant.statusNotUsedYetVoucherCoupon});
                if(res && res.result && res.result?.length > 0){
                    this.errors = res.result;
                }
                if(this.errors?.length == 0){
                    // this.releaseVoucher.quantity = id?.length;
                      this.quantity = id?.length;
                }
                window.jQuery(this.$refs.modal).modal('show');
            }                        
        },   
         hide () {
            window.jQuery(this.$refs.modal).modal('hide');
        },   
        async cancelVoucher(){
                const res = await $post('/v1/vouchers/cancel-vouchers', {ids: this.voucherId});  
                
                if(res && res.status.code == 200 && res.result.code == 200){                
                    toastr.success(this.$t('message.common.cancelVoucherSuccess'));
                    window.jQuery(this.$refs.modal).modal('hide');
                    this.$emit('reloadVoucher');
                    this.$emit('removeChecked');
                }
                else {                
                    if(res && res.result.code == 201) {
                        toastr.error(res.result.message);
                    }
                    else {
                        toastr.error(this.$t('message.common.cancelVoucherFailed'));
                    }
                }
        },
        clearData(){
              this.errors = [];
        }
    }
}
</script>
<style>
</style>