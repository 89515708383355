<template>
    <div class="row">
        <ActionBar :hideButtonSave="hideButtonSave" back-url="/branches/index" @reset="load" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>{{showTitle()}}</h1>
            </div>
            <TabNavigation @tabChange="tabChange"
                           @onValidate="onValidate"
                           ref="tab" :tabs="tabs"
                           :id="id"/>

        </div>
    </div>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import InputLocationPicker from "../../components/forms/InputLocationPicker";
    import * as toastr from 'toastr';
    import TabNavigation from "@/components/TabNavigation";
    import TabInfo from "./tabs/TabInfo";
    import TabUser from "./tabs/TabUser";
    import {markRaw} from "vue";
    import {scrollToErrors, setArray} from "../../lib/utils";

    export default {
        name: "BranchForm",
        components: {TabNavigation, InputLocationPicker, ActionBar, InputText, InputSwitchButton},
        data() {
            const tabs = this.$route.query.id ? [
                {id: 'TabInfo', name: 'Thông tin', component: markRaw(TabInfo)},
                {id: 'TabUser', name: 'Người dùng', component: markRaw(TabUser)},
            ] : [
                {id: 'TabInfo', name: 'Thông tin', component: markRaw(TabInfo)},
            ]
            return {
                tabs: tabs,
                id: this.$route.query.id || null,
                location: {
                    districtId: null,
                    provinceId: null,
                    wardId: null,
                },
                title: '',
                isDataLoaded: false,
                entry: {},
                errors: {
                    location: []
                },
                hideButtonSave: true
            }
        },
        mounted() {
            document.title = 'BranchForm | OmiPos';
        },
        methods: {
            showTitle() {
                if (this.$route.query.id) {
                    return "Sửa thông tin Chi nhánh";
                }
                return "Thêm thông tin Chi nhánh";
            },
            tabChange(tab) {
                this.currentTab = tab;                
                if (tab.id == 'TabUser') {
                    this.hideButtonSave = true;
                } else {
                    this.hideButtonSave = false;
                }
                if (this.$route.query.id) {
                    this.load();
                } else {
                    this.setTabData({
                        isDataLoaded: true
                    })
                }
            },
            setTabData(values) {
                this.$refs.tab.setTabData(values);
            },
            getTabData(key) {
                return this.$refs.tab.getTabData(key)
            },
            onValidate() {
                const entry = this.$refs.tab.getTabData('entry');
                const location = this.$refs.tab.getTabData('location');
                this.validateTabInfo(entry, location);
            },

            validateTabInfo(entry, location) {
                entry.errors = {};
                if (!location.provinceId) {
                    entry.errors.provinceId = ['Vui lòng nhập tỉnh'];
                    return false;
                }

                if (!location.districtId) {
                    entry.errors.districtId = ['Vui lòng nhập Quận/Huyện'];
                    return false;
                }

                if (!location.wardId) {
                    entry.errors.wardId = ['Vui lòng nhập Phường/Xã'];
                    return false;
                }

                return true;
            },
            async saveTabInfo() {
                this.errors = {};
                const tab = this.$refs.tab;
                const entry = tab.getTabData('entry');
                const location = tab.getTabData('location');
                entry.provinceId = location.provinceId;
                entry.districtId = location.districtId;
                entry.wardId = location.wardId;

                if (!this.validateTabInfo(entry, location)) {
                    toastr.error('Vui lòng kiểm tra dữ liệu');
                    scrollToErrors();
                    return;
                }

                const res = await $post('/v1/branches/save', {
                    entry: entry
                });

                $alert(res);
            },
            async load() {
                const res = await $get('/v1/branches/show', {
                    id: this.$route.query.id
                });
                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }
                this.entry = res.data.entry;
                this.location.provinceId = this.entry.provinceId;
                this.location.districtId = this.entry.districtId;
                this.location.wardId = this.entry.wardId;

                this.isDataLoaded = true;
                this.setTabData({
                    entry: res.data.entry,
                    isDataLoaded: true,
                    tabUsers: res.data.users,
                    location: this.location
                });
            },
            async save() {
                this.saveTabInfo();
            }
        }
    }
</script>

<style scoped>

</style>
