<template>
    <div class="container mt-0">
        <div class="row mt-4">
            <div class="col-12">
                <a href="/sale-online/e-commerce/list" class="text-primary text-decoration-none"><b>{{$t('message.common.linkToECommerce')}}</b></a><i class="fa fa-chevron-right ml-2 cursor-pointer text-secondary mr-2"></i>{{ $t('message.common.settingSync') }}
            </div>
        </div>
        <div class="row mt-1 d-block setting-sync-content">
            <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>{{ $t('message.common.branchSync') }}</b></h5>
                        <div class="setting-sync-des text-gray">{{ $t('message.common.branchSyncDesc') }}</div>
                    </div>
                    <div class="setting-sync-option">
                        <InputSelectSearch :modelValue="entry.branchId" :defaultOption="branches" :noSearch="true" @update:modelValue="setBranch"></InputSelectSearch>
                    </div>
                </div>
            </div>
            <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>{{ $t('message.common.syncOrder') }}</b></h5>
                        <div class="setting-sync-des text-gray">{{ $t('message.common.syncOrderDesc') }}</div>
                    </div>
                    <span class="toggle" :class="entry.syncOrder === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textSyncOrder)"></span>
                </div>
                <div class="sync-inserted" v-if="entry.syncOrder === this.$constant.textActive">
                    <div class="d-flex flex-column setting-sync-block">
                        <div class="d-flex align-items-center setting-list-dropdown">
                            <div class="text-gray mr-4 dropdown-title">{{ $t('message.common.onlySyncOrderWhen') }}</div>
                            <div class="setting-sync-option">
                                <InputSelectSearch :modelValue="detailInfoSync.syncStatusOrder" :defaultOption="this.$constant.statusSyncOrder" :noSearch="true" @update:modelValue="setSyncStatusOrder"></InputSelectSearch>
                            </div>
                            <div class="text-gray mr-4 ml-4">{{ $t('message.common.from') }}</div>
                            <div class="setting-sync-option">
                                <InputSelectSearch :modelValue="detailInfoSync.syncTime" :defaultOption="this.$constant.timeSyncOrder" :noSearch="true" @update:modelValue="setSyncTime"></InputSelectSearch>
                            </div>
                        </div>
                        <div class="setting-sync-des mt-3 text-gray"> {{ $t('message.common.syncOrderNote') }} </div>
                        <div class="d-flex align-items-center setting-list-dropdown">
                            <div class="text-gray mr-4 dropdown-title">{{ $t('message.common.sellTime') }}</div>
                            <div class="setting-sync-option">
                                <InputSelectSearch :modelValue="detailInfoSync.syncTimeSale" :defaultOption="this.$constant.syncTimeSale" :noSearch="true" @update:modelValue="setSyncTimeSale"></InputSelectSearch>
                            </div>
                        </div>
                        <div class="d-flex align-items-center setting-list-dropdown">
                            <div class="text-gray mr-4 dropdown-title">{{ $t('message.common.productNoLink') }}</div>
                            <div class="setting-sync-option">
                                <InputSelectSearch :modelValue="detailInfoSync.syncProductLink" :defaultOption="this.$constant.syncProductLink" :noSearch="true" @update:modelValue="setSyncProductLink"></InputSelectSearch>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between setting-item-des sync-inserted" v-if="entry.syncOrder === this.$constant.textActive">
                    <div class="d-flex flex-column">
                        <div class="font-weight-bold">{{ $t('message.common.confirmDelivery') }}</div>
                        <div class="setting-sync-des text-gray mt-2">{{ $t('message.common.confirmDeliveryDesc') }}</div>
                    </div>
                </div>
            </div>
            <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>{{ $t('message.common.syncSaleQuantity') }}</b></h5>
                        <div class="setting-sync-des text-gray">{{ $t('message.common.autoSyncInventory') }} <span class="text-capitalize">{{ this.textTypeChannel }}</span></div>
                    </div>
                    <span class="toggle" :class="entry.syncOrderQuantity === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textSyncOrderQuantity)"></span>
                </div>
                <div class="d-flex align-items-center justify-content-between setting-item-des sync-inserted" v-if="entry.syncOrderQuantity === this.$constant.textActive">
                    <div class="d-flex flex-column">
                        <div class="font-weight-bold">{{$t('message.common.formulaSyncQuantity')}}</div>
                        <div class="d-flex align-items-center setting-list-dropdown">
                            <div class="setting-sync-des text-gray mt-2 mr-4">{{ $t('message.common.setValueQuantity') }} <span class="text-capitalize">{{ this.textTypeChannel }}</span> {{ $t('message.common.fromFormuala') }}</div>
                        </div>
                    </div>
                    <div class="setting-sync-option">
                        <InputSelectSearch :modelValue="detailInfoSync.syncSaleQuantity" :defaultOption="this.$constant.syncSaleQuantity" :noSearch="true" @update:modelValue="setSyncSaleQuantity"></InputSelectSearch>
                    </div>
                </div>
                <div class="d-flex align-items-center justify-content-between setting-item-des sync-inserted" v-if="entry.syncOrderQuantity === this.$constant.textActive">
                    <div class="d-flex flex-column">
                        <div class="setting-sync-des text-gray mt-2"> {{ $t('message.common.distributionOfSaleQuantity') }}</div>
                    </div>
                    <span class="toggle" :class="detailInfoSync.syncQuantityAllocation === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textSyncQuantityAllocation)"></span>
                </div>
            </div>
            <!-- <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>{{$t('message.common.syncPrice')}}</b></h5>
                        <div class="setting-sync-des text-gray">Tự đồng bộ giá bán từ Omipos lên <span class="text-capitalize">{{ this.textTypeChannel }}</span></div>
                    </div>
                    <span class="toggle" :class="entry.syncPrice === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textSyncPrice)"></span>
                </div>
                <div class="d-flex align-items-center justify-content-between setting-item-des sync-inserted" v-if="entry.syncPrice === this.$constant.textActive">
                    <div class="d-flex flex-column">
                        <div class="font-weight-bold">Bảng giá bán</div>
                        <div class="d-flex align-items-center setting-list-dropdown">
                            <div class="setting-sync-des text-gray mt-2 mr-4">Giá hàng hóa trên bảng giá được chọn sẽ được đồng bộ tương ứng lên hàng hóa đã liên kết trên <span class="text-capitalize">{{ this.textTypeChannel }}</span> như là Giá bán niêm yết</div>
                        </div>
                    </div>
                    <div class="setting-sync-option">
                        <InputSelectSearch :modelValue="detailInfoSync.syncPriceList" :defaultOption="priceLists" :noSearch="true" @update:modelValue="setSyncPriceList"></InputSelectSearch>
                    </div>
                </div>
            </div> -->
            <!-- <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>Tự động sao chép hàng hóa khi có hàng hóa mới tạo trên sàn</b></h5>
                        <div class="setting-sync-des text-gray">Tự động sao chép và liên kết hàng hóa mới tạo trên sàn về Omipos.</div>
                    </div>
                    <span class="toggle" :class="entry.autoCopyProduct === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textAutoCopyProduct)"></span>
                </div>
            </div>
            <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>Tự động liên kết hàng hóa trên sàn với hàng hóa trên Omipos khi trùng SKU</b></h5>
                        <div class="setting-sync-des text-gray">Bạn cần thiết lập SKU của hàng hóa trên sàn trùng với Mã của hàng hóa trên Omipos để hệ thống tự động liên kết với nhau</div>
                    </div>
                    <span class="toggle" :class="entry.autoMappingProduct === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textAutoMappingProduct)"></span>
                </div>
            </div> -->
            <!-- <div class="setting-sync-item">
                <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex flex-column">
                        <h5><b>Xóa liên kết hàng hóa nếu Mã hàng - SKU thay đổi</b></h5>
                        <div class="setting-sync-des text-gray">Khi bật tính năng hệ thống sẽ tự động xóa liên kết hàng hóa nếu có thay đổi SKU trên sàn hoặc mã hàng trên Omipos.</div>
                    </div>
                    <span class="toggle" :class="entry.removeMappingProduct === this.$constant.textActive ? 'active' : ''" @click="changeOption(this.$constant.textRemoveMappingProduct)"></span>
                </div>
            </div> -->
        </div>
        <div class="setting-sync-footer btn-sync-end sync-inserted">
            <div class="btn btn-lg btn-outline btn-outline-primary btn-icon-left" @click="cancel()">{{$t('message.button.cancel')}}</div>
            <div class="btn btn-lg btn-primary btn-icon-left" @click="save()"><i class="fa fa-check text-white mr-2"></i>{{ $t('message.button.save') }}</div>
        </div>
    </div>
</template>

<script>
    import {$get, $post} from "../../lib/utils";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import store from "../../store";
    import toastr from 'toastr';
    export default {
        name: "SettingConnect",
        components: {InputSelectSearch},
        // mounted() {
            
        // },
        created() {
            if(this.$route.query.channelId){
                switch (+this.$route.query.channelId) {
                    case this.$constant.shopeeId:
                        this.textTypeChannel = this.$constant.textShopee;
                        break;
                    case this.$constant.lazadaId:
                        this.textTypeChannel = this.$constant.textLazada;
                        break;
                    case this.$constant.tikiId:
                        this.textTypeChannel = this.$constant.textTiki;
                        break;
                    case this.$constant.tiktokId:
                        this.textTypeChannel = this.$constant.textTiktok;
                        break;
                    case this.$constant.sendoId:
                        this.textTypeChannel = this.$constant.textSendo;
                        break;
                }
            }
            this.getPriceList();
            this.getInfoSetting();
        },
        data() {
            return {
                entry: {
                    id: null,
                    branchId: store.state.CurrentBranch.id,
                    syncOrder: this.$constant.textInActive,
                    syncOrderQuantity: this.$constant.textInActive,
                    syncPrice: this.$constant.textInActive,
                    autoCopyProduct: this.$constant.textInActive,
                    autoMappingProduct: this.$constant.textInActive,
                    removeMappingProduct: this.$constant.textInActive,
                },
                detailInfoSync: {
                    syncStatusOrder: 0,
                    syncTime: 0,
                    syncTimeSale: 0,
                    syncProductLink: 0,
                    syncSaleQuantity: 0,
                    syncPriceList: 0,
                    syncQuantityAllocation: this.$constant.textInActive,
                },
                branches: store.state.GlobalConfig.branches,
                priceLists: [],
                textTypeChannel: null,
                syncOrderQuantityDefault: this.$constant.textInActive,
            }
        },
        methods: {
            async getPriceList() {
                const res = await $get('/v1/setting-price/get-price-lists');
                // const res = await $get('/v1/setting-price/get-price-list');
                if (res && res.status.code == 200) {
                    this.priceLists = res.result;
                    this.priceLists.unshift(this.$constant.priceListCommon);
                } 
            },
            changeOption(o){
                switch (o) {
                    case this.$constant.textSyncOrder:
                        this.entry.syncOrder = this.entry.syncOrder === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textSyncPrice:
                        this.entry.syncPrice = this.entry.syncPrice === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textSyncOrderQuantity:
                        this.entry.syncOrderQuantity = this.entry.syncOrderQuantity === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textAutoCopyProduct:
                        this.entry.autoCopyProduct = this.entry.autoCopyProduct === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textAutoMappingProduct:
                        this.entry.autoMappingProduct = this.entry.autoMappingProduct === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textRemoveMappingProduct:
                        this.entry.removeMappingProduct = this.entry.removeMappingProduct === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                    case this.$constant.textSyncQuantityAllocation:
                        this.detailInfoSync.syncQuantityAllocation = this.detailInfoSync.syncQuantityAllocation === this.$constant.textActive ? this.$constant.textInActive : this.$constant.textActive;
                        break;
                }
            },
            setBranch(branchId){
                this.entry.branchId = branchId;
            },
            setSyncStatusOrder(syncStatusOrder){
                this.detailInfoSync.syncStatusOrder = syncStatusOrder;

            },
            setSyncTime(syncTime){
                this.detailInfoSync.syncTime = syncTime;

            },
            setSyncTimeSale(syncTimeSale){
                this.detailInfoSync.syncTimeSale = syncTimeSale;

            },
            setSyncProductLink(syncProductLink){
                this.detailInfoSync.syncProductLink = syncProductLink;

            },
            setSyncSaleQuantity(syncSaleQuantity){
                this.detailInfoSync.syncSaleQuantity = syncSaleQuantity;

            },
            setSyncPriceList(syncPriceList){
                this.detailInfoSync.syncPriceList = syncPriceList;
            },
            async cancel(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/list',
                    query: {}
                });
            },
            async getInfoSetting()
            {
                if (this.$route.query.shopId && this.$route.query.channelId) {
                    const res = await $get('/v1/connection/get-info-shop', {
                        shopId: this.$route.query.shopId,
                        channelId : this.$route.query.channelId,
                    });
                    if(res && res.result){
                        this.entry.id = res.result.id;
                        this.entry.branchId = res.result.branchId ? res.result.branchId : store.state.CurrentBranch.id;
                        this.entry.syncOrder = (res.result.syncOrder == this.$constant.textActive && res.result.syncOrder) ? this.$constant.textActive : this.$constant.textInActive;
                        this.entry.syncOrderQuantity = (res.result.syncOrderQuantity == this.$constant.textActive  && res.result.syncOrderQuantity) ? this.$constant.textActive : this.$constant.textInActive;
                        this.syncOrderQuantityDefault = (res.result.syncOrderQuantity == this.$constant.textActive  && res.result.syncOrderQuantity) ? this.$constant.textActive : this.$constant.textInActive;
                        this.entry.syncPrice = (res.result.syncPrice == this.$constant.textActive && res.result.syncPrice) ? this.$constant.textActive : this.$constant.textInActive;
                        this.entry.autoCopyProduct = (res.result.autoCopyProduct == this.$constant.textActive && res.result.autoCopyProduct) ? this.$constant.textActive : this.$constant.textInActive;
                        this.entry.autoMappingProduct = (res.result.autoMappingProduct == this.$constant.textActive && res.result.autoMappingProduct) ? this.$constant.textActive : this.$constant.textInActive;
                        this.entry.removeMappingProduct = (res.result.removeMappingProduct == this.$constant.textActive && res.result.removeMappingProduct) ? this.$constant.textActive : this.$constant.textInActive;
                        
                        if(res.result.details){
                            this.detailInfoSync.syncStatusOrder = res.result.details.syncStatusOrder ?? 0;
                            this.detailInfoSync.syncTime = res.result.details.syncTime ?? 0;
                            this.detailInfoSync.syncTimeSale = res.result.details.syncTimeSale ?? 0;
                            this.detailInfoSync.syncProductLink = res.result.details.syncProductLink ?? 0;
                            this.detailInfoSync.syncSaleQuantity = res.result.details.syncSaleQuantity ?? 0;
                            this.detailInfoSync.syncPriceList = res.result.details.syncPriceList ?? 0;
                            this.detailInfoSync.syncQuantityAllocation = res.result.details.syncQuantityAllocation ?? 0;
                        }
                    
                    }
                }
            },
            async save(){
                const res = await $post('/v1/connection/update-connect-shop', {
                    entries: this.entry,
                    details : this.detailInfoSync,
                });
                if(res && res.result){
                    if(!res.result.status){
                        toastr.error(res.result.message);
                    }else{
                        toastr.success(res.result.message);
                        
                        if(this.entry.syncOrderQuantity == this.$constant.textActive && this.syncOrderQuantityDefault == this.$constant.textInActive){
                            
                            switch (this.textTypeChannel) {
                                case this.$constant.textShopee:
                                    await $post('/v1/shopee/update-stock-realTime-in-shop', {
                                        id: this.entry.id,
                                    });
                                    break;
                                case this.$constant.textLazada:
                                    break;
                                case this.$constant.textTiki:
                                    break;
                                case this.$constant.textTiktok:
                                    break;
                                case this.$constant.textSendo:
                                    break;
                            }
                        }
                    }
                    this.cancel();
                }
            }
        }
    }
</script>
<style scoped lang="scss">
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.sidebar-item {
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}
.sidebar-item.active{
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.mainRight{
    padding: 1.2rem 3rem;
}

.setting-sync-item {
    background-color: #f5f6f8;
    overflow: hidden;
    margin-top: 1rem;
    padding: 1rem 2rem;
    border-radius: 10px;
}
.setting-sync-item:first-child {
    margin-top: 0;
}
.setting-sync-content .setting-sync-item {
    overflow: unset;
}
.setting-sync-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}
.toggle {
    background-color: rgba(31,32,33,.15);
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 14px;
    width: 51px;
    height: 28px;
    transition: all .2s ease-in-out;
}
.toggle.active {
    background-color: rgb(47, 161, 161);
}
.toggle:before {
    content: "";
    background-color: #fff;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all .2s ease-in-out;
}
.toggle.active:before {
    left: auto;
    right: 2px;
    transition: all .2s ease-in-out;
}
.setting-sync-block .setting-list-dropdown {
    margin-top: 1rem;
}
.setting-item-des {
    border-top: 1px solid #d7d9de;
    padding-top: 1rem;
    margin-top: 1rem;
}
.btn-sync-end {
    justify-content: flex-end;
}
.setting-sync-footer {
    align-items: center;
    padding-top: 1rem;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.setting-sync-option{
    min-width: 200px !important;
}
.dropdown-title{
    min-width: 300px !important;
}
</style>