<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" :class="this.disabledDeparment && this.disabledDeparment === true ? 'modal-second' : ''">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.title_create') }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-3">
                                            <b>
                                                {{ $t('message.DepartmentIndex.table.code') }}
                                                <label class="text-danger px-0">*</label> 
                                            </b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <input type="text" v-model="form.code"  
                                                :placeholder="$t('message.DepartmentIndex.modal.createOrUpdate.placeholder.code')" 
                                                class="form-control" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <b>
                                                {{ $t('message.DepartmentIndex.modal.createOrUpdate.name') }}
                                                <label class="text-danger px-0">*</label> 
                                            </b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="form.name"  
                                                :placeholder="$t('message.DepartmentIndex.modal.createOrUpdate.placeholder.name')" 
                                                class="form-control" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{ $t('message.DepartmentIndex.modal.createOrUpdate.description') }}</b>
                                        </div>
                                        <div class="col-9 description-job-title">
                                            <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                            <input type="text" name="description" v-model="form.note" class="form-control" autocomplete="off">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{ $t('message.DepartmentIndex.modal.createOrUpdate.status') }}</b>
                                        </div>
                                        <div class="col-4 d-flex align-items-center">
                                            <label class="container-radio ml-2 min-vw-100">{{ $t('message.DepartmentIndex.modal.createOrUpdate.active') }}
                                                <input type="radio" name="status" v-model="form.status" :value="1" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-5 d-flex align-items-center">
                                            <label class="container-radio ml-2 min-vw-100">{{ $t('message.DepartmentIndex.modal.createOrUpdate.in_active') }}
                                                <input type="radio" name="status" v-model="form.status" :value="0" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <AppPermission :permission="this.$permissions.departments.update ? this.$permissions.departments.update : (this.$permissions.departments.create ? this.$permissions.departments.create : '' ) ">
                                        <button type="button" class="btn btn-save mr-2" @click="submit">
                                            <i class="fa fa-save mr-1"></i>
                                            {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.save') }}
                                        </button>                                   
                                    </AppPermission> 
                                    <button type="button" class="btn btn-secondary text-white  mr-2" @click="clearInput">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()"  :title="$t('message.DepartmentIndex.modal.createOrUpdate.title_confirm')" :content="$t('message.DepartmentIndex.modal.createOrUpdate.content_confirm_create')"/>
</template>
<script>
    import InputText from "@/components/forms/InputText";
    import {$post, $alert, scrollToElement} from "@/lib/utils";
    import toastr from 'toastr';
    import ModalConfirm from "@/components/ModalConfirm.vue";
    import emitter from "@/lib/emitter";
    export default {
        name: "ModalCreateDepartment",
        components: { InputText , ModalConfirm },
        props: {
            openModal: {
                type: Boolean,
               
                default: false
            },
             disabledDeparment:Boolean,
        },
        data () {
            return {
            active: 1,
            form: {},
            errors: {}
            }
        },
        mounted() {
            this.form.status = 1;
        },
        methods: {
            show () {                
                this.setValue();
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            clearInput() {
                if (this.form.name != '' || this.form.note != '') {
                    this.$refs.ModalConfirm.show();
                } else {
                    this.setValue();
                    window.jQuery(this.$refs.modal).modal('hide');
                }
            },
            setValue () {
                this.form = {
                    name : '',
                    note: '',
                    code: '',
                    status: 1,
                };
            },
            async submit() {
                const result = await $post('/v1/departments/store', this.form);
                if(result.code === 422){
                    let errors = result.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: result.code, message: errors[key][0]});
                    });
                    return;
                }
                if (result.code === 512 || result.code === 400) {
                    this.errors = result.errors;
                    scrollToElement('.error-label');
                    $alert(result);
                } else {
                    toastr.success(this.$t('message.DepartmentIndex.modal.createOrUpdate.alerts.create_success'));
                    emitter.emit('reloadDataEmployee');
                    window.jQuery(this.$refs.modal).modal('hide');
                    this.$emit('updated', result);
                    this.$emit('inputData','1');
                }
            }
        }
    }
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
</style>