<template>
       <div class="customer-tab tab-info">
        <template v-if="!isModal">
            <ActionBar back-url="/customers/index" @action="save()"/>
            <div class="title">

            </div>
        </template>

        <div class="content">
            <div class="row">
                <div class="col-6">
                    <!-- Start name -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.branch_name') }}<label class="text-danger px-0">*</label> </b>
                        </div>
                        <div class="col-9">
                            <InputText v-model.trim="entry.name" :errors="errors.name" :placeholder="$t('message.branchIndex.placeholder.name')"/>
                        </div>
                    </div>
                    <!-- End name -->

                    <!-- Start birthday and gender -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.branch_phone') }}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                          <InputText v-model="entry.phone" :errors="errors.phone" :placeholder="$t('message.branchIndex.placeholder.phone')" :max=12 :cusNumber="true"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <b>{{ $t('message.branchIndex.branch_email') }}</b>
                        </div>
                        <div class="col-md-9">
                            <InputText v-model="entry.email" :errors="errors.email" :placeholder="$t('message.branchIndex.placeholder.email')"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.branch_address') }}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <InputTextArea v-model="entry.address" :placeholder="$t('message.branchIndex.placeholder.address')"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.branch_work_day') }}</b>
                        </div>
                        <div class="col-9">
                            <template v-for="day in this.$constant.dayOfWeeks" :key="day.id">
                                <button class="btn" :class="isExistInArray(day.id) == true ? 'btn-save ml-0 mr-1' : 'btn-default mr-1'" @click="changeDayOfWork(day.id)">{{day.name}}</button> 
                            </template>
                               
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row mb-3">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.modal.branch_province') }}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <q-select v-model="entry.provinceId" :default-options="provinces" :multiple="false" :placeholder="$t('message.branchIndex.placeholder.province')" @update:modelValue="changeProvince" ref="inputProvinces"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.modal.branch_district') }}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <q-select v-model="entry.districtId" :default-options="districts" :multiple="false" :placeholder="$t('message.branchIndex.placeholder.district')" @update:modelValue="changeDistrict" ref="inputDistricts"/>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-3">
                            <b>{{ $t('message.branchIndex.modal.branch_ward') }}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <q-select v-model="entry.wardId" :default-options="wards" :multiple="false" :placeholder="$t('message.branchIndex.placeholder.ward')" ref="inputWards"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import InputNumber from "@/components/forms/InputNumber";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputFileManager from "@/components/forms/InputFileManager";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QImage from "@/components/QImage";
import InputTextArea from "@/components/forms/InputTextArea";
import emitter from "@/lib/emitter";
import InputSelect from "../../../components/forms/InputSelect";
import QSelect from "../../../components/QSelect";
import store from "@/store";
    export default {
        name: "TabInfo",
        components: {        
            InputTextArea,
            QImage,
            InputDatePicker,
            InputFileManager,
            InputSwitchButton,
            InputLocationPicker,
            InputText, ActionBar,
            InputSelect, InputNumber,
            QSelect
        },
        data() {
            return {
                provinces: [],
                districts: [],
                wards: [],
                title: '',
                isDataLoaded: false,
                entry: {},
                errors: {
                    location: []
                },
                dayOfWorks: [],
            }
        },
        mounted() {
            let id = document.getElementById('branchDropdown');
            // this.currentBranchID = id.getAttribute('data-id');
            // this.currentBranchName = id.innerText;
            this.currentBranchID = store.state.CurrentBranch.id;
            this.currentBranchName = store.state.CurrentBranch.name;

        },
        methods: {
            async changeDistrict() {
                this.entry.wardId = null;
                const res = await $get('/v1/locations/wards', {districtId: this.entry.districtId});
                this.wards = res.data;
            },
            async changeProvince() {
                this.entry.districtId = null;
                this.entry.wardId = null;
                const res = await $get('/v1/locations/districts', {provinceId: this.entry.provinceId});
                this.districts = res.data;
                this.changeDistrict();
            },
            changeDayOfWork(id){
                if(this.dayOfWorks.includes(id)){
                    this.dayOfWorks = this.dayOfWorks.filter(item => item !== id);
                    this.entry.work_days =  this.dayOfWorks;
                }else{
                    this.dayOfWorks.push(id);
                    this.entry.work_days = this.dayOfWorks;
                }
            },
            isExistInArray(id){
                if(this.dayOfWorks.includes(id)){
                    return true;
                }else{
                    return false;
                }
            },
        }
    }
</script>

<style scoped>


</style>
