<template>
    <div class="purchase-order-detail">
        <ActionBar :buttons="buttons"
                   :hideButtonSave="true"
                   :back-url="'/purchase-orders/index?action=' + entry.actionType"
                   @action="edit()"/>
        <div class="main-title">
            <h1>{{ pageTitle }}</h1>

        </div>
        <div class="row mt-3">
            <div class="col-lg-4">
                <table class="table table-index table-striped">
                    <tbody>
                        <tr>
                            <th>Mã nhập hàng</th>
                            <td>{{entry.code}}</td>
                        </tr>
                        <tr>
                            <th>{{$t('message.common.time')}}</th>
                            <td>{{d(entry.createdAt)}}</td>
                        </tr>
                        <tr>
                            <th>Nhà cung cấp</th>
                            <td v-text="entry.supplier ? entry.supplier.name : ''"></td>
                        </tr>
                        <tr>
                            <th>{{$t('message.common.created_by')}}</th>
                            <td v-text="entry.createdBy"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-4">
                <table class="table table-index table-striped">
                    <tbody>
                    <tr >
                        <template v-if="entry.actionType === 'import'">
                            <th> Mã đặt hàng nhập </th>
                            <td v-text="entry.orderSupplierCode"></td>
                        </template>
                        <template v-else>
                            <th> Mã nhập hàng</th>
                            <td>
                                <a v-if="entry.refCode"
                                   :href="'/purchase-orders/detail?code=' + entry.refCode"  v-text="entry.refCode" target="_blank">

                                </a>
                            </td>
                        </template>

                    </tr>
                    <tr>
                        <th>{{$t('message.common.status')}}</th>
                        <td>{{entry.statusName}}</td>
                    </tr>
                    <tr>
                        <th>{{$t('message.common.branch')}}</th>
                        <td>{{entry.branch?.name}}</td>
                    </tr>
                    <tr>
                        <th>Người nhập</th>
                        <td>{{entry.updatedBy}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-lg-4">
                <InputTextArea :label="$t('message.ProductIndex.note')" v-model="entry.description"/>
            </div>

        </div>
        <div class="row mt-3">
            <div class="col-lg-12">
                <table class="table table-index table-striped">
                    <thead>
                    <tr>
                        <th>{{$t('message.common.STT')}}</th>
                        <th>Mã Hàng</th>
                        <th>{{$t('message.DetailOrdersCard.prodName')}}</th>
                        <th style="width: 20%">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                        <th>
                            {{entry.actionType === 'return' ?  'SL trả' : 'Số lượng'}}
                        </th>
                        <th>{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-for="(product,index) in details" :key="index">
                        <tr >

                            <td>{{index+1}}</td>
                            <td v-text="product.productCode"></td>
                            <td >
                                <a target="_blank" v-text="product.productName" :href="'/products/form?id=' + product.productId"> </a>
                            </td>
                            <td >
                                {{product.productUnitName}}
                            </td>
                            <td>
                                <strong>{{n(product.totalQuantity)}}</strong>
                            </td>
                            <td colspan="5">
                            </td>

                        </tr>
                        <template v-for="batch in product.productBatches" :key="batch.id">
                            <tr >
                                <td></td>
                                <th>Lô/Date</th>
                                <td colspan="2" style="width: 200px">
                                    {{batch.product_batch?.name_formatted}}
                                </td>

                                <td  v-text="n(batch.quantity)"></td>
                                <td v-text="n(batch.price)"></td>
                                <td v-text="getDiscountInfo(batch.discountObject, batch.price)"></td>

                            </tr>
                        </template>

                    </template>

                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col-lg-8">

            </div>
            <div class="col-lg-4">
                <table class="table table-index table-striped">
                    <tbody>
                        <tr>
                            <th>{{$t('message.OrderSupplierIndex.total_quantity')}}</th>
                            <td>{{n(entry.totalQuantity)}}</td>
                        </tr>
                        <tr>
                            <th>Tổng số mặt hàng</th>
                            <td>{{n(entry.totalProduct)}}</td>
                        </tr>
                        <tr>
                            <th>{{$t('message.OrderSupplierIndex.total_origin')}}</th>
                            <td>{{n(entry.subTotal)}}</td>
                        </tr>
                        <tr>
                            <th>{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                            <td>{{n(entry.discount)}}</td>
                        </tr>
                        <tr>
                            <th>{{$t('message.OrderIndex.total')}}</th>
                            <td>{{n(entry.total)}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {$alert, $get} from "@/lib/utils";
import ActionBar from "../../components/ActionBar";
import {$post} from "../../lib/utils";
import {PurchaseOrderStatus, PurchaseOrderType} from "../../models/PurchaseOrder";
import InputTextArea from "../../components/forms/InputTextArea";

export default {
    name: "CustomerDetail",
    components: {InputTextArea, ActionBar},
    mounted() {
        this.load()
    },
    data() {
        return {
            pageTitle: '',
            buttons: [],
            fields: [],
            entry: {},
            details: [],
            info: {}
        }
    },
    methods: {
        getDiscountInfo(discountObject) {
            if (!discountObject) {
                return 0;
            }

            if (discountObject.discountType == 2) {
                return discountObject.discountValue.toString() + '%';
            }

            return this.n(discountObject.discountValue);
        },
        initButtons() {
            const {entry} = this;
            entry.readonly = parseInt(entry.readonly);

            if (entry.actionType === PurchaseOrderType.IMPORT) {
                this.buttons = [
                    {
                        class: 'btn btn-primary',
                        icon: 'fas fa-save',
                        label:  'Lưu lại',
                        action: this.saveNote,
                    },
                    {
                        class: 'btn btn-primary',
                        icon: 'fas fa-edit',
                        label:  'Mở phiếu',
                        showIf: entry.readonly === 0,
                        action: () => {
                            this.edit();
                        }
                    },
                    {
                    class: 'btn btn-primary',
                    icon: 'fas fa-undo',
                    label: 'Trả hàng nhập',
                    showIf: entry.status === PurchaseOrderStatus.IMPORTED,
                    action: () => {
                        location.href = '/purchase-orders/form?action=return&ref_code=' + this.entry.code;
                     }
                    },
                    {
                        class: 'btn btn-danger',
                        icon: 'fas fa-trash',
                        label: 'Hủy phiếu',
                        action: this.cancel,
                        showIf: entry.readonly === 0,
                    }

                ];

            } else {
                this.buttons = [
                    {
                        class: 'btn btn-primary',
                        icon: 'fas fa-edit',
                        label:  'Chỉnh sửa',
                        showIf: entry.status === PurchaseOrderStatus.DRAFT,
                        action: () => {
                            this.edit();
                        }
                    },
                    {
                        class: 'btn btn-primary',
                        icon: 'fas fa-save',
                        label:  'Lưu lại',
                        action: this.saveNote,
                    },
                    {
                        class: 'btn btn-danger',
                        icon: 'fas fa-trash',
                        label: 'Hủy phiếu',
                        showIf: entry.status === PurchaseOrderStatus.RETURNTED,
                        action: this.cancel,
                    }
                ]
            }
        },
        async saveNote() {
            const res = await $post('/v1/purchase-orders/save-description', {
                id: this.entry.id,
                description: this.entry.description
            });

            $alert(res);
        },
        async edit() {
            const res = await $post('/v1/purchase-orders/unlock', {
                id: this.entry.id
            });

            $alert(res);

            if (res.code === 200) {
                location.href = '/purchase-orders/form?code=' + this.entry.code;
            }
        },
        async cancel() {
            if (!confirm('Hủy phiếu nhập này?')) {
                return;
            }

            const res = await $post('/v1/purchase-orders/cancel', {
                id: this.entry.id
            });

            $alert(res);
            if (res.code === 200) {
                this.load();
            }

        },
        async load() {
            const res = await $get('/v1/purchase-orders/show', this.$route.query);
            if (res.code !== 200) {
                $alert(res);
                return;
            }


            this.fields = res.data.fields;
            this.entry = res.data.entry;
            this.details = res.data.details;
            this.info = res.data.info;
            this.pageTitle = res.data.title;
            this.initButtons();

            document.title = this.pageTitle;

        }
    }
}
</script>
<style>
.purchase-order-detail {
    padding-bottom: 100px;
}
</style>
