<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static" id="modalChecked">
        <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                    <div class="d-flex">
                        <h5 style="fotn" class="modal-title py-2">{{ this.action? this.action : $t('message.customerIndex.modal.titleCreate') }}</h5>
                        <span class="m-3">|</span>
                        <span class="my-3">{{$t('message.customerIndex.modal.branch')}}: {{ this.currentBranchName }}</span>
                    </div>                    
                    <button type="button" class="close" aria-label="Close" @click="confirmClose()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0">                  
                    <div class="customer-tab tab-info">
                        <!-- <template v-if="!isModal"> -->
                            <!-- <ActionBar back-url="/customers/index" @action="save()" /> -->
                            <!-- <div class="title"> -->
                                <!-- Thông tin khách hàng: {{ entry.name }} -->
                            <!-- </div> -->
                        <!-- </template> -->

                        <div class="content">
                            <div class="row">
                                <div class="col-2" id="imageAction">
                                    <span @click="removeImg()" class="fmi-close" v-if="this.entry.avatar">
                                        <i class="fa fa-times"/>
                                    </span>
                                    <QImage
                                        :src="entry.avatar && entry.avatar.url ? entry.avatar.url : '/img/default-product.593ae091.png'"
                                        style="width: 100%;" class="py-2 text-center" />
                                    <InputFileManager :multiple="false" v-model="entry.avatar" :cusStyle="true" :hidePreview="this.hidePreview"></InputFileManager>
                                </div>
                                <div class="col-5">
                                    <!-- Start mã khách hàng -->
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <b>{{$t('message.customerIndex.modal.customerCode')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model="entry.code" :errors="errors.code" :placeholder="$t('message.customerIndex.modal.defaultCode')"  />
                                        </div>
                                    </div>
                                    <!-- End mã khách hàng -->
                                    <!-- Start name -->
                                    <div class="row">
                                        <div class="col-3 pr-0">
                                            <b>{{$t('message.customerIndex.modal.customerName')}}<label class="text-danger ">*</label> </b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model="entry.name" :errors="errors.name" :placeholder="$t('message.customerIndex.modal.enterCustomerName')" />
                                        </div>
                                    </div>
                                    <!-- End name -->
                                    <!-- Start phone number -->
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerPhoneNumber')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model.trim="entry.contactNumber" :errors="errors.contactNumber" :placeholder="$t('message.customerIndex.modal.enterPhone1')" :max="12" :cusNumber="true"/>
                                            <InputText v-model.trim="entry.contactNumber1" :errors="errors.contactNumber1" :placeholder="$t('message.customerIndex.modal.enterPhone2')" :max="12" :cusNumber="true"/>
                                        </div>
                                    </div>
                                    <!-- Start birthday and gender -->
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerBirthDate')}}</b>
                                        </div>
                                        <div class="col-9 mr-0">                                            
                                                <InputDate v-model="entry.birthDate" :errors="errors.name"
                                                placeholder="DD/MM/YYYY" :dateData="true" :max="10"/>
                                                <i class="far fa-calendar fa-md" aria-hidden="true"></i>       
                                        </div>                                        
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerGender')}}</b>
                                        </div>                                       
                                        <div class="col-5">
                                            <div class="form-check">
                                                <input class="form-check-input" v-model="entry.gender" type="radio" 
                                                    name="exampleRadios" id="exampleRadios1" value="1">
                                                <label class="form-check-label" for="exampleRadios1">
                                                    {{$t('message.common.male')}}
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-4">
                                            <div class="form-check ">
                                                <input class="form-check-input" v-model="entry.gender" type="radio"
                                                    name="exampleRadios" id="exampleRadios2" value="0">
                                                <label class="form-check-label" for="exampleRadios2">
                                                    {{$t('message.common.female')}}
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- End birthday and gender -->                                    
                                    <!-- <div class="row">
                      
                                    </div> -->
                                    <!-- End status -->                                   
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerAddress')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputTextArea v-model="address.addressValue" :placeholder="$t('message.customerIndex.modal.enterAddress')" />
                                        </div>
                                    </div>
                                    <InputLocationPicker v-model="this.location"></InputLocationPicker>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerAccountNumber')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" v-model.trim="entry.accountNumber" class="form-control" :placeholder="$t('message.customerIndex.modal.enterAccountNumber')">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerAccountName')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" v-model.trim="entry.accountName" class="form-control" :placeholder="$t('message.customerIndex.modal.enterAccountOwner')">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerBankName')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" v-model.trim="entry.bankName" class="form-control" :placeholder="$t('message.customerIndex.modal.enterBankName')">
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerBankAddress')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <input type="text" v-model.trim="entry.bankAddress" class="form-control" :placeholder="$t('message.customerIndex.modal.enterBankAddress')">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5">
                                    <!-- Start loại khách -->
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerType')}}</b> 
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group row ">
                                                <div class="form-check mx-3">
                                                    <input class="form-check-input" type="radio"
                                                        name="chooseTypeAddress" :value="1"
                                                        v-model="address.typeAddress" id="nha-rieng"
                                                        @click="companyShow($constant.not_company)"
                                                        :checked="entry.type == this.$constant.cus_individual ? true : false"
                                                    >
                                                        
                                                    <label class="form-check-label" for="nha-rieng" style="font-size:13px !important">
                                                        {{$t('message.customerIndex.modal.personal')}}
                                                    </label>
                                                </div>
                                                <div class="form-check ">
                
                                                     <input class="form-check-input" type="radio"
                                                        name="chooseTypeAddress" :value="2"
                                                        v-model="address.typeAddress" id="cong-ty"
                                                        @click="companyShow($constant.is_company)"
                                                         :checked="entry.type == this.$constant.cus_company ? true : false"
                                                    >    
                                                    <label class="form-check-label" for="cong-ty" style="font-size:13px !important">
                                                        {{$t('message.customerIndex.modal.company')}}
                                                    </label>
                                                </div>
                                              
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="this.isCompany == 2" class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerCompanyName')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model="entry.companyName" :placeholder="$t('message.customerIndex.modal.enterCompanyName')" />
                                        </div>
                                    </div>
                                    <!-- End loại khách -->
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerTaxCode')}}</b>
                                        </div>
                                        <div class="col-9">                                            
                                            <InputText v-model="entry.taxCode" :errors="errors.taxCode" v-on:keyup.enter="getInfoCompany()" :placeholder="$t('message.customerIndex.modal.enterTaxCode')" :max="14"/>
                                        </div>
                                    </div>
                                    <!-- Start email -->
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b>{{$t('message.customerIndex.modal.customerEmail')}}</b>
                                        </div>
                                        <div class="col-md-9">
                                            <InputText v-model="entry.email" :errors="errors.email" :placeholder="$t('message.customerIndex.modal.enterEmail')"/>
                                        </div>
                                    </div>
                                    <!-- End email -->
                                    <!-- Start facebook -->
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b>{{$t('message.customerIndex.modal.customerFacebook')}}</b>
                                        </div>
                                        <div class="col-md-9">
                                            <InputText v-model="entry.facebookUrl" :errors="errors.facebookUrl" :placeholder="$t('message.customerIndex.modal.enterFacebook')"/>
                                        </div>
                                    </div>
                                    <!-- End facebook -->                             
                                    <!-- Start nhóm khách hàng -->
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerGroup')}}</b>
                                        </div>
                                        <div class="col-md-9 form-group"> 
                                            <q-select name="cusGroup" :default-options="cusGroup" :multiple="true" v-model="entry.customerGroupId" :selectAll="true" :placeholder="$t('message.customerIndex.modal.chooseCustomerGroup')" 
                                            />
                                        </div>
                                    </div>
                                    <!-- End facebook -->                                    
                                    <div class="row">
                                        <div class="col-md-3">
                                            <b>{{$t('message.customerIndex.modal.customerNote')}}</b>
                                        </div>
                                        <div class="col-md-9">
                                            <InputTextArea v-model="entry.comments" :placeholder="$t('message.customerIndex.modal.enterNote')" />
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.customerDebtDate')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="debt">
                                                <input type="text" v-model.trim="entry.numberDebtDate" @input="changeNumberDebtDate" 
                                                @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"
                                                class="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0 mb-2" :placeholder="$t('message.customerIndex.modal.enterDebtDate')">
                                                <input type="text" v-model.trim="entry.recurringPaymentDate" @input="changePaymentDate" class="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0" :placeholder="$t('message.customerIndex.modal.choosePaymentDate')">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.user')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <q-select v-model="entry.userId" :default-options="users" :multiple="false" :placeholder="$t('message.customerIndex.modal.enterEmployee')"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.paymentTerms')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model="entry.paymentTerms" :errors="errors.paymentTerms" :placeholder="$t('message.customerIndex.modal.enterPaymentCondition')"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.modal.maxDebtNumber')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputNumberFormat :max="100000000000" v-model="entry.maxDebtNumber" :bgTransparent="true" :placeholder="$t('message.customerIndex.modal.enterMaxDebt')" class="mb-0"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12">
                                            <label class="container-checkbox d-flex m-0" v-if="isSupplierOrCustomer">
                                                <span class="text-bold">{{$t('message.customerIndex.modal.isSupplier')}}</span>
                                                <input type="checkbox" class="chkType" :disabled="entry.id && entry.supplier ? true : false" v-model="entry.isSupplier" :checked="entry.isSupplier ? true : false">
                                                <span class="checkmark-checkbox"></span>
                                            </label>
                                        </div>
                                    </div>

                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.customerIndex.branchActive')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <q-select name="branchIDs" :default-options="branches" :multiple="true" v-model="entry.branchIDs" :selectAll="true" :placeholder="$t('message.customerIndex.branchActive')"/>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>                       
                    </div>
                </div>
                <div class="modal-footer">
                    <AppPermission :permission="[this.$permissions.customers.update ?? '', this.$permissions.customers.create ?? '']">
                        <button class="btn btn-primary" back-url="/products/index" @click="save()">
                            <i class="fa fa-save"></i>
                            {{$t('message.customerIndex.modal.button.save')}}
                        </button>
                    </AppPermission>
                    <!-- <a aria-current="page" href="/customers/index" class="router-link-active router-link-exact-active btn btn-default"> Bỏ qua </a> -->
                    <button type="button" class="btn btn-secondary text-white" aria-label="Close" @click="confirmClose">
                        <i class="fa fa-ban mr-1" data-v-76ef515c=""></i>
                        <span aria-hidden="true">{{$t('message.customerIndex.modal.button.skip')}}</span>
                    </button>
                </div>
            </div>
        </div>        
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :title="this.title" :content="this.content"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import {$alert, $get, $post, scrollToElement, $msg} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputFileManager from "@/components/forms/InputFileManager";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QImage from "@/components/QImage";
import InputTextArea from "@/components/forms/InputTextArea";
import store from "../../store";
import QSelect from "../../components/QSelect.vue";
import ModalConfirm from "@/components/ModalConfirm.vue"
import Datepicker from 'vue3-datepicker';
import moment from 'moment';
import InputDate from "@/components/forms/InputDate.vue";
import emitter from "@/lib/emitter";
import AppPermission from '../AppPermission';
import currencyMixin from "@/currencyMixin";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import { mapGetters } from "vuex";
export default {
  created () {
  },
    name: "ModalAddInfoCustomer",
    mixins: [currencyMixin],
    emits: ['updated'],
    mounted() {
        
        if (this.id) {
            this.load();
        }

    },
    components: {  
        InputTextArea,
        QSelect,    
        QImage,
        InputDatePicker,
        InputFileManager,
        InputSwitchButton,
        InputLocationPicker,
        InputText, ActionBar,
        ModalConfirm,
        Datepicker,
        InputDate,
        AppPermission,
        InputNumberFormat
     },
    props: {
        openModal: {
            type: Boolean,
            default: false,
        },
        action:{
            type: String,
        }
    },
    watch: {
        id: function () {
            this.load();
        },
        entry: function(){                    
            if(this.id !== null) this.loadCustomerGroup();
        }
    },
    methods: {
        async getInfoCompany() {
            this.clearDataCompany();
            if (this.isCompany == this.$constant.is_company) {
                const res = await $get('/v1/customers/get-info-company', {                
                    taxCode: this.entry.taxCode
                });
                if (res.result && res.result.status) {
                    let dataCompany = res.result.dataCompany;
                    this.entry.companyName = dataCompany.companyName;
                    this.address.addressValue = dataCompany.address;
                    this.location = {
                        provinceId: dataCompany.provinceId,
                        districtId: dataCompany.districtId,
                        wardId: dataCompany.wardId,
                    }
                    this.entry.contactNumber = dataCompany.phoneNumber;
                } else {
                    $alert({code: '500', message: this.$t('message.customerIndex.modal.notFoundInfoTax')});
                }
            }
        },
        clearDataCompany() {
            this.entry.companyName = null;
            this.address.addressValue = null;
            this.location = {
                provinceId: null,
                districtId: null,
                wardId: null,
            }
            this.entry.contactNumber = null;
        },
        removeImg(){
            this.entry.avatar = null;
            this.entry.avatar.url = null;
        },
        companyShow(status){
            this.isCompany = status;
        },
        isEmpty(entry,location){    
            let status = true;
            if (entry.code && entry.code !== null && entry.code !=="") status =false;
            if (entry.name && entry.name !== null && entry.name !=="") status =false;
            if (entry.contactNumber && entry.contactNumber !== null && entry.contactNumber !=="") status =false;
            if (entry.contactNumber1 && entry.contactNumber1 !== null && entry.contactNumber1 !=="") status =false;
            if (entry.birthDate && entry.birthDate !== null && entry.birthDate !=="") status =false;
            if (entry.gender && entry.gender !== null && entry.gender !=="") status =false;
            if (entry.taxCode && entry.taxCode !== null && entry.taxCode !=="") status =false;
            if (entry.email && entry.email !== null && entry.email !=="") status =false;
            if (entry.facebookUrl && entry.facebookUrl !== null && entry.facebookUrl !=="") status =false;
            if (entry.name && entry.name !== null && entry.name !=="") status =false;
            if (entry.customerGroupId && entry.customerGroupId !== null && entry.customerGroupId !=="") status =false;
            if (entry.comments && entry.comments !== null && entry.comments !=="") status =false;
            if (location?.provinceId && this.location.provinceId !== null && location.provinceId !== 'undefined') status =false;            
            if (location?.districtId && this.location.prodistrictIdvdistrictIdinceId !== null && location.districtId !== 'undefined') status =false;
            if (location?.wardId && this.location.wardId !== null && location.wardId !== 'undefined') status =false;
            return status;
        },
        isChange(entry, dEntry){   
            let status = false;
            if (entry.code && dEntry.code && entry.code !== dEntry.code) status = true;
            if (entry.name && dEntry.name && entry.name !== dEntry.name) status = true;
            if (entry.contactNumber && dEntry.contactNumber && entry.contactNumber !== dEntry.contactNumber) status = true;
            if (entry.contactNumber1 && dEntry.contactNumber1 && entry.contactNumber1 !== dEntry.contactNumber1) status = true;
            if (entry.birthDate && dEntry.birthDate && entry.birthDate !== dEntry.birthDate) status = true;
            if (entry.gender && dEntry.gender && entry.gender !== dEntry.gender) status = true;
            if (entry.taxCode && dEntry.taxCode && entry.taxCode !== dEntry.taxCode) status = true;
            if (entry.email && dEntry.email && entry.email !== dEntry.email) status = true;
            if (entry.facebookUrl && dEntry.facebookUrl && entry.facebookUrl !== dEntry.facebookUrl) status = true;
            if (entry.name && dEntry.name && entry.name !== dEntry.name) status = true;
            if (entry.customerGroupId && dEntry.customerGroupId && entry.customerGroupId !== dEntry.customerGroupId) status = true;
            if (entry.comments && dEntry.comments && entry.comments !== dEntry.comments) status = true;
            if (location.provinceId && this.location.provinceId !== null && location.provinceId !== 'undefined') status =false;            
            if (location.districtId && this.location.prodistrictIdvdistrictIdinceId !== null && location.districtId !== 'undefined') status =false;
            if (location.wardId && this.location.wardId !== null && location.wardId !== 'undefined') status =false;
            return status;
        },
        confirmClose(){                 
            
            let isEmpty = this.isEmpty(this.entry,this.location);
            let cData = this.isChange(this.entry, this.dEntry, this.location);
            this.content = this.$t('message.customerIndex.contentConfirmSkip');
            this.title = this.$t('message.customerIndex.confirm');
            if (this.id && this.id != ""){
               
                if (cData === true) {
                    this.$refs.ModalConfirm.show();
                }
                else 
                {
                   this.hide();
                }
            }
            else {                
                if (isEmpty === false) {                                    
                    this.$refs.ModalConfirm.show();
                }
                else 
                {                    
                    // window.jQuery(this.$refs.modal).modal('hide');
                    this.hide();
                }
            }
            

           
        },
        async show(customerId) {            
            let id = document.getElementById('branchDropdown');
            // this.currentBranchID = id.getAttribute('data-id');
            // this.currentBranchName = id.innerText;
            if(customerId) {
                this.id = customerId;
                await this.load();
                this.dEntry = {...this.entry}
                window.jQuery(this.$refs.modal).modal('show');
            }
            else{                
                this.entry = this.getEntryNull();                
                this.address = this.getAddressNull();
                this.isCompany = 1;
                this.hidePreview = true; 
                emitter.emit('clearOptionsQselect');               
                window.jQuery(this.$refs.modal).modal('show');               
            }
            if(!this.entry.userId){
                this.entry.userId = store.state.Auth.user.id;
            }
            this.isCompany = this.entry.type;
        },
        hide(){
            this.entry = this.getEntryNull();
            this.entry.customerGroupId = null;            
            this.address = this.getAddressNull();
            emitter.emit('clearOptionsQselect');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        getAddressNull(){
            let data = {               
                addressValue:null, 
                typeAddress: 1             
            }
            return data;
        },
        getEntryNull(){
            this.id = null;
            let data = {                
                code:null,
                name:null,
                contactNumber:null,
                contactNumber1:null,
                birthDate: null,
                gender: null, 
                addressValue:null,
                taxCode: null,
                email: null,
                facebookUrl: null,
                customerGroupId: null,
                comments: null,
                avatar: null,      
                address:null,
                type:1,                
            }            
            this.location = null;            
            this.isCompany = 1;
            return data;
        },
        async load() {
            const res = await $get('/v1/customers/show', {                
                id: this.id
            });
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            this.entry = res.data.entry;
            
            //Convert string to Date
            if(this.entry.birthDate && this.entry.birthDate!== null) this.entry.birthDate =  moment(this.entry.birthDate).format('DD/MM/YYYY');
            else this.entry.birthDate =  null;
           
            if (this.entry.address2 ) {
                this.address = this.entry.address2;
                this.location = this.entry.address2;
            }
            if(this.entry.companyName !== '') this.isCompany = 2;
             
        },
        async loadCustomerGroup() {
            const res = await $get('/v1/customer-group/data');
            if (res.code !== 200 ) {
                $alert(res);
                return;
            }
            this.customerGroup = res.data;            
        },

        async save() {                            
            // Lấy chi nhánh 
            let id = document.getElementById('branchDropdown');            
            this.entry.branchID = this.currentBranchID;                            
            this.errors = {};
            this.address.provinceId = this.location?.provinceId? this.location.provinceId : null;
            this.address.districtId = this.location?.districtId?  this.location.districtId : null;
            this.address.wardId = this.location?.wardId? this.location.wardId : null;
            
            // if(this.entry.birthDate && this.entry.birthDate !==null){
            //     let birthDate = moment(this.entry.birthDate).format('YYYY-MM-DD');            
            //     this.entry.birthDate = birthDate;      
            // }
            if(this.entry.paymentTerms){
                this.entry.paymentTerms = this.entry.paymentTerms.trim();
            }
            let res = null;
            if(this.id){
                res = await $post('/v1/customers/update', {
                    entry: this.entry, address: this.address, branchId: this.currentBranchID
                });
            }else{
                res = await $post('/v1/customers/save', {
                    entry: this.entry, address: this.address, branchId: this.currentBranchID
                });
            }
            if (res.code === 200) {  
                    this.$emit('updated', res.data);
                    this.$emit('selectCustomer', {model: res.data.id, name: res.data.name});
                    this.getEntryNull();
                    window.jQuery(this.$refs.modal).modal('hide');                       
                 }
            else {                    
                    if (res.redirect) {
                        setTimeout(()=>{
                        location.replace(res.redirect);
                        return;
                        },1000);
                    }
                }
             if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }
            $alert(res);

        },
        isMessageError(res, errMss){   
            switch (res.code) {
                case 422:
                    if(errMss.name) {
                    $msg(res, errMss.name);
                    return;
                    }
                    if(errMss.code) {
                        $msg(res, errMss.code);
                        return;
                    }
                    if(errMss.contactNumber) {
                        $msg(res, errMss.contactNumber);
                        return;
                    }
                    if(errMss.contactNumber1) {
                        $msg(res, errMss.contactNumber1);
                        return;
                    }
                    if(errMss.taxCode) {
                        $msg(res, errMss.taxCode);
                        return;
                    }
                    if(errMss.birthDate) {
                        $msg(res, errMss.birthDate);
                        return;
                    }
                    break;
                case 400:
                    if(errMss) {
                        $msg(res, errMss);
                        return;
                    }
                    break;             
            }
                
            },
        async save1() {
            // Lấy chi nhánh 
            let id = document.getElementById('branchDropdown');
            // this.currentBranchID = id.getAttribute('data-id');
            // this.currentBranchName = id.innerText;
            this.entry.branchID = this.currentBranchID;     
                       
            this.errors = {};
            this.address.provinceId = this.location?.provinceId? this.location.provinceId : null;
            this.address.districtId = this.location?.districtId?  this.location.districtId : null;
            this.address.wardId = this.location?.wardId? this.location.wardId : null;
            
            // if(this.entry.birthDate && this.entry.birthDate !==null){
            //     let birthDate = moment(this.entry.birthDate).format('YYYY-MM-DD');            
            //     this.entry.birthDate = birthDate;      
            // }
            
            const res = await $post('/v1/customers/save', {
                ...this.entry, cus_address: this.address, cus_branchId: this.currentBranchID
            });       
            if (res.code === 200) {                               
                    this.$emit('updated', res.data);
                    this.$emit('selectCustomer', {model: res.data.id});
                    this.getEntryNull();
                    window.jQuery(this.$refs.modal).modal('hide');                       
                 }
            else {     
                    if(res.messages){                       
                        this.isMessageError(res, res.messages.errors);
                    }    
                    
                    if(res.message){                            
                        this.isMessageError(res, res.message);
                    } 
                  
                    if (res.redirect) {
                        setTimeout(()=>{
                        location.replace(res.redirect);
                        return;
                        },1000);
                    }
                }
             if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }
            // $alert(res);

        },
        addGroupCustomer() {
            this.shown = true;
            this.$nextTick(() => {
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            })
        },
        async saveCustomerGroup() { 
            this.errorGroups = {};
            const res = await $post('/v1/tags/saveCustomer', {
                entry: this.group
            });

            if (res.code === -1) {
                this.errorGroups = res.errors;
                scrollToElement('.error-label');
            }

            if (res.code === 200) {
                await this.loadCustomerGroup();
                this.group = {
                    name: '',
                    description: ''
                };
                this.errorGroups = {
                    name: []
                };
                window.jQuery(this.$refs.modal).modal('hide');

            }

            $alert(res);
        },
        onClose() {
            this.group = {
                name: '',
                description: ''
            }
        },
        changePaymentDate(){
            this.entry.numberDebtDate = null;
        },
        changeNumberDebtDate(){
            this.entry.recurringPaymentDate = null;
        }
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            picked: new Date(),
            isSelectAll: false,
            currentBranchID: store.state.CurrentBranch.id,
            currentBranchName: store.state.CurrentBranch.name,
            branches: globalConfig.branches,
            name: '',
            cusGroup:globalConfig.cusGroup,
            users: globalConfig.users,
            dEntry:{},
            entry: {},
            title: "",
            content:"",
            hidePreview:false,
            address: {
                is_default: 1,                
            },
            errors: {
                apartmentName: []
            },
            errorGroups: {
                name: []
            },
            group: {
                name: '',
                description: ''
            },
            shown: false,
            location: {
                provinceId: null,
                districtId: null,
                wardId: null,
            },
            customerGroup: [],            
            showHistory: false,
            isCompany: 1,
        }
    },
    computed: {
        ...mapGetters({
            isSupplierOrCustomer: 'settingStore/isCustomerOrSuplier'
        })
    }
}
</script>
<style scoped>
.action-bar {
    display: none !important;
}
#imageAction{
    display: fixed;
    align-items: center;
}
.far {
    position: absolute;
    right: 25px;
    top: 11px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.fmi-close {
    position: absolute;
    top: 0.5rem;
    right:1.5rem;
    color: #8b0000;
    cursor: pointer;
    font-size: 1.2em;
}
.debt{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 10px;
}
</style>