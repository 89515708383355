<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.title_update') }}
                    </h5>
                    <button type="button" class="close"  @click="showModalCancelEdit">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-3">
                                            <b>
                                                {{ $t('message.DepartmentIndex.table.code') }}
                                                <label class="text-danger px-0">*</label> 
                                            </b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <input type="text" v-model="entry.code"  
                                                :placeholder="$t('message.DepartmentIndex.modal.createOrUpdate.placeholder.code')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <b>
                                                {{ $t('message.DepartmentIndex.modal.createOrUpdate.name') }}
                                                <label class="text-danger px-0">*</label> 
                                            </b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="entry.name"  
                                                :placeholder="$t('message.DepartmentIndex.modal.createOrUpdate.placeholder.name')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                    
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{ $t('message.DepartmentIndex.modal.createOrUpdate.description') }}</b>
                                        </div>
                                        <div class="col-9 description-job-title">
                                            <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                            <input type="text" name="description" v-model="entry.note" class="form-control">
                                        </div>
                                    </div>

                                    <div class="row ">
                                        <div class="col-3">
                                            <b>{{ $t('message.DepartmentIndex.modal.createOrUpdate.status') }}</b>
                                        </div>
                                        <div class="col-4 d-flex align-items-center">
                                            <label class="container-radio ml-2 min-vw-100">{{ $t('message.DepartmentIndex.modal.createOrUpdate.active') }}
                                                <input type="radio" name="status" v-model="entry.status" :value="1" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-5 d-flex align-items-center">
                                            <label class="container-radio ml-2 min-vw-100">{{ $t('message.DepartmentIndex.modal.createOrUpdate.in_active') }}
                                                <input type="radio" name="status" v-model="entry.status" :value="0" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <AppPermission :permission="this.$permissions.departments.update?? '' ">
                                    <button type="button" class="btn btn-save mr-2" @click="update(entry.id)">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.save') }}
                                    </button>
                                    </AppPermission>
                                    <button type="button" class="btn btn-secondary text-white  mr-2"
                                        @click="showModalCancelEdit">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.skip') }}
                                    </button>
                                    <AppPermission :permission="this.$permissions.departments.remove?? '' ">
                                    <button type="button" class="btn btn-danger mr-2" @click="removeDepartment(entry.id, entry.name)">
                                        <i class="fa fa-trash-alt mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.remove') }}
                                    </button>
                                    </AppPermission>
                                </div>
                            </div>
                        </div>
                      
                    </div>
                </div>
            </div>
        </div>      
    </div>
    <ModalCancelEdit ref="ModalConfirm" @confirm="hide()" :message="$t('message.DepartmentIndex.modal.createOrUpdate.content_confirm_update')" />
    <ModalConfirmDelete ref="ModalConfirmDelete" @input="hide"/>
</template>
<script>
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import toastr from 'toastr';
import ModalCancelEdit from '@/components/customers/ModalCancelEdit';
import ModalConfirmDelete from './ModalConfirmDelete.vue';
import InputText from "@/components/forms/InputText";
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalEditDepartment",
    components: { ModalCancelEdit, InputText, ModalConfirmDelete, AppPermission },
    props: {
        customerGroupDetail: {
            type: Object,
            default: () => ({})
        },
        flag: {
            type: String,
            default: ''
        },
    },
    data () {
        return {
          active: 1,
          errors: {},
          entry: {},
          defaultEntry: {},
          customerGroupList: [],
        }
    },
    methods: {
        async show (id = null) {
            window.jQuery(this.$refs.modal).modal('show');
            await this.load(id);
            this.defaultEntry = {...this.entry}  
        },
        async submitForm () {
            this.errors = {}
            const res = await $post('/v1/customer-group/create', this.form);
            if (res.code === 512) {
                this.errors = res.errors;
                scrollToElement('.error-label')
                $alert(res);
            }
            if(res.code == 200){
                toastr.success(this.$t('message.ModalCreateRouteOfUse.alert.create_success'));
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        async load(id = null) {
            const departments = await $get('/v1/departments', {...this.$route.query, numberRecord: this.numberRecord});
            this.departments = departments.data;
            // const customerGroupCustom = await $get('/v1/customer-group/list-of-customer');
            // this.customerGroupListCustom = customerGroup;
 
            const res = await $get(`/v1/departments/show/${id}`)
            if (res.code === 404) {
                location.replace('/404');
                return;
            }        
            this.entry = res;
        },
        removeDepartment (id, name) 
        {
            this.$refs.ModalConfirmDelete.show(id, name); 
        },
        showModalCancelEdit () {
            if ( this.defaultEntry.name !== this.entry.name || this.defaultEntry.note !== this.entry.note || this.defaultEntry.status !== this.entry.status){ 
                this.$refs.ModalConfirm.show();
            } else {
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        hide () {
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('input','1');
            this.$emit('inputData', '1');
            this.$emit('updated', '')
        },
        async update (id) 
        {
            const data = await $post(`/v1/departments/update/${id}`, this.entry);
            if(data.code === 422){
                let errors = data.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: data.code, message: errors[key][0]});
                });
                return;
            }
            if (data.code === 512) {
                this.errors = data.errors;
                scrollToElement('.error-label')
                $alert(data);
            }
            else {
                toastr.success(this.$t('message.DepartmentIndex.modal.createOrUpdate.alerts.update_success'));
                emitter.emit('reloadDataEmployee');
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('updated', data);
                this.$emit('input');
            }
           
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}

.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
</style>