<template>
    <div class="sale-screen" @keyup.enter="processBarcodeScanner">
        <div id="header-sale">
            <div class="row header-main-menu">
                <div class="col-4 search-box__wrapper">
                    <ProductSearch @select="onSelectProduct" :searchForPlan="true" :notAdd="true" :toBranchId="entryBranch" :startDate="this.startDate" :endDate="this.endDate"></ProductSearch>
                </div>

                <div class="col-5 p-0">
                    <div class="sale-tabs__root" ref="saleTabRoot">
                        <template v-if="showLeftRight" >
                            <div class="sale-tabs__scroller sale-tabs__left" @click.prevent="onClickScroll('left')" >
                                <div>
                                    <i class="fa fa-chevron-left"/>
                                </div>
                            </div>
                            <div class="sale-tabs__scroller sale-tabs__right"  @click.prevent="onClickScroll('right')">
                                <div>
                                    <i class="fa fa-chevron-right"/>
                                </div>
                            </div>
                        </template>

                        <div class="sale-tabs__btn" :style="{left: btnLeftFloat+'px'}" >
                            <button type="button" class="sale-tabs__btn--add" data-toggle="tooltip" data-placement="top" @click="addTab"><i class="fa fa-plus-circle" ></i></button>
                            <!-- <a class="nav-link" href="#" id="partnerDropdown" role="button" >
                                <i class="fas fa-caret-down text-white"></i>
                            </a> -->
                        </div>

                        <draggable
                            v-model="tabs"
                            class="sale-tabs"
                            group="tabs"
                            @start="drag=true"
                            :wrap="false"
                            @end="drag=false"
                            id="plans-tabs"
                            item-key="id">
                            <template #item="{element}">
                                <div v-if="!element.branchId || element.branchId == currentBranch.id"
                                     class="sale-tabs__tab" :class="{'sale-tabs__tab--active': element.id === currentTab.id}">
                                    <div class="sale-tabs__tab-content" @click.prevent="changeTab(element)">
                                        <div>
                                            <a class="sale-tabs__link" href="">{{ element.name }}</a>
                                            <a class="sale-tabs__close " href="" @click.stop.prevent="removeCurrentTab(element)" style="margin-left: 10px" data-toggle="tooltip" data-placement="top" title="Đóng">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>

                <div class="col-3 float-right">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <ul class="navbar-nav ml-auto mr-4">
                                <li class="nav-item">
                                    <a class="nav-link text-white" href="javascript:void(0);">
                                        {{ this.user.name }}
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-bars text-white"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                        <a class="dropdown-item" href="/customers/index" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-user-shield"></i> 
                                            {{$t('message.headers.manage')}}
                                        </a>
                                        <a class="dropdown-item" @click="logout" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-sign-out-alt"></i> 
                                            {{$t('message.headers.logout')}}
                                        </a>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-screen__main">
            <component :is="currentComponent"
                        v-if="currentTab && currentTab.id"
                        v-model="currentTab"
                        :key="currentTab.id"
                        ref="ticket"
                        @changeEntryBranch="setEntryBranch"
                        @changeStartDate="setStartDate"
                        @changeEndDate="setEndDate"
                        @removeTab="removeCurrentTab"
                        @orderProcessing="addTab"
                        @print="onPrint"
            ></component>
        </div>

        <div id="footer-sale">
            <div class="row footer-sale-content">
                <div class="col-9 p-0"></div>
                <div class="col-3 p-0 float-left">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <a class="text-dark m-0 p-0 pr-2" href="#" role="button" :title="currentBranch.name" style="padding-bottom: 0">
                                <i class="fas fa-map-marker-alt"></i>
                            </a>
                            <div id="dropdownMenuButton1">
                                <span class="m-tb-auto p-0" v-text="currentBranch.name"></span>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-modal">
        </div>
    </div>
</template>

<script>

import PlansTab from "./PlansTab";
import draggable from 'vuedraggable'
import store from "../../store";
import ProductSearch from "@/components/ProductSearch.vue";
import moment from "moment";
import * as toastr from 'toastr';

import {
  $alert,
  $get, $post, ACTION_CREATE, ACTION_ORDER_PROCESSING,
  ACTION_UPDATE_INVOICE,
  ACTION_UPDATE_ORDER,
  isEmpty,
  TYPE_IMPORT,
  TYPE_EXPORT,
  TYPE_RETURN,
  TYPE_TRANSFER,
  TYPE_DELIVERY, ACTION_RETURN_INVOICE,
  clone
} from "../../lib/utils";
import { useLoading } from 'vue3-loading-overlay';
import emitter from "@/lib/emitter";
import ModalConfirmCancel from './ModalConfirm.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import AppPermission from '@/components/AppPermission.vue';
import { mapGetters, mapActions } from 'vuex';
import storeModul from "@/storeModul";

export default {
    name: "PlansCreateUpdate",
    components: {
        ModalConfirmCancel, PlansTab, draggable, ProductSearch, ModalConfirm,AppPermission},
    data() {
        return {
            currentComponent: 'PlansTab',
            createdById: null,
            drag: false,
            showLeftRight: false,
            btnLeftFloat: -30,
            currentTab: {},
            tabs: [],
            branches: store.state.GlobalConfig?.userBranches,
            currentBranch: store.state.CurrentBranch,
            user: store.state.Auth ? store.state.Auth.user : {},
            barcode: '',
            type: null,
            printType: '',
            ticketSearchCode: null,
            content: null,
            TYPE_IMPORT, TYPE_EXPORT, TYPE_DELIVERY, TYPE_RETURN, TYPE_TRANSFER,
            entryBranch: store.state.CurrentBranch ? store.state.CurrentBranch.id : null,
            startDate: null,
            endDate: null,
        }
    },
    created() {
        this.handleTabInput();
        storeModul.dispatch('productStore/setProductIds', []);
    },
    mounted() {
        document.title = this.$t('message.headers.branch') + ' ' + this.currentBranch.name + '';
        this.rootWidth = this.$refs.saleTabRoot.offsetWidth;
        this.divSaleTab = window.jQuery('#plans-tabs');
        this.updateStyle();
    },
    computed: {
        getIndexTabNext() {
            const mapTabs = {};
            this.tabs.forEach(function (tab) {
                mapTabs[tab.id] = true;
            });

            let tabNext = 0;
            do {
                ++tabNext;
            } while (mapTabs[tabNext]);

            return tabNext;
        },
        ...mapGetters({
            productIds: 'productStore/productIds',
        }),
    },
    methods: {
        async handleTabInput(code = null) {
            emitter.emit("onLoading");
            this.tabs = [];
            const tabNext = this.getIndexTabNext;
            let activeTabNext = false;
            const query = this.$route.query;
            if (query.type) {
                let data = {};
                const type = query.type;
                if (type === 'update' && query.id) {
                    let res = await $post('/v1/plans/get-details', {id: query.id});
                    if (res && res.result) {
                        data = res.result;
                        data.originId = res.result.id;
                        data.id = +tabNext;
                        data.name = data.code;
                        data.isUpdate = true;
                    }
                } else if (type === 'create') {
                    data = {
                        id: +tabNext, 
                        name: this.$t('message.common.planDetail') + ' ' + tabNext,
                        createdBy: this.user.id,
                        statusName: this.$t('message.common.draft'),
                    };
                    data.products = [];
                }
                else if (type == 'short-inventories-plan') {
                    let params = {
                        branchId: this.currentBranch.id,
                        ids: this.productIds
                    }
                    let res = await $post('/v1/plans/get-product-for-plan-id', params);
                    data = {
                        id: +tabNext, 
                        name: this.$t('message.common.planDetail') + ' ' + tabNext,
                        createdBy: this.user.id,
                        statusName: this.$t('message.common.draft'),
                    };
                    data.products = res.result;
                }
                this.tabs.unshift(data);
                activeTabNext = true; 
            }
            if (!this.tabs.length) {
                this.addTab();
                activeTabNext = true;
            }
            if (activeTabNext) {
                if (!this.currentTab || !this.currentTab.id) {
                    this.currentTab = this.tabs[0];
                }
            } else {
                this.setCurrentTab();
            }
            emitter.emit("offLoading");
            await this.$router.replace({params: {}});
        },
        async onSelectInvoiceProcessing(code) {
            emitter.emit("onLoading");

            await this.$router.push({query: {returnInvoice: code}});
            await this.handleTabInput();

            emitter.emit("offLoading");
        },
        async getData(code, type = TYPE_IMPORT) {
            var res;
            let isCopy = this.$route.query.isCoppy;    
            if(isCopy && isCopy == 'copy')
            {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code, isCopy: isCopy});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code, isCopy: isCopy});
                }
            }
            else {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code});
                }

               
            }
             if (res.status && res.status.code == 200) {
                    return res.result;
                } else {
                    $alert(res);
                    return false;
                }
        },
        setCurrentTab() {
            const currentTabIndex = localStorage.getItem('planTabData_KH' + this.user.id);
            const branchTabs = this.tabs.filter((el) => (!el.branchId || el.branchId == this.currentBranch.id));
            if (branchTabs && branchTabs.length) {
                this.currentTab = branchTabs.find(el => (el.id == currentTabIndex)) || branchTabs[0];
            } else {
                this.currentTab = {};
            }
        },
        addTab(tab = null, order = null) {
            let id = this.getIndexTabNext;
            tab = {
                id: +id, 
                name: this.$t('message.common.planDetail') + ' ' + id,
                createdBy: this.user.id,
                statusName: this.$t('message.common.draft'),
            };
            tab.products = [];
            this.tabs.unshift(tab);
            this.currentTab = tab;

            this.updateStyle();
            this.$nextTick(() => {
                this.scrollLeftTab(0);
            })
        },
        updateStyle() {
            const width = this.tabs.length * 120;

            if (width  >= this.rootWidth) {
                this.showLeftRight = true;
                this.btnLeftFloat = -55;
            } else {
                this.showLeftRight = false;
                this.btnLeftFloat = -30;
            }
        },
        changeTab(tab) {
            localStorage.setItem('planTabData_KH' + this.user.id, tab.id);
            this.currentTab = tab;
        },
        async removeCurrentTab(isPartialOrderProcessing) {
            const currentTab = this.currentTab;

            this.closeTab(currentTab, false);

            if (currentTab.orderId && currentTab.action == ACTION_ORDER_PROCESSING) {
                const orderRelatedTab = this.tabs.find((t) => (t.orderId == currentTab.orderId && t.id != currentTab.id));

                if (orderRelatedTab) {
                    if (isPartialOrderProcessing) {
                        this.currentTab = orderRelatedTab;
                        if (confirm('Bạn vừa tạo hóa đơn từ đơn đặt hàng ' + currentTab.code +'. Bạn sẽ không xử lý tiếp với những hàng hóa chưa lấy hết và kết thúc đơn đặt hàng ngay bây giờ?')) {

                            const res = await $post('/v1/orders/saveStatus', {
                                id: currentTab.orderId,
                                action: 'complete'
                            });

                            $alert(res);
                        }
                    }
                    this.closeTab(orderRelatedTab, false);
                }
            }
        },
        onClickScroll(direction) {

            const offset = direction === 'right' ? this.divSaleTab[0].scrollLeft + 120 : this.divSaleTab[0].scrollLeft - 120;
            this.scrollLeftTab(offset)
        },
        scrollLeftTab(offset) {
            this.divSaleTab.animate({scrollLeft: offset}, 100);
        },
        confirmCloseTab(tab, confirm = true){
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                if(tab.products && tab.products.length > 0){
                    if (confirm) {
                        if (!this.$refs.ModalCancel.show(tab)) {
                            return;
                        }
                    }
                }else{
                    this.closeTab(tab);
                }
            }
        },
        closeTab(tab) {
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                this.tabs.splice(index, 1);
                this.updateStyle();
                if (this.tabs.length > 0) {
                    this.setCurrentTab();
                } else {
                    this.addTab();
                }
            }

        },
        async onSelectProduct(product) {
            if (!this.currentTab.products) {
                this.currentTab.products = [];
            } else {
                if (this.currentTab.products.length) {
                    if (this.currentTab.products.find(p => (p.id === product.id))) {
                        toastr.warning(this.$t('message.common.existProduct'));
                        return false;
                    }
                }
            }
            product.productUnitId = product.product_units[0].id;
            this.currentTab.products.unshift(product);
        },

        setEntryBranch(id) {
            this.entryBranch = id;
        },

        setStartDate(value) {
            this.startDate = value;
        },

        setEndDate(value) {
            this.endDate = value;
        },

        setBranch(branch) {
            store.commit('SetCurrentBranch', branch);
            this.$nextTick(() => {
                location.reload();
            })
        },
        logout() {
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        processBarcodeScanner(e) {
            const barcode = e.target.value; // ??
            this.$nextTick(async () => {
                // //temporarily hide

                // const res = await $get('/v1/sale/productBarcodeScanner', {product_code: barcode});
                //
                // if (res.code === 200) {
                //     const product = res.data;
                //
                //     if (product) {
                //         this.onSelectProduct(product);
                //     }
                // }
                // $alert(res);
            });
        },
        
        onPrint(type, tabData = null) {
            this.printType = type;

            if (tabData) {
                this.currentTab.code = tabData.code;
                this.currentTab.purchaseDate = tabData.purchaseDate;
                this.currentTab.delivery = tabData.delivery;
            }

            this.$nextTick(() => {
                window.print();
            });
        }
    },
    watch: {
        tabs: {
            handler: function (val) {
                localStorage.setItem('planTabData_KH' + this.user.id, JSON.stringify(val))
            },
            deep: true
        }
    }
}
</script>

<style  lang="scss">
@import '../../assets/css/vars';
    .sale-screen__main {
        padding-top: 5px;
        font-size: 14px;
    }
    .sale-tabs__root {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        .sale-tabs__scroller {
            z-index: 1;
            font-size: 22px;
            cursor: pointer;
            opacity: 0.3;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }

        .sale-tabs__btn {
            position: absolute;
            left: -55px;
            display: flex;
            height: 100%;
            align-items: center;
            z-index: 1;
        }

        .sale-tabs__btn--add {
            background-color: $base-color;
            color: #fff;
            border: 0;
            height: 100%;
            width: 50px;
            outline: 0;
            &:hover,&:focus {
                outline: 0;
                background-color: #168080;
            }
        }

        .sale-tabs__left {
            left: 50px;
        }
        .sale-tabs__right {
            right: 0;
        }
    }
    .sale-tabs {
        list-style: none;
        padding-left: 50px;
        height: 45px;
        overflow-x: hidden;
        white-space: nowrap;
        overflow-y: hidden;

        .sale-tabs__tab {
            cursor: pointer;
            color: white;
            display: inline-block;
            margin-left: 1px;
            height: 100%;
            min-width: 120px;
            background-color: #168080;
            .sale-tabs__tab-content {
                padding: 5px 10px;
                justify-content: center;
                flex-direction: column;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .sale-tabs__tab--active {
            background-color: white;
            .sale-tabs__link {
                color: $base-color;
                font-weight: bold;
            }
        }
        .sale-tabs__link {
            text-decoration: none;
            color: #ccc;
            font-size: 14px;
        }

        .sale-tabs__close {
            color: #ccc;
            &:hover {
                color: darkred;
            }
        }
    }

    .sale-form__print {
        display: none;
    }
    @media print {
        .sale-screen {
            display: none;
        }
        .sale-form__print {
            display: block;
        }
    }
</style>
