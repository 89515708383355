<template> 
      <div v-html="this.editorDataClone" class="px-2 pb-5 view-show tabInfo" style="max-height:750px;"></div>
</template>
<script>

export default {
  name: "View",
  props: ['action', 'entry', 'editorDataClone'],
  data(){
      return {              
 
      };
  },
  created() {

      },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
     
    }
  },
};
</script>

<style scoped>
 
.printBox {
  font-family: Arial, sans-serif;
  font-size: 11px;
  min-height: 740px;
}

table {
  page-break-inside: auto;
  border-collapse: collapse;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

img {
  max-width: 100%;
  height: auto;
}
.view-show .k-table,
.k-table td {
  outline: 0;
  border: 0px dotted #ccc !important;
   
}
</style>
