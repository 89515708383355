<template>

    <div ref="modalLoginZalo" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalCreateTicket" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2 border-none">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2 mb-4 pt-0 d-flex">
                    <div class="col-lg-5 text-center d-flex align-items-center justify-content-center">
                        <img src="../../../public/img/zalo-OA-icon.png" class="img-zalo-oa">
                    </div>
                    <div class="col-lg-7">
                        <h3 class="text-primary text-bold">{{ $t('message.ModalLoginZalo.title') }}</h3>
                        <div class="row m-0 p-0 my-4 border-bottom">
                            <div class="col-3 text-bold d-flex align-items-center">
                                {{ $t('message.ModalLoginZalo.refresh_token') }}<span class="text-danger">*</span>
                            </div> 
                            <div class="col-9 border-left">
                                <input type="text" v-model.trim="entry.refresh_token" 
                                    :placeholder="$t('message.ModalLoginZalo.placeholder.refresh_token')"
                                    class="form-control border-none">
                            </div>
                        </div>
                        <div class="row m-0 p-0 my-4 border-bottom">
                            <div class="col-3 text-bold d-flex align-items-center">
                                {{ $t('message.ModalLoginZalo.app_id') }}<span class="text-danger">*</span>
                            </div> 
                            <div class="col-9 border-left">
                                <input type="text" v-model.trim="entry.app_id" 
                                    :placeholder="$t('message.ModalLoginZalo.placeholder.app_id')"
                                    class="form-control border-none">
                            </div>
                        </div>
                        <div class="row m-0 p-0 my-4 border-bottom">
                            <div class="col-3 text-bold d-flex align-items-center">
                                {{ $t('message.ModalLoginZalo.app_secret') }}<span class="text-danger">*</span>
                            </div> 
                            <div class="col-9 border-left">
                                <input type="text" v-model.trim="entry.app_secret" 
                                    :placeholder="$t('message.ModalLoginZalo.placeholder.app_secret')"
                                    class="form-control border-none">
                            </div>
                        </div>
                        <div class="row m-0 p-0 my-4 border-bottom">
                            <div class="col-3 text-bold d-flex align-items-center">
                                {{ $t('message.ModalLoginZalo.webhook_secret') }}<span class="text-danger">*</span>
                            </div> 
                            <div class="col-9 border-left">
                                <input type="text" v-model.trim="entry.webhook_secret" 
                                    :placeholder="$t('message.ModalLoginZalo.placeholder.webhook_secret')"
                                    class="form-control border-none">
                            </div>
                        </div>
                        <div class="row m-0 p-0 my-4"> 
                            <div class="col-12 text-center">
                                <button class="btn btn-save" @click="save()">
                                    {{ $t('message.ModalLoginZalo.button.login') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert} from "@/lib/utils";
export default {
    name: "ModalLoginZaloOa",
    emits: ['reload'],
    components: {},
    data(){
        return {
            entry: {},
        }
    },
    methods: {
        show() {
            this.clearData();
            window.jQuery(this.$refs.modalLoginZalo).modal('show');
        },
        clearData(){
            this.entry = {
                'refresh_token' : null,
                'app_id' : null,
                'app_secret' : null,
                'webhook_secret' : null,
            };
        },
        hide(){
            this.clearData();
            window.jQuery(this.$refs.modalLoginZalo).modal('hide');
        },
        async save(){
            const res = await $post('/v1/zalo/get-author-oa', {...this.entry});
            if(res.code === 422){
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
            }else if(!res.result.status){
                let errors = res.result?.message ?? this.$t('message.ModalLoginZalo.message.login_failed');
                $alert({code: 500, message: errors});
            }
            else if(res.status.code == 200){
                const message = this.$t('message.ModalLoginZalo.message.login_success');
                $alert({code: 200, message: message});
                this.$emit('reload');
                this.hide();
            }
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style lang="scss" scoped>
.img-zalo-oa{
    width: 50% !important;
    height: 50% !important;
}
</style>