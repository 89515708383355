<template>
    <div ref="modal" class="modal fade mx-0" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h5 class="modal-title py-2" v-bind:class="{ align: isAlign }" v-if="this.multi == true">
                        {{$t('message.ProductIndex.modal.title_remove_multiple')}}
                    </h5>
                    <h5 class="modal-title py-2 px-2" v-else>
                        {{$t('message.ProductIndex.modal.title_remove')}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <div class="row" v-if="this.multi == true">
                        <div class="col-12 text-center" v-if="this.afterRemove===false">
                            <table id="modalTableChecked" class="w-100">
                                <thead>
                                <tr>
                                    <td class="pb-4">
                                        <span style="font-size: larger;font-weight: 400;color: black;">
                                            {{$t('message.ProductIndex.modal.content_remove_multiple', {number : this.IDs.length})}}
                                        </span>
                                        <br>
                                        <span style="color: gray !important">
                                            {{$t('message.ProductIndex.modal.note_remove')}}
                                        </span>
                                    </td>
                                </tr>
                                </thead>
                                <tfoot style="text-align: right !important">
                                    <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{$t('message.ProductIndex.button.skip')}}
                                    </button>
                                    <button class="btn btn-save" style="margin-right: 50px !important;" @click="deleteMultipleData()">
                                        {{$t('message.ProductIndex.button.confirm')}}
                                    </button>
                                </tfoot>
                            </table>
                        </div>
                        <div class="col-12" v-else-if="this.afterRemove===true">
                            <span style="padding-left:50px">
                                {{$t('message.ProductIndex.modal.processed_quantity')}} {{ this.IDs.length }}
                            </span><br>
                            <span style="padding-left:50px">
                                {{$t('message.ProductIndex.modal.removed_quantity')}} {{ this.doneRemove }}
                            </span><br>
                            <span style="padding-left:50px">
                                {{$t('message.ProductIndex.modal.not_remove_quantity')}} {{ this.falseRemove }}
                            </span><br>
                            <table class="table table-index table-striped" id="modalTableChecked">
                                <thead>
                                    <tr>
                                        <th scope="col" width="10%" class="text-center">
                                            {{$t('message.ProductIndex.table.combo.STT')}}
                                        </th>
                                        <th scope="col" width="20%" class="text-left">
                                            {{$t('message.ProductIndex.code')}}
                                        </th>
                                        <th scope="col" width="25%" class="text-left">
                                            {{$t('message.ProductIndex.name')}}
                                        </th>
                                        <th scope="col" width="45%" class="text-left">
                                            {{$t('message.ProductIndex.reason')}}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.dataFalseRemove.length >=1">
                                        <tr v-for="(item,index) in this.dataFalseRemove" :key="item.id" :id="'modal_' + item.id" style="height: 40px">
                                            <td v-text="index + 1" class="text-center"></td>
                                            <td  class="text-left" v-text="item.code"></td>
                                            <td  class="text-left" v-text="item.name"></td>
                                            <td  class="text-left">
                                                {{$t('message.ProductIndex.modal.categories_have_transaction')}}
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-else>
                                        <tr>
                                            <td  class="pt-4 pb-4 text-center" colspan="4">
                                                <label>
                                                    {{$t('message.ProductIndex.modal.remove_success')}}
                                                </label>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <div v-if="this.dataFalseRemove?.length > 0" class="pb-2">
                                <Pagination :value="paginate" :record="15" @change="pageChange" />
                            </div>
                            <div style="display: flex; justify-content: flex-end; width: 100%; padding-bottom: 20px;">
                                <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close" @click="clearData()" style="margin-right: 50px !important;">
                                    <i class="fa fa-ban mr-1"></i>
                                    {{$t('message.ProductIndex.button.close')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <template v-if="this.afterRemove == false">
                            <template v-for="entry in this.data" :key="entry.id">
                                <div class="col-12 pb-3">
                                    <h6>
                                        {{$t('message.ProductIndex.modal.content_remove', {name: entry.name})}}
                                    </h6>
                                    <h6>
                                        {{$t('message.ProductIndex.modal.content_remove_confirm')}}
                                    </h6>
                                </div>
                                <div class="col-12 d-flex justify-content-end">
                                    <button class="btn btn-danger mr-2" @click="deleteData()">
                                        <i class="fa fa-check mr-1"></i>
                                        {{$t('message.ProductIndex.button.agree')}}
                                    </button>
                                    <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{$t('message.ProductIndex.button.skip')}}
                                    </button>
                                </div>
                            </template>
                        </template>
                        <template v-else>
                            <template v-for="entry in this.data" :key="entry.id">
                                <div class="col-12 pb-4">
                                    <h6>
                                        {{$t('message.ProductIndex.modal.product_name', {name: entry.name})}}
                                        <b>{{$t('message.ProductIndex.modal.have_transaction')}}</b>
                                        {{$t('message.ProductIndex.modal.not_remove')}}
                                    </h6>
                                    <h6>
                                        {{$t('message.ProductIndex.modal.reason')}}
                                    </h6>
                                </div>
                                <div class="col-12 d-flex justify-content-end">
                                    <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{$t('message.ProductIndex.button.skip')}}
                                    </button>
                                </div>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.table thead th
#modalConfirm .modal-header {
    border-bottom: 0 !important;
}
.align{
    width: 100%;
    text-align: center !important;
}
</style>
<script>
import {$post, $alert} from "@/lib/utils";
import Pagination from "@/components/Pagination";
export default {
    name: "ModalProductRemove",
    components: {Pagination},
    data(){

        return {
            data: [],
            IDs: [],
            arrayIdRemoveFalse: [],
            afterRemove: false,
            isAlign: false,
            doneRemove: null,
            falseRemove: null,
            dataFalseRemove: [],
            paginate: {},
            multi: true,
        }
    },
    props: {

    },
    methods: {
        show(data, arrayIDs) {
            this.data = this.IDs = [];
            if(data != null){
                this.data = [data];
                this.multi = false;
                this.afterRemove = false;
            } else if(arrayIDs != null){
                this.IDs =  arrayIDs;
                this.multi = true;
                this.dataFalse = [];
                this.afterRemove = false;
                if(this.isAlign === false){
                    this.isAlign = !this.isAlign;
                }
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        async loadData(page){
            const res = await $post('/v1/products/get-product-by-ids', {
                id: this.arrayIdRemoveFalse,
                page: page
            });
            this.dataFalseRemove = res.data;
            this.paginate.totalRecord = res.total;
            this.paginate.currentPage = res.current_page;
            this.paginate.lastPage = res.last_page;
        },
        async deleteMultipleData(){
            this.isAlign = !this.isAlign;
            const res = await $post('/v1/products/remove', { id: this.IDs, multi: this.multi});
            this.falseRemove = res.arrayIDs.length;
            this.doneRemove = (this.IDs.length - res.arrayIDs.length);
            this.arrayIdRemoveFalse = res.arrayIDs;
            this.loadData(1);
            this.afterRemove = true;
            this.$emit('inputData','1');
            this.$emit('removeData');
        },
        async deleteData(){
            this.isAlign = !this.isAlign;
            let id;
            this.data.forEach(entry => {
                id = entry.id;
            });
            const res = await $post('/v1/products/remove', { id: id, multi: this.multi});
            if(res.code == 200){
                if(res.status == false){
                    this.afterRemove = true;
                }else{
                    $alert(res);
                    this.$emit('inputData','1');
                    window.jQuery(this.$refs.modal).modal('hide');
                }
            }
        },
        clearData(){
            this.dataFalseRemove = [];
            this.$emit('inputData','1');
        },
        async pageChange(page) {
            this.loadData(page);
        },
    }
}
</script>

