<template>    
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{(this.isEdit && this.id != null) ? $t('message.ModalCreateRouteOfUse.title_update') : $t('message.ModalCreateRouteOfUse.title_create')}}</h5>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-12">
                                    <b>{{ $t('message.ModalCreateRouteOfUse.name') }}</b>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <InputText v-model="entry.name"
                                        :errors="errors.name"
                                        :placeholder="$t('message.ModalCreateRouteOfUse.placeholder.name')" :borderBottom="true" :paddingLeftNone="true"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <button type="button" class="btn btn-save mr-2" v-if="this.id"  @click="update">
                                <i class="fa fa-save mr-1"></i>
                                {{ $t('message.ModalCreateRouteOfUse.button.update') }}
                            </button>
                            <button type="button" class="btn btn-save mr-2" v-if="!this.id" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{ $t('message.ModalCreateRouteOfUse.button.create') }}
                            </button>
                            <button type="button" class="btn btn-default mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.ModalCreateRouteOfUse.button.skip') }}
                            </button>
                            <button type="button" class="btn btn-danger mr-2" v-if="this.id" @click="del()">
                                <i class="fa fa-trash-alt mr-1"></i>
                                {{ $t('message.ModalCreateRouteOfUse.button.remove') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import InputText from "@/components/forms/InputText";
import toastr from 'toastr';
import {$post, $alert, $get, scrollToElement, isChangeData, cloneObject} from "@/lib/utils";
import QSelect from "@/components/QSelect";
import emitter from "@/lib/emitter";
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '@/components/AppPermission';
// import { valueToNode } from "@babel/types";
export default {
    name: "ModalCreateRouteOfUse",
    components: { InputText, QSelect, ModalConfirm, AppPermission},
    props: {},
    data() {
        return {
            entry: {
                name: '',
                parentId: null
            },
            errors: {},
            default: {
                name: '',
                parentId: null
            },
            contentConfirm: '',
            titleConfirm: '',
            id: null,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
            };
            this.id = null;
        },
        async show(id = null) { 
            this.clearInput();
            if(id !== null){
                this.isEdit = true;
                await this.load(id);
            }
            this.default = {...this.entry};
            this.id = id;
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async load (id) {
            const res = await $post('/v1/sync-medicine/show', {id: id});
            if (res && res.result) {
                this.entry = res.result;
            } 
        },
        async save () {
            const res = await $post('/v1/sync-medicine/create', {...this.entry});
            if(res.code === 422){
                toastr.error(res.messages.errors.name[0]);
                return;
            }
            else if (res.status.code == 200) {
                $alert({ code: 200, message: this.$t("message.ModalCreateRouteOfUse.alert.create_success")});
            }
            else {
                $alert({ code: 500, message: this.$t("message.ModalCreateRouteOfUse.alert.create_error")});
            }
            await emitter.emit("getRouteOfUse");
            this.hide();
        },
        async update () {
            const res = await $post('/v1/sync-medicine/update', {...this.entry});
            if(res.code === 422){
                toastr.error(res.messages.errors.name[0]);
                return;
            }
            else if (res.status.code == 200) {
                $alert({ code: 200, message: this.$t("message.ModalCreateRouteOfUse.alert.update_success")});
            } else {
                $alert({ code: 500, message: this.$t("message.ModalCreateRouteOfUse.alert.update_error")});
            }
            await emitter.emit("getRouteOfUse");
            this.hide()
        },
        async del () {
            const res = await $post('/v1/sync-medicine/delete', {...this.entry});
            if (res.status.code == 200) {
                $alert({ code: 200, message: this.$t("message.ModalCreateRouteOfUse.alert.delete_success")});
            } else {
                $alert({ code: 500, message: this.$t("message.ModalCreateRouteOfUse.alert.delete_error")});
            }
            await emitter.emit("getRouteOfUse");
            await emitter.emit("removeRouteOfUse", this.id);
            this.hide()
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
    #modalChecked{
        overflow-x: hidden;
        overflow-y: auto;
    }
    #modalTableChecked thead th {
        border-bottom: 0 !important;
    }
    @media (max-width: 1280px) {
        .modal-xl {
            width: auto !important;
        }
    }
    .table thead th #modalChecked .modal-header {
        border-bottom: 0 !important;
    }

</style>