<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">{{$t('message.VoucherIndex.modal.create_voucher_code.title')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 pl-1" v-for="(v, index) in listCode.voucherCode" :key="index">
                        <div class="col-3 d-flex align-items-center text-bold">{{$t('message.VoucherIndex.modal.create_voucher_code.voucher_code')}}</div>
                        <div class="col-9">
                            <div class="form-group mb-0 position-relative">
                                <input type="text" class="form-control input-custom pr-4" autocomplete="off" v-model.trim="v.code">
                                <i class="fa fa-times mr-2 text-gray" @click="removeVoucher(index)"></i>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <button type="button" class="btn btn-white mr-2 border" @click="addMoreVoucher">
                                <i class="fa fa-plus mr-1"></i>
                                {{$t('message.VoucherIndex.modal.create_voucher_code.button.add')}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none">
                    <button type="button" class="btn btn-save mr-2" @click="createVoucher()">
                        <i class="fa fa-save mr-1"></i>
                        {{$t('message.VoucherIndex.modal.create_voucher_code.button.save')}}
                    </button>
                    <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                        <i class="fa fa-ban mr-1"></i>
                        {{$t('message.VoucherIndex.modal.create_voucher_code.button.skip')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$post, $alert, $get, removeVietnameseTones} from "@/lib/utils";
import toastr from 'toastr';
    export default {
        name: "VoucherCodeCreate",
        components: {},
        props: [],
        data() {
            return {
                listCode: {
                    voucherCode: [
                        { code: null },
                    ],
                    id: ''
                }
               
            }
        },
        mounted() {
        },
        methods: {
            show(id) {
                this.listCode.id = id
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                this.removeData();
                window.jQuery(this.$refs.modal).modal('hide');
            },
            submit(){
                this.$emit('createVoucherCode', this.listCode.voucherCode);
                this.hide();
            },
            removeData(){
                this.listCode.voucherCode = [
                    {code: null}
                ]
            },
            addMoreVoucher(){
                let fill = true;
                this.listCode.voucherCode.forEach(el => {
                    if(el.code == null || el.code == ''){
                        fill = false;
                    }
                })
                if(fill){
                    this.listCode.voucherCode.push({
                        code: null
                    })
                }
            },
            removeVoucher(index){
                if(index != 0){
                    this.listCode.voucherCode.splice(index, 1);
                }else{
                    this.listCode.voucherCode[index].code = null;
                }
            },
            async createVoucher () {
                let emptyValue = false;
                if(this.listCode.voucherCode){
                    this.listCode.voucherCode.forEach((voucher) => {
                        if(voucher.code == null || voucher.code == ''){
                            emptyValue = true;
                        }
                    })
                }
                if(emptyValue){
                    toastr.error(this.$t('message.VoucherIndex.modal.create_voucher_code.alerts.validate_voucher_code'));
                }else{
                    const data = await $post('/v1/vouchers/create', {...this.listCode});
                    let msg = '';
                    if(data.result != null){
                        if(data.result.fail){
                            data.result.fail.forEach(item => {
                                msg += item + ','
                            });
                            $alert({code: 422, message: this.$t('message.VoucherIndex.modal.create_voucher_code.alerts.validate_voucher_code_is_exist', {code: msg})});
                        }
                    }
                    else if (data.status.code == 200) {
                        $alert({code: 200, message: this.$t('message.VoucherIndex.modal.create_voucher_code.alerts.success')});
                        this.$emit('createVoucherCode');
                        this.hide();
                    }
                }
            }
        },
        beforeUnmount() {
            this.hide();
        },
    }
</script>
<style scoped>
.fa-times{
    position: absolute;
    right: 0;
    top: 40%;
}
</style>
