
<template>
    <AppPermission :permission="this.$permissions.promotions.data?? '' ">
        <div >
        <div class="container my-4">
            <div class="row m-0 p-0">
            <div class="mainLeft">
                <div class="main-title">
                <span>
                    {{$t('message.DetailOrdersCard.promotion')}}
                </span>
                </div>
                <div>
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row">
                    <div class="col-8 text-title">
                        <label>{{$t('message.common.numberRecord')}}:</label>
                    </div>
                    <div class="col-4">
                        <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                    </div>
                </div>
                </div>
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">
                                    {{$t('message.common.branch')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="filter.branches" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'branches'"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Filter trạng thái -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{$t('message.common.status')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="status">
                        <template v-if="this.optionStatus">
                            <div class="row mb-1 ml-0" v-for="option in this.optionStatus" :key="option.id">
                                <label class="container-radio ml-2">{{option.label}}
                                    <input type="radio" name="statusPromotion" v-model="this.filter.status" :checked="this.filter.status === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('status', option.value)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
                <!-- Filter hiệu lực -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{$t('message.PriceListsIndex.effect')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="effectTime">
                        <template v-if="this.optionEffectTime">
                            <div class="row mb-1 ml-0" v-for="option in this.optionEffectTime" :key="option.id">
                                <label class="container-radio ml-2">{{option.label}}
                                    <input type="radio" name="effectTime" v-model="this.filter.statusEffectTime" :checked="this.filter.statusEffectTime === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('effectTime', option.value)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
            
            <div class="mainRight">
                <div>
                    <div class="row">
                        <form class="col-lg-5">
                            <InputQuickSearch :placeholder="$t('message.common.searchPromotion')" @filterSearch="changeQuickSearch" :promotionSearch="true"/>
                        </form>
                        <div class="col-lg-7 d-flex justify-content-end">
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{$t('message.button.removeFilter')}}
                            </button>
                            <AppPermission :permission="this.$permissions.promotions.create?? '' ">
                                <button class="btn btn-save ml-1" @click="createPromotion()">
                                    <i class="fa fa-plus"></i> 
                                    {{$t('message.DetailOrdersCard.promotion')}} 
                                </button>
                            </AppPermission>
                            <div id="loc" v-on:mouseleave="onMouseLeave">
                                <SelectField :fields="fields" label="promotions"></SelectField>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                            <a href="javascript:;" @click="removeChecked()">
                                <i class="fa fa-times text-danger" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row mx-0 my-2" id="divTable">
                        <table class="table table-index table-striped lstProduct" >
                            <thead>
                                <tr>
                                    <!-- <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th> -->
                                    <template v-for="field in fields" :key="field.field">
                                        <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                            <TableSortField :current="sortData.field" :no-sort="!field.isColumn"
                                                            :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                        </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="entries.length > 0">
                                    <template v-for="(entry, index) in entries" :key="entry.id" >
                                        <tr class="master-row" :class="entry.id === this.promotionId ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                            <!-- <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                        :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td> -->
                                            <template v-for="field in fields" :key="field.field">
                                                <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                    <a :href="'#row_' + index" class="text-dark tagA" @click="detailPromotion(entry.id, entry)">
                                                        <div v-if="field.field==='startDate'" :style="field.style" :class="field.class"><span v-text="d(entry.startDate)"></span></div>
                                                        <div v-else-if="field.field==='endDate'" :style="field.style" :class="field.class"><span v-text="d(entry.endDate)"></span></div>
                                                        <div v-else-if="field.field==='promotion'" :style="field.style" :class="field.class"><span v-text="entry.promotion"></span></div>
                                                        <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :class="field.class" class="description">
                                                        </div>
                                                    </a>
                                                </td>
                                            </template>
                                        </tr>
                                        <tr v-if="promotionId === entry.id" :class="[ entry.id === promotionId ? 'backgroup__detail border__header-detail border-top-0' : '', errorClass]">
                                            <!-- <td></td> -->
                                            <td :colspan="fields.length" class="pb-0">
                                                <div class="row detail-prd-row">
                                                    <div class="col-2 mt-2" @click="showContent('info')">
                                                        <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.ProductIndex.info')}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2 mt-2" @click="showContent('promotionForm')">
                                                        <span :class="[ headerActive === 'promotionForm' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.common.formOfPromotion')}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2 mt-2" @click="showContent('history-order')">
                                                        <span :class="[ headerActive === 'history-order' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.common.historyPromotionOrder')}}
                                                        </span>
                                                    </div>
                                                    <div class="col-2 mt-2" @click="showContent('history-invoice')">
                                                        <span :class="[ headerActive === 'history-invoice' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.common.historyPromotionInvoice')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="promotionId === entry.id && headerActive === 'info'">
                                            <!-- <td></td> -->
                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                <div class="mt-2 pb-4 detail__user pl-2">
                                                    <div class="row mt-2 detail-prd-row">
                                                        <div class="col-6 px-4">
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4">
                                                                    {{ $t('message.noticeDetail.tables.promotion.code') }}:
                                                                </div>
                                                                <div class="col-8 ow-break-word">                                                            
                                                                    <b>{{ entry.code }}</b>
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4 lableOS">
                                                                    {{$t('message.common.time')}}:
                                                                </div>
                                                                <div class="col-8 ow-break-word lableOS pr-0">                                                            
                                                                    {{ d(entry.startDate) + ' - ' + d(entry.endDate)}}
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4 lableOS">
                                                                    {{$t('message.DateRangePicker.byMonth')}}:
                                                                </div>
                                                                <div class="col-8 ow-break-word lableOS pr-0">   
                                                                    <template v-if="entry.monthly">
                                                                        <template v-for="(month, i) in entry.monthly" :key="month">
                                                                            <span v-text="i < (entry.monthly?.length - 1) ? renderFieldById(month, this.$constant.monthly) + ', ' : renderFieldById(month, this.$constant.monthly)"></span>
                                                                        </template>
                                                                    </template>                                                         
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4 lableOS">
                                                                    {{$t('message.DateRangePicker.byDay')}}:
                                                                </div>
                                                                <div class="col-8 ow-break-word lableOS pr-0">     
                                                                    <template v-if="entry.byDate">
                                                                        <template v-for="(day, i) in entry.byDate" :key="day">
                                                                            <span v-text="i < (entry.byDate?.length - 1) ? renderFieldById(day, this.$constant.byDate) + ', ' : renderFieldById(day, this.$constant.byDate)"></span>
                                                                        </template>
                                                                    </template>                                                         
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4 lableOS">
                                                                    {{$t('message.RevenueOriginal.dayOfWeek')}}:
                                                                </div>
                                                                <div class="col-8 ow-break-word lableOS pr-0">    
                                                                    <template v-if="entry.dayOfWeek">
                                                                        <template v-for="(day, i) in entry.dayOfWeek" :key="day">
                                                                            <span v-text="i < (entry.dayOfWeek?.length - 1) ? renderFieldById(day, this.$constant.dayOfWeeks) + ', ' : renderFieldById(day, this.$constant.dayOfWeeks)"></span>
                                                                        </template>
                                                                    </template>                                                          
                                                                </div>
                                                            </div>
                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                <div class="col-4 lableOS">
                                                                    {{$t('message.RevenueOriginal.hour')}}:
                                                                </div>
                                                                <div class="col-8 ow-break-word lableOS pr-0">      
                                                                    <template v-if="entry.hours">
                                                                        <template v-for="(hour, i) in entry.hours" :key="hour">
                                                                            <span v-text="i < (entry.hours?.length - 1) ? renderFieldById(hour, this.$constant.hours) + ', ' : renderFieldById(hour, this.$constant.hours)"></span>
                                                                        </template>
                                                                    </template>                                                        
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-6 px-4">
                                                            <div class="mx-1">
                                                                <div class="col-12">
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-4 lableOS">
                                                                            {{$t('message.CouponIndex.name')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{ entry.name }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-4">
                                                                            {{$t('message.common.status')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{ entry.status }}
                                                                        </div>
                                                                    </div>
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-4">
                                                                            {{$t('message.CouponIndex.note')}}:
                                                                        </div>                                                                
                                                                        <div class="col-8 ow-break-word">                                                                    
                                                                            {{entry.description}}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                         <AppPermission :permission="this.$permissions.promotions.update?? '' ">
                                                        <button type="button" class="btn btn-save mx-1" @click="updatePromotion(entry)">
                                                            <i class="fa fa-check-square"></i>
                                                            {{$t('message.CouponIndex.button.update')}}
                                                        </button>
                                                        </AppPermission>
                                                         <AppPermission :permission="this.$permissions.promotions.create?? '' ">
                                                        <button type="button" class="btn btn-save mx-1 pr-4" @click="copy(entry, 'copy')">
                                                            <i class="fa fa-copy" aria-hidden="true"></i>
                                                            {{$t('message.CouponIndex.button.copy')}}
                                                        </button>
                                                        </AppPermission>
                                                         <AppPermission :permission="this.$permissions.promotions.remove?? '' ">
                                                            <button type="button" class="btn btn-danger ml-1 mr-4" @click="RemovePromotion(entry.id, entry.code)">
                                                                <i class="fa fa-trash-alt"></i>
                                                                {{$t('message.CouponIndex.button.remove')}}
                                                            </button>
                                                        </AppPermission>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="promotionId === entry.id && headerActive === 'promotionForm'">
                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                <div class="row detail-prd-row mx-0 mt-3 pr-4 pb-4">
                                                    <div class="col-12 p-0 text-capitalize">
                                                        <h6 class="text-bold text-primary" v-text="entry.promotion"></h6>
                                                        <i v-text="$t('message.common.promotionProductDonateVoucherNote')" v-if="entry.promotionFromId === this.$constant.promotion_product_donate_voucher"></i>
                                                    </div>
                                                    <div class="col-12 p-0">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_invoice_discount">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto text-right">{{$t('message.InvoiceIndex.discount')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_invoice_donate_product">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.giftGroup')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.giftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_invoice_discount_on_product">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.categoriesAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.productsAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_invoice_donate_voucher">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.common.donateVoucher')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_product_discount">
                                                                    <th scope="col" class="pl-4">{{$t('message.CouponIndex.categories')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.CouponIndex.product')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.common.categoriesAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.common.productsAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check">{{$t('message.common.quantityDiscount')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_product_donate_product">
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.CouponIndex.categories')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-auto">{{$t('message.CouponIndex.product')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.giftGroup')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-auto">{{$t('message.common.giftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityGift')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_product_allow_quantity">
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.CouponIndex.categories')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.CouponIndex.product')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto text-right">{{$t('message.common.pricePromotion')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_product_donate_voucher">
                                                                    <th scope="col" class="pl-4">{{$t('message.CouponIndex.categories')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.CouponIndex.product')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4">{{$t('message.common.donateVoucher')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_all_invoice_discount">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftCategories')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto text-right">{{$t('message.InvoiceIndex.discount')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_all_donate_product">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftCategories')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.giftGroup')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.giftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                                <tr class="backgroup__detail table-header border-1" v-if="entry.promotionFromId === this.$constant.promotion_all_product_discount">
                                                                    <th scope="col" class="pl-2 cell-status-coupon text-right">{{$t('message.common.totalAmountFrom')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftCategories')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.withGiftProduct')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.common.quantityBuy')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.categoriesAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4 cell-code-coupon">{{$t('message.common.productsAreDiscounted')}}</th>
                                                                    <th scope="col" class="pl-4 cell-check text-right">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <template v-if="entry.promotion_options?.length > 0">
                                                                    <tr class="table-header border" v-for="(option, index) in entry.promotion_options" :key="option.id" :class="index % 2 ? 'bg-white' : 'bg-index-promotion'">
                                                                        <!-- khuyến mại hóa đơn: giảm giá hóa đơn -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_invoice_discount">
                                                                            <td scope="col" class="pl-2 cell-status-coupon text-right font-weight-normal" v-text="n(option.totalAmount)"></td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal" v-text="option.discountValue && !option.discountRatio ? n(option.discountValue) : (option.discountRatio ? n(option.discountRatio) + '%' : null)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn: tặng hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_invoice_donate_product">
                                                                            <td scope="col" class="pl-2 cell-status-coupon text-right font-weight-normal pt-3" v-text="n(option.totalAmount)"></td>
                                                                            <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <span v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal pt-3" v-text="n(option.totalGift)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn: giảm giá hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_invoice_discount_on_product">
                                                                            <td scope="col" class="pl-2 cell-status-coupon text-right font-weight-normal pt-3" v-text="n(option.totalAmount)"></td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal pt-3" v-text="option.discountValue && !option.discountRatio ? n(option.discountValue) : (option.discountRatio ? n(option.discountRatio) + '%' : null)"></td>
                                                                            <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <span v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal pt-3" v-text="n(option.totalQuantityDiscount)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn: tặng voucher -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_invoice_donate_voucher">
                                                                            <td scope="col" class="pl-2 cell-status-coupon text-right font-weight-normal pt-3" v-text="n(option.totalAmount)"></td>
                                                                            <td scope="col" class="pl-4 cell-code-coupon font-weight-normal">
                                                                                <template v-if="option.receive_vouchers?.length > 0">
                                                                                    <span v-for="voucher in option.receive_vouchers" :key="voucher.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="voucher.name"> {{ voucher.name }}</span>
                                                                                    </span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal pt-3" v-text="n(option.totalVoucher)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hàng: giảm giá hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_product_discount">
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalBuyQuantity)"></td>
                                                                            <td scope="col" class="pl-4 cell-auto text-right font-weight-normal pt-3" v-text="option.discountValue && !option.discountRatio ? n(option.discountValue) : (option.discountRatio ? n(option.discountRatio) + '%' : null)"></td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <template v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalQuantityDiscount)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hàng: tặng hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_product_donate_product">
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalBuyQuantity)"></td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <template v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalGift)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hàng: tặng voucher -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_product_donate_voucher">
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalBuyQuantity)"></td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.receive_vouchers?.length > 0">
                                                                                    <template v-for="voucher in option.receive_vouchers" :key="voucher.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="voucher.name"> {{ voucher.name }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalVoucher)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn và hàng hóa: giảm giá hóa đơn -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_all_invoice_discount">
                                                                            <th scope="col" class="pl-2 text-right font-weight-normal" v-text="n(option.totalAmount)"></th>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.attachQuantity)"></td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal" v-text="option.discountValue && !option.discountRatio ? n(option.discountValue) : (option.discountRatio ? n(option.discountRatio) + '%' : null)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn và hàng hóa: tặng hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_all_donate_product">
                                                                            <th scope="col" class="pl-2 text-right font-weight-normal" v-text="n(option.totalAmount)"></th>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.attachQuantity)"></td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <template v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalGift)"></td>
                                                                        </template>
                                                                        <!-- khuyến mại hóa đơn và hàng hóa: giảm giá hàng -->
                                                                        <template v-if="entry.promotionFromId === this.$constant.promotion_all_product_discount">
                                                                            <th scope="col" class="pl-2 text-right font-weight-normal" v-text="n(option.totalAmount)"></th>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategoriesCondition">
                                                                                    <template v-if="option.condition_categories?.length > 0">
                                                                                        <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.condition_products?.length > 0">
                                                                                    <template v-for="product in option.condition_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.attachQuantity)"></td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal" v-text="option.discountValue && !option.discountRatio ? n(option.discountValue) : (option.discountRatio ? n(option.discountRatio) + '%' : null)"></td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="!option.isFullCategories">
                                                                                    <template v-if="option.receive_categories?.length > 0">
                                                                                        <template v-for="cate in option.receive_categories" :key="cate.id">
                                                                                            <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 font-weight-normal">
                                                                                <template v-if="option.receive_products?.length > 0">
                                                                                    <template v-for="product in option.receive_products" :key="product.id">
                                                                                        <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                    </template>
                                                                                </template>
                                                                            </td>
                                                                            <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(option.totalQuantityDiscount)"></td>
                                                                        </template>
                                                                    </tr>
                                                                    <!-- khuyến mại hàng: giá bán theo số lượng mua -->
                                                                    <template v-if="entry.promotionFromId === this.$constant.promotion_product_allow_quantity">
                                                                        <template  v-for="option in entry.promotion_options" :key="option.id">
                                                                            <template v-if="option.whenBuying && option.whenBuying?.length > 0">
                                                                                <tr class="table-header border" v-for="(optBuy, index) in option.whenBuying" :key="optBuy.id" :class="index % 2 ? 'bg-white' : 'bg-index-promotion'">
                                                                                    <td scope="col" class="pl-4 font-weight-normal">
                                                                                        <template v-if="!option.isFullCategoriesCondition">
                                                                                            <template v-if="option.condition_categories?.length > 0">
                                                                                                <template v-for="cate in option.condition_categories" :key="cate.id">
                                                                                                    <span class="promotion-option-item p-2 m-1" v-if="cate.categoryName"> {{ cate.categoryName }}</span>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span class="promotion-option-item p-2 m-1">{{$t('message.CouponIndex.all_categories')}}</span>
                                                                                        </template>
                                                                                    </td>
                                                                                    <td scope="col" class="pl-4 font-weight-normal">
                                                                                        <template v-if="option.condition_products?.length > 0">
                                                                                            <template v-for="product in option.condition_products" :key="product.id">
                                                                                                <span class="promotion-option-item p-2 m-1" v-if="product.productName"> {{ product.productName }}</span>
                                                                                            </template>
                                                                                        </template>
                                                                                    </td>
                                                                                    <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="n(optBuy.quantity)"></td>
                                                                                    <td scope="col" class="pl-4 text-right font-weight-normal pt-3" v-text="optBuy.discountValue && !optBuy.discountRatio ? n(optBuy.discountValue) : (optBuy.discountRatio ? n(optBuy.discountRatio) + '%' : null)"></td>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <div class="no-data border border-top-0" v-if="entry.promotion_options?.length == 0">
                                                            <span>{{$t('message.common.notFound')}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="promotionId === entry.id && headerActive === 'history-order'">
                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                <div class="row detail-prd-row mx-0 mt-3 pr-4 pb-4">
                                                    <div class="col-12 p-0">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="pl-2 cell-auto">{{$t('message.PurchaseOrderIndex.tabs.table.history_payment.code')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.common.time')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.customerIndex.sellerName')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.headers.customers')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.OrderIndex.total')}}</th>
                                                                    <th scope="col" class="pl-4 cell-status-coupon">{{$t('message.common.promotionValue')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="table-header border" v-if="entry.order?.length > 0">
                                                                    <td scope="col" class="pl-2 cell-auto text-primary"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto">{{ n(entry.totalOrderValue) }}</td>
                                                                    <td scope="col" class="pl-4 cell-status-coupon text-center">{{ n(entry.totalPromotionOrderValue) }}</td>
                                                                </tr>
                                                                <template v-if="entry.order?.length > 0">
                                                                    <tr class="table-header border " v-for="history in entry.order" :key="history.id">
                                                                        <td scope="col" class="pl-2 cell-auto text-primary">
                                                                            <a :href="'/orders/index?code=' + history.code" target="_blank" class="text-decoration-none text-primary">{{ history.code }}</a>
                                                                        </td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ dt(history.purchaseDate) }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ history.soldByName }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ history.customerName }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ n(history.total) }}</td>
                                                                        <td scope="col" class="pl-4 cell-status-coupon text-center">{{ n(history.promotionValue) }}</td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <div class="no-data border border-top-0" v-if="!entry.order || entry.order?.length == 0">
                                                            <span>{{$t('message.common.notFound')}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__body-detail" v-if="promotionId === entry.id && headerActive === 'history-invoice'">
                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                <div class="row detail-prd-row mx-0 mt-3 pr-4 pb-4">
                                                    <div class="col-12 p-0">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="pl-2 cell-auto">{{$t('message.PurchaseOrderIndex.tabs.table.history_payment.code')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.common.time')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.customerIndex.sellerName')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.headers.customers')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.common.branch')}}</th>
                                                                    <th scope="col" class="pl-4 cell-auto">{{$t('message.StatisticalInvoice.revenue')}}</th>
                                                                    <th scope="col" class="pl-4 cell-status-coupon text-center">{{$t('message.common.promotionValue')}}</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr class="table-header border" v-if="entry.invoice?.length > 0">
                                                                    <td scope="col" class="pl-2 cell-auto text-primary"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto"></td>
                                                                    <td scope="col" class="pl-4 cell-auto">{{ n(entry.totalInvoiceValue) }}</td>
                                                                    <td scope="col" class="pl-4 cell-status-coupon text-center">{{ n(entry.totalPromotionInvoiceValue) }}</td>
                                                                </tr>
                                                                <template v-if="entry.invoice?.length > 0">
                                                                    <tr class="table-header border " v-for="history in entry.invoice" :key="history.id">
                                                                        <td scope="col" class="pl-2 cell-auto text-primary">
                                                                            <a :href="'/invoices/index?code=' + history.code" target="_blank" class="text-decoration-none text-primary">{{ history.code }}</a>
                                                                        </td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ dt(history.purchaseDate) }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ history.soldByName }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ history.customerName }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ history.branchName }}</td>
                                                                        <td scope="col" class="pl-4 cell-auto">{{ n(history.total) }}</td>
                                                                        <td scope="col" class="pl-4 cell-status-coupon text-center">{{ n(history.promotionValue) }}</td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <div class="no-data border border-top-0" v-if="!entry.invoice || entry.invoice?.length == 0">
                                                            <span>{{$t('message.common.notFound')}}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row mx-0 my-3 pr-4">
                                                    <div class="col-12 flex-end pr-0">
                                                        <AppPermission :permission="this.$permissions.promotions.export?? '' ">
                                                            <button type="button" class="btn btn-secondary text-white mr-4" @click="exportHistoryPromotion(entry.invoice)">
                                                                <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                {{$t('message.button.export.name')}}
                                                            </button>
                                                        </AppPermission>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr class="detail-row bg-white border__footer-detail" v-if="promotionId === entry.id"></tr>
                                    </template>
                                </template>
                                <template v-if="entries.length == 0 && !this.loading">
                                    <tr>
                                        <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                            <div class="row detail-prd-row mx-0">
                                                <div class="col-12 text-center text-secondary mt-5">
                                                    <h3>
                                                        <i class="fa fa-file mr-2 text-secondary"></i>
                                                        <i class="fa fa-exclamation"></i>
                                                    </h3>
                                                </div>
                                                <div class="col-12 text-center my-10 text-secondary mb-5">
                                                    <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                    <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </div>
        <ModalCreateUpdatePromotion ref="ModalCreateUpdatePromotion" @update="clearFilter"/>
        <ModalRemovePromotions ref="ModalRemovePromotions" @reloadData="this.load()" />
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import SelectField from "@/components/SelectField";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import {$post} from "../../lib/utils";
    import store from "@/store";   
    import emitter from "@/lib/emitter";
    import ModalCreateUpdatePromotion from "./ModalCreateUpdatePromotion.vue";
    import ModalRemovePromotions from './ModalRemovePromotions';
    import http from '@/services/api';
    import SelectSearch from "@/components/SelectSearch.vue";
    
    import AppPermission from '@/components/AppPermission.vue';
    import { mapGetters } from "vuex";
    import storeModul from "@/storeModul";
    export default {
        name: "PromotionIndex",
        components: {TableSortField, Pagination, InputQuickSearch, SelectField, ModalCreateUpdatePromotion, ModalRemovePromotions, SelectSearch, AppPermission},
        mounted() {
            document.title = this.$t('message.DetailOrdersCard.promotion') + ' | OmiPOS';
            const fields = localStorage.getItem('promotionsSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if (query['code'] != null) {
                this.filter['keyword'] = query['code'];
                this.loadDetail();
            } else {
                this.clearFilter();
            }
        },
        data() {
            const query = getSearchQuery();
            const globalConfig = store.state.GlobalConfig;
            return {
                filter: {
                    keyword: '',
                    status: '',
                    statusEffectTime: '',
                    branches: null,
                },
                filterEntry: {
                    keyword: '',
                    status: '',
                    statusEffectTime: '',
                    branches: null,
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                optionStatus: [
                    { id: null, label: this.$t('message.common.all'), value: '' },
                    { id: 1, label: this.$t('message.CouponIndex.modal.create_or_update.status_active'), value: 1 },
                    { id: 0, label: this.$t('message.CouponIndex.modal.create_or_update.status_inactive'), value: 0 },
                ],
                optionEffectTime: [
                    { id: 0, label: this.$t('message.common.all'), value: '' },
                    { id: 1, label: this.$t('message.common.effect'), value: 1 },
                    { id: 2, label: this.$t('message.common.expire'), value: 2 },
                ],
                numberRecord: 15,
                branches: globalConfig.branches,
                roles: globalConfig.roles,
                user: store.state.Auth.user,
                arrayChecked: [],
                loading: false,
                headerActive: 'info',
                promotionId: null,
                fixed: false,
                title: '',
                content: '',
                type: '',
                isFilter: false,
            }
        },
        methods: {
            RemovePromotion(id, code){                
                this.$refs.ModalRemovePromotions.show(id, code);
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/promotions/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterEntry).forEach(key => {
                    this.filterEntry[key] = '';
                });
                await emitter.emit("clearQuickSearch");
                this.arrayChecked = [];
                this.filter.branches = store.state.CurrentBranch?.id;
                this.branches.search = null;
                this.numberRecord = 15;
                this.promotionId = null;
                this.isFilter = false;
                this.load();
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                this.promotionId = null;
                this.filter.branches = store.state.CurrentBranch?.id;
                this.isFilter = false;
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailPromotion(entry.id);
                });
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            async doFilterSearch(v){
                const query = clone(this.filter);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filter = {...query};
                this.isFilter = true;
                await this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filter);
                query[field] = value;
                query['page'] = 1;
                this.filter = {...query};
                this.isFilter = true;
                await this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.filter);
                query['page'] = page;
                this.filter = {...query};
                this.isFilter = true;
                await this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filter);
                query['page'] = 1;
                this.filter = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filter);
                Object.keys(this.filterEntry).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filterEntry[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filter = {...query};
                await this.load();
            }, 
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/promotions/data', {...this.filter, numberRecord: this.numberRecord, currentLoadPage: 'promotion'});
               
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                // this.paginate.currentPage = res.result.data.paginate.currentPage;
                // this.paginate.lastPage = res.result.data.paginate.lastPage;
                // this.paginate.totalRecord = res.result.data.paginate.totalRecord;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;

                this.isCheckedAll(); 
                emitter.emit("offLoading");
                this.loading = false;
                
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            detailPromotion(id){
                if (id === this.promotionId) {
                    this.promotionId = null;
                } else {
                    this.promotionId = id;
                }
                this.headerActive = 'info';
                
            },
            showContent(name) {
                this.headerActive = name;
            },
            createPromotion(){
                this.$refs.ModalCreateUpdatePromotion.show();
            },
            updatePromotion(data){
                this.$refs.ModalCreateUpdatePromotion.show(data);
            },
            copy (data, type) {
                this.$refs.ModalCreateUpdatePromotion.show(data, type);
            },
            async exportHistoryPromotion(entry) {
                if(entry){
                    let ids = [];
                    entry.forEach(item => {
                        ids.push(item.id);
                    });
                    const res = await http.download('/v1/promotions/export-history-promotion', {id: ids});
                    const fileName = res.headers['content-disposition'].split('filename=')[1];
                    var _blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    });
                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(_blob);
                    link.setAttribute('download', fileName);
                    link.click();  
                }
            },

        }, 
        computed: {
            ...mapGetters({
                isUsePromotion: 'settingStore/isUsePromotion',
            })
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search, .table-header-search:hover{
    height: 50px !important;
    background-color: #FEFCED !important;
}
.promotion-option-item{
    background-color: #ececec;
    display: inline-block;
    vertical-align: middle;
    border-radius: 2px;
}
.bg-index-promotion{
    background-color: #F9F9F9 !important;
}
.table-header td{
    vertical-align: top !important;
}
/* .cell-check{
    width: 16px;
    min-width: 16px;
    max-width: 16px;
}
.cell-code-coupon{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-release-coupon{
    width: 190px;
    min-width: 190px;
    max-width: 190px;
}
.cell-auto{
    min-width: 160px;
}
.cell-status-coupon{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
} */
.cell-check{
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}
</style>
