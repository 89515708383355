<template>
    <div :class="wrapperClass ? wrapperClass : 'form-group'">
        <label v-if="label" v-text="label"></label>
        <div class="d-flex">
            <div class="flex-1">
                <input :readonly="!!readonly" ref="input" v-model="model" @input="onInput(true)" @change="changeInput()"
                       :class="errors && errors.length ? 'error-input': ''"
                       :min="minValue"
                       :max="maxValue"
                       class="form-control" type="number" :placeholder="placeholder || label">
            </div>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>
<style scoped>
    .form-group{
        position: relative;
    }
    .error-label{
        position: absolute;
    }
</style>
<script>
import {parseIntEx} from "../../lib/utils";
export default {
    name: "InputNumber",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'min', 'max', 'readonly', 'showAdjust', 'wrapperClass', 'formatNumber'],
    data() {
        return {
            model: parseIntEx(this.modelValue) != 0 ? parseIntEx(this.modelValue) : '',
            minValue : this.min !== undefined ? parseIntEx(this.min) : 0,
            maxValue : this.max !== undefined ? parseIntEx(this.max) : 1000000000,
        }
    },
    watch: {
        modelValue: function (newValue) {
            if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.model = parseIntEx(newValue);
            
        },
        max: function (newValue) {
            if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.maxValue = parseIntEx(newValue)
            
        },
        min: function (newValue) {
            if(this.formatNumber) return this.model = parseFloat(newValue).toFixed(2);
            this.minValue = parseIntEx(newValue)
            
        }
    },
    mounted() {

    },
    methods: {
        changeInput() {
            this.$emit('changeData', this.model);
        },
        onInput() {
            if (!this.$refs.input.value) {
                return;
            }

            let value = parseIntEx(this.model);

            if (this.minValue !== false) {
                if (value <= this.minValue) {
                    value = this.minValue;
                }
                this.model = value;
            }

            if (this.maxValue !== false) {
                if (value >= this.maxValue) {
                    value = this.maxValue;
                }
                this.model = value;
            }

            this.$emit('update:modelValue', value);
            this.$nextTick(() => {
                this.$emit('input', value);
            })
        }
    }
}
</script>


