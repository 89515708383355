<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">        
        <div class="modal-dialog modal-xl" role="document" style="min-width: 95%;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{$t("message.LocationIndex.titleCreateLocation")}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-3">
                     <div class="row">
                        <div class="col-lg-7" style="border-right: 1px solid #ddd">
                            <div class="row w-50 mx-2 h1c1" >
                                <div class="col-lg-12">
                                    <span><b>{{$t("message.LocationIndex.addInfo")}}</b></span>
                                </div>
                                <div class="col-lg-12 my-2">
                                     <input type="text" v-model="this.branchName" class="form-control inputDisabled" disabled>
                                </div>
                                <div class="col-lg-12">
                                    <input type="text" v-model="this.locationName" class="form-control" @click="showModalSelectLocations">
                                    <i class="fa fa-sort-down" style="position:absolute;top:8px;right:24px;" @click="showModalSelectLocations"></i>
                                </div>
                                <div class="col-lg-12 pt-4 " id="leftNote">
                                    <span>
                                        {{$t("message.LocationIndex.note")}}:
                                    </span>
                                    <p>
                                        {{$t("message.LocationIndex.contentNote")}}
                                    </p>
                                </div>
                            </div>
                            <!-- Tiêu đề form -->
                            <div class="row mx-2 mt-4" >
                                <div class="col-lg-3">
                                    <span><b>{{$t("message.LocationIndex.addLocationName")}}</b></span>
                                    <span class="text-danger"><b> *</b></span>
                                </div>
                                <div class="col-lg-3">
                                    <span><b>{{$t("message.LocationIndex.addLocationCode")}}</b></span>
                                    <span class="text-danger"><b> *</b></span>
                                </div>
                                <div class="col-lg-3">
                                    <span><b>{{$t("message.LocationIndex.addBarcode")}}</b></span>                                    
                                </div>
                                <div class="col-lg-3">
                                    <span><b>{{$t("message.LocationIndex.addMaxQuantity")}}</b></span>                                    
                                </div>
                            </div>
                            <!-- Form input -->
                            <div class="row" id="autoRows" >
                                <div class="col-12">
                                    <div class="row mx-2 my-2" v-for="(value, index) in this.entry" :key="index" >
                                        <div class="col-lg-3">
                                            <InputText v-model="value.name" :placeholder="$t('message.LocationIndex.enterLocationName')" v-on:change="getIsEmptyData('name', value.name.trim(), index)"> </InputText>
                                        </div>
                                        <div class="col-lg-3">
                                            <InputText v-model="value.code" :placeholder="$t('message.LocationIndex.enterLocationCode')"  v-on:change="getQRCode(value.code.trim(), index)" > </InputText>
                                        </div>
                                        <div class="col-lg-3">
                                            <InputText v-model="value.qrCode" :placeholder="$t('message.LocationIndex.enterBarcode')" :id="'qrCode_' + index" v-on:change="changeData"> </InputText>
                                        </div>
                                        <div class="col-lg-3">
                                            <InputNumberFormat v-model="value.maxQuantityProducts"  v-on:change="changeData" :placeholder="$t('message.LocationIndex.enterMaxQuantity')" />
                                        </div>
                                    </div>     
                                </div>
                            </div>               
                            <!-- ------------------------------------------------------------------------------- -->
                            <!-- Các button xử lý -->
                            <div class="row mx-2 my-4" >
                                <div class="col-lg-12">
                                    <button type="button" class="btn btn-outline-secondary mr-2" @click="addRow">
                                    <i class="fa fa-plus mr-1"></i>
                                    {{$t("message.LocationIndex.addRow")}}
                                </button>
                                <AppPermission :permission="this.$permissions.locations.create ? this.$permissions.locations.create : '' ">
                                    <button type="button" class="btn btn-save mr-2" @click="save">
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t("message.button.save")}}
                                    </button>
                                </AppPermission>
                                <button type="button" class="btn btn-secondary text-white  mr-2"
                                    @click="hide">
                                    <i class="fa fa-ban mr-1"></i>
                                    {{$t("message.button.cancel")}}
                                </button>
                                </div>
                            </div>
                        </div>
                        <!-- Bảng dữ liệu -->
                        <div class="col-lg-5"  >
                            <div class="row mx-1">                                
                                <div class="col-xs-5"> <h6 class="px-2"><b>{{ $t("message.LocationIndex.locationInFolder") }}</b></h6> </div>                        
                            </div>
                            <div class="row">
                                <div class="col-12 " style="height:500px" id="autoRows">
                                        <table class="table table-index table-striped " style="margin-top:-1px !important;" >
                                            <thead class="text-center sticky-top">
                                                <tr>
                                                    <td>  {{$t('message.LocationIndex.barcode') }}  </td>
                                                    <td>  {{$t('message.LocationIndex.name') }}  </td>
                                                    <td>  {{$t('message.LocationIndex.code') }}  </td>
                                                    <td>  {{$t('message.LocationIndex.totalProducts') }}  </td>
                                                    <td>  {{$t('message.LocationIndex.totalQuantity') }}  </td>
                                                    <td>  {{$t('message.LocationIndex.maxTotalQuantity') }}  </td>
                                                </tr>
                                            </thead>
                                            <tbody class="text-center">
                                                <template v-for="entry in this.entries" :key="entry.id">
                                                    <!-- <tr style="height:5px; !important"> -->
                                                    <tr>
                                                        <td class="text-left">{{entry.qrCode}}  </td>
                                                        <td class="text-left">{{entry.name}}  </td>
                                                        <td class="text-left">{{entry.code}}  </td>
                                                        <td>{{entry.totalProducts}}  </td>
                                                        <td>{{entry.totalQuantityProducts}}  </td>
                                                        <td>{{entry.maxQuantityProducts}}  </td>
                                                    </tr>    
                                                </template>
                                                <tr style="height:35px; border-bottom:1px solid #ccc">                                    
                                                    <td colspan="3" class="text-right">
                                                        <b>{{$t("message.LocationIndex.total")}}</b>
                                                    </td>                                                
                                                    <td><b>{{this.totalProducts}}</b></td>
                                                    <td><b>{{this.totalQuantityProducts}}</b></td>
                                                    <td></td>
                                                </tr>  
                                            </tbody>
                                        </table>     
                                </div>
                            </div>
                             <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>                       
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="confirm"  :title="this.$t('message.common.confirm')" :content="this.$t('message.LocationIndex.confirmCancelCreateLocation')"/>
    <ModalSelectLocations ref="ModalSelectLocations" @updateLocationsName="getLocationName" @loadLocationId="loadLocationId" @updateLocationsCode="updateLocationsCode"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import ModalConfirm from "@/components/ModalConfirm.vue";
import store from "@/store";
import SelectSearch from "@/components/SelectSearch";
import { $get, $post, clone } from "@/lib/utils";
import ModalSelectLocations from './ModalSelectLocations';
import toastr from 'toastr'
import Pagination from '@/components/Pagination';
import emitter from '@/lib/emitter';
import InputNumberFormat from '@/components/forms/InputNumberFormat';
import AppPermission from '@/components/AppPermission.vue';
import moment from 'moment';
export default {
    name: "ModalAddLocationDeatils",
    components: { InputText, InputNumberFormat , ModalConfirm, SelectSearch, ModalSelectLocations, Pagination, AppPermission
    },
    props: {
       
    },
    data() {
        return {
            isAdd: false,
            currentUser: store.state.Auth.user,
            isEmptyData: [],
            totalProducts:0,
            totalQuantityProducts:0,
            entries: [],
            entry:[
                {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                },
                {
                    name: [],
                    code: [],
                    qrCode: [],
                   maxQuantityProducts: 0,
                },
                {
                    name: [],
                    code: [],
                    qrCode: [],
                   maxQuantityProducts: 0,
                },
                {
                    name: [],
                    code: [],
                    qrCode: [],
                   maxQuantityProducts: 0,
                }
            ],
            input: 4,
            branchId: store.state.CurrentBranch.id,
            branchName: store.state.CurrentBranch.name,
            locationId: {
                locationLv1Id:null,
                locationLv2Id:null,
                locationLv3Id:null,
                locationLv4Id:null,
            },
            locationName: null,
            locationCode: null,
            isStoreName: [],
            isStoreCode: [],
            renderQrCode: true,
            paginate: 
            {
                currentPage: 1,
                lastPage: 1
            },
            numberRecord: 15,
            parentList:[]
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    watch: {
        locationCode(newCode) {
            this.entry.forEach(item => {
                if(item.name != "" && item.code !== "") item.qrCode = newCode + '-' + item.code;                
            });
        }
    },
    methods: {
        async pageChange(page) {                 
        const res = await $get("/v1/location-details/data", {page: page, record: this.numberRecord, locationId: this.locationId.locationLv4Id});
            if(res && res.status.code == 200 && res.result.data.length > 0){
                this.entries = res.result.data;  
                this.paginate = res.result.paginate;      
            }        
        },
        show(entry) {           
            this.resetForm();
            this.locationId.locationLv4Id = entry.id;
            this.locationName =  entry.name;
            this.locationCode = entry.code
            this.parentList = clone(entry);
            if(this.parentList.listCurrentParentInfo){
                emitter.emit('getDataSelectLocation', this.parentList.listCurrentParentInfo.reverse());
            }
            this.load();      
            window.jQuery(this.$refs.modal).modal('show');
        },
        confirm(){
            this.$emit('reload');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        hide() {
             if(this.isAdd == true) {
                this.$refs.ModalConfirm.show();
            }
            else {
               this.confirm(); 
            }


           
        },
        showModalSelectLocations(){
             this.$refs.ModalSelectLocations.show();
        },
        checkExistLocationName(name){              
            let status  = false;
            this.entries.forEach(item => {                             
                if(item.name == name || item.name.toUpperCase() == name.toUpperCase()) status = true;
            });             
            return status;
        },
        checkExistLocationCode(code){            
            let status  = false;
            this.entries.forEach(item => {
                if(item.code == code) status = true;
                if(item.code.toUpperCase() == code.toUpperCase()) status = true;
            });            
            return status;
        },
        clearData(index, type){           
            this.entry.forEach((item,inds) => {                
                if(index == inds) {
                    switch (type) {
                        case 'name':
                            item.name = [];
                            break;
                        case 'code':
                            item.code = [];
                            item.qrCode = [];
                            break;                        
                    }
                } 

            });
        },
        resetForm(){
             this.totalProducts = 0;
             this.totalQuantityProducts = 0;
              this.entry = [
                {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                },
                {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                },
                {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                }
                ,
                {
                    name: [],
                    code: [],
                    qrCode: [],
                   maxQuantityProducts: 0,
                }
            ];
        },
        clearCode(isStoreCode,input){
            isStoreCode.forEach((item, index) => {
                if(item == input){
                    this.isStoreCode.splice(index, 1);
                }
            });            
        },
        checkDataInputExist(type, input, inds){              
            let status = false;
            switch (type) {
                case 'name':
                    this.isStoreName.forEach((item, index) => {
                        if(index != inds && item == input) {
                            status = true;
                        }
                        if(index != inds && item.toUpperCase() == input.toUpperCase()) {
                            status = true;
                        }
                    });
                    break;
                case 'code':                   
                    this.isStoreCode.forEach((item, index) => {
                        if(index != inds && item == input) {
                            status = true;
                        }
                          if(index != inds && item.toUpperCase() == input.toUpperCase()) {
                            status = true;
                        }
                    });
                    break;
            }

           
            return status;
        },
        updateDataInputChange(type, input, inds){     
            
            switch (type) {
                case 'name':                   
                    if(this.isStoreName.length == 0) this.isStoreName.push(input);
                    else {
                        this.isStoreName.splice(inds, 1);
                        this.isStoreName.splice(inds, 1, input );                       
                    }

                    break;
                case 'code':
                    if(this.isStoreCode.length == 0) this.isStoreCode.push(input);
                    else {
                        this.isStoreCode.splice(inds, 1);
                        this.isStoreCode.splice(inds, 1, input );                       
                    }
                    break;
            }
        },
        getIsEmptyData(type, input, inds){
            this.isAdd = true;
           // Check id truoc push vao bien this.isEmptyData
            if(this.isEmptyData.includes(inds) == false) this.isEmptyData.push(inds);
            switch (type) {
                case 'name':
                    if(input) {
                        // Kiểm tra trùng tên trong DB
                        let statusExist = this.checkExistLocationName(input);
                        if(statusExist == true) {
                            toastr.error(this.$t('message.LocationIndex.existLocationName'));
                            this.clearData(inds, 'name');
                            return;
                        }
                        // Kiểm tra trùng dữ liệu khi input
                        let status = this.checkDataInputExist('name', input, inds);                           
                        if(status == true) {
                            toastr.error(this.$t('message.LocationIndex.duplicateLocationName'));
                            this.clearData(inds, 'name');
                            return;
                        }
                        else {
                            // this.isStoreName.push(input);
                            this.updateDataInputChange(type, input, inds);                            
                        }
                    }
                    break;
                case 'code':
                    if(input) {                          
                        // Kiểm tra tồn tại trong DB
                        let statusExist = this.checkExistLocationCode(input);                                
                        if(statusExist == true) {
                            toastr.error(this.$t('message.LocationIndex.existLocationCode'));
                            this.clearData(inds, 'code');
                            this.renderQrCode = false;
                            return;
                        }
                        // Kiểm tra tồn tại khi nhập
                        let status = this.checkDataInputExist('code', input, inds);                           
                        if(status == true) {
                             toastr.error(this.$t('message.LocationIndex.duplicateLocationCode'));
                            //  this.clearCode(this.isStoreCode, input);
                             this.clearData(inds, 'code');
                             this.renderQrCode = false;
                            return;
                        }
                        else {
                            this.renderQrCode = true;   
                            this.updateDataInputChange('code', input, inds);                            
                        }
                    }
                    
                    break;                       
            }
        },
        getQRCode(code, inds){            
            this.getIsEmptyData('code', code ,inds);            
            if(this.renderQrCode == true) {
                this.entry.forEach((item, index) => {
                if(index == inds) {                    
                    item.qrCode = code? this.locationCode + '-' + code : this.locationCode;
                    // item.locationId = this.locationId.locationLv4Id;
                    item.totalQuantityProducts = 0;
                    item.totalProducts = 0;
                    item.created_by = this.currentUser.id;
                }
            });
            }
            // Tự động get QRcode
            
          
        },
        async load(){
            const res = await $get("/v1/location-details/data", {locationId: this.locationId.locationLv4Id});
            if(res && res.status.code == 200 && res.result.data.length > 0){
                this.entries = res.result.data;
                this.entries.forEach(item => {
                    this.totalProducts = this.totalProducts + item.totalProducts;
                    this.totalQuantityProducts = this.totalQuantityProducts + item.totalQuantityProducts;
                    this.paginate = res.result.paginate;      
                    
                });                
            }
            else {
               this.entries = [];
            }

        },
        getLocationName(input){           
            this.locationName = (input.nameLv1? input.nameLv1 : '' ) + (input.nameLv2?  ' > ' + input.nameLv2 : '' ) + (input.nameLv3? ' > ' + input.nameLv3 : '' ) + (input.nameLv4? ' > ' +  input.nameLv4: '' );
        },
        updateLocationsCode(input){           
            this.locationCode = (input.codeLv1? input.codeLv1 : '' ) + (input.codeLv2? '-' + input.codeLv2 : '' ) + (input.codeLv3? '-' + input.codeLv3 : '' ) + (input.codeLv4? '-' + input.codeLv4 : '' );          
        },
        loadLocationId(id){            
            this.locationId.locationLv4Id = id;            
            this.load();
        },
        checkFieldEmpty(){
            let data = [];
            if(this.isEmptyData.length == 0){
                toastr.error(this.$t('message.LocationIndex.emptyData'));
                return;
            }
            else {
                this.isEmptyData.forEach(value => {
                    this.entry.forEach((item, index) => {                        
                        if(index == value && item.name == '') {  
                            data.push(this.$t('message.LocationIndex.row') + (index + 1)  + ': ' + this.$t('message.LocationIndex.emptyLocationName'));
                        } 
                        if(index == value && item.code == '') {
                            data.push(this.$t('message.LocationIndex.row')  + (index + 1) + ': ' + this.$t('message.LocationIndex.emptyLocationCode'));                            
                        }                    
                    });    
                });
            }      
            return data;
        },
        getDataToInsert(){
            let data = [];
            this.isEmptyData.forEach(value => {
                this.entry.forEach((item, index) => {
                    if(index == value) {
                        data.push(item);
                    }
                    
                });    
            });                    
            return data;
        },
        async save(){  
            // Thông báo khi chưa nhập dữ liệu   
            let checkInput = this.checkFieldEmpty();
            if(checkInput.length > 0) {
                checkInput.forEach(item => {
                    toastr.error(item);
                });
                return;
            }
            let data = this.getDataToInsert();
            const now = moment().format('YYYY-MM-DD hh:mm:ss');
            data.forEach(value => {
                value.locationId = this.locationId.locationLv4Id;
                if(!value.created_at){
                    value.created_at = now;
                }
            });            
            const res = await $post("/v1/location-details/create", {locationId: this.locationId.locationLv4Id, data: data});                      
            if(res && res.status.code == 200 && res.result.code == 200){
                  toastr.success(res.result.message);
                  this.resetForm();
                  this.$emit('ModalAddLocationDeatils');
                  this.isAdd = false;
                  this.hide();
                  this.load();                  
            }
            else {
                toastr.error(res.result.message);
            }
        },
        changeData(){
            this.isAdd = true;
        },
        addRow(){
            let newObject =  {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                }
            this.entry.push(newObject);            
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: 95% !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
#leftNote {
 color:gray;
 font-style: italic;
}
.h1c1 {
    border: 0;
    border-bottom: 1px solid #ddd;    
}
.inputDisabled{
    background-color:#eee;
    cursor: not-allowed;
}

#autoTable tr {
    min-height: 35px !important;
}

.table tbody tr {
    height: 35px !important;
}

</style>