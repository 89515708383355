<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2">{{$t('message.VoucherIndex.modal.remove.title')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{$t('message.VoucherIndex.modal.remove.content')}}
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <button class="btn btn-danger mr-2" @click="removeVoucher()">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.VoucherIndex.modal.remove.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.VoucherIndex.modal.remove.button.skip')}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemoveVoucher',
    props: {},
    data () {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async removeVoucher() {
        
          const res = await $post(`/v1/vouchers/remove-voucher`, {id: this.id});          
          if(res && res.status.code == 200 && res.result == true){
            toastr.success(this.$t('message.VoucherIndex.modal.remove.alerts.success'));
          }
          else {
            toastr.error(this.$t('message.VoucherIndex.modal.remove.alerts.error'));
          }
          
          window.jQuery(this.$refs.modal).modal('hide');
          this.$emit('reloadData');
        }
    }
}
</script>
<style>
</style>