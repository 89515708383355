<template>
    <div ref="modalCopyRole" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content px-4">
                <div class="modal-header py-1">
                    <h6 class="modal-title py-2">
                        {{$t('message.common.addNewRole')}}
                    </h6>
                    <button type="button" class="close" data-dismiss="modalCopyRole" aria-label="Close" @click="clearData">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-4">
                            {{ $t('message.common.role') }}:
                        </div>
                        <div class="col-8 position-relative">                                                            
                            <input type="text" v-model.trim="entry.name" class="w-100 border-0 border-bottom-1 pr-4">
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mx-0 my-4">
                        <div class="col-lg-12 flex-end p-0">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-check-square mr-1"></i>
                                {{ $t('message.button.save') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="clearData">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert} from "@/lib/utils";
export default {
    name: "ModalCopyRole",
    components: {},
    data(){
        return {
            entry: {},
        }
    },
    props: {},
    methods: {
        show(entry) {
            if(entry){
                this.entry = {...entry};
            }
            window.jQuery(this.$refs.modalCopyRole).modal('show');
        },
        async save(){
            if(this.entry.permissionIds){
                if(typeof this.entry.permissionIds == 'string'){
                    this.entry.permissionIds = this.entry.permissionIds.split(",");
                }
                this.entry.permissionIds = this.entry.permissionIds.map(Number);
            }
            const res = await $post('/v1/roles/save', { ...this.entry});
            if (res.code === 422) {
                const errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
            }
            else if (res.status.code == 200 && res.result.status && res.result.message) {
                $alert({code: 200, message: res.result.message});
                this.clearData();
            } else {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
            }
        },
        clearData(){
            window.jQuery(this.$refs.modalCopyRole).modal('hide');
        },
    }
}
</script>

