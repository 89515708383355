<template>
    <div class="container">
        <div class="row">
            <ActionBar back-url="/order-suppliers/index" @action="save()"/>
            <div class="col-12 pb-100">
                <div class="row">
                    <div class="col-9 p-0 card-transaction-left position-relative">
                        <div class="col-md-12 d-flex align-items-center mb-3" style="margin-top: 10px">
                            <div class="d-flex align-items-center justify-content-lg-between" :class="!isConfirm ? 'col-2' : ''">
                                <svg width="17" height="17" viewBox="0 0 17 17" @click="back()" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1666 7.54183H4.50373L9.86081 2.18475L8.49998 0.833496L0.833313 8.50016L8.49998 16.1668L9.85123 14.8156L4.50373 9.4585H16.1666V7.54183Z" fill="#666666"/>
                                </svg>
                                <div class="main-title custom-title">
                                    <span>
                                        {{$t('message.RequestIndex.title')}}
                                    </span>
                                </div>
                            </div>
                            <div v-if="!isConfirm" class="col-md-6">
                                <ProductSearch @select="onSelectProduct" :readonly="!form.toBranchId" :action="$constant.getPriceOrigin" :toBranchId="form.toBranchId" :notAdd="true"></ProductSearch>
                            </div>
                            <div class="col-md-2 text-right" v-if="!isConfirm">
                                {{ $t('message.RequestIndex.to_branch') }}
                            </div>
                            <div class="col-md-2" v-if="!isConfirm">
                                <SelectSearch :placeholder="$t('message.common.selectBranchRequest')" :modelValue="form.toBranchId" :defaultOption="this.branches" @update:modelValue="selectBranch" :label="'toBranch'"></SelectSearch>
                            </div>
                        </div>
                        <!-- <Pagination v-if=" form.products != null && form.products.length && form.products.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/> -->
                        
                        <Pagination v-if="info.typeShow == $constant.typeMatch && productsMatch != null && productsMatch.length && productsMatch.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if=" info.typeShow == $constant.typeDeviated && productsDeviated != null && productsDeviated.length && productsDeviated.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if=" info.typeShow == $constant.typeNotReceived && productsNotReceived != null && productsNotReceived.length && productsNotReceived.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if="info.typeShow == $constant.typeAll && form.products != null && form.products.length && form.products.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        
                        <div v-if="isConfirm" class="row ml-0 mt-2">
                            <div :class="[(info.typeShow == $constant.typeAll ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeAll)">{{$t('message.common.all')}} ({{ form.products.length }})</div>
                            <div :class="[(info.typeShow == $constant.typeMatch ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeMatch)">{{$t('message.common.joint')}} ({{ info.match }})</div>
                            <div :class="[(info.typeShow == $constant.typeDeviated ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeDeviated)">{{$t('message.common.deviated')}} ({{ info.deviated }})</div>
                            <div :class="[(info.typeShow == $constant.typeNotReceived ? 'tab_active' : '' ), 'col-md-2 pb-2']" @click="showProducts($constant.typeNotReceived)">{{$t('message.common.notGiven')}} ({{ info.notReceived }})</div>
                        </div>
                        <table class="table table-index table-striped table-transaction" style="margin-top: 15px">
                            <thead>
                            <tr >
                                <th width="1%"></th>
                                <th width="2%">{{$t('message.common.STT')}}</th>
                                <th width="10%">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                <th width="15%">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                <th width="8%">{{$t('message.DetailOrdersCard.dvt')}}</th>
                                <th width="10%">{{$t('message.DetailOrdersCard.attribute')}}</th>
                                <th width="12%">{{$t('message.common.requestQuantity')}}</th>
                                <th v-if="isConfirm" width="11%">{{$t('message.common.transferQuantity')}}</th>
                                <th width="12%">{{$t('message.common.requestPrice')}}</th>
                                <th width="15%">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                <td></td>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in 
                                    ( info.typeShow == $constant.typeMatch ? productsMatch : 
                                    (info.typeShow == $constant.typeDeviated ? productsDeviated 
                                    : (info.typeShow == $constant.typeNotReceived ? productsNotReceived : form.products)) )" 
                                :key="product.id" >
                                
                                <tr v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                    <td>
                                        <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                            <i class="fas fa-trash"/>
                                        </a>
                                    </td>
                                    <td>{{ index + 1 }}</td>
                                    <td>
                                        <router-link :to="{name: 'ProductIndex'}">{{ product.code }}</router-link>
                                    </td>
                                    <td>
                                        {{ product.name }}
                                        <div class="position-relative">
                                            <template v-if="!isConfirm">
                                                <input type="text" v-model.trim="product.note" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                                <span class="noteOrder text-gray" v-if="product.note == null || product.note == ''"><i class="fa fa-pencil-alt"></i></span>
                                            </template>
                                            <div v-if="isConfirm" class="pb-4">{{ product.receiveDescription }}</div>
                                        </div>
                                    </td>                                    
                                    <td class="text-center py-2">
                                        <template v-if="isConfirm">
                                            <span v-text="product.productUnit ? product.productUnit.unitName : null"></span>
                                        </template>
                                        <template v-else>
                                        <InputSelect v-model="product.productUnitId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                            :options="product.product_units"
                                            v-if="product.product_units && product.product_units.length"
                                            option-label="unitName"
                                            :readonly="product.isUpdated || isConfirm"
                                            @update:modelValue="updateProductUnit(product, (product.hasVariant ? true : false))"
                                            :cardTransaction="true">
                                        </InputSelect>
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="isConfirm">
                                            <span v-text="product.variant ? product.variant.name : ''"></span>
                                        </template>
                                        <template v-else-if="!isConfirm && product.hasVariant">
                                            <InputSelect 
                                                v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="product.variants"
                                                option-label="name"
                                                :cardTransaction="true"
                                                :readonly="isConfirm"
                                                @update:defaultValue="value => updateAttribute(product, value)">
                                            </InputSelect>
                                        </template>
                                    </td>
                                    <td class="">
                                        <span v-if="isConfirm">{{ product.receiveQuantity }}</span>
                                        <InputNumberFormat v-if="!isConfirm" :max="$constant.maxNumber" :cardTransaction="true" v-model="product.receiveQuantity" @input="checkQuality(product.sendQuantity, product.inventories, index)" :inputTable="true" class="mb-0" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                    </td>
                                    <td v-if="isConfirm" class="">
                                        <div class="position-relative" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <InputNumberFormat :max="product.receiveQuantity" :cardTransaction="true" v-model="product.sendQuantity" :inputTable="true" class="mb-0" @change="changeReceiveQuantity" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                            <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment">
                                                <div>{{ currentBranch.name }}</div>
                                                <div>{{$t('message.ProductIndex.inventory')}}: {{ product.inventories }}</div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="position-relative">
                                        {{ $filters.formatPrice(product.price) }}
                                    </td>
                                    
                                    <td v-if="isConfirm">{{ $filters.formatPrice(product.price * product.sendQuantity) }}</td>
                                    <td v-else>{{ $filters.formatPrice(product.price * product.receiveQuantity) }}</td>

                                    <td class="text-center">
                                        <div class="d-flex align-items-center" v-if="!isConfirm">
                                            <i class="fas fa-plus mr-2" @click="addProductPlus(index, product)"></i>
                                        </div>
                                    </td>
                                </tr>
                                <tr v-if="product.isConsignment && isConfirm">
                                    <td></td>
                                    <td :colspan="10">
                                        <ChooseConsignment :index="index" :object="product" @update:value="updateConsignment" :noAddConsignment="true"/>
                                    </td>
                                </tr>
                                
                                <!-- Order thêm các đơn vị khác -->
                                <template v-for="(plus, plusIndex) in  product.plus" :key="plusIndex">
                                    <tr v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                        <td :colspan="4"></td>

                                        <td class="text-center py-2">
                                            <template v-if="isConfirm">
                                                <span v-text="plus.productUnit ? plus.productUnit.unitName : null"></span>
                                            </template>
                                            <template v-else>
                                            <InputSelect v-model="plus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.product_units"
                                                v-if="product.product_units && product.product_units.length"
                                                option-label="unitName"
                                                :readonly="product.isUpdated || isConfirm"
                                                @update:modelValue="updateProductUnit(plus, (product.hasVariant ? true : false))"
                                                :cardTransaction="true">
                                            </InputSelect>
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="isConfirm">
                                                <span v-text="plus.variant ? plus.variant.name : ''"></span>
                                            </template>
                                            <template v-else-if="!isConfirm && product.hasVariant">
                                                <InputSelect 
                                                    v-model="plus.variantId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                    :options="plus.variants"
                                                    option-label="name"
                                                    :cardTransaction="true"
                                                    :readonly="isConfirm"
                                                    @update:defaultValue="value => updateAttribute(plus, value)"
                                                ></InputSelect>
                                            </template>
                                        </td>
                                        <td class="">
                                            <span v-if="isConfirm">{{ plus.receiveQuantity }}</span> 
                                            <InputNumberFormat v-if="!isConfirm" :max="$constant.maxNumber" :cardTransaction="true" v-model="plus.receiveQuantity"  :inputTable="true" class="mb-0" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                        </td>
                                        <td v-if="isConfirm" class="">
                                            <div class="position-relative" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <InputNumberFormat :max="plus.receiveQuantity" :cardTransaction="true" v-model="plus.sendQuantity" :inputTable="true" class="mb-0" @change="changeReceiveQuantity" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                                <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment">
                                                    <div>{{ currentBranch.name }}</div>
                                                    <div>{{$t('message.ProductIndex.inventory')}}: {{ plus.inventories }}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td class="position-relative">
                                            {{ $filters.formatPrice(plus.price) }}
                                        </td>
                                        
                                        <td v-if="isConfirm">{{ $filters.formatPrice(plus.price * plus.sendQuantity) }}</td>
                                        <td v-else>{{ $filters.formatPrice(plus.price * plus.receiveQuantity) }}</td>
                                      
                                        <td>
                                            <a class="btn-remove" @click.prevent="removeDetailProductPlus(index, plusIndex)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                    </tr>

                                    <tr v-if="product.isConsignment && isConfirm">
                                        <td></td>
                                        <td :colspan="10">
                                            <ChooseConsignment :object="plus"  @update:value="updateConsignmentProductUnit" :noAddConsignment="true"  :index="index" :plusIndex="plusIndex"/>
                                        </td>
                                    </tr> 
                                </template>

                            </template>
                            </tbody>
                        </table>
                      <div>
                      </div>
                        <div class="row d-flex align-items-center importArea" v-if="form.products?.length == 0 || form.products == null">
                            <div class="col-12 d-flex align-items-center justify-content-center" v-if="form.toBranchId !== null">
                                <div class="main-title">
                                    <span>{{$t('message.ImportExcel.title')}}</span>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center" v-if="form.toBranchId !== null">
                                <div>
                                    ({{$t('message.ImportExcel.defaultFile')}} <a :href="'/../template/template_goi_hang_' + this.language + '.xlsx'" class="text-primary">{{$t('message.ModalFileUploadProducts.titleExcel')}}</a>)
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="form.toBranchId !== null">
                                <button class="p-2 button-import" :title="$t('message.ImportExcel.chooseFile')">
                                    <form ref="form">
                                        <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" :title="$t('message.ImportExcel.chooseFile')"/>
                                    </form>
                                    <i class="fa fa-file mr-2"></i>{{$t('message.ImportExcel.chooseFile')}}
                                </button>
                            </div>
                            <div class="col-12" v-if="form.toBranchId !== null">
                                <div class="col-12 w-100 mt-3 upload-files" v-if="this.fileName">
                                    <div class="row h-100" >
                                        <div class="col-6 d-flex align-items-center" >
                                            {{ this.fileName }}
                                        </div>
                                        <div class="col-6 d-flex justify-content-end align-items-center">
                                            <a href="javascript:;" class="text-danger" @click="removeFile(false)">
                                                <i class="fa fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 w-100 mt-3">
                                    <div class="alert alert-danger row py-2" v-if="this.messageFile">
                                        {{ this.messageFile }}
                                    </div>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="this.fileName != ''">
                                    <button v-show="this.showBtn" class="p-2 button-import" :title="$t('message.ImportExcel.action')" @click="uploadExcel(this.patient)">
                                        <i class="fa fa-file mr-2"></i>{{$t('message.ImportExcel.action')}}
                                    </button>
                                </div>
                            </div>    
                        </div>
                    </div>

                    <div class="col-3 pt-2 card-transaction-right">
                        <div class="row card-transaction-right-content">
                            <div class="col-12">
                                <div class="row pb-2">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-2 p-0 d-flex justify-content-end align-items-center">
                                                <h5 class="mb-0"><i class="fa fa-user-circle"></i></h5>
                                            </div>
                                            <div class="col-10 pl-2 py-2">
                                                {{ form.employees }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row format__block-time">
                                            <div class="col-7 pl-0 pr-3 text-right">
                                                {{ form.transferDate }}
                                            </div>
                                            <div class="col-5 pl-0 pr-3 text-right">
                                                {{ form.transferTime }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.TransferIndex.request_code')}}
                                    </div>
                                    <div class="col-6">
                                        <input v-if="!isConfirm" type="text" v-model.trim="form.code" class="form-control input__order min-w-100 border-bottom-1 p-0" :placeholder="$t('message.common.autocompleteCode')">
                                        <span v-if="isConfirm">{{ form.code }}</span>
                                    </div>
                                </div>
                                <div class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.status')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        <span v-if="!isConfirm">{{$t('message.common.draft')}}</span>
                                        <span v-else>{{$t('message.common.waitForConfirmation')}}</span>
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.branchRequest')}}
                                    </div>
                                    <div class="col-6 text-left" v-if="form.fromBranch">
                                        {{ form.fromBranch.name }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{ $t('message.RequestIndex.request_date') }}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{  $filters.formatDateHour(form.deliveryDate) }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.ProductIndex.note')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.receiveDescription }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.totalRequestQuantity')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.totalReceiveQuantity }}
                                    </div>
                                </div>

                                <div class="row pb-4">
                                    <div class="col-6">
                                        <span v-if="!isConfirm">{{$t('message.OrderSupplierIndex.total_quantity')}}</span>
                                        <span v-else>{{$t('message.common.totalTransferQuantity')}}</span>
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.totalQuantity }}
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="form.note" class="input__textArea mb-0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2 card-transaction-button w-100">
                            <div class="col-5">
                                <button class="mt-2 btn_submit btn btn-save" :disabled="isButtonDisabled" @click="submit($constant.statusTicketTransferTemporary)">
                                    <i class="fa fa-save"></i>
                                    <div>
                                        {{$t('message.button.saveDraft')}}
                                    </div>
                                </button>
                            </div>
                            <div class="col-7">
                                <button class="btn btn-success mt-2 btn_submit" :disabled="isButtonDisabled" @click="submit($constant.statusTicketTransferBeg)">
                                    <i class="fa fa-check"></i>
                                    <div v-if="!isConfirm">
                                        {{$t('message.OrderSupplierIndex.status_complete')}} 
                                    </div>
                                    <div v-else>
                                        {{$t('message.common.createTransfer')}}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalErrorsMessage ref="ModalErrorsMessageImportTransfer" />
        <ModalCreateTemporary ref="ModalCreateTemporary" @confirm="submit($constant.statusTicketTransferTemporary)"/>
    </div>
</template>

<script>
    import InputSelect from "../../components/forms/InputSelect";
    import ActionBar from "@/components/ActionBar";    
    import {$post, $alert, $get, $upload, DISCOUNT_TYPE_VND, clone} from "@/lib/utils";
    import toastr from 'toastr';
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import moment from "moment";
    import store from "../../store";
    import ProductSearch from "@/components/ProductSearch.vue";
    import router from "@/router/index";
    import currencyMixin from "@/currencyMixin";
    import Pagination from "@/components/Pagination"
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalErrorsMessage from '../order_suppliers/ModalErrorsMessage.vue';
    import ChooseConsignment from "@/components/ChooseConsignment.vue";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import ModalCreateTemporary from "./ModalCreateTemporaryConfirm.vue";
    // import attributesMixin from '@/attributesMixin';
    export default {
        name: "TransferCreateUpdate",
        mixins: [currencyMixin],
        // mixins: [currencyMixin, attributesMixin],
        components: {ActionBar, InputTextArea, InputNumberFormat, ModalErrorsMessage, ProductDiscount, ProductSearch, InputSelect, Pagination, SelectSearch, ChooseConsignment, ModalCreateTemporary},
        data() {
            let currentDate = new Date().toJSON().slice(0, 10);
            const globalConfig = store.state.GlobalConfig;
            return {
                branches: globalConfig.branches,
                productIds: [],
                form: {
                    id: null,
                    toBranchId: null,
                    products: [],
                    employees: store.state.Auth.user.name,
                    currentBranchId: store.state.CurrentBranch.id,
                    transferDate: moment(currentDate).format('DD/MM/YYYY'),
                    transferTime: moment().format('HH:mm'),
                    code: null,
                    totalQuantity: 0,
                    status: this.$constant.statusOptionsTicketTemporary,
                    note: null,

                    // confirm transfer
                    fromBranch: null,
                    deliveryDate: null,
                    toBranchName: null,
                },
                productsMatch: [],
                productsDeviated: [],
                productsNotReceived: [],
                info : {
                    match: 0,
                    deviated: 0,
                    notReceived: 0,
                    typeShow: this.$constant.typeAll,
                },
                paginate: {
                    totalRecord: 0,
                    currentPage: 1,
                    lastPage: 0,
                },
                numberRecord: 15,
                isEdit: false,
                isCopy:false,
                isConfirm: false,
                patient: {
                    file: [],
                    branchId: '',
                    toBranch: '',
                    toCall: true
                },
                fileName: '',
                currentBranch: store.state.CurrentBranch,
                isButtonDisabled: false,
                language: localStorage.getItem('language') ?? 'vi',
                validationRequest: null
            }
        },
        mounted() {
            document.title = this.$t('message.RequestIndex.title');
        },
        watch: {
            form: {
                handler(dataNew) {
                    if (dataNew.products !== null) {
                        let totalQuantity = 0;
                        dataNew.products.forEach(product => {
                            this.productIds.push(product.id);
                            if(product.variant){
                                product.inventoryVariant = product.variant.product_branch_meta_has_one ? product.variant.product_branch_meta_has_one.inventories : 0;
                            }
                            if (product.consignmentActive && product.consignmentActive?.length > 0) {
                                let quantity = 0;
                                let receive = 0;
                                product.consignmentActive.forEach(item => {
                                if (item.quantity) {
                                    if (this.isConfirm) {
                                        quantity = +item.quantity + +quantity;
                                    }else{
                                        receive = +item.quantity + +receive;
                                    }
                                }
                                });
                                if (this.isConfirm) {
                                    product.sendQuantity = quantity;
                                }else{
                                    product.receiveQuantity = receive;
                                }
                                product.consignmentsActive = product.consignmentActive;
                            }else if(product.isConsignment && product.consignmentActive && product.consignmentActive?.length <= 0 && !this.isCopy){
                                if (this.isConfirm) {
                                    product.sendQuantity = 0;
                                }
                            }
                            if (this.isConfirm) {
                                totalQuantity += (product.sendQuantity ?? 0);
                            } else {
                                totalQuantity += (product.receiveQuantity ?? 0);
                            }
                            if (product.plus && product.plus?.length > 0) {
                                product.plus.forEach(plusItem => {
                                    if(plusItem.variant){
                                        plusItem.inventoryVariant = plusItem.variant.product_branch_meta_has_one ? plusItem.variant.product_branch_meta_has_one.inventories : 0;
                                    }
                                    if (plusItem.consignmentActive && plusItem.consignmentActive?.length > 0) {
                                        let quantityPlus = 0;
                                        let receivePlus = 0;
                                        plusItem.consignmentActive.forEach(item => {
                                        if (item.quantity) {
                                            if (this.isConfirm) {
                                                quantityPlus = +item.quantity + +quantityPlus;
                                            }else{
                                                receivePlus = +item.quantity + +receivePlus;
                                            }
                                        }
                                        });
                                        if (this.isConfirm) {
                                            plusItem.sendQuantity = quantityPlus;
                                        }else{
                                            plusItem.receiveQuantity = receivePlus;
                                        }
                                        plusItem.consignmentsActive = plusItem.consignmentActive;
                                    }else if(product.isConsignment && plusItem.consignmentActive && plusItem.consignmentActive?.length <= 0 && !this.isCopy){
                                        if (this.isConfirm) {
                                            plusItem.sendQuantity = 0;
                                        }
                                    }
                                    if (this.isConfirm) {
                                        totalQuantity += (plusItem.sendQuantity ?? 0);
                                    } else {
                                        totalQuantity += (plusItem.receiveQuantity ?? 0);
                                    }
                                });
                            }
                        });
                        this.form.totalQuantity = totalQuantity;
                        this.paginate.totalRecord = dataNew.products.length;
                        this.paginate.lastPage = Math.ceil(dataNew.products.length / this.numberRecord);
                    }
                },
                deep: true
            },
        },
        created() {
            if (this.$route.query && this.$route.query.id && this.$route.query.type && this.$route.query.type == 'copy'){                       
                 this.isCopy =  true;
                 this.copyRequestById(this.$route.query.id);
            }else if (this.$route.params && this.$route.params.id) {                                
                this.isEdit =  true;
                let action = null;
                if (this.$route.query.action) {
                    action = this.$route.query.action;
                    this.isConfirm = true;
                }
                this.getRequestTransferDetailById(this.$route.params.id, action);
            }
        },
        methods: {

            changePaginate() {
                if (this.info.typeShow == this.$constant.typeMatch) {
                    this.paginate.totalRecord = this.productsMatch.length;
                    this.paginate.lastPage = Math.ceil(this.productsMatch.length / this.numberRecord);
                } else if (this.info.typeShow == this.$constant.typeDeviated) {
                    this.paginate.totalRecord = this.productsDeviated.length;
                    this.paginate.lastPage = Math.ceil(this.productsDeviated.length / this.numberRecord);
                } else if (this.info.typeShow == this.$constant.typeNotReceived) {
                    this.paginate.totalRecord = this.productsNotReceived.length;
                    this.paginate.lastPage = Math.ceil(this.productsNotReceived.length / this.numberRecord);
                } else {
                    this.paginate.totalRecord = this.form.products.length;
                    this.paginate.lastPage = Math.ceil(this.form.products.length / this.numberRecord);
                }
            },
            changeReceiveQuantity() {
                let match = 0;
                let notReceived = 0;
                let deviated = 0
                this.productsMatch = [];
                this.productsDeviated = [];
                this.productsNotReceived = [];
                this.form.products.forEach(product => {
                    if (product.sendQuantity == product.receiveQuantity) {
                        match = match + 1; 
                        this.productsMatch.push(product);
                    } else if (product.sendQuantity != product.receiveQuantity && product.sendQuantity) {
                        deviated = deviated + 1;
                        this.productsDeviated.push(product);
                    } else if (!product.sendQuantity) {
                        notReceived = notReceived + 1; 
                        this.productsNotReceived.push(product);
                    } 
                });
                this.info.match = match;
                this.info.notReceived = notReceived;
                this.info.deviated = deviated;
                this.changePaginate();

            },
            showProducts(type) {
                this.info.typeShow = type;
                this.paginate.currentPage = 1;
                this.changePaginate();
            },
            checkQuality(sendQuantity, inventories, $key) {
                if (sendQuantity > inventories) {
                    this.form.products[$key].sendQuantity = inventories;
                }
            },
            setDefaultQuantity(product){
                product.forEach(item => {
                    item.sendQuantity = 0;
                });
            },
            async getRequestTransferDetailById(id, action) {
                const res = await $get('/v1/transfers/get-detail-by-id', {
                    id: id,
                    action: action,
                    type: this.$constant.typeRequest,
                });
             
                if (res.status.code == 200) {
                    const request = res.result;
                    this.form.id = request.id;
                    this.form.toBranchId = request.toBranchId;
                    this.form.fromBranchId = request.fromBranchId;
                    this.form.products = request.products;
                    this.form.code = request.code;
                    this.form.toBranchName = request.to_branch ? request.to_branch.name : null

                    if (!this.isConfirm) {
                        this.form.note = request.receiveDescription;
                    } else {
                        this.form.note = request.sendDescription;
                    }

                    // is confirm
                    this.form.fromBranch = request.from_branch ?? null;
                    this.form.deliveryDate = request.deliveryDate;
                    this.form.receiveDescription = request.receiveDescription;
                    this.form.totalReceiveQuantity = request.totalReceiveQuantity;
                    if (this.isConfirm) {
                        this.changeReceiveQuantity();
                    }
                }
            },
            async copyRequestById(id) {
                const res = await $get('/v1/transfers/request/copyReq', {
                    id: id,                    
                    type: this.$constant.typeRequest,
                });
                if (res.status.code == 200) {
                    const request = res.result;
                    this.form.id = request.id;
                    this.form.toBranchId = request.toBranchId;
                    this.form.products = request.products;
                    this.form.code = null;

                    // if (!this.isConfirm) {
                    //     this.form.note = request.receiveDescription;
                    // } else {
                    //     this.form.note = request.sendDescription;
                    // }
                    this.form.note = null;
                    // is confirm
                    this.form.fromBranch = request.from_branch ?? null;
                    this.form.deliveryDate = request.deliveryDate;
                    this.form.receiveDescription = request.receiveDescription;
                    this.form.totalReceiveQuantity = request.totalReceiveQuantity;
                }
            },
            pageChange(page) {
               this.paginate.currentPage = page;
            },
            back() {
                router.push({ name: 'RequestIndex'});
            },
            selectBranch(v) {
                this.form.toBranchId = v.model;
                this.form.toBranchName = v.optionsName
                if (this.productIds.length) {
                    this.checkProductExistNewBranch(this.form.toBranchId);
                }
            },
            async checkProductExistNewBranch(branchId) {
                const res = await $post('/v1/transfers/check-products-exist-branch', {
                    branchId: branchId,
                    productIds: this.productIds,
                });
                const productsOrigin = Object.keys(res.result).map((key) => res.result[key]);
                this.form.products = this.form.products.filter(function(product){
                        return (productsOrigin.includes(product.id));
                    });
            },
            onSelectProduct(product) {                        
                if (!this.form.products.length || !this.form.products.find(item => item.id === product.id)) {
                    if (product.product_units) {
                        product.productUnitId = product.product_units[0].id;
                        product.price = product.product_units[0].price;
                        product.productUnit = product.product_units[0];
                       
                    }
                    this.form.products.unshift(product);
                     this.getListAttributeForUnit(product);
                }
            },
            removeDetailProduct(product) {
                if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                    return;
                }
                let itemIndex = -1;
                this.form.products.forEach((item, index) => {
                    if (item.id === product.id) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.form.products.splice(itemIndex, 1);
                }  
            },
            async submit(status) {
                if (this.form.products.length === 0) {
                    toastr.error(this.$t('message.common.emptyRequests'));
                    return;
                }
                if (!this.isConfirm && this.form.toBranchId == store.state.CurrentBranch.id) {
                    toastr.error(this.$t('message.common.inValidRequestBranch'));
                    return;
                }
                for (let index = 0; index < this.form.products.length; index++) {
                    const product = this.form.products[index];
                    if (!product.receiveQuantity) {
                        product.receiveQuantity = 0;
                    }
                }

                let save = true;
                for (let index = 0; index < this.form.products.length; index++) {
                    const product = this.form.products[index];
                    if(product.isConsignment && this.isConfirm) {
                        if(product.consignmentActive.length <= 0){
                            toastr.error(this.$t('message.common.emptyBatch', {code: product.name}));
                            save = false;
                            return;
                        }
                        if(product.consignmentActive && product.consignmentActive.length){
                            product.consignmentActive.forEach(consignment => {
                                if(consignment.inventory < consignment.quantity){
                                    toastr.error(this.$t('message.common.inValidTransferQuantityConsignment', {consignment: consignment.name, name: product.name}));
                                    save = false;
                                    return;
                                }
                            });
                        }
                    }
                    if (product.plus && product.plus.length) {
                        for (let plusIndex = 0; plusIndex < product.plus.length; plusIndex++) {
                            const plus = product.plus[plusIndex];
                            if(product.isConsignment && this.isConfirm) {
                                if(plus.consignmentActive.length <= 0){
                                    toastr.error(this.$t('message.common.emptyBatch', {code: product.name}));
                                    save = false;
                                    return;
                                }
                                if(plus.consignmentActive && plus.consignmentActive.length){
                                    plus.consignmentActive.forEach(consignment => {
                                        if(consignment.inventory < consignment.quantity){
                                            toastr.error(this.$t('message.common.inValidTransferQuantityConsignment', {consignment: consignment.name, name: product.name}));
                                            save = false;
                                            return;
                                        }
                                    });
                                }
                            }

                            if (this.isConfirm) {
                                if (plus.sendQuantity > plus.inventories) {
                                    toastr.error(this.$t('message.common.inValidTransferQuantityProduct', {name: product.name}));
                                    save = false;
                                    return;
                                }
                            }
                            if (this.isConfirm && plus.receiveQuantity && plus.sendQuantity && plus.receiveQuantity < plus.sendQuantity) {
                                toastr.error(this.$t('message.common.inValidTransferQuantityProductForRequest', {name: product.name}));
                                save = false;
                                return;
                            }
                            if (!plus.receiveQuantity) {
                                plus.receiveQuantity = 0;
                            }
                        }
                    }
                    if (this.isConfirm) {
                        if (product.sendQuantity > product.inventories) {
                            toastr.error(this.$t('message.common.inValidTransferQuantityProduct', {name: product.name}));
                            save = false;
                            return;
                        }
                    }
                    if (this.isConfirm && product.receiveQuantity && product.sendQuantity && product.receiveQuantity < product.sendQuantity) {
                        toastr.error(this.$t('message.common.inValidTransferQuantityProductForRequest', {name: product.name}));
                        save = false;
                        return;
                    }
                    if (!product.receiveQuantity) {
                        product.receiveQuantity = 0;
                    }
                }
                if(save){
                    const params = {
                        toBranchId: this.form.toBranchId,
                        fromBranchId: this.form.fromBranchId,
                        products: this.form.products,
                        branchId: store.state.CurrentBranch.id,
                        code: this.form.code,
                        status: status,
                        note: this.form.note,
                        totalQuantity: this.form.totalQuantity,
                        type: this.$constant.typeRequest,
                        productIds: this.productIds,
                    };
                    this.isButtonDisabled = true;
                    let res;
                    if (!this.isEdit && !this.isConfirm) {
                        res = await $post('/v1/transfers/create', {...params});
                        this.validationRequest = res?.messages?.errors?.status ? res?.messages?.errors?.status[0] : false;
                        if (this.validationRequest == 422) {
                            this.$refs.ModalCreateTemporary.show(this.form.toBranchName, 1);
                        }
                    } else if (this.isConfirm) {
                        params.action = this.$constant.actionConfirmRequest;
                        params.totalQuantity = this.form.totalQuantity;
                        params.id = this.form.id;
    
                        if (status == this.$constant.statusTicketTransferTemporary) {
                            params.status = this.$constant.statusTicketTransferTransported;
                        } else {
                            params.status = this.$constant.statusTicketRequestTransferSucceed;
                        }
                        res = await $post('/v1/transfers/update', {...params});
                    } else {
                        params.id = this.form.id;
                        res = await $post('/v1/transfers/update', {...params});
                        this.validationRequest = res?.messages?.errors?.status[0] ?? false;
                        if (this.validationRequest == 422) {
                            this.$refs.ModalCreateTemporary.show(this.form.toBranchName, 1);
                        }
                    }
    
                    if (res.code === 422) {
                        const errors = res.messages.errors;
                        Object.keys(errors).forEach(function(key) {
                            if (errors[key][0] != 422) {
                                $alert({code: res.code, message: errors[key][0]});
                            }
                        });
                        this.isButtonDisabled = false;
                    } else if (res.status.code == 200 && res.result.status) {
                        if (this.isEdit && !this.isConfirm) {
                            $alert({code: 200, message: this.$t('message.common.updateRequestSuccess')});
                        } else if (this.isEdit && this.isConfirm) {
                            if (params.status == this.$constant.statusTicketTransferTransported) {
                                $alert({code: 200, message: this.$t('message.common.updateConfirmRequestSuccess')});
                            } else {
                                $alert({code: 200, message: this.$t('message.common.confirmRequestSuccess')});
                                this.$router.push({name: 'TransferIndex'});
                                return;
                            }
                        } else {
                            $alert({code: 200, message: this.$t('message.common.addRequestSuccess')});
                        }
                        
                        this.$router.push({name: 'RequestIndex'});
                    } else {
                        $alert({code: 500, message: this.$t('message.common.errorServer')});
                        this.isButtonDisabled = false;
                    }
                }
            },
            onFileChange(event) {
                var file = event.target.files;
                if (!file.length) {
                    return;
                }
                this.fileName = file[0].name;
                this.patient.file = file[0];
                let id = document.getElementById('branchDropdown');
                // this.patient.branchId = id.getAttribute('data-id');
                this.patient.branchId = store.state.CurrentBranch.id;
                this.patient.toBranch = this.form.toBranchId
                if (file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file[0].type === 'application/vnd.ms-excel') {
                    this.showBtn = true;
                    this.messageFile = '';

                } else {
                    this.showBtn = false;
                    this.messageFile = this.$t('message.common.inValidFormatFile')
                }
                this.$refs.form.reset()
            },
            removeFile(status) {
            this.patient.file = [];
            this.fileName = '';
                if (status == false) {
                    this.$refs.fileupload.value = '';
                    this.messageFile = ''
                }
            },
            async uploadExcel (file) 
            {
                emitter.emit("onLoading");
                const result = await $upload('/v1/transfers/import-call', [], file);
                if (result.result.code == 422)
                {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    emitter.emit("offLoading");
                }else {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    this.form.products = result.result.data;
                    emitter.emit("offLoading");
                }
            },
            updateConsignment(data){
                // this.form.products.forEach(product => {
                //     if (product.id == data.object.id) {
                //         product.consignmentActive = data.data;
                //     }
                //     if(product.consignmentActive?.length == 0){
                //         product.quantity = 0;
                //     }
                // });

                if(this.form.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.form.products[data.index].consignmentActive = [];
                        return;
                    }
                    let consignments = [];
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let newConsignment = {
                            consignmentId: consignment.id,
                            id: consignment.id,
                            quantity: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            maxQuantity: 1,
                            quantityDraft: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            name: consignment.name,
                            expried: consignment.expried,
                            productUnitId: productUnitId,
                            inventory: consignment.inventory,
                            productId: data.object.id,
                        };
                        consignments.push(newConsignment);
                    });
                    this.form.products[data.index].consignmentActive = consignments;
                }
            },
            updateConsignmentProductUnit (data) {
                if(this.form.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.form.products[data.index].plus[data.plusIndex].consignmentActive = [];
                        return;
                    }
                    let consignments = [];
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let newConsignment = {
                            consignmentId: consignment.id,
                            id: consignment.id,
                            quantity: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            maxQuantity: 1,
                            quantityDraft: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            name: consignment.name,
                            expried: consignment.expried,
                            productUnitId: productUnitId,
                            inventory: consignment.inventory,
                            productId: data.object.id,
                        };
                        consignments.push(newConsignment);
                    });
                    this.form.products[data.index].plus[data.plusIndex].consignmentActive = consignments;
                }
            },
            updateProductUnit(product, hasVariant = false) {
                if (product.product_units && product.product_units.length) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = null;
                    product.discountValue = 0;
                    product.price = product.productUnit.price;
                    if(hasVariant){
                        this.getListAttributeForUnit(product);
                    }
                }
            },
            updateAttribute(product, attribute){
                product.price = 0;
                if(attribute){
                    product.price = attribute.price;
                    product.variant = attribute;
                }
            },  
            async getListAttributeForUnit(product){
                const result = await $post('/v1/products/get-list-attributes-for-unit', {
                    productUnitId: product.productUnitId,
                    branchId: store.state.CurrentBranch.id,
                });
                product.variants = [];
                product.variantId = null;
                if(result && result.result){
                    product.variants = result.result;
                    product.variantId = result.result && result.result?.length ? result.result[0].id : null;
                    product.price = result.result && result.result?.length ? result.result[0].price : null;
                    product.variant = result.result && result.result?.length ? result.result[0] : null;
                }
            },         
            addProductPlus (productIndex, product) {
                let plus = {
                    productUnitId: null,
                    quantity: 0,
                    price: 0,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    attributesActive: product.attributes,
                    attributes: product.attributes
                }
                if (!this.form.products[productIndex].plus) {
                    this.form.products[productIndex].plus = [];
                }
                plus.index = this.form.products[productIndex].plus.length;
                this.form.products[productIndex].plus.push(plus);
            },
            removeDetailProductPlus(productIdex, plusIndex) {
                this.form.products[productIdex].plus.splice(plusIndex, 1);
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .btn_submit:focus{
        outline: none;
        border: none;
    }
    .custom__select {
        height: 35px;
        border: none;
        border-bottom: 1px solid #C7C5C5;
        color: #495057;
    }
    .format__list-time {
        top: 0 !important;
        left: 0 !important;
        width: 220px !important;
        height: 160px;
        overflow: auto;
    }
    .input__order .form-control {
        border: none;
        border-bottom: solid 1px #ccc;
    }
    .main__content {
        margin: 10px 30px;
    }
    .input__order, input {
        width: 90%;
    }
    .input__order {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    input[type=checkbox], input[type=radio] {
        width: 10% !important;
    }
    .input__textArea textarea {
        border: none;
        resize: none;
        border-bottom: solid 1px #ccc;
    }
    .far {
        position: absolute;
        right: 25px;
        top: 11px;
        font: normal normal normal 14px/1;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .importArea{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
    }
    .button-import{
        border-radius: 3px;
        border:none;
        outline: none;
        background: $base-color;
        color: #ffffff;
    }
    .button-import:focus{
        outline: none;
        border: none;
    }
    .button-import input{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        font: 170px monospace;
        filter: alpha(opacity=0);
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
    }
    .upload-files{
        overflow: hidden;
        padding: 1px 5px 2px;
        background: #ebf7fb;
        border: 1px solid #c5e7f2!important;
        min-height: inherit;
        line-height: inherit;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        height: 40px;
    }
    .popup-update-price{
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 99;
        width: 350px;
        background: white;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        padding: 1.4rem;
        border: none;
        border-radius: 7px;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .table-transaction thead th, .table-transaction tbody td{
        padding-top: 10px !important;
        vertical-align: top !important;
    }
    #typeCard {
        border: none;
        box-shadow: none;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .format__block-time {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }
    .custom-title {
        width: 100%;
        text-align: center;
    }
    .btn-primary, .btn-primary:hover{
        background: #0090DA !important;
    }
    .tab_active {
        border-bottom: 1px solid $base-color;
    }
</style>
