<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h6 v-if="isEdit" class="modal-title py-2 px-4">{{$t('message.CouponIndex.modal.create_or_update.title_update')}}</h6>
                    <h6 v-else class="modal-title py-2 px-4">{{$t('message.CouponIndex.modal.create_or_update.title_create')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header custom__list-tab bg-light px-3 pt-3 pb-0 text-center">
                    <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.first)">
                        {{$t('message.CouponIndex.modal.create_or_update.tabs.info')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.second)">
                        {{$t('message.CouponIndex.modal.create_or_update.tabs.list_coupon')}}
                    </div>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-7 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.CouponIndex.modal.create_or_update.code')}}
                                            <div class="container-circle tooltip-left-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{$t('message.CouponIndex.modal.create_or_update.tooltip.code')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="coupon.code"  :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.code')" class="form-control input-custom pl-0" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.CouponIndex.modal.create_or_update.name')}}
                                            <label class="text-danger px-0">*</label>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="coupon.name"  :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.name')" class="form-control pl-0 input-custom" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.CouponIndex.modal.create_or_update.value_coupon')}}</div>
                                        <div class="col-3 pl-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                            <div class="form-group mb-0 position-relative">
                                                <InputNumberFormat :textLeft="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                v-model="coupon.value" @input="onInputValueCoupon()" :max="100" :min="0" :readonly="this.notEdit"/>
                                                <span class="currencyUnit text-gray">{{$t('message.CouponIndex.modal.create_or_update.currency_percent')}}</span>
                                            </div>
                                        </div>
                                        <div class="col-2 d-flex align-items-end justify-content-center px-0">{{ $t('message.CouponIndex.modal.create_or_update.max') }}</div>
                                        <div class="col-3 pl-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                            <div class="form-group mb-0 position-relative">
                                                <InputNumberFormat :textLeft="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                v-model="coupon.maxPrice" :readonly="this.notEdit"/>
                                                <span class="currencyUnit text-gray">{{ $t('message.CouponIndex.modal.create_or_update.currency_vnd') }}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{ $t('message.CouponIndex.modal.create_or_update.effect') }}</div>
                                        <div class="col-3 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="date" maxlength="100" v-model="coupon.startDate" :readonly="this.notEdit" class="form-control input-custom px-0" autocomplete="off">
                                            </div>
                                        </div>
                                        <div class="col-2 d-flex align-items-end justify-content-center px-0">{{ $t('message.CouponIndex.modal.create_or_update.to') }}</div>
                                        <div class="col-3 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="date" maxlength="100" v-model="coupon.endDate" :readonly="this.notEdit" class="form-control input-custom px-0" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4  d-flex align-items-center text-bold">{{$t('message.CouponIndex.modal.create_or_update.status')}}</div>
                                        <div class="col-4 d-flex align-items-center pl-0">
                                            <label class="container-radio ml-0 min-vw-100">{{$t('message.CouponIndex.modal.create_or_update.status_active')}}
                                                <input type="radio" name="statusCoupon" v-model="coupon.status" :value="this.$constant.statusActive" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-4 d-flex align-items-center pl-0">
                                            <label class="container-radio ml-2 min-vw-100">{{$t('message.CouponIndex.modal.create_or_update.status_inactive')}}
                                                <input type="radio" name="statusCoupon" v-model="coupon.status" :value="this.$constant.status_draft" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 pr-0">
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">{{$t('message.CouponIndex.modal.create_or_update.categories')}}
                                            <div class="container-circle tooltip-right-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{ $t('message.CouponIndex.modal.create_or_update.tooltip.categories') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                <template v-if="this.coupon.categories?.length == 0">
                                                    <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray">{{$t('message.CouponIndex.modal.create_or_update.choose_categories')}}</div>
                                                </template>
                                                <template v-else>
                                                    <div class="col-10 area_obj_selected p-0" v-if="!selectAllCategories">
                                                        <template v-for="o in this.coupon.categories" :key="o.id">
                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">{{ $t('message.CouponIndex.modal.create_or_update.group') }}: {{ o.name }}
                                                                <i class="fa fa-times ml-2 text-gray" @click="removeCategory(o.id)" v-if="!this.notEdit"></i>
                                                                <i class="fa fa-times ml-2 text-gray" v-else></i>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="col-10 area_obj_selected p-0" v-else>
                                                        <div class="cate_selected d-flex align-items-center p-2 my-1">{{$t('message.CouponIndex.modal.create_or_update.all_categories')}}
                                                            <i class="fa fa-times ml-2 text-gray" @click="removeAllCategory()" v-if="!this.notEdit"></i>
                                                            <i class="fa fa-times ml-2 text-gray" v-else></i>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                    <button class="listIcon text-gray border-none" @click="showSelectCategories()" v-if="!this.notEdit">
                                                        <i class="fa fa-list"></i>
                                                    </button>
                                                    <button class="listIcon text-gray border-none" v-else>
                                                        <i class="fa fa-list"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">{{ $t('message.CouponIndex.modal.create_or_update.products') }}</div>
                                        <div class="col-7">
                                            <ProductUnitsSearch @selectProductUnit="onSelectProductUnit" :label="'products'" :readonly="this.notEdit" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="this.coupon.products" ref="ProductUnitsSearch"></ProductUnitsSearch>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">{{ $t('message.CouponIndex.modal.create_or_update.total_price') }}
                                            <div class="container-circle tooltip-right-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{ $t('message.CouponIndex.modal.create_or_update.tooltip.total_price') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-group mb-0">
                                                <InputNumberFormat :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                v-model="coupon.minPrice" :readonly="this.notEdit"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12 d-flex align-items-center position-relative">
                                            <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                            <input type="text" name="note" id="note" v-model.trim="coupon.note" class="form-control input-custom pl-4" autocomplete="off" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.note')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-4">
                                 
                                <div class="col-lg-12 flex-end p-0">
                                    <AppPermission :permission="[this.$permissions.coupons.update ?? '', this.$permissions.coupons.create ?? '']">
                                        <button type="button" class="btn btn-save mr-2" @click="submitCoupon()">
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.CouponIndex.modal.create_or_update.button.save')}}
                                        </button>
                                    </AppPermission>
                                    <button type="button" class="btn btn-secondary text-white mr-2" @click="hide()">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.CouponIndex.modal.create_or_update.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.second">
                            <div class="row mx-4 p-0">
                                <div class="col-4 mt-4 d-flex align-items-center pl-0">
                                    <template v-if="this.arrayChecked?.length > 0">
                                        <span>
                                            {{$t('message.common.numberCouponSelected', {number: this.arrayChecked?.length})}}
                                        </span>
                                        <a href="javascript:void(0);" class="icon-remove ml-2 text-gray" @click="removeChecked()">
                                            <i class="fa fa-times p-1 text-gray"></i>
                                        </a> 
                                    </template>
                                </div>
                                <div class="col-8 mt-4 d-flex justify-content-end pr-0">
                                    <div class="dropdown" v-if="this.arrayChecked?.length > 0 && listCoupon?.length > 0">
                                        <button class="btn btn-save dropdown-toggle pr-4" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-v px-2"></i>{{ $t('message.CouponIndex.modal.create_or_update.button.action') }}
                                        </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <AppPermission :permission="this.$permissions.coupons.releaseCoupon ?? '' ">
                                                    <a class="dropdown-item" @click="releaseCoupon()">
                                                        <i class="fa fa-check-square mr-2"></i>{{ $t('message.CouponIndex.modal.create_or_update.button.release_coupon') }}
                                                    </a>
                                                </AppPermission>
                                                <!-- <a class="dropdown-item">
                                                    <i class="fa fa-barcode mr-2"></i>In mã coupon
                                                </a> -->
                                                <a class="dropdown-item" @click="cancelCoupon()">
                                                    <i class="fa fa-trash-alt mr-2"></i>{{ $t('message.CouponIndex.modal.create_or_update.button.cancel_coupon') }}
                                                </a>
                                        </div>
                                    </div>
                                    <button class="btn btn-save" @click="createCouponCode()">
                                        <i class="fa fa-plus mr-2"></i>
                                        {{ $t('message.CouponIndex.modal.create_or_update.button.add') }}
                                    </button>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 mt-3">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">
                                                <th scope="col" class="pl-2 cell-check"><input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)"></th>
                                                <th scope="col" class="pl-2 cell-code-coupon">{{ $t('message.CouponIndex.modal.create_or_update.table.code') }}</th>
                                                <th scope="col" class="pl-4 cell-release-coupon">{{ $t('message.CouponIndex.modal.create_or_update.table.release_date') }}</th>
                                                <th scope="col" class="pl-4 cell-auto">{{ $t('message.CouponIndex.modal.create_or_update.table.use_date') }}</th>
                                                <th scope="col" class="pl-4 cell-status-coupon">{{ $t('message.CouponIndex.modal.create_or_update.table.status') }}</th>
                                            </tr>
                                            <tr class="table-header-search border border-bottom-0">
                                                <th scope="col" class="pl-2 cell-check"></th>
                                                <th scope="col" class="pl-2 cell-code-coupon position-relative">
                                                    <input type="text" v-model="this.keyword" :placeholder="$t('message.CouponIndex.modal.create_or_update.table.placeholder.search')" class="form-control input-custom bg-transparent pr-4 pl-0" autocomplete="off" @input="getCouponDetails(1)">
                                                    <i class="fa fa-search mr-2 text-gray"></i>
                                                </th>
                                                <th scope="col" class="pl-4 cell-release-coupon"></th>
                                                <th scope="col" class="pl-4 cell-auto"></th>
                                                <th scope="col" class="pl-4 cell-status-coupon position-relative">
                                                    <SelectSearch :placeholder="$t('message.CouponIndex.modal.create_or_update.table.placeholder.choose_method')" :readonly="true" :modelValue="statusCoupon" :defaultOption="optionStatus" @update:modelValue="doFilterSearch" :label="'statusCoupon'"/>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 mt-0 auto-scrollable">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <tbody>
                                            <template v-if="this.listCoupon?.length > 0">
                                                <tr class="table-header border" v-for="coupon in listCoupon" :key="coupon.id">
                                                    <th scope="col" class="pl-2 cell-check"><input type="checkbox" v-model="coupon.checked" @change.stop="countChecked(coupon.id)" class="prdChecked"
                                                    :id="coupon.id"  :value="coupon.code" :checked="isExistInArrayChecked(coupon, this.arrayChecked)"></th>
                                                    <th scope="col" class="pl-2 cell-code-coupon position-relative font-weight-normal" v-text="coupon.code"></th>
                                                    <th scope="col" class="pl-4 cell-release-coupon font-weight-normal" v-text="d(coupon.releaseDate)"></th>
                                                    <th scope="col" class="pl-4 cell-auto font-weight-normal" v-text="d(coupon.useDate)"></th>
                                                    <th scope="col" class="pl-4 cell-status-coupon font-weight-normal" v-text="coupon.status"></th>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div class="no-data border" v-if="this.listCoupon?.length == 0">
                                        <span>{{ $t('message.common.notFound') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 my-3">
                                <div class="col-12 pl-0">
                                    <Pagination :value="paginateDetail" :record="numRecords" @change="pageChange"/>
                                </div>
                            </div> 
                            <div class="row mx-4 p-0 my-3">
                                <div class="col-11 flex-end align-items-center p-0">
                                    {{$t('message.CouponIndex.modal.create_or_update.table.number_coupon')}}:
                                </div>
                                <div class="col-1 p-0 d-flex align-items-center pr-0">
                                    <select :value="numRecords" name="number_records" id="number_records" class="number_records mt-0" @change="changeRecords">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                    </select>
                                </div>
                                <!-- <div class="col-2 flex-end align-items-center pr-0">
                                    <button type="button" class="btn btn-secondary text-white mx-1">
                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                        Xuất file
                                    </button>
                                </div> -->
                            </div> 
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <CouponCodeModal ref="CouponCodeModal" @confirm="confirmCreateDetail"/>
    <ModalConfirm ref="ModalConfirm" :title="$t('message.CouponIndex.modal.create_or_update.alert.title_confirm')" :content="$t('message.CouponIndex.modal.create_or_update.alert.content_confirm')" @confirm="confirmCreateCoupon"/>
    <SelectCategories ref="SelectCategories" @update:modelValue="updateListCategories"/>
    <ReleaseCoupons ref="ReleaseCoupons"  @reloadData="getCouponDetails" @removeChecked="removeChecked"/>
    <CancelCoupons ref="CancelCoupons" @reloadData="getCouponDetails" @removeChecked="removeChecked"/>
</template>
<script>
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import ModalConfirm from "@/components/ModalConfirm";
import SelectCategories from "@/components/SelectCategories";
import ProductUnitsSearch from "@/components/ProductUnitsSearch";
import {$post, $alert, $get, removeVietnameseTones} from "@/lib/utils";
import moment from 'moment';
import store from "@/store";
import Pagination from "@/components/Pagination";
import CouponCodeModal from "./CouponCodeModal.vue";
import ReleaseCoupons from "./ReleaseCoupons";
import CancelCoupons from './CancelCoupons';
 import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "CouponCreateUpdateModal",
    components: { SelectSearch, CouponCodeModal, InputNumberFormat, ModalConfirm, SelectCategories, ProductUnitsSearch, Pagination, ReleaseCoupons, CancelCoupons, AppPermission},
    data () {
        const globalConfig = store.state.GlobalConfig;
        let currentDate = new Date().toJSON().slice(0, 10);
        let defaultendDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toJSON().slice(0, 10);
        return {
            isEdit: false,
            isTab: this.$constant.tab.first,
            coupon: {},
            listCoupon: [
                {id: 1, code: 'CP5WQP7304', releaseDate: '06/06/2023', useDate: '', status: 'Đã phát hành'},
                {id: 1, code: 'CP5WQP7304', releaseDate: '06/06/2023', useDate: '', status: 'Đã phát hành'},
                {id: 1, code: 'CP5WQP7304', releaseDate: '06/06/2023', useDate: '', status: 'Đã phát hành'},
                {id: 1, code: 'CP5WQP7304', releaseDate: '06/06/2023', useDate: '', status: 'Đã phát hành'},
                {id: 1, code: 'CP5WQP7304', releaseDate: '06/06/2023', useDate: '', status: 'Đã phát hành'},
            ],
            optionStatus: this.$constant.optionStatusCoupon,
            filter: {
                status: '',
            },
            numRecords: 10,
            statusCoupon: null,
            selectAllCategories: false,
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            keyword: null,
            arrayChecked: [],
            checkedAll: false,
            notEdit: false,
            type: null
        }
    },
    created() {
    },
    methods: {
        async confirmCreateDetail(data) {
            data.couponVoucherId = this.coupon.id;
            let res = await $post('/v1/coupons/create-coupon-details', {...data});
            if (res.code === 422) {
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
            } else if (res.result.status) {
                this.getCouponDetails();
                $alert({code: 200, message: this.$t('message.CouponIndex.modal.create_or_update.alert.create_list_coupon_success')});
                this.$refs.CouponCodeModal.hide();
            }
        },
        async getCouponDetails(page = null) {
            let res = await $post('/v1/coupons/get-coupon-details', {
                couponVoucherId: this.coupon.id,
                numberRecord: this.numRecords,
                status: this.statusCoupon,
                page: page,
                keyword: this.keyword,
            });
            if (res.result) {
                this.listCoupon = res.result.data;
                this.paginateDetail.currentPage = res.result.current_page;
                this.paginateDetail.lastPage = res.result.last_page;
                this.paginateDetail.totalRecord = res.result.total;
                this.isCheckedAll();
            }
        },
        async changeRecords(v) {
            this.numRecords = v.target.value;
            await this.getCouponDetails(1);
        },
        async pageChange(page) {
            await this.getCouponDetails(page);
        },
        async doFilterSearch(v){
            this.statusCoupon = v.model ?? null;
            await this.getCouponDetails(1);
        },
        show (data = null, type = null) {
            this.clearData();
            if (data) {
                this.type = type;
                this.isEdit = type === "copy" ? false : true;
                this.coupon = {...data};
                this.coupon.status = this.coupon.statusOrigin;
                this.coupon.id = !this.isEdit ? null : this.coupon.id;
                this.coupon.code = !this.isEdit ? null : this.coupon.code;
                this.notEdit = this.coupon.notEdit && this.isEdit ? true : false;
                this.getCouponDetails();
                if(data.categories && !data.isFullCategories){
                    let arrCate = [];
                    data.categories.forEach(cate => {
                        arrCate.push(cate.categoryId);
                    })
                    this.$refs.SelectCategories.updateArrayChecked(arrCate, false);
                }else if(data.isFullCategories){
                    this.$refs.SelectCategories.updateArrayChecked([], true);
                }
                if(this.$refs.ProductUnitsSearch){
                    this.$refs.ProductUnitsSearch.listItemSelected = this.coupon.products ?? [];
                }
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide () {
            this.clearData();
            this.$emit('input');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        clearData(){
            this.coupon = {
                status: this.$constant.statusActive,
                code: null,
                value: null,
                maxPrice: null,
                startDate: null,
                endDate: null,
                categories: [],
                products: [],
                name: null,
                minPrice: null,
                note: null,
                id: null,
            };
            this.isEdit = false;
            this.isTab = this.$constant.tab.first,
            this.statusCoupon = null;
            this.keyword = null;
            this.arrayChecked = [];
            this.notEdit = false;
            this.listCoupon = {};
            if(this.$refs.SelectCategories){
                this.$refs.SelectCategories.arrayChecked = [];
            }
            if(this.$refs.ProductUnitsSearch){
                this.$refs.ProductUnitsSearch.listItemSelected = [];
            }
        },
        choseTab(tab) {
            if (tab === this.$constant.tab.second) {
                if (!this.coupon.id) {
                    this.$refs.ModalConfirm.show();
                } else {
                    this.isTab = tab;
                }

            } else {
                this.isTab = tab;
            }
        },
        async confirmCreateCoupon(status) {
            if (status) {
                let infoCreate = await this.submitCoupon(this.$constant.fromConfirm);
                if (infoCreate.status) {
                    this.isTab = this.$constant.tab.second;
                    this.coupon.id = infoCreate.id;
                }
            }
        },
        createCouponCode(){
            this.$refs.CouponCodeModal.show();
        },
        async submitCoupon(type = null){
            this.coupon.startDate = moment(this.coupon.startDate).format('YYYY-MM-DD 00:00:00');
            this.coupon.endDate = moment(this.coupon.endDate).format('YYYY-MM-DD 23:59:59');
            let res;
            this.coupon.selectAllCategories = this.selectAllCategories;
            if (!this.isEdit) {
                res = await $post('/v1/coupons/create-coupon', {...this.coupon});
            } else {
                let dataSub = {...this.coupon};
                dataSub.couponVoucherId = this.coupon.id;
                res = await $post('/v1/coupons/update-coupon', {...dataSub});
            }
            if (res.code === 422) {
                this.coupon.startDate = moment(this.coupon.startDate).format('YYYY-MM-DD');
                this.coupon.endDate = moment(this.coupon.endDate).format('YYYY-MM-DD');
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                const info = {
                    status: false,
                }
                return info;
            } else if (res && res.result.status)  {
                $alert({code: 200, message: this.isEdit ? this.$t('message.CouponIndex.modal.create_or_update.alert.update_success') : this.$t('message.CouponIndex.modal.create_or_update.alert.create_success')});
                this.$emit('input');
                if (type != this.$constant.fromConfirm) {
                    this.clearData();
                    window.jQuery(this.$refs.modal).modal('hide');
                }else{
                    const info = {
                        status: true,
                        id: res.result.coupon.id
                    }
                    return info;
                }
            } else {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
            }
        },
        onInputValueCoupon(){
            if(this.coupon.value > 100){
                this.coupon.value = 100;
            }
        },
        removeCategory(id){
            this.coupon.categories.splice(this.coupon.categories.findIndex(v => v.id == id), 1);
            let arr = [];
            this.coupon.categories.forEach(item => {
                arr.push(item.id);
            })
            this.$refs.SelectCategories.arrayChecked = arr;
        },
        removeAllCategory(){
            this.coupon.categories = [];
            this.$refs.SelectCategories.arrayChecked = [];
        },
        showSelectCategories() {
            this.$refs.SelectCategories.show();
        },
        updateListCategories(value){
            this.coupon.categories = value.data;
            this.selectAllCategories = value.selectAll;
        },
        async onSelectProductUnit(value){
            if(value.label == 'products' && value.data){
                this.coupon.products = value.data;
            }
        },
        isCheckedAll(){
            let getChecked = 0;
            this.listCoupon.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.listCoupon.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        onCheckedAll(value) {
            if(value == true){
                this.listCoupon.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.listCoupon.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        removeChecked(){            
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        releaseCoupon(){
            this.$refs.ReleaseCoupons.show(this.arrayChecked);
        },
        cancelCoupon(){
            this.$refs.CancelCoupons.show(this.arrayChecked);
        },
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
.description-job-title {
    position: relative;
}
.description-job-title i{
    position: absolute;
    top: 0.6rem;
    right: 1rem;
}
.description-job-title input{
    padding-right: 1rem;
}
.label__time {
    position: absolute;
    top: 0.4rem;
    right: 5rem;
}
.block__depament {
    position: relative;
}
.format__list-time {
    top: 10px !important;
    left: -185px !important;
    width: 220px !important;
    height: 160px;
    overflow: auto;
}
.custom__list-tab {
    justify-content: flex-start;
    align-items: end;
    padding-bottom: 8px;
}
.tab__active {
    border-bottom: 2px solid $base-color !important;
}
.tab__item {
    /* min-width: 150px; */
    cursor: pointer;
    padding: 0 15px;
    border-bottom: 2px solid #f8f9fa;
}
.item__body {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.list__item {
    width: 300px;
}
.input:focus-visible {
    border: none !important;
}
.input__select {
    width: 300px;
    border: none;
    border-bottom: 1px solid $base-color;
}
.border-bottom{
    border-bottom: 1px solid $base-color !important;
    padding-bottom: 20px;
}
.dropdownSupplier {
    max-width: 16vw;
    max-height: 200px;
}
.custom__block {
    margin-right: 12px;
}

.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50% !important;
}
.options-dt input:focus,.options-dt input:hover{
    border-bottom: 2px solid #0dac50;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.currencyUnit{
    position: absolute;
    right: 0;
    top: 20%;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search{
    height: 50px !important;
    background-color: #FEFCED;
}
.cell-check{
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}
.cell-code-coupon{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-release-coupon{
    width: 190px;
    min-width: 190px;
    max-width: 190px;
}
.cell-auto{
    min-width: 160px;
}
.cell-status-coupon{
    width: 150px;
    min-width: 150px;
    max-width: 150px;
}
.listIcon{
    outline: none !important;
    background: none !important;
    height: 20px;
    width: 25px;
}
.listIcon:hover{
    background-color: #e5e5e5 !important;
    outline: 0;
    box-shadow: none;
    border-radius: 50%;
}
.cate_selected{
    background: #e5e5e5 !important;
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
/* .area_obj_selected:hover{
    border-bottom: 2px solid $base-color;
} */
.area_obj_selected::-webkit-scrollbar {
  display: none;
}

.area_obj_selected {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.dropdown-item{
    cursor: pointer;
}
</style>