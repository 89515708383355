<template>
    <div class="row">
        <ActionBar back-url="/units/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Đơn vị hàng hóa</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên đơn vị"/>


                        </div>

                        <div class="col-lg-12">
                            <InputSwitchButton v-model="entry.status"
                                               :errors="errors.status"
                                               label="Trạng thái"/>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import ActionBar from "@/components/ActionBar";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import InputSwitchButton from "../../components/forms/InputSwitchButton";
import QSelect from "@/components/QSelect";

export default {
    name: "UnitForm",
    components: {ActionBar, InputText, InputSwitchButton, QSelect},
    data() {
        return {
            isDataLoaded: false,
            entry: {},
            errors: {}
        }
    },
    mounted() {
        document.title = 'UnitForm | OmiPos';
        if (this.$route.query.id) {
            this.load();
        } else {
            this.isDataLoaded = true;
        }

    },
    methods: {
        async load() {
            const res = await $get('/v1/units/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.isDataLoaded = true;

        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/units/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        }
    }
}
</script>

<style scoped>

</style>
