<template>
    <div ref="modal" class="modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Chọn nhóm sản phẩm</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" v-if="shown">
                    <div>
                        <InputText v-model="model.name" label="Tên nhóm" :errors="errors.name"/>
                        <InputTextArea v-model="model.description" label="Mô tả nhóm"/>
                        <div class="form-group">
                            <label>Chọn sản phẩm</label>
                            <QSelect v-model="productUnitValue" name="ProductUnit" @onselected="onProductSelected" datasource="ProductUnits"/>
                        </div>
                        <div>
                            <ul class="tag-group">
                                <li class="tag-item" v-for="(pu,index) in model.productUnits" :key="pu.id">
                                    {{pu.name}}
                                    <span  @click="remove(pu,index)" class="close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
        <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
    </span>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">

                    <button type="button" class="btn btn-primary" @click="save()">Lưu lại</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputTextArea from "../../components/forms/InputTextArea";
import InputText from "../../components/forms/InputText";
import QSelect from "../../components/QSelect";
import {$post, $get} from "../../lib/utils";

export default {
    name: "ModalProductPromotionGroupForm",
    components: {QSelect, InputText, InputTextArea},
    data() {
        return {
            errors: {},
            productUnitValue: null,
            shown: false,
            batchUpdate: false,
            batchUpdateContent: '',
            model: {
                description: '',
                tagTypeId: 1,
                productUnits: []
            }
        }
    },
    methods: {
        async save() {
            const res = await $post('/v1/tags/save', {
                entry: this.model
            });

            if (res.code === -1) {
                this.errors = res.errors;
            } else if (res.code === 200) {
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },

        remove(index) {
            this.model.productUnits.splice(index, 1);
        },
        onProductSelected(entry) {
            const found =  this.model.productUnits.find(p => p.id === entry.id);
            if (!found) {
                this.model.productUnits.push(entry);
            }

            this.$nextTick(() => {
                this.productUnitValue =  null;
            })
        },
        async loadProductUnits(model) {
            const res = await $get('/v1/tags/load-product-units', {
                id: model.id
            });

            model.productUnits = res.data;
        },
        show(model, onDone) {
            if (model) {
                this.model = model;
                if (model.id) {
                    this.loadProductUnits(model)
                }
            }

            this.shown = true;
            this.$nextTick(() => {
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                }).on('hidden.bs.modal', () => {
                    if (onDone) {
                        onDone();
                    }
                })
            })
        }
    }
}
</script>

<style scoped>

</style>
