<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 pl-4" :class="entry.products && entry.products.length ? 'col-left' : ''">            
            <table class="table table-index table-striped">
                <thead class="text-center">
                <tr>
                    <th scope="col" style="width: 2%;" class="checkbox-table"></th>
                    <th scope="col" style="width: 2%;">{{$t('message.common.STT')}}</th>
                    <th scope="col" style="width: 10%;">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                    <th scope="col" style="width: 13%;">{{$t('message.DetailOrdersCard.prodName')}}</th>
                    <th scope="col" style="width: 8%;">{{$t('message.DetailOrdersCard.dvt')}}</th>
                    <th scope="col" style="width: 10%;">{{$t('message.DetailOrdersCard.attribute')}}</th>
                    <!-- <th scope="col" style="width: 7%;"  v-if="this.isHsCode">HS code</th>
                    <th scope="col" style="width: 7%;"  v-if="this.isTaxRate">Thuế suất</th>
                    <th scope="col" style="width: 10%;"  v-if="this.hasConsignment">Lô date</th>
                    <th scope="col" style="width: 10%;" v-if="this.hasStamp">Tem nhãn</th> -->
                    <th scope="col" style="width: 10%" v-if="entry.type == TYPE_RETURN">{{$t('message.common.returnQuantity')}}</th>
                    <th scope="col" style="width: 10%" v-else-if="entry.type == TYPE_INVOICE">{{$t('message.common.shortQuantity')}}</th>
                    <th scope="col" style="width: 10%" v-else>{{$t('message.common.shortQuantity')}}</th>
                    <th scope="col" style="width: 2%" v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE"></th>
                    <th scope="col" style="width: 10%;" v-if="entry.type == TYPE_RETURN">{{$t('message.ProductIndex.price')}}</th>
                    <th scope="col" style="width: 10%;" v-else>{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                    <th scope="col" style="width: 10%;">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                    <th scope="col" style="width: 10%;" v-if="entry.type == TYPE_RETURN">{{$t('message.ReturnPurchaseOrder.returnPrice')}}</th>
                    <th scope="col" style="width: 10%;" v-else>{{$t('message.ProductIndex.price')}}</th>
                    <th scope="col" style="width: 8%;">{{$t('message.DetailOrdersCard.totalPrice')}}</th>                    
                    <th scope="col" style="width: 4%;"></th>
                    <th v-if="entry.type == TYPE_RETURN"></th>
                </tr>
                </thead>
                <tbody class="font-weight-bold">
                    <template v-if="entry.products && entry.products.length">
                        <template v-for="(product, index) in entry.products" :key="product.code">
                            <!-- Hiển thị thông tin sản phẩm chính -->
                            <tr>
                                <td class="checkbox-table text-center">
                                    <a href="#" @click="removeProduct(index)" v-if="entry.type != 'approve'" >
                                        <i class="fas fa-trash-alt"></i>
                                    </a>                                    
                                </td>
                                <td class="text-center" v-text="index + 1"></td>
                                <td class="text-center">
                                    {{ product.code }}
                                </td>
                                <td>
                                    {{ product.name }}
                                    <span v-if="entry.promotionsProducts && entry.promotionsProducts.length && (checkPromotionActive(product.productUnitId, product.product_category, product.quantity))" 
                                        @click="showPromotionProduct(product.productUnitId, product.product_category, product.quantity)" class="btn-icon-gift"><i class="fa fa-gift"></i>
                                    </span>
                                    <div class="position-relative">
                                        <input type="text" v-model.trim="product.description" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                    </div>
                                </td>
                                <td>     
                                    <span v-if="entry.type == this.$constant.type_refund_approve && product.product_unit">
                                        {{ product.product_unit?.unitName }}
                                    </span>                                                                
                                    <InputSelect v-model="product.productUnitId"
                                        :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                        :options="product.product_units"
                                        v-else-if="(product.product_units && product.product_units.length && entry.type != TYPE_RETURN)"
                                        option-label="unitName"
                                        :marginAuto="true"
                                        :readonly="product.isUpdated || entry.type == 'approve'"
                                        @update:modelValue="updateProductUnit(product, null, (product.hasVariant ? true : false))"
                                        :cardTransaction="true"
                                    ></InputSelect>
                                    <InputSelect v-model="product.productUnitId"
                                        :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                        :options="product.product_units"
                                        v-else-if="(product.product_units && product.product_units.length && entry.type === TYPE_RETURN) "
                                        option-label="unitName"
                                        :marginAuto="true"
                                        :readonly="product.isUpdated || entry.type == 'approve'"
                                        @update:modelValue="updateProductUnit(product, null, (product.hasVariant ? true : false))"
                                        :cardTransaction="true">
                                    </InputSelect>
                                </td>
                                <td>
                                    <span v-if="entry.type == this.$constant.type_refund_approve">
                                        {{ product.variantName ? product.variantName : null }}
                                    </span> 
                                    <template v-else-if="product.hasVariant">
                                        <InputSelect v-model="product.variantId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                            :options="product.variants"
                                            option-label="name"  
                                            :marginAuto="true"                                                                                
                                            :cardTransaction="true">
                                        </InputSelect>
                                    </template>
                                </td> 
                                <td class="position-relative">
                                    <div class="d-flex justify-content-center">
                                        <div class="position-relative" data-toggle="dropdown" id="dropdownMenuButtonDepartment" aria-haspopup="true" aria-expanded="false" >
                                            <div class="row"> 
                                                    <InputNumberFormat                                                     
                                                    :readonly="entry.type=='approve'"
                                                    v-model="product.quantity" 
                                                    class="sale-input-number mb-0" 
                                                    :cardTransaction="true" :bgTransparent="true" 
                                                    v-if="product.quantityRemaining" 
                                                    :min="1">
                                                    </InputNumberFormat> 
                                                    <InputNumberFormat v-model="product.quantity"  class="sale-input-number mb-0" 
                                                    :readonly="entry.type=='approve'"
                                                    :class="product.inventories < product.quantity ? 'danger_fail' : ''" :cardTransaction="true" :bgTransparent="true" v-else>
                                                    </InputNumberFormat>
                                                    
                                                    <AppPermission :permission="this.$permissions.invoices.getInventory?? '' ">
                                                        <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment">
                                                            <div>{{ branchName }}</div>
                                                            <div class="font-weight-normal">
                                                                {{$t('message.ProductIndex.inventory')}} :
                                                                <template v-for="(item, index) in product.product_branch_meta" :key="index">
                                                                    <template v-if="index < product.product_branch_meta.length - 1">
                                                                        {{  item.inventories + ' ' +  item.product_unit?.unitName + ', '}}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{  (item.inventories > 0) ? item.inventories : '__'  + ' ' +  item.product_unit?.unitName }}
                                                                    </template>
                                                                </template>
                                                            </div>
                                                            <div class="font-weight-normal">{{$t('message.ProductUnitSearch.orderSupplier')}}: {{ (product.order_quantity && product.order_quantity.length) ? (product.order_quantity[0]?.totalQuantity + ' ' + product.order_quantity[0].unit.unitName) : 0 }}</div>
                                                            <div class="font-weight-normal">
                                                                {{$t('message.ProductUnitSearch.orderFromCustomer')}}:
                                                                <template v-for="(item, index) in product.product_branch_meta" :key="index">
                                                                    <template v-if="index < product.product_branch_meta.length - 1">
                                                                        {{ item.quantityOrder + ' ' +  item.product_unit?.unitName + ', '}}
                                                                    </template>
                                                                    <template v-else>
                                                                        {{ item.quantityOrder + ' ' +  item.product_unit?.unitName}}
                                                                    </template>
                                                                </template>
                                                            </div>
                                                        </div>  
                                                    </AppPermission>
                                            </div>
                                            
                                        </div>                            
                                    </div>
                                    <div class="text-center quantity-remaining" v-if="entry.action == ACTION_ORDER_PROCESSING">
                                        <span> / </span>
                                        <span v-text="product.quantityRemaining"></span>
                                    </div>
                                </td>
                                <td v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE" class="p-0 text-gray">
                                </td>
                                
                                <td class="text-center popover-wrap">
                                    <InputNumberFormat v-model="product.price" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"
                                    :readonly="product.isUpdated || entry.type == 'approve'">
                                    </InputNumberFormat>                                    
                                </td>
                                <!-- // Giảm giá hóa đơn   -->                                                                 
                                <td class="text-center position-relative" style="padding-top: 25px">                                                                
                                    <ProductDiscount :modelValue="product" :price="product.price" @update:modelValue="updateDiscount" 
                                    :readonly="product.isUpdated || entry.type == 'approve'"
                                    />
                                </td>
                                <td class="text-center">
                                    {{ $filters.formatPrice(product.amount) }}
                                </td>
                                <td class="text-center">
                                    {{ $filters.formatPrice(product.amount * product.quantity) }}
                                </td>
                                <td class="text-center">
                                    <div class="d-flex align-items-center" v-if="entry.type!== this.$constant.type_refund_approve">
                                        <i class="fas fa-plus mr-2" @click="cloneProduct(product.id)" v-if="entry.type != TYPE_RETURN"></i>
                                        <i class="fa fa-ellipsis-v" @click="showDetailsProduct(product)" v-if="entry.type != TYPE_RETURN"></i>
                                    </div>
                                </td>

                                <td v-if="entry.type == TYPE_RETURN">
                                    <i class="fa fa-plus" @click="addProductPlusUnitReturn(index, product)" v-if="entry.type!== this.$constant.type_refund_approve"></i>
                                </td>
                            </tr>

                            <!-- Mua thêm sản phẩm-->
                            <template v-if="product.plusOrder && product.plusOrder.length">
                                <template v-for="(productPlus, key) in product.plusOrder" :key="productPlus.key">
                                    <tr>
                                        <td colspan="2"></td>
                                        <td class="text-center">
                                            <div class="row m-0 d-flex">
                                                <div class="col-2 p-0">
                                                    <!-- <i class="fa fa-chevron-down" v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && !productPlus.showCombo" @click.stop="showCombo(productPlus)"></i>
                                                    <i class="fa fa-chevron-up" v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && productPlus.showCombo" @click.stop="showCombo(productPlus)"></i> -->
                                                </div>
                                                <div class="col-10 p-0">
                                                </div>
                                            </div>
                                        </td>
                                        <td></td>
                                        <td>
                                            <InputSelect v-model="productPlus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.product_units"
                                                v-if="product.product_units && product.product_units.length && entry.type != TYPE_RETURN && entry.type != this.$constant.type_refund_approve"
                                                option-label="unitName"
                                                :marginAuto="true"
                                                :readonly="product.isUpdated || entry.type == 'approve'"
                                                @update:modelValue="updateProductUnit(productPlus, product, (product.hasVariant ? true : false))"
                                                :cardTransaction="true">
                                            </InputSelect>
                                            <span v-else>
                                                {{ productPlus.productUnitText }}
                                            </span>
                                        </td>
                                        <td>
                                            <span v-if="entry.type == this.$constant.type_refund_approve">
                                                {{ productPlus.variantName ? productPlus.variantName : null }}
                                            </span> 
                                            <template v-else-if="product.hasVariant">
                                                <InputSelect v-model="productPlus.variantId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                    :options="productPlus.variants"
                                                    option-label="name"  
                                                    :marginAuto="true"                                                                                
                                                    :cardTransaction="true">
                                                </InputSelect>
                                            </template>
                                        </td>
                                        <td class="position-relative">
                                            <div class="d-flex justify-content-center">
                                                <!-- <InputNumberFormat v-model="productPlus.quantity" 
                                                :readonly="product.isConsignment && (entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))" 
                                                class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" v-if="productPlus.quantityRemaining" :max="productPlus.quantityRemaining"></InputNumberFormat>
                                                <InputNumberFormat v-model="productPlus.quantity" :readonly="product.isConsignment && (entry.type == TYPE_INVOICE || (entry.type == TYPE_RETURN && product.isConsignment) || (entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder))" 
                                                class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" v-else></InputNumberFormat> -->

                                                <InputNumberFormat v-model="productPlus.quantity" 
                                                :readonly="entry.type == this.$constant.type_refund_approve"
                                                class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                            </div>
    
                                            <div class="text-center quantity-remaining" v-if="entry.action == ACTION_ORDER_PROCESSING">
                                                <span> / </span>
                                                <span v-text="productPlus.quantityRemaining"></span>
                                            </div>
                                        </td>
                                        <td v-if="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE" class="p-0 text-gray">
                                        </td>
    
                                        <td class="text-center popover-wrap">
                                            <InputNumberFormat v-model="productPlus.price" :cardTransaction="true" :bgTransparent="true" class="sale-input-number mb-0" 
                                            :readonly="entry.type == this.$constant.type_refund_approve"
                                            ></InputNumberFormat>
                                        </td>
    
                                        <td class="text-center position-relative" style="padding-top: 25px">
                                            <ProductDiscount :modelValue="productPlus" :price="productPlus.price" @update:modelValue="updateDiscount"
                                            :readonly="entry.type == this.$constant.type_refund_approve"
                                            />
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(productPlus.amount) }}
                                        </td>
                                        <td class="text-center">
                                            {{ $filters.formatPrice(productPlus.amount * productPlus.quantity) }}
                                        </td>
                                        <td class="text-center">
                                            <i class="fas fa-trash-alt" v-if="entry.type!== this.$constant.type_refund_approve"
                                            @click="removeProductOrderPlus(product.id, key)"></i>
                                        </td>
                                        <td v-if="entry.type == TYPE_RETURN"></td>  
                                    </tr>
                                   
                                    <template v-if="productPlus.combo_products && productPlus.combo_products.length > 0 && productPlus.showCombo" >
                                        <template v-for="(itemCombo, indexCombo) in productPlus.combo_products" :key="indexCombo" >
                                            <tr style="padding: 50px;">
                                                    <td></td>
                                                <td class="text-center"></td>
                                                <td class="text-center">
                                                    <div class="row m-0 d-flex">
                                                        <div class="col-2 p-0">
                                                            <span v-text="(indexCombo + 1) + '. '"></span>
                                                        </div>
                                                        <div class="col-10 p-0">
                                                            {{ itemCombo.product.code }}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    {{ itemCombo.product.name }}
                                                </td>
                                                <td style="padding-top: 18px" class="text-center">
                                                    <span>{{ itemCombo.product_unit.unitName }}</span>
                                                </td> 
                                                <td style="padding-top: 18px" class="text-center">
                                                    <span>{{ itemCombo.product_unit.name }}</span>
                                                </td>   
                                                <td v-if="this.isHsCode"></td>
                                                <td v-if="this.isTaxRate"></td>                                                     
                                                <td class="text-right colQuantity" :class="this.isTaxRate ? 'colQuantityTax' : '' ">
                                                    {{ itemCombo.quantity }}
                                                </td>
                                                <td colspan="5"></td>
                                            </tr>
                                            <template v-if="itemCombo.product.consignmentActive && itemCombo.product.consignmentActive.length ">
                                                <tr v-for="(consignmentPlus, ind) in itemCombo.product.consignmentActive" :key="ind">
                                                    <td></td>
                                                    <td class="checkbox-table text-center">
                                                        <div>
                                                            <i v-if="ind > 0 && entry.type != TYPE_RETURN" @click="removeConsignmentProductInComboPlus(index, key, indexCombo, ind)" class="fas fa-trash-alt"></i>
                                                        </div>
                                                        <!-- <div>
                                                            {{ itemCombo.product.code }}
                                                        </div> -->
                                                    </td>
                                                    <td colspan="4" :id="'consignmentPlus_' + key">
                                                        <ChooseConsignment :hideOption="(entry.type == TYPE_RETURN)" :object="itemCombo.product" @update:value="updateConsignmentProductPlusProduct" :isSaleScreen="true" :defaultValue="consignmentPlus" :index="index" :plusIndex="key" :indexComboProducts="indexCombo" :indexConsigmentActive="ind"/>
                                                    </td>
                                                    <td v-if="this.isHsCode"></td>
                                                    <td v-if="this.isTaxRate"></td>       
                                                    <td class="position-relative" :id="'consignmentPlus_input_' + key">
                                                        <div class="d-flex justify-content-center">
                                                            <div v-if="consignmentPlus.maxQuantity && entry.type == TYPE_RETURN" class="d-flex align-items-center">
                                                                <InputNumberFormat :max="consignmentPlus.maxQuantity" :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" v-model="consignmentPlus.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                                <!-- <div class="text-left">
                                                                    <span>{{  '/' + consignmentPlus.maxQuantity }}</span>
                                                                </div> -->
                                                            </div>
                                                            <div v-else>
                                                                <InputNumberFormat :readonly="(entry.type == TYPE_RETURN && (entry.valueCoupon || entry.valueVoucher || entry.promotionId))" v-model="consignmentPlus.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td colspan="5">
                                                        <div class="flex-end" v-if="ind == 0">
                                                            <i class="fas fa-plus mr-2" @click="addConsignmentCombo(index, indexCombo, key)"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template >
                                    </template>
                                </template>
                            </template>

                        </template>
                    </template>
                    <template v-if="(entry.productGifts && entry.productGifts.length) || (entry.promotion && (entry.promotion.discountRatio || entry.promotion.discountValue))">
                        <tr class="bg-gift">
                            <!-- <td class="checkbox-table text-center border-none">
                            </td> -->
                            <!-- <td colspan="10" class="border-none">
                                <h6 class="label_gift text-bold">Khuyến mại hóa đơn</h6>
                            </td> -->
                        </tr>
                        <tr class="bg-gift" v-if="entry.productGifts && entry.productGifts.length">
                            <td class="checkbox-table text-center py-3 border-0">
                            </td>
                            <td class="text-center py-3 border-0"></td>
                            <td class="py-3 text-primary  border-top-dotted" :colspan="9">
                                {{ this.entry.promotion.name }}
                            </td>
                        </tr>
                        <template v-for="(product, productGiftIndex) in entry.productGifts" :key="product.id">
                            <tr class="bg-gift">
                                <td class="checkbox-table text-center py-3 border-0">
                                </td>
                                <td class="text-center py-3 border-0"></td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ product.productCode }}
                                </td>
                                <td class="py-3 border-top-dotted">
                                    {{ product.productName }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    <span >{{ product.productUnitText }}</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.quantity) + ' / ' + n(product.maxQuantity) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.priceOrigin) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    0
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                </td>
                            </tr>
                            <template v-if="(entry.type == TYPE_ORDER && entry.action == $constant.actionUpdateOrder) || (entry.type != TYPE_ORDER && entry.action != $constant.actionUpdateOrder)">
                                <tr v-for="(consignment, k) in product.consignmentActive" :key="k" class="bg-gift">
                                    <td>
                                    </td>
                                    <td @click="k > 0 ? removeConsignmentProductGift(productGiftIndex, k) : null" class="checkbox-table text-center">
                                        <i class="fas fa-trash-alt" v-if="k > 0"></i>
                                    </td>
                                    <td colspan="3" :id="'consignmentGift_' + key">
                                        <ChooseConsignment :object="product" @update:value="updateConsignmentProductGift" :isSaleScreen="true" :defaultValue="consignment"/>
                                    </td>
                                    <td class="position-relative">
                                        <div class="d-flex justify-content-center">
                                            <InputNumberFormat v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignment.maxQuantity ? consignment.maxQuantity : null" @change="setMaxQuantityProductGift(productGiftIndex, this.entry.productGifts[productGiftIndex].maxQuantity)"></InputNumberFormat>
                                        </div>
                                    </td>
                                    <td colspan="4">
                                    </td>
                                    <td>
                                        <div class="flex-end" v-if="k == 0">
                                            <i class="fas fa-plus mr-2" @click="addConsignment(null, null, productGiftIndex)"></i>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </template>
                    </template>
                    <template v-if="entry.productDiscount && entry.productDiscount.length">
                        <tr class="bg-gift">
                            <td class="checkbox-table text-center border-none">
                            </td>
                            <td colspan="10" class="border-none">
                                <h6 class="label_gift text-bold">{{$t('message.common.discountProduct')}}</h6>
                            </td>
                        </tr>
                        <tr class="bg-gift">
                            <td class="checkbox-table text-center py-3 border-0">
                            </td>
                            <td class="text-center py-3 border-0"></td>
                            <td class="py-3 text-primary  border-top-dotted" :colspan="9">
                                {{ this.entry.promotion.name }}
                            </td>
                        </tr>
                        <template v-for="(product, productDiscountIndex) in entry.productDiscount" :key="productDiscountIndex">
                            <tr class="bg-gift">
                                <td class="checkbox-table text-center py-3 border-0">
                                </td>
                                <td class="text-center py-3 border-0"></td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ product.productCode }}
                                </td>
                                <td class="py-3 border-top-dotted">
                                    {{ product.productName }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    <span >{{ product.productUnitText }}</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.quantity) + ' / ' + n(product.maxQuantity) }}
                                </td>

                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.priceOrigin) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted text-danger">
                                    {{ n(product.discountValue) }} <span v-if="product.discountRatio">({{ product.discountRatio }}%)</span>
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ (product.priceOrigin - product.discountValue) > 0 ? n(product.priceOrigin - product.discountValue) : 0  }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                    {{ n(product.amount) }}
                                </td>
                                <td class="text-center py-3 border-top-dotted">
                                </td>
                            </tr>

                            <tr v-for="(consignmentDiscount, productDiscountInd) in product.consignmentActive" :key="productDiscountInd" class="bg-gift">
                                <td>
                                </td>
                                <td @click="productDiscountInd > 0 ? removeConsignmentProductDiscount(productDiscountIndex, productDiscountInd) : null" class="checkbox-table text-center">
                                    <i class="fas fa-trash-alt" v-if="productDiscountInd > 0"></i>
                                </td>
                                <td colspan="3" :id="'consignmentDiscount_' + key">
                                    <ChooseConsignment :object="product" @update:value="updateConsignmentProductDiscount" :isSaleScreen="true" :defaultValue="consignmentDiscount"/>
                                </td>
                                <td class="position-relative">
                                    <div class="d-flex justify-content-center">
                                        <InputNumberFormat v-model="consignmentDiscount.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignmentDiscount.maxQuantity ? consignmentDiscount.maxQuantity : null"
                                         @change="setMaxQuantityProductDiscount(productDiscountIndex, this.entry.productDiscount[productDiscountIndex].maxQuantity)"></InputNumberFormat>
                                    </div>
                                </td>
                                <td colspan="4">
                                </td>
                                <td>
                                    <div class="flex-end" v-if="productDiscountInd == 0">
                                        <i class="fas fa-plus mr-2" @click="addConsignment(null, null, null, productDiscountIndex)"></i>
                                    </div>
                                </td>
                            </tr>
                        </template>
                    </template>
                    <!-- Không có data -->
                    <template v-if="(!entry.products || !entry.products.length) && (!entry.productGifts || !entry.productGifts.length) && (!entry.productDiscount || !entry.productDiscount.length)">
                        <tr>
                            <td :colspan="entry.type == TYPE_RETURN && entry.action == ACTION_RETURN_INVOICE ? 12 : 11" class="text-center p-4">{{$t('message.common.noProduct')}}</td>                            
                            <td v-if="this.isHsCode"></td>
                            <td v-if="this.isTaxRate"></td>
                            <td v-if="this.hasConsignment"></td>
                            <td v-if="this.hasStamp"></td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="col-4 col-xl-3 col-right">
            <div class="col-right__content">
                <div class="row">
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.common.created_by')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0 pt-2">  
                                <SelectSearch :placeholder="$t('message.common.created_by')" :modelValue="entry.created_by" :defaultOption="branchAdmins" 
                                @update:modelValue="selectCreatedBy" :label="'createdBy'" :readonly="true" :disabled="true">
                                </SelectSearch>                                  
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mt-4">
                        
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.common.createdAt')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <div class="form-control" readonly v-text="entry.created_at ? entry.created_at : null"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-1">
                            <div class="col-12 p-0">
                                {{$t('message.common.businessMan')}} 
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0 pt-2">                                
                                <SelectSearch :placeholder="$t('message.common.businessMan')" :modelValue="entry.soldById" :defaultOption="branchAdmins" 
                                @update:modelValue="selectSoldBy" :label="'saleBy'" 
                                :disabled="entry.type == 'approve' ? true : false "></SelectSearch>
                                <!-- v-if="entry.type != 'approve'" -->
                            </div>
                        </div>
                    </div>
                     <div class="col-6 mt-4">
                        <div class="row m-0 mb-1">
                            <div class="col-12 p-0">
                                {{$t('message.supplierIndex.table.code')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <input type="text" v-model="entry.code" :placeholder="$t('message.common.autocompleteCode')" 
                                :disabled="entry.type == 'approve' ? true : false "
                                class="form-control border-0 border-bottom-1">
                            </div>
                        </div>
                    </div>
                </div>
               
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.InvoiceIndex.code')}}
                    </div>
                    <div class="col-6 mt-4 text-right">
                        {{ entry.invoiceCode }}
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.InvoiceIndex.total_quantity')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalQuantity)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.InvoiceIndex.total_origin')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalOrigin - entry.discountValue)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.ReturnIndex.returnCustomer')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.total)">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="entry.description" class="input__textArea mb-0"/>
                    </div>
                </div>
            </div>
            <div class="col-right__save py-0 row">
                <div class="col-12 text-center">                  
                    <div class="row pb-4" v-if="entry.action == ACTION_CREATE">
                        <div class="col-12">
                             <AppPermission :permission="this.$permissions.refunds.create?? '' ">
                                <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click.stop="refundCreate(entry)">
                                    <div>
                                        {{$t('message.button.saveDraft')}}
                                    </div>
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div class="row my-2" v-else>                        
                        <div class="col-12 pb-3"  v-if="entry.type == 'update'">                                                       
                            
                            <AppPermission :permission="this.$permissions.refunds.update?? '' ">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save"  @click.stop="refundUpdate(entry)">
                                <i class="fa fa-save"></i> 
                                {{$t('message.button.save')}} 
                            </button>
                            </AppPermission>
                        </div>
                        <div class="col-12 pb-3" v-if="entry.type == 'approve'">
                            <div class="row">                                 
                                <div class="col-6" @click.stop="approveRefund(entry)">
                                     <AppPermission :permission="this.$permissions.refunds.approve?? '' ">
                                        <button type="button" :disabled="isButtonDisabled" class="btn btn-save">
                                            <span style="font-size:15px;">
                                                <i class="fa fa-check"></i> 
                                                {{$t('message.common.approve')}} 
                                            </span>
                                        </button>
                                    </AppPermission>
                                </div>
                                <div class="col-6" >
                                     <AppPermission :permission="this.$permissions.refunds.refuse?? '' ">
                                        <button type="button" :disabled="isButtonDisabled" class="btn btn-danger" style="bg-color:red" @click.stop="refuseRefunds(entry)">                                          
                                            <span style="font-size:15px;">
                                            <i class="fa fa-ban mr-1"></i> 
                                            {{$t('message.common.refuse')}}      
                                            </span>                        
                                        </button>
                                    </AppPermission>
                                </div>
                            </div>

                        </div>
                       
                       
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="">
        <ModalAddInfoCustomer ref="ModalAddInfoCustomer" :action="$t('message.customerIndex.modal.titleCreate')" @updated="getCustomers" @selectCustomer="selectCustomer"/>
        <ModalDetailProduct ref="modalDetailProduct" @updateQuantity="updateQuantity" v-model="entry"></ModalDetailProduct>
        <ModalPromotionInvoiceList ref="modalPromotionInvoiceList" @choosePromotion="choosePromotion"></ModalPromotionInvoiceList>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="getSaleChannels()" :typeEditAdd="'ADD'"/>
        <ModalPromotionWarning ref="ModalPromotionWarning" @outputData="promotionWarning"/>
        <ModalRefundConfirm ref="ModalRefundConfirm"/>
    </div>
</template>
<script>
    import InputSelect from "../../components/forms/InputSelect";
    import store from "../../store";
    import ModalAddInfoCustomer from '@/components/customers/ModalAddInfoCustomer';
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone, TYPE_REFUND, forEach} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import {ACTION_CREATE, DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_VND, TYPE_INVOICE, TYPE_ORDER, ACTION_ORDER_PROCESSING, ACTION_UPDATE_ORDER, ACTION_UPDATE_INVOICE, TYPE_RETURN, ACTION_RETURN_INVOICE} from "../../lib/utils";
    import InputTextArea from "../../components/forms/InputTextArea";
    import storage from "../../lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import ModalDetailProduct from './ModalDetailProduct';
    import ModalPromotionWarning from './ModalPromotionWarning';
    import ModalPromotionInvoiceList from './ModalPromotionInvoiceList'
    import emitter from "@/lib/emitter";
    import ChooseConsignment from "@/components/ChooseConsignment.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import permissionMixin from '@/permissionMixin';    
    import ModalRefundConfirm from '@/views/request_refund/tabs/ModalRefundConfirm';
    export default {
        name: "RefundTab",
        emits: ['removeTab', 'orderProcessing', 'print'],
        mixins: [currencyMixin,permissionMixin],
        components: {
            ProductDiscount,
            ModalRefundConfirm,
            ModalDetailProduct,
            InputTextArea,
            InputNumberFormat,
            ModalAddInfoCustomer,
            InputSelect,
            SelectSearch,
            ModalAddInfoSaleChannels,
            ModalPromotionInvoiceList,
            ModalPromotionWarning,
            ChooseConsignment, AppPermission
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            return {
                user: store.state.Auth.user.id,
                userName: store.state.Auth.user.name,
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                priceLists: [],
                customers: [],
                productIds: [],
                    productDetail: null,
                paymentChannel: [this.$constant.paymentChannelCashMoney],
                branchAdmins: null,
                branchName: store.state.CurrentBranch.name ?? null,             
                TYPE_INVOICE,
                TYPE_ORDER,
                TYPE_RETURN,
                mapTypeLabel: {
                    [TYPE_INVOICE]: 'Hóa đơn ',
                    [TYPE_ORDER]: 'Đặt hàng',
                    [TYPE_REFUND]: 'Đề nghị hoàn hàng'
                },
                ACTION_UPDATE_ORDER,
                ACTION_UPDATE_INVOICE,
                ACTION_ORDER_PROCESSING,
                ACTION_RETURN_INVOICE,
                DISCOUNT_TYPE_VND,
                DISCOUNT_TYPE_PERCENT,
                ACTION_CREATE,
                productUnitIds: null,
                categoriesIds: null,
                disablePromotionInvoiceIds: [],
                disablePromotionProductIds: [],
                totalQuantityMax: 0,
                isChangeCustomer: false,
                readonlyCombo: true,
                hasConsignment: false,
                hasStamp: false,
                isButtonDisabled: false
            
            }
        },
        async mounted() {            
            switch (this.entry.type) {
                case TYPE_RETURN:
                    document.title = 'Trả hàng';       
                    break;
                case TYPE_INVOICE:
                    document.title = 'Hóa đơn';       
                    break;
                case TYPE_ORDER:
                    document.title = 'Đặt hàng';       
                    break;
                case TYPE_REFUND:
                    document.title = 'Đề nghị hoàn hàng';       
                    break;
            }

        },
        async created() {
            this.getPriceList();
            this.getCustomers();
            this.setValue();
            this.getUserActive();
            await this.formatDefaultData();
        },
         computed: {
            permissionInfo: () => store.state.PermissionInfo,   
            authData: () => store.state.Auth
        },
        methods: {
            formatDefaultData(){
                if(this.entry.products){
                    this.entry.products.forEach(product => {
                        if(product.hasVariant){
                            this.getListAttributeForUnit(product, true);
                        }
                        if(product.plus){
                            product.plus.forEach(plusItem => {
                                if(product.hasVariant){
                                    this.getListAttributeForUnit(plusItem, true);
                                }
                            });
                        }else if(product.plusOrder){
                            product.plusOrder.forEach(plusItem => {
                                if(product.hasVariant){
                                    this.getListAttributeForUnit(plusItem, true);
                                }
                            });
                        }
                    });
                }
            },
            async approveRefund(entry){
                this.$refs.ModalRefundConfirm.show(entry, this.$constant.type_approve );
            },
            async refuseRefunds(entry){
                this.$refs.ModalRefundConfirm.show(entry, this.$constant.type_refuse );              
            },
            totalQuantity(details){                
                let total = 0;
                if(Array.isArray(details)){
                    details.forEach(item => {
                                        if(item!== null){
                                            total = total + item.quantity;
                                        }                    
                                    });
                }
                else {
                     total = total + details.quantity;
                }
                
                return total;
            },           
            getRefundDetailConsignment(details){
                let data = [];
                 if(Array.isArray(details)){
                     details.forEach(item => {
                         if(item.consignmentActive !== null){
                             item.consignmentActive.forEach(value => {
                                 data.push({
                                     refundDetailId: null,
                                     consignmentProductBranchMetaId: value?.consignmentProductBranchMetaId?? null,
                                     quantity: value?.quantity?? 0,
                                     created_at : new Date(),
                                 });
                             });                        
                         }
                         else {
                             data = [];
                         }
                     });
                 }
                 else {
                    data.push({
                        refundDetailId: null,
                        consignmentProductBranchMetaId: details?.consignmentProductBranchMetaId?? null,
                        quantity: details?.quantity?? 0,
                        created_at : new Date(),
                    });
                 }
                return data;
            },
            getRefundDetails(details, refundId){
               
                let dataDetails = [];
                details.forEach( item => {
                    if(item!== null){
                        dataDetails.push({
                            refundId: item.refundId?? null,
                            productId: item.productId?? null,
                            productUnitId: item.productUnitId?? null,
                            price: item.price?? 0,
                            quantity: item.quantity?? 0, 
                            discountType : item.discountType ?? 0,
                            discountValue : item.discountValue ?? 0,
                            discountRatio : item.discountRatio ?? 0,
                            description : item.description ? item.description : '',
                            subTotal : item.subTotal ?? 0,
                            created_by : store.state.Auth.user.id,                          
                            created_at : new Date(),
                            parentId: item.parentId?? null,
                            consignmentActive: item.consignmentActive ?? [],
                        })
                    }
                    if(item.plusOrder){
                        item.plusOrder.forEach(pls => {                            
                            dataDetails.push({
                            refundId: refundId,
                            productId:  item.productId,
                            productUnitId: pls.productUnitId,
                            price: pls.price,
                            quantity: pls.quantity, 
                            discountType : pls.discountType ?? 0,
                            discountValue : pls.discount ?? 0,
                            discountRatio : pls.discountRatio ?? 0,
                            description : pls.description ? pls.description : '',
                            subTotal : pls.subTotal ?? 0,
                            created_by : store.state.Auth.user.id,                          
                            created_at : new Date(),    
                            parentId: pls.parentId?? null,
                            consignmentActive: pls.consignmentActive ?? [],                        
                            })
                        });
                    }

                    // Combo
                    // if(item.children_combo) {
                    //     item.children_combo.forEach(combo => {
                    //         dataDetails.push({
                            // refundId: refundId,
                    //         productId: combo.id ,
                    //         productUnitId: combo.productUnitId,
                    //         price: combo.price,
                    //         quantity: combo.quantity, 
                    //         discountType : combo.discountType ?? 0,
                    //         discountValue : combo.discountValue ?? 0,
                    //         discountRatio : combo.discountRatio ?? 0,
                    //         description : combo.description?? null,
                    //         subTotal : combo.subTotal ?? 0,
                    //         created_by : store.state.Auth.user.id,                          
                    //         created_at : new Date(),
                    //         })
                    //     });
                    // }
                }); 
                return dataDetails;
            },
            async refundCreate(entry){                      
                // if(!this.entry.products) {
                //      toastr.error('Vui lòng kiểm tra dữ liệu');
                //      return;
                // }
                // emitter.emit("onLoading");                  
                // let data = {                    
                //     code: entry.code,
                //     status: this.$constant.refund_status_origin?? 1,                    
                //     branchId:entry.branchId,
                //     invoiceId:entry.invoiceId,                    
                //     totalOrigin:entry.totalOrigin,
                //     // totalQuantity:this.totalQuantity(entry.invoice_items),
                //     totalQuantity:entry.totalQuantity,
                //     discountType:entry.discountType,
                //     discountValue:entry.discountValue,
                //     discountRatio:entry.discountRatio,  
                //     sold_by: entry.soldById,                
                //     total: entry.total,
                //     description: entry.description?? '',
                // }                
                // let details = this.getRefundDetails(entry.products);    
                // let refundDetailConsignment = this.getRefundDetailConsignment(entry.invoice_items);                            
                // const res = await $post('/v1/refunds/create', {
                //     data: data,
                //     details:details,
                //     refundDetailConsignment: refundDetailConsignment
                // });
                // if(res && res.status.code == 200 && res.result){
                //      toastr.success(this.$t('message.common.addRequestRefundSuccess'));
                //      window.location.href="/request-refund/index"
                // }
                // else {
                //      toastr.error('Tạo phiếu đề nghị hoàn hàng không thành công');
                // }
                // emitter.emit("offLoading");
                this.isButtonDisabled = true;
                const data = cloneObject(this.entry);
                if(!this.entry.products || this.entry.products?.length <= 0){
                    toastr.error(this.$t('message.common.emptyRequestRefund'));
                    this.isButtonDisabled = false;
                    return;
                }
                const res = await $post('/v1/refunds/create', data);
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } else if (res.status.code == 200 && res.result.status) {
                    toastr.success(this.$t('message.common.addRequestRefundSuccess'));
                    await this.$router.replace({
                        path: '/request-refund/index',
                        query: {}
                    });
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            getTotal(details){
                let total = 0;
                details.forEach(item => {                    
                    total = total + (item.price - item.discountValue) * item.quantity;
                });
                return total;
            },           
            async refundUpdate(entry){ 
                const data = cloneObject(this.entry);
                if(!this.entry.products || this.entry.products?.length <= 0){
                    toastr.error(this.$t('message.common.emptyRequestRefund'));
                    return;
                }
                this.isButtonDisabled = true;
                const res = await $post('/v1/refunds/update', data);
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } else if (res.status.code == 200 && res.result.status) {
                    toastr.success(this.$t('message.common.updatedRequestRefundSuccess'));
                    await this.$router.replace({
                        path: '/request-refund/index',
                        query: {}
                    });
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            showTaxRate(){                
                this.isTaxRate = !this.isTaxRate;
            },
            showHsCode(){                
                this.isHsCode = !this.isHsCode;
            },
            async changeOptionConsignment(){
                this.hasConsignment = !this.hasConsignment;
                this.entry.consignment = this.hasConsignment;
            },
            async changeOptionStamp(){
                this.hasStamp = !this.hasStamp;
                if(this.hasStamp && this.entry){
                    this.entry.products.forEach(product => {
                        if(!product.stamp){
                            product.stamp = this.$constant.statusHasStamp;
                        }
                        if(product.plusOrder && product.plusOrder.length){
                            product.plusOrder.forEach(plusItem => {
                                if(!plusItem.stamp){
                                    plusItem.stamp = this.$constant.statusHasStamp;
                                }
                            });
                        }
                    });
                }
                this.entry.stamp = this.hasStamp;
            },
            async updateConsignment(data){
                if(this.entry.products){
                    let productIndex = this.entry.products.findIndex(prod => prod.id == data.object.id);
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[productIndex].consignmentActive[data.index].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let totalQuantity = 0;
                        this.entry.products[productIndex].consignmentActive.forEach(el => {
                            if(el.consignmentId == consignment.id){
                                totalQuantity += el.quantity;
                            }
                        })
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                            // quantityRemaining: 
                        };
                        if(this.entry.products[productIndex].consignmentActive?.length == 1 && this.entry.products[productIndex].consignmentActive[0].consignmentId == null){
                            this.entry.products[productIndex].consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[productIndex].consignmentActive[data.index] = newConsignment;
                        }
                    });
                }
            },
            async updateConsignmentProductPlus(data){
                let productIndex = this.entry.products.findIndex(prod => prod.id == data.object.parentId);
                if(data.data?.length == 0 && data.defaultValue){
                    // const consignmentIndex = this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.findIndex(v => v.consignmentId == data.defaultValue.consignmentId);
                    this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[data.index].consignmentId = null;
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.forEach(el => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        productUnitId: productUnitId,
                    };
                    if(this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive?.length == 1 && this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[0].consignmentId == null){
                        this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[0] = newConsignment;

                    }else{
                        let consignmentIndex = this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.consignmentId == data.defaultValue.consignmentId);
                        if(consignmentIndex > -1){
                            this.entry.products[productIndex].plusOrder[data.plusIndex].consignmentActive[data.index] = newConsignment;
                        }
                    }
                });
            },
            // Cập nhật lại lô date của sản phẩm được break để trả
            async updateConsignmentProductReturn(data){
                if(data.data?.length == 0 && data.defaultValue){
                    this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId = null;
                    return;
                }
                data.data.forEach(consignment => {
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: consignment.quantity,
                        name: consignment.name,
                        productUnitId: productUnitId,
                    };
                    this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive] = newConsignment;
                });
                // this.entry.products[data.index].plusReturn[data.plusIndex].consignmentActive[data.indexConsigmentActive] = data.data;
            },
            async updateConsignmentProductPlusProduct (data) {
                // if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                        };
                        if(this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive?.length == 1 && this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0].consignmentId == null){
                            this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[data.index].plusOrder[data.plusIndex].combo_products[data.indexComboProducts].product.consignmentActive[data.indexConsigmentActive] = newConsignment;
                        }
                    });
                // }
            },
            async updateConsignmentProductGift(data){
                let productIndex = this.entry.productGifts.findIndex(prod => prod.productUnitId == data.object.productUnitId);
                if(data.data?.length == 0 && data.defaultValue){
                    const consignmentIndex = this.entry.productGifts[productIndex].consignmentActive.findIndex(v => v.key == data.defaultValue.key);
                    this.entry.productGifts[productIndex].consignmentActive[consignmentIndex].consignmentId = null;
                    this.entry.productGifts[productIndex].consignmentActive[consignmentIndex].quantity = 0;
                    this.setMaxQuantityProductGift(productIndex, this.entry.productGifts[productIndex].maxQuantity);
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    if (!this.entry.productGifts[productIndex].consignmentActive) {
                        this.entry.productGifts[productIndex].consignmentActive = [];
                    }
                    let maxQuantity = this.entry.productGifts[productIndex].maxQuantity;
                    let quantityActive = 0
                    this.entry.productGifts[productIndex].consignmentActive.forEach((el , index) => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        } else {
                            quantityActive = +quantityActive + (el.quantity ?? 0);
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        key: data.defaultValue.key,
                        productUnitId: productUnitId,
                    };
                    if(this.entry.productGifts[productIndex].consignmentActive?.length == 1 && this.entry.productGifts[productIndex].consignmentActive[0].consignmentId == null){
                        this.entry.productGifts[productIndex].consignmentActive[0] = newConsignment;
                    }else{
                        let consignmentIndex = this.entry.productGifts[productIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.key == data.defaultValue.key);
                        if(consignmentIndex > -1){
                            this.entry.productGifts[productIndex].consignmentActive[consignmentIndex] = newConsignment;
                        }
                    }
                    this.setMaxQuantityProductGift(productIndex, maxQuantity);
                });
            },
            async updateConsignmentProductDiscount(data){
                let productIndex = this.entry.productDiscount.findIndex(prod => prod.productUnitId == data.object.productUnitId);
                if(data.data?.length == 0 && data.defaultValue){
                    const consignmentIndex = this.entry.productDiscount[productIndex].consignmentActive.findIndex(v => v.key == data.defaultValue.key);
                    this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex].consignmentId = null;
                    this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex].quantity = 0;
                    this.setMaxQuantityProductDiscount(productIndex, this.entry.productDiscount[productIndex].maxQuantity);
                    return;
                }
                data.data.forEach(consignment => {
                    let totalQuantity = 0;
                    if (!this.entry.productDiscount[productIndex].consignmentActive) {
                        this.entry.productDiscount[productIndex].consignmentActive = [];
                    }

                    let maxQuantity = this.entry.productDiscount[productIndex].maxQuantity;
                    let quantityActive = 0
                    this.entry.productDiscount[productIndex].consignmentActive.forEach((el , index) => {
                        if(el.consignmentId == consignment.id){
                            totalQuantity += el.quantity;
                        } else {
                            quantityActive = +quantityActive + (el.quantity ?? 0);
                        }
                    })
                    let productUnitId = data.object.productUnitId;
                    let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                    let newConsignment = {
                        consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                        consignmentId: consignmentProductBranchMeta.consignmentId,
                        quantity: totalQuantity < consignmentProductBranchMeta.inventories ? consignment.quantity : consignmentProductBranchMeta.inventories,
                        name: consignment.name,
                        expried: consignment.expried,
                        key: data.defaultValue.key,
                        productUnitId: productUnitId,
                    };
                    if(this.entry.productDiscount[productIndex].consignmentActive?.length == 1 && this.entry.productDiscount[productIndex].consignmentActive[0].consignmentId == null){
                        this.entry.productDiscount[productIndex].consignmentActive[0] = newConsignment;
                    }else{
                        let consignmentIndex = this.entry.productDiscount[productIndex].consignmentActive.findIndex(consignmentActive => consignmentActive.key == data.defaultValue.key);
                        if(consignmentIndex > -1){
                            this.entry.productDiscount[productIndex].consignmentActive[consignmentIndex] = newConsignment;
                        }
                    }
                    this.setMaxQuantityProductDiscount(productIndex, maxQuantity);
                });
            },
            async updateConsignmentComboProducts(data){
                if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let consignmentProductBranchMeta = consignment.consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnitId);
                        let newConsignment = {
                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                            consignmentId: consignmentProductBranchMeta.consignmentId,
                            quantity: consignment.quantity,
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                        };
                        if(this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive?.length == 1 && this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0].consignmentId == null){
                            this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[0] = newConsignment;
                        }else{
                            this.entry.products[data.index].combo_products[data.plusIndex].product.consignmentActive[data.indexComboProducts] = newConsignment;
                        }
                    });
                }
            },
            setMaxQuantityProductGift(productIndex, maxQuantity){
                if (this.entry.productGifts[productIndex] && this.entry.productGifts[productIndex].consignmentActive && this.entry.productGifts[productIndex].consignmentActive.length) {
                    this.entry.productGifts[productIndex].consignmentActive.forEach(el => {
                        const getQuantity = this.entry.productGifts[productIndex].consignmentActive.reduce(function(sum, record) {
                            return (record.key != el.key) ? sum + (record.quantity ? record.quantity : 0) : sum;
                        }, 0);
                        el.maxQuantity = (maxQuantity - getQuantity) < 0 ? 0 : (maxQuantity - getQuantity);
                    })
                }
            },
            setMaxQuantityProductDiscount(productIndex, maxQuantity) {
                if (this.entry.productDiscount[productIndex] && this.entry.productDiscount[productIndex].consignmentActive && this.entry.productDiscount[productIndex].consignmentActive.length) {
                    this.entry.productDiscount[productIndex].consignmentActive.forEach(el => {
                        const getQuantity = this.entry.productDiscount[productIndex].consignmentActive.reduce(function(sum, record) {
                            return (record.key != el.key) ? sum + (record.quantity ? record.quantity : 0) : sum;
                        }, 0);
                        el.maxQuantity = (maxQuantity - getQuantity) < 0 ? 0 : (maxQuantity - getQuantity);
                    })
                }
            },
            addConsignment(productIndex, productPlusIndex = null, productGiftIndex = null, productDiscountIndex = null){
                if(productPlusIndex !== null){
                    var maxKey = this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive == undefined){
                        this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive = [];
                    }
                    this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.push({
                        key: maxKey + 1,
                        consignmentId: null,
                    });
                } else if (productGiftIndex !== null) {
                    if(this.entry.productGifts[productGiftIndex].consignmentActive == undefined){
                        this.entry.productGifts[productGiftIndex].consignmentActive = [];
                    }
                    var maxKey1 = this.entry.productGifts[productGiftIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    let maxQuantity = this.entry.productGifts[productGiftIndex].quantity;
                    let totalQuantity = 0;
                    this.entry.productGifts[productGiftIndex].consignmentActive.forEach(consignment => {
                        totalQuantity += consignment.quantity ? consignment.quantity : 0;
                    })
                    this.entry.productGifts[productGiftIndex].consignmentActive.push({
                        key: maxKey1 + 1,
                        consignmentId: null,
                        quantity: 0,
                        maxQuantity: (maxQuantity - totalQuantity) < 0 ? 0 : (maxQuantity - totalQuantity),
                    });
                }
                else if (productDiscountIndex !== null) {
                    if(this.entry.productDiscount[productDiscountIndex].consignmentActive == undefined){
                        this.entry.productDiscount[productDiscountIndex].consignmentActive = [];
                    }
                    var maxKey3 = this.entry.productDiscount[productDiscountIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    let maxQuantity = this.entry.productDiscount[productDiscountIndex].quantity;
                    let totalQuantity = 0;
                    this.entry.productDiscount[productDiscountIndex].consignmentActive.forEach(consignment => {
                        totalQuantity += consignment.quantity ? consignment.quantity : 0;
                    })
                    this.entry.productDiscount[productDiscountIndex].consignmentActive.push({
                        key: maxKey3 + 1,
                        consignmentId: null,
                        quantity: 0,
                        maxQuantity: (maxQuantity - totalQuantity) < 0 ? 0 : (maxQuantity - totalQuantity),
                    });
                }
                else{
                    var maxKey2 = this.entry.products[productIndex].consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    this.entry.products[productIndex].consignmentActive.push({
                        key: maxKey2 + 1,
                        consignmentId: null,
                    });
                }
            },
            addConsignmentPlusReturn(productIndex, productPlusReturnIndex){
                if(this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive == undefined){
                    this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive = [];
                }
                this.entry.products[productIndex].plusReturn[productPlusReturnIndex].consignmentActive.push({
                    consignmentId: null,
                    quantity: null,
                });
            },
            addConsignmentCombo (productIndex, comboIndex, plusComboIndex = null) {          
                if (plusComboIndex != null) {
                    var maxKey = this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive == undefined){
                        this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive = [];
                    }
                    else{
                        this.entry.products[productIndex].plusOrder[plusComboIndex].combo_products[comboIndex].product.consignmentActive.push({
                            key: maxKey + 1,
                            consignmentId: null,
                        });
                    }
                } else {
                    var maxKey1 = this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive.reduce((acc, value) => {
                        return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                    }, 0);
                    if(this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive == undefined){
                        this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive = [];
                    }
                    else{
                        this.entry.products[productIndex].combo_products[comboIndex].product.consignmentActive.push({
                            key: maxKey1 + 1,
                            consignmentId: null,
                        });
                    }
                }
            },
            choosePromotion(promotion) {
                if (!promotion) {
                    if (this.entry.promotion && this.entry.promotion.promotionById == this.$constant.promotionProductId){
                        this.clearDataProduct();
                    }
                    this.clearPromotion();
                    return;
                }
                this.clearPromotion();
                if (promotion && promotion.promotionById == this.$constant.promotionInvoiceId) {
                    this.applyPromotionForInvoice(promotion);    
                }
                if (promotion && promotion.promotionById == this.$constant.promotionProductId) {
                    this.applyPromotionForProduct(promotion);
                }
                if (promotion && promotion.promotionById == this.$constant.promotionAllId) {
                    this.applyPromotionForInvoiceAndProduct(promotion);
                }
            },
            clearDataProduct() {
                this.getPriceProductForPiceList();
            },
            applyPromotionForInvoiceAndProduct(promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById : promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                };
                if (promotion.promotionFromId == this.$constant.promotion_all_invoice_discount) {
                    this.setDiscountPrice(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_all_donate_product) {
                    this.setProductGift(promotion)
                } else if (promotion.promotionFromId == this.$constant.promotion_all_product_discount) {
                    this.setProductDiscount(promotion);
                }  
            },
            setProductGift(promotion) {
                this.entry.productGifts = [];
                promotion.receiveProductUnit.forEach(productUnit => {
                    if(productUnit.quantity){
                        let productGift = {
                            productId: productUnit.product.id,
                            productName: productUnit.product.name,
                            productCode: productUnit.product.code,
                            productUnitId: productUnit.productUnitId ?? productUnit.id,
                            productUnitText: productUnit.unitName,
                            quantity: productUnit.quantity,
                            discount: null,
                            priceOrigin: productUnit.price,
                            discountType: null,
                            discountValue: null,
                            maxQuantity: productUnit.quantity,
                            amount: 0,
                            consignments: productUnit.product.consignments ?? productUnit.consignments,
                            isConsignment: productUnit.product.isConsignment,
                        };
                        if (productGift.isConsignment) {
                            if (productGift.consignments && productGift.consignments.length) {
                                productGift.consignmentActive = [];
                                let newConsignment = {
                                    key: 1,
                                    consignmentProductBranchMetaId: productGift.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productGift.productUnitId).id,
                                    consignmentId: productGift.consignments[0].id,
                                    quantity: productUnit.quantity,
                                    maxQuantity: productUnit.quantity,
                                    name: productGift.consignments[0].name,
                                    expried: productGift.consignments[0].expried,
                                    productUnitId: productUnit.id,
                                };
                                productGift.consignmentActive[0] = newConsignment;
                                this.entry.productGifts.push(productGift);
                            } else {
                                toastr.error('Tồn kho của sản phẩm '+productGift.productName +' không đủ.');
                            }
                        }
                    }
                });
                if (!this.entry.productGifts.length) {
                    this.entry.promotion = null;
                }
            },
            setDiscountPrice(promotion) {
                if (promotion.promotion_options.discountValue && promotion.promotion_options.discountValue != this.$constant.priceZero) {
                    this.entry.promotion.discountValue = promotion.promotion_options.discountValue;
                } else if (promotion.promotion_options.discountRatio) {
                    this.entry.promotion.discountRatio = promotion.promotion_options.discountRatio;
                }
            },
            setProductDiscount(promotion) {
                this.entry.productDiscount = [];
                promotion.receiveProductUnit.forEach(productUnit => {
                    if(productUnit.quantity){
                        let discountValue = 0;
                        let discountRatio = null;
                        if (promotion.promotion_options.discountValue != this.$constant.priceZero && promotion.promotion_options.discountValue) {
                            discountValue = promotion.promotion_options.discountValue;
                        } else if (promotion.promotion_options.discountRatio) {
                            discountValue = promotion.promotion_options.discountRatio*productUnit.price/100;
                            discountRatio = promotion.promotion_options.discountRatio;
                        }
                        let productDiscount = {
                            productId: productUnit.product.id,
                            productName: productUnit.product.name,
                            productCode: productUnit.product.code,
                            productUnitId: productUnit.id,
                            productUnitText: productUnit.unitName,
                            quantity: productUnit.quantity,
                            maxQuantity: productUnit.quantity,
                            discount: null,
                            priceOrigin: productUnit.price,
                            discountType: null,
                            discountValue: discountValue,
                            discountRatio: discountRatio,
                            amount: (+productUnit.price - +discountValue) > 0 ? (+productUnit.price - +discountValue)*productUnit.quantity : 0,
                            valuePercent: promotion.promotion_options.discountRatio,
                            isConsignment: productUnit.product.isConsignment,
                        };
                        if (productDiscount.isConsignment) {
                            if (productUnit.product.consignments && productUnit.product.consignments.length) {
                                let newConsignment = {
                                    key: 1,
                                    consignmentProductBranchMetaId: productUnit.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == productUnit.id).id,
                                    consignmentId: productUnit.product.consignments[0].id,
                                    quantity: productUnit.quantity,
                                    maxQuantity: productUnit.quantity,
                                    name: productUnit.product.consignments[0].name,
                                    expried: productUnit.product.consignments[0].expried,
                                    productUnitId: productUnit.id,
                                };
                                productDiscount.consignmentActive = [newConsignment];
                                productDiscount.consignments = productUnit.product.consignments;
                                this.entry.productDiscount.push(productDiscount);
                            } else {
                                toastr.error('Tồn kho của sản phẩm '+productDiscount.productName +' không đủ.');
                                return false;
                            }
                        }
                    }
                });
                if (!this.entry.productDiscount.length) {
                    this.entry.promotion = null;
                }
            },
            applyPromotionForInvoice(promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById: promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                };
                if (promotion.promotionFromId == this.$constant.promotion_invoice_discount) {
                    if (promotion.promotion_options.discountValue && !promotion.promotion_options.discountRatio) {
                        this.entry.promotion.discountValue = promotion.promotion_options.discountValue;
                    } else if (promotion.promotion_options.discountRatio) {
                        this.entry.promotion.discountRatio = promotion.promotion_options.discountRatio;
                    }
                } else if (promotion.promotionFromId == this.$constant.promotion_invoice_donate_product) {
                    this.setProductGift(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_invoice_discount_on_product) {
                    this.setProductDiscount(promotion);
                }
            },

            // Quân làm hàng hóa khuyến mại
            applyPromotionForProduct (promotion) {
                this.entry.promotion = {
                    id: promotion.id,
                    name: promotion.name,
                    promotionOptionId: promotion.promotion_options.id,
                    promotionOptionChildId: promotion.promotionOptionChildId ?? null,
                    promotionById: promotion.promotionById ?? null,
                    receiveProductUnit: promotion.receiveProductUnit,
                    clonePromotion: promotion,
                };
                if (promotion.promotionFromId == this.$constant.promotion_product_discount) {
                    this.setProductDiscount(promotion);
                } 
                else if (promotion.promotionFromId == this.$constant.promotion_product_donate_product) {
                    this.setProductGift(promotion);
                } else if (promotion.promotionFromId == this.$constant.promotion_product_allow_quantity) {
                    this.entry.products.forEach(product => {
                        if (product.productUnitId == promotion.productUnitId) {
                            let originPrice = !product.originPrice ? product.price : product.originPrice; 
                            product.originPrice = originPrice;
                            if (promotion.promotion_options.whenBuying.discountBy == 1) {
                                product.price = promotion.promotion_options.whenBuying.discountValue;
                            }
                            else {
                                if (promotion.promotion_options.whenBuying.discountValue && !promotion.promotion_options.whenBuying.discountRatio) {
                                    product.price = product.originPrice - promotion.promotion_options.whenBuying.discountValue;
                                } 
                                if (promotion.promotion_options.whenBuying.discountRatio) {
                                    product.price = product.originPrice - (product.originPrice * (promotion.promotion_options.whenBuying.discountRatio/100));
                                }
                            }
                        }
                    });
                }
            },
            removeCoupon() {
                this.entry.couponCode = null;
                this.entry.isShowCodeActive = false;
                this.entry.valueCoupon = 0;
                this.entry.couponDetailId = null;
            },
            removeVoucher(index, value, id) {
                this.entry.infoVouchers.splice(index, 1);
                this.entry.valueVoucher = +this.entry.valueVoucher - +value;
                var i = this.entry.voucherDetailIds.indexOf(id);
                if (i !== -1) {
                    this.entry.voucherDetailIds.splice(i, 1);
                }
            },
            removeAllVoucher(){
                this.entry.infoVouchers = [];
                this.entry.valueVoucher = 0;
                this.entry.voucherDetailIds = [];
            },
            async getVoucher() {
                if (!this.entry.products || !this.entry.products.length) {
                    $alert({code: 500, message: 'Đơn hàng trống .'});
                    return;
                }
                const res = await $post('/v1/vouchers/get-voucher-active', {
                    totalPrice: this.entry.totalPrice,
                    voucherCode: this.entry.voucherCode,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    branchId: store.state.CurrentBranch.id,
                    userId: this.entry.soldById,
                    customerGroupId: this.groupIds,
                });
                this.entry.voucherCode = null;
                if (res.result && !res.result.status) {
                    $alert({code: 500, message: 'Mã voucher không tồn tại hoặc không đủ điều kiện để áp dụng.'});
                    return;
                }
                if (!Array.isArray(this.entry.infoVouchers)) {
                    this.entry.infoVouchers = [];
                }
                if (!Array.isArray(this.entry.voucherDetailIds)) {
                    this.entry.voucherDetailIds = [];
                }
                if (this.entry.voucherDetailIds.includes(res.result.voucherActive.id)) {
                    $alert({code: 500, message: 'Voucher đã được sử dụng'});
                    return
                }
                
                if (!res.result.voucherActive.coupon_voucher.applyMultipleVoucher) {
                    for (let index = 0; index < this.entry.infoVouchers.length; index++) {
                        const info = this.entry.infoVouchers[index];
                        if (info.voucherId == res.result.voucherActive.coupon_voucher.id) {
                            $alert({code: 500, message: 'Không áp dụng nhiều mã voucher trong cùng 1 đợt phát hành.'});
                            return;
                        }
                    }
                }
                this.entry.infoVouchers.push({
                    code: res.result.voucherActive.code,
                    value: +res.result.voucherActive.coupon_voucher.value,
                    id: res.result.voucherActive.id,
                    voucherId: res.result.voucherActive.coupon_voucher.id,
                    applyMultipleVoucher: res.result.voucherActive.coupon_voucher.applyMultipleVoucher,
                });
                this.entry.valueVoucher = (this.entry.valueVoucher ?? 0) + +res.result.voucherActive.coupon_voucher.value;
                this.entry.voucherDetailIds.push(res.result.voucherActive.id);
            },
            async getCoupon() {
                if (!this.entry.products || !this.entry.products.length) {
                    $alert({code: 500, message: 'Đơn hàng trống .'});
                    return;
                }
                const res = await $post('/v1/coupons/get-coupon-active', {
                    totalPrice: this.entry.totalPrice,
                    couponCode: this.entry.couponCode,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                });
                if (res.result && !res.result.status) {
                    $alert({code: 500, message: 'Mã coupon không tồn tại hoặc không đủ điều kiện để áp dụng.'});
                    return;
                }
                this.setEntry(res);
            },
            async checkCouponActive() {
                if (!this.entry.couponDetailId) return;
                if (this.entry.type == TYPE_RETURN) return;
                const res = await $post('/v1/coupons/get-coupon-active', {
                    totalPrice: this.entry.totalPrice,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    couponId: this.entry.couponDetailId,
                });
                if (res.result && !res.result.status) {
                    await this.removeCoupon();
                    return;
                }
                this.setEntry(res);
            },
            async checkVoucherActive () {
                if (this.entry.type == TYPE_RETURN) return;
                if (!this.entry.valueVoucher) {
                    return;
                }
                const res = await $post('/v1/vouchers/get-voucher-active', {
                    totalPrice: this.entry.totalPrice,
                    productUnitIds: this.productUnitIds,
                    categoriesIds: this.categoriesIds,
                    orderId: this.entry.orderId,
                    invoiceId: this.entry.invoiceId,
                    voucherDetailIds: this.entry.voucherDetailIds,
                    branchId: store.state.CurrentBranch.id,
                });
                this.entry.infoVouchers = [];
                this.entry.valueVoucher = 0;
                this.entry.voucherDetailIds = [];
                if (res.result.status) {
                    if (!Array.isArray(this.entry.infoVouchers)) {
                        this.entry.infoVouchers = [];
                    }
                    if (!Array.isArray(this.entry.voucherDetailIds)) {
                        this.entry.voucherDetailIds = [];
                    }
                    res.result.voucherActive.forEach(element => {
                        this.entry.infoVouchers.push({
                            code: element.code,
                            value: +element.coupon_voucher.value,
                            id: element.id,
                            voucherId: element.coupon_voucher.id,
                            applyMultipleVoucher: element.coupon_voucher.applyMultipleVoucher,
                        });
                        this.entry.valueVoucher = (this.entry.valueVoucher ?? 0) + +element.coupon_voucher.value;
                        this.entry.voucherDetailIds.push(element.id);
                    });
                }
            },

            setEntry(res) {
                let valueCouponCovert = (+res.result.couponActive.coupon_voucher.value * +this.entry.totalPrice)/100;
                if ((res.result.couponActive.coupon_voucher.maxPrice != this.$constant.priceZero && res.result.couponActive.coupon_voucher.maxPrice)  && valueCouponCovert > +res.result.couponActive.coupon_voucher.maxPrice) {
                    this.entry.valueCoupon = +res.result.couponActive.coupon_voucher.maxPrice;
                } else {
                    this.entry.valueCoupon = valueCouponCovert;
                }
                this.entry.isShowCodeActive = true;
                this.entry.couponDetailId = res.result.couponActive.id;
                this.entry.couponCode = res.result.couponActive.code;
            },
            calculateDebtTerm() {
                this.entry.debtTerm = moment().add(this.entry.period, 'days');
            },
            removeProductOrderPlus(productId, key) {
                const index = this.entry.products.findIndex(el => (el.id == productId));
                if (index != -1) {
                    this.entry.products[index].plusOrder.splice(key, 1);
                    localStorage.setItem('details', this.entry.products);
                }
            },
            cloneProduct(id) {
                this.entry.products.forEach(product => {
                    if (product.id == id) {
                        if (!product.plusOrder || !product.plusOrder.length) {
                            product.plusOrder = [];
                        }
                        let oldCombo = null;
                        if (product.combo_products) {
                            oldCombo = clone(product.combo_products);
                            oldCombo.forEach(element => {
                                if(element.oldQuantity) {
                                    element.quantity = element.oldQuantity
                                }
                            });
                        }
                        let plusOrder = {
                            key: product.plusOrder.length,
                            parentId: product.id,
                            invoiceDetailId: product.invoiceDetailId ?? null,
                            productUnitId: product.productUnitId,
                            productUnitText: (product.product_units.find(el => (el.id == product.productUnitId))).unitName,
                            quantity: 1,
                            discount: null,
                            price: product.price,
                            discountType: null,
                            discountValue: null,
                            amount: product.price,
                            consignments: product.consignments,
                            combo_products: oldCombo ? oldCombo : product.combo_products ,
                        };
                        
                        if (product.isConsignment) {
                            if (!product.consignments || !product.consignments.length) {
                                return;
                            }
                            plusOrder.consignmentActive = product.consignmentActive;
                            let consignmentProductBranchMeta = product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                            let newConsignment = {
                                consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                                consignmentId: consignmentProductBranchMeta.consignmentId,
                                quantity: 1,
                                name: product.consignments[0].name,
                                expried: product.consignments[0].expried,
                                key: 1,
                                productUnitId: product.productUnitId
                            };
                            plusOrder.consignmentActive.push(newConsignment);
                        }                        
                        product.plusOrder.unshift(plusOrder);
                    }
                });
            },
            updateQuantity(quantity, productId) {
                this.entry.products.forEach(product => {
                    if (product.id == productId) {
                        product.quantity = quantity;
                    }
                });
            },
            showDetailsProduct(product) {
                this.$refs.modalDetailProduct.show(product);
            },
            setValue() {
                this.entry.created_at = moment().format('DD/MM/YYYY HH:mm');
                
                if (!this.entry.priceListId) {
                    this.entry.priceListId = this.$constant.priceListIdCommon;
                }
                emitter.emit('clearSelectSearchValue');
                this.paymentChannel = [];
                if (+this.entry.cash) {
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                } 
                if (+this.entry.card) {
                    this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                } 
                if (+this.entry.transfer) {
                    this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                } 
                if (+this.entry.voucher) {
                    this.paymentChannel.push(this.$constant.paymentChannelVoucher);
                }
                if (!this.paymentChannel.length) {
                    this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                }
                if (this.entry.type != TYPE_RETURN) {
                    this.entry.couponCode = null;
                    this.entry.voucherCode = null;
                }
            },
            async getPriceProductForPiceList() {
                const res = await $post('/v1/orders/get-price-for-price-list-id', {
                    productIds: this.productIds,
                    priceListId: this.entry.priceListId,
                });
                const resultFormat = res.result;
                this.entry.products?.forEach(product => {
                    let item = resultFormat.find(el => (el.productId == product.id && el.productUnitId == product.productUnitId));
                    if (item) {
                        product.price = item.price;
                    }else{    
                        product.product_units.forEach((pu) => {        
                            // thiết lập giá cho hàng hóa bằng giá đơn vị hàng hóa hiện tại được chọn
                            if(pu.id == product.productUnitId){
                                product.price = pu.price;
                            }
                            
                        });
                    }
                });
            },
            async getUserActive() {                
                const res = await $get('/v1/orders/get-user-active-price-list', {
                    priceListId: this.entry.priceListId,
                });
                this.branchAdmins = res.result;              
                for (let index = 0; index < this.branchAdmins.length; index++) {
                    const user = this.branchAdmins[index];
                    if (user.id == this.entry.soldById) {
                        return;
                    }
                }                
            },
            async getUserActive1() {                             
                const res = await $get('/v1/orders/get-user-active-price-list', {
                    priceListId: this.entry.priceListId,
                });
                this.branchAdmins = res.result;  
                this.entry.soldById = this.entry.invoice.soldById?? null;
            },
            async getCustomers() {
                const res = await $get('/v1/orders/get-customer', {
                    priceListId: this.entry.priceListId,
                });
                if (res.status.code != 200) {
                    $alert({code: 500, message: 'lỗi server'});
                    return;
                } 
                this.customers = res.result;
                for (let index = 0; index < this.customers.length; index++) {
                    const customer = this.customers[index];
                    if (customer.id == this.entry.customerId) {
                        return;
                    }
                }
                this.entry.customerId = null;
            },
            async getPriceList() {
                const res = await $get('/v1/orders/get-price-lists');
                if (res.status.code != 200) {
                    $alert({code: 500, message: 'lỗi server'});
                    return;
                } 
                this.priceLists = res.result;
                this.priceLists.unshift(this.$constant.priceListCommon);
            },
            async getSaleChannels(){
                const res = await $post('/v1/configs/index', {} , false);
                if (res.code === 200) {
                    this.saleChannels = store.state.GlobalConfig.saleChannels = res.data.saleChannels;
                }
            },

            selectPriceList(v) {
                this.entry.priceListId = v.model;
            },
            selectSaleChannel(v) {
                this.entry.channelId = v.model;
            },
            selectSoldBy(v) {
                this.entry.soldById = v.model;
            },
            selectCustomer(v) {                
                this.entry.customerId = v.model;
                this.disablePromotionInvoiceIds = [];
                this.disablePromotionProductIds = [];
            },
            updateDiscount(value){
                if (value.dEntry && value.dEntry.parentId) {
                    this.entry.products.forEach(product => {    
                        if (product.id == value.dEntry.parentId) {
                            product.plusOrder.forEach(item => {
                                if(item.key == value.dEntry.key) {
                                    item.discount = value.model.discountValue;
                                    item.valuePercent = value.model.valuePercent;
                                    item.discountType = value.model.discountType;
                                }
                            });
                        }
                    })
                }else if(value.dEntry.products){
                    this.entry.discountValue = value.model.discountValue;
                    this.entry.valuePercent = value.model.valuePercent;
                    this.entry.discountType = value.model.discountType;
                }else{
                    if (this.entry && this.entry.products) {
                        this.entry.products.forEach(product => {
                            if(product.id === value.dEntry?.id) {
                                product.discount = value.model.discountValue;
                                product.valuePercent = value.model.valuePercent;
                                product.discountType = value.model.discountType;
                            }
                        })
                    }
                }
            },
            updateProductUnit(product, productParent = null, hasVariant = false) {
                if(productParent && productParent.product_units && productParent.product_units.length){
                    product.productUnit = productParent.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = DISCOUNT_TYPE_VND;
                    product.discountValue = 0;
                    if (product.baseProductUnitId && product.basePrice && product.productUnitId == product.baseProductUnitId) {
                        product.price = product.basePrice;
                    }else{
                        product.price = product.productUnit && product.productUnit.price ? product.productUnit.price : null;
                    }
                }else if (product.product_units && product.product_units.length) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = DISCOUNT_TYPE_VND;
                    product.discountValue = 0;
                    // product.quantityRemaining = product.quantityRemaining * product.productUnit.conversionValue;
                    this.getPriceProductForPiceList();
                }

                if(hasVariant){
                    this.getListAttributeForUnit(product);
                }
            },
            async getListAttributeForUnit(product, formatData = false){
                const result = await $post('/v1/products/get-list-attributes-for-unit', {
                    productUnitId: product.productUnitId,
                    branchId: store.state.CurrentBranch.id,
                });
                product.variants = result && result.result ? result.result : [];
                product.variantId = !formatData ? null : product.variantId;
            },

            async onOrderProcessing() {
                const tabData = cloneObject(this.entry);
                tabData.branchId = store.state.CurrentBranch.id;
                if (!this.entry.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmPayment'))) {
                        return false;
                    }
                    if (this.entry.type == this.$constant.typeInvoice && this.entry.customerPayment < this.entry.totalPriceAfterDiscount) {
                        toastr.error('Tiền thanh toán không đủ');
                        return;
                    }
                }
                if (!this.entry.products || this.entry.products?.length <= 0){
                    toastr.error('Phiếu hóa đơn đang trống');
                    return;
                } else {
                    let confirm = true;
                    this.entry.products.forEach(el => {
                        if(el.quantityOrigin && el.quantityOrigin < el.quantity){
                            toastr.error('Số lượng hàng hóa ' + el.name + ' không được lớn hơn số lượng đặt');
                            confirm = false;
                            return;
                        }
                        if(el.plusOrder && el.plusOrder.length){
                            el.plusOrder.forEach(el1 => {
                                if(el1.quantityOrigin && el1.quantityOrigin < el1.quantity){
                                    toastr.error('Số lượng hàng hóa ' + el1.name + ' không được lớn hơn số lượng đặt');
                                    confirm = false;
                                    return;
                                }
                            });
                        }
                    });
                    if(confirm){
                        let form = this.formatConsignmentActive();
                        form.code = null;
                        form.isLink = true;
                        this.entry.isLink = true;
                        form.type = TYPE_INVOICE;
                        const res = await $post('/v1/orders/create-invoice', {...form});
                        if (res.code === 422) {
                            const errors = res.messages.errors;
                            Object.keys(errors).forEach(function(key) {
                                $alert({code: res.code, message: errors[key][0]});
                            });
                        } else if (res.status.code == 200 && res.result.status) {
                            toastr.success(this.$t('message.common.addInvoiceSuccess'));
                            this.$emit('removeTab');
                            this.$router.push({name: 'InvoiceIndex'});
                        } else {
                            $alert({code: 500, message: this.$t('message.common.errorServer')});
                        }
                        this.entry.isLink =  false;
                    }
                }               
            },
            async save() {                                  
                this.entry.branchId = store.state.CurrentBranch.id;                
                if(this.isTaxRate == true) this.entry.isTaxRate = 1;                
                if(this.isHsCode == true) this.entry.isHsCode = 1;
                if (!this.entry.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmPayment'))) {
                        return false;
                    }
                    if (this.entry.type == this.$constant.typeInvoice && this.entry.customerPayment < this.entry.totalPriceAfterDiscount) {
                        toastr.error('Tiền thanh toán không đủ');
                        return;
                    }
                }
                if(!this.entry.products || this.entry.products?.length <= 0){
                    let message = this.entry.type == TYPE_RETURN ? this.$t('message.common.emptyReturn') : (this.entry.type == TYPE_INVOICE ? 'Phiếu hóa đơn đang trống' : 'Phiếu hàng đang trống');
                    toastr.error(message);
                    return;
                }
                let res;
                if (this.entry.type == this.$constant.typeOrder) {
                    if (!this.entry.isUpdated) {
                        res = await $post('/v1/orders/create', {
                            ...this.entry
                        });
                    } else {
                        res = await $post('/v1/orders/update', {
                            ...this.entry
                        });
                    }
                } else if (this.entry.type == this.$constant.typeInvoice) {
                    let form = this.formatConsignmentActive();
                    if (!this.entry.isUpdated) {
                        res = await $post('/v1/orders/create-invoice', {
                            ...form
                        });
                    } else {
                        res = await $post('/v1/orders/update-invoice', {
                            ...form
                        });
                    }
                }
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                } else if (res.status.code == 200 && res.result.status) {
                    if (this.entry.type == this.$constant.typeOrder) {
                        if (!this.entry.isUpdated) {
                            toastr.success(this.$t('message.common.addOrderSuccess'));
                        } else {
                            toastr.success(this.$t('message.common.updateOrderSuccess'));
                        }
                        this.$emit('removeTab');
                        this.$router.push({name: 'OrderIndex'});
                    } else if (this.entry.type == this.$constant.typeInvoice) {
                        if (!this.entry.isUpdated) {
                            toastr.success(this.$t('message.common.addInvoiceSuccess'));
                        } else {
                            toastr.success(this.$t('message.common.updateInvoiceSuccess'));
                        }
                        this.$emit('removeTab');
                        this.$router.push({name: 'InvoiceIndex', query: {print_id: res.result.id}});
                    }
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            formatConsignmentActive() {
                let form = JSON.parse(JSON.stringify(this.entry));
                form.products.forEach(product => {
                    let consignments = [];
                    if (product.isConsignment && product.consignmentActive && product.consignmentActive.length) {
                        product.consignmentActive.forEach(consignment => {
                            let index = consignments.findIndex(item => (item.consignmentId == consignment.consignmentId && item.productUnitId == consignment.productUnitId));
                            if (index > -1) {
                                consignments[index].quantity = consignments[index].quantity + consignment.quantity;
                            } else {
                                consignments.push(JSON.parse(JSON.stringify(consignment)));
                            }
                        });

                        if (product.plusOrder && product.plusOrder.length) {
                            product.plusOrder.forEach(plus => {
                                plus.consignmentActive.forEach(consignment => {
                                    let index = consignments.findIndex(item => (item.consignmentId == consignment.consignmentId && item.productUnitId == consignment.productUnitId));
                                    if (index > -1) {
                                        consignments[index].quantity = consignments[index].quantity + consignment.quantity;
                                    } else {
                                        consignments.push(JSON.parse(JSON.stringify(consignment)));
                                    }
                                });
                            });
                        }
                        product.consignmentActiveClone = consignments;
                    }
                });
                return form;
            },
            async saveReturn() {
                if(this.isTaxRate == true) this.entry.isTaxRate = 1;                
                if(this.isHsCode == true) this.entry.isHsCode = 1;                
                const data = cloneObject(this.entry);                
                let canSave = false;
                if (!data.customerId) {
                    if (!window.confirm(this.$t('message.common.confirmReturn'))) {
                        return false;
                    }
                }
                if(!this.entry.products || this.entry.products?.length <= 0){
                    toastr.error(this.$t('message.common.emptyReturn'));
                    return;
                }else{
                    this.entry.products.forEach(product => {
                        if(product.quantity > 0){
                            canSave = true;
                        }
                        if (product.plusOrder && product.plusOrder.length) {
                            product.plusOrder.forEach(item => {
                                if(item.quantity > 0){
                                    canSave = true;
                                }
                            });
                        }
                    })
                }
                if(!canSave){
                    toastr.error(this.$t('message.common.quantityReturnGreaterThan0'));
                    return;
                }
                const res = await $post('/v1/returns/save', data);
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                } else if (res.status.code == 200 && res.result.status) {
                    if (!this.entry.isUpdated) {
                        toastr.success(this.$t('message.common.addReturnSuccess'));
                    } else {
                        toastr.success(this.$t('message.common.updateReturnPOSuccess'));
                    }
                    this.$emit('removeTab');
                    this.$router.push({name: 'ReturnIndex'});
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            removeProduct(index) {
                this.entry.products.splice(index, 1);
                if(this.entry.products?.length == 0){
                    this.removeAllVoucher();
                    this.removeCoupon();
                    this.entry.promotion = null;
                    this.entry.productDiscount = [];
                    this.entry.productGifts = [];
                    this.entry.promotionsInvoice = [];
                    this.entry.promotionsProducts = [];
                }
                localStorage.setItem('details', this.entry.products);
            },
            onSaveProductBatch(data) {
                const product = this.entry.products.find(p => (p.id == data.productId));
                const quantityOrigin = product.productUnit ? product.productUnit.quantityOrigin : 0;

                if (product) {
                    if (data.quantity > quantityOrigin) {
                        product.quantity = quantityOrigin;
                        toastr.error(this.$t('message.common.notEnoughInventorySystem'));
                    } else {
                        product.product_batches = data.product_batches;
                        product.quantity = data.quantity;
                    }
                }
            },
            onCreateCustomer() {
                this.$refs.ModalAddInfoCustomer.show();
            },
            onCreateSaleChannel() {
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            createdCustomer(customer) {
                this.entry.customerId = customer.id;
                this.onChangeCustomer();
            },
            onShowCustomer() {
                this.$refs.modalAddInfoCustomer.show(this.entry.customerId);
            },
            clearCustomer() {
                this.entry.customerId = null;
            },
            onSavePayment(cashFlows, totalPayment) {
                this.entry.cash_flows = cashFlows;
                this.entry.totalPayment = totalPayment;
            },
            async onChangeCustomer() {
                let customer = null;
                let address = null;

                if (this.entry.customerId) {
                    const res = await $get('/v1/sale/getCustomer', {customer_id: this.entry.customerId});
                    if (res.code === 200) {
                        customer = res.data;
                        address = res.address;
                        this.entry.customer = customer;
                    }
                }

                const userInfo = {};
                userInfo.receiverName = customer ? customer.name : null;
                userInfo.receiverPhone = customer ? customer.contactNumber : null;
                userInfo.receiverAddress = address ? address.addressValue : null;
                userInfo.receiverProvinceId = address ? address.provinceId : null;
                userInfo.receiverDistrictId = address ? address.districtId : null;
                userInfo.receiverWardId = address ? address.wardId : null;

                if (typeof this.entry.delivery === 'object') {
                    this.entry.delivery = {...this.entry.delivery, ...userInfo};
                } else {
                    this.entry.delivery = userInfo;
                }
            },
            getDataCustomer(customer) {
                this.groupIds = [];
                if (customer) {
                    customer.customer_group_details.forEach(detail => {
                        this.groupIds.push(detail.customerGroupId);
                    });
                }
            },
            async getPromotion() {
                let maxQuantity = 0;
                if(!Array.isArray(this.quantityOrder)) {
                    this.quantityOrder = [];
                }
               for (let index = 0; index < this.quantityOrder.length; index++) {
                    const element = this.quantityOrder[index];
                    if (element.maxQuantity > maxQuantity) {
                        maxQuantity = element.maxQuantity;
                    }
               }
               var max = 0 
               this.quantityOrder.forEach(element => {
                    if (element.categoryIds && element.categoryIds.length) {
                        max += parseInt(element.maxQuantity)
                    }
               });
               
               this.totalQuantityMax = max;

                const res = await $post('/v1/promotions/get-promotion-active', {
                    branchId: store.state.CurrentBranch.id,
                    totalPrice: this.entry.totalPrice,
                    categoryIds: this.categoriesIds,
                    productUnitId: this.productUnitIds,
                    userId: this.entry.soldById,
                    customerId: this.entry.customerId,
                    groupId: this.groupIds,
                    maxQuantity: maxQuantity,
                    productUnits: this.quantityOrder,
                    categories: this.categories,
                    currentPromotionId: this.entry.promotionId,
                    totalQuantityMax: this.totalQuantityMax
                });
                this.entry.promotionsInvoice = [];
                this.entry.promotionsProducts = [];
                if(res.result) {
                    this.entry.promotionsInvoice = res.result.promotionsInvoice;
                    this.entry.promotionsProducts = res.result.promotionsProducts;
                }
                if(this.disablePromotionInvoiceIds){
                    this.disablePromotionInvoiceIds.forEach(id => {
                        let index = this.entry.promotionsInvoice.findIndex(v => v.id == id);
                        if(index > -1){
                            this.entry.promotionsInvoice.splice(index, 1)
                        }
                    });
                }
                if(this.disablePromotionProductIds){
                    this.disablePromotionProductIds.forEach(id => {
                        let index = this.entry.promotionsProducts.findIndex(v => v.id == id);
                        if(index > -1){
                            this.entry.promotionsProducts.splice(index, 1)
                        }
                    });
                }
                if(!this.entry.customerId && !this.entry.soldById && !this.entry.promotionsInvoice.length && !this.entry.promotionsProducts.length){
                    this.clearPromotion();
                }
                if (!this.entry.promotion) {
                    return;
                }
                this.clearCurrentPromotion();
            },
            clearCurrentPromotion(){
                let statusDeletePromotionInvoice = true;
                for (let index = 0; index < this.entry.promotionsInvoice.length; index++) {
                    const promotion = this.entry.promotionsInvoice[index];
                    if (this.entry.promotion?.id == promotion.id) {
                        for (let index = 0; index < promotion.promotion_options.length; index++) {
                            const option = promotion.promotion_options[index];
                            if (option.id == this.entry.promotion.promotionOptionId) {
                                statusDeletePromotionInvoice = false;
                                break;
                            }
                        }
                    }
                    if (!statusDeletePromotionInvoice) {
                        break;
                    }
                }

                let statusDeletePromotionProduct = true;
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    if (this.entry.promotion?.id == promotion.id) {
                        for (let index = 0; index < promotion.promotion_options.length; index++) {
                            const option = promotion.promotion_options[index];
                            if (option.whenBuying) {
                                const buying = JSON.parse(option.whenBuying);
                                if (buying.length) {
                                    for (let ind = 0; ind < buying.length; ind++) {
                                        if ((option.id + '_' + ind) == this.entry.promotion.promotionOptionId) {
                                            statusDeletePromotionProduct = false;
                                            break;
                                        }else if(option.id == this.entry.promotion.promotionOptionId && this.entry.promotion.promotionOptionChildId && buying[ind].id && this.entry.promotion.promotionOptionChildId == buying[ind].id){
                                            this.entry.promotion.promotionOptionId = (option.id + '_' + ind);
                                            statusDeletePromotionProduct = false;
                                            break;
                                        }
                                        if (!statusDeletePromotionProduct) {
                                            break;
                                        }
                                    }
                                }
                            }
                            else if(option.id == this.entry.promotion.promotionOptionId) {
                                statusDeletePromotionProduct = false;
                                break;
                            }
                        }
                    }
                    if (!statusDeletePromotionProduct) {
                        break;
                    }
                }
                if (statusDeletePromotionInvoice && statusDeletePromotionProduct) {
                    if(!this.entry.isFirst){
                        this.clearPromotion();
                    }else{
                        this.entry.isFirst = false;
                    }
                }
            },
            showPromotionList(promotions) {
                let promotionOptionId = this.entry.promotion ? this.entry.promotion.promotionOptionId : null;
                let noCheckExistPromotion = true;
                if(this.entry.promotion){
                    noCheckExistPromotion = false;
                    promotions.forEach(promotion => {
                        if(promotion.promotionById == this.entry.promotion.promotionById){
                            noCheckExistPromotion = true;
                        }
                    })
                }
                this.$refs.modalPromotionInvoiceList.show(promotions, promotionOptionId, noCheckExistPromotion);
            },
            clearPromotion() {
                this.entry.promotion = null;
                this.entry.productDiscount = [];
                this.entry.productGifts = [];
            },
            promotionWarning(value){
                switch (value) {
                    case this.$constant.confirmPromotionWarning:
                        if(!this.isChangeCustomer){
                            this.showPromotionListInvoice();
                        }
                        this.isChangeCustomer = false;
                        break;
                    case this.$constant.cancelPromotionWarning:
                        if(this.disablePromotionInvoiceIds){
                            this.disablePromotionInvoiceIds.forEach(id => {
                                let index = this.entry.promotionsInvoice.findIndex(v => v.id == id);
                                if(index > -1){
                                    this.entry.promotionsInvoice.splice(index, 1)
                                }
                            });
                        }
                        if(this.disablePromotionProductIds){
                            this.disablePromotionProductIds.forEach(id => {
                                let index = this.entry.promotionsProducts.findIndex(v => v.id == id);
                                if(index > -1){
                                    this.entry.promotionsProducts.splice(index, 1)
                                }
                            });
                        }
                        if(!this.isChangeCustomer){
                            this.showPromotionListInvoice();
                        }
                        this.isChangeCustomer = false;
                        this.clearCurrentPromotion();
                        break;
                }
            },
            showPromotionListInvoice(){
                let promotions = [];
                for (let index = 0; index < this.entry.promotionsInvoice.length; index++) {
                    const promotion = this.entry.promotionsInvoice[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const option = promotion.promotion_options[index];
                        var newItem = {
                            id: promotion.id,
                            code: promotion.code,
                            name: promotion.name,
                            promotionFromId: promotion.promotionFromId,
                            promotionById: promotion.promotionById,
                            promotion_from: promotion.promotion_from,
                            promotionOptionId: option.id,
                            promotion_options: {
                                id: option.id,
                                promotionFromId: option.promotionFromId,
                                promotionId: option.promotionId,
                                totalAmount: option.totalAmount,
                                totalGift: option.totalGift ?? null,
                                totalBuyQuantity: option.totalBuyQuantity ?? null,
                                totalVoucher: option.totalVoucher ?? null,
                                discountValue: option.discountValue ?? null,
                                discountRatio: option.discountRatio ?? null,
                                totalQuantityDiscount: option.totalQuantityDiscount ?? null,
                                whenBuying: option.whenBuying ?? null,
                                attachQuantity: option.attachQuantity ?? null,
                                receive_categories: option.receive_categories ?? null,
                                receive_products: option.receive_products ?? null,
                                receiveProductUnitIds: option.receiveProductUnitIds ?? null,
                                receiveCategoriesIds: option.receiveCategoriesIds ?? null,
                                isFullCategories: option.isFullCategories,
                            }
                        };
                        if (this.entry.promotion && this.entry.promotion.id == promotion.id && option.id == this.entry.promotion.promotionOptionId) {
                            newItem.receiveProductUnit = this.entry.promotion.receiveProductUnit;
                        }
                        promotions.push(newItem);
                    }
                }
                this.showPromotionList(promotions);
            },
            checkPromotionActive(productUnitId, infoCategory, productQuantity) {
                let categoresId = [];
                infoCategory.forEach(category => {
                    categoresId.push(category.categoryId);
                });
                let status = false;
                let statusQuantity = false;
                let statusQuantityWhenBuying = false;
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];

                        if (element.totalBuyQuantity && element.totalBuyQuantity <= productQuantity) {
                            statusQuantity = true;
                        }
                        if (element.totalBuyQuantity && element.totalBuyQuantity <= this.totalQuantityMax) {
                            statusQuantity = true;
                        }
                        if (element.productUnitId && element.productUnitId.length && element.productUnitId.includes(productUnitId)) {
                            status = true;
                        }
                        if (element.isFullCategoriesCondition || categoresId.length) {
                            status = true;
                        }
                        if (element.categoriesIds && element.categoriesIds.length && element.categoriesIds.some(r=> categoresId.indexOf(r) >= 0)) {
                            status = true;
                        } 
                    }

                    
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];
                        if (element.whenBuying) {
                            const buying = JSON.parse(element.whenBuying);
                            buying.forEach(item => {
                                if(element.isFullCategoriesCondition){
                                    if(item.quantity <= this.totalQuantityMax){
                                        statusQuantityWhenBuying = true;
                                    }
                                }else{
                                    if(statusQuantityWhenBuying != true){
                                        statusQuantityWhenBuying = this.checkConditionPromotion(element, categoresId, productQuantity, item.quantity, productUnitId);
                                    }
                                }
                            });
                        }
                    }

                } 
                return ((status && statusQuantity) || statusQuantityWhenBuying); 
                
            },
            showPromotionProduct(productUnitId, infoCategory, quantityOrder) {
                let categoresId = [];
                let promotions = [];
                let product = this.entry.products.find(product => product.productUnitId == productUnitId);
                if(product){
                    quantityOrder = 0;
                    quantityOrder += product.quantity;
                    if(product.plusOrder && product.plusOrder.length){
                        product.plusOrder.forEach(element => {
                            quantityOrder += element.quantity;
                        });
                    }
                }
                infoCategory.forEach(category => {
                    categoresId.push(category.categoryId);
                });
                for (let index = 0; index < this.entry.promotionsProducts.length; index++) {
                    const promotion = this.entry.promotionsProducts[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const element = promotion.promotion_options[index];
                        if (element.productUnitId.includes(productUnitId)) {
                            promotions.push(promotion);
                            break;
                        }
                        if (element.isFullCategoriesCondition || categoresId.length) {
                            promotions.push(promotion);
                            break;
                        }  
                        if (element.categoriesIds && element.categoriesIds.length && element.categoriesIds.some(r=> categoresId.indexOf(r) >= 0)) {
                            promotions.push(promotion);
                            break;
                        }      
                    }
                }
                let newPromotionOptions = [];
                var newItem = {};
                for (let index = 0; index < promotions.length; index++) {
                    const promotion = promotions[index];
                    for (let index = 0; index < promotion.promotion_options.length; index++) {
                        const option = promotion.promotion_options[index];
                        if (option.whenBuying) {
                            const buying = JSON.parse(option.whenBuying);
                            if (buying.length) {
                                buying.forEach((element, ind) => {
                                    let append = false;
                                    if(option.isFullCategoriesCondition){
                                        if(element.quantity <= this.totalQuantityMax){
                                            append = true;
                                        }
                                    }else{
                                        append = this.checkConditionPromotion(option, categoresId, quantityOrder, element.quantity, productUnitId);
                                    }
                                    if(append){
                                        newItem = {
                                            id: promotion.id,
                                            code: promotion.code,
                                            name: promotion.name,
                                            productUnitId: productUnitId,
                                            quantityOrder: quantityOrder,
                                            duplicateProduct: promotion.duplicateProduct,
                                            promotionFromId: promotion.promotionFromId,
                                            promotionById: promotion.promotionById,
                                            promotion_from: promotion.promotion_from,
                                            promotionOptionId: option.id + '_' + ind,
                                            promotionOptionChildId: element.id,
                                            promotion_options: {
                                                id: option.id + '_' + ind,
                                                promotionFromId: option.promotionFromId,
                                                promotionId: option.promotionId,
                                                totalAmount: option.totalAmount,
                                                totalGift: option.totalGift,
                                                totalBuyQuantity: option.totalBuyQuantity,
                                                totalVoucher: option.totalVoucher,
                                                discountValue: option.discountValue,
                                                discountRatio: option.discountRatio,
                                                isFullCategories: option.isFullCategories,
                                                totalQuantityDiscount: option.totalQuantityDiscount,
                                                whenBuying: element,
                                                attachQuantity: option.attachQuantity,
                                                receive_categories: option.receive_categories,
                                                receive_products: option.receive_products,
                                                receiveProductUnitIds: option.receiveProductUnitIds,
                                                receiveCategoriesIds: option.receiveCategoriesIds,
                                            }
                                        };
                                        newPromotionOptions.push(newItem);
                                    }
                                });
                            }
                        }
                        if (!option.whenBuying) {
                                if (quantityOrder >= option.totalBuyQuantity || this.totalQuantityMax >= option.totalBuyQuantity) {
                                    newItem = {
                                        id: promotion.id,
                                        code: promotion.code,
                                        name: promotion.name,
                                        productUnitId: productUnitId,
                                        quantityOrder: quantityOrder,
                                        promotionFromId: promotion.promotionFromId,
                                        duplicateProduct: promotion.duplicateProduct,
                                        promotionById: promotion.promotionById,
                                        promotion_from: promotion.promotion_from,
                                        promotionOptionId: option.id,
                                        promotion_options: {
                                            id: option.id,
                                            promotionFromId: option.promotionFromId,
                                            promotionId: option.promotionId,
                                            totalAmount: option.totalAmount,
                                            totalGift: option.totalGift,
                                            totalBuyQuantity: option.totalBuyQuantity,
                                            totalVoucher: option.totalVoucher,
                                            discountValue: option.discountValue,
                                            isFullCategories: option.isFullCategories,
                                            discountRatio: option.discountRatio,
                                            totalQuantityDiscount: option.totalQuantityDiscount,
                                            whenBuying: '',
                                            attachQuantity: option.attachQuantity,
                                            receive_categories: option.receive_categories,
                                            receive_products: option.receive_products,
                                            receiveProductUnitIds: option.receiveProductUnitIds,
                                            receiveCategoriesIds: option.receiveCategoriesIds,
                                    }
                                }   
                                newPromotionOptions.push(newItem);
                            }
                        }
                        if (this.entry.promotion && this.entry.promotion.id == promotion.id && option.id == this.entry.promotion.promotionOptionId) {
                            newItem.receiveProductUnit = this.entry.promotion.receiveProductUnit;
                        }
                    }
                }
                this.showPromotionList(newPromotionOptions);
            },
            checkConditionPromotion(promotion, productCategories, quantityProduct, conditionQuantity, productUnitId){
                if(promotion.condition_categories && promotion.condition_categories?.length > 0 && promotion.condition_products && promotion.condition_products?.length > 0){
                    let append = false;
                    if(this.categories){
                        promotion.condition_categories.forEach(category => {
                            const result = this.categories.filter(cate => cate.id == category.categoryId);
                            let totalQuantityProduct = 0;
                            this.quantityOrder.forEach(prod => {
                                let isExistProduct = promotion.condition_products.filter(product => product.productUnitId == prod.id);
                                if(isExistProduct && isExistProduct?.length > 0){
                                    let isExistCategory = promotion.condition_categories.some(r=> prod.categoryIds.indexOf(r.categoryId) >= 0) ? true : false;
                                    if(!isExistCategory){
                                        totalQuantityProduct += prod.maxQuantity;
                                    }
                                }
                            })
                            if(result[0] && (result[0].maxQuantity + totalQuantityProduct >= conditionQuantity)){
                                append = true;
                            }
                        })
                    }
                    return append;

                }else if(promotion.condition_categories && promotion.condition_categories?.length > 0 && promotion.condition_products && promotion.condition_products?.length == 0){
                    let isExistCategory = promotion.condition_categories.some(r=> productCategories.indexOf(r.categoryId) >= 0) ? true : false;
                    if(!isExistCategory){
                        return false;
                    }else{
                        let status = false;
                        if(this.categories){
                            promotion.condition_categories.forEach(category => {
                                const result = this.categories.filter(cate => cate.id == category.categoryId);
                                if(result[0] && result[0].maxQuantity >= conditionQuantity){
                                    status = true;
                                }
                            })
                        }
                        return status;
                    }
                }else if(promotion.condition_categories && promotion.condition_categories?.length == 0 && promotion.condition_products && promotion.condition_products?.length > 0){
                    let isExistProduct = promotion.condition_products.filter(prod => prod.productUnitId == productUnitId);
                    if(isExistProduct && isExistProduct?.length > 0){
                        if(quantityProduct >= conditionQuantity){
                            return true;
                        }
                        return false;
                    }else{
                        return false;
                    }
                }
            },
            async checkPromotionForCustomer() {
                this.disablePromotionInvoiceIds = [];
                this.disablePromotionProductIds = [];
                await this.getPromotion();
                let promotionIds = [];
                this.entry.promotionsInvoice.forEach(promotion => {
                    promotionIds.push(promotion.id);
                });
                this.entry.promotionsProducts.forEach(promotion => {
                    promotionIds.push(promotion.id);
                });
                const res = await $post('/v1/promotions/check-promotion-for-customer', {
                    customerId: this.entry.customerId,
                    promotionIds: promotionIds,
                });
                if (res && res.result && res.result.length) {
                    let namePromotion = '';
                    let indexPromotionInvoices = [];
                    let indexPromotionProducts = [];
                    res.result.forEach(promotionId => {
                        let indexInvoice = this.entry.promotionsInvoice.findIndex(p => (p.id == promotionId));
                        let indInvoice = indexPromotionInvoices.findIndex(i => i == promotionId);
                        if (indexInvoice > -1 && indInvoice < 0) {
                            indexPromotionInvoices.push(this.entry.promotionsInvoice[indexInvoice].id);
                            namePromotion = (namePromotion ? (namePromotion + ', ') : '' ) + this.entry.promotionsInvoice[indexInvoice].name;
                        }
                        let indexProduct = this.entry.promotionsProducts.findIndex(p => (p.id == promotionId));
                        let indProduct = indexPromotionProducts.findIndex(p => p == promotionId);
                        if (indexProduct > -1 && indProduct < 0) {
                            indexPromotionProducts.push(this.entry.promotionsProducts[indexProduct].id);
                            namePromotion = (namePromotion ? (namePromotion + ', ') : '' ) + this.entry.promotionsProducts[indexProduct].name;
                        }
                    });
                    this.disablePromotionInvoiceIds = indexPromotionInvoices;
                    this.disablePromotionProductIds = indexPromotionProducts;
                    this.isChangeCustomer = true;
                    this.$refs.ModalPromotionWarning.show(namePromotion);
                    return;
                }else{
                    this.disablePromotionInvoiceIds = [];
                    this.disablePromotionProductIds = [];
                }
            },
            removeConsignment(productIndex, consignmentIndex) {
                this.entry.products[productIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            removeConsignmentProductPlus(productIndex, productPlusIndex, consignmentIndex) {
                this.entry.products[productIndex].plusOrder[productPlusIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            removeConsignmentProductGift(productIndex, consignmentIndex) {
                this.entry.productGifts[productIndex].consignmentActive.splice(consignmentIndex, 1);
                this.setMaxQuantityProductGift(productIndex, this.entry.productGifts[productIndex].maxQuantity);
            },
            removeConsignmentProductDiscount(productIndex, consignmentIndex){
                this.entry.productDiscount[productIndex].consignmentActive.splice(consignmentIndex, 1);
                this.setMaxQuantityProductDiscount(productIndex, this.entry.productDiscount[productIndex].maxQuantity);
            },
            countQuantityProduct(productIndex, consignmentIndex) {
                this.entry.products[productIndex].consignmentActive;
            },

            // Remove consigment of product in combo
            removeConsignmentProductInCombo(productComboIndex, productOfComboIndex, consigmentIndex) {
                this.entry.products[productComboIndex].combo_products[productOfComboIndex].product.consignmentActive.splice(consigmentIndex, 1)
            },
            removeConsignmentProductInComboPlus (productComboIndex, productPlusComboIndex, productOfComboPlusIndex, consignmentIndex) {                
                this.entry.products[productComboIndex].plusOrder[productPlusComboIndex].combo_products[productOfComboPlusIndex].product.consignmentActive.splice(consignmentIndex, 1)
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },

            // Xử lí task liên quan đến break đơn vị để trả hàng
            addProductPlusUnitReturn(productIndex, productOrigin) {
                let product = clone(productOrigin);
                let plusReturn = {
                    productUnitId: product.product_units[0]['id'],
                    productUnit: product.product_units[0],
                    quantity: 0,
                    price: product.product_units[0].price,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    isConsignment: product.isConsignment,
                    consignments: product.consignments,
                    consignmentActive: product.consignmentActive,
                }
                if (!this.entry.products[productIndex].plusReturn) {
                    this.entry.products[productIndex].plusReturn = [];
                }
                plusReturn.index = this.entry.products[productIndex].plusReturn.length;
                this.entry.products[productIndex].plusReturn.push(plusReturn);
            }, 

            removeReturnProductPlus(productIdex, plusIndex) {
                this.entry.products[productIdex].plusReturn.splice(plusIndex, 1);
            },

            updateUnitProductReturn (plusReturn) {
                if (plusReturn.product_units && plusReturn.product_units.length) {
                    plusReturn.productUnit = plusReturn.product_units.find(el => (el.id == plusReturn.productUnitId));
                    plusReturn.quantity = 0;
                    plusReturn.discountType = DISCOUNT_TYPE_VND;
                    plusReturn.discountValue = 0;
                    // plusReturn.quantityRemaining = plusReturn.quantityRemaining * plusReturn.productUnit.conversionValue;                    
                    this.getPriceProductForPiceListReturn();
                }
            },
            async getPriceProductForPiceListReturn() {
                const res = await $post('/v1/orders/get-price-for-price-list-id', {
                    productIds: this.productIds,
                    priceListId: this.entry.priceListId,
                });
                const resultFormat = res.result;
                this.entry.products?.forEach(product => {
                    let item = resultFormat.find(el => (el.productId == product.id && el.productUnitId == product.productUnitId));
                    if (item) {
                        product.price = item.price;
                    }else{    
                        product.product_units.forEach((pu) => {        
                            // thiết lập giá cho hàng hóa bằng giá đơn vị hàng hóa hiện tại được chọn
                            if(pu.id == product.productUnitId){
                                product.price = pu.price;
                            }
                            
                        });
                    }

                    if (product.plusReturn && product.plusReturn.length > 0) {
                        product.plusReturn.forEach(itemPls => {
                            itemPls.price = itemPls.productUnit.price;
                           
                        });
                    }
                });
            },
        },
        watch: {
            'entry.priceListId'(newValue) {
                this.$emit('SetPriceListId', newValue);
                this.getUserActive();
                this.getCustomers();
                this.getPriceProductForPiceList();
            },
            'entry.channelId'(newValue) {
                // this.$emit('setSaleChannelId', newValue);
            },
            'entry.soldById'(newValue) {
                // this.$emit('setSoldById', newValue);
                // this.getPromotion();
            },
            'entry.customerId'(newValue) {
                // this.$emit('setCustomerId', newValue);
                // this.checkPromotionForCustomer();
            },
            'entry.cash'(newValue) {
                // this.$emit('setCashMoney', newValue);
            },
            'entry.card'(newValue) {
                // this.$emit('setCardMoney', newValue);
            },
            'entry.transfer'(newValue) {
                // this.$emit('setTransferMoney', newValue);
            },
            'entry.voucher'(newValue) {
                // this.$emit('setVoucher', newValue);
            },
            'entry.debtTerm'(newValue) {
                // this.$emit('setDebtTerm', newValue);
            },
            paymentChannel: {
                handler(){
                    // if (!this.paymentChannel.includes(this.$constant.paymentChannelCashMoney)) {
                    //     this.entry.cash = 0;
                    // }
                    // if (!this.paymentChannel.includes(this.$constant.paymentChannelCardMoney)) {
                    //     this.entry.card = 0;
                    // }
                    // if (!this.paymentChannel.includes(this.$constant.paymentChannelTransferMoney)) {
                    //     this.entry.transfer = 0;
                    // }
                    // if (!this.paymentChannel.includes(this.$constant.paymentChannelVoucher)) {
                    //     this.entry.voucher = 0;
                    // }
                },
                deep: true
            },
            
            entry: {
                handler: function(dataNew) {                              
                    if (!dataNew) return;
                    let totalPrice = 0;
                    let totalBasePrice = 0;
                    let totalQuantity = 0;
                    this.productIds = [];
                    this.productUnitIds = [];
                    this.categoriesIds = [];
                    this.quantityOrder = [];
                    this.productUnits = [];
                    this.categories = [];
                    if (dataNew.products) {
                        dataNew.products.forEach(product => {                              
                            // if(product.product?.hsCode){
                            //     product.hsCode = product.product.hsCode
                            // }
                            // if(product.product?.taxRate){
                            //     product.taxRate = product.product.taxRate
                            // }
                            //  if(product.consignmentActive && product.consignmentActive?.length > 0){
                            //     let quantityProduct = 0;
                            //     product.consignmentActive.forEach(element => {
                            //         quantityProduct += (element.quantity ? element.quantity : 0);
                            //     });
                            //     product.quantity = quantityProduct;
                            // }
                            // if (product.plusOrder) {
                            //     product.plusOrder.forEach(plus => {
                            //         if (plus.consignmentActive && plus.consignmentActive.length) {
                            //             let quantityProductPlus = 0;
                            //             plus.consignmentActive.forEach(consignmentPlus => {
                            //                 quantityProductPlus += +(consignmentPlus.quantity ?? 0);
                            //             });
                            //             plus.quantity = quantityProductPlus;
                            //         }
                            //     })
                            // }
                            if (product.plusReturn) {
                                product.plusReturn.forEach(plusItem => {
                                    plusItem.amount = plusItem.price - (!isNaN(plusItem.discount) ? plusItem.discount : 0);
                                    if (plusItem.consignmentActive && plusItem.consignmentActive.length > 0) {
                                        let quantityProductPlus = 0;
                                        plusItem.consignmentActive.forEach(consignmentPlus => {
                                            quantityProductPlus += +(consignmentPlus.quantity ?? 0);
                                        });
                                        plusItem.quantity = quantityProductPlus;
                                    }
                                    totalQuantity = totalQuantity + plusItem.quantity;
                                    totalPrice = totalPrice + plusItem.amount * plusItem.quantity;
                                });
                            }                            
                            let currentProductCategories = [];
                            if(product.product_category && product.product_category?.length){
                                product.product_category.forEach(children => {
                                    currentProductCategories.push(children.categoryId);
                                    let index = this.categories.findIndex(v => v.id == children.categoryId);
                                    let sumQuantityChild = 0;
                                    if (product.plusOrder && product.plusOrder.length){
                                        sumQuantityChild = product.plusOrder.reduce((accumulator, object) => {
                                            return accumulator + object.quantity;
                                        }, 0);
                                    }
                                    if(index < 0){
                                        this.categoriesIds.push(children.categoryId);
                                        this.categories.push({
                                            id: children.categoryId,
                                            maxQuantity: product.quantity + sumQuantityChild,
                                        })
                                    }else{
                                        this.categories[index].maxQuantity += (product.quantity + sumQuantityChild);
                                    }
                                });
                            }
                            let index = this.quantityOrder.findIndex((item) => item.id == product.productUnitId);
                            if (index > -1) {
                                this.quantityOrder[index].maxQuantity += product.quantity;
                            } else {
                                this.quantityOrder.push({
                                    id: product.productUnitId,
                                    maxQuantity: product.quantity,
                                    categoryIds: currentProductCategories
                                })
                            }
                            this.productUnitIds.push(product.productUnitId);
                            let productDiscountRatio = 0;
                            if (product.discountType == this.$constant.discountTypePercent) {
                                product.discount = product.price * product.valuePercent/100;
                                productDiscountRatio = product.valuePercent;
                            }
                            product.discountRatio = productDiscountRatio;
                            product.discountValue = product.discount;
                            product.amount = product.price - (!isNaN(product.discount) ? product.discount : 0);
                            product.subTotal = product.amount * product.quantity;
                            totalPrice = totalPrice + product.amount * product.quantity;
                            totalQuantity = totalQuantity + product.quantity;
                            if(product.basePrice){
                                totalBasePrice = totalBasePrice + (product.basePrice * product.quantity);
                            }
                            product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                            this.productIds.push(product.id);
                            if (product.plusOrder && product.plusOrder.length) {
                                product.plusOrder.forEach(item => {
                                    this.productUnitIds.push(item.productUnitId);
                                    let discountRatio = 0;
                                    if (item.discountType == this.$constant.discountTypePercent) {
                                        item.discount = item.price * item.valuePercent/100;
                                        discountRatio = item.valuePercent;
                                    }
                                    item.discountRatio = discountRatio;
                                    item.discountValue = item.discount;
                                    item.amount = +item.price - +item.discount;
                                    totalQuantity = totalQuantity + item.quantity;
                                    item.subTotal = item.amount * item.quantity;
                                    totalPrice = totalPrice + item.amount * item.quantity;
                                    if(item.basePrice){
                                        totalBasePrice = totalBasePrice + (item.basePrice * item.quantity);
                                    }

                                    let key = this.quantityOrder.findIndex((i) => i.id == item.productUnitId);
                                    if (key > -1) {
                                        this.quantityOrder[key].maxQuantity += item.quantity;
                                    } else {
                                        this.quantityOrder.push({
                                            id: item.productUnitId,
                                            maxQuantity: item.quantity,
                                            categoryIds: currentProductCategories
                                        })
                                    }
                                });
                            }                            
                        });
                    }
                    if(!dataNew.products || (dataNew.products && dataNew.products.length == 0)){
                        this.entry.invoiceCode = null;
                    }
                    this.entry.customerPayment = +(this.entry.cash ?? 0) + +(this.entry.card ?? 0) + +(this.entry.transfer ?? 0) + +(this.entry.voucher ?? 0);
                    let amountDiscountProduct = 0;
                    let quantityPromotion = Number(0);
                    if (dataNew && dataNew.productDiscount && dataNew.productDiscount.length) {
                        dataNew.productDiscount.forEach((promtion, index)=> {
                            this.setMaxQuantityProductDiscount(index, promtion.maxQuantity);
                            amountDiscountProduct = Number(amountDiscountProduct) +  Number(promtion.amount);
                            quantityPromotion = Number(quantityPromotion) + Number(promtion.quantity);
                        });
                    }
                    if (dataNew && dataNew.productGifts && dataNew.productGifts.length) {
                        dataNew.productGifts.forEach((promtion, index) => {
                            this.setMaxQuantityProductGift(index, promtion.maxQuantity);
                            quantityPromotion = +quantityPromotion + promtion.quantity;
                            if (promtion.isConsignment && promtion.consignmentActive && promtion.consignmentActive.length) {

                                let totalQuantity = 0;
                                promtion.consignmentActive.forEach(item => {
                                    totalQuantity += +item.quantity;
                                });
                                promtion.quantity = totalQuantity;
                            }
                        });
                    }
                    if (dataNew && dataNew.productDiscount && dataNew.productDiscount.length) {
                        dataNew.productDiscount.forEach((promtion, index) => {
                            this.setMaxQuantityProductGift(index, promtion.maxQuantity);
                            quantityPromotion = +quantityPromotion + promtion.quantity;
                            if (promtion.isConsignment && promtion.consignmentActive && promtion.consignmentActive.length) {
                                let totalQuantity = 0;
                                promtion.consignmentActive.forEach(item => {
                                    totalQuantity += +item.quantity;
                                });
                                promtion.quantity = totalQuantity;
                            }
                        });
                    }
                    this.entry.totalPrice =  Number(totalPrice) + Number(amountDiscountProduct);
                    
                    this.entry.totalBasePrice = totalBasePrice;
                    this.entry.totalQuantity = +totalQuantity + +quantityPromotion;
                    if (this.entry.discountType == this.$constant.discountTypePercent) {
                        this.entry.discountValue = totalPrice * this.entry.valuePercent/100;
                    }
                    if (dataNew.promotion && dataNew.promotion.id) {
                        if (dataNew.promotion.discountRatio) {
                            this.entry.promotion.discountValue = dataNew.totalPrice*dataNew.promotion.discountRatio/100;
                        }
                    }
                    let totalPriceEnd = 0;
                    if(this.entry.type == TYPE_RETURN){
                        totalPriceEnd = +totalPrice - (!isNaN(this.entry.discountValue) ? this.entry.discountValue : 0) - +(this.entry.surchargeAmount ?? 0) - (this.entry.valueCoupon ?? 0) - (this.entry.valueVoucher ?? 0) - (this.entry.promotionValue ?? 0);
                        
                        if(+totalPriceEnd < 0){
                            totalPriceEnd = 0;
                        }
                        this.entry.discount = this.entry.discountValue;
                    }else{
                        
                        totalPriceEnd = Number(this.entry.totalPrice) - (!isNaN(this.entry.discountValue) ? this.entry.discountValue : 0) + +(this.entry.surchargeAmount ?? 0) - (this.entry.valueCoupon ?? 0) - (this.entry.valueVoucher ?? 0) - ((this.entry.promotion && this.entry.promotion.discountValue) ? this.entry.promotion.discountValue : 0);
                        if(+totalPriceEnd < 0){
                            totalPriceEnd = 0;
                        }
                    }
                    this.entry.totalPriceAfterDiscount = totalPriceEnd;
                    // cần trả khách hàng
                    this.entry.total = totalPriceEnd;
                    // this.entry.total = this.getTotal(this.entry.details);
                    // Tổng tiền
                    this.entry.totalOrigin = this.entry.totalPrice;
                },
                deep: true
            },
            'entry.products': {
                handler: function(newProducts) {
                    
                    newProducts.forEach(product => {
                        if (product.combo_products) {
                            product.combo_products.forEach(element => {
                                if (element.oldQuantity) {
                                    element.quantity = product.quantity * element.oldQuantity;
                                }
                                if (element.product.isConsignment && (this.entry.type == TYPE_INVOICE || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                                    if (!element.product.consignmentActive && element.product.consignments && element.product.consignments.length) {
                                        let comboConsignment = element.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == element.productUnitId);
                                        let consignmentActive1 = {
                                            consignmentProductBranchMetaId: comboConsignment.id,
                                            consignmentId: comboConsignment.consignmentId,
                                            quantity: element.quantity,
                                            key: 1,
                                            productUnitId: element.productUnitId,
                                            name: element.product.consignments[0].name,
                                        }
                                        element.product.consignmentActive = [consignmentActive1];

                                    }
                                }
                            });
                        }

                        if (product.plusOrder) {
                            product.plusOrder.forEach(plusItem => {
                                if (plusItem.combo_products) {
                                    plusItem.combo_products.forEach(element => {
                                        if (element.oldQuantity) {
                                            element.quantity = plusItem.quantity * element.oldQuantity;
                                        }
                                        if (element.product.isConsignment && (this.entry.type == TYPE_INVOICE || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                                            if (!element.product.consignmentActive && element.product.consignments && element.product.consignments.length) {
                                                let comboConsignment = element.product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == element.productUnitId);
                                                let consignmentActive2 = {
                                                    consignmentProductBranchMetaId: comboConsignment.id,
                                                    consignmentId: comboConsignment.consignmentId,
                                                    quantity: element.quantity,
                                                    key: 1,
                                                    productUnitId: element.productUnitId,
                                                    name: element.product.consignments[0].name,
                                                }
                                                element.product.consignmentActive = [consignmentActive2];
                                            }
                                        }
                                    });
                                }
                            });
                        }
                
                        if (product.isConsignment && (this.entry.type == TYPE_INVOICE || (this.entry.type == TYPE_ORDER && this.entry.action == this.$constant.actionUpdateOrder))) {
                            if (!product.consignmentActive && product.consignments && product.consignments.length) {
                                let consignmentProductBranchMeta = product.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                                let consignmentActive = {
                                    consignmentProductBranchMetaId: consignmentProductBranchMeta.id,
                                    consignmentId: consignmentProductBranchMeta.consignmentId,
                                    quantity: product.quantity,
                                    key: 1,
                                    productUnitId: product.productUnitId,
                                    name: product.consignments[0].name
                                }
                                product.consignmentActive = [consignmentActive];
                            }

                            if (product.plusOrder && product.plusOrder.length) {
                                product.plusOrder.forEach(plus => {
                                    if (!plus.consignmentActive) { 
                                        let consignmentProductBranchMetaPlus = plus.consignments[0].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == plus.productUnitId);
                                        let consignmentActivePlus = {
                                            consignmentProductBranchMetaId: consignmentProductBranchMetaPlus.id,
                                            consignmentId: consignmentProductBranchMetaPlus.consignmentId,
                                            quantity: plus.quantity,
                                            key: 1,
                                            productUnitId: plus.productUnitId,
                                            name: plus.consignments[0].name
                                        }
                                        plus.consignmentActive = [consignmentActivePlus];
                                    }
                                });
                            }
                        }
                        if(this.entry.promotion && this.entry.promotion.clonePromotion){
                            if (this.entry.promotion.clonePromotion.promotionFromId == this.$constant.promotion_product_allow_quantity) {
                                if (product.productUnitId == this.entry.promotion.clonePromotion.productUnitId) {
                                    let originPrice = !product.originPrice ? product.price : product.originPrice; 
                                    product.originPrice = originPrice;
                                    if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountBy == 1) {
                                        this.entry.promotion.promotionProductValue = (product.originPrice - this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue) < 0 ? 0 : (product.originPrice - this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue);
                                    }
                                    else {
                                        if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue && !this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio) {
                                            this.entry.promotion.promotionProductValue = this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountValue;
                                        } 
                                        if (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio) {
                                            this.entry.promotion.promotionProductValue = (product.originPrice * (this.entry.promotion.clonePromotion.promotion_options.whenBuying.discountRatio/100));
                                        }
                                    }
                                    this.entry.promotion.promotionProductValue = this.entry.promotion.promotionProductValue * product.quantity;
                                }
                            }
                        }
                    });
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
    .btn-icon-gift, .label_gift{
        color: #FF2F86 !important;
    }
    .btn-icon-gift:hover, .btn-icon-gift.active{
        border-radius: 999px !important;
        background-color: rgba(255,47,134,0.1);
    }
    .bg-text-promotion{
        background-color: rgba(255,47,134,0.1);
        color: #FF2F86 !important;
    }
    .bg-gift{
        background-color: #fff !important;
        padding: 10px 20px 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        // border: 1px solid #d7d7d7;
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        box-shadow: 0 5px 10px rgba(0,0,0,.15);
    }
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            overflow: hidden auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: #fff;
        overflow: hidden auto;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;

            .btn-save {
                    width: 100%;
                    font-size: 20px;
                    height: 65px;
                    margin-left: 0;
                    font-weight: 600;

                    &:hover {
                        background-color: #168080 !important;
                    }
            }
            .btn-danger{
                width: 100%;
                    font-size: 20px;
                    height: 65px;
                    margin-left: 0;
                    font-weight: 600;

                    &:hover {
                        background-color: #168080 !important;
                    }
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }

        
</style>
