<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header my-0 pt-2 mx-2 head-remove">
                    <h5 class="modal-title py-0">{{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2 py-0">
                            {{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.content', {name: this.name}) }}
                    <div class="col-12 d-flex justify-content-end my-3">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white py-2" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                          {{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $get, $post, $alert } from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalTransporterIndexDelete',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {            
            this.id = id;
            this.name = name;            
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide(){
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async deleteData() {        
            const res = await $post(`/v1/transporters-groups/remove`, {id: this.id});             
            if(res && res.status?.code && res.status.code == 200 && res.result){
                    toastr.success(this.$t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.msgSuccess'));  
                    this.$emit('loadData');
                    this.hide();
            }
           else{
                  toastr.error(this.$t('message.TransporterIndex.modal.modalAddTransporterGroups.remove.msgError')); 
                  this.$emit('loadData');
            }    
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>