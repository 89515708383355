<template>
    <div class="search-box position-relative" :class="normal ? 'border-bottom-1 rounded-0 p-0 h-100' : ''" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray" v-show="!normal"></i>
        <div class="form-control multiselect p-0 border-none  bg-transparent pb-0" :class="readonly ? 'cursor-ban' : ''" v-if="normal" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
            <template v-if="listItemSelected?.length > 0">
                <ul class="p-0 m-0">
                    <template v-for="item in listItemSelected" :key="item.id">
                        <li class="item-select p-2 mb-1 d-flex flex-row align-items-center">
                            <span v-text="item.productCode + ' - ' + item.productName" v-if="item.productCode"></span>
                            <span v-text="item.productName" v-else></span>
                            <span @click="removeItem(item.id)" v-if="!readonly">
                                <i class="fa fa-times mr-2 text-gray"></i>
                            </span>
                            <span v-else>
                                <i class="fa fa-times mr-2 text-gray"></i>
                            </span>
                        </li>
                    </template>
                </ul>
            </template>
            <input type="text" class="search-input bg-transparent h-input-search" :placeholder="placeholder ? placeholder : $t('message.common.searchProduct')"
               v-model.trim="keyword" @input="onSearchProduct()" :readonly="readonly" v-if="normal" v-on:keyup.enter="onSearchProduct(true)"/>
        </div>
        <div class="dropdown-menu dropdown-menu-content" :class="keyword ? 'd-block' : 'd-none'">      
            <template v-if="products.length">
                <template v-for="product in products" :key="product.id">
                    <div class="dropdown-item d-flex" href="javascript:;" @click="onSelect(product)">
                        <template v-if="product.image?.length > 0 && product.image[0]?.url">
                            <div class="product-img mr-2">
                                <img v-if="product.image" :src="product.image[0]?.url" alt="" class="detail-row-img">
                            </div>
                        </template>
                        <template v-else>
                            <div class="product-img mr-2">
                                <img src="../../public/img/default-product.png" alt="" class="detail-row-img">
                            </div>
                        </template>
                        <div class="product-info">
                            <p class="name-item text-wrap">
                                <span v-text="product.productName"></span>
                            </p>
                            <p>
                                <span class="price-label">{{ product.productCode }}</span>
                                <span class="price-label ml-2">{{$t('message.ProductUnitSearch.price')}}: {{ n(product.price) }}</span>
                                <span class="price-label ml-1">{{$t('message.ProductUnitSearch.inventory')}}: {{ n(product.inventories) }}</span>
                            </p>
                            <p>
                                <span class="price-label">{{$t('message.ProductUnitSearch.orderFromCustomer')}}: {{ n(product.quantityOrder) }}</span>
                                <span class="price-label ml-1"> {{$t('message.ProductUnitSearch.orderSupplier')}}: {{ n(product.quantityOrderSupplier) }}</span>
                            </p>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="dropdown-item">
                    {{ $t('message.common.notFound') }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import store from "@/store";
    let app;

    export default {
        name: "ProductUnitsSelectSearch",
        components: {},
        props: ['label', 'branch', 'normal', 'placeholder', 'defaultValue', 'readonly'],
        data() {
            app = this;
            return {
                products: [],
                keyword: '',
                branchId: this.branch ?? store.state.CurrentBranch.id,
                listItemSelected: this.defaultValue ?? [],
            }
        },
        watch: {
            defaultValue: {                
                handler(newData){                          
                    this.listItemSelected = newData ?? [];
                },        
                deep: true,
            },
        },
        methods: {
            async onSearchProduct(isMultiple) {
                if (this.keyword && this.keyword.length >= 3) {
                    const res = await $get('/v1/products/get-product-units-main', {
                        keyword: this.keyword,
                        branchId: this.branchId,
                        isMultiple: isMultiple,
                    });
                    if (isMultiple) {
                        this.selectMultipleProduct(res);
                        this.hidePopup();
                    } else {
                        this.products = res;   
                    }
                }else{
                    this.products = [];
                }
            },
            onSelect(product) {
                this.selectProductDetail(product);
                this.hidePopup();
            },
            selectMultipleProduct(listProducts) {
                for (let index = 0; index < listProducts.length; index++) {
                    const product = listProducts[index];
                    this.selectProductDetail(product);
                }
            },
            selectProductDetail(product) {
                if(product && product.productUnit && product.productUnit.productId){
                    product.productId = product.productUnit.productId;
                }
                if(this.normal){
                    if(this.listItemSelected?.length == 0){
                        this.listItemSelected.push(product);
                    }else{
                        let apply = true;
                        this.listItemSelected.forEach(item => {
                            if(item.productUnitId == product.productUnitId){
                                apply = false;
                            }
                        });
                        if(apply){
                            this.listItemSelected.push(product);
                        }
                    }
                    this.$emit('selectProductUnit', {'data': this.listItemSelected, 'label': this.label});
                }else{
                    this.$emit('selectProductUnit', {'data': product, 'label': this.label});
                }
            },
            removeItem(id){
                this.listItemSelected.splice(this.listItemSelected.findIndex(v => v.id == id), 1);
                this.$emit('selectProductUnit', {'data': this.listItemSelected, 'label': this.label});
            },
            hidePopup() {
                this.keyword = '';
                this.products.length = 0;
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .multiselect{
        height: auto;
        max-height: 84px;
        overflow: auto;
        // padding-bottom: 5px !important;
    }
    .h-input-search{
        height: 35px !important;
    }
    .item-select{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .dropdown-menu-content{
        max-height: 300px !important;
        max-width: 500px !important;
        transform: translateY(-100%) !important;
        overflow-y: auto;
        cursor: pointer;
        background: #fff;
        z-index: 1002;
        padding-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
    }
    .popup-product-search {
        // position: absolute;
        // top: 100%;
        // left: 0;
        // width: 100%;
        // min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;
        /*padding-bottom: 50px;*/

        // max-height: calc(100vh - 230px);
        // font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-products {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0px;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .products-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
</style>
