
<template>
    <AppPermission :permission="this.$permissions.mappingProducts.data">
        <div>
            <div class="container my-4">
                <div class="row m-0 p-0">
                    <div class="mainLeft">
                    <!-- <div class="col-md-2" id="col2"> -->
                        <div class="main-title">
                            <span>{{ $t('message.medicine.title_index') }}</span>
                        </div>
                        <div>
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-8 text-title">
                                        <label>{{ $t('message.common.numberRecord') }}:</label>
                                    </div>
                                    <div class="col-4">
                                        <select :value="numberRecord" style="border: none;outline: none;" id="number_records" class="number_records" @change="onChange($event)">
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card" id="typeCard">
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-9">
                                        <label for="" class="typeTitle">{{ $t("message.medicineProductMapping.filter.title.category") }}</label>
                                    </div>
                                    <div class="col-1">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                            <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                            <i class="fa fa-chevron-up" id="branchUp"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                <div class="row">
                                    <div class="col-12">
                                        <SelectSearch :placeholder="$t('message.medicineProductMapping.placeholder.chooseCategories')" :modelValue="filterQuery.category" :defaultOption="this.categories" @update:modelValue="doFilterSearch" :label="'category'"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card mt-4" id="typeCard">
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-9">
                                        <label class="typeTitle">{{ $t("message.medicineProductMapping.filter.title.status") }}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body my-0 py-2" id="sBody">
                                <div class="row">
                                    <div class="col-12" v-for="item in this.listStatus " :key="item.id">
                                        <label class="container-radio ml-0">{{ $t(item.name) }}
                                            <input type="radio" name="sttLink" @click="doFilter('sttLink', item.id)" class="chkType" :checked="this.sttLink === item.id ? true: false">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                    <div class="mainRight">
                    <!-- <div class="col-md-10" id="col10"> -->
                        <div class="sticky-top">
                            <div class="row">
                                <div class="col-5">
                                    <InputQuickSearch :placeholder="$t('message.InputQuickSearch.placeholder.product_omipos')" @filterSearch="changeQuickSearch" :medicineSearchProduct="true"></InputQuickSearch>
                                </div>
                                <div class="col-md-7 d-flex justify-content-end">
                                    <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                </div>
                            </div>
                            <div>
                                <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                            </div>
                            <div class="row mx-0 my-2" id="divTable">
                                <table class="table table-index table-striped w-100">
                                    <thead>
                                        <tr>
                                            <th class="omi-product">
                                                {{$t('message.medicineProductMapping.productOmipos')}}
                                            </th>
                                            <th class="shop-product">
                                                {{$t('message.medicineProductMapping.medicineCode')}}
                                            </th>
                                            <th class="operation text-center">
                                                {{$t('message.button.action')}}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="entries?.length > 0">
                                            <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                <tr class="master-row" :style="index % 2 ? 'background-color: white' : null">
                                                    <td class="ow-break-word py-0" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                                        <div v-text="entry.name"></div>
                                                        <div v-text="entry.code" class="text-primary"></div>
                                                    </td>
                                                    <td>
                                                            
                                                                <template v-if="!entry.medicine">
                                                                    <AppPermission :permission="this.$permissions.mappingProducts.mapping">
                                                                        <ProductUnitsSearch @selectProductUnit="onSelectProductUnit" :searchMedicine="true" :label="entry" :branch="entry.get_shop?.branchId"></ProductUnitsSearch>
                                                                    </AppPermission>                                                     
                                                                </template>   
                                                             
                                                            <template v-else>
                                                                <div v-text="entry.medicine.name"></div>
                                                                <div v-text="entry.medicine.code" class="text-primary"></div>
                                                            </template>
                                                       
                                                    </td>
                                                    <td>
                                                        <AppPermission :permission="this.$permissions.mappingProducts.remove">
                                                            <template v-if="entry.medicine">
                                                                <div class="d-flex justify-content-center mapping-grid-content tooltip-icon-item ng-star-inserted">
                                                                    <div class="btn-icon btn-icon-sm btn-circle ng-star-inserted">
                                                                        <i class="fas fa-unlink" :title="$t('message.medicineProductMapping.removeLinked')" @click="unLink(entry.id)"></i>
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </AppPermission>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-if="entries.length == 0 && !this.loading">
                                            <tr>
                                                <td :colspan="5" class="pb-0 bg-light">
                                                    <div class="row detail-prd-row mx-0">
                                                        <div class="col-12 text-center text-secondary mt-5">
                                                            <h3>
                                                                <i class="fa fa-file mr-2 text-secondary"></i>
                                                                <i class="fa fa-exclamation"></i>
                                                            </h3>
                                                        </div>
                                                        <div class="col-12 text-center my-10 text-secondary mb-5">
                                                            <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalImportMedicine ref="ModalImportMedicine" />
    </AppPermission>
</template>

<script>
    import {$alert, $get, $post, clone, setArray} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import emitter from "@/lib/emitter";    
    import AppPermission from '@/components/AppPermission.vue';
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import SelectField from "../../components/SelectField";
    import ModalImportMedicine from './ModalImportMedicine.vue';
    import SelectSearch from "@/components/SelectSearch.vue";
    import ProductUnitsSearch from "@/components/ProductUnitsSearch.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import store from "@/store";
    export default {
        name: "MedicineProductMappingIndex",
        components: {
            TableSortField,
            Pagination,
            AppPermission,
            InputQuickSearch,
            SelectField,
            ModalImportMedicine,
            ProductUnitsSearch,
            SelectSearch,
            InputSelectSearch
    },
        mounted() {
            document.title = this.$t('message.medicine.title_document');
            const query = this.$route.query;
            this.filter = {...this.filter, ...query};
            this.paginate.currentPage = this.$route.query.page || 1;
            this.numberRecord = this.$route.query.numberRecord || "15";
            this.clearFilter();
            window.addEventListener('scroll', this.onScroll);
        },
        data() {
            return {
                fields: [],
                entries: [],
                customerGroupList: [],
                customerGroupDetail: {},
                fixed: false,
                params: {
                    keyword: '',
                    status: ''
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                numberRecord: 15,
                flag: true,
                loading: false,

                filterQuery: {
                    category: '',
                },
                optionSearch: [
                    {id: 1, name: 'Hang 1'},
                    {id: 2, name: 'Hang 2'}
                ],
                listStatus : [
                    { "id": 1, "name": "message.medicineProductMapping.all", "language": true },
                    { "id": 2, "name": "message.medicineProductMapping.linked", "language": true },
                    { "id": 3, "name": "message.medicineProductMapping.noLink", "language": true }
                ],
                sttLink: null,
                categories: [],
                filter: {
                    product_omipos: '',
                    product_medicine: ''
                },
                isFilter: false,
            }
        },
        methods: {
            async load () {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $get('/v1/sync-medicine/medicine-products', {...this.$route.query, ...this.filterQuery, record: this.numberRecord, branchID: store.state.CurrentBranch?.id,});
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data; 
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                // if(res.result.data.listStatus){
                //     this.listStatus = res.result.data.listStatus;
                // }
                this.loading = false;
                emitter.emit("offLoading");
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                await this.load();
            },
            showModal(flag) {
                this.$refs.ModalAddCustomer.show(flag);
            },

            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
          
            renderListData(newCustomerGroup) {
                this.customerGroupList = this.customerGroupList.map(customerGroup => customerGroup.id === newCustomerGroup.id ? newCustomerGroup : customerGroup)
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async search () {
                let query = clone(this.$route.query);
                this.params = {
                    ...this.params,
                }
                const data = await $get('/v1/customer-group', {...this.params, numberRecord: this.numberRecord, query: query});
                // this.load();
                this.customerGroupList = data.data;
                this.paginate = data.paginate;
                this.entries = data.entries;     
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async clearFilter () {
                await this.$router.replace({
                    path: '/medicine/mapping-product',
                    query: {}
                });
                Object.keys(this.filterQuery).forEach(key => {
                    this.filterQuery[key] = '';
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                await emitter.emit("clearQuickSearch");
                this.numberRecord = 15;
                this.sttLink = this.$constant.status_all;
                this.isFilter = false;
                await this.getDataCategories();
                await this.load();
            },
            onScroll() {
                let height = window.scrollY;
                if (height > 100) {
                    this.fixed = true;
                } else {
                    this.fixed = false;
                }
            },
            showModalUploadFileExcel () {
                this.$refs.ModalImportMedicine.show();
            },
            async onSelectProductUnit(data){

                let syncMedicineId = data.data.id;
                let productId = data.label.id;
                if(!productId || !syncMedicineId){
                    $alert({ code: 422, message: this.$t("message.medicineProductMapping.message_connect_error") });
                    return;
                }
                const res = await $post('/v1/sync-medicine/connect-product', {
                    id: productId,
                    syncMedicineId: syncMedicineId,
                });
                if (res.status.code == 200) {
                    $alert({ code: 200, message: this.$t("message.medicineProductMapping.message_connect_success")});
                }else {
                    $alert({ code: 500, message: this.$t("message.medicineProductMapping.err")});
                }
               this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                query[field] = value;
                if(field == 'sttLink'){
                    this.sttLink = value;
                    if(value == null){
                        delete query[field];
                    }
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async getDataCategories(){
                const cRes = await $get('/v1/categories/get-all-data');
                this.categories = cRes.result.data;
            },

            async unLink(id){
                const res = await $post('/v1/sync-medicine/remove', {
                    id: id,
                });
                if(res && res.status.code == 200){
                    $alert({ code: 200, message: this.$t("message.medicineProductMapping.message_disconnect_success")});
                }else {
                    $alert({ code: 500, message: this.$t("message.medicineProductMapping.err")});
                }
                this.load();
            },
        },
        computed: {
            formatCurrentCies (price) {
               return price.toLocaleString()
            }
        },
    }
</script>
<style scoped>
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.custom-desc {
    margin-top: 15px !important;
}
.lstCustomer tr td , .lstCustomer tr th {
    /* min-width: 150px !important;
    min-height: 500px !important; */
    vertical-align: middle !important;
    padding: 10px;
}

.lstCustomer {
    border-collapse: collapse;
    width: 2600px !important;
    /* overflow-x: scroll; */
    max-height: 700px;
    display: block;
    margin-top: -1px;
    margin-right: 0px;
}

.lstCustomer thead th {
    border: none;  
}
.name-customer-group {
    width: 400px;
}
.note-customer-group {
    width: 500px;
}
.discount-customer-group {
    width: 300px;
}
.action {
    width: 300px;
}
tbody {
    /* display: block;
    max-height: 600px;
    overflow: auto; */
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cell-name{
    width: 40% !important;
    min-width: 40%;
    max-width: 40%;
}
.cell-quantity{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
.cell-discount{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-auto{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-check{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
table tbody tr td{
    min-height: 40px !important;
    padding: 10px !important;
} 
</style>
