<template>
  <div class="row">
    <div class="col-lg-6 px-0 mx-0" style="border: 1px solid #ccc" id="design">              
      <DesignTemplates 
        ref="DesignTemplates"
        :action="this.action"
        :entry="this.entry"
        :html="this.html"
        :content="this.content"
        :printTempId="this.tabId"
        :printTempData="this.printTemplate"
        @changeContent="this.changeContent"  
        />      
         
    </div>
    <div class="col-lg-6 px-0 mx-0" style="background-color: #eef3f7">
      <ViewTemplates ref="ViewTemplates" 
        :entry="this.data"
        :contenOrigin="contenOrigin" 
        :action="this.action"/>
    </div>
  </div>
</template>
<script>
import DesignTemplates from "./tabs/DesignTemplates";
import ViewTemplates from "./tabs/ViewTemplates";
import { $post } from '@/lib/utils';

export default {
  name: "PlanDetailsTemplate",
  components: { DesignTemplates, ViewTemplates },
  mounted() {          
  },
  created() {  
    this.load();  
  },
  data() {
    return {      
      printPaperId: 2,
      action: "TabPlanDetails",
      entry:{},      
      data:{},
      html:'',
      printTemplate: null,      
      content:null,
      contenOrigin: null,
      tabId: this.$constant.defaultPlanDetailTemplateId,
    };
  },
  methods: {
    changeContent(newContent){        
        this.contenOrigin = newContent;
    },
    async load(){
        const res = await $post('/v1/print-templates/get-template-id', {action: this.action});
        if(res && res.status.code == this.$constant.statusSuccess && res?.result && res.result?.data){
            this.tabId = res.result.data.id;                
        }         
    }        
  },
  watch: {    
    content: {                
        handler(newData){                  
            this.content = newData;                  
        },
        deep: true
    },            
    printTemplate: {                
        handler(newData){                  
            this.printTemplate = newData;                  
        },
        deep: true        
    },
  }
};
</script>
