<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2">
                        {{$t('message.common.confirm')}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{$t('message.common.confirmCloseTab', {tab: this.tab})}}
                    <div class="col-12 mt-4 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="closed()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "ModalConfirmCancel",
    
    data(){
        return {
            tab: '',
            element: ''
        }
    },
    props: [],
    methods: {
        show(tab) {
            this.tab = tab.name;  
            this.element = tab;         
            window.jQuery(this.$refs.modal).modal('show');
        },
        closed() {
            this.$emit('closeTab', this.element);
            window.jQuery(this.$refs.modal).modal('hide');
        },
    },
    beforeUnmount() {
        this.closed();
    },
    unmounted() {
        this.closed();
    }
}
</script>

