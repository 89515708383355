<template>
    <div class="sale-screen" @keyup.enter="processBarcodeScanner">
        <div id="header-sale">
            <div class="row header-main-menu">
                <div class="col-4 search-box__wrapper">
                    <MultipleSearch @select="onSelectTicketSearch" :placeholder="$t('message.common.searchOrderSupplier')" :currentTab="currentTab"></MultipleSearch>
                </div>

                <div class="col-5 p-0">
                    <div class="sale-tabs__root" ref="saleTabRoot">
                        <template v-if="showLeftRight" >
                            <div class="sale-tabs__scroller sale-tabs__left" @click.prevent="onClickScroll('left')" >
                                <div>
                                    <i class="fa fa-chevron-left"/>
                                </div>
                            </div>
                            <div class="sale-tabs__scroller sale-tabs__right"  @click.prevent="onClickScroll('right')">
                                <div>
                                    <i class="fa fa-chevron-right"/>
                                </div>
                            </div>
                        </template>

                        <div class="sale-tabs__btn" :style="{left: btnLeftFloat+'px'}" >
                            <button type="button" class="sale-tabs__btn--add" data-toggle="tooltip" data-placement="top"><i class="fa fa-plus-circle" ></i></button>
                            <a class="nav-link" href="#" id="partnerDropdown" role="button" >
                                <i class="fas fa-caret-down text-white"></i>
                            </a>
                            <!-- <a class="nav-link" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fas fa-caret-down text-white" data-toggle="tooltip" data-placement="top"></i>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                <a class="dropdown-item" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px" @click.prevent="addTab(null, 'order')">
                                    Thêm mới đặt hàng
                                </a>
                            </div> -->
                        </div>

                        <draggable
                            v-model="tabs"
                            class="sale-tabs"
                            group="tabs"
                            @start="drag=true"
                            :wrap="false"
                            @end="drag=false"
                            id="ticket-tabs"
                            item-key="id">
                            <template #item="{element}">
                                <div v-if="!element.branchId || element.branchId == currentBranch.id"
                                     class="sale-tabs__tab" :class="{'sale-tabs__tab--active': element.id === currentTab.id}">
                                    <div class="sale-tabs__tab-content" @click.prevent="changeTab(element)">
                                        <div>
                                            <a class="sale-tabs__link" href="">{{ element.name }}</a>
                                            <a class="sale-tabs__close " href="" @click.stop.prevent="confirmCloseTab(element)" style="margin-left: 10px" data-toggle="tooltip" data-placement="top" title="Đóng">
                                                <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                    </div>
                </div>

                <div class="col-3 float-right">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <ul class="navbar-nav ml-auto mr-4">
                                <li class="nav-item">
                                    <a class="nav-link text-white" href="javascript:void(0);">
                                        {{ this.user.name }}
                                    </a>
                                </li>
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-bars text-white"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                        <a class="dropdown-item" href="/customers/index" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-user-shield"></i> 
                                            {{$t('message.headers.manage')}}
                                        </a>
                                        <a class="dropdown-item" @click="logout" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-sign-out-alt"></i> 
                                            {{$t('message.headers.logout')}}
                                        </a>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-screen__main">
<!--            <keep-alive>-->
                <component :is="currentComponent"
                           v-if="currentTab && currentTab.id"
                           v-model="currentTab"
                           :key="currentTab.id"
                           ref="ticket"
                           @removeTab="removeCurrentTab"
                           @orderProcessing="addTab"
                           @print="onPrint"
                ></component>
<!--            </keep-alive>-->
        </div>

        <div id="footer-sale">
            <div class="row footer-sale-content">
                <div class="col-9 p-0"></div>
                <div class="col-3 p-0 float-left">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <a class="text-dark m-0 p-0 pr-2" href="#" role="button" :title="currentBranch.name" style="padding-bottom: 0">
                                <i class="fas fa-map-marker-alt"></i>
                            </a>
                            <div id="dropdownMenuButton1">
                                <span class="m-tb-auto p-0" v-text="currentBranch.name"></span>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-modal">
            <!-- <ModalConfirmCancel ref="ModalCancel" @closeTab="closeTab"/> -->
            <!-- <ModalConfirm ref="ModalConfirm" @confirm="confirmSelectTicket()" @cancel="cancelSelectTicket()"  :title="'Xác nhận'" :content="this.content"/> -->
        </div>
    </div>
</template>

<script>

import SettlementAdvanceTab from "./SettlementAdvanceTab";
import draggable from 'vuedraggable'
import store from "../../store";
import MultipleSearch from "@/components/MultipleSearch.vue";
import moment from "moment";
import * as toastr from 'toastr';

import {
  $alert,
  $get, $post, ACTION_CREATE, ACTION_ORDER_PROCESSING,
  ACTION_UPDATE_INVOICE,
  ACTION_UPDATE_ORDER,
  isEmpty,
  TYPE_IMPORT,
  TYPE_EXPORT,
  TYPE_RETURN,
  TYPE_TRANSFER,
  TYPE_DELIVERY, ACTION_RETURN_INVOICE,
  clone
} from "../../lib/utils";
import { useLoading } from 'vue3-loading-overlay';
import emitter from "@/lib/emitter";
import ModalConfirmCancel from './ModalConfirm.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import AppPermission from '@/components/AppPermission.vue';

export default {
    name: "SettlementAdvanceCreateUpdate",
    components: {
        ModalConfirmCancel, SettlementAdvanceTab, draggable, MultipleSearch, ModalConfirm,AppPermission},
    data() {
        return {
            currentComponent: 'SettlementAdvanceTab',
            createdById: null,
            drag: false,
            showLeftRight: false,
            btnLeftFloat: -30,
            currentTab: {},
            tabs: [],
            branches: store.state.GlobalConfig?.userBranches,
            currentBranch: store.state.CurrentBranch,
            user: store.state.Auth ? store.state.Auth.user : {},
            barcode: '',
            type: null,
            printType: '',
            ticketSearchCode: null,
            content: null,
            TYPE_IMPORT, TYPE_EXPORT, TYPE_DELIVERY, TYPE_RETURN, TYPE_TRANSFER
        }
    },
    created() {
        this.handleTabInput();
        // emitter.on('ticketConfirmed', () => {
        //     this.cancelSelectTicket();
        // })
    },
    mounted() {
        document.title = this.$t('message.headers.branch') + ' ' + this.currentBranch.name + '';
        this.rootWidth = this.$refs.saleTabRoot.offsetWidth;
        this.divSaleTab = window.jQuery('#ticket-tabs');
        this.updateStyle();
        // this.checkHandleTabInput();
    },
    computed: {
        getIndexTabNext() {
            const mapTabs = {};
            this.tabs.forEach(function (tab) {
                mapTabs[tab.id] = true;
            });

            let tabNext = 0;
            do {
                ++tabNext;
            } while (mapTabs[tabNext]);

            return tabNext;
        }
    },
    methods: {
        async handleTabInput(code = null) {
            this.tabs = [];
            const tabNext = this.getIndexTabNext;
            let activeTabNext = false;
            const query = this.$route.query;
            if (query.type) {
                let data = {};
                const type = query.type;
                if (type === 'update' && query.id) {
                    let res = await $get('/v1/settlement-advance/get-detail/'+query.id);
                    if (res && res.result) {
                        data = res.result;
                        data.originId = res.result.id;
                        data.id = +tabNext;
                        data.name = data.code;
                    }
                } else if (type === 'create') {
                    data = {
                        id: +tabNext, 
                        name: 'DNTU' + tabNext,
                        createdBy: this.user.id,
                        statusName: this.$t('message.common.draft'),
                    };
                    data.tickets = [];
                }
                this.tabs.unshift(data);
                activeTabNext = true; 
            }
            if (activeTabNext) {
                if (!this.currentTab || !this.currentTab.id) {
                    this.currentTab = this.tabs[0];
                }
            }
        },
        async onSelectInvoiceProcessing(code) {
            emitter.emit("offLoading");

            await this.$router.push({query: {returnInvoice: code}});
            await this.handleTabInput();

            emitter.emit("offLoading");
        },
        async getData(code, type = TYPE_IMPORT) {
            var res;
            let isCopy = this.$route.query.isCoppy;    
            if(isCopy && isCopy == 'copy')
            {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code, isCopy: isCopy});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code, isCopy: isCopy});
                }
            }
            else {
                if (type == this.$constant.typeInvoice) {                   
                        res = await $get('/v1/orders/get-invoice-detail', {type: type, code: code});
                } else {
                        res = await $get('/v1/orders/get-order-detail', {type: type, code: code});
                }

               
            }
             if (res.status && res.status.code == 200) {
                    return res.result;
                } else {
                    $alert(res);
                    return false;
                }
        },
        setCurrentTab() {
            const currentTabIndex = localStorage.getItem('currentSettlementAdvanceTab_KH' + this.user.id);
            const branchTabs = this.tabs.filter((el) => (!el.branchId || el.branchId == this.currentBranch.id));
            if (branchTabs && branchTabs.length) {
                this.currentTab = branchTabs.find(el => (el.id == currentTabIndex)) || branchTabs[0];
            } else {
                this.currentTab = {};
            }
        },
        addTab(tab = null, order = null) {
            let id = this.getIndexTabNext;
            if (tab) {
                tab.id = id;
                tab.name = 'Nhập kho';
                this.tabs.push(tab);
            } else {
                if(order){
                    tab = {
                        id,
                        name: 'Xuất kho',
                        type: TYPE_EXPORT,
                        action: ACTION_CREATE
                    };
                }else{
                    tab = {
                        id,
                        name: 'Nhập kho',
                        type: TYPE_IMPORT,
                        action: ACTION_CREATE
                    };
                }

                this.tabs.unshift(tab);
            }

            this.currentTab = tab;

            this.updateStyle();
            this.$nextTick(() => {
                this.scrollLeftTab(0);
            })
        },
        updateStyle() {
            const width = this.tabs.length * 120;

            if (width  >= this.rootWidth) {
                this.showLeftRight = true;
                this.btnLeftFloat = -55;
            } else {
                this.showLeftRight = false;
                this.btnLeftFloat = -30;
            }
        },
        changeTab(tab) {
            localStorage.setItem('currentSettlementAdvanceTab_KH' + this.user.id, tab.id);
            this.currentTab = tab;
        },
        async removeCurrentTab(isPartialOrderProcessing) {
            const currentTab = this.currentTab;

            this.closeTab(currentTab, false);

            if (currentTab.orderId && currentTab.action == ACTION_ORDER_PROCESSING) {
                const orderRelatedTab = this.tabs.find((t) => (t.orderId == currentTab.orderId && t.id != currentTab.id));

                if (orderRelatedTab) {
                    if (isPartialOrderProcessing) {
                        this.currentTab = orderRelatedTab;
                        if (confirm('Bạn vừa tạo hóa đơn từ đơn đặt hàng ' + currentTab.code +'. Bạn sẽ không xử lý tiếp với những hàng hóa chưa lấy hết và kết thúc đơn đặt hàng ngay bây giờ?')) {

                            const res = await $post('/v1/orders/saveStatus', {
                                id: currentTab.orderId,
                                action: 'complete'
                            });

                            $alert(res);
                        }
                    }
                    this.closeTab(orderRelatedTab, false);
                }
            }
        },
        onClickScroll(direction) {

            const offset = direction === 'right' ? this.divSaleTab[0].scrollLeft + 120 : this.divSaleTab[0].scrollLeft - 120;
            this.scrollLeftTab(offset)
        },
        scrollLeftTab(offset) {
            this.divSaleTab.animate({scrollLeft: offset}, 100);
        },
        confirmCloseTab(tab, confirm = true){
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                if(tab.products && tab.products.length > 0){
                    if (confirm) {
                        if (!this.$refs.ModalCancel.show(tab)) {
                            return;
                        }
                    }
                }else{
                    this.closeTab(tab);
                }
            }
        },
        closeTab(tab) {
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                this.tabs.splice(index, 1);
                this.updateStyle();
                if (this.tabs.length > 0) {
                    this.setCurrentTab();
                } else {
                    this.addTab();
                }
            }

        },
        async onSelectProduct(product) {
            if (!this.currentTab.products) {
                this.currentTab.products = [];
            }
            product.product = {
                "isConsignment": null
            };
            if(product.isConsignment){
                product.product.isConsignment = product.isConsignment;
            }
            if(product.product_units){
                product.productUnits = product.product_units;
                product.productUnitId = product.productUnits[0] ? product.productUnits[0].id : null;
            }
            product.fromLocationDetailId = null;
            product.show = true;
            product.toLocationDetailId = null;
            product.productId = product.id ?? null;
            if(this.currentTab.type && (this.currentTab.type == TYPE_TRANSFER)){
                let now = moment().format('YYYY-MM-DD');
                if (product.isConsignment || (product.product && product.product.isConsignment)) {
                    if (!product.consignmentActive) {
                        var minExpried = product.consignments.reduce((acc, value) => {
                            return (acc = (moment(acc).format('YYYY-MM-DD') <= moment(value.expried).format('YYYY-MM-DD')) &&  moment(acc).format('YYYY-MM-DD') >= now ? acc : value.expried);
                        }, 0);
                        var index = product.consignments.findIndex(consignment => consignment.expried == minExpried);
                        if(product.consignments[index] && product.consignments[index].consignment_product_branch_metas){
                            let consignmentProductBranchMeta = product.consignments[index].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                            let consignmentActive = {
                                consignmentProductBranchMetaId: consignmentProductBranchMeta.id ?? null,
                                consignmentId: consignmentProductBranchMeta.consignmentId ?? null,
                                id: consignmentProductBranchMeta.consignmentId ?? null,
                                name: product.consignments[index].name ?? null,
                                expried: product.consignments[index].expried ?? null,
                                quantity: product.quantity ?? 1,
                                quantityDraft: product.quantity ?? 1,
                                key: 1,
                                productUnitId: product.productUnitId ?? null,
                                inventory: consignmentProductBranchMeta.inventory,
                                productId: product.id,
                            }
                            await this.getListLocationForExport(consignmentActive);
                            product.consignmentActive = [consignmentActive];
                        }
                    }
                }else{
                    await this.getListLocationForExport(product);
                }
            }          
            this.currentTab.products.unshift(product);
        },

        async getListLocationForExport(params){
            if(this.currentTab.type && (this.currentTab.type == TYPE_TRANSFER)){
                params.branchId = store.state.CurrentBranch.id ?? null;
                params.ticketExportId = null;
                const res = await $post('/v1/location-details/get-list-for-ticket-export', { ...params});
                if(res.result && res.result.data){
                    params.locations =  res.result.data;
                }else{
                    params.locations = [];
                }
            }
        },

        setBranch(branch) {
            store.commit('SetCurrentBranch', branch);
            this.$nextTick(() => {
                location.reload();
            })
        },
        logout() {
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        processBarcodeScanner(e) {
            const barcode = e.target.value; // ??
            this.$nextTick(async () => {
                // //temporarily hide

                // const res = await $get('/v1/sale/productBarcodeScanner', {product_code: barcode});
                //
                // if (res.code === 200) {
                //     const product = res.data;
                //
                //     if (product) {
                //         this.onSelectProduct(product);
                //     }
                // }
                // $alert(res);
            });
        },
        onShowModalOrderProcessing() {
            this.$refs.modalSelectOrderProcessing.show();
        },
        async onSelectOrderProcessing(orderCode) {
            let loader = useLoading();
            loader.show({
                zIndex: 9999,
                color: '#008080',
            });

            await this.$router.push({query: {order: orderCode}});

            await this.handleTabInput();
            loader.hide();
        },
        async onSelectTicketSearch(ticket){
            // if (Array.isArray(ticket) && ticket[0] && ticket[0].typeSrc == this.$constant.TYPE_SRC_DISCOUNT_BAS) {
            //     let existDiscount = this.currentTab.tickets.findIndex(item => item.discountBasId == ticket[0].discountBasId);
            //     if (existDiscount != -1) {
            //         toastr.error('Chứng từ gốc đã tồn tại');
            //         return;
            //     }
            //     ticket.forEach(element => {
            //         this.currentTab.tickets.unshift(element);
            //     });
            // } else {
                let index = this.currentTab.tickets.findIndex(item => item.code == ticket.code);
                if (index != -1 && ticket.typeSrc !== this.$constant.TYPE_SRC_DISCOUNT_BAS) {
                    toastr.error('Chứng từ gốc đã tồn tại');
                    return;
                } 
                this.currentTab.tickets.unshift(ticket);
            // }
        },
        async confirmSelectTicket(){
            this.submitTicket = true;
            await emitter.emit("submitTicket");
        },
        async cancelSelectTicket(){
            if(this.submitTicket){
                emitter.emit("onLoading");
                const query = clone(this.$route.query);
                if(this.type == TYPE_IMPORT){
                    query['importCode'] = this.ticketSearchCode;
                }
                if(this.type == TYPE_EXPORT){
                    query['exportCode'] = this.ticketSearchCode;
                }
                this.submitTicket = false;
                await this.$router.push({query: query});
                await this.handleTabInput();
                emitter.emit("offLoading");
            }
        },
        onPrint(type, tabData = null) {
            this.printType = type;

            if (tabData) {
                this.currentTab.code = tabData.code;
                this.currentTab.purchaseDate = tabData.purchaseDate;
                this.currentTab.delivery = tabData.delivery;
            }

            this.$nextTick(() => {
                window.print();
            });
        }
    },
    watch: {
        tabs: {
            handler: function (val) {
                localStorage.setItem('settlementAdvanceTabData_KH' + this.user.id, JSON.stringify(val))
            },
            deep: true
        }
    }
}
</script>

<style  lang="scss">
@import '../../assets/css/vars';
    .sale-screen__main {
        padding-top: 5px;
        font-size: 14px;
    }
    .sale-tabs__root {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        .sale-tabs__scroller {
            z-index: 1;
            font-size: 22px;
            cursor: pointer;
            opacity: 0.3;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }

        .sale-tabs__btn {
            position: absolute;
            left: -55px;
            display: flex;
            height: 100%;
            align-items: center;
            z-index: 1;
        }

        .sale-tabs__btn--add {
            background-color: $base-color;
            color: #fff;
            border: 0;
            height: 100%;
            width: 50px;
            outline: 0;
            &:hover,&:focus {
                outline: 0;
                background-color: #168080;
            }
        }

        .sale-tabs__left {
            left: 50px;
        }
        .sale-tabs__right {
            right: 0;
        }
    }
    .sale-tabs {
        list-style: none;
        padding-left: 50px;
        height: 45px;
        overflow-x: hidden;
        white-space: nowrap;
        overflow-y: hidden;

        .sale-tabs__tab {
            cursor: pointer;
            color: white;
            display: inline-block;
            margin-left: 1px;
            height: 100%;
            min-width: 120px;
            background-color: #168080;
            .sale-tabs__tab-content {
                padding: 5px 10px;
                justify-content: center;
                flex-direction: column;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .sale-tabs__tab--active {
            background-color: white;
            .sale-tabs__link {
                color: $base-color;
                font-weight: bold;
            }
        }
        .sale-tabs__link {
            text-decoration: none;
            color: #ccc;
            font-size: 14px;
        }

        .sale-tabs__close {
            color: #ccc;
            &:hover {
                color: darkred;
            }
        }
    }

    .sale-form__print {
        display: none;
    }
    @media print {
        .sale-screen {
            display: none;
        }
        .sale-form__print {
            display: block;
        }
    }
</style>
