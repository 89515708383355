<template>
  <div class="container">
    <div class="row my-3">
      <div class="col-xs-1 pt-1">
        <a href="/inventory-plans/index" class="text-dark" rel="noopener noreferrer">
            <i class="fa fa-arrow-left fa-lg" aria-hidden="true"></i>
        </a>        
      </div>
      <div class="col-11">
        <h5 style="font-weight: bold">Dự trù hàng nhập</h5>
      </div>
    </div>

    <!-- Form nhập dữ liệu -->
    <div class="row" >
        <div class="col-2" id="titleReport">
            <i class="fas fa-chart-line pt-1"></i>
            <span class="px-2" style="font-size: 15px">Báo cáo mới</span>
        </div>      
    </div>
    <div class="row px-3 py-4" style="background-color:#F2F2F2 ">
    <!-- Cột 1 -->
      <div class="col-lg-6">
        <!-- Mã báo cáo dự trù: -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle">Mã báo cáo dự trù: </span>
          </div>
          <div class="col-9">
            <InputText v-model="entry.code" placeholder="Mã tự động" :readonly="this.inActive"/>
          </div>
        </div>

        <!-- Tên báo cáo dự trù: -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle">Tên báo cáo dự trù: </span>
          </div>
          <div class="col-9">
            <InputText
              v-model="entry.name"
              placeholder="Nhập tên báo cáo dự trù" :readonly="this.inActive" />
          </div>
        </div>

        <!-- Chi nhánh nhập hàng: -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle">Chi nhánh cần nhập hàng: </span>
            <span class="planTitle text-danger pl-1"> * </span>
          </div>
          <div class="col-9">
            <SelectSearch
              :placeholder="$t('message.common.placeholder.branch')"
              :modelValue="entry.branchId"
              :defaultOption="branches"
              @update:modelValue="selectBranch"
              :label="'branch'"
              :class="'form-control px-0 mx-0'"
              :action="true"
              :actionStyle="true"
              :disabled="this.inActive"
              ></SelectSearch>
          </div>
        </div>

        <!-- Nhóm hàng: -->
        <div class="row mt-3">
          <div class="col-3 divTitle">
            <span class="planTitle">Nhóm hàng hóa: </span>
            
          </div>
          <div class="col-9">
            <div class="form-group">
              <q-tree-select-input
                name="categoryId"
                :options="categoryTree"
                :single="false"
                v-model="entry.categoryId"
                @change="resetProductId"      
                :disabled="this.inActive"
                :readyonly="true"               
                />              
            </div>
          </div>
        </div>

        <!-- Thời gian để tính lượng bán trung bình / ngày: -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle" style="line-height: 20px"
              >Thời gian để tính lượng bán trung bình/ngày:
              <span class="planTitle text-danger pl-1"> * </span>
            </span>
            
          </div>
          <div class="col-4">
             <InputDatePicker v-model="entry.startDate" :errors="errors.startDate" @update:modelValue="changeAvgDate(entry.startDate, entry.endDate)" :inActive="this.inActive"/>
          </div>
          <div class="col-1 divTitle text-center">
            <span class="planTitle"> đến </span>
          </div>
          <div class="col-4">
             <InputDatePicker v-model="entry.endDate" :errors="errors.endDate" @update:modelValue="changeAvgDate(entry.startDate, entry.endDate)" :inActive="this.inActive"/>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-lg-12 mx-0 px-2">
            <button class="btn btn-primary" @click="save()" :disabled="this.inActive">
              <i class="fas fa-sync-alt ml-2 text-light"></i>              
              <span class="pl-2">Tính số lượng cần nhập</span>
            </button>
          </div>
        </div>
      </div>
    <!-- Cột 2 -->
      <div class="col-lg-6">
        <!-- Hàng hóa: -->
        <div class="row mb-3">
          <div class="col-3 divTitle">
            <span class="planTitle">Hàng hóa: </span>
          </div>
          <div class="col-9">               
                <q-select name="product" :default-options="product" :multiple="true" v-model="entry.productId" placeholder="Nhập Tên hoặc Mã sản phẩm" :overFlow="true"  @click="loadProduct"
                :readonly="this.inActive"
                />     
          </div>
        </div>
        <!-- Số ngày bán dự kiến -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle">Số ngày bán dự kiến: </span>
            <span class="planTitle text-danger pl-1"> * </span>
          </div>
          <div class="col-9">
              <InputNumber
                v-model="entry.estimatedDay"                
                placeholder="Nhập số ngày bán dự kiến" 
                @change="changeEstimatedDay(entry.estimatedDay)"
                :readonly="this.inActive"
                />
              
          </div>
        </div>
        <!-- Tôn min -->
        <div class="row">
          <div class="col-3 divTitle">
            <span class="planTitle">Tồn min: </span>
          </div>
          <div class="col-9">
            <InputNumber
                v-model="entry.minInventory"                
                placeholder="Nhập số ngày bán dự kiến"
                :readonly="this.inActive" />           
          </div>
        </div>
      </div>
    </div>

    <!-- Bảng dữ liệu -->
    <div class="row my-3">
      <div class="col-lg-12 mx-0 px-0" id="divTable">
        <table class="table flex-table table-striped lstRenderData w-100">
          <thead>
            <tr> 
              <th>{{$t('message.common.STT')}}</th>             
              <th
                scope="col"
                v-for="field in fields"
                :key="field.field"
                class="text-center"                
                >
                <TableSortField    
                  v-if="field.field == 'totalNumberFeature'"             
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.name + ' ' + (this.entry.estimatedDay != 0 ? this.entry.estimatedDay : '...') + ' ngày tới' " 
                  :field="field.field "
                  :class="field.class"
                  :style="field.style" />

                <TableSortField    
                  v-else-if="field.field == 'avgNumberOfSale'"             
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.name + ' ' + (this.avgDays != 0 ? this.avgDays : '...') + ' ngày tới' " 
                  :field="field.field "
                  :class="field.class"
                  :style="field.style" />


                <TableSortField
                  v-else
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.name"
                  :field="field.field"
                  :class="field.class"
                  :style="field.style" />                   
              </th>              
            </tr>
            <tr style="background-color:#f2f2f2"> 
              <th>[1]</th>             
              <th
                scope="col"
                v-for="field in fields"
                :key="field.field"
                class="text-center"                
                >
                <TableSortField    
                  v-if="field.field == 'totalNumberFeature'"             
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.note + (this.entry.estimatedDay != 0 ? this.entry.estimatedDay : '...') + ']' " 
                  :field="field.field "
                  :class="field.class"
                  :style="field.style" />

                <TableSortField    
                  v-else-if="field.field == 'avgNumberOfSale'"             
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.note + (this.avgDays != 0 ? this.avgDays : '...') + ']' " 
                  :field="field.field "
                  :class="field.class"
                  :style="field.style" />


                <TableSortField
                  v-else
                  :current="sortData.field"
                  :no-sort="!sortData.isColumn"
                  @onsort="sort"
                  :name="field.note"
                  :field="field.field"
                  :class="field.class"
                  :style="field.style" />                   
              </th>              
            </tr>
          </thead>
          
          <tbody>
              <template v-for="(entry, index) in entries" :key="entry.id" >                    
                    <tr class="master-row" :style="index % 2 ? 'background-color: white' : null"  :id="'row_' + (index + 1)">                      
                      <td class="text-center" style="display:flex; align-center:center">{{index + 1}}</td>
                      <template v-for="field in fields" :key="field.field">
                      
                        <!-- <td class=" py-0"  style="vertical-align:middle !important;" :style="'min-width:110px'"> -->
                        <td class="py-0">  
                            <div v-if="field.field==='productName'" :style="field.style" class="description text-left">
                              <span class="mr-1" v-text="entry.productName" :style="field.style"  :title="renderField(entry, field.field, field)"></span>
                            </div> 

                            <div v-else-if="field.field==='increaseOrDecrease'" :style="field.style" class="description">                                                             
                              <input class="form-control increaseOrDecrease" type="number" v-model="entry.increaseOrDecrease" v-if="this.inOrDeValue == 0" @change="updateData(entry)" :id="entry.id" :name="entry.id">
                              <span  v-else v-text="entry.increaseOrDecrease" :style="field.style"  :title="renderField(entry, field.field, field)"></span>
                            </div> 
                            <div v-else-if="field.field==='quantityToOrder'" :style="field.style" class="description text-center" >                                
                                <span class="mr-1" v-text="entry.quantityToOrder" :style="field.style"  :title="renderField(entry, field.field, field)"></span>
                            </div> 
                            <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class" class="text-center"></div>
                          
                        </td>                       
                      </template>
                    </tr>                            
              </template>
          </tbody>
        </table>
       <div class="col-lg-12 mx-0 px-0">
          <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
      </div>         
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import { $get, forEach, isEmpty, clone, updateTreeNode } from "@/lib/utils";
import TableSortField from "@/components/TableSortField.vue";
import SelectSearch from "@/components/SelectSearch";
import store from "@/store";
import QTreeSelectInput from "@/components/qtreeselect/QTreeSelectInput";
import emitter from "@/lib/emitter";
import InputDatePicker from '@/components/forms/InputDatePicker';
import moment from 'moment';
import QSelect from '@/components/QSelect';
import toastr from 'toastr';
import Pagination from '@/components/Pagination';
import InputNumber from '@/components/forms/InputNumber.vue';
export default {
  name: "PlansForm",
  components: {
    InputText,
    TableSortField,
    SelectSearch,    
    QTreeSelectInput,
    InputDatePicker,QSelect,
    Pagination,InputNumber
    
  },
  data() {
    return {
      inActive: false,
      entry: {        
        code: null,        
        name: null,
        branchId: store.state.CurrentBranch.id,
        branchName: [],
        categoryId:[],
        startDate: null,
        endDate: null,
        productId: [],
        productName: [],
        estimatedDay: 0,
        minInventory: 0,      
        increaseOrDecrease:0,        
      },
      planId: null,
      paginate: 
      {
        currentPage: 1,
        lastPage: 1
      },
      avgDays: 0,
      numberRecord: 10,
      quantityToOrder: 0,
      product: [],
      inOrDeValue: 0,
      branches: [],
      entries: [],
      fields: [],
      sortData: {
        field: "id",
        direction: "desc",
      },
      categoryTree: [],
      errors: {
        startDate: null,
        endDate: null,
      }
    };
  },
  created() {
    
    this.load();
  },
  methods: {
    changeEstimatedDay(number){
      this.entry.estimatedDay = number;
    },
    async pageChange(page) {                 
        const res = await $get("/v1/plans/details", {page: page, record: this.numberRecord, planId: this.planId});
        if(res && res.status.code == 200){
          this.entries = res.result.data;        
        }
        
    },
    changeAvgDate(startDate, endDate){     
       this.getAvgDate('change', startDate , endDate);
    },
    getAvgDate(type, startDate, endDate){      
      var dif = null;
      switch (type) {
        case 'load':               
          dif= Math.abs(endDate-startDate);      
          this.avgDays = dif/(1000 * 3600 * 24);       
          break;
        case 'change':    
          var first_date = new Date(startDate);
          var sec_date = new Date(endDate);          
          dif= Math.abs(sec_date-first_date);      
          this.avgDays = dif/(1000 * 3600 * 24);       
          break;        
      }
          
    },
    getStartEnd(){
      let date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      var lastDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      this.entry.endDate  = lastDay;
      this.entry.startDate = firstDay;      
      this.getAvgDate('load', this.entry.startDate , this.entry.endDate); 
    },
    async load() {    
      const res = await $get("/v1/plans/get-fields");         
      this.fields = res.result;      
      this.getStartEnd();
      this.getBranch();         
      this.loadTreeCategory();
      await this.loadProduct();         
    },
    async getBranch() {
      const res = await $get("/v1/branches/data-by-user");        
      if (res.status.code == 200 && res.result.length > 0) {
          this.branches = res.result;        
      }
      // this.loadBranchName(this.entry.branchId);        
    },
    async loadTreeCategory() {
        const res = await $get('/v1/categories/getTreeCategories', {});
        await updateTreeNode(res.result.tree)
        this.categoryTree = res.result.tree;
        emitter.emit('loadQTreeSelect', this.categoryTree);
    },

    async loadProduct(){                     
        const res = await $get('/v1/products/get-product-by-category',{categories:  this.entry.categoryId, branchId: this.entry.branchId});
        if(res && res.code == 200) {
            this.product = res.data;
        }
             
    },

    selectBranch(v) {  
      this.entry.branchId = v.model;
        this.branches.forEach(item => {
            if(item.id == v.model) {
                this.entry.branchName = item.name
            }
        });
    },
    getProductName(){       
        let name = [];  
        this.product.forEach(item => {
            this.entry.productId.forEach(value => {
                if(value == item.id) {
                   name.push(item.name)
                }
            });
        });
        return name;
    },
    getListProductID(products){
        let id = [];
        products.forEach(item => {
            id.push(item.id);
        });
        return id;
    },
    resetProductId(){
        this.entry.productId = null;
    },
    async save(){        
        this.entry.startDate = moment(this.entry.startDate).format('YYYY-MM-DD');
        this.entry.endDate = moment(this.entry.endDate).format('YYYY-MM-DD');
        
        if(this.entry.estimatedDay == null) 
        {
            toastr.error('Vui lòng nhập số ngày bán dự kiến');
            return;
        }
        if(isEmpty(this.entry.productId))
        {          
            this.entry.productId = this.getListProductID(this.product);
        }        
        this.entry.productName = this.getProductName();    
        const res = await $get('/v1/plans/create',{...this.entry});             
        if(res && res.status.code == 200 && res.result && res.result.code == 200){
            this.inActive = true;
            this.planId = res.result.data.planId;
            this.entries = res.result.data.data;
            this.paginate = res.result.data.paginate;
            toastr.success('Thêm mới Dự trù hàng nhập thành công');
        }
        else
        {   
            if(res && res.code && res.code === 422) {     
               if(res.messages.errors.code) toastr.error(res.messages.errors.code);
               if(res.messages.errors.name) toastr.error(res.messages.errors.name);           
               if(res.messages.errors.estimatedDay) toastr.error(res.messages.errors.estimatedDay);
               if(res.messages.errors.endDate) toastr.error(res.messages.errors.endDate);
            }
            if(res && res.result && res.result.code == 201){                         
                toastr.error(res.result.message);                 
            }
        }
    },
    updateDataEntries(id, data){
        this.entries.forEach(item => {
            if(item.id == id ){
                item.quantityToOrder = data.quantityToOrder,
                item.increaseOrDecrease = data.increaseOrDecrease
            }
            
        });        
    },
    async updateData(entry){        
        let id = entry.id;
        let increaseOrDecrease = Number(entry.increaseOrDecrease);
        let quantityToOrder = Number(entry.quantityToOrder) + increaseOrDecrease;
        const res = await $get("/v1/plans/update-quantity-to-order", {id: id, quantityToOrder: quantityToOrder, increaseOrDecrease: increaseOrDecrease});                
        if(res && res.status.code == 200 && res.result.code == 200){
            this.updateDataEntries(id, res.result.data);
        }
       
    },
  },
  watch: {
    increaseOrDecrease(newValue){
       this.entry.increaseOrDecrease = newValue;
    },
  }
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/vars.scss";
.divTitle {
  display: flex;
  align-items: center;

  height: 35px;
}
.planTitle {
  font-size: 13px;
  font-weight: 700;
}
table.flex-table thead,table.flex-table tbody {
    width: max-content;
}
#titleReport {
    max-width: max-content;
    border-bottom: 1px solid $base-color;
}
.increaseOrDecrease{
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    background: none;
    text-align: end;
}
.increaseOrDecrease:focus{
    border-bottom: 1px solid $base-color;
}
.lstRenderData{    
    max-height: 400px ;
}
</style>
