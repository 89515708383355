<template>
    <div v-if="this.entries">
        <div class="">
            <h5><b>{{ $t('message.SettingPage.partner.title') }}</b></h5>
        </div>
        <ul class="list-group">
            <template v-for="(entry, index) in this.entries" :key="index">
                <li class="list-group-item" v-if="entry.id != 8 && entry.id != 10">
                    <div class="row d-flex align-items-center justify-content-between">
                        <div class="d-flex col-9 align-items-center">  
                            <i :class="[(entry.icon),(isExistInArrayChecked(entry, this.options) == true ? 'icon-active' : '')]" class="mr-3" style="font-size: 20px;"></i>
                            <div>
                                <h6 class="m-0"><b>{{ entry.name }}</b></h6>
                                <div class="setting-sync-des text-gray">{{ entry.desc }}</div>
                            </div>
                        </div>
                        <div class="col-2 text-right">
                            <button type="button mr-2" @click="showModal(entry.id)" class="btn btn-save" v-if = "entry.id != 11 && entry.id != 9 &&  isExistInArrayChecked(entry, this.options) == true">
                                {{ $t('message.button.detail') }}
                            </button>
                        </div>
                        <div class="col-1 d-flex justify-content-end">
                            <span class="toggle" :class="isExistInArrayChecked(entry, this.options) == true ? 'active' : ''"
                                @click="changeOption(entry.id)"></span>
                        </div>
                    </div>
                </li>
            </template>
        </ul>
    </div>
    <ModalDebt ref="ModalDebt"></ModalDebt>
    <ModalTransporter ref="ModalTransporter"></ModalTransporter>
    <!-- <ModalSocialite ref="ModalSocialite"></ModalSocialite> -->

</template>

<script>
import { $post, $get } from "@/lib/utils";
import ModalDebt from '@/components/settings/modal/ModalDebt.vue';
import ModalTransporter from '@/components/settings/modal/ModalTransporter.vue';
import ModalSocialite from '@/components/settings/modal/ModalSocialite.vue';
import toastr from 'toastr';

import storeModul from "@/storeModul";
export default {
    name: "SettingPartner",
    components: {ModalDebt, ModalSocialite, ModalTransporter},
    props: ['entries'],
    data() {
        return {
            options: []
        }
    },
    methods: {
        async changeOption(id) {
            let status = false;
            let arr = this.options;
            if (arr.includes(id)) {
                arr = arr.filter(item => item !== id)
            } else {
                arr.push(id);
                status = true;
            }
            this.options = arr;

            const result = await $post('/v1/setting-store/update-option', { id: id, status: status });
            if (result.result == true) {
                toastr.success(this.$t('message.SettingPage.success'));
            }
            storeModul.dispatch('settingStore/setEmail', status);
        },
        isExistInArrayChecked(entry, array = []) {
            if (array.includes(entry.id)) {
                return true;
            } else {
                return false;
            }
        },
        showModal (id) {
            if(id == 10){
                this.$refs.ModalDebt.show();
            }else if(id == 35){
                this.$refs.ModalTransporter.show();
            }
        }
    },
    watch: {
        entries: {
            handler: function (newValue) {
                this.options = [];
                newValue.forEach((item) => {
                    if (item && item.status) {
                        this.options.push(item.id);
                    }
                });
            },
            deep: true
        },
    }
}
</script>

<style scoped>
.setting-sync-content .setting-sync-item {
    overflow: unset;
}

.setting-sync-content {
    max-height: calc(100vh - 200px);
    overflow-y: auto;
}

.setting-sync-item {
    background-color: #f1f1f1;
    overflow: hidden;
    margin-top: 1rem;
    padding: 1rem 2rem;
    border-radius: 10px;
}

.toggle {
    background-color: rgba(31, 32, 33, .15);
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 14px;
    width: 51px;
    height: 28px;
    transition: all .2s ease-in-out;
}

.toggle.active {
    background-color: rgb(47, 161, 161);
}

.toggle:before {
    content: "";
    background-color: #fff;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all .2s ease-in-out;
}

.toggle.active:before {
    left: auto;
    right: 2px;
    transition: all .2s ease-in-out;
}

.icon-active {
    color: rgb(47, 161, 161);
}
</style>