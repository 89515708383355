import {forEach, numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
import {clone} from "@/lib/utils";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {                     
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            for (let index = 1; index < dataValue.convertProducts.length; index++) {
                let template = htmlObject.querySelector("#content-product").children[0];
                let clone = template.cloneNode(true);
                clone.children[0].innerHTML = "";
                let node = document.createElement("tbody");
                node.innerHTML = clone.outerHTML;
                htmlObject.querySelector("#content-product").appendChild(node);
            }
            templateOrder = htmlObject.outerHTML;
            dataValue.convertProducts.forEach((product) => {
                product.forEach((data, index) => {
                    templateOrder =
                        templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                        templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                });
            });
            return templateOrder;
         },
        getPriceImportPrint(id, productUnitId, importForSupplier){
            let price = 0;
            if(importForSupplier?.details){
                importForSupplier.details.forEach(detail => {
                    if(detail.productId == id && detail.productUnitId == productUnitId){
                        price = detail.price - (detail.discount / detail.quantity);
                    }
                });
            }
            return price;
        },
        getProduct(entry, type){            
            let data = entry.details; 
            let products = [];
            let discount = 0;
            let Don_Gia_Sau_Chiet_Khau = 0;
            let Chiet_Khau_Phan_Tram = 0;
            data.forEach(prd => {       
                discount = numberFormat(prd.discount/prd.quantity);                
                // if(prd.orderForSupplerId !== null && prd.importForSupplierId == null && prd.returnImportId == null) discount = numberFormat(prd.discount/prd.quantity);
                // if(prd.orderForSupplerId == null && prd.importForSupplierId !== null && prd.returnImportId == null) discount = numberFormat(prd.discount);
                // if(prd.orderForSupplerId == null && prd.importForSupplierId !== null && prd.returnImportId !== null) discount = numberFormat(prd.discount/prd.quantity);                
                // Lấy Chiet_Khau_Phan_Tram
                if(prd.discountType == 1) Chiet_Khau_Phan_Tram = (prd.discount / prd.subTotal) * 100;
                if(prd.discountType == 1) Don_Gia_Sau_Chiet_Khau = (prd.price - prd.discount) * 100;
                let priceImport = 0
                if (entry.import_for_supplier && prd.import_supplier_detail) {
                    priceImport = +prd.import_supplier_detail.price - +(prd.import_supplier_detail.discount ?? 0 )/prd.import_supplier_detail.quantity;
                }
                
                products.push({
                    // Chung
                    Ma_Hang: prd.product.code,                    
                    Ten_Hang_Hoa: prd.product.name,
                    Don_Gia: numberFormat(prd.price),
                    So_Luong: numberFormat(prd.quantity),
                    Thanh_Tien: numberFormat(prd.subTotal),                    
                    //Nhập hàng, Đặt hàng nhập            
                    Chiet_Khau: discount,
                    Giam_Gia: discount,
                    Gia_Tri_Chiet_Khau: discount,
                    Chiet_Khau_Phan_Tram: Chiet_Khau_Phan_Tram,
                    Don_Gia_Sau_Chiet_Khau: Don_Gia_Sau_Chiet_Khau,
                    // Tra hàng
                    Gia_Nhap: numberFormat(priceImport), 
                    Don_Gia_Chiet_Khau:  numberFormat(prd.price-discount),
                    Giam_Gia_Tra_Lai: discount,
                    Don_Vi_Tinh: prd.product_unit ? prd.product_unit.unitName : (prd.unit ? prd.unit.unitName : ''),
                    Thuoc_Tinh_Hang_Hoa: prd?.variant ? prd.variant.name : '',
                    Hoat_Chat: prd.product.activeIngredient ? prd.product.activeIngredient : '',
                    Hang_San_Xuat: prd.product.manufacturer ? prd.product.manufacturer : '',
                    Nuoc_San_Xuat: prd.product.country ? prd.product.country : '',
                    Quy_Cach_Dong_Goi: prd.product.unit ? prd.product.unit : '',
                    Mo_Ta_Hang_Hoa: prd.product.description ? prd.product.description : '',
                    HS_Code: prd.product.hsCode ? prd.product.hsCode : '',
                    Thue_Suat: prd.product.taxRate ? prd.product.taxRate + '%' : '',
                    Nhom_Hang: this.getCategories(prd.product.product_category)?? '',
                    Lo: type !== this.$constant.TypeOrderSuppliers ? (prd.consignment ? prd.consignment.name : '') : '',
                    Han_Su_Dung: prd.consignment ? prd.consignment.expried : '',
                    So_Luong_Lo: numberFormat(prd.quantity),
                    Don_Gia_Tra: numberFormat(prd.price),
                    // Lo: type !== this.$constant.TypeOrderSuppliers ? this.getConsignments(prd.product.consignment) : '',
                    // Han_Su_Dung: this.getExpriedDate(prd.product.consignments),
                    
                    // Tạm thời lấy theo đơn vị đính chính
                    Gia_Chung: prd.product.product_unit_main ? prd.product.product_unit_main.price : 0,
                });
            });                  
            return products;
        },
        getExpriedDate(data){            
            let exDate = '';
            data.forEach(item => {
                if(exDate == '') exDate = item.name + ' ' + moment(item.expried).format('DD/MM/YYYY');
                else exDate = exDate + ', ' + item.name + ' ' + moment(item.expried).format('DD/MM/YYYY');
            });            
            return exDate;
        },
        getCategories(categories){          
          let cat_name = '';          
          categories.forEach(item => {
               if(cat_name == '') cat_name = item.category.name;
               else cat_name = cat_name + ', ' + item.category.name;           
          });
          return cat_name;
        },
        getConsignments(data){
            let con_name = '';          
            data.forEach(item => {
               if(con_name == '') con_name = item.name;
               else con_name = con_name + ', ' + item.name;           
          });          
          return con_name;
        },
        getQuantityProduct(entry){
            let total = 0;
            entry.forEach(item => {
                        total = total + item.quantity;
                    });   
            return total;
        },
        
        async renderData(dataValue){                              
            let templateOrder = this.content;     
            let image = null;                                   
            dataValue.common.forEach(data => {                                                
                switch (data.key) {      
                    case 'Logo_Cua_Hang':
                        image = '<img src="' + data.value + '">';                
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', image)?? '';
                        break;                      
                    case 'Tong_So_Luong_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Tong_Tien_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Giam_Gia_Dat_Hang_Nhap':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Muc_Chi_Phi_Nhap_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Tong_Cong':                        
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Muc_Chi_Phi_Nhap_Hang_Khac':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;         
                    case 'Chi_Phi_Nhap_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng')?? null;
                        break;    
                    case 'Chi_Phi_Nhap_Hang_Khac':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng khác')?? null;
                        break;          
                    default:
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', data.value ) ?? null;
                        break;
                }                  
            });
            return this.formatShowProduct(dataValue, templateOrder);
        },
        
        getStatusName(type, status){
            let  name = '';
            let lstStatus = null;
            switch (type) {
                case 'impForSupplier':                    
                    lstStatus = this.$constant.listStatusImportOrder;
                    break;
                case 'ordForSupplier':
                    lstStatus = this.$constant.listStatusOrderSupplier;
                    break;
                case 'returnForSupplier':
                    lstStatus = this.$constant.listStatusReturnOrderSupplier;
                    break;            
                default:
                    break;
            }            
            lstStatus.forEach(item => {
                if(item.id == status) name = this.$t(item.name);
            });            
            return name;
        },
        getNameTypePayment(entry){            
            let name = '';
            if(entry.cash != 0 ) name = name + 'Tiền mặt'
            if(entry.card != 0) name = name + ', ' + 'Thẻ'
            if(entry.transfer != 0) name = name + ', ' + 'Chuyển khoản'            
            return name;  
        },
        getValueTypePayment(entry){            
            let value = '';
            if(entry.cash !== 0) value = value + numberFormat(entry.cash);
            if(entry.card !== 0) value = value + '; ' +numberFormat( entry.card);
            if(entry.transfer !== 0) value = value + '; ' + numberFormat(entry.transfer);            
            return value;  
        },
        getPaymentForSupplier(entry){            
            let totalPrice = numberFormat((Number(entry.cash) + Number( entry.card) + Number(entry.transfer)) - Number(entry.paymentForSupplier));               
            return totalPrice;  
        },
        async dataPrint(entry) {                 
            let convertProducts = [];
            let products = this.getProduct(entry, entry.typePrint);   
            let branchName =  entry.branch?.name ? entry.branch?.name :  (entry.import_for_supplier?.branch.name?? null);
            let common = [
                {key:'Nguoi_Tao',value: entry.created_by ? entry.created_by.name : null},
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry.branch ? entry.branch.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch ? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_gian_tao_phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ngay_Thang_Nam_Tao', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: ''},
                {key:'Nhan_Vien_Ban_Hang', value: entry.created_by ? entry.created_by.name : ''},
            ];
            let keyOrderSupplier = [];
            switch (entry.typePrint) {
                case this.$constant.TypeOrderSuppliers:
                    keyOrderSupplier = [
                        {key:'Tieu_De_In',value: "PHIẾU ĐẶT HÀNG NHẬP"},
                        {key:'Ma_Dat_Hang_Nhap', value: entry.code?? null},
                        {key:'Nha_Cung_Cap',value: entry.supplier.name?? null},
                        {key:'Cong_Ty_NCC',value: entry.supplier.company?? null},
                        {key:'Ma_So_Thue_NCC',value: entry.supplier.taxCode?? null},
                        {key:'Email_Nha_Cung_Cap',value: entry.supplier.email?? null},
                        {key:'Ghi_Chu_Nha_Cung_Cap',value: entry.supplier.note?? null},
                        {key:'Ma_Nha_Cung_Cap',value: entry.supplier.code?? null},
                        {key:'So_Dien_Thoai_Nha_Cung_Cap',value: entry.supplier.contactNumber?? null},
                        {key:'Dia_Chi_Nha_Cung_Cap',value: entry.supplier.address + (entry.supplier?.ward?.name ? ' - ' +  entry.supplier.ward.name : '') +(entry.supplier?.district?.name ? ' - ' +  entry.supplier.district.name : '')  +( entry.supplier?.province?.name ? ' - ' + entry.supplier.province.name : '')},
                        {key:'Phuong_Xa_Nha_Cung_Cap',value: entry.supplier?.ward?.name ? entry.supplier.ward.name : ''},
                        {key:'Khu_Vuc_Nha_Cung_Cap_QH_TP',value: (entry.supplier?.district?.name ? ' - ' +  entry.supplier.district.name : '')  + ( entry.supplier?.province?.name ? ' - ' + entry.supplier.province.name : '')},                    
                        {key:'Du_Kien_Ngay_Nhap',value: entry.ExpectedDate!== null ? moment(entry.ExpectedDate).format('DD/MM/YYYY') : ''},    
                        {key:'STT',value: '{STT}'},    
                        {key:'Tong_So_Luong_Hang',value: this.getQuantityProduct(entry.details)},
                        {key:'Tong_Tien_Hang', value: entry.totalPrice},                
                        {key:'Giam_Gia_Dat_Hang_Nhap',value: entry.priceDiscount?? 0},                    
                        {key:'Chiet_Khau_Hoa_Don',value:  numberFormat(entry.priceDiscount) ?? 0},                  
                        {key:'Muc_Chi_Phi_Nhap_Hang',value: 0 },
                        {key:'Tong_Cong',value: entry.totalPrice - entry.priceDiscount},
                        {key:'Tong_Cong_Bang_Chu', value: entry.totalPrice && entry.priceDiscount  ? this.toVietnamWord(entry.totalPrice - entry.priceDiscount) : this.toVietnamWord(0)},                            
                        {key:'Tong_Cong',value: entry.totalPrice - entry.priceDiscount},
                        {key:'Muc_Chi_Phi_Nhap_Hang_Khac',value: 0},
                        {key:'Chi_Phi_Nhap_Hang',value: 'Chi phí nhập hàng'},
                        {key:'Chi_Phi_Nhap_Hang_Khac',value: 'Chi phí nhập hàng khác'},
                        {key:'Ghi_Chu',value: entry.note?? ''},
                        {key:'Nguoi_Nhap',value: ''},
                        {key:'Trang_Thai',value: this.getStatusName('ordForSupplier', entry.status)},
                        {key:'Tong_So_Mat_Hang',value: entry.exsitProductIds ? entry.exsitProductIds.length : 0},
                        //Bổ sung thêm
                    ];
                    break;
                case this.$constant.TypePurchaseOrder:
                    keyOrderSupplier = [
                        {key:'Tieu_De_In',value: "PHIẾU NHẬP HÀNG"},
                        {key:'Ma_Nhap_Hang', value: entry.code?? null},
                        {key:'Nha_Cung_Cap',value: entry.supplier.name?? null},
                        {key:'Cong_Ty_NCC',value: entry.supplier.company?? null},
                        {key:'Ma_So_Thue_NCC',value: entry.supplier.taxCode?? null},
                        {key:'Email_Nha_Cung_Cap',value: entry.supplier.email?? null},
                        {key:'Ghi_Chu_Nha_Cung_Cap',value: entry.supplier.note?? null},
                        {key:'Ma_Nha_Cung_Cap',value: entry.supplier.code?? null},
                        {key:'So_Dien_Thoai_Nha_Cung_Cap',value: entry.supplier.contactNumber?? null},
                        {key:'Dia_Chi_Nha_Cung_Cap',value: entry.supplier.address + (entry.supplier?.ward?.name ? ' - ' +  entry.supplier.ward.name : '') +(entry.supplier?.district?.name ? ' - ' +  entry.supplier.district.name : '')  +( entry.supplier?.province?.name ? ' - ' + entry.supplier.province.name : '')},                    
                        {key:'Phuong_Xa_Nha_Cung_Cap',value: entry.supplier?.ward?.name ? entry.supplier.ward.name : ''},
                        {key:'Khu_Vuc_Nha_Cung_Cap_QH_TP',value: (entry.supplier?.district?.name ? ' - ' +  entry.supplier.district.name : '')  + ( entry.supplier?.province?.name ? ' - ' + entry.supplier.province.name : '')},                    
                        {key:'Du_Kien_Ngay_Nhap',value: entry.ExpectedDate!== null ? moment(entry.ExpectedDate).format('DD/MM/YYYY') : ''},    
                        {key:'STT',value: '{STT}'},    
                        {key:'Tong_So_Luong_Hang',value: this.getQuantityProduct(entry.details)},
                        {key:'Tong_So_Mat_Hang', value: entry.exsitProductIds ?  entry.exsitProductIds.length : 0},
                        {key:'Tong_Tien_Hang', value: entry.totalPrice},                
                        {key:'Giam_Gia_Dat_Hang_Nhap',value: entry.priceDiscount?? 0},                    
                        {key:'Chiet_Khau_Hoa_Don',value:  numberFormat(entry.priceDiscount) ?? 0},                  
                        {key:'Muc_Chi_Phi_Nhap_Hang',value: 0 },
                        {key:'Tong_Cong',value: entry.totalPrice - entry.priceDiscount},
                        {key:'Tong_Cong_Bang_Chu', value: entry.totalPrice && entry.priceDiscount  ? this.toVietnamWord(entry.totalPrice - entry.priceDiscount) : this.toVietnamWord(0)},                            
                        {key:'Tong_Cong',value: entry.totalPrice - entry.priceDiscount},
                        {key:'Muc_Chi_Phi_Nhap_Hang_Khac',value: 0},
                        {key:'Chi_Phi_Nhap_Hang',value: 'Chi phí nhập hàng'},
                        {key:'Chi_Phi_Nhap_Hang_Khac',value: 'Chi phí nhập hàng khác'},
                        {key:'Ghi_Chu',value: entry.note?? ''},
                        {key:'Nguoi_Nhap',value: entry.userName?? ''},
                        {key:'Trang_Thai',value: this.getStatusName('impForSupplier', entry.statusId)},   
                        {key:'Ten_Phuong_Thuc_TToan',value: this.getNameTypePayment(entry)?? ''},
                        {key:'Tien_Theo_Phuong_Thuc_TToan',value: this.getValueTypePayment(entry)?? ''},                            
                        {key:'Tien_Da_Tra_NCC',value: entry.paymentForSupplier ? numberFormat(entry.paymentForSupplier) : 0},
                        {key:'Tien_NCC_Tra_Lai',value: this.getPaymentForSupplier(entry) ?? 0},
                        {key:'Tong_So_Luong_Mat_Hang', value: entry.listIdProduct ? entry.listIdProduct.length: 0},
                    ]
                    break;
                case this.$constant.TypeReturnSuppliers:
                        if (entry.typeDiscount) {
                            entry.priceDiscountRatio = entry.priceDiscount/entry.totalPrice;
                        } else {
                            entry.priceDiscountRatio = '';
                        }
                        keyOrderSupplier = [
                            {key:'Tieu_De_In',value: "TRẢ HÀNG NHẬP"},
                            {key:'Ma_Tra_Hang_Nhap', value: entry.code?? null},
                            {key:'Ma_Nhap_Hang', value: (entry?.import_for_supplier && entry.import_for_supplier?.code) ? entry.import_for_supplier.code : null},
                            {key:'Tong_So_Luong',value: this.getQuantityProduct(entry.details)?? 0},
                            {key:'Tong_So_Mat_Hang',value: entry.exsitProductIds ? entry.exsitProductIds.length : 0},
                            {key:'Tong_Tien_Hang_Tra',value: numberFormat(entry.totalPrice)?? 0},
                            {key:'Giam_Gia',value: numberFormat(entry.priceDiscount)?? 0},
                            {key:'Tien_NCC_Tra_Lai',value: numberFormat(entry.totalPrice - entry.priceDiscount) ?? 0},
                            {key:'Nha_Cung_Cap',value: (entry?.supplier && entry.supplier?.name) ? entry.supplier.name : null},
                            {key:'Cong_Ty_NCC',value: (entry?.supplier && entry.supplier?.company) ? entry.supplier.company : null},
                            {key:'Ma_So_Thue_NCC',value: (entry?.supplier && entry.supplier?.taxCode) ? entry.supplier.taxCode : null},
                            {key:'Email_Nha_Cung_Cap',value: (entry?.supplier && entry.supplier?.email) ? entry.supplier.email : null},
                            {key:'Ghi_Chu_Nha_Cung_Cap',value: (entry?.supplier && entry.supplier?.note) ? entry.supplier.note : null},
                            {key:'Ma_Nha_Cung_Cap',value: (entry?.supplier && entry.supplier?.code) ? entry.supplier.code : null},
                            {key:'So_Dien_Thoai_Nha_Cung_Cap',value:(entry?.supplier && entry.supplier?.contactNumber) ?  entry.supplier.contactNumber : null},
                            // {key:'Dia_Chi_Nha_Cung_Cap',value: this.getAddressSupplier(entry)},
                            {key:'Dia_Chi_Nha_Cung_Cap',value: entry.supplier?.adress ? entry.supplier.adress : ''},
                            {key:'Phuong_Xa_Nha_Cung_Cap',value: entry.supplier?.ward?.name ? entry.supplier.ward.name : ''},
                            {key:'Khu_Vuc_Nha_Cung_Cap_QH_TP',value: (entry.supplier?.district?.name ? ' - ' +  entry.supplier.district.name : '')  + ( entry.supplier?.province?.name ? ' - ' + entry.supplier.province.name : '')},                    
                            {key:'STT',value: '{STT}'},                                
                            {key:'Ghi_Chu',value: entry.note?? ''},
                            {key:'Nguoi_Nhap',value: entry.userName?? ''},                            
                            {key:'Tong_Cong_Bang_Chu', value: entry.totalPrice && entry.priceDiscount  ? this.toVietnamWord(entry.totalPrice - entry.priceDiscount) : this.toVietnamWord(0)},                            
                            {key:'Trang_Thai',value: this.getStatusName('returnForSupplier', entry.status)},
                            {key:'Giam_Gia_Tra_Hang_Phan_Tram', value: entry.priceDiscountRatio ? entry.priceDiscountRatio*100 + '%': ''},
                            {key:'Giam_Gia_Tra_Hang', value: numberFormat(entry.priceDiscount)},
                        ]
                       break;
                default:
                    break;
            }                                  
            common.push(...keyOrderSupplier);        
            products.forEach(product => {
                convertProducts.push([
                    {key:'Ma_Hang',value: product.Ma_Hang?? null},
                    {key:'Ten_Hang_Hoa',value: product.Ten_Hang_Hoa?? null},
                    {key:'Don_Gia',value: product.Don_Gia?? null},
                    {key:'So_Luong',value: product.So_Luong?? null},
                    {key:'Thanh_Tien',value: product.Thanh_Tien?? null},
                    {key:'Chiet_Khau',value: product.Chiet_Khau?? null},
                    {key:'Giam_Gia',value: product.Giam_Gia?? null},
                    {key:'Gia_Tri_Chiet_Khau',value: product.Gia_Tri_Chiet_Khau?? null},
                    {key:'Chiet_Khau_Phan_Tram',value: product.Chiet_Khau_Phan_Tram?? null},
                    {key:'Don_Gia_Sau_Chiet_Khau',value: product.Don_Gia_Sau_Chiet_Khau?? null},
                    {key:'Gia_Nhap',value: product.Gia_Nhap?? null},
                    {key:'Don_Gia_Chiet_Khau',value: product.Don_Gia_Chiet_Khau?? null},
                    {key:'Giam_Gia_Tra_Lai',value: product.Giam_Gia_Tra_Lai?? null},
                    {key:'Thuoc_Tinh_Hang_Hoa',value: product.Thuoc_Tinh_Hang_Hoa?? null},
                    {key:'Don_Vi_Tinh',value: product.Don_Vi_Tinh?? null},
                    {key:'Hoat_Chat',value: product.Hoat_Chat?? null},
                    {key:'Hang_San_Xuat',value: product.Hang_San_Xuat?? null},
                    {key:'Nuoc_San_Xuat',value: product.Nuoc_San_Xuat?? null},
                    {key:'Quy_Cach_Dong_Goi',value: product.Quy_Cach_Dong_Goi?? null},
                    {key:'Mo_Ta_Hang_Hoa',value: product.Mo_Ta_Hang_Hoa?? null},
                    {key:'HS_Code',value: product.HS_Code?? null},
                    {key:'Thue_Suat',value: product.Thue_Suat?? null},
                    {key:'Nhom_Hang',value: product.Nhom_Hang?? null},
                    {key:'Lo',value: product.Lo?? null},
                    {key:'Han_Su_Dung',value: product.Han_Su_Dung?? null},
                    {key:'Gia_Chung',value: product.Gia_Chung?? null},
                    {key:'So_Luong_Lo',value: product.So_Luong_Lo ?? null},
                    {key:'Don_Gia_Tra',value: product.Don_Gia_Tra ?? null},
                ])
            });                
            return {common, convertProducts};
        },
        getAddressSupplier(entry){            
            let address = (entry?.supplier && entry.supplier?.company) ? entry.supplier.address + ', ' : null;
            let wardName = (entry?.ward && entry.ward?.name) ? entry.ward.name + ', ' : null;
            let districtName = (entry?.district && entry.district?.name) ? entry.district.name + ', ' : null;
            let provinceName = (entry?.province && entry.province?.name) ? entry.province.name : null;
            let supplierAddress = address + wardName + districtName + provinceName;
            return supplierAddress;
        },
        formatproductPrintCommon(data, type = null) {
            let dataPrint = clone(data);            
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            let exsitProductIds = [];
            let details = dataPrint.details ? dataPrint.details : (dataPrint.products ? dataPrint.products : '');                    
            details.forEach(item => {
                if (!exsitProductIds.includes(item.productId)) {
                    exsitProductIds.push(item.productId);
                }
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }
                let product = clone(item);             
                product.product.name = product.product.name + ' ( ' + product.unit.unitName + ' )';
                if (product.consignment_detail && product.consignment_detail.length) {
                    product.consignment_detail.forEach(detail => { 
                        product.consignment = {};
                        product.consignment.name = detail.consignment.name,
                        product.consignment.expried = detail.consignment.expried,
                        product.quantity = detail.quantity,
                        product.discount = (item.discount/item.quantity) * detail.quantity;
                        if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                            product.subTotal = product.price*detail.quantity;
                        } else {
                            product.subTotal = (product.price*detail.quantity) - product.discount;
                        }
                        totalPrice += +product.subTotal;
                        products.push(clone(product));
                    });
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));
                }                
                if (product && product.plus && type == this.$constant.type_print_single) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            plus.product.consignments = product.product.consignments;
                            plus.consignment_detail.forEach(detail => {
                                plus.consignment = {};
                                plus.consignment.name = detail.consignment.name;
                                plus.consignment.expried = detail.consignment.expried;
                                plus.quantity = detail.quantity;
                                plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                                if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                                    plus.subTotal = plus.price*detail.quantity;
                                } else {
                                    plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                                }
                                totalPrice += +plus.subTotal;
                                products.push(clone(plus));
                            });
                        } else {
                            totalPrice += +plus.subTotal;
                            products.push(clone(plus));
                        }
                    });
                }                   
            });
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            dataPrint.exsitProductIds = exsitProductIds;            
            return dataPrint;
        },
    },
}
  