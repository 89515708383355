<template>

    <div ref="modalCreateTicket" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalCreateTicket" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.modalBankBook.title') }}</h5>
                    <button type="button" class="close" aria-label="Close" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3" >
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <div class="row">
                                <h6 class="text-bold">{{ $t('message.modalBankBook.titleContentLeft') }}</h6>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.applicableObject') }}<span class="text-danger">*</span>:</div>
                                <div class="col-8">
                                    <q-select v-model="entry.objectId" :default-options="objects" :multiple="false" 
                                    :placeholder="$t('message.modalBankBook.placeholder.object')"/>
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.amount') }}<span class="text-danger">*</span>:</div>
                                <div class="col-8">
                                    <InputNumber v-model="entry.amount" class="mb-0" 
                                    :placeholder="$t('message.modalBankBook.placeholder.amount')" 
                                    :formatNumber="true"/>
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.reasonForPayment') }}</div>
                                <div class="col-8">
                                    <input type="text" v-model.trim="entry.reason" 
                                    :placeholder="$t('message.modalBankBook.placeholder.reason')"
                                    class="form-control">
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.employee') }}<span class="text-danger">*</span>:</div>
                                <div class="col-8">
                                    <q-select v-model="entry.userId" :default-options="users" :multiple="false" 
                                    :placeholder="$t('message.modalBankBook.placeholder.employee')"/>
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.method') }}<span class="text-danger">*</span>:</div>
                                <div class="col-8">
                                    <q-select v-model="entry.method" :default-options="listTypes" :multiple="false" 
                                    :placeholder="$t('message.modalBankBook.placeholder.method')"/>
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.note') }}</div>
                                <div class="col-8">
                                    <textarea class="form-control" 
                                        v-model.trim="entry.note" 
                                        :placeholder="$t('message.modalBankBook.placeholder.description')">
                                    </textarea>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="row">
                                <h6 class="text-bold">{{ $t('message.modalBankBook.titleContentRight') }}</h6>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.planningDay') }}</div>
                                <div class="col-8">
                                    <input type="text" v-model="entry.planningDay" class="form-control cursor-ban" readonly="true">
                                </div>
                            </div>
                            <div class="row py-1">
                                <div class="col-4 pl-4 text-bold">{{ $t('message.modalBankBook.documentNumber') }}</div>
                                <div class="col-8">
                                    <input type="text" class="form-control"
                                        v-model.trim="entry.documentNumber"
                                        :placeholder="$t('message.modalBankBook.placeholder.documentNumber')">
                                </div>
                            </div>
                            <!-- <div class="row py-1">
                                <div class="col-4 pl-4 text-bold"></div>
                                <div class="col-8">
                                    <label class="container-checkbox">{{ $t('message.modalBankBook.planning') }}
                                        <input type="checkbox" v-model="entry.statusPlanning" class="chkType">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                                
                            </div> -->
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-lg-12 flex-end">
                            <AppPermission :permission="this.$permissions.bankbooks.create?? '' ">
                                <button type="button" class="btn btn-save mx-1" @click="save">
                                    <i class="fa fa-save"></i>
                                    {{ $t('message.modalCreateTicketForCash.button.save') }}
                                </button>
                                <AppPermission :permission="this.$permissions.bankbooks.print?? '' ">
                                    <button type="button" class="btn btn-save mx-1">
                                        <i class="fa fa-save"></i>
                                        {{ $t('message.modalCreateTicketForCash.button.saveAndPrint') }}
                                    </button>
                                </AppPermission>
                            </AppPermission>
                            <button type="button" class="btn btn-secondary mx-1" @click="skip">
                                <i class="fa fa-ban"></i>
                                {{ $t('message.modalCreateTicketForCash.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm" />
</template>
<script>

import {$post, isChangeData, $alert} from "@/lib/utils";
import toastr from 'toastr';
import InputNumber from "@/components/forms/InputNumberCustom";
import moment from 'moment';
import store from "@/store";
import QSelect from "@/components/QSelect";
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalPrepareReceipt",
    components: {InputNumber, QSelect, ModalConfirm,AppPermission },
    data(){
        return {
            entry: {},
            default: {},
            type: null,
            typeSrc: null,
            users: store.state.GlobalConfig.users,
            currentUser: store.state.Auth ? store.state.Auth.user : {},
            objects: [],
            listTypes: [],
            contentConfirm: null,
            titleConfirm: null,
        }
    },
    methods: {
        show(type, objects, listTypes) {
            this.clearData();
            if(type){
                this.type = type;
                this.typeSrc = type == this.$constant.ticket_receipt_cash_book ? this.$constant.type_ticket_receipt_cash_book : this.$constant.type_ticket_payment_cash_book;
                this.objects = objects;
                this.listTypes= listTypes;
                this.entry.planningDay = moment().format('DD/MM/YYYY');
                this.entry.userId = this.currentUser.id ?? null;
                this.entry.objectId = this.$constant.object_other_bank_book;
                this.default = {...this.entry};
                window.jQuery(this.$refs.modalCreateTicket).modal('show');
            }
        },
        clearData(){
            this.entry = {
                'objectId' : null,
                'amount' : null,
                'reason' : null,
                'userId' : null,
                'note' : null,
                'method': null,
                'planningDay' : null,
                'documentNumber' : null,
                'statusPlanning': false
            };
            this.default = {
                'objectId' : null,
                'amount' : null,
                'reason' : null,
                'userId' : null,
                'note' : null,
                'planningDay' : null,
                'documentNumber' : null,
                'statusPlanning': false
            };
            this.titleConfirm = null;
            this.contentConfirm = null;
        },
        hide(){
            this.clearData();
            window.jQuery(this.$refs.modalCreateTicket).modal('hide');
        },
        skip(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.modalCreateTicketForCash.title_confirm_close');
                this.contentConfirm = this.type == this.$constant.ticket_receipt_cash_book ? this.$t('message.modalCreateTicketForCash.content_confirm_close_receipt') : this.$t('message.modalCreateTicketForCash.content_confirm_close_payment');
                this.$refs.ModalConfirm.show();
            }
        },
        async save(){
            if(!this.entry.typeSrc){
                this.entry.typeSrc = this.typeSrc;
            }
            if(!this.entry.type){
                this.entry.type = this.type;
            }
            if(!this.entry.branchId){
                this.entry.branchId = store.state.CurrentBranch.id;
            }
            const res = await $post('/v1/bank-book/prepare-receipt', {...this.entry});
            if(res.code === 422){
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
            }
            else if(res.status.code == 200){
                const message = this.type == this.$constant.ticket_receipt_cash_book ? this.$t('message.modalCreateTicketForCash.alert.create_receipt_success') : this.$t('message.modalCreateTicketForCash.alert.create_payment_success');
                $alert({code: 200, message: message});
                this.hide();
                this.$emit('updated');
            }
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style>
.action-bar {
    display: none !important;
}
</style>