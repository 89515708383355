
<template>
    <!-- <AppPermission :permission="this.$permissions.settlementAdvance.data?? '' "> -->
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{ $t('message.DiscountsBAS.title') }}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for="">{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Chi nhánh -->
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t('message.DiscountsBAS.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click.stop="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branchId" 
                                    :modelValue="filterQuery.branchId" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.DiscountsBAS.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.DiscountsBAS.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branchId'"/> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Chọn thời gian -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DiscountsBAS.time')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>
                    <!-- Người tạo -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DiscountsBAS.created_by')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                        <i class="fa fa-chevron-up" id="creatorUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.DiscountsBAS.placeholder.created_by')" :modelValue="filterQuery.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Trạng thái -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.DiscountsBAS.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.DiscountsBAS.placeholder.search')" @filterSearch="changeQuickSearch" :discountBasSearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle mr-1" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>{{$t('message.DiscountsBAS.button.action')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click="exSelectOverview()">                                            
                                           <i class="fa fa-file-export" />
                                           <span class="px-2">{{$t('message.DiscountsBAS.button.export_all')}}</span>
                                           
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="exSelectDetails()">
                                           <i class="fa fa-share" aria-hidden="true"></i>
                                             <span class="px-2">{{$t('message.DiscountsBAS.button.export_detail')}}</span>
                                        </a>
                                        <!-- <a class="dropdown-item" @click="sendMailAndZalo()">
                                            <i class="fa fa-share"></i>
                                            {{ $t('message.button.sendMailText') }}
                                        </a> -->
                                    </div>
                                </div>
                                <!-- <AppPermission :permission="this.$permissions.settlementAdvance.create?? '' "> -->
                                    <button class="btn btn-save ml-1" @click="createDiscountBas()">
                                        <i class="fa fa-plus px-1"></i>
                                        {{ $t('message.DiscountsBAS.button.add') }}
                                    </button>
                                <!-- </AppPermission> -->
                                <!-- <AppPermission :permission="this.$permissions.settlementAdvance.export?? '' "> -->                               
                                 <div class="dropdown">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-download px-2" />{{$t('message.DiscountsBAS.button.export')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click="exOverview()">                                            
                                           <i class="fa fa-file-export" />
                                           <span class="px-2">{{$t('message.DiscountsBAS.button.file_all')}}</span>
                                           
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="exDetails()">
                                           <i class="fa fa-share" aria-hidden="true"></i>
                                             <span class="px-2">{{$t('message.DiscountsBAS.button.file_detail')}}</span>
                                        </a>
                                    </div>
                                </div>
                                <!-- </AppPermission> -->
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <tr>
                                                    <td class="bg-light-warning prdChecked"></td>
                                                    <template v-for="field in fields" :key="field.field">
                                                        <td class="ow-break-word py-0 bg-light-warning" v-if="field.selected" style="vertical-align:middle !important;">  
                                                            <div v-if="field.field == 'amount'" v-text="n(this.totalAmount)" class="py-2 text-bold"></div>
                                                            <div v-else class="cell-auto py-2 text-center"></div>
                                                        </td>
                                                    </template>
                                                </tr>
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :class="entry.id === this.requestRefundActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="showDetail(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="requestRefundActive === entry.id" :class="[ entry.id === requestRefundActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2" @click="showContent('info')">
                                                                    <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                        {{$t('message.DiscountsBAS.tabs.info')}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiết phiếu-->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="requestRefundActive === entry.id">
                                                        <td class="border-top-0"></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4 detail__user">
                                                                <div class="row mt-2 detail-prd-row">                                                                    
                                                                    <div class="col-4">
                                                                        <!-- Mã phiếu -->
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t('message.DiscountsBAS.code') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">                                                            
                                                                                <b>{{ entry.code }}</b>
                                                                            </div>
                                                                        </div>
                                                                        <!-- Thời gian tạo -->
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{ $t('message.DiscountsBAS.created_at') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word lableOS pr-0"> 
                                                                                {{ dt(entry.createdAt) }}
                                                                            </div>
                                                                        </div>       
                                                                        
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{ $t('message.DiscountsBAS.created_by') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                {{ entry.createdBy }}                                                                                        
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Kho tạo -->
                                                                    <div class="col-4">
                                                                        <div class="mx-1">
                                                                            <div class="col-12">
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.DiscountsBAS.branch') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        {{ entry.branchName }}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 lableOS">
                                                                                        {{ $t('message.DiscountsBAS.status') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        {{ entry.status }}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 lableOS">
                                                                                        {{ $t('message.DiscountsBAS.supplierCode') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        <a  target="_blank" :href="'/suppliers/index?keyword=' + entry?.supplier?.code">{{ entry?.supplier?.code }}</a>                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 lableOS">
                                                                                        {{ $t('message.DiscountsBAS.supplierName') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        {{ entry.supplier.name}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- Ghi chú -->
                                                                    <div class="col-4" id="noteRO">  
                                                                        <div class="row p-0 font-italic">                                                                          
                                                                            <div class="col-12">
                                                                                <textarea v-model.trim="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.DiscountsBAS.note')" 
                                                                                class="noteStyle px-4 text-gray font-italic" maxlength="255"></textarea>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                            </div>                                                                            
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiet san pham va gia -->                                                    
                                                    <tr class="detail-row bg-white border__body-detail" v-if="requestRefundActive === entry.id">
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <!-- detail__user -->
                                                            <div class="mt-2 pb-4 ">
                                                                <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                                    <div class="col-12 mx-4">
                                                                        <table class="" style="width: 95%;">
                                                                            <thead>
                                                                                <tr style="background-color: #dcf4fc !important;">
                                                                                    <th scope="col" class="pl-4 text-left" v-for="fieldDetail in this.fieldDiscountBasDetails" 
                                                                                    :key="fieldDetail" v-text="fieldDetail.name"></th>
                                                                                </tr>                                                                                
                                                                            </thead>
                                                                            <tbody>             
                                                                                <template v-if="entry.details.length > 0">
                                                                                    <tr class="bg-light-warning">
                                                                                        <td class="px-4" style="backgroud-color: #f8efd3 !important;" colspan="3"><span style="font-weight: bold" v-text="n(entry.amount)"></span></td>                                                                                        
                                                                                    </tr>
                                                                                    <tr v-for="detail in entry.details" :key="detail.id">
                                                                                        <td class="px-4"><span v-text="n(detail.amount)"></span></td>
                                                                                        <td class="px-4"><span v-text="detail.content"></span></td>
                                                                                        <td class="px-4"><span v-text="detail.deductionLimit"></span></td>
                                                                                    </tr>
                                                                                </template>                                                                                                                                                  
                                                                                <template v-else>
                                                                                    <tr class="text-center bg-light">
                                                                                        <td :colspan="fieldDiscountBasDetails.length">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                                    </div>
                                                                </div>                                                               
                                                                <!-- Hàng button -->
                                                                <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                                    <!-- <template v-if="entry.status !== this.$constant.status_cancel_order_supplier"> -->
                                                                        <!-- Nút lưu nhanh -->
                                                                        <button type="button" class="btn btn-save mx-1"  @click.stop="quickSave(entry)">
                                                                            <i class="fa fa-save"></i>
                                                                            {{ $t('message.DiscountsBAS.button.save') }}
                                                                        </button>
                                                                        <!-- Nút mở phiếu -->                                                                        
                                                                        <button type="button" class="btn btn-save mx-1" @click.stop="updateDiscountBas(entry.id)" 
                                                                            v-if="entry.statusOrigin == this.$constant.discount_bas_status_draft">
                                                                            <i class="fa fa-save"></i>
                                                                            {{ $t('message.DiscountsBAS.button.open') }}
                                                                        </button>
                                                                        <button type="button" class="btn btn-danger mx-1" @click.stop="deleteDiscountBas(entry.id, entry.code)"
                                                                            v-if="entry.statusOrigin == this.$constant.discount_bas_status_draft">
                                                                            <i class="fa fa-trash"></i>
                                                                            {{ $t('message.DiscountsBAS.button.delete') }}
                                                                        </button>                                                                        
                                                                        <!-- Nút print -->
                                                                        <button type="button" class="btn btn-secondary mx-1" @click.stop="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print"></i>
                                                                            {{ $t('message.DiscountsBAS.button.print') }}
                                                                        </button>
                                                                        <!-- Nút xuất file -->
                                                                        <button type="button" class="btn btn-save mx-1" @click.stop="exportOrderSupplierById(entry.id)">
                                                                            <i class="fa fa-save"></i>
                                                                            {{ $t('message.DiscountsBAS.button.export') }}
                                                                        </button>
                                                                        <!-- Nút hủy -->
                                                                        <button type="button" class="btn btn-danger mx-1" @click.stop="cancelDiscountBas(entry.id, entry.code)"
                                                                            v-if="entry.statusOrigin == this.$constant.discount_bas_status_draft ||  entry.statusOrigin == this.$constant.discount_bas_status_no_payment">
                                                                            <i class="fa fa-times"></i>
                                                                            {{ $t('message.DiscountsBAS.button.cancel') }}
                                                                        </button>
                                                                    <!-- </template>  -->
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>

                                                    <tr class="detail-row bg-white border__footer-detail" v-if="requestRefundActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @updateContent="updateContent"/>
        <PrintTemplate ref="PrintTemplate" :editorDataClone="this.editorDataClone"/>
        <ModalConfirm ref="ModalConfirm" @confirmCancel="confirmCancel"  @confirmDelete="confirmDelete" :title="$t('message.common.confirm')" :content="this.contentCancel"/>
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
    <!-- </AppPermission> -->
</template>

<script>
    import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import http from '@/services/api';
    import TableSortField from "@/components/TableSortField";
    import CreateReturn from "@/views/returns/CreateReturn";
    import emitter from "@/lib/emitter";
    import store from "../../store";
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import router from "@/router/index";
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import SelectField from "../../components/SelectField";    
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalConfirm from '@/components/ModalConfirm.vue';
    import exportMixin from '@/exportMixin';
    
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    // import printTransactionMixin from "@/printTransactionMixin";
    import discountBasMixin from '@/discountBasMixin'
    import PrintTemplate from '@/components/PrintTemplate.vue';
    import QSelect from "@/components/QSelect";
    export default {
        name: "SettlementAdvanceIndex",
        mixins: [
        discountBasMixin, exportMixin],
        // printTransactionMixin,         
        components: {
            CreateReturn, 
            TableSortField, 
            Pagination, 
            SelectSearch, 
            SelectDateTime, 
            InputQuickSearch, 
            InputSelectSearch, 
            ModalAddInfoSaleChannels, 
            SelectField,
            ModalSelectPrintTemplate,
            PrintTemplate,
            AppPermission,
            ModalConfirm,
            ModalSelectSendMailAndZaloTemplate,
            QSelect
        },
        mounted() {
            document.title = this.$t('message.DiscountsBAS.document_title');
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['code'] = query['code'];
                this.loadDetail();
            }else{
                this.clearFilter();
            }
        },
        data() {
            const query = getSearchQuery();            
            const globalConfig = store.state.GlobalConfig;
            return {
                id: null,
                action: null,
                exEntries:[],
                filter: {
                    supplier_code_name: '',
                    discount_bas_code: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth ? store.state.Auth.user : {},
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    branchId: [store.state.CurrentBranch.id],
                    createdBy: '',
                    // type: [],
                    // receiverBy: '',
                    // saleChannel: '',
                    // paymentMethod: '',
                    // priceList: '',
                },
                currentBranchId: store.state.CurrentBranch.id,
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                receiverBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                createdBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                priceLists: [],
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                users: globalConfig.users,
                arrayChecked: [],
                loading: false,
                requestRefundActive: null,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                defaultSettlementAdvance:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                fieldDiscountBasDetails : [
                    {
                        name: this.$t('message.DiscountsBAS.table.amount'),
                        field: 'amount',
                        selected: true,
                    },
                    {
                        name: this.$t('message.DiscountsBAS.table.content'),
                        field: 'content',
                        selected: true,
                    },
                    {
                        name: this.$t('message.DiscountsBAS.table.deductionLimit'),
                        field: 'deductionLimit',
                        selected: true,
                    },
                ],
                listStatus: [],
                listTypeReturn: [],
                receiverByEdit: null,
                headerActive: null,
                contentCancel: null,
                isFilter: false,
                totalAmount: 0,
            }
        },
        methods: {
            async updateFilterBranch(values) {
                this.filterQuery.branchId = [...values];
                this.filterQuery.page = 1;
                this.isFilter = true;
                await this.load();
            },
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabDiscountBas, this.$constant.TypePrintDiscountBas, this.arrayChecked);
            },          
            showModalAddInfoSaleChannels(){
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            async printMultiple(ids = null) {
                this.editorDataClone = '';
                const res = await $post('/v1/discounts-bas/data', {
                    id: ids ?? this.arrayChecked,
                });
                if (res.result && res.result.length) {
                    await this.getTemplatePrint(res.result);
                }
            },
            async print(entry) { 
                entry.typePrint = this.$constant.TypePrintDiscountBas;                
                entry.soldById = this.user.id;
                entry.soldByName = this.user.id;
                let dataPrint = this.dataPrint(entry); 
                this.editorDataClone = this.renderData(dataPrint);                
                this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);   
            },
            async getTemplatePrint(entry) {                 
                let action = this.$constant.TabDiscountBas;
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: action});                 
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){
                this.content = data.content;
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {
                    await this.print(data.dataPrint);          
                }           
            },
            async startPrintMultiple(returns) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < returns.length; index++) {
                    let order = returns[index];
                    order.typePrint = this.$constant.TypePrintSettlementAdvance;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint);
                    if (index != returns.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.PrintTemplate.multiplePrint(this.editorDataClone); 
            },

            async clearFilter() {
                await this.$router.replace({
                    path: '/discount-bas/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
               
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        //Gán giá trị mặc định
                        // this.filterQuery[key] = [this.$constant.discount_bas_status_success_payment, this.$constant.discount_bas_status_draft, this.$constant.discount_bas_status_no_payment];
                        this.filterQuery[key] = [this.$constant.discount_bas_status_draft];
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearQuickSearch");
                this.filterQuery.branchId = [this.currentBranchId];
                await emitter.emit("resetSelectDateTime", 'month');
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.requestRefundActive = null;
                this.isFilter = false;
                await this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            isExistInArrayChecked(entry, array = []){                
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);                
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            showDetail(id, entry){
                if (id === this.requestRefundActive) {
                    this.requestRefundActive = null;
                } else {
                    this.requestRefundActive = id;
                }
                if(entry && entry.details){
                    entry.details.forEach(detail => {
                        let isEmptyInvoice = true;
                        let totalAmount = 0;
                        if(detail.src && detail.src.invoice_suppliers){
                            detail.src.invoice_suppliers.forEach(invoice => {
                                totalAmount += +invoice.amountForInvoice;
                                if(isEmptyInvoice && invoice.code && invoice.billDate && (+invoice.amountForInvoice)){
                                    isEmptyInvoice = false;
                                }
                            });
                        }
                        detail.totalAmountForInvoice = totalAmount;
                    });
                }
                this.headerActive = 'info';
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else if(field == 'type'){
                    if(query['type'].includes(value)){
                        query['type'] = query['type'].filter(item => item !== value)
                    }else{
                        query['type'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/discounts-bas/get-data-discount-bas', {...this.filterQuery, record: this.numberRecord});
                
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }

                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                if (res.result.data.totalAmount) {
                    this.totalAmount = res.result.data.totalAmount;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branchId = [this.currentBranchId];
                this.requestRefundActive = null;
                await this.load();
                this.entries.forEach((entry) => {
                    this.showDetail(entry.id, entry);
                });
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            showContent(name) {
                this.headerActive = name;
            },
            changeCreatedBy(v){
                this.entries.forEach(entry => {
                    if(('createdBy_' + entry.id) == v.label){
                        entry.createdByOrigin = v.model;
                    }
                });
            },
            async quickSave(entry){
                const res = await $post('/v1/discounts-bas/quick-save', {
                    id: entry.id,                    
                    note: entry.note,
                });
                if(res && res?.status && res.status.code == 200 && res.result){
                    $alert({
                        code: 200,
                        message: this.$t('message.common.updateBASSuccess', {code: entry.code}),
                    });
                    this.load();
                }else{
                    $alert({
                        code: 403,
                        message: this.$t('message.common.updateBASFailed', {code: entry.code}),
                    });
                }
            },
            createDiscountBas(){
                this.$router.replace({
                    name: 'DiscountsBasCreate',
                    query: {
                        type: 'create',
                    },                    
                });
            },
            updateDiscountBas(discountBasID){                            
                this.$router.push({name: 'DiscountsBasUpdate', query: {
                    type: 'update',
                    id: discountBasID,
                }})
            },    
            async confirmCancel() {
                const res = await $post('/v1/discounts-bas/cancel', {
                    id: this.id,
                });                
                if (res && res.result) {
                    $alert({code: 200, message: this.$t('message.SettlementAdvanceIndex.alertCancelSuccess')});
                    this.load()
                } else {
                    $alert({code: 422, message: this.$t('message.common.cancelBASFailed')});
                }
                this.id = null;
            },   
            async confirmDelete() {                
                const res = await $post('/v1/discounts-bas/delete', {
                    id: this.id,
                });                
                if (res && res.result) {
                    $alert({code: 200, message: this.$t('message.common.removeBASSuccess')});
                    this.load()
                } else {
                    $alert({code: 422, message: this.$t('message.common.removeBASFailed')});
                }
                this.id = null;
            },  
            deleteDiscountBas(id, code){
                this.id = id;
                this.contentCancel = this.$t('message.common.confirmRemoveBAS', {code: code});
                this.$refs.ModalConfirm.show(this.$constant.deleteDiscountBas);
            },
            cancelDiscountBas(id, code)
            {                
                this.id = id;
                this.contentCancel = this.$t('message.common.confirmCancelBAS', {code: code});
                this.$refs.ModalConfirm.show(this.$constant.cancelDiscountBas);
            },
            // Xuất file 
            async exportOrderSupplierById(id){
                emitter.emit("onLoading");   
                const res = await http.download('/v1/discounts-bas/exOSById/' + id, {...this.fields, branchID: this.currentBranchId});                
                this.returnDataExport(res);  
            },      
            async exSelectOverview() {                
                emitter.emit("onLoading");    
                let id = this.arrayChecked;                            
                const res = await http.download('/v1/discounts-bas/exOverview', {fields:this.fields, id:id, branchID: this.currentBranchId});              
                this.returnDataExport(res);  
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");  
                let id = this.arrayChecked;                
                const res = await http.download('/v1/discounts-bas/exDetails', {id:id, branchID: this.currentBranchId});
                this.returnDataExport(res);
            },
            async exOverview() {                
                emitter.emit("onLoading");
                const res = await http.download('/v1/discounts-bas/exOverview', {fields:this.fields, branchID: this.currentBranchId, ...this.filterQuery});
                this.returnDataExport(res);     
            },  

            async exDetails() {                
                emitter.emit("onLoading");                            
                const res = await http.download('/v1/discounts-bas/exDetails', {branchID: this.currentBranchId, ...this.filterQuery});
                this.returnDataExport(res);          
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 90% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
    text-align:justify;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.detail__user table thead tr th, .detail__user table tbody tr td{
    background: #fff !important;
    /* background: #b1ddf0 !important; */
    
}

.cell-check{
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}
.cell-name{
    width: 232px;
    min-width: 232px;
    max-width: 232px;
}
.cell-auto{
    width: 160px;
    min-width: 160px;
    max-width: 160px;
}
.cell-large{
    width: 480px;
    min-width: 480px;
    max-width: 480px;
}
</style>