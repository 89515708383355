<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-md" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header my-0 py-1">                    
                    <span style="font-size:18px; font-weight:400px;" v-if="type && type =='edit'" class="modal-title py-2">
                        {{ $t('message.common.updatePaper') }}
                    </span>
                    <span style="font-size:18px; font-weight:400px;" v-if="type && type =='add'" class="modal-title py-2">
                        {{ $t('message.common.addPaper') }}
                    </span>
                    <button type="button" class="close"  aria-label="Close" @click="hide()">
                        <!-- data-dismiss="modal" -->
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="row">
                     <div class="col-lg-12 mb-2">
                          <span> {{ $t('message.common.printTemplateName') }}: </span>
                          <input class="py-2" type="text" v-model="entry.name" id="inputPrintPaperName" :placeholder="$t('message.common.enterPrintTemplateName')" @blur="changeName()">         
                    </div>               
                    </div>
                    <div class="row my-2 text-right">
                        <div class="col-12">
                            <button class="btn btn-primary mr-2" @click="save()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.button.save') }}
                            </button>
                            <button class="btn btn-secondary text-white"  aria-label="Close" @click="hide()">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.cancel')}}
                            </button>
                        </div>
                    </div>

                    
                </div>
        </div>
    </div>
</div>
</template>

<script>
    import { $get } from "@/lib/utils";
    import toastr from 'toastr';
    export default {
  props: {
  },
        name: 'ModalPrintPaper',
        data() {
            return {
                type: null,
                entry: {
                    id: null,
                    name: null,
                },
                
            }
        },
        methods: {
            changeName(){ 
                let newName = this.entry.name;
                this.entry.name = newName;
            },
            async load(){               
               const res = await $get(`/v1/print-templates/get-data-print-paper`,{id: this.entry.id});
               if(res && res.status.code == 200) {
                    if(this.entry.id !== null) {
                       this.entry.name = res.result.name;
                    }
                    else {
                        this.entry = res.result;
                    }
                }
            },
            show(id, type) {                          
                if(id) {
                    this.entry.id = id;                                
                    this.load();
                }
                else {
                    this.entry.id = null;
                    this.entry.name = null;
                }
                this.type = type;                                
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide(){
                window.jQuery(this.$refs.modal).modal('hide');
            },
            async save() {                
                // Kiểm tra dữ liệu nhập
                if(this.entry.name == ''){                 
                     toastr.error(this.$t('message.common.emptyPaper'));
                     return;
                }              
                
                if(this.entry.id !== '' && this.entry.id !== null) {                                        
                    const res = await $get(`/v1/print-templates/get-save-print-paper`,{id: this.entry.id, name: this.entry.name});
                     if(res && res.status.code == 200 && res.result.code == 200){
                         toastr.success(res.result.message);
                         this.$emit('loadData');
                         this.hide();
                    }
                    else {
                         toastr.error(res.result.message);
                    }        
                }
                else {                    
                    const res = await $get(`/v1/print-templates/get-save-print-paper`,{name: this.entry.name});
                    if(res && res.status.code == 200 && res.result.code == 200){
                         toastr.success(res.result.message);
                         this.$emit('loadData');
                         this.hide();
                    }
                    else {
                         toastr.error(res.result.message);
                    }                    
                }                
            }

            
        },
        mounted() {
            // this.load();
        },
    }
</script>
<style scoped>
    #inputPrintPaperName {
        width:100%;
        border: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0 ;
    }
</style>