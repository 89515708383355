<template>
    <div ref="ModalBankCancel" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="ModalBankCancel">
        <div class="modal-dialog modal-xs" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.modalCashCancel.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h6 style="line-height:30px;">{{ $t('message.modalCashCancel.content', {code:this.code}) }}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click.stop="cancel()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.modalCashCancel.btnSubmit') }}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                                <i class="fa fa-ban mr-1"></i> 
                                {{ $t('message.modalCashCancel.btnAbort') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';

export default {
    name: "ModalBankCancel",
    components: {},
    data(){
        return {
            id:null,
            code:null,
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(id, code) {
            if(id != null){
                this.id = id;
                this.code = code;
            }
            window.jQuery(this.$refs.ModalBankCancel).modal('show');
        },
        async cancel(){ 
            const res = await $post('/v1/bank-book/cancel', {id: this.id});                    
            if(res.status.code == 200 && res.result){
                if(res.result.code && res.result.code == 201){
                    toastr.error(res.result.message);
                    // this.$emit('updateContent','1');
                    // this.hide();
                }
                else{                    
                    toastr.success(this.$t('message.modalCashCancel.messageSuccess', {code: this.code}));
                    this.$emit('loadData');
                    this.hide();                    
                }

            }else{                
                toastr.error(this.$t('message.modalCashCancel.messageError', {code: this.code}));
                this.$emit('loadData');
                this.hide();
            }
               
        },
        hide(){
            window.jQuery(this.$refs.ModalBankCancel).modal('hide');
        },
    }
}
</script>

