<template>
    <!-- <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalError">     -->
    <div class="mx-0" id="modalError" v-if="this.errMsgShow">
        <div class="modal-dialog modal-xl" role="document" :style="this.position">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc; background-color: #2fa1a1;">  
                        <label id="headerText" class="modal-title py-2">{{this.title? this.title : $t('message.common.processingUpload')}}</label>
                        <div id="zoom">
                            <!-- Dấu ẩn popup -->
                            <a class="text-white px-2" v-if="this.msgStatus === true" href="javascript:;" rel="noopener noreferrer" @click="minimize(true)">
                                    <i class="fa fa-angle-down fa-lg" aria-hidden="true"></i>                                          
                            </a>
                            <a  class="text-white px-2" v-else href="javascript:;" rel="noopener noreferrer" @click="minimize(false)">                                    
                                    <i class="fa fa-angle-up fa-lg" aria-hidden="true"></i>   
                            </a>
                            <!-- Dấu zoom popup -->
                            <a  class="text-white px-2" v-if="this.startPosition == 0" href="javascript:;" rel="noopener noreferrer" @click="fullScreen()">
                                    <i  class="fa fa-arrows-alt fa-md" aria-hidden="true"></i>                                                       
                            </a>   
                            <!-- Dấu mini -->
                            <a class="text-white" v-else href="javascript:;" rel="noopener noreferrer" @click="notFullScreen()">                            
                                <i  class="fa fa-expand fa-md px-2" aria-hidden="true"></i>
                            </a>  
                        </div>
                        <a class="text-white pt-1" href="javascript:;" rel="noopener noreferrer" @click="hide()" >                            
                            <i class="fa fa-times fa-md" aria-hidden="true"></i>
                        </a>                      
                      
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0 h-50 overflow-y-auto overflow-x-hidden" :class="this.isFullScreen ? 'body-error-msg' : ''" :id="this.msgStyle">
                    <div class="row" v-if="this.errors && this.code == 422">
                        <div class="col-lg-1"> 
                            <i class="fas fa-file-import"></i>
                        </div>   
                        <div class="col-lg-10">
                                {{ $t('message.common.uploadFile') }}  {{ this.fileName? this.fileName : '' }}                 
                        </div>
                        
                        <div class="col-lg-1">
                            <template v-if="this.code === 200">
                                <i class="fa fa-check text-success" aria-hidden="true"></i>
                            </template>
                            <template v-else>
                                <i class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>    
                            </template>                                                    
                        </div>
                        <div class="col-lg-12 pt-2 mx-2">
                        </div>
                                          
                        <div class="col-lg-12 px-4 pt-2">                            
                            <template v-for="(error, index) in this.errors" :key="index">
                                <label id="message" >{{$t('message.common.row')}} {{ index }}: </label>
                                <p v-for="(msg, key) in error" :key="key" id="message" >{{ msg.message }}</p>
                            </template>
                        </div>
                    </div>
                    <div class="row" v-else>
                        <div class="col-lg-1"> 
                            <i class="fas fa-file-import"></i>
                        </div>                   
                        <div class="col-lg-10">                          
                            {{ $t('message.common.uploadFile') }} {{ this.fileName? this.fileName :'' }}
                        </div>
                        <div class="col-lg-1">
                            <template v-if="this.code === 200">
                                <i  class="fa fa-check text-success" aria-hidden="true"></i>
                            </template>
                            <template v-else>
                                <i   class="fa fa-exclamation-triangle text-danger" aria-hidden="true"></i>    
                            </template>
                        </div>
                        <div class="col-lg-12 pt-2 mx-2">
                           
                        </div>   
                        <div class="col-lg-12 pt-2">
                            <label class="px-4" id="msgSuccess" v-if="this.data.length > 0" >{{ $t('message.common.uploadFileSuccess') }}</label>                            
                            <label class="px-4" v-else> 
                                {{ $t('message.common.emptyUploadFile') }}
                            </label>                            
                        </div>
                    </div>
                </div>                
            </div>
        </div>
    </div>
</template>
<style scoped>
#minimize {
    display:none;
}
#maxnimize {
    display:block;
}
#headerText{
    font-size: 16px !important;
    font-weight: 700 !important;
    color: #fff !important;
}
#message {
    color:red !important;
    font-size: 13px !important;
    margin-bottom: 0 !important;
}
#zoom{
    position: absolute;
    right: 40px;
    top:10px;
}
#msgSuccess{
    color:rgb(91 168 91);
}
</style>
<script>
import emitter from "@/lib/emitter";
export default {
    name: "ModalErrorsMessageImportVoucher",    
    // props: ['errMsgShow'],
    data() {
        return {            
            message:"",
            errors: [],
            position:"width: 400px;position: fixed; bottom: 0; right: 0; z-index:9999",
            startPosition: 0,
            code: 200,
            title: '',
            numberError: 10,
            isFullScreen: false,
            msgStyle: "maxnimize",
            msgStatus: true,
            totalRecord: 0,
            totalAddnew: 0,
            totalUpdate: 0,
            totalError: 0,
            errMsgShow: false,
            fileName: '',
            data: []
        }
    },
    methods:{
        minimize(status) {
            if(status == true) 
            {
                this.msgStyle = "minimize" ;
                this.msgStatus = false;
            }
            else {
                this.msgStyle = "maxnimize";
                this.msgStatus = true;
            }
        },
        async show(content) {                 
            this.errMessage = content.errors;
    
            const body = document.querySelector("body"); 
            body.style.overflow = "hidden";                            
            window.jQuery(this.$refs.modal).modal("show");      
        },
        async hide(){
            const body = document.querySelector("body"); 
            body.style.overflow = "auto";
            this.errMsgShow = false;
            //Reset data
            this.title = "";
            this.code = "";
            this.message = "";
            this.errMessage = "";
            this.totalRecord = 0;
            this.totalAddnew = 0;
            this.totalUpdate = 0;
            this.totalError = 0;
        },
        fullScreen(){            
            this.position = "width: 600px;position: fixed;top:25%; bottom: 25%; left:25%; right: 25%;z-index:500";
            this.isFullScreen = true;
            this.startPosition = 1;
        },
        notFullScreen(){            
            this.position = "width: 400px;position: fixed; bottom: 0; right: 0; z-index:500";
            this.isFullScreen = false;
            this.startPosition = 0;
        }
    },
    mounted(){
        emitter.on("errMsgShow", status => {
            this.errMsgShow = status;
        });
        emitter.on("dataMsgShow", content => {
            if (content.code == 422) {
                this.code = 422;
                this.errors = content.errors;
            }
            if (content.code == 200) {
                this.errors = [];
                this.code = 200
                this.data = content.data;  
            }  
        });
        emitter.on('fileName', name => {
          this.fileName = name
        });
        
        emitter.emit("position", pos => { 
            alert(pos);
            this.position = `width: 400px;position: absolute; bottom: ${pos}; right: ${pos};`;
        });
    }
    
}
</script>