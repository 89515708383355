<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <div class="d-flex">
            <div v-if="showAdjust" class="adjust-block">
                <button type="button" @click.prevent="decr()" class="adjust-block__button"><i class="fas fa-minus text-gray"></i></button>
            </div>

            <div class="flex-1" :class="showAdjust ? 'border-bottom-1' : ''">
                <input :readonly="!!readonly" ref="input" v-model="model" @input="onInput(true)"
                       :class="[(errors && errors.length ? 'error-input': ''), (cardTransaction ? 'border-bottom-1 text-right rounded-0' : ''), 
                       (bgTransparent ? 'bg-transparent' : ''), (inputTable ? 'format__input' : ''), (notBorder ? 'border__none': ''), 
                       (textLeft ? 'text-left pl-0 border-bottom-1 rounded-0': ''), (showAdjust ? 'border-0 mw-100' : ''), (inputMarginAuto ? 'm-0 mw-100' : ''), 
                       (paddingRightNone ? 'pr-0' : ''),
                       (borderActive ? 'border-bottom-focus-active' : ''),
                       (wStyle ? 'wStyle' : ''),
                       (pxNone ? 'px-0' : '')]"
                       class="form-control" :placeholder="placeholder || label"
                       :maxlength="this.maxLength ? this.maxLength : ''" 
                       @change="changeData"                      
                       >
            </div>
            <div v-if="showAdjust" class="adjust-block">
                <button type="button" @click.prevent="incr()"  class="adjust-block__button">   <i class="fas fa-plus text-gray"></i></button>
            </div>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import {numberFormat, parseIntEx, $alert} from "../../lib/utils";

const MAX_VALUE_DEFAULT = 1000000000;

export default {
    name: "InputNumberFormat",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'min', 'max', 'readonly', 'showAdjust','formatNumber', 'cardTransaction', 
    'inputTable', 'notBorder', 'bgTransparent', 'textLeft', 'inputMarginAuto', 'maxLength', 'paddingRightNone', 'borderActive', 'pxNone', 'noAlert'],
    data() {
        return {            
            model: this.formatNumber ? numberFormat(parseFloat(this.modelValue).toFixed(2),2) : numberFormat(parseIntEx(this.modelValue)),          
            minValue : this.min !== undefined ? (this.formatNumber ? parseFloat(this.min) : parseIntEx(this.min)) : false,
            maxValue : this.max !== undefined ? (this.formatNumber ? parseFloat(this.max) : parseIntEx(this.max)) : MAX_VALUE_DEFAULT,
        }
    },
    watch: {
        modelValue: function (newValue) {
            if(this.formatNumber){                
                this.model = parseFloat(newValue);
            }
            else{                
                this.model = new Intl.NumberFormat('en-US').format(parseIntEx(newValue));
                
            }
        },
        max: function (newVal) {       
            if(this.formatNumber){
                this.maxValue = newVal !== undefined ? parseFloat(newVal) : MAX_VALUE_DEFAULT
            }else{
                this.maxValue = newVal !== undefined ? parseIntEx(newVal) : MAX_VALUE_DEFAULT
            }       
        }
    },
    mounted() {
        // let value = parseIntEx(this.model);
        // this.$emit('update:modelValue', value);
    },
    methods: {
        changeData(){
            this.$emit('changeData');
        },
        decr() {
            this.model = parseIntEx(this.model) - 1;
            this.onInput();
        },
        incr() {
            this.model = parseIntEx(this.model) + 1;
            this.onInput();
        },
        onInput() {
            let $input = this.$refs.input;
            let start = $input.selectionStart + 1,
                end = $input.selectionEnd + 1;

            let value = parseIntEx(this.model);

            if (this.minValue !== false) {
                if (value < this.minValue) {
                    value = this.minValue;
                    if(!this.noAlert){
                        $alert({code: 422, message: this.$t('message.common.cannotBeSmaller') + ": " + this.minValue});
                    }
                }
            }

            if (this.maxValue !== false) {
                if (value > this.maxValue) {
                    value = this.maxValue;
                    if(!this.noAlert){
                        $alert({code: 422, message: this.$t('message.common.cannotBeLarger') + ": " + this.maxValue.toLocaleString('en-En')})
                    }
                }
            }
            this.model = numberFormat(value);            
            this.$emit('update:modelValue', value);            
            this.$nextTick(() => {
                this.$emit('input', value);
                $input.setSelectionRange(start, end)
            });
            
        },
    }
}
</script>
<style>
.adjust-block .fa-sort-up {
    height: 2px;
}
.adjust-block {
    font-size: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
}

.adjust-block__button, .adjust-block__button:hover, .adjust-block__button:focus {
    border: 1px solid #ccc;
    background-color: white;
    border-radius: 50%;
    outline:0;
}
</style>
<style scoped>
    .form-group{
        position: relative;
    }
    .error-label{
        position: absolute;
    }
    
    .format__input {
        background: none;
        height: 100%;
        border-bottom: 1px solid #C7C5C5!important;
        border-radius: 0;
        padding-top: 0 !important;
    }
    .border__none{
        border: none;
        text-align: right;
    }
    .danger_fail input {
      color: red !important;
      border-bottom: 1px solid red !important ;
    }
    .wStyle{
        width: 174px !important;
    }        
    .sale-input-number .form-control {
            max-width: 10rem !important;
    }
</style>

