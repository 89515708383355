<template>
    <div class="row mt-3">
        <div class="col-lg-12" >
            <table class="table__mini">
                <thead>
                    <tr>
                        <th>{{ $t('message.ProductIndex.table.batch.batch_number') }}</th>
                        <th>{{ $t('message.ProductIndex.table.batch.expired') }}</th>
                        <th colspan="3" class="text-center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                    </tr>
                </thead>
                <tbody>
                <tr >
                    <td ></td>
                    <td ></td>
                    <th class="text-center" v-for="pu in productUnits" :key="pu.id" v-text="pu.unitName"></th>
                </tr>

                <tr v-for="iv in entries" :key="iv.id">
                    <td v-text="iv.product_batch?.name"></td>
                    <td v-text="d(iv.product_batch?.expireDate)"></td>
                    <td class="text-center" v-for="pu in productUnits" :key="pu.id" v-text="iv.qty/pu.conversionValue"></td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-4">
            <Paginate :value="paginate" @change="pageChange"/>
        </div>
    </div>
</template>

<script>
import Paginate from "../../../components/Paginate";
import {$get} from "../../../lib/utils";

export default {
    name: "ProductFormTabBatches",
    components: {Paginate},
    data() {
        return {
            entry: {},
            paginate: {currentPage: 1, lastPage: 1},
            productUnits: [],
            isDataLoaded: true,
            entries: [],
        }
    },
    watch: {
        entry: function (newValue) {
            this.load(newValue)
        }
    },
    mounted() {

    },
    methods: {
        pageChange(page) {            
            this.load(page);
        },
        async load (page = 1) {
            const res = await $get('/v1/product-batches/inventory-data', {
                productId: this.entry.id,
                page
            });

            this.entries = res.data.inventories;
            this.paginate = res.data.paginate;
        }
    }
}
</script>

<style scoped>

</style>
