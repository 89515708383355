<template>
    <div >
      <div class="container mt-0">
        <div class="row mt-4">
            <div class="col-12">
                <a href="/sale-online/e-commerce/list" class="text-primary text-decoration-none"><b>{{$t('message.common.linkToECommerce')}}</b></a><i class="fa fa-chevron-right ml-2 cursor-pointer text-secondary mr-2"></i>{{$t('message.common.addStall')}}
            </div>
        </div>
        <div class="row mt-4 d-flex align-items-center setting-tabs">
            <div class="col-4 active align-items-center d-flex setting-tab-item setting-tab1">
                <span class="item-count d-flex align-items-center justify-content-center">1</span>
                {{ $t('message.common.linkToStall') }}
            </div>
            <div class="col-4 align-items-center d-flex setting-tab-item">
                <span class="item-count d-flex align-items-center justify-content-center">2</span>
                {{ $t('message.common.linkProductToOmipos') }}
            </div>
            <div class="col-4 align-items-center d-flex setting-tab-item">
                <span class="item-count d-flex align-items-center justify-content-center">3</span>
                {{ $t('message.common.settingSync') }}
            </div>
        </div>
        <div class="row mt-4 setting-step-block">
            <div class="col-4 setting-step-content">
                <div class="setting-step-title">{{ $t('message.common.linkToStall') }}</div>
                <div class="setting-step-des">{{$t('message.common.loginStallToOmpos', {stall: this.element})}}</div>
                <button class="btn btn-lg btn-success btn-width-180 justify-content-center" @click="loginShop()">{{ $t('message.LoginScreen.submit') }}</button>
                <div class="setting-step-register mb-3">{{ $t('message.common.noAccount') }} {{this.element}}?<a :href="this.type == this.$constant.textTiktok ? 'https://seller-vn.tiktok.com/account/register' : 'javascript:void(0)'"> {{ $t('message.common.register') }}</a></div>
            </div>
            <div class="col-8">
                <div class="setting-step-image">
                    <img src="../../../public/img/banner-step1.svg" alt=""/>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    import {$get} from "../../lib/utils";
    export default {
        name: "LoginECommerce",
        components: {},
        // mounted() {
            
        // },
        created() {
            this.getType();
        },
        data() {
            return {
                type: null,
                code: null,
                shop_id: null,
                element: null,
                redirect_url: process.env.VUE_APP_REDIRECT_URL,
                client_id: process.env.VUE_APP_LAZADA_APP_KEY,
            }
        },
        methods: {
            getType() {
                this.type = this.$route.query.type;
                switch (this.type) {
                    case this.$constant.textShopee:
                        this.element = 'Shopee';
                        break;
                    case this.$constant.textLazada:
                        this.element = 'Lazada';
                        break;
                    case this.$constant.textTiki:
                        this.element = 'Tiki';
                        break;
                    case this.$constant.textTiktok:
                        this.element = 'Tiktok';
                        break;
                    case this.$constant.textSendo:
                        this.element = 'Sendo';
                        break;
                }   
            },
            async loginShop() {
                if (this.type == this.$constant.type_channel.shopee) {
                    const res = await $get('/v1/shopee/get-url-form-Login');
                    if (res && res.result) {
                        window.open(res.result);
                    }
                } else if (this.type == this.$constant.type_channel.lazada) {
                    const url = 'https://auth.lazada.com/oauth/authorize?client_id=' + this.client_id + '&redirect_uri=' + this.redirect_url + '/sale-online/e-commerce/list?type=lazada&response_type=code&force_auth=true';
                    window.open(url)
                } else if (this.type == this.$constant.type_channel.tiktok) {
                    const res = await $get('/v1/tiktok/get-url-login');
                    if (res && res.result) {
                        window.open(res.result);
                    }
                }else if (this.type == this.$constant.type_channel.tiki) {
                    const res = await $get('/v1/tiki/get-url-login');
                    if (res && res.result) {
                        window.open(res.result);
                    }
                }
            },
        }
    }
</script>
<style scoped lang="scss">
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.sidebar-item {
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}
.sidebar-item.active{
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.mainRight{
    padding: 1.2rem 3rem;
}
.table-content{
    background-color: #f5f6f8;
    border: 1px solid #d7d9de;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* border-bottom-right-radius: 10px; */
    /* border-bottom-left-radius: 10px; */
    padding:0px !important;
}
.table thead tr th, tbody tr td{
    border-top: none !important;
    text-align: center;
}
.table tbody tr td{
    background-color: #fff !important;
}
.connectLogo {
    width: 110px;
    height: 36px;
    object-fit: contain;
} 
.btn-add-connection-tiktok{
    color: #fff;
    border-color: #5245e5;
    background-color: #5245e5;
}
.btn-add-connection-lazada{
    color: #fff;
    border-color: #000083;
    background-color: #000083;
}
.btn-add-connection-tiki{
    color: #fff;
    border-color: #1ba9ff;
    background-color: #1ba9ff;
}
.setting-tabs {
    background-color: #f1f3f4;
    overflow: hidden;
    margin-top: 1.1rem;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top-right-radius: 5px;
    height: 40px;

}
.setting-tab-item.active {
    background-color: #cfeedc;
}
.setting-tab-item {
    color: #000;
    padding: 0.75rem 1rem;
    position: relative;
    cursor: pointer;
    // width: 30%;
}
.setting-tab-item:before {
    opacity: 0;
    visibility: hidden;
    left: 0;
}
.setting-tab-item .item-count {
    color: rgba(31,32,33,.86);
    font-weight: 600;
    background-color: #fff;
    margin-right: 0.7rem;
    width: 18px;
    height: 18px;
    border-radius: 999px;
}
.setting-tab-item:hover, .setting-tab-item:nth-child(2).active:hover {
    background-color: #cfeedc;
}
.setting-tab-item:after, .setting-tab-item:before {
    content: "";
    background: url(../../../public/img/setting-tab-icon.png) no-repeat;
    background-position: 0 0;
    position: absolute;
    top: 0;
    width: 20px;
    height: 40px;
}
.setting-tab-item.active:after {
    background-position: 0 -50px;
}
.setting-tab-item:after {
    right: -1px;
}
.setting-tab-item:hover:nth-child(2):before {
    opacity: 1;
    visibility: visible;
    left: -19px;
    background-position: 0 -150px;
}
.setting-tab-item:hover:nth-child(2):after {
    background-position: 0 -50px;
}
.setting-tab-item:hover:nth-child(3):before {
    opacity: 1;
    visibility: visible;
    left: -19px;
    background-position: 0 -100px;
}
.setting-tab-item:hover:nth-child(3):after, .setting-tab-item:nth-child(3):after {
    background: none !important;
}
.setting-step-block {
    padding-top: 2.4rem;
    padding-left: 10rem;
    margin-top: 3.2rem;
    flex: 1;
    overflow: hidden;
    background-color: #f2f9fd;
    border-radius: 10px;
}
.setting-step-title {
    font-size: 2rem;
    line-height: 39px;
    font-weight: 800;
    color: #24ac60;
    font-style: italic;
}
.setting-step-des {
    color: #000;
    font-size: 0.8rem;
    margin-bottom: 3rem;
    margin-top: 0.75rem;
}
.setting-step-register {
    margin-top: 1rem;
}
</style>