// import http from '@/services/http';
// import apiEndpoints from '@/services/apiEndpoints';
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
// import router from '@/router';
const getDefaultState = () => {
    return {
        orderIds: [],
    };
};
const state = getDefaultState();
const getters = {  
    orderIds (state) {
        let orderIds = localStorage.getItem('orderIds');
        return JSON.parse(orderIds);
    },
};
const mutations = {   
    SET_ORDERS_ID (state, data) {
        state.orderIds = data;
        localStorage.setItem('orderIds', JSON.stringify(data))
    },
};
const actions = {  
    async setOrderIds({commit}, data) {
        commit('SET_ORDERS_ID', data);
    },
    
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
