<template>
  <div class="row productBarcode__item_jewelry" :style="{height: this.height + 'mm', width: this.width + 'mm'}">
    <div class="col-6 col-units">
      <div class="productBarcode__name "  v-if="item.nameOptions != 0" style="font-weight: 700;padding-bottom:20px; font-size:13px;"> {{item.branchName ? item.branchName : 'Omipharma'}}</div>   
      <!-- Price + currency + unit -->
      <div v-if="this.status.priceOptions !=0" class="productBarcode__info py-2">                    
          <label style="font-size:11px;">{{item.prdPrice ? n( item.prdPrice ) : '' }} </label>
          <label v-if="this.status.currentOptions !=0" class="pl-1">{{item.prdCurrency ? item.prdCurrency : '' }}</label>
          <label v-if="this.status.unitOptions !=0">{{item.unitName ? '/' + item.unitName : '' }}</label>
      </div>        
    </div>
    <div class="col-6">
      <!-- Product Name + Barcode -->                           
      <div class="productBarcode__name"> {{item.prdName ? item.prdName : ''}}</div>
      <BarcodeGenerator
          :value="item.prdCode"
          :width="1"
          :height="30"
          :fontSize="12"
          :margin="6"
          >
      </BarcodeGenerator>
    </div>
  </div> 
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator";

export default {
  name: "JewelryBarcode",
  created() {},
  props: {
    item: {},
    products: {},
    status: {},
    number: null,
    width: null,
    height: null,
  },
  components: { BarcodeGenerator },
  data() {
    return {};
  },
  methods: {},
  watch: {},
};
</script>
