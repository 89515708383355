<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <textarea v-model="model" @input="onInput(true)"
                  :readonly="!!readonly"
                  :class="[(errors && errors.length ? 'error-input': '' ), (pencil ? 'px-4' : '')]"
                  :id="customerStyle? 'customerStyle' : ''"
                  class="form-control"
                  :style="height ? 'height: ' + height : ''"
                  :placeholder="placeholder || label"
                  :rows="this.rowNote? this.rowNote : 2"  
                  :maxlength="this.max"                
                  >                  
        </textarea>
        <i class="fa fa-pencil-alt text-secondary" aria-hidden="true" :class="customerStyle ? 'penStyle' : null " v-if="pencil"></i>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "InputTextArea",
        props: ['label', 'placeholder', 'modelValue', 'errors', 'readonly', 'height','customerStyle','rowNote', 'max', 'pencil'],
        data() {
            return {
                model: this.modelValue
            }
        },
        watch: {
            modelValue: function (newValue) {
                this.model = newValue;
            }
        },
        methods: {
            onInput() {
                this.$emit('update:modelValue', this.model)

            }
        }
    }
</script>

<style>
    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-weight: bolder;
        color: red;
    }

    .error-input, .error-input:hover,.error-input:focus,.error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
    #customerStyle {
        /* width: 280px;*/
        height: 100px; 
        border: 0;
        /* border-left: 1px solid #ccc; */
        border-radius: 0;
        border-bottom: 1px solid #ccc;
        
    }
    .penStyle {
        position: absolute;
        top: 10px;
    }
</style>
