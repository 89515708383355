<template>
    <div class="row">
        <ActionBar back-url="/coupons/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Thêm/Sửa mã coupon</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.code"
                                       :errors="errors.code"
                                       label="Mã chương trình"/>
                            <InputText v-model="entry.codePrefix"
                                       :errors="errors.codePrefix"
                                       label="Tiền tố mã KM"/>
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên chương trình"/>
                            <InputNumberFormat v-model="entry.discountValue"
                                       :errors="errors.discountValue"
                                       label="Mệnh giá"/>

                            <InputNumberFormat v-model="entry.minInvoiceAmount"
                                       :errors="errors.minInvoiceAmount"
                                       label="Đơn hàng tối thiểu"/>
                        </div>
                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">

                            <InputSwitchButton v-model="entry.allowManyCoupons"
                                               :errors="errors.allowManyCoupons"
                                               label="Áp dụng đồng thời nhiều coupon cho 1 hóa đơn"/>
                            <InputSwitchButton v-model="entry.status"
                                               :errors="errors.status"
                                               label="Trạng thái"/>
                            <InputDatePicker :timepicker="true" v-model="entry.startTime"
                                       :errors="errors.startTime"
                                       label="Thời gian bắt đầu áp dụng"/>
                            <InputDatePicker :timepicker="true" v-model="entry.endTime"
                                       :errors="errors.endTime"
                                       label="Thời gian kết thúc áp dụng"/>
                        </div>
                    </div>

                </div>
            </div>



            <div class="row mt-2">
                <div>
                    <h5>Quản lý mã coupon</h5>
                </div>
                <div class="col-lg-12">
                    <button type="button" class="btn btn-primary" @click="openModal()">
                        <i class="fa fa-list"/>
                        Quản lý mã
                    </button>
                    <CouponCodeModal ref="couponCodeModal" :coupon-id="entry.id"/>
                </div>
            </div>


            <div class="row mt-2">
                <div class="col-lg-12">
                    <div>
                        <h5>Phạm vi áp dụng</h5>
                    </div>
                    <div>
                        <fieldset>
                            <legend>Đối tượng khách hàng</legend>
                        </fieldset>
                        <div class="form-radio-group">
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForCustomerGroup" value="0"> Toàn bộ khách hàng
                                </label>
                            </div>
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForCustomerGroup" value="1"> Nhóm khách hàng
                                </label>
                            </div>
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForCustomerGroup" value="2"> Khách lẻ
                                </label>
                            </div>
                        </div>
                        <div v-if="entry.applyForCustomerGroup==2">
                            <QSelect datasource="Customers" v-model="applyTo.customerIDs" :multiple="true"/>
                        </div>
                    </div>

                    <hr>
                    <div class="application">
                        <fieldset>
                            <legend>Chi nhánh áp dụng</legend>
                        </fieldset>
                        <div class="form-radio-group">
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForBranch" value="0"> Toàn bộ chi nhánh
                                </label>
                            </div>
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForBranch" value="1"> Chọn chi nhánh
                                </label>
                            </div>
                        </div>
                        <div v-if="entry.applyForBranch==1">
                            <QSelect datasource="branches" v-model="applyTo.branchIDs" :multiple="true"/>
                        </div>
                    </div>
                    <hr>
                    <div class="application">
                        <fieldset>
                            <legend>{{$t('message.customerIndex.sellerName')}}</legend>
                        </fieldset>
                        <div class="form-radio-group">
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForSale" value="0"> Toàn bộ người bán
                                </label>
                            </div>
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForSale" value="1"> Chọn người bán
                                </label>
                            </div>
                        </div>
                        <div v-if="entry.applyForSale==1">
                            <QSelect datasource="Sales" v-model="applyTo.saleIDs" :multiple="true"/>
                        </div>

                    </div>
                    <hr>
                    <div class="application">
                        <fieldset>
                            <legend>Kênh bán</legend>
                        </fieldset>
                        <div class="form-radio-group">
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForSaleChannel" value="0"> Toàn bộ kênh bán
                                </label>
                            </div>
                            <div class="form-radio-group__item">
                                <label>
                                    <input type="radio" v-model="entry.applyForSaleChannel" value="1"> Chọn kênh bán
                                </label>
                            </div>
                        </div>
                        <div v-if="entry.applyForSaleChannel==1">
                            <QSelect datasource="SaleChannels" v-model="applyTo.saleChannelIDs" :multiple="true"/>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import ActionBar from "@/components/ActionBar";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import InputSwitchButton from "../../components/forms/InputSwitchButton";
import QSelect from "../../components/QSelect";
import InputDatePicker from "../../components/forms/InputDatePicker";
import InputNumberFormat from "../../components/forms/InputNumberFormat";
import CouponCodeModal from "./CouponCodeModal";

export default {
    name: "CouponForm",
    components: {CouponCodeModal, InputNumberFormat, InputDatePicker, QSelect, ActionBar, InputText, InputSwitchButton},
    data() {
        return {
            isDataLoaded: false,
            applyTo: {
                customerIDs: [],
                saleIDs: [],
                saleChannelIDs: [],
                branchIDs: []
            },
            entry: {},
            errors: {}
        }
    },
    mounted() {
        document.title = 'CouponForm | OmiPos';
        if (this.$route.query.id) {
            this.load();
        } else {
            this.isDataLoaded = true;
        }

    },
    methods: {
        async load() {
            const res = await $get('/v1/coupons/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.applyTo = res.data.applyTo;
            this.isDataLoaded = true;
        },
        openModal() {
            if (!this.entry.id) {
                alert('Vui lòng lưu coupon trước khi quản lý mã');
                return;
            }
            this.$refs.couponCodeModal.show(() => {})
        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/coupons/save', {
                entry: this.entry,
                applyTo: this.applyTo
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        }
    }
}
</script>

<style scoped>

</style>
