<template>
    <div ref="modal" class="modal fade mx-0" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 style="fotn" class="modal-title py-2">
                        {{$t('message.SupplierGroupIndex.titleUpdate')}}
                    </h5>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{$t('message.SupplierGroupIndex.name')}}<label class="text-danger px-0">*</label> </b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="entry.name" :placeholder="$t('message.SupplierGroupIndex.enterName')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{$t('message.SupplierGroupIndex.note')}}</b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <textarea class="form-control" name="" v-model="entry.note" id="" cols="30" rows="7" :placeholder="$t('message.SupplierGroupIndex.note')"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <AppPermission :permission="this.$permissions.supplierGroups.update ? this.$permissions.supplierGroups.update : ''">
                                        <button type="button" class="btn btn-save mr-2" @click="update(entry.id)" :disabled="requestData">
                                            <i class="fa fa-save mr-1"></i>
                                            {{ $t('message.button.save') }}
                                        </button>
                                    </AppPermission>
                                        <button type="button" class="btn btn-secondary text-white mr-2"
                                            @click="closeModal">
                                            <i class="fa fa-ban mr-1"></i>
                                            {{ $t('message.button.skip') }}
                                        </button>
                                    <AppPermission :permission="this.$permissions.supplierGroups.remove ? this.$permissions.supplierGroups.remove : ''">
                                        <button type="button" class="btn btn-danger mr-2" @click="removeCustomerGroup(entry.id,entry.name)">
                                            <i class="fa fa-trash-alt mr-1"></i>
                                            {{ $t('message.button.remove') }}
                                        </button>
                                    </AppPermission>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>      
    </div>
    <ModalRemoveSuppliersGroup ref="ModalRemoveSuppliersGroup" @input="hide"/>
    <ModalCancelEditSuppliersGroup ref="ModalCancelEditSuppliersGroup" @inputData="hide"/>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
</template>
<script>
// import InputText from "@/components/forms/InputText";
import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import toastr from 'toastr';
import ModalRemoveSuppliersGroup from '@/components/supplierl/ModalRemoveSuppliersGroup';
import ModalCancelEditSuppliersGroup from '@/components/supplierl/ModalCancelEditSuppliuersGroup';
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '../AppPermission.vue';
export default {
    name: "ModalEditSuppliersGroup",
    components: { ModalRemoveSuppliersGroup, ModalCancelEditSuppliersGroup, ModalConfirm, AppPermission},
    props: {
        customerGroupDetail: {
            type: Object,
            default: () => ({})
    }
},
    data () {
        return {
            active: 1,
            form: {
                'name': '',
                'note': '',
                'discount_by': 1,
                'price': ''
            },
            errors: {},
            entry: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            requestData: false,
        }
    },
    methods: {
        async show(id = null) {
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
            }
        },
        hide () {
            this.requestData = false;
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('input','1');
            this.$emit('inputData', '1');
        },
        async load(id = null) {
            const res = await $get(`/v1/supplier-groups/show?id=${id}`)
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            this.entry = res.data.entry;
        },
        removeCustomerGroup (id,name)
        {
          this.$refs.ModalRemoveSuppliersGroup.show(id, name);
        },
        async update(id) 
        {
            this.requestData = true;
            let params ={
                entry:this.entry,
                id:id
            }
            const data = await $post(`/v1/supplier-groups/update`, params);
            if (data.code != 200) {
                this.requestData = false;
                this.errors = data.errors;
                scrollToElement('.error-label');                
                $alert(data);
            }
            else if(data.code === 200){
              $alert(data);              
              this.hide();
            }
           
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.SupplierGroupIndex.confirm');
                this.contentConfirm = this.$t('message.SupplierGroupIndex.contentCancelEdit');
                this.$refs.ModalConfirm.show();
            }
            
        },
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
</style>