<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">Xác nhận</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4">
                    Hệ thống sẽ xóa hoàn toàn người dùng này
                    <div class="col-12 d-flex justify-content-end pt-2">
                        <button class="btn btn-danger mr-2" @click="deleteData"><i
                                class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close"><i
                                class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $get } from "@/lib/utils";
import emitter from "@/lib/emitter";
import toastr from 'toastr';
export default {
    name: 'ModalRemoveBranch',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData() {
            const data = await $get(`/v1/users/delete/${this.id}`);
            const message = `Xóa người dùng ${this.name} thành công`;
            toastr.success(message);
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('updated', this.id)
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>