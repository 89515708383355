<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title m-2">
                        {{ $t('message.ModalImportDepartment.title') }}
                    </h6>

                        <button type="button" class="close" @click="resetStatus" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                        </button>
                </div>
            
            
                <div class="modal-body pt-0 px-3">
                    <div class="form-check m-2 p-0 pb-3 border-bottom-1">
                    <span class="" style="font-weight: 400;"> ({{$t('message.ImportExcel.defaultFile')}}
                        <a target="_blank" :href="'../phong_ban_' + this.language + '.xlsx'" class="text-primary">{{$t('message.ModalFileUploadProducts.titleExcel')}}</a>):
                    </span> 
                    </div>
                    <!-- <div class="form-check m-2 pb-3 pt-2 mt-4 warning-content">
                        <div class="row">
                        <div class="col-12">
                            <b>
                                <i class="fa fa-exclamation-triangle mr-2"></i>
                                <span class="font-italic">{{$t('message.LocationIndex.note')}}</span>
                            </b>
                        </div>
                        </div>
                        <div class="row mt-2">
                        <div class="col-12 font-italic">
                            {{ $t('message.common.maxLengthVoucher') }}
                        </div>
                        </div>
                    </div> -->
                    <div class="row my-3 mx-2">
                        <div class="col-12 text-right mx-0 px-0" id="divUpload">
                            <label class="custom-file-upload">
                                <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" />
                                <i class="fa fa-cloud-upload"></i> 
                                {{ $t('message.ImportExcel.chooseFile') }}
                            </label>
                        </div>
                    </div>
                </div>

                <div class="row py-2 mx-2" id="rowMessage" v-if="this.actionShow">
                <div class="col-6">
                    <label>{{ this.fileName }}</label>
                </div>
                <div class="col-6 text-danger text-right">
                    <a href="javascript:;" @click="showAction(false)" class="text-danger">
                    <i class="fa fa-times"></i>
                    </a>
                </div>
                </div>
                <div class="alert alert-danger row py-2 m-2" v-show="this.messageFile">
                {{ this.messageFile }}
                </div>
                <div class="row mt-3 mx-2">
                <!-- v-if="this.actionShow" -->
                <div class="col-12 text-right px-0 mx-0 mb-3" v-if="this.showBtn">
                    <input type="button" class="btn btn-primary" :value="$t('message.ImportExcel.action')" id="btnAction" @click="UpdateFile(this.patient)" />
                </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
    import { $post, $upload} from "@/lib/utils";
    import toastr from 'toastr';
    import emitter from "@/lib/emitter";
    import store from "@/store";
    export default {
        name: "ImportDepartment",
        props: {},
        data () {
            return {
            actionShow: false,
            showBtn: false,
            fileName: "",
            patient: {
                file: [],
                branchID: '',
                statusEmail: false,
                statusUpdate: false,
                id: '',
            },
            errors: [],
            messageFile: '',
            loadingaa:false,
            id: '',
            language: localStorage.getItem('language') ?? 'vi'
            }
        },
    methods: {
        show(id) {
            this.id = id;
            this.showAction(false);
            this.showBtn = false;
            window.jQuery(this.$refs.modal).modal('show');
        },
        showAction(status) {
            this.actionShow = status;
            this.showBtn = status;
            if (status == false) {
            this.$refs.fileupload.value = '';
            this.messageFile ='';
            }
        },
        onFileChange(event) {   
            this.patient = {
            statusEmail: this.patient.statusEmail,
            statusUpdate: this.patient.statusUpdate,
            file: "",
            id: this.id
            };
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) {  
            return;
            }
            let id = document.getElementById('branchDropdown');
            // this.patient.branchID = id.getAttribute('data-id'); 
            this.patient.branchID = store.state.CurrentBranch.id;
            this.fileName = files[0].name;
            this.patient.file = files[0];
        
            if(files[0].name == "" ) {
            this.actionShow = false;  
            this.messageFile ='';       
            }
            else {
            this.actionShow = true;
            this.messageFile ='';
            }
            if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
            this.showBtn = true;
            this.messageFile ='';
            }else {
            this.showBtn = false;
            this.messageFile = this.$t('message.common.inValidFormatFile')
            }   
            // this.$refs.fileupload.value = null;
        },
        async UpdateFile(file) {
            this.loadingaa = true;
            const result = await $upload('/v1/departments/import', [], file);
            if (result.code == 200) {
                emitter.emit("errMsgShow", true);
                emitter.emit("dataMsgShow", result);
                emitter.emit("fileName", this.fileName);
                this.$emit('input','1');
                window.jQuery(this.$refs.modal).modal("hide");
                this.loadingaa = false;
            }
            if (result.code == 422) {
                emitter.emit("errMsgShow", true);
                emitter.emit("dataMsgShow", result);
                emitter.emit("fileName", this.fileName);
                window.jQuery(this.$refs.modal).modal("hide");
                this.loadingaa = false;
            }
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    #loadingaa {
        position: fixed;
        top:25%;
        left:45%;
        z-index: 100;
    }
    input[type="file"] {
        display: none;
    }
    
    #btnAction{
        width: 100px;
    }
    #rowMessage{
        background-color: rgb(224 241 241);
        border-radius: 5px;
    
    }
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-flex;
        padding: 6px 12px;
        cursor: pointer;
        background: $base-color;
        color: #ccffff;
        margin-left: 5px;
        height: 40px;
        border-radius: 5px;
        align-items: center;
    }
    
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .modal-dialog {
        max-width: 800px !important;
    }
    
    .container-circle {
        position: relative;
    }
    
    .icon-circle-custom {
        cursor: pointer;
    }
    
    .content-circle {
        display: none;
        position: absolute;
        font-size: 13px;
        width: 310px;
        border: 1px solid $base-color;
        padding: 5px;
        background-color: #ffffff;
        top: 0;
        left: 45px;
        z-index: 1;
        border-radius: 5px;
    }
    
    .content-circle:before {
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent $base-color transparent transparent;
        position: absolute;
        left: -16px;
    }
    
    .icon-circle-custom:hover+.content-circle {
        display: block;
    }
    
    .status-circle {
        display: none;
        position: absolute;
        font-size: 13px;
        width: 310px;
        border: 1px solid $base-color;
        padding: 5px;
        background-color: #ffffff;
        top: -5px;
        left: 257px;
        z-index: 1;
        border-radius: 5px;
        line-height: 25px;
        text-align: justify;
    }
    
    .status-circle:before {
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent $base-color transparent transparent;
        position: absolute;
        left: -16px;
    
    }
    
    .icon-circle-custom:hover+.status-circle {
        display: block;
    }
    .warning-content {
        background-color: #f9f9e0;
        border-color: #faebcc;
        color: #7b5e2a;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid transparent;
        font-style: italic;
    }
</style>