<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog"  aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        {{$t('message.permissions.titleUpdate')}}
                    </h5>
                </div>
                <div class="modal-body">
                    {{$t('message.common.noPermission')}}
                    <a href="/">{{$t('message.common.backToHome')}}</a>
                    <div>
                        {{$t('message.common.needPermission')}}:
                        <ul>
                            <li v-for="p in requiredPermissions" v-text="p" :key="p"></li>
                        </ul>
                    </div>

                </div>
                <div class="modal-footer">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";


export default {
    name: "ModalPermissionRequired",
    mounted() {
        this.modal =   window.jQuery(this.$refs.modal);
    },
    watch: {
        showPermissionRequired: function (newValue) {
            if (newValue) {
                this.show();
            } else {
                this.hide();
            }
        },
    },
    computed: {
        showPermissionRequired: () =>  store.state.ShowPermissionRequired,
        requiredPermissions: () => store.state.RequiredPermissions
    },
    methods: {
        show() {
            this.modal.modal({
                backdrop: 'static', keyboard: false
            })
        },
        hide() {
            this.modal .modal('hide')
        }
    }
}
</script>

<style scoped>

</style>
