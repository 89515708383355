<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 700px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">Báo cáo chuyển hàng</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                                <label>Chọn ngày xuất báo cáo chuyển hàng</label>
                                <input-date-range-picker v-model="filter.date"></input-date-range-picker>
                            </div>
                        </div>
                        <div class="col-lg-6 col-sm-12">
                            <div class="form-group">
                                <label>Chọn chi nhánh</label>
                                <q-select datasource="branchesIndex" :multiple="true" v-model="filter.branch_ids" ></q-select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-right mt-4 d-flex">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="onClose">
                            <i class="fa fa-ban"></i>&nbsp;Bỏ qua
                        </button>
                        <export-excel
                            :fetch  = "exportExcel"
                            :fields = "excelData.fields"
                            :name   = "excelData.fileName">
                            <button type="button" class="btn btn-save" style="padding-bottom: 0.5rem">
                                <i class='fas fa-file-export'></i>
                                Xuất Excel
                            </button>
                        </export-excel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$get, DISCOUNT_TYPE_PERCENT, explode} from "../../lib/utils";
import InputNumberFormat from "../../components/forms/InputNumberFormat";
import InputText from "../../components/forms/InputText";
import InputDateRangePicker from "@/components/forms/InputDateRangePicker";
import moment from "moment";
import QSelect from "@/components/QSelect";
import FieldsReportTransfer from "@/assets/exportFields/fieldsReportTransfer";
import toastr from "toastr";

export default {
    name: "ModalReportTransfer",
    components: {QSelect, InputDateRangePicker, InputText, InputNumberFormat},
    props: ['modelValue'],
    data() {
        let query = this.$route.query;
        return {
            data: {},
            DISCOUNT_TYPE_PERCENT,
            checkedIds: [],
            filter: {
                date: query.date || '',
                branch_ids: explode(query.branch_ids)
            },
            excelData: {

            }
        }
    },
    mounted() {
    },
    methods: {
        show() {

            window.jQuery(this.$refs.modal).modal({
                backdrop: 'static', keyboard: false
            });
        },

        save() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        onClose() {
            this.filter = {
                date: '',
                branch_ids: ''
            }
        },
        async exportExcel() {
            if (!this.excelData.fields) {
                this.excelData.fields = FieldsReportTransfer;
            }

            const date = moment().format('HHmm_DDMMYYYY');
            this.excelData.fileName = 'bao-cao-chuyen-hang-' + date + 'xls';

            const params = this.filter;
            params._action = 'excel';

            const res = await $get('/v1/transfers/exportExcel', params);

            if (res.code === 200) {
                if (res.data && res.data.length > 0) {

                    return res.data;

                } else {
                    toastr.warning('Không có bản ghi nào phù hợp');
                    return false;
                }
            } else {
                toastr.error(res.message);
            }
            return false;
        },
        getTotalPrice(item) {
            const totalOrigin = this.modelValue.totalOrigin || this.modelValue.total;
            let result = 0;

            if (item.priceType == DISCOUNT_TYPE_PERCENT) {
                result = totalOrigin * item.priceValue / 100;
            } else {
                result = item.priceValue;
            }

            return parseInt(result);
        }
    }
}
</script>

<style scoped>

</style>
