<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 1140px">
            <div class="modal-content">
                <div class="modal-header" v-if="product">
                    <h5 class="modal-title" id="exampleModalLabel">{{ product.name }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="onClose">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div v-if="product" class="custom__list-tab bg-custom-modal pb-0 pt-0 text-center" style="border-bottom:1px solid #ccc ;">
                    <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item']" @click="choseTab($constant.tab.first)">
                        {{$t('message.modalCreateTicketForCash.info_common')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item']" @click="choseTab($constant.tab.second)">
                        {{$t('message.common.descriptionDetail')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.third ? 'tab__active' : '', 'tab__item', 'd-flex']" @click="choseTab($constant.tab.third)">
                        <!-- <div>
                            Tồn kho:
                        </div>
                        <div v-for="(item, index) in product.inventories" :key="index" class="d-flex" >
                            <div class="actualQuantityValue ml-4">{{ item.inventories }}</div>
                            <div class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                            <span> ,</span>
                        </div> -->
                        {{$t('message.ProductIndex.inventory')}}: 
                        <template v-if="product.inventories && product.inventories != [] && product.inventories?.length > 0">
                            <template v-for="(item, index) in product.inventories" :key="index">
                                <template v-if="index < product.inventories.length - 1">
                                    {{ item.inventories + ' ' +  item.product_unit?.unitName + ', '}}
                                </template>
                                <template v-else>
                                    {{ item.inventories + ' ' +  item.product_unit?.unitName}}
                                </template>
                            </template>
                        </template>
                    </div>
                </div>
                <div class="modal-body" v-if="product">
                    <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                        <div class="row">
                            <div class="col-md-3 product-img">
                                <div v-if="product.image">
                                        <template v-for="img in product.image" :key="img.id">
                                            <img :src="img?.url" alt="" class="detail-row-img" @error="imageUrlAlt">
                                    </template>
                                </div>
                                <div v-else>
                                    <img src="../../../public/img/default-product.png" alt="" class="detail-row-img">
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="d-flex">
                                    <div><span class="label__detail">{{$t('message.ProductIndex.price')}}: </span>{{ n(product.price) }}</div>
                                    <!-- <div v-if="product.product_unit_price_lists[0]">{{ $filters.formatPrice(product.product_unit_price_lists[0].price)}}</div>
                                    <div v-else>{{ $filters.formatPrice(product.product_units[0].price)}}</div> -->
                                </div>
                                <div class="d-flex mt-3">
                                    <div class="d-flex mr-4 align-items-center">
                                        <span class="label__detail">{{$t('message.ProductIndex.quantity')}} : </span>
                                        <div class="d-flex ml-4 align-items-center">
                                            <button type="button" class="btn__update" @click="updateQuantity(product, -1)">
                                                -
                                            </button>
                                            <InputNumberFormat v-model="product.quantity"
                                                class="sale-input-number mb-0"
                                            ></InputNumberFormat>
                                            <button type="button" class="btn__update" @click="updateQuantity(product, +1)">
                                                +
                                            </button>
                                        </div>
                                    </div>
                                    <div class="d-flex mr-4 align-items-center">
                                        <span class="label__detail">{{$t('message.ProductUnitSearch.inventory')}}: </span>
                                        <div>
                                            <div v-for="(item, index) in product.inventories" :key="index" class="d-flex" >
                                                <div v-if="product.productUnitId == item.productUnitId" class="actualQuantityValue ml-4">{{ n(item.inventories) }}</div>
                                                <div v-if="product.productUnitId == item.productUnitId" class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                                <span v-if="product.productUnitId == item.productUnitId"> ,</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex mr-4 align-items-center">
                                        <span class="label__detail">{{$t('message.common.canSell')}}: </span>
                                        <div v-if="product.inventories">
                                            <div v-for="(item, index) in product.inventories" :key="index" class="d-flex" >
                                                <div v-if="product.productUnitId == item.productUnitId" class="actualQuantityValue ml-4">{{ n(item.inventories - item.quantityOrder) }}</div>
                                                <div v-if="product.productUnitId == item.productUnitId" class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                                <span v-if="product.productUnitId == item.productUnitId"> ,</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <span class="label__detail">{{$t('message.ProductIndex.location')}}: </span>
                                </div>
                            </div> 
                        </div>
                    </div>


                    <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.third">
                        <div class="row block-table">
                            <table class="table__product-detail">
                                <tr class="head__table">
                                    <th style="width: 400px;">
                                        {{$t('message.common.branch')}}
                                    </th>
                                    <th>{{$t('message.ProductUnitSearch.inventory')}}</th>
                                    <th>{{$t('message.common.totalCustomerOrder')}}</th>
                                    <th>{{$t('message.common.canSell')}}</th>
                                </tr>
                                <tr v-for="(item, index) in branches" :key="index" class="item_branch">
                                    <td>{{ item.branch?.name }}</td>
                                    <td>{{ item.inventories ?? 0 }}</td>
                                    <td>{{ item.quantityOrder ?? 0 }}</td>
                                    <td>{{ (item.inventories ?? 0 ) - (item.quantityOrder ?? 0) }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="onClose">
                        <i class="fa fa-ban"></i>&nbsp;
                        {{$t('message.button.skip')}}
                    </button>
                    <button type="button" class="btn btn-primary" @click="changeUpdate()">
                        <i class="fa fa-save"></i>&nbsp;
                        {{$t('message.button.finished')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import {$get} from "../../lib/utils";
    import store from "../../store";
    export default {
        name: "ModalCreateCustomer",
        emits: ['created'],
        components: {InputNumberFormat},
        data() {
            return {
                product: null,
                isTab: this.$constant.tab.first,
                branches: null,
                currentBranch: store.state.CurrentBranch,
            }
        },
        methods: {
            async show(productOrigin) {
                if (productOrigin) {
                    this.product = { ...productOrigin };
                    await this.getInventoriesInBranch(this.product.id, this.product.productUnitId);
                    await this.getInventoriesProduct(this.product.code);
                }
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            },
            async getInventoriesInBranch(productId, productUnitId) {
                const res = await $get('/v1/orders/get-inventories-in-branches', {
                    productId: productId,
                    productUnitId: productUnitId,
                });
                if (res && res.result) {
                    this.branches = res.result;
                }
            },
            async getInventoriesProduct(productCode){
                const res = await $get('/v1/order-suppliers/get-products', {
                    keyword: productCode,
                    branchId: this.currentBranch.id,
                });
                if (res.status.code == 200) {
                    if(res.result && res.result != [] && res.result?.length > 0){
                        this.product.inventories = res.result[0].inventories;
                    }
                }
            },
            onClose() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            updateQuantity(product, change) {
                this.product.quantity = +product.quantity + +change;
            },
            changeUpdate() {
                this.$emit('updateQuantity', this.product.quantity, this.product.id); 
                window.jQuery(this.$refs.modal).modal('hide');
            },
            choseTab(tab){
                this.isTab = tab;
            }

        },
        beforeUnmount() {
            this.onClose();
        },
        unmounted() {
            this.onClose();
        }
    }
</script>

<style scoped>
    .modal-dialog{
       max-width: 1140px;
       min-width: 900px;
       height: 100%;
       padding-top: 0;
       margin-top: 0;
       float: right;
       height: 0;
    }
    .modal-content {
        height: 100vh;
    }
    .model-body{
        max-height: calc(100vh - 150px);
    }
    .custom__list-tab {
        display: flex;
        justify-content: space-around;
        align-items: end;
        font-size: 16px;
        padding-bottom: 8px;
    }
    .tab__active {
        border-bottom: 2px solid #2FA1A1;
    }
    .tab__item {
        /* min-width: 150px; */
        cursor: pointer;
        padding: 0 15px;
    }
    .product-img {
        width: 100px;
        height: 100px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        float: left;
        margin-left: -5px;
        margin-top: 0
    }

    img {
        max-width: 100%;
        max-height: 100%;
        vertical-align: middle;
    }
    .detail-row-img{
        width: 5vw;
        object-fit: cover;
    }
    .label__detail {
        font-weight: 600;
        color: #495057;
    }
    .btn__update {
        border: none;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: none;
    }
    .table__product-detail {
        width: 100%;
        margin: 20px;
    }
    .head__table {
        height: 50px;
        background: #2FA1A1;
        color: #FFFFFF;
    }
    .block-table {
        max-height: calc(100vh - 200px);
        /* height: 750px; */
        overflow: auto;
    }
    .item_branch {
        height: 40px;
        background: #F2F2F2;
        border-bottom: 1px solid #FFFFFF;
    }

</style>
