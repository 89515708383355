<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h5 class="modal-title py-2">{{ $t('message.common.removePrintTemplate') }} </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{ $t('message.common.confirmRemovePrintTemplate') }}
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <button class="btn btn-danger mr-2" @click="removePrintTemplate()"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemovePrintTemplate',
    props: {},
    data () {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id) {
            this.id = id;         
            window.jQuery(this.$refs.modal).modal('show');
        },
        async removePrintTemplate() {        
            const res = await $post( `/v1/print-templates/remove-print-template`, {              
                 printTemplateId: this.id
                }
              );
            if(res && res.status.code == 200 && res.result && res.result.code == 200) {
                toastr.success(res.result.message);
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('loadData');
            }
            else {
                toastr.error(res.result.message);
            }         
        
        }
    }
}
</script>
<style>
</style>