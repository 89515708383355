<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalJobTitle" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 class="modal-title py-2">{{ $t('message.SettingPage.partner.modal.transporter.title') }}</h5>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <template v-for="(option, index) in this.options" :key="index">
                        <div class="row mx-4 my-4 mt-1">
                            <div class="col-lg-12">
                                <div class="row d-flex align-items-center justify-content-between">
                                    <div class="col-2">
                                        <img src="../../../../public/img/ahamove.png" class="w-100 bg-transparent" v-if="option.id == this.$constant.ahamove_option_id">
                                        <img src="../../../../public/img/GHN.png" class="w-100 bg-transparent" v-if="option.id == this.$constant.ghn_option_id">
                                        <img src="../../../../public/img/viettelpost.png" class="w-100 bg-transparent" v-if="option.id == this.$constant.vtp_option_id">
                                    </div>
                                    <div class="d-flex col-8 align-items-center">  
                                        <h6 class="m-0"><b>{{ option.name }}</b></h6>
                                    </div>
                                    <div class="col-2 d-flex justify-content-end">
                                        <span class="toggle" :class="option.status ? 'active' : ''"
                                            @click="changeOption(option)"></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="modal-footer py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import emitter from "@/lib/emitter";

export default {
    name: "ModalTransporter",
    components: {},
    props: {},
    data() {
        return {
            options: [],
        }
    },
    methods: {
        async show() {              
            window.jQuery(this.$refs.modal).modal('show');
            await this.load();
        },
        hide() {   
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async load() {
            const res = await $get('/v1/setting-store/get-options', {action: 'transporter'});
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            this.options = res.result ?? [];
        },
        async save() {
            const res = await $post('/v1/setting-store/update-multiple-option', {options: this.options});
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            if(res.result){
                $alert({code: 200, message: this.$t('message.ModalCreateRouteOfUse.alert.update_success')});
            }else{
                $alert({code: 500, message: this.$t('message.ModalCreateRouteOfUse.alert.update_error')});
            }
            this.hide();
        },
        async changeOption(option) {
            option.status = !option.status;
        },
    },
    mounted() {
    },
    created () {
    },
    beforeUnmount(){
        this.hide();
    }
}
</script>

<style scoped>
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}
#modal-third {
    z-index: 1;
    opacity:0.5;
}
.toggle {
    background-color: rgba(31, 32, 33, .15);
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 14px;
    width: 51px;
    height: 28px;
    transition: all .2s ease-in-out;
}

.toggle.active {
    background-color: rgb(47, 161, 161);
}

.toggle:before {
    content: "";
    background-color: #fff;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all .2s ease-in-out;
}

.toggle.active:before {
    left: auto;
    right: 2px;
    transition: all .2s ease-in-out;
}

.icon-active {
    color: rgb(47, 161, 161);
}
</style>