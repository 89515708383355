<template>
    <div class="search-box position-relative custom__input" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input text-overflow-ellipsis" :placeholder="$t('message.common.searchTransporter')"
               v-model.trim="keyword" @input="onSearchTransporter" :readonly="readonly"/>
        <i v-if="!keyword" class="fa fa-plus px-2"  @click="onCreateTransporter"></i>
        <i v-if="keyword" class="fa fa-times px-2"  @click="clearValue"></i>
        <div class="popup-supplier-search" v-if="entries && entries.length">
            <ul class="wrap-products">
                <li v-for="entry in entries" :key="entry.id" @click="onSelect(entry)">
                    <div class="product-info">
                        <span class="branch__name">{{ entry.name }}</span>
                    </div>
                </li>
            </ul>
        </div>
    </div>
    <!-- <ModalAddTransporters ref="ModalAddTransporters" @loadData ="load"/> -->
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import AppPermission from './AppPermission.vue';
    // import ModalAddTransporters from '../views/transporters/tabs/ModalAddTransporters.vue';
    let app;

    export default {
        name: "TransportersSearch",
        emits: ['select'],
        components: {AppPermission},
        props: ['modelValue', 'readonly'],
        data() {
            app = this;
            return {
                entries: [],
                keyword: this.modelValue,
                read: this.readonly ?? false,
            }
        },
        methods: {
            selectValue(v) {  
                this.$emit('selectValue', v);           
                this.keyword = v.name;
            },
            onSearchTransporter: debounce(async () => {
                app.callOnSearchCustomer();
            }),
            getNewData(supplier) {
                this.keyword = supplier.name;
                this.$emit('select', supplier);
            },
            clearValue(){
                this.keyword = '',
                this.$emit('selectValue');
            },
            async callOnSearchCustomer() {
                if (this.keyword) {
                    const res = await $get('/v1/transporters/get-list-transporter', {keyword: this.keyword});
                    if (res.status.code == 200) {
                        this.entries = res.result;
                    } else {
                        $alert(res);
                    }
                }
            },
            onSelect(entry) {
                this.$emit('selectValue', entry);
                this.keyword = entry.name;
                this.entries.length = 0;
            },
            hidePopup() {
                this.entries.length = 0;
            },
            onCreateTransporter() {
                this.$emit('createNewValue');
            },
        },
        watch: {
            modelValue() {
                this.keyword = this.modelValue;
            },
            readonly() {
                this.read = this.readonly;
            },
            keyword(newValue) {
                if (!newValue) {
                    this.clearValue();
                }
            }, 
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .custom__input {
        border: none;
        border-bottom: solid 1px #ccc;
        border-radius: 0;
    }
    .popup-supplier-search {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            // min-width: 450px;
            z-index: 10;
            padding-bottom: 20px;
            /*padding-bottom: 50px;*/

            max-height: calc(100vh - 230px);
            font-size: 14px;
            background: #fff;

            border-radius: 5px;
            box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

            ul.wrap-products {
                padding: 10px 0;
                overflow: auto;
                max-height: calc(100vh - 284px);
                list-style: none;
                margin-bottom: 0;

                li {
                    cursor: pointer;
                    padding: 10px 20px;
                    margin: 0;

                    .product-img {
                        width: 55px;
                        height: 55px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        float: left;
                        margin-left: -5px;
                        margin-top: 0;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            vertical-align: middle;
                        }
                    }

                    .product-info {
                        margin-left: 10px;
                        line-height: 18px;
                        display: flex;
                        justify-content: space-between;
                        p {
                            margin-bottom: 2px;
                        }

                        .name-item {
                            font-weight: bold;
                            word-break: break-word;
                        }

                        .code-item, .price-item {
                            margin-right: 20px;
                            margin-left: 0;
                        }
                    }
                }

                li:hover {
                    background-color: $base-color;
                    color: #fff;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    width: 8px;
                    border-radius: 4px;
                    background-color: #c1c1c1;
                    border: 1px solid #fff;
                }

                &::-webkit-scrollbar-track {
                    padding: 6px;
                }
            }

            .products-not-found {
                padding: 25px 10px;
                color: #999999;
                text-align: center;
            }

            .add-new-product {
                line-height: 50px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                border-top: 1px solid #ebebeb;
                cursor: pointer;

                &:hover {
                    background-color: $base-color;
                    color: #fff;
                }
            }
        }
</style>
