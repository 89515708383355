import { getMessaging, onMessage } from "firebase/messaging";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    // apiKey: process.env.VUE_APP_FIREBASE_API_KEY,
    // authDomain: process.env.VUE_APP_FIREBASE_AUTH_DOMAIN,
    // projectId: process.env.VUE_APP_FIREBASE_PROJECT_ID,
    // storageBucket: process.env.VUE_APP_FIREBASE_STORAGE_BUCKET,
    // messagingSenderId: process.env.VUE_APP_FIREBASE_MESSAGESING_SENDER_ID,
    // appId: process.env.VUE_APP_FIREBASE_APP_ID,
    // measurementId: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID

    apiKey: "AIzaSyBIAGo4Tf3oRrQBZQ-JE6lQTk0Jk0mTY70",
    authDomain: "dev-omipos-720e6.firebaseapp.com",
    projectId: "dev-omipos-720e6",
    storageBucket: "dev-omipos-720e6.appspot.com",
    messagingSenderId: "320519479274",
    appId: "1:320519479274:web:9f0a17eceb65f05cb15c93",
    measurementId: "G-3MK23SV1VY"
};
const app = initializeApp(firebaseConfig);
// const messaging = getMessaging();

export default app;
