<template>    
    <div ref="ModalProductsChecked" class="modal mx-0" :class="this.parent ? 'fade' : '' " tabindex="-1" role="dialog" id="ModalProductsChecked" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document" style="width: 1280px; height: 1280px;">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <!-- <h6 class="modal-title py-2">Chọn loại giấy in tem mã</h6> -->
                    <h6 class="modal-title py-2">{{ this.$constant.typePrintTitle }}</h6>
                    <button type="button" class="close" data-dismiss="ModalProductsChecked" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-0 py-0 mx-0 px-0" >
                    <div class="row my-3 mx-0 px-0">
                        <div class="col-3" style="line-height:30px;">
                                <div class="pb-2 row" v-if="this.type && this.type == 'single'">                                    
                                    <label class="optQuantity py-0 my-0 w-50 labelQuantity" >{{$t('message.DetailOrdersCard.prodQuantity')}}</label>                                    
                                    <input type="number" class="form-control text-right px-2" id="inputQuantity" v-model="this.quantity" min="1" oninput="this.value = Math.abs(this.value)">
                                </div>
                                <div class="options">
                                   <label class="pl-3 py-0 my-0">{{$t('message.DetailOrdersCard.prodCode')}}</label>
                                </div>
                                <div class="options">
                                    <select id="priceOptions" width="100%" class="form-control my-0 py-0" v-model="status.priceOptions" >
                                        <option v-for="item in this.priceOptions" :value="item.value" :key="item.id">{{item.label}}</option>                                        
                                    </select>                                    
                                </div>
                                <div class="options">
                                    <select id="currentOptions" width="100%" class="form-control my-0 py-0" v-model="status.currentOptions"  >
                                        <option v-for="item in this.currentOptions" :value="item.value" :key="item.id">{{item.label}}</option>                                        
                                    </select>                                      
                                </div>
                                <div class="options">
                                    <select id="unitOptions" width="100%" class="form-control my-0 py-0" v-model="status.unitOptions" >
                                        <option v-for="item in this.unitOptions" :value="item.value" :key="item.id" >{{item.label}}</option>                                        
                                    </select>                                       
                                </div>
                                <div class="options">
                                    <select id="nameOptions" width="100%" class="form-control my-0 py-0" v-model="status.nameOptions"  >
                                        <option v-for="item in this.nameOptions" :value="item.value" :key="item.id">{{item.label}}</option>                                        
                                    </select>                                        
                                </div>
                        </div>
                        <div class="col-9">     
                            <div class="typePaper">
                                        <!-- Hàng 1 -->
                                        <div class="row">
                                            <!-- Mẫu 1 -->
                                                <div class="col-1 imgLeft">
                                                    <img src="../../../../public/img/papers/mau1-3-nhan-104x22mm.jpg" alt="" />
                                                </div>
                                                <div class="col-4 px-0" style="line-height:17px !important;">
                                                    <p class="lblName">{{$t('message.common.paper3')}}</p>                                                                                                       
                                                    <button class="btn btn-save" style="font-size:14px;" @click="this.showModalPreviewBarcode(1,3, 35, 22)">  
                                                        <i class="fa fa-th"></i>
                                                        <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                    </button>
                                                </div> 
                                            <!-- Mẫu 2 -->
                                                <div class="col-1 imgLeft">
                                                    <img src="../../../../public/img/papers/mau2-12-nhan-202x162mm.jpg" alt="" />
                                                </div>
                                                <div class="col-4 px-0" style="line-height:17px !important;">
                                                    <p class="lblName">{{$t('message.common.paper12')}}</p>                                                     
                                                    <button class="btn btn-save" style="font-size:14px;"  @click="this.showModalPreviewBarcode(2, 12, 62, 60)">  
                                                    <i class="fa fa-th"></i>
                                                        <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                    </button>
                                                </div>                                               
                                        </div>
                                        <!-- Hàng 2 -->
                                        <div class="row my-3">
                                            <!-- Mẫu 3 -->
                                            <div class="col-1 imgLeft">
                                                <img src="../../../../public/img/papers/mau3-2-nhan-72x22mm.jpg" alt="" />
                                            </div>
                                            <div class="col-4 px-0" style="line-height:17px !important;">
                                                <p class="lblName pr-2">{{$t('message.common.paper2')}}</p>                                               
                                                <button class="btn btn-save" style="font-size:14px;" @click="this.showModalPreviewBarcode(3, 2, 35, 22)">  
                                                    <i class="fa fa-th"></i>
                                                    <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                </button>
                                            </div> 
                                            <!-- Mẫu 4 -->
                                            <div class="col-1 imgLeft">
                                                <img src="../../../../public/img/papers/mau4-65-nhan.jpg" alt="" />
                                            </div>
                                            <div class="col-4 px-0" style="line-height:17px !important;">
                                                <p class="lblName">{{$t('message.common.paper65')}}</p>                                               

                                                <button class="btn btn-save" style="font-size:14px;" @click="this.showModalPreviewBarcode(4, 65, 38, 21)">  
                                                <i class="fa fa-th"></i>
                                                    <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                </button>
                                            </div>                                               
                                        </div>
                                         <!-- Hàng 3 -->
                                        <div class="row">
                                            <!-- Mẫu 5 -->
                                                <div class="col-1 imgLeft">
                                                    <img src="../../../../public/img/papers/mau5-2-nhan-74x22mm.jpg" alt="" />
                                                </div>
                                                <div class="col-4 px-0" style="line-height:17px !important;">
                                                    <p class="lblName pr-2">{{$t('message.common.paper2_1')}}</p>                                                   
                                                    <button class="btn btn-save" style="font-size:14px;" @click="this.showModalPreviewBarcode(5, 2, 35, 22)">  
                                                        <i class="fa fa-th"></i>
                                                        <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                    </button>
                                                </div> 
                                            <!-- Mẫu 6 -->
                                                <div class="col-1 imgLeft">
                                                    <img src="../../../../public/img/papers/mau6-trang-suc-75x10mm.jpg" alt="" />
                                                </div>
                                                <div class="col-4 px-0" style="line-height:17px !important;">
                                                    <p class="lblName">{{$t('message.common.paperJewelry')}}</p>                                                   
                                                    <button class="btn btn-save" style="font-size:14px;" @click="this.showModalPreviewBarcode(6, 3, 80, 10)">  
                                                    <i class="fa fa-th"></i>
                                                            <span class="pl-2"> {{ this.$constant.typePrintPreviewPrintTitle }}</span>
                                                    </button>
                                                </div>                                               
                                        </div>
                            </div>
                        </div>
                    </div>
                    <div class="row my-3 mx-2">
                        <div class="col-3 text-justify">     
                             <label style="font-size:0.9rem; font-style:italic">{{$t('message.common.printNote')}}</label>
                        </div>
                        <div class="col-9 text-center"></div>
                    </div>
                </div>
            </div>
        </div>        
    </div>
    
    <ModalPreviewBarcode ref="ModalPreviewBarcode" :status="this.status"  :info="this.info" :parent="this.parent" :multiParent="this.multiParent" />
</template>

<script>
import emitter from '@/lib/emitter';
import store from "@/store";
import ModalPreviewBarcode from "./ModalPreviewBarcode.vue";

export default {
    name: "ModalProductsChecked",
    components: {
        ModalPreviewBarcode
    },
    data(){        
        return {
            data: [],
            total:0,
            priceOptions: [
                { id: 1, label: this.$t('message.PriceListsIndex.title'), value: 1 },
                { id: 2, label: this.$t('message.common.noPrintPrice'), value: 0 },
            ],
            currentOptions: [
                { id: 1, label: this.$t('message.common.priceWithVND'), value: 1 },
                { id: 2, label: this.$t('message.common.priceNonVND'), value: 0 },
            ],
            unitOptions: [
                { id: 1, label: this.$t('message.common.priceWithUnit'), value: 1 },
                { id: 2, label: this.$t('message.common.priceNonUnit'), value: 0 },
            ],
            nameOptions: [
                { id: 1, label: this.$t('message.common.printShopName'), value: 1 },
                { id: 2, label: this.$t('message.common.noPrintShopName'), value: 0 },
            ],
            status:{
                priceOptions : 1,
                currentOptions : 1,
                unitOptions : 1,
                nameOptions : 1
            },
            quantity: 1,
            type: null,
            info: null,
        }
    },
    props: {
        parent: null,
        multiParent: null
    },
    mounted(){
            
    },
    watch: {
        quantity(newQuantity){                     
            emitter.emit('changeQuantityProductBarcode', {quantity: newQuantity, data: this.info});
        },       
    },
    methods: {            

        getProductUnit(product_units, units){
            
            let unit;
            if(product_units.length != 0){
                product_units.forEach(pu => {                
                    if(pu.unitName == units) {                    
                        unit = {
                            'unitName': pu.unitName,
                            'price': pu.price,
                        }
                    }
                });
            }else{
                unit = {
                    'unitName': null,
                    'price': null,
                }
            }            
            return unit;
        },
        getCategories(categories){            
            let name = '';
            categories.forEach(value => {
                if (name == '') name = value;
                else name = name + ', ' + value;
            });
            return name;
        },
        getProductInfo(data){
           
            let infomations = [];        
            // Load thông tin cần in ra tem bằng biến data khai báo
               if(this.quantity > 0){                        
                        let unit = this.getProductUnit(data.product_units, data.units);                                        
                        infomations.push({
                            // Get id của product
                            'prdID' : data.id,
                            // Mã sản phẩm 
                            'prdCode' : data.code,
                            // Tên sản phẩm
                            'prdName' : data.name,
                            // Tên đơn vị
                            'unitName': unit.unitName,
                            // Giá tiền
                            'prdPrice': unit.price,
                            // Tiền tệ
                            'prdCurrency': 'VNĐ', 
                            // Số lượng
                            'prdQuantity': this.quantity,
                            //Tên cửa hàng 
                            'branchName': store.state.CurrentBranch.name ?? null,
                            // Nhóm hàng
                            'categoryName': this.getCategories(data.categoryName),
                        })
                    }                 
            return infomations;           
        },
        show( type, data) {             
            switch (type) {
                case this.$constant.typePrintSingle:                    
                    this.type = type;
                    this.data.id = data.id;
                    this.data.name = data.name;
                    this.data.code = data.code;
                    this.info = this.getProductInfo(data);     
                    break;
                case this.$constant.typePrintMulti:                                        
                    this.type = type;                             
                    this.info = data;                    
                    break;
            }      
            window.jQuery(this.$refs.ModalProductsChecked).modal('show');            
            
        },
        hide(){
            window.jQuery(this.$refs.ModalProductsChecked).modal('hide');
        },
        getStatus(){
            
            return this.status;
        },
        showModalPreviewBarcode(type, number = null, width = null, height = null){            
            this.$refs.ModalPreviewBarcode.show(type, number, width, height);   
        },
        getDataCheck(){            
            let data = [];
            let className = document.getElementsByClassName('prdChecked');            
            className.forEach(item => {                
                if (item.checked) {
                   data.push({
                        'id' : item.id, 
                        'name' : item.name,
                        'code' : item.value
                   });
                }
            });
            return data;
        },
        unChecked(id){
            //Bỏ chọn ở danh sách 
            document.getElementById(id).checked = false;
            this.removeItem(id);
            
            //xoá dòng ở modal
            // document.getElementById('modal_' + id).remove();
            
        },
        removeItem(id){            
            let data = this.data;
            let vitri = 0;
            data.forEach((item, index) => {
                if(item.id === id) vitri = index;
            });            
            data.splice(vitri,1);            
            this.$emit('inputData', '4');
        },
       
    }
}
</script>

<style lang="scss" scoped>
    .labelQuantity {
        display: flex !important;
        align-items: center !important;
    }
    #inputQuantity {
        border: 0;
        border-bottom: 1px solid #ddd;
        border-radius: 0;
        width: 80px;
    }
    .optQuantity {
        width: 66% !important;
        // border-bottom: 1px solid #ccc;
        padding-left: 33px !important ;
    }
    #modalTableChecked thead th {
        border-bottom: 0 !important;    
    }
    .table thead th
    #modalChecked .modal-header {
        border-bottom: 0 !important;
    }
    #quantity{
        border-bottom: 1px solid #ccc !important;
        border-top: 0 !important;
        border-left: 0 !important;
        border-right: 0 !important;
        height: 30px ;
        width: 100%;
    }
    .options {
        width: 100%;
        border-bottom: 1px solid #ccc;
        padding: 4px 2px;        
    }
    
    .typePaper {
        widows: 50%;
    }
    .typePaper img {
        width: 85px;
        height: 85px;
        border-radius: 3px;
        border:  1px solid #ccc;
    }
    .lblName {
        font-size:14px; 
        color: #495057;
        // text-align: justify;
        
        // line-height: 25px;
    }
    #priceOptions,#currentOptions,#unitOptions,#nameOptions {
        border: 0;
    }

    .imgLeft {
        min-width: fit-content !important;
    }
</style>