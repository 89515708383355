import {numberFormat, parseIntEx} from "@/lib/utils";
import moment from 'moment';
import i18n from "./setup/i18n";
import constant from "./configs/constant";

const currentYear = (new Date).getFullYear().toString();
const renderFunctions:any = {    
    date(tzTime: any) {
        if (!tzTime) {
            return '';
        }

        const m = moment(tzTime);

        if (m.format('YYYY') === currentYear) {
            return m.format('DD/MM')
        }

        return m.format('DD/MM/YYYY')
    },
    datetime(tzTime: any) {
        if (!tzTime) {
            return '';
        }

        const m = moment(tzTime);

        // if (m.format('YYYY') === currentYear) {
        //     return m.format('DD/MM HH:mm')
        // }

        return m.format('DD/MM/YYYY HH:mm')
    },
    number(a: number) {
        if (a === null || a === 0) {
            return 0;
        }

        a = parseIntEx(a);
        return numberFormat(a)
    },
    numberFloat(a: any) {
        if(typeof a === 'string'){
            a = Number(a.replace(/,/g, ''));
        }
        if (a === null || a === 0 || !a) {
            return 0;
        }
        a = new Intl.NumberFormat('en-US').format(a);
        return a
    },
    gender(a: number) {
        a = parseIntEx(a);
        return a === 1 ? i18n.t('message.common.male') : i18n.t('message.common.female')
    },
    status(s: number) {
        s = parseIntEx(s);
        return s == 1 ? i18n.t('message.customerIndex.active') : i18n.t('message.customerIndex.inActive');
    },
    dot(value: any, field: string, entry: any, settings: any) {
        const defaultValue = settings.defaultValue || '';
        try {
            const v = field.split('.').reduce((o,i)=> o[i], entry);
            if (!v) {
                return defaultValue;
            }

            return v;
        } catch (_) {
            return defaultValue;
        }
    },
    truncate40(str: string) {
        if (!str) {
            return '';
        }

        const length = 40;
        const dots = str.length > length ? '...' : '';
        return str.substring(0, length)+dots;
    }
};

const appMixin = {
    methods: {
        truncate(str: string, length: number) {
            const dots = str.length > length ? '...' : '';
            return str.substring(0, length)+dots;
        },
        /**
         * @deprecated Please use renderField
         * @param entry
         * @param field
         * @param renderType
         * @param settings
         */
        r(entry:any, field: string, renderType: string, settings: any) {
            const value = entry[field];
            if (renderFunctions[renderType]) {
                return renderFunctions[renderType](value, field, entry, settings);
            }

            return value;
        },
        renderField(entry:any, field: string, settings: any) {
            const value = entry[field];
            const renderType = settings.render;

            if (renderFunctions[renderType]) {
                return renderFunctions[renderType](value, field, entry, settings);
            }

            return value;
        },
        renderFieldById(id:number, arrays: any) {
            if(!Array.isArray(arrays)){
                return null;
            }else{
                const index = arrays.findIndex(a => a.id == id);
                if(index !== undefined){
                    return arrays[index]?.name;
                }
            }
        },
        n(a: number) {
            if (a === null || a === 0) {
                return 0;
            }
            a = parseIntEx(a);
            return numberFormat(a)
        },

        numberFloat(a: any) {
            if(typeof a === 'string'){
                a = Number(a.replace(/,/g, ''));
            }
            if (a === null || a === 0 || !a) {
                return 0;
            }
            a = new Intl.NumberFormat('en-US').format(a);
            return a
        },

        d(tzTime: any, format: string = '') {
            if (!tzTime) {
                return '';
            }

            const m =  moment(tzTime);
            // if (m.format('YYYY') == (new Date).getFullYear()) {
            //     return m.format('DD/MM HH:mm')
            // }

            if (format) {
                return m.format(format);
            }

            return m.format('DD/MM/YYYY')
        },
         day(tzTime: any, format: string = '') {
            if (!tzTime) {
                return '';
            }

            const m =  moment(tzTime);
            // if (m.format('YYYY') == (new Date).getFullYear()) {
            //     return m.format('DD/MM HH:mm')
            // }

            if (format) {
                return m.format(format);
            }

            return m.format('DD')
        },
        dt(tzTime: any) {
            if (!tzTime) {
                return '';
            }
            if (tzTime == '0000-00-00 00:00:00') {
                return '';
            }

            const m =  moment(tzTime);
            // if (m.format('YYYY') == (new Date).getFullYear()) {
            //     return m.format('DD/MM HH:mm')
            // }

            return m.format('DD/MM/YYYY HH:mm')
        },
        abs(num: number) {
            return Math.abs(num);
        },
        status(s: number) {
            s = parseIntEx(s);
            return s == 1 ? i18n.t('message.customerIndex.active') : i18n.t('message.customerIndex.inActive');
        },
        statusInvoiceSupplier(s: number) {
            s = parseIntEx(s);
            return s == 1 ? i18n.t('message.common.hasInvoice') : i18n.t('message.common.nonInvoice');
        },
        getNameByLocale(data: any){
            if(!data) return null;
            const language = localStorage.getItem('language') ?? 'vi';
            if (data[`name_${language}`]) return data[`name_${language}`];
            if (data[`label_${language}`]) return data[`label_${language}`];
            return data.name ?? (data.label ?? null);
        },
        getDocumentType(data: any){
            if(!data) return null;
            if(data == constant.textInvoice) return i18n.t('message.headers.invoices');
            if(data == constant.textReturn) return i18n.t('message.headers.returns');
            if(data == constant.textBuy) return i18n.t('message.headers.buy');
            if(data == constant.textReturnImport) return i18n.t('message.headers.return_purchase_orders');
            if(data == constant.textSale) return i18n.t('message.headers.sales');
            return null;
        }
    }
}

export default appMixin;
