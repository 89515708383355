<template>    
    <div ref="modal" class="modal mx-0 fade" :class="this.isFirstModal ? '' : 'modal-second'" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 600px;">
            <div class="modal-content">
                <div class="modal-header py-3" style="border-bottom:0">
                    <h6 class="modal-title">{{(this.id != null) ? $t('message.TransporterIndex.modal.modalAddTransporterGroups.update.title') : $t('message.TransporterIndex.modal.modalAddTransporterGroups.add.title')}}
                       
                    </h6>
                    <button type="button" class="close" @click="this.hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-2">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-2 divTitle px-0 mx-0">
                                    <b>
                                        {{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.name')  }}
                                        <label class="text-danger px-0">*</label> 
                                    </b>
                                </div>
                                <div class="col-10 px-0">
                                    <input type="text" v-model.trim="this.entry.name" class="form-control textInput" maxlength="255"
                                    :placeholder="$t('message.TransporterIndex.placeholder.groupName')"
                                    >                                    
                                </div>
                            </div>
                            <div class="row my-2">
                                <div class="col-2 px-0 mx-0">
                                    <b>{{ $t('message.TransporterIndex.modal.modalAddTransporterGroups.note') }}</b>
                                </div>
                                <div class="col-10 px-0">
                                    <div class="form-group">
                                       <InputTextArea v-model.trim="this.entry.note" :customerStyle="true"
                                       :placeholder="$t('message.TransporterIndex.modal.modalAddTransporterGroups.notePlaceholder')"  />
                                       <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-3">
                        <div class="col-lg-12 flex-end">
                            <AppPermission :permission="this.$permissions.transporterGroups.update">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="this.id">
                                    <i class="fa fa-save mr-1"></i>                                   
                                    {{$t('message.TransporterIndex.modal.modalAddTransporterGroups.buttonAddUpdate.confirm')}}
                                </button>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.transporterGroups.create">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="!this.id">
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('message.TransporterIndex.modal.modalAddTransporterGroups.buttonAddUpdate.confirm')}}
                                </button>
                            </AppPermission>
                                <button type="button" class="btn btn-secondary text-white  mr-2" @click="this.hide()">
                                    <i class="fa fa-ban mr-1"></i>
                                   {{$t('message.TransporterIndex.modal.modalAddTransporterGroups.buttonAddUpdate.skip')}}
                                </button>
                            <AppPermission :permission="this.$permissions.transporterGroups.remove">
                                <button type="button" class="btn btn-danger mr-2" v-if="this.id" @click="removeCate(entry.name)">
                                    <i class="fa fa-trash-alt mr-1"></i>
                                   {{$t('message.TransporterIndex.modal.modalAddTransporterGroups.buttonRemove')}}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <ModalTransporterGroupsDelete ref="ModalTransporterGroupsDelete" @loadData="loadData" />
</template>
<style scoped>
.textInput {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
.divTitle {
    display: flex;
    align-items: center;
}
#fa_edit {
  position: absolute;
  left: -5px;
  top: 10px;
  color: #a7a2a2;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}

</style>
<script>
import ActionBar from "../../../components/ActionBar";
import InputText from "../../../components/forms/InputText";
import toastr from 'toastr';
import {$post, $alert, $get, scrollToElement, isChangeData, cloneObject} from "@/lib/utils";
import InputSwitchButton from "../../../components/forms/InputSwitchButton";
import QSelect from "@/components/QSelect";
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission';
import InputTextArea from '@/components/forms/InputTextArea.vue';
import ModalTransporterGroupsDelete from './ModalTransporterGroupsDelete.vue';

export default {
    name: "ModalAddTransporterGroups",
    components: { ActionBar, InputText, InputSwitchButton, QSelect, InputTextArea, AppPermission,ModalTransporterGroupsDelete    
    },
    props: {
        typeEditAdd: String,
        idCategory: Number,
        isFirstModal: Boolean
    },
    data() {
        return {
            // isDataLoaded: false,
            entry: {
                name: '',
                note: null
            },
            errors: {},
            default: {
                name: '',
                note: null
            },
            contentConfirm: '',
            titleConfirm: '',
            id: null,
           
        }
    },
    methods: {
        loadData(){
           this.hide();
        },
        clearInput(){
            this.entry = {
                name: '',
                note: null
            };
            this.id = null; 

        },
        async show(id = null) { 
            
            if(id === null){                          
                this.clearInput();
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                this.id = id;                
                await this.load(id);                
            }
            this.default = {...this.entry};            
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            this.$emit('loadData');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = 'Xác nhận';
                if(this.id != null){
                    this.contentConfirm = 'Bạn có muốn hủy cập nhật nhóm hàng hóa?';
                }else{
                    this.contentConfirm = 'Bạn có muốn hủy thêm nhóm hàng hóa?';
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async load(id = null) {
            const res = await $get('/v1/transporters-groups/show', {
                id: id
            });
            if(res && res.status.code == 200 && res.result.status) {
                this.entry.name = res.result.data.name;
                this.entry.note = res.result.data.note;
            }
        },
        async save() {
            this.errors = {};
            const submitEntry = cloneObject(this.entry);                       
            // Tách hàm thêm mới và cập nhật do hai permission khác nhau
            let strSave = '';
            if(this.id) {
                strSave = '/v1/transporters-groups/update';
                submitEntry.id = this.id;
            }
            else strSave = '/v1/transporters-groups/create';                  
            const res = await $post(strSave, {...submitEntry }); 
            if(res && res.status?.code && res.status.code == 200 && res.result){
                if(!this.id){
                    toastr.success(this.$t('message.TransporterIndex.modal.modalAddTransporterGroups.add.msgSuccess'));
                }  
                else {
                    toastr.success(this.$t('message.TransporterIndex.modal.modalAddTransporterGroups.update.msgSuccess'));                    
                }                             
                this.hide(); 
            }
            else{
                    if(res.messages.errors.name) toastr.error(res.messages.errors.name);    
                    if(res.messages.errors.note) toastr.error(res.messages.errors.note);                           
            }
        },
        removeCate(name){
            this.$refs.ModalTransporterGroupsDelete.show(this.id,name);
        },
    },
    mounted() {
        emitter.on('openAdd',() => this.show())
    },
}
</script>

