<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2" v-text="$t('message.SendMailZaloIndex.modal.title_send')"></h5>
                    <button type="button" class="close pt-3" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 text-center my-3">
                        <div class="row">
                            <div class="col-lg-4" v-for="entry in entries" :key="entry.id">
                                <a href="javascript:void(0)"  @click="selectTemplate(entry.id)" :class="templateId == entry.id ? 'btn btn-primary active' : 'btn btn-outline-secondary notActive' ">
                                    {{entry.name}}
                                </a>
                            </div>  
                        </div>
                    </div>
                    <div class="col-12 text-center my-3 mt-3">
                        <QSelectMail @updateListMailCc="updateListMailCc" :updateListMailTo="updateListMailTo" :optionsPropMailCc="listMailsCC" :optionsPropMailTo="listMailsTo"/>
                    </div>
                    <div v-if="showSendObj" class="col-12 my-3">
                        <input type="checkbox" class="prdChecked" v-model="hasSendMailObj" :value="true">
                        <span class="ml-2">
                            {{$t('message.common.receiverDefault')}}
                        </span>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-success mr-2" @click="confirm()" :disabled="this.isCallApi" >
                            <i class="fa fa-share mx-2"></i>
                            {{$t('message.SendMailZaloIndex.modal.button.send')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="hide()">
                            <i class="fa fa-ban mx-2"></i>
                            {{$t('message.SendMailZaloIndex.modal.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import {$get, $post, $alert} from "@/lib/utils";
import QSelectMail from '@/components/QSelectMail.vue'
import emitter from "@/lib/emitter";
export default {
    name: "ModalSelectSendMailAndZaloTemplate",
    components: {QSelectMail},
    data(){
        return {
            entries: [],
            templateId: null,
            type: null,
            object: null,
            ids: null,
            listMailsCC: [],
            optionsSelect: [],
            listMailsTo: [],
            hasSendMailObj: false,
            showSendObj: false,
            isCallApi: false
        }
    },
    methods: {
        updateListMailCc(mails) {
            this.listMailsCC = mails;
        },
        updateListMailTo(mails) {
            this.listMailsTo = mails;
        },
        selectTemplate(id){
            this.templateId = id;            
        },
        show(type, object, ids) {            
            this.type = type;
            this.object = object;
            this.ids = ids;
            this.listMailsCC = [];
            this.getTemplates();
            this.setsShowSendObj();
            window.jQuery(this.$refs.modal).modal('show');
        },
        setsShowSendObj() {
            if (
                this.type == this.$constant.TabOrderSuppliers || 
                this.type == this.$constant.TabImports || 
                this.type == this.$constant.TabReturnSuppliers || 
                this.type == this.$constant.TabInvoices || 
                this.type == this.$constant.TabOrder || 
                this.type == this.$constant.TabRefunds || 
                this.type == this.$constant.TabTransfers || 
                this.type == this.$constant.TabRequestPDF ||
                this.type == this.$constant.TabReturns
            ) {
                this.showSendObj = true;
                this.hasSendMailObj = true;
            } 
        },
        async getTemplates(){
            const response = await $get('/v1/pdf-templates/get-templates',{code: this.type});             
            if(response.status.code == 200){
                if(response.result) {                    
                    this.entries = response.result.data;
                    this.templateId = (response.result.id ? response.result.id : null);
                }
            }
        },
        hide() {
            this.showSendObj = false;
            this.hasSendMailObj = false;
            this.listMailsCC = [];
            this.listMailsTo = [];
            this.isCallApi = false;
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async confirm(){
            emitter.emit("onLoading");            
            let params = {
                type: this.type,
                ids: this.ids,
                templateId: this.templateId,
                listMailsCC: this.listMailsCC,
                listMailsTo: this.listMailsTo,
                hasSendMailObj: this.hasSendMailObj,
            }
            let response;
            this.isCallApi = true;
            switch (this.object) {
                case this.$constant.typeImportProducts:
                    response = await $post('/v1/order-suppliers/send-mail', {...params});
                    break;
                case this.$constant.typeSellProducts:
                    response = await $post('/v1/send-mail/invoice', {...params});
                    break;
                case this.$constant.typeTransferProducts:
                    response = await $post('/v1/transfers/send-mail', {...params});
                    break;
            }
            if(response && response.result){
                $alert({code: 200, message: this.$t('message.sendMail.success')});
            } else if (response && response.code == 422) {
                const errors = response.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: response.code, message: errors[key][0]});
                });
                this.isCallApi = false;
            } else {
                $alert({code: 500, message: this.$t('message.sendMail.failing')});
                this.isCallApi = false;
            }
            emitter.emit("offLoading");
            this.hide();
        },
    },
    beforeUnmount() {
        this.hide();
    }
}
</script>

<style scoped>
    .notActive{
        color:#000;
    }
    .notActive:hover {
        background-color: #ddd;
        color: #000;
    }
</style>