<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="modalConfirm" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-body mb-4">
                    <div class="row m-0 p-0">
                        <div class="col-12 pb-5 text-center">
                            <h6 class="font-weight-bold mb-2" v-text="this.titleNotice"></h6>
                            <p class="mb-2" v-text="$t('message.noticeDetail.time', {from: fromDate, to: toDate})"></p>
                            <p class="mb-2" v-text="$t('message.noticeDetail.branch', {branch: branchName})"></p>
                        </div>
                    </div>
                    <div class="row detail-prd-row m-0 w-100">
                        <div class="col-12 p-0">
                            <table class="w-100" v-if="this.type == this.$constant.typeNoticeProduct">
                                <thead>
                                    <tr class="backgroup__detail table-header border-1">
                                        <th scope="col" class="pl-4 cell-code-coupon p-2" v-for="field in this.fieldTableProduct" :key="field.id" v-text="field.field"></th>
                                    </tr>
                                    <tr class="bg-light-warning border-1" v-if="this.entries?.length > 0">
                                        <th scope="col" colspan="5" class="pl-4 cell-auto p-2" v-text="$t('message.common.quantityProductNoti') + n(this.totalRecords)"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.entries?.length > 0">
                                        <template v-for="(item, index) in this.entries" :key="item.id">
                                            <tr class="table-header border"  :class="index % 2 ? 'bg-white' : 'bg-light'" v-if="index >= (this.paginateDetail.currentPage - 1)*this.numberRecord && index < this.paginateDetail.currentPage*this.numberRecord">
                                                <th scope="col" class="pl-4 cell-code-coupon p-2 text-primary" @click="linkToProduct(item.code)">{{item.code}}</th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.productName"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.consignmentName"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.expried"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.daysLeft"></th>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <table class="w-100" v-if="this.type == this.$constant.typeNoticeVoucher">
                                <thead>
                                    <tr class="backgroup__detail table-header border-1">
                                        <th scope="col" class="pl-4 cell-code-coupon p-2" v-for="field in this.fieldTableVoucher" :key="field.id" v-text="field.field"></th>
                                    </tr>
                                    <tr class="bg-light-warning border-1" v-if="this.entries?.length > 0">
                                        <th scope="col" colspan="5" class="pl-4 cell-auto p-2" v-text="$t('message.common.quantityVoucherNoti') + n(this.totalRecords)"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.entries?.length > 0">
                                        <template v-for="(item, index) in this.entries" :key="item.id">
                                            <tr class="table-header border" v-if="index >= (this.paginateDetail.currentPage - 1)*this.numberRecord && index < this.paginateDetail.currentPage*this.numberRecord" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                <th scope="col" class="pl-4 cell-code-coupon p-2 text-primary" v-text="item.code" @click="linkToVoucher(item.release_code, item.code)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 text-primary" v-text="item.release_code" @click="linkToVoucher(item.release_code, item.code)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.amount)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.release_date)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.expired_date)"></th>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <table class="w-100" v-if="this.type == this.$constant.typeNoticeCoupon">
                                <thead>
                                    <tr class="backgroup__detail table-header border-1">
                                        <th scope="col" class="pl-4 cell-code-coupon p-2" v-for="field in this.fieldTableCoupon" :key="field.id" v-text="field.field"></th>
                                    </tr>
                                    <tr class="bg-light-warning border-1" v-if="this.entries?.length > 0">
                                        <th scope="col" colspan="5" class="pl-4 cell-auto p-2" v-text="$t('message.common.quantityCouponNoti') + n(this.totalRecords)"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.entries?.length > 0">
                                        <template v-for="(item, index) in this.entries" :key="item.id">
                                            <tr class="table-header border" v-if="index >= (this.paginateDetail.currentPage - 1)*this.numberRecord && index < this.paginateDetail.currentPage*this.numberRecord" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                <th scope="col" class="pl-4 cell-code-coupon p-2 text-primary" v-text="item.code" @click="linkToCoupon(item.release_code, item.code)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 text-primary" v-text="item.release_code" @click="linkToCoupon(item.release_code, item.code)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.percent) + '% ' + this.$t('message.noticeDetail.max_value') + ' ' + n(item.maxAmount)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.release_date)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.expired_date)"></th>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <table class="w-100" v-if="this.type == this.$constant.typeNoticePromotion">
                                <thead>
                                    <tr class="backgroup__detail table-header border-1">
                                        <th scope="col" class="pl-4 cell-code-coupon p-2" v-for="field in this.fieldTablePromotion" :key="field.id" v-text="field.field"></th>
                                    </tr>
                                    <tr class="bg-light-warning border-1" v-if="this.entries?.length > 0">
                                        <th scope="col" colspan="4" class="pl-4 cell-auto p-2" v-text="$t('message.common.quantityPromotionNoti') + n(this.totalRecords)"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-if="this.entries?.length > 0">
                                        <template v-for="(item, index) in this.entries" :key="item.id">
                                            <tr class="table-header border" v-if="index >= (this.paginateDetail.currentPage - 1)*this.numberRecord && index < this.paginateDetail.currentPage*this.numberRecord" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                <th scope="col" class="pl-4 cell-code-coupon p-2 text-primary" v-text="item.code" @click="linkToPromotion(item.code)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.form"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.release_date)"></th>
                                                <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="d(item.expired_date)"></th>
                                            </tr>
                                        </template>
                                    </template>
                                </tbody>
                            </table>
                            <Pagination :value="paginateDetail" :record="this.numberRecord" v-if="this.paginateDetail.totalRecord >= this.numberRecord"/>
                            <div class="no-data border border-top-0" v-if="this.entries?.length == 0">
                                <span>{{$t('message.common.notData')}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 d-flex justify-content-end mt-4">
                        <button class="btn btn-secondary text-white" @click="hide()"><i class="fa fa-ban mr-1"></i>
                            {{ $t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import moment from 'moment';
import store from "@/store";
export default {
    name: "NoticeDetail",
    components: {Pagination},
    data(){
        return {
            entries: null,
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            numberRecord: 50,
            totalRecords: null,
            type: null,
            titleNotice: null,
            branchName: store.state.CurrentBranch.name,
            fromDate: moment().locale('vi').format('DD/MM/YYYY'),
            toDate: null,
            fieldTableProduct: [
                {id: 1, field: this.$t('message.noticeDetail.tables.product.code')},
                {id: 2, field: this.$t('message.noticeDetail.tables.product.name')},
                {id: 3, field: this.$t('message.noticeDetail.tables.product.consignment')},
                {id: 4, field: this.$t('message.noticeDetail.tables.product.expired')},
                {id: 5, field: this.$t('message.noticeDetail.tables.product.daysLeft')},
            ],
            fieldTableVoucher: [
                {id: 1, field: this.$t('message.noticeDetail.tables.voucher.code')},
                {id: 2, field: this.$t('message.noticeDetail.tables.voucher.release_code')},
                {id: 3, field: this.$t('message.noticeDetail.tables.voucher.amount')},
                {id: 4, field: this.$t('message.noticeDetail.tables.voucher.release_date')},
                {id: 5, field: this.$t('message.noticeDetail.tables.voucher.expired_date')},
            ],
            fieldTableCoupon: [
                {id: 1, field: this.$t('message.noticeDetail.tables.coupon.code')},
                {id: 2, field: this.$t('message.noticeDetail.tables.coupon.release_code')},
                {id: 3, field: this.$t('message.noticeDetail.tables.coupon.amount')},
                {id: 4, field: this.$t('message.noticeDetail.tables.coupon.release_date')},
                {id: 5, field: this.$t('message.noticeDetail.tables.coupon.expired_date')},
            ],
            fieldTablePromotion: [
                {id: 1, field: this.$t('message.noticeDetail.tables.promotion.code')},
                {id: 2, field: this.$t('message.noticeDetail.tables.promotion.form')},
                {id: 3, field: this.$t('message.noticeDetail.tables.promotion.release_date')},
                {id: 4, field: this.$t('message.noticeDetail.tables.promotion.expired_date')},
            ]
        }
    },
    props: ['message', 'title', 'content'],
    methods: {
        show(data) {
            if(!data) return;
            this.entries = data ? data.info : [];
            this.type = data ? data.type : [];
            this.totalRecords = data ? data.count : null;
            this.toDate = moment().locale('vi').add(7, 'days').format('DD/MM/YYYY')
            this.entries.sort(function(a, b) { return new Date(a.expired_date) - new Date(b.expired_date) });
            switch (data.type) {
                case this.$constant.typeNoticeProduct:
                    this.titleNotice = this.$t('message.noticeDetail.title_product')
                    this.toDate = moment().locale('vi').add(3, 'M').format('DD/MM/YYYY')
                    this.entries.sort(function(a, b) { return a.daysLeft - b.daysLeft });
                    break;
                case this.$constant.typeNoticeVoucher:
                    this.titleNotice = this.$t('message.noticeDetail.title_voucher')
                    break;
                case this.$constant.typeNoticeCoupon:
                    this.titleNotice = this.$t('message.noticeDetail.title_coupon')
                    break;
                case this.$constant.typeNoticePromotion:
                    this.titleNotice = this.$t('message.noticeDetail.title_promotion')
                    break;
            }
            this.setPaginate();
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        async hide() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        setPaginate(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.entries.length / this.numberRecord);
            this.paginateDetail.totalRecord = this.entries.length;
        },
        linkToProduct(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ProductIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToVoucher(release_code, code){
            var route = null;
            route = this.$router.resolve({
                name: 'VoucherIndex',
                query: {
                    'code': release_code,
                    'detail_code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToCoupon(release_code, code){
            var route = null;
            route = this.$router.resolve({
                name: 'CouponIndex',
                query: {
                    'code': release_code,
                    'detail_code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToPromotion(code){
            var route = null;
            route = this.$router.resolve({
                name: 'PromotionIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style scoped lang="scss">
tbody {
    display: block;
    max-height: 500px;
    overflow: auto;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>
