<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">

                    <h5 class="modal-title py-2">
                        {{ $t('message.SupplierGroupIndex.titleCancelEdit') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    {{ $t('message.SupplierGroupIndex.contentCancelEdit') }}
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalCancelEditSuppliersGroup',
    props: {},
    data() {
        return {
            id: ''
        };
    },
    methods: {
        show(id) {
            this.id = id
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData () {
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('inputData', 1);
        }
    }
}
</script>
<style>

</style>