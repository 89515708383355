import {numberFormat, clone} from "@/lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";


export default {
    mixins: [toVietnamWordMixin],
    methods: {     
        getTextPay(cash, card, transfer) {
            let text = '';
            if (cash && cash != this.$constant.priceZero) {
                text = 'Tiền mặt';
            }
            if (card && card != this.$constant.priceZero) {
                text += (text ?  ', ': '') + 'Thẻ';
            }
            if (transfer && transfer != this.$constant.priceZero) {
                text += (text ?  ', ': '') + 'Chuyển khoản';
            }
            return text;
        },   
        formatShowProduct(dataValue, templateOrder) {
            let htmlObject = document.createElement('div');
            htmlObject.innerHTML = templateOrder;
            var tdTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var searchText4 = "{Ma_PO}";
            var found = '';
            var printSettlementAdvance = false;
            for (var i = 0; i < tdTags.length; i++) {
                if (tdTags[i].textContent.trim() == searchText1 || tdTags[i].textContent.trim() == searchText2 || tdTags[i].textContent.trim() == searchText3) {
                    found = tdTags[i];
                    break;
                }
                if (tdTags[i].textContent.trim() == searchText4) {
                    found = tdTags[i];
                    printSettlementAdvance = true;
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            for (let index = 1; index < dataValue.convertProducts.length; index++) {
                let template = htmlObject.querySelector("#content-product").children[0]; 
                let clone = template.cloneNode(true);
                clone.children[0].innerHTML = '';
                if(printSettlementAdvance){
                    clone.children[1].innerHTML = '';
                }
                let node = document.createElement('tbody');
                node.innerHTML = clone.outerHTML;
                htmlObject.querySelector("#content-product").appendChild(node);
            }
            templateOrder = htmlObject.outerHTML;
            dataValue.convertProducts.forEach(product => {
                product.forEach((data, index) => {
                    templateOrder = templateOrder.replace('{' + data.key + '}', data.value ) ?? '';
                    templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                });
            });
            return templateOrder;
        },
        getProductPrintFormat(data){
            let products = [];
            data.forEach((prd, index) => {
                let arr = {
                    Ten_Hang_Hoa: prd.productName ??  prd.product.name,
                    Don_Vi_Tinh: prd.product_unit ? prd.product_unit.unitName : (prd.unit ? prd.unit.unitName : ''),
                    Don_gia_chiet_khau: numberFormat(prd.price - prd.discountValue),
                    So_Luong: (prd.consignments && prd.consignments.length) ? 0 : prd.quantity,
                    Thanh_Tien: numberFormat(prd.subTotal),
                    Giam_Gia: numberFormat(prd.discountValue),
                    Chiet_Khau: numberFormat(prd.discountValue),
                    Don_Gia: numberFormat(prd.price),
                    Ma_Hang: prd.code ?? prd.product.code,
                    So_Thu_Tu: index + 1,
                    So_Luong_Chuyen: prd.sendQuantity ?? '',
                    So_Luong_Nhan: prd.receiveQuantity ?? '',
                    Gia_Chuyen: prd.sendPrice ?? '',
                    HS_code: prd.hsCode ?? prd.product.hsCode,
                    Gia_Chung: prd.product_unit_main ? numberFormat(prd.product_unit_main.price) : ( prd.product_unit ? numberFormat(prd.product_unit.price) : ''),
                    Han_Su_Dung: '',
                    Hoat_Chat: prd.product.activeIngredient,
                    Hang_San_Xuat: prd.product.manufacturer,
                    Nuoc_San_Xuat: prd.product.country,
                    Quy_Cach_Dong_Goi: prd.product.unit,
                    Mo_Ta_Hang_Hoa: prd.product.description,
                    Thue_Suat: prd.product.taxRate ? prd.product.taxRate + ' %' : '',
                    Don_Gia_Sau_Chiet_Khau: numberFormat(prd.price - prd.discountValue),
                    Nhom_Hang: '', 
                    Vi_Tri_Nhap: prd.locationName ?? '', 
                    Vi_Tri_Xuat: prd.locationName ?? '', 
                    Vi_Tri_Chuyen_Di: prd.fromLocation ?? '', 
                    Vi_Tri_Chuyen_Den: prd.toLocation ?? '', 
                    Thuoc_Tinh_Hang_Hoa: prd?.variant && prd.variant?.name ? prd.variant.name : ''
                }
                if(prd.product && prd.product.product_category){
                    prd.product.product_category.forEach((category, index) => {
                        arr.Nhom_Hang = arr.Nhom_Hang + (category.category ? (index == 0 ? category.category.name : (', ' + category.category.name)) : '');
                    });
                }
                if (prd.consignments && prd.consignments.length) {
                    prd.consignments.forEach((element) => {                        
                        let a = clone(element);
                        let arrClone = clone(arr);
                        arrClone.Han_Su_Dung = a.expried;
                        arrClone.So_Luong_Chuyen = a.sendQuantity;
                        arrClone.So_Luong_Nhan = a.receiveQuantity;
                        arrClone.So_Luong = a.quantity;
                        arrClone.Lo = a.name ?? '';
                        arrClone.Vi_Tri_Nhap = a.locationName ?? '';
                        arrClone.Vi_Tri_Xuat = a.locationName ?? '';
                        arrClone.Vi_Tri_Chuyen_Di = a.fromLocation ?? '';
                        arrClone.Vi_Tri_Chuyen_Den = a.toLocation ?? '';
                        products.push(arrClone);
                    });
                }
                else {
                    products.push(arr);
                }
            });
            return products;
        },
        getTicketDetailPrintFormat(data){
            let tickets = [];
            data.forEach((prd, index) => {
                let arr = {
                    Ma_PO: prd.src ? prd.src.code : '',
                    Trang_Thai_PO: prd.src ? prd.src.statusName : '',
                    Lich_Thanh_Toan: (!prd.planPayment || prd.planPayment == '0000-00-00 00:00:00' ? '' : '-' +  moment(prd.planPayment).format('DD/MM/YYYY')),
                    Ma_NCC: prd.supplier ? prd.supplier.code : '',
                    Ten_NCC: prd.supplier ? prd.supplier.name : '',
                    Hinh_Thuc_Thanh_Toan: prd.typePaymentName ?? '',
                    So_Tai_Khoan: prd.accountNumber ?? '',
                    Chu_Tai_Khoan: prd.accountOwner ?? '',
                    Ngan_Hang: prd.bankBranch ?? '',
                    Tam_Ung: prd.advance != null ? numberFormat(prd.advance) : '',
                    Quyet_Toan: prd.settlement != null ? numberFormat(prd.settlement) : '',
                    Chenh_Lech: prd.settlement != null && prd.advance != null ? numberFormat(prd.settlement - prd.advance) : '',
                    Ma_Phieu_Nhap: '',
                    So_Hoa_Don: prd.statusHasInvoice == this.$constant.status_not_invoice_supplier ? this.$constant.not_invoice_supplier : this.$constant.has_invoice_supplier,
                    Ma_Hoa_Don: '',
                    Ngay_Hoa_Don: '',
                    So_Tien_Hoa_Don: '',
                    Thong_Tin_Hoa_Don: '',
                    Tong_So_Tien_Hoa_Don: prd.totalAmountForInvoice != null ? numberFormat(prd.totalAmountForInvoice) : '',
                    Trang_Thai_Hoa_Don: prd.statusInvoiceName ?? '',
                }
                if(prd.importSupplierCode){
                    prd.importSupplierCode.forEach(element => {
                        arr.Ma_Phieu_Nhap = arr.Ma_Phieu_Nhap !== '' ? (element ? arr.Ma_Phieu_Nhap + '<br>' + element : '') : (element ? element : '');
                    });
                }
                if (prd.src.invoice_suppliers && prd.src.invoice_suppliers.length) {
                    prd.src.invoice_suppliers.forEach((element) => {     
                        arr.Thong_Tin_Hoa_Don = arr.Thong_Tin_Hoa_Don !== '' 
                                                ? (element ? arr.Thong_Tin_Hoa_Don + '<br> <div>' + ((element.code ? element.code : '') + (!element.billDate || element.billDate == '0000-00-00 00:00:00' ? '' : '-' +  moment(element.billDate).format('DD/MM/YYYY')) + (element.amountForInvoice ? '-' +  numberFormat(element.amountForInvoice) : '') + '</div>') : '') 
                                                : (element ? '<br> <div>' + ((element.code ? element.code : '') + (!element.billDate || element.billDate == '0000-00-00 00:00:00' ? '' : '-' +  moment(element.billDate).format('DD/MM/YYYY')) + (element.amountForInvoice ? '-' +  numberFormat(element.amountForInvoice) : '')) + '</div>' : '');
                    });
                }
                tickets.push(arr);
            });
            return tickets;
        },
        async renderData(dataValue){
            let templateOrder = this.content;
            let image = '';
            dataValue.common.forEach(data => {
                switch (data.key) {
                    case 'Logo_Cua_Hang':
                        image = '<img src="' + data.value + '" style=width:200px;>';                
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', image)?? '';
                        break;
                    case 'Tong_Tien_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? '';
                        break;
                    case 'Chiet_khau_hoa_don':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value)) ?? '';
                        break;
                    case 'Tong_cong':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value)) ?? '';
                        break;
                    case 'Chiet_Khau_Hoa_Don_Phan_Tram':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value) + '%') ?? '';
                        break;
                    case 'Ngay':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value)) ?? '';
                        break;
                    default:
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', data.value ) ?? '';
                        break;
                }                  
            });
            return this.formatShowProduct(dataValue, templateOrder);
        },        
        dataPrint(entry) {
            let pros = null;        
            if (entry.typePrint == this.$constant.TypePrintOrder) {
                pros = entry.order_items;
            } else {
                pros = entry.invoice_items ?? (entry.order_items ?? entry.details);
            }  
            let products;
            let tickets;
            if(entry.typePrint != this.$constant.TypePrintSettlementAdvance){
                products = this.getProductPrintFormat(pros); 
            }else{
                tickets = this.getTicketDetailPrintFormat(pros);
            }
            let convertProducts = [];
            let dateOrigin = '';
            entry.total = parseFloat(entry.total);            
            if (entry.invoice_items || entry.order_items) {
                dateOrigin = entry.purchaseDate
            } else if (entry.details) {
                if (entry.type == this.$constant.typeTransfer) {
                    dateOrigin = entry.deliveryDate;
                    entry.totalOrigin = entry.sendPrice;
                    entry.discountValue = 0;
                    entry.branch = entry.from_branch;
                } else if (entry.type == this.$constant.typeRequest) {
                    // 
                } else {
                    dateOrigin = entry.invoice ? entry.invoice.purchaseDate : null;
                    entry.totalOrigin = entry.returnTotal ?? null;
                    entry.discountValue = entry.returnDiscount ?? null;
                }
            }
            let common = [
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry.branch && entry.branch?.address ? entry.branch.address : (entry?.branchName? entry.branchName : '')},
                {key:'Dia_Chi_Cua_Hang', value: entry.branch && entry.branch?.address ? entry.branch.address : (entry?.branchName? entry.branchName : '')},                
                {key:'Phuong_Xa_Chi_Nhanh', value: entry.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch &&  entry.branch?.phone? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_gian_tao_phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ngay_Thang_Nam_Tao', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: ''},
                {key:'Nhan_Vien_Ban_Hang', value: entry.sold_by ? entry.sold_by.name : (entry.invoice?.sold_by && entry.invoice.sold_by?.name ?  entry.invoice.sold_by.name : '')},
                {key:'Ghi_Chu', value: entry.description ?? (entry.note ?? '')},
                {key:'Dien_Thoai_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.phone : ''},
            ];

            if (entry.typePrint == this.$constant.TypePrintOrder) {
                let customerAddress2 = entry.customer ? entry.customer.address2 : '';
                let keyOrder = [
                    {key:'Tieu_De_In',value: "HÓA ĐƠN ĐẶT HÀNG"},
                    {key:'Ma_Dat_Hang_Ban', value: entry.code},
                    {key:'Ma_Khach_Hang', value: entry.customer ? entry.customer.code : ''},
                    {key:'Khach_Hang', value: entry.customer ? entry.customer.name : ''},
                    {key:'So_Dien_Thoai_Khach_Hang', value: entry.customer ? (entry.customer.contactNumber ? entry.customer.contactNumber1 : '') : ''},
                    {key:'Email_Khach_Hang', value: entry.customer ? entry.customer.email : ''},
                    {key:'Facebook_Khach_Hang', value: entry.customer ? entry.customer.facebookUrl : ''},
                    {key:'Nhom_Khach_Hang', value: entry.customerGroups},
                    {key:'Dia_Chi_Khach_Hang', value: entry.customer && entry.customer.address ? entry.customer.address : ''},
                    {key:'Phuong_Xa_Khach_Hang', value: customerAddress2 ? (customerAddress2.wards ? customerAddress2.wards.name : '') : ''},
                    {key:'Khu_Vuc_Khach_Hang_QH_TP', value: (customerAddress2 ? (customerAddress2.districts ? customerAddress2.districts.name : '') : '' )+ ' ' + (customerAddress2 ? (customerAddress2.provinces? customerAddress2.provinces.name : '') : '')},
                    {key:'Ngay_Sinh_Khach_Hang', value: entry.customer ? moment(entry.customer.birthDate).format('DD/MM/YYYY') : ''},
                    {key:'Gioi_Tinh_Khach_Hang', value: (entry.customer && entry.customer.gender) ? 'Nam' : (entry.customer && entry.customer.gender === 0 ? 'Nữ' : '')},
                    {key:'Ghi_Chu_Khach_Hang',value: entry.customer ? entry.customer.comments : ''},
                    {key:'Ma_So_Thue',value: entry.customer ? entry.customer.taxCode : '' },
                    {key:'Cong_Ty',value: entry.customer ? entry.customer.companyName : '' },
                    {key:'Ten_Bang_Gia', value: entry.priceListName},
                    {key:'Kenh_Ban_Hang', value: entry.saleChannelName},
                    {key:'Chiet_Khau', value: ''},
                    {key:'Chiet_Khau_Phan_Tram', value: ''},
                    {key:'Thanh_Tien_Truoc_GGSP', value: ''},
                    {key:'Tong_So_Luong_Mat_Hang', value: entry.order_items.length},
                    {key:'Tong_So_Luong', value: entry.totalProduct},
                    {key:'Tong_Tien_Hang_Truoc_GGSP', value: entry.totalOrigin},
                    {key:'Tong_Tien_Hang', value: entry.totalOrigin},
                    {key:'Chiet_Khau_Hoa_Don', value: numberFormat(entry.discountValue?? 0)},
                    {key:'Chiet_Khau_Hoa_Don_Phan_Tram', value: entry.discountRatio?? ''},
                    {key:'Tong_Tien_Hang_Tru_CKHD', value: numberFormat((+entry.totalOrigin ?? 0) - (+entry.discountValue ?? 0))},
                    {key:'Thu_Khac', value: numberFormat(entry.surchargeAmount ?? 0)},
                    {key:'Chuong_Trinh_Khuyen_Mai', value: entry.promotion ? entry.promotion.name : ''},
                    {key:'Gia_Tri_Khuyen_Mai_HĐ', value: numberFormat(entry.promotionValue ?? 0)},
                    {key:'KM', value: ''},
                    {key:'Ma_Voucher', value: ''},
                    {key:'Ma_Coupon', value: ''},
                    {key:'Tong_cong', value: entry.total},                    
                    {key:'Tong_Cong_Bang_Chu', value: entry.total ? this.toVietnamWord(+entry.total) : this.toVietnamWord(0)},
                    {key:'Tien_Khach_Dua', value: numberFormat(+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0))},
                    {key:'Tien_Khach_Dua_Bang_Chu', value: +(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) ? this.toVietnamWord(+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0)) : this.toVietnamWord(0)},
                    {key:'Tien_Thua_Tra_Khach', value: numberFormat(((+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) - entry.total) < 0 ? 0 : (+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) - entry.total)))},
                    {key:'Tong_Thanh_Toan', value: ''},
                    {key:'Tong_Thanh_Toan_Bang_Chu', value: ''},
                    {key:'Tong_Can_Thanh_Toan', value: ''},
                    {key:'Tong_Can_Thanh_Toan_Bang_Chu', value: ''},
                    {key:'Ten_Phuong_Thuc_TToan', value: this.getTextPay(entry.cash, entry.card, entry.transfer)},
                    {key:'Tien_Theo_Phuong_Thuc_TToan', value: ''},
                    {key:'Giam_Gia_Don_Hang_Theo_Tien', value: numberFormat(entry.discountValue?? 0)},
                    {key:'Giam_Gia_Don_Hang_Phan_Tram', value: entry.discountRatio ?? ''},
                    {key:'Dien_Thoai_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.phone : ''},
                    {key:'Email_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.email : ''},
                    {key:'Nguoi_Tao', value: entry.user_created ? entry.user_created.name : ''},
                ];
                common.push(...keyOrder)
            }

            if (entry.typePrint == this.$constant.TypePrintInvoice) {
                let customerAddress2 = entry.customer ? entry.customer.address2 : '';
                let voucherCode = '';
                let couponCode = '';
                if(entry.voucher_detail && entry.voucher_detail.length){
                    entry.voucher_detail.forEach((voucher, index) => {
                        voucherCode = voucherCode + (voucher.code ? (index == 0 ? voucher.code : (', ' + voucher.code)) : '');
                    });
                }
                if(entry.coupon_detail && entry.coupon_detail.code){
                    couponCode = entry.coupon_detail.code;
                }
                let keyOrder = [
                    {key:'Tieu_De_In',value: "HÓA ĐƠN BÁN HÀNG"},
                    {key:'Thu_Ngan', value: ''},
                    {key:'Ma_Hoa_Don', value: entry.code},
                    {key:'Ma_Khach_Hang', value: entry.customer ? entry.customer.code : ''},
                    {key:'Khach_Hang', value: entry.customer ? entry.customer.name : ''},
                    {key:'So_Dien_Thoai_Khach_Hang', value: entry.customer ? (entry.customer.contactNumber != null ? entry.customer.contactNumber : '') : ''},
                    {key:'Email_Khach_Hang', value: entry.customer ? entry.customer.email : ''},
                    {key:'Facebook_Khach_Hang', value: entry.customer ? entry.customer.facebookUrl : ''},
                    {key:'Nhom_Khach_Hang', value: entry.customerGroups ?? ''},
                    {key:'Dia_Chi_Khach_Hang', value: entry.customer && entry.customer.address ? entry.customer.address : ''},
                    {key:'Phuong_Xa_Khach_Hang', value: customerAddress2 ? (customerAddress2.wards ? customerAddress2.wards.name : '') : ''},
                    {key:'Khu_Vuc_Khach_Hang_QH_TP', value: (customerAddress2 ? (customerAddress2.districts ? customerAddress2.districts.name : '') : '' )+ ' ' + (customerAddress2 ? (customerAddress2.provinces? customerAddress2.provinces.name : '') : '')},
                    {key:'Ngay_Sinh_Khach_Hang', value: entry.birthDate ? moment(entry.birthDate).format('DD/MM/YYYY') : (entry.customerBirthDate ? moment(entry.customerBirthDate).format('DD/MM/YYYY') : '')},
                    {key:'Gioi_Tinh_Khach_Hang', value: entry.gender ? 'Nam' : (entry.gender === 0 ? 'Nữ' : (entry.customer ? (entry.customer.gender ? 'Nam' : (entry.customer.gender == 0 ? 'Nữ' : '')) : '')) },
                    {key:'Ghi_Chu_Khach_Hang',value: entry.customer ? entry.customer.comments : '' },
                    {key:'Ma_So_Thue',value: entry.customer ? entry.customer.taxCode : '' },
                    {key:'Cong_Ty',value: entry.customer ? entry.customer.companyName : '' },
                    {key:'Ten_Bang_Gia', value: entry.priceListName},
                    {key:'Kenh_Ban_Hang', value: entry.saleChannelName},
                    {key:'Tong_So_Luong_Mat_Hang', value: entry.invoice_items.length},
                    {key:'Tong_So_Luong', value: entry.totalProduct},
                    {key:'Tong_Tien_Hang_Truoc_GGSP', value: entry.totalOrigin},
                    {key:'Tong_Tien_Hang', value: entry.totalOrigin},
                    {key:'Giam_Gia_Hoa_Don_Theo_Tien', value: numberFormat(entry.discountValue?? '')},
                    {key:'Giam_Gia_Hoa_Don_Phan_Tram', value: numberFormat(entry.discountRatio?? '')},
                    {key:'Tong_Tien_Hang_Tru_CKDH', value: numberFormat((+entry.totalOrigin ?? 0) - (+entry.discountValue ?? 0))},
                    {key:'Thu_Khac', value: numberFormat(entry.invoiceOrderSurcharges ?? 0)},
                    {key:'Chuong_Trinh_Khuyen_Mai', value: entry.promotion ? entry.promotion.name : ''},
                    {key:'Gia_Tri_Khuyen_Mai_DH', value: numberFormat(entry.promotionValue ?? 0)},
                    {key:'KM', value: ''},
                    {key:'Ma_Voucher', value: voucherCode},
                    {key:'Gia_Tri_Voucher', value: numberFormat(entry.valueVoucher)},
                    {key:'Ma_Coupon', value: couponCode},
                    {key:'Gia_Tri_coupon', value: numberFormat(entry.valueCoupon)},
                    {key:'Tong_cong', value: entry.total},                    
                    {key:'Tong_Cong_Bang_Chu', value: entry.total ? this.toVietnamWord(+entry.total) : this.toVietnamWord(0)},
                    {key:'Tien_Khach_Dua', value: numberFormat(+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0))},
                    {key:'Tien_Khach_Dua_Bang_Chu', value: +(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) ? this.toVietnamWord(+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0)) : this.toVietnamWord(0)},
                    {key:'Tien_Thua_Tra_Khach', value: numberFormat(((+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) - entry.total) < 0 ? 0 : (+(entry.cash ?? 0) + +(entry.card ?? 0) + +(entry.transfer ?? 0) - entry.total)))},
                    {key:'Tong_Thanh_Toan', value: entry.totalPaymentOrigin ? numberFormat(+entry.totalPaymentOrigin) : ''},
                    {key:'Tong_Thanh_Toan_Bang_Chu', value: entry.totalPaymentOrigin ? this.toVietnamWord(+entry.totalPaymentOrigin) : ''},
                    {key:'Tong_Can_Thanh_Toan', value: ''},
                    {key:'Tong_Can_Thanh_Toan_Bang_Chu', value: ''},
                    {key:'Ten_Phuong_Thuc_TToan', value: this.getTextPay(entry.cash, entry.card, entry.transfer)},
                    {key:'Tien_Theo_Phuong_Thuc_TToan', value: ''},
                    {key:'Giam_Gia_Don_Hang_Theo_Tien', value: ''},
                    {key:'Giam_Gia_Don_Hang_Phan_Tram', value: ''},
                    {key:'Nhan_Vien_Ban_Hang', value: entry.sold_by ? entry.sold_by.name : ''},
                    {key:'Dien_Thoai_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.phone : ''},
                    {key:'Email_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.email : ''},
                    {key:'Chiet_Khau_Hoa_Don', value: numberFormat(entry.discountValue ?? 0)},
                    {key:'Chiet_Khau_Hoa_Don_Phan_Tram', value: entry.discountRatio ?? ''},
                    {key:'Nguoi_Tao', value: entry.user_created ? entry.user_created.name : ''},
                ]
                common.push(...keyOrder)
            }
            if (entry.typePrint == this.$constant.TypePrintTransfer) {
                let keyTransfer = [
                    {key:'Tieu_De_In',value: "PHIẾU CHUYỂN HÀNG"},
                    {key:'Ma_Chuyen_Hang', value: entry.code},
                    {key:'Ma_Goi_Hang', value: entry.refCode ? entry.refCode : ''},
                    {key:'Ngay_Thang_Nam_Chuyen', value: entry.deliveryDate ?  moment(entry.deliveryDate).format('DD/MM/YYYY') : ''},
                    {key:'Ngay_Thang_Nam_Nhan', value: entry.receivedDate ? entry.receivedDate  : ''},
                    {key:'Trang_Thai', value: entry.statusName ? entry.statusName : ''},
                    {key:'Chi_Nhanh_Chuyen', value: entry.from_branch ? entry.from_branch.name : ''},
                    {key:'Dia_Chi_Chi_Nhanh_Chuyen', value: entry.from_branch ? entry.from_branch.address : ''},
                    {key:'Phuong_Xa_Chi_Nhanh_Chuyen', value: entry.form_branch && entry.form_branch.ward ? entry.form_branch.ward.name : ''},
                    {key:'Khu_Vuc_Chi_Nhanh_Chuyen', value: entry.form_branch && entry.form_branch.district ? entry.form_branch.district.name : ''},
                    {key:'Dien_Thoai_Chi_Nhanh_Chuyen', value: entry.from_branch ? entry.from_branch.phone : '' }, 
                    {key:'Ghi_Chu_Chi_Nhanh_Chuyen', value: entry.sendDescription ? entry.sendDescription : '' },
                    {key:'Nguoi_Chuyen', value: entry.created_by_user ? entry.created_by_user.name : ''  },
                    {key:'Chi_Nhanh_Nhan',value: entry.to_branch ? entry.to_branch.name : '' },
                    {key:'Dia_Chi_Chi_Nhanh_Nhan',value: entry.to_branch ? entry.to_branch.address : '' },
                    {key:'Phuong_Xa_Chi_Nhanh_Nhan',value: entry.to_branch && entry.to_branch.ward ? entry.to_branch.ward.name : '' },
                    {key:'Khu_Vuc_Chi_Nhanh_Nhan', value: entry.to_branch && entry.to_branch.district ? entry.to_branch.district.name : ''},                    {key:'Dien_Thoai_Chi_Nhanh_Nhan', value: entry.phone ?? ''},
                    {key:'Ghi_Chu_Chi_Nhanh_Nhan', value: entry.receiveDescription ? entry.receiveDescription : ''},

                    {key:'Nguoi_Nhan', value: entry.received_by_user ? entry.received_by_user.name : ''},

                    // {key:'Gia_Chuyen', value: entry.sendPrice ?? ''},

                    {key:'Thanh_Tien_Hang_Chuyen', value: entry.totalOrigin ?? ''},
                    {key:'Thanh_Tien_Hang_Nhan', value: entry.totalReceiveQuantity ?? ''},

                    {key:'Tong_So_Luong_Mat_Hang_Chuyen', value: entry.totalSendQuantity ?? ''},
                    {key:'Tong_So_Luong_Mat_Hang_Nhan', value: entry.totalReceiveQuantity ?? ''},
                    // {key:'Kenh_Ban_Hang', value: ''},
                    {key:'Tong_Gia_Tri_Chuyen', value: entry.sendPrice ?? 0},
                    {key:'Tong_Gia_Tri_Chuyen_Bang_Chu', value: this.toVietnamWord(entry.sendPrice ?? 0)},
                    {key:'Tong_Gia_Tri_Nhan', value: entry.receivePrice ?? 0},
                    {key:'Tong_Gia_Tri_Nhan_Bang_Chu', value: this.toVietnamWord(entry.receivePrice ?? 0)},
                ];
                common.push(...keyTransfer)
            }
            if (entry.typePrint == this.$constant.TypePrintReturn) {
                let customerAddress2 = entry.customer ? entry.customer.address2 : '';
                let invoice = entry.invoice;
                if (invoice.typeDiscount) {
                    invoice.priceDiscountRatio = invoice.priceDiscount/invoice.totalPrice;
                } else {
                    invoice.priceDiscountRatio = '';
                }
                let keyReturn = [
                    {key:'Tieu_De_In', value: "HÓA ĐƠN TRẢ HÀNG"},
                    {key:'Ma_Tra_Hang', value: entry.code},
                    {key:'Ma_Hoa_Don_Mua', value: invoice.code},
                    {key:'Ngay_Thang_Nam_Mua', value: moment(invoice.purchaseDate).format('DD/MM/YYYY')},
                    {key:'Nhan_Vien_Ban_Hang', value: invoice.sold_by ? invoice.sold_by.name : ''},
                    {key:'Dien_Thoai_Nguoi_Ban', value: invoice.sold_by ? invoice.sold_by.phone : ''},
                    {key:'Email_Nguoi_Ban', value: invoice.sold_by ? invoice.sold_by.email : ''},
                    {key:'Ma_Khach_Hang', value: entry.customer ? entry.customer.code : ''},
                    {key:'Khach_Hang', value: entry.customer ? entry.customer.name : ''},
                    {key:'So_Dien_Thoai_Khach_Hang', value: entry.customer ? (entry.customer.contactNumber ? entry.customer.contactNumber1 : '') : ''},
                    {key:'Email_Khach_Hang', value: entry.customer ? entry.customer.email : ''},
                    {key:'Facebook_Khach_Hang', value: entry.customer ? entry.customer.facebookUrl : ''},
                    {key:'Nhom_Khach_Hang', value: entry.customerGroups},
                    {key:'Dia_Chi_Khach_Hang', value: entry.customer && entry.customer.address ? entry.customer.address : ''},
                    {key:'Phuong_Xa_Khach_Hang', value: customerAddress2 ? (customerAddress2.wards ? customerAddress2.wards.name : '') : ''},
                    {key:'Khu_Vuc_Khach_Hang_QH_TP', value: (customerAddress2 ? (customerAddress2.districts ? customerAddress2.districts.name : '') : '' )+ ' ' + (customerAddress2 ? (customerAddress2.provinces? customerAddress2.provinces.name : '') : '')},
                    {key:'Ngay_Sinh_Khach_Hang', value: entry.birthDate ? moment(entry.birthDate).format('DD/MM/YYYY') : ''},
                    {key:'Gioi_Tinh_Khach_Hang', value: entry.gender ? 'Nam' : (entry.gender === 0 ? 'Nữ' : '') },
                    {key:'Ghi_Chu_Khach_Hang', value: '' },
                    {key:'Ma_So_Thue', value: entry.customer ? entry.customer.taxCode : ''},
                    {key:'Cong_Ty', value: entry.customer ? entry.customer.companyName : '' },
                    {key:'Ten_Bang_Gia', value: entry.priceListName},
                    {key:'Kenh_Ban_Hang', value: entry.saleChannelName},
                    {key:'Tong_So_Luong_Mat_Hang', value: entry.details.length},
                    {key:'Tong_So_Luong', value: entry.totalProduct},
                    {key:'Tong_Tien_Tra_Hang', value: entry.returnTotal},
                    {key:'Phi_Tra_Hang', value: entry.returnFee},
                    {key:'Chuong_Trinh_Khuyen_Mai', value: invoice.promotion ? invoice.promotion.name : ''},
                    {key:'Gia_Tri_Khuyen_Mai_ĐH', value: numberFormat(invoice.promotionValue)},
                    {key:'Gia_Tri_Voucher', value: entry.valueVoucher},
                    {key:'Gia_Tri_coupon', value: entry.valueCoupon},
                    {key:'Ma_Coupon', value: invoice.couponDetail ? invoice.coupon.couponDetail.code : ''},
                    {key:'Ma_Voucher', value: invoice.voucherDetail ? invoice.voucherDetail.code : ''},
                    {key:'Can_Tra_Khach', value: numberFormat(entry.returnCustomer)},
                    {key:'Can_Tra_Khach_Bang_Chu', value: this.toVietnamWord(entry.returnCustomer)},
                    {key:'Giam_Gia_Tra_Hang', value: numberFormat(entry.priceDiscount)},
                    {key:'Giam_Gia_Tra_Hang', value: numberFormat(entry.priceDiscount)},
                    {key:'Giam_Gia_Tra_Hang_Phan_Tram', value: numberFormat(entry.priceDiscountRatio)},
                ]
                common.push(...keyReturn)
            }
            if (entry.typePrint == this.$constant.TypePrintRefund) {   
                let address2 = null;   
                if(entry.invoice && entry.invoice.customer)      
                {
                    address2  = entry.invoice && entry.invoice?.customer.address2 && entry.invoice.customer?.address2 ? entry.invoice.customer.address2 : null  ;
                }
                else {
                    address2 = null;
                }
               
                let keyReturn = [
                    {key:'Tieu_De_In', value: "ĐỀ NGHỊ HOÀN HÀNG"},
                    {key:'Ma_Hoa_Don', value: entry.invoiceCode},                    
                    {key:'Thoi_Gian_Duyet', value: entry.approvalDate ?? ''},
                    {key:'Trang_Thai', value: entry.status?? 0},
                    {key:'Tong_So_Luong', value: entry.totalQuantity?? 0},
                    {key:'Giam_Gia', value: entry.discountValue?? ''},                                        
                    {key:'Da_Tra_Khach', value: entry.customer ? (entry.customer.contactNumber ? entry.customer.contactNumber1 : '') : ''},                    
                    {key:'Tong_Tien_Hang', value: entry.total},                    
                    {key:'Can_Tra_Khach', value: numberFormat(entry.invoice.totalPayment)},
                    {key:'Khach_Hang', value: entry.invoice?.customer && entry.invoice.customer?.name ? entry.invoice.customer.name : ''},
                    {key:'Dia_Chi_Khach_Hang', value: address2?.addressValue ? address2?.addressValue : ''},
                    {key:'Phuong_Xa_Khach_Hang', value: address2?.wards ? address2.wards.name : ''},
                    {key:'Khu_Vuc_Khach_Hang_QH_TP', value: address2?.districts && address2?.provinces  ? address2.districts.name + (address2?.provinces.name.length > 0 ? ' - ' + address2?.provinces.name : '') : ''},                   
                ]
                common.push(...keyReturn)
            }
            if (entry.typePrint == this.$constant.TypePrintSettlementAdvance) {
                let keyOrder = [
                    {key:'Tieu_De_In',value: "TẠM ỨNG QUYẾT TOÁN"},
                    {key:'Ma_Phieu', value: entry.code ? entry.code : ''},
                    {key:'Chi_Nhanh', value: entry.branchName ? entry.branchName : ''},
                    {key:'Nguoi_Tao', value: entry.createdBy ? entry.createdBy : ''},
                    {key:'Ghi_Chu', value: entry.description ? entry.description : ''},
                    {key:'Tong_Tam_Ung', value: entry.totalAmountSettlement != null ? numberFormat(entry.totalAmountSettlement) : ''},
                    {key:'Tong_Quyet_Toan', value: entry.totalAmountAdvance != null ? numberFormat(entry.totalAmountAdvance) : ''},
                    {key:'Tong_Chenh_Lech', value: entry.totalAmountCompare != null ? numberFormat(entry.totalAmountCompare) : ''},
                    {key:'So_Hoa_Don_Con_No', value: entry.totalDebtInvoice != null ? numberFormat(entry.totalDebtInvoice) : ''},
                ];
                common.push(...keyOrder)
            }
            if (entry.typePrint == this.$constant.TabTicketImport) {
                let keyOrder = [
                    {key:'Tieu_De_In',value: this.$t('message.print_template_convert.ticket_import.title')},
                    {key:'Ma_Phieu', value: entry.code ? entry.code : ''},
                    {key:'Chi_Nhanh', value: entry.branch && entry.branch.name ? entry.branch.name : ''},
                    {key:'Nguoi_Tao', value: entry.createdBy ? entry.createdBy : ''},
                    {key:'Chung_Tu_Goc', value: entry.codeOriginalDocuments ? entry.codeOriginalDocuments : ''},
                    {key:'Ghi_Chu', value: entry.note ? entry.note : ''},
                    {key:'Tong_So_Luong', value: entry.totalProducts != null ? numberFormat(entry.totalProducts) : ''},
                    {key:'Tong_So_Mat_Hang', value: entry.totalTypeProducts != null ? numberFormat(entry.totalTypeProducts) : ''},
                    {key:'So_Luong_Vi_Tri_Da_Nhap', value: entry.numberLocation != null ? numberFormat(entry.numberLocation) : ''},
                ];
                common.push(...keyOrder)
            }
            if (entry.typePrint == this.$constant.TabTicketExport) {
                let keyOrder = [
                    {key:'Tieu_De_In',value: this.$t('message.print_template_convert.ticket_export.title')},
                    {key:'Ma_Phieu', value: entry.code ? entry.code : ''},
                    {key:'Chi_Nhanh', value: entry.branch && entry.branch.name ? entry.branch.name : ''},
                    {key:'Nguoi_Tao', value: entry.createdBy ? entry.createdBy : ''},
                    {key:'Chung_Tu_Goc', value: entry.codeOriginalDocuments ? entry.codeOriginalDocuments : ''},
                    {key:'Ghi_Chu', value: entry.note ? entry.note : ''},
                    {key:'Tong_So_Luong', value: entry.totalProducts != null ? numberFormat(entry.totalProducts) : ''},
                    {key:'Tong_So_Mat_Hang', value: entry.totalTypeProducts != null ? numberFormat(entry.totalTypeProducts) : ''},
                    {key:'So_Luong_Vi_Tri_Da_Xuat', value: entry.numberLocation != null ? numberFormat(entry.numberLocation) : ''},
                ];
                common.push(...keyOrder)
            }
            if (entry.typePrint == this.$constant.TabTicketTransfer) {
                let keyOrder = [
                    {key:'Tieu_De_In',value: this.$t('message.print_template_convert.ticket_transfer.title')},
                    {key:'Ma_Phieu', value: entry.code ? entry.code : ''},
                    {key:'Chi_Nhanh', value: entry.branch && entry.branch.name ? entry.branch.name : ''},
                    {key:'Nguoi_Tao', value: entry.createdBy ? entry.createdBy : ''},
                    {key:'Chung_Tu_Goc', value: entry.codeOriginalDocuments ? entry.codeOriginalDocuments : ''},
                    {key:'Ghi_Chu', value: entry.note ? entry.note : ''},
                    {key:'Tong_So_Luong', value: entry.totalProduct != null ? numberFormat(entry.totalProduct) : ''},
                    {key:'Tong_So_Mat_Hang', value: entry.totalTypeProduct != null ? numberFormat(entry.totalTypeProduct) : ''},
                ];
                common.push(...keyOrder)
            }
            if(products && products.length){
                products.forEach(product => {
                    convertProducts.push([
                        {key:'Ten_Hang_Hoa', value: product.Ten_Hang_Hoa ?? ''},
                        {key:'Don_Vi_Tinh', value: product.Don_Vi_Tinh ?? ''},
                        {key:'Don_gia_chiet_khau', value: product.Don_gia_chiet_khau ?? ''},
                        {key:'So_Luong', value: product.So_Luong ?? ''},
                        {key:'Thanh_Tien', value: product.Thanh_Tien ?? ''},   
                        {key:'Don_Gia', value: product.Don_Gia ?? ''},   
                        {key:'Giam_Gia', value: product.Giam_Gia ?? ''},
                        {key:'Ma_Hang', value: product.Ma_Hang ?? ''},
                        {key:'STT', value: product.So_Thu_Tu ?? '' },
                        {key:'So_Luong_Chuyen', value: product.So_Luong_Chuyen ?? ''},
                        {key:'So_Luong_Nhan', value: product.So_Luong_Nhan ?? ''},
                        {key: 'Gia_Chuyen', value: numberFormat(product.Gia_Chuyen) },
                        {key: 'HS_Code', value: product.HS_code ?? ''},
                        {key: 'Gia_Chung', value: product.Gia_Chung ?? ''},
                        {key: 'Han_Su_Dung', value: moment(product.Han_Su_Dung).format('DD/MM/YYYY') ?? ''},
                        {key: 'Hoat_Chat', value: product.Hoat_Chat ?? ''},
                        {key: 'Hang_San_Xuat', value: product.Hang_San_Xuat ?? ''},
                        {key: 'Nuoc_San_Xuat', value: product.Nuoc_San_Xuat ?? ''},
                        {key: 'Don_Gia_Sau_Chiet_Khau', value: product.Don_Gia_Sau_Chiet_Khau ?? ''},
                        {key: 'Quy_Cach_Dong_Goi', value: product.Quy_Cach_Dong_Goi ?? ''},
                        {key: 'Mo_Ta_Hang_Hoa', value: product.Mo_Ta_Hang_Hoa ?? ''},
                        {key: 'Nhom_Hang', value: product.Nhom_Hang ?? ''},
                        {key: 'Thue_Suat', value: product.Thue_Suat ?? ''},
                        {key: 'Lo', value: product.Lo ?? ''},
                        {key: 'Chiet_Khau', value: product.Chiet_Khau ?? ''},
                        {key: 'Vi_Tri_Nhap', value: product.Vi_Tri_Nhap ?? ''},
                        {key: 'Vi_Tri_Xuat', value: product.Vi_Tri_Xuat ?? ''},
                        {key: 'Vi_Tri_Chuyen_Di', value: product.Vi_Tri_Chuyen_Di ?? ''},
                        {key: 'Vi_Tri_Chuyen_Den', value: product.Vi_Tri_Chuyen_Den ?? ''},
                        {key: 'Thuoc_Tinh_Hang_Hoa', value: product.Thuoc_Tinh_Hang_Hoa ?? ''},
                    ])
                });            
            }
            if(tickets && tickets.length){
                tickets.forEach(ticket => {
                    convertProducts.push([
                        {key:'Ma_PO', value: ticket.Ma_PO ?? ''},
                        {key:'Trang_Thai_PO', value: ticket.Trang_Thai_PO ?? ''},
                        {key:'Lich_Thanh_Toan', value: ticket.Lich_Thanh_Toan ?? ''},
                        {key:'Ma_NCC', value: ticket.Ma_NCC ?? ''},
                        {key:'Ten_NCC', value: ticket.Ten_NCC ?? ''},   
                        {key:'Hinh_Thuc_Thanh_Toan', value: ticket.Hinh_Thuc_Thanh_Toan ?? ''},   
                        {key:'So_Tai_Khoan', value: ticket.So_Tai_Khoan ?? ''},
                        {key:'Chu_Tai_Khoan', value: ticket.Chu_Tai_Khoan ?? ''},
                        {key:'Ngan_Hang', value: ticket.Ngan_Hang ?? '' },
                        {key:'Tam_Ung', value: ticket.Tam_Ung ?? ''},
                        {key:'Quyet_Toan', value: ticket.Quyet_Toan ?? ''},
                        {key: 'Chenh_Lech', value: ticket.Chenh_Lech },
                        {key: 'Ma_Phieu_Nhap', value: ticket.Ma_Phieu_Nhap ?? ''},
                        {key: 'So_Hoa_Don', value: ticket.So_Hoa_Don ?? ''},
                        {key: 'Ma_Hoa_Don', value: ticket.Ma_Hoa_Don ?? ''},
                        {key: 'Ngay_Hoa_Don', value: ticket.Ngay_Hoa_Don ?? ''},
                        {key: 'So_Tien_Hoa_Don', value: ticket.So_Tien_Hoa_Don ?? ''},
                        {key: 'Tong_So_Tien_Hoa_Don', value: ticket.Tong_So_Tien_Hoa_Don ?? ''},
                        {key: 'Thong_Tin_Hoa_Don', value: ticket.Thong_Tin_Hoa_Don ?? ''},
                        {key: 'Trang_Thai_Hoa_Don', value: ticket.Trang_Thai_Hoa_Don ?? ''},
                    ])
                }); 
            }
            return {common, convertProducts};
        },
        formatproductPrintCommon(data) {
            let dataPrint = clone(data);
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            dataPrint.details.forEach(item  => {
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }
                let product = clone(item);
                product.product.name = product.product.name + ' ( ' + product.unit.unitName + ' )';
                if (product.consignment_detail && product.consignment_detail.length) {
                    // product.consignment_detail.forEach(detail => { 
                    //     product.consignments.name = detail.consignment.name,
                    //     product.consignments.expried = detail.consignment.expried,
                    //     product.quantity = detail.quantity,
                    //     product.discount = (item.discount/item.quantity) * detail.quantity;
                    //     product.subTotal = (product.price*detail.quantity) - product.discount;
                    //     totalPrice += +product.subTotal;
                    // });
                    products.push(clone(product));
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));
                }
                if (product && product.plus) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        totalPrice += +plus.subTotal;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            // plus.product.consignments = product.product.consignments;
                            // plus.consignment_detail.forEach(detail => {
                            //     plus.consignment = {};
                            //     plus.consignment.name = detail.consignment.name;
                            //     plus.consignment.expried = detail.consignment.expried;
                            //     plus.quantity = detail.quantity;
                            //     plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                            //     plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                            // });
                           
                            products.push(clone(plus));
                        } else {
                            products.push(clone(plus));
                        }
                    });
                }
            });
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            return dataPrint;
        },
        getTitleImformation(action){            
            let list = [
                {id: this.$constant.TabOrder, name: 'Đặt hàng'},
                {id: this.$constant.TabInvoices, name: 'Hóa đơn'},
                {id: this.$constant.TabDeliveries, name: 'Giao hàng'},
                {id: this.$constant.TabReturns, name: 'Trả hàng'},  
                {id: this.$constant.TabOrderSuppliers, name: 'Đặt hàng nhập'},
                {id: this.$constant.TabImports, name: 'Nhập hàng'},
                {id: this.$constant.TabReturnSuppliers, name: 'Trả hàng nhập'},
                {id: this.$constant.TabTransfers, name: 'Chuyển hàng'},
                {id: this.$constant.TabReceipts, name: 'Phiếu thu'},
                {id: this.$constant.TabPayment, name: 'Phiếu chi'},
                {id: this.$constant.TabRefunds, name: 'Đề nghị hoàn hàng'},
                {id: this.$constant.TabSettlementAdvance, name: 'Tạm ứng quyết toán'},
                {id: this.$constant.TabTicketImport, name: 'Nhập vị trí'},
                {id: this.$constant.TabTicketExport, name: 'Xuất vị trí'},
                {id: this.$constant.TabTicketTransfer, name: 'Chuyển vị trí'},
                {id: this.$constant.TabPlanDetails, name: 'Dự trù chi tiết'},
                {id: this.$constant.TabPlanImportBuy, name: 'Dự trù nhập mua'},
                {id: this.$constant.TabDiscountBas, name: 'BAS'},
            ];
            let name = '';
            list.map(item => {
                if(item.id == action) name = item.name;  
            })                        
            return name;
        }
    },
}
  