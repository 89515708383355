<template>
    <AppPermission :permission="this.$permissions.bankbooks.data?? '' ">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{ $t("message.BankBookIndex.title") }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8 text-title">
                                    <label>{{$t("message.BankBookIndex.numberRecord") }}:</label>
                                </div>
                                <div class="col-4">
                                    <select name="number_records" id="number_records" @change="onChange($event)"
                                        class="number_records">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t("message.BankBookIndex.filter.payment_type") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('typeBody', 'typeDown', 'typeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="typeDown"></i>
                                        <i class="fa fa-chevron-up" id="typeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="typeBody">
                            <div class="row" v-for="entry in this.listType" :key="entry.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{entry.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('type', entry.id)" :checked="isExistInArrayChecked(entry, this.filterQuery.type)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.BankBookIndex.filter.branch") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branchId" 
                                    :modelValue="filterQuery.branchId" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.BankBookIndex.filter.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.BankBookIndex.filter.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branchId'"/> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Start created at -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t("message.BankBookIndex.filter.time") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>
                    <!-- Start date of birth -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.BankBookIndex.filter.created_by") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('createdByBody', 'createdByDown', 'createdByUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="createdByDown"></i>
                                        <i class="fa fa-chevron-up" id="createdByUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="createdByBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.BankBookIndex.filter.placeholder.created_by')" :modelValue="filterQuery.createdBy" :defaultOption="this.users" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Start tổng bán -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.BankBookIndex.filter.user") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('userBody', 'userDown', 'userUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="userDown"></i>
                                        <i class="fa fa-chevron-up" id="userUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="userBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.BankBookIndex.filter.placeholder.user')" :modelValue="filterQuery.userId" :defaultOption="this.users" @update:modelValue="doFilterSearch" :label="'userId'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End tổng bán -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.BankBookIndex.filter.object_payment_receive") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('objectBody', 'objectDown', 'objectUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="objectDown"></i>
                                        <i class="fa fa-chevron-up" id="objectUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="objectBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.common.all')" :readonly="true" :modelValue="filterQuery.objectFilter" :defaultOption="this.listObjectPaymentReceipt" @update:modelValue="doFilterSearch" :label="'objectFilter'"></SelectSearch>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <input type="text" class="form-control border-bottom-1 rounded-0"
                                    @keydown.enter="doFilter('name', filterQuery.name)" v-model.trim="filterQuery.name" 
                                    :placeholder="$t('message.CashBookIndex.filter.placeholder.name')">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <input type="text" class="form-control border-bottom-1 rounded-0"
                                    @keydown.enter="doFilter('phone', filterQuery.phone)" v-model.trim="filterQuery.phone" 
                                    :placeholder="$t('message.CashBookIndex.filter.placeholder.phone')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="mainRight">
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.BankBookIndex.placeholder.search')" @filterSearch="changeQuickSearch" :bankBookSearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">                                
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i
                                        class="fa fa-filter mr-1"></i>{{ $t("message.BankBookIndex.button.clearFilter") }}
                                </button>
                                <!-- nút Thao tác  -->
                                 <AppPermission :permission="this.$permissions.bankbooks.create ?? ''">
                                    <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0" >
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-v px-2"></i>{{ $t("message.BankBookIndex.button.operation") }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item"  @click="exDetails()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.CashBookIndex.button.export')}}
                                            </a>
                                        </div>
                                 </div>
                                </AppPermission>
                                <!-- Nút thêm mới -->
                                <AppPermission :permission="this.$permissions.bankbooks.create ?? ''">
                                    <div class="dropdown">
                                        <button @click="showModalPrepareReceipt()" class="btn btn-save" type="button" id="dropdownMenuButton"
                                            style="padding:7px"
                                            aria-expanded="false">
                                            <i class="fa fa-plus px-1"></i>
                                            {{ $t("message.BankBookIndex.button.receipts") }}
                                        </button>
                                    </div>
                                </AppPermission>
                                <!-- Nút xuất file -->
                                <AppPermission :permission="this.$permissions.bankbooks.export ?? ''">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton" @click="exOverview()"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true"
                                            aria-expanded="false">
                                            <i class="fas fa-file-export px-1"></i>{{ $t("message.BankBookIndex.button.export") }}
                                        </button>
                                    </div>
                                </AppPermission>
                            </div>
                        </div>
                        <div class="row mx-0 mt-2 bg-light p-2">
                            <div class="col-12 text-right">
                                <h6 class="text-bold">{{$t('message.BankBookIndex.totalAmountRevenue')}}</h6>
                                <span class="text-success" v-text="n(this.totalAmountRevenue)"></span>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" />
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped w-100" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class=""/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries?.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                    <tr class="master-row" :class="entry.id === this.bankBookActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td class="checkbox-table">
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected && field.field" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="text-dark tagA" @click="detailBankBook(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="" :class="field.class"></div>
                                                                </a>
                                                            </td>                                                        
                                                        </template>
                                                    </tr>
                                                    <tr v-if="bankBookActive === entry.id" :class="[ entry.id === bankBookActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td class="checkbox-table"></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2">
                                                                    <span class="backgroup__detail-active head-detail">
                                                                        <span>
                                                                            {{$t('message.ProductIndex.info')}}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiết phiếu chuyển hàng -->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="bankBookActive === entry.id">
                                                        <td class="checkbox-table"></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4">
                                                                <div class="row mt-2 m-0">
                                                                    <div class="col-4">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t("message.BankBookIndex.detail.code") }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">                                                            
                                                                                <b>{{ entry.code }}</b>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{ $t("message.BankBookIndex.detail.time") }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                {{ dt(entry.createdAt) }}
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t("message.BankBookIndex.detail.value") }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" 
                                                                                v-text="entry.amount != null ? n(entry.amount) : null">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t("message.BankBookIndex.detail.method") }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.methodOrigin == this.$constant.type_bank_book_card ? $t('message.BankBookIndex.method.value.card') : $t('message.BankBookIndex.method.value.banking')"></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t("message.BankBookIndex.detail.payer") }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                <span v-if="entry.paidBy"></span>
                                                                                <span v-else-if="entry.customer" v-text="entry.customer.name"></span>
                                                                                <span v-else v-text="$t('message.CashBookIndex.guest')"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t("message.BankBookIndex.detail.phone") }}: 
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-if="entry.customer" v-text="entry.customer.contactNumber">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="mx-1">
                                                                            <div class="col-12">
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t("message.BankBookIndex.detail.address") }}: 
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        <template v-if="entry.customer">
                                                                                            <span v-text="entry.customer.address2 ? entry.customer.address2.addressValue : null"></span>
                                                                                            <span v-text="entry.customer.address2 && entry.customer.address2.wards ? ', ' + entry.customer.address2.wards.name : null"></span>
                                                                                            <span v-text="entry.customer.address2 && entry.customer.address2.districts ? ', ' + entry.customer.address2.districts.name : null"></span>
                                                                                            <span v-text="entry.customer.address2 && entry.customer.address2.provinces ? ', ' + entry.customer.address2.provinces.name : null"></span>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 lableOS">
                                                                                        {{ $t("message.BankBookIndex.detail.branch") }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="entry.branch ? entry.branch?.name : null"></div>
                                                                                </div>
                                                                            
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t("message.BankBookIndex.detail.created_by") }}:
                                                                                    </div>                                                                
                                                                                    <div class="col-7 ow-break-word" v-text="entry.created_by ? entry.created_by?.name : null"></div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 d-flex align-items-center">
                                                                                        {{ $t("message.BankBookIndex.detail.user") }}:
                                                                                    </div>                                                                
                                                                                    <div class="col-7 ow-break-word" v-text="entry.user ? entry.user?.name : null"></div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5 d-flex align-items-center">
                                                                                        {{ $t("message.BankBookIndex.detail.object_payer") }}:
                                                                                    </div>                                                                
                                                                                    <div class="col-7 ow-break-word">
                                                                                        <template v-if="entry.paidBy">
                                                                                            <span v-text="entry.paidBy"></span>
                                                                                        </template>
                                                                                        <template v-else-if="this.documentSrc && this.documentSrc.supplier">
                                                                                            {{$t('message.CashBookIndex.supplier')}}
                                                                                        </template>
                                                                                        <template v-else-if="this.documentSrc && (this.documentSrc.customer || entry.delivery)">
                                                                                            {{$t('message.CashBookIndex.customer')}}
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4" id="noteRO">  
                                                                        <div class="row p-0 font-italic">
                                                                            <div class="col-12" v-if="entry.statusOrigin != this.$constant.BANK_STATUS_CANCEL">
                                                                                <textarea v-model.trim="entry.note" :id="'note_'+ entry.id" 
                                                                                :placeholder="$t('message.BankBookIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                            </div>
                                                                            <div class="col-12 text-gray" v-else>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                <span class="noteStyle px-4 text-gray font-italic">
                                                                                    {{ entry.note ? entry.note : $t('message.BankBookIndex.note')}}
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__body-detail" v-if="bankBookActive === entry.id">
                                                        <td scope="col" class="checkbox-table border-top-0"></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4">
                                                                <div class="row mt-4 pr-4">
                                                                    <div class="col-6 font-italic">
                                                                        <span class="text-gray" v-text="this.toLink"></span>
                                                                        <span class="text-primary" @click="linkToDocumentSrc(entry)" v-text="this.documentSrc && this.documentSrc.code? ' ' + this.documentSrc.code : ' ---'"></span>
                                                                    </div>
                                                                    <div class="col-6 flex-end">
                                                                        <AppPermission :permission="this.$permissions.bankbooks.update ?? ''">
                                                                            <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.statusOrigin != this.$constant.BANK_STATUS_CANCEL">
                                                                                <i class="fa fa-save"></i>
                                                                                {{$t('message.BankBookIndex.button.save')}}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <!-- 
                                                                            <AppPermission :permission="this.$permissions.bankbooks.update ?? ''">
                                                                            <button type="button" class="btn btn-save mx-1" @click="updateTicket(entry)" v-if="entry.typeSrc == this.$constant.type_ticket_receipt_cash_book || entry.typeSrc == this.$constant.type_ticket_payment_cash_book">
                                                                            <i class="fa fa-check-square"></i>
                                                                        {{$t('message.CashBookIndex.button.update')}}
                                                                        </button> 
                                                                        <button type="button" class="btn btn-save mx-1" @click="showBankBook(entry)">
                                                                            <i class="fas fa-file-export"></i>
                                                                            Mở phiếu
                                                                        </button> 
                                                                        </AppPermission>
                                                                        -->
                                                                        <!-- <button type="button" class="btn btn-danger mx-1" @click.stop="cancel(entry.id, entry.code)" v-if="entry.statusOrigin != this.$constant.CASH_STATUS_CANCEL">
                                                                            <i class="fas fa-file-export"></i>
                                                                            Hủy phiếu
                                                                        </button> -->
                                                                        <AppPermission :permission="this.$permissions.bankbooks.print?? ''">
                                                                            <button type="button" class="btn btn-secondary mr-2" @click.stop="print(entry)">
                                                                                <i class="fa fa-print mr-1"></i>
                                                                                {{$t('message.button.print.name')}}
                                                                            </button>
                                                                        </AppPermission>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="bankBookActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries?.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalPrepareReceipt ref="ModalPrepareReceipt" @updated="this.load()"/>
        <ModalUpdatePrepareReceipt ref="ModalUpdatePrepareReceipt" @updated="this.load()"/>

        <ModalDetailBankBook ref="ModalDetailBankBook" @updated="this.load()" @print="print"/>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent" />
        <PrintTemplate ref='PrintTemplate' />
    </AppPermission>
</template>
<script>
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
import store from "../../store";
import SelectDateTime from "@/components/SelectDateTime.vue";
import SelectSearch from "@/components/SelectSearch.vue";
import emitter from "@/lib/emitter";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import ModalPrepareReceipt from "./ModalPrepareReceipt";
import ModalUpdatePrepareReceipt from "./tabs/ModalUpdatePrepareReceipt";
import http from '@/services/api';
import ModalDetailBankBook from "./tabs/ModalDetailBankBook";
import CashBookMixin from '@/CashBookMixin';
import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate.vue';
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import PrintTemplate from '@/components/PrintTemplate.vue';
import AppPermission from '@/components/AppPermission.vue';
import QSelect from "@/components/QSelect";
export default {
    name: "BankBookIndex",
    mixins: [CashBookMixin],
    components: {
        SelectDateTime,
        SelectSearch,
        TableSortField,
        ModalPrepareReceipt,
        Pagination, 
        ModalUpdatePrepareReceipt,
        ModalDetailBankBook,
        ModalSelectPrintTemplate,
        PrintTemplate,
        InputQuickSearch,
        AppPermission,
        QSelect
    },
    mounted() {
        document.title = this.$t('message.BankBookIndex.document_title');

        const fields = localStorage.getItem('bankbookSelectedFields_' + this.user.id);
        if (fields) {
            this.fields = JSON.parse(fields);
        }
        const query = clone(this.$route.query);
        if (query['code'] != null) {
            this.filterQuery['bank_book_code'] = query['code'];
            this.loadDetail();
        } else {
            this.clearFilter();
        }
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            filter: {
                keyword: '',
                customerGroupSearch: '',
                gender: '',
                status: '',
                typeCustomer: '',
                branch: '',
                createdBy: '',
                createdAt: '',
                branchId: '',
                birth_day: '',
                address: '',
                bank_book_code: '',
                note: '',
            },
            filterQuery: {
                keyword: '',
                status: [],
                branches: '',
                createdBy: '',
                userId: '',
                type: [],
                typeTicket: [],
                orderBy: '',
                saleChannel: '',
                paymentMethod: '',
                priceList: '',
                branchId: [],
                time: '',
                objectFilter: '',
                name: '',
                phone: '',
            },
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            users: globalConfig.users,
            user: store.state.Auth ? store.state.Auth.user : {},
            fields: [],
            entries: [],
            arrayChecked: [],
            listObjectPaymentReceipt: [],
            loading: false,
            isFilter: false,
            totalAmountRevenue: 0,
            bankBookActive: null,
            documentSrc: null,
            toLink: null,
        }
    },
    created() {
        this.getData();
    },
    methods: {
        async updateFilterBranch(values) {
            this.filterQuery.branchId = [...values];
            this.filterQuery.page = 1;
            this.isFilter = true;
            await this.load();
        },
        getData() {
            this.$store.dispatch('userStore/getData');
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/bank-book/index',
                query: {}
            });

            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });
            Object.keys(this.filterQuery).forEach(key => {
                if (key == 'type') {
                    this.filterQuery[key] = [this.$constant.type_bank_book_card, this.$constant.type_bank_book_transfer];
                } else if (key == 'export') {
                    this.filterQuery[key] = false;
                } else {
                    this.filterQuery[key] = '';
                }
            });
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("resetSelectDateTime", 'month');
            this.arrayChecked = [];
            this.numberRecord = 15;
            // this.sortTime = 0;
            this.filterQuery.branchId = [store.state.CurrentBranch.id];
            this.bankBookActive = null;
            this.isFilter = false;
            this.documentSrc = null;
            this.toLink = null;
            await this.load();
        },
        async loadDetail(){
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("resetSelectDateTime", 'month');
            this.filterQuery.branchId = [store.state.CurrentBranch.id];
            this.bankBookActive = null;
            await this.load();
            this.entries.forEach((entry) => {
                this.detailBankBook(entry.id, entry);
            });
        },
        showHide(name, iDown, iUp) {
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        doFilterTime(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async doFilter(field, value) {
            const query = clone(this.filterQuery);
            if(field == 'status'){
                if(query['status'].includes(value)){
                    query['status'] = query['status'].filter(item => item !== value)
                }else{
                    query['status'].push(value)
                }
            }else if(field == 'type'){
                if(query['type'].includes(value)){
                    query['type'] = query['type'].filter(item => item !== value)
                }else{
                    query['type'].push(value)
                }
            }else if(field == 'typeTicket'){
                if(query['typeTicket'].includes(value)){
                    query['typeTicket'] = query['typeTicket'].filter(item => item !== value)
                }else{
                    query['typeTicket'].push(value)
                }
            }else{
                query[field] = value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async changeQuickSearch(object){
            const query = clone(this.filterQuery);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filter[key] = obj[o];
                            query[o] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete query[o];
                        }
                    });
                })
            });
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        isShow() {
            let isShow = false;
            this.fields.forEach(field => {
                if (field.selected) {
                    isShow = true;
                }
            });
            return isShow;
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.filterQuery);
            query['page'] = 1;
            this.isFilter = true;
            this.filterQuery = {...query};
            await this.load();
        },
        async pageChange(page) {
            const query = clone(this.filterQuery);
            query['page'] = page;
            this.isFilter = true;
            this.filterQuery = {...query};
            await this.load();
        },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post('/v1/bank-book/index', { ...this.$route.query, ...this.filterQuery, record: this.numberRecord });
            if (res.result.code !== 200) {
                $alert(res);
                return;
            }
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.data.fields;
            }
            this.entries = res.result.data.data.data;     
            this.paginate.totalRecord = res.result.data.data.total;
            this.paginate.currentPage = res.result.data.data.current_page;
            this.paginate.lastPage = res.result.data.data.last_page;

            if (res.result.data.listStatus) {
                this.listStatus = res.result.data.listStatus;
            }
            if (res.result.data.listType) {
                this.listType = res.result.data.listType;
            }
            if (res.result.data.listObjectPaymentReceipt) {
                this.listObjectPaymentReceipt = res.result.data.listObjectPaymentReceipt;
            }
            if (res.result.data.objects) {
                this.objects = res.result.data.objects;
            }
            if (res.result.data.listTypePaymentReceipts) {
                this.listTypeTicket = res.result.data.listTypePaymentReceipts;
            }
            this.totalAmountRevenue = res.result.data.totalAmountRevenue ?? 0;
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
        },
        async quickSave(entry){
            if(entry.note && entry.note.length > 255){
                $alert({code: 422, message: this.$t('message.BankBookIndex.alerts.validate_error_note')})
            }
            const res = await $post('/v1/bank-book/quick-save', {
                id: entry.id,
                note: entry.note,
            });
            if(res.result.status == true){
                $alert({
                    code: 200,
                    message: this.$t('message.BankBookIndex.alerts.update_success', {code: entry.code}),
                });
                this.load();
            }else{
                $alert({
                    code: 403,
                    message: this.$t('message.BankBookIndex.alerts.update_error', {code: entry.code}),
                });
            }
        },
        isExistInArrayChecked(entry, array = []) {
            if (array.includes(entry.id)) {
                entry.checked = true;
                return true;
            } else {
                entry.checked = false;
                return false;
            }
        },
        isCheckedAll() {
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if (found) {
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if (getChecked > 0 && getChecked == this.entries.length) {
                this.checkedAll = true;
            } else {
                this.checkedAll = false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        onCheckedAll(value) {
            setArray(this.entries, {
                checked: value
            });
            if (value == true) {
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if (!found) {
                        this.countChecked(entry.id);
                    }
                })
            } else {
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }            
        },
        showModalPrepareReceipt() {
            this.$refs.ModalPrepareReceipt.show(this.$constant.ticket_receipt_cash_book, this.objects, this.listType);
        },
         // Xuất file
        getOSByID(entries) {                
            let id = [];
            entries.forEach(item => {
                id.push(item.id);
            });
            return id;
        },
        async exOverview() {
            emitter.emit("onLoading");                  
            const res = await http.download('/v1/bank-book/select-export', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});            
            const fileName = res.headers['content-disposition'].split('filename=')[1];
            var _blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();       
            emitter.emit("offLoading");     
        },   
        async exDetails(){
            emitter.emit("onLoading");      
                const result = await http.download('/v1/bank-book/select-export', {id: this.arrayChecked});
                const fileName = result.headers['content-disposition'].split('filename=')[1];
                var _blob = new Blob([result.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.setAttribute('download', fileName);
                link.click();       
            emitter.emit("offLoading");    
        },
        async dataExport(){
                const response = await $post('/v1/bank-book/ex-data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});                          
                if(response && response.status.code == 200 && response.result.code == 200 ){                    
                    this.exEntries = response.result.data.data;                    
                }        
        },   
        // In phiếu
        async print(entry){            
            // Chọn mẫu in
            entry.typePrint = this.$constant.TypePrintReceipts;  
            const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabReceipts'});            
            if(res && res.status.code == 200){
                if(res.result) {                    
                    this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                }
            }                                                             
        },
        async printTemplates(entry) {                    
            entry.typePrint = this.$constant.TypePrintReceipts;
            let dataPrint = await this.dataPrint(entry);
            this.editorDataClone = await this.renderData(dataPrint);
            await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);
        },
        // Emit từ modal
        async updateContent(data){                   
           this.content = data.content;
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {                    
                    await this.startPrintMultiple(data.dataPrint);
                } else {                    
                    await this.printTemplates(data.dataPrint);          
                }        
        },
        // In multiple phiếu
        async startPrintMultiple(returns) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < returns.length; index++) {
                    let order = returns[index];
                    order.typePrint = this.$constant.TypePrintReceipts;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint);
                    if (index != returns.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.OrderPrint.multiplePrint(this.editorDataClone); 
        },
        detailBankBook(id, entry){
            this.documentSrc = null;
            this.toLink = null;
            if (id === this.bankBookActive) {
                this.bankBookActive = null;
            } else {
                this.bankBookActive = id;
            }
            if(entry){
                if(entry.invoice){
                    this.documentSrc = entry.invoice;
                    this.toLink = this.$t('message.BankBookIndex.toLink.invoice_receipt');
                }
                if(entry.order){
                    this.documentSrc = entry.order;
                    this.toLink = this.$t('message.BankBookIndex.toLink.order_receipt');
                }
                if(entry.return_import_for_supplier){
                    this.documentSrc = entry.return_import_for_supplier;
                    this.toLink = this.$t('message.BankBookIndex.toLink.return_import_for_supplier_receipt');
                }
                if(entry.delivery){
                    this.documentSrc = entry.delivery;
                    this.toLink = this.$t('message.BankBookIndex.toLink.delivery_receipt');
                }
            }
        },
        showBankBook(entry){
            this.$refs.ModalDetailBankBook.show(entry);
        },
        updateTicket(entry){
            this.$refs.ModalUpdatePrepareReceipt.show(entry.type, this.objects, entry, this.listType);
        },
        linkToInvoice(code){
            var route = null;
            route = this.$router.resolve({
                name: 'InvoiceIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'OrderIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToReturnPurchaseOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnPurchaseOrder',
                query: {
                    'return_purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToDelivery(entry){
            var route = null;
            route = this.$router.resolve({
                name: 'DeliveryIndex',
                query: {
                    'code': entry.code ?? null,
                    'id': entry.id ?? null,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToDocumentSrc(entry){
            if(entry.invoice){
                this.linkToInvoice(entry.invoice.code);
                return;
            }else if(entry.order){
                this.linkToOrder(entry.order.code);
                return;
            }else if(entry.return_import_for_supplier){
                this.linkToReturnPurchaseOrder(entry.return_import_for_supplier.code);
                return;
            }else if(entry.delivery){
                this.linkToDelivery(entry.delivery);
                return;
            }
        },
    },
}
</script>

<style scoped>#number_records {
    margin-top: 11px;
}

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.text-title {
    line-height: 52px;
}

#typeCard {
    margin-top: -8px;
}

#noteRO {
    border-left: 1px solid #ccc;
}

.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}

#fa_edit {
    position: absolute;
    left: 20px;
    top: 4px;
    color: #a7a2a2;
}</style>
