<template>
    <div class="d-flex align-items-center mb-2 consignment-space">
        <template v-if="this.arrayConsignmentChoose?.length > 0">
            <template v-for="(consignment, index) in this.arrayConsignmentChoose" :key="index">
                <!-- <template v-if="!consignment.deleted"> -->
                    <div class="consignment_name text-white ml-1 mt-1">
                    <span v-text="consignment.name ? consignment.name 
                    + (consignment.expried ? ' - ' + d(consignment.expried) : '')
                    + (consignment.quantity && !this.isSaleScreen ? ' - ' + $t('message.common.shortQuantity') + ':' + consignment.quantity : '') : ''"></span>
                    <i v-if="!hideOption && !this.noRemove"  class="fa fa-times ml-2 text-white" @click="removeConsignment(consignment.id)"></i>
                    </div>
                <!-- </template> -->
            </template>
        </template>
        <div class="border-left ml-1" v-if="(this.isSaleScreen || this.returnSupplier) && this.arrayConsignmentChoose?.length > 0">
        </div>
        <div class="border-left ml-1" v-else>
            <form data-toggle="dropdown" aria-expanded="false">
                <input type="text" :placeholder="$t('message.common.enterBatch')" autocomplete="off" class="border-none bg-transparent h-100 consignment_input pl-3 mr-4">
            </form>
            <template v-if="!this.isSaleScreen && !this.noAddConsignment">
                <div class="dropdown-menu dropdown-menu-content shadow">
                    <a class="dropdown-item" href="javascript:void(0);" @click.stop="addConsignment()">
                        {{$t('message.common.addBatch')}}
                    </a>
                    <template v-for="(consignment, i) in this.listConsignment" :key="i">
                        <a class="dropdown-item text-warning" :class="(!this.isSaleScreen && consignment.disabled) ? 'cursor-ban' : ''" href="javascript:void(0);" v-show="this.isSaleScreen ? (consignment.hidden ? false : true) : consignment.deleted" @click="(!this.isSaleScreen && consignment.disabled) ? null : (this.isSaleScreen ? applyConsignment(consignment) : setConsignment(consignment))">
                            {{ consignment.name + ' - ' + d(consignment.expried) + ' - ' + $t('message.ProductUnitSearch.inventory') + ': ' + (consignment.inventory ? n(consignment.inventory) : '')}}
                        </a>
                    </template>
                </div>
            </template>
            <template v-else>
                <div class="dropdown-menu dropdown-menu-content shadow" v-show="showListConsignment">
                    <template v-for="(consignment, i) in this.listConsignment" :key="i">
                        <a class="dropdown-item text-warning" :class="(!this.isSaleScreen && consignment.disabled) ? 'cursor-ban' : ''" href="javascript:void(0);" v-show="this.isSaleScreen ? (consignment.hidden ? false : true) : consignment.deleted" @click="(!this.isSaleScreen && consignment.disabled) ? null : (this.isSaleScreen ? applyConsignment(consignment) : setConsignment(consignment))">
                            {{ consignment.name + ' - ' + d(consignment.expried) + ' - ' + $t('message.ProductUnitSearch.inventory') + ': ' + (consignment.inventory ? n(consignment.inventory) : '')}}
                        </a>
                    </template>
                </div>
            </template>
        </div>
        <a v-if="!hideOption" class="text-primary text-decoration-none" href="javascript:void(0);" @click="chooseConsignment()">
            {{$t('message.common.selectBatch')}}
        </a>
    </div>
    <div class="modal mx-0 fade" tabindex="-1" role="dialog" ref="modalAddConsignment" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title" v-if="!this.isSettingConsignment">{{$t('message.common.addBatchFor')}} {{ this.object?.code }}</h6>
                    <h6 class="modal-title" v-if="this.isSettingConsignment">{{name + ' - ' + $t('message.noticeDetail.tables.product.expired') + ': ' + d(expried) }}</h6>
                    <button type="button" class="close" aria-label="Close" @click="cancelModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0">
                        <div class="col-4">{{$t('message.common.batch')}}</div>
                        <div class="col-8 border-bottom p-0">
                            <input type="text" v-model.trim="name" class="form-control border-none pl-2" autocomplete="off" :readonly="this.isSettingConsignment">
                        </div>
                    </div>
                    <div class="row mt-3 mx-0">
                        <div class="col-4">{{$t('message.noticeDetail.tables.product.expired')}}</div>
                        <div class="col-8 border-bottom p-0">
                            <input type="date" v-model="expried" class="form-control border-none pl-2" placeholder="''" autocomplete="off" :readonly="this.isSettingConsignment">
                        </div>
                    </div>
                    <div class="row mt-3 mx-0">
                        <div class="col-4">{{$t('message.ProductIndex.quantity')}}</div>
                        <div class="col-4 p-0">
                            <InputNumberFormat v-model="quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                            <!-- <input type="number" v-model="quantity" :min="1" class="form-control text-right border-none pl-2" autocomplete="off"> -->
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-save mr-2" @click="submitConsignment()">
                                <i class="fa fa-check mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.finished')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="cancelModal()">
                                <i class="fa fa-ban mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal mx-0 fade" tabindex="-1" role="dialog" ref="modalChooseConsignment" data-backdrop="static">
        <div class="modal-dialog modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">{{$t('message.common.selectBatchExpire')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="cancelModal()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0">
                        <div class="col-12 d-flex align-items-center position-relative p-0 border-bottom-1 border-bottom-focus">
                            <i class="fa fa-search ml-2 text-secondary"></i>
                            <input type="text" v-model.trim="search" class="form-control border-none pl-2" autocomplete="off" 
                            :placeholder="$t('message.common.searchBatch')" @input="onInputSearch">
                        </div>
                    </div>
                    <template v-if="this.listConsignment?.length > 0">
                        <template v-for="(consignment, index) in this.listConsignment" :key="index">
                            <template v-if="consignment.consignment_product_branch_metas && consignment.consignment_product_branch_metas.length">
                                <div class="row mt-3 mx-0" v-if="!consignment.hidden">
                                    
                                    <div class="col-9" >
                                        <div class="consignment_name text-white ml-1 mt-1" 
                                            :class="[(consignment.deleted ? 'bg-white border text-dark' : null), (!this.isSaleScreen && consignment.disabled ? 'cursor-ban' : '')]" 
                                            @click.stop="(!this.isSaleScreen && consignment.disabled) ? null : (this.isSaleScreen ? changeConsignmentApply(consignment.id) : changeChooseConsignment(index))">
                                            <span v-text="consignment.name ? consignment.name 
                                                        + (consignment.expried ? ' - ' + d(consignment.expried) : '') + ' - ' + $t('message.ProductUnitSearch.inventory') + ':' + (consignment.inventory != null ? n(consignment.inventory) : '')
                                                        : ''">
                                            </span>
                                            <!-- <template v-for="(item, key) in consignment.consignment_product_branch_metas" :key="key">
                                                <template v-if="object.hasVariant && object && object.variantId && item.product_branch_meta.productUnitId == object.variantId">
                                                    <span v-text="(item.inventories ? n(item.inventories)  : '')"></span>
                                                </template>
                                                <template v-else-if="!object.hasVariant && object && object.productUnitId && item.product_branch_meta.productUnitId == object.productUnitId">
                                                    <span v-text="(item.inventories ? n(item.inventories) : '')"></span>
                                                </template>
                                            </template> -->
                                        </div>
                                    </div>
                                    <div class="col-3 p-0" v-if="this.isSaleScreen && !consignment.deleted">
                                        <InputNumberFormat v-if="this.defaultValue && this.defaultValue.maxQuantity" v-model="consignment.quantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="this.defaultValue.maxQuantity != null ? this.defaultValue.maxQuantity : ''" @input="validateInputValue(consignment)"></InputNumberFormat>
                                        <InputNumberFormat v-else v-model="consignment.quantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                    </div>
                                    <div class="col-3 p-0" v-else-if="!this.isSaleScreen">
                                        <InputNumberFormat v-if="consignment.maxQuantity" :readonly="consignment.disabled" v-model="consignment.quantityDraft" :min="1" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignment.maxQuantity"></InputNumberFormat>
                                        <InputNumberFormat v-else :readonly="consignment.disabled" v-model="consignment.quantityDraft" :min="1" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                    </div>
                                </div>
                            </template>
                            
                            <template v-else>
                                <div class="row mt-3 mx-0" v-show="!consignment.hidden">
                                    <div class="col-9">
                                        <div class="consignment_name text-white ml-1 mt-1" :class="consignment.deleted ? 'bg-white border text-dark' : null" @click.stop="this.isSaleScreen ? changeConsignmentApply(consignment.id) : changeChooseConsignment(index)">
                                            <span v-text="consignment.name ? consignment.name 
                                        + (consignment.expried ? ' - ' + d(consignment.expried) : '')
                                        + ' - ' + $t('message.ProductUnitSearch.inventory') + ':' + (consignment.inventory != null ? n(consignment.inventory) : '') : ''"></span>
                                        </div>
                                    </div>
                                    <div class="col-3 p-0" v-if="this.isSaleScreen && !consignment.deleted">
                                        <InputNumberFormat v-if="this.defaultValue && this.defaultValue.maxQuantity" v-model="consignment.quantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="this.defaultValue.maxQuantity != null ? this.defaultValue.maxQuantity : ''" @input="validateInputValue(consignment)"></InputNumberFormat>
                                        <InputNumberFormat v-else v-model="consignment.quantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                    </div>
                                    <div class="col-3 p-0" v-else-if="!this.isSaleScreen">
                                        <InputNumberFormat v-if="consignment.maxQuantity" v-model="consignment.quantityDraft" :min="0" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true" :max="consignment.maxQuantity"></InputNumberFormat>
                                        <InputNumberFormat v-else v-model="consignment.quantityDraft" :min="1" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                    <template v-else>
                        <div class="row mt-3 mx-0">
                            <div class="col-12 d-flex align-items-center justify-content-center my-4">
                                {{$t('message.common.notFoundBatch')}}
                            </div>
                        </div>
                    </template>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row m-0">
                        <div class="col-12 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-save mr-2" @click="save()">
                                <i class="fa fa-save mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.save')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="cancelModal()">
                                <i class="fa fa-ban mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {removeVietnameseTones, $alert, $post, forEach, clone} from "@/lib/utils";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import emitter from "@/lib/emitter";
import moment from 'moment';
import store from "@/store";
export default {
    name: "ChooseConsignment",
    components: {
        InputNumberFormat
    },
    props:  ['placeholder', 'modelValue', 'defaultOption', 'label', 'disabled', 'action', 'readonly', 'indexComboProducts',
     'object', 'isSaleScreen', 'defaultValue', 'noAddConsignment', 'index', 'plusIndex', 'hideOption', 'noRemove', 'noChooseOtherConsignment',
      'isReturn', 'isTransfer', 'isConfirm', 'returnSupplier', 'indexConsigmentActive', 'isUpdate'],
    methods: {
        addConsignment(){
            this.name = null;
            this.expried = null;
            this.quantity = 1;
            window.jQuery(this.$refs.modalAddConsignment).modal('show');
        },
        chooseConsignment(){
            this.search = null;
            this.listConsignment.forEach(element => {
                const found = this.arrayConsignmentChoose.find(el => el.id == element.id);
                if(found){
                    element.quantityDraft = found.quantity ? found.quantity : element.quantity;
                    element.deleted = false;
                }else{
                    element.deleted = true;
                }
                if(this.isSaleScreen && this.defaultValue){
                    element.quantityDraft = this.defaultValue.quantity ?? 1;
                }
                if (!element.quantityDraft) {
                    element.quantityDraft = 1;
                }
            });
            this.defaultListConsignment = JSON.parse(JSON.stringify(this.listConsignment));
            window.jQuery(this.$refs.modalChooseConsignment).modal('show');
        },
        validateInputValue(consignment){
            if(this.defaultValue && this.defaultValue.maxQuantity){
                if(this.defaultValue.maxQuantity < consignment.quantityDraft){
                    consignment.quantityDraft = this.defaultValue.maxQuantity;
                    $alert({code: 422, message: this.$t('message.common.quantityNotGreaterThan') + " " + this.defaultValue.maxQuantity});
                }
            }
        },
        onInputSearch(){
            if(this.search.length == 0){
                this.listConsignment = this.defaultListConsignment;
                return;
            }
            let arr = [];
            for (let index = 0; index < this.defaultListConsignment.length; index++) {
                if (this.defaultListConsignment[index].name != null && removeVietnameseTones(this.defaultListConsignment[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.search.toLowerCase())) > -1){
                    arr.push(this.defaultListConsignment[index]);
                }else if(this.defaultListConsignment[index].expried != null && removeVietnameseTones((moment(this.defaultListConsignment[index].expried).format('DD/MM/YYYY')).toLowerCase()).indexOf(removeVietnameseTones(this.search.toLowerCase())) > -1){
                    arr.push(this.defaultListConsignment[index]);
                }
            }
            this.listConsignment = arr;
        },
        async submitConsignment(){
            if(this.isSettingConsignment){
                this.listConsignment.forEach(element => {
                    if(element.name == this.name && moment(element.expried).format('YYYY-MM-DD') == this.expried){
                        let params = clone(element);
                        params.quantity = this.quantity;
                        params.quantity = params.quantityDraft = this.quantity;
                        // let params = {
                        //     id: element.id ?? null,
                        //     name: element.name,
                        //     expried: element.expried,
                        //     quantity: this.quantity,
                        //     quantityDraft: this.quantity,
                        // };
                        this.arrayConsignmentChoose.push(params);
                        element.deleted = false;
                        element.quantityDraft = this.quantity;
                    }
                });
                this.isSettingConsignment = false;
                this.changeConsignment();
                this.cancelModal();
            }else{

                if(!this.name){
                    $alert({code: 422, message: this.$t('message.common.emptyProductBatch')});
                    return;
                }
                if(!this.expried){
                    $alert({code: 422, message: this.$t('message.common.inValidExpire')});
                    return;
                }
                var date = moment(this.expried).format('YYYY-MM-DD 23:59:59');
                var now = moment().format('YYYY-MM-DD hh:mm:ss');

                if (now > date) {
                    $alert({code: 422, message: this.$t('message.common.exprieNotLessThanToday')});
                    return;
                }
                let isExist = false;
                this.listConsignment.forEach(consignment => {
                    if(consignment.name == this.name && consignment.expried == this.expried){
                        isExist = true;
                        $alert({code: 422, message: this.$t('message.common.existBatch') + ' ' + this.object?.code});
                        return;
                    }
                });
                if(!isExist){
                    this.params = {
                        name: this.name,
                        start: moment().format('YYYY-MM-DD'),
                        expried: this.expried ? moment(this.expried).format('YYYY-MM-DD 23:59:59') : null,
                        quantity: this.quantity,
                        quantityDraft: this.quantity,
                        branchId: this.branchId,
                        productId: this.object.id,
                        productName: this.object?.code
                    };
                    const result = await $post('/v1/consignments/create', {...this.params});
                    if(result.code === 422){
                        let errors = result.messages.errors;
                        Object.keys(errors).forEach(function(key) {
                            $alert({code: result.code, message: errors[key][0]});
                        });
                        return;
                    }
                    let newItem = result.result;
                    newItem.quantity = newItem.quantityDraft = this.quantity;
                    newItem.hidden = false;
                    this.listConsignment.push(newItem);
                    this.arrayConsignmentChoose.push(newItem);
                    this.listConsignmentNewAdd.push(newItem);
                    this.isSettingConsignment = false;
                    this.changeConsignment();
                    this.cancelModal();
                }
            }
        },
        cancelModal(){
            this.isSettingConsignment = false;
            this.name = null;
            this.expried = null;
            this.quantity = 1;
            if(this.defaultListConsignment && this.defaultListConsignment?.length > 0){
                this.listConsignment = JSON.parse(JSON.stringify(this.defaultListConsignment));
            }
            window.jQuery(this.$refs.modalAddConsignment).modal('hide');
            window.jQuery(this.$refs.modalChooseConsignment).modal('hide');
            // this.changeConsignment();
        },
        removeConsignment(id){
            this.arrayConsignmentChoose.splice(this.arrayConsignmentChoose.findIndex(v => v.id == id), 1);
            this.listConsignment.forEach(element => {
                if(element.id && element.id == id){
                    element.deleted = true;
                    this.showListConsignment = true;
                }
            });
            this.$emit('update:value', {data: this.arrayConsignmentChoose, indexComboProducts:this.indexComboProducts, object: this.object, defaultValue: this.defaultValue, index: this.index, plusIndex: this.plusIndex, indexConsigmentActive: this.indexConsigmentActive})
        },
        save(){
            this.arrayConsignmentChoose = [];
            this.listConsignment.forEach((consignment) => {
                if(!consignment.deleted){
                    consignment.quantity = consignment.quantityDraft;
                    if(consignment.quantity || this.isConfirm){
                        this.arrayConsignmentChoose.push(consignment);
                    }
                }
            })
            this.changeConsignment();
            this.cancelModal();
        },
        changeConsignment(){
            this.$emit('update:value', {data: this.arrayConsignmentChoose, indexComboProducts:this.indexComboProducts, object: this.object, defaultValue: this.defaultValue, index: this.index, plusIndex: this.plusIndex, indexConsigmentActive: this.indexConsigmentActive})
        },
        changeChooseConsignment(index){
            this.listConsignment.forEach((consignment, ind) => {
                if(ind == index){
                    if(this.noRemove){
                        const found = this.defaultOptionSelected.find(el => el.id == consignment.id);
                        if(!found){
                            consignment.deleted = consignment.deleted ? !consignment.deleted : true;
                            consignment.quantityDraft = 1;
                        }
                    }else{
                        consignment.deleted = consignment.deleted ? !consignment.deleted : true;
                        consignment.quantityDraft = 1;
                    }
                }
                
            })
        },
        chooseConsignmentActive(){
        },
        setConsignment(consignment){
            this.isSettingConsignment = true;
            this.name = consignment.name;
            this.expried = moment(consignment.expried).format('YYYY-MM-DD');
            this.quantity = 1;
            window.jQuery(this.$refs.modalAddConsignment).modal('show');
        },
        applyConsignment(consignment){
            this.listConsignment.forEach(element => {
                if(element.id == consignment.id){
                    element.deleted = false;
                    element.quantityDraft = this.object.quantity ? this.object.quantity : (element.quantity ? element.quantity : 1);
                    this.arrayConsignmentChoose.push(element);
                }
            });
            this.changeConsignment();
        },
        changeConsignmentApply(id){
            this.listConsignment.forEach(element => {
                if(element.id == id){
                    element.deleted = false;
                }else{
                    element.deleted = true;
                }
            });
        }
    },
    watch: {
        defaultValue: {
            handler: function(newValue) {
                this.arrayConsignmentChoose.forEach((consignment) => {
                    if(newValue && newValue.consignmentId == consignment.id){
                        consignment.quantity = newValue.quantity;
                    }
                });
            },
            deep: true
        },
        listConsignment: {
            handler: function(newData) {
                this.showListConsignment = false;
                newData.forEach((consignment) => {
                    if(consignment.deleted){
                        this.showListConsignment = true;
                    }
                });
            },
            deep: true
        },
        'object': {
            handler: function(newProducts) {
                if(!this.returnSupplier){
                    this.listConsignment = JSON.parse(JSON.stringify(newProducts.consignments));
                    if(this.listConsignment){
                        this.listConsignment.forEach(consignment => {
                            consignment.deleted = true;
                            const found = this.arrayConsignmentChoose.find(el => el.id == consignment.id);
                            if(found){
                                consignment.deleted = false;
                            }
                            if(this.isConfirm && (!found || found === undefined)){
                                consignment.hidden = true;
                            }
                            if(consignment.consignment_product_branch_metas){
                                let inventory = 0;
                                let total_sales = 0;
                                consignment.consignment_product_branch_metas.forEach(element => {
                                    if (this.object.variantId) {
                                        if(this.object && this.object.variantId && element.product_branch_meta && element.product_branch_meta.productUnitId == this.object.variantId){
                                            inventory += element.inventories;
                                            total_sales = element.total_sales;
                                        }
                                    } else {
                                        if(this.object && this.object.productUnitId && element.product_branch_meta && element.product_branch_meta.productUnitId == this.object.productUnitId){
                                            inventory += element.inventories;
                                            total_sales = element.total_sales;
                                        }
                                    }
                                });
                                consignment.inventory = inventory;
                                consignment.total_sales = total_sales;
                            }
                        });
                    }
                    if(this.listConsignmentNewAdd && this.listConsignmentNewAdd.length){
                        this.listConsignmentNewAdd.forEach(consignment => {
                            const isExist = this.listConsignment.find(el => (el.id == consignment.id || el.consignmentId == consignment.id));
                            if(!isExist || isExist === undefined){
                                const found = this.arrayConsignmentChoose.find(el => el.id == consignment.id);
                                if(found){
                                    consignment.deleted = false;
                                }
                                this.listConsignment.push(consignment);
                            }
                        });
                    }
                }
            },
            deep: true
        },
    },
    data() {
        return {
            model: this.modelValue ?? null,
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
                search: '',
            },
            search: null,
            active: false,
            name: null,
            expried: null,
            quantity: 1,
            listConsignment: [],
            listConsignmentNewAdd: [],
            showListConsignment: false,
            defaultListConsignment: [],
            defaultOptionSelected: [],
            params: {},
            branchId: store.state.CurrentBranch.id,
            arrayConsignmentChoose: [],
            isSettingConsignment: false,
        }
    },
    created(){
        
    },
    mounted(){
        // console.log(this.object, this.defaultValue)
        // if (this.object.consignmentActive) {
        //     this.arrayConsignmentChoose = this.object.consignmentActive;
        //     this.arrayConsignmentChoose.forEach(element => {
        //         element.deleted = false;
        //     });
        // }
        this.listConsignmentNewAdd = [];
        if (this.object.consignmentsActive) {
            this.arrayConsignmentChoose = this.defaultOptionSelected = JSON.parse(JSON.stringify(this.object.consignmentsActive));
            this.arrayConsignmentChoose.forEach(element => {
                element.deleted = false;
            });
        }
        this.listConsignment = JSON.parse(JSON.stringify(this.object.consignments));
        let now = moment().format('YYYY-MM-DD');
        if(this.listConsignment){
            this.listConsignment.forEach((consignment, ind) => {
                consignment.deleted = true;
                consignment.inventory = 0;
                if(this.defaultValue && (this.defaultValue.consignmentId == consignment.id || this.defaultValue.id == consignment.id)){
                    if(this.defaultValue.quantity){
                        consignment.quantity = this.defaultValue.quantity;
                    }
                    this.arrayConsignmentChoose.push(consignment);
                    this.defaultOptionSelected.push(consignment);
                    consignment.deleted = false;
                }
                if(consignment.consignment_product_branch_metas){
                    consignment.consignment_product_branch_metas.forEach(element => {
                        if (this.object.variantId) {
                            if(this.object && this.object.variantId && element.product_branch_meta && element.product_branch_meta.productUnitId == this.object.variantId){
                                consignment.inventory += element.inventories;
                            }
                        } else {
                            if(this.object && this.object.productUnitId && element.product_branch_meta && element.product_branch_meta.productUnitId == this.object.productUnitId){
                                consignment.inventory += element.inventories;
                            }
                        }
                    });
                }
                const found = this.arrayConsignmentChoose.find(el => el.id == consignment.id);
                if(found){
                    consignment.deleted = false;
                    if(found.checkMaxQuantity || this.returnSupplier){
                        consignment.maxQuantity = found.quantity;
                    }
                }

                consignment.disabled = false;
                if(now > moment(consignment.expried).format('YYYY-MM-DD') && !this.noChooseOtherConsignment && !this.isTransfer){
                    consignment.disabled = true;
                }

                consignment.hidden = false;
                if(this.isSaleScreen && consignment.inventory <= 0 && !this.isReturn && !this.isConfirm && !this.isUpdate){
                    consignment.hidden = true;
                }
                else if(this.isSaleScreen && consignment.inventory < 0 && (this.isReturn || this.isConfirm || this.isUpdate)){
                    consignment.hidden = true;
                }
                else if(this.isTransfer && consignment.inventory <= 0 && !this.isConfirm){
                    consignment.hidden = true;
                }else if(this.isTransfer && consignment.inventory <= 0 && this.isConfirm){
                    consignment.hidden = false;
                }

                if(this.noChooseOtherConsignment && !found){
                    consignment.disabled = true;
                }

                if(this.isConfirm && (!found || found === undefined)){
                    consignment.hidden = true;
                }
            });
        }
    },
    beforeUnmount() {
        window.jQuery(this.$refs.modalAddConsignment).modal('hide');
        window.jQuery(this.$refs.modalChooseConsignment).modal('hide');
    },
    unmounted() {
        window.jQuery(this.$refs.modalAddConsignment).modal('hide');
        window.jQuery(this.$refs.modalChooseConsignment).modal('hide');
    }
}
</script>

<style lang="scss" scoped>
@import "../assets/css/vars.scss";
.consignment-space{
    flex-wrap: wrap;
}
.consignment_input{
    width: 200px !important;
    height: 30px !important;
}
.consignment_input:focus, .border-bottom-focus:hover{
    border-bottom: 2px solid $base-color !important;
}
.consignment_name{
    background: $base-color;
    padding: 5px !important;
    border-radius: 2px !important;
    width: fit-content;
}
</style>
