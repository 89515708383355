<template>
    <div class="container">
        <div class="row">
            <ActionBar back-url="/purchase-orders/index" @action="save()"/>
            <div class="col-12 pb-100">
                <div class="row">
                    <div class="col-9 p-0 card-transaction-left">
                        <div class="row d-flex align-items-center" style="margin-top: 10px">
                            <div class="col-md-2 d-flex align-items-center">
                                <svg width="17" height="17" viewBox="0 0 17 17" @click="back()" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1666 7.54183H4.50373L9.86081 2.18475L8.49998 0.833496L0.833313 8.50016L8.49998 16.1668L9.85123 14.8156L4.50373 9.4585H16.1666V7.54183Z" fill="#666666"/>
                                </svg>
                                <div class="main-title pl-3">
                                    <span>
                                        {{$t('message.headers.purchase_products')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <ProductSearch @select="onSelectProduct" @getInfoProduct="getInfoProductAndAdd" :readonly="isLink"></ProductSearch>
                            </div>
                            <div class="col-md-5">
                                <OrderSupplierSearch @select="onSelectOrder"></OrderSupplierSearch>
                            </div>
                        </div>
                        <Pagination v-if="form.products.length && form.products.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <table class="table table-index table-striped table-transaction" style="margin-top: 15px">
                            <thead>
                            <tr >
                                <th></th>
                                <th width="2%">
                                    {{$t('message.common.STT')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodCode')}}
                                </th>
                                <th width="23%">
                                    {{$t('message.DetailOrdersCard.prodName')}}
                                </th>
                                <th width="8%">
                                    {{$t('message.DetailOrdersCard.dvt')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.attribute')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodQuantity')}}
                                </th>
                                <th width="4%"></th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodPrice')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodDiscount')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.totalPrice')}}
                                </th>
                                <th width="2%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in form.products" :key="product.id" >
                                <template v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                    <tr>
                                        <td>
                                            <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                        <td>{{ index + 1 }}</td>
                                        <td v-text="product.code"></td>
                                        <td>
                                            {{ product.name }}
                                            <div class="position-relative">
                                                <input type="text" v-model.trim="product.note" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                                <span class="noteOrder text-gray" v-if="product.note == null || product.note == ''"><i class="fa fa-pencil-alt"></i></span>
                                            </div>
                                        </td>
                                        <td>
                                            <template v-if="isLink">
                                                <span v-text="product.productUnit ? product.productUnit.unitName : null"></span>
                                            </template>
                                            <template v-else>
                                                <InputSelect v-model="product.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.product_units"
                                                v-if="product.product_units && product.product_units.length"
                                                option-label="unitName"
                                                :readonly="product.isUpdated"
                                                @update:modelValue="updateProductUnit(product)"
                                                :cardTransaction="true">
                                                </InputSelect>
                                            </template>
                                        </td>                                        
                                         <!-- <td>
                                            <InputSelect v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.attributesActive"                                                
                                                option-label="name"
                                                :readonly="product.isUpdated"                                                
                                                :cardTransaction="true">
                                            </InputSelect>
                                        </td> -->
                                        <td class="pt-3 block__quantity">
                                            <template v-if="isLink">
                                                <span v-text="product.variant ? product.variant.name : null"></span>
                                            </template>
                                            <template v-else>
                                                <InputSelect v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="product.attributesActive"
                                                v-if="product.attributesActive && product.attributesActive.length"
                                                option-label="name"
                                                :readonly="product.isUpdated"
                                                
                                                :cardTransaction="true">
                                                </InputSelect>
                                            </template>                                            
                                        </td>
                                        <td class="pt-3 block__quantity">
                                            <input type="number" class="format__input" :min="1" @keyup="checkQuantity(product.quantity, product.id, $event)" v-model="product.quantity" :readonly="product.isConsignment || (product.consignmentActive && product.consignmentActive?.length > 0) ? true : false" :class="product.isConsignment || (product.consignmentActive && product.consignmentActive?.length > 0) ? 'cursor-ban' : null">
                                        </td>
                                        <td class="pt-3 block__quantity">
                                            <div v-if="isLink && product.maxQuantity" class="text-left">{{ '/' + n(product.maxQuantity) }}</div>
                                        </td>
                                        <td class="pt-0">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="product.price" :inputTable="true" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                        </td>
                                        <td class="pt-3">
                                            <ProductDiscount :modelValue="product" @update:modelValue="updateDiscount" :price="product.price"/>
                                        </td>
                                        <td>
                                            <!-- {{ product.price + '-' + product.discount + '-' + '-' + product.quantity  }} -->
                                            {{ $filters.formatPrice((product.price - (product.discount?? 0))*product.quantity) }}
                                        </td>
                                        <td>
                                            <template v-if="!isLink">
                                                <i class="fa fa-plus" @click="addProductPlus(index, product)"></i>
                                            </template>
                                        </td>
                                    </tr>
                                    <tr v-if="product.isConsignment">
                                        <td></td>
                                        <td :colspan="11">
                                            <ChooseConsignment :object="product" @update:value="updateConsignment"/>
                                        </td>
                                    </tr>
                                      <!-- Thêm đơn vị tùy chọn -->
                                    <template v-for=" (plus, plusIndex) in  product.plus" :key="plusIndex">
                                        <tr>
                                            <td colspan="4"></td>
                                            <td>
                                                <template v-if="isLink">
                                                    <span v-text="plus.productUnit ? plus.productUnit.unitName : null"></span>
                                                </template>
                                                <template v-else>
                                                    <InputSelect v-model="plus.productUnitId"
                                                        :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                        :options="plus.product_units"
                                                        option-label="unitName"
                                                        :cardTransaction="true"
                                                        @update:modelValue="updateProductUnit(plus)">
                                                    </InputSelect>
                                                </template>
                                            </td>
                                            <td>
                                                <template v-if="isLink">
                                                    <span v-text="plus.variant ? plus.variant.name : null"></span>
                                                </template>
                                                <template v-else>
                                                    <InputSelect v-model="plus.variantId"
                                                        :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                        :options="plus.attributesActive"
                                                        option-label="name"
                                                        :cardTransaction="true"
                                                        v-if="plus.attributesActive && plus.attributesActive.length"
                                                        >
                                                    </InputSelect>
                                                </template>                                                
                                            </td>
                                            <td class="pt-3 block__quantity">
                                                <input type="number" class="format__input" :min="1"  v-model="plus.quantity">
                                            </td>
                                            <td class="pt-3 block__quantity">
                                                <div v-if="isLink && plus.maxQuantity" class="text-left">{{ '/' + n(plus.maxQuantity) }}</div>
                                            </td>
                                            <td class="">
                                                <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="plus.price" :inputTable="true" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                            </td>

                                            <td>
                                                <div class="position-relative">
                                                    <ProductDiscount :modelValue="plus" :indexDiscount="plusIndex" @update:modelValue="updateDiscountPlus" :price="plus.price"/>
                                                </div>
                                            </td>
                                            <td>
                                                {{ $filters.formatPrice((plus.price - (plus.discount?? 0))*plus.quantity) }}
                                            </td>
                                            <td>
                                                <a v-if="!isLink || create_from_order" class="btn-remove" @click.prevent="removeDetailProductPlus(index, plusIndex)">
                                                    <i class="fas fa-trash"/>
                                                </a>
                                            </td>
                                        </tr>
                                        <!-- consignment từng đơn vị -->
                                        <tr v-if="product.isConsignment">
                                            <td></td>
                                            <td :colspan="11">
                                                <ChooseConsignment :object="plus" @update:value="updateConsignmentProductUnit"  :index="index" :plusIndex="plusIndex"/>
                                            </td>
                                        </tr>

                                    </template>
                                </template>
                            </template>
                            </tbody>
                        </table>
                        <div class="row d-flex align-items-center importArea" v-if="form.products?.length == 0">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="main-title">
                                    <span>{{$t('message.ImportExcel.title')}}</span>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div>
                                    (
                                        {{$t('message.ImportExcel.defaultFile')}} 
                                        <a :href="'../template/template_nhap_hang_' + this.language + '.xlsx'" target="_blank" class="text-primary">
                                            {{$t('message.ImportExcel.purchaseOrderFile')}}
                                        </a>
                                    )
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3">
                                <button class="p-2 button-import" :title="$t('message.ImportExcel.chooseFile')">
                                    <form ref="form">
                                        <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" :title="$t('message.ImportExcel.chooseFile')"/>
                                    </form>
                                    
                                    <i class="fa fa-file mr-2"></i>
                                    {{ $t('message.ImportExcel.chooseFile') }}
                                </button>
                            </div>
                            <div class="col-12 w-100 mt-3 upload-files" v-if="this.fileName != ''">
                                <div class="row h-100">
                                    <div class="col-6 d-flex align-items-center">
                                        {{ this.fileName }}
                                    </div>
                                    <div class="col-6 d-flex justify-content-end align-items-center">
                                        <a href="javascript:;" class="text-danger" @click="removeFile(false)">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 w-100 mt-3">
                                <div class="alert alert-danger row py-2" v-if="this.messageFile">
                                       {{ this.messageFile }}
                                </div>
                            </div>

                            <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="this.fileName != ''">
                                <button v-show="this.showBtn" class="p-2 button-import" :title="$t('message.ImportExcel.action')" @click="uploadExcel(this.patient)">
                                    <i class="fa fa-file mr-2"></i>
                                    {{ $t('message.ImportExcel.action') }}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="col-3 pt-2 card-transaction-right">
                        <div class="row card-transaction-right-content" :class="this.style_overflow ? '' : 'overflow-visible'">
                            <div class="col-12">
                                <div class="row pb-2">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-2 p-0 d-flex justify-content-end align-items-center">
                                                <h5 class="mb-0"><i class="fa fa-user-circle"></i></h5>
                                            </div>
                                            <div class="col-10 pl-2 py-2">
                                                <SelectSearch :placeholder="$t('message.OrderSupplierIndex.order_by')" :modelValue="form.userId" :defaultOption="this.employees.all" @update:modelValue="selectEmployee" :label="'orderBy'"></SelectSearch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-7 pl-0 pr-3">
                                                <InputDatePicker :cardTransaction="true" v-model="form.importDate" :placeholder="$t('message.OrderSupplierIndex.created_by')" class="mb-0"/>
                                            </div>
                                            <div class="col-5 pl-0 pr-3">
                                                <InputTimePicker :cardTransaction="true" :default="form.startTime" @input="setTimeStart"></InputTimePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <SupplierSearch class="form-control" @select="onSelectSupplier" @clearSupplier="clearSupplier" :readSupplier="isLink" :supplierName="form.supplierName" :placeholder="$t('message.common.searchSupplier')"></SupplierSearch>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        {{$t('message.PurchaseOrderIndex.code')}}
                                    </div>
                                    <div class="col-6">
                                        <input type="text" v-model="form.code" class="form-control input__order min-w-100 border-bottom-1" :placeholder="$t('message.common.autocompleteCode')">
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.code')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ this.form.orderCode }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        {{$t('message.common.status')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="form-control border-none">
                                            {{$t('message.common.draft')}}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.total_origin')}}
                                        <button class="border ml-2 text-dark" disabled v-text="this.form.TotalQuantity"></button>
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(form.totalPrice) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.discount')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="border-bottom form-discount text-right" @click.stop="showDiscountForm">{{ n(this.form.discountValue ?? 0) }}</div>
                                        <!-- <ProductDiscount @changeStylePopup="changeStyle" :modelValue="form" @update:modelValue="updateDiscount" :discountPercent="form.valuePercent" :discountMoney="form.discountValue" :price="form.totalPrice"/> -->
                                    </div>
                                </div>
                                <div class="row my-2" v-if="this.form.paymentsInfo && this.form.paymentsInfo.length">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.paymentForSupplier')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary bg-light" @click="showModalPaymentSupplier" v-text="n(form.totalPaymentSupplier)"></div>
                                    </div>
                                </div>
                                <div class="row pb-2 text-bold">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.needToPaySupplier')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary" v-text="(form.totalPrice - (form.discountValue ?? 0) - (form.totalPaymentSupplier ?? 0)) > 0 ? n(form.totalPrice - (form.discountValue ?? 0) - (form.totalPaymentSupplier ?? 0)) : 0"></div>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.paidSupplier')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="form-control border-none text-right">
                                            {{ $filters.formatPrice(form.paymentForSupplier) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-4 pr-0">
                                        <label class="container-checkbox">{{$t('message.common.cash')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCashMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox">{{$t('message.common.card')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCardMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox">{{$t('message.common.transferPayment')}}
                                            <input type="checkbox" :value="$constant.paymentChannelTransferMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-4 pr-0">
                                        <div class="input__order">
                                            <InputNumberFormat :pxNone="true" :max="$constant.maxNumber" :cardTransaction="true" v-model="form.cashMoney" @keyup="onChangeCurrency($event)" @keydown="onKeyDownCurrency($event)" :readonly="!paymentChannel.includes($constant.paymentChannelCashMoney)" class="mb-0"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :pxNone="true" :max="$constant.maxNumber" :cardTransaction="true" v-model="form.cardMoney" @keyup="onChangeCurrency($event)" @keydown="onKeyDownCurrency($event)" :readonly="!paymentChannel.includes($constant.paymentChannelCardMoney)" class="mb-0"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :pxNone="true" :max="$constant.maxNumber" :cardTransaction="true" v-model="form.transferMoney" @keyup="onChangeCurrency($event)" @keydown="onKeyDownCurrency($event)" :readonly="!paymentChannel.includes($constant.paymentChannelTransferMoney)" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="form.totalPaymentSupplier > (+form.totalPrice - form.discountValue)">
                                    <div class="col-6">
                                        {{$t('message.ReturnPurchaseOrder.advanceRefund')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="form.depositReturn" @keyup="onChangeCurrency($event)" @keydown="onKeyDownCurrency($event)" :paddingRightNone="true" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-8">
                                        <label class="container-radio pr-0 min-w-100">
                                            {{$t('message.OrderSupplierIndex.supplierReturn')}}
                                            <input type="radio" id="return" :value="$constant.return" v-model="form.typeReturns">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 text-right form-group mb-0">
                                        <div class="form-control border-none" v-text="form.supplierReturn > 0 ? n(form.supplierReturn) : 0"></div>
                                    </div>
                                </div>
                                <div class="row pb-2" v-if="form.supplierId">
                                    <div class="col-8">
                                        <label class="container-radio pr-0 min-w-100">
                                            {{$t('message.ReturnPurchaseOrder.includedInDebt')}}
                                            <input type="radio" id="debt" :value="$constant.debt" v-model="form.typeReturns">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(+form.paymentForSupplier - (+form.totalPrice - +form.discountValue - (+form.totalPaymentSupplier ?? 0))) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <InputTextArea :placeholder="$t('message.OrderSupplierIndex.note')" v-model.trim="form.note" class="input__textArea mb-0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row pb-2 card-transaction-button w-100 bg-white">
                            <div class="col-5" v-if="!this.isLink">
                                <button class="mt-2 mb-2 btn_submit btn btn-save" :disabled="isButtonDisabled" @click="submit($constant.statusOptionsTicketTemporary)">
                                    <i class="fa fa-save"></i>
                                    <div>
                                        {{$t('message.button.saveDraft')}}
                                    </div>
                                </button>
                            </div>
                            <div class="col-7" :class="this.isLink ? 'col-12' : ''">
                                <button class="btn btn-success mt-2 mb-2 btn_submit" :disabled="isButtonDisabled" @click="submit($constant.statusOptionsTicketConfirm)">
                                    <i class="fa fa-check"></i>
                                    <div>
                                        {{$t('message.OrderSupplierIndex.status_complete')}}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <ModalErrorsMessage ref="ModalErrorsMessageImport" />
                <ModalPaymentSupplier ref="ModalPaymentSupplier" />
            </div>
        </div>
    </div>
    <OrderDiscount ref="orderDiscount" :modelValue="form" @update:modelValue="updateDiscount" :discountPercent="form.valuePercent" :discountMoney="form.discountValue" :price="form.totalPrice"/>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, $upload, rand, removeVietnameseTones} from "@/lib/utils";
    import toastr from 'toastr';
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import QSelect from "../../components/QSelect";
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumber from "../../components/forms/InputNumber";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    import ErrorLabel from "../../components/ErrorLabel";
    import moment from "moment";
    import store from "../../store";
    import ProductSearch from "@/components/ProductSearch.vue";
    import SupplierSearch from "@/components/SupplierSearch.vue";
    import router from "@/router/index";
    import currencyMixin from "@/currencyMixin";
    import ModalErrorsMessage from './ModalErrorsMessage.vue'
    import emitter from "@/lib/emitter";
    import InputTimePicker from "../../components/forms/InputTimePicker.vue";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import OrderDiscount from "@/components/OrderDiscount.vue";
    import Pagination from "@/components/Pagination";
    import OrderSupplierSearch from "@/components/OrderSupplierSearch";
    import SelectSearch from "@/components/SelectSearch.vue";
    import ChooseConsignment from "@/components/ChooseConsignment.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalPaymentSupplier from './tabs/ModalPaymentSupplier.vue';
    import attributesMixin from '@/attributesMixin';
    import { mapGetters, mapActions } from 'vuex';
    import storeModul from "@/storeModul";
    export default {
        name: "OrderSupplierForm",
        components: {ActionBar, ModalErrorsMessage, InputText, InputSwitchButton, QSelect, InputTextArea, InputSelect, InputNumberFormat, 
            InputNumber, InputDatePicker, ErrorLabel, ProductSearch, SupplierSearch, InputTimePicker, ProductDiscount, Pagination, 
            OrderSupplierSearch, SelectSearch, ChooseConsignment, AppPermission,ModalPaymentSupplier, OrderDiscount
            },
        mixins: [currencyMixin,attributesMixin],
        data() {
            let currentDate = new Date().toJSON().slice(0, 10);
            return {
                form: {
                    products: [],
                    userId: store.state.Auth.user.id,
                    branchId: store.state.CurrentBranch.id,
                    importDate: currentDate,
                    startTime: moment().format('HH:mm'),
                    supplierId: null,
                    code: null,
                    status: this.$constant.statusOptionsTicketTemporary,
                    totalPrice: 0,
                    TotalQuantity: 0,
                    discountType: null,
                    discountValue: 0,
                    valuePercent: 0,
                    paymentForSupplier: 0,
                    cashMoney: 0,
                    cardMoney: 0,
                    transferMoney: 0,
                    otherCosts: 0,
                    note: null,
                    supplierName: null,
                    orderCode: null,
                    supplierReturn: 0,
                    typeReturns: this.$constant.return,
                    orderForSupplierId: null,
                },
                paymentChannel: [this.$constant.paymentChannelCashMoney],
                isEdit: false,
                isCopy: false,
                fileName: "",
                patient: {
                    file: [],
                    branchId: ''
                },
                employees: {
                    all: null,
                    search: store.state.Auth.user.name,
                    filters: null,
                },
                isLink: false,
                paginate: {
                    totalRecord: 0,
                    currentPage: 1,
                    lastPage: 0,
                },
                numberRecord: 15,
                showBtn: false,
                messageFile: '',
                create_from_order: false,
                style_overflow: true,
                isButtonDisabled: false,
                language: localStorage.getItem('language') ?? 'vi',
            }
        },
        mounted() {
            document.title = this.$t('message.headers.purchase_products');
        },
        computed: {
            ...mapGetters({
                productIds: 'productStore/productIds',
            }),
        },
        watch: {
            form: {
                handler(dataNew){
                    let TotalPrice = 0;
                    let TotalQuantity = 0;
                    dataNew.products.forEach((product, index) => {
                        if (product.discountType == this.$constant.discountTypePercent) {
                            product.discount = product.price * product.valuePercent/100;  
                        }
                        if (product.isConsignment) {
                            let quantity = 0;
                            if (product.consignmentActive && product.consignmentActive?.length > 0) {
                                product.consignmentActive.forEach(item => {
                                    if (item.quantity) {
                                        quantity = +item.quantity + +quantity
                                    }
                                });
                            }
                            product.quantity = quantity;
                            product.consignmentsActive = product.consignmentActive;
                        }

                        if (product.plus && product.plus?.length > 0) {
                            product.plus.forEach((plusItem, plusIndex) => {
                                if (product.isConsignment) {
                                    let quantity = 0;
                                    if (plusItem.consignmentActive && plusItem.consignmentActive?.length > 0) {
                                        plusItem.consignmentActive.forEach(item => {
                                            if (item.quantity) {
                                                quantity = +item.quantity + +quantity
                                            }
                                        });
                                    }
                                    plusItem.quantity = quantity;
                                    plusItem.consignmentsActive = plusItem.consignmentActive;
                                 }
                                 TotalPrice = TotalPrice + (plusItem.price - (plusItem.discount ?? 0))* plusItem.quantity;
                                 TotalQuantity += plusItem.quantity;
                            });
                        }
                        TotalPrice = TotalPrice + (product.price - (product.discount ?? 0))* product.quantity;
                        TotalQuantity = TotalQuantity + product.quantity;
                        if (!product.productUnitId) {
                            product.productUnitId = product.product_units[0] ? product.product_units[0].id : null;
                        }

                    });
                    this.form.totalPrice = TotalPrice;
                    this.form.TotalQuantity = TotalQuantity;
                    if (this.form.discountType == this.$constant.discountTypePercent) {
                        this.form.discountValue = TotalPrice * this.form.valuePercent/100;
                    }
                    this.calculatePayment();
                    this.paginate.totalRecord = dataNew.products.length;
                    this.paginate.lastPage = Math.ceil(dataNew.products.length / this.numberRecord);
                },
                deep: true
            },
            paymentChannel: {
                handler(){
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCashMoney)) {
                        this.form.cashMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCardMoney)) {
                        this.form.cardMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelTransferMoney)) {
                        this.form.transferMoney = 0;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getEmployees();   
            if (this.$route.query && this.$route.query.id && this.$route.query.type && this.$route.query.type == 'copy') {
                this.isCopy =  true;
                this.getCopyImportSupplier(this.$route.query.id, this.$route.query.type);
            } else if (this.$route.query && this.$route.query.orderId) {
                // tạo phiếu nhập từ phiếu đặt
                this.isLink = true;
                this.create_from_order = true;
                this.getOrderSupplierById(this.$route.query.orderId);
            }
             else if (this.$route.query && this.$route.query.id) {
                // update phiếu tạo
                this.form.id = this.$route.query.id;
                this.isEdit = true;                    
                this.getImportSupplier(this.form.id, null);
            }
            else if (this.productIds && this.$route.query.type == 'short-create-import') {                                
                this.getProductsImport(this.productIds);
                storeModul.dispatch('productStore/setProductIds', []);
            }
        },
        methods: {
            showDiscountForm($event){
                let top = $event.clientY ?? 0;
                this.$refs.orderDiscount.showDiscountForm(top);
            },
            changeStyle(status){
                this.style_overflow = status ? false : true;
            },
            getInfoProductAndAdd(product) {
                if (product.data && product.data.entry && product.data.productUnits);
                    const data = product.data.entry;
                    const units = product.data.productUnits;
                const productNew = {
                    id: data.id,
                    code: data.code,
                    name: data.name,
                    quantity: 1,
                    discount: null,
                    discountType: null,
                    valuePercent: null,
                    product_units: [
                        {
                            id: units[0].id,
                            unitName: units[0].unitName,
                            price: 0,
                        }
                    ]
                }
                this.form.products.unshift(productNew)
            },
            pageChange(page) {
               this.paginate.currentPage = page;
            },
            async getProductsImport (ids)
            {
                let params = {
                    branchId: store.state.CurrentBranch.id,
                    ids: ids
                }
                let data = await $post('/v1/order-suppliers/get-products-id', params);
                this.form.products = data.result;
                this.form.products.forEach(element => {
                    element.quantity = 1;
                });
            },
            async getOrderSupplierById(id) {
                const res = await $get('/v1/order-suppliers/detail', {
                    id: id,
                    action: this.$constant.actionLinkImportByOrder,
                });
                if (res.status && res.status.code == 200 && res.result) {
                    const data = res.result;
                    this.form.orderCode = data.code;
                    this.form.branchId = res.result.branchId;
                    this.form.supplierId = res.result.supplierId;                    
                    this.form.discountType = res.result.typeDiscount;
                    this.form.supplierName = res.result.supplier?.name;
                    this.form.products = res.result.products;
                    this.form.orderForSupplierId = res.result.id;
                    this.paymentChannel = [this.$constant.paymentChannelCashMoney];
                    this.form.cashMoney = 0;
                    this.form.cardMoney = 0;
                    this.form.transferMoney = 0;
                    this.form.discountValue = res.result.priceDiscount;
                    this.form.valuePercent = res.result.valuePercent;
                    this.form.paymentsInfo = res.result.paymentsInfo ?? [];
                    // this.form.paymentForSupplier = res.result.paymentForSupplier;
                    // this.form.transferMoney = res.result.transfer;
                    // this.form.cardMoney = res.result.card;
                    // this.form.cashMoney = res.result.cash;
                    // if (this.form.cashMoney != 0) {
                    //     this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    // } 
                    // if (this.form.cardMoney != 0) {
                    //     this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                    // } 
                    // if (this.form.transferMoney != 0) {
                    //     this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                    // }
                    this.form.products.forEach(product => {
                        if (product.plus) {
                            product.plus.forEach(element => {
                                element.maxQuantity = element.quantity;
                            });
                        }
                        product.maxQuantity = product.quantity;
                    });
                    let totalPaymentSupplier = 0;
                    if(this.form.paymentsInfo){
                        this.form.paymentsInfo.forEach(el => {
                            totalPaymentSupplier += (+el.amount) ?? 0;
                        });
                    }
                    this.form.totalPaymentSupplier = totalPaymentSupplier;
                }
            },
            selectEmployee(v) {
                this.form.userId = v.model;
            },
            clearEmployee() {
                this.form.userId = null;
                this.employees.search = null;
            },
            onInputFilterEmployees(){
                if(this.employees.search == ''){
                    this.employees.filters = this.employees.all;
                    this.form.userId = null;
                    return
                }
                let arr = [];
                for (let index = 0; index < this.employees.all.length; index++) {
                    if (removeVietnameseTones(this.employees.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.employees.search.toLowerCase())) > -1){
                        arr.push(this.employees.all[index]);
                    }
                }
                this.employees.filters = arr;

            },
            calculatePayment() {
                this.form.paymentForSupplier = +this.form.cashMoney + +this.form.cardMoney + +this.form.transferMoney;
                this.form.supplierReturn = (+this.form.totalPaymentSupplier) + +this.form.paymentForSupplier - (+this.form.totalPrice - +this.form.discountValue);
            },
              async getCopyImportSupplier(id, type) {
                 
                 
                const res = await $get('/v1/order-suppliers/import-suppliers/detail', {
                    id: id, type: type
                }); 
                if (res.status && res.status.code == 200) {
                    const infoImport = res.result.import;                    
                    this.form.id = infoImport.id;
                    this.form.userId = infoImport.userId?? store.state.Auth.user.id;
                    this.form.branchId = infoImport.branchId?? store.state.CurrentBranch.id;
                    // this.form.importDate = infoImport.importDate?? moment();
                    this.form.importDate = moment();
                    this.form.startTime = moment();
                    this.form.supplierId = infoImport.supplierId;                    
                    this.form.code = this.isCopy === true ? 'PN' + rand(100000,999999) : infoImport.code;
                    this.form.status = infoImport.status;
                    // discount
                    //------------------------------------------------------------------
                    this.form.discountType = null;
                    this.form.discountValue = 0;
                    this.form.valuePercent = null;
                    //------------------------------------------------------------------
                    this.form.paymentForSupplier = infoImport.paymentForSupplier;          
                    this.form.cashMoney = 0;                    
                    this.form.cardMoney = 0;
                    this.form.transferMoney =  infoImport.transfer;
                    this.form.note =null;
                    this.form.supplierName = null
                    this.form.products = res.result.import.products;
                    // this.form.typeReturns = res.result.debt === null ? this.$constant.return : this.$constant.debt;
                    this.form.typeReturns = 0;
                    this.paymentChannel = [];
                    if (this.form.cashMoney == 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    }                  
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
                
            },
            async getImportSupplier(id, type) {
                
                    const res = await $get('/v1/order-suppliers/import-suppliers/detail', {
                        id: id, type: type
                    }); 
                if (res.status && res.status.code == 200) {
                    const infoImport = res.result.import;
                    const orderDetails = res.result.orderDetails;
                    this.form.id = infoImport.id;
                    this.form.userId = infoImport.userId;
                    this.form.branchId = infoImport.branchId;
                    this.form.importDate = infoImport.importDate;
                    this.form.startTime = moment(this.form.importDate).format('HH:mm');
                    this.form.supplierId = infoImport.supplierId;                    
                    this.form.code = this.isCopy === true ? 'PN' + rand(100000,999999) : infoImport.code;
                    this.form.status = infoImport.status;
                    this.form.discountType = infoImport.typeDiscount;
                    this.form.discountValue = infoImport.priceDiscount;
                    this.form.valuePercent = infoImport.valuePercent;
                    this.form.paymentForSupplier = infoImport.paymentForSupplier;
                    this.form.paymentsInfo = infoImport.paymentsInfo ?? [];
                    this.form.cashMoney = infoImport.cash;
                    this.form.cardMoney = infoImport.card;
                    this.form.transferMoney = infoImport.transfer;
                    this.form.note = infoImport.note;
                    this.form.supplierName = infoImport.supplier?.name;
                    this.form.products = infoImport.products;
                    this.form.typeReturns = infoImport.debt === null ? this.$constant.return : this.$constant.debt;
                    this.form.orderCode = infoImport.order_for_supplier?.code;
                    this.form.orderForSupplierId = infoImport.orderForSupplierId;
                    this.paymentChannel = [];
                    if (this.form.cashMoney != 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    } 
                    if (this.form.cardMoney != 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                    } 
                    if (this.form.transferMoney != 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                    }
                    let totalPaymentSupplier = 0;
                    if(this.form.paymentsInfo){
                        this.form.paymentsInfo.forEach(el => {
                            totalPaymentSupplier += (+el.amount) ?? 0;
                        });
                    }
                    this.form.totalPaymentSupplier = totalPaymentSupplier;
                    if (this.form.orderCode) {
                        this.isLink = true;
                        this.form.products.forEach(product => {
                            // let item = orderDetails.find(item => item.productId === product.id);
                            // if (item) {
                            //     product.maxQuantity = item.quantity;
                            // } else {
                            //     product.maxQuantity = 0;
                            // }
                            if(product.maxQuantityAfterImport) {
                                product.maxQuantity = product.maxQuantityAfterImport
                            }
                            if(product.plus){
                                product.plus.forEach(plusItem => {
                                    if(plusItem.maxQuantityAfterImport) {
                                        plusItem.maxQuantity = plusItem.maxQuantityAfterImport
                                    }
                                });
                            }
                        });
                    }
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            clearSupplier() {
                this.form.supplierId = null;
                this.form.supplierName =  null;
            },
            back() {
                this.$router.replace({
                    path: '/purchase-orders/index',
                    query: {
                        action: 'import'
                    }
                });
            },
            async getEmployees() {
                const res = await $post('/v1/order-suppliers/get-employees');
                if (res.status.code == 200) {
                    this.employees.all = res.result;
                    this.employees.filters = res.result;
                }
            },
            onSelectProduct(product) {                
                if (!this.form.products.length || !this.form.products.find(item => item.id === product.id)) {
                    if (product.product_units) {
                        product.productUnit = product.product_units[0];
                        product.price = product.product_units[0]['price'];                         
                    }
                    this.form.products.unshift(product);
                    
                }
            },
            onSelectOrder(order) {
                if (this.form.orderCode) {
                    if (!confirm(this.$t('message.common.confirmChangePo'))) {
                        return;
                    }
                }
                if (order.import_count != 0) {
                    $alert({code: 500 , message: this.$t('message.common.havePODraft')});
                    return;
                }
                if (order.id) {
                    this.isLink = true,
                    this.create_from_order = true;
                    this.getOrderSupplierById(order.id);
                }
            },
            removeDetailProduct(product) {
                if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                    return;
                }
                let itemIndex = -1;
                this.form.products.forEach((item, index) => {
                    if (item.id === product.id) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.form.products.splice(itemIndex, 1);
                }  
            },
            onSelectSupplier(supplier) {
                this.form.supplierId = supplier.id;
            },
            setTimeStart (time) {
                this.form.startTime = time;
            },
            updateDiscountForm(value, price) {
                this.form.discountType = value.discountType;
                if (value.discountType == this.$constant.discountTypePercent) {
                    this.form.discountValue = price*value.valuePercent/100;
                    this.form.valuePercent = value.valuePercent;
                } else {
                    this.form.discountValue = value.discount;
                }
            },
            async submit(status) {
                if (this.form.products.length === 0) {
                    toastr.error(this.$t('message.common.isEmptyPO'));
                    return;
                }
                for (let index = 0; index < this.form.products.length; index++) {
                    const product = this.form.products[index];
                    if (!product.quantity || product.quantity == 0) {
                        toastr.error(this.$t('message.common.invalidQuantity', {name: product.name}));
                        return;
                    }
                    if (this.isLink && product.maxQuantity && product.maxQuantity < product.quantity) {
                        toastr.error(this.$t('message.common.invalidPurchaseQuantity', {name: product.name}));
                        return;
                    }
                    if(status == this.$constant.statusOptionsTicketConfirm && product.isConsignment && (!product.consignmentsActive || product.consignmentsActive?.length <= 0)){
                        toastr.error(this.$t('message.common.emptyBatch', {name: product.name}));
                        return;
                    }
                    if (product.plus) {
                        for (let indexPls = 0; indexPls < product.plus.length; indexPls++) {
                            const productPls = product.plus[indexPls];
                            if (!productPls.quantity || productPls.quantity == 0) {
                                toastr.error(this.$t('message.common.invalidQuantity', {name: product.name}));
                                return;
                            }
                            if (this.isLink && productPls.maxQuantity && productPls.maxQuantity < productPls.quantity) {
                                toastr.error(this.$t('message.common.invalidPurchaseQuantity', {name: product.name}));
                                return;
                            }
                            if(status == this.$constant.statusOptionsTicketConfirm && product.isConsignment && (!productPls.consignmentsActive || productPls.consignmentsActive?.length <= 0)){
                                toastr.error(this.$t('message.common.emptyBatch', {name: product.name}));
                                return;
                            }
                        }
                    }
                }

                this.form.importDateFormat = moment(this.form.importDate).format("YYYY-MM-DD") + ' ' + this.form.startTime;
                const params = {
                    products: this.form.products,
                    userId: this.form.userId,
                    branchId: this.form.branchId,
                    startTime: this.form.startTime,
                    supplierId: this.form.supplierId,
                    code: this.form.code?.trim(),
                    status: status,
                    totalPrice: this.form.totalPrice,
                    discountType: this.form.discountType,
                    discountValue: this.form.discountValue,
                    valuePercent: this.form.valuePercent,
                    paymentForSupplier: this.form.paymentForSupplier + (this.form.totalPaymentSupplier ?? 0),
                    totalPaymentSupplier: (this.form.totalPaymentSupplier ?? 0),
                    cashMoney: this.form.cashMoney,
                    cardMoney: this.form.cardMoney,
                    transferMoney: this.form.transferMoney,
                    note: this.form.note,
                    importDate: this.form.importDateFormat,
                    orderCode: this.form.orderCode,
                    typeReturns: this.form.typeReturns,
                    orderForSupplierId: this.form.orderForSupplierId,
                    depositReturn: this.form.depositReturn ?? 0,
                };
                this.isButtonDisabled = true;
                let res;
                if (!this.isEdit) {                    
                    res = await $post('/v1/order-suppliers/create-import-coupon', {...params});
                } 
                else {                      
                    params.id = this.form.id;
                    res = await $post('/v1/order-suppliers/update-import-coupon', {...params});
                }
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } 
                else if (res.result.status && res.status.code == 200) {
                    $alert({code: 200, message: !this.isEdit ? this.$t('message.common.createPurchaseOrderSuccess') : this.$t('message.common.updatePurchaseOrderSuccess')});
                    this.$router.push({name: 'PurchaseOrderIndex', query: {action: 'import'}});
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            updateDiscountProduct(value, productId) {
                this.form.products.forEach((item) => {
                    if (item.id === productId) {
                        item.discountType = value.discountType;
                        if (value.discountType == this.$constant.discountTypePercent) {
                            item.discount = Math.round(item.product_units[0].price * item.quantity * value.valuePercent /100);
                            item.valuePercent = value.valuePercent;
                        } else {
                            item.discount = value.discount;
                        }
                    }
                });
            },
              onFileChange(event) {
                var files = event.target.files;
                if (!files.length) {  
                    return;
                }
                // await this.removeFile(false);
              
                // if (this.patient.file.length > 0) {
                //   await this.removeFile(false);
                // } 
                this.fileName = files[0].name;
                this.patient.file = files[0];
               
                let id = document.getElementById('branchDropdown');
                // this.patient.branchId = id.getAttribute('data-id');
                this.patient.branchId = store.state.CurrentBranch.id;
                if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
                    this.showBtn = true;
                    this.messageFile = '';
                } else {
                    this.showBtn = false;
                    this.messageFile = this.$t('message.common.inValidFormatFile');
                }
                this.$refs.form.reset();
            },
            removeFile(status){
                this.patient.file = [];
                this.fileName = '';
                if (status == false) {
                    this.$refs.fileupload.value = '';
                    this.messageFile = '';
                }
            },
            async uploadExcel (file) {
                const result = await $upload('/v1/order-suppliers/import-goods', [], file);
                
                if (result.result.code == 422) {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                } else {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    this.form.products = result.result.data;
                }
            },
            updateDiscount(value){
                if(value.dEntry.products){
                    this.form.discountValue = value.model.discountValue;
                    this.form.valuePercent = value.model.valuePercent;
                    this.form.discountType = value.model.discountType;
                }else{
                    this.form.products.forEach(product => {
                        if(product.id === value.dEntry?.id){
                            product.discount = value.model.discountValue;
                            product.valuePercent = value.model.valuePercent;
                            product.discountType = value.model.discountType;
                        }
                    })
                }
            },
            updateDiscountPlus(value) {
                this.form.products.forEach(product => {
                    if (product.id === value.dEntry?.id) {
                        product.plus[value.index].discount = value.model.discountValue;
                        product.plus[value.index].valuePercent = value.model.valuePercent;
                        product.plus[value.index].discountType = value.model.discountType;
                    }
                });  
            },
            checkQuantity(quantity, id, e) {
                if(e.key === 'Backspace') return;
                let product = this.form.products.find(item => item.id === id);
                if (typeof quantity != 'number') {
                    product.quantity = 1;
                }
                if (quantity < 0) {
                    product.quantity = 1;
                }
            },
            updateConsignment(data){
                this.form.products.forEach(product => {
                    if (product.id == data.object.id) {
                        product.consignmentActive = data.data;
                    }
                    // if(product.consignmentActive?.length == 0){
                    //     product.quantity = 0;
                    // }
                });
            },
            updateConsignmentProductUnit (data) {
                this.form.products[data.index].plus[data.plusIndex].consignmentActive = data.data;
            },
            updateProductUnit(product) {
                if (product.product_units && product.product_units.length && product.productUnitId) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = null;
                    product.discountValue = 0;
                    product.price = 0;                    
                }
                else {
                     product.attributesActive = product.attributes;
                }
                this.getListAttributeForUnit(product);
            },           
            addProductPlus (productIndex, product) {
                let plus = {
                    // productUnitId: product.product_units[0]['id'],
                    productUnitId: null,
                    productUnit: product.product_units[0],
                    quantity: 1,
                    price: 0,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    isConsignment: product.isConsignment,
                    // consignmentActive: product.consignmentActive,
                    consignments: product.consignments,                    
                    attributes: product.attributes,
                    attributesActive: product.attributesActive,
                }
                if (!this.form.products[productIndex].plus) {
                    this.form.products[productIndex].plus = [];
                }
                plus.index = this.form.products[productIndex].plus.length;
                this.form.products[productIndex].plus.push(plus);
            },
            removeDetailProductPlus(productIdex, plusIndex) {
                this.form.products[productIdex].plus.splice(plusIndex, 1);
            },
            showModalPaymentSupplier(){
                this.$refs.ModalPaymentSupplier.show(this.form, (this.form.code ? this.form.code : this.form.orderCode), (this.isLink ? false : true));
            }
        },
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .custom__select {
        height: 35px;
        border: none;
        border-bottom: 1px solid #C7C5C5;
        color: #495057;
    }
    .format__list-time {
        top: 0 !important;
        left: 0 !important;
        width: 220px !important;
        height: 160px;
        overflow: auto;
    }
    .input__order .form-control {
        border: none;
        border-bottom: solid 1px #ccc;
    }
    .custom__show-time {
        height: 32px;
        border-bottom: solid 1px #ccc;
        margin-bottom: 15px;
    }
    .main__content {
        margin: 10px 30px;
    }
    .input__order, input {
        width: 90%;
    }
    .input__order {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    input[type=checkbox], input[type=radio] {
        width: 10% !important;
    }
    .input__textArea textarea {
        border: none;
        resize: none;
        border-bottom: solid 1px #ccc;
    }
    .far {
        position: absolute;
        right: 25px;
        top: 11px;
        font: normal normal normal 14px/1;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .importArea{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
    }
    .button-import{
        border-radius: 3px;
        border:none;
        outline: none;
        background: $base-color;
        color: #ffffff;
    }
    .button-import:focus{
        outline: none;
        border: none;
    }
    .button-import input{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        font: 170px monospace;
        filter: alpha(opacity=0);
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
    }
    .upload-files{
        overflow: hidden;
        padding: 1px 5px 2px;
        background: #ebf7fb;
        border: 1px solid #c5e7f2!important;
        min-height: inherit;
        line-height: inherit;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        height: 40px;
    }
    #typeCard {
        border: none;
        box-shadow: none;
    }
    .block__quantity {
        position: relative;
    }
    .block__quantity-max {
        position: absolute;
        right: 15px;
        bottom: -6px;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .table-transaction thead th, .table-transaction tbody td{
        padding-top: 10px !important;
        vertical-align: top !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
</style>
