<template>
    <div class="sale-screen">
        <div id="header-sale">
            <div class="row header-main-menu">
                <div class="col-4 search-box__wrapper">                    
                    <SupplierSearch class="form-control" @select="confirmSearch" :cusStyle="true" 
                    :plus="false"
                    :placeholder="$t('message.common.searchSuppliers')" 
                    :clearKeyword="true" :currentTab="currentTab"></SupplierSearch>                    
                </div>

                <div class="col-5 p-0">
                    <div class="sale-tabs__root" ref="saleTabRoot">
                        <template v-if="showLeftRight" >
                            <div class="sale-tabs__scroller sale-tabs__left" @click.prevent="onClickScroll('left')" >
                                <div>
                                    <i class="fa fa-chevron-left"/>
                                </div>
                            </div>
                            <div class="sale-tabs__scroller sale-tabs__right"  @click.prevent="onClickScroll('right')">
                                <div>
                                    <i class="fa fa-chevron-right"/>
                                </div>
                            </div>
                        </template>
                        <div class="sale-tabs__btn" :style="{left: btnLeftFloat+'px'}" >
                            <button type="button" class="sale-tabs__btn--add" data-toggle="tooltip"  @click="addTab()"><i class="fa fa-plus-circle" ></i></button>                           
                        </div>

                        <draggable
                            v-model="tabs"
                            class="sale-tabs"
                            group="tabs"
                            @start="drag=true"
                            :wrap="false"
                            @end="drag=false"
                            id="ticket-tabs"
                            item-key="id">
                            <template #item="{element}">
                                <div v-if="!element.branchId || element.branchId == currentBranch.id"
                                     class="sale-tabs__tab" :class="{'sale-tabs__tab--active': element.id === currentTab.id}">
                                    <div class="sale-tabs__tab-content" @click.prevent="changeTab(element)">
                                        <div>
                                            <a class="sale-tabs__link" href="">{{ element.name }}</a>
                                            <a class="sale-tabs__close " href="" @click.stop.prevent="confirmCloseTab(element)" 
                                            style="margin-left: 10px" data-toggle="tooltip" data-placement="top" title="Đóng">
                                            <i class="fas fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </draggable>
                        
                    </div>
                </div>
                <!-- Thông tin tài khoản -->
                <div class="col-3 float-right">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <ul class="navbar-nav ml-auto mr-4">
                                <li class="nav-item">
                                    <a class="nav-link text-white" href="javascript:void(0);">
                                        {{ this.user.name }}
                                    </a>                                    
                                </li>
                                
                                <li class="nav-item dropdown">
                                    <a class="nav-link dropdown-toggle" href="#" id="partnerDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-bars text-white"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="parterDropdown">
                                        <a class="dropdown-item" href="/discount-bas/index" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-user-shield"></i> <span class="px-1"> {{$t('message.headers.manage')}} </span>
                                        </a>
                                        <a class="dropdown-item" @click="logout" href="#" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-sign-out-alt"></i> <span class="px-2"> {{$t('message.headers.logout')}} </span>
                                        </a>
                                    </div>

                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
        <div class="sale-screen__main">
                <component :is="currentComponent"
                    v-if="currentTab && currentTab.id"
                    v-model="currentTab"
                    :key="currentTab.id"
                    @changeData="changeData"
                    ref="ticket">
                </component>
        </div>
        <div id="footer-sale">
            <div class="row footer-sale-content">
                <div class="col-9 p-0 tabs-pos"></div>
                <div class="col-3 p-0 float-left">
                    <nav class="navbar navbar-expand-lg navbar-light">
                        <div class="collapse navbar-collapse" id="navbarNotiContent">
                            <a class="text-dark m-0 p-0 pr-2" href="#" role="button" :title="currentBranch.name" style="padding-bottom: 0">
                                <i class="fas fa-map-marker-alt"></i>
                            </a>
                            <div class="dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <span class="m-tb-auto p-0" v-text="currentBranch.name"></span>
                            </div>
                            <div class="dropdown-menu footer-dropdown-menu" style="margin-top: 0">
                                <a v-for="branch in branches"
                                    :key="branch.id"
                                    @click.prevent="setBranch(branch)"
                                    class="dropdown-item"
                                    :class="branch.id == currentBranch.id ? 'active' : ''"
                                    href="#">{{ branch.name }}</a>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
         <ModalConfirm ref="ModalConfirm" :title="$t('message.common.confirm')" 
            :dataBranch="this.dataBranch"
            :content="this.contentCancel" :dataSearch="this.dataSearch"
            @confirmBranch="selectBranch"
            @confirmSearch="onSelectSupplierSearch"
         />
         <ModalConfirmCancel ref="ModalConfirmCancel" @closeTab="closeTab"/>
    </div>
</template>

<script>

import SettlementAdvanceTab from "./SettlementAdvanceTab";
import draggable from 'vuedraggable'
import store from "../../store";
import MultipleSearch from "@/components/MultipleSearch.vue";
import moment from "moment";
import * as toastr from 'toastr';
import {
  $alert,
  $get, $post, ACTION_CREATE, ACTION_ORDER_PROCESSING,
  ACTION_UPDATE_INVOICE,
  ACTION_UPDATE_ORDER,
  isEmpty,
  TYPE_IMPORT,
  TYPE_EXPORT,
  TYPE_RETURN,
  TYPE_TRANSFER,
  TYPE_DELIVERY, ACTION_RETURN_INVOICE,
  clone
} from "../../lib/utils";
import { useLoading } from 'vue3-loading-overlay';
import emitter from "@/lib/emitter";
import ModalConfirmCancel from './ModalConfirm.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';
import AppPermission from '@/components/AppPermission.vue';
import DiscountBasTab from './DiscountBasTab.vue';
import SupplierSearch from '@/components/SupplierSearch.vue'; 


export default {
    name: "DiscountsBasCreateUpdate",
    components: {
        ModalConfirmCancel, DiscountBasTab, draggable, MultipleSearch, ModalConfirm,AppPermission,
        SupplierSearch, 
        },
    data() {
        return {
            currentComponent: 'DiscountBasTab',
            createdById: null,
            drag: false,
            showLeftRight: false,
            btnLeftFloat: -30,
            currentTab: {},
            tabs: [],
            branches: store.state.GlobalConfig?.userBranches,
            currentBranch: store.state.CurrentBranch,
            user: store.state.Auth ? store.state.Auth.user : {},
            barcode: '',
            type: null,
             Type: '',
            ticketSearchCode: null,
            content: null,
            TYPE_IMPORT, TYPE_EXPORT, TYPE_DELIVERY, TYPE_RETURN, TYPE_TRANSFER,
            dataSearch: null,
            dataBranch: null,
            isChange: false
        }
    },
    created() {
        this.handleTabInput();       
    },
    mounted() {
        document.title = this.$t('message.common.branch') + ' ' + this.currentBranch.name + '';
        this.rootWidth = this.$refs.saleTabRoot.offsetWidth;
        this.divSaleTab = window.jQuery('#ticket-tabs');
        this.updateStyle();
        // this.checkHandleTabInput();
    },
    computed: {
        getIndexTabNext() {
            const mapTabs = {};
            this.tabs.forEach(function (tab) {
                mapTabs[tab.id] = true;
            });
            let tabNext = 0;
            do {
                ++tabNext;
            } while (mapTabs[tabNext]);

            return tabNext;
        }
    },
    methods: {
        changeData(status){
            this.isChange = status;
        },
        selectBranch(branch){
            store.commit('SetCurrentBranch', branch);
            this.$nextTick(() => {
                location.reload();
            })
        },
        setBranch(branch) {                        
            if(this.isChange == true){ 
                this.dataBranch = branch;
                this.contentCancel = this.$t('message.common.confirmCancelInforSaved') + "?";
                this.$refs.ModalConfirm.show(this.$constant.branchDiscountBas);
            }else {
                this.selectBranch(branch);
            }          
        },
        async handleTabInput() {            
            this.tabs = [];
            const tabNext = this.getIndexTabNext?? null;            
            let activeTabNext = false;
            const query = this.$route.query;
            if (query.type) {
                let data = {};
                const type = query.type;
                if (type === 'update' && query.id) {
                    
                    let res = await $get('/v1/discounts-bas/show/' + query.id);
                   
                    if (res && res.result) {
                        data = res.result;                        
                        data.id = res.result.id;
                        data.amounts = res.result.amount;
                        data.name = data.code;                        
                        data.isUpdate = true;                         
                    }
                    else {
                        $alert({code: 500, message: this.$t('message.common.notFoundRecord')});
                        this.$router.replace({
                            name: 'DiscountsBasIndex'
                        });
                         
                    }
                } else if (type === 'create') {
                    data = {
                        id: +tabNext, 
                        name: this.$t('message.common.bas') + ' ' + tabNext,
                        createdBy: this.user.id,                        
                        statusName: this.$t('message.common.draft'),
                    };
                    data.details = [];
                }                
                this.tabs.unshift(data);
                activeTabNext = true; 
            }
            if (activeTabNext) {
                if (!this.currentTab || !this.currentTab.id) {
                    this.currentTab = this.tabs[0];
                }
            }
        },
        closeTab(tab) {            
            const index = this.tabs.findIndex(e => tab === e);            
            if (index >= 0) {
                this.isChange = false;
                this.tabs.splice(index, 1);
                this.updateStyle();                 
                if (this.tabs.length > 0) {                    
                    this.setCurrentTab();                    
                } else {                      
                    this.addTab();                      
                }
            }                  
        },       
        setCurrentTab() {
            const currentTabIndex = localStorage.getItem('discountBAS' + this.user.id);            
            const branchTabs = this.tabs.filter((el) => (!el.branchId || el.branchId == this.currentBranch.id));
            if (branchTabs && branchTabs.length) {
                this.currentTab = branchTabs.find(el => (el.id == currentTabIndex)) || branchTabs[0];
            } else {
                this.currentTab = {};
            }
        },
        addTab(tab = null) {            
            let id = this.getIndexTabNext;                        
            if (tab) {                
                tab.id = id;
                tab.name = this.$t('message.common.bas') + ' ' + id;
                tab.planPurchaseDate = !this.tabs.planPurchaseDate ? moment().format('DD/MM/YYYY H:mm') : moment(this.tabs.planPurchaseDate).format('DD/MM/YYYY H:mm');
                tab.createdByName = !this.tabs.createdByName ? this.user.name : this.tabs.createdByName;
                this.tabs.push(tab);
            }
            else {
                tab = {
                    id,
                    name: this.$t('message.common.bas') + ' ' + id,
                    type: this.$constant.TYPE_SRC_DISCOUNT_BAS,
                    action: ACTION_CREATE,
                    details: [],
                    planPurchaseDate: !this.tabs.planPurchaseDate ? moment().format('DD/MM/YYYY H:mm') : moment(this.tabs.planPurchaseDate).format('DD/MM/YYYY H:mm'),
                    createdByName: !this.tabs.createdByName ? this.user.name : this.tabs.createdByName,
                };
            }
            this.tabs.unshift(tab);
            this.currentTab = tab;            
            this.updateStyle();
            this.$nextTick(() => {
                this.scrollLeftTab(0);
            })
        },
        updateStyle() {
            const width = this.tabs.length * 120;
            if (width  >= this.rootWidth) {
                this.showLeftRight = true;
                this.btnLeftFloat = -55;
            } else {
                this.showLeftRight = false;
                this.btnLeftFloat = -30;
            }
        },
        changeTab(tab) {
            localStorage.setItem('discountBAS' + this.user.id, tab.id);
            this.currentTab = tab;
        },        
        onClickScroll(direction) {

            const offset = direction === 'right' ? this.divSaleTab[0].scrollLeft + 120 : this.divSaleTab[0].scrollLeft - 120;
            this.scrollLeftTab(offset)
        },
        scrollLeftTab(offset) {
            this.divSaleTab.animate({scrollLeft: offset}, 100);
        },
        confirmCloseTab(tab, confirm = true){
            const index = this.tabs.findIndex(e => tab === e);
            if (index >= 0) {
                if(tab.details && tab.details.length > 0 &&  this.isChange == true){
                    if (confirm) {
                        if (!this.$refs.ModalConfirmCancel.show(tab)) {
                            return;
                        }
                    }
                }else{
                    this.closeTab(tab);
                }
            }
                    
        },
        logout() {
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        // Tìm kiếm nhà cung cấp
        confirmSearch(ticket){     
            this.dataSearch = ticket;
            if(this.isChange == true){ 
                this.contentCancel = this.$t('message.common.confirmCancelInforSaved') + "?";                
                this.$refs.ModalConfirm.show(this.$constant.searchDiscountBas);                
            }
            else {          
                this.onSelectSupplierSearch(ticket);                      
            }
        },
        async onSelectSupplierSearch(ticket){
            
            let index = this.currentTab.details.findIndex(item => item.code == ticket.code);
            if (index != -1 && ticket.typeSrc !== this.$constant.TYPE_SRC_DISCOUNT_BAS) {
                toastr.error(this.$t('message.common.existSupplier'));
                return;
            }
            this.currentTab.planPurchaseDate = !ticket.planPurchaseDate ? moment().format('DD/MM/YYYY H:mm') : moment(ticket.planPurchaseDate).format('DD/MM/YYYY H:mm');
            this.currentTab.createdByName = !ticket.createdByName ? this.user.name : ticket.createdByName; 
            this.currentTab.supplierId = ticket.id;
            this.currentTab.supplierCode = ticket.code;
            this.currentTab.supplierName = ticket.name;    
            // Reset details chỉ hiển thị 1 dòng ghi tìm kiếm nhà cung cấp        
            if(this.currentTab.details.length > 0) {
                this.currentTab.details = [];
            }
            this.currentTab.details.unshift({
                amount: 0,
                content: null,
                deductionLimit: null,
            });                    
        }
                 
    },
    watch: {
        tabs: {
            handler: function (val) {                
                localStorage.setItem('discountBas' + this.user.id, JSON.stringify(val))
            },
            deep: true
        }
    }
}
</script>

<style  lang="scss">
@import '../../assets/css/vars';
    .sale-screen__main {
        padding-top: 5px;
        font-size: 14px;
    }
    .sale-tabs__root {
        position: relative;
        padding-left: 20px;
        padding-right: 20px;
        .sale-tabs__scroller {
            z-index: 1;
            font-size: 22px;
            cursor: pointer;
            opacity: 0.3;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            position: absolute;
        }

        .sale-tabs__btn {
            position: absolute;
            left: -55px;
            display: flex;
            height: 100%;
            align-items: center;
            z-index: 1;
        }

        .sale-tabs__btn--add {
            background-color: $base-color;
            color: #fff;
            border: 0;
            height: 100%;
            width: 50px;
            outline: 0;
            &:hover,&:focus {
                outline: 0;
                background-color: #168080;
            }
        }

        .sale-tabs__left {
            left: 50px;
        }
        .sale-tabs__right {
            right: 0;
        }
    }
    .sale-tabs {
        list-style: none;
        padding-left: 50px;
        height: 45px;
        overflow-x: hidden;
        white-space: nowrap;
        overflow-y: hidden;

        .sale-tabs__tab {
            cursor: pointer;
            color: white;
            display: inline-block;
            margin-left: 1px;
            height: 100%;
            min-width: 120px;
            background-color: #168080;
            .sale-tabs__tab-content {
                padding: 5px 10px;
                justify-content: center;
                flex-direction: column;
                display: flex;
                align-items: center;
                height: 100%;
            }
        }
        .sale-tabs__tab--active {
            background-color: white;
            .sale-tabs__link {
                color: $base-color;
                font-weight: bold;
            }
        }
        .sale-tabs__link {
            text-decoration: none;
            color: #ccc;
            font-size: 14px;
        }

        .sale-tabs__close {
            color: #ccc;
            &:hover {
                color: darkred;
            }
        }
    }

    .sale-form__print {
        display: none;
    }
    @media print {
        .sale-screen {
            display: none;
        }
        .sale-form__print {
            display: block;
        }
    }
</style>
