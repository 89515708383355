<template>
    <div ref="modalPrintOrder" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">Xem trước khi in</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <div v-html="this.data" class="px-2 pb-5 view-show" style="height:750px;"></div>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: "ModalPrintOrder",
  props: ['editorDataClone'],
  data(){
      return {              
        data: null
      };
  },
  created() {
    
  },
  mounted() {
    this.load();
    
  },
  watch: {
    editorDataClone(newData) {
        this.data = newData;
    }   
  },
  methods: {
    show() {        
        window.jQuery(this.$refs.modalPrintOrder).modal('show');
    },
    async load() {
        
        
    }
  },
};
</script>

<style scoped>
.printBox {
  font-family: Arial, sans-serif;
  font-size: 11px;
  min-height: 740px;
}

table {
  page-break-inside: auto;
  border-collapse: collapse;
}

tr {
  page-break-inside: avoid;
  page-break-after: auto;
}

img {
  max-width: 100%;
  height: auto;
}
.view-show .k-table,
.k-table td {
  outline: 0;
  border: 0px dotted #ccc !important;
   
}
</style>
