function addStyles(win: any, styles: any) {
    styles.forEach((style: any) => {
        const link = win.document.createElement("link");
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("type", "text/css");
        link.setAttribute("href", style);
        win.document.getElementsByTagName("head")[0].appendChild(link);
    });
}

const VueHtmlToPaper = {
    install(app: any, options: any = {}) {
        app.config.globalProperties.$htmlToPaper = (
            el: any,
            localOptions: any,
            cb = () => true
        ) => {
            const defaultName = "_blank",
                defaultSpecs = ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
                defaultReplace = true,
                defaultStyles: any = [];

            let name: string = options.name || defaultName;
            let specs: string[] = options.specs || defaultSpecs;
            let replace: boolean = options.replace || defaultReplace;
            let styles: any = options.styles || defaultStyles;

            // If has localOptions
            // TODO: improve logic
            if (localOptions) {
                if (localOptions.name) name = localOptions.name;
                if (localOptions.specs) specs = localOptions.specs;
                if (localOptions.replace) replace = localOptions.replace;
                if (localOptions.styles) styles = localOptions.styles;
            }

            const optionSpecs: string = specs.length ? specs.join(",") : "";

            const element = window.document.getElementById(el);

            if (!element) {
                alert(`Element to print #${el} not found!`);
                return;
            }

            const url = "";
            const win: any = window.open(url, name, optionSpecs, replace);

            win.document.write(`
          <html>
            <head>
              <title>${window.document.title}</title>
            </head>
            <body>
              ${element.innerHTML}
            </body>
          </html>
        `);

            addStyles(win, styles);

            setTimeout(() => {
                win.document.close();
                win.focus();
                win.print();
                win.close();
                cb();
            }, 1000);

            return true;
        };
    }
};

export default VueHtmlToPaper;
