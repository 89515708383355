<template>
      <div class="productBarcode__item row" :style="{height: this.height + 'mm', width: this.width + 'mm'}">
            <div class="productBarcode__name"  v-if="item.nameOptions != 0" style="font-weight: 700; "> {{item.branchName ? item.branchName : 'Omipharma'}}</div>                                
            <div class="productBarcode__name"> {{item.prdName ? item.prdName : ''}}</div>
            <BarcodeGenerator
                :value="item.prdCode"
                :width="1"
                :height="30"
                :fontSize="12"
                :margin="4"
                >
            </BarcodeGenerator>
            
            <div v-if="item.priceOptions !=0" class="productBarcode__info py-2">                    
                <label>{{item.prdPrice ? n( item.prdPrice ) : '' }} </label>
                <label v-if="item.currentOptions !=0" class="pl-1">{{item.prdCurrency ? item.prdCurrency : '' }}</label>
                <label v-if="item.unitOptions !=0">{{item.unitName ? '/' + item.unitName : '' }}</label>
            </div>
      </div>     
</template>

<script>
import BarcodeGenerator from "./BarcodeGenerator";


export default {
  name: "PaperRollLabelBarcode",
  created() {
    
  },
  props: {
    item: {},
    products: {},
    status: {},
    number: null,
    width: null,
    height: null,
  },
  components: { BarcodeGenerator },
  data() {
    return {
       
    };
  },
  methods: {},
  watch: {
  },
};
</script>
