<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2">
                        {{ $t('message.common.disconnect') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{ $t('message.common.confirmDisconnect') }} {{ this.element}}?
                    <div class="col-12 mt-4 d-flex justify-content-end">
                        <div class="btn btn-lg btn-outline btn-outline-danger btn-icon-left" @click="cancel()">{{ $t('message.button.cancel') }}</div>
                        <div class="btn btn-lg btn-danger btn-icon-left ml-2" @click="confirm()"><i class="fa fa-check text-white mr-2"></i>{{ $t('message.button.confirm') }}</div>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$get, $post} from "../../lib/utils";
import toastr from 'toastr';
export default {
    name: "ModalConfirmRemoveConnect",
    
    data(){
        return {
            element: '',
            id: null,
        }
    },
    props: [],
    methods: {
        show(id, type) {
            this.id = id;
            switch (type) {
                case this.$constant.textShopee:
                    this.element = 'Shopee';
                    break;
                case this.$constant.textLazada:
                    this.element = 'Lazada';
                    break;
                case this.$constant.textTiki:
                    this.element = 'Tiki';
                    break;
                case this.$constant.textTiktok:
                    this.element = 'Tiktok';
                    break;
                case this.$constant.textSendo:
                    this.element = 'Sendo';
                    break;
            }       
            window.jQuery(this.$refs.modal).modal('show');
        },
        cancel() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async confirm(){
            const res = await $post('/v1/connection/remove-connect-shop', {
                id: this.id,
            });
            if(res && res.result){
                if(!res.result.status){
                    toastr.error(res.result.message);
                }else{
                    toastr.success(res.result.message);
                }
                this.$emit('refresh');
                this.cancel();
            }
        }
    },
    beforeUnmount() {
        this.cancel();
    },
    unmounted() {
        this.cancel();
    }
}
</script>
<style scoped>
.btn-outline-danger:hover {
    color: #dc3545;
    background-color: #fff;
    border-color: #dc3545;
}
</style>

