<template>    
    <div ref="modal" class="modal mx-0 fade" :class="this.isFirstModal ? '' : 'modal-second'" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header my-0 pb-0 " style="border-bottom:0">
                    <h6 class="modal-title">
                            {{(this.id != null) 
                            ? $t('message.TransporterIndex.modal.modalAddTransporters.update.title') 
                            : $t('message.TransporterIndex.modal.modalAddTransporters.add.title')}}
                        </h6>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <!-- Nội dung chia làm từng hàng -->
                <div class="modal-body py-0 my-0 px-0 mx-0">                  
                    <div class="row mx-4 my-3" v-if="entry">
                        <div class="col-lg-6"> 
                            <!-- Loại đối tác -->
                            <div class="row mx-0 my-3">
                                <div class="col-lg-3 divTitle "> <div class="divTitle "> <span class="spanTitle">{{ $t('message.TransporterIndex.type')  }}</span></div></div>                                                              
                                <div class="col-lg-9">
                                    <div class="row">                                        
                                        <div class="col-1 px-0 inputStyle">                                                                                          
                                            <input type="radio" v-model="entry.type" value="0" class="form-control" style="width:25px; height:15px;" :checked="entry.type == this.$constant.typeTranPersonal ? true : false">     
                                        </div>
                                        <div class="col-4" @click.stop="checkType(this.$constant.typeTranPersonal)">
                                            <span class="spanTitle">{{ $t('message.TransporterIndex.personal') }}</span> 
                                        </div>
                                        <div class="col-1 inputStyle">                                           
                                            <input  type="radio" v-model.trim="entry.type" value="1" class="form-control" style="width:25px; height:15px;" :checked="entry.type == this.$constant.typeTranCompany ? true : false"> 
                                        </div>
                                        <div class="col-4" @click.stop="checkType(this.$constant.typeTranCompany)">
                                            <span class="spanTitle">{{ $t('message.TransporterIndex.company') }}</span>   
                                        </div>                                                                                
                                    </div>
                                </div>                      
                            </div> 
                            <!-- Mã đối tác -->
                            <div class="row mx-0 py-3">
                                <div class="col-lg-3 divTitle "> <span class="spanTitle">{{ $t('message.TransporterIndex.code')  }}</span> </div>
                                <div class="col-lg-9 px-0 mx-0"> 
                                    <input type="text" v-model.trim="this.entry.code" class="form-control textInput" maxlength="255"
                                    :placeholder="$t('message.TransporterIndex.placeholder.code')">
                                </div>
                            </div> 
                            <!-- Tên đối tác -->
                            <div class="row mx-0 my-3">
                                <div class="col-lg-3 divTitle "> <span class="spanTitle">{{ $t('message.TransporterIndex.name')  }}</span><label class="text-danger px-0">*</label>  </div>
                                <div class="col-lg-9 px-0 mx-0"> <input type="text" 
                                :placeholder="$t('message.TransporterIndex.placeholder.name')" v-model.trim="this.entry.name" class="form-control textInput" maxlength="255"></div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-lg-3 divTitle "> <span class="spanTitle">{{ $t('message.TransporterIndex.phone')  }}</span> </div>
                                <div class="col-lg-9 px-0 mx-0">                                     
                                    <InputText v-model.trim="entry.contactNumber" :errors="errors.contactNumber" 
                                    :placeholder="$t('message.TransporterIndex.placeholder.phone')" :max="10" :cusNumber="true" :sCustomer="true"/>
                                </div>
                            </div> 
                            <div class="row mx-0 my-3">
                                <div class="col-lg-3 divTitle "> <span class="spanTitle">{{ $t('message.TransporterIndex.email')  }}</span> </div>
                                <div class="col-lg-9 px-0 mx-0"> <input type="text"  
                                :placeholder="$t('message.TransporterIndex.placeholder.email')" v-model.trim="this.entry.email" class="form-control textInput"></div>
                            </div>  
                        </div>
                        <div class="col-lg-6 py-2"> 
                             <!-- Nhóm đối tác -->
                            <div class="row mx-0 mb-3">
                                <div class="col-lg-3 divTitle px-0"> <span class="spanTitle">{{ $t('message.TransporterIndex.transporter_group_name')  }}</span> </div>
                                <div class="col-lg-9 px-0 mx-0 ">
                                   <q-select :prdStyle="true" name="cusGroup" :default-options="transporterGroups" :multiple="true" v-model.trim="this.entry.transporterGroupId" :selectAll="true" 
                                   :placeholder="$t('message.TransporterIndex.placeholder.group')"/>
                                </div>
                            </div>
                            <div class="row mx-0 mb-3">
                                <div class="col-lg-3 divTitle px-0"> <span class="spanTitle">{{ $t('message.TransporterIndex.address')  }}</span> </div>
                                <div class="col-lg-9 px-0 mx-0 ">
                                   <InputText v-model.trim="entry.address" :errors="errors.address" 
                                   :placeholder="$t('message.TransporterIndex.placeholder.address')" :sCustomer="true" :max="255"/>
                                </div>
                            </div> 
                            <InputLocationPicker v-model="this.location" class=" my-3"></InputLocationPicker>
                            <div class="row mx-0">
                                <div class="col-lg-3 px-0">
                                     <span class="spanTitle">{{ $t('message.TransporterIndex.note')  }}</span>
                                </div>
                                <div class="col-lg-9 px-0">
                                      <InputTextArea v-model.trim="this.entry.note" :customerStyle="true" :max="255"
                                       :placeholder="$t('message.TransporterIndex.modal.modalAddTransporterGroups.notePlaceholder')"  />
                                       <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                </div>
                            </div>   
                        </div>
                    </div>
                    <!-- Nút Lưu và Bỏ qua -->
                    <div class="row my-3">
                        <div class="col-lg-12 flex-end">
                            <AppPermission :permission="this.$permissions.transporters.update">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="this.id">
                                    <i class="fa fa-save mr-1"></i>                                   
                                    {{$t('message.TransporterIndex.modal.modalAddTransporters.buttonAddUpdate.confirm')}}
                                </button>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.transporters.create">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="!this.id">
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('message.TransporterIndex.modal.modalAddTransporters.buttonAddUpdate.confirm')}}
                                </button>
                            </AppPermission>                        
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="this.hide()">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.TransporterIndex.modal.modalAddTransporters.buttonAddUpdate.skip')}}
                            </button>                           
                            
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <ModalTransporterGroupsDelete ref="ModalTransporterGroupsDelete" @addLoadData="addLoadData"/>
    <ModalConfirm ref="ModalConfirm" /> 
</template>
<style scoped>

.spanTitle {
    font-weight: bold;    
}

.textInput {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
/* .divTitle {
    display: flex;
    align-items: center;
} */

#fa_edit {
  position: absolute;
  left: -5px;
  top: 10px;
  color: #a7a2a2;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.inputStyle {
    padding-top: 0.5px;
}
</style>
<script>
import ActionBar from "../../../components/ActionBar";
import InputText from "@/components/forms/InputText";
import toastr from 'toastr';
import {$post, $alert, $get, scrollToElement, isChangeData, cloneObject} from "@/lib/utils";
import InputSwitchButton from "../../../components/forms/InputSwitchButton";
// import QSelect from "@/components/QSelect";
import QSelect from "@/components/QSelectMuliple";
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission';
import InputTextArea from '@/components/forms/InputTextArea.vue';
import ModalTransporterGroupsDelete from './ModalTransporterGroupsDelete.vue';
// import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputLocationPicker from "@/components/forms/InputLocationPickerSingle";
import store from "../../../store";
import ModalConfirm from '@/components/ModalConfirm.vue';

export default {
    name: "ModalAddTransporters",
    components: { ActionBar, InputText, InputSwitchButton, QSelect, InputTextArea, AppPermission, ModalTransporterGroupsDelete,
    InputLocationPicker, ModalConfirm
    },
    props: {
        typeEditAdd: String,
        idCategory: Number,
        isFirstModal: Boolean
    },
    data() {
        const globalConfig = store.state.GlobalConfig;    
        return {
            // isDataLoaded: false,
            entry: {               
                name: null,
                code: null,
                contactNumber: null,
                type: this.$constant.typeTranPersonal,
                email: null,
                status: null,
                address: null,
                provinceId: null,
                districtId: null,
                wardId: null,                
                transporterGroupId: [],
                branches:globalConfig.branches,
            },
            location: {},
            errors: {},
            default: {
                name: null,
                code: null,
                contactNumber: null,
                type: this.$constant.typeTranPersonal,
                email: null,
                status: null,
                address: null,
                provinceId: null,
                districtId: null,
                wardId: null,                
                transporterGroupId: [],
                branches:globalConfig.branches,
            },
            contentConfirm: '',
            titleConfirm: '',
            id: null,           
            transporterGroups: []
        }
    },
    methods: {  
        checkType(value){
            this.entry.type = value;
        },
        clearInput(){
           this.entry = {               
                name: null,
                code: null,
                contactNumber: null,
                type: this.$constant.typeTranPersonal,
                email: null,
                status: null,
                address: null,
                provinceId: null,
                districtId: null,
                wardId: null,                
                transporterGroupId: [],                
            },
            this.location = {};
            this.id = null;  
            emitter.emit('clearOptionsQselect');                
        },
        async show(id = null) {
            if(id === null){                          
                this.clearInput();
                
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                this.id = id;
            }
            await this.load();                
            this.default = {...this.entry};            
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {               
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.common.confirm');
                if(this.id != null){
                    this.contentConfirm = this.$t('message.CategoriesIndex.modal.contentCancelUpdate');
                }else{
                    this.contentConfirm = this.$t('message.CategoriesIndex.modal.contentCancelCreate');
                }
                this.$refs.ModalConfirm.show();
            }
            this.hide();
        },
        async getDataTransporterGroups(){
            const resG = await $get('/v1/transporters-groups/data-group-for-transporter'); // chuyển router data --> data-group-for-transporter do phân quyền nhóm đối tác            
            if(resG && resG.status.code == 200 && resG.result.status){
                 return resG;
            }
            else {
                 return null;
            }
        },
        getLocation(entry){                 
            this.location.provinceId = entry?.provinceId ? entry.provinceId : null;
            this.location.districtId = entry?.districtId ? entry.districtId : null;
            this.location.wardId = entry?.wardId ? entry.wardId : null;
                              
        },
        async load() {
            //Get dữ liệu nhóm đối tác giao hàng
            const resG = await this.getDataTransporterGroups();       
            this.transporterGroups = (resG && resG.result) ? resG.result.data : null;            
            // Lấy chi tiết danh sách đối tác giao hàng
            if(this.id){
                const res = await $post('/v1/transporters/show', {
                    id: this.id
                });              
                if(res && res.status.code == 200 && res.result.status){
                    this.entry = res.result.data;
    
                    if(this.entry) this.getLocation(this.entry);
                }
                else {
                    this.entry = null;
                }             
            }
        },
        async save() {
            this.entry.provinceId = this.location?.provinceId ? this.location.provinceId : null;
            this.entry.districtId = this.location?.districtId ? this.location.districtId : null;
            this.entry.wardId = this.location?.wardId ? this.location.wardId : null;                       
            this.errors = {};
            const submitEntry = cloneObject(this.entry);             
            // Tách hàm thêm mới và cập nhật do hai permission khác nhau
            let strSave = '';
            if(this.id) {
                strSave = '/v1/transporters/update';
                submitEntry.id = this.id;
            }
            else strSave = '/v1/transporters/create';                  
            const res = await $post(strSave, {...submitEntry });   
            if(res && res.status?.code && res.status.code == 200 && res.result){
                toastr.success(res.result.message);
                this.$emit('loadData');     
                emitter.emit('clearOptionsQselect');                    
                this.hide(); 
            }
            else{
                    if(res.messages.errors.name) toastr.error(res.messages.errors.name); 
                    if(res.messages.errors.email) toastr.error(res.messages.errors.email);    
                    if(res.messages.errors.code) toastr.error(res.messages.errors.code);    
                    if(res.messages.errors.contactNumber) toastr.error(res.messages.errors.contactNumber);    
                    if(res.messages.errors.note) toastr.error(res.messages.errors.note);                           
                    if(res.messages.errors.address) toastr.error(res.messages.errors.address);                      
            }
        },
        removeCate(name){
            this.$refs.ModalTransporterGroupsDelete.show(this.id,name);
        },
    },
    mounted() {
        // this.load();
        emitter.on('openAdd',() => this.show())
    },
}
</script>

