import {forEach, isEmpty, numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
import {clone} from "@/lib/utils";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {            
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            if(dataValue?.convertProducts && dataValue.convertProducts.length){
                for (let index = 1; index < dataValue.convertProducts.length; index++) {
                    let template = htmlObject.querySelector("#content-product").children[0];
                    let clone = template.cloneNode(true);
                    clone.children[0].innerHTML = "";
                    let node = document.createElement("tbody");
                    node.innerHTML = clone.outerHTML;
                    htmlObject.querySelector("#content-product").appendChild(node);
                }
                templateOrder = htmlObject.outerHTML;
                dataValue.convertProducts.forEach((product) => {
                    product.forEach((data, index) => {
                        templateOrder =
                            templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                            templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                    });
                });
            }            
            return templateOrder;
         },
        getProduct(entry){
            let data = [];
            let products = entry?.products ? entry.products : (entry?.details ? entry.details : null);
            if(products) {
                products.forEach(product => {               
                    data.push({
                        Ma_Hang: product?.code ? product.code : (product?.product && product.product?.code ? product.product.code :''),
                        Ten_Hang_Hoa: product?.name ? product.name : (product?.product && product.product?.name ? product.product.name :''),
                        Don_Vi_Tinh: product?.product_unit ? product.product_unit.unitName : '',                 
                        SL_Ton_Min: product?.minimalQuantity && product.minimalQuantity != null ? product.minimalQuantity : 0,
                        SL_Ton_Max: product?.maximumQuantity && product.maximumQuantity!= null ? product.maximumQuantity : 0,
                        SL_Du_Bao_Bien_Dong: product?.estimateNumber && product.estimateNumber!= null ? product.estimateNumber : 0,
                        SL_Trung_Binh_Ban_Mot_Ngay: product?.avgNumberOfSale && product.avgNumberOfSale!= null ? product.avgNumberOfSale : 0,
                        SL_He_Thong_Du_Bao_Ban: product?.sysEstimateNumber && product.sysEstimateNumber!= null ? product.sysEstimateNumber : 0,
                        Ton_Co_The_Ban: product?.availableInventory && product.availableInventory!= null ? product.availableInventory : 0,
                        Du_Tru_Dat: product?.totalQuantityOrder && product.totalQuantityOrder!= null ? product.totalQuantityOrder : 0,
                    })
                });
            }
            return data;
        },
        renderDataPlanDetails(dataValue){                
            this.editorDataClone = this.content;
            let image = null;          
            let barcode = ''; 
            if(dataValue && dataValue.common){
                dataValue.common.forEach(data => { 
                    let image = '<img src="' + data.value + '">';                    
                    switch (data.key) {      
                        case 'Logo_Cua_Hang':
                            image = '<img src="' + data.value + '">';                
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', image)?? '';
                            break;                                                          
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', data.value ) ?? null;
                            break;
                    }                  
                });
            }            
            return this.formatShowProduct(dataValue, this.editorDataClone);
        },
        startEndDate(entry){
            let Thoi_Gian_Ban_Trung_Binh = null;
            Thoi_Gian_Ban_Trung_Binh = (entry?.startDate ? moment(entry.startDate).format('DD/MM/YYYY') : '') + (entry?.endDate ? ' - ' + moment(entry.endDate).format('DD/MM/YYYY') : '');
            return Thoi_Gian_Ban_Trung_Binh;
        },
        async dataPrintPlanDetails(entry) {                        
            // eslint-disable-next-line no-unreachable
            let convertProducts = []; 
            let common = [                
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry?.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry?.branch ? entry.branch.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: entry?.branch ? entry.branch.address : ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry?.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch ? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_Gian_Tao_Phieu', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY H:m') : ''},
                {key:'Ngay_Thang_Nam_Tao', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: entry.code},
                {key:'Nguoi_Tao',value: entry.created_by_user.name?? null},
                {key:'Nhan_Vien_Ban_Hang', value: entry.created_by_user ? entry.created_by_user.name : ''},
                {key:'Nhan_Vien', value: entry.created_by_user ? entry.created_by_user.name : ''},                
            ];            
            let totalQuantity = 0;          
            let keyOrderSupplier = [];            
            switch (entry.typePrint) {
                case this.$constant.TabPlanDetails:
                    keyOrderSupplier = [                        
                        {key:'Tieu_De_In',value: "DỰ TRÙ CHI TIẾT"},
                        {key:'Ngay_Lap_Phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD')},
                        {key:'Thang_Lap_Phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('MM')},
                        {key:'Nam_Lap_Phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('YYYY')},
                        {key:'Chi_Nhanh_Du_Tru', value: entry?.branch ? entry.branch.name : ''},
                        {key:'Chi_Nhanh_Duoc_Du_Tru', value: entry?.plan_branch ? entry.plan_branch.name : ''},
                        {key:'Khu_Vuc_Chi_Nhanh', value: (entry.plan_branch ? (entry.plan_branch.district ? entry.plan_branch.district.name : '') : '') + ' - ' + (entry.plan_branch ? (entry.plan_branch.province ? entry.plan_branch.province.name : '') : '')},
                        {key:'Khu_Vuc_Chi_Nhanh_QH_TP', value: (entry.plan_branch ? (entry.plan_branch.district ? entry.plan_branch.district.name : '') : '') + ' - ' + (entry.plan_branch ? (entry.plan_branch.province ? entry.plan_branch.province.name : '') : '')},
                        {key:'Trang_Thai',value: entry?.statusName ? entry.statusName : ''},
                        {key:'Ten_Du_Tru', value: entry?.planName ? entry.planName : ''},
                        {key:'Thoi_Gian_Ban_Trung_Binh', value: this.startEndDate(entry)},
                        {key:'So_Ngay_Ban_Du_Kien', value: entry?.estimatedDate ? entry.estimatedDate + ' Ngày' : ''},
                        {key:'Tong_So_Ma_Du_Tru', value: entry?.totalProducts ? entry.totalProducts : 0},
                        {key:'Tong_So_San_Pham_Du_Tru', value: entry?.totalQuantityProducts ?  entry.totalQuantityProducts : 0},
                        {key:'Ghi_Chu_Du_Tru', value: entry?.note ?  entry.note : ''},
                        {key:'Ma_Du_Tru', value: entry?.code ? entry.code : ''}
                    ];
                    break;                       
            }                                   
            common.push(...keyOrderSupplier);             
            let products = this.getProduct(entry);                
            products.forEach((product, index) => {                
                totalQuantity = totalQuantity + parseInt(product.So_Luong);
                convertProducts.push([
                    {key:'STT', value: index+1},
                    {key:'Ma_Hang', value: product.Ma_Hang?? ''},
                    {key:'Ten_Hang_Hoa', value: product.Ten_Hang_Hoa?? ''},
                    {key:'Don_Vi_Tinh', value: product.Don_Vi_Tinh?? ''},                    
                    {key:'SL_Ton_Min', value: product.SL_Ton_Min?? 0},
                    {key:'SL_Ton_Max', value: product.SL_Ton_Max?? 0},
                    {key:'SL_Du_Bao_Bien_Dong', value: product.SL_Du_Bao_Bien_Dong?? 0},
                    {key:'SL_Trung_Binh_Ban_Mot_Ngay', value: product.SL_Trung_Binh_Ban_Mot_Ngay?? 0},
                    {key:'SL_He_Thong_Du_Bao_Ban', value: product.SL_He_Thong_Du_Bao_Ban?? 0},
                    {key:'Ton_Co_The_Ban', value: product.Ton_Co_The_Ban?? 0},
                    {key:'Du_Tru_Dat', value: product.Du_Tru_Dat?? 0},                    
                ]);
            });              
            return {common, convertProducts};
        },
        getConsignmentName(product, invoice_items){
            let consignmentName = [];
            invoice_items.forEach(item => {
                
            });
            return consignmentName;
        },
        formatproductPrintCommon(data) {            
            let dataPrint = clone(data);
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            let exsitProductIds = [];
            let details = dataPrint.details ? dataPrint.details : (dataPrint.products ? dataPrint.products : ''); 
            details.forEach(item => {
                if (!exsitProductIds.includes(item.productId)) {
                    exsitProductIds.push(item.productId);
                }
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }   
                             
                let product = clone(item);
                // Sản phẩm có lô             
                if (product.consignment_detail && product.consignment_detail.length) {
                    product.consignment_detail.forEach(detail => { 
                        product.consignment = {};
                        product.consignment.name = detail.consignment.name,
                        product.consignment.expried = detail.consignment.expried,
                        product.quantity = detail.quantity,
                        product.discount = (item.discount/item.quantity) * detail.quantity;
                        if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                            product.subTotal = product.price*detail.quantity;
                        } else {
                            product.subTotal = (product.price*detail.quantity) - product.discount;
                        }
                        totalPrice += +product.subTotal;
                        products.push(clone(product));
                    });
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));                    
                }
                // Sản phẩm có plus
                if (product && product.plus) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            plus.product.consignments = product.product.consignments;
                            plus.consignment_detail.forEach(detail => {
                                plus.consignment = {};
                                plus.consignment.name = detail.consignment.name;
                                plus.consignment.expried = detail.consignment.expried;
                                plus.quantity = detail.quantity;
                                plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                                if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                                    plus.subTotal = plus.price*detail.quantity;
                                } else {
                                    plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                                }
                                totalPrice += +plus.subTotal;
                                products.push(clone(plus));
                            });
                        } else {
                            totalPrice += +plus.subTotal;
                            products.push(clone(plus));
                        }
                    });
                }
            });
            dataPrint.planName = dataPrint.name;
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            dataPrint.exsitProductIds = exsitProductIds;            
            return dataPrint;
        },
    },
}
  