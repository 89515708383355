<template>
  <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
    <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
      <div class="modal-content">
        <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
          <div class="d-flex">
            <h5 style="fotn" class="modal-title py-2"> {{ this.isEdit ? this.$t('message.ModalCreateUpdateDelivery.title_update')
              : this.$t('message.ModalCreateUpdateDelivery.title') }}</h5>
          </div>
          <button type="button" class="close" @click="hide()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body py-0 mx-4 my-4 px-0">
          <div class="content">
            <div class="row">
              <div class="col-6">
                <!-- Start name -->
                <div class="row">
                  <div class="col-3">
                    <b>{{ $t('message.ModalCreateUpdateDelivery.name_address') }}</b>
                  </div>
                  <div class="col-9">
                    <InputText v-model.trim="entry.nameAddress"/>
                  </div>
                </div>
                <!-- End name -->

                <!-- Start birthday and gender -->
                <div class="row">
                  <div class="col-3">
                    <b>{{ $t('message.ModalCreateUpdateDelivery.name') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-9">
                    <InputText v-model="entry.name" />
                  </div>
                </div>

                <div class="row">
                  <div class="col-md-3">
                    <b>{{ $t('message.ModalCreateUpdateDelivery.phone') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-md-9">
                    <InputText v-model="entry.phone" :max=12 :cusNumber="true"/>
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <b>{{ $t('message.ModalCreateUpdateDelivery.address_value') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-9">
                    <InputTextArea v-model="entry.addressValue" />
                  </div>
                </div>

              </div>
              <div class="col-6">
                <div class="row mb-3">
                  <div class="col-3">
                    <b>{{ $t('message.branchIndex.modal.branch_province') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-9">
                    <q-select :removeKeyword="true" v-model="entry.provinceId" :default-options="provinces" :multiple="false"
                      :placeholder="$t('message.branchIndex.placeholder.province')" @update:modelValue="changeProvince"
                      ref="inputProvinces" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-3">
                    <b>{{ $t('message.branchIndex.modal.branch_district') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-9">
                    <q-select :removeKeyword="true" v-model="entry.districtId" :default-options="districts" :multiple="false"
                      :placeholder="$t('message.branchIndex.placeholder.district')" @update:modelValue="changeDistrict"
                      ref="inputDistricts" />
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-3">
                    <b>{{ $t('message.branchIndex.modal.branch_ward') }}<label class="text-danger px-0">*</label></b>
                  </div>
                  <div class="col-9">
                    <q-select :removeKeyword="true" v-model="entry.wardId" :default-options="wards" :multiple="false"
                      :placeholder="$t('message.branchIndex.placeholder.ward')" ref="inputWards" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-primary" @click="update(this.entry.id)" :disabled="requestData" v-if="this.isEdit">
            <i class="fa fa-save"></i>
            {{ $t('message.branchIndex.modal.button.save') }}
          </button>
          <button class="btn btn-primary" @click="save" :disabled="requestData" v-else>
            <i class="fa fa-save"></i>
            {{ $t('message.branchIndex.modal.button.save') }}
          </button>
          <button type="button" class="btn btn-secondary text-white  mr-2" @click="hide()">
            <i class="fa fa-ban mr-1"></i>
            {{ $t('message.branchIndex.modal.button.skip') }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm" />
</template>
<script>
import TabInfo from "@/views/branches/tabs/TabInfo";

import emitter from "@/lib/emitter";
import { $post, $alert, $get, scrollToElement, isChangeData } from "@/lib/utils";
import store from "@/store";
import ModalConfirm from "@/components/ModalConfirm.vue";
import toastr from 'toastr';
import InputText from "@/components/forms/InputText";
import InputTextArea from "@/components/forms/InputTextArea";
import QSelect from "@/components/QSelect";

export default {
  name: "ModalCreateUpdateDelivery",
  emits: ['updated', 'inputData'],
  components: { TabInfo, ModalConfirm, InputText, InputTextArea, QSelect },
  props: {
    openModal: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    isMessageError(errMss) {
      if (errMss['name']) {
        toastr.error(errMss['name']);
        return;
      }
      if (errMss['phone']) {
        toastr.error(errMss['phone']);
        return;
      }
      if (errMss['addressValue']) {
        toastr.error(errMss['addressValue']);
        return;
      }
      if (errMss['provinceId']) {
        toastr.error(errMss['provinceId']);
        return;
      }
      if (errMss['districtId']) {
        toastr.error(errMss['districtId']);
        return;
      }
      if (errMss['wardId']) {
        toastr.error(errMss['wardId']);
        return;
      }
    },
    async save() {
      const result = await $post('/v1/delivery-address/create', this.entry);
        if (result.code === 512 || result.code === 422) {
            this.errors = result.errors;
            scrollToElement('.error-label');
            this.isMessageError(result.messages.errors);
        } else {
            toastr.success(this.$t("message.ModalCreateUpdateDelivery.message_success"));
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('updated', result);
            this.$emit('inputData','1');
        }
    },
    async update() {
      const result = await $post('/v1/delivery-address/update', this.entry);
        if (result.code === 512 || result.code === 422) {
            this.errors = result.errors;
            scrollToElement('.error-label');
            this.isMessageError(result.messages.errors);
        } else {
            toastr.success(this.$t("message.ModalCreateUpdatePickup.message_success_update"));
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('updated', result);
            this.$emit('inputData','1');
        }
    },
    async show(id, code, type) {
      this.setValue();
      if (id && type) {
        this.isEdit = true;
        await this.load(id);
      } else if (id && code) {
        this.entry.customer_id = id;
        this.entry.customer_code_address = code;
      }
      await this.loadDataLocation();
      window.jQuery(this.$refs.modal).modal('show');
    },
    hide() {
      this.requestData = false;
      this.$emit('inputData', '1');
      window.jQuery(this.$refs.modal).modal('hide');
    },
    async load(id) {
      const res = await $get(`/v1/delivery-address/show/${id}`);
      if (res.code === 404) {
        location.replace('/404');
        return;
      }
      this.entry = res.result;
    },
    setValue () {
        this.isEdit = false;
        this.entry = {
            addressValue : '',
            provinceId: '',
            districtId: '',
            wardId: '',
            phone: '',
            nameAddress: ''
        };
    },
    async loadDataLocation() {
      const res = await $get('/v1/locations/provinces', {
        provinceId: this.entry.provinceId,
        districtId: this.entry.districtId
      });

      this.provinces = res.data.provinces;
      this.districts = res.data.districts;
      this.wards = res.data.wards;
    },
    async changeDistrict() {
      this.entry.wardId = null;
      const res = await $get('/v1/locations/wards', { districtId: this.entry.districtId });
      this.wards = res.data;
    },
    async changeProvince() {
      this.entry.districtId = null;
      this.entry.wardId = null;
      const res = await $get('/v1/locations/districts', { provinceId: this.entry.provinceId });
      this.districts = res.data;
      this.changeDistrict();
    },
    closeModal() {
      window.jQuery(this.$refs.modal).modal('hide');
    },
  },
  data() {
    return {
      isEdit: false,
      id: null,
      entry: {},

      provinces: [],
      districts: [],
      wards: [],
      title: '',
      isDataLoaded: false,
      supplierGroups: [],
      default: {},
      contentConfirm: '',
      titleConfirm: '',
      requestData: false,
    }
  },
  created() {
    this.loadDataLocation();
  },
}
</script>
<style>.action-bar {
  display: none !important;
}</style>