<template>
    <div class="row">
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Quản lý hóa đơn</h1>
            </div>
            <TabNavigation @tabChange="tabChange"
                           ref="tab"
                           :tabs="tabs"/>
        </div>
    </div>
</template>

<script>
    import {$get} from "@/lib/utils";
    import TabNavigation from "../../components/TabNavigation";
    import {markRaw} from "vue";
    import InvoiceTabInvoiceInfo from "./tabs/InvoiceTabInvoiceInfo";
    import InvoiceTabPaymentHistory from "./tabs/InvoiceTabPaymentHistory";

    export default {
        name: "InvoiceForm",
        components: {TabNavigation},
        data() {
            return {
                tabs: [
                    {id: 'TabInvoiceInfo', name: 'Thông tin hóa đơn', component: markRaw(InvoiceTabInvoiceInfo)},
                    {id: 'TabPaymentHistory', name: 'Lịch sử thanh toán' , component: markRaw(InvoiceTabPaymentHistory)}
                ],
            }
        },
        mounted() {
            document.title = 'Chỉnh sửa hóa đơn | OmiPos';
        },
        methods: {
            tabChange(tab) {
                this.currentTab = tab;
                if (this.$route.query.id) {
                    this.load();
                } else {
                    this.$refs.tab.setTabData({
                        isDataLoaded: true
                    })
                }
            },
            async load() {
                const res = await $get('/v1/invoices/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }

                if (!res.data.entry || !res.data.entry.cash_flows.length) {
                    const indexPayment = this.tabs.findIndex(el => (el.id === "TabPaymentHistory"));
                    if (indexPayment !== -1) {
                        this.tabs.splice(indexPayment, 1);
                    }
                }

                this.$refs.tab.setTabData({
                    entry: res.data.entry,
                    invoiceItems: res.data.entry.invoice_items || [],
                    listStatusPack: res.data.list_status_pack,
                    delivery: res.data.delivery || null,
                    receiverLocation: res.data.receiver_location || [],
                    listDeliveryStatus: res.data.list_delivery_status || [],
                    isDataLoaded: true,

                    invoiceCashFlows: res.data.entry.cash_flows || [],
                });

            }
        }
    }
</script>

<style scoped>

</style>
