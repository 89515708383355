<template>
    <div class="mx-2 pt-2 pb-4 design" style="min-height:600px !important">
        <div v-html="this.content"></div>
    </div>
</template>

<script>
export default {
    name: "Design",
    mounted() { },
    props: ["content"],
    data() {
        return {};
    },
    methods: {},
};
</script>

<style>
.k-marker {
    display: none;
}

.k-paste-container,
.Apple-style-span {
    position: absolute;
    left: -10000px;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

ul,
ol {
    padding-left: 2.5em;
}

span {
    -ms-high-contrast-adjust: none;
}

a {
    color: #00a;
}

code {
    font-size: 1.23em;
}

telerik\3ascript {
    display: none;
}

.k-table {
    table-layout: fixed;
    width: 100%;
    border-spacing: 0;
    margin: 0 0 1em;
}

.k-table td {
    min-width: 1px;
    padding: 0.2em 0.3em;
}

.k-table p {
    margin: 0;
    padding: 0;
}

k\:script {
    display: none;
}


.cke_top {
    padding: 50px 8px 2px !important;
}

#rowSelect {
    position: absolute;
    top: 5px;
}

#inputPrint {
    border: 0;
    border-radius: 0;
    border-bottom: 1px solid #ccc;
    background-color: #f8f8f8;
}

#inputPrint:focus,
#inputPrint:hover,
.prd-search-box1:hover,
.prd-search-box1:active {
    border-bottom-color: #0dac50;
}

#orderTemplate {
    display: block !important;
}

#printPaper {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
    background-color: #f8f8f8;
}

#printPaper:hover,
#printPaper:focus {
    border-bottom: 1px solid #0dac50;
}

.design .k-table,
.design .k-table td {
    outline: 0;
    border: 1px dotted #ccc;
    word-wrap: break-word;
}
</style>
