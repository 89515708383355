<template>
  <div
    ref="modal"
    class="modal mx-0 fade modal-second"
    tabindex="-1"
    role="dialog"
    id="ModalSelectLocations">
    <div class="modal-dialog modal-lg" role="document" style="width: 900px">
      <div class="modal-content">
        <div class="modal-header py-1" style="boder-bottom: 1px solid #ccc">
          <h5 class="modal-title py-2">
            {{$t('message.LocationIndex.titleChooseParentCate')}}
        </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-4">
          <!-- Hiển thị tên đường dẫn -->
          <!------------------------------------------------------------------------------------>
          <div class="row">
                <div class="col-12">
                    <!-- Parent Lv1 -->
                    <span v-if="this.locationName.nameLv1">                    
                        {{ this.locationName.nameLv1 ? this.locationName.nameLv1 :'' }} 
                        <!-- <i class="fa fa-angle-right rightIcon px-2" aria-hidden="true"></i> -->
                    </span>
                    <!-- Childrent Lv1 -->
                    <span v-if="this.locationName.nameLv2">
                      <i class="fa fa-angle-right rightIcon px-2" aria-hidden="true"></i>
                        {{ this.locationName.nameLv2 ? this.locationName.nameLv2: '' }} 
                    </span>
                  <span v-if="this.locationName.nameLv3">                    
                    <i class="fa fa-angle-right rightIcon px-2" aria-hidden="true"></i>
                        {{ this.locationName.nameLv3 ? this.locationName.nameLv3 :'' }} 
                    </span>
                    <!-- Childrent Lv1 -->
                    <span v-if="this.locationName.nameLv4">
                      <i class="fa fa-angle-right rightIcon px-2" aria-hidden="true"></i>
                        {{ this.locationName.nameLv4 ? this.locationName.nameLv4 : '' }} 
                    </span>
                </div>            
          </div>
        <!------------------------------------------------------------------------------------>

          <div class="row my-2 overflow-auto" style="border: 1px solid #ccc; height: 400px; border-radius:5px;">
                 <!-- Level cấp 1 -->
                <div class="col-2 mx-0 px-0 my-4 ml-4 mr-1">                    
                    <ul class="list-group"> 
                      <template v-for="data in this.dataLv1" :key="data.id">                       
                        <li class="list-group-item py-2"  @click="showItem(2,data.id, data.name, data.code)" :class="this.locationName.nameLv1 && this.locationName.nameLv1 == data.name ? 'bg-light' : ''">
                            <a href="#" class="linkA"> {{data.name}}
                            <span class="dropdown-btn pr-0" style="right: 0">
                                <i class="fa fa-angle-right rightIcon" aria-hidden="true"></i>
                            </span>
                            </a>
                        </li>                     
                      </template>                     
                    </ul>
                </div>
                <!-- Level cấp 2 -->
                <div class="col-2  mx-0 px-0 my-4 mr-1">                   
                    <ul class="list-group" v-if="this.showLv2">
                       <template v-for="data in this.dataLv2" :key="data.id">
                        <li class="list-group-item py-2"  @click="showItem(3,data.id, data.name, data.code)" :class="this.locationName.nameLv2 && this.locationName.nameLv2 == data.name ? 'bg-light' : ''">
                            <a href="#" class="linkA"> {{data.name}}
                            <span class="dropdown-btn pr-0" style="right: 0">
                               <i class="fa fa-angle-right rightIcon" aria-hidden="true"></i>
                            </span>
                            </a>
                        </li>
                      </template>
                    </ul>
                </div>
                <!-- Level cấp 3 -->
                <div class="col-2 mx-0 px-0 my-4 mr-1">                    
                    <ul class="list-group" v-if="this.showLv3">
                      <template v-for="data in this.dataLv3" :key="data.id">
                        <li class="list-group-item py-2"  @click="showItem(4,data.id, data.name, data.code)" :class="this.locationName.nameLv3 && this.locationName.nameLv3 == data.name ? 'bg-light' : ''">
                            <a href="#" class="linkA"> {{data.name}}
                            <span class="dropdown-btn pr-0" style="right: 0">
                                <i class="fa fa-angle-right rightIcon" aria-hidden="true"></i>
                            </span>
                            </a>
                        </li>
                      </template>
                    </ul>
                </div>
                <!-- Level cấp 4 -->
                <div class="col-2  mx-0 px-0 my-4 mr-1">                   
                    <ul class="list-group" v-if="this.showLv4">
                      <template v-for="data in this.dataLv4" :key="data.id">
                        <li class="list-group-item py-2"  @click="!this.addLocationCategory ? showItem(5,data.id, data.name, data.code) : null" :class="this.locationName.nameLv4 && this.locationName.nameLv4 == data.name ? 'bg-light' : ''">
                            <a href="#" class="linkA"> {{data.name}} </a>
                        </li>
                      </template>
                    </ul>
                </div>
          </div>
        </div>
        <div class="modal-footer my-0 py-2">
          <button class="btn btn-primary mr-2" @click="submit">
            <i class="fa fa-check mr-1"></i>
            {{$t('message.button.choose')}}
          </button>
          <button
            class="btn btn-secondary text-white"
            data-dismiss="modal"
            aria-label="Close">
            <i class="fa fa-ban mr-1"></i>
            {{$t('message.button.skip')}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>

// import {$post} from "@/lib/utils";
// import toastr from 'toastr';
import store from "@/store";
import { $get, clone } from "@/lib/utils";
import emitter from "@/lib/emitter";

export default {
  name: "ModalSelectLocations",
  components: {

  },
  props: ['addLocationCategory'],
  data() {
    return {
    dataLv1: [],
    dataLv2: [],
    dataLv3: [],
    dataLv4: [],
    input: 4,
    branchId: store.state.CurrentBranch.id,
    branchName: store.state.CurrentBranch.name,
    locationId: null,
    locationName: {
      nameLv1: null,
      nameLv2: null,
      nameLv3: null,
      nameLv4: null,
    },
    locationCode: {
      codeLv1: null,
      codeLv2: null,
      codeLv3: null,
      codeLv4: null,
    },
    
    showLv2:false,
    showLv3:false,
    showLv4:false,
    };
  },
  mounted() {},
  created() {    
    emitter.on('resetDataSelectLocation', () => {            
      this.resetData();
    });
    emitter.on('getDataSelectLocation', (params) => {   
      this.load(this.$constant.locationCategoryLevel1, this.$constant.isFirstCate);
      let data = clone(params);
      data.forEach((param, index) => {
        this.showItem((index + 2), param.id, param.name, param.code);
      });
      this.$emit('updateLocationsName', this.locationName)
    });
    emitter.on('setBranchIdSelectLocation', (branchId) => {  
      this.branchId = branchId;          
      this.resetData();
      this.load(this.$constant.locationCategoryLevel1, this.$constant.isFirstCate);
    });
  },

  methods: {
    show() {
      window.jQuery(this.$refs.modal).modal("show");
      this.load(this.$constant.locationCategoryLevel1, this.$constant.isFirstCate);
    },
    resetData(){
      this.showLv1 = false;
      this.showLv2 = false;
      this.showLv3 = false;
      this.showLv4 = false;
      this.locationName = {
        nameLv1: null,
        nameLv2: null,
        nameLv3: null,
        nameLv4: null,
      }
    },
    hide() {
      window.jQuery(this.$refs.modal).modal("hide");
    },        
    async showItem(level, id, name, code){      
      
        if(id){
            this.locationName.id = id;
            this.$emit('loadLocationId', id)
        }  
        switch (level) {
            case this.$constant.locationCategoryLevel2:
                this.showLv2 = true;
                if(this.showLv3 == true) this.showLv3 = !this.showLv3;
                if(this.showLv4 == true) this.showLv4 = !this.showLv4;   
                this.locationName.nameLv1 = name;
                
                //Reset nameLv2,Lv3,lv4
                this.locationName.nameLv4 = null;
                this.locationName.nameLv3 = null;
                this.locationName.nameLv2 = null;
                // Load code
                this.locationCode.codeLv1 = code;                
                //Reset nameLv2,Lv3,lv4
                this.locationCode.codeLv4 = null;
                this.locationCode.codeLv3 = null;
                this.locationCode.codeLv2 = null;

                //Get data level 2
                await this.load(this.$constant.locationCategoryLevel2, id);
                
                break;
            case this.$constant.locationCategoryLevel3:
                this.showLv3 = true;
                if(this.showLv4 == true) this.showLv4 = !this.showLv4; 
                this.locationName.nameLv2 = name;
                // Reset Lv3,lv4
                this.locationName.nameLv4 = null;
                this.locationName.nameLv3 = null;  
                //Reset Code 
                this.locationCode.codeLv2 = code;                
                //Reset nameLv2,Lv3,lv4
                this.locationCode.codeLv4 = null;
                this.locationCode.codeLv3 = null;                
                await this.load(this.$constant.locationCategoryLevel3, id)                
                break;
            case this.$constant.locationCategoryLevel4:                                
                this.locationName.nameLv3 = name;
                // Reset nameLv4
                this.locationName.nameLv4 = null;
                //Code
                this.locationCode.codeLv3 = code;                
                //Reset nameLv2,Lv3,lv4
                this.locationCode.codeLv4 = null;
                await this.load(this.$constant.locationCategoryLevel4, id);                
                if(this.dataLv4.length > 0) this.showLv4 = true;
                else this.showLv4 = false;                
                break;
            case this.$constant.locationCategoryLevel5:
                // this.showLv4 = true;
                this.locationName.nameLv4 = name;
                this.locationCode.codeLv4 = code;             
                break;
        }
    },
    
    async load(type, parent) {
        // Get data parent cấp 1        
        const res = await $get("/v1/locations/get-data-by-parent", {parent: parent, branchId: this.branchId});
        if(res && res.status.code == 200) {
            switch (type) {
                case this.$constant.locationCategoryLevel1:
                    this.dataLv1 = res.result;                     
                    break;
                case this.$constant.locationCategoryLevel2:
                    this.dataLv2 = res.result;
                    break;
                case this.$constant.locationCategoryLevel3:                    
                    this.dataLv3 = res.result;        
                    break;
                case this.$constant.locationCategoryLevel4:
                    this.dataLv4 = res.result;                    
                    break;
                default:
                    break;
            }            
        }
        else {
             switch (type) {
                case this.$constant.locationCategoryLevel1:
                    this.dataLv1 = [];
                    break;
                case this.$constant.locationCategoryLevel2:
                    this.dataLv2 = [];
                    break;
                case this.$constant.locationCategoryLevel3:
                    this.dataLv3 = [];
                    break;
                case this.$constant.locationCategoryLevel4:
                    this.dataLv4 = [];
                    break;
                default:
                    break;
            }          
        }
        
    },
    submit(){
        this.$emit('updateLocationsName', this.locationName)        
        this.$emit('updateLocationsCode', this.locationCode);
        this.hide();
    }
  },
};
</script>
<style scoped>
.dropdown-menu-item {
  position: absolute;
  top: 100%;
  left: 2px;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 13px;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
#subItemLv2{
    position: absolute;
    top:  -2px ;
    left: 172px;
}
.parentLv1 {
    border: 1px solid #ccc; width: 170px;
    height: 40px !important;
    border-radius: 5px;
}
.dropdown-menu > li:hover > .submenu{
    display: block;
}
.linkA {
    text-decoration: none !important;
    color: #000
}
</style>
