import i18n from "@/setup/i18n";

export const DATE_LOCALES = {
    "format": "DD/MM/YYYY",
    "separator": " -- ",
    "applyLabel": i18n.t('message.button.choose'),
    "cancelLabel": i18n.t('message.button.cancel'),
    "fromLabel": i18n.t('message.TransporterIndex.fee_from'),
    "toLabel": i18n.t('message.TransporterIndex.fee_to'),
    "customRangeLabel": i18n.t('message.ProductIndex.button.option'),
    "daysOfWeek": [
        i18n.t('message.common.sunday'),
        i18n.t('message.common.monday'),
        i18n.t('message.common.tuesday'),
        i18n.t('message.common.wednesday'),
        i18n.t('message.common.thursday'),
        i18n.t('message.common.friday'),
        i18n.t('message.common.saturday')
    ],
    "monthNames": [
        i18n.t('message.common.month1'),
        i18n.t('message.common.month2'),
        i18n.t('message.common.month3'),
        i18n.t('message.common.month4'),
        i18n.t('message.common.month5'),
        i18n.t('message.common.month6'),
        i18n.t('message.common.month7'),
        i18n.t('message.common.month8'),
        i18n.t('message.common.month9'),
        i18n.t('message.common.month10'),
        i18n.t('message.common.month11'),
        i18n.t('message.common.month12')
    ],
    "firstDay": 1
};
