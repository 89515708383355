<template>
    <div class="row">
        <div class="col-lg-12">
            <InputNumber v-model="productBranchMeta.onShowRoomQuantity" label="Số lượng trưng bày"/>
            <InputNumber v-model="productBranchMeta.minimalQuantity" label="Tồn kho min"/>
            <InputNumber v-model="productBranchMeta.maximumQuantity" label="Tồn kho max"/>
        </div>
    </div>
</template>

<script>
import InputNumber from "../../../components/forms/InputNumber";
export default {
    name: "ProductFormTabThreshold",
    components: {InputNumber},
    data() {
        return {
            productBranchMeta: {
                onShowRoomQuantity: 0,
                minimalQuantity: 0,
                maximumQuantity: 0,
            }
        }
    }
}
</script>

<style scoped>

</style>
