<template>    
        <div v-click-outside="hideDiscountForm">
            <div class="row mx-4 my-4 mt-1 popup-update-price" v-if="showPopupDiscount == true" :style="[('top: ' + this.top), (this.right ? 'right: ' + this.right : '')]">
                <div class="col-3 p-0 d-flex align-items-center">
                    <span class="mb-0 ow-break-word">
                        {{ $t('message.DetailOrdersCard.prodDiscount') }}
                    </span> 
                </div>
                <div class="col-5 p-0 d-flex align-items-center custom__input-discount">
                    <InputNumberFormat v-if="this.model.discountType === this.$constant.discountTypeMoney" :notBorder="true" class="input-custom min-w-100 text-right bg-transparent"
                    v-model="this.model.discountValue" @input="onInputDiscount()" :max="this.model.totalPrice" :min="0"/>
                    <InputNumberFormat v-else :notBorder="true" class="input-custom min-w-100 text-right bg-transparent"
                    v-model="this.model.valuePercent" @input="onInputDiscount()" :max="100" :min="0"/> 
                </div>
                <div class="col-2 p-0 d-flex align-items-center">
                    <button type="button" class="btn btn-default ml-2 text-white" 
                    v-for="opt in this.$constant.discountOptions" :key="opt.id" :class="this.model.discountType == opt.id ? 'btn btn-save' : ''"
                    @click="changeDiscountType(opt.id)">{{opt.name}}</button> 
                </div>
            </div>
        </div>    
</template>

<script>
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import currencyMixin from "@/currencyMixin";    
    import AppPermission from './AppPermission.vue';
    export default {
        name: "OrderDiscount",
        components: {InputNumberFormat, AppPermission},
        mixins: [currencyMixin],
        props:['modelValue', 'price', 'indexDiscount', 'discountMoney', 'discountPercent','readonly'],
        
        data() {
            return {
                dEntry: this.modelValue,
                model: this.getData(this.modelValue),
                showPopupDiscount: false,
                maxInputDiscount: 0,
                inputDiscount: 0,
                notEmit: false,
                top: 0,
                right: 0,
            }
        },
        methods: {
            getData(entry){
                return {
                    discountValue: entry.discountValue ? entry.discountValue : (entry.discount ?? 0),
                    discountType: entry.discountType ? entry.discountType : this.$constant.discountTypeMoney,
                    valuePercent: entry.valuePercent ? entry.valuePercent : 0,
                    totalPrice: this.price ? this.price : this.getTotalPrice(entry),
                }
            },
            getTotalPrice(entry){
                if(entry.price){
                    return entry.price;
                }else if (entry.priceReturn) {
                    return entry.priceReturn;
                } else if(entry.product_units){
                    return entry.product_units[0].price;
                }else if(entry.totalPrice){
                    return entry.totalPrice;
                }else{
                    return 0;
                }
            },
            showDiscountForm(top, right = false){
                this.top = top + 'px';
                this.right = right ? this.right + 'px' : 0;
                this.showPopupDiscount = true;
                if(this.model.discountType == null){
                    this.model.discountType = 0;
                }
            },
            hideDiscountForm(){
                if(this.top){
                    this.showPopupDiscount = false;
                    this.top = 0;
                    this.right = 0;
                }
            },
            changeDiscountType(id){
                this.notEmit = false;
                if(id != this.model.discountType){
                    this.model.valuePercent = this.model.discountValue = 0;
                }
                this.model.discountType = id;
                this.$emit('update:modelValue', {model: this.model, dEntry: this.dEntry, index: this.indexDiscount});
            },
            onInputDiscount(){
                this.notEmit = false;
                if(this.model.discountType === this.$constant.discountTypeMoney){
                    if(this.model.discountValue > this.model.totalPrice){
                        this.model.discountValue = this.model.totalPrice;
                    }else if(this.model.discountValue < 0){
                        this.model.discountValue = 0;
                    }
                }else{
                    if(this.model.valuePercent > 100){
                        this.model.valuePercent = 100;
                    }
                    this.model.discountValue = (this.model.valuePercent/100) * this.model.totalPrice;
                }
                this.$emit('update:modelValue', {model: this.model, dEntry: this.dEntry, index: this.indexDiscount});
            },
            updateDiscountParams(discountValue = null){
                this.notEmit = true;
                this.model.discountValue = discountValue;
                this.model.discountType = this.$constant.discountTypeMoney;
            }
        },
        watch: {
            modelValue: {                
                handler(newValue){
                    this.model = this.getData(newValue);
                },
                deep: true
            },
            'model.discountValue'(value){
                if(!this.notEmit){
                    this.model.discountValue = value;
                    this.onInputDiscount();
                }
            },
            'model.valuePercent'(value){
                if(!this.notEmit){
                    this.model.valuePercent = value;
                    this.onInputDiscount();
                }
            },
            price: {                
                handler(newPrice){
                    if(!this.notEmit){
                        this.model.totalPrice = newPrice;
                        this.onInputDiscount();
                    }
                },
                deep: true
            },
            discountMoney: {                
                handler(newDiscount){
                    this.model.discountValue = newDiscount;
                },
                deep: true
            },
            discountPercent: {                
                handler(newPercent){
                    this.model.valuePercent = newPercent;
                },
                deep: true
            },
        }
    }
</script>

<style scoped>
.popup-update-price{
    position: fixed;
    right: 170px;
    z-index: 99;
    width: 350px;
    background: white;
    box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
    padding: 1.4rem;
    border: none;
    border-radius: 7px;
}
.format__input {
    border-bottom: 1px solid #C7C5C5!important;
    margin-bottom: 15px !important;
    text-align: right;
    border: none;
    background: none;
    width: 100%;
}
.format__input:focus{
    border: none;
    outline: none;
}
.format__discount {
    padding-right: 12px !important;
    padding-bottom: 2px !important;
}
.custom__input-discount input{
    border: none !important;
    text-align: right !important;
}
</style>
