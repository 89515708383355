import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";
const currentYear = (new Date).getFullYear().toString();

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}
function datetime(tzTime) {
    if (!tzTime) {
        return '';
    }

    const m = moment(tzTime);

    if (m.format('YYYY') === currentYear) {
        return m.format('DD/MM HH:mm')
    }

    return m.format('DD/MM/YYYY HH:mm')
}

const fields = {
    'Mã chuyển hàng': 'code',
    'Loại phiếu': 'typeName',
    'Từ chi nhánh': 'fromBranchName',
    'Tới chi nhánh': 'toBranchName',
    'Ngày chuyển': 'createdDate',
    'Thời gian tạo': {
        field: 'createdAt',
        callback: (value) => {
            return datetime(value);
        }
    },
    'Ngày nhận': {
        field: 'receivedDate',
        callback: (value) => {
            return datetime(value);
        }
    },
    'Người tạo': 'createdByName',
    'Ghi chú chuyển': 'sendDescription',
    'Ghi chú nhận': 'receiveDescription',
    'Tổng SL chuyển': {
        field: 'totalSendQuantity',
        callback: (value) => {
            return n(value);
        }
    },
    'Tổng giá trị chuyển': {
        field: 'totalSendPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Tổng SL nhận': {
        field: 'totalReceiveQuantity',
        callback: (value) => {
            return n(value);
        }
    },
    'Tổng giá trị nhận': {
        field: 'totalReceivePrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Tổng số mặt hàng': {
        field: 'totalProduct',
        callback: (value) => {
            return n(value);
        }
    },
    'Trạng thái': 'statusName',
    'Mã hàng': 'code',
    'DVT': 'productUnitName',
    'Tên hàng': 'productName',
    'Số lượng chuyển': 'sendQuantity',
    'Số lượng nhận': 'receiveQuantity',
    'Lô': 'batchName',
    'Hạn sử dụng': 'batchDate',
    'Giá chuyển/nhận': {
        field: 'price',
        callback: (value) => {
            return n(value);
        }
    },
    'Thành tiền chuyển': {
        field: 'sendPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Thành tiền nhận': {
        field: 'receivePrice',
        callback: (value) => {
            return n(value);
        }
    },

};

export default fields;
