<template>
    <div class="container my-4">
        <div class="row m-0 p-0">
            <div class="mainLeft">
                <div class="main-title">
                    <span>{{ $t('message.ReportValueBranch.title') }}</span>
                </div>
                <!-- Xuất file -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12">
                                <button class="btn btn-save w-100 mx-0" @click="exportReport()">
                                    <i class="fas fa-file-export"></i>
                                    {{ $t('message.ReportValueBranch.button.export') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tìm kiếm Thời gian -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{ $t('message.ReportValueBranch.time') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                    <i class="fa fa-chevron-up" id="timeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="timeBody">
                        <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                    </div>
                </div>
                <!-- Tìm kiếm hàng hóa -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t('message.ReportValueBranch.product') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('productBody', 'productDown', 'productUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="productDown"></i>
                                    <i class="fa fa-chevron-up" id="productUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="productBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.ReportValueBranch.placeholder.product')" :modelValue="filterQuery.productId" :defaultOption="this.products" :onSearch="true" @onSearch="onSearchProducts" @update:modelValue="doFilterSearch" :label="'productId'"/>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Tìm kiếm nhóm hàng hóa -->
                <div class="card mt-4" id="GrpProduct">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-8">
                                <label class="typeTitle">{{ $t('message.ReportValueBranch.category') }}</label>
                            </div>
                            <div class="col-1 pl-0 ml-0">
                            </div>
                            <div class="col-2">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('productGroup','productGroupDown','productGroupUp')" >
                                    <i class="fa fa-chevron-down dp-hide" id="productGroupDown"></i>
                                    <i class="fa fa-chevron-up" id="productGroupUp"></i>
                                </a>
                            </div>
                        </div>
                        <!-- Tìm kiếm -->
                        <div class="row mx-2 my-2 py-2 ">
                            <div class="col-12">
                                <div class="prd-search-box">
                                    <i class="fa fa-search mr-2 text-secondary"></i>
                                    <input type="text" class="filter-search-input w-100" v-model="inputFilterCategories" @input="onInputFilterCategories" :placeholder="$t('message.ReportValueBranch.placeholder.category')" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-0" id="productGroup">
                        <!-- Danh sách -->
                        <div class="row my-2">
                            <template v-if="this.displayCategories?.length >=1">
                                <div class="col-12 py-2 bg-white">
                                    <span :class="{'font-weight-bold': filterCategories?.length == 0}" @click="handleCheckCategories(null)" >{{$t('message.common.all')}}</span>
                                </div>
                                <div class="col-12 py-2 bg-white" v-for="category in displayCategories" :key="category.id">
                                    <div class="d-flex flex-row justify-content-lg-between">
                                        <div >
                                            <i :class="{
                                                    'fa':true,
                                                    'fa-plus': !choosedParentCategories.includes(category.id),
                                                    'px-1':true,
                                                    'fa-minus': choosedParentCategories.includes(category.id),
                                                }"
                                                v-if="checkHaveChildCategories(category)"
                                                @click="onChooseParentCategory(category?.id)"
                                            ></i>
                                            <span class="px-2 my-0" :for="`key${category.id}`" @click="handleCheckCategories(category.id)" :class="{'font-weight-bold': filterCategories.includes(category.id)}" >{{category.name}}</span>
                                        </div>
                                    </div>
                                    <div v-if="choosedParentCategories.includes(category.id)" class="child-category-container">
                                        <div  v-for="child in category?.children" :key="child?.id" :ref="`${child?.id}`" :id="`key${category.id}`" >
                                            <div class="d-flex flex-row justify-content-lg-between">
                                                <div>
                                                    <i :class="{
                                                            'fa':true,
                                                            'fa-plus': !choosedParentCategories.includes(child?.id),
                                                            'px-1':true,
                                                            'fa-minus': choosedParentCategories.includes(child?.id),
                                                        }"
                                                        v-if="checkHaveChildCategories(child)"
                                                        @click="onChooseParentCategory(child?.id)"
                                                    ></i>
                                                    <span class="px-2 my-0" :for="`key${child?.id}`" :class="{'font-weight-bold': filterCategories.includes(child?.id)}" @click="handleCheckCategories(child?.id)">{{child?.name}}</span>
                                                </div>
                                            </div>
                                            <div v-if="choosedParentCategories.includes(child?.id)" class="child-category-container">
                                                <div v-for="child2 in child?.children" :key="child2.id">
                                                    <div class="d-flex flex-row justify-content-lg-between">
                                                        <p class="px-2 my-0" :for="`key${child2?.id}`" :class="{'font-weight-bold': filterCategories.includes(child2?.id)}" @click="handleCheckCategories(child2?.id)">{{child2?.name}}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>             
                <!-- Tìm kiếm chi nhánh -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t('message.ReportValueBranch.branch') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.ReportValueBranch.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branchId'"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="mainRight">
                <div class="sticky-top content-report" style="background: #B0BBC9;">
                    <div class="row m-0 p-0">
                        <div class="col-lg-12 p-2 text-white d-flex justify-content-center align-items-center">
                            <ul class="menubar-report">
                                <li>
                                    <i class="fas fa-sync-alt mx-4" @click="clearFilter()"></i>
                                </li>
                                <li>                                    
                                    <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" :hiddenTotalRecord="true" :buttonColor="'text-white'" :showLastPage="true"/>
                                </li>
                                 <li>
                                    <div class="dropdown">
                                        <button class="btn dropdown-toggle pl-2 text-white" type="button" id="dropdownDownload" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-cloud-download-alt"></i>
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownDownload">
                                            <a class="dropdown-item" href="javascript:;">
                                                {{$t('message.common.pdf')}}
                                            </a>
                                            <a class="dropdown-item" href="javascript:;" @click="exportReport()">
                                                {{$t('message.common.excel')}}
                                            </a>
                                        </div>
                                    </div>
                                    
                                </li>
                                <li>
                                    <i class="fa fa-print icon-print mx-4"></i>
                                </li>
                               <li @click="zoom(1)">
                                    <i class="fa fa-search-plus icon-zoom-in mx-4"></i>
                                </li>
                                <li @click="zoom(-1)">
                                    <i class="fa fa-search-minus icon-zoom-out mx-4"></i>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="row m-0 py-0 px-3 content-report-table" >
                        <div class="col-lg-12 bg-white p-3 m-0" id="panzoom-element">
                            <div>{{ $t('message.ReportValueBranch.created_date') }}: <span v-text="this.createdDate"></span></div>
                             <h3 class="w-100 text-center text-bold mt-2 reportTitle" v-text="this.titleHeader + ' ' + this.contentHeader"> </h3>  
                            <div class="w-100 text-center font-italic text-gray">{{ $t('message.ReportValueBranch.from_date') }} <span v-text="this.startTime"></span> {{ $t('message.ReportValueBranch.to_date') }} <span v-text="this.endTime"></span></div>
                            <div class="w-100 text-center"><span class="text-bold">{{ $t('message.ReportValueBranch.branch') }}: </span><span v-text="this.branchName"></span></div>                            
                            <div class="row mt-2">
                                <div class="col-12">
                                    <div class="row mx-0 my-2" id="divTable">                                        
                                        <table class="table table-index table-striped w-100 lstProduct">
                                            <thead>
                                                <tr class="text-center">
                                                    <template v-for="field in fields" :key="field.field">
                                                        <th scope="col" v-if="field.selected" style="vertical-align: middle !important">
                                                            <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                            :class="field.class" :name="field.name" :field="field.field" class="w-100 fit-column" :title="field.name"/>
                                                        </th>
                                                    </template>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-if="entries?.length > 0">
                                                    <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                        <tr class="master-row" :id="'row_' + (index + 1)" :class="index ? '' : 'bg-light-warning'">
                                                            <template v-for="field in fields" :key="field.field">                                                                
                                                                <td class="ow-break-word py-2" v-if="field.selected && field.field " style="vertical-align:middle !important;">
                                                                    <div v-text="renderField(entry, field.field, field) != 0 ? renderField(entry, field.field, field) : '-'" :title="renderField(entry, field.field, field)"
                                                                        class="w-100 ">
                                                                    </div>                                                                    
                                                                </td>
                                                            </template>
                                                        </tr>
                                                    </template>
                                                </template>
                                                <template v-if="entries?.length == 0 && !this.loading">
                                                    <tr>
                                                        <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                            <div class="row detail-prd-row mx-0">
                                                                <div class="col-12 text-center text-secondary mt-5">
                                                                    <h3>
                                                                        <i class="fa fa-file mr-2 text-secondary"></i>
                                                                        <i class="fa fa-exclamation"></i>
                                                                    </h3>
                                                                </div>
                                                                <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                    <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { $alert, $get, $post, clone, removeVietnameseTones, setArray} from "@/lib/utils";
    import store from "../../store";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import Pagination from "@/components/PaginationCustom";
    import TableSortField from "@/components/TableSortField";
    import http from '@/services/api';
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import moment from 'moment';
    import Panzoom from '@panzoom/panzoom';    
    import exportMixin from '@/exportMixin';
    export default {
        name: "ReportValueBranchndex",
        mixins: [exportMixin],
        components: {
            SelectDateTime,
            SelectSearch,
            TableSortField,
            Pagination,
            InputQuickSearch
        },
        mounted () {
            document.title = this.$t('message.ReportValueBranch.title_document');            
            this.clearFilter();
            this.getDataCategories();
            this.panzoom = Panzoom(document.getElementById('panzoom-element'), {
                maxScale: 5
            })
        },
        data () {
            const globalConfig = store.state.GlobalConfig;
            return {
                panzoom: '',
                filter: {
                    keyword: '',
                    saleChannelId: '',
                    branchId: '',
                    productId: ''                  
                },
                filterQuery: {
                    saleChannelId: '',
                    branchId: '',
                    productId: '',                    
                },
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                users: globalConfig.users,
                user: store.state.Auth ? store.state.Auth.user : {},
                fields: [],
                entries: [],
                arrayChecked: [],
                listStatus: [],
                listType: [],
                listTypeTicket: [],
                listObjectPaymentReceipt: [],
                objects: [],
                loading: false,
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                },
                isFilter: false,
                toLink: null,
                inputFilterCategories: '',
                categories:[],
                displayCategories: [],
                choosedParentCategories: [],
                filterCategories: [],
                products: [],
                customers: [],
                customerGroups: globalConfig.cusGroup, 
                saleChannels: globalConfig.saleChannels, 
                employeeName:'',                
                branchName: '', 
                priceList: '', 
                startTime: '', 
                endTime: '', 
                titleHeader: '', 
                contentHeader: '', 
                createdDate: moment().format('DD/MM/YYYY H:i'), 
            }
        },
        methods: {    
            zoom(level){
                level === -1 ? this.panzoom.zoomOut() : this.panzoom.zoomIn()
            },        
            async clearFilter() {
                await this.$router.replace({
                    path: '/report-value-branch/index',
                    query: {}
                });
    
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    this.filterQuery[key] = '';
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("resetSelectDateTime", 'month');
                this.arrayChecked = [];
                this.numberRecord = 50;
                this.filterCategories = [];
                this.inputFilterCategories = '';
                this.isFilter = false;
                this.toLink = null;
                this.branchName = '';
                this.priceList = '';
                this.startTime = '';
                this.endTime = '';
                this.titleHeader = '';
                this.contentHeader = '';
                this.createdDate = moment().format('DD/MM/YYYY HH:mm');
                this.filterQuery.productId = '';
                // Do hàm này gọi đến hàm doFilterTime nên call 2 lần load()
                // await this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/reports/get-data-report-value-branch', {...this.filterQuery, record: this.numberRecord});               
                
                
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }
                this.entries = res.result.data.data.data;     
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                this.branchName = res.result.data.branchName;
                this.employeeName = res.result.data.employeeName;
                this.totalEmployee = res.result.data.totalEmployee;
                this.priceList = res.result.data.priceList;
                this.startTime = res.result.data.startTime;
                this.endTime = res.result.data.endTime;
                this.titleHeader = res.result.data.titleHeader;
                this.contentHeader = res.result.data.contentHeader;
                this.fields = res.result.data.fields;
                emitter.emit("offLoading");
                this.loading = false;
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else if(field == 'type'){
                    if(query['type'].includes(value)){
                        query['type'] = query['type'].filter(item => item !== value)
                    }else{
                        query['type'].push(value)
                    }
                }else if(field == 'typeTicket'){
                    if(query['typeTicket'].includes(value)){
                        query['typeTicket'] = query['typeTicket'].filter(item => item !== value)
                    }else{
                        query['typeTicket'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });
    
                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            async getDataCategories(){
                const cRes = await $get('/v1/categories/getTreeCategories');
                this.categories = cRes.result.tree;
                this.displayCategories = cRes.result.tree;
            },
            onInputFilterCategories(){
                const categories = this.categories;
                if(this.inputFilterCategories ==''){
                    this.displayCategories = categories;
                    this.choosedParentCategories = [];
                    return
                }
                let arr = []
                let idList = []
                categories.forEach(item => {
                    if(item.children && item.children?.length){
                        let newItem
                        let dataSearch = [];
                        item.children.forEach(child => {
                            let newChild
                            if(child.children && child.children?.length){
                                let newChildChildren = child.children.filter(e => removeVietnameseTones(e.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())))
                                
                                if(newChildChildren?.length && !(arr.includes(item))){
                                    idList.push(child?.id);
                                    newChild = [{...child,children: newChildChildren}];
                                    newItem = {...item,children: newChild};
                                }else if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                    arr.push(item)
                                }
                                if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                    dataSearch.push(child)
                                    newItem = {...item, children: dataSearch}
                                    idList.push(item.id)
                                    arr.push(newItem)
                                }
                            }else{
                                if(removeVietnameseTones(item.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                    arr.push(item)
                                }
                                if(removeVietnameseTones(child.name.toLowerCase()).includes(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) && !(arr.includes(item))){
                                    dataSearch.push(child)
                                    newItem = {...item, children: dataSearch}
                                    idList.push(item.id)
                                    arr.push(newItem)
                                }
                            }
                        })
                        if(newItem && !(arr.includes(newItem))){
                            idList.push(newItem.id)
                            arr.push(newItem)
                        }
                        
                    }else if(removeVietnameseTones(item.name.toLowerCase()).indexOf(removeVietnameseTones(this.inputFilterCategories.toLowerCase())) > -1 && !(arr.includes(item))){
                        arr.push(item)
                    }
                })
                
                this.choosedParentCategories = [...idList]
                this.displayCategories = [...arr]
            },
            onChooseParentCategory(id){
                let arr = this.choosedParentCategories;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id);
                }else{
                    arr.push(id);
                }
                this.choosedParentCategories = [...arr];
            },
            handleCheckCategories(value){
                let arr = this.filterCategories
                arr = (arr.includes(value) || !value) ? [] : [value]
                this.filterCategories = arr
                this.handleFilter('categories',arr);
            },
            handleFilter(key, value){
                let query = {...this.filterQuery,page:'1'};
                query[key] = JSON.stringify(...value);
                this.filterQuery = query;
                this.load();
            },
            checkHaveChildCategories(data){
                if(data?.children) return true
                return false
            },
            async onSearchProducts(keyword){
                const res = await $post('/v1/products/search-product', {keyword: keyword});               
                this.products = res.result;
            },
            async onSearchCustomers(keyword){
                const res = await $post('/v1/customers/search-customer', {keyword: keyword});               
                this.customers = res.result;
            },
            async exportReport() {    
                // emitter.emit("onLoading");               
                // this.filterQuery.export = true;
                // await this.load();                                              
                // const res = await http.download('/v1/reports/export-data-report-value-branch', {...this.filterQuery});          
                // this.returnDataExport(res);
                // this.filterQuery.export = false; 

                emitter.emit("onLoading");
                const res = await http.download('/v1/reports/export-data-report-value-branch', {...this.filterQuery});               
                const fileName = res.headers['content-disposition'].split('filename=')[1];
                var _blob = new Blob([res.data], {
                    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                });
                var link = document.createElement("a");
                link.href = window.URL.createObjectURL(_blob);
                link.setAttribute('download', fileName);
                link.click();       
                emitter.emit("offLoading");     
            },          
        },
    }
</script>
<style scoped lang="scss">
.content-report{
    min-height: calc(125vh - 190px) !important; 
}
#number_records {
    margin-top: 11px;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.text-title {
    line-height: 52px;
}

#typeCard {
    margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.table{
    overflow: auto !important;   
}

#productGroup {
    max-height: 350px !important;
    overflow-y: scroll;
}
.child-category-container {
    margin-left: 1rem
}

.child-category-item {
    display:flex;
    justify-content: space-between;
    margin-top: .5rem
}
.input-number-paginate{
    width: 60px !important;
    border: none !important;
    outline: none !important;
    &:active, &hover, &focus{
        border: none !important;
        outline: none !important;
    }
}
.menubar-report{
    display: flex;
    margin: auto;
    align-items: center;
}
.menubar-report li{
    padding: 5px !important;
}
.description1{
    width: 45px;      
    text-align: center;  
}
    .description2{
    width: 160px;
}
    .maxWidth {        
    max-width: max-content !important;
    
}
.contentStyle {
    max-width:98% !important; 
    max-height:92% !important
}
.notDataStyle {
    width: 300px !important;
    text-align: center;
}
tbody {
    display: block;
    // max-height: 600px;
    overflow: hidden !important;
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
</style>