<template>
  <div class="col-12 py-2" style="text-align: end;">
            <label style="font-size: 13px; color: #666666; padding-right: 5px; "> {{ $t('message.common.previewPrintTemplate') }} </label>
  </div>  
  <View ref="View" :entry="this.entry" :action="this.action" :data="this.content" :editorDataClone="this.editorDataClone" />        
</template>
<script>
import {$get, $post} from "@/lib/utils";
import View from './View';

export default {
name: "ViewTemplates",
props: ['entry', 'action', 'isShow', 'contenOrigin'],
components:{
     View,
},
created() {
    this.load();
    
},
  data() { 
        return {          
            content: null,
            printTemplate: [],
            printTemplateId: null,           
            printPaperId:2,   
            dataValue: null,  
            editorDataClone:null
        }
    },
  mounted() {    
  },
  watch: {
    contenOrigin(newData) {
      if (!this.dataValue) return;
      this.editorDataClone = newData;
      let image = '';
      let str = '';      
      let barcode = '';
      this.dataValue.forEach(data => {
                const image =  '<img src="' + data.value + '" style=width:200px;>';    
                switch (data.key) {
                    case '{Logo_Cua_Hang}':
                        this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
                        break;
                    case '{Sort_Code}':                                
                        barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=`+ data.value +`&amp;'
                            alt="" title=`+ data.value +` width="80" height="80" />`
                        this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                        break;
                    case '{Ma_Van_Don_Ma_Vach}':                 
                        barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                        alt="" title=`+ data.value +` width="150"  height="100" />`
                    this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                    break;
                    // eslint-disable-next-line no-duplicate-case
                    case '{Ma_Vach_Khach_Hang}':   
                        if(data?.value && data.value != null){
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                            }
                        break;
                    case '{Ma_Vach_Hoa_Don}':   
                            if(data?.value && data.value != null){
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                            }
                    break;    
              default:
                  this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
                  break;
          }        
      });
    },
  },
  methods: {
        async renderData(){
              const resValues = await $post('/v1/print-templates/get-value-print-template');
                if(resValues.result){
                    this.dataValue = resValues.result;
                }
                this.editorDataClone = this.content;
                let barcode = '';
                this.dataValue.forEach(data => {
                    const image =  '<img src="' + data.value + '" style=width:200px;>';
                          switch (data.key) {
                        case '{Logo_Cua_Hang}':
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
                            break;
                        case '{Sort_Code}':                                
                            barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=`+ data.value +`&amp;'
                                alt="" title=`+ data.value +` width="80" height="80" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Van_Don_Ma_Vach}':                 
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                            // eslint-disable-next-line no-duplicate-case
                        case '{Ma_Vach_Khach_Hang}':   
                            if(data?.value && data.value != null){
                                    barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                    alt="" title=`+ data.value +` width="150"  height="100" />`
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                                }
                                else {
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                                }
                            break;
                        case '{Ma_Vach_Hoa_Don}':   
                                if(data?.value && data.value != null){
                                    barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                    alt="" title=`+ data.value +` width="150"  height="100" />`
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                                }
                                else {
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                                }
                                break;    
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
                            break;
                    }        
                });
                localStorage.setItem('editorDataClone', this.editorDataClone);
        },
        async load() {                
            const res = await $get(`/v1/print-templates/get-content-print-template`,{action: this.action, printPaperId: this.printPaperId });
            
            if(res && res.status.code == 200){
              if(res.result) {                    
                  this.content = res.result.content; 
              }
            }
            this.renderData();
         },
  },
};
</script>
