<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4">
                        {{ this.entry && this.entry.type == this.$constant.ticket_receipt_cash_book ? $t('message.CashBookIndex.modal.showDetail.title_receipt') : $t('message.CashBookIndex.modal.showDetail.title_payment') }}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">
                                            {{ this.entry && this.entry.type == this.$constant.ticket_receipt_cash_book ? $t('message.CashBookIndex.modal.showDetail.code_receipt') : $t('message.CashBookIndex.modal.showDetail.code_payment') }}:
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="entry.code"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.filter.time') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.created_at)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.defaultValues && this.defaultValues.supplier">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.supplier') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary cursor-pointer" @click="linkToSupplier(this.defaultValues.supplier.code)" v-text="this.defaultValues.supplier.name"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" v-if="this.defaultValues && this.defaultValues.customer">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.customer') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary cursor-pointer" @click="linkToCustomer(this.defaultValues.customer.code)" v-text="this.defaultValues.customer.name"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.created_by') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.created_by ? entry.created_by.name : null"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.user') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="entry.user ? entry.user.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{ $t('message.CashBookIndex.method') }}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{ entry.method == 1 ? $t('message.BankBookIndex.method.value.card') : $t('message.BankBookIndex.method.value.banking') }}</div>
                                        </div>
                                    </div>

                                    <div class="row m-3">
                                        <label class="container-checkbox">{{ $t('message.modalBankBook.planning') }}
                                            <input type="checkbox" v-model="entry.statusPlanning" class="chkType">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>

                                </div>
                                <div class="col-4 border-left">  
                                    <div class="row p-0 font-italic mb-4">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ entry.note ? entry.note : $t('message.CashBookIndex.note')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mx-4 pb-2 mb-4" v-if="this.defaultValues">
                                <div class="row detail-prd-row mt-3 m-0 w-100">
                                    <div class="col-12 p-0">
                                        <table class="w-100">
                                            <thead>
                                                <tr class="backgroup__detail table-header border-1">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                        {{ this.entry.invoice ? $t('message.CashBookIndex.modal.showDetail.table.invoice_code') 
                                                            : this.entry.import_for_supplier ? $t('message.CashBookIndex.modal.showDetail.table.purchase_code')
                                                            : this.entry.order ? $t('message.CashBookIndex.modal.showDetail.table.order_code') 
                                                            : this.entry.order_for_supplier ? $t('message.CashBookIndex.modal.showDetail.table.order_supplier_code') 
                                                            : this.entry.return ? $t('message.CashBookIndex.modal.showDetail.table.return_code') 
                                                            : this.entry.return_import_for_supplier ? $t('message.CashBookIndex.modal.showDetail.table.return_purchase_code') 
                                                            : null
                                                        }}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{ $t('message.CashBookIndex.modal.showDetail.table.time') }}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{ $t('message.CashBookIndex.modal.showDetail.table.value') }}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">
                                                        {{ this.entry && this.entry.type == this.$constant.ticket_receipt_cash_book ? $t('message.CashBookIndex.modal.showDetail.table.receipted') : $t('message.CashBookIndex.modal.showDetail.table.paymented') }}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{ $t('message.CashBookIndex.modal.showDetail.table.amount_receipt_payment') }}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2">
                                                        {{ $t('message.CashBookIndex.modal.showDetail.table.status') }}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="table-header border" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2 text-primary cursor-pointer" v-text="this.defaultValues.code" @click="linkToDocumentSrc"></th>
                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="dt(this.defaultValues.created_at)"></th>
                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal"
                                                     v-text="this.defaultValues.totalPrice ? n(this.defaultValues.totalPrice) : n(this.defaultValues.totalOrigin)">
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal"
                                                     v-text="this.defaultValues.totalPayment ? n(this.defaultValues.totalPayment) : n(this.defaultValues.supplierPayment)">
                                                     </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(entry.amount)"></th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="this.defaultValues.statusName"></th>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <div class="no-data border border-top-0" v-if="this.defaultValues.length == 0">
                                            <span>{{ $t('message.common.notFound') }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-0 pb-4 mt-0">
                                <div class="col-6"></div>
                                <div class="col-6">
                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1">
                                            <i class="fa fa-check mr-2"></i>{{ $t('message.CashBookIndex.button.update') }}
                                        </button>
                                        <button class="btn btn-white border-1 mx-1" type="button" @click="print(this.entry)" >
                                            <i class="fa fa-print px-2"></i>{{ $t('message.CashBookIndex.button.print') }}
                                        </button>
                                        <button class="btn btn-danger mx-1" type="button" @click.stop="cancel(this.entry.id, this.entry.code)" v-if="entry.status != this.$constant.BANK_STATUS_CANCEL">
                                            <i class="fa fa-trash-alt px-2"></i>{{ $t('message.CashBookIndex.button.cancel') }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     <ModalBankCancel ref="ModalBankCancel" @loadData="loadData"/>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post, removeVietnameseTones} from "@/lib/utils";
import permissionMixin from '@/permissionMixin';
import store from "@/store";
import ModalBankCancel from './ModalBankCancel.vue';
export default {
    mixins: [permissionMixin],
    name: "ModalDetailBankBook",
    components: {Pagination, ModalBankCancel},
    data () {
        return {
            entry: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            stockCardType: null,
            defaultValues: null,
            userId: store.state.Auth.user.id,
        }   
    },
    methods: {
        print(entry){
            this.$emit('print', entry);
            // this.hide();
        },
        async cancel(id, code){
               this.$refs.ModalBankCancel.show(id, code);
        },
        loadData(){
            this.$emit('updated');
            this.hide();
        },
        async show (entry) {
            this.clearData();
            if(entry){
                const res = await $post('/v1/bank-book/show', {id: entry.id});
                if(res && res.result){
                    if(res.result.status && res.result.data != []){
                        this.entry = res.result.data;
                        if(this.entry){
                            if(this.entry.invoice){
                                this.defaultValues = this.entry.invoice;
                            }
                            if(this.entry.import_for_supplier){
                                this.defaultValues = this.entry.import_for_supplier;
                            }
                            if(this.entry.order){
                                this.defaultValues = this.entry.order;
                            }
                            if(this.entry.order_for_supplier){
                                this.defaultValues = this.entry.order_for_supplier;
                            }
                            if(this.entry.return){
                                this.defaultValues = this.entry.return;
                            }
                            if(this.entry.return_import_for_supplier){
                                this.defaultValues = this.entry.return_import_for_supplier;
                            }
                        }
                        window.jQuery(this.$refs.modal).modal('show');
                    }
                }
            }
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        clearData(){
            this.entry = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.stockCardType = null;
            this.defaultValues = null;
        },
        linkToSupplier(code){
            var route = null;
            route = this.$router.resolve({
                name: 'SupplierIndex',
                query: {
                    'keyword': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToCustomer(code){
            var route = null;
            route = this.$router.resolve({
                name: 'CustomerIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToInvoice(code){
            var route = null;
            route = this.$router.resolve({
                name: 'InvoiceIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToPurchaseOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'PurchaseOrderIndex',
                query: {
                    'purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'OrderIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToOrderForSupplier(code){
            var route = null;
            route = this.$router.resolve({
                name: 'OrderSupplierIndex',
                query: {
                    'order_supplier': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToReturn(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToReturnPurchaseOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnPurchaseOrder',
                query: {
                    'return_purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToDocumentSrc(){
            if(this.entry.invoice){
                this.linkToInvoice(this.entry.invoice.code);
                return;
            }else if(this.entry.import_for_supplier){
                this.linkToPurchaseOrder(this.entry.import_for_supplier.code);
                return;
            }else if(this.entry.order){
                this.linkToOrder(this.entry.order.code);
                return;
            }else if(this.entry.order_for_supplier){
                this.linkToOrderForSupplier(this.entry.order_for_supplier.code);
                return;
            }else if(this.entry.return){
                this.linkToReturn(this.entry.return.code);
                return;
            }else if(this.entry.return_import_for_supplier){
                this.linkToReturnPurchaseOrder(this.entry.return_import_for_supplier.code);
                return;
            }
        },
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
</style>