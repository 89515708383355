<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-xs" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.modalCashCancel.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h6 style="line-height:30px;">{{ $t('message.modalCashCancel.content', {code:this.code}) }}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click="cancel()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.modalCashCancel.btnSubmit') }}
                            </button>
                            <AppPermission :permission="this.$permissions.cashbooks.cancel?? '' ">
                                <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="hide()"><i class="fa fa-ban mr-1"></i> {{ $t('message.modalCashCancel.btnAbort') }}</button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalCashCancel",
    components: {AppPermission},
    data(){
        return {
            id:null,
            code:null,
            type: null,
            entry: null,
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(entry) {
            if(entry != null){
                this.id = entry.id;
                this.code = entry.code;
                this.entry = {...entry};
                this.type = entry.type;
                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        getMessage(status){            
            let msg = '';
            switch (this.type) {
                case this.$constant.type_receipts_cash_book:
                    if(status == this.$constant.statusSuccess) msg = 'message.modalCashCancel.messageReceiveSuccess';
                    if(status != this.$constant.statusSuccess) msg = 'message.modalCashCancel.messageReceiveError';
                    break;
                case this.$constant.type_payment_cash_book:
                    if(status == this.$constant.statusSuccess) msg = 'message.modalCashCancel.messagePaymentSuccess';
                    if(status != this.$constant.statusSuccess) msg = 'message.modalCashCancel.messagePaymentError';                    
                    break;      
            }
            return msg;
        },
        async cancel(){ 
            const res = await $post('/v1/cash-book/cancel', {...this.entry});                    
            if(res.status.code == 200 && res.result){
                toastr.success(this.$t(this.getMessage(res.status.code), {code: this.code}));
                this.$emit('loadData',res.result);
                this.hide();      

            }else{                
                toastr.error(this.$t(this.getMessage(res.status.code), {code: this.code}));
                this.$emit('loadData',res.result);
                this.hide();
            }
            this.$emit('loadData');
            this.hide();
        },
        hide(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>

