<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 960px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t('message.common.customerPays') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12">
                        <table class="table table-index table-striped">
                            <thead>
                            <tr>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                <th scope="col" class="text-center">{{$t('message.DetailOrdersCard.dvt')}}</th>
                                <th scope="col" style="width: 15%" class="text-center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in data" :key="index">
                                <tr style="font-weight: bold">
                                    <td v-text="product.productCode"></td>
                                    <td class="product-name">
                                        <a v-text="product.productName" :title="product.productName" target="_blank"
                                           :href="'/products/form?id=' + product.productId"></a>
                                    </td>
                                    <td>
                                        <select class="form-control" v-model="product.productUnitId" disabled>
                                            <option :value="unit.id" v-for="unit in product.productUnits" v-text="unit.unitName" :key="unit.id"></option>
                                        </select>
                                    </td>
                                    <td>
                                        <InputNumber v-model="product.quantity"
                                                     class="sale-input-number full"
                                        ></InputNumber>
                                    </td>
                                    <td>
                                        <a @click="removeItem(index)">
                                            <i class="fa fa-trash-alt"></i>
                                        </a>
                                    </td>
                                </tr>
                            </template>

                            </tbody>
                        </table>
                    </div>
                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            <i class="fa fa-ban"></i>&nbsp;
                            {{ $t('message.button.skip') }}
                        </button>
                        <button type="button" class="btn btn-primary" @click="save()">
                            <i class="fa fa-check-square"></i>&nbsp;
                            {{ $t('message.button.finished') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="">
        <ModalProductBarcode ref="productBarcode"></ModalProductBarcode>
    </div>
</template>

<script>
    import {cloneObject, forEach, parseIntEx} from "../../lib/utils";
    import InputNumber from "../../components/forms/InputNumber";
    import ModalProductBarcode from "../products/tabs/ModalProductBarcode";

    export default {
        name: "ModalPrintProductBarCode",
        components: {ModalProductBarcode, InputNumber},
        data() {
            return {
                data: {},
            }
        },
        methods: {
            show(entry) {
                this.data = cloneObject(entry);
                this.data.forEach((product) => {
                    product.quantity = Math.max(parseIntEx(product.receiveQuantity), 0);
                    product.code = product.productCode;
                    product.name = product.productName;
                    product.id = product.productId;
                });

                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            },
            save() {
                window.jQuery(this.$refs.modal).modal('hide');
                this.$refs.productBarcode.multiplePrint(this.data);
            },
            removeItem(index) {
                this.data.splice(index, 1);
            }
        }
    }
</script>

<style scoped>

</style>
