
<template>
    <AppPermission :permission="this.$permissions.settingPrice.data?? '' ">
        <div class="container my-4">
            <div class="row m-0">
                <div class="mainLeft">
                    <div class="main-title">
                        <span>{{ $t('message.PriceListsIndex.title') }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8 text-title">
                                    <label>{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select v-model="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PriceListsIndex.time') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>
                    <!-- Start created at -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t('message.PriceListsIndex.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.PriceListsIndex.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'branchId'"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PriceListsIndex.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-radio ml-0">{{$t(status.name)}}
                                        <input type="radio" name="sttStatus" v-model="this.filterQuery.status" :checked="this.filterQuery.status === status.id" :value="status.id" class="cheker mr-2" @change="doFilter('status', status.id)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.PriceListsIndex.effect') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('effectBody', 'effectDown', 'effectUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="effectDown"></i>
                                        <i class="fa fa-chevron-up" id="effectUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="effectBody">
                            <div class="row" v-for="effect in this.listEffect" :key="effect.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-radio ml-0">{{$t(effect.name)}}
                                        <input type="radio" name="sttEffect" v-model="this.filterQuery.effect" :checked="this.filterQuery.effect === effect.id" :value="effect.id" class="cheker mr-2" @change="doFilter('effect', effect.id)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mainRight">
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-5">
                                <div class="search-box">
                                    <i class="fa fa-search mr-2"></i>
                                    <input type="text" class="search-input"
                                        @keydown.enter="doFilter('keyword', filterQuery.keyword)"
                                        v-model.trim="filterQuery.keyword" :placeholder="$t('message.PriceListsIndex.placeholder.search')"/>
                                </div>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                                <!-- Xóa lọc -->
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <!-- Thêm mới -->
                                <AppPermission :permission="this.$permissions.settingPrice.create?? '' ">
                                    <button type="button" class="btn btn-save" @click="showModalCreatePriceList">
                                        <i class="fas fa-plus"></i>
                                        {{ $t('message.PriceListsIndex.button.create') }}
                                    </button>
                                </AppPermission>

                                <!-- File: Import, Export -->
                                 <AppPermission :permission="this.$constant.SettingPriceFilePermission">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton" style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-2"></i>
                                            {{ $t('message.PriceListsIndex.button.file') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <AppPermission :permission="this.$permissions.settingPrice.import?? '' ">
                                                <a class="dropdown-item" @click="importPriceLists">
                                                    <i class="fas fa-file-import px-2" ></i>
                                                    {{ $t('message.PriceListsIndex.button.import') }}
                                                </a>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.settingPrice.export?? '' ">
                                                <a class="dropdown-item" @click="selectOptionExport('exportData')">
                                                    <i class="fas fa-file-export px-2"></i>
                                                    {{ $t('message.PriceListsIndex.button.export') }}
                                                </a>
                                            </AppPermission>
                                        </div>
                                    </div>
                                </AppPermission>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="numberRecord" @change="pageChange" />
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped w-100">
                                        <thead>
                                            <tr>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;" :class="field.field == 'timeEffect' ? 'cell-time' : ''">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class=""/>
                                                    </th>
                                                </template>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries?.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                    <tr @click="showDetailTicket(entry.id)" class="master-row" :style="index % 2 ? 'background-color: white' : null" :id="'row_' + (index + 1)">
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0 py-1" :class="field.field == 'timeEffect' ? 'cell-time' : ''" v-if="field.selected && field.field " style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="text-dark tagA">
                                                                    <div v-if="field.field == 'timeEffect'" :style="field.style" :class="field.class" :title="(entry.startDate ? dt(entry.startDate) : '') + (entry.endDate ? ' - ' + dt(entry.endDate) : '')" class="description">
                                                                        <p class="p-0 m-0" v-text="(entry.startDate ? dt(entry.startDate) : '') + (entry.endDate ? ' - ' + dt(entry.endDate) : '')"></p>
                                                                    </div>
                                                                    <div v-else-if="field.field == 'name'" v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class" class="text-overflow-ellipsis"></div>
                                                                    <div v-else-if="field.field == 'areaApply'" v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class" class="text-overflow-ellipsis"></div>
                                                                    <div v-else-if="field.field == 'effect'" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class">
                                                                        <p v-text="entry.effect" class="p-0 m-0" :class="entry.expired ? 'text-danger' : ''"></p>
                                                                    </div>
                                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                        <td>
                                                            <AppPermission :permission="[this.$permissions.settingPrice.update ?? '']">
                                                                <button class="btn mr-2" @click.stop="update(entry)">
                                                                    <i class="fa fa-edit"></i>
                                                                </button>
                                                            </AppPermission>
                                                            <AppPermission :permission="[this.$permissions.settingPrice.delete ?? '']">
                                                                <button class="btn text-danger" @click.stop="remove(entry)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                                </button>
                                                            </AppPermission>
                                                        </td>
                                                    </tr>

                                                    <!-- DETAIL TICKET -->
                                                    <template v-if="entry.id == priceActiveId">
                                                        <tr :class="[ entry.id === priceActiveId ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                            <td :colspan="fields.length" class="pb-0">
                                                                <div class="row detail-prd-row">
                                                                    <!-- Thông tin -->
                                                                    <div class="col-2 mt-2" @click="showContent($constant.priceListTab.orders)">
                                                                        <span :class="[ headerActive === $constant.priceListTab.orders ? 'backgroup__detail-active' : '', 'head-detail']">                                                            
                                                                            {{$t('message.headers.orders')}}
                                                                        </span>
                                                                    </div>
                                                                    <!-- Địa chỉ nhận hàng -->
                                                                    <div class="col-2 mt-2" @click="showContent($constant.priceListTab.invoices)">
                                                                        <span :class="[ headerActive === $constant.priceListTab.invoices ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                            <!-- {{$t('message.customerIndex.tabs.address')}} -->
                                                                            {{$t('message.headers.sales')}}
                                                                        </span>
                                                                    </div>
                                                                    <!-- Lịch sử đặt hàng -->
                                                                    <div class="col-2 mt-2" @click="showContent($constant.priceListTab.returns)">
                                                                        <span :class="[ headerActive === $constant.priceListTab.returns ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                            <!-- {{$t('message.customerIndex.tabs.historyOrder')}} -->
                                                                            {{$t('message.headers.returns')}}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- DETAIL ORDER -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="this.headerActive == this.$constant.priceListTab.orders">
                                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                                <div class="mt-2 pb-4">                                                                
                                                                    <div class="detail-prd-row mt-2">
                                                                        <table class="w-100" style="max-width:100%;">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.code')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.time')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.seller')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.customer')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.sum')}}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="listOrders">
                                                                                    <tr class="table-header border-1" v-for="(order, index) in this.listOrders" :key="index">
                                                                                        <td class="text-primary" @click="linkTo(order.code, 'order')">{{ order.code }}</td>
                                                                                        <td>{{ dt(order.purchaseDate) }}</td>
                                                                                        <td>{{ order.sold_by ? order.sold_by.name : null }}</td>
                                                                                        <td>{{ order.customer ? order.customer.name : mull }}</td>
                                                                                        <td>{{ n(order.total) }}</td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination :value="paginateDetail" :record="paginateDetail.numberRecordDetail" @change="pageChangeDetail"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- DETAIL INVOICE -->
                                                        <tr class="detail-row bg-white border__body-detail" v-else-if="this.headerActive == this.$constant.priceListTab.invoices">
                                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                                <div class="mt-2 pb-4">                                                                
                                                                    <div class="detail-prd-row mt-2">
                                                                        <table class="w-100" style="max-width:100%;">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.code')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.time')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.seller')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.customer')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.revenue')}}                                                                                            
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="listInvoices">
                                                                                    <tr class="table-header border-1" v-for="(invoice, index) in this.listInvoices" :key="index">
                                                                                        <td class="text-primary" @click="linkTo(invoice.code , 'invoice')">{{ invoice.code }}</td>
                                                                                        <td>{{ dt(invoice.purchaseDate) }}</td>
                                                                                        <td>{{ invoice.sold_by ? invoice.sold_by.name : null }}</td>
                                                                                        <td>{{ invoice.customer ? invoice.customer.name : mull }}</td>
                                                                                        <td>{{ n(invoice.total) }}</td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination :value="paginateDetail" :record="paginateDetail.numberRecordDetail" @change="pageChangeDetail"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- DETAIL RETURN -->
                                                        <tr class="detail-row bg-white border__body-detail" v-else-if="this.headerActive == this.$constant.priceListTab.returns">
                                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                                <div class="mt-2 pb-4">                                                                
                                                                    <div class="detail-prd-row mt-2">
                                                                        <table class="w-100" style="max-width:100%;">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.code')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.time')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.seller')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.recipientPays')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.customer')}}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{$t('message.PriceListsIndex.details.returnedGuests')}}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="listReturns">
                                                                                    <tr class="table-header border-1" v-for="(returnItem, returnIndex) in listReturns" :key="returnIndex">
                                                                                        <td class="text-primary" @click="linkTo(returnItem.code , 'return')">{{ returnItem.code }}</td>
                                                                                        <td>{{ dt(returnItem.returnItemDate) }}</td>
                                                                                        <td>{{ returnItem.sold_by ? returnItem.sold_by.name : null }}</td>
                                                                                        <td>{{ returnItem.receiver ? returnItem.receiver.name : null }}</td>
                                                                                        <td>{{ returnItem.customer ? returnItem.customer.name : null }}</td>
                                                                                        <td>{{ n(returnItem.returnTotal) }}</td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination :value="paginateDetail" :record="paginateDetail.numberRecordDetail" @change="pageChangeDetail"/>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </template>
                                            <template v-if="entries?.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalCreateEditPriceList ref="ModalCreateEditPriceList" @updated="load"/>
        <ImportPriceLists ref="ImportPriceLists" @reload="load"/>
        <ModalConfirmDelete ref="ModalConfirmDelete" @updated="load"/>
        <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
    </AppPermission>
</template>
<script>
import { $get, $post, $alert, removeVietnameseTones, cloneObject, clone} from "@/lib/utils";
import TableSortField from "@/components/TableSortField";
import Pagination from "@/components/Pagination";
import emitter from "@/lib/emitter";
import SelectField from "../../components/SelectField";
import currencyMixin from "@/currencyMixin";
import http from '@/services/api';
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from '@/permissionMixin';
import checkPermissionMixin from '@/checkPermissionMixin';
import store from "@/store";
import SelectDateTime from "@/components/SelectDateTime.vue";
import SelectSearch from "@/components/SelectSearch.vue";
import ModalCreateEditPriceList from './ModalCreateEditPriceList.vue';
import ImportPriceLists from './ImportPriceLists.vue';
import ModalConfirmDelete from './ModalConfirmDelete.vue';
import exportMixin from '@/exportMixin';
import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
export default {
    name: "PriceListIndex",
    mixins: [currencyMixin, permissionMixin, checkPermissionMixin, exportMixin],
    components: {
        TableSortField,
        Pagination,
        SelectField,
        AppPermission,
        SelectDateTime,
        SelectSearch,
        ModalCreateEditPriceList,
        ImportPriceLists,
        ModalConfirmDelete,
        ModalSelectOptionExport
    },
    mounted() {
        document.title = this.$t('message.PriceListsIndex.document_title');
        this.clearFilter();
    },
    computed: {
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            fields: [],
            entries: [],
            isFilter: false,
            loading: false,
            checkedAll: false,
            arrayChecked: [],
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            numberRecord: 15,
            filterQuery: {
                keyword: '',
                status: [],
                effect: [],
                branchId: '',
            },
            branches: globalConfig.branches,
            listStatus: [
                { "id": 2, "name": "message.common.all" },
                { "id": 1, "name": "message.PriceListsIndex.modal.apply" },
                { "id": 0, "name": "message.PriceListsIndex.modal.notApply" }
            ],
            listEffect: [
                { "id": 2, "name": "message.common.all" },
                { "id": 1, "name": "message.common.effect" },
                { "id": 0, "name": "message.common.expire" }
            ],
            priceActiveId: null,
            headerActive: this.$constant.priceListTab.orders,
            listOrders: [],
            listInvoices: [],
            listReturns: [],
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
                numberRecordDetail: 15,
            },
            isCallApi: false,
        }
    },
    methods: {
        pageChangeDetail(page) {
            if (this.isCallApi) return;
            this.isCallApi = true;
            this.paginateDetail.currentPage = page;
            this.getListTicketsForPriceList();
            this.isCallApi = false;
        },
        showContent(tab) {
            this.headerActive = tab;
            this.getListTicketsForPriceList();
            this.$forceUpdate();
        },
        async getListTicketsForPriceList() {
            const res = await $post('/v1/price-lists/get-detail-ticket', {
                branchId: store.state.CurrentBranch.id,
                priceListId: this.priceActiveId,
                type: this.headerActive,
                page: this.paginateDetail.currentPage,
                numberRecount: this.paginateDetail.numberRecordDetail,
            });  
            if (res.status.code != 200 || !res.result) {
                $alert(res);
                return;
            }
            if (this.headerActive == this.$constant.priceListTab.orders) {
                this.listOrders = res.result.data;
            } else if (this.headerActive == this.$constant.priceListTab.invoices) {
                this.listInvoices = res.result.data;
            } else if (this.headerActive == this.$constant.priceListTab.returns) {
                this.listReturns = res.result.data;
            }
            this.paginateDetail.currentPage = res.result.current_page;
            this.paginateDetail.lastPage = res.result.last_page;
            this.paginateDetail.totalRecord = res.result.total;
        },

        async showDetailTicket(priceListId) {
            if (priceListId == this.priceActiveId) {
                this.clearShowTicketDetail();
                return;
            }
            this.priceActiveId = priceListId; 
            this.getListTicketsForPriceList(this.$constant.priceListTab.orders, priceListId);
        },
        clearShowTicketDetail() {
            this.listOrders = [];
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = 1;
            this.paginateDetail.totalRecord = 1;
            this.headerActive = this.$constant.priceListTab.orders,
            this.priceActiveId = null;
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/price-lists/index',
                query: {}
            });
            Object.keys(this.filterQuery).forEach(key => {
                if(key == 'status'){
                    this.filterQuery[key] = 2;
                }else if(key == 'effect'){
                    this.filterQuery[key] = 2;
                }else{
                    this.filterQuery[key] = '';
                }
            });
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("clearSelectDateTime");
            this.arrayChecked = [];
            this.numberRecord = 15;
            this.filterQuery.branchId = store.state.CurrentBranch.id;
            this.isFilter = false;
            await this.load();
        },

        linkTo(code , type){
            var route = null;
            if (type == 'order') {
                route = this.$router.resolve({
                    name: 'OrderIndex',
                    query: {
                        'code': code,
                    }
                });
            } else if (type == 'invoice') {
                route = this.$router.resolve({
                    name: 'InvoiceIndex',
                    query: {
                        'code': code,
                    }
                });
            } else {
                route = this.$router.resolve({
                    name: 'ReturnIndex',
                    query: {
                        'code': code,
                    }
                });
            }
            
            window.open(route.href, '_blank');
        },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post('/v1/price-lists/get-price-lists', {...this.filterQuery, record: this.numberRecord});               
            if (res.result.code !== 200) {
                $alert(res);
                return;
            }
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.data.fields;
            }
            this.entries = res.result.data.data.data;     
            this.paginate.totalRecord = res.result.data.data.total;
            this.paginate.currentPage = res.result.data.data.current_page;
            this.paginate.lastPage = res.result.data.data.last_page;

            // if (res.result.data.listStatus) {
            //     this.listStatus = res.result.data.listStatus;
            // }
            // if (res.result.data.listEffect) {
            //     this.listEffect = res.result.data.listEffect;
            // }
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        isCheckedAll(){
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.entries.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        onCheckedAll(value) {
            if(value == true){
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.filterQuery);
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        async pageChange(page) {
            const query = clone(this.filterQuery);
            query['page'] = page;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        async doFilter(field, value) {
            const query = clone(this.filterQuery);
            query[field] = value;
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        doFilterTime(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        showModalCreatePriceList() {
            this.$refs.ModalCreateEditPriceList.show()
        },
        selectOptionExport(method = 'exportData', totalRecord = 0){
            let params = { method: method };
            totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
            this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
        },
        async exportFiles(params){
            if (params.method) {
                this[params.method](params.firstRecordIndex);
            }
        },
        async exportData(firstRecordIndex = 0) {           
            emitter.emit("onLoading");
            const res = await http.download('/v1/price-lists/exports', {
                ...this.filterQuery, 
                record: this.numberRecord, 
                fields: this.fields,
                firstRecordIndex: firstRecordIndex
            });          
            this.returnDataExport(res);
        }, 
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        importPriceLists(){
            this.$refs.ImportPriceLists.show()
        },
        update(entry){
            this.$refs.ModalCreateEditPriceList.show(entry);
        },
        remove(entry){
            this.$refs.ModalConfirmDelete.show(entry.id, entry.name);
        }
    },
    created () {},
    watch: {}
}
</script>

<style scoped lang="scss">
    @import "../../assets/css/vars.scss";

    #number_records {
        margin-top: 11px;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .text-title {
        line-height: 52px;
    }

    #typeCard {
        margin-top: -8px;
    }
    #noteRO {
        border-left: 1px solid #ccc;
    }
    .noteStyle {
        width: 100% !important;
        height: 120px !important;
        border: 0;
        border-radius: 0;
    }
    .noteStyle:focus {
        outline: none !important;
        border-color: none;
        box-shadow: 0 none;
    }
    #fa_edit {
        position: absolute;
        left:20px;
        top:4px;
        color:#a7a2a2;
    }
    tbody {
        // display: block;
        // max-height: 600px;
        // overflow: auto;
    }
    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }
    .cell-time{
        width: 250px !important;
    }
</style>
