<template>
    <div :class="wrapperClass ? wrapperClass : 'form-group'">
        <label v-if="label" v-text="label"></label>
        <div :class="noFlex ? '': 'd-flex'">
            <div style="flex: 1">
                <input ref="input" v-model="model" @input="onInput(true)"
                       :class="errors && errors.length ? 'error-input': ''"
                       class="form-control" :placeholder="placeholder || label">
            </div>
            <div
                class="d-flex"

                 :class="noFlex ? 'mt-1 justify-content-center': ''">
                <div >
                    <button class="btn-discount-type" type="button"
                            @click.prevent="onDiscountTypeChange(DISCOUNT_TYPE_VND)"
                            :class="{'btn-discount-type--active':  discountType === DISCOUNT_TYPE_VND}">{{ $t('message.common.vnd') }}</button>
                </div>
                <div  >
                    <button class="btn-discount-type"
                            @click.prevent="onDiscountTypeChange(DISCOUNT_TYPE_PERCENT)"
                            type="button"  :class="{'btn-discount-type--active':  discountType === DISCOUNT_TYPE_PERCENT}">{{$t('message.common.percent')}}</button>
                </div>

            </div>
        </div>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import {numberFormat, parseIntEx} from "../../lib/utils";
import {DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_VND} from "../../lib/utils";

export default {
    name: "InputDiscount",
    props: ['label', 'placeholder', 'modelValue', 'errors', 'wrapperClass', 'noFlex'],
    data() {
        let discountType = DISCOUNT_TYPE_VND;
        let model = 0;

        if (this.modelValue) {
            discountType = parseInt(this.modelValue.discountType) || DISCOUNT_TYPE_VND;
            model = numberFormat(this.modelValue.discountValue);
        }

        let maxValue = 1000000000;
        if (discountType === DISCOUNT_TYPE_PERCENT) {
            maxValue = 100;
        }

        return {
            DISCOUNT_TYPE_PERCENT,
            DISCOUNT_TYPE_VND,
            model,
            minValue: 0,
            maxValue,
            discountType: discountType
        }
    },
    methods: {
        onDiscountTypeChange(discountType) {
            discountType = parseInt(discountType);

            this.discountType = discountType;
            if (discountType === DISCOUNT_TYPE_PERCENT) {
                this.maxValue = 100;
                this.model = 0;
            } else {
                this.maxValue = 1000000000;
            }

            this.$emit('update:modelValue', {
                discountValue: 0,
                discountType
            });
        },
        onInput() {
            let $input = this.$refs.input;
            let start = $input.selectionStart + 1,
                end = $input.selectionEnd + 1;

            let value = parseIntEx(this.model);

            if (value <= 0) {
                value = 0;
            }

            if (value > this.maxValue) {
                value = this.maxValue;
            }

            this.model = numberFormat(value);

            this.$emit('update:modelValue', {
                discountValue: value,
                discountType: parseInt(this.discountType)
            });

            this.$nextTick(() => {
                $input.setSelectionRange(start, end)
            });

        }
    }
}
</script>

