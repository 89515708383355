<template>
    <div v-if="isDataLoaded">
        <ActionBar back-url="/deliveries/index" @action="save()"/>
        <div class="content">
            <div class="row">
                <div class="col-lg-4">
                    <h4>Thông tin người nhận</h4>

                    <InputText v-model="entry.receiverName"
                               :errors="errors.receiverName"
                               :label="$t('message.InvoiceIndex.tabs.delivery.receive_by')"
                    ></InputText>

                    <InputText v-model="entry.receiverPhone"
                               :errors="errors.receiverPhone"
                               :label="$t('message.InvoiceIndex.tabs.delivery.contact_number')"
                    ></InputText>

                    <InputText v-model="entry.receiverAddress"
                               :errors="errors.receiverAddress"
                               :label="$t('message.InvoiceIndex.tabs.delivery.address')"
                    ></InputText>

                    <InputLocationPicker v-model="location"></InputLocationPicker>
                </div>
                <div class="col-lg-4">
                    <h4>Thông tin hóa đơn</h4>

                    <div class="form-group">
                        <label>{{$t('message.InputQuickSearch.title.code_invoice')}}</label>
                        <div v-text="entry.invoice ? entry.invoice.code : ''" class="form-control" readonly></div>
                    </div>

                    <div class="form-group">
                        <label>{{$t('message.common.branch')}}</label>
                        <div class="form-control" v-text="entry.branch ? entry.branch.name : ''" readonly></div>
                    </div>

                    <div class="form-group">
                        <label>{{$t('message.headers.customers')}}</label>
                        <div class="form-control" readonly
                             v-text="(entry.invoice && entry.invoice.customer) ? entry.invoice.customer.name : ''"></div>
                    </div>

                    <div class="form-group">
                        <label>Số lượng hàng</label>
                        <div class="form-control" v-text="entry.invoice ? entry.invoice.invoice_items_count : ''" readonly></div>
                    </div>

                    <div class="form-group">
                        <label>Giá trị đơn hàng</label>
                        <div class="form-control" v-text="entry.invoice ? n(entry.invoice.total) : ''" readonly></div>
                    </div>

                </div>
                <div class="col-lg-4" style="border-left: 1px solid">
                    <InputTextArea v-model="entry.note"
                                   :errors="errors.note"
                                   height="378px"
                                   style="padding-top: 2.25rem"
                                   :label="$t('message.ProductIndex.note')"/>
                </div>
            </div>

            <div class="row mt-3" style="border: 1px solid #b1ddf0">
                <h3 class="col-lg-12 p-2" style="background: #b1ddf0">Thông tin vận chuyển</h3>
                <div class="col-lg-4">
                    <InputText v-model="entry.code"
                               :label="$t('message.orderFromTab.delivery_code')"
                               :errors="errors.code"
                    ></InputText>
                    <InputSelect v-model="entry.status"
                                 label="Trạng thái giao"
                                 :options="listStatus"
                                 :errors="errors.status"
                    ></InputSelect>
                    <div class="form-group">
                        <label>Thời gian tạo</label>
                        <div class="form-control" v-text="d(entry.createdAt)" readonly></div>
                    </div>
                    <div class="form-group">
                        <label>{{$t('message.common.created_by')}}</label>
                        <div class="form-control" v-text="entry.userCreated ? entry.userCreated.name : ''" readonly></div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="form-group mb-0">
                        <label>{{$t('message.InvoiceIndex.tabs.delivery.weight')}}</label>
                        <div class="row">
                            <div class="col-sm-6">
                                <InputText v-model="entry.weight"
                                           :errors="errors.weight"
                                           :placeholder="$t('message.InvoiceIndex.tabs.delivery.weight')"
                                ></InputText>
                            </div>
                            <div class="col-sm-4">
                                <InputSelect v-model="entry.weightUnit"
                                             :options="listWeightUnit"
                                ></InputSelect>
                            </div>
                        </div>
                    </div>
                    <div class="form-group mb-0">
                        <label>{{$t('message.InvoiceIndex.tabs.delivery.size')}}</label>
                        <div class="row">
                            <div class="col-sm-4">
                                <InputText v-model="entry.long"
                                           :errors="errors.long"
                                           :placeholder="$t('message.common.long')"
                                ></InputText>
                            </div>
                            <div class="col-sm-4">
                                <InputText v-model="entry.width"
                                           :errors="errors.width"
                                           :placeholder="$t('message.common.width')"
                                ></InputText>
                            </div>
                            <div class="col-sm-4">
                                <InputText v-model="entry.height"
                                           :errors="errors.height"
                                           :placeholder="$t('message.common.height')"
                                ></InputText>
                            </div>
                        </div>
                    </div>
                    <InputSelect v-model="entry.transporterId"
                                 :errors="errors.transporterId"
                                 :options="listTransporter"
                                 label="Người giao"
                    ></InputSelect>
                    <InputSelect v-model="entry.serviceTypeId"
                                 :errors="errors.serviceTypeId"
                                 :options="listServiceType"
                                 label="Dịch vụ"
                    ></InputSelect>
                </div>
                <div class="col-lg-4">
                    <InputDatePicker v-model="entry.deliveryTime"
                                     :errors="errors.deliveryTime"
                                     :timepicker="true"
                                     :label="$t('message.orderFromTab.delivery_time')"
                    ></InputDatePicker>
                    <InputSwitchButton v-model="entry.usingPriceCod"
                                       label="Thu hộ tiền (COD)"
                                       :detail="n(entry.priceCodPayment)"
                    ></InputSwitchButton>
                    <div class="form-group">
                        <label>Còn cần thu (COD)</label>
                        <div class="form-control" v-text="entry.usingPriceCod ? n(entry.priceCodPayment) : ''" readonly></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {$post, $alert, scrollToElement} from "@/lib/utils";
    import InputDatePicker from "../../../components/forms/InputDatePicker";
    import InputSelect from "../../../components/forms/InputSelect";
    import InputTextArea from "../../../components/forms/InputTextArea";
    import InputText from "../../../components/forms/InputText";
    import InputLocationPicker from "../../../components/forms/InputLocationPicker";
    import ActionBar from "../../../components/ActionBar";
    import InputSwitchButton from "../../../components/forms/InputSwitchButton";

    export default {
        name: "DeliveryTabInfo",
        components: {
            InputSwitchButton,
            ActionBar, InputLocationPicker, InputDatePicker, InputSelect, InputTextArea, InputText},
        data() {
            return {
                entry: {},
                errors: {},
                isDataLoaded: false,
                location: {
                    provinceId: 0,
                    districtId: 0,
                    wardId: 0
                },
                listStatus: [],
                listWeightUnit: [],
                listTransporter: [],
                listServiceType: []
            }
        },
        methods: {
            async save() {
                this.errors = {};

                this.entry.receiverProvinceId = this.location.provinceId;
                this.entry.receiverDistrictId = this.location.districtId;
                this.entry.receiverWardId = this.location.wardId;

                const res = await $post('/v1/deliveries/save', {
                    entry: this.entry
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            }
        }
    }
</script>

<style scoped>

</style>
