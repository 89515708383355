<template>
    <div class="search-box position-relative" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input" :placeholder="$t('message.common.searchProduct')"
               v-model.trim="keyword" @input="onSearchProduct" :readonly="readonly"/>
        <i class="fa fa-plus px-2" @click="showModalProductsAdd" v-if="!notAdd"></i>
        <div class="popup-product-search" v-if="keyword">
            <ul class="wrap-products" v-if="products.length">
                <li v-for="product in products" :key="product.id" @click="onSelect(product)">
                    <template v-if="product.image?.length > 0">
                        <div class="product-img">
                            <img v-if="product.image" :src="product.image[0]?.url" alt="" class="detail-row-img">
                        </div>
                    </template>
                    <template v-else>
                        <div class="product-img">
                            <img src="../../public/img/default-product.png" alt="" class="detail-row-img">
                        </div>
                    </template>
                    <div class="product-info">
                        <p class="name-item">
                            <span v-text="product.name"></span>
                            <span v-text="' (' + product.productUnit.unitName + ')'" v-if="product.productUnit"></span>
                        </p>
                        <p>
                            <span class="code-item" v-text="product.code"></span>
                            <span class="price-label">{{$t('message.ProductUnitSearch.price')}}: </span>
                            <span class="price-value">{{ $filters.formatPrice((product.product_units && product.product_units.length) ? product.product_units[0].price : '' ) }}</span>
                        </p>
                        <p>
                            <span class="d-flex price-item" v-if="product.product_branch_meta">
                                <span translate="">{{$t('message.ProductUnitSearch.inventory')}}: </span>
                                <div v-for="(item, index) in product.product_branch_meta" :key="index" class="d-flex" >
                                    <div class="actualQuantityValue ml-4">{{ ( item.inventories && product.type != this.$constant.typeProductCombo )  ? item.inventories : '__' }}</div>
                                    <div class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                    <span> ,</span>
                                </div>
                            </span>
                            <span class="d-flex price-item mt-1" v-if="product.product_branch_meta">
                                <span translate="">{{$t('message.ProductUnitSearch.orderFromCustomer')}}: </span>
                                <div v-for="(item, index) in product.product_branch_meta" :key="index" class="d-flex" >
                                    <div class="actualQuantityValue ml-4">{{ item.quantityOrder ? item.quantityOrder : '' }}</div>
                                    <div class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                    <span> ,</span>
                                </div>
                            </span>
                        </p>
                        <P class="mt-2">
                            <div v-if="product.order_quantity_convert && product.order_quantity_convert.length">
                                {{$t('message.ProductUnitSearch.orderSupplier')}}:
                                <span v-for="(itemOrder , index) in product.order_quantity_convert" :key="index">
                                     {{ itemOrder.totalQuantity ? (itemOrder.totalQuantity + ' ' +  itemOrder.unit.unitName + ', ') : ''}} 
                                </span>
                            </div>
                            <div v-else>
                                {{$t('message.ProductUnitSearch.orderSupplier')}}: 0
                            </div>
                        </P>
                    </div>
                </li>
            </ul>
            <div v-else class="products-not-found">
                <span>
                    {{ $t('message.common.notFound') }}
                </span>
            </div>
        </div>
    </div>
    <ModalProductsAdd ref="ModalProductsAdd" @getInfoProduct="getInfoProduct" :typeEditAdd="typeEditAdd"/>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import store from "@/store";
    import ModalProductsAdd from "@/views/products/tabs/ModalProductsAdd.vue";
    let app;

    export default {
        name: "ProductSearch",
        emits: ['select'],
        components: {ModalProductsAdd},
        props: ['readonly', 'action', 'notAdd', 'toBranchId', 'priceListId', 'getAll', 'searchForPlan', 'startDate', 'endDate'],
        data() {
            app = this;
            return {
                products: [],
                keyword: '',
                typeEditAdd: 'ADD',
                branchId: store.state.CurrentBranch.id,
                all: this.getAll ? true : false,
            }
        },
        watch: {
            toBranchId(value) {
                if (!value) {
                    this.keyword = '';
                }
            }
        },
        methods: {
            getInfoProduct(product) {
                if (product.data.entry && product.data.entry.type == this.$constant.productCommon && product.data.entry.branchIDs && product.data.entry.branchIDs.includes(this.branchId.toString())) {
                    this.$emit('getInfoProduct', product);
                }
            },
            onSearchProduct: debounce(async () => {
                app.callOnSearchProduct();
            }),
            async callOnSearchProduct() {
                if (this.keyword && this.keyword.length >= 3) {
                    let res;
                    if(this.searchForPlan){
                        res = await $get('/v1/plans/get-product-for-plan', {
                            keyword: this.keyword,
                            branchId: this.toBranchId,
                            startDate: this.startDate,
                            endDate: this.endDate,
                        });
                    }else{
                        res = await $get('/v1/order-suppliers/get-products', {
                            keyword: this.keyword,
                            branchId: this.branchId,
                            action: this.action,
                            toBranchId: this.toBranchId,
                            priceListId: this.priceListId,
                            all: this.all,
                        });
                    }

                    if (res.status.code == 200) {
                        this.products = res.result;                        
                    } else {
                        $alert(res);
                    }
                }else{
                    this.products = [];
                }
            },
            onSelect(product) {
                product.quantity = 1;
                this.$emit('select', product);
                this.products.length = 0;
                this.keyword = '';
                // localStorage.setItem('details', product);
            },
            hidePopup() {
                this.keyword = '';
                this.products.length = 0;
            },
            showModalProductsAdd(){
                if (!this.readonly) {
                    this.$refs.ModalProductsAdd.show();
                }
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;
        /*padding-bottom: 50px;*/

        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-products {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .products-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
</style>
