import {$get, $post, buildQuery, forEach} from "./utils";
import config from "@/config";

let requestQueue: Array<any> = [];
const promiseMap: any = {};
let timeoutId: any;
let isDataSourceLoaded = !config.App.debug;
let autoID = 1;

export function $getDataSource(datasource: string, params:any = {}) {
    if (!isDataSourceLoaded) {
        if (!params) {
            params = {};
        }

        params.datasource = datasource;
        return $get('/v1/data-sources/index', params, false);
    }

    return new Promise(((resolve, reject) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(async () => {
            const entries = requestQueue;
            requestQueue = [];
            isDataSourceLoaded = false;
            const res:any = await $post('/v1/data-sources/get-many', {
                entries
            }, false);

            if (res.code === 200) {
                const result = res.data;
                forEach(result, (rRes: any, reqId: string) => {
                    const e = promiseMap[reqId];

                    if (rRes.code === 200) {
                        e.resolve(rRes);
                    } else {
                        console.error(rRes);
                        e.reject(rRes);
                    }
                })

                forEach(promiseMap, (p: any, requestId: string) => {
                    p.resolve(result[requestId]);
                });
            }

        }, 200);

        const requestId = autoID;
        requestQueue.push({request_id: requestId, datasource, params});
        promiseMap[requestId] = {resolve, reject};
        autoID++;
    }));
}
