<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <input  ref="input" :readonly="readonly" :placeholder="placeholder ? placeholder : label" @change="onChange()" :disabled="this.inActive == true"
               autocomplete="off"
               :class="[(errors && errors.length ? 'error-input': ''), (cardTransaction ? 'border-bottom-1 p-0 rounded-0' : ''),(this.sortTimeHeight ? 'select-option' : '')]"
               class="form-control" @focus="disabled"
               id="inputDatePicker"         
               >
        <a v-if="this.isAction" href="javascript:;" class="text-secondary" id="icoTimes" @click="isEmpty()">
            <i class="fa fa-times"></i>
        </a>
        <i class="fa fa-calendar-alt icon-calendar" v-if="sortTimeHeight" aria-hidden="true" @click="showCalendar()"></i>
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
import {DATE_LOCALES} from "../../lib/locale";
import moment from 'moment';

export default {
    name: "InputTimePicker",
    props: ['modelValue', 'placeholder', 'timepicker', 'label', 'min', 'errors', 'readonly', 'isAction', 'cardTransaction', 'canNullValue', 'sortTimeHeight', 'inActive'],
    methods: {
        isEmpty(){
            let inputDatePicker = document.getElementById('inputDatePicker');
            inputDatePicker.value = "";
        },
        disabled(){
            let inputDatePicker = document.getElementById('inputDatePicker');
            inputDatePicker.onkeydown = function (e) 
            {
                return false;
            }
        },
        onChange() {
           if (!this.$refs.input.value) {
               this.$emit('update:modelValue', null);
           }
        },
        showCalendar(){
            window.jQuery(this.$refs.input).focus()
        }
    },
    watch: {
        modelValue: function (newValue) {            
            if (!this.modelValue) {
                this.$refs.input.value = '';
            }

            if (newValue) {
                let value = newValue ? moment(newValue) : moment();
                let format = this.timepicker ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';
                this.$refs.input.value = value.format(format);
            }
        }
    },

    mounted: function () {
        const input = this.$refs.input;

        let value = this.modelValue ? moment(this.modelValue) : moment();
        let format = this.timepicker ? 'DD/MM/YYYY HH:mm' : 'DD/MM/YYYY';

        if (!this.readonly) {
            let minDate = undefined;
            if (this.min === 'now') {
                minDate = moment();
            }

            const $input = window.jQuery(input);

            window.jQuery($input).daterangepicker({
                singleDatePicker: true,
                autoUpdateInput: false,
                startDate: value,
                endDate: value,
                locale: DATE_LOCALES,
                timePicker: this.timepicker || false,
                timePicker24Hour: true,
                minDate
            });

            const changeHandler = (ev, picker) => {
                if(this.canNullValue && ev.type == 'change' && !input.value){
                    return;
                }
                const start = $input.data('daterangepicker').startDate;
                let modelValue;
                if (this.sortTimeHeight) {
                    modelValue = start.format('YYYY-MM-DD');
                }
                else {
                    modelValue = start.format('YYYY-MM-DD HH:mm:ss');
                }
                this.$emit('update:modelValue', modelValue);
                input.value = start.format(format);

                this.$nextTick(() => {
                    this.$emit('input', modelValue);
                });
            };

            $input.on('change',changeHandler);
            $input.on('apply.daterangepicker',  changeHandler);
        }

        if (this.modelValue) {
            input.value = value.format(format);
        }

    },

}
</script>

<style scoped>
#icoTimes {
    position: absolute;
    top:15%;
    right:23%;
}
.icon-calendar{     
    position: absolute;
    right: 16px;     
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;     
    -moz-osx-font-smoothing: grayscale;
}
</style>
