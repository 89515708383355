export class Storage {
    private prefix = 'web.';
    constructor() {
    }


    get<T>(key: string, defaultValue: any = null): T | null {
        key = this.prefix + key;
        const r = localStorage.getItem(key);
        if (!r) {
            return defaultValue;
        }

        try {
            return JSON.parse(r);
        } catch (e) {
            return null;
        }

    }

    set(key: string, value: any) {
        key = this.prefix + key;
        localStorage.setItem(key, JSON.stringify(value))
    }

    remove(key: string) {
        key = this.prefix + key;
        localStorage.removeItem(key);
    }
}

export default new Storage()
