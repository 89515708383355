
<template>
    <AppPermission :permission="this.$permissions.orders.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>{{ $t('message.OrderIndex.title') }}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                            <div class="col-8 text-title">
                                <label>{{ $t('message.common.numberRecord') }}:</label>
                            </div>
                            <div class="col-4">
                                <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branches" 
                                    :modelValue="filterQuery.branches" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.OrderIndex.filter.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.branch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"></SelectSearch> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.time') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <!-- <div class="row mb-2">
                                <div class="col-2 pl-2 d-flex align-items-center">
                                    <label class="container ml-2">
                                        <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios1" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-10 pl-0">
                                    <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-2 pl-2 d-flex align-items-center">
                                    <label class="container ml-2">
                                        <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios2" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-10 pl-0">
                                    <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
                                    @input="filterTime(filter.timePicker, 1)" v-model="filter.timePicker"/>
                                </div>
                            </div> -->
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Người tạo -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.created_by') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                        <i class="fa fa-chevron-up" id="creatorUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.created_by')" :modelValue="filterQuery.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Người nhân đặt -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.ordered_by') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('orderBody', 'orderDown', 'orderUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="orderDown"></i>
                                        <i class="fa fa-chevron-up" id="orderUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="orderBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.ordered_by')" :modelValue="filterQuery.orderBy" :defaultOption="this.orderBy.all" @update:modelValue="doFilterSearch" :label="'orderBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Kênh bán -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{ $t('message.OrderIndex.filter.saleChannel') }}</label>
                                </div>
                                <AppPermission :permission="this.$permissions.saleChannels.create?? '' ">
                                    <div class="col-1" @click="showModalAddInfoSaleChannels()">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                        </a>
                                    </div>
                                </AppPermission> 
                                <div class="col-2 pl-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('saleChannelBody', 'saleChannelDown', 'saleChannelUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="saleChannelDown"></i>
                                        <i class="fa fa-chevron-up" id="saleChannelUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="saleChannelBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.OrderIndex.filter.placeholder.saleChannel')" :modelValue="filterQuery.saleChannel" :defaultOption="this.saleChannel.all" @update:modelValue="doFilterSearch" :label="'saleChannel'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.OrderIndex.filter.placeholder.search')" @filterSearch="changeQuickSearch" :ordersSearch="true"></InputQuickSearch>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                            
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter mr-1"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>
                                         {{ $t('message.button.action') }}
                                    </button>                                       
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                         <AppPermission :permission="this.$permissions.orders.export">  
                                            <a class="dropdown-item" @click="exSelectOverview()">
                                                <i class="fa fa-share" ></i>
                                                 {{ $t('message.button.export_all') }}
                                            </a>
                                            <a class="dropdown-item"  @click="exSelectDetails()">
                                                    <i class="fas fa-file-export"></i>
                                                 {{ $t('message.button.export_detail') }}
                                            </a>
                                        </AppPermission>
                                            <a class="dropdown-item"  @click="printMultiple()">
                                                    <i class="fas fa-print"></i>
                                                 {{ $t('message.button.print.name') }}
                                            </a>
                                            <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                                <i class="fa fa-envelope" aria-hidden="true"></i>
                                                {{ $t('message.button.sendMailText') }}
                                            </a>
                                        <!-- <AppPermission :permission="[this.$permissions.invoices.create ?? '', this.$permissions.orders.createImportSupplier ?? '', this.$permissions.orders.update ?? '', this.$permissions.orders.updateStatus ?? '']"> -->
                                            <a class="dropdown-item" @click.stop="createInvoicesMultiple()">
                                                <i class="fa fa-file" aria-hidden="true"></i>
                                                {{ $t('message.button.invoiceAdd') }}
                                            </a>
                                        <!-- </AppPermission> -->
                                            <a class="dropdown-item" @click="sendZalo()">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                            </a>
                                    </div>
                                </div>
                                 <AppPermission :permission="this.$permissions.orders.create">
                                    <button class="btn btn-save ml-1" @click="createOrders()">
                                        <i class="fa fa-plus px-1"></i>
                                        {{$t('message.OrderIndex.button.order')}}
                                    </button>
                                </AppPermission>
                                <!-- <button class="btn btn-save ml-1">
                                    <i class="fa fa-copy px-1"></i>Gộp phiếu
                                </button> -->
                                 <AppPermission :permission="this.$permissions.orders.export">
                                        <div class="dropdown">
                                            <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                                style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-file-export px-1"></i>
                                                {{$t('message.OrderIndex.button.export')}}
                                            </button>
                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <a class="dropdown-item" href="javascript:void(0);" @click="exOverview()">{{$t('message.OrderIndex.button.file_all')}}</a>
                                                <a class="dropdown-item" href="javascript:void(0);"  @click="exDetails()">{{$t('message.OrderIndex.button.file_detail')}}</a>
                                            </div>
                                        </div>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="orders"></SelectField>
                                </div>                            
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()">
                                        <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :class="entry.id === this.orderActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected && field.field !='customerContactNumber'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailOrder(entry.id, entry)">
                                                                    <div v-if="field.field==='invoice_codes'" :title="entry.invoice_codes" :style="field.style" class="description" :class="field.class">
                                                                        <span v-for="(invoiceInOder,i) in entry.invoice_codes" :key="invoiceInOder.code" :class="invoiceInOder.isCanceled ? 'text-danger' : ''">{{ invoiceInOder.code + (i + 1 == entry.invoice_codes?.length ? '' : ', ') }}</span>
                                                                    </div>
                                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                            <td class="ow-break-word py-0" v-else-if="field.selected && field.field ==='customerContactNumber'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <template v-if="entry.customerContactNumber.length > 1" >
                                                                    <select  class="sltUnit">
                                                                        <option class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></option>
                                                                    </select>
                                                                </template>
                                                                <template v-else-if="entry.customerContactNumber.length == 1" >
                                                                    <span class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></span>
                                                                </template>  
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="orderActive === entry.id" :class="[ entry.id === orderActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2"  @click="showContent(0)">
                                                                    <span :class=" this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{$t('message.OrderIndex.tabs.info')}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 mt-2"  @click="showContent(2)" v-if="entry.invoices && entry.invoices.length > 0" >
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryImport ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{$t('message.OrderIndex.tabs.history_invoice')}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 mt-2" @click="showContent(1)" v-if="(entry.cash_book && entry.cash_book.length > 0)  || (entry.bank_book && entry.bank_book.length > 0)" >                                                      
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.OrderIndex.tabs.history_payment')}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Hiển thị tab Thông tin -->
                                                     <template v-if="this.tabActive == this.$constant.tabInformation">
                                                        <!-- Chi tiết phiếu chuyển hàng -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="orderActive === entry.id">
                                                            <td></td>
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row">
                                                                        <div class="col-4">
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.OrderIndex.code')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">                                                            
                                                                                    <b>{{ entry.code }}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5 lableOS">
                                                                                    {{$t('message.OrderIndex.time')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                    {{ dt(entry.purchaseDate) }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.OrderIndex.customer')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :class="entry.customer && entry.customer.code ? 'text-primary' : ''" @click="entry.customer && entry.customer.code ? linkToCustomer(entry.customer.code) : ''">
                                                                                    {{ entry.customerName }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.OrderIndex.price_list')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    {{ entry.priceListName }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.OrderIndex.expected_date_of_transfer')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    <template  v-if="entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order">
                                                                                        <input type="date" v-model="entry.ExpectedDateOfTransfer" class="w-100 border-none">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="d(entry.ExpectedDateOfTransfer)"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2" v-if="entry.invoice_codes">
                                                                                <div class="col-5">
                                                                                    {{$t('message.OrderIndex.invoice_code')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    <span v-for="(invoiceInOder,i) in entry.invoice_codes" :key="invoiceInOder.code" :class="invoiceInOder.isCanceled ? 'text-danger' : ''">{{ invoiceInOder.code + (i + 1 == entry.invoice_codes?.length ? '' : ', ') }}</span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="mx-1">
                                                                                <div class="col-12">
                                                                                    <div class="row border-bottom-1" :class="(entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order) ? 'pt-2 pb-1' : 'mt-2 py-2'">
                                                                                        <div class="col-5 d-flex align-items-center">
                                                                                            {{$t('message.OrderIndex.status')}}:
                                                                                        </div>
                                                                                        <div class="col-7 ow-break-word">
                                                                                            <span v-if="this.hasPermission(this.$permissions.orders.updateNotStatus) && this.user.id != this.$constant.isAdmin">{{ entry.status }}</span>
                                                                                            <InputSelectSearch :defaultValue="$t('message.common.selectStatus')" :modelValue="entry.statusOrigin" :defaultOption="listEditStatus" @update:modelValue="setStatus"
                                                                                            v-else-if="(entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order)">
                                                                                            </InputSelectSearch>
                                                                                             <!-- v-if="((entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order) &&  -->
                                                                                            <!-- (this.hasPermission(this.$permissions.orders.update)) || this.user.id == this.$constant.isAdmin)" -->
                                                                                            <span v-else>{{ entry.status }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.OrderIndex.branch')}}:
                                                                                        </div>
                                                                                        <div v-if="!entry.branchesActive" class="col-7 ow-break-word">
                                                                                            {{ entry.branchName }}
                                                                                        </div>
                                                                                        <div v-else class="col-7 ow-break-word">
                                                                                            <InputSelectSearch :defaultValue="entry.branchIdNew" :modelValue="entry.branchIdNew" :defaultOption="entry.branchesActive" v-model="entry.branchIdNew"/>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1" :class="(entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order) ? 'pt-2 pb-1' : 'mt-2 py-2'">
                                                                                        <div class="col-5 d-flex align-items-center">
                                                                                            {{$t('message.OrderIndex.ordered_by')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word"> 
                                                                                            <InputSelectSearch :defaultValue="$t('message.OrderIndex.filter.placeholder.ordered_by')" :modelValue="entry.soldById" :defaultOption="users" @update:modelValue="setSoldBy"
                                                                                            v-if="entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order"></InputSelectSearch>
                                                                                            <span v-else>{{ entry.soldByName }}</span>                                                                   
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.OrderIndex.created_by')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">                                                                    
                                                                                            {{entry.userCreatedName}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2" v-if="entry.sync_order != null">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.OrderIndex.status_on_ecommerce')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">                                                                    
                                                                                            {{ $filters.formatStatusShopee(entry.sync_order?.sync_status) }}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">  
                                                                            <div class="row p-0 h-50 font-italic" id="noteRO">
                                                                                <!-- <div class="col-12">
                                                                                    <textarea v-model="entry.description" :id="'note_'+ entry.id" :placeholder="$t('message.ProductIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                </div> -->
                                                                                <div class="col-12" v-if="entry.statusOrigin == this.$constant.status_draft_order">
                                                                                    <textarea v-model="entry.description" :id="'note_'+ entry.id" :placeholder="$t('message.OrderIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                </div>
                                                                                <div class="col-12 text-gray" v-else>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                    <span class="noteStyle px-4 text-gray font-italic">
                                                                                        {{ entry.description ? entry.description : $t('message.OrderIndex.note')}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>                                                     
                                                                            <div class="row border-bottom-1 pt-2 pb-1">
                                                                                <div class="col-5 d-flex align-items-center">
                                                                                    {{$t('message.OrderIndex.sale_channel')}}:
                                                                                </div>                                                                
                                                                                <div class="col-7 ow-break-word">    
                                                                                    <InputSelectSearch :defaultValue="$t('message.OrderIndex.filter.placeholder.saleChannel')" :modelValue="entry.saleChannelId" :defaultOption="saleChannels" @update:modelValue="setSaleChannel"
                                                                                    v-if="(entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order) && (entry.sync_order == null)">
                                                                                    </InputSelectSearch>
                                                                                    <span v-else>{{ entry.saleChannelName }}</span>                                                                
                                                                                </div>
                                                                            </div>
    
                                                                            <div class="row pt-2">
                                                                                <div class="col-12">
                                                                                    <InputFileManagerInsideTemplate :ref="'InputFileManagerInsideTemplate' + entry.id" max="5" v-model="entry.image" :multiple="true" :smallSize="true" :zIndex="true" @showModal="showModalManageFile"/>
                                                                                </div>
                                                                            </div>
    
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="orderActive === entry.id && entry.delivery">
                                                            <td :colspan="fields.length + 1" class="border-top-0 px-0">
                                                                <div class="row pt-4 px-4 pb-2 m-0 detail-prd-row bg-light">
                                                                    <div class="col-12 text-bold px-0">
                                                                        <h6 class="text-bold">{{ $t('message.InvoiceIndex.tabs.delivery.title') }}:</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-3 m-0 detail-prd-row bg-light">
                                                                    <div class="col-12 d-flex px-0">
                                                                        <span>{{ $t('message.InvoiceIndex.tabs.delivery.address_receive') }}:</span>
                                                                        <span>
                                                                            {{ entry.delivery && entry.delivery.branch_address 
                                                                                ? entry.delivery.branch_address.addressValue 
                                                                                    + ( entry.delivery.branch_address.ward ? ', ' + entry.delivery.branch_address.ward.name : null )  
                                                                                    + ( entry.delivery.branch_address.district ? ', ' + entry.delivery.branch_address.district.name : null )  
                                                                                    + ( entry.delivery.branch_address.province ? ', ' + entry.delivery.branch_address.province.name : null )  
                                                                                    + ' - ' 
                                                                                    + entry.delivery.branch_address.phone 
                                                                                : '' 
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="!entry.delivery.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2 ">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.customer_address.name">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.name : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.contact_number') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <InputText v-model.trim="entry.delivery.customer_address.phone" 
                                                                                            placeholder="Nhập số điện thoại" :max="10" 
                                                                                        :cusNumber="true" :classCustom="'border-bottom-1 w-100 border-bottom-focus-active rounded-0 pl-0'"/>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.phone : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.address') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <textarea class="border-0 bg-transparent border-bottom-focus-active w-100 textarea-outline-none" v-model.trim="entry.delivery.customer_address.addressValue"></textarea>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.addressValue : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.area') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address 
                                                                                        && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.customer_address.districtId" 
                                                                                                        :modelValue="entry.delivery.customer_address.districtId" 
                                                                                                        :defaultOption="listAreaDelivery" :label="'areaDelivery_' + entry.id" 
                                                                                                        @update:modelValue="changeDistrict(entry.delivery.customer_address)"
                                                                                                        @setNewData="setAreaDelivery"
                                                                                                        :areaSearch="true" 
                                                                                                        :readonly="true">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.districts 
                                                                                                        ? entry.delivery.customer_address.districts.name + (entry.delivery.customer_address.provinces ? ' - ' + entry.delivery.customer_address.provinces.name : null) : (entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.provinces ? entry.delivery.customer_address.provinces.name 
                                                                                                        : null)">
                                                                                        </span>
                                                                                    </template>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.ward') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.customer_address.wardId" 
                                                                                                        :modelValue="entry.delivery.customer_address.wardId" 
                                                                                                        :defaultOption="listWards" :label="'wards_' + entry.id" 
                                                                                                        :areaSearch="true" 
                                                                                                        :readonly="true">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.wards ? entry.delivery.customer_address.wards.name : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-12 d-flex pr-0">
                                                                                    <i class="fa fa-pencil-alt text-gray"></i>
                                                                                    <div class="w-100 font-italic text-gray" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                        <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                            <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.note">
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="entry.delivery.note"></span>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_code') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.code">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span class="text-primary" v-text="entry.delivery && entry.delivery.code ? entry.delivery.code : null" @click="entry.delivery && entry.delivery.code ? linkToDelivery(entry.delivery.code) : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <!-- <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_status') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.status" :modelValue="entry.delivery.status" :defaultOption="this.listStatusDeliveryAll" :label="'status_' + entry.id" :readonly="true"></SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.statusName ? entry.delivery.statusName : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div> -->
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.weight') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <div class="row m-0 p-0 d-flex align-items-end">
                                                                                            <div class="col-lg-7 px-0 d-flex align-items-end pr-2">
                                                                                                <InputNumberFormat :pxNone="true" v-model="entry.delivery.weight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                            </div>
                                                                                            <div class="col-lg-5 px-0">
                                                                                                <SelectSearch :vModel="true" v-model="entry.delivery.weightUnit" :modelValue="entry.delivery.weightUnit" :defaultOption="listWeightUnit" :label="'weightUnit_' + entry.id" :readonly="true"></SelectSearch>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.weight ? n(entry.delivery.weight) + ' ' + entry.delivery.weightUnitValue : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.size') }}:</div>
                                                                                <div class="col-7 px-0 d-flex">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <div class="column-size">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.long" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="column-size mx-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.width" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="column-size">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.hight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <div class="column-size" v-text="entry.delivery && entry.delivery.long ? n(entry.delivery.long) + ' x' : null"></div>
                                                                                        <div class="column-size mx-2" v-text="entry.delivery && entry.delivery.width ? n(entry.delivery.width) + ' x' : null"></div>
                                                                                        <div class="column-size" v-text="entry.delivery && entry.delivery.hight ? n(entry.delivery.hight) + ' cm' : null"></div>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.control_code') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.control_code">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.code ? entry.delivery.control_code : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.service') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.service_type ? getNameByLocale(entry.delivery.service_type) : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectSearch v-model="entry.delivery.serviceTypeId" :modelValue="entry.delivery.serviceTypeId" :defaultOption="listServiceType" :label="'serviceTypeId_' + entry.id" :readonly="true" :vModel="true"></SelectSearch>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_by') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.name : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.transporterId" :modelValue="entry.delivery.transporterId" :defaultOption="listTransporterSelfCreated" :label="'transporterId_' + entry.id" :areaSearch="true" :readonly="true"></SelectSearch>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <label class="container-checkbox">
                                                                                        <b v-text="n(entry.delivery.priceCodPayment)" v-if="entry.delivery.usingPriceCod"></b>
                                                                                        <input type="checkbox" class="chkType" v-model="entry.delivery.usingPriceCod" 
                                                                                        :checked="entry.delivery && entry.delivery.usingPriceCod ? true : false" 
                                                                                        :readonly="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.delivery.priceCodPayment > entry.priceCodPaymentStill)) ? true : false" 
                                                                                        :disabled="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.delivery.priceCodPayment > entry.priceCodPaymentStill)) ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod_still') }}:</div>
                                                                                <div class="col-7 px-0" v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.statusUpdate != this.$constant.status_update_delivery_3 && entry.statusUpdate != this.$constant.status_update_delivery_4">
                                                                                        <span v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <InputNumberFormat :pxNone="true" v-model="entry.delivery.price" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_still') }}:</div>
                                                                                <div class="col-7 px-0" v-text="n(entry.priceStill)"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_time') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.deliveryTime ? dt(entry.delivery.deliveryTime) : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectDateTimePicker :modelValue="entry.delivery.deliveryTime" v-model="entry.delivery.deliveryTime" :borderActive="true" :backgroundColor="'bg-white'"/>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="entry.delivery.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2 ">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.name : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.contact_number') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.phone : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.address') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.addressValue : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.area') }}:</div>
                                                                                <div class="col-7 "
                                                                                    v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.districts ? entry.delivery.customer_address.districts.name + (entry.delivery.customer_address.provinces ? ' - ' + entry.delivery.customer_address.provinces.name : null) : (entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.provinces ? entry.delivery.customer_address.provinces.name : null)">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.ward') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.wards ? entry.delivery.customer_address.wards.name : null"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_code') }}:</div>
                                                                                <div class="col-7 text-primary" v-text="entry.delivery && entry.delivery.code ? entry.delivery.code : null" @click="entry.delivery && entry.delivery.code ? linkToDelivery(entry.delivery.code) : null"></div>
                                                                            </div>
                                                                            <!-- <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_status') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.statusName ? entry.delivery.statusName : null"></div>
                                                                            </div> -->
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.weight') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.weight ? n(entry.delivery.weight) + (entry.delivery.weightUnitValue ? ' ' + entry.delivery.weightUnitValue : null) : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.size') }}:</div>
                                                                                <div class="col-7">
                                                                                    {{ entry.delivery.long ? n(entry.delivery.long) : null }}
                                                                                    {{ entry.delivery.width ? ' x ' + n(entry.delivery.width) : null }}
                                                                                    {{ entry.delivery.hight ? ' x ' + n(entry.delivery.hight) + ' cm' : null }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.conversion_exchange') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_declaration') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        (
                                                                                            {{$t('message.common.productValue')}}: {{n(entry.delivery.isInSurance ? entry.delivery.inSuranceValue : 0)}}
                                                                                        )
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery.isInSurance ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.take_to_office') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery.isTakePostOffice ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.pay_fee_by') }}:</div>
                                                                                <div class="col-7">
                                                                                    <div>
                                                                                        <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.paid_by') }}
                                                                                            <input type="radio" name="fee" :checked="entry.delivery.payFeeShip == this.$constant.paid_by_sender || entry.delivery.payFeeShip == this.$constant.paid_by_app ? true : false" :readonly="true" :disabled="true">
                                                                                            <span class="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}
                                                                                            <input type="radio" name="fee" :checked="entry.delivery.payFeeShip == this.$constant.paid_by_receiver ? true : false" :readonly="true" :disabled="true">
                                                                                            <span class="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.note_delivery') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery.note"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.discount_code') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_by') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.name : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.estimate_delivery') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.service') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.service_type ? getNameByLocale(entry.delivery.service_type) : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.control_code') }}:<i class="fa fa-info-circle"></i></div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        <b v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.delivery.priceCodPayment) : 0"></b>
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery && entry.delivery.usingPriceCod ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod_still') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.total_fee') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.totalPrice ? n(entry.delivery.totalPrice) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_still') }}:</div>
                                                                                <div class="col-7" v-text="n(entry.priceStill)"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.paidByReceiverValue ? n(entry.delivery.paidByReceiverValue) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.total_receive') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.amountCollectReceiver ? n(entry.delivery.amountCollectReceiver) : 0"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        
                                                        <!-- Chi tiet san pham va gia -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="orderActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                                        <div class="col-12 pl-0">
                                                                            <table class="w-100">
                                                                                <thead>
                                                                                    <tr class="backgroup__detail">
                                                                                        <template v-for="fieldDetail in this.fieldOrderDetail" :key="fieldDetail">
                                                                                            <th scope="col" class="pl-4"  v-text="fieldDetail.name" v-if="fieldDetail.selected" :style="fieldDetail.style ? fieldDetail.style : ''"></th>
                                                                                        </template>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="bg-white">
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultOrder.searchCode" @input="onInputFilterOrderDetail('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultOrder.searchName" @input="onInputFilterOrderDetail('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4" :colspan="fieldOrderDetail.length - 2"> 
                                                                                        </th>
                                                                                    </tr>
                                                                                    <template v-if="this.defaultOrder.filter?.length > 0">
                                                                                        <template v-for="(orderDetail, index) in this.defaultOrder.filter" :key="orderDetail.id">
                                                                                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                                <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                                    <td class="pl-4">
                                                                                                        <div class="row m-0 d-flex">
                                                                                                            <div class="col-1 p-0">
                                                                                                                <i class="fa fa-chevron-down" v-if="orderDetail.children_combo && orderDetail.children_combo.length > 0 && !orderDetail.showCombo" @click="showCombo(orderDetail)"></i>
                                                                                                                <i class="fa fa-chevron-up" v-if="orderDetail.children_combo && orderDetail.children_combo.length > 0 && orderDetail.showCombo" @click="showCombo(orderDetail)"></i>
                                                                                                            </div>
                                                                                                            <div class="col-10 p-0">
                                                                                                                <a :href="'/products/index?code=' + orderDetail.productCode" target="_blank" class="text-decoration-none text-primary">{{orderDetail.productCode}}</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td class="pl-4">
                                                                                                        <div class="row p-0 m-0">
                                                                                                            <div class="col-12 pl-0" v-text="orderDetail.productName"></div>
                                                                                                            <div class="col-12 pl-0 description font-italic text-gray" v-text="orderDetail.description" :title="orderDetail.description"></div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td v-text="orderDetail.unitName" class="text-center"></td>
                                                                                                    <td v-text="orderDetail.attributeName" class="text-center"></td>
                                                                                                    <td v-text="orderDetail.product?.hsCode" class="text-left"></td>
                                                                                                    <td v-text="orderDetail.product?.taxRate" class="text-left"></td>
                                                                                                    <td v-text="orderDetail.consignment" class="text-left"></td>
                                                                                                    <td v-text="orderDetail.stampName" class="text-left"></td>
                                                                                                    <td class="pl-4 text-right">{{n(orderDetail.quantity)}}/<span class="text-danger" v-text="n(orderDetail.quantityInvoice)"></span></td>
                                                                                                    <td v-text="n(orderDetail.price)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(orderDetail.discount)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(orderDetail.price - orderDetail.discount)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(orderDetail.subTotal)" class="pl-4 text-right"></td>
                                                                                                </tr>
                                                                                                <template v-if="orderDetail.children_combo && orderDetail.children_combo.length > 0 && orderDetail.showCombo">
                                                                                                    <tr v-for="(productInCombo, key) in orderDetail.children_combo" :key="key" class="backgroup__detail">
                                                                                                        <td class="pl-4">
                                                                                                            <div class="row m-0 d-flex">
                                                                                                                <div class="col-1 p-0">
                                                                                                                </div>
                                                                                                                <div class="col-10 p-0 pl-2">
                                                                                                                    <span v-text="(key + 1) + '. '"></span>
                                                                                                                    <a :href="'/products/index?code=' + productInCombo.product.code" target="_blank" class="text-decoration-none text-primary">{{productInCombo.product.code}}</a>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </td>
                                                                                                        <td class="pl-4">{{ productInCombo.product.name }}</td>
                                                                                                        <td class="pl-4">{{ productInCombo.product_unit.unitName }}</td>
                                                                                                        <td></td>
                                                                                                        <td></td>
                                                                                                        <td v-if="entry.consignment"></td>
                                                                                                        <td v-if="entry.stamp"></td>
                                                                                                        <td class="pl-4 text-right">{{ productInCombo.quantity }}</td>
                                                                                                        <td :colspan="4"></td>
                                                                                                    </tr>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                    
                                                                                    <template v-else>
                                                                                        <tr class="text-center bg-light">
                                                                                            <td :colspan="fieldOrderDetail.length">{{$t('message.common.notFound')}}</td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </tbody>
                                                                            </table>
                                                                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-4 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.total_quantity') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalProduct)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.total_origin') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalOrigin)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.discount') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.discountPrice)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.surcharge') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.surchargeAmount)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.total') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.total)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.total_payment') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalPayment)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.transaction_fee') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.transactionFee)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.shipping_fee_collected_by_customer') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="entry.delivery && entry.delivery.priceOrigin ? n(entry.delivery.priceOrigin) : 0"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.OrderIndex.fees_paid_to_delivery_partners') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="entry.feesPaidToDeliveryPartners ? n(entry.feesPaidToDeliveryPartners) : 0"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                                        <button type="button" class="btn btn-save mx-1" @click="sendZalo(entry)">
                                                                            <i class="fa fa-share"></i>
                                                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                                        </button>
                                                                        <!-- <AppPermission :permission="this.$permissions.orders.update?? '' "> -->
                                                                            <button type="button" class="btn btn-save mx-1" @click="endOrder(entry.id)" v-if="(entry.branchId == currentBranchId && entry.statusOrigin != this.$constant.status_end_order && entry.statusOrigin != this.$constant.status_cancel_order)">
                                                                                <i class="fa fa-save"></i>
                                                                                {{ $t('message.OrderIndex.button.end') }}
                                                                            </button>
                                                                        <!-- </AppPermission> -->
                                                                        <!-- Lưu nhanh -->
                                                                        <AppPermission :permission="this.$permissions.orders.update?? '' ">
                                                                            <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.branchId == currentBranchId && entry.statusOrigin != this.$constant.status_cancel_order">
                                                                                <i class="fa fa-save"></i>
                                                                                {{ $t('message.OrderIndex.button.save') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <!-- Xét quyền nut xử lý hóa đơn -->
                                                                        <!-- --------------------------------------------------------------------------------- -->
                                                                            <!-- <template v-if="(this.hasPermission(this.$permissions.invoices.create) && this.hasPermission(this.$permissions.orders.createImportSupplier)) || this.hasPermission(this.$permissions.orders.update)">
                                                                                <button type="button" class="btn btn-save mx-1" v-if="(entry.statusOrigin == this.$constant.status_draft_order && entry.branchId == currentBranchId) || (entry.statusOrigin == this.$constant.status_confirm_order && entry.branchId == currentBranchId)" 
                                                                                    @click="editOrder(entry.code)"> <i class="fa fa-share"></i>  Xử lý đơn hàng
                                                                                </button>
                                                                            </template> -->
    
                                                                            <!-- <AppPermission :permission="[this.$permissions.invoices.create ?? '', this.$permissions.orders.createImportSupplier ?? '', this.$permissions.orders.update ?? '', this.$permissions.orders.updateStatus ?? '']">
                                                                                <button type="button" class="btn btn-save mx-1" 
                                                                                    v-if="((entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_delivery_order)&& entry.branchId == currentBranchId) || (entry.statusOrigin == this.$constant.status_confirm_order && entry.branchId == currentBranchId)" 
                                                                                    @click="editOrder(entry.code)"> <i class="fa fa-share"></i>
                                                                                    {{ $t('message.OrderIndex.button.process_order') }}
                                                                                </button>
                                                                            </AppPermission> -->
    
                                                                            <AppPermission :permission="[this.$permissions.invoices.create ?? '', this.$permissions.orders.createImportSupplier ?? '', this.$permissions.orders.update ?? '', this.$permissions.orders.updateStatus ?? '']">
                                                                                <button type="button" class="btn btn-save mx-1" 
                                                                                    v-if="entry.statusOrigin == this.$constant.status_draft_order && entry.branchId == currentBranchId" 
                                                                                    @click="updateOrder(entry.code)"> <i class="fa fa-edit"></i>
                                                                                    {{ $t('message.InvoiceIndex.button.update') }}
                                                                                </button>
                                                                            </AppPermission>
    
                                                                            <AppPermission :permission="[this.$permissions.invoices.create ?? '', this.$permissions.orders.createImportSupplier ?? '', this.$permissions.orders.update ?? '', this.$permissions.orders.updateStatus ?? '']">
                                                                                <button type="button" class="btn btn-save mx-1" 
                                                                                    v-if="(entry.statusOrigin == this.$constant.status_delivery_order || entry.statusOrigin == this.$constant.status_confirm_order) && entry.branchId == currentBranchId" 
                                                                                    @click="createInvoice(entry.code)"> <i class="fa fa-share"></i>
                                                                                    {{$t('message.button.invoiceAdd')}}
                                                                                </button>
                                                                            </AppPermission>
    
                                                                            <!-- <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                                                                <AppPermission :permission="this.$permissions.orders.createImportSupplier?? '' ">
                                                                                    <button type="button" class="btn btn-save mx-1" v-if="(entry.statusOrigin == this.$constant.status_draft_order && entry.branchId == currentBranchId) || (entry.statusOrigin == this.$constant.status_confirm_order && entry.branchId == currentBranchId)" @click="editOrder(entry.code)">
                                                                                        <i class="fa fa-share"></i>
                                                                                        Xử lý đơn hàng
                                                                                    </button>
                                                                                </AppPermission>
                                                                            </AppPermission> -->
    
                                                                        <!-- --------------------------------------------------------------------------------- -->
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print" aria-hidden="true"></i>
                                                                            {{ $t('message.OrderIndex.button.print') }}
                                                                        </button>
                                                                        <AppPermission :permission="this.$permissions.orders.export?? '' ">
                                                                            <button type="button" class="btn btn-secondary text-white mx-1"  @click="exOrdersById(entry.id)">
                                                                                <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                                {{ $t('message.OrderIndex.button.export') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.orders.create?? '' ">
                                                                            <button type="button" class="btn btn-save mx-1" @click="copyOrdersById(entry.code)">
                                                                                <i class="fa fa-copy" aria-hidden="true"></i>
                                                                                {{ $t('message.OrderIndex.button.copy') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.orders.cancel?? '' ">
                                                                            <button type="button" class="btn btn-danger mx-1"  @click="ShowModalOrdersAbort(entry)" v-if="(entry.statusOrigin == this.$constant.status_draft_order || entry.statusOrigin == this.$constant.status_confirm_order) && entry.branchId == currentBranchId">
                                                                                <i class="fa fa-times"></i>
                                                                                {{ $t('message.OrderIndex.button.cancel') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Lịch sử thanh toán -->
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryPayment">                                                    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="orderActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                                    <div class="col-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="" id="orderCode" >
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.code') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.time') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.created_by') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.method') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.status') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table text-center">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_payment.total_price') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="entry.historyPayment">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                                        <td scope="col" class="" style="max-width:50px !important">                                                                                        
                                                                                            <a href="#" class="text-decoration-none text-primary" @click.stop="showModalDataPayment(history.id, history.type, this.$constant.typeOrder)">
                                                                                                <span>{{history?.code ? history.code : ''}}</span>
                                                                                            </a>
                                                                                        </td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdAt ? dt(history.createdAt) : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table text-center"><span>{{history?.payment ? n(history.payment) : ''}}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="6" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Lịch sử hóa đơn -->
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryImport">
                                                        <tr class="detail-row bg-white border__body-detail" v-if="orderActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                                    <div class="col-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_invoice.code') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_invoice.time') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_invoice.created_by') }}
                                                                                    </th>                                                                    
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_invoice.value') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.OrderIndex.tabs.table.history_invoice.status') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="entry.invoices">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.invoices" :key="index">                                                                 
                                                                                        <td scope="col" class="checkbox-table text-primary" @click="showDetailStockCard(history.id, this.$constant.stockCardInvoice)" v-text="history && history.code ? history.code : ''"></td>                                                                                    
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.created_at ? dt(history.created_at) : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.user_created ? history.user_created.name : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.total ? n(history.total) : ''}}</span></td>                                                                        
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                        
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="6" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>
                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                    
                                                                </div>
                                                            </td>
                                                        </tr>                                            
                                                    </template>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="orderActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="getSaleChannels()" :typeEditAdd="'ADD'"/>
        <ModalOrdersAbort ref="ModalOrdersAbort" @inputData="this.load()"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalDataPayment ref="ModalDataPayment"  @updated="this.load()" />
        
        <!-- Tạo modal cho kết thúc phiếu đặt hàng bán -->
        <ModalEndOrder ref="ModalEndOrder" @updated="this.load()"/>
        <DetailStockCard ref="DetailStockCard" />
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalCreateInvoiceMultiple ref="ModalCreateInvoiceMultiple" :statusAllow="this.arrayStatusAllows ? this.arrayStatusAllows : 0" 
            :statusNotAllow="this.arraySattusNotAllows ? this.arraySattusNotAllows : 0 " 
            @getEditOrder="getEditOrder"
        />
            
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
        <ModalManageFile ref="ModalManageFile" :showFileManager="this.showFileManager" :isMultiple="this.isMultiple" :group="this.group" :type="this.type" @fileClicked="fileClicked"></ModalManageFile>
    </AppPermission>
</template>
<script>
    import {$alert, $get, clone, setArray, getSearchQuery,cloneObject, $post, $getApiShopee, removeVietnameseTones, forEach} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import QSelect from "../../components/QSelect";
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import {explode} from "../../lib/utils";
    import SelectField from "../../components/SelectField";
    import store from "../../store";
    import FieldsExportProduct from "@/assets/exportFields/fieldsExportProduct";
    import moment from "moment";
    import config from "@/config";
    import emitter from "@/lib/emitter";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import SelectSearch from "@/components/SelectSearch.vue";
    import DateRangePicker from "@/components/DateRangePicker";
    import http from '@/services/api';
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import router from "@/router/index";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import ModalOrdersAbort from './tabs/ModalOrdersAbort';
    export const ACTION_ORDER_PROCESSING = 'orderProcessing';    
    import ModalPrintOrder from './ModalPrintOrder';
    import {numberFormat} from "@/lib/utils";    
    import toVietnamWordMixin from "@/toVietnamWordMixin";    
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    import ModalDataPayment from '@/components/ModalDataPayment.vue';
    import ModalEndOrder from './ModalEndOrder';
    import DetailStockCard from '../products/DetailStockCard';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputText from "@/components/forms/InputText";
    import SelectDateTimePicker from "@/components/SelectDateTimePicker";
    import exportMixin from '@/exportMixin';
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    import printOrdersMixin from '@/printOrdersMixin';
    import storeModul from "@/storeModul";
    // import printTransactionMixin from "@/printTransactionMixin";
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    import { mapGetters } from "vuex";    
    import ModalCreateInvoiceMultiple from './tabs/ModalCreateInvoiceMultiple.vue';
    import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
    import mixin from '@/mixin';
    import InputFileManagerInsideTemplate from "@/components/forms/InputFileManagerInsideTemplate";
    import ModalManageFile from "@/components/forms/ModalManageFile";
    
    export default {
        name: "OrderIndex",
        mixins: [
            toVietnamWordMixin, 
            printOrdersMixin,
            // printTransactionMixin,             
            permissionMixin, exportMixin,
            mixin
            ],
        components: {
            SelectField, 
            InputDateRangePicker, 
            TableSortField, 
            Pagination, 
            QSelect, 
            InputQuickSearch, 
            SelectSearch, 
            DateRangePicker, 
            InputSelectSearch, 
            SelectDateTime, 
            ModalAddInfoSaleChannels,
            ModalOrdersAbort,
            ModalPrintOrder,             
            PreviewTemplate,
            ModalSelectPrintTemplate,
            AppPermission,
            ModalDataPayment, 
            ModalEndOrder,
            DetailStockCard,
            InputNumberFormat,
            InputText,
            SelectDateTimePicker,
            ModalSelectSendMailAndZaloTemplate,
            ModalCreateInvoiceMultiple,
            ModalSelectSendZaloGroupTemplate,
            InputFileManagerInsideTemplate,
            ModalManageFile
        },
        data() {
            const query = getSearchQuery();
            const branchIds = query.branch_ids !== undefined ? explode(query.branch_ids) : ([store.state.CurrentBranch.id] || []);

            const globalConfig = store.state.GlobalConfig;
            return {
                tabActive: this.$constant.tabInformation,
                currentBranchId: store.state.CurrentBranch.id,
                filter: {
                    keyword: query.keyword,
                    branch_ids: branchIds,
                    sale_channel_ids: explode(query.sale_channel_ids),
                    purchase_date: query.purchase_date,
                    status: explode(query.status),
                    created_by_ids: explode(query.created_by_ids),
                    delivery_status: explode(query.delivery_status),

                    // time: null,
                    // timePicker: null,
                    product_code_name: '',
                    code_name_phone: '',
                    code_invoice: '',
                    note: '',
                    note_product: '',
                    order_code: '',
                    delivery_code: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                exEntries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth.user,
                paymentMethods: store.state.GlobalConfig.listPaymentMethod,
                listWeightUnit: store.state.GlobalConfig.listWeightUnit,
                listServiceType: store.state.GlobalConfig.listServiceType,
                details: [],
                listTransporterSelfCreated: [],
                listAreaDelivery: [],
                listWards: [],
                listStatusDeliveryAll: [],
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    branches: [],
                    createdBy: '',
                    orderBy: '',
                    saleChannel: '',
                    export: false,
                },              
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                orderBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                createdBy: {
                    filter: globalConfig.users,
                    all: globalConfig.users,
                    search: null,
                },
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                listStatus: [],
                // sortTime: 0,
                loading: false,
                orderActive: null,
                defaultOrder:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                fieldOrderDetail : this.$constant.fieldOrderDetail,
                listEditStatus: [
                    {'id': this.$constant.status_draft_order, 'name': this.$t('message.common.draft')},
                    {'id': this.$constant.status_confirm_order, 'name': this.$t('message.ModalCreateInvoiceMultiple.status_confirm')},
                ],
                users: globalConfig.users,
                saleChannels: globalConfig.saleChannels,
                saleChannelEdit: null,
                soldByEdit: null,
                statusEdit: null,

                //printTemplate
                content: null,
                editorDataClone: null,
                isFilter: false,
                arrayChecked: [], //List id checked
                arrayStatusAllows: 0, // So luong cho phep
                arraySattusNotAllows: 0, // Số lượng không cho phép
                orderIds: [], // ID check các id cho phép tạo hóa đơn
                productIds: [], // ID check các id cho phép tạo hóa đơn
                orderCode: [],
                showFileManager: false,
                isMultiple: false,
                group: '',
                type: '',
            }
        },
        mounted() {
            document.title = this.$t('message.OrderIndex.document_title');            
            const fields = localStorage.getItem('ordersSelectedFields_' + this.user.id);
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['order_code'] = query['code'];
                this.loadDetail();
            }else{
                this.clearFilter();
            }
            this.getTransporterSelfCreated();
            this.getAreaDelivery();
        },
        methods: {
            async updateFilterBranch(values) {
                this.filterQuery.branches = [...values];
                this.filterQuery.page = 1;
                this.isFilter = true;
                await this.load();
            },
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabOrder, this.$constant.typeSellProducts, this.arrayChecked);
            },
            async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = entry && entry.customer ? (entry.customer.contactNumber ?? entry.customer.contactNumber1) : null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabOrder, this.$constant.typeSellProducts, this.arrayChecked, typeSend, phone, true);
            },
            getEditOrder(){                                                
                storeModul.dispatch('orderStore/setOrderIds', this.orderIds);
                this.$router.push({ name: 'EditOrder', query: {
                    type: this.$constant.typeMultiOrder,                    
                    code: this.orderIds,
                }});
            },
            editOrder(code) {
                this.$router.push({ name: 'EditOrder', query: {
                    type: this.$constant.typeOrder,                    
                    order: code,                    
                }});
            },
            updateOrder(code){
                this.$router.push({ name: 'EditOrder', query: {
                    type: this.$constant.typeOrder,                    
                    order: code,
                    isUpdate: true,                    
                }});
            },
            createInvoice(code){
                this.$router.push({ name: 'EditOrder', query: {
                    type: this.$constant.typeOrder,                    
                    order: code,
                    createInvoice: true,                    
                }});
            },
            createInvoicesMultiple(){
                if(this.arrayChecked.length > 10) {
                    $alert({
                        code: 422,
                        message: this.$t('message.common.maxLengthProcessOrder'),
                        });
                    return;
                } 
                if(this.arraySattusNotAllows > 0){                    
                    this.$refs.ModalCreateInvoiceMultiple.show();
                }else{                                      
                   this.getEditOrder();                    
                }
            },
            async getTransporterSelfCreated(){
                const res = await $get('/v1/transporters/get-list-transporter-self-created');
                if(res && res.result){
                    this.listTransporterSelfCreated = res.result;
                }
            },
            async getAreaDelivery(){
                const res = await $get('/v1/deliveries/get-area-delivery');
                if(res && res.result){
                    this.listAreaDelivery = res.result;
                }
            },
            async changeDistrict(entry) {
                const res = await $get('/v1/locations/wards', {districtId: entry.districtId});
                this.listWards = res.data;
            },
            showModalDataPayment(id, method, input){
             this.$refs.ModalDataPayment.show(id, method, input);
            },   
            showContent(tab){            
                switch (tab) {
                    case 0:                    
                        this.tabActive = this.$constant.tabInformation;
                        break;
                    case 1:                    
                        this.tabActive = this.$constant.tabHistoryPayment;                    
                        break;
                    case 2:                    
                        this.tabActive = this.$constant.tabHistoryImport;
                        break;  
                }   
            },  
            async getTemplatePrint(entry) {  
                entry.typePrint = this.$constant.TypePrintOrder;
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabOrder});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){
                this.content = data.content;
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {
                    await this.print(data.dataPrint);          
                }    
            },
            async printMultiple() {
                this.editorDataClone = '';
                const res = await $post('/v1/orders/data', {
                    listIDs: this.arrayChecked,
                });
                if (res.result && res.result.length) { 
                    await this.getTemplatePrint(res.result);
                }
            },
            async startPrintMultiple(orders) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < orders.length; index++) {
                    let order = orders[index];
                    order.typePrint = this.$constant.TypePrintOrder;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint, order.typePrint);
                    if (index != orders.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },           
            async clearFilter() {
                await this.$router.replace({
                    path: '/orders/index',
                    query: {}
                });
                
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [1,2,4,3];
                    }else if(key == 'export'){
                        this.filterQuery[key] = false;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branches = [store.state.CurrentBranch.id];
                this.orderActive = null;
                this.isFilter = false;
                this.arrayStatusAllows = 0;
                this.arraySattusNotAllows = 0;
                this.orderIds = [];
                await this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async sort(field, direction) {                
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/orders/data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});         
                if (res.result.code !== 200) {
                    $alert(res.status);
                    return;
                }                                
                if(res.result.data.exEntries.length > 0){
                    //Phục vụ cho export tổng quang không qua phân trang
                    this.exEntries = JSON.parse(res.result.data.exEntries)?? null;  
                }
                
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;                    
                this.entries.forEach(item => {                    
                  item.historyPayment.sort(function(a, b) { return new Date(b.createdAt) - new Date(a.createdAt) });
                 });   
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                
                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                if(res.result.data.listStatusDeliveryAll){
                    this.listStatusDeliveryAll = res.result.data.listStatusDeliveryAll;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },            
            async loadDetail(){
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.filterQuery.branches = [this.currentBranchId];
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailOrder(entry.id, entry);
                });
            },
            async getSaleChannels(){
                const res = await $post('/v1/configs/index', {} , false);
                if (res.code === 200) {
                    this.saleChannel.all = this.saleChannel.filter = store.state.GlobalConfig.saleChannels = res.data.saleChannels;
                }
            },
            async exportExcel() {
                if (!this.excelData.fields) {
                    this.excelData.fields = FieldsExportProduct;
                }
                const date = moment().format('HHmm_DDMMYYYY');
                this.excelData.fileName = 'danh-sach-hang-hoa-' + date + 'xls';

                const params = this.filter;
                params._action = 'excel';

                const res = await $get('/v1/products/data', params);

                if (res.code === 200) {
                    return res.data;
                } 
                return false;
            },
            async store(items){    
                let data = 
                    {
                        "id": 1,
                        "name": "Đặt hàng 1",
                        "type": "order",
                        "items": items
                    } 
                // Đồng bộ đơn hàng
                const res = await $post('/v1/shopee/shopeeOrderSync', {'data' : data});
                // Đồng bộ trạng thái hóa đơn
                // const oRes = await $post('/v1/shopee/shopeeInvoiceSync', {'data' : data});


            },              
            async getAccessToken(){       
               
                let v1Param = {
                    'path': '/api/v2/auth/token/get',                    
                    'partnerId' : config.ApiShopee.v2PartnerId,
                    // 'partnerKey' : partner_key,                    
                    'redirectUrl': config.App.redirectUrl
                }
                const res = await $get('/v1/shopee/sign',v1Param);               
                let sign  = res.data.sign;
                let timestamp = res.data.timestamp;

                let v2Param = {
                    'partnerId':  config.ApiShopee.v2PartnerId, 
                    'redirect': config.App.redirectUrl,
                    'timestamp':  timestamp,
                    'sign':  sign,                                      
                };
                const v2Res = await $getApiShopee('/api/v2/shop/auth_partner',v2Param); 
                let code = v2Res.data.code;

                let tokenParam =  {
                    'code' : code,
                    'partnerId': config.ApiShopee.v2PartnerId
                }      
                
                const tokenRes = await $getApiShopee('/api/v2/auth/token/get',tokenParam).then();                   
                return tokenRes.data.access_token;
            },
            async dataOrderSynch() {    
                let partner_id = config.ApiShopee.v2PartnerId;
                let partner_key = config.ApiShopee.v2PartnerKey;
                let v1Param = {
                    'path': '/api/v2/auth/token/get',                    
                    'partnerId' : partner_id,
                    'partnerKey' : partner_key,                    
                    'redirectUrl': config.App.redirectUrl
                }
                const res = await $get('/v1/shopee/sign',v1Param);               
                let sign  = res.data.sign;
                let timestamp = res.data.timestamp;

                let v2Param = {
                    'partnerId':  partner_id, 
                    'redirect': config.App.redirectUrl,
                    'timestamp':  timestamp,
                    'sign':  sign,                                      
                };
                const v2Res = await $getApiShopee('/api/v2/shop/auth_partner',v2Param); 
                let code = v2Res.data.code;
                let shop_id = v2Res.data.shop_id;
                let tokenParam =  {
                    'code' : code,
                    'partnerId': config.ApiShopee.v2PartnerId
                }      
                
                const tokenRes = await $getApiShopee('/api/v2/auth/token/get',tokenParam).then();   
                let access_token = tokenRes.data.access_token;
                let shopeeParam = {
                    'access_token':access_token,
                    'cursor':"",
                    'order_status':'READY_TO_SHIP',
                    'page_size':20,
                    'partner_id':partner_id,
                    'response_optional_fields':'READY_TO_SHIP',
                    'shop_id':shop_id,
                    'sign':sign,
                    'time_from':1607235072,
                    'time_range_field':1607235072,
                    'time_to':1607235072,
                    'timestamp':timestamp,
                }
                
                const shopee = await $getApiShopee('/api/v2/order/get_order_list',shopeeParam);                   
                let store = shopee.data.data;   
                
               
                // Hàm để lưu đơn hàng
                this.store(cloneObject(store));                                        
            },    
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },           
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },  
            totalQuantityInvoice(invoiceItem){
                let totalQuantity = 0;
                invoiceItem.forEach(item => {
                    totalQuantity += item.quantity;
                });                
                return totalQuantity;
            },
            setStatusDelivery(entry){
                let status = false;
                if(entry.statusOrigin == this.$constant.orderStatusDelivery){
                     this.totalQuantityInvoice(entry.invoice_items);
                }
                return status;
            },
            lstOrderIds(entry){                               
                // let ivTotalQuantity = this.setStatusDelivery(entry);                
                let arr = this.orderIds;                
                let arrCheck = this.arrayChecked;        
                if(!this.$constant.statusOrderNotAllow.includes(entry.statusOrigin)){                    
                    if(arr.includes(entry.id)){ // Nếu tồn tại thì ...
                        arr = arr.filter(item => item !== entry.id)                        
                        this.arrayStatusAllows -= 1;                                            
                    }else{ // Chưa tồn tại push
                        arr.push(entry.id)
                        this.arrayStatusAllows += 1;
                    }
                    this.orderIds = arr;                                          
                }else {  
                    if(arrCheck.includes(entry.id)){                   
                        arrCheck = arrCheck.filter(item => item !== entry.id)          
                        this.arraySattusNotAllows += 1;                                      
                    }else{                                                                            
                        this.arraySattusNotAllows -= 1;
                    }                   
                }                  
            },           
            countChecked(entry){
                let id = entry.id;
                let arr = this.arrayChecked;
                if(arr.includes(id)){                   
                    arr = arr.filter(item => item !== id)
                }else{                    
                    arr.push(id)
                }
                this.arrayChecked = arr; 
                // Lấy danh sach order code
                this.lstOrderIds(entry);
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },            
            removeChecked(){
                this.arrayChecked = [];                
                // this.orderIds = [];
                this.arrayStatusAllows = 0;
                this.arraySattusNotAllows = 0;
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){                    
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            // this.countChecked(entry.id);
                            this.countChecked(entry);
                        }
                    })
                }else{                    
                    this.entries.forEach(entry => {
                        // this.countChecked(entry.id);
                        this.countChecked(entry);
                    })
                }
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async print(entry) {
                entry.typePrint = this.$constant.TypePrintOrder;
                let dataPrint = await this.dataPrint(entry);  
                this.editorDataClone = await this.renderData(dataPrint, entry.typePrint);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            async getTemplate() {      
                const res = await $get(`/v1/print-templates/get-content-print-template`,{action: 'TabOrder'});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.content = res.result.content; 
                    }
                }                                
            },
            getOSByID(entries){               
                return entries;
            },  
            async exOverview() {
                emitter.emit("onLoading");
                // this.filterQuery.export = true;
                // await this.load();
                // let id = this.getOSByID(this.exEntries);                                           
                const res = await http.download('/v1/orders/exOverview', {
                    fields: this.fields, 
                    // id:id, 
                    branchID: this.currentBranchId,
                    ...this.filterQuery
                });             
                this.returnDataExport(res);
                // this.filterQuery.export = false;          
            },    
            async exSelectOverview() {    
                emitter.emit("onLoading");                
                let id = this.arrayChecked;                   
                const res = await http.download('/v1/orders/exOverview', {fields:this.fields, id:id, branchID: this.currentBranchId});
                this.returnDataExport(res);     
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");
                let id = this.arrayChecked;                
                const res = await http.download('/v1/orders/exDetails', {id: id, branchID: this.currentBranchId});
                this.returnDataExport(res);       
            },
            async exDetails() {
                emitter.emit("onLoading");
                // this.filterQuery.export = true;
                // await this.load();                                
                // let id = this.getOSByID(this.exEntries);                
                const res = await http.download('/v1/orders/exDetails', {
                    // id:id, 
                    branchID: this.currentBranchId,
                    ...this.filterQuery
                });
                this.returnDataExport(res);
            },
            async exOrdersById(id){
                const res = await http.download('/v1/orders/exById/' + id, {...this.fields, branchID: this.currentBranchId});                
                this.returnDataExport(res);    
            },
            copyOrdersById(code){                  
                 this.$router.push({
                    name: 'EditOrder', query: {
                        type: this.$constant.typeOrder,
                        order: code,
                        isCoppy: 'copy',
                    }
                 });                
            },  
            ShowModalOrdersAbort(entry){
                this.$refs.ModalOrdersAbort.show(entry);                
            },  
            //------------------------------------------------------------      
            detailOrder(id, entry){
                if (id === this.orderActive) {
                    this.orderActive = null;
                } else {
                    this.orderActive = id;
                    if(entry.delivery && !entry.delivery.transporterIntegration && entry.delivery.customer_address){
                        this.changeDistrict(entry.delivery.customer_address);
                    }
                }
                this.defaultOrder.filter = this.defaultOrder.all = entry.order_items;
                this.setPaginate();
                this.tabActive = this.$constant.tabInformation;
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultOrder.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultOrder.filter.length;
            },
            onInputFilterOrderDetail(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultOrder.searchCode.length == 0 && this.defaultOrder.searchName.length == 0){
                    this.defaultOrder.filter = this.defaultOrder.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultOrder.all.length; index++) {
                            if (this.defaultOrder.all[index] != null && removeVietnameseTones(this.defaultOrder.all[index].productCode.toLowerCase()).indexOf(removeVietnameseTones(this.defaultOrder.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultOrder.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultOrder.all.length; index++) {
                            if (this.defaultOrder.all[index] != null && removeVietnameseTones(this.defaultOrder.all[index].productName.toLowerCase()).indexOf(removeVietnameseTones(this.defaultOrder.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultOrder.all[index]);
                            }
                        }
                        break;
                }
                this.defaultOrder.filter = arr;
                this.setPaginate();
            },
            async quickSave(entry){
                if(entry.ExpectedDateOfTransfer && moment(entry.ExpectedDateOfTransfer).format('YYYY/MM/DD') < moment().format('YYYY/MM/DD')){
                    $alert({
                        code: 422,
                        message: this.$t('message.common.inValidExpectedDateOfTransfer'),
                    });
                }else if(entry.description?.length > 255){
                    $alert({
                        code: 422,
                        message: this.$t('message.CashBookIndex.alerts.validate_error_note'),
                    });
                }else{
                    let dataDelivery = {};
                    if(entry.delivery && !entry.delivery.transporterIntegration){
                        dataDelivery = {
                            "id": entry.delivery.id ?? null,
                            "code": entry.delivery.code ?? null,
                            "note": entry.delivery.note ?? null,
                            "status": entry.delivery.status ?? null,
                            "weight": entry.delivery.weight ?? null,
                            "weightUnit": entry.delivery.weightUnit ?? null,
                            "hight": entry.delivery.hight ?? null,
                            "long": entry.delivery.long ?? null,
                            "width": entry.delivery.width ?? null,
                            "transporterId": entry.delivery.transporterId ?? null,
                            "serviceTypeId": entry.delivery.serviceTypeId ?? null,
                            "deliveryTime": entry.delivery.deliveryTime ?? null,
                            "usingPriceCod": entry.delivery.usingPriceCod ?? null,
                            "price": entry.delivery.price ?? null,
                            "receiver_info": entry.delivery.customer_address ?? null,
                        }
                    }
                    let params = {
                        id: entry.id, 
                        saleChannelId: this.saleChannelEdit != null ? this.saleChannelEdit : entry.saleChannelId, 
                        description: entry.description, 
                        ExpectedDateOfTransfer: entry.ExpectedDateOfTransfer, 
                        soldById: this.soldByEdit != null ? this.soldByEdit : entry.soldById,
                        status: this.statusEdit != null ? this.statusEdit : entry.statusOrigin,
                        infoUpdateDelivery: dataDelivery,
                        branchIdNew: entry.branchIdNew,
                        image: entry.image ?? [],
                    }
                    
                    const res = await $post('/v1/orders/quick-save', params);
                    if(res.result && !res.result.status && res.result.message){
                        $alert({code: 500, message: res.result.message});
                    }else if(res.result.status == true){
                        $alert({
                            code: 200,
                            message: this.$t('message.common.updateOrderSuccessfully', {code: entry.code}),
                        });
                        this.load();
                    }else{
                        $alert({
                            code: 403,
                            message: this.$t('message.common.updateOrderFailed', {code: entry.code}),
                        });
                    }
                }
            },
            async endOrder(id) {
                if (id) {
                    this.$refs.ModalEndOrder.show(id);
                }  
            },
            setSaleChannel(saleChannelId){
                this.saleChannelEdit = saleChannelId;
            },
            setSoldBy(soldById){
                this.soldByEdit = soldById;
            },
            setStatus(status){
                this.statusEdit = status;
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            async createOrders(){
                await this.$router.replace({
                    path: '/sale/create-order',
                    query: {
                        type: 'Order',
                    }
                });
            },
            linkToDelivery(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'DeliveryIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToCustomer(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'CustomerIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },

            async doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            showModalAddInfoSaleChannels(){
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            showDetailStockCard(id, type){
                this.$refs.DetailStockCard.show(id, type);
            },
            showModalManageFile(props){
                this.showFileManager = props.showFileManager ?? false;
                this.isMultiple = props.isMultiple ?? false;
                this.group = props.group ?? '';
                this.type = props.type ?? '';
                this.$refs.ModalManageFile.show();
            },
            fileClicked(file){
                let changeFile = this.$refs['InputFileManagerInsideTemplate' + this.orderActive][0];
                if(changeFile){
                    changeFile.fileClicked(file);
                }
            }
        },
        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            }),
        },
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.noteStyle {
    width: 100% !important;
    height: 80px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#orderCode {
    width:20px !important;
}
</style>