<template>
    <div class="row">
        <ActionBar back-url="/categories/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Danh mục hàng hóa</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên danh mục"/>


                        </div>
                      <div class="col-lg-12">
                        <div class="form-group">
                          <label>Danh mục cha</label>
                          <q-select v-model="entry.parentId"
                                    datasource="categories"
                                    placeholder="Chọn danh mục cha"
                                    :multiple="false"
                                    @click="renderData"
                                    />
                        </div>


                      </div>
                        <div class="col-lg-12">
                            <InputSwitchButton v-model="entry.isActive"
                                               :errors="errors.isActive"
                                               label="Trạng thái"/>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import ActionBar from "@/components/ActionBar";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import InputSwitchButton from "../../components/forms/InputSwitchButton";
import QSelect from "@/components/QSelect";

export default {
    name: "CategoryForm",
    components: {ActionBar, InputText, InputSwitchButton, QSelect},
    data() {
        return {
            isDataLoaded: false,
            entry: {},
            errors: {}
        }
    },
    mounted() {
        document.title = 'Danh mục sản phẩm | OmiPos';
        if (this.$route.query.id) {
            this.load();
        } else {
            this.isDataLoaded = true;
        }

    },
    methods: {
        renderData(){
            this.load();
        },
        async load() {
            const res = await $get('/v1/categories/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.isDataLoaded = true;

        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/categories/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        }
    }
}
</script>

<style scoped>

</style>
