
<template>
    <div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <InputText v-model="entry.code"
                           label="Mã trả hàng" :readonly="true"/>
                <InputDatePicker v-model="entry.returnDate"
                                 label="Thời gian" :readonly="true"/>
                <div class="form-group">

                    <label>{{$t('message.headers.customers')}}</label>
                    <QSelect v-model="entry.customerId" datasource="customers"/>
                </div>
                <InputText v-model="entry.invoiceId"
                           label="Mã hóa đơn" :readonly="true"/>
                <div class="form-group ">
                    <label>{{$t('message.common.created_by')}}</label>
                    <QSelect v-model="entry.receivedById" datasource="users"/>
                </div>

            </div>
            <div class="col-lg-4 col-sm-6">
                <div class="form-group">
                    <label>{{$t('message.common.branch')}}</label>
                    <QSelect v-model="entry.branchId" datasource="branches"/>
                </div>
                <div class="form-group">
                    <label>{{$t('message.common.status')}}: </label>
                    <div class="form-group mt-2">
                        <span class="status" style="font-weight: bold" v-text="entry.status === 1 ? 'Đã trả' : 'Đã hủy'"></span>
                    </div>

                </div>
                <InputText v-model="entry.soldByName"
                           label="Người bán" :readonly="true"/>

                <div class="form-group ">
                    <label>Người nhận trả</label>
                    <QSelect v-model="entry.receivedById" datasource="users"/>
                </div>
                <div class="form-group ">
                    <label>Kênh bán</label>
                    <QSelect v-model="entry.saleChannelId" datasource="saleChannels"/>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <InputTextArea v-model="entry.description" :label="$t('message.ProductIndex.note')"></InputTextArea>
            </div>
        </div>
        <div class="row mt-3">
            <table class="table table-index table-striped">
                <thead>
                <tr>
                    <th scope="col">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                    <th scope="col">{{$t('message.DetailOrdersCard.prodName')}}</th>
                    <th scope="col" style="text-align: center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                    <th scope="col" >{{$t('message.ProductIndex.price')}}</th>
                    <!--                    <th scope="col">Giá nhập lại</th>-->
                    <th scope="col">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr style="font-weight: bold" v-for="item in entry.return_details" :key="item.id">
                    <td v-text="item.productCode"></td>
                    <td class="product-name">
                        <span v-text="item.productName" :title="item.productName"></span>
                    </td>
                    <td style="text-align: center" v-text="item.quantity"> </td>
                    <td v-text="n(intVal(item.price) * intVal(item.quantity))"></td>
                    <td v-text="n(item.price)"></td>
                </tr>
                </tbody>
            </table>

        </div>
        <div class="row">
            <div class="col-3 ml-auto">
                <div class="row" style="margin-bottom: 60px">
                    <div class="col-8">{{$t('message.OrderSupplierIndex.total_quantity')}}</div>
                    <div class="col-4 text-bold" v-text="quantity"></div>
                    <div class="col-8">{{$t('message.OrderSupplierIndex.total_origin')}}</div>
                    <div class="col-4 text-bold" v-text="n(entry.returnTotal)"></div>
                    <div class="col-8">Giảm giá phiếu trả</div>
                    <div class="col-4 text-bold" v-text="n(entry.returnDiscount)"></div>
                    <div class="col-8">Tổng trả</div>
                    <div class="col-4 text-bold" v-text="n(intVal(entry.returnTotal) - intVal(entry.returnDiscount))"></div>
                    <div class="col-8">Đã trả khách</div>
                    <div class="col-4 text-bold" v-text="n(entry.totalPayment)"></div>
                </div>
            </div>
        </div>
        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/returns/index" class="btn btn-default">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        Quay lại
                    </router-link>
                    <button v-if="!isCancel" class="btn btn-save" type="button" @click="save()">
                        <i class="fa fa-save"/>
                        Lưu lại
                    </button>
                    <button class="btn btn-save" type="button">
                        <i class="fa fa-print"/>
                        In phiếu
                    </button>
                    <button class="btn btn-save" type="button">
                        <i class="fas fa-file-export"></i>
                        Xuất file
                    </button>
                    <button v-if="!isCancel" class="btn btn-danger" type="button" @click="cancel()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Hủy bỏ
                    </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {$alert, $get, $post, intVal, scrollToElement} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputText from "@/components/forms/InputText";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QSelect from "@/components/QSelect";
import InputTextArea from "@/components/forms/InputTextArea";

export default {
    name: "ReturnDetail",
    components: {InputTextArea, InputText, InputDatePicker, QSelect},
    mounted() {
        document.title = 'Chi tiết trả hàng | Omipos';
        if (this.entry.return_details) {
            this.calQuantity(this.entry.return_details);
        }
    },
    data() {
        return {
            fields: [],
            entry: {},
            quantity: 0,
            receiveQuantity: 0,
            totalProduct: 0,
            totalSendValue: 0,
            totalReceiveValue: 0
        }
    },
    methods: {
        intVal,
        calQuantity(products) {
            let quantity = 0;
            let receiveQuantity = 0;
            let totalSendValue = 0;
            let totalReceiveValue = 0;
            if (products.length > 0) {
                this.totalProduct = products.length;
                products.forEach(item => {
                    quantity += item.quantity;
                    receiveQuantity += item.receiveQuantity
                    totalSendValue += parseInt(item.sendPrice);
                    totalReceiveValue += parseInt(item.receivePrice);

                })
            }

            this.totalSendValue = totalSendValue;
            this.totalReceiveValue = totalReceiveValue;
            this.quantity = quantity;
            this.receiveQuantity = receiveQuantity;
        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/returns/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        },
        async cancel() {
            if (!window.confirm('Bạn có chắc chắn muốn hủy phiếu trả hàng ' + this.entry.code + ' này không?')) {
                return false;
            }

            const res = await $post('/v1/returns/saveStatus', {
                id: this.entry.id,
                action: 'cancel'
            });

            $alert(res);

            if (res.code === 200) {
                window.location.reload();
            }
        }
    },
    computed: {
        isCancel() {
            return parseInt(this.entry.status) === 2;
        }
    }
}
</script>
