<template>
    <div class="row">
        <ActionBar  :back-url="'/purchase-orders/index?action=' + entry.actionType"
                    label="Hoàn thành phiếu"
                   :buttons="buttons"
                   :hide-button-save="true"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>{{ pageTitle }}</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-8">
                    <div class="form-group">
                        <label>Thêm sản phẩm</label>
                        <QSelect v-model="searchModel"
                                 name="AddProduct"
                                 v-slot="slotProps"
                                 :used-slot="true"
                                 @onselected="onSelected"
                                 datasource="products" placeholder="Tìm kiếm">
                                <div>
                                    <strong v-text="slotProps.item.orignalName"></strong>
                                    <div class="q-slot">
                                        <div class="slot-row">
                                            {{slotProps.item.code}}
                                        </div>
                                        <div class="slot-row">
                                            Giá: {{slotProps.item.product_units.length > 0 ? n(slotProps.item.product_units[0].price) : ''}}
                                        </div>

                                    <div/>
                                    <div class="q-slot">
                                        <div class="slot-row">
                                            Tồn TT: {{slotProps.item.inventory.onHand}}
                                        </div>
                                        <div class="slot-row">
                                            Tồn HT: {{slotProps.item.inventory.onHand}}
                                        </div>
                                    </div>

                                </div>
                            </div>

                        </QSelect>
                    </div>
                    <div class="table-wrapper">
                        <table class="table table-index table-striped" >
                            <thead>
                            <tr class="text-center">
                                <td width="1%"></td>
                                <th width="2%">{{$t('message.common.STT')}}</th>
                                <th width="10%">Mã Hàng</th>
                                <th class="text-left" width="15%">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                <th style="width: 10%">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                                <th  style="width: 10%" v-if="isReturnAction">{{$t('message.common.returnQuantity')}}</th>
                                <th style="width: 10%" class="text-center" v-if="!isReturnAction">
                                    Số lượng
                                </th>
                                <th style="width: 15%">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                                <th>{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                                <th style="width: 15%" class="text-center">Thành Tiền</th>
                                <th class="text-center"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product,index) in details" :key="index">
                                <tr v-if="!product.deleted">
                                    <td class="text-center">
                                        <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                            <i class="fas fa-trash"/>
                                        </a>
                                    </td>
                                    <td class="text-center">{{ index + 1 }}</td>
                                    <td v-text="product?.productCode || product.code"></td>
                                    <td>
                                        <a target="_blank" v-text="product?.productName || product.name"
                                           :href="'/products/form?id=' + product.productId">

                                        </a>
                                    </td>
                                    <td class="pt-3">
                                        <InputSelect
                                            option-label="unitName"
                                            option-value="id"
                                            :readonly="isReturnAction && entry.refCode"
                                            @update:modelValue="(value) => onUnitChange(value, product)"
                                            :errors="detailErrors[index]"
                                            :defaultOption="{id: 0, unitName: 'Chọn đơn vị'}"
                                            v-model="product.productUnitId"
                                            :options="product.productUnits"/>
                                    </td>
                                    <td class="text-center" v-text="n(product.quantity)">

                                    </td>
                                    <td :colspan="3" >
                                    </td>

                                    <td>
                                        <a @click="addProductBatch(product)" class="btn btn-sm btn-save">
                                            <i class="fa fa-plus"/>
                                        </a>
                                    </td>
                                </tr>
                                <template v-for="(batch,bIndex) in product.productBatches" :key="batch.id">
                                    <tr v-if="!batch.deleted" :data-inventory-id="batch.inventoryId">
                                        <td colspan="5" style="width: 200px">
                                            <QSelect
                                                v-model="batch.productBatchId"
                                                :quickadd="!isReturnAction"
                                                :searchParams="{productId: batch.productId, refCode: entry.refCode, actionType: this.isReturnAction ? 'return' : 'import'}"
                                                default-empty-label="Chưa có lô nào"
                                                @onselected="(option) => productBatchChange(option, batch, product)"
                                                datasource="inventoriesProductBatches"
                                                :quickaddhandler="(callback) => showModalBatchForm(product, batch, callback)"
                                                name="ProductBatch" placeholder="Nhập lô, hạn sử dụng"/>
                                            <ErrorLabel :errors="productBatchErrors[bIndex]"/>
                                        </td>
                                        <td v-if="isReturnAction">
                                            <InputNumber :min="1"
                                                         :errors="quantityErrors[bIndex]"
                                                         :max="batch.inventory ? Math.min(
                                                             batch.inventory.onHand/product.currentConversionValue,
                                                             batch.inventory.onHandAll/product.currentConversionValue,
                                                         ): 0"
                                                         v-model="batch.quantity" @input="onPriceUpdated"/>

                                            <template v-if="batch.inventory">
                                                / <span v-text="batch.inventory.onHand/product.currentConversionValue"></span>
                                                (<span v-text="batch.inventory.onHandAll/product.currentConversionValue"></span>)
                                            </template>

                                        </td>

                                        <td v-if="!isReturnAction">
                                            <InputNumber :min="1"
                                                         :errors="quantityErrors[bIndex]"
                                                         v-model="batch.quantity" @input="() => onPriceUpdated('a')"/>
                                        </td>
                                        <td>
                                            <InputNumberFormat v-model="batch.price" @input="() => onPriceUpdated('b')"/>
                                            <label class="label label-warning" v-if="batch.price > product.currentSalePrice">
                                                Lưu ý: Giá nhập đang lớn hơn giá bán
                                            </label>
                                        </td>
                                        <td>
                                            <InputDiscount
                                                :noFlex="true"
                                                v-model="batch.discountObject" @update:modelValue="() => onPriceUpdated('c')"/>
                                        </td>
                                        <td >
                                            <div class="div__center"
                                                 v-text="n(batch.total)"></div>
                                        </td>
                                        <td class="text-center">
                                            <a class="btn-remove" @click.prevent="removeDetailBatch(batch)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                    </tr>
                                </template>

                            </template>

                            </tbody>
                        </table>
                    </div>

                    <div class="form-group purchase-form__import" v-if="entry.status === PurchaseOrderStatus.DRAFT">
                        <div class="text-center">
                            <div class="mb-2">
                                Thêm sản phẩm từ file excel
                                <a target="_blank" :href="excelSampleFile">(Tải file mẫu)</a>
                            </div>
                            <InputFileManager v-model="excelImportModel" @input="onImportExcel" type="excel" group="PurchaseOrderImport"/>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Người tạo
                                </div>
                                <div class="purchase-form__input">
                                    <QSelect name="UserEmails"
                                             v-model="entry.createdBy"
                                             datasource="UserEmails"/>
                                    <ErrorLabel :errors="errors.createdBy"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Ngày tạo
                                </div>
                                <div class="purchase-form__input">
                                    <InputDatePicker v-model="entry.createdAt" placeholder="Ngày tạo"/>
                                </div>
                            </div>

                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Nhà cung cấp
                                </div>
                                <div class="purchase-form__input">
                                    <QSelect name="suppliers" datasource="suppliers" v-model="entry.supplierId"/>
                                    <ErrorLabel :errors="errors.supplierId"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    {{isReturnAction ? 'Mã phiếu trả' : 'Mã phiếu nhập'}}
                                </div>
                                <div class="purchase-form__input">
                                    <InputText v-model="entry.code" :placeholder="$t('message.common.autocompleteCode')"/>
                                </div>
                            </div>
                            <div class="purchase-form" v-if="!isReturnAction">
                                <div class="purchase-form__label">
                                    Mã đặt hàng nhập
                                </div>
                                <div class="purchase-form__input">
                                    <InputText v-model="entry.orderSupplierCode" placeholder="Mã đặt hàng nhập"/>
                                </div>
                            </div>
                            <div class="purchase-form" v-else>
                                <div class="purchase-form__label">
                                    Mã nhập hàng
                                </div>
                                <div class="purchase-form__input">
                                    <InputText v-model="entry.refCode" :readonly="true" placeholder="Mã nhập hàng"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Trạng Thái
                                </div>
                                <div class="purchase-form__input">
                                    <InputSelect :readonly="true" name="status" v-model="entry.status" :options="statusOptions"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Tổng tiền hàng
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.subTotal"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Giảm giá
                                </div>
                                <div class="purchase-form__input">
                                    <InputDiscount v-model="entry.discountObject" @update:modelValue="onDiscountChanged"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    {{entry.actionType === 'return' ? 'Cần trả lại' : 'Cần trả nhà cung cấp'}}
                                </div>
                                <div class="purchase-form__input">
                                    <InputNumberFormat v-model="entry.total"/>
                                </div>
                            </div>
                            <div class="purchase-form">
                                <div class="purchase-form__label">
                                    Ghi chú
                                </div>
                                <div class="purchase-form__input">
                                    <InputTextArea v-model="entry.description" :placeholder="$t('message.ProductIndex.note')"/>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div>
                <ModalProductBatchForm v-model="productBatchModel" ref="modal"/>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "../../components/forms/InputText";
import ActionBar from "../../components/ActionBar";
import {$post, $alert, $get, scrollToElement, setArray} from "@/lib/utils";
import InputSwitchButton from "../../components/forms/InputSwitchButton";
import QSelect from "../../components/QSelect";
import InputFileManager from "../../components/forms/InputFileManager";
import InputDatePicker from "../../components/forms/InputDatePicker";
import InputSelect from "../../components/forms/InputSelect";
import InputNumber from "../../components/forms/InputNumber";
import InputNumberFormat from "../../components/forms/InputNumberFormat";
import InputTextArea from "../../components/forms/InputTextArea";
import ModalProductBatchForm from "./ModalProductBatchForm";
import {PurchaseOrderStatus, PurchaseOrderType} from '../../models/PurchaseOrder';
import ErrorLabel from "../../components/ErrorLabel";
import store from "../../store";
import moment from "moment";
import config from "../../config";
// import toastr from 'toastr';
import InputDiscount from "../../components/forms/InputDiscount";
// import {DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_VND, getDiscountAmount} from "../../lib/utils";
import {DISCOUNT_TYPE_VND, getDiscountAmount} from "../../lib/utils";

export default {
    name: "PurchaseOrderForm",
    components: {
        InputDiscount,
        ErrorLabel,
        ModalProductBatchForm,
        InputTextArea,
        InputNumber,
        InputNumberFormat,
        InputSelect, InputDatePicker, InputFileManager, QSelect, ActionBar, InputText, InputSwitchButton
    },
    data() {

        return {
            pageTitle: '',
            isReturnAction: false,
            PurchaseOrderStatus,
            excelSampleFile: config.ExcelSamples.PurchaseOrderImport,
            searchModel: null,
            excelImportModel: null,
            buttons: [],
            productBatchModel: {},
            isDataLoaded: false,
            statusOptions: [
                {id: PurchaseOrderStatus.DRAFT, name: 'Phiếu tạm'},
                {id: PurchaseOrderStatus.IMPORTED, name: 'Đã nhập hàng'},
                {id: PurchaseOrderStatus.CANCELLED, name: 'Đã hủy'},
                {id: PurchaseOrderStatus.RETURNTED, name: 'Đã trả hàng'},
            ],
            details: [],
            entry: {
                status: PurchaseOrderStatus.DRAFT,
                createdBy: store.state.Auth.user.email,
                branchId: store.state.CurrentBranch.id,
                createdAt: moment().format('YYYY-MM-DD'),
                readonly: 0,
                discountObject: {
                    discountValue: 0,
                    discountType: DISCOUNT_TYPE_VND
                },
            },
            errors: {},
            productBatchErrors: {},
            quantityErrors: {},
            detailErrors: {},
        }
    },
    mounted() {
       
        if (JSON.parse(localStorage.getItem('details'))!==null) this.details = JSON.parse(localStorage.getItem('details'));
        
        const {query} = this.$route;
        this.isReturnAction = query.action === 'return';

        if ( this.isReturnAction) {
            this.entry.actionType = 'return';
            if (query.ref_code || query.code) {
                this.load(query, true).then(this.initButtons);
            } else {
                this.isDataLoaded = true;
                this.initButtons();
                document.title = 'Tạo phiếu trả hàng nhập | OmiPos';
                this.pageTitle = 'Tạo phiếu trả hàng nhập';
            }

        } else {
            this.entry.actionType = 'import';
            if (query.dhn_ref_code) {
                document.title = 'Tạo phiếu nhập | OmiPos';
                this.loadOrderSupplier(query.dhn_ref_code)
            } else {
                if (query.code) {
                    this.load(query).then(this.initButtons);
                } else {
                    this.isDataLoaded = true;
                    document.title = 'Tạo phiếu nhập | OmiPos';
                    this.pageTitle = 'Tạo phiếu nhập';
                    this.initButtons();
                }
            }

        }
    },
    methods: {
        onUnitChange(value, product) {
            const option = product.productUnits.find(o => o.id === value);
            if (option) {
                product.currentConversionValue = option.conversionValue;
                product.currentSalePrice = option.price;
                product.productBatches.forEach(b => {
                    b.quantity = 0;
                })
            }

        },
        initButtons() {
            let buttons = [
                {
                    class: 'btn btn-default',
                    label: 'Lưu tạm',
                    action: this.saveDraft,
                    showIf: parseInt(this.entry.readonly) === 0
                        && this.entry.status === PurchaseOrderStatus.DRAFT
                },
                {
                    class: 'btn btn-primary',
                    icon: 'fas fa-save',
                    label: 'Hoàn thành phiếu',
                    showIf: parseInt(this.entry.readonly) === 0 && this.entry.status !== PurchaseOrderStatus.CANCELLED,
                    action: () => {
                        this.save(false);
                    }
                },
                {
                    class: 'btn btn-default ml-1',
                    label: 'Xem phiếu',
                    icon: 'fas fa-external',
                    showIf: !!this.entry.code,
                    href: '/purchase-orders/detail?code=' + this.entry.code
                }
            ];

            this.buttons = buttons;

        },
        removeDetailProduct(product) {
            
            if (!confirm('Xóa sản phẩm: ' + product.productName)) {
                return;
            }
            let itemIndex = -1;
            this.details.forEach((item, index)=> {
                
                if (item.productId === product.productId) {                                         
                    itemIndex = index;
                }
            });

           
            if (itemIndex > -1) {
                this.details.splice(itemIndex, 1);
            }        
            localStorage.removeItem('details');
            localStorage.setItem('details',JSON.stringify(this.details));
            product.deleted = true;
            setArray(product.productBatches, {
                deleted: true
            });

            this.onPriceUpdated();
           
        },
        async onImportExcel(model) {
            if (!model.id) {
                return;
            }
            const res = await $get('/v1/purchase-orders/excel-data', {
                file_id: model.id
            });

            if (res.code === 200) {
                res.data.forEach(d => {
                    this.details.push(d);
                });

                this.onPriceUpdated();
            } else {
                $alert(res);
            }
        },
        onDiscountChanged(discount) {
            this.entry.discount = getDiscountAmount(this.entry.subTotal, discount);
            this.entry.total = this.entry.subTotal -  this.entry.discount;
        },
        removeDetailBatch(batch) {
            if (!confirm('Xóa lô này?')) {
                return;
            }

            batch.deleted = true;
            this.onPriceUpdated();
        },
        onPriceUpdated(tag) {

            let subTotal= 0;
            this.details.forEach(d => {
                if (d.deleted) {
                    return;
                }

                d.productBatches.forEach(b => {
                    if (b.deleted) {
                        return;
                    }

                    const price = parseInt(b.price);
                    const discountPrice = price - getDiscountAmount(price, b.discountObject);

                    b.total = b.quantity * discountPrice

                    subTotal += b.total;

                })
            });

            ///this.entry.discount = discount;
            this.entry.discount = getDiscountAmount(this.entry.subTotal, this.entry.discountObject);
            this.entry.total = subTotal - this.entry.discount;
            this.entry.subTotal = subTotal;
        },
        productBatchChange(option, batch, product) {
            if (!option) {
                return;
            }

            batch.quantity = option.onHand/product.currentConversionValue;
            if (this.isReturnAction) {
                batch.inventory = option.inventory;
            }

            if (option.purchaseOrderDetail) {
                batch.price = option.purchaseOrderDetail.price;
                batch.discount = option.purchaseOrderDetail.discount;
                //batch.quantity = 0;
            }


            this.onPriceUpdated('c');
        },
        showModalBatchForm(product, batch, callback) {
            this.productBatchModel = {
                name: '',
                expireDate: null,
                productId: product.productId,
                productUnitId: product.productUnitId,
                quantity: product.quantity || 1
            };

            this.$refs.modal.show(async (entry) => {
                await callback(entry);
                batch.productBatchId = entry.id;
                batch.quantity = entry.quantity;
            });
        },
        addProductBatch(d) {

            d.productBatches.push({
                productId: d.productId,
                productBatchId: null,
                quantity: 1,
                discount: 0,
                price: d.price,
                deleted: false
            })
        },
        onSelected(p) {
            if (!p) {
                return;
            }

            const firstProductUnit = p.product_units.length ? p.product_units[0] : null
            
            this.details.push({
                productId: p.id,
                productCode: p.code,
                productName: p.orignalName,
                quantity: 1,
                discount: 0,
                currentConversionValue: firstProductUnit ? firstProductUnit.conversionValue : 1,
                currentSalePrice: firstProductUnit ? firstProductUnit.price : 0,
                productUnitId: firstProductUnit ? firstProductUnit.id : null,
                price: p.capitalPrice,
                productUnits: p.product_units,
                productBatches: [
                    {
                        productId: p.id,
                        productBatchId: null,
                        quantity: 1,
                        discount: 0,
                        price: p.capitalPrice,
                        deleted: false,
                        discountObject: {
                            discountValue: 0,
                            discountType: 1
                        }
                    }
                ],
                deleted: false,
            })            
            localStorage.setItem('details',JSON.stringify(this.details));
            this.$nextTick(() => {
                this.searchModel = null;
            })
            
        },
        async loadOrderSupplier(code) {
            const res = await $get('/v1/order-suppliers/show', {
                code
            });

            if (res.code !== 200) {
                $alert(res);
                return;
            }

            this.isDataLoaded = true;
            const {entry, details} = res.data;

            this.entry = {
                orderSupplierCode: entry.code,
                branchId: entry.branchId,
                createdBy: entry.createdBy,
                createdAt: entry.createdAt,
                subTotal: entry.subTotal,
                total: entry.total,
                supplierId: entry.supplierId,
                inventoryPlanId: entry.inventoryPlanId,
                readonly: 0,
                status: PurchaseOrderStatus.DRAFT,
            };

            this.details = details.map(detail => {
                return {
                    deleted: false,
                    price: 0,
                    currentConversionValue: 1,
                    currentSalePrice: 0,
                    productCode: detail.product.code,
                    productId: detail.product.id,
                    productName: detail.product.name,
                    productUnitId: detail.productUnitId,
                    quantity: detail.quantity,
                    productUnits: detail.product.product_units,
                    inventoryPlanId: detail.inventoryPlanId,
                    productBatches: [{
                        productId: detail.product.id,
                        productBatchId: null,
                        quantity: detail.quantity,
                        discount: 0,
                        price: detail.price,
                        deleted: false,
                    }]
                };
            });
            this.initButtons();
        },
        async load(query) {
            const res = await $get('/v1/purchase-orders/show', query);

            if (res.code === 404) {
                this.$store.commit('SetIsPageNotFound', true);
                return;
            }

            const {entry, details, title} = res.data;

            this.entry = entry;
            this.details = details;
            this.isDataLoaded = true;
            this.isReturnAction = entry.actionType === 'return';

            this.pageTitle = title;
            this.onPriceUpdated('Loaded');
            document.title = title;
        },
        saveDraft() {
            this.save(true);
        },
        async save(draft = false) {

            this.detailErrors = {}
            this.errors = {};
            this.productBatchErrors = {};

            for (let i = 0; i < this.details.length; i++) {
                const d = this.details[i];

                if (d.deleted) {
                    continue;
                }

                if (parseInt(d.productUnitId) === 0) {
                    this.detailErrors[i] = ['Vui lòng chọn đơn vị'];
                    return;
                }

                const productBatches = this.details[i].productBatches;
                for (let j = 0; j < productBatches.length; j++) {
                    const b = productBatches[j];

                    if (b.deleted) {
                        continue;
                    }

                    if (parseInt(b.quantity) === 0) {                        
                        this.quantityErrors[j] = ['Vui lòng chọn số lượng'];
                        return;
                    }
                }

            }

            let qty = 0;
            let hasError = false;

            this.details.forEach(d => {
                if (d.deleted) {
                    return;
                }

                d.productBatches.forEach((b, bIndex) => {
                    if (b.deleted) {
                        return;
                    }

                    if (!b.productBatchId) {
                        hasError = true;
                        this.productBatchErrors[bIndex] = [
                            'Vui lòng chọn lô/date'
                        ];
                    }

                    qty += b.quantity;
                });
            });

            if (hasError) {
                return;
            }

            if (this.entry.status !== PurchaseOrderStatus.CANCELLED) {
                if (draft) {
                    this.entry.status = PurchaseOrderStatus.DRAFT;
                } else {

                    if (qty === 0) {
                        alert('Vui lòng sản phẩm để nhập');
                        return;
                    }

                    if (!confirm('Hoàn thành phiếu nhập hàng này?')) {
                        return;
                    }

                    if (this.entry.actionType === PurchaseOrderType.RETURN) {
                        this.entry.status = PurchaseOrderStatus.RETURNTED;
                    } else {
                        this.entry.status = PurchaseOrderStatus.IMPORTED;
                    }

                }
            }

            const res = await $post('/v1/purchase-orders/save', {
                entry: this.entry,
                details: this.details,
                draft: draft ? 1 : 0,
                action_type: this.isReturnAction ? 'return' : 'import',
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.importedRedirect) {
                location.replace(res.importedRedirect)
            }  else if (res.redirect) {
                location.replace(res.redirect);
            }

            localStorage.removeItem('details');
            $alert(res);
            this.initActionBarButtons();
        }
    }
}
</script>

<style>
.purchase-form {
    display: flex;
    width: 100%;
    margin-top: 10px;
    align-items: center;
}

.div__center {
    height: calc(1.5em + 0.75rem + 2px);
    display: flex;
    align-items: center;
    font-weight: bold;
    justify-content: center;
}

/* .purchase-form .form-group {
    margin-bottom: 0 !important;
} */

.purchase-form__label {
    width: 28%;
}

.purchase-form__input {
    flex: 1;
    margin-left: 5px;
}

.purchase-form__import {
    display: flex;
    justify-content: center;
}
.slot-row {
    display: inline-block;
    margin-right: 15px;
}
</style>
