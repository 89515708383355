<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="main-title">
                    <h1>{{title}}</h1>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="search-box">
                    <i class="fas fa-search mr-2"></i>
                    <input type="text" class="search-input"
                           @keydown.enter="doFilter('keyword', filter.keyword)"
                           v-model="filter.keyword" placeholder="Tìm kiếm"/>
                </div>
            </div>

        </div>

        <div class="row mt-3" v-if="!currentPlantId">
            <button class="btn btn-primary" type="button" @click="createPlan()">
                <i class="fa fa-plus"/>
                Tạo phiếu đặt hàng
            </button>
            <button class="btn btn-primary" type="button" @click="createPlanAll()">
                <i class="fa fa-plus"/>
                Tạo phiếu đặt hàng (tất cả)
            </button>
        </div>

        <div class="row mt-3">
            <div style="overflow-x: auto; width: 100%">
                <Paginate :value="paginate" @change="pageChange"/>
                <table class="table table-index table-striped">
                    <thead>
                    <tr>
                        <th v-if="!currentPlan">
                            <input type="checkbox" v-model="productCheckedAll" @change="onProductCheckedAll">
                        </th>
                        <th style="width: 25%">{{$t('message.common.products')}}</th>

                        <th>{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                        <template v-for="branch in branches" :key="branch.id">
                            <th  :style="{backgroundColor: branch.color}">
                                <div v-text="branch.code "></div>
                                Tồn kho
                            </th>
                        </template>
                        <th>Tổng dự trù</th>
                        <th>{{$t('message.common.status')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in products" :key="product.id">
                        <td v-if="!currentPlan">
                            <input type="checkbox" v-model="product.checked">
                        </td>
                        <td >
                            <ul class="ul--normal">
                                <li>MÃ: {{product.code}}</li>
                                <li>Tên:{{product.name}}</li>
                                <li>HSD: {{d(getLastBatchExpiredDate(product))}}</li>
                                <li>NCC: {{product.supplier?.name}} </li>
                            </ul>
                        </td>

                        <td>
                            <select
                                @change="onProductUnitIdChange(product,product.currentProductUnitId)"
                                v-model="product.currentProductUnitId">
                                <option :value="pu.id" v-for="pu in product.product_units" :key="pu.id" v-text="pu.unitName"></option>
                            </select>
                        </td>
                        <template v-for="(branch) in branches"  :key="branch.id">
                            <td >
                                <ul class="ul--normal" >
                                    <li>  Min: {{n(getMinimalQuantity(product, branch))}}</li>
                                    <li>  TK:  {{n(getInventoryOnHand(product, branch)) }}</li>
                                    <li>  Dự trù:
                                        {{
                                            n( getInventoryPlanning(product, branch))
                                        }}
                                    </li>
                                </ul>

                            </td>

                        </template>
                        <td>
                            <ul class="ul--normal" >
                                <li>
                                    SL: {{n(getInventoryPlanningTotal(product))}}
                                </li>
                                <li>
                                    Đơn giá: {{n(getInventoryPlanningPrice(product))}}
                                </li>
                                <li>
                                    Giá tiền: {{n(getInventoryPlanningTotal(product) * getInventoryPlanningPrice(product))}}
                                </li>
                            </ul>

                        </td>
                        <td v-text="product.currentProgressStatus">

                        </td>
                    </tr>
                    </tbody>
                </table>
                <Paginate :value="paginate" @change="pageChange"/>
            </div>

        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="modalPrepare">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"><i class="fa fa-spinner fa-spin"></i> Đang xử lý </h5>
                    </div>
                    <div class="modal-body">
                        <div>
                            <p>
                                {{prepareData.progressText}}
                            </p>
                            <em>Vui lòng ko đóng trình duyệt</em>
                        </div>
                        <div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar"
                                     :style="{width: prepareData.percentageStyle + '%'}"
                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal" tabindex="-1" role="dialog" ref="modal">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title"><i class="fa fa-spinner fa-spin"></i> Đang xử lý </h5>
                    </div>
                    <div class="modal-body">
                        <div>
                            <p>
                                {{progressText}}
                            </p>
                            <p>
                                Số SP cần đặt hàng: {{needToOrderProduct}}
                            </p>
                            <em>Vui lòng ko đóng trình duyệt</em>
                        </div>
                        <div>
                            <div class="progress">
                                <div class="progress-bar" role="progressbar"
                                     :style="{width: percentageStyle + '%'}"
                                     aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import {$alert, $get, $post, sleep, percentage, clone, getSearchQuery} from "../../lib/utils";
import Paginate from "../../components/Paginate";
import store from "../../store";
import * as toastr from 'toastr';

export default {
    name: "InventoryPlanOrderSuppliers",
    components: {Paginate, InputDateRangePicker},
    data() {
        const query = getSearchQuery();

        return {
            currentPlan: null,
            title: 'Đặt hàng dự trù định kỳ',
            saveData: {
                fields: ['productUnitId', 'supplierId' ,'quantity' ,'price', 'amount'],
                data: []
            },
            prepareData: {
                percentageStyle: 0,
                progressText: ''
            },
            percentageStyle: 0,
            needToOrderProduct: 0,
            pageLimit: 100,
            countInProgress: 0,
            countTotal: 0,
            progressText: '',
            productCheckedAll: false,
            products: [],
            branches: [],
            paginate: {
                currentPage: 1,
                lastPage: 1,
            },
            currentPlantId: null,
            filter: {
                keyword: query.keyword || '',
                type: 1
            }
        }
    },
    mounted() {
        this.currentPlantId = this.$route.query.id;
        document.title = this.title;

        this.modal = window.jQuery(this.$refs.modal);
        this.modalPrepareModal = window.jQuery(this.$refs.modalPrepare);
        this.prepare();
    },
    methods: {
        async prepare() {
            this.modalPrepareModal.modal({
                backdrop: 'static', keyboard: false
            });

            const limit = 200;

            let countInProgress = 0;
            const result = {};

            let rProducts = [], rBranches;

            for (let page = 1; page < 10000; page++) {
                const query = {page, limit}
                await sleep(2000);
                const res = await this.load(query, false, false);

                if (res.code !== 200) {
                    console.error(res.message);
                    return;
                }

                if (!Array.isArray(res.data.products)) {
                    console.error('Invalid response Data');
                    return;
                }

                if (res.data.products.length === 0) {                    
                    this.prepareData.progressText = '';
                    this.prepareData.percentageStyle = 100;
                    break;
                }

                if (!rBranches) {
                    rBranches = res.data.branches;
                }

                res.data.products.forEach(p => {
                    if (p.sumInventoryPlanning > 0) {
                        rProducts.push(p);
                    }
                })

                countInProgress += res.data.products.length;

                let countTotal = res.data.paginate.total;
                this.prepareData.progressText = 'Đang xử lý';
                this.prepareData.percentageStyle = percentage(countInProgress, countTotal);


            }

            this.products = rProducts;
            this.branches = rBranches;
            this.modalPrepareModal.modal('hide')
        },
        async doFilter(field, value) {
            const query = clone(this.$route.query);
            query[field] = value;
            await this.$router.push({query: query});
            this.load();
        },
        beforeUnload(event) {
            event.preventDefault();
            event.returnValue = 'Dữ liệu chưa được xử lý xong. Đóng trình duyệt?"';
        },
        async step$1ProcessProducts(productIDs) {
            const limit = 1000;

            let countInProgress = 0;
            const result = {};

            for (let page = 1; page < 10000; page++) {
                const query = {page, limit, productIDs}
                await sleep(2000);
                const res = await this.load(query, false, false);

                if (res.code !== 200) {
                    console.error(res.message);
                    return;
                }

                if (!Array.isArray(res.data.products)) {
                    console.error('Invalid response Data');
                    return;
                }

                if (res.data.products.length === 0) {
                    console.log('No data')
                    break;
                }

                let countTotal = res.data.paginate.total;
                const products = res.data.products;
                const productLen = products.length;
                countInProgress += productLen;
                for (let i = 0; i < productLen; i++) {
                    const product = products[i];
                    this.branches.forEach(branch => {
                        this.getInventoryPlanning(product, branch);
                    });

                    const quantity = this.getInventoryPlanningTotal(product);
                    const price = this.getInventoryPlanningPrice(product);
                    const supplier =  product.supplier;

                    if (quantity > 0) {
                        if (supplier) {
                            this.needToOrderProduct++;
                            result[supplier.id] =  result[supplier.id] || [];
                            result[supplier.id].push({
                                productId: product.id,
                                productUnitId: product.currentProductUnitId,
                                supplierName: supplier.name,
                                supplierId: supplier.id,
                                quantity,
                                price,
                                subTotal: quantity*price,
                                inventories: product.inventories,
                                productBranchMeta: product.product_branch_meta
                            })
                        } else {
                            this.progressText = ('Sản phẩm '  + product.name + ' không có nhà cung cấp');
                            await sleep(1000);
                        }

                    }
                }

                this.percentageStyle = percentage( countInProgress,countTotal, 0.6);
                this.progressText = `Đang xử lý sản phẩm: ${countInProgress}/${countTotal}`;
            }

            const returnData = [];
            Object.keys(result).forEach(supplierId => {
                const details = result[supplierId];
                const supplierName = details[0].supplierName;

                details.forEach(d => {
                    delete d.supplierId;
                    delete d.supplierName;
                })

                returnData.push({
                    supplierId,
                    supplierName,
                    data: result[supplierId]
                })
            })

            return returnData;
        },
        async step$2CreatePlan() {
            this.progressText = 'Tạo phiếu đặt hàng nhập định kỳ';
            const res = await $post('/v1/inventory-plans/create')


            if (res.code !== 200) {
                alert(res.message);
                return;
            }
            const plan = res.data;
            this.percentageStyle += 10;
            return plan;
        },
        async step$3CreateBatchSupplierOrder(supplierOrderDetailGroups, plan) {
            const savedPercentage =  this.percentageStyle;
            this.progressText = 'Tạo phiếu đặt hàng nhập';
            const len = supplierOrderDetailGroups.length;
            for (let i = 0; i < len; i++) {
                const p = percentage(i+1, len , 0.4);

                const group = supplierOrderDetailGroups[i];
                const details = group.data.map(d => {
                    return d;
                });


                this.progressText = 'Tạo phiếu đặt hàng nhập cho: ' + group.supplierName;
                await sleep(1000);

                const supplierOrder = {
                    supplierId: group.supplierId,
                    inventoryPlanId: plan.id,
                    details
                };

                const res = await $post('/v1/inventory-plans/create-order-suppliers', {
                    entry: supplierOrder
                });

                if (res.code === 200) {
                    this.percentageStyle = savedPercentage + p;
                } else {
                    console.error(res);
                    toastr.error(res.message);
                    this.progressText = 'Lỗi: ' + res.message;

                    return;
                }

            }
        },
        async startProcessCreatePlanAll(productIDs) {
            window.addEventListener('beforeunload', this.beforeUnload);

            const supplierOrderDetails = await this.step$1ProcessProducts(productIDs);
            if (supplierOrderDetails.length === 0) {
                this.progressText = 'Không có sản phẩm nào cần đặt hàng!';
                this.percentageStyle = 100;
                setTimeout(() => {
                    this.modal.modal('hide');
                }, 3000);
                return;
            }

            const plan = await this.step$2CreatePlan(true);
            await this.step$3CreateBatchSupplierOrder(supplierOrderDetails, plan);
            window.removeEventListener('beforeunload', this.beforeUnload);

            this.progressText = 'Đã tạo phiếu đặt hàng nhập thành công.';
            await sleep(1000);

            this.modal.modal('hide');
            this.progressText = '';
            this.percentageStyle = 0;
        },
        async createPlanAll() {
            if (!confirm('Tạo phiếu đặt hàng nhập cho tất cả sản phẩm?')) {
                return;
            }

            this.modal.modal({
                backdrop: 'static', keyboard: false
            });

            const productIDs = this.products.map(p => p.id);

            this.startProcessCreatePlanAll(productIDs);
         /*   const res = await $post('/v1/inventories/create-plan');
            $alert(res);*/
        },
        createPlan() {
            const products = this.products.filter(p => p.checked);
            if (products.length === 0) {
                alert('Vui lòng chọn sản phẩm');
                return;
            }

            const productIDs = products.map( p => p.id);

            if (!confirm('Tạo phiếu đặt hàng nhập?')) {
                return;
            }

            this.modal.modal({
                backdrop: 'static', keyboard: false
            });

            this.startProcessCreatePlanAll(productIDs);
        },
        onProductCheckedAll() {
            this.products.forEach(product => {
                product.checked = this.productCheckedAll
            })
        },
        onProductUnitIdChange(product, productUnitId) {
            const pu = product.product_units.find(pu => pu.id === productUnitId);
            if (pu) {
                product.currentConversionValue = pu.conversionValue;
            }
        },
        getInventoryPlanningPrice(product) {
            if (!product.lastPurchaseOrderDetail) {
                return 0;
            }

            const lastPurchaseOrderDetail = product.lastPurchaseOrderDetail;
            return parseInt(lastPurchaseOrderDetail.price)
        },
        getInventoryPlanningTotal(product) {
            if (product.sumInventoryPlanning) {
                return product.sumInventoryPlanning;
            }

            const values = Object.values(product.inventoryPlanning)
            product.sumInventoryPlanning = values.reduce(function(a, b) { return a + b; }, 0);
            return product.sumInventoryPlanning;
        },
        getInventoryOnHand(product, branch) {
          //  console.log('getInventoryOnHand')
            return (product.inventoryMap[branch.id] ?? 0)/product.currentConversionValue
        },
        getMinimalQuantity(product, branch) {
            const v = product.metaMap[branch.id]?.minimalQuantity ?? 0;
            return v/product.currentConversionValue;
        },
        getInventoryPlanning(product, branch) {
            const value = Math.max(0, this.getMinimalQuantity(product, branch)
             - this.getInventoryOnHand(product ,branch));

            product.inventoryPlanning[branch.id] = value;

            return value;
        },

        getLastBatchExpiredDate(product) {
            if (product.product_batches.length > 0) {
                return product.product_batches[0].expireDate
            }

            return '';
        },
        async pageChange(page) {
            await this.$router.push({query: {page: page}});
            this.load();
        },
        async load(query = null, setData = true, showLoading = true) {

            if (!query) {
                query = this.$route.query;
            }

            query.currentPlantId = this.currentPlantId;
            const res = await $post('/v1/inventory-plans/order-suppliers-plan', query, showLoading);
            if (res.code === 404) {
                store.commit('SetIsPageNotFound', true);
                return;
            }
            this.currentPlan = res.data.plan;
            if (res.data.plan) {
                this.title = 'Chi tiết xử lý dự trù: #' + res.data.plan.code;
                document.title = this.title;
            }

            const branches = res.data.branches;

            res.data.products.forEach(product => {
                product.product_units = product.product_units.reverse();//Đơn vị to lên trước
                const inventoryMap = {};
                const metaMap = {};
                const inventories = product.inventories;
                const productBranchMeta = product.product_branch_meta;
                product.supplier = null;
                if (product.lastPurchaseOrderDetail && product.lastPurchaseOrderDetail.purchase_order) {
                    product.supplier = product.lastPurchaseOrderDetail.purchase_order.supplier;
                }

                inventories.forEach(iv => {
                    inventoryMap[iv.branchId] = parseInt(iv.onHand);
                });

                productBranchMeta.forEach(meta => {
                    metaMap[meta.branchId] = meta;
                });

                product.inventoryPlanning  = {};
                product.currentConversionValue = 1;
                if (product.product_units.length) {
                    product.currentProductUnitId = product.product_units[0].id;
                    product.currentConversionValue = product.product_units[0].conversionValue;
                } else {
                    product.currentProductUnit = null;
                }

                product.inventoryMap = inventoryMap;
                product.metaMap = metaMap;
                branches.forEach(branch => {
                    this.getMinimalQuantity(product, branch);
                    this.getInventoryOnHand(product, branch);
                    this.getInventoryPlanning(product, branch);
                    this.getInventoryPlanningTotal(product, branch)
                });
            });

            if (setData) {
                this.branches = res.data.branches;
                this.paginate = res.data.paginate;
                this.countTotal = res.data.paginate.total;
                this.products = res.data.products;
            }

            return res;
        },
    }
}
</script>

<style scoped>

</style>
