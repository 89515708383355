<template>    
    <div ref="modal" class="modal mx-0 fade" :class="this.isFirstModal ? '' : 'modal-second'" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{(typeEditAdd === "EDIT" && this.id != null) ? $t('message.CategoriesIndex.modal.title_update') : $t('message.CategoriesIndex.modal.title_create')}}</h5>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-2">
                                    <b>
                                        {{ $t('message.CategoriesIndex.modal.name') }}
                                        <label class="text-danger px-0">*</label> 
                                    </b>
                                </div>
                                <div class="col-10">
                                    <InputText v-model="entry.name"
                                        :errors="errors.name"
                                        :placeholder="$t('message.CategoriesIndex.modal.placeholder.name')"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-2">
                                    <b>{{ $t('message.CategoriesIndex.modal.parent_category') }}</b>
                                </div>
                                <div class="col-10">
                                    <div class="form-group">
                                        <q-select v-model="entry.parentId"
                                            datasource="categories"
                                            :placeholder="$t('message.CategoriesIndex.modal.placeholder.parent_category')"
                                            :multiple="false"
                                            ref="parentId"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <AppPermission :permission="this.$permissions.categories.update">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="this.id">
                                    <i class="fa fa-save mr-1"></i>
                                    {{ $t('message.CategoriesIndex.modal.button.update') }}
                                </button>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.categories.create">
                                <button type="button" class="btn btn-save mr-2" @click="save" v-if="!this.id">
                                    <i class="fa fa-save mr-1"></i>
                                    {{ $t('message.CategoriesIndex.modal.button.add') }}
                                </button>
                            </AppPermission>
                                <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                                    <i class="fa fa-ban mr-1"></i>
                                    {{ $t('message.CategoriesIndex.modal.button.skip') }}
                                </button>
                            <AppPermission :permission="this.$permissions.categories.remove">
                                <button type="button" class="btn btn-danger mr-2" v-if="(typeEditAdd === 'EDIT' && this.id != null)" @click="removeCate(entry.id)">
                                    <i class="fa fa-trash-alt mr-1"></i>
                                    {{ $t('message.CategoriesIndex.modal.button.remove') }}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <ModalCategoryRemove ref="ModalCategoryRemove" @inputData="hide()"/>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
</template>
<style scoped>
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}

</style>
<script>
import ActionBar from "../../../components/ActionBar";
import InputText from "../../../components/forms/InputText";
import toastr from 'toastr';
import {$post, $alert, $get, scrollToElement, isChangeData, cloneObject} from "@/lib/utils";
import InputSwitchButton from "../../../components/forms/InputSwitchButton";
import QSelect from "@/components/QSelect";
import emitter from "@/lib/emitter";
import ModalCategoryRemove from "./ModalCategoryRemove.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '@/components/AppPermission';
// import { valueToNode } from "@babel/types";
export default {
    name: "ModalCategoryAdd",
    components: { ActionBar, InputText, InputSwitchButton, QSelect, ModalCategoryRemove, ModalConfirm, AppPermission},
    props: {
        typeEditAdd: String,
        idCategory: Number,
        isFirstModal: Boolean
    },
    data() {

        return {
            // isDataLoaded: false,
            entry: {
                name: '',
                parentId: null
            },
            errors: {},
            default: {
                name: '',
                parentId: null
            },
            contentConfirm: '',
            titleConfirm: '',
            id: null,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                parentId: null
            };
            this.id = null;
            this.$refs.parentId.keyword = '';
        },
        async show(id = null) {   
            this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                await this.load(id);
            }
            this.default = {...this.entry};
            this.id = id;
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.common.confirm');
                if(this.id != null){
                    this.contentConfirm = this.$t('message.CategoriesIndex.modal.contentCancelUpdate');
                }else{
                    this.contentConfirm = this.$t('message.CategoriesIndex.modal.contentCancelCreate');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async load(id = null) {
            const res = await $get('/v1/categories/show', {
                id: id
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.result;
        },
        async save() {
            this.errors = {};
            const submitEntry = cloneObject(this.entry);
            submitEntry.name = submitEntry.name.trim();            
            // Tách hàm thêm mới và cập nhật do hai permission khác nhau
            let strSave = '';
            if(this.id) strSave = '/v1/categories/update';
            else strSave = '/v1/categories/save'
            const res = await $post(strSave, {...submitEntry });            
            if(res.code === 422){
                toastr.error(res.messages.errors.name[0]);
            }
            else if(res.status.code == 200){
                if(res.result){
                    toastr.success(res.result.message);
                    this.hide();
                    emitter.emit('getListTreeCategory');
                }else{
                    toastr.error(res.result.message);
                }
            }
        },
        removeCate(id){
            this.$refs.ModalCategoryRemove.show(id);
        },
    },
    mounted() {
        emitter.on('openAdd',() => this.show())
    },
    beforeUnmount(){
        this.hide();
    }
}
</script>

