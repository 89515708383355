const saleRoutes = [
    // {'path': '/sale', 'name': 'SaleScreen', 'component':  require('@/views_sale/sales/SaleForm').default},
    {'path': '/sale/create-order', 'name': 'CreateOrder', 'component':  require('@/views_sale/sales/OrderFormCreateUpdate').default},
    {'path': '/sale/edit-order', 'name': 'EditOrder', 'component':  require('@/views_sale/sales/OrderFormCreateUpdate').default},

    //Sao chép
    {'path': '/sale/copy-order', 'name': 'CopyOrders', 'component':  require('@/views_sale/sales/OrderFormCreateUpdate').default},

    //Bán hàng online
    {'path': '/sale-online/e-commerce/list', 'name': 'SaleOnlineScreen', 'component':  require('@/views_sale/sales-online/ECommerce').default},
    {'path': '/sale-online/e-commerce/mapping', 'name': 'MappingScreen', 'component':  require('@/views_sale/sales-online/SettingMapping').default},
    {'path': '/sale-online/e-commerce/new-connect', 'name': 'NewConnectScreen', 'component':  require('@/views_sale/sales-online/LoginEcommerce').default},
    {'path': '/sale-online/e-commerce/new/setting', 'name': 'NewSettingScreen', 'component':  require('@/views_sale/sales-online/SettingConnect').default},
    {'path': '/sale-online/e-commerce/new/mapping', 'name': 'NewMappingScreen', 'component':  require('@/views_sale/sales-online/MappingProduct').default},
    {'path': '/sale-online/e-commerce/sync-fail', 'name': 'SyncFailScreen', 'component':  require('@/views_sale/sales-online/SyncFail').default},

    // Xử lý phiếu nhập, xuất, chuyển vị trí hàng hóa
    {'path': '/ticket-import', 'name': 'CreateTickerImportScreen', 'component':  require('@/views_sale/sales/TicketImportCreateUpdate').default},
    {'path': '/ticket-export', 'name': 'CreateTickerExportScreen', 'component':  require('@/views_sale/sales/TicketImportCreateUpdate').default},
    {'path': '/ticket-transfer', 'name': 'CreateTickerTransferScreen', 'component':  require('@/views_sale/sales/TicketImportCreateUpdate').default},

    // Tạm ứng quyết toán
    {'path': '/settlement-advance', 'name': 'CreateSettlementAdvanceScreen', 'component':  require('@/views_sale/sales/SettlementAdvanceCreateUpdate').default},
    // {'path': '/not-permissions', 'name': 'notPermission', 'component':  require('@/views/errors/NotPermissionPage').default},

    // Đề nghị hoàn hàng
    {'path': '/request_refund', 'name': 'CreateRequestRefundScreen', 'component':  require('@/views_sale/sales/RefundCreateUpdate').default},

    // Dự trù hàng mua - dự trù chi tiết
    {'path': '/inventory-plans', 'name': 'PlansCreateUpdateScreen', 'component':  require('@/views_sale/sales/PlansCreateUpdate').default},

    // Dự trù hàng mua - dự trù nhập mua
    {'path': '/plan-purchase', 'name': 'PlanPurchaseCreateUpdateScreen', 'component':  require('@/views_sale/sales/PlanPurchaseCreateUpdate').default},

    // Các khoản chiết khấu sau BAS
    {'path': '/discount-bas/create', 'name': 'DiscountsBasCreate', 'component':  require('@/views_sale/sales/DiscountsBasCreateUpdate').default},
    {'path': '/discount-bas/update', 'name': 'DiscountsBasUpdate', 'component':  require('@/views_sale/sales/DiscountsBasCreateUpdate').default},
    // Zalo mapping
    {'path': '/zalo/index', 'name': 'ZaloTemplate', 'component':  require('@/views_sale/sales-online/ZaloTemplate').default},
]

export default saleRoutes as Array<any>;
