<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document" style="max-width: 1140px" >
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">Báo cáo bán hàng theo hàng hóa</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>Chọn thời gian</label>
                                <input-date-range-picker v-model="filter.date"></input-date-range-picker>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6 col-sm-12">
                            <div class="form-group">
                                <label>Chọn chi nhánh</label>
                                <q-select datasource="branchesIndex" :multiple="true" v-model="filter.branch_ids" ></q-select>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12">
                            <button type="button" class="btn btn-primary" @click="load()" style="margin-top: 35px">Tìm kiếm</button>
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12">
                            <table class="table table-index table-striped">
                                <thead>
                                <tr>
                                    <th>{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                    <th>{{$t('message.DetailOrdersCard.prodName')}}</th>
                                    <th>Số lượng bán</th>
                                    <th>Gía trị niêm yết</th>
                                    <th>{{$t('message.StatisticalInvoice.revenue')}}</th>
                                    <th>Chênh lệch</th>
                                    <th>{{$t('message.common.returnQuantity')}}</th>
                                    <th>Giá trị trả</th>
                                    <th>Doanh thu thuần</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr v-for="product in entries" :key="product.id">
                                    <td v-text="product.product?.code"></td>
                                    <td v-text="product.product?.name + '(' + product.product_unit?.unitName + ')'"></td>
                                    <td v-text="product.qty"></td>
                                    <td v-text="n(product.originalPrice)"></td>
                                    <td v-text="n(product.subPrice)"></td>
                                    <td v-text="n(product.subPrice - product.originalPrice)"></td>
                                    <td v-text="product.returnQty"></td>
                                    <td v-text="n(product.returnPrice)"></td>
                                    <td v-text="n(product.subPrice - product.returnPrice)"></td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <div class="text-right mt-4 d-flex">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            <i class="fa fa-ban"></i>&nbsp;Bỏ qua
                        </button>
                        <export-excel
                            :fetch  = "exportExcel"
                            :fields = "excelData.fields"
                            :name   = "excelData.fileName">
                            <button type="button" class="btn btn-save" style="padding-bottom: 0.5rem">
                                <i class='fas fa-file-export'></i>
                                Xuất Excel
                            </button>
                        </export-excel>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {
    $get,
    DISCOUNT_TYPE_PERCENT,
    explode,
    TYPE_OUT_OF_DATE,
    TYPE_NOT_OUT_OF_DATE,
    numberFormat
} from "../../lib/utils";
import InputNumberFormat from "../../components/forms/InputNumberFormat";
import InputText from "../../components/forms/InputText";
import InputDateRangePicker from "@/components/forms/InputDateRangePicker";
import moment from "moment";
import QSelect from "@/components/QSelect";
import toastr from "toastr";
import FieldsProductInvoiceReport from "@/assets/exportFields/fieldsProductInvoiceReport";

export default {
    name: "ModalProductInvoiceReport",
    components: {QSelect, InputDateRangePicker, InputText, InputNumberFormat},
    props: ['modelValue'],
    data() {
        let query = this.$route.query;
        return {
            data: {},
            DISCOUNT_TYPE_PERCENT,
            TYPE_OUT_OF_DATE,
            TYPE_NOT_OUT_OF_DATE,
            checkedIds: [],
            filter: {
                type: query.type || TYPE_NOT_OUT_OF_DATE,
                date: query.date || '',
                branch_ids: explode(query.branch_ids)
            },
            excelData: {

            },
            entries: []
        }
    },
    mounted() {
    },
    methods: {
        show() {

            window.jQuery(this.$refs.modal).modal({
                backdrop: 'static', keyboard: false
            });
        },

        save() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async load() {
            const params = this.filter;

            const res = await $get('/v1/products/productInvoiceReport', params);

            if (res.code === 200) {
                this.entries =  res.data;
            }
        },
        async exportExcel() {
            if (!this.excelData.fields) {
                this.excelData.fields = FieldsProductInvoiceReport;
            }

            const date = moment().format('HHmm_DDMMYYYY');
            this.excelData.fileName = 'bao-cao-hang-hoa-' + date + 'xls';

            if (this.entries && this.entries.length) {
                return this.exportInfo(this.entries);
            } else {
                toastr.warning('Không có bản ghi để xuất file excel');
            }
            return false;
        },
        getTotalPrice(item) {
            const totalOrigin = this.modelValue.totalOrigin || this.modelValue.total;
            let result = 0;

            if (item.priceType == DISCOUNT_TYPE_PERCENT) {
                result = totalOrigin * item.priceValue / 100;
            } else {
                result = item.priceValue;
            }

            return parseInt(result);
        },
        exportInfo(data) {
            let arr = [];
            if (data && data.length) {
                data.forEach(item => {

                    let it = {};
                    it.productCode = item.product?.code;
                    it.productName = item.product?.name + '(' + item.product_unit?.unitName + ')';
                    it.qty = item.qty;
                    it.originalPrice = item.originalPrice;
                    it.subPrice = item.subPrice;
                    it.diffPrice = item.subPrice - item.originalPrice;
                    it.returnQty = item.returnQty;
                    it.returnPrice = item.returnPrice;
                    it.totalPrice = item.subPrice - item.returnPrice;

                    arr.push(it);

                })
            }
            return arr;
        },
    }
}
</script>

<style scoped>

</style>
