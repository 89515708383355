export default {
  methods: {
    formatCurrency(number, label = '', signDisplay = true) {
        const locale = 'en-US';
        const options = {
            style: 'currency',
            currency: 'JPY',
        };
        if (signDisplay) {
            options.signDisplay = 'never';
        }
        const formatter = new Intl.NumberFormat(locale, options);

        return formatter.formatToParts(number).map(({ type, value }) => {
            switch (type) {
            case 'currency': return `${label}`;
            default: return value;
            }
        }).reduce((string, part) => string + part);
    },
    onChangeCurrency(event) {
        if (event.target.value != '') {
            event.target.value = this.formatCurrency(
                event.target.value.replace(/,/g, '')
            );
        }
    },
    onKeyDownCurrency(event) {
        let isShift = false;
        if (window.event) {
            isShift = !!window.event.shiftKey;
        } else {
            isShift = !!event.shiftKey;
        }
        if (isShift) {
            event.preventDefault();
            return '';
        }
        const numberChars = ["0","1","2","3","4","5","6","7","8","9"];
        if (
            numberChars.indexOf(event.key) == -1
            && event.code !== 'Backspace'
            && event.key !== 'Tab'
            && event.key !== 'ArrowUp'
            && event.key !== 'ArrowDown'
            && event.key !== 'ArrowLeft'
            && event.key !== 'ArrowRight'
            && (!(event.key == 'c' && event.ctrlKey === true))
        ) {
            event.preventDefault();
        }
    },
  }
}
