<template>
    <div class="wrap">
        <div class="header">
            <div class="mt-3">
                <img src="/img/logo-text.png" alt="">
            </div>
            <h4>Omipharma</h4>
            <div>
                <span>Địa chỉ: </span>
                <span v-text="currentBranch.addressDecode"></span>
            </div>
            <div>
                <span>Điện thoại: </span>
                <span v-text="currentBranch.phone"></span>
            </div>
            <div>
                <h3 class="mt-2" v-if="data.type == TYPE_REQUEST">PHIẾU XIN HÀNG</h3>
                <h3 class="mt-2" v-else>PHIẾU CHUYỂN HÀNG</h3>
            </div>
            <div>
                <span>Mã phiếu: </span>
                <span v-text="data.code"></span>
            </div>
            <div v-text="'Ngày ' + d(data.createdAt, 'DD') + ' tháng ' + d(data.createdAt, 'MM') + ' năm ' + d(data.createdAt, 'YYYY')"></div>
        </div>
        <div class="content">
            <div class="customer">
                <div>
                    <span v-text="data.type === TYPE_REQUEST ? 'Chi nhánh xin: ' : 'Chi nhánh chuyển: '"> </span>
                    <span v-text="data.from_branch?.name"></span>
                </div>
                <div>
                    <span>Người chuyển: </span>
                    <span v-text="data.created_by?.name"></span>
                </div>
                <div>
                    <span>Chi nhánh nhận: </span>
                    <span v-text="data.to_branch?.name"></span>
                </div>
                <div>
                    <span>Người nhận: </span>
                    <span v-text="data.received_by?.name"></span>
                </div>
            </div>
            <div class="items">
                <table v-if="items" class="table-items">
                    <thead>
                    <tr>
                        <th>{{$t('message.common.STT')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodCode')}}</th>
                        <th>{{$t('message.common.products')}}</th>
                        <th v-text="data.type === TYPE_REQUEST ? 'SL xin' : 'SL chuyển'"></th>
                        <th v-text="data.type === TYPE_REQUEST ? 'SL chuyển' : 'SL nhận'"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in items" :key="item.id">
                        <td v-text="index + 1"></td>
                        <td v-text="item.productCode"></td>
                        <td>
                            <div>
                                <span v-text="item.productName"></span>
                                <span v-if="item.productUnit" v-text="' (' + item.productUnit.unitName + ')'"></span>
                            </div>
                        </td>
                        <td v-text="item.sendQuantity"></td>
                        <td v-text="item.receiveQuantity"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div>
                <div>
                    <span>Ghi chú chi nhánh chuyển: </span>
                    <span v-text="data.sendDescription"></span>
                </div>
                <div>
                    <span>Ghi chứ chi nhánh nhận: </span>
                    <span v-text="data.receiveDescription"></span>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="row">
                <div class="col-6">
                    Người chuyển
                </div>
                <div class="col-6">
                    Người nhận
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import store from "../../store";
    import {DISCOUNT_TYPE_PERCENT, parseIntEx, TYPE_INVOICE, TYPE_REQUEST, TYPE_TRANSFER} from "../../lib/utils";

    export default {
        name: "ModalPrintTransfer",
        props: ['data', 'items'],
        data() {
            return {
                TYPE_INVOICE, TYPE_TRANSFER, TYPE_REQUEST
            }
        },
        computed: {
            currentBranch: () => store.state.CurrentBranch,
        }
    }
</script>

<style scoped lang="scss">
    .wrap {
        font-size: 16px;
        font-weight: 600;
    }

    .header {
        text-align: center;
    }

    .footer {
        margin-top: 20px;
        text-align: center;

        .tks {
            font-size: 18px;
            font-weight: 600;
        }

        .powered {
            font-style: italic;
        }
    }

    .table-items {
        margin: 20px 0;
        width: 100%;

        thead tr {
            border-top: 1px solid #000;
            border-bottom: 1px solid #000;
        }

        tbody {
            tr {
                border-bottom: 1px dashed #000;
            }
        }
    }

    .total-payment {
        text-align: right;
        font-weight: bold;

        div {
            margin: 5px 0;

            span:last-child {
                min-width: 150px;
                display: inline-block;
            }
        }
    }
</style>
