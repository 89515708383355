import {isEmpty, numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
import {clone} from "@/lib/utils";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {                     
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            for (let index = 1; index < dataValue.convertProducts.length; index++) {
                let template = htmlObject.querySelector("#content-product").children[0];
                let clone = template.cloneNode(true);
                clone.children[0].innerHTML = "";
                let node = document.createElement("tbody");
                node.innerHTML = clone.outerHTML;
                htmlObject.querySelector("#content-product").appendChild(node);
            }
            templateOrder = htmlObject.outerHTML;
            dataValue.convertProducts.forEach((product) => {
                product.forEach((data, index) => {
                    templateOrder =
                        templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                        templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                });
            });
            return templateOrder;
         },
        getPriceImportPrint(id, productUnitId, importForSupplier){
            let price = 0;
            if(importForSupplier?.details){
                importForSupplier.details.forEach(detail => {
                    if(detail.productId == id && detail.productUnitId == productUnitId){
                        price = detail.price - (detail.discount / detail.quantity);
                    }
                });
            }
            return price;
        },       
        async renderData1(dataValue){                              
            let templateOrder = this.content;     
            let image = null;                                   
            dataValue.common.forEach(data => {                                                
                switch (data.key) {      
                    case 'Logo_Cua_Hang':
                        image = '<img src="' + data.value + '">';                
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', image)?? '';
                        break;                      
                    case 'Tong_So_Luong_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Tong_Tien_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Giam_Gia_Dat_Hang_Nhap':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Muc_Chi_Phi_Nhap_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Tong_Cong':                        
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;
                    case 'Muc_Chi_Phi_Nhap_Hang_Khac':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                        break;         
                    case 'Chi_Phi_Nhap_Hang':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng')?? null;
                        break;    
                    case 'Chi_Phi_Nhap_Hang_Khac':
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng khác')?? null;
                        break;          
                    default:
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', data.value ) ?? null;
                        break;
                }                  
            });
            return this.formatShowProduct(dataValue, templateOrder);
        },    
        async renderData(dataValue){                              
            let templateOrder = this.content;     
            let image = null;                                   
            dataValue.common.forEach(data => {                                                
                switch (data.key) {      
                    case 'Logo_Cua_Hang':
                        image = '<img src="' + data.value + '">';                
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', image)?? '';
                        break; 
                    default:
                        templateOrder = templateOrder.replaceAll('{' + data.key + '}', data.value ) ?? null;
                        break;
                }                  
            });            
            return templateOrder;            
        },
        getStatusName(type, status){
            let  name = '';
            let lstStatus = null;
            switch (type) {
                case 'impForSupplier':                    
                    lstStatus = this.$constant.listStatusImportOrder;
                    break;
                case 'ordForSupplier':
                    lstStatus = this.$constant.listStatusOrderSupplier;
                    break;
                case 'returnForSupplier':
                    lstStatus = this.$constant.listStatusReturnOrderSupplier;
                    break;            
                default:
                    break;
            }            
            lstStatus.forEach(item => {
                if(item.id == status) name = this.$t(item.name);
            });            
            return name;
        },
        getNameTypePayment(entry){            
            let name = '';
            if(entry.cash != 0 ) name = name + 'Tiền mặt'
            if(entry.card != 0) name = name + ', ' + 'Thẻ'
            if(entry.transfer != 0) name = name + ', ' + 'Chuyển khoản'            
            return name;  
        },
        getValueTypePayment(entry){            
            let value = '';
            if(entry.cash !== 0) value = value + numberFormat(entry.cash);
            if(entry.card !== 0) value = value + '; ' +numberFormat( entry.card);
            if(entry.transfer !== 0) value = value + '; ' + numberFormat(entry.transfer);            
            return value;  
        },
        getPaymentForSupplier(entry){            
            let totalPrice = numberFormat((Number(entry.cash) + Number( entry.card) + Number(entry.transfer)) - Number(entry.paymentForSupplier));               
            return totalPrice;  
        },
        async dataPrint(entry) {            
            //Lấy tên chi nhánh      
            let branchName =  entry.branch?.name ? entry.branch?.name :  (entry.import_for_supplier?.branch.name?? null);
            let address =  entry?.branch && entry.branch?.address ? entry.branch.address.name : '';
            let wardName =  entry?.branch && entry.branch?.ward ? entry.branch.ward.name : '';
            let districtName =  entry?.branch && entry.branch?.district ? entry.branch.district.name : ''
            let provinceName =  entry?.branch && entry.branch?.province ? entry.branch.province.name : '';
            let phone = entry?.branch && entry.branch?.phone ? entry.branch.phone : '';
            let common = [
                {key:'Nguoi_Tao',value: entry.created_by ? entry.created_by.name : null},
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry.branch ? entry.branch.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: entry.branch ? entry.branch.address : ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch ? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_gian_tao_phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ngay_Thang_Nam_Tao', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: ''},
                {key:'Nhan_Vien_Ban_Hang', value: entry.created_by ? entry.created_by.name : ''},
            ];
            let keyOrderSupplier = [];
            if(entry?.reasonForPayment) entry.reason = entry.reasonForPayment;    
            // Phiếu thu
            if (entry.typePrint == this.$constant.TypePrintReceipts) {                
                if (entry.typeDiscount) {
                    entry.priceDiscountRatio = entry.priceDiscount/entry.totalPrice;
                } else {
                    entry.priceDiscountRatio = '';
                }                        
                keyOrderSupplier = [
                    {key:'Tieu_De_In',value: "PHIẾU THU"},
                    {key:'Dia_Chi_Cua_Hang', value: address},
                    {key:'Phuong_Xa_Cua_Hang', value: wardName},
                    {key:'Khu_Vuc_Cua_Hang', value: districtName},
                    {key:'Khu_Vuc_Cua_Hang_QH_TP', value: districtName + ', ' + provinceName},
                    {key:'So_Dien_Thoai_Cua_Hang', value: phone},
                    {key:'Ngay_Thang_Nam', value: moment(entry.created_at).format('DD/MM/YYYY')},
                    {key:'Ngay_Thang_Nam_Gio_Phut', value: moment(entry.created_at).format('DD/MM/YYYY HH:mm')},
                    {key:'Ngay_Lap_Phieu', value: moment(entry.created_at).format('DD')},
                    {key:'Thang_Lap_Phieu',  value: moment(entry.created_at).format('MM')},
                    {key:'Nam_Lap_Phieu', value: moment(entry.created_at).format('YYYY')},
                    {key:'Chi_Nhanh_Ban_Hang', value: branchName},
                    {key:'Khu_Vuc_Chi_Nhanh', value: wardName},
                    {key:'Khu_Vuc_Chi_Nhanh_QH_TP', value: districtName + ', ' + provinceName},
                    {key:'Nhan_Vien', value: entry.user.name},
                    {key:'Phuong_Thuc_Thanh_Toan', value: ''},
                    {key:'Loai_Thu_Chi', value: entry.typeTicket?? ''},
                    {key:'Ly_Do', value: entry.reason?? ''},
                    {key:'Cong_Ty', value: this.getInfo(entry, 'Cong_Ty')?? ''},
                    {key:'Doi_Tac', value: this.getInfo(entry, 'Cong_Ty')?? ''},
                    {key:'Doi_Tuong', value: this.getInfo(entry, 'Doi_Tuong')?? ''},
                    {key:'So_Dien_Thoai', value: this.getInfo(entry, 'So_Dien_Thoai')?? ''},
                    {key:'So_Dien_Thoai_An', value:  this.convertPhone(this.getInfo(entry, 'So_Dien_Thoai_An')) ?? ''},
                    {key:'Dia_Chi', value: this.getInfo(entry, 'Dia_Chi')?? ''},
                    {key:'Phuong_Xa', value: this.getInfo(entry, 'Phuong_Xa')?? ''},
                    {key:'Khu_Vuc', value: this.getInfo(entry, 'Khu_Vuc')?? ''},
                    {key:'Ma_Phieu', value: entry.code},
                    {key:'Gia_Tri_Phieu', value: numberFormat(entry.amount)?? 0},
                    {key:'Gia_Tri_Phieu_Bang_So',  value: numberFormat(entry.amount)?? 0},
                    {key:'Gia_Tri_Bang_Chu', value: entry.amount ? this.toVietnamWord(+entry.amount) : this.toVietnamWord(0)},
                    {key:'Du_No_Truoc', value: 0},
                    {key:'Du_No_Truoc_Bang_So', value: 0},
                    {key:'Du_No_Sau', value: 0},
                    {key:'Du_No_Sau_Bang_So', value: 0},
                ]
            } 
            // Phiếu chi 
            if (entry.typePrint == this.$constant.TypePrintPayments) {                
                if (entry.typeDiscount) {
                    entry.priceDiscountRatio = entry.priceDiscount/entry.totalPrice;
                } else {
                    entry.priceDiscountRatio = '';
                }           
                keyOrderSupplier = [
                    {key:'Tieu_De_In',value: "PHIẾU CHI"},
                    {key:'Dia_Chi_Cua_Hang', value: address},
                    {key:'Phuong_Xa_Cua_Hang', value: wardName},
                    {key:'Khu_Vuc_Cua_Hang', value: districtName},
                    {key:'Khu_Vuc_Cua_Hang_QH_TP', value: districtName + ', ' + provinceName},
                    {key:'So_Dien_Thoai_Cua_Hang', value: phone},
                    {key:'Ngay_Thang_Nam', value: moment(entry.created_at).format('DD/MM/YYYY')},
                    {key:'Ngay_Thang_Nam_Gio_Phut', value: moment(entry.created_at).format('DD/MM/YYYY HH:mm')},
                    {key:'Ngay_Lap_Phieu', value: moment(entry.created_at).format('DD')},
                    {key:'Thang_Lap_Phieu',  value: moment(entry.created_at).format('MM')},
                    {key:'Nam_Lap_Phieu', value: moment(entry.created_at).format('YYYY')},
                    {key:'Chi_Nhanh_Ban_Hang', value: branchName},
                    {key:'Khu_Vuc_Chi_Nhanh', value: wardName},
                    {key:'Khu_Vuc_Chi_Nhanh_QH_TP', value: districtName + ', ' + provinceName},
                    {key:'Nhan_Vien', value: entry.user.name},
                    {key:'Phuong_Thuc_Thanh_Toan', value: ''},
                    {key:'Loai_Thu_Chi', value: entry.typeTicket?? ''},
                    {key:'Ly_Do', value: entry.reason?? ''},
                    {key:'Cong_Ty', value: this.getInfo(entry, 'Cong_Ty')?? ''},
                    {key:'Doi_Tac', value: this.getInfo(entry, 'Cong_Ty')?? ''},
                    {key:'Doi_Tuong', value: this.getInfo(entry, 'Doi_Tuong')?? ''},
                    {key:'So_Dien_Thoai', value: this.getInfo(entry, 'So_Dien_Thoai')?? ''},
                    {key:'So_Dien_Thoai_An', value:  this.convertPhone(this.getInfo(entry, 'So_Dien_Thoai_An')) ?? ''},
                    {key:'Dia_Chi', value: this.getInfo(entry, 'Dia_Chi')?? ''},
                    {key:'Phuong_Xa', value: this.getInfo(entry, 'Phuong_Xa')?? ''},
                    {key:'Khu_Vuc', value: this.getInfo(entry, 'Khu_Vuc')?? ''},
                    {key:'Ma_Phieu', value: entry.code},
                    {key:'Gia_Tri_Phieu', value: numberFormat(entry.amount)?? 0},
                    {key:'Gia_Tri_Phieu_Bang_So',  value: numberFormat(entry.amount)?? 0},
                    {key:'Gia_Tri_Bang_Chu', value: entry.amount ? this.toVietnamWord(+entry.amount) : this.toVietnamWord(0)},
                    {key:'Du_No_Truoc', value: 0},
                    {key:'Du_No_Truoc_Bang_So', value: 0},
                    {key:'Du_No_Sau', value: 0},
                    {key:'Du_No_Sau_Bang_So', value: 0},
                ]
            }                                 
            common.push(...keyOrderSupplier);   
            return {common};                                                  
        },

        getName(entry){
            let name = null;
            if(!isEmpty(entry.order_for_supplier)) {
                name = entry.order_for_supplier?.supplier ? entry.order_for_supplier.supplier.name : '';
                return name;
            }
            if(!isEmpty(entry.import_for_supplier)){
                name = entry.import_for_supplier?.supplier ? entry.import_for_supplier.supplier.name : '';
                return name;
            }
            if(!isEmpty(entry.return_import_for_supplier)){
                name = entry.return_import_for_supplier?.supplier ? entry.return_import_for_supplier.supplier.name : '';
                return name;
            }    
            
            if(!isEmpty(entry.order)){
                name = entry.order?.customer ? entry.order.customer.name : '';  
                return name;                              
            } 
            if(!isEmpty(entry.invoice)){
                name = entry.invoice?.customer ? entry.invoice.customer.name : '';    
                return name;                            
            } 
            if(!isEmpty(entry.return)){
                name = entry.return?.customer ? entry.return.customer.name : '';  
                return name;                              
            } 
        },
        getPhone(entry){
            let contactNumber = '';
            if(entry.order_for_supplier && entry.order_for_supplier?.supplier) { 
                contactNumber = entry?.order_for_supplier  && entry.order_for_supplier?.supplier && entry.order_for_supplier.supplier?.contactNumber ? entry.order_for_supplier.supplier.contactNumber : (entry.order_for_supplier.supplier?.contactNumber1 ? entry.order_for_supplier.supplier.contactNumber1 : '');            
                return contactNumber;
            }
            if(entry.import_for_supplier){                
                contactNumber = entry?.import_for_supplier  && entry.import_for_supplier?.supplier && entry.import_for_supplier.supplier?.contactNumber ? entry.import_for_supplier.supplier.contactNumber : (entry.import_for_supplier.supplier?.contactNumber1 ? entry.import_for_supplier.supplier.contactNumber1 : '');            
                return contactNumber;
            }  
            if(entry.return_import_for_supplier){                
                contactNumber = entry?.return_import_for_supplier  && entry.return_import_for_supplier?.supplier && entry.return_import_for_supplier.supplier?.contactNumber ? entry.return_import_for_supplier.supplier.contactNumber : (entry.return_import_for_supplier.supplier?.contactNumber1 ? entry.return_import_for_supplier.supplier.contactNumber1 : '');            
                return contactNumber;
            }  
            if(entry?.customer){                             
                contactNumber = entry.customer?.contactNumber ? entry.customer.contactNumber : (entry.customer?.contactNumber1 ? entry.customer.contactNumber1 : '');
                return contactNumber;                              
            }         
        },
        convertPhone(phone){                        
            let newPhone = '';
            if(phone){
                for (let index = 0; index < phone.length; index++) {
                    if(index <= 5) newPhone = newPhone + '*';
                    else newPhone = newPhone + phone[index];                
                }
            }            
            return newPhone;
        },
        getAddress(entry){
            let address = null;
            if(!isEmpty(entry.order_for_supplier)) {                
                address = entry?.order_for_supplier && entry.order_for_supplier?.supplier && entry.order_for_supplier.supplier?.address ? entry.order_for_supplier.supplier.address : '';
                return address;
            }
            if(!isEmpty(entry.import_for_supplier)){                
                address = entry?.import_for_supplier && entry.import_for_supplier?.supplier && entry.import_for_supplier.supplier?.address ? entry.import_for_supplier.supplier.address : '';
                return address;
            }  
            if(!isEmpty(entry.return_import_for_supplier)){                
                address = entry?.return_import_for_supplier && entry.return_import_for_supplier?.supplier && entry.return_import_for_supplier.supplier?.address ? entry.return_import_for_supplier.supplier.address : '';
                return address;
            }  
            
            if(!isEmpty(entry.order)){                
                address = entry?.order && entry.order?.customer && entry.order.customer?.address ? entry.order.customer.address : '';  
                return address;                              
            } 
            if(!isEmpty(entry.invoice)){                
                address = entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.address ? entry.invoice.customer.address : '';  
                return address;                            
            } 
            if(!isEmpty(entry.return)){                
                address = entry?.return && entry.return?.customer && entry.return.customer?.address ? entry.return.customer.address : '';  
                return address;                              
            } 
        },
        getWards(entry){
            let name = null;
            if(!isEmpty(entry.order_for_supplier)) {                
                entry?.order_for_supplier && entry.order_for_supplier?.supplier && entry.order_for_supplier.supplier?.ward ? entry.order_for_supplier.supplier.ward.name : '';
                return name;
            }
            if(!isEmpty(entry.import_for_supplier)){                
                entry?.import_for_supplier && entry.order_for_supplier?.supplier && entry.import_for_supplier.supplier?.ward ? entry.import_for_supplier.supplier.ward.name : '';
                return name;
            }  
            if(!isEmpty(entry.return_import_for_supplier)){                
                entry?.return_import_for_supplier && entry.return_import_for_supplier?.supplier && entry.return_import_for_supplier.supplier?.ward ? entry.return_import_for_supplier.supplier.ward.name : '';
                return name;
            }              
            if(!isEmpty(entry.order) || !isEmpty(entry.invoice) || !isEmpty(entry.return)){
                name = entry?.customer &&  entry.customer?.address2 && entry.customer.address2?.wards ? entry.customer.address2.wards.name : '';                  
                return name;                              
            }            
        },
        getDistrictProvince(entry){
            let name = null;
            let provinceName = null;
            let dictrictName = null;            
            if(!isEmpty(entry.order_for_supplier)) {               
                dictrictName = entry?.order_for_supplier && entry.order_for_supplier?.supplier && entry.order_for_supplier.supplier?.district ? entry.order_for_supplier.supplier.district.name : '';                
                provinceName = entry?.order_for_supplier && entry.order_for_supplier?.supplier && entry.order_for_supplier.supplier?.province ? entry.order_for_supplier.supplier.province.name : '';
                name = dictrictName + ', ' + provinceName;
                return name;
            }
            if(!isEmpty(entry.import_for_supplier)){
              
                dictrictName = entry?.import_for_supplier && entry.import_for_supplier?.supplier && entry.import_for_supplier.supplier?.district ? entry.import_for_supplier.supplier.district.name : '';                
                provinceName = entry?.import_for_supplier && entry.import_for_supplier?.supplier && entry.import_for_supplier.supplier?.province ? entry.import_for_supplier.supplier.province.name : '';
                name = dictrictName + ', ' + provinceName;
                return name;
            }  
            if(!isEmpty(entry.return_import_for_supplier)){              
                dictrictName = entry?.return_import_for_supplier && entry.return_import_for_supplier?.supplier && entry.return_import_for_supplier.supplier?.district ? entry.return_import_for_supplier.supplier.district.name : '';                
                provinceName = entry?.return_import_for_supplier && entry.return_import_for_supplier?.supplier && entry.return_import_for_supplier.supplier?.province ? entry.return_import_for_supplier.supplier.province.name : '';
                name = dictrictName + ', ' + provinceName;
                return name;
            }              
            if(!isEmpty(entry.order) || !isEmpty(entry.invoice) || !isEmpty(entry.return)){
                dictrictName = entry?.customer &&  entry.customer?.address2 && entry.customer.address2?.districts ? entry.customer.address2.districts.name : '';  
                provinceName = entry?.customer &&  entry.customer?.address2 && entry.customer.address2?.provinces ? entry.customer.address2.provinces.name : '';  
                name = dictrictName + ', ' + provinceName;
                return name;                              
            } 
           
        },
        getObject(entry){
            let name = '';
            if(entry.applicableObject != null){
                let applicableObject = this.$constant.applicableObject;
                    applicableObject.forEach(item => {
                        if(item.id == entry.applicableObject) name = item.name;
                });                
            }
            else{                
                if(this.$constant.typeSrcCustomer.includes(entry.typeSrc)) name = 'Khách hàng';
                if(this.$constant.typeSrcSupplier.includes(entry.typeSrc)) name = 'Nhà cung cấp';                
            }
            return name;
        },
        getInfo(entry, type){
            let info = null;
            switch (type) {
                // Lấy tên công ty, nhân viên nhận/ nộp
                case 'Cong_Ty':
                    info = this.getName(entry);
                    break;
                case 'Doi_Tuong':
                    info = this.getObject(entry);
                    break;
                case 'So_Dien_Thoai':
                    info = this.getPhone(entry);
                    break;
                case 'So_Dien_Thoai_An':
                    info = this.getPhone(entry);                    
                    break;
                case 'Dia_Chi':
                    info = this.getAddress(entry);                    
                    break;
                case 'Phuong_Xa':
                    info = this.getWards(entry);                    
                    break;
                case 'Khu_Vuc':
                    info = this.getDistrictProvince(entry);                    
                    break;
                default:
                    break;
            }
            return info;
        },  
        formatproductPrintCommon(data) {   
            let dataPrint = clone(data);
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            let exsitProductIds = [];            
            dataPrint.details.forEach(item => {
                if (!exsitProductIds.includes(item.productId)) {
                    exsitProductIds.push(item.productId);
                }
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }
                let product = clone(item);
                product.product.name = product.product.name + ' ( ' + product.unit.unitName + ' )';
                if (product.consignment_detail && product.consignment_detail.length) {
                    product.consignment_detail.forEach(detail => { 
                        product.consignment = {};
                        product.consignment.name = detail.consignment.name,
                        product.consignment.expried = detail.consignment.expried,
                        product.quantity = detail.quantity,
                        product.discount = (item.discount/item.quantity) * detail.quantity;
                        if (dataPrint.typePrint == this.$constant.TypePrintReceipts || dataPrint.typePrint == this.$constant.TypePrintPayments) {
                            product.subTotal = product.price*detail.quantity;
                        } else {
                            product.subTotal = (product.price*detail.quantity) - product.discount;
                        }
                        totalPrice += +product.subTotal;
                        products.push(clone(product));
                    });
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));
                }
                if (product && product.plus) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            plus.product.consignments = product.product.consignments;
                            plus.consignment_detail.forEach(detail => {
                                plus.consignment = {};
                                plus.consignment.name = detail.consignment.name;
                                plus.consignment.expried = detail.consignment.expried;
                                plus.quantity = detail.quantity;
                                plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                                if (dataPrint.typePrint == this.$constant.TypePrintReceipts || dataPrint.typePrint == this.$constant.TypePrintPayments) {
                                    plus.subTotal = plus.price*detail.quantity;
                                } else {
                                    plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                                }
                                totalPrice += +plus.subTotal;
                                products.push(clone(plus));
                            });
                        } else {
                            totalPrice += +plus.subTotal;
                            products.push(clone(plus));
                        }
                    });
                }
            });
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            dataPrint.exsitProductIds = exsitProductIds;
            return dataPrint;
        },
    },
}
  