<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalRemoveUsers" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">                       
                    <h6 class="modal-title py-2">
                        {{ $t('message.employeeIndex.titlRemove') }}
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{ $t('message.employeeIndex.contentRemove') }}
                    <div class="col-12 d-flex justify-content-end mt-3">
                        <button class="btn btn-danger mr-2" @click="deleteData()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.employeeIndex.button.agree') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.employeeIndex.button.skip') }}
                        </button>
                    </div>
                </div>
           </div>
       </div>
   </div>
</template>
<script>
import { $post } from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemoveEmployees',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },

        async deleteData() {
            const res = await $post(`/v1/employee/remove/${this.id}`);
            if (res && res.result && res.result.haveTransaction) {
                toastr.error(res.result.message ?? this.$t('message.employeeIndex.alertRemoveError', { name: this.name }));
            }
            else if (res && res.status.code == 200 && res.result) {
                this.$emit('inputData');
                toastr.success(this.$t('message.employeeIndex.alertRemoveSuccess', { name: this.name }));
            }
            else {
                toastr.error(this.$t('message.employeeIndex.alertRemoveError', { name: this.name }));
            }
            window.jQuery(this.$refs.modal).modal('hide');
        }
    }
}
</script>
<style>
</style>