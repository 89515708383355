<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">
                        {{ $t('message.SupplierGroupIndex.titleRemove') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <h6>
                        {{ $t('message.SupplierGroupIndex.contentRemove') }}
                    </h6>
                    <div class="col-12 d-flex justify-content-end mt-4">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import toastr from 'toastr';
import emitter from "@/lib/emitter";
export default {
    name: 'ModalRemoveSuppliersGroup',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {            
            this.id = id;            
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData() {           
            const res = await $post('/v1/supplier-groups/remove',{id: this.id, name: this.name});            
            if(res && res.status?.code && res.status.code == 200 && res.result?.status && res.result.status){
                toastr.success(res.result.message);
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('input','1');                       
                // emitter.emit('changeSupplierGroup');
            }
            else {
                    toastr.error(res.result.message);
                    window.jQuery(this.$refs.modal).modal('hide');            
                    this.$emit('input' , '1');     
            }
        }
    }
}
</script>
<style>
</style>