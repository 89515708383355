<template>
  <div class="row mt-2 pl-3" >        
        <div class="col-1 divStyle pr-0 " style="min-width: 78px !important;"><label class="pl-3 pt-2 px-0 "> <strong>{{ $t('message.common.printTemplate') }}:</strong> </label></div>
        <div class="col-3 py-2 pl-2" style="display: block">                 
            <SelectSearch   :placeholder="$t('message.common.choosePrintTemplate')" :modelValue="this.printTemplateId" :defaultOption="this.printTempData" 
                            :label="'purchaseBy' + this.action " :action="'print'" @loadPrintTemplateId="this.loadPrintTemplateId"> 
            </SelectSearch>
        </div>
        <div class="col-1 divStyle px-0">          
             <AppPermission :permission="[this.$permissions.printTemplates.update?? '', this.$permissions.printTemplates.remove]">
                <a  v-if="this.contents" href="javascript:0;" class="pr-4" @click="showModalEditPrintTemplate('update', this.printTemplateId)">
                    <i class="fa fa-pencil-alt text-dark" style="font-size:14px"></i>  
                </a>
            </AppPermission>            
            <AppPermission :permission="this.$permissions.printTemplates.create?? '' ">
                <a v-if="this.contents"  href="#"  @click="showModalEditPrintTemplate('add', this.printTemplateId)" class="pr-4">
                    <i class="fa fa-plus text-dark" style="font-size:14px"></i>            
                </a>
                <a v-if="!this.contents" href="#"  @click="showModalAddDataPrintTemplate(this.action)">
                    <i class="fa fa-database text-dark" style="font-size:14px"></i>            
                </a>
            </AppPermission>
        </div>
         <div class="col-12 d-flex align-items-center mx-3">
            <span style="min-width: 85px !important;" class="pr-2"><strong>{{ $t('message.common.branch') }}: </strong></span>            
            <!-- <q-select :default-options="branches" :multiple="true" v-model="this.branchIDs" :selectAll="true" :placeholder="$t('message.ModalProductFormTabMain.placeholder.branch')"
            :printTemplateStatus="true" /> -->
            <div class="row px-2 mx-3">
                <template v-if="this.branchIDs.length < branches.length">
                    <template v-for="branch in branches" :key=" branch.id">                    
                        <span class="styleDiv px-2 mx-1 my-1 py-1" v-if="this.branchIDs.includes(branch.id)">{{ branch.name}}</span>    
                    </template>                   
                </template>
                <template v-else-if="this.branchIDs.length <= branches.length" >
                    <span class=" styleDiv px-2 mx-1 my-1 py-1">{{$t('message.common.all')}}</span>    
                </template>
            </div>
         </div>
  </div>   
    <Design ref="Design" :entry ="this.entry" :action="this.action" :isShow="this.isShow" :content="this.content" />
    <ModalEditPrintTemplate ref='ModalEditPrintTemplate' :action="this.action" :html="this.html" :content="this.content" :templateId="this.templateId" 
    @delLoadData = "delLoadData" @addLoadData="addLoadData" @loadData="loadData" :printTemplateName="this.printTemplateName" :printTemplate="this.printTemplate"/>
    <ModalAddDataPrintTemplate ref="ModalAddDataPrintTemplate" @loadData="load()"/>
</template>
<script>
import SelectSearch from "@/components/SelectSearch";
import Design from './Design';
import ModalEditPrintTemplate from './ModalEditPrintTemplate';
import {$get, $post} from "@/lib/utils";
import emitter from "@/lib/emitter";
import ModalAddDataPrintTemplate from '../ModalAddDataPrintTemplate';
import AppPermission from '@/components/AppPermission.vue';
import permissionMixin from '@/permissionMixin';
import BarcodeGenerator from '@/components/products/BarcodeGenerator.vue';
import QSelect from '@/components/QSelect.vue';
import store from '@/store';
export default {
name: "DesignTemplates",
mixins: [permissionMixin],
components:{
    SelectSearch, Design,
    ModalEditPrintTemplate, 
    ModalAddDataPrintTemplate,AppPermission,
    BarcodeGenerator, QSelect
},
props: ['data', 'action', 'entry', 'isShow', 'html', 'printTempId', 'printTempData', 'content'],
created() {     
    this.load();  
},

data() { 
    const globalConfig = store.state.GlobalConfig;
    return {          
        contents: null,
        printTemplate: [],
        printTemplateId: this.printTempId,  
        printTemplateName: null,         
        printPaperId:null,   
        dataValue: null,  
        editorDataClone:null,
        templateId: null,
        isDel:false,
        branches: globalConfig.branches,
        branchIDs: []
    }
    },
watch: {
    content(newData) {        
        this.contents = newData;
        this.$emit('changeContent', newData);
    },   
    printTempData(newData){
        this.printTemplate = newData;
    },
    printTempId: {        
        handler(newId){            
            this.printTemplateId = newId;              
            this.load();        
        },
        deep: true 
    },
    editorDataClone(editor){
        this.editorDataClone = editor
    }
  },
methods: {
    showModalAddDataPrintTemplate(){
        this.$refs.ModalAddDataPrintTemplate.show();
    },
    async showModalEditPrintTemplate(typeUpdate, printTemplateId){
        if(!printTemplateId){
            printTemplateId = this.printTempId;
        }                
        this.$refs.ModalEditPrintTemplate.show(typeUpdate, printTemplateId);    
    },   
    loadPrintTemplateId(id,name){    
        this.printTemplateId = id;
        this.printTemplateName = name   
        localStorage.setItem('defaultID', id );        
        this.load();      
    },
    async renderData(){
            let image = '';
            let str ='';
            if(!this.dataValue) {
                const resValues = await $post('/v1/print-templates/get-value-print-template');                
                if(resValues.result){
                    this.dataValue = resValues.result;
                }            
            }            
            if(this.content){
                this.editorDataClone = this.content;                
                let barcode = '';
                this.dataValue.forEach(data => {                     
                    switch (data.key) {
                        case '{Logo_Cua_Hang}':
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, image);
                            break;
                        case '{Sort_Code}':                                 
                            barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=`+ data.value +`&amp;'
                                alt="" title="HELLO" width="80" height="80" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        case '{Ma_Van_Don_Ma_Vach}':                 
                            barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title="HELLO" width="150"  height="100" />`
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, barcode);
                            break;
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll(data.key, data.value);
                            break;
                    }                
                });    
            }
            
    },
    addLoadData(){        
        emitter.emit('reloadDataPrintTemplate');
    },
    
    loadData(newContent){
        this.contents = newContent;          
        this.load();
        // this.printTemplateId = localStorage.getItem('defaultID');
        // this.$emit('loadData');
        // emitter.emit('reloadDataPrintTemplate');
        // emitter.emit('loadDataContent',newContent);
    },
    delLoadData(){
        this.printTemplateId = 1;
        this.isDel = true;
        this.load();
    },
    async getBranch(printTemplateId){   
        const res = await $get(`/v1/print-templates/get-content-by-id`,{id: printTemplateId});  
        if(res && res.status.code ==200 && res.result && res.result.name!='') {           
            this.branchIDs = res.result.branchIds;                     
        }           
    },
    async load() {  
        await this.getBranch(this.printTemplateId);    
        const res = await $get(`/v1/print-templates/get-content-print-templates`,{id: this.printTemplateId});                    
        if(res && res.status.code == 200){
            if(res.result) {                    
                this.contents = res.result.content;                     
            }
        }                     
        this.renderData();
        if(this.isDel) {             
             emitter.emit('loadDataContent',this.content);
        }
        await emitter.emit('loadDataContentById',this.printTemplateId);              
    },
},
};
</script>

<style scoped>
    .printBox {
        font-family: Arial, sans-serif;
        font-size: 11px;
    }

    table {
        page-break-inside: auto;
        border-collapse: collapse;
    }

    tr {
        page-break-inside: avoid;
        page-break-after: auto
    }

    img {
        max-width: 100%;
        height: auto;
    }

    .wrap-logo {
        /*border-bottom: 1px solid #0b0b0b;*/
        margin-bottom: 30px;
        text-align: center;
    }

    .footer-delivery {
        position: fixed;
        text-align: center;
        bottom: 0px;
        width: 100%;
    }
    .styleDiv {
        background-color: #2fa1a1; 
        border-radius: 5px; 
        color:#fff;
    }
</style>