<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.branchIndex.modal.title_remove') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4">
                    {{ $t('message.branchIndex.modal.content_remove') }}
                    <div class="col-12 d-flex justify-content-end pt-2">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.branchIndex.modal.button.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.branchIndex.modal.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $post, $alert } from "@/lib/utils";
import emitter from "@/lib/emitter";
import toastr from 'toastr';
import store from "@/store";
export default {
    name: 'ModalRemoveBranch',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData() {
            const data = await $post(`/v1/branches/remove/${this.id}`, {branchId: store.state.CurrentBranch.id});
            if(!data.result.status && data.result.message){
                $alert({code: 422, message: data.result.message});
            }else{
                const message = this.$t('message.branchIndex.modal.alerts.success_remove', {name: this.name});
                $alert({code: 200, message: message});
            }
            this.$emit('input', '1');
            this.$emit('updated', this.id)
            emitter.emit('deleted')
            this.$emit('inputData','1');
            window.jQuery(this.$refs.modal).modal('hide');
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>