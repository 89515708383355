<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px;">
            <div class="modal-content">
                <div class="modal-header py-1 bg-custom-modal" style="border-bottom:1px solid #ccc ;">
                    <h5 v-if="isEdit" style="fotn" class="modal-title py-2">
                        {{$t('message.PriceListsIndex.modal.titleUpdate')}}
                    </h5>
                    <h5 v-else style="fotn" class="modal-title py-2">
                        {{$t('message.PriceListsIndex.modal.titleCreate')}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header custom__list-tab bg-custom-modal pb-0 pt-0 text-center" style="border-bottom:1px solid #ccc ;">
                    <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item']" @click="choseTab($constant.tab.first)">
                        {{$t('message.PriceListsIndex.modal.info')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item']" @click="choseTab($constant.tab.second)">
                        {{$t('message.PriceListsIndex.modal.applyArea')}}
                    </div>
                    <!-- <div :class="[ isTab === $constant.tab.third ? 'tab__active' : '', 'tab__item']" @click="choseTab($constant.tab.third)">Thiết lập nâng cao</div> -->
                </div>
    
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1" v-if="isTab === $constant.tab.first">
                                <div class="col-12 p-0">
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.PriceListsIndex.modal.name')}}<label class="text-danger px-0">*</label> </b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="form.name"  :placeholder="$t('message.PriceListsIndex.placeholder.search')" class="form-control input-custom-1" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{$t('message.PriceListsIndex.modal.startDate')}}</b>
                                        </div>
                                        <div class="col-4 description-job-title">
                                            <input type="date" name="description" v-model="form.startDate" class="form-control input-custom-1">
                                            <span class="label__time">{{ form.startTime }}</span>
                                            <i class="fa fa-clock ml-2 text-secondary" data-toggle="dropdown"></i>
                                            <!-- Danh sách -->
                                            <ul class="dropdown-menu format__list-time">
                                                <template  v-for="(n, i) in 24" :key="i">
                                                    <li style="padding-left: 10px;" @click="setTimeStart(('0' + i ).slice(-2) + ':00')">{{ ('0' + i ).slice(-2) + ':00' }}</li> 
                                                    <li style="padding-left: 10px;" @click="setTimeStart(('0' + i ).slice(-2) + ':30')">{{ ('0' + i ).slice(-2) + ':30' }}</li>
                                                </template>
                                            </ul>
                                        </div>
                                        <div class="col-1 d-flex align-items-center">
                                            {{$t('message.PriceListsIndex.modal.to')}}
                                        </div>
                                        <div class="col-4 description-job-title">
                                            <input type="date" name="description" v-model="form.endDate" class="form-control input-custom-1">
                                            <span class="label__time">{{ form.endTime }}</span>
                                            <i class="fa fa-clock ml-2 text-secondary" data-toggle="dropdown"></i>
                                            <!-- Danh sách -->
                                            <ul class="dropdown-menu format__list-time">
                                                <template  v-for="(n, i) in 24" :key="i">
                                                    <li style="padding-left: 10px;" @click="setTimeEnd(('0' + i ).slice(-2) + ':00')">{{ ('0' + i ).slice(-2) + ':00' }}</li> 
                                                    <li style="padding-left: 10px;" @click="setTimeEnd(('0' + i ).slice(-2) + ':30')">{{ ('0' + i ).slice(-2) + ':30' }}</li>
                                                </template>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{$t('message.PriceListsIndex.status')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="row">
                                                <div class="col-md-3">
                                                    <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.apply')}}
                                                        <input type="radio" :value="$constant.statusActive" v-model="form.status" name="flexRadioDefault" id="flexRadioDefault1">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-md-4">
                                                    <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.notApply')}}
                                                        <input type="radio" :value="$constant.statusLock" v-model="form.status" name="flexRadioDefault" id="flexRadioDefault2">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.second">
                                <!-- chi nhánh -->
                                <div class="row mx-4 my-4 mt-1 border-bottom">
                                    <div class="col-12 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.allBranch')}}
                                                    <input type="radio" v-model="this.form.isBranchFull" :value="$constant.statusCheckAll" @click="clearDataBranches()">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.branch')}}
                                                    <input type="radio" v-model="this.form.isBranchFull" :value="$constant.statusCheckAPart">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8">
                                                <q-select v-model="form.branchesIds" :readonly="form.isBranchFull != $constant.statusCheckAPart" :defaultOptions="branches" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseBranch')" :cardSettingPrice="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-4 my-4 mt-1 border-bottom">
                                    <div class="col-12 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.allUser')}}
                                                    <input type="radio" :value="$constant.statusCheckAll" v-model="form.isUsersTransactionFull" @click="clearDataUsersTransaction()">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.createdBy')}}
                                                    <input type="radio" :value="$constant.statusCheckAPart" v-model="form.isUsersTransactionFull">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8">
                                                <q-select v-model="form.usersTransactionIds" :readonly="form.isUsersTransactionFull != $constant.statusCheckAPart" :defaultOptions="usersTransactions" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseUser')" :cardSettingPrice="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mx-4 my-4 mt-1 border-bottom">
                                    <div class="col-12 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.allCustomer')}}
                                                    <input type="radio" :value="$constant.statusCheckAll" v-model="form.isCustomerFull" @click="clearDataCustomer('all')">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8"></div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.customerGroup')}}
                                                    <input type="radio" :value="$constant.statusCheckGroup" v-model="form.isCustomerFull" @click="clearDataCustomer('groups')">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8">
                                                <q-select v-model="form.customerGroupsIds" :readonly="form.isCustomerFull != $constant.statusCheckGroup" :defaultOptions="customerGroups" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseCustomerGroup')" :cardSettingPrice="true"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-2 p-0">
                                        <div class="row">
                                            <div class="col-4">
                                                <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.guest')}}
                                                    <input type="radio" :value="$constant.statusCheckFIt" v-model="form.isCustomerFull" @click="clearDataCustomer('fit')">
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                            <div class="col-8">
                                                <q-select v-model="form.customersIds" :readonly="form.isCustomerFull != $constant.statusCheckFIt" :defaultOptions="customers" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseCustomer')" :cardSettingPrice="true"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.third">
                                <!-- chi nhánh -->
                                <div class="row mx-4 my-4 mt-1 border-bottom">
                                    <div class="col-2 p-0 d-flex align-items-center">
                                        <h6 class="mb-0"><b>{{$t('message.PriceListsIndex.modal.price')}} =</b></h6> 
                                    </div>
                                    <div class="col-3 p-0">
                                        <div class="select-option dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <template v-for="priceList in this.priceList.all" :key="priceList.id">
                                                <span v-if="priceList.id === this.priceList.selected" v-text="priceList.name"></span>
                                            </template>
                                        </div>
                                        <div class="dropdown-menu options-dt" aria-labelledby="dropdownMenuButton">
                                            <input type="text" class="min-w-100" v-model="this.priceList.search" @input="onInputPriceList">
                                            <i class="fa fa-search btn-search"></i>
                                            <template v-if="this.priceList.filter?.length > 0">
                                                <a class="dropdown-item pl-0" href="javascript:;" v-for="priceList in this.priceList.filter" :key="priceList.id" :value="priceList.id" @click="choosePriceList(priceList)">
                                                    {{priceList.name}}<i class="fa fa-check" v-if="this.priceList.selected == priceList.id"></i>
                                                </a>
                                            </template>
                                            <template v-else>
                                                <span class="ow-break-word" href="javascript:;">
                                                    {{$t('message.common.notFound')}}
                                                </span>
                                            </template>
                                        </div>
                                    </div>
                                    <div class="col-2 p-0 d-flex align-items-center">
                                        <button type="button" class="btn btn-default ml-2 text-white" 
                                        v-for="opt in this.$constant.calculatorOptions" :key="opt.id" :disabled="this.priceList.selected === null"
                                         :class="this.priceList.selected != null && this.calculatorOption == opt.id ? 'btn btn-save' : ''"
                                         @click="changeCalculatorOption(opt.id)">{{opt.name}}</button> 
                                    </div>
                                    <div class="col-3 p-0 d-flex align-items-center">
                                        <input type="number" class="input-custom min-w-100 h-100 text-right bg-transparent" min="0" :disabled="this.priceList.selected === null" v-model="this.calcPriceType"> 
                                    </div>
                                    <div class="col-2 p-0 d-flex align-items-center">
                                        <button type="button" class="btn btn-default ml-2 text-white" 
                                        v-for="opt in this.$constant.discountOptions" :key="opt.id" 
                                        :disabled="this.priceList.selected === null" :class="this.priceList.selected != null && this.discountOption == opt.id ? 'btn btn-save' : ''"
                                        @click="changeDiscountOption(opt.id)">{{opt.name}}</button> 
                                    </div>
                                </div>
                                <div class="row mx-4 my-4 mt-1">
                                    <div class="col-12 p-0">
                                        <label class="container min-w-100 pl-0">
                                            <b>{{$t('message.PriceListsIndex.modal.chooseProduct')}}</b>
                                        </label>
                                    </div>
                                    <div class="col-12 p-0">
                                        <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.chooseProductDifferentPrice')}}
                                            <input type="radio" :value="$constant.statusAllow" v-model="form.isChoosePriceList">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-12 p-0">
                                        <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.chooseProductAndWarning')}}
                                            <input type="radio" :value="$constant.statusAllowAndWarning" v-model="form.isChoosePriceList">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                    <div class="col-12 p-0">
                                        <label class="container-radio min-w-100">{{$t('message.PriceListsIndex.modal.notChooseProductDifferentPrice')}}
                                            <input type="radio" :value="$constant.statusNotAllow" v-model="form.isChoosePriceList">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                             
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                     <AppPermission :permission="[this.$permissions.settingPrice.update ?? '', this.$permissions.settingPrice.create ?? '']">
                                    <button type="button" class="btn btn-save mr-2" @click="submit">
                                        <i class="fa fa-save mr-1"></i>
                                        {{$t('message.button.save')}}
                                    </button>
                                    </AppPermission>
                                        <button type="button" class="btn btn-secondary text-white  mr-2" @click="clearInput">
                                            <i class="fa fa-ban mr-1"></i>
                                            {{$t('message.button.skip')}}
                                        </button>
                                    <AppPermission :permission="this.$permissions.settingPrice.delete ">
                                        <button v-if="isEdit" type="button" class="btn btn-danger mx-1" :href="'javascript:;'"  @click="showModalRemovePriceList()">
                                            <i class="fa fa-trash-alt"></i>
                                            {{$t('message.button.remove')}}
                                        </button>
                                     </AppPermission>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()"  :title="$t('message.PriceListsIndex.modal.confirm')" :content=" isEdit ? $t('message.PriceListsIndex.modal.contentCancelUpdate') : $t('message.PriceListsIndex.modal.contentCancelCreate')"/>
    <ModalConfirmDelete ref="ModalConfirmDelete" @updated="getData"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import {$post, $alert, $get, removeVietnameseTones} from "@/lib/utils";
import ModalConfirmDelete from './ModalConfirmDelete.vue';
import ModalConfirm from "@/components/ModalConfirm.vue";
import moment from 'moment';
import store from "../../store";
import QSelect from "@/components/QSelect";
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalCreateisEditPriceList",
    components: { InputText , ModalConfirm, ModalConfirmDelete, QSelect, AppPermission },
    props: {
        openModal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        const globalConfig = store.state.GlobalConfig;
        let currentDate = new Date().toJSON().slice(0, 10);
        let defaultEndDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toJSON().slice(0, 10);
        return {
            isEdit: false,
            isTab: this.$constant.tab.first,
            branches: globalConfig.branches,
            usersTransactions: null,
            customerGroups: globalConfig.cusGroup,
            customers: null,
            form: {
                name: null,
                nameOrigin: null,
                startDate: currentDate,
                endDate: defaultEndDate,
                status: this.$constant.statusActive,
                startTime: moment().format('HH:mm'),
                endTime: moment().format('HH:mm'),
                id: null,
                customersIds: [],
                customerGroupsIds: [],
                usersTransactionIds: [],
                branchesIds: [],
                isBranchFull: this.$constant.statusCheckAll,
                isCustomerFull: this.$constant.statusCheckAll,
                isUsersTransactionFull: this.$constant.statusCheckAll,
                isChoosePriceList: this.$constant.statusAllow,
            },
            priceList: {
                selected: null,
                search: null,
                filter: null,
                all: null,
            },
            calculatorOption: 0,
            discountOption: 1,
            calcPriceType: 0,
        }
    },
    created() {
        this.getUsersTransaction();
        this.getCustomers();
        this.getPriceList();
    },
    methods: {
        async getCustomers(){
            const res = await $get('/v1/setting-price/get-customers');
            this.customers = res.result;
        },
        async getUsersTransaction(){
            const res = await $get('/v1/setting-price/get-user-transaction');
            this.usersTransactions = res.result;
        },
        show (data = null) {
            window.jQuery(this.$refs.modal).modal('show');
            if (data) {
                this.form.nameOrigin = data.name,
                this.isEdit = true;
                this.form.name = data.name;
                this.form.id = data.id;
                this.form.startDate = this.formatDate(data.startDate);
                this.form.endDate =  this.formatDate(data.endDate);
                this.form.status = data.status;
                this.form.startTime = moment(data.startDate).format('HH:mm');
                this.form.endTime = moment(data.endDate).format('HH:mm');
                this.form.customersIds = data.customersIds;
                this.form.customerGroupsIds = data.customerGroupsIds;
                this.form.usersTransactionIds = data.usersTransactionIds;
                this.form.branchesIds = data.branchesIds;
                this.form.isBranchFull = data.isBranchFull,
                this.form.isUsersTransactionFull = data.isUsersTransactionFull,
                this.form.isCustomerFull = data.isCustomerFull
            }
        },
        formatDate(date) {
            var d = new Date(date),
                month = '' + (d.getMonth() + 1),
                day = '' + d.getDate(),
                year = d.getFullYear();
            if (month.length < 2) 
                month = '0' + month;
            if (day.length < 2) 
                day = '0' + day;
            return [year, month, day].join('-');
        },
        hide() {
            window.jQuery(this.$refs.modal).modal('hide');
            this.clearData();
        },
        clearInput() {
            if (this.form.name != '' || this.form.note != '') {
                this.$refs.ModalConfirm.show();
            } else {
                this.setValue();
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        async submit() {
            let params = {
                name: this.form.name?.trim(),
                status: this.form.status,
                startDate: this.form.startDate + ' ' +this.form.startTime,
                endDate: this.form.endDate + ' ' + this.form.endTime,
                id: this.form.id,
                customersIds: this.form.customersIds,
                customerGroupsIds: this.form.customerGroupsIds,
                usersTransactionIds: this.form.usersTransactionIds,
                branchesIds: this.form.branchesIds,
                isBranchFull: this.form.isBranchFull,
                isCustomerFull: this.form.isCustomerFull,
                isUsersTransactionFull: this.form.isUsersTransactionFull,
                isCustomerGroupFull: this.form.isCustomerGroupFull,
            }
            if (this.isEdit) {
                const result = await $post('/v1/setting-price/update-price-list', {...params});
                this.beforeSubmit(result);
            } else {
                const result = await $post('/v1/setting-price/create-price-list', {...params});
                this.beforeSubmit(result);
            }
        },
        beforeSubmit(result) {
            if (result.code === 422) {
                let errors = result.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: result.code, message: errors[key][0]});
                });
            } else {
                $alert({code: 200, message: this.isEdit ? this.$t('message.PriceListsIndex.modal.alertUpdateSuccess') : this.$t('message.PriceListsIndex.modal.alertCreateSuccess')});
                window.jQuery(this.$refs.modal).modal('hide');
                this.clearData();
                this.$emit('updated');
            }
        },
        setTimeStart(time){
            this.form.startTime = time;
        },
        setTimeEnd(time){
            this.form.endTime = time;
        },
        clearData() {
            this.form.name = null;
            this.form.startDate = new Date().toJSON().slice(0, 10);
            this.form.endDate = new Date(new Date().setFullYear(new Date().getFullYear() + 1)).toJSON().slice(0, 10);
            this.form.status = this.$constant.statusActive;
            this.form.startTime = moment().format('HH:mm');
            this.form.endTime = moment().format('HH:mm');
            this.form.id = null;
            this.isEdit = false;
            this.form.customersIds = [];
            this.form.customerGroupsIds = [];
            this.form.usersTransactionIds = [];
            this.form.branchesIds = [];
            this.form.isBranchFull = this.$constant.statusCheckAll,
            this.form.isUsersTransactionFull = this.$constant.statusCheckAll,
            this.form.isCustomerFull = this.$constant.statusCheckAll,
            this.isTab = this.$constant.tab.first
        },
        clearDataBranches() {
            this.form.branchesIds = [];
        },
        clearDataUsersTransaction() {
            this.form.usersTransactionIds = [];
        },
        clearDataCustomer(option) {
            switch (option) {
                case 'all':
                    this.form.customersIds = [];
                    this.form.customerGroupsIds = [];
                    break;
                case 'groups':
                    this.form.customersIds = [];
                    break;
                case 'fit':
                    this.form.customerGroupsIds = [];
                    break;
                default:
                    break;
            }
        },
        showModalRemovePriceList() {
            this.$refs.ModalConfirmDelete.show(this.form.id, this.form.nameOrigin);
        },
        getData() {
            this.$emit('delete', this.form.id);
            this.hide();
        },
        choseTab(tab) {
            this.isTab = tab;
        },
        async getPriceList() {
            const res = await $get('/v1/setting-price/get-price-lists');
            if (res.status.code != 200) {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
                return;
            } 
            res.result = [{id: null, name: this.$t('message.PriceListsIndex.modal.choosePriceList')}, ...res.result];
            this.priceList.all = res.result;       
            this.priceList.filter = res.result;
        },
        choosePriceList(entry){
            this.priceList.selected = entry.id;
        },
        onInputPriceList(){
            if(this.priceList.search == ''){
                this.priceList.filter = this.priceList.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.priceList.all.length; index++) {
                if (removeVietnameseTones(this.priceList.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.priceList.search.toLowerCase())) > -1){
                    arr.push(this.priceList.all[index]);
                }
            }
            this.priceList.filter = arr;

        },
        changeCalculatorOption(id){
            this.calculatorOption = id;
        },
        changeDiscountOption(id){
            if(id != this.discountOption){
                this.calcPriceType = 0;
                this.discountOption = id;
            }
        }
    },
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
.description-job-title {
    position: relative;
}
.description-job-title i{
    position: absolute;
    top: 0.6rem;
    right: 1rem;
}
.description-job-title input{
    padding-right: 1rem;
}
.label__time {
    position: absolute;
    top: 0.4rem;
    right: 5rem;
}
.block__depament {
    position: relative;
}
.format__list-time {
    top: 10px !important;
    left: -185px !important;
    width: 220px !important;
    height: 160px;
    overflow: auto;
}
.custom__list-tab {
    /* padding: 30px; */
    justify-content: flex-start;
    /* height: 50px; */
    align-items: end;
    font-size: 16px;
    padding-bottom: 8px;
}
.tab__active {
    border-bottom: 2px solid $base-color;
}
.tab__item {
    /* min-width: 150px; */
    cursor: pointer;
    padding: 0 15px;
}
.item__body {
    height: 30px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.list__item {
    width: 300px;
}
.input:focus-visible {
    border: none !important;
}
.input__select {
    width: 300px;
    border: none;
    border-bottom: 1px solid $base-color;
}
.border-bottom{
    border-bottom: 1px solid $base-color !important;
    padding-bottom: 20px;
}
.dropdownSupplier {
    max-width: 16vw;
    max-height: 200px;
}
.custom__block {
    margin-right: 12px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 12px;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.options-dt input:focus,.options-dt input:hover{
    border-bottom: 2px solid #0dac50;
}
</style>