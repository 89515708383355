<template>
    <div class="row">
        <ActionBar back-url="/promotions/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Khuyến mại</h1>
            </div>

            <TabNavigation @tabChange="tabChange"
                           ref="tab" :tabs="tabs"/>
        </div>
    </div>
</template>

<script>
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
    import TabNavigation from "../../components/TabNavigation";
    import {markRaw} from "vue";
    import PromotionTabMain from "./tabs/PromotionTabMain";
    import PromotionTabTime from "./tabs/PromotionTabTime";
    import PromotionTabApply from "./tabs/PromotionTabApply";
    import PromotionTabVoucher from "./tabs/PromotionTabVoucher";
    import * as toastr from "toastr";

    export default {
        name: "PromotionForm",
        components: {TabNavigation, ActionBar},
        data() {
            return {
                isDataLoaded: false,
                entry: {},
                cached: markRaw({}),
                errors: {},
                currentTab: {},
                tabs: [
                    {id: 'TabMain', showActionBar: true, name: 'Thông tin khuyến mại', component: markRaw(PromotionTabMain)},
                    {id: 'TabTime', showActionBar: true, name: 'Thời gian áp dụng', component: markRaw(PromotionTabTime)},
                    {id: 'TabApply', showActionBar: true, name: 'Phạm vi áp dụng', component: markRaw(PromotionTabApply)},
                    {id: 'TabVoucher', showActionBar: true, name: 'Danh sách voucher', component: markRaw(PromotionTabVoucher)},
                ]
            }
        },
        mounted() {
            document.title = 'Khuyến mại | OmiPos';
        },
        methods: {
            tabChange(tab) {
                this.currentTab = tab;
                this.load();
            },
            setTabData(values) {
                this.$refs.tab.setTabData(values);
            },
            getTabData(key) {
                return this.$refs.tab.getTabData(key)
            },
            async load() {
                if (this.cached.data) {

                    this.$nextTick(() => {
                        this.setTabData(this.cached.data);
                    })

                    return;
                }

                const settingRes = await $get('/v1/promotions/settings');

                if (settingRes.code !== 200) {
                    toastr.error(settingRes.message);
                    return;
                }

                const settings = settingRes.data;
                let entry = {
                    formValues: []
                };

                if (this.$route.query.id) {
                    const res = await $get('/v1/promotions/show', {
                        id: this.$route.query.id
                    });

                    if (res.code === 404) {
                        this.$store.commit('SetIsPageNotFound', true);
                        return;
                    }

                    entry = res.data.entry;
                }




                const currentIf = settings.ifs.find(e => e.id === entry.currentIfId) || null;
                if (!entry.formValues) {
                    entry.formValues = [];
                }

                let currentThen = null;
                if (currentIf && currentIf.thens) {
                    currentThen = currentIf.thens.find(e => e.id === entry.currentThenId);
                }

                const condition = {
                    ifs: settings.ifs,
                    currentIf,
                    currentThen
                }

                if (!entry.currentIfId) {
                    entry.currentIfId = '0';
                }

                if (!entry.currentThenId) {
                    entry.currentThenId = '0';
                }

                entry.formValues.forEach(fv => {
                    if (Array.isArray(fv.cThen)) {
                        fv.cThen = {};
                    }

                    if (Array.isArray(fv.cIf)) {
                        fv.cIf = {};
                    }
                });

                this.entry = entry;
                this.cached.data = {
                    entry: entry,
                    isDataLoaded: true,
                    condition
                };
                this.setTabData(this.cached.data);
            },
            async save() {
                this.setTabData({
                    errors: {}
                });

                const entry = this.getTabData('entry');
                const res = await $post('/v1/promotions/save', {
                    entry
                });

                if (res.code === -1) {
                    this.setTabData({
                        errors: res.errors
                    });

                    scrollToElement('.error-label');
                    return;
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            }
        }
    }
</script>

<style scoped>

</style>
