<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-1">
                    <h6 class="modal-title py-2">
                        {{isEdit ? this.$t('message.permissions.modal.titleEditRole') : this.$t('message.common.addRole')}}
                    </h6>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-6">
                            <div class="row mt-2 py-2">
                                <div class="col-3">
                                    {{ $t('message.common.roleName') }}: <span class="text-danger"> * </span>
                                </div>
                                <div class="col-9 position-relative">                                                            
                                    <input type="text" v-model.trim="entry.name" class="w-100 border-0 border-bottom-1 pr-4">
                                    <i class="fa fa-copy" id="fa_copy" v-if="this.isEdit" @click="copyRole"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">  
                            <div class="row mt-2 py-2">
                                <div class="col-3">
                                    {{ $t('message.ProductIndex.note') }}:
                                </div>
                                <div class="col-9 position-relative">   
                                    <i class="fa fa-pencil-alt" id="fa_edit"></i>                                                         
                                    <input type="text" v-model.trim="entry.note" class="pl-4 w-100 border-0 border-bottom-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-12">
                            <!-- <TabPermission :defaultValues="entry.permissionIds" @update:modelValue="updatePermission" :showAction="isEdit ? true : false"></TabPermission> -->
                            <TabPermission :defaultValues="entry.permissionIds" @update:modelValue="updatePermission" :showAction="false"></TabPermission>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-check-square mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                            <!-- <button type="button" class="btn btn-danger mr-2" v-if="isEdit" @click="removeRole(entry.id)">
                                <i class="fa fa-trash-alt mr-1"></i>
                                Xóa
                            </button> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
    <ModalRemoveRole ref="ModalRemoveRole" @inputData="hide()"/>
    <ModalCopyRole ref="ModalCopyRole"/>
</template>

<script>
import InputText from "@/components/forms/InputText";
import {$post, $alert, isChangeData, clone} from "@/lib/utils";
import emitter from "@/lib/emitter";
import ModalConfirm from "@/components/ModalConfirm.vue";
import TabPermission from "@/components/TabPermission.vue";
import ModalRemoveRole from "./ModalRemoveRole.vue";
import ModalCopyRole from "./ModalCopyRole.vue";
export default {
    name: "ModalCreateRole",
    components: {InputText, ModalConfirm, TabPermission, ModalRemoveRole, ModalCopyRole},
    props: {},
    data() {
        return {
            entry: {},
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                note: '',
                permissionIds: null,
            };
            this.isEdit = false;
            emitter.emit('removeDataQTreeListCustom');
            emitter.emit('removeDataTabPermission');
        },
        async show(entry = null) {   
            this.clearInput();
            if(entry){
                this.isEdit = true;
                this.entry = clone(entry);
            }
            this.default = clone(this.entry);
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {   
            this.$emit('inputData');
            this.clearInput();  
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.common.confirm');
                if(this.isEdit){
                    this.contentConfirm = this.$t('message.common.confirmCancelUpdateRole');
                }else{
                    this.contentConfirm = this.$t('message.common.confirmCancelAddRole');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        updatePermission(data){
            this.entry.permissionIds = data.values;
        },
        async save(){
            if(typeof this.entry.permissionIds == 'string'){
                this.entry.permissionIds = this.entry.permissionIds.split(",");
            }
            if(this.entry.permissionIds){
                this.entry.permissionIds = this.entry.permissionIds.map(Number);
            }
            let res = null;
            if (!this.isEdit) {
                res = await $post('/v1/roles/save', { ...this.entry});
            } else {
                res = await $post('/v1/roles/update', { ...this.entry});
            }
             
            if (res.code === 422) {
                const errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
               
            }
            else if (res.status.code == 200 && res.result.status && res.result.message) {
                $alert({code: 200, message: res.result.message});
                this.$emit('updateRole');
                this.hide();
            } else {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
            }
        },
        removeRole(id){
            this.$refs.ModalRemoveRole.show(id);
        },
        copyRole(){
            let data = {
                name: null,
                permissionIds: this.entry.permissionIds,
                note: this.entry.note,
            }
            this.$refs.ModalCopyRole.show(data);
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}

#fa_edit {
    position: absolute;
    left: 20px;
    bottom: 5px;
    color:#a7a2a2;
    cursor: text;
}
#fa_copy{
    position: absolute;
    right: 20px;
    bottom: 5px;
    color:#a7a2a2;
    cursor: pointer;
}
</style>