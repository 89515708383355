<template>
    <div class="modal" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Thêm lô hạn sử dụng</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <InputText :errors="errors.name" v-model="model.name" label="Tên lô"/>
                    <InputDatePicker min="now" :errors="errors.expireDate"

                                     v-model="model.expireDate" label="Hạn sử dụng"/>
                    <InputNumber :min="1" :errors="errors.quantity" v-model="model.quantity" label="Số lượng"/>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="save()" class="btn btn-primary">Lưu lại</button>
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Đóng lại</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "../../components/forms/InputText";
import InputDatePicker from "../../components/forms/InputDatePicker";
import InputNumber from "../../components/forms/InputNumber";
import {$post, $alert} from "../../lib/utils";

export default {
    name: "ModalProductBatchForm",
    props: ['modelValue'],
    components: {InputNumber, InputDatePicker, InputText},
    data() {
        return {
            model: this.modelValue || {},
            errors: {}
        }
    },
    watch: {
        modelValue: function (newValue) {
            this.model = newValue || {}
        }
    },
    methods: {
        async save() {
            const res = await $post('/v1/product-batches/save', {
                entry: this.model
            })

            $alert(res);

            if (res.code === -1) {
                this.errors = res.errors;
                return;
            }

            this.$emit('update:modelValue', this.model);

            if (this.onSave) {
                this.onSave(res.data);
            }

            window.jQuery(this.$refs.modal).modal('hide');
        },
        show(onSave) {
            this.errors = {};
            this.onSave = onSave;
            window.jQuery(this.$refs.modal).modal();
        }
    }
}
</script>

<style scoped>

</style>
