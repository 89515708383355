<template>
    <div  ref="wrapper">
        <div class="row mb-3">
            <div class="col-md-2 text-left">
                <span>{{ $t('message.CouponIndex.modal.create_or_update.to') }}</span>
            </div>
            <div class="qselect-input-box pl-0 col-md-10 text-left">
                <span v-for="(option, i) in optionsMailTo" :key="i">
                    <span v-if="option" class="qslelect-tag" :class="this.filter === true ? 'text-dark bg-gray' : ''">
                        {{ option }}
                        <span  class="close-btn" @click="removeItem(optionsMailTo, i)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
                                <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
                        </span>
                    </span>
                </span>  
                <input v-model.trim="textOptionTo" class="qselect-input pl-0 min-w-100" @input="onSearchMailsTo" v-on:keyup.enter="selectOptionMailTo()">
                <div class="ml-2 block-select-option" >
                    <div v-for="(option, i) in optionsSelectTo" :key="i" @click="selectOptionMailTo(option.email, i)" class="text-left">
                        {{ option.email }}
                    </div>
                </div>
            </div>       
        </div>
        <div class="row">
            <div class="col-md-2 text-left">
                <span>{{ $t('message.common.cc') }}</span>
            </div>
            <div class="qselect-input-box pl-0 col-md-10 text-left">
                <span v-for="(option, i) in optionsMailCc" :key="i">
                    <span v-if="option" class="qslelect-tag" :class="this.filter === true ? 'text-dark bg-gray' : ''">
                        {{ option }}
                        <span  class="close-btn" @click="removeItem(optionsMailCc, i)">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
                                <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
                        </span>
                    </span>
                </span>  
                <input v-model.trim="textOptionCc" class="qselect-input pl-0 min-w-100" @input="onSearchMailsCc" v-on:keyup.enter="selectOptionMailCc()">
                <div class="ml-2 block-select-option" >
                    <div v-for="(option, i) in optionsSelectCc" :key="i" @click="selectOptionMailCc(option.email, i)" class="text-left">
                        {{ option.email }}
                    </div>
                </div>
            </div>       
        </div>
    </div>
</template>
<script>
    let app;
    import { $get, debounce, $alert} from "../lib/utils";
    export default {
        props: ['optionsPropMailCc', 'optionsPropMailTo'],
        methods: {
            async getListMailCC(textOption, type) {
                if (!textOption) return; 
                const response = await $get('/v1/send-mail/get-mails', {emailSearch: textOption});  
                if (response && response.result) {
                    if (type == 'cc') {
                        this.optionsSelectCc = response.result;
                    } else {
                        this.optionsSelectTo = response.result;
                    }
                }
            },
            selectOptionMailCc(mail = null, index = null) {
                if (mail) {
                    if (this.optionsMailCc.includes(mail, 0)) return;
                    this.optionsMailCc.push(mail);
                    this.textOptionCc = '';
                    this.optionsSelectCc = [];
                    // this.optionsMailCcSelect.splice(index, 1)
                } else {
                    const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if ( String(this.textOptionCc).toLowerCase().match(mailRegex)) {
                        if (this.optionsMailCc.includes(this.textOptionCc, 0)) {
                            $alert({code: 500, message: this.textOptionCc + ' đã tồn tại'});
                            return
                        }
                        this.optionsMailCc.push(this.textOptionCc);
                        this.textOptionCc = '';
                    } else {
                        $alert({code: 500, message: this.textOptionCc + ' Không đúng định dạng email'});
                    }
                }
                this.updateData();
            },
            selectOptionMailTo(mail = null, index = null) {
                if (mail) {
                    if (this.optionsMailTo.includes(mail, 0)) return;
                    this.optionsMailTo.push(mail);
                    this.textOptionTo = '';
                    this.optionsSelectTo = [];
                    // this.optionsMailCcSelect.splice(index, 1)
                } else {
                    const mailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                    if ( String(this.textOptionTo).toLowerCase().match(mailRegex)) {
                        if (this.optionsMailTo.includes(this.textOptionTo, 0)) {
                            $alert({code: 500, message: this.textOptionTo + ' ' + this.$t('message.common.exist')});
                            return
                        }
                        this.optionsMailTo.push(this.textOptionTo);
                        this.textOptionTo = '';
                    } else {
                        $alert({code: 500, message: this.textOptionTo + ' ' + this.$t('message.common.inValidEmail')});
                    }
                }
                this.updateData();
            },
            onSearchMailsTo:  debounce(async () => {
                app.getListMailCC(app.textOptionTo, 'to');
            }),
            onSearchMailsCc:  debounce(async () => {
                app.getListMailCC(app.textOptionCc, 'cc');
            }),
            removeItem(options, index) {
                options.splice(index, 1);
                this.updateData();
            },
            updateData() {
                this.$emit('updateListMailCc', this.optionsMailCc);
                this.$emit('updateListMailTo', this.optionsMailTo);
            }
        },
        data() {
            app = this;
            return {
                textOption: null,
                optionsMailCc: this.optionsPropMailCc ?? [],
                optionsMailTo: this.optionsPropMailTo ?? [],
                optionsSelectTo: [],
                optionsSelectCc: [],
            }
        },
        created(){
        },
        watch: {
            optionsProp: {
                handler(newData) {  
                    this.options = newData;
                },
                deep: true,
            },
            optionsPropMailCc: {
                handler(newData) {  
                    this.optionsMailCc = newData;
                },
                deep: true,
            },
            optionsPropMailTo: {
                handler(newData) {  
                    this.optionsMailTo = newData;
                },
                deep: true,
            },
        },
        name: "QSelectTag"
    }
</script>

<style lang="scss">
    .block-select-option {
        width: 100%;
        max-height: 300px;
        overflow: auto;
    }
    .lnkRole{
        text-decoration: none;
        // color: #000;
    }
    .lnkRole:hover{
        text-decoration: none;
        // color: #000;
    }
    .titleLabel:hover {
        text-decoration: none;
        color: #000;
    }
    .titleLabel {
        color:#000;
        max-width: 70%;
    }
    .upload_file {
        border-radius: 0 !important;
        border: 0 !important;
        border-bottom: 1px solid #ccc !important;
    }
    .qselect {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .qselect-add {
        width: 40px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: 0;
        &:hover,&:active,&:focus {
            outline: 0;
        }
    }

    .qselect-input-box.active {
        border: 1px solid gray;
    }
    
    .dropdown-btn {
        position: absolute;
        right: 15px;
        top: 10px;
    }

    .dropdown-btn svg{
        width: 9px;
        height: 9px;
        fill: #ccc
    }
    .close-btn svg:hover {
        fill: red;
    }

    .close-btn svg {
        width: 6px;
        height: 6px;
        cursor: pointer;
        fill: white;

    }
    .notfound {
        padding-left:10px;
        padding-right:10px;
        padding-top:10px;
        font-size: 12px;
    }
    .close-btn {
        color: #039be5;
        padding-left: 5px;
        border-left: 1px solid #fff;
        line-height: 0;
    }
    .qslelect-tag {
        display: inline-block;
        padding: 4px;
        color: white;
        margin: 1px;
        border-radius: .2rem;
        background-color: #008080;
        font-size: 12px;
    }
    .qselect-input-box {
        position: relative;
        display: block;
        width: 100%;
        min-height: 25px;
        // padding-top:3px;
        padding-left: 5px;
        padding-right: 5px;
        // padding-bottom: 3px;
        font-size: .9rem;
        font-weight: 400;
        color: #6c757d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .42rem;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    }

    #qSelectInput{
       border:0;

    }
    #qSelectBox{
        width: 100% !important;
        border: 0px !important;
        border-radius: 0 !important;    
        border-bottom: 1px solid #ced4da !important;
    }
    .qselect-input {
        margin: 3px;
        padding: 0;
        border: 0;
        outline: none;
        box-sizing: content-box;
        box-shadow: none;
        background: none rgba(0,0,0,0);
        line-height: 1;
        vertical-align: middle;
        height: 25px;
        padding-left: 10px;
        padding-bottom:5px;
    }    
    .qselect-dropdown {
        position: absolute;
        background-color: #fff;
        z-index:1040;
        border: 1px solid #ccc;
        border-top: 0;
    }
    .qselect-dropdown ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        max-height: 250px;
        overflow-y: auto;
    }
    .qselect-item.last {
        border-bottom: 0;
    }
    .qselect-item.active {
        background-color: #e2e5e5a8;
        color: white;
        cursor: pointer;
    }
    // .qSelectAll{
    //     border: 1px solid #ccc;
    //     height: 32px;
        
    // }
    // .qSelectAll:hover{
        
    //     background-color: #e2e5e5a8;
    //     color: white;
    //     cursor: pointer;
    // }
    .qselect-item--flex {
        display: flex;
    }
    .qselect-item {
        position: relative;
        border-bottom: 1px solid #ccc;
        /* padding-top: 3px; */
        /* margin-top: 3px; */
        padding-left: 10px;
        padding-top: 5px;
        min-height: 30px;
        align-items: center;
    }

    .qselect-item-flex {
        border-bottom: 1px solid #ccc;
        /* padding-top: 3px; */
        /* margin-top: 3px; */
        padding-left: 10px;
        padding-top: 5px;
        min-height: 30px;
        display: flex;
        align-items: center;
    }

    .qselect-item span{
        height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .qselect-checkbox {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
        cursor: pointer;
        margin-right: 5px;
    }

    .q-select-loading {
        margin-top: 4px;
        margin-left: 5px;

    }
    .pencil-update{
        position: absolute;
        right: 1rem;
        padding-left: 5px;
        display: none;
    }
    .pencil-update-hover{
        position: absolute;
        right: 1rem;
        padding-left: 5px;
        display: none;
    }

    li:hover{
        .pencil-update-hover, .pencil-update{
            display: block;
        }         
    } 
        

    .bg-gray{
        background: #D9D9D9;
    }
    .overFlow{
        max-height: 200px !important ;
        overflow-y: auto;
        overflow-x: hidden ;
    }
    .add-q-btn {
        display: none;
        position: absolute;
        top: 10px;
        right: 6px;
    }
    .qselect-input-box:hover  .add-q-btn {
        display: block;
    }
</style>
