<template>
    <div class="fm-root">
        <div class="row" >
            <div class="form-inline">
                <div class="form-group">
                    <input @keydown="search()" class="form-control" :placeholder="$t('message.InputQuickSearch.button.search')" v-model="filter.keyword">
                </div>
                <InputDateRangePicker @update:modelValue="search()" v-model="filter.created" :placeholder="$t('message.common.uploadDate')"/>
                <button
                    @click.prevent="selectFile()"
                    type="button"
                    style="margin-left: 15px;" class="btn btn-save btn-sm"><i class="fa fa-upload"></i> {{$t('message.common.upload')}}</button>
                <button
                    v-if="multiple"
                    @click.prevent="fileClickedAll()"
                    type="button"
                    :disabled="selectDisabled" style="margin-left: 15px;" class="btn btn-save btn-sm">
                    <i class="fa fa-check"></i> 
                    {{$t('message.button.choose')}}
                </button>
            </div>
        </div>
        <div class="row fm-table-wrapper">
            <table class="fm-table">
                <thead>
                <tr>
                    <th  @change="checkAll($event)" class="text-center">
                        <input v-if="multiple"  type="checkbox"/>
                    </th>
                    <th class="text-center">#</th>
                    <th>
                        <TableSortField @onsort="sort" :name="$t('message.common.fileName')" field="name"/>
                    </th>
                    <th>
                        <TableSortField @onsort="sort" :name="$t('message.common.fileExtension')" field="extension"/>
                    </th>
                    <th>
                        <TableSortField @onsort="sort" :name="$t('message.DeliveryIndex.size')" field="size"/>
                    </th>
                    <th>
                        <TableSortField @onsort="sort" :name="$t('message.common.createdAt')" field="created_at"/>
                    </th>

                    <th>
                        <a @click.prevent="selectFile()" class="fm-btn-upload">
                            <i class="fa fa-plus"/>
                        </a>
                        <input @change="fileChange()" :accept="this.type ==='excel' ? '.xlsx, .xls, .csv' : 'image/*'"
                               type="file"
                               ref="uploader" style="display: none;width: 1px;height: 1px"/>
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(file, index) in files" :key="file.id" class="fm-row" >
                    <td  class="text-center">
                        <input v-if="multiple" v-model="file.checked" type="checkbox"/>
                        <button  class="btn btn-default btn-sm" @click.prevent="fileClicked(file)" v-else>
                           <i class="fa fa-check"></i>
                        </button>
                    </td>
                    <td class="fm-preview">

                        <QImage v-if="file.isImage" class="fm-img-preview" :src="file.url"/>
                        <i :class="getIconClass(file.extension)" v-else ></i>
                    </td>
                    <td class="fm-filename">
                        <span  v-if="!file.editMode" v-text="file.name"></span>
                        <template v-else>
                            <input
                                :id="'fm-input' + file.id"
                                @keydown.esc="file.editMode=false"
                                @keydown.enter="save(file, model.name)" class="form-control" v-model="model.name">
                            <div class="mt-2">
                                <button class="btn btn-primary btn-sm" @click.prevent="save(file)">
                                    {{$t('message.button.save')}}
                                </button>
                                <button  class="btn btn-secondary btn-sm" @click.prevent="file.editMode=false">
                                    {{$t('message.button.cancel')}}
                                </button>
                            </div>
                        </template>
                    </td>
                    <td v-text="file.extension"></td>
                    <td v-text="humanFileSize(file.size)"></td>
                    <td v-text="d(file.createdAt)"></td>

                    <td>
                        <a @click.prevent="rename(file, index )" class="fm-btn-rename">
                            <i class="fa fa-edit"/>
                        </a>

                        <a @click.prevent="remove(file, index )" class="fm-btn-remove">
                            <i class="fa fa-trash"/>
                        </a>
                        <a :href="file.url" target="_blank">
                            <i class="fa fa-download"/>
                        </a>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="row" >
            <Paginate @change="onPageChange" :value="paginate" />
        </div>
    </div>
</template>

<script>
import {$get, $post, $upload, debounce, humanFileSize} from "../lib/utils";
import TableSortField from "./TableSortField";
import Paginate from "./Paginate";
import toastr from 'toastr';
import QImage from "./QImage";
import InputDateRangePicker from "./forms/InputDateRangePicker";

const fileExtensionIcons = {
    'xlsx': 'fa fa-file-excel',
    'xls': 'fa fa-file-excel',
    'docx': 'fa fa-file-word',
    'doc': 'fa fa-file-word',
    'pdf': 'fa fa-file-pdf'
};
let app;

export default {
    name: "FileManager",
    props: ['multiple', 'type', 'group'],
    components: {InputDateRangePicker, QImage, Paginate, TableSortField},
    data() {
        return {
            sortData: {
                direction: 'desc',
                field: 'id'
            },
            filter: {
                keyword: '',
                created: ''
            },
            model: {
                name: ''
            },
            files: [],
            paginate : {lastPage: 1, currentPage: 1},
        }
    },
    computed: {
        selectDisabled: function () {
            const len = this.files.length;
            for (let i = 0; i < len; i++) {
                if (this.files[i].checked) {
                    return false;
                }
            }

            return true;
        }
    },
    methods: {
        humanFileSize,
        checkAll(ev) {
            this.files.forEach(file => {
                file.checked = ev.target.checked;
            })
        },
        search: debounce(() => {
            app.doFilter();
        }),
        getIconClass(ext) {
            return fileExtensionIcons[ext] || '';
        },
        async save(file) {
            const res = await $post('/v1/files/rename', {
                id: file.id,
                new_name: this.model.name
            });

            if (res.code !== 200) {
                toastr.error(res.message);
            } else {
                toastr.success(res.message);
                file.name = this.model.name;
                file.editMode = false;
            }

        },
        async rename(file) {
            this.model.name = file.name;
            file.editMode = true;
            this.$nextTick(() => {
                document.getElementById('fm-input' + file.id).focus();
            })
        },
        fileChange() {
            const files = this.$refs.uploader.files;
            this.upload(files);
        },
        async upload(files) {
            if (files[0]) {
                //console.log(files[0])
                const res = await $upload('/v1/files/upload', files, {group: this.group});
                this.$refs.uploader.value = '';
                if (res.code !== 200) {
                    alert(res.message);
                } else {
                    res.file.editMode = false;
                    res.file.checked = false;
                    this.files.unshift(res.file)

                }
            }
        },
        async remove(file, index) {
            if (!confirm('Xóa file: ' + file.id)) {
                return;
            }

            const res = await $post('/v1/files/remove', {
                id: file.id
            });

            if (res.code !== 200) {
                toastr.error(res.message);
            } else {
                toastr.success(res.message);
                this.files.splice(index, 1);
            }
        },
        sort(field, direction) {
            this.sortData = {
                field, direction
            }
            this.load(1);
        },
        selectFile() {
            this.$refs.uploader.click();
        },
        onPageChange(page) {
            this.load(page);
        },
        doFilter() {
            this.load(1);
        },
        async load(page = 1) {
            const res = await $get('/v1/files/data', {
                page,

                keyword: this.filter.keyword,
                created: this.filter.created,
                sort_direction: this.sortData.direction,
                sort_field: this.sortData.field,
                file_type: this.type || 'image',
                group: this.group,
            }, false);

            res.data.entries.forEach(file => {
                file.editMode = false;
                file.checked = false;
            });
            this.files = res.data.entries;
            this.paginate = res.data.paginate;
        },
        fileClicked(file) {
            if (this.multiple) {
                file.checked = !file.checked;
            } else {
                this.$emit('fileclick', [file]);
            }
        },
        fileClickedAll() {
            const values=  this.files.filter(file => file.checked);
            this.$emit('fileclick', values);
        }
    },
    mounted() {
        app = this;
        this.load(1);
    }
}
</script>

<style scoped>
.fm-root .form-group {
    margin-left: 10px;
}
    .fm-table {
        width: 100%;
        border-collapse: collapse;
        position: relative;
    }
    .fm-preview {
        text-align: center;
    }
    .fm-preview i {
        font-size: 32px;
    }
    .fm-filename {
        word-break: break-all;
    }
    .fm-id {
        width: 10%;
    }
    .fm-btn-upload {
        color: #007bff !important;
        cursor: pointer;
    }
    .fm-btn-rename {
        cursor: pointer;
        color: #0b8949 !important;
    }
    .fm-btn-remove {
        color: #db0b0b !important;
        cursor: pointer;
    }
    .fm-img-preview {
        width: 64px;
        height: 64px;
    }

    .fm-table th,.fm-table td {
        padding: 5px;
        border-top: 1px solid  #ccc;
        border-bottom: 1px solid  #ccc;
    }

    .fm-row:hover {
        background-color: #96e0b6;
        cursor: pointer;
    }

    .fm-table-wrapper {
        margin-top:15px;
        margin-bottom: 15px;
        max-height: 600px;
        overflow-y: auto;
    }
</style>
