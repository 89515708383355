<template>
    <div class="form-group">
        <label v-if="label" v-text="label"></label>
        <input v-model="model" @input="onInput(true)" 
               :readonly="!!readonly"
               :class="errors && errors.length ? 'error-input': '' "
               :id="ordStyle==true ? 'inputDatetime' : '' "
                class="form-control inputDatetime" :placeholder="placeholder || label"
               :maxlength="this.max? this.max : ''"
               :type="'text'"
               v-on:keypress="DateInputOnly"
               :style="this.styleCSS? this.styleCss : ''"
               >      
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "InputDate",
        props: ['label', 'placeholder', 'modelValue', 'errors', 'datalist', 'readonly','max','cusNumber', 'note','dateData','ordStyle', 'styleCSS'],
        data() {
            return {
                model: this.modelValue,            
            }
        },
        watch: {
            modelValue: function (newValue) {
                this.model = newValue;
            }
        },
        methods: {
            NumbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            DateInputOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode!==47) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            onInput() {
                this.$emit('update:modelValue', this.model);
                this.$nextTick(() => {
                    this.$emit('input', this.model);
                })

            },
            isNumberic(e){
                var charCode = (e.which) ? e.which : e.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
                return true;
            }

        }
    }
</script>

<style scoped>
    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-weight: bolder;
        color: red;
    }

    .error-input, .error-input:hover,.error-input:focus,.error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
    
</style>
