<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="form-wrapper" style="   padding: 31px;width: 500px; border: 0; text-align: center">

                <!-- logo -->
                <div class="logo">
                    <img :src="this.$constant.logo_image">
                </div>
                <!-- ./ logo -->

                <h5 style="margin-top:20px;">{{ $t('message.LoginScreen.header') }}</h5>

                <!-- form -->
                <form method="post"  style="margin-top:50px;" @submit.prevent="login()" v-if="loaded">
                    <div class="form-group">
                        <input type="text" v-model="model.email" class="form-control" :placeholder="$t('message.LoginScreen.username')" name="username"  required="" autofocus="">
                    </div>
                    <div class="form-group position-relative" id="divForm" >
                        <input :type="this.type" v-model="model.password" class="form-control" :placeholder="$t('message.LoginScreen.password')" required="" name="password">
                        <i class="fa fa-eye iconEye" aria-hidden="true" @click="isShowIcon" :style="!this.isShow ? 'display:none' : '' "></i>
                        <i class="fa fa-eye-slash iconEye" aria-hidden="true"  @click="isShowIcon" :style="this.isShow ? 'display:none' : '' "></i>
                    </div>
                    <div class="alert alert-danger" v-if="errorMessage" v-text="errorMessage"></div>
                    <div class="form-group d-flex justify-content-between">
                        <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" checked="" id="customCheck1">
                            <label class="custom-control-label" for="customCheck1">{{ $t('message.LoginScreen.remember') }}</label>
                        </div>
                    </div>
                    <button type="submit"  class="btn btn-primary btn-block">{{ $t('message.LoginScreen.submit') }}</button>
                </form>
                <!-- ./ form -->

            </div>
        </div>
    </div>
</template>

<script>
    import {$post} from "@/lib/utils";
    import store from "../../store";
    import { initializeApp } from "firebase/app";
    import { getMessaging, getToken, onMessage } from "firebase/messaging";
    import { app } from "@/firebase";

    export default {
        name: "LoginScreen",
        data() {
            return {
                type:"password",
                errorMessage: '',
                loaded: false,
                model: {
                    email: '',
                    password: '',
                    tokenFirebase: null,
                },
                isShow: true,
            }
        },
        methods: {
            isShowIcon(){
                this.isShow = !this.isShow;
                if(this.isShow == false){
                    this.type = 'text';
                }
                else {
                    this.type = 'password';
                }
            },
            async login() {
                await this.getTokenFirebase();
                const res = await $post('/v1/auth/login',this.model);
                if (res.code !== 200) {
                    this.errorMessage = res.message;
                    return;
                }
                store.commit('SetAuth', res.data);
                location.replace('/');
            },
            async getTokenFirebase() {
                const messaging = await getMessaging();
                await getToken(messaging, { vapidKey: process.env.VUE_APP_FIREBASE_KEY }).then((currentToken) => {
                    console.log('currentToken', currentToken);
                    if (currentToken) {
                        console.log("Token is:", currentToken);
                        this.model.tokenFirebase = currentToken;
                    } else {
                        console.log('No registration token available. Request permission to generate one.');
                    }
                    }).catch((err) => {
                        console.log('err', err);
                });
            }
        },
        mounted() {
            document.title = this.$t('message.LoginScreen.title');

            if (store.state.Auth) {
                location.replace('/');
                return;
            }

            this.loaded = true;
        }
    }
</script>

<style scoped>
    .iconEye{
        position: absolute;
        top: 10px;
        right: 10px;
    }
</style>
