<template>
    <div ref="modalConfirm" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{this.title ? this.title : this.$t('message.common.confirm')}}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3">
                            <h6 style="line-height:30px;">{{ this.content ? this.content : this.$t('message.common.confirmCancelTicket')}}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">                           
                            <button :disabled="isButtonDisabled" class="btn btn-danger mr-2" @click="confirm()" >
                                <i class="fa fa-check mr-1"></i>
                                {{$t('message.button.confirm')}}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.cancel')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';
import router from "@/router/index";

export default {
    name: "ModalRefundConfirm",
    components: {},
    data(){
        return {
            id:null,
            code:null,
            title:null,
            content: null,
            type: null,
            isButtonDisabled: false
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(entry, type) {                 
            this.id = entry.id;
            this.code = entry.code; 
            this.type = type;
            switch (this.type) {
                case this.$constant.type_approve: // Duyệt đơn đề nghi
                    this.title = this.$t('message.common.approveRequestRefund');
                    this.content = this.$t('message.common.confirmApproveRequestRefund', {code: this.code});
                    break;
                case this.$constant.type_refuse: // Duyệt đơn đề nghi
                    this.title = this.$t('message.common.refuseRequestRefund');
                    this.content = this.$t('message.common.confirmRefuseRequestRefund', {code: this.code});
                    break;
            
                default:
                    break;
            }
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        async confirm(){
             switch (this.type) {
                case this.$constant.type_approve: // Duyệt đơn đề nghi
                    this.approveRefund();
                    break;
                case this.$constant.type_refuse: // Duyệt đơn đề nghi
                    this.refuseRefunds();
                    break;
            
                default:
                    break;
            }
        },
        async approveRefund(){
            this.isButtonDisabled = true;                                                
            const res = await $post('/v1/refunds/approve', { id: this.id});
            if(res && res.status.code == 200 && res.result){
                await toastr.success(this.$t('message.common.approveSuccess'));
                this.clearData();
                router.push({ name: 'RequestRefundIndex'});
                // window.location.href="/request-refund/index"
            }
            else {
                toastr.error(this.$t('message.common.approveFailed'));
                this.isButtonDisabled = false;
            }
        },
        async refuseRefunds(){
            this.isButtonDisabled = true;
            const res = await $post('/v1/refunds/refuse', { id: this.id});
            if(res && res.status.code == 200 && res.result){
                await toastr.success(this.$t('message.common.refuseSuccess'));
                this.clearData();
                router.push({ name: 'RequestRefundIndex'});
                // window.location.href="/request-refund/index"
            }
            else {
                toastr.error(this.$t('message.common.refuseFailed'));
                this.isButtonDisabled = false;
            }
        },
        clearData(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

