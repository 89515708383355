
<template>
    <div class="transfer-detail" >
        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button" style="display: flex">
                    <router-link to="/transfers/index" class="btn btn-default">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        Quay lại
                    </router-link>
                    <router-link :to="'/transfers/form?id=' + entry.id" class="btn btn-save" v-if="isOpen">
                        <i class="fa fa-edit" aria-hidden="true"></i>
                        Mở phiếu
                    </router-link>
                    <button class="btn btn-save" type="button" @click="save()" v-if="entry.status !== 4 && entry.status !== 5">
                        <i class="fa fa-save"/> Lưu lại</button>
                    <button class="btn btn-save" type="button" @click="showModalPrintBarCode">
                        <i class="fa fa-print"/>
                        In tem mã
                    </button>
                    <button class="btn btn-save" type="button" @click="onPrint()">
                        <i class="fa fa-print"/>
                        In phiếu
                    </button>
                    <export-excel
                        :fetch  = "exportExcel"
                        :fields = "excelData.fields"
                        :name   = "excelData.fileName">
                        <button type="button" class="btn btn-save" style="padding-bottom: 0.5rem">
                            <i class='fas fa-file-export'></i>
                            Xuất Excel
                        </button>
                    </export-excel>
                    <button v-if="!isCancel" class="btn btn-danger" type="button" @click="cancel()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Hủy bỏ
                    </button>
                </div>
            </div>
        </div>
        <div class="main-title">
            <h1 v-text="entry.type === 'request' ? 'Chi tiết phiếu xin hàng: ' + entry.code :  'Chi tiết phiếu chuyển: ' + entry.code"></h1>
        </div>
        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <InputText v-model="entry.code"
                           label="Mã chuyển hàng/ xin hàng" :readonly="!!entry.id"/>
                <div class="form-group">
                    <label>Chi nhánh chuyển/ tạo yêu cầu</label>
                    <QSelect v-model="entry.fromBranchId" datasource="branches" :readonly="true"/>
                </div>
                <div class="form-group">
                    <label>Chi nhánh nhận</label>
                    <QSelect v-model="entry.toBranchId" datasource="branches" :readonly="!isEditField"/>
                </div>
                <div class="form-group ">
                    <label>{{$t('message.common.created_by')}}</label>
                    <QSelect v-model="entry.createdBy" datasource="users" :readonly="true"/>
                </div>

            </div>
            <div class="col-lg-4 col-sm-6">
                <InputDatePicker v-model="entry.createdAt" label="Ngày tạo" :readonly="true"/>

                <div class="row">
                    <div class="col-lg-3 col-sm-4">
                        <div class="form-group">
                            <label>{{$t('message.common.status')}}: </label>
                        </div>
                    </div>

                    <div class="col-8">
                        <div class="form-group">
                            <span class="status" style="font-weight: bold" v-text="entry.type === 'request' ? statusRequest[(entry.status ? entry.status - 1 : 0)].name : (status[(entry.status ? entry.status - 1 : 0)].name || '')"></span>
                        </div>
                    </div>


                </div>

                <InputDatePicker v-if="entry.receivedDate" v-model="entry.receivedDate" label="Ngày nhận" :readonly="true"/>

                <div class="form-group " v-if="entry.status === 3">
                    <label>Người nhận</label>
                    <QSelect v-model="entry.receivedBy" datasource="users"/>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6">
                <InputTextArea v-model="entry.sendDescription" label="Ghi chú chi nhánh gửi"
                :readonly="isReceiveBranch || isCancel"></InputTextArea>
                <InputTextArea v-model="entry.receiveDescription" label="Ghi chú chi nhánh nhận"
                :readonly="isSendBranch || isCancel"></InputTextArea>
            </div>
        </div>
        <div class="row mt-3">
            <table class="table table-index table-striped">
                <thead>
                <tr>
                    <th scope="col">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                    <th scope="col" style="width: 40%">{{$t('message.DetailOrdersCard.prodName')}}</th>
                    <th scope="col" style="min-width: 100px">{{$t('message.DetailOrdersCard.dvt')}}</th>
                    <th scope="col" style="min-width: 100px; text-align: center" v-text="entry.type === 'request' ? 'Số lượng xin' : 'Số lượng chuyển'"></th>
                    <th scope="col" style="min-width: 100px; text-align: center" v-text="entry.type === 'request' ? 'Số lượng chuyển' : 'Số lượng nhận'"></th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(product, index) in details" :key="index">
                    <tr style="font-weight: bold">
                        <td v-text="product.productCode"></td>
                        <td class="product-name">
                            <a v-text="product.productName" :title="product.productName" target="_blank"
                               :href="'/products/form?id=' + product.productId"></a>
                        </td>
                        <td>
                            <select class="form-control" v-model="product.productUnitId" disabled>
                                <option :value="unit.id" v-for="unit in product.productUnits" v-text="unit.unitName" :key="unit.id"></option>
                            </select>
                        </td>
                        <td style="text-align: center" v-text="product.sendQuantity">

                        </td>
                        <td style="text-align: center" v-text="product.receiveQuantity">
                        </td>

                    </tr>
                    <template v-if="entry.type !== 'request'">
                        <tr v-for="batch in product.productBatches" :key="batch.id">
                            <th>Lô/Date</th>
                            <td style="width: 200px" v-text="batch.product_batch ? batch.product_batch.nameFormatted : ''"></td>
                            <td></td>
                            <td style="text-align: center" v-text="n(batch.sendQuantity)"></td>
                            <td style="text-align: center" v-text="n(batch.receiveQuantity)"></td>

                        </tr>
                    </template>
                </template>

                </tbody>
            </table>

        </div>
        <div class="row" style="margin-bottom: 120px">
            <div class="col-lg-8">

            </div>
            <div class="col-lg-4">
                <table class="table table-index table-striped">
                    <tbody>
                    <tr>
                        <th>Tổng số mặt hàng</th>
                        <td>{{n(totalProduct)}}</td>
                    </tr>
                    <tr>
                        <th v-text="entry.type === 'request' ? 'Tổng số lượng xin' : 'Tổng số lượng chuyển'"></th>
                        <td>{{n(sendQuantity)}}</td>
                    </tr>
                    <tr>
                        <th>Tổng số lượng nhận</th>
                        <td>{{n(receiveQuantity)}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="">
            <ModalPrintProductBarCode ref="modalPrintProductBarCode"></ModalPrintProductBarCode>
        </div>

    </div>
    <div class="transfer-detail__print">
        <ModalPrintTransfer :data="entry" :items="details"></ModalPrintTransfer>
    </div>
</template>

<script>
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import InputText from "@/components/forms/InputText";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QSelect from "@/components/QSelect";
import InputTextArea from "@/components/forms/InputTextArea";
import store from "@/store";
import ModalPrintProductBarCode from "./ModalPrintProductBarCode";
import ModalPrintTransfer from "@/views/transfers/ModalPrintTransfer";
import FieldsExportTransfer from "@/assets/exportFields/fieldsExportTransfer";
import moment from "moment";
    export default {
        name: "TransferDetail",
        components: {ModalPrintProductBarCode, InputTextArea, InputText, InputDatePicker, QSelect, ModalPrintTransfer},
        mounted() {
            document.title = 'Chi tiết chuyển hàng | OmiPOS';
            this.load()
        },
        data() {
            return {
                fields: [],
                entry: {},
                details: [],
                status: [
                    {id: 1 , name: 'Phiếu tạm', color: '#0487d9'},
                    {id: 2 , name: 'Đang chuyển', color: '#04b2d9'},
                    {id: 3 , name: 'Đã nhận', color: '#04bfad'},
                    {id: 4 , name:'Đã hủy', color: '#d7d7d9'}
                ],
                statusRequest: [
                    {id: 1 , name: 'Phiếu tạm', color: '#0487d9'},
                    {id: 2 , name: 'Đang chờ', color: '#04b2d9'},
                    {id: 3 , name: 'Đã chuyển hàng', color: '#04bfad'},
                    {id: 4 , name:'Đã hủy', color: '#d7d7d9'},
                    {id: 5, name: 'Từ chối', color: '#b51b1b'}
                ],
                sendQuantity: 0,
                receiveQuantity: 0,
                totalProduct: 0,
                currentBranch: store.state.CurrentBranch,
                excelData: {
                    fields: null,
                    data: [],
                    fileName: ''
                },

            }
        },
        methods: {
            edit() {
                location.href = '/transfers/form?id=' + this.entry.id;
            },
            async load() {
                const res = await $get('/v1/transfers/show', this.$route.query);
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                this.fields = res.data.fields;
                this.entry = res.data.entry;
                this.details = res.data.details;
                await this.calQuantity();

            },
            async save() {
                this.errors = {};
                this.entry.totalSendQuantity = this.sendQuantity;
                const res = await $post('/v1/transfers/save', {
                    entry: this.entry, details: this.details
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                $alert(res);
            },
            async cancel() {
                if (!window.confirm('Bạn có chắc chắn muốn hủy phiếu ' + this.entry.code + ' này không?')) {
                    return false;
                }

                const res = await $post('/v1/transfers/saveStatus', {
                    id: this.entry.id,
                    action: 'cancel'
                });

                $alert(res);

                if (res.code === 200) {
                    window.location.reload();
                }
            },

            async calQuantity() {
                let sendQuantity = 0;
                let receiveQuantity = 0;
                if (this.details && this.details.length > 0) {
                    this.totalProduct = this.details.length;
                    this.details.forEach(item => {
                        if (item.productBatches && item.productBatches.length > 0) {
                            let sendQty = 0;
                            let receiveQty = 0;
                            item.productBatches.forEach(pb => {
                                sendQuantity += pb.sendQuantity;
                                receiveQuantity += pb.receiveQuantity;
                                sendQty += pb.sendQuantity;
                                receiveQty += pb.receiveQuantity;
                            })

                            item.sendQuantity = sendQty;
                            item.receiveQuantity = receiveQty;
                        }

                    })
                }
                this.sendQuantity = sendQuantity;
                this.receiveQuantity = receiveQuantity;
            },

            showModalPrintBarCode() {
                this.$refs.modalPrintProductBarCode.show(this.details);
            },
            async onPrint() {
                this.$nextTick(() => {
                    window.print();
                });
            },
            async exportExcel() {
                if (!this.excelData.fields) {
                    this.excelData.fields = FieldsExportTransfer;
                }

                const date = moment().format('HHmm_DDMMYYYY');
                this.excelData.fileName = 'chi-tiet-phieu-chuyen-' + this.entry.code + '-' + date + 'xls';

                if (this.details && this.details.length) {
                    return this.exportInfo(this.details);
                } else {
                    console.log('Có lỗi xảy ra');
                }
                return false;
            },
            exportInfo(data) {                
                let arr = [];
                if (data && data.length) {
                    data.forEach(item => {
                        if (item.productBatches && item.productBatches.length) {
                            item.productBatches.forEach(pb => {
                                let it = {};
                                it.code = item.productCode;
                                it.name = item.productName;
                                it.unitName = item.productUnitName;
                                it.sendQuantity = pb.sendQuantity;
                                it.receiveQuantity = pb.receiveQuantity;
                                it.batchName = pb.product_batch ? pb.product_batch.name : '';
                                it.batchDate = pb.product_batch ? pb.product_batch.expireDate : '';

                                arr.push(it);
                            })
                        }
                    })
                }
                return arr;

            }

        },
        computed: {
            isCancel() {
                return parseInt(this.entry.status) === 4 || (this.isReceiveBranch && parseInt(this.entry.status) === 5);
            },
            isDraft() {
                return parseInt(this.entry.status) === 1;
            },
            isOpen() {
                return (this.entry.type === 'transfer' && parseInt(this.entry.status) === 1) || (this.entry.type === 'request' && parseInt(this.entry.status) === 1)
                    || (this.isReceiveBranch && parseInt(this.entry.status) === 2);
            },
            isSendBranch() {
                return this.entry.fromBranchId === this.currentBranch.id;
            },
            isReceiveBranch() {
                return this.entry.toBranchId === this.currentBranch.id;
            },
            isEditField() {
                return (this.isSendBranch && (parseInt(this.entry.status) === 1 || parseInt(this.entry.status) === 2));
            }
        }
    }
</script>

<style lang="scss" scoped>

    .product-name {
        display: block;
        max-width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .transfer-detail__print {
        display: none;
    }
    @media print {
        .transfer-detail {
            display: none;
        }
        .transfer-detail__print {
            display: block;
        }
    }

</style>
