<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" :class="this.disabledDeparment && this.disabledDeparment === true ? 'modal-second' : ''">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{ this.isEdit ? $t("message.ModalCreateUpdatePickup.title_update") : $t("message.ModalCreateUpdatePickup.title") }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{ $t("message.ModalCreateUpdatePickup.address") }}<label class="text-danger px-0">*</label></b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <InputTextArea :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_address")' v-model="entry.addressValue"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{ $t("message.ModalCreateUpdatePickup.province") }}<label class="text-danger px-0">*</label></b>
                                        </div>
                                        <div class="col-9">
                                            <q-select :removeKeyword="true" v-model="entry.provinceId" :default-options="provinces" :multiple="false" :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_province")' @update:modelValue="changeProvince" ref="inputProvinces"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{ $t("message.ModalCreateUpdatePickup.district") }}<label class="text-danger px-0">*</label></b>
                                        </div>
                                        <div class="col-9">
                                            <q-select :removeKeyword="true" v-model="entry.districtId" :default-options="districts" :multiple="false" :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_district")' @update:modelValue="changeDistrict" ref="inputDistricts"/>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3">
                                            <b>{{ $t("message.ModalCreateUpdatePickup.ward") }}<label class="text-danger px-0">*</label></b>
                                        </div>
                                        <div class="col-9">
                                            <q-select :removeKeyword="true" v-model="entry.wardId" :default-options="wards" :multiple="false" :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_ward")' ref="inputWards"/>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-3">
                                            <b>{{ $t("message.ModalCreateUpdatePickup.phone") }}<label class="text-danger px-0">*</label></b>
                                        </div>
                                        <div class="col-9">
                                            <InputText v-model="entry.phone" :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_phone")' :max="12" :cusNumber="true"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <button v-if="this.isEdit" type="button" class="btn btn-save mr-2" @click="update">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.save') }}
                                    </button> 
                                    <button v-else type="button" class="btn btn-save mr-2" @click="submit">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.save') }}
                                    </button>                                    
                                    <button type="button" class="btn btn-secondary text-white  mr-2" @click="clearInput">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.skip') }}
                                    </button>
                                    <button v-if="this.isEdit" type="button" class="btn btn-danger mr-2" @click="showModalDeletePickup(entry.id)">
                                            <i class="fa fa-save mr-1"></i>
                                            {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.remove') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @updated="hide"/>
</template>
<script>
    import InputText from "@/components/forms/InputText";
    import {$post, $alert, scrollToElement, $get} from "@/lib/utils";
    import toastr from 'toastr';
    import InputTextArea from "@/components/forms/InputTextArea";
    import InputLocationPicker from "@/components/forms/InputLocationPicker";
    import QSelect from "@/components/QSelect";
    import store from "@/store";
    import ModalConfirm from "@/views/pickupaddress/ModalRemove.vue";
    export default {
        name: "ModalCreateUpdatePickup",
        components: {InputText , ModalConfirm, InputTextArea, InputLocationPicker, QSelect},
        props: {
            openModal: {
                type: Boolean,
                default: false
            },
             disabledDeparment:Boolean,
        },
        data () {
            return {
                active: 1,
                entry: {},
                errors: {},
                provinces: [],
                districts: [],
                wards: [],
                isEdit: false
            }
        },
        mounted() {
           
        },
        created () {
        }, 
        methods: {
            async show (id ,type) {  
                this.setValue();  
                if (id && type == this.$constant.addressBranchCreate) {
                    this.isEdit = false;
                    this.entry.branchId = id;
                }
                else if (id == null && type == this.$constant.addressBranchCreate) {
                    this.entry.branchId = store.state.CurrentBranch.id;

                }else if (id && type == this.$constant.addressBranchUpdate) {
                    this.isEdit = true;
                    await this.load(id);

                }    
                await this.loadDataLocation();   
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                this.$emit('updated');
                window.jQuery(this.$refs.modal).modal('hide');
            },
            clearInput() {
                this.setValue();
                window.jQuery(this.$refs.modal).modal('hide');
            },
            setValue () {
                this.isEdit = false;
                this.entry = {
                    addressValue : '',
                    provinceId: '',
                    districtId: '',
                    wardId: '',
                    phone: ''
                };
            },

            isMessageError(errMss){ 
                if(errMss['addressValue']) {
                    toastr.error(errMss['addressValue']);                
                    return;
                }
                if(errMss['phone']) {
                    toastr.error(errMss['phone']);                
                    return;
                }
                if(errMss['provinceId']) {
                    toastr.error(errMss['provinceId']);
                    return;
                }
                if(errMss['districtId']){
                    toastr.error(errMss['districtId']);
                    return;
                }
                if(errMss['wardId']){
                    toastr.error(errMss['wardId']);
                    return;
                }
            },
            async submit() {
                const result = await $post('/v1/pickup-address/create', this.entry);
                if (result.code === 512 || result.code === 422) {
                    this.errors = result.errors;
                    scrollToElement('.error-label');
                    this.isMessageError(result.messages.errors);
                } else {
                    toastr.success(this.$t("message.ModalCreateUpdatePickup.message_success"));
                    this.$emit('updated', result);
                    this.$emit('inputData','1');
                    this.hide()
                }
            },
            async update() {
                const result = await $post('/v1/pickup-address/update', this.entry);
                if (result.code === 512 || result.code === 422) {
                    this.errors = result.errors;
                    scrollToElement('.error-label');
                    this.isMessageError(result.messages.errors);
                } else {
                    toastr.success(this.$t("message.ModalCreateUpdatePickup.message_success_update"));
                    window.jQuery(this.$refs.modal).modal('hide');
                    this.$emit('updated', result);
                    this.$emit('inputData','1');
                }
            },
            async loadDataLocation(){
                const res = await $get('/v1/locations/provinces', {
                    provinceId: this.entry.provinceId,
                    districtId: this.entry.districtId
                });
                this.provinces = res.data.provinces;
                this.districts = res.data.districts;
                this.wards = res.data.wards;
            },
            async changeDistrict() {
                this.entry.wardId = null;
                const res = await $get('/v1/locations/wards', {districtId: this.entry.districtId});
                this.wards = res.data;
            },
            async changeProvince() {
                this.entry.districtId = null;
                this.entry.wardId = null;
                const res = await $get('/v1/locations/districts', {provinceId: this.entry.provinceId});
                this.districts = res.data;
                this.changeDistrict();
            },
            async load(id) {
                const res = await $get(`/v1/pickup-address/show/${id}`);
                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }
                this.entry = res.result;
                if (res.result.work_days) {
                    res.result.work_days.forEach(element => {
                    this.$refs.tab.dayOfWorks.push(element.local_key_day);
                    this.entry.work_days = this.$refs.tab.dayOfWorks;
                    });
                }           
            },
            showModalDeletePickup (id)
            {
                this.$refs.ModalConfirm.show(id);
            }
        }
    }
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
</style>