<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="modalConfirm" :class="this.disabledJobTitle && this.disabledJobTitle === true ? 'modal-third' : '' " data-backdrop="static">
        <div class="modal-dialog modal-md" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                    <h5 class="modal-title py-2" v-text="this.title ? this.title : $t('message.common.confirm')"></h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        
                        <h6 style="line-height: 30px;">
                           {{ $t('message.ModalCreateInvoiceMultiple.content') }} <b> {{ $t('message.ModalCreateInvoiceMultiple.status_draft') }}</b> , <b> {{ $t('message.ModalCreateInvoiceMultiple.status_confirm') }}</b>!
                        </h6>
                        <dt style="line-height: 30px;">
                            <li class="px-3"> {{ $t('message.ModalCreateInvoiceMultiple.add_success') }} {{ this.statusAllow }}</li>
                            <li class="px-3"> {{ $t('message.ModalCreateInvoiceMultiple.add_errors') }} {{ this.statusNotAllow }}</li>
                        </dt>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-primary mr-2" @click="confirm()" v-if="statusAllow > 0"><i class="fa fa-check mr-1"></i>{{ $t('message.ModalCreateInvoiceMultiple.continue') }}</button>
                        <button class="btn btn-secondary text-white" @click="skip()"><i class="fa fa-ban mr-1"></i>{{ $t('message.ModalCreateInvoiceMultiple.inogre') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import storeModul from "@/storeModul";

export default {
    name: "ModalCreateInvoiceMultiple",
    data(){
        return {
            action: null,
        }
    },
    props: ['statusAllow', 'statusNotAllow'],
    methods: {
        show(action = null) {              
            this.action = action;            
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            this.$emit('cancel', 1);
            this.hide();
            storeModul.dispatch('productStore/setShowModalConfirnRemoveAtrribute', false);
        },
        async confirm(){
            storeModul.dispatch('productStore/setStatusConfirmRemoveAtrribute', true);
            this.$emit('getEditOrder');
            this.hide();
        },
        async hide() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
            storeModul.dispatch('productStore/setShowModalConfirnRemoveAtrribute', false);
        },
    }
}
</script>

