<template>
    <div class="search-box position-relative" :class="normal ? 'border-bottom-1 rounded-0 p-0 h-100' : ''" v-click-outside="hidePopup">
        <input type="text" class="search-input" :placeholder="placeholder ? placeholder : $t('message.MedicineSearch.placeholder.name')"
               v-model.trim="keyword" @input="onSearchProduct" :readonly="readonly"/>
        <div class="popup-product-search" v-if="keyword && products.length">
            <table class="table table-index table-striped">
                <thead>
                    <tr>
                        <th>{{ $t('message.MedicineSearch.table.name') }}</th>
                        <th>{{ $t('message.MedicineSearch.table.registerNumber') }}</th>
                        <th>{{ $t('message.MedicineSearch.table.ingredient') }}</th>
                        <th>{{ $t('message.MedicineSearch.table.content') }}</th>
                        <th>{{ $t('message.MedicineSearch.table.packing') }}</th>
                        <th>{{ $t('message.MedicineSearch.table.manufacturer') }}</th>
                    </tr>
                </thead>
                <tbody>
                    <template v-if="products.length">

                        <template v-for="product in products" :key="product.id">
                            <tr class="unitRowContent" style="height: 50px" @click="onSelect(product)">
                                <td>{{ (product.name ? product.name : null) }}</td>
                                <td>{{ (product.registerNumber ? product.registerNumber : null) }}</td>
                                <td>{{ (product.ingredient ? product.ingredient : null) }}</td>
                                <td>{{ (product.content ? product.content : null) }}</td>
                                <td>{{ (product.packing ? product.packing : null) }}</td>
                                <td>{{ (product.manufacturer ? product.manufacturer : null) }}</td>
                            </tr>
                        </template>
                    </template>
                    <template v-else>
                        <tr>
                            <td colspan="6" class="products-not-found">{{ $t('message.common.notFound') }}</td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import store from "@/store";
    let app;

    export default {
        name: "MedicineSearch",
        components: {},
        props: ['label', 'branch', 'normal', 'placeholder', 'defaultValue', 'readonly', 'searchMedicine'],
        data() {
            app = this;
            return {
                products: [],
                keyword: this.defaultValue ?? '',
                branchId: this.branch ?? store.state.CurrentBranch.id,
            }
        },
        watch: {
            defaultValue: {
                handler: function(newValue) {
                    if(newValue){
                        this.keyword = newValue;
                    }
                },
                deep: true
            },
        },
        methods: {
            async onSearchProduct() {
                if (this.keyword && this.keyword.length >= 3) {
                    const res = await $get('/v1/sync-medicine/mapping', {
                        keyword: this.keyword,
                        branchId: this.branchId,
                    });
                    this.products = res.result;
                }else{
                    this.products = [];
                }
            },
            onSelect(product) {
                this.$emit('selectMedicine', {'data': product, 'label': this.label});
                this.keyword = product.name ? product.name : '';
                this.hidePopup();
            },
            hidePopup() {
                this.products.length = 0;
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .multiselect{
        height: auto;
        max-height: 84px;
        overflow: auto;
        // padding-bottom: 5px !important;
    }
    .h-input-search{
        height: 35px !important;
    }
    .item-select{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: -50px;
        width: 100%;
        min-width: 900px;
        z-index: 10;
        padding-bottom: 20px;
        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
        tbody {
            display: block;
            max-height: calc(100vh - 300px);
            overflow: auto;
        }
        thead, tbody tr {
            display: table;
            width: 100%;
            table-layout: fixed;
        }
        .products-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }
    }
</style>
