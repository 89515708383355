<template>
    <div class="row transfer-form">
        <div class="action-bar" v-if="type === 'transfer'">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/transfers/index" class="btn btn-default">
                        Quay lại
                    </router-link>

                    <button class="btn btn-default" type="button" @click="save(true)" :disabled="entry.status && entry.status !== 1">
                        <i class="fa fa-save"/>
                        Lưu nháp
                    </button>
                    <button class="btn btn-save" type="button" @click="saveSendTransfer()" v-if="isSendTransfer && entry.status !== 4">Hoàn thành phiếu chuyển</button>
                    <button class="btn btn-save" type="button" @click="saveReceiveTransfer()" v-if="!isSendTransfer && entry.status !== 4">Nhận hàng</button>
                </div>
            </div>
        </div>
        <div class="action-bar" v-if="type === 'request'">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/transfers/index" class="btn btn-default">
                        Quay lại
                    </router-link>

                    <button class="btn btn-default" type="button" @click="save(true)" v-if="entry.status !== 5 && entry.status !== 4">
                        <i class="fa fa-save"/>
                        Lưu nháp
                    </button>
                    <button class="btn btn-save" type="button" @click="saveRequestTransfer()" v-if="isSendRequest && entry.status !== 4">Gửi yêu cầu</button>
                    <button class="btn btn-save" type="button" @click="saveReceiveRequest()" v-if="!isSendRequest && entry.status !== 5 && entry.status !== 4 && !entry.disabled">Tạo phiếu chuyển</button>
                    <button class="btn btn-danger" type="button" @click="save(false, true)" v-if="!isSendRequest && entry.status !== 5">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Từ chối
                    </button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 pb-100 mb-3" v-if="type === 'transfer'">
            <div class="main-title d-flex mb-2">
                <h1 style="width: 30%">Chuyển hàng</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-8 col-sm-12">
                    <div class="form-group">
                        <label>Thêm sản phẩm</label>
                        <QSelect placeholder="Thêm sản phẩm"  @onselected="onSelected" datasource="products" v-model="productModel" :readonly="!isSendTransfer || entry.status === 4 || entry.status === 3"/>
                    </div>
                    <div class="table-wrapper">
                        <table class="table table-index table-striped">
                            <thead>
                            <tr>
                                <th scope="col" class="checkbox-table"></th>
                                <th scope="col">{{$t('message.common.STT')}}</th>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                <th scope="col" style="min-width: 100px">{{$t('message.DetailOrdersCard.dvt')}}</th>
                                <th scope="col" v-if="isSendTransfer">{{$t('message.ProductIndex.inventory')}}</th>
                                <th scope="col" v-text="isSendTransfer ? 'Tồn CN nhận' : 'Tồn kho'"></th>
                                <th scope="col" style="min-width: 100px">{{$t('message.common.quantityTransfer')}}</th>
                                <th scope="col" style="min-width: 100px" v-if="!isSendTransfer">{{$t('message.common.quantityReceive')}}</th>
                                <th scope="col"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template  v-for="(product, index) in details" :key="index">
                                <tr style="font-weight: bold" v-if="!product.deleted">
                                    <td><i class="fas fa-trash-alt" @click.prevent="removeProduct(product)" v-if="isSendTransfer"> </i> </td>
                                    <td v-text="index + 1"></td>
                                    <td v-text="product.productCode"></td>
                                    <td class="product-name">
                                        <span v-text="product.productName" :title="product.productName"></span>
                                        <ErrorLabel :errors="product.errors?.productName"/><br/>
                                    </td>
                                    <td>
                                        <select class="form-control" v-model="product.productUnitId" @change="updateUnit(product)">
                                            <option value="">Chọn đơn vị</option>
                                            <option :value="unit.id" v-for="unit in product.productUnits" v-text="unit.unitName" :key="unit.id"></option>
                                        </select>
                                    </td>
                                    <td v-if="isSendTransfer" v-text="product.invSendQuantity ? product.invSendQuantity : 0"></td>
                                    <td v-text="product.invReceiveQuantity ? product.invReceiveQuantity : 0"></td>
                                    <td colspan="1" v-if="isSendTransfer"></td>
                                    <td colspan="2" v-else></td>

                                    <td>
                                        <a @click="addProductBatch(product)" class="btn btn-sm btn-save" v-if="isSendTransfer">
                                            <i class="fa fa-plus"/>
                                        </a>
                                    </td>

                                </tr>
                                <template v-for="batch in product.productBatches" :key="batch.id">
                                    <tr v-if="!batch.deleted">
                                        <td colspan="5" style="width: 200px">
                                            <QSelect
                                                v-model="batch.productBatchId"
                                                :searchParams="{productId: product.productId}"
                                                @onselected="(option) => productBatchChange(option, batch, product)"
                                                datasource="inventoriesProductBatches"
                                                name="ProductBatch" placeholder="Nhập lô, hạn sử dụng"
                                            :readonly="!isSendTransfer"/>
                                            <ErrorLabel :errors="batch.errors?.productBatchId"/>
                                        </td>
                                        <td v-if="isSendTransfer" v-text="n(batch.sendInventory)"></td>
                                        <td v-text="n(batch.receiveInventory)"></td>
                                        <td style="text-align: center">
                                            <InputNumber :min="0" v-model="batch.sendQuantity"
                                                         @input="calQuantity()" @update:modelValue="value => updatedReceiveQuantity(batch, value)"
                                                         :readonly="!isSendTransfer" :errors="batch.errors?.sendQuantity"/>
                                        </td>
                                        <td style="text-align: center" v-if="!isSendTransfer">
                                            <InputNumber  @input="calQuantity()" @update:modelValue="value => updatedReceiveQuantity(batch, value)" v-model="batch.receiveQuantity"
                                                         :errors="batch.errors.receiveQuantity"/>
                                        </td>

                                        <td class="text-center">
                                            <a class="btn-remove" @click.prevent="removeDetailBatch(batch)" v-if="isSendTransfer">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                    </tr>
                                </template>

                            </template>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="col-lg-4 col-sm-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>{{$t('message.common.created_by')}}</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="form-group">
                                <QSelect v-model="entry.createdBy" datasource="users"
                                         :errors="errors.createdBy" :readonly="isReceiveBranch"/>
                            </div>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>Ngày tạo</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <input-date-picker v-model="entry.createdAt"></input-date-picker>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>Mã chuyển hàng</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <InputText v-model="entry.code"
                                       :errors="errors.code"
                                       :readonly="!!entry.id"/>
                        </div>
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{$t('message.common.status')}}</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="form-group ml-3">
                                <span class="status" :style="{background: status[(entry.status ? entry.status - 1 : 0)].color || '#0487d9'}" v-text="status[(entry.status ? entry.status - 1 : 0)].name || ''"></span>
                            </div>

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>Tổng số lượng chuyển</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <span v-text="n(totalSendQuantity)"></span>
                        </div>
                    </div>
                    <div class="row" v-if="!isSendTransfer">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>Tổng số lượng nhận</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <span v-text="n(totalReceiveQuantity)"></span>
                        </div>
                    </div>
                    <div class="row" v-if="isSendTransfer">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>Chi nhánh nhận</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <QSelect v-model="entry.toBranchId" datasource="branches" @onselected="updateBranch"/>
                        </div>

                        <ul class="error-label" v-if="errors.toBranchId && errors.toBranchId.length">
                            <li v-for="error in errors.toBranchId" :key="error" v-text="error"></li>
                        </ul>
                    </div>
                    <div class="row" v-if="!isSendTransfer">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>Chi nhánh chuyển</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="form-group">
                                <QSelect v-model="entry.fromBranchId" datasource="branches" :readonly="isReceiveBranch"/>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="isSendTransfer">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{$t('message.InvoiceIndex.note')}}</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <InputTextArea v-model="entry.sendDescription" :errors="errors.sendDescription"/>
                        </div>
                    </div>
                    <div class="row mt-2" v-if="!isSendTransfer" >
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>Ghi chú chi nhánh nhận</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <InputTextArea v-model="entry.receiveDescription" :errors="errors.receiveDescription"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-12 pb-100 mb-3"  v-if="type === 'request'">
            <div class="main-title d-flex mb-2">
                <h1 style="width: 30%">Xin chuyển hàng</h1>

            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-8 col-sm-12">
                    <div class="form-group">
                        <label>Thêm sản phẩm</label>
                        <QSelect placeholder="Thêm sản phẩm"  @onselected="onSelected" datasource="products" v-model="productModel" :readonly="!isSendRequest || entry.status === 4 || entry.status === 3"/>
                    </div>
                    <div class="table-wrapper">
                        <table class="table table-index table-striped">
                            <thead>
                            <tr>
                                <th scope="col" class="checkbox-table"></th>
                                <th scope="col">{{$t('message.common.STT')}}</th>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                <th scope="col">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                <th scope="col" style="min-width: 100px">{{$t('message.DetailOrdersCard.dvt')}}</th>
                                <th scope="col" v-text="isSendRequest ? 'Tồn kho' : 'Tồn kho CN yêu cầu'"></th>
                                <th scope="col" v-text="isSendRequest ? 'Tồn kho chi nhánh xin' : 'Tồn kho'"></th>
                                <th scope="col" style="min-width: 100px">Số lượng xin</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template  v-for="(product, index) in details" :key="index">
                                <tr style="font-weight: bold" v-if="!product.deleted">
                                    <td><i class="fas fa-trash-alt" @click.prevent="removeProduct(product)" v-if="isSendRequest"> </i> </td>
                                    <td v-text="index + 1"></td>
                                    <td v-text="product.productCode"></td>
                                    <td class="product-name">
                                        <span v-text="product.productName" :title="product.productName"></span> <br/>
                                    </td>
                                    <td>
                                        <select class="form-control" v-model="product.productUnitId" @change="updateUnit(product)">
                                            <option :value="unit.id" v-for="unit in product.productUnits" v-text="unit.unitName" :key="unit.id"></option>
                                        </select>
                                    </td>
                                    <td v-text="product.invSendQuantity ? product.invSendQuantity : 0"></td>
                                    <td v-text="product.invReceiveQuantity ? product.invReceiveQuantity : 0"></td>
                                    <td>
                                        <InputNumber :min="0" @input="calQuantity()" @update:modelValue="updatedReceiveQuantity()" v-model="product.sendQuantity"
                                                     :errors="product.errors?.sendQuantity" :readonly="!isSendRequest"/>
                                    </td>
                                    <td>
                                    </td>

                                </tr>

                            </template>
                            </tbody>
                        </table>
                    </div>


                </div>
                <div class="col-lg-4 col-sm-12">
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>{{$t('message.common.created_by')}}</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <QSelect v-model="entry.createdBy" datasource="users" :readonly="!isSendRequest"
                                     :errors="errors.createdBy"/>
                        </div>

                        <div class="col-lg-4 col-sm-12 mt-2">
                            <div class="form-group">
                                <label>Ngày tạo</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12 mt-2">
                            <input-date-picker v-model="entry.createdAt"></input-date-picker>
                        </div>

                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>Mã yêu cầu</label>
                            </div>
                        </div>

                        <div class="col-lg-8 col-sm-12">
                            <InputText v-model="entry.code"
                                       :errors="errors.code"
                                       :readonly="!!entry.id"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{$t('message.common.status')}}: </label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="form-group">
                                <span class="status" :style="{background: statusRequest[(entry.status ? entry.status - 1 : 0)].color || '#0487d9'}" v-text="statusRequest[(entry.status ? entry.status - 1 : 0)].name || ''"></span>
                            </div>
                        </div>

                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group ">
                                <label>Tổng số lượng xin</label>
                            </div>
                        </div>
                        <div class="col-lg-8 col-sm-12">
                            <div class="form-group ">
                                <span v-text="n(totalSendQuantity)"></span>
                            </div>
                        </div>
                    </div>

                    <div class="form-group" v-if="isSendRequest">
                        <label>Chi nhánh nhận yêu cầu</label>
                        <QSelect v-model="entry.toBranchId" datasource="branches" @onselected="updateBranch"/>
                        <ul class="error-label" v-if="errors.toBranchId && errors.toBranchId.length">
                            <li v-for="error in errors.toBranchId" :key="error" v-text="error"></li>
                        </ul>
                    </div>
                    <div class="form-group" v-if="!isSendRequest">
                        <label>Chi nhánh yêu cầu xin</label>
                        <QSelect v-model="entry.fromBranchId" :readonly="!isSendRequest" datasource="branches"/>
                    </div>
                    <InputTextArea v-model="entry.sendDescription" label="Ghi chú chi nhánh gửi yêu cầu" :readonly="!isSendRequest"  :errors="errors.sendDescription"/>
                    <InputTextArea v-model="entry.receiveDescription" label="Ghi chú chi nhánh nhận yêu cầu" v-if="!isSendRequest"  :errors="errors.receiveDescription"/>

        </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import {$post, $alert, $get, scrollToElement, clone, setArray, intVal, TYPE_ORDER} from "@/lib/utils";
import QSelect from "@/components/QSelect";
import InputDatePicker from "@/components/forms/InputDatePicker";
import InputTextArea from "@/components/forms/InputTextArea";
import InputNumber from "@/components/forms/InputNumber";
import {TransferStatus} from "@/models/Transfer";
import ErrorLabel from "@/components/ErrorLabel";
import store from "@/store";
import toastr from 'toastr';
import moment from "moment";
import {cloneObject} from "../../lib/utils";
import ModalPrintTransfer from "@/views/transfers/ModalPrintTransfer";

export default {
    name: "TransferForm",
    components: {ModalPrintTransfer, InputTextArea, InputDatePicker, QSelect, InputText, InputNumber, ErrorLabel},
    data() {
        return {
            isDataLoaded: false,
            entry: {
                createdBy: store.state.Auth.user.id,
                transfer_detail: [],
                createdAt: moment().format('YYYY-MM-DD')
            },
            errors: {},
            status: [
                {id: 1, name: 'Phiếu tạm', color: '#0487d9'},
                {id: 2, name: 'Đang chuyển', color: '#04b2d9'},
                {id: 3, name: 'Đã nhận', color: '#04bfad'},
                {id: 4, name:'Đã hủy', color: '#d7d7d9'},
                {id: 5, name: 'Từ chối', color: '#b51b1b'}
            ],
            statusRequest: [
                {id: 1, name: 'Phiếu tạm', color: '#0487d9'},
                {id: 2, name: 'Đang chờ', color: '#04b2d9'},
                {id: 3, name: 'Đã chuyển hàng', color: '#04bfad'},
                {id: 4, name: 'Đã hủy', color: '#d7d7d9'},
                {id: 5, name: 'Từ chối', color: '#b51b1b'}
            ],
            type: '',
            details: [],
            productModel: null,
            totalSendQuantity: 0,
            totalReceiveQuantity: 0,
            user: store.state.Auth.user,
            currentBranch: store.state.CurrentBranch,
            isSendTransfer: false,
            isSendRequest: false
        }
    },
    mounted() {
      if (JSON.parse(localStorage.getItem('details'))!==null) this.details = JSON.parse(localStorage.getItem('details'));
        if (this.$route.query.id) {
            this.load();
        } else {
            this.type = this.$route.query.type ? this.$route.query.type : 'transfer';
            this.isSendTransfer = true;
            if (this.type === 'request') {
                this.isSendRequest = true;
            }
            this.isDataLoaded = true;
        }

        if (this.type === 'request' || this.entry.type === 'request') {
            document.title = 'Xin chuyển hàng | OmiPos';
        } else {
            document.title = 'Chuyển hàng | OmiPos';
        }


    },
    methods: {
        async load() {
            const res = await $get('/v1/transfers/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.details = res.data.details;

            this.type = this.entry.type ? this.entry.type : (this.$route.query.type ?? 'transfer');

            if (this.entry.fromBranchId === this.currentBranch.id) {
                this.isSendTransfer = true;
                this.isSendRequest = true;
            }
            if (this.details && this.details.length > 0) {

                for (const item of this.details) {
                    let sendQty = 0;
                    let receiveQty = 0;
                    const sendRes = await this.getQuantityFromBranch(item.productId, this.entry.fromBranchId ?? this.currentBranch.id);
                    item.totalSendQuantity = sendRes.totalQty;
                    item.invSendQuantity = intVal(item.totalSendQuantity) / intVal(item.conversionValue);
                    item.sendBatchQty = sendRes.inventories;

                    const receiveRes = this.entry.toBranchId ? await this.getQuantityFromBranch(item.productId, this.entry.toBranchId) : [];
                    item.totalReceiveQuantity = receiveRes ? receiveRes.totalQty : 0;
                    item.invReceiveQuantity = intVal(item.totalReceiveQuantity)/ intVal(item.conversionValue);
                    item.receiveBatchQty = receiveRes ? receiveRes.inventories : [];
                    item.errors = {
                        productName: [],
                        sendQuantity: [],
                        receiveQuantity: []
                    }

                    if (item.productBatches && item.productBatches.length > 0) {
                        item.productBatches.forEach(pb => {
                            pb.errors = {
                                productBatchId: [],
                                sendQuantity: [],
                                receiveQuantity: []
                            };
                            sendQty += pb.sendQuantity;
                            receiveQty += pb.receiveQuantity;
                            pb.sendInventory = item.sendBatchQty[pb.productBatchId] ?? 0;
                            pb.receiveInventory = item.receiveBatchQty[pb.productBatchId] ?? 0;
                        })

                        item.sendQuantity = sendQty;
                        item.receiveQuantity = receiveQty;
                    }
                }

                setArray(this.details, {
                    deleted: false,
                });
            }

            await this.calQuantity(this.details ?? []);

            this.isDataLoaded = true;

        },

        async calQuantity() {
            let sendQuantity = 0;
            let receiveQuantity = 0;
            if (this.details && this.details.length > 0) {
                this.details.forEach(item => {
                    if ((this.type === 'transfer' || this.entry.type === 'transfer') && item.productBatches && item.productBatches.length > 0) {
                        let sendQty = 0;
                        let receiveQty = 0;
                        item.productBatches.forEach(pb => {
                            if (!pb.deleted) {
                                sendQuantity += pb.sendQuantity;
                                receiveQuantity += pb.receiveQuantity;
                                sendQty += pb.sendQuantity;
                                receiveQty += pb.receiveQuantity;
                                pb.sendPrice = pb.price * pb.sendQuantity;
                                if (pb.receiveQuantity) {
                                  pb.receivePrice = pb.price * pb.receiveQuantity;
                                }
                            }

                        })

                        item.sendQuantity = sendQty;
                        item.receiveQuantity = receiveQty;
                    } else {
                        if (!item.deleted) {
                            sendQuantity += item.sendQuantity;
                            receiveQuantity += item.receiveQuantity;
                        }
                    }


                })
            }
            this.totalSendQuantity = sendQuantity;
            this.totalReceiveQuantity = receiveQuantity;
        },
        async save(draft = false, isCancel = false) {
            this.errors = {};
            if (this.details && this.details > 0 && !this.validateProductDetails(this.details)) {
                toastr.error('Có lỗi xảy ra, vui lòng kiểm tra lại')
                return;
            }

            if (isCancel) {
                if (!window.confirm('Bạn có chắc chắn muốn từ chối phiếu xin hàng ' + this.entry.code + ' này không?')) {
                  return;
                }
                this.entry.status = TransferStatus.NOT_ACCEPT;
            } else {
                this.entry.status = this.entry.status ? this.entry.status : (draft ? TransferStatus.DRAFT : TransferStatus.INPROGRESS);
            }

            this.entry.type = this.entry.type ? this.entry.type : this.type;
            this.entry.totalSendQuantity = this.totalSendQuantity;
            const res = await $post('/v1/transfers/save', {
                entry: this.entry, details: this.details, currentId: this.currentBranch.id
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            localStorage.removeItem('details');
            $alert(res);
        },

        async saveSendTransfer(draft = false) {
            this.errors = {};
            if (this.details && this.details.length > 0 && !this.validateProductDetails(this.details)) {
                toastr.error('Có lỗi xảy ra, vui lòng kiểm tra lại');
                return;
            }
            this.entry.status = TransferStatus.INPROGRESS;
            this.entry.type = 'transfer';

            const res = await $post('/v1/transfers/save', {
                entry: this.entry, details: this.details, currentId: this.currentBranch.id
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            localStorage.removeItem('details');
            $alert(res);
        },
        async saveRequestTransfer(draft = false) {
            this.errors = {};
            if (this.details && this.details.length > 0 && !this.validateProductDetails(this.details)) {
                return;
            }
            this.entry.status = TransferStatus.INPROGRESS;
            // this.entry.status = this.entry.status ? this.entry.status : (draft ? TransferStatus.DRAFT : TransferStatus.INPROGRESS);
            this.entry.type = 'request';
            this.entry.totalSendQuantity = this.totalSendQuantity;
            const res = await $post('/v1/transfers/save', {
                entry: this.entry, details: this.details, currentId: this.currentBranch.id
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            localStorage.removeItem('details');
            $alert(res);
        },
        async saveReceiveTransfer() {
            this.errors = {};
            if (this.details && this.details.length > 0 && !this.validateProductDetails(this.details)) {
                toastr.error('Có lỗi xảy ra, vui lòng thử lại');
                return;
            }
            if (this.totalReceiveQuantity !== this.totalSendQuantity && !this.entry.receiveDescription) {
                this.errors.receiveDescription = ['Vui lòng nhập trường này']
                toastr.error('Vui lòng nhập ghi chú của chi nhánh nhận hàng');
                return;
            }

            this.entry.status = TransferStatus.SUCCEED;

            const res = await $post('/v1/transfers/save', {
                entry: this.entry, details: this.details, currentId: this.currentBranch.id
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            localStorage.removeItem('details');
            $alert(res);
        },

        async saveReceiveRequest() {
            this.errors = {};
            if (this.details && this.details.length > 0 && !this.validateProductDetails(this.details)) {
                toastr.error('Có lỗi xảy ra, vui lòng thử lại');
                return;
            }

            this.entry.status = TransferStatus.SUCCEED;

            const res = await $post('/v1/transfers/save', {
                entry: this.entry, details: this.details, currentId: this.currentBranch.id
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            localStorage.removeItem('details');
            $alert(res);
        },
        async getQuantityFromBranch(productId, branchId) {
            return await $get('/v1/transfers/getQuantityFromBranch', {
                productId: productId, branchId: branchId
            });
        },
        async updateBranch(branch) {
            if (this.details && this.details.length > 0) {
                    for (const item of this.details) {
                        const receiveRes = await this.getQuantityFromBranch(item.productId, branch.id);
                        item.totalReceiveQuantity = receiveRes.totalQty;
                        item.invReceiveQuantity = intVal(item.totalReceiveQuantity) / intVal(item.conversionValue);
                        item.receiveBatchQty = receiveRes.inventories;
                        if (item.productBatches && item.productBatches.length > 0) {
                            item.productBatches.forEach(pb => {
                                pb.receiveInventory = intVal(item.receiveBatchQty[pb.productBatchId]) / intVal(pb.conversionValue) ?? 0;
                            })
                        }
                }
            }
        },
        updatedReceiveQuantity() {
            this.calQuantity(this.details ?? []);
        },
        async productBatchChange(option, batch, product) {
            batch.productBatchId = option.id;
          if ( intVal(product.sendBatchQty[batch.productBatchId]) / intVal(batch.conversionValue) >0){
            batch.sendInventory = intVal(product.sendBatchQty[batch.productBatchId]) / intVal(batch.conversionValue) ?? 0;
          }else if ( product.sendBatchQty>0) {
            batch.sendInventory = product.sendBatchQty;
          }else {
            batch.sendInventory = 0
          }

            if (this.entry.toBranchId) {
                batch.receiveInventory = intVal(product.receiveBatchQty[batch.productBatchId]) / intVal(batch.conversionValue) ?? 0;
            }
        },
        async onSelected(item) {
            if (!item) {
                return;
            }

            const product = cloneObject(item);
            product.id = null;
            product.errors = {};
            product.sendQuantity = 1;
            product.receiveQuantity = 0;
            product.productId = item.id;
            product.productName = item.orignalName;
            product.productCode = item.code;
            product.productUnitId = item.product_units.length ? item.product_units[0].id : null;
            product.productUnits = item.product_units;
            product.conversionValue = item.product_units.length ? item.product_units[0].conversionValue : 1;
            product.price = item.product_units[0].price;
            product.sendPrice = product.price * product.sendQuantity;


            const sendRes = await this.getQuantityFromBranch(product.productId, this.entry.fromBranchId ?? this.currentBranch.id);

            product.totalSendQuantity = sendRes.totalQty;
            product.invSendQuantity = intVal(product.totalSendQuantity) / intVal(product.conversionValue);
            product.sendBatchQty = sendRes.inventories;

            const receiveRes = this.entry.toBranchId ? await this.getQuantityFromBranch(product.productId, this.entry.toBranchId) : [];
            product.totalReceiveQuantity = receiveRes ? receiveRes.totalQty : 0;
            product.invReceiveQuantity = intVal(product.totalReceiveQuantity)/ intVal(product.conversionValue);
            product.receiveBatchQty = receiveRes ? receiveRes.inventories : [];
            product.errors = {
                productName: [],
                sendQuantity: [],
                receiveQuantity: []
            };
            if (this.entry.type === 'transfer' || this.type === 'transfer') {
                product.productBatches = [
                    {
                        productBatchId: null,
                        productUnitId: product.productUnitId,
                        sendQuantity: 1,
                        sendInventory: 0,
                        receiveInventory: 0,
                        receiveQuantity: 0,
                        conversionValue: item.product_units.length ? item.product_units[0].conversionValue : 1,
                        price: product.price,
                        sendPrice: product.price,
                        quantity: 0,
                        errors: {
                            productBatchId: [],
                            sendQuantity: [],
                            receiveQuantity: []
                        },
                        deleted: false
                    }
                ];
            }

            product.deleted = false;

            this.details.push(product);
            localStorage.setItem('details', JSON.stringify(this.details));
            await this.calQuantity(this.details ?? [])
        },

        async updateUnit(product) {
            for (const item of product.productUnits) {
                if (item.id === product.productUnitId) {
                    product.conversionValue = item.conversionValue;
                    product.productUnitId = item.id;
                    product.invSendQuantity = intVal(product.totalSendQuantity) / intVal(product.conversionValue);
                    product.invReceiveQuantity = intVal(product.totalReceiveQuantity) / intVal(product.conversionValue);
                    product.price = item.price;

                    if (product.productBatches && product.productBatches.length > 0) {
                        product.productBatches.forEach(pb => {
                            pb.conversionValue = item.conversionValue;
                            pb.productUnitId = item.id;
                            pb.sendInventory = intVal(product.sendBatchQty[pb.productBatchId]) / intVal(pb.conversionValue) ?? 0;
                            pb.receiveInventory = product.receiveBatchQty ? intVal(product.receiveBatchQty[pb.productBatchId])/ intVal(pb.conversionValue) : 0;
                            pb.price = product.price;
                            pb.sendPrice = pb.price * item.sendQuantity;

                        })
                    }

                }
            }
        },
        removeProduct(product) {
            if (!confirm('Xóa sản phẩm: ' + product.productName)) {
              return;
            }
          let itemIndex = -1;
          this.details.forEach((item, index)=> {

            if (item.productId === product.productId) {
              itemIndex = index;
            }
          });


          if (itemIndex > -1) {
            this.details.splice(itemIndex, 1);
          }
          this.totalSendQuantity -= product.sendQuantity;
          localStorage.removeItem('details');
          localStorage.setItem('details',JSON.stringify(this.details));
          product.deleted = true;
          setArray(product.productBatches, {
            deleted: true
          });
            this.calQuantity(this.details ?? []);
        },
        validateLength(details) {
            let length = 0;
            details.forEach(product => {
                if (!product.deleted) {
                    length++;
                }
                let lengthBatch = 0;

                if ((this.entry.type === 'transfer' || this.type === 'transfer') && product.productBatches && product.productBatches.length > 0) {
                    product.productBatches.forEach(pb => {
                        if (!pb.deleted) {
                            lengthBatch++;
                        }

                    })

                    if (lengthBatch === 0) {
                        product.errors.productName = ['Vui lòng thêm lô date cho sản phẩm này'];
                        return true;
                    }
                }
            })

            if (length === 0) {
                toastr.error('Vui lòng chọn sản phẩm trước khi lưu');
                return true;
            }
        },
        validateProductDetails(details) {
            let validateOK  = true;
            if (this.validateLength(details)) {
                return;
            }
            details.forEach(product => {
                if (product.deleted) {
                    return;
                }

                if (!product.productName) {
                    validateOK = false;
                    product.errors.productName = ['Vui lòng chọn sản phẩm'];
                }

                if ((this.entry.type === 'transfer' || this.type === 'transfer') && product.productBatches && product.productBatches.length > 0  && product.errors.length >0) {
                    product.productBatches.forEach(pb => {
                        if (!pb.productBatchId) {
                            validateOK = false;
                            pb.errors.productBatchId = ['Vui lòng chọn lô date'];
                        } else {
                          pb.errors.productBatchId = [];
                        }
                        if (!pb.sendQuantity) {
                            validateOK = false;
                            pb.errors.sendQuantity = ['Vui lòng nhập số lượng sản phẩm'];
                        } else {
                          pb.errors.sendQuantity = [];
                        }

                        if (pb.sendQuantity === 0) {
                            validateOK = false;
                            pb.errors.sendQuantity = ['Số lượng sản phẩm phải lớn hơn 0'];
                        } else {
                            pb.errors.sendQuantity = [];
                        }

                        if (this.isSendTransfer && pb.sendQuantity > pb.sendInventory) {
                            validateOK = false;
                            pb.errors.sendQuantity = ['Số lượng tồn kho không đủ'];
                        } else {
                            pb.errors.sendQuantity = [];
                        }

                        if (!this.isSendTransfer && pb.receiveQuantity > pb.sendQuantity) {
                            validateOK = false;
                            pb.errors.receiveQuantity = ['Số lượng nhận không lớn hơn số lượng gửi'];
                        } else {
                          pb.errors.receiveQuantity = [];
                        }

                    })
                }

                if (this.entry.type === 'request' || this.type === 'request') {
                    if (this.isSendRequest && !product.sendQuantity) {
                        validateOK = false;
                        product.errors.sendQuantity = ['Vui lòng nhập số lượng sản phẩm'];
                    } else {
                      product.errors.sendQuantity = [];
                    }
                    if (intVal(product.sendQuantity) === 0) {
                        validateOK = false;
                        product.errors.sendQuantity = ['Số lượng xin phải lớn hơn 0'];
                    } else {
                        product.errors.sendQuantity = [];
                    }
                    if (this.isSendRequest && product.sendQuantity > product.invReceiveQuantity) {
                        validateOK = false;
                        product.errors.sendQuantity = ['Tồn kho chi nhánh xin không đủ'];
                    } else {
                        product.errors.sendQuantity = [];
                    }

                }
            })

            return validateOK;
        },

        addProductBatch(d) {
            d.productBatches.push({
                productBatchId: null,
                productUnitId: d.productUnitId,
                sendQuantity: 1,
                sendInventory: 0,
                receiveInventory: 0,
                receiveQuantity: 0,
                conversionValue: d.conversionValue,
                quantity: 0,
                errors: {
                    productBatchId: [],
                    sendQuantity: [],
                    receiveQuantity: []
                },
                deleted: false
            });

            this.calQuantity(this.details ?? []);
        },
        removeDetailBatch(batch) {
            if (!confirm('Xóa lô này?')) {
                return;
            }

            batch.deleted = true;
            this.calQuantity(this.details ?? []);
        }
    },
    watch: {
        'entry.toBranchId': function (newVal) {
            this.currentBranch = store.state.CurrentBranch;
            if (this.isSendTransfer) {
                if (newVal && (newVal === this.entry.fromBranchId || (!this.entry.fromBranchId && newVal === this.currentBranch.id))) {
                    this.errors.toBranchId = this.type === 'transfer' ? ['Không được chọn chi nhánh chuyển là chi nhánh hiện tại'] : ['Không được chọn chi nhánh nhận yêu cầu là chi nhánh hiện tại'];
                    return;
                } else {
                    this.errors.toBranchId = [];
                }
            }
        }
    },
    computed: {
        isSendBranch() {
            return this.entry.fromBranchId === this.currentBranch.id;
        },
        isReceiveBranch() {
            return this.entry.toBranchId === this.currentBranch.id;
        }
    }
}
</script>

<style scoped lang="scss">
.status {
    padding: 2px 6px;
    color: #ffffff;
    border-radius: 6px;
}
.transfer-form {
    .product-name {
        display: block;
        font-weight: normal;
        width: 250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

</style>
