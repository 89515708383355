<template>
    <div class="row">
        <ActionBar back-url="/sale-channels/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.id ? 'Chỉnh sửa kênh bán' : 'Thêm mới kênh bán'"></h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên kênh bán"/>
                            <div class="form-group">
                                <label>Màu sắc</label>
                                <ColorPicker
                                    theme="light"
                                    :color="color"
                                    :sucker-hide="false"
                                    :sucker-canvas="null"
                                    @changeColor="changeColor"
                                    @openSucker="false"
                                />
                            </div>

                            <InputSwitchButton v-model="entry.isActive"
                                               label="Trạng thái"></InputSwitchButton>

                        </div>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputFileManager v-model="entry.img"
                                              :errors="errors.img"
                                              label="Thumbnail"/>
                            </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import InputFileManager from "../../components/forms/InputFileManager";
    import { ColorPicker } from 'vue-color-kit'
    import 'vue-color-kit/dist/vue-color-kit.css'

    export default {
        name: "SaleChannelForm",
        components: {InputFileManager, ActionBar, InputText, InputSwitchButton, ColorPicker},
        data() {
            return {
                isDataLoaded: false,
                entry: {
                    isActive: 1,
                    color: '#194d33',
                },
                color: '#194d33',
                errors: {}
            }
        },
        mounted() {
            document.title = this.entry.id ? 'Chỉnh sửa kênh bán | OmiPos' : 'Thêm mới kênh bán | OmiPos';
            if (this.$route.query.id) {
                this.load();
            } else {
                this.isDataLoaded = true;
            }

        },
        methods: {
            async load() {
                const res = await $get('/v1/sale-channels/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {

                    location.replace('/404');
                    return;
                }

                this.entry = res.data.entry;
                this.color = this.entry.color;
                this.isDataLoaded = true;

            },
            async save() {
                this.errors = {};

                const res = await $post('/v1/sale-channels/save', {
                    entry: this.entry
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            },

            async changeColor(color) {
                this.entry.color = color.hex;
                this.color = color.hex;
            }
        }
    }
</script>

<style scoped>

</style>
