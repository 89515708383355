<template>
    <div class="qselect " ref="wrapper" :class="[(readonly ? 'cursor-ban' : ''), (this.overFlow ? 'overFlow' : '')]"  @click="openDropdown()">                
        <div class="qselect-input-box pl-0" ref="input" :class="[({active: showDropDown, 'upload_file':this.shadow, 'border-none' : this.filter}), (cardSettingPrice ? 'input-custom-1' : ''),(this.printTemplateStatus ? 'upload_file' : '')]"
        :id="this.prdStyle && this.prdStyle == true ? 'qSelectBox' : '' "
        >
              <!-- <div @click="openDropdown()" class="pr-4 mr-4"> -->
                <div class="pr-0 mr-4">
                <template v-if="!hiddenOptionValues">
                    <template v-if="this.isSelectedAll == true">
                        <span class="qslelect-tag">
                            {{$t('message.common.all')}}                       
                            <span  class="close-btn"  @click="titleClickAll(this.options)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
                                    <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
                            </span>
                        </span>
                    </template>
                    <template v-else>
                        <span class="qslelect-tag" v-for="(v,i) in values" :key="i" :class="this.filter === true ? 'text-dark bg-gray' : ''">
                            {{v.label}}
                            <span  class="close-btn" @click="remove(v, i)">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
                                    <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
                            </span>
                        </span>
                    </template>
                </template>                
                <input class="qselect-input" :class="[(this.prdStyle !== true ? 'min-w-100' : '' ), (readonly ? 'cursor-ban' : ''), (this.printTemplateStatus ? 'd-none py-2' : '') , styleInput ]" ref="searchInput"
                :id="this.prdStyle && this.prdStyle == true ? 'qSelectInput' : ''"
                @keyup="onSearch(keyword, $event)" v-model="keyword"  
                :placeholder="values.length != 0 && !hiddenOptionValues ? '' : placeholder||'tìm kiếm...'" :readonly="!!readonly" >
                <span class="dropdown-btn mr-0">
                    <i class="fa fa-caret-down" aria-hidden="true" :class="[(printTemplateStatus ? 'icoDown' : '')]"></i>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362" ><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg> -->
                </span>
              </div>
              <a class="add-q-btn text-dark" href="#" @click.stop="updatePermissionRole" v-if="this.isEditRole == true">
                    <i class="fa fa-pencil-alt"></i>
              </a>
        </div>
        <button title="Thêm Nhanh" v-if="quickadd" class="qselect-add" type="button" @click="add()"><i class="fas fa-plus"></i></button>
        <div class="qselect-dropdown" ref="dropdown" style="display: none" >
            <ul ref="dropDownUl" v-if="options.length">
                <li v-if="this.newRole" class="qselect-item qSelectAll">                   
                    <a href="#" class="lnkRole" @click.stop="addRole">
                         <i class="fa fa-plus-circle"></i>
                         {{this.newRoleLable}}
                    </a>
                </li>
                <li v-if="this.multiple && this.isSelectAll === true" class="qselect-item qSelectAll">
                        <input class="qselect-checkbox" :checked="this.chkStatus" @change="titleClickAll(this.options)" :label="$t('message.common.all')" type="checkbox" id="sltAll">
                        <a href="javascript:;" :class="[(printTemplateStatus ? 'titleLabelCustom' : 'titleLabel')]" @click="titleClickAll(this.options)">
                            {{$t('message.common.all')}}
                        </a>   
                </li>
                <li @click="select(o)"
                    class="qselect-item"
                    v-for="(o,index) in options" v-show="o.show"
                    :key="index"
                    @mouseover="onMouseOver(index)"
                    :title="o.label"
                    :class="{
                        active: focusIndex===index,
                        last: index === options.length - 1,
                        'qselect-item--flex': !usedSlot,
                    }"
                >
                    <div v-if="usedSlot">
                        <slot :item="o"></slot>
                    </div>
                    <template v-else>   
                        <input v-if="isMultiple" @change="checkBoxCheck(o)" v-model="o.checked" class="qselect-checkbox" type="checkbox">
                        <a href="javascript:;" @click="titleClick(o)" :class="[(printTemplateStatus ? 'titleLabelCustom' : 'titleLabel')]">{{o.label}}</a>      
                    </template>                    
                    <span v-if="this.isPermission">
                        <i class="fa fa-pencil-alt ml-2 text-secondary " :class="this.isPermission ? 'pencil-update' : 'd-none'" @click.stop="updateItem(o)" v-if="this.filter === true"></i>
                    </span>   
                    <span v-if="this.isEditRouteOfUse">
                        <i class="fa fa-pencil-alt ml-2 text-secondary pencil-update-hover" @click.stop="updateRouteOfUse(o)"></i>
                    </span>                  
                </li>
            </ul>
            <p v-else class="notfound">
                <em>{{loaded ? emptyLabel : '...'}}</em>
            </p>
            <p class="text-center" v-if="isCreateAttribute" >
                <i class="fa fa-plus px-2" @click="showModalCreateAtt()"></i>
            </p>
        </div>
    </div>
</template>
<script>
    import {clone, pluck, removeVietnameseTones, $post, $alert, arr} from "../lib/utils";
    import $ from 'jquery';
    import {$getDataSource} from "../lib/datasource";
    import emitter from "@/lib/emitter";
    const searchIgnore = {
        'ArrowDown': true,
        'ArrowUp': true,
        'Enter': true,
    };
    function convertOption(o) {
        o.checked = false;
        o.show = true;
        o.label = o.label || o.name || '';
        o.keyword = removeVietnameseTones(o.label.toLowerCase());
        return o;
    }
    function getQSelectValues(modelValue, options, isMultiple, isSelectAll) {
        const optionMap = pluck(options);
        let values = [];
        let value = modelValue;
        let valueCheckedCount = 0;
        let valueLength = 0;
        let isSelectedAll = false;

        if (isMultiple) {
            if (!Array.isArray(modelValue)) {
                if (modelValue) {
                    console.warn(`QSelect WARNING: ${this.name} props.multiple=true but v-model value is not an array`);
                }
                value = [];
            }
            valueLength = value.length;
            value.forEach(v => {
                const o = optionMap[v] ? optionMap[v] : null;
                if (o) {
                    valueCheckedCount++;
                    o.checked = true;
                    values.push(clone(o));
                } else {
                    values.push({id: v, label: v + '...' });
                }
            });
            if(isSelectAll){
                const new_value = values.filter(value => value.id != 'null');
                const new_option = options.filter(option => option.id != 'null');
                if(new_value?.length == new_option?.length){
                    isSelectedAll = true;
                }else{
                    isSelectedAll = false;
                }
                values = new_value;
            }
        } else {
            if (Array.isArray(modelValue)) {
                console.error('QSelect ERROR: Attribute multiple=false but v-model value IS an Array');
                value = modelValue.length ? modelValue[0] : null;
            }

            const o = optionMap[value] ? optionMap[value] : null;
            valueLength = 1;

            if (o) {
                o.checked = true;
                valueCheckedCount = 1;
                values.push(clone(o));
            } else if (value) {
                values.push({id: value, label: value + '...' });
            }
        }

        return {
            values,
            model: value,
            isSelectedAll: isSelectedAll,
            fullfill: valueCheckedCount === valueLength
        };
    }
    export default {
        props: ['modelValue', 'multiple', 'defaultOptions', 'datasource', 'search',
            'placeholder', 'quickadd', 'quickaddhandler', 'optionchanged', 'field', 'name',
            'searchParams', 'defaultEmptyLabel', 'readonly', 'usedSlot',
            'noWaitOther', 'shadow','showadd', 'selectAll', 'isFilter', 'cardSettingPrice',
            'prdStyle', 'overFlow', 'hiddenOptionValues',
            'newRole', 'newRoleLable', 'isEditRole', 'dataUpdate', 'isPermission', 'isEditRouteOfUse', 'isCreateAttribute',
            'removeKeyword', 'index', 'printTemplateStatus', 'styleInput'
            ],
        methods: {
            showModalCreateAtt() {
                this.$emit('showModalCreateAtt');
            },
            updatePermissionRole() {                          
                this.$emit('updatePermissionRole', this.dataUpdate);
            },
            addRole(){                
                this.$emit('addRole');
            },
            updateItem(o){
                this.$emit('updateItem', o);
            },
            updateRouteOfUse (o) {
                if (o) {
                    emitter.emit('showModalCreateRouteOfUse', o.id); 
                } 
            },
            titleClickAll(options){        
                // chưa được check trước khi tick chọn
                if(this.chkStatus == false) {
                    this.$emit('updateFullBranch', 1);
                    options.forEach(opt => {                        
                        this.select(opt);
                    });
                    this.chkStatus = true;
                }
                
                else {
                    this.$emit('updateFullBranch', 0);
                    options.forEach(opt => {
                    if(opt.checked == false) {
                        this.chkStatus = true;
                        this.select(opt);
                    }
                    else {
                        if(opt.checked == false) {
                            this.chkStatus = true;
                            this.select(opt);
                        }
                        else {

                            this.chkStatus = false;
                            this.removeItem(opt);
                        }
                        
                    }   
                });
                }
            },
            titleClick(option){    
               //Kiểm tra trường checked == false;
               if (option.checked == false) {            
                        if(option.label == this.$t('message.common.all') && option.id == 'null'){
                            this.isSelectedAll = true;
                            this.options.forEach(opt => {                            
                                this.select(opt);
                            });
                            this.chkStatus = true;
                        }  
                        else {
                            this.select(option);
                            
                        }
               }
               else {    
                    this.chkStatus = true;
                    if(option.label == this.$t('message.common.all') && option.id == 'null'){                         
                        this.isSelectedAll = false;    
                        this.removeSelectAll();                    
                        this.options.forEach(opt => {                            
                            this.removeItem(opt);                                                       
                        });
                    }
                    else{
                        this.removeItem(option);
                        this.isSelectedAll = false;
                        this.options.forEach(opt => {
                            if(opt.id == 'null' && opt.label == this.$t('message.common.all')){
                                opt.checked = false;
                            }
                            if(opt.id == option.id){
                                opt.checked = false;
                            }
                        })
                    }
                }
                this.$forceUpdate();
            },
            checkBoxCheck(option) {
               
                // Tất cả
                if (option.checked) {               
                    if(option.label == this.$t('message.common.all') && option.id == 'null'){
                        this.isSelectedAll = true;
                        this.options.forEach(opt => {                            
                            this.select(opt);
                        });
                    }else{                       
                        this.select(option);
                    }
                } 
                else {                    
                    if(option.label == this.$t('message.common.all') && option.id == 'null'){
                        this.isSelectedAll = false;
                        this.options.forEach(opt => {                            
                            this.removeItem(opt);
                        });
                    }else{
                        this.removeItem(option);
                        this.isSelectedAll = false;
                        this.options.forEach(opt => {
                            if(opt.id == 'null' && opt.label == this.$t('message.common.all')){
                                opt.checked = false;
                            }
                        })
                    }
                }                
            },
            removeItem(option){
                const vIndex = this.values.findIndex(o =>  {
                    return o.id.toString() === option.id.toString();
                });
                this.remove(option, vIndex);                          
            },
            async onSearch(keyword, $event) {

                if (searchIgnore[$event.key]) {
                    console.log('nothing happen');
                    return;
                }
                keyword = removeVietnameseTones(keyword.toLowerCase());
                this.focusIndex = -1;
                if (!this.isAsync) {
                    this.options.forEach(o => {
                        o.show = o.keyword.indexOf(keyword) >= 0;
                    });
                } else {
                    this.handleSearch(keyword);
                }

            },
            async selfQuickAddHandler() {
                const name = prompt('Thêm nhanh giá Trị:', '');
                if (name) {
                    const res = await $post('/xadmin/data-source/save', {
                        datasource: this.field.datasource,
                        field: this.field.field,
                        name: name
                    });
                    $alert(res);
                    if (res.code === 200) {
                        this.options = res.data.map(convertOption);
                    }
                }
            },
            async add() {
                if (!this.quickaddhandler) {
                    this.selfQuickAddHandler();
                    return;
                }

                let retData = await this.quickaddhandler(async (value) => {
                    await this.handleSearch(this.keyword, value)
                });

                if (!retData) {
                    return;
                }

                if (!Array.isArray(retData)) {
                    alert('quickadd not return an Array');
                    return;
                }
                let options = retData.map(convertOption);
                const map = pluck(options);
                this.values.forEach(v => {
                    if (map[v.id]) {
                        map[v.id].checked = true;
                        v.label = map[v.id].label;
                    }
                });
                this.options = options;
                if (this.optionchanged) {
                    this.optionchanged(options);
                }
            },
            handleSearch: async function (keyword, defaultValues) {                
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(async () => {
                    const params = this.searchParams || {};
                    params.query = keyword;

                    if (defaultValues) {
                        params.values = defaultValues.map(v => v.id).join(',');
                    }
                    let retData;
                    let options;
                    if (this.search) {
                        retData = await this.search(this.datasource, params, defaultValues);
                        if (!Array.isArray(retData)) {
                            console.error(this.name, 'QSelect Error: search function not return a valid Array}');
                            retData = [];
                        }

                        options = retData.map(convertOption);
                        options.forEach(o => {
                            o.show = o.keyword.indexOf(keyword) >=0;
                        })
                    } else {
                        if (this.isAsync) {
                          //const res = await $get(this.dataSourceUrl, params, false);
                          const res = await $getDataSource(this.datasource, params);
                          retData = res.data;
                          options = retData.map(convertOption);
                        }
                    }

                    const map = pluck(options);
                    this.values.forEach(v => {
                        if (map[v.id]) {
                            map[v.id].checked = true;
                            v.label = map[v.id].label;
                        }
                    });

                    this.loaded = true;
                    this.options = options;

                }, 500);
            },
            onMouseOver(i) {
                this.focusIndex = i;
            },
            setShowDropDown(value) {
                this.showDropDown = value;
                if (value) {
                    this.$refs.dropdown.style.display = 'block';
                    this.checkSelectAll();
                } else {
                    this.$refs.dropdown.style.display = 'none';
                }
            },
            checkSelectAll(){
                if(this.selectAll && Array.isArray(this.values) && Array.isArray(this.options)){
                    this.isSelectedAll = this.chkStatus = (this.values.length == this.options.length);
                }
            },
            onKeyDown(ev) {
                if (!this.showDropDown) {
                    return;
                }

                const maxIndex = this.options.length - 1;
                if (ev.key === 'ArrowDown') {
                    ev.preventDefault();
                    let indexCount = 0;
                    for (let i = 0; i <= maxIndex; i++) {
                        if (this.options[i].show) {
                            indexCount++;
                            if ( this.focusIndex < i) {
                                this.focusIndex = i;
                                break;
                            }
                        }
                    }

                    this.$refs.dropDownUl.scrollTop = (indexCount-8)*27;

                } else if (ev.key === 'ArrowUp') {
                    ev.preventDefault();
                    if (this.focusIndex > 0) {
                        let indexCount = 0;
                        let f = 0;
                        for (let i = 0; i <= maxIndex; i++) {
                            if (this.options[i].show) {
                                if (this.focusIndex <= i) {
                                   break;
                                }
                                f = i;
                                indexCount++;

                            }
                        }

                        this.focusIndex = f;


                        this.$refs.dropDownUl.scrollTop = (indexCount-8)*27;
                    }
                } else if (ev.key === 'Enter') {
                    ev.preventDefault();
                    const o = this.options[this.focusIndex];
                    if (o) {
                        if (o.checked) {
                            this.remove(this.options[this.focusIndex]);
                        } else {
                            this.select(this.options[this.focusIndex]);
                        }
                    }
                } else if (ev.key === 'Escape') {
                    ev.preventDefault();
                    this.setShowDropDown(false);
                }
            },
            
            onChange() {                     
                this.model = this.values.map(v => v.id);
                this.$emit('changeData', clone(this.model));                
                this.$emit('roleChange',  {dataUpdate: this.dataUpdate, newRoleId: this.model});                
                this.$emit('branchChangeStatus', {dataUpdate: this.dataUpdate, branchIds: this.model});
                
                if (this.isMultiple) {
                    this.$emit('update:modelValue', this.model);
                    this.$emit('onselected', clone(this.values));
                    this.$nextTick(() => {
                        this.$emit('input', this.model)
                    })
                } else {
                    const modelValue =  (typeof this.model[0] !== 'undefined') ? this.model[0] : null;
                    const value =  (typeof this.values[0] !== 'undefined') ? this.values[0] : null;
                    this.$emit('update:modelValue', modelValue);
                    this.$emit('onselectedAttribute', {modelValue, index: this.index});
                    this.$emit('onselected', value);
                    this.$nextTick(() => {
                        this.$emit('input', modelValue)
                    })
                }
                this.$nextTick(() => {
                    this.resizeHandler();
                });
            },
            remove(option, vIndex) {
                if (this.readonly) {
                    return;
                }
                const index = this.options.findIndex(o => option.id.toString() === o.id.toString());
                this.values.splice(vIndex, 1);
                if (index > -1) {
                    this.options[index].checked = false;
                }
                this.options[0].checked = false;                                
                this.onChange();
            },
            removeSelectAll(){
                this.options.forEach(option => {
                    option.checked = false;
                    this.removeItem(option);
                })
                this.isSelectedAll = false;
            },
            select(option) { 
                if (this.isMultiple) {
                    if (!option.checked) {
                        option.checked = true;
                        this.values.push(clone(option));
                        this.onChange();
                        this.keyword = '';                        
                    }
                } else {
                    this.options.forEach(o => {
                        o.checked = (o.id === option.id);
                    });
                    this.setShowDropDown(false);
                    this.values = [clone(option)];
                    this.onChange();
                    this.keyword = '';
                    
                }
            },
            openDropdown() {                
                if (this.readonly) {
                    return;
                }
                this.setShowDropDown(true);
                this.resizeHandler();
                this.$refs.searchInput.focus();
            },
            resizeHandler() {
                const offset = this.input.position();
                this.dropDown.css({top: offset.top + this.input.height()+9, left: offset.left, width: this.input.width() + 8})
            },
            clickHandler(ev) {
                if (!this.wrapper.find(ev.target).length) {
                    this.setShowDropDown(false);
                    if(this.removeKeyword){
                        this.keyword = null;
                        if(this.options){
                            this.options.forEach(o => {
                                o.show = true;
                            });
                        }
                    }
                }
            }
        },
        watch: {
            dataUpdate(newData){                                   
                this.updateData = newData;                  
            },
            defaultOptions: function (newOptions) {                
                let options;
                if (Array.isArray(newOptions)) {
                    options = clone(newOptions).map(convertOption);
                }  else {
                    options = [];
                }                
                this.options = options;                
                this.options.forEach(o => {
                    this.values.forEach( v => {
                        if(v.id === o.id){
                            v.label = o.label;
                            o.checked = true;
                        }
                    });
                });
            },
            modelValue: {
                handler(newValue) {  // here having access to the new and old value
                    if(this.defaultOptions?.length > this.options?.length){
                        this.options = clone(this.defaultOptions).map(convertOption);
                    }
                    const q = getQSelectValues(newValue, this.options, this.isMultiple, this.isSelectAll);
                    this.isSelectedAll = q.isSelectedAll;
                    this.values = q.values;
                    if(this.values?.length == 0){
                        this.options.forEach(option => {
                            option.checked = false;
                        })
                    }
                    if(this.isSelectedAll){
                        this.options.forEach(option => {
                            option.checked = true;
                        }) 
                        this.chkStatus = true;                
                    } else {
                        if (!Array.isArray(newValue)) {
                            if (newValue) {
                                console.warn(`QSelect WARNING: ${this.name} props.multiple=true but v-model value is not an array`);
                            }
                        }else{
                            this.options.forEach(option => {
                                if (newValue.includes(option.id))  {
                                    option.checked = true;
                                } else {
                                    option.checked = false;
                                }
                            });
                        }
                    }                
                    if (this.isAsync) {
                        if (!q.fullfill) {
                            console.log('QSELECT: Missing local value. Perform a remote fetch', this.name)
                            this.handleSearch('', arr(newValue).map(v => {
                                return {id: v}
                            }));
                        } else {
                            console.log('QSELECT: Local Value FullFill')
                        }
                    }
                    this.checkSelectAll();
                },
                deep: true,
            }
        },
        data() {
            if (!this.name) {
                // console.error('QSelect: Missing props.name');
            }

            const isMultiple =  this.multiple || false;
            const filter =  this.isFilter || false;
            let isAsync = false;

            if (this.datasource || typeof this.search === 'function') {
                isAsync = true;
            }

            let options;

            if (Array.isArray(this.defaultOptions)) {
                options = clone(this.defaultOptions).map(convertOption);
            }  else {
                options = [];
            }
            const isSelectAll = this.selectAll || false;
            if(isSelectAll){
                // options = [{id: 'null', label: this.$t('message.common.all'), checked: false, show: true}, ...options]
                options = [...options]
            }
            const q = getQSelectValues(this.modelValue, options, isMultiple, isSelectAll);

            let dataSourceUrl = this.datasource;

            if (this.datasource) {
                if (!(this.datasource.startsWith('http://') || this.datasource.startsWith('https://'))) {
                    dataSourceUrl = '/v1/data-sources/index?datasource=' + this.datasource;
                }
            }

            return {                
                isChange:false,
                updateData:null,
                chkStatus:false,
                keyword: '',
                showSearchInput: false,
                timeoutId: 0,
                isMultiple,
                isSelectAll,
                options: options,
                loaded: false,
                focusIndex: -1,
                showDropDown: false,
                emptyLabel: this.defaultEmptyLabel || this.$t('message.common.noData'),
                isAsync,
                model: q.model,
                values: q.values,
                dataSourceUrl,
                isSelectedAll: q.isSelectedAll,
                filter: filter
            }
        },
        beforeUnmount() {

            window.removeEventListener('resize', this.resizeHandler);
            document.removeEventListener('click',  this.clickHandler);
            window.removeEventListener('keydown',  this.onKeyDown);
        },
        mounted() {            
            this.dropDown = $(this.$refs.dropdown);
            this.input = $(this.$refs.input);
            this.wrapper = $(this.$refs.wrapper);
    
            window.addEventListener('resize', this.resizeHandler);
            window.addEventListener('keydown', this.onKeyDown);
            document.addEventListener('click',  this.clickHandler);
            this.resizeHandler();

            if (this.isAsync && this.options.length === 0) {
                this.handleSearch('', this.values);
            } else {
                this.loaded = true;
            }

        },
        created(){
            emitter.on("clearOptionsQselect", () => {
                this.options.forEach(o => {
                    o.checked = false;
                });
                this.isSelectedAll = false;
                this.chkStatus = false;
            });
                    
        },
        name: "QSelect"
    }
</script>

<style lang="scss">
    .lnkRole{
        text-decoration: none;
        // color: #000;
    }
    .lnkRole:hover{
        text-decoration: none;
        // color: #000;
    }
    .titleLabel:hover, .titleLabelCustom:hover{
        text-decoration: none;
        color: #000;
    }
    .titleLabel {
        color:#000;
        max-width: 70% !important;
    }
    .titleLabelCustom {
        color:#000;
        max-width: 90% !important;
    }
    .upload_file {
        border-radius: 0 !important;
        border: 0 !important;
        border-bottom: 1px solid #ccc !important;
    }
    .qselect {
        width: 100%;
        display: flex;
        flex-direction: row;
    }

    .qselect-add {
        width: 40px;
        background-color: white;
        border-radius: 5px;
        border: 1px solid #ccc;
        outline: 0;
        &:hover,&:active,&:focus {
            outline: 0;
        }
    }

    .qselect-input-box.active {
        border: 1px solid gray;
    }
    
    .dropdown-btn {
        position: absolute;
        right: 15px;
        top: 10px;
    }

    .dropdown-btn svg{
        width: 9px;
        height: 9px;
        fill: #ccc
    }
    .close-btn svg:hover {
        fill: red;
    }

    .close-btn svg {
        width: 6px;
        height: 6px;
        cursor: pointer;
        fill: white;

    }
    .notfound {
        padding-left:10px;
        padding-right:10px;
        padding-top:10px;
        font-size: 12px;
    }
    .close-btn {
        color: #039be5;
        padding-left: 5px;
        border-left: 1px solid #fff;
        line-height: 0;
    }
    .qslelect-tag {
        display: inline-block;
        padding: 4px;
        color: white;
        margin: 1px;
        border-radius: .2rem;
        background-color: #008080;
        font-size: 12px;
    }
    .qselect-input-box {
        position: relative;
        display: block;
        width: 100%;
        min-height: 25px;
        // padding-top:3px;
        padding-left: 5px;
        padding-right: 5px;
        // padding-bottom: 3px;
        font-size: .9rem;
        font-weight: 400;
        color: #6c757d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .42rem;
        -webkit-transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out,-webkit-box-shadow .15s ease-in-out;
    }

    #qSelectInput{
       border:0;

    }
    #qSelectBox{
        width: 100% !important;
        border: 0px !important;
        border-radius: 0 !important;    
        border-bottom: 1px solid #ced4da !important;
    }
    .qselect-input {
        margin: 3px;
        padding: 0;
        border: 0;
        outline: none;
        box-sizing: content-box;
        box-shadow: none;
        background: none rgba(0,0,0,0);
        line-height: 1;
        vertical-align: middle;
        height: 25px;
        padding-left: 10px;
        padding-bottom:5px;
    }    
    .qselect-dropdown {
        position: absolute;
        background-color: #fff;
        z-index:1040;
        border: 1px solid #ccc;
        border-top: 0;
    }
    .qselect-dropdown ul {
        list-style: none;
        padding: 0;
        margin-bottom: 0;
        max-height: 250px;
        overflow-y: auto;
    }
    .qselect-item.last {
        border-bottom: 0;
    }
    .qselect-item.active {
        background-color: #e2e5e5a8;
        color: white;
        cursor: pointer;
    }
    // .qSelectAll{
    //     border: 1px solid #ccc;
    //     height: 32px;
        
    // }
    // .qSelectAll:hover{
        
    //     background-color: #e2e5e5a8;
    //     color: white;
    //     cursor: pointer;
    // }
    .qselect-item--flex {
        display: flex;
    }
    .qselect-item {
        position: relative;
        border-bottom: 1px solid #ccc;
        /* padding-top: 3px; */
        /* margin-top: 3px; */
        padding-left: 10px;
        padding-top: 5px;
        min-height: 30px;
        align-items: center;
    }

    .qselect-item-flex {
        border-bottom: 1px solid #ccc;
        /* padding-top: 3px; */
        /* margin-top: 3px; */
        padding-left: 10px;
        padding-top: 5px;
        min-height: 30px;
        display: flex;
        align-items: center;
    }

    .qselect-item span{
        height: 25px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .qselect-checkbox {
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 0;
        cursor: pointer;
        margin-right: 5px;
    }

    .q-select-loading {
        margin-top: 4px;
        margin-left: 5px;

    }
    .pencil-update{
        position: absolute;
        right: 1rem;
        padding-left: 5px;
        display: none;
    }
    .pencil-update-hover{
        position: absolute;
        right: 1rem;
        padding-left: 5px;
        display: none;
    }

    li:hover{
        .pencil-update-hover, .pencil-update{
            display: block;
        }         
    } 
        

    .bg-gray{
        background: #D9D9D9;
    }
    .overFlow{
        max-height: 200px !important ;
        overflow-y: auto;
        overflow-x: hidden ;
    }
    .add-q-btn {
        display: none;
        position: absolute;
        top: 10px;
        right: 6px;
    }
    .qselect-input-box:hover  .add-q-btn {
        display: block;
    }
    .icoDown{
        position: absolute;
        right: -1.5rem !important;
    }
</style>
