<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4">{{$t('message.PlanIndex.title')}} {{ this.entry.code }}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent" v-if="this.entry">
                        <div class="tab-pane fade show active" v-if="this.entry">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-6 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.PlanIndex.detail.code')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="entry.code ?? null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.PlanIndex.detail.name')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.name ?? null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.PlanPurchaseIndex.detail.branchCreate')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.branch ? entry.branch.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.PlanPurchaseIndex.detail.branch')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{ entry.plan_branch ? entry.plan_branch.name : null }}</div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.status')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.statusName"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6 pl-0">
                                    <div class="row border-bottom-1 m-0" >
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.planCreateUpdate.plan_time')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="col-7 ow-break-word" v-text="d(entry.startDate) + ' '+ $t('message.planCreateUpdate.to') + d(entry.endDate)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.planCreateUpdate.plan_estimate')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1">{{ entry.estimatedDate }}</div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.created_by_user ? entry.created_by_user.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.DiscountsBAS.created_at')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(entry.created_at)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.InvoiceIndex.note')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="entry.note"></div>
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <div class="mt-2 mx-4 pb-2 mb-4">
                                <div class="row detail-prd-row mt-3 m-0 w-100">
                                    <div class="col-12 p-0">
                                        <table class="w-100">
                                            <thead>
                                                <tr class="backgroup__detail table-header border-1">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.ImportPositionIndex.table.unit')}}</th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.planCreateUpdate.table.min_inventory')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.planCreateUpdate.table.max_inventory')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.planCreateUpdate.table.estimate_number')}}</th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.planCreateUpdate.table.sys_estimate_number')}}</th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2">{{$t('message.planCreateUpdate.table.available_inventory')}}</th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2">{{$t('message.common.totalQuantityOrder')}}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <!-- <tr class="table-header border">
                                                    <th scope="col" class="pl-4 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent">
                                                    </th>
                                                    <th scope="col" class="pl-4 py-2"> 
                                                        <input type="text" v-model="this.defaultValues.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent">
                                                    </th>
                                                    <th colspan="6"></th>
                                                </tr> -->
                                                <template v-if="this.defaultValues.filter?.length > 0">
                                                    <template v-for="(item, index) in this.defaultValues.filter" :key="item.id">
                                                        <tr class="table-header border" v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                            <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                <div class="row m-0 d-flex">
                                                                    <div class="col-10 p-0">
                                                                        <a :href="'/products/index?code=' + item.product.code" target="_blank" class="text-decoration-none text-primary">{{item.product.code}}</a>
                                                                    </div>
                                                                </div>
                                                            </th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal">
                                                                <span v-text="item.product.name"></span>
                                                            </th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.product_unit ? item.product_unit.unitName : null"></th>
                                                            <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.minimalQuantity)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.minimalQuantity)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.estimateNumber)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.sysEstimateNumber)"></th>
                                                            <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="n(item.availableInventory)"></th>
                                                            <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="n(item.totalQuantityOrder)"></th>
                                                        </tr>
                                                    </template>
                                                </template>
                                                
                                            </tbody>
                                        </table>
                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                        <div class="no-data border border-top-0" v-if="entry.invoice_items?.length == 0 || this.defaultValues.filter?.length == 0">
                                            <span>{{$t('message.common.notFound')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-0 pb-4 mt-0" v-if="this.stockCardType != this.$constant.stockCardTransfer">
                                <div class="col-6">
                                    <div class="row mb-2" v-show="entry.coupon_detail">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <i class="fa-solid fa-badge-percent"></i>{{$t('message.DetailOrdersCard.codeDiscount')}}
                                            </p>
                                            <span v-if="entry.messageCoupon" v-text="entry.messageCoupon"></span>
                                        </div>
                                    </div>
                                    <div class="row" v-show="entry.messagePromotion">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <span class="btn-icon-gift mr-2 text-danger"><i class="fa fa-gift"></i></span>
                                                {{$t('message.DetailOrdersCard.promotion')}}
                                            </p>
                                            <span v-text="entry.messagePromotion"></span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.PlanIndex.total_products')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalProducts)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.PlanIndex.total_quantity_products')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(entry.totalQuantityProducts)"></div>
                                    </div>
                                   
                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1" @click="open(entry.code)">
                                            <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post, $get, removeVietnameseTones} from "@/lib/utils";
import permissionMixin from '@/permissionMixin';
import store from "@/store";

export default {
    mixins: [permissionMixin],
    name: "DetailStockCard",
    components: {Pagination},
    data () {
        return {
            entry: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            stockCardType: null,
            defaultValues:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
            userId: store.state.Auth.user.id,
        }   
    },
    created() {
    },
    methods: {
        async show (id) {
            if (id) {
                const result = await $get('/v1/plans/detail', {id:id});
                this.entry = result.result;

            
                this.defaultValues.filter = this.defaultValues.all = this.entry.details
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(items){
            let arr = [];
            if(items){
                arr = items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
     
        clearData(){
            this.entries = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
            this.stockCardType = null;
            this.defaultValues = {
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            };
        },
        open(code){
            const route = this.$router.resolve({
                path: '/inventory-plans/index',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        showCombo(entry){
            if(!entry.showCombo){
                entry.showCombo = true;
            }else{
                entry.showCombo = !entry.showCombo;
            }
        },
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
</style>