// import http from '@/services/http';
// import apiEndpoints from '@/services/apiEndpoints';
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
// import router from '@/router';
const getDefaultState = () => {
    return {
        token: '',
        timeActive: '',
    };
};
const state = getDefaultState();
const getters = {
    token (state) {
        return state.token;
    },
    timeActive (state) {
        return state.timeActive;
    },
};
const mutations = {
    SET_TOKEN (state, token) {
        state.token = token;
    },
    SET_TIME_ACTIVE (state, time) {
        state.timeActive = time;
    },
};
const actions = {
    // async loginUser ({ commit }, payload) {
    //     const resLogin = await http.post(apiEndpoints.login, payload);
    //     const { token, timeActive } = resLogin.data.result;
    //     commit('SET_TOKEN', token);
    //     commit('SET_TIME_ACTIVE', timeActive);
    //     return true;
    // },
    // async logoutUser ({ commit }, payload) {
    //     const res = await http.post(apiEndpoints.logout);
    //     const { status } = res;
    //     if (status) {
    //         await commit('UNSET_USER');
    //         return true;
    //     } else {
    //         return false;
    //     }
    // },
    // async removeToken ({ commit }) {
    //     await http.post(apiEndpoints.removeToken);
    //     commit('SET_TOKEN', '');
    //     commit('SET_TIME_ACTIVE', '');
    // },
    // async updateTimeActive ({ commit }, payload) {
    //     commit('SET_TIME_ACTIVE', payload);
    // },
    async getData () {        
        // const res = await $post('/v1/bank-book/ex-data', {}); 
        // console.log(res);
        // return res;
    },
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
