<template>
    <div class="row p-0 m-0">
        <div class="col-lg-6 px-0 mx-0" style="border: 1px solid #ccc" id="design">
            <!-- <DesignTemplates :ref="'DesignTemplates' + this.pdfTemplateId" 
                :action="this.action" 
                :entry="this.entry"
                :html="this.html" 
                :content="this.content" 
                :pdfTemplateId="this.pdfTemplateId"
                :pdfTemplateData="this.pdfTemplate" 
                :file="this.file" 
                :dataValue="this.dataValue" 
                :printTemplates="this.printTemplates" 
                @changeContent="this.changeContent" /> -->
            <DesignTemplates :ref="'DesignTemplates' + this.pdfTemplateId" @changeContent="this.changeContent" />

        </div>
        <div class="col-lg-6 px-0 mx-0" style="background-color: #eef3f7">
            <ViewTemplates ref="ViewTemplates" :contentOrigin="contentOrigin"/>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import DesignTemplates from "./tabs/DesignTemplates";
import ViewTemplates from "./tabs/ViewTemplates";


export default {
    name: "PdfTemplate",
    components: { DesignTemplates, ViewTemplates },
    mounted() { },
    data() {
        return {
            entry: {},
            data: {},
            html: '',
            contentOrigin: null,
        };
    },
    methods: {
        changeContent(newContent) {
            this.contentOrigin = newContent;
        },
    },
    computed: {
        ...mapGetters({
            content: 'sendMailTemplateStore/sendMailZaloContent',
            pdfTemplate: 'sendMailTemplateStore/sendMailZaloTemplate',
            pdfTemplateId: 'sendMailTemplateStore/sendMailZaloTemplateId',
            action: 'sendMailTemplateStore/action',
            file: 'sendMailTemplateStore/pdfFileId',
            dataValue: 'sendMailTemplateStore/dataValueKeys',
            printTemplates: 'sendMailTemplateStore/printTemplates',
        }),
    },
    watch: {
        
    }
};
</script>
