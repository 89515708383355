<template>
    <div class="d-row g-4 my-4">
        <div class="col-12 col-lg-12">
            <div class="app-card app-card-chart h-100 shadow-sm">
                <div class="app-card-body p-3 p-lg-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h5 class="app-card-title">
                                {{this.$t('message.RevenueOriginal.title')}}
                                <i class="fa fa-arrow-circle-right"></i>
                                <span class="text-primary">{{ ' ' + this.totalAmount > 0 ? numberFloat(this.totalAmount) : 0}}</span>
                            </h5>
                        </div>
                        <div class="">
                            <DateRangeDashboardPicker :formatType="'YYYY-MM-DD'" @picker="filterTime"/>
                        </div>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="col-md-1 px-0" :class="this.option == $constant.typeForDay ? 'option-active' : ''" @click="choseOption($constant.typeForDay)">
                            {{$t('message.RevenueOriginal.day')}}
                        </div>
                        <div class="col-md-1 px-0" :class="this.option == $constant.typeForHour ? 'option-active' : ''" @click="choseOption($constant.typeForHour)">
                            {{$t('message.RevenueOriginal.hour')}}
                        </div>
                        <div class="col-md-1 px-0" :class="this.option == $constant.typeForDayOfWeek ? 'option-active' : ''" @click="choseOption($constant.typeForDayOfWeek)">
                            {{$t('message.RevenueOriginal.dayOfWeek')}}
                        </div>
                    </div>
                    <div class="chart-container" v-if="this.totalAmount >= 0">
                        <BarChart ref="revenueChart" :chartData="chartData" :options="chartOptions"></BarChart>
                    </div>
                    <div class="chart-container" v-else>
                        <div class="col-12 text-center text-secondary mt-5">
                            <img src="../../../public/img/No-record.png">
                        </div>
                        <div class="col-12 text-center my-10 text-secondary mb-5">
                            <span class="border-none">{{$t('message.common.notData')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import { BarChart } from 'vue-chart-3';
import DateRangeDashboardPicker from '../DateRangeDashboardPicker.vue';
import { $get, $alert} from "@/lib/utils";
import { Chart } from 'chart.js';
    export default {
        components: {
            BarChart,
            DateRangeDashboardPicker,
        },
        data() {
            return {
                total: [],
                branchesRemove: [],
                chartData: {
                    labels: [],
                    datasets: [],
                },
                chartOptions: {
                    responsive: true,
                    maintainAspectRatio: false,
                    tooltips: {
                        enabled: true,
                        callbacks: {
                            label: function(tooltipItem, data) {
                                let value = new Intl.NumberFormat('en-US').format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                                return data.labels[tooltipItem.index] + ': ' + value;
                            }
                        }
                    },
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                                callback: function(value, index, ticks) {
                                    // return new Intl.NumberFormat('vi').format(value);
                                    if (!value) return 0;
                                    const units = ['', 'k', 'tr', 'tỉ'];
                                    const k = 1000;
                                    const magnitude = Math.floor(Math.log(value) / Math.log(k));
                                    return (
                                        value / Math.pow(k, magnitude) + ' ' + units[magnitude]
                                    );
                                }
                            },
                            stacked: true,
                        }],
                        xAxes:[{
                            stacked: true,
                        }]
                    },
                    legend: {
                        display: true,
                        onClick: this.legendClickHandler
                    }
                },
                filter: null,
                option: this.$constant.typeForDay,
                totalAmount: 0,
            };
        },
       
        methods: {
             async legendClickHandler(event, legendItem) {
                let ci = this.$refs.revenueChart.chartInstance;
                var index = legendItem.datasetIndex;
                var meta = ci.getDatasetMeta(index);
                meta.hidden = meta.hidden === null ? !ci.data.datasets[index].hidden : null;

                const branchId =  this.chartData.datasets[legendItem.datasetIndex].branchId;
                if (!this.branchesRemove.includes(branchId)) {
                    this.branchesRemove.push(branchId);
                }else {
                    this.branchesRemove = this.branchesRemove.filter(item => item != branchId)
                }
                const res = await $get('/v1/dashBoard/get-revenue-invoices', {
                    filter: this.filter,
                    option: this.option,
                    removeBranches: [...this.branchesRemove]
                });
                this.totalAmount = res.result.totalAmount ?? 0;
                this.chartData.labels = res.result.labels;
                this.chartData.datasets = res.result.datasets;

                ci.update();
            },
            choseOption(option) {
                this.option = option;
                this.branchesRemove = [];
                this.getDataRevenueChart(); 
            },
            filterTime(dataFilter) {
                this.filter = dataFilter.type;  
                this.getDataRevenueChart();    
            },
            async getDataRevenueChart() {
                this.chartData.labels = [];
                this.chartData.datasets = [];
                this.totalAmount = -1;
                const res = await $get('/v1/dashBoard/get-revenue-invoices', {
                    filter: this.filter,
                    option: this.option,
                    removeBranches: []
                });
                if (res.status.code != 200) {
                    $alert({code: 500, message: 'lỗi server'});
                    return;
                } 
                this.chartData.labels = res.result.labels;
                this.chartData.datasets = res.result.datasets;
                this.totalAmount = res.result.totalAmount ?? 0;
            },
        },
    };
  </script>
<style scoped lang="scss">
    @import "@/assets/css/vars.scss";
    select{
        border: none !important;
        outline: none !important;
    }
    .app-card-title{
        font-weight: 600;
    }
    .option-active{
        border-bottom: 2px solid $primary-color;
    }
</style>