<template>
    <div ref="modal" class="modal mx-0 fade modal-second"  tabindex="-1" role="dialog" data-backdrop="static" style="min-width:400px !important;">
        <div class="modal-dialog" :class="(errors?.length > 0) ? 'modal-dialog-scrollable' : 'modal-md'" role="document" >
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4">
                        {{ $t('message.VoucherIndex.modal.create_or_update.button.release_voucher') }}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3 " :class="this.errors?.length > 0 && error?.code.length > 10 ? 'bodyModal' : '' " >
                    <div class="tab-content" id="myTabContent" v-if="this.errors?.length > 0">
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-12 pl-0 mx-0 px-0">
                                    {{$t('message.common.releaseVoucherError')}}:
                                </div>
                            </div>
                            <div class="row mx-4 my-0 mt-2" v-for="error in this.errors" :key="error.id">
                                <div class="col-12 p-0">
                                    - {{ error.code }}
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="tab-content" id="myTabContent" v-else>
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-12 pl-0 mx-0 px-0">
                                    <div class="row m-0">
                                        <div class="col-9 d-flex align-items-center pl-0 text-bold">{{$t('message.VoucherIndex.modal.create_list_voucher.number_voucher')}} <i class="fa fa-info-circle text-gray ml-3"></i></div>
                                        <div class="col-3 pl-0 d-flex align-items-center justify-content-end px-0">
                                            <!-- {{releaseVoucher.quantity}}  -->
                                             {{this.quantity }}
                                        </div>
                                    </div>
                                    <div class="row my-3">
                                        <div class="col-5 text-bold">{{$t('message.common.form')}}</div>
                                        <div class="col-7 text-right">{{$t('message.common.give')}}</div>
                                        <!-- <div class="col-3 d-flex align-items-center pl-0">
                                            <label class="container m-0 flex-1 min-w-100">Bán
                                                <input type="radio" name="releaseVoucherStatus" v-model="releaseVoucher.type" :value="this.$constant.statusActive" class="mr-2" @change="changeValue(this.$constant.statusActive)">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-4 d-flex align-items-center pl-0">
                                            <label class="container m-0 flex-1 min-w-100">Tặng
                                                <input type="radio" name="releaseVoucherStatus" v-model="releaseVoucher.type" :value="this.$constant.status_draft" class="mr-2" @change="changeValue(this.$constant.status_draft)">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div> -->
                                    </div>
                                    <div class="row m-0 my-3">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">{{$t('message.noticeDetail.tables.voucher.release_date')}}</div>
                                        <div class="col-7 pl-0 px-0">
                                            <input type="date" v-model="releaseVoucher.releaseDate" class="form-control input-custom px-0" autocomplete="off">
                                        </div>
                                    </div>

                                    <!-- Bo sung ghi chu -->
                                     <div class="row m-0 my-3">
                                        <div class="col-12 d-flex align-items-center position-relative pl-0 px-0">
                                            <i class="fa fa-pencil-alt icon-note ml-0 text-secondary"></i>                                            
                                            <input :readonly="this.isBuy == false ? true : false"  type="text" name="note" id="note" v-model.trim="releaseVoucher.description" class="form-control input-custom pl-4" autocomplete="off" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                        </div>
                                    </div>

                                    <!-- <div class="row m-0 my-3">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Nhóm người mua/nhận</div>
                                        <div class="col-7 pl-0">
                                            <SelectSearch :placeholder="'Chọn nhóm người mua/nhận'" :readonly="true" :modelValue="releaseVoucher.cusGroupId" :defaultOption="this.$constant.optionUserCreate" 
                                            @update:modelValue="doFilterSearch" class="px-2" @loadCusGroupId="loadCusGroupId"  :label="'cusGroupId'" />
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Tên người mua/nhận</div>
                                        <div class="pl-0 position-relative pl-0" :class="this.cusGrpId == 5 ? 'col-6' : 'col-7'">
                                            <i class="fa fa-search ml-0 text-secondary"></i>                                            
                                            <SelectSearch :placeholder="'Chọn người mua/nhận'" :modelValue="releaseVoucher.customerId" :defaultOption="this.customers" @update:modelValue="doFilterSearch" 
                                             @loadCustomerId="loadCustomerId" class="px-2 h-100" :label="'customer'" />                                             
                                        </div>                                        
                                        <div class="col-1 pt-2 pl-0 d-flex align-items-center justify-content-center" v-if="this.cusGrpId == 5">
                                            <a href="javascript:0" @click="showModalAddCustomerForVoucher(false)" class="border-bottom-1 px-1">
                                                <i class="fa fa-plus ml-0 text-secondary"></i>
                                            </a>                                            
                                        </div>                                          
                                    </div> -->
                                    <!-- <div class="row py-3" v-if="this.newCustomer">
                                        <div class="col-5"></div>
                                        <div class="col-1 d-flex align-items-center p-0">
                                            <a href="#" @click="showModalAddCustomerForVoucher(true)" class="px-1"  >
                                                 <i class="fa fa-pencil-alt ml-0 text-secondary"></i>
                                            </a>
                                            <a class="" href="#" @click="removeCustomer">
                                                 <i class="fa fa-times ml-2 text-danger"></i>
                                            </a>
                                        </div>
                                        <div class="col-6"><span><b> {{this.newCustomer.name }} </b> {{this.newCustomer.phone}}</span></div>
                                    </div> -->

                                </div>
                                <!-- <div class="col-6 pl-0"> -->
                                    <!-- <div class="row m-0">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Giá trị Voucher</div>
                                        <div class="col-7 pl-0">
                                            <div class="form-group mb-0">
                                                <InputNumberFormat :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                :readonly="this.isBuy == false ? 'true' : ''"
                                                v-model="releaseVoucher.value"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row m-0 my-3">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Khách thanh toán</div>
                                        <div class="col-7 pl-0 d-flex align-items-center justify-content-end">
                                            <div class="cate_selected px-2 my-1" v-text="n(releaseVoucher.payment)"></div>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row m-0 my-3">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Phương thức</div>
                                        <div class="col-7 pl-0">
                                            <SelectSearch :placeholder="'Chọn phương thức'" :modelValue="releaseVoucher.paymentMethod" :defaultOption="this.$constant.optionMethodPayment" @update:modelValue="doFilterSearch" 
                                             @loadPaymentMethod="getPaymentMethod" :readonly="true" :disabled="this.isBuy == false ? true : false"
                                            :label="'VoucherpaymentMethod'"/>
                                        </div>
                                    </div>
                                    <div class="row m-0 my-3" v-if="this.paymentMethod == this.$constant.methodPaymentCard || this.paymentMethod == this.$constant.methodPaymentBank">
                                        <div class="col-5 d-flex align-items-center pl-0 text-bold">Số tài khoản:</div>
                                        <div class="col-7 pl-0">
                                            <SelectSearch :placeholder="'Chọn số tài khoản'" :modelValue="releaseVoucher.numberCard" :defaultOption="[]" @update:modelValue="doFilterSearch" :label="'numberCard'"/>
                                        </div>
                                    </div> -->
                                    <!-- <div class="row m-0 my-3 py-3">
                                        <div class="col-12 d-flex align-items-center position-relative pl-0">
                                            <i class="fa fa-pencil-alt icon-note ml-0 text-secondary"></i>                                            
                                            <input :readonly="this.isBuy == false ? true : false"  type="text" name="note" id="note" v-model.trim="releaseVoucher.description" class="form-control input-custom pl-4" autocomplete="off" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                        </div>
                                    </div> -->
                                <!-- </div> -->
                            </div>
                            <div class="row mx-4 pb-4">
                                <div class="col-12 d-flex justify-content-end p-0">
                                    <button class="btn btn-save" @click="releaseVouchers">
                                        <i class="fa fa-save mr-2"></i>
                                        {{ $t('message.button.save') }}
                                    </button>
                                    <button class="btn btn-secondary ml-3" @click="hide">
                                        <i class="fa fa-ban mr-2"></i>{{$t('message.button.cancel')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                    <!-- footer -->
                <div class="modal-footer my-0 py-0 border-top-0" v-if="this.errors?.length > 0" style="height:50px;">
                    <div class="row mx-4 my-4 pb-0">
                        <div class="col-12 d-flex justify-content-end p-0">
                            <button class="btn btn-save" @click="hide">
                            <i class="fa fa-check-square mr-2"></i
                            >{{$t('message.button.confirm')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalMessageGeneral ref="ModalMessageGeneral" @inputData="acceptStepFirst" @acceptStepSecond="acceptStepSecond"/>
    <AddCustomerForVoucher ref="AddCustomerForVoucher" @getCustomer="dataCustomer"/>
</template>
<script>
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import Pagination from "@/components/Pagination";
import {$post, $get} from "@/lib/utils";
import toastr from 'toastr';
import moment from 'moment';
import ModalMessageGeneral from '@/components/ModalMessageGeneral';
import AddCustomerForVoucher from './AddCustomerForVoucher';
export default {
    name: "ReleaseVouchers",
    components: {Pagination, InputNumberFormat, SelectSearch, ModalMessageGeneral, AddCustomerForVoucher},
    data () {
        return {
            quantity: 0,
            // invoiceData: {},
            // paginateDetail: {
            //     currentPage: 1,
            //     lastPage: 1,
            // },
            errors: [],
            releaseVoucher: {},
            isShow: false,
            voucherId:[],
            customers:null,
            cusGrpId: null,
            isBuy: true,
            paymentMethod:1,
            location: null,
            newCustomer: null,
            arrayNewCustomer: [],
            isEditCustomer: false,
        }
    },
    created() {
    },
    methods: {
        removeCustomer(){
            this.newCustomer = null;
        },
        showModalAddCustomerForVoucher(statusEdit){  
            this.isEditCustomer = statusEdit;     
            if(this.isEditCustomer)  {
                this.$refs.AddCustomerForVoucher.show(this.newCustomer);
            }else{
                this.$refs.AddCustomerForVoucher.show();
            }
        },
        dataCustomer(customer, location){
            this.releaseVoucher.customerId = null
            this.customer = customer;
            this.location = location;
            this.newCustomer = {...this.customer, ...this.location};
            if(!this.isEditCustomer){
                this.newCustomer.id = this.arrayNewCustomer?.length + 1;
                this.arrayNewCustomer.push(this.newCustomer);
            }else{
                this.arrayNewCustomer.forEach(cus => {
                    if(cus.id == this.newCustomer.id){
                        Object.keys(this.newCustomer).forEach(key => {
                            cus[key] = this.newCustomer[key];
                        });
                    }
                });
            }
        },
        async show (id = null) {    
            this.voucherId = id;        
            this.clearData();
            if (id) {
                const res = await $post('/v1/vouchers/check-status-vouchers', {ids: id, status: this.$constant.statusNotUsedYetVoucherCoupon});
                if(res && res.result && res.result?.length > 0){
                    this.errors = res.result;
                }
                if(this.errors?.length == 0){                    
                    this.quantity = id?.length;
                }
                window.jQuery(this.$refs.modal).modal('show');
            }
        },
        // Phat hanh voucher
        changeValue(type){
            if(type == 1){
                this.isBuy = true;
            }
            if(type == 0) {
                this.isBuy = false;
            }
            this.releaseVoucher.value = 0;
        },
        async store(){
            const res = await $post('/v1/vouchers/release-vouchers', {ids: this.voucherId, data: this.releaseVoucher, newCustomer:this.newCustomer});  
            if(res && res.status.code == 200 && res.result.code == 200){                
               toastr.success(this.$t('message.common.releaseVoucherSuccess'));
               window.jQuery(this.$refs.modal).modal('hide');
               this.$emit('reloadVoucher');
               this.$emit('removeChecked');
            }
            else {                           
                if(res && res.result.code == 201) {
                    toastr.error(res.result.message);
                }
                else {
                    toastr.error(this.$t('message.common.releaseVoucherFailed'));
                }
            }
        },
        async releaseVouchers_backup(){          
            if(this.releaseVoucher.customerId == null && !this.newCustomer) { 
                let title = this.$t('message.VoucherIndex.modal.create_or_update.button.release_voucher');
                let content = this.$t('message.common.confirmReleaseVoucher');  
                let type = 1;              
                this.$refs.ModalMessageGeneral.show(title, content, type);      
            }
            else {
                if(this.isBuy && this.releaseVoucher.value == 0) {                
                    toastr.error(this.$t('message.common.inValidVoucherValue'));    
                }
                else {                        
                    // window.jQuery(this.$refs.modal).modal('hide');           
                    let title = this.$t('message.VoucherIndex.modal.create_or_update.button.release_voucher');
                    let content = this.$t('message.common.confirmAddReceiptsForVoucher');  
                    let type = 2;                
                    this.$refs.ModalMessageGeneral.show(title, content, type);     
                }  
            }
        },
        async releaseVouchers(){          
                    // window.jQuery(this.$refs.modal).modal('hide');           
                    let title = this.$t('message.VoucherIndex.modal.create_or_update.button.release_voucher');
                    let content = this.$t('message.common.confirmAddReceiptsForVoucher');  
                    let type = 2;                
                    this.$refs.ModalMessageGeneral.show(title, content, type);     
        },
        // acceptStepFirst() { 
        //     if(this.isBuy && this.releaseVoucher.value == 0) {                
        //          toastr.error('Giá trị voucher không hợp lệ');            
        //          return;
        //     }
        //     else {
        //         window.jQuery(this.$refs.modal).modal('hide');           
        //         let title = 'Phát hành voucher';
        //         let content = this.$t('message.common.confirmAddReceiptsForVoucher');  
        //         let type = 2;                
        //         this.$refs.ModalMessageGeneral.show(title, content, type);     
        //     }
  
        // },
        acceptStepSecond(){
            window.jQuery(this.$refs.modal).modal('show');           
            this.store();
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        clearData(){
            this.errors = [];
            this.releaseVoucher = {
                type: this.$constant.statusActive,
                releaseDate: moment().locale('vi').format('YYYY-MM-DD'),
                
                cusGroupId: 0, // Nhóm khách hàng
                customerId: null,
                value: 0,
                payment: 0,
                paymentMethod: this.$constant.methodCashId,
                description: null,
                numberCard: null,
            };
            this.isBuy = true;
            this.arrayNewCustomer = [];
        },
        async doFilterSearch(v){
            if(v.label == 'paymentMethod'){
                this.releaseVoucher.paymentMethod = v.model;
            }
            if(v.label == 'customer'){
                this.releaseVoucher.customerId = v.model;
                this.arrayNewCustomer.forEach(customer => {
                    if(customer.id == this.releaseVoucher.customerId){
                        this.newCustomer = customer;
                    }
                })
            }
        },
        openInvoice(code){
            let route = this.$router.resolve({
                path: '/invoices/index',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        loadCusGroupId(id){    
            this.newCustomer = null;
            this.releaseVoucher.customerId = null;       
            this.customers = []; 
            switch (id) {
                case 1: 
                    this.getDataCustomer();
                    this.cusGrpId = id;
                    break;
                case 2: 
                    this.getDataSupplier();
                    this.cusGrpId = id;
                    break;
                case 3: 
                    this.getDataEmployees();
                    this.cusGrpId = id;
                    break;
                case 4: 
                    // this.getDataSupplier();
                    this.cusGrpId = id;
                    break;
                case 5: 
                    this.cusGrpId = id;
                    this.customers = this.arrayNewCustomer;
                    break;
            }
        },
        loadCustomerId(id){
            this.releaseVoucher.customerId = id;
        },
        async getDataCustomer(){
            const res = await $get('/v1/customers/all-data');
            if(res && res.code == 200) {
                this.customers = res.data;
            }
        },
        async getDataSupplier(){
            const res = await $get('/v1/suppliers/all-data');
            if(res && res.code == 200) {
                this.customers = res.data;
            }
        },
        async getDataEmployees(){
            const res = await $get('/v1/employee/all-data');
            if(res && res.code == 200) {
                this.customers = res.data;
            }
        },
        getPaymentMethod(id){
            this.paymentMethod = id;
        }

    },
    watch: {
        'releaseVoucher.value': {                
            handler(newValue){                          
                this.releaseVoucher.payment = newValue;
            },        
            deep: true,
            
        },   
        'releaseVoucher.method': {                
            handler(newMethod){                          
                this.isShow = (newMethod != this.$constant.methodCashId) ? true : false;
            },        
            deep: true,
            
        },    
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>

#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.icon-note, .fa-search{
    position: absolute;
    left: 0px;
    bottom: 10px;
}

</style>