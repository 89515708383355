
<template>
    <AppPermission :permission="this.$permissions.ticketImports.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{$t('message.ImportPositionIndex.title')}}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                            <div class="col-8 text-title">
                                <label>{{ $t('message.common.numberRecord') }}:</label>
                            </div>
                            <div class="col-4">
                                <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                <option value="15">15</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                </select>
                            </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.ImportPositionIndex.time') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.ImportPositionIndex.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.InputQuickSearch.title.original_documents_type') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="option in this.optionType" :key="option.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{option.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('optionType', option.id)" :checked="isExistInArrayChecked(option, this.filterQuery.optionType)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.ImportPositionIndex.placeholder.search')" @filterSearch="changeQuickSearch" :importPositionSearch="true"></InputQuickSearch>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter mr-1"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <!-- Thao tác -->
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>
                                        {{$t('message.button.action')}}
                                    </button>                                       
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                         <AppPermission :permission="this.$permissions.ticketImports.export">  
                                            <a class="dropdown-item" @click="exSelectOverview()">
                                                <i class="fa fa-share" ></i>
                                                {{$t('message.button.export_all')}}
                                            </a>
                                            <a class="dropdown-item"  @click="exSelectDetails()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.button.export_detail')}}
                                            </a>
                                        </AppPermission>                                         
                                    </div>
                                </div>
                                <!-- Xuất file -->
                                 <AppPermission :permission="this.$permissions.ticketImports.export?? '' ">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-1"></i>
                                            {{$t('message.InvoiceIndex.button.file')}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">                                                                              
                                            <a class="dropdown-item" href="javascript:void(0);" @click="exportOverview()">
                                                <i class="fas fa-file-export mr-1"></i>
                                                {{$t('message.InvoiceIndex.button.file_all')}}
                                            </a>                                        
                                            <a class="dropdown-item" href="javascript:void(0);" @click="exportDetail()">
                                                <i class="fas fa-external-link-alt mr-1"></i>
                                                {{$t('message.InvoiceIndex.button.file_detail')}}
                                            </a>                                        
                                        </div>
                                    </div>
                                </AppPermission>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()">
                                        <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                    </a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.importPositionActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailImportPositionProduct(entry.id, entry)">
                                                                    <div v-if="field.field==='invoice_codes'" :title="entry.invoice_codes" :style="field.style" class="description" :class="field.class">
                                                                        <span :class="entry.invoiceCanceled ? 'text-danger' : ''">{{ entry.invoice_codes }}</span>
                                                                    </div>
                                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="importPositionActive === entry.id" :class="[ entry.id === importPositionActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2">
                                                                    <span class="backgroup__detail-active head-detail">
                                                                        <span>
                                                                            {{$t('message.ImportPositionIndex.tabs.info')}}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiết phiếu chuyển hàng -->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="importPositionActive === entry.id">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4 detail__user">
                                                                <div class="row mt-2 detail-prd-row">
                                                                    <div class="col-4">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.ImportPositionIndex.code')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.code"></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5 lableOS">
                                                                                {{$t('message.ImportPositionIndex.created_at')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word lableOS pr-0" v-text="dt(entry.created_at)"></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2" v-if="entry.statusOrigin != this.$constant.status_draft">
                                                                            <div class="col-5">
                                                                                {{$t('message.ImportPositionIndex.created_by')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.createdBy"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">
                                                                        <div class="row ml-2 border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.ImportPositionIndex.original_document')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">   
                                                                                <template v-if="entry.return_buy && entry.return_buy != null">
                                                                                    <a :href="'/returns/index?code=' + entry.codeOriginalDocuments" target="_blank" class="text-decoration-none text-primary">{{ entry.codeOriginalDocuments }}</a>
                                                                                </template>   
                                                                                <template v-else-if="entry.transfer && entry.transfer != null">
                                                                                    <a :href="'/request/index?code=' + entry.codeOriginalDocuments" target="_blank" class="text-decoration-none text-primary">{{ entry.codeOriginalDocuments }}</a>
                                                                                </template>   
                                                                                <template v-else-if="entry.import_supplier && entry.import_supplier != null">
                                                                                    <a :href="'/purchase-orders/index?action=import&status=0,1&purchase_order=' + entry.codeOriginalDocuments" target="_blank" class="text-decoration-none text-primary">{{ entry.codeOriginalDocuments }}</a>
                                                                                </template>                                                         
                                                                            </div>
                                                                        </div>
                                                                        <div class="row ml-2 border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{$t('message.ImportPositionIndex.status')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.status"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4">  
                                                                        <div class="row ml-2 p-0 font-italic" id="noteRO">
                                                                            <div class="col-12">
                                                                                <textarea v-model.trim="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.ImportPositionIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Chi tiet san pham va gia -->
                                                    <tr class="detail-row bg-white border__body-detail" v-if="importPositionActive === entry.id">
                                                        <td :colspan="fields.length" class="border-top-0">
                                                            <div class="mt-2 pb-4 detail__user">
                                                                <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                                    <div class="col-12 pl-0">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail">
                                                                                    <template v-for="fieldDetail in this.fieldImportPositionDetail" :key="fieldDetail">
                                                                                        <th scope="col" class="pl-4"  v-text="fieldDetail.name" v-if="fieldDetail.selected" :style="fieldDetail.style ? fieldDetail.style : ''"></th>
                                                                                    </template>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                <tr class="bg-white">
                                                                                    <th scope="col" class="pl-4"> 
                                                                                        <input type="text" v-model="this.defaultImport.searchCode" @input="onInputFilterDetail('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                                    </th>
                                                                                    <th scope="col" class="pl-4"> 
                                                                                        <input type="text" v-model="this.defaultImport.searchName" @input="onInputFilterDetail('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                                    </th>
                                                                                    <th scope="col" class="pl-4" :colspan="fieldImportPositionDetail.length - 2"> 
                                                                                    </th>
                                                                                </tr>
                                                                                <template v-if="this.defaultImport.filter?.length > 0">
                                                                                    <template v-for="(detail, index) in this.defaultImport.filter" :key="detail.id">
                                                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                            <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                                <td class="pl-4">
                                                                                                    <a :href="'/products/index?code=' + detail.productCode" target="_blank" class="text-decoration-none text-primary">{{detail.productCode}}</a>
                                                                                                </td>
                                                                                                <td class="pl-4">
                                                                                                    <div class="row p-0 m-0">
                                                                                                        <div class="col-12 pl-0" v-text="detail.productName"></div>
                                                                                                        <div class="col-12 pl-0 description font-italic text-gray" v-text="detail.description" :title="detail.description"></div>
                                                                                                    </div>
                                                                                                </td>
                                                                                                <td class="text-center" v-text="detail.unitName"></td>
                                                                                                <td class="pl-4" v-text="detail.variant ? detail.variant.name : null"></td>
                                                                                                <td class="pl-4 text-right" v-text="n(detail.quantity)"></td>
                                                                                                <td class="pl-4">
                                                                                                    <template v-if="detail.locationName">
                                                                                                        <div v-text="detail.locationName"></div>
                                                                                                        <div v-text="'(' + detail.locationCode + ')'"></div>
                                                                                                    </template>
                                                                                                </td>
                                                                                            </tr>
                                                                                            <template v-if="detail.consignments && detail.consignments.length">
                                                                                                <tr class="table-header border bg-white border-left-0 border-right-0" v-for="(value, index) in detail.consignments" :key="index">
                                                                                                    <td></td>
                                                                                                    <td :colspan="4">
                                                                                                        <div class="mr-1 mt-1 mb-2">
                                                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td class="pl-4">
                                                                                                        <template v-if="value.locationName">
                                                                                                            <div v-text="value.locationName"></div>
                                                                                                            <div v-text="'(' + value.locationCode + ')'"></div>
                                                                                                        </template>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr class="text-center bg-light">
                                                                                        <td :colspan="fieldImportPositionDetail.length">{{ $t('message.common.notFound') }}</td>
                                                                                    </tr>
                                                                                </template>
                                                                            </tbody>
                                                                        </table>
                                                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                                    </div>
                                                                </div>
                                                                <div class="row detail-prd-row mt-4 pr-4">
                                                                    <div class="col-10 flex-end">{{$t('message.ImportPositionIndex.totalProducts')}}:</div>
                                                                    <div class="col-2 flex-end" v-text="n(entry.totalProducts)"></div>
                                                                </div>
                                                                <div class="row detail-prd-row mt-2 pr-4">
                                                                    <div class="col-10 flex-end">{{$t('message.ImportPositionIndex.totalTypeProducts')}}:</div>
                                                                    <div class="col-2 flex-end" v-text="n(entry.totalTypeProducts)"></div>
                                                                </div>
                                                                <div class="row detail-prd-row flex-end mt-4 pr-4" >
                                                                    <AppPermission :permission="this.$permissions.ticketImports.quickSave?? '' ">
                                                                        <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)">
                                                                            <i class="fa fa-save"></i>
                                                                            {{$t('message.ImportPositionIndex.button.save')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                     <AppPermission :permission="this.$permissions.ticketImports.update?? '' ">
                                                                        <button type="button" class="btn btn-save mx-1" @click="update(entry.code)" v-if="entry.statusOrigin == this.$constant.status_draft">
                                                                            <i class="fa fa-share"></i>
                                                                            {{$t('message.ImportPositionIndex.button.process')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                    <AppPermission :permission="this.$permissions.ticketImports.update?? '' ">
                                                                        <button type="button" class="btn btn-save mx-1" @click="update(entry.code)" v-if="entry.statusOrigin != this.$constant.completed && entry.statusOrigin != this.$constant.status_draft">
                                                                            <i class="fa fa-share"></i>
                                                                            {{$t('message.ImportPositionIndex.button.update')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                     <AppPermission :permission="this.$permissions.ticketImports.print?? '' ">
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print" aria-hidden="true"></i>
                                                                            {{$t('message.ImportPositionIndex.button.print')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                     <AppPermission :permission="this.$permissions.ticketImports.export?? '' ">
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="exportRecord(entry.id)">
                                                                                <i class="fa fa-file-export"></i>
                                                                                {{ $t('message.ExportPositionProduct.button.export') }}
                                                                        </button>
                                                                    </AppPermission>
                                                                    <AppPermission :permission="this.$permissions.ticketImports.cancel?? '' ">
                                                                        <button type="button" class="btn btn-danger mx-1" @click="cancelTicket(entry)" v-if="entry.statusOrigin != this.$constant.status_draft">
                                                                            <i class="fa fa-times"></i>
                                                                            {{$t('message.ImportPositionIndex.button.cancel')}}
                                                                        </button>
                                                                    </AppPermission>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="importPositionActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalConfirm ref="ModalConfirm" @confirm="confirmCancel"  :title="$t('message.ImportPositionIndex.title_confirm')" :content="this.contentCancel"/>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
    </AppPermission>
</template>
<script>
    import {$alert, clone, setArray, getSearchQuery, $get, $post, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import {explode} from "@/lib/utils";
    import store from "@/store";
    import emitter from "@/lib/emitter";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalConfirm from '@/components/ModalConfirm.vue';
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';       
    import http from '@/services/api';
    import exportMixin from "@/exportMixin";
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    // import printTransactionMixin from "@/printTransactionMixin";     
    import printOrdersMixin from '@/printOrdersMixin';
    export default {
        name: "ImportPositionIndex",
        mixins: [printOrdersMixin, exportMixin],
        components: {
            TableSortField, 
            Pagination, 
            InputQuickSearch, 
            SelectDateTime, 
            AppPermission,
            ModalConfirm,
            ModalSelectPrintTemplate,
            PreviewTemplate
        },
        data() {
            const query = getSearchQuery();
            const globalConfig = store.state.GlobalConfig;
            return {
                currentBranchId: store.state.CurrentBranch.id,
                filter: {
                    import_position_code: '',
                    original_documents_code: '',
                    original_documents_type: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                exEntries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth.user,
                paymentMethods: store.state.GlobalConfig.listPaymentMethod,
                details: [],
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    type: null,
                    optionType: []
                },
                arrayChecked: [],
                listStatus: [],
                loading: false,
                importPositionActive: null,
                defaultImport:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                fieldImportPositionDetail : [
                    {
                        name: this.$t('message.ImportPositionIndex.table.code'),
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: this.$t('message.ImportPositionIndex.table.name'),
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: this.$t('message.ImportPositionIndex.table.unit'),
                        field: 'unitName',
                        selected: true,
                    },
                    {
                        name: this.$t('message.ImportPositionIndex.table.attribute'),
                        field: 'attributeName',
                        selected: true,
                    },
                    {
                        name: this.$t('message.ImportPositionIndex.table.quantity'),
                        field: 'quantity',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: this.$t('message.ImportPositionIndex.table.import_position'),
                        field: 'position',
                        selected: true,
                    },
                ],
                listEditStatus: [
                    {'id': this.$constant.status_draft_order, 'name': this.$t('message.ImportPositionIndex.status_draft')},
                    {'id': this.$constant.status_confirm_order, 'name': this.$t('message.ImportPositionIndex.status_confirm')},
                ],
                users: globalConfig.users,
                saleChannelEdit: null,
                soldByEdit: null,
                statusEdit: null,
                contentCancel: null,
                isFilter: false,
                optionType: [
                    {'id': 1, 'name': this.$t('message.headers.purchase_products')},
                    {'id': 2, 'name': this.$t('message.headers.warehouse_transfer')},
                    {'id': 3, 'name': this.$t('message.headers.returns')},
                ]
            }
        },
        mounted() {
            document.title = this.$t('message.ImportPositionIndex.document_title');
            this.clearFilter();
        },
        methods: {
            async cancelTicket(entry) {
                this.contentCancel = this.$t('message.ImportPositionIndex.content_confirm', {code: entry.code});
                this.$refs.ModalConfirm.show();
            },
            async confirmCancel() {
                const res = await $post('/v1/ticket-import/cancel', {id: this.importPositionActive});
                if (res.result && res.result.status) {
                    $alert({code: 200, message: this.$t('message.ImportPositionIndex.alerts.cancel_success')});
                    this.load();
                    return;
                } else {
                    $alert({code: 500, message: this.$t('message.ImportPositionIndex.alerts.error_server')});
                    return;
                }
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/position-products/import',
                    query: {}
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [0];
                    }else if(key == 'type'){
                        this.filterQuery[key] = this.$constant.importPositionProduct;
                    }else if(key == 'optionType'){
                        this.filterQuery[key] = [];
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branchId = store.state.CurrentBranch.id;
                this.importPositionActive = null;
                this.isFilter = false;
                await this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                } else if(field == 'optionType'){
                    if(query['optionType'].includes(value)){
                        query['optionType'] = query['optionType'].filter(item => item !== value)
                    }else{
                        query['optionType'].push(value)
                    }
                }
                else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async sort(field, direction) {                
                this.sortData = {
                    field, direction
                };
                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;
                await this.$router.push({query: query});
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/ticket-import/get-data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});
                if (res.result.code !== 200 && res.status) {
                    $alert(res.status);
                    return;
                }                              
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }         
                // this.paginate = res.result.data.paginate;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                await this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            //------------------------------------------------------------      
            detailImportPositionProduct(id, entry){
                if (id === this.importPositionActive) {
                    this.importPositionActive = null;
                } else {
                    this.importPositionActive = id;
                }
                this.defaultImport.filter = this.defaultImport.all = entry.details;
                this.setPaginate();
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultImport.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultImport.filter.length;
            },
            onInputFilterDetail(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultImport.searchCode.length == 0 && this.defaultImport.searchName.length == 0){
                    this.defaultImport.filter = this.defaultImport.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultImport.all.length; index++) {
                            if (this.defaultImport.all[index] != null && removeVietnameseTones(this.defaultImport.all[index].productCode.toLowerCase()).indexOf(removeVietnameseTones(this.defaultImport.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultImport.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultImport.all.length; index++) {
                            if (this.defaultImport.all[index] != null && removeVietnameseTones(this.defaultImport.all[index].productName.toLowerCase()).indexOf(removeVietnameseTones(this.defaultImport.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultImport.all[index]);
                            }
                        }
                        break;
                }
                this.defaultImport.filter = arr;
                this.setPaginate();
            },
            async quickSave(entry){
                if(entry.note?.length > 255){
                    $alert({
                        code: 422,
                        message: this.$t('message.ImportPositionIndex.alerts.validate_note'),
                    });
                }else{
                    const res = await $post('/v1/ticket-import/quick-save', {
                        id: entry.id,
                        note: entry.note, 
                    });
                    if(res.result.status == true){
                        $alert({
                            code: 200,
                            message: this.$t('message.ImportPositionIndex.alerts.update_success', {code: entry.code}),
                        });
                        this.load();
                    }else{
                        $alert({
                            code: 403,
                            message: this.$t('message.ImportPositionIndex.alerts.update_error', {code: entry.code}),
                        });
                    }
                }
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            async doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async update(code){
                await this.$router.replace({
                    path: '/ticket-import',
                    query: {
                        type: 'Import',
                        importCode: code,
                    }
                });
            },
            async getTemplatePrint(entry) {  
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabTicketImport'});               
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){
                this.content = data.content;
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {
                    await this.print(data.dataPrint);          
                }           
            },
            async print(entry) { 
                entry.typePrint = this.$constant.TabTicketImport;
                let dataPrint = this.dataPrint(entry);  
                this.editorDataClone = await this.renderData(dataPrint);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            async startPrintMultiple(returns) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < returns.length; index++) {
                    let order = returns[index];
                    order.typePrint = this.$constant.TabTicketImport;
                    let dataPrint = await this.dataPrint(order);
                    let orderItem = await this.renderData(dataPrint);
                    if (index != returns.length -1 ) {
                        this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + orderItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },
            async exportRecord(id){  
                emitter.emit("onLoading");              
                const res = await http.download('/v1/ticket-import/export-record/' + id, {...this.fields, branchID: this.currentBranchId});                
                this.returnDataExport(res);
            },
            async exSelectOverview(){
                let id = this.arrayChecked;
                emitter.emit("onLoading");
                const res = await http.download('/v1/ticket-import/export-overview', {...this.fields, id:id, ...this.$route.query, ...this.filterQuery, branchID: this.currentBranchId});
                this.returnDataExport(res);
            },
            async exSelectDetails(){
                emitter.emit("onLoading");
                let id = this.arrayChecked;            
                const res = await http.download('/v1/ticket-import/export-detail', {...this.fields, id:id, ...this.$route.query, ...this.filterQuery, branchID: this.currentBranchId});
                this.returnDataExport(res);   
            },
            async exportOverview(){                
                emitter.emit("onLoading");                    
                const res = await http.download('/v1/ticket-import/export-overview', {...this.fields, ...this.$route.query, ...this.filterQuery, branchID: this.currentBranchId});                
                this.returnDataExport(res);             
            },

            async exportDetail(){  
                emitter.emit("onLoading");                       
                const res = await http.download('/v1/ticket-import/export-detail', {...this.fields, ...this.$route.query, ...this.filterQuery, branchID: this.currentBranchId});
                this.returnDataExport(res);                
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.noteStyle {
    width: 100% !important;
    height: 80px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
</style>