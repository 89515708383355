<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title py-2 px-4">
                        {{$t('message.OrderIndex.title')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent" v-if="this.invoice">
                        <div class="tab-pane fade show active">
                            <div class="row mx-4 my-0 pb-0 mt-1">
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.orderCode')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-bold" v-text="invoice.code"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.time')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="dt(invoice.purchaseDate)"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.headers.customers')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1 text-primary">
                                                <a :href="'/customers/index?code=' + invoice.customer?.code" target="_blank" class="text-decoration-none">{{ invoice.customer ? invoice.customer.name : null }}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.InvoiceIndex.price_list')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.price_list ? invoice.price_list.name : $t('message.PriceListsIndex.title')"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4 pl-0">
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.status')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.statusName"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.branch')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.branch ? invoice.branch.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.customerIndex.sellerName')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.sold_by ? invoice.sold_by.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row border-bottom-1 m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.common.created_by')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.user_created ? invoice.user_created.name : null"></div>
                                        </div>
                                    </div>
                                    <div class="row m-0">
                                        <div class="col-4 d-flex align-items-center pl-0">{{$t('message.SaleChannelIndex.title')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="cate_selected d-flex align-items-center px-2 my-1" v-text="invoice.sale_channel ? invoice.sale_channel.name : null "></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-4" id="noteRO">  
                                    <div class="row p-0 font-italic">
                                        <div class="col-12 text-gray">
                                            <span class="noteStyle text-gray font-italic">
                                                {{ invoice.description ? invoice.description : $t('message.InvoiceIndex.note')}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 mx-4 pb-2">
                                <div class="row detail-prd-row mt-3 m-0 w-100">
                                    <div class="col-12 p-0">
                                        <table class="w-100">
                                            <thead>
                                                <tr class="backgroup__detail table-header border-1">
                                                    <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                        {{$t('message.DetailOrdersCard.prodCode')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{$t('message.DetailOrdersCard.prodName')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-release-coupon p-2">
                                                        {{$t('message.DetailOrdersCard.prodQuantity')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{$t('message.DetailOrdersCard.prodPrice')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{$t('message.DetailOrdersCard.prodDiscount')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-auto p-2">
                                                        {{$t('message.ProductIndex.price')}}
                                                    </th>
                                                    <th scope="col" class="pl-4 cell-status-coupon p-2">
                                                        {{$t('message.DetailOrdersCard.totalPrice')}}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <template v-if="invoice.order_items?.length > 0">
                                                    <template v-for="(item, index) in invoice.order_items" :key="item.id">
                                                        <tr class="table-header border" v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10" :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                            <th scope="col" class="pl-4 cell-code-coupon p-2">
                                                                <a :href="'/products/index?code=' + item.product ? item.product.code: null" target="_blank" class="text-decoration-none">{{item.product ? item.product.code: null}}</a>
                                                            </th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="item.product ? item.product.name : null"></th>
                                                            <th scope="col" class="pl-4 cell-release-coupon p-2 font-weight-normal" v-text="n(item.quantity)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.discount)"></th>
                                                            <th scope="col" class="pl-4 cell-auto p-2 font-weight-normal" v-text="n(item.price - item.discount)"></th>
                                                            <th scope="col" class="pl-4 cell-status-coupon p-2 font-weight-normal" v-text="n(item.subTotal)"></th>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                        <div class="no-data border border-top-0" v-if="invoice.invoice_items?.length == 0">
                                            <span>{{$t('message.common.notFound')}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-0 pb-4 mt-0">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12 p-3 border-dotted-2 rounded">
                                            <p class="text-bold m-0">
                                                <i class="fa-solid fa-badge-percent"></i>{{$t('message.DetailOrdersCard.codeDiscount')}}
                                            </p>
                                            <span>
                                                {{$t('message.common.discountCouponValue', {code: invoice.coupon_detail?.code, value: n(invoice.valueCoupon), percent: (invoice.coupon_detail?.coupon_voucher ? n(invoice.coupon_detail?.coupon_voucher?.value) : null)})}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.totalQuantity')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(invoice.totalProduct)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.DetailOrdersCard.total')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(invoice.totalOrigin)"></div>
                                    </div>
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.OrderIndex.discount')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(invoice.discountPrice)"></div>
                                    </div>

                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.OrderIndex.surcharge')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="0"></div>
                                    </div>

                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.OrderIndex.total')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(invoice.total)"></div>
                                    </div>
                                   
                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.InvoiceIndex.total_payment')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="n(invoice.totalPayment)"></div>
                                    </div>

                                    <div class="row m-2 mr-0">
                                        <div class="col-6 flex-end">{{$t('message.OrderIndex.transaction_fee')}}:</div>
                                        <div class="col-6 p-0 flex-end" v-text="0"></div>
                                    </div>

                                    <div class="row flex-end mt-4" >
                                        <button type="button" class="btn btn-save mx-1" @click="openInvoice(invoice.code)">
                                            <i class="fa fa-share mr-2"></i>{{$t('message.DetailOrdersCard.btnOpen')}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pagination from "@/components/Pagination";
import {$post} from "@/lib/utils";
export default {
    name: "HistoryUseCouponInOrder",
    components: {Pagination},
    data () {
        return {
            invoice: {},
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },

        }
    },
    created() {
    },
    methods: {
        async show (id = null) {
            this.clearData();
            if (id) {
                const res = await $post('/v1/orders/show/' + id);
                if(res && res.result){
                    if(res.result.status && res.result.data != []){
                        this.invoice = res.result;
                        this.setPaginate();
                        window.jQuery(this.$refs.modal).modal('show');
                    }
                }
            }
        },
        hide () {
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setPaginate(){
            let arr = [];
            if (this.invoice.invoice_items){
                arr = this.invoice.order_items;
            }
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(arr.length / 10);
            this.paginateDetail.totalRecord = arr.length;
        },
        clearData(){
            this.invoiceData = {};
            this.paginateDetail = {
                currentPage: 1,
                lastPage: 1,
            };
        },
        openInvoice(code){
            let route = this.$router.resolve({
                path: '/orders/index',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        }
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
.cate_selected{
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
.border-dotted-2{
    border: 1px dashed #ccc;
}
</style>