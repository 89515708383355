<template>
    <div class="customer-tab tab-info">
        <div class="title">
            Lịch sử tích điểm: Trinh Đinh
        </div>
        <table class="table table-index table-striped">
            <thead>
            <tr>
                <th scope="col">{{$t('message.common.time')}}</th>
                <th scope="col">Thay đổi</th>
                <th scope="col">Đã chi</th>
                <th scope="col">Tổng điểm sau giao dịch</th>
                <th scope="col">Hạng thẻ</th>
                <th scope="col">{{$t('message.InvoiceIndex.note')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="i in 3" :key="'row-' + i">
                <td>12/10/2021</td>
                <td>+100</td>
                <td>0</td>
                <td>120</td>
                <td>Gold</td>
                <td v-text="(i+1)%2 === 0 ? 'Mua hàng' : 'Trả hàng'"></td>
            </tr>
            </tbody>
        </table>

    </div>
</template>

<script>
export default {
name: "TabPointHistory"
}
</script>

<style scoped>

</style>