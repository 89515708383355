<template>
    <div v-if="entry" ref="modalCreateDelivery" class="modal mx-0" tabindex="-1" role="dialog" id="modalConfirm" :class="this.disabledJobTitle && this.disabledJobTitle === true ? 'modal-third' : '' " data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 1400px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                        <h5 class="modal-title py-2">{{ $t('message.ModalcreateDelivery.titleCreate') }}</h5>
                        <button type="button" class="close pt-3" @click="hide()">
                            <span aria-hidden="true">&times;</span>
                        </button>
                </div>
                <div class="modal-body d-flex">
                    <div class="col-md-6 m-0 p-0">
                        <div class="col-12 mt-2">
                            <div class="row p-0 m-0 d-flex align-items-center">
                                <div class="col-lg-3 form-group px-0">
                                    <label>{{$t('message.common.collection')}}</label>
                                </div>
                                <div class="col-lg-4">
                                    <InputSwitchButton v-model="entry.usingPriceCod"/>
                                </div>
                                <div class="col-lg-5 p-0 text-right" v-if="entry.usingPriceCod">
                                    <div class="form-group" style="padding-right: .75rem">
                                        <span >{{ n(entry.amountCod) }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4 transport-address-to">
                                    <label>{{$t('message.customerIndex.receiverName')}}</label>
                                </div>
                                <div class="col-lg-8 p-0">
                                    <div class="form-group mb-0 position-relative d-flex">
                                        <input type="text" v-model="entry.to_name" :placeholder="$t('message.ModalCreateUpdateDelivery.name')" class="form-control pl-0 input-custom" autocomplete="off">
                                        <i class="far fa-address-book" v-if="this.entry.to_name" @click="showInformationCustomer"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4 transport-address-to">
                                    <label>{{$t('message.customerIndex.phone')}}</label>
                                </div>
                                <div class="col-lg-8 p-0">
                                    <div class="form-group mb-0">
                                        <input type="text" v-model="entry.to_phone" :placeholder="$t('message.supplierIndex.phone')" class="form-control pl-0 input-custom" autocomplete="off"
                                        :readonly="true">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4 transport-address-to">
                                    <label>{{$t('message.customerIndex.receiverAddress')}}</label>
                                </div>
                                <div class="col-lg-8 p-0">
                                    <div class="form-group mb-0">
                                        <!-- <input type="text" :placeholder="$t('message.ModalCreateUpdatePickup.placeholder_address')" class="form-control pl-0 input-custom" autocomplete="off"> -->
                                        <SearchLocation @select="onSelectLocation" :addressValue="entry.to_address"
                                        :readonly="true"></SearchLocation>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                                <div class="row p-0 m-0">
                                    <div class="col-lg-4 transport-address-to">
                                        <label>{{$t('message.supplierIndex.modal.supplierProvince')}}</label>
                                    </div>
                                    <div class="col-lg-8 p-0">
                                        <div class="form-group mb-0">
                                            <SelectSearch :defaultOption="provinces" :modelValue="entry.customerProvinceId" @update:modelValue="changeProvince"
                                            :disabled="true"></SelectSearch>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-12 mt-3">
                                <div class="row p-0 m-0">
                                    <div class="col-lg-4 transport-address-to">
                                        <label>{{$t('message.supplierIndex.modal.supplierDistrict')}}</label>
                                    </div>
                                    <div class="col-lg-8 p-0">
                                        <div class="form-group mb-0">
                                            <SelectSearch :defaultOption="districts" :modelValue="entry.customerDistrictId" @update:modelValue="changeDistrict"
                                            :disabled="true"></SelectSearch>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4 transport-address-to">
                                    <label>{{$t('message.supplierIndex.modal.supplierWard')}}</label>
                                </div>
                                <div class="col-lg-8 p-0">
                                    <div class="form-group mb-0">
                                        <SelectSearch :defaultOption="wards" :modelValue="entry.customerWardId"  @update:modelValue="changeWard"
                                        :disabled="true"></SelectSearch>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4 transport-address-to">
                                    <label>{{$t('message.InvoiceIndex.tabs.delivery.address_receive')}}</label>
                                </div>
                                <div class="col-lg-8 p-0">
                                    <div class="form-group mb-0">
                                        <SelectPickupAddress @update:modelValue="getFromAddress" :defaultOption="this.pickupAddress" @reloadData="getDataPickupAddress" :defaultValue="entry.from_address_id"></SelectPickupAddress>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                                <div class="row p-0 m-0">
                                    <div class="col-lg-4 transport-address-to">
                                        <label>{{$t('message.InvoiceIndex.tabs.delivery.weight')}}</label>
                                    </div>
                                    <div class="col-lg-8 d-flex p-0 align-items-end">
                                        <div class="form-group mb-0">
                                            <InputNumberFormat v-model="entry.weight" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                        <div class="form-group mb-0">
                                            <SelectSearch :defaultOption="$constant.weightUnits" :readonly="true" :label="'weightUnit'" :modelValue="entry.weightUnit" @update:modelValue="selectWeightUnits"></SelectSearch>
                                        </div>
                                    </div>
                                </div>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="row p-0 m-0">
                                <div class="col-lg-4">
                                    <label>{{$t('message.InvoiceIndex.tabs.delivery.size')}}</label>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.long" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.width" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-2 pr-0">
                                    <div class="form-group mb-0">
                                        <InputNumberFormat v-model="entry.hight" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                    </div>
                                </div>
                                <div class="col-lg-1 d-flex align-items-center">{{$t('message.common.cm')}}</div>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <InputTextArea placeholder="Ghi chú cho tài xế" v-model.trim="entry.shippingNote" class="input__textArea mb-0"/>
                        </div>
                    </div>
                    <div class="col-md-6 m-0 p-0">
                        <div class="row">
                            <div class="col-6 d-flex align-items-center mt-2 pr-4">
                                <b>{{$t('message.TransporterIndex.title')}}</b>
                            </div>
                            <div class="col-6 d-flex align-items-center mt-2">
                                <div class="d-flex">
                                    <TransportersSearch class="form-control" @selectValue="selectTransporter" @createNewValue="createTransporter" @clearValue="clearTransporter" :readonly="false"></TransportersSearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 d-flex cursor-pointer" v-if="isTransporterIntegration">
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_default ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_default)">
                                    <span class="p-2">{{$t('message.common.normal')}}</span>
                                    <i class="fa fa-truck d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_fast ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_fast)">
                                    <span class="p-2">{{$t('message.common.fast')}}</span>
                                    <i class="fa fa-plane d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                            <span class="shipping-mode">
                                <a class="d-flex align-items-center justify-content-center" :class="[(entry.shippingMode == this.$constant.shipping_mode_in_day ? 'active' : '')]" @click="toggleShippingMode(this.$constant.shipping_mode_in_day)">
                                    <span class="p-2">{{$t('message.common.duringTheDay')}}</span>
                                    <i class="fa fa-clock d-flex align-items-center justify-content-center pr-2"></i>
                                </a>
                            </span>
                        </div>
                        <div class="col-12 mt-2 cursor-pointer border px-0" v-if="isTransporterIntegration">
                            <div class="p-2 bg-warning w-100" v-if="!this.entry.from_address">
                                <i class="fas fa-info-circle pr-2"></i>
                                <span>{{$t('message.orderFromTab.message_create_address')}}</span>
                            </div>
                            <div class="transpoter-options">
                                <template v-if="entry.shippingMode == this.$constant.shipping_mode_default">
                                    <div v-if="!this.isLoginGHN">
                                        <template v-for="(item, index) in this.deliveries" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item._id ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../public/img/GHN.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.name"></div>
                                                    <div class="transpoter-description" v-text="item.infoService.description"></div>
                                                </div>
                                                <div class="col-2 text-primary text-right" v-text="n(item.total_price)"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../public/img/GHN.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">{{$t('message.common.linkToGHN')}}</span>
                                                    <span class="text-primary text-bold" @click="connectTransporter($constant.typeGHN)">{{$t('message.common.loginNow')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>

                                <template v-else-if="entry.shippingMode == this.$constant.shipping_mode_in_day">
                                    <template v-if="!this.isLoginAhamove">
                                        <template v-for="(item, index) in this.deliveries" :key="index">
                                            <div v-if="item.total_price && item.total_price > 0" class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item._id ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../public/img/ahamove.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.name"></div>
                                                    <div class="transpoter-description" v-text="item.infoService.description"></div>
                                                </div>
                                                <div class="col-2 text-primary text-right" v-text="n(item.total_price)"></div>
                                            </div>
                                        </template>
                                    </template>
                                    <template v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../public/img/ahamove.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">{{$t('message.common.linkToAhaMove')}}</span>
                                                    <span class="text-primary text-bold" @click="connectTransporter">{{$t('message.common.loginNow')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </template>

                                <template v-if="entry.shippingMode == this.$constant.shipping_mode_default">
                                    <div v-if="!this.isLoginViettelPost">
                                        <!-- <template v-for="(item, index) in this.deliveriesViettelPost" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item.infoService.code ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../public/img/viettelpost.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.code"></div>
                                                    <div class="transpoter-description">{{ item.infoService.name}}</div>
                                                </div>
                                                <div class="col-2 text-primary text-right p-0" v-text="n(item.infoService.total_price)"></div>
                                            </div>
                                        </template> -->

                                        <template v-for="(item, index) in this.deliveriesViettelPost" :key="index">
                                            <div class="row px-0 py-2 m-0 border  bg-light" @click="chooseSerivce(item)">
                                                <div class="col-1 p-0">
                                                    <label class="container-radio ml-2 w-100">
                                                        <input type="radio" name="transporter" :checked="this.entry.serviceId == item.infoService.code ? true : false">
                                                        <span class="checkmark"></span>
                                                    </label>
                                                </div>
                                                <div class="col-3">
                                                    <img src="../../public/img/viettelpost.png" class="w-100 bg-transparent">
                                                </div>
                                                <div class="col-6">
                                                    <div class="transpoter-name" v-text="item.infoService.code"></div>
                                                    <div class="transpoter-description" v-text="item.infoService.name"></div>
                                                </div>
                                                <div class="col-2 text-primary text-right" v-text="n(item.infoService.total_price)"></div>
                                            </div>
                                        </template>
                                    </div>
                                    <div v-else>
                                        <div class="row px-0 py-2 m-0 border bg-light">
                                            <div class="col-3">
                                                <img src="../../public/img/viettelpost.png" class="w-100">
                                            </div>
                                            <div class="col-9">
                                                <div class="transpoter-name">
                                                    <span class="text-danger text-bold">{{$t('message.common.linkToViettelPost')}}</span>
                                                    <span class="text-primary text-bold" @click="connectTransporter($constant.typeVTP)">{{$t('message.common.loginNow')}}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </template>
                            </div>
                        </div>
                        <div class="col-12 mt-2 py-2 cursor-pointer bg-light" v-if="isTransporterIntegration">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <span class="btn-icon-gift pr-2"><i class="fa fa-gift"></i></span>
                                    <label class="text-bold">{{$t('message.common.deliveryPromotion')}}</label>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <span class="px-2 py-1 m-2 border-active">4 mã</span><i class="fa fa-chevron-right"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 pt-2 cursor-pointer d-flex justify-content-between" v-if="isTransporterIntegration">
                            <div>
                                <label class="container-radio ml-0">
                                    <b>{{$t('message.common.theSenderPaysTheFee')}}</b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.sendPayment">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div v-if="this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter && this.entry.transporterId != this.$constant.vtp_transposter">
                                <label class="container-radio ml-0">
                                    <b>{{$t('message.common.paymentViaApp')}}</b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.appPayment">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div>
                                <label class="container-radio ml-0">
                                    <b>{{$t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value')}}</b>
                                    <input type="radio" name="fee" v-model="entry.type_payment" :value="$constant.receivePayment">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 pt-2 cursor-pointer" v-if="isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter && this.entry.transporterId != this.$constant.vtp_transposter">
                            <div>
                                <label class="container-checkbox">
                                    <b>{{$t('message.common.heavyThings')}} <span v-if="entry.levelName">{{ '( '+entry.levelName+ ' )' }}</span></b>
                                    <input type="checkbox" :readonly="true" :disabled="true" :checked="entry.is_bulky ? true : false">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="isTransporterIntegration">
                            <label class="container-checkbox">
                                <b  v-if="this.entry.transporterId != this.$constant.vtp_transposter">
                                    {{$t('message.common.declarePrice')}}
                                    (
                                        {{$t('message.common.maximumDeclaredValue')}}
                                        30
                                        {{$t('message.common.million')}}
                                    )
                                </b>
                                <b v-else>
                                    {{$t('message.common.declarePrice')}}
                                </b>
                                <input type="checkbox" v-model="entry.is_insurance" :checked="entry.is_insurance ? true : false">
                                <span class="checkmark-checkbox"></span>
                            </label>
                            <InputNumberFormat :max="30000000" v-if="entry.is_insurance && this.entry.transporterId != this.$constant.vtp_transposter" v-model="entry.item_value" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                            <InputNumberFormat  v-if="entry.is_insurance && this.entry.transporterId == this.$constant.vtp_transposter" v-model="entry.item_value" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter && this.entry.transporterId != this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>{{$t('message.common.sendSMS')}}</b>
                                <input type="checkbox" v-model="entry.sendSms" :checked="entry.sendSms ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>
                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="isTransporterIntegration && this.entry.transporterId != this.$constant.giao_hanh_nhanh_transposter && this.entry.transporterId != this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>{{$t('message.common.fragile')}}</b>
                                <input type="checkbox" v-model="entry.is_fragile" :checked="entry.is_fragile ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>

                        <div class="col-12 cursor-pointer d-flex justify-content-between" v-if="isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                            <label class="container-checkbox">
                                <b>{{$t('message.common.atThePostOffice')}}</b>
                                <input type="checkbox" v-model="entry.isSend" :checked="entry.isSend ? true : false">
                                <span class="checkmark-checkbox" ></span>
                            </label>
                        </div>

                        <div class="col-6 py-2" v-if="isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                            <SelectSearch :modelValue="entry.serviceVTP" 
                            :defaultOption="optionServiceVTP" @update:modelValue="selectServiceVTP" 
                            :label="'serviceVTP'" :readonly="true"></SelectSearch>
                        </div>
                        <div class="col-6 py-2" v-if="isTransporterIntegration && this.entry.transporterId == this.$constant.vtp_transposter">
                        </div>

                        <div class="col-12 py-2 cursor-pointer" v-if="isTransporterIntegration && entry.usingPriceCod">
                            <div class="d-flex justify-content-between align-items-center">
                                <div class="">
                                    <label class="text-bold">{{$t('message.InvoiceIndex.tabs.delivery.total_receive')}}</label>
                                </div>
                                <div class="" style="padding-right: .75rem">
                                    <div v-text="n(totalAmountReceiver)"></div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 mt-2 py-2 cursor-pointer bg-light rounded" v-if="!isTransporterIntegration">
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.service_type')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectSearch :defaultOption="listServiceType" :borderActive="true" :modelValue="entry.serviceTypeId" @update:modelValue="changeServiceType" :readonly="true" :label="'serviceTypeId'"></SelectSearch>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.shipping_fee')}}<i class="fa fa-info-circle ml-1"></i></div>
                                <div class="col-lg-7 py-2 px-0">
                                    <InputNumberFormat v-model="entry.shipping_price" :borderActive="true" :bgTransparent="true" :cardTransaction="true" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_code')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <div class="form-group mb-0">
                                        <input type="text" v-model="entry.deliveryCode" :placeholder="$t('message.orderFromTab.placeholder.delivery_code')" class="form-control pl-0 input-custom bg-transparent border-bottom-focus-active" autocomplete="off">
                                    </div>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_time')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectDateTimePicker :modelValue="entry.deliveryTime" @update:modelValue="changeDeliveryTime" :borderActive="true" :backgroundColor="'bg-light'"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0">
                                <div class="col-lg-5 py-2 px-0">{{$t('message.orderFromTab.delivery_status')}}</div>
                                <div class="col-lg-7 py-2 px-0">
                                    <SelectSearch :defaultOption="this.$constant.listDeliveryStatus" :borderActive="true" :modelValue="entry.deliveryStatus" @update:modelValue="changeDeliveryStatus" :readonly="true" :label="'deliveryStatus'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-12 mt-2 py-2 text-right customer-btn-create">
                            <button type="button" class="btn btn-save" @click="createDelivery">Lưu</button>
                        </div> -->
                    </div>
                </div>
                <div class="modal-footer d-flex flex-end">
                    <button type="button" class="btn btn-save" @click="createDelivery">
                        {{$t('message.button.save')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ModalAddTransporters ref="ModalAddTransporters"/>   
    <ModalInformationCustomer ref="ModalInformationCustomer" @changeData="changeDataAddress"/>
</template>
<style scoped>
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .customer-btn-create {
        display: block;
        position: absolute;
        bottom: 0px;
    }
</style>
<script>
import InputSwitchButton from "@/components/forms/InputSwitchButtonCustom";
import SearchLocation from '@/components/SearchLocation';
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import InputTextArea from "@/components/forms/InputTextArea";
import TransportersSearch from '@/components/TransportersSearch';
import SelectPickupAddress from "@/components/SelectPickupAddress";
import {$alert, $get, $post, debounce, clone} from "@/lib/utils";
import ModalAddTransporters from '@/views/transporters/tabs/ModalAddTransporters.vue';
import store from "@/store";
import ModalInformationCustomer from '@/views_sale/sales/ModalInformationCustomer.vue';
import SelectDateTimePicker from "@/components/SelectDateTimePicker";
import currencyMixin from "@/currencyMixin";

let app;

export default {
    name: "ModalCreateDelivery",
    components: {
        InputSwitchButton,
        SearchLocation,
        SelectSearch,
        InputNumberFormat,
        TransportersSearch,
        InputTextArea,
        SelectPickupAddress,
        ModalAddTransporters,
        ModalInformationCustomer,
        SelectDateTimePicker,
    },
    mixins: [currencyMixin],
    data(){
        app = this;
        return {
            isLoginAhamove: false,
            isLoginGHN: false,
            entry: {
                to_address: null,
                item_value: 0,
                from_address_id: null,
            },
            isTransporterIntegration: true,
            deliveries: [],
            deliveriesViettelPost: [],
            provinces: [],
            districts: [],
            wards: [],
            pickupAddress: [],
            listServiceType: store.state.GlobalConfig.listServiceType,
            optionServiceVTP: [
                {id: 'GDK', name: this.$t('message.common.showProduct')},
                {id: '1', name: this.$t('message.common.notShowProduct')},
            ]
        }
    },
    props: ['message', 'title', 'content','disabledJobTitle'],
    created() {
        this.getDataPickupAddress();
        this.countAhamove();
    },
    methods: {
        changeDeliveryTime(date){
            this.entry.deliveryTime = date;
        },
        changeDataAddress(value){
            if(this.entry.saleMode == this.$constant.sale_mode_trans){
                this.entry.to_name = value.name ?? null;
                this.entry.to_phone = value.phone ?? null;
                this.entry.customerAddressId = value.id ?? null;
                this.entry.customerProvinceId = value.provinceId ? value.provinceId : null;
                this.entry.customerDistrictId = value.districtId ? value.districtId : null;
                this.entry.customerWardId = value.wardId ? value.wardId : null;
                this.entry.to_address = this.formatAddress(value);
                this.entry.customerAddressMain = value.addressValue ?? null;
                this.loadDataLocation();
            } 
        },
        formatAddress(value){
            let address = [value.addressValue];
            address.push(value && value.wards ? value.wards.name : null);
            address.push(value && value.districts ? value.districts.name : null);
            address.push(value && value.provinces ? value.provinces.name : null);
            return address.join(' ,');
        },
        selectCustomer(v) {   
            this.entry.customerId = v.id;
            this.entry.customerName = v.name;
            this.disablePromotionInvoiceIds = [];
            this.disablePromotionProductIds = [];
            if(this.entry.saleMode == this.$constant.sale_mode_trans){
                this.entry.to_name = v.name ?? null;
                this.entry.to_phone = v.contactNumber ? v.contactNumber : (v.contactNumber1 ? v.contactNumber1 : null);
                this.entry.customerCode = v.customer_code ?? null
                this.entry.customerAddressId = v.address2 && v.address2.provinceId ? v.address2.id : null;
                this.entry.customerProvinceId = v.address2 && v.address2.provinceId ? v.address2.provinceId : null;
                this.entry.customerDistrictId = v.address2 && v.address2.districtId ? v.address2.districtId : null;
                this.entry.customerWardId = v.address2 && v.address2.wardId ? v.address2.wardId : null;
                this.informationCustomer = v.address_has_many ? v.address_has_many : [];
                this.entry.to_address = v.address2 ? this.formatAddress(v.address2) : null;
                this.loadDataLocation();
            }
        },
        showInformationCustomer(){
            this.$refs.ModalInformationCustomer.show(this.informationCustomer, this.entry.customerId, this.entry.customerCode);
        },
        async onSelectLocation(location) {
           const result = await $post('/v1/pickup-address/search-location', {
                    province: location.compound.province,
                    district: location.compound.district,
                    commune: location.compound.commune
            });
            this.entry.customerProvinceId = result.result.provinceId
            this.entry.customerDistrictId = result.result.districtId;
            this.entry.customerWardId = result.result.wardId;
            this.entry.to_address = location.description;
            this.entry.customerAddressMain = location.structured_formatting.main_text;
            this.entry.toDistrictName = result.result.toDistrictName,
            this.entry.toWardName = result.result.toWardName,
            this.entry.toProvinceName = result.result.toProvinceName,
            this.loadDataLocation();     
        },
        chooseSerivce(item){
            this.entry.serviceId = item._id ?? null;
            this.entry.transporterId = item.transporterId ?? null;
            this.entry.requests = item.requests ?? null;
            this.entry.currentServiceId = item.infoService ? item.infoService.id : null;
            this.totalAmountReceiver = this.entry.amountCod;
            this.entry.total_price = item.total_price ?? null;

            if (this.entry.transporterId == this.$constant.vtp_transposter) {
                this.entry.kpi_ht = item.kpi_ht;
                this.entry.is_insurance = false;
                this.entry.isSend = false;
                this.entry.serviceVTP = "1";
            }
        },
        async createDelivery(){
            if(this.entry.saleMode == this.$constant.sale_mode_trans){
                this.entry.hasShipping = 1;
                this.entry.paymentMethod = this.entry.type_payment ?? null;
                this.entry.serviceTypeId = this.isTransporterIntegration ? this.entry.shippingMode : this.entry.serviceTypeId;
                this.entry.totalAmountReceiver = this.isTransporterIntegration ? this.totalAmountReceiver : 0;
                this.entry.deliveryStatus = this.$constant.delivery_status_default;
            }
            let form = clone(this.entry);
            if (form.transporterId == this.$constant.giao_hanh_nhanh_transposter) {
                let weight = 0;
                if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                    weight = form.weight;
                } else {
                    weight = form.weight * 1000;
                }
                form.weight = weight;
                if (+form.weight > 30000) {
                    $alert({code: 500, message: this.$t('message.common.notExceed30Kg')});
                    return;
                }
                if (form.usingPriceCod && +form.amountCod > 10000000) {
                    $alert({code: 500, message: this.$t('message.common.notExceed10Million')});
                    return;
                }
                if (+form.item_value > 5000000) {
                    $alert({code: 500, message: this.$t('message.common.notExceed5Million')});
                    return;
                }
                if (+form.hight > 150 || +form.long > 150 || +form.width > 150) {
                    $alert({code: 500, message: this.$t('message.common.notExceed150Cm')});
                    return;
                }
            }
            if (form.transporterId == this.$constant.vtp_transposter) {
                let weight = 0;
                if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                    weight = form.weight;
                } else {
                    weight = form.weight * 1000;
                }
                form.weight = weight;
            }
            const res = await $post('/v1/orders/recreate-delivery', {...form});
            if (res.code === 422) {
                const errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                return;
            } else if (res.result) {
                $alert({code: 200, message: this.$t('message.common.addDeliverySuccess')});
                this.$emit('updated');
            } else {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
            }
            this.hide();
        },
        async countAhamove(){
            const result = await $get('/v1/ahamove/count-account-trans');
            if (result.result === false) {
                this.isLoginAhamove = false;
            }else {
                this.isLoginAhamove = true;
            }
        },
        changeServiceType(data){
            this.entry.serviceTypeId = data && data.model ? data.model : null;
        },
        toggleShippingMode(mode){
            this.entry.shippingMode = mode;
            this.entry.serviceTypeId = mode;
        },
        show(entry, informationCustomer) {  
            this.entry = entry;
            this.informationCustomer = informationCustomer;
            this.loadDataLocation();    
            // this.onGetLevelBulkyUseAhamove();     
            // this.onEstimatedFeesGiaoHangNhanh();
            this.getETGHN();
            this.getETAhamove();
            this.getETVTP();
            if(!this.entry.serviceVTP){
                this.entry.serviceVTP = '1';
            }
            window.jQuery(this.$refs.modalCreateDelivery).modal('show');
            this.$forceUpdate();
        },
        skip() {
            this.$emit('skip', false);
            window.jQuery(this.$refs.modalCreateDelivery).modal('hide');
        },
        confirm(){
            this.$emit('confirm', true);
            window.jQuery(this.$refs.modalCreateDelivery).modal('hide');
        },
        hide(){
            window.jQuery(this.$refs.modalCreateDelivery).modal('hide');
        },
        getFromAddress (value) {
            this.entry.from_address = value.currentOption ? value.currentOption.address : null;
            this.entry.from_address_id = value.model ?? null;
            this.entry.from_phone = value.currentOption ? value.currentOption.phone : null;
            this.entry.branchAddressId = value.currentOption ? value.currentOption.id : null;
            this.entry.formDistrictName = value.currentOption ? value.currentOption.formDistrictName : null;
        },
        async getDataPickupAddress()
        {
            const result = await $post('/v1/pickup-address/data', {
                branchId: store.state.CurrentBranch.id
            });
            this.pickupAddress = result.result;
        },

        async changeDistrict(value = null) {
            this.entry.customerDistrictId = value && value.model ? value.model : null;
            this.entry.customerDistrictName = value && value.optionsName ? value.optionsName : null;
            this.entry.customerWardId = null;
            const res = await $get('/v1/locations/wards', {districtId: this.entry.customerDistrictId});
            this.wards = res.data;
        },
        changeWard(value = null) {
            this.entry.customerWardId = value && value.model ? value.model : null;
            this.entry.customerWardName = value && value.optionsName ? value.optionsName : null;
        },
        async changeProvince(value = null) {
            this.entry.customerProvinceId = value && value.model ? value.model : null;
            this.entry.customerProvinceName = value && value.optionsName ? value.optionsName : null;
            this.entry.customerDistrictId = null;
            this.entry.customerWardId = null;
            const res = await $get('/v1/locations/districts', {provinceId: this.entry.customerProvinceId});
            this.districts = res.data;
            this.changeDistrict();
        },
        async loadDataLocation(){
            const res = await $get('/v1/locations/provinces', {
                provinceId: this.entry.customerProvinceId,
                districtId: this.entry.customerDistrictId
            });
            this.provinces = res.data.provinces;
            this.districts = res.data.districts;
            this.wards = res.data.wards;
            this.$forceUpdate();
        },
        onEstimatedFeesAhamove: debounce(async () => {
            app.getETAhamove();
        }),
        onEstimatedFeesGiaoHangNhanh: debounce(async () => {
            app.getETGHN();
        }),
        onEstimatedFeesViettelPost: debounce (async () => {
                app.getETVTP();
            }),
        async getETAhamove() {
            if (this.entry.shippingMode != this.$constant.shipping_mode_in_day) {
                return;
            }
            if (!this.entry.to_address || !this.entry.customerWardId || !this.entry.customerProvinceId || !this.entry.customerDistrictId) {
                return;
            }
            const result = await $post('/v1/ahamove/estimate-multiple-service-fee', {...this.entry});
            if (this.entry.shippingMode != this.$constant.shipping_mode_in_day || this.isLoginAhamove) {
                return;
            }
            this.deliveries = result.result;
            this.$forceUpdate();
        },
        async getETGHN() {
            if (this.entry.shippingMode != this.$constant.shipping_mode_default || this.isLoginGHN) {
                return;
            }
            if (!this.entry.width || !this.entry.hight || !this.entry.long || !this.entry.weightUnit || !this.entry.weight) {
                return;
            }
            let dataEt = await this.formatDataShipForGiaoHangNhanh();
            const result = await $post('/v1/giao-hang-nhanh/estimate-order-fee', {...dataEt});
            if (this.entry.shippingMode != this.$constant.shipping_mode_default || this.isLoginGHN) {
                return;
            }
            this.deliveries = [result.result];
            this.$forceUpdate();
        },
        async getETVTP () {
            if (this.entry.shippingMode != this.$constant.shipping_mode_default || this.isLoginViettelPost) {
                    this.deliveriesViettelPost = [];
                    return;
                }
                if (!this.entry.width || !this.entry.hight || !this.entry.long || !this.entry.weightUnit || !this.entry.weight) {
                    this.deliveriesViettelPost = [];
                    return;
                }
               
                let dataEtVTP = await this.formatDataShipForViettelPost();
                const result = await $post('/v1/viettel-post/estimate-order-fee', {...dataEtVTP});
                this.deliveriesViettelPost = result.result;
        },
        async formatDataShipForViettelPost () {
            let weight = 0;
            if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                weight = this.entry.weight;
            } else {
                weight = this.entry.weight * 1000;
            }
            const dataEt = {
                'formDistrictName': this.entry.formDistrictName ?? '',
                'toDistrictName': this.entry.toDistrictName ?? '',
                'toWardName': this.entry.toWardName ?? '',
                'width': this.entry.width,
                'height': this.entry.hight ?? '',
                'weight': weight,
                'length': this.entry.long ?? '',
                'amount': this.entry.totalPrice ?? '',
                'items': this.entry.items,
                'amountCod': this.entry.usingPriceCod ? this.entry.amountCod : 0,
                'itemValue': this.entry.is_insurance ? this.entry.item_value : 0,
                'isSend': this.entry.isSend,
                'is_declare_price': this.entry.is_insurance,
                'products': this.entry.items,
                'serviceVTP': this.entry.serviceVTP,
            }
            return dataEt;
        },
        async formatDataShipForGiaoHangNhanh() {
            let weight = 0;
            if (this.$constant.weightUnitsIdGram == this.entry.weightUnit) {
                weight = this.entry.weight;
            } else {
                weight = this.entry.weight * 1000;
            }
            const dataEt = {
                'formDistrictName': this.entry.formDistrictName ?? '',
                'toDistrictName': this.entry.toDistrictName ?? '',
                'toWardName': this.entry.toWardName ?? '',
                'width': this.entry.width,
                'height': this.entry.hight ?? '',
                'weight': weight,
                'length': this.entry.long ?? '',
                'amount': this.entry.totalPrice ?? '',
                'items': this.entry.items,
                'amountCod': this.entry.usingPriceCod ? this.entry.amountCod : 0,
                'itemValue': this.entry.item_value ? +this.entry.item_value : 0,
            }
            return dataEt;
        },
        onGetLevelBulkyUseAhamove: debounce(async () => {
            app.getLevelBulky();
        }),
        async getLevelBulky() {
            if (!this.entry.width || !this.entry.hight || !this.entry.long) {
                this.clearLevel();
            }
            const res = await $get('/v1/ahamove/convert-level-bulky', {
                width: this.entry.width,
                hight: this.entry.hight,
                long: this.entry.long,
                weightUnit: this.entry.weightUnit,
            });
            if (res && res['result'] && res['result']['is_bulky']) {
                this.entry.is_bulky = res['result']['is_bulky'];
                this.entry.level = res['result']['level'];
                this.entry.levelName = res['result']['name'];
            } else {
                this.clearLevel();
            }              
        }, 
        selectServiceVTP(o){
            if (!o) {
                return;
            }
            this.entry.serviceVTP = o.model;   
        },
        clearLevel() {
            this.entry.is_bulky = false;
            this.entry.level = this.$constant.level_1;
            this.entry.levelName = null;
        },
        addAmountShipping(amount) {
            this.entry.total_price = amount;
        },
        clearInfosShipping(){
            this.entry.serviceId = "";
            this.entry.requests = [];
            this.entry.item_value = 0;
            this.entry.total_price = 0;
            this.entry.transporterId = null;
        },
        createTransporter(){
            this.$refs.ModalAddTransporters.show();
        },
        selectTransporter(data){
            this.entry.transporterId = data && data.id ? data.id : null;
            // this.entry.isTransporterIntegration = data ? data.isIntegration : true;
            this.isTransporterIntegration = data ? data.isIntegration : true;
            if(!this.isTransporterIntegration){
                this.entry.serviceTypeId = this.$constant.service_type_default;
                this.entry.deliveryStatus = this.$constant.delivery_status_default;
            }
        },
        selectWeightUnits(value) {
            this.entry.weightUnit = value.model;
        }
    },
    computed: {
        propertyADeliveries() {
            return `${this.entry.from_address}|${this.entry.to_address}|${this.entry.is_bulky}|${this.entry.level}|${this.entry.is_insurance}|
                ${this.entry.type_payment}|${this.entry.item_value}|${this.entry.sendSms}|${this.entry.is_fragile}|${this.entry.usingPriceCod}|${this.entry.amountCod}`;
        },
        totalAmountReceiver() {
            if (this.entry.type_payment == this.$constant.receivePayment) {
                return +this.entry.amountCod + (this.entry.total_price ?? 0);
            } 
            return this.entry.amountCod; 
        }
    },
    watch: {
        'entry.shippingMode'(newValue) {
            this.deliveries = [];
            this.getETAhamove();
            this.getETGHN();
            this.getETVTP();
        },
        'entry.width'() {
            if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                if (this.entry.width && this.entry.hight && this.entry.long) {
                    this.onGetLevelBulkyUseAhamove();
                } else {
                    this.clearLevel();
                }
            } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.hight'() {
            if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                if (this.entry.width && this.entry.hight && this.entry.long) {
                    this.onGetLevelBulkyUseAhamove();
                } else {
                    this.clearLevel();
                }
            } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.long'() {
            if (this.entry.shippingMode == this.$constant.shipping_mode_in_day) {
                if (this.entry.width && this.entry.hight && this.entry.long) {
                    this.onGetLevelBulkyUseAhamove();
                } else {
                    this.clearLevel();
                }
            } else if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.weightUnit'($value) {
            if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.weight'($value) {
            if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesGiaoHangNhanh();
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.serviceVTP' () {
            if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                    this.onEstimatedFeesViettelPost();
                }
            }
        },
        'entry.isSend' () {
                if (this.entry.shippingMode == this.$constant.shipping_mode_default) {
                    if (this.entry.width && this.entry.hight && this.entry.long && this.entry.weightUnit) {
                        this.onEstimatedFeesViettelPost();
                    }
                }
            },
        propertyADeliveries() {
            if (this.entry.from_address && this.entry.to_address) {
                this.onEstimatedFeesAhamove();
                this.onEstimatedFeesGiaoHangNhanh();
                this.onEstimatedFeesViettelPost();
            }
        },
        deliveries: {
            handler(newData){
                let item = newData.find(item => item._id == this.entry.serviceId);
                if (item) {
                    this.addAmountShipping(item.total_price);
                    this.entry.requests = item.requests;
                } else if (!item && this.entry.serviceId && this.entry.transporterId != this.$constant.vtp_transposter) {
                    this.clearInfosShipping();
                }
            },
            deep: true
        },
        deliveriesViettelPost: {
            handler(newData){
                let item = newData.find(item => item._id == this.entry.serviceId);
                if (item && item.total_price) {
                    this.addAmountShipping(item.total_price);
                    this.entry.requests = item.requests;
                } else if ((!item || !this.entry.serviceId) && this.entry.transporterId == this.$constant.vtp_transposter) {
                    this.clearInfosShipping();
                }
            },
            deep: true
        },
    },
    beforeUnmount(){
        this.hide();
    }
}
</script>

