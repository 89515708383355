<template>
    <div class="container">
        <div class="row">
            <ActionBar back-url="/order-suppliers/index" @action="save()"/>
            <div class="col-12 pb-100">
                <div class="row">
                    <div class="col-9 p-0 card-transaction-left position-relative">
                        <div class="row d-flex align-items-center" style="margin-top: 10px">
                            <div class="col-3 d-flex align-items-center justify-content-lg-between">
                                <svg width="17" height="17" viewBox="0 0 17 17" @click="back()" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1666 7.54183H4.50373L9.86081 2.18475L8.49998 0.833496L0.833313 8.50016L8.49998 16.1668L9.85123 14.8156L4.50373 9.4585H16.1666V7.54183Z" fill="#666666"/>
                                </svg>
                                <div class="main-title">
                                    <span>
                                        {{$t('message.OrderSupplierIndex.titleCreate')}}
                                    </span>
                                </div>
                            </div>
                            <div class="col-md-8">
                                <ProductSearch @select="onSelectProduct" @getInfoProduct="getInfoProductAndAdd"></ProductSearch>
                            </div>
                        </div>
                        <Pagination v-if=" form.products != null && form.products.length && form.products.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <table class="table table-index table-striped table-transaction" style="margin-top: 15px">
                            <thead>
                            <tr >
                                <th></th>
                                <th width="2%">
                                    {{$t('message.common.STT')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodCode')}}
                                </th>
                                <th width="23%">
                                    {{$t('message.DetailOrdersCard.prodName')}}
                                </th>                                
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.dvt')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.attribute')}}
                                </th>
                                <th width="10%">
                                    {{$t('message.DetailOrdersCard.prodQuantity')}}
                                </th>
                                <th width="15%">
                                    {{$t('message.DetailOrdersCard.prodPrice')}}
                                </th>
                                <th width="15%">
                                    {{$t('message.DetailOrdersCard.prodDiscount')}}
                                </th>
                                <th width="12%">
                                    {{$t('message.DetailOrdersCard.totalPrice')}}
                                </th>
                                <th width="3%"></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in form.products" :key="product.id" >
                                <tr v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                    <td>
                                        <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                            <i class="fas fa-trash"/>
                                        </a>
                                    </td>
                                    <td>{{ index + 1 }}</td>
                                    <td v-text="product.code"></td>
                                    <td>
                                        {{ product.name }}
                                        <div class="position-relative">
                                            <input type="text" v-model.trim="product.note" class="format__input bg-transparent text-left" :placeholder="$t('message.OrderSupplierIndex.note')">
                                            <span class="noteOrder text-gray" v-if="product.note == null || product.note == ''"><i class="fa fa-pencil-alt"></i></span>
                                        </div>
                                    </td>                                    
                                    <td>                                      
                                        <InputSelect v-model="product.productUnitId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                            :options="product.product_units"
                                            option-label="unitName"
                                            :cardTransaction="true"
                                            @update:modelValue="updateProductUnit(product)"
                                        ></InputSelect>
                                    </td>
                                    <!-- Thuộc tính -->
                                    <td>                                        
                                        <InputSelect v-model="product.variantId"
                                            v-if="product.attributesActive && product.attributesActive.length"
                                            :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                            :options="product.attributesActive"
                                            option-label="name"
                                            :cardTransaction="true"
                                            @update:modelValue="updateAttributes(product, product.variantId)"                                          
                                        ></InputSelect>
                                    </td>
                                    <td>                                        
                                        <div class="position-relative" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <input type="number" class="format__input py-1 mt-1" :min="1" @keyup="checkQuantity(product.quantity, product.id, $event)" v-model="product.quantity">
                                            <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment">
                                                <div>{{ branchName }}</div>
                                                <div>
                                                    <div>
                                                        {{$t('message.ProductUnitSearch.inventory')}} :
                                                    </div>
                                                    <div v-for="(item, index) in product.product_branch_meta" :key="index" class="d-flex" >
                                                        <div class="actualQuantityValue ml-4">{{ item.inventories }}</div>
                                                        <div class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                                        <span> ,</span>
                                                    </div>
                                                </div>
                                                <div>{{$t('message.ProductUnitSearch.orderSupplier')}}: {{ (product.order_quantity && product.order_quantity.length) ? product.order_quantity[0]?.totalQuantity : 0 }}</div>
                                                <div>
                                                    <div>
                                                        {{$t('message.ProductUnitSearch.orderFromCustomer')}}:
                                                    </div> 
                                                    <div v-for="(item, index) in product.product_branch_meta" :key="index" class="d-flex" >
                                                        <div class="actualQuantityValue ml-4">{{ item.quantityOrder }}</div>
                                                        <div class="actualQuantityValue ml-1">{{ item.product_unit?.unitName }}</div>
                                                        <span> ,</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="">
                                        <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="product.price" :inputTable="true" class="mb-0 py-1 mt-1"/>
                                    </td>
                                    <td class="position-relative ">
                                        <ProductDiscount :modelValue="product" @update:modelValue="updateDiscount" :price="product.price" class="py-1 mt-1"/>
                                    </td>
                                    <td class="mt-2 text-center border-none" style="display: flex; align-items:center;">{{ $filters.formatPrice((product.price - (product.discount?? 0))*product.quantity) }}</td>
                                    <td><i class="fa fa-plus" @click="addProductPlus(index, product)"></i></td>
                                </tr>
                                <template v-if="index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                    <tr v-for="(plus, plusIndex) in  product.plus" :key="plusIndex">
                                        <td colspan="4">
                                        </td>
                                        <td>
                                            <InputSelect v-model="plus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="plus.product_units"
                                                option-label="unitName"
                                                :cardTransaction="true"
                                                @update:modelValue="updateProductUnit(plus)"
                                            ></InputSelect>
                                        </td>
                                        <!-- Thuộc tính -->
                                        <td>                                            
                                            <InputSelect v-model="plus.variantId"
                                                v-if="product.attributesActive && product.attributesActive.length"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="plus.attributesActive"
                                                option-label="name"
                                                :cardTransaction="true"                                                                                      
                                                ></InputSelect>
                                        </td>
                                        <td>
                                            <div class="position-relative" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <input type="number" class="format__input" :min="1" @keyup="checkQuantity(plus.quantity, plus.id, $event)" v-model="plus.quantity">
                                            </div>
                                        </td>
                                        <td class="">
                                            <InputNumberFormat :max="$constant.maxNumber" :cardTransaction="true" v-model="plus.price" :inputTable="true" class="mb-0"/>
                                        </td>
                                        <td class="position-relative">
                                            <ProductDiscount :modelValue="plus" @update:modelValue="updateDiscount" :price="plus.price"/>
                                        </td>
                                        <td>{{ $filters.formatPrice((plus.price - (plus.discount?? 0))*plus.quantity) }}</td>
                                        <td>
                                            <a class="btn-remove" @click.prevent="removeDetailProductPlus(index, plusIndex)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                            </tbody>
                        </table>
                        <div class="row d-flex align-items-center importArea" v-if="form.products?.length == 0 || form.products == null">
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div class="main-title">
                                    <span>{{$t('message.ImportExcel.title')}}</span>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center">
                                <div>
                                    (
                                        {{$t('message.ImportExcel.defaultFile')}}
                                        <a :href="'../template/template_dat_hang_nhap_' + this.language + '.xlsx'" class="text-primary">
                                            {{$t('message.ImportExcel.orderSupplierFile')}}
                                        </a>
                                    )
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3">
                                <button class="p-2 button-import" :title="$t('message.ImportExcel.chooseFile')">
                                    <form ref="form">
                                        <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" :title="$t('message.ImportExcel.chooseFile')"/>
                                    </form>
                                    
                                    <i class="fa fa-file mr-2"></i>
                                    {{ $t('message.ImportExcel.chooseFile') }}
                                </button>
                            </div>
                            <div class="col-12 w-100 mt-3 upload-files" v-if="this.fileName != ''">
                                <div class="row h-100">
                                    <div class="col-6 d-flex align-items-center">
                                        {{ this.fileName }}
                                    </div>
                                    <div class="col-6 d-flex justify-content-end align-items-center">
                                        <a href="javascript:;" class="text-danger" @click="removeFile(false)">
                                            <i class="fa fa-times"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 w-100 mt-3">
                                <div class="alert alert-danger row py-2" v-if="this.messageFile">
                                       {{ this.messageFile }}
                                </div>
                            </div>
                           
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="this.fileName != ''">
                                <button v-show="this.showBtn" class="p-2 button-import" :title="$t('message.ImportExcel.action')" @click="uploadExcel(this.patient)">
                                    <i class="fa fa-file mr-2"></i>
                                    {{ $t('message.ImportExcel.action') }}
                                </button>
                            </div>
                        </div>

                    </div>

                    <div class="col-3 pt-2 card-transaction-right">
                        <div class="row card-transaction-right-content">
                            <div class="col-12">
                                <div class="row pb-2">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-2 p-0 d-flex justify-content-end align-items-center">
                                                <h5 class="mb-0"><i class="fa fa-user-circle"></i></h5>
                                            </div>
                                            <div class="col-10 pl-2 py-2">
                                                <SelectSearch :placeholder="$t('message.OrderSupplierIndex.order_by')" :modelValue="form.userId" :defaultOption="this.employees" @update:modelValue="selectEmployee" :label="'orderBy'"></SelectSearch>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-7 pl-0 pr-3">
                                                <InputDatePicker :cardTransaction="true" v-model="form.orderDate" :placeholder="$t('message.OrderSupplierIndex.created_by')" class="mb-0"/>
                                            </div>
                                            <div class="col-5 pl-0 pr-3">
                                                <InputTimePicker :cardTransaction="true" :default="form.startTime" @input="setTimeStart"></InputTimePicker>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row py-2">
                                    <div class="col-12">
                                        <SupplierSearch class="form-control" @select="onSelectSupplier" @clearSupplier="clearSupplier" :supplierName="form.supplierName" :placeholder="$t('message.common.searchSupplier')"></SupplierSearch>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-6 title" >
                                        {{$t('message.OrderSupplierIndex.code')}}
                                    </div>
                                    <div class="col-6">
                                        <input type="text" v-model="form.code" class="form-control input__order min-w-100 border-bottom-1 p-0" :placeholder="$t('message.common.autocompleteCode')">
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 title">
                                        {{$t('message.common.status')}}
                                    </div>
                                    <div class="col-6 title py-2">                                        
                                         <template v-if="this.hasPermission(this.$permissions.orderSuppliers.update) || this.userId == this.$constant.isAdmin || !this.isEdit">                                            
                                            <InputSelect :cardTransaction="true" name="status" v-model="form.status" :options="$constant.statusOptionsCreateOrderSupplier" class="mb-0"/>    
                                        </template>
                                        <template v-else>                                                                                        
                                            <span >                                                             
                                                {{ $constant.statusNameOrderSupplier[0] }}
                                                <input type="hidden" :id="'status_' + form.id" v-model="form.status">
                                            </span>
                                        </template>      

                                        <!-- <InputSelect :cardTransaction="true" name="status" v-model="form.status" :options="$constant.statusOptionsCreateOrderSupplier" class="mb-0"/> -->
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-6 pr-0 title">
                                        {{$t('message.OrderSupplierIndex.total_origin')}}
                                        <button class="border border-default ml-2 text-dark" disabled v-text="this.form.TotalQuantity"></button>
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(form.totalPrice) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row position-relative">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.discount')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="border-bottom form-discount text-right" @click.stop="showDiscountForm">{{ n(this.form.discountValue ?? 0) }}</div>
                                        <!-- <ProductDiscount :price="form.totalPrice" :modelValue="this.form" :discountPercent="form.valuePercent" :discountMoney="form.discountValue" @update:modelValue="updateDiscount"/> -->
                                    </div>
                                </div>
                                <div class="row my-2" v-if="this.form.paymentsInfo && this.form.paymentsInfo.length">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.paymentForSupplier')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary bg-light" @click="showModalPaymentSupplier" v-text="n(form.totalPaymentSupplier)"></div>
                                    </div>
                                </div>
                                <div class="row my-2 text-bold">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.needToPaySupplier')}}
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none text-primary" v-text="(form.totalPrice - (form.discountValue ?? 0) - (form.totalPaymentSupplier ?? 0)) > 0 ? n(form.totalPrice - (form.discountValue ?? 0) - (form.totalPaymentSupplier ?? 0)) : 0"></div>
                                    </div>
                                </div>
                                <div class="row mb-3" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.paidSupplier')}}
                                    </div>
                                    <div class="col-6">
                                        <div class="form-control text-right border-none">
                                            {{ $filters.formatPrice(form.paymentForSupplier) }}
                                        </div>
                                    </div>
                                </div>
                                 <div class="row" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-4 pr-0">
                                        <label class="container-checkbox title">{{$t('message.common.cash')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCashMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox title">{{$t('message.common.card')}}
                                            <input type="checkbox" :value="$constant.paymentChannelCardMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                    <div class="col-4 p-0">
                                        <label class="container-checkbox title">{{$t('message.common.transferPayment')}}
                                            <input type="checkbox" :value="$constant.paymentChannelTransferMoney" v-model="paymentChannel">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="row my-0 py-0" v-if="!form.totalPaymentSupplier || (form.totalPaymentSupplier < (+form.totalPrice - form.discountValue))">
                                    <div class="col-4 pr-0">
                                        <div class="input__order">
                                            <InputNumberFormat :cardTransaction="true" v-model="form.cashMoney" :readonly="!paymentChannel.includes($constant.paymentChannelCashMoney)" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :cardTransaction="true" v-model="form.cardMoney" :readonly="!paymentChannel.includes($constant.paymentChannelCardMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                    <div class="col-4 p-0">
                                        <div class="input__order">
                                            <InputNumberFormat :cardTransaction="true" v-model="form.transferMoney" :readonly="!paymentChannel.includes($constant.paymentChannelTransferMoney)" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)" class="mb-0"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row my-2">
                                    <div class="col-6">
                                        <!-- <input type="radio" id="return" :value="$constant.return" v-model="form.typeReturns"> -->
                                        <label for="return title">{{$t('message.OrderSupplierIndex.supplierReturn')}}</label>
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none" v-text="form.supplierReturn > 0 ? n(form.supplierReturn) : 0"></div>
                                    </div>
                                </div>
                                <!-- <div class="row pb-2" v-if="form.supplierId">
                                    <div class="col-6">
                                        <input type="radio" id="debt" :value="$constant.debt" v-model="form.typeReturns">
                                        <label for="debt">Tính vào công nợ</label>
                                    </div>
                                    <div class="col-6 text-right form-group mb-0">
                                        <div class="form-control border-none">
                                            {{ $filters.formatPrice(+form.paymentForSupplier - (+form.totalPrice - +form.discountValue)) }}
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-6">
                                        Chi phí nhập khác
                                    </div>
                                    <div class="col-6">
                                        <InputNumberFormat :cardTransaction="true" v-model="form.otherCosts" class="mb-0" @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"/>
                                    </div>
                                </div> -->
                                <div class="row pb-2">
                                    <div class="col-6 title">
                                        {{$t('message.OrderSupplierIndex.estimatedImportDate')}}
                                    </div>
                                    <div class="col-6">
                                        <InputDatePicker :cardTransaction="true" v-model="form.ExpectedDateOfImport" :canNullValue="true" :placeholder="$t('message.OrderSupplierIndex.estimatedImportDate')" class="mb-0"/>
                                        <i class="far fa-calendar fa-md" aria-hidden="true"></i>
                                    </div>
                                </div>
                                <div class="row pt-2 pb-3">
                                    <div class="col-6 title">
                                        {{$t('message.supplierIndex.debtDate')}}
                                    </div>
                                    <div class="col-6 min-h-100">
                                        <div class="w-100 p-0 m-0 h-100">
                                            <div class="p-0 m-0 border-bottom h-100" v-text="this.form.debtDate"></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <InputTextArea :placeholder="$t('message.OrderSupplierIndex.note')" v-model.trim="form.note" class="input__textArea mb-0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2 card-transaction-button w-100">
                            <div class="col-12">                                                                     
                                    <button class="btn_submit" :disabled="isButtonDisabled" @click="submit">
                                        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M3.42857 11.1429H8.57143V6H12L6 0L0 6H3.42857V11.1429ZM0 12.8571H12V14.5714H0V12.8571Z" fill="white"/>
                                        </svg>
                                        <div>
                                            {{ !isEdit ? $t('message.OrderSupplierIndex.button.add') : $t('message.button.save') }} 
                                        </div>
                                    </button>                                
                            </div>
                        </div>
                    </div>
                </div>
                <ModalProductDiscount ref="modal"/>
                <ModalErrorsMessage ref="ModalErrorsMessageImport" />
                <ModalPaymentSupplier ref="ModalPaymentSupplier" />
            </div>
        </div>
    </div>
    <OrderDiscount ref="orderDiscount" :price="form.totalPrice" :modelValue="this.form" :discountPercent="form.valuePercent" :discountMoney="form.discountValue" @update:modelValue="updateDiscount"/>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";    
    import {$post, $alert, $get, rand,$upload} from "@/lib/utils";
    import toastr from 'toastr';
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import QSelect from "../../components/QSelect";
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumber from "../../components/forms/InputNumber";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    // import ModalProductDiscount from "./ModalProductDiscount";
    import ErrorLabel from "../../components/ErrorLabel";
    import moment from "moment";
    import store from "../../store";
    import ProductSearch from "@/components/ProductSearch.vue";
    import SupplierSearch from "@/components/SupplierSearch.vue";
    import ProductDiscount from "@/components/ProductDiscount.vue";
    import OrderDiscount from "@/components/OrderDiscount.vue";
    import router from "@/router/index";
    import currencyMixin from "@/currencyMixin";
    import ModalErrorsMessage from './ModalErrorsMessage.vue';
    import emitter from "@/lib/emitter";
    import InputTimePicker from "../../components/forms/InputTimePicker.vue";
    import Pagination from "@/components/Pagination"
    import SelectSearch from "@/components/SelectSearch.vue";    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalPaymentSupplier from './tabs/ModalPaymentSupplier.vue';
    import attributesMixin from '@/attributesMixin';
    import { mapGetters } from 'vuex';
    import storeModul from "@/storeModul";
    import permissionMixin from "@/permissionMixin";
    export default {
        name: "OrderSupplierForm",
        mixins: [currencyMixin, attributesMixin, permissionMixin],
        components: {ActionBar, InputText, InputSwitchButton, QSelect, InputTextArea, InputSelect, InputNumberFormat, InputNumber,AppPermission,
        InputDatePicker, ErrorLabel, ProductSearch, SupplierSearch, ModalErrorsMessage, ProductDiscount, InputTimePicker, Pagination, SelectSearch, ModalPaymentSupplier, OrderDiscount},
        data() {
            let currentDate = new Date().toJSON().slice(0, 10);
            return {
                currentDate: currentDate,
                form: {
                    products: [],
                    userId: store.state.Auth.user.id,
                    branchId: store.state.CurrentBranch.id,
                    orderDate: currentDate,
                    startTime: moment().format('HH:mm'),
                    supplierId: null,
                    code: null,
                    status: this.$constant.statusOptionsTicketTemporary,
                    totalPrice: 0,
                    TotalQuantity: 0,
                    discountType: null,
                    discountValue: 0,
                    valuePercent: 0,
                    paymentForSupplier: 0,
                    cashMoney: 0,
                    cardMoney: 0,
                    transferMoney: 0,
                    otherCosts: 0,
                    ExpectedDateOfImport: null,
                    note: null,
                    supplierName: null,
                    supplierReturn: 0,
                    typeReturns: this.$constant.return,
                    totalPaymentSupplier: 0,
                },
                employees: null,
                paymentChannel: [this.$constant.paymentChannelCashMoney],
                isEdit: false,
                isCopy:false,
                fileName: "",
                patient: {
                    files: [],
                    branchId: ''
                },
                placeholderUser: 'Người đặt',
                paginate: {
                    totalRecord: 0,
                    currentPage: 1,
                    lastPage: 0,
                },
                numberRecord: 15,
                showBtn: false,
                messageFile: '',
                branchName: store.state.CurrentBranch.name,
                isButtonDisabled: false,
                language: localStorage.getItem('language') ?? 'vi'
            }
        },
        mounted() {
            document.title = this.$t('message.OrderSupplierIndex.document_title');
        },
        watch: {
            form: {
            handler(dataNew) {                
                if (dataNew.products !== null) {
                    let TotalPrice = 0;
                    let TotalQuantity = 0;
                    dataNew.products.forEach(product => {
                        if (product.discountType == this.$constant.discountTypePercent) {
                            product.discount = product.price * product.valuePercent / 100;
                        }
                        TotalPrice = TotalPrice + product.price * product.quantity - (product.discount ? product.discount * product.quantity : 0);
                        TotalQuantity = TotalQuantity + product.quantity;
                        if (product.plus && product.plus.length) {
                            product.plus.forEach(p => {
                                TotalPrice = TotalPrice + p.price * p.quantity - (p.discount ? p.discount * p.quantity : 0);
                                TotalQuantity += p.quantity;
                            });
                        }
                    });
                    this.form.totalPrice = TotalPrice;
                    this.form.TotalQuantity = TotalQuantity;
                    if (this.form.discountType == this.$constant.discountTypePercent) {
                        this.form.discountValue = TotalPrice * this.form.valuePercent / 100;
                    }
                    this.calculatePayment();
                    this.paginate.totalRecord = dataNew.products.length;
                    this.paginate.lastPage = Math.ceil(dataNew.products.length / this.numberRecord);
                }
            },
            deep: true
        },
            paymentChannel: {
                handler(){
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCashMoney)) {
                        this.form.cashMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelCardMoney)) {
                        this.form.cardMoney = 0;
                    }
                    if (!this.paymentChannel.includes(this.$constant.paymentChannelTransferMoney)) {
                        this.form.transferMoney = 0;
                    }
                },
                deep: true
            },
        },
        created() {
            this.getEmployees();            
            if (this.$route.query && this.$route.query.orderId && this.$route.query.type && this.$route.query.type == 'copy') {                                
                this.isCopy =  true;
                this.getCopySupplierOrder(this.$route.query.orderId, this.$route.query.type);
            }
            else if (this.$route.query && this.$route.query.orderId) {                
                this.isEdit =  true;
                this.getSupplierOrder(this.$route.query.orderId);
            } else if (this.productIds && this.$route.query.type == 'short-create-order-supplier') {
                this.getProducts(this.productIds);
                storeModul.dispatch('productStore/setProductIds', []);
            }
        },

        computed: {
            ...mapGetters({
                productIds: 'productStore/productIds',
            }),
        },
        methods: {
            showDiscountForm($event){
                let top = $event.clientY ?? 0;
                this.$refs.orderDiscount.showDiscountForm(top);
            },
            addProductPlus(ProductIndex, product) {                
                let plus = {
                    productUnitId: null,
                    quantity: 0,
                    discountValue: 0,
                    price: 0,
                    discountRatio: 0,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    attributesActive: product.attributes,
                    attributes: product.attributes
                }
                if (!this.form.products[ProductIndex].plus) {
                    this.form.products[ProductIndex].plus = [];
                }
                plus.index = this.form.products[ProductIndex].plus.length;
                this.form.products[ProductIndex].plus.push(plus);                
            },
            removeDetailProductPlus(productIdex, plusIndex) {
                this.form.products[productIdex].plus.splice(plusIndex, 1);
            },
            updateProductUnit(product) {                   
                if (product.product_units && product.product_units.length && product.productUnitId) {                    
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = null;
                    product.discountValue = 0;
                    product.price = product.productUnit.price?? 0;                    
                }
                else {
                    product.attributesActive = product.attributes;
                }    
                this.getListAttributeForUnit(product);               
            },
            updateAttributes(product, variantId) {
                if (!product.attributesActive || !product.attributesActive.length || !variantId) return;
                let variant = product.attributes.find(el => (el.id == variantId));
                product.price = variant ? variant.price : 0; 
            },
           
            pageChange(page) {
               this.paginate.currentPage = page;
            },
            checkQuantity(quantity, id, e) {
                if(e.key === 'Backspace') return;
                let product = this.form.products.find(item => item.id === id);
                if (typeof quantity != 'number') {
                    product.quantity = 1;
                }
                if (quantity < 0) {
                    product.quantity = 1;
                }
            },
            clearSupplier() {
                this.form.supplierId = null;
                this.form.supplierName =  null;
                this.form.debtDate = '';
            },
            getInfoProductAndAdd(product) {
                if (product.data && product.data.entry && product.data.productUnits);
                    const data = product.data.entry;
                    const units = product.data.productUnits;
                const productNew = {
                    id: data.id,
                    code: data.code,
                    name: data.name,
                    quantity: 1,
                    discount: null,
                    discountType: null,
                    valuePercent: null,
                    product_units: [
                        {
                            id: units[0].id,
                            unitName: units[0].unitName,
                            price: 0,
                        }
                    ],
                    inventories: 0,
                    orderByCustomer: 0,
                    order_quantity: [{
                        totalQuantity: 0,
                    }],
                }
                this.form.products.unshift(productNew)
            },
            calculatePayment() {
                this.form.paymentForSupplier = +this.form.cashMoney + +this.form.cardMoney + +this.form.transferMoney;
                this.form.supplierReturn = (+this.form.totalPaymentSupplier) + +this.form.paymentForSupplier - (+this.form.totalPrice - +this.form.discountValue);
                // form.totalPrice -form.discountValue
            },
             async getCopySupplierOrder(id, type) {                
                const res = await $get('/v1/order-suppliers/detail', {
                    id: id, type:type
                });                
                if (res.status.code == 200) {
                    this.form.id = res.result.id;
                    this.form.userId = res.result.userId;
                    this.form.branchId = res.result.branchId;
                    this.form.orderDate = moment().format('YYYY-MM-DD');
                    this.form.startTime = moment().format('HH:mm');
                    this.form.supplierId = null;
                    this.form.code = null;
                    this.form.status = this.$constant.status_draft;
                    this.form.discountType = null;
                    this.form.discountValue = null;
                    this.form.valuePercent = res.result.valuePercent;
                    this.form.paymentForSupplier = res.result.paymentForSupplier;
                    this.form.cashMoney = res.result.cash;
                    this.form.cardMoney = res.result.card;
                    this.form.transferMoney = res.result.transfer;
                    this.form.otherCosts = res.result.otherCosts;                    
                    this.form.ExpectedDateOfImport = null;
                    this.form.note = null;
                    this.form.supplierName = null;
                    this.form.products = res.result.products;
                    this.form.debtDate = res.result.debtDate ?? null;
                    this.paymentChannel = [];
                    this.form.typeReturns = res.result.debt === null ? this.$constant.return : this.$constant.debt;
                    if (this.form.cashMoney != 0 ) {
                        this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    } 
                    if (this.form.cardMoney != 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                    } 
                    if (this.form.transferMoney != 0) {
                        this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                    }                    
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            async getSupplierOrder(id, type) {                
                const res = await $get('/v1/order-suppliers/detail', {
                    id: id, 
                    type:type,
                    branchId: store.state.CurrentBranch.id,
                });                
                if (res.status.code == 200) {
                    this.form.id = res.result.id;
                    this.form.userId = res.result.userId;
                    this.form.branchId = res.result.branchId;
                    this.form.orderDate = res.result.orderDate;
                    this.form.startTime = moment(this.form.orderDate).format('HH:mm');
                    this.form.supplierId = res.result.supplierId;
                    this.form.code = this.isCopy === true ? 'PDN' + rand(100000,999999) : res.result.code;
                    this.form.status = res.result.status;
                    this.form.discountType = res.result.typeDiscount;
                    this.form.discountValue = res.result.priceDiscount;
                    this.form.valuePercent = res.result.valuePercent;
                    this.form.paymentForSupplier = res.result.paymentForSupplier;
                    this.form.cashMoney = res.result.cash;
                    this.form.cardMoney = res.result.card;
                    this.form.transferMoney = res.result.transfer;
                    this.form.otherCosts = res.result.otherCosts;
                    this.form.ExpectedDateOfImport = res.result.ExpectedDateOfImport;
                    this.form.note = res.result.note;
                    this.form.supplierName = res.result.supplier?.name;
                    this.form.products = res.result.products;
                    this.form.debtDate = res.result.debtDate;
                    this.paymentChannel = [];
                    this.form.paymentsInfo = res.result.paymentsInfo ?? [];
                    this.form.typeReturns = res.result.debt === null ? this.$constant.return : this.$constant.debt;
                    if (this.form.cashMoney != 0 && this.form.cashMoney) {
                        this.paymentChannel.push(this.$constant.paymentChannelCashMoney);
                    } 
                    if (this.form.cardMoney != 0 && this.form.cardMoney) {
                        this.paymentChannel.push(this.$constant.paymentChannelCardMoney);
                    } 
                    if (this.form.transferMoney != 0 && this.form.transferMoney) {
                        this.paymentChannel.push(this.$constant.paymentChannelTransferMoney);
                    }
                    let totalPaymentSupplier = 0;
                    if(this.form.paymentsInfo){
                        this.form.paymentsInfo.forEach(el => {
                            totalPaymentSupplier += (+el.amount) ?? 0;
                        });
                    }
                    this.form.totalPaymentSupplier = totalPaymentSupplier;
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                }
            },
            back() {
                router.push({ name: 'OrderSupplierIndex'});
            },
            async getEmployees() {
                const res = await $post('/v1/order-suppliers/get-employees');
                if (res.status.code == 200) {
                    this.employees = res.result;
                }
            },
            async getProducts (ids) 
            {
                let params = {
                    branchId: store.state.CurrentBranch.id,
                    ids: ids
                }
                let data = await $post('/v1/order-suppliers/get-products-id', params);
                this.form.products = data.result;
                this.form.products.forEach(element => {
                    element.quantity = 1;
                });
            },
            selectEmployee(v) {
                this.form.userId = v.model;
            },
            onSelectProduct(product) {
                if (!this.form.products.length || !this.form.products.find(item => item.id === product.id)) {
                    if (product.product_units) {
                        product.productUnitId = product.product_units[0].id;
                        product.price = product.product_units[0].price;
                        product.productUnit = product.product_units[0];
                        // product.attributesActive = product.attributes;                       
                    }                    
                    this.form.products.unshift(product);
                }
            },
            showModalDiscountProduct(product) {
                this.$refs.modal.show(product.discount, product.discountType, product.valuePercent, async (value) => {
                    this.updateDiscountProduct(value, product.id)
                });
            },
            showModalDiscountForm(form) {
                this.$refs.modal.show(form.discountValue , form.discountType, form.valuePercent, async (value) => {
                    this.updateDiscountForm(value, form.totalPrice);
                });
            },
            updateDiscount(value){
                if(value.dEntry.products){
                    this.form.discountValue = value.model.discountValue;
                    this.form.valuePercent = value.model.valuePercent;
                    this.form.discountRatio = value.model.valuePercent;
                    this.form.discountType = value.model.discountType;
                }else{
                    if (this.form.products != null) {
                        this.form.products.forEach(product => {
                            if (product.id === value.dEntry?.id) {
                                if (!value.dEntry.isPlus) {
                                    product.discount = value.model.discountValue;
                                    product.discountValue = value.model.discountValue;
                                    product.valuePercent = value.model.valuePercent;
                                    product.discountRatio = value.model.valuePercent;
                                    product.discountType = value.model.discountType;
                                } else {
                                    product.plus[value.dEntry.index].discount = value.model.discountValue;
                                    product.plus[value.dEntry.index].discountValue = value.model.discountValue;
                                    product.plus[value.dEntry.index].valuePercent = value.model.valuePercent;
                                    product.plus[value.dEntry.index].discountRatio = value.model.valuePercent;
                                    product.plus[value.dEntry.index].discountType = value.model.discountType;
                                }
                            }
                        })
                    }
                }
            },
            removeDetailProduct(product) {
                if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                    return;
                }
                let itemIndex = -1;
                this.form.products.forEach((item, index) => {
                    if (item.id === product.id) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.form.products.splice(itemIndex, 1);
                }  
            },
            onSelectSupplier(supplier) {
                this.form.supplierId = supplier.id;
                this.form.debtDate = '';
                if(supplier.numberDebtDate){
                    this.form.debtDate = moment().add((+supplier.numberDebtDate), 'days').format('DD/MM/YYYY');
                }else if(supplier.recurringPaymentDate){
                    this.form.debtDate = supplier.recurringPaymentDate;
                }
            },
            setTimeStart (time) {
                this.form.startTime = time;
            },
            updateDiscountForm(value, price) {
                this.form.discountType = value.discountType;
                if (value.discountType == this.$constant.discountTypePercent) {
                    this.form.discountValue = price*value.valuePercent/100;
                    this.form.valuePercent = value.valuePercent;
                } else {
                    this.form.discountValue = value.discount;
                }
            },
            async submit() {
                // let isUpStatus = this.hasPermission(this.$permissions.orderSuppliers.update) || this.userId == this.$constant.isAdmin;
                let isUpStatus = (this.hasPermission(this.$permissions.orderSuppliers.update) || this.userId == this.$constant.isAdmin || !this.isEdit) ? true : false;
                
                if (this.form.products.length === 0) {
                    toastr.error(this.$t('message.OrderSupplierIndex.emptyOrder'));
                    return;
                }
                if (this.form.expectedAt) {
                    let date1 = moment(this.form.ExpectedDateOfImport).format("YYYY-MM-DD");
                    let date2 = moment(this.form.orderDate).format("YYYY-MM-DD");
                    if (date1 < date2) {
                        toastr.error(this.$t('message.OrderSupplierIndex.invalidETImportDate'));
                        return;
                    }
                }
                this.form.orderDateFormat = moment(this.form.orderDate).format("YYYY-MM-DD") + ' ' + this.form.startTime;
                const params = {
                    products: this.form.products,
                    userId: this.form.userId,
                    branchId: this.form.branchId,
                    orderDate: this.form.orderDate,
                    startTime: this.form.startTime,
                    supplierId: this.form.supplierId,
                    code: this.form.code?.trim(),
                    status: this.form.status,
                    totalPrice: this.form.totalPrice,
                    discountType: this.form.discountType,
                    discountValue: this.form.discountValue,
                    valuePercent: this.form.valuePercent,
                    paymentForSupplier: this.form.paymentForSupplier + (this.form.totalPaymentSupplier ?? 0),
                    totalPaymentSupplier: (this.form.totalPaymentSupplier ?? 0),
                    cashMoney: this.form.cashMoney,                    
                    cardMoney: this.form.cardMoney,                    
                    transferMoney: this.form.transferMoney,
                    transfer: this.form.transferMoney,
                    otherCosts: this.form.otherCosts,
                    ExpectedDateOfImport: this.form.ExpectedDateOfImport,
                    note: this.form.note,
                    orderDateFormat: this.form.orderDateFormat,
                    typeReturns: this.form.typeReturns,
                    cash: this.form.cashMoney,
                    card: this.form.cardMoney,                    
                    debtDate: this.form.debtDate
                };
                // if(isUpStatus){
                //     params.status = this.form.status;
                // }
                
                this.isButtonDisabled = true;                
                let res;
                if (!this.isEdit) {                    
                    res = await $post('/v1/order-suppliers/create', {...params}); 
                } else {
                    params.id = this.form.id;
                    res = await $post('/v1/order-suppliers/update-order', {...params});
                }
                if (res.code === 422) {
                    const errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: res.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                } else if (res.status.code == 200 && res.result.status) {
                    $alert({code: 200, message: !this.isEdit ? this.$t('message.OrderSupplierIndex.alertCreateSuccess') : this.$t('message.OrderSupplierIndex.alertUpdateSuccess')});
                    this.$router.push({name: 'OrderSupplierIndex'});
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            updateDiscountProduct(value, productId) {
                this.form.products.forEach((item) => {
                    if (item.id === productId) {
                        item.discountType = value.discountType;
                        if (value.discountType == this.$constant.discountTypePercent) {
                            item.discount = Math.round(item.product_units[0].price * value.valuePercent /100);
                            item.valuePercent = value.valuePercent;
                        } else {
                            item.discount = value.discount;
                        }
                    }
                });
            },
            onFileChange(event) {
               
                this.patient.files = '';
                var files = event.target.files;
                if (!files.length) {  
                    return;
                }
                
                this.fileName = files[0].name;
                this.patient.files = files[0];
                
                let id = document.getElementById('branchDropdown');
                // this.patient.branchId = id.getAttribute('data-id'); 
                this.patient.branchId = store.state.CurrentBranch.id;
                if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
                    this.showBtn = true;
                    this.messageFile = '';

                } else {
                    this.showBtn = false;
                    this.messageFile = this.$t('message.common.inValidFormatFile');
                }
                this.$refs.form.reset() 
            },
            removeFile(status) {
            this.patient.files = [];
            this.fileName = '';
                if (status == false) {
                    this.$refs.fileupload.value = '';
                    this.messageFile = ''
                }
            },
            async uploadExcel (file) 
            {
                const result = await $upload('/v1/order-suppliers/import', [] , file);
                if (result.result.code == 422) {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                } else if (result.result.code == 200) {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    this.form.products = result.result.data;
                }
            },
            showModalPaymentSupplier(){
                this.$refs.ModalPaymentSupplier.show(this.form, this.form.code);
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        background: $base-color;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .btn_submit:focus{
        outline: none;
        border: none;
    }
    .custom__select {
        height: 35px;
        border: none;
        border-bottom: 1px solid #C7C5C5;
        color: #495057;
    }
    .format__list-time {
        top: 0 !important;
        left: 0 !important;
        width: 220px !important;
        height: 160px;
        overflow: auto;
    }
    .input__order .form-control {
        border: none;
        border-bottom: solid 1px #ccc;
    }
    .main__content {
        margin: 10px 30px;
    }
    .input__order, input {
        width: 90%;
    }
    .input__order {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    input[type=checkbox], input[type=radio] {
        width: 10% !important;
    }
    .input__textArea textarea {
        border: none;
        resize: none;
        border-bottom: solid 1px #ccc;
    }
    .far {
        position: absolute;
        right: 25px;
        top: 11px;
        font: normal normal normal 14px/1;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .importArea{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
    }
    .button-import{
        border-radius: 3px;
        border:none;
        outline: none;
        background: $base-color;
        color: #ffffff;
    }
    .button-import:focus{
        outline: none;
        border: none;
    }
    .button-import input{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        font: 170px monospace;
        filter: alpha(opacity=0);
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
    }
    .upload-files{
        overflow: hidden;
        padding: 1px 5px 2px;
        background: #ebf7fb;
        border: 1px solid #c5e7f2!important;
        min-height: inherit;
        line-height: inherit;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        height: 40px;
    }
    .popup-update-price{
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 99;
        width: 350px;
        background: white;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        padding: 1.4rem;
        border: none;
        border-radius: 7px;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .table-transaction thead th, .table-transaction tbody td{
        padding-top: 10px !important;
        vertical-align: top !important;
    }
    #typeCard {
        border: none;
        box-shadow: none;
    }
    .title {
        display: flex;
        align-items: center;
    }
</style>
