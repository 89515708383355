const mainRoutes = [
    {
        path: "/",
        name: "Home",
        label: 'Trang chủ',
        component: require('@/views/Dashboard').default,
    },    
    {
        path: "/xadmin/login",
        name: "Login",
        component: require('@/views/auth/LoginScreen').default,
        meta: {
            noRequiredAuth: true
        }
    },
    {'path': '/not-permissions', 'name': 'notPermission', 'component':  require('@/views/errors/NotPermissionPage').default},

    {'path': '/customers/index', 'name': 'CustomerIndex', 'component':  require('@/views/customers/CustomerIndex').default},
    {'path': '/customers-group/index', 'name': 'CustomerGroupIndex', 'component':  require('@/views/customers/CustomerGroupIndex').default},
    {'path': '/customers/form', 'name': 'CustomerForm', 'component':  require('@/views/customers/CustomerForm').default},
    {'path': '/customers/detail', 'name': 'CustomerDetail', 'component':  require('@/views/customers/CustomerDetail').default},
    {'path': '/branches/index', 'name': 'BranchIndex', 'component':  require('@/views/branches/BranchIndex').default},
    {'path': '/branches/form', 'name': 'BranchForm', 'component':  require('@/views/branches/BranchForm').default},
    {'path': '/branches/detail', 'name': 'BranchDetail', 'component':  require('@/views/branches/BranchDetail').default},
    {'path': '/suppliers/index', 'name': 'SupplierIndex', 'component':  require('@/views/suppliers/SupplierIndex').default},
    {'path': '/suppliers-group/index', 'name': 'SuppliersGroupIndex', 'component':  require('@/views/suppliers/SuppliersGroupIndex').default},
    {'path': '/suppliers/form', 'name': 'SupplierForm', 'component':  require('@/views/suppliers/SupplierForm').default},
    {'path': '/suppliers/detail', 'name': 'SupplierDetail', 'component':  require('@/views/suppliers/SupplierDetail').default},
    {'path': '/transporters/index', 'name': 'TransporterIndex', 'component':  require('@/views/transporters/TransporterIndex').default},
    {'path': '/transporters/form', 'name': 'TransporterForm', 'component':  require('@/views/transporters/TransporterForm').default},
    {'path': '/transporters/detail', 'name': 'TransporterDetail', 'component':  require('@/views/transporters/TransporterDetail').default},
    {'path': '/products/index', 'name': 'ProductIndex', 'component':  require('@/views/products/ProductIndex').default},
    {'path': '/products/form', 'name': 'ProductForm', 'component':  require('@/views/products/ProductForm').default},
    {'path': '/products/detail', 'name': 'ProductDetail', 'component':  require('@/views/products/ProductDetail').default},
    {'path': '/categories/index', 'name': 'CategoryIndex', 'component':  require('@/views/categories/CategoryIndex').default},
    {'path': '/categories/form', 'name': 'CategoryForm', 'component':  require('@/views/categories/CategoryForm').default},
    {'path': '/categories/detail', 'name': 'CategoryDetail', 'component':  require('@/views/categories/CategoryDetail').default},
    
    {'path': '/purchase-orders/index', 'name': 'PurchaseOrderIndex', 'component':  require('@/views/purchase_orders/PurchaseOrderIndex').default},
    {'path': '/purchase-orders/form', 'name': 'PurchaseOrderForm', 'component':  require('@/views/purchase_orders/PurchaseOrderForm').default},
    {'path': '/purchase-orders/detail', 'name': 'PurchaseOrderDetail', 'component':  require('@/views/purchase_orders/PurchaseOrderDetail').default},
    {'path': '/purchase-orders/return', 'name': 'ReturnPurchaseOrder', 'component':  require('@/views/purchase_orders/ReturnPurchaseOrderIndex').default},
    
    {'path': '/transfers/index', 'name': 'TransferIndex', 'component':  require('@/views/transfers/TransferIndex').default},
    {'path': '/transfers/form', 'name': 'TransferForm', 'component':  require('@/views/transfers/TransferForm').default},
    {'path': '/transfers/detail', 'name': 'TransferDetail', 'component':  require('@/views/transfers/TransferDetail').default},
    {'path': '/orders/index', 'name': 'OrderIndex', 'component':  require('@/views/orders/OrderIndex').default},
    {'path': '/orders/error-sync', 'name': 'OrderTabErrorOrderSync', 'component':  require('@/views/orders/OrderTabErrorOrderSync').default},
    {'path': '/orders/form', 'name': 'OrderForm', 'component':  require('@/views/orders/OrderForm.vue').default},
    {'path': '/sale-channels/index', 'name': 'SaleChannelIndex', 'component':  require('@/views/sale_channels/SaleChannelIndex.vue').default},
    {'path': '/sale-channels/form', 'name': 'SaleChannelForm', 'component':  require('@/views/sale_channels/SaleChannelForm.vue').default},
    {'path': '/sale-channels/detail', 'name': 'SaleChannelDetail', 'component':  require('@/views/sale_channels/SaleChannelDetail.vue').default},
    {'path': '/order-suppliers/index', 'name': 'OrderSupplierIndex', 'component':  require('@/views/order_suppliers/OrderSupplierIndex').default},
    {'path': '/order-suppliers/form', 'name': 'OrderSupplierForm', 'component':  require('@/views/order_suppliers/OrderSupplierForm').default},
    {'path': '/order-suppliers/detail', 'name': 'OrderSupplierDetail', 'component':  require('@/views/order_suppliers/OrderSupplierDetail').default},
    {'path': '/invoices/index', 'name': 'InvoiceIndex', 'component':  require('@/views/invoices/InvoiceIndex').default},
    {'path': '/invoices/form', 'name': 'InvoiceForm', 'component':  require('@/views/invoices/InvoiceForm').default},
    {'path': '/deliveries/index', 'name': 'DeliveryIndex', 'component':  require('@/views/deliveries/DeliveryIndex').default},
    {'path': '/deliveries/form', 'name': 'DeliveryForm', 'component':  require('@/views/deliveries/DeliveryForm').default},
    {'path': '/returns/index', 'name': 'ReturnIndex', 'component':  require('@/views/returns/ReturnIndex').default},
    {'path': '/returns/form', 'name': 'ReturnForm', 'component':  require('@/views/returns/ReturnForm').default},
    {'path': '/promotions/index', 'name': 'PromotionIndex', 'component':  require('@/views/promotions/PromotionIndex').default},
    {'path': '/promotions/form', 'name': 'PromotionForm', 'component':  require('@/views/promotions/PromotionForm').default},
    {'path': '/promotions/detail', 'name': 'PromotionDetail', 'component':  require('@/views/promotions/PromotionDetail').default},
    

    // Danh mục vị trí
    {'path': '/locations/index', 'name': 'LocationsIndex', 'component':  require('@/views/locations/LocationsIndex').default},
    // Vị trí hàng hóa
    {'path': '/position-products/import', 'name': 'ImportPositionProductsIndex', 'component':  require('@/views/position_products/importPositionProducts.vue').default},
    {'path': '/position-products/export', 'name': 'ExportPositionProductsIndex', 'component':  require('@/views/position_products/exportPositionProducts.vue').default},
    {'path': '/position-products/transfer', 'name': 'TransferPositionProductsIndex', 'component':  require('@/views/position_products/transferPositionProducts.vue').default},
    // {'path': '/settings/store', 'name': 'StoreSetting', 'component':  require('@/views/settings/tabs/StoreSetting').default},
    //Settings
    {'path': '/settings/store', 'name': 'StoreSetting', 'component':  require('@/views/settings/ConnectionSetting.vue').default},
    {'path': '/settings/print-template', 'name': 'PrintTemplate', 'component':  require('@/views/settings/print_template/PrintTemplate.vue').default},

    {'path': '/coupons/index', 'name': 'CouponIndex', 'component':  require('@/views/coupons/CouponIndex').default},
    {'path': '/coupons/form', 'name': 'CouponForm', 'component':  require('@/views/coupons/CouponForm').default},
    {'path': '/coupons/detail', 'name': 'CouponDetail', 'component':  require('@/views/coupons/CouponDetail').default},
    {'path': '/tags/index', 'name': 'TagIndex', 'component':  require('@/views/tags/TagIndex').default},
    {'path': '/tags/form', 'name': 'TagForm', 'component':  require('@/views/tags/TagForm').default},
    
    // User và Employees
    {'path': '/users/index', 'name': 'UserIndex', 'component':  require('@/views/users/UserIndex').default},
    {'path': '/users/form', 'name': 'UserForm', 'component':  require('@/views/users/UserForm').default},
    {'path': '/users/detail', 'name': 'UserDetail', 'component':  require('@/views/users/UserDetail').default},
    {'path': '/users/detail', 'name': 'UserDetail', 'component':  require('@/views/users/UserDetail').default},
    // employee
    {'path': '/employees/index', 'name': 'EmployeeIndex', 'component':  require('@/views/employee/EmployeeIndex').default},

    // Vouchers    
    {'path': '/vouchers/index', 'name': 'VoucherIndex', 'component':  require('@/views/vouchers/VoucherIndex').default},
    
    // Dự trù hàng nhập
    // {'path': '/plans/create', 'name': 'PlansForm', 'component':  require('@/views/plans/PlansForm').default},

    // Dự trù hàng mua
    {'path': '/plan-purchase/index', 'name': 'PlanPurchaseIndex', 'component':  require('@/views/plan_purchase/PlanPurchaseIndex').default},
    
    {'path': '/permissions/index', 'name': 'PermissionIndex', 'component':  require('@/views/permissions/PermissionIndex').default},
    {'path': '/permissions/form', 'name': 'PermissionForm', 'component':  require('@/views/permissions/PermissionForm').default},

    {'path': '/inventory-plans/index', 'name': 'InventoryPlanIndex', 'component':  require('@/views/inventory_plans/InventoryPlanIndex').default},
    {'path': '/inventory-plans/create', 'name': 'PlansForm', 'component':  require('@/views/inventory_plans/PlansForm').default},
    {'path': '/inventory-plans/order-suppliers', 'name': 'InventoryPlanOrderSuppliers',
        'component':  require('@/views/inventory_plans/InventoryPlanOrderSuppliers').default},
    {'path': '/inventory-plans/products', 'name': 'InventoryPlanProducts', 'component':  require('@/views/inventory_plans/InventoryPlanProducts').default},

    {'path': '/roles/index', 'name': 'RoleIndex', 'component':  require('@/views/roles/RoleIndex').default},
    {'path': '/roles/form', 'name': 'RoleForm', 'component':  require('@/views/roles/RoleForm').default},
    {'path': '/units/index', 'name': 'UnitIndex', 'component':  require('@/views/units/UnitIndex').default},
    {'path': '/units/form', 'name': 'UnitForm', 'component':  require('@/views/units/UnitForm').default},

    {'path': '/surcharges/index', 'name': 'SurchargeIndex', 'component':  require('@/views/surcharges/SurchargeIndex').default},
    {'path': '/surcharges/form', 'name': 'SurchargeForm', 'component':  require('@/views/surcharges/SurchargeForm').default},

    

    // Department
    {'path': '/departments', 'name': 'Department', 'component': require('@/views/department/DepartmentIndex').default},

    // setting price
    {'path': '/setting_price/index', 'name': 'SettingPriceIndex', 'component':  require('@/views/setting_price/SettingPriceIndex').default},

    // price lists
    {'path': '/price-lists/index', 'name': 'PriceListIndex', 'component':  require('@/views/setting_price/PriceListIndex').default},
 
    // create order supplier
    {'path': '/order-supplier/create', 'name': 'orderSupplierCreate', 'component':  require('@/views/order_suppliers/OrderSupplierCreateUpdate').default},

    // update order supplier
    {'path': '/order-supplier/update', 'name': 'orderSupplierUpdate', 'component':  require('@/views/order_suppliers/OrderSupplierCreateUpdate').default},
    // {'path': '/order-supplier/update/:orderId', 'name': 'orderSupplierUpdate', 'component':  require('@/views/order_suppliers/OrderSupplierCreateUpdate').default},
    {'path': '/errors', 'name': 'ErrorsRequest', 'component':  require('@/views/errors/BadRequest').default},

    // create import supplier
    {'path': '/order-supplier/create-import', 'name': 'ImportSupplierCreate', 'component':  require('@/views/order_suppliers/ImportSupplierCreateUpdate').default},

    // update import supplier
    {'path': '/order-supplier/update-import', 'name': 'ImportSupplierUpdate', 'component':  require('@/views/order_suppliers/ImportSupplierCreateUpdate').default},

    {'path': '/order-supplier/copy', 'name': 'ImportSupplierCopy', 'component':  require('@/views/order_suppliers/ImportSupplierCreateUpdate').default},
    // create return supplier
    {'path': '/order-supplier/create-return', 'name': 'ReturnSupplierCreate', 'component':  require('@/views/order_suppliers/ReturnSupplierCreateUpdate').default},
    // {'path': '/order-supplier/copy-return', 'name': 'ReturnSupplierCopy', 'component':  require('@/views/order_suppliers/ReturnSupplierCreateUpdate').default},

    // update return supplier
    {'path': '/order-supplier/update-return', 'name': 'ReturnSupplierUpdate', 'component':  require('@/views/order_suppliers/ReturnSupplierCreateUpdate').default},
    // product-charateristics
    {'path': '/product-charateristics/update', 'name': 'UpdateProductCharateristics', 'component':  require('@/views/product-charateristics/ProductCharateristicsIndex').default},

    // create transfer branch
    {'path': '/transfer-branch/create', 'name': 'CreateTransferBranch', 'component':  require('@/views/transfers/TransferCreateUpdate').default},

    // copy transfer branch
    {'path': '/transfer-branch/copy', 'name': 'CopyTransferBranch', 'component':  require('@/views/transfers/TransferCreateUpdate').default},

    // update transfer branch
    {'path': '/transfer-branch/update/:id', 'name': 'UpdateTransferBranch', 'component':  require('@/views/transfers/TransferCreateUpdate').default},

    // confirm transfer branch
    {'path': '/transfer-branch/confirm/:id', 'name': 'ConfirmTransferBranch', 'component':  require('@/views/transfers/TransferCreateUpdate').default},

    // create request transfer 
    {'path': '/transfer-branch/request/create', 'name': 'RequestTransferBranch', 'component':  require('@/views/transfers/RequestCreateUpdate').default},

    // edit request transfer 
    {'path': '/transfer-branch/request/edit/:id', 'name': 'EditRequestTransferBranch', 'component':  require('@/views/transfers/RequestCreateUpdate').default},

    // confirm request branch
    {'path': '/transfer-branch/request/confirm/:id', 'name': 'ConfirmRequestBranch', 'component':  require('@/views/transfers/RequestCreateUpdate').default},
    
    // request index
    {'path': '/request/index', 'name': 'RequestIndex', 'component':  require('@/views/transfers/RequestIndex').default},
    
    // request copy
    {'path': '/transfer-branch/request/copy', 'name': 'CopyRequestTransferBranch', 'component':  require('@/views/transfers/RequestCreateUpdate').default},

    // Đề nghị hoàn hàng
    {'path': '/request-refund/index', 'name': 'RequestRefundIndex', 'component':  require('@/views/request_refund/RequestRefundIndex').default},

    // Đề nghị tạm ứng
    {'path': '/settlement-advance/index', 'name': 'SettlementAdvanceIndex', 'component':  require('@/views/settlement_advance/SettlementAdvanceIndex').default},

    // Sổ quỹ - tiền mặt
    {'path': '/cash-book-money/index' , 'name': 'CashBookMoneyIndex', 'component': require('@/views/cashbook/CashBookMoneyIndex').default},

    // Sổ quỹ - ngân hàng
    {'path': '/bank-book/index', 'name': 'BankBookIndex', 'component': require('@/views/bankbook/BankBookIndex').default},

    // Danh mục thuốc
    {'path': '/medicine/index', 'name': 'MedicineIndex', 'component': require('@/views/medicine/MedicineIndex').default},

    // Liên kết thuốc với danh mục thuốc cục dược
    {'path': '/medicine/mapping-product', 'name': "MedicineProductMappingIndex", 'component': require('@/views/medicine/MedicineProductMappingIndex').default},

    // Lịch sử thao tác
    {'path': '/history/index', 'name': "HistoryIndex", 'component': require('@/views/history/HistoryIndex').default},

    // Mẫu gửi mail
    {'path': '/pdf-template/index', 'name': "PdfTemplateIndex", 'component': require('@/views/settings/pdf_template/PdfTemplateIndex').default},

    // discount BAS    
    {'path': '/discount-bas/index', 'name': "DiscountsBasIndex", 'component': require('@/views/discounts_bas/DiscountsBasIndex').default},

    // Báo cáo bán hàng theo chi nhánh
    {'path': '/report-branch/index', 'name': "ReportBranchIndex", 'component': require('@/views/reports/ReportBranchIndex').default},
    
    // Báo cáo bán hàng theo nhân viên
    {'path': '/report-employee/index', 'name': "ReportEmployeeIndex", 'component': require('@/views/reports/ReportEmployeeIndex').default},
    
    // Báo cáo bán hàng theo kênh bán
    {'path': '/report-sale-channels/index', 'name': "ReportSaleChannelsIndex", 'component': require('@/views/reports/ReportSaleChannelsIndex').default},

    // Báo cáo tồn kho theo vị trí
    {'path': '/report-locations/index', 'name': "ReportLocationsIndex", 'component': require('@/views/reports/ReportLocationsIndex').default},

    // Báo cáo tồn kho theo hạn sử dụng
    {'path': '/report-consignment/index', 'name': "ReportConsignmentIndex", 'component': require('@/views/reports/ReportConsignmentIndex').default},
    {'path': '/report-locations/index', 'name': "ReportLocationsIndex", 'component': require('@/views/reports/ReportLocationsIndex').default},
    {'path': '/report-location-consignment/index', 'name': "ReportLocationConsignmentIndex", 'component': require('@/views/reports/ReportLocationConsignmentIndex').default},

    // Báo cáo tăng giảm tồn kho
    {'path': '/report-inventories/index', 'name': 'ReportInventoriesIndex', 'component': require('@/views/reports/ReportInventoriesIndex').default},
    {'path': '/report-inventories-detail/index', 'name': 'ReportInventoriesDetailIndex', 'component': require('@/views/reports/ReportInventoriesDetailIndex').default},
    
    // Báo cáo giá trị kho
    {'path': '/report-value-branch/index', 'name': "ReportValueBranchndex", 'component': require('@/views/reports/ReportValueBranchndex').default},

    // Báo cáo doanh thu theo bảng giá
    {'path': '/report-revenue-by-price-list/index', 'name': "ReportRevenuePriceListIndex", 'component': require('@/views/reports/ReportRevenuePriceListIndex').default},
    
    {'path': '/report-sale-detail/index', 'name': "ReportSaleDetailIndex", 'component': require('@/views/reports/ReportSaleDetailIndex').default},
    {'path': '/report-stock-detail/index', 'name': "ReportStockDetailIndex", 'component': require('@/views/reports/ReportStockDetailIndex').default},
     // Báo cáo doanh thu theo nhóm khách hàng
     {'path': '/report-revenue-customer-group/index', 'name': "ReportRevenueCustomerGroupIndex", 'component': require('@/views/reports/ReportRevenueCustomerGroupIndex').default},
    // Phân tích doanh thu
    {'path': '/report-revenue-time/index', 'name': 'ReportRevenueTimeIndex', 'component': require('@/views/reports/ReportRevenueTime').default},
    {'path': '/report-revenue-branch/index', 'name': 'ReportRevenueBranchIndex', 'component': require('@/views/reports/ReportRevenueBranch').default},

    {'path': '/report-purchase-detail/index', 'name': "ReportPurchaseDetailIndex", 'component': require('@/views/reports/ReportPurchaseDetailIndex').default},

    {'path': '/report-pay-to-debt/index', 'name': 'ReportPayToDebt', 'component': require('@/views/reports/ReportPayToDebt').default}
];
export default mainRoutes as Array<any>;
