<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h6 v-if="isEdit && type == null" class="modal-title py-2 px-4">{{$t('message.VoucherIndex.modal.create_or_update.title_update')}}</h6>
                    <h6 v-else-if="isEdit && type" class="modal-title py-2 px-4">{{$t('message.VoucherIndex.modal.create_or_update.title_create')}}</h6>
                    <h6 v-else class="modal-title py-2 px-4">{{$t('message.VoucherIndex.modal.create_or_update.title_create')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-header custom__list-tab bg-light px-3 pt-3 pb-0 text-center">
                    <div :class="[ isTab === $constant.tab.first ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.first)">
                        {{$t('message.VoucherIndex.modal.create_or_update.tabs.info')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.second ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.second)">
                        {{$t('message.VoucherIndex.modal.create_or_update.tabs.apply')}}
                    </div>
                    <div :class="[ isTab === $constant.tab.third ? 'tab__active' : '', 'tab__item px-4 pb-2']" @click="choseTab($constant.tab.third)">
                        {{$t('message.VoucherIndex.modal.create_or_update.tabs.list_voucher')}}
                    </div>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.first">
                            <div class="row mx-4 my-4 pb-4 mt-1 border-bottom-dotted-2">
                                <div class="col-7 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.VoucherIndex.modal.create_or_update.code')}}
                                            <div class="container-circle tooltip-left-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{$t('message.VoucherIndex.modal.create_or_update.tooltip.code')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="voucher.code"  
                                                :placeholder="$t('message.VoucherIndex.modal.create_or_update.placeholder.code')" 
                                                class="form-control input-custom pl-0" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.VoucherIndex.modal.create_or_update.name')}} 
                                            <i class="text-danger">*</i>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <input type="text" maxlength="100" v-model.trim="voucher.name"  
                                                :placeholder="$t('message.VoucherIndex.modal.create_or_update.placeholder.name')" 
                                                class="form-control pl-0 input-custom" autocomplete="off">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.VoucherIndex.modal.create_or_update.value_voucher')}} 
                                            <i class="text-danger">*</i> 
                                            <div class="container-circle tooltip-left-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{$t('message.VoucherIndex.modal.create_or_update.tooltip.value')}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <InputNumberFormat :readonly="this.notEdit" :notBorder="true" 
                                                class="input-custom min-w-100 mb-0 bg-transparent"
                                                v-model="voucher.value"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3 pt-2">
                                        <div class="col-4 d-flex align-items-center text-bold">
                                            {{$t('message.VoucherIndex.modal.create_or_update.status')}}
                                        </div>
                                        <div class="col-3 d-flex align-items-center pl-0">
                                            <label class="container-radio m-0 flex-1 min-w-100">
                                                {{$t('message.VoucherIndex.modal.create_or_update.status_active')}}
                                                <input type="radio" name="voucherStatus" v-model="voucher.status" :value="this.$constant.statusActive" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-5 d-flex align-items-center pl-0">
                                            <label class="container-radio m-0 flex-1 min-w-100">
                                                {{$t('message.VoucherIndex.modal.create_or_update.status_inactive')}}
                                                <input type="radio" name="voucherStatus" v-model="voucher.status" :value="this.$constant.status_draft" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-5 pr-0">
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">
                                            {{$t('message.VoucherIndex.modal.create_or_update.categories')}}
                                            <div class="container-circle tooltip-right-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{ $t('message.VoucherIndex.modal.create_or_update.tooltip.categories') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="row prd-search-box1 mx-0" :class="this.notEdit ? 'cursor-ban' : ''">
                                                <template v-if="this.voucher.categories?.length == 0">
                                                    <div class="col-10 area_obj_selected d-flex align-items-center pl-0 text-gray">
                                                        {{$t('message.VoucherIndex.modal.create_or_update.choose_categories')}}
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <div class="col-10 area_obj_selected p-0" v-if="!selectAllCategories">
                                                        <template v-for="o in this.voucher.categories" :key="o.id">
                                                            <div class="cate_selected d-flex align-items-center p-2 my-1">
                                                                {{ $t('message.VoucherIndex.modal.create_or_update.group') }}: {{ o.name }}
                                                                <i class="fa fa-times ml-2 text-gray" @click="removeCategory(o.id)" v-if="!this.notEdit"></i>
                                                                <i class="fa fa-times ml-2 text-gray" v-else></i>
                                                            </div>
                                                        </template>
                                                    </div>
                                                    <div class="col-10 area_obj_selected p-0" v-else>
                                                        <div class="cate_selected d-flex align-items-center p-2 my-1">
                                                            {{$t('message.VoucherIndex.modal.create_or_update.all_categories')}}
                                                            <i class="fa fa-times ml-2 text-gray" @click="removeAllCategory()" v-if="!this.notEdit"></i>
                                                            <i class="fa fa-times ml-2 text-gray" v-else></i>
                                                        </div>
                                                    </div>
                                                </template>
                                                <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                    <button class="listIcon text-gray border-none" @click="showSelectCategories()" v-if="!this.notEdit">
                                                        <i class="fa fa-list"></i>
                                                    </button>
                                                    <button class="listIcon text-gray border-none" v-else>
                                                        <i class="fa fa-list"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">
                                            {{ $t('message.VoucherIndex.modal.create_or_update.products') }}
                                            <div class="container-circle tooltip-right-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{ $t('message.VoucherIndex.modal.create_or_update.tooltip.products') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <ProductUnitsSearch @selectProductUnit="onSelectProductUnit" :readonly="this.notEdit" :label="'products'" :normal="true" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.products')" :defaultValue="this.voucher.products" ref="ProductUnitsSearch"></ProductUnitsSearch>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-5 d-flex align-items-center text-bold">
                                            {{ $t('message.VoucherIndex.modal.create_or_update.total_price') }}
                                            <div class="container-circle tooltip-right-center">
                                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                                <div class="content-circle font-weight-normal">
                                                    {{ $t('message.VoucherIndex.modal.create_or_update.tooltip.total_price') }}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="form-group mb-0">
                                                <InputNumberFormat :readonly="this.notEdit" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"
                                                v-model="voucher.minPrice"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-12 d-flex align-items-center position-relative">
                                            <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                            <input type="text" name="note" id="note" 
                                            v-model.trim="voucher.note" class="form-control input-custom pl-4" 
                                            autocomplete="off" 
                                            :placeholder="$t('message.VoucherIndex.modal.create_or_update.placeholder.note')">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-4">
                                <div class="col-lg-12 flex-start p-0">
                                    <label class="container-checkbox d-flex">
                                        {{$t('message.VoucherIndex.modal.create_or_update.apply_multiple_voucher')}} 
                                        <div class="container-circle tooltip-left-center">
                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                            <div class="content-circle font-weight-normal">
                                                {{ $t('message.VoucherIndex.modal.create_or_update.tooltip.apply_multiple_voucher') }}
                                            </div>
                                        </div>
                                        <!-- <input type="checkbox" class="chkType" v-model="voucher.applyMultipleVoucher" :checked="voucher.applyMultipleVoucher == this.$constant.statusCheckAll ? true : false"> -->
                                        <input type="checkbox" class="chkType" v-model="this.checked" @change="changeChecked">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-4">
                                <div class="col-lg-12 flex-end p-0">
                                    <AppPermission :permission="this.$permissions.voucher.update?? '' ">
                                        <button type="button" class="btn btn-save mr-2" @click="updateVoucher()" v-if="isEdit">
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.VoucherIndex.modal.create_or_update.button.save')}}
                                        </button>
                                    </AppPermission>
                                    <AppPermission :permission="this.$permissions.voucher.create?? '' ">
                                        <button type="button" class="btn btn-save mr-2" @click="submitVoucher()" v-if="!isEdit">
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.VoucherIndex.modal.create_or_update.button.save')}}
                                        </button>
                                        <!-- <button type="button" class="btn btn-save mr-2" @click="submitVoucher()" v-else>
                                        <i class="fa fa-save mr-1"></i>
                                        Lưu
                                        </button> -->
                                    </AppPermission>
                                    <!-- <button type="button" class="btn btn-secondary text-white mr-2" @click="hide()"></button> -->
                                   
                                    <button type="button" class="btn btn-secondary text-white mr-2" @click="hide()">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.VoucherIndex.modal.create_or_update.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.second">
                            <div class="row mx-4 my-4 pb-2 mt-1 border-bottom-dotted-2">
                                <div class="col-12 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-12 d-flex align-items-center text-bold"> {{ $t('message.VoucherIndex.modal.create_or_update.apply_time') }}</div>
                                    </div>
                                    <div class="row mb-3 pt-2">
                                        <div class="col-2 d-flex align-items-center">
                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.effect') }}
                                                <input type="radio" name="applyFrom" v-model="voucher.applyTime" :value="this.$constant.statusActive" class="mr-2">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                        <div class="col-4">
                                            <div class="row p-0">
                                                <div class="col-5 p-0"><input type="date" v-model="voucher.startDate" class="form-control input-custom px-0" autocomplete="off"></div>
                                                <div class="col-2 p-0 d-flex align-items-center justify-content-center">
                                                    {{ $t('message.VoucherIndex.modal.create_or_update.to') }}
                                                </div>
                                                <div class="col-5 p-0"><input type="date" v-model="voucher.endDate" class="form-control input-custom px-0" autocomplete="off"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-4 pb-2 mt-1">
                                <div class="col-12 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-12 d-flex align-items-center text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.apply_area') }}</div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.all_system') }}
                                                                <input type="radio" :disabled="this.notEdit" v-model="voucher.isFullBranch" :value="$constant.statusCheckAll" @click="clearDataBranches()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.branch') }}
                                                                <input type="radio" v-model="voucher.isFullBranch" :value="$constant.statusCheckAPart" :disabled="this.notEdit">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8" :class="this.notEdit ? 'cursor-ban' : ''">
                                                            <q-select v-model="voucher.listBranch" :readonly="voucher.isFullBranch != $constant.statusCheckAPart || this.notEdit" :defaultOptions="branches" :multiple="true" :placeholder="$t('message.ModalFileUploadProducts.titlePlaceHolderSelectBranch')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.all_customer') }}
                                                                <input type="radio" :disabled="this.notEdit" v-model="voucher.isFullUserCustomer" :value="$constant.statusCheckAll" @click="clearDataCustomerGroup()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold pr-0">{{ $t('message.VoucherIndex.modal.create_or_update.customer_group') }}
                                                                <input type="radio" v-model="voucher.isFullUserCustomer" :value="$constant.statusCheckAPart" :disabled="this.notEdit">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8" :class="this.notEdit ? 'cursor-ban' : ''">
                                                            <q-select v-model="voucher.listCustomerGroup" :readonly="voucher.isFullUserCustomer != $constant.statusCheckAPart || this.notEdit" :defaultOptions="customerGroups" :multiple="true" :placeholder="$t('message.PriceListsIndex.modal.chooseCustomerGroup')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-6">
                                            <div class="row mt-2">
                                                <div class="col-12">
                                                    <div class="row">
                                                        <div class="col-12">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.all_created_by') }}
                                                                <input type="radio" :disabled="this.notEdit" v-model="voucher.isFullUserCreate" :value="$constant.statusCheckAll" @click="clearDataUserCreate()">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8"></div>
                                                    </div>
                                                </div>
                                                <div class="col-12 mt-3">
                                                    <div class="row">
                                                        <div class="col-4 d-flex align-items-center">
                                                            <label class="container-radio m-0 flex-1 min-w-100 text-bold">{{ $t('message.VoucherIndex.modal.create_or_update.created_by') }}
                                                                <input type="radio" v-model="voucher.isFullUserCreate" :value="$constant.statusCheckAPart" :disabled="this.notEdit">
                                                                <span class="checkmark"></span>
                                                            </label>
                                                        </div>
                                                        <div class="col-8" :class="this.notEdit ? 'cursor-ban' : ''">
                                                            <q-select v-model="voucher.listUserCreate" :readonly="voucher.isFullUserCreate != $constant.statusCheckAPart || this.notEdit" :defaultOptions="users" :multiple="true" :placeholder="$t('message.common.selectCreatorApplied')" :cardSettingPrice="true"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 my-4">
                                <div class="col-lg-12 flex-end p-0">
                                    <AppPermission :permission="this.$permissions.voucher.update ?? '' ">
                                        <button type="button" class="btn btn-save mr-2" @click="updateVoucher()" v-if="isEdit">
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.VoucherIndex.modal.create_or_update.button.save')}}
                                        </button>
                                        <button type="button" class="btn btn-save mr-2" @click="submitVoucher()" v-else>
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.VoucherIndex.modal.create_or_update.button.save')}}
                                        </button>
                                    </AppPermission>
                                    <button type="button" class="btn btn-secondary text-white mr-2" @click="hide()">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.VoucherIndex.modal.create_or_update.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab" v-if="isTab === $constant.tab.third">
                            <div class="row mx-4 p-0">
                                <div class="col-4 mt-4 d-flex align-items-center pl-0">
                                    <template v-if="this.arrayChecked?.length > 0">
                                        <span>
                                            {{ $t('message.common.numberVoucherSelected', {number: this.arrayChecked?.length}) }}
                                        </span>
                                        <a href="javascript:void(0);" class="icon-remove ml-2 text-gray" @click="removeChecked()"><i class="fa fa-times p-1 text-gray"></i></a> 
                                    </template>
                                </div>
                                <div class="col-8 mt-4 d-flex justify-content-end pr-0">
                                    <div class="dropdown" v-if="this.arrayChecked?.length > 0 && listVoucher?.length > 0">
                                        <!-- <template> -->
                                            <button v-if="this.$permissions.voucher.update || this.$permissions.voucher.releaseVoucher" class="btn btn-save dropdown-toggle pr-4" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                 <i class="fa fa-ellipsis-v px-2"></i>
                                                 {{ $t('message.VoucherIndex.modal.create_or_update.button.action') }}
                                            </button>
                                             <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                <AppPermission :permission="this.$permissions.voucher.releaseVoucher ?? '' ">
                                                    <a class="dropdown-item" @click="releaseVouchers()">
                                                        <i class="fa fa-check-square mr-2"></i>
                                                        {{ $t('message.VoucherIndex.modal.create_or_update.button.release_voucher') }}
                                                    </a>
                                                </AppPermission>
                                                <!-- <a class="dropdown-item">
                                                    <i class="fa fa-barcode mr-2"></i>In mã voucher
                                                </a> -->
                                                <!-- <a class="dropdown-item" @click="returnVouchers()">
                                                    <i class="fa fa-reply-all mr-2"></i>Trả voucher
                                                </a>  -->
                                                <!-- <AppPermission :permission="this.$permissions.voucher.remove?? '' "> -->
                                                <!-- Chỗ này chị minh bắt đổi vì nếu tích chọn quyền cập nhật thì vẫn đc hiển thị button hủy -->
                                                <AppPermission :permission="this.$permissions.voucher.update?? '' "> 
                                                    <a class="dropdown-item" @click="cancelVoucher()">
                                                        <i class="fa fa-trash-alt mr-2"></i>
                                                        {{ $t('message.VoucherIndex.modal.create_or_update.button.cancel_voucher') }}
                                                    </a>
                                                </AppPermission>
                                            </div>
                                        <!-- </template> -->
                                       
                                    </div>
                                    <AppPermission :permission="[this.$permissions.voucher.update ?? '', this.$permissions.voucher.create ?? '']">
                                        <button class="btn btn-save mr-2" @click="createVoucherCode()">
                                            <i class="fa fa-plus mr-2"></i>
                                            {{ $t('message.VoucherIndex.modal.create_or_update.button.voucher') }}
                                        </button>
                                        <button class="btn btn-save mr-2" @click="createVoucherCodeList()">
                                            <i class="fa fa-plus mr-2"></i>
                                            {{ $t('message.VoucherIndex.modal.create_or_update.button.add') }}
                                        </button>
                                        <button class="btn btn-save" @click="importVoucher">
                                            <i class="fa fa-file-import mr-2"></i>
                                            {{ $t('message.VoucherIndex.modal.create_or_update.button.import') }}
                                        </button>
                                    </AppPermission>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 mt-3">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <thead>
                                            <tr class="backgroup__detail table-header border-1">
                                                <th scope="col" class="pl-2 cell-check"><input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)"></th>
                                                <th scope="col" class="pl-2 cell-code-coupon">{{ $t('message.VoucherIndex.modal.create_or_update.table.code') }}</th>
                                                <th scope="col" class="pl-2 cell-auto"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon">{{ $t('message.VoucherIndex.modal.create_or_update.table.release_date') }}</th>
                                                <th scope="col" class="pl-2 cell-status-coupon">{{ $t('message.VoucherIndex.modal.create_or_update.table.end_date') }}</th>
                                                <th scope="col" class="pl-2 cell-status-coupon">{{ $t('message.VoucherIndex.modal.create_or_update.table.use_date') }}</th>
                                                <th scope="col" class="pl-2 cell-status-coupon"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon">{{ $t('message.VoucherIndex.modal.create_or_update.table.status') }}</th>
                                            </tr>
                                            <tr class="table-header-search border border-bottom-0">
                                                <th scope="col" class="pl-2 cell-check"></th>
                                                <th scope="col" class="pl-2 cell-code-coupon position-relative">
                                                    <input type="text" v-model="this.keyword" 
                                                    :placeholder="$t('message.VoucherIndex.modal.create_or_update.table.placeholder.search')" 
                                                    class="form-control input-custom bg-transparent pr-4 pl-0" autocomplete="off" @input="getCouponDetails(1)">
                                                    <i class="fa fa-search mr-2 text-gray"></i>
                                                </th>
                                                <th scope="col" class="pl-2 cell-auto"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon"></th>
                                                <th scope="col" class="pl-2 cell-status-coupon position-relative">
                                                    <SelectSearch :placeholder="$t('message.VoucherIndex.modal.create_or_update.table.placeholder.choose_method')" :readonly="true" :modelValue="statusVoucher" :defaultOption="optionStatus" @update:modelValue="doFilterSearch" :label="'statusVoucher'"/>
                                                </th>
                                            </tr>
                                        </thead>
                                    </table>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 mt-0 auto-scrollable">
                                <div class="col-12 p-0">
                                    <table class="w-100">
                                        <tbody>
                                            
                                            <template v-if="this.listVoucher?.length > 0">
                                                <tr class="table-header border" v-for="voucher in listVoucher" :key="voucher.id">
                                                    <th scope="col" class="pl-2 cell-check"><input type="checkbox" v-model="voucher.checked" @change.stop="countChecked(voucher.id)" class="prdChecked"
                                                    :id="voucher.id"  :value="voucher.code" :checked="isExistInArrayChecked(voucher, this.arrayChecked)"></th>
                                                    <th scope="col" class="pl-2 cell-code-coupon position-relative font-weight-normal" v-text="voucher.code"></th>
                                                    <th scope="col" class="pl-2 cell-auto font-weight-normal"></th>
                                                    <th scope="col" class="pl-2 cell-status-coupon font-weight-normal" v-text="d(voucher.releaseDate)"></th>
                                                    <th scope="col" class="pl-2 cell-status-coupon font-weight-normal" v-text="d(voucher.endDate)"></th>
                                                    <th scope="col" class="pl-2 cell-status-coupon font-weight-normal" v-text="d(voucher.useDate)"></th>
                                                    <th scope="col" class="pl-2 cell-status-coupon font-weight-normal"></th>
                                                    <th scope="col" class="pl-2 cell-status-coupon font-weight-normal" v-text="voucher.status"></th>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                    <div class="no-data border" v-if="this.listVoucher?.length == 0">
                                        <span>{{ $t('message.common.notFound') }}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 my-3">
                                <div class="col-12 pl-0">
                                    <Pagination :value="paginateDetail" :record="numRecords" @change="pageChange"/>
                                </div>
                            </div>
                            <div class="row mx-4 p-0 my-3">
                                <div class="col-11 flex-end align-items-center p-0">
                                    {{$t('message.VoucherIndex.modal.create_or_update.table.number_voucher')}}:
                                </div>
                                <div class="col-1 p-0 d-flex align-items-center pr-0">
                                    <select :value="numRecords" name="number_records" id="number_records" class="number_records mt-0" @change="changeRecords">
                                        <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="150">150</option>
                                        <option value="200">200</option>
                                    </select>
                                </div>
                                <!-- <div class="col-2 flex-end align-items-center pr-0">
                                    <button type="button" class="btn btn-secondary text-white mx-1">
                                        <i class="fa fa-file-export" aria-hidden="true"></i>
                                        Xuất file
                                    </button>
                                </div> -->
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <VoucherCodeListCreate ref="VoucherCodeListCreate" @createVoucherCode="reloadVoucher" />
    <VoucherCodeCreate ref="VoucherCodeCreate" @createVoucherCode="reloadVoucher"/>
    <ModalConfirm ref="ModalConfirm" :title="this.$t('message.VoucherIndex.modal.create_or_update.alert.title_confirm')" :content="this.$t('message.VoucherIndex.modal.create_or_update.alert.content_confirm')" @confirm="confirmCreateVoucher"/>
    <SelectCategories ref="SelectCategories" @update:modelValue="updateListCategories"/>
    <ImportVoucher ref="ImportVoucher" @input="reloadVoucher"/>
    <ReleaseVouchers ref="ReleaseVouchers" @reloadVoucher="this.reloadVoucher" @removeChecked="removeChecked" />
    <ReturnVouchers ref="ReturnVouchers" @reloadVoucher="this.reloadVoucher"  @removeChecked="removeChecked" />
    <ModalCancelVoucher ref="ModalCancelVoucher" @reloadVoucher="this.reloadVoucher"  @removeChecked="removeChecked" />
</template>
<script>
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import {$post, $alert, $get, removeVietnameseTones} from "@/lib/utils";
import ModalConfirm from "@/components/ModalConfirm";
import ProductUnitsSearch from "@/components/ProductUnitsSearch";
import SelectCategories from "@/components/SelectCategories";
import moment from 'moment';
import store from "@/store";
import QSelect from "@/components/QSelect";
// import modal
import VoucherCodeListCreate from "./VoucherCodeListCreate.vue";
import VoucherCodeCreate from "./VoucherCodeCreate.vue";
import ReleaseVouchers from "./ReleaseVouchers.vue";
import ReturnVouchers from "./ReturnVouchers.vue";
import ImportVoucher from "./ImportVoucher.vue";
import Pagination from "@/components/Pagination";
import ModalCancelVoucher from './ModalCancelVoucher';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "VoucherCreateUpdateModal",
    components: { SelectSearch, InputNumberFormat, QSelect, VoucherCodeListCreate, VoucherCodeCreate, ModalConfirm, SelectCategories, ImportVoucher, ProductUnitsSearch, Pagination, ReleaseVouchers, ReturnVouchers, ModalCancelVoucher,
    AppPermission
    },
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            isEdit: false,
            type: null,
            isTab: this.$constant.tab.first,
            voucher: {},
            listVoucher: [],
            listCategories: [],
            optionStatus: this.$constant.optionStatusCoupon,
            filter: {
                status: '',
            },
            numRecords: 10,
            branches: globalConfig.branches,
            customerGroups: globalConfig.cusGroup,
            users: globalConfig.users,
            selectAllCategories: false,
            statusVoucher: null,
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
            },
            keyword: null,
            arrayChecked: [],
            checkedAll: false,
            checked: false,
            notEdit: false,
        }
    },
    created() {
    },
    methods: {
        show (data = null, type= null) {
            this.clearData();
            if (data) {
                this.type = type;
                this.isEdit = type === "copy" ? false : true;
                this.voucher = {...data};
                this.voucher.code = !this.isEdit ? null : this.voucher.code;
                this.voucher.id = !this.isEdit ? null : this.voucher.id;
                this.voucher.categories = this.voucher.categories ?? [];
                this.voucher.products = this.voucher.products ?? [];
                this.voucher.status = this.voucher.statusOrigin;
                this.voucher.applyTime = this.$constant.statusActive,
                this.notEdit = this.voucher.notEdit && this.isEdit ? true : false;
                this.getCouponDetails();
                if(data.categories && !data.isFullCategories){
                    let arrCate = [];
                    data.categories.forEach(cate => {
                        arrCate.push(cate.categoryId);
                    })
                    this.$refs.SelectCategories.updateArrayChecked(arrCate, false);
                }else if(data.isFullCategories){
                    this.$refs.SelectCategories.updateArrayChecked([], true);
                }
                if(this.$refs.ProductUnitsSearch){
                    this.$refs.ProductUnitsSearch.listItemSelected = this.voucher.products ?? [];
                }
                if(data.applyMultipleVoucher){
                    this.voucher.applyMultipleVoucher = this.$constant.statusCheckAll;
                    this.checked = true;
                }
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide () {
            this.clearData();
            this.$emit('reloadData');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        changeChecked(){
            this.voucher.applyMultipleVoucher = (this.voucher.applyMultipleVoucher == this.$constant.statusCheckAll) ? this.$constant.statusCheckAPart : this.$constant.statusCheckAll;
            this.checked = (this.voucher.applyMultipleVoucher == this.$constant.statusCheckAll) ? true : false ;
        },
        clearData(){
            this.voucher = {
                status: this.$constant.statusActive,
                code: null,
                value: null,
                maxPrice: null,
                startDate: moment().format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
                categories: [],
                products: [],
                name: null,
                minPrice: null,
                note: null,
                id: null,
                applyMultipleVoucher: this.$constant.statusCheckAPart,
                isFullBranch: this.$constant.statusCheckAll,
                isFullUserCreate: this.$constant.statusCheckAll,
                isFullUserCustomer: this.$constant.statusCheckAll,
                listBranch: [],
                listUserCreate: [],
                listCustomerGroup: [],
                applyTime: this.$constant.statusActive,
            };
            this.arrayChecked = [];
            this.isTab = this.$constant.tab.first;
            this.statusVoucher = null;
            this.keyword = null;
            if(this.$refs.SelectCategories){
                this.$refs.SelectCategories.arrayChecked = [];
            }
            this.isEdit = false;
            if(this.$refs.ProductUnitsSearch){
                this.$refs.ProductUnitsSearch.listItemSelected = [];
            }
            this.checked = false;
            this.notEdit = false;
        },
        choseTab(tab) {
            if (tab === this.$constant.tab.third) {
                if (!this.voucher.id || this.voucher.id == null) {
                    this.$refs.ModalConfirm.show();
                } else {
                    this.isTab = tab;
                }

            } else {
                this.isTab = tab;
            }
        },
        createVoucherCodeList(){
            this.$refs.VoucherCodeListCreate.show(this.voucher.id);
        },
        createVoucherCode(){
            this.$refs.VoucherCodeCreate.show(this.voucher.id);
        },
        reloadVoucher(){
            this.getCouponDetails();
        },
        async getCouponDetails(page = null) {
            let res = await $post('/v1/coupons/get-coupon-details', {
                couponVoucherId: this.voucher.id,
                numberRecord: this.numRecords,
                status: this.statusVoucher,
                page: page,
                keyword: this.keyword,
            });
            if (res.result) {
                this.listVoucher = res.result.data;
                this.paginateDetail.currentPage = res.result.current_page;
                this.paginateDetail.lastPage = res.result.last_page;
                this.paginateDetail.totalRecord = res.result.total;
                this.isCheckedAll();
            }
        },
        async changeRecords(v) {
            this.numRecords = v.target.value;
            await this.getCouponDetails(1);
        },
        async pageChange(page) {
            await this.getCouponDetails(page);
        },
        async doFilterSearch(v){
            this.statusVoucher = v.model ?? null;
            await this.getCouponDetails(1);
        },
        async confirmCreateVoucher() {
            let infoCreate = await this.submitVoucher(this.$constant.fromConfirm);
            if (infoCreate.status) {
                this.isTab = this.$constant.tab.third;
                this.voucher.id = infoCreate.id;
            }
        },
        async submitVoucher(type = null){
            this.voucher.selectAllCategories = this.selectAllCategories;
            this.voucher.startDate = moment(this.voucher.startDate).format('YYYY-MM-DD 00:00:00');
            this.voucher.endDate = moment(this.voucher.endDate).format('YYYY-MM-DD 23:59:59');
            const res = await $post('/v1/vouchers/store', {...this.voucher});
            if (res.code === 422) {
                this.voucher.startDate = moment(this.voucher.startDate).format('YYYY-MM-DD');
                this.voucher.endDate = moment(this.voucher.endDate).format('YYYY-MM-DD');
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                const info = {
                    status: false,
                }
                return info;
            } else {
                $alert({code: 200, message: this.isEdit ? this.$t('message.VoucherIndex.modal.create_or_update.alert.update_success') : this.$t('message.VoucherIndex.modal.create_or_update.alert.create_success')});
                if (type != this.$constant.fromConfirm) {
                    this.$emit('reloadData');
                    this.clearData();
                    window.jQuery(this.$refs.modal).modal('hide');
                }
                const info = {
                    status: true,
                    id: res.result.id
                }
                return info;
            }
        },
        async updateVoucher (type = null)
        {
            this.voucher.selectAllCategories = this.selectAllCategories;
            this.voucher.startDate = moment(this.voucher.startDate).format('YYYY-MM-DD 00:00:00');
            this.voucher.endDate = moment(this.voucher.endDate).format('YYYY-MM-DD 23:59:59');
            const res = await $post('/v1/vouchers/update', {...this.voucher});
            if (res.code === 422) {
                this.voucher.startDate = moment(this.voucher.startDate).format('YYYY-MM-DD');
                this.voucher.endDate = moment(this.voucher.endDate).format('YYYY-MM-DD');
                let errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                const info = {
                    status: false,
                }
                return info;
            } else {
                $alert({code: 200, message: this.isEdit ? this.$t('message.VoucherIndex.modal.create_or_update.alert.update_success') : this.$t('message.VoucherIndex.modal.create_or_update.alert.create_success')});
                if (type != this.$constant.fromConfirm) {
                    this.$emit('reloadData');
                    this.clearData();
                    window.jQuery(this.$refs.modal).modal('hide');
                }
                const info = {
                    status: true,
                    id: res.result.id
                }
                return info;
            }
        },
        onInputValueCoupon(){
            if(this.voucher.value > 100){
                this.voucher.value = 100;
            }
        },
        clearDataBranches(){
            this.voucher.listBranch = [];
        },
        clearDataUserCreate(){
            this.voucher.listUserCreate = [];
        },
        clearDataCustomerGroup(){
            this.voucher.listCustomerGroup = [];
        },
        showSelectCategories() {
            this.$refs.SelectCategories.show();
        },
        removeCategory(id){
            this.voucher.categories.splice(this.voucher.categories.findIndex(v => v.id == id), 1);
            let arr = [];
            this.voucher.categories.forEach(item => {
                arr.push(item.id);
            })
            this.$refs.SelectCategories.arrayChecked = arr;
        },
        removeAllCategory(){
            this.voucher.categories = [];
            this.$refs.SelectCategories.arrayChecked = [];
        },
        updateListCategories(value){
            this.voucher.categories = value.data;
            this.selectAllCategories = value.selectAll;
        },
        importVoucher(){
            this.$refs.ImportVoucher.show(this.voucher.id);
        },
        async onSelectProductUnit(value){
            if(value.label == 'products' && value.data){
                this.voucher.products = value.data;
            }
        },
        isCheckedAll(){
            let getChecked = 0;
            this.listVoucher.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.listVoucher.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        onCheckedAll(value) {
            if(value == true){
                this.listVoucher.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.listVoucher.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        releaseVouchers(){
            this.$refs.ReleaseVouchers.show(this.arrayChecked);
        },
        returnVouchers(){
            this.$refs.ReturnVouchers.show(this.arrayChecked);
        },
        cancelVoucher(){
             this.$refs.ModalCancelVoucher.show(this.arrayChecked);
        }
    },
    beforeUnmount() {
        this.clearData();
    },
    unmounted() {
        this.clearData();
    }
}
</script>
<style scoped>
a:hover {
    cursor: pointer !important;
}
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}

.custom__list-tab {
    justify-content: flex-start;
    align-items: end;
    padding-bottom: 8px;
}
.tab__active {
    border-bottom: 2px solid #2FA1A1 !important;
}
.tab__item {
    /* min-width: 150px; */
    cursor: pointer;
    padding: 0 15px;
    border-bottom: 2px solid #f8f9fa;
}

.input:focus-visible {
    border: none !important;
}

.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.currencyUnit{
    position: absolute;
    right: 0;
    top: 20%;
}
.fa-search{
    position: absolute;
    right: 0;
    top: 40%;
}
.table-header th{
    height: 40px !important;
}
.table-header-search{
    height: 50px !important;
    background-color: #FEFCED;
}
.cell-check{
    width: 5%;
    min-width: 5%;
    max-width: 5%;
}
.cell-code-coupon{
    width: 20%;
    min-width: 20%;
    max-width: 20%;
}
.cell-release-coupon{
    width: 20%;
    min-width: 20%;
    max-width: 20%;
}
.cell-auto{
    min-width: 10%;
}
.cell-status-coupon{
    width: 12%;
    min-width: 12%;
    max-width: 12%;
}

.listIcon{
    outline: none !important;
    background: none !important;
    height: 20px;
    width: 25px;
}
.listIcon:hover{
    background-color: #e5e5e5 !important;
    outline: 0;
    box-shadow: none;
    border-radius: 50%;
}
.cate_selected{
    background: #e5e5e5 !important;
    min-height: 30px !important;
    width:fit-content !important;
}
.area_obj_selected{
    max-height: 100px !important;
    min-height: 35px !important;
    overflow-y: auto;
}
/* .area_obj_selected:hover{
    border-bottom: 2px solid #2fa1a1;
} */
.area_obj_selected::-webkit-scrollbar {
  display: none;
}

.area_obj_selected {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.dropdown-menu-content{
    width: 100vh;
    max-height: 20rem;
    max-width: 12rem;
    overflow: auto;
    z-index: 1000;
    transform: translate3d(15px, 0px, 0px) !important;
    background: white;
    position: absolute;
}
.fa-times:hover{
    background-color: #e5e5e5;
    box-shadow: none;
    border-radius: 999px;
}
.dropdown-toggle::after {
    position: absolute;
    right: 15px;
    top: 50% !important;
}
</style>