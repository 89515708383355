<template>
    <div class="row order-detail">
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>Quản lý phiếu đặt hàng</h1>
            </div>
            <TabNavigation @tabChange="tabChange"
                           ref="tab"
                           :tabs="tabs"/>
        </div>
    </div>

</template>

<script>
import {$get, TYPE_ORDER} from "@/lib/utils";
    import {markRaw} from "vue";
    import TabNavigation from "../../components/TabNavigation";
    import OrderTabOrderInfo from "./tabs/OrderTabOrderInfo";
    import OrderTabInvoiceHistory from "./tabs/OrderTabInvoiceHistory";
    import OrderTabPaymentHistory from "./tabs/OrderTabPaymentHistory";
    import ModalPrintOrder from "@/views/orders/ModalPrintOrder";

    import "./../../assets/css/order.scss";

    export default {
        name: "OrderForm",
        components: {TabNavigation, ModalPrintOrder},
        data() {
            return {
                tabs: [
                    {id: 'TabOrderInfo', name: 'Thông tin đặt hàng', component: markRaw(OrderTabOrderInfo)},
                    {id: 'TabInvoiceHistory', name: 'Lịch sử hóa đơn' , component: markRaw(OrderTabInvoiceHistory)},
                    {id: 'TabPaymentHistory', name: 'Lịch sử thanh toán' , component: markRaw(OrderTabPaymentHistory)}
                ],
                printType: '',
                entry: {},
                TYPE_ORDER
            }
        },
        mounted() {
            document.title = 'Chỉnh sửa phiếu đặt hàng | OmiPos';
        },
        methods: {
            tabChange(tab) {
                this.currentTab = tab;
                if (this.$route.query.id) {
                    this.load();
                } else {
                    this.$refs.tab.setTabData({
                        isDataLoaded: true
                    })
                }
            },
            async load() {
                const res = await $get('/v1/orders/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }

                if (!res.data.entry || !res.data.entry.invoices.length) {
                    const indexInvoice = this.tabs.findIndex(el => (el.id === "TabInvoiceHistory"));
                    if (indexInvoice !== -1) {
                        this.tabs.splice(indexInvoice, 1);
                    }
                }

                if (!res.data.entry || !res.data.entry.cash_flows.length) {
                    const indexPayment = this.tabs.findIndex(el => (el.id === "TabPaymentHistory"));
                    if (indexPayment !== -1) {
                        this.tabs.splice(indexPayment, 1);
                    }
                }
                this.entry = res.data.entry;

                this.$refs.tab.setTabData({
                    entry: res.data.entry,
                    orderItems: res.data.entry.order_items || [],
                    listStatus: res.data.list_status,  
                    shopee_value: res.data.shopee_value,
                    omipos_value: res.data.omipos_value,
                    shopee_name: res.data.shop_name || null,
                    delivery: res.data.delivery || null,
                    receiverLocation: res.data.receiver_location || [],
                    isDataLoaded: true,
                    shopeeValue: res.data.shopee_value || null,
                    invoices: res.data.entry.invoices || [],
                    orderCashFlows: res.data.entry.cash_flows || [],
                });
            },
        }
    }
</script>

<style scoped lang="scss">
</style>
