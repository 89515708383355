<template>
    <div class="wrap">
        <div class="header">
            <div class="mt-3">
                <img src="/img/logo-text.png" alt="">
            </div>
            <h3 class="mt-2">PHIẾU GIAO HÀNG</h3>
            <div>
                <span v-if="entry.type == TYPE_INVOICE">{{$t('message.InvoiceIndex.code')}}:</span>
                <span v-else>{{$t('message.InvoiceIndex.order_code')}}:</span>

                <span>&nbsp;</span>
                <span v-text="entry.code"></span>
            </div>
            <div v-if="delivery.code">
                <BarcodeGenerator
                        :value="delivery.code"
                        :width="2"
                        :height="50"
                        :fontSize="20"
                        :margin="10"
                ></BarcodeGenerator>
            </div>
            <div>
                <span>Mã vận đơn:</span>
                <span>&nbsp;</span>
                <span class="font-weight-bold" v-text="delivery.code"></span>
            </div>
            <div>
                <span>Đơn vị giao hàng:</span>
                <span>&nbsp;</span>
                <span v-text="delivery.transporter ? delivery.transporter.name : ''"></span>
            </div>
            <div>
                <span>Dịch vụ:</span>
                <span>&nbsp;</span>
                <span v-text="delivery.serviceType ? delivery.serviceType.name : ''"></span>
            </div>
        </div>
        <div class="content">
            <div class="sender mt-2">
                <div class="font-weight-bold">
                    <span class="text-underline">Người gửi:</span>
                    <span>&nbsp;</span>
                    <span v-text="delivery.branch ? (delivery.branch.name + ' - ' + delivery.branch.phone) : ''"></span>
                </div>
                <div>
                    <span v-text="delivery.branch ? delivery.branch.addressDecode : ''"></span>
                </div>
            </div>

            <div class="recipient mt-3">
                <div class="font-weight-bold">
                    <span class="text-underline">Người nhận:</span>
                    <span>&nbsp;</span>
                    <span v-text="delivery.receiverName"></span>
                    <span> - </span>
                    <span v-text="delivery.receiverPhone"></span>
                </div>
                <div>
                    <span v-text="delivery.addressDecode"></span>
                </div>
            </div>

            <div class="total-amount">
                <span class="text-underline">{{$t('message.InvoiceIndex.tabs.delivery.total_receive')}}:</span>
                <span>&nbsp;</span>
                <span class="price" v-text="n(delivery.price) || '--'"></span>
                <span class="price">&nbsp;VND</span>
            </div>

            <div class="description font-weight-bold">
                <span class="text-underline">Lưu ý khi giao hàng:</span>
                <span>&nbsp;</span>
                <span v-text="delivery.note"></span>
            </div>

            <div class="invoice-order-info">
                <div class="text-underline font-weight-bold">Thông tin đơn hàng:</div>
                <div class="pl-2">
                    <span>Khối lượng: </span>
                    <span v-text="(delivery.weight || '') + ' ' + (delivery.weightUnit || '')"></span>
                </div>
                <div class="pl-2">
                    <span>Tổng số sản phẩm: </span>
                    <span v-text="entry.items ? entry.items.length : 0"></span>
                </div>
            </div>

            <div class="table-items" v-if="entry.items && entry.items.length">
                <table class="table table-bordered">
                    <thead>
                    <tr class="text-center">
                        <th style="width: 7%">{{$t('message.common.STT')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodName')}}</th>
                        <th style="width: 7%">{{$t('message.common.shortQuantity')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(item, index) in entry.items" :key="item.id">
                        <td class="text-center" v-text="index + 1"></td>
                        <td>
                            <span v-text="item.name"></span>
                            <span v-if="item.productUnit" v-text="' (' + item.productUnit.unitName + ')'"></span>
                        </td>
                        <td class="text-center" v-text="item.quantity"></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
    import {TYPE_INVOICE} from "../../lib/utils";
    import BarcodeGenerator from "../../components/products/BarcodeGenerator";

    export default {
        name: "ModalPrintDelivery",
        components: {BarcodeGenerator},
        props: ['data'],
        data() {
            return {
                entry: this.data,
                delivery: this.data.delivery || {},

                TYPE_INVOICE
            }
        }
    }
</script>

<style scoped lang="scss">
    .wrap {
        font-weight: 600;
        font-size: 16px;

        .header {
            text-align: center;
        }

        .content {
            text-align: left;

            .total-amount {
                margin: 20px 0;
                font-weight: bold;

                .price {
                    font-size: 24px;
                }
            }

            .invoice-order-info {
                margin-top: 10px;
            }

            .table-items  {
                margin-top: 20px;

                table, td, th {
                    border: 1px solid #000 !important;
                }
            }
        }
    }
</style>
