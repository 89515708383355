<template>
    <AppPermission :permission="this.$permissions.customers.data">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                <!-- <div class="col-md-2" id="col2"> -->
                    <div class="main-title">
                        <span>{{$t('message.customerIndex.title')}}</span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8 text-title">
                                    <label>{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select name="number_records" id="number_records" @change="onChange($event)" class="number_records">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Start group customer -->
                    <div class="card" id="GrpProduct">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-10">
                                    <label class="typeTitle">{{$t('message.customerIndex.customerGroup')}}</label>
                                </div>

                                <div class="col-1 pl-0 ml-0">
                                    <AppPermission :permission="this.$permissions.customerGroups.create">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showModal(1)">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                        </a>
                                    </AppPermission>
                                </div>
                                <!-- <div class="col-2">
                                    <a href="#" class="text-dark" id="linkIcon">
                                        <i class="fa fa-chevron-down dp-hide" id="gDown"></i>
                                        <i class="fa fa-chevron-up" id="gUp"></i>
                                    </a>
                                </div> -->
                            </div>
                            <!-- Tìm kiếm -->
                            <div class="row mx-2 my-2 py-2 ">
                                <div class="col-12">
                                    <div class="prd-search-box border-none">
                                        <SelectSearch :placeholder="$t('message.customerIndex.customerGroup')" :modelValue="params.customerGroupSearch" :defaultOption="customerGroupList" @update:modelValue="doFilterSearch" :label="'customerGroupSearch'"></SelectSearch>
                                        <!-- <treeselect placeholder="Nhóm khách hàng" slot="option-label" @select="searchCustomerGroup" :clearable="false" :show-count="true" :options="customerGroupList"/>       -->
                                        <AppPermission :permission="this.$permissions.customerGroups.update">
                                            <i v-if="params.customerGroupSearch" class="fa fa-pencil-alt ml-2 text-secondary"  @click="showModalCategoryEdit(params.customerGroupSearch)"></i>
                                        </AppPermission>
                                       
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                    <!-- End group customer -->

                    <!-- Start created at -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.createdAt')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('dBody', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="dBody">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1">
                                        <InputDateRangePicker :placeholder="$t('message.customerIndex.createdAt')"
                                            @input="search()"
                                            v-model="params.createdAt" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End created at -->

                    <!-- Start created by -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.createdBy')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('createdAt', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-2 py-2" id="createdAt">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.customerIndex.createdBy')" :modelValue="params.createdBy" :defaultOption="employeeList" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End created by -->

                    <!-- Start date of birth -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.birthDate')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('birthDay', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="birthDay">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1">
                                        <InputDateRangePicker :placeholder="$t('message.customerIndex.birthDate')" @input="search()"
                                            v-model="params.birth_day" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End date of birth -->

                    <!-- Start ngày giao dịch cuối -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.lastTransactionDate')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('lastTradingDay', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="lastTradingDay">
                            <div class="row">
                                <div class="col-12">
                                    <SelectDateTime :label="'lastDay'" @update:modelValue="doFilterTime"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End ngày giao dịch cuối -->

                     <!-- Start ngày giao dịch cuối -->
                     <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.totalSale')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('totalSale', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="totalSale">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card-body px-0 pt-0 pb-2" id="feeShippingBody">
                                        <div class="row">
                                            <div class="col-12 d-flex align-items-center">
                                                <span>{{ $t('message.TransporterIndex.fee_from') }}</span>
                                                <InputNumber v-model="params.feeShippingFrom" 
                                                :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                                class="mb-0 ml-1" :cardTransaction="true" @change="load()"/>
                                            </div>
                                        </div>
                                        <div class="row mt-2 mb-1">
                                            <div class="col-12 d-flex align-items-center">
                                                <span>{{ $t('message.TransporterIndex.fee_to') }}</span>
                                                <InputNumber v-model="params.feeShippingTo" 
                                                :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                                class="mb-0 ml-1" :cardTransaction="true" @change="load()"/>
                                            </div>
                                        </div>
                                        <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End ngày giao dịch cuối -->

                    <!-- Start tổng bán -->
                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">Chi nhánh tạo</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('branchCreated', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-2 py-2" id="branchCreated">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="'Chi nhánh tạo'" :modelValue="params.branchId" :defaultOption="branchesList" @update:modelValue="doFilterSearch" :label="'branchId'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- End tổng bán -->

                    <!-- Start nợ hiện tại -->
                    <!-- <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">Nợ hiện tại</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('currentDebt', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="currentDebt">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1">
                                        <input type="text" class="form-control" placeholder="Nợ hiện tại">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> -->
                    <!-- End nợ hiện tại -->

                    <!-- Start loại khách -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.customerIndex.type') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('typeCustomer', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="typeCustomer">
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.all') }}
                                        <input class="form-check-input" type="radio" @change="search()" v-model="params.typeCustomer" value="" name="typeCustomer" id="flexRadioDefault1" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.individual') }}
                                        <input class="form-check-input" type="radio" @change="search()" name="typeCustomer" v-model="params.typeCustomer" value="1" id="flexRadioDefault2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.company') }}
                                        <input class="form-check-input" type="radio" @change="search()" name="typeCustomer" v-model="params.typeCustomer" value="2" id="flexRadioDefault2" >
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End loại khách -->

                    <!-- Start giới tính -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.customerIndex.gender') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('gender', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="gender">
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.all') }}
                                        <input class="form-check-input" v-model="params.gender" @change="search()" value="" type="radio" name="gender" id="flexRadioDefault1" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.male') }}
                                        <input class="form-check-input" v-model="params.gender" @change="search()" value="1" type="radio" name="gender" id="flexRadioDefault2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{ $t('message.customerIndex.female') }}
                                        <input class="form-check-input" v-model="params.gender" @change="search()" value="0" type="radio" name="gender" id="flexRadioDefault2" >
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End giới tính -->

                    <!-- Start khu vực giao hàng -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.customerIndex.deliveryArea') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('deliveryArea', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-2 py-2" id="deliveryArea">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.customerIndex.placeholder.chooseArea')" :modelValue="params.address" :defaultOption="dataDistrictList.filter" @update:modelValue="doFilterSearch" :label="'address'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End khu vực giao hàng -->

                    <!-- Start khu vực giao hàng -->
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.customerIndex.status')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                        @click="showHide('status', 'dDown', 'dUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                        <i class="fa fa-chevron-up" id="dUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-2 py-0" id="status">
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{$t('message.customerIndex.all')}}
                                        <input class="form-check-input" type="radio" v-model="params.status" value=""  @change="search()" name="status" checked>
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{$t('message.customerIndex.active')}}
                                        <input class="form-check-input" type="radio" v-model="params.status" value="1" @change="search()" name="status">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <label class="container-radio ml-2">{{$t('message.customerIndex.inActive')}}
                                        <input class="form-check-input" type="radio" v-model="params.status" value="0" @change="search()" name="status">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End khu vực giao hàng -->

                    <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t('message.SaleReportByConsignment.branch') }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <q-select name="branchIDs" :default-options="branchesList" :multiple="true" v-model="params.branchId" :selectAll="true" :placeholder="$t('message.common.placeholder.branch')" @update:modelValue="search"/>
                            </div>
                        </div>
                    </div>
                </div>

                </div>

                <div class="mainRight">
                <!-- <div class="col-md-10" id="col10"> -->
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-5">
                                <!-- <div class="search-box">
                                    <i class="fa fa-search mr-2"></i>
                                    <input type="text" class="search-input"
                                      @keyup.enter="search()" v-model="params.keyword" placeholder="Theo mã, tên, điện thoại" />
                                </div> -->
                                <InputQuickSearch :placeholder="$t('message.customerIndex.placeholder.searchCustomer')" @filterSearch="changeQuickSearch" :customer="true"></InputQuickSearch>
                            </div>
                            <div class="col-7 d-flex justify-content-end">

                                <button type="button" class="btn btn-default" @click="clearFilter">
                                    <i class="fa fa-filter"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <AppPermission :permission="this.$permissions.customers.create">
                                    <button class="btn btn-save" type="button" id="dropdownMenuButton"
                                        style="padding:7px"
                                        @click="showModalAddInfo"
                                        aria-expanded="false">
                                        <i class="fa fa-plus px-2"></i>
                                        {{$t('message.customerIndex.button.addCustomer')}}
                                    </button>
                                </AppPermission>
                                <AppPermission :permission="[this.$permissions.customers.import,  this.$permissions.customers.export]">
                                    <button class="btn btn-save" type="button" id="dropdownMenuButton" 
                                        v-on:mouseleave="toggleSelectFile = false"
                                        style="padding:7px"
                                        v-on:mouseover="toggleSelectFile = true"
                                        aria-expanded="false">
                                        <i class="fa fa-file px-2"></i>
                                        {{$t('message.customerIndex.button.file')}}
                                        <div class="dropdown-menu dropdown-menu-right d-block mt-0"  v-if="toggleSelectFile" style="max-width: 300px">
                                            <div class="px-3">
                                                <AppPermission :permission="this.$permissions.customers.import ? this.$permissions.customers.import : ''">
                                                    <div class="label__file py-2" @click="showModalUploadFileExcel">
                                                        <span style="width: 30px;"><i class="fas fa-file-import px-2"></i></span>
                                                        {{$t('message.customerIndex.button.import')}}
                                                    </div>
                                                </AppPermission>
                                                <AppPermission :permission="this.$permissions.customers.export ? this.$permissions.customers.export : ''">
                                                    <div class="py-2" @click="selectOptionExport('exportExcel')">
                                                        <i class='fas fa-file-export px-2'></i>
                                                        {{$t('message.customerIndex.button.export')}}
                                                    </div>
                                                </AppPermission>
                                            </div>
                                        </div>
                                    </button>
                                </AppPermission>
                                <!-- <button class="btn btn-save" type="button" id="dropdownMenuButton"
                                    style="padding:7px"
                                    @click="showModalAddInfo"
                                    aria-expanded="false">
                                    <i class="fa fa-bars px-2"></i>
                                </button> -->
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="customers"></SelectField>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" />
                        </div>
                        <div class="row mt-3"></div>
                        <div class="row mx-0 my-2" id="divTable" v-if="entries?.length > 0">
                            <table class="table table-index table-striped" >
                                <thead>
                                    <tr>
                                        <th scope="col" class="checkbox-table">
                                            <input v-model="checkedAll"  @change="onCheckedAll(checkedAll)" type="checkbox" />
                                        </th>                                                                  
                                        <template v-for="field in fields" :key="field.field">
                                            <th scope="col" v-if="field.selected && field.field == 'name'"
                                                :class="field.class" style="vertical-align: middle !important;min-width:200px;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                    :name="field.name" :field="field.field" />
                                            </th>
                                            <th scope="col" v-else-if="field.selected" style="vertical-align: middle !important;">
                                                <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                            </th>
                                        </template>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(entry, index) in entries" :key="entry.id" >                                        
                                        <tr class="master-row" :id="'row_' + (index + 1)" :class="entry.id === this.customerDetail ? 'backgroup__detail border__master' :''">
                                            <td>
                                                <input v-model="entry.checked" type="checkbox" />
                                            </td>
                                            <template v-for="field in fields" :key="field.field">
                                                <td v-if="field.selected" :style="field.style" class="py-0">
                                                    <a :href="'#row_' + index" class="description text-dark tagA" @click="detailCustomer(entry.id)">
                                                        <div v-if="field.field==='type'" :style="field.style" v-text="renderField(entry, field.field, field) == 1 ? $t('message.customerIndex.individual') : renderField(entry, field.field, field) == 2 ? $t('message.customerIndex.company') : ''"></div>
                                                        <div v-else-if="field.field==='name'" :style="field.style" v-text="renderField(entry, field.field, field)" class="description"></div>
                                                        <div v-else-if="field.field==='gender'" :style="field.style" v-text="renderField(entry, field.field, field) == 0 ? $t('message.common.female') : renderField(entry, field.field, field) == 1 ? $t('message.common.male'): '' "></div>
                                                        <div v-else-if="field.field==='status'" :style="field.style" v-text="renderField(entry, field.field, field) == 0 ? $t('message.customerIndex.inActive') : $t('message.customerIndex.active')" class="cusStatus" ></div>

                                                        <!-- Cột điện thoại -->
                                                        <div v-else-if="field.field==='contactNumber'" :style="field.style">                                                        
                                                            <template v-if="entry.phone.length > 1" >
                                                                <select  class="sltUnit" style="min-width: 160px;" >
                                                                    <option :key="p" v-for="p in entry.phone" :value="p" v-text="p"></option>
                                                                </select>
                                                            </template>
                                                            <template v-else-if="entry.phone.length == 1" >                                                            
                                                                <span :value="n" v-text="n" :key="n" v-for="n in entry.phone"></span>
                                                            </template> 
                                                        </div> 
                                                        <!-------------------------------------------------->
                                                        <!-- Cột Nhóm khách hàng -->
                                                        <div v-else-if="field.field==='customerGroupId'" :style="field.style" class="description">                                                                                                                   
                                                            <template v-if="entry.customerGroup.length > 1" >
                                                                <select  class="sltUnit" style="min-width: 160px;" >
                                                                    <option :value="group.customerGroupId" v-text="group.customerGroupName" :key="group" v-for="group in entry.customerGroup"></option>
                                                                </select>
                                                            </template>
                                                            <template v-else-if="entry.customerGroup.length == 1" >
                                                                <span :value="group.customerGroupId" v-text="group.customerGroupName" :key="group" v-for="group in entry.customerGroup"></span>
                                                            </template>  
                                                        </div> 
                                                        <!-- ---------------------------------------------- -->                                                    
                                                        <div v-else-if="field.field==='birthDate'">
                                                            {{entry.birthDate? new Date(entry.birthDate).toLocaleDateString('en-GB')  : '' }} 
                                                        </div>  
                                                        <div v-else-if="field.field==='createdAt'">
                                                            {{entry.createdAt? new Date(entry.createdAt).toLocaleDateString('en-GB')  : '' }} 
                                                        </div>    
                                                        <div v-else-if="field.field==='updatedAt'">
                                                            {{entry.updatedAt? new Date(entry.updatedAt).toLocaleDateString('en-GB')  : '' }} 
                                                        </div>
                                                        <div v-else-if="field.field==='wardName'">
                                                            {{entry.wards? entry.wards : '' }}
                                                        </div>
                                                        <div v-else-if="field.field==='address'" class="cus_address" :title="entry.address? entry.address : '' ">                                                        
                                                            {{entry.address? entry.address : '' }}
                                                        </div>                                                    
                                                        <div v-else-if="field.field==='locationName'">                                                        
                                                            {{entry.districts? entry.districts + ' - ': '' }}  {{entry.provinces? entry.provinces : '' }}   
                                                        </div>
                                                        <div v-else-if="field.field==='sumBuy'">                                                        
                                                            {{entry.sumBuy ? n(entry.sumBuy) : null}}   
                                                        </div>
                                                        <div v-else-if="field.field==='lastDay'">                                                        
                                                            {{ entry.sumBuy ? d(entry.lastDay) : null}}   
                                                        </div>
                                                        <div v-else-if="field.field==='facebookUrl'">
                                                            <span class="facebookLink" :title="entry.facebookUrl">{{entry.facebookUrl?  entry.facebookUrl : '' }}</span>
                                                        </div>
                                                        <div v-else v-text="renderField(entry, field.field, field)" :class="field.className"></div>
                                                    </a>
                                                </td>  
                                            </template>
                                        </tr>    
                                        <!-- Chi tiết khách hàng -->
                                        <tr v-if="this.customerDetail === entry.id" :class="[ entry.id === this.customerDetail ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                            <td></td>
                                            <td :colspan="fields.length" class="pb-0">
                                                <div class="row detail-prd-row">
                                                    <!-- Thông tin -->
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.customer.values.info, entry)">
                                                        <span :class="[ headerActive === this.$constant.customer.values.info ? 'backgroup__detail-active' : '', 'head-detail']">                                                            
                                                            {{$t('message.customerIndex.tabs.info')}}
                                                        </span>
                                                    </div>
                                                    <!-- Địa chỉ nhận hàng -->
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.customer.values.address, entry)">
                                                        <span :class="[ headerActive === this.$constant.customer.values.address ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.customerIndex.tabs.address')}}
                                                        </span>
                                                    </div>
                                                    <!-- Lịch sử đặt hàng -->
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.customer.values.historyOrder, entry)" v-if="entry.historyOrder.length > 0">
                                                        <span :class="[ headerActive === this.$constant.customer.values.historyOrder ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.customerIndex.tabs.historyOrder')}}
                                                        </span>
                                                    </div>
                                                    <!-- Lịch sử bán trả hàng -->
                                                    <div class="col-2 mt-2" @click="showContent(this.$constant.customer.values.historySale, entry)">
                                                        <span :class="[ headerActive === this.$constant.customer.values.historySale ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.customerIndex.tabs.historySale')}}
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Thông tin tab -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.customerDetail === entry.id && this.headerActive ==  this.$constant.customer.values.info">
                                            <td class="border-top-0"></td>
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4">                                                                
                                                    <div class="row detail-prd-row mt-2">
                                                        <div class="row detail-prd-row mt-2">
                                                            <div class="col-2">
                                                                <div class="img-container mt-4">
                                                                    <div class="img-main w-100">
                                                                        <img v-if="entry.url != null" :src="entry.url" alt="" class="img-main-item">
                                                                        <img v-else src="../../../public/img/default-product.png" alt="" class="img-main-item">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4">
                                                                <div class="row mx-1">
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.code')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0description pl-0 ow-break-word">
                                                                                {{entry.code? entry.code : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.name')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word">
                                                                                {{entry.name? entry.name : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.birthDay')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0">
                                                                                {{entry.birthDate?  new Date(entry.birthDate).toLocaleDateString('en-GB') : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.customerGroup')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0">
                                                                                <template v-if="entry.customerGroup.length > 1" >
                                                                                    <select  class="sltUnit" style="min-width: 160px;" >
                                                                                        <option :value="group.customerGroupId" v-text="group.customerGroupName" :key="group" v-for="group in entry.customerGroup"></option>
                                                                                    </select>
                                                                                </template>
                                                                                <template v-else-if="entry.customerGroup.length == 1" >
                                                                                    <span :value="group.customerGroupId" v-text="group.customerGroupName" :key="group" v-for="group in entry.customerGroup"></span>
                                                                                </template>  
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1" v-if="entry.companyName">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.companyName')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0">
                                                                                {{ entry.companyName && entry.companyName != "" ? entry.companyName : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.taxCode')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word">
                                                                                {{entry.taxCode?  entry.taxCode : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0 ">
                                                                                {{$t('message.customerIndex.email')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word" :title="entry.email?  entry.email : ''">
                                                                            <span>
                                                                                {{entry.email?  entry.email : '' }}
                                                                            </span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.facebook')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word">
                                                                                <span  :title="entry.facebookUrl">{{entry.facebookUrl?  entry.facebookUrl : '' }}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.accountNumber')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word" v-text="entry.accountNumber"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.accountOwner')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word" v-text="entry.accountName"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.bankName')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word" v-text="entry.bankName"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5 px-0">
                                                                                {{$t('message.customerIndex.bankAddress')}}:
                                                                            </div>
                                                                            <div class="col-7 pl-0 ow-break-word" v-text="entry.bankAddress"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 pr-2">
                                                                <div class="row mx-1">
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.phone')}}:
                                                                            </div>
                                                                            <div class="col-7 manufacturer">
                                                                                {{entry.contactNumber? entry.contactNumber : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                <p></p>
                                                                            </div>
                                                                            <div class="col-7 manufacturer">
                                                                                <!-- Chỗ này sẽ bổ sung thêm biến -->
                                                                                {{entry.contactNumber1? entry.contactNumber1 : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.address')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" >
                                                                                {{entry.address? entry.address : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.deliveryArea')}}:
                                                                            </div>
                                                                            <div class="col-7  ow-break-word">
                                                                                {{entry.districts? entry.districts + ' - ' : '' }}   {{entry.provinces? entry.provinces : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>                                                                            
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.ward')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" >
                                                                                {{entry.wards? entry.wards : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.createdBy')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{entry.createdBy? entry.createdBy : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.createdAt')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word">
                                                                                {{entry.createdAt? new Date(entry.createdAt).toLocaleDateString('en-GB')  : '' }}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.debtDate')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.numberDebtDate ? n(entry.numberDebtDate) + ' ' + $t('message.common.date') : (entry.recurringPaymentDate ? (entry.recurringPaymentDate) : null)"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.employee')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.employee ? entry.employee.name : null"></div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-12 mt-2 py-2 border-bottom-1">
                                                                        <div class="row">
                                                                            <div class="col-5">
                                                                                {{$t('message.customerIndex.branchActive')}}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.branchName"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>                                                                   
                                                            <div class="col-2" style="border-left: 1px solid lightgray">
                                                                <span class="note-supplier text-gray" v-if="entry.comments"><i class="fa fa-pencil-alt mr-1"></i>{{entry.comments}}</span>
                                                                <span class="note-supplier text-gray" v-else><i class="fa fa-pencil-alt mr-1"></i>{{$t('message.employeeIndex.note')}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                    <AppPermission :permission="this.$permissions.customers.update">
                                                        <button type="button" class="btn btn-save mx-1" @click="showModalProductsEditById(entry.id)">
                                                            <i class="fa fa-check"></i>
                                                            {{$t('message.customerIndex.button.update')}}
                                                        </button>
                                                    </AppPermission>       
                                                    <AppPermission :permission="this.$permissions.customers.inActive">
                                                    <button v-if="entry.status == 1" type="button" class="btn btn-danger mx-1" @click="showModalStopUser(entry.id, entry.name)">
                                                        <i class="fa fa-lock"></i>
                                                        {{$t('message.customerIndex.inActive')}}
                                                    </button>
                                                    
                                                    <button v-else type="button" class="btn btn-primary mx-1" @click="showModalStartUser(entry.id, entry.name)">
                                                        <i class="fa fa-unlock"></i>
                                                        {{$t('message.customerIndex.reActive')}}
                                                    </button>
                                                    </AppPermission>
                                                    <AppPermission :permission="this.$permissions.customers.remove">
                                                    <button type="button" class="btn btn-danger mx-1" @click="showModalRemoveCustomer(entry.id, entry.name)">
                                                        <i class="fa fa-trash-alt"></i>
                                                        {{$t('message.customerIndex.button.remove')}}
                                                    </button>
                                                    </AppPermission>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Địa chỉ nhận hàng tab -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.customerDetail === entry.id && this.headerActive ==  this.$constant.customer.values.address">
                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                <div class="mt-2 pb-4">                                                                
                                                    <div class="detail-prd-row mt-2">
                                                        <table class="w-100" style="max-width:100%;">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">
                                                                    <th scope="col" class="checkbox-table"></th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.addressName')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.receiverName')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.receiverPhone')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.receiverAddress')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.createdAt')}}
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody> 
                                                                <template v-if="this.dataDetail">
                                                                    <tr class="table-header border-1" v-for="(item, index) in this.dataDetail" :key="index">
                                                                        <td class="col-1" @click="showModalDeletePickup(item.id)">
                                                                            <i class="fa fa-times text-danger"></i>
                                                                        </td>
                                                                        <td class="text-primary" @click="showModalUpdatePickup(item.id, null, 'isEdit')"> {{ item.nameAddress ? item.nameAddress : '-----' }}</td>
                                                                        <td>{{ item.name }}</td>
                                                                        <td>{{ item.phone }}</td>
                                                                        <td>{{ item.addressReceive}}</td>
                                                                        <td>{{ dt(item.createdAt) }}</td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <Pagination :value="paginateDetail" :record="this.numberRecordDetail" @change="pageChangeDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                    <button type="button" class="btn btn-save mx-1" @click="showModalCreateDelivery(entry.id, entry.customer_code)">
                                                        <i class="fa fa-plus"></i>
                                                        {{$t('message.customerIndex.button.new')}}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Lịch sử đặt hàng -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.customerDetail === entry.id && this.headerActive ==  this.$constant.customer.values.historyOrder">
                                            <td class="border-top-0"></td>
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4">                                                                
                                                    <div class="detail-prd-row mt-2">
                                                        <table class="w-100" style="max-width:94.5%;">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">                                                                    
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.orderCode')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.time')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.sellerName')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.branch')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.total')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.status')}}
                                                                    </th>                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>                                                                 
                                                                <tr class="table-header border-1" v-for="(item, index) in this.dataDetail" :key="index">
                                                                    <td class="col-1 text-center" @click="showDetailStockCard(item.code)">
                                                                        <a href="#" class="text-primary text-decoration-none">{{ item.code }}</a>                                                                             
                                                                    </td>
                                                                    <td>{{ item.purchaseDate ? dt(item.purchaseDate) : null}}</td>
                                                                    <td>{{ item.sold_by && item.sold_by.name ? item.sold_by.name : null }}</td>
                                                                    <td>{{ item.branch && item.branch.name ? item.branch.name : null}}</td>
                                                                    <td>{{ n(item.total?? 0) }}</td>
                                                                    <td>{{ item.statusName }}</td>                                                                        
                                                                </tr>                                                                
                                                            </tbody>
                                                        </table>
                                                        <Pagination :value="paginateDetail" :record="this.numberRecordDetail" @change="pageChangeDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row flex-end pr-5">
                                                     <AppPermission :permission="this.$permissions.customers.export ? this.$permissions.customers.export : ''">
                                                        <button type="button" class="btn btn-secondary mx-1" @click.stop="exHistoryOrder(entry.id)">
                                                            <i class="fa fa-file-export pr-1"></i>
                                                            {{$t('message.customerIndex.button.export')}}
                                                        </button>
                                                    </AppPermission>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Lịch sử bán hàng/trả hàng -->
                                        <tr class="detail-row bg-white border__body-detail" v-if="this.customerDetail === entry.id && this.headerActive ==  this.$constant.customer.values.historySale">
                                            <td class="border-top-0"></td>
                                            <td :colspan="fields.length" class="border-top-0">
                                                <div class="mt-2 pb-4">                                                                
                                                    <div class="detail-prd-row mt-2">
                                                        <table class="w-100" style="max-width:94.5%;">
                                                            <thead>
                                                                <tr class="backgroup__detail table-header border-1">                                                                    
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.invoiceCode')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.time')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.sellerName')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.total')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.branch')}}
                                                                    </th>
                                                                    <th scope="col" class="checkbox-table">
                                                                        {{$t('message.customerIndex.status')}}
                                                                    </th>                                                                    
                                                                </tr>
                                                            </thead>
                                                            <tbody>                                                            
                                                                <!-- <tr class="table-header border-1" v-for="(item, index) in entry.invoices" :key="index">
                                                                    <td class="col-1 text-center">
                                                                        <span class="text-primary" @click="showDetail(item.id , 'invoice')">{{ item.code }}</span>                                                                             
                                                                    </td>
                                                                    <td>{{ item.purchaseDate ? dt(item.purchaseDate) : null}}</td>
                                                                    <td>{{ item.sold_by ? item.sold_by.name : null }}</td>
                                                                    <td>{{ n(item.total?? 0) }}</td>
                                                                    <td>{{ item.branch ? item.branch.name : null }}</td>
                                                                    <td>{{ item.statusName }}</td>                                                                        
                                                                </tr>
                                                                <tr class="table-header border-1" v-for="(item, index) in entry.returns" :key="index">
                                                                    <td class="col-1 text-center">
                                                                        <span class="text-primary" @click="showDetail(item.id , 'return')">{{ item.code }}</span>                                                                             
                                                                    </td>
                                                                    <td>{{ item.returnDate ? dt(item.returnDate) : null}}</td>
                                                                    <td>{{ item.sold_by ? item.sold_by.name : null }}</td>
                                                                    <td>{{ n(item.returnTotal ?? 0) }}</td>
                                                                    <td>{{ item.branch ? item.branch.name : null }}</td>
                                                                    <td>{{ item.statusName }}</td>                                                                        
                                                                </tr>-->
                                                                <template v-if="this.dataDetail?.length > 0">
                                                                    <template v-for="(item, i) in this.dataDetail" :key="i">
                                                                        <tr class="table-header border" v-if="i >= (this.paginateDetail.currentPage - 1)*10 && i < this.paginateDetail.currentPage*10">
                                                                            <td class="col-1 text-center">
                                                                                <span class="text-primary" @click="showDetail(item.id , (item.isInvoice ? 'invoice' : 'return'))">{{ item.code }}</span>                                                                             
                                                                            </td>
                                                                            <td>{{ item.isInvoice && item.purchaseDate ? dt(item.purchaseDate) : (item.returnDate ? dt(item.returnDate) : null)}}</td>
                                                                            <td>{{ item.sold_by ? item.sold_by.name : null }}</td>
                                                                            <td>{{ item.isInvoice && item.returnTotal ? n(item.returnTotal) : n(item.total)}}</td>
                                                                            <td>{{ item.branch ? item.branch.name : null }}</td>
                                                                            <td>{{ item.statusName }}</td>
                                                                        </tr>
                                                                    </template>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <Pagination :value="paginateDetail" :record="this.numberRecordDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row flex-end pr-5" >
                                                    <AppPermission :permission="this.$permissions.customers.export ? this.$permissions.customers.export : ''">
                                                        <button type="button" class="btn btn-secondary mx-1" @click.stop="exHistorySaleReturn(entry.id)" >
                                                            <i class="fa fa-file-export pr-1"></i>
                                                            {{$t('message.customerIndex.button.export')}}
                                                        </button>
                                                    </AppPermission>
                                                    
                                                </div>
                                            </td>
                                        </tr>
                                    </template>      
                                </tbody>
                            </table>
                        </div>
                        <div class="row ml-auto mr-auto" v-if="entries?.length == 0 && !this.loading" id="notFound">
                            <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <!-- <p>{{ this.$constant.notFound }}</p> -->
                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalAddCustomer ref="ModalAddCustomer" :openModal="openModal" @updated="renderListDataCreate" />
        <modalAddInfoCustomer ref="ModalAddInfoCustomer" :openModal="openModalAddInfo" :action="this.action" @updated="renderData"/>
        <ModalRemoveCustomer ref="ModalRemoveCustomer" @updated="renderDataCustomer"/>
        <ModalStopUserCustomer ref="ModalStopUserCustomer" :title="this.title" :content="this.content" :type="this.type" @updated="load"/>
        <ModalImportFileExcel ref="ModalImportExcel" @input="load()" />
        <ModalEditCustomer :customerGroupDetail="customerGroupDetail" ref="ModalEditCustomer" @updated="renderListData" @renderData="renderListDataRemove"  @input="load()" :flag="2" />
        <ModalErrorsMessageImport ref="ModalErrorsMessageImport" />
        <ModalCreateUpdateDelivery ref="ModalCreateUpdateDelivery" @updated="pageChangeDetail()"></ModalCreateUpdateDelivery>
        <ModalConfirm ref="ModalConfirm" @updated="load()"/>
        <DetailOrdersCard ref="DetailOrdersCard"/>
        <DetailStockCard ref="DetailStockCard" />
        <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
    </AppPermission>
</template>

<script>
import { $alert, $get, $post, clone, setArray, removeVietnameseTones} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import QSelect from "@/components/QSelect";
import modalAddCustomer from '@/components/customers/ModalAddCustomer';
import modalAddInfoCustomer from '@/components/customers/ModalAddInfoCustomer';
import InputDateRangePicker from '@/components/forms/InputDateRangePicker'; 
import ModalRemoveCustomer from '@/components/customers/ModalRemoveCustomer';
import ModalStopUserCustomer from '@/components/customers/ModalStopUserCustomer';
import ModalImportFileExcel from '@/components/customers/ModalImportExcel';
import SelectField from "../../components/SelectField";
import emitter from "@/lib/emitter";
import store from "@/store";
import InputTextArea from "@/components/forms/InputTextArea";
import ModalEditCustomer from '@/components/customers/ModalEditCustomer';
import Treeselect from 'vue3-treeselect';
import http from '@/services/api';
import ModalErrorsMessageImport from './ModalErrorsMessageImport.vue'
import SelectSearch from "@/components/SelectSearch.vue";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import AppPermission from '@/components/AppPermission.vue';
import ModalCreateUpdateDelivery from './ModalCreateUpdateDelivery.vue';
import ModalConfirm from "@/views/customers/tabs/ModalConfirmDelete.vue";
import SelectDateTime from "@/components/SelectDateTime.vue";
import DetailOrdersCard from './tabs/DetailOrdersCard.vue';
import InputText from "@/components/forms/InputText";
import DetailStockCard from '../products/DetailStockCard';
import exportMixin from "@/exportMixin";   
import InputNumber from "@/components/forms/InputNumberCustom"; 
import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
export default {
    name: "CustomerIndex",
    mixins: [exportMixin],
    components: {
        DetailOrdersCard, DetailStockCard, InputNumber, 
        SelectField, ModalConfirm, InputText, SelectDateTime, 
        ModalCreateUpdateDelivery, Treeselect, AppPermission, 
        InputQuickSearch, InputTextArea,TableSortField, Pagination, 
        QSelect, modalAddCustomer, modalAddInfoCustomer, InputDateRangePicker, 
        ModalRemoveCustomer, ModalStopUserCustomer, ModalImportFileExcel, 
        ModalEditCustomer, ModalErrorsMessageImport, SelectSearch,
        ModalSelectOptionExport
    },
    mounted() {
        document.title = this.$t('message.customerIndex.documentTitle');
        const query = this.$route.query;
        const fields = localStorage.getItem('customersSelectedFields_' + this.user.id);        
        if (fields) {
            this.fields = JSON.parse(fields);
        }
        this.filter = {...this.filter, ...query};
        this.paginate.currentPage = this.$route.query.page || 1;
        this.numberRecord = this.$route.query.numberRecord || "15";
        if(query['code'] != null){
            this.params['code_name_phone'] = query['code'];
            this.loadDetail();
        }else{
            this.clearFilter();
        }
        // this.clearFilter();        
        // window.addEventListener('scroll', this.onScroll);
    },
    created() {
        emitter.on("clearIdProduct", () => {
            this.idProduct = null;
        });
        emitter.on("deleted", () => {
           location.reload();
        });
    },
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            action:'',
            toggleSelectFile: false,
            filter: {  
                branchId: '',
                status: '',
                code_name_phone: '',
                code_invoice: '',
                email: '',
                note: '',
            },
            checkedAll: false,
            user: store.state.Auth.user,
            fields: [],
            entries: [],
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            fixed: false,
            numberRecord: 25,
            openModal: false,
            openModalAddInfo: false,
            customerGroupList: globalConfig.cusGroup,
            customerGroupDetail: {},
            employeeList: [],
            branchesList: globalConfig.branches,
            dataDistrictList: {
                all: null,
                filter: null,
                search: null,
            },
            params: {
                keyword: '',
                customerGroupSearch: '',
                gender: '',
                status: '',
                typeCustomer: '',
                branch: '',
                createdBy: '',
                createdAt: '',
                branchId: '',
                birth_day: '',
                address: '',
                feeShippingFrom: '',
                feeShippingTo: ''
            },
            customerGroup:{},
            title: '',
            content: '',
            type: '',
            newFlag: true,
            loading: false,
            customerDetail: null,
            isFilter: false,
            headerActive: '',
            dataDetail: [],
            paginateDetail: {
                currentPage: 1,
                lastPage: 1
            },
            numberRecordDetail: 10,
        }
    },
    methods: {
        async exHistorySaleReturn(id){             
            emitter.emit("onLoading");                
            const res = await http.download('/v1/customers/ex-history-sale-return', {id: id});          
            this.returnDataExport(res);    
        },
        async exHistoryOrder(id){
            emitter.emit("onLoading");                
            const res = await http.download('/v1/customers/ex-history-order', {id: id});
            this.returnDataExport(res);    
        },
        showDetailStockCard(code){                           
            this.$refs.DetailOrdersCard.show(code);
        },
        async changeQuickSearch(object){
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.params[key] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete this.params[key];
                        }
                    });
                })
            });
            this.search();
        },
        renderData(){
            this.search();
        },
        showModalProductsEditById(id){            
            this.action = this.$t('message.customerIndex.modal.titleUpdate');
            this.$refs.ModalAddInfoCustomer.show(id);            
        },
        getDataChecked(fields) {            
            let count = 0;
            fields.forEach(item => {
                if (item.selected == true) count += 1;
            });
            return count;
        },
        onScroll() {
            let height = window.scrollY;
            if(height > 120){
                this.fixed = true;
            }else{
                this.fixed = false;
            }
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/customers/index',
                query: {}
            });

            Object.keys(this.params).forEach(key => {
                this.params[key] = '';
            });
            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });
            emitter.emit("clearQuickSearch");
            emitter.emit("clearSelectSearchValue");
            emitter.emit("clearSelectDateTime");
            this.dataDistrictList.search = '';
            this.numberRecord = 15;
            this.isFilter = false;
            this.customerDetail = null;
            this.load();
        },
        getCheckedIDs() {
            return this.entries.filter(e => e.checked).map(e => e.id);
        },
        onCheckedAll(value) {            
            setArray(this.entries, {
                checked: value
            });
        },
        async doFilterSearch(v){
            switch (v.label) {
                case 'createdBy':
                    this.params.createdBy = v.model;
                    break;
                case 'branchId':
                    this.params.branchId = v.model;
                    break;
                case 'address':
                    this.params.address = v.model;
                    break;
                case 'customerGroupSearch': 
                    this.params.customerGroupSearch = v.model;
                    break;
            }
            this.search();
        },
        async doFilter(field, value) {
            const query = clone(this.$route.query);
            query[field] = value;
            await this.$router.push({ query: query });
            this.load();
        },
        async sort(field, direction) {
            this.sortData = {
                field, direction
            };
            const query = clone(this.$route.query);
            query.sort_field = field;
            query.sort_direction = direction;
            await this.$router.push({ query: query });
            this.load();
        },
        async search (){
            this.params = {
                ...this.params,
                // filter: this.filter
            }
            const res = await $get('/v1/customers/data', this.params);
            if (!this.fields || !this.fields.length) {
                this.fields = res.data.fields;
            }
            // this.paginate = res.data.paginate;
            // this.entries = res.data.entries; 
            this.entries = res.data.data.data;       
            this.paginate.totalRecord = res.data.data.total;
            this.paginate.currentPage = res.data.data.current_page;
            this.paginate.lastPage = res.data.data.last_page; 
            this.isFilter = true;      
        },
        async loadDetail(){
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            this.dataDistrictList.search = '';
            this.numberRecord = 15;
            await this.search();
            this.entries.forEach((entry) => {
                this.detailCustomer(entry.id);
            });
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },
        async searchTest (node, instanceId){
            this.params = {
                ...this.params,
                address: node?.id
            }
            const res = await $get('/v1/customers/data', this.params);
            if (!this.fields || !this.fields.length) {
                this.fields = res.data.fields;
            }
            // this.paginate = res.data.paginate;
            // this.entries = res.data.entries;
            this.entries = res.data.data.data;       
            this.paginate.totalRecord = res.data.data.total;
            this.paginate.currentPage = res.data.data.current_page;
            this.paginate.lastPage = res.data.data.last_page;               
        },
        async searchDeliveryArea(node) {
            let id = node.id;
            let label = node.label;
            if(this.params.address == node.id){
                id = null;
                label = null;
            }
            this.params = {
                ...this.params,
                address: id
            }
            this.dataDistrictList.search = label;
            const res = await $get('/v1/customers/data', this.params);
            if (!this.fields || !this.fields.length) {
                this.fields = res.data.fields;
            }
            // this.paginate = res.data.paginate;
            // this.entries = res.data.entries;
            this.entries = res.data.data.data;       
            this.paginate.totalRecord = res.data.data.total;
            this.paginate.currentPage = res.data.data.current_page;
            this.paginate.lastPage = res.data.data.last_page;
        },
        onInputFilterDeliveryArea(){
            if(this.dataDistrictList.search == ''){
                this.dataDistrictList.filter = this.dataDistrictList.all;
                return
            }
            let arr = [];
            for (let index = 0; index < this.dataDistrictList.all.length; index++) {
                if (removeVietnameseTones(this.dataDistrictList.all[index].label.toLowerCase()).indexOf(removeVietnameseTones(this.dataDistrictList.search.toLowerCase())) > -1){
                    arr.push(this.dataDistrictList.all[index]);
                }
            }
            this.dataDistrictList.filter = arr;
        },
        async searchCustomerGroup (node, instanceId){
            this.params = {
                ...this.params,
                customerGroupSearch: node?.id
            }
            const res = await $get('/v1/customers/data', this.params);
            if (!this.fields || !this.fields.length) {
                this.fields = res.data.fields;
            }
            // this.paginate = res.data.paginate;
            // this.entries = res.data.entries; 
            this.entries = res.data.data.data;       
            this.paginate.totalRecord = res.data.data.total;
            this.paginate.currentPage = res.data.data.current_page;
            this.paginate.lastPage = res.data.data.last_page;              
        },
        async pageChange(page) {
            this.params = {
                ...this.params,
            }
            this.isFilter = true;
            await this.$router.push({ query: { page: page, ...this.params } });
            this.load();
        },
        pageChangeDetail(page = null, entry = null){
            this.dataDetail = [];
            if(this.headerActive === this.$constant.customer.values.address && this.customerDetail){
                this.loadDataAddressCustomer(page);
            }else if(this.headerActive === this.$constant.customer.values.historyOrder && this.customerDetail){
                this.loadDataHistoryOrder(page);
            }else if(this.headerActive === this.$constant.customer.values.historySale && this.customerDetail){
                if(entry && entry.invoices){
                    this.dataDetail = this.dataDetail.concat(entry.invoices);
                }
                if(entry && entry.returns){
                    this.dataDetail = this.dataDetail.concat(entry.returns);
                }
                if(this.dataDetail){
                    this.dataDetail.sort(function(a, b) { return new Date(b.createdAt) - new Date(a.createdAt) });
                }
                this.setPaginateDataDetail();
            }
        },
        setPaginateDataDetail(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.dataDetail.length / 10);
            this.paginateDetail.totalRecord = this.dataDetail.length;
        },
        doFilterTime(v){
            const query = clone(this.params);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.params = {...query};
            this.load();
          },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const dataDis = await $get('/v1/data-distric');
            this.dataDistrictList.all = dataDis;
            this.dataDistrictList.filter = dataDis;
            const res = await $get('/v1/customers/data', { ...this.$route.query, record: this.numberRecord, ...this.params});              
            if (res.code !== 200) {
                $alert(res);
                return;
            }            
            // setArray(res.data.entries, {
            //     checked: false
            // });

            // const customerGroup = await $get('/v1/customer-group/list-of-customer');
            // this.customerGroupList = customerGroup;

            // Get list employee.
            const employeeData = await $get('/v1/users/data');
            this.employeeList = employeeData.data.entries;

            // Get branches data
            // const branchesData = await $get('/v1/branches/data');
            // this.branchesList = branchesData.data.entries;
            
            // this.paginate = res.data.paginate;
            // this.entries = res.data.entries;
            this.entries = res.data.data.data;       
            this.paginate.totalRecord = res.data.data.total;
            this.paginate.currentPage = res.data.data.current_page;
            this.paginate.lastPage = res.data.data.last_page;

            if (!this.fields || !this.fields.length) {
                this.fields = res.data.fields;
            }
            emitter.emit("offLoading");
            this.loading = false;
        },
        async loadDataHistoryOrder(page = 1) {
            const result = await $post('/v1/customers/get-history-order', {id: this.customerDetail, record: this.numberRecordDetail, page: page});
            if(result){
                this.dataDetail = result.data;
                this.paginateDetail.totalRecord = result.total;
                this.paginateDetail.currentPage = result.current_page;
                this.paginateDetail.lastPage = result.last_page;
            }
        },
        async loadDataHistoryInvoice(page = 1) {
            const result = await $post('/v1/customers/get-history-invoice', {id: this.customerDetail, record: this.numberRecordDetail, page: page});
            if(result){
                this.dataDetail = result.data;
                this.paginateDetail.totalRecord = result.total;
                this.paginateDetail.currentPage = result.current_page;
                this.paginateDetail.lastPage = result.last_page;
            }
        },
        async loadDataAddressCustomer(page = 1) {
            const result = await $post('/v1/customers/get-deliveries', {id: this.customerDetail, record: this.numberRecordDetail, page: page, sub: true});
            if(result){
                this.dataDetail = result.data;
                this.paginateDetail.totalRecord = result.total;
                this.paginateDetail.currentPage = result.current_page;
                this.paginateDetail.lastPage = result.last_page;
            }
        },
        edit(id) {
            location.href = '/customers/form?id=' + id;
        }, 
        showModal (flag) {
            this.$refs.ModalAddCustomer.show(flag);
        },
        showModalAddInfo () {
            this.action = this.$t('message.customerIndex.modal.titleCreate');
            this.$refs.ModalAddInfoCustomer.show();            
        },
        showModalRemoveCustomer (id, name) {
            this.$refs.ModalRemoveCustomer.show(id, name);
        },
        showModalStopUser (id,name) {
            this.title = this.$t('message.customerIndex.confirm');
            this.content = this.$t('message.customerIndex.contentInActive');
            this.type = 0;
            this.$refs.ModalStopUserCustomer.show(id, name);
        },
        showModalStartUser (id,name) {
            this.title = this.$t('message.customerIndex.confirm');
            this.content = this.$t('message.customerIndex.contentReActive');
            this.type = 1;
            this.$refs.ModalStopUserCustomer.show(id, name);            
            document.getElementsByClassName('cusStatus').html(this.$t('message.customerIndex.active'));            
            
        },
        showModalUploadFileExcel () {
            this.$refs.ModalImportExcel.show();
        },
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        detailCustomer(id){
            if (id === this.customerDetail) {
                this.customerDetail = null;
            } else {
                this.customerDetail = id;
            }
            this.headerActive =  this.$constant.customer.values.info;
            this.dataDetail = [];
        },
        showContent(name, entry = null) {
            this.headerActive = name;
            this.pageChangeDetail(null, entry);
        },
        async showModalEditCustomer () 
        {
            const data = await $get('/v1/customer-group/show');
        },
        async showModalCategoryEdit (id)
        {
            this.$refs.ModalEditCustomer.show(id);
        },
        renderListData (newCustomerGroup) 
        {
            this.customerGroupList = this.customerGroupList.map(customerGroup => customerGroup.id === newCustomerGroup.id ? newCustomerGroup : customerGroup)
        },
        renderListDataCreate (newData) 
        {
            this.customerGroupList.push(newData.customData)
        },
        renderListDataRemove (newCustomerGroup) 
        {
            this.customerGroupList = this.customerGroupList.map(customerGroup => customerGroup.id !== newCustomerGroup.id ? newCustomerGroup : customerGroup)
        },
        renderDataCustomer(id) {
            const listUpdate = this.entries.filter(item => item.id !== id);
            this.entries = listUpdate;
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query.numberRecord = v.target.value;
            this.isFilter = true;
            await this.$router.push({ query: { page: 1, ...this.params } });
            this.load();
        },
        selectOptionExport(method = 'exportExcel', totalRecord = 0){
            let params = { method: method };
            totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
            this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
        },
        async exportFiles(params){
            if (params.method) {
                this[params.method](params.firstRecordIndex);
            }
        },
        async exportExcel (firstRecordIndex = 0) {
            emitter.emit("onLoading");
            const res = await http.download('/v1/customers/export', {
                fields: this.fields, 
                ...this.params,
                firstRecordIndex: firstRecordIndex
            });
            this.returnDataExport(res);
        },
        showModalCreateDelivery(id, code){
            this.$refs.ModalCreateUpdateDelivery.show(id, code);
        },
        showModalDeletePickup (id) {
            this.$refs.ModalConfirm.show(id);
        },
        showModalUpdatePickup (id, code, type){
            this.$refs.ModalCreateUpdateDelivery.show(id, code, type);
        },
        showDetail(id,  stockCardType){ 
            this.$refs.DetailStockCard.show(id, stockCardType);
        }
    },
}
</script>
<style scoped>

#notData{
    font-weight: 700;
    font-size: 14px;
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.img-container {
    display: flex;
    flex-direction: row;
    gap: 1rem
}

.img-list {
    display: flex;
    flex-direction: column;
}

.img-main-item {
    width: 100% !important;
    object-fit: cover;
}

.text-title {
    line-height: 52px;
}

#number_records {
    margin-top: 11px;
}

#typeCard {
    margin-top: -8px;
}

.child-category-container {
    margin-left: 1rem
}

.child-category-item {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem
}
.customer-group {
    border: none;
    padding-left: 0px;
    border-bottom: 1px solid #f1f1f1;
}
.custom-input {
    border: none;
    border-bottom: 1px solid #f1f1f1;
}
.custome-radio {
    margin-top: 10px;
}
.form-check {
    margin-bottom: 10px;
}
.table-index-cus thead {
    background-color: #B1DDF0;
    
}
.table-index-cus {
    width: 900px !important;
}


.lstCustomer tr td , .lstCustomer tr th {
    /* min-width: 150px !important;
    min-height: 500px !important; */
    vertical-align: middle !important;
    padding: 10px;
}

.lstCustomer {
    border-collapse: collapse;
    width: 2600px !important;
    overflow-x: scroll;
    max-height: 700px;
    display: block;
    margin-top: -1px;
    margin-right: 0px;
}
.lstCustomer thead th {
    border: none;
    
}
.lstCustomer {
    width: 100%;
  }

  @media (max-width: 1030px) {
    .lstCustomer {
        max-height: 400px !important;
    }

    .lstCustomer {
        max-height: 500px;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1030px){

    .lstCustomer {
        max-height: 500px;
    }
    
}
select {
    border: none;
}

.label__file {
    height: 30px;
    cursor: pointer;
    width: 100%;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.nav-link.active{
    outline: none !important;
}
</style>