<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">
                        {{$t('message.medicineProductMapping.placeholder.chooseCategories')}}
                    </h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0">
                        <div class="col-12 d-flex align-items-center position-relative p-0 border-bottom-1">
                            <i class="fa fa-search ml-2 text-secondary"></i>
                            <input type="text" v-model.trim="search" class="form-control border-none pl-2" autocomplete="off" 
                            :placeholder="$t('message.PriceListDetailIndex.searchCategories')" @input="onInputFilter">
                        </div>
                    </div>
                    <div class="row mt-3 mx-0 list_categories">
                        <div class="col-12 position-relative p-0">
                            <template v-if="this.arrayCategories.filter?.length > 0">
                                <label class="form-check form-check-block pl-1">
                                    <label class="container-checkbox mb-0">
                                        {{$t('message.CouponIndex.all_categories')}}
                                        <input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </label>
                                <label class="form-check form-check-block pl-1" v-for="category in this.arrayCategories.filter" :key="category.id">
                                    <label class="container-checkbox mb-0">{{category.name}}
                                        <input type="checkbox" v-model="category.checked" @change.stop="countChecked(category.id)" :checked="isExistInArrayChecked(category, this.arrayChecked)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </label>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0">
                        <div class="col-5 d-flex justify-content-start align-items-center">
                            <a href="javascript:void(0);" class="text-primary text-decoration-none" @click="removeChecked()">{{$t('message.common.removeAllSelected')}}</a>
                        </div>
                        <div class="col-7 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-save mr-2" @click="submit">
                                <i class="fa fa-save mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.finished')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { $get, $post, $alert, removeVietnameseTones, cloneObject } from "@/lib/utils";
    export default {
        name: "SelectCategories",
        components: {},
        props: ['placeholder','disabled', 'label'],
        data() {
            return {
                search: null,
                arrayCategories: {
                    filter: [],
                    all: [],
                },
                checkedAll: false,
                arrayChecked: [],
            }
        },
        mounted() {
            this.getCategories();
        },
        methods: {
            async show(data = null, checkedAll) {
                // await this.getCategories();
                if(data){
                    this.arrayChecked = data;
                    this.checkedAll = checkedAll;
                    if(checkedAll){
                        this.onCheckedAll(true);
                    }
                }
                this.isCheckedAll();
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            submit(){
                let arraySelected = [];
                this.arrayCategories.all.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        arraySelected.push(entry);
                    }
                })
                this.$emit('update:modelValue', { data: arraySelected, selectAll: this.checkedAll, label: this.label});
                this.hide();
            },
            async getCategories(){
                const res = await $post('/v1/categories/get-all-data');
                if (res.status.code != 200) {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    return;
                } 
                if(res.result?.data){
                    this.arrayCategories.all = this.arrayCategories.filter = res.result.data;       
                }
            },
            onCheckedAll(value) {
                if(value == true){
                    this.arrayCategories.all.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id, true, false);
                        }
                    })
                    this.checkedAll = true;
                }else{
                    this.arrayCategories.all.forEach(entry => {
                        this.countChecked(entry.id, false, true);
                    })
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id, checkAll = false, noCheckAll = false){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                if(checkAll || noCheckAll) return;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.arrayCategories.all.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.arrayCategories.all.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onInputFilter(){
                if(this.search?.length == 0 || !this.search){
                    this.arrayCategories.filter = this.arrayCategories.all;
                    return;
                }
                let arr = [];
                for (let index = 0; index < this.arrayCategories.all.length; index++) {
                    if (this.arrayCategories.all[index] != null && removeVietnameseTones(this.arrayCategories.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.search.toLowerCase())) > -1){
                        arr.push(this.arrayCategories.all[index]);
                    }
                }
                this.arrayCategories.filter = arr;
            },
            async updateArrayChecked(array, isSelectAll = false){
                await this.getCategories();
                if(isSelectAll){
                    this.onCheckedAll();
                }else{
                    this.arrayChecked = array;
                }
                this.submit();
            }
        },
    }
</script>
<style scoped>
.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.list_categories{
    max-height: 300px !important;
    overflow-y: auto;
}
</style>
