<template> 
<AppPermission :permission="this.$permissions.printTemplates.data?? '' ">
    <div class="container">
        <div class="row m-0 p-0">          
            <TabTemplates @tabChange="tabChange"  @onValidate="onValidate"  ref="tab" :tabs="tabs"/>
        </div>
    </div>
</AppPermission>
</template>
<script>
import {markRaw} from "vue";
import TabTemplates from "../../../components/TabTemplates";
import OrderTemplate from "./OrderTemplate";
import InvoiceTemplate from './InvoiceTemplate';
import DeliveriesTemplate from './DeliveriesTemplate';
import ReturnTemplate from './ReturnTemplate';
import ReturnSupplierTemplate from './ReturnSupplierTemplate';
import ReceiptTemplate from './ReceiptTemplate';
import TransferTemplate from './TransferTemplate';
import PaymentTemplate from './PaymentTemplate';
import OrdForSupplierTemplate from './OrdForSupplierTemplate';
import ImportTemplate from './ImportTemplate.vue';
import {$get} from "@/lib/utils";
import emitter from "@/lib/emitter";
import AppPermission from '@/components/AppPermission';
import RefundsTemplate from './RefundsTemplate.vue';
import SettlementAdvanceTemplate from './SettlementAdvanceTemplate.vue';
import TicketImportTemplate from './TicketImportTemplate.vue';
import TicketExportTemplate from './TicketExportTemplate.vue';
import TicketTransferTemplate from './TicketTransferTemplate.vue';
import PlanDetailsTemplate from './PlanDetailsTemplate.vue';
import PlanImportBuyTemplate from './PlanImportBuyTemplate.vue';
import DiscountsBasTemplate from './DiscountsBasTemplate.vue';
export default {
    name: "ProductForm",   
    components: {TabTemplates, AppPermission},
    data() {      
        return {         
            tabs: [
                {id: this.$constant.TabOrder, showActionBar: true, name: this.$t('message.OrderIndex.button.order'), component: markRaw(OrderTemplate)},
                {id: this.$constant.TabInvoices, showActionBar: true, name: this.$t('message.InvoiceIndex.title'), component: markRaw(InvoiceTemplate)},
                {id: this.$constant.TabDeliveries, showActionBar: true, name: this.$t('message.common.delivery'), component: markRaw(DeliveriesTemplate)},
                {id: this.$constant.TabReturns, showActionBar: true, name: this.$t('message.InvoiceIndex.button.return'), component: markRaw(ReturnTemplate)},                    
                {id: this.$constant.TabOrderSuppliers, showActionBar: true, name: this.$t('message.headers.order_suppliers'), component: markRaw(OrdForSupplierTemplate)},
                {id: this.$constant.TabImports, showActionBar: true, name: this.$t('message.headers.purchase_products'), component: markRaw(ImportTemplate)},
                {id: this.$constant.TabReturnSuppliers, showActionBar: true, name: this.$t('message.headers.return_purchase_orders'), component: markRaw(ReturnSupplierTemplate)},
                {id: this.$constant.TabTransfers, showActionBar: true, name: this.$t('message.TransferIndex.title'), component: markRaw(TransferTemplate)},
                {id: this.$constant.TabReceipts, showActionBar: true, name: this.$t('message.CashBookIndex.modal.showDetail.title_receipt'), component: markRaw(ReceiptTemplate)},
                {id: this.$constant.TabPayment, showActionBar: true, name: this.$t('message.CashBookIndex.modal.showDetail.title_payment'), component: markRaw(PaymentTemplate)},
                // Khác
                {id: this.$constant.TabRefunds, showActionBar: true, name: this.$t('message.headers.request_refund'), component: markRaw(RefundsTemplate)},
                {id: this.$constant.TabSettlementAdvance, showActionBar: true, name: this.$t('message.SettlementAdvanceIndex.title'), component: markRaw(SettlementAdvanceTemplate)},
                {id: this.$constant.TabTicketImport, showActionBar: true, name: this.$t('message.common.importPosition'), component: markRaw(TicketImportTemplate)},
                {id: this.$constant.TabTicketExport, showActionBar: true, name: this.$t('message.common.exportPosition'), component: markRaw(TicketExportTemplate)},
                {id: this.$constant.TabTicketTransfer, showActionBar: true, name: this.$t('message.common.transferPosition'), component: markRaw(TicketTransferTemplate)},
                {id: this.$constant.TabPlanDetails, showActionBar: true, name: this.$t('message.headers.inventory_plan'), component: markRaw(PlanDetailsTemplate)},
                {id: this.$constant.TabPlanImportBuy, showActionBar: true, name: this.$t('message.headers.plan_purchase'), component: markRaw(PlanImportBuyTemplate)},
                {id: this.$constant.TabDiscountBas, showActionBar: true, name: this.$t('message.common.bas'), component: markRaw(DiscountsBasTemplate)},
            ],
            currentTab:null,
        }
    },
    methods: {
        tabChange(tab) {
            this.currentTab = tab;    
            this.load(this.currentTab); 
        },
        async load(tab, id = null) {            
           let printTemplate = null;
           const res = await $get(`/v1/print-templates/get-content-print-template`,{action: tab.id, id: id });              
           if(res && res.status.code == 200){
             if(res.result) {   
                     this.content = res.result.content;
                     this.setTabData({content: this.content})
             }
           }          
            const response = await $get(`/v1/print-templates/data`,{action: tab.id });             
            if(response.status.code == 200){
                if(response.result) {                    
                    printTemplate = response.result;
                }
                
            }
            this.setTabData({content: this.content, printTemplate: printTemplate})
         },
        setTabData(values) {
            this.$refs.tab.setTabData(values);
        },
    },
    mounted() {
        document.title = 'Mẫu in | OmiPos';
    }, 
    created() {
        emitter.on('reloadDataPrintTemplate',() => {       
            this.load(this.currentTab);             
        })
        emitter.on('loadDataContent',(content) => {       
            this.content = content
        })
        emitter.on('loadDataContentById',(id) => {       
            this.load(this.currentTab, id);
        })
    },

}
</script>

<style scoped>

</style>
