<template>
    <div class="form-group" :class="[(this.sCustomer ? 'my-2 px-0' : ''), (this.classCustom ? 'my-0' : '')]">
        <label v-if="label" v-text="label"></label>
        <input v-model="model" @input="onInput(true)" 
               v-if="this.cusNumber === true"
               :id="this.id"
               :readonly="!!readonly"
               :class="[(errors && errors.length ? 'error-input': ''), (borderBottom ? 'border-bottom-1 rounded-0' : ''), (paddingLeftNone ? 'pl-0' : ''), (this.sCustomer == true ? 'border-0 border-bottom-1 rounded-0' : ''), (this.classCustom ? this.classCustom : '')]"
               class="form-control" :placeholder="placeholder || label"
               :maxlength="this.max? this.max : ''"
               :type="'text'"
               v-on:keypress="NumbersOnly"              
               >       
        <input v-else v-model="model" @input="onInput(true)"         
               :readonly="!!readonly"
               :class="[(errors && errors.length ? 'error-input': ''), (borderBottom ? 'border-bottom-1 rounded-0' : ''), (paddingLeftNone ? 'pl-0' : ''), (this.sCustomer == true ? 'sCustomer border-0 border-bottom-1 rounded-0' : ''), (this.bgTransparent ? 'bg-transparent' : ''), (this.classCustom ? this.classCustom : '')]"
               class="form-control" :placeholder="placeholder || label" 
               :maxlength="this.max? this.max : ''"
               :id="this.id"
                @change="changeData"
               >
        <!-- <i class="fa fa-pencil-alt ml-2 text-secondary" v-if="this.note === true"></i> -->
        <ul class="error-label" v-if="errors && errors.length">
            <li v-for="error in errors" :key="error" v-text="error"></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "InputText",
        props: ['label', 'placeholder', 'modelValue', 'errors', 'datalist', 'readonly','max','cusNumber', 
        'note','dateData', 'id', 'borderBottom', 'paddingLeftNone', 'sCustomer', 'bgTransparent', 'classCustom'],
        data() {
            return {
                model: this.modelValue,            
            }
        },
        watch: {
            modelValue: function (newValue) {
                this.model = newValue;
            }
        },
        methods: {
            changeData(){                
                this.$emit('changeData');
            },
            NumbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            DateInputOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode!==47) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            onInput() {
                this.$emit('update:modelValue', this.model);
                this.$nextTick(() => {
                    this.$emit('input', this.model);
                })

            },
            isNumberic(e){
                var charCode = (e.which) ? e.which : e.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
                return true;
            }

        }
    }
</script>

<style scoped>
    .sCustomer{
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }
    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-weight: bolder;
        color: red;
    }

    .error-input, .error-input:hover,.error-input:focus,.error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
    /* .form-group i{
        position: absolute;
        top: 0.5rem;
    }
    /* .form-group input{
        padding-left: 1.5rem;
    } */

    
</style>
