<template>    
    <AppPermission :permission="this.$permissions.invoices.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{ $t('message.InvoiceIndex.title') }}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for="">{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t('message.InvoiceIndex.filter.branch') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <div class="col-12">
                                    <q-select v-model="filterQuery.branches" 
                                    :modelValue="filterQuery.branches" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.InvoiceIndex.filter.placeholder.branch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                                    <!-- <SelectSearch :placeholder="$t('message.InvoiceIndex.filter.placeholder.branch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"/> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.InvoiceIndex.filter.time') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.InvoiceIndex.filter.status') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <div class="row" v-for="status in this.listStatus" :key="status.id">
                                <div class="col-12 d-flex align-items-center">
                                    <label class="container-checkbox">{{status.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-8">
                                    <label class="typeTitle">{{ $t('message.InvoiceIndex.filter.saleChannel') }}</label>
                                </div>
                                <AppPermission :permission="this.$permissions.saleChannels.create?? '' ">
                                    <div class="col-1" @click="showModalAddInfoSaleChannels()">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon">
                                            <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                        </a>
                                    </div>
                                </AppPermission>
                                <div class="col-2 pl-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('saleChannelBody', 'saleChannelDown', 'saleChannelUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="saleChannelDown"></i>
                                        <i class="fa fa-chevron-up" id="saleChannelUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="saleChannelBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.InvoiceIndex.filter.placeholder.saleChannel')" :modelValue="filterQuery.saleChannel" :defaultOption="this.saleChannel.all" @update:modelValue="doFilterSearch" :label="'saleChannel'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.InvoiceIndex.filter.method') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('orderBody', 'orderDown', 'orderUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="orderDown"></i>
                                        <i class="fa fa-chevron-up" id="orderUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="orderBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.InvoiceIndex.filter.placeholder.method')" :modelValue="filterQuery.paymentMethod" :defaultOption="this.paymentMethods" @update:modelValue="doFilterSearch" :label="'paymentMethod'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.InvoiceIndex.filter.priceList')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('priceListBody', 'priceListDown', 'priceListUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="priceListDown"></i>
                                        <i class="fa fa-chevron-up" id="priceListUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="priceListBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.InvoiceIndex.filter.placeholder.priceList')" :modelValue="filterQuery.priceList" :defaultOption="this.priceLists" @update:modelValue="doFilterSearch" :label="'priceList'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.InvoiceIndex.filter.placeholder.search')" @filterSearch="changeQuickSearch" :invoicesSearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-ellipsis-v px-2"></i>{{$t('message.InvoiceIndex.button.action')}}
                                    </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <AppPermission :permission="this.$permissions.invoices.export?? '' ">
                                            <a class="dropdown-item" @click="exSelectOverview()">
                                                <i class="fa fa-share" ></i>
                                                {{$t('message.InvoiceIndex.button.export_all')}}
                                            </a>
                                            <a class="dropdown-item"  @click="exSelectDetails()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.InvoiceIndex.button.export_detail')}}
                                            </a>
                                            </AppPermission>
                                            <a class="dropdown-item"  @click="printMultiple()">
                                                <i class="fas fa-file-export"></i>
                                                {{$t('message.InvoiceIndex.button.print')}}
                                            </a>
                                            <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.button.sendMailText') }}
                                            </a>
                                            <a class="dropdown-item" @click="sendZalo()">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                            </a>
                                    </div>
                                </div>
                                <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                 <div class="dropdown">                                    
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fa fa-plus px-1"></i>
                                        {{$t('message.InvoiceIndex.button.invoice')}}
                                    </button>                                  
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">                                       
                                            <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                                <a class="dropdown-item" href="javascript:void(0);" @click="createInvoices()">
                                                    <i class="fa fa-plus px-1"></i>
                                                    {{$t('message.InvoiceIndex.button.add')}}
                                                </a>
                                                    <!-- <button class="btn btn-save ml-1" @click="createInvoices()"><i class="fa fa-plus px-1"></i>Thêm mới</button> -->
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.invoices.import?? '' ">
                                                <a class="dropdown-item" href="javascript:void(0);" @click="showInvoiceImport()">
                                                    <i class="fas fa-sign-in-alt mr-1" ></i>
                                                    {{$t('message.InvoiceIndex.button.import')}}
                                                </a>
                                            </AppPermission>
                                        </div>                                    
                                </div>
                                </AppPermission>
                                <!-- <button class="btn btn-save ml-1"><i class="fa fa-copy px-1"></i>Gộp đơn</button> -->
                                 <AppPermission :permission="this.$permissions.invoices.export?? '' ">
                                <div class="dropdown">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-file-export px-1"></i>
                                        {{$t('message.InvoiceIndex.button.file')}}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">                                                                              
                                        <a class="dropdown-item" href="javascript:void(0);" @click="exOverview()">
                                            <i class="fas fa-file-export mr-1"></i>
                                            {{$t('message.InvoiceIndex.button.file_all')}}
                                        </a>                                        
                                        <a class="dropdown-item" href="javascript:void(0);" @click="exDetails()">
                                            <i class="fas fa-external-link-alt mr-1"></i>
                                            {{$t('message.InvoiceIndex.button.file_detail')}}
                                        </a>                                        
                                    </div>
                                </div>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="invoice"></SelectField>
                                </div> 
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                    <tr class="master-row"  :class="entry.id === this.invoiceActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected && field.field !='customerContactNumber'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailInvoice(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                            <td class="ow-break-word py-0" v-else-if="field.selected && field.field ==='customerContactNumber'" style="vertical-align:middle !important;"
                                                            :style="'min-width:110px'" >
                                                                <template v-if="entry.customerContactNumber.length > 1" >
                                                                    <select  class="sltUnit">
                                                                        <option class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></option>
                                                                    </select>
                                                                </template>
                                                                <template v-else-if="entry.customerContactNumber.length == 1" >
                                                                    <span class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></span>
                                                                </template>  
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="invoiceActive === entry.id" :class="[ entry.id === invoiceActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                           <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2"  @click="showContent(this.$constant.tabInformation)">
                                                                    <span :class=" this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{$t('message.InvoiceIndex.tabs.info')}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryReturn)" v-if="(entry.returns && entry.returns.length > 0)" >                                                      
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryReturn ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.InvoiceIndex.tabs.history_return')}}
                                                                    </span>
                                                                </div>
                                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryPayment)" v-if="entry?.historyPayment && entry?.historyPayment?.length" >                                                      
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.InvoiceIndex.tabs.history_payment')}}
                                                                    </span>
                                                                </div>
                                                                 <!--  -->
                                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryDelivery)" v-if="entry?.delivery_status && entry.delivery_status.length > 0">
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryDelivery ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.InvoiceIndex.tabs.history_delivery')}}
                                                                    </span>
                                                                </div>
                                                                <!-- v-if="entry?.delivery_status && entry.delivery_status.length > 0" -->
                                                                <!-- Chi tiết giao hàng -->
                                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabDetailsDelivery)" 
                                                                     v-if="entry?.delivery_status && entry.delivery_status.length > 0" >
                                                                    <span :class=" this.tabActive == this.$constant.tabDetailsDelivery ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.InvoiceIndex.tabs.detail_delivery')}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Hiển thị tab Lịch sử thông tin -->
                                                    <template v-if="this.tabActive == this.$constant.tabInformation">
                                                        <!-- Chi tiết phiếu chuyển hàng -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id" >
                                                            <td class="border-top-0"></td>
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row">
                                                                        <div class="col-4">
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.InvoiceIndex.code')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">                                                            
                                                                                    <b>{{ entry.code }}</b>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5 lableOS">
                                                                                    {{$t('message.InvoiceIndex.time')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word lableOS pr-0">                                                            
                                                                                    {{ dt(entry.purchaseDate) }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.InvoiceIndex.customer')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :class="entry.customer && entry.customer.code ? 'text-primary' : ''" @click="entry.customer && entry.customer.code ? linkToCustomer(entry.customer.code) : ''">
                                                                                    {{ entry.customerName }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.InvoiceIndex.price_list')}}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    {{entry.priceListName}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{$t('message.InvoiceIndex.order_code')}}: 
                                                                                </div>
                                                                                <div class="col-7 ow-break-word">
                                                                                    <span @click="linkToOrder(entry.orderCode)" v-text="entry.orderCode" class="text-primary" v-if="entry.orderCode"></span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="mx-1">
                                                                                <div class="col-12">
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5 lableOS">
                                                                                            {{$t('message.InvoiceIndex.status')}}:
                                                                                        </div>
                                                                                        <div class="col-7 ow-break-word">
                                                                                            {{ entry.status }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.InvoiceIndex.branch')}}:
                                                                                        </div>
                                                                                        <div class="col-7 ow-break-word">
                                                                                            {{ entry.branchName }}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1" :class="entry.statusOrigin == this.$constant.status_complete_invoice ? 'pt-2 pb-1' : 'mt-2 py-2'">
                                                                                        <div class="col-5 d-flex align-items-center">
                                                                                            {{$t('message.InvoiceIndex.employee')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">
                                                                                            <InputSelectSearch :defaultValue="$t('message.InvoiceIndex.filter.placeholder.sold_by')" :modelValue="entry.soldById" :defaultOption="users" @update:modelValue="setSoldBy" v-if="entry.statusOrigin == this.$constant.status_complete_invoice"></InputSelectSearch>
                                                                                            <span v-else>{{ entry.soldByName }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.InvoiceIndex.created_by')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">                                                                    
                                                                                            {{entry.userCreatedName}}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1" :class="entry.statusOrigin == this.$constant.status_complete_invoice ? 'pt-2 pb-1' : 'mt-2 py-2'">
                                                                                        <div class="col-5 d-flex align-items-center">
                                                                                            {{$t('message.InvoiceIndex.sale_channel')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word">
                                                                                            <InputSelectSearch :defaultValue="$t('message.InvoiceIndex.filter.placeholder.saleChannel')" :modelValue="entry.saleChannelId" :defaultOption="saleChannel.all" @update:modelValue="setSaleChannel" v-if="entry.statusOrigin == this.$constant.status_complete_invoice"></InputSelectSearch>
                                                                                            <span v-else>{{ entry.saleChannelName }}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                                        <div class="col-5">
                                                                                            {{$t('message.InvoiceIndex.method')}}:
                                                                                        </div>                                                                
                                                                                        <div class="col-7 ow-break-word"> 
                                                                                            <template :key="n" v-for="(n, index) in entry.paymentMethods">     
                                                                                                <template v-if="index != (entry.paymentMethods.length - 1)">
                                                                                                    <span>{{ n + ', '}}</span>
                                                                                                </template>
                                                                                                <template v-if="index === (entry.paymentMethods.length - 1)">
                                                                                                    <span>{{ n }}</span>
                                                                                                </template>
                                                                                            </template>                                                
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4" id="noteRO">  
                                                                            <div class="row p-0 font-italic">
                                                                                <div class="col-12" v-if="entry.statusOrigin == this.$constant.status_complete_invoice || (entry.delivery)">
                                                                                    <textarea v-model.trim="entry.description" :id="'note_'+ entry.id" :placeholder="$t('message.InvoiceIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                </div>
                                                                                <div class="col-12 text-gray" v-else>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                    <span class="noteStyle px-4 text-gray font-italic">
                                                                                        {{ entry.description ? entry.description : $t('message.InvoiceIndex.note')}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id && entry.delivery">
                                                            <td :colspan="fields.length + 1" class="border-top-0 px-0">
                                                                <div class="row pt-4 px-4 pb-2 m-0 detail-prd-row bg-light">
                                                                    <div class="col-12 text-bold px-0">
                                                                        <h6 class="text-bold">{{ $t('message.InvoiceIndex.tabs.delivery.title') }}:</h6>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-3 m-0 detail-prd-row bg-light">
                                                                    <div class="col-12 d-flex px-0">
                                                                        <span>{{ $t('message.InvoiceIndex.tabs.delivery.address_receive') }}:</span>
                                                                        <span>
                                                                            {{ entry.delivery && entry.delivery.branch_address 
                                                                                ? entry.delivery.branch_address.addressValue 
                                                                                    + ( entry.delivery.branch_address.ward ? ', ' + entry.delivery.branch_address.ward.name : null )  
                                                                                    + ( entry.delivery.branch_address.district ? ', ' + entry.delivery.branch_address.district.name : null )  
                                                                                    + ( entry.delivery.branch_address.province ? ', ' + entry.delivery.branch_address.province.name : null )  
                                                                                    + ' - ' 
                                                                                    + entry.delivery.branch_address.phone 
                                                                                : '' 
                                                                            }}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="!entry.delivery.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2 ">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.customer_address.name">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.name : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.contact_number') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <InputText v-model.trim="entry.delivery.customer_address.phone" 
                                                                                            placeholder="Nhập số điện thoại" :max="10" 
                                                                                        :cusNumber="true" :classCustom="'border-bottom-1 w-100 border-bottom-focus-active rounded-0 pl-0'"/>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.phone : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.address') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="entry.delivery.customer_address && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <!-- <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.customer_address.addressValue"> -->
                                                                                        <textarea class="border-0 bg-transparent border-bottom-focus-active w-100 textarea-outline-none" v-model.trim="entry.delivery.customer_address.addressValue"></textarea>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.addressValue : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.area') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address 
                                                                                        && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.customer_address.districtId" 
                                                                                                        :modelValue="entry.delivery.customer_address.districtId" 
                                                                                                        :defaultOption="listAreaDelivery" :label="'areaDelivery_' + entry.id" 
                                                                                                        @update:modelValue="changeDistrict(entry.delivery.customer_address)"
                                                                                                        @setNewData="setAreaDelivery"
                                                                                                        :areaSearch="true" 
                                                                                                        :readonly="true">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.districts 
                                                                                                        ? entry.delivery.customer_address.districts.name + (entry.delivery.customer_address.provinces ? ' - ' + entry.delivery.customer_address.provinces.name : null) : (entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.provinces ? entry.delivery.customer_address.provinces.name 
                                                                                                        : null)">
                                                                                        </span>
                                                                                    </template>
                                                                                
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.ward') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.delivery.customer_address 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.customer_address.wardId" 
                                                                                                        :modelValue="entry.delivery.customer_address.wardId" 
                                                                                                        :defaultOption="listWards" :label="'wards_' + entry.id" 
                                                                                                        :areaSearch="true" 
                                                                                                        :readonly="true">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.wards ? entry.delivery.customer_address.wards.name : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-12 d-flex pr-0">
                                                                                    <i class="fa fa-pencil-alt text-gray"></i>
                                                                                    <div class="w-100 font-italic text-gray" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                        <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                            <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.note">
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="entry.delivery.note"></span>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_code') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.code">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span class="text-primary" v-text="entry.delivery && entry.delivery.code ? entry.delivery.code : null" @click="entry.delivery && entry.delivery.code ? linkToDelivery(entry.delivery.code) : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_status') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.status" :modelValue="entry.delivery.status" :defaultOption="this.listStatusDeliveryAll" :label="'status_' + entry.id" :readonly="true"></SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.statusName ? entry.delivery.statusName : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.weight') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <div class="row m-0 p-0 d-flex align-items-end">
                                                                                            <div class="col-lg-8 px-0 d-flex align-items-end pr-2">
                                                                                                <InputNumberFormat :pxNone="true" v-model="entry.delivery.weight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                            </div>
                                                                                            <div class="col-lg-4 px-0">
                                                                                                <SelectSearch :vModel="true" v-model="entry.delivery.weightUnit" :modelValue="entry.delivery.weightUnit" :defaultOption="listWeightUnit" :label="'weightUnit_' + entry.id" :readonly="true"></SelectSearch>
                                                                                            </div>
                                                                                        </div>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.weight ? n(entry.delivery.weight) + ' ' + entry.delivery.weightUnitValue : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.size') }}:</div>
                                                                                <div class="col-7 px-0 d-flex">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <div class="column-size">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.long" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="column-size mx-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.width" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="column-size">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.delivery.hight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <div class="column-size" v-text="entry.delivery && entry.delivery.long ? n(entry.delivery.long) + ' x' : null"></div>
                                                                                        <div class="column-size mx-2" v-text="entry.delivery && entry.delivery.width ? n(entry.delivery.width) + ' x' : null"></div>
                                                                                        <div class="column-size" v-text="entry.delivery && entry.delivery.hight ? n(entry.delivery.hight) + ' cm' : null"></div>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.control_code') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" class="border-0 bg-transparent border-bottom-focus-active w-100" v-model.trim="entry.delivery.control_code">
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.delivery && entry.delivery.code ? entry.delivery.control_code : null"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.service') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.service_type ? entry.delivery.service_type.name : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectSearch v-model="entry.delivery.serviceTypeId" :modelValue="entry.delivery.serviceTypeId" :defaultOption="listServiceType" :label="'serviceTypeId_' + entry.id" :readonly="true" :vModel="true"></SelectSearch>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="bg-white h-100 pt-2">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_by') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.name : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectSearch :vModel="true" v-model="entry.delivery.transporterId" :modelValue="entry.delivery.transporterId" :defaultOption="listTransporterSelfCreated" :label="'transporterId_' + entry.id" :areaSearch="true" :readonly="true"></SelectSearch>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <label class="container-checkbox">
                                                                                        <b v-text="n(entry.delivery.priceCodPayment)" v-if="entry.delivery.usingPriceCod"></b>
                                                                                        <input type="checkbox" class="chkType" v-model="entry.delivery.usingPriceCod" 
                                                                                        :checked="entry.delivery && entry.delivery.usingPriceCod ? true : false" 
                                                                                        :readonly="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.delivery.priceCodPayment > entry.priceCodPaymentStill)) ? true : false" 
                                                                                        :disabled="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.delivery.priceCodPayment > entry.priceCodPaymentStill)) ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod_still') }}:</div>
                                                                                <div class="col-7 px-0" v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price') }}:</div>
                                                                                <div class="col-7 px-0">
                                                                                    <template v-if="entry.statusUpdate != this.$constant.status_update_delivery_3 && entry.statusUpdate != this.$constant.status_update_delivery_4">
                                                                                        <span v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <InputNumberFormat :pxNone="true" v-model="entry.delivery.price" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_still') }}:</div>
                                                                                <div class="col-7 px-0" v-text="n(entry.priceStill)"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_time') }}:</div>
                                                                                <div class="col-7 px-0" :class="entry.statusUpdate != this.$constant.status_update_delivery_1 ? 'border-bottom' : ''">
                                                                                    <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                        <span v-text="entry.delivery && entry.delivery.deliveryTime ? dt(entry.delivery.deliveryTime) : null"></span>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <SelectDateTimePicker :modelValue="entry.delivery.deliveryTime" v-model="entry.delivery.deliveryTime" :borderActive="true" :backgroundColor="'bg-white'"/>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="entry.delivery.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2 ">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.name : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.contact_number') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.phone : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.address') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address ? entry.delivery.customer_address.addressValue : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.area') }}:</div>
                                                                                <div class="col-7 "
                                                                                    v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.districts ? entry.delivery.customer_address.districts.name + (entry.delivery.customer_address.provinces ? ' - ' + entry.delivery.customer_address.provinces.name : null) : (entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.provinces ? entry.delivery.customer_address.provinces.name : null)">
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.ward') }}:</div>
                                                                                <div class="col-7 " v-text="entry.delivery && entry.delivery.customer_address && entry.delivery.customer_address.wards ? entry.delivery.customer_address.wards.name : null"></div>
                                                                            </div>
                                                                            <!-- <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-12 d-flex">
                                                                                    <i class="fa fa-pencil-alt text-gray"></i>
                                                                                    <div class="text-gray w-100" v-text="entry.delivery.note ? entry.delivery.note : 'Ghi chú giao'"></div>
                                                                                </div>
                                                                            </div> -->
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_code') }}:</div>
                                                                                <div class="col-7 text-primary" v-if="entry.delivery && entry.delivery.code" 
                                                                                    v-text="entry.delivery && entry.delivery.code ? entry.delivery.code : null" 
                                                                                    @click="entry.delivery && entry.delivery.code ? linkToDelivery(entry.delivery.code) : null">
                                                                                </div>
                                                                                <div class="col-7 text-primary" v-else>
                                                                                    <i class="fa fa-spinner fa-spin"></i>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_status') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.statusName ? entry.delivery.statusName : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2" v-if="entry.delivery && entry.delivery.noteCancel">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.note_cancel') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery ? entry.delivery.noteCancel : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.weight') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.weight ? n(entry.delivery.weight) + (entry.delivery.weightUnitValue ? ' ' + entry.delivery.weightUnitValue : null) : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.size') }}:</div>
                                                                                <div class="col-7">
                                                                                    {{ entry.delivery.long ? n(entry.delivery.long) : null }}
                                                                                    {{ entry.delivery.width ? ' x ' + n(entry.delivery.width) : null }}
                                                                                    {{ entry.delivery.hight ? ' x ' + n(entry.delivery.hight) + ' cm' : null }}
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.conversion_exchange') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_declaration') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        (
                                                                                            {{$t('message.common.productValue')}}: {{n(entry.delivery.isInSurance ? entry.delivery.inSuranceValue : 0)}}
                                                                                        )
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery.isInSurance ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.take_to_office') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery.isTakePostOffice ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.pay_fee_by') }}:</div>
                                                                                <div class="col-7">
                                                                                    <div>
                                                                                        <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.paid_by') }}
                                                                                            <input type="radio" name="fee" :checked="entry.delivery.payFeeShip == this.$constant.paid_by_sender || entry.delivery.payFeeShip == this.$constant.paid_by_app ? true : false" :readonly="true" :disabled="true">
                                                                                            <span class="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div>
                                                                                        <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}
                                                                                            <input type="radio" name="fee" :checked="entry.delivery.payFeeShip == this.$constant.paid_by_receiver ? true : false" :readonly="true" :disabled="true">
                                                                                            <span class="checkmark"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.note_delivery') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery.note"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.discount_code') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="bg-white h-100">
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.delivery_by') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.name : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.estimate_delivery') }}:</div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.service') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.service_type ? entry.delivery.service_type.name : null"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.control_code') }}:<i class="fa fa-info-circle"></i></div>
                                                                                <div class="col-7"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod') }}:</div>
                                                                                <div class="col-7">
                                                                                    <label class="container-checkbox">
                                                                                        <b v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.delivery.priceCodPayment) : 0"></b>
                                                                                        <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.delivery && entry.delivery.usingPriceCod ? true : false">
                                                                                        <span class="checkmark-checkbox"></span>
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.using_price_cod_still') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.total_fee') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.price_still') }}:</div>
                                                                                <div class="col-7" v-text="n(entry.priceStill)"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.paid_by_receiver_value') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.paidByReceiverValue ? n(entry.delivery.paidByReceiverValue) : 0"></div>
                                                                            </div>
                                                                            <div class="row m-0 p-2 pb-4">
                                                                                <div class="col-5">{{ $t('message.InvoiceIndex.tabs.delivery.total_receive') }}:</div>
                                                                                <div class="col-7" v-text="entry.delivery && entry.delivery.amountCollectReceiver ? n(entry.delivery.amountCollectReceiver) : 0"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="row px-4 py-4 m-0 detail-prd-row bg-light">
                                                                    <div class="col-lg-12 px-0 flex-end">
                                                                        <!-- Phiếu in giao hàng -->
                                                                        <AppPermission :permission="this.$permissions.deliveries.print?? '' ">
                                                                            <button class="btn btn-white border mx-1 bg-white" @click.stop="printMultipleDelivery(entry.delivery.id)">
                                                                                <i class="fa fa-print"></i>
                                                                                {{ $t('message.InvoiceIndex.button.print_delivery') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                         <AppPermission :permission="this.$permissions.deliveries.cancel?? '' ">
                                                                            <button class="btn btn-white border mx-1 bg-white" v-if="entry.delivery.transporterIntegration && $constant.LIST_STATUS_DELIVERY_CAN_CANCEL.includes(entry.lastDeliveryStatusOrigin)" @click="ShowModalCancelDelivery(entry)">
                                                                                <i class="fa fa-ban"></i>
                                                                                {{ $t('message.InvoiceIndex.button.cancel_delivery') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.deliveries.create?? '' ">
                                                                            <button class="btn btn-white border mx-1 bg-white" 
                                                                                v-if="entry.delivery.transporterIntegration && $constant.STATUS_DELEIVEY_CANCEL.includes(entry.lastDeliveryStatusOrigin)"
                                                                                @click="ShowModalCreateDelivery(entry)">
                                                                                <i class="fa fa-plus"></i>
                                                                                {{ $t('message.InvoiceIndex.button.create_delivery') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                         <AppPermission :permission="this.$permissions.deliveries.payment?? '' ">
                                                                            <button class="btn btn-white border mx-1 bg-white" @click="paymentAndCreateRefund(entry)">
                                                                                <i class="fa fa-calculator"></i>
                                                                                {{ $t('message.InvoiceIndex.button.payment_with_transporter') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <!-- Chi tiet san pham va gia -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id" >
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                                        <div class="col-12 pl-0">
                                                                            <table class="w-100">
                                                                                <thead>
                                                                                    <tr class="backgroup__detail">
                                                                                        <template v-for="fieldDetail in this.fieldInvoiceDetail" :key="fieldDetail">                                                                                     
                                                                                            <!-- HsCode   -->
                                                                                            <th scope="col" class="pl-4"  v-if="fieldDetail.field =='hsCode' && entry.isHsCode == this.$constant.hsCodeShow" :style="fieldDetail.style ? fieldDetail.style : ''" v-text="$t(fieldDetail.name)" ></th>
                                                                                            <!-- Thuế suất --> 
                                                                                            <th scope="col" class="pl-4"  v-else-if="fieldDetail.field =='taxRate' && entry.isTaxRate == this.$constant.taxRateShow" :style="fieldDetail.style ? fieldDetail.style : ''">
                                                                                                <span>{{fieldDetail.name? $t(fieldDetail.name) : ''}}</span>
                                                                                            </th>
                                                                                            <th scope="col" class="pl-4"  v-else-if="fieldDetail.selected" :style="fieldDetail.style ? fieldDetail.style : ''" v-text="$t(fieldDetail.name)"></th>
                                                                                        </template>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    <tr class="bg-white">
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultInvoice.searchCode" @input="onInputFilterInvoiceDetail('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4"> 
                                                                                            <input type="text" v-model="this.defaultInvoice.searchName" @input="onInputFilterInvoiceDetail('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                                        </th>
                                                                                        <th scope="col" class="pl-4" :colspan="fieldInvoiceDetail.length - 2"> 
                                                                                        </th>
                                                                                    </tr>
                                                                                    <template v-if="this.defaultInvoice.filter?.length > 0">
                                                                                        <template v-for="(invoiceDetail, index) in this.defaultInvoice.filter" :key="invoiceDetail.id">
                                                                                            <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                                                <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                                    <td class="pl-4">
                                                                                                        <div class="row m-0 d-flex">
                                                                                                            <div class="col-1 p-0">
                                                                                                                <i class="fa fa-chevron-down" v-if="invoiceDetail.children_combo && invoiceDetail.children_combo.length > 0 && !invoiceDetail.showCombo" @click="showCombo(invoiceDetail)"></i>
                                                                                                                <i class="fa fa-chevron-up" v-if="invoiceDetail.children_combo && invoiceDetail.children_combo.length > 0 && invoiceDetail.showCombo" @click="showCombo(invoiceDetail)"></i>
                                                                                                            </div>
                                                                                                            <div class="col-10 p-0">
                                                                                                                <a :href="'/products/index?code=' + invoiceDetail.productCode" target="_blank" class="text-decoration-none text-primary">{{invoiceDetail.productCode}}</a>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td class="pl-4">
                                                                                                        <span class="col-12 pl-0" v-text="invoiceDetail.productName"></span>
                                                                                                        <!-- <template v-if="invoiceDetail.consignments">
                                                                                                            <div v-for="(value, index) in invoiceDetail.consignments" :key="index" class="mr-1 mt-1 mb-2">
                                                                                                                <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                                            </div>
                                                                                                        </template> -->
                                                                                                        <span class="col-12 pl-0 description font-italic text-gray" v-text="invoiceDetail.description" :title="invoiceDetail.description"></span>
                                                                                                    </td>
                                                                                                    <td v-text="invoiceDetail.unitName" class="text-center"></td>
                                                                                                    <td v-text="invoiceDetail.attributeName" class="text-center"></td>
                                                                                                    <!-- Hiển thị taxRate -->
                                                                                                    <td v-if="entry.isHsCode == this.$constant.hsCodeShow" v-text="invoiceDetail.hsCode" class="text-center"></td>
                                                                                                    <td v-if="entry.isTaxRate == this.$constant.taxRateShow" class="text-center">
                                                                                                        {{invoiceDetail.taxRate}}%
                                                                                                    </td>

                                                                                                    <td class="pl-4 text-right">{{numberFloat(invoiceDetail.quantity)}}</td>
                                                                                                    <td v-text="n(invoiceDetail.price)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(invoiceDetail.discount)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(invoiceDetail.price - invoiceDetail.discount)" class="pl-4 text-right"></td>
                                                                                                    <td v-text="n(invoiceDetail.subTotal)" class="pl-4 text-right"></td>
                                                                                                </tr>
                                                                                                <template v-if="invoiceDetail.consignments">
                                                                                                    <tr class="bg-white" v-for="(value, consignmentIndex) in invoiceDetail.consignments" :key="consignmentIndex">
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td class="pl-4">
                                                                                                            <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + numberFloat(value.quantity)}}</span>
                                                                                                        </td>
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td class="pl-4"></td>
                                                                                                        <td :colspan="4"></td>
                                                                                                    </tr>
                                                                                                </template>
                                                                                                <template v-if="invoiceDetail.children_combo && invoiceDetail.children_combo.length > 0 && invoiceDetail.showCombo">
                                                                                                    <template v-for="(productInCombo, key) in invoiceDetail.children_combo" :key="key">
                                                                                                        <tr class="backgroup__detail">
                                                                                                            <td class="pl-4">
                                                                                                                <div class="row m-0 d-flex">
                                                                                                                    <div class="col-1 p-0">
                                                                                                                    </div>
                                                                                                                    <div class="col-10 p-0 pl-2">
                                                                                                                        <span v-text="(key + 1) + '. '"></span>
                                                                                                                        <a :href="'/products/index?code=' + productInCombo.product.code" target="_blank" class="text-decoration-none text-primary">{{productInCombo.product.code}}</a>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </td>
                                                                                                            <td class="pl-4">{{ productInCombo.product.name }}</td>
                                                                                                            <td class="pl-4">{{ productInCombo.product_unit.unitName }}</td>
                                                                                                            <td class="pl-4 text-right">{{ numberFloat(productInCombo.quantity) }}</td>
                                                                                                            <td :colspan="4"></td>
                                                                                                        </tr>
                                                                                                        <template v-if="productInCombo.consignments">
                                                                                                            <tr class="backgroup__detail" v-for="(invoice_consignment_detail, keyDetail) in productInCombo.consignments" :key="keyDetail">
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td class="pl-4">
                                                                                                                    <span class="consignment_name mr-2 text-white">{{ invoice_consignment_detail.name + ' - ' + d(invoice_consignment_detail.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + numberFloat(invoice_consignment_detail.quantity)}}</span>
                                                                                                                </td>
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td class="pl-4"></td>
                                                                                                                <td :colspan="4"></td>
                                                                                                            </tr>
                                                                                                        </template>
                                                                                                    </template>
                                                                                                </template>
                                                                                            </template>
                                                                                        </template>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <tr class="text-center bg-light">
                                                                                            <td :colspan="fieldInvoiceDetail.length">{{$t('message.common.notFound')}}</td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </tbody>
                                                                            </table>
                                                                            <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-4 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.total_quantity') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalProduct)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.total_origin') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.totalOrigin)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.discount') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.discountPrice)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.surcharge') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.invoiceOrderSurcharges)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.total') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="n(entry.total)"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.total_payment') }}:</div>
                                                                        <div class="col-2 flex-end">
                                                                            <span v-if="entry.delivery" 
                                                                                v-text="+entry.delivery.totalBankBookReceipt + +entry.delivery.totalCashBookReceipt - +entry.delivery.totalCashBookReceiptCancel + +entry.totalPayment > 0 
                                                                                ? n(+entry.delivery.totalBankBookReceipt + +entry.delivery.totalCashBookReceipt - +entry.delivery.totalCashBookReceiptCancel + +entry.totalPayment) 
                                                                                : n(entry.totalPayment)">
                                                                            </span>
                                                                            <span v-else v-text="n(entry.totalPayment)"></span>
                                                                        </div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.shipping_fee_collected_by_customer') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="entry.delivery && entry.delivery.priceOrigin ? n(entry.delivery.priceOrigin) : 0"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row mt-2 pr-4">
                                                                        <div class="col-10 flex-end">{{ $t('message.InvoiceIndex.fees_paid_to_delivery_partners') }}:</div>
                                                                        <div class="col-2 flex-end" v-text="entry.delivery && entry.delivery.price ? n(entry.delivery.price) : 0"></div>
                                                                    </div>
                                                                    <div class="row detail-prd-row flex-end mt-4 pr-4" >  
                                                                        <button type="button" class="btn btn-save mx-1" @click="sendZalo(entry)">
                                                                            <i class="fa fa-share"></i>
                                                                            {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                                        </button>
                                                                        <!--Đối với hóa đơn có vận đơn  -->                                                                  
                                                                        <template v-if="entry.delivery">
                                                                            <button type="button" class="btn btn-save mx-1" @click="createRefund(entry)" 
                                                                                v-if="this.statusCanRequestRefund && entry.statusOrigin != this.$constant.status_cancel_invoice && entry.branchId == currentBranchId && entry.delivery.status == this.$constant.status_delivery_complete">
                                                                                <i class="fa fa-reply"></i>
                                                                                {{ $t('message.InvoiceIndex.button.refund') }}
                                                                            </button>
                                                                            <AppPermission :permission="this.$permissions.returns.create?? '' "> 
                                                                                <button type="button" 
                                                                                v-if="(entry.refunds_count == 0 && entry.statusOrigin == this.$constant.status_complete_invoice && entry.branchId == currentBranchId && !entry.statusSuccess && entry.delivery.status == this.$constant.status_delivery_complete)"
                                                                                class="btn btn-save mx-1" @click="returns(entry)">
                                                                                    <i class="fa fa-reply-all"></i>
                                                                                    {{ $t('message.InvoiceIndex.button.return') }}
                                                                                </button>
                                                                            </AppPermission>
                                                                            <AppPermission :permission="this.$permissions.invoices.update?? '' ">
                                                                                <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.branchId == currentBranchId">
                                                                                    <i class="fa fa-save"></i>
                                                                                    {{ $t('message.InvoiceIndex.button.save') }}
                                                                                </button>
                                                                            </AppPermission>
                                                                        </template> 
                                                                         <!--Đối với hóa đơn không có vận đơn  -->
                                                                        <template v-else>
                                                                            <button type="button" class="btn btn-save mx-1" @click="createRefund(entry)" 
                                                                                v-if="this.statusCanRequestRefund && entry.statusOrigin != this.$constant.status_cancel_invoice && entry.branchId == currentBranchId">
                                                                                <i class="fa fa-reply"></i>
                                                                                {{ $t('message.InvoiceIndex.button.refund') }}
                                                                            </button>
                                                                            <AppPermission :permission="this.$permissions.returns.create?? '' "> 
                                                                                <button type="button" 
                                                                                v-if="(entry.refunds_count == 0 && entry.statusOrigin == this.$constant.status_complete_invoice && entry.branchId == currentBranchId && !entry.statusSuccess)"
                                                                                class="btn btn-save mx-1" @click="returns(entry)">
                                                                                    <i class="fa fa-reply-all"></i>
                                                                                    {{ $t('message.InvoiceIndex.button.return') }}
                                                                                </button>
                                                                            </AppPermission>
                                                                            <AppPermission :permission="this.$permissions.invoices.update?? '' ">
                                                                                <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.statusOrigin == this.$constant.status_complete_invoice && entry.branchId == currentBranchId">
                                                                                    <i class="fa fa-save"></i>
                                                                                    {{ $t('message.InvoiceIndex.button.save') }}
                                                                                </button>
                                                                            </AppPermission>
                                                                        </template>
                                                                        <!-- Phiếu in Hóa đơn -->
                                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="getTemplatePrint(entry)">
                                                                            <i class="fa fa-print" aria-hidden="true"></i>
                                                                            {{ $t('message.InvoiceIndex.button.print') }}
                                                                        </button>
                                                                        <AppPermission :permission="this.$permissions.invoices.export?? '' ">
                                                                            <button type="button" class="btn btn-secondary text-white mx-1"  @click="exOrdersById(entry.id)">
                                                                                <i class="fa fa-file-export" aria-hidden="true"></i>
                                                                                {{ $t('message.InvoiceIndex.button.export') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.invoices.create?? '' ">
                                                                            <button type="button" class="btn btn-save mx-1" @click="copyInvoices(entry.code)">
                                                                                <i class="fa fa-copy" aria-hidden="true"></i>
                                                                                {{ $t('message.InvoiceIndex.button.copy') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.invoices.cancel?? '' ">
                                                                            <button type="button" class="btn btn-danger mx-1"  @click="ShowModalInvoicesAbort(entry)" 
                                                                                v-if="((  
                                                                                            this.$constant.list_status_can_cancel_invoice.includes(entry.statusOrigin) && 
                                                                                            (
                                                                                                !entry.delivery 
                                                                                                || $constant.LIST_STATUS_DELIVERY_HANDMADE_CAN_CANCEL.includes(entry.delivery.status)
                                                                                            )
                                                                                        ) 
                                                                                        && entry.branchId == currentBranchId && entry.returns_count == 0)">
                                                                                <i class="fa fa-times"></i>
                                                                                {{ $t('message.InvoiceIndex.button.cancel') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Hiển thị tab Lịch sử trả hàng-->
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryReturn">                                                    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row" v-if="entries.length > 0">
                                                                    <div class="col-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="" style="width:30px !important;">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_return.code') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_return.time') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_return.receive_by') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table text-center">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_return.total_price') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_return.status') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                           <tbody> 
                                                                                <template v-if="entry.returns.length > 0">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.returns" :key="index">                                                                 
                                                                                        <td scope="col" class="" style="width:50px !important;">
                                                                                            <span class="text-primary" @click="linkToReturn(history.code)">{{history?.code ? history.code : ''}}</span>
                                                                                        </td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.returnDate ? dt(history.returnDate) : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.receiver ? history.receiver.name : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table text-center"><span>{{history?.returnTotal ? n(history.returnTotal) : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="5" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Hiển thị tab Lịch sử thanh toán -->
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryPayment">                                                    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 detail-prd-row" v-if="entries.length > 0" id="divTable">
                                                                    <div class="col-12">
                                                                        <table class="w-100" style="max-width:100%;">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" class="" style="width:30px !important;">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.code') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.time') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.created_by') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.value') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.method') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.status') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table text-center">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.total_price') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                           <tbody> 
                                                                                <template v-if="entry.historyPayment.length > 0">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                                        <td scope="col" class="" style="width:50px !important;">
                                                                                            <a href="#" class="text-decoration-none text-primary" @click.stop="showModalDataPayment(history.id, history.type, this.$constant.typeInvoice , history)">                                                                                            
                                                                                                <span>{{history?.code ? history.code : ''}}</span>
                                                                                            </a>
                                                                                        </td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdAt ? history.createdAt : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table">
                                                                                            {{n( history.total) }}
                                                                                        </td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                        <td scope="col" class="checkbox-table text-center"><span>{{history?.payment ? history.payment : ''}}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="6" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Hiển thị tab Lịch sử giao hàng -->                                                   
                                                    <template v-if="(this.tabActive == this.$constant.tabHistoryDelivery)" >                                                        
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id" >
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 detail-prd-row">
                                                                    <div class="col-lg-12">
                                                                        <table class="w-100" style="max-width:98%">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.created_at') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.delivery_code') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.transporter') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.created_by') }}
                                                                                    </th>
                                                                                    <th scope="col">    
                                                                                        {{ $t('message.DeliveryIndex.column_status') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="entry?.delivery_status">                                                                                                                                  
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.delivery_status" :key="index">
                                                                                        <td scope="col" ><span>{{ history?.createdAt ? dt(history.createdAt) : null}}</span></td>
                                                                                        <td scope="col" class="pl-4"><span class="pl-2">{{ entry?.delivery && entry.delivery?.code ? entry.delivery.code : '---'}}</span></td>
                                                                                        <td scope="col" ><span>{{ entry?.deliveryTransporterName ? entry.deliveryTransporterName : null }}</span></td>                                                                                        
                                                                                        <td scope="col" ><span>{{ history?.user_created_by && history.user_created_by?.name ? history.user_created_by.name : null}}</span></td>                                                                                        
                                                                                        <td scope="col" ><span>{{ history?.statusName ? history.statusName : null }}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="5" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Chi tiết giao hàng -->
                                                    <template v-if="(this.tabActive == this.$constant.tabDetailsDelivery)" >                                                        
                                                        <tr class="detail-row bg-white border__body-detail" v-if="invoiceActive === entry.id" >
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 detail-prd-row">
                                                                    <div class="col-lg-12">                                                                        
                                                                        <table class="table w-100" style="max-width:98%">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" style="width:150px !important;">
                                                                                        {{ $t('message.DeliveryIndex.created_at') }}
                                                                                    </th>
                                                                                    <th scope="col" style="width:150px !important;">
                                                                                        {{ $t('message.DeliveryIndex.position') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.infoUpdate') }}
                                                                                    </th>                                                                                   
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>                    
                                                                                <template v-if="entry.delivery_details">
                                                                                    <tr class="table-header border-1" v-if="entry?.delivery_details.status_info">
                                                                                        <td scope="col" ><span>{{ entry?.delivery && entry.delivery?.created_at ? dt(entry.delivery.created_at) : null}}</span></td>
                                                                                        <td scope="col" class="pl-2"></td>
                                                                                        <td scope="col" ><span>{{ entry.delivery_details.status_info }}</span></td>
                                                                                    </tr> 
                                                                                   <tr class="table-header border-1" v-if="entry?.delivery_details.delivery_info">
                                                                                        <td scope="col" ><span>{{ entry?.delivery && entry.delivery?.created_at ? dt(entry.delivery.created_at) : null}}</span></td>
                                                                                        <td scope="col" class="pl-2"><span class="pl-2">{{ entry?.delivery && entry.delivery?.branch_address ? entry.delivery.branch_address.addressValue : '---'}}</span></td>
                                                                                        <td scope="col" ><span>{{ entry.delivery_details.delivery_info }}</span></td>
                                                                                    </tr> 
                                                                                </template>                                                        
                                                                               
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="invoiceActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <InvoiceImportExcel @input="load()" ref="InvoiceImportExcel"/>
        <ModalErrorsInvoice ref="ModalErrorsMessageImport" />
        <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="getSaleChannels()" :typeEditAdd="'ADD'"/>
        <ModalInvoicesAbort ref="ModalInvoicesAbort" @inputData="this.load()" />        
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent" @invoiceUpdateContent="updateContentDelivery"/>
        <ModalConfirm ref="ModalConfirm" @confirm="updateStusOrderCompleted" :title="this.titleConfim" :content="this.contentConfim" />
        <ModalDataPayment ref="ModalDataPayment"  @updated="load()"/>
        <ModalCancelDelivery ref="ModalCancelDelivery" @loadData="load()" />
        <ModalCreateDelivery ref="ModalCreateDelivery" @updated="reloadDelivery()"/>
        <ModalPaymentAndCreateRefund ref="modalPaymentAndCreateRefund" @reload="load"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalCheckSettingReturnTime ref="ModalCheckSettingReturnTime"/>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </AppPermission>
</template>

<script>    
    import {$alert, $get, $post, clone, setArray, getSearchQuery, removeVietnameseTones, debounce} from "./../../lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import QSelect from "../../components/QSelect";
    import {explode, isEmpty} from "../../lib/utils";
    import SelectField from "../../components/SelectField";
    import store from "../../store";
    import FieldsExportInvoice from "../../assets/exportFields/fieldsExportInvoice";
    import moment from 'moment';
    import emitter from "@/lib/emitter";
    // new import
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import DateRangePicker from "@/components/DateRangePicker.vue";
    import InvoiceImportExcel from "./InvoiceImportExcel.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import router from "@/router/index";
    import ModalErrorsInvoice from '../order_suppliers/ModalErrorsMessage.vue';
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import http from '@/services/api';    
    import ModalInvoicesAbort from './tabs/ModalInvoicesAbort';
    import ModalCancelDelivery from "@/views/deliveries/ModalCancel.vue";    
    import {numberFormat} from "@/lib/utils";    
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';    
    import AppPermission from '@/components/AppPermission.vue';
    import ModalConfirm from "@/components/ModalConfirm.vue"
    import ModalDataPayment from '@/components/ModalDataPayment.vue';
    import ModalCreateDelivery from "@/components/ModalCreateDelivery";
    import ModalPaymentAndCreateRefund from "@/views/deliveries/ModalPaymentAndCreateRefund.vue";
    import deliveriesForSupplierMixin from "@/deliveriesForSupplierMixin";    
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputText from "@/components/forms/InputText";
    import SelectDateTimePicker from "@/components/SelectDateTimePicker";
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    import exportMixin from '@/exportMixin';    
    import printOrdersMixin from '@/printOrdersMixin';
    import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
    import ModalCheckSettingReturnTime from '@/components/returns/ModalCheckSettingReturnTime.vue';
    import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
    import { mapGetters } from "vuex";
    let app;
    export default {
        name: "InvoiceIndex",
        mixins: [            
            printOrdersMixin,
            deliveriesForSupplierMixin, 
            exportMixin
        ],
        components: {
            SelectField, 
            ModalErrorsInvoice, 
            InputDateRangePicker, 
            TableSortField, 
            Pagination, 
            QSelect, 
            SelectSearch, 
            InputQuickSearch, 
            DateRangePicker, 
            SelectDateTime, 
            InvoiceImportExcel, 
            InputSelectSearch, 
            ModalAddInfoSaleChannels,
            ModalInvoicesAbort,
            ModalCancelDelivery,            
            ModalSelectPrintTemplate,
            AppPermission,
            ModalConfirm,
            ModalDataPayment,
            ModalPaymentAndCreateRefund,
            PreviewTemplate,
            ModalCreateDelivery,
            InputNumberFormat,
            SelectDateTimePicker,
            InputText,
            // ModalPaymentAndCreateRefund
            ModalSelectSendMailAndZaloTemplate,
            ModalCheckSettingReturnTime,
            ModalSelectSendZaloGroupTemplate
        },
        data() {
            const query = getSearchQuery();            
            const globalConfig = store.state.GlobalConfig;
            app = this;
            return {
                tabActive: this.$constant.tabInformation,                
                init: {
                    fieldsExport: []
                },
                filter: {
                    // keyword: query.keyword,
                    // is_pack: query.is_pack,
                    // branch_ids: branchIds,
                    // sale_channel_ids: explode(query.sale_channel_ids),
                    // purchase_date: query.purchase_date,
                    // status: explode(query.status),
                    // created_by_ids: explode(query.created_by_ids),
                    // sold_by_ids: explode(query.sold_by_ids),
                    // methods: explode(query.methods),
                    // delivery_status: explode(query.delivery_status),

                    product_code_name: '',
                    code_name_phone: '',
                    code_invoice: '',
                    note: '',
                    note_product: '',
                    order_code: '',
                    delivery_code: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                exEntries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                excelData: {
                    fields: null,
                    data: [],
                    fileName: ''
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth ? store.state.Auth.user : {},
                paymentMethods: store.state.GlobalConfig.listPaymentMethod,
                listWeightUnit: store.state.GlobalConfig.listWeightUnit,
                listServiceType: store.state.GlobalConfig.listServiceType,
                numberRecord: 15,
                filterQuery: {
                    status: [],
                    branches: [],
                    createdBy: '',
                    orderBy: '',
                    saleChannel: '',
                    paymentMethod: '',
                    priceList: '',
                },
                currentBranchId: store.state.CurrentBranch.id,
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                priceLists: [],
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                users: globalConfig.users,
                arrayChecked: [],
                loading: false,
                invoiceActive: null,
                sortTime: 0,
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 1,
                },
                defaultInvoice:{
                    filter: null,
                    all: null,
                    searchCode: '',
                    searchName: '',
                },
                fieldInvoiceDetail : [
                    {
                        name: 'message.DetailOrdersCard.prodCode',
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.prodName',
                        field: 'code',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.prodUnit',
                        field: 'unitName',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.attribute',
                        field: 'attributeName',
                        selected: true,
                    },
                    {
                        name: 'message.ModalProductFormTabMain.hs_code',
                        field: 'hsCode',
                        // selected: true,
                    },
                    {
                        name: 'message.ModalProductFormTabMain.tax_rate',
                        field: 'taxRate',
                        // selected: true,
                    },                    
                    {
                        name: 'message.DetailOrdersCard.prodQuantity',
                        field: 'quantity',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.prodPrice',
                        field: 'price',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.prodDiscount',
                        field: 'discount',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'message.ProductIndex.price',
                        field: 'sendPrice',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                    {
                        name: 'message.DetailOrdersCard.totalPrice',
                        field: 'subTotal',
                        style: 'text-align: right !important; ',
                        selected: true,
                    },
                ],
                listStatus: [],
                saleChannelEdit: null,
                soldById: null,
                orderIdConfim: null,
                statusCanRequestRefund: false,
                isFilter: false,
                listTransporterSelfCreated: [],
                listAreaDelivery: [],
                listWards: [],
                listStatusDeliveryAll: [],
                intervalLoad: null,
                hasGetDataInterval: false,
                clearIntervalLoad: false,
                try: 1,
            }
        },
        mounted() {
            document.title = this.$t('message.InvoiceIndex.document_title');

            const fields = localStorage.getItem('invoiceSelectedFields_' + this.user.id);
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['code_invoice'] = query['code'];
                this.loadDetail();
            }else{
                this.clearFilter();
            }
            this.getPriceList();
            if (this.$route.query && this.$route.query.statusOrder == this.$constant.status_delivery_order 
                && this.$route.query.orderCode && this.$route.query.orderId && this.$route.query.action == this.$constant.confim
            ) {
                this.titleConfim = this.$t('message.common.completeOrder');
                this.contentConfim = this.$t('message.common.confirmCompleteOrder', {code: this.$route.query.orderCode});
                this.orderIdConfim = this.$route.query.orderId;
                this.$refs.ModalConfirm.show();
            }
        },
        created() {
            if (this.$route.query && this.$route.query.print_id) {
                this.printMultiple([this.$route.query.print_id]);
            }
            this.getTransporterSelfCreated();
            this.getAreaDelivery();
        },
        methods: {
            getDataInterval(){          
                if(this.invoiceActive && this.hasGetDataInterval){
                    this.intervalLoad = setInterval(() => {
                        this.checkExistDelivery();
                        if (this.clearIntervalLoad) {
                            clearInterval(this.intervalLoad);
                        }
                        this.try += 1;
                        if(this.try > 5){
                            clearInterval(this.intervalLoad);
                            this.try = 1;
                        }
                    }, 2000);
                }
            },
            async checkExistDelivery(){
                const checkExistDelivery = await $post('/v1/deliveries/check-exist-delivery', {invoiceId: this.invoiceActive});
                if(checkExistDelivery && checkExistDelivery.result){
                    this.clearIntervalLoad = true;
                    this.load();
                }
            },
            reloadDelivery(){
                this.clearIntervalLoad = false;
                this.hasGetDataInterval = true;
                this.load();
                this.try = 1;
                this.getDataInterval();
            },
            async sendMailAndZalo() {
                this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabInvoices, this.$constant.typeSellProducts, this.arrayChecked);
            },
            async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = entry && entry.customer ? (entry.customer.contactNumber ?? entry.customer.contactNumber1) : null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabInvoices, this.$constant.typeSellProducts, this.arrayChecked, typeSend, phone, true);
            },
            async getTransporterSelfCreated(){
                const res = await $get('/v1/transporters/get-list-transporter-self-created');
                if(res && res.result){
                    this.listTransporterSelfCreated = res.result;
                }
            },
            async getAreaDelivery(){
                const res = await $get('/v1/deliveries/get-area-delivery');
                if(res && res.result){
                    this.listAreaDelivery = res.result;
                }
            },
            async changeDistrict(entry) {
                const res = await $get('/v1/locations/wards', {districtId: entry.districtId});
                this.listWards = res.data;
            },
            async updateStusOrderCompleted() {    
                const res = await $post('/v1/orders/update-status-completed', {
                    id: this.orderIdConfim,
                });
                if (res.result && res.result.status) {
                    $alert({code: 200, message: this.$t('message.common.completeOrderSuccess')})
                }
                this.orderIdConfim = null;
                window.jQuery(this.$refs.modal).modal('hide');
            },
            showModalDataPayment(id, method, input, history){
             this.$refs.ModalDataPayment.show(id, method, input, history);
            },   
            showContent(tab){            
                this.tabActive = tab;
            },  
            isEmpty(obj) {
            if (!obj) {
                return true;
            }
            for (const k in obj) {
                return false;
            }
            return true;
            },
            async printMultiple(ids = null) {
                this.editorDataClone = '';
                const res = await $post('/v1/invoices/data', {
                    ids: ids ?? this.arrayChecked,
                });
                if (res.result && res.result.length) {
                    await this.getTemplatePrint(res.result);
                }
            },
            async print(entry, status = true) {                
                entry.typePrint = this.$constant.TypePrintInvoice; 
                let dataPrint = this.dataPrint(entry, status);                                
                this.editorDataClone = await this.renderData(dataPrint, entry.typePrint);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            async startPrintMultiple(invoices) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < invoices.length; index++) {
                    let invoice = invoices[index];
                    invoice.typePrint = this.$constant.TypePrintInvoice;
                    let dataPrint = await this.dataPrint(invoice);
                    let invoiceItem = await this.renderData(dataPrint, invoice.typePrint);
                    if (index != invoices.length -1 ) {
                        this.editorDataClone = this.editorDataClone + invoiceItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + invoiceItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },
            async getTemplatePrint(entry) {                
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabInvoices});                
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){                
                this.content = data.content;
                let status = this.content.includes('{Lo}');
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {                    
                    await this.startPrintMultiple(data.dataPrint);
                } else {                    
                    await this.print(data.dataPrint, status);          
                }    
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/invoices/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [this.$constant.status_invoice_in_process, this.$constant.status_invoice_complete, this.$constant.status_invoice_not_delivery];
                    }else if(key == 'export'){
                        this.filterQuery[key] = false;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.sortTime = 0;
                this.filterQuery.branches = [this.currentBranchId];
                this.invoiceActive = null;
                this.isFilter = false;
                this.hasGetDataInterval = false;
                this.clearIntervalLoad = false;
                this.try = 1;
                clearInterval(this.intervalLoad);
                await this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            doFilter(field, value) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value)
                    }else{
                        query['status'].push(value)
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.invoiceActive = null;
                this.load();
            },
            onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/invoices/data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});                 
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }
                if(res.result.data.exEntries.length > 0){
                    //Phục vụ cho export tổng quang không qua phân trang
                    this.exEntries = JSON.parse(res.result.data.exEntries)?? null;  
                }
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;  
                this.entries.forEach(item => {                    
                    item.historyPayment.sort(function(a, b) { return new Date(b.createdAt) - new Date(a.createdAt) });
                    item.historyPayment.forEach(history => {
                        history.createdAt = history?.createdAt ? moment(history.createdAt).format('DD/MM/YYYY HH:mm'): null;    
                        history.payment = history?.payment ? numberFormat(history.payment): 0;  
                    });                                   
                }); 
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;

                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                if(res.result.data.listStatusDeliveryAll){
                    this.listStatusDeliveryAll = res.result.data.listStatusDeliveryAll;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async loadDetail(){
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.filterQuery.branches = [this.currentBranchId];
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailInvoice(entry.id, entry);
                });
            },

            async getSaleChannels(){
                const res = await $post('/v1/configs/index', {} , false);
                if (res.code === 200) {
                    this.saleChannel.all = this.saleChannel.filter = store.state.GlobalConfig.saleChannels = res.data.saleChannels;
                }
            },

            async exportExcel() {
                if (!this.excelData.fields) {
                    const fields = FieldsExportInvoice;

                    const paymentMethodMap = {};
                    if (this.paymentMethods && this.paymentMethods.length) {
                        this.paymentMethods.forEach((method) => {
                            paymentMethodMap[method.id] = method.name;
                        })
                    }

                    fields['Phương thức thanh toán'] = {
                        field: 'cash_flows',
                        callback: (value) => {
                            if (value && value.length) {
                                const paymentMethods = value.map((method) => {
                                    return paymentMethodMap[method.method] || '';
                                });

                                return paymentMethods.join(', ');
                            }
                            return null;
                        }
                    };

                    this.excelData.fields = fields;
                }

                const date = moment().format('HHmm_DDMMYYYY');
                this.excelData.fileName = 'danh-sach-hoa-don-' + date + 'xls';

                const params = this.filter;
                params._action = 'excel';

                const res = await $get('/v1/invoices/data', params);

                if (res.code === 200) {
                    return res.data;
                } else {
                    console.log(res.message);
                }
                return false;
            },


            // new code
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model != null){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.load();
            },
            async detailInvoice(id, entry){
                this.clearIntervalLoad = false;
                clearInterval(this.intervalLoad);
                this.statusCanRequestRefund = false;
                if (id === this.invoiceActive) {
                    this.invoiceActive = null;
                } else {
                    this.invoiceActive = id;
                    await this.checkCanRequestRefund(this.invoiceActive, entry);
                    await this.checkCanReturn(this.invoiceActive, entry);
                    // await this.load();
                    if(entry.delivery && !entry.delivery.transporterIntegration && entry.delivery.customer_address){
                        this.changeDistrict(entry.delivery.customer_address);
                    }
                    if(entry.delivery && !entry.delivery.code){
                        this.hasGetDataInterval = true;
                    }
                    this.try = 1;
                    this.getDataInterval();
                }
                this.defaultInvoice.filter = this.defaultInvoice.all = entry.invoice_items;
                this.setPaginate();
                this.tabActive = this.$constant.tabInformation;
            },
            async checkCanRequestRefund(id, entry){
                if(id && entry){
                    const res = await $get('/v1/invoices/check-can-request-refund', {id: id});
                    if(res.result){
                        this.statusCanRequestRefund = res.result.status;
                    }
                }
            },
            async checkCanReturn(id, entry){
                if(id && entry){
                    const res = await $get('/v1/invoices/check-can-return', {id: id});
                    if(res.result){
                        entry.statusSuccess = res.result.status;
                    }
                }
            },
            setPaginate(){
                this.paginateDetail.currentPage = 1;
                this.paginateDetail.lastPage = Math.ceil(this.defaultInvoice.filter.length / 10);
                this.paginateDetail.totalRecord = this.defaultInvoice.filter.length;
            },
            showInvoiceImport(){
                this.$refs.InvoiceImportExcel.show();
            },
            createInvoices(){
                router.push({ name: 'CreateOrder', query: {
                    type: 'Invoice',
                }});
            },
            copyInvoices(code){
                router.push({
                    name: 'EditOrder', query: {
                        type: this.$constant.typeInvoice,
                        updateInvoice: code,
                        isCoppy: 'copy',
                    }
                 });  
            },
            updateInvoice(code){
                router.push({
                    name: 'EditOrder', query: {
                        type: this.$constant.typeInvoice,
                        updateInvoice: code,                        
                    }
                });  
            },
            changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async quickSave(entry){
                if(entry.description?.length > 255){
                    $alert({
                        code: 422,
                        message: this.$t('message.CashBookIndex.alerts.validate_error_note'),
                    });
                }else{
                    let dataDelivery = {};
                    if(entry.delivery && !entry.delivery.transporterIntegration){
                        dataDelivery = {
                            "id": entry.delivery.id ?? null,
                            "code": entry.delivery.code ?? null,
                            "note": entry.delivery.note ?? null,
                            "status": entry.delivery.status ?? null,
                            "weight": entry.delivery.weight ?? null,
                            "weightUnit": entry.delivery.weightUnit ?? null,
                            "hight": entry.delivery.hight ?? null,
                            "long": entry.delivery.long ?? null,
                            "width": entry.delivery.width ?? null,
                            "transporterId": entry.delivery.transporterId ?? null,
                            "serviceTypeId": entry.delivery.serviceTypeId ?? null,
                            "deliveryTime": entry.delivery.deliveryTime ?? null,
                            "usingPriceCod": entry.delivery.usingPriceCod ?? null,
                            "price": entry.delivery.price ?? null,
                            "receiver_info": entry.delivery.customer_address ?? null,
                        }
                    }

                    const res = await $post('/v1/invoices/quick-save', {
                        id: entry.id, 
                        saleChannelId: this.saleChannelEdit != null ? this.saleChannelEdit : entry.saleChannelId, 
                        description: entry.description, 
                        soldById: this.soldByEdit != null ? this.soldByEdit : entry.soldById,
                        infoUpdateDelivery: dataDelivery
                    });
                    if(res.result && !res.result.status && res.result.message){
                        $alert({code: 500, message: res.result.message});
                    }else if(res.result.status == true){
                        $alert({
                            code: 200,
                            message: this.$t('message.common.updateInvoiceSuccess'),
                        });
                        this.load();
                    }else{
                        $alert({
                            code: 403,
                            message: this.$t('message.common.updateInvoiceFailed'),
                        });
                    }
                }
            },
            setSaleChannel(saleChannelId){
                this.saleChannelEdit = saleChannelId;
            },
            setSoldBy(soldById){
                this.soldByEdit = soldById;
            },
            async getPriceList() {
                const res = await $get('/v1/setting-price/get-price-list');
                if (res.status.code == 200) {
                    this.priceLists = res.result;
                    this.priceLists.unshift(this.$constant.priceListCommon);
                } 
            },
            showModalAddInfoSaleChannels(){
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            // Export
            async exDetails() {
                emitter.emit("onLoading");
                this.filterQuery.export = true;
                // await this.load();
                // let id = this.getOSByID(this.exEntries);
                const res = await http.download('/v1/invoices/exDetails', {
                    // id:id, 
                    branchID: this.currentBranchId,
                    ...this.filterQuery
                });
                this.returnDataExport(res);
            },
            async exOrdersById(id){  
                emitter.emit("onLoading");              
                const res = await http.download('/v1/invoices/exById/' + id, {...this.fields, branchID: this.currentBranchId});                
                this.returnDataExport(res); 
            },
            copyTransferById(id){                        
                 router.push({ name: 'CopyTransferBranch', query: { id: id, type: 'copy' }});               
            },  
            ShowModalInvoicesAbort(entry){
                this.$refs.ModalInvoicesAbort.show(entry);                
            },  
            ShowModalCancelDelivery(entry){
                this.$refs.ModalCancelDelivery.show(entry);                
            }, 
            async ShowModalCreateDelivery(entry){
                if (!entry) return;
                let dataShipping = {
                    items: await this.formatItems(entry.invoice_items),
                    products: await this.formatItemsVTP(entry.invoice_items),
                    senderInfo: entry.sold_by,
                    totalQuantity: entry.totalProduct,
                    priceCodPayment:  entry.delivery.priceCodPayment ?? 0,
                    invoiceId: entry.id,
                    customerId: entry.customerId,
                    customerCode: entry.customer ? entry.customer.code : null,
                    customerAddressId: entry.delivery.customerAddressId,
                    usingPriceCod: entry.delivery.usingPriceCod ?? false,
                    amountCod: entry.delivery.priceCodPayment ?? 0,
                    to_name: entry.delivery.customer_address.name ?? null,
                    to_phone: entry.delivery.customer_address.phone ?? null,
                    customerAddressValue: this.formatAddress(entry.delivery.customer_address),
                    to_address: this.formatAddress(entry.delivery.customer_address),
                    customerAddressMain: entry.delivery.customer_address.addressValue,
                    customerProvinceId: entry.delivery.customer_address.provinceId,
                    customerDistrictId: entry.delivery.customer_address.districtId,
                    customerWardId: entry.delivery.customer_address.wardId,
                    // weightUnit: entry.delivery.weightUnit ?? null,
                    long: entry.delivery.long ?? null,
                    width: entry.delivery.width ?? null,
                    hight: entry.delivery.hight ?? null,
                    serviceTypeId: this.$constant.shipping_mode_default,
                    from_address_id: entry.delivery && entry.delivery.branch_address.id,
                    branchAddressId: entry.delivery && entry.delivery.branch_address.id,
                    customerProvinceName: entry.delivery.customer_address.provinces.name,
                    customerDistrictName: entry.delivery.customer_address.districts.name,
                    customerWardName: entry.delivery.customer_address.wards.name,
                    type_payment: entry.delivery.typePayment,
                    shippingMode: this.$constant.shipping_mode_default,
                    saleMode: this.$constant.sale_mode_trans,
                    customerName: entry.delivery.customer_address.name,
                    deliveryTime: moment().format('YYYY-MM-DD HH:mm'),
                    is_fragile: entry.is_fragile,
                    inSuranceValue: entry.inSuranceValue,
                    item_value: +entry.inSuranceValue,
                    is_insurance: entry.is_insurance,
                    sendSms: entry.sendSms,
                    weightUnit: entry.weightUnit,
                    weight: entry.weight,
                    shippingNote: entry.shippingNote,
                    from_address: entry.delivery && entry.delivery.branch_address 
                                    ? entry.delivery.branch_address.addressValue 
                                        + ( entry.delivery.branch_address.ward ? ', ' + entry.delivery.branch_address.ward.name : null )  
                                        + ( entry.delivery.branch_address.district ? ', ' + entry.delivery.branch_address.district.name : null )  
                                        + ( entry.delivery.branch_address.province ? ', ' + entry.delivery.branch_address.province.name : null )  
                                        + ' - ' 
                                        + entry.delivery.branch_address.phone 
                                    : '',
                    formDistrictName: entry.delivery.branch_address.district ? entry.delivery.branch_address.district.name : null,
                    toDistrictName: entry.delivery.customer_address.districts.name,
                    toWardName: entry.delivery.customer_address.wards.name,
                    toProvinceName: entry.delivery.customer_address.provinces.name,
                };
                this.$refs.ModalCreateDelivery.show(dataShipping, entry.customer?.address_has_many);                
            }, 
            formatItems(products) {
                let items = [];
                for (let index = 0; index < products.length; index++) {
                    const product = products[index];
                    items.push({
                        'name': product.product.name,
                        'quantity': product.quantity,
                    })
                }
                return items;
            },

            formatItemsVTP(products) {
                let items = [];
                for (let index = 0; index < products.length; index++) {
                    const product = products[index];
                    items.push({
                        'name': product.product.name,
                        'quantity': product.quantity,
                        'price': product.price
                    })
                }
                return items;
            },
            formatAddress(value){
                let address = [value.addressValue];
                address.push(value && value.wards ? value.wards.name : null);
                address.push(value && value.districts ? value.districts.name : null);
                address.push(value && value.provinces ? value.provinces.name : null);
                return address.join(' ,');
            },
            // formatGetAddress
            async exSelectOverview() {  
                emitter.emit("onLoading");
                this.filterQuery.export = true;             
                let id = this.arrayChecked;
                const res = await http.download('/v1/invoices/exOverview', {fields:this.fields, id:id, branchID: this.currentBranchId});
                this.returnDataExport(res);
                this.filterQuery.export = false;           
            },  
            async exSelectDetails() {
                emitter.emit("onLoading");
                let id = this.arrayChecked;
                const res = await http.download('/v1/invoices/exDetails', {id:id, branchID: this.currentBranchId});
                this.returnDataExport(res);          
            },
            getOSByID(entries){             
                return entries;
            },  
            async exOverview() { 
                emitter.emit("onLoading");               
                this.filterQuery.export = true;
                // await this.load();
                // let id = this.getOSByID(this.exEntries);                                
                const res = await http.download('/v1/invoices/exOverview', {
                    fields:this.fields, 
                    // id:id, 
                    branchID: this.currentBranchId,
                    ...this.filterQuery
                });
                this.returnDataExport(res);
                this.filterQuery.export = false;                       
            },   
            onInputFilterInvoiceDetail(field){
                this.paginateDetail.currentPage = 1;
                if(this.defaultInvoice.searchCode.length == 0 && this.defaultInvoice.searchName.length == 0){
                    this.defaultInvoice.filter = this.defaultInvoice.all;
                    this.setPaginate();
                    return;
                }
                let arr = [];
                switch (field) {
                    case 'searchCode':
                        for (let index = 0; index < this.defaultInvoice.all.length; index++) {
                            if (this.defaultInvoice.all[index] != null && removeVietnameseTones(this.defaultInvoice.all[index].productCode.toLowerCase()).indexOf(removeVietnameseTones(this.defaultInvoice.searchCode.toLowerCase())) > -1){
                                arr.push(this.defaultInvoice.all[index]);
                            }
                        }
                        break;
                    case 'searchName':
                        for (let index = 0; index < this.defaultInvoice.all.length; index++) {
                            if (this.defaultInvoice.all[index] != null && removeVietnameseTones(this.defaultInvoice.all[index].productName.toLowerCase()).indexOf(removeVietnameseTones(this.defaultInvoice.searchName.toLowerCase())) > -1){
                                arr.push(this.defaultInvoice.all[index]);
                            }
                        }
                        break;
                }
                this.defaultInvoice.filter = arr;
                this.setPaginate();
            },
            returns(entry){
                this.$refs.ModalCheckSettingReturnTime.show(entry, this.$constant.settingReturnTimeTypeInvoice);
                // router.push({ name: 'CreateOrder', query: { returnInvoice: code }});
            },
            createRefund(entry){                
                this.$router.push({
                    path: '/request_refund',
                    query: {
                        'type': 'create',
                        'code': entry.code ?? null,
                    }
                });                            
            },
            linkToReturn(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'ReturnIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToOrder(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'OrderIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToDelivery(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'DeliveryIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToCustomer(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'CustomerIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            paymentAndCreateRefund(entry){
                let data = {
                    invoice: {
                        code: entry.code ?? null,
                        customerId: entry.customerId ?? null,
                        created_at: entry.created_at ?? null,
                        id: entry.id ?? null,
                    },
                    id: entry.delivery ? entry.delivery.id : null,
                    code: entry.delivery ? entry.delivery.code : null,
                    transporter: {
                        id: entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.id : null,
                        name: entry.delivery && entry.delivery.transporter ? entry.delivery.transporter.name : null,
                    },
                    priceStill: entry.priceStill ?? null,
                    priceCodPaymentStill: entry.priceCodPaymentStill ?? 0,
                    branchId: entry.branchId ?? null,
                };
                if(entry.delivery && !entry.delivery.usingPriceCod){
                    data.priceCodPaymentStill = 0;
                }
                if(!data.priceCodPaymentStill && !data.priceStill){
                    $alert({code: 422, message: this.$t('message.DeliveryIndex.alert.payment_error')});
                    return;
                }
                this.$refs.modalPaymentAndCreateRefund.show(data);
            },
            // In phiếu
            // -------------------------------------------------------------------------------------------------------------
            async printMultipleDelivery(id) { 
                this.editorDataClone = '';
                const res = await $post('/v1/deliveries/get-deliveries-by-id', {
                    id: id
                });
                if (res.result && res.result.code == 200) {                
                    await this.getTemplatePrintDelivery(res.result.data);
                }
            },
            async printDelivery(entry) { // print of Delivery
                entry.typePrint = this.$constant.TypePrintDeliveries;                 
                let dataPrint = await this.dataPrintDelivery(entry);                
                this.editorDataClone = await this.renderDataDelivery(dataPrint, this.content);
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            async getTemplatePrintDelivery(entry) {  
                let typePrints = this.$constant.typeInvoice;
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabDeliveries});
                if(res && res.status.code == 200){
                    if(res.result) {                    
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry, typePrints);
                    }
                }  
            },
            async updateContentDelivery(data){              
                this.content = data.content;                
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultipleDelivery(data.dataPrint);
                } else {                 
                    await this.printDelivery(data.dataPrint);          
                }    
            },
            async startPrintMultipleDelivery(invoices) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                let dataPrint = null;
                for (let index = 0; index < invoices.length; index++) {
                    let invoice = invoices[index];
                    invoice.typePrint = this.$constant.TypePrintDeliveries;
                    dataPrint = await this.dataPrintDelivery(invoice);                   
                    let invoiceItem = await this.renderDataDelivery(dataPrint);
                    if (index != invoices.length -1 ) {
                        this.editorDataClone = this.editorDataClone + invoiceItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + invoiceItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },
            async updateFilterBranch(values) {
                this.filterQuery.branches = [...values];
                this.filterQuery.page = 1;
                this.isFilter = true;
                await this.load();
            }
        },
        computed: {
            ...mapGetters({
                statusEmail: 'settingStore/email',
            }),
        },
        beforeUnmount() {
            clearInterval(this.intervalLoad);
        },
        unmounted() {
            clearInterval(this.intervalLoad);
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.container-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
</style>
