<template>

    <div ref="modalLogoutZalo" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalCreateTicket" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2 border-none">
                    <h5 class="text-bold">{{ $t('message.ModalLogoutZalo.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2 d-flex">
                    <div class="col-lg-12">
                        <p >{{ $t('message.ModalLogoutZalo.content') }}</p>
                        <div class="row m-0 p-0 mt-4"> 
                            <div class="col-12 text-right">
                                <button class="btn btn-danger mr-2" @click="submit()">
                                    {{ $t('message.ModalLogoutZalo.button.logout') }}
                                </button>
                                <button class="btn btn-secondary text-white" @click="hide()">
                                    {{ $t('message.ModalLogoutZalo.button.skip') }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert} from "@/lib/utils";
export default {
    name: "ModalConfirmLogout",
    emits: ['reload'],
    components: {},
    data(){
        return {
            id: {},
        }
    },
    methods: {
        show(id) {
            if(!id) return;
            this.id = id;
            window.jQuery(this.$refs.modalLogoutZalo).modal('show');
        },
        hide(){
            window.jQuery(this.$refs.modalLogoutZalo).modal('hide');
        },
        async submit(){
            const res = await $post('/v1/zalo/logout-zalo-oa', {id: this.id});
            if(!res.result){
                const errors = this.$t('message.ModalLogoutZalo.message.logout_failed');
                $alert({code: 500, message: errors});
            }
            else if(res.result){
                const message = this.$t('message.ModalLogoutZalo.message.logout_success');
                $alert({code: 200, message: message});
                this.$emit('reload');
            }
            this.hide();
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style>
</style>