<template>
    <div >
      <div class="container my-4">
        <div class="row">
          <div class="mainLeft">
          <!-- <div class="col-md-2" id="col2"> -->
            <div class="main-title">
              <span>Người dùng</span>
            </div>
            <div>
              <div class="card-header mx-0 px-0" id="typeHeader">
                <div class="row">
                  <div class="col-8 text-title">
                    <label>{{$t('message.common.numberRecord')}}:</label>
                  </div>
                  <div class="col-4">
                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <!-- Chi nhánh -->
            <div class="card" id="typeCard">
                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label class="typeTitle">{{$t('message.common.branch')}}</label>
                        </div>
                        <div class="col-1">
                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                <i class="fa fa-chevron-up" id="branchUp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body my-0 pb-3 pt-2" id="branchBody">
                    <div class="row">
                        <div class="col-12">
                            <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="filter.branches" :defaultOption="this.branches" @update:modelValue="doFilterSearch" :label="'branches'"></SelectSearch>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Vai trò -->
            <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label class="typeTitle">Vai trò</label>
                        </div>
                        <div class="col-1">
                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('roleBody', 'roleDown', 'roleUp')">
                                <i class="fa fa-chevron-down dp-hide" id="roleDown"></i>
                                <i class="fa fa-chevron-up" id="roleUp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body my-0 pb-3 pt-2" id="roleBody">
                    <div class="row">
                        <div class="col-12">
                            <SelectSearch :placeholder="'Chọn vai trò'" :modelValue="filter.roles" :defaultOption="this.roles" @update:modelValue="doFilterSearch" :label="'roles'"></SelectSearch>
                        </div>
                    </div>
                </div>
            </div>

            <!-- Filter trạng thái -->
            <div class="card mt-4" id="typeCard">
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row mx-2">
                        <div class="col-9">
                            <label class="typeTitle">{{$t('message.common.status')}}</label>
                        </div>
                        <div class="col-1">
                            <a href="#" class="text-dark" id="linkIcon"
                            @click="showHide('status', 'sttDown', 'sttUp')">
                            <i class="fa fa-chevron-down dp-hide" id="sttDown"></i>
                            <i class="fa fa-chevron-up" id="sttUp"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="card-body my-0 py-2" id="status">
                    <template v-if="this.optionSttUser">
                        <div class="row mb-2 ml-0" v-for="option in this.optionSttUser" :key="option.id">
                            <label class="container-radio ml-2">{{option.label}}
                                <input type="radio" name="sttSaleChannel" v-model="this.filter.status" :checked="this.filter.status === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('status', option.value)">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </template>
                </div>
            </div>
          </div>
        
          <div class="mainRight">
          <!-- <div class="col-md-10" id="col10"> -->
            <div>
                <div class="row">
                    <form class="col-5">
                        <InputQuickSearch :placeholder="'Tên đăng nhập, người dùng'" @filterSearch="changeQuickSearch" :usersSearch="true" />
                    </form>
                    <div class="col-7 d-flex justify-content-end">
                        <button type="button" @click="clearFilter()" class="btn btn-default"><i class="fa fa-filter mr-1"></i>Xóa lọc</button>
                        <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <i class="fa fa-ellipsis-v px-2"></i>Thao tác
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item">
                                    <i class="fa fa-lock mr-2"></i>
                                    Ngừng hoạt động
                                </a>
                                <a class="dropdown-item">
                                    <i class="fa fa-trash-alt mr-2"></i>
                                    Xóa
                                </a>
                                <a class="dropdown-item">
                                    <i class="fas fa-file-export mr-2"></i>
                                    Xuất file
                                </a>
                            </div>
                        </div>
                        <button class="btn btn-save ml-1" @click="showModalCreateUser()"><i class="fa fa-plus"/> Người dùng </button>
                        <div id="loc" v-on:mouseleave="onMouseLeave">
                            <SelectField :fields="fields" label="users"></SelectField>
                        </div>
                    </div>
                </div>
                <div>
                    <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                </div>
                <div class="row mt-2">
                    <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                        Có <a href="#" v-text="this.arrayChecked?.length + ' bản ghi'" id="lnkCount"></a> được chọn
                        <a href="javascript:;" @click="removeChecked()">
                            <i class="fa fa-times text-danger" aria-hidden="true"></i>
                        </a>
                    </div>
                </div>
                <div class="row mx-0 my-2" v-if="entries.length > 0" id="divTable">
                    <table class="table table-index table-striped lstProduct" >
                        <thead>
                            <tr>
                                <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                <template v-for="field in fields" :key="field.field">
                                
                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                    <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                </th>
                                </template>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="(entry, index) in entries" :key="entry.id" >
                                <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :class="entry.id === this.userDetailId ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                    <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td>
                                    <template v-for="field in fields" :key="field.field">
                                        <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                            <a :href="'#row_' + index" class="text-dark tagA" @click="detailUser(entry.id)">
                                                <div v-if="field.field==='status'" :style="field.style" class="description" :class="field.class"><span v-text="status(entry.status)"></span></div>
                                                <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :class="field.class" class="description">
                                                </div>
                                            </a>
                                        </td>
                                    </template>
                                </tr>
                                <tr v-if="this.userDetailId === entry.id" :class="[ entry.id === this.userDetailId ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                    <td></td>
                                    <td :colspan="fields.length" class="pb-0">
                                        <div class="row detail-prd-row">
                                            <div class="col-2 mt-2" @click="showContent('info')">
                                                <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                    Thông tin
                                                </span>
                                            </div>
                                            <div class="col-2 mt-2" @click="showContent('rolePermission')">
                                                <span :class="[ headerActive === 'rolePermission' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                    Phân quyền
                                                </span>
                                            </div>
                                            <div class="col-2 mt-2" @click="showContent('accessTime')">
                                                <span :class="[ headerActive === 'accessTime' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                    Thời gian truy cập
                                                </span>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="detail-row bg-white border__body-detail" v-if="this.userDetailId === entry.id">
                                    <td></td>
                                    <td :colspan="fields.length" class="border-top-0">
                                        <div class="mt-2 pb-4 detail__user">
                                            <div class="row mt-2 ml-0 detail-prd-row">
                                                <div class="col-6 pr-4">
                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                        <div class="col-5">
                                                            Tên đăng nhập:
                                                        </div>
                                                        <div class="col-7 ow-break-word">                                                            
                                                            {{ entry.userName }}
                                                        </div>
                                                    </div>
                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                        <div class="col-5">
                                                            Tên người dùng:
                                                        </div>
                                                        <div class="col-7 ow-break-word">
                                                            {{ entry.name }}
                                                        </div>
                                                    </div>
                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                        <div class="col-5">
                                                            Điện thoại:
                                                        </div>
                                                        <div class="col-7 ow-break-word">
                                                            {{ entry.phone }}
                                                        </div>
                                                    </div>
                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                        <div class="col-5">
                                                            Email:
                                                        </div>
                                                        <div class="col-7 ow-break-word">
                                                            {{ entry.email }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-6 border-left">
                                                    <span class="note-supplier text-gray" v-if="entry.note"><i class="fa fa-pencil-alt mr-1"></i>{{entry.note}}</span>
                                                    <span class="note-supplier text-gray" v-else><i class="fa fa-pencil-alt mr-1"></i>Ghi chú...</span>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="detail-row bg-white border__body-detail" v-if="this.userDetailId === entry.id">
                                    <td :colspan="fields.length" class="border-top-0">
                                        <div class="row detail-prd-row flex-end pr-4 mb-2"> 
                                            <button type="button" class="btn btn-save mx-1" @click="showModalUpdate(entry.id)">
                                                <i class="fa fa-check mr-2"></i>
                                                Cập nhật
                                            </button>
                                            <button type="button" class="btn btn-save mx-1" @click="showModalCopyUser(entry.id, 'copy')">
                                                <i class="fa fa-copy mr-2"></i>
                                                Sao chép
                                            </button>
                                            <button type="button" class="btn btn-danger mx-1" v-if="entry.status == 1" @click="showModalStopUser(entry.id, entry.name)">
                                                <i class="fa fa-lock mr-2"></i>
                                                Ngừng hoạt động
                                            </button>
                                            <button type="button" class="btn btn-primary mx-1" v-else @click="showModalActiveUser(entry.id, entry.name)">
                                                <i class="fa fa-unlock"></i> 
                                                Cho phép hoạt động
                                            </button>

                                            <button type="button" class="btn btn-danger mx-1" @click="showModalDelete(entry.id, entry.name)">
                                                <i class="fa fa-trash-alt mr-2"></i>
                                                Xóa
                                            </button>
                                        </div>
                                    </td>
                                </tr>
                                <tr class="detail-row bg-white border__footer-detail" v-if="this.userDetailId === entry.id"></tr>
                            </template>
                        </tbody>
                    </table>
                </div>
                <div class="row ml-auto mr-auto" v-if="entries.length == 0 && !this.loading" id="notFound">
                    <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                        <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                        <p>{{$t('message.common.notFound')}}</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalConfirmDeleteUser @updated="load()" ref="ModalConfirmDeleteUser" />
    <ModalStopOrActiveUser ref="ModalStopOrActiveUser" :title="this.title" :content="this.content" :type="this.type" @updated="load()"  />
    <ModalCreateUser ref="ModalCreateUser" @updated="load()" />
</template>

<script>
    import {$alert, $get, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectField from "../../components/SelectField";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import {$post} from "../../lib/utils";
    import store from "@/store";   
    import emitter from "@/lib/emitter";

    // import modal
    import ModalConfirmDeleteUser from './ModalConfirmDeleteUser.vue';
    import ModalStopOrActiveUser from './ModalStopUser.vue';
    import ModalCreateUser from './ModalCreateUser.vue';
    export default {
        name: "UserIndex",
        components: {TableSortField, Pagination, InputQuickSearch, SelectField, ModalConfirmDeleteUser, ModalStopOrActiveUser, ModalCreateUser, SelectSearch},
        mounted() {
            document.title = 'Người dùng | OmiPOS';
            const fields = localStorage.getItem('usersSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            this.clearFilter();
            // window.addEventListener('scroll', this.onScroll);
        },
        data() {
            const query = getSearchQuery();
            const globalConfig = store.state.GlobalConfig;
            return {
                filter: {
                    keyword: query.keyword,
                    status: '',
                    branches: '',
                    roles: '',
                    username: '',
                    phone: '',
                    email: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                optionSttUser: [
                    { id: 0, label: 'Tất cả', value: '' },
                    { id: 1, label: 'Đang hoạt động', value: 1 },
                    { id: 2, label: 'Ngừng hoạt động', value: 2 },
                ],
                numberRecord: 15,
                // branches: {
                //     filter: globalConfig.branches,
                //     all: globalConfig.branches,
                //     search: null,
                // },
                branches: globalConfig.branches,
                roles: globalConfig.roles,
                user: store.state.Auth.user,
                arrayChecked: [],
                loading: false,
                headerActive: 'info',
                userDetailId: null,
                fixed: false,
                title: '',
                content: '',
                type: ''
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/users/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                emitter.emit("clearQuickSearch");
                emitter.emit("clearSelectSearchValue");
                this.arrayChecked = [];
                this.branches.search = null;
                this.numberRecord = 15;
                this.load();
            },
            async clearSearch(field){
                const query = clone(this.$route.query);
                delete query[field];
                query['page'] = 1;
                await this.$router.push({query: query});
                switch (field) {
                    case 'branches':
                        this.branches.search = null;
                        this.branches.filter = this.branches.all;
                        break;
                    default:
                        break;
                }
                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            async doFilterSearch(v){
                const query = clone(this.$route.query);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                await this.$router.push({query: query});
                this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                if(field == 'status'){
                    query[field] = value;
                    if(value == null || value == ''){
                        delete query[field];
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                await this.$router.push({query: query});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                const query = clone(this.$route.query);
                query['page'] = page;
                await this.$router.push({query: query});
                this.load();
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                await this.$router.push({query: query});
                this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.$route.query);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                await this.$router.push({query: query});
                this.load();
            }, 
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $get('/v1/users/index', {...this.$route.query, numberRecord: this.numberRecord});
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                // this.paginate.currentPage = res.result.data.paginate.currentPage;
                // this.paginate.lastPage = res.result.data.paginate.lastPage;
                // this.paginate.totalRecord = res.result.data.paginate.totalRecord;
                // this.entries = res.result.data.entries;
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async onToggleStatusAction(userId, status) {
                const res = await $post('/v1/users/onToggleStatus', {id: userId, status: status});
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }
                $alert(res);
                this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            detailUser(id){
                if (id === this.userDetailId) {
                    this.userDetailId = null;
                } else {
                    this.userDetailId = id;
                }
                this.headerActive = 'info';
                
            },
            // onScroll() {
            //     let height = window.scrollY;
            //     if(height > 120){ 
            //         this.fixed = true;
            //     }else{
            //         this.fixed = false;
            //     }
            //     emitter.emit("position", 0);
            
                
            // },
            showContent(name) {
                this.headerActive = name;
            },
            showModalDelete (id, name) {
                this.$refs.ModalConfirmDeleteUser.show(id, name)
            },
            showModalStopUser(id, name) {
                this.title = "Xác nhận";
                this.content = "Bạn có chắc chắn muốn ngừng hoạt động của người dùng này.";
                this.type = 0;
                this.$refs.ModalStopOrActiveUser.show(id, name, 0);
            },
            showModalActiveUser(id, name) {
                this.title = "Xác nhận";
                this.content = "Bạn có muốn hoạt động trở lại người dùng này không ?";
                this.type = 1;
                this.$refs.ModalStopOrActiveUser.show(id, name, 1);
            },
            showModalCreateUser () {
                this.$refs.ModalCreateUser.show();
            },
            showModalUpdate (id) {
                this.$refs.ModalCreateUser.show(id);
            },
            showModalCopyUser (id, type) {
                this.$refs.ModalCreateUser.show(id, type)
            },
            onInputFilterBranch(){
                if(this.branches.search == ''){
                    this.branches.filter = this.branches.all;
                    return
                }
                let arr = [];
                for (let index = 0; index < this.branches.all.length; index++) {
                    if (removeVietnameseTones(this.branches.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.branches.search.toLowerCase())) > -1){
                        arr.push(this.branches.all[index]);
                    }
                }
                this.branches.filter = arr;
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
</style>