<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalSelectOptionExport" data-backdrop="static">
        <div class="modal-dialog modal-md modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2" v-text="$t('message.ModalSelectOptionExport.title')"></h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row mb-2">
                        <div class="col-lg-12">
                            {{ $t('message.ModalSelectOptionExport.content', {totalNumbers: n(this.totalNumbers)}) }}
                        </div>
                    </div>
                    <template v-if="this.totalIndex">
                        <div class="row" v-for="(index, key) in this.totalIndex" :key="key">
                            <div class="col-lg-12">
                                <label class="container-radio ml-2 w-100">
                                    {{ $t('message.ModalSelectOptionExport.option', {from: (key ? n(key * this.defaultTotalRecord + 1) : 0), to: (key ? n((key + 1 ) * this.defaultTotalRecord) : n((key + 1 ) * this.defaultTotalRecord))})}}
                                    <input type="radio" name="option" :checked="firstRecordIndex == (key * this.defaultTotalRecord) ? true : false" :value="(key * this.defaultTotalRecord)" v-model="this.firstRecordIndex">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="modal-footer" >
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-save mr-2" @click="exportRecord()"><i class="fas fa-file-export"></i>{{ $t('message.ModalSelectOptionExport.export') }}</button>
                        <button class="btn btn-secondary text-white" @click="skip()"><i class="fa fa-ban mr-1"></i>{{ $t('message.ModalSelectOptionExport.skip') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import emitter from "@/lib/emitter";
import http from '@/services/api';
export default {
    name: "ModalSelectOptionExport",
    data(){
        return {
            action: null,
            totalIndex: 0,
            firstRecordIndex: 0,
            params: {},
            defaultTotalRecord: 5000,
            totalNumbers: 0,
            fields: {}
        }
    },
    props: ['message', 'title', 'content'],
    methods: {
        show(params, totalNumbers, fields, totalRecords = 5000){
            this.params = params;
            this.totalNumbers = +totalNumbers;
            if(!this.totalNumbers){
                return;
            }
            this.defaultTotalRecord = totalRecords;
            this.totalIndex = +totalNumbers ? Math.ceil(+totalNumbers / this.defaultTotalRecord) : 0;
            this.fields = fields;
            window.jQuery(this.$refs.modal).modal('show');
        },
        skip(){
            this.hide();
        },
        async exportRecord(){
            this.$emit('export', {
                firstRecordIndex: this.firstRecordIndex,
                method: this.params.method
            });
            this.hide();
        },
        async hide(){
            this.params = {};
            this.totalIndex = 0;
            this.firstRecordIndex = 0;
            this.totalNumbers = 0;
            this.fields = {};
            window.jQuery(this.$refs.modal).modal('hide');
        },
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>
<style scoped lang="scss">
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
</style>
