<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-1">
                    <h6 class="modal-title py-2">
                        {{$t('message.permissions.modal.titleEditRole')}}                        
                    </h6>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-6">
                            <div class="row mt-2 py-2">
                                <div class="col-3">
                                    {{$t('message.common.roleName')}}:
                                </div>
                                <div class="col-9 position-relative">                                                            
                                    <input type="text" v-model="entry.name" class="w-100 border-0 border-bottom-1 pr-4">
                                    <i class="fa fa-copy" id="fa_copy" v-if="this.isEdit" @click="copyRole"></i>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">  
                            <div class="row mt-2 py-2">
                                <div class="col-3">
                                    {{ $t('message.InputQuickSearch.title.note') }}:
                                </div>
                                <div class="col-9 position-relative">   
                                    <i class="fa fa-pencil-alt" id="fa_edit"></i>                                                         
                                    <input type="text" v-model.trim="entry.note" class="pl-4 w-100 border-0 border-bottom-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-12">                            
                            <TabPermission :defaultValues="this.entry.permissionIds" @update:modelValue="updatePermission" :showAction="isEdit ? true : false"></TabPermission>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <button type="button" class="btn btn-save mr-2" @click="save" :disabled="this.isDisabled">
                                <i class="fa fa-check-square mr-1"></i>
                                {{$t('message.permissions.modal.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.permissions.modal.cancel')}}               
                            </button>                          
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>    
</template>

<script>
import {$post, $alert, isChangeData, clone} from "@/lib/utils";
import toastr from 'toastr';
import emitter from "@/lib/emitter";
import ModalConfirm from "@/components/ModalConfirm.vue";
import TabPermission from "@/components/TabPermission.vue";

import InputText from '@/components/forms/InputText';
export default {
    name: "ModalCreateRole",
    components: {InputText, ModalConfirm, TabPermission, 
    // ModalRemoveRole, ModalCopyRole
    },
    props: {},
    data() {
        return {
            isDisabled: true,
            entry: {},
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            permissionIds: [],
            data: null,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                note: '',
                permissionIds: null,
            };
           
            this.isEdit = false;
            emitter.emit('removeDataQTreeListCustom');
            emitter.emit('removeDataTabPermission');
        },
        async getDataPermisstionByRole(data){            
            const res = await $post('/v1/permissions/data-by-role', {...data});
            if(res && res.status.code == 200 && res.result.length > 0)
            {
                
                this.entry.permissionIds = res.result; 
                              
            }                       
            if(this.entry.permissionIds.length > 0) this.isDisabled = false;
            else this.isDisabled = true;
        },       
        async show(roles, permissionIds, data) { 
            this.data  = data;
            this.entry = clone(roles);
            this.entry.permissionIds = permissionIds;
            if(this.entry.permissionIds.length > 0) this.isDisabled = false;
            else this.isDisabled = true;
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide() {   
            this.$emit('inputData');
            this.clearInput();  
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            console.log(`isChange`, isChange);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.common.confirm');
                if(this.isEdit){
                    this.contentConfirm = this.$t('message.common.confirmCancelUpdateRole');
                }else{
                    this.contentConfirm = this.$t('message.common.confirmCancelAddRole');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async remove(data){
            const res = await $post('/v1/permissions/remove', {roleId: data.roleId, userId: data.userId, branchId: data.branchId});            
            return;
        },
        updatePermission(data){                
            this.entry.permissionIds = data.values;                        
            if(this.entry.permissionIds.length > 0) this.isDisabled = false;
            else this.isDisabled = true;
            
        },
        formatData(data, permissionIds){               
            let insertData = [];
            data.branchId.forEach(value => {
                if(permissionIds.length) {
                    permissionIds.forEach( item => {
                        insertData.push({
                            userId: data.userId,
                            roleId: data.roleId,
                            branchId: value,
                            permissionId:item
                        });
                });
                }
            });            
            return insertData;
        },
        async save(){                  
             if(this.entry.permissionIds.length <= 0) {                 
                toastr.success(this.$t('message.common.emptyPermission'));                
             }
             else {
                let dataInsert = this.formatData(this.data, this.entry.permissionIds); 
                this.$emit('updatePermission', {data: dataInsert, id: (this.data.user_role_id ? this.data.user_role_id : null)});
                this.hide();            
             }
            
        },       
        removeRole(id){
            this.$refs.ModalRemoveRole.show(id);
        },
        copyRole(){
            let data = {
                displayName: null,
                permissionIds: this.entry.permissionIds,
                note: this.entry.note,
            }
            this.$refs.ModalCopyRole.show(data);
        }
    },
    mounted() {
    },
}
</script>

<style scoped>
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}

#fa_edit {
    position: absolute;
    left: 20px;
    bottom: 5px;
    color:#a7a2a2;
    cursor: text;
}
#fa_copy{
    position: absolute;
    right: 20px;
    bottom: 5px;
    color:#a7a2a2;
    cursor: pointer;
}
</style>