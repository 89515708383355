<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modal" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                    <h5 class="modal-title py-2" v-text="$t('message.common.receiveInvoice')"></h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        <table class="w-100">
                            <thead>
                                <tr class="backgroup__detail">
                                    <th scope="col" class="pl-4 py-2 cell-auto text-center border">
                                        {{$t('message.SettlementAdvanceIndex.po_code')}}
                                    </th>
                                    <th scope="col" class="pl-4 py-2 cell-auto text-center border">
                                        {{$t('message.SettlementAdvanceIndex.import_number')}}
                                    </th>
                                    <th scope="col" class="pl-4 py-2 cell-name text-center border">
                                        {{$t('message.SettlementAdvanceIndex.invoice_number')}}
                                    </th>
                                    <th scope="col" class="pl-4 py-2 cell-large text-center border">
                                        {{ $t('message.common.invoiceDate') }}
                                    </th>
                                    <th scope="col" class="pl-4 py-2 cell-auto text-center border">
                                        {{$t('message.common.invoiceAmount')}}
                                    </th>
                                    <th scope="col" class="pl-4 py-2 cell-auto text-center border">
                                        {{ $t('message.PurchaseOrderIndex.filter.status_invoice') }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-if="entries && entries.length">
                                    <template v-for="(entry, index) in entries" :key="index">
                                        <tr>
                                            <td scope="col" class="cell-auto border text-primary text-center">
                                                <a :href="'/order-suppliers/index?order_supplier=' + entry.orderForSupplierCode" target="_blank" class="text-decoration-none text-primary">{{ entry.orderForSupplierCode }}</a>
                                            </td>                
                                            <td scope="col" class="cell-auto border text-center" v-text="entry.purchaseOrderCode"></td>                
                                            <td scope="col" class="cell-name border">
                                                <div class="row w-100 m-0">
                                                    <div class="col-12 d-flex align-items-center w-100 p-0">
                                                        <label class="container-radio ml-2 w-100">{{$t('message.common.nonInvoice')}}
                                                            <input type="radio" :name="'statusInvoice_' + index" v-model="entry.statusHasInvoice" :value="this.$constant.statusInActive" :checked="entry.statusHasInvoice === this.$constant.statusInActive ? true : false" class="mr-2" @click="changeStatusInvoice(entry, index)">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="row w-100 m-0">
                                                    <div class="col-12 d-flex align-items-center w-100 p-0">
                                                        <label class="container-radio ml-2 w-100 text-left">{{$t('message.common.hasInvoice')}}
                                                            <input type="radio" :name="'statusInvoice_' + index" v-model="entry.statusHasInvoice" :value="this.$constant.statusActive" :checked="entry.statusHasInvoice === this.$constant.statusActive ? true : false" class="mr-2">
                                                            <span class="checkmark"></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </td>
                                            <!-- <td scope="col" class="cell-auto border px-2">
                                                <input type="date" v-model="entry.billDate" :readonly="entry.statusHasInvoice == this.$constant.statusInActive" :placeholder="$t('message.common.enterInvoiceDate')" class="format__input bg-transparent text-left mb-0">
                                            </td>
                                            <td scope="col" class="cell-auto border px-2">
                                                <InputNumber v-model="entry.amountForInvoice" :readonly="entry.statusHasInvoice == this.$constant.statusInActive" :cardTransaction="true" class="mb-0" :placeholder="$t('message.common.enterAmount')" :formatNumber="true"/>
                                            </td> -->
                                            <td scope="col" class="cell-large border">
                                                <table class="h-100 w-100">
                                                    <template v-for="(info, infoIndex) in entry.infoInvoiceSupplier" :key="infoIndex">
                                                        <tr v-if="!info.deleted">
                                                            <td scope="col" class="cell-check border-none px-2">
                                                                <i class="fa fa-trash-alt" v-if="infoIndex > 0" @click="entry.statusHasInvoice !== this.$constant.statusActive ? null : removeInvoiceSupplier(index, infoIndex)"></i>
                                                            </td>
                                                            <td scope="col" class="cell-name border-right px-2">
                                                                <input type="text" v-model.trim="info.numberInvoice" :readonly="entry.statusHasInvoice !== this.$constant.statusActive" :placeholder=" $t('message.common.enterInvoice') + '...'" class="format__input bg-transparent text-left mb-0" :class="entry.statusHasInvoice !== this.$constant.statusActive ? 'cursor-ban' : ''">
                                                            </td>
                                                            <td scope="col" class="cell-name border-right px-2">
                                                                <input type="date" v-model="info.billDate" :readonly="entry.statusHasInvoice !== this.$constant.statusActive" :placeholder="$t('message.common.enterInvoiceDate')" class="format__input bg-transparent text-left mb-0" :class="entry.statusHasInvoice !== this.$constant.statusActive ? 'cursor-ban' : ''">
                                                            </td>
                                                            <td scope="col" class="cell-name border-right px-2">
                                                                <InputNumber v-model="info.amountForInvoice" :readonly="entry.statusHasInvoice !== this.$constant.statusActive" :cardTransaction="true" class="mb-2" :placeholder="$t('message.common.enterAmount')" :formatNumber="true"/>
                                                            </td>
                                                            <td scope="col" class="cell-check border-none px-2">
                                                                <i class="fa fa-plus" v-if="infoIndex == 0" @click="entry.statusHasInvoice !== this.$constant.statusActive ? null : addInvoiceSupplier(index)"></i>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </table>
                                            </td>
                                            <td scope="col" class="cell-auto border px-2 text-center" v-text="n(entry.totalAmountForInvoice)"></td>
                                            <td scope="col" class="cell-auto border text-center" v-text="entry.statusName"></td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-if="(!entries || !entries.length)">
                                    <tr>
                                        <td :colspan="6" class="text-center p-4">
                                            {{$t('message.common.notData')}}
                                        </td>                            
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-save mr-2" @click="save()">
                            <i class="fa fa-save mr-1"></i>
                            {{$t('message.button.save')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="skip()">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.cancel')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$alert, $get, debounce, $post, clone} from "@/lib/utils";
import emitter from "@/lib/emitter";
import InputNumber from "@/components/forms/InputNumberCustom";
import toastr from 'toastr';
import moment from 'moment';
import store from "@/store";
export default {
    name: "ModalReceiveInvoiceSupplier",
    data(){
        return {
            entries: [],
            ids: [],
        }
    },
    components: {InputNumber},
    props: [],
    methods: {
        async show(ids) {
            if(ids){
                this.ids = ids;
                const status =  await this.getDataImportForSupplier();
                if (status) {
                    window.jQuery(this.$refs.modal).modal('show');
                }
            }            
        },
        async getDataImportForSupplier(){
            emitter.emit("onLoading");
            const res = await $post('/v1/order-suppliers/data-by-ids', {ids: this.ids, type:"receiveInvoice", branchId: store.state.CurrentBranch.id});
            emitter.emit("offLoading");
            if (res.result && res.result.status && res.result.status == "err") {
                toastr.error(res.result.message);
                return false;
            } else {
                if (res.result && res.result.length > 0) {
                    this.entries = res.result;
                    this.entries.forEach(element => {
                       if (element && element.invoice_suppliers) {
                            let infoInvoiceSupplier = [];
                            element.invoice_suppliers.forEach(invoice => {
                                infoInvoiceSupplier.push({
                                    'invoiceSupplierId': invoice.id ?? null,
                                    'numberInvoice': invoice.code ?? null,
                                    'billDate': invoice.billDate ?  moment(invoice.billDate).format('YYYY-MM-DD')  : null,
                                    'amountForInvoice': invoice.amountForInvoice ?? null,
                                });
                            });
                            if(element.invoice_suppliers.length == 1 && element.invoice_suppliers[0].status == this.$constant.statusInActive){
                                element.statusHasInvoice = this.$constant.statusInActive;
                            }else{
                                element.statusHasInvoice = this.$constant.statusActive;
                            }
                            element.infoInvoiceSupplier = infoInvoiceSupplier;
                       }else {
                            let infoInvoiceSupplier = [];
                            infoInvoiceSupplier.push({
                                'invoiceSupplierId': null,
                                'numberInvoice': null,
                                'billDate': null,
                                'amountForInvoice': null,
                            });
                            element.statusHasInvoice = this.$constant.statusActive;
                            element.infoInvoiceSupplier = infoInvoiceSupplier;
                       }
                    });
                }
                return true;
            }  
        },
        async save () {
            let entriesClone = clone(this.entries);
            let dataSave = [];
            entriesClone.forEach(element => {
                if(element.infoInvoiceSupplier){
                    element.infoInvoiceSupplier.forEach(invoice => {
                        element.code = invoice.numberInvoice;
                        let data = clone(element);
                        data.numberInvoice = invoice.numberInvoice;
                        data.billDate = invoice.billDate;
                        data.amountForInvoice = invoice.amountForInvoice;
                        data.deleted = invoice.deleted ?? null;
                        data.invoiceSupplierId = invoice.invoiceSupplierId ?? null;
                        dataSave.push(data);
                    });
                }
            });
            const result = await $post('/v1/purchase-orders/save-invoice-supplier' ,{ ...dataSave })
            if (result.result && result.result.code == 422) {
                toastr.error(result.result.message);
                window.jQuery(this.$refs.modal).modal('hide');
            }
            else if (result.result == true) {
                toastr.success(this.$t('message.common.addInvoiceForPOSuccess'));
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit("updated", "1");
            }
            else {
                toastr.error(this.$t('message.common.errorServer'));
            }
        },
        skip() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        confirm(){
            this.$emit('confirm', 1);
            window.jQuery(this.$refs.modal).modal('hide');
        },
        changeStatusInvoice(entry, index){
            entry.infoInvoiceSupplier.forEach(invoice => {
                invoice.deleted = true;
            })
            this.addInvoiceSupplier(index);
        },
        addInvoiceSupplier(index){
            this.entries[index].infoInvoiceSupplier.push(
            {
                'invoiceSupplierId': null,
                'numberInvoice': null,
                'billDate': null,
                'amountForInvoice': null,
            });
        },
        removeInvoiceSupplier(index, invoiceSupplierIndex){
            this.entries[index].infoInvoiceSupplier[invoiceSupplierIndex].deleted = true;
        }
    },
    watch: {
        entries: {
            handler: function(dataNew) {
                dataNew.forEach(entry => {
                    let isEmptyInvoice = true;
                    if(!entry.infoInvoiceSupplier || (entry.infoInvoiceSupplier && entry.infoInvoiceSupplier.length === 0)){
                        entry.infoInvoiceSupplier = [
                            {
                                'invoiceSupplierId': null,
                                'numberInvoice': null,
                                'billDate': null,
                                'amountForInvoice': null,
                            }
                        ];
                    }else{
                        let totalAmount = 0;
                        entry.infoInvoiceSupplier.forEach(invoice => {
                            if(!invoice.deleted){
                                totalAmount += +invoice.amountForInvoice;
                                if(isEmptyInvoice && invoice.numberInvoice && invoice.billDate && invoice.amountForInvoice){
                                    isEmptyInvoice = false;
                                }
                            }
                        });
                        entry.totalAmountForInvoice = totalAmount;
                    }
                    // entry.statusHasInvoice = !entry.numberInvoice ? this.$constant.statusInActive : this.$constant.statusActive;
                    if(!entry.statusHasInvoice && entry.statusHasInvoice !== null){
                        entry.statusHasInvoice = !entry.numberInvoice ? this.$constant.statusInActive : this.$constant.statusActive;
                    }
                    if(entry.statusHasInvoice == this.$constant.statusInActive){
                        entry.statusName = this.$constant.not_invoice_supplier;
                        entry.statusValue = this.$constant.status_not_invoice_supplier;
                    }else{
                        entry.statusName = isEmptyInvoice ? this.$constant.not_receive_invoice_supplier : this.$constant.received_invoice_supplier;
                        entry.statusValue = isEmptyInvoice ? this.$constant.status_not_receive_invoice_supplier : this.$constant.status_received_invoice_supplier;
                    }
                    if(entry.statusHasInvoice === null){
                        entry.statusName = this.$constant.not_receive_invoice_supplier;
                        entry.statusValue = this.$constant.status_not_receive_invoice_supplier;
                    }
                });
            },
            deep: true
        },
    }
}
</script>
<style scoped>

.format__input {
    border-bottom: 1px solid #C7C5C5!important;
    margin-bottom: 10px;
    text-align: right;
    border: none;
    background: none;
    width: 100%;
    outline: none !important;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.cell-check{
    width: 30px;
    min-width: 30px;
    max-width: 30px;
}
.cell-name{
    width: 120px;
    min-width: 120px;
    max-width: 120px;
}
.cell-auto{
    min-width: 120px;
}

.cell-large{
    width: 420px;
    min-width: 420px;
    max-width: 420px;
}
.container {
    width: 100% !important;
    max-width: inherit !important;
}
</style>
