<template>
    <div class="container-fluid">
        <Header v-if="showHeader"></Header>
        <HeaderSaleOnline v-if="showHeaderSaleOnline"></HeaderSaleOnline>

        <div class="main-content" :class="showHeader ? '' : 'p-0'">
            <div v-if="IsPageNotFound">
                Không tìm thấy trang
            </div>
            <router-view v-else></router-view>
        </div>
        <ModalBranchSelect/>
        <LoadingData ref="LoadingData"/>
        <ModalPermissionRequired v-if="showHeader"/>
    </div>

</template>

<script lang="js">
import {defineComponent} from 'vue';
import Header from "@/components/Header";
import HeaderSaleOnline from "@/components/HeaderSaleOnline";
import ModalBranchSelect from "@/components/ModalBranchSelect";
import store from "@/store";
import ModalPermissionRequired from "@/components/ModalPermissionRequired";
import 'vue3-treeselect/dist/vue3-treeselect.css'
import 'vue3-loading-overlay/dist/vue3-loading-overlay.css';
import toastr from 'toastr';
import LoadingData from "@/components/LoadingData";
import storeModul from "@/storeModul";
import { $alert } from "@/lib/utils";
import { getMessaging, onMessage } from "firebase/messaging";
import { app } from "@/firebase";

const messaging = getMessaging();
onMessage(messaging, (payload) => {
    console.log('Message main. ', payload);
    $alert({
        code: 200,
        message: payload.data.message,
    })
});
const noHeaderScreens = {
    Login: true,
    Register: true,
    SaleScreen: true,
    OrderCreate: true,
    CreateOrder: true,
    CopyOrders:true,
    EditOrder: true,
    SaleOnlineScreen: true,
    NewConnectScreen: true,
    NewSettingScreen: true,
    NewMappingScreen: true,
    MappingScreen: true,
    SyncFailScreen: true,
    CreateTickerTransferScreen: true,
    CreateTickerExportScreen: true,
    CreateTickerImportScreen: true,
    CreateSettlementAdvanceScreen: true,
    CreateRequestRefundScreen: true,
    PlansCreateUpdateScreen: true,
    PlanPurchaseCreateUpdateScreen: true,
    DiscountsBasCreate: true,
    DiscountsBasUpdate: true
    // notPermission: true,
};
const HeaderSaleOnlineScreens = {
    SaleOnlineScreen: true,
    NewConnectScreen: true,
    NewSettingScreen: true,
    NewMappingScreen: true,
    MappingScreen: true,
    SyncFailScreen: true,
};
export default defineComponent({
    name: 'App',
    components: {
        ModalPermissionRequired,
        ModalBranchSelect,
        Header,
        LoadingData,
        HeaderSaleOnline
    },
    mounted() {
        toastr.options = {
            "debug": false,
            "positionClass": "toast-bottom-right",
            "onclick": null,
            "fadeIn": 300,
            "fadeOut": 1000,
            "timeOut": 5000,
            "extendedTimeOut": 1000
        }
    },
    computed: {
        IsPageNotFound: () => store.state.IsPageNotFound,
        showHeader() {
            return !noHeaderScreens[this.$route.name];
        },
        showHeaderSaleOnline() {
            return HeaderSaleOnlineScreens[this.$route.name];
        }
    },
    updated() {
        storeModul.dispatch('settingStore/getStatus', this.$route.name);
    }
});
</script>
<style lang="scss">
    .cke_notifications_area {
        display: none !important;
    }
    @import "./assets/css/style";
    @import "./assets/css/customer";
    @import "./assets/css/header";
</style>

