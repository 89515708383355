// import http from '@/services/http';
// import apiEndpoints from '@/services/apiEndpoints';
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
// import router from '@/router';
const getDefaultState = () => {
    return {
        indexAttributeRemove: null,
        productUnitIdAttributeRemove: null,
        showModalConfirnRemoveAtrribute: false,
        statusConfirmRemoveAtrribute: null,
        productIds: [],
    };
};
const state = getDefaultState();
const getters = {
    indexAttributeRemove (state) {
        return state.indexAttributeRemove;
    },
    showModalConfirnRemoveAtrribute (state) {
        return state.showModalConfirnRemoveAtrribute;
    },
    statusConfirmRemoveAtrribute (state) {
        return state.statusConfirmRemoveAtrribute;
    },
    productUnitIdAttributeRemove (state) {
        return state.productUnitIdAttributeRemove;
    },
    productIds (state) {
        let productIds = localStorage.getItem('productIds');
        return JSON.parse(productIds);
    },
};
const mutations = {
    SET_INDEX_ATTRIBUTE_REMOVE (state, indexAttributeRemove) {
        state.indexAttributeRemove = indexAttributeRemove;
    },
    SET_SHOW_MODAL_CONFIRM_REMOVE_ATRRIBUTE (state, showModalConfirnRemoveAtrribute) {
        state.showModalConfirnRemoveAtrribute = showModalConfirnRemoveAtrribute;
    },
    SET_STATUS_CONFIRM_REMOVE_ATRRIBUTE (state, statusConfirmRemoveAtrribute) {
        state.statusConfirmRemoveAtrribute = statusConfirmRemoveAtrribute;

    },
    SET_DATA_REMOVE_ATRRIBUTE (state, data) {
        state.indexAttributeRemove = data.index;
        state.productUnitIdAttributeRemove = data.productUnitId;
    },
    SET_PRODUCT_IDS (state, data) {
        state.productIds = data;
        localStorage.setItem('productIds', JSON.stringify(data))
    },
};
const actions = {
    async setIndexAttributeRemove ({commit}, indexAttributeRemove) {
        commit('SET_INDEX_ATTRIBUTE_REMOVE', indexAttributeRemove);
    },
    async setShowModalConfirnRemoveAtrribute ({commit}, showModalConfirnRemoveAtrribute) {
        commit('SET_SHOW_MODAL_CONFIRM_REMOVE_ATRRIBUTE', showModalConfirnRemoveAtrribute);
    },
    async setStatusConfirmRemoveAtrribute ({commit}, statusConfirmRemoveAtrribute) {
        commit('SET_STATUS_CONFIRM_REMOVE_ATRRIBUTE', statusConfirmRemoveAtrribute);
    },
    async setDataRemoveAtrribute ({commit}, data) {
        commit('SET_DATA_REMOVE_ATRRIBUTE', data);
    },
    async setProductIds({commit}, data) {
        commit('SET_PRODUCT_IDS', data);
    },
    
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
