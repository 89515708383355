<template>
  <div class="container"  >
    <div class="row mx-0 px-0">
      <div class="col-lg-1 px-0 ">
        <h3><strong>
            {{$t('message.common.printTemplate')}}
        </strong></h3>
      </div>
      <div class="col-lg-11">
        <div class="tab-navigation">
          <ul class="nav nav-tabs">
            <li class="nav-item" v-for="tab in tabs" :key="tab.id">
              <a
                class="nav-link"
                @click.prevent="changeTab(tab)"
                :class="{ active: tab.id === currentTabId }"
                href=""
                >{{ tab.name }}</a
              >
            </li>
          </ul>
          
        </div>
      </div>
      <div class="col-lg-12" >
        <div class="tab-navigation-content">
            <keep-alive>
              <component
                ref="currentTab"
                @onValidate="onValidate"
                @print="onPrint()"
                :is="currentTab.component"
                :id="id"></component>
            </keep-alive>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { clone, forEach } from "@/lib/utils";

export default {
  name: "TabTemplates",  props: ["tabs", "id"],
  mounted() {
    this.$emit("tabChange", this.currentTab);
  },
  methods: {
    changeTab(tab) {      
      this.currentTab = tab;
      this.currentTabId = tab.id;
      location.hash = tab.id;
      this.$emit("tabChange", tab);
    },
    onValidate() {
      this.$emit("onValidate");
    },
    getTabData(key) {
      return this.$refs.currentTab[key];
    },
    onPrint() {
      this.$emit("onPrint");
    },
    setTabData(key, value) {
      const keyType = typeof key;

      if (keyType === "object") {
        forEach(key, (v, k) => {
          this.$refs.currentTab[k] = v;
        });
      } else {
        if (keyType !== "string") {
          throw new Error(
            "TabTemplates.setTabData: key must be a string or object"
          );
        }

        this.$refs.currentTab[key] = value;
      }
    },
  },
  data() {
    if (!Array.isArray(this.tabs)) {
      throw new Error("TabTemplates: props.tabs is invalid");
    }

    if (this.tabs.length === 0) {
      throw new Error("TabTemplates: tabs is empty");
    }

    this.tabs.forEach((tab) => {
      if (!tab.id) {
        throw new Error("TabTemplates: Missing tab.id");
      }

      if (!tab.name) {
        throw new Error("TabTemplates:Missing tab.name");
      }

      if (!tab.component) {
        throw new Error("TabTemplates:Missing tab.component");
      }
    });

    let currentTabId = location.hash.substr(1);
    let current = this.tabs.find((tab) => tab.id === currentTabId);

    return {
      currentTabId: current ? current.id : this.tabs[0].id,
      currentTab: current ? current : this.tabs[0],
    };
  },
};
</script>

<style scoped>
.tab-navigation-content {
  margin-top: 5px;
}
.tab-navigation {
  margin-bottom: 5px;
}

.tab-navigation .nav-link {
  text-decoration: none;
  color: #000;
}
.tab-navigation .nav-link.active {
  font-weight: bold;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #008080;
}
</style>
