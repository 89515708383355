<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 1250px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{ $t('message.button.selectInvoiceForReturn') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card mt-1" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">
                                                {{$t('message.InputQuickSearch.button.search')}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputText v-model="filter.code_invoice"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.placeholder.code_invoice')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model="filter.delivery_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.placeholder.delivery_code')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model="filter.code_name_phone"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.placeholder.code_name_phone')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model="filter.product_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.placeholder.product_code')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model="filter.product_name"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.placeholder.product_name')"
                                                @update:modelValue="search"
                                            ></InputText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">
                                                {{$t('message.common.time')}}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.start_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model="filter.start_date"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.end_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model="filter.end_date"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <table class="table table-index table-striped">
                                <thead>
                                <tr>
                                    <th>
                                        {{ $t('message.InvoiceIndex.code') }}
                                    </th>
                                    <th>
                                        {{ $t('message.common.createdAt') }}
                                    </th>
                                    <th>
                                        {{ $t('message.headers.employees') }}
                                    </th>
                                    <th>
                                        {{ $t('message.headers.customers') }}
                                    </th>
                                    <th>
                                        {{$t('message.OrderIndex.total')}}
                                    </th>
                                    <th></th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-for="invoice in data" :key="invoice.id">
                                        <tr>
                                            <td>
                                                <a :href="'/invoices/index?code=' + invoice.code" target="_blank" class="text-decoration-none">{{ invoice.code }}</a>
                                            </td>
                                            <td v-text="dt(invoice.created_at)"></td>
                                            <td v-text="invoice.soldByName"></td>
                                            <td v-text="invoice.customerName"></td>
                                            <td v-text="n(invoice.total)"></td>
                                            <td>
                                                <button class="btn btn-sm btn-outline-dark" @click="checkReturnTime(invoice)" :disabled="invoice.statusInvoice">{{ $t('message.button.choose') }}</button>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="data.length == 0">
                                        <tr>
                                            <td :colspan="6" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <p>
                                                            {{$t('message.common.notFound')}}
                                                        </p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>

                            <Pagination :value="paginate" :record="this.numberRecord" @change="load"/>
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-save" @click="fastReturnProduct()">
                            <i class="fa fa-save"></i>&nbsp;
                            {{$t('message.button.returnFast')}}
                        </button>
                        <button type="button" class="btn btn-secondary ml-2" data-dismiss="modal">
                            <i class="fa fa-ban"></i>&nbsp;
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalCheckSettingReturnTime ref="ModalCheckSettingReturnTime" @confirm="onSelectInvoice"/>
</template>

<script>
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputText from "../../components/forms/InputText";
    import {$post, debounce, $alert} from "../../lib/utils";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    import Pagination from "@/components/Pagination";
    import emitter from "@/lib/emitter";
    import ModalCheckSettingReturnTime from '@/components/returns/ModalCheckSettingReturnTime.vue';
    let app;

    export default {
        name: "ModalSelectInvoiceProcessing",
        emits: ['select'],
        components: {Pagination, InputDatePicker, InputText, InputNumberFormat, ModalCheckSettingReturnTime},
        data() {
            return {
                data: [],
                filter: {
                    product_code: '',
                    product_name: '',
                    code_name_phone: '',
                    code_invoice: '',
                    delivery_code: '',
                    start_date: '',
                    end_date: '',
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                },
                numberRecord: 15,
            }
        },
        mounted() {
            app = this;
        },
        methods: {
            show() {
                this.clearValue();
                this.load();
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            },
            async load(page = 1) {
                emitter.emit("onLoading");
                const res = await $post('/v1/invoices/getInvoiceProcessing', {...this.filter, record: this.numberRecord, page: page});
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }
                this.paginate = res.result.data.paginate;
                this.data = res.result.data.entries;
                emitter.emit("offLoading");
            },
            checkReturnTime(entry){
                this.$refs.ModalCheckSettingReturnTime.show(entry, this.$constant.settingReturnTimeTypeInvoice, true);
            },
            onSelectInvoice(invoice) {
                this.$emit('select', invoice.code);
                window.jQuery(this.$refs.modal).modal('hide');
            },
            search() {
                app.load();
            },
            clearValue(){
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
            },
            fastReturnProduct () {
                this.$emit('fastReturn');
                window.jQuery(this.$refs.modal).modal('hide');
            }
        }
    }
</script>

<style scoped>
.title-search {
    background-color: #b1ddf0;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.table th, .table td {
    height: 40px !important;
}
.table tbody td{
    background-color: #F2F2F2;
    border-bottom: 1px solid #FFFFFF;
}
.form-group .form-control{
    padding-left: 0 !important;
}
</style>
