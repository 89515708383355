<template > 
        <div v-show="false" id="productBarcode1">
            <div class="productBarcode_wrap row" id="divToPrint">     
                <template v-for="(item,inds)  in this.products" :key="item.prdId  + '-' + inds">                          
                        <PaperRollLabelBarcode v-if="this.tempType !== 2 && this.tempType !== 6" ref="PaperRollLabelBarcode" :products="this.products" :status="this.status" :item="item"/>
                        <TommyPaperBarcode v-if="this.tempType == 2" ref="TommyPaperBarcode" :products="this.products" :status="this.status" :item="item"/>
                        <JewelryBarcode v-if="this.tempType == 6" ref="JewelryBarcode" :products="this.products" :status="this.status" :item="item"/>                        
                        <!-- Ngat dong -->
                        <div v-if="this.tempType == 6" style="page-break-before: always"></div>
                        <div v-else-if="this.tempType == 2 && ((inds + 1)%item.number) == 0 && (inds + 1) != products.length" style="page-break-before: always"></div>                        
                        <div v-else-if="((inds + 1)%item.number) == 0 && (inds + 1) != products.length" style="page-break-before: always"></div>                        
                </template>
            </div>
        </div>
</template>


<script>            
    
    import {cloneObject} from "../../../lib/utils";
    import emitter from '@/lib/emitter';
    import PaperRollLabelBarcode from '@/components/products/PaperRollLabelBarcode';    
    import TommyPaperBarcode from '@/components/products/TommyPaperBarcode';        
    import JewelryBarcode from '@/components/products/JewelryBarcode';   
    //PDF
    import pdfMake from "pdfmake";
    import pdfFonts from "pdfmake/build/vfs_fonts";
    import htmlToPdfmake from "html-to-pdfmake";
    import {rand} from "@/lib/utils";
    import BarcodeGenerator from '@/components/products/BarcodeGenerator';

    export default {
        name: "PrintProductBarcode",    
        components: {PaperRollLabelBarcode, TommyPaperBarcode, JewelryBarcode,
        BarcodeGenerator },   
        props: {
            status:{},
            number: null,
            width: null,
            height: null,
            tempType: null,       
        } ,
        data() {
            return {
                products:[],
                show: false,
                numbers: null,
            }
        },
        created(){            
            emitter.on('changeQuantityProductBarcode', (item) => {            
                this.setQuantityProduct(item.quantity, item.data) 
                this.products = item.data
            });
            emitter.on('loadDataProducts', (item) => {                              
                this.products = item.data
            })
        },
        watch: {
            products(newData){ 
                 this.products = newData
                 emitter.emit('loadDataProducts', {data: newData}); 
            },
            number(newData){                
                this.numbers = newData;                
            },
            width(newData){
                this.widths = newData;
            },
            height(newData){
                this.heights = newData;
            },            
        },
        methods: {
            print(product) {                
                if (!product.quantity) {
                    product.quantity = 1;
                }
                this.products = [product];
                this.launch();
            },
            multiplePrint(products) {    
                
                if (products && products.length) {
                    const data = [];
                    products.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = 1;
                        }
                        const item = cloneObject(p);
                        for (let i = 0; i < p.prdQuantity; i++) {
                            item.number = this.number;
                            item.priceOptions = this.status.priceOptions;
                            item.currentOptions =  this.status.currentOptions;
                            item.unitOptions =  this.status.unitOptions;
                            item.nameOptions =  this.status.nameOptions;
                            data.push(item);
                        }
                    });
                   
                    this.products = data;
                }                
                this.launch();                
            },
            setQuantityProduct(quantity, data){                   
                if (this.products && this.products.length) {
                    this.products.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = quantity;
                        }else{
                            p.prdQuantity = quantity;
                        }
                    });
                }else if(data && data.length){
                    data.forEach((p) => {
                        if (!p.prdQuantity) {
                            p.prdQuantity = quantity;
                        }else{
                            p.prdQuantity = quantity;
                        }
                    });
                    this.products = data;
                }
            },      
            launch() {
                //get table html
                const pdfTable = document.getElementById("divToPrint");
                
                //html to pdf format
                let html = htmlToPdfmake(pdfTable.innerHTML);
                
                const documentDefinition = { content: html };
                
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).download(`Ma_Vach_` +  rand(100000,999999)  + `.pdf`);
                pdfMake;
            },
        },
        mounted(){     
            
        }
    }
</script>