import { createStore } from 'vuex'
import storage from "@/lib/storage";
import userStore from '@/storeModul/modules/userStore';
import productStore from '@/storeModul/modules/productStore';
import sendMailTemplateStore from '@/storeModul/modules/sendMailTemplateStore';
import settingStore from '@/storeModul/modules/settingStore';
import orderStore from '@/storeModul/modules/orderStore';
import { $post } from './lib/utils';

// Create a new store instance.
const initialState: GlobalState = {
    Auth: storage.get('Auth'),
    authAhamove: storage.get('authAhamove'),
    GlobalConfig: null,
    ShowPermissionRequired: false,
    CurrentBranch: storage.get('CurrentBranch'),
    IsPageNotFound: false,
    PermissionInfo: null,
    RequiredPermissions: [],
}

const store = createStore({
    state () {
        return initialState;
    },
    mutations: {
        SetAuth(state: GlobalState, value: AuthData | null) {
            state.Auth = value;
            storage.set('Auth', value);
        },
        setAhamoveAccount(state: GlobalState, value: AuthAhamove | null) {
            state.authAhamove = value;
            storage.set('authAhamove', value);
        },
        RemoveAuthAhamove(state: GlobalState) {
            storage.remove('authAhamove');
            state.authAhamove = null;
        },
        RemoveAuth(state: GlobalState) {
            $post('/v1/history-logout');
            storage.remove('Auth');
            const language = localStorage.getItem('language') ?? 'vi';
            localStorage.clear();
            localStorage.setItem('language', language);
            state.Auth = null;
        },
        SetGlobalConfig(state: GlobalState, value: GlobalConfig | null) {
            state.GlobalConfig = value;
        },
        SetCurrentBranch(state: GlobalState, value: Branch | null) {
            state.CurrentBranch = value;
            storage.set('CurrentBranch', value);
        },
        async SetCurrentBranchForLogin(state: GlobalState, value: Branch | null) {
            state.CurrentBranch = value;
            await storage.set('CurrentBranch', value);
            $post('/v1/history-login');
        },
        SetIsPageNotFound(state: GlobalState, value:boolean) {
            state.IsPageNotFound = value;
        },
        SetPermissionInfo(state: GlobalState, permissionInfo: PermissionInfo) {
            storage.set('PermissionInfo', permissionInfo);

            permissionInfo.permissionNameMap = {};

            permissionInfo.permissions.forEach(p => {
                permissionInfo.permissionNameMap[p.name] = p.id;
                if(p.children){
                    p.children.forEach((c:any) => {
                        permissionInfo.permissionNameMap[c.name] = c.id;
                    })
                }
                
            });
            state.PermissionInfo = permissionInfo;
        },
        PushRequiredPermissions(state: GlobalState, value: any) {
            const map: any = {};
            state.RequiredPermissions.forEach (p => {
                if (p.name) {
                    map[p.name] = true;
                }

            });

            value.forEach( (p: any) => {
                if (p.name) {
                    map[p.name] = true;
                }
            });

            state.RequiredPermissions = Object.keys(map);
        },
        SetShowPermissionRequired(state: GlobalState, value: boolean)
        {
            state.ShowPermissionRequired = value;
        }
    },
    modules:{
        userStore,
        productStore,
        sendMailTemplateStore,
        settingStore,
        orderStore
    }
})

export default store;
