<template>
    <div ref="modalCreateAttribute" class="modal mx-0 fade modal-third" tabindex="-1" id="modalCreateAttribute">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-3">
                    <h5 class="modal-title py-2">
                        {{$t('message.common.addAttribute')}}
                    </h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <b>{{$t('message.common.attributeName')}}</b>
                            <label class="text-danger px-0"><strong>*</strong></label>
                        </div>
                        <div class="col-md-8">
                            <input type="text" v-model.trim="form.attributeName" class="form-control pl-0 input-custom bg-transparent border-bottom-focus-active" autocomplete="off">
                        </div>
                    </div>
                    <div class="row d-flex justify-content-end mx-3 mt-3">
                        <button type="button" class="btn btn-save" @click="createAttribute">
                            {{$t('message.button.save')}}
                        </button>
                        <button type="button" class="router-link-active router-link-exact-active btn btn-secondary text-white ml-2" @click="skip">
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import {$post, $alert} from "@/lib/utils";

export default {
    name: "modalCreateAttribute",
    data(){
        return {
            form: {
                attributeName: null,
            },
            index: null,
        }
    },
    // props: ['message', 'title', 'content','disabledJobTitle'],
    methods: {
        async createAttribute() {
            const res = await $post('/v1/products/create-attribute', {...this.form});
            if (res && res.result && res.result.status) {
                $alert({code: 200, message: this.$t('message.common.addAttributeSuccess')});
                this.form.attributeName = null;
                this.loadData(res.result.id);
                this.hide();
                return;
            } else if (res && res.code == 422) {
                const errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                return;
            } else {
                $alert({code: 500, message: this.$t('message.common.errorServer')});
            }
        },
        show(index) {   
            this.index = index; 
            window.jQuery(this.$refs.modalCreateAttribute).modal('show');
        },
        hide() {    
            this.index = null;        
            window.jQuery(this.$refs.modalCreateAttribute).modal('hide');
        },
        skip() {
            this.$emit('cancel');
            window.jQuery(this.$refs.modalCreateAttribute).modal('hide');
        },
        confirm(){
            this.$emit('confirm');
            window.jQuery(this.$refs.modalCreateAttribute).modal('hide');
        },
        loadData(attId){
            this.$emit('loadData', {attId: attId , index: this.index});
        }
    }
}
</script>

