<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 pl-4" :class="entry.products && entry.products.length ? 'col-left' : ''">            
            <table class="table table-index table-striped">
                <thead class="text-center">
                    <tr>
                        <th scope="col" style="width: 3%" class="cell-auto">{{ $t('message.planCreateUpdate.table.stt') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.code') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.name') }}</th>                
                        <th scope="col" style="width: 9%" class="cell-auto">{{ $t('message.planCreateUpdate.table.unit') }}</th>                
                        <th scope="col" style="width: 8%" class="cell-auto">{{ $t('message.planCreateUpdate.table.total_quantity_order') }}</th>                
                        <th scope="col" style="width: 8%" class="cell-auto">{{ $t('message.planCreateUpdate.table.total_quantity_real_order') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.price') }}</th>                
                        <th scope="col" style="width: 10%" class="cell-auto">{{ $t('message.planCreateUpdate.table.supplier') }}</th>                 
                    </tr>
                </thead>
                <tbody class="font-weight-bold">
                    <template v-if="entry.products && entry.products.length">
                        <template v-for="(product, index) in entry.products" :key="index">
                            <tr>
                                <td scope="col" class="cell-auto" v-text="index + 1"></td>                
                                <td scope="col" class="cell-auto" v-text="product.productCode"></td>                
                                <td scope="col" class="cell-auto" v-text="product.productName"></td>                
                                <td scope="col" class="cell-auto" v-text="product.productUnitName"></td>                
                                <td scope="col" class="cell-auto" v-text="n(product.totalQuantityOrder)"></td>                
                                <td scope="col" class="cell-auto">
                                    <InputNumberFormat :pxNone="true" :readonly="this.entry.createPO ? true : false" v-model="product.totalQuantityRealOrder" :bgTransparent="true" :cardTransaction="true" class="mb-0" :min="1"/>
                                </td>                
                                <td scope="col" class="cell-auto">
                                    <InputNumberFormat :pxNone="true" :readonly="this.entry.createPO ? true : false" v-model="product.price" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                </td>                
                                <td scope="col" class="cell-auto">
                                    <div class="position-relative">
                                        <SelectSearch :vModel="true" v-model="product.supplierId" 
                                            :modelValue="product.supplierId" 
                                            :label="'supplier_'" 
                                            :classCustom="'py-2'"
                                            :plNone="true"
                                            :searchData="'supplier'"
                                            :nameSearch="product.supplierCode"
                                            :readonly="this.entry.createPO ? true : false"
                                            :disabled="this.entry.createPO ? true : false"
                                            v-if="!this.entry.createPO">
                                        </SelectSearch>
                                        <span v-else v-text="product.supplierName ? product.supplierName : null"></span>
                                    </div>
                                </td>                    
                            </tr>
                        </template>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="col-4 col-xl-3 px-0 col-right">
            <div class="col-right__content">
                <div class="overflow-a-part">
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.created_by') }}:
                        </div>
                        <div class="col-4 pl-0" v-text="this.entry.createdByName"></div>
                        <div class="col-4" v-text="this.entry.planPurchaseDate"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_code') }}:
                        </div>
                        <div class="col-8 pl-0">
                            <input type="text" :readonly="this.entry.createPO ? true : false" v-model.trim="entry.code" :placeholder="$t('message.PlanIndex.autoCreateCode')" class="form-control w-100 border-bottom-1 rounded-0 pl-0">
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_name') }}:
                        </div>
                        <div class="col-8 pl-0">
                            <textarea class="border-0 bg-transparent border-bottom-1 rounded-0 w-100 textarea-outline-none" :class="this.entry.createPO ? 'cursor-ban' : ''" :placeholder="$t('message.PlanIndex.name')" :readonly="this.entry.createPO ? true : false" v-model.trim="entry.planName"></textarea>
                            <!-- <input type="text" v-model.trim="entry.planName" placeholder="Tên báo cáo" class="form-control w-100 border-bottom-1 rounded-0 pl-0"> -->
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_branch') }}: <span class="text-danger">*</span>
                        </div>
                        <div class="col-8 pl-0">
                            <q-select name="branchIDs" :cardSettingPrice="true" :readonly="this.entry.createPO ? true : false" 
                            :default-options="branches" :multiple="true" v-model="entry.branchIDs" :selectAll="true" 
                            @update:modelValue="changeBranch"
                            :placeholder="$t('message.ModalProductFormTabMain.placeholder.branch')"/>
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0 d-flex align-items-end">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_time') }}: <span class="text-danger">*</span>
                        </div>
                        <div class="col-lg-3 px-0">
                            <input type="date" v-model="entry.startDate" class="form-control w-100 border-bottom-1 rounded-0 px-0">
                        </div>
                        <div class="col-lg-2 px-1 mb-2 text-center">{{ $t('message.planCreateUpdate.to') }}</div>
                        <div class="col-lg-3 pl-0">
                            <input type="date" v-model="entry.endDate" class="form-control w-100 border-bottom-1 rounded-0 px-0">
                        </div>
                        <!-- <div class="col-3 px-0 border-bottom-1 cursor-pointer" v-text="entry.startDate"></div>
                        <div class="col-2 d-flex justify-content-center">{{ $t('message.planCreateUpdate.to') }}</div>
                        <div class="col-3 px-0 border-bottom-1 cursor-pointer" v-text="entry.endDate"></div> -->
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_estimate') }}: <span class="text-danger">*</span>
                        </div>
                        <!-- <div class="col-8 pl-0 text-right" v-text="(entry.estimatedDate ? entry.estimatedDate : 0) + ' ngày'"></div> -->
                        <div class="col-8 pl-0">
                            <InputNumberFormat :pxNone="true" :max="365" v-model="entry.estimatedDate" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                        </div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.plan_status') }}:
                        </div>
                        <div class="col-8 pl-0 text-right" v-text="entry.statusName"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.total_product') }}:
                        </div>
                        <div class="col-8 pl-0 text-right text-bold" v-text="n(entry.totalProducts)"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.total_quantity_product') }}:
                        </div>
                        <div class="col-8 pl-0 text-right text-bold" v-text="n(entry.totalQuantityProducts)"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-4">
                            {{ $t('message.planCreateUpdate.total_supplier') }}:
                        </div>
                        <div class="col-8 pl-0 text-right text-bold" v-text="n(entry.totalSupplier)"></div>
                    </div>
                    <div class="row mt-2 mx-0 p-0">
                        <div class="col-12 mt-3">
                            <InputTextArea :placeholder="$t('message.PlanIndex.note') + '...'" v-model.trim="entry.note" class="input__textArea mb-0"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-right__save row mx-0 px-0">
                <div class="col-12 text-center">
                    <div class="row justify-content-center">
                        <div class="col-lg-4">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-primary" @click="submit(this.$constant.plan_purchase_status_draft)" v-if="!this.entry.createPO">
                                <div>{{ $t('message.planCreateUpdate.button.draft') }}</div>
                            </button>
                        </div>
                        <div class="col-lg-4">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submit(this.$constant.plan_purchase_status_complete)" v-if="!this.entry.createPO">
                                <div>{{ $t('message.planCreateUpdate.button.success') }}</div>
                            </button>
                        </div>
                        <div class="col-lg-4" :class="this.entry.createPO ? 'col-lg-12' : ''">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="submit(this.$constant.plan_purchase_status_complete_order)">
                                <div>{{ $t('message.planCreateUpdate.button.create_order') }}</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalDetailTicket ref="ModalDetailTicket" />
</template>

<script>
    import InputSelect from "@/components/forms/InputSelect";
    import store from "../../store";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputTextArea from "@/components/forms/InputTextArea";
    import storage from "@/lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalDetailTicket from './ModalDetailTicket';
    import InputNumber from "@/components/forms/InputNumberCustom";    
    import AppPermission from '@/components/AppPermission.vue';    
    import permissionMixin from '@/permissionMixin';
    import QSelect from "@/components/QSelect";
    export default {
        name: "PlanPurchaseTab",
        emits: ['changeEntryBranch', 'changeStartDate', 'changeEndDate', 'removeTab', 'orderProcessing', 'print'],
        mixins: [currencyMixin, permissionMixin],
        components: {
            InputTextArea,
            InputNumberFormat,
            InputSelect,
            SelectSearch,
            ModalDetailTicket,
            InputNumber,
            AppPermission,
            QSelect
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            const globalConfig = store.state.GlobalConfig;
            return {
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                customers: [],
                branchName: store.state.CurrentBranch.name ?? null,
                user,
                createdByOption: globalConfig.users,
                paymentChannelCashMoney: 0,
                paymentChannelTransferMoney: 0,
                paymentChannelCardMoney: 0,
                paymentChannelCash: false,
                paymentChannelCard: false,
                paymentChannelTransfer: false,
                branches: globalConfig.branches,
                ids: [],
                listSupplier: [],
                isButtonDisabled: false,
                currentBranch: store.state.CurrentBranch,
            }
        },
        async mounted() {
            document.title = this.$t('message.planCreateUpdate.title_plan_purchase');
            this.entry.planPurchaseDate = !this.entry.planPurchaseDate ? moment().format('DD/MM/YYYY H:mm') : moment(this.entry.planPurchaseDate).format('DD/MM/YYYY H:mm');
            this.entry.createdByName = !this.entry.createdByName ? this.user.name : this.entry.createdByName;
            if(this.entry.branchIDs){
                this.$emit('changedEntryBranch', this.entry.branchIDs);
            }
            if(!this.entry.endDate){
                this.entry.endDate = moment().format('YYYY-MM-DD');
            }
            if(!this.entry.startDate){
                this.entry.startDate = moment().subtract(29, 'days').format('YYYY-MM-DD');
            }  
        },
        async created() {
            emitter.on('reloadPlanPurchase', (data) => {
                this.loadData(data);
            })
        },
        methods: {
            async submit(status){
                this.isButtonDisabled = true;
                let data = {...this.entry};
                data.status = status;
                data.branchId = store.state.CurrentBranch ? store.state.CurrentBranch.id : null;
                data.name = data.planName ?? null;
                data.id = data.originId ? data.originId : (data.id ?? null);
                if(!data.products || (data.products && data.products.length <= 0)){
                    $alert({code: 422, message: this.$t('message.planCreateUpdate.alerts.error')});
                    return;
                }
                let result;
                this.entry.isUpdate = this.entry.isUpdate ? true : false;
                if(this.entry.isUpdate){
                    result = await $post('/v1/plan-purchases/update', {...data});
                }else{
                    result = await $post('/v1/plan-purchases/create', {...data});
                }
                if(result.code === 422){
                    let errors = result.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                        $alert({code: result.code, message: errors[key][0]});
                    });
                    this.isButtonDisabled = false;
                    return;
                }else if(result.result && result.result.status){
                    if ((this.entry.isUpdate && status == this.$constant.plan_purchase_status_complete_order) || (!this.entry.isUpdate && status == this.$constant.plan_purchase_status_complete_order)) {
                        $alert({code: 200, message: this.$t('message.PlanPurchaseIndex.alerts.create_order')});
                    } else if(this.entry.isUpdate){
                        $alert({code: 200, message: this.$t('message.PlanPurchaseIndex.alerts.update_success')});
                    }else{
                        $alert({code: 200, message: this.$t('message.PlanPurchaseIndex.alerts.create_success')});
                    }
                    if(this.entry.createPO || status == this.$constant.plan_purchase_status_complete_order){
                        this.backToPOScreenIndex();
                    }else{
                        this.rollbackToScreenIndex();
                    }
                }else if(result.result && !result.result.status && !result.result.message){
                    $alert({code: 500, message: this.$t('message.PlanPurchaseIndex.alerts.update_error')});
                    this.isButtonDisabled = false;
                    return;
                }
            },
            rollbackToScreenIndex(){
                this.$router.replace({
                    name: 'PlanPurchaseIndex',
                    query: {}
                });
            },
            backToPOScreenIndex(){
                this.$router.replace({
                    name: 'OrderSupplierIndex',
                    query: {}
                });
            },
            async loadData(data = null){
                if(((this.entry.planIDs && this.entry.planIDs.length) || (this.entry.productIDs && this.entry.productIDs.length)) && !this.entry.createPO){
                    const res = await $post('/v1/plans/get-list-plans', {
                        branchIDs: this.entry.branchIDs,
                        ids: this.entry.planIDs ?? [],
                        productIDs: this.entry.productIDs ?? [],
                        planIDsOrigin: this.entry.planIDsOrigin ?? [],
                        currentBranchId: this.currentBranch.id
                    });
                    if(res.result && !res.result.status && res.result.message){
                        if(data && data.oldPlanIDs){
                            this.entry.planIDs = data.oldPlanIDs;
                        }
                        if(data && data.oldProductIDs){
                            this.entry.productIDs = data.oldProductIDs;
                        }
                        $alert({code: 500, message: res.result.message});
                        return;
                    }
                    if (res.status.code == 200 && res.result) {
                        res.result.products.forEach(data => {
                            const found = this.entry.products.find(product => (product.productId == (data.id ? data.id : (data.productId ?? null))));
                            if(found){
                                data.price = found.price ? found.price : data.price;
                                data.totalQuantityRealOrder = found.totalQuantityRealOrder ? found.totalQuantityRealOrder : data.totalQuantityRealOrder;
                                data.supplierId = found.supplierId ? found.supplierId : data.supplierId;
                            }
                        })
                        this.entry.products = res.result.products;
                        if(!this.entry.startDate){
                            this.entry.startDate = res.result.startDate ? res.result.startDate : null;
                        }
                        if(!this.entry.endDate){
                            this.entry.endDate = res.result.endDate ? res.result.endDate : null;
                        }
                        if(!this.entry.estimatedDate){
                            this.entry.estimatedDate = res.result.estimatedDate ? res.result.estimatedDate : null;
                        }
                        if(!this.entry.branchIDs || this.entry.branchIDs?.length <= 0){
                            this.entry.branchIDs = res.result.branchIDs ? res.result.branchIDs : null;
                        }
                        // if(!this.entry.planIDs){
                            this.entry.planIDs = res.result.planIDs ? res.result.planIDs : null;
                        // }
                        if(this.entry.newPlanIDs){
                            if(!this.entry.planIDs){
                                this.entry.newPlanIDs = [];
                            }else{
                                this.entry.newPlanIDs = this.entry.newPlanIDs.filter(x => this.entry.planIDs.includes(x));
                            }
                        }
                    }
                }
            },
            changeBranch(branch){
                if(!branch?.length){
                    this.entry.products = [];
                    this.entry.planIDs = [];
                    // this.entry.productIDs = [];
                }
                if(branch){
                    this.$emit('changedEntryBranch', branch);
                    this.loadData();
                }
            }
        },
        watch: {
            // 'entry.branchIDs'(value){
            //     if(!value?.length){
            //         this.entry.products = [];
            //         this.entry.planIDs = [];
            //         this.entry.productIDs = [];
            //     }
            //     if(value){
            //         this.$emit('changedEntryBranch', value);
            //         this.loadData();
            //     }
            // },
            entry: {
                handler: function(dataNew) {
                    let diffDate = 0;
                    if(dataNew.startDate && dataNew.endDate){
                        diffDate = moment(dataNew.endDate).diff(moment(dataNew.startDate), "days");
                    }
                    let totalProducts = 0;
                    let totalQuantityProducts = 0;
                    let totalQuantity = 0;
                    let suppliers = [];
                    if (dataNew.products) {
                        this.ids = [];
                        dataNew.products.forEach(product => {
                            product.productId = product.productId ? product.productId : (product.id ?? null);
                            this.ids.push(product.productId);
                            totalProducts += 1;
                            totalQuantityProducts += product.totalQuantityOrder;
                            totalQuantity += product.totalQuantityRealOrder;
                            if(product.supplierId && !suppliers.includes(product.supplierId)){
                                suppliers.push(product.supplierId);
                            }
                        });
                    }
                    this.entry.totalProducts = totalProducts;
                    this.entry.totalQuantityProducts = totalQuantityProducts;
                    this.entry.totalQuantity = totalQuantity;
                    this.entry.totalSupplier = suppliers.length;
                },
                deep: true
            },
            modelValue: {
                handler: function(dataNew) {
                    if (!dataNew) return;
                    this.entry = dataNew;
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    tbody th, tbody td {
        padding: 0.75rem !important;
        text-align: center;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            overflow: hidden auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: $background-white;
        overflow: hidden;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;
            .btn-save, .btn-primary {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;
            }
            .btn-save {
                &:hover {
                    background-color: $base-color !important;
                }
            }

            .btn-primary {
                background-color: $primary-color !important;
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .transpoter-options{
        overflow: auto;
        max-height: 300px;
    }
    .transpoter-options .disable{
        cursor: not-allowed !important;
        pointer-events: all !important;
    }
    .border-active{
        border: 1px solid $base-color;
    }

    .cart-footer-info {
        padding: 10px;
        background-color: $background-white;
        margin: 5px 0px;
        border-radius: 5px;
        box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25);
    }

    .cart-footer-info .total-quantity {
        min-width: 24px;
        height: 24px;
        line-height: 24px;
        margin: 0 5px;
        border: 1px solid #e1e1e1;
        border-radius: 3px;
        font-size: 14px;
        display: inline-block;
        padding: 0 5px;
        text-align: center;
    }

    .cart-container-content table{
        height: calc(100vh - 300px) !important;
    }
    .position-relative .fa-address-book{
        position: absolute;
        top: 35%;
        right: 0px;
    }
    .container-radio input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .icon-un-extend, .icon-extend{
        position: absolute;
        top: calc(100vh - 100px - 60%);
        left: 5px;
    }
    .overflow-a-part{
        max-height: calc(100vh - 300px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    .overflow-all{
        max-height: calc(100vh - 150px);
        overflow-y: auto;
        overflow-x: hidden;
    }
</style>
