<template>
    <div>
        <!-- <h1>Không tìm thấy dữ liệu</h1> -->
        <div class="empty-wrapper">
            <div class="row mx-0">
                <div class="col-12 text-center text-secondary mt-5">
                    <img src="../../../public/img/No-record.png">
                </div>
                <div class="col-12 text-center my-10 text-secondary mb-5">
                    <span class="border-none">{{$t('message.common.notFound')}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BadRequest",
    mounted() {
        document.title = 'Không tìm thấy dữ liệu';
    }
}
</script>

<style scoped>
.empty-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    z-index: -1;
}
</style>
