<template>
    <div v-if="isDataLoaded" class="row">
        <div class="col-lg-12 mt-4">
            <table class="table table-index table-striped">
                <thead>
                <tr>
                    <th>Mã phiếu nhập</th>
                    <th>{{$t('message.common.time')}}</th>
                    <th>{{$t('message.common.created_by')}}</th>
                    <th>{{$t('message.common.status')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in entry.purchaseOrders" :key="item.id">
                    <td v-text="item?.code"></td>
                    <td v-text="d(item?.purchaseDate)"></td>
                    <td v-text="item?.purchaseName"></td>
                    <td v-text="item?.statusName">
                    </td>
                </tr>

                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
    import InputText from "../../../components/forms/InputText";
    import InputNumber from "../../../components/forms/InputNumber";
    export default {
        name: "OrderSupplierTabPurchase",
        data() {
            return {
                isDataLoaded: false,
                entry: {
                },
            }
        }
    }
</script>

<style scoped>

</style>
