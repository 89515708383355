<template>
    <div class="search-box">
        <i class="fa fa-search mr-2"></i>
        <input type="text" class="search-input" :placeholder="placeholder" autocomplete="off" v-model.trim="filterQuickSearch" v-on:keyup.enter="quickSearch()" @change="changeQuickSearch()"/>
        <div class="d-inline-flex" v-click-outside="closeDropDownSearch">
            <button type="button" class="border-none bg-transparent btn-icon" v-show="filterQuickSearch && filterQuickSearch.length > 0" @click="clearFilterSearch()"><i class="fa fa-times"></i></button>
            <button type="button" id="idDropdownBtnSearch" class="border-none bg-transparent btn-icon" @click="showDropDownSearch()" v-if="!promotionSearch">
            <i class="fas fa-caret-down" v-if="!this.notIcon" ></i>
            </button>
            <div id="idDropdownMenuSearch" class="dropdown-content" v-show="showDropDown">
                <div class="form-group p-0 mx-2 mb-1" v-show="discountBasSearch">
                    <input type="text" v-model.trim="filter.discount_bas_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.discount_bas_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="inventoryPlanSearch || planPurchaseSearch">
                    <input type="text" v-model.trim="filter.inventory_plan_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.inventory_plan_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="inventoryPlanSearch || planPurchaseSearch">
                    <input type="text" v-model.trim="filter.plan_purchase_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.plan_purchase_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="transporterSearch">
                    <input type="text" v-model.trim="filter.transporter_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.TransporterIndex.placeholder.search')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <!-- Start -->
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearch">
                    <input type="text" v-model.trim="filter.medicine_name" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.medicine.placeholder.search')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearch">
                    <input type="text" v-model.trim="filter.medicine_number" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.medicine.placeholder.number')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearch">
                    <input type="text" v-model.trim="filter.medicine_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.medicine.placeholder.code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearch">
                    <input type="text" v-model.trim="filter.medicine_content" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.medicine.placeholder.content')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearch">
                    <input type="text" v-model.trim="filter.medicine_country" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.medicine.placeholder.country')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <!-- end -->

                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearchProduct">
                    <input type="text" v-model.trim="filter.product_omipos" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.product_omipos')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="medicineSearchProduct">
                    <input type="text" v-model.trim="filter.product_medicine" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.product_medicine')"
                    v-on:keyup.enter="quickSearch()">
                </div>

                <div class="form-group p-0 mx-2 mb-1" v-show="cashBookSearch">
                    <input type="text" v-model.trim="filter.cash_book_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.CashBookIndex.placeholder.search')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="bankBookSearch">
                    <input type="text" v-model.trim="filter.bank_book_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.BankBookIndex.placeholder.search')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="requestRefundSearch">
                    <input type="text" v-model.trim="filter.request_refund_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.request_refund_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="settlementAdvanceSearch">
                    <input type="text" v-model.trim="filter.settlement_advance_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.settlement_advance_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="transferPositionSearch">
                    <input type="text" v-model.trim="filter.transfer_position_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.transfer_position_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="importPositionSearch">
                    <input type="text" v-model.trim="filter.import_position_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.import_position_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="exportPositionSearch">
                    <input type="text" v-model.trim="filter.export_position_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.export_position_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="importPositionSearch || exportPositionSearch">
                    <input type="text" v-model.trim="filter.original_documents_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.original_documents_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <!-- <div class="form-group p-0 mx-2 mb-1" v-show="importPositionSearch || exportPositionSearch">
                    <input type="text" v-model.trim="filter.original_documents_type" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.original_documents_type')"
                    v-on:keyup.enter="quickSearch()">
                </div> -->
                <div class="form-group p-0 mx-2 mb-1" v-show="couponsSearch || vouchersSearch">
                    <input type="text" v-model.trim="filter.coupon_voucher_code_name" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.coupon_voucher_code_name')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="couponsSearch">
                    <input type="text" v-model.trim="filter.coupon_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.coupon_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="vouchersSearch">
                    <input type="text" v-model.trim="filter.voucher_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.voucher_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="returnsSearch || requestRefundSearch">
                    <input type="text" v-model.trim="filter.returns_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="returnsSearch ? $t('message.InputQuickSearch.placeholder.returns_code_return_search') : (requestRefundSearch ? $t('message.InputQuickSearch.placeholder.returns_code_refund_search') : $t('message.InputQuickSearch.placeholder.returns_code_return_search'))"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="ordersSearch || invoicesSearch || historySearch">
                    <input type="text" v-model.trim="filter.order_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.order_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="transferSearch">
                    <input type="text" v-model.trim="filter.code_transfer" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.code_transfer')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="requestSearch">
                    <input type="text" v-model.trim="filter.code_request" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.code_request')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="transferSearch || requestSearch">
                    <input type="text" v-model.trim="filter.product_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.product_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="transferSearch || requestSearch">
                    <input type="text" v-model.trim="filter.product_name" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.product_name')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="customer || ordersSearch || invoicesSearch || returnsSearch || deliverySearch">
                    <input type="text" v-model.trim="filter.code_name_phone" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.code_name_phone')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="usersSearch">
                    <input type="text" v-model.trim="filter.username" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.username')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="usersSearch">
                    <input type="text" v-model.trim="filter.phone" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.phone')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="usersSearch || customer">
                    <input type="text" v-model.trim="filter.email" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.email')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="returnPurchaseOrderSearch || settlementAdvanceSearch">
                    <input type="text" v-model.trim="filter.return_purchase_order" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.return_purchase_order')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="purchaseOrderSearch || orderSupplierSearch || settlementAdvanceSearch">
                    <input type="text" v-model.trim="filter.order_supplier" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.order_supplier')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="purchaseOrderSearch || orderSupplierSearch || returnPurchaseOrderSearch">
                    <input type="text" v-model.trim="filter.purchase_order" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.purchase_order')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="purchaseOrderSearch || orderSupplierSearch || returnPurchaseOrderSearch || products || ordersSearch || invoicesSearch || returnsSearch || transferPositionSearch">
                    <input type="text" v-model.trim="filter.product_code_name" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.product_code_name')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="purchaseOrderSearch || orderSupplierSearch || returnPurchaseOrderSearch || discountBasSearch">
                    <input type="text" v-model.trim="filter.supplier_code_name" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.supplier_code_name')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="customer || ordersSearch || invoicesSearch || returnsSearch || requestRefundSearch || deliverySearch">
                    <input type="text" v-model.trim="filter.code_invoice" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.code_invoice')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="ordersSearch || invoicesSearch || returnsSearch || deliverySearch">
                    <input type="text" v-model.trim="filter.delivery_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.delivery_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="(showInput && (purchaseOrderSearch || orderSupplierSearch || returnPurchaseOrderSearch || ordersSearch || invoicesSearch)) || customer || transferSearch || requestSearch || returnsSearch || cashBookSearch || bankBookSearch">
                    <input type="text" v-model.trim="filter.note" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.note')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="deliverySearch">
                    <input type="text" v-model.trim="filter.note_delivery" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.note_delivery')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="(showInput && (purchaseOrderSearch || returnPurchaseOrderSearch || ordersSearch || invoicesSearch)) || returnsSearch">
                    <input type="text" v-model.trim="filter.note_product" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.note_product')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="products">
                    <input type="text" v-model.trim="filter.note_description" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.note_description')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="purchaseOrderSearch">
                    <input type="text" v-model.trim="filter.invoice_supplier_code" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.invoice_supplier_code')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group p-0 mx-2 mb-1" v-show="deliverySearch">
                    <input type="text" v-model.trim="filter.control_history" autocomplete="off" class="form-control pl-0 border-bottom input-quick-search" :placeholder="$t('message.InputQuickSearch.placeholder.control_history')"
                    v-on:keyup.enter="quickSearch()">
                </div>
                <div class="form-group window-footer pt-3 pb-0 mx-2 align-items-center flex-end">
                    <a class="show-more pr-3" v-show="!showInput && !products && !customer && !usersSearch && !transferSearch 
                    && !requestSearch && !returnsSearch && !couponsSearch && !vouchersSearch && !importPositionSearch 
                    && !exportPositionSearch && !transferPositionSearch && !requestRefundSearch && !settlementAdvanceSearch 
                    && !cashBookSearch && !bankBookSearch && !medicineSearch && !transporterSearch && !deliverySearch && !inventoryPlanSearch 
                    && !planPurchaseSearch && !historySearch && !discountBasSearch" @click="showMore()">
                        {{ $t('message.InputQuickSearch.button.extend') }} <i class="fa fa-arrow-down"></i>
                    </a> 

                    <a class="show-more pr-3" v-show="showInput && !products && !customer && !usersSearch && !transferSearch 
                    && !requestSearch && !returnsSearch && !couponsSearch && !vouchersSearch && !importPositionSearch 
                    && !exportPositionSearch && !transferPositionSearch && !requestRefundSearch && !settlementAdvanceSearch 
                    && !cashBookSearch && !bankBookSearch && !medicineSearch && !transporterSearch && !deliverySearch && !inventoryPlanSearch 
                    && !planPurchaseSearch && !historySearch && !discountBasSearch" @click="showMore()">
                        {{ $t('message.InputQuickSearch.button.collapse') }} <i class="fa fa-arrow-up"></i>
                    </a> 

                    <button type="button" class="btn btn-save" @click="quickSearch()"><i class="fa fa-search pr-2 text-white"></i>{{ $t('message.InputQuickSearch.button.search') }}</button></div>
            </div>
        </div>
    </div>
</template>

<script>
import {isChangeData, cloneObject} from "@/lib/utils";
import emitter from "@/lib/emitter";
    export default {
        name: "InputQuickSearch",
        props: ['placeholder', 'orderSupplierSearch', 'purchaseOrderSearch', 'returnPurchaseOrderSearch', 'medicineSearch', 'medicineSearchProduct',
         'usersSearch', 'products', 'customer', 'transferSearch', 'requestSearch',
          'ordersSearch', 'invoicesSearch', 'returnsSearch', 'couponsSearch', 'vouchersSearch', 'promotionSearch',
        'importPositionSearch', 'exportPositionSearch', 'transferPositionSearch', 'requestRefundSearch', 'settlementAdvanceSearch', 
        'cashBookSearch', 'bankBookSearch', 'transporterSearch', 'deliverySearch', 'notIcon', 'inventoryPlanSearch', 'planPurchaseSearch',
        'historySearch', 'discountBasSearch'
        ],
        data() {
            const field = {
                order_supplier: '',      
                purchase_order: '',      
                return_purchase_order: '',      
                product_code_name: '',      
                supplier_code_name: '',      
                note: '',
                note_product: '',
                username: '',
                phone: '',
                email: '',
                note_description: '',
                code_name_phone: '',
                code_invoice: '',
                code_transfer: '',
                code_request: '',
                product_name: '',
                product_code: '',
                order_code: '',
                delivery_code: '',
                returns_code: '',
                coupon_voucher_code_name: '',
                coupon_code: '',
                voucher_code: '',
                keyword: '',
                import_position_code: '',
                export_position_code: '',
                original_documents_code: '',
                original_documents_type: '',
                transfer_position_code: '',
                request_refund_code: '',
                settlement_advance_code: '',
                invoice_supplier_code: '',
                cash_book_code: '',
                bank_book_code: '',
                control_history: '',
                note_delivery: '',

                //medicine
                medicine_name: '',
                medicine_number: '',
                medicine_code: '',
                medicine_content: '',
                medicine_country: '',

                // 
                product_omipos: '',
                product_medicine: '',
                transporter_code: '',
                inventory_plan_code: '',
                plan_purchase_code: '',
                discount_bas_code: '',

            }
            return {
                model: [],
                showInput: false, 
                showDropDown: false,
                defaultFilter: cloneObject(field),
                filter: cloneObject(field),
                filterQuickSearch: [],
                optionFilter: [
                    {label: 'code_transfer', name: this.$t('message.InputQuickSearch.title.code_transfer'), value: ''},
                    {label: 'code_request', name: this.$t('message.InputQuickSearch.title.code_request'), value: ''},
                    {label: 'product_name', name: this.$t('message.InputQuickSearch.title.product_name'), value: ''},
                    {label: 'product_code', name: this.$t('message.InputQuickSearch.title.product_code'), value: ''},
                    {label: 'order_supplier', name: this.$t('message.InputQuickSearch.title.order_supplier'), value: ''},
                    {label: 'purchase_order', name: this.$t('message.InputQuickSearch.title.purchase_order'), value: ''},
                    {label: 'return_purchase_order', name: this.$t('message.InputQuickSearch.title.return_purchase_order'), value: ''},
                    {label: 'product_code_name', name: this.$t('message.InputQuickSearch.title.product_code_name'), value: ''},
                    {label: 'supplier_code_name', name: this.$t('message.InputQuickSearch.title.supplier_code_name'), value: ''},
                    {label: 'note', name: this.$t('message.InputQuickSearch.title.note'), value: ''},
                    {label: 'note_product', name: this.$t('message.InputQuickSearch.title.note_product'), value: ''},
                    {label: 'username', name: this.$t('message.InputQuickSearch.title.username'), value: ''},
                    {label: 'phone', name: this.$t('message.InputQuickSearch.title.phone'), value: ''},
                    {label: 'email', name: this.$t('message.InputQuickSearch.title.email'), value: ''},
                    {label: 'note_description', name: this.$t('message.InputQuickSearch.title.note_description'), value: ''},
                    {label: 'code_name_phone', name: this.$t('message.InputQuickSearch.title.code_name_phone'), value: ''},
                    {label: 'code_invoice', name: this.$t('message.InputQuickSearch.title.code_invoice'), value: ''},
                    {label: 'order_code', name: this.$t('message.InputQuickSearch.title.order_code'), value: ''},
                    {label: 'delivery_code', name: this.$t('message.InputQuickSearch.title.delivery_code'), value: ''},
                    {label: 'returns_code', name: this.$t('message.InputQuickSearch.title.returns_code'), value: ''},
                    {label: 'coupon_voucher_code_name', name: this.$t('message.InputQuickSearch.title.coupon_voucher_code_name'), value: ''},
                    {label: 'coupon_code', name: this.$t('message.InputQuickSearch.title.coupon_code'), value: ''},
                    {label: 'voucher_code', name: this.$t('message.InputQuickSearch.title.voucher_code'), value: ''},
                    {label: 'keyword', name: this.$t('message.InputQuickSearch.title.keyword'), value: ''},
                    {label: 'import_position_code', name: this.$t('message.InputQuickSearch.title.import_position_code'), value: ''},
                    {label: 'export_position_code', name: this.$t('message.InputQuickSearch.title.export_position_code'), value: ''},
                    {label: 'original_documents_code', name: this.$t('message.InputQuickSearch.title.original_documents_code'), value: ''},
                    {label: 'original_documents_type', name: this.$t('message.InputQuickSearch.title.original_documents_type'), value: ''},
                    {label: 'transfer_position_code', name: this.$t('message.InputQuickSearch.title.transfer_position_code'), value: ''},
                    {label: 'request_refund_code', name: this.$t('message.InputQuickSearch.title.request_refund_code'), value: ''},
                    {label: 'settlement_advance_code', name: this.$t('message.InputQuickSearch.title.settlement_advance_code'), value: ''},
                    {label: 'invoice_supplier_code', name: this.$t('message.InputQuickSearch.title.invoice_supplier_code'), value: ''},
                    {label: 'cash_book_code', name: this.$t('message.InputQuickSearch.title.cash_book_code'), value: ''},
                    {label: 'bank_book_code', name: this.$t('message.InputQuickSearch.title.bank_book_code'), value: ''},
                    {label: 'medicine_name', name: this.$t('message.InputQuickSearch.title.medicine_name'), value: ''},
                    {label: 'medicine_number', name: this.$t('message.InputQuickSearch.title.medicine_number'), value: ''},
                    {label: 'medicine_code', name: this.$t('message.InputQuickSearch.title.medicine_code'), value: ''},
                    {label: 'medicine_content', name: this.$t('message.InputQuickSearch.title.medicine_content'), value: ''},
                    {label: 'medicine_country', name: this.$t('message.InputQuickSearch.title.medicine_country'), value: ''},

                    {label: 'product_omipos', name: this.$t('message.InputQuickSearch.title.product_omipos'), value: ''},
                    {label: 'product_medicine', name: this.$t('message.InputQuickSearch.title.product_medicine'), value: ''},
                    {label: 'transporter_code', name: this.$t('message.InputQuickSearch.title.transporter_code'), value: ''},
                    {label: 'control_history', name: this.$t('message.InputQuickSearch.title.control_history'), value: ''},
                    {label: 'note_delivery', name: this.$t('message.InputQuickSearch.title.note_delivery'), value: ''},
                    {label: 'inventory_plan_code', name: this.$t('message.InputQuickSearch.title.inventory_plan_code'), value: ''},
                    {label: 'plan_purchase_code', name: this.$t('message.InputQuickSearch.title.plan_purchase_code'), value: ''},
                    {label: 'discount_bas_code', name: this.$t('message.InputQuickSearch.title.discount_bas_code'), value: ''},
                ],
            }
        },
        watch: {
        },
        methods: {
            closeDropDownSearch(){
                this.showDropDown = false;
            },
            showDropDownSearch(){
                this.showDropDown = !this.showDropDown;
            },
            showMore(){
                this.showInput = !this.showInput;
            },
            quickSearch(){
                this.model = [];
                this.filterQuickSearch = [];
                Object.keys(this.filter).forEach(key => {
                    this.model.push({[key]:this.filter[key].trim()});
                    if(this.filter[key] != ''){
                        this.optionFilter.forEach(o => {
                            if(key == o.label){
                                let str = ' ' + o.name + ': ' + this.filter[key].trim();
                                this.filterQuickSearch.push(str);
                            }
                        })
                    }
                });
                this.$emit('filterSearch', this.model);
                this.closeDropDownSearch();
            },
            changeQuickSearch(){
                if(this.filterQuickSearch?.length <= 0){
                    this.filter = {...this.defaultFilter};
                }else{
                    const isChange = isChangeData(this.filter, this.defaultFilter);
                    if(isChange == true && typeof this.filterQuickSearch == 'string'){
                        if(this.purchaseOrderSearch){
                            this.filter.purchase_order = this.filterQuickSearch;
                        }else if(this.orderSupplierSearch){
                            this.filter.order_supplier = this.filterQuickSearch;
                        }else if(this.returnPurchaseOrderSearch){
                            this.filter.return_purchase_order = this.filterQuickSearch;
                        }else if(this.usersSearch){
                            this.filter.username = this.filterQuickSearch;
                        }else if(this.products){
                            this.filter.product_code_name = this.filterQuickSearch;
                        }else if(this.customer){
                            this.filter.code_name_phone = this.filterQuickSearch;
                        }else if(this.transferSearch){
                            this.filter.code_transfer = this.filterQuickSearch;
                        }else if(this.requestSearch){
                            this.filter.code_request = this.filterQuickSearch;
                        }else if(this.ordersSearch){
                            this.filter.order_code = this.filterQuickSearch;
                        }else if(this.invoicesSearch){
                            this.filter.code_invoice = this.filterQuickSearch;
                        }else if(this.returnsSearch){
                            this.filter.returns_code = this.filterQuickSearch;
                        }else if(this.couponsSearch || this.vouchersSearch){
                            this.filter.coupon_voucher_code_name = this.filterQuickSearch;
                        }else if(this.promotionSearch){
                            this.filter.keyword = this.filterQuickSearch;
                        }else if(this.importPositionSearch){
                            this.filter.import_position_code = this.filterQuickSearch;
                        }else if(this.exportPositionSearch){
                            this.filter.export_position_code = this.filterQuickSearch;
                        }else if(this.transferPositionSearch){
                            this.filter.transfer_position_code = this.filterQuickSearch;
                        }else if(this.requestRefundSearch){
                            this.filter.code_invoice = this.filterQuickSearch;
                        }else if(this.settlementAdvanceSearch){
                            this.filter.settlement_advance_code = this.filterQuickSearch;
                        }else if(this.cashBookSearch){
                            this.filter.cash_book_code = this.filterQuickSearch;
                        }else if(this.bankBookSearch){
                            this.filter.bank_book_code = this.filterQuickSearch;
                        }else if(this.medicineSearch){
                            this.filter.medicine_name = this.filterQuickSearch;
                        }else if(this.medicineSearchProduct){
                            this.filter.product_omipos = this.filterQuickSearch;
                        }else if(this.transporterSearch){
                            this.filter.transporter_code = this.filterQuickSearch;
                        }else if(this.deliverySearch){
                            this.filter.delivery_code = this.filterQuickSearch;
                        }else if(this.inventoryPlanSearch){
                            this.filter.inventory_plan_code = this.filterQuickSearch;
                        }else if(this.planPurchaseSearch){
                            this.filter.plan_purchase_code = this.filterQuickSearch;
                        }else if(this.historySearch){
                            this.filter.keyword = this.filterQuickSearch;
                        }else if(this.discountBasSearch){
                            this.filter.discount_bas_code = this.filterQuickSearch;
                        }else{
                            this.filter.keyword = this.filterQuickSearch;
                        }
                    }else{
                        var obj={};
                        var KeyVal = this.filterQuickSearch.split(",");
                        var i;
                        for (i in KeyVal) {
                            KeyVal[i] = KeyVal[i].split(":");
                            if(KeyVal[i][0] && KeyVal[i][1]){
                                obj[KeyVal[i][0].trim()]=KeyVal[i][1].trim();
                            }
                        }
                        this.setValueOptionFilter(obj);
                        this.setValueFilter();
                    }
                }
            },
            setValueOptionFilter(object){
                this.optionFilter.forEach(o => {
                    Object.keys(object).forEach(key => {
                        if (o.name == key) {
                            o.value = object[key];
                        }
                    });
                })
            },
            setValueFilter(){
                Object.keys(this.filter).forEach(key => {
                    this.optionFilter.forEach(o => {
                        if(key == o.label){
                            this.filter[key] = o.value;
                        }
                    })
                });
            },
            clearFilterSearch(){
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                this.filterQuickSearch = [];
                this.showDropDown = false;
                this.showInput = false;
                this.quickSearch();
            }
        },
        created(){
            emitter.on("clearQuickSearch", () => {
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                this.filterQuickSearch = [];
                this.showDropDown = false;
                this.showInput = false;
            });
        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .btn-icon:focus{
        outline: none;
        border: none;
        border-radius: 50%;
        background: #F2F2F2 !important;
    }
    .dropdown-content{
        z-index: 999;
        margin: 0.5rem 0 0;
        width: 95%;
        background-color: #fff;
        padding: 0.5rem;
        border: none;
        background-color: #fff;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        -webkit-box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        -moz-box-shadow: 0 5px 25px rgba(0,0,0,.25);
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        transform: translate3d(15px, 28px, 0px) !important;

        position: absolute;
        left: 0;
        top: 0;
        float: left;
        min-width: 10rem;
        color: #212529;
        text-align: left;
        list-style: none;
        background-clip: padding-box;
    }
    .input-quick-search:focus{
        border-bottom: 1px solid $base-color !important;
        border-radius: 0;
    }
</style>
