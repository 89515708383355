<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content px-4">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">
                        {{ $t('message.UnitIndex.modal.title_remove')}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-5">
                            <h6>
                                {{ $t('message.UnitIndex.modal.content_remove')}}
                            </h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click="deleteData()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.UnitIndex.modal.button.confirm')}}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.UnitIndex.modal.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';
import Paginate from "@/components/Paginate";
export default {
    name: "ModalUnitRemove",
    components: {Paginate},
    data(){

        return {
            id: [],
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(id) {
            if(id != null){
                this.id = id;
            }
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        async deleteData(){
            const res = await $post('/v1/units/remove', { id: this.id});
            if(res.code == 200){
                toastr.success(res.message);
                this.$emit('inputData','1');
                window.jQuery(this.$refs.modalConfirm).modal('hide');
            }
          if(res.code == 3){
            toastr.error(res.message);
            this.$emit('inputData','1');
            window.jQuery(this.$refs.modalConfirm).modal('hide');
          }
        },
        clearData(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

