<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header border-0 py-2">
                    <h6 class="modal-title">
                        <a class="pr-3 text-dark" href="#" @click="showTab(this.$constant.TabEditPdfTemplate)">
                            <span v-if="!this.typeUpdate">{{ $t("message.SendMailZaloIndex.modal.title_create") + ': ' + this.currentTabName }}</span>
                            <span v-else>{{ $t("message.SendMailZaloIndex.modal.title_update") + ': ' + this.pdfTemplateNameOrigin }}</span>
                        </a>
                        <a href="#" @click="showTab(this.$constant.TabShowKeyPdfTemplate)"><i class="fa fa-info-circle fa-xs text-dark"></i></a>
                    </h6>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0">
                    <!-- <ModalEditPdf v-if="this.type === this.$constant.TabEditPdfTemplate" ref="ModalEditPdf" 
                        :action="this.action"
                        :pdfTemplateId="this.pdfTemplateId" 
                        :pdfTemplateName="this.pdfTemplateName" 
                        :pdfTemplate="this.pdfTemplate"
                        :content="this.content" 
                        :file="this.file" 
                        :dataValue="this.dataValue" 
                        :printTemplates="this.printTemplates"
                        @updateContent="updateContent" @updateFileId="updateFileId" @updateTemplateName="updateTemplateName"/> -->
                    <ModalEditPdf v-if="this.type === this.$constant.TabEditPdfTemplate" ref="ModalEditPdf" 
                        @updateContent="updateContent" @updateFileId="updateFileId" @updateTemplateName="updateTemplateName"/>
                    <ModalEditPdfInformation v-if="this.type === this.$constant.TabShowKeyPdfTemplate" ref="ModalEditPdfInformation" :entries="this.dataValue" />
                </div>
                <div class="modal-footer border-0" v-if="this.type == this.$constant.TabEditPdfTemplate">
                    <button type="button" class="btn btn-primary px-2" @click="save()">
                        <i class="fa fa-check mr-2"></i>
                        {{ $t("message.SendMailZaloIndex.modal.button.save") }}
                    </button>
                    <button type="button" class="btn btn-secondary" @click="hide">
                        <i class="fa fa-ban mr-1"></i>
                        {{ $t("message.SendMailZaloIndex.modal.button.skip") }}
                    </button>
                    <button v-if="this.typeUpdate" type="button" class="btn btn-danger"
                        @click="remove()">
                        <i class="fa fa-trash-alt mr-1"></i>
                        {{ $t("message.SendMailZaloIndex.modal.button.remove") }}
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ModalRemovePdfTemplate ref="ModalRemovePdfTemplate" @loadData="loadData" />
</template>

<script>
import ModalEditPdf from "./ModalEditPdf";
import ModalEditPdfInformation from "./ModalEditPdfInformation";
import ModalRemovePdfTemplate from './ModalRemovePdfTemplate';
import { $alert, $get, $post } from "@/lib/utils";
import { markRaw } from "vue";
import toastr from "toastr";
import AppPermission from '@/components/AppPermission.vue';
import emitter from '@/lib/emitter';
import { mapGetters } from "vuex";
import storeModul from "@/storeModul";
export default {
    components: {
        ModalEditPdf,
        ModalEditPdfInformation, 
        ModalRemovePdfTemplate, 
        AppPermission
    },
    mounted() {},
    props: [],
    name: "ModalEditPdfTemplate",
    data() {
        return {
            type: this.$constant.TabEditPdfTemplate,
            entry: {},
            data: {},
            typeUpdate: false,
            templateName: this.pdfTemplateName,
            isChangeName: false,
            newContent: this.content,
            newFileId: this.file,
        };
    },
    methods: {
        showTab(type = null) {
            this.type = this.$constant.TabEditPdfTemplate;
            if(type){
                switch (type) {
                    case this.$constant.TabEditPdfTemplate:
                        this.type = this.$constant.TabEditPdfTemplate;
                        break;
                    case this.$constant.TabShowKeyPdfTemplate:
                        this.type = this.$constant.TabShowKeyPdfTemplate;
                        break;
                }
            }
        },
        async show(typeUpdate) {
            await this.showTab();
            this.typeUpdate = typeUpdate;
            this.$refs.ModalEditPdf.renderData();
            window.jQuery(this.$refs.modal).modal("show");
        },
        hide() {
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloContent', this.contentOriginal);
            storeModul.dispatch('sendMailTemplateStore/setPdfFileId', this.fileOrigin);
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplateName', this.pdfTemplateNameOrigin);
            window.jQuery(this.$refs.modal).modal("hide");
        },
        updateTemplateName(value){
            this.templateName = value;
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplateName', value);
        },
        updateContent(value){
            this.newContent = value;
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloContent', value);
        },
        updateFileId(value){
            this.newFileId = value;
            storeModul.dispatch('sendMailTemplateStore/setPdfFileId', value);
        },
        async save(){
            const params = {
                id : this.pdfTemplateId,
                content : this.newContent,
                fileId : this.newFileId,
                name : this.templateName,
                code: this.action,
                update: this.typeUpdate
            }
            let response;
            if(this.typeUpdate){
                response = await $post('/v1/pdf-templates/update-send-mail-template', {...params});
            }else{
                response = await $post('/v1/pdf-templates/create-send-mail-template', {...params});
            }
            if(response.code === 422){
                let errors = response.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: response.code, message: errors[key][0]});
                });
                return;
            }
            if(response && response.result){
                $alert({code: 200, message: this.typeUpdate ? this.$t('message.SendMailZaloIndex.modal.alerts.success_update') : this.$t('message.SendMailZaloIndex.modal.alerts.success_create')});
                this.loadData(this.pdfTemplateId);
            }else{
                $alert({code: 500, message: this.typeUpdate ? this.$t('message.SendMailZaloIndex.modal.alerts.error_update') : this.$t('message.SendMailZaloIndex.modal.alerts.error_create')});
            }
        },
        remove() {
            this.$refs.ModalRemovePdfTemplate.show(this.pdfTemplateId);
        },
        loadData(id = null){
            emitter.emit("loadDataPdfTemplateContent", id);
            window.jQuery(this.$refs.modal).modal("hide");
        }
    },
    watch: {},
    beforeUnmount() {
        // this.hide();
    },
    computed: {
        ...mapGetters({
            content: 'sendMailTemplateStore/sendMailZaloContent',
            contentOriginal: 'sendMailTemplateStore/sendMailZaloContentOriginal',
            pdfTemplate: 'sendMailTemplateStore/sendMailZaloTemplate',
            pdfTemplateId: 'sendMailTemplateStore/sendMailZaloTemplateId',
            action: 'sendMailTemplateStore/action',
            file: 'sendMailTemplateStore/pdfFileId',
            fileOrigin: 'sendMailTemplateStore/pdfFileIdOrigin',
            dataValue: 'sendMailTemplateStore/dataValueKeys',
            printTemplates: 'sendMailTemplateStore/printTemplates',
            pdfTemplateName: 'sendMailTemplateStore/sendMailZaloTemplateName',
            pdfTemplateNameOrigin: 'sendMailTemplateStore/sendMailZaloTemplateNameOrigin',
            currentTabName: 'sendMailTemplateStore/currentTabName',
        }),
    },
};
</script>

<style lang="css" scoped>
a {
    text-decoration: none;
}
</style>
