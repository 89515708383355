<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
           <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
               <div class="modal-content">
                   <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                       <div class="d-flex">
                           <h5 style="fotn" class="modal-title py-2" > {{ this.isEdit ? this.$t('message.branchIndex.modal.title_update') : this.$t('message.branchIndex.modal.title_create')}}</h5>
                       </div>
                       <button type="button" class="close" @click="closeModal">
                           <span aria-hidden="true">&times;</span>
                       </button>
                   </div>
                   <div class="modal-body py-0 mx-4 my-4 px-0">
                      <TabInfo ref="tab" ></TabInfo>
                   </div>
                   <div class="modal-footer">
                     
                       <button class="btn btn-primary" @click="update(this.entry.id)" :disabled="requestData" v-if="this.isEdit">
                           <i class="fa fa-save"></i>
                           {{ $t('message.branchIndex.modal.button.save') }}
                       </button>
                       <button class="btn btn-primary"  @click="save" :disabled="requestData" v-else>
                           <i class="fa fa-save"></i>
                           {{ $t('message.branchIndex.modal.button.save') }}
                       </button>
                       <button type="button" class="btn btn-secondary text-white  mr-2"
                         @click="closeModal">
                         <i class="fa fa-ban mr-1"></i>
                         {{ $t('message.branchIndex.modal.button.skip') }}
                       </button>
                   </div>
               </div>
           </div>
       </div>
       <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
   </template>
   <script>
   import TabInfo from "@/views/branches/tabs/TabInfo";
   
   import emitter from "@/lib/emitter";
   import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
   import store from "@/store";
   import ModalConfirm from "@/components/ModalConfirm.vue";
   import toastr from 'toastr';
   
   export default {
      name: "ModalAddInfoSuppliers",
      components: { TabInfo, ModalConfirm},
      props: {
          openModal: {
              type: Boolean,
              default: false,
          },
      },
      methods: {
        clearInput(){
          this.entry = {
              'code': '',
              'name': '',
              'contactNumber': '',
              'address': '',
              'provinceId': null,
              'districtId': null,
              'wardId': null,
              'email': '',
              'company': '',
              'taxCode': '',
              'supplierGroupId': null,
              'note': '',
            };
            this.$refs.tab.dayOfWorks = [];
          emitter.emit('removeSupplierData');
        },

        isMessageError(errMss){ 
          if(errMss['entry.name']) {
            toastr.error(errMss['entry.name']);                
            return;
          }
          if(errMss['entry.phone']) {
            toastr.error(errMss['entry.phone']);                
            return;
          }
          if(errMss['entry.email']) {
            toastr.error(errMss['entry.email']);                
            return;
          }
          if(errMss['entry.address']) {
            toastr.error(errMss['entry.address']);
            return;
          }
          if(errMss['entry.provinceId']) {
            toastr.error(errMss['entry.provinceId']);
            return;
          }
          if(errMss['entry.districtId']){
            toastr.error(errMss['entry.districtId']);
            return;
          }
          if(errMss['entry.wardId']){
            toastr.error(errMss['entry.wardId']);
            return;
          }
        },
        async save() {
          this.requestData = true;
          const tab = this.$refs.tab
          let param={
            entry: tab.entry,
            currentBranchID:tab.currentBranchID,
          }
          const res = await $post('/v1/branches/store', param);
          if (res.code == 422) {
            this.requestData = false;
            scrollToElement('.error-label');
            this.isMessageError(res.messages.errors);
          }
          
          if(res.status.code == 200){
            toastr.success(this.$t('message.branchIndex.modal.alerts.success_create'));
            this.$emit('updated', '')
            this.hide();
          }
        },
        async update (id) {
          const tab = this.$refs.tab
          let param={
              entry: tab.entry,
              currentBranchID:tab.currentBranchID,
          }
          const result = await $post(`/v1/branches/update/${id}`, param);
          if (result.code == 422) {
              this.requestData = false;
              scrollToElement('.error-label');
              this.isMessageError(result.messages.errors);
          }
          if (result.status.code == 200) {
              toastr.success(this.$t('message.branchIndex.modal.alerts.success_update'));
              this.$emit('updated', '')
              this.hide();
          }
        },
        async show(id) {
          if (id){
          this.isEdit = true;
          this.id = id;
          await this.load(id);
          }else {
          this.id = null;
          this.isEdit=false;
          }
          await this.loadDataLocation();
          this.default = {...this.entry};
          this.$refs.tab.entry = this.entry;
          // this.$refs.tab.supplierGroups = this.supplierGroups;
          // emitter.emit('loadDataSupplier');
          window.jQuery(this.$refs.modal).modal('show');
        },
        hide(){
          this.requestData = false;
          this.$emit('inputData','1');
          this.clearInput();
          window.jQuery(this.$refs.modal).modal('hide');
        },
        async load(id) {
          const res = await $get(`/v1/branches/show/${id}`);
          if (res.code === 404) {
              location.replace('/404');
              return;
          }
          this.entry = res.result;
          if (res.result.work_days) {
            res.result.work_days.forEach(element => {
              this.$refs.tab.dayOfWorks.push(element.local_key_day);
              this.entry.work_days = this.$refs.tab.dayOfWorks;
            });
          }           
        },
        async loadDataLocation(){
            const res = await $get('/v1/locations/provinces', {
                provinceId: this.entry.provinceId,
                districtId: this.entry.districtId
            });
            this.$refs.tab.provinces = res.data.provinces;
            this.$refs.tab.districts = res.data.districts;
            this.$refs.tab.wards = res.data.wards;
        },
        closeModal(){
          const tab = this.$refs.tab;
          const isChange = isChangeData(tab.entry, this.default);
          if(isChange == true){
              this.hide();
          }else{
              this.titleConfirm = this.$t('message.branchIndex.modal.title_confirm');
              if(this.id === null){
                this.contentConfirm = this.$t('message.branchIndex.modal.alerts.confirm_skip_create');
              }else{
                this.contentConfirm = this.$t('message.branchIndex.modal.alerts.confirm_skip_update');
              }
              this.$refs.ModalConfirm.show();
          }
        },
      },
      data () {
          return {
            isEdit:false,
            id: null,
            entry:{
              name: '',
              phone: '',
              email: '',
              address: '',
              provinceId: '',
              districtId: '',
              wardId: '',
              work_days: []
            },
            supplierGroups: [],
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            requestData: false,
          }
      }
   }
   </script>
   <style>
   .action-bar {
       display: none !important;
   }
   </style>