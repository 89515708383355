<template>
    <div class="position-relative">
        <div class="select-option dropdown-toggle" id="dropdownMenuButton1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            <span>{{$t(this.sortTime) ?? this.sortTime}}</span>
        </div>
        <div class="dropdown-menu sortTimeList pb-4" aria-labelledby="dropdownMenuButton3">
            <div class="row p-0 m-0">
                <div class="col-2 p-0 text-bold">{{ $t('message.DateRangePicker.byDay') }}</div>
                <div class="col-2 p-0 text-bold">{{ $t('message.DateRangePicker.byWeek') }}</div>
                <div class="col-3 p-0 text-bold">{{ $t('message.DateRangePicker.byMonth') }}</div>
                <div class="col-2 p-0 text-bold">{{ $t('message.DateRangePicker.byQuarter') }}</div>
                <div class="col-3 p-0 text-bold">{{ $t('message.DateRangePicker.byYear') }}</div>
            </div>
            <div class="row p-0 m-0 mt-2">
                <div class="col-2 p-0">
                    <div class="row">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('today')">{{ $t('message.DateRangePicker.today') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('yesterday')">{{ $t('message.DateRangePicker.yesterday') }}</a></div>
                    </div>
                </div>
                <div class="col-2 p-0">
                    <div class="row">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('thisweek')">{{ $t('message.DateRangePicker.thisWeek') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastweek')">{{ $t('message.DateRangePicker.lastWeek') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('2day')">{{ $t('message.DateRangePicker.twoDay') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('3day')">{{ $t('message.DateRangePicker.threeDay') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('7day')">{{ $t('message.DateRangePicker.sevenDay') }}</a></div>
                    </div>
                </div>
                <div class="col-3 p-0">
                    <div class="row">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('month')">{{ $t('message.DateRangePicker.month') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastmonth')">{{ $t('message.DateRangePicker.lastMonth') }}</a></div>
                    </div>
                    <!-- <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('monthlunar')">Tháng này (âm lịch)</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastmonthlunar')">Tháng trước (âm lịch)</a></div>
                    </div> -->
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('30day')">{{ $t('message.DateRangePicker.thirtyDay') }}</a></div>
                    </div>
                </div>
                <div class="col-2 p-0">
                    <div class="row">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('quarter')">{{ $t('message.DateRangePicker.quarter') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastquarter')">{{ $t('message.DateRangePicker.lastQuarter') }}</a></div>
                    </div>
                </div>
                <div class="col-3 p-0">
                    <div class="row">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('year')">{{ $t('message.DateRangePicker.year') }}</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastyear')">{{ $t('message.DateRangePicker.lastYear') }}</a></div>
                    </div>
                    <!-- <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('yearlunar')">Năm này (âm lịch)</a></div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12"><a href="javascript:void(0);" @click="filterByRange('lastyearlunar')">Năm trước (âm lịch)</a></div>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {DATE_LOCALES} from "@/lib/locale";
import moment from 'moment';
import 'moment-lunar';
import emitter from "@/lib/emitter";
export default {
    name: "DateRangePicker",
    props: ['formatType'],
    data(){
        const format = this.formatType;
        return{
            optionFilterByRange: [
                {id: 0, label: 'message.DateRangePicker.today', keyword: 'today', value: moment().locale('vi').format(format)},
                {id: 1, label: 'message.DateRangePicker.yesterday', keyword: 'yesterday', value: moment().locale('vi').subtract(1, 'days').format(format)},
                {id: 2, label: 'message.DateRangePicker.thisWeek', keyword: 'thisweek', value: moment().locale('vi').startOf('week').format(format) + '_' + moment().locale('vi').endOf('week').format(format)},
                {id: 3, label: 'message.DateRangePicker.lastWeek', keyword: 'lastweek', value: moment().locale('vi').subtract(1, 'weeks').startOf('week').format(format) + '_' + moment().locale('vi').subtract(1, 'weeks').endOf('week').format(format)},
                {id: 3, label: 'message.DateRangePicker.twoDay', keyword: '2day', value: moment().locale('vi').subtract(1, 'days').format(format) + '_' + moment().locale('vi').format(format)},
                {id: 3, label: 'message.DateRangePicker.threeDay', keyword: '3day', value: moment().locale('vi').subtract(2, 'days').format(format) + '_' + moment().locale('vi').format(format)},
                {id: 3, label: 'message.DateRangePicker.sevenDay', keyword: '7day', value: moment().locale('vi').subtract(6, 'days').format(format) + '_' + moment().locale('vi').format(format)},
                {id: 4, label: 'message.DateRangePicker.month', keyword: 'month', value: moment().locale('vi').startOf('month').format(format) + '_' + moment().locale('vi').endOf('month').format(format)},
                {id: 5, label: 'message.DateRangePicker.lastMonth', keyword: 'lastmonth', value: moment().locale('vi').subtract(1, 'months').startOf('month').format(format) + '_' + moment().locale('vi').subtract(1, 'months').endOf('month').format(format)},
                // {id: 6, label: 'Tháng này (âm lịch)', keyword: 'monthlunar', value: moment(moment().lunar().format(format)).locale('vi').startOf("month").solar().format(format) +"_" +moment(moment().lunar().format(format)).locale('vi').endOf("month").solar().format(format)},
                // {id: 7, label: 'Tháng trước (âm lịch)', keyword: 'lastmonthlunar', value: moment(moment().lunar().format(format)).locale('vi').subtract(1, "months").startOf("month").solar().format(format) +"_" +moment(moment().lunar().format(format)).locale('vi').subtract(1, "months").endOf("month").solar().format(format)},
                {id: 8, label: 'message.DateRangePicker.thirtyDay', keyword: '30day', value: moment().locale('vi').subtract(29, 'days').format(format) + '_' + moment().locale('vi').format(format)},
                {id: 9, label: 'message.DateRangePicker.quarter', keyword: 'quarter', value: moment().locale('vi').startOf('quarter').format(format) + '_' + moment().locale('vi').endOf('quarter').format(format)},
                {id: 10, label: 'message.DateRangePicker.lastQuarter', keyword: 'lastquarter', value: moment().locale('vi').subtract(1, 'quarters').startOf('quarter').format(format) + '_' + moment().locale('vi').subtract(1, 'quarters').endOf('quarter').format(format)},
                {id: 11, label: 'message.DateRangePicker.year', keyword: 'year', value: moment().locale('vi').startOf('year').format(format) + '_' + moment().locale('vi').endOf('year').format(format)},
                {id: 12, label: 'message.DateRangePicker.lastYear', keyword: 'lastyear', value: moment().locale('vi').subtract(1, 'years').startOf('year').format(format) + '_' + moment().locale('vi').subtract(1, 'years').endOf('year').format(format)},
                // {id: 13, label: 'Năm nay (âm lịch)', keyword: 'yearlunar', value: moment(moment().lunar().format(format)).locale('vi').startOf("year").solar().format(format) +"_" +moment(moment().lunar().format(format)).locale('vi').endOf("year").solar().format(format)},
                // {id: 14, label: 'Năm trước (âm lịch)', keyword: 'lastyearlunar', value: moment(moment().lunar().format(format)).locale('vi').subtract(1, "years").startOf("year").solar().format(format) +"_" +moment(moment().lunar().format(format)).locale('vi').subtract(1, "years").endOf("year").solar().format(format)},
                {id: 15, label: 'message.DateRangePicker.all', keyword: 'all', value: null},
            ],
            sortTime: null,
            sortTimeValue: null,
        }
    },
    methods: {
        filterByRange(keyword){
            this.optionFilterByRange.forEach(o => {
                if(o.keyword == keyword){
                    this.sortTime = o.label;
                    this.sortTimeValue = o.value;
                }
            })
            this.$emit('picker', this.sortTimeValue);
        },
        clearData(){
            this.sortTime = 'message.DateRangePicker.all';
            this.sortTimeValue = null;
        }
    },
    mounted(){
        // if(this.sortTime == null){
        //     this.filterByRange('all');
        // }
    },
    created(){
        emitter.on("clearDateRangePicker", () => {
            this.filterByRange('all');
        });
        emitter.on("clearDataDateRangePicker", () => {
            this.clearData();
        });
        emitter.on("setValueDefaultDateRange", (value) => {
            this.filterByRange(value);
        });
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
a{
    color: $base-color !important;
}
.dropdown-toggle::after {
    position: absolute;
    right: 5px;
    top: 12px;
}
.sortTimeList{
    width: 620px;
    padding: 1rem 1rem 0.5rem;
    z-index: 999999;
    background: #fff;
    position: absolute;
    top: -90px !important;
    left: 115% !important;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
    -moz-box-shadow: 0 5px 15px rgba(0,0,0,.25);
    box-shadow: 0 5px 15px rgb(0 0 0 / 25%);
    transform: none !important;
}
.sortTimeList:after {
    content: "";
    border-bottom: 8px solid transparent;
    border-top: 8px solid transparent;
    border-right: 8px solid #fff;
    position: absolute;
    bottom: 90px;
    left: -8px;
}
</style>
