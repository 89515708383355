import {deepFreeze} from "@/lib/deepfreeze";

const PurchaseOrderStatus = {
    DRAFT: 1,
    IMPORTED: 3,
    CANCELLED: 4,
    RETURNTED: 5,
}

const PurchaseOrderType = {
    IMPORT: 'import',
    RETURN: 'return'
}

deepFreeze(PurchaseOrderStatus);
deepFreeze(PurchaseOrderType);

export {
    PurchaseOrderStatus, PurchaseOrderType
};
