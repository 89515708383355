<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    <h5 class="modal-title py-2">
                        {{ $t("message.SendMailZaloIndex.modal.title_remove") }}
                    </h5>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2">
                    {{ $t("message.SendMailZaloIndex.modal.remove_content") }}
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <button class="btn btn-danger mr-2" @click="remove()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t("message.SendMailZaloIndex.modal.button.confirm") }}
                        </button>
                        <button class="btn btn-secondary text-white" @click="hide">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t("message.SendMailZaloIndex.modal.button.skip") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $alert, $post } from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemovePdfTemplate',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id) {
            this.id = id;
            window.jQuery(this.$refs.modal).modal('show');
        },
        async remove() {
            const response = await $post('/v1/pdf-templates/remove-send-mail-template', {
                id: this.id
            }
            );
            if(response && response.result && response.result.status){
                $alert({code: 200, message: this.$t('message.SendMailZaloIndex.modal.alerts.success_remove')});
                this.$emit('loadData');
            }else{
                let message = response.result && response.result.message ? response.result.message : this.$t('message.SendMailZaloIndex.modal.alerts.error_remove');
                $alert({code: 500, message: message});
            }
            this.hide();
        },
        hide(){
            window.jQuery(this.$refs.modal).modal('hide');
        }
    }
}
</script>
<style></style>