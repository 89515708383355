<template>
    <div class="customer-tab tab-info">
        <template v-if="!isModal">
            <ActionBar back-url="/customers/index" @action="save()"/>
            <div class="title">
                <!-- Thông tin khách hàng: {{ entry.name }} -->
            </div>
        </template>

        <div class="content">
            <div class="row">
                <div class="col-2 ">
                    <QImage :src="entry.avatar && entry.avatar.url ? entry.avatar.url : '/img/default-product.593ae091.png'" style="width: 100%" class="py-2"/>                    
                    <InputFileManager :multiple="false" v-model="entry.avatar"></InputFileManager>
                </div>
                <div class="col-5">
                    <!-- Start mã khách hàng -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.code')}}</b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.code" :errors="errors.code" />
                        </div>
                    </div>
                    <!-- End mã khách hàng -->
                    <!-- Start name -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.common.fullname')}}<label class="text-danger px-0">*</label> </b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.name" :errors="errors.name" />
                        </div>
                    </div>
                    <!-- End name -->
                    <!-- Start birthday and gender -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.birthDay')}}</b>
                        </div>
                        <div class="col-5">
                            <InputDatePicker v-model="entry.birthDate" :errors="errors.birthDate"/>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" v-model="entry.gender" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                                <label class="form-check-label" for="exampleRadios1">
                                    {{$t('message.common.male')}}
                                </label>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="form-check">
                                <input class="form-check-input" v-model="entry.gender" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                <label class="form-check-label" for="exampleRadios2">
                                    {{$t('message.common.female')}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <!-- End birthday and gender -->
                    <!-- Start phone number -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.phone')}}</b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.contactNumber" :errors="errors.contactNumber" />
                        </div>
                    </div>
                    <!-- End phone number -->
                    <!-- <div class="row">
                        <div class="col-3">
                            <b>Trạng thái hoạt động</b>
                        </div>
                        <div class="col-md-9">
                            <InputSwitchButton v-model="entry.status" :errors="errors.status" />
                        </div>
                    </div> -->
                    <!-- End status -->
                    <!-- Start nhóm khách hàng -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.customerGroup')}}</b>
                        </div>
                        <div class="col-md-9 form-group">
                            <select class="form-control" v-model="entry.tagId">
                                <option value="">{{$t('message.customerIndex.modal.chooseCustomerGroup')}}</option>
                                <option v-for="i in customerGroup" :value="i.id" :key="i.id" v-text="i.name"></option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.common.addressDetail')}}</b>
                        </div>
                        <div class="col-9">
                            <InputTextArea v-model="address.addressValue" />
                        </div>
                    </div>
                    <InputLocationPicker v-model="location"></InputLocationPicker>
                </div>
                <div class="col-5">
                    <!-- Start loại khách -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.type')}}</b>
                        </div>
                        <div class="col-9">
                            <div class="form-group row">
                                <div class="form-check mr-3">
                                    <input class="form-check-input" type="radio" name="chooseTypeAddress" :value="2"
                                        v-model="address.typeAddress" id="cong-ty">
                                    <label class="form-check-label" for="cong-ty">
                                        {{$t('message.customerIndex.company')}}
                                    </label>
                                </div>
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="chooseTypeAddress" :value="3"
                                        v-model="address.typeAddress" id="nha-rieng">
                                    <label class="form-check-label" for="nha-rieng">
                                        {{$t('message.common.home')}}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- End loại khách -->
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.taxCode')}}</b>
                        </div>
                        <div class="col-9">
                            <InputText placeholder="" />
                        </div>
                    </div>
                     <!-- Start email -->
                     <div class="row">
                        <div class="col-md-3">
                            <b>{{$t('message.customerIndex.email')}}</b>
                        </div>
                        <div class="col-md-9">
                            <InputText v-model="entry.email" :errors="errors.email" />
                        </div>
                    </div>
                    <!-- End email -->
                    <!-- Start facebook -->
                    <div class="row">
                        <div class="col-md-3">
                            <b>{{$t('message.customerIndex.facebook')}}</b>
                        </div>
                        <div class="col-md-9">
                            <InputText v-model="entry.facebook" :errors="errors.facebook" />
                        </div>
                    </div>
                    <!-- End facebook -->
                    <!-- Start facebook -->
                    <div class="row">
                        <div class="col-md-3">
                            <b>{{$t('message.customerIndex.modal.customerGroup')}}</b>
                        </div>
                        <div class="col-md-9">
                            <InputText :placeholder="$t('message.common.enterGroupName')" />
                        </div>
                    </div>
                    <!-- End facebook -->
                    <!-- Start facebook -->
                    <div class="row">
                        <div class="col-md-3">
                            <b>{{$t('message.InvoiceIndex.note')}}</b>
                        </div>
                        <div class="col-md-9">
                            <InputTextArea />
                        </div>
                    </div>
                </div>
            </div>            
        </div>
        <!-- Modal -->
        <div ref="modal" class="modal" tabindex="-1" role="dialog">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            {{$t('message.customerGroupIndex.titleCreate')}}
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body" v-if="shown">
                        <div>
                            <InputText v-model="group.name" :label="$t('message.customerGroupIndex.name')" :errors="errorGroups.name"/>
                            <InputTextArea v-model="group.description" :label="$t('message.customerGroupIndex.description')"/>
                        </div>
                    </div>                  
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputFileManager from "@/components/forms/InputFileManager";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QImage from "@/components/QImage";
import InputTextArea from "@/components/forms/InputTextArea";

export default {
    name: "TabInfo",
    props: ['id', 'isModal'],
    emits: ['created'],
    components: {
        InputTextArea,
        QImage,
        InputDatePicker,
        InputFileManager,
        InputSwitchButton,
        InputLocationPicker,
        InputText, ActionBar
    },
    mounted() {
        this.loadCustomerGroup();
        if (this.id) {
            this.load();
        }

    },
    data() {
        return {
            name: '',
            entry: {
                status: 1,
                gender: "",
                tagId: ""
            },
            address: {
                is_default: 1
            },
            errors: {
                apartmentName: []
            },
            errorGroups: {
                name: []
            },
            group: {
                name: '',
                description: ''
            },
            shown: false,
            location: {},
            customerGroup: [],
            typeAddress: '',
            showHistory: false
        }
    },

    methods: {
        async load() {
            const res = await $get('/v1/customers/show', {
                id: this.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            if (this.entry.addresses && this.entry.addresses.length > 0) {
                this.address = this.entry.addresses[0];
                this.location = this.address;
            }

        },

        async loadCustomerGroup() {
            const res = await $get('/v1/customers/loadCustomerGroup');

            if (res.code !== 200 ) {
                $alert(res);
                return;
            }

            this.customerGroup = res.data;

        },

        async save() {
            
            this.errors = {};
            this.address.provinceId = this.location.provinceId;
            this.address.districtId = this.location.districtId;
            this.address.wardId = this.location.wardId;

            const res = await $post('/v1/customers/save', {
                entry: this.entry, address: this.address
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.code === 200) {
                if (this.isModal) {
                    this.$emit('created', res.data);
                } else {
                    if (res.redirect) {
                        location.replace(res.redirect);
                    }
                }
            }

            $alert(res);
        },
        addGroupCustomer() {
            this.shown = true;
            this.$nextTick(() => {
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            })
        },
        async saveCustomerGroup() {
            alert(111);
            this.errorGroups = {};

            const res = await $post('/v1/tags/saveCustomer', {
                entry: this.group
            });

            if (res.code === -1) {
                this.errorGroups = res.errors;
                scrollToElement('.error-label');
            }

            if (res.code === 200) {
                await this.loadCustomerGroup();
                this.group = {
                    name: '',
                    description: ''
                };
                this.errorGroups = {
                    name: []
                };
                window.jQuery(this.$refs.modal).modal('hide');

            }

            $alert(res);
        },
        onClose() {
            this.group = {
                name: '',
                description: ''
            }
        }
    },
    watch: {
        id: function () {
            this.load();
        }
    }
}
</script>

<style scoped>

</style>
