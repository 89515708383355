// import http from '@/services/http';
// import apiEndpoints from '@/services/apiEndpoints';
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
// import router from '@/router';
const getDefaultState = () => {
    return {
        status: null,
        email: false,
        isCustomerOrSuplier: false,
        isConnectTransporter: false,
        statusGetDataTransporter: false,
        isUseVoucher: false,
        isUseCoupon: false,
        isUsePromotion: false,
        isUseOrder: false,
        isUseOrderForSupplier: false,
        isUseDelivery: false
    };
};
const state = getDefaultState();
const getters = {
    status (state) {
        return state.status;
    },
    email (state) {
        return state.email;
    },
    isCustomerOrSuplier (state) {
        return state.isCustomerOrSuplier
    },
    isConnectTransporter (state) {
        return state.isConnectTransporter
    },
    statusGetDataTransporter (state) {
        return state.statusGetDataTransporter
    },
    isUseVoucher(state) {
        return state.isUseVoucher
    },
    isUseCoupon(state) {
        return state.isUseCoupon
    },
    isUsePromotion(state) {
        return state.isUsePromotion
    },
    isUseOrder(state){
        return state.isUseOrder
    },
    isUseOrderForSupplier(state){
        return state.isUseOrderForSupplier
    },
    isUseDelivery(state){
        return state.isUseDelivery
    }
    
};
const mutations = {
    SET_STATUS (state, status) {
        state.status = status;
    },
    SET_EMAIL (state, email) {
        state.email = email;
    },
    SET_CUSTOMER_OR_SUPLIER (state, isCustomerOrSuplier) {
        state.isCustomerOrSuplier = isCustomerOrSuplier;
    },
    SET_IS_CONNECT_TRANSPORTER (state, isConnectTransporter) {
        state.isConnectTransporter = isConnectTransporter;
    },
    SET_STATUS_GET_DATA_TRANSPORTER (state, status) {
        state.statusGetDataTransporter = status;
    },
    SET_IS_USE_COUPON(state, isUseCoupon) {
        state.isUseCoupon = isUseCoupon
    },
    SET_IS_USE_VOUCHER(state, isUseVoucher){
        state.isUseVoucher = isUseVoucher
    },
    SET_IS_USE_PROMOTION(state, isUsePromotion) {
        state.isUsePromotion = isUsePromotion
    },
    SET_IS_USE_ORDER(state, isUseOrder){
        state.isUseOrder = isUseOrder
    },
    SET_IS_USE_ORDER_FOR_SUPPLIER(state, isUseOrderForSupplier){
        state.isUseOrderForSupplier = isUseOrderForSupplier
    },
    SET_IS_USE_DELIVERY(state, isUseDelivery){
        state.isUseDelivery = isUseDelivery
    },
};
const actions = {
    async setStatus ({commit}, status) {
        commit('SET_STATUS', status);
    },
    async setEmail ({commit}, email) {
        commit('SET_EMAIL', email);
    },
    async setCustomerOrSuplier({commit}, isCustomerOrSuplier){
        commit('SET_CUSTOMER_OR_SUPLIER', isCustomerOrSuplier);
    },
    async setIsConnectTransporter({commit}, isConnectTransporter){
        commit('SET_IS_CONNECT_TRANSPORTER', isConnectTransporter);
    },
    async setIsUseVoucher({commit}, isUseVoucher){
        commit('SET_IS_USE_VOUCHER', isUseVoucher)
    },
    async setIsUseCoupon({commit}, isUseCoupon){
        commit('SET_IS_USE_COUPON', isUseCoupon)
    },
    async setIsUsePromotion({commit}, isUsePromotion){
        commit('SET_IS_USE_PROMOTION', isUsePromotion)
    },
    async setIsUseOrder({commit}, isUseOrder){
        commit('SET_IS_USE_ORDER', isUseOrder)
    },
    async setIsUseOrderForSupplier({commit}, isUseOrderForSupplier){
        commit('SET_IS_USE_ORDER_FOR_SUPPLIER', isUseOrderForSupplier)
    },
    async setIsUseDelivery({commit}, isUseDelivery){
        commit('SET_IS_USE_DELIVERY', isUseDelivery)
    },
    async statusGetDataSettingTransports({commit}, status){
        commit('SET_STATUS_GET_DATA_TRANSPORTER', status);
    },

    async getStatus({commit}, routerName) {
        let actionName = null;
        let res = await $get('/v1/setting-store/get-options', {action: actionName});
        if (res && res.result && res.result.length) {
            // this.statusOption.consignment = this.statusOption.variant = false;
            commit('SET_EMAIL', false);
            res.result.forEach(element => {
                if (element.id == 9 && element.status == 1) {
                    commit('SET_EMAIL', true);
                }
                if (element.id == 11 && element.status == 1) {
                    commit('SET_CUSTOMER_OR_SUPLIER', true);
                }
                if (element.id == 35 && element.status == 1) {
                    commit('SET_IS_CONNECT_TRANSPORTER', true);
                }
                if (element.id == 17 && element.status == 1){
                    commit('SET_IS_USE_VOUCHER', true)
                }
                if (element.id == 19 && element.status == 1){
                    commit('SET_IS_USE_COUPON', true)
                }
                if (element.id == 16 && element.status == 1) {
                    commit('SET_IS_USE_PROMOTION', true)
                }
                if (element.id == 12 && element.status == 1) {
                    commit('SET_IS_USE_ORDER', true)
                }
                if (element.id == 21 && element.status == 1) {
                    commit('SET_IS_USE_ORDER_FOR_SUPPLIER', true)
                }                
                if (element.id == 38 && element.status == 1) {
                    commit('SET_IS_USE_DELIVERY', true)
                }
            });
        }
    }
};
export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
