const permissions = {
    products: {
        data:'Products.data',
        create:'Products.create',
        update:'Products.update',
        remove:'Products.remove',
        import:'Products.import',
        export:'Products.export',
        inActive:'Products.inActive',
    },
    categories: {        
        data:'Categories.data',
        create:'Categories.save',
        update:'Categories.update',
        remove:'Categories.remove',
    },
    units: {        
        data:'Units.data',
        create:'Units.create',
        update:'Units.update',
        remove:'Units.remove',
    },
    customers: {
        data:'Customers.data',
        create:'Customers.create',
        update:'Customers.update',
        remove:'Customers.remove',
        import:'Customers.import',
        export:'Customers.export',
        inActive:'Customers.inActive',
    },
    customerGroups: {
        data:'CustomerGroups.data',
        create:'CustomerGroups.create',
        update:'CustomerGroups.update',
        remove:'CustomerGroups.remove',
    },
    suppliers: {
        data:'Suppliers.data',
        create:'Suppliers.create',
        update:'Suppliers.update',
        remove:'Suppliers.remove',
        import:'Suppliers.import',
        export:'Suppliers.export',
        inActive:'Suppliers.inActive',
    },
    supplierGroups: {
        data:'SupplierGroups.data',
        create:'SupplierGroups.create',
        update:'SupplierGroups.update',
        remove:'SupplierGroups.remove',
    },
    users: {
        data:'Users.data',
        create:'Users.create',
        update:'Users.update',
        remove:'Users.remove',        
        export:'Users.export',
        import:'Users.import',
        inActive:'Users.inActive',
    },
    departments: {
        data:'Departments.data',
        create:'Departments.create',
        update:'Departments.update',
        remove:'Departments.remove',
    },
    saleChannels: {
        data:'SaleChannels.data',
        create:'SaleChannels.create',
        update:'SaleChannels.update',
        remove:'SaleChannels.remove',
    },
    settingPrice: {
        data:'SettingPrice.data',
        create:'SettingPrice.create',      
        update:'SettingPrice.update',
        export:'SettingPrice.export',
        import:'SettingPrice.import',
        delete:'SettingPrice.delete',
    },
    priceDetails: {
        data:'PriceDetails.data',        
        update:'PriceDetails.update',        
        export:'PriceDetails.export',
        import:'PriceDetails.import',
    },
    locations: {
        data:'Locations.data',
        create:'Locations.create',
        update:'Locations.update',
        remove:'Locations.remove',        
        export:'Locations.export',
        inActive:'Locations.inActive',
    },
    orderSuppliers: {
        data:'OrderSuppliers.data',
        create:'OrderSuppliers.create',
        update:'OrderSuppliers.update',
        updateStatus:'OrderSuppliers.updateNotStatus',
        cancel:'OrderSuppliers.cancel',
        export:'OrderSuppliers.export',
        createImportSupplier:'OrderSuppliers.createImportSupplier',
        detailDataAction:'OrderSuppliers.detailDataAction',
        
    },
    purchaseOrders: {
        // data:'PurchaseOrders.data',
        data:'purchaseOrders.data',
        create:'purchaseOrders.create',
        update:'purchaseOrders.update',
        cancel:'purchaseOrders.cancel',
        export:'purchaseOrders.export',  
        returnImportSupplier:'PurchaseOrders.returnImportSupplier'
    },
    returnImportForSupplier: {
        data:'ReturnImportForSupplier.data',
        create:'ReturnImportForSupplier.create',
        update:'ReturnImportForSupplier.update',
        cancel:'ReturnImportForSupplier.cancel',
        export:'ReturnImportForSupplier.export',        
    },
    orders: {
        data:'Orders.data',
        create:'Orders.create',
        update:'Orders.update',        
        updateStatus:'Orders.updateNotStatus',
        cancel:'Orders.cancel',
        export:'Orders.export',
        createImportSupplier:'Orders.createInvoice',
        updateNotStatus:'Orders.updateNotStatus',
    },
    invoices: {
        data: 'Invoices.data',
        create: 'Invoices.create',
        cancel: 'Invoices.cancel',
        export: 'Invoices.export',
        update: 'Invoices.update',
        getInventory: 'Invoices.getInventory',
        changPrice: 'Invoices.changPrice',
        updateDiscountInvoice: 'Invoices.updateDiscountInvoice',
        updateSoldBy: 'Invoices.updateSoldBy',
        createReturns: 'Invoices.createReturns',
        import: 'Invoices.import',        
    },
    returns: {
        data:'Returns.data',
        create:'Returns.create',
        update:'Returns.update',
        cancel:'Returns.cancel',
        export:'Returns.export',      
    },
    ticketImports: {
        data:'TicketImport',
        quickSave:'TicketImport.quickSave',
        update:'TicketImport.update',        
        cancel: 'TicketImport.cancel',
        export: 'TicketImport.export',
        print: 'TicketImport.print'
    },
    ticketExports: {
        data:'TicketExport',
        quickSave:'TicketExport.quickSave',
        update:'TicketExport.update',        
        cancel: 'TicketExport.cancel',
        export: 'TicketExport.export',
        print: 'TicketExport.print'
    },
    positionProducts: {
        data:'PositionProducts',
        create:'PositionProducts.update',
        quickSave:'PositionProducts.quickSave',
        update:'PositionProducts.update',        
        cancel: 'PositionProducts.cancel',
        print: 'PositionProducts.print',
        export: 'PositionProducts.export',
    },
    transfers: {
        data:'Transfers.data',
        create:'Transfers.create',
        update:'Transfers.update',
        cancel:'Transfers.cancel',
        export:'Transfers.export',      
        print:'Transfers.print',   
    },
    requests: {
        data:'Requests.data',
        create:'Requests.create',
        update:'Requests.update',
        cancel:'Requests.cancel',
        export:'Requests.export',      
    },
    coupons: {
        data:'Coupons.data',
        create:'Coupons.create',
        update:'Coupons.update',
        cancel:'Coupons.cancel',
        remove:'Coupons.remove',      
        releaseCoupon:'Coupons.releaseCoupon',      
    },
    voucher: {
        data:'Vouchers.data',
        create:'Vouchers.create',
        update:'Vouchers.update',
        cancel:'Vouchers.cancel',
        remove:'Vouchers.remove', 
        releaseVoucher: 'Vouchers.releaseVoucher'
    },
    promotions: {
        data:'Promotions.data',
        create:'Promotions.create',
        update:'Promotions.update',        
        remove:'Promotions.remove',      
        export:'Promotions.export',
    },
    branches: {
        data:'Branches.data',
        create:'Branches.create',
        update:'Branches.update',        
        remove:'Branches.remove',      
        inActive:'Branches.inActive',
    },
    printTemplates: {
        data:'PrintTemplates.data',
        create:'PrintTemplates.create',
        update:'PrintTemplates.update',        
        remove:'PrintTemplates.remove',              
    },
    roles: {
        data:'Roles.data',
        create:'Roles.create',
        update:'Roles.update',        
        remove:'Roles.remove',    
        toggleStatus:'Roles.toggleStatus',
        dataAction:'Roles.dataAction',
        getTreePermissionsAction:'Roles.getTreePermissionsAction',        
        export:'Roles.export',  
    },
    permissions: {
        data:'Permissions.data',       
        create:'Permissions.create',     
        update:'Permissions.update', 
        remove:'Permissions.remove', 
    },
    refunds: {
        data:'Refunds.data',
        create:'Refunds.create',
        update:'Refunds.update',  
        export:'Refunds.export',       
        cancel:'Refunds.cancel',    
        approve:'Refunds.approve',
        refuse:'Refunds.refuse', 
    },
    //sổ quỹ tiền mặt
    cashbooks: {
        data:'CashBook.data',
        create:'CashBook.create',
        update:'CashBook.update',  
        export:'CashBook.export',       
        cancel:'CashBook.cancel',  
        print:'CashBook.print',  
    },
    //sổ quỹ ngân hàng
    bankbooks: {
        data:'BankBook.data',
        create:'BankBook.create',
        update:'BankBook.update',  
        export:'BankBook.export',       
        cancel:'BankBook.cancel',  
        print:'BankBook.print',  
    },
    // Tích hợp kênh bán
    saleOnline: {
        data:'SaleOnline.data',
        create:'SaleOnline.create',
        update:'SaleOnline.update',          
        delete:'SaleOnline.delete', 
    },
    // Tam ứng quyết toán
    settlementAdvance: {
        data: 'SettlementAdvance.data',
        create: 'SettlementAdvance.create',
        update: 'SettlementAdvance.update',
        cancel: 'SettlementAdvance.cancel',
        export: 'SettlementAdvance.export',
        print: 'SettlementAdvance.print',
    },
    // Danh mục thuốc
    medicine: {        
        data: 'Medicine.data',
        export: 'Medicine.export',
        import: 'Medicine.import'
    },
    // Hàng hóa liên kết
    mappingProducts:{
        data: 'MappingProduct.data',
        mapping: 'MappingProduct.mapping',
        remove: 'MappingProduct.remove'
    },
    // Đối tác giao hàng
    transporters: {
        data:'Transporters.data',
        create:'Transporters.create',
        update:'Transporters.update',        
        remove:'Transporters.remove',    
        stop:'Transporters.stop',
        export:'Transporters.export',
    },
    // Nhóm đối tác giao hàng
    transporterGroups: {
        data: 'TransporterGroups.data',
        create: 'TransporterGroups.create',
        update: 'TransporterGroups.update',
        remove: 'TransporterGroups.remove',
    },
    // Vận đơn
    deliveries: {
        data: 'Deliveries.data',
        update: 'Deliveries.update',
        payment: 'Deliveries.paymentAndCreateFund',
        export: 'Deliveries.export',
        remove: 'Deliveries.remove',
        save: 'Deliveries.save',
        print: 'Deliveries.print',
        cancel: 'Deliveries.cancel',
    },
    // Dự trù chi tiết
    plans: {
        data: 'Plans.data',
        create: 'Plans.create',
        update: 'Plans.update',
        export: 'Plans.export',
        cancel: 'Plans.cancel',
        print: 'Plans.print',
        delete: 'Plans.delete',
    },
    // Dự trù nhập mua
    planPurchases:{
        data: 'PlanPurchases.data',
        create: 'PlanPurchases.create',
        update: 'PlanPurchases.update',
        export: 'PlanPurchases.export',
        cancel: 'PlanPurchases.cancel',
        print: 'PlanPurchases.print',
        delete: 'PlanPurchases.delete',
    },
    dashboard: "Dashboard"
}
export default permissions;