<template>
    <div ref="ModalMultipleProductsBarcode" class="modal mx-0"  tabindex="-1" role="dialog" id="ModalMultipleProductsBarcode" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 619px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px ;">
                    <h6 class="modal-title py-2">
                        {{ $t('message.ProductIndex.button.print') }}
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-0 py-0 mx-0 px-0" >
                    <div class="row">
                        <div class="col-12 text-center">     
                            <table class="table table-index table-striped " id="modalTableChecked">
                                <thead>                                    
                                <tr>
                                    <th scope="col" width="10%" class="text-center">
                                        <i class="fa fa-trash text-secondary"></i>
                                    </th>
                                    <th scope="col" width="10%" class="text-center">{{ $t('message.common.STT') }}</th>
                                    <th scope="col" width="22%" class="text-left">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                    <th scope="col" width="40%" class="text-left">{{$t('message.DetailOrdersCard.prodName')}}</th>
                                    <th scope="col" width="18%" class="text-center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                </tr>
                                </thead>
                                <tbody>            
                                    <template v-if="this.dataPrint.length >=1">
                                        <tr>                                           
                                            <td colspan="4"></td>
                                            <td style="font-weight: bold;" class="text-center py-2" id="total">
                                                <label class="pr-4"></label>{{this.total ?? 0}}
                                            </td> 
                                        </tr>                                       
                                        <tr :id="'modal_' + item.id" v-for="(item,index) in this.dataPrint" :key="item.id">
                                            <td class="text-center py-2" scope="row">
                                                <a href="javascript:;" @click="unChecked(item.id)" >                                                
                                                    <i class="fa fa-trash text-secondary"></i>
                                                </a>
                                            </td>
                                            <td class="py-2" v-text="index + 1"></td>
                                            <td class="text-left py-2" v-text="item.code"></td>
                                            <td class="text-left py-2">
                                                <label>{{item.name}}</label>
                                                
                                            </td>
                                            <td class="text-center py-2">                                                  
                                                <input type="number" name="quantity" v-model="item.quantity"  min="1" :id="item.id" class="form-control quantity text-right" placeholder="0"  oninput="this.value = Math.abs(this.value)">
                                            </td>
                                            </tr>                     
                                    </template>                                               
                                    <template v-else>
                                        <tr>
                                            <td class="text-center pt-4" colspan="5">
                                                <label>
                                                    {{$t('message.common.emptyProductSelected')}}
                                                </label>
                                            </td>
                                        </tr>                              
                                    </template>
                                     
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <td colspan="5" class="text-right pt-3 py-0">
                                            <button type="button" data-dismiss="modal" aria-label="Close" class="btn btn-secondary">
                                                {{$t('message.button.skip')}}
                                            </button>
                                            <button class="btn btn-primary" @click="showModalTypePapers(this.dataPrint)"> 
                                                {{ $t('message.ProductIndex.button.print') }}
                                            </button>                                                
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>        
    </div>   
    <ModalTypePapers ref="ModalTypePapers" @data="this.info" :multiParent="true"/>
</template>
<style>
#ModalMultipleProductsBarcode{
    overflow-x: hidden;
    overflow-y: auto;
}
</style>
<script>
import emitter from '@/lib/emitter';
import ModalTypePapers from "./ModalTypePapers.vue";
import InputText from "../../../components/forms/InputText";
import store from "@/store";
export default {
    name: "ModalMultipleProductsBarcode",
    components: {
        ModalTypePapers, InputText
    },
    data(){        
        return {
            dataPrint: [],
            total:0,
            info: [],
            quantity: 1

        }
    },
    
    mounted(){
        // this.quantityChange();
    },
    
    methods: {
        getTotalSendQuantity(data){
            let total = 0;
            data.forEach(value => {
                total= total + value.quantity;
            });
            return total;
        },
        formatDataProduct(){
            this.dataPrint.forEach(product => {
                product.quantity = 1;
            });
        },
        show(obj, data) {      
                
            this.dataPrint = data;                       
            switch (obj) {
                case 'product':
                    this.total = data.length;
                    this.formatDataProduct();                 
                    break;
                case 'transfer':
                    this.total = this.getTotalSendQuantity(this.dataPrint);                   
                    break;
                case 'purchaseOrders':
                    this.total = this.getTotalSendQuantity(this.dataPrint);                   
                    break;
            }              
             window.jQuery(this.$refs.ModalMultipleProductsBarcode).modal('show');
           
        },
      
         clearData(){
            this.total = 0;
            let className = document.getElementsByClassName('quantity');
            className.forEach(element => {       
                 element.value = 0;
            });
        },
        getDataCheck(){
            let data = [];
            let className = JSON.parse(localStorage.getItem('details'));
            if(className){
                className.forEach(item => {
                    if (item.checked) {
                    data.push({
                            'id' : item.productId, 
                            'name' : item.name,
                            'code' : item.code
                    });
                    }
                });
            }            
            
            return data;
        },
        unChecked(id){
            //Bỏ chọn ở danh sách
            this.quantityChange(id);
            this.removeItem(id);
        },
        removeItem(id){            
            let data = this.dataPrint;
            let vitri = 0;
            data.forEach((item, index) => {
                if(item.id === id) vitri = index;
            });            
            data.splice(vitri,1);
            this.$emit('inputData', '4');
        },
        showModalTypePapers(data){             
             this.info = this.getProductInfo(data);                                            
             this.$refs.ModalTypePapers.show('multi', this.info);
        }, 
        getProductUnit(product_units, unitID){
            let unit;
            if(product_units && product_units?.length != 0){
                
                product_units.forEach(pu => {   
                     if(pu.unitId == unitID) {                    
                        unit = {
                            'unitName': pu.unitName,
                            'price': pu.price,
                        }
                    }
                });
            }else{                
                unit = {
                    'unitName': null,
                    'price': null,
                }
            }            
            return unit;
        },
        getQuantityInfo(prdId){
            //Get số lượng sản phẩm đã chọn             
            let quantity = 0;
            var className =  document.getElementsByClassName('quantity');
            className.forEach(item => {                        
                if (item.id == prdId) quantity = item.value
            });            
            return quantity;
        },
        //Get infomations
        getProductInfo(data){       
                     
            let informations = [];
            let unit = "";
            // Load thông tin cần in ra tem bằng biến data khai báo                  
                data.forEach(value => {                         
                        let checkQuantity = this.getQuantityInfo(value.id);                                           
                        if(checkQuantity != 0){
                            unit = this.getProductUnit(value.product_units, value.unitID);
                            
                            informations.push({
                                // Get id của product
                                'prdID' : value.id,
                                // Mã sản phẩm 
                                'prdCode' : value.code,
                                // Tên sản phẩm
                                'prdName' : value.name,
                                // Tên đơn vị
                                'unitName': unit.unitName?? value.unitName,
                                // Giá tiền
                                'prdPrice': unit.price?? value.sendPrice,
                                // Tiền tệ
                                'prdCurrency': 'VNĐ', 
                                // Số lượng
                                'prdQuantity': value.quantity?? this.getQuantityInfo(value.id),
                                //Tên cửa hàng 
                                'branchName': store.state.CurrentBranch.name ?? null,
                            })
                        }               
                        
                });                          
            return informations;           
        }
    },
    created() {
         emitter.on("resetTotal", () => {            
            this.clearData();
        });
    },
    watch: {
        dataPrint:{
            handler(newData){
                if(!newData) {
                        return;
                }
                let total = 0;
                newData.forEach(item => {
                    total = +total + item.quantity;
                });
                this.total = total;
            },
            deep: true
        }
    }
}
</script>

