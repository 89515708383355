<template>
    <div class="d-row g-4 my-4">
        <div class="col-12 col-lg-12 px-0 mx-0">
            <div class="app-card app-card-chart h-100 shadow-sm">
                <div class="app-card-body">
                    <div class="row mx-0 pt-2 px-3 pb-0 border-bottom">
                        <div class="col-auto">
                            <h5 class="font-weight-bold">{{$t('message.notice.title_notice')}}</h5>
                        </div>
                    </div>
                    <div class="row-notice-body p-3 p-lg-4">
                        <div class="row mx-0 py-2 px-0 d-flex align-items-center" v-for="(entry, index) in newNoticeToday" :key="index">
                            <div class="col-1 col-lg-1 p-0 text-right">
                                <i class="fas fa-bell font-size-20 text-omi"></i>
                            </div>
                            <div class="col-10 col-lg-10">
                                <!-- <p class="m-0">
                                    {{$t('message.notice.have')}}
                                    <span class="text-primary" @click="showNoticeDetail(entry)" v-text="entry.type == this.$constant.typeNoticeProduct 
                                        ? entry.count + ' ' + this.$t('message.notice.product') 
                                        : (entry.type == this.$constant.typeNoticeVoucher ? entry.count + ' ' + this.$t('message.notice.voucher') : (entry.type == this.$constant.typeNoticeCoupon ? entry.count + ' ' + this.$t('message.notice.coupon') : entry.count + ' ' + this.$t('message.notice.promotion')))"></span>
                                    {{$t('message.notice.expired')}}
                                    <span class="text-primary" v-text="entry.type == this.$constant.typeNoticeProduct ? ' ' + this.$t('message.notice.three_month') : ' '"></span>
                                </p> -->
                                <p class="m-0" v-html="renderNotice(entry)" @click="showNoticeDetail(entry)"></p>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-0 pt-2 px-3 pb-0 border-bottom">
                        <div class="col-auto">
                            <h5 class="font-weight-bold">{{$t('message.notice.title_action')}}</h5>
                        </div>
                    </div>
                    <div class="row-action-body p-3 p-lg-4">
                        <div class="row mx-0 py-2 px-0 d-flex align-items-center" v-for="entry in newPaperToday" :key="entry.id">
                            <div class="col-1 col-lg-1 p-0 text-right">
                                <i class="fa fa-file-alt font-size-20 text-omi"></i>
                            </div>
                            <div class="col-10 col-lg-10">
                                <!-- <p class="m-0"> 
                                    <span class="text-primary" v-text="' ' + entry.userName" @click="entry.userCode ? linkToEmployee(entry.userCode) : ''"></span>
                                    {{$t('message.notice.action_by_user')}}
                                    <span class="text-primary" v-text="' ' + entry.action" @click="entry.code ? linkToPaper(entry) : ''"></span>
                                    <span class="" v-text="entry.branchNameReceive && entry.branchNameSend ? ' ' + this.$t('message.notice.content_transfer', {fromBranch: entry.branchNameSend, toBranch: entry.branchNameReceive}) : ''"></span>
                                    <span class="" v-text="entry.amount ? (' ' + this.$t('message.notice.value')) : ''"></span>
                                    <span class="text-bold" v-text="entry.amount ? (' ' + n(entry.amount)) : ''"></span>
                                </p> -->
                                <p class="m-0" v-html="$t('message.notice.noticeActionInvoice', 
                                    {
                                        href: entry.href ?? '#',
                                        hrefAction: entry.hrefAction ?? '#', 
                                        employee: entry.userName, 
                                        action: entry.action, 
                                        showBranch: entry.branchNameReceive && entry.branchNameSend ? '' : 'd-none', 
                                        branchNameSend: entry.branchNameSend ?? null, 
                                        branchNameReceive: entry.branchNameReceive ?? null,
                                        showAmount: entry.amount ? '' : 'd-none',
                                        amount: n(entry.amount)
                                    })">
                                </p>
                                <p class="m-0"> 
                                    <span class="text-gray font-italic" v-text="dt(entry.time)"></span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <NoticeDetail ref="NoticeDetail"/>
</template>

<script>
import { $get, $alert } from "@/lib/utils";
import store from "@/store";
import NoticeDetail from "./NoticeDetail.vue";
export default {
    name: "Notice",
    components: {
        NoticeDetail
    },
    data() {
        return {
            statistic: {},
            newPaperToday: [],
            newNoticeToday: [],
            intervalLoad: null,
        };
    },
    methods: {
        async getNewPaperToday() {
            const res = await $get('/v1/dashBoard/get-newsPaper-today', {branchId: store.state.CurrentBranch.id});
            if (res.status.code != 200) {
                $alert({ code: 500, message: this.$t('message.common.errorServer') });
                return;
            }
            res.result.sort(function(a, b) { return new Date(b.time) - new Date(a.time) });
            this.newPaperToday = res.result;
        },
        getDataInterval(){          
            this.intervalLoad = setInterval(() => {
                this.getNewPaperToday();
                this.getNoticeToday();
            }, 180000);
        },
        linkToEmployee(code){
            var route = null;
            route = this.$router.resolve({
                name: 'EmployeeIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToInvoice(code){
            var route = null;
            route = this.$router.resolve({
                name: 'InvoiceIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToOrder(code){
            var route = null;
            route = this.$router.resolve({
                name: 'OrderIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToReturn(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToTransfer(code){
            var route = null;
            route = this.$router.resolve({
                name: 'TransferIndex',
                query: {
                    'code_transfer': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToRequest(code){
            var route = null;
            route = this.$router.resolve({
                name: 'RequestIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToImportSupplier(code){
            var route = null;
            route = this.$router.resolve({
                name: 'PurchaseOrderIndex',
                query: {
                    'purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToOrderSupplier(code){
            var route = null;
            route = this.$router.resolve({
                name: 'OrderSupplierIndex',
                query: {
                    'order_supplier': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToReturnSupplier(code){
            var route = null;
            route = this.$router.resolve({
                name: 'ReturnPurchaseOrder',
                query: {
                    'return_purchase_order': code,
                }
            });
            window.open(route.href, '_blank');
        },
        linkToPaper(entry){
            switch (entry.type) {
                case 'invoice':
                    this.linkToInvoice(entry.code);
                    break;
                case 'order':
                    this.linkToOrder(entry.code);
                    break;
                case 'return':
                    this.linkToReturn(entry.code);
                    break;
                case 'transfer':
                    this.linkToTransfer(entry.code);
                    break;
                case 'request':
                    this.linkToRequest(entry.code);
                    break;
                case 'importSupplier':
                    this.linkToImportSupplier(entry.code);
                    break;
                case 'orderSupplier':
                    this.linkToOrderSupplier(entry.code);
                    break;
                case 'returnSupplier':
                    this.linkToReturnSupplier(entry.code);
                    break;
            }
        },
        async getNoticeToday() {
            const res = await $get('/v1/dashBoard/get-notice-today', {branchId: store.state.CurrentBranch.id});
            if (res.status.code != 200) {
                $alert({ code: 500, message: this.$t('message.common.errorServer') });
                return;
            }
            this.newNoticeToday = res.result
        },
        showNoticeDetail(entry){
            this.$refs.NoticeDetail.show(entry);
        },
        renderNotice(entry){
            let content = null;
            switch (entry.type) {
                case this.$constant.typeNoticeProduct:
                    content = this.$t('message.notice.noticeForProduct', {entry: entry, number: entry.count});
                    break;
                case this.$constant.typeNoticeVoucher:
                    content = this.$t('message.notice.noticeForVoucher', {entry: entry, number: entry.count});
                    break;
                case this.$constant.typeNoticeCoupon:
                    content = this.$t('message.notice.noticeForCoupon', {entry: entry, number: entry.count});
                    break;
                case this.$constant.typeNoticePromotion:
                    content = this.$t('message.notice.noticeForPromotion', {entry: entry, number: entry.count});
                    break;
            }
            return content;
        }
    },
    mounted(){
        try{
            this.getNewPaperToday()
            this.getNoticeToday()
            this.getDataInterval()
        }catch(e){
            console.error(e)
        }
    },
    created() {
    }
};
</script>
<style scoped lang="scss">
    @import "@/assets/css/vars.scss";
    .font-size-20{
        font-size: 20px !important;
    }
    .row-notice-body{
        max-height: 200px !important;
        overflow: auto;
    }
    .row-action-body{
        max-height: 600px !important;
        overflow: auto;
    }
</style>