<template>
    <div v-if="isDataLoaded" style="padding-bottom: 80px">
        <div class="row mt-3">
            <div class="col-lg-6 row">
                <div class="col-lg-12">
                    <InputText v-model="entry.code"
                               :errors="errors.code"
                               label="Mã đặt hàng nhập" :readonly="true"/>
                    <InputDatePicker
                            :readonly="entry.purchaseOrders.length > 0"
                            :timepicker="true"
                            v-model="entry.createdAt"
                            :errors="errors.createdAt"
                            label="Thời gian"/>
                    <div class="form-group">
                        <label>Nhà cung cấp</label>
                        <q-select name="supplierId" datasource="suppliers" v-model="entry.supplierId"/>
                    </div>
                    <div class="form-group">
                        <label>{{$t('message.common.created_by')}}</label>
                        <q-select name="createdBy" v-model="entry.createdBy"
                                  datasource="users"/>
                        <ErrorLabel :errors="errors.createdBy"/>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label>{{$t('message.common.status')}}</label>
                        <InputSelect
                                name="status"
                                v-model="entry.status"
                                :options="entry.status > 1 ? statusList : statusOptions"
                                :readonly="entry.status > 1"
                        />
                    </div>
                    <div class="form-group">
                        <label>{{$t('message.common.branch')}}</label>
                        <q-select name="branchId" datasource="branches" v-model="entry.branchId"/>
                    </div>
                    <div class="form-group">
                        <label>Người đặt</label>
                        <q-select name="orderBy" v-model="entry.orderBy"
                                  datasource="users"/>
                    </div>
                    <InputDatePicker
                            v-model="entry.expectedAt"
                            :errors="errors.expectedAt"
                            label="Ngày nhập dự kiến"/>
                </div>
            </div>
            <div class="col-lg-12">
                <InputTextArea v-model="entry.description" :errors="errors.description" :label="$t('message.ProductIndex.note')"/>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-lg-12">
                <table class="table table-index table-striped">
                    <thead>
                    <tr>
                        <th>Mã hàng hóa</th>
                        <th>Tên hàng hóa</th>
                        <th>{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                        <th>{{$t('message.DetailOrdersCard.prodImportPrice')}}</th>
                        <th>{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="detail in entry.orderDetails" :key="detail.id" >
                        <td v-text="detail.product?.code"></td>
                        <td v-text="detail.product?.name"></td>
                        <td v-text="detail.product_unit?.unitName"></td>
                        <td><span v-text="detail.quantity"></span>/<span v-text="getTotalPurchase(detail)"></span></td>
                        <td v-text="n(detail.price)">
                        </td>
                        <td v-text="detail.discount">
                        </td>
                        <td v-text="n(detail.price - detail.discount)">
                        </td>
                        <td v-text="n(detail.subTotal)">
                        </td>
                    </tr>

                    </tbody>
                </table>

            </div>
            <div class="col-lg-12" v-if="entry.orderDetails.length > 0">
                <div class="row" style="float: right; width: 40%">
                    <div class="col-6">
                        <ul style="list-style-type: none">
                            <li>{{$t('message.DetailOrdersCard.totalQuantity')}}:</li>
                            <li>{{$t('message.TransferIndex.total_products')}}:</li>
                            <li>{{$t('message.DetailOrdersCard.total')}}:</li>
                            <li>Giảm giá:</li>
                            <li>{{$t('message.OrderIndex.total')}}:</li>
                        </ul>
                    </div>
                    <div class="col-6">
                        <ul style="list-style-type: none">
                            <li v-text="entry.totalQuantity"></li>
                            <li v-text="entry.totalProductType"></li>
                            <li v-text="n(entry.totalAmt)"></li>
                            <li v-text="n(entry.discount)"></li>
                            <li v-text="n(entry.total)"></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "../../../components/forms/InputText";
    import InputNumber from "../../../components/forms/InputNumber";
    import QSelect from "../../../components/QSelect";
    import InputTextArea from "../../../components/forms/InputTextArea";
    import InputFileManager from "../../../components/forms/InputFileManager";
    import InputDatePicker from "../../../components/forms/InputDatePicker";
    import ErrorLabel from "../../../components/ErrorLabel";
    import store from "../../../store";
    import InputSelect from "../../../components/forms/InputSelect";

    export default {
        name: "ProductFormTabMain",
        components: {
            InputSelect,
            ErrorLabel,
            InputFileManager, InputTextArea, QSelect, InputNumber, InputText, InputDatePicker
        },
        data() {
            const globalConfig = store.state.GlobalConfig;

            return {
                errors: {},
                currentBaseAttribute: null,
                isDataLoaded: false,
                productUnits: [],
                units: globalConfig.units,
                branches: globalConfig.branches,
                entry: {
                    branchIDs: [],
                    images: []
                },
                statusOptions: [
                    {id: 0, name: 'Phiếu tạm'},
                    {id: 1, name: 'Đã xác nhận NCC'},
                ],
                statusList: [
                    {id: 0, name: 'Phiếu tạm'},
                    {id: 1, name: 'Đã xác nhận NCC'},
                    {id: 2, name: 'Nhập một phần'},
                    {id: 3, name: 'Hoàn thành'},
                    {id: 4, name: 'Đã hủy'},
                ],
            }
        },
        methods: {
            conversionValueUpdated(a, conversionValue) {
                setTimeout(() => {
                    this.$emit('onValidate');
                }, 50);

                if (!this.entry.unitAutoPrice || a.isBase) {
                    return;
                }


                const base = this.productUnits.find(a => a.isBase);
                if (base) {
                    a.price = base.price * conversionValue;
                }

            },
            priceUpdated() {
                setTimeout(() => {
                    this.$emit('onValidate');
                }, 50);
            },
            onBaseChange(a) {
                this.productUnits.forEach(pa => {
                    if (!pa.deleted) {
                        pa.isBase = (pa.unitId === a.unitId);
                    }
                });

                this.$emit('onValidate')
            },
            removeAttribute(attribute) {
                if (!confirm('Xóa đơn vị?')) {
                    return;
                }

                attribute.deleted = true;
                if (attribute.isBase) {
                    const first = this.productUnits.find(pa => !pa.deleted);
                    if (first) {
                        first.conversionValue = 1;
                        first.isBase = true;
                    }
                }
            },
            addAttribute() {
                let isBase = false;
                if (this.productUnits.filter(pa => !pa.deleted).length === 0) {
                    isBase = true;
                }

                this.productUnits.push({
                    isBase: isBase,
                    unitId: null,
                    conversionValue: 1,
                    price: 0,
                    deleted: false,
                    errors: {}
                })
            },
            getTotalPurchase(detail) {
                if (this.entry.purchaseOrders) {
                    let total = 0;
                    this.entry.purchaseOrders.forEach((purchaseOrder) => {
                        if (purchaseOrder.purchase_order_details) {
                            purchaseOrder.purchase_order_details.forEach((item) => {
                                if (item.productId === detail.productId) {
                                    total += item.quantity
                                }
                            })
                        }
                    });
                    return total
                } else {
                    return 0
                }
            }
        },
        mounted() {
            document.title = 'Thông tin hàng hóa';
        }
    }
</script>

<style scoped>

</style>
