<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2 mx-3">{{ $t("message.ModalCreateUpdatePickup.confirm_title") }}</h5>
                    <button type="button" class="close" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <div class="col-12 pb-5">
                        <h6><span>{{ $t("message.ModalCreateUpdatePickup.confirm_content") }}</span></h6>
                    </div>
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="confirm()"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        <button class="btn btn-secondary text-white" @click="skip()"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.table thead th
#modalConfirm .modal-header {
    border-bottom: 0 !important;
}
</style>
<script>
import {$post, $alert} from "@/lib/utils";
import toastr from 'toastr';
import Paginate from "@/components/Paginate";
export default {
    name: "ModalConfirm",
    components: {Paginate},
    data(){
        return {
            id: null
        }
    },
    props: ['message'],
    methods: {
        show(id) {
            this.id = id;
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        async confirm(){
            await $post('/v1/pickup-address/delete', {id: this.id});
            toastr.success(this.$t("message.ModalCreateUpdatePickup.message_success_delete"));
            this.$emit('updated', '');
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        }
    }
}
</script>

