<template>
    <div class="search-box position-relative custom__input" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input" :placeholder="this.placeholder"
               v-model.trim="keyword" @input="onSearchBranch" :readOnly="read" :class="cusStyle ? 'cusStyle' : ''"               
        />
        <AppPermission :permission="this.$permissions.suppliers.create?? '' ">
            <i v-if="!keyword" class="fa fa-plus px-2"  @click="showModalAddSupplier" :readOnly="read" v-show="this.plus == false ? false : true"></i>           
        </AppPermission>
         <i v-if="keyword" class="fa fa-times px-2"  @click="clearSupplier"></i>
        <div class="popup-supplier-search" v-if="branches.length">
            <ul class="wrap-products" v-if="branches.length">
                <li v-for="branch in branches" :key="branch.id" @click="onSelect(branch)">
                    <div class="product-info">
                        <span class="branch__name">{{ branch.name }}</span>
                        <span class="branch__contactNumber">{{ branch.contactNumber }}</span>
                    </div>
                </li>
            </ul>
            <!-- <div v-else class="products-not-found">
                <span>{{$t('message.common.notFound')}}</span>
            </div> -->
        </div>
    </div>
    <ModalAddInfoSupplierl ref="ModalAddInfoSupplierl" @resetCount="resetCount()" @getNewData="getNewData"/>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import ModalAddInfoSupplierl from '@/components/supplierl/ModalAddInfoSupplierl';
    import AppPermission from './AppPermission.vue';
    let app;

    export default {
        name: "SupplierSearch",
        emits: ['select'],
        components: {ModalAddInfoSupplierl, AppPermission},
        props: ['supplierName', 'readSupplier', 'cusStyle', 'placeholder', 'clearKeyword', 'plus'],
        data() {
            app = this;
            return {
                branches: [],
                keyword: '',
                read: false,
            }
        },
        updated() {
            if (this.supplierName) {
                this.keyword = this.supplierName;
            }
            if (this.readSupplier) {
                this.read = this.readSupplier;
            }
        },
        methods: {
            onSearchBranch: debounce(async () => {
                app.callOnSearchBranch();
            }),
            getNewData(supplier) {
                this.keyword = supplier.name;
                this.$emit('select', supplier);
            },
            clearSupplier(){
                if (!this.read) {
                    this.keyword = '',
                    this.$emit('clearSupplier');
                }
            },
            async callOnSearchBranch() {                
                if (this.keyword) {
                    const res = await $get('/v1/order-suppliers/get-branches', {keyword: this.keyword?.trim()});
                    if (res.status.code == 200) {
                        this.branches = res.result;
                    } else {
                        $alert(res);
                    }
                }
            },
            onSelect(supplier) {
                this.$emit('select', supplier);                
                this.keyword = this.clearKeyword ? null : supplier.name;
                this.branches.length = 0;
                // localStorage.setItem('details', product);
            },
            hidePopup() {
                // this.keyword = '';
                this.branches.length = 0;
            },
            showModalAddSupplier() {
                this.$refs.ModalAddInfoSupplierl.show();
            },
        },
        watch: {
            supplierName() {
                this.keyword = this.supplierName;
            },
            readSupplier() {
                this.read = this.readSupplier;
            },
            keyword(newValue) {
                if (!newValue) {
                    this.clearSupplier();
                }
            }, 
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .custom__input {
        border: none;
        border-bottom: solid 1px #ccc;
        border-radius: 0;
    }
    .popup-supplier-search {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            // min-width: 450px;
            z-index: 10;
            padding-bottom: 20px;
            /*padding-bottom: 50px;*/

            max-height: calc(100vh - 230px);
            font-size: 14px;
            background: #fff;

            border-radius: 5px;
            box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

            ul.wrap-products {
                padding: 10px 0;
                overflow: auto;
                max-height: calc(100vh - 284px);
                list-style: none;
                margin-bottom: 0;

                li {
                    cursor: pointer;
                    padding: 10px 20px;
                    margin: 0;

                    .product-img {
                        width: 55px;
                        height: 55px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        float: left;
                        margin-left: -5px;
                        margin-top: 0;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            vertical-align: middle;
                        }
                    }

                    .product-info {
                        margin-left: 60px;
                        line-height: 18px;
                        display: flex;
                        justify-content: space-between;
                        p {
                            margin-bottom: 2px;
                        }

                        .name-item {
                            font-weight: bold;
                            word-break: break-word;
                        }

                        .code-item, .price-item {
                            margin-right: 20px;
                            margin-left: 0;
                        }
                    }
                }

                li:hover {
                    background-color: $base-color;
                    color: #fff;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    width: 8px;
                    border-radius: 4px;
                    background-color: #c1c1c1;
                    border: 1px solid #fff;
                }

                &::-webkit-scrollbar-track {
                    padding: 6px;
                }
            }

            .products-not-found {
                padding: 25px 10px;
                color: #999999;
                text-align: center;
            }
            .cusStyle {
                border-radius: 15px !important;
            }
            .add-new-product {
                line-height: 50px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                border-top: 1px solid #ebebeb;
                cursor: pointer;

                &:hover {
                    background-color: $base-color;
                    color: #fff;
                }
            }
        }
</style>
