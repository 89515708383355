<template>
    <ul class="error-label" v-if="errors && errors.length">
        <li v-for="error in errors" :key="error" v-text="error"></li>
    </ul>
</template>

<script>
export default {
    name: "ErrorLabel",
    props: ['errors']
}
</script>

<style scoped>

</style>
