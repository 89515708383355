<template>
    <div class="row">
        <ActionBar back-url="/surcharges/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.id ? 'Chỉnh sửa thu khác' : 'Thêm mới thu khác'"></h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.code"
                                       :errors="errors.code"
                                       label="Mã thu khác"
                                       placeholder="Mã thu tự dộng"/>
                            <InputText v-model="entry.type"
                                       :errors="errors.type"
                                       label="Loại thu"/>

                            <div class="form-group">
                                <label>Giá trị</label>
                                <div class="d-flex">
                                    <InputNumberFormat v-model="entry.priceValue"
                                                       @update:modelValue="updatePriceValue(entry)"
                                                       :min="0" :max="entry.priceType == DISCOUNT_TYPE_PERCENT ? 100 : undefined"
                                                       class="w-100"
                                    ></InputNumberFormat>
                                    <div class="btn-discount-types">
                                        <span class="btn-discount-types__item"
                                              :class="entry.priceType == DISCOUNT_TYPE_VND ? 'active' : ''"
                                              @click="updatePriceType(entry, DISCOUNT_TYPE_VND)">VNĐ</span>
                                        <span class="btn-discount-types__item"
                                              :class="entry.priceType == DISCOUNT_TYPE_PERCENT ? 'active' : ''"
                                              @click="updatePriceType(entry, DISCOUNT_TYPE_PERCENT)">%</span>
                                    </div>
                                </div>
                            </div>

                            <InputSwitchButton v-model="entry.status"
                                               :errors="errors.status"
                                               label="Đang thu"/>
                            <InputNumber v-model="entry.order"
                                       :errors="errors.order"
                                       label="Thứ tự hiển thị"/>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {
        $post,
        $alert,
        $get,
        scrollToElement,
        DISCOUNT_TYPE_PERCENT,
        DISCOUNT_TYPE_VND,
        debounce
    } from "./../../lib/utils";
    import InputSwitchButton from "../../components/forms/InputSwitchButton";
    import InputNumber from "../../components/forms/InputNumber";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";

    export default {
        name: "SurchargeForm",
        components: {InputNumberFormat, InputNumber, ActionBar, InputText, InputSwitchButton},
        data() {
            return {
                isDataLoaded: false,
                entry: {
                    status: 1
                },
                errors: {},

                DISCOUNT_TYPE_VND,
                DISCOUNT_TYPE_PERCENT,
            }
        },
        mounted() {
            document.title = this.entry.id ? 'Chỉnh sửa thu khác | OmiPos' : 'Thêm mới thu khác | OmiPos';
            if (this.$route.query.id) {
                this.load();
            } else {
                this.isDataLoaded = true;
            }

        },
        methods: {
            async load() {
                const res = await $get('/v1/surcharges/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {

                    location.replace('/404');
                    return;
                }

                this.entry = res.data.entry;
                this.isDataLoaded = true;

            },
            async save() {
                this.errors = {};

                const res = await $post('/v1/surcharges/save', {
                    entry: this.entry
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            },
            updatePriceValue: debounce((entry) => {
                if (!entry.priceType) {
                    entry.priceType = DISCOUNT_TYPE_VND;
                }
            }),
            updatePriceType(entry, type) {
                entry.priceValue = 0;
                entry.priceType = type;
            }
        }
    }
</script>

<style scoped>

</style>
