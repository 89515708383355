<template v-show="this.show"> 
    <div class="row">
        <div class="col-12">
                <div v-html="this.data" class="px-2 pb-5 view-show d-none" style="height:750px;" id="Print"></div>
        </div>
    </div>         
</template>


<script>                
    export default {
        name: "PrintTemplate",    
        components: {},   
        props: {} ,
        data() {
            return {
                data:[],
                show: false,                                                 
            }
        },
        created(){ },
        mounted(){ },
        watch: {
            data(newData){                                        
                this.data = newData
            }
        },
        methods: {
            multiplePrint(data) {                                
                this.data = data;                
                this.show = true;                
                setTimeout(() => {
                    this.$htmlToPaper('Print', {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        autoClose: true,
                        windowTitle: window.document.title,
                        styles: [
                            '/assets/css/barcode.css'
                        ]
                    }, () => {this.show = false;});
                }, 500);
            },
        },
        
    }
</script>