<template>
    <div ref="modal" class="modal mx-0 fade" :class="this.disabled && this.disabled === true ? 'modal-second' : ''"
         tabindex="-1" role="dialog" id="modalJobTitle" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{ $t('message.SettingPage.partner.modal.debt.title') }}</h5>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12"><h6><b>{{$t('message.common.level')}}:</b></h6></div>
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3 d-flex align-items-center">
                                    <label class="container-checkbox d-flex m-0" :class="this.isEdit ? 'cursor-ban' : ''">
                                        {{$t('message.TransporterIndex.current_debt')}}
                                        <div class="container-circle tooltip-left-center">
                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                            <div class="content-circle font-weight-normal">
                                                <p>{{$t('message.common.maxDebt')}}</p>
                                                <p>{{$t('message.common.warningWhenTransaction')}}</p>
                                            </div>
                                        </div>
                                        <input type="checkbox" class="chkType" :disabled="this.isEdit || (entry.customer_count && entry.customer_count > 0) ? true : false" v-model="entry.isCustomer" :checked="entry.isCustomer || (entry.customer_count && entry.customer_count > 0) ? true : false">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                                <div class="col-3">
                                    <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"/>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-3 d-flex align-items-center">
                                    <label class="container-checkbox d-flex m-0" :class="this.isEdit ? 'cursor-ban' : ''">
                                        {{$t('message.common.numberOfDebtDate')}}
                                        <div class="container-circle tooltip-left-center">
                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                            <div class="content-circle font-weight-normal">
                                                <p>{{$t('message.common.maxNumberOfDebtDate')}}</p>
                                                <p>{{$t('message.common.warningWhenTransaction2')}}</p>
                                            </div>
                                        </div>
                                        <input type="checkbox" class="chkType" :disabled="this.isEdit || (entry.customer_count && entry.customer_count > 0) ? true : false" v-model="entry.isCustomer" :checked="entry.isCustomer || (entry.customer_count && entry.customer_count > 0) ? true : false">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>
                                <div class="col-2">
                                     <InputNumberFormat :readonly="this.notEdit" :bgTransparent="true" :notBorder="true" class="input-custom min-w-100 mb-0 bg-transparent"/>
                                </div>
                                <div class="col-2 d-flex align-items-center">
                                    <label class="container-checkbox d-flex m-0">
                                        <p>{{$t('message.common.dayFrom')}}</p>
                                    </label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-lg-12">
                                    <label class="container-checkbox d-flex m-0" :class="this.isEdit ? 'cursor-ban' : ''">
                                        {{$t('message.common.addDebt')}}
                                        <div class="container-circle tooltip-left-center">
                                            <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                            <div class="content-circle font-weight-normal">
                                                <p>{{$t('message.common.addDebtTooltip')}}</p>
                                                <p>{{$t('message.common.addDebtNote')}}</p>
                                            </div>
                                        </div>
                                        <input type="checkbox" class="chkType" :disabled="this.isEdit || (entry.customer_count && entry.customer_count > 0) ? true : false" v-model="entry.isCustomer" :checked="entry.isCustomer || (entry.customer_count && entry.customer_count > 0) ? true : false">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                            <button type="button" class="btn btn-danger mr-2" v-if="(typeEditAdd === 'EDIT' && this.id != null)" @click="removeJobTitle(entry)">
                                <i class="fa fa-trash-alt mr-1"></i>
                                {{$t('message.button.remove')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import InputText from "@/components/forms/InputText";

import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import emitter from "@/lib/emitter";
import InputNumberFormat from "@/components/forms/InputNumberFormat";

export default {
    name: "ModalDebt",
    components: {InputText, InputNumberFormat},
    props: {
        typeEditAdd: String,
        disabledJobTitle:Boolean,
    },
    data() {
        return {
            entry: {},
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            id: null,
            disabled: this.disabledJobTitle,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                description: '',
                status: 1,
            };
            this.default = {
                name: '',
                description: '',
                status: 1,
            };
            this.id = null;
        },
        async show(id = null) {              
            this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
                this.id = id;
            }
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            emitter.emit('reloadDataEmployee');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        
        async load(id = null) {
            const res = await $get('/v1/job-title/show', {
                id: id
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
        },
        async save() {
            this.errors = {};
            const res = await $post('/v1/job-title/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }
            $alert(res);
            if(res.code == 200){
                this.hide();
            }
        },
        removeJobTitle(entry){
            let id = entry.id;
            let name = entry.name;
            this.$refs.ModalRemoveJobTitle.show(id, name);
        },
    },
    mounted() {
    },
    created () {
        emitter.on("showPopupAddJobTitle", (value) => {
            this.disabled = value;
            this.show();
        });
    },
}
</script>

<style scoped>
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}
#modal-third {
    z-index: 1;
    opacity:0.5;
}
.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
</style>