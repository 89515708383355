
<template>
    <div >
        <ActionBar type="edit"
                   icon="fa fa-edit"
                   label="Chỉnh sửa"
                   back-url="/promotions/index" @action="edit()"/>
        <div class="main-title">
            <h1>
                PromotionDetail            </h1>

        </div>
        <div class="row mt-3">
            <table class="table table-index table-striped">
                <tbody>
                <tr v-for="field in fields" :key="field.field">
                    <th  v-text="field.name"></th>
                    <td  v-text="entry[field.field]"></td>
                </tr>
                </tbody>
            </table>

        </div>

    </div>
</template>

<script>
    import {$alert, $get} from "@/lib/utils";
    import ActionBar from "@/components/ActionBar";

    export default {
        name: "CustomerDetail",
        components: {ActionBar},
        mounted() {
            document.title = 'CustomerDetails';
            this.load()
        },
        data() {
            return {
                fields: [],
                entry: {}
            }
        },
        methods: {
            edit() {
                location.href = '/promotions/form?id=' + this.entry.id;
            },
            async load() {
                const res = await $get('/v1/promotions/show', this.$route.query);
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                this.fields = res.data.fields;
                this.entry = res.data.entry;

            }
        }
    }
</script>
