<template>
    <div class="row mb-2">
        <div class="col-2 pl-2 d-flex align-items-center">
            <label class="container-radio ml-2">
                <input type="radio" v-model="this.sortDateTime" :name="this.label" :id="this.label + '0'" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col-10 pl-0">
            <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
        </div>
    </div>
    <div class="row mb-2">
        <div class="col-2 pl-2 d-flex align-items-center">
            <label class="container-radio ml-2">
                <input type="radio" v-model="this.sortDateTime" :name="this.label" :id="this.label + '1'" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                <span class="checkmark"></span>
            </label>
        </div>
        <div class="col-10 pl-0">
            <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
            @changed="filterTime(filter.timePicker, 1)" v-model="filter.timePicker"/>
        </div>
    </div>
</template>

<script>
import DateRangePicker from "@/components/DateRangePicker.vue";
import InputDateRangePicker from "@/components/forms/InputDateRangePicker";
import emitter from "@/lib/emitter";
export default {
    name: "SelectDateTime",
    props:  ['placeholder', 'modelValue', 'defaultOption', 'label', 'disabled'],
    components: {DateRangePicker, InputDateRangePicker},
    methods: {
        async filterTime(time = null, option){
            if(option == 1){
                emitter.emit("clearDataDateRangePicker");
                this.filter.time = null;
                this.sortDateTime = 1;
                this.filter.timePicker = time;
                
            }else{
                this.filter.timePicker = null;
                this.sortDateTime = 0;
                this.filter.time = time;
            }
            this.$emit('update:modelValue', {value: time, label: this.label ?? null});
        },
        refreshDatePicker(option){
            switch (option) {
                case 0:
                    this.filterTime(this.filter.time);
                    break;
                case 1:
                    this.filterTime(this.filter.timePicker, 1);
                    break;
            }
        },
    },
    data() {
        return {
            sortDateTime: 0,
            filter: {
                time: null,
                timePicker: null,
            }
        }
    },
    created(){
        emitter.on('clearSelectDateTime', () => {
            this.sortDateTime = 0;
            this.filter.time = null;
            this.filter.timePicker = null;
            emitter.emit("clearDataDateRangePicker");
        })

        emitter.on('resetSelectDateTime', (value) => {
            this.sortDateTime = 0;
            this.filter.time = null;
            this.filter.timePicker = null;
            emitter.emit("setValueDefaultDateRange", value);
        })
    }
}
</script>

<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
</style>