<template>
    <div class="customer-tab tab-info">
        <template v-if="!isModal">
            <ActionBar back-url="/customers/index" @action="save()"/>
            <div class="title">

            </div>
        </template>

        <div class="content">
            <div class="row">
                <div class="col-6">
                    <!-- Start name -->
                    <div class="row">
                        <div class="col-3">
                            <b>Tên người dùng<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.name" :errors="errors.name" placeholder="Nhập tên người dùng"/>
                        </div>
                    </div>
                    <!-- End name -->

                    <!-- Start birthday and gender -->
                    <div class="row">
                        <div class="col-3">
                            <b>Tên đăng nhập<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                           <InputText v-model="entry.username" :errors="errors.username" placeholder="Nhập tên đăng nhập"/>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-3">
                            <b>Mật khẩu<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-md-9 form-group">
                            <input class="form-control" type="password" v-model="entry.password" :errors="errors.password" placeholder="Nhập mật khẩu"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>Gõ lại mật khẩu<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9 form-group">
                            <input @input="changePass" class="form-control" type="password" v-model="entry.same_password" :errors="errors.same_password" placeholder="Nhập lại mật khẩu"/>
                        </div>
                        
                    </div>
                    <div class="row" v-if="checkMsg">
                        <div class="col-3">

                        </div>
                        <div class="col-9">
                            <p class='text-danger'>{{ this.msg }}</p>
                        </div>
                    </div>
                    <div class="row mb-3" v-if="!entry.id">
                        <div class="col-3">
                            <b>Vai trò<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <q-select v-model="entry.role_id" :default-options="roles" :multiple="false" placeholder="Chọn vai trò" ref="role"/>
                        </div>
                    </div>

                    <div class="row mb-3">
                        <div class="col-3">
                            <b>{{$t('message.common.branch')}}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <q-select v-model="entry.branch_id" :default-options="branches" :multiple="true" :selectAll="true"  placeholder="Chọn chi nhánh" ref="branch"/>
                        </div>
                    </div>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-3">
                            <b>Điện thoại</b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.phone" :errors="errors.phone" :max=12 placeholder="Số điện thoại" :cusNumber="true" />
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.customerIndex.email')}}<label class="text-danger px-0">*</label></b>
                        </div>
                        <div class="col-9">
                            <InputText v-model="entry.email" :errors="errors.email" placeholder="Nhập email"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-3">
                            <b>{{$t('message.InvoiceIndex.note')}}</b>
                        </div>
                        <div class="col-9">
                            <InputTextArea v-model="entry.note" :placeholder="$t('message.ProductIndex.note')"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import InputText from "@/components/forms/InputText";
import InputNumber from "@/components/forms/InputNumber";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputFileManager from "@/components/forms/InputFileManager";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QImage from "@/components/QImage";
import InputTextArea from "@/components/forms/InputTextArea";
import emitter from "@/lib/emitter";
import InputSelect from "../../../components/forms/InputSelect";
import QSelect from "../../../components/QSelect";
import store from "../../../store";

    export default {
        name: "TabInfo",
        components: {
            InputTextArea,
            QImage,
            InputDatePicker,
            InputFileManager,
            InputSwitchButton,
            InputLocationPicker,
            InputText, ActionBar,
            InputSelect, InputNumber,
            QSelect
        },
        data() {
            const globalConfig = store.state.GlobalConfig;
            return {
                title: '',
                isDataLoaded: false,
                entry: {},
                errors: {},
                roles: globalConfig.roles,
                branches: globalConfig.branches,
                msg: '',
                checkMsg: false
            }
        },
        mounted() {
            
        },
        methods: {
            changePass () {
                if (this.entry.password === this.entry.same_password) 
                {
                    this.checkMsg = false;
                    this.msg = '';
                }
                else {
                    this.checkMsg = true;
                    this.msg = 'Mật khẩu gõ lại không trùng với mật khẩu'
                }
            }
        },
    }
</script>

<style scoped>
</style>
