import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}

const fields = {
    'ID': 'id',
    'Mã hàng hóa': 'code',
    'Tên hàng hóa': 'name',
    'Giá bán': {
        field: 'basePrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Tồn kho min': {
        field: 'quantity_min',
        callback: (value) => {
            return n(value);
        }
    },
    'Tồn kho max': {
        field: 'quantity_max',
        callback: (value) => {
            return n(value);
        }
    },
    'SL trưng bày': {
        field: 'quantity',
        callback: (value) => {
            return n(value);
        }
    },
    'Tồn kho hệ thống': {
        field: 'inventory.quantity',
        callback: (value) => {
            return n(value);
        }
    },
    'Tồn kho thực tế': {
        field: 'actualInventory',
        callback: (value) => {
            return n(value);
        }
    },
    'Khách hàng đặt': {
        field: 'quantity_ordered',
        callback: (value) => {
            return n(value);
        }
    }
};

export default fields;
