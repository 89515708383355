
<template>
    <AppPermission :permission="this.$permissions.medicine.data">
        <div>
            <div class="container my-4">
                <div class="row m-0 p-0">
                    <div class="mainLeft">
                    <!-- <div class="col-md-2" id="col2"> -->
                        <div class="main-title">
                            <span>{{ $t('message.medicine.title') }}</span>
                        </div>
                        <div>
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-8 text-title">
                                        <label>{{ $t('message.common.numberRecord') }}:</label>
                                    </div>
                                    <div class="col-4">
                                        <select :value="numberRecord" style="border: none;outline: none;" id="number_records" class="number_records" @change="onChange($event)">
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mainRight">
                    <!-- <div class="col-md-10" id="col10"> -->
                        <div class="sticky-top">
                            <div class="row">
                                <div class="col-5">
                                    <InputQuickSearch :placeholder="$t('message.medicine.placeholder.search')" @filterSearch="changeQuickSearch" :medicineSearch="true"></InputQuickSearch>
                                </div>
                                <div class="col-md-7 d-flex justify-content-end">
                                    <button type="button" class="btn btn-default" @click="clearFilter"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                    <AppPermission :permission="this.$permissions.medicine.import">
                                        <button type="button" class="btn btn-save ml-2 mx-1" @click="showModalUploadFileExcel">
                                            <i class="fas fa-file-import px-2"></i>
                                            {{$t('message.medicine.import')}}
                                        </button>
                                    </AppPermission>
                                     <AppPermission :permission="this.$permissions.medicine.export">
                                        <button type="button" class="btn btn-primary ml-2 mx-1" @click="exOverview()">
                                            <i class="fa fa-file-export mr-1"></i>
                                            {{$t('message.medicine.export')}}
                                        </button>
                                    </AppPermission>
                                    <div id="loc" v-on:mouseleave="onMouseLeave">
                                         <SelectField :fields="fields" label="medicine"></SelectField>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                            </div>
                            <div class="row mx-0 my-2 overflow-x-hidden" id="divTable">
                                <table class="table table-index table-striped w-100">
                                    <thead>
                                        <tr>
                                            <template v-for="field in fields" :key="field.field">
                                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                    <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style" :title="field.name" class="text-overflow-ellipsis"/>
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="entries?.length > 0">
                                            <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                <tr class="master-row" :class="entry.id === this.cashBookActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                    <template v-for="field in fields" :key="field.field">
                                                        <td class="ow-break-word py-0" v-if="field.selected && field.field " style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                            <a :href="'#row_' + index" class="text-dark tagA">
                                                                <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="text-overflow-ellipsis" :class="field.class"></div>
                                                            </a>
                                                        </td>
                                                    </template>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-if="entries.length == 0 && !this.loading">
                                            <tr>
                                                <td :colspan="5" class="pb-0 bg-light">
                                                    <div class="row detail-prd-row mx-0">
                                                        <div class="col-12 text-center text-secondary mt-5">
                                                            <h3>
                                                                <i class="fa fa-file mr-2 text-secondary"></i>
                                                                <i class="fa fa-exclamation"></i>
                                                            </h3>
                                                        </div>
                                                        <div class="col-12 text-center my-10 text-secondary mb-5">
                                                            <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalImportMedicine ref="ModalImportMedicine" />
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import emitter from "@/lib/emitter";    
    import AppPermission from '@/components/AppPermission.vue';
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import SelectField from "../../components/SelectField";
    import ModalImportMedicine from './ModalImportMedicine.vue';
    import http from '@/services/api';
    
    export default {
        name: "MedicineIndex",
        components: {
            TableSortField,
            Pagination,
            AppPermission,
            InputQuickSearch,
            SelectField,
            ModalImportMedicine,    
    },
        mounted() {
            document.title = this.$t('message.medicine.title_document');
            const query = this.$route.query;
            this.filter = {...this.filter, ...query};
            this.paginate.currentPage = this.$route.query.page || 1;
            this.numberRecord = this.$route.query.numberRecord || "15";
            this.load();
            window.addEventListener('scroll', this.onScroll);
        },
        data() {
            return {
                fields: [],
                entries: [],
                customerGroupList: [],
                customerGroupDetail: {},
                fixed: false,
                params: {
                    keyword: '',
                    status: ''
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                numberRecord: 15,
                flag: true,
                loading: false,

                filterQuery: {
                    keyword: '',
                    status: [],
                    branches: '',
                    createdBy: '',
                    userId: '',
                    type: [],
                    typeTicket: [],
                    orderBy: '',
                    saleChannel: '',
                    paymentMethod: '',
                    priceList: '',
                    branchId: '',
                    time: '',
                    objectFilter: '',
                    objectName: '',
                    objectPhone: '',
                },
                filter: {
                    medicine_name: '',
                    medicine_number: '',
                    medicine_code: '',
                    medicine_content: '',
                    medicine_country: ''
                },
                isFilter: false,
            }
        },
        methods: {
           async exOverview() {           
            emitter.emit("onLoading");
            const res = await http.download('/v1/sync-medicine/export', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});          
            const fileName = res.headers['content-disposition'].split('filename=')[1];
            var _blob = new Blob([res.data], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            var link = document.createElement("a");
            link.href = window.URL.createObjectURL(_blob);
            link.setAttribute('download', fileName);
            link.click();       
            emitter.emit("offLoading");     
        }, 
           async load () {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $get('/v1/sync-medicine/index', {...this.filterQuery, record: this.numberRecord});
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.data.fields;
            }
            this.entries = res.result.data.data.data; 
            this.paginate.totalRecord = res.result.data.data.total;
            this.paginate.currentPage = res.result.data.data.current_page;
            this.paginate.lastPage = res.result.data.data.last_page;
            this.loading = false;
            emitter.emit("offLoading");
           },
           async pageChange(page) {
            const query = clone(this.filterQuery);
            query['page'] = page;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
            },
            showModal(flag) {
                this.$refs.ModalAddCustomer.show(flag);
            },
          
            renderListData(newCustomerGroup) {
                this.customerGroupList = this.customerGroupList.map(customerGroup => customerGroup.id === newCustomerGroup.id ? newCustomerGroup : customerGroup)
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            async search () {
                let query = clone(this.$route.query);
                this.params = {
                    ...this.params,
                }
                const data = await $get('/v1/customer-group', {...this.params, numberRecord: this.numberRecord, query: query});
                // this.load();
                this.customerGroupList = data.data;
                this.paginate = data.paginate;
                this.entries = data.entries;     
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                await this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async clearFilter () {
                await this.$router.replace({
                    path: '/medicine/index',
                    query: {}
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [this.$constant.status_paid_cash_book];
                    }else if(key == 'type'){
                        this.filterQuery[key] = [this.$constant.type_payment_cash_book, this.$constant.type_receipts_cash_book];
                    }else if(key == 'typeTicket'){
                        this.filterQuery[key] = [];
                    }else if(key == 'export'){
                        this.filterQuery[key] = false;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                this.params.keyword = '';
                this.numberRecord = 15;
                this.isFilter = false;
                this.load();
            },
            onScroll() {
                let height = window.scrollY;
                if (height > 100) {
                    this.fixed = true;
                } else {
                    this.fixed = false;
                }
            },
            showModalUploadFileExcel () {
                this.$refs.ModalImportMedicine.show();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
        },
        computed: {
            formatCurrentCies (price) {
               return price.toLocaleString()
            }
        }
    }
</script>
<style scoped>
.custom-desc {
    margin-top: 15px !important;
}
.lstCustomer tr td , .lstCustomer tr th {
    /* min-width: 150px !important;
    min-height: 500px !important; */
    vertical-align: middle !important;
    padding: 10px;
}

.lstCustomer {
    border-collapse: collapse;
    width: 2600px !important;
    /* overflow-x: scroll; */
    max-height: 700px;
    display: block;
    margin-top: -1px;
    margin-right: 0px;
}

.lstCustomer thead th {
    border: none;  
}
.name-customer-group {
    width: 400px;
}
.note-customer-group {
    width: 500px;
}
.discount-customer-group {
    width: 300px;
}
.action {
    width: 300px;
}
tbody {
    /* display: block;
    max-height: 600px;
    overflow: auto; */
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cell-name{
    width: 40% !important;
    min-width: 40%;
    max-width: 40%;
}
.cell-quantity{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
.cell-discount{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-auto{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-check{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
</style>
