<template>
    <div class="row mt-2 detail-prd-row">
        <div class="col-4" v-if="this.permissionCategoryTree && this.permissionCategoryTree.length">
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.headers.list')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12">
                    <QTreeListCustom :items="permissionCategoryTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="this.permissionMajorTree && this.permissionMajorTree.length">
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.common.major')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12">
                    <QTreeListCustom :items="permissionMajorTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="this.permissionReportTree && this.permissionReportTree.length">  
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.headers.reports')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12 px-0">
                    <QTreeListCustom :items="permissionReportTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
    <!-- </div> -->
    <!-- <div class="row mt-2 detail-prd-row"> -->
        <div class="col-4" v-if="this.permissionPromotionTree && this.permissionPromotionTree.length">
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.DetailOrdersCard.promotion')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12">
                    <QTreeListCustom :items="permissionPromotionTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="this.permissionSettingTree && this.permissionSettingTree.length">
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.common.setting')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12">
                    <QTreeListCustom :items="permissionSettingTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="this.permissionDashboardTree && this.permissionDashboardTree.length">  
            <div class="row mt-2 py-2">
                <div class="col-12 text-omi">
                    {{$t('message.headers.dashboard')}}
                </div>
            </div>
            <div class="row mt-2 py-2">
                <div class="col-12 px-0">
                    <QTreeListCustom :items="permissionDashboardTree" :modelValue="arrayChecked" :collapsed="false" @ontreechange="onTreeChange" 
                    :readonly="this.readonly" :onCreate="this.onCreate" :onUpdate="this.onUpdate" :showAction="this.showAction"></QTreeListCustom>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {$post} from "@/lib/utils";
    import QTreeListCustom from "@/components/qtreeselect/QTreeListCustom.vue";
    import emitter from "@/lib/emitter";
    export default {
        name: "TabPermission",
        components: {
            QTreeListCustom 
        },
        props: ['items', 'modelValue', 'defaultValues', 'label', 'readonly', 'onCreate', 'onUpdate', 'showAction'],
        data(){
            return{
                arrayChecked: [],
                permissionCategoryTree: [],
                permissionMajorTree: [],
                permissionReportTree: [],
                permissionPromotionTree: [],
                permissionSettingTree: [],
                permissionDashboardTree: [],
            }
        },
        mounted() {
            this.formatValues(this.defaultValues);
            this.loadPermissionTree();
        },
        methods: {
            async loadPermissionTree(){
                const res = await $post('/v1/roles/getTreePermissions', {});
                if(res){
                    res.forEach(el => {
                        if(el.type && el.type == this.$constant.typePermissionCategory){
                            this.permissionCategoryTree = el.tree;
                        }else if(el.type && el.type == this.$constant.typePermissionMajor){
                            this.permissionMajorTree = el.tree;
                        }else if(el.type && el.type == this.$constant.typePermissionReport){
                            this.permissionReportTree = el.tree;
                        }else if(el.type && el.type == this.$constant.typePermissionPromotion){
                            this.permissionPromotionTree = el.tree;
                        }else if(el.type && el.type == this.$constant.typePermissionSetting){
                            this.permissionSettingTree = el.tree;
                        }else if(el.type && el.type == this.$constant.typePermissionDashboard){
                            this.permissionDashboardTree = el.tree;
                        }
                    });
                }
            },
            onTreeChange(node) {
                let arr = this.arrayChecked;
                if(!node.checked){
                    if(arr.includes(node.id)){
                        arr = arr.filter(item => item !== node.id);
                    }
                }else{
                    if(!arr.includes(node.id)){
                        arr.push(node.id);
                    }
                }
                this.arrayChecked = arr;
                this.$emit('update:modelValue', {values: this.arrayChecked, label: this.label})
            },
            formatValues(values){
                if(typeof values == 'string'){
                    this.arrayChecked = values ? values.split(",") : [];
                }else if(Array.isArray(values) == true){
                    this.arrayChecked = values ? values : [];
                }
                this.arrayChecked = this.arrayChecked.map(Number);
            }
        },
        watch: {
            items: function (newValue) {
                if (newValue) {
                    return newValue;
                }
            },
            defaultValues: function (newData){
                this.formatValues(newData);
            }
        },
        created(){
            emitter.on('removeDataTabPermission', () => {
                this.arrayChecked = [];
            })
        }
    }
</script>

<style lang="scss" scoped>
</style>
