<template>
    <AppPermission :permission="this.$permissions.returnImportForSupplier.data?? '' ">
    <div class="container">
        <div class="row m-0 p-0">
            <div class="mainLeft pt-3">
                <div class="main-title">
                <span>{{$t('message.ReturnPurchaseOrder.title')}}</span>
                </div>
                <div>
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row">
                    <div class="col-8 text-title">
                        <label>{{$t('message.common.numberRecord')}}:</label>
                    </div>
                    <div class="col-4">
                        <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        </select>
                    </div>
                    </div>
                </div>
                </div>
                <!-- Chi nhánh -->
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">{{$t('message.common.branch')}}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-3" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <!-- <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="filter.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"></SelectSearch> -->
                                <q-select v-model="filter.branches" 
                                        :modelValue="filter.branches" 
                                        :default-options="this.branches.all" 
                                        :multiple="true" 
                                        :placeholder="$t('message.common.placeholder.branch')" 
                                        :isFilter="true"
                                        @update:modelValue="updateFilterBranch"
                                        class="border-bottom-1"/>

                            </div>
                        </div>
                    </div>
                </div>

                <!-- Thời gian -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12">
                                <label class="typeTitle">{{$t('message.common.time')}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="time">
                        <div class="row mb-2">
                            <div class="col-1 p-0 d-flex align-items-center">
                                <label class="container-radio ml-2 min-vw-100">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios1" :value="0" class="mr-2" @click="refreshDatePicker(0)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-11">
                                <DateRangePicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangePicker>
                            </div>
                        </div>
                        <div class="row mb-2">
                            <div class="col-1 p-0 d-flex align-items-center">
                                <label class="container-radio ml-2 min-vw-100">
                                    <input type="radio" v-model="sortTime" name="exampleRadios" id="exampleRadios2" :value="1" class="mr-2" @click="refreshDatePicker(1)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                            <div class="col-11">
                                <InputDateRangePicker :sortTimeHeight="true" :defaultDate="true" :placeholder="$t('message.SelectDateTime.otherOption')" class="w-100 mb-0 custom-input"
                                @input="filterTime(filter.createdPicker, 1)" v-model="filter.createdPicker"/>
                                <!-- <i class="far fa-calendar fa-md icon-calendar" aria-hidden="true"></i> -->
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Trạng thái -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">
                                    {{$t('message.common.status')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                    <i class="fa fa-chevron-up" id="statusUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                        <div class="row" v-for="status in this.statusList" :key="status.id">
                            <div class="col-12 d-flex align-items-center">
                            <label class="container-checkbox">{{$t(status.name)}}
                                <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :id="`key${status.id}`" :checked="isExistInArrayChecked(status, this.filter.status)">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">
                                    {{$t('message.OrderSupplierIndex.filter.status_payment')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusPaymentBody', 'statusPaymentDown', 'statusPaymentUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="statusPaymentDown"></i>
                                    <i class="fa fa-chevron-up" id="statusPaymentUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-2" id="statusPaymentBody">
                        <div class="row" v-for="status in this.listStatusPayment" :key="status.id">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{$t(status.name)}}
                                    <input type="checkbox" class="chkType" @click="doFilter('statusPayment', status.id)" :checked="isExistInArrayChecked(status, this.filter.statusPayment)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Người tạo -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">
                                    {{$t('message.common.created_by')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('creatorBody', 'creatorDown', 'creatorUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="creatorDown"></i>
                                    <i class="fa fa-chevron-up" id="creatorUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-3" id="creatorBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.OrderSupplierIndex.filter.placeholder.created_by')" :modelValue="filter.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Người nhập -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label class="typeTitle">
                                    {{$t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by')}}
                                </label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('inputBody', 'inputDown', 'inputUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="inputDown"></i>
                                    <i class="fa fa-chevron-up" id="inputUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-3" id="inputBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.ReturnPurchaseOrder.selectReturnBy')" :modelValue="filter.purchaseBy" :defaultOption="this.purchaseBy.all" @update:modelValue="doFilterSearch" :label="'purchaseBy'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mainRight">
                <div class="sticky-top pt-3">
                    <div class="row">
                        <div class="col-lg-5">
                            <InputQuickSearch :placeholder="$t('message.InputQuickSearch.placeholder.returns_code_return_search')" @filterSearch="changeQuickSearch" :returnPurchaseOrderSearch="true"></InputQuickSearch>
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{$t('message.button.removeFilter')}}
                            </button>
                            <AppPermission :permission="this.$permissions.returnImportForSupplier.export?? '' ">
                            <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                    style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-ellipsis-v px-2"></i>
                                    {{$t('message.button.action')}}
                                </button>
                                
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <!-- <a class="dropdown-item" @click="printMultiple(this.arrayChecked)">
                                        <i class="fa fa-print"></i>
                                        In phiếu
                                    </a>                                    -->
                                    <a class="dropdown-item" @click="exSelectOverview()">
                                        <i class="fa fa-share"></i>
                                        {{$t('message.button.export_all')}}
                                    </a>
                                    <a class="dropdown-item" @click="exSelectDetails()">
                                        <i class="fas fa-file-export"></i>
                                        {{$t('message.button.export_detail')}}
                                    </a>
                                    <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                        <i class="fa fa-share"></i>
                                        {{ $t('message.button.sendMailText') }}
                                    </a>

                                    <a class="dropdown-item" @click="sendZalo()" v-if="this.statusEmail">
                                        <i class="fa fa-share"></i>
                                        {{$t('message.PurchaseOrderIndex.button.sendZalo')}}
                                    </a>
                                </div>
                            </div>
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.returnImportForSupplier.create?? '' ">
                                <button @click="showModalPurchaseOrderCreateUpdate()" class="btn btn-save ml-1">
                                    <i class="fa fa-plus"/> 
                                    {{$t('message.PurchaseOrderIndex.button.return')}}
                                </button>                        
                            </AppPermission>
                            <AppPermission :permission="this.$permissions.returnImportForSupplier.export?? '' ">
                                <div class="dropdown">
                                    <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-file-export px-2" />
                                        {{ $t('message.button.export.name') }}
                                    </button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#" @click="exOverview()">
                                            {{ $t('message.button.file_all') }}    
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="exDetails()">
                                            {{ $t('message.button.file_detail') }}
                                        </a>
                                    </div>
                                </div>
                            </AppPermission>
                            <div id="loc" v-on:mouseleave="onMouseLeave">
                                <SelectField :fields="fields" label="returnPurchaseOrder"></SelectField>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mt-2">
                        <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                            {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                            <a href="javascript:;" @click="removeChecked()">
                                <i class="fa fa-times text-danger" aria-hidden="true"></i>
                            </a>
                        </div>
                    </div>
                    <div class="row mx-0 my-2" v-if="entries.length > 0" id="divTable">
                        <table class="table table-index table-striped lstProduct" >
                            <thead>
                                <tr>
                                    <th scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                    <template v-for="field in fields" :key="field.field">
                                    
                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                        :class="field.class" :name="field.name" :field="field.field" :style="field.style"  />
                                    </th>
                                    </template>
                                </tr>
                            </thead>
                            <tbody>
                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                    <tr class="master-row" :class="entry.id === this.purchaseOrderDetail ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                        <td> <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)"></td>
                                        <template v-for="field in fields" :key="field.field">
                                            <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailPurchaseOrder(entry.id, entry)">
                                                    <div v-if="field.field==='totalQuantity'" :style="field.style" :class="field.class"><span v-text="n(entry.totalQuantity)"></span></div>
                                                    <div v-else-if="field.field==='totalProduct'" :style="field.style" :class="field.class"><span v-text="n(entry.totalProduct)"></span></div>
                                                    <div v-else-if="field.field==='returnSupplier'" :style="field.style" :class="field.class"><span v-text="n(entry.returnSupplier ?? 0)"></span></div>
                                                    <div v-else-if="field.field==='priceDiscount'" :style="field.style" :class="field.class"><span v-text="n(entry.priceDiscount)"></span></div>
                                                    <div v-else-if="field.field==='totalPrice'" :style="field.style" :class="field.class"><span v-text="n(entry.totalPrice)"></span></div>
                                                    <div v-else-if="field.field==='supplierPayment'" :style="field.style" :class="field.class"><span v-text="n(entry.supplierPayment)"></span></div>
                                                    <div v-else-if="field.field==='userId'" :style="field.style" :class="field.class"><span v-text="entry.userName"></span></div>
                                                    <div v-else-if="field.field==='status'" :style="field.style" :class="field.class"><span v-text="entry.statusText"></span></div>
                                                    <div v-else-if="field.field==='note'" :style="field.style" :class="field.class" class="description" :title="entry.note"><span v-text="entry.note"></span></div>
                                                    <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" :class="field.class">
                                                    </div>
                                                </a>
                                            </td>
                                        </template>
                                    </tr>
                                    <tr v-if="purchaseOrderDetail === entry.id" :class="[ entry.id === purchaseOrderDetail ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                        <td></td>
                                        <td :colspan="fields.length" class="pb-0">
                                            <div class="row detail-prd-row">
                                                <div class="col-2 mt-2">
                                                    <span class="backgroup__detail-active head-detail">
                                                        {{$t('message.ProductIndex.info')}}
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                        <td></td>
                                        <td :colspan="fields.length" class="border-top-0">
                                            <div class="mt-2 pb-4 detail__user">
                                                <div class="row mt-2 detail-prd-row">
                                                    <div class="col-4">
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{$t('message.PurchaseOrderIndex.tabs.table.history_return.code')}}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">                                                            
                                                                {{ entry.code }}
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 py-1">
                                                            <div class="col-5 d-flex align-items-center">
                                                                {{$t('message.common.time')}}:
                                                            </div>
                                                            <div class="col-7 ow-break-word d-flex align-items-center">
                                                                <InputDateTimePicker :modelValue="entry.dateTimePicker" @update:modelValue="changeDateTimePicker"
                                                                v-if="entry.status !== this.$constant.statusReturnCancelled"
                                                                />
                                                                 <span v-else>
                                                                {{dt(entry.dateTimePicker)}}
                                                            </span>
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{$t('message.headers.suppliers')}}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">
                                                                <template v-if="entry.supplier">
                                                                    <a :href="'/suppliers/index?keyword=' + entry?.supplier?.code" target="_blank" class="text-decoration-none text-primary">{{ entry?.supplier?.name }}</a>
                                                                </template>
                                                            </div>
                                                        </div>
                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                            <div class="col-5">
                                                                {{$t('message.common.created_by')}}:
                                                            </div>
                                                            <div class="col-7 ow-break-word">
                                                                {{ entry.createdBy }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-4">
                                                        <div class="mx-1">
                                                            <div class="col-12">
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5 d-flex align-items-center">
                                                                        {{$t('message.InputQuickSearch.title.purchase_order')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word text-primary">
                                                                        <span @click="openPurchaseOrder(entry.importForSupplierId)">{{ entry.importForSupplierId }}</span>                                                                                                                                 
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5 d-flex align-items-center">
                                                                        {{$t('message.common.status')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ entry.statusText }}                                                                                                                                 
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.common.branch')}}:
                                                                    </div>
                                                                    <div class="col-7 ow-break-word">
                                                                        {{ entry.branchId }}
                                                                    </div>
                                                                </div>
                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                    <div class="col-5">
                                                                        {{$t('message.PurchaseOrderIndex.tabs.table.history_return.receive_by')}}:
                                                                    </div>                                                                
                                                                    <div class="col-7 ow-break-word">
                                                                        <InputSelectSearch :defaultValue="$t('message.ReturnPurchaseOrder.selectReturnBy')" :modelValue="entry.userId" :defaultOption="this.purchaseBy.all" @update:modelValue="resetValue" 
                                                                            v-if="entry.status !== this.$constant.statusReturnCancelled"
                                                                            />                                                                 
                                                                            <template v-else>
                                                                                <span v-for="n in this.purchaseBy.all" :key="n.id">
                                                                                    {{ n.id == entry.userId ? n.name : '' }}
                                                                                </span> 
                                                                            </template>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <!-- <div class="col-4" style="border-left: 1px solid lightgray"> -->
                                                    <div class="col-4" :id="entry.status == this.$constant.status_cancel_returned_order_supplier ? 'noteRO' : ''">                                                       
                                                        <textarea v-model="entry.note" :id="'note_'+ entry.id" :placeholder="$t('message.PurchaseOrderIndex.note')" class="noteStyle px-4 text-gray font-italic" 
                                                        v-if="entry.status != $constant.status_cancel_returned_order_supplier"
                                                        ></textarea>
                                                        <span class="px-4 text-gray font-italic" v-else style="color:#6c757d">
                                                            {{ entry.note ? entry.note : $t('message.PurchaseOrderIndex.note')}}
                                                        </span>
                                                        <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    
                                    <tr class="detail-row bg-white border__body-detail" v-if="purchaseOrderDetail === entry.id">
                                        <td :colspan="fields.length" class="border-top-0">
                                            <div class="mt-2 pb-4 detail__user">
                                                <div class="row mt-2 detail-prd-row detail-prd-table pl-2">
                                                    <div class="col-12 pl-0">
                                                        <table class="w-100">
                                                            <thead>
                                                                <tr class="backgroup__detail">
                                                                    <th scope="col" class="pl-4" v-for="fieldDetail in this.fieldOrderSupplierDetail" :key="fieldDetail" v-text="$t(fieldDetail.name)"></th>
                                                                </tr>
                                                                
                                                            </thead>
                                                            <tbody>
                                                                <tr class="bg-white">
                                                                    <th scope="col" class="pl-4"> 
                                                                        <input type="text" v-model="this.defaultPurchaseOrder.searchCode" @input="onInputFilterDetailOrderSupplier('searchCode')" :placeholder="$t('message.DetailOrdersCard.searchProductCode')" class="border-none bg-transparent h-100">
                                                                    </th>
                                                                    <th scope="col" :colspan="fieldOrderSupplierDetail.length - 1" class="pl-4"> 
                                                                        <input type="text" v-model="this.defaultPurchaseOrder.searchName" @input="onInputFilterDetailOrderSupplier('searchName')" :placeholder="$t('message.DetailOrdersCard.searchProductName')" class="border-none bg-transparent h-100">
                                                                    </th>
                                                                </tr>
                                                                <template v-if="this.defaultPurchaseOrder.filter?.length > 0">
                                                                    <template v-for="(purchaseOrderDetail, index) in this.defaultPurchaseOrder.filter" :key="purchaseOrderDetail.id">
                                                                        <template v-if="index >= (this.paginateDetail.currentPage - 1)*10 && index < this.paginateDetail.currentPage*10">
                                                                            <tr :class="index % 2 ? 'bg-white' : 'bg-light'">
                                                                                    <td class="pl-4">
                                                                                        <a :href="'/products/index?code=' + purchaseOrderDetail.product?.code" target="_blank" class="text-decoration-none text-primary">{{purchaseOrderDetail.product?.code}}</a>
                                                                                    </td>
                                                                                    <td class="pl-4">
                                                                                        <span v-text="purchaseOrderDetail.product?.name"></span>
                                                                                        <span class="col-12 description font-italic text-gray" v-text="purchaseOrderDetail.note" :title="purchaseOrderDetail.note"></span>
                                                                                    </td>
                                                                                    <td v-text="purchaseOrderDetail.unit ? purchaseOrderDetail.unit.unitName : ''" class="pl-4 text-left"></td>
                                                                                    <td v-text="purchaseOrderDetail.attributeName ? purchaseOrderDetail.attributeName : ''" class="pl-4 text-left"></td>
                                                                                    <td v-text="n(purchaseOrderDetail.quantity)" class="pl-4"></td>
                                                                                    <td v-text="n(purchaseOrderDetail.price ? +purchaseOrderDetail.price + (+purchaseOrderDetail.discount ? +purchaseOrderDetail.discount / +purchaseOrderDetail.quantity : 0 ) : 0 )" class="pl-4"></td>
                                                                                    <td v-text="n(purchaseOrderDetail.price ?? 0)" class="pl-4"></td>
                                                                                    <td v-text="n(purchaseOrderDetail.discount / purchaseOrderDetail.quantity)" class="pl-4"></td>
                                                                                    <td v-text="n(purchaseOrderDetail.subTotal)" class="pl-4"></td>
                                                                                
                                                                            </tr>
                                                                            <template v-if="purchaseOrderDetail.consignments">
                                                                                <tr class="bg-white" v-for="(value, indexConsignment) in purchaseOrderDetail.consignments" :key="indexConsignment">
                                                                                    <td class="pl-4"></td>
                                                                                    <td class="pl-4">
                                                                                        <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                    </td>
                                                                                    <td class="pl-4"></td>
                                                                                    <td :colspan="5"></td>
                                                                                </tr>
                                                                            </template>
                                                                            <!-- Plus -->
                                                                            <template v-if="purchaseOrderDetail.plus && purchaseOrderDetail.plus?.length > 0">
                                                                                <template v-for="(plusItem, indexPlus) in purchaseOrderDetail.plus" :key="indexPlus">
                                                                                    <tr :class="indexPlus % 2 ? 'bg-white' : 'bg-light'">
                                                                                        <td class="pl-4">
                                                                                            <a :href="'/products/index?code=' + plusItem.product?.code" target="_blank" class="text-decoration-none text-primary">{{purchaseOrderDetail.product?.code}}</a>
                                                                                        </td>
                                                                                        <td class="pl-4">
                                                                                            <span v-text="plusItem.product?.name"></span>
                                                                                            <span class="col-12 description font-italic text-gray" v-text="plusItem.note" :title="purchaseOrderDetail.note"></span>
                                                                                        </td>
                                                                                        <td v-text="plusItem.unit ? plusItem.unit.unitName : ''" class="pl-4 text-left"></td>
                                                                                         <td v-text="plusItem.attributeName ? plusItem.attributeName : ''" class="pl-4 text-left"></td>
                                                                                        <td v-text="n(plusItem.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.price ? +plusItem.price + (plusItem.discount ? (+plusItem.discount / +plusItem.quantity) : 0 ) : 0 )" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.price ?? 0)" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.discount / plusItem.quantity)" class="pl-4"></td>
                                                                                        <td v-text="n(plusItem.subTotal)" class="pl-4"></td>
                                                                                    </tr>
                                                                                    <template v-if="plusItem.consignments">
                                                                                        <tr class="bg-white" v-for="(value, indexConsignment) in plusItem.consignments" :key="indexConsignment">
                                                                                            <td class="pl-4"></td>
                                                                                            <td class="pl-4">
                                                                                                <span class="consignment_name mr-2 text-white">{{ value.name + ' - ' + d(value.expried) + ' - ' + $t('message.common.shortQuantity') + ':' + value.quantity}}</span>
                                                                                            </td>
                                                                                            <td class="pl-4"></td>
                                                                                            <td :colspan="5"></td>
                                                                                        </tr>
                                                                                    </template>
                                                                                </template>
                                                                            </template>
                                                                        </template>
                                                                    </template>
                                                                </template>

                                                                <template v-else>
                                                                    <tr class="text-center bg-light">
                                                                        <td :colspan="fieldOrderSupplierDetail.length">
                                                                            {{$t('message.common.notFound')}}
                                                                        </td>
                                                                    </tr>
                                                                </template>
                                                            </tbody>
                                                        </table>
                                                        <Pagination :value="paginateDetail" :record="10" v-if="this.paginateDetail.totalRecord >= 10"/>
                                                    </div>
                                                </div>
                                                <div class="row detail-prd-row mt-4 pr-4">
                                                    <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_quantity') }}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.totalQuantity ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row mt-2 pr-4">
                                                    <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.total_products') }}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.totalProduct ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row mt-2 pr-4">
                                                    <div class="col-10 flex-end">{{$t('message.ReturnPurchaseOrder.totalAmountReturn')}}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.totalPrice ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row mt-2 pr-4">
                                                    <div class="col-10 flex-end">{{ $t('message.OrderSupplierIndex.discount') }}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.priceDiscount ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row mt-2 pr-4">
                                                    <div class="col-10 flex-end">{{$t('message.ReturnPurchaseOrder.supplierNeedToPay')}}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.returnSupplier ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row mt-2 pr-4">
                                                    <div class="col-10 flex-end">{{$t('message.ReturnPurchaseOrder.supplierPaid')}}:</div>
                                                    <div class="col-2 flex-end">{{n(entry.supplierPayment ?? 0)}}</div>
                                                </div>
                                                <div class="row detail-prd-row flex-end mt-4 pr-4" v-if="entry.status !== 4">
                                                    <button type="button" class="btn btn-save mx-1" v-if="this.statusEmail" @click="sendZalo(entry)">
                                                                <i class="fas fa-sms"></i>
                                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                                        </button>
                                                     <AppPermission :permission="this.$permissions.returnImportForSupplier.update?? '' ">
                                                        <button type="button" class="btn btn-save mx-1" @click="updateChange(entry.id, entry.code)" v-if="entry.status != $constant.status_cancel_returned_order_supplier">
                                                            <i class="fa fa-save mr-1"></i>
                                                            {{$t('message.button.save')}}
                                                        </button>
                                                    </AppPermission>
                                                     <AppPermission :permission="this.$permissions.returnImportForSupplier.update?? '' ">
                                                        <button type="button" class="btn btn-save mx-1" v-if="entry.status == $constant.status_draft" @click="editReturn(entry.id)">
                                                            <i class="fa fa-share mr-1"></i>
                                                            {{ $t('message.OrderSupplierIndex.button.show') }}
                                                        </button>
                                                    </AppPermission>
                                                    <button type="button" class="btn btn-secondary text-white mx-1" @click="print(entry)">
                                                        <i class="fa fa-print mr-1"></i>
                                                        {{$t('message.button.print.name')}}
                                                    </button>
                                                    <!-- <button type="button" class="btn btn-save mx-1">
                                                        <i class="fa fa-share"></i>
                                                        Trả hàng nhập
                                                    </button> -->
                                                     <AppPermission :permission="this.$permissions.returnImportForSupplier.export?? '' ">
                                                        <button type="button" class="btn btn-secondary text-white mx-1" @click="exportReturnById(entry.id)">
                                                            <i class="fa fa-file-export mr-1"></i>
                                                            {{$t('message.button.export.name')}}
                                                        </button>
                                                    </AppPermission>
                                                    <!-- <button type="button" class="btn btn-save mx-1" @click="copyReturnById(entry.id)">
                                                        <i class="fa fa-copy mr-1"></i>
                                                        Sao chép
                                                    </button> -->
                                                     <AppPermission :permission="this.$permissions.returnImportForSupplier.cancel?? '' ">
                                                        <button type="button" class="btn btn-danger mx-1" @click="showModalReturnAbort(entry.id, entry.code)" v-if="entry.status != $constant.status_cancel_returned_order_supplier && entry.status != $constant.status_paid">
                                                            <i class="fa fa-trash-alt mr-1"></i>
                                                            {{$t('message.button.cancel')}}
                                                        </button>                                                   
                                                    </AppPermission>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr class="detail-row bg-white border__footer-detail" v-if="purchaseOrderDetail === entry.id"></tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                    <div class="row ml-auto mr-auto" v-if="entries.length == 0 && !this.loading" id="notFound">
                        <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                            <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                            <!-- <p>{{$t('message.common.notFound')}}</p> -->
                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalReturnAbort ref="ModalReturnAbort" @inputData="load()"/>
        <PrintTemplate ref="PrintTemplate" v-show="false" />    
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </div>
    </AppPermission>
</template>
<script>
import {$alert, $get, $post, clone, setArray, getSearchQuery, explode, removeVietnameseTones} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import DateRangePicker from "@/components/DateRangePicker";
import store from "../../store";
import InputDatePicker from "@/components/forms/InputDatePicker";
import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import InputDateTimePicker from "@/components/forms/InputDateTimePicker";
import SelectField from "../../components/SelectField";
import emitter from "@/lib/emitter";
import router from "@/router/index";
import http from '@/services/api';
import ModalReturnAbort from './tabs/ModalReturnAbort';
import toastr from 'toastr';
import moment from 'moment';
import SelectSearch from "@/components/SelectSearch.vue";
import PrintTemplate from '@/components/PrintTemplate';
import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate.vue';
import orderForSupplierMixin from "@/orderForSupplierMixin";
import AppPermission from '@/components/AppPermission.vue';
import exportMixin from '@/exportMixin';
import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue';
import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
import QSelect from '@/components/QSelect';
import { mapGetters } from "vuex";
export default {
    name: "ReturnPurchaseOrderIndex",
    mixins: [orderForSupplierMixin, exportMixin],
    components: {
        ModalSelectSendZaloGroupTemplate,
        QSelect, InputDateRangePicker, SelectSearch, TableSortField, Pagination, InputDatePicker, InputQuickSearch, DateRangePicker, SelectField,ModalReturnAbort, InputSelectSearch, InputDateTimePicker,
        PrintTemplate,ModalSelectPrintTemplate,AppPermission, ModalSelectSendMailAndZaloTemplate
    },
    mounted() {
        const fields = localStorage.getItem('returnPurchaseOrderSelectedFields_' + this.user.id);
        if (fields) {
            this.fields = JSON.parse(fields);
        }
        const query = clone(this.$route.query);
        if(query['return_purchase_order'] != null){
            this.loadDetail();
        }else{
            this.clearFilter();
        }
        window.addEventListener('scroll', this.onScroll);
    },
    data() {
        const query = getSearchQuery();
        const globalConfig = store.state.GlobalConfig;
        return {
            currentBranchID: store.state.CurrentBranch.id,
            loading: false,
            user: store.state.Auth.user,
            actionType: query.action,
            filter: {
                keyword: query.keyword,
                created: null,
                createdPicker: null,
                status: [],
                branches: explode(query.branches),
                suppliers: explode(query.suppliers),
                createdBy: explode(query.createdBy),
                purchaseBy: explode(query.purchaseBy),
                action: explode(query.action),
                return_purchase_order: '',
                purchase_order: '',
                product_code_name: '',
                supplier_code_name: '',
                note: '',
                note_product: '',
                statusPayment: []
            },
            statusList: this.$constant.listStatusReturnOrderSupplier,
            checkedAll: false,
            fields: [],
            entries: [],
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: parseInt(query.page) || 1,
                lastPage: 1
            },
            paginateDetail: {
                currentPage: 1,
                lastPage: 1,
                totalRecord: 1,
            },
            numberRecord: 15,
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },
            purchaseBy: {
                filter: globalConfig.users,
                all: globalConfig.users,
                search: null,
            },
            createdBy: {
                filter: globalConfig.users,
                all: globalConfig.users,
                search: null,
            },
            fixed: false,
            arrayChecked: [],
            purchaseOrderDetail: null,
            fieldOrderSupplierDetail : [
                { name: 'message.DetailOrdersCard.prodCode', field: 'code', },
                { name: 'message.DetailOrdersCard.prodName', field: 'name', },
                { name: 'message.DetailOrdersCard.prodUnit', field: 'unitName', },
                { name: 'message.DetailOrdersCard.attribute', field: 'attributeName', },
                { name: 'message.DetailOrdersCard.prodQuantity', field: 'quantity', },
                { name: 'message.DetailOrdersCard.prodImportPrice', field: 'price', },
                { name: 'message.ReturnPurchaseOrder.returnPrice', field: 'returnPrice', },
                { name: 'message.ReturnPurchaseOrder.returnDiscount', field: 'discount', },
                { name: 'message.DetailOrdersCard.totalPrice', field: 'subTotal', },
            ],
            defaultPurchaseOrder:{
                filter: null,
                all: null,
                searchCode: '',
                searchName: '',
            },
            sortTime: 0,
            listStatusPayment: this.$constant.listStatusPayment,
            isFilter: false,
        }
    },
    computed: {
        userBranchList: () => store.state.GlobalConfig?.userBranches ?? [],
        ...mapGetters({
            statusEmail: 'settingStore/email',
        }),
    },
    methods: {
        updateFilterBranch(values) {
            this.doFilterSearch({
                label: 'branches',
                model: (values && values.length) ? values.toString() : '',
            });
        },
        async sendMailAndZalo() {
            this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabReturnSuppliers, this.$constant.typeImportProducts, this.arrayChecked);
        },
        editReturn(id) {
            this.$router.push({name: 'ReturnSupplierUpdate' , query: {id}});
        },

        async sendZalo (entry = null) {
                if (entry) {
                    this.arrayChecked = [entry.id];
                }
                const typeSend = entry ? this.$constant.typeSendZaloZNS : this.$constant.typeSendZaloGroup;
                const phone = entry && entry.supplier ? entry.supplier.contactNumber : null;
                this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabReturnSuppliers, this.$constant.typeImportProducts, this.arrayChecked, typeSend, phone);
            }, 
        async changeQuickSearch(object){
            const query = clone(this.$route.query);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filter[key] = obj[o];
                            query[o] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete query[o];
                        }
                    });
                })
            });
            query['page'] = 1;
            this.isFilter = true;
            await this.$router.push({query: query});
            this.load();
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/purchase-orders/return',
                query: {
                    status: '0,1',
                    branches: store.state.CurrentBranch.id,
                }
            });

            Object.keys(this.filter).forEach(key => {
                if(key == 'status'){
                    this.filter[key] = [0,1];
                }else if(key == 'statusPayment'){
                    this.filter[key] = [0,1];
                }else if(key == 'branches'){
                    this.filter[key] = [store.state.CurrentBranch.id];
                }else{
                    this.filter[key] = '';
                }
            });
            emitter.emit("clearQuickSearch");
            emitter.emit("clearDateRangePicker");
            emitter.emit("clearSelectSearchValue");
            this.arrayChecked = [];
            this.branches.search = null;
            this.purchaseBy.search = null;
            this.createdBy.search = null;
            this.sortTime = 0;
            this.numberRecord = 15;
            this.purchaseOrderDetail = null;
            this.isFilter = false;
            this.load();
        },
        getCheckedIDs() {
            return this.entries.filter(e => e.checked).map(e => e.id);
        },
        async filterTime(time = null, option){
            const query = clone(this.$route.query);
            delete query['created'];
            if(time){
                query['created'] = time;
                query['page'] = 1;
                if(option == 1){
                    this.sortTime = 1;
                    this.filter.createdPicker = time;
                }else{
                    this.sortTime = 0;
                    this.filter.created = time;
                }
            }
            this.isFilter = true;
            await this.$router.push({query: query});
            this.load();
        },
        async doFilterSearch(v){
            const query = clone(this.$route.query);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async doFilter(field, value, name) {
            const query = clone(this.$route.query);
            if(field == 'status'){
                let arr = this.filter.status;
                if(arr.includes(value)){
                    arr = arr.filter(item => item !== value)
                }else{
                    arr.push(value)
                }
                this.filter.status = arr;
                if (Object.keys(arr).length === 0) {
                    delete query[field];
                }else{
                    query[field] = arr.toString();
                }
            }else if(field == 'statusPayment'){
                let arr = this.filter.statusPayment;
                if(arr.includes(value)){
                    arr = arr.filter(item => item !== value)
                }else{
                    arr.push(value)
                }
                this.filter.statusPayment = arr;
                if (Object.keys(arr).length === 0) {
                    delete query[field];
                }else{
                    query[field] = arr.toString();
                }
            }else{
                if(query[field] == value){
                    delete query[field];
                    name = null;
                }else{
                    query[field] = value;
                }
            }
            query['page'] = 1;
            await this.$router.push({query: query});
            switch (field) {
                case 'branches':
                    this.branches.search = name;
                    break;
                case 'purchaseBy':
                    this.purchaseBy.search = name;
                    break;
                case 'createdBy':
                    this.createdBy.search = name;
                    break;
                default:
                    break;
            }
            this.isFilter = true;
            this.load();
            this.purchaseOrderDetail = null;
        },
        async sort(field, direction) {
            this.sortData = {
                field, direction
            };

            const query = clone(this.$route.query);
            query.sort_field = field;
            query.sort_direction = direction;

            await this.$router.push({query: query});
            this.load();


        },
        async pageChange(page) {
            const query = clone(this.$route.query);
            query['page'] = page;
            query['action'] = this.actionType;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.$route.query);
            query['page']=1;
            query.numberRecord = v.target.value;
            await this.$router.push({query: query});
            this.isFilter = true;
            this.load();
        },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post('/v1/purchase-orders/return', {...this.$route.query, numberRecord: this.numberRecord});
            if (res.status.code != 200) {
                $alert(res);
                return;
            }
            //Phục vụ cho export tổng quang không qua phân trang
            // this.exEntries = JSON.parse(res.result.exEntries)?? null;     
            setArray(res.result.data.data, {
                checked: false
            });
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.fields;
            }
            // if (res.result.listStatusPayment) {
            //     this.listStatusPayment = res.result.listStatusPayment;
            // }
            this.pageTitle = res.result.title;
            document.title = this.pageTitle;

            this.paginate.totalRecord = res.result.data.total;
            this.paginate.currentPage = res.result.data.current_page;
            this.paginate.lastPage = res.result.data.last_page;
            this.entries = res.result.data.data;
            this.entries.forEach(item => {
                item.dateTimePicker = item.returnDate;
                item.created_at =  item.created_at ? moment(item.created_at).format('DD/MM/YYYY HH:mm'): null;
                item.returnDate = item.returnDate ? moment(item.returnDate).format('DD/MM/YYYY HH:mm'): null;
            }); 
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
        },
        async loadDetail(){
            emitter.emit("clearQuickSearch");
            emitter.emit("clearDateRangePicker");
            emitter.emit("clearSelectSearchValue");
            this.arrayChecked = [];
            this.branches.search = null;
            this.purchaseBy.search = null;
            this.createdBy.search = null;
            this.sortTime = 0;
            this.numberRecord = 15;
            await this.load();
            this.entries.forEach((entry) => {
                this.detailPurchaseOrder(entry.id, entry);
            });
        },
        getPriceImport(id, importForSupplier){
            let price = 0;
            if(importForSupplier.details){
                importForSupplier.details.forEach(detail => {
                    if(detail.productId == id){
                        price = detail.price - (detail.discount / detail.quantity);
                    }
                });
            }
            return price;
        },
        detailPurchaseOrder(id, entry){
            if (id === this.purchaseOrderDetail) {
                this.purchaseOrderDetail = null;
            } else {
                this.purchaseOrderDetail = id;
            }
            this.defaultPurchaseOrder.filter = this.defaultPurchaseOrder.all = entry.details;
            this.setPaginate();
        },
        onInputFilterDetailOrderSupplier(field){
            this.paginateDetail.currentPage = 1;
            if(this.defaultPurchaseOrder.searchCode.length == 0 && this.defaultPurchaseOrder.searchName.length == 0){
                this.defaultPurchaseOrder.filter = this.defaultPurchaseOrder.all;
                this.setPaginate();
                return;
            }
            let arr = [];
            switch (field) {
                case 'searchCode':
                    for (let index = 0; index < this.defaultPurchaseOrder.all.length; index++) {
                        if (this.defaultPurchaseOrder.all[index] != null && removeVietnameseTones(this.defaultPurchaseOrder.all[index].product?.code.toLowerCase()).indexOf(removeVietnameseTones(this.defaultPurchaseOrder.searchCode.toLowerCase())) > -1){
                            arr.push(this.defaultPurchaseOrder.all[index]);
                        }
                    }
                    break;
                case 'searchName':
                    for (let index = 0; index < this.defaultPurchaseOrder.all.length; index++) {
                        if (this.defaultPurchaseOrder.all[index] != null && removeVietnameseTones(this.defaultPurchaseOrder.all[index].product?.name.toLowerCase()).indexOf(removeVietnameseTones(this.defaultPurchaseOrder.searchName.toLowerCase())) > -1){
                            arr.push(this.defaultPurchaseOrder.all[index]);
                        }
                    }
                    break;
            }
            this.defaultPurchaseOrder.filter = arr;
            this.setPaginate();
        },
        setPaginate(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.defaultPurchaseOrder.filter.length / 10);
            this.paginateDetail.totalRecord = this.defaultPurchaseOrder.filter.length;
        },
        isCheckedAll(){
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.entries.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        onCheckedAll(value) {
            setArray(this.entries, {
                checked: value
            });
            if(value == true){
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        showHide(name,iDown, iUp){
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        onScroll() {
            let height = window.scrollY;
            if(height > 120){
                //Giữ vị trí cho thông báo lỗi    
                this.fixed = true;
            }else{
                this.fixed = false;
            }
            emitter.emit("position", 0);
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },
        showModalPurchaseOrderCreateUpdate(){
            // router.push({ name: 'ImportSupplierCreate'});
            router.push({ name: 'ReturnSupplierCreate'});
        },
        timeChange(time, entry){                        
            entry.orderTime = time;
            this.orderTime = time;
        }, 
        //Export
        async exSelectOverview() {
            emitter.emit("onLoading");
            let id = this.arrayChecked;
            const res = await http.download('/v1/purchase-orders/return/exOverview', {fields:this.fields, id:id, branchID: this.currentBranchID});
            this.returnDataExport(res);
        },
        async exSelectDetails() {
            emitter.emit("onLoading");
            let id = this.arrayChecked;
            const res = await http.download('/v1/purchase-orders/return/exDetails', {id:id, branchID: this.currentBranchID});
            this.returnDataExport(res);
        },
        getOSByID(entries){                
                return entries;
        },
        async exOverview() {
            emitter.emit("onLoading");
            // let id = this.getOSByID(this.exEntries);
            const res = await http.download('/v1/purchase-orders/return/exOverview', {fields:this.fields, query: {...this.$route.query}, branchID: this.currentBranchID});
            this.returnDataExport(res);
        },
        async exDetails() {
            emitter.emit("onLoading");
            // let id = this.getOSByID(this.exEntries);
            const res = await http.download('/v1/purchase-orders/return/exDetails', {
                // id:id, 
                branchID: this.currentBranchID,
                query: {...this.$route.query}
            });
            this.returnDataExport(res);
        },
        async exportReturnById(id){
            emitter.emit("onLoading");
            const res = await http.download('/v1/purchase-orders/return/exPOById/' + id, {...this.fields, branchID: this.currentBranchID});
            this.returnDataExport(res);
        },
        showModalReturnAbort(id, code){            
            this.$refs.ModalReturnAbort.show(id, code)
        },
      
        async copyReturnById(id){
                router.push({ name: 'ReturnSupplierCreate', query: { id: id, type: 'copy' }});               
        },
        changeDateTimePicker(time){
            this.entries.forEach(entry => {
                if(entry.id == this.purchaseOrderDetail){
                    entry.dateTimePicker = time;
                }
            });
        },
        resetValue(uid){
            this.entries.forEach(entry => {
                if(entry.id === this.purchaseOrderDetail){
                    entry.userId = uid;
                }
            })
        },
        async updateChange(id,code){
            let uid, dateTime;
            this.entries.forEach(entry => {
                if(entry.id === id){
                    uid = entry.userId;
                    dateTime = entry.dateTimePicker;
                }
            })               
                            
            let note = document.getElementById('note_' + id).value;
              let data = {
                    returnDate: dateTime,
                    userId: uid,
                    note: note
                }      
            const res = await $post('/v1/purchase-orders/return/updateList/' + id, data);            
            if(res.status.code == 200) {                    
                toastr.success(this.$t('message.ReturnPurchaseOrder.updateSuccess', {code: code}));
                this.load();
            }
            else {
               if(res.code == 422){
                    if(res.messages.errors.orderDate) toastr.error(res.messages.errors.orderDate);
                    if(res.messages.errors.expectedAt) toastr.error(res.messages.errors.expectedAt);
                    if(res.messages.errors.returnDate) toastr.error(res.messages.errors.importDate);
                    if(res.messages.errors.note) toastr.error(res.messages.errors.note);
                }   
            }
        },
        refreshDatePicker(option){
            switch (option) {
                case 0:
                    this.filterTime(this.filter.created);
                    break;
                case 1:
                    this.filterTime(this.filter.createdPicker, 1);
                    break;
            }
        },

        // In phiếu
        //----------------------------------------------------------------------------------------
        async printTemplate(entry) {                       
            let dataPrint = await this.dataPrint(entry);
            this.editorDataClone = await this.renderData(dataPrint);
            await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);
        },
        async updateContent(data){ 
            this.editorDataClone = '';
            this.content = data.content;                                                 
            if(data.dataPrint && Array.isArray(data.dataPrint)){                
                let breakPage = `<div style="page-break-before: always"></div>`
                for (let index = 0; index < data.dataPrint.length; index++) {
                        const print = this.formatproductPrintCommon(data.dataPrint[index], this.$constant.type_print_multiple); 
                        // const print = data.dataPrint[index];
                        print.typePrint = this.$constant.TypeReturnSuppliers;  
                        let dataPrint = await this.dataPrint(print);
                        let orderItem = await this.renderData(dataPrint);
                        
                        if (index != data.dataPrint.length - 1) {
                            this.editorDataClone = this.editorDataClone + orderItem + breakPage;
                        } else {
                            this.editorDataClone = this.editorDataClone + orderItem;
                        }
                    }                 
                                    
                if(this.editorDataClone!== null) await this.$refs.PrintTemplate.multiplePrint(this.editorDataClone);         
            }
            else{
                let dataPrint = await this.formatproductPrintCommon(data.dataPrint, this.$constant.type_print_single);
                await this.printTemplate(dataPrint);
            }
        },
        async printMultiple(ids = null){
            //Lấy data theo mang ids
            const res = await $post('/v1/purchase-orders/return/data-return-by-ids', {
                    ids: ids ?? this.arrayChecked,
            });                
            if(res && res.status.code == 200 && res.result.length > 0) {
                await this.print(res.result);                
            }
        },
        async print(entry){                     
            // Chọn mẫu in
            entry.typePrint = this.$constant.TypeReturnSuppliers;  
            const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: 'TabReturnSuppliers'});                 
            if(res && res.status.code == 200){
                if(res.result) {                    
                    this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                }
            }                                                             
        },
        openPurchaseOrder(code){
            if(code){
                var route = null;
                route = this.$router.resolve({
                    path: '/purchase-orders/index',
                    query: {
                        'purchase_order': code,
                    }
                });
                window.open(route.href, '_blank');
            }
        },
    }
}
</script>
<style scoped>

select option {
    border-color: none !important;
    box-shadow: none !important;
    outline: 0 none !important;
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.far, .fa-clock {     
    position: absolute;
    right: 16px;     
    top: 11px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;     
    -moz-osx-font-smoothing: grayscale;
}
.drp-menu{
    position: absolute;
    left:-125px;
    top:-102px;
}
 .noteStyle {
        width: 280px;
        height: 100% !important;
        border: 0;
        border-left: 1px solid #ccc;
        border-radius: 0;
        
    }
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
 #fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
 }
 .detail-prd-table tbody td{
    z-index: 100 !important
 }
 #noteRO {
     border-left: 1px solid #ccc;
    color:#000;
 }
</style>
