<template>
    <div id="header">
        <div class="row header-menu-top align-items-center pb-0 mx-0">
            <div class="container">
                <div class="row">
                    <div class="col-6 left d-flex align-items-center">
                        <h1 class="logo-title">
                            <a class="logo-title__link" href="/">
                                OMIPOS
                            </a>
                        </h1>
                        <!-- <a class="btn-icon-tab ml-4 text-decoration-none">
                            <i class="fas fa-home m-0 text-dark"></i>
                        </a>
                        <a class="btn-icon-tab text-decoration-none">
                            <i class="fas fa-box m-0 text-dark"></i>
                        </a> -->
                        <a class="btn-icon-tab active text-decoration-none" href="/sale-online/e-commerce/list">
                            <i class="fas fa-cog m-0 text-dark"></i>
                        </a>
                    </div>
                    <div class="col-6 right d-flex align-items-center justify-content-end">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <div class="collapse navbar-collapse" id="navbarTopContent">
                                <ul class="navbar-nav ml-auto">
                                    <!-- <li class="nav-item">
                                        <a class="nav-link text-dark btn-icon btn-circle btn-icon-gray" href="#" role="button" aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0">
                                            <i class="fas fa-bell m-0"></i>
                                            <span class="badge badge-danger"></span>
                                        </a>    
                                    </li> -->
                                    <li class="nav-item">
                                        <a class="nav-link text-dark btn-icon btn-circle btn-icon-gray" href="#" role="button" aria-haspopup="true" aria-expanded="false" style="padding-bottom: 0">
                                            <i class="fas fa-sync-alt m-0"></i>
                                            <span class="badge badge-danger"></span>
                                        </a>    
                                    </li>
                                    <!-- <li class="nav-item">
                                        <a class="nav-link text-dark" href="javascript:void(0);" role="button" aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            Bán online <i class="fas fa-shopping-cart"></i>
                                        </a>
                                    </li> -->
                                    <li class="nav-item dropdown">
                                        <a class="nav-link text-dark dropdown-toggle mr-0" href="javascript:void(0);" id="branchDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" :data-id="currentBranch?.id">
                                            {{currentBranch?.name}} <i class="fas fa-map-marker-alt"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu__branches" aria-labelledby="branchDropdown" style="margin-top: 0">
                                            <a v-for="branch in branches"
                                            :key="branch.id"
                                            @click.prevent="setBranch(branch)"
                                            class="dropdown-item" href="javascript:void(0);">{{ branch.name }}</a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link text-dark dropdown-toggle" href="javascript:void(0);" id="navbarDropdown" role="button"
                                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" style="margin-right: 0">
                                            {{user?.name}} <i class="far fa-user-circle"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown" style="margin-top: 0">
                                            <!-- <a class="dropdown-item" :href="'/users/form?id=' +user?.id"><i class="far fa-user mr-2"></i> Tài khoản</a> -->
                                            <!-- <div class="dropdown-divider"></div> -->
                                            <a class="dropdown-item" href="javascript:void(0);" @click.prevent="logout()">
                                                <i class="fas fa-sign-out-alt mr-2"></i> 
                                                {{$t('message.headers.logout')}}
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link p-0" href="#" id="languageDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="margin-right: 0">
                                            <img :src="getSrcFlagLanguage(this.isLang)" alt="" class="rounded-circle" 
                                                style="height: 30px; width: 30px; border: 2px solid #fff; object-fit: cover"/>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-image-lang" aria-labelledby="languageDropdown" style="margin-top: 0; min-width: 0px !important;">
                                            <a className="dropdown-item image-lang px-0" @click="setLocale(lang)" v-for="(lang, i) in this.$constant.languages" :key="`Lang${i}`">
                                                <img :src="getSrcFlagLanguage(lang)"/>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
  <ModalReportTransfer ref="modalReportTransfer"></ModalReportTransfer>
    <ModalProductInvoiceReport ref="modalProductInvoiceReport"></ModalProductInvoiceReport>
    <ModalProductBatchReport ref="modalProductBatchReport"></ModalProductBatchReport>
</template>

<script>
import store from "../store";
import PermissionLink from "@/components/PermissionLink";
import ModalReportTransfer from "@/views/reports/ModalReportTransfer";
import ModalProductBatchReport from "@/views/reports/ModalProductBatchReport";
import ModalProductInvoiceReport from "@/views/reports/ModalProductInvoiceReport";
import { onChangeLanguage } from '@/setup/i18n.js';
export default {
    name: "HeaderSaleOnline",
    components: {ModalProductInvoiceReport, ModalProductBatchReport, ModalReportTransfer, PermissionLink},
    data() {
        return {
            isLang: localStorage.getItem('language') ?? 'vi',
            flagLanguages: [
                {id: 'vi', src: require('../../public/img/vi-lang.png')},
                {id: 'en', src: require('../../public/img/en-lang.png')},
                {id: 'ja', src: require('../../public/img/ja-lang.png')}
            ]
        }
    },
    computed: {
        currentBranch: () =>  store.state.CurrentBranch,
        branches: () =>  store.state.GlobalConfig?.userBranches,
        user: () => store.state.Auth?.user
    },
    methods: {
        setBranch(branch) {
            store.commit('SetCurrentBranch', branch);
            this.$nextTick(() => {
                location.reload();
            })
        },
        logout() {
            //store.Auth = null;
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        onReportTransfer() {
            this.$refs.modalReportTransfer.show();
        },
        onReportProductBatch() {
            this.$refs.modalProductBatchReport.show();
        },
        onReportProductInvoice() {
            this.$refs.modalProductInvoiceReport.show();
        },
        setLocale(locale){
            let reload = this.isLang != locale ? true : false;
            this.isLang = locale;
            onChangeLanguage(locale);
            reload && window.location.reload();
        },
        getSrcFlagLanguage(lang){
            const flag = this.flagLanguages.find(flag => flag.id == lang);
            return flag?.src ?? null;
        },
    }
}
</script>

<style scoped lang="scss">
@import "../assets/css/header";
.btn-circle {
    border-radius: 999px !important;
}
.btn-icon-gray, .nav-link:hover{
    border-color: rgba(36,36,37,.1) !important;
    background-color: rgba(36,36,37,.1) !important;
}
.btn-icon:hover i {
    color: rgb(47, 161, 161);
}

.btn-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 0;
    cursor: pointer;
    width: 36px;
    height: 36px;
    transition: all .2s ease-in-out;
}
.badge{
    position: absolute;
    top: -3px;
    right: -7px;
}
.badge-danger {
    color: #fff;
    background-color: #ff3650;
    transition: all .2s ease-in-out;
    z-index: 9;
    border-radius: 15px;
}
.btn-icon-tab {
    font-size: 1.5rem;
    color: rgba(31,32,33,.7);
    position: relative;
    width: 72px;
    height: 54px;
    display: flex;
    justify-content: center !important;
    align-items: center !important;
}
.btn-icon-tab:before {
    content: "";
    background-color: rgb(47, 161, 161);
    opacity: 0;
    visibility: hidden;
    width: 100%;
    height: 3px;
    position: absolute;
    left: 0;
    bottom: -1px;
}
.btn-icon-tab.active:before, .btn-icon-tab:active:before, .btn-icon-tab:focus:before {
    animation: scale-in-hor-center .3s cubic-bezier(.25,.46,.45,.94) both;
    opacity: 1;
    visibility: visible;
}
.btn-icon-tab.active i {
    color: rgb(47, 161, 161) !important;
}
.header-menu-top{
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.25) !important;
}

.dropdown-menu__branches{
    max-height: 300px;
    overflow: auto;
    scroll-behavior: smooth;
}

</style>
