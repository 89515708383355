<template>
    <div class="row">
        <ActionBar back-url="/suppliers/index" @action="save()"/>
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1>{{this.$route.query.id ? "Chỉnh sửa nhà cung cấp" : "Thêm mới nhà cung cấp"}}</h1>
            </div>

            <div class="row" v-if="isDataLoaded">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.name"
                                       :errors="errors.name"
                                       label="Tên nhà cung cấp"/>
                            <InputText v-model="entry.code"
                                       :errors="errors.code"
                                       label="Mã nhà cung cấp"/>
                            <InputText v-model="entry.contactNumber"
                                       :errors="errors.contactNumber"
                                       :label="$t('message.InvoiceIndex.tabs.delivery.contact_number')"/>
                            <InputText v-model="entry.email"
                                       :errors="errors.email"
                                       label="Email"/>

                        </div>

                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputSwitchButton v-model="entry.status"
                                               :errors="errors.status"
                                               label="Trạng thái"/>
                            <InputText v-model="entry.address"
                                       :errors="errors.address"
                                       label="Địa chỉ"/>

                            <div class="form-group">
                                <label>Ngày tạo</label>
                                <input v-model="entry.createdDate"
                                       class="form-control" type="date">
                                <ul class="error-label" v-if="errors && errors.length">
                                    <li v-for="error in errors" :key="error" v-text="error"></li>
                                </ul>
                            </div>
                            <InputText v-model="entry.createdName"
                                       :errors="errors.createdName"
                                       label="Người tạo"/>

                        </div>

                    </div>

                </div>
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-12">
                            <InputText v-model="entry.taxCode"
                                       :errors="errors.taxCode"
                                       label="Mã số thuế"/>


                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputText from "@/components/forms/InputText";
    import ActionBar from "@/components/ActionBar";
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
    import InputSwitchButton from "../../components/forms/InputSwitchButton";

    export default {
        name: "SupplierForm",
        components: {ActionBar, InputText, InputSwitchButton},
        data() {
            return {
                isDataLoaded: false,
                entry: {
                    status: false
                },
                errors: {}
            }
        },
        mounted() {

            if (this.$route.query.id) {
                this.load();
            } else {
                this.isDataLoaded = true;
            }

            document.title = 'SupplierForm | OmiPos';


        },
        methods: {
            async load() {
                const res = await $get('/v1/suppliers/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {

                    location.replace('/404');
                    return;
                }

                this.entry = res.data.entry;
                this.isDataLoaded = true;

            },
            async save() {
                this.errors = {};

                const res = await $post('/v1/suppliers/save', {
                    entry: this.entry
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            }
        }
    }
</script>

<style scoped>

</style>
