<template>
    <div id="productBarcode" v-if="show">
        <div class="productBarcode_wrap">
            <template v-for="(product, index) in products" :key="product.id + '-' + index">
                <div class="productBarcode__item" :class="'item-' + (index % 3)">
                    <div class="productBarcode__name" v-text="product.name"></div>
                    <BarcodeGenerator
                            :value="product.code"
                            :width="1"
                            :height="24"
                            :fontSize="12"
                            :margin="4"
                    ></BarcodeGenerator>
                    <div class="productBarcode__info">
                        <span v-text="(product.productUnit && product.productUnit.price) ? n(product.productUnit.price) : n(product.price)"></span>
                        <span> 
                            {{ $t('message.common.vnd') }}
                        </span>
                        <span v-if="product.productUnit" v-text="' / ' + product.productUnit.unitName"></span>
                    </div>
                </div>
                <div v-if="index%3==2" style="page-break-before: always"></div>
            </template>
        </div>
    </div>
</template>

<script>
    import BarcodeGenerator from "../../../components/products/BarcodeGenerator";
    import {cloneObject} from "../../../lib/utils";

    export default {
        name: "ModalProductBarcode",
        components: {BarcodeGenerator},
        data() {
            return {
                products: [],
                show: false
            }
        },
        methods: {
            print(product) {
                if (!product.quantity) {
                    product.quantity = 1;
                }
                this.products = [product];

                this.launch();
            },
            multiplePrint(products) {
                if (products && products.length) {
                    const data = [];
                    products.forEach((p) => {
                        if (!p.quantity) {
                            p.quantity = 1;
                        }

                        const item = cloneObject(p);
                        for (let i = 0; i < p.quantity; i++) {
                            data.push(item);
                        }
                    });
                    this.products = data;
                }

                this.launch();
            },
            launch() {
                this.show = true;               
                setTimeout(() => {
                    this.$htmlToPaper('productBarcode', {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        autoClose: true,
                        windowTitle: window.document.title,
                        styles: [
                            '/assets/css/barcode.css'
                        ]
                    }, () => {
                        this.show = false;
                    });
                }, 500);
            }
        }
    }
</script>

<style scoped>

</style>
