<template>
    <div class="container my-4">
        <div class="row m-0 p-0">
            <div class="mainLeft">
                <div class="main-title">
                    <span>{{ $t("message.HistoryIndex.title") }}</span>
                </div>
                <div>
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-8 text-title">
                                <label>{{$t("message.common.numberRecord") }}:</label>
                            </div>
                            <div class="col-4">
                                <select name="number_records" id="number_records" @change="onChange($event)"
                                    class="number_records">
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                    <option value="30">30</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t("message.HistoryIndex.user") }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('employeeBody', 'employeeDown', 'employeeUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="employeeDown"></i>
                                    <i class="fa fa-chevron-up" id="employeeUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="employeeBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.HistoryIndex.placeholder.user')" :modelValue="filterQuery.userId" :defaultOption="this.users" @update:modelValue="doFilterSearch" :label="'userId'"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t("message.HistoryIndex.function") }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('functionBody', 'functionDown', 'functionUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="functionDown"></i>
                                    <i class="fa fa-chevron-up" id="functionUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="functionBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.HistoryIndex.placeholder.function')" :modelValue="filterQuery.typeFunctionId" :defaultOption="this.listTypeFunction" @update:modelValue="doFilterSearch" :label="'typeFunctionId'"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-9">
                                <label for="" class="typeTitle">{{ $t("message.HistoryIndex.branch") }}</label>
                            </div>
                            <div class="col-1">
                                <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                    <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                    <i class="fa fa-chevron-up" id="branchUp"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                        <div class="row">
                            <div class="col-12">
                                <SelectSearch :placeholder="$t('message.HistoryIndex.placeholder.branch')" :modelValue="filterQuery.branchId" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branchId'"/>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12">
                                <label class="typeTitle">{{ $t("message.HistoryIndex.action") }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0 pb-2" id="actionBody">
                        <div class="row" v-for="entry in this.listAction" :key="entry.id">
                            <div class="col-12 d-flex align-items-center">
                                <label class="container-checkbox">{{entry.name}}
                                    <input type="checkbox" class="chkType" @click="doFilter('action', entry.id)" :checked="isExistInArrayChecked(entry, this.filterQuery.action)">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Start created at -->
                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12">
                                <label class="typeTitle">{{ $t("message.HistoryIndex.time") }}</label>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-2 pt-0" id="timeBody">
                        <div class="row m-0 p-0">
                            <div class="col-12 px-0">
                                <label class="container-radio ml-0">
                                    <b>{{ $t("message.HistoryIndex.today") }}</b>
                                    <input type="radio" name="filterTime" v-model="this.filterQuery.time" :value="$constant.option_time_today" @click="doFilter('time', $constant.option_time_today)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 p-0">
                            <div class="col-12 px-0">
                                <label class="container-radio ml-0">
                                    <b>{{ $t("message.HistoryIndex.week") }}</b>
                                    <input type="radio" name="filterTime" v-model="this.filterQuery.time" :value="$constant.option_time_week" @click="doFilter('time', $constant.option_time_week)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 p-0">
                            <div class="col-12 px-0">
                                <label class="container-radio ml-0">
                                    <b>{{ $t("message.HistoryIndex.month") }}</b>
                                    <input type="radio" name="filterTime" v-model="this.filterQuery.time" :value="$constant.option_time_month" @click="doFilter('time', $constant.option_time_month)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 p-0">
                            <div class="col-12 px-0">
                                <label class="container-radio ml-0">
                                    <b>{{ $t("message.HistoryIndex.three_month_ago") }}</b>
                                    <input type="radio" name="filterTime" v-model="this.filterQuery.time" :value="$constant.option_time_3_month_ago" @click="doFilter('time', $constant.option_time_3_month_ago)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 p-0">
                            <div class="col-12 px-0">
                                <label class="container-radio ml-0">
                                    <b>{{ $t("message.HistoryIndex.other") }}</b>
                                    <input type="radio" name="filterTime" v-model="this.filterQuery.time" :value="$constant.option_time_other" @click="doFilter('time', $constant.option_time_other)">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                        <div class="row m-0 p-0" v-if="this.filterQuery.time == $constant.option_time_other">
                            <div class="col-lg-5 pr-0 d-flex align-items-center">
                                {{ $t("message.HistoryIndex.from_date") }}
                            </div>
                            <div class="col-lg-7 px-0">
                                <InputDatePicker :sortTimeHeight="true" class="w-100 mb-0 custom-input" @update:modelValue="value => doFilter('startDate', value)" v-model="filterQuery.startDate"/>
                            </div>
                        </div>
                        <div class="row m-0 p-0" v-if="this.filterQuery.time == $constant.option_time_other">
                            <div class="col-lg-5 pr-0 d-flex align-items-center">
                                {{ $t("message.HistoryIndex.to_date") }}
                            </div>
                            <div class="col-lg-7 px-0">
                                <InputDatePicker :sortTimeHeight="true" class="w-100 mb-0 custom-input" @update:modelValue="value => doFilter('endDate', value)" v-model="filterQuery.endDate"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mainRight">
                <div class="sticky-top">
                    <div class="row">
                        <div class="col-lg-5">
                            <InputQuickSearch :placeholder="$t('message.HistoryIndex.placeholder.search')" @filterSearch="changeQuickSearch" :historySearch="true" />
                        </div>
                        <div class="col-lg-7 d-flex justify-content-end">  
                            <button type="button" @click="clearFilter()" class="btn btn-default">
                                <i class="fa fa-filter mr-1"></i>
                                {{ $t("message.button.removeFilter") }}
                            </button>                              
                            <button class="btn btn-save mr-1" type="button" @click="exportHistory()">
                                <i class="fas fa-file-export mx-1"></i>
                                {{ $t("message.HistoryIndex.button.export") }}
                            </button>
                            <div id="loc" v-on:mouseleave="onMouseLeave">
                                <SelectField :fields="fields" label="history"></SelectField>
                            </div>
                        </div>
                    </div>
                    <div>
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange" />
                    </div>
                    <div class="row mt-2">
                        <div class="col-12">
                            <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                            </div>
                            <div class="row mx-0 my-2" id="divTable">
                                <table class="table table-index table-striped w-100 lstRenderTable">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                            <template v-for="field in fields" :key="field.field">
                                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                    <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                                    :class="field.class" :name="field.name" :field="field.field" :style="field.style" class=""/>
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="entries?.length > 0">
                                            <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                <tr class="master-row" :class="entry.id === this.historyActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                    <td class="checkbox-table">
                                                        <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                    </td>
                                                    <template v-for="field in fields" :key="field.field">
                                                        <td class="ow-break-word py-0" v-if="field.selected && field.field" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                            <a :href="'#row_' + index" class="text-dark tagA" @click="detailHistory(entry.id)">
                                                                <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="" :class="field.class"></div>
                                                            </a>
                                                        </td>                                                        
                                                    </template>
                                                </tr>
                                                <tr v-if="historyActive === entry.id" :class="[ entry.id === historyActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                    <td class="checkbox-table border-top-0"></td>
                                                    <td :colspan="fields.length" class="pb-0 border-top-0">
                                                        <div class="row detail-prd-row">
                                                            <div class="col-2 mt-2">
                                                                <span class="backgroup__detail-active head-detail">
                                                                    <span>
                                                                        {{$t('message.HistoryIndex.tabs.content')}}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="detail-row bg-white border__body-detail" v-if="historyActive === entry.id">
                                                    <td class="checkbox-table border-top-0"></td>
                                                    <td :colspan="fields.length" class="border-top-0">
                                                        <div class="mt-2 pb-4 pt-2">
                                                            <div class="row mt-2 m-0">
                                                                <div class="col-12 sub-content-history" v-html="entry.subContent">

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr class="detail-row bg-white border__footer-detail" v-if="historyActive === entry.id"></tr>
                                            </template>
                                        </template>
                                        <template v-if="entries?.length == 0 && !this.loading">
                                            <tr>
                                                <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                    <div class="row detail-prd-row mx-0">
                                                        <div class="col-12 text-center text-secondary mt-5">
                                                            <h3>
                                                                <i class="fa fa-file mr-2 text-secondary"></i>
                                                                <i class="fa fa-exclamation"></i>
                                                            </h3>
                                                        </div>
                                                        <div class="col-12 text-center my-10 text-secondary mb-5">
                                                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <PrintTemplate ref='PrintTemplate' />
</template>
<script>
import { $alert, $get, $post, clone, setArray, removeVietnameseTones } from "@/lib/utils";
import store from "@/store";
import SelectDateTime from "@/components/SelectDateTime.vue";
import InputDatePicker from "@/components/forms/InputDatePicker";
import SelectSearch from "@/components/SelectSearch.vue";
import emitter from "@/lib/emitter";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import http from '@/services/api';
import CashBookMixin from '@/CashBookMixin';
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import PrintTemplate from '@/components/PrintTemplate.vue';
import AppPermission from '@/components/AppPermission.vue';
import SelectField from "@/components/SelectField";
import exportMixin from "@/exportMixin"; 
export default {
    name: "BankBookIndex",
    mixins: [CashBookMixin, exportMixin],
    components: {
        SelectDateTime,
        SelectSearch,
        TableSortField,
        Pagination, 
        PrintTemplate,
        InputQuickSearch,
        AppPermission,
        InputDatePicker,
        SelectField
    },
    mounted() {
        document.title = this.$t('message.HistoryIndex.document_title');
        const fields = localStorage.getItem('historySelectedFields_' + this.user.id);
        if (fields) {
            this.fields = JSON.parse(fields);
        }
        this.clearFilter();
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            filter: {
                keyword: '',
                order_code: '',
            },
            filterQuery: {
                action: [],
                branchId: '',
                userId: '',
                typeFunctionId: '',
                time: '',
                startDate: '',
                endDate: '',
            },
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },
            sortData: {
                field: 'id',
                direction: 'desc',
            },
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            users: globalConfig.users,
            user: store.state.Auth ? store.state.Auth.user : {},
            fields: [],
            entries: [],
            arrayChecked: [],
            listObjectPaymentReceipt: [],
            loading: false,
            isFilter: false,
            totalAmountRevenue: 0,
            historyActive: null,
            documentSrc: null,
            toLink: null,
            listAction: [],
            listTypeFunction: [],
        }
    },
    methods: {
        async clearFilter() {
            await this.$router.replace({
                name: 'HistoryIndex',
                query: {}
            });

            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });
            Object.keys(this.filterQuery).forEach(key => {
                if (key == 'action') {
                    this.filterQuery[key] = [];
                }else if (key == 'time') {
                    this.filterQuery[key] = this.$constant.option_time_today;
                }else {
                    this.filterQuery[key] = '';
                }
            });
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("resetSelectDateTime", 'month');
            this.arrayChecked = [];
            this.numberRecord = 15;
            this.filterQuery.branchId = store.state.CurrentBranch.id;
            this.historyActive = null;
            this.isFilter = false;
            this.documentSrc = null;
            this.toLink = null;
            await this.load();
        },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post('/v1/histories/get-data-history', {...this.filterQuery, record: this.numberRecord });
            if (res.result.code !== 200) {
                $alert(res);
                return;
            }
            if (!this.fields || !this.fields.length) {
                this.fields = res.result.data.fields;
            }
            this.entries = res.result.data.data.data;     
            this.paginate.totalRecord = res.result.data.data.total;
            this.paginate.currentPage = res.result.data.data.current_page;
            this.paginate.lastPage = res.result.data.data.last_page;

            if (res.result.data.listAction) {
                this.listAction = res.result.data.listAction;
            }
            if (res.result.data.listFunction) {
                this.listTypeFunction = res.result.data.listFunction;
            }
            this.isCheckedAll();
            emitter.emit("offLoading");
            this.loading = false;
        },
        showHide(name, iDown, iUp) {
            var dropdownContent = document.getElementById(name);
            dropdownContent.classList.toggle("dp-hide");
            var down = document.getElementById(iDown);
            down.classList.toggle("dp-hide");
            var up = document.getElementById(iUp);
            up.classList.toggle("dp-hide");
        },
        doFilterTime(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async doFilter(field, value) {
            const query = clone(this.filterQuery);
            if(field == 'action'){
                if(query['action'].includes(value)){
                    query['action'] = query['action'].filter(item => item !== value)
                }else{
                    query['action'].push(value)
                }
            }else{
                query[field] = value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async changeQuickSearch(object){
            const query = clone(this.filterQuery);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filter[key] = obj[o];
                            query[o] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete query[o];
                        }
                    });
                })
            });
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.filterQuery);
            query['page'] = 1;
            this.isFilter = true;
            this.filterQuery = {...query};
            await this.load();
        },
        async pageChange(page) {
            const query = clone(this.filterQuery);
            query['page'] = page;
            this.isFilter = true;
            this.filterQuery = {...query};
            await this.load();
        },
        
        isExistInArrayChecked(entry, array = []) {
            if (array.includes(entry.id)) {
                entry.checked = true;
                return true;
            } else {
                entry.checked = false;
                return false;
            }
        },
        isCheckedAll() {
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if (found) {
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if (getChecked > 0 && getChecked == this.entries.length) {
                this.checkedAll = true;
            } else {
                this.checkedAll = false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        onCheckedAll(value) {
            setArray(this.entries, {
                checked: value
            });
            if (value == true) {
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if (!found) {
                        this.countChecked(entry.id);
                    }
                })
            } else {
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }            
        },

        async quickSave(entry){
            if(entry.note && entry.note.length > 255){
                $alert({code: 422, message: this.$t('message.BankBookIndex.alerts.validate_error_note')})
            }
            const res = await $post('/v1/bank-book/quick-save', {
                id: entry.id,
                note: entry.note,
            });
            if(res.result.status == true){
                $alert({
                    code: 200,
                    message: this.$t('message.BankBookIndex.alerts.update_success', {code: entry.code}),
                });
                this.load();
            }else{
                $alert({
                    code: 403,
                    message: this.$t('message.BankBookIndex.alerts.update_error', {code: entry.code}),
                });
            }
        },
        onMouseLeave(){
            emitter.emit("mouseleave");
        },
        async detailHistory(id){
            if (id === this.historyActive) {
                this.historyActive = null;
            } else {
                this.historyActive = id;
                
            }
        },
        async exportHistory() {
            emitter.emit("onLoading");    
            const res = await http.download('/v1/histories/export-history', {...this.filterQuery, record: this.numberRecord });              
            this.returnDataExport(res);  
        },
    },
}
</script>

<style scoped>#number_records {
    margin-top: 11px;
}

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.text-title {
    line-height: 52px;
}

#typeCard {
    margin-top: -8px;
}

#noteRO {
    border-left: 1px solid #ccc;
}

.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}

.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}

#fa_edit {
    position: absolute;
    left: 20px;
    top: 4px;
    color: #a7a2a2;
}
.container-radio input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.sub-content-history{
    max-height: 400px !important;
    overflow: auto;
}
/* .sub-content-history::-webkit-scrollbar {
    width: 0px !important;
    height: 0px !important;
} */
</style>
