<template>
    <div class="row">
        <div class="col-lg-8">
            <div class="row">
                <div class="col-6">
                    <InputDatePicker :label="$t('message.common.startTime')" :timepicker="true" v-model="entry.startTime"/>
                </div>
                <div class="col-6">
                    <InputDatePicker :label="$t('message.common.endTime')" :timepicker="true" v-model="entry.endTime"/>
                </div>
            </div>
        </div>

    </div>

</template>

<script>
import InputDatePicker from "../../../components/forms/InputDatePicker";
export default {
    name: "PromotionTabTime",
    components: {InputDatePicker},
    data() {
        return {
            entry: {}
        }
    }
}
</script>

<style scoped>

</style>
