import {deepFreeze} from "@/lib/deepfreeze";

const TransferStatus = {
    DRAFT: 1,
    INPROGRESS: 2,
    SUCCEED: 3,
    CANCELLED: 4,
    NOT_ACCEPT: 5
}

deepFreeze(TransferStatus);

export {
    TransferStatus
};
