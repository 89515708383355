import messages from "@/locale/languages";

import { createI18n } from 'vue-i18n-lite';

const languageStorage = localStorage.getItem('language') ?? 'vi';

const i18n = createI18n({
    locale: languageStorage,
    messages,
});

export const onChangeLanguage = (value) => {
    i18n.changeLocale(value);
    localStorage.setItem('language', value);
}

export const getLang = () => {
    return i18n.locale
}

export default i18n;
