<template>
    <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.data : '' ">  
    <div >     
      <div class="container mt-0">
        <div class="row m-0 p-0">
            <div class="mainLeft my-4">
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>{{$t('message.common.integrateSalesChannels')}}</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0" id="branchBody">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item active" @click="listShop()">
                                {{$t('message.common.linkToECommerce')}}
                            </div>
                        </div>
                        <div class="row px-2 mt-2">
                            <div class="col-12 sidebar-item" @click="mappingProducts()">
                                {{$t('message.common.linkToProduct')}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>{{$t('message.headers.products')}}</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-2" id="roleBody">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item">
                                Đăng bán hàng hóa
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>Marketing</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="status">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item">
                                Tự động đẩy hàng hóa
                            </div>
                        </div>
                        <div class="row px-2 mt-2">
                            <div class="col-12 sidebar-item">
                                Thông tin khách hàng
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        
            <div class="mainRight pr-0">
                <div class="">
                    <h4><b>{{$t('message.common.integrateSalesChannels')}}</b></h4>
                </div>
                <div class="shopee">
                    <div class="row">
                        <div class="col-8 d-flex align-items-center">
                            <img src="../../../public/img/shopee.svg" alt="" class="connectLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                             <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.create : '' ">
                                <button class="btn btn-warning text-white" @click="newConnect(this.$constant.textShopee)">
                                    <i class="fa fa-plus"></i> {{$t('message.common.addStall')}}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="shop-name">{{$t('message.common.stallName')}}</th>
                                            <th class="branch-name">{{$t('message.common.branch')}}</th>
                                            <th class="sync-order">{{$t('message.common.syncOrder')}}</th>
                                            <th class="sync-sell-quantity">{{$t('message.common.syncQuantity')}}</th>
                                            <th class="sync-price">{{$t('message.common.syncPrice')}}</th>
                                            <th class="status-connect">{{$t('message.common.status')}}</th>
                                            <th class="operation">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.shopee?.length > 0">
                                            <template v-for="entry in this.shopee" :key="entry.id">
                                                <tr :class="entry.status != this.$constant.status_connected ? 'connection-warning' : ''">
                                                    <td class="shop-name" v-text="entry.name"></td>
                                                    <td class="branch-name" v-text="entry.get_branch?.name"></td>
                                                    <td class="sync-order"><i class="fa fa-check text-success" v-if="entry.syncOrder === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-sell-quantity"><i class="fa fa-check text-success" v-if="entry.syncOrderQuantity === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-price"><i class="fa fa-check text-success" v-if="entry.syncPrice === this.$constant.status_choose_setting"></i></td>
                                                    <td class="status-connect" :class="entry.status != this.$constant.status_connected ? 'text-warning' : 'text-success'" v-text="entry.status != this.$constant.status_connected ? $t('message.common.noSetup') : $t('message.common.connected')"></td>
                                                    <td class="operation px-0">
                                                        <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.update : '' ">                                                            
                                                            <AppPermission :permission="this.$constant.saleChanelList">
                                                                <span class="btn-icon btn-circle mr-2" @click="newSetting(entry)"><i class="fas fa-cog m-0 text-secondary" :title="$t('message.common.settingSync')"></i></span>
                                                            </AppPermission>
                                                            <span class="btn-icon btn-circle mr-2" @click="mappingProduct(entry, this.$constant.textShopee)"><i class="far fa-list-alt ml-2 text-secondary" :title="$t('message.common.linkToProduct')"></i></span>
                                                            <span class="btn-icon btn-circle mr-2 position-relative" @click="syncFail(entry, this.$constant.textShopee)">
                                                                <i class="fas fa-sync-alt ml-2 text-secondary" :title="$t('message.common.syncError')"></i>
                                                                <span class="badge badge-danger" v-if="entry.sync_order?.length > 0">{{ entry.sync_order.length }}</span>
                                                            </span>
                                                        </AppPermission>
                                                         <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.delete : '' ">
                                                            <span class="btn-icon btn-circle mr-2" @click="showConfirmRemove(entry.id, this.$constant.textShopee)"><i class="far fa-trash-alt ml-2 text-secondary" :title="$t('message.common.removeLink')"></i></span>
                                                        </AppPermission>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="noConnection">
                                                <td colspan="7">{{$t('message.common.noLinkStall')}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="lazada">
                    <div class="row mt-4">
                        <div class="col-8 d-flex align-items-center">
                            <img src="../../../public/img/lazada.png" alt="" class="connectLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.create : '' ">
                                <button class="btn btn-add-connection-lazada" @click="newConnect(this.$constant.textLazada)">
                                    <i class="fa fa-plus"></i> {{$t('message.common.addStall')}}
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="shop-name">{{$t('message.common.stallName')}}</th>
                                            <th class="branch-name">{{$t('message.common.branch')}}</th>
                                            <th class="sync-order">{{$t('message.common.syncOrder')}}</th>
                                            <th class="sync-sell-quantity">{{$t('message.common.syncQuantity')}}</th>
                                            <th class="sync-price">{{$t('message.common.syncPrice')}}</th>
                                            <th class="status-connect">{{$t('message.common.status')}}</th>
                                            <th class="operation">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.lazada?.length > 0">
                                            <template v-for="entry in this.lazada" :key="entry.id">
                                                <tr :class="entry.status != this.$constant.status_connected ? 'connection-warning' : ''">
                                                    <td class="shop-name" v-text="entry.name"></td>
                                                    <td class="branch-name" v-text="entry.get_branch?.name"></td>
                                                    <td class="sync-order"><i class="fa fa-check text-success" v-if="entry.syncOrder === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-sell-quantity"><i class="fa fa-check text-success" v-if="entry.syncOrderQuantity === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-price"><i class="fa fa-check text-success" v-if="entry.syncPrice === this.$constant.status_choose_setting"></i></td>
                                                    <td class="status-connect" :class="entry.status != this.$constant.status_connected ? 'text-warning' : 'text-success'" v-text="entry.status != this.$constant.status_connected ? $t('message.common.noSetup') : $t('message.common.connected')"></td>
                                                    <td class="operation px-0">
                                                        <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.update : '' ">
                                                            <AppPermission :permission="this.$constant.saleChanelList">
                                                                <span class="btn-icon btn-circle mr-2" @click="newSetting(entry)"><i class="fas fa-cog m-0 text-secondary" :title="$t('message.common.settingSync')"></i></span>                                                                
                                                            </AppPermission>
                                                            <span class="btn-icon btn-circle mr-2" @click="mappingProduct(entry, this.$constant.textLazada)"><i class="far fa-list-alt ml-2 text-secondary" :title="$t('message.common.linkToProduct')"></i></span>
                                                            <span class="btn-icon btn-circle position-relative mr-2" @click="syncFail(entry, this.$constant.textLazada)">
                                                                <i class="fas fa-sync-alt ml-2 text-secondary" :title="$t('message.common.syncError')"></i>
                                                                <span class="badge badge-danger" v-if="entry.sync_order?.length > 0">{{ entry.sync_order.length }}</span>
                                                            </span>
                                                        </AppPermission>
                                                        <AppPermission :permission="this.$permissions?.saleOnline ? this.$permissions.saleOnline.delete : '' ">
                                                            <span class="btn-icon btn-circle" @click="showConfirmRemove(entry.id, this.$constant.textLazada)">
                                                                <i class="far fa-trash-alt ml-2 text-secondary" :title="$t('message.common.removeLink')"></i>
                                                            </span>
                                                        </AppPermission>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="noConnection">
                                                <td colspan="7">{{$t('message.common.noLinkStall')}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Tiki -->
                 <div class="tiki">
                    <div class="row mt-4">
                        <div class="col-8 d-flex align-items-center">
                            <img src="../../../public/img/tiki-logo.png" alt="" class="connectLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <button class="btn btn-add-connection-tiki" @click="newConnect(this.$constant.textTiki)">
                                <i class="fa fa-plus"></i> {{$t('message.common.addStall')}}
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="shop-name">{{$t('message.common.stallName')}}</th>
                                            <th class="branch-name">{{$t('message.common.branch')}}</th>
                                            <th class="sync-order">{{$t('message.common.syncOrder')}}</th>
                                            <th class="sync-sell-quantity">{{$t('message.common.syncQuantity')}}</th>
                                            <th class="sync-price">{{$t('message.common.syncPrice')}}</th>
                                            <th class="status-connect">{{$t('message.common.status')}}</th>
                                            <th class="operation">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.tiki?.length > 0">
                                            <template v-for="entry in this.tiki" :key="entry.id">
                                                <tr :class="entry.status != this.$constant.status_connected ? 'connection-warning' : ''">
                                                    <td class="shop-name" v-text="entry.name"></td>
                                                    <td class="branch-name" v-text="entry.get_branch?.name"></td>
                                                    <td class="sync-order"><i class="fa fa-check text-success" v-if="entry.syncOrder === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-sell-quantity"><i class="fa fa-check text-success" v-if="entry.syncOrderQuantity === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-price"><i class="fa fa-check text-success" v-if="entry.syncPrice === this.$constant.status_choose_setting"></i></td>
                                                    <td class="status-connect" :class="entry.status != this.$constant.status_connected ? 'text-warning' : 'text-success'" v-text="entry.status != this.$constant.status_connected ? $t('message.common.noSetup') : $t('message.common.connected')"></td>
                                                    <td class="operation px-0">
                                                        <span class="btn-icon btn-circle mr-2" @click="newSetting(entry)"><i class="fas fa-cog m-0 text-secondary" :title="$t('message.common.settingSync')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2" @click="mappingProduct(entry, this.$constant.textTiki)"><i class="far fa-list-alt ml-2 text-secondary" :title="$t('message.common.linkToProduct')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2 position-relative" @click="syncFail(entry, this.$constant.textTiki)">
                                                            <i class="fas fa-sync-alt ml-2 text-secondary" :title="$t('message.common.syncError')"></i>
                                                            <span class="badge badge-danger" v-if="entry.sync_order?.length > 0">{{ entry.sync_order.length }}</span>
                                                        </span>
                                                        <span class="btn-icon btn-circle mr-2" @click="showConfirmRemove(entry.id, this.$constant.textTiki)"><i class="far fa-trash-alt ml-2 text-secondary" :title="$t('message.common.removeLink')"></i></span>
                                                        
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="noConnection">
                                                <td colspan="7">{{$t('message.common.noLinkStall')}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Tiktok -->
                <div class="tiktok">
                    <div class="row mt-4">
                        <div class="col-8 d-flex align-items-center">
                            <img src="../../../public/img/tiktok.svg" alt="" class="connectLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <button class="btn btn-add-connection-tiktok" @click="newConnect(this.$constant.textTiktok)">
                                <i class="fa fa-plus"></i> {{$t('message.common.addStall')}}
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="shop-name">{{$t('message.common.stallName')}}</th>
                                            <th class="branch-name">{{$t('message.common.branch')}}</th>
                                            <th class="sync-order">{{$t('message.common.syncOrder')}}</th>
                                            <th class="sync-sell-quantity">{{$t('message.common.syncQuantity')}}</th>
                                            <th class="sync-price">{{$t('message.common.syncPrice')}}</th>
                                            <th class="status-connect">{{$t('message.common.status')}}</th>
                                            <th class="operation">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.tiktok?.length > 0">
                                            <template v-for="entry in this.tiktok" :key="entry.id">
                                                <tr :class="entry.status != this.$constant.status_connected ? 'connection-warning' : ''">
                                                    <td class="shop-name" v-text="entry.name"></td>
                                                    <td class="branch-name" v-text="entry.get_branch?.name"></td>
                                                    <td class="sync-order"><i class="fa fa-check text-success" v-if="entry.syncOrder === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-sell-quantity"><i class="fa fa-check text-success" v-if="entry.syncOrderQuantity === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-price"><i class="fa fa-check text-success" v-if="entry.syncPrice === this.$constant.status_choose_setting"></i></td>
                                                    <td class="status-connect" :class="entry.status != this.$constant.status_connected ? 'text-warning' : 'text-success'" v-text="entry.status != this.$constant.status_connected ? $t('message.common.noSetup') : $t('message.common.connected')"></td>
                                                    <td class="operation px-0">
                                                        <span class="btn-icon btn-circle mr-2" @click="newSetting(entry)"><i class="fas fa-cog m-0 text-secondary" :title="$t('message.common.settingSync')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2" @click="mappingProduct(entry, this.$constant.textTiktok)"><i class="far fa-list-alt ml-2 text-secondary" :title="$t('message.common.linkToProduct')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2 position-relative" @click="syncFail(entry, this.$constant.textTiktok)">
                                                            <i class="fas fa-sync-alt ml-2 text-secondary" :title="$t('message.common.syncError')"></i>
                                                            <span class="badge badge-danger" v-if="entry.sync_order?.length > 0">{{ entry.sync_order.length }}</span>
                                                        </span>
                                                        <span class="btn-icon btn-circle mr-2" @click="showConfirmRemove(entry.id, this.$constant.textTiktok)"><i class="far fa-trash-alt ml-2 text-secondary" :title="$t('message.common.removeLink')"></i></span>
                                                        
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="noConnection">
                                                <td colspan="7">{{$t('message.common.noLinkStall')}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="sendo">
                    <div class="row mt-4">
                        <div class="col-8 d-flex align-items-center">
                            <img src="../../../public/img/sendo.svg" alt="" class="connectLogo">
                        </div>
                        <div class="col-4 d-flex justify-content-end">
                            <button class="btn btn-add-connection-sendo" @click="newConnect(this.$constant.textSendo)">
                                <i class="fa fa-plus"></i> {{$t('message.common.addStall')}}
                            </button>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12 table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="shop-name">{{$t('message.common.stallName')}}</th>
                                            <th class="branch-name">{{$t('message.common.branch')}}</th>
                                            <th class="sync-order">{{$t('message.common.syncOrder')}}</th>
                                            <th class="sync-sell-quantity">{{$t('message.common.syncQuantity')}}</th>
                                            <th class="sync-price">{{$t('message.common.syncPrice')}}</th>
                                            <th class="status-connect">{{$t('message.common.status')}}</th>
                                            <th class="operation">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.sendo?.length > 0">
                                            <template v-for="entry in this.sendo" :key="entry.id">
                                                <tr :class="entry.status != this.$constant.status_connected ? 'connection-warning' : ''">
                                                    <td class="shop-name" v-text="entry.name"></td>
                                                    <td class="branch-name" v-text="entry.get_branch?.name"></td>
                                                    <td class="sync-order"><i class="fa fa-check text-success" v-if="entry.syncOrder === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-sell-quantity"><i class="fa fa-check text-success" v-if="entry.syncOrderQuantity === this.$constant.status_choose_setting"></i></td>
                                                    <td class="sync-price"><i class="fa fa-check text-success" v-if="entry.syncPrice === this.$constant.status_choose_setting"></i></td>
                                                    <td class="status-connect" :class="entry.status != this.$constant.status_connected ? 'text-warning' : 'text-success'" v-text="entry.status != this.$constant.status_connected ? $t('message.common.noSetup') : $t('message.common.connected')"></td>
                                                    <td class="operation px-0">
                                                        <span class="btn-icon btn-circle mr-2" @click="newSetting(entry)"><i class="fas fa-cog m-0 text-secondary" :title="$t('message.common.settingSync')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2" @click="mappingProduct(entry, this.$constant.textSendo)"><i class="far fa-list-alt ml-2 text-secondary" :title="$t('message.common.linkToProduct')"></i></span>
                                                        <span class="btn-icon btn-circle mr-2 position-relative" @click="syncFail(entry, this.$constant.textSendo)">
                                                            <i class="fas fa-sync-alt ml-2 text-secondary" :title="$t('message.common.syncError')"></i>
                                                            <span class="badge badge-danger" v-if="entry.sync_order?.length > 0">{{ entry.sync_order.length }}</span>
                                                        </span>
                                                        <span class="btn-icon btn-circle mr-2" @click="showConfirmRemove(entry.id, this.$constant.textSendo)"><i class="far fa-trash-alt ml-2 text-secondary" :title="$t('message.common.removeLink')"></i></span>
                                                        
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="noConnection">
                                                <td colspan="7">{{$t('message.common.noLinkStall')}}</td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
      </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @refresh="refreshData()"/>
     </AppPermission>
    

</template>

<script>
    import toastr from 'toastr';
    import {$get, $post} from "../../lib/utils";
    import ModalConfirm from './ModalConfirm.vue';    
    import AppPermission from '@/components/AppPermission.vue';
    export default {
        name: "ECommerce",
        components: {ModalConfirm, AppPermission},
        mounted() {
            // this.loadLazada();
        },
        created() {
            this.getInfoLoginShopee();
            this.getDataShopee();
            this.getDataLazada();
            this.getDataTiki();
            this.getDataTiktok();
            this.getDataSendo();
        },
        data() {
            return {
                shopee: [],
                lazada: [],
                tiki: [],
                tiktok: [],
                sendo: [],
            }
        },
        methods: {
            async newConnect(type){
                await this.$router.push({ name: 'NewConnectScreen', query: {
                    'type': type,
                }});
            },
            async listShop(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/list',
                    query: {}
                });
            },
            async mappingProducts(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/mapping',
                    query: {}
                });
            },
            async getInfoLoginShopee()
            {
                if (this.$route.query.code && this.$route.query.shop_id && this.$route.query.type == this.$constant.type_channel.shopee) {
                    this.code = this.$route.query.code;
                    this.shop_id = this.$route.query.shop_id;
                    const res = await $get('/v1/shopee/get-token', {
                        shop_id: this.shop_id,
                        code : this.code,
                    });
                    if (res && res.result) {
                        if(!res.result.status){
                            toastr.error(res.result.message);
                        }else{
                            toastr.success(this.$t('message.common.linkToStallSuccess'));
                        }
                        this.$router.replace({
                            path: '/sale-online/e-commerce/list',
                            query: {}
                        });
                        this.getDataShopee();
                    }
                }else if (this.$route.query.code && this.$route.query.type == this.$constant.type_channel.lazada) {
                     this.code = this.$route.query.code;
                     const result = await $get('/v1/lazada/access-token', {
                        code: this.code
                     });
                    if (result && result.result) {
                        const getSeller = await $get('/v1/lazada/getSeller', {
                            'access_token': result.result.access_token,
                            'country': result.result.country
                        });
                        
                        const saveData = await $get('/v1/lazada/save-connect', {
                            'name': getSeller.result.data.name,
                            'shopId': getSeller.result.data.seller_id,
                            'channel_name': this.$constant.type_channel.lazada,
                            'accessToken': result.result.access_token,
                            'refreshAccessToken': result.result.refresh_token,
                            'expireTime': result.result.expires_in
                        });
                        if (saveData.result.code_connect == 201) {
                            // this.loadLazada();
                            toastr.success(this.$t('message.common.linkToStallSuccess'));
                            this.getDataLazada();
                        }else if (saveData.result.code_connect == 400) {
                            toastr.success(this.$t('message.common.existStall'));
                        }else {
                            toastr.error(this.$t('message.common.retry'));
                        }
                        this.$router.replace({
                            path: '/sale-online/e-commerce/list',
                            query: {}
                        });            
                    }
                }else if (this.$route.query.code && this.$route.query.type == this.$constant.type_channel.lazada) {
                     this.code = this.$route.query.code;
                     const result = await $get('/v1/lazada/access-token', {
                        code: this.code
                     });
                    if (result && result.result) {
                        const getSeller = await $get('/v1/lazada/getSeller', {
                            'access_token': result.result.access_token,
                            'country': result.result.country
                        });
                        
                        const saveData = await $get('/v1/lazada/save-connect', {
                            'name': getSeller.result.data.name,
                            'shopId': getSeller.result.data.seller_id,
                            'channel_name': this.$constant.type_channel.lazada,
                            'accessToken': result.result.access_token,
                            'refreshAccessToken': result.result.refresh_token,
                            'expireTime': result.result.expires_in
                        });
                        if (saveData.result.code_connect == 201) {
                            this.loadLazada();
                            toastr.success(this.$t('message.common.linkToStallSuccess'));
                        }else if (saveData.result.code_connect == 400) {
                            toastr.success(this.$t('message.common.existStall'));
                        }else {
                            toastr.error(this.$t('message.common.retry'));
                        }            
                    }
                }else if (this.$route.query.code && this.$route.query.type == this.$constant.type_channel.tiktok) {
                    this.code = this.$route.query.code;
                    const result = await $get('/v1/tiktok/get-shop', {
                        auth_code: this.code
                    });
                    if(result && result?.result?.status){
                        toastr.success(result?.result?.message);
                        this.$router.replace({
                            path: '/sale-online/e-commerce/list',
                            query: {}
                        });
                        this.getDataTiktok();
                    }else if(result && result?.result?.message){
                        toastr.error(result?.result?.message);
                    }
                }else if (this.$route.query.code && this.$route.query.type == this.$constant.type_channel.tiki) {
                    this.code = this.$route.query.code;
                    const result = await $post('/v1/tiki/get-seller', {
                        code: this.code
                    });
                    if(result && result?.result?.status){
                        toastr.success(result?.result?.message);
                        this.$router.replace({
                            path: '/sale-online/e-commerce/list',
                            query: {}
                        });
                        this.getDataTiki();
                    }else if(result && result?.result?.message){
                        toastr.error(result?.result?.message);
                    }
                }
            },
            async loadLazada () {
                const result = await $get('/v1/lazada/get-shop'); 
                if (result.status.code == 200) {
                    this.shopLazada = result.result;
                }         
            },
            getDataShopee() {
                this.getData(this.$constant.textShopee);
            },
            getDataLazada() {
                this.getData(this.$constant.textLazada);
            },
            getDataTiki() {
                this.getData(this.$constant.textTiki);
            },
            getDataTiktok() {
                this.getData(this.$constant.textTiktok);
            },
            getDataSendo() {
                this.getData(this.$constant.textSendo);
            },
            async getData(type){
                
                const res = await $post('/v1/connection/get-list-shops', { type: type});
                switch (type) {
                    case this.$constant.textShopee:
                        this.shopee = res.result;
                        break;
                    case this.$constant.textLazada:
                        this.lazada = res.result;
                        break;
                    case this.$constant.textTiki:
                        this.tiki = res.result;
                        break;
                    case this.$constant.textTiktok:
                        this.tiktok = res.result;
                        break;
                    case this.$constant.textSendo:
                        this.sendo = res.result;
                        break;
                }
            },
            async newSetting(entry){
                await this.$router.push({ name: 'NewSettingScreen', query: {
                    'shopId': entry.shopId,
                    'channelId': entry.channelId
                }});
            },
            async mappingProduct(entry, typeChannel){
                await this.$router.push({ name: 'NewMappingScreen', query: {
                    'shopId': entry.shopId,
                    'channelId': entry.channelId,
                    'type':typeChannel,
                }});
            },
            async syncFail(entry, typeChannel){
                await this.$router.push({ name: 'SyncFailScreen', query: {
                    'shopId': entry.shopId,
                    'channelId': entry.channelId,
                }});
            },
            showConfirmRemove(id, typeChannel){
                this.$refs.ModalConfirm.show(id, typeChannel);
            },
            refreshData(){
                this.getDataShopee();
                this.getDataLazada();
                this.getDataTiki();
                this.getDataTiktok();
                this.getDataSendo();
            }
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.sidebar-item {
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}
.sidebar-item.active{
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.mainRight{
    padding: 1.2rem 1rem;
}
.table-content-head{
    background-color: #f5f6f8;
    border: 1px solid #d7d9de;
    border-bottom: none !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.table-content-body{
    background-color: #fff;
    border: 1px solid #d7d9de;
    border-top: none !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    overflow: auto;
}
.table thead tr th, tbody tr td{
    border-top: none !important;
    border-bottom: none !important;
}
.table tbody tr td{
    border-top: 1px solid #d7d9de !important;
}
.connectLogo {
    height: 29px;
    object-fit: contain;
} 
.btn-add-connection-tiktok{
    color: #fff;
    border-color: #5245e5;
    background-color: #5245e5;
}
.btn-add-connection-lazada{
    color: #fff;
    border-color: #000083;
    background-color: #000083;
}
.btn-add-connection-tiki{
    color: #fff;
    border-color: #1ba9ff;
    background-color: #1ba9ff;
}

.btn-add-connection-tiktok{
    color: #fff;
    border-color: #5245e5;
    background-color: #5245e5;
}

.btn-add-connection-sendo{
    color: #fff;
    border-color: #ec2324;
    background-color: #ec2324;
}
.btn-icon:hover i {
    color: rgb(47, 161, 161) !important;
}
.connection-warning td{
    background-color: #fff2e7 !important;
}
.noConnection{
    text-align: center;
}
.shop-name{
    width: 20% !important;
}
.branch-name, .sync-order, .sync-sell-quantity, .sync-price{
    width: 15% !important;
}
.status-connect, .operation{
    width: 10% !important;
}
.badge{
    position: absolute;
    top: -10px;
    right: -12px;
    padding: 0.2em 0.4em !important;
}
.badge-danger {
    color: #fff;
    background-color: #ff3650;
    transition: all .2s ease-in-out;
    z-index: 9;
    border-radius: 15px;
}
</style>