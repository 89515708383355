<template>
    <div class="container mt-0 pl-2">
        <div class="row mt-4">
            <div class="col-12">
                <a href="/sale-online/e-commerce/list" class="text-primary text-decoration-none"><b>{{$t('message.common.linkToECommerce')}}</b></a><i class="fa fa-chevron-right ml-2 cursor-pointer text-secondary mr-2"></i>{{$t('message.common.linkToProduct')}}
            </div>
        </div>
        <div class="sync-inserted mt-1">
            <div class="setting-mapping-wrapper selectProduct sync-inserted">
                <div class="mapping-header">
                    <div class="filter-block">
                        <div class="filter-title">{{$t('message.common.status')}}:</div>
                        <div class="filter-list-choose">
                            <div class="btn btn-md btn-outline-default btn-radius-cicrle font-normal" :class="sttSync ? 'filter-active btn-primary' : ''" @click="changeSttSync(true)">{{$t('message.common.all')}}</div>
                            <div class="btn btn-md btn-outline-default btn-radius-cicrle font-normal ml-3" :class="!sttSync ? 'filter-active btn-primary' : ''" @click="changeSttSync(false)">{{$t('message.common.syncError')}}</div>
                        </div>  
                    </div>
                    <div class="d-flex align-items-center filter-search-block">
                        <div class="filter-title mr-3">{{$t('message.InputQuickSearch.button.search')}}:</div>
                        <div class="setting-sync-option">
                            <InputSelectSearch :modelValue="channel" :defaultOption="this.optionSearch" :noSearch="true" @update:modelValue="setTypeChannel"></InputSelectSearch>
                        </div>
                        <div class="ml-0 w-100">
                            <div class="d-flex align-items-center form-control-search">
                                <span class="form-control-icon"><i class="fas fa-search"></i></span>
                                <input type="text" name="" class="form-control ng-valid ng-touched ng-dirty" v-model="keyword" @change="searchProducts()" :placeholder="this.channel == this.$constant.omiId ? $t('message.common.enterCodeOrName') : $t('message.common.enterIDOrSKU')">
                                <div class="input-group-append pointer" v-if="keyword?.length > 0" @click="removeKeyword()"><i class="fas fa-times text-danger"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="mapping-connection-filter">
                <div class="mapping-filter-tab">
                    <div class="toolbar-item" :class="sttMapping === this.$constant.allMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.allMapping)">{{$t('message.common.all')}}</div>
                    <div class="toolbar-item" :class="sttMapping === this.$constant.falseMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.falseMapping)">{{$t('message.medicineProductMapping.linked')}}</div>
                    <div class="toolbar-item" :class="sttMapping === this.$constant.successMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.successMapping)">{{$t('message.medicineProductMapping.noLink')}}</div>
                </div>
                <div class="mapping-filter-button justify-content-end">
                    <!-- <div class="button-function sync-inserted">
                        <div class="import-guide">
                            <a class="btn btn-primary btn-icon-left">
                                <i class="fas fa-upload mr-2"></i>
                                Đăng sản phẩm hàng loạt
                            </a>
                        </div>
                    </div>
                    <div class="btn-group sync-inserted ml-2">
                        <a id="buttonGroup" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fas fa-plus-circle mr-1"></i>
                            <span class="btn-group-title mr-1">{{$t('message.button.action')}}</span>
                            <i class="fas fa-chevron-down"></i>
                        </a>
                        <div class="dropdown-menu" aria-labelledby="buttonGroup">
                            <a class="dropdown-item" href="javascript:void(0)">Sao chép hàng đã chọn về Omipos</a>
                            <a class="dropdown-item" href="javascript:void(0)">Sao chép tất cả hàng hóa</a>
                        </div>
                    </div> -->
                </div>
            </div>
        </div>
        <div class="mapping-grid-container">
            <div class="setting-mapping-grid">
                <div class="table-content">
                    <div class="table-content-head">
                        <table class="table mb-0" >
                            <thead>
                                <tr>
                                    <th class="input-checkbox">
                                        <label class="container-checkbox mb-3">
                                            <input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </th>
                                    <th class="shop-name">{{$t('message.common.stall')}}</th>
                                    <th class="shop-product">{{$t('message.common.productOnStall')}}</th>
                                    <th class="omi-product">{{$t('message.medicineProductMapping.productOmipos')}}</th>
                                    <th class="operation text-center">{{$t('message.button.action')}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table-content-body">
                        <table class="table mb-0" >
                            <tbody>
                                <template v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in this.entries" :key="entry.id">
                                        <tr :class="[(index % 2 ? 'bg-light' : ''), (!entry.status ? 'cursor-ban' : false)]">
                                            <td class="input-checkbox">
                                                <label class="container-checkbox">
                                                    <input v-model="entry.checked" type="checkbox" :disabled="!entry.status ? true : false" @change.stop="countChecked(entry.id)"
                                                                :id="entry.id" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                    <span class="checkmark-checkbox"></span>
                                                </label>
                                            </td>
                                            <td class="shop-name">
                                                <div class="d-flex align-items-center">
                                                    <span class="logo-tmdt">
                                                        <template v-for="icon in this.iconChannel" :key="icon.id">
                                                            <img :src="icon.src" alt="" v-if="icon.name == this.typeChannel">
                                                        </template>
                                                    </span>
                                                    <span class="mapping-booth-title" v-text="entry.shopName"></span>
                                                </div>
                                            </td>
                                            <td class="shop-product">
                                                <div class="text-wrapper">
                                                    <div class="font-weight-bold" v-text="entry.productName"></div>
                                                    <div class="setting-mapping-text">
                                                        <a href="javascript:void(0)" v-if="entry.productId">{{$t('message.common.id')}}: <span v-text="entry.productId"></span></a>
                                                        <span v-if="entry.productSku"> - </span><span class="text-warning" v-if="entry.productSku">{{$t('message.common.sku')}}: <span v-text="entry.productSku  "></span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="omi-product">
                                                <template v-if="entry.omiProductCode">
                                                    <div class="text-wrapper">
                                                        <div class="font-weight-bold" v-text="entry.omiProductName"></div>
                                                        <div class="setting-mapping-text">
                                                            <a :href="'/products/index?code=' + entry.omiProductCode" target="_blank">{{entry.omiProductCode}}</a>
                                                            <span> - {{$t('message.ProductUnitSearch.price')}}: {{ n(entry.price) }}</span>
                                                            <span> - {{$t('message.ProductUnitSearch.inventory')}}: {{ n(entry.inventories) }}</span>
                                                            <span> - {{$t('message.ProductUnitSearch.orderFromCustomer')}}: {{ n(entry.quantityOrder) }}</span>
                                                            <span> - {{$t('message.ProductUnitSearch.orderSupplier')}}: {{ n(entry.quantityOrderSupplier) }}</span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <template v-else>
                                                    <ProductUnitsSearch @selectProductUnit="onSelectProductUnit" :readonly="!entry.status ? true : false" :label="entry" :branch="entry.get_shop?.branchId"></ProductUnitsSearch>
                                                </template>
                                            </td>
                                            <td class="operation">
                                                <div class="d-flex justify-content-center mapping-grid-content tooltip-icon-item ng-star-inserted">
                                                    <div class="btn-icon btn-icon-sm btn-circle ng-star-inserted">
                                                        <i class="fas fa-unlink" v-if="entry.omiProductCode" :title="$t('message.medicineProductMapping.removeLinked')" @click="entry.status ? unLink(entry.id) : false"></i>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr class="empty-wrapper">
                                        <div class="row detail-prd-row mx-0">
                                            <div class="col-12 text-center text-secondary mt-5">
                                                <img src="../../../public/img/No-record.png">
                                            </div>
                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                <span class="border-none">{{$t('message.common.notData')}}</span>
                                            </div>
                                        </div>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row paginate-space">
                    <div class="col-7">
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="col-5 d-flex justify-content-end align-items-center">
                        <span class="filter-title">{{$t('message.common.numberRecord')}}:</span>
                        <InputSelectSearch :modelValue="numberRecord" :defaultOption="this.$constant.numberRecords" :noSearch="true" @update:modelValue="setRecords"></InputSelectSearch>
                    </div>
                </div>
            </div>
            <div class="d-flex justify-content-end align-items-center">
                <button class="btn btn-primary" @click="back()">
                    <i class="fas fa-arrow-left mr-2"></i>
                    <span>{{$t('message.common.back')}}</span>
                </button>
            </div>
        </div>
        
    </div>
</template>

<script>
    import {$get, $post, $alert} from "../../lib/utils";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import Pagination from "@/components/Pagination";
    import ProductUnitsSearch from "@/components/ProductUnitsSearch.vue";
    import store from "../../store";
    export default {
        name: "MappingProduct",
        components: {InputSelectSearch, Pagination, ProductUnitsSearch},
        // mounted() {
            
        // },
        created() {
            if(this.$route.query.type){
                this.typeChannel = this.$route.query.type;
                switch (this.typeChannel) {
                    case this.$constant.textShopee:
                        this.optionSearch = this.$constant.optionSearchProductShopee;
                        break;
                    case this.$constant.textLazada:
                        this.optionSearch = this.$constant.optionSearchProductLazada;
                        break;
                    case this.$constant.textTiki:
                        this.optionSearch = this.$constant.optionSearchProductTiki;
                        break;
                    case this.$constant.textTiktok:
                        this.optionSearch = this.$constant.optionSearchProductTiktok;
                        break;
                    case this.$constant.textSendo:
                        this.optionSearch = this.$constant.optionSearchProductSendo;
                        break;
                    default:
                        this.optionSearch = this.$constant.optionSearchProductShopee;
                        break;
                }
            }
            this.getSyncProduct();
        },
        data() {
            return {
                entries: [],
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 10,
                },
                numberRecord: 10,
                page: 1,
                channel: this.$constant.omiId,
                sttSync: true,
                sttMapping: this.$constant.allMapping,
                typeChannel: null,
                iconChannel:[
                    {id: 0, name: this.$constant.textShopee, src: require('../../../public/img/shopee-icon-active.png') },
                    {id: 1, name: this.$constant.textLazada,  src: require('../../../public/img/lazada-icon-active.png') },
                    {id: 2, name: this.$constant.textTiki, src: require('../../../public/img/tiki-icon.png') },
                    {id: 3, name: this.$constant.textTiktok, src: require('../../../public/img/tiktok-icon-active.png') },
                    {id: 4, name: this.$constant.textSendo, src: require('../../../public/img/lazada-icon-active.png') },
                ],
                optionSearch: null,
                keyword: '',
                arrayChecked: [],
                checkedAll: false,
            }
        },
        methods: {
            pageChange(page){
                this.page = page;
                this.getSyncProduct();
            },
            setRecords(records){
                this.numberRecord = records;
                this.page = 1;
                this.getSyncProduct();
            },
            changeSttSync(stt){
                this.sttSync = stt;
                this.page = 1;
                this.getSyncProduct();
            },
            changeSttMapping(stt){
                this.sttMapping = stt;
                this.page = 1;
                this.getSyncProduct();
            },
            async getSyncProduct(){
                
                if (this.$route.query.shopId && this.$route.query.channelId) {
                    const res = await $post('/v1/connection/get-sync-product', {
                        shopId: this.$route.query.shopId,
                        channelId : this.$route.query.channelId,
                        numberRecord: this.numberRecord,
                        page: this.page,
                        branchId: store.state.CurrentBranch.id,
                        statusLink: this.sttMapping,
                        typeChannel: this.channel,
                        keyword: this.keyword,
                        sttSync: this.sttSync,
                    });
                    if(res && res.result){
                       this.paginate.currentPage = res.result.current_page;
                       this.paginate.lastPage = res.result.last_page;
                       this.paginate.totalRecord = res.result.total;
                       this.entries = res.result.data;
                    }
                }
                this.isCheckedAll();
            },
            searchProducts(){
                this.page = 1;
                this.getSyncProduct();
            },
            async onSelectProductUnit(data){                
                let productUnitId = data.data.productUnitId;
                let variantId = data.data.variantId;
                let productBranchMetaId = data.data.productBranchMetaId;
                let shopId = data.label.get_shop.shopId;
                let syncProductId = data.label.id;
                if(!syncProductId || !productUnitId){
                    $alert({ code: 422, message: this.$t('message.medicineProductMapping.message_connect_error')});
                }
                const res = await $post('/v1/products/mapping-product', {
                    variantId: variantId,
                    productUnitId: productUnitId,
                    id: syncProductId,
                });
                
                $alert(res);
                if(res.code == 200){
                    switch (this.typeChannel) {
                        case this.$constant.textShopee:
                            this.updateStockProductShopee(shopId, productBranchMetaId, syncProductId);
                            break;
                        case this.$constant.textLazada:
                            this.updateStockProductLazada(shopId, productBranchMetaId, syncProductId);
                            break;
                        case this.$constant.textTiki:
                        this.updateStockProductTiki(shopId);
                            break;
                        case this.$constant.textTiktok:
                            this.updateStockProductTiktok(shopId);
                            break;
                        case this.$constant.textSendo:
                            // this.optionSearch = this.$constant.optionSearchProductSendo;
                            break;
                    }
                    
                }
                this.getSyncProduct();
            },
            async updateStockProductShopee(shopId, productBranchMetaId, syncProductId){
                const res = await $post('/v1/shopee/update-stock-realTime', {
                    shopId: shopId,
                    productBranchMetaId: productBranchMetaId,
                    syncProductId: syncProductId,
                });
            },
            async updateStockProductLazada(shopId, productBranchMetaId, syncProductId){
                const res = await $post('/v1/lazada/update-stock-realTime', {
                    shopId: shopId,
                    productBranchMetaId: productBranchMetaId,
                    syncProductId: syncProductId,
                });
            },
            async updateStockProductTiktok(shopId){
                await $post('/v1/tiktok/update-stocks', {
                    shopId: shopId,
                });
            },

            async updateStockProductTiki(shopId){
                await $post('/v1/tiki/update-stocks', {
                    shopId: shopId,
                });
            },
            async unLink(id){
                const res = await $post('/v1/products/mapping-product', {
                    productUnitId: null,
                    id: id,
                });
                if(res && res.code == 200){
                    $alert({ code: 200, message: this.$t('message.medicineProductMapping.message_disconnect_success')});
                    this.getSyncProduct();
                }
            },
            setTypeChannel(type){
                this.channel = type;
                this.page = 1;
                this.getSyncProduct();
            },
            removeKeyword(){
                this.keyword= '';
                this.page = 1;
                this.getSyncProduct();
            },
            async back(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/list',
                    query: {}
                });
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
        }
    }
</script>
<style scoped lang="scss">
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.sidebar-item {
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}
.sidebar-item.active{
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.mainRight{
    padding: 1.2rem 3rem;
}

.setting-mapping-wrapper {
    flex-direction: column;
    flex: 1;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.mapping-header {
    background-color: #f5f6f8;
    padding: 1.5rem;
    border-radius: 10px;
}
.filter-block:first-child {
    margin-top: 0;
}
.filter-block {
    margin-top: 1.6rem;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.filter-block .filter-title {
    margin-right: 1rem;
}
.filter-title{
    min-width: 80px !important;
}
.filter-block .filter-list-choose {
    flex: 1;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.btn:not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding: 0 calc(1.1rem + 1px);
    height: 32px;
}
.filter-block .filter-list-choose .btn.filter-active{
    border-color: rgb(47, 161, 161);
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg){
    box-shadow: none!important;
}
.filter-block .filter-list-choose .btn:first-child{
    margin-left: 0;
}
.btn.btn-outline-default{
    border: 1px solid #bebfc2;
}
button:not(:disabled) {
    cursor: pointer;
}
.btn-radius-cicrle {
    border-radius: 999px;
}
.btn{
    outline: none!important;
    margin: 0;
    align-items: center;
    border: none;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.setting-sync-option{
    min-width: 300px !important;
}
.filter-search-block {
    border-top: 1px solid #d7d9de;
    margin-top: 1rem;
    padding-top: 1rem;
}
.form-control-search {
    position: relative;
}
.form-control-search .form-control-icon {
    cursor: pointer;
    color: rgba(31,32,33,.52);
    transform: translateY(-50%);
    position: absolute;
    left: 8px;
    top: 50%;
}
.mapping-header .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.form-control-search input {
    border-color: #d7d9de;
    padding: 0.4rem 2rem;
}
.form-control {
    border-color: transparent;
    padding: 0.43rem 1.2rem;
    height: 32px;
    border-width: 1px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}
.form-control-search .input-group-append {
    color: rgba(31,32,33,.86);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 999px;
    position: absolute;
    top: 55%;
    right: 8px;
}
.mapping-connection-filter .mapping-filter-tab {
    flex: 1;
}
.mapping-connection-filter, .mapping-connection-filter .mapping-filter-tab, .mapping-filter-button {
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.toolbar-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    position: relative;
    border-radius: 25px;
    transition: all .2s ease-in-out;
}
.mapping-connection-filter .toolbar-item {
    font-weight: 600;
}
.toolbar-item.active, .toolbar-item:active, .toolbar-item:hover {
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.mapping-grid-container {
    position: relative;
}

.setting-mapping-grid {
    flex: 1;
    margin-top: 1rem;
}
.table-content-head{
    background-color: #f5f6f8;
    border: 1px solid #d7d9de;
    border-bottom: none !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.table-content-body{
    background-color: #fff;
    border: 1px solid #d7d9de;
    border-top: none !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 400px;
    overflow: auto;
}
.table thead tr th, tbody tr td{
    border-top: none !important;
    border-bottom: none !important;
}
.table tbody tr td{
    border-top: 1px solid #d7d9de !important;
    border-right: 1px dashed #d7d9de;
}
.paginate-space {
    border: none;
    padding: 10px;
}
.input-checkbox{
    width: 5% !important;
    border-right: none !important;
}
.shop-name{
    width: 15% !important;
}
.shop-product, .omi-product{
    width: 35% !important;
}
.operation{
    width: 10% !important;
}
.logo-tmdt img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.dropdown-toggle::after {
    display: none;
}
.empty-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-60%);
}
.btn-icon:hover{
    color: rgb(47, 161, 161);
}
</style>