import {forEach, numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
import {clone} from "@/lib/utils";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {            
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            if(dataValue?.convertProducts && dataValue.convertProducts.length){
                for (let index = 2; index < dataValue.convertProducts.length; index++) {
                    let template = htmlObject.querySelector("#content-product").children[0];
                    let clone = template.cloneNode(true);
                    clone.children[0].innerHTML = "";
                    let node = document.createElement("tbody");
                    node.innerHTML = clone.outerHTML;
                    htmlObject.querySelector("#content-product").appendChild(node);
                }
                templateOrder = htmlObject.outerHTML;
                dataValue.convertProducts.forEach((product) => {
                    product.forEach((data, index) => {
                        templateOrder =
                            templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                            templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                    });
                });
            }                        
            return templateOrder;
         },
        getPriceImportPrint(id, productUnitId, importForSupplier){
            let price = 0;
            if(importForSupplier?.details){
                importForSupplier.details.forEach(detail => {
                    if(detail.productId == id && detail.productUnitId == productUnitId){
                        price = detail.price - (detail.discount / detail.quantity);
                    }
                });
            }
            return price;
        },
        getCategories(product){
            let categories = '';
            if(product && product?.categories && product.categories.length > 0) {
                product.categories.forEach(cat => {
                    if(categories == '') categories = cat.name?? '';
                    else categories = categories + (cat?.name && cat.name != '' ? ', ' + cat.name : '')
                });
            }
            return categories;
        },
        getParamProduct(prd, discount, Chiet_Khau_Phan_Tram, Don_Gia_Sau_Chiet_Khau, priceImport, type, categories, data){
            let product = {
                Ma_Hang: prd.product.code?? '',
                    Ten_Hang_Hoa: prd.product.name?? '',
                    Anh_San_Pham: this.getPrdImage(prd.product.image)?? null, 
                    Don_Gia: numberFormat(prd.price),
                    So_Luong: prd.quantity,
                    Thanh_Tien: numberFormat(prd.subTotal),
                    //Nhập hàng, Đặt hàng nhập
                    Chiet_Khau: discount,
                    Gia_Tri_Chiet_Khau: discount,
                    Chiet_Khau_Phan_Tram: Chiet_Khau_Phan_Tram,
                    Don_Gia_Sau_Chiet_Khau: Don_Gia_Sau_Chiet_Khau,
                    // Tra hàng
                    Gia_Nhap: numberFormat(priceImport), 
                    Don_Gia_Chiet_Khau:  numberFormat(prd.price-discount),
                    Giam_Gia_Tra_Lai: discount,
                    Don_Vi_Tinh: prd.product_unit ? prd.product_unit.unitName : (prd.unit ? prd.unit.unitName : ''),
                    Hoat_Chat: prd.product.activeIngredient ? prd.product.activeIngredient : '',
                    Hang_San_Xuat: prd.product.manufacturer ? prd.product.manufacturer : '',
                    Nuoc_San_Xuat: prd.product.country ? prd.product.country : '',
                    Quy_Cach_Dong_Goi: prd.product.unit ? prd.product.unit : '',
                    Mo_Ta_Hang_Hoa: prd.product.description ? prd.product.description : '',
                    HS_Code: prd.product.hsCode ? prd.product.hsCode : '',
                    Thue_Suat: prd.product.taxRate ? prd.product.taxRate + '%' : '',
                    // Nhom_Hang: this.getCategories(prd.product.product_category)?? '',
                    Lo: type !== this.$constant.TypeOrderSuppliers ? (prd.consignment ? prd.consignment.name : '') : '',
                    Han_Su_Dung: prd.consignment ? prd.consignment.expried : '',
                    So_Luong_Lo: numberFormat(prd.quantity),
                    Don_Gia_Tra: numberFormat(prd.price),

                    Nhom_Khach_Hang: categories,
                    Ghi_Chu_Hang_Hoa: data.description?? '',
                    Vi_Tri_Hang_Hoa: '',                   
                    Thuong_Hieu: '',
                    DS_Don_Vi_Tinh_Quy_Doi_SL: '',                    
                    Gia_Chung: prd.product.product_unit_main ? prd.product.product_unit_main.price : 0,
            }
            return product;
        },
        getProduct(entry, type){            
            let data = entry.details;            
            let products = [];
            let discount = 0;
            let Don_Gia_Sau_Chiet_Khau = 0;
            let Chiet_Khau_Phan_Tram = 0;
            let categories = null;
            let prd = null;
            if(data){
                data.forEach(prd => {                
                    categories = this.getCategories(prd.product);                
                    discount = numberFormat(prd.discount/prd.quantity); 
                    if(prd.discountType == this.$constant.discountTypePercent) Chiet_Khau_Phan_Tram = (prd.discount / prd.subTotal) * 100;
                    if(prd.discountType == this.$constant.discountTypePercent) Don_Gia_Sau_Chiet_Khau = (prd.price - prd.discount) * 100;
                    let priceImport = 0
                    if (entry.import_for_supplier) {
                        priceImport = +prd.import_supplier_detail.price - +(prd.import_supplier_detail.discount ?? 0 )/prd.import_supplier_detail.quantity;
                    }
                    // Push hàng hóa thường
                    prd = this.getParamProduct(prd, discount, Chiet_Khau_Phan_Tram, Don_Gia_Sau_Chiet_Khau, priceImport, type, categories, data);
                    products.push(prd);
                    
                    // Push hàng hóa combo
                    //Combo:
                    //  Ma_Hang_TP_Combo: this.getCodePrdCombo(prd.product.combo_products),
                    //  Ten_Hang_Hoa_TP_Combo: this.getNamePrdCombo(prd.product.combo_products),
                    // Ma_Hang_TP_Combo: '',
                    // Ten_Hang_Hoa_TP_Combo: '',
                    // So_Luong_TP_Combo: '',
                    // Don_Vi_Tinh_TP_Combo: '',
                });
            }           
            return products;
        },
        getCodePrdCombo(combo){
            let code = '';
            if(combo && combo.length > 0){
                combo.forEach(co => {
                    if(code && code == '') code = co.product.code;
                    else code = code + co?.product && co.product?.code ? co.product.code : '';
                });
            }
            return code;
        },
        getNamePrdCombo(combo){
            let name = '';
            if(combo && combo.length > 0){
                combo.forEach(co => {
                    if(name && name == '') name = co.product.code;
                    else name = name + co?.product && co.product?.code ? co.product.code : '';
                });
            }
            return name;
        },
        getPrdImage(img){
            let url = '';
            if(img && img.length > 0){
                img.forEach(i => {
                    url = i.url
                });
            }
            return url;
        },
        getExpriedDate(data){            
            let exDate = '';
            data.forEach(item => {
                if(exDate == '') exDate = item.name + ' ' + moment(item.expried).format('DD/MM/YYYY');
                else exDate = exDate + ', ' + item.name + ' ' + moment(item.expried).format('DD/MM/YYYY');
            });            
            return exDate;
        },
        getConsignments(data){
            let con_name = '';          
            data.forEach(item => {
               if(con_name == '') con_name = item.name;
               else con_name = con_name + ', ' + item.name;           
          });          
          return con_name;
        },
        getQuantityProduct(entry){
            let total = 0;
            entry.forEach(item => {
                        total = total + item.quantity;
                    });   
            return total;
        },
        getPhoneHide(phone){
            let phoneConvert = '';
            for (let index = 0; index < phone.length; index++) {
                if(index <= phone.length - 3){
                     phoneConvert = phoneConvert + '*';
                }
                else {
                     phoneConvert = phoneConvert + phone[index];
                }    
             }
            return phoneConvert;
        },
        getCusPhone(customer, status = false){
            let phone;
            if(customer && customer.contactNumber != null) {
                phone = customer.contactNumber;
            }
            else {
                if(customer && customer.contactNumber1 != null){
                    phone = customer.contactNumber1;
                }                
            }
            if(status == true)  phone = this.getPhoneHide(phone);            
            return phone;
        },
        renderDataDelivery(dataValue){                   
            this.editorDataClone = this.content;            
            let image = null;          
            let barcode = ''; 
            if(dataValue && dataValue.common){
                dataValue.common.forEach(data => { 
                    let image = '<img src="' + data.value + '">';                    
                    switch (data.key) {      
                        case 'Logo_Cua_Hang':
                            image = '<img src="' + data.value + '">';                
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', image)?? '';
                            break;
                        case 'Sort_Code':
                                if(data?.value && data.value != null){
                                    barcode = `<img id="barcode" src='https://api.qrserver.com/v1/create-qr-code/?data=`+ data.value +`&amp;'
                                    alt="" title=`+ data.value +` width="80" height="80" />`
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);                                
                                }
                                else {
                                    this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                                }
                                
                        break;
                        case 'Ma_Van_Don_Ma_Vach':   
                            if(data?.value && data.value != null){
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                            }
                        break;
                        case 'Ma_Vach_Hoa_Don':   
                            if(data?.value && data.value != null){
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);
                            }
                            else {
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                            }
                            break;  
                        case 'Ma_Vach_Khach_Hang':   
                            if(data?.value && data.value != null){                                
                                barcode = `<img id="barcode" src='https://barcode.tec-it.com/barcode.ashx?data=`+ data.value +`%0A&code=Code128'
                                alt="" title=`+ data.value +` width="150"  height="100" />`
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', barcode);                                
                            }
                            else {                                
                                this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', ''); 
                            }
                            break;                      
                        case 'Tong_So_Luong':                            
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;
                        case 'Tong_Tien_Hang':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;
                        case 'Giam_Gia_Dat_Hang_Nhap':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;
                        case 'Muc_Chi_Phi_Nhap_Hang':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;
                        case 'Tong_Cong':                        
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;
                        case 'Muc_Chi_Phi_Nhap_Hang_Khac':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', numberFormat(data.value))?? null;
                            break;         
                        case 'Chi_Phi_Nhap_Hang':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng')?? null;
                            break;    
                        case 'Chi_Phi_Nhap_Hang_Khac':
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', 'Chi phí nhập hàng khác')?? null;
                            break;                                     
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', data.value ) ?? null;
                            break;
                    }                  
                });
            }              
            return this.formatShowProduct(dataValue, this.editorDataClone);
        },
        getStatusName(type, status){
            let  name = '';
            let lstStatus = null;
            switch (type) {
                case 'impForSupplier':                    
                    lstStatus = this.$constant.listStatusImportOrder;
                    break;
                case 'ordForSupplier':
                    lstStatus = this.$constant.listStatusOrderSupplier;
                    break;
                case 'returnForSupplier':
                    lstStatus = this.$constant.listStatusReturnOrderSupplier;
                    break;            
                default:
                    break;
            }     
            lstStatus.forEach(item => {
                if(item.id == status) name = this.$t(item.name);
            });            
            return name;
        },
        getNameTypePayment(entry){            
            let name = '';
            if(entry.cash != 0 ) name = name + 'Tiền mặt'
            if(entry.card != 0) name = name + ', ' + 'Thẻ'
            if(entry.transfer != 0) name = name + ', ' + 'Chuyển khoản'            
            return name;  
        },
        getValueTypePayment(entry){            
            let value = '';
            if(entry.cash !== 0) value = value + numberFormat(entry.cash);
            if(entry.card !== 0) value = value + '; ' +numberFormat( entry.card);
            if(entry.transfer !== 0) value = value + '; ' + numberFormat(entry.transfer);            
            return value;  
        },
        getPaymentForSupplier(entry){            
            let totalPrice = numberFormat((Number(entry.cash) + Number( entry.card) + Number(entry.transfer)) - Number(entry.paymentForSupplier));               
            return totalPrice;  
        },
        methodPayment(invoice){            
            let method = '';
            if(invoice?.cash && invoice.cash > 0) method = method + 'Tiền mặt';
            if(invoice?.card && invoice.card > 0) method = method + '; Tiền thẻ';
            if(invoice?.transfer && invoice.transfer > 0) method = method + '; Chuyển khoản';
            if(invoice?.voucher && invoice.voucher > 0) method = method + '; Voucher';            
            return method;
        },
        priceMethodPayment(invoice){
            let method = '';
            if(invoice?.cash && invoice.cash > 0) method = method + numberFormat(invoice.cash);
            if(invoice?.card && invoice.card > 0) method = method + '; ' + numberFormat(invoice.card);
            if(invoice?.transfer && invoice.transfer > 0)  method = method + '; ' + numberFormat(invoice.transfer);
            if(invoice?.voucher && invoice.voucher > 0) method = method + '; ' + numberFormat(invoice.voucher);
            return method;
        },
        getTransporter(entry){            
            let name = '';
            if(entry.transporterId == this.$constant.ahamoveId){
                name = 'Ahamove'
            }
            else {
                if(entry && entry?.transporter){
                    name = entry.transporter.name;
                }
            }            
            return name;
        },
        getDisProvince(data, type){
            let district = '';
            let province = '';
            let location = '';
            switch (type) {
                case this.$constant.typeLocationCustomer:
                    district = data && data?.address2 && data.address2?.districts ? data.address2.districts.name : '';
                    province = data && data?.address2 && data.address2?.provinces ? data.address2.provinces.name : '';
                    location = district + ' - ' +province;
                    break;
            
                default:
                    break;
            }       
            return location;     
        },
        getWards(data, type){            
            let ward = '';
            switch (type) {
                case this.$constant.typeLocationCustomer:
                    ward = data && data?.address2 && data.address2?.wards ? data.address2.wards.name : '';
                    break;            
                default:
                    break;
            }       
            return ward;     
        },
        getTotalQuantity(products){
            let totalQuantity = 0;
            if(products){
                products.forEach(prd => {
                    // totalQuantity = totalQuantity + prd.quantity;
                    totalQuantity = totalQuantity + prd.So_Luong;
                    
                });
            }            
            return totalQuantity;
        },       
        async dataPrintDelivery(entry) {                               
            let convertProducts = [];
            let products = this.getProduct(entry, entry.typePrint);
            // eslint-disable-next-line no-unreachable
            let common = [
                {key:'Nguoi_Tao',value: entry.user_created.name?? null},
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry.branch ? entry.branch.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: entry.branch ? entry.branch.address : ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch ? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_gian_tao_phieu', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ngay_Thang_Nam_Tao', value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: entry.code},
                {key:'Nhan_Vien_Ban_Hang', value: entry.sold_by ? entry.sold_by.name : ''},
            ];  
            let totalQuantity = 0;          
            let keyOrderSupplier = [];
            switch (entry.typePrint) {
                case this.$constant.TypePrintDeliveries:
                    keyOrderSupplier = [                        
                        {key: 'Tieu_De_In',value: "PHIẾU GIAO HÀNG"},                       
                        {key: 'Phuong_Xa_Cua_Hang', value: entry.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},
                        {key: 'Khu_Vuc_Cua_Hang', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                        {key: 'Khu_Vuc_Cua_Hang_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                        {key: 'So_Dien_Thoai_Cua_Hang', value: (entry.branch ? entry.branch.phone : '')},
                        {key: 'Chi_Nhanh_Ban_Hang', value: (entry.branch ? entry.branch.name : '')},
                        {key: 'Khu_Vuc_Chi_Nhanh', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                        {key: 'Khu_Vuc_Chi_Nhanh_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},                        
                        {key: 'Dia_Chi_Lay_Hang', value: entry.branch ? entry.branch.address : ''},                                                
                        {key: 'Dien_Thoai_Lay_Hang', value: entry.branch ? entry.branch.phone : ''},                        
                        {key: 'Ngay_Thang_Nam',value: entry.createdAt ? moment(entry.createdAt).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                        {key: 'Phuong_Thuc_Thanh_Toan', value: this.methodPayment(entry.invoice) ?? null},
                        {key: 'Ten_Phuong_Thuc_TToan', value: this.methodPayment(entry.invoice) ?? null},
                        {key: 'Tien_Theo_Phuong_Thuc_TToan', value: this.priceMethodPayment(entry.invoice)?? null},
                        {key: 'Kenh_Ban_Hang', value: entry?.invoice && entry.invoice?.sale_channel && entry.invoice.sale_channel?.name ? entry.invoice.sale_channel.name : null},                         
                        {key: 'Ma_Dat_Hang',  value: entry?.invoice && entry.invoice?.order && entry.invoice.order?.code ? entry.invoice.order.code : null},
                        {key: 'Ma_Vach_Hoa_Don',value: entry?.invoice && entry.invoice?.code ? entry.invoice.code : null},
                        {key: 'Ma_Vach_Khong_Ma_Hoa_Don', value: entry?.invoice && entry.invoice?.code ? entry.invoice.code : null},
                        {key: 'Nhan_Vien_Ban_Hang', value: entry.sold_by ? entry.sold_by.name : ''},
                        {key: 'Dien_Thoai_Nguoi_Ban', value: entry.sold_by ? entry.sold_by.phone : ''},
                        {key: 'Thu_Ngan', value: entry.sold_by ? entry.sold_by.name : ''},
                        {key: 'Ma_Don_Hang',  value: entry?.invoice && entry.invoice?.code ? entry.invoice.code : null},
                        {key: 'Trang_Thai',  value: entry?.invoice && entry.invoice?.statusName ? entry.invoice.statusName : null},

                        //Thông tin khách hàng
                        {key: 'So_Tai_Khoan',  value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.accountNumber ? entry.invoice.customer.accountNumber : null},                        
                        {key: 'Ma_So_Thue', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.taxCode? entry.invoice.customer.taxCode : null},
                        {key: 'Cong_Ty',value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.companyName ? entry.invoice.customer.companyName : null},
                        {key: 'Ma_Vach_Khach_Hang',  value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.code ? entry.invoice.customer.code : null},
                        {key: 'Ma_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.code ? entry.invoice.customer.code : null},
                        {key: 'Ma_Khach_Hang_An', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.name? entry.invoice.customer.name : null},
                        {key: 'Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.name? entry.invoice.customer.name : null},
                        {key: 'Nhom_Khach_Hang', value: entry?.invoice && entry.invoice?.customerGroup ? entry.invoice.customerGroup : null},
                        {key: 'So_Dien_Thoai', value: entry?.invoice && entry.invoice?.customer ?  this.getCusPhone(entry.invoice.customer) : ''  },
                        {key: 'So_Dien_Thoai_An', value: entry?.invoice && entry.invoice?.customer ?  this.getCusPhone(entry.invoice.customer, true) : ''  },
                        {key: 'Dia_Chi_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.address?  entry.invoice.customer.address: null},                        
                        {key: 'Ngay_Sinh_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.birthDate ? moment(entry.invoice.customer.birthDate).format('DD/MM/YYYY') : null},
                        {key: 'Email_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.email? entry.invoice.customer.email : null},
                        {key: 'Facebook_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.facebookUrl? entry.invoice.customer.facebookUrl : null},
                        // Thông tin địa chỉ khách hàng                       
                        {key: 'Khu_Vuc_Khach_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },
                        {key: 'Khu_Vuc_Khach_Hang_QH_TP', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },
                        {key: 'Phuong_Xa_Giao_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getWards(entry.invoice.customer, this.$constant.typeLocationCustomer) : ''},
                        {key: 'Phuong_Xa_Khach_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getWards(entry.invoice.customer, this.$constant.typeLocationCustomer) : ''},

                        {key: 'Tien_Khach_Dua', value: entry?.invoice && entry.invoice?.totalPayment ? numberFormat(entry.invoice.totalPayment) : 0},
                        {key: 'Tien_Khach_Dua_Bang_Chu', value: entry?.invoice && entry.invoice?.totalPayment ? this.toVietnamWord(numberFormat(entry.invoice.totalPayment)) : this.toVietnamWord(0)},                        
                        {key: 'Nguoi_Nhan', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.name? entry.invoice.customer.name : null},
                        {key: 'Dien_Thoai', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.contactNumber? entry.invoice.customer.contactNumber : null},
                        {key: 'Dia_Chi_Giao_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.address? entry.invoice.customer.address : null},                        
                        {key: 'Khu_Vuc_Giao_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },
                        {key: 'Khu_Vuc_Giao_Hang_QH_TP', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : ''},
                        
                        // Thông tin vận đơn
                        {key: 'Trong_Luong_Giao_Hang', value: entry && entry?.weight ? entry.weight : ''},                        
                        {key: 'Loai_Dich_Vu', value: entry && entry?.service_type ? entry.service_type.name : ''},
                        {key: 'Doi_Tac_Giao_Hang', value: this.getTransporter(entry)},
                        {key: 'Thoi_Gian_Giao',value: entry && entry?.deliveryTime ? entry.deliveryTime : ''},
                        {key: 'Phi_Tra_DTGH', value: entry && entry?.price ? entry.price : ''},
                        {key: 'Tong_Cuoc_Phi_Giao', value: entry && entry?.price ? entry.price : ''},
                        {key: 'Thu_Tu_Nguoi_Nhan', value: entry?.amountCollectReceiver ? numberFormat(entry.amountCollectReceiver) : 0},                  
                        {key: 'Ma_Van_Don', value: entry && entry?.code ? entry.code : ''},
                        {key: 'Ma_Van_Don_Ma_Vach',  value: entry && entry?.code ? entry.code : ''},
                        {key: 'Ma_Van_Don_Ma_QR', value: entry && entry?.code ? entry.code : ''},
                        {key: 'Tong_Thu_Nguoi_Nhan', value: entry?.amountCollectReceiver ? numberFormat(entry.amountCollectReceiver) : 0},
                        {key: 'Luu_Y_Khi_Phat', value: ''},
                        {key: 'Ghi_Chu_Giao_Hang', value: entry && entry?.note ? entry.note : ''},
                        {key: 'Sort_Code', value: entry && entry?.code ? entry.code : ''},
                        {key: 'Sort_Code_Ma_Vach', value: entry && entry?.code ? entry.code : ''},
                        {key: 'Ghi_Chu_Khach_Hang', value: entry?.invoice && entry.invoice?.customer && entry.invoice.customer?.comments? entry.invoice.customer.comments : null}, 
                        {key: 'Tong_So_Luong', value: this.getTotalQuantity(products)},
                        {key: 'Don_Vi_Trong_Luong', value: entry && entry?.weightUnitValue ? entry.weightUnitValue : ''},
                        
                        
                        // {key: 'Khu_Vuc_Khach_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },
                        // {key: 'Khu_Vuc_Khach_Hang_QH_TP', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },
                        // {key: 'Phuong_Xa_Khach_Hang', value: entry?.invoice && entry.invoice?.customer ? this.getWards(entry.invoice.customer, this.$constant.typeLocationCustomer) : ''},
                        // // Khu vực giao hàng này khả năng lấy bảng khác
                        // {key: 'Khu_Vuc_Giao_Hang_QH_TP', value: entry?.invoice && entry.invoice?.customer ? this.getDisProvince(entry.invoice.customer, this.$constant.typeLocationCustomer) : '' },

                        // {key: 'STT', value: ''},
                        // {key: 'STT_Hang_Thanh_Phan', value: ''},
                        // {key: 'Ghi_Chu_Khach_Hang', value: ''},
                        // {key: 'Du_No_Hien_Tai', value: ''},
                        // {key: 'Du_No_Hien_Tai_Bang_Chu', value: ''},
                        // {key: 'Du_No_Truoc', value: ''},
                        // {key: 'Du_No_Sau_Tao_Hoa_Don', value: ''},
                        // {key: 'Du_No_Sau_Tao_Hoa_Don_Bang_Chu', value: ''},
                        // {key: 'Du_No_Hoa_Don', value: ''},
                        // {key: 'Du_No_Hoa_Don_Bang_Chu', value: ''},
                        // {key: 'Du_No_Truoc_Tao_Hoa_Don', value: ''},
                       
                    ];
                    break;                       
            }                           
            common.push(...keyOrderSupplier); 
            products.forEach(product => {                
                totalQuantity = totalQuantity + parseInt(product.So_Luong);
                convertProducts.push([
                    {key:'Ma_Hang', value: product.Ma_Hang?? null},
                    {key: 'Ma_Vach_HH', value: product.Ma_Hang?? null},
                    {key:'Ten_Hang_Hoa',value: product.Ten_Hang_Hoa?? null},
                    {key:'Don_Gia',value: product.Don_Gia?? null},
                    {key:'So_Luong',value: product.So_Luong?? null},
                    {key:'Thanh_Tien',value: product.Thanh_Tien?? null},
                    {key:'Chiet_Khau',value: product.Chiet_Khau?? null},
                    {key:'Gia_Tri_Chiet_Khau',value: product.Gia_Tri_Chiet_Khau?? null},
                    {key:'Chiet_Khau_Phan_Tram',value: product.Chiet_Khau_Phan_Tram?? null},
                    {key:'Don_Gia_Sau_Chiet_Khau',value: product.Don_Gia_Sau_Chiet_Khau?? null},
                    {key:'Gia_Nhap',value: product.Gia_Nhap?? null},
                    {key:'Don_Gia_Chiet_Khau',value: product.Don_Gia_Chiet_Khau?? null},
                    {key:'Giam_Gia_Tra_Lai',value: product.Giam_Gia_Tra_Lai?? null},
                    {key:'Don_Vi_Tinh',value: product.Don_Vi_Tinh?? null},
                    {key:'Hoat_Chat',value: product.Hoat_Chat?? null},
                    {key:'Hang_San_Xuat',value: product.Hang_San_Xuat?? null},
                    {key:'Nuoc_San_Xuat',value: product.Nuoc_San_Xuat?? null},
                    {key:'Quy_Cach_Dong_Goi',value: product.Quy_Cach_Dong_Goi?? null},
                    {key:'Mo_Ta_Hang_Hoa',value: product.Mo_Ta_Hang_Hoa?? null},
                    {key:'HS_Code',value: product.HS_Code?? null},
                    {key:'Thue_Suat',value: product.Thue_Suat?? null},
                    {key:'Nhom_Hang',value: product.Nhom_Hang?? null},
                    {key:'Lo',value: product.Lo?? null},
                    // {key:'Lo',value: this.getConsignmentName(product, entry.invoice.invoice_items)?? null},
                    {key:'Han_Su_Dung',value: product.Han_Su_Dung?? null},
                    {key:'Gia_Chung',value: product.Gia_Chung?? null},
                    {key:'So_Luong_Lo',value: product.So_Luong_Lo ?? null},
                    {key:'Don_Gia_Tra',value: product.Don_Gia_Tra ?? null},
                    // Bổ sung
                   
                    {key: 'Ghi_Chu_Hang_Hoa', value: product.Ghi_Chu_Hang_Hoa},
                    {key: 'Vi_Tri_Hang_Hoa', value: product.Vi_Tri_Hang_Hoa},
                    {key: 'Anh_San_Pham', value: product.Anh_San_Pham},
                    {key: 'Thuong_Hieu', value: product.Thuong_Hieu},
                    {key: 'DS_Don_Vi_Tinh_Quy_Doi_SL', value: product.DS_Don_Vi_Tinh_Quy_Doi_SL},
            
                ]);
            });                                                     
            return {common, convertProducts};
        },
        getConsignmentName(product, invoice_items){
            let consignmentName = [];
            invoice_items.forEach(item => {
                
            });
            return consignmentName;
        },
        formatproductPrintCommon(data) {
            let dataPrint = clone(data);
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            let exsitProductIds = [];
            dataPrint.details.forEach(item => {
                if (!exsitProductIds.includes(item.productId)) {
                    exsitProductIds.push(item.productId);
                }
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }
                let product = clone(item);
                product.product.name = product.product.name + ' ( ' + product.unit.unitName + ' )';
                if (product.consignment_detail && product.consignment_detail.length) {
                    product.consignment_detail.forEach(detail => { 
                        product.consignment = {};
                        product.consignment.name = detail.consignment.name,
                        product.consignment.expried = detail.consignment.expried,
                        product.quantity = detail.quantity,
                        product.discount = (item.discount/item.quantity) * detail.quantity;
                        if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                            product.subTotal = product.price*detail.quantity;
                        } else {
                            product.subTotal = (product.price*detail.quantity) - product.discount;
                        }
                        totalPrice += +product.subTotal;
                        products.push(clone(product));
                    });
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));
                }
                if (product && product.plus) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            plus.product.consignments = product.product.consignments;
                            plus.consignment_detail.forEach(detail => {
                                plus.consignment = {};
                                plus.consignment.name = detail.consignment.name;
                                plus.consignment.expried = detail.consignment.expried;
                                plus.quantity = detail.quantity;
                                plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                                if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                                    plus.subTotal = plus.price*detail.quantity;
                                } else {
                                    plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                                }
                                totalPrice += +plus.subTotal;
                                products.push(clone(plus));
                            });
                        } else {
                            totalPrice += +plus.subTotal;
                            products.push(clone(plus));
                        }
                    });
                }
            });
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            dataPrint.exsitProductIds = exsitProductIds;
            return dataPrint;
        },
    },
}
  