<template>
    <div class="customer-tab tab-info">
        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/customers/index" class="btn btn-default">
                        {{$t('message.common.back')}}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="title">
            Thông tin lịch sử bán hàng/ trả hàng
        </div>
        <table class="table table-index table-striped">
            <thead>
            <tr>
                <th scope="col">{{$t('message.InputQuickSearch.title.code_invoice')}}</th>
                <th scope="col">{{$t('message.common.time')}}</th>
                <th scope="col">{{$t('message.customerIndex.sellerName')}}</th>
                <th scope="col">{{$t('message.OrderIndex.total')}}</th>
                <th scope="col">{{$t('message.common.status')}}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(invoice, index) in invoices" :key="'row-' + index">
                <td>
                    <router-link class="btn " :to="'/returns/form?id=' + invoice.returns[0].id" v-if="invoice.returns && invoice.returns.length > 0">
                        <span v-text="invoice.returns[0].code"></span>
                    </router-link>
                    <router-link class="btn " :to="'/invoices/form?id=' + invoice.id" v-else>
                        <span v-text="invoice.code"></span>
                    </router-link>
                </td>
                <td v-text="invoice.returns && invoice.returns.length > 0 ? d(invoice.returns[0].returnDate) : d(invoice.createdDate)"> </td>
                <td v-text="invoice.soldByName"></td>
                <td v-text="invoice.returns && invoice.returns.length > 0 ? n(invoice.returns[0].totalPayment) : n(invoice.totalPayment)"></td>
                <td v-text="invoice.returns && invoice.returns.length > 0 ? (returnStatus[invoice.returns.status + 1] || '') : invoice.statusValue"></td>
            </tr>
            </tbody>
        </table>
        <div class="row">
            <button class="btn btn-primary ml-auto mr-3">Xuất file</button>
        </div>

    </div>
    <Paginate :value="paginate" @change="pageChange"/>
</template>

<script>
import {$alert, $get, setArray} from "@/lib/utils";
import Paginate from "@/components/Paginate";

export default {
    name: "TabOrderHistory",
    props: ['id'],
    components: {
        Paginate
    },
    data() {
        return {
            invoices: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            returnStatus:
            [
                'Đã trả',
                'Đã hủy'
            ]

        }
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    },
    methods: {
        async load() {
            const res = await $get('/v1/customers/dataInvoice', this.$route.query);
            if (res.code !== 200) {
                $alert(res);
                return;
            }

            setArray(res.data.entries, {
                checked: false
            });

            this.fields = res.data.fields;
            this.paginate = res.data.paginate;
            this.invoices = res.data.entries;

        },
        async pageChange(page) {
            await this.$router.push({query: {page: page}});
            await this.load();
        },

    }
}
</script>

<style scoped>

</style>