<template>
    <div class="row mr-1">
        <div class="col-8 col-xl-9 pl-4" :class="entry.products && entry.products.length ? 'col-left' : ''">            
            <table class="table table-index table-striped w-100">
                <thead class="text-center min-w-100">
                <tr class="w-100">
                    <th scope="col" class="cell-check" style="width: 2%"></th>
                    <th scope="col" class="cell-check" style="width: 3%">{{$t('message.common.STT')}}</th>
                    <th scope="col" class="cell-name" style="width: 20%">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                    <th scope="col" class="cell-name" style="width: 20%">{{$t('message.DetailOrdersCard.prodName')}}</th>
                    <th scope="col" class="" style="width: 10%">{{$t('message.DetailOrdersCard.dvt')}}</th>
                    <th scope="col" class="cell-auto" style="width: 5%">{{$t('message.DetailOrdersCard.attribute')}}</th>
                    <th scope="col" class="cell-auto" style="width: 10%">{{$t('message.common.shortQuantity')}}</th>
                    <th scope="col" class="cell-name" style="width: 15%" v-if="this.entry.type != TYPE_TRANSFER">
                        <span v-if="this.entry.type == TYPE_EXPORT">{{$t('message.common.outputLocation')}}</span>
                        <span v-else>{{$t('message.common.entryLocation')}}</span>
                    </th>
                    <th scope="col" class="cell-auto" style="width: 10%" v-if="this.entry.type != TYPE_TRANSFER">
                        {{$t('message.common.locationCode')}}
                    </th>    
                    <th scope="col" class="cell-name" style="width: 15%" v-if="this.entry.type == TYPE_TRANSFER">
                        {{$t('message.TransferPositionIndex.table.from_location')}}
                    </th>
                    <th scope="col" class="cell-auto" style="width: 15%" v-if="this.entry.type == TYPE_TRANSFER">
                        {{$t('message.TransferPositionIndex.table.to_location')}}
                    </th>                
                    <th scope="col" class="cell-check" style="width: 5%"></th>                 
                </tr>
                </thead>
                <tbody class="font-weight-bold">
                    <template v-if="entry.products && entry.products.length">
                        <tr class="trSearch">
                            <td></td>
                            <td></td>
                            <td>
                                <input type="text" v-model="this.searchCode" @input="doFilter" class="format__input bg-transparent text-left mb-0" :placeholder="$t('message.DetailOrdersCard.searchProductCode')">
                            </td>
                            <td>
                                <input type="text" v-model="this.searchName" @input="doFilter" class="format__input bg-transparent text-left mb-0" :placeholder="$t('message.DetailOrdersCard.searchProductName')">
                            </td>
                            <td :colspan="6"></td>                            
                        </tr>
                        <template v-for="(product, index) in entry.products" :key="product.code">
                            <template v-if="product.show">
                                <!-- Hiển thị thông tin sản phẩm chính -->
                                <tr>
                                    <td class="text-center font-weight-normal">
                                        <i class="fas fa-trash-alt" @click="deleteProduct(index)" v-if="this.entry.type && this.entry.type == TYPE_TRANSFER"></i>
                                    </td>
                                    <td class="text-center font-weight-normal" v-text="index + 1"></td>
                                    <td class="text-center font-weight-normal" v-text="product.code"></td>
                                    <td class="font-weight-normal">
                                        {{ product.name }}
                                        <div class="position-relative">
                                            <input type="text" v-model.trim="product.description" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                        </div>
                                    </td>
                                    <td class="text-center font-weight-normal">
                                        <InputSelect v-model="product.productUnitId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                            :options="product.productUnits"
                                            option-label="unitName"
                                            :cardTransaction="true"
                                            :marginAuto="true"
                                            @change="getListLocationForProduct(product, (product.hasVariant ? true : false))"
                                        ></InputSelect>
                                    </td> 
                                    <td class="text-center font-weight-normal">
                                        <template v-if="product.hasVariant">
                                            <InputSelect v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="product.variants"
                                                v-if="product?.variants && product.variants.length"
                                                option-label="name"  
                                                :marginAuto="true"                                                                                
                                                :cardTransaction="true"
                                                @update:defaultValue="value => updateAttribute(product, value)">
                                            </InputSelect>
                                        </template>
                                        <!-- <InputSelect v-model="product.variantId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                            :options="product.attributesActive"
                                            v-if="product?.attributesActive && product.attributesActive.length"
                                            option-label="name"                                                                                  
                                            :cardTransaction="true">
                                        </InputSelect> 
                                         <InputSelect v-model="product.variantId"
                                            :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                            :options="product.variant"
                                            v-if="product?.variant && product.variant.length"
                                            option-label="name"                                                                                  
                                            :cardTransaction="true">
                                        </InputSelect>  -->
                                    </td>                                                             
                                    <td class="text-center font-weight-normal">
                                        <div class="position-relative" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <input type="number" @change="product.product && !product.product.isConsignment ? getListLocationForExport(product) : null" v-model.trim="product.quantity" :readonly="product.product && product.product.isConsignment ? true : false" :class="product.product && product.product.isConsignment ? 'cursor-ban' : ''" class="format__input bg-transparent text-right mb-0">
                                            <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment" v-if="product.infoImport && product.infoImport.length">
                                                <div v-for="(info, index) in product.infoImport" :key="index" class="d-flex">
                                                    <div class="actualQuantityValue ml-4">{{ info.unitName }}: </div>
                                                    <div class="actualQuantityValue ml-1">{{ info.quantity }}</div>
                                                </div>
                                            </div>
                                            <div class="dropdown-menu dropdownSupplier p-2" aria-labelledby="dropdownMenuButtonDepartment" v-if="product.infoExport && product.infoExport.length">
                                                <div v-for="(info, index) in product.infoExport" :key="index" class="d-flex">
                                                    <div class="actualQuantityValue ml-4">{{ info.unitName }}: </div>
                                                    <div class="actualQuantityValue ml-1">{{ info.quantity }}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                        <template v-if="product.product && !product.product.isConsignment">
                                            <SelectSearch :title="true" :placeholder="$t('message.DeliveryIndex.position')" :modelValue="product.locationId" :defaultOption="product.locations" @update:modelValue="selectLocation" :label="'location' + product.id + '_' + index"></SelectSearch>
                                        </template>
                                    </td>
                                    <td class="text-left font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                        <span v-if="product.product && !product.product.isConsignment" v-text="product.locationCode"></span>
                                    </td>
                                    <td class="text-center font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                        <template v-if="product.product && !product.product.isConsignment">
                                            <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.from_location')" :modelValue="product.fromLocationDetailId" :defaultOption="product.locations" @update:modelValue="selectLocation" :label="'location_from_' + product.id + '_' + index"></SelectSearch>
                                        </template>
                                    </td>
                                    <td class="text-left font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                        <template v-if="product.product && !product.product.isConsignment">
                                            <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.to_location')" :modelValue="product.toLocationDetailId" :defaultOption="this.locations" @update:modelValue="selectLocation" :label="'location_to_' + product.id + '_' + index"></SelectSearch>
                                        </template>
                                    </td>
                                    <td class="text-center">
                                        <div class="d-flex align-items-center" @click="addPlusProduct(product, index)" v-if="this.entry.type != TYPE_TRANSFER">
                                            <i class="fas fa-plus mr-2"></i>
                                        </div>
                                    </td>
                                </tr>
                                <!-- Lô date hàng hóa -->
                                <template v-if="product.consignmentActive && product.consignmentActive.length">
                                    <tr v-for="(consignment, key) in product.consignmentActive" :key="key">
                                        <td></td>
                                        <td class="checkbox-table text-center">
                                            <i v-if="key > 0" @click="key != 0 ? removeConsignment(index, key, null): null" class="fas fa-trash-alt"></i>
                                        </td>
                                        <td colspan="4" :id="'consignment_' + key">
                                            <ChooseConsignment :object="product" @update:value="updateConsignment" :isSaleScreen="true" :defaultValue="consignment" :index="index" :indexConsigmentActive="key"/>
                                        </td>
                                        <td class="text-center font-weight-normal">
                                            <div class="position-relative">
                                                <InputNumberFormat :inputMarginAuto="true" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                            </div>
                                        </td>
                                        <td class="text-center font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                            <SelectSearch :title="true" :placeholder="$t('message.DeliveryIndex.position')" :modelValue="consignment.locationId" :defaultOption="consignment.locations" @update:modelValue="selectLocation" :label="'location_consignment' + key + '_for_product_' + index"></SelectSearch>
                                        </td>
                                        <td class="text-left font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                            <span v-text="consignment.locationCode"></span>
                                        </td>
                                        <td class="text-center font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                            <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.from_location')" :modelValue="consignment.fromLocationDetailId" :defaultOption="consignment.locations" @update:modelValue="selectLocation" :label="'location_from_consignment' + key + '_for_product_' + index"></SelectSearch>
                                        </td>
                                        <td class="text-left font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                            <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.to_location')" :modelValue="consignment.toLocationDetailId" :defaultOption="this.locations" @update:modelValue="selectLocation" :label="'location_to_consignment' + key + '_for_product_' + index"></SelectSearch>
                                        </td>
                                        <td>
                                            <div class="d-flex align-items-center" @click="addPlusProductConsignment(product.consignmentActive, key)" v-if="key == 0">
                                                <i class="fas fa-plus mr-2 text-gray"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                                <!-- Hiển thị thông tin thêm -->
                                <template v-for="(plus, indexPlus) in product.plus" :key="indexPlus">
                                    <tr>
                                        <td></td>
                                        <td class="text-center font-weight-normal">
                                            <i @click="removePlus(index, indexPlus)" class="fas fa-trash-alt"></i>
                                        </td>
                                        <td class="text-center font-weight-normal" v-text="plus.code"></td>
                                        <td class="font-weight-normal">
                                            {{ plus.name }}
                                            <div class="position-relative">
                                                <input type="text" v-model.trim="plus.description" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                            </div>
                                        </td>
                                        <td class="text-center font-weight-normal">
                                            <InputSelect v-model="plus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="plus.productUnits"
                                                option-label="unitName"
                                                :cardTransaction="true"
                                                :marginAuto="true"
                                                @change="getListLocationForProduct(plus, (product.hasVariant ? true : false))"
                                            ></InputSelect>
                                        </td>
                                        <td class="text-center font-weight-normal">
                                            <template v-if="product.hasVariant">
                                                <InputSelect v-model="plus.variantId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                    :options="plus.variants"
                                                    v-if="plus?.variants && plus.variants.length"
                                                    option-label="name"  
                                                    :marginAuto="true"                                                                                
                                                    :cardTransaction="true"
                                                    @update:defaultValue="value => updateAttribute(plus, value)">
                                                </InputSelect>
                                            </template>
                                             <!-- <InputSelect v-model="plus.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="plus.attributesActive"
                                                v-if="plus?.attributesActive && plus.attributesActive.length"
                                                option-label="name"                                                                                  
                                                :cardTransaction="true">
                                            </InputSelect>  -->
                                        </td>                                                       
                                        <td class="text-center font-weight-normal">
                                            <input type="number" @change="product.product && !product.product.isConsignment ? getListLocationForExport(plus) : null" v-model.trim="plus.quantity" :readonly="product.product && product.product.isConsignment ? true : false" :class="product.product && product.product.isConsignment ? 'cursor-ban' : ''" class="format__input bg-transparent text-right mb-0">
                                        </td>
                                        <td class="text-center font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                            <template v-if="product.product && !product.product.isConsignment">
                                                <SelectSearch :title="true" :placeholder="$t('message.DeliveryIndex.position')" :modelValue="plus.locationId" :defaultOption="plus.locations" @update:modelValue="selectLocation" :label="'location-plus' + indexPlus + '-'+product.id"></SelectSearch>
                                            </template>
                                        </td>
                                        <td class="text-left font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                            <span v-if="product.product && !product.product.isConsignment" v-text="plus.locationCode"></span>
                                        </td>
                                        <td class="text-center font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                            <template v-if="product.product && !product.product.isConsignment">
                                                <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.from_location')" :modelValue="plus.fromLocationDetailId" :defaultOption="plus.locations" @update:modelValue="selectLocation" :label="'location_from-plus' + indexPlus + '-'+product.id"></SelectSearch>
                                            </template>
                                        </td>
                                        <td class="text-left font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                            <template v-if="product.product && !product.product.isConsignment">
                                                <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.to_location')" :modelValue="plus.toLocationDetailId" :defaultOption="this.locations" @update:modelValue="selectLocation" :label="'location_to-plus' + indexPlus + '-'+product.id"></SelectSearch>
                                            </template>
                                        </td>
                                        <td class="text-center">
                                        </td>
                                    </tr>
                                    <!-- Lô date hàng hóa -->
                                    <template v-if="plus.consignmentActive && plus.consignmentActive.length">
                                        <tr v-for="(consignment, key) in plus.consignmentActive" :key="key">
                                            <td></td>
                                            <td class="checkbox-table text-center">
                                                <i v-if="key > 0" @click="key != 0 ? removeConsignment(index, key, indexPlus): null" class="fas fa-trash-alt"></i>
                                            </td>
                                            <!-- <td colspan="7" :id="'consignment_' + key">
                                                <ChooseConsignment :object="product" @update:value="updateConsignment" :isSaleScreen="true" :defaultValue="consignment" :index="key"/>
                                            </td> -->
                                            <td colspan="4" :id="'consignment_' + key">
                                                <ChooseConsignment :object="plus" @update:value="updateConsignmentPlus" :isSaleScreen="true" :defaultValue="consignment" :index="index" :indexConsigmentActive="key" :plusIndex="indexPlus"/>
                                            </td>
                                            <td class="text-center font-weight-normal">
                                                <div class="position-relative">
                                                    <InputNumberFormat :inputMarginAuto="true" v-model="consignment.quantity" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat>
                                                </div>
                                            </td>
                                            <td class="text-center font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                                <SelectSearch :title="true" :placeholder="$t('message.DeliveryIndex.position')" :modelValue="consignment.locationId" :defaultOption="consignment.locations" @update:modelValue="selectLocation" :label="'location_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index"></SelectSearch>
                                            </td>
                                            <td class="text-left font-weight-normal" v-if="this.entry.type != TYPE_TRANSFER">
                                                <span v-text="consignment.locationCode"></span>
                                            </td>
                                            <td class="text-center font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                                <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.from_location')" :modelValue="consignment.fromLocationDetailId" :defaultOption="consignment.locations" @update:modelValue="selectLocation" :label="'location_from_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index"></SelectSearch>
                                            </td>
                                            <td class="text-left font-weight-normal" v-if="this.entry.type == TYPE_TRANSFER">
                                                <SelectSearch :title="true" :placeholder="$t('message.TransferPositionIndex.table.to_location')" :modelValue="consignment.toLocationDetailId" :defaultOption="this.locations" @update:modelValue="selectLocation" :label="'location_to_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index"></SelectSearch>
                                            </td>
                                            <td>
                                                <div class="d-flex align-items-center" v-if="key == 0" @click="addPlusProductConsignment(plus.consignmentActive, key)">
                                                    <i class="fas fa-plus mr-2 text-gray"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </template>
                        </template>
                    </template>
                    <template v-if="(!entry.products || !entry.products.length)">
                        <tr>
                            <td :colspan="10" class="text-center p-4">{{$t('message.common.noProduct')}}</td>                            
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
        <div class="col-4 col-xl-3 col-right">
            <div class="col-right__content">
                <div class="row">
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.common.created_by')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0 pt-2">
                                <SelectSearch :placeholder="$t('message.common.created_by')" :modelValue="entry.createdBy" :defaultOption="createdBy" @update:modelValue="selectCreatedBy" :label="'createdby'"></SelectSearch>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 mt-4">
                        <div class="row m-0 mb-3">
                            <div class="col-12 p-0">
                                {{$t('message.common.createdAt')}}
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="col-12 p-0">
                                <div class="form-control" readonly v-text="entry.purchaseDate ? entry.purchaseDate : null"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 mt-4">
                        {{$t('message.common.totalQuantityProducts')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="n(entry.totalProducts)">
                    </div>
                </div>
                <div class="row" v-if="this.entry.type != TYPE_TRANSFER">
                    <div class="col-6 mt-4">
                        {{$t('message.InputQuickSearch.title.original_documents_code')}}
                    </div>
                    <div class="col-6 mt-4 text-right">
                        {{ entry.origin_document_code }}
                    </div>
                </div>
                <div class="row" v-if="this.entry.type != TYPE_TRANSFER">
                    <div class="col-6 mt-4">
                        {{$t('message.InputQuickSearch.title.original_documents_type')}}
                    </div>
                    <div class="col-6 mt-4 text-right" v-text="entry.type_text">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-3">
                        <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="entry.note" class="input__textArea mb-0"/>
                    </div>
                </div>
            </div>
            <div class="col-right__save row">
                <div class="col-12 text-center">
                    <div class="row">
                        <div class="col-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="updateTicket($constant.temporary)">
                                <div><i class="fa fa-save"></i></div>
                                <div>
                                    {{ $t('message.button.saveDraft') }}
                                </div>
                            </button>
                        </div>
                        <div class="col-6">
                            <button type="button" :disabled="isButtonDisabled" class="btn btn-save" @click="updateTicket($constant.completed)">
                                <div><i class="fa fa-check"></i></div>
                                <div>{{ $t('message.OrderSupplierIndex.status_complete') }}</div>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputSelect from "../../components/forms/InputSelect";
    import store from "../../store";
    import ModalAddInfoCustomer from '@/components/customers/ModalAddInfoCustomer';
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import {$alert, $get, $post, cloneObject, parseIntEx, clone} from "../../lib/utils";
    import toastr from 'toastr';
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import {DISCOUNT_TYPE_PERCENT, DISCOUNT_TYPE_VND, TYPE_INVOICE, TYPE_ORDER, ACTION_ORDER_PROCESSING, ACTION_UPDATE_ORDER, ACTION_UPDATE_INVOICE, TYPE_RETURN, TYPE_IMPORT,
    TYPE_EXPORT, TYPE_TRANSFER, ACTION_RETURN_INVOICE, removeVietnameseTones} from "../../lib/utils";
    import InputTextArea from "../../components/forms/InputTextArea";
    import storage from "../../lib/storage";
    import moment, { locales } from "moment";
    import currencyMixin from "@/currencyMixin";
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ChooseConsignment from "@/components/ChooseConsignment.vue";
    // import attributesMixin from '@/attributesMixin';
    export default {
        name: "SaleFormTab",
        emits: ['removeTab', 'orderProcessing', 'print'],
        mixins: [currencyMixin],
        components: {
            InputTextArea,
            InputNumberFormat,
            ModalAddInfoCustomer,
            InputSelect,
            SelectSearch,
            ModalAddInfoSaleChannels,
            ChooseConsignment,
        },
        props: ['modelValue'],
        data() {
            const user = store.state.Auth ? store.state.Auth.user : {};
            const globalConfig = store.state.GlobalConfig;
            return {
                isTaxRate: false,
                isHsCode: false,
                addPlusOder: 'addPlusOder',
                changePlusOder: 'changePlusOder',
                change: 'change',
                entry: this.modelValue,
                saleChannels: store.state.GlobalConfig.saleChannels,
                customers: [],
                branchName: store.state.CurrentBranch.name ?? null,
                user,
                TYPE_INVOICE,
                TYPE_ORDER,
                TYPE_RETURN,
                TYPE_IMPORT,
                TYPE_EXPORT,
                TYPE_TRANSFER,
                mapTypeLabel: {
                    [TYPE_INVOICE]: 'Hóa đơn ',
                    [TYPE_ORDER]: 'Đặt hàng'
                },
                ACTION_UPDATE_ORDER,
                ACTION_UPDATE_INVOICE,
                ACTION_ORDER_PROCESSING,
                ACTION_RETURN_INVOICE,
                DISCOUNT_TYPE_VND,
                DISCOUNT_TYPE_PERCENT,
                createdBy: globalConfig.users,
                locations: [],
                confirmSubmitTicket: false,
                searchCode: '',
                searchName: '',
                updateLocations: false,
                isButtonDisabled: false
            }
        },
        async mounted() {
            if(this.entry.type == TYPE_IMPORT){
                document.title = this.$t('message.common.intoStorage');       
            }else if(this.entry.type == TYPE_EXPORT){
                document.title = this.$t('message.common.takeOutStorage');       
            }else{
                document.title = this.$t('message.common.transferPosition');       
            }
        },
        async created() {
            await this.setValue();
            await this.getListLocations();
            this.updateLocations = true;
            await this.formatDefaultData();
            this.updateLocations = false;
            emitter.on('submitTicket', () => {
                this.confirmSubmitTicket = true;
                this.updateTicket(this.$constant.temporary);
            });
        },
        methods: {
            formatDefaultData(){
                if(this.entry.products){
                    this.entry.products.forEach(product => {
                        this.getListLocationForProduct(product, (product.hasVariant ? true : false), true);
                        if(product.plus){
                            product.plus.forEach(plusItem => {
                                this.getListLocationForProduct(plusItem, (product.hasVariant ? true : false), true);
                            });
                        }
                    });
                }
            },
            deleteProduct(productIdex) {
                this.entry.products.splice(productIdex, 1);
            },
            removeConsignment(productIndex, consignmentIndex, plusIndex = null) {
                if (plusIndex !== null) {
                    this.entry.products[productIndex].plus[plusIndex].consignmentActive.splice(consignmentIndex, 1);
                } else {
                    this.entry.products[productIndex].consignmentActive.splice(consignmentIndex, 1);
                }
            },
            removePlus(productIndex, plusIndex){
                this.entry.products[productIndex].plus.splice(plusIndex, 1);
            },
            addPlusProduct(productOrigin, productIndex) {
                if (!productOrigin) return;
                let product = clone(productOrigin);
                if (!this.entry.products[productIndex].plus) {
                    this.entry.products[productIndex].plus = [];
                }
                this.entry.products[productIndex].plus.push({
                    code: product.code,
                    productId: product.productId,
                    productUnits: product.productUnits,
                    quantity: 0,
                    locationCode: null,
                    consignmentActive: product.consignmentActive,
                    consignments: product.consignments,
                    name: product.name,
                    type: 'plus',
                });
            },
            addPlusProductConsignment(productOrigin, productIndex, consignmentIndex){
                if (!productOrigin) return;
                productOrigin.push({});
                // if (!this.entry.products[productIndex].consignmentActive) {
                //     this.entry.products[productIndex].consignmentActive = [];
                // }
                // this.entry.products[productIndex].consignmentActive.push({});
            },
            async updateTicket(status) 
            {
                this.isButtonDisabled = true;
                let form = clone(this.entry);
                form.status = status;
                let res = null;                
                if (form.type == TYPE_IMPORT) {
                    res = await $post('/v1/ticket-import/update', {...form});
                } else if (form.type == TYPE_EXPORT) {
                    res = await $post('/v1/ticket-export/update', {...form});
                } else if (form.type == TYPE_TRANSFER) {
                    if (form.status == this.$constant.completed) {
                        form.status = this.$constant.ticketTransfercompleted;
                    }
                    if (form.action == this.$constant.update) {
                        res = await $post('/v1/ticket-transfer/update', {...form});
                    } else {
                        res = await $post('/v1/ticket-transfer/create', {...form});
                    }
                }

                if (res.result && res.result.status) {
                    if (form.type == TYPE_TRANSFER) {
                        if (!form.action) {
                            $alert({code: 200, message: this.$t('message.common.addTicketSuccess')});
                        } else {
                            $alert({code: 200, message: this.$t('message.common.updateTicketSuccess')});
                        }
                        if(this.confirmSubmitTicket){
                            await emitter.emit("ticketConfirmed");
                            this.confirmSubmitTicket = false;
                        }else{
                            this.$router.push({ name: 'TransferPositionProductsIndex' });
                        }
                    } else {
                        $alert({code: 200, message: this.$t('message.common.updateTicketSuccess')});
                        if (form.type == TYPE_IMPORT) {
                            if(this.confirmSubmitTicket){
                                emitter.emit("ticketConfirmed");
                                this.confirmSubmitTicket = false;
                            }else{
                                this.$router.push({ name: 'ImportPositionProductsIndex' });
                            }
                        } else if (form.type == TYPE_EXPORT) {
                            if(this.confirmSubmitTicket){
                                emitter.emit("ticketConfirmed");
                                this.confirmSubmitTicket = false;
                            }else{
                                this.$router.push({ name: 'ExportPositionProductsIndex' });
                            }
                        }
                    }
                } else if (res.code == 422) {
                    let errors = res.messages.errors;
                    Object.keys(errors).forEach(function(key) {
                            $alert({code: res.code, message: errors[key][0]});
                        });
                    this.isButtonDisabled = false;
                } else {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    this.isButtonDisabled = false;
                }
            },
            setValue() {
                this.entry.purchaseDate = moment().format('DD/MM/YYYY HH:mm');
            },
            async getListLocations(){
                const res = await $post('/v1/location-details/get-list', { branchId: store.state.CurrentBranch.id});
                if(res.result && res.result.data){
                    this.locations = res.result.data;
                }
            },
            async getListLocationForExport(params){
                let isCopy = this.$route.query.isCopy ?? null;
                if(this.entry.type && (this.entry.type == TYPE_EXPORT || this.entry.type == TYPE_TRANSFER)){
                    params.branchId = store.state.CurrentBranch.id ?? null;
                    params.ticketExportId = this.entry.type == TYPE_EXPORT ? (this.entry.id ? this.entry.id : null) : null;
                    params.isCopy = isCopy;
                    if(!this.updateLocations){
                        if(this.entry.type == TYPE_EXPORT){
                            params.locationId = null;
                            params.locationCode = null;
                        }else if(this.entry.type == TYPE_TRANSFER){
                            params.toLocationDetailId = null;
                            params.fromLocationDetailId = null;
                        }
                    }
                    const res = await $post('/v1/location-details/get-list-for-ticket-export', { ...params});
                    if(res.result && res.result.data){
                        params.locations =  res.result.data;
                    }else{
                        params.locations = [];
                    }
                }else{
                    params.locations = this.locations;
                }
            },
            async getListLocationForProduct(product, hasVariant = false, formatData = false){
                // let variantId = product.variantId ? product.variantId : null;
                // if((product.product && product.product.isConsignment) || (product.consignmentActive && product.consignmentActive.length)){
                //     product.consignmentActive.forEach(element => {
                //         element.productUnitId = product.productUnitId ?? null;
                //         element.variantId = variantId;
                //         this.getListLocationForExport(element);
                //     });
                // }else{
                //     this.getListLocationForExport(product);
                // }
                this.getListLocation(product);
                if(hasVariant){
                    product.variantId = formatData ? product.variantId : null;
                    this.getListAttributeForUnit(product, formatData);     
                }
            },

            getListLocation(product){
                let variantId = product.variantId ? product.variantId : null;
                if((product.product && product.product.isConsignment) || (product.consignmentActive && product.consignmentActive.length)){
                    product.consignmentActive.forEach(element => {
                        element.productUnitId = product.productUnitId ?? null;
                        element.variantId = variantId;
                        this.getListLocationForExport(element);
                    });
                }else{
                    this.getListLocationForExport(product);
                }
            },
            updateAttribute(product){
                this.getListLocation(product);
            },

            async getListAttributeForUnit(product, formatData = false){
                const result = await $post('/v1/products/get-list-attributes-for-unit', {
                    productUnitId: product.productUnitId,
                    branchId: store.state.CurrentBranch.id,
                });
                product.variants = [];
                if(!formatData){
                    product.variantId = null;
                }
                if(result && result.result){
                    product.variants = result.result;
                    // if(!formatData){
                    //     product.variantId = result.result && result.result?.length ? result.result[0].id : null;
                    // }
                }
            },
            selectLocation(data){          
                if(data.label){
                    this.entry.products.forEach((product, index) => {
                        if(('location' + product.id + '_' + index) == data.label){
                            product.locationCode= null;
                            if (!data.model) {
                                product.locationId = null;
                            } else {
                                this.locations.forEach(location => {
                                    if(location.id == data.model){
                                        product.locationCode = location.qrCode;
                                        product.locationId = location.id;
                                        product.locationName = data.optionsName;
                                    }
                                });
                            }
                        }
                        if(('location_to_' + product.id + '_' + index) == data.label){
                            if (!data.model) {
                                product.toLocationDetailId = null;
                            } else {
                                product.toLocationDetailId = data.model;
                            }
                        }
                        if(('location_from_' + product.id + '_' + index) == data.label){
                            if (!data.model) {
                                product.fromLocationDetailId = null;
                            } else {
                                product.fromLocationDetailId = data.model;
                                product.locationName = data.optionsName;
                            }
                        }
                        if(product.consignmentActive){
                            product.consignmentActive.forEach((consignment, key) => {
                                if(('location_consignment' + key + '_for_product_' + index) == data.label){
                                    consignment.locationCode= null;
                                    if (!data.model) {
                                        consignment.locationId = null;
                                    } else {
                                        this.locations.forEach(location => {
                                            if(location.id == data.model){
                                                consignment.locationCode = location.qrCode;
                                                consignment.locationId = location.id;
                                                consignment.locationName = data.optionsName;
                                            }
                                        });
                                    }
                                }
                                if(('location_from_consignment' + key + '_for_product_' + index) == data.label){
                                    if (!data.model) {
                                        consignment.fromLocationDetailId = null;
                                    } else {
                                        consignment.fromLocationDetailId = data.model;
                                        consignment.locationName = data.optionsName;
                                    }
                                }
                                if(('location_to_consignment' + key + '_for_product_' + index) == data.label){
                                    if (!data.model) {
                                        consignment.toLocationDetailId = null;
                                    } else {
                                        consignment.toLocationDetailId = data.model;
                                    }
                                }
                            });
                        }
                        if (product.plus) {
                            product.plus.forEach((plus, indexPlus) => {
                                if(('location-plus' + indexPlus + '-' +product.id) == data.label){
                                    plus.locationCode= null;
                                    if (!data.model) {
                                        plus.locationId = null;
                                    } else {
                                        this.locations.forEach(location => {
                                            if(location.id == data.model){
                                                plus.locationCode = location.qrCode;
                                                plus.locationId = location.id;
                                                plus.locationName = data.optionsName;
                                            }
                                        });
                                    }
                                }
                                if(('location_from-plus' + indexPlus + '-' +product.id) == data.label){
                                    if (!data.model) {
                                        plus.fromLocationDetailId = null;
                                    } else {
                                        plus.fromLocationDetailId = data.model;
                                        plus.locationName = data.optionsName;
                                    }
                                }
                                if(('location_to-plus' + indexPlus + '-' +product.id) == data.label){
                                    if (!data.model) {
                                        plus.toLocationDetailId = null;
                                    } else {
                                        plus.toLocationDetailId = data.model;
                                        plus.locationName = data.optionsName;
                                    }
                                }
                                if(plus.consignmentActive){
                                    plus.consignmentActive.forEach((consignment, key) => {
                                        if(('location_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index) == data.label){
                                            consignment.locationCode= null;
                                            if (!data.model) {
                                                consignment.locationId = null;
                                            } else {
                                                this.locations.forEach(location => {
                                                    if(location.id == data.model){
                                                        consignment.locationCode = location.qrCode;
                                                        consignment.locationId = location.id;
                                                        consignment.locationName = data.optionsName;
                                                    }
                                                });
                                            }
                                        }
                                        if(('location_to_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index) == data.label){
                                            if (!data.model) {
                                                consignment.toLocationDetailId = null;
                                            } else {
                                                consignment.toLocationDetailId = data.model;
                                            }
                                        }
                                        if(('location_from_consignmentPlus' + key + '_' + indexPlus + '_for_product_' + index) == data.label){
                                            if (!data.model) {
                                                consignment.fromLocationDetailId = null;
                                            } else {
                                                consignment.fromLocationDetailId = data.model;
                                                consignment.locationName = data.optionsName;
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            },
            selectCreatedBy(data){
                this.entry.createdBy = data.model ?? null;
            },
            async updateConsignment(data){
                if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        consignment.locationId = this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].locationId;
                        consignment.locationCode = this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].locationCode;
                        this.entry.products[data.index].consignmentActive[data.indexConsigmentActive] = clone(consignment);
                        this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].productUnitId = this.entry.products[data.index].productUnitId ?? null;
                        this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].consignmentId = consignment.id ?? null;
                        this.entry.products[data.index].consignmentActive[data.indexConsigmentActive].variantId = this.entry.products[data.index].variantId ? this.entry.products[data.index].variantId : null;
                        this.getListLocationForExport(this.entry.products[data.index].consignmentActive[data.indexConsigmentActive]);
                    });
                }
            },
            async updateConsignmentPlus(data){
                if(this.entry.products){
                    if(data.data?.length == 0 && data.defaultValue){
                        this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId = null;
                        return;
                    }
                    data.data.forEach(consignment => {
                        consignment.locationId = this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].locationId;
                        consignment.locationCode = this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].locationCode;
                        this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive] = clone(consignment);
                        this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].productUnitId = this.entry.products[data.index].productUnitId ?? null;
                        this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId = consignment.id ?? null;
                        this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].variantId = this.entry.products[data.index].plus[data.plusIndex].variantId ? this.entry.products[data.index].plus[data.plusIndex].variantId : null;
                        this.getListLocationForExport(this.entry.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive]);
                    });
                }
            },
            async doFilter(){
                if(this.entry && this.entry.products){
                    this.entry.products.forEach(product => {
                        if((!this.searchCode || (this.searchCode && this.searchCode.length == 0)) && (!this.searchName || (this.searchName && this.searchName.length == 0))){
                            product.show = true;
                        }else if(this.searchCode || this.searchName){
                            product.show = false;
                            if (this.searchName && product.name && !this.searchCode
                                && removeVietnameseTones(product.name.toLowerCase()).indexOf(removeVietnameseTones(this.searchName.toLowerCase())) > -1){
                                product.show = true;
                            }else if (this.searchCode && product.code && !this.searchName
                                && removeVietnameseTones(product.code.toLowerCase()).indexOf(removeVietnameseTones(this.searchCode.toLowerCase())) > -1){
                                product.show = true;
                            }else if (this.searchCode && product.code && this.searchName && product.name
                                && removeVietnameseTones(product.code.toLowerCase()).indexOf(removeVietnameseTones(this.searchCode.toLowerCase())) > -1
                                && removeVietnameseTones(product.name.toLowerCase()).indexOf(removeVietnameseTones(this.searchName.toLowerCase())) > -1){
                                product.show = true;
                            }
                        }else{
                            product.show = true;
                        }
                    });
                }
            },
        },
        watch: {
            entry: {
                handler: function(dataNew) {
                    if (!dataNew) return;
                    let totalProducts = 0;
                    if (dataNew.products) {
                        // totalProducts = dataNew.products.length;
                        dataNew.products.forEach(product => {
                            // if((!this.entry.searchCode || (this.entry.searchCode && this.entry.searchCode.length == 0)) && (!this.entry.searchName || (this.entry.searchName && this.entry.searchName.length == 0))){
                            //     product.show = true;
                            // }else if(this.entry.searchCode || this.entry.searchName){
                            //     product.show = false;
                            //     if (this.entry.searchName && product.name && removeVietnameseTones(product.name.toLowerCase()).indexOf(removeVietnameseTones(this.entry.searchName.toLowerCase())) > -1){
                            //         product.show = true;
                            //     }else if (this.entry.searchCode && product.code && removeVietnameseTones(product.code.toLowerCase()).indexOf(removeVietnameseTones(this.entry.searchCode.toLowerCase())) > -1){
                            //         product.show = true;
                            //     }
                            // }else{
                            //     product.show = true;
                            // }
                            if(product.consignmentActive && product.consignmentActive.length){
                                let quantity = 0;
                                product.consignmentActive.forEach(item => {
                                    quantity += item.quantity;
                                });
                                product.quantity = quantity;
                            }
                            totalProducts += product.quantity;
                            if(product.plus){
                                product.plus.forEach(plusItem => {
                                    if(plusItem.consignmentActive && plusItem.consignmentActive.length){
                                        let quantity = 0;
                                        plusItem.consignmentActive.forEach(consignment => {
                                            quantity += consignment.quantity;
                                        });
                                        plusItem.quantity = quantity;
                                    }
                                    totalProducts += plusItem.quantity;
                                });
                            }
                        });
                    }
                    this.entry.totalProducts = totalProducts;
                },
                deep: true
            },
            'entry.products': {
                handler: function(newProducts) {
                    if(newProducts){
                        let now = moment().format('YYYY-MM-DD');
                        newProducts.forEach(product => {
                            if (product.isConsignment || (product.product && product.product.isConsignment)) {
                                if (!product.consignmentActive) {
                                    var minExpried = product.consignments.reduce((acc, value) => {
                                        return (acc = (moment(acc).format('YYYY-MM-DD') <= moment(value.expried).format('YYYY-MM-DD')) &&  moment(acc).format('YYYY-MM-DD') >= now ? acc : value.expried);
                                    }, 0);
                                    var index = product.consignments.findIndex(consignment => consignment.expried == minExpried);
                                    if(product.consignments[index] && product.consignments[index].consignment_product_branch_metas){
                                        let consignmentProductBranchMeta = product.consignments[index].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                                        let consignmentActive = {
                                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id ?? null,
                                            consignmentId: consignmentProductBranchMeta.consignmentId ?? null,
                                            id: consignmentProductBranchMeta.consignmentId ?? null,
                                            name: product.consignments[index].name ?? null,
                                            expried: product.consignments[index].expried ?? null,
                                            quantity: product.quantity ?? 1,
                                            quantityDraft: product.quantity ?? 1,
                                            key: 1,
                                            productUnitId: product.productUnitId ?? null,
                                            inventory: consignmentProductBranchMeta.inventory,
                                            productId: product.id,
                                        }
                                        product.consignmentActive = [consignmentActive];
                                    }
                                }
                            }
                        });
                    }
                },
                deep: true
            },
            modelValue: {
                handler: function(dataNew) {
                    if (!dataNew) return;
                    this.entry = dataNew;
                },
                deep: true
            },
        }
    }
</script>

<style scoped lang="scss">
    .btn-icon-gift, .label_gift{
        color: #FF2F86 !important;
    }
    .btn-icon-gift:hover, .btn-icon-gift.active{
        border-radius: 999px !important;
        background-color: rgba(255,47,134,0.1);
    }
    .bg-text-promotion{
        background-color: rgba(255,47,134,0.1);
        color: #FF2F86 !important;
    }
    .bg-gift{
        background-color: #fff !important;
        padding: 10px 20px 10px;
        -webkit-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        // border: 1px solid #d7d7d7;
        -webkit-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        -moz-box-shadow: 0 5px 10px rgba(0,0,0,.15);
        box-shadow: 0 5px 10px rgba(0,0,0,.15);
    }
    .border-top-dotted{
        border: none;
        border-top: 1px dashed #D6D7E1;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
        outline: none !important;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .col-left {
        background-color: #fff;

        table {
            height: calc(100vh - 150px);
            overflow: hidden auto;
            display: block;
            margin-bottom: 0;

            .popover-wrap {
                font-weight: 400;
                margin-bottom: 5px;
                position: relative;

                .popover-wrap__content {
                    padding-left: 10rem;
                    .arrow-up {
                        width: 0;
                        height: 0;
                        border-left: 10px solid transparent;
                        border-right: 10px solid transparent;
                        border-bottom: 10px solid teal;

                        position: absolute;
                        top: -10px;
                        left: calc(10rem + 50px);

                        &:after {
                            margin-left: -10px;
                            content: " ";
                            top: 1px;
                            border-width: 10px;
                            border-top-width: 0;
                            border-color: transparent;
                            border-bottom-color: #fff;
                            position: absolute;
                            display: block;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            z-index: 11;
                        }
                    }

                    .content-tooltip {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        width: max-content;
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;

                        .price-item {
                            padding: 0 5px;
                        }
                    }

                    .inventory-by-branch {
                        border: 1px solid teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        padding: 10px;
                        background-color: white;
                        font-weight: 600;
                        min-width: 500px;

                        table {
                            height: auto;
                            width: 100%;
                            display: table;
                        }
                    }

                    textarea {
                        border-color: teal;
                        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
                        font-size: 13px;

                        height: auto;
                        min-height: 50px;
                        max-height: 112px;
                        width: 300px;

                        &:focus {
                            outline: none;
                        }
                    }
                }
            }
        }

        .btn-icon {
            border: 1px solid rgba(0,0,0,0.15);
            text-align: center;
            height: 24px;
            width: 24px;
            margin: auto 5px auto;
            outline: none;
        }

        .product-batch-selected  {
            margin-bottom: 7px;

            &>span {
                background-color: #2fa1a1;
                color: white;
                font-weight: 600;

                padding: 1px 2px 1px 6px;
                display: inline-block;
                border-radius: 3px;

                .remove-product-batch {
                    padding: 0 5px;
                    margin-left: 2px;
                    font-size: 16px;

                    &:hover {
                        background-color: teal;
                    }
                }
            }
        }

        .input-product-price {
            cursor: text;
            font-weight: 600;
            background-color: transparent;

            &:hover {
                border-bottom: 2px solid teal;
                font-weight: bold;
            }
        }
    }

    .col-right {
        height: calc(100vh - 150px);
        background: #fff;
        overflow: hidden auto;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);

        .col-right__content {
            min-height: calc(100% - 100px);

            .total-quantity {
                min-width: 24px;
                height: 24px;
                line-height: 24px;
                margin: 0 5px;
                border: 1px solid #e1e1e1;
                border-radius: 3px;
                font-size: 14px;
                display: inline-block;
                padding: 0 5px;
                text-align: center;
            }

            .form-group {
                label {
                    font-weight: 600;
                }
            }

            .btn-icon-payment {
                font-size: 24px;
                color: teal;
                font-weight: 400;
                background-color: transparent;
                border: none;
                padding: 0;
                line-height: 3.1rem;
            }
        }

        .col-right__save {
            // margin: 0 12px;
            padding-top: 20px;
            height: 100px;

            .btn-save {
                width: 100%;
                font-size: 20px;
                height: 65px;
                margin-left: 0;
                font-weight: 600;

                &:hover {
                    background-color: #168080 !important;
                }
            }
        }
    }

    .col-left table, .col-right {
        &::-webkit-scrollbar {
            width: 8px;
            height: 8px;
        }

        &::-webkit-scrollbar-thumb {
            width: 8px;
            border-radius: 4px;
            background-color: #c1c1c1;
            border: 1px solid #fff;
        }

        &::-webkit-scrollbar-track {
            padding: 6px;
        }
    }
    .quantity-remaining{
        position: absolute;
        top: 35%;
        right: 0;
        color: gray;
    }
    .show_coupon {
        padding: 5px;
        border-radius: 5px;
        margin-bottom: 5px;
        background-color: #e9ecef;
    }
    .custom_format {
        width: 50% !important;
    }
    .label_gift {
        height: 50px;
        display: flex;
        align-items: center;
    }
    .cate_selected{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .colQuantity {
        padding-right: 33px !important;
    }
    .colQuantityTax {
        padding-right: 24px !important;
    }
    .cell-check{
        width: 30px;
        min-width: 30px;
        max-width: 30px;
    }
    .cell-name{
        width: 232px;
        min-width: 232px;
        max-width: 232px;
    }
    .cell-auto{
        min-width: 160px;
    }
    .col-left table{
        overflow-x: auto;
    }
    .trSearch{
        height: 40px !important;
    }
</style>
