<template>
    
        <div class="customer-tab tab-info">
            <template v-if="!isModal">
                <ActionBar back-url="/customers/index" @action="save()"/>
                <div class="title">

                </div>
            </template>

            <div class="content">
                <div class="row">
                    <div class="col-6">
                        <!-- Start mã khách hàng -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierCode')}}</b>
                            </div>
                            <div class="col-9">
                                <InputText v-model="entry.code" :placeholder="$t('message.supplierIndex.modal.supplierCode')" />
                            </div>
                        </div>
                        <!-- End mã khách hàng -->
                        <!-- Start name -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierName')}}<label class="text-danger px-0">*</label> </b>
                            </div>
                            <div class="col-9">
                                <InputText v-model="entry.name" :errors="errors.name" :placeholder="$t('message.supplierIndex.modal.enterName')"/>
                            </div>
                        </div>
                        <!-- End name -->
                        <!-- Start birthday and gender -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierPhoneNumber')}} <label class="text-danger px-0">*</label></b>
                            </div>
                            <div class="col-9">
                            <InputText v-model="entry.contactNumber" :errors="errors.contactNumber" :placeholder="$t('message.supplierIndex.modal.enterPhone')" :max="12" :cusNumber="true"/>
                            </div>
                        </div>
                        <!-- End status -->
                        <!-- Start nhóm khách hàng -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierAddress')}}</b>
                            </div>
                            <div class="col-9">
                                <InputTextArea v-model="entry.address" :placeholder="$t('message.supplierIndex.modal.enterAddress')"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierProvince')}}</b>
                            </div>
                            <div class="col-9">
                                <q-select v-model="entry.provinceId" :default-options="provinces" :multiple="false" :placeholder="$t('message.supplierIndex.modal.chooseProvince')" @update:modelValue="changeProvince" ref="inputProvinces"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierDistrict')}}</b>
                            </div>
                            <div class="col-9">
                                <q-select v-model="entry.districtId" :default-options="districts" :multiple="false" :placeholder="$t('message.supplierIndex.modal.chooseDistrict')" @update:modelValue="changeDistrict" ref="inputDistricts"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierWard')}}</b>
                            </div>
                            <div class="col-9">
                                <q-select v-model="entry.wardId" :default-options="wards" :multiple="false" :placeholder="$t('message.supplierIndex.modal.chooseWard')" ref="inputWards"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierAccountNumber')}}</b>
                            </div>
                            <div class="col-9">
                                <input type="text" v-model.trim="entry.accountNumber" class="form-control" :placeholder="$t('message.supplierIndex.modal.enterAccountNumber')">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierAccountName')}}</b>
                            </div>
                            <div class="col-9">
                                <input type="text" v-model.trim="entry.accountName" class="form-control" :placeholder="$t('message.supplierIndex.modal.enterAccountOwner')">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierBankName')}}</b>
                            </div>
                            <div class="col-9">
                                <input type="text" v-model.trim="entry.bankName" class="form-control" :placeholder="$t('message.supplierIndex.modal.enterBankName')">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierBankAddress')}}</b>
                            </div>
                            <div class="col-9">
                                <input type="text" v-model.trim="entry.bankAddress" class="form-control" :placeholder="$t('message.supplierIndex.modal.enterBankAddress')">
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.openingFee')}}</b>
                            </div>
                            <div class="col-9">
                                <InputNumberFormat v-model="entry.openingFee" class="" :bgTransparent="true"></InputNumberFormat>   
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.paymentDiscount')}}</b>
                            </div>
                            <div class="col-9">
                                <InputNumberFormat v-model="entry.paymentDiscount" class="" :bgTransparent="true"></InputNumberFormat>   
                            </div>
                        </div>
                        <!-- <InputLocationPicker v-model="location"></InputLocationPicker> -->
                    </div>
                    <div class="col-6">
                        <!-- Start loại khách -->
                        <div class="row">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.branch')}}:</b>
                            </div>
                            <div class="col-9">
                            <InputText v-model="this.currentBranchName" :placeholder="$t('message.supplierIndex.modal.branch')"  readonly="true"/>
                            </div>
                        </div>
                        <!-- Start email -->
                        <div class="row">
                            <div class="col-md-3">
                                <b>{{$t('message.supplierIndex.modal.supplierEmail')}}</b>
                            </div>
                            <div class="col-md-9">
                                <InputText v-model="entry.email" :errors="errors.email" :placeholder="$t('message.supplierIndex.modal.enterEmail')"/>
                            </div>
                        </div>
                        <!-- End email -->
                        <!-- Start facebook -->
                        <div class="row">
                            <div class="col-md-3">
                                <b>{{$t('message.supplierIndex.modal.company')}}</b>
                            </div>
                            <div class="col-md-9">
                                <InputText v-model="entry.company" :errors="errors.company" :placeholder="$t('message.supplierIndex.modal.enterCompanyName')"/>
                            </div>
                        </div>
                        <!-- End facebook -->
                        <!-- Start facebook -->
                        <div class="row">
                            <div class="col-md-3">
                                <b>{{$t('message.supplierIndex.modal.supplierTaxCode')}}</b>
                            </div>
                            <div class="col-md-9">
                                <InputText :placeholder="$t('message.supplierIndex.modal.enterTaxCode')" v-model="entry.taxCode" v-on:keyup.enter="getInfoCompany()"  :max="14" :cusNumber="false"/>
                            </div>
                        </div>
                        <!-- End facebook -->
                        <div class="row my-2">
                            <div class="col-md-3">
                            <b>{{$t('message.supplierIndex.modal.supplierGroup')}}</b>
                            </div>
                            <div class="col-md-9">
                                <q-select name="supplierGroupId" :default-options="supplierGroups" :multiple="true" v-model="entry.supplierGroupId" :selectAll="true" :placeholder="$t('message.supplierIndex.modal.chooseSupplierGroup')"/>
                            </div>
                        </div>

                        <!-- Start facebook -->
                        <div class="row my-2">
                            <div class="col-md-3">
                                <b>{{$t('message.supplierIndex.modal.supplierNote')}}</b>
                            </div>
                            <div class="col-md-9">
                                <InputTextArea :placeholder="$t('message.supplierIndex.modal.enterNote')" v-model="entry.note"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.supplierDebtDate')}}</b>
                            </div>
                            <div class="col-9">
                                <div class="debt">
                                    <input type="text" v-model.trim="entry.numberDebtDate" @input="changeNumberDebtDate" 
                                    @keyup="onChangeCurrency($event)"  @keydown="onKeyDownCurrency($event)"
                                    class="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0 mb-2" :placeholder="$t('message.supplierIndex.modal.enterDebtDate')">
                                    <input type="text" v-model.trim="entry.recurringPaymentDate" @input="changePaymentDate" class="form-control border-top-0 border-right-0 border-left-0 border-bottom rounded-0" :placeholder="$t('message.supplierIndex.modal.choosePaymentDate')">
                                </div>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.user')}}</b>
                            </div>
                            <div class="col-9">
                                <q-select v-model="entry.userId" :default-options="users" :multiple="false" :placeholder="$t('message.supplierIndex.modal.user')"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.paymentTerms')}}</b>
                            </div>
                            <div class="col-9">
                                <InputText v-model="entry.paymentTerms" :errors="errors.paymentTerms" :placeholder="$t('message.supplierIndex.modal.enterPaymentTerm')"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-3">
                                <b>{{$t('message.supplierIndex.modal.maxDebtNumber')}}</b>
                            </div>
                            <div class="col-9">
                                <InputNumberFormat :max="100000000000" v-model="entry.maxDebtNumber" :bgTransparent="true" :placeholder="$t('message.supplierIndex.modal.enterMaxDebt')" class="mb-0"/>
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-12" >
                                <label class="container-checkbox d-flex m-0" v-if="this.isSupplierOrCustomer" :class="this.isEdit ? 'cursor-ban' : ''">
                                    {{$t('message.supplierIndex.modal.isCustomer')}}
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            <p>. {{$t('message.supplierIndex.modal.tooltip1')}}</p>
                                            <p>. {{$t('message.supplierIndex.modal.tooltip2')}}</p>
                                        </div>
                                    </div>
                                    <input type="checkbox" class="chkType" :disabled="this.isEdit || (entry.customer_count && entry.customer_count > 0) ? true : false" v-model="entry.isCustomer" :checked="entry.isCustomer || (entry.customer_count && entry.customer_count > 0) ? true : false">
                                    <span class="checkmark-checkbox"></span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div ref="modal" class="modal" tabindex="-1" role="dialog">
                <div class="modal-dialog" role="document">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">{{ $t('message.customerGroupIndex.titleCreate') }}</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body" v-if="shown">
                            <div>
                                <InputText v-model="group.name" :label="$t('message.customerGroupIndex.enterName')" :errors="errorGroups.name"/>
                                <InputTextArea v-model="group.description" :label="$t('message.customerGroupIndex.description')"/>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <AppPermission :permission="this.$permissions.suppliers.update ? this.$permissions.suppliers.update : (this.$permissions.suppliers.create ? this.$permissions.suppliers.create : '')">   
                                    <button type="button" class="btn btn-primary" @click="saveCustomerGroup()">
                                        {{ $t('message.button.save') }}
                                    </button>
                            </AppPermission>
                            <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="onClose()">
                                {{ $t('message.button.close') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    
</template>

<script>
import InputText from "@/components/forms/InputText";
import InputNumber from "@/components/forms/InputNumber";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import ActionBar from "@/components/ActionBar";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputFileManager from "@/components/forms/InputFileManager";
import InputDatePicker from "@/components/forms/InputDatePicker";
import QImage from "@/components/QImage";
import InputTextArea from "@/components/forms/InputTextArea";
import emitter from "@/lib/emitter";
import InputSelect from "../../../components/forms/InputSelect";
import QSelect from "../../../components/QSelect";
import AppPermission from '@/components/AppPermission';
import store from "@/store";
import currencyMixin from "@/currencyMixin";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import { mapGetters } from "vuex";
export default {
    name: "TabInfo",
    props: ['id', 'isModal','data', 'isEdit'],
    mixins: [currencyMixin],
    emits: ['created'],
    components: {
        InputTextArea,
        QImage,
        InputDatePicker,
        InputFileManager,
        InputSwitchButton,
        InputLocationPicker,
        InputText, ActionBar,
        InputSelect, InputNumber,
        QSelect, AppPermission,
        InputNumberFormat
    },
    mounted() {
        let id = document.getElementById('branchDropdown');
        this.currentBranchID = store.state.CurrentBranch.id;
        this.currentBranchName = store.state.CurrentBranch.name;
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            name: '',
            entry: {},
            address: {
                is_default: 1
            },
            errors: {
                apartmentName: []
            },
            errorGroups: {
                name: []
            },
            group: {
                name: '',
                description: ''
            },
            shown: false,
            location: [],
            customerGroup: [],
            typeAddress: '',
            currentBranchName: '',
            showHistory: false,
            supplierGroups: [],
            provinces: [],
            districts: [],
            wards: [],
            users: globalConfig.users,
        }
    },
    created() {
        emitter.on('removeSupplierData',()=>{
            this.entry= {};
            if (this.$refs.inputProvinces) {
                this.$refs.inputProvinces.keyword = '';
            }
            if (this.$refs.inputDistricts) {
                this.$refs.inputDistricts.keyword = '';
            }
            if (this.$refs.inputWards) {
                this.$refs.inputWards.keyword = '';
            }
        });
        // emitter.on('loadDataSupplier',()=>{
        //     this.load();
        // });

    },
    methods: {
        // async load(){
        //     const res = await $get('/v1/locations/provinces', {
        //         provinceId: this.entry.provinceId,
        //         districtId: this.entry.districtId
        //     });
        //     this.provinces = res.data.provinces;
        //     this.districts = res.data.districts;
        //     this.wards = res.data.wards;
        // },

        async getInfoCompany() {
            this.clearDataCompany();
            await this.entry.getInfoCompany
            const res = await $get('/v1/customers/get-info-company', {                
                taxCode: this.entry.taxCode
            });
            if (res.result && res.result.status) {
                let dataCompany = res.result.dataCompany;
                this.entry.company = dataCompany.companyName;
                this.entry.provinceId = dataCompany.provinceId;
                this.entry.districtId = dataCompany.districtId;
                this.entry.wardId = dataCompany.wardId;
                this.entry.address = dataCompany.address;
                this.entry.contactNumber = dataCompany.phoneNumber;
                this.districts = dataCompany.districts;
                this.wards = dataCompany.wards;
            } else {
                $alert({code: '500', message: this.$t('message.customerIndex.modal.notFoundInfoTax')});
            }
        },
        clearDataCompany() {
            this.entry.company = null;
            this.entry.provinceId = null;
            this.entry.districtId = null;
            this.entry.wardId = null;
            this.entry.address = null;
            this.entry.contactNumber = null;
        },

        async changeDistrict() {
            this.entry.wardId = null;
            const res = await $get('/v1/locations/wards', {districtId: this.entry.districtId});
            this.wards = res.data;
        },
        async changeProvince() {
            this.entry.districtId = null;
            this.entry.wardId = null;
            const res = await $get('/v1/locations/districts', {provinceId: this.entry.provinceId});
            this.districts = res.data;
            this.changeDistrict();
        },
        changePaymentDate(){
            this.entry.numberDebtDate = null;
        },
        changeNumberDebtDate(){
            this.entry.recurringPaymentDate = null;
        }
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            isSupplierOrCustomer: 'settingStore/isCustomerOrSuplier'
        })
    }
}
</script>

<style scoped>
.debt{
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    padding: 10px;
}
</style>
