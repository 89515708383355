<template>
    <div class="customer-create ml-auto">
        <!-- Button trigger modal -->
        <button type="button" class="add-btn" data-toggle="modal" :data-target="id ? '#formAddress_' + id : '#createAddress'"
        v-text="id ? 'Sửa' : 'Thêm địa chỉ'">

        </button>

        <!-- Modal -->
        <div class="modal fade" :id="id ? 'formAddress_' + id : 'createAddress'" tabindex="-1" role="dialog" aria-labelledby="createAddressLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="createAddressLabel" v-text="id ? 'Chỉnh sửa địa chỉ: ' + address.name : 'Thêm mới địa chỉ'"></h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="container">
                            <div class="row">
                                <div class="col-lg-6 col-sm-12">
                                    <InputText v-model="address.name" :errors="errors.name"
                                               label="Tên người thân"/>
                                    <InputText v-model="address.phone" :errors="errors.phone"
                                               label="Số điện thoại"/>
                                    <InputSwitchButton v-model="address.is_default" label="Địa chỉ mặc định"/>

                                </div>
                                <div class="col-lg-6 col-sm-12">
                                    <div class="row mb-2">
                                        <div class="form-check mr-3 ml-3">
                                            <input class="form-check-input" type="radio" name="chooseTypeAddress" :value="1" v-model="address.typeAddress" id="chung-cu">
                                            <label class="form-check-label" for="chung-cu">
                                                Chung cư
                                            </label>
                                        </div>
                                        <div class="form-check mr-3">
                                            <input class="form-check-input" type="radio" name="chooseTypeAddress" :value="2" v-model="address.typeAddress" id="cong-ty">
                                            <label class="form-check-label" for="cong-ty">
                                                Công ty
                                            </label>
                                        </div>
                                        <div class="form-check">
                                            <input class="form-check-input" type="radio" name="chooseTypeAddress" :value="3" v-model="address.typeAddress" id="nha-rieng">
                                            <label class="form-check-label" for="nha-rieng">
                                                Nhà riêng
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row" v-if="address.typeAddress === 1">
                                        <div class="col-12">
                                            <InputText v-model="address.apartmentName" label="Tên chung cư"/>
                                        </div>
                                        <div class="col-5">
                                            <InputText v-model="address.apartmentBuilding" label="Tòa nhà"/>
                                        </div>
                                        <div class="col-7">
                                            <InputText v-model="address.apartmentRoom" label="Số phòng"/>
                                        </div>

                                    </div>

                                    <InputLocationPicker v-model="location"/>
                                    <InputTextArea v-model="address.addressValue" :errors="errors.addressValue"
                                               label="Địa chỉ chi tiết" />

                                </div>
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="save()">Lưu lại</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import InputText from "@/components/forms/InputText";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import InputLocationPicker from "@/components/forms/InputLocationPicker";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import InputTextArea from "@/components/forms/InputTextArea";
export default {
    name: "CreateAddress",
    props: ['id', 'customerId'],
    components: {
        InputTextArea,
        InputLocationPicker, InputText, InputSwitchButton
    },
    mounted() {
        if (this.id) {
            this.load();
        }

    },
    data() {
        return {
            name: '',
            address: {},
            errors: {},
            location: {
                provinceId: '',
                districtId: '',
                wardId: ''
            },
            typeAddress: ''
        }
    },
    methods: {
        async load() {
            const res = await $get('/v1/customers/detailAddress', {
                id: this.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.address = res.data.entry;
            this.location = this.address;
        },
        async save() {
            this.errors = {};
            this.address.provinceId = this.location.provinceId;
            this.address.districtId = this.location.districtId;
            this.address.wardId = this.location.wardId;
            const res = await $post('/v1/customers/saveAddress', {
                customer_id: this.customerId, address: this.address
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }
            if (res.code === 200) {
                window.$(this.id ? '#formAddress_' + this.id : '#createAddress').modal('hide');
                await this.$parent.loadAddress();
            }

            $alert(res);
        },
    }
}
</script>

<style lang="scss">
@import "../../assets/css/customer";
.modal-lg {
    max-width: 1000px;
}
.modal-title {
    font-weight: bold;
    color: #000000;
}

</style>