<template>
    <div id="header">
        <div class="row header-menu-top p-0 mx-0">
            <!-- <div class="header-menu-top align-items-center"> -->
            <div class="container">
                <div class="row p-0">
                    <div class="col-12 right py-1">
                        <nav class="navbar navbar-expand-lg navbar-light">
                            <div class="collapse navbar-collapse" id="navbarTopContent">
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item dropdown">
                                        <div class="row m-0 align-items-center h-100">
                                            <span class="mr-1">{{ $t('message.headers.branch') }}</span>
                                            <select @change="setBranch($event)"
                                                class="form-select form-select-sm ms-auto d-inline-flex w-auto mr-2 form-control"
                                                aria-labelledby="branchDropdown">
                                                <option v-for="(branch, index) in branches" :key="index" :value="index"
                                                    :selected="currentBranch?.id == branch.id">
                                                    {{ branch.name }}
                                                </option>
                                            </select>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a v-if="hasPermission(this.$permissions.printTemplates.data) || hasPermission(this.$permissions.branches.data) 
                                            || hasPermission(this.$permissions.promotions.data) || hasPermission(this.$permissions.coupons.data) ||
                                         hasPermission(this.$permissions.voucher.data)  || hasPermission(this.$permissions.permissions.data) 
                                            || hasPermission(this.$permissions.roles.data) || this.userId == this.$constant.roleRoot"  
                                        class="nav-link dropdown-toggle" href="#" id="settingDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="margin-right: 0">
                                            <i class="fas fa-cog"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="settingDropdown"
                                            style="margin-top: 0">
                                            <PermissionLink class="dropdown-item"
                                                            permission="SettingStore"
                                                            href="/settings/store"
                                                            :label="$t('message.headers.setupShop')" />                                    
                                            <PermissionLink class="dropdown-item" permission="PrintTemplates"
                                                href="/settings/print-template" :label="$t('message.headers.print_template_manage')" />
                                            <PermissionLink class="dropdown-item" permission="Branches"
                                                href="/branches/index" :label="$t('message.headers.branch_manage')" />
                                            <template v-if="isUsePromotion">
                                                <PermissionLink class="dropdown-item" permission="Promotions" 
                                                href="/promotions/index" :label="$t('message.headers.promotion_manage')" />
                                            </template>
                                            <template v-if="isUseCoupon">
                                                <PermissionLink class="dropdown-item" permission="Coupons" href="/coupons/index" 
                                                :label="$t('message.headers.coupon_manage')" />
                                            </template>
                                           
                                            <template v-if="isUseVoucher">
                                                <PermissionLink class="dropdown-item" permission="Vouchers" 
                                                href="/vouchers/index" :label="$t('message.headers.voucher_manage')" />
                                            </template>
                                            <PermissionLink class="dropdown-item" permission="Roles" href="/roles/index" 
                                            :label="$t('message.headers.role_manage')" />
                                            <PermissionLink class="dropdown-item" permission="Permissions"
                                                href="/permissions/index" :label="$t('message.headers.permission_manage')" />
                                            <PermissionLink class="dropdown-item" permission="Permissions"
                                                href="/history/index" :label="$t('message.headers.history')" />
                                            <PermissionLink class="dropdown-item" permission="PrintTemplates"
                                                href="/pdf-template/index" :label="$t('message.headers.email_template_manage')" />
                                            <a class="dropdown-item" href="#" @click="link('ZaloTemplate')">
                                                {{$t('message.zaloGroups.zaloTitle')}}
                                            </a> 
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="margin-right: 0">
                                            {{ user?.name }} <i class="far fa-user-circle"></i>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown"
                                            style="margin-top: 0">
                                            <a class="dropdown-item" href="#" @click.prevent="logout()">
                                                <i class="fas fa-sign-out-alt mr-2"></i>
                                                {{$t('message.headers.logout')}}
                                            </a>
                                        </div>
                                    </li>
                                    <li class="nav-item dropdown">
                                        <a class="nav-link p-0" href="#" id="languageDropdown" role="button"
                                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                                            style="margin-right: 0">
                                            <img :src="getSrcFlagLanguage(this.isLang)" alt="" class="rounded-circle" 
                                                style="height: 30px; width: 30px; border: 2px solid #fff; object-fit: cover"/>
                                        </a>
                                        <div class="dropdown-menu dropdown-menu-right dropdown-image-lang" aria-labelledby="languageDropdown" style="margin-top: 0; min-width: 0px !important;">
                                            <a className="dropdown-item image-lang px-0" @click="setLocale(lang)" v-for="(lang, i) in this.$constant.languages" :key="`Lang${i}`">
                                                <img :src="getSrcFlagLanguage(lang)"/>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
        <div class="row header-main-menu mx-0">
            <!-- <div class="header-main-menu"> -->
            <div class="container">
                <div class="row">
                    <div class="col-12 left position-relative">
                        <nav class="navbar navbar-expand-lg navbar-light" id="navbar-responsive">
                            <div class="collapse navbar-collapse" id="navbarMainContent">
                                <h2 class="mr-2 logo">
                                    <!-- <a class="logo-title__link" href="/" style="text-decoration:none"> -->
                                    <a class="logo-title__link" href="#" style="text-decoration:none" @click="link('Home')">
                                        <img src="@/assets/pos.png" height="32" />
                                    </a>
                                </h2>
                                <ul class="navbar-nav mr-auto">
                                    <AppPermission :permission="this.$constant.permissionsDasboard">
                                        <li class="nav-item">
                                            <a class="nav-link " href="/" role="button" aria-haspopup="true"
                                                aria-expanded="false">
                                                <i class="fas fa-chart-bar"></i> <span style="font-size:medium;">
                                                    {{$t('message.headers.dashboard')}}
                                                </span>
                                            </a>
                                        </li>
                                    </AppPermission>
                                    <AppPermission :permission="this.$constant.categoryPermission">
                                        <li class="nav-item dropdown">                                           
                                            <a class="nav-link dropdown-toggle" href="#" id="productDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-cubes mr-1"></i><span style="font-size:medium;">
                                                    {{$t('message.headers.list')}}
                                                </span>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="settingDropdown">
                                                <!-- Danh mục | Hàng hóa -->
                                                <AppPermission :permission="this.$constant.productsPermission">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">                                                            
                                                                <a class="dropdown-item" href="#">
                                                                    {{$t('message.headers.products')}}
                                                                </a>                                                            
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="Products.data"
                                                                    href="/products/index" :label="$t('message.headers.product_list')" />
                                                                <PermissionLink class="dropdown-item" permission="Categories"
                                                                    href="/categories/index" :label="$t('message.headers.categories')" />
                                                                <PermissionLink class="dropdown-item" permission="Units"
                                                                    href="/units/index" :label="$t('message.headers.units')" />
                                                                <PermissionLink class="dropdown-item" permission="MappingProduct"
                                                                    href="/medicine/mapping-product" :label="$t('message.medicineProductMapping.title')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>

                                                <PermissionLink class="dropdown-item"  href="/medicine/index" :label="$t('message.medicine.title')" permission="Medicine.data"/>
                                                <!-- Danh mục | Khách hàng -->
                                                <AppPermission :permission="'CustomerCategory'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.customers')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="Customers"
                                                                    href="/customers/index" :label="$t('message.headers.customer_list')" />
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="CustomerGroups" href="/customers-group/index"
                                                                    :label="$t('message.headers.customer_group')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <!-- Danh mục | Nhà cung cấp -->
                                                <AppPermission :permission="'SupplierCategory'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.suppliers')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="Suppliers"
                                                                    href="/suppliers/index" :label="$t('message.headers.supplier_list')" />
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="SupplierGroups" href="/suppliers-group/index"
                                                                    :label="$t('message.headers.supplier_group')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <!-- Danh mục | Nhân viên -->
                                                <AppPermission :permission="'UserCategory'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.employees')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="Users"
                                                                    href="/employees/index" :label="$t('message.headers.employee_list')" />
                                                                <PermissionLink class="dropdown-item" permission="Departments"
                                                                    href="/departments" :label="$t('message.headers.departments')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <PermissionLink class="dropdown-item" permission="Transporters" href="/transporters/index" :label="$t('message.headers.transporters')" />
                                                <PermissionLink class="dropdown-item" permission="SaleChannels" href="/sale-channels/index" :label="$t('message.headers.sale_channels')" />
                                                <AppPermission :permission="this.$constant.SettingPricePermission">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.setting_price')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                 <PermissionLink class="dropdown-item" permission="PriceList"
                                                                    href="/price-lists/index" :label="$t('message.headers.price_list')" />
                                                                <PermissionLink class="dropdown-item" permission="PriceDetails"
                                                                    href="/setting_price/index" :label="$t('message.headers.price_list_detail')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                
                                                <PermissionLink class="dropdown-item" permission="Locations"
                                                    href="/locations/index" :label="$t('message.headers.location_list')" />
                                            </div>
                                        </li>
                                    </AppPermission>
                                    <AppPermission :permission="this.$constant.transactionPermission">
                                        <li class="nav-item dropdown">
                                            <a class="nav-link dropdown-toggle" href="#" id="transactionDropdown" role="button"
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                <i class="fas fa-exchange-alt"></i> <span style="font-size:medium;">
                                                    {{$t('message.headers.transaction')}}
                                                </span>
                                            </a>
                                            <div class="dropdown-menu" aria-labelledby="transactionDropdown">
                                                <AppPermission :permission="this.$constant.importSupplierPermission">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.purchase_products')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <AppPermission :permission="this.$constant.planPermission">
                                                                    <ul class="parent-menu">
                                                                        <li class="nav-item">
                                                                            <a class="dropdown-item" href="#">
                                                                                {{$t('message.headers.plan_purchase_order')}}
                                                                            </a>
                                                                            <span class="dropdown-btn">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    viewBox="0 0 292.362 292.362">
                                                                                    <path
                                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                                    </path>
                                                                                </svg>
                                                                            </span>
                                                                            <div class="dropdown-menu sub-menu">
                                                                                <PermissionLink class="dropdown-item"
                                                                                    permission="Plans"
                                                                                    href="/inventory-plans/index"
                                                                                    :label="$t('message.headers.inventory_plan')" />                                                            
                                                                                <PermissionLink class="dropdown-item"
                                                                                    permission="PlanPurchases"
                                                                                    href="/plan-purchase/index"
                                                                                    :label="$t('message.headers.plan_purchase')"  />                                                             
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                </AppPermission>                                                                      
                                                                <!-- <template v-if="isUseOrderForSupplier || userId == this.$constant.isAdmin"> -->
                                                                <template v-if="isUseOrderForSupplier">
                                                                     <PermissionLink class="dropdown-item" permission="OrderSuppliers" href="/order-suppliers/index" :label="$t('message.headers.order_suppliers')" />  
                                                                </template>                                                                                                                         
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="purchaseOrders"
                                                                    href="/purchase-orders/index?action=import"
                                                                    :label="$t('message.headers.purchase_orders')" />                                                            
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="ReturnImportForSupplier"
                                                                    href="/purchase-orders/return" :label="$t('message.headers.return_purchase_orders')" />
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="SettlementAdvance"
                                                                    href="/settlement-advance/index" :label="$t('message.headers.settlement_advances')" />  
                                                                <a class="dropdown-item" href="/discount-bas/index">
                                                                    <span>
                                                                        {{$t('message.DiscountsBAS.title')}}
                                                                    </span>
                                                                </a>                                                              
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>

                                                <AppPermission :permission="'SaleCategory'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <AppPermission :permission="'SaleCategory'">
                                                                <a class="dropdown-item" href="#">
                                                                    {{$t('message.headers.sales')}}
                                                                </a>
                                                                <span class="dropdown-btn">
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        viewBox="0 0 292.362 292.362">
                                                                        <path
                                                                            d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                        </path>
                                                                    </svg>
                                                                </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <template v-if="isUseOrder">
                                                                <PermissionLink class="dropdown-item" permission="Orders"
                                                                href="/orders/index" :label="$t('message.headers.orders')" />
                                                                </template>
                                                                <PermissionLink class="dropdown-item" permission="Invoices"
                                                                    href="/invoices/index" :label="$t('message.headers.invoices')" />
                                                                <PermissionLink class="dropdown-item" permission="Returns"
                                                                    href="/returns/index" :label="$t('message.headers.returns')" />
                                                                <PermissionLink class="dropdown-item" permission="Refunds"
                                                                    href="/request-refund/index" :label="$t('message.headers.request_refund')" />
                                                            </div>
                                                            </AppPermission>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <AppPermission :permission="'TransferWarehouse'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.warehouse_transfer')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="Transfers"
                                                                    href="/transfers/index" :label="$t('message.headers.transfers')" />
                                                                <PermissionLink class="dropdown-item" permission="Requests"
                                                                    href="/request/index" :label="$t('message.headers.requests')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <AppPermission :permission="'LocationsProducts'">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.locations')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="TicketImport"
                                                                    href="/position-products/import"
                                                                    :label="$t('message.headers.ticket_import_location')" />
                                                                <PermissionLink class="dropdown-item" permission="TicketExport"
                                                                    href="/position-products/export"
                                                                    :label="$t('message.headers.ticket_export_location')" />
                                                                <PermissionLink class="dropdown-item"
                                                                    permission="TicketTransfer"
                                                                    href="/position-products/transfer"
                                                                    :label="$t('message.headers.ticket_transfer_location')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                 <AppPermission :permission="this.$constant.bankCashBookPermission">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.cash_book')}}
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" :permission="this.$constant.cashBookPermission"
                                                                href="/cash-book-money/index" :label="$t('message.headers.cash_book_money')" />
                                                                <PermissionLink class="dropdown-item" :permission="this.$constant.bankBookPermission"
                                                                href="/bank-book/index" :label="$t('message.headers.bank_book')" />
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <PermissionLink class="dropdown-item"
                                                                permission="Deliveries"
                                                                href="/deliveries/index"
                                                                :label="$t('message.headers.delivery')" />
                                            </div>
                                        </li>
                                    </AppPermission>
                                    
                                    <AppPermission :permission="this.$constant.reports">
                                        <li class="nav-item dropdown">                                            
                                                <a class="nav-link dropdown-toggle" href="#" id="reportDropdown" role="button" 
                                                data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-chart-line mr-1"></i>
                                                    <span style="font-size:medium;"> 
                                                        {{$t('message.headers.reports')}} 
                                                    </span>
                                                </a>
                                            <div class="dropdown-menu" aria-labelledby="reportDropdown">
                                                <!-- Báo cáo bán hàng -->
                                                <AppPermission :permission="this.$constant.salesReports">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">      
                                                            <!-- Báo cáo Bán hàng -->
                                                            <PermissionLink class="dropdown-item" :permission="this.$constant.salesReports" href="#" :label="$t('message.headers.sales')" />                                                        
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">                                                                
                                                                <PermissionLink class="dropdown-item" permission="ReportSaleDetails" 
                                                                :label="$t('message.headers.ReportSaleDetails')" :typeClick="true" @click="link" :routeName="'ReportSaleDetailIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportSaleChannel" 
                                                                :label="$t('message.headers.ReportSaleChannel')" :typeClick="true" @click="link" :routeName="'ReportSaleChannelsIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportSaleEmployee" 
                                                                :label="$t('message.headers.ReportSaleEmployee')" :typeClick="true" @click="link" :routeName="'ReportEmployeeIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportSaleBranch" 
                                                                :label="$t('message.headers.ReportSaleBranch')" :typeClick="true" @click="link" :routeName="'ReportBranchIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportRevenueProduct" 
                                                                :label="$t('message.headers.ReportRevenueProduct')"  :typeClick="true" @click="link" :routeName="'ReportRevenueTimeIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportRevenueInBranch" 
                                                                :label="$t('message.headers.ReportRevenueInBranch')"  :typeClick="true" @click="link" :routeName="'ReportRevenueBranchIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportRevenuePriceList" 
                                                                :label="$t('message.headers.ReportRevenuePriceList')" :typeClick="true" @click="link" :routeName="'ReportRevenuePriceListIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportRevenueCustomerGroup" 
                                                                :label="$t('message.headers.ReportRevenueCustomerGroup')" :typeClick="true" @click="link" :routeName="'ReportRevenueCustomerGroupIndex'"/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <AppPermission :permission="this.$constant.purchaseReports">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <!-- Báo cáo mua hàng -->
                                                            <PermissionLink class="dropdown-item" :permission="this.$constant.purchaseReports" href="#" :label="$t('message.headers.buy')" />                                                            
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="ReportPurchaseDetail" 
                                                                :label="$t('message.headers.ReportPurchaseDetail')" :typeClick="true" @click="link" :routeName="'ReportPurchaseDetailIndex'"/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                                <AppPermission :permission="this.$constant.productReports">
                                                    <ul class="parent-menu">
                                                        <li class="nav-item">
                                                            <a class="dropdown-item" href="#">
                                                                {{$t('message.headers.products')}} 
                                                            </a>
                                                            <span class="dropdown-btn">
                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                    viewBox="0 0 292.362 292.362">
                                                                    <path
                                                                        d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z">
                                                                    </path>
                                                                </svg>
                                                            </span>
                                                            <div class="dropdown-menu sub-menu">
                                                                <PermissionLink class="dropdown-item" permission="ReportStockDetail" 
                                                                :label="$t('message.headers.ReportStockDetail')" :typeClick="true" @click="link" :routeName="'ReportStockDetailIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportInventories" 
                                                                :label="$t('message.headers.ReportInventories')" :typeClick="true" @click="link" :routeName="'ReportInventoriesIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportInventoriesDetail" 
                                                                :label="$t('message.headers.ReportInventoriesDetail')" :typeClick="true" @click="link" :routeName="'ReportInventoriesDetailIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportValueBranch" 
                                                                :label="$t('message.headers.ReportValueBranch')" :typeClick="true" @click="link" :routeName="'ReportValueBranchndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportConsignment" 
                                                                :label="$t('message.headers.ReportConsignment')" :typeClick="true" @click="link" :routeName="'ReportConsignmentIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportStockLocation" 
                                                                :label="$t('message.headers.ReportStockLocation')" :typeClick="true" @click="link" :routeName="'ReportLocationsIndex'"/>

                                                                <PermissionLink class="dropdown-item" permission="ReportStockLocationConsignment" 
                                                                :label="$t('message.headers.ReportStockLocationConsignment')" :typeClick="true" @click="link" :routeName="'ReportLocationConsignmentIndex'"/>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </AppPermission>
                                            </div>
                                        </li>
                                    </AppPermission>
                                </ul>
                                <ul class="navbar-nav ml-auto">
                                    <li class="nav-item" v-if="hasPermission(this.$permissions.saleOnline.data) || hasPermission(this.$permissions.saleOnline.create)|| hasPermission(this.$permissions.saleOnline.update) || hasPermission(this.$permissions.saleOnline.delete)  || userId == this.$constant.isAdmin">
                                        <a class="nav-link " href="/sale-online/e-commerce/list" target="_blank"
                                            role="button" aria-haspopup="true" aria-expanded="false"
                                            style="margin-right: 5px">
                                            <i class="fas fa-shopping-cart mr-1"></i><span style="font-size:medium;">
                                                {{$t('message.headers.sell_online')}}
                                            </span>
                                        </a>
                                    </li>                                    
                                    <li class="nav-item" v-if="hasPermission(this.$permissions.orders.create) || hasPermission(this.$permissions.orders.update)  || userId == this.$constant.isAdmin">
                                        <a class="nav-link " href="/sale/create-order" target="_blank" role="button"
                                            aria-haspopup="true" aria-expanded="false" style="margin-right: 5px">
                                            <i class="fas fa-shopping-cart mr-1"></i><span style="font-size:medium;">
                                                {{$t('message.headers.sales')}}
                                            </span>
                                        </a>
                                    </li>                                    
                                </ul>
                            </div>
                        </nav>
                        <a href="javascript:void(0);" class="d-none icon-bars" style="text-decoration:none" @click="toggleMenu">
                            <i class="fa fa-bars"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalReportTransfer ref="modalReportTransfer"></ModalReportTransfer>
    <ModalProductInvoiceReport ref="modalProductInvoiceReport"></ModalProductInvoiceReport>
    <ModalProductBatchReport ref="modalProductBatchReport"></ModalProductBatchReport>
</template>

<script>
import store from "../store";
import PermissionLink from "@/components/PermissionLink";
import ModalReportTransfer from "@/views/reports/ModalReportTransfer";
import ModalProductBatchReport from "@/views/reports/ModalProductBatchReport";
import ModalProductInvoiceReport from "@/views/reports/ModalProductInvoiceReport";
import AppPermission from './AppPermission';
import permissionMixin from "@/permissionMixin";
import { mapGetters } from "vuex";
import { onChangeLanguage } from '@/setup/i18n.js';
export default {
    name: "Header",
    mixins: [permissionMixin],
    components: {
        ModalProductInvoiceReport, ModalProductBatchReport, ModalReportTransfer, PermissionLink, AppPermission
    },
    data() {
        return {
            userId: store.state.Auth?.user.id,
            isLang: localStorage.getItem('language') ?? 'vi',
            flagLanguages: [
                {id: 'vi', src: require('../../public/img/vi-lang.png')},
                {id: 'en', src: require('../../public/img/en-lang.png')},
                {id: 'ja', src: require('../../public/img/ja-lang.png')}
            ]
        }
    },
    computed: {
        currentBranch: () => store.state.CurrentBranch,
        branches: () => store.state.GlobalConfig?.userBranches,
        user: () => store.state.Auth?.user,
        ...mapGetters({
            isUseVoucher: 'settingStore/isUseVoucher',
            isUseCoupon: 'settingStore/isUseCoupon',
            isUsePromotion: 'settingStore/isUsePromotion',
            isUseOrder: 'settingStore/isUseOrder',
            isUseOrderForSupplier: 'settingStore/isUseOrderForSupplier',
            isUseDelivery: 'settingStore/isUseDelivery'
        })
    },
    methods: {
        setBranch(event) {
            var index = event.target.value;            
            store.commit('SetCurrentBranch', this.branches[index]);
            this.$nextTick(() => {
                // location.replace('/')
                location.reload();
            })
        },
        setLocale(locale){
            let reload = this.isLang != locale ? true : false;
            this.isLang = locale;
            onChangeLanguage(locale);
            reload && window.location.reload();
        },
        getSrcFlagLanguage(lang){
            const flag = this.flagLanguages.find(flag => flag.id == lang);
            return flag?.src ?? null;
        },
        logout() {            
            store.commit('RemoveAuth');
            this.$nextTick(() => {
                location.replace('/xadmin/login');
            })
        },
        onReportTransfer() {
            this.$refs.modalReportTransfer.show();
        },
        onReportProductBatch() {
            this.$refs.modalProductBatchReport.show();
        },
        onReportProductInvoice() {
            this.$refs.modalProductInvoiceReport.show();
        },
        toggleMenu(){
            var menu = document.getElementById('navbar-responsive');
            menu.classList.toggle("navbar-responsive_show");
        },
        link(name){            
            var route = null;
            route = this.$router.resolve({
                name: name,
            });
            window.open(route.href);
        },
    },
}
</script>

<style scoped lang="scss">
@import "../assets/css/header";
</style>
