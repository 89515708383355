<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none">
                    <h6 class="modal-title">{{$t('message.VoucherIndex.modal.create_list_voucher.title')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-5 d-flex align-items-center text-bold">{{$t('message.VoucherIndex.modal.create_list_voucher.number_voucher')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.VoucherIndex.modal.create_list_voucher.tooltip.number_voucher')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group mb-0">
                                <input type="number" class="form-control input-custom" autocomplete="off" v-model="voucherCode.slot">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-5 d-flex align-items-center text-bold">{{$t('message.VoucherIndex.modal.create_list_voucher.voucher_code_length')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.VoucherIndex.modal.create_list_voucher.tooltip.voucher_code_length')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group mb-0">
                                <input type="number" class="form-control input-custom" autocomplete="off" v-model="voucherCode.length">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-5 d-flex align-items-center text-bold">{{$t('message.VoucherIndex.modal.create_list_voucher.first_character')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.VoucherIndex.modal.create_list_voucher.tooltip.first_character')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group mb-0">
                                <input type="text" class="form-control input-custom" autocomplete="off" v-model="voucherCode.startStr">
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-5 d-flex align-items-center text-bold">{{$t('message.VoucherIndex.modal.create_list_voucher.last_character')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{$t('message.VoucherIndex.modal.create_list_voucher.tooltip.last_character')}}
                                </div>
                            </div>
                        </div>
                        <div class="col-7">
                            <div class="form-group mb-0">
                                <input type="text" class="form-control input-custom" autocomplete="off" v-model="voucherCode.endStr">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer  border-none">
                    <AppPermission :permission="this.$permissions.voucher.update?? '' ">
                    <button type="button" class="btn btn-save mr-2" @click="createMultiple()">
                        <i class="fa fa-save mr-1"></i>
                        {{$t('message.VoucherIndex.modal.create_list_voucher.button.save')}}
                    </button>
                    </AppPermission>
                    <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                        <i class="fa fa-ban mr-1"></i>
                        {{$t('message.VoucherIndex.modal.create_list_voucher.button.skip')}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$post, $alert} from "@/lib/utils";
    export default {
        name: "VoucherCodeListCreate",
        components: {},
        props: [],
        data() {
            return {
                voucherCode: {
                    slot: null,
                    length: null,
                    startStr: null,
                    endStr: null,
                    id: ''
                }
            }
        },
        mounted() {
        },
        methods: {
            show(id) {
                this.voucherCode.id = id;
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                this.removeData();
                window.jQuery(this.$refs.modal).modal('hide');
            },
            submit(){
                this.$emit('createVoucherCode', this.voucherCode);
                this.hide();
            },
            removeData(){
                Object.keys(this.voucherCode).forEach(key => {
                    this.voucherCode[key] = null;
                });
            },
            async createMultiple () {
                if(!this.voucherCode.slot){
                    $alert({code: 422, message: this.$t('message.VoucherIndex.modal.create_list_voucher.alerts.validate_number_voucher')});
                    return;
                }
                const lengthStartStr = this.voucherCode.startStr ? this.voucherCode.startStr.length : 0;
                const lengthEndStr = this.voucherCode.endStr ? this.voucherCode.endStr.length : 0;
                if((+this.voucherCode.length - (+lengthStartStr + +lengthEndStr)) < 6 || (+lengthStartStr + +lengthEndStr > 25) || (+this.voucherCode.length > 25)){
                    $alert({code: 422, message: this.$t('message.VoucherIndex.modal.create_list_voucher.alerts.validate_voucher_code_length')});
                }
                else{
                    const multipleData = await $post('/v1/vouchers/create-multiple', {...this.voucherCode});
                    if (multipleData.status.code == 200) {
                        $alert({code: 200, message: this.$t('message.VoucherIndex.modal.create_list_voucher.alerts.success')});
                        this.$emit('createVoucherCode');
                        this.hide();
                    }
                }
                
            }
        },
        beforeUnmount() {
            this.hide();
        },
    }
</script>
<style scoped>
.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid #2fa1a1;
  padding: 5px;
  background-color: #ffffff;
  top: -7px;
  left: 40px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent #2fa1a1 transparent transparent;
  position: absolute;
  left: -16px;
}

.icon-circle-custom:hover+.content-circle {
  display: block;
}
</style>

