<template>
    <AppPermission :permission="this.$permissions.plans.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>{{ $t("message.PlanIndex.title") }}</span> 
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for=""> <span>{{ $t("message.PlanIndex.record") }}</span> :</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.PlanIndex.branch") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('branchBody', 'branchDown', 'branchUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="branchDown"></i>
                                        <i class="fa fa-chevron-up" id="branchUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                            <div class="row">
                                <!-- <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.PlanIndex.chooseBranch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" @update:modelValue="doFilterSearch" :label="'branches'"/>
                                </div> -->

                                <q-select v-model="filterQuery.branches" 
                                    :modelValue="filterQuery.branches" 
                                    :default-options="this.branches.all" 
                                    :multiple="true" 
                                    :placeholder="$t('message.PlanIndex.chooseBranch')" 
                                    :isFilter="true"
                                    @update:modelValue="updateFilterBranch"
                                    class="border-bottom-1"/>
                            </div>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t("message.PlanIndex.time") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label for="" class="typeTitle">{{ $t("message.PlanIndex.createdBy") }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('createdByBody', 'createdByDown', 'createdByUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="createdByDown"></i>
                                        <i class="fa fa-chevron-up" id="createdByUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pb-3 pt-0" id="createdByBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.PlanIndex.chooseCreatedBy')" :modelValue="filterQuery.createdBy" :defaultOption="this.createdBy.all" @update:modelValue="doFilterSearch" :label="'createdBy'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-9">
                                        <label class="typeTitle">{{ $t('message.PlanIndex.status') }}</label>
                                    </div>
                                    <div class="col-1">
                                        <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                            <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                            <i class="fa fa-chevron-up" id="statusUp"></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                                <div class="row" v-for="status in this.listStatus" :key="status.id">
                                    <div class="col-12 d-flex align-items-center">
                                        <label class="container-checkbox">{{status.name}}
                                            <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.PlanIndex.search')" @filterSearch="changeQuickSearch" :inventoryPlanSearch="true"></InputQuickSearch>
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button class="btn btn-default ml-1" @click="clearFilter()">
                                    <i class="fa fa-filter px-1"></i>
                                    {{$t('message.button.removeFilter')}}
                                </button>
                                <!-- Thao tác -->
                                <AppPermission :permission="this.$constant.planActionPermission">
                                    <div class="dropdown" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fa fa-ellipsis-v px-2"></i>{{$t('message.InvoiceIndex.button.action')}}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <AppPermission :permission="this.$permissions.plans.create?? '' ">
                                                <a class="dropdown-item" @click="createMultiplePlanPurchase()">
                                                    <i class="fa fa-share" ></i>
                                                    {{ $t('message.PlanIndex.button.create') }}
                                                </a>
                                            </AppPermission>
                                            <!-- Xuất file tổng quan -->
                                            <AppPermission :permission="this.$permissions.plans.export?? '' ">
                                            <a class="dropdown-item"   @click.stop="exOverview(this.$constant.record_checked)">
                                                <i class="fas fa-download"></i>
                                                {{ $t('message.PlanIndex.button.exportOverview') }}
                                            </a>
                                            <!-- Xuất file chi tiết  -->
                                            <a class="dropdown-item" @click.stop="exDetailSelect()">
                                                <i class="fas fa-file-export"></i>
                                                {{ $t('message.PlanIndex.button.exportDetail') }}
                                            </a>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.plans.print?? '' ">
                                            <a class="dropdown-item" @click="printSelected()">
                                                <i class="fas fa-print"></i>
                                                {{ $t('message.InvoiceIndex.button.print')}}
                                            </a>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.plans.delete?? '' ">
                                            <a class="dropdown-item"  @click="deleteMultiple(this.$constant.delete)">
                                                <i class="fas fa-trash-alt"></i>
                                                {{ $t('message.PlanIndex.button.delete') }}
                                            </a>
                                            </AppPermission>
                                            <AppPermission :permission="this.$permissions.plans.cancel?? '' ">
                                            <a class="dropdown-item"  @click="cancelMultiple(this.$constant.cancelPromotionWarning)">
                                                <i class="fa fa-ban"></i>
                                                {{ $t('message.PlanIndex.button.cancel') }}
                                            </a>
                                            </AppPermission>
                                            <a class="dropdown-item" @click="sendMailAndZalo()" v-if="this.statusEmail">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.button.sendMailText') }}
                                            </a>
                                            <a class="dropdown-item" @click="sendZalo()">
                                                <i class="fa fa-share"></i>
                                                {{ $t('message.PurchaseOrderIndex.button.sendZalo') }}
                                            </a>
                                        </div>
                                    </div>
                                </AppPermission>
                                <AppPermission :permission="this.$permissions.plans.create?? '' ">
                                    <button class="btn btn-save ml-1" @click="create()">
                                        <i class="fa fa-plus px-1" ></i>
                                        {{ $t('message.PlanIndex.button.new') }}
                                    </button> 
                                </AppPermission>
                                 <AppPermission :permission="this.$permissions.plans.export?? '' ">
                                    <div class="dropdown">
                                        <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButton"
                                            style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <i class="fas fa-file-export px-2" />
                                        {{ $t('message.PlanIndex.button.export') }}
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <a class="dropdown-item" href="#" @click="exOverview()">
                                                <i class="fas fa-download pr-2"></i>{{ $t('message.PlanIndex.button.fileOverview') }}
                                            </a>
                                            <a class="dropdown-item" href="javascript:;" @click="exDetails()">
                                                <i class="fas fa-file-export pr-2"></i>{{ $t('message.PlanIndex.button.fileDetails') }}
                                            </a>
                                        </div>
                                    </div>
                                </AppPermission>
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2 w-100" id="divTable">
                                <table class="table table-index table-striped w-100">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="checkbox-table" v-if="fields.length > 0">
                                                <input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/>
                                            </th>
                                            <template v-for="field in fields" :key="field.field">
                                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                    <TableSortField :no-sort="!field.isColumn" @onsort="sort" 
                                                    :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                </th>
                                            </template>
                                        </tr>
                                    </thead>
                                    <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >
                                                    <tr class="master-row" :class="entry.id === this.planActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                        :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td v-if="field.selected" :style="field.style" class="py-0">
                                                                <a :href="'#row_' + index" class="text-dark tagA" @click="detailPlan(entry.id, entry)">
                                                                    <div v-if="field.field === 'status'">{{ entry.statusName }}</div>
                                                                    <div v-else-if="field.field === 'name'" class="description" :title="entry.name">{{ entry.name }}</div>
                                                                    <div v-else v-text="renderField(entry, field.field, field)"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="planActive === entry.id" :class="[ entry.id === planActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td :colspan="fields.length + 1" class="pb-0">
                                                            <div class="row detail-prd-row">
                                                                <div class="col-2 mt-2">
                                                                    <span :class="[ headerActive === this.$t('message.TransporterIndex.tabs.info') ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                        {{ $t('message.TransporterIndex.tabs.info') }}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__body-detail" v-if="planActive === entry.id && headerActive === this.$t('message.TransporterIndex.tabs.info')">
                                                        <!-- <td scope="col" class="checkbox-table border-top-0"></td> -->
                                                        <td :colspan="fields.length + 1" class="border-top-0">
                                                            <div class="mt-2 pb-4">
                                                                <div class="row m-0 p-0 detail-prd-row">
                                                                    <div class="col-4 px-4">
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t('message.PlanIndex.detail.code') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word description" v-text="entry.code" ></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t('message.PlanIndex.detail.name') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.name"></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2">
                                                                            <div class="col-5">
                                                                                {{ $t('message.PlanIndex.detail.branch') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word" v-text="entry.branchName"></div>
                                                                        </div>
                                                                        <div class="row border-bottom-1 mt-2 py-2" v-if="entry.plan_purchase">
                                                                            <div class="col-5">
                                                                                {{ $t('message.PlanIndex.detail.plan_purchase') }}:
                                                                            </div>
                                                                            <div class="col-7 ow-break-word text-primary" @click="linkToPlanPurchase(entry.plan_purchase.code)" v-text="entry.plan_purchase.code"></div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-4">
                                                                        <div class="mx-1">
                                                                            <div class="col-12">
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.PlanIndex.detail.time') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="d(entry.startDate) + ' ' + $t('message.planCreateUpdate.to') + d(entry.endDate)"></div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.PlanIndex.detail.estimate') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="entry.estimatedDate"></div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.PlanIndex.detail.status') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word">
                                                                                        <template v-if="(entry.status != this.$constant.plan_status_complete) && (entry.status != this.$constant.plan_status_cancel)">
                                                                                            <SelectSearch :vModel="true" v-model="this.statusEdit" 
                                                                                                :modelValue="this.statusEdit" 
                                                                                                :defaultOption="this.$constant.listPlanStatus" :label="'plan_status_' + entry.id" 
                                                                                                :readonly="true">
                                                                                            </SelectSearch>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="entry.statusName"></span>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4" id="noteRO">  
                                                                        <div class="mx-1">
                                                                            <div class="col-12">
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.PlanIndex.detail.createdBy') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="(entry.createdBy ? entry.createdBy + '  ' : '') + dt(entry.created_at)"></div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{ $t('message.PlanIndex.detail.description') }}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word position-relative">
                                                                                        <template v-if="(entry.status != this.$constant.plan_status_complete) && (entry.status != this.$constant.plan_status_cancel)">
                                                                                            <!-- <input type="text" class="border-0 bg-transparent w-100" :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="this.noteEdit"> -->
                                                                                            <textarea v-model.trim="this.noteEdit" :id="'note_'+ entry.id" :placeholder="$t('message.InvoiceIndex.note')" class="noteStyle px-4 text-gray font-italic border-0 bg-transparent w-100"></textarea>
                                                                                            <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                        </template>
                                                                                        <template v-else>
                                                                                            <span v-text="entry.note"></span>
                                                                                        </template>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="mt-2 pb-4 detail-prd-row">                                                                
                                                                <table class="table table-index table-striped">
                                                                    <thead class="z-1">
                                                                        <tr class="backgroup__detail border">
                                                                            <template v-for="field in fieldDetails" :key="field.field">
                                                                                <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                                                    <TableSortField :no-sort="!field.isColumn" @onsort="sort" 
                                                                                    :name="field.name" :field="field.field" :style="field.style" class="py-2"/>
                                                                                </th>
                                                                            </template>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-if="this.dataDetail?.length > 0">
                                                                            <template v-for="(detail, i) in this.dataDetail" :key="i">
                                                                                <tr class="table-header border" v-if="i >= (this.paginateDetail.currentPage - 1)*10 && i < this.paginateDetail.currentPage*10">
                                                                                    <td class="py-2">{{ i + 1 }}</td>
                                                                                    <td class="py-2">{{ detail.product ? detail.product.code : null}}</td>
                                                                                    <td class="py-2">{{ detail.product ? detail.product.name : null}}</td>
                                                                                    <td class="py-2">{{ detail.product_unit ? detail.product_unit.unitName : null}}</td>
                                                                                    <td class="py-2">{{ n(detail.minimalQuantity) }}</td>
                                                                                    <td class="py-2">{{ n(detail.maximumQuantity) }}</td>
                                                                                    <td class="py-2">{{ n(detail.estimateNumber) }}</td>
                                                                                    <td class="py-2">{{ n(detail.avgNumberOfSale) }}</td>
                                                                                    <td class="py-2">{{ n(detail.sysEstimateNumber) }}</td>
                                                                                    <td class="py-2">{{ n(detail.availableInventory) }}</td>
                                                                                    <td class="py-2">{{ n(detail.totalQuantityOrder) }}</td>
                                                                                </tr>
                                                                            </template>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <Pagination :value="paginateDetail" :record="this.numberRecordDetail" v-if="this.paginateDetail.totalRecord >= this.numberRecordDetail"/>
                                                            </div>
                                                            <div class="row detail-prd-row mt-1 pr-4">
                                                                <div class="col-11 flex-end">{{ $t('message.PlanIndex.total_products') }}:</div>
                                                                <div class="col-1 flex-end" v-text="n(entry.totalProducts)"></div>
                                                            </div>
                                                            <div class="row detail-prd-row mt-1 pr-4">
                                                                <div class="col-11 flex-end">{{ $t('message.PlanIndex.total_quantity_products') }}:</div>
                                                                <div class="col-1 flex-end" v-text="n(entry.totalQuantityProducts)"></div>
                                                            </div>
                                                            <div class="row detail-prd-row flex-end my-4 pr-4">
                                                                
                                                                <AppPermission :permission="this.$permissions.plans.update?? '' ">
                                                                <!-- Lưu nhanh -->
                                                                <button type="button" class="btn btn-save mx-1" @click="quickSave(entry)" v-if="entry.status != this.$constant.plan_status_cancel">
                                                                    <i class="fa fa-save ml-1"></i>
                                                                    {{ $t('message.PlanIndex.button.save') }}
                                                                </button>
                                                                <!-- Mở phiếu -->
                                                                <button type="button" class="btn btn-save mx-1" @click="updateInventoryPlan(entry.id)" v-if="entry.status == this.$constant.plan_status_draft">
                                                                    <i class="fa fa-share mr-1"></i>
                                                                    {{ $t('message.PlanIndex.button.open') }}  
                                                                </button>   
                                                                </AppPermission>
                                                                 <AppPermission :permission="this.$permissions.plans.update?? '' ">
                                                                <!-- Xử lý phiếu -->
                                                                <button type="button" class="btn btn-save mx-1" @click="processInventoryPlan(entry.id)" v-if="entry.status == this.$constant.plan_status_wait && !entry.planPurchaseId">
                                                                    <i class="fa fa-share mr-1"></i>
                                                                    {{ $t('message.PlanIndex.button.process') }}  
                                                                </button>
                                                                </AppPermission>
                                                                <AppPermission :permission="this.$permissions.plans.export?? '' ">
                                                                <button type="button" class="btn btn-save mx-1" @click.stop="exDetailsById(entry.id)">
                                                                    <i class="fa fa-file-export"></i>
                                                                    {{ $t('message.PlanIndex.button.export') }}
                                                                </button>
                                                                </AppPermission>
                                                                <AppPermission :permission="this.$permissions.plans.print?? '' ">
                                                                <button type="button" class="btn btn-secondary text-white mx-1" @click.stop="printMultiple(entry.id)">
                                                                    <i class="fa fa-print" aria-hidden="true"></i>
                                                                    {{ $t('message.PlanIndex.button.print') }}
                                                                </button>
                                                                </AppPermission>
                                                                <AppPermission :permission="this.$permissions.plans.cancel?? '' ">
                                                                <button type="button" class="btn btn-danger mx-1" v-if="entry.status == 1 || entry.status == 2" @click="cancel(entry, this.$constant.cancelPromotionWarning)">
                                                                    <i class="fa fa-times" aria-hidden="true"></i>
                                                                    {{ $t('message.PlanIndex.button.cancel') }}
                                                                </button>
                                                                </AppPermission>
                                                                 <AppPermission :permission="this.$permissions.plans.delete?? '' ">
                                                                <button type="button" class="btn btn-danger mx-1" v-if="entry.status == 1" @click="deleted(entry, this.$constant.delete)">
                                                                    <i class="fa fa-trash-alt" aria-hidden="true"></i>
                                                                    {{ $t('message.PlanIndex.button.delete') }}
                                                                </button>
                                                                </AppPermission>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr class="detail-row bg-white border__footer-detail" v-if="planActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ModalDeletePlans ref="ModalDeletePlans" @updated="refreshData"/>
            <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
            <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
            <ModalSelectSendMailAndZaloTemplate ref="ModalSelectSendMailAndZaloTemplate"/>
        </div>
        <ModalSelectSendZaloGroupTemplate ref='ModalSelectSendZaloGroupTemplate' />
    </AppPermission>
</template>

<script>
import {$get, clone, $post, $alert, forEach} from "../../lib/utils";
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import Pagination from "../../components/Pagination";
import SelectSearch from "@/components/SelectSearch.vue";
import store from "../../store";
import SelectDateTime from "@/components/SelectDateTime.vue";
import emitter from "@/lib/emitter";
import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
import ModalDeletePlans from './tabs/ModalDeletePlans';
import TableSortField from "@/components/TableSortField";
import http from '@/services/api';    
import exportMixin from "@/exportMixin";
import planDetailsMixin from "@/planDetailsMixin";
import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate.vue';
import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
import AppPermission from '@/components/AppPermission.vue';    
import ModalSelectSendMailAndZaloTemplate from '@/components/ModalSelectSendMailAndZaloTemplate.vue'; 
import ModalSelectSendZaloGroupTemplate from '@/components/ModalSelectSendZaloGroupTemplate.vue';
import QSelect from '@/components/QSelect';
import { mapGetters } from "vuex";   
export default {
    name: "InventoryPlanIndex",
    mixins: [exportMixin, planDetailsMixin],
    components: {
        InputDateRangePicker, TableSortField, Pagination, SelectSearch, SelectDateTime, InputQuickSearch, ModalDeletePlans,
        ModalSelectPrintTemplate, PreviewTemplate, AppPermission, ModalSelectSendMailAndZaloTemplate, ModalSelectSendZaloGroupTemplate, QSelect
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            entries: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            },
            filter: {
                inventory_plan_code: '',
                plan_purchase_code: '',
            },
            numberRecord: 15,
            filterQuery: {
                status: [],
                branches: '',
                createdBy: '',
                orderBy: '',
                saleChannel: '',
                paymentMethod: '',
                priceList: '',
                keyword: '',
            },
            currentBranchId: store.state.CurrentBranch.id,
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },
            createdBy: {
                filter: globalConfig.users,
                all: globalConfig.users,
                search: null,
            },
            sortTime: 0,
            checkedAll: false,
            arrayChecked: [],
            loading: false,
            fields: [],
            fieldDetails: [],
            planActive: null,
            dataDetail: [],
            paginateDetail: {
                currentPage: 1,
                lastPage: 1
            },
            numberRecordDetail: 10,
            isFilter: false,
            statusEdit: null,
            noteEdit: null,
        }
    },
    mounted() {
        document.title = this.$t('message.PlanIndex.documentTitle');
        const query = clone(this.$route.query);
        if(query['code'] != null){
            this.filterQuery['keyword'] = query['code'];
            this.loadDetail();
        }else{
            this.clearFilter();
        }
    },
    methods: {
        async updateFilterBranch(values) {
            this.filterQuery.branches = [...values];
            this.filterQuery.page = 1;
            this.isFilter = true;
            await this.load();
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/inventory-plans/index',
                query: {}
            });
            Object.keys(this.filter).forEach(key => {
                this.filterQuery[key] = '';
            });
            Object.keys(this.filterQuery).forEach(key => {
                if(key == 'status'){
                    this.filterQuery[key] = [this.$constant.plan_status_draft, this.$constant.plan_status_wait, this.$constant.plan_status_complete];
                }else{
                    this.filterQuery[key] = '';
                }
            });
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("clearSelectDateTime");
            this.arrayChecked = [];
            this.numberRecord = 15;
            this.sortTime = 0;
            this.filterQuery.branches = [this.currentBranchId];
            this.planActive = null;
            this.isFilter = false;
            await this.load();
        }, 
        async loadDetail(){
            await emitter.emit("clearQuickSearch");
            await emitter.emit("clearSelectSearchValue");
            await emitter.emit("clearSelectDateTime");
            await this.load();
            this.entries.forEach((entry) => {
                this.detailPlan(entry.id, entry);
            });
        },
        async doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        async changeQuickSearch(object){
            const query = clone(this.filterQuery);
            Object.keys(this.filter).forEach(key => {
                object.forEach(obj => {
                    Object.keys(obj).forEach(o => {
                        if(key == o && obj[o] != ''){
                            this.filter[key] = obj[o];
                            query[o] = obj[o];
                        }else if(key == o && obj[o] == ''){
                            delete query[o];
                        }
                    });
                })
            });
            query['page'] = 1;
            this.isFilter = true;
            this.filterQuery = {...query};
            this.load();
        },
        doFilterTime(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.value){
                query[v.label] = v.value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        async onChange(v) {
            this.numberRecord = v.target.value
            let query = clone(this.filterQuery);
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        async pageChange(page) {
            const query = clone(this.filterQuery);
            query['page'] = page;
            this.filterQuery = {...query};
            this.isFilter = true;
            await this.load();
        },
        setPaginateDataDetail(){
            this.paginateDetail.currentPage = 1;
            this.paginateDetail.lastPage = Math.ceil(this.dataDetail.length / 10);
            this.paginateDetail.totalRecord = this.dataDetail.length;
        },
        refreshData(){
            this.load();
            this.arrayChecked = [];
        },
        async load() {
            this.loading = true;
            emitter.emit("onLoading");
            const res = await $post('/v1/plans/data', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});
            this.entries = res.result.data.entries.data;
            this.fields = res.result.data.fields;
            this.fieldDetails = res.result.data.fieldDetails;

            this.paginate.totalRecord = res.result.data.entries.total;
            this.paginate.currentPage = res.result.data.entries.current_page;
            this.paginate.lastPage = res.result.data.entries.last_page;
            this.isCheckedAll();
            emitter.emit("offLoading");

            if (res.result.data.listStatus) {
                this.listStatus = res.result.data.listStatus;
            }
            this.loading = false;
        },
        isExistInArrayChecked(entry, array = []){
            if(array.includes(entry.id)){
                entry.checked = true;
                return true;
            }else{
                entry.checked = false;
                return false;
            }
        },
        countChecked(id){
            let arr = this.arrayChecked;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayChecked = arr;
            this.isCheckedAll();
        },
        isCheckedAll(){
            let getChecked = 0;
            this.entries.forEach((entry) => {
                const found = this.arrayChecked.find(id => id === entry.id);
                if(found){
                    getChecked += 1;
                    entry.checked = true;
                }
            });

            if(getChecked > 0 && getChecked == this.entries.length){
                this.checkedAll = true;
            }else{
                this.checkedAll = false;
            }
        },
        removeChecked(){
            this.arrayChecked = [];
            this.isCheckedAll();
        },
        onCheckedAll(value) {
            if(value == true){
                this.entries.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(!found){
                        this.countChecked(entry.id);
                    }
                })
            }else{
                this.entries.forEach(entry => {
                    this.countChecked(entry.id);
                })
            }
        },
        doFilter(field, value) {
            const query = clone(this.filterQuery);
            if(field == 'status'){
                if(query['status'].includes(value)){
                    query['status'] = query['status'].filter(item => item !== value)
                }else{
                    query['status'].push(value)
                }
            }else{
                query[field] = value;
            }
            query['page'] = 1;
            this.filterQuery = {...query};
            this.isFilter = true;
            this.load();
        },
        detailPlan(id, entry = null){
            this.dataDetail = [];
            if (id === this.planActive) {
                this.planActive = null;
            } else {
                this.planActive = id;
                if(entry && entry.details){
                    this.dataDetail = this.dataDetail.concat(entry.details);
                }
                if(this.dataDetail){
                    this.dataDetail.sort(function(a, b) { return new Date(b.created_at) - new Date(a.created_at) });
                }
                this.noteEdit = entry && entry.note ? entry.note : null;
                this.statusEdit = entry && entry.status ? entry.status : null;
                this.setPaginateDataDetail();
            }
            this.headerActive =  this.$t('message.TransporterIndex.tabs.info');
        },
        deleted (entry, type) {
            this.$refs.ModalDeletePlans.show(entry, type);
        },
        cancel (entry, type) {
            this.$refs.ModalDeletePlans.show(entry, type);
        },
        create(){
            var route = null;
            route = this.$router.resolve({
                name: 'PlansCreateUpdateScreen',
                query: {
                    'type': 'create',
                }
            });
            window.open(route.href, '_blank');
        },
        updateInventoryPlan(id = null){
            var route = null;
            route = this.$router.resolve({
                name: 'PlansCreateUpdateScreen',
                query: {
                    'type': 'update',
                    'id': id,
                }
            });
            window.open(route.href, '_blank');
        },
        processInventoryPlan(id = null){
            var route = null;
            route = this.$router.resolve({
                name: 'PlanPurchaseCreateUpdateScreen',
                query: {
                    'type': 'create',
                    'ids': '[' + id + ']',
                }
            });
            window.open(route.href, '_blank');
        },
        async createMultiplePlanPurchase(){
            const res = await $post('/v1/plans/get-list-plans', {ids: this.arrayChecked});
            if(res.result && !res.result.status && res.result.message){
                $alert({code: 500, message: res.result.message});
                return;
            }
            var route = null;
            route = this.$router.resolve({
                name: 'PlanPurchaseCreateUpdateScreen',
                query: {
                    'type': 'create',
                    'ids': '[' + this.arrayChecked + ']',
                }
            });
            window.open(route.href, '_blank');
        },
        cancelMultiple (type){
            this.$refs.ModalDeletePlans.show(null, type, 'multiple', this.arrayChecked);
        },
        deleteMultiple (type){
            this.$refs.ModalDeletePlans.show(null, type, 'multiple', this.arrayChecked);
        },
        async quickSave(entry = null){
            if(entry){
                let data = {
                    "id": entry.id ?? null,
                    "note": this.noteEdit ?? null,
                    "status": this.statusEdit ?? null,
                }
                const res = await $post('/v1/plans/quick-save', {...data});
                if(res.result && res.result.status){
                    $alert({code: 200, message: this.$t('message.PlanIndex.alerts.update_success')});
                    this.load();
                }else if(res.result && !res.result.status && !res.result.message){
                    $alert({code: 500, message: this.$t('message.PlanIndex.alerts.update_error')});
                }else if(res.result && !res.result.status && res.result.message){
                    $alert({code: 500, message: res.result.message});
                }
            }
        },
        async exOverview(action = null){
            emitter.emit("onLoading");
            let query;
            switch (action) {
                case this.$constant.record_checked:
                    query = {...this.$route.query, ...this.filterQuery, record: this.numberRecord, ids: this.arrayChecked }
                    break;            
                default:
                    query = {...this.$route.query, ...this.filterQuery, record: this.numberRecord }
                    break;
            }   
            const res = await http.download('/v1/plans/ex-overview', query);
            this.returnDataExport(res);    
        },
        async exDetailsById(id){                 
            emitter.emit("onLoading");              
            const res = await http.download('/v1/plans/ex-detail-by-id', {id:id});
            this.returnDataExport(res);    
        },
        async exDetails(){
            emitter.emit("onLoading");              
            const res = await http.download('/v1/plans/ex-detail');
            this.returnDataExport(res);    
        },
        async exDetailSelect(){                           
            emitter.emit("onLoading");              
            const res = await http.download('/v1/plans/ex-detail', {ids: this.arrayChecked});
            this.returnDataExport(res);    
        },
        // In nhiều phiếu
        async printSelected(){                  
            const res = await $post('/v1/plans/data-by-ids', {
                ids: this.arrayChecked,
            });                     
            if(res && res.status.code == 200 && res.result.length > 0) {
                await this.getTemplatePrint(res.result);                
            }
        },
        // In phiếu 1 phiếu          
        async printMultiple(id) {            
            this.editorDataClone = '';
            const res = await $post('/v1/plans/get-details', { id: id });                      
            if (res && res?.status && res.status.code == 200 && res?.result) {                                 
                await this.getTemplatePrint(res.result);
            }          
        },
        async getTemplatePrint(entry) {                       
            const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabPlanDetails});                
            if(res && res.status.code == 200){
                if(res.result) {  
                    this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                }
            }  
        },
        async updateContent(data){                 
            this.content = data.content;               
            if (data.dataPrint && Array.isArray(data.dataPrint)) {                 
               await this.startPrintMultiple(data);
            } else {                 
               await this.print(data.dataPrint);          
            }    
        },
        async print(entry) {            
            entry.typePrint = this.$constant.TabPlanDetails;                 
            let dataPrint = await this.dataPrintPlanDetails(entry);           
            this.editorDataClone = await this.renderDataPlanDetails(dataPrint, this.content);
            this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
        }, 
        async startPrintMultiple(data){
            let dataClone = '';   
            let orderItem = '';    
            let dataPrint = '';                 
            let breakPage = `<div style="page-break-before: always"></div>`            
            for (let index = 0; index < data.dataPrint.length; index++) {                    
                const print = this.formatproductPrintCommon(data.dataPrint[index]);                              
                print.typePrint = this.$constant.TabPlanDetails;                
                dataPrint = await this.dataPrintPlanDetails(print);
                orderItem = await this.renderDataPlanDetails(dataPrint);                
                if (index != data.dataPrint.length - 1) {
                    dataClone = dataClone + orderItem + breakPage;
                } else {
                    dataClone = dataClone + orderItem;
                }                
            }
            this.editorDataClone = dataClone;            
            if(this.editorDataClone !== null) await this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);     
        },
        linkToPlanPurchase(code){
            var route = null;
            route = this.$router.resolve({
                name: 'PlanPurchaseIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        async sendMailAndZalo() {
            this.$refs.ModalSelectSendMailAndZaloTemplate.show(this.$constant.TabPlanDetails, this.$constant.typeImportProducts, this.arrayChecked);
        },
        async sendZalo () {
            const typeSend = this.$constant.typeSendZaloGroup;
            const phone = null;
            this.$refs.ModalSelectSendZaloGroupTemplate.show(this.$constant.TabPlanDetails, this.$constant.typeImportProducts, this.arrayChecked, typeSend, phone);
        },
    },
    computed: {
        ...mapGetters({
            statusEmail: 'settingStore/email',
        }),
    },
}
</script>

<style scoped>
#notData{
    font-weight: 700;
    font-size: 14px;
}
.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;
}

.img-container {
    display: flex;
    flex-direction: row;
    gap: 1rem
}

.img-list {
    display: flex;
    flex-direction: column;
}

.img-main-item {
    width: 100% !important;
    object-fit: cover;
}

.text-title {
    line-height: 52px;
}

#number_records {
    margin-top: 11px;
}

#typeCard {
    margin-top: -8px;
}

.child-category-container {
    margin-left: 1rem
}

.child-category-item {
    display: flex;
    justify-content: space-between;
    margin-top: .5rem
}
.customer-group {
    border: none;
    padding-left: 0px;
    border-bottom: 1px solid #f1f1f1;
}
.custom-input {
    border: none;
    border-bottom: 1px solid #f1f1f1;
}
.custome-radio {
    margin-top: 10px;
}
.form-check {
    margin-bottom: 10px;
}
.table-index-cus thead {
    background-color: #B1DDF0;
    
}
.table-index-cus {
    width: 900px !important;
}


.lstCustomer tr td , .lstCustomer tr th {
    /* min-width: 150px !important;
    min-height: 500px !important; */
    vertical-align: middle !important;
    padding: 10px;
}

.lstCustomer {
    border-collapse: collapse;
    width: 2600px !important;
    overflow-x: scroll;
    max-height: 700px;
    display: block;
    margin-top: -1px;
    margin-right: 0px;
}
.lstCustomer thead th {
    border: none;
    
}
.lstCustomer {
    width: 100%;
  }

  @media (max-width: 1030px) {
    .lstCustomer {
        max-height: 400px !important;
    }

    .lstCustomer {
        max-height: 500px;
    }
}

@media screen and (max-width: 1366px) and (min-width: 1030px){

    .lstCustomer {
        max-height: 500px;
    }
    
}
select {
    border: none;
}

.label__file {
    height: 30px;
    cursor: pointer;
    width: 100%;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.nav-link.active{
    outline: none !important;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.noteStyle {
    width: 100% !important;
    max-height: 120px !important;
    border: 0;
    border-radius: 0;
    padding-right: 0 !important;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
</style>
