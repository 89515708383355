import {numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {                      
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Tien}";
            var searchText2 = "{Noi_Dung}";
            var searchText3 = "{Han_Can_Tru}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            if(dataValue?.convertProducts && dataValue.convertProducts.length){
                for (let index = 1; index < dataValue.convertProducts.length; index++) {
                    let template = htmlObject.querySelector("#content-product").children[0];
                    let clone = template.cloneNode(true);
                    clone.children[0].innerHTML = "";
                    let node = document.createElement("tbody");
                    node.innerHTML = clone.outerHTML;
                    htmlObject.querySelector("#content-product").appendChild(node);
                }
                templateOrder = htmlObject.outerHTML;
                dataValue.convertProducts.forEach((product) => {
                    product.forEach(data => {
                        templateOrder = templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                        // templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                    });
                });
            }            
            return templateOrder;
        },
        getProduct(entry){
            let data = [];
            let details = entry.details;                        
            details.forEach((d, i) => { 
                data.push({
                    STT: i + 1,
                    So_Tien: d?.amount ? numberFormat(d.amount) : '',
                    Noi_Dung: d?.content ? d.content : '',
                    Han_Can_Tru: d?.deductionLimit ? d.deductionLimit : '',
                });                
            });            
            return data;
        },
        renderData(dataValue){                               
            this.editorDataClone = this.content;            
            let image = null;          
            let barcode = ''; 
            if(dataValue && dataValue.common){
                dataValue.common.forEach(data => { 
                    let image = '<img src="' + data.value + '">';                    
                    switch (data.key) {      
                        case 'Logo_Cua_Hang':
                            image = '<img src="' + data.value + '">';                
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', image)?? '';
                            break;                                                          
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', data.value ) ?? null;
                            break;
                    }                  
                });
            }                               
            return this.formatShowProduct(dataValue, this.editorDataClone);
        },      
        dataPrint(entry) {
            // eslint-disable-next-line no-unreachable
            let convertProducts = []; 
            let common = [                
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry?.branch ? entry.branch.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry?.branch ? entry.branch.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: entry?.branch ? entry.branch.address : ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry?.branch ? (entry.branch.ward ? entry.branch.ward.name: ''): ''},                
                {key:'Khu_Vuc_QH_TP', value: (entry.branch ? (entry.branch.district ? entry.branch.district.name : '') : '') + ' - ' + (entry.branch ? (entry.branch.province ? entry.branch.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch ? entry.branch.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch ? entry.branch.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_Gian_Tao_Phieu', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY H:m') : ''},
                {key:'Ngay_Thang_Nam_Tao', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ngay_Thang_Nam', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: entry.code},
                {key:'Nguoi_Tao',value: entry.user_create.name?? null},
                {key:'Nhan_Vien_Ban_Hang', value: entry.user_create ? entry.user_create.name : ''},
                {key:'Nhan_Vien', value: entry.user_create ? entry.user_create.name : ''},                
            ];            
            let totalQuantity = 0;          
            let keyOrderSupplier = [];
            switch (entry.typePrint) {
                case this.$constant.TypePrintDiscountBas:
                    keyOrderSupplier = [                        
                        {key:'Tieu_De_In',value: "CÁC KHOẢN CHIẾT KHẤU SAU BAS"},
                        {key: 'Ma_BAS', value: entry?.code ? entry.code : ''}, 
                        {key: 'Thoi_Gian_Tao', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY H:m') : ''}, 
                        {key: 'Nguoi_Tao_Phieu', value: entry?.user_create ? entry.user_create.name : ''}, 
                        {key: 'Trang_Thai', value: entry?.status ? entry.status : '' },                         
                        {key: 'Tong_So_Tien', value: entry?.amount ?  numberFormat(entry.amount) : 0 }, 
                        {key: 'Ghi_Chu', value: entry?.note ? entry.note : ''}, 
                    ];                  
                    break;                       
            }                              
            common.push(...keyOrderSupplier);             
            let products = this.getProduct(entry);
            products.forEach(product => {                                
                convertProducts.push([
                    {key:'STT', value: product.STT},
                    {key:'So_Tien', value:  product.So_Tien?? ''},
                    {key:'Noi_Dung', value: product.Noi_Dung?? ''},
                    {key:'Han_Can_Tru', value: product.Han_Can_Tru?? ''},
                ]);
            });                                         
            return {common, convertProducts};
        },
        getDisProvince(branches){            
            let list=[];
            let districtName;
            let provinceName;
            // let branchName;
            branches.forEach(br => {                
                // branchName = br?.branch ? br.branch.name : '';
                districtName = br?.branch && br.branch?.province ? br.branch.district.name : '';
                provinceName = br?.branch && br.branch?.province ? " - " + br.branch.province.name : '';               
                list.push(districtName + provinceName);
            });            
            return list.toString();
        },
        getWards(branches){            
            let list = [];            
            let wardName;
            // let branchName;
            branches.forEach(br => {
                // branchName = br?.branch ? br.branch.name : '';
                wardName = br?.branch && br.branch?.ward ? " - " + br.branch.ward.name : '';                
                list.push(wardName);
            });
            return list.toString();                     
        },
    },
}
  