import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { get } from 'lodash';
import config from "../config";

const ERROR_CODE_VALIDATE = 422;
const ERROR_CODE_NOT_FOUND = 404;
const ERROR_CODE_UNAUTHORIZED = 401;
const ERROR_CODE_BAD_REQUEST = 400;
const ERROR_CODE_FORBIDDEN = 403;

const API_URL = config.App.v1ApiUrl;
const language = localStorage.getItem('language') ?? 'vi';

let callingApiCount = 0;

function reduceCallingAPICount() {
    if (callingApiCount > 0) {
        callingApiCount -= 1;
    }
}
axios.defaults.baseURL = API_URL;
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.interceptors.request.use(async function (config) {
    config.headers['X-Branch-Id'] = store.state.CurrentBranch?.id.toString() || '';
    const token = store.state.Auth?.token || '';
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    callingApiCount += 1;
    // store.dispatch('setIsCallingAPI', true);

    if (callingApiCount === 0) {
        // store.dispatch('setIsCallingAPI', false);
    }

    config.headers['language'] = language;
    return config;
}, function (err) {
    reduceCallingAPICount();
    return Promise.reject(err);
});

axios.interceptors.response.use(
    function (response) {
        reduceCallingAPICount();
        return response;
    },
);

export default {
    request(method, url, params, data, headers = {}, config = {}) {
        return axios.request({ ...config, url, params, data, method: method.toLowerCase(), headers });
    },

    get(url, params) {
        return this.request('get', url, params, {});
    },

    post(url, data, headers = {}, config = {}) {
        return this.request('post', url, {}, data, headers, config);
    },

    put(url, data) {
        return this.request('put', url, {}, data);
    },

    delete(url, data = {}) {
        return this.request('delete', url, {}, data);
    },

    async download(url, data) {
        return this.request('post', url, {}, data, {}, { responseType: 'blob' });
    },

    init() {
        axios.defaults.baseURL = process.env.VUE_APP_API_URL;
        axios.defaults.headers.common.Accept = 'application/json';
        axios.defaults.headers.common['Content-Type'] = 'application/json';
    },

    setSessionIdHeader(sessionId) {
        axios.defaults.headers.common.Authorization = 'Bearer ' + sessionId;
    },

    removeSessionIdHeader() {
        axios.defaults.headers.common.Authorization = '';
    },
    caseNetWorkError(e) {
        const error = e.toJSON();
        const { message } = error;
        if (message === 'Network Error') {
            return true;
        }
        return false;
    },
    _setError(e, url) {
        if (this.caseNetWorkError(e)) {
            const currentRoute = get(router, 'history.router.history.current.name');
            if (!(currentRoute === 'Error' || currentRoute === 'Login' || url === '/admin/logout')) {
                router.push({
                    name: 'Error',
                    params: {
                        errors: {
                            status: 999,
                            error_message: 'Network Error',
                        },
                    },
                });
            }
        }

        const errorCode = e.response ? e.response.status : 500;
        switch (errorCode) {
            case ERROR_CODE_VALIDATE:
            case ERROR_CODE_NOT_FOUND:
            case ERROR_CODE_BAD_REQUEST:
                return {
                    status: false,
                    errorCode: errorCode,
                    data: e.response ? e.response.data.errors : 'error',
                    message: e.response ? e.response.data.message : 'Unknow',
                    error: e,
                };
            case ERROR_CODE_UNAUTHORIZED:
                store.dispatch('userStore/clearInfoUserLoginAndRedirectToLogin');
                break;
            case ERROR_CODE_FORBIDDEN:
                store.dispatch('commonStore/goToErrorPage', { code: errorCode, message: e.response ? e.response.data.message : 'Unknow' });
                break;
            default:
                store.commit('commonStore/SET_ERROR_CODE', errorCode);
                break;
        }

        return e;
    },
};