<template>
    <div >
      <div class="container mt-0">
        <div class="row m-0 p-0">
            <div class="mainLeft my-4">
                <div class="card" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>{{$t('message.common.integrateSalesChannels')}}</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pt-0" id="branchBody">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item" @click="listShop()">
                                {{$t('message.common.linkToECommerce')}}
                            </div>
                        </div>
                        <div class="row px-2 mt-2">
                            <div class="col-12 sidebar-item active" @click="mappingProducts()">
                                {{$t('message.common.linkToProduct')}}
                            </div>
                        </div>
                    </div>
                </div>

                <!-- <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>{{$t('message.headers.products')}}</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 pb-3 pt-2" id="roleBody">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item">
                                Đăng bán hàng hóa
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card mt-4" id="typeCard">
                    <div class="card-header mx-0 px-0" id="typeHeader">
                        <div class="row mx-2">
                            <div class="col-12 pl-1 main-title">
                                <h5><b>Marketing</b></h5>
                            </div>
                        </div>
                    </div>
                    <div class="card-body my-0 py-2" id="status">
                        <div class="row px-2">
                            <div class="col-12 sidebar-item">
                                Tự động đẩy hàng hóa
                            </div>
                        </div>
                        <div class="row px-2 mt-2">
                            <div class="col-12 sidebar-item">
                                Thông tin khách hàng
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        
            <div class="mainRight pr-0">
                <div class="row">
                    <div class="col-12 h6">
                        <a href="/sale-online/e-commerce/list" class="text-primary text-decoration-none"><b>{{$t('message.common.linkToECommerce')}}</b></a><i class="fa fa-chevron-right ml-2 cursor-pointer text-secondary mr-2"></i>{{$t('message.common.linkToProduct')}}
                    </div>
                </div>
                <div class="sync-inserted mt-1">
                    <div class="setting-mapping-wrapper selectProduct sync-inserted">
                        <div class="mapping-header">
                            <div class="d-flex align-items-center">
                                <div class="filter-title">{{$t('message.common.ecommerceLevel')}}:</div>
                                <div class="setting-sync-option">
                                    <InputSelectSearch :modelValue="channel" :defaultOption="this.$constant.listSaleChannel" :noSearch="true" @update:modelValue="setSaleChannel"></InputSelectSearch>
                                </div>
                                <div class="filter-title mx-3">{{$t('message.common.stall')}}:</div>
                                <div class="flex-1">
                                    <MultiSelectList :modelValue="selectShops" :defaultOption="listShops" @update:modelValue="setListShops"></MultiSelectList>
                                </div>
                                <div class="filter-title mx-3">{{$t('message.common.status')}}:</div>
                                <div class="setting-sync-option">
                                    <InputSelectSearch :modelValue="statusSync" :defaultOption="this.$constant.listStatusSync" :noSearch="true"></InputSelectSearch>
                                </div>
                            </div>
                            <div class="d-flex align-items-center filter-search-block">
                                <div class="filter-title mr-3">{{$t('message.InputQuickSearch.button.search')}}:</div>
                                <div class="setting-sync-option">
                                    <InputSelectSearch :modelValue="typeChannelId" :defaultOption="this.$constant.listTypeChannel" :noSearch="true" @update:modelValue="setTypeChannel"></InputSelectSearch>
                                </div>
                                <div class="ml-0 w-100">
                                    <div class="d-flex align-items-center form-control-search">
                                        <span class="form-control-icon"><i class="fas fa-search"></i></span>
                                        <input type="text" name="" class="form-control ng-valid ng-touched ng-dirty" v-model="keyword" @change="searchProducts()" :placeholder="this.typeChannelId == this.$constant.omiId ? $t('message.common.enterCodeOrName') : $t('message.common.enterIDOrSKU')">
                                        <div class="input-group-append pointer" v-if="keyword?.length > 0" @click="removeKeyword()"><i class="fas fa-times text-danger"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div class="mapping-connection-filter">
                        <div class="mapping-filter-tab">
                            <div class="toolbar-item" :class="sttMapping === this.$constant.allMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.allMapping)">{{$t('message.common.all')}}</div>
                            <div class="toolbar-item" :class="sttMapping === this.$constant.falseMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.falseMapping)">{{$t('message.medicineProductMapping.linked')}}</div>
                            <div class="toolbar-item" :class="sttMapping === this.$constant.successMapping ? 'active' : ''" @click="changeSttMapping(this.$constant.successMapping)">{{$t('message.medicineProductMapping.noLink')}}</div>
                        </div>
                        <!-- <div class="mapping-filter-button justify-content-end">
                            <div class="button-function sync-inserted">
                                <div class="import-guide">
                                    <a class="btn btn-primary btn-icon-left">
                                        <i class="fas fa-upload mr-2"></i>
                                        Đăng sản phẩm hàng loạt
                                    </a>
                                </div>
                            </div>
                            <div class="btn-group sync-inserted ml-2">
                                <a id="buttonGroup" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fas fa-plus-circle mr-1"></i>
                                    <span class="btn-group-title mr-1">{{$t('message.button.action')}}</span>
                                    <i class="fas fa-chevron-down"></i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="buttonGroup">
                                    <a class="dropdown-item" href="javascript:void(0)">Sao chép hàng đã chọn về Omipos</a>
                                    <a class="dropdown-item" href="javascript:void(0)">Sao chép tất cả hàng hóa</a>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <div class="mapping-grid-container">
                    <div class="setting-mapping-grid">
                        <div class="table-content">
                            <div class="table-content-head">
                                <table class="table mb-0" >
                                    <thead>
                                        <tr>
                                            <th class="input-checkbox">
                                                <label class="container-checkbox mb-3">
                                                    <input type="checkbox" v-model="checkedAll" @change="onCheckedAll(checkedAll)">
                                                    <span class="checkmark-checkbox"></span>
                                                </label>
                                            </th>
                                            <th class="shop-name text-center">{{$t('message.common.stall')}}</th>
                                            <th class="shop-product text-center">{{$t('message.common.productOnStall')}}</th>
                                            <th class="omi-product text-center">{{$t('message.medicineProductMapping.productOmipos')}}</th>
                                            <th class="operation text-center">{{$t('message.button.action')}}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="table-content-body">
                                <table class="table mb-0" >
                                    <tbody>
                                        <template v-if="this.entries?.length > 0">
                                            <template v-for="(entry, index) in this.entries" :key="entry.id">
                                                <tr :class="[(index % 2 ? 'bg-light' : ''), (!entry.status ? 'cursor-ban' : false)]">
                                                    <td class="input-checkbox">
                                                        <label class="container-checkbox">
                                                            <input v-model="entry.checked" :disabled="!entry.status ? true : false" type="checkbox"  @change.stop="countChecked(entry.id)"
                                                                :id="entry.id" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                            <span class="checkmark-checkbox"></span>
                                                        </label>
                                                    </td>
                                                    <td class="shop-name">
                                                        <div class="d-flex align-items-center">
                                                            <span class="logo-tmdt">
                                                                <template v-for="icon in this.iconChannel" :key="icon.id">
                                                                    <img :src="icon.src" alt="" v-if="icon.name == this.textTypeChannel">
                                                                </template>
                                                            </span>
                                                            <span class="mapping-booth-title" v-text="entry.shopName"></span>
                                                        </div>
                                                    </td>
                                                    <td class="shop-product">
                                                        <div class="text-wrapper">
                                                            <div class="font-weight-bold" v-text="entry.productName"></div>
                                                            <div class="setting-mapping-text">
                                                                <a href="javascript:void(0)" v-if="entry.productId">{{$t('message.common.id')}}: <span v-text="entry.productId"></span></a>
                                                                <span v-if="entry.productSku"> - </span><span class="text-warning" v-if="entry.productSku">{{$t('message.common.sku')}}: <span v-text="entry.productSku  "></span></span>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td class="omi-product">
                                                        <template v-if="entry.omiProductCode">
                                                            <div class="text-wrapper">
                                                                <div class="font-weight-bold" v-text="entry.omiProductName"></div>
                                                                <div class="setting-mapping-text">
                                                                    <a :href="'/products/index?code=' + entry.omiProductCode" target="_blank">{{ entry.omiProductCode }}</a>
                                                                    <span> - {{$t('message.ProductUnitSearch.price')}}: {{ n(entry.price) }}</span>
                                                                    <span> - {{$t('message.ProductUnitSearch.inventory')}}: {{ n(entry.inventories) }}</span>
                                                                    <span> - {{$t('message.ProductUnitSearch.orderFromCustomer')}}: {{ n(entry.quantityOrder) }}</span>
                                                                    <span> - {{$t('message.ProductUnitSearch.orderSupplier')}}: {{ n(entry.quantityOrderSupplier) }}</span>
                                                                </div>
                                                            </div>
                                                        </template>
                                                        <template v-else>
                                                            <ProductUnitsSearch @selectProductUnit="onSelectProductUnit" :readonly="!entry.status ? true : false" :label="entry" :branch="entry.get_shop?.branchId"></ProductUnitsSearch>
                                                        </template>
                                                    </td>
                                                    <td class="operation">
                                                        <div class="d-flex justify-content-center mapping-grid-content tooltip-icon-item ng-star-inserted">
                                                            <div class="btn-icon btn-icon-sm btn-circle ng-star-inserted">
                                                                <i class="fas fa-unlink" v-if="entry.omiProductCode" :title="$t('message.medicineProductMapping.removeLinked')" @click="entry.status ? unLink(entry.id) : false"></i>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-else>
                                            <tr class="empty-wrapper">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <img src="../../../public/img/No-record.png">
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <span class="border-none">{{$t('message.common.notData')}}</span>
                                                    </div>
                                                </div>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row paginate-space">
                            <div class="col-9">
                                <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                            </div>
                            <div class="col-3 d-flex justify-content-end align-items-center">
                                <span class="filter-title mr-2">{{$t('message.common.numberRecord')}}:</span>
                                <InputSelectSearch :modelValue="numberRecord" :defaultOption="this.$constant.numberRecords" :noSearch="true" @update:modelValue="setRecords"></InputSelectSearch>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import {$get, $post, $alert} from "../../lib/utils";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import MultiSelectList from "@/components/forms/MultiSelectList.vue";
    import Pagination from "@/components/Pagination";
    import ProductUnitsSearch from "@/components/ProductUnitsSearch.vue";
    import store from "../../store";
    export default {
        name: "SettingMapping",
        components: {InputSelectSearch, Pagination, ProductUnitsSearch, MultiSelectList},
        mounted() {
            document.title = "Liên kết hàng hóa"
        },
        created() {
            if(this.$route.query.itemSkuLst){
                this.keyword = this.$route.query.itemSkuLst;
                this.typeChannelId = 1;
            }
            if(this.$route.query.shopId){
                this.quickSearchShop = [this.$route.query.shopId];
            }
            if(this.$route.query.channelId){
                this.setSaleChannel(this.$route.query.channelId);
            }else{
                this.getListShopByChannel();
            }
            // this.getListShopByChannel();
            this.getSyncProduct();
            this.$router.replace({
                path: '/sale-online/e-commerce/mapping',
                query: {}
            });
        },
        data() {
            return {
                entries: [],
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 10,
                },
                numberRecord: 10,
                page: 1,
                channel: this.$constant.shopeeId,
                statusSync: 0,
                typeChannelId: this.$constant.omiId,
                sttMapping: this.$constant.allMapping,
                textTypeChannel: this.$constant.textShopee,
                iconChannel:[
                    {id: 0, name: this.$constant.textShopee, src: require('../../../public/img/shopee-icon-active.png') },
                    {id: 1, name: this.$constant.textLazada,  src: require('../../../public/img/lazada-icon-active.png') },
                    {id: 2, name: this.$constant.textTiki, src: require('../../../public/img/tiki-icon.png') },
                    {id: 3, name: this.$constant.textTiktok, src: require('../../../public/img/tiktok-icon-active.png') },
                    {id: 4, name: this.$constant.textSendo, src: require('../../../public/img/lazada-icon-active.png') },
                ],
                keyword: '',
                listShops: [],
                selectShops: [],
                quickSearchShop: [],
                arrayChecked: [],
                checkedAll: false,
            }
        },
        methods: {
            async listShop(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/list',
                    query: {}
                });
            },
            async mappingProducts(){
                await this.$router.replace({
                    path: '/sale-online/e-commerce/mapping',
                    query: {}
                });
            },
            setSaleChannel(channel){
                this.channel = channel;
                switch (+this.channel) {
                    case this.$constant.shopeeId:
                        this.textTypeChannel = this.$constant.textShopee;
                        break;
                    case this.$constant.lazadaId:
                        this.textTypeChannel = this.$constant.textLazada;
                        break;
                    case this.$constant.tikiId:
                        this.textTypeChannel = this.$constant.textTiki;
                        break;
                    case this.$constant.tiktokId:
                        this.textTypeChannel = this.$constant.textTiktok;
                        break;
                    case this.$constant.sendoId:
                        this.textTypeChannel = this.$constant.textSendo;
                        break;
                    default:
                        this.textTypeChannel = this.$constant.textShopee;
                        break;
                }
                this.getListShopByChannel();
                this.getSyncProduct();
            },
            async getSyncProduct(){
                let params = {
                    channelId : this.channel,
                    numberRecord: this.numberRecord,
                    page: this.page,
                    branchId: store.state.CurrentBranch.id,
                    statusLink: this.sttMapping,
                    typeChannel: this.typeChannelId,
                    shopIds: this.selectShops,
                }
                var ItemSkuList = this.keyword.split(",");
                if(ItemSkuList?.length > 1){
                    params = {...params, ItemSkuList: ItemSkuList};
                }else{
                    params = {...params, keyword: this.keyword};
                }
                const res = await $post('/v1/connection/get-sync-product', {...params});
                if(res && res.result){
                    this.paginate.currentPage = res.result.current_page;
                    this.paginate.lastPage = res.result.last_page;
                    this.paginate.totalRecord = res.result.total;
                    this.entries = res.result.data;
                }
                this.isCheckedAll();
            },
            searchProducts(){
                this.page = 1;
                this.getSyncProduct();
            },
            async getListShopByChannel(){
                const res = await $post('/v1/connection/get-list-shops', { type: this.textTypeChannel});
                if(res && res.result){
                    let arr = [];
                    let ids = [];
                    let in_array = 0;
                    res.result.forEach(el => {
                        const obj = {
                            id: el.shopId ?? null,
                            name: el.name ?? null,
                            checked: true,
                        }
                        arr.push(obj);
                        ids.push(obj.id);
                        in_array = el.shopId == this.quickSearchShop ? (in_array + 1) : in_array;
                    });
                    this.listShops = arr;
                    if(this.quickSearchShop != [] && in_array > 0){
                        this.selectShops = this.quickSearchShop;
                    }else{
                        this.selectShops = ids;
                    }
                }
            },
            async onSelectProductUnit(data){
                let productUnitId = data.data.productUnitId;
                let productBranchMetaId = data.data.productBranchMetaId;
                let shopId = data.label.get_shop.shopId;
                let syncProductId = data.label.id;
                if(!syncProductId || !productUnitId){
                    $alert({ code: 422, message: this.$t('message.medicineProductMapping.message_connect_error')});
                }
                const res = await $post('/v1/products/mapping-product', {
                    productUnitId: productUnitId,
                    id: syncProductId,
                });
                $alert(res);
                if(res.code == 200){
                    switch (this.textTypeChannel) {
                        case this.$constant.textShopee:
                            this.updateStockProductShopee(shopId, productBranchMetaId, syncProductId);
                            break;
                        case this.$constant.textLazada:
                            this.updateStockProductLazada(shopId, productBranchMetaId, syncProductId);
                            break;
                        case this.$constant.textTiki:
                            this.updateStockProductTiki(shopId);
                            break;
                        case this.$constant.textTiktok:
                            this.updateStockProductTiktok(shopId);
                            break;
                        case this.$constant.textSendo:
                            // this.optionSearch = this.$constant.optionSearchProductSendo;
                            break;
                    }
                    
                }
                this.getSyncProduct();
            },
            async updateStockProductShopee(shopId, productBranchMetaId, syncProductId){
                const res = await $post('/v1/shopee/update-stock-realTime', {
                    shopId: shopId,
                    productBranchMetaId: productBranchMetaId,
                    syncProductId: syncProductId,
                });
            },
            async updateStockProductLazada(shopId, productBranchMetaId, syncProductId){
                const res = await $post('/v1/lazada/update-stock-realTime', {
                    shopId: shopId,
                    productBranchMetaId: productBranchMetaId,
                    syncProductId: syncProductId,
                });
            },
            async updateStockProductTiktok(shopId){
                await $post('/v1/tiktok/update-stocks', {
                    shopId: shopId,
                });
            },
            async updateStockProductTiki(shopId){
                await $post('/v1/tiki/update-stocks', {
                    shopId: shopId,
                });
            },
            async unLink(id){
                const res = await $post('/v1/products/mapping-product', {
                    productUnitId: null,
                    id: id,
                });
                if(res && res.code == 200){
                    $alert({ code: 200, message: this.$t('message.medicineProductMapping.message_disconnect_success')});
                    this.getSyncProduct();
                }
            },
            pageChange(page){
                this.page = page;
                this.getSyncProduct();
            },
            setRecords(records){
                this.numberRecord = records;
                this.page = 1;
                this.getSyncProduct();
            },
            changeSttMapping(stt){
                this.sttMapping = stt;
                this.page = 1;
                this.getSyncProduct();
            },
            setTypeChannel(type){
                this.typeChannelId = type;
                this.page = 1;
                this.getSyncProduct();
            },
            removeKeyword(){
                this.keyword= '';
                this.page = 1;
                this.getSyncProduct();
            },
            setListShops(lists){
                this.selectShops = lists;
                this.page = 1;
                this.getSyncProduct();
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
.sidebar-item {
    font-size: 1rem;
    padding-bottom: 10px;
    padding-top: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: all .2s ease-in-out;
}
.sidebar-item.active{
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.mainRight{
    padding: 1.2rem 1rem;
}
.setting-mapping-wrapper {
    flex-direction: column;
    flex: 1;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.mapping-header {
    background-color: #f5f6f8;
    padding: 1.5rem;
    border-radius: 10px;
}
.filter-block:first-child {
    margin-top: 0;
}
.filter-block {
    margin-top: 1.6rem;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.filter-block .filter-title {
    margin-right: 1rem;
}
.filter-title{
    min-width: 60px !important;
}
.filter-block .filter-list-choose {
    flex: 1;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.btn:not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding: 0 calc(1.1rem + 1px);
    height: 32px;
}
.filter-block .filter-list-choose .btn.filter-active{
    border-color: rgb(47, 161, 161);
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg){
    box-shadow: none!important;
}
.filter-block .filter-list-choose .btn:first-child{
    margin-left: 0;
}
.btn.btn-outline-default{
    border: 1px solid #bebfc2;
}
button:not(:disabled) {
    cursor: pointer;
}
.btn-radius-cicrle {
    border-radius: 999px;
}
.btn{
    outline: none!important;
    margin: 0;
    align-items: center;
    border: none;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.setting-sync-option{
    min-width: 150px !important;
}
.filter-search-block {
    border-top: 1px solid #d7d9de;
    margin-top: 1rem;
    padding-top: 1rem;
}
.form-control-search {
    position: relative;
}
.form-control-search .form-control-icon {
    cursor: pointer;
    color: rgba(31,32,33,.52);
    transform: translateY(-50%);
    position: absolute;
    left: 8px;
    top: 50%;
}
.mapping-header .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.form-control-search input {
    border-color: #d7d9de;
    padding: 0.4rem 2rem;
}
.form-control {
    border-color: transparent;
    padding: 0.43rem 1.2rem;
    height: 32px;
    border-width: 1px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}
.form-control-search .input-group-append {
    color: rgba(31,32,33,.86);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 999px;
    position: absolute;
    top: 55%;
    right: 8px;
}
.mapping-connection-filter .mapping-filter-tab {
    flex: 1;
}
.mapping-connection-filter, .mapping-connection-filter .mapping-filter-tab, .mapping-filter-button {
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.toolbar-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    position: relative;
    border-radius: 25px;
    transition: all .2s ease-in-out;
}
.mapping-connection-filter .toolbar-item {
    font-weight: 600;
}
.toolbar-item.active, .toolbar-item:active, .toolbar-item:hover {
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.mapping-grid-container {
    position: relative;
}

.setting-mapping-grid {
    flex: 1;
    margin-top: 1rem;
}
.table-content-head{
    background-color: #f5f6f8;
    border: 1px solid #d7d9de;
    border-bottom: none !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}
.table-content-body{
    background-color: #fff;
    border: 1px solid #d7d9de;
    border-top: none !important;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 400px;
    overflow: auto;
}
.table thead tr th, tbody tr td{
    border-top: none !important;
    border-bottom: none !important;
}
.table tbody tr td{
    border-top: 1px solid #d7d9de !important;
    border-right: 1px dashed #d7d9de;
}
.paginate-space {
    border: none;
    padding: 10px;
}
.input-checkbox{
    width: 3% !important;
    border-right: none !important;
}
.shop-name{
    width: 17% !important;
}
.shop-product, .omi-product{
    width: 35% !important;
}
.operation{
    width: 10% !important;
}
.logo-tmdt img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.dropdown-toggle::after {
    display: none;
}
.empty-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-60%);
}
.btn-icon:hover{
    color: rgb(47, 161, 161);
}
</style>