import { deepFreeze } from "./lib/deepfreeze";

const env = process.env;

const originConfig = {
    App: {
        debug: env.VUE_APP_DEBUG === 'true',
        baseUri: process.env.VUE_APP_API_URL,
        imageBaseUrl: env.VUE_APP_IMAGE_URL,
        v1ApiUrl: env.VUE_APP_V1_API_URL || 'http://localhost:7012',
        avatarUrl: env.VUE_APP_AVATAR_URL,
        redirectUrl: env.VUE_APP_REDIRECT_URL || 'https://omipos.ominext.dev/settings/store'

    },
    Service: {
        GoogleClientID: env.VUE_APP_GOOGLE_CLIENT_ID,
    },
    ExcelSamples: {
        PurchaseOrderImport: '/assets/samples/PurchaseOrder.xlsx',
        ProductsTemplate: '/assets/samples/template.xlsx'
    },
    ApiShopee: {
        v2ApiShopeeUrl: env.VUE_APP_V2_SHOPEE_API_URL || 'https://partner.test-stable.shopeemobile.com',
        v2PartnerId: env.VUE_APP_V2_PARTNER_ID || '1015037',
        v2PartnerKey: env.VUE_APP_V2_PARTNER_KEY || '4b574152684241555348544d6e4365547855545651426f7776535148794b4d5a',
        v2RedirectUrl: env.VUE_APP_REDIRECT_URL || 'http://localhost:8209/settings/store',
        v2Path: env.VUE_APP_PATH_URL || '/api/v2/shop/auth_partner'
    },
    ApiLazada: {
        ApiAuthLazadaUrl: env.VUE_APP_AUTH_LAZADA_URL || 'https://auth.lazada.com',
        ClientId: env.VUE_APP_LAZADA_APP_KEY || '114060',
        AppSecret: env.VUE_APP_LAZADA_APP_SECRET || 'HJCz5lU7XLozwWWp2l2N3taOlbu7Vqhi',
        redirectUrl: env.VUE_APP_REDIRECT_URL || 'https://omipos.ominext.dev/settings/store',
        ApiGetAccessToken: env.VUE_APP_LAZADA_API_NAME_GET_TOKEN || '/auth/token/create'
    },
    Report: {
        ConsignmentExpired: env.VUE_APP_V1_API_URL + '/v1/report/report-consignment-expired?',
        ActiveCustomer: env.VUE_APP_V1_API_URL + '/v1/report/report-active-customer?',
        InvoiceAmount: env.VUE_APP_V1_API_URL + '/v1/report/report-invoice-amount?',
        InvoiceSaleChannel: env.VUE_APP_V1_API_URL + '/v1/report/report-invoice-sl?',
        Order: env.VUE_APP_V1_API_URL + '/v1/report/order?',
        Statistic: env.VUE_APP_V1_API_URL + '/v1/report/statistic?'
    }
};

if (originConfig.App.debug) {
    // console.log('APP running in DEBUG mode');
}

deepFreeze(originConfig);

export default originConfig;