<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">                    
                    <h3 class="modal-title py-2">{{ this.title? this.title : '' }}</h3>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                   {{ this.content? this.content : '' }}
                   <div class="col-12 d-flex justify-content-end mt-2">
                        <button v-if="this.type == 0" class="btn btn-danger mr-2" @click="stopCustomers()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.customerIndex.button.agree') }}
                        </button>
                        <button v-if="this.type == 1" class="btn btn-danger mr-2" @click="startCustomers()">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.customerIndex.button.agree') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.customerIndex.button.skip') }}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    data () {
        return {
            id: '',
            name: ''
        };
    },
    name: "ModalStopUserCustomer",
    props: ['title', 'content', 'type'],    
    methods: {
        show (id, name) {            
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async stopCustomers() {          
          const res = await $post(`/v1/customers/stop/${this.id}`);
          if(res.code === 200){            
            toastr.success(res.message);
            this.$emit('updated' , this.id)
            this.hide();
          }
          else {
            toastr.error(res.message);
          }        
          
         
        },
        async startCustomers() {  
          const res = await $post(`/v1/customers/start/${this.id}`);
          if(res.code === 200){            
            toastr.success(res.message);
            this.$emit('updated' , this.id)
            this.hide();
          }
          else {
            toastr.error(res.message);
          } 
        },
        hide(){
            window.jQuery(this.$refs.modal).modal('hide');
        }
    }
}
</script>
<style>
</style>