<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 1250px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card mt-1">
                                <div class="card-header mx-0 px-0 pb-0 border-none bg-transparent">
                                    <div class="row mx-2">
                                        <div class="col-11">
                                            <label class="typeTitle">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.search') }}</label>
                                        </div>
                                        <div class="col-1 p-0 d-flex align-items-center justify-content-center">
                                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('searchBody', 'searchDown', 'searchUp')">
                                                <i class="fa fa-chevron-down dp-hide" id="searchDown"></i>
                                                <i class="fa fa-chevron-up" id="searchUp"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0 border-none bg-transparent" id="searchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputText v-model.trim="filter.control_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.placeholder.control')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model.trim="filter.invoice_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.placeholder.invoice')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model.trim="filter.delivery_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.placeholder.delivery')"
                                                @update:modelValue="search"
                                            ></InputText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2">
                                <div class="card-header mx-0 px-0 pb-0 border-none bg-transparent">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.branch') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    
                                </div>
                            </div>
                            <div class="card mt-2">
                                <div class="card-header mx-0 px-0 pb-0 border-none bg-transparent">
                                    <div class="row mx-2">
                                        <div class="col-11">
                                            <label class="typeTitle">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.status_delivery') }}</label>
                                        </div>
                                        <div class="col-1 p-0 d-flex align-items-center justify-content-center">
                                            <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                                <i class="fa fa-chevron-down" id="statusDown"></i>
                                                <i class="fa fa-chevron-up dp-hide" id="statusUp"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0 dp-hide" id="statusBody">
                                    <template v-for="status in this.defaultOptions" :key="status.id">
                                        <div class="row">
                                            <div class="col-12 d-flex align-items-center position-relative">
                                                <label class="container-checkbox">{{status.name}}
                                                    <input type="checkbox" class="chkType" @click="doFilter('status', status.id)" :checked="isExistInArrayChecked(status, arrayChecked)">
                                                    <span class="checkmark-checkbox"></span>
                                                </label>
                                                <i class="fa fa-plus icon-plus" v-if="status.children" @click="toggleChildren(status.id)" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? 'd-none' : ''"></i>
                                                <i class="fa fa-minus icon-plus" v-if="status.children" @click="toggleChildren(status.id)" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? '' : 'd-none'"></i>
                                            </div>
                                        </div>
                                        <template v-if="status.children">
                                            <div class="row pl-4" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? '' : 'd-none'" v-for="child in status.children" :key="child.id">
                                                <div class="col-12 d-flex align-items-center">
                                                    <label class="container-checkbox">{{child.name}}
                                                        <input type="checkbox" class="chkType" @click="doFilter('status', child.id)" :checked="isExistInArrayChecked(child, arrayChecked)">
                                                        <span class="checkmark-checkbox"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </template>
                                    </template>
                                </div>
                            </div>
                            <div class="card mt-2">
                                <div class="card-header mx-0 px-0 pb-0 border-none bg-transparent">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.time') }}</label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.start_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model.trim="filter.start_date"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.end_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model.trim="filter.end_date"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <table class="table table-index table-striped" :class="data && data.length ? 'table-overflow-auto' : 'table-overflow-hidden'">
                                <thead>
                                <tr>
                                    <th class="checkbox-table"></th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.invoice_code') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.delivery_code') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.control_code') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.customer') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.branch') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.transporter') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.invoice_value') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.cod') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.fee_payment') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.cod_fee_payment') }}</th>
                                    <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.status_delivery') }}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-for="invoice in data" :key="invoice.id">
                                        <tr>
                                            <td class="checkbox-table"><i class="fa fa-trash-alt" @click="remove(invoice.id)"></i></td>
                                            <td class="column-value text-primary" v-text="invoice.code" @click="linkToInvoice(invoice.code)"></td>
                                            <td class="column-value" v-text="invoice.delivery ? invoice.delivery.code : null"></td>
                                            <td class="column-value" v-text="invoice.controlCode ? invoice.controlCode : null"></td>
                                            <td class="column-value" v-text="invoice.customer ? invoice.customer.name : null"></td>
                                            <td class="column-value" v-text="invoice.branch ? invoice.branch.name : null"></td>
                                            <td class="column-value" v-text="invoice.delivery && invoice.delivery.transporter ? invoice.delivery.transporter.name : null"></td>
                                            <td class="column-value" v-text="n(invoice.totalOrigin)"></td>
                                            <td class="column-value" v-text="invoice.delivery && invoice.delivery.usingPriceCod ? n(invoice.delivery.priceCodPayment) : 0"></td>
                                            <td class="column-value" v-text="invoice.delivery && (invoice.delivery.payFeeShip == this.$constant.paid_by_sender  || invoice.delivery.payFeeShip == this.$constant.paid_by_app) ? n(invoice.delivery.price) : 0"></td>
                                            <td class="column-value" v-text="invoice.delivery && invoice.delivery.usingPriceCod 
                                                                            ? n(invoice.delivery.priceCodPayment - (invoice.delivery && (invoice.delivery.payFeeShip == this.$constant.paid_by_sender  || invoice.delivery.payFeeShip == this.$constant.paid_by_app) ? invoice.delivery.price : 0)) 
                                                                            : (invoice.delivery && (invoice.delivery.payFeeShip == this.$constant.paid_by_sender  || invoice.delivery.payFeeShip == this.$constant.paid_by_app) ? n(invoice.delivery.price) : 0)"></td>
                                            <td class="column-value" v-text="invoice.delivery ? invoice.delivery.statusName : null"></td>
                                        </tr>
                                    </template>
                                    <template v-if="data.length == 0">
                                        <tr>
                                            <td :colspan="7" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <p>{{ $t('message.common.notFound') }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>

                            <Pagination :value="paginate" :record="this.numberRecord" @change="load"/>
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-save mr-2" @click="payment()">
                            <i class="fa fa-calculator"></i> 
                            {{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.button.payment') }}
                        </button>
                        <button type="button" class="btn btn-secondary" @click="hidden()">
                            <i class="fa fa-ban"></i> 
                            {{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputText from "@/components/forms/InputText";
    import {$post, debounce, $alert, clone} from "@/lib/utils";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import Pagination from "@/components/Pagination";
    import emitter from "@/lib/emitter";

    let app;
    export default {
        name: "ModalSelectInvoicePayment",
        emits: ['select'],
        props: ['defaultOptions'],
        components: {Pagination, InputDatePicker, InputText, InputNumberFormat},
        data() {
            return {
                data: [],
                filter: {
                    delivery_code: '',
                    invoice_code: '',
                    control_code: '',
                    start_date: '',
                    end_date: '',
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                },
                numberRecord: 10,
                arrayChecked: [],
                arrayChildChecked: [],
                id: null,
                ids: null,
                idsRemove: [],
            }
        },
        mounted() {
            app = this;
        },
        methods: {
            async show(id) {
                if(id){
                    this.id = id;
                    await this.clearValue();
                    await this.load();
                    window.jQuery(this.$refs.modal).modal({
                        backdrop: 'static', keyboard: false
                    });
                }
            },
            async load(page = 1) {
                emitter.emit("onLoading");
                const res = await $post('/v1/transporters/get-list-invoices', {...this.filter, record: this.numberRecord, page: page, statusDeliveries: this.arrayChecked, id: this.id, idsRemove: this.idsRemove});
                if (!res.result.status) {
                    $alert(res);
                    return;
                }
                this.paginate.currentPage = res.result.data.current_page ?? null;
                this.paginate.lastPage = res.result.data.last_page ?? null;
                this.paginate.totalRecord = res.result.data.total ?? null;
                this.data = res.result.data.data;
                this.ids = res.result.ids ?? [];
                emitter.emit("offLoading");
            },
            onSelectInvoice(invoice) {
                this.$emit('select', invoice.code);
                this.hidden();
            },
            search() {
                app.load();
            },
            clearValue(){
                this.idsRemove = [];
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            toggleChildren(value){
                if(this.arrayChildChecked.includes(value)){
                    this.arrayChildChecked = this.arrayChildChecked.filter(item => item !== value)
                }else{
                    this.arrayChildChecked.push(value);
                }
            },
            doFilter(field, value) {
                if(this.arrayChecked.includes(value)){
                    this.arrayChecked = this.arrayChecked.filter(item => item !== value);
                    this.defaultOptions.forEach(entry => {
                        if(entry.id == value && entry.children){
                            entry.children.forEach(children => {
                                const found = this.arrayChecked.find(id => id === children.id);
                                if(found){
                                    this.arrayChecked = this.arrayChecked.filter(item => item !== children.id);
                                }
                            });
                        }
                    })
                }else{
                    this.arrayChecked.push(value);
                    this.defaultOptions.forEach(entry => {
                        if(entry.id == value && entry.children){
                            entry.children.forEach(children => {
                                const found = this.arrayChecked.find(id => id === children.id);
                                if(!found){
                                    this.arrayChecked.push(children.id);
                                }
                            });
                        }
                    })
                }
                this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                if(dropdownContent){
                    dropdownContent.classList.toggle("dp-hide");
                }
                var down = document.getElementById(iDown);
                if(down){
                    down.classList.toggle("dp-hide");
                }
                var up = document.getElementById(iUp);
                if(up){
                    up.classList.toggle("dp-hide");
                }
            },
            linkToInvoice(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'InvoiceIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            hidden(){
                window.jQuery(this.$refs.modal).modal('hide');
            },
            payment(){
                if(this.ids && this.ids.length){
                    this.$emit('payment', this.ids);
                }
                this.hidden();
            },
            remove(id){
                this.ids = this.ids.filter(item => item !== id);
                this.idsRemove.push(id);
                this.load();
            }
        },
        beforeUnmount() {
            this.hidden();
        },
    }
</script>

<style scoped>
.title-search {
    background-color: #b1ddf0;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.table th, .table td {
    height: 40px !important;
}
.table tbody td{
    background-color: #F2F2F2;
    border-bottom: 1px solid #FFFFFF;
}
.form-group .form-control{
    padding-left: 0 !important;
}
.icon-plus {
    position: absolute;
    right: 10%;
    top: 0px;
    color: gray;
}
.table-overflow-hidden{
    overflow: hidden;
    display: block;
}
.table-overflow-auto{
    overflow: auto;
    display: block;
}
.column-value{
    min-width: 160px !important;
}
table tr .checkbox-table{
    max-width: 30px !important;
    min-width: none;
}
</style>
