<template>
    <div class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" ref="modal" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header border-none pb-0">
                    <h6 class="modal-title">{{$t('message.common.status')}}</h6>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0">
                    <div class="row mt-3 mx-0">
                        <div class="col-12 d-flex align-items-center position-relative p-0 border-bottom-1">
                            <i class="fa fa-search ml-2 text-secondary"></i>
                            <input type="text" v-model.trim="search" class="form-control border-none pl-2" autocomplete="off" :placeholder="$t('message.common.searchDeliveryStatus')" @input="onInputFilter">
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 d-flex align-items-center position-relative">
                            <label class="container-checkbox">{{$t('message.common.all')}}
                                <input type="checkbox" v-model="checkedAll" @click="onCheckedAll(checkedAll)">
                                <span class="checkmark-checkbox"></span>
                            </label>
                        </div>
                    </div>
                    <template v-if="this.arrayEntries.filter?.length > 0">
                        <template  v-for="entry in this.arrayEntries.filter" :key="entry.id">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center position-relative">
                                    <label class="container-checkbox">{{entry.name}}
                                        <input type="checkbox" class="chkType" @click="doFilter(entry.id, entry)" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                        <span class="checkmark-checkbox"></span>
                                    </label>
                                    <i class="fa fa-plus icon-plus" v-if="entry.children" @click="toggleChildren(entry.id)" :class="isExistInArrayChecked(entry, this.arrayChildChecked) ? 'd-none' : ''"></i>
                                    <i class="fa fa-minus icon-plus" v-if="entry.children" @click="toggleChildren(entry.id)" :class="isExistInArrayChecked(entry, this.arrayChildChecked) ? '' : 'd-none'"></i>
                                </div>
                            </div>
                            <template v-if="entry.children && entry.children.length !== 0">
                                <div class="row pl-4" :class="isExistInArrayChecked(entry, this.arrayChildChecked) ? '' : 'd-none'" v-for="child in entry.children" :key="child.id">
                                    <div class="col-12 d-flex align-items-center">
                                        <label class="container-checkbox">{{child.name}}
                                            <input type="checkbox" class="chkType" @click="doFilter(child.id)" :checked="isExistInArrayChecked(child, this.arrayChecked)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                    </div>
                                </div>
                            </template>
                        </template>
                    </template>
                </div>
                <div class="modal-footer  border-none d-block">
                    <div class="row m-0">
                        <div class="col-5 d-flex justify-content-start align-items-center">
                            <a href="javascript:void(0);" class="text-primary text-decoration-none" @click="removeChecked()">{{$t('message.common.removeAllSelected')}}</a>
                        </div>
                        <div class="col-7 d-flex justify-content-end align-items-center">
                            <button type="button" class="btn btn-save mr-2" @click="submit">
                                <i class="fa fa-save mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.finished')}}
                                </span>
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mx-2"></i>
                                <span class="mr-2">
                                    {{$t('message.button.skip')}}
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {$post, $alert, removeVietnameseTones} from "@/lib/utils";
    export default {
        name: "SelectStatusDeliveries",
        components: {},
        props: ['placeholder','disabled', 'label', 'defaultOptions'],
        data() {
            return {
                search: null,
                arrayEntries: {
                    filter: this.defaultOptions ?? [],
                    all: this.defaultOptions ?? [],
                },
                checkedAll: false,
                arrayChecked: [],
                arrayChildChecked: [],
            }
        },
        mounted() {
            // this.getCategories();
        },
        methods: {
            toggleChildren(value){
                if(this.arrayChildChecked.includes(value)){
                    this.arrayChildChecked = this.arrayChildChecked.filter(item => item !== value)
                }else{
                    this.arrayChildChecked.push(value);
                }
            },
            async show(data = null, checkedAll, defaultOption) {
                // await this.getCategories();
                if(data){
                    this.arrayChecked = data;
                    this.checkedAll = checkedAll;
                    if(checkedAll){
                        this.onCheckedAll(true);
                    }
                    if(defaultOption){
                        this.arrayEntries.all = this.arrayEntries.filter = defaultOption;
                    }
                }
                this.isCheckedAll();
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                window.jQuery(this.$refs.modal).modal('hide');
            },
            submit(){
                let arraySelected = [];
                this.arrayEntries.all.forEach(entry => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        arraySelected.push(entry);
                    }
                    if(entry.children){
                        entry.children.forEach(child => {
                            const foundChild = this.arrayChecked.find(id => id === child.id);
                            if(foundChild){
                                arraySelected.push(entry);
                            }
                        })
                    }
                })
                this.$emit('update:modelValue', { data: arraySelected, selectAll: this.checkedAll, label: this.label, arrayChecked: this.arrayChecked});
                this.hide();
            },
            async getCategories(){
                const res = await $post('/v1/categories/get-all-data');
                if (res.status.code != 200) {
                    $alert({code: 500, message: this.$t('message.common.errorServer')});
                    return;
                } 
                if(res.result?.data){
                    this.arrayEntries.all = this.arrayEntries.filter = res.result.data;       
                }
            },
            async onCheckedAll(value) {
                this.arrayChecked = [];
                if(!value){
                    this.arrayEntries.all.forEach(entry => {
                        this.arrayChecked.push(entry.id);
                        if(entry.children && entry.children.length){
                            entry.children.forEach(child => {
                                this.arrayChecked.push(child.id);
                            })
                        }
                    })
                }
                await this.isCheckedAll();
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id, entry = null){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id);
                    if(entry && entry.children && entry.children.length){
                        entry.children.forEach(child => {
                            if(arr.includes(child.id)){
                                arr = arr.filter(item => item !== child.id);
                            }
                        })
                    }
                }else{
                    arr.push(id);
                    if(entry && entry.children && entry.children.length){
                        entry.children.forEach(child => {
                            if(!arr.includes(child.id)){
                                arr.push(child.id);
                            }
                        })
                    }
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.arrayEntries.all.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.arrayEntries.all.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            onInputFilter(){
                if(this.search?.length == 0 || !this.search){
                    this.arrayEntries.filter = this.arrayEntries.all;
                    return;
                }
                let arr = [];
                for (let index = 0; index < this.arrayEntries.all.length; index++) {
                    if (this.arrayEntries.all[index] != null && removeVietnameseTones(this.arrayEntries.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.search.toLowerCase())) > -1){
                        arr.push(this.arrayEntries.all[index]);
                    }
                }
                this.arrayEntries.filter = arr;
            },
            async updateArrayChecked(array, isSelectAll = false){
                await this.getCategories();
                if(isSelectAll){
                    this.onCheckedAll();
                }else{
                    this.arrayChecked = array;
                }
                this.submit();
            },
            async doFilter(value, entry = null) {
                let query = this.arrayChecked;
                if(query.includes(value)){
                    query = query.filter(item => item !== value);
                    if(entry && entry.children){
                        entry.children.forEach(child => {
                            query = query.filter(item => item !== child.id);
                        })
                    }
                }else{
                    query.push(value);
                    if(entry && entry.children){
                        entry.children.forEach(child => {
                            const foundChild = this.arrayChecked.find(item => item === child.id);
                            if(!foundChild){
                                query.push(child.id);
                            }
                        })
                    }
                }
                this.arrayChecked = [...query];
                await this.isCheckedAll();
            },
        },
        beforeUnmount() {
            this.hide();
        },
    }
</script>
<style scoped>
.fa-pencil-alt{
    position: absolute;
    left: 10px;
    bottom: 10px;
}
.list_categories{
    max-height: 300px !important;
    overflow-y: auto;
}
.icon-plus {
    position: absolute;
    right: 10%;
    top: 0px;
    color: gray;
}
</style>
