<template>
    <div class="container mt-0">
        <div class="toolbar d-block">
            <div class="container-xxl d-flex flex-stack flex-wrap justify-content-center mt-2">
                <div class="d-flex align-items-center toolbar-container">
                    <div class="d-flex align-items-center toolbar-item font-bold" :class="syncFailObject === this.$constant.syncFailOrders ? 'active' : ''" @click="changeSyncFailObject(this.$constant.syncFailOrders)">{{$t('message.OrderIndex.button.order')}}</div>
                    <!-- <div class="d-flex align-items-center toolbar-item font-bold">Hóa đơn</div> -->
                    <div class="d-flex align-items-center toolbar-item font-bold" :class="syncFailObject === this.$constant.syncFailProducts ? 'active' : ''" @click="changeSyncFailObject(this.$constant.syncFailProducts)">{{$t('message.headers.products')}}</div>
                </div>
            </div>
        </div>
        <div class="sync-inserted mt-3">
            <div class="setting-mapping-wrapper selectProduct sync-inserted">
                <div class="mapping-header">
                    <div class="d-flex align-items-center">
                        <div class="filter-title">{{$t('message.common.ecommerceLevel')}}:</div>
                        <div class="setting-sync-option">
                            <InputSelectSearch :modelValue="channel" :defaultOption="this.$constant.listSaleChannel" :noSearch="true" @update:modelValue="setSaleChannel"></InputSelectSearch>
                        </div>
                        <div class="filter-title mx-3">{{$t('message.common.stall')}}:</div>
                        <div class="flex-1">
                            <MultiSelectList :modelValue="selectShops" :defaultOption="listShops" @update:modelValue="setListShops"></MultiSelectList>
                        </div>
                    </div>
                    <div class="d-flex align-items-center filter-search-block">
                        <div class="filter-title mr-3">{{$t('message.InputQuickSearch.button.search')}}:</div>
                        <div class="setting-sync-option" v-show="this.syncFailObject != this.$constant.syncFailProducts">
                            <InputSelectSearch :modelValue="searchObj" :defaultOption="this.optionSearch" :noSearch="true" @update:modelValue="setSearchObj"></InputSelectSearch>
                        </div>
                        <div class="ml-0 w-100">
                            <div class="d-flex align-items-center form-control-search">
                                <span class="form-control-icon"><i class="fas fa-search"></i></span>
                                <input type="text" name="" class="form-control ng-valid ng-touched ng-dirty" v-model="keyword" @input="getSyncFail()" :placeholder="placeholder">
                                <div class="input-group-append pointer" v-if="keyword?.length > 0" @click="removeKeyword()"><i class="fas fa-times text-danger"></i></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-3">
            <div class="mapping-connection-filter">
                <div class="mapping-filter-tab pl-4">
                    <h5>{{ n(this.paginate.totalRecord) }} <span v-text="this.syncFailObject === this.$constant.syncFailOrders ? $t('message.OrderIndex.button.order') : (this.syncFailObject === this.$constant.syncFailProducts ? $t('message.headers.products') : $t('message.headers.invoices'))"></span> {{ $t('message.common.error') }}</h5>
                </div>
                <div class="mapping-filter-button justify-content-end">
                    <div class="button-function sync-inserted">
                        <div class="import-guide">
                            <a class="btn btn-primary btn-icon-left" @click="syncAll()">
                                <i class="fas fa-sync-alt mr-2"></i>
                                {{$t('message.common.syncAll')}}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="mapping-grid-container">
            <div class="setting-mapping-grid">
                <div class="table-content" v-show="this.syncFailObject === this.$constant.syncFailOrders">
                    <div class="content-head">
                        <table class="table mb-0" >
                            <thead>
                                <tr>
                                    <th class="field-product">{{$t('message.common.products')}}</th>
                                    <th class="field-quantity"></th>
                                    <th class="field-sync-error">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                                    <th class="operation">{{$t('message.button.action')}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="content-body mt-4">
                        <template v-if="this.entries?.length > 0">
                            <template v-for="(entry) in this.entries" :key="entry.id">
                                <template v-if="entry.syncFailProduct?.length > 0">
                                    <div class="table-content-head">
                                        <table class="table mb-0" >
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="">{{ entry.shopName }}</th>
                                                    <th colspan="2" class="">
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <span class="logo-tmdt">
                                                                <template v-for="icon in this.iconChannel" :key="icon.id">
                                                                    <img :src="icon.src" alt="" v-if="icon.id == entry.channelId">
                                                                </template>
                                                            </span>
                                                            <span>{{$t('message.common.orderCode')}} {{ entry.orderCode }}</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <table class="table mb-0 mb-4">
                                        <tbody>
                                            <tr>
                                                <div class="d-flex flex-column order-item-detail">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="cell cell-auto">
                                                            <div class="product-error">
                                                                <i class="fas fa-exclamation-triangle"></i>
                                                                <div class="product-name ml-3">
                                                                    <span v-for="detail in entry.syncFailProduct" :key="detail.id">
                                                                        {{ detail.productName + ' (' + detail.productId + ') ' + detail.noteError + '; '}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="cell cell-sync-date d-flex align-items-center">
                                                            {{ dt(entry.created_at) }}
                                                        </div>
                                                        <div class="cell cell-sync-actions text-center">
                                                            <div class="d-flex align-items-center justify-content-end">
                                                                <a id="buttonGroup" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <span class="btn-group-title mr-1">{{$t('message.button.action')}}</span>
                                                                    <i class="fas fa-chevron-down"></i>
                                                                </a>
                                                                <div class="dropdown-menu" aria-labelledby="buttonGroup">
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="mappingProducts(entry)">{{$t('message.common.linkToProduct')}}</a>
                                                                    <!-- <a class="dropdown-item" href="javascript:void(0)">Sao chép & liên kết hàng hóa</a> -->
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="removeSyncFail(entry)">{{$t('message.common.removeSyncError')}}</a>
                                                                </div>
                                                                <span class="btn-icon btn-circle mr-2" @click="reSyncFailOrder(entry)"><i class="fas fa-sync-alt ml-2" :title="$t('message.common.sync')"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap product-sync-item mt-3" v-for="detail in entry.syncFailProduct" :key="detail.id">
                                                            <div class="cell cell-auto">
                                                                <div class="d-flex align-items-start order-item">
                                                                    <div class="product-sync-image position-relative">
                                                                        <div class="images-default d-flex align-items-center justify-content-center">
                                                                            <i class="far fa-image text-gray"></i>
                                                                        </div>
                                                                        <div class="product-sync-error-icon">
                                                                            <i class="fas fa-exclamation-circle product-error"></i>
                                                                        </div>
                                                                    </div>
                                                                    <div class="order-item-right pl-3">
                                                                        <div class="d-flex">
                                                                            <b> {{ detail.productName }} </b>
                                                                        </div>
                                                                        <div class="item-description text-primary">
                                                                            {{ $t('message.common.id') }} {{ detail.productId }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cell cell-order-total text-center">
                                                                {{ 'x' + detail.quantity }}
                                                            </div>
                                                            <div class="cell cell-sync-multi-lodate"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                                <template v-else>
                                    <div class="table-content-head" v-show="entry.details?.length > 0">
                                        <table class="table mb-0" >
                                            <thead>
                                                <tr>
                                                    <th colspan="2" class="">{{ entry.shopName }}</th>
                                                    <th colspan="2" class="">
                                                        <div class="d-flex align-items-center justify-content-end">
                                                            <span class="logo-tmdt">
                                                                <template v-for="icon in this.iconChannel" :key="icon.id">
                                                                    <img :src="icon.src" alt="" v-if="icon.id == entry.channelId">
                                                                </template>
                                                            </span>
                                                            <span>{{$t('message.common.orderCode')}} {{ entry.orderCode }}</span>
                                                        </div>
                                                    </th>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                    <table class="table mb-0 mb-4" v-show="entry.details?.length > 0">
                                        <tbody>
                                            <tr>
                                                <div class="d-flex flex-column order-item-detail">
                                                    <div class="d-flex flex-wrap">
                                                        <div class="cell cell-auto">
                                                            <!-- <div class="product-error">
                                                                <i class="fas fa-exclamation-triangle"></i>
                                                                <div class="product-name ml-3">
                                                                    <span v-for="detail in entry.details" :key="detail.id">
                                                                        {{ detail.productName + ' (' + detail.productId + ') ' + detail.noteError + '; '}}
                                                                    </span>
                                                                </div>
                                                            </div> -->
                                                        </div>
                                                        <div class="cell cell-sync-date d-flex align-items-center">
                                                            {{ dt(entry.created_at) }}
                                                        </div>
                                                        <div class="cell cell-sync-actions text-center">
                                                            <div class="d-flex align-items-center justify-content-end">
                                                                <a id="buttonGroup" type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    <span class="btn-group-title mr-1">{{$t('message.button.action')}}</span>
                                                                    <i class="fas fa-chevron-down"></i>
                                                                </a>
                                                                <div class="dropdown-menu" aria-labelledby="buttonGroup">
                                                                    <!-- <a class="dropdown-item" href="javascript:void(0)" @click="mappingProducts(entry)">{{$t('message.common.linkToProduct')}}</a> -->
                                                                    <!-- <a class="dropdown-item" href="javascript:void(0)">Sao chép & liên kết hàng hóa</a> -->
                                                                    <a class="dropdown-item" href="javascript:void(0)" @click="removeSyncFail(entry)">{{$t('message.common.removeSyncError')}}</a>
                                                                </div>
                                                                <span class="btn-icon btn-circle mr-2" @click="reSyncFailOrder(entry)"><i class="fas fa-sync-alt ml-2" :title="$t('message.common.sync')"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="d-flex flex-column">
                                                        <div class="d-flex flex-wrap product-sync-item mt-3" v-for="detail in entry.details" :key="detail.id">
                                                            <div class="cell cell-auto">
                                                                <div class="d-flex align-items-start order-item">
                                                                    <div class="product-sync-image position-relative">
                                                                        <div class="images-default d-flex align-items-center justify-content-center">
                                                                            <i class="far fa-image text-gray"></i>
                                                                        </div>
                                                                        <!-- <div class="product-sync-error-icon">
                                                                            <i class="fas fa-exclamation-circle product-error"></i>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="order-item-right pl-3">
                                                                        <div class="d-flex">
                                                                            <b> {{ detail.productName }} </b>
                                                                        </div>
                                                                        <div class="item-description text-primary">
                                                                            {{ $t('message.common.id') }} {{ detail.productId }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="cell cell-order-total text-center">
                                                                {{ 'x' + detail.quantity }}
                                                            </div>
                                                            <div class="cell cell-sync-multi-lodate"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </tr>
                                        </tbody>
                                    </table>
                                </template>
                            </template>
                        </template>

                        <template v-else>
                            <div class="row mx-0">
                                <div class="col-12 text-center text-secondary mt-5">
                                    <img src="../../../public/img/No-record.png">
                                </div>
                                <div class="col-12 text-center my-10 text-secondary mb-5">
                                    <span class="border-none">{{$t('message.common.notData')}}</span>
                                </div>
                            </div>
                        </template>
                    </div>

                </div>


                <div class="table-content" v-show="this.syncFailObject === this.$constant.syncFailProducts">
                    <div class="content-head">
                        <table class="table mb-0" >
                            <thead>
                                <tr>
                                    <th class="shop-name">{{$t('message.common.stall')}}</th>
                                    <th class="shop-product">{{$t('message.common.productSync')}}</th>
                                    <th class="omi-product">{{$t('message.common.syncContent')}}</th>
                                    <th class="operation  text-center">{{$t('message.button.action')}}</th>
                                </tr>
                            </thead>
                        </table>
                    </div>
                    <div class="table-content-body mt-4">
                        <table class="table mb-0" >
                            <tbody>
                                <template v-if="this.entries?.length > 0">
                                    <template v-for="(entry, index) in this.entries" :key="entry.id">
                                        <tr :class="index % 2 ? 'bg-light' : ''">
                                            <td class="shop-name">
                                                <span class="logo-tmdt">
                                                    <template v-for="icon in this.iconChannel" :key="icon.id">
                                                        <img :src="icon.src" alt="" v-if="icon.id == entry.channelId">
                                                    </template>
                                                </span>
                                                <span class="mapping-booth-title" v-text="entry.shopName"></span>
                                            </td>
                                            <td class="shop-product">
                                                <div class="text-wrapper">
                                                    <div class="font-weight-bold" v-text="entry.productName"></div>
                                                    <div class="setting-mapping-text">
                                                        <a href="javascript:void(0)" v-if="entry.productId">{{$t('message.common.id')}}: <span v-text="entry.productId"></span></a>
                                                        <span v-if="entry.productSku"> - </span><span class="text-warning" v-if="entry.productSku">{{$t('message.common.sku')}}: <span v-text="entry.productSku  "></span></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="omi-product">
                                                <div class="text-wrapper">
                                                    <div class="text-danger">
                                                        {{$t('message.common.inValidInventoryProduct')}}
                                                        <template v-for="channel in this.iconChannel" :key="channel.id">
                                                            <span v-if="channel.id == entry.channelId" v-text="channel.name" class="text-capitalize"></span>
                                                        </template>
                                                    </div>
                                                    <div class="setting-mapping-text">
                                                        <span v-text="entry.created_at"></span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td class="operation text-center">
                                                <span class="btn-icon btn-circle mr-2" @click="reSyncProduct(entry)"><i class="fas fa-sync-alt ml-2" :title="$t('message.common.sync')"></i></span>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                                <template v-else>
                                    <tr class="empty-wrapper">
                                        <div class="row mx-0">
                                            <div class="col-12 text-center text-secondary mt-5">
                                                <img src="../../../public/img/No-record.png">
                                            </div>
                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                <span class="border-none">{{$t('message.common.notData')}}</span>
                                            </div>
                                        </div>
                                    </tr>
                                </template>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row paginate-space" v-if="this.entries?.length > 0">
                    <div class="col-7">
                        <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="col-5 d-flex justify-content-end align-items-center">
                        <span class="filter-title mr-3">{{$t('message.common.numberRecord')}}:</span>
                        <InputSelectSearch :modelValue="numberRecord" :defaultOption="this.$constant.numberRecords" :noSearch="true" @update:modelValue="setRecords"></InputSelectSearch>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toastr from 'toastr';
    import {$get, $post, $alert} from "../../lib/utils";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";
    import MultiSelectList from "@/components/forms/MultiSelectList.vue";
    import Pagination from "@/components/Pagination";
    import ProductUnitsSearch from "@/components/ProductUnitsSearch.vue";
    import store from "../../store";
    export default {
        name: "SyncFail",
        components: {InputSelectSearch, Pagination, ProductUnitsSearch, MultiSelectList},
        mounted() {
            document.title = "Đồng bộ lỗi"
        },
        created() {
            if(this.$route.query.shopId){
                this.quickSearchShop = [this.$route.query.shopId];
            }
            if(this.$route.query.channelId){
                this.setSaleChannel(this.$route.query.channelId);
            }else{
                this.getListShopByChannel();
            }
            this.setSearchObj(this.searchObj);
        },
        data() {
            return {
                entries: [],
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                    totalRecord: 10,
                },
                numberRecord: 10,
                page: 1,
                channel: this.$constant.shopeeId,
                statusSync: 0,
                searchObj: this.$constant.searchOrderId,
                optionSearch: this.$constant.optionSearchSyncFailOrder,
                sttMapping: this.$constant.allMapping,
                textTypeChannel: this.$constant.textShopee,
                iconChannel:[
                    {id: 1, name: this.$constant.textShopee, src: require('../../../public/img/shopee-icon-active.png') },
                    {id: 2, name: this.$constant.textLazada,  src: require('../../../public/img/lazada-icon-active.png') },
                    {id: 3, name: this.$constant.textTiki, src: require('../../../public/img/tiki-icon.png') },
                    {id: 4, name: this.$constant.textTiktok, src: require('../../../public/img/tiktok-icon-active.png') },
                    {id: 5, name: this.$constant.textSendo, src: require('../../../public/img/lazada-icon-active.png') },
                ],
                keyword: '',
                listShops: [],
                selectShops: [],
                syncFailObject: this.$constant.syncFailOrders,
                placeholder: null,
                quickSearchShop: [],
                searchOrders: null,
                searchInvoices: null,
                searchCustomers: null,
                searchProducts: null,
            }
        },
        methods: {
            setSaleChannel(channel){
                this.channel = channel;
                switch (+this.channel) {
                    case this.$constant.shopeeId:
                        this.textTypeChannel = this.$constant.textShopee;
                        break;
                    case this.$constant.lazadaId:
                        this.textTypeChannel = this.$constant.textLazada;
                        break;
                    case this.$constant.tikiId:
                        this.textTypeChannel = this.$constant.textTiki;
                        break;
                    case this.$constant.tiktokId:
                        this.textTypeChannel = this.$constant.textTiktok;
                        break;
                    case this.$constant.sendoId:
                        this.textTypeChannel = this.$constant.textSendo;
                        break;
                    default:
                        this.textTypeChannel = this.$constant.textShopee;
                        break;
                }
                this.getListShopByChannel();
                this.getSyncFail();
            },
            async getSyncFail(){
                let res;
                switch (this.syncFailObject) {
                    case this.$constant.syncFailOrders:
                        res = await $post('/v1/connection/get-sync-fail-orders', {
                            channelId : this.channel,
                            numberRecord: this.numberRecord,
                            page: this.page,
                            branchId: store.state.CurrentBranch.id,
                            keyword: this.keyword,
                            shopIds: this.selectShops,
                            searchObj: this.searchObj,
                            searchOrders: this.searchOrders,
                            searchInvoices: this.searchInvoices,
                            searchCustomers: this.searchCustomers,
                            searchProducts: this.searchProducts,
                        });
                        break;
                    case this.$constant.syncFailProducts:
                        res = await $post('/v1/connection/get-sync-fail-products', {
                            channelId : this.channel,
                            numberRecord: this.numberRecord,
                            page: this.page,
                            branchId: store.state.CurrentBranch.id,
                            keyword: this.keyword,
                            shopIds: this.selectShops,
                            searchObj: this.searchObj,
                            
                        });
                        break;
                }
                if(res && res.result){
                    this.paginate.currentPage = res.result.current_page;
                    this.paginate.lastPage = res.result.last_page;
                    this.paginate.totalRecord = res.result.total;
                    this.entries = res.result.data;
                }
            },
            async getListShopByChannel(){
                const res = await $post('/v1/connection/get-list-shops', { type: this.textTypeChannel});
                if(res && res.result){
                    let arr = [];
                    let ids = [];
                    let in_array = 0;
                    res.result.forEach(el => {
                        const obj = {
                            id: el.shopId ?? null,
                            name: el.name ?? null,
                            checked: true,
                        }
                        arr.push(obj);
                        ids.push(obj.id);
                        in_array = el.shopId == this.quickSearchShop ? (in_array + 1) : in_array;
                    });
                    this.listShops = arr;
                    if(this.quickSearchShop != [] && in_array > 0){
                        this.selectShops = this.quickSearchShop;
                    }else{
                        this.selectShops = ids;
                    }
                }
            },
            async onSelectProductUnit(data){
                let productUnitId = data.data.productUnitId;
                let eID = data.label;
                if(!eID || !productUnitId){
                    $alert({ code: 422, message: this.$t('message.medicineProductMapping.message_connect_error')});
                }
                const res = await $post('/v1/products/mapping-product', {
                    productUnitId: productUnitId,
                    id: eID,
                });
                $alert(res);
                this.getSyncFail();
            },
            async unLink(id){
                const res = await $post('/v1/products/mapping-product', {
                    productUnitId: null,
                    id: id,
                });
                if(res && res.code == 200){
                    $alert({ code: 200, message: this.$t('message.medicineProductMapping.message_disconnect_success')});
                    this.getSyncFail();
                }
            },
            pageChange(page){
                this.page = page;
                this.getSyncFail();
            },
            setRecords(records){
                this.numberRecord = records;
                this.page = 1;
                this.getSyncFail();
            },
            changeSyncFailObject(obj){
                this.syncFailObject = obj;
                switch (this.syncFailObject) {
                    case this.$constant.syncFailOrders:
                        this.optionSearch = this.$constant.optionSearchSyncFailOrder;
                        this.setSearchObj(this.$constant.searchOrderId)
                        break;
                    case this.$constant.syncFailProducts:
                        this.setSearchObj(this.$constant.searchProductId)
                        break;
                }
                this.page = 1;
                this.getSyncFail();
            },
            setSearchObj(obj){
                this.searchObj = obj;
                this.optionSearch.forEach(opt => {
                    if(opt.id == this.searchObj){
                        this.placeholder = opt.placeholder;
                    }
                })
                switch (+this.searchObj) {
                    case this.$constant.searchOrderId:
                        this.searchOrders = true;
                        this.searchInvoices = false;
                        this.searchCustomers = false;
                        this.searchProducts = false;
                        break;
                    case this.$constant.searchInvoiceId:
                        this.searchOrders = false;
                        this.searchInvoices = true;
                        this.searchCustomers = false;
                        this.searchProducts = false;
                        break;
                    case this.$constant.searchCustomerId:
                        this.searchOrders = false;
                        this.searchInvoices = false;
                        this.searchCustomers = true;
                        this.searchProducts = false;
                        break;
                    case this.$constant.searchProductId:
                        this.searchOrders = false;
                        this.searchInvoices = false;
                        this.searchCustomers = false;
                        this.searchProducts = true;
                        break;
                }
                this.page = 1;
                this.getSyncFail();
            },
            removeKeyword(){
                this.keyword= '';
                this.page = 1;
                this.getSyncFail();
            },
            setListShops(lists){
                this.selectShops = lists;
                this.page = 1;
                this.getSyncFail();
            },
            async mappingProducts(entry){
                let itemSkuLst = "";
                if(entry.details){
                    entry.details.forEach((detail, index) => {
                        itemSkuLst = index > 0 ? itemSkuLst.concat(',', detail.productId) : itemSkuLst.concat('', detail.productId);
                    });
                }
                let route = this.$router.resolve({
                    path: '/sale-online/e-commerce/mapping',
                    query: {
                        'shopId': entry.shopId,
                        'channelId': entry.channelId,
                        'itemSkuLst': itemSkuLst,
                    }
                });
                window.open(route.href, '_blank');
                // await this.$router.push({ name: 'MappingScreen', query: {
                //     'shopId': entry.shopId,
                //     'channelId': entry.channelId,
                //     'itemSkuLst': itemSkuLst,
                // }});
            },
            async reSyncFailOrder(entry){
                $alert({ code: 200, message: this.$t('message.common.reSync')});
                const res = await $post('/v1/connection/re-sync-fail-order', { 
                    id: entry.id,
                    branchId: entry.branchId,
                    channelId: entry.channelId,
                    shopId: entry.shopId,
                });
                if(res && res.status){
                    this.getSyncFail();
                }

            },
            async removeSyncFail(entry){
                const res = await $post('/v1/connection/remove-sync-fail-order', { 
                    id: entry.id,
                });
                if(res && res.result.status){
                    $alert({ code: 200, message: res.result.message});
                    this.getSyncFail();
                }else{
                    $alert({ code: 422, message: res.result.message});
                }
            },
            async reSyncProduct(entry){
                await $alert({ code: 200, message: this.$t('message.common.reSync')});
                const res = await $post('/v1/connection/re-sync-product', { 
                    id : entry.id,
                    productId : entry.productId,
                    productUnitId : entry.productUnitId,
                    branchId: entry.get_shop?.branchId,
                });
                if(res && res.status){
                    await this.getSyncFail();
                }
                $alert({ code: 200, message: this.$t('message.common.syncSuccess')});
            },
            async syncAll(){
                $alert({ code: 200, message: this.$t('message.common.reSync')});
                let res;
                switch (this.syncFailObject) {
                    case this.$constant.syncFailOrders:
                        res = await $post('/v1/connection/re-sync-all-order', { 
                            channelId : this.channel,
                            shopIds: this.selectShops,
                        });
                        if(res && res.status){
                            this.getSyncFail();
                        }
                        $alert({ code: 200, message: this.$t('message.common.syncSuccess')});
                        break;
                    case this.$constant.syncFailProducts:
                        res = await $post('/v1/connection/re-sync-all-products', { 
                            branchId: store.state.CurrentBranch.id,
                        });
                        if(res && res.status){
                            this.getSyncFail();
                        }
                        $alert({ code: 200, message: this.$t('message.common.syncSuccess')});
                        break;
                }
            }
        }
    }
</script>
<style scoped>

.setting-mapping-wrapper {
    flex-direction: column;
    flex: 1;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.mapping-header {
    background-color: #f5f6f8;
    padding: 1.5rem;
    border-radius: 10px;
}
.filter-block:first-child {
    margin-top: 0;
}
.filter-block {
    margin-top: 1.6rem;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.filter-block .filter-title {
    margin-right: 1rem;
}
.filter-title{
    min-width: 60px !important;
}
.filter-block .filter-list-choose {
    flex: 1;
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.btn:not(.btn-dashed):not(.border-hover):not(.border-active):not(.btn-flush):not(.btn-icon){
    padding: 0 calc(1.1rem + 1px);
    height: 32px;
}
.filter-block .filter-list-choose .btn.filter-active{
    border-color: rgb(47, 161, 161);
}
.btn:not(.btn-shadow):not(.shadow):not(.shadow-sm):not(.shadow-lg){
    box-shadow: none!important;
}
.filter-block .filter-list-choose .btn:first-child{
    margin-left: 0;
}
.btn.btn-outline-default{
    border: 1px solid #bebfc2;
}
button:not(:disabled) {
    cursor: pointer;
}
.btn-radius-cicrle {
    border-radius: 999px;
}
.btn{
    outline: none!important;
    margin: 0;
    align-items: center;
    border: none;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.setting-sync-option{
    min-width: 250px !important;
}
.filter-search-block {
    border-top: 1px solid #d7d9de;
    margin-top: 1rem;
    padding-top: 1rem;
}
.form-control-search {
    position: relative;
}
.form-control-search .form-control-icon {
    cursor: pointer;
    color: rgba(31,32,33,.52);
    transform: translateY(-50%);
    position: absolute;
    left: 8px;
    top: 50%;
}
.mapping-header .form-control {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}
.form-control-search input {
    border-color: #d7d9de;
    padding: 0.4rem 2rem;
}
.form-control {
    border-color: transparent;
    padding: 0.43rem 1.2rem;
    height: 32px;
    border-width: 1px;
    border-radius: 5px;
    transition: all .2s ease-in-out;
}
.form-control-search .input-group-append {
    color: rgba(31,32,33,.86);
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    border-radius: 999px;
    position: absolute;
    top: 55%;
    right: 8px;
}
.mapping-connection-filter .mapping-filter-tab {
    flex: 1;
}
.mapping-connection-filter, .mapping-connection-filter .mapping-filter-tab, .mapping-filter-button {
    align-items: center;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.toolbar-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-right: 1rem;
    position: relative;
    border-radius: 25px;
    transition: all .2s ease-in-out;
}
.mapping-connection-filter .toolbar-item {
    font-weight: 600;
}
.toolbar-item.active, .toolbar-item:active, .toolbar-item:hover {
    color: rgb(47, 161, 161);
    background-color: #e7f7ee;
}
.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle;
}
.mapping-grid-container {
    position: relative;
}

.setting-mapping-grid {
    flex: 1;
    margin-top: 1rem;
}
.content-head{
    background-color: #e7f7ee;
    border-radius: 5px;
    border: none !important;
}
.table-content-head{
    background-color: #f5f6f8;
    border: 1px solid #d7d9de;
    border-bottom: none !important;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    /* border: none !important; */
}
.table-content-body{
    background-color: #fff;
    border: 1px solid #d7d9de;
    /* border-top: none !important; */
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    height: 400px;
    overflow: auto;
}
.table thead tr th, tbody tr td{
    /* border: none !important; */
    border-top: none !important;
    border-bottom: none !important;
}
.table tbody tr td{
    border-top: 1px solid #d7d9de !important;
    border-right: 1px dashed #d7d9de;
}
.table tbody tr:first-child td{
    border-top: none !important;
}
.paginate-space {
    border: none;
    padding: 10px;
}
.input-checkbox{
    width: 5% !important;
    border-right: none !important;
}
.field-product{
    width: 50% !important;
}
.field-sync-error{
    width: 30% !important;
}
.operation, .field-quantity{
    width: 10% !important;
}
.shop-name{
    width: 15% !important;
}
.shop-product, .omi-product{
    width: 35% !important;
}
.logo-tmdt img{
    width: 24px;
    height: 24px;
    margin-right: 5px;
}
.dropdown-toggle::after {
    display: none;
}
.empty-wrapper{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-60%);
}
.btn-icon{
    color: rgb(47, 161, 161);
}
.toolbar {
    display: flex;
    align-items: center;
}
.toolbar-container {
    box-shadow: 0 16px 24px rgba(0,0,0,.06), 0 2px 6px rgba(0,0,0,.04), 0 0 1px rgba(0,0,0,.04);
    background-color: #fff;
    padding: 0.8rem 2.4rem;
    border-radius: 5px;
}
.toolbar-item {
    cursor: pointer;
    padding: 0.5rem 1rem;
    margin-right: 1.6rem;
    position: relative;
    border-radius: 25px;
    transition: all .2s ease-in-out;
}
.toolbar-item.active, .toolbar-item:active, .toolbar-item:hover {
    color: #008080;
    background-color: #e7f7ee;
}
.order-item-detail {
    border: 1px solid #d7d9de;
    /* border-top: none; */
    /* margin-left: 3.6rem; */
    padding: 1rem 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
}
.cell {
    padding: 0 0.8rem;
}
.cell-auto {
    min-width: 165px;
    flex: 1;
}
.product-error {
    color: #ff3650;
    align-items: baseline;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.cell-sync-date {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
}
.cell-sync-actions {
    width: 140px;
    min-width: 140px;
    max-width: 140px;
}
.images-default {
    background-color: rgba(31,32,33,.07);
    font-size: 1.5rem;
    width: 54px;
    height: 54px;
    border-radius: 5px;
}
.product-error {
    color: #ff3650;
    align-items: baseline;
    -ms-box-orient: horizontal;
    display: -moz-flex;
    display: flex;
}
.product-sync-image .product-sync-error-icon {
    border: 1px solid #fff;
    background-color: #fff;
    width: 16px;
    height: 16px;
    border-radius: 999px;
    position: absolute;
    top: -6px;
    right: -6px;
}
.cell-order-total {
    width: 130px !important;
    min-width: 130px !important;
    max-width: 130px !important;
}
.cell-sync-multi-lodate {
    width: 500px !important;
    min-width: 500px !important;
    max-width: 500px !important;
}
.content-body{
    max-height: 400px !important;
    overflow-y: auto;
}
</style>