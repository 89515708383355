<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-xl modal-dialog-scrollable" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h5 class="modal-title py-2 px-2">
                        {{$t('message.LocationIndex.titleCreate')}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-0 mt-4">
                                <div class="col-12 pl-0 text-bold">
                                    <i class="fa fa-info-circle mr-2"></i><label class="border-bottom-1-omi">{{$t('message.LocationIndex.info')}}</label>
                                </div>
                            </div>
                            <div class="row mx-4 my-3 mt-1">
                                <div class="col-6 pl-0">
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.branch')}}:<label class="text-danger px-0">*</label></div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0">
                                                <SelectSearch :placeholder="$t('message.LocationIndex.chooseBranch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" :border="true" :readonly="true" @update:modelValue="doFilterSearch" :label="'branches'"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.parentCategory')}}:</div>
                                        <div class="col-8 pl-0">
                                            <div class="form-group mb-0 position-relative">
                                                <input type="text" v-model="this.locationName" :placeholder="$t('message.LocationIndex.parentCategory') + '...'" class="form-control" @click="showModalSelectLocations">
                                                <button type="button" class="border-none bg-transparent btn-icon icon-arrow">            
                                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362" ><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mx-0">
                                <div class="col-12 pl-0 text-bold">
                                    <i class="fa fa-list mr-2"></i><label class="border-bottom-1-omi">{{$t('message.LocationIndex.categories')}}</label>
                                </div>
                            </div>
                            <template v-for="(entry, index) in entries" :key="index">
                                <div class="row mx-4 mt-3">
                                    <div class="col-6 pl-0">
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.categoriesName')}}:<label class="text-danger px-0">*</label></div>
                                            <div class="col-8 pl-0">
                                                <!-- <input type="text" class="w-100 pl-2 p-1 border rounded" placeholder="Nhập tên danh mục"> -->
                                                <InputText v-model.trim="entry.name" :placeholder="$t('message.LocationIndex.enterCategoriesName')" class="mb-0"></InputText>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6 pl-0">
                                        <div class="row">
                                            <div class="col-4 d-flex align-items-center text-bold">{{ $t('message.LocationIndex.categoriesCode') }}:<label class="text-danger px-0">*</label></div>
                                            <div class="col-8 pl-0">
                                                <!-- <input type="text" class="w-100 pl-2 p-1 border rounded" placeholder="Nhập mã danh mục"> -->
                                                <InputText v-model.trim="entry.code" :placeholder="$t('message.LocationIndex.enterCategoriesCode')" class="mb-0"></InputText>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mx-4 my-4">
                        <div class="col-12 flex-start p-0">
                            <button type="button" class="btn btn-white mr-2 border" @click="addRow">
                                <i class="fa fa-plus mr-1"></i>
                                {{$t('message.LocationIndex.addRow')}}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{$t('message.button.save')}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide" :title="$t('message.common.confirm')" :content="$t('message.LocationIndex.confirmCancelCreate')"/>
    <ModalSelectLocations ref="ModalSelectLocations" @updateLocationsName="getLocationName" :addLocationCategory="true"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import ModalConfirm from "@/components/ModalConfirm.vue";
import InputNumber from '@/components/forms/InputNumber';
import store from "@/store";
import SelectSearch from "@/components/SelectSearch";
import ModalSelectLocations from '../ModalSelectLocations';
import { $get, $post, $alert, clone, removeVietnameseTones} from "@/lib/utils";
import emitter from '@/lib/emitter';
export default {
    name: "ModalAddLocations",
    components: { InputText, InputNumber , ModalConfirm, SelectSearch, ModalSelectLocations},
    props: {
       
    },
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            entries:[],
            filterQuery: {
                branches: '',
            },
            currentBranchId: store.state.CurrentBranch.id,
            branches: {
                filter: globalConfig.branches,
                all: globalConfig.branches,
                search: null,
            },  
            locationName: null,   
            locationActiveId: 0,
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        show() {
            this.setDefaultValue();
            emitter.emit('resetDataSelectLocation');
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide () {            
            this.$emit('reload');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setDefaultValue(){
            this.filterQuery.branches = this.currentBranchId;
            this.entries = [
                {name: null, code: null},
                {name: null, code: null},
                {name: null, code: null},
                {name: null, code: null},
                {name: null, code: null},
                {name: null, code: null}
            ];
            this.locationActiveId = 0;
            this.locationName = null;
        },
        closeModal(){
            let hide = true;
            this.entries.forEach(entry => {
                if(entry.name || entry.code){
                    hide = false;
                    this.$refs.ModalConfirm.show();
                    return;
                }
            });
            if(hide){
                this.hide();
            }
        },
        addRow(){
            if(this.entries.length < 20){
                this.entries.push({name: null, code: null})
            }else{
                $alert({code: 422, message: this.$t('message.LocationIndex.maxRow')});
            }
        },
        showModalSelectLocations(){
             this.$refs.ModalSelectLocations.show();
        },
        getLocationName(input){
            this.locationActiveId = input.id ? input.id : 0;
            this.locationName = null;
            this.locationName = (input.nameLv1 ? input.nameLv1 : '' ) + (input.nameLv2 ? ' > ' + input.nameLv2 : '' ) + (input.nameLv3 ? ' > ' + input.nameLv3 : '' ) + (input.nameLv4 ? ' > ' + input.nameLv4: '' );
        },
        doFilterSearch(v){
            const query = clone(this.filterQuery);
            delete query[v.label];
            if(v.model){
                query[v.label] = v.model;
                emitter.emit('setBranchIdSelectLocation', v.model); 
                this.locationName = null;
            }
            this.filterQuery = {...query};
        },
        async save(){
            let data = [];
            let passValidate = true;
            this.entries.forEach(entry => {
                if(entry.name || entry.code){
                    if(entry.name){
                        const foundName = data.find(el => removeVietnameseTones(el.name.toLowerCase()) == removeVietnameseTones(entry.name.toLowerCase()));
                        if(foundName){
                            $alert({code: 422, message: this.$t('message.LocationIndex.existCategoriesName', {name: entry.name})});
                            passValidate = false;
                            return;
                        }
                    }
                    if(entry.code){
                        const foundCode = data.find(el => removeVietnameseTones(el.code.toLowerCase()) == removeVietnameseTones(entry.code.toLowerCase()));
                        if(foundCode){
                            $alert({code: 422, message: this.$t('message.LocationIndex.existCategoriesCode', {name: entry.code})});
                            passValidate = false;
                            return;
                        }
                    }
                    data.push(entry);
                }
            });
            if(passValidate){
                if(data.length){
                    let save = true;
                    data.forEach(element => {
                        if(!element.name){
                            $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesName')});
                            save = false;
                            return;
                        }
                        if(!element.code){
                            $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesCode')});
                            save = false;
                            return;
                        }
                    })
                    if(save){
                        let branchId = this.filterQuery.branches ? this.filterQuery.branches : this.currentBranchId;
                        const res = await $post('/v1/locations/create-location-category', {branchId: branchId, data: data, parentId: this.locationActiveId});
                        if(res.result && !res.result.status && res.result.message){
                            $alert({code: 422, message: res.result.message});
                        }else if(res.result && res.result.status && res.result.message){
                            $alert({code: 200, message: res.result.message});
                            this.hide();
                        }
                    }
                }else{
                    $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesName')});
                    $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesCode')});
                }
            }
        },
    }
}
</script>
<style scoped lang="scss">
@import "../../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.border-bottom-1-omi{
    border: none;
    border-bottom: 1px solid $base-color;
}
.icon-arrow{
    background-color: none;
    outline: none;
    position: absolute;
    top: 5px;
    right: 5px;
}
.icon-arrow svg{
    fill: #212529;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.icon-arrow svg{
    width: 9px;
    height: 9px;
    fill: #666
}
</style>