<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" id="modalSaleChannels">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                    <div class="d-flex">
                        <h5 style="fotn" class="modal-title py-2">{{typeEditAdd === "EDIT" ? $t('message.SaleChannelIndex.titleUpdate') : $t('message.SaleChannelIndex.titleCreate')}}
                        </h5>
                    </div>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0">
                    <div class="row">
                        <div class="col-10">
                            <div class="row">
                                <div class="col-2">
                                    <b>{{$t('message.SaleChannelIndex.name')}}<label class="text-danger px-0">*</label> </b>
                                </div>
                                <div class="col-10">
                                    <input type="text" name="name" id="name" v-model="entry.name" class="form-control input-custom" autocomplete="off">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <b>{{$t('message.SaleChannelIndex.description')}}</b>
                                </div>
                                <div class="col-10">
                                    <input type="text" name="name" id="name" v-model="entry.description" class="form-control input-custom" autocomplete="off">
                                </div>
                            </div>
                            <div class="row mt-3">
                                <div class="col-2">
                                    <b>{{$t('message.SaleChannelIndex.status')}}</b>
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">{{$t('message.SaleChannelIndex.active')}}
                                        <input type="radio" name="status" v-model="entry.status" :checked="entry.status === 1" :value="1" class="mr-2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">{{$t('message.SaleChannelIndex.inActive')}}
                                        <input type="radio" name="status" v-model="entry.status" :checked="entry.status === 2" :value="2" class="mr-2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            <div class="mb-2" v-if="entry.img && entry.img.url">
                                <span @click="removeImg()" class="fmi-close">
                                    <i class="fa fa-times"/>
                                </span>
                                <QImage
                                :src="entry.img && entry.img.url ? entry.img.url : '/img/default-product.593ae091.png'"
                                style="width: 100%;" class="py-2 text-center" />
                            </div>
                            <div class="d-flex align-items-center justify-content-center border-1 height-100 mb-2" v-else>
                                <QImage
                                :src="entry.img && entry.img.url ? entry.img.url : '/img/default-product.593ae091.png'"
                                style="width: 50%;" class="py-2 px-2 text-center" />
                            </div>
                            <InputFileManager :multiple="false" v-model="entry.img" :cusStyle="true"
                                :hidePreview="true" :defaultButton="true" :type="$t('message.common.image')"></InputFileManager>
                        </div>
                    </div>
                </div>
                <div class="modal-footer mt-4">
                    <AppPermission :permission="[this.$permissions.saleChannels.update ?? '', this.$permissions.saleChannels.create ?? '']">
                        <button type="button" class="btn btn-save mr-2" @click="save">
                            <i class="fa fa-save mr-1"></i>
                            {{$t('message.button.save')}}
                        </button>
                    </AppPermission>
                    <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                        <i class="fa fa-ban mr-1"></i>
                        {{$t('message.button.skip')}}
                    </button>
                    <AppPermission :permission="this.$permissions.saleChannels.remove?? '' ">
                        <button type="button" class="btn btn-danger mr-2" v-if="(typeEditAdd === 'EDIT' && this.id != null)" @click="removeSaleChannel(entry.id, entry.name)">
                            <i class="fa fa-trash-alt mr-1"></i>
                            {{$t('message.button.remove')}}
                        </button>
                    </AppPermission>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :title="this.titleConfirm" :content="this.contentConfirm" />
    <ModalRemoveSaleChannels ref="ModalRemoveSaleChannels" @inputData="hide()"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import { $get, $post, isChangeData, cloneObject} from "@/lib/utils";
import InputFileManager from "@/components/forms/InputFileManager";
import QImage from "@/components/QImage";
import ModalConfirm from "@/components/ModalConfirm.vue"
import toastr from 'toastr';
import emitter from "@/lib/emitter";
import ModalRemoveSaleChannels from "@/components/sale_channels/ModalRemoveSaleChannels";
import AppPermission from '../AppPermission.vue';
export default {
    name: "ModalAddInfoSaleChannels",
    mounted() {
    },
    emits: ['inputData'],
    components: {
        QImage,
        InputFileManager,
        InputText,
        ModalConfirm,
        ModalRemoveSaleChannels,
        AppPermission
    },
    props: {
        typeEditAdd: String,
        idSaleChannel: Number,
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                description: '',
                status: 1,
                img: null,
            };
            this.default = {
                name: '',
                description: '',
                status: 1,
                img: null,
            };
        },
        async show(id = null) {   
            this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
                this.id = id;
            }
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            emitter.emit('removeDataInputFileManager');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.SaleChannelIndex.confirm');
                if(this.id != null){
                    this.contentConfirm = this.$t('message.SaleChannelIndex.contentConfirmCancelUpdate');
                }else{
                    this.contentConfirm = this.$t('message.SaleChannelIndex.contentConfirmCancelCreate');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async load(id = null) {
            const res = await $get('/v1/sale-channels/show', {
                id: id
            });
            this.entry = res.result;
        },
        async save() {
            this.errors = {};
            const submitEntry = cloneObject(this.entry);
            Object.keys(submitEntry).forEach(key => {
                if(typeof submitEntry[key] === 'string'){
                    submitEntry[key] = submitEntry[key].trim();
                }
            });
            let res = null;
            if(this.id){
                res = await $post('/v1/sale-channels/update', {
                    ...submitEntry
                });
            }else{
                res = await $post('/v1/sale-channels/save', {
                    ...submitEntry
                });
            }
            if(res.code === 422){
                if(res.messages.errors.name){
                    toastr.error(res.messages.errors.name[0]);
                }
                if(res.messages.errors.description){
                    toastr.error(res.messages.errors.description[0]);
                }
            }
            else if(res.status.code == 200){
                if(res.result.code === 422){
                    toastr.error(res.result.message);
                }else{
                    toastr.success(res.result.message);
                }
                this.hide();
            }
        },
        removeSaleChannel(id, name){
            this.$refs.ModalRemoveSaleChannels.show(id, name);
        },
        removeImg(){
            this.entry.img = null;
        }
    },
    data() {
        return {
            entry: {},
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            id: null,
        }
    }
}
</script>
<style scoped>

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#modalSaleChannels{
    max-width: 800px !important;
}
.border-1{
    border: 1px dotted gray;
    border-radius: 5px;
    height: 100px;
}
.fmi-close {
    position: absolute;
    top: -.5rem;
    right: 1rem;
    color: #8b0000;
    cursor: pointer;
}
</style>