<template>
  <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" data-backdrop="static">
    <tr id="loadingaa">
        <div id="test" class="spinner-border text-info d-flex justify-content-center"  role="status"  :class="this.loadingaa ? 'dp-hide' : ''" v-if="loadingaa">
          <span class="sr-only">loading...</span>
        </div>
    </tr>
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                  <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                      <h5 class="modal-title m-2">
                          {{$t('message.ModalImportEmployees.headerTitle')}}
                      </h5>
                      <h6 class="modal-title my-2 pt-1">
                          <span class="" style="font-weight: 400;"> (   {{$t('message.ModalImportEmployees.templateTitle')}}
                            <a target="_blank" :href="'../Files/template_employees_' + this.language + '.xlsx'">{{$t('message.ModalImportEmployees.urlTitle')}}</a>)
                          </span>
                      </h6>
                      <button type="button" class="close" @click="resetStatus" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                  </div>
                <div class="modal-body py-3 px-3">
                    <!-- Xử lý trùng mã nhân viên, khác thông tin nhân viên: -->
                    <div class="form-check mx-0">                      
                        <label class="form-check-label" for="exampleCheck1">{{$t('message.ModalImportEmployees.contentTitle')}}</label> 
                    </div>
                    <!-- Lựa chọn 1: Báo lỗi và dừng import -->
                    <div class="form-check m-3">
                      <label class="container-radio mt-3 form-check-label">
                        <!-- <input  v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off" v-bind:value="this.$constant.msgErrorStop" checked @click="existValue(this.$constant.msgErrorStop)">                     -->
                        <input  v-model="patient.optionImport" :value="0" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off" checked>
                        <span class="checkmark"></span>
                        <span>  {{$t('message.ModalImportEmployees.errStopTitle')}}</span>
                      </label>
                    </div>
                    <!-- Lựa chọn 2: Thay thế thông tin nhân viên cũ bằng thông tin nhân viên mới -->                    
                    <div class="form-check m-3">
                       <label class="container-radio mt-3 form-check-label">
                        <!-- <input  v-model="rdoCodeExist" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off" v-bind:value="this.$constant.msgErrorStop" checked @click="existValue(this.$constant.msgUpEmployee)">                     -->
                        <input  v-model="patient.optionImport" :value="1" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off">
                        <span class="checkmark"></span>
                        {{$t('message.ModalImportEmployees.changeUpdateTitle')}}
                      </label>                           
                    </div>
                    <!-- Nút chọn file -->
                    <div class="row my-2 mx-2">
                        <div class="col-12 text-right mx-0 px-0" id="divUpload">
                            <label class="custom-file-upload">
                                <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" />
                                <i class="fa fa-cloud-upload"></i> {{$t('message.button.import.selectFile')}} 
                            </label>
                        </div>
                    </div>
                </div>
                <!-- Hiển File được  -->
                <div class="row py-2 mx-2" id="rowMessage" v-if="this.actionShow">
                    <div class="col-6">
                      <label>{{ this.fileName }}</label>
                    </div>
                    <div class="col-6 text-danger text-right">
                      <a href="javascript:;" @click="showAction(false)" class="text-danger">
                        <i class="fa fa-times"></i>
                      </a>
                    </div>
                </div>
                <div class="alert alert-danger row py-2 m-2" v-show="this.messageFile">
                    {{ this.messageFile }}
                </div>
                <!-- Nút thực hiện upload file -->
                <div class="row mt-3 mx-2">                  
                    <div class="col-12 text-right px-0 mx-0 mb-3" v-if="this.showBtn">
                      <button type="button" class="btn btn-primary" id="btnAction" @click="UpdateFile(this.patient)" >
                        {{$t('message.button.import.action')}} 
                      </button>                   
                    </div>
                </div> 
            </div>
        </div>
    </div>
</template>
<script>
import { $post, $upload} from "@/lib/utils";
import toastr from 'toastr';
import emitter from "@/lib/emitter";
import store from "@/store";
export default {
    name: "ModalImportExcel",
    props: {},
    data () {
      return {
        actionShow: false,
        showBtn: false,
        fileName: "",
        patient: {
          file: [],
          branchID: store.state.CurrentBranch.id,
          optionImport: 0,
        },
        errors: [],
        messageFile: '',
        loadingaa:false,
        language: localStorage.getItem('language') ?? 'vi'
      }
    },
  methods: {
    existValue(value){      
      this.rdoCodeExist = value;
      let data = {
          'rdoCodeExist': this.rdoCodeExist,
          'rdoInventory': this.rdoInventory,
          'rdoStatus': this.rdoStatus,  
          'branchID': this.entry.branchIDs       
       }
      this.patient.data = JSON.stringify(data);
      
    },
    show() {
      this.showAction(false);
      this.showBtn = false;
      window.jQuery(this.$refs.modal).modal('show');
    },
    showAction(status) {
      this.actionShow = status;
      this.showBtn = status;
      if (status == false) {
        this.$refs.fileupload.value = '';
        this.messageFile ='';
      }
    },
    onFileChange(event) {   
      this.patient = {
        // statusEmail: this.patient.statusEmail,
        // statusUpdate: this.patient.statusUpdate,
        optionImport: this.patient.optionImport,
        file: "",
      };
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) {  
        return;
      }
      
      this.patient.branchID = store.state.CurrentBranch.id;
      this.fileName = files[0].name;
      this.patient.file = files[0];
     
      if(files[0].name == "" ) {
         this.actionShow = false;  
         this.messageFile ='';       
      }
      else {
        this.actionShow = true;
        this.messageFile ='';
      }
      if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
        this.showBtn = true;
        this.messageFile ='';
      }else {
        this.showBtn = false;
        this.messageFile = this.$t('message.common.inValidFormatFile');
      }   
      // this.$refs.fileupload.value = null;
    },
    getContent(data){         
        let content = {
        title:data.title,
        code: data.code,
        message: data.message,       
        totalRecord: data.totalRecord,
        totalAddnew: data.totalRecordNew,
        totalUpdate: data.totalRecordUpdate,
        totalError: data.totalRecordError,
        error: data.error,
      }    
      return content;
    },

    async UpdateFile(file) {
      this.loadingaa = true;
      const res = await $upload("/v1/employee/import-excel", [], file);              
      let data = null;
      let content = null;
      if(res && res.code === 200) {
        if(res.data) {
          data = res.data; 
          content = this.getContent(data);
        }
        this.loadingaa = false;  
        emitter.emit("errMsgShow", true);
        emitter.emit("dataMsgShow", content);
        emitter.emit("fileName", this.fileName)
        this.$emit('input','1');
        this.$emit('updated');
        window.jQuery(this.$refs.modal).modal("hide");
        this.resetStatus();
      }
      else {
         if (res && res.code == 422 && res?.data && res.data?.error) {           
            data = res.data;
            content = this.getContent(data);            
            this.loadingaa = false;  
            emitter.emit("errMsgShow", true);
            emitter.emit("dataMsgShow", content);
            emitter.emit("fileName", this.fileName)
            this.$emit('updated');
            window.jQuery(this.$refs.modal).modal("hide");
            this.resetStatus();
         }
      }    
    },
    resetStatus ()
    {       
      this.patient.optionImport = 0;     
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#loadingaa {
  position: fixed;
  top:25%;
  left:45%;
  z-index: 100;
}
input[type="file"] {
  display: none;
}

#btnAction{
  width: 100px;
}
#rowMessage{
  background-color: rgb(224 241 241);
  border-radius: 5px;

}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-flex;
  padding: 6px 12px;
  cursor: pointer;
  background: $base-color;
  color: #ccffff;
  margin-left: 5px;
  height: 40px;
  border-radius: 5px;
  align-items: center;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal-dialog {
  max-width: 800px !important;
}

.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: 0;
  left: 45px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
}

.icon-circle-custom:hover+.content-circle {
  display: block;
}

.status-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: -5px;
  left: 257px;
  z-index: 1;
  border-radius: 5px;
  line-height: 25px;
  text-align: justify;
}

.status-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;

}

.icon-circle-custom:hover+.status-circle {
  display: block;
}
.form-check-label{
    font-size:15px;    
}
.container input[type='radio'] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
</style>