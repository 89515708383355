<template>
  <div class="row">
    <div class="col-lg-6 px-0 mx-0" style="border: 1px solid #ccc" id="design">        
      <DesignTemplates
        ref="DesignTemplates"
        :action="this.action"
        :entry="this.entry"
        :html="this.html"
        :content="this.content"   

        :printTempId="this.$constant.defaultReturnSupplierTemplateId"
        :printTempData="this.printTemplate"
        @changeContent="this.changeContent"  
        />      
         
    </div>
    <div class="col-lg-6 px-0 mx-0" style="background-color: #eef3f7">
      <ViewTemplates ref="ViewTemplates" 
        :entry="this.data"
        :contenOrigin="contenOrigin" 
        :action="this.action"/>
    </div>
  </div>
</template>
<script>
import DesignTemplates from "./tabs/DesignTemplates";
import ViewTemplates from "./tabs/ViewTemplates";
export default {
  name: "ReturnSupplierTemplate",
  components: { DesignTemplates, ViewTemplates },
  mounted() {          
  },
  created() {    
  },
  data() {
    return {      
      printPaperId: 2,
      action: "TabReturnSuppliers",
      entry:{},      
      data:{},
      html:'',
      printTemplate: null,      
      content:null,
      contenOrigin: null,
    };
  },
  methods: {
        changeContent(newContent){        
          this.contenOrigin = newContent;
        },
  },
  watch: {    
            content: {                
                handler(newData){                  
                  this.content = newData;                  
                },
                deep: true
            },            
            printTemplate: {                
                handler(newData){                  
                  this.printTemplate = newData;  
                
                },
                deep: true
                
            },
  }
};
</script>
