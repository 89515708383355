<template>    
    <AppPermission :permission="this.$permissions.deliveries.data?? '' ">
        <div class="container">
            <div class="row m-0 p-0">
                <div class="mainLeft pt-3">
                    <div class="main-title">
                        <span>
                            {{$t('message.DeliveryIndex.title')}}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row">
                                <div class="col-8 text-title">
                                    <label for="">{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.created_at')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('timeBody', 'timeDown', 'timeUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="timeDown"></i>
                                        <i class="fa fa-chevron-up" id="timeUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="timeBody">
                            <SelectDateTime :label="'createdAt'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.completed_at')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('completedAtBody', 'completedAtDown', 'completedAtUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="completedAtDown"></i>
                                        <i class="fa fa-chevron-up" id="completedAtUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="completedAtBody">
                            <SelectDateTime :label="'completedAt'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.status')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('statusBody', 'statusDown', 'statusUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="statusDown"></i>
                                        <i class="fa fa-chevron-up" id="statusUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="statusBody">
                            <template v-for="status in this.listStatus" :key="status.id">
                                <div class="row">
                                    <div class="col-12 d-flex align-items-center position-relative">
                                        <label class="container-checkbox">{{status.name}}
                                            <input type="checkbox" class="chkType" @click="doFilter('status', status.id, status)" :checked="isExistInArrayChecked(status, this.filterQuery.status)">
                                            <span class="checkmark-checkbox"></span>
                                        </label>
                                        <i class="fa fa-plus icon-plus" v-if="status.children" @click="toggleChildren(status.id)" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? 'd-none' : ''"></i>
                                        <i class="fa fa-minus icon-plus" v-if="status.children" @click="toggleChildren(status.id)" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? '' : 'd-none'"></i>
                                    </div>
                                </div>
                                <template v-if="status.children">
                                    <div class="row pl-4" :class="isExistInArrayChecked(status, this.arrayChildChecked) ? '' : 'd-none'" v-for="child in status.children" :key="child.id">
                                        <div class="col-12 d-flex align-items-center">
                                            <label class="container-checkbox">{{child.name}}
                                                <input type="checkbox" class="chkType" @click="doFilter('status', child.id)" :checked="isExistInArrayChecked(child, this.filterQuery.status)">
                                                <span class="checkmark-checkbox"></span>
                                            </label>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.transporter')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('transporterBody', 'transporterDown', 'transporterUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="transporterDown"></i>
                                        <i class="fa fa-chevron-up" id="transporterUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="transporterBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.DeliveryIndex.placeholder.transporter')" :modelValue="filterQuery.transporter" :defaultOption="listTransporters" @update:modelValue="doFilterSearch" :label="'transporter'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.area_delivery')}}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('areaDeliveryBody', 'areaDeliveryDown', 'areaDeliveryUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="areaDeliveryDown"></i>
                                        <i class="fa fa-chevron-up" id="areaDeliveryUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="areaDeliveryBody">
                            <div class="row">
                                <div class="col-12">
                                    <SelectSearch :placeholder="$t('message.DeliveryIndex.placeholder.area_delivery')" :modelValue="filterQuery.area_delivery" :defaultOption="listAreaDelivery" @update:modelValue="doFilterSearch" :label="'area_delivery'"></SelectSearch>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{$t('message.DeliveryIndex.cod')}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="sBody">
                            <div class="row">
                                <div class="col-12" v-for="item in this.listStatusCod " :key="item.id">
                                    <label class="container-radio ml-0">{{item.name}}
                                        <input type="radio" name="status" @click="doFilter('statusCod', item.id)" class="chkType" :checked="filterQuery.statusCod === item.id ? true: false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- right -->
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row">
                            <div class="col-lg-5">
                                <InputQuickSearch :placeholder="$t('message.DeliveryIndex.placeholder.search')" @filterSearch="changeQuickSearch" :deliverySearch="true" />
                            </div>
                            <div class="col-lg-7 d-flex justify-content-end">
                                <button type="button" @click="clearFilter()" class="btn btn-default">
                                    <i class="fa fa-filter mr-1"></i>
                                    {{ $t('message.button.removeFilter') }}
                                </button>
                                <AppPermission :permission="this.$permissions.deliveries.export?? '' ">
                                    <button class="btn btn-save" type="button" id="dropdownMenuButton" @click.stop="exOverview()"
                                        style="padding:7px" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <i class="fas fa-file-export mr-1"></i>
                                        {{$t('message.DeliveryIndex.button.export')}}
                                    </button>
                                </AppPermission>
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="deliveries"></SelectField>
                                </div> 
                            </div>
                        </div>
                        <div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                    {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                                    <a href="javascript:;" @click="removeChecked()"><i class="fa fa-times text-danger" aria-hidden="true"></i></a>
                                </div>
                                <div class="row mx-0 my-2" id="divTable">
                                    <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                        <thead>
                                            <tr>
                                                <th scope="col" class="checkbox-table" v-if="fields.length > 0"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox"/></th>
                                                <template v-for="field in fields" :key="field.field">
                                                    <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                        <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort"
                                                        :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                                    </th>
                                                </template>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template v-if="entries.length > 0">
                                                <template v-for="(entry, index) in entries" :key="entry.id" >                                                    
                                                    <tr class="master-row" :class="entry.id === this.deliveryActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                        <td>
                                                            <input v-model="entry.checked" type="checkbox"  @change.stop="countChecked(entry.id)" class="prdChecked"
                                                                    :id="entry.id" :name="entry.name" :value="entry.code" :checked="isExistInArrayChecked(entry, this.arrayChecked)">
                                                        </td>
                                                        <template v-for="field in fields" :key="field.field">
                                                            <td class="ow-break-word py-0" v-if="field.selected && field.field != 'customerContactNumber' && field.field != 'priceCodPayment' && field.field != 'totalPrice'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailDelivery(entry.id, entry)">
                                                                    <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                            <td class="ow-break-word py-0" v-else-if="field.selected && field.field ==='customerContactNumber'" style="vertical-align:middle !important;"
                                                            :style="'min-width:110px'" >
                                                                <template v-if="entry.customerContactNumber.length > 1" >
                                                                    <select  class="sltUnit">
                                                                        <option class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></option>
                                                                    </select>
                                                                </template>
                                                                <template v-else-if="entry.customerContactNumber.length == 1" >
                                                                    <span class="description" v-text="n" :key="n" v-for="n in entry.customerContactNumber"></span>
                                                                </template>  
                                                            </td>
                                                            <td class="ow-break-word py-0" v-else-if="field.selected && field.field === 'priceCodPayment'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailDelivery(entry.id, entry)">
                                                                    <div v-text="entry.usingPriceCod ? n(entry.priceCodPayment) : 0" :title="entry.usingPriceCod ? n(entry.priceCodPayment) : 0" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                            <td class="ow-break-word py-0" v-else-if="field.selected && field.field === 'totalPrice'" style="vertical-align:middle !important;" :style="'min-width:110px'" >
                                                                <a :href="'#row_' + index" class="description text-dark tagA" @click="detailDelivery(entry.id, entry)">
                                                                    <div v-text="entry.payFeeShip != this.$constant.paid_by_receiver ? n(entry.price) : 0" :title="entry.payFeeShip != this.$constant.paid_by_receiver ? n(entry.price) : 0" :style="field.style" class="description" :class="field.class"></div>
                                                                </a>
                                                            </td>
                                                        </template>
                                                    </tr>
                                                    <tr v-if="deliveryActive === entry.id" :class="[ entry.id === deliveryActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                        <td></td>
                                                        <td :colspan="fields.length" class="pb-0">
                                                           <div class="row detail-prd-row">
                                                            <!-- Thông tin -->
                                                                <div class="col-2 mt-2"  @click="showContent(this.$constant.tabInformation)">
                                                                    <span :class=" this.tabActive == this.$constant.tabInformation ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{$t('message.DeliveryIndex.tabs.info')}}
                                                                    </span>
                                                                </div>
                                                            <!-- Lịch sử thanh toán ĐTGH -->
                                                                <div class="col-2 mt-2 px-2"  @click="showContent(this.$constant.tabHistoryPayment)" v-if="entry.historyPayment.length > 0">
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryPayment ? 'backgroup__detail-active head-detail' : 'head-detail' ">
                                                                        {{$t('message.DeliveryIndex.tabs.paymentTrans')}}
                                                                        <i class="fa fa-info-circle px-2" :title="$t('message.DeliveryIndex.infoTitle')"></i>                                                                     
                                                                    </span>                                                                    
                                                                </div>
                                                            <!-- Chuyển hàng -->
                                                                <div class="col-2 mt-2" @click="showContent(this.$constant.tabHistoryReturn)">                                                      
                                                                    <span :class=" this.tabActive == this.$constant.tabHistoryReturn ? 'backgroup__detail-active head-detail' : 'head-detail' " >
                                                                        {{$t('message.DeliveryIndex.tabs.history')}}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <!-- Thông tin Tab -->            
                                                    <template v-if="this.tabActive == this.$constant.tabInformation">
                                                        <!-- Chi tiết phiếu chuyển hàng -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="deliveryActive === entry.id" >
                                                            <td class="border-top-0"></td>
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="mt-2 pb-4 detail__user">
                                                                    <div class="row mt-2 detail-prd-row">
                                                                        <div class="col-4">
                                                                            <div class="row mt-2 py-2">
                                                                                <div class="col-lg-12 text-bold">
                                                                                    <h6 class="text-bold">{{$t('message.DeliveryIndex.receiver_info')}}</h6>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mt-2">
                                                                                <div class="col-5 py-2">
                                                                                    {{$t('message.DeliveryIndex.receiver_name')}}:
                                                                                </div>
                                                                                <div class="col-7 px-0 ow-break-word" 
                                                                                :class="!entry.transporterIntegration && !entry.statusCancel 
                                                                                && (entry.statusUpdate != this.$constant.status_update_delivery_1) 
                                                                                    ? '' : 'py-2 border-bottom-1'">   
                                                                                    <template v-if="!entry.transporterIntegration && !entry.statusCancel && entry.customer_address 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" v-model="entry.customer_address.name" class="py-2 pl-0 border-bottom-1 w-100 border-bottom-focus-active">                                                         
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.receiverName"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mt-2">
                                                                                <div class="col-5 py-2 lableOS">
                                                                                    {{$t('message.DeliveryIndex.receiver_phone')}}:
                                                                                </div>
                                                                                <div class="col-7 px-0 ow-break-word lableOS" :class="!entry.transporterIntegration 
                                                                                && (entry.statusUpdate != this.$constant.status_update_delivery_1) 
                                                                                    ? '' : 'py-2 border-bottom-1'">
                                                                                    <template v-if="!entry.transporterIntegration && entry.customer_address 
                                                                                        && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <InputText v-model.trim="entry.customer_address.phone" 
                                                                                            :placeholder="$t('message.supplierIndex.modal.enterPhone')" :max="10" 
                                                                                        :cusNumber="true" :classCustom="'border-bottom-1 w-100 border-bottom-focus-active rounded-0 pl-0'"/>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.receiverPhone"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mt-2">
                                                                                <div class="col-5 py-2">
                                                                                    {{$t('message.DeliveryIndex.receiver_address')}}:
                                                                                </div>
                                                                                <div class="col-7 px-0 ow-break-word" :class="!entry.transporterIntegration 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)
                                                                                    ? '' : 'py-2 border-bottom-1'">
                                                                                    <template v-if="!entry.transporterIntegration && entry.customer_address 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <input type="text" v-model="entry.customer_address.addressValue" class="py-2 pl-0 border-bottom-1 w-100 border-bottom-focus-active">                                                         
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.receiverAddress"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mt-2">
                                                                                <div class="col-5 py-2">
                                                                                    {{$t('message.DeliveryIndex.receiver_area')}}:
                                                                                </div>
                                                                                <div class="col-7 px-0 ow-break-word" :class="!entry.transporterIntegration 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)
                                                                                    ? '' : 'py-2 border-bottom-1'">
                                                                                    <template v-if="!entry.transporterIntegration && entry.customer_address 
                                                                                        && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.customer_address.districtId" 
                                                                                                        :modelValue="entry.customer_address.districtId" 
                                                                                                        :defaultOption="listAreaDelivery" :label="'areaDelivery_' + entry.id" 
                                                                                                        :classCustom="'py-2'"
                                                                                                        @update:modelValue="changeDistrict(entry.customer_address)"
                                                                                                        @setNewData="setAreaDelivery">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.receiverArea"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row mt-2">
                                                                                <div class="col-5 py-2">
                                                                                    {{$t('message.DeliveryIndex.receiver_ward')}}: 
                                                                                </div>
                                                                                <div class="col-7 px-0 ow-break-word" :class="!entry.transporterIntegration 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)
                                                                                    ? '' : 'py-2 border-bottom-1'">
                                                                                    <template v-if="!entry.transporterIntegration && entry.customer_address 
                                                                                    && (entry.statusUpdate != this.$constant.status_update_delivery_1)">
                                                                                        <SelectSearch :vModel="true" v-model="entry.customer_address.wardId" 
                                                                                                        :modelValue="entry.customer_address.wardId" 
                                                                                                        :defaultOption="listWards" :label="'wards_' + entry.id" 
                                                                                                        :classCustom="'py-2'">
                                                                                        </SelectSearch>
                                                                                    </template>
                                                                                    <template v-else>
                                                                                        <span v-text="entry.receiverWardId"></span>
                                                                                    </template>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4">
                                                                            <div class="mx-1">
                                                                                <div class="row mt-2 py-2">
                                                                                    <div class="col-lg-12 text-bold">
                                                                                        <h6 class="text-bold">{{$t('message.DeliveryIndex.invoice_info')}}</h6>
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{$t('message.DeliveryIndex.invoice_code')}}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word text-primary" @click="entry.invoiceCode ? linkToInvoice(entry.invoiceCode) : null" v-text="entry.invoiceCode">                                                            
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{$t('message.DeliveryIndex.branch')}}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word pr-0" v-text="entry.branchName">                                                            
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{$t('message.DeliveryIndex.customer_name')}}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word text-primary" @click="entry.customerCode ? linkToCustomer(entry.customerCode) : null" v-text="entry.customer">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{$t('message.DeliveryIndex.total_products')}}:
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="entry.invoice && entry.invoice.totalQuantity ? n(entry.invoice.totalQuantity) : null">
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row border-bottom-1 mt-2 py-2">
                                                                                    <div class="col-5">
                                                                                        {{$t('message.DeliveryIndex.values')}}: 
                                                                                    </div>
                                                                                    <div class="col-7 ow-break-word" v-text="entry.invoice && entry.invoice.totalOrigin ? n(entry.invoice.totalOrigin) : null">
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-4" id="noteRO">  
                                                                            <div class="row p-0 font-italic">
                                                                                <div class="col-12" v-if="entry.invoice">
                                                                                    <textarea v-model.trim="entry.invoice.description" :id="'note_'+ entry.id" :placeholder="$t('message.DeliveryIndex.note')" class="noteStyle px-4 text-gray font-italic"></textarea>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                </div>
                                                                                <div class="col-12 text-gray" v-else>
                                                                                    <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                    <span class="noteStyle px-4 text-gray font-italic">
                                                                                        {{ entry.invoice && entry.invoice.description ? entry.invoice.description : $t('message.DeliveryIndex.note')}}
                                                                                    </span>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="detail-row bg-white border__body-detail" v-if="deliveryActive === entry.id">
                                                            <td :colspan="fields.length + 1" class="border-top-0 px-0">
                                                                <div class="row pt-4 px-4 pb-2 m-0 detail-prd-row bg-light">
                                                                    <div class="col-12 text-bold px-0">
                                                                        <h6 class="text-bold">{{ $t('message.DeliveryIndex.delivery_info') }}:</h6>
                                                                    </div>
                                                                </div>
                                                                <!-- Đối tác giao hàng tự tạo -->
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="!entry.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.delivery_code') }}:</div>
                                                                            <div class="col-7 pl-0" :class="(entry.statusUpdate == this.$constant.status_update_delivery_1) ? '' : 'border-bottom-1'">
                                                                                <input v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)" type="text" class="w-100 border-none bg-transparent" v-model="entry.code">
                                                                                <span v-else>{{ entry.code }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.status') }}:</div>
                                                                            <div class="col-7" :class="(entry.statusUpdate != this.$constant.status_update_delivery_1) ? 'px-0' : ''">
                                                                                <SelectSearch v-if="(entry.statusUpdate != this.$constant.status_update_delivery_1)" :vModel="true" v-model="entry.status" :modelValue="entry.status" :defaultOption="listStatusAll" :label="'status_' + entry.id" :readonly="true"></SelectSearch>
                                                                                <span v-else>{{ entry.statusName }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.created_at') }}:</div>
                                                                            <div class="col-7 pl-0" v-text="dt(entry.createdAt)"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.created_by') }}:</div>
                                                                            <div class="col-7 pl-0" v-text="entry.createdBy">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-lg-12 position-relative">
                                                                                <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                                                                                <textarea v-model.trim="entry.note" v-if="entry.statusUpdate != this.$constant.status_update_delivery_1" :id="'note_delivery'+ entry.id" :placeholder="$t('message.DeliveryIndex.note')" class="noteStyle border-bottom-1 bg-transparent px-4 text-gray font-italic"></textarea>
                                                                                <!-- <input type="text" class="w-100 pl-4 border-bottom-1 border-none pb-2 bg-transparent" v-model.trim="entry.note"
                                                                                v-if="entry.statusUpdate != this.$constant.status_update_delivery_1"> -->
                                                                                <span v-text="entry.note" v-else></span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.weight') }}:</div>
                                                                            <div class="col-7">
                                                                                <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                    <span v-text="entry.weight ? n(entry.weight) + ' ' + entry.weightUnitValue : null"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <div class="row m-0 p-0 d-flex align-items-end">
                                                                                        <div class="col-lg-8 px-0 d-flex align-items-end pr-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.weight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="col-lg-4 px-0">
                                                                                            <SelectSearch :vModel="true" v-model="entry.weightUnit" :modelValue="entry.weightUnit" :defaultOption="listWeightUnit" :label="'weightUnit_' + entry.id" :readonly="true"></SelectSearch>
                                                                                        </div>
                                                                                    </div>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.size') }}:</div>
                                                                            <div class="col-7 ">
                                                                                <template v-if="entry.statusUpdate == this.$constant.status_update_delivery_1">
                                                                                    <span v-text=" n(entry.long) + ' x ' + n(entry.width) + ' x ' +  n(entry.hight) + ' cm'"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <div class="row m-0 p-0">
                                                                                        <div class="col-lg-4 px-0 d-flex align-items-end pr-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.long" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="col-lg-4 px-0 d-flex align-items-end pr-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.width" :cardTransaction="true" :bgTransparent="true" class="mb-0"/>
                                                                                        </div>
                                                                                        <div class="col-lg-4 px-0 d-flex align-items-end pr-2">
                                                                                            <InputNumberFormat :pxNone="true" v-model="entry.hight" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                        </div>
                                                                                    </div>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.delivery_by') }}:</div>
                                                                            <div class="col-7">
                                                                                <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                    <span v-text="entry.transporterName"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <SelectSearch :vModel="true" v-model="entry.transporterId" :modelValue="entry.transporterId" :defaultOption="listTransporterSelfCreated" :label="'transporterId_' + entry.id" :areaSearch="true" :readonly="true"></SelectSearch>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.service') }}:</div>
                                                                            <div class="col-7">
                                                                                <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                    <span v-text="entry.service"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <SelectSearch v-model="entry.serviceTypeId" :modelValue="entry.serviceTypeId" :defaultOption="listServiceType" :label="'serviceTypeId_' + entry.id" :readonly="true" :vModel="true"></SelectSearch>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.delivery_time') }}:</div>
                                                                            <div class="col-7 px-0" :class="(entry.statusUpdate == this.$constant.status_update_delivery_1) ? '' : 'border-bottom'">
                                                                                <template v-if="(entry.statusUpdate == this.$constant.status_update_delivery_1)">
                                                                                    <span v-text="dt(entry.deliveryTime)"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <SelectDateTimePicker :modelValue="entry.deliveryTime" v-model="entry.deliveryTime" :borderActive="true" :backgroundColor="'bg-light'"/>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.cod') }}:</div>
                                                                            <div class="col-7 px-0">
                                                                                <label class="container-checkbox">
                                                                                    <b v-text="n(entry.priceCodPayment)" v-if="entry.statusCancel || entry.usingPriceCod"></b>
                                                                                    <input type="checkbox" v-model="entry.usingPriceCod" :readonly="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.priceCodPayment > entry.priceCodPaymentStill)) ? true : false" :disabled="(entry.statusUpdate == this.$constant.status_update_delivery_1 || (entry.priceCodPayment > entry.priceCodPaymentStill)) ? true : false" :checked="entry.usingPriceCod ? true : false">
                                                                                    <span class="checkmark-checkbox"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.cod_still') }}:</div>
                                                                            <div class="col-7 px-0" v-text="entry.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.price') }}:</div>
                                                                            <div class="col-7 px-0">
                                                                                <template v-if="entry.statusUpdate != this.$constant.status_update_delivery_3 && entry.statusUpdate != this.$constant.status_update_delivery_4">
                                                                                    <span  v-text="n(entry.price)"></span>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <InputNumberFormat :pxNone="true" v-model="entry.price" :bgTransparent="true" :cardTransaction="true" class="mb-0"/>
                                                                                </template>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.price_still') }}:</div>
                                                                            <div class="col-7 px-0" v-text="n(entry.priceStill)"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <!-- Đối tác giao hàng tích hợp -->
                                                                <div class="row px-4 pb-4 m-0 detail-prd-row bg-light" v-if="entry.transporterIntegration">
                                                                    <div class="col-4 px-0">
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.delivery_code') }}:</div>
                                                                            <div class="col-7">
                                                                                <span class="text-primary">{{ entry.code }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.status') }}:</div>
                                                                            <div class="col-7">
                                                                                <span>{{ entry.statusName }}</span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.created_at') }}:</div>
                                                                            <div class="col-7 " v-text="dt(entry.createdAt)"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.created_by') }}:</div>
                                                                            <div class="col-7 " v-text="entry.createdBy">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.service') }}:</div>
                                                                            <div class="col-7">
                                                                                <span v-text="entry.service"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.control_code') }}:</div>
                                                                            <div class="col-7">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.weight') }}:</div>
                                                                            <div class="col-7">
                                                                                <span v-text="entry.weight ? n(entry.weight) + ' ' + entry.weightUnitValue : null"></span>
                                                                                <!-- <span v-if="this.listWeightUnit">
                                                                                    <template v-for="(unit, i) in this.listWeightUnit" :key="i">
                                                                                        <span v-if="entry.weightUnit && unit.id == entry.weightUnit">{{ ' ' + unit.name }}</span>
                                                                                    </template>
                                                                                </span> -->
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.size') }}:</div>
                                                                            <div class="col-7 ">
                                                                                <span v-text="n(entry.long) + ' x ' + n(entry.width) + ' x ' + n(entry.hight) + ' cm'"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!-- <div class="row m-0 p-2">
                                                                            <div class="col-lg-12">
                                                                                <i class="fa fa-pencil-alt text-gray mr-2"></i>
                                                                                <span v-text="entry.note"></span>
                                                                            </div>
                                                                        </div> -->
                                                                    </div>
                                                                    <div class="col-4 px-2">
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.conversion_rate') }}:</div>
                                                                            <div class="col-7">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.expected_delivery') }}:</div>
                                                                            <div class="col-7" v-text="dt(entry.expectedDelivery)">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.price_declaration') }}:</div>
                                                                            <div class="col-7">
                                                                                <label class="container-checkbox">
                                                                                    ({{ $t('message.common.productValue') }}: {{n(entry.inSuranceValue)}})
                                                                                    <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.isInSurance ? true : false">
                                                                                    <span class="checkmark-checkbox"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.take_to_post_office') }}:</div>
                                                                            <div class="col-7">
                                                                                <label class="container-checkbox">
                                                                                    <input type="checkbox" class="chkType" :readonly="true" :disabled="true" :checked="entry.isTakePostOffice ? true : false">
                                                                                    <span class="checkmark-checkbox"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.paying_by') }}:</div>
                                                                            <div class="col-4 pr-0">
                                                                                <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.paid_by') }}
                                                                                    <input type="radio" name="fee" :checked="entry.payFeeShip == this.$constant.paid_by_sender || entry.payFeeShip == this.$constant.paid_by_app? true : false" :readonly="true" :disabled="true">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                            <div class="col-3 p-0">
                                                                                <label class="container-radio ml-0">{{ $t('message.InvoiceIndex.tabs.delivery.receive_by') }}
                                                                                    <input type="radio" name="fee" :checked="entry.payFeeShip == this.$constant.paid_by_receiver ? true : false" :readonly="true" :disabled="true">
                                                                                    <span class="checkmark"></span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.attention_when_delivering') }}:</div>
                                                                            <div class="col-7" v-text="entry.note">
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.discount_code') }}:</div>
                                                                            <div class="col-7">
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-4 px-0">  
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.delivery_by') }}:</div>
                                                                            <div class="col-7">
                                                                                <span v-text="entry.transporterName"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.cod') }}:</div>
                                                                            <div class="col-7" v-text="entry.usingPriceCod ? n(entry.priceCodPayment) : 0"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.cod_still') }}:</div>
                                                                            <div class="col-7 " v-text="entry.usingPriceCod ? n(entry.priceCodPaymentStill) : 0"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.total_fare') }}:</div>
                                                                            <div class="col-7" v-text="n(entry.price)"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.price') }}:</div>
                                                                            <div class="col-7">
                                                                                <span  v-text="n(entry.price)"></span>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.price_still') }}:</div>
                                                                            <div class="col-7" v-text="n(entry.priceStill)"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.receipt_paid_the_fee') }}:</div>
                                                                            <div class="col-7" v-text="n(entry.paidByReceiverValue)"></div>
                                                                        </div>
                                                                        <div class="row m-0 p-2">
                                                                            <div class="col-5">{{ $t('message.DeliveryIndex.total_revenue_of_the_recipient') }}:</div>
                                                                            <div class="col-7" v-text="n(entry.amountCollectReceiver)"></div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr class="detail-row bg-white border__body-detail" v-if="deliveryActive === entry.id">
                                                            <td :colspan="fields.length + 1" class="border-top-0">
                                                                <div class="mt-2 pb-4">
                                                                    <div class="row detail-prd-row flex-end mt-4 pr-4">
                                                                        <!-- <button type="button" class="btn btn-primary mx-1" v-if="entry.transporterIntegration">
                                                                            <i class="fas fa-paper-plane ml-1"></i>
                                                                            {{ $t('message.DeliveryIndex.button.support') }}
                                                                        </button> -->
                                                                        <!-- Lưu nhanh -->
                                                                         <AppPermission :permission="this.$permissions.deliveries.update?? '' ">
                                                                            <button type="button" class="btn btn-primary mx-1" @click="quickSave(entry)">
                                                                                <i class="fa fa-save ml-1"></i>
                                                                                {{ $t('message.DeliveryIndex.button.save') }}
                                                                            </button>
                                                                         </AppPermission>
                                                                        <!-- In phiếu -->
                                                                       <AppPermission :permission="this.$permissions.deliveries.print?? '' ">
                                                                            <button type="button" class="btn btn-secondary text-white mx-1" @click.stop="printMultiple(entry.id)" v-if="!entry.statusCancel">
                                                                                <i class="fa fa-print" aria-hidden="true"></i>
                                                                                {{ $t('message.DeliveryIndex.button.print') }}
                                                                            </button>
                                                                       </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.deliveries.cancel?? '' ">
                                                                            <button type="button" class="btn btn-danger mx-1" @click="cancel(entry)" v-if="entry.transporterIntegration && !entry.statusCancel">
                                                                                <i class="fa fa-times" aria-hidden="true"></i>
                                                                                {{ $t('message.DeliveryIndex.button.cancel') }}
                                                                            </button>  
                                                                        </AppPermission>
                                                                        <AppPermission :permission="this.$permissions.deliveries.payment?? '' ">
                                                                            <button type="button" class="btn btn-primary mx-1" @click="paymentAndCreateRefund(entry)" v-if="!entry.statusCancel">
                                                                                <i class="fa fa-calculator ml-1"></i>
                                                                                {{ $t('message.DeliveryIndex.button.payment') }}
                                                                            </button>
                                                                        </AppPermission>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <!-- Lịch sử chuyển hàng Tab -->            
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryReturn">
                                                        <!-- Chi tiết phiếu chuyển hàng -->
                                                        <tr class="detail-row bg-white border__body-detail" v-if="deliveryActive === entry.id" >
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row">
                                                                    <div class="col-lg-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.created_at') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.transporter') }}
                                                                                    </th>
                                                                                    <th scope="col">
                                                                                        {{ $t('message.DeliveryIndex.created_by') }}
                                                                                    </th>
                                                                                    <th scope="col" class="text-right">    
                                                                                        {{ $t('message.DeliveryIndex.column_status') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                           <tbody> 
                                                                                <template v-if="entry.delivery_status.length > 0">                                                                                                                                  
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.delivery_status" :key="index">                                                                                        
                                                                                        <td scope="col" ><span>{{ history?.createdAt ? dt(history.createdAt) : null}}</span></td>
                                                                                        <td scope="col" ><span>{{ entry?.transporterName ? entry.transporterName : null }}</span></td>
                                                                                        <td scope="col" ><span>{{  history?.user_created_by && history.user_created_by?.name ? history.user_created_by.name : null}}</span></td>
                                                                                        <td scope="col" class="text-right"><span>{{ history?.statusName ? history.statusName : null }}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="4" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>
                                                      <!-- Hiển thị tab Lịch sử thanh toán -->
                                                    <template v-if="this.tabActive == this.$constant.tabHistoryPayment">                                                    
                                                        <tr class="detail-row bg-white border__body-detail" v-if="deliveryActive === entry.id">
                                                            <td :colspan="fields.length" class="border-top-0">
                                                                <div class="row mx-0 my-4 pr-4 detail-prd-row">
                                                                    <div class="col-12">
                                                                        <table class="w-100">
                                                                            <thead>
                                                                                <tr class="backgroup__detail table-header border-1">
                                                                                    <th scope="col" style="width:30px !important;">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.code') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table pl-3">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.time') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.created_by') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.type_payment') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.value') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.method') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.status') }}
                                                                                    </th>
                                                                                    <th scope="col" class="checkbox-table">
                                                                                        {{ $t('message.InvoiceIndex.tabs.table.history_payment.total_price') }}
                                                                                    </th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody> 
                                                                                <template v-if="entry?.historyPayment && entry.historyPayment.length > 0">                                                                                                                                       
                                                                                    <tr class="table-header border-1" v-for="(history, index) in entry.historyPayment" :key="index">                                                                 
                                                                                        <td scope="col" style="max-width:10px;">                                                                                          
                                                                                             <span>{{history?.code ? history.code : ''}}</span>
                                                                                        </td>
                                                                                        <td scope="col"><span>{{history?.createdAt ? dt(history.createdAt) : ''}}</span></td>
                                                                                        <td scope="col"><span>{{history?.createdByName ? history.createdByName : ''}}</span></td>
                                                                                        <td scope="col"><span>{{history?.createdByName ? history.typeMoney : ''}}</span></td>
                                                                                        <td scope="col"><span style="width: max-content;">{{history?.createdByName ? history.valueTicket : ''}}</span></td>
                                                                                        <td scope="col"><span>{{history?.methodName ? history.methodName : ''}}</span></td>
                                                                                        <td scope="col"><span>{{history?.statusName ? history.statusName : ''}}</span></td>
                                                                                        <td scope="col pl-4"><span>{{history?.payment ? n(history.payment) : ''}}</span></td>
                                                                                    </tr>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <tr >
                                                                                        <td colspan="6" class="text-center">
                                                                                            {{$t('message.common.notFound')}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </template>                                                                                
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </div>                                                                
                                                            </td>
                                                        </tr>
                                                    </template>

                                                    <tr class="detail-row bg-white border__footer-detail" v-if="deliveryActive === entry.id"></tr>
                                                </template>
                                            </template>
                                            <template v-if="entries.length == 0 && !this.loading">
                                                <tr>
                                                    <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                        <div class="row detail-prd-row mx-0">
                                                            <div class="col-12 text-center text-secondary mt-5">
                                                                <h3>
                                                                    <i class="fa fa-file mr-2 text-secondary"></i>
                                                                    <i class="fa fa-exclamation"></i>
                                                                </h3>
                                                            </div>
                                                            <div class="col-12 text-center my-10 text-secondary mb-5">
                                                                <!-- <p>{{$t('message.common.notFound')}}</p> -->
                                                                <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalSelectPrintTemplate ref="ModalSelectPrintTemplate" @updateContent="updateContent"/>
        <PreviewTemplate ref="PreviewTemplate" :editorDataClone="this.editorDataClone"/>
        <ModalCancel ref="modalCancel" @loadData="load()"/>
        <ModalPaymentAndCreateRefund ref="modalPaymentAndCreateRefund" @reload="load"/>
    </AppPermission>
</template>

<script>
    import {$alert, $get, $post, clone, setArray, getSearchQuery, removeVietnameseTones} from "./../../lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import QSelect from "../../components/QSelect";    
    import SelectField from "../../components/SelectField";
    import store from "../../store";    
    import emitter from "@/lib/emitter";    
    import SelectSearch from "@/components/SelectSearch.vue";
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import DateRangePicker from "@/components/DateRangePicker.vue";
    import InputSelectSearch from "@/components/forms/InputSelectSearch.vue";    
    import deliveriesForSupplierMixin from "@/deliveriesForSupplierMixin";
    import AppPermission from '@/components/AppPermission.vue';    
    import ModalSelectPrintTemplate from '@/components/ModalSelectPrintTemplate';
    import PreviewTemplate from '@/components/printTemplate/PreviewTemplate.vue';
    import FieldsExportInvoice from "../../assets/exportFields/fieldsExportInvoice";
    import {explode, isEmpty} from "../../lib/utils";
    import moment from 'moment';
    import router from "@/router/index";
    import http from '@/services/api';    
    import {numberFormat} from "@/lib/utils";
    import printTransactionMixin from "@/printTransactionMixin";
    import exportMixin from "@/exportMixin";        
    import SelectDateTimePicker from "@/components/SelectDateTimePicker";
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputText from "@/components/forms/InputText";
    import ModalCancel from "./ModalCancel.vue";
    import ModalPaymentAndCreateRefund from "./ModalPaymentAndCreateRefund.vue";
    export default {
        name: "DeliveriesIndex",
        // mixins: [printTransactionMixin, exportMixin, deliveriesForSupplierMixin],
        mixins: [exportMixin, deliveriesForSupplierMixin],
        components: {
            SelectField, 
            InputDateRangePicker, 
            TableSortField, 
            Pagination, 
            QSelect, 
            SelectSearch, 
            InputQuickSearch, 
            DateRangePicker, 
            SelectDateTime, 
            InputSelectSearch, 
            AppPermission,
            ModalSelectPrintTemplate,
            PreviewTemplate,
            SelectDateTimePicker,
            InputNumberFormat,
            ModalCancel,
            ModalPaymentAndCreateRefund,
            InputText
        },
        data() {
            const query = getSearchQuery();            
            const globalConfig = store.state.GlobalConfig;
            return {
                tabActive: this.$constant.tabInformation,                
                init: {
                    fieldsExport: []
                },
                filter: {
                    delivery_code: '',
                    control_history: '',
                    code_name_phone: '',
                    code_invoice: '',
                    note_delivery: '',
                },
                filterQuery: {
                    status: [],
                    statusCod: '',
                    createdAt: '',
                    completedAt: '',
                    transporter: '',
                    area_delivery: '',
                    cod: '',
                    branchId: '',
                },
                listStatus: [],
                listStatusAll: [],
                listStatusCod: [],
                fields: [],
                entries: [],
                checkedAll: false,
                numberRecord: 15,
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth ? store.state.Auth.user : {},
                currentBranchId: store.state.CurrentBranch.id,
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paymentMethods: store.state.GlobalConfig.listPaymentMethod,
                listTransporters: store.state.GlobalConfig.listTransporter,
                listWeightUnit: store.state.GlobalConfig.listWeightUnit,
                listAreaDelivery: [],
                listWards: [],
                listTransporterSelfCreated: [],
                listServiceType: store.state.GlobalConfig.listServiceType,
                saleChannel: {
                    filter: globalConfig.saleChannels,
                    all: globalConfig.saleChannels,
                    search: null,
                },
                users: globalConfig.users,
                arrayChecked: [],
                arrayChildChecked: [],
                loading: false,
                deliveryActive: null,
                sortTime: 0,
                soldById: null,
                isFilter: false,
            }
        },
        mounted() {
            document.title = this.$t('message.DeliveryIndex.title_document');
            const fields = localStorage.getItem('deliveriesSelectedFields_' + this.user.id);
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = clone(this.$route.query);
            if(query['code'] != null){
                this.filterQuery['delivery_code'] = query['code'];
                this.filterQuery['statusCod'] = this.$constant.status_cod_all;
                this.$router.replace({params: {}})
                this.loadDetail();
            }else if(query['id'] != null){
                this.filterQuery['delivery_id'] = query['id'];
                this.filterQuery['statusCod'] = this.$constant.status_cod_all;
                this.$router.replace({params: {}})
                this.loadDetail();
            }else{
                this.clearFilter();
            }
        },
        created() {
            this.getAreaDelivery();
            this.getTransporterSelfCreated();
        },
        methods: {
            formatInputNumber(evt){
                if(["e", "E", "+", "-"].includes(evt.key)){
                    evt.preventDefault();
                }
            },
             async exOverview() {      
                emitter.emit("onLoading");
                const res = await http.download('/v1/deliveries/ex-overview', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});          
                this.returnDataExport(res);                
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/deliveries/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(key == 'status'){
                        this.filterQuery[key] = [];
                    }else if(key == 'statusCod'){
                        this.filterQuery[key] = this.$constant.status_cod_all;
                    }else if(key == 'branchId'){
                        this.filterQuery[key] = this.currentBranchId;
                    }else{
                        this.filterQuery[key] = '';
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.arrayChildChecked = [];
                this.numberRecord = 15;
                this.sortTime = 0;
                this.deliveryActive = null;
                this.isFilter = false;
                await this.load();
            },
            async loadDetail(){
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                await this.load();
                this.entries.forEach((entry) => {
                    this.detailDelivery(entry.id, entry);
                });
            },
            async load() {
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/deliveries/get-deliveries', {...this.$route.query, ...this.filterQuery, record: this.numberRecord});               
                if (res.result.code !== 200) {
                    $alert(res);
                    return;
                }
                if (!this.fields || !this.fields.length) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;

                if (res.result.data.listStatus) {
                    this.listStatus = res.result.data.listStatus;
                }
                if (res.result.data.listStatusAll) {
                    this.listStatusAll = res.result.data.listStatusAll;
                }
                if (res.result.data.listStatusCod) {
                    this.listStatusCod = res.result.data.listStatusCod;
                }
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async getAreaDelivery(){
                const res = await $get('/v1/deliveries/get-area-delivery');
                if(res && res.result){
                    this.listAreaDelivery = res.result;
                }
            },
            async getTransporterSelfCreated(){
                const res = await $get('/v1/transporters/get-list-transporter-self-created');
                if(res && res.result){
                    this.listTransporterSelfCreated = res.result;
                }
            },
            async changeDistrict(entry) {
                const res = await $get('/v1/locations/wards', {districtId: entry.districtId});
                this.listWards = res.data;
            },
            setAreaDelivery(newData){
                let index = this.entries.findIndex(entry => entry.id == this.deliveryActive);
                if(this.entries[index] && newData && newData.provinceId){
                    this.entries[index].customer_address.provinceId = newData.provinceId;
                }
            },
            doFilter(field, value, entry = null) {
                const query = clone(this.filterQuery);
                if(field == 'status'){
                    if(query['status'].includes(value)){
                        query['status'] = query['status'].filter(item => item !== value);
                        if(entry && entry.children){
                            entry.children.forEach(child => {
                                query['status'] = query['status'].filter(item => item !== child.id);
                            })
                        }
                    }else{
                        query['status'].push(value);
                        if(entry && entry.children){
                            entry.children.forEach(child => {
                                const foundChild = this.arrayChecked.find(item => item === child.id);
                                if(!foundChild){
                                    query['status'].push(child.id);
                                }
                            })
                        }
                    }
                }else{
                    query[field] = value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            toggleChildren(value){
                if(this.arrayChildChecked.includes(value)){
                    this.arrayChildChecked = this.arrayChildChecked.filter(item => item !== value)
                }else{
                    this.arrayChildChecked.push(value);
                }
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };
                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;
                await this.$router.push({query: query});
                this.load();
            },
            pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model != null){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async detailDelivery(id, entry){
                if (id === this.deliveryActive) {
                    this.deliveryActive = null;
                } else {
                    this.deliveryActive = id;
                    if(entry.customer_address){
                        await this.changeDistrict(entry.customer_address);
                    }
                    await this.load();
                }
                this.tabActive = this.$constant.tabInformation;
            },
            changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            showContent(tab){            
                this.tabActive = tab;
            },

            // In phiếu
            // -------------------------------------------------------------------------------------------------------------
            async printMultiple(id) {
                this.editorDataClone = '';
                const res = await $post('/v1/deliveries/get-deliveries-by-id', {
                    id: id
                });                
                if (res.result && res.result.code == 200) {                
                    await this.getTemplatePrint(res.result.data);
                }
            },
            async print(entry) {
                entry.typePrint = this.$constant.TypePrintDeliveries;                 
                let dataPrint = await this.dataPrintDelivery(entry);                 
                this.editorDataClone = await this.renderDataDelivery(dataPrint, this.content);                
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone);   
            },
            async getTemplatePrint(entry) {
                const res = await $get(`/v1/print-templates/get-content-print-cli`,{action: this.$constant.TabDeliveries});                
                if(res && res.status.code == 200){
                    if(res.result) {  
                        this.$refs.ModalSelectPrintTemplate.show(res.result, entry);
                    }
                }  
            },
            async updateContent(data){              
                this.content = data.content;                
                if (data.dataPrint && Array.isArray(data.dataPrint) ) {
                    await this.startPrintMultiple(data.dataPrint);
                } else {                 
                    await this.print(data.dataPrint);          
                }    
            },
            async startPrintMultiple(invoices) { 
                let breakPage = `<div style="page-break-before: always"></div>`
                let dataPrint = null;
                for (let index = 0; index < invoices.length; index++) {
                    let invoice = invoices[index];
                    invoice.typePrint = this.$constant.TypePrintDeliveries;
                    dataPrint = await this.dataPrintDelivery(invoice);
                    let invoiceItem = await this.renderDataDelivery(dataPrint);
                    if (index != invoices.length -1 ) {
                        this.editorDataClone = this.editorDataClone + invoiceItem + breakPage;
                    } else {
                        this.editorDataClone = this.editorDataClone + invoiceItem;
                    }
                }
                this.$refs.PreviewTemplate.multiplePrint(this.editorDataClone); 
            },         

            linkToCustomer(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'CustomerIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToInvoice(code){
                var route = null;
                route = this.$router.resolve({
                    name: 'InvoiceIndex',
                    query: {
                        'code': code,
                    }
                });
                window.open(route.href, '_blank');
            },
            async quickSave(entry){
                let data = {
                    "id": entry.id ?? null,
                    "code": entry.code ?? null,
                    "note": entry.note ?? null,
                    "invoice_description": entry.invoice && entry.invoice.description ? entry.invoice.description : null,
                    "status": entry.status ?? null,
                    "weight": entry.weight ?? null,
                    "weightUnit": entry.weightUnit ?? null,
                    "hight": entry.hight ?? null,
                    "long": entry.long ?? null,
                    "width": entry.width ?? null,
                    "transporterId": entry.transporterId ?? null,
                    "serviceTypeId": entry.serviceTypeId ?? null,
                    "deliveryTime": entry.deliveryTime ?? null,
                    "usingPriceCod": entry.usingPriceCod ?? null,
                    "price": entry.price ?? null,
                    "receiver_info": entry.customer_address ?? null,
                }
                const res = await $post('/v1/deliveries/quick-save', {...data});
                if(res.result && res.result.status){
                    $alert({code: 200, message: this.$t('message.DeliveryIndex.alert.update_success')});
                    this.load();
                }
                if(res.result && !res.result.status && res.result.message){
                    $alert({code: 500, message: res.result.message});
                }
            },
            cancel(entry){
                entry.deliveryCode = entry.code;
                this.$refs.modalCancel.show(entry);
            },
            paymentAndCreateRefund(entry){
                let data = {...entry};
                if(!data.usingPriceCod){
                    data.priceCodPaymentStill = 0;
                }
                if(!data.priceCodPaymentStill && !data.priceStill){
                    $alert({code: 422, message: this.$t('message.DeliveryIndex.alert.payment_error')});
                    return;
                }
                this.$refs.modalPaymentAndCreateRefund.show(data);
            }
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
#noteRO {
    border-left: 1px solid #ccc;
}
.noteStyle {
    width: 100% !important;
    height: 120px !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.icon-plus {
    position: absolute;
    right: 10%;
    top: 0px;
    color: gray;
}
</style>
