<template>
    <div class="row mt-3" v-if="isDataLoaded">
        <div class="col-lg-12" >
            <table class="table__mini">
                <thead>
                <tr>
                    <th>{{ $t('message.common.branch') }}</th>
                    <th class="text-center" :colspan="productUnits.length">{{ $t('message.common.inventorySystem') }}</th>
                    <th class="text-center" :colspan="productUnits.length">{{ $t('message.common.inventory') }}</th>
                    <th class="text-center" :colspan="productUnits.length">{{ $t('message.ProductUnitSearch.orderFromCustomer') }}</th>
                    <th class="text-center">{{ $t('message.ProductIndex.table.inventory.expected_out_of_stock') }}</th>
<!--                    <th class="text-center">{{$t('message.common.status')}}</th>-->
                </tr>
                </thead>
                <tbody>
                    <template v-if="productUnits.length">
                        <tr >
                            <td></td>
                            <th class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{pu.unitName}}
                            </th>
                            <th class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{pu.unitName}}
                            </th>
                            <th class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{pu.unitName}}
                            </th>
                            <td></td>
<!--                            <td></td>-->
                        </tr>
                        <tr >
                            <td></td>
                            <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{sumQty(pu.conversionValue)}}
                            </td>
                            <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{sumActualQty(pu.conversionValue)}}
                            </td>
                            <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                                {{ sumCustomerOrder(pu.conversionValue)}}
                            </td>
                            <td></td>
<!--                            <td></td>-->
                        </tr>
                    </template>
                    <tr v-for="inventory in inventories" :key="inventory.id">
                        <td  v-text="inventory.branch.name"></td>
                        <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                            {{inventory.quantity/pu.conversionValue}}
                        </td>
                        <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                            {{intVal(actualInv[inventory.branch?.id] ?? 0)/pu.conversionValue}}
                        </td>
                        <td class="text-center" v-for="pu in productUnits" :key="pu.id">
                            {{ ((actualInv[inventory.branchId] ?? 0) - inventory.quantity)/pu.conversionValue}}
                        </td>
                        <td class="text-center"></td>
<!--                        <td class="text-center"></td>-->
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import $ from 'jquery';
import {intVal} from "@/lib/utils";

export default {
    name: "ProductFormTabInventory",
    data() {
        return {
            isDataLoaded: false,
            inventories: [],
            actualInv: [],
            productUnits: []
        }
    },
    methods: {
        intVal,
        sumQty(conversionValue) {
            let value = 0;

            this.inventories.forEach(iv => {
                value += iv.quantity/conversionValue;
            });

            return value;
        },
        sumActualQty(conversionValue) {
            let value = 0;

            $.each(this.actualInv, function (branchId, qty ) {
                value += qty/conversionValue;
            });

            return value;
        },
        sumCustomerOrder(conversionValue) {
            return (this.sumActualQty(conversionValue) - this.sumQty(conversionValue)).toFixed(1);
        }
    }
}
</script>


