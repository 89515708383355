<template>
    <div class="container px-0 d-flex" v-if="hasPermission(this.$permissions.dashboard) || this.userId == this.$constant.isAdmin">        
        <div class="col-md-9">
            <StatisticalInvoice></StatisticalInvoice>
            <!-- biểu đồ doanh thu -->
            <RevenueChart />
            <RevenueFromBranch />
            <div class="d-row g-4 mb-4">
               <ProductChart />
            </div>
        </div>
        <div class="col-md-3">
            <Notice></Notice>
        </div>

    </div>
</template>
<script>
import { LineChart, PieChart } from 'vue-chart-3';
import Pagination from '../components/dashboard/Pagination.vue';
import ProductChart from '../components/dashboard/ProductStatistical.vue';
import RevenueChart from '../components/dashboard/RevenueChart.vue';
import RevenueFromBranch from '@/components/dashboard/RevenueFromBranch.vue';
import StatisticalInvoice from '@/components/dashboard/StatisticalInvoice.vue';
import router from "@/router/index";
import permissionMixin from "@/permissionMixin";
import Notice from '@/components/dashboard/Notice.vue';
// import "../assets/plugins/fontawesome/js/all.min.js"
import Config from '../config';
import store from '@/store';
const options = {
    responsive: true,
    maintainAspectRatio: false,
    tooltips: {
        enabled: true
    },
    plugins: {
        datalabels: {
            formatter: (value, ctx) => {
                let sum = ctx.dataset._meta[0].total;
                let percentage = (value * 100 / sum).toFixed(2) + "%";
                return percentage;
            },
            color: '#fff',
        }
    }
}

export default {
    name: 'Dashboard',
    components: {
        LineChart,
        Pagination,
        PieChart,
        ProductChart,
        RevenueChart,
        RevenueFromBranch,
        StatisticalInvoice,
        Notice,
    },
    mixins: [permissionMixin],
    data: () => ({
        userId: store.state.Auth.user.id,
        statistic: { amount: 0, order: 0, invoice: 0, return: 0, customer: 0 },
        years: [],
        months: [],
        orderSelectValues: ['This week', 'This month', 'This year'],
        loaded: false,
        orderLineChartData: {},
        orderLineChartSelectedValue: 'week',
        selectLineChartValue: null,
        options: options,
        saleChannelSelectValues: ['This week', 'This month', 'This year'],
        saleChannelData: {},
        selectSaleChannelValue: null,
        saleChannelSelectedValue: 'week',
        consignments: null,
        activeCustomers: null
    }),
    computed: {

    },

    watch: {
        selectLineChartValue: function (value = "This week") {
            var type = 'week';
            if (value == 'This month') {
                type = 'month'
            } else if (value == 'This year') {
                type = 'year'
            }
            this.loadLineChartOrder(type)
        },
        selectSaleChannelValue: function (value = "This week") {
            var type = 'week';
            if (value == 'This month') {
                type = 'month'
            } else if (value == 'This year') {
                type = 'year'
            }
            this.loadSaleChannel(type)
        },
    },
    methods: {
        getDataPermissionsName(){
            let lstPermissionName = [];
            let permissions = store.state.PermissionInfo.permissions;
            permissions.forEach(per => {
                lstPermissionName.push(per.name);
            });
            return lstPermissionName;
        },        
        onPageChange(page = 1) {
            this.list(page)
        },
        onActiveCustomerPageChange(page = 1) {
            this.loadActiveCustomer(page)
        },
        async list(page = 1) {
            var url = Config.Report.ConsignmentExpired;
            url = this.appendUrl(url, 'page', page);
            var cached = sessionStorage.getItem(url)
            if (cached) {
                this.consignments = JSON.parse(cached)
            } else {
                const res = await fetch(url);
                const json = await res.json();
                this.consignments = json;
                sessionStorage.setItem(url, JSON.stringify(json));
            }
        },
        async loadActiveCustomer(page = 1) {
            var url = Config.Report.ActiveCustomer;
            url = this.appendUrl(url, 'page', page);

            var cached = sessionStorage.getItem(url)
            if (cached) {
                this.activeCustomers = JSON.parse(cached)
            } else {
                const res = await fetch(url);
                const json = await res.json();
                this.activeCustomers = json;
                sessionStorage.setItem(url, JSON.stringify(json));
            }
        },
        async loadLineChartOrder(value = 'week') {
            var url = Config.Report.InvoiceAmount
            url = this.appendUrl(url, 'type', value)
            var cached = sessionStorage.getItem(url)
            if (cached) {
                this.orderLineChartData = JSON.parse(cached)
            } else {
                const res = await fetch(url)
                const json = await res.json()
                this.orderLineChartData = json
                sessionStorage.setItem(url, JSON.stringify(json));
            }
        },
        async loadSaleChannel(value = 'week') {
            var url = Config.Report.InvoiceSaleChannel;
            url = this.appendUrl(url, 'time', value);
            var cached = sessionStorage.getItem(url);
            if (cached) {
                this.saleChannelData = JSON.parse(cached);
            } else {
                const res = await fetch(url);
                const json = await res.json();
                sessionStorage.setItem(url, JSON.stringify(json));
                this.saleChannelData = json;
            }
        },
        onChangeSaleChanelValue(event) {
            this.selectSaleChannelValue = event.target.value
        },
        onChangeLineCharValue(event) {
            this.selectLineChartValue = event.target.value
        },
        async loadStatistic() {
            const res = await fetch(Config.Report.Statistic)
            const json = await res.json()
            this.statistic = json
        },
        appendUrl(url, key, value) {
            if (!value) return url;
            if (url.endsWith('?')) return url += key + '=' + value
            else return url += '&' + key + '=' + value
        }

    },
    async mounted() {        
        try {
            let status = this.hasPermission(this.$constant.permissionsDasboard);
            if(status) {
                this.loadStatistic()
                this.loadLineChartOrder()
                this.loadSaleChannel()
                this.list(1)
                this.loadActiveCustomer()                
            } else {
                let lstPermissionName = this.getDataPermissionsName();
                let routerName = this.$constant.routerName;
                  routerName.forEach(route => {
                    if(lstPermissionName.includes(route.code)) {                        
                        router.push({ name: route.name});  
                    } else {                        
                        router.push({ name: 'Home'});  
                    }
                });
                
            }

        } catch (e) {
            console.error(e)
        }
    }
}
</script>


<style scoped src="@/assets/css/portal.css">
/* @import '../assets/css/portal.css'; */
</style>
