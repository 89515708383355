<template>
    <div class="tab-navigation" :class="template ? 'w-100' : ''">
        <ul class="nav nav-tabs">            
            <li class="nav-item" v-for="tab in tabs" :key="tab.id">
                <a class="nav-link" @click.prevent="changeTab(tab)" :class="{active: tab.id === currentTabId}"
                   href="">{{ tab.name }}                    
                </a>
               
            </li>
        </ul>
        <div class="tab-navigation-content pt-3" >
            <keep-alive>
                <component ref="currentTab" @onValidate="onValidate" @print="onPrint()" :is="currentTab.component" :id="id" :type="this.typeEditAdd"></component>
            </keep-alive>
        </div>
    </div>
</template>

<script>
import {clone, forEach, isEmpty} from "@/lib/utils";

    export default {
        name: "TabNavigation",
        props: ['tabs', 'id', 'template', 'action'],
        mounted() {
            this.$emit('tabChange',   this.currentTab);
        },
        methods: {
            changeTab(tab) {
                this.$emit('draftTabData', {data: this.$refs.currentTab, id: this.currentTabId})
                this.currentTab = tab;
                this.currentTabId = tab.id;
                location.hash = tab.id;
                this.$emit('tabChange', tab)
            },
            onValidate() {
                this.$emit('onValidate')
            },
            getTabData(key) {
                
                return this.$refs.currentTab[key];
            },
            onPrint() {
                this.$emit('onPrint');
            },
            setTabData(key, value) {
                const keyType = typeof key;

                if (keyType === 'object') {
                    forEach(key, (v,k) => {
                        this.$refs.currentTab[k] = v;
                    });
                } else {
                    if (keyType !== 'string') {
                        throw new Error('TabNavigation.setTabData: key must be a string or object')
                    }

                    this.$refs.currentTab[key] = value;
                }
            },
            setFirstTab(){
                this.currentTabId = this.tabs[0].id;
                this.currentTab = this.tabs[0];
                this.$emit('tabChange', this.tabs[0])
            }
        },
        data() {
            if (!Array.isArray(this.tabs)) {
                throw new Error('TabNavigation: props.tabs is invalid');
            }

            if (this.tabs.length === 0) {
                throw new Error('TabNavigation: tabs is empty')
            }

            this.tabs.forEach(tab => {
                if (!tab.id) {
                    throw new Error('TabNavigation: Missing tab.id')
                }

                if (!tab.name) {
                    throw new Error('TabNavigation:Missing tab.name')
                }

                if (!tab.component) {
                    throw new Error('TabNavigation:Missing tab.component')
                }
            });

            let currentTabId = location.hash.substr(1);
            let current = this.tabs.find(tab => tab.id === currentTabId);
            

            return {
                currentTabId: current ? current.id : this.tabs[0].id,
                currentTab: current ? current : this.tabs[0],
                typeEditAdd: null
            }
        },
        watch: {
            action(newAction){
                this.typeEditAdd = newAction;
            }
        }
    }
</script>

<style scoped>
.tab-navigation-content {
    margin-top: 5px;
}
.tab-navigation {
    margin-bottom: 5px;
}

.tab-navigation .nav-link {
    text-decoration: none;
    color: #000;
}
.tab-navigation .nav-link.active {
    font-weight: bold;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  border: 0;
  border-bottom: 2px solid #008080;
}
.nav-tabs .nav-link.active{
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
}
</style>
