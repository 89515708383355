<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h6 class="modal-title m-2">
                        {{ $t('message.PriceListsIndex.modal.title') }}
                    </h6>
                    <button type="button" class="close" @click="resetStatus" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body pt-0 px-3">
                    <div class="form-check m-2 p-0 pb-3 border-bottom-1">
                        <span class="" style="font-weight: 400;"> 
                            ({{ $t('message.PriceListsIndex.modal.download_file') }}:
                            <a target="_blank" :href="'../template_bang_gia_' + this.language + '.xlsx'" class="text-primary">{{$t('message.ModalFileUploadProducts.titleExcel')}}</a>):
                        </span> 
                    </div>
                    <div class="form-check m-2 pt-2 mt-4">
                        <div class="col-12 d-flex align-items-center">
                            <label class="container-radio ml-0">{{ $t('message.PriceListsIndex.modal.alert_error_status') }}
                                <input type="radio" name="option" v-model="patient.option" :value="this.$constant.status_alert_error_import" :checked="true" class="mr-2">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="form-check m-2 pt-2">
                        <div class="col-12 d-flex align-items-center">
                            <label class="container-radio ml-0">{{ $t('message.PriceListsIndex.modal.update_status') }}
                                <input type="radio" name="option" v-model="patient.option" :value="this.$constant.status_update_value_import" class="mr-2">
                                <span class="checkmark"></span>
                            </label>
                        </div>
                    </div>
                    <div class="row my-3 mx-2">
                        <div class="col-12 text-right mx-0 px-0" id="divUpload">
                            <label class="custom-file-upload">
                                <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" />
                                <i class="fa fa-cloud-upload"></i> 
                                {{ $t('message.PriceListsIndex.modal.button') }}
                            </label>
                        </div>
                    </div>
                    <div class="row py-2 mx-2" id="rowMessage" v-if="this.actionShow">
                        <div class="col-6">
                            <label>{{ this.fileName }}</label>
                        </div>
                        <div class="col-6 text-danger text-right">
                            <a href="javascript:;" @click="showAction(false)" class="text-danger">
                                <i class="fa fa-times"></i>
                            </a>
                        </div>
                    </div>
                    <div class="alert alert-danger row py-2 m-2" v-show="this.messageFile">
                        {{ this.messageFile }}
                    </div>
                    <div class="row mt-3 mx-2">
                        <div class="col-12 text-right px-0 mx-0 mb-3" v-if="this.showBtn">
                            <input type="button" class="btn btn-primary" 
                            :value="$t('message.PriceListsIndex.modal.button_action')" 
                            id="btnAction" @click="UpdateFile(this.patient)" />
                        </div>
                    </div> 
                </div>

            </div>
        </div>
    </div>
    <ModalErrorsMessageImport ref="ModalErrorsMessageImport" :update="true"/>
</template>
<script>
    import { $post, $upload} from "@/lib/utils";
    import toastr from 'toastr';
    import emitter from "@/lib/emitter";
    import store from "@/store";
    import ModalErrorsMessageImport from '../order_suppliers/ModalErrorsMessage.vue';
    export default {
        name: "ImportPriceLists",
        props: {},
        components: {ModalErrorsMessageImport},
        data () {
            return {
                actionShow: false,
                showBtn: false,
                fileName: "",
                patient: {
                    file: [],
                    branchID: '',
                    option: false,
                },
                errors: [],
                messageFile: '',
                language: localStorage.getItem('language') ?? 'vi'
            }
        },
    methods: {
        show() {
            this.showAction(false);
            this.showBtn = false;
            this.patient.option = this.$constant.status_alert_error_import;
            window.jQuery(this.$refs.modal).modal('show');
        },
        showAction(status) {
            this.actionShow = status;
            this.showBtn = status;
            if (status == false) {
                this.$refs.fileupload.value = '';
                this.messageFile ='';
            }
        },
        onFileChange(event) {   
            this.patient = {
                option: this.patient.option,
                file: "",
            };
            var files = event.target.files || event.dataTransfer.files;
            if (!files.length) {  
                return;
            }
            this.patient.branchID = store.state.CurrentBranch.id;
            this.fileName = files[0].name;
            this.patient.file = files[0];
        
            if(files[0].name == "" ) {
                this.actionShow = false;  
                this.messageFile ='';       
            }
            else {
                this.actionShow = true;
                this.messageFile ='';
            }
            if (files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || files[0].type === 'application/vnd.ms-excel') {
                this.showBtn = true;
                this.messageFile ='';
            }else {
                this.showBtn = false;
                this.messageFile = this.$t('message.PriceListsIndex.modal.alert_error_validate')
            }   
        },
        async UpdateFile(file) {
            emitter.emit("onLoading");
            const res = await $upload('/v1/price-lists/import', [], file);
            emitter.emit("errMsgShow", true);
            emitter.emit("fileName", this.fileName);
            if (res.result.code == 200) {
                emitter.emit("dataMsgShow", res.result);
                this.$emit('reload');
            }
            if (res.result.code == 422) {
                emitter.emit("dataMsgShow", res.result);
            } 
            window.jQuery(this.$refs.modal).modal("hide");
            emitter.emit("offLoading");
        }
    }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    input[type="file"] {
        display: none;
    }
    
    #btnAction{
        width: 100px;
    }
    #rowMessage{
        background-color: rgb(224 241 241);
        border-radius: 5px;
    
    }
    .custom-file-upload {
        border: 1px solid #ccc;
        display: inline-flex;
        padding: 6px 12px;
        cursor: pointer;
        background: $base-color;
        color: #ccffff;
        margin-left: 5px;
        height: 40px;
        border-radius: 5px;
        align-items: center;
    }
    
    .container input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    
    .modal-dialog {
        max-width: 800px !important;
    }
    
    .container-circle {
        position: relative;
    }
    
    .icon-circle-custom {
        cursor: pointer;
    }
    
    .content-circle {
        display: none;
        position: absolute;
        font-size: 13px;
        width: 310px;
        border: 1px solid $base-color;
        padding: 5px;
        background-color: #ffffff;
        top: 0;
        left: 45px;
        z-index: 1;
        border-radius: 5px;
    }
    
    .content-circle:before {
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent $base-color transparent transparent;
        position: absolute;
        left: -16px;
    }
    
    .icon-circle-custom:hover+.content-circle {
        display: block;
    }
    
    .status-circle {
        display: none;
        position: absolute;
        font-size: 13px;
        width: 310px;
        border: 1px solid $base-color;
        padding: 5px;
        background-color: #ffffff;
        top: -5px;
        left: 257px;
        z-index: 1;
        border-radius: 5px;
        line-height: 25px;
        text-align: justify;
    }
    
    .status-circle:before {
        content: "";
        border-style: solid;
        border-width: 10px 15px 10px 0;
        border-color: transparent $base-color transparent transparent;
        position: absolute;
        left: -16px;
    
    }
    
    .icon-circle-custom:hover+.status-circle {
        display: block;
    }
    .warning-content {
        background-color: #f9f9e0;
        border-color: #faebcc;
        color: #7b5e2a;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        padding: 10px;
        border: 1px solid transparent;
        font-style: italic;
    }
</style>