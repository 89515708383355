<template>
	<div class="row m-0 p-0 mb-4">
		<div class="col-12">
			<h6 style="font-weight: 600">{{$t('message.common.templateKeys')}} {{ this.title }}</h6>
		</div>
		<template v-for="(entry, index) in this.entries" :key="index">
			<div class="col-6">
				<div class="row p-0 mt-3">
					<div class="col-6 text-bold" v-text="'{' + entry.code + '}'"></div>
					<div class="col-6" v-text="entry.name"></div>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import { $get } from "@/lib/utils";
export default {
	name: "ModalEditPdfInformation",
	props: ["entries"],
	data() {
		return {
			title: null,
		};
	},
	mounted() {
	},
	methods: {},
};
</script>

<style>
ul li {
	list-style: none;
	min-height: 14px;
}

.titleFr {
	font-weight: 600;
	padding-right: 10px;
}

.right-print,
.titleFr {
	font-size: 15px;
}
</style>
