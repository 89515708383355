<template>
    <div class="row mt-3">
        <div class="col-lg-12">
            <div class="form-group">
                <label>{{ $t('message.ProductIndex.modal.title_create_combo') }}</label>
                <QSelect v-model="searchModel"
                         @onselected="onSelected"
                         datasource="productUnits" :placeholder="$t('message.ProductIndex.modal.search')" :readonly="this.haveTransaction" :hiddenOptionValues="true"/>
            </div>
        </div>
        <div class="col-lg-12">
            <table class="table table-index table-striped">
                <thead>
                    <tr>
                        <td>
                            {{$t('message.ProductIndex.code')}}
                        </td>
                        <td>
                            {{$t('message.ProductIndex.table.combo.component_row_name')}}
                        </td>
                        <td>
                            {{$t('message.ProductIndex.quantity')}}
                        </td>
                        <!-- <td>Giá vốn</td>
                        <td>{{$t('message.DetailOrdersCard.totalPrice')}}</td> -->
                        <td></td>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(product,index) in comboProducts" :key="product.id">
                        <td v-text="product.code"></td>
                        <td v-text="product.name"></td>
                        <td>
                            <!-- <InputNumber :min="1" @update:modelValue="value => updatedQuantity(product, value)" v-model="product.quantity" :show-adjust="true"/> -->
                            <InputNumber :min="1" v-model="product.quantity" :show-adjust="true" :wrapperClass="true" :readonly="this.haveTransaction"/>
                        </td>
                        <td>
                            <button type="button" @click="!this.haveTransaction ? remove(index, product) : null" class="btn btn-danger btn-sm" v-if="this.$route.query.action!='detail'">
                                {{$t('message.ProductIndex.button.remove')}}
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import QImage from "../../../components/QImage";
import QSelect from "../../../components/QSelect";
import InputNumber from "../../../components/forms/InputNumber";
import {clone} from "../../../lib/utils";
import emitter from "@/lib/emitter";

export default {
    name: "ProductFormTabProductCombo",
    components: {InputNumber, QSelect, QImage},
    data() {
        return {
            searchModel: null,
            comboProducts: [],
            haveTransaction: false,
        }
    },
    created() {
        emitter.on("clearCombo", () => {
            this.searchModel = null;
            this.comboProducts = [];
            this.haveTransaction = false;
        });
    },
    methods: {
        remove(index, product) {
            if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                return;
            }

            this.comboProducts.splice(index, 1);
        },
        updatedQuantity(product, qty) {
            product.amount = qty*product.capitalPrice;
        },
        onSelected(item) {
            const found = this.comboProducts.find(product => product.productUnitId === item.productUnitId);
            if(!found){
                const product = {};
                product.quantity = 1;
                product.productUnitId = item.productUnitId;
                product.productId = item.productId;
                product.name = item.name;
                product.code = item.code;
                this.comboProducts.push(product);
            }
        }
    }
}
</script>

<style scoped>

</style>
