<template>
    <div class="search-box position-relative custom__input" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input text-overflow-ellipsis" :placeholder="$t('message.headers.customers')"
               v-model.trim="keyword" @input="onSearchCustomer" :readOnly="read"/>
        <AppPermission :permission="this.$permissions.customers.create?? '' ">
            <i v-if="!keyword" class="fa fa-plus px-2"  @click="onCreateCustomer" :readOnly="read"></i>
           
        </AppPermission>
         <i v-if="keyword" class="fa fa-times px-2"  @click="clearCustomer"></i>
        <div class="popup-supplier-search" v-if="customers && customers.length">
            <ul class="wrap-products">
                <li v-for="customer in customers" :key="customer.id" @click="onSelect(customer)">
                    <div class="product-info">
                        <span class="branch__name">{{ customer.name }}</span>
                    </div>
                </li>
            </ul>
            <!-- <div v-else class="products-not-found">
                <span>{{$t('message.common.notFound')}}</span>
            </div> -->
        </div>
    </div>
    <ModalAddInfoCustomer ref="ModalAddInfoCustomer" :action="$t('message.customerIndex.modal.titleCreate')" @updated="selectCustomer"/>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import AppPermission from './AppPermission.vue';
    import ModalAddInfoCustomer from '@/components/customers/ModalAddInfoCustomer';
    let app;

    export default {
        name: "CustomerSearch",
        emits: ['select', 'selectCustomer', 'clearCustomer'],
        components: {AppPermission, ModalAddInfoCustomer},
        props: ['customerName', 'readCustomer'],
        data() {
            app = this;
            return {
                customers: [],
                keyword: this.customerName,
                read: this.readCustomer ?? false,
            }
        },
        methods: {
            selectCustomer(v) {  
                this.$emit('selectCustomer', v);           
                this.keyword = v.name;
            },
            onSearchCustomer: debounce(async () => {
                app.callOnSearchCustomer();
            }),
            getNewData(supplier) {
                this.keyword = supplier.name;
                this.$emit('select', supplier);
            },
            clearCustomer(){
                if (!this.read) {
                    this.keyword = '',
                    this.$emit('clearCustomer');
                }
            },
            async callOnSearchCustomer() {
                if (this.keyword) {
                    const res = await $get('/v1/orders/get-customer', {keyword: this.keyword?.trim()});
                    if (res.status.code == 200) {
                        this.customers = res.result;
                    } else {
                        $alert(res);
                    }
                }
            },
            onSelect(customer) {
                this.$emit('selectCustomer', customer);
                this.keyword = customer.name;
                this.customers.length = 0;
            },
            hidePopup() {
                this.customers.length = 0;
            },
            onCreateCustomer() {
                this.$refs.ModalAddInfoCustomer.show();
            },
        },
        watch: {
            customerName() {
                this.keyword = this.customerName;
            },
            readCustomer() {
                this.read = this.readCustomer;
            },
            keyword(newValue) {
                if (!newValue) {
                    this.clearCustomer();
                }
            }, 
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .custom__input {
        border: none;
        border-bottom: solid 1px #ccc;
        border-radius: 0;
    }
    .popup-supplier-search {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            // min-width: 450px;
            z-index: 10;
            padding-bottom: 20px;
            /*padding-bottom: 50px;*/

            max-height: calc(100vh - 230px);
            font-size: 14px;
            background: #fff;

            border-radius: 5px;
            box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

            ul.wrap-products {
                padding: 10px 0;
                overflow: auto;
                max-height: calc(100vh - 284px);
                list-style: none;
                margin-bottom: 0;

                li {
                    cursor: pointer;
                    padding: 10px 20px;
                    margin: 0;

                    .product-img {
                        width: 55px;
                        height: 55px;
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        float: left;
                        margin-left: -5px;
                        margin-top: 0;

                        img {
                            max-width: 100%;
                            max-height: 100%;
                            vertical-align: middle;
                        }
                    }

                    .product-info {
                        margin-left: 10px;
                        line-height: 18px;
                        display: flex;
                        justify-content: space-between;
                        p {
                            margin-bottom: 2px;
                        }

                        .name-item {
                            font-weight: bold;
                            word-break: break-word;
                        }

                        .code-item, .price-item {
                            margin-right: 20px;
                            margin-left: 0;
                        }
                    }
                }

                li:hover {
                    background-color: $base-color;
                    color: #fff;
                }

                &::-webkit-scrollbar {
                    width: 8px;
                    height: 8px;
                }

                &::-webkit-scrollbar-thumb {
                    width: 8px;
                    border-radius: 4px;
                    background-color: #c1c1c1;
                    border: 1px solid #fff;
                }

                &::-webkit-scrollbar-track {
                    padding: 6px;
                }
            }

            .products-not-found {
                padding: 25px 10px;
                color: #999999;
                text-align: center;
            }

            .add-new-product {
                line-height: 50px;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                text-align: center;
                border-top: 1px solid #ebebeb;
                cursor: pointer;

                &:hover {
                    background-color: $base-color;
                    color: #fff;
                }
            }
        }
</style>
