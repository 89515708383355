<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px">
            <div class="modal-content px-4">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">
                        {{ $t('message.JobTitle.confirm') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-5">
                            <h6>{{ $t('message.JobTitle.contentRemove') }}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button class="btn btn-danger mr-2" @click="deleteData()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.JobTitle.agree') }}
                            </button>
                            <button class="btn btn-secondary text-white" data-dismiss="modalConfirm" aria-label="Close" @click="clearData()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.JobTitle.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post } from "@/lib/utils";
import toastr from 'toastr';
import Paginate from "@/components/Paginate";
export default {
    name: "ModalRemoveJobTitle",
    components: {Paginate},
    data(){

        return {
            id: null,
            name: null
        }
    },
    props: {
        multiRemove: String
    },
    methods: {
        show(id, name) {
            if(id != null){
                this.id = id;
            }
             if(name != null){
                this.name = name;
            }
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        async deleteData(){
            const res = await $post('/v1/job-title/remove', { id: this.id, name: this.name});
            if(res && res.status?.code && res.status.code == 200 && res.result?.status && res.result.status){
                toastr.success(res.result.message);
                this.$emit('inputData','1');
                this.clearData();
            }else{
                toastr.error(res.result.message);
                this.$emit('inputData','1');
                this.clearData();
            }
        },
        clearData(){
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
    }
}
</script>

