<template>
    <div class="q-tree-input-wrapper" ref="root">
        <div class="q-tree-input"
             :class="{'q-tree-input-empty': this.tags.length === 0}"
             @click="toggleMenu()">
            <div class="q-tree-loading" v-if="loading">
                <i class="fa fa-spinner fa-spin"></i>
            </div>
            <template v-else>
                <div class="q-tree-label" v-if="tags.length === 0">
                    {{$t('message.medicineProductMapping.placeholder.chooseCategories')}}
                </div>
                <template v-else>
                    <span v-for="(tag, index) in tags" class="q-tree-tag" :key="tag.id">
                        {{ tag.name }}
                        <span  class="close-btn" @click="remove(tag, index)">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 348.333 348.333">
                            <path d="M336.559 68.611L231.016 174.165l105.543 105.549c15.699 15.705 15.699 41.145 0 56.85-7.844 7.844-18.128 11.769-28.407 11.769-10.296 0-20.581-3.919-28.419-11.769L174.167 231.003 68.609 336.563c-7.843 7.844-18.128 11.769-28.416 11.769-10.285 0-20.563-3.919-28.413-11.769-15.699-15.698-15.699-41.139 0-56.85l105.54-105.549L11.774 68.611c-15.699-15.699-15.699-41.145 0-56.844 15.696-15.687 41.127-15.687 56.829 0l105.563 105.554L279.721 11.767c15.705-15.687 41.139-15.687 56.832 0 15.705 15.699 15.705 41.145.006 56.844z"></path></svg>
                    </span>
                    </span>
                </template>
                <span class="q-dropdown-icon"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path
                    d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg></span>

            </template>

          </div>
        <div class="q-tree-input-menu" ref="menu" style="display: none">
            <q-tree-select v-model="items"
                           @update:modelValue="values => onInput(true, values)"
                           ref="tree"
                           :items="qOptions"
                           :single="single"
                           :datasource="datasource"
                           @loaded="onLoaded"
                           :disabled="true"
                           />
        </div>
    </div>
</template>

<script>
    import QTreeSelect from "./QTreeSelect";
    import $ from 'jquery';
    import {clone, pluck, treeFindNode, treeFindPath, treeIterate, parseIntEx} from "../../lib/utils";
    import emitter from "@/lib/emitter";
    export default {
        name: "QTreeSelectInput",
        props: ['modelValue', 'value', 'lazy', 'options', 'datasource', 'single'],
        data() {
            const items = [];

            if (this.modelValue && Array.isArray(this.modelValue)) {
                this.modelValue.forEach(v => {
                    items.push(v);
                });
            } else {
                if (this.modelValue) {
                    items.push(this.modelValue);
                }

            }

            return {
                loading: true,
                qOptions: clone(this.options),
                menuShown: false,
                items: items,
                tags: []
            }
        },
        components: {QTreeSelect},
        watch: {
            modelValue: function (newValue) {
                if(newValue){
                    const items = newValue ? newValue : [];
                    const exists = {};

                    items.forEach(v => {
                        exists[v] = true;
                    });

                    if (Array.isArray(newValue)) {
                        newValue.forEach(v => {
                            if (exists[v]) {
                                return;
                            }
                            items.push(v);
                        });
                    } else {
                        if (!exists[newValue]) {
                            items.push(newValue);
                        }
                    }
                    this.items = items;
                    this.onInput(true, this.items);
                    this.qOptions.forEach(o => {
                        this.items.forEach(i => {
                            if(i == o.id){
                                o.checked = true;
                                o.highlight = true;
                            }
                        })
                    })
                }
            },
            options: function (newVal) {
                if (newVal) {
                    this.qOptions = clone(newVal);
                }
            }
        },
        mounted() {
            document.addEventListener('click', this.documentClicked);
            if (this.modelValue) {
                this.getQSelectValues(this.modelValue, clone(this.options), this.single);
            }

        },
        beforeUnmount() {
            document.removeEventListener('click', this.documentClicked);
        },
        created(){
            emitter.on("clearValueTags", () => {
                this.tags = []
                this.items = []
                this.qOptions.forEach(o => {
                    o.checked = false;
                    o.highlight = false;
                })
            });
        },
        methods: {
            onLoaded() {
                this.onInput(true, this.items);
                setTimeout(() => {
                    this.loading = false;
                }, 500);
            },
            onInput(emit = true, items) {
                const root = this.$refs.tree.entries;
                this.tags = [];

                items.forEach(itemId => {
                    const node = treeFindNode(root, itemId);
                    if (node) {
                        this.tags.push(node);
                    }
                });

                if (emit) {
                    this.$emit('update:modelValue', items);
                }
            },
            documentClicked(ev) {
                if ($(this.$refs.root).find(ev.target).length === 0) {
                    this.showDropDown(false)
                } else {
                    this.showDropDown(true);
                }

            },
            toggleMenu() {
                this.menuShown = !this.menuShown;
                this.showDropDown(this.menuShown);
            },
            showDropDown(value) {
                if (value) {
                    this.$refs.menu.style.display = 'block';
                } else {
                    this.$refs.menu.style.display = 'none';
                }
            },
            remove(option, vIndex) {

                const itemIndex = this.items.findIndex( item => item == option.id);
                if (itemIndex > -1) {
                    this.items.splice(itemIndex, 1);


                    treeIterate(option, (node) => {
                        node.checked = false;
                        node.highlight = false;
                        node.expanded = false;
                    })

                    this.onInput(true, this.items);
                }

            },

            getQSelectValues(modelValue, options, isMultiple) {

                const optionMap = pluck(options);

                const values = [];
                let value = modelValue;
                let valueCheckedCount = 0;
                let valueLength = 0;

                if (isMultiple) {
                    if (!Array.isArray(value)) {
                        if (value) {
                            console.warn(`QSelect WARNING: ${this.name} props.multiple=true but v-model value is not an array`);
                        }

                        value = [];
                    }

                    valueLength = value.length;

                    value.forEach(v => {

                        const o = optionMap[v] ? optionMap[v] : null;
                        if (o) {
                            valueCheckedCount++;
                            o.checked = true;
                            values.push(clone(o));
                        } else {
                            values.push({id: v, label: v + '...' });
                        }
                    });

                } else {
                    if (Array.isArray(value)) {
                        value = value.length ? value[0] : null;
                    }

                    const o = optionMap[value] ? optionMap[value] : null;

                    valueLength = 1;

                    if (o) {
                        o.checked = true;
                        valueCheckedCount = 1;
                        values.push(clone(o));
                    } else if (value) {
                        values.push({id: value, label: value + '...' });
                    }
                }
            }
        }
    }
</script>

<style scoped>
    .q-tree-input-empty {
        display: flex !important;
        flex-direction: column;
        justify-content: center;
    }
    .q-tree-label {
        margin-left: 5px;
        font-size: 13px;
    }
    .q-tree-loading {
        margin-top:5px;
        margin-left: 10px;
    }
    .q-dropdown-icon {
        position: absolute;
        right: 30px;
        top: 7px;
    }
    .q-dropdown-icon svg{
        width: 9px;
        height: 9px;
        fill: #ccc;
    }
    .q-tree-tag {
        display: inline-block;
        padding: 4px 10px;
        color: white;
        margin: 1px;
        border-radius: 5px;
        background-color: #008080;
        font-size: 12px;
    }

    .q-tree-input-wrapper {
        position: relative;
    }
    .q-tree-input label {
        cursor: pointer;
        color: gray;
    }
    .q-tree-input  {
        cursor: pointer;
        position: relative;
        display: block;
        width: 100%;
        min-height: 36px;
        padding: 3px;
        font-size: .9rem;
        font-weight: 400;
        color: #6c757d;
        background-color: #fff;
        background-clip: padding-box;
        border: 1px solid #ced4da;
        border-radius: .42rem;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
    .q-tree-input-menu {
        width: 100%;
        position: absolute;
        z-index: 999;
        background-color: white;
        max-height: 300px;
        overflow-y: auto;
        border: 1px solid #ccc;
        padding: 10px 5px 5px;
    }


</style>
