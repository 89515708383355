<template>
    <div class="customer-tab tab-info">
        <div class="title">
            Thông tin lịch sử giỏ hàng
        </div>
        <div class="table-height-wrapper">
            <table class="table table-index table-striped" >
                <thead>
                <tr>
                    <th scope="col">{{$t('message.common.STT')}}</th>
                    <th scope="col">Tên sản phẩm</th>
                    <th scope="col">Thời gian mua</th>
                    <th scope="col" class="text-center">Số lượng mua</th>
                    <th scope="col" class="text-center">{{$t('message.ProductIndex.price')}}</th>
                    <th scope="col">Tổng số lần đã mua tính đến hiện tại</th>
                </tr>
                </thead>
                <tbody>
                <template v-for="(product, index) in products" :key="'product-' + index">
                    <tr>
                        <td v-text="index+1"></td>
                        <td v-text="product[0].productName"></td>
                        <td v-text="d(product[0].order ? product[0].order.purchaseDate : '')"></td>
                        <td v-text="product[0].quantity" class="text-center"></td>
                        <td v-text="n(product[0].price)" class="text-center"></td>
                        <td v-text="product.length" class="text-center"></td>
                    </tr>
                    <template v-if="product.length > 1">
                        <tr v-for="(p, i) in startFrom(product, 1)" :key="'product-item-' + i">
                            <td colspan="2"></td>
                            <td v-text="d(p.order ? p.order.purchaseDate : '')"></td>
                            <td v-text="p.quantity" class="text-center"></td>
                            <td v-text="n(p.price)" class="text-center"></td>
                            <td></td>
                        </tr>
                    </template>
                </template>
                </tbody>
            </table>
        </div>

        <div class="row mt-2">
            <button class="btn btn-primary ml-auto mr-3">Xuất file</button>
        </div>

    </div>
</template>

<script>
import {$alert, $get} from "@/lib/utils";

export default {
name: "TabCartHistory",
    props: ['id'],
    components: {
    },
    data() {
        return {
            products: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            }
        }
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    },
    methods: {
        async load() {
            const res = await $get('/v1/customers/dataCart', this.$route.query);
            if (res.code !== 200) {
                $alert(res);
                return;
            }

            this.products = res.data.products;

        },
        async pageChange(page) {
            await this.$router.push({query: {page: page}});
            await this.load();
        },
        startFrom (arr, idx) {
            return arr.slice(idx)
        }

    }
}
</script>

<style scoped>

    .table-height-wrapper {
        max-height: 500px;
        overflow-y: auto;
    }
</style>