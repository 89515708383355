<template>
    <div ref="modal" class="modal fade modal-third" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document" style="max-width: 80%;">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ $t('message.common.manageFile') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <FileManager ref="fileManager" :group="group" v-if="showFileManager" :multiple="isMultiple" @fileclick="fileClicked" :type="this.type"/>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>


</style>
<script>
import FileManager from "../FileManager";

export default {
    name: "ModalManageFile",
    data(){
        return {}
    },
    props: ['showFileManager', 'isMultiple', 'group', 'type'],
    components: { FileManager },
    methods: {
        show() {              
            window.jQuery(this.$refs.modal).modal('show');
            this.$nextTick(() => {
                this.$refs.fileManager.load();
            })
        },
        clearInput() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        fileClicked(file) {
            this.$emit('fileClicked', file);
            window.jQuery(this.$refs.modal).modal('hide');
        },
    }
}
</script>

