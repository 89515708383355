import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";
const currentYear = (new Date).getFullYear().toString();

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}
function date(tzTime) {
    if (!tzTime) {
        return '';
    }

    const m = moment(tzTime);

    if (m.format('YYYY') === currentYear) {
        return m.format('DD/MM')
    }

    return m.format('DD/MM/YYYY')
}

const fields = {
    'Mã hàng': 'productCode',
    'Tên hàng': 'productName',
    'Lô': 'batchName',
    'Ngày hết hạn': {
        field: 'expireDate',
        callback: (value) => {
            return date(value);
        }
    },
    'Số ngày còn lại': 'daysToOutOfDate',
    'Số lượng': 'quantity',
};

export default fields;
