<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" :class="this.disabledJobTitle && this.disabledJobTitle === true ? 'modal-third' : '' " data-backdrop="static">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2" v-text="this.title ? this.title : $t('message.common.confirm')"></h5>
                    <button type="button" class="close pt-3" @click="skip()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body" >
                    <template v-if="this.type == 'add'">
                        <div class="row">
                            <div class="col-12">
                                <p style="font-size:13px; color:red;" v-for="(msg, index) in this.message" :key="index" >  {{ msg }} </p>                       
                            </div>
                            <div class="col-12 d-flex justify-content-end">                        
                                <button class="btn btn-secondary text-white" @click="skip()">
                                    <i class="fa fa-ban mr-1"></i>
                                    {{$t('message.button.close')}}
                                </button>
                            </div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="row">
                            <div class="col-12">
                                <p style="font-size:13px;" >  {{ this.message }} </p>                       
                            </div>
                            <div class="col-12 d-flex justify-content-end">                        
                                <button class="btn btn-primary text-white mx-2" @click="confirm()">
                                    <i class="fa fa-save mr-2"></i>
                                    {{$t('message.button.confirm')}}
                                </button>
                                <button class="btn btn-secondary text-white" @click="skip()">
                                    <i class="fa fa-ban mr-1"></i>
                                    {{$t('message.button.close')}}
                                </button>
                            </div>
                        </div>
                    </template>
                  
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
export default {
    name: "ModalConfirmPermissions",    
    data(){
        return {
            type:null,
            data: null,
        }
    },
    props: ['message', 'title', 'content','disabledJobTitle'],
    methods: {
        show(type, data = null) {         
            this.type = type;
            this.data = data;            
            window.jQuery(this.$refs.modalConfirm).modal('show');
        },
        skip() {
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        confirm(){              
            switch (this.type) {
                case 'update':
                     this.$emit('updateRole', this.data);
                    break;
                case 'remove':                    
                     this.$emit('deleteRole', this.data);
                    break;
                default:
                    break;
            } 
           
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        }
    }
}
</script>

