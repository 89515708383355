
<template>
    <div >
        <div class="row">
            <div class="col-12">
                <div class="justify-center-content">
                    <div class="row orders-op">
                        <div class="col-4 d-flex align-items-center justify-content-center" @click="choose('orders')">
                            <h6 :class="this.ordersOption === 'orders' ? 'order-active-op' : ''">Đặt hàng <span :class="this.ordersOption === 'orders' ? 'num-order-dt' : ''">{{this.order_sync_error}}</span></h6>
                        </div>
                        <div class="col-4 d-flex align-items-center justify-content-center" @click="choose('invoices')">
                            <h6 :class="this.ordersOption === 'invoices' ? 'order-active-op' : ''">Hóa đơn <span :class="this.ordersOption === 'invoices' ? 'num-order-dt' : ''">{{this.inventory_sync_error}}</span></h6>
                        </div>
                        <div class="col-4 d-flex align-items-center justify-content-center" @click="choose('products')">
                            <h6 :class="this.ordersOption === 'products' ? 'order-active-op' : ''">Hàng hóa <span :class="this.ordersOption === 'products' ? 'num-order-dt' : ''">{{this.product_sync_error}}</span></h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row bg-gray border-radius-8 mt-3">
            <div class="col-12">
                <div class="row py-1 border-bottom">
                    <div class="col-4 col-m-6">
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-1 text-right">
                                <span>{{$t('message.common.ecommerceLevel')}}:</span>
                            </div>
                            <div class="col-11">
                                <q-select v-model="filter.sale_channel_ids"
                                    datasource="saleChannels"
                                    @update:modelValue="doFilter('sale_channel_ids', filter.sale_channel_ids)"
                                    placeholder="Chọn sàn"
                                    :multiple="false"/>
                            </div>
                        </div>
                    </div>
                    <div class="col-8 col-m-6">
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-1 text-right">
                                <span>Shop:</span>
                            </div>
                            <div class="col-11">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="row d-flex align-items-center justify-content-center">
                            <div class="col-1">
                                <span>{{$t('message.InputQuickSearch.button.search')}}:</span>
                            </div>
                            <div class="col-11 search-box">
                                <i class="fa fa-search mr-2 search-input"></i>
                                <input type="text" class="search-input" @keydown.enter="doFilter('keyword', filter.keyword)"
                                v-model="filter.keyword" placeholder="Nhập mã, thêm hàng" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3">
            <div class="col-5">
                <div class="main-title" v-if="this.ordersOption === 'products'">
                    <h5>Hàng hóa lỗi</h5>
                </div>
            </div>
            <div class="col-7 text-right">
                <button class="btn btn-primary mx-2">
                    <i class="fa fa-retweet" aria-hidden="true"></i> Đồng bộ tất cả
                </button>  
            </div>
        </div>
        <template v-if="this.ordersOption === 'products'">
            <div class="row mt-3 title-table d-flex align-items-center justify-content-center">
                <div class="col-8">{{$t('message.common.productSync')}}</div>
                <div class="col-3">{{$t('message.common.syncContent')}}</div>
                <div class="col-1">{{$t('message.button.action')}}</div>
            </div>
            <div class="row mt-3 ">
                <div class="col-12">
                    <template v-for="shop in this.shops" :key="shop.id">
                        <template v-if="shop.get_product_sync.length > 0">
                            <div class="row px-3 body-table d-flex align-items-center">
                            <img class="logoSaleChannel mx-1" src="../../../public/img/shopee-icon.png" alt="" v-if="shop.saleChannelName === 'Shopee'">
                            <img class="logoSaleChannel mx-1" src="../../../public/img/lazada-icon-active.png" alt="" v-else>
                            {{shop.name}}
                                <i class="fa fa-retweet pos-ab-50 color-primary" aria-hidden="true"></i>
                            </div>
                            <template v-for="product in shop.get_product_sync" :key="product.id">
                                <template  v-if="shop.identity_key === product.shop_id">
                                    <div class="row py-3 px-1 border" >
                                        <div class="col-8">
                                            <h6>{{product.omi_prd_name}}</h6>
                                            <a :href="'/products/form?id=' + product.omi_prd_id" target="_blank">{{product.omi_prd_id}}</a> - Giá bán: {{product.basePrice}} - SL bán: {{product.quantity}}
                                        </div>
                                        <div class="col-4">
                                            <p class="text-danger">{{product.message}}</p>
                                            <span class="sz-12 c-gr">{{product.createdAt}}</span>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </template>
                    </template>
                </div>
            </div>
        </template>
        <!-- <template v-if="this.ordersOption === 'orders'">
            <div class="row mt-3 title-table d-flex align-items-center justify-content-center">
                <div class="col-8">{{$t('message.common.products')}}</div>
                <div class="col-3">{{$t('message.DetailOrdersCard.prodQuantity')}}</div>
                <div class="col-1">{{$t('message.button.action')}}</div>
            </div>
            <div class="row mt-3 ">
                <div class="col-12" v-if="this.entries.length > 0">
                    <div class="row px-3 body-table d-flex align-items-center">
                        <img class="logoSaleChannel mx-1" src="../../../public/img/shopee-icon.png" alt="" v-if="entry.saleChannelName === 'Shopee'">
                        <img class="logoSaleChannel mx-1" src="../../../public/img/lazada-icon-active.png" alt="" v-else>
                        Shop1
                        <i class="fa fa-retweet pos-ab-50 color-primary" aria-hidden="true"></i>
                    </div>
                    <div class="row py-3 px-1 border" v-for="product in entry.orderDetails" :key="product.productId">
                        <div class="col-8">
                            <h6>aa</h6>
                            <a href="">Cd1</a> - Giá bán: 21 - SL bán: 1
                        </div>
                        <div class="col-4">
                            <p class="text-danger">nvv</p>
                            <span class="sz-12 c-gr">25/11/2022</span>
                        </div>
                    </div>
                </div>
            </div>
        </template> -->
    </div>
</template>
<script>
    import {$alert, $get, clone, setArray, getSearchQuery,cloneObject, $post,$getApiShopee} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import TableSortField from "@/components/TableSortField";
    import QSelect from "../../components/QSelect";
    import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
    import {explode} from "../../lib/utils";
    import SelectField from "../../components/SelectField";
    import store from "../../store";
    import FieldsExportProduct from "@/assets/exportFields/fieldsExportProduct";
    import moment from "moment";
    import config from "@/config";


    export const ACTION_ORDER_PROCESSING = 'orderProcessing';
    export default {
        name: "OrderTabErrorOrderSync",
        components: {SelectField, InputDateRangePicker, TableSortField, Paginate, QSelect},
        data() {
            const query = getSearchQuery();
            const branchIds = query.branch_ids !== undefined ? explode(query.branch_ids) : ([store.state.CurrentBranch.id] || []);
            if (query.branch_ids === undefined && branchIds && branchIds.length) {
                this.doFilter('branch_ids', branchIds.join(','))
            }

            return {
                filter: {
                    keyword: query.keyword,
                    branch_ids: branchIds,
                    sale_channel_ids: explode(query.sale_channel_ids),
                    purchase_date: query.purchase_date,
                    status: explode(query.status),
                    created_by_ids: explode(query.created_by_ids),
                    delivery_status: explode(query.delivery_status),
                    shop_ids: explode(query.shop_ids),
                },
                shop: '',
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                user: store.state.Auth.user,
                paymentMethods: store.state.GlobalConfig.listPaymentMethod,
                details: [],
                ordersOption: 'products',
                product_sync_error: 0,
                order_sync_error: 0,
                inventory_sync_error: 0,
                shops: null,
                sale_channel_name: null,
            }
        },
        mounted() {
            this.$router.replace({
                path: '/orders/error-sync',
                query: {}
            });

            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });
            this.loadData();
        },
        methods: {
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                await this.$router.push({query: query});
                this.getShopConnected(query);
            },
            async getShopConnected(query){
                if(query.sale_channel_ids != null){
                    const result = await $get('/v1/sale-channels/show', {id: query.sale_channel_ids});
                    this.getProductSyncError(result.data.entry.name);
                }
            },
            choose(option){
                this.ordersOption = option;
                this.loadData();
            },
            loadData(){
                let sale_channel_name = localStorage.getItem('lnkActive') ? localStorage.getItem('lnkActive') : '';
                switch(this.ordersOption){
                    case 'products':
                        this.getProductSyncError(sale_channel_name);
                        break;
                }
            },
            async getProductSyncError(sale_channel_name){
                // lấy dữ liệu sàn được kết nối và thông tin sản phẩm đồng bộ lỗi của sàn
                const res = await $get('/v1/products/getProdSyncError', {'sale_channel_name': sale_channel_name});
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }
                this.product_sync_error = res.product.length;
                this.shops = res.shop;
            }
        }
    }
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .justify-center-content{
        max-width: 500px;
        height: 50px;
        background: #ffffff;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        border-radius: 5px;
        margin: auto;
        display: block;
    }
    .orders-op{
        height: 100%;
    }
    .order-active-op{
        background: #e7f7ee;
        color: #0dac50;
        padding: 10px;
        border-radius: 50px;
    }
    .num-order-dt{
        background: #0dac50;
        color: #ffffff;
        border-radius: 50%;
        padding: 0px 3px 0px 3px;
    }
    .border-radius-8{
        border-radius: 8px;
    }
    .bg-gray{
        background: #f5f6f8;
        padding: 10px;
    }
    .search-box{
        height: 50px;
        position: relative;
    }
    .search-box i{
        position: absolute;
        top: 15px;
        left: 30px;
        color: #afadad;
    }
    .search-box input{
        padding-left: 50px;
    }
    .border-bottom{
        border-bottom: 1px solid gray;
    }
    h6{
        margin-bottom: 0;
    }
    .title-table{
        height: 40px;
        background: #b1ddf0;
        font-weight: bold;
        border-radius: 8px;
    }
    .body-table{
        height: 40px;
        background: #f5f6f8;
        border-radius: 4px 4px 0px 0px;
        border: 1px solid #ced4da;
    }
    .logoSaleChannel{
        width: 20px;
        padding-bottom: 5px;
    }
    .pos-ab-50{
        position: absolute;
        top: 10px;
        right: 50px;
    }
    .color-primary{
        color: $base-color;
    }
</style>