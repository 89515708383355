import {forEach, isEmpty, numberFormat} from "./lib/utils";
import toVietnamWordMixin from "./toVietnamWordMixin";
import moment from 'moment';
import store from "@/store";
import {clone} from "@/lib/utils";
export default {
    mixins: [toVietnamWordMixin],
    methods: {        
        formatShowProduct(dataValue, templateOrder) {            
            let htmlObject = document.createElement("div");
            htmlObject.innerHTML = templateOrder;
            var aTags = htmlObject.getElementsByTagName("td");
            var searchText1 = "{So_Luong}";
            var searchText2 = "{Ten_Hang_Hoa}";
            var searchText3 = "{Ma_Hang}";
            var found = null;
            for (var i = 0; i < aTags.length; i++) {
                if (
                    aTags[i].textContent.trim() == searchText1 ||
                    aTags[i].textContent.trim() == searchText2 ||
                    aTags[i].textContent.trim() == searchText3 
                ) {
                    found = aTags[i];
                    break;
                }
            }
            if (found) {
                found.parentNode.parentNode.parentNode.setAttribute("id", "content-product");
            }
            if(dataValue?.convertProducts && dataValue.convertProducts.length){
                for (let index = 1; index < dataValue.convertProducts.length; index++) {
                    let template = htmlObject.querySelector("#content-product").children[0];
                    let clone = template.cloneNode(true);
                    clone.children[0].innerHTML = "";
                    let node = document.createElement("tbody");
                    node.innerHTML = clone.outerHTML;
                    htmlObject.querySelector("#content-product").appendChild(node);
                }
                templateOrder = htmlObject.outerHTML;
                dataValue.convertProducts.forEach((product) => {
                    product.forEach((data, index) => {
                        templateOrder = templateOrder.replace("{" + data.key + "}", data.value) ?? null;
                        templateOrder = templateOrder.replace('{STT}', index + 1 ) ?? null; 
                    });
                });
            }
            return templateOrder;
        },
        getProduct(entry){
            let data = [];
            let details = entry.details;            
            let dataPush = null;
            details.forEach(d => { 
                data.push({
                    Ma_Hang: d.product?.code ? d.product.code : '',
                    Ten_Hang_Hoa: d.product?.name ? d.product.name : '',
                    Don_Vi_Tinh: d?.product_unit ? d.product_unit.unitName : '',                 
                    // Tong_Du_Tru_Dat: d?.totalQuantityOrder ? d.totalQuantityOrder : 0,
                    Du_Tru_Dat: d?.totalQuantityOrder ? d.totalQuantityOrder : 0,
                    So_Luong_Thuc_Dat: d?.totalQuantityRealOrder ? d.totalQuantityRealOrder : 0,
                    Don_Gia: d?.price ? d.price : 0,
                    Nha_Cung_Cap: d?.supplier ? d.supplier.name : ''
                });                
            });
            return data;
        },
        renderDataPlanDetails(dataValue){                   
            this.editorDataClone = this.content;            
            let image = null;          
            let barcode = ''; 
            if(dataValue && dataValue.common){
                dataValue.common.forEach(data => { 
                    let image = '<img src="' + data.value + '">';                    
                    switch (data.key) {      
                        case 'Logo_Cua_Hang':
                            image = '<img src="' + data.value + '">';                
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', image)?? '';
                            break;                                                          
                        default:
                            this.editorDataClone = this.editorDataClone.replaceAll('{' + data.key + '}', data.value ) ?? null;
                            break;
                    }                  
                });
            }              
            return this.formatShowProduct(dataValue, this.editorDataClone);
        },
        startEndDate(entry){
            let Thoi_Gian_Ban_Trung_Binh = null;
            Thoi_Gian_Ban_Trung_Binh = (entry?.startDate ? moment(entry.startDate).format('DD/MM/YYYY') : '') + (entry?.endDate ? ' - ' + moment(entry.endDate).format('DD/MM/YYYY') : '');
            return Thoi_Gian_Ban_Trung_Binh;
        },
        async dataPrintPlanDetails(entry) {               
            // eslint-disable-next-line no-unreachable
            let convertProducts = []; 
            let common = [                
                {key:'Logo_Cua_Hang', value: this.$constant.logo_image},
                {key:'Ten_Cua_Hang', value: entry?.branch_create ? entry.branch_create.name : ''},
                {key:'Dia_Chi_Chi_Nhanh', value: entry?.branch_create ? entry.branch_create.address : ''},
                {key:'Dia_Chi_Cua_Hang', value: entry?.branch_create ? entry.branch_create.address : ''},
                {key:'Phuong_Xa_Chi_Nhanh', value: entry?.branch_create ? (entry.branch_create.ward ? entry.branch_create.ward.name: ''): ''},
                {key:'Khu_Vuc_QH_TP', value: (entry.branch_create ? (entry.branch_create.district ? entry.branch_create.district.name : '') : '') + ' - ' + (entry.branch_create ? (entry.branch_create.province ? entry.branch_create.province.name : '') : '')},
                {key:'Dien_Thoai_Chi_Nhanh', value: (entry.branch_create ? entry.branch_create.phone : '')},
                {key:'Email_Chi_Nhanh', value: (entry.branch_create ? entry.branch_create.email : '')},
                {key:'Thoi_Gian_In', value: moment().format('DD/MM/YYYY HH:mm')},
                {key:'Nguoi_In', value: store.state.Auth ? store.state.Auth.user.name : ''},
                {key:'Thoi_Gian_Tao_Phieu', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY H:m') : ''},
                {key:'Ngay_Thang_Nam_Tao', value: entry.created_at ? moment(entry.created_at).format('DD/MM/YYYY') : moment(entry.created_at).format('DD/MM/YYYY')},
                {key:'Ma_QR', value: entry.code},
                {key:'Nguoi_Tao',value: entry.created_by_user.name?? null},
                {key:'Nhan_Vien_Ban_Hang', value: entry.created_by_user ? entry.created_by_user.name : ''},
                {key:'Nhan_Vien', value: entry.created_by_user ? entry.created_by_user.name : ''},                
            ];                          
            let totalQuantity = 0;          
            let keyOrderSupplier = [];
            switch (entry.typePrint) {
                case this.$constant.TabPlanImportBuy:
                    keyOrderSupplier = [                        
                        {key:'Tieu_De_In',value: "DỰ TRÙ NHẬP MUA"},
                        {key:'Ngay_Lap_Phieu', value: entry.created_at ? moment(entry.created_at).format('DD') : moment(entry.created_at).format('DD')},
                        {key:'Thang_Lap_Phieu', value: entry.created_at ? moment(entry.created_at).format('MM') : moment(entry.created_at).format('MM')},
                        {key:'Nam_Lap_Phieu', value: entry.created_at ? moment(entry.created_at).format('YYYY') : moment(entry.created_at).format('YYYY')},
                        {key:'Chi_Nhanh_Du_Tru', value: entry?.branch_create ? entry.branch_create.name : ''},
                        {key:'Chi_Nhanh_Duoc_Du_Tru', value: entry?.branchName ? entry.branchName : ''},
                        {key:'Phuong_Xa_Chi_Nhanh', value: this.getWards(entry.branches)},
                        {key:'Khu_Vuc_Chi_Nhanh', value: this.getDisProvince(entry.branches)},
                        {key:'Khu_Vuc_Chi_Nhanh_QH_TP', value: this.getDisProvince(entry.branches)},
                        {key:'Trang_Thai',value: entry?.statusName ? entry.statusName : ''},
                        {key:'Ten_Du_Tru', value: entry?.name ? entry.name : ''},
                        {key:'Thoi_Gian_Ban_Trung_Binh', value: this.startEndDate(entry)},
                        {key:'So_Ngay_Ban_Du_Kien', value: entry?.estimatedDate ? entry.estimatedDate + ' Ngày' : ''},
                        {key:'Tong_So_Ma_Du_Tru', value: entry?.totalProducts ? entry.totalProducts : 0},
                        {key:'Tong_So_San_Pham_Du_Tru', value: entry?.totalQuantity ?  entry.totalQuantity : 0},
                        {key:'Ghi_Chu_Du_Tru', value: entry?.note ?  entry.note : ''},
                        {key:'Ma_Du_Tru', value: entry?.code ? entry.code : ''},                        
                        {key:'Tong_So_Nha_Cung_Cap', value: entry?.totalSupplier ? entry.totalSupplier : ''}
                        
                    ];
                  
                    break;                       
            }                  
            common.push(...keyOrderSupplier); 
            let products = this.getProduct(entry);
            products.forEach((product, index) => {                
                totalQuantity = totalQuantity + parseInt(product.So_Luong);
                convertProducts.push([
                    {key:'STT', value: index+1},
                    {key:'Ma_Hang', value: product.Ma_Hang?? ''},
                    {key:'Ten_Hang_Hoa', value: product.Ten_Hang_Hoa?? ''},
                    {key:'Don_Vi_Tinh', value: product.Don_Vi_Tinh?? ''},                    
                    // {key:'Tong_Du_Tru_Dat', value: product.Tong_Du_Tru_Dat?? 0},
                    {key:'Du_Tru_Dat', value: product.Du_Tru_Dat?? 0},
                    {key:'So_Luong_Thuc_Dat', value: product.So_Luong_Thuc_Dat?? 0},
                    {key:'Don_Gia', value: product.Don_Gia?? 0},
                    {key:'Nha_Cung_Cap', value: product.Nha_Cung_Cap?? 0},
                ]);
            });            
            return {common, convertProducts};
        },
        getDisProvince(branches){            
            let list=[];
            let districtName;
            let provinceName;
            // let branchName;
            branches.forEach(br => {                
                // branchName = br?.branch ? br.branch.name : '';
                districtName = br?.branch && br.branch?.province ? br.branch.district.name : '';
                provinceName = br?.branch && br.branch?.province ? " - " + br.branch.province.name : '';               
                list.push(districtName + provinceName);
            });            
            return list.toString();
        },
        getWards(branches){            
            let list = [];            
            let wardName;
            // let branchName;
            branches.forEach(br => {
                // branchName = br?.branch ? br.branch.name : '';
                wardName = br?.branch && br.branch?.ward ? " - " + br.branch.ward.name : '';                
                list.push(wardName);
            });
            return list.toString();                     
        },
        // getConsignmentName(product, invoice_items){
        //     let consignmentName = [];
        //     invoice_items.forEach(item => {
                
        //     });
        //     return consignmentName;
        // },
        formatproductPrintCommon(data) {
            let dataPrint = clone(data);
            let products = [];
            let totalPrice = 0;
            let listIdProduct = [];
            let exsitProductIds = [];
            dataPrint.details.forEach(item => {
                if (!exsitProductIds.includes(item.productId)) {
                    exsitProductIds.push(item.productId);
                }
                if (!listIdProduct.includes(item.productId)) {
                    listIdProduct.push(item.productId);
                }
                let product = clone(item);                
                product.product.name = product.product.name + ' ( ' + product.product_unit.unitName + ' )';
                if (product.consignment_detail && product.consignment_detail.length) {
                    product.consignment_detail.forEach(detail => { 
                        product.consignment = {};
                        product.consignment.name = detail.consignment.name,
                        product.consignment.expried = detail.consignment.expried,
                        product.quantity = detail.quantity,
                        product.discount = (item.discount/item.quantity) * detail.quantity;
                        if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                            product.subTotal = product.price*detail.quantity;
                        } else {
                            product.subTotal = (product.price*detail.quantity) - product.discount;
                        }
                        totalPrice += +product.subTotal;
                        products.push(clone(product));
                    });
                } else {
                    totalPrice += +product.subTotal;
                    products.push(clone(product));
                }
                if (product && product.plus) {
                    product.plus.forEach(plusOrigin => {
                        let plus = clone(plusOrigin);
                        plus.product.name = plus.product.name + ' ( ' + plus.unit.unitName + ' )';
                        plus.product.product_category = product.product.product_category;
                        if (plus.consignment_detail && plus.consignment_detail.length) {
                            plus.product.consignments = product.product.consignments;
                            plus.consignment_detail.forEach(detail => {
                                plus.consignment = {};
                                plus.consignment.name = detail.consignment.name;
                                plus.consignment.expried = detail.consignment.expried;
                                plus.quantity = detail.quantity;
                                plus.discount = (plusOrigin.discount/plusOrigin.quantity) * detail.quantity;
                                if (dataPrint.typePrint == this.$constant.TypeReturnSuppliers) {
                                    plus.subTotal = plus.price*detail.quantity;
                                } else {
                                    plus.subTotal = (plus.price*detail.quantity) - plus.discount;
                                }
                                totalPrice += +plus.subTotal;
                                products.push(clone(plus));
                            });
                        } else {
                            totalPrice += +plus.subTotal;
                            products.push(clone(plus));
                        }
                    });
                }
            });
            dataPrint.totalPrice = totalPrice;
            dataPrint.listIdProduct = listIdProduct;
            dataPrint.details = products;
            dataPrint.exsitProductIds = exsitProductIds;
            return dataPrint;
        },
    },
}
  