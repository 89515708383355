<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{ $t('message.customerGroupIndex.titleCreate') }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active outline-none" id="home-tab" data-toggle="tab" data-target="#homeInfoCustomer" type="button" role="tab"
                            aria-controls="homeInfoCustomer" aria-selected="true">
                            {{ $t('message.customerGroupIndex.info') }}
                        </button>
                    </li>
                    <!-- <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#editAdvanced" type="button" role="tab"
                            aria-controls="editAdvanced" aria-selected="false">
                            {{ $t('message.customerGroupIndex.advanceSetting') }}
                        </button>
                    </li> -->
                </ul>
            
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.customerGroupIndex.name') }}<label class="text-danger px-0">*</label> </b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="form.name"  :placeholder="$t('message.customerGroupIndex.enterName')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                        
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.customerGroupIndex.discount') }}</b>
                                        </div>
                                        <div class="col-8">
                                            <div class="form-group">
                                                <input type="number" v-model="form.price" :placeholder="$t('message.customerGroupIndex.chooseDiscount')" class="form-control">
                                            </div>
                                        </div>
                                        <div class="col-2">
                                            <div class="form-check">
                                                <input class="form-check-input" @change="changeData" value="1" :checked="checkedValue == '1'" v-model="form.discountBy" type="radio" name="exampleRadios">
                                                <label class="form-check-label" for="exampleRadios1">
                                                    {{$t('message.customerGroupIndex.vnd')}}
                                                </label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" @change="changeData" value="2" :checked="checkedValue == '2'" v-model="form.discountBy" type="radio" name="exampleRadios">
                                                <label class="form-check-label" for="exampleRadios2">
                                                    {{$t('message.customerGroupIndex.percent')}}
                                                </label>
                                            </div>
                                            
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{$t('message.customerGroupIndex.note')}}</b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <textarea class="form-control" name="" id="" v-model="form.note" cols="30" rows="7" :placeholder="$t('message.customerGroupIndex.note')"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <button type="button" class="btn btn-save mr-2" @click="submitForm">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.save') }}
                                    </button>
                                    <button type="button" class="btn btn-secondary text-white  mr-2"
                                        @click="clearInput">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div>
                        
                        <!-- <div class="tab-pane fade" id="editAdvanced" role="tabpanel" aria-labelledby="profile-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <span>{{ $t('message.customerGroupIndex.settingConditional') }}</span>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                                        checked>
                                    <label class="form-check-label" for="exampleRadios1">
                                        {{ $t('message.customerGroupIndex.add') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios2" value="option2">
                                    <label class="form-check-label" for="exampleRadios2">
                                        {{ $t('message.customerGroupIndex.update') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mx-4 my-4 mt-1">
                                <div class="form-check">
                                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios3" value="option3">
                                    <label class="form-check-label" for="exampleRadios3">
                                        {{ $t('message.customerGroupIndex.notUpdate') }}
                                    </label>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <button type="button" class="btn btn-save mr-2" @click="submitForm">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.save') }}
                                    </button>
                                    <button type="button" class="btn btn-default text-white mr-2" aria-label="Close">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.customerGroupIndex.button.skip') }}
                                    </button>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()"  :title="$t('message.customerGroupIndex.confirm')" :content="$t('message.customerGroupIndex.confirmContent')"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import {$post, $alert, scrollToElement} from "@/lib/utils";
import toastr from 'toastr';
import ModalConfirm from "@/components/ModalConfirm.vue";
export default {
    name: "ModalAddCustomer",
    components: { InputText , ModalConfirm },
    props: {
        openModal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
          active: 1,
          form: {
            'name': '',
            'note': '',
            'discountBy': "",
            'price': ''
          },
          errors: {}
        }
    },
    mounted() {
        this.form.discountBy = "1"
    },
    methods: {
        show (flag) {
            if (flag) {
                this.setValue();
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        changeData () {
            this.form.price = ''
        },
        async submitForm () {
            this.errors = {}
            const res = await $post('/v1/customer-group/create', this.form);
            // if (res.code === 512) {
            //     this.errors = res.errors;
            //     scrollToElement('.error-label')
            //     $alert(res);
            // }
            if(res.code === 422){
                if(res.messages.errors.name){
                    toastr.error(res.messages.errors.name[0]);
                }
                if(res.messages.errors.price){
                    toastr.error(res.messages.errors.price[0]);
                }
            }
            if(res.code == 200){
                toastr.success(this.$t('message.customerGroupIndex.alertSuccess'));
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit("updated", res);
                this.setValue();
            }
        },
        hide(){
            window.jQuery(this.$refs.modal).modal('hide');
        },
        clearInput(){   
            if ( this.form.name != '' ||  this.form.note != '' || this.form.price != ''){ 
                this.$refs.ModalConfirm.show();
            } else {
                this.setValue();
                window.jQuery(this.$refs.modal).modal('hide');
            }
        },
        setValue () {
            this.form.name = '';
            this.form.price = '';
            this.form.discountBy = 1;
            this.form.note = ''; 
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
</style>