<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-4 head-remove">
                    <h5 class="modal-title py-2">
                        {{ $t('message.common.confirm') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4 pt-0" v-if="totalRecord">
                    {{$t('message.PriceListDetailIndex.contentConfirmClone', {name: this.priceListName})}}
                    <div class="col-12 pt-4 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="cloneAllPriceList">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close" @click="cloneProductCurrentPriceList">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $post } from "@/lib/utils";
// import emitter from "@/lib/emitter";
import toastr from 'toastr';
export default {
    name: 'ModalConfirmClonePrice',
    props: {},
    data() {
        return {
            priceListIdSelect: null,
            productCurrentId: null,
            priceListName: null,
            params: null,
            totalRecord: 0,
        };
    },
    methods: {
        show(priceListIdSelect, productCurrentId, priceListName, params, totalRecord) {
            this.priceListIdSelect = priceListIdSelect;
            this.productCurrentId = productCurrentId;
            this.priceListName = priceListName;
            this.params = params;
            this.totalRecord = totalRecord
            window.jQuery(this.$refs.modal).modal('show');
        },
        async cloneAllPriceList() {
            window.jQuery(this.$refs.modal).modal('hide');
            let params = {
                priceListId: this.params.priceListId,
                inputSearch: this.params.inputSearch,
                categoryId: this.params.categoryId,
                priceListIdSelect: this.priceListIdSelect,
                // productUnitId: this.params.productUnitId,
            }
            const res = await $post('/v1/setting-price/clone-multiple-price', {...params});
            if (res && res.result && res.result.status) {
                const message = this.$t('message.PriceListDetailIndex.alertCreateSuccess');
                toastr.success(message);
                this.$emit('updated')
            } else {
                const message = this.$t('message.PriceListDetailIndex.alertCreateError');
                toastr.error(message);
            }
        },
        async cloneProductCurrentPriceList() {
            let params = {
                productCurrentId: this.productCurrentId,
                priceListIdSelect: this.priceListIdSelect,
                productUnitId: this.params.productUnitId,
            }
            const res = await $post('/v1/setting-price/clone-Product-current-price', {...params});
            if (res && res.result && res.result.status) {
                const message = this.$t('message.PriceListDetailIndex.alertCreateSuccess');
                toastr.success(message);
                this.$emit('updated')
            } else {
                const message = this.$t('message.PriceListDetailIndex.alertCreateError');
                toastr.error(message);
            }
            window.jQuery(this.$refs.modal).modal('hide');
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>