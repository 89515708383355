<template>
    <select class="form-select form-select-sm ms-auto d-inline-flex w-auto" @change="onChangeOption($event)">
        <option v-for="o in optionFilterByRange" :key="o.id" :value="o.keyword" :selected="this.sortTimeValue == o.value" v-text="o.label"></option>
    </select>
</template>

<script>
import moment from 'moment';
import 'moment-lunar';
export default {
    name: "DateRangeDashboardPicker",
    props: ['formatType'],
    data(){
        const format = this.formatType;
        return{
            optionFilterByRange: [
                {id: 1, label: this.$t('message.DateRangerDashboardPicker.today'), keyword: 'today', value: moment().locale('vi').format(format)},
                {id: 2, label: this.$t('message.DateRangerDashboardPicker.last_day'), keyword: 'yesterday', value: moment().locale('vi').subtract(1, 'days').format(format)},
                {id: 3, label: this.$t('message.DateRangerDashboardPicker.seven_day'), keyword: '7day', value: moment().locale('vi').subtract(6, 'days').format(format) + '_' + moment().locale('vi').format(format)},
                {id: 4, label: this.$t('message.DateRangerDashboardPicker.month'), keyword: 'month', value: moment().locale('vi').startOf('month').format(format) + '_' + moment().locale('vi').endOf('month').format(format)},
                {id: 5, label: this.$t('message.DateRangerDashboardPicker.last_month'), keyword: 'lastmonth', value: moment().locale('vi').subtract(1, 'months').startOf('month').format(format) + '_' + moment().locale('vi').subtract(1, 'months').endOf('month').format(format)},
            ],
            sortTimeValue: null,
            label: null,
            type: null,
        }
    },
    methods: {
        filterByRange(keyword){
            this.optionFilterByRange.forEach(o => {
                if(o.keyword == keyword){
                    this.sortTimeValue = o.value;
                    this.label = o.label;
                    this.type = o.id
                }
            })
            this.$emit('picker', {label: this.label, value: this.sortTimeValue, type: this.type});
        },
        onChangeOption(event){
            let keyword = event.target.value;
            if(keyword){
                this.filterByRange(keyword);
            }
        }
    },
    mounted(){
    },
    created(){
        this.filterByRange('month');
    }
}
</script>

