<template>
    <div class="row">
        <div class="nav flex-column nav-pills" aria-orientation="vertical">
            <a v-for="(permission, branchId) in arrUserPermission" :key="'permission-' + branchId" class="nav-link"
              v-text="branchMapName[branchId]" style="cursor: pointer"
               @click.prevent="changeTab(branchId)" :class="{active: currentTabId === branchId}"></a>
        </div>
        <div class="tab-content" style="width: 70%; margin-left: 20px">
            <div class="tab-pane show active">
                <q-tree-select-input :options="permissionTree" v-model="currentTab"/>
            </div>
        </div>


    </div>
</template>

<script>
    import {$post, $alert, $get} from "@/lib/utils";
    import QTreeSelectInput from "@/components/qtreeselect/QTreeSelectInput";

    export default {
        name: "TabRole",
        components: {QTreeSelectInput},
        data() {
            return {
                tabUsers: [],
                currentTabId: '',
                currentTab: [],
                arrUserPermission: [],
                permissionTree: [],
                branchMaps: [],
                branchMapName: []
            }
        },
        mounted() {
            this.loadTreePermissions();
        },
        watch: {
            branchMaps: function(newVal) {
                if (newVal && !this.currentTabId) {
                    this.branchMaps = newVal;
                    this.currentTabId = newVal[0].id;
                    this.currentTab = this.arrUserPermission[this.currentTabId];
                }
            },
            currentTabId: function (newVal) {
                if (this.arrUserPermission && this.arrUserPermission[newVal]) {
                    this.currentTab = this.arrUserPermission[newVal];
                }
            }
        },
        methods: {
            changeTab(tab) {
                this.currentTab = this.arrUserPermission[tab] ?? [];
                this.currentTabId = tab;
                location.hash = tab;
            },
            async loadTreePermissions() {
                const res = await $get('/v1/roles/getTreePermissions');

                this.permissionTree = res.tree;
                if (!this.currentTabId && this.branchMaps && this.branchMaps.length) {
                    this.changeTab(this.this.branchMaps[0].id)
                }

            },
        }
    }
</script>

<style scoped lang="scss">
.nav-pills .nav-link.active {
    background-color: #2fa1a1!important;
}

</style>
