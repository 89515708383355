<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h5 class="modal-title py-2">Thêm mới mẫu in mặc định</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    Bạn chắc chắn muốn thêm mới 10 mẫu in mặc định vào hệ thống?
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <AppPermission :permission="this.$permissions.printTemplates.create?? '' ">
                            <button class="btn btn-danger mr-2" @click="save"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        </AppPermission>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: 'ModalAddDataPrintTemplate',
    props: {},
    component: {
        AppPermission,
    },
    data () {
        return {
            
        };
    },
    methods: {
        show() {                                
            window.jQuery(this.$refs.modal).modal('show');
        },     
        async save(){
            const res = await $post(`/v1/print-templates/create_data_default`);            
            if(res && res.status.code == 200){
                toastr.success('Thêm mới mẫu in mặc định thành công');
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('loadData');
            }
            else {
                toastr.success('Thêm mới mẫu in mặc định thành công');
            }
        } 
    }
}
</script>
<style>
</style>