<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-4 head-remove">
                    <h5 class="modal-title py-2">
                        {{ $t('message.customerGroupIndex.titleRemove') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4">
                    {{ $t('message.customerGroupIndex.contentRemove') }}
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="deleteData">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.customerGroupIndex.button.agree') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.customerGroupIndex.button.skip') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import emitter from "@/lib/emitter";
import toastr from 'toastr';
export default {
    name: 'ModalRemoveCustomerGroup',
    props: {},
    data() {
        return {
            id: '',
            name: ''
        };
    },
    methods: {
        show(id, name) {
            this.id = id;
            this.name = name
            window.jQuery(this.$refs.modal).modal('show');
        },
        async deleteData () {  
          const res = await $post(`/v1/customer-group/delete`, {id: this.id});          
           if(res && res.status?.code && res.status.code == 200 && res.result?.status && res.result.status){
            toastr.success(res.result.message);
            window.jQuery(this.$refs.modal).modal('hide');
            this.$emit('input' , '1');
            this.$emit('renderData', '');
            emitter.emit('deleted');
          }
          else {
            toastr.error(res.result.message);
            window.jQuery(this.$refs.modal).modal('hide');            
            this.$emit('input' , '1');            
          }
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>