<template v-if="this.model && this.model.length > 0">
    <template v-for="item in this.model" :key="item.id">
        <div class="row">
            <div class="col-2 pl-2 d-flex align-items-center">
                <label class="container-radio ml-2">
                    <input type="radio" v-model="item.id" name="radio"  :id="'radio' + item.id" :value="item.id" 
                    class="mr-2" @click="selectOptions(item)" :checked="item.id == 0 ? true : false">
                    <span class="checkmark"></span>
                </label>
            </div>
            <div class="col-10 pl-0">                
                <input type="text" :value="item.name" class="txtInputShow" :readonly="true">
            </div>    

        </div>
    </template>
</template>

<script>    
    export default {
        name: "InputRadioSelect",
        components: {},
        props: ['label', 'placeholder', 'modelValue', 'errors', 'datalist','max','cusNumber', 
        'note','dateData', 'id', 'borderBottom', 'paddingLeftNone', 'sCustomer', 'bgTransparent', 'classCustom'],
        data() {
            return {
                model: this.modelValue,            
            }
        },
        watch: {
            modelValue: function (newValue) {                
                this.model = newValue;
            }
        },
        methods: {
            selectOptions(item){     
                let data = {model: item.id, label: 'option', optionsName: item.name}                                     
                this.$emit('update:modelValue', data);
            },

            changeData(){                
                this.$emit('changeData');
            },
            NumbersOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            DateInputOnly(evt) {
                evt = (evt) ? evt : window.event;
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46 && charCode!==47) {
                    evt.preventDefault()
                } else {
                    return true;
                }
            },
            onInput() {
                this.$emit('update:modelValue', this.model);
                this.$nextTick(() => {
                    this.$emit('input', this.model);
                })

            },
            isNumberic(e){
                var charCode = (e.which) ? e.which : e.keyCode
                if (charCode > 31 && (charCode < 48 || charCode > 57))
                return false;
                return true;
            }

        }
    }
</script>

<style scoped>
    .sCustomer{
        border: 0;
        border-bottom: 1px solid #ccc;
        border-radius: 0;
    }
    .error-label {
        list-style: none;
        margin-left: 0;
        padding-left: 0;
        font-weight: bolder;
        color: red;
    }

    .error-input, .error-input:hover,.error-input:focus,.error-input:active {
        border: 1px solid red !important;
        outline: 0;
        box-shadow: unset;
    }
    /* .form-group i{
        position: absolute;
        top: 0.5rem;
    }
    /* .form-group input{
        padding-left: 1.5rem;

    
    } */

    .txtInputShow{
        border: 0;
        border-bottom: 1px solid #ccc;        
    }
</style>
