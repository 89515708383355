<template>
    <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalChecked">
        <div class="modal-dialog modal-lg" role="document" style="width: 609px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px ;">
                    <h6 class="modal-title py-2">
                        {{ $t('message.common.productSelected') }}
                    </h6>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-0 py-0 mx-0 px-0" >
                    <div class="row">
                        <div class="col-12 text-center">     
                            <table class="table table-striped border-1" id="modalTableChecked">
                                <thead>                                    
                                <tr>
                                    <th scope="col" width="10%" class="text-center py-2" @click="unCheckedAll()">
                                        <i class="fa fa-trash text-secondary"></i>
                                    </th>
                                    <th scope="col" width="10%" class="text-center py-2">
                                        {{ $t('message.common.STT') }}
                                    </th>
                                    <th scope="col" width="30%" class="text-left py-2">
                                        {{ $t('message.SaleReportRevenueCustomerGroup.table.productCode') }}
                                    </th>
                                    <th scope="col" width="50%" class="text-left py-2">
                                        {{ $t('message.SaleReportRevenueCustomerGroup.table.productName') }}
                                    </th>
                                    
                                </tr>
                                </thead>
                                <tbody>            
                                    <template v-if="this.data.length >=1">
                                        <tr v-for="(item,index) in this.data" :key="item.id" :id="'modal_' + item.id">
                                            <td scope="row" class=" py-2">
                                                <a href="javascript:;" @click="unChecked(item.id)" >                                                
                                                    <i class="fa fa-trash text-secondary"></i>
                                                </a>
                                            </td>
                                            <td v-text="index + 1" class=" py-2"></td>
                                            <td  class="text-left py-2" v-text="item.code"></td>
                                            <td  class="text-left py-2" v-text="item.name"></td>                                        
                                        </tr>                             
                                    </template>                                               
                                    <template v-else>
                                        <tr>
                                            <td  class="pt-4" colspan="4"><label>{{ $t('message.common.emptyProductSelected') }}</label></td>
                                        </tr>                              
                                    </template>
                                     
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div v-if="this.data?.length > 0" class="pb-2">
                        <Pagination :value="paginate" :record="15" @change="pageChange" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
#modalTableChecked thead th {
    border-bottom: 0 !important;    
}
.table thead th
#modalChecked .modal-header {
    border-bottom: 0 !important;
}
</style>
<script>
import { $post } from "@/lib/utils";
import Pagination from "@/components/Pagination";
export default {
    name: "ModalProductsChecked",
    components: {Pagination},
    data(){        
        return {
            data: [],
            arrayIDs: [],
            paginate: {},
        }
    },
    props: {

    },
    mounted(){

    },
    methods: {        
        show(data) {
            this.array = [];
            window.jQuery(this.$refs.modal).modal('show');
            if(data){
                this.arrayIDs = data;
                this.loadData(1);
            }
        },
        async loadData(page){
            const res = await $post('/v1/products/get-product-by-ids', {
                id: this.arrayIDs,
                page: page
            });
            this.data = res.data;
            this.paginate.totalRecord = res.total;
            this.paginate.currentPage = res.current_page;
            this.paginate.lastPage = res.last_page;
        },
        async pageChange(page) {
            this.loadData(page);
        },
        unChecked(id){
            let arr = this.arrayIDs;
            if(arr.includes(id)){
                arr = arr.filter(item => item !== id)
            }else{
                arr.push(id)
            }
            this.arrayIDs = arr;
            this.$emit('uncheckedItem', id);
            this.loadData(1);
        },
        unCheckedAll(){
            this.arrayIDs = [];
            this.$emit('removeData');
            this.loadData(1);
        }
    }
}
</script>

