<template>
    <div>
        <div class="main-title">
            <h1>{{$t('message.OrderIndex.surcharge')}}</h1>
        </div>
        <div class="row">
            <div class="col-5">
                <div class="search-box">
                    <i class="fas fa-search mr-2"></i>
                    <input type="text" class="search-input"
                           @keydown.enter="doFilter('keyword', filter.keyword)"
                           v-model="filter.keyword" placeholder="Tìm kiếm"/>
                </div>
            </div>
            <div class="col-7 d-flex">
                <button type="button" @click="clearFilter()" class="btn btn-default">Xóa lọc</button>
                <router-link to="/surcharges/form" class="btn  btn-save ml-1"><i class="fa fa-plus"/> Thêm</router-link>
            </div>
        </div>

        <div class="row mt-3">
            <table class="table table-index table-striped">
                <thead>
                <tr>
                    <th scope="col" class="checkbox-table">
                        <input v-model="checkedAll"
                               @change="onCheckedAll(checkedAll)"
                               type="checkbox"/>
                    </th>
                    <th scope="col" v-for="field in fields" :key="field.field">
                        <TableSortField :current="sortData.field"
                                        @onsort="sort"
                                        :name="field.name"
                                        :field="field.field"/>
                    </th>
                    <th></th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="entry in entries" :key="entry.id">
                    <td><input v-model="entry.checked" type="checkbox"/></td>
                    <td scope="col" v-for="field in fields" :key="field.field"
                        v-text="r(entry, field.field, field.render)"></td>
                    <th>
                        <router-link class="btn " :to="'/surcharges/form?id=' + entry.id">
                            <i class="fa fa-edit"/>
                        </router-link>
                    </th>
                </tr>
                </tbody>
            </table>

            <Paginate :value="paginate" @change="pageChange"/>
        </div>
    </div>
</template>

<script>
    import {$alert, $get, clone, setArray, getSearchQuery} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import TableSortField from "@/components/TableSortField";

    export default {
        name: "SurchargeIndex",
        components: {TableSortField, Paginate},
        mounted() {
            document.title = 'OmiPos - Quản lý thu khác';
            this.load()
        },
        data() {
            const query = getSearchQuery();
            return {
                filter: {
                    keyword: query.keyword,
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                }
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/surcharges/index',
                    query: {}
                });

                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });

                this.load();
            },
            getCheckedIDs() {
                return this.entries.filter(e => e.checked).map(e => e.id);
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                await this.$router.push({query: query});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                await this.$router.push({query: {page: page}});
                this.load();
            },
            async load() {
                const res = await $get('/v1/surcharges/data', this.$route.query);
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                setArray(res.data.entries, {
                    checked: false
                });

                this.fields = res.data.fields;
                this.paginate = res.data.paginate;
                this.entries = res.data.entries;

            }
        }
    }
</script>
