<template>
    <div class="col-12 col-lg-12">
        <div class="app-card app-card-chart h-100 shadow-sm">
            <div class="app-card-body p-3 p-lg-4">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex">
                        <h5 class="app-card-title mr-2">
                            {{$t('message.ProductStatistical.title')}} 
                        </h5>
                        <select @change="onChangeOption($event)">
                            <option v-for="option in optionFilter" :key="option.id" :value="option.value" v-text="option.name"></option>
                        </select>
                    </div>
                    <div>
                        <DateRangeDashboardPicker :formatType="'YYYY-MM-DD'" @picker="filterTime"/>
                    </div>
                </div>
                <div class="chart-container">
                    <BarChart :chartData="chartData" :options="options"></BarChart>
                </div>
                <!-- <div class="chart-container" v-else>
                    <div class="col-12 text-center text-secondary mt-5">
                        <img src="../../../public/img/No-record.png">
                    </div>
                    <div class="col-12 text-center my-10 text-secondary mb-5">
                        <span class="border-none">{{$t('message.common.notData')}}</span>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</template>
  <script>
  import { BarChart } from 'vue-chart-3';
  import DateRangeDashboardPicker from '@/components/DateRangeDashboardPicker.vue';

  import { $post, stringTruncate } from "@/lib/utils";
  import store from "@/store";
  export default {
    components: {
        BarChart,
        DateRangeDashboardPicker
    },
    data() {
        return {
            chartData: {},
            options: {
                responsive: true,
                maintainAspectRatio: false,
                legend: false,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let value = new Intl.NumberFormat('en-US').format(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                            return data.labels[tooltipItem.index] + ': ' + value;
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true,
                            callback: function(value) {
                                return new Intl.NumberFormat('en-US').format(value);
                            }
                        }
                    }],
                    xAxes: [{
                            ticks: {
                                fontSize: 12,
                                beginAtZero: true,
                                lineHeight: 1.2,
                                callback: function(value) {
                                    return stringTruncate(value, 10);
                                },
                            }
                        }]
                }
            },
            optionFilter: [
                {id: 1, name: this.$t('message.common.netRevenue'), value: 1},
                {id: 2, name: this.$t('message.common.accordingToQuantity'), value: 2},
            ],
            time: null,
            type: 1
        };
    },
    methods: {
        async load() {
            const res = await $post('/v1/dashBoard/products', {filter: this.time, option: this.type, branchId: store.state.CurrentBranch.id});
            if(res && res.result){
                this.chartData = res.result.chartData;
               
            }           
        },
        onChangeOption(event){
            this.type = event.target.value;
            this.load();
        },
        filterTime(dataFilter){
            if(!dataFilter) return;
            this.time = dataFilter.type;
            this.load();
        }
    },
    mounted(){
        try {
            this.load()
        } catch (e) {
            // console.error(e)
        }
    },
}
    </script>
   <style scoped lang="scss">
   select{
       border: none !important;
       outline: none !important;
   }
   .app-card-title{
       font-weight: 600;
   }
</style>