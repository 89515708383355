<template>
    <div class="container">
        <div class="row">
            <ActionBar back-url="/order-suppliers/index" @action="save()"/>
            <div class="col-12 pb-100">
                <div class="row">
                    <div class="col-9 p-0 card-transaction-left position-relative">
                        <div class="col-md-12 d-flex align-items-center mb-3" style="margin-top: 10px">
                            <div class="col-2 d-flex align-items-center justify-content-lg-between">
                                <svg width="17" height="17" viewBox="0 0 17 17" @click="back()" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M16.1666 7.54183H4.50373L9.86081 2.18475L8.49998 0.833496L0.833313 8.50016L8.49998 16.1668L9.85123 14.8156L4.50373 9.4585H16.1666V7.54183Z" fill="#666666"/>
                                </svg>
                                <div class="main-title custom-title">
                                    <span>
                                        {{$t('message.TransferIndex.title')}}
                                    </span>
                                </div>
                            </div>
                            <div v-if="!isConfirm" class="col-md-6">
                                <ProductSearch @select="onSelectProduct" :readonly="(!form.toBranchId || isConfirm)" :action="$constant.getPriceOrigin" :toBranchId="form.toBranchId" :notAdd="true"></ProductSearch>
                            </div>
                            <div v-if="!isConfirm" class="col-md-2 text-right">
                                {{$t('message.TransferIndex.to_branch')}}
                            </div>
                            <div v-if="!isConfirm" class="col-md-2">
                                <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="form.toBranchId" :defaultOption="this.branches" @update:modelValue="selectBranch" :label="'toBranch'"></SelectSearch>
                            </div>
                        </div>
                        <Pagination v-if="info.typeShow == $constant.typeMatch && productsMatch != null && productsMatch.length && productsMatch.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if=" info.typeShow == $constant.typeDeviated && productsDeviated != null && productsDeviated.length && productsDeviated.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if=" info.typeShow == $constant.typeNotReceived && productsNotReceived != null && productsNotReceived.length && productsNotReceived.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <Pagination v-else-if="info.typeShow == $constant.typeAll && form.products != null && form.products.length && form.products.length > numberRecord" :value="paginate" @change="pageChange" :record="numberRecord"/>
                        <div v-if="isConfirm" class="row ml-0 mt-2">
                            <div :class="[(info.typeShow == $constant.typeAll ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeAll)">{{$t('message.common.all')}} ({{ form.products.length }})</div>
                            <div :class="[(info.typeShow == $constant.typeMatch ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeMatch)">{{$t('message.common.joint')}} ({{ info.match }})</div>
                            <div :class="[(info.typeShow == $constant.typeDeviated ? 'tab_active' : '' ), 'col-md-1 pb-2']" @click="showProducts($constant.typeDeviated)">{{$t('message.common.deviated')}} ({{ info.deviated }})</div>
                            <div :class="[(info.typeShow == $constant.typeNotReceived ? 'tab_active' : '' ), 'col-md-2 pb-2']" @click="showProducts($constant.typeNotReceived)">{{$t('message.common.notReceived')}} ({{ info.notReceived }})</div>
                        </div>
                        <table class="table table-index table-striped table-transaction" style="margin-top: 15px">
                            <thead>
                            <tr >
                                <th v-if="!isConfirm"></th>
                                <th width="2%">{{$t('message.common.STT')}}</th>
                                <th width="10%">{{$t('message.DetailOrdersCard.prodCode')}}</th>
                                <th width="18%">{{$t('message.DetailOrdersCard.prodName')}}</th>                                
                                <th width="10%">{{$t('message.DetailOrdersCard.dvt')}}</th>
                                <th width="10%">{{$t('message.DetailOrdersCard.attribute')}}</th>
                                <th width="10%">{{$t('message.ProductIndex.inventory')}}</th>
                                <th width="12%">{{$t('message.common.quantityTransfer')}}</th>
                                <th v-if="isConfirm" width="11%">{{$t('message.common.quantityReceive')}}</th>
                                <th width="12%">{{$t('message.common.transferPrice')}}</th>
                                <th width="12%">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            <template v-for="(product, index) in 
                                    ( info.typeShow == $constant.typeMatch ? productsMatch : 
                                    (info.typeShow == $constant.typeDeviated ? productsDeviated 
                                    : (info.typeShow == $constant.typeNotReceived ? productsNotReceived : form.products)) )" 
                                :key="product.id" >
                                <template v-if="(index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index)">
                                    <tr>
                                    <td v-if="!isConfirm">
                                        <a class="btn-remove" @click.prevent="removeDetailProduct(product)">
                                            <i class="fas fa-trash"/>
                                        </a>
                                    </td>
                                    <td>{{ index + 1 }}</td>
                                    <td class="py-2">
                                        <router-link :to="{name: 'ProductIndex'}">{{ product.code }}</router-link>
                                    </td>
                                    <td>
                                        {{ product.name }}
                                        <div class="position-relative">
                                            <template v-if="!isConfirm">
                                                <input type="text" v-model.trim="product.note" class="format__input bg-transparent text-left" :placeholder="$t('message.PurchaseOrderIndex.note')">
                                                <span class="noteOrder text-gray" v-if="product.note == null || product.note == ''"><i class="fa fa-pencil-alt"></i></span>
                                            </template>
                                            <div v-if="isConfirm" class="pb-4">{{ product.sendDescription }}</div>
                                        </div>
                                    </td>                    
                                    <td>
                                        <template v-if="isConfirm">
                                            <span v-text="product.productUnit ? product.productUnit.unitName : null"></span>
                                        </template>
                                        <template v-else>
                                            <InputSelect v-model="product.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="product.product_units"
                                                option-label="unitName"
                                                :cardTransaction="true"
                                                :readonly="isConfirm"
                                                @update:modelValue="updateProductUnit(product, (product.hasVariant ? true : false))">
                                            </InputSelect>
                                        </template>
                                    </td>
                                     <td>
                                        <template v-if="isConfirm">
                                            <span v-text="product.variant ? product.variant.name : ''"></span>
                                        </template>
                                        <template v-else-if="!isConfirm && product.hasVariant">
                                            <InputSelect 
                                                v-model="product.variantId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                :options="product.variants"
                                                option-label="name"
                                                :cardTransaction="true"
                                                :readonly="isConfirm"
                                                @update:defaultValue="value => updateAttribute(product, value)">
                                            </InputSelect>
                                        </template>
                                    </td>         
                                    <td class="text-center">
                                        <template v-if="product.hasVariant">
                                            <span>{{ product.inventoryVariant ? n(product.inventoryVariant) : 0 }}</span>
                                        </template>
                                        <template v-else>
                                            <template v-for="inv in product.inventories" :key="inv.id">
                                                <span v-if="inv.productUnitId == product.productUnitId">{{ n(inv.inventories) }}</span>
                                            </template>
                                        </template>
                                    </td>
                                    <td class="">
                                        <span v-if="isConfirm">{{ product.sendQuantity }}</span>
                                        <InputNumberFormat v-if="!isConfirm" :max="$constant.maxNumber" :cardTransaction="true" v-model="product.sendQuantity" @input="checkQuality(product.sendQuantity, product.inventories, index)" :inputTable="true" class="mb-0"  :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                    </td>
                                    <td v-if="isConfirm" class="">
                                        <InputNumberFormat :max="product.sendQuantity" :cardTransaction="true" v-model="product.receiveQuantity" :inputTable="true" class="mb-0" @change="changeReceiveQuantity" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                    </td>
                                    <td class="position-relative" v-if="!isConfirm">
                                        {{  $filters.formatPrice(product.price) }}
                                    </td>
                                    <td class="position-relative" v-else>
                                        {{  $filters.formatPrice(product.productUnit.price) }}
                                    </td>

                                    <td v-if="!isConfirm">{{ $filters.formatPrice(product.price * product.sendQuantity) }}</td>
                                    <td v-else>{{ $filters.formatPrice(product.productUnit.price * product.receiveQuantity) }}</td>
                                    <td colspan="2">
                                        <template v-if="!isConfirm">
                                            <i class="fa fa-plus" @click="addProductPlus(index, product)"></i>
                                        </template>
                                    </td>
                                </tr>

                                <template v-if="product.isConsignment && product.consignmentActive && product.consignmentActive.length">
                                    <tr v-for="(consignment, key) in product.consignmentActive" :key="key">
                                        <td>
                                        </td>
                                        <td class="checkbox-table text-center">
                                            <i v-if="key > 0 && !isConfirm" @click="key != 0 ? removeConsignment(index, key): null" class="fas fa-trash-alt"></i>
                                        </td>
                                        <td colspan="3" :id="'consignmentActive_' + index + '_' + key">
                                            <ChooseConsignment :object="product" @update:value="updateConsignment" :isSaleScreen="true" :noChooseOtherConsignment="isConfirm ? true : false" :defaultValue="consignment" :index="key" :isConfirm="isConfirm ? true : false" :noAddConsignment="true" :isTransfer="!isConfirm ? true : false" :noRemove="isConfirm ? true : false"/>
                                        </td>
                                        <td colspan="6">
                                            <div class="flex-end" v-if="key == 0 && !isConfirm">
                                                <i class="fas fa-plus mr-2" @click="addConsignment(index)"></i>
                                            </div>
                                        </td>
                                    </tr>
                                </template>

                                <!-- Thêm đơn vị tùy chọn  -->
                                <template v-for=" (plus, plusIndex) in  product.plus" :key="plusIndex">
                                    <tr v-if=" index < numberRecord*paginate.currentPage && numberRecord*(paginate.currentPage - 1) <= index">
                                        <td v-if="!isConfirm" colspan="4"></td>
                                        <td v-else colspan="3"></td>                                        
                                        <td class="py-2">
                                            <template v-if="isConfirm">
                                                <span v-text="plus.productUnit ? plus.productUnit.unitName : null"></span>
                                            </template>
                                            <template v-else>
                                                <InputSelect v-model="plus.productUnitId"
                                                :placeholder="$t('message.DetailOrdersCard.chooseUnit')"
                                                :options="plus.product_units"
                                                option-label="unitName"
                                                :cardTransaction="true"
                                                
                                                :readonly="isConfirm"
                                                @update:modelValue="updateProductUnit(plus, (product.hasVariant ? true : false))"
                                            ></InputSelect>
                                            </template>
                                        </td>
                                        <td>
                                            <template v-if="isConfirm">
                                                <span v-text="plus.variant ? plus.variant.name : ''"></span>
                                            </template>
                                            <template v-else-if="!isConfirm && product.hasVariant">
                                                <InputSelect 
                                                    v-model="plus.variantId"
                                                    :placeholder="$t('message.DetailOrdersCard.chooseAttribute')"
                                                    :options="plus.variants"
                                                    option-label="name"
                                                    :cardTransaction="true"
                                                    :readonly="isConfirm"
                                                    @update:defaultValue="value => updateAttribute(plus, value)"
                                                ></InputSelect>
                                            </template>
                                        </td>
                                        <td class="text-center">
                                            <template v-if="product.hasVariant">
                                               <span>{{ plus.inventoryVariant ? n(plus.inventoryVariant) : 0 }}</span>
                                            </template>
                                            <template v-else>
                                               <template v-for="inv in product.inventories" :key="inv.id">
                                                  <span v-if="inv.productUnitId == plus.productUnitId">{{ inv.inventories ?? 0 }}</span>
                                               </template>
                                            </template>
                                        </td>
                                        <td class="">
                                            <span v-if="isConfirm">{{ plus.sendQuantity ?? 0 }}</span>
                                            <InputNumberFormat v-if="!isConfirm" :max="$constant.maxNumber" :cardTransaction="true" v-model="plus.sendQuantity" @input="checkQuality(product.sendQuantity, product.inventories, index)" :inputTable="true" class="mb-0"  :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                        </td>

                                        <td v-if="isConfirm" class="">
                                            <InputNumberFormat :max="plus.sendQuantity" :cardTransaction="true" v-model="plus.receiveQuantity" :inputTable="true" class="mb-0" @change="changeReceiveQuantity" :readonly="(product.consignmentActive && product.consignmentActive?.length) > 0 ? true : false" :class="(product.consignmentActive && product.consignmentActive?.length) > 0 ? 'cursor-ban' : null"/>
                                        </td>
                                      
                                        <td class="">
                                            {{ $filters.formatPrice(plus.price) }}
                                        </td>

                                        <td v-if="!isConfirm">{{ $filters.formatPrice(plus.price * plus.sendQuantity) }}</td>
                                        <td v-else>{{ $filters.formatPrice(plus.price * plus.receiveQuantity) }}</td>
                                        <td>
                                            <a v-if="!isConfirm" class="btn-remove" @click.prevent="removeDetailProductPlus(index, plusIndex)">
                                                <i class="fas fa-trash"/>
                                            </a>
                                        </td>
                                    </tr>

                                    <!-- consignment từng đơn vị -->
                                    <template v-if="plus.consignmentActive && plus.consignmentActive.length">
                                        <tr v-for="(consignment, key) in plus.consignmentActive" :key="key">
                                            <td></td>
                                            <td class="checkbox-table text-center">
                                                <i v-if="key > 0 && !isConfirm" @click="key != 0 ? removeConsignmentPlus(index, key , plusIndex): null" class="fas fa-trash-alt"></i>
                                            </td>
                                            <td colspan="3" :id="'consignmentActive_' + index + '_' + key">
                                                <ChooseConsignment :indexConsigmentActive="key" :object="plus" @update:value="updateConsignmentProductUnit" :isSaleScreen="true" :noChooseOtherConsignment="isConfirm ? true : false" :defaultValue="consignment" :index="index" :isConfirm="isConfirm ? true : false" :noAddConsignment="true" :isTransfer="!isConfirm ? true : false" :noRemove="isConfirm ? true : false" :plusIndex="plusIndex"/>
                                            </td>
                                            <td colspan="6">
                                                <div class="flex-end" v-if="key == 0 && !isConfirm">
                                                    <i class="fas fa-plus mr-2" @click="addConsignmentPlus(index, plusIndex)"></i>
                                                </div>
                                            </td>
                                        </tr>
                                   </template>

                                </template>
                            </template>
                            </template>
                            </tbody>
                        </table>
                        <div class="row d-flex align-items-center importArea" v-if="form.products?.length == 0 || form.products == null">
                            <div class="col-12 d-flex align-items-center justify-content-center" v-if="form.toBranchId !== null">
                                <div class="main-title">
                                    <span>{{$t('message.ImportExcel.title')}}</span>
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center" v-if="form.toBranchId !== null">
                                <div>
                                    ({{$t('message.ImportExcel.defaultFile')}} <a :href="'../template/template_chuyen_hang_' + this.language + '.xlsx'" class="text-primary">{{$t('message.ModalFileUploadProducts.titleExcel')}}</a>)
                                </div>
                            </div>
                            <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="form.toBranchId !== null">
                                <button class="p-2 button-import" :title="$t('message.ImportExcel.chooseFile')" >
                                    <form ref="form">
                                        <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload" accept=".xls, .xlsx" :title="$t('message.ImportExcel.chooseFile')"/>
                                    </form>
                                    <i class="fa fa-file mr-2"></i>{{$t('message.ImportExcel.chooseFile')}}
                                </button>
                            </div>
                            <div class="col-12" v-if="form.toBranchId !== null">
                                <div class="col-12 w-100 mt-3 upload-files" v-if="this.fileName">
                                    <div class="row h-100">
                                        <div class="col-6 d-flex align-items-center">
                                            {{ this.fileName }}
                                        </div>
                                        <div class="col-6 d-flex justify-content-end align-items-center">
                                            <a href="javascript:;" class="text-danger" @click="removeFile(false)">
                                                <i class="fa fa-times"></i>
                                            </a>
                                        </div>
                                    </div>
                                 </div>
                                <div class="col-12 w-100 mt-3">
                                    <div class="alert alert-danger row py-2" v-if="this.messageFile">
                                        {{ this.messageFile }}
                                    </div>
                                </div>
                                <div class="col-12 d-flex align-items-center justify-content-center mt-3" v-if="this.fileName != ''">
                                    <button v-show="this.showBtn" class="p-2 button-import" :title="$t('message.ImportExcel.action')" @click="uploadExcel(this.patient)">
                                        <i class="fa fa-file mr-2"></i>{{$t('message.ImportExcel.action')}}
                                    </button>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-3 pt-2 card-transaction-right">
                        <div class="row card-transaction-right-content">
                            <div class="col-12">
                                <div class="row pb-2">
                                    <div class="col-6">
                                        <div class="row">
                                            <div class="col-2 p-0 d-flex justify-content-end align-items-center">
                                                <h5 class="mb-0"><i class="fa fa-user-circle"></i></h5>
                                            </div>
                                            <div class="col-10 pl-2 py-2">
                                                {{ form.employees }}
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="row format__block-time">
                                            <div class="col-7 pl-0 pr-3 text-right">
                                                {{ form.transferDate }}
                                            </div>
                                            <div class="col-5 pl-0 pr-3 text-right">
                                                {{ form.transferTime }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.TransferIndex.transfer_code')}}
                                    </div>
                                    <div class="col-6">
                                        <input v-if="!isConfirm" type="text" v-model.trim="form.code" class="form-control input__order min-w-100 border-bottom-1 p-0" :placeholder="$t('message.common.autocompleteCode')">
                                        <span v-if="isConfirm">{{ form.code }}</span>
                                    </div>
                                </div>
                                <div class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.status')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        <span v-if="isConfirm">{{$t('message.common.moving')}}</span>
                                        <span v-else>{{$t('message.common.draft')}}</span>
                                    </div>
                                </div>
                                <div v-if="!isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.OrderSupplierIndex.total_quantity')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.totalQuantity }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.transferBranch')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.fromBranch ? form.fromBranch.name: null }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.TransferIndex.transfer_date')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ $filters.formatDateHour(form.deliveryDate) }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.ProductIndex.note')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.sendDescription }}
                                    </div>
                                </div>

                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.totalSendQuantity')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.totalSendQuantity }}
                                    </div>
                                </div>
                                <div v-if="isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        {{$t('message.common.totalReceiveQuantity')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        {{ form.totalReceiveQuantity }}
                                    </div>
                                </div>
                                <!-- <div v-if="!isConfirm" class="row pb-4">
                                    <div class="col-6">
                                        Tới chi nhánh
                                    </div>
                                    <div class="col-6 text-left">
                                        <SelectSearch :placeholder="$t('message.common.placeholder.branch')" :modelValue="form.toBranchId" :defaultOption="this.branches" @update:modelValue="selectBranch" :label="'toBranch'"></SelectSearch>
                                    </div>
                                </div> -->
                                <div class="row pb-2">
                                    <div class="col-12">
                                        <InputTextArea :placeholder="$t('message.PurchaseOrderIndex.note')" v-model.trim="form.note" class="input__textArea mb-0"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row pb-2 card-transaction-button w-100">
                            <div class="col-5">
                                <button class="mt-2 btn_submit btn btn-save" :disabled="isButtonDisabled" @click="submit($constant.statusTicketTransferTemporary)">
                                    <i class="fa fa-save"></i>
                                    <div>
                                        {{$t('message.button.saveDraft')}}
                                    </div>
                                </button>
                            </div>
                            <div class="col-7">
                                <button class="btn btn-success mt-2 btn_submit" :disabled="isButtonDisabled" @click="submit($constant.statusTicketTransferTransported)">
                                    <i class="fa fa-check"></i>
                                    <div v-if="!isConfirm">
                                        {{$t('message.OrderSupplierIndex.status_complete')}} 
                                    </div>
                                    <div v-else>
                                        {{$t('message.common.receive')}}
                                    </div>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ModalErrorsMessage ref="ModalErrorsMessageImportTransfer" />
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import ActionBar from "@/components/ActionBar";    
    import {$post, $alert, $get, rand, $upload, clone} from "@/lib/utils";
    import toastr from 'toastr';
    import InputTextArea from "../../components/forms/InputTextArea";
    import InputSelect from "../../components/forms/InputSelect";
    import InputNumberFormat from "../../components/forms/InputNumberFormat";
    import InputDatePicker from "../../components/forms/InputDatePicker";
    import moment from "moment";
    import store from "../../store";
    import ProductSearch from "@/components/ProductSearch.vue";
    import SupplierSearch from "@/components/SupplierSearch.vue";
    import router from "@/router/index";
    import currencyMixin from "@/currencyMixin";
    import InputTimePicker from "../../components/forms/InputTimePicker.vue";
    import Pagination from "@/components/Pagination"
    import SelectSearch from "@/components/SelectSearch.vue";
    import emitter from "@/lib/emitter";
    import ModalErrorsMessage from '../order_suppliers/ModalErrorsMessage.vue';
    import ChooseConsignment from "@/components/ChooseConsignment.vue";
    export default {
        name: "TransferCreateUpdate",
        mixins: [currencyMixin],
        components: {ActionBar, InputTextArea, InputSelect, InputNumberFormat, ModalErrorsMessage, ChooseConsignment,
        InputDatePicker, ProductSearch, SupplierSearch, InputTimePicker, Pagination, SelectSearch},
        data() {
            let currentDate = new Date().toJSON().slice(0, 10);
            const globalConfig = store.state.GlobalConfig;
            return {
                branches: globalConfig.branches,
                form: {
                    id: null,
                    toBranchId: null,
                    products: [],
                    employees: store.state.Auth.user.name,
                    currentBranchId: store.state.CurrentBranch.id,
                    // transferDate: currentDate,
                    transferDate: moment(currentDate).format('DD/MM/YYYY'),
                    transferTime: moment().format('HH:mm'),
                    code: null,
                    totalQuantity: 0,
                    status: this.$constant.statusOptionsTicketTemporary,
                    note: null,

                    // confirm transfer
                    fromBranch: null,
                    deliveryDate: null,
                    sendDescription: null,
                    totalSendQuantity: 0,
                    totalReceiveQuantity: 0,
                },
                productIds: [],
                productsMatch: [],
                productsDeviated: [],
                productsNotReceived: [],
                paginate: {
                    totalRecord: 0,
                    currentPage: 1,
                    lastPage: 0,
                },
                numberRecord: 15,
                isEdit: false,
                isCopy:false,
                isConfirm: false,
                info : {
                    match: 0,
                    deviated: 0,
                    notReceived: 0,
                    typeShow: this.$constant.typeAll,
                },
                patient: {
                    file: [],
                    branchId: '',
                    toBranch: ''
                },
                fileName: '',
                isButtonDisabled: false,
                language: localStorage.getItem('language') ?? 'vi'
            }
        },
        mounted() {
            document.title = this.$t('message.TransferIndex.title');
        },
        watch: {
            form: {
                handler: function(dataNew) {
                    if (dataNew.products !== null) {
                        let totalQuantity = 0;
                        let totalReceiveQuantity = 0;
                        this.productIds = [];
                        dataNew.products.forEach(product => {
                            this.productIds.push(product.id);
                            totalQuantity += (product.sendQuantity ?? 0);
                            totalReceiveQuantity += (product.receiveQuantity ?? 0);
                            if(product.variant){
                                product.inventoryVariant = product.variant.product_branch_meta_has_one ? product.variant.product_branch_meta_has_one.inventories : 0;
                            }
                            if (product.consignmentActive && product.consignmentActive?.length > 0) {
                                let quantity = 0;
                                let receive = 0;
                                product.consignmentActive.forEach(item => {
                                if (item.quantity) {
                                    if (this.isConfirm) {
                                        receive = +item.quantity + +receive;
                                        item.checkMaxQuantity = true;
                                    }else{
                                        quantity = +item.quantity + +quantity;
                                    }
                                }
                                });
                                if (this.isConfirm) {
                                    product.receiveQuantity = receive;
                                }else{
                                    product.sendQuantity = quantity;
                                }

                                if (product.plus && product.plus?.length > 0) {
                                    product.plus.forEach(plusItem => {
                                        if(plusItem.variant){
                                            plusItem.inventoryVariant = plusItem.variant.product_branch_meta_has_one ? plusItem.variant.product_branch_meta_has_one.inventories : 0;
                                        }
                                        if (plusItem.consignmentActive && plusItem.consignmentActive?.length > 0) {
                                            let quantity = 0;
                                            let receive = 0;
                                            plusItem.consignmentActive.forEach(item => {
                                            if (item.quantity) {
                                                if (this.isConfirm) {
                                                    receive = +item.quantity + +receive;
                                                    
                                                }else{
                                                    quantity = +item.quantity + +quantity;
                                                  
                                                }
                                            }
                                            });
                                            if (this.isConfirm) {
                                                plusItem.receiveQuantity = receive;
                                                totalReceiveQuantity += receive
                                            }else{
                                                plusItem.sendQuantity = quantity;
                                                totalQuantity += quantity
                                            }
                                        }
                                    });
                                }
                                // product.sendQuantity = quantity;
                                // product.receiveQuantity = receive;
                                // product.consignmentsActive = product.consignmentActive;
                            } else {
                                if (product.plus && product.plus.length) {
                                    product.plus.forEach(plusItem => {
                                        if(plusItem.variant){
                                            plusItem.inventoryVariant = plusItem.variant.product_branch_meta_has_one ? plusItem.variant.product_branch_meta_has_one.inventories : 0;
                                        }
                                        if (this.isConfirm) {
                                            totalReceiveQuantity += (plusItem.receiveQuantity ?? 0);
                                        }else {
                                            totalQuantity += (plusItem.sendQuantity ?? 0);
                                        }
                                    });
                                }
                            }
                        });
                        this.form.totalQuantity = totalQuantity;
                        this.paginate.totalRecord = dataNew.products.length;
                        this.paginate.lastPage = Math.ceil(dataNew.products.length / this.numberRecord);
                        this.form.totalReceiveQuantity = totalReceiveQuantity;
                        if (this.isConfirm) {
                            this.changePaginate();
                        }
                    } 
                },
                deep: true
            },
            'form.products': {
                handler: function(newProducts) {
                    let now = moment().format('YYYY-MM-DD');
                    newProducts.forEach(product => {
                        if (product.isConsignment) {
                            if (!product.consignmentActive || (product.consignmentActive && product.consignmentActive.length == 0)) {
                                var minExpired = product.consignments.reduce((acc, value) => {
                                    if(value.consignment_product_branch_metas){
                                        return (acc = ((moment(acc).format('YYYY-MM-DD') <= moment(value.expried).format('YYYY-MM-DD')) && moment(acc).format('YYYY-MM-DD') >= now) ||  value.consignment_product_branch_metas.length <= 0
                                                    ? acc 
                                                    : value.expried);
                                    }
                                }, 0);
                                var index = product.consignments.findIndex(consignment => consignment.expried == minExpired);
                                if(product.consignments[index] && product.consignments[index].consignment_product_branch_metas){
                                    let consignmentProductBranchMeta = product.consignments[index].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == product.productUnitId);
                                    if (consignmentProductBranchMeta) {
                                        let consignmentActive = {
                                            consignmentProductBranchMetaId: consignmentProductBranchMeta.id ?? null,
                                            consignmentId: consignmentProductBranchMeta.consignmentId ?? null,
                                            id: consignmentProductBranchMeta.consignmentId ?? null,
                                            name: product.consignments[index].name ?? null,
                                            expried: product.consignments[index].expried ?? null,
                                            quantity: product.quantity ?? 1,
                                            quantityDraft: product.quantity ?? 1,
                                            key: 1,
                                            productUnitId: product.productUnitId ?? null,
                                            inventory: consignmentProductBranchMeta.inventory,
                                            productId: product.id,
                                        }
                                        product.consignmentActive = [consignmentActive];
                                    }

                                }
                            }
                            if (product.plus && product.plus.length) {
                                product.plus.forEach(element => {
                                    if (!element.consignmentActive) {
                                        var minExpired = element.consignments.reduce((acc, value) => {
                                            if(value.consignment_product_branch_metas){
                                                return (acc = ((moment(acc).format('YYYY-MM-DD') <= moment(value.expried).format('YYYY-MM-DD')) && moment(acc).format('YYYY-MM-DD') >= now) ||  value.consignment_product_branch_metas.length <= 0
                                                            ? acc 
                                                            : value.expried);
                                            }
                                        }, 0);
                                        var index = element.consignments.findIndex(consignment => consignment.expried == minExpired);
                                        if(element.consignments[index] && element.consignments[index].consignment_product_branch_metas){
                                            let consignmentProductBranchMeta = element.consignments[index].consignment_product_branch_metas.find(item => item.product_branch_meta.productUnitId == element.productUnitId);
                                            if (consignmentProductBranchMeta) {
                                                let consignmentActive = {
                                                    consignmentProductBranchMetaId:  consignmentProductBranchMeta.id ?? null,
                                                    consignmentId: consignmentProductBranchMeta.consignmentId ?? null,
                                                    id: consignmentProductBranchMeta.consignmentId ?? null,
                                                    name: element.consignments[index].name ?? null,
                                                    expried: element.consignments[index].expried ?? null,
                                                    quantity: element.quantity ?? 1,
                                                    quantityDraft: element.quantity ?? 1,
                                                    key: 1,
                                                    productUnitId: element.productUnitId ?? null,
                                                    inventory: consignmentProductBranchMeta.inventory,
                                                    productId: product.id,
                                                }
                                                element.consignmentActive = [consignmentActive];
                                            }
                                            
                                        }
                                    }
                                });
                            }
                        }
                    });
                },
                deep: true
            },
        },
        created() {
            if (this.$route.query && this.$route.query.id && this.$route.query.type && this.$route.query.type == 'copy'){                 
                 this.isCopy =  true;
                 this.copyTransferDetailById(this.$route.query.id, this.$route.query.type);
            }
            else if (this.$route.params && this.$route.params.id) {                                
                let action = null;
                if (this.$route.query.action) {
                    action = this.$route.query.action
                    if (action == this.$constant.actionConfirmTransfer) {
                        this.isEdit =  true;
                        this.isConfirm = true;
                    }
                } else {
                    this.isEdit =  true;
                }
                this.getTransferDetailById(this.$route.params.id, action);
            }
        },
        methods: {
            changePaginate() {
                if (this.info.typeShow == this.$constant.typeMatch) {
                    this.paginate.totalRecord = this.productsMatch.length;
                    this.paginate.lastPage = Math.ceil(this.productsMatch.length / this.numberRecord);
                } else if (this.info.typeShow == this.$constant.typeDeviated) {
                    this.paginate.totalRecord = this.productsDeviated.length;
                    this.paginate.lastPage = Math.ceil(this.productsDeviated.length / this.numberRecord);
                } else if (this.info.typeShow == this.$constant.typeNotReceived) {
                    this.paginate.totalRecord = this.productsNotReceived.length;
                    this.paginate.lastPage = Math.ceil(this.productsNotReceived.length / this.numberRecord);
                } else {
                    this.paginate.totalRecord = this.form.products.length;
                    this.paginate.lastPage = Math.ceil(this.form.products.length / this.numberRecord);
                }
            },
            changeReceiveQuantity() {
                let match = 0;
                let notReceived = 0;
                let deviated = 0
                this.productsMatch = [];
                this.productsDeviated = [];
                this.productsNotReceived = [];
                this.form.products.forEach(product => {
                    if (product.sendQuantity == product.receiveQuantity) {
                        match = match + 1; 
                        this.productsMatch.push(product);
                    } else if (product.sendQuantity != product.receiveQuantity && product.receiveQuantity) {
                        deviated = deviated + 1;
                        this.productsDeviated.push(product);
                    } else if (!product.receiveQuantity) {
                        notReceived = notReceived + 1; 
                        this.productsNotReceived.push(product);
                    } 
                    if(this.isConfirm){
                        product.receiveQuantity = product.receiveQuantity > product.sendQuantity ? product.sendQuantity : product.receiveQuantity;
                    }
                    if(product.plus && product.plus?.length){
                        product.plus.forEach(plusItem => {
                            if(this.isConfirm){
                                plusItem.receiveQuantity = plusItem.receiveQuantity > plusItem.sendQuantity ? plusItem.sendQuantity : plusItem.receiveQuantity;
                            }
                        });
                    }
                });
                this.info.match = match;
                this.info.notReceived = notReceived;
                this.info.deviated = deviated;
                this.changePaginate();

            },
            showProducts(type) {
                this.info.typeShow = type;
                this.paginate.currentPage = 1;
                this.changePaginate();
            },
            checkQuality(sendQuantity, inventories, $key) {
                if (sendQuantity > inventories) {
                    this.form.products[$key].sendQuantity = inventories;
                }
            },
            setDefaultQuantity(product){
                product.forEach(item => {
                    item.consignmentActive = null;
                    if (item.plus && item.plus.length > 0) {
                        item.plus.forEach(element => {
                            element.sendQuantity = 0;
                            element.consignmentActive = null;
                        });
                    }
                });
            },
            async copyTransferDetailById(id, action) { 
                const res = await $get('/v1/transfers/copy', {
                    id: id,
                    action: action,
                });
                if (res.status.code == 200) {
                    const transfer = res.result;                    
                    this.form.id = transfer.id;
                    this.form.toBranchId =transfer.toBranchId;
                    // Set số lượng chuyển về 0
                    // this.setDefaultQuantity(transfer.products);
                    this.form.products = transfer.products;
                    this.form.code = null;
                    this.form.totalQuantity = transfer.totalQuantity;
                    this.form.note = null;
                }
            },
            async getTransferDetailById(id, action) {
                const res = await $get('/v1/transfers/get-detail-by-id', {
                    id: id,
                    action: action,
                });
                if (res.status.code == 200) {
                    const transfer = res.result;
                    this.form.products = transfer.products;
                    this.form.products.forEach(product => {
                        product.inventories = product.inventoriesMeta;
                    });
                    this.form.totalQuantity = transfer.totalQuantity;
                    this.form.id = transfer.id;
                    this.form.code = transfer.code;
                    this.form.toBranchId = transfer.toBranchId;
                    if (!this.isConfirm) {
                        this.form.note = transfer.sendDescription;
                    } else {
                        this.form.note = transfer.receiveDescription;
                        this.form.refCode = transfer.refCode;
                    }

                    // is confirm
                    if (this.isConfirm) {
                        this.form.fromBranch = transfer.from_branch ?? null;
                        this.form.deliveryDate = transfer.deliveryDate;
                        this.form.sendDescription = transfer.sendDescription;
                        this.form.totalSendQuantity = transfer.totalSendQuantity;
                        this.form.fromBranchId = transfer.fromBranchId;
                        this.form.products.forEach(product => {
                            product.typeShow = this.info.typeAll;
                        });
                        this.changeReceiveQuantity();
                    }
                }
            },
            pageChange(page) {
               this.paginate.currentPage = page;
            },
            back() {
                router.push({ name: 'TransferIndex'});
            },
            selectBranch(v) {
                this.form.toBranchId = v.model;
                // check sp có tồn tại ở kho mới hay k
                if (this.productIds.length) {
                    this.checkProductExistNewBranch(this.form.toBranchId);
                }
            },
            async checkProductExistNewBranch(branchId) {
                const res = await $post('/v1/transfers/check-products-exist-branch', {
                    branchId: branchId,
                    productIds: this.productIds,
                });
                const productsOrigin = Object.keys(res.result).map((key) => res.result[key]);
                this.form.products = this.form.products.filter(function(product){
                        return (productsOrigin.includes(product.id));
                    });
            },
            onSelectProduct(product) {
                if (!this.form.products.length || !this.form.products.find(item => item.id === product.id)) {
                    if (product.inventories < 1) {
                        toastr.error(this.$t('message.common.outOfStock'));
                        return;
                    }
                    if (product.product_units) {
                        product.productUnit = product.product_units[0];
                        product.productUnitId = product.product_units[0].id;
                        product.price = product.product_units[0].price;
                        product.sendQuantity = 0;
                        if(product.hasVariant){
                            this.getListAttributeForUnit(product);
                        }
                    }
                    this.form.products.unshift(product);
                }
            },
            removeDetailProduct(product) {
                if (!confirm(this.$t('message.ProductIndex.modal.confirm_remove_product', {name: product.name}))) {
                    return;
                }
                let itemIndex = -1;
                this.form.products.forEach((item, index) => {
                    if (item.id === product.id) {
                        itemIndex = index;
                    }
                });

                if (itemIndex > -1) {
                    this.form.products.splice(itemIndex, 1);
                }  
            },
            async submit(status) {
                if (this.form.products.length === 0) {
                    toastr.error(this.$t('message.common.emptyTransfer'));
                    return;
                }
                if (!this.isConfirm && this.form.toBranchId == store.state.CurrentBranch.id) {
                    toastr.error(this.$t('message.common.inValidReceiveBranch'));
                    return;
                }
                
                if (this.isConfirm && this.info.match != this.form.products.length) {
                    if (this.form.note == '' || this.form.note == null) {
                        toastr.error(this.$t('message.common.emptyNote'));
                        return;
                    }
                }
                let save = true;
                for (let index = 0; index < this.form.products.length; index++) {
                    const product = this.form.products[index];
                    if(product.isConsignment && !this.isConfirm) {
                        if( product.consignmentActive && product.consignmentActive.length <= 0){
                            toastr.error(this.$t('message.common.emptyBatch', {name: product.name}));
                            save = false;
                            return;
                        }
                        if(product.consignmentActive && product.consignmentActive.length){
                            product.consignmentActive.forEach(consignment => {
                                if(!consignment.id){
                                    toastr.error(this.$t('message.common.emptyConsignment', {name: product.name}));
                                    save = false;
                                    return;
                                }
                            });
                        }
                        // Plus
                        if (product.plus && product.plus.length) {
                            for (let plusIndex = 0; plusIndex < product.plus.length; plusIndex++) {
                                const plus = product.plus[plusIndex];
                                if( plus.consignmentActive && plus.consignmentActive.length <= 0){
                                    toastr.error(this.$t('message.common.emptyBatch', {name: product.name}));
                                    save = false;
                                    return;
                                }
                                if(plus.consignmentActive && plus.consignmentActive.length){
                                    plus.consignmentActive.forEach(consignment => {
                                        if(!consignment.id){
                                            toastr.error(this.$t('message.common.emptyConsignment', {name: product.name}));
                                            save = false;
                                            return;
                                        }
                                    });
                                }
                            }
                        }
                    }
                    if (this.isConfirm && product.receiveQuantity && product.sendQuantity && product.receiveQuantity > product.sendQuantity) {
                        toastr.error(this.$t('message.common.inValidReceiveQuantity', {name: product.name}));
                        save = false;
                        return;
                    }
                    if (product.plus && product.plus.length) {
                        for (let plusIndex = 0; plusIndex < product.plus.length; plusIndex++) {
                            const plus = product.plus[plusIndex];
                            if (this.isConfirm && plus.receiveQuantity && plus.sendQuantity && plus.receiveQuantity > plus.sendQuantity) {
                                toastr.error(this.$t('message.common.inValidReceiveQuantity', {name: product.name}));
                                save = false;
                                return;
                            }
                        }
                    }
                    if (this.isConfirm && !product.receiveQuantity) {
                        product.receiveQuantity = 0;
                    }
                }
                if(save){
                    const params = {
                        toBranchId: this.form.toBranchId,
                        products: this.form.products,
                        branchId: store.state.CurrentBranch.id,
                        code: this.form.code,
                        status: status,
                        note: this.form.note,
                        totalQuantity: this.form.totalQuantity,
                        type: this.$constant.typeTransfer,
                        productIds: this.productIds,
                    };
                    this.isButtonDisabled = true;
                    let res;
                    if (!this.isEdit && !this.isConfirm) {
                        res = await $post('/v1/transfers/create', {...params});
                    } else if (this.isConfirm) {
                        params.action = this.$constant.actionConfirmTransfer;
                        params.totalQuantity = this.form.totalReceiveQuantity;
                        params.id = this.form.id;
                        params.fromBranchId = this.form.fromBranchId;
                        params.refCode = this.form.refCode;
                        if (status == this.$constant.statusTicketTransferTemporary) {
                            params.status = this.$constant.statusTicketTransferTransported;
                        } else {
                            params.status = this.$constant.statusTicketTransferReceive;
                        }
                        res = await $post('/v1/transfers/update', {...params});
                    } else {
                        params.id = this.form.id;
                        res = await $post('/v1/transfers/update', {...params});
                    }
                    if (res.code === 422) {
                        const errors = res.messages.errors;
                        Object.keys(errors).forEach(function(key) {
                            $alert({code: res.code, message: errors[key][0]});
                        });
                        this.isButtonDisabled = false;
                    } else if (res.status.code == 200 && res.result.status) {
                        if (this.isEdit && !this.isConfirm) {
                            $alert({code: 200, message: this.$t('message.common.updateTransferSuccess')});
                        } else if (this.isEdit && this.isConfirm) {
                            if (params.status != this.$constant.statusTicketTransferReceive) {
                                $alert({code: 200, message: this.$t('message.common.confirmTransferSuccess')});
                            } else {
                                $alert({code: 200, message: this.$t('message.common.receiveSuccess')});
                            }
                        } else {
                            $alert({code: 200, message: this.$t('message.common.addTransferSuccess')});
                        }
                        this.$router.push({name: 'TransferIndex'});
                    } else {
                        $alert({code: 500, message: this.$t('message.common.errorServer')});
                        this.isButtonDisabled = false;
                    }
                }
            },
            onFileChange(event) {
                var file = event.target.files;
                if (!file.length) {
                    return;
                }
                this.fileName = file[0].name;
                this.patient.file = file[0];
                let id = document.getElementById('branchDropdown');
                // this.patient.branchId = id.getAttribute('data-id');
                this.patient.branchId = store.state.CurrentBranch.id;
                this.patient.toBranch = this.form.toBranchId
                if (file[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file[0].type === 'application/vnd.ms-excel') {
                    this.showBtn = true;
                    this.messageFile = '';

                } else {
                    this.showBtn = false;
                    this.messageFile = this.$t('message.common.inValidFormatFile')
                }
                this.$refs.form.reset()
            },
            removeFile(status) {
            this.patient.file = [];
            this.fileName = '';
                if (status == false) {
                    this.$refs.fileupload.value = '';
                    this.messageFile = ''
                }
            },
            async uploadExcel (file) 
            {
                emitter.emit("onLoading");
                const result = await $upload('/v1/transfers/import', [], file);
                if (result.result.code == 422)
                {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    emitter.emit("offLoading");
                }else {
                    emitter.emit("errMsgShow", true);
                    emitter.emit("dataMsgShow", result.result);
                    emitter.emit("fileName", this.fileName);
                    this.form.products = result.result.data;
                    emitter.emit("offLoading");
                }
            },
            async updateConsignment(data){
                if(this.form.products){
                    let productIndex = this.form.products.findIndex(prod => prod.id == data.object.id);
                    if(data.data?.length == 0 && data.defaultValue){
                        this.form.products[productIndex].consignmentActive[data.index].consignmentId = null;
                        this.form.products[productIndex].consignmentActive[data.index].id = null;
                        this.form.products[productIndex].consignmentActive[data.index].quantity = 0;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let newConsignment = {
                            consignmentId: consignment.id,
                            id: consignment.id,
                            quantity: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            maxQuantity: data.defaultValue.maxQuantity,
                            quantityDraft: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                            inventory: consignment.inventory,
                            productId: data.object.id,
                        };
                        if(this.form.products[productIndex].consignmentActive?.length == 1 && this.form.products[productIndex].consignmentActive[data.index].consignmentId == null){
                            this.form.products[productIndex].consignmentActive[data.index] = newConsignment;
                        }else{
                            this.form.products[productIndex].consignmentActive[data.index] = newConsignment;
                        }
                    });
                }
            },
            addConsignment(productIndex){
                var maxKey = this.form.products[productIndex].consignmentActive.reduce((acc, value) => {
                    return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                }, 0);
                this.form.products[productIndex].consignmentActive.push({
                    key: maxKey + 1,
                    consignmentId: null,
                });
            },
            removeConsignment(productIndex, consignmentIndex) {
                this.form.products[productIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            updateConsignmentProductUnit (data) {
                if(this.form.products){
                    // let productIndex = this.form.products.findIndex(prod => prod.id == data.object.id);
                    if(data.data?.length == 0 && data.defaultValue){
                        this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId = null;
                        this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].id = null;
                        this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].quantity = 0;
                        return;
                    }
                    data.data.forEach(consignment => {
                        let productUnitId = data.object.productUnitId;
                        let newConsignment = {
                            consignmentId: consignment.id,
                            id: consignment.id,
                            quantity: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            maxQuantity: data.defaultValue.maxQuantity,
                            quantityDraft: consignment.quantity ?? (consignment.quantityDraft ?? 1),
                            name: consignment.name,
                            expried: consignment.expried,
                            key: data.defaultValue.key,
                            productUnitId: productUnitId,
                            inventory: consignment.inventory,
                            productId: data.object.id,
                        };
                        if(this.form.products[data.index].plus[data.plusIndex].consignmentActive?.length == 1 && this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive].consignmentId == null){
                            this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive] = newConsignment;
                        }else{
                            this.form.products[data.index].plus[data.plusIndex].consignmentActive[data.indexConsigmentActive] = newConsignment;
                        }
                    });
                }
            },
            updateProductUnit(product, hasVariant = false) {
                if (product.product_units && product.product_units.length) {
                    product.productUnit = product.product_units.find(el => (el.id == product.productUnitId));
                    product.quantity = 1;
                    product.discountType = null;
                    product.discountValue = 0;
                    product.price = product.productUnit ? product.productUnit.price : 0;
                    if(hasVariant){
                        this.getListAttributeForUnit(product);
                    }
                }   
            },

            updateAttribute(product, attribute){
                product.price = 0;
                if(attribute){
                    product.variant = attribute;
                    product.price = attribute.price;
                }
            },

            async getListAttributeForUnit(product){
                const result = await $post('/v1/products/get-list-attributes-for-unit', {
                    productUnitId: product.productUnitId,
                    branchId: store.state.CurrentBranch.id,
                });
                product.variants = [];
                product.variantId = null;
                if(result && result.result){
                    product.variants = result.result;
                    product.variantId = result.result && result.result?.length ? result.result[0].id : null;
                    product.price = result.result && result.result?.length ? result.result[0].price : null;
                    product.variant = result.result && result.result?.length ? result.result[0] : null;
                }
            },
            addProductPlus (productIndex, productOrigin) {
                let product = clone(productOrigin);
                let plus = {
                    productUnitId: null,
                    price: 0,
                    product_units: product.product_units,
                    id: product.id,
                    isPlus: true,
                    isConsignment: product.isConsignment,
                    consignmentActive: product.consignmentActive,
                    consignments: product.consignments,
                    sendQuantity: 0
                }
                if (!this.form.products[productIndex].plus) {
                    this.form.products[productIndex].plus = [];
                }
                plus.index = this.form.products[productIndex].plus.length;
                this.form.products[productIndex].plus.push(plus);
            },
            removeDetailProductPlus(productIdex, plusIndex) {
                this.form.products[productIdex].plus.splice(plusIndex, 1);
            },
            removeConsignmentPlus(productIndex, consignmentIndex, plusIndex) {
                this.form.products[productIndex].plus[plusIndex].consignmentActive.splice(consignmentIndex, 1);
            },
            addConsignmentPlus(productIndex, plusIndex){
                var maxKey = this.form.products[productIndex].plus[plusIndex].consignmentActive.reduce((acc, value) => {
                    return (acc = acc > (value.key ? value.key : 1) ? acc : (value.key ? value.key : 1));
                }, 0);
                this.form.products[productIndex].plus[plusIndex].consignmentActive.push({
                    key: maxKey + 1,
                    consignmentId: null,
                });
            },

        }
    }
</script>

<style scoped lang="scss">
@import "../../assets/css/vars.scss";
    .purchase-form {
        display: flex;
        width: 100%;
        margin-top: 10px;
        align-items: center;
    }

    .purchase-form__label {
        width: 28%;
    }

    .purchase-form__input {
        flex: 1
    }
    .table tbody td {
        vertical-align: middle !important;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .btn_submit:focus{
        outline: none;
        border: none;
    }
    .custom__select {
        height: 35px;
        border: none;
        border-bottom: 1px solid #C7C5C5;
        color: #495057;
    }
    .format__list-time {
        top: 0 !important;
        left: 0 !important;
        width: 220px !important;
        height: 160px;
        overflow: auto;
    }
    .input__order .form-control {
        border: none;
        border-bottom: solid 1px #ccc;
    }
    .main__content {
        margin: 10px 30px;
    }
    .input__order, input {
        width: 90%;
    }
    .input__order {
        display: flex;
        align-items: center;
        justify-content: end;
    }
    input[type=checkbox], input[type=radio] {
        width: 10% !important;
    }
    .input__textArea textarea {
        border: none;
        resize: none;
        border-bottom: solid 1px #ccc;
    }
    .far {
        position: absolute;
        right: 25px;
        top: 11px;
        font: normal normal normal 14px/1;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    .format__input {
        /* height: 100%; */
        border-bottom: 1px solid #C7C5C5!important;
        margin-bottom: 10px;
        text-align: right;
        border: none;
        background: none;
        width: 100%;
    }
    .format__input:focus{
        border: none;
        outline: none;
    }
    .format__input::placeholder{
        font-style: italic;
    }
    .format__discount {
        padding-right: 10px;
        border-bottom: 1px solid #eeeeee !important;
    }
    .importArea{
        position: absolute;
        top: 40%;
        left: 0%;
        width: 100%;
    }
    .button-import{
        border-radius: 3px;
        border:none;
        outline: none;
        background: $base-color;
        color: #ffffff;
    }
    .button-import:focus{
        outline: none;
        border: none;
    }
    .button-import input{
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1;
        font: 170px monospace;
        filter: alpha(opacity=0);
        opacity: 0;
        margin: 0;
        padding: 0;
        cursor: pointer;
        width: 100%;
    }
    .upload-files{
        overflow: hidden;
        padding: 1px 5px 2px;
        background: #ebf7fb;
        border: 1px solid #c5e7f2!important;
        min-height: inherit;
        line-height: inherit;
        -webkit-border-radius: 3px;
        -moz-border-radius: 3px;
        border-radius: 3px;
        height: 40px;
    }
    .popup-update-price{
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 99;
        width: 350px;
        background: white;
        box-shadow: 0 5px 25px rgb(0 0 0 / 25%);
        padding: 1.4rem;
        border: none;
        border-radius: 7px;
    }
    .noteOrder{
        position: absolute;
        top: 0px;
        left: 60px;
    }
    .table-transaction thead th, .table-transaction tbody td{
        padding-top: 10px !important;
        vertical-align: top !important;
    }
    #typeCard {
        border: none;
        box-shadow: none;
    }
    .btn_submit {
        width: 100%;
        height: 70px;
        border: none;
        color: #FFFFFF;
        border-radius: 5px;
    }
    .format__block-time {
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
    }
    .custom-title {
        width: 100%;
        text-align: center;
    }
    .btn-primary, .btn-primary:hover{
        background: #0090DA !important;
    }
    .tab_active {
        border-bottom: 1px solid $base-color;
    }
</style>
