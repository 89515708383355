<template>
    <div class="row info-row">
        <div class="col-12 col-lg-12 text-bold mb-3">
            {{ $t('message.ProductIndex.info') }}
        </div>
        <div class="col-lg-6">
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-3 text-bold">{{ $t('message.noticeDetail.tables.promotion.code') }}</div>
                <div class="col-9">
                    <input type="text" name="code" id="code" v-model="entry.code" class="form-control promotion-input" autocomplete="off" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.code')">
                </div>
            </div>
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-3 text-bold">{{ $t('message.CouponIndex.name') }}</div>
                <div class="col-9">
                    <input type="text" name="name" id="name" v-model="entry.name" class="form-control promotion-input" autocomplete="off" :placeholder="$t('message.CouponIndex.modal.create_or_update.placeholder.name')">
                </div>
            </div>
        </div>
        <div class="col-lg-6">
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-3 text-bold">{{$t('message.common.status')}}</div>
                <div class="col-4 d-flex align-items-center">
                    <label class="container-radio ml-2 min-vw-100">{{$t('message.CouponIndex.modal.create_or_update.status_active')}}
                        <input type="radio" name="status" v-model="entry.status" :checked="entry.status === 1" :value="1" class="mr-2">
                        <span class="checkmark"></span>
                    </label>
                </div>
                <div class="col-5 d-flex align-items-center">
                    <label class="container-radio ml-2 min-vw-100">{{$t('message.CouponIndex.modal.create_or_update.status_inactive')}}
                        <input type="radio" name="status" v-model="entry.status" :checked="entry.status === 2" :value="2" class="mr-2">
                        <span class="checkmark"></span>
                    </label>
                </div>
            </div>
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-lg-12 position-relative">
                    <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                    <input type="text" name="note" id="note" v-model="entry.note" class="form-control promotion-input pl-4" autocomplete="off" :placeholder="$t('message.CouponIndex.note')">
                </div>
            </div>
        </div>
    </div>

    <div class="row mt-3">
        <div class="col-12 col-lg-12 text-bold mb-3">
            {{ $t('message.common.formOfPromotion') }}
        </div>
        <div class="col-lg-4">
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-5 text-bold">{{$t('message.common.promotionBy')}}</div>
                <div class="col-7">
                    <SelectSearch :placeholder="$t('message.common.selectPromotionType')" :modelValue="filterQuery.promotion" :defaultOption="optionPromotions" @update:modelValue="doFilterSearch" :label="'promotion'"/>
                </div>
            </div>
        </div>
        <div class="col-lg-4">
            <div class="row mb-3 d-flex align-items-center">
                <div class="col-4 text-bold">{{ $t('message.common.form') }} <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i></div>
                <div class="col-8">
                    <SelectSearch :placeholder="$t('message.CouponIndex.modal.create_or_update.table.placeholder.choose_method')" :modelValue="filterQuery.formPromotion" :defaultOption="optionFormPromotion" @update:modelValue="doFilterSearch" :label="'formPromotion'"/>
                </div>
            </div>
        </div>
        <!-- <div class="col-12">
            <h5>Hình thức khuyến mại</h5>
        </div>
        <div class="col-12">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th style="width: 50%">
                            <label>Khuyến mại theo</label>
                            <select class="form-control" v-model="entry.currentIfId" @change="onIfChange" >
                                <option value="0">Chọn điều kiện</option>
                                <option :key="i.id" :value="i.id" v-for="i in condition.ifs" v-text="i.name"></option>
                            </select>
                        </th>
                        <th  style="width: 50%">
                            <label>{{$t('message.common.form')}}</label>
                            <select v-if="condition.currentIf" class="form-control" v-model="entry.currentThenId" @change="onThenChange">
                                <option value="0">Chọn hình thức</option>
                                <option :key="t.id" :value="t.id" v-for="t in condition.currentIf.thens" v-text="t.name"></option>
                            </select>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th>{{ condition.currentIf?.name }}</th>
                        <th>{{ condition.currentThen?.name }}</th>
                    </tr>
                    <tr v-for="(value,index) in entry.formValues" :key="index">
                        <td>
                            <template v-if="condition.currentIf">
                                <div :key="form.id" v-for="form in condition.currentIf.form">
                                    <component :is="form.id" :label="form.label" v-model="value.cIf[form.name]"/>
                                </div>
                            </template>
                        </td>
                        <td class="promotion-condition-then">
                            <template v-if="condition.currentThen">
                                <div :key="form.id" v-for="form in condition.currentThen.form">
                                    <component :is="form.id" :label="form.label"  v-model="value.cThen[form.name]"/>
                                </div>
                            </template>
                            <button type="button" @click.prevent="removeFormValue(index)" class="btn btn-sm btn-default promotion-condition-then__remove">
                                <i class="fa fa-trash"/>
                            </button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div>
                <button class="btn btn-primary" type="button" @click="addFormValues()"><i class="fa fa-plus"/> Thêm điều kiện</button>
            </div>
        </div> -->
    </div>

</template>

<script>
import SelectSearch from "@/components/SelectSearch.vue";
import store from "@/store";
function getFormValue() {
    return {
        cIf: {},
        cThen: {}
    }
}

export default {
    name: "PromotionTabMain",
    components: {
        SelectSearch
    },
    data() {
        const globalConfig = store.state.GlobalConfig;
        return {
            errors: {},
            condition: {
                currentIf: null,
                currentThen: null,
                ifs: [],
            },
            entry: {
                currentIfId: '0',
                currentThenId: '0',
                status: 1,
            },
            branchOptions: globalConfig.branches,
            filterQuery:{
                promotion: 1,
                formPromotion: 1,
            },
            optionPromotions: [
                {id: 1, name: this.$t('message.headers.invoices')},
                {id: 2, name: this.$t('message.headers.products')},
                {id: 3, name: this.$t('message.common.invoiceWithProduct')},
            ],
            optionFormPromotion: [],
            optionFormPromotionInvoice: [
                {id: 1, name: this.$t('message.InvoiceIndex.discount')},
                {id: 2, name: this.$t('message.common.giveProduct')},
                {id: 3, name: this.$t('message.common.discountProduct')},
                {id: 4, name: this.$t('message.common.giveVoucher')},
            ],
            optionFormPromotionProduct: [
                {id: 1, name: this.$t('message.common.buyProductDiscountProduct')},
                {id: 2, name: this.$t('message.common.buyProductGiveProduct')},
                {id: 3, name: this.$t('message.common.priceByQuantity')},
                {id: 4, name: this.$t('message.common.buyProductGiveVoucher')},
            ],
            optionFormPromotionAll: [
                {id: 1, name: this.$t('message.InvoiceIndex.discount')},
                {id: 2, name: this.$t('message.common.giveProduct')},
                {id: 3, name: this.$t('message.common.discountProduct')},
            ]
            
        }
    },
    created(){
    },
    mounted() {
    },
    methods: {
        removeFormValue(index) {
            if (!confirm('Xóa khuyến mại')) {
                return;
            }

            this.entry.formValues.splice(index, 1);
        },
        addFormValues() {
            this.entry.formValues.push(getFormValue());
        },
        onIfChange() {
            this.condition.currentIf = this.condition.ifs.find(c => c.id === this.entry.currentIfId);
            this.condition.currentThen = null;
            this.entry.formValues = [getFormValue()];
        },
        onThenChange() {
            this.condition.currentThen = this.condition.currentIf.thens.find(c => c.id === this.entry.currentThenId);
            this.entry.formValues = [getFormValue()];
        },
        async doFilterSearch(v){
            switch (v.label) {
                case 'promotion':
                    this.filterQuery.promotion = v.model;
                    this.setFormPromotion(this.filterQuery.promotion);
                    this.filterQuery.formPromotion = 1;
                    break;
            }
        },
        setFormPromotion(val){
            switch (val) {
                case 1:
                    console.log(1);
                    this.optionFormPromotion = this.optionFormPromotionInvoice;
                    break;
                case 2:
                    console.log(2);
                    this.optionFormPromotion = this.optionFormPromotionProduct;
                    break;
                case 3:
                    console.log(3);
                    this.optionFormPromotion = this.optionFormPromotionAll;
                    break;
                default:
                    console.log(4);
                    this.optionFormPromotion = this.optionFormPromotionInvoice;
                    break;
            }
        },
    },
    watch: {
    },
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/vars.scss";
    .promotion-condition-then {
        position: relative;
    }

    .promotion-condition-then__remove {
        position: absolute;
        right: 0;
        top: 0;
        color: red;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .promotion-input{
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #d5d5d5;
        outline: none;
        &:focus {
            box-shadow: none;
            border-bottom: 2px solid $base-color;
        } 

    }
    .fa-pencil-alt{
        position: absolute;
        left: 10px;
        bottom: 10px;
    }
    .info-row{
        border-bottom: 2px dotted #d5d5d5;
    }
</style>
