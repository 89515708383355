<template>
    <div class="row">
        <div class="col-lg-12 pb-100">
            <div class="main-title">
                <h1 v-text="entry.code ? 'Thông tin trả hàng: ' + entry.code : 'Thông tin trả hàng'"></h1>
            </div>

            <TabNavigation @tabChange="tabChange"
                           ref="tab"
                           :tabs="tabs"/>
        </div>
    </div>
</template>

<script>
import ActionBar from "@/components/ActionBar";
import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
import {markRaw} from "vue";
import TabReturnDetail from "@/views/returns/tabs/ReturnFormTabReturnDetail";
import TabPaymentHistory from "@/views/returns/tabs/ReturnFormTabPaymentHistory";
import TabNavigation from "@/components/TabNavigation";

export default {
    name: "ReturnForm",
    components: {ActionBar, TabNavigation},
    data() {
        return {
            isDataLoaded: false,
            entry: {},
            errors: {},
            currentTab: {},
            tabs: [
                {id: 'TabReturnDetail', name: 'Thông tin', component: markRaw(TabReturnDetail)},
                {id: 'TabPaymentHistory', name: 'Lịch sử thanh toán' , component: markRaw(TabPaymentHistory)}
            ],
        }
    },
    mounted() {
        document.title = 'Chi tiết trả hàng | OmiPos';
        if (this.$route.query.id) {
            this.load();
        } else {
            this.isDataLoaded = true;
        }

    },
    methods: {
        tabChange(tab) {
            this.currentTab = tab;
            if (this.$route.query.id) {
                this.load();
            } else {
                this.$refs.tab.setTabData({
                    isDataLoaded: true
                })
            }
        },
        async load() {
            const res = await $get('/v1/returns/show', {
                id: this.$route.query.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;

            this.$refs.tab.setTabData({
                entry: res.data.entry,
                invoiceItems: res.data.entry.invoice_items || [],
                saleChannels: res.data.sale_channels,
                brandAdmins: res.data.brand_admins,
                listStatus: res.data.list_status,
                isDataLoaded: true,

                invoiceCashFlows: res.data.entry.cash_flows || [],
            });
            this.isDataLoaded = true;

        },
        async save() {
            this.errors = {};

            const res = await $post('/v1/returns/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            $alert(res);
        }
    }
}
</script>

<style scoped>

</style>
