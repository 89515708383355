import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}

const fields = {
    'STT': 'stt',
    'Mã hóa đơn': 'code',
    'Mã vận đơn': 'delivery.code',
    'Thời gian' : {
        field: 'purchaseDate',
        callback: (value) => {
            return value ? moment(value).format('DD/MM/YYYY HH:mm') : null;
        }
    },
    'Mã trả hàng': {
        field: 'returns',
        callback: (value) => {
            if (value && value.length) {
                const returnCodes = value.map((r) => (r.code));
                return returnCodes.join(', ');
            }
            return null;
        }
    },
    'Khách hàng': 'customer.name',
    'Địa chỉ': 'customer.address',
    'Khu vực': 'customer.locationName',
    'Phương/xã': 'customer.wardName',
    'Tổng tiền hàng': {
        field: 'totalOrigin',
        callback: (value) => {
            return n(value);
        }
    },
    'Giảm giá': {
        field: 'discountPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Khách cần trả': {
        field: 'total',
        callback: (value) => {
            return n(value);
        }
    },
    'Khách đã trả': {
        field: 'totalPayment',
        callback: (value) => {
            return n(value);
        }
    },
    'Phương thức thanh toán': 'cash_flows' // temp
};

export default fields;
