<template>
    <div class="d-row g-4 my-4">
        <div class="col-12 col-lg-12">
            <div class="app-card app-card-chart h-100 shadow-sm">
                <div class="app-card-body p-3 p-lg-4">
                    <div class="d-flex justify-content-between align-items-center">
                        <div class="">
                            <h5 class="app-card-title">
                                {{this.$t('message.RevenueFromBranch.title', {label: this.label})}}
                                <i class="fa fa-arrow-circle-right"></i>
                                <span class="text-primary">{{ ' ' + numberFloat(this.total)}}</span>
                            </h5>
                        </div>
                        <div class="">
                            <DateRangeDashboardPicker :formatType="'YYYY-MM-DD'" @picker="filterTime"></DateRangeDashboardPicker>
                        </div>
                    </div>
                    <div class="chart-container" v-if="this.total > 0">
                        <PieChart ref="pieChart" :chartData="branchData" :options="options" :plugins="plugins"/>
                    </div>
                    <div class="chart-container" v-else>
                        <div class="col-12 text-center text-secondary mt-5">
                            <img src="../../../public/img/No-record.png">
                        </div>
                        <div class="col-12 text-center my-10 text-secondary mb-5">
                            <span class="border-none">{{$t('message.common.notData')}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { PieChart } from 'vue-chart-3';
import { $post } from "@/lib/utils";
import DateRangeDashboardPicker from '@/components/DateRangeDashboardPicker.vue';

// const options = {
//     responsive: true,
//     maintainAspectRatio: false,
//     tooltips: {
//         enabled: true,
//         callbacks: {
//             label: function(tooltipItem, data) {
//                 let value = new Intl.NumberFormat('en-US').format(data.datasets[0].data[tooltipItem.index]);
//                 return data.labels[tooltipItem.index] + ': ' + value;
//             }
//         }
//     },
//     legend: {
//         display: true,
//         position: 'bottom',
//         onClick: funclegendClickHandler
//     },
//     layouts: {
//         padding: 10
//     },
//     plugins: {
//         datalabels: {
//             formatter: (value, ctx) => {
//                 let sum = ctx.dataset._meta[0].total;
//                 let percentage = (value * 100 / sum).toFixed(2) + "%";
//                 return percentage;
//             },
//             color: '#fff',
//         },
//     },
//     labels: {
//         render: 'percentage',
//         position: 'outside',
//     }
// };
const plugins = [
    {
        datalabels: {
            formatter: (value, ctx) => {
                let sum = ctx.dataset._meta[0].total;
                let percentage = (value * 100 / sum).toFixed(2) + "%";
                return percentage;
            },
            color: '#fff',
        },
        labels: {
            render: 'percentage',
            position: 'outside',
        }
    }
];
export default {
    name: "RevenueBranch",
    components: {
        PieChart,
        DateRangeDashboardPicker
    },
    data(){
        return{
            branchesRemove: [],
            options: {
                responsive: true,
                maintainAspectRatio: false,
                tooltips: {
                    enabled: true,
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let value = new Intl.NumberFormat('en-US').format(data.datasets[0].data[tooltipItem.index]);
                            return data.labels[tooltipItem.index] + ': ' + value;
                        }
                    }
                },
                layouts: {
                    padding: 10
                },
                plugins: {
                    datalabels: {
                        formatter: (value, ctx) => {
                            let sum = ctx.dataset._meta[0].total;
                            let percentage = (value * 100 / sum).toFixed(2) + "%";
                            return percentage;
                        },
                        color: '#fff',
                    },
                    
                },
                labels: {
                    render: 'percentage',
                    position: 'outside',
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    onClick: this.legendClickHandler
                },
            },
            plugins: plugins,
            branchData: {},
            time: '',
            label: '',
            total: 0,
        }
    },
    methods: {
        async legendClickHandler(event, legendItem) {
            const chart = this.$refs.pieChart.chartInstance;
            const index = legendItem.index;
            const meta = chart.getDatasetMeta(0);

            const indexBranch = meta.data[index]._index;
            const branchId =  this.branchData.branchIds[indexBranch];
            meta.data[index].hidden = !meta.data[index].hidden;
            if (!this.branchesRemove.includes(branchId)) {
                this.branchesRemove.push(branchId);
            }else {
                this.branchesRemove = this.branchesRemove.filter(item => item != branchId)
            }

            const res = await $post('/v1/dashBoard/get-revenue-branch', {
                time: this.time,
                removeBranches: [...this.branchesRemove]
            });
            this.total = res.result.total;
            

            chart.update();
        },
        async load() {
            const res = await $post('/v1/dashBoard/get-revenue-branch', {time: this.time});    
            if(res && res.result){
                this.branchData = res.result.data;
                this.total = res.result.total;
            }           
        },
        filterTime(obj){
            if(!obj) return;
            this.time = obj.value ?? null;
            this.label = obj.label.toUpperCase() ?? null;
            this.load();
        }
    },
    mounted(){
        try {
            this.load()
        } catch (e) {
            console.error(e)
        }
    },
    created(){
    }
}
</script>

<style scoped lang="scss">
    select{
        border: none !important;
        outline: none !important;
    }
    .app-card-title{
        font-weight: 600;
    }
</style>