<template>
<AppPermission :permission="this.$permissions.locations.data?? '' ">
    <div class="container">
        <div class="row pt-3">
            <div class="col-12 pl-0">
                <div class="main-title">
                    <span>
                        {{$t('message.LocationIndex.title')}}
                    </span>
                </div>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-3 pl-0">
                <SelectSearch :placeholder="$t('message.LocationIndex.chooseBranch')" :modelValue="filterQuery.branches" :defaultOption="this.branches.all" :border="true" :readonly="true" @update:modelValue="doFilterSearch" :label="'branches'"/>
            </div>
            <div class="col-3">
                <button class="btn btn-save" @click="filter">
                    {{$t('message.LocationIndex.button.filter')}}
                </button>
                <button class="btn btn-default ml-2" @click="clearFilter">
                    {{ $t('message.button.removeFilter') }}
                </button>
            </div>
        </div>
        <div class="row my-4">
            <div class="col-6">
                <div class="row mb-4">
                    <div class="col-12 d-flex pl-0">
                        <AppPermission :permission="this.$permissions.locations.create?? '' ">
                            <div class="dropdown mr-4">
                                <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButtonAdd" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="fa fa-plus mr-2"></i>
                                    {{$t('message.LocationIndex.button.create')}}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonAdd">
                                    <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="showModalAddLocations()">
                                        <i class="fa fa-plus mr-2"></i>
                                        {{$t('message.LocationIndex.button.create_location_category')}}
                                    </a>
                                    <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="showModalQuickAddLocations()">
                                        <i class="fa fa-plus mr-2"></i>
                                        {{$t('message.LocationIndex.button.create_fast_location_category')}}
                                    </a>
                                </div>
                            </div>
                        </AppPermission>
                         <AppPermission :permission="this.$permissions.locations.export?? '' ">
                        <div class="dropdown">
                            <button class="btn btn-save dropdown-toggle" type="button" id="dropdownMenuButtonAction" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {{$t('message.LocationIndex.button.action')}}
                            </button>
                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButtonAction">
                                <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="selectOptionExport('exportLocation')">
                                    <i class="fas fa-file-export mr-2"></i>
                                    {{$t('message.LocationIndex.button.export')}}
                                </a>
                                <!-- <a class="dropdown-item font-weight-normal" href="javascript:void(0);"><i class="fa fa-print mr-2"></i>In mã vạch tất cả vị trí</a> -->
                            </div>
                        </div>
                        </AppPermission>
                    </div>
                </div>
                <div class="row sticky-top">
                    <table class="w-100 table-index">
                        <thead>
                            <tr class="backgroup__detail table-header border-1">
                                <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.LocationIndex.table_location_category.code')}}</th>
                                <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.LocationIndex.table_location_category.name')}}</th>
                                <th scope="col" class="pl-4 cell-auto p-2">{{$t('message.LocationIndex.table_location_category.number_location')}}</th>
                                <th scope="col" class="pl-4 cell-release-coupon p-2">{{$t('message.LocationIndex.table_location_category.created_at')}}</th>
                                <th scope="col" class="pl-4 text-right pr-4">
                                    <i class="fas fa-cog text-omi"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="this.entries && this.entries.length">
                                <template v-for="(entry, i) in this.entries" :key="entry.id">
                                    <template v-if="i >= (this.paginate.currentPage - 1)*this.numberRecord && i < this.paginate.currentPage*this.numberRecord">
                                    <tr class="border-1">
                                        <td scope="col" class="pl-4 cell-code-coupon p-2" v-text="entry.code" @click="showLocationDetails(entry.id, entry.nameLevel, entry.codeLevel)"></td>
                                        <td scope="col" class="pl-4 cell-auto p-2" v-text="entry.nameLevel" @click="showLocationDetails(entry.id, entry.nameLevel, entry.codeLevel)"></td>
                                        <td scope="col" class="pl-4 cell-auto p-2" v-text="entry.details_count ? n(entry.details_count) : null" @click="showLocationDetails(entry.id, entry.nameLevel, entry.codeLevel)"></td>
                                        <td scope="col" class="pl-4 cell-release-coupon p-2" v-text="entry.created_at ? dt(entry.created_at) : ''" @click="showLocationDetails(entry.id, entry.nameLevel, entry.codeLevel)"></td>
                                        <td scope="col" class="pl-4 text-right">
                                            <template v-if="hasPermission(this.$permissions.locations.update) || (hasPermission(this.$permissions.locations.create) && !entry.get_children_count) || hasPermission(this.$permissions.locations.remove)  || this.currentUser.id == this.$constant.isAdmin">
                                            <div class="dropdown" >
                                                <button class="btn btn-white dropdown-toggle" type="button" :id="'dropdownMenuButtonSetting' + entry.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-bars pr-2"></i>
                                                </button>                                                
                                                <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButtonSetting' + entry.id" >
                                                    <AppPermission :permission="this.$permissions.locations.update?? '' ">
                                                        <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="showModalEditLocation(entry)">
                                                            <i class="fa fa-pencil-alt mr-2"></i>
                                                            {{$t('message.LocationIndex.table_location_category.button.update')}}
                                                        </a>
                                                    </AppPermission>
                                                    <AppPermission :permission="this.$permissions.locations.create?? '' ">
                                                        <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="showModalAddLocationDetail(entry)" v-if="!entry.get_children_count">
                                                            <i class="fa fa-plus mr-2"></i>
                                                            {{$t('message.LocationIndex.table_location_category.button.add_location')}}
                                                        </a>
                                                    </AppPermission>
                                                        <!-- <a class="dropdown-item font-weight-normal" href="javascript:void(0);"><i class="fa fa-print mr-2"></i>In mã vạch</a> -->
                                                    <AppPermission :permission="this.$permissions.locations.remove?? '' ">
                                                        <a class="dropdown-item font-weight-normal text-danger" href="javascript:void(0);" @click="confirmRemove(entry.id)">
                                                            <i class="fa fa-trash-alt mr-2"></i>
                                                            {{$t('message.LocationIndex.table_location_category.button.remove')}}
                                                        </a>
                                                    </AppPermission>
                                                </div> 
                                            </div>
                                            </template>
                                        </td>
                                    </tr>
                                    </template>
                                </template>
                            </template>
                        </tbody>
                    </table>
                    <Pagination :value="paginate" :record="this.numberRecord"/>
                </div>
            </div>
            <div class="col-1"></div>
            <div class="col-5">
                <div class="row mb-4">
                    <div class="col-12 pl-0 py-1">
                        <div class="main-title">
                            <span>
                                {{$t('message.LocationIndex.title_location')}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row sticky-top">
                    <table class="w-100 table-index">
                        <thead>
                            <tr class="backgroup__detail table-header border-1">
                                <th scope="col" class="pl-4 cell-code-coupon p-2">{{$t('message.LocationIndex.table_location.barcode')}}</th>
                                <th scope="col" class="cell-auto p-2">{{$t('message.LocationIndex.table_location.name')}}</th>
                                <th scope="col" class="cell-auto p-2">{{$t('message.LocationIndex.table_location.code')}}</th>
                                <th scope="col" class="cell-auto p-2">{{$t('message.LocationIndex.table_location.created_at')}}</th>
                                <th scope="col" class="pl-4 text-right pr-4">
                                    <i class="fas fa-cog text-omi"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-if="this.listLocations && this.listLocations.length">
                                <template v-for="entry in this.listLocations" :key="entry.id">
                                    <tr class="border-1">
                                        <td scope="col" class="pl-4 cell-code-coupon p-2" v-text="entry.qrCode"></td>
                                        <td scope="col" class="cell-auto p-2" v-text="entry.name"></td>
                                        <td scope="col" class="cell-auto p-2" v-text="entry.code"></td>
                                        <td scope="col" class="cell-auto p-2" v-text="entry.created_at ? dt(entry.created_at) : ''"></td>
                                        <td scope="col" class="pl-4 text-right">
                                            <template v-if="hasPermission(this.$permissions.locations.update) || hasPermission(this.$permissions.locations.remove) || this.currentUser.id == this.$constant.isAdmin">
                                            <div class="dropdown">
                                                <button class="btn btn-white dropdown-toggle" type="button" :id="'dropdownMenuButtonSettingLocation' + entry.id" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <i class="fas fa-bars pr-2"></i>
                                                </button>
                                                <div class="dropdown-menu" :aria-labelledby="'dropdownMenuButtonSettingLocation' + entry.id">
                                                    <AppPermission :permission="this.$permissions.locations.update?? '' ">                                                    
                                                        <a class="dropdown-item font-weight-normal" href="javascript:void(0);" @click="showModalEditLocationDetail(entry, this.locationName, this.locationCode)">
                                                            <i class="fa fa-pencil-alt mr-2"></i>
                                                            {{$t('message.LocationIndex.table_location.button.update')}}
                                                        </a>
                                                    </AppPermission>
                                                        <!-- <a class="dropdown-item font-weight-normal" href="javascript:void(0);"><i class="fa fa-print mr-2"></i>In mã vạch</a> -->
                                                    <AppPermission :permission="this.$permissions.locations.remove?? '' ">
                                                        <a class="dropdown-item font-weight-normal text-danger" href="javascript:void(0);" @click="showModalDeleteLocationDetails(entry.id, entry.name)">
                                                            <i class="fa fa-trash-alt mr-2"></i>
                                                            {{$t('message.LocationIndex.table_location.button.remove')}}
                                                        </a>
                                                    </AppPermission>
                                                </div>
                                            </div>
                                            </template>
                                        </td>
                                    </tr>
                                </template>
                            </template>
                        </tbody>
                    </table>
                    <Pagination :value="paginatePosition" :record="this.numberRecord" @change="pageChangePosition" v-if="this.listLocations && this.listLocations.length"/>
                </div>
            </div>
        </div>
    </div>
    <ModalAddLocationDeatils ref="ModalAddLocationDeatils"  @reload="loadLocationDetails"/>
    <ModalAddLocations ref="ModalAddLocations" @reload="load"/>
    <ModalQuickAddLocations ref="ModalQuickAddLocations" @reload="load"/>
    <ModalDeleteLocationDetails ref="ModalDeleteLocationDetails" @reload="loadLocationDetails" />
    <ModalEditLocations ref="ModalEditLocations" @reload="load"/>
    <ModalConfirm ref="ModalConfirm" @confirm="removeLocationCategory" :title="this.$t('message.LocationIndex.title_confirm')" :content="this.$t('message.LocationIndex.content_confirm')"/>
    <ModalEditLocationDeatils ref="ModalEditLocationDeatils" @reload="loadLocationDetails" />
    <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
</AppPermission>
</template>

<script>
    import { $get, $post, $alert, clone} from "@/lib/utils";
    import ModalAddLocationDeatils from './ModalAddLocationDeatils';
    import ModalAddLocations from './tabs/ModalAddLocations.vue';
    import ModalQuickAddLocations from './tabs/ModalQuickAddLocations.vue';
    import ModalEditLocations from './tabs/ModalEditLocations.vue';
    import store from "../../store";
    import emitter from "@/lib/emitter";
    import SelectSearch from "@/components/SelectSearch.vue";
    import Pagination from "@/components/Pagination";    
    import ModalConfirm from "@/components/ModalConfirm.vue";
    import ModalDeleteLocationDetails from './tabs/ModalDeleteLocationDetails';
    import ModalEditLocationDeatils from './ModalEditLocationDeatils.vue';
    import http from '@/services/api';    
    import AppPermission from '@/components/AppPermission.vue';
    import permissionMixin from '@/permissionMixin';
    import exportMixin from '@/exportMixin';
    import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
    export default {
        name: "LocationIndex",
        mixins: [permissionMixin, exportMixin],
        components: {
            SelectSearch, ModalAddLocationDeatils, ModalAddLocations, 
            ModalQuickAddLocations, Pagination, ModalEditLocations, 
            ModalDeleteLocationDetails, ModalConfirm, ModalEditLocationDeatils, 
            AppPermission, ModalSelectOptionExport
        },
        mounted(){
            document.title = this.$t('message.LocationIndex.documentTitle');
            this.clearFilter();
        },
        data() {
            const globalConfig = store.state.GlobalConfig;
            return {
                fields: [],
                entries: [],
                listLocations: [],
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                paginatePosition: {
                    currentPage: 1,
                    lastPage: 1
                },
                numberRecord: 15,
                filterQuery: {
                    branches: '',
                },
                currentBranchId: store.state.CurrentBranch.id,
                currentUser: store.state.Auth ? store.state.Auth.user : {},
                branches: {
                    filter: globalConfig.branches,
                    all: globalConfig.branches,
                    search: null,
                },
                locationActiveId: null,
                locationRemoveId: null,
                locationName: null,
                locationCode: null,
                edit: false,
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/locations/index',
                    query: {}
                });
                Object.keys(this.filterQuery).forEach(key => {
                    this.filterQuery[key] = '';
                });
                await emitter.emit("clearSelectSearchValue");
                this.numberRecord = 15;
                this.filterQuery.branches = this.currentBranchId;
                this.locationActiveId = null;
                this.listLocations = [];
                this.paginate.currentPage = 1;
                await this.load();
            },
            showModalEditLocationDetail(entry, locationName, code){   
                this.edit = true;                 
                this.$refs.ModalEditLocationDeatils.show(entry, locationName, code);

            },
            showModalAddLocationDetail(entry){                      
                let value = {
                    id: entry.id,
                    code: entry.codeLevel,
                    name: entry.nameLevel,
                    listCurrentParentInfo: entry.listCurrentParentInfo
                }
                this.edit = true;
                this.locationActiveId = entry.id;
                this.$refs.ModalAddLocationDeatils.show(value);
            },
            showModalEditLocation(entry){
                this.edit = true;
                this.$refs.ModalEditLocations.show(entry);
            },
            showModalAddLocations(){
                this.$refs.ModalAddLocations.show();
            },
            showModalQuickAddLocations(){
                this.$refs.ModalQuickAddLocations.show();
            },
            async load() {
                emitter.emit("onLoading");
                const res = await $get('/v1/locations/get-location-category', {...this.filterQuery, record: this.numberRecord});                
                this.entries = res.result.data;
                this.entries.sort((a, b) => {
                    return a.nameLevel < b.nameLevel ? -1 : 1
                });               
                this.setPaginateEntries(this.entries);
                emitter.emit("offLoading");
            },
            setPaginateEntries(data){
                this.paginate.currentPage = this.edit ? this.paginate.currentPage : 1;
                this.paginate.lastPage = Math.ceil(data.length / this.numberRecord);
                this.paginate.totalRecord = data.length;
            },
            showLocationDetails(id, name, code){
                //Lấy name của parent hiển lúc sửa
                this.locationName = name;
                this.locationActiveId = id;
                this.locationCode = code                
                this.loadLocationDetails();
            },
            async loadLocationDetails(){                
                this.listLocations = [];
                const res = await $get("/v1/location-details/data", {locationId: this.locationActiveId, page: this.filterQuery.pagePosition, record: this.numberRecord});                
                if(res && res.status.code == 200 && res.result.data.length > 0){                            
                    this.listLocations = res.result.data;    
                    this.paginatePosition = res.result.paginate;                
                }
                if(this.edit){
                    await this.load();
                }
                this.edit = false;
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                await this.load();
            },
            async pageChangePosition(page) {
                const query = clone(this.filterQuery);
                query['pagePosition'] = page;
                this.filterQuery = {...query};
                await this.loadLocationDetails();
            },
            doFilterSearch(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
            },
            confirmRemove(id){
                this.locationRemoveId = id;
                this.$refs.ModalConfirm.show();
            },
            async removeLocationCategory(){
                const res = await $get("/v1/locations/remove-location-category", {id: this.locationRemoveId});
                if(res.result && !res.result.status && res.result.message){
                    $alert({code: 422, message: res.result.message});
                }else if(res.result && res.result.status && res.result.message){
                    $alert({code: 200, message: res.result.message});
                    this.load();
                }
            },
            selectOptionExport(method = 'exportLocation', totalRecord = 0){
                let params = { method: method };
                totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
                this.$refs.ModalSelectOptionExport.show(params, totalRecord, this.fields);
            },
            async exportFiles(params){
                if (params.method) {
                    this[params.method](params.firstRecordIndex);
                }
            },
            async exportLocation(firstRecordIndex = 0) {   
                emitter.emit("onLoading");
                let branchId = this.filterQuery.branches ? this.filterQuery.branches : this.currentBranchId;
                const res = await http.download('/v1/locations/export', {
                    branchId: branchId,
                    firstRecordIndex: firstRecordIndex
                });
                this.returnDataExport(res);
            },  

            //Show modal xoa
            showModalDeleteLocationDetails(id, name){
                this.edit = true;
                this.$refs.ModalDeleteLocationDetails.show(id, name);                
            },
            filter(){
                this.listLocations = [];
                this.load();
            },
        }
    }
</script>