<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div class="main-title">
                    <h1>{{title}}</h1>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="search-box">
                    <i class="fas fa-search mr-2"></i>
                    <input type="text" class="search-input"
                           @keydown.enter="doFilter('keyword', filter.keyword)"
                           v-model="filter.keyword" placeholder="Tìm kiếm"/>
                </div>
            </div>
        </div>

        <div class="row mt-2">
            <div class="col-12">
                <button type="button" @click="sendChangesRequest()" class="btn btn-primary">Gửi đề xuất</button>
                <AppPermission permission="DataChangeRequests.applyAction">
                    <button type="button" @click="showModalChangeRequests()" class="btn btn-primary">Duyệt đề xuất</button>
                </AppPermission>
            </div>
            <ModalDataChangeRequest ref="modal"/>
        </div>
        <div class="row mt-1">
            <div class="col-12">
                <table class="table table-index table-striped">
                    <thead>
                    <tr>
                        <td>
                            <input type="checkbox" v-model="checkedAll" @change="onCheckedAll()">
                        </td>
                        <th>Mã SP</th>
                        <th style="width: 13%">Tên SP</th>
                        <th class="text-center">Tồn kho Min Công thức</th>
                        <th class="text-center">SL TB Bán 3 tháng</th>
                        <th class="text-center">SL Trưng Bày</th>
                        <th class="text-center">{{$t('message.ProductIndex.inventory')}}</th>
                        <th class="text-center">Tồn kho Min</th>
                        <th class="text-center">Tồn kho Min đề xuất</th>
                        <th class="text-center">Lý do đề xuất</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="product in entries" :key="product.id">
                        <td>
                            <input type="checkbox" v-model="product.checked">
                        </td>
                        <td v-text="product.code"></td>
                        <td v-text="product.name"></td>
                        <td class="text-center" v-text="n(product.meta.onShowRoomQuantity + product.meta.avgSold3Months *40)"></td>
                        <td class="text-center" v-text="n(product.meta.avgSold3Months)"></td>
                        <td class="text-center" v-text="n(product.meta.onShowRoomQuantity)"></td>
                        <td class="text-center" v-text="n(product.inventory.onHand)"></td>
                        <td class="text-center" v-text="n(product.meta.minimalQuantity)"></td>
                        <td>
                            <InputNumber
                                :errors="errors[product.id]"
                                v-model="product.meta.suggestedMinimalQuantity" placeholder="Tồn kho Min đề xuất"/>
                        </td>
                        <td>
                            <InputTextArea
                                :errors="reasonErrors[product.id]"
                                v-model="product.meta.suggestedMinimalQuantityReason" placeholder="Lý do"/>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <Paginate :value="paginate" :record="this.numberRecord" @change="pageChange"/>
            </div>

        </div>

    </div>
</template>

<script>
import InputDateRangePicker from "../../components/forms/InputDateRangePicker";
import QSelect from "../../components/QSelect";
import {$alert, $get, $post, clone, getSearchQuery, trim} from "../../lib/utils";
import Paginate from "../../components/Paginate";
import InputNumberFormat from "../../components/forms/InputNumberFormat";
import InputNumber from "../../components/forms/InputNumber";
import ModalDataChangeRequest from "../../components/ModalDataChangeRequest";
import store from "../../store";
import InputTextArea from "../../components/forms/InputTextArea";
import AppPermission from "../../components/AppPermission";

export default {
    name: "InventoryPlanProducts",
    components: {
        AppPermission,
        InputTextArea,
        ModalDataChangeRequest, InputNumber, InputNumberFormat, Paginate, QSelect, InputDateRangePicker},
    data() {
        const query = getSearchQuery();

        return {
            errors: {},
            reasonErrors: {},
            title: 'Thiết lập tồn kho Min',
            checkedAll: false,
            filter: {
                keyword: query.keyword,
            },
            entries: [],
            paginate: {
                currentPage: parseInt(query.page) || 1,
                lastPage: 1
            },
            numberRecord: 25,
        }
    },
    mounted() {
        document.title = this.title;
        this.load();
    },
    methods: {
        showModalChangeRequests() {
            this.$refs.modal.show(() => {
                this.load();
            });
        },
        async sendChangesRequest() {
            const checked = this.entries.filter(e => e.checked);

            if (checked.length === 0) {
                alert('Vui lòng chọn sản phẩm');
                return;
            }

            const submitData = {
                applyData: [],
                requestType: 'UPDATE_MINIMAL_QUANTITY',
                reason: '',
                note: 'Thay đổi tồn khi Min'
            };

            const len = this.entries.length;
            this.errors = {};
            let hasError = false;
            for (let i = 0; i < len; i++) {
                const e = this.entries[i];
                const productId = e.id;

                if (e.checked) {
                    const from = parseInt(e.meta.minimalQuantity) || 0;
                    const to = parseInt(e.meta.suggestedMinimalQuantity) || 0;


                    if (to === 0) {
                        this.errors[e.id] = [`Vui lòng nhập giá trị lớn hơn 0.`]
                        hasError = true;
                    }

                    if (from === to) {
                        this.errors[e.id] = [`Vui lòng nhập giá trị khác giá trị cũ`]
                        hasError  = true;
                    }

                    if (!trim(e.meta.suggestedMinimalQuantityReason)) {
                        this.reasonErrors[e.id] = [`Vui lòng nhập lý do`]
                        hasError  = true;
                    }

                    if (!hasError) {
                        submitData.applyData.push({
                            table: 'product_branch_meta',
                            tableId: e.meta.id,
                            name: e.name + ' (' + e.code + ')' ,
                            reason: trim(e.meta.suggestedMinimalQuantityReason),
                            data: [
                                {
                                    field: 'minimalQuantity',
                                    name: 'Tồn kho min',
                                    from,
                                    to
                                },
                                {
                                    field: 'productId',
                                    name: 'IDSP',
                                    from: productId,
                                    to: productId,
                                }
                            ]
                        })
                    }
                }
            }

            if (hasError) {
                return;
            }

            const reason = prompt('Nhập lý do tổng', '');
            if (reason === null) {
                return;
            }

            submitData.reason = reason;
            const res = await $post('/v1/data-change-requests/submit', {
                entry: submitData
            });

            $alert(res);

        },
        onCheckedAll() {
            this.entries.forEach( e=> {
                e.checked = this.checkedAll
            })
        },
        async clearFilter() {
            await this.$router.replace({
                path: '/order-suppliers/index',
                query: {}
            });

            Object.keys(this.filter).forEach(key => {
                this.filter[key] = '';
            });

            this.load();
        },
        async load() {
            const res = await $get('/v1/inventory-plans/products-data', {...this.$route.query, record: this.numberRecord});

            if (res.code !== 200) {
                $alert(res);
                return;
            }

            this.paginate = res.data.paginate;
            this.entries = res.data.entries;
        },
        async doFilter(field, value) {
            const query = clone(this.$route.query);
            query[field] = value;
            await this.$router.push({query: query});
            this.load();
        },
        async pageChange(page) {
            await this.$router.push({query: { page:page }});
            this.load();
        },
    }
}
</script>

<style scoped>

</style>
