<template>
<!-- :class="this.voucherClass == true? 'voucherStyle' : '' " -->
    <div >
        <div class="form-group row">
            <div class="col-3 pr-0">
                <b>{{ $t('message.common.province') }}</b>
            </div>
            <div class="col-9">
                <treeselect  v-if="this.provinceId" :normalizer="your_normalizer" name="provinceId" v-model="this.provinceId"  :options="provinces" :clearable="false" :show-count="true" 
               :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_province")' @select="changeProvince" />
                <treeselect  v-else name="provinceId" v-model="this.provinceId" :normalizer="your_normalizer"  :options="provinces" :clearable="false" :show-count="true"
                :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_province")'  @select="changeProvince" />               
            </div>
        </div>
        <div class="form-group row">
            <div class="col-3">
                <b>{{ $t('message.common.district') }}</b>
            </div>
            <div class="col-9">
                <treeselect v-if="this.districtId" :normalizer="your_normalizer" name="districtId" v-model="this.districtId"  :options="districts" :clearable="false" :show-count="true" 
                :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_district")'  @select="changeDistrict"/>
                <treeselect v-else name="districtId" v-model="this.districtId" :normalizer="your_normalizer"  :options="districts" :clearable="false" :show-count="true" 
                :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_district")' @select="changeDistrict"/>               
            </div>
        </div>
        <div class="form-group row">
            <div class="col-3">
                <b>{{ $t('message.common.ward') }}</b>
            </div>
            <div class="col-9">                
                <treeselect v-if="this.wardId" :normalizer="your_normalizer" name="wardId" v-model="this.wardId"  :options="wards" :clearable="false" :show-count="true" 
               :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_ward")' @select="changeWard" />
                <treeselect v-else name="wardId" v-model="this.wardId" :normalizer="your_normalizer"  :options="wards" :clearable="false" :show-count="true" 
                :placeholder='$t("message.ModalCreateUpdatePickup.placeholder_ward")' @select="changeWard" />                
            </div>
        </div>
    </div>
</template>

<script>
    import {$get} from "@/lib/utils";
    import Treeselect from 'vue3-treeselect'
    export default {
        name: "InputLocationPicker",
        props: ['modelValue', 'readonly', 'voucherClass'],
        components: {
            Treeselect
        },
        data() {
            let v = this.modelValue || {};              
            return {
                provinceId: v.provinceId || null,
                districtId: v.districtId || null,
                wardId: v.wardId || null,
                provinces: [],
                wards: [],
                districts: [],
            }
        },
        watch: {
            modelValue: {                
                handler(newValue){                          
                    let v = newValue || {};
                    this.provinceId = v.provinceId;
                    this.districtId = v.districtId;
                    this.wardId = v.wardId;
                    this.load();
                },
                deep: true
            }
        },
        methods: {
            changeWard(node) {
                this.wardId = node.id;                                
                this.emit();
            },
            emit() {                
                const modelValue =  {                    
                    provinceId: this.provinceId,
                    districtId: this.districtId,
                    wardId: this.wardId,
                };                
                this.$emit('update:modelValue', modelValue);
                this.$nextTick(() => {
                    this.$emit('input', modelValue)
                })
            },
            async changeDistrict() {
                const res = await $get('/v1/locations/wards', {districtId: this.districtId});
                this.wards = res.data;                   
                this.emit();
            },
            async changeProvince() {                
                const res = await $get('/v1/locations/districts', {provinceId: this.provinceId});
                this.districts = res.data;
                this.emit();
            },
            async load() {
                const res = await $get('/v1/locations/provinces', {
                    provinceId: this.provinceId,
                    districtId: this.districtId
                });
                this.provinces = res.data.provinces;
                this.districts = res.data.districts;
                this.wards = res.data.wards;
            }
        },
        mounted() {            
            this.load();
        },
    }
</script>

<style scoped>
/* .voucherStyle  */
   .vue-treeselect__control {
        border: 0 !important;
    }
</style>
