<template> 
    <!-- <AppPermission :permission="this.$permissions.printTemplates.data?? '' "> -->
        <div class="container">
            <div class="row m-0 p-0">
                <div class="col-lg-12">
                    <h3>
                        {{ $t("message.SendMailZaloIndex.title") }}
                    </h3>
                </div>
            </div>
            <div class="row m-0 p-0">          
                <TabNavigation @tabChange="tabChange" ref="tab" :tabs="tabs" :template="true"/>
            </div>
        </div>
    <!-- </AppPermission> -->
</template>
<script>
import {markRaw} from "vue";
import TabNavigation from "@/components/TabNavigation";
import PdfTemplate from "./PdfTemplate";
import {$get, $post} from "@/lib/utils";
import emitter from "@/lib/emitter";
import storeModul from "@/storeModul";
import AppPermission from '@/components/AppPermission';
export default {
    name: "PdfTemplateIndex",   
    components: {TabNavigation, AppPermission},
    data() {
        return {         
            tabs: [
                {id: this.$constant.TabOrder, showActionBar: true, name: this.$t('message.OrderIndex.button.order'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabInvoices, showActionBar: true, name: this.$t('message.InvoiceIndex.title'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabReturns, showActionBar: true, name: this.$t('message.InvoiceIndex.button.return'), component: markRaw(PdfTemplate)},                    
                {id: this.$constant.TabOrderSuppliers, showActionBar: true, name: this.$t('message.headers.order_suppliers'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabImports, showActionBar: true, name: this.$t('message.headers.purchase_products'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabReturnSuppliers, showActionBar: true, name: this.$t('message.headers.return_purchase_orders'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabTransfers, showActionBar: true, name: this.$t('message.TransferIndex.title'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabRequestPDF, showActionBar: true, name: this.$t('message.RequestIndex.title'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabRefunds, showActionBar: true, name: this.$t('message.headers.request_refund'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabSettlementAdvance, showActionBar: true, name: this.$t('message.SettlementAdvanceIndex.title'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabPlanDetails, showActionBar: true, name: this.$t('message.headers.inventory_plan'), component: markRaw(PdfTemplate)},
                {id: this.$constant.TabPlanImportBuy, showActionBar: true, name: this.$t('message.headers.plan_purchase'), component: markRaw(PdfTemplate)},
            ],
            currentTab: null,
        }
    },
    methods: {
        tabChange(tab) {
            this.currentTab = tab;    
            this.load(this.currentTab); 
        },
        async load(tab, id = null) {   
            let content = null;
            let file = null;
            let dataValue = null;
            let printTemplates = null;         
            let pdfTemplate = null;
            let pdfTemplateId = null;
            const response = await $get('/v1/pdf-templates/get-templates',{code: tab.id, id: id});             
            if(response.status.code == 200){
                if(response.result) {                    
                    pdfTemplate = response.result.data;
                    content = response.result.content;
                    file = response.result.pdfFileId;
                    pdfTemplateId = id ? id : (response.result.id ? response.result.id : null);
                }
            }
            const resValues = await $post('/v1/pdf-templates/get-pdf-template-keys', {code: tab.id});
            if (resValues.result && resValues.result.data) {
                dataValue = resValues.result.data;
            }
            const resPrintTemplate = await $post('/v1/pdf-templates/get-print-templates', {code: tab.id});
            if (resPrintTemplate.result && resPrintTemplate.result.data) {
                printTemplates = resPrintTemplate.result.data;
            }
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloContent', content);
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloContentOriginal', content);
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplate', pdfTemplate);
            storeModul.dispatch('sendMailTemplateStore/setAction', tab.id);
            storeModul.dispatch('sendMailTemplateStore/setSendMailZaloTemplateId', pdfTemplateId);
            storeModul.dispatch('sendMailTemplateStore/setPdfFileId', file);
            storeModul.dispatch('sendMailTemplateStore/setPdfFileIdOrigin', file);
            storeModul.dispatch('sendMailTemplateStore/setDataValueKeys', dataValue);
            storeModul.dispatch('sendMailTemplateStore/setPrintTemplates', printTemplates);
            storeModul.dispatch('sendMailTemplateStore/setCurrentTabName', tab.name);
            if(pdfTemplateId){
                emitter.emit('reloadContentDataPdfTemplate', pdfTemplateId);
            }
        },
        setTabData(values) {
            this.$refs.tab.setTabData(values);
        },
    },
    mounted() {
        document.title = this.$t("message.SendMailZaloIndex.title_document");
    }, 
    created() {
        emitter.on('loadDataPdfTemplateContent',(id) => {       
            this.load(this.currentTab, id);
        })
    },

}
</script>

<style scoped>

</style>
    