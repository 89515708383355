<template>
 <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc;">
                    <div class="d-flex">
                        <h5 style="fotn" class="modal-title py-2" > {{this.isEdit? $t('message.supplierIndex.modal.titleUpdate') : $t('message.supplierIndex.modal.titleCreate')}}</h5>
                    </div>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0">
                   <TabInfo ref="tab" :supplierData="entry" :supplierGroupData="supplierGroups" :isEdit="this.isEdit"></TabInfo>
                </div>
                <div class="modal-footer">
                  <button class="btn btn-primary" back-url="/suppliers/index" @click="save" :disabled="requestData">
                      <i class="fa fa-save"></i>
                      {{$t('message.supplierIndex.modal.button.save')}}
                  </button>
                  <button type="button" class="btn btn-secondary text-white  mr-2"
                    @click="closeModal">
                    <i class="fa fa-ban mr-1"></i>
                    {{$t('message.supplierIndex.modal.button.skip')}}
                  </button>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
</template>
<script>
import TabInfo from "@/views/suppliers/tabs/TabInfo";

import emitter from "@/lib/emitter";
import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import store from "@/store";
import ModalConfirm from "@/components/ModalConfirm.vue";

export default {
    name: "ModalAddInfoSuppliers",
    components: { TabInfo, ModalConfirm},
    props: {
        openModal: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
      clearInput(){
        this.entry = {
            'code': '',
            'name': '',
            'contactNumber': '',
            'address': '',
            'provinceId': null,
            'districtId': null,
            'wardId': null,
            'email': '',
            'company': '',
            'taxCode': '',
            'supplierGroupId': null,
            'note': '',
          };
        emitter.emit('removeSupplierData');
      },
      async save() {
        this.requestData = true;
        const tab = this.$refs.tab;
        if(tab.entry.paymentTerms){
            tab.entry.paymentTerms = tab.entry.paymentTerms.trim();
        }
        let param={
          entry: tab.entry,
          currentBranchID:tab.currentBranchID,
        }
        
        let strSave = '';
        if(this.id) {
            strSave = '/v1/suppliers/update';
        }
        else {
            strSave = '/v1/suppliers/save';
        }
        
        const res = await $post(strSave, param);

        // const res = await $post('/v1/suppliers/save', param);
        if (res.code != 200) {
          this.requestData = false;
          this.errors = res.errors;
          scrollToElement('.error-label')
        }
        $alert(res);
        if(res.code == 200){
          this.$emit('inputData','1');
          this.$emit('getNewData', res.data);
          this.hide();
        }
      },
      async show(id) {
        if (id){
          this.isEdit=true;
          this.id = id;
          await this.load(id);
        }else {
          this.isEdit=false;
        }
        await this.loadSupplierGroupData();
        await this.loadDataLocation();
        if(!this.entry.userId){
          this.entry.userId = store.state.Auth.user.id;
        }
        this.default = {...this.entry};
        this.$refs.tab.entry = this.entry;
        // this.$refs.tab.supplierGroups = this.supplierGroups;
        // emitter.emit('loadDataSupplier');
        window.jQuery(this.$refs.modal).modal('show');
      },
      hide(){
        this.requestData = false;
        this.$emit('inputData','1');
        this.clearInput();
        window.jQuery(this.$refs.modal).modal('hide');
      },
      async load(id) {
        const res = await $get('/v1/suppliers/show', {
            id: id
        });
        if (res.code === 404) {

            location.replace('/404');
            return;
        }
        this.entry = res.data.entry;
        
      },
      async loadSupplierGroupData() {
        //Nhóm nhà cung cấp
        const sRes = await $get('/v1/supplier-groups/get-data');
        this.$refs.tab.supplierGroups = sRes.data.entries;
      },
      async loadDataLocation(){
          const res = await $get('/v1/locations/provinces', {
              provinceId: this.entry.provinceId,
              districtId: this.entry.districtId
          });
          this.$refs.tab.provinces = res.data.provinces;
          this.$refs.tab.districts = res.data.districts;
          this.$refs.tab.wards = res.data.wards;
      },
      closeModal(){
        const tab = this.$refs.tab;
        const isChange = isChangeData(tab.entry, this.default);
        if(isChange == true){
            this.hide();
        }else{
            this.titleConfirm = this.$t('message.supplierIndex.confirm');
            if(this.id === null){
              this.contentConfirm = this.$t('message.supplierIndex.contentConfirmCancelCreate');
            }else{
              this.contentConfirm = this.$t('message.supplierIndex.contentConfirmCancelUpdate');
            }
            this.$refs.ModalConfirm.show();
        }
      },
    },
    data () {
        return {
          isEdit:false,
          id: null,
          entry:{
            'code': '',
            'name': '',
            'contactNumber': '',
            'address': '',
            'provinceId': null,
            'districtId': null,
            'wardId': null,
            'email': '',
            'company': '',
            'taxCode': '',
            'supplierGroupId': null,
            'note': '',
          },
          supplierGroups: [],
          default: {},
          contentConfirm: '',
          titleConfirm: '',
          requestData: false,
        }
    }
}
</script>
<style>
.action-bar {
    display: none !important;
}
</style>