import moment from 'moment';
import {numberFormat, parseIntEx} from "../../lib/utils";
const currentYear = (new Date).getFullYear().toString();

function n(num) {
    num = parseIntEx(num);
    return numberFormat(num);
}
function date(tzTime) {
    if (!tzTime) {
        return '';
    }

    const m = moment(tzTime);

    if (m.format('YYYY') === currentYear) {
        return m.format('DD/MM')
    }

    return m.format('DD/MM/YYYY')
}

const fields = {
    'Mã hàng': 'productCode',
    'Tên hàng': 'productName',
    'Số lượng': 'qty',
    'Giá trị niêm yết': {
        field: 'originalPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Doanh thu': {
        field: 'subPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'Chênh lệch': {
        field: 'diffPrice',
        callback: (value) => {
            return n(value);
        }
    },
    'SL Trả': 'returnQty',
    'Giá trị trả': {
        field: 'returnPrice',
        callback: (value) => {
            return n(value);
        },
    },
    'Doanh thu thuần': {
        field: 'totalPrice',
        callback: (value) => {
            return n(value);
        },
    },
};

export default fields;
