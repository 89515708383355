<template>
    <div ref="modal" class="modal mx-0 fade" :class="this.disabled && this.disabled === true ? 'modal-second' : ''"
         tabindex="-1" role="dialog" id="modalJobTitle" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{typeEditAdd === "EDIT" && this.id != null ? $t('message.JobTitle.titleUpdate') : $t('message.JobTitle.titleCreate')}}</h5>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <div class="row">
                                <div class="col-3">
                                    <b>{{ $t('message.JobTitle.name') }}<label class="text-danger px-0">*</label> </b>
                                </div>
                                <div class="col-9">
                                    <InputText v-model="entry.name"
                                        :errors="errors.name"/>
                                </div>
                            </div>
                            <div class="row mb-3">
                                <div class="col-3">
                                    <b>{{ $t('message.JobTitle.description') }}</b>
                                </div>
                                <div class="col-9 description-job-title">
                                    <i class="fa fa-pencil-alt ml-2 text-secondary"></i>
                                    <input type="text" name="description" v-model="entry.description" class="form-control" autocomplete="off">
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-3">
                                    <b>{{ $t('message.JobTitle.status') }}</b>
                                </div>
                                <div class="col-4 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">
                                        {{ $t('message.JobTitle.active') }}
                                        <input type="radio" name="status" v-model="entry.status" :value="1" class="mr-2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-5 d-flex align-items-center">
                                    <label class="container-radio ml-2 min-vw-100">
                                        {{ $t('message.JobTitle.inActive') }}
                                        <input type="radio" name="status" v-model="entry.status" :value="2" class="mr-2">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-5 mx-4 my-4">
                        <div class="col-lg-12 flex-end">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{ $t('message.JobTitle.save') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white  mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.JobTitle.skip') }}
                            </button>
                            <button type="button" class="btn btn-danger mr-2" v-if="(typeEditAdd === 'EDIT' && this.id != null)" @click="removeJobTitle(entry)">
                                <i class="fa fa-trash-alt mr-1"></i>
                                {{ $t('message.JobTitle.remove') }}
                            </button>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
    <ModalRemoveJobTitle ref="ModalRemoveJobTitle" @inputData="hide()"/>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm" :disabledInConfirm="this.disabled"/>
</template>

<style scoped>
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}
#modal-third {
    z-index: 1;
    opacity:0.5;
}
.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
</style>
<script>
import InputText from "@/components/forms/InputText";

import {$post, $alert, $get, scrollToElement, isChangeData} from "@/lib/utils";
import emitter from "@/lib/emitter";
import ModalConfirm from "@/components/ModalConfirm";
import ModalRemoveJobTitle from "@/components/employees/ModalRemoveJobTitle";
// import { valueToNode } from "@babel/types";
export default {
    name: "ModalAddJobTitle",
    components: {InputText, ModalConfirm, ModalRemoveJobTitle},
    props: {
        typeEditAdd: String,
        disabledJobTitle:Boolean,
    },
    data() {

        return {
            entry: {},
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            id: null,
            disabled: this.disabledJobTitle,
        }
    },
    methods: {
        clearInput(){
            this.entry = {
                name: '',
                description: '',
                status: 1,
            };
            this.default = {
                name: '',
                description: '',
                status: 1,
            };
            this.id = null;
        },
        async show(id = null) {              
            this.clearInput();
            if(id === null){
                window.jQuery(this.$refs.modal).modal('show');
            }else{
                window.jQuery(this.$refs.modal).modal('show');
                await this.load(id);
                this.default = {...this.entry};
                this.id = id;
            }
        },
        hide() {   
            this.clearInput();  
            this.$emit('inputData','1');
            emitter.emit('reloadDataEmployee');
            window.jQuery(this.$refs.modal).modal('hide');
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.JobTitle.confirm');
                if(this.id != null){
                    this.contentConfirm = this.$t('message.JobTitle.cancelUpdate');
                }else{
                    this.contentConfirm = this.$t('message.JobTitle.cancelCreate');
                }
                this.$refs.ModalConfirm.show();
            }
        },
        async load(id = null) {
            const res = await $get('/v1/job-title/show', {
                id: id
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
        },
        async save() {
            this.errors = {};
            const res = await $post('/v1/job-title/save', {
                entry: this.entry
            });

            if (res.code === -1) {
                this.errors = res.errors;
                scrollToElement('.error-label')
            }
            $alert(res);
            if(res.code == 200){
                this.hide();
            }
        },
        removeJobTitle(entry){
            let id = entry.id;
            let name = entry.name;
            this.$refs.ModalRemoveJobTitle.show(id, name);
        },
    },
    mounted() {
    },
    created () {
        emitter.on("showPopupAddJobTitle", (value) => {
            this.disabled = value;
            this.show();
        });
    },
}
</script>

