<template>
    <div class="d-row g-4 p-3 my-4 app-card app-card-stat shadow-sm mx-0">
        <div class="col-12 col-lg-12 text-left">
            <h5 class="font-weight-bold">{{$t('message.StatisticalInvoice.title')}}</h5>
        </div>
        <div class="col-3 col-lg-3">
            <div class="row h-100 border-right d-flex align-items-center">
                <div class="col-3 col-lg-3 m-0 p-0">
                    <label class="icon-revenue">
                        <span>$</span>
                    </label>
                </div>
                <div class="col-9 col-lg-9 m-0 p-0">
                    <div class="text-left">
                        {{ statistic.countInvoicesToday + ' ' + this.$t('message.StatisticalInvoice.invoice') }}
                    </div>
                    <div class="text-color-success text-left font-weight-bold">
                        {{ new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(statistic.amountInvoicesToday ?? 0) }}
                    </div>
                    <div class="text-left">
                        {{$t('message.StatisticalInvoice.revenue')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 col-lg-3">
            <div class="row h-100 border-right d-flex align-items-center">
                <div class="col-3 col-lg-3 m-0 p-0">
                    <label class="icon-return">
                        <span>
                            <i class="fa fa-reply"></i>
                        </span>
                    </label>
                </div>
                <div class="col-9 col-lg-9 m-0 p-0">
                    <div class="text-left">
                        {{ statistic.countReturnsToday + ' ' + this.$t('message.StatisticalInvoice.promissory') }}
                    </div>
                    <div class="text-color-warning text-left font-weight-bold">
                        {{ new Intl.NumberFormat('vi-VN', {style: 'currency', currency: 'VND'}).format(statistic.amountReturns ?? 0) }}
                    </div>
                    <div class="text-left">
                        {{$t('message.StatisticalInvoice.return')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 col-lg-3">
            <div class="row h-100 border-right d-flex align-items-center">
                <div class="col-3 col-lg-3 m-0 p-0">
                    <label :class="statistic.comparedToYesterday >= 0 ? 'text-color-success' : 'text-color-danger'">
                        <span>
                            <i class="fa" :class="statistic.comparedToYesterday >= 0 ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
                        </span>
                    </label>
                </div>
                <div class="col-9 col-lg-9 m-0 p-0">
                    <div class="text-left" :class="statistic.comparedToYesterday >= 0 ? 'text-color-success' : 'text-color-danger'">
                        {{ statistic.comparedToYesterday + " %"}}
                    </div>
                    <div class="text-left">
                        {{$t('message.StatisticalInvoice.compared_to_yesterday')}}
                    </div>
                </div>
            </div>
        </div>
        <div class="col-3 col-lg-3">
            <div class="row h-100 d-flex align-items-center">
                <div class="col-3 col-lg-3 m-0 p-0">
                    <label :class="statistic.comparedSamePeriodLastMonth >= 0 ? 'text-color-success' : 'text-color-danger'">
                        <span>
                            <i class="fa" :class="statistic.comparedSamePeriodLastMonth >= 0 ? 'fa-arrow-up' : 'fa-arrow-down'"></i>
                        </span>
                    </label>
                </div>
                <div class="col-9 col-lg-9 m-0 p-0">
                    <div class="text-left" :class="statistic.comparedSamePeriodLastMonth >= 0 ? 'text-color-success' : 'text-color-danger'">
                        {{ statistic.comparedSamePeriodLastMonth + " %"}}
                    </div>
                    <div class="text-left">
                        {{$t('message.StatisticalInvoice.compared_same_period_last_month')}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $get, $alert } from "@/lib/utils";
import store from "@/store";
export default {
    data() {
        return {
            statistic: {},
        };
    },
    methods: {
        async getStatisticalInvoice() {
            const res = await $get('/v1/dashBoard/get-result-invoices-today', {branchId: store.state.CurrentBranch.id});
            if (res.status.code != 200) {
                $alert({ code: 500, message: 'lỗi server' });
                return;
            }
            this.statistic = res.result;
        },
    },
    created() {
        this.getStatisticalInvoice();
    }
};
</script>
<style scoped lang="scss">
    @import "@/assets/css/vars.scss";
    .icon-revenue{
        display: inline-block;
        border-radius: 100px;
        background: $base-color;
        width: 30px;
        height: 30px;
        margin: 0;
        line-height: 32px;
        color: #fff;
    }
    .icon-return{
        display: inline-block;
        border-radius: 100px;
        background: rgba(255, 154, 98, 1);
        width: 30px;
        height: 30px;
        margin: 0;
        line-height: 32px;
        color: #fff;
    }
    .text-color-success{
        color: $base-color !important;
        font-size: 18px !important;
        font-weight: 600;
    }
    .text-color-warning{
        color: rgba(255, 154, 98, 1) !important;
        font-size: 18px !important;
        font-weight: 600;
    }
    .text-color-danger{
        color: #dc3545 !important;
        font-size: 18px !important;
        font-weight: 600;
    }
</style>