<template>
    <div class="modal fade" ref="modal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document" style="max-width: 1250px">
            <div class="modal-content">
                <div class="modal-header border-bottom-0">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{$t('message.common.updateDeliveryInforSuccess')}}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-3">
                            <div class="card mt-1" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">
                                                {{ $t('message.InputQuickSearch.button.search') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputText v-model="filter.code_invoice"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.title.code_invoice')"
                                                @update:modelValue="search"
                                            ></InputText>
                                            <InputText v-model="filter.delivery_code"
                                                class="sale-input-number full-left"
                                                :placeholder="$t('message.InputQuickSearch.title.delivery_code')"
                                                @update:modelValue="search"
                                            ></InputText>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card mt-2" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-12">
                                            <label for="" class="typeTitle">
                                                {{ $t('message.common.sellTime') }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-0 pb-3 pt-0" id="branchBody">
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.start_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model="filter.start_date"/>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <InputDatePicker :sortTimeHeight="true" :placeholder="$t('message.TransporterIndex.modal.modelSelectInvoicePayment.end_date')" class="w-100 mb-0 custom-input"
                                            @input="search" v-model="filter.end_date"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-9">
                            <table class="table table-index table-striped">
                                <thead>
                                <tr>
                                    <th class="checkbox-table"></th>
                                    <th>{{$t('message.InputQuickSearch.title.code_invoice')}}</th>
                                    <th>{{$t('message.InputQuickSearch.title.delivery_code')}}</th>
                                    <th>{{$t('message.InputQuickSearch.title.control_history')}}</th>
                                    <th>{{$t('message.headers.customers')}}</th>
                                    <th>{{$t('message.TransporterIndex.invoice_value')}}</th>
                                    <th>{{$t('message.TransporterIndex.modal.modelSelectInvoicePayment.cod')}}</th>
                                    <th>{{$t('message.TransporterIndex.modal.modelSelectInvoicePayment.fee_payment')}}</th>
                                    <th>{{$t('message.DeliveryIndex.status')}}</th>
                                </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(item, index) in data" :key="index">
                                        <tr>
                                            <td class="checkbox-table" @click="removeHistoryItem(index, item.id)"><i class="fa fa-trash-alt"></i></td>
                                            <td v-text="item.invoice ? item.invoice.code : null"></td>
                                            <td>
                                                <input type="text" v-model="item.code" class="border-bottom-1 bg-transparent"/>
                                            </td>
                                            <td></td>
                                            <td v-text="item.invoice.customer ? item.invoice.customer.name : null"></td>
                                            <td v-text="item.invoice ? n(item.invoice.total) : null"></td>
                                            <td v-text="n(item.priceCodPayment) ?? null"></td>
                                            <td>
                                                <template v-if="item.isCanUpdate">
                                                    <InputNumberFormat :pxNone="true" v-model="item.price" class="sale-input-number mb-0" :cardTransaction="true" :bgTransparent="true"></InputNumberFormat> 
                                                </template>
                                                <span v-else v-text="n(item.price)"></span>
                                            </td>
                                            <td class="position-relative">
                                                <template v-if="item.isCanUpdate">
                                                    <SelectSearch :vModel="true" v-model="item.status" :placeholder="$t('message.common.status')" :label="'branchId'" :defaultOption="listStatus" :modelValue="item.status" :readonly="true"/>
                                                </template>
                                                <span v-else v-text="item.statusName"></span>
                                            </td>
                                        </tr>
                                    </template>
                                    <template v-if="data.length == 0">
                                        <tr>
                                            <td :colspan="6" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <p>{{$t('message.common.notFound')}}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="load"/>
                        </div>
                    </div>
                    <div class="text-right mt-4">
                        <button type="button" class="btn btn-save mr-2" @click="save">
                            <i class="fa fa-check"></i>&nbsp;
                            {{$t('message.button.save')}}
                        </button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">
                            <i class="fa fa-ban"></i>&nbsp;
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                    
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import InputText from "@/components/forms/InputText";
    import {$post, debounce, $alert} from "@/lib/utils";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import Pagination from "@/components/Pagination";
    import emitter from "@/lib/emitter";
    import SelectSearch from "@/components/SelectSearch.vue";
    import toastr from 'toastr';

    let app;

    export default {
        name: "ModalUpdateHistoryDelivery",
        emits: ['select'],
        props: ['defaultOptions'],
        components: {Pagination, InputDatePicker, InputText, InputNumberFormat, SelectSearch},
        data() {
            return {
                data: [],
                filter: {
                    product_code: '',
                    product_name: '',
                    code_name_phone: '',
                    code_invoice: '',
                    delivery_code: '',
                    start_date: '',
                    end_date: '',
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1,
                },
                numberRecord: 15,
                id: null,
                listStatus: [],
                id_remove: []
            }
        },
        mounted() {
            app = this;
        },
        methods: {
            show(id, listStatus) {
                this.listStatus = listStatus;
                this.id = id;
                this.clearValue();
                this.load();
                window.jQuery(this.$refs.modal).modal({
                    backdrop: 'static', keyboard: false
                });
            },
            async load(page = 1) {
                emitter.emit("onLoading");
                const res = await $post('/v1/transporters/history-deliveries/' + this.id, {...this.filter, record: this.numberRecord, page: page});
                this.data = res.result;
                emitter.emit("offLoading");
            },
            onSelectInvoice(invoice) {
                this.$emit('select', invoice.code);
                window.jQuery(this.$refs.modal).modal('hide');
            },
            search() {
                app.load();
            },
            clearValue(){
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
            },
            async save(){
                const result = await $post('/v1/transporters/update-multiple-delivery', {data: this.data, id_remove: this.id_remove});
                if(result.result && !result.result.status && result.result.message){
                    $alert({code: 500, message: result.result.message});
                    return;
                }
                this.$emit("updated");
                toastr.success(this.$t('message.common.updateHistoryDeliverySuccess'));
                window.jQuery(this.$refs.modal).modal('hide'); 
            },
            removeHistoryItem (index, id){
                this.data.splice(index, 1);
                this.id_remove.push(id);
            }
        },
        beforeUnmount() {
            window.jQuery(this.$refs.modal).modal('hide');
        },
    }
</script>

<style scoped>
.title-search {
    background-color: #b1ddf0;
    padding: 5px 10px;
    font-weight: bold;
    margin-bottom: 1rem;
}
.table{
    overflow: auto;
    display: block;
    min-height: 100%;
}
.table th, .table td {
    height: 40px !important;
    min-width: 150px !important;
}

.table .checkbox-table {
    min-width: initial !important;
}
.table tbody td{
    background-color: #F2F2F2;
    border-bottom: 1px solid #FFFFFF;
}
.form-group .form-control{
    padding-left: 0 !important;
}
</style>
