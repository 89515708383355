<template>
 <div ref="modal" class="modal mx-0 fade modal-third" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-xl" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h5 class="modal-title py-2">{{this.titleName}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    {{this.content}}
                    <div class="col-12 d-flex justify-content-end mt-4">
                        <button class="btn btn-danger mr-2" @click="submit()">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalMessageGeneral',
    props: {},
    data () {
        return {
            titleName:null,
            content: null,
            type: 1
        };
    },
    methods: {
        show(title, content, type) {
            this.titleName = title;
            this.content = content;    
            this.type = type;    
            window.jQuery(this.$refs.modal).modal('show');
        },
        async submit_backup() {      
            
            switch (this.type) {
                case 1:
                    this.$emit('inputData');
                    window.jQuery(this.$refs.modal).modal('hide');
                    break;
            
               case 2:                    
                    this.$emit('acceptStepSecond');
                    window.jQuery(this.$refs.modal).modal('hide');
                    break;
            }
           
        },
        async submit() {      
            this.$emit('acceptStepSecond');
            window.jQuery(this.$refs.modal).modal('hide');           
        }
    }
}
</script>
<style>
</style>