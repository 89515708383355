<template>
    <div class="d-flex align-items-center position-relative h-100" :class="[(this.readonly ? 'cursor-ban' : '')]">
        <input type="text" :placeholder="placeholder" v-model="this.dateTime" 
                :readonly="this.readonly" 
                :class="[(this.readonly ? 'cursor-ban' : ''), (backgroundColor ? backgroundColor : ''), (borderActive ? 'border-bottom-focus-active' : '')]" 
                class="border-none inputText_picker h-100" 
                @change="changeDateTime">
        <input type="date" class="inputDate_picker h-100" 
                v-model="this.date" :readonly="this.readonly" 
                :class="[(this.readonly ? 'cursor-ban' : ''), (backgroundColor ? backgroundColor : '')]" 
                @change="changeDate">
        <i class="far fa-clock time_picker" data-toggle="dropdown" v-if="!this.readonly"></i>
        <ul class="dropdown-menu format__list-time">
            <template  v-for="(n, i) in 24" :key="i">
                <li class="pl-3 pb-2" @click="changeTime(('0' + i ).slice(-2) + ':00')">{{ ('0' + i ).slice(-2) + ':00' }}</li> 
                <li class="pl-3 pb-2" @click="changeTime(('0' + i ).slice(-2) + ':30')">{{ ('0' + i ).slice(-2) + ':30' }}</li>
            </template>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
import {$alert} from "@/lib/utils";
import emitter from '@/lib/emitter';
export default {
    name: "SelectDateTimePicker",
    props: ['modelValue', 'placeholder', 'readonly', 'backgroundColor', 'borderActive'],
    data(){
        return{
            value: this.modelValue,
            dateTime: null,
            date: moment(this.modelValue).format('YYYY-MM-DD'),
            time: moment(this.modelValue).format('HH:mm'),
        }
    },
    methods: {
        formatData(){
            this.value = this.value ? moment(this.value).format('YYYY-MM-DD HH:mm') : moment().format('YYYY-MM-DD HH:mm');
            this.dateTime = this.value ? moment(this.value).format('DD/MM/YYYY HH:mm') : moment().format('DD/MM/YYYY HH:mm');
            this.$emit('update:modelValue', this.value);
        }, 
        changeDateTime(){
            if(this.dateTime){
                this.value = moment(this.dateTime, "DD/MM/YYYY HH:mm").toDate();
            }
            this.formatData();
        },
        changeDate(){
            this.value = this.date + ' ' + this.time;
            this.formatData();
        },
        changeTime(time){
            this.time = time;
            this.value = this.date + ' ' + this.time;
            this.formatData();
        },
    },
    created(){
        this.formatData();
        emitter.on('clearSelectDateTimePicker', () => {
            this.value = null;
            this.dateTime = null;
            this.date = moment().format('YYYY-MM-DD');
            this.time = moment().format('HH:mm');
            this.formatData();
        })
    },
    watch: {
        modelValue(neValue) {
            if (neValue) {
                this.date = moment(neValue).format('YYYY-MM-DD');
                this.time = moment(neValue).format('HH:mm');
            }
        },
    }
}
</script>

<style scoped lang="scss">
.format__list-time{
    position: absolute;
    will-change: transform;
    width: 100% !important;
    height: 200px !important;
    top: 0px;
    left: 0px;
    transform: translate3d(150px, 23px, 0px) !important;
    background-color: #fff;
}
.inputDate_picker, .inputDate_picker:focus, .inputDate_picker:active{
    width: 90%;
    border: none;
    position: absolute;
    outline: none;
}
.time_picker{
    position: absolute;
    right: 0px;
}
input[type="date"]:focus, input[type="date"]:active{
    outline: none;
    border: none;
}
.inputText_picker{
    width: 80% !important;
    z-index: 1;
}
.format__list-time li{
    cursor: pointer;
}
</style>
