<template>
    <AppPermission :permission="this.$permissions.saleChannels.data? this.$permissions.saleChannels.data : '' ">
    <div class="container my-4">
        <div class="row m-0 p-0">
          <div class="mainLeft">
          <!-- <div class="col-md-2" id="col2"> -->
            <div class="main-title">
              <span>
                {{ $t('message.SaleChannelIndex.title') ?? "" }}
              </span>
            </div>
            <div>
              <div class="card-header mx-0 px-0" id="typeHeader">
                <div class="row mx-2">
                  <div class="col-8 text-title">
                    <label>{{ $t('message.common.numberRecord') ?? "" }}:</label>
                  </div>
                  <div class="col-4">
                    <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                      <option value="15">15</option>
                      <option value="20">20</option>
                      <option value="30">30</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <!-- Filter trạng thái -->
            <div class="card" id="typeCard">
              <div class="card-header mx-0 px-0" id="typeHeader">
                <div class="row mx-2">
                  <div class="col-9">
                    <label class="typeTitle">{{ $t('message.SaleChannelIndex.status') ?? "" }}</label>
                  </div>
                  <div class="col-1">
                    <a href="#" class="text-dark" id="linkIcon"
                       @click="showHide('status', 'sttDown', 'sttUp')">
                      <i class="fa fa-chevron-down dp-hide" id="sttDown"></i>
                      <i class="fa fa-chevron-up" id="sttUp"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div class="card-body my-0 py-2" id="status">
                <template v-if="this.optionSttSaleChannel">
                    <div class="row mb-2 ml-0" v-for="option in this.optionSttSaleChannel" :key="option.id">
                        <label class="container-radio ml-2">{{$t(option.label)}}
                            <input type="radio" name="sttSaleChannel" v-model="this.filter.status" :checked="this.filter.status === option.value" :value="option.value" class="cheker mr-2" @change="doFilter('status', option.value)">
                            <span class="checkmark"></span>
                        </label>
                    </div>
                </template>
              </div>
            </div>
          </div>

          <div class="mainRight">
          <!-- <div class="col-md-10" id="col10"> -->
            <div class="sticky-top">
                <div class="row">
                    <div class="col-5">
                    <div class="search-box">
                        <i class="fa fa-search mr-2"></i>
                        <input type="text" class="search-input"
                            @keydown.enter="doFilter('keyword', filter.keyword)"
                            v-model="filter.keyword" :placeholder="($t('message.SaleChannelIndex.search') ?? '')"/>
                    </div>
                    </div>
                    <div class="col-7 d-flex justify-content-end">
                        <button type="button" @click="clearFilter()" class="btn btn-default">
                            <i class="fa fa-filter mr-1"></i>
                            {{$t('message.button.removeFilter') ?? ''}}
                        </button>
                         <AppPermission :permission="this.$permissions.saleChannels.create?? '' ">
                            <button @click="showModalAddInfoSaleChannels()" class="btn  btn-save ml-1">
                                <i class="fa fa-plus"/> 
                                {{$t('message.SaleChannelIndex.add') ?? ''}}
                            </button>
                        </AppPermission>
                    </div>
                </div>
                <div>
                    <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                </div>
                <div class="row mt-2"></div>
                <div class="row mx-0 my-2" id="divTable">
                    <table class="table table-index table-striped w-100">
                    <thead>
                        <tr>
                            <th scope="col" v-for="field in fields" :key="field.field" class="pl-5">
                                <TableSortField :current="sortData.field" :no-sort="!sortData.isColumn" @onsort="sort" :name="$t(field.name)" :field="field.field" :class="field.class"/>
                            </th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-if="entries.length > 0">
                            <tr v-for="entry in entries" :key="entry.id" @click="(this.hasPermission(this.$permissions.saleChannels.update ?? '') || this.hasPermission(this.$permissions.saleChannels.remove ?? ''))? showModalSaleChannelsEdit(entry.id) : ''">
                                <template v-for="field in fields" :key="field.field">
                                    <td scope="col" class="ow-break-word pl-5" style="vertical-align:middle !important;" :style="'min-width:110px'">
                                        <div v-if="field.field =='name'" :class="field.class">
                                            <div class="row d-flex align-items-center">
                                                <div class="col-2">
                                                    <img v-if="entry.img && entry.img.url" :src="entry.img.url" alt="" class="detail-sale-channel-img">
                                                    <img v-else src="../../../public/img/default-product.png" alt="" class="detail-sale-channel-img">
                                                </div>
                                                <div class="col-10 description" v-if="entry.name">{{entry.name}}</div>
                                            </div>
                                        </div>
                                        <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :class="field.class" class="description">
                                        </div>
                                    </td>
                                </template>
                                <th class="d-flex justify-content-end">
                                     <AppPermission :permission="this.$permissions.saleChannels.update?? '' ">
                                        <button class="btn" @click="showModalSaleChannelsEdit(entry.id)">
                                            <i class="fa fa-edit"/>
                                        </button>
                                    </AppPermission>
                                    <AppPermission :permission="this.$permissions.saleChannels.remove?? '' ">
                                        <button class="btn text-danger" @click.stop="showModalRemoveSaleChannels(entry.id, entry.name)">
                                            <i class="fa fa-trash-alt"/>
                                        </button>
                                    </AppPermission>
                                </th>
                            </tr>
                        </template>
                    </tbody>
                    </table>
                </div>
                <div class="row ml-auto mr-auto" v-if="entries.length == 0" id="notFound">
                    <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                        <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                        <!-- <p>{{$t('message.common.notFound')}}</p> -->
                        <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                    </div>
                </div>
            </div>
          </div>
        </div>
    </div>
    <ModalAddInfoSaleChannels ref="ModalAddInfoSaleChannels" @inputData="load()" :typeEditAdd="typeEditAdd"/>
    <ModalRemoveSaleChannels ref="ModalRemoveSaleChannels" @inputData="load()"/>
    </AppPermission>
</template>

<script>
    import {$get, clone, getSearchQuery} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import emitter from "@/lib/emitter";
    import ModalAddInfoSaleChannels from "@/components/sale_channels/ModalAddInfoSaleChannels";
    import ModalRemoveSaleChannels from "@/components/sale_channels/ModalRemoveSaleChannels";    
    import AppPermission from '@/components/AppPermission.vue';
    import permissionMixin from '@/permissionMixin';
    export default {
        name: "SaleChannelIndex",
        components: {TableSortField, Pagination, ModalAddInfoSaleChannels, ModalRemoveSaleChannels, AppPermission},
        mixins: [permissionMixin],
        mounted() {
            document.title = this.$t('message.SaleChannelIndex.documentTitle') ?? '';
            this.clearFilter()
        },
        data() {
            const query = getSearchQuery();
            return {
                filter: {
                    keyword: query.keyword,
                    status: '',
                },
                checkedAll: false,
                fields: [
                    { "field": "name", "name": "message.SaleChannelIndex.name", "languages": true },
                    { "field": "description", "name": "message.SaleChannelIndex.description", "languages": true },
                    { "field": "status", "name": "message.SaleChannelIndex.status", "languages": true }
                ],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {},
                numberRecord: 15,
                typeEditAdd: 'ADD',
                optionSttSaleChannel: [
                    { id: 0, label: 'message.SaleChannelIndex.all', value: '' },
                    { id: 1, label: 'message.SaleChannelIndex.active', value: 1 },
                    { id: 2, label: 'message.SaleChannelIndex.inActive', value: 2 },
                ],
                isFilter: false,
            }
        },
        methods: {
            async clearFilter() {
                await this.$router.replace({
                    path: '/sale-channels/index',
                    query: {}
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.sortData).forEach(key => {
                    this.sortData[key] = '';
                });
                this.numberRecord = 15;
                this.isFilter = false;
                this.load();
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                query['page'] = 1;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();


            },
            async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            async load() {
                emitter.emit("onLoading");
                const res = await $get('/v1/sale-channels/data', {...this.$route.query, numberRecord: this.numberRecord});
                this.entries = res.result.data.data;       
                this.paginate.totalRecord = res.result.data.total;
                this.paginate.currentPage = res.result.data.current_page;
                this.paginate.lastPage = res.result.data.last_page;
                // this.fields = res.result.fields;
                emitter.emit("offLoading");
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                query.numberRecord = v.target.value;
                await this.$router.push({query: query});
                this.isFilter = true;
                this.load();
            },
            showModalAddInfoSaleChannels(){
                this.typeEditAdd = 'ADD';
                this.$refs.ModalAddInfoSaleChannels.show();
            },
            showModalSaleChannelsEdit(id){
                this.typeEditAdd = 'EDIT';
                this.$refs.ModalAddInfoSaleChannels.show(id);
            },
            showModalRemoveSaleChannels(id, name) {
                this.$refs.ModalRemoveSaleChannels.show(id, name);

            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.text-title {
  line-height: 52px;
}

#typeCard {
  margin-top: -8px;
}
</style>