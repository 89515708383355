<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" :class="this.disabledDeparment && this.disabledDeparment === true ? 'modal-second' : ''">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                       {{ $t("message.ModalFeeAdjustment.title") }}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <input type="hidden" v-model="this.entry.currentPrice" />
                                    <div class="row" v-if="!this.isEdit">
                                        <div class="col-3 ">
                                            <b>{{ $t("message.ModalFeeAdjustment.current") }}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <span v-text="n(entry.price)"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row" v-else>
                                        <div class="col-3 ">
                                            <b>{{$t('message.PurchaseOrderIndex.tabs.table.history_payment.code')}}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <span v-text="entry.code"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mb-3">
                                        <div class="col-3 ">
                                            <b>{{ $t("message.ModalFeeAdjustment.updatedAt") }}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="border-bottom-1">
                                                <SelectDateTimePicker :modelValue="entry.dateAdjustment" v-model="entry.dateAdjustment" :borderActive="true" :backgroundColor="'bg-light'"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-3 d-flex align-items-center">
                                            <b>{{ $t("message.ModalFeeAdjustment.adjustedValue") }}</b>
                                        </div>
                                        <div class="col-9">
                                            <InputNumberFormat :pxNone="true" :textLeft="true" v-model.trim="entry.value" :bgTransparent="false"></InputNumberFormat>
                                            <!-- <InputText v-model.trim="entry.value" placeholder="Giá trị nợ điều chỉnh" :errors="errors.contactNumber" :cusNumber="true" :sCustomer="true"/> -->
                                        </div>
                                    </div>

                                    <div class="row" v-if="this.isEdit">
                                        <div class="col-3 ">
                                            <b>{{ $t("message.ModalFeeAdjustment.createdBy") }}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <span v-text="entry.created_by ? entry.created_by.name : null"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col-3 d-flex align-items-centerZZ">
                                            <b>{{ $t("message.ModalFeeAdjustment.description") }}</b>
                                        </div>
                                        <div class="col-9">
                                            <div class="form-group">
                                                <InputTextArea v-model.trim="this.entry.note" :placeholder="$t('message.ProductIndex.note')" :customerStyle="true" :max="255" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <button v-if="this.isEdit" type="button" class="btn btn-save mr-2" @click="update">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t("message.ModalFeeAdjustment.update") }}
                                    </button>
                                    <button v-else type="button" class="btn btn-save mr-2" @click="submit">
                                        <i class="fa fa-save mr-1"></i>
                                        {{ $t("message.ModalFeeAdjustment.update") }}
                                    </button>                                    
                                    <button type="button" class="btn btn-secondary text-white  mr-2" @click="clearInput">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{ $t('message.DepartmentIndex.modal.createOrUpdate.button.skip') }}
                                    </button>
                                    <button v-if="this.isEdit" type="button" class="btn btn-danger mr-2" @click="cancel(entry)">
                                            <i class="fa fa-save mr-1"></i>
                                            {{ $t("message.ModalFeeAdjustment.cancel") }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @updated="hide"/>
</template>
<script>
    import InputText from "@/components/forms/InputText";
    import {$post, $alert, scrollToElement, $get} from "@/lib/utils";
    import toastr from 'toastr';
    import InputTextArea from "@/components/forms/InputTextArea";
    import InputLocationPicker from "@/components/forms/InputLocationPicker";
    import QSelect from "@/components/QSelect";
    import store from "@/store";
    import ModalConfirm from "./ModalConfirmCancel.vue";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import InputNumberFormat from "@/components/forms/InputNumberFormat";
    import SelectDateTimePicker from "@/components/SelectDateTimePicker";
    import emitter from '@/lib/emitter';
    export default {
        name: "ModalFeeAdjustment",
        components: {InputText , ModalConfirm, InputTextArea, InputLocationPicker, QSelect, InputDatePicker, InputNumberFormat, SelectDateTimePicker},
        props: {
            openModal: {
                type: Boolean,
                default: false
            },
             disabledDeparment:Boolean,
        },
        data () {
            return {
                active: 1,
                entry: {},
                errors: {},
                provinces: [],
                districts: [],
                wards: [],
                isEdit: false,
                feeId: null
            }
        },
        mounted() {
           
        },
        created () {
        }, 
        methods: {
            async show (id , feeId = null) {
                if (id && feeId == null) {
                    const result = await $get('/v1/transporters/show-first', {id:id});
                    this.entry.transporterId = id;
                    if (!result.result) {
                        this.entry.price = "0";
                        this.entry.currentPrice = 0;
                    }else {
                        this.entry.currentPrice = result.result.price;
                        this.entry.price = result.result.value;
                    }
                } else if (id && feeId) {
                    this.isEdit = true;
                    this.feeId = feeId;
                    const result = await $get('/v1/transporters/show-update', {id: feeId});
                    this.entry = result.result;
                    this.entry.dateAdjustment = result.result.dateAdjustment;
                }
                emitter.emit('clearSelectDateTimePicker');
                
                window.jQuery(this.$refs.modal).modal('show');
            },
            hide() {
                this.$emit('updated');
                window.jQuery(this.$refs.modal).modal('hide');
            },
            clearInput() {
                this.setValue();
                window.jQuery(this.$refs.modal).modal('hide');
            },
            setValue () {
                this.isEdit = false;
                this.entry = {
                    value: null,
                };
            },

            async submit() {
                const result = await $post('/v1/transporters/fee-adjustment', {...this.entry});
                if (result.status.code == 200) {
                    toastr.success(this.$t('message.common.debtAdjustmentSuccess'));
                    this.clearInput();
                    this.$emit('updated', '');
                }else {
                    toastr.error(this.$t('message.common.debtAdjustmentFailed'));
                }
            },
            async update () {
                const result = await $post('/v1/transporters/update-adj', {...this.entry});
                if (result.result == true) {
                    toastr.success(this.$t('message.common.debtAdjustmentSuccess'));
                    this.clearInput();
                    this.$emit('updated', '');
                }else {
                    toastr.error(this.$t('message.common.debtAdjustmentFailed'));
                }
            },
            async cancel (entry) {
                this.$refs.ModalConfirm.show(entry);
            },

            isMessageError(errMss){ 
                if(errMss['addressValue']) {
                    toastr.error(errMss['addressValue']);                
                    return;
                }
                if(errMss['phone']) {
                    toastr.error(errMss['phone']);                
                    return;
                }
                if(errMss['provinceId']) {
                    toastr.error(errMss['provinceId']);
                    return;
                }
                if(errMss['districtId']){
                    toastr.error(errMss['districtId']);
                    return;
                }
                if(errMss['wardId']){
                    toastr.error(errMss['wardId']);
                    return;
                }
            },
           
            async load(id) {
                const res = await $get(`/v1/pickup-address/show/${id}`);
                if (res.code === 404) {
                    location.replace('/404');
                    return;
                }
                this.entry = res.result;
                if (res.result.work_days) {
                    res.result.work_days.forEach(element => {
                    this.$refs.tab.dayOfWorks.push(element.local_key_day);
                    this.entry.work_days = this.$refs.tab.dayOfWorks;
                    });
                }
            },
            
            showModalDeletePickup (id)
            {
                this.$refs.ModalConfirm.show(id);
            }
        }
    }
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
.description-job-title i{
    position: absolute;
    top: 0.5rem;
}
.description-job-title input{
    padding-left: 1.5rem;
}
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
.textInput {
    border: 0;
    border-bottom: 1px solid #ccc;
    border-radius: 0;
}
#fa_edit {
  position: absolute;
  left: -5px;
  top: 10px;
  color: #a7a2a2;
}
</style>