
<template>
    <AppPermission :permission="this.$permissions.customerGroups.data">
        <div>
            <div class="container my-4">
                <div class="row m-0 p-0">
                    <div class="mainLeft">
                    <!-- <div class="col-md-2" id="col2"> -->
                        <div class="main-title">
                            <span>{{ $t('message.customerGroupIndex.title') }}</span>
                        </div>
                        <div>
                            <div class="card-header mx-0 px-0" id="typeHeader">
                                <div class="row mx-2">
                                    <div class="col-8 text-title">
                                        <label>{{ $t('message.common.numberRecord') }}:</label>
                                    </div>
                                    <div class="col-4">
                                        <select :value="numberRecord" style="border: none;outline: none;" id="number_records" class="number_records" @change="onChange($event)">
                                            <option value="15">15</option>
                                            <option value="20">20</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="mainRight">
                    <!-- <div class="col-md-10" id="col10"> -->
                        <div class="sticky-top">
                            <div class="row">
                                <div class="col-md-5">
                                    <div class="search-box">
                                        <i class="fas fa-search mr-2"></i>
                                        <input type="text" class="search-input" v-model="params.keyword" @keyup.enter="search()"
                                            :placeholder="$t('message.customerGroupIndex.placeholder.search')" />
                                    </div>
                                </div>
                                <div class="col-md-7 d-flex justify-content-end">
                                    <button type="button" class="btn btn-default" @click="clearFilter"><i class="fa fa-filter mr-1"></i>{{ $t('message.button.removeFilter') }}</button>
                                    <AppPermission :permission="this.$permissions.customerGroups.create ? this.$permissions.customerGroups.create : '' ">
                                        <button class="btn  btn-save ml-1" @click="showModal(1)"><i class="fa fa-plus" /> {{ $t('message.customerGroupIndex.button.add') }} </button>
                                    </AppPermission>
                                </div>
                            </div>
                            <div>
                                <Paginate :value="paginate" :record="this.numberRecord" @change="pageChange" />
                            </div>
                            <div class="row mx-0 my-2" id="divTable">
                                <table class="table table-index table-striped w-100">
                                    <thead>
                                        <tr style="width:100%">
                                            <th class="pl-5 cell-name">
                                                <TableSortField :current="sortData.field" :no-sort="!sortData.isColumn" @onsort="sort" :name="$t('message.customerGroupIndex.name')" field="name" />
                                            </th>
                                            <th class="pl-3 cell-quantity">{{ $t('message.customerGroupIndex.quantity') }}</th>
                                            <th class="pl-5 cell-discount">{{ $t('message.customerGroupIndex.discount') }}</th>
                                            <th class="pl-5 cell-auto">{{ $t('message.customerGroupIndex.note') }}</th>
                                            <th class="action cell-check"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <template v-if="customerGroupList?.length > 0">
                                            <template v-for="(item, index) in customerGroupList" :key="item.id" >                                                    
                                                <tr class="master-row" :style="index % 2 ? 'background-color: white' : null" :id="'row_' + (index + 1)" @click="showModalCategoryEdit(item.id)">
                                                    <td class="ow-break-word pl-5 cell-name">
                                                        <p class="custom-desc" :title="item.name">{{ item.name ? item.name : ''}}</p>
                                                    </td>
                                                    <td class="ow-break-word pl-3 cell-quantity">{{item.totalCustomer? item.totalCustomer : 0}}</td>
                                                    <td class="ow-break-word pl-5 cell-discount">
                                                        <span v-if="item.discountBy == '1'">
                                                        {{ item.price != 0 ? n(item.price) + ' ' + $t('message.common.vnd') : '' }}</span>
                                                        <span v-if="item.discountBy == '2'">
                                                            {{ item.voucher != 0 ? n(item.voucher) + ' ' + $t('message.common.percent') : '' }}
                                                        </span>
                                                    </td>
                                    
                                                    <td class="ow-break-word pl-5 cell-auto" data-toggle="tooltip" data-placement="right" :title=item.note>
                                                        <p class="custom-desc">{{ item.note }}</p>
                                                    </td>
                                                    <td class="text-right cell-check d-flex align-items-center">
                                                        <AppPermission :permission="this.$permissions.customerGroups.update">
                                                            <button class="btn">
                                                                <i class="fa fa-edit" />
                                                            </button>
                                                        </AppPermission>
                                                        <AppPermission :permission="this.$permissions.customerGroups.remove">
                                                            <button class="btn text-danger" @click.stop="removeCustomerGroup(item.id, item.name)">
                                                                <i class="fa fa-trash-alt" />
                                                            </button>
                                                        </AppPermission>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                        <template v-if="customerGroupList.length == 0 && !this.loading">
                                            <tr>
                                                <td :colspan="5" class="pb-0 bg-light">
                                                    <div class="row detail-prd-row mx-0">
                                                        <div class="col-12 text-center text-secondary mt-5">
                                                            <h3>
                                                                <i class="fa fa-file mr-2 text-secondary"></i>
                                                                <i class="fa fa-exclamation"></i>
                                                            </h3>
                                                        </div>
                                                        <div class="col-12 text-center my-10 text-secondary mb-5">
                                                            <!-- <p>{{ $t('message.common.notFound') }}</p> -->
                                                            <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <modalAddCustomer ref="ModalAddCustomer" @updated="renderListDataCreate" />
        <ModalRemoveCustomerGroup ref="ModalRemoveCustomerGroup" @updated="renderData" @input="load()" />
        <ModalEditCustomer :customerGroupDetail="customerGroupDetail" ref="ModalEditCustomer" @updated="renderListData" @input="load()" :flag="1" />
    </AppPermission>
</template>

<script>
    import {$alert, $get, clone, setArray} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import TableSortField from "@/components/TableSortField";
    import modalAddCustomer from '@/components/customers/ModalAddCustomer';
    import ModalRemoveCustomerGroup from '@/components/customers/ModalRemoveCustomerGroup';
    import ModalEditCustomer from '@/components/customers/ModalEditCustomer';
    import emitter from "@/lib/emitter";    
    import AppPermission from '@/components/AppPermission.vue';
    export default {
        name: "CustomerGroupIndex",
        components: {TableSortField, Paginate, modalAddCustomer, ModalRemoveCustomerGroup, ModalEditCustomer, AppPermission
        },
        mounted() {
            document.title = this.$t('message.customerGroupIndex.documentTitle');
            const query = this.$route.query;
            this.filter = {...this.filter, ...query};
            this.paginate.currentPage = this.$route.query.page || 1;
            this.numberRecord = this.$route.query.numberRecord || "15";
            this.load();
            window.addEventListener('scroll', this.onScroll);
        },
        data() {
            return {
                fields: [],
                customerGroupList: [],
                customerGroupDetail: {},
                fixed: false,
                params: {
                    keyword: '',
                    status: ''
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                numberRecord: 15,
                flag: true,
                loading: false,
                isFilter: false,
            }
        },
        methods: {
           async load () {
            this.loading = true;
            emitter.emit("onLoading");
            const customerGroup = await $get('/v1/customer-group', {...this.$route.query, numberRecord: this.numberRecord});
            this.customerGroupList = customerGroup.data;
            this.paginate = customerGroup.paginate;
            emitter.emit("offLoading");
            this.loading = false;
           },
           async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                await this.$router.push({ query: { page: page, ...this.params } });
                this.load();
            },
            showModal(flag) {
                this.$refs.ModalAddCustomer.show(flag);
            },
            renderListDataCreate(newData) {
                this.customerGroupList.unshift(newData.data);
            },
            removeCustomerGroup(id, name) {
                this.$refs.ModalRemoveCustomerGroup.show(id, name);
            },
            renderData (id) {
                const listUpdate = this.customerGroupList.filter(item => item.id !== id);
                this.customerGroupList = listUpdate;
            },
            showModalCategoryEdit(id) {
                this.$refs.ModalEditCustomer.show(id);
            },
            renderListData(newCustomerGroup) {
                this.customerGroupList = this.customerGroupList.map(customerGroup => customerGroup.id === newCustomerGroup.id ? newCustomerGroup : customerGroup)
            },
            async search () {
                let query = clone(this.$route.query);
                this.params = {
                    ...this.params,
                }
                const data = await $get('/v1/customer-group', {...this.params, numberRecord: this.numberRecord, query: query});
                // this.load();
                this.customerGroupList = data.data;
                this.paginate = data.paginate;
                this.entries = data.entries;     
                this.isFilter = true;
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query.numberRecord = v.target.value;
                this.isFilter = true;
                await this.$router.push({ query: { page: 1, ...this.params }});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async clearFilter () {
                await this.$router.replace({
                    path: '/customers-group/index',
                    query: {}
                });
                this.params.keyword = '';
                this.numberRecord = 15;
                this.isFilter = false;
                this.load();
            },
            onScroll() {
            let height = window.scrollY;
            if(height > 100){
                this.fixed = true;
            }else{
                this.fixed = false;
            }
        },
        },
        computed: {
            formatCurrentCies (price) {
               return price.toLocaleString()
            }
        }
    }
</script>
<style scoped>
.custom-desc {
    margin-top: 15px !important;
}
.lstCustomer tr td , .lstCustomer tr th {
    /* min-width: 150px !important;
    min-height: 500px !important; */
    vertical-align: middle !important;
    padding: 10px;
}

.lstCustomer {
    border-collapse: collapse;
    width: 2600px !important;
    /* overflow-x: scroll; */
    max-height: 700px;
    display: block;
    margin-top: -1px;
    margin-right: 0px;
}

.lstCustomer thead th {
    border: none;  
}
.name-customer-group {
    width: 400px;
}
.note-customer-group {
    width: 500px;
}
.discount-customer-group {
    width: 300px;
}
.action {
    width: 300px;
}
tbody {
    /* display: block;
    max-height: 600px;
    overflow: auto; */
}
thead, tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}
.cell-name{
    width: 40% !important;
    min-width: 40%;
    max-width: 40%;
}
.cell-quantity{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
.cell-discount{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-auto{
    width: 20% !important;
    min-width: 20%;
    max-width: 20%;
}
.cell-check{
    width: 10% !important;
    min-width: 10%;
    max-width: 10%;
}
</style>
