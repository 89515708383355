<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalJobTitle" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 border-bottom-0">
                    <h5 class="modal-title py-2">{{ $t('message.SettingPage.transaction.modal.setting_time_return.title') }}</h5>
                    <button type="button" class="close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-0 font-size-15">
                    <template v-for="(option, index) in this.options" :key="index">
                        <div class="row mx-4 my-4 mt-1 d-flex align-items-center justify-content-between" v-if="!index">
                            {{$t('message.SettingPage.transaction.modal.setting_time_return.title_value_time_return')}}
                            <InputNumberFormat v-model="option.value" class="sale-input-number mb-0"></InputNumberFormat>
                            {{$t('message.SettingPage.transaction.modal.setting_time_return.date')}}
                            <div class="container-circle tooltip-left-center">
                                <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                <div class="content-circle font-weight-normal">
                                    {{option.description}}
                                </div>
                            </div>
                        </div>
                    </template>
                    <div class="row mx-4 my-4 mt-1">
                        {{$t('message.SettingPage.transaction.modal.setting_time_return.process_option')}}
                    </div>
                    <template v-for="(option, index) in this.options" :key="index">
                        <div class="row mx-4 my-4 mt-1" v-if="index">
                            <div class="col-12 d-flex align-items-center pl-0">
                                <label class="container-radio m-0 d-flex align-items-center min-w-100">
                                    {{option.name}}
                                    <div class="container-circle tooltip-left-center">
                                        <i class="fa fa-info-circle ml-2 text-muted icon-circle-custom"></i>
                                        <div class="content-circle font-weight-normal">
                                            {{option.description}}
                                        </div>
                                    </div>
                                    <input type="radio" name="status" v-model="option.status" :value="this.$constant.statusActive" @click="changeOption(option)" class="mr-2">
                                    <span class="checkmark"></span>
                                </label>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="modal-footer py-0 px-0">
                    <div class="row mx-4 my-4 mt-1">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-save mr-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{ $t('message.SettingPage.transaction.modal.setting_time_return.button_save') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="hide">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.SettingPage.transaction.modal.setting_time_return.button_skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>

import {$post, $alert, $get} from "@/lib/utils";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
export default {
    name: "ModalSettingTimeReturn",
    components: {InputNumberFormat},
    props: {},
    data() {
        return {
            options: [],
        }
    },
    methods: {
        async show(id) {              
            if(!id){
                return;
            }
            window.jQuery(this.$refs.modal).modal('show');
            await this.load(id);
        },
        hide() {   
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async load(id) {
            const res = await $get('/v1/setting-store/get-option-details', {settingId: id});
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            this.options = res.result ?? [];
        },
        async save() {
            const res = await $post('/v1/setting-store/update-multiple-option-details', {options: this.options});
            if (res.code === 404) {
                location.replace('/404');
                return;
            }
            if(res.result){
                $alert({code: 200, message: this.$t('message.ModalCreateRouteOfUse.alert.update_success')});
            }else{
                $alert({code: 500, message: this.$t('message.ModalCreateRouteOfUse.alert.update_error')});
            }
            this.hide();
        },
        async changeOption(option) {
            this.options.forEach(o => {
                o.status = o.id != option.id ? this.$constant.statusInActive : this.$constant.statusActive
            })
        },
    },
    mounted() {
    },
    created () {
    },
    beforeUnmount(){
        this.hide();
    }
}
</script>

<style scoped>
.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}
#modal-third {
    z-index: 1;
    opacity:0.5;
}
.toggle {
    background-color: rgba(31, 32, 33, .15);
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 14px;
    width: 51px;
    height: 28px;
    transition: all .2s ease-in-out;
}

.toggle.active {
    background-color: rgb(47, 161, 161);
}

.toggle:before {
    content: "";
    background-color: #fff;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all .2s ease-in-out;
}

.toggle.active:before {
    left: auto;
    right: 2px;
    transition: all .2s ease-in-out;
}

.icon-active {
    color: rgb(47, 161, 161);
}
.font-size-15{
    font-size: 15px !important;
}
</style>