<template>
    <div class="row">
        <ActionBar :buttons="buttons"
                   @action="save()"
                   back-url="/order-suppliers/index"
                   :hide-button-save="entry.status === statusDefined.DA_HUY"
        />
        <div class="main-title">
            <h1>Chi tiết phiếu đặt hàng nhập</h1>

        </div>
        <div class="mt-3 col-lg-12">
            <TabNavigation @tabChange="tabChange"
                           @onValidate="onValidate"
                           ref="tab" :tabs="tabs"/>
        </div>

    </div>
</template>

<script>
    import {$post, $alert, $get, scrollToElement} from "@/lib/utils";
    import ActionBar from "@/components/ActionBar";
    import TabNavigation from "../../components/TabNavigation";
    import OrderSupplierTabMain from "./tabs/OrderSupplierTabMain";
    import OrderSupplierTabPurchase from "./tabs/OrderSupplierTabPurchase";
    import {markRaw} from "vue";
    import moment from "moment";

    export default {
        name: "OrderSupplierDetail",
        components: {ActionBar, TabNavigation},
        mounted() {
            document.title = 'Chi tiết đặt hàng nhập | OmiPOS';
            this.load()
        },
        data() {
            return {
                buttons: [],
                fields: [],
                entry: {},
                statusDefined: {
                    PHIEU_TAM: 0,
                    DA_XAC_NHAN_NCC: 1,
                    NHAP_MOT_PHAN: 2,
                    HOAN_THANH: 3,
                    DA_HUY: 4
                },
                tabs: [
                    {id: 'TabMain', name: 'Thông tin phiếu đặt hàng', component: markRaw(OrderSupplierTabMain)},
                    {id: 'TabHistory', name: 'Lịch sử nhập hàng' , component: markRaw(OrderSupplierTabPurchase)},
                ],
                errors: {}
            }
        },
        methods: {
            initActionBarButtons() {
                let buttons = [];

                if (this.entry.status === this.statusDefined.HOAN_THANH) {
                    buttons = [
                        {class: 'btn btn-danger', label: 'Huỷ bỏ', action: () => {this.cancel();}},
                    ];
                } else if (this.entry.status === this.statusDefined.DA_HUY) {
                    buttons = [];
                } else {
                    buttons = [
                        {class: 'btn btn-default', label: 'Kết thúc', action: () => {
                                this.finish();
                            }},
                        {class: 'btn btn-default', label: 'Tạo phiếu nhập', href: '/purchase-orders/form?action=import&dhn_ref_code=' + this.entry.code},
                        {class: 'btn btn-default', label: 'Mở phiếu', href: '/order-suppliers/form?code=' + this.entry.code},
                        {class: 'btn btn-danger', label: 'Huỷ bỏ', action: () => {this.cancel();}},
                    ];
                }

                this.buttons = buttons;

            },
            edit() {
                location.href = '/order-suppliers/form?code=' + this.entry.code;
            },
            tabChange(tab) {
                this.currentTab = tab;
                if (this.$route.query.code) {
                    this.load();
                } else {
                    this.setTabData({
                        isDataLoaded: true
                    })
                }
            },
            setTabData(values) {
                this.$refs.tab.setTabData(values);
            },
            getTabData(key) {
                return this.$refs.tab.getTabData(key)
            },
            onValidate() {
            },

            async load() {
                const res = await $get('/v1/order-suppliers/show', this.$route.query);
                if (res.code !== 200) {
                    $alert(res);
                    return;
                }

                this.fields = res.data.fields;

                this.entry = res.data.entry;

                this.setTabData({
                    entry: res.data.entry,
                    isDataLoaded: true,
                });

                this.initActionBarButtons();
            },

            async finish() {

                if (!confirm('Bạn sẽ không thể tiếp tục xử lý phiếu đặt hàng nhập mặc dù vẫn còn hàng chưa nhập hết. Bạn có chắc chắn muốn kết thúc phiếu đặt hàng nhập không?')) {
                    return;
                }

                this.entry.status = this.statusDefined.HOAN_THANH;

                const res = await $post('/v1/order-suppliers/toggle', {
                    entry: this.entry,
                });

                if (res.redirect) {
                    $alert(res);
                    location.replace(res.redirect);
                    this.initActionBarButtons();
                } else {
                    $alert(res);
                    this.initActionBarButtons();
                }
            },

            async cancel() {

                if (!confirm('Bạn có chắc chắn hủy phiếu đặt hàng nhập mã ' + this.entry.code + ' này không?')) {
                    return;
                }

                if (this.entry.purchaseOrders.length > 0) {
                    alert('Không thể hủy vì đã có phiếu nhập gắn với phiếu đặt hàng nhập này');
                    return;
                }

                this.entry.status = this.statusDefined.DA_HUY;

                const res = await $post('/v1/order-suppliers/toggle', {
                    entry: this.entry,
                });

                if (res.redirect) {
                    $alert(res);
                    location.replace(res.redirect);
                    this.initActionBarButtons();
                } else {
                    $alert(res);
                    this.initActionBarButtons();
                }
            },
            async save() {
                this.errors = {};

                if (this.entry.expectedAt) {
                    let date1 = moment(this.entry.expectedAt).format("YYYY-MM-DD");
                    let date2 = moment(this.entry.createdAt).format("YYYY-MM-DD");
                    if (date1 < date2) {
                        alert('Ngày nhập dự kiến phải lớn hơn ngày tạo');
                        return;
                    }
                }

                const res = await $post('/v1/order-suppliers/update', {
                    entry: this.entry,
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    this.setTabData({
                        errors: res.errors
                    })
                }

                if (res.redirect) {
                    $alert(res);
                    location.replace(res.redirect);
                } else {
                    $alert(res);
                }


            },
        }
    }
</script>
