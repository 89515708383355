<template>
  <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog">
    <tr id="loadingaa">
        <div id="test" class="spinner-border text-info d-flex justify-content-center"  role="status"  :class="this.loadingaa ? 'dp-hide' : ''" v-if="loadingaa">
          <span class="sr-only">loading...</span>
        </div>
    </tr>
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 class="modal-title d-flex">
                        {{ $t('message.supplierIndex.modal.titleUpload') }} <br>
                        <span class="ml-1" style="font-weight: 400;"> 
                            (
                                {{ $t('message.common.downloadDefaultFile') }}
                                <a target="_blank" :href="'../template_supplier_' + this.language + '.xlsx'">
                                    {{ $t('message.common.excelFile') }}
                                </a>
                            )
                        </span>
                    </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>

              <div class="modal-body my-2 mx-2" @dragover.prevent @drop.prevent>
                <div class="row">
                  <div class="col-12 mx-0">
                    <!-- Xử lý trùng mã hàng, khác tên hàng -->
                    <div>
                      <label class="container-radio mt-3 ml-2">{{ $t('message.supplierIndex.modal.replaceAndUpdate') }}
                        <input  v-model="patient.optionImport" :value="1" name="rdoCodeExist" type="radio" class ="cheker" autocomplete="off" checked>
                        <span class="checkmark"></span>
                      </label>
                      <label class="container-radio ml-2">
                        {{ $t('message.supplierIndex.modal.notReplaceAndUpdate') }}
                        <!-- <input type="radio" name="rdoCodeExist" value="2" v-model="picked" ref="rdoCodeExist" /> -->
                        <input v-model="patient.optionImport" :value="2" name="rdoCodeExist" type="radio" class ="cheker">
                        <span class="checkmark"></span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="row my-2 mx-2">
                  <div class="col-12 text-right mx-0 px-0" id="divUpload">
                    <label class="custom-file-upload">
                      <input class="btn btn-primary" @change="onFileChange" type="file" ref="fileupload"  accept=".xls, .xlsx" />
                      <i class="fa fa-cloud-upload"></i> {{ $t('message.supplierIndex.modal.chooseFile') }}
                    </label>
                  </div>
                </div>
                <div class="row py-2 mx-2" id = "rowMessage" v-if="this.actionShow">
                  <div class="col-6">
                    <label>{{ this.fileName }}</label>
                  </div>
                  <div  class="col-6 text-danger text-right">
                    <a href="javascript:;" @click="showAction(false)" class="text-danger">
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                </div>
                <div class="row mt-3 mx-2" >
                  <!-- v-if="this.actionShow" -->
                  <div class="col-12 text-right px-0 mx-0" v-if="this.actionShow">
                    <input type="button" class="btn btn-primary" :value="$t('message.supplierIndex.modal.button.action')" id="btnAction" @click="UpdateFile(this.patient)"/>
                  </div>
                </div>
              </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $upload } from '@/lib/utils';
import toastr from 'toastr';
import emitter from "@/lib/emitter";
import store from "@/store";
export default {
    name: "ModalImportExcel",
    props: {},
    data() {
    return {
      actionShow: false,
      fileName: "",
      loadingaa:false,
      patient: {
        file: [],
        optionImport: "1",
        branchID: store.state.CurrentBranch.id,
      },
      language: localStorage.getItem('language') ?? 'vi'
    }
  },
  methods: {
    show() {
      this.showAction(false);
      window.jQuery(this.$refs.modal).modal('show');
    },
    showAction (status) {
      this.actionShow = status;
      if (status == false) {
        this.$refs.fileupload.value = '';
      }
    },
    onFileChange(event) {
      this.patient = {
        file: '',
        optionImport: this.patient.optionImport,
        branchID : ''
      }
      var files = event.target.files || event.dataTransfer.files;
      if (!files.length) {
        return;
      }

      let id = document.getElementById('branchDropdown');
      // this.patient.branchID = id.getAttribute('data-id'); 
      this.patient.branchID = store.state.CurrentBranch.id;
      this.fileName = files[0].name;
      this.patient.file = files[0];
      if (files[0].name == "") {
        this.actionShow = false;
      } else {
        this.actionShow = true;
      }
    },
    async UpdateFile (file) {
      this.loadingaa = true;
      const result = await $upload('/v1/suppliers/import', [], file);
      if (result.code === 422) {
        this.loadingaa = false;  
        emitter.emit("errMsgShow", true);
        emitter.emit("dataMsgShow", result);
        emitter.emit("fileName", this.fileName)
        window.jQuery(this.$refs.modal).modal("hide");
      }else {
        this.loadingaa = false;  
        emitter.emit("errMsgShow", true);
        this.$emit('input', '1');
        emitter.emit("dataMsgShow", result);
        emitter.emit("fileName", this.fileName)
        window.jQuery(this.$refs.modal).modal("hide");
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/vars.scss";
#loadingaa {
  position: fixed;
  top:25%;
  left:45%;
  z-index: 100;
}

input[type="file"] {
  display: none;
}

#btnAction{
  width: 100px;
}
#rowMessage{
  background-color: rgb(224 241 241);
  border-radius: 5px;

}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-flex;
  padding: 6px 12px;
  cursor: pointer;
  background: $base-color;
  color: #ccffff;
  margin-left: 5px;
  height: 40px;
  border-radius: 5px;
  align-items: center;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.modal-dialog {
  max-width: 800px !important;
}

.container-circle {
  position: relative;
}

.icon-circle-custom {
  cursor: pointer;
}

.content-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: 0;
  left: 45px;
  z-index: 1;
  border-radius: 5px;
}

.content-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;
}

.icon-circle-custom:hover+.content-circle {
  display: block;
}

.status-circle {
  display: none;
  position: absolute;
  font-size: 13px;
  width: 310px;
  border: 1px solid $base-color;
  padding: 5px;
  background-color: #ffffff;
  top: -5px;
  left: 257px;
  z-index: 1;
  border-radius: 5px;
  line-height: 25px;
  text-align: justify;
}

.status-circle:before {
  content: "";
  border-style: solid;
  border-width: 10px 15px 10px 0;
  border-color: transparent $base-color transparent transparent;
  position: absolute;
  left: -16px;

}

.icon-circle-custom:hover+.status-circle {
  display: block;
}
</style>