
<template>
    <AppPermission :permission="this.$permissions.transporters.data?? '' ">
       <div class="container">
           <div class="row m-0 p-0">
               <div class="mainLeft pt-3">
                   <div class="main-title">
                       <span>{{ $t('message.TransporterIndex.title') }}</span>
                   </div>
                   <div class="mt-2">
                       <h6 @click="toggleTransporterType(this.$constant.transporter_integration)">
                            <span :class="this.transporterType == this.$constant.transporter_integration ? 'border-bottom-focus' : ''">{{ $t('message.TransporterIndex.integration') }}</span>
                        </h6>
                   </div>
                   <div class="mt-2">
                       <h6 class="mb-0" @click="toggleTransporterType(this.$constant.transporter_other)">
                            <span :class="this.transporterType == this.$constant.transporter_other ? 'border-bottom-focus' : ''">{{ $t('message.TransporterIndex.other') }}</span>
                        </h6>
                   </div>
                   <div v-if="this.transporterType == this.$constant.transporter_other">
                       <div class="card-header mx-0 px-0 pt-0" id="typeHeader">
                           <div class="row">
                           <div class="col-8 text-title">
                               <label>{{ $t('message.common.numberRecord') }}:</label>
                           </div>
                           <div class="col-4">
                               <select :value="numberRecord" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                               <option value="15">15</option>
                               <option value="20">20</option>
                               <option value="30">30</option>
                               <option value="50">50</option>
                               <option value="100">100</option>
                               </select>
                           </div>
                           </div>
                       </div>
                   </div>
   
                   <div class="card" id="typeCard" v-if="this.transporterType == this.$constant.transporter_other">
                       <div class="card-header mx-0 px-0" id="typeHeader">
                           <div class="row mx-2">
                               <div class="col-9">
                                   <label class="typeTitle">{{ $t('message.TransporterIndex.transporter_group') }}</label>
                               </div>
                               <div class="col-1">
                                    <AppPermission :permission="this.$permissions.transporterGroups.create?? '' ">
                                        <a href="javascript:void(0);" class="text-dark" @click.stop="showModalAddTransporterGroup()">
                                        <i class="fa fa-plus"></i>
                                        </a>
                                    </AppPermission>
                               </div>
                           </div>
                       </div>
                       <div class="card-body my-0 pt-0 pb-3" id="">  
                           <div class="row">
                               <div class="col-12">                                
                                    <SelectSearch :placeholder="$t('message.TransporterIndex.placeholder.transporter_group')" :readonly="true" 
                                    :modelValue="this.filterQuery.transporterGroup" :defaultOption="transporterGroup" 
                                    @update:modelValue="doFilterSearch" :cusStyle="true" :label="'transporterGroup'" :isEditPermission="this.hasPermission(this.$permissions.transporterGroups.update)" @editGroups="showModalUpdateTransporterGroup"></SelectSearch>
                               </div>
                           </div>
                       </div>
                   </div>

                    <div class="card mt-4" id="typeCard" v-if="this.transporterType == this.$constant.transporter_other">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.TransporterIndex.total_delivery_fee') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('feeShippingBody', 'feeShippingDown', 'feeShippingUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="feeShippingDown"></i>
                                        <i class="fa fa-chevron-up" id="feeShippingUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-2" id="feeShippingBody">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center">
                                    <span>{{ $t('message.TransporterIndex.fee_from') }}</span>
                                    <InputNumber v-model="filterQuery.feeShippingFrom" 
                                    :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                    class="mb-0 ml-1" :cardTransaction="true" @change="load"/>
                                </div>
                            </div>
                            <div class="row mt-2 mb-1">
                                <div class="col-12 d-flex align-items-center">
                                    <span>{{ $t('message.TransporterIndex.fee_to') }}</span>
                                    <InputNumber v-model="filterQuery.feeShippingTo" 
                                    :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                    class="mb-0 ml-1" :cardTransaction="true" @change="load"/>
                                </div>
                            </div>
                            <SelectDateTime :label="'time'" @update:modelValue="doFilterTime"/>
                        </div>
                    </div>

                    <div class="card mt-4" id="typeCard" v-if="this.transporterType == this.$constant.transporter_other">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.TransporterIndex.current_debt') }}</label>
                                </div>
                                <div class="col-1">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('currentDebtBody', 'currentDebtDown', 'currentDebtUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="currentDebtDown"></i>
                                        <i class="fa fa-chevron-up" id="currentDebtUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0 pb-3" id="currentDebtBody">
                            <div class="row">
                                <div class="col-12 d-flex align-items-center">
                                    <span>{{ $t('message.TransporterIndex.fee_from') }}</span>
                                    <InputNumber v-model="filterQuery.currentDebtFrom" 
                                    :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                    class="mb-0 ml-1" :cardTransaction="true" @change="load"/>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-12 d-flex align-items-center">
                                    <span>{{ $t('message.TransporterIndex.fee_to') }}</span>
                                    <InputNumber v-model="filterQuery.currentDebtTo" 
                                    :placeholder="$t('message.TransporterIndex.placeholder.value')" 
                                    class="mb-0 ml-1" :cardTransaction="true" @change="load"/>
                                </div>
                            </div>
                        </div>
                    </div>
   
                    <div class="card mt-4" id="typeCard" v-if="this.transporterType == this.$constant.transporter_other">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-9">
                                    <label class="typeTitle">{{ $t('message.TransporterIndex.select_show') }}</label>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="sBody">
                            <div class="row">
                                <div class="col-12" v-for="item in this.status " :key="item.id">
                                    <label class="container-radio ml-0">{{$t(item.name)}}
                                        <input type="radio" name="status" @click="doFilter('status', item.id)" class="chkType" :checked="filterQuery.status === item.id ? true: false">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mainRight">
                    <div class="sticky-top pt-3">
                        <div class="row m-0">
                            <div class="col-5 pl-0">
                                <!-- Input tìm kiếm thông tin -->
                                <InputQuickSearch :placeholder="$t('message.TransporterIndex.placeholder.search')" :notIcon="true"
                                    @filterSearch="changeQuickSearch" :transporterSearch="true" v-if="this.transporterType == this.$constant.transporter_other">
                                </InputQuickSearch>
                            </div>
                            <div class="col-7 d-flex justify-content-end">
                                <!-- Xóa lọc -->
                               
                                    <button type="button" @click="clearFilter()" class="btn btn-default" v-if="this.transporterType == this.$constant.transporter_other">
                                        <i class="fa fa-filter mr-1"></i>
                                        {{ $t('message.button.removeFilter') }}
                                    </button>
                                
                                <!-- Thêm đối tác giao hàng -->
                                <AppPermission :permission="this.$permissions.transporters.create?? '' ">
                                    <button class="btn btn-save" type="button" v-if="this.transporterType == this.$constant.transporter_other" @click.stop="showModalAddTransporter">
                                        <i class="fa fa-plus mr-1"></i>
                                        {{ $t('message.TransporterIndex.button.create') }}
                                    </button>
                                </AppPermission>
                                <!-- Import -->
                                 <!-- <button class="btn btn-save" type="button" v-if="this.transporterType == this.$constant.transporter_other">
                                    <i class="fas fa-file-import mr-1"></i>
                                    {{ $t('message.TransporterIndex.button.import') }}
                                </button> -->
                                <!-- Xuất file thông tin đối tác giao hàng tích hợp -->   
                                 <AppPermission :permission="this.$permissions.transporters.export?? '' ">
                                    <button class="btn btn-save" type="button" @click="selectOptionExport('exOverview')">
                                        <i class="fa fa-file-export mr-1"></i>
                                        {{ $t('message.TransporterIndex.button.export') }}
                                    </button>
                                </AppPermission>                             
                                <div id="loc" v-on:mouseleave="onMouseLeave">
                                    <SelectField :fields="fields" label="transporter"></SelectField>
                                </div> 
                            </div>
                        </div>
                        <div>
                           <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                        </div>
                        <div class="row mt-2 m-0" v-if="this.transporterType != this.$constant.transporter_other">
                            <span class="px-2 py-1" :class="this.transporterTab == this.$constant.transporter_tab_info ? 'border-bottom-focus' : ''" 
                                @click="toggleTransporterTabs(this.$constant.transporter_tab_info)">
                                {{ $t('message.TransporterIndex.tabs.info') }}
                            </span>
                            <span class="px-2 py-1" :class="this.transporterTab == this.$constant.transporter_tab_history ? 'border-bottom-focus' : ''"
                                @click="toggleTransporterTabs(this.$constant.transporter_tab_history)">
                                {{ $t('message.TransporterIndex.tabs.history') }}
                            </span>
                            <!-- <span class="px-2 py-1" :class="this.transporterTab == this.$constant.transporter_tab_control_history ? 'border-bottom-focus' : ''">
                                {{ $t('message.TransporterIndex.tabs.control_history') }}
                            </span> -->
                        </div>
                        <div class="row mx-0">
                           <div class="col-12" v-if="this.arrayChecked?.length > 0 && entries?.length > 0">
                                {{ $t('message.common.numberRecordSelected', {number: this.arrayChecked?.length}) }}
                               <a href="javascript:;" @click="removeChecked()">
                                   <i class="fa fa-times text-danger" aria-hidden="true"></i>
                               </a>
                           </div>
                           <div class="row mx-0 my-2 w-100" id="divTable">
                               <table class="table table-index table-striped lstProduct" v-show="isShow()">
                                   <thead>
                                       <tr>
                                           <template v-for="field in fields" :key="field.field">
                                               <th scope="col" v-if="field.selected" style="vertical-align: middle !important;">
                                                   <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort" 
                                                   :name="field.name" :field="field.field" :style="field.style" class="description"/>
                                               </th>
                                           </template>
                                       </tr>
                                   </thead>
                                   <tbody>
                                       <tr>
                                           <template v-for="field in fields" :key="field.field">
                                               <td class="ow-break-word py-0 bg-light-warning" v-if="field.selected" style="vertical-align:middle !important;">  
                                                   <div v-if="field.field == 'invoiceCode'">
                                                       <InputText :placeholder="$t('message.TransporterIndex.invoice_code')" v-model.trim="filterQuery.invoiceCode" @change="load(null, 1)" :sCustomer="true" :bgTransparent="true"/>
                                                   </div>
                                                   <div v-if="field.field == 'transporterCode'">
                                                       <InputText :placeholder="$t('message.TransporterIndex.transporterCode')" v-model.trim="filterQuery.transporterCode" @change="load(null, 1)" :sCustomer="true" :bgTransparent="true"/>
                                                   </div>  
                                                   <div v-if="field.field == 'code' && this.transporterTab == this.$constant.transporter_tab_history">
                                                       <InputText :placeholder="$t('message.TransporterIndex.delivery_code')" v-model.trim="filterQuery.code" @change="load(null , 1)" :sCustomer="true" :bgTransparent="true"/>
                                                   </div>
                                                   <div v-if="field.field == 'totalOrder'" class="cell-auto py-2" v-text="n(this.totalOrder)"></div>
                                                   <div v-if="field.field == 'currentDebt'" class="cell-auto py-2" v-text="n(this.currentDebt)"></div>
                                                   <div v-if="field.field == 'totalDeliveryFee' || field.field == 'price'" class="cell-auto py-2" v-text="n(this.totalDeliveryFee)"></div>
                                                   <div v-if="field.field == 'cod'" class="cell-auto py-2" v-text="n(this.cod)"></div>
                                                   <div v-if="field.field == 'stillCod' || field.field == 'priceCodPayment'" class="cell-auto py-2" v-text="n(this.stillCod)"></div>
                                                   <div v-if="field.field == 'stillTotalDeliveryFee'" class="cell-auto py-2" v-text="n(this.stillTotalDeliveryFee)"></div>
                                                   <div v-if="field.field == 'invoiceTotal'" class="cell-auto py-2" v-text="n(this.totalInvoiceValue)"></div>
                                                   <div v-if="field.field == 'totalWeight'" class="cell-auto py-2" v-text="n(this.totalWeight)"></div>
                                                   <div v-if="field.field == 'statusName'">
                                                        <th scope="col" class="pl-2"  @click="selectStatus">
                                                            {{ this.optionChecked }}
                                                        </th>
                                                   </div> 
                                                   <div v-else class="cell-auto  text-center"></div>
                                               </td>
                                           </template>
                                       </tr>
                                        <template v-if="entries.length > 0">
                                            <template v-for="(entry, index) in entries" :key="entry.id" >
                                               <tr class="master-row" :class="entry.id === this.transporterActive ? 'backgroup__detail border__master' :''" :id="'row_' + (index + 1)">
                                                    <template v-for="field in fields" :key="field.field">
                                                       <td class="ow-break-word py-0" v-if="field.selected" style="vertical-align:middle !important;">
                                                            <a :href="'#row_' + index" class="cell-auto text-dark tagA" v-if="this.transporterType == this.$constant.transporter_other" @click="detailTransporter(entry.id, entry)">
                                                                <div v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="cell-auto description" :class="field.class"></div>
                                                            </a>
                                                            <div v-else-if="this.transporterType != this.$constant.transporter_other && field.field == 'invoiceCode'"
                                                                @click="linkToInvoice(entry)" 
                                                                v-text="renderField(entry, field.field, field)" 
                                                                :title="renderField(entry, field.field, field)" :style="field.style" class="cell-auto py-2 description" :class="field.class">
                                                            </div>
                                                            <div v-else-if="this.transporterType != this.$constant.transporter_other && field.field == 'code'"
                                                                @click="field.class ? linkToDelivery(entry) : null" 
                                                                v-text="entry.code ? renderField(entry, field.field, field) : '---'" 
                                                                :title="renderField(entry, field.field, field)" :style="field.style" class="cell-auto py-2 description" :class="field.class">
                                                            </div>
                                                            <div v-else v-text="renderField(entry, field.field, field)" :title="renderField(entry, field.field, field)" :style="field.style" class="cell-auto py-2 description" :class="field.class"></div>
                                                       </td>
                                                    </template>
                                               </tr>
                                               <tr v-if="transporterActive === entry.id" :class="[ entry.id === transporterActive ? 'backgroup__detail border__header-detail' : '', errorClass]">
                                                   <!-- <td scope="col" class="checkbox-table"></td> -->
                                                   <td :colspan="fields.length + 1" class="pb-0">
                                                       <div class="row detail-prd-row">
                                                        <div class="col-2 mt-2" @click="showContent(this.$t('message.TransporterIndex.tabs.info'))">
                                                            <span :class="[ headerActive === this.$t('message.TransporterIndex.tabs.info') ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{ $t('message.TransporterIndex.tabs.info') }}
                                                            </span>
                                                        </div>
                                                        <div class="col-2 mt-2" @click="showContent(this.$t('message.TransporterIndex.tabs.history'))">
                                                            <span :class="[ headerActive === this.$t('message.TransporterIndex.tabs.history') ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{ $t('message.TransporterIndex.tabs.history') }}
                                                            </span>
                                                        </div>
                                                        <div class="col-2 mt-2" @click="showContent(this.$t('message.TransporterIndex.tabs.return_fee'))">
                                                            <span :class="[ headerActive === this.$t('message.TransporterIndex.tabs.return_fee') ? 'backgroup__detail-active' : '', 'head-detail']">
                                                                {{ $t('message.TransporterIndex.tabs.return_fee') }}
                                                            </span>
                                                        </div>
                                                       </div>
                                                   </td>
                                               </tr>
                                               <tr class="detail-row bg-white border__body-detail" v-if="transporterActive === entry.id && headerActive === this.$t('message.TransporterIndex.tabs.info')">
                                                   <!-- <td scope="col" class="checkbox-table border-top-0"></td> -->
                                                   <td :colspan="fields.length + 1" class="border-top-0">
                                                        <div class="mt-2 pb-4">
                                                            <div class="row m-0 p-0 detail-prd-row">
                                                                <div class="col-4 px-4">
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{ $t('message.TransporterIndex.code') }}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word description" :title="entry.code" v-text="entry.code" ></div>
                                                                    </div>
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{ $t('message.TransporterIndex.name') }}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word" :title="entry.name" v-text="entry.name"></div>
                                                                    </div>
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{ $t('message.TransporterIndex.phone') }}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word" v-text="entry.contactNumber"></div>
                                                                    </div>
                                                                    <div class="row border-bottom-1 mt-2 py-2">
                                                                        <div class="col-5">
                                                                            {{ $t('message.TransporterIndex.email') }}:
                                                                        </div>
                                                                        <div class="col-7 ow-break-word" :title="entry.email" v-text="entry.email"></div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4 px-4">
                                                                    <div class="mx-1">
                                                                        <div class="col-12">
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{ $t('message.TransporterIndex.address') }}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :title="entry.address" v-text="entry.address"></div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{ $t('message.TransporterIndex.area_delivery') }}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :title="entry.areaDelivery" v-text="entry.areaDelivery"></div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{ $t('message.TransporterIndex.ward') }}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :title="entry.wardName" v-text="entry.wardName"></div>
                                                                            </div>
                                                                            <div class="row border-bottom-1 mt-2 py-2">
                                                                                <div class="col-5">
                                                                                    {{ $t('message.TransporterIndex.transporter_group_name') }}:
                                                                                </div>
                                                                                <div class="col-7 ow-break-word" :title="entry.tranGroupName" v-text="entry.tranGroupName"></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-4" id="noteRO">  
                                                                    <div class="row p-0 font-italic">
                                                                        <div class="col-12" :title="entry.note">
                                                                            <span v-text="entry.note ? entry.note : $t('message.TransporterIndex.note')" class="noteStyle px-4 text-gray font-italic"></span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                       </div>
                                                   </td>
                                               </tr>
                                               <tr class="detail-row bg-white border__body-detail" v-if="transporterActive === entry.id && headerActive === this.$t('message.TransporterIndex.tabs.info')">
                                                   <!-- <td scope="col" class="checkbox-table border-top-0"></td> -->
                                                   <td :colspan="fields.length + 1" class="border-top-0">
                                                       <div class="mt-2 pb-4">
                                                           <div class="row detail-prd-row mt-4 pr-4">
                                                                <div class="col-lg-12 flex-end">                                                                     
                                                                    <AppPermission :permission="this.$permissions.transporters.update?? '' ">
                                                                       <button type="button" class="btn btn-save mx-1" @click="updateTransporters(entry.id)">
                                                                           <i class="fa fa-check-square"></i>
                                                                           {{ $t('message.TransporterIndex.button.update') }}
                                                                       </button>
                                                                   </AppPermission>
                                                                   <AppPermission :permission="this.$permissions.transporters.stop?? '' ">                                                                      
                                                                        <button type="button" class="btn btn-danger mr-2" @click.stop="stop(entry.id, entry.status)" v-if="entry.status == this.$constant.transporters.status_active">
                                                                            <i class="fa fa-trash-alt mr-1"></i>
                                                                            {{ $t('message.TransporterIndex.button.in_active') }}
                                                                        </button>
                                                                        <button type="button" class="btn btn-primary mr-2" @click.stop="stop(entry.id, entry.status)" v-else-if="entry.status == this.$constant.transporters.status_in_active">
                                                                            <i class="fa fa-trash-alt mr-1"></i>
                                                                            {{ $t('message.TransporterIndex.button.active') }}
                                                                        </button>                                                                 
                                                                    </AppPermission>
                                                                   <AppPermission :permission="this.$permissions.transporters.remove?? '' ">
                                                                    <button type="button" class="btn btn-danger mr-2" @click="remove(entry.id)">
                                                                        <i class="fa fa-trash-alt mr-1"></i>
                                                                        {{ $t('message.TransporterIndex.button.remove') }}                                                                   
                                                                    </button>
                                                                   </AppPermission>
                                                                </div>
                                                           </div>
                                                       </div>
                                                   </td>
                                               </tr>
                                               <tr class="detail-row bg-white border__body-detail" v-if="transporterActive === entry.id && headerActive === this.$t('message.TransporterIndex.tabs.history')">
                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                        <div class="mt-2 pb-4">                                                                
                                                            <div class="detail-prd-row mt-2 pr-4">
                                                                <table class="w-100" style="max-width:100%;">
                                                                    <thead>
                                                                        <tr class="backgroup__detail table-header border-1">
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.invoice_code') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.delivery_code') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.time') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.invoice_value') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.cod') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.fee') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.status') }}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-if="this.dataDetail">
                                                                            <tr class="table-header-search border border-bottom-0 bg-light-warning">
                                                                                <th scope="col" class="pl-2 cell-check"></th>
                                                                                <th scope="col" class="pl-2"></th>
                                                                                <th scope="col" class="pl-2"></th>
                                                                                <th scope="col" class="pl-2">{{ n(this.totalInvoiceValueFromDelivery) }}</th>
                                                                                <th scope="col" class="pl-2">{{ n(this.totalPriceCodStillDelivery) }}</th>
                                                                                <th scope="col" class="pl-2">{{ n(this.totalPriceCodStillDelivery) }}</th>
                                                                                <th scope="col" class="pl-2" @click="selectStatus">
                                                                                    <div class="border-bottom-1 icon-triangle w-100 d-flex align-items-center justify-content-between">
                                                                                        {{ this.optionChecked }}
                                                                                    </div>
                                                                                </th>
                                                                            </tr>
                                                                        </template>
                                                                        <template v-if="this.dataDetail">
                                                                            <tr class="table-header border-1" v-for="(item, index) in this.dataDetail" :key="index">
                                                                                <td scope="col" class="col-1 text-primary"  @click="showDetail(item.invoice.id , 'invoice')">{{ item.invoice ? item.invoice.code : null }}</td>
                                                                                <td scope="col" class="col-2 text-primary" @click="linkToDelivery(item)">{{ item.code ? item.code : '---' }}</td>
                                                                                <td scope="col" class="col-2">{{ item.invoice ?item.invoice.purchaseDate: null }}</td>
                                                                                <td scope="col" class="col-2">{{ item.invoice ? n(item.invoice.total) : null }}</td>
                                                                                <td scope="col" class="col-2">{{ n(item.priceCodStill) ?? null }}</td>
                                                                                <td scope="col" class="col-2">{{ n(item.price) }}</td>
                                                                                <td scope="col" class="col-2">{{ item.statusName ? item.statusName : null }}</td>
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <Pagination :value="paginateDetail" :record="this.numberRecordDetail" @change="pageChangeDetail"/>
                                                            </div>
                                                        </div>
                                                        <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                            <button type="button" class="btn btn-save mx-1" @click="getHistoryDeliveries(entry.id)">
                                                                <i class="fa fa-plus"></i>
                                                                {{ $t('message.TransporterIndex.button.update_delivery') }}
                                                            </button>
                                                            <button type="button" class="btn btn-save mx-1" @click="paymentForTransporter(entry.id)">
                                                                <i class="fa fa-calculator"></i>
                                                                {{ $t('message.TransporterIndex.button.payment') }}
                                                            </button>
                                                            <button type="button" class="btn btn-secondary mx-1" @click="exTransporterById(entry.id)">
                                                                <i class="fa fa-file-export"></i>
                                                                {{ $t('message.TransporterIndex.button.export') }}
                                                            </button>
                                                        </div>
                                                    </td>
                                               </tr>
                                               <tr class="detail-row bg-white border__body-detail" v-if="transporterActive === entry.id && headerActive === this.$t('message.TransporterIndex.tabs.return_fee')">
                                                    <!-- <td class="border-top-0"></td> -->
                                                    <td :colspan="fields.length + 1" class="border-top-0">
                                                        <div class="mt-2 pb-4">                                                                
                                                            <div class="detail-prd-row mt-2">
                                                                <table class="w-100" style="max-width:100%;">
                                                                    <thead>
                                                                        <tr class="backgroup__detail table-header border-1">
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.tiket_code') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.time') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.type_fee') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.value') }}
                                                                            </th>
                                                                            <th scope="col" class="checkbox-table">
                                                                                {{ $t('message.TransporterIndex.debt') }}
                                                                            </th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        <template v-if="entry.deliveries">
                                                                            <tr class="table-header border-1" v-for="(item, index) in this.dataDetail" :key="index">
                                                                                <template  v-if="!item.deliveryId">
                                                                                    <td class="text-primary" @click="feeAdj(entry.id, item.id)">{{ item.code ? item.code : null }}</td>
                                                                                </template>
                                                                                <template  v-else-if="item.deliveryId && item.type == 2">
                                                                                    <td class="text-primary" @click="showDetail(item.fee_has_one_delivery_invoice.invoiceId, 'invoice')" >{{ item.code ? item.code : null  }}</td>
                                                                                </template>
                                                                                <template v-else>
                                                                                    <td class="text-primary">
                                                                                        <span v-for="(history, key) in item.historyPayment" :key="key" @click.stop="item.code && history.code == item.code ? (showModalDataPayment(history.id, history.type, this.$constant.typeReturn)) : null" v-text="item.code && history.code == item.code ? (item.code ? item.code : null) : null" ></span>
                                                                                    </td>
                                                                                </template>
                                                                                <td>{{ item.dateAdjustment ? dt(item.dateAdjustment) : null }}</td>
                                                                                <td>{{ item.typeName }}</td>
                                                                                <td>{{ n(item.price) }}</td>
                                                                                <td>{{ n(item.value) }}</td>     
                                                                            </tr>
                                                                        </template>
                                                                    </tbody>
                                                                </table>
                                                                <Pagination :value="paginateDetail" :record="this.numberRecordDetail" @change="pageChangeDetail"/>
                                                            </div>
                                                        </div>
                                                        <div class="row detail-prd-row flex-end mt-4 pr-5">
                                                            <button type="button" class="btn btn-save mx-1" @click="feeAdj(entry.id)">
                                                                <i class="fa fa-plus"></i>
                                                                {{ $t('message.TransporterIndex.button.feeAdjustments') }}
                                                            </button>
                                                            <button type="button" class="btn btn-secondary mx-1" @click="exFeeAdjustments(entry.id)">
                                                                <i class="fa fa-file-export"></i>
                                                               {{ $t('message.TransporterIndex.button.export') }}
                                                            </button>
                                                        </div>
                                                    </td>
                                               </tr>
                                               <tr class="detail-row bg-white border__footer-detail" v-if="transporterActive === entry.id">
                                               </tr>
                                            </template>
                                        </template>
                                        <template v-if="entries.length == 0 && !this.loading">
                                            <tr>
                                                <td :colspan="fields.length + 1" class="pb-0 bg-light">
                                                    <div class="row detail-prd-row mx-0">
                                                        <div class="col-12 text-center text-secondary mt-5">
                                                           <h3>
                                                               <i class="fa fa-file mr-2 text-secondary"></i>
                                                               <i class="fa fa-exclamation"></i>
                                                           </h3>
                                                        </div>
                                                        <div class="col-12 text-center my-10 text-secondary mb-5">
                                                           <p>{{ !this.isFilter ? $t('message.common.notData') :  $t('message.common.notFound')}}</p>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </template>
                                   </tbody>
                               </table>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
       <ModalTransporterDelete ref="ModalTransporterDelete" @loadData ="load"/>
       <ModalTransporterStop ref="ModalTransporterStop" @loadData ="load"/>
       <ModalAddTransporterGroups ref="ModalAddTransporterGroups" @loadData ="load"/>
       <ModalAddTransporters ref="ModalAddTransporters" @loadData ="load"/>
       <SelectStatusDeliveries ref="SelectStatusDeliveries" :defaultOptions="this.listStatusDelivery" @update:modelValue="filterHistoryDelivery"/>
       <ModalUpdateHistoryDelivery ref="ModalUpdateHistoryDelivery" :defaultOptions="this.listStatusDelivery" @updated="reloadDataDetail"/>
       <ModalFeeAdjustment ref="ModalFeeAdjustment" @updated="reloadDataDetail"/>
       <ModalSelectInvoicePayment ref="ModalSelectInvoicePayment" :defaultOptions="this.listStatusDelivery" @payment="payment"/>
       <ModalPaymentTransporter ref="ModalPaymentTransporter"/>
       <DetailStockCard ref="DetailStockCard" />
       <ModalDataPayment ref="ModalDataPayment" @updated="this.load()" />
       <ModalSelectOptionExport ref="ModalSelectOptionExport" @export="exportFiles"/>
   </AppPermission>
</template>
<script>
    import {$alert, $get, clone, setArray, getSearchQuery, $post} from "@/lib/utils";
    import Pagination from "@/components/Pagination";
    import TableSortField from "@/components/TableSortField";
    import SelectField from "../../components/SelectField";
    import store from "@/store";
    import emitter from "@/lib/emitter";
    import InputQuickSearch from "@/components/forms/InputQuickSearch.vue";
    import TabPermission from "@/components/TabPermission.vue";
    import InputDatePicker from "@/components/forms/InputDatePicker";
    import SelectSearch from "@/components/SelectSearch.vue";
    import AppPermission from '@/components/AppPermission.vue';
    import SelectDateTime from "@/components/SelectDateTime.vue";
    import InputNumber from "@/components/forms/InputNumberCustom";    
    import ModalTransporterDelete from './tabs/ModalTransporterDelete.vue';    
    import ModalTransporterStop from './tabs/ModalTransporterStop.vue';    
    import ModalAddTransporterGroups from './tabs/ModalAddTransporterGroups.vue';      
    import ModalAddTransporters from './tabs/ModalAddTransporters.vue';
    import permissionMixin from "@/permissionMixin";
    import SelectStatusDeliveries from "@/components/SelectStatusDeliveries.vue";
    import ModalUpdateHistoryDelivery from "./tabs/ModalUpdateHistoryDelivery.vue";
    import ModalSelectInvoicePayment from "./tabs/ModalSelectInvoicePayment.vue";
    import ModalPaymentTransporter from "./tabs/ModalPaymentTransporter.vue";
    import exportMixin from "@/exportMixin";  
    import http from '@/services/api';
    import ModalFeeAdjustment from "./tabs/ModalFeeAdjustment.vue";
    import InputText from "@/components/forms/InputText";
    import DetailStockCard from '../products/DetailStockCard';
    import ModalDataPayment from '@/components/ModalDataPayment.vue';
    import ModalSelectOptionExport from '@/components/ModalSelectOptionExport.vue';
    export default {
        name: "TransporterIndex",
        mixins: [permissionMixin, exportMixin],
        components: {
            TableSortField, 
            Pagination,
            SelectStatusDeliveries,
            InputQuickSearch, 
            InputDatePicker,
            DetailStockCard,
            TabPermission,
            AppPermission,
            SelectSearch,
            SelectDateTime,
            InputNumber,
            SelectField,
            ModalTransporterDelete,
            ModalTransporterStop,
            ModalAddTransporterGroups,
            ModalAddTransporters,
            ModalUpdateHistoryDelivery,
            ModalFeeAdjustment,
            InputText,
            ModalSelectInvoicePayment,
            ModalPaymentTransporter,
            ModalDataPayment,
            ModalSelectOptionExport
        },
        data() {
            const query = getSearchQuery();
            return {
                filter: {
                    transporter_code: '',
                },
                checkedAll: false,
                fields: [],
                entries: [],
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                paginate: {
                    currentPage: parseInt(query.page) || 1,
                    lastPage: 1
                },
                numberRecord: 15,
                filterQuery: {
                    status: '',
                    createdAt: '',
                    updatedAt: '',
                    keyword: '',
                    transporterGroup: '',
                    feeShippingFrom: '',
                    feeShippingTo: '',
                    currentDebtTo: '',
                    currentDebtFrom: '',
                    invoiceCode: '',
                    transporterCode: '',
                    code: ''
                },
                arrayChecked: [],
                loading: false,
                transporterActive: null,
                noteEdit: null,
                isFilter: false,
                status: [
                    { "id": 1, "name": "message.common.active" },
                    { "id": 0, "name": "message.common.inActive" },
                    { "id": 2, "name": "message.common.all" }
                ],
                user: store.state.Auth.user,
                headerActive: this.$t('message.TransporterIndex.tabs.info'),
                transporterType: this.$constant.transporter_integration,
                transporterTab: this.$constant.transporter_tab_info,
                totalOrder: 0,
                currentDebt: 0,
                totalDeliveryFee: 0,
                cod: 0,
                stillCod: 0,
                stillTotalDeliveryFee: 0,
                transporterGroup: null,
                totalWeight: 0,
                listStatusDelivey: [],
                allStatusDelivery: [],
                optionChecked: null,
                ids: [],
                dataDetail: [],
                paginateDetail: {
                    currentPage: 1,
                    lastPage: 1
                },
                numberRecordDetail: 10,
                totalPriceDelivery: 0,
                totalPriceCodStillDelivery: 0,
                totalInvoiceValueFromDelivery: 0,
            }
        },
        mounted() {
            document.title = this.$t('message.TransporterIndex.title_document');
            const fields = localStorage.getItem('transporterSelectedFields_' + this.user.id);            
            if (fields) {
                this.fields = JSON.parse(fields);
            }
            const query = this.$route.query;
            if(query['code'] != null && query['id'] != null){
                this.filterQuery.transporter_code = query['code'];
                this.transporterActive = query['id'];
                this.clearFilter(true);
            }else{
                this.clearFilter();
            }
        },
        methods: {
            showModalDataPayment(id, method, input){
             this.$refs.ModalDataPayment.show(id, method, input);
            },
            async clearFilter(search = false) {
                await this.$router.replace({
                    path: '/transporters/index',
                    query: {}
                });
                Object.keys(this.filter).forEach(key => {
                    this.filter[key] = '';
                });
                Object.keys(this.filterQuery).forEach(key => {
                    if(!search){
                        if(key == 'status'){
                            this.filterQuery[key] = this.$constant.statusActive;
                        }else{
                            this.filterQuery[key] = '';
                        }
                    }else{
                        if(key == 'status'){
                            this.filterQuery[key] = this.$constant.statusTransporterAll;
                        }
                    }
                });
                await emitter.emit("clearQuickSearch");
                await emitter.emit("clearSelectSearchValue");
                await emitter.emit("clearSelectDateTime");
                this.arrayChecked = [];
                this.numberRecord = 15;
                this.filterQuery.branchId = store.state.CurrentBranch.id;
                this.transporterActive = !search ? null : this.transporterActive;
                this.isFilter = false;
                this.ids = [];
                if(search){
                    this.transporterType = this.transporterActive > this.$constant.max_transporter_integration_id ? this.$constant.transporter_other : this.$constant.transporter_integration;
                }
                await this.load();
                if(search){
                    this.entries.forEach((entry) => {
                        this.detailTransporter(entry.id, entry);
                    });
                }
            },
            async doFilter(field, value) {
                const query = clone(this.filterQuery);
                query[field] = value;
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            handleCheckTransporterGroups(id = null){
            let arr = this.filterQuery.transporterGroup;
            arr = (arr.includes(id) || !id) ? [] : [id]
            this.filterQuery.transporterGroup = arr
            this.handleFilter('supplierGroups',arr);
          },
            doFilterSearch(v){
                const query = clone(this.filterQuery);                
                delete query[v.label];
                if(v.model){
                    query[v.label] = v.model;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                this.load();
            },
            async doFilterTime(v){
                const query = clone(this.filterQuery);
                delete query[v.label];
                if(v.value){
                    query[v.label] = v.value;
                }
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            async changeQuickSearch(object){
                const query = clone(this.filterQuery);
                Object.keys(this.filter).forEach(key => {
                    object.forEach(obj => {
                        Object.keys(obj).forEach(o => {
                            if(key == o && obj[o] != ''){
                                this.filter[key] = obj[o];
                                query[o] = obj[o];
                            }else if(key == o && obj[o] == ''){
                                delete query[o];
                            }
                        });
                    })
                });
                query['page'] = 1;
                this.isFilter = true;
                this.filterQuery = {...query};
                this.load();
            },
            async pageChange(page) {
                const query = clone(this.filterQuery);
                query['page'] = page;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            pageChangeDetail(page){
                if(this.headerActive === this.$t('message.TransporterIndex.tabs.history') && this.transporterActive){
                    this.loadDataHistoryDeliveries(page);
                }else if(this.headerActive === this.$t('message.TransporterIndex.tabs.return_fee') && this.transporterActive){
                    this.loadDataFeeAdjustment(page);
                }
            },
            async getTransporterGroup(){
                const resG = await $get('/v1/transporters-groups/data-group-for-transporter'); // chuyển router data --> data-group-for-transporter do phân quyền nhóm đối tác giao hàng
                if(resG && resG.status.code == 200 && resG.result.status) {
                    this.transporterGroup = resG.result.data;
                }
            },
            async load(data = null, page = null) {
                if (data) {
                    this.optionChecked = data.data && data.data.length > 1 ? this.$constant.multiple_option : (data.data[0].name ?? this.$constant.all_option);
                    this.ids = data.arrayChecked;
                }
                if (page) {
                    this.filterQuery['page'] = page;
                }
                // Lấy danh sách nhóm đối tác giao hàng
                await this.getTransporterGroup();
                this.loading = true;
                emitter.emit("onLoading");
                const res = await $post('/v1/transporters/data', 
                {   ...this.$route.query, 
                    ...this.filterQuery,
                    record: this.numberRecord, 
                    transporterType: this.transporterType,
                    transporterTab: this.transporterTab, 
                    ids: this.ids
                });              
                if (res.result.code !== 200 && res.status) {
                    $alert(res.status);
                    return;
                }       
                if (res.result.data.fields) {
                    this.fields = res.result.data.fields;
                }
                this.entries = res.result.data.data.data;       
                this.paginate.totalRecord = res.result.data.data.total;
                this.paginate.currentPage = res.result.data.data.current_page;
                this.paginate.lastPage = res.result.data.data.last_page;
                // if (res.result.data.listStatus) {
                //     this.status = res.result.data.listStatus;
                // }
                if (res.result.data.listStatusDelivery) {
                    this.listStatusDelivery = res.result.data.listStatusDelivery;
                }
                if (res.result.data.allStatusDelivery) {
                    this.allStatusDelivery = res.result.data.allStatusDelivery;
                }
                this.totalOrder = res.result.data.totalOrder ? res.result.data.totalOrder : 0;
                this.currentDebt = res.result.data.currentDebt ? res.result.data.currentDebt : 0;
                this.totalDeliveryFee = res.result.data.totalDeliveryFee ? res.result.data.totalDeliveryFee : 0;
                this.cod = res.result.data.cod ? res.result.data.cod : 0;
                this.stillCod = res.result.data.stillCod ? res.result.data.stillCod : 0;
                this.stillTotalDeliveryFee = res.result.data.stillTotalDeliveryFee ? res.result.data.stillTotalDeliveryFee : 0;
                this.totalInvoiceValue = res.result.data.totalInvoiceValue ? res.result.data.totalInvoiceValue : 0;
                this.totalWeight = res.result.data.totalWeight ? res.result.data.totalWeight : 0;
                this.isCheckedAll();
                emitter.emit("offLoading");
                this.loading = false;
            },
            async loadDataFeeAdjustment(page = 1) {
                const res = await $post('/v1/transporters/fee-adjustment-for-transporter/', {id: this.transporterActive, record: this.numberRecordDetail, page: page});
                if(res.result){
                    // this.entry.fee_adjustment =  res.result.data;
                    this.dataDetail = res.result.data;
                    this.paginateDetail.totalRecord = res.result.total;
                    this.paginateDetail.currentPage = res.result.current_page;
                    this.paginateDetail.lastPage = res.result.last_page;
                }
            },
            async loadDataHistoryDeliveries(page = 1) {
                const res = await $post('/v1/transporters/history-deliveries/' + this.transporterActive, {record: this.numberRecordDetail, page: page, show: true, status: this.ids});
                if(res.result){
                    this.dataDetail = res.result.data.data;
                    this.paginateDetail.totalRecord = res.result.data.total;
                    this.paginateDetail.currentPage = res.result.data.current_page;
                    this.paginateDetail.lastPage = res.result.data.last_page;
                    this.totalPriceDelivery = res.result.totalPrice;
                    this.totalPriceCodStillDelivery = res.result.totalPriceCodStill;
                    this.totalInvoiceValueFromDelivery = res.result.totalInvoiceValue;
                }
            },
            filterHistoryDelivery(params){
                if (params) {
                    this.optionChecked = params.data && params.data.length > 1 ? this.$constant.multiple_option : (params.data && params.data.length && params.data[0].name ? params.data[0].name : this.$constant.all_option);
                    this.ids = params.arrayChecked ?? [];
                    this.loadDataHistoryDeliveries();
                }
            },
            async onChange(v) {
                this.numberRecord = v.target.value
                let query = clone(this.filterQuery);
                query['page'] = 1;
                this.filterQuery = {...query};
                this.isFilter = true;
                await this.load();
            },
            onMouseLeave(){
                emitter.emit("mouseleave");
            },
            showHide(name,iDown, iUp){
                var dropdownContent = document.getElementById(name);
                dropdownContent.classList.toggle("dp-hide");
                var down = document.getElementById(iDown);
                down.classList.toggle("dp-hide");
                var up = document.getElementById(iUp);
                up.classList.toggle("dp-hide");
            },
            showCombo(entry){
                if(!entry.showCombo){
                    entry.showCombo = true;
                }else{
                    entry.showCombo = !entry.showCombo;
                }
            },
            isExistInArrayChecked(entry, array = []){
                if(array.includes(entry.id)){
                    entry.checked = true;
                    return true;
                }else{
                    entry.checked = false;
                    return false;
                }
            },
            countChecked(id){
                let arr = this.arrayChecked;
                if(arr.includes(id)){
                    arr = arr.filter(item => item !== id)
                }else{
                    arr.push(id)
                }
                this.arrayChecked = arr;
                this.isCheckedAll();
            },
            isCheckedAll(){
                let getChecked = 0;
                this.entries.forEach((entry) => {
                    const found = this.arrayChecked.find(id => id === entry.id);
                    if(found){
                        getChecked += 1;
                        entry.checked = true;
                    }
                });

                if(getChecked > 0 && getChecked == this.entries.length){
                    this.checkedAll = true;
                }else{
                    this.checkedAll = false;
                }
            },
            removeChecked(){
                this.arrayChecked = [];
                this.isCheckedAll();
            },
            onCheckedAll(value) {
                setArray(this.entries, {
                    checked: value
                });
                if(value == true){
                    this.entries.forEach(entry => {
                        const found = this.arrayChecked.find(id => id === entry.id);
                        if(!found){
                            this.countChecked(entry.id);
                        }
                    })
                }else{
                    this.entries.forEach(entry => {
                        this.countChecked(entry.id);
                    })
                }
            },

            showContent(name) {
                this.headerActive = name;
                this.ids = [];
                this.optionChecked = this.$constant.all_option;
                this.reloadDataDetail();
            },
            reloadDataDetail(){
                if(this.headerActive === this.$t('message.TransporterIndex.tabs.history') && this.transporterActive){
                    this.loadDataHistoryDeliveries();
                }else if(this.headerActive === this.$t('message.TransporterIndex.tabs.return_fee') && this.transporterActive){
                    this.loadDataFeeAdjustment();
                }
            },
            toggleTransporterType(type){
                this.transporterType = type;
                this.transporterTab = this.$constant.transporter_tab_info;
                this.clearFilter();
            },

            toggleTransporterTabs(tab){
                this.transporterTab = tab;
                this.clearFilter();
            },
            detailTransporter(id, entry){
                if (id === this.transporterActive) {
                    this.transporterActive = null;
                } else {
                    this.transporterActive = id;
                }
                if(entry.note){
                    this.noteEdit = entry.note;
                }
                this.headerActive = this.$t('message.TransporterIndex.tabs.info');
                this.totalPriceDelivery = 0;
                this.totalPriceCodStillDelivery = 0;
                this.totalInvoiceValueFromDelivery = 0;
                this.dataDetail = [];
                this.optionChecked = this.$constant.all_option;
            },
            isShow(){
                let isShow = false;
                this.fields.forEach(field => {
                    if(field.selected){
                        isShow = true;
                    }
                });
                return isShow;
            },
            updatePermission(data){
                this.entries.forEach(entry => {
                    if(entry.id == data.label){
                        entry.permissionIds = data.values;
                    }
                })
            },           
            remove(id){
                this.$refs.ModalTransporterDelete.show(id);
            },
            stop(id, action){
                this.$refs.ModalTransporterStop.show(id, action);
            },

            // Thêm nhóm đối tác giao hàng         
            async showModalAddTransporterGroup(){                
                 this.$refs.ModalAddTransporterGroups.show();
            },
            // Cập nhật nhóm đối tác giao hàng
            async showModalUpdateTransporterGroup(id){                
                 this.$refs.ModalAddTransporterGroups.show(id);
            },
            async showModalAddTransporter(){                
                 this.$refs.ModalAddTransporters.show();
            },
            updateTransporters(id){
               this.$refs.ModalAddTransporters.show(id);
            },
            selectStatus (){
                this.$refs.SelectStatusDeliveries.show((this.ids ?? []), false, this.listStatusDelivery);
            },
            async getHistoryDeliveries(id){
                this.$refs.ModalUpdateHistoryDelivery.show(id, this.allStatusDelivery);
            },
            paymentForTransporter(id){
                this.$refs.ModalSelectInvoicePayment.show(id);
            },
            payment(ids){
                this.$refs.ModalPaymentTransporter.show(ids);
            },
            // Xuất file lịch sử giao hàng
            async exTransporterById(id){    
                emitter.emit("onLoading");            
                const res = await http.download('/v1/transporters/export-by-id', {id: id });
                this.returnDataExport(res);    
            },
            async exFeeAdjustments(id){         
                emitter.emit("onLoading");       
                const res = await http.download('/v1/transporters/export-fee-adjustments', {id: id });
                this.returnDataExport(res);    
            },
            selectOptionExport(method = 'exOverview', totalRecord = 0){
                let params = { method: method };
                totalRecord = totalRecord ? totalRecord : this.paginate.totalRecord;
                this.$refs.ModalSelectOptionExport.show(params, totalRecord, []);
            },
            async exportFiles(params){
                if (params.method) {
                    this[params.method](params.firstRecordIndex);
                }
            },
            async exOverview(firstRecordIndex = 0){
                emitter.emit("onLoading");                
                const res = await http.download('/v1/transporters/export-overview', {
                    type: this.transporterTab, 
                    transporterType: this.transporterType,
                    firstRecordIndex: firstRecordIndex
                });
                this.returnDataExport(res);    
            },
            feeAdj (id, feeId){
                this.$refs.ModalFeeAdjustment.show(id , feeId);
            },
            linkToInvoice(entry){
                var route = null;
                route = this.$router.resolve({
                    name: 'InvoiceIndex',
                    query: {
                        'code': entry.invoiceCode,
                    }
                });
                window.open(route.href, '_blank');
            },
            linkToDelivery (entry) {
                var route = null;
                route = this.$router.resolve({
                    name: 'DeliveryIndex',
                    query: {
                        'code': entry.code,
                        'id': entry.id,
                    }
                });
                window.open(route.href, '_blank');
            },
            showDetail(id, stockCardType){ 
                this.$refs.DetailStockCard.show(id, stockCardType);
            }
        }
    }
</script>
<style scoped>
    #number_records {
        margin-top: 11px;
    }
    .container input[type="radio"] {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }
    .text-title {
        line-height: 52px;
    }
    
    #typeCard {
        margin-top: -8px;
    }
    #noteRO {
        border-left: 1px solid #ccc;
    }
    #fa_edit {
        position: absolute;
        left:20px;
        top:4px;
        color:#a7a2a2;
    }
    .noteStyle {
        width: 100% !important;
        height: 80px !important;
        border: 0;
        border-radius: 0;
    }
    
    .noteStyle:focus {
        outline: none !important;
        border-color: none;
        box-shadow: 0 none;
    }
    .table-header-search{
    height: 50px !important;
    background-color: #FEFCED;
}
    
    /* tbody {
        display: block;
        max-height: 600px;
        overflow: auto;
    }
    thead, tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    } */
    .icon-triangle::after{
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: 0.255em;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
    }
   </style>