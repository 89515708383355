
<template>
    <AppPermission :permission="this.$permissions.supplierGroups.data">
        <div class="container my-4">
            <div class="row m-0 p-0">
            <div class="mainLeft">
            <!-- <div class="col-md-2" id="col2"> -->
                <div class="main-title">
                <span>{{$t('message.SupplierGroupIndex.title')}}</span>
                </div>
                <div>
                <div class="card-header mx-0 px-0" id="typeHeader">
                    <div class="row mx-2">
                    <div class="col-8 text-title">
                        <label>{{$t('message.common.numberRecord')}}:</label>
                    </div>
                    <div class="col-4">
                        <select :value="numberRecord" style="border: none;outline: none;" name="number_records" id="number_records" class="number_records" @change="onChange($event)">
                        <option value="15">15</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                        </select>
                    </div>
                    </div>
                </div>
                </div>
            </div>

            <div class="mainRight">
            <!-- <div class="col-md-10" id="col10"> -->
                <div class="sticky-top">
                    <div class="row">
                        <div class="col-5">
                        <div class="search-box">
                            <i class="fa fa-search mr-2"></i>
                            <input type="text" class="search-input"
                            @keydown.enter="doFilter('keyword', params.keyword)" v-model="params.keyword"
                            :placeholder="$t('message.SupplierGroupIndex.search')" />
                        </div>
                        </div>                        
                        <div class="col-7 d-flex justify-content-end">
                            <button type="button" class="btn btn-default" @click="clearFilter">
                                <i class="fa fa-filter mr-1"></i>
                                {{ $t('message.button.removeFilter') }} 
                            </button>
                            <AppPermission :permission="this.$permissions.supplierGroups.create">
                                <button class="btn  btn-save ml-1" @click="showModal">
                                    <i class="fa fa-plus"/>
                                    {{ $t('message.SupplierGroupIndex.add') }} 
                                </button>
                            </AppPermission>
                        </div>
                    </div>
                    <div>
                        <Paginate :value="paginate" :record="this.numberRecord" @change="pageChange"/>
                    </div>
                    <div class="row mx-0 my-2" v-if="SuppliersGroupList.length > 0" id="divTable">
                        <table class="table flex-table table-striped" >
                        <thead>
                            <tr>
                                <th scope="col" v-for="field in fields" :key="field.field" :style="field.style" class="pl-5">
                                    <TableSortField :current="sortData.field" :no-sort="!field.isColumn" @onsort="sort" :name="$t(field.name)" :field="field.field"/>
                                </th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="item in SuppliersGroupList" :key="item.id" @click="showModalEditSupplierGroup(item.id)">
                                <template v-for="field in fields" :key="field.field">
                                    <td class="ow-break-word pl-5" style="vertical-align:middle !important;">
                                        <div v-if="field.field =='note'" v-text="item.note" :style="field.style" class="description"></div>
                                        <div v-else-if="field.field =='name'" v-text="item.name" :style="field.style" class="description"></div>
                                        <div v-else v-text="renderField(item, field.field, field)" :title="renderField(item, field.field, field)" :style="field.style">
                                        </div>
                                    </td>
                                </template>
                                <th class="d-flex justify-content-end">
                                     <AppPermission :permission="this.$permissions.supplierGroups.update">
                                        <button class="btn">
                                            <i class="fa fa-edit" />
                                        </button>
                                    </AppPermission>
                                     <AppPermission :permission="this.$permissions.supplierGroups.remove">
                                        <button class="btn text-danger" @click.stop="removeSuppliersGroup(item.id, item.name)">
                                            <i class="fa fa-trash-alt" />
                                        </button>
                                    </AppPermission>
                                </th>
                            </tr>
                        </tbody>
                        </table>
                    </div>
                    <div class="row ml-auto mr-auto" id="notFound" v-else>
                        <div class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                            <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                            <p v-if="params.keyword">{{$t('message.common.notFound')}}</p>
                            <p v-else-if="SuppliersGroupList.length == 0">{{$t('message.common.notData')}}</p>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    </AppPermission>
    <ModalAddSuppliersGroup ref="ModalAddSuppliersGroup" @inputData="load"/>
    <ModalRemoveSuppliersGroup ref="ModalRemoveSuppliersGroup" @updated="renderData" @input="load" />
    <ModalEditSuppliersGroup :customerGroupDetail="ModalEditSuppliersGroup" ref="ModalEditSuppliersGroup" @input="load" />
</template>

<script>
    import {$alert, $get, clone, setArray} from "@/lib/utils";
    import Paginate from "@/components/Paginate";
    import TableSortField from "@/components/TableSortField";
    import ModalAddSuppliersGroup from '@/components/supplierl/ModalAddSuppliersGroup';
    import ModalEditSuppliersGroup from '@/components/supplierl/ModalEditSuppliersGroup';
    import ModalRemoveSuppliersGroup from "@/components/supplierl/ModalRemoveSuppliersGroup";
    import emitter from "@/lib/emitter";    
    import AppPermission from '@/components/AppPermission';
    export default {
        name: "SuppliersGroupIndex",
        components: {
          ModalRemoveSuppliersGroup,
          TableSortField, Paginate, ModalAddSuppliersGroup, ModalEditSuppliersGroup, AppPermission
          },
        mounted() {
            document.title = this.$t('message.SupplierGroupIndex.documentTitle');
            const query = this.$route.query;
            this.filter = {...this.filter, ...query};
            this.paginate.currentPage = this.$route.query.page || 1;
            this.numberRecord = this.$route.query.numberRecord || "15";
            this.load();
        },
        data() {
            return {
                fields: [
                    { "field": "name", "name": "message.SupplierGroupIndex.name", "class": "text-center", "languages": true },
                    { "field": "totalSupplier", "name": "message.SupplierGroupIndex.quantity", "class": "text-center", "languages": true },
                    { "field": "note", "name": "message.SupplierGroupIndex.note", "class": "text-center", "languages": true }
                ],
                SuppliersGroupList: [],
                customerGroupDetail: {},
                params: {
                    keyword: '',
                    status: ''
                },
                paginate: {
                    currentPage: 1,
                    lastPage: 1
                },
                sortData: {
                    field: 'id',
                    direction: 'desc',
                },
                numberRecord: 15,
            }
        },
        methods: {
           async load () {
            emitter.emit("onLoading");
            const SuppliersGroup = await $get('/v1/supplier-groups/data', {...this.$route.query, numberRecord: this.numberRecord});
            this.SuppliersGroupList = SuppliersGroup.data.entries;
            this.paginate = SuppliersGroup.data.paginate;
            // this.fields = SuppliersGroup.data.fields;
            emitter.emit("offLoading");
           },
           async pageChange(page) {
                let query = clone(this.$route.query);
                query.page = page;
                await this.$router.push({query: query});
                this.load();
            },
            showModal() {
                this.$refs.ModalAddSuppliersGroup.show();
            },
            renderListDataCreate(newData) {
                this.SuppliersGroupList.unshift(newData.data);
            },
            removeSuppliersGroup(id, name) {
                this.$refs.ModalRemoveSuppliersGroup.show(id, name);
            },
            renderData (id) {
                const listUpdate = this.SuppliersGroupList.filter(item => item.id !== id);
                this.SuppliersGroupList = listUpdate;
            },
          showModalEditSupplierGroup(id) {
                this.$refs.ModalEditSuppliersGroup.show(id);
            },
            renderListData(newSuppliersGroup) {
                this.SuppliersGroupList = this.SuppliersGroupList.map(SuppliersGroup => SuppliersGroup.id === newSuppliersGroup.id ? newSuppliersGroup : SuppliersGroup)
            },
            async doFilter(field, value) {
                const query = clone(this.$route.query);
                query[field] = value;
                await this.$router.push({query: {...query,page:'1'}});
                const filterQuery = clone(this.filterQuery);
                this.load(filterQuery);
            },
            async onChange(v){
                this.numberRecord = v.target.value
                let query = clone(this.$route.query);
                query['page']=1;
                query.numberRecord = v.target.value;
                await this.$router.push({query: query});
                this.load();
            },
            async sort(field, direction) {
                this.sortData = {
                    field, direction
                };

                const query = clone(this.$route.query);
                query.sort_field = field;
                query.sort_direction = direction;

                await this.$router.push({query: query});
                this.load();
            },
            async clearFilter() {
                await this.$router.replace({
                    path: '/suppliers-group/index',
                    query: {}
                });
                this.params.keyword = '';
                Object.keys(this.sortData).forEach(key => {
                    this.sortData[key] = null;
                });
                this.numberRecord = 15;
                this.load();
                // location.reload()
            },
        }
    }
</script>
<style scoped>
#number_records {
  margin-top: 11px;
}
</style>
