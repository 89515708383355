<template>
    <div class="search-box position-relative" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray"></i>
        <input type="text" class="search-input" :placeholder="this.placeholder"
               v-model.trim="keyword" @input="onSearchInvoice"/>
        <div class="popup-product-search" v-if="keyword">
            <ul class="wrap-products" v-if="tickets.length">
                <li v-for="ticket in tickets" :key="ticket.code" @click="onSelect(ticket)">
                    <div class="product-info">
                        <p>
                            <span class="code-item" v-text="ticket.code"></span>
                        </p>
                    </div>
                </li>
            </ul>
            <div v-else class="products-not-found">
                <span>
                    {{$t('message.common.notFound')}}
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import {$alert, $get, debounce, $post, TYPE_IMPORT, TYPE_EXPORT, TYPE_TRANSFER,} from "@/lib/utils";
    import store from "@/store";
    let app;

    export default {
        name: "TicketSearch",
        emits: ['select'],
        props: ['placeholder', 'type'],
        data() {
            app = this;
            return {
                tickets: [],
                keyword: '',
                TYPE_IMPORT, TYPE_EXPORT, TYPE_TRANSFER
            }
        },
        methods: {
            onSearchInvoice: debounce(async () => {
                app.tickets.length = 0;

                if (!app.keyword) {
                    return true;
                }
                let res = null;
                if(app.type === app.TYPE_IMPORT){
                    res = await $post('/v1/ticket-import/get-lists', {code: app.keyword, record: 15, page: 1, branchId: store.state.CurrentBranch.id});
                }
                if(app.type === app.TYPE_EXPORT){
                    res = await $post('/v1/ticket-export/get-lists', {code: app.keyword, record: 15, page: 1, branchId: store.state.CurrentBranch.id});
                }
                if(res){
                    if (res.result.code !== 200) {
                        $alert(res);
                        return;
                    }
                    app.tickets = res.result.data.entries;
                }
            }),
            onSelect(ticket) {
                this.$emit('select', ticket.code);
                this.hidePopup();
            },
            hidePopup() {
                this.keyword = '';
                this.tickets.length = 0;
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .popup-product-search {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        min-width: 450px;
        z-index: 10;
        padding-bottom: 20px;
        /*padding-bottom: 50px;*/

        max-height: calc(100vh - 230px);
        font-size: 14px;
        background: #fff;

        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);

        ul.wrap-invoices {
            padding: 10px 0;
            overflow: auto;
            max-height: calc(100vh - 284px);
            list-style: none;
            margin-bottom: 0;

            li {
                cursor: pointer;
                padding: 10px 20px;
                margin: 0;

                .product-img {
                    width: 55px;
                    height: 55px;
                    text-align: center;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    float: left;
                    margin-left: -5px;
                    margin-top: 0;

                    img {
                        max-width: 100%;
                        max-height: 100%;
                        vertical-align: middle;
                    }
                }

                .product-info {
                    margin-left: 60px;
                    line-height: 18px;

                    p {
                        margin-bottom: 2px;
                    }

                    .name-item {
                        font-weight: bold;
                        word-break: break-word;
                    }

                    .code-item, .price-item {
                        margin-right: 20px;
                        margin-left: 0;
                    }
                }
            }

            li:hover {
                background-color: $base-color;
                color: #fff;
            }

            &::-webkit-scrollbar {
                width: 8px;
                height: 8px;
            }

            &::-webkit-scrollbar-thumb {
                width: 8px;
                border-radius: 4px;
                background-color: #c1c1c1;
                border: 1px solid #fff;
            }

            &::-webkit-scrollbar-track {
                padding: 6px;
            }
        }

        .invoices-not-found {
            padding: 25px 10px;
            color: #999999;
            text-align: center;
        }

        .add-new-product {
            line-height: 50px;
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #ebebeb;
            cursor: pointer;

            &:hover {
                background-color: $base-color;
                color: #fff;
            }
        }
    }
</style>
