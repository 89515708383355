<template>
    <div v-click-outside="hideDropDown" :class="[(disabled ? 'cursor-ban' : ''), (border ? 'border rounded p-1' : ''), (this.locationStyle == true ? 'prd-search-box2' : 'prd-search-box1'), classCustom, 'position-relative', (!this.heightNormal ? 'h-100' : ''), ('dropdown-search-' + this.label)]">
        <form class="w-100" data-toggle="dropdown" aria-expanded="false" v-if="!disabled" @submit.prevent="null" :class="this.editTemplate ? 'd-flex align-items-center': '' ">
            <span v-if="readonly" v-text="options.search" class="text-overflow-ellipsis"></span>
            <input v-else type="text" :placeholder="placeholder" :title="title ? options.search : null" 
            v-model="options.search" @input="onInputFilter" @click="showDropDown"
            :readonly="readonly" class="bg-transparent text-wrap input-select-search pl-2 p-0 text-overflow-ellipsis" :class="[(borderActive ? 'border-bottom-focus-active' : ''), (plNone ? 'pl-0' : ''), (locationStyle ? 'pl-2' : '')]">
        </form>
        <form class="w-100" @submit.prevent="null" v-else>
            <span v-if="readonly" v-text="options.search" class="text-overflow-ellipsis"></span>
            <input v-else type="text" :placeholder="placeholder" :title="title ? options.search : null" 
            v-model="options.search" @input="onInputFilter" @click="showDropDown" 
            :readonly="readonly" :disabled="disabled" class="bg-transparent text-wrap input-select-search p-0 text-overflow-ellipsis" :class="[(borderActive ? 'border-bottom-focus-active' : ''), (plNone ? 'pl-0' : ''), (locationStyle ? 'pl-2' : '')]">
        </form>
        <i v-if="this.locationStyle == true" class="fa fa-sort-down" aria-hidden="true" style="position:absolute; right:10px; top:8px"></i> 
        <button v-if="!this.action" type="button" class="border-none bg-transparent btn-icon" v-show="options.search && !disabled && !readonly" @click.stop="clearValue()"
        :class="this.locationStyle ? 'prd-btn-icon' : '' ">
            <i  class="fa fa-times text-danger pr-3" ></i>              
        </button>
        <button v-if="this.readonly && !this.disabled" type="button" class="border-none bg-transparent btn-icon icon-arrow" :class="active ? 'active' : ''">            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362" ><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg>
        </button>
        <!-- Cái này sytle cho form thêm mới hàng hóa -->
         <div v-if="this.locationStyle == true" class="dropdown-menu w-100 locationStyle"  :id="!this.disabled ? 'dropdownContent' + this.label : ''">            
            <template v-if="this.options.filter?.length > 0">
                <a class="dropdown-item text-wrap py-2" href="javascript:;" v-for="o in this.options.filter" :key="o.id" :value="o.id" @click="chooseOption(o)" :class="this.model === o.id ? 'text-bold' :''">                    
                    <!-- {{o.name ? o.name : (o.label ? o.label : null)}} -->
                    {{ getNameByLocale(o) }}
                    <i class="fa fa-check px-4" v-if="this.model == o.id"></i>          
                </a>
                
            </template>
            <template v-else>
                <a class="dropdown-item" href="javascript:;">
                    {{$t('message.common.notFound')}}
                </a>
            </template>
        </div>
        <!-- Cái này sytle cho các phần khác -->
        <div v-else class="dropdown-menu w-100" :class="[(this.actionStyle == true ? 'dropdown-menu-content-plans' : 'dropdown-menu-content'), ('dropdown-content-search-' + this.label)]" :id="!this.disabled ? 'dropdownContent' + this.label : ''">            
            <div class="py-1 px-2 position-relative" v-if="this.areaSearch && this.readonly">
                <input type="text" class="border-bottom-1 border-bottom-focus-active pr-4" v-model="keyword" @input="doFilter">
                <i class="fa fa-search text-gray"></i>
            </div>
            <div class="dropdown-item-content position-relative">
                <template v-if="this.options.filter?.length > 0">
                    <template  v-for="o in this.options.filter" :key="o.id">
                         <a class="dropdown-item text-wrap py-1 nameGroups" href="javascript:;" 
                            :value="o.id" @click="chooseOption(o)"
                            :class="this.model === o.id ? 'text-bold' :''">                                                  
                            <!-- {{o.name ? o.name : (o.label ? o.label : null)}} -->
                            {{ getNameByLocale(o) }}
                            <i class="fa fa-check px-3" v-if="this.model == o.id"></i>                             
                            <i  class="fa fa-pencil-alt text-secondary pl-3" aria-hidden="true" 
                                @click.stop="editGroups(o.id)" :class="cusStyle ? 'cusStyle d-none' : 'd-none' "
                                v-if="this.isEditPermission"
                            >
                            </i>  
                        </a>
                    </template>                     
                </template>
                <template v-else>
                    <a class="dropdown-item" href="javascript:;">
                        {{$t('message.common.notFound')}}
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import {removeVietnameseTones, $post} from "@/lib/utils";
import emitter from "@/lib/emitter";
import mixin from "@/mixin";
export default {
    name: "SelectSearch",
    props:  ['placeholder', 'modelValue', 'defaultOption', 'label', 'disabled', 'action', 'readonly', 'actionStyle', 
            'locationStyle', 'border', 'title', 'cusStyle', 'borderActive', 'isEditPermission', 'vModel', 'classCustom',
            'areaSearch', 'plNone', 'searchData', 'nameSearch', 'editTemplate', 'heightNormal', 'onSearch'
            ],
    emits: ['onSearch', 'editGroups', 'update:modelValue', 'getDataCustomer', 'setNewData', 'loadPaymentMethod', 'loadCustomerId', 'loadCusGroupId', 'loadPrintTemplateId'],
    mixins: [mixin],
    methods: {
        editGroups(id){
            this.$emit('editGroups', id);
        },
        hideDropDown(){
            this.active = false;
        },
        showDropDown(){     
            this.active = true;  
            if(this.areaSearch){
                this.keyword = '';
                this.doFilter();
            }      
        },
        clearValue(){
            if(this.onSearch){
                this.options.filter = this.options.all = [];
            }            
            if (!this.disabled) {
                this.model = null;
                this.options.search = null;
                this.options.filter = this.options.all;
                this.optionsName = null;
                if(!this.vModel){
                    this.$emit('update:modelValue', {model: this.model, label: this.label ?? null});
                }else{
                    this.$emit('update:modelValue', this.model);
                }
                this.$emit('getDataCustomer', null);
            }
            if(this.searchData){
                this.searchDataOptions();
            }
            this.hideDropDown();
        },  
        chooseOption(o){      
            this.model = o.id ?? null;
            this.options.search = this.getNameByLocale(o);
            // this.options.search = o.name ? o.name : (o.label ? o.label : null);
            this.optionsName = this.options.search;
            // this.optionsName = o.name;
            this.$emit('loadPrintTemplateId', o.id, o.name);
            this.$emit('loadCusGroupId', o.id);
            this.$emit('loadCustomerId', o.id);
            this.$emit('loadPaymentMethod', o.id);
            if(!this.vModel){
                this.$emit('update:modelValue', {model: this.model, label: this.label ?? null, optionsName: this.optionsName ?? null});
            }else{
                this.$emit('update:modelValue', this.model);
            }
            this.$emit('getDataCustomer', o);
            this.$emit('setNewData', o);
            this.hideDropDown();
        },
        setValue(){            
            if (this.options.filter) {
                for (let index = 0; index < this.options.filter.length; index++) {
                    const element = this.options.filter[index];
                    if(element.id == this.model){
                        // this.options.search = element.name ? element.name : (element.label ? element.label : null);
                        this.options.search = this.getNameByLocale(element);
                        return;
                    }
                }
                this.options.search = null;
            }
        },
        onInputFilter(){            
            this.showDropDown();
            if(this.searchData && this.searchData == 'supplier'){
                this.loadDataSuppliers();
            }else if(this.onSearch){
                this.$emit('onSearch', this.options.search);
                return;
            }else{
                if(!this.options.search){
                    this.options.filter = this.options.all;
                    this.clearValue();
                    return;
                }
                let arr = [];
                for (let index = 0; index < this.options.all.length; index++) {
                    const paramSearch = this.getNameByLocale(this.options.all[index]);
                    // if (this.options.all[index]?.name && removeVietnameseTones(this.options.all[index]?.name.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    //     arr.push(this.options.all[index]);
                    // }else if(this.options.all[index]?.label && removeVietnameseTones(this.options.all[index]?.label.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    //     arr.push(this.options.all[index]);
                    // }

                    if (paramSearch && removeVietnameseTones(paramSearch.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                        arr.push(this.options.all[index]);
                    }
                }
                this.options.filter = arr;
            }
        },
        doFilter(){
            if(!this.keyword){
                this.options.filter = this.options.all;
                return;
            }
            let arr = [];
            for (let index = 0; index < this.options.all.length; index++) {
                if (this.options.all[index]?.name && removeVietnameseTones(this.options.all[index]?.name.toLowerCase()).indexOf(removeVietnameseTones(this.keyword.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }else if(this.options.all[index]?.label && removeVietnameseTones(this.options.all[index]?.label.toLowerCase()).indexOf(removeVietnameseTones(this.keyword.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }
            }
            this.options.filter = arr;
        },
        async searchDataOptions(){
            if(this.searchData == 'supplier'){
                await this.loadDataSuppliers();
            }
            this.setValue();
        },
        async loadDataSuppliers(){
            const res = await $post('/v1/plans/get-list-supplier', {keyword: this.options.search});
            if(res.status.code == 200 && res.result){
                this.options.filter = this.options.all = res.result;
            }
        },
        adjustDropdownPosition() {
            const dropdown = document.querySelector(".dropdown-search-" + this.label);
            if(dropdown){
                const dropdownContent = dropdown.querySelector(".dropdown-content-search-" + this.label);
                if(dropdownContent){
                    const contentHeight = dropdownContent.offsetHeight;
                    const dropdownBottom = dropdown.getBoundingClientRect().bottom;
                    let top = 0;
                    if (dropdownBottom + contentHeight > window.innerHeight && contentHeight < 280) {
                        top = - (contentHeight + 30);
                    }
                    dropdownContent.style.top = `${top}px`;
                }
            }

        }
    },
    watch: {
        modelValue: {                
            handler(newModelValue){                          
                this.model = newModelValue;
                this.setValue();
                
            },        
            deep: true,
            
        },
        defaultOption: {                
            handler(newDefaultOption){                        
                this.options.filter = this.options.all = newDefaultOption;
                if(!this.onSearch){
                    this.setValue();
                }                
            },
            deep: true
        },    
        nameSearch: {
            handler(newNameSearch){                        
                this.options.search = newNameSearch;                
                this.searchDataOptions();
            },
            deep: true
        },
        'options.search': {
            handler(){                        
                this.adjustDropdownPosition();                
            },
            deep: true
        }    
    },
    data() {
        return {
            model: this.modelValue ?? null,
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
                search: this.nameSearch ? this.nameSearch : '',
            },
            active: false,
            keyword: '',
        }
    },
    created(){
        emitter.on("clearSelectSearchValue", () => {
            this.options.search = '';
            this.model = null;
            if(this.onSearch){
                this.options.filter = this.options.all = [];
            }
        });
        if(this.searchData){
            this.searchDataOptions();
        }
    },
    updated() {
        this.adjustDropdownPosition();
    },
    mounted(){
        this.setValue(); 
        window.addEventListener("resize", this.adjustDropdownPosition);
        window.addEventListener("scroll", this.adjustDropdownPosition);
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.adjustDropdownPosition);
        window.removeEventListener('scroll', this.adjustDropdownPosition);
    },
}
</script>

<style scoped>
.dropdown-menu-content{
    width: 100vh;
    max-height: 20rem;
    /* max-width: 12rem; */
    /* overflow: auto; */
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
    transform: translate3d(0px, 24px, 0px) !important;
}
.dropdown-item-content{
    overflow: auto;
    max-height: 15rem;
}
.dropdown-menu-content-plans{
    margin-top: 6px ;
    width: 100vh;
    max-height: 20rem;
    max-width: 94.5%;
    overflow: auto;
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
}
.locationStyle{
    margin-top: 6px ;
    width: 95vh;
    max-height: 20rem;
    max-width: 94%;
    overflow: auto;
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
input[type = 'text']{
    padding-left: 10px;
    padding-top: 2px;
}
.disabled{
    background-color: #e9ecef;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0rem;
}
.icon-arrow{
    background-color: none;
}
.icon-arrow svg{
    fill: #212529;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.active > svg{
    transform: rotate(180deg);
}
.icon-arrow svg{
    width: 9px;
    height: 9px;
    fill: #666
}
.input-select-search{
    cursor: pointer;
}
.cursor-ban .input-select-search{
    cursor: not-allowed !important;
    pointer-events: all !important;
}
.prd-search-box1:active, .prd-search-box1:hover{
    border-bottom: 1px solid #ccc !important;
}
.prd-search-box2:active, .prd-search-box2:hover{
    border-bottom: 0px solid #ccc !important;
}
.prd-btn-icon{
    top: 7px !important;
    position: absolute !important;
    right: 20px !important;
}
.nameGroups:hover .cusStyle{
    display: inline-block !important;
}

.fa-search{
    position: absolute;
    right: 5%;
    bottom: 10px;
}
</style>
