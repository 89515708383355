import {$post} from "./lib/utils";
export default {
    methods: {
        async getListAttributeForUnit(product){
            const result = await $post('/v1/products/get-list-attributes-for-unit', {
                productUnitId: product.productUnitId,
            });            
            product.variants = [];
            product.variantId = null;
            if(result && result.result){                
                product.attributesActive = result.result;
            }
            else {
                 product.attributesActive = product.attributes;
            }
        },   

        //old
        getAttributes(attributes, productUnitId){
            let lstAttributes = [];
            attributes.forEach(item => {
                if(item.mainId == productUnitId){
                    lstAttributes.push({
                        id: item.id,
                        name: item.name,
                        mainId: item.mainId
                    })
                }
                
            });                
            return lstAttributes;
        },
    }

}
