<template>
    <div class="search-box position-relative" :class="normal ? 'border-bottom-1 rounded-0 p-0 h-100' : ''" v-click-outside="hidePopup">
        <i class="fas fa-search mr-2" style="color: gray" v-show="!normal"></i>
        <div class="form-control multiselect p-0 border-none  bg-transparent pb-0" :class="readonly ? 'cursor-ban' : ''" v-if="normal" data-toggle="dropdown" aria-expanded="false" aria-haspopup="true">
            <template v-if="listItemSelected?.length > 0">
                <ul class="p-0 m-0">
                    <template v-for="item in listItemSelected" :key="item.id">
                        <li class="item-select p-2 mb-1 d-flex flex-row align-items-center">
                            <span v-text="item.name"></span>
                            <span @click="removeItem(item.id)" v-if="!readonly">
                                <i class="fa fa-times mr-2 text-gray"></i>
                            </span>
                            <span v-else>
                                <i class="fa fa-times mr-2 text-gray"></i>
                            </span>
                        </li>
                    </template>
                </ul>
            </template>
            <input type="text" class="search-input bg-transparent h-input-search" :placeholder="placeholder ? placeholder : $t('message.common.selectVouchers')"
               v-model.trim="keyword" @input="onSearchVoucher()" :readonly="readonly" v-if="normal" v-on:keyup.enter="onSearchVoucher(true)"/>
        </div>
        <div class="dropdown-menu dropdown-menu-content" :class="keyword ? 'd-block' : 'd-none'">      
            <template v-if="vouchers.length">
                <template v-for="voucher in vouchers" :key="voucher.id">
                    <div class="dropdown-item d-flex" href="javascript:;" @click="onSelect(voucher)">
                        <div class="product-info">
                            <p class="name-item text-wrap">
                                <span class="text-bold" v-text="voucher?.name"></span>
                            </p>
                            <p>
                                <span class="price-label">{{ $t('message.LocationIndex.code') }}: {{ voucher?.code }}</span>
                                <span class="price-label ml-2">{{ $t('message.VoucherIndex.modal.create_list_voucher.number_voucher') }}: {{ n(voucher?.details_count) }}</span>
                            </p>
                        </div>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="dropdown-item">
                    {{ $t('message.common.notFound') }}
                </div>
            </template>
        </div>
    </div>
</template>

<script>
    import {$alert, $get, debounce} from "@/lib/utils";
    import store from "@/store";
    let app;

    export default {
        name: "VoucherSelectSearch",
        components: {},
        props: ['label', 'branch', 'normal', 'placeholder', 'defaultValue', 'readonly'],
        data() {
            app = this;
            return {
                vouchers: [],
                keyword: '',
                branchId: this.branch ?? store.state.CurrentBranch.id,
                listItemSelected: this.defaultValue ?? [],
            }
        },
        watch: {
            defaultValue: {                
                handler(newData){                          
                    this.listItemSelected = newData ?? [];
                },        
                deep: true,
            },
        },
        methods: {
            async onSearchVoucher(isMultiple) {
                if (this.keyword && this.keyword.length >= 3) {
                    const res = await $get('/v1/vouchers/get-list-vouchers', {
                        keyword: this.keyword,
                        branchId: this.branchId,
                    });
                    if (isMultiple) {
                        this.selectMultipleVoucher(res.result);
                        this.hidePopup();
                    } else {
                        this.vouchers = res.result;   
                    }
                }else{
                    this.vouchers = [];
                }
            },
            onSelect(voucher) {
                this.selectVoucher(voucher);
                this.hidePopup();
            },
            selectMultipleVoucher(listVouchers) {
                for (let index = 0; index < listVouchers.length; index++) {
                    const voucher = listVouchers[index];
                    this.selectVoucher(voucher);
                }
            },
            selectVoucher(voucher) {
                if(this.normal){
                    if(this.listItemSelected?.length == 0){
                        this.listItemSelected.push(voucher);
                    }else{
                        let apply = true;
                        this.listItemSelected.forEach(item => {
                            if(item.id == voucher.id){
                                apply = false;
                            }
                        });
                        if(apply){
                            this.listItemSelected.push(voucher);
                        }
                    }
                    this.$emit('selectVoucher', {'data': this.listItemSelected, 'label': this.label});
                }else{
                    this.$emit('selectVoucher', {'data': voucher, 'label': this.label});
                }
            },
            removeItem(id){
                this.listItemSelected.splice(this.listItemSelected.findIndex(v => v.id == id), 1);
                this.$emit('selectVoucher', {'data': this.listItemSelected, 'label': this.label});
            },
            hidePopup() {
                this.keyword = '';
                this.vouchers.length = 0;
            },
        }
    }
</script>

<style scoped lang="scss">
@import "../assets/css/vars.scss";
    .multiselect{
        height: auto;
        max-height: 84px;
        overflow: auto;

        ul{
            display: flex;
            flex-wrap: wrap;

            li{
                margin-right: 10px
            }
        }
    }
    .h-input-search{
        height: 35px !important;
    }
    .item-select{
        background: #e5e5e5 !important;
        min-height: 30px !important;
        width:fit-content !important;
    }
    .dropdown-menu-content{
        max-height: 300px !important;
        max-width: 500px !important;
        transform: translateY(-100%) !important;
        overflow-y: auto;
        cursor: pointer;
        background: #fff;
        z-index: 1002;
        padding-bottom: 20px;
        border-radius: 5px;
        box-shadow: 0 5px 15px rgb(0 0 0 / 35%);
    }
</style>
