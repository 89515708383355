<template>
    <div class="row">
        <div class="col-lg-12 mt-4 mb-4">
            <table class="table table-index table-striped text-center">
                <thead>
                <tr>
                    <th>{{$t('message.CashBookIndex.modal.showDetail.code_receipt')}}</th>
                    <th>{{$t('message.common.time')}}</th>
                    <th>{{$t('message.common.created_by')}}</th>
                    <th>{{$t('message.InvoiceIndex.tabs.table.history_payment.value')}}</th>
                    <th>Phương thúc</th>
                    <th>{{$t('message.common.status')}}</th>
                    <th>Tiền thu/chi</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in invoiceCashFlows" :key="item.id">
                    <td v-text="item.code"></td>
                    <td v-text="d(item.transDate)"></td>
                    <td v-text="item.user ? item.user.name : ''"></td>
                    <td v-text="n(item.amount)"></td>
                    <td v-text="item.method"></td>
                    <td v-text="item.statusValue"></td>
                    <td v-text="n(item.amount)"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TabPaymentHistory",
        data() {
            return {
                invoiceCashFlows: []
            }
        }
    }
</script>

<style scoped>

</style>
