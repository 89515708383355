<template>
    <div class="customer-tab tab-info">
        <ActionBar back-url="/customers/index"/>

        <div class="col-12">
            <table class="table table-index table-striped" style="width: 100%">
                <thead>
                <tr>
                    <th scope="col">{{$t('message.common.STT')}}</th>
                    <th scope="col">Tên người nhận</th>
                    <th scope="col">SĐT nhận hàng</th>
                    <th scope="col">Điạ chỉ nhận</th>
                    <th scope="col">Ngày tạo</th>
                    <th scope="col">Hành động</th>
                    <th scope="col" style="text-align: center">Tùy chọn địa chỉ mặc định</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(address, index) in addresses" :key="'address-' + address.id">
                    <td v-text="index+1"></td>
                    <td v-text="address.name"></td>
                    <td v-text="address.phone"></td>
                    <td v-text="address.addressValue"></td>
                    <td v-text="d(address.createdAt)"></td>
                    <td>
                        <create-address :customerId="id" :id="address.id"></create-address>
                    </td>

                    <td style="text-align: center">
                        <InputSwitchButton v-model="address.is_default" @change="onChangeDefault(index)"></InputSwitchButton>
                    </td>
                </tr>
                </tbody>
            </table>
            <div class="row">
                <create-address :customerId="id"></create-address>
            </div>
        </div>


    </div>
</template>

<script>
import CreateAddress from "@/views/customers/CreateAddress";
import {$alert, $get, $post, scrollToElement} from "@/lib/utils";
import InputSwitchButton from "@/components/forms/InputSwitchButton";
import ActionBar from "@/components/ActionBar";
export default {
    name: "TabAddress",
    props: ['id'],
    components: {
        InputSwitchButton, CreateAddress, ActionBar
    },
    data() {
        return {
            addresses: [],
        }
    },
    mounted() {
        if (this.id) {
            this.loadAddress();
        }

    },
    methods: {
        async loadAddress() {
            const res = await $get('/v1/customers/getAddress', {
                id: this.id
            });

            if (res.code === 404) {

                location.replace('/404');
                return;
            }

            this.addresses = res.data.addresses;
        },
        async onChangeDefault(index) {

            const res = await $post('/v1/customers/saveAddress', {
                customer_id: this.id, address: this.addresses[index]
            });

            if (res.code === -1) {
                this.errors = res.errors;
                return;
            }

            if (res.redirect) {
                location.replace(res.redirect);
            }

            this.addresses = res.addresses;
            $alert(res);

        }
    }

}
</script>

<style scoped>

</style>