<template>
    <div class="customer-tab tab-info">
        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/customers/index" class="btn btn-default">
                        {{$t('message.common.back')}}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="title">
            Thông tin lịch sử mua hàng
        </div>
        <table class="table table-index table-striped">
            <thead>
            <tr>
                <th scope="col">{{$t('message.InputQuickSearch.title.code_invoice')}}</th>
                <th scope="col">{{$t('message.headers.orders')}}</th>
                <th scope="col">{{$t('message.common.time')}}</th>
                <th scope="col">{{$t('message.customerIndex.sellerName')}}</th>
                <th scope="col">Tổng thanh toán</th>
                <th scope="col">{{$t('message.common.status')}}</th>
                <th scope="col">Thời gian xử lý dự kiến</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(order, index) in orders" :key="'row-' + index">
                <td>
                    <router-link class="btn " :to="'/invoices/form?id=' + order.invoice.id" v-if="order.invoice">
                        <span  v-text="order.invoice.code"></span>
                    </router-link>
                </td>
                <td>
                    <router-link class="btn " :to="'/orders/form?id=' + order.id">
                        <span v-text="order.code"></span>
                    </router-link>
                </td>
                <td v-text="d(order.createdAt)"> </td>
                <td v-text="order.soldByName"></td>
                <td v-text="n(order.total)"></td>
                <td v-text="order.statusValue"></td>
                <td></td>
            </tr>
            </tbody>
        </table>
        <div class="row">
            <button class="btn btn-primary ml-auto mr-3">Xuất file</button>
        </div>

    </div>
    <Paginate :value="paginate" @change="pageChange"/>
</template>

<script>
import {$alert, $get, setArray} from "@/lib/utils";
import Paginate from "@/components/Paginate";

export default {
    name: "TabOrderHistory",
    props: ['id'],
    components: {
        Paginate
    },
    data() {
        return {
            orders: [],
            paginate: {
                currentPage: 1,
                lastPage: 1
            }
        }
    },
    mounted() {
        if (this.id) {
            this.load();
        }
    },
    methods: {
        async load() {
            const res = await $get('/v1/customers/dataOrder', this.$route.query);
            if (res.code !== 200) {
                $alert(res);
                return;
            }

            setArray(res.data.entries, {
                checked: false
            });

            this.fields = res.data.fields;
            this.paginate = res.data.paginate;
            this.orders = res.data.entries;

        },
        async pageChange(page) {
            await this.$router.push({query: {page: page}});
            await this.load();
        },

    }
}
</script>

<style scoped>

</style>