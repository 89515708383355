<template>
 <div ref="modal" class="modal fade mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2 border-bottom-0">
                    <h5 class="modal-title py-2 text-danger">
                        {{$t('message.DetailOrdersCard.promotion')}}
                    </h5>
                    <button type="button" class="close"  @click="submit('close')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <p>{{$t('message.common.appliedPromotionOfCustomer')}}:</p>
                    <p>- {{ content }}</p>
                    <p>{{$t('message.common.confirmApplyContinue')}}</p>
                    <div class="col-12 mt-4 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="submit('confirm')">
                            <i class="fa fa-check mr-1"></i>
                            {{$t('message.button.confirm')}}
                        </button>
                        <button class="btn btn-secondary text-white" @click="submit('cancel')">
                            <i class="fa fa-ban mr-1"></i>
                            {{$t('message.button.skip')}}
                        </button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: "ModalPromotionWarning",
    
    data(){
        return {
            tab: '',
            element: '',
            content: '',
        }
    },
    props: [],
    methods: {
        show(data) {   
            this.content = data;      
            window.jQuery(this.$refs.modal).modal('show');
        },
        submit(stt) {
            this.$emit('outputData', stt);
            window.jQuery(this.$refs.modal).modal('hide');
        },
    },
}
</script>
