<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalChecked" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document" style="width: 1280px;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">{{typeEditAdd === "EDIT" ? $t('message.ProductIndex.modal.title_update_multiple') : entry.id ? $t('message.ProductIndex.modal.product_manage') + entry.name : $t('message.ProductIndex.modal.title_create')}}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="clearInput">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 mx-4 my-4 px-0">
                    <div class="row">                        
                        <div class="col-lg-12">                        
                             <TabNavigation @tabChange="tabChange" @draftTabData="draftTabData" ref="tab" :tabs="this.type == 2 ? tabProductServices : (this.type == 1 ? tabs : (this.type == 3 ? tabProductCombos : tabs))"/>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                     <AppPermission :permission="this.$permissions.products.update">
                        <button class="btn btn-primary" :disabled="isLoading" back-url="/products/index" v-if="currentTab.showActionBar" @reset="load" @click="save()" :showResetButton="true">
                            <i class="fa fa-save"></i>
                            {{$t('message.ProductIndex.modal.button.save')}}
                        </button>
                    </AppPermission>
                    <a aria-current="page" href="javascript:;" data-dismiss="modal" aria-label="Close" @click="clearInput" class="router-link-active router-link-exact-active btn btn-secondary text-white">
                        <i class="fa fa-ban mr-1"></i>
                        {{$t('message.ProductIndex.modal.button.skip')}}
                    </a>
                </div>
            </div>
        </div>
    </div>
    <ModalUnitAdd ref="ModalUnitAdd" :quickAdd="true"></ModalUnitAdd>
    <ModalCategoryAdd ref="ModalCategoryAdd" :typeEditAdd="'ADD'"/>
    <ModalCreateRouteOfUse ref="ModalCreateRouteOfUse"/>
    <ModalCreateAttribute @loadData="loadDataAttribute" ref="modalCreateAttribute"></ModalCreateAttribute>
    <ModalConfirm ref="ModalConfirm" :title="this.title" :content="$t('message.ProductIndex.modal.confirm_remove_attribute')"/>
</template>
<style>
#modalChecked{
    overflow-x: hidden;
    overflow-y: auto;
}
#modalTableChecked thead th {
    border-bottom: 0 !important;
}
@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}

</style>
<script>
import ActionBar from "../../../components/ActionBar";
import TabNavigation from "../../../components/TabNavigation"
import ProductFormTabMain from "../tabs/ProductFormTabMain";
import ProductFormTabThreshold from "../tabs/ProductFormTabThreshold";
import ProductFormTabProductCombo from "../tabs/ProductFormTabProductCombo";
import ProductFormTabInventory from "../tabs/ProductFormTabInventory";
import ProductFormTabBatches from "../tabs/ProductFormTabBatches";
import ModalCreateRouteOfUse from "./ModalCreateRouteOfUse";
import ModalUnitAdd from "../../units/tabs/ModalUnitAdd";
import ModalCategoryAdd from "../../categories/tabs/ModalCategoryAdd";
import { markRaw } from "vue";
import { $alert, $get, $post, scrollToErrors, setArray, toastCutomBottom } from "../../../lib/utils";
import store from "../../../store";
import { trim } from 'jquery';
import emitter from "@/lib/emitter";
import ModalCreateAttribute from "@/components/ModalCreateAttribute.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import { mapGetters, mapActions } from 'vuex';

// import { valueToNode } from "@babel/types";
export default {
    name: "ModalProductsAdd",
    components: { TabNavigation, ActionBar, ModalUnitAdd, ModalCategoryAdd, ModalCreateRouteOfUse, ModalCreateAttribute, ModalConfirm},
    props: {
        typeEditAdd: String,
        idProduct: Number,
    },
    data() {
        const unitNameMap = {};
        store.state.GlobalConfig.units.forEach(a => {
            unitNameMap[a.id] = a.name;
        });

        return {
            disabled:false,
            idArr:[],
            unitNameMap: markRaw(unitNameMap),
            isDataLoaded: false,
            currentTab: {},
            entry: {
                categoryId: []
            },
            type: '',
            errors: {},
            tabs: [
                { id: 'TabMain', showActionBar: true, name: this.$t('message.ProductIndex.info'), component: markRaw(ProductFormTabMain) },
                // { id: 'TabThreshold', showActionBar: true, name: 'Định mức tồn', component: markRaw(ProductFormTabThreshold) },
                // { id: 'TabInventory', showActionBar: false,name: 'Tồn kho' , component: markRaw(ProductFormTabInventory)},
                // { id: 'TabBatches', showActionBar: false, name: 'Lô-Date' , component: markRaw(ProductFormTabBatches)},           
            ],
            tabProductCombos: [
                {id: 'TabMain',showActionBar: true, name: this.$t('message.ProductIndex.info'), component: markRaw(ProductFormTabMain)},
                {id: 'TabCombo', showActionBar: true, name: this.$t('message.ProductIndex.component_product') , component: markRaw(ProductFormTabProductCombo)},
            ],
            tabProductServices: [
                {id: 'TabMain',showActionBar: true, name: this.$t('message.ProductIndex.info'), component: markRaw(ProductFormTabMain)},
            ],
            isLoading: false,
            TabMain: {},
            TabCombo: {},
        }
    },
    computed: {
        ...mapGetters({
            showModalConfirnRemoveAtrribute: 'productStore/showModalConfirnRemoveAtrribute',
        }),
    },
    methods: {
        showModalCreateAttribute(index) {
            this.$refs.modalCreateAttribute.show(index);
        },
        clearInput(){
            this.isLoading = false;
            const body = document.querySelector("body"); 
            body.style.overflow = "auto"; 
        },
        loadDataAttribute(data) {
            emitter.emit('loadDataAttribute', data);
        },
        show() {     
            this.$refs.tab.setFirstTab();
            window.jQuery(this.$refs.modal).modal('show'); 
            const body = document.querySelector("body"); 
            body.style.overflow = "hidden";   
            emitter.emit('clearMainAll')
            emitter.emit('clearCombo')
            emitter.emit('clearThreshold')
            emitter.emit('clearIdProduct')     
            emitter.emit('clearOptionsQselect')     
            emitter.emit('clearValueTags')  
            emitter.emit('quickUpdateUnit');
            emitter.emit('getListTreeCategory');   
            this.type = '';
            this.onDisabled(true);
        },
        hide(){
            this.clearInput();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        onDisabled(status){
            return status;
        },
        tabChange(tab) {
            this.currentTab = tab;
            if (this.$route.query.id) {
                this.load();
            } else {
                this.setTabData({
                    isDataLoaded: true
                })
            }
        },
        draftTabData(data){
            this[data.id] = data.data;
        },
        setTabData(values) {
            this.$refs.tab.setTabData(values);
        },
        getTabData(key) {
            return this.$refs.tab.getTabData(key)
        },
        onValidate() {
            const productUnits = this.$refs.tab.getTabData('productUnits');
            this.validateProductUnits(productUnits);
        },
        //----------------------------------------------------------------
        // Validate mã hàng
        isEmptyQSelect(name, strInogre) {
            let result = false;
            name.forEach(value => {
                if (value.innerText !== "" && strInogre !== value.innerText) result = false;
                else result = true
            });
            return result;
        },
        isEmptyInput(name, strInogre) {
            let result = false;
            name.forEach(value => {
                if (value.children[0].value !== "" && strInogre !== value.children[0].value) result = false;
                else result = true
            });
            return result;
        },
        //----------------------------------------------------------------
        validateProductUnits(productUnits) {
            if (this.typeEditAdd != 'ADD') return true;
            let lengthDel = 0;
            productUnits.forEach(a => {
                a.errors = {};
                if (!a.deleted) {
                    lengthDel++;
                }
            });

            if (lengthDel === 0) {
                toastCutomBottom(this.$t('message.ProductIndex.modal.least_one_unit'), "error")
                return;
            }

            let idMap = {}, conversionMap = {};
            let validateOK = true;
            productUnits.forEach(productUnit => {
                if (productUnit.deleted) {
                    return;
                }

                if (!productUnit.unitId) {
                    validateOK = false;
                    productUnit.errors.unitId = [this.$t('message.ProductIndex.modal.choose_enough_unit')];
                }

                if (!productUnit.conversionValue) {
                    validateOK = false;
                    productUnit.errors = [this.$t('message.ProductIndex.modal.choose_enter_unit')];
                }

                if (!productUnit.price) {
                    validateOK = false;
                    productUnit.errors.price = [this.$t('message.ProductIndex.modal.enter_price')];
                }

                // if (!productUnit.isBase && productUnit.conversionValue == 1) {
                //     validateOK = false;
                //     productUnit.errors.conversionValue = ['Giá trị chuyển đổi phải khác 1'];
                // }

                if (idMap[productUnit.unitId]) {
                    validateOK = false;
                    const name = this.unitNameMap[productUnit.unitId];
                    productUnit.errors.unitId = [this.$t('message.ProductIndex.modal.duplicate_unit', {name: name})];

                }

                // if (conversionMap[productUnit.conversionValue]) {
                //     validateOK = false;
                //     productUnit.errors.conversionValue = [`Giá trị chuyển đổi ${productUnit.conversionValue} bị trùng`]
                // }

                idMap[productUnit.unitId] = true;
                conversionMap[productUnit.conversionValue] = true;
            })

            return validateOK;
        },

        async save() {     
            if(this.isLoading) return            
            const method = 'save' + this.currentTab.id;   
            this.isLoading = true;
            if(JSON.parse(localStorage.getItem('productID'))!=null) this.idArr = JSON.parse(localStorage.getItem('productID'));
            if (!this[method]) {
                alert('Method: ' + method + ' not exists');
                return;
            }            
            this[method]();
        },

        async saveTabThreshold() {             
            const productBranchMeta = this.getTabData('productBranchMeta');  
            let res = '';
            res = await $post('/v1/products/saveProductBranchMeta', {
                    entry: productBranchMeta,
                    productId: this.idArr,
                    multi: this.idProduct ? false :true
                });
            this.isLoading = false;
            $alert(res);
        },

        async saveTabCombo() {         
            // const comboProducts = this.getTabData('comboProducts');
            // const res = await $post('/v1/products/saveComboProducts', {
            //         products: comboProducts,
            //         parentProductId:  this.idArr,
            //         multi: this.idProduct ? false :true
            //     });
            // this.isLoading = false;
            // $alert(res);
            this.saveTabMain();
        },

        async saveTabMain() {           
            this.errors = {};
            const tab = this.$refs.tab;
            const errors = tab.getTabData('errors') ? tab.getTabData('entry') : this.TabMain ? (this.TabMain.entry ?? []) : []; 
            const value = tab.getTabData('entry') ? tab.getTabData('entry') : this.TabMain ? (this.TabMain.entry ?? []) : []; 
            const comboProducts = tab.getTabData('comboProducts') ? tab.getTabData('comboProducts') : this.TabCombo ? (this.TabCombo.comboProducts ?? []) : []; 
            //-------------------------------------------------               
            const productUnits = tab.getTabData('productUnits') ? tab.getTabData('productUnits') : this.TabMain ? (this.TabMain.productUnits ?? []) : [];
            
            if (!this.validateProductUnits(productUnits)) {
                this.isLoading = false;
                return;
            } 
            if (!this.validLength(value, errors)) {
                this.isLoading = false;
                return;
            }
            let res = '';
            if(value.type != this.$constant.typeProduct){
                value.isConsignment = 0;
            }
            if (!isNaN(value.categoryId) && !Array.isArray(value.categoryId) && value.categoryId) {
                value.categoryId = [value.categoryId]
            }
            if(value.categoryId?.length === 0){
                value.categoryName = '';
            }
            if(this.typeEditAdd != 'ADD') {   
                    value.id = this.idArr;            
                    res = await $post('/v1/products/saveMultiple', {
                    entry: value,
                    productUnits,
                    comboProducts: comboProducts,
                    });
                }
            else {                
                res = await $post('/v1/products/save', {
                entry: value,
                productUnits,
                comboProducts: comboProducts,         
                });
            }
            if (res.code === 422) {
                const errors = res.messages.errors;
                Object.keys(errors).forEach(function(key) {
                    $alert({code: res.code, message: errors[key][0]});
                });
                this.isLoading = false;
                return;
            }
            if (res.code === -1) {
                this.setTabData({
                    errors: res.errors
                });
                scrollToErrors();
                this.isLoading = false;
                $alert(res);
                return;
            }
            if (res.code === 200) {
                localStorage.removeItem('productID');
                this.$emit('resetCount');
                this.$emit('inputData');
                this.$emit('getInfoProduct', res);
                this.hide();
                $alert(res);
                return;
            }else{
                $alert(res);
                this.isLoading = false;
                return;
            }
        },

        validLength(data, errors){
            let validateOK = true;

            if (data.description?.length > 255) {
                validateOK = false;
                errors.description = [this.$t('message.ProductIndex.modal.max_length')];
            }
            if (data.unit?.length > 255) {
                validateOK = false;
                errors.unit = [this.$t('message.ProductIndex.modal.max_length')];
            }
            if (data.country?.length > 255) {
                validateOK = false;
                errors.country = [this.$t('message.ProductIndex.modal.max_length')];
            }
            if (data.manufacturer?.length > 255) {
                validateOK = false;
                errors.manufacturer = [this.$t('message.ProductIndex.modal.max_length')];
            }
            if (data.activeIngredient?.length > 255) {
                validateOK = false;
                errors.activeIngredient = [this.$t('message.ProductIndex.modal.max_length')];
            }

            return validateOK;
        },

        setProductUnits(productUnits) {
            setArray(productUnits, {
                deleted: false,
                isBase: false,
                errors: {}
            });

            const baseAttribute = productUnits?.find(pa => pa.conversionValue == 1);

            if (baseAttribute) {
                baseAttribute.isBase = true;
            }

            this.setTabData({
                productUnits: productUnits,
            })
        },

        async load() {
            if (!this.idProduct) return
            const res = await $get('/v1/products/show', {
                id: this.idProduct
            });

            if (res.code === 404) {
                location.replace('/404');
                return;
            }

            this.entry = res.data.entry;
            this.setTabData({
                entry: res.data.entry,
                isDataLoaded: true,
                comboProducts: res.data.comboProducts,
                inventories: res.data.inventories,
                inventoryWithBatches: res.data.inventoryWithBatches,
                productUnits: res.data.productUnits,
                productBranchMeta: res.data.productBranchMeta,
                actualInv: res.data.actualInv
            });

            document.title = res.data.entry.name;

            this.setProductUnits(res.data.productUnits);
        },

        showModalUnitAdd() {
            this.$refs.ModalUnitAdd.show();
        },

        showModalCategoriesAdd(){
            this.$refs.ModalCategoryAdd.show();
        },
        showModalCreateRouteOfUse(id){
            this.$refs.ModalCreateRouteOfUse.show(id);
        },
        showModalConfirmRemoveAtt(){
            this.$refs.ModalConfirm.show();
        },
        hideModalConfirmRemoveAtt(){
            this.$refs.ModalConfirm.hide();
        },
    },
    created() {
        emitter.on("changeType", (type) => {
            this.type = type;
        });
        emitter.on("showModalUnitAdd", () => {
            this.showModalUnitAdd()
        });
        emitter.on("showModalCategoriesAdd", () => {
            this.showModalCategoriesAdd()
        });
        emitter.on("showModalCreateRouteOfUse", (id) => {
            this.showModalCreateRouteOfUse(id)
        });
        emitter.on("showModalCreateAttribute", (index) => {
            this.showModalCreateAttribute(index)
        });
    },
    mounted() {
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        } else {
            this.type = this.entry.type ? this.entry.type : 1;

        }
    },

    watch: {
        entry: function (newValue) {
            if (newValue) {
                this.type = this.entry.type ? this.entry.type : 1;
            }
        },
        idProduct: function(newVal,oldVal) {
            if (this.idProduct || newVal) {
                return this.load()
            }
        },
        showModalConfirnRemoveAtrribute(newStatus) {
            if (newStatus) {
                this.showModalConfirmRemoveAtt();
            } else {
                this.hideModalConfirmRemoveAtt();
            }
        }
    }
}
</script>

