<template>
    <div ref="modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        {{$t('message.common.selectBranches')}}
                    </h5>
                </div>
                <div class="modal-body">
                    <InputSelect :errors="errors.branchId" :options="branches" :label="$t('message.employeeIndex.chooseBranch')" v-model="branchId"/>
                </div>
                <div class="modal-footer">
                    <button type="button" @click="selectBranch(branchId)" class="btn btn-primary">{{$t('message.employeeIndex.chooseBranch')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import store from "../store";
import InputSelect from "@/components/forms/InputSelect";
import { $post } from '@/lib/utils';

export default {
    name: "ModalBranchSelect",
    components: {InputSelect},
    mounted() {
        setTimeout(this.checkCurrentBranch, 1000);
    },
    data() {
        return {
            branchId: null,
            errors: {}
        }
    },
    computed: {
        branches: () =>  store.state.GlobalConfig?.userBranches ?? [],
    },
    methods: {
        checkCurrentBranch() {
            if (store.state.Auth) {
                if (!store.state.CurrentBranch) {
                    this.show();
                } else {
                    const found = this.branches.find(b => b.id === store.state.CurrentBranch.id);
                    if (!found) {
                        // Branch hiện tại bị xóa hoặc bị off
                        this.show()
                    }
                }
            }

        },
        selectBranch(branchId) {
            if (!branchId) {
                this.errors.branchId = [this.$t('message.common.selectBranches')];
                return;
            }

            const branch = this.branches.find(b => b.id === branchId);
            // store.commit('SetCurrentBranch', branch);
            store.commit('SetCurrentBranchForLogin', branch);
            location.reload();
            window.jQuery(this.$refs.modal).modal('hide');
            // $post('/v1/history-login', {branchId: store.state.CurrentBranch.id});
        },
        show() {
            window.jQuery(this.$refs.modal).modal({
                backdrop: 'static', keyboard: false
            })
        }
    }
}
</script>

<style scoped>

</style>
