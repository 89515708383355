<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm">
        <div class="modal-dialog modal-xs" role="document" style="width: 800px">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 class="modal-title py-2">{{ $t('message.DeliveryIndex.modal.modalCancel.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row">
                        <div class="col-12 pb-3" v-if="entry && entry.deliveryCode">
                            <h6 style="line-height:30px;">{{ $t('message.DeliveryIndex.modal.modalCancel.content')}}</h6>
                        </div>
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-save mr-2" @click="cancelDelivery()">
                                <i class="fa fa-check mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modalCancel.button.confirm') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mx-1" @click="hide()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modalCancel.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style scoped>
</style>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalCancel",
    components: {AppPermission},
    data(){
        return {
            entry: null,
        }
    },
    methods: {
        show(entry) {
            if(entry && entry.deliveryCode){
                this.entry = entry;
                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        hide(){
            this.entry = null;
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        async cancelDelivery() {
            const res = await $post(`/v1/transporters/cancel-trasporter`, {code: this.entry.deliveryCode});  
            if (res && res.result && res.result.status) {
                toastr.success(this.$t('message.common.cancelDeliverySuccess'));
                this.$emit('loadData');
            } else {
                toastr.error(this.$t('message.common.cancelDeliveryFailed'));
            }
            this.hide();
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>

