<template>
    <div ref="modalConfirm" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalConfirm" data-backdrop="static">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h6 class="modal-title py-2">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.title') }}</h6>
                    <button type="button" class="close" data-dismiss="modalConfirm" aria-label="Close" @click="hide()">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body my-4 py-0 mx-3 px-0" >
                    <div class="row m-0 p-0 pt-4" v-if="entries && entries.length">
                        <div class="col-lg-4">
                            <div class="row m-0 p-0" v-if="this.entry.price && this.hasCod">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.total_fee') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_total" 
                                        :checked="entry.type == this.$constant.type_payment_total ? true : false" @click="toggleOption(this.$constant.option_payment_3)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0 text-right" v-text="n(entry.total)"></div>
                            </div>
                            <div class="row m-0 p-0 pt-2" v-if="this.hasCod">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.cod') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_cod" 
                                        :checked="entry.type == this.$constant.type_payment_cod ? true : false" @click="toggleOption(this.$constant.option_payment_1)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0 text-right" v-text="n(entry.priceCodPayment)"></div>
                            </div>
                            <div class="row m-0 p-0 pt-2" v-if="this.entry.price">
                                <div class="col-lg-8 p-0">
                                    <label class="container-radio ml-0">
                                        {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.fee_ship') }}
                                        <input type="radio" name="type" class="chkType" 
                                        v-model="entry.type" 
                                        :value="this.$constant.type_payment_fee_ship"
                                        :checked="entry.type == this.$constant.type_payment_fee_ship ? true : false" @click="toggleOption(this.$constant.option_payment_2)">
                                        <span class="checkmark"></span>
                                    </label>
                                </div>
                                <div class="col-lg-4 p-0 text-right" v-text="n(entry.price)"></div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="row m-0 p-0">
                                <div class="col-lg-4">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.time') }}:</div>
                                <div class="col-lg-8 p-0 border-bottom-1">
                                    <SelectDateTimePicker :modelValue="entry.createdAt" v-model="entry.createdAt" :borderActive="true" :backgroundColor="'bg-white'"/>
                                </div>
                            </div>
                            <div class="row m-0 p-0 pt-4">
                                <div class="col-lg-4">{{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.method') }}:</div>
                                <div class="col-lg-8 p-0">
                                    <SelectSearch :readonly="true" :vModel="true" v-model="entry.method" :modelValue="entry.method" :defaultOption="this.$constant.optionMethodPayment"></SelectSearch>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <textarea v-model="entry.note" :placeholder="'Ghi chú'" class="noteStyle px-4 text-gray font-italic border-bottom border-bottom-focus-active"></textarea>
                            <i class="fa fa-pencil-alt text-gray" id="fa_edit"></i>
                        </div>
                    </div>
                    <div class="row m-0 p-0 mt-4">
                        <div class="col-lg-12">
                            <table class="table table-index table-striped" :class="entries && entries.length ? 'table-overflow-auto' : 'table-overflow-hidden'">
                                <thead>
                                    <tr>
                                        <th class="checkbox-table"></th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.invoice_code') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.delivery_code') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.customer') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.branch') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.transporter') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.invoice_value') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.cod') }}</th>
                                        <th class="column-value" v-if="this.option == this.$constant.option_payment_1">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.receive_cod') }}</th>
                                        <th class="column-value" v-if="this.option == this.$constant.option_payment_3 || this.option == this.$constant.option_payment_2">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.debt_fee_payment') }}</th>
                                        <th class="column-value" v-if="this.option == this.$constant.option_payment_3">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.cod_fee_payment') }}</th>
                                        <th class="column-value">{{ $t('message.TransporterIndex.modal.modelSelectInvoicePayment.status_delivery') }}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-light-warning" v-if="entries && entries.length">
                                        <td class="checkbox-table"></td>
                                        <td class="column-value"></td>
                                        <td class="column-value"></td>
                                        <td class="column-value"></td>
                                        <td class="column-value"></td>
                                        <td class="column-value"></td>
                                        <td class="column-value text-bold">{{ n(this.totalInvoiceValue) }}</td>
                                        <td class="column-value text-bold">{{ n(this.totalCOD) }}</td>
                                        <td class="column-value text-bold" v-if="this.option == this.$constant.option_payment_1">{{ n(this.totalCODEnd) }}</td>
                                        <td class="column-value text-bold" v-if="this.option == this.$constant.option_payment_3 || this.option == this.$constant.option_payment_2">{{ n(this.totalFee) }}</td>
                                        <td class="column-value text-bold" v-if="this.option == this.$constant.option_payment_3">{{ n(this.total) }}</td>
                                        <td class="column-value"></td>
                                    </tr>
                                    <template v-for="invoice in entries" :key="invoice.id">
                                        <tr>
                                            <td class="checkbox-table"><i class="fa fa-trash-alt" @click="remove(invoice.id)"></i></td>
                                            <td class="column-value text-primary" v-text="invoice.code" @click="linkToInvoice(invoice.code)"></td>
                                            <td class="column-value" v-text="invoice.delivery ? invoice.delivery.code : null"></td>
                                            <td class="column-value" v-text="invoice.customer ? invoice.customer.name : null"></td>
                                            <td class="column-value" v-text="invoice.branch ? invoice.branch.name : null"></td>
                                            <td class="column-value" v-text="invoice.delivery && invoice.delivery.transporter ? invoice.delivery.transporter.name : null"></td>
                                            <td class="column-value" v-text="n(invoice.totalOrigin)"></td>
                                            <td class="column-value" v-text="invoice.codStill ? n(invoice.codStill) : 0"></td>
                                            <td class="column-value" v-if="this.option == this.$constant.option_payment_1">
                                                <InputNumberFormat v-model="invoice.codStillEnd" :pxNone="true" :bgTransparent="true" :textLeft="true" 
                                                :max="invoice.codStill" :noAlert="true"
                                                class="mb-0"/>
                                            </td>
                                            <td class="column-value" v-text="invoice.feeStill ? n(invoice.feeStill) : 0" v-if="this.option == this.$constant.option_payment_3 || this.option == this.$constant.option_payment_2"></td>
                                            <td class="column-value" v-text="invoice.totalStill ? n(invoice.totalStill) : 0" v-if="this.option == this.$constant.option_payment_3"></td>
                                            <td class="column-value" v-text="invoice.delivery ? invoice.delivery.statusName : null"></td>
                                        </tr>
                                    </template>
                                    <template v-if="entries.length == 0">
                                        <tr>
                                            <td :colspan="8" class="pb-0 bg-light">
                                                <div class="row detail-prd-row mx-0">
                                                    <div class="col-12 text-center text-secondary mt-5">
                                                        <h3>
                                                            <i class="fa fa-file mr-2 text-secondary"></i>
                                                            <i class="fa fa-exclamation"></i>
                                                        </h3>
                                                    </div>
                                                    <div class="col-12 text-center my-10 text-secondary mb-5">
                                                        <p>{{ $t('message.common.notFound') }}</p>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </tbody>
                            </table>

                            <!-- <Pagination :value="paginate" :record="this.numberRecord" @change="load"/> -->
                        </div>
                    </div>
                    <div class="row m-0 p-0 mt-4">
                        <div class="col-12 d-flex justify-content-end">
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_cod">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_receipt') }}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_fee_ship">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_payment') }}
                            </button>
                            <button type="button" class="btn btn-save mr-2" @click="submit" v-if="entry.type == this.$constant.type_payment_total">
                                <i class="fa fa-calculator mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.type_all') }}
                            </button>
                            <button type="button" class="btn btn-secondary text-white mx-1" @click="hide()">
                                <i class="fa fa-ban mr-1"></i>
                                {{ $t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.button.skip') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {$post, $alert} from "@/lib/utils";
import toastr from 'toastr';
import AppPermission from '@/components/AppPermission.vue';
import SelectDateTimePicker from "@/components/SelectDateTimePicker";
import SelectSearch from "@/components/SelectSearch.vue";
import InputNumberFormat from "@/components/forms/InputNumberFormat";
import moment from 'moment';
import emitter from '@/lib/emitter';
import Pagination from "@/components/Pagination";
import currencyMixin from "@/currencyMixin";
export default {
    name: "ModalPaymentTransporter",
    mixins: [currencyMixin],
    components: {AppPermission, SelectDateTimePicker, SelectSearch, InputNumberFormat, Pagination},
    data(){
        return {
            entry: {},
            entries: [],
            max_price: 0,
            max_price_cod: 0,
            hasCod: false,
            paginate: {
                currentPage: 1,
                lastPage: 1,
            },
            numberRecord: 10,
            ids: [],
            idsRemove: [], 
            option: null,
            total: 0,
            totalCOD: 0,
            totalCODEnd: 0,
            totalFee: 0,
            totalInvoiceValue: 0,
        }
    },
    methods: {
        async show(ids) {
            if(ids){
                this.ids = ids;
                await this.load();
                emitter.emit('clearSelectDateTimePicker');
                window.jQuery(this.$refs.modalConfirm).modal('show');
            }
        },
        async load(page = 1) {
            emitter.emit("onLoading");
            const res = await $post('/v1/transporters/get-list-invoices', {record: this.numberRecord, page: page, ids: this.ids, idsRemove: this.idsRemove});
            if (!res.result.status) {
                $alert(res);
                return;
            }
            // this.paginate.currentPage = res.result.data.current_page ?? null;
            // this.paginate.lastPage = res.result.data.last_page ?? null;
            // this.paginate.totalRecord = res.result.data.total ?? null;
            // this.entries = res.result.data.data;
            this.entries = res.result.data;
            
            if(this.option){
                this.filterEntries();
            }else{
                this.totalInvoiceValue = 0;
                this.totalCOD = 0;
                this.totalCODEnd = 0;
                this.totalFee = 0;
                this.total = 0;
                if(res.result.totalInvoiceValue){
                    this.totalInvoiceValue = res.result.totalInvoiceValue;
                }
                if(res.result.totalCOD){
                    this.totalCOD = res.result.totalCOD;
                    this.totalCODEnd = res.result.totalCOD;
                    this.entry.priceCodPayment = res.result.totalCOD;
                    this.option = this.$constant.option_payment_1;
                    this.hasCod = true;
                }
                if(res.result.totalFee){
                    this.totalFee = res.result.totalFee;
                    this.entry.price = res.result.totalFee;
                    this.option = this.$constant.option_payment_2;
                }
                if(res.result.total){
                    this.total = res.result.total;
                    this.entry.total = res.result.total;
                }
                this.entry.type = this.entry.price ? this.$constant.type_payment_fee_ship : this.$constant.type_payment_cod;
                if(this.totalCOD && this.totalFee){
                    this.option = this.$constant.option_payment_3;
                    this.entry.type = this.$constant.type_payment_total;
                }
                this.max_price_cod = this.entry.priceCodPayment;
                this.max_price = this.entry.total;
                this.entry.method = 1;
            }
            emitter.emit("offLoading");
        },
        hide(){
            this.clear();
            window.jQuery(this.$refs.modalConfirm).modal('hide');
        },
        clear(){
            this.entry = {
                note: null,
                invoiceCode: null,
                deliveryId: null,
                deliveryCode: null,
                transporterId: null,
                transporterName: null,
                price: null,
                priceCodPayment: null,
                method: null,
                type: null,
                total: null,
                branchId: null,
                customerId: null,
            };
            this.idsRemove = [];
            this.ids = [];
            this.option = null;
            this.total = 0;
            this.totalCOD = 0;
            this.totalCODEnd = 0;
            this.totalFee = 0;
            this.totalInvoiceValue = 0;
            this.hasCod = false;
        },
        async submit(){
            let now = moment().format('YYYY-MM-DD hh:mm');
            if(now < moment(this.entry.createdAt).format('YYYY-MM-DD hh:mm')){
                $alert({code: 422, message: this.$t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.message_validate_time_error')});
                return;
            }
            this.entry.cod = this.entry.priceCodPayment;
            this.entry.priceFee = +this.entry.price;
            if(this.entry.type == this.$constant.type_payment_total){
                this.entry.cod = +this.entry.total + +this.entry.price;
            }
            if(this.entry.type == this.$constant.type_payment_cod){
                this.entry.priceFee = 0;
            }
            if(this.entry.type == this.$constant.type_payment_fee_ship){
                this.entry.cod = 0;
            }
            const res = await $post('/v1/deliveries/payment-multiple', {...this.entry, data: this.entries});
            if(res.result && res.result.status){
                $alert({code: 200, message: this.$t('message.DeliveryIndex.modal.modelPaymentAndCreateRefund.message_create_multiple_success')});
                this.$emit('reload');
                this.hide();
            }
            if(res.result && !res.result.status && res.result.message){
                $alert({code: 500, message: res.result.message});
            }
        },
        linkToInvoice(code){
            var route = null;
            route = this.$router.resolve({
                name: 'InvoiceIndex',
                query: {
                    'code': code,
                }
            });
            window.open(route.href, '_blank');
        },
        remove(id){
            this.idsRemove.push(id);
            this.load();
        },
        toggleOption(option) {
            if(option){
                this.option = option;
                this.load();
            }
        },
        filterEntries(){
            if(this.option == this.$constant.option_payment_1){
                this.entries = this.entries.filter(entry => entry.codStill > 0);
            }else if(this.option == this.$constant.option_payment_2){
                this.entries = this.entries.filter(entry => entry.feeStill > 0);
            }
        }
    },
    beforeUnmount() {
        this.hide();
    },
}
</script>

<style scoped>
.noteStyle {
    width: 100% !important;
    border: 0;
    border-radius: 0;
}
.noteStyle:focus {
    outline: none !important;
    border-color: none;
    box-shadow: 0 none;
}
#fa_edit {
    position: absolute;
    left:20px;
    top:4px;
    color:#a7a2a2;
}
.container-radio input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.table th, .table td {
    height: 40px !important;
}
.table tbody td{
    border-bottom: 1px solid #FFFFFF;
}
.table-overflow-hidden{
    overflow: hidden;
    display: block;
}
.table-overflow-auto{
    overflow: auto;
    display: block;
}
.column-value{
    min-width: 160px !important;
}
table tr .checkbox-table{
    max-width: 30px !important;
    min-width: none;
}
</style>
