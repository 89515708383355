<template>
    <div>
        <div class="container my-4">
            <div class="row m-0 p-0">
                <div class="mainLeft">
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-12 pl-1 main-title">
                                    <h5><b>{{ $t('message.SettingPage.title_left_top') }}</b></h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0" id="branchBody">
                            <div class="row px-2">
                                <div class="col-12 sidebar-item" :class="this.nameTab == 'product' ? 'active' : ''" @click="nextTab('product')">
                                    {{ $t('message.SettingPage.product.title') }}
                                </div>
                            </div>
                            <div class="row px-2 mt-2">
                                <div class="col-12 sidebar-item" :class="this.nameTab == 'partner'  ? 'active' : ''" @click="nextTab('partner')">
                                    {{ $t('message.SettingPage.partner.title') }}
                                </div>
                            </div>
                            <div class="row px-2 mt-2">
                                <div class="col-12 sidebar-item" :class="this.nameTab == 'transaction'  ? 'active' : ''" @click="nextTab('transaction')">
                                    {{ $t('message.SettingPage.transaction.title') }}
                                </div>
                            </div>
                            <div class="row px-2 mt-2">
                                <div class="col-12 sidebar-item" :class="this.nameTab == 'webhook'  ? 'active' : ''" @click="nextTab('webhook')">
                                    {{ $t('message.SettingPage.webhook.title') }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-2"></div>

                    <!-- Ẩn đi do không sử dụng -->
                    
                    <!-- <div class="card" id="typeCard m-4">
                        <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                            <div class="row mx-2">
                                <div class="col-12 pl-1 main-title">
                                    <h5><b>{{ $t('message.SettingPage.title_left_bot') }}</b></h5>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 pt-0" id="branchBody">
                            <div class="row px-2">
                                <div class="col-12 sidebar-item" @click="listShop()">
                                    {{ $t('message.SettingPage.store.title') }}
                                </div>
                            </div>
                            <div class="row px-2 mt-2">
                                <div class="col-12 sidebar-item" @click="mappingProducts()">
                                    {{ $t('message.SettingPage.security.title') }}
                                </div>
                            </div>
                        </div>
                    </div> -->

                </div>
                <div class="mainRight pr-0">

                    <!-- Đăng nhập misa , tạm comment -->
                    <!-- <div class="d-flex d-flex align-items-center">
                        <div class="col-lg-6 pl-5 justify-content-center">
                            <img src="../../../public/img/misa.png" class="w-100">
                        </div>
                        <div class="col-lg-6">
                            <h3 class="text-custom text-bold">ĐĂNG NHẬP MISA</h3>
                            <div class="row m-0 p-0 my-4 border-bottom">
                                <div class="col-3 text-bold d-flex align-items-center">Mã số thuế <label
                                        class="text-danger px-0">*</label></div>
                                <div class="col-9">
                                    <input type="text" v-model="model.taxCode" id="phone" name="taxcode"
                                        class="border-none w-100 py-2" placeholder="Mã số thuế" autocomplete="off"
                                        autofocus>
                                </div>
                            </div>
                            <div class="row m-0 p-0 my-4 border-bottom">
                                <div class="col-3 text-bold d-flex align-items-center">Tên đăng nhập <label
                                        class="text-danger px-0">*</label></div>
                                <div class="col-9">
                                    <input type="text" id="api_key" v-model="model.userName" name="username"
                                        class="border-none w-100 py-2" placeholder="Tên đăng nhập" autocomplete="off">
                                </div>
                            </div>
                            <div class="row m-0 p-0 my-4 border-bottom">
                                <div class="col-3 text-bold d-flex align-items-center">Mật khẩu <label
                                        class="text-danger px-0">*</label></div>
                                <div class="col-9">
                                    <input type="password" v-model="model.password" name="password"
                                        class="border-none w-100 py-2 form-control" placeholder="Mật khẩu" autocomplete="off">
                                </div>
                            </div>
                            <div class="row m-0 p-0 my-4">
                                <div class="col-12 text-center">
                                    <button class="btn btn-save" @click="submit()">Đăng nhập</button>
                                </div>
                            </div>
                        </div>
                    </div> -->

                    <!-- Thiết lập hàng hóa -->
                    <template v-if="this.nameTab == 'product'">
                        <SettingProducts :entries="this.entries"></SettingProducts>
                    </template>

                    <!-- Thiết lập đối tác -->
                    <template v-else-if="this.nameTab == 'partner'">
                        <SettingPartner :entries="this.entries"></SettingPartner>
                    </template>

                    <!-- Thiết lập đồng bộ -->
                    <template v-else-if="this.nameTab == 'webhook'">
                        <SettingSync :entries="this.entries"></SettingSync>
                    </template>

                    <!-- Thiết lập giao dịch -->
                    <template v-else>
                        <SettingTransaction :entries="this.entries"></SettingTransaction>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { $post, $get } from "@/lib/utils";
import toastr from 'toastr';
import SettingProducts from '@/components/settings/SettingProduct.vue';
import SettingPartner from "@/components/settings/SettingPartner.vue";
import SettingTransaction from '@/components/settings/SettingTransaction.vue';
import SettingSync from '@/components/settings/SettingSync.vue';
import { mapGetters } from "vuex";
import storeModul from "@/storeModul";
export default {
    name: "StoreSetting",
    components: { SettingProducts, SettingPartner, SettingTransaction, SettingSync},
    computed: {
        ...mapGetters({
            statusGetDataTransporter: 'settingStore/statusGetDataTransporter'
        })
    },
    data() {
        return {
            model: {
                taxCode: '',
                userName: '',
                password: ''
            },
            nameTab: 'product',
            entries: []
        }
    },
    mounted() {
        document.title = this.$t('message.SettingPage.title_left_bot');
        this.load();
    },
    methods: {
        async submit() {
            const res = await $post('/v1/misa/login', this.model);
            if (res.result == false) {
                toastr.error(this.$t('message.common.inValidInforLogin'));
            } else {
                if (res.code == 422) {
                    this.isMessageError(res.messages.errors);
                } else {
                    toastr.success(this.$t('message.common.loginMisaSuccess'))
                }
            }
        },
        isMessageError(errMss) {
            if (errMss.userName) {
                toastr.error(errMss.userName);
                return;
            }
            if (errMss.taxCode) {
                toastr.error(errMss.taxCode);
                return;
            }
            if (errMss.password) {
                toastr.error(errMss.password);
                return;
            }
        },
        async nextTab(tab) {
            this.nameTab = tab;
            this.load();
        },
        async load() {
            const data = await $get('/v1/setting-store/get-option', {action: this.nameTab});
            this.entries = data.result;
        }
    },
    watch: {
        statusGetDataTransporter: function (newValue) {
            if (!newValue) return;
            if (newValue) {
                this.load();
            }
            storeModul.dispatch('settingStore/statusGetDataSettingTransports', false);
        }
    }
}
</script>

<style scoped>
#number_records {
    margin-top: 11px;
}

.container input[type="radio"] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.text-title {
    line-height: 52px;
}

#typeCard {
    margin-top: -8px;
}

.text-custom {
    color: #0D4073
}
</style>