<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-md" role="document">
            <div class="modal-content">
                <div class="modal-header py-1 bg-light">
                    <h5 class="modal-title py-2 px-2">
                        {{$t('message.LocationIndex.titleUpdateLocationCategories')}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
    
                <div class="modal-body py-0 px-3">
                    <div class="row mx-4 my-3 mt-1">
                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.branch')}}:<label class="text-danger px-0">*</label></div>
                        <div class="col-8 pl-0">
                            <template v-for="(branch, index) in this.branches" :key="index">
                                <template v-if="branch.id === entry.branchId">
                                    <label v-text="branch.name"></label>
                                </template>
                            </template>
                        </div>
                    </div>
                    <div class="row mx-4 my-3 mt-1">
                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.parentCategory')}}:</div>
                        <div class="col-8 pl-0">
                            <div class="form-group mb-0 position-relative">
                                <input type="text" v-model="this.locationName" :placeholder="$t('message.LocationIndex.parentCategory') + '...'" class="form-control" @click="showModalSelectLocations">
                                <button type="button" class="border-none bg-transparent btn-icon icon-arrow">            
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362" ><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row mx-4 my-3 mt-1">
                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.name')}}: <label class="text-danger">*</label></div>
                        <div class="col-8 pl-0">
                            <InputText v-model.trim="entry.name" :placeholder="$t('message.LocationIndex.enterCategoriesName')" class="mb-0"></InputText>
                        </div>
                    </div>
                    <div class="row mx-4 my-3 mt-1">
                        <div class="col-4 d-flex align-items-center text-bold">{{$t('message.LocationIndex.code')}}: <label class="text-danger">*</label></div>
                        <div class="col-8 pl-0">
                            <InputText v-model.trim="entry.code" :placeholder="$t('message.LocationIndex.enterCategoriesCode')" class="mb-0"></InputText>
                        </div>
                    </div>
                </div>
                <div class="modal-footer border-none d-block">
                    <div class="row mx-4 my-4">
                        <div class="col-12 flex-end p-0">
                             <AppPermission :permission="this.$permissions.locations.update?? '' ">
                                <button type="button" class="btn btn-save mr-2" @click="save">
                                    <i class="fa fa-save mr-1"></i>
                                    {{$t('message.button.save')}}
                                </button>
                            </AppPermission>
                            <button type="button" class="btn btn-secondary text-white mr-2" @click="closeModal">
                                <i class="fa fa-ban mr-1"></i>
                                {{$t('message.button.skip')}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide" :title="$t('message.common.confirm')" :content="$t('message.LocationIndex.cancelUpdateLocationCategories')"/>
    <ModalSelectLocations ref="ModalSelectLocations" @updateLocationsName="getLocationName" :addLocationCategory="true"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import ModalConfirm from "@/components/ModalConfirm.vue";
import InputNumber from '@/components/forms/InputNumber';
import store from "@/store";
import SelectSearch from "@/components/SelectSearch";
import ModalSelectLocations from '../ModalSelectLocations';
import { $get, $post, $alert, clone, removeVietnameseTones, isChangeData} from "@/lib/utils";
import emitter from '@/lib/emitter';
import AppPermission from '@/components/AppPermission.vue';
export default {
    name: "ModalEditLocations",
    components: { InputText, InputNumber , ModalConfirm, SelectSearch, ModalSelectLocations,AppPermission
    },
    props: {},
    data () {
        const globalConfig = store.state.GlobalConfig;
        return {
            entry:{},
            default: {},
            branchId: null,  
            locationName: null,   
            locationActiveId: 0,
            branches: globalConfig.branches,
        }
    },
    mounted() {
    },
    created() {
    },
    methods: {
        show(entry) {
            
            this.setDefaultValue();
            if(entry){
                this.entry = {...entry};
                this.default = {...entry};
            }
            emitter.emit('resetDataSelectLocation');
            if(this.entry.listParentInfo){
                let params = clone(this.entry.listParentInfo);
                emitter.emit('getDataSelectLocation', params.reverse());
            }
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide () {
            window.jQuery(this.$refs.modal).modal('hide');
        },
        setDefaultValue(){
            this.entry = {};
            this.default = {};
            this.locationActiveId = 0;
            this.branchId = null;
            this.locationName = null;
        },
        closeModal(){
            const isChange = isChangeData(this.entry, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.$refs.ModalConfirm.show();
            }
        },
        showModalSelectLocations(){
             this.$refs.ModalSelectLocations.show();
        },
        getLocationName(input){
            this.entry.parent = input.id ? input.id : 0;
            this.locationName = null;
            this.locationName = (input.nameLv1 ? input.nameLv1 : '' ) + (input.nameLv2 ? ' > ' + input.nameLv2 : '' ) + (input.nameLv3 ? ' > ' + input.nameLv3 : '' ) + (input.nameLv4 ? ' > ' + input.nameLv4: '' );
        },
        async save(){
            let save = true;
            if(!this.entry.name){
                $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesName')});
                save = false;
                return;
            }
            if(!this.entry.code){
                $alert({code: 422, message: this.$t('message.LocationIndex.emptyCategoriesCode')});
                save = false;
                return;
            }
            if(save){
                const res = await $post('/v1/locations/update-location-category', {data: {...this.entry}});
                if(res.result && !res.result.status && res.result.message){
                    $alert({code: 422, message: res.result.message});
                }else if(res.result && res.result.status && res.result.message){
                    $alert({code: 200, message: res.result.message});
                    this.$emit('reload');
                    this.hide();
                }
            }
        },
    }
}
</script>
<style scoped lang="scss">
@import "../../../assets/css/vars.scss";
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}
.border-bottom-1-omi{
    border: none;
    border-bottom: 1px solid $base-color;
}
.icon-arrow{
    background-color: none;
    outline: none;
    position: absolute;
    top: 5px;
    right: 5px;
}
.icon-arrow svg{
    fill: #212529;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.icon-arrow svg{
    width: 9px;
    height: 9px;
    fill: #666
}
</style>