<template>
 <div ref="modal" class="modal mx-0" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-2">
                    
                    <h5 class="modal-title py-2">
                        {{ $t('message.common.removePromotions') }}
                    </h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-2" >
                    <span v-text="$t('message.common.confirmRemovePromotion', {code: this.code})"></span>
                    <div class="col-12 d-flex justify-content-end mt-2">
                        <button class="btn btn-danger mr-2" @click="removePromotion()"><i class="fa fa-check mr-1"></i>{{$t('message.button.confirm')}}</button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close"><i class="fa fa-ban mr-1"></i>{{$t('message.button.cancel')}}</button>
                    </div>
                </div>
        </div>
    </div>
</div>
</template>
<script>
import {$post} from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalRemovePromotions',
    props: {},
    data () {
        return {
            id: null,
            code: null
        };
    },
    methods: {
        show(id, code) {
            this.id = id;
            this.code = code;          
            window.jQuery(this.$refs.modal).modal('show');
        },
        async removePromotion() {
        
          const res = await $post(`/v1/promotions/remove-promotion`, {id: this.id, code: this.code});
          if(res && res.status.code == 200 && res.result.code == 200){
            toastr.success(res.result.message   );
          }
          else {
            toastr.error(res.result.message);
          }
          
          window.jQuery(this.$refs.modal).modal('hide');
          this.$emit('reloadData');
        }
    }
}
</script>
<style>
</style>