<template>
  <div class="row mb-4 tabInfo" style="max-height:850px !important;" >
    <div class="col-12">
        <h6 style="font-weight:600;">
           {{ $t('message.common.templateKeys') }} {{ this.title}}         
        </h6>
    </div>
    <template v-for="(entry, index) in this.entries" :key="index">
      <div class="col-6">
        <div class="row p-0 mt-3">
          <div class="col-6 text-bold" v-text="'{'+ entry.code + '}'"></div>
          <div class="col-6" v-text="entry.name"></div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {$get} from "@/lib/utils";
import printTransactionMixin from '@/printTransactionMixin';
export default {
  name: "ModalEditPrintInfomation",
  mixins: [printTransactionMixin],
  props: ['action'],
  data() {
      return {
        entries: [],
        title: null
      }
  },
  mounted() {
    this.load();
  },
  methods: {    
    async load() {   
      this.title = this.getTitleImformation(this.action);       
      const res = await $get(`/v1/print-templates/details`, {action: this.action});      
      if(res && res.status.code == 200){
        if(res.result) {
          this.entries = res.result;
        }
      }      
    },
  },
};
</script>

<style>
    ul li {
      list-style: none;
      min-height: 14px;
    }
    .titleFr{
      font-weight: 600;
      padding-right: 10px;     
    }   
    .right-print, .titleFr {
      font-size: 15px;
    }
</style>