<template>
    <div class="row">
        <div class="col-lg-12 mt-4 mb-4">
            <table class="table table-index table-striped text-center">
                <thead>
                <tr>
                    <th>{{$t('message.InputQuickSearch.title.code_invoice')}}</th>
                    <th>{{$t('message.common.time')}}</th>
                    <th>{{$t('message.common.created_by')}}</th>
                    <th>Giá trị</th>
                    <th>{{$t('message.common.status')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="item in invoices" :key="item.id">
                    <td v-text="item.code"></td>
                    <td v-text="d(item.purchaseDate)"></td>
                    <td v-text="item.soldByName"></td>
                    <td v-text="n(item.total)"></td>
                    <td v-text="item.statusValue"></td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OrderTabInvoiceHistory",
        data() {
            return {
                invoices: []
            }
        }
    }
</script>

<style scoped>

</style>
