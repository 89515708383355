<template>
     <AppPermission :permission="this.$permissions.permissions.data?? '' ">
        <div class="container my-4">
            <div class="row m-0 p-0">
                <!-- <div class="col-xl-3 col-lg-3 col-md-4"> -->
                <div class="mainLeft">
                    <div class="main-title">
                        <span>
                            {{ $t('message.common.permission') }}
                        </span>
                    </div>
                    <div>
                        <div class="card-header mx-0 px-0" id="typeHeader" >
                            <div class="row mx-2" >
                                <div class="col-8 text-title">
                                    <label>{{ $t('message.common.numberRecord') }}:</label>
                                </div>
                                <div class="col-4">
                                    <select name="number_records" id="number_records" class="number_records" v-model="numberRecord" @change="onChange($event)">
                                        <option value="15">15</option>
                                        <option value="20">20</option>
                                        <option value="30">30</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>           
                    <div class="card" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row ml-2">
                                <div class="col-8">
                                    <label class="typeTitle">
                                        {{$t('message.employeeIndex.employee_job_title')}}
                                    </label>
                                </div>
                                <!-- <div class="col-1 pl-0 ml-0">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showModalJobTitleAdd">
                                        <i class="fa fa-plus-circle fa-md text-secondary"></i>
                                    </a>
                                </div> -->
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon"  @click="showHide('jobTitle','jobTitleDown','jobTitleDownUp')" >
                                        <i class="fa fa-chevron-down dp-hide" id="jobTitleDown"></i>
                                        <i class="fa fa-chevron-up" id="jobTitleDownUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-2" id="jobTitle">
                            <div class="row">
                                <div class="col-12">
                                    <div class="prd-search-box1" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="text" :placeholder="this.$t('message.employeeIndex.chooseJobTitle')" v-model="jobTitle.search" @input="onInputFilterJobTitle">
                                        <button type="button" class="border-none bg-transparent btn-icon" v-show="jobTitle.search" @click.stop="clearValue('jobTitle')"><i class="fa fa-times text-danger"></i></button>
                                        <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButton">
                                            <template v-if="this.jobTitle.filter?.length > 0">
                                                <a class="dropdown-item" href="javascript:;" v-for="jobTitle in this.jobTitle.filter" :key="jobTitle.id" :value="jobTitle.id" @click="searchUserForJobTitle(jobTitle.id, jobTitle.name)" :class="this.searchSibar.jobTitleId === jobTitle.id ? 'text-bold' :''">
                                                    {{jobTitle.name}}                                                    
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a class="dropdown-item" href="javascript:;">
                                                    {{$t('message.common.notFound')}}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div class="card mt-4" id="typeCard">
                        <div class="card-header mx-0 px-0" id="typeHeader">
                            <div class="row ml-2">
                                <div class="col-8">
                                    <label class="typeTitle">
                                        {{$t('message.common.role')}}
                                    </label>
                                </div>                     
                                <div class="col-2">
                                    <a href="javascript:void(0);" class="text-dark" id="linkIcon" @click="showHide('depament','depamentDown','depamentUp')">
                                        <i class="fa fa-chevron-down dp-hide" id="depamentDown"></i>
                                        <i class="fa fa-chevron-up" id="depamentUp"></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div class="card-body my-0 py-0 block__depament" id="depament">
                            <!-- Danh sách -->
                            <div class="row my-0 py-2">
                                <div class="col-12">
                                    <div class="prd-search-box1" id="dropdownMenuButtonDepartment" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        <input type="text" :placeholder="$t('message.employeeIndex.chooseRole')" v-model="this.roleId.search" @input="onInputFilterDepartment">
                                        <button type="button" class="border-none bg-transparent btn-icon" v-show="roleId.search" @click.stop="clearValue('roleId')"><i class="fa fa-times text-danger"></i></button>
                                        <div class="dropdown-menu dropdownSupplier" aria-labelledby="dropdownMenuButtonDepartment">
                                            <template v-if="roleId.filter?.length > 0">
                                                <a class="dropdown-item" href="javascript:;" v-for="item in roleId.filter" :key="item.id" :value="item.id" @click="searchEmployeeForDepartment(item.id, item.name)" :class="this.searchSibar.roleId === item.id ? 'text-bold' :''">
                                                    {{item.name}}                                                    
                                                </a>
                                            </template>
                                            <template v-else>
                                                <a class="dropdown-item" href="javascript:;">
                                                    {{$t('message.common.notFound')}}
                                                </a>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tìm kiếm theo trạng thái: -->
                    <div class="card mt-4" id="typeCard">
                                <div class="card-header mx-0 px-0 pb-0" id="typeHeader">
                                    <div class="row mx-2">
                                        <div class="col-9">
                                            <label class="typeTitle">{{$t('message.common.status')}}</label>
                                        </div>
                                        <div class="col-1">
                                            <a href="javascript:void(0);" class="text-dark" id="linkIcon"
                                                @click="showHide('status', 'dDown', 'dUp')">
                                                <i class="fa fa-chevron-down dp-hide" id="dDown"></i>
                                                <i class="fa fa-chevron-up" id="dUp"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body my-2 py-0" id="status">
                                    <!-- <div class="row">
                                        <div class="col-12">
                                            <label class="container ml-2">{{$t('message.common.all')}}
                                                <input class="form-check-input" type="radio" v-model="this.emStatus.search" value=""  @change="search(this.emStatus.search)" name="status" checked>
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="container ml-2">Đang hoạt động
                                                <input class="form-check-input" type="radio" v-model="this.emStatus.search" value="1" @change="search(this.emStatus.search)" name="status">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-12">
                                            <label class="container ml-2">Ngừng hoạt động
                                                <input class="form-check-input" type="radio" v-model="this.emStatus.search" value="2" @change="search(this.emStatus.search)" name="status">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div> -->
                                    <div class="row">
                                        <div class="col-12" v-for="item in this.$constant.listStatusUser" :key="item.id">
                                            <label class="container-radio ml-0">{{$t(item.name)}}
                                                <input type="radio" name="sttActive" @click="doFilter('sttActive', item.id)" class="chkType" :checked="this.emStatus.search === item.id ? true: false">
                                                <span class="checkmark"></span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                <!-- <div class="col-xl-9 col-lg-9 col-md-8"> -->
                <div class="mainRight">
                    <div class="sticky-top">
                        <div class="row">
                            <div class="col-lg-5">
                                <div class="search-box">                           
                                    <i class="fa fa-search mr-2"></i>                                                   
                                    <input type="text" class="search-input" id="search-header" :placeholder="$t('message.common.searchEmployee')" @keydown.enter="searchHeadEmployee()" autocomplete="off" v-model="this.searchHead"/>                                   
                                </div>
                            </div>                         
                        </div>
                        <div>                            
                            <Pagination :value="paginate" :record="numberRecord" @change="pageChange" />
                        </div>
                        <div class="row my-2"></div>
                        <div v-if="users && users.length" class="row mx-0 my-2" id="divTable">
                            <table class="table table-index table-striped " v-if="!noField">
                                <thead>
                                    <tr>
                                        <th style="min-width:40px;max-width:200px" scope="col" class="checkbox-table"><input v-model="checkedAll" @change="onCheckedAll(checkedAll)" type="checkbox" id="chkAll" /></th>
                                        <th scope="col" style="vertical-align: middle !important;" v-for="(item, index) in fields" :key="index" v-show="item?.selected">
                                            <TableSortField :current="sortData.field" :no-sort="!item.noSort" @onsort="sort" :name="item.name" :field="item.field" />
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <template v-for="(user, key) in users" :key="key" >
                                        <tr class="master-row" :style="key % 2 ? 'background-color: white' : null" :class="[ user.id === userIdDetail ? 'backgroup__detail' : '', errorClass]" :id="'row_' + (key + 1)">                                
                                            <td><input type="checkbox"></td>
                                            <td v-show="(fields.find(item => item.field === 'code'))?.selected" class="py-0" style="max-width:220px;">
                                                <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                    <span class="description">{{ user.code }}</span>
                                                </a>
                                            </td>
                                            <td v-show="(fields.find(item => item.field === 'name'))?.selected" class="py-0">
                                                <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                    <span class="description">{{ user.name }}</span>
                                                </a>
                                            </td>
                                            <td v-show="(fields.find(item => item.field === 'userName'))?.selected" class="py-0" style="max-width:220px;">
                                                <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                    <span class="description">{{user.userName ? user.userName : ''  }}</span>
                                                </a>
                                            </td>
                                            <td v-show="(fields.find(item => item.field === 'jobTitleName'))?.selected" class="py-0" style="max-width:220px;">
                                                <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                <span class="description">{{ user.job_title ? user.job_title.name : '' }}</span>
                                                </a>
                                            </td>                                                        
                                            <td v-show="(fields.find(item => item.field === 'statusName'))?.selected" class="py-0" style="max-width:220px;">
                                                <a :href="'#row_' + key" class="description text-dark tagA" @click="showDetail(user.id)">
                                                    <span class="description">{{ this.getStatus(user.status) }}</span>
                                                </a>
                                            </td>  
                                        </tr>
                                        <!-- Hiển thị tab -->
                                        <tr v-if="userIdDetail === user.id" :class="[ user.id === userIdDetail ? 'backgroup__detail' : '', errorClass]">
                                            <td class="no-boder"></td>
                                            <td :colspan="fields.length" class="no-boder">
                                                <div class="row detail-prd-row">
                                                    <div class="col-2 mt-2" @click="showContent('info')">
                                                        <span :class="[ headerActive === 'info' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.ProductIndex.info')}}
                                                        </span>
                                                    </div>     
                                                    <!-- || this.userLogin.id == this.$constant.isAdmin                                                                       -->
                                                    <div class="col-2 mt-2" @click="showContent('permissions', user)"
                                                        v-if="user.id != this.$constant.isAdmin  "> <!-- Chi có quyền admin mới chỉnh sửa được Admin-->                                                        
                                                        <span :class="[ headerActive === 'permissions' ? 'backgroup__detail-active' : '', 'head-detail']">
                                                            {{$t('message.common.permission')}}
                                                        </span>
                                                    </div>                                            
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Thông tin tab thông tin người dùng -->                                     
                                        <tr class="detail-row bg-white" v-if="userIdDetail === user.id && headerActive === 'info'">
                                            <td :colspan="fields.length+2">
                                                <div class="mt-2 pb-4 detail__user mx-4">
                                                    <div class="row mt-2">                                               
                                                        <div class="col-6">
                                                            <div class="row mx-1">
                                                                <div class="col-12 mt-2 py-2 border-bottom-0">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            {{$t('message.employeeIndex.employee_code')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{ user.code }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mt-2 py-2 border-bottom-0">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            {{$t('message.employeeIndex.employee_name')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{ user.name }}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-12 mt-2 py-2 border-bottom-0">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            {{$t('message.employeeIndex.employee_user_name')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                        {{user.userName?  user.userName : '' }}
                                                                        </div>
                                                                    </div>
                                                                </div>                                              
                                                            </div>
                                                        </div>
                                                        <div class="col-6">
                                                            <div class=" row mx-1">  
                                                                <div class="col-12 mt-2 py-2 border-bottom-0">
                                                                <div class="row">
                                                                        <div class="col-3">
                                                                            {{$t('message.employeeIndex.employee_job_title')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{ user.job_title ? user.job_title.name : '' }}
                                                                        </div>
                                                                    </div>
                                                                </div>                                                              
                                                                <div class="col-12 mt-2 py-2 border-bottom-0">
                                                                    <div class="row">
                                                                        <div class="col-3">
                                                                            {{$t('message.common.status')}}:
                                                                        </div>
                                                                        <div class="col-8 ow-break-word">
                                                                            {{this.getStatus(user.status)}}
                                                                        </div>
                                                                    </div>
                                                                </div>                                                        
                                                            </div>
                                                        </div>                                               
                                                    </div>                                            
                                                </div>
                                            </td>
                                        </tr>
                                        <!-- Hiển thị tab phân quyền-->                               
                                        <tr class="detail-row bg-white" v-if="userIdDetail === user.id && headerActive === 'permissions'" >
                                            <td class="no-boder"></td>
                                            <td :colspan="fields.length">
                                                <div class="row mt-4 mx-2 detail__user">
                                                    <div class="col-6 text-left" style="font-weight:bold">
                                                        {{$t('message.common.perrmissionUser')}}
                                                    </div>
                                                    <div class="col-6 text-right"> 
                                                        <a href="/roles/index" target="_blank">
                                                            {{ $t('message.common.roles') }}
                                                        </a>                                                   
                                                    </div>
                                                </div>
                                                <!-- Nếu u r đã tồn tại quyền theo role và chi nhánh -->
                                                <!-- --------------------------------------------------------------------------------------------------------------------------- -->                                                 
                                                <template v-for="(role, index) in user.groupRole" :key="role.id">                                                                                                             
                                                    <div class="row mx-2 my-4 detail_permission">
                                                        <div class="col-5 pr-0 empTitle">                                                       
                                                            <span v-if="index == 0">
                                                                {{ $t('message.common.role') }}
                                                            </span>                                                                
                                                            <q-select name="role" :default-options="this.roleId.all" :multiple="false" v-model="role.roleId" :selectAll="false"                                                                                                                                                                        
                                                            :newRole="true" :newRoleLable="$t('message.common.addRole')"  :placeholder="$t('message.common.searchRole')" ref="role"
                                                            @addRole="showModalCreateRole" 
                                                            :dataUpdate="{user_role_id: role.user_role_id, roleId:role.roleId, roleName: role.roleName , userId:user.id, branchId:role.branchId?? '', isEdit: true, permissionsIds: role.permissionsIds}" 
                                                            :isEditRole="user.sys_user_role.length > 0 ? true : false"                                                               
                                                            @updatePermissionRole="updatePermissionRole" @roleChange="changeData"
                                                            />                                                       
                                                            <!-- :dataUpdate="{roleId:role.role && role.role.id ? role.role.id : role.id, userId:user.id, branchId:role.branchId?? '', isEdit: true, roleName: role.role.name?? ''}" -->
                                                        </div>
                                                        <div class="col-6"> 
                                                            <span v-if="index == 0"> 
                                                                {{$t('message.common.branch')}}
                                                            </span>                                                        
                                                            <q-select name="branches" :default-options="user.branches" :multiple="true" :selectAll="true" v-model="role.branchId"  
                                                            :placeholder="$t('message.common.searchBranch')" ref="branches"                                                                                                                                                                                       
                                                            :dataUpdate="{user_role_id: role.user_role_id, roleId:role.roleId, roleName: role.roleName , userId:user.id, branchId:role.branchId?? '', isEdit: true, permissionsIds: this.dataPermission}" 
                                                            @branchChangeStatus="branchChangeStatus"
                                                            />                                                                
                                                        </div>
                                                        <!-- @branchChangeStatus="branchChangeStatus"   -->
                                                        <!-- @updateFullBranch="updateFullBranch"  -->
                                                        <AppPermission :permission="this.$permissions.permissions.remove?? '' ">
                                                        <div class="col-1 detail_permission" v-if="index !== 0 ||  user.sys_user_role.length > 0">
                                                            <a href="#" @click="showModalConfirm(role, user)" class="pt-2">
                                                                {{$t('message.button.remove')}}
                                                            </a>
                                                        </div>
                                                        </AppPermission>
                                                    </div>  
                                                    <!-- <div class="mx-4" style="display:flex; left:3px; top:2px;align-items:center">                                                    
                                                            <span class="toggle" :class="role.statusActive == 1 ? 'active' : ''" @click="changeStatus(role)" :id="'active_' + index"></span>
                                                            <span class="px-2">Cho phép xem thông tin giao dịch của nhân viên khác</span>                                                                 
                                                    </div>                                                    -->
                                                </template>      
                                                                                            
                                                <!-- --------------------------------------------------------------------------------------------------------------------------- -->
                                                <template v-for="(entry, index) in this.entries" :key="entry.id">                                                            
                                                    <div v-if="user.sys_user_role.length <=0 || this.isShow == true">
                                                        <div class="row mx-2 my-4 detail_permission">
                                                            <div class="col-5 pr-0 empTitle">
                                                                <span v-if="index == 0"> 
                                                                    {{ $t('message.common.role') }}
                                                                </span>
                                                                <q-select name="role" :default-options="this.roleId.all" :multiple="false" v-model="entry.roleId" :selectAll="false"  
                                                                :newRole="true" :newRoleLable="$t('message.common.addRole')"  :placeholder="$t('message.common.searchRole')" ref="role"     
                                                                :dataUpdate="{userId:user.id}"                                                                
                                                                @addRole="showModalCreateRole" />                                                                                                                           
                                                            </div>                                                    
                                                            <div class="col-6"> 
                                                                <span v-if="index == 0"> 
                                                                    {{$t('message.common.branch')}}
                                                                </span>                                                        
                                                                <q-select name="departmentId" :default-options="user.branches" :multiple="true" v-model="entry.branchId" :selectAll="true"
                                                                    :dataUpdate="{userId:user.id, roleId: entry.roleId}"  
                                                                    :placeholder="$t('message.common.searchBranch')" ref="branches" @updateFullBranch="updateFullBranch" @branchChangeStatus="changeNewBranch"/>
                                                            </div>
                                                            <div class="col-1 detail_permission" v-if="index !== 0 ||  user.sys_user_role.length > 0">
                                                                <a href="#" @click="delRow(index)" class="pt-2">
                                                                    {{$t('message.button.remove')}}
                                                                </a>
                                                            </div>
                                                        </div>    
                                                            <!-- <div class="mx-4" style="display:flex; left:3px; top:2px;align-items:center">                                                    
                                                            <span class="toggle" :class="entry.statusActive == true ? 'active' : ''" @click="changeEntryStatus(index)" ></span>
                                                            <span class="px-2">Cho phép xem thông tin giao dịch của nhân viên khác</span>                                                                 
                                                        </div>                                                           -->
                                                    </div>
                                                        
                                                </template>
                                                <!-- --------------------------------------------------------------------------------------------------------------------------- -->
                                                <div class="row mx-2 my-4 detail__user">
                                                        <div class="col-12 px-2">
                                                            <button class="btn btn-primary" @click="addRow(user.sys_user_role)">
                                                                <i class="fa fa-plus"></i>
                                                                <span class="px-2">{{$t('message.common.assignRole')}}</span>
                                                            </button>
                                                        </div>
                                                </div>
                                                <div class="row mx-2 my-4 text-right">
                                                        <div class="col-12 px-2">
                                                            <AppPermission :permission="this.$permissions.permissions.create?? '' ">
                                                                <button class="btn btn-primary mx-2" back-url="/employees/index" @click="save(user.id)" v-if="user.sys_user_role.length <= 0">
                                                                    <i class="fa fa-save"></i>
                                                                    {{$t('message.button.save')}}
                                                                </button>  
                                                            </AppPermission>
                                                                <AppPermission :permission="this.$permissions.permissions.update?? '' ">
                                                                <button class="btn btn-primary mx-2" back-url="/employees/index" @click="updateMessage(user)"  v-if="user.sys_user_role.length > 0" >
                                                                    <i class="fa fa-save"></i>
                                                                    {{$t('message.ProductIndex.button.update')}}
                                                                </button>  
                                                            </AppPermission>                   
                                                            <button type="button" class="btn btn-secondary text-white" aria-label="Close" @click="confirmClose">
                                                                <i class="fa fa-ban mr-1" data-v-76ef515c=""></i>
                                                                <span aria-hidden="true">{{$t('message.button.cancel')}}</span>
                                                            </button>                                                         
                                                        </div>
                                                </div>
                                            </td>
                                        </tr>                                
                                    </template>
                                </tbody>
                            </table>
                            <div v-if="noField" class="col-sm-12 text-center my-10" id="notFound">
                                <p> {{$t('message.ProductIndex.no_field')}}</p>
                            </div>
                        </div>
                        <div class="row" v-else id="notFound">
                            <div v-if="!users" class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <p>{{$t('message.common.notData')}}</p>
                            </div>
                            <div v-else class="col-sm-12 text-center my-10 text-secondary align-items-center d-flex justify-content-center">
                                <i class="fa fa-hdd-o mr-2 text-secondary"></i>
                                <p>{{$t('message.common.notFound')}}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ModalRemoveEmployees ref="ModalRemoveEmployees" @inputData="renderData"/>
        <ModalAddInfoEmployee ref="ModalAddInfoEmployee" :action="this.action" @updated="renderData"/>
        <ModalCreateDepartment ref="ModalCreateDepartment" @updated="renderData" />
        <ModalEditDepartment  ref="ModalEditDepartment"  @updated="renderListData"/>
        <ModalAddJobTitle ref="ModalAddJobTitle" @inputData="renderData" :typeEditAdd="typeEditAdd"/>
        <ModalCreateRole ref="ModalCreateRole" @updateRole="getRole" />
        <ModalConfirmPermissions ref="ModalConfirmPermissions" :title="this.title" :message="this.message" @updateRole="update" @deleteRole="deleteRole"/>
        <ModalEditRole ref="ModalEditRole" @updatePermission="updatePermission" />
    </AppPermission>
</template>

<script>
import { $get, $post, $alert, removeVietnameseTones, isEmpty, forEach, clone} from "@/lib/utils";
import Pagination from "@/components/Pagination";
import TableSortField from "@/components/TableSortField";
import emitter from "@/lib/emitter";
import SelectField from "../../components/SelectField";
import ModalRemoveEmployees from '@/components/employees/ModalRemoveEmployees.vue';
import ModalAddInfoEmployee from '@/components/employees/ModalAddInfoEmployees.vue';
import ModalAddJobTitle from "@/components/employees/ModalAddJobTitle";
import ModalCreateDepartment from '../department/ModalCreateDepartment.vue';
import ModalEditDepartment from '../department/ModalEditDepartment.vue';
import http from '@/services/api';
import store from "../../store";
import SelectSearch from "@/components/SelectSearch.vue";
import permissionMixin from '@/permissionMixin';
import ModalCreateRole from '../roles/ModalCreateRole.vue';
import ModalConfirmPermissions from './tabs/ModalConfirmPermissions';
import toastr from 'toastr';
import ModalEditRole from './tabs/ModalEditRole';
import AppPermission from '@/components/AppPermission.vue';
import QSelect from '@/components/QSelectPermission.vue';
// import QSelect from "@/components/QSelect.vue";
export default {
name: "EmployeeIndex",
mixins: [permissionMixin],
components: {
    SelectField,
    Pagination,
    TableSortField,
    ModalRemoveEmployees,
    ModalAddInfoEmployee,
    ModalAddJobTitle,
    ModalCreateDepartment,
    ModalEditDepartment,
    SelectSearch,
    ModalCreateRole,    
    QSelect,
    ModalConfirmPermissions,
    ModalEditRole,AppPermission    
},
data() {
    const globalConfig = store.state.GlobalConfig; 
    return {
        groupKey:[
            {
                roleId: null,
                branchId: []
            }
        ],        
        isBranchChange: false,
        isRoleChange:false,
        isRoleAdd: false,
        isShow: false,
        message: null,
        title: this.$t('message.permissions.titleUpdate'),
        currentBranchId: store.state.CurrentBranch.id, 
        branches:globalConfig.branches,
        statusActive:false,
        userLogin: store.state.Auth.user,      
        users: null,
        isEdit: null,
        // entries:[],
        entries:[
            {
                roleId:null,
                branchId:[],
                statusActive:false,                
            },
        ],
        roles:[
            {
                roleId:null,
                branchId:[],
                statusActive:false,                
            },
        ],
        sortData: {
            field: null,
            direction: null,
        },
        fields: this.employeeFields(),
        paginate: {
            totalRecord: null,
            currentPage: null,
            lastPage: null,
        },
        numberRecord: 15,
        noField: false,
        userIdDetail: null,
        headerActive: 'info',
        typeEditAdd: 'ADD',
        focus: false,
        jobTitle: {
            all: null,
            filter: null,
            search: null,
        },
        jobTitleEditId: null,
        roleId : {
            all: null,
            filter: null,
            search: null,
        },
        branchId : {
            all: null,
            filter: null,
            search: null,
        },
        searchSibar: {
            roleId: null,
            jobTitleId: null,
            branchId: null
        },
        emStatus: {
            all: null,
            filter: null,
            search: null,
        },
        searchHead: null,
        userCode: null,
        dataExist: [
            {
                roleId: null,
                branchId: null,
            }
        ],
        dataPermission:[],
        list:[]

    }
},
methods: { 
    checkPermissionExist(objectToCheck, array) {
        let status = false;
        // Kiểm tra xem đối tượng có trong mảng không
        if (
            array.some(
            (obj) =>
                obj.permissionId === objectToCheck.permissionId &&
                obj.roleId === objectToCheck.roleId &&
                obj.branchId === objectToCheck.branchId &&
                obj.userId === objectToCheck.userId
            )
        ) {
            status = true;
        } else {
            status = false;
        }
        return status;
    },
    branchChangeStatus(data) {  
          
        let dataInsert = null;
        if (data) {
            this.list = [];
            if (!data.branchIds || !data.branchIds.length || !data.dataUpdate) {                
                return;
            }
            if (!data.dataUpdate.permissionsIds[0]) {                
                return;
            }
             
            data.branchIds.forEach((branchId) => {
                data.dataUpdate.permissionsIds[0].forEach(
                    (permissionId) => {
                        dataInsert = {
                            userId: data.dataUpdate.userId ?? null,
                            roleId: data.dataUpdate.roleId ?? null,
                            branchId: branchId,
                            permissionId: permissionId,
                        };
                    if (!this.checkPermissionExist(dataInsert, this.list))  this.list.push(dataInsert);
                    }
                );
            });        
            this.dataPermission[data.dataUpdate.user_role_id] = this.list;
        }              
    },
    updatePermission(newData){            
        if(newData.id){
            if (!this.dataPermission[newData.id]) { this.dataPermission[newData.id] = []; }
            this.dataPermission[newData.id] = newData.data;            
        }           
    },
    async getDataByRole(data, key){               
         const res = await $post('/v1/permissions/getPermission', {roleId: data.roleId, userId: data.userId, branchId: data.branchId});          
         if(res && res.status.code == 200 && res.result.length > 0) {            
            this.dataPermission[key] = [];   
            res.result.forEach(item => {
                item.branchId.forEach(value => {
                    this.dataPermission.push({
                        // user_role_id: data.user_role_id,
                        branchId: value,
                        userId: item.userId,
                        roleId: item.roleId,
                        permissionId: item.permissionId,
                    });
                });
            });
         }          
    },
    async changeData(data){          
        let key = data.dataUpdate.user_role_id;
        this.isRoleChange = true; 
        data.newRoleId.forEach(value => {
             data.dataUpdate.roleId = value
        });               
       this.getDataByRole(data.dataUpdate, key);             
    },
    async changeNewData(data){
        this.isRoleChange = true; 
        data.newRoleId.forEach(value => {
            data.dataUpdate.roleId = value
        });
    },
    async changeNewBranch(data){
        data.dataUpdate.branchId = data.branchIds;
        this.getDataByRole(data.dataUpdate);
    },
    updatePermissionRole(data){         
        let role = {
            name: data.roleName,
            id: data.user_role_id,
        }  
        this.$refs.ModalEditRole.show(role ,data.permissionsIds, data);
    },
    resetForm(){
        this.entries = [
                {
                    roleId:null,
                    branchId:[],
                    statusActive:false,                
                },
            ];
        this.isShow = false;
    },
    getDataExist(data, roleId, branchId){
        let status = false;
        let dem = 0;
        data.forEach(item => {                  
            if(item.roleId == roleId && branchId == item.branchId) {
                dem += 1;
            }            
        });
        if(dem >= 1) status = true;
        return status;
    },
    checkDataExist(data){        
        let status = false
        let dataExist = [];         
        data.forEach(value => {
            status = this.getDataExist(dataExist, value.roleId, value.branchId);
            if( status == false){                
                dataExist.push(value);
                status = false;
            }
            else {                         
                return true;
            }
        });
        return status;
    },
    showModalConfirm(role, user){                       
            this.message = this.$t('message.permissions.contentUpdate', {name: user.name});                                    
            let params = {
                roleId: role.roleId,
                userId: role.userId, 
                branchId: role.branchId,                 
            }             
            this.$refs.ModalConfirmPermissions.show('remove', params);        
    },
    async deleteRole(data){  
        const res = await $post('/v1/permissions/remove', {roleId: data.roleId, userId: data.userId, branchId: data.branchId});      
        if(res && res.status.code == 200 && res.result){            
            toastr.success(this.$t('message.permissions.messageSuccess'));    
            this.getUsers();
            this.getRole();
            this.resetForm();
            this.headerActive = 'info';
        }
        else {
            toastr.error(this.$t('message.permissions.messageError'));    
        }        
    },
    confirmClose(){
        this.getUsers();
        this.getRole();
        this.resetForm();
        this.headerActive = 'info';
    },
    updateMessage(user){         
         this.message = this.$t('message.common.confirmLogoutUser', {name: user.name});
         this.$refs.ModalConfirmPermissions.show('update', user);        
    },
    attachDataForUpdate(data){        
        let insertData = [];
        data.forEach(item => {             
             item.branchId.forEach(value => {
                insertData.push({
                        userId: item.userId,
                        roleId: item.roleId,
                        branchId: value,
                        statusActive: item.statusActive == true ? 1 : 0,
                        createdAt: new Date(),
                        groupKey: item.groupKey,

                })
            });          
        });                
        return insertData; 
    },
    async save(userId){
        let check = false; // not empty
        // let msgError = '';
        let errCount = 0;
        let data = [];
        let strRand = null; 
        if(this.entries.length > 0) {          
            this.entries.forEach((item, index) => {
                check = this.checkDataEmpty(item, index);                
                if(check.status == true) {                    
                    errCount +=1;
                }
                else {                    
                    strRand = (Math.random() + 1).toString(36).substring(7);
                    if(item.branchId){
                        item.branchId.forEach(value => {
                        data.push({
                            branchId: value,
                            userId: userId,
                            roleId: item.roleId,
                            createdAt: new Date(),
                            statusActive: item.statusActive == true ? this.$constant.statusActive : this.$constant.statusInActive,
                            groupKey: strRand
                            })    
                        });
                    }
                }
            });
        }
        // Kiểm tra dữ liệu trước khi phân quyền
        if(errCount > 0){
             toastr.error(this.$t('message.common.emptyBranchRole'));
        }
        else {
            let dataExist = this.checkDataExist(data);        
            if(dataExist) {
                 toastr.error(this.$t('message.common.duplicateBranchRole'));
                 return;
            }
            let res = await $post('/v1/permissions/save', {data:data, userId: userId});            
            if(res && res.status.code == 200 && res.result == true) {           
                toastr.success(this.$t('message.common.addPermissionSuccess'));
                this.getUsers();
                this.getRole();
                this.resetForm();
                this.headerActive = 'info';
            }
            else {            
                if(res && res.result && res.result.code == false) {
                     this.message = res.result.message; 
                     this.$refs.ModalConfirmPermissions.show('add');                
                }
                if(res && res.result == false) {
                    toastr.error(this.$t('message.common.addPermissionFailed'));
                }
            }
        }
    },
    async store(userId){         
        let data = [];
        let strRand = null;
        this.entries.forEach(item => {       
                strRand = (Math.random() + 1).toString(36).substring(7);
                if(item.branchId){
                    item.branchId.forEach(value => {
                    data.push({
                            branchId: value,
                            userId: userId,
                            roleId: item.roleId,
                            createdAt: new Date(),
                            statusActive: item.statusActive == true ? this.$constant.statusActive : this.$constant.statusInActive,
                            groupKey: strRand
                        })    
                    });
                }                    
            });         
        let dataExist = this.checkDataExist(data);        
        if(dataExist) {
             toastr.error(this.$t('message.common.duplicateBranchRole'));
             return;
        }
        await $post('/v1/permissions/save', {data:data}); 
    },
    async update(user){        
        let errCount = 0;       
        emitter.emit("onLoading");       
        let strRand =  null;           
        let lstBranchId = [];
        let data = [];  
        let check = false;        
        if(user.groupRole && user.groupRole.length > 0) {                     
            user.groupRole.forEach((item,index) => {
                check = this.checkDataEmpty(item, index);
                if(check.status == true) {                    
                    errCount +=1;
                }else{
                    strRand = (Math.random() + 1).toString(36).substring(7);                                  
                    if(item.branchId) lstBranchId.push(item.branchId);                
                    data.push({
                        userId: user.id,
                        roleId: item.roleId,
                        branchId: item.branchId,
                        statusActive: item.statusActive == true ? this.$constant.statusActive : this.$constant.statusInActive,
                        createdAt: new Date(),
                        groupKey: strRand                          
                    })
                   
                }

            });
        }       
        else {
            this.isEdit = false;
        }
        data = this.attachDataForUpdate(data);
        // Trường hợp thêm dòng
        if(this.entries.length > 0)
        {   
            this.entries.forEach((item, index) => {
                if(item.branchId.length > 0){
                    check = this.checkDataEmpty(item, index);                
                    if(check.status == true) {
                        errCount +=1;
                    }else {
                        strRand = (Math.random() + 1).toString(36).substring(7);             
                        item.branchId.forEach(value => {                        
                            if(value.length > 0)lstBranchId.push(item.branchId);
                            data.push({
                                branchId: value,
                                userId: user.id,
                                roleId: item.roleId,
                                createdAt: new Date(),
                                statusActive: item.statusActive == true ? this.$constant.statusActive : this.$constant.statusInActive,
                                groupKey: strRand
                            })    
                        });
                    }
                }

            }); 
        }           
        
        if(errCount > 0){
            toastr.error(this.$t('message.common.emptyBranchRole'));
            emitter.emit("offLoading");
            return;
         }else{
             let dataExist = this.checkDataExist(data);
             if(dataExist) {            
                 toastr.error(this.$t('message.common.duplicateBranchRole'));
                 emitter.emit("offLoading");
                 return;
             } 
             else {                                
                 var newArray = this.dataPermission.filter(value => Object.keys(value).length !== 0);
                 const res = await $post('/v1/permissions/update', {
                         branchId: lstBranchId, userId: user.id, oldData: user.groupRoleOld , data:data,  dataPermission: newArray, isRoleChange: this.isRoleChange
                 });  
                 if(res && res.status.code == 200 && res.result == true) {                                               
                     toastr.success(this.$t('message.common.updatePermissionSuccess'));    
                     this.isEdit = false;
                     this.dataPermission = [];
                     this.getUsers();
                     this.getRole();
                     this.resetForm();
                     this.headerActive = 'info';
                     emitter.emit("offLoading");
                 }
                 else {            
                     if(res && res.result && res.result.code == false) {
                         this.message = res.result.message; 
                         this.$refs.ModalConfirmPermissions.show();                
                     }
                     if(res && res.result == false) {
                         toastr.error(this.$t('message.common.updatePermissionFailed'));
                         emitter.emit("offLoading");
                     }
                 }
             }
         }
    },
    checkDataEmpty(item, index){
        let msgError = '';
        let status = false;
        if(item.branchId.length <= 0 && item.roleId == null){    
            msgError = this.$t('message.common.selectBranchRole', {row: index + 1});
            status = true;
        }
        else {                                            
            if(item.branchId.length <= 0){
                msgError = this.$t('message.common.selectBranchPermission', {row: index + 1});
                status = true;
            }                        
            if(item.roleId == null){      
                msgError = this.$t('message.common.selectRolePermission', {row: index + 1});
                status = true;
            }
        }  
        return {
            status: status, // empty == true
            msgError: msgError
        };
    },
    delRow(index){
        this.entries.splice(index,1);
    },
    addRow(dataRole){
        this.isRoleAdd = true;
        let newObject = null;
        if(dataRole.length <= 0 || this.isShow == true){
            newObject =  {
                    roleId:null,
                    branchId:[],
                    statusActive:false,                    
            }
            this.entries.push(newObject);
        }     
            this.isShow = true;            
    },
    changeStatus(role){                     
      this.statusActive = !this.statusActive;
      if(this.statusActive){
        role.statusActive = 1;
      }        
      else {
        role.statusActive = 0; 
      }
    
    },
    changeEntryStatus(index){
        this.entries.forEach((item, inds) => {
            if(inds == index) {
                item.statusActive = !item.statusActive;
            }
        });
    },

    searchUserForJobTitle(jobTitleId, name) {
        this.paginate.currentPage = 1;
        if(jobTitleId === this.searchSibar.jobTitleId){
            jobTitleId = null;
            name= null;
        }
        this.searchSibar.jobTitleId = jobTitleId;
        this.jobTitle.search = name;
        this.getUsers();
    },
    search(value){
        this.emStatus.search =  value;
        this.getUsers();  
    },
    async doFilter(field, value) {
        if(field == 'sttActive'){
            this.emStatus.search = value;
            this.paginate.currentPage = 1;
        }
        this.getUsers();
    },
    reloadData() {
        this.getUsers();
        this.getJobTitle();
    },
    renderData(){
        this.getUsers();
        this.getRole();
        this.getJobTitle();        
    },
    onMouseLeave(){
        emitter.emit("mouseleave");
    },
    async pageChange(page) {
        this.paginate.currentPage = page;
        this.getUsers();
    },
    async onChange(v) {
        this.numberRecord = v.target.value
        this.paginate.currentPage = 1;
        await this.getUsers();
    },
    async sort(field, direction) {
        this.sortData.field = field;
        this.sortData.direction = direction;
        this.getUsers();
    },
    groupByData(data, permissions){        
        let list = [];
        data.forEach(role => {
            let index = list.findIndex(item => (item.roleId == role.roleId && item.groupKey == role.groupKey));
            if (index != -1) {
                    list[index].branchId.push(role.branchId);
            } else {
                let permissionsIds = [];
                permissions.forEach(item => {
                    if (item.branchId == role.branchId && item.roleId == role.roleId) {
                        permissionsIds.push(item.permissionId)
                    }
                });
                let item = {
                    user_role_id: role.id ?? null,
                    userId: role.userId?? null,
                    roleId: role.roleId?? null,                    
                    roleName:role.role?.name?? null,
                    branchId: [role.branchId]?? null, 
                    groupKey: role.groupKey?? null,
                    statusActive: role.statusActive?? null,
                    permissionsIds: permissionsIds,
                }
                list.push(item);                
            }
        });
        
        return list;
    },
    addPermission(role) {   
        this.dataPermission = [];
        role.forEach((rl) => {           
             rl.permissionsIds.forEach(permissionsId => {
                rl.branchId.forEach(branch => {                                                       
                    if(rl.user_role_id){
                        if (!this.dataPermission || !this.dataPermission[rl.user_role_id] ) {
                            this.dataPermission[rl.user_role_id] = [];
                        }                        
                        this.dataPermission[rl.user_role_id].push({                                    
                            userId: rl.userId,
                            roleId: rl.roleId,
                            branchId: branch,
                            permissionId: permissionsId,                        
                            created_at: new Date(),
                            updated_at: new Date(),
                        }); 
                        if (!this.dataPermission || !this.dataPermission[0] ) {
                            this.dataPermission[0] = [];
                        }   
                        if (!this.dataPermission[0].includes(permissionsId)) {
                            this.dataPermission[0].push(permissionsId); 
                        }             
                    }   
                });
            });
        });           
    },
    getBranchList(data, entry){        
        // data.forEach(item => {
            entry.groupRole = this.groupByData(entry.sys_user_role, data);
            let cloneObj = clone(entry);
            entry.groupRoleOld = cloneObj.groupRole ?? null;  
        // });       
    },
    async getUsers() {
        emitter.emit("onLoading");
        const params = {
            page: this.paginate.currentPage,
            field: this.sortData.field,
            direction: this.sortData.direction,
            numberRecord: this.numberRecord,            
            inputSearch: this.searchHead,
            roleId: this.searchSibar.roleId,
            jobTitleId: this.searchSibar.jobTitleId,
            branchId: store.state.CurrentBranch.id,
            status: this.emStatus.search,
            userCode: this.userCode            
        };                    
        const res = await $get('/v1/permissions/data', params);  
        if (res.status.code != 200) {
            $alert({code: 500, message: this.$t('message.common.errorServer')});
            return;
        }               
        this.users = this.getDataUser(res.result.data);    
        // this.getBranchList(this.users);                  
        this.paginate.totalRecord = res.result.total;
        this.paginate.currentPage = res.result.current_page;
        this.paginate.lastPage = res.result.last_page;
        this.numberRecord = res.result.per_page;
        emitter.emit("offLoading");
    },
    sysUserPermission(data){                
        let roleName = '';
        data.forEach(item => {
            if(item.role && item.branchId == this.currentBranchId) 
            {               
                if(roleName == '') roleName = item.role.name;
                else roleName = roleName + ', '  + item.role.name;
            }          
        });         
        return roleName;
    },
    getDataUser(data){      
         if(data.length > 0){ 
            data.forEach(item => {    
                item.roleName = this.sysUserRole(item.sys_user_role, item.user)?? null;
                item.branches = this.getDataBranch(item.user_branch);          
            });                
         }        
        return data;
    },
    
    getDataBranch(data){
        let list = [];
        
        if(data.length){
            data.forEach(item => {
                if(item.branch){
                    list.push(item.branch)
                }
            });
        }       
        return list;
    },
    sysUserRole(data){               
        let roleName = '';
        if(data.length > 0){
            data.forEach(item => {
                if(item.role && item.branchId == this.currentBranchId)             {               
                    if(roleName == '') roleName = item.role.name;
                    else roleName = roleName + ', '  + item.role.name;
                }          
            });
        }
        
        return roleName;
    },
   
    async getJobTitle(){
        const resJobTitle = await $get('/v1/job-title/data');
        this.jobTitle.all = resJobTitle.data.entries;
        this.jobTitle.filter = resJobTitle.data.entries;
        this.jobTitle.all.forEach(jt => {
            if(jt.id === this.jobTitleEditId && this.searchSibar.jobTitleId === jt.id){
                this.jobTitle.search = jt.name
            }
        });
        const foundJobTitle = this.jobTitle.all.find(jt => jt.id === this.searchSibar.jobTitleId);
        if(!foundJobTitle){
            this.jobTitle.search = null;
        }
    },
    showHide(name,iDown, iUp){
        var dropdownContent = document.getElementById(name);
        dropdownContent.classList.toggle("dp-hide");
        var down = document.getElementById(iDown);
        down.classList.toggle("dp-hide");
        var up = document.getElementById(iUp);
        up.classList.toggle("dp-hide");
    },
    showModalAddInfoEmployee () {        
        this.action = this.$t('message.modalCreateEmployee.title_create');        
        this.$refs.ModalAddInfoEmployee.show();            
    },
    showModalUpdateEmployee(id){        
        this.action = this.$t('message.modalCreateEmployee.title_update');
        this.$refs.ModalAddInfoEmployee.show(id);
    },
    showModalRemoveEmployees(id, name) {
            this.$refs.ModalRemoveEmployees.show(id, name);
        },
    renderDataUsers() {
        this.getUsers();
    },
    showDetail(id) {        
        if (id === this.userIdDetail) {
            this.userIdDetail = null;
        } else {
            this.userIdDetail = id;
            this.headerActive = 'info';
        }
    },

    async getPermissionUser(id, entry){
        if(id){
            emitter.emit("onLoading");
            const res = await $post('/v1/permissions/get-permission-by-id', {id: id});    
            if(res && res.result){
                this.getBranchList(res.result, entry); 
            }
            emitter.emit("offLoading");
        }
    },

    async showContent(name, entry) {        
        if (name == 'permissions') {
            if(entry){                
                await this.getPermissionUser(entry.id, entry);
            }
            if(entry.groupRole){
                await this.addPermission(entry.groupRole);
            }
        }
        this.headerActive = name;
    },
    showModalJobTitleAdd(){
        this.typeEditAdd = 'ADD';
        this.$refs.ModalAddJobTitle.show();
    },
    showModalJobTitleEdit(id){
        this.jobTitleEditId = id;
        this.typeEditAdd = 'EDIT';
        this.$refs.ModalAddJobTitle.show(id);
    },
    showModalCreateRole(){        
        this.$refs.ModalCreateRole.show();
    },
    showDropDown(){
        this.focus = true;
    },
    hideDropDown(){
        this.focus = false;
    },
    async getRole() {
        const res = await $post('/v1/roles/get-full-data');    
        if (res.status.code != 200) {
            $alert({code: 500, message: this.$t('message.common.errorServer')});
            return;
        }                 
        this.roleId.all =  res.result.data; 
        this.roleId.filter = res.result.data; 
    },
    searchEmployeeForDepartment(roleId, name) {
        this.paginate.currentPage = 1;
        if(roleId === this.searchSibar.roleId){
            roleId = null;
            name= null;
        }
        this.searchSibar.roleId = roleId;
        this.roleId.search = name;
        // this.showHide('depament','depamentDown','depamentUp');
        this.getUsers();
    },
    
    searchHeadEmployee() {
        this.paginate.currentPage = 1;
        // this.searchHead = event.target.value.trim();
        this.getUsers();
    },

    onInputFilterDepartment(){
        if(this.roleId.search == ''){
            this.roleId.filter = this.roleId.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.roleId.all.length; index++) {
            if (removeVietnameseTones(this.roleId.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.roleId.search.toLowerCase())) > -1){
                arr.push(this.roleId.all[index]);
            }
        }
        this.roleId.filter = arr;

    },
    onInputFilterPayrollBranch(){
        if(this.payrollBranch.search == ''){
            this.payrollBranch.filter = this.payrollBranch.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.payrollBranch.all.length; index++) {
            if (removeVietnameseTones(this.payrollBranch.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.payrollBranch.search.toLowerCase())) > -1){
                arr.push(this.payrollBranch.all[index]);
            }
        }
        this.payrollBranch.filter = arr;
    },
    onInputFilterJobTitle(){
        if(this.jobTitle.search == ''){
            this.jobTitle.filter = this.jobTitle.all;
            return
        }
        let arr = [];
        for (let index = 0; index < this.jobTitle.all.length; index++) {
            if (removeVietnameseTones(this.jobTitle.all[index].name.toLowerCase()).indexOf(removeVietnameseTones(this.jobTitle.search.toLowerCase())) > -1){
                arr.push(this.jobTitle.all[index]);
            }
        }
        this.jobTitle.filter = arr;

    },
    showModalCreateDepartment() {
        this.$refs.ModalCreateDepartment.show()
    },
    showModalEditDepartment(id) {
        this.$refs.ModalEditDepartment.show(id);
    },
    renderListDataCreate(newData) {
        this.roleId.filter.unshift(newData.data);
    },
    renderListData(newDepartment) {
        this.roleId.filter = this.roleId.filter.map(department => department.id != newDepartment.id ? newDepartment : newDepartment);
    },

    clearFilter() {
        this.searchSibar.roleId = null;
        this.searchSibar.jobTitleId = null;        
        this.roleId.search = null;        
        document.getElementById('search-header').value = null;
        this.jobTitle.search = null;
        this.emStatus.search = null;
        this.sortData.field = null,
        this.sortData.direction = null,
        this.searchHead = null,        
        this.roleId.filter = this.roleId.all,
        this.jobTitle.filter = this.jobTitle.all,
        this.paginate.currentPage = 1,
        this.numberRecord = 15;
        this.getUsers();
        emitter.emit("clearSelectSearchValue");
    },   
    clearValue(options){
        switch (options) {
            case 'jobTitle':
                this.jobTitle.search = '';
                this.onInputFilterJobTitle();
                this.searchSibar.jobTitleId = '';
                this.getUsers();
                break;
            case 'roleId':
                this.roleId.search = '';
                this.onInputFilterDepartment();
                this.searchSibar.roleId = '';
                this.getUsers();
                break;
        }
    },
    async loadDetails(){
        this.searchSibar.roleId = null;
        this.searchSibar.jobTitleId = null;        
        this.roleId.search = null;        
        document.getElementById('search-header').value = null;
        this.jobTitle.search = null;
        this.emStatus.search = null;
        this.sortData.field = null,
        this.sortData.direction = null,
        this.searchHead = null,        
        this.roleId.filter = this.roleId.all,
        this.jobTitle.filter = this.jobTitle.all,
        this.paginate.currentPage = 1,
        this.numberRecord = 15;
        this.userIdDetail = null;
        emitter.emit("clearSelectSearchValue");
        await this.getUsers();
        this.users.forEach((entry) => {                      
            this.showDetail(entry.id);
            
        });
    }
},

created () {
    // this.getUsers();
    this.getRole();
    this.getJobTitle();    
    emitter.on("deleted", () => {
           location.reload();
        });
    emitter.on("reloadDataEmployee", () => {
        this.renderData();
    });
},
mounted() {
    document.title = 'Phân quyền';
    const query = this.$route.query;
    if(query['code'] != null){
        this.userCode = query['code'];
        this.loadDetails();
    }else{
        this.clearFilter();
    }
},

watch: {
    fields: {
        handler(newValue){
            let noField = true;
            for (let index = 0; index < newValue.length; index++) {
                if (newValue[index]?.selected) {
                    noField = false;
                }
            }
            this.noField = noField;
        },
        deep: true
    }
},
computed:{
},
}
</script>
<style scoped>
.custom-switch {
    display: flex;
    align-items: center;
}
.container input[type="radio"] {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
#typeCard {
  margin-top: -8px;
}
#number_records {
  margin-top: 11px;
}
.detail__user {
    max-width: 1400px;
   
}
.detail_permission {
    max-width: 1400px;
    display: flex;
    align-items: center;
}
.no-boder {
    border-top: none !important;
    padding-bottom: 0 !important;
}

/* .backgroup__detail {   
    background: #B1DDF0 !important;    
} */

.head-detail {
    min-width: 100px;
    display: block;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.backgroup__detail-active {
    background: #fff !important;
}

.border-left {
    border-left: 1px solid #D9D9D9;
}
.note-detail {
    margin-left: 20px;
    border: none;
    overflow: auto;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize:none;
    width: 400px;
}

.label__note {
  color: #C7C5C5;
  padding: 10px;
}

.detail-row-img {
    width: 8vw;
}

.dropdown-item>i{
  display: none;
  position: absolute;
  left: 0.5rem;
}
.dropdown-item:hover > i{
  display: inline-block;
}
.dropdown-toggle{
  white-space: normal;
}
.dropdown-toggle::after{
  position: absolute;
  left: 1.5rem;
  top: 0.75rem;
}
.dropdown-item{
  white-space: normal;
  overflow-wrap: break-word;
}
.dropdownJobTitle{
    max-height: 250px;
}
.list__departments {
    max-height: 300px !important;
    overflow-y: scroll;
    z-index: 1000;
    position: absolute;
    top: 36px;
    width: 220px;
    left: 24px;
    box-shadow: 1px 2px 4px #bbb;
}
.img-main-item {
    width: 10vw;
    object-fit: cover;
}
.block__depament {
    position: relative;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}

/* Toogle:  */
.toggle {
    background-color: rgba(31,32,33,.15);
    position: relative;
    display: block;
    cursor: pointer;
    border-radius: 14px;
    width: 51px;
    height: 28px;
    transition: all .2s ease-in-out;
}
.toggle.active {
    background-color: rgb(47, 161, 161);
}
.toggle:before {
    content: "";
    background-color: #fff;
    border-radius: 999px;
    width: 24px;
    height: 24px;
    position: absolute;
    left: 2px;
    top: 2px;
    transition: all .2s ease-in-out;
}
.toggle.active:before {
    left: auto;
    right: 2px;
    transition: all .2s ease-in-out;
}
.add-q-btn {
        position: absolute;
        top: 20px;
        right: 6px;
 }
</style>
