<template>
  <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" id="modalRemoveCustomer">
    <div class="modal-dialog modal-lg" role="document" style="width: 700px">
      <div class="modal-content">
        <div class="modal-header py-1 mx-4">

          <h5 class="modal-title py-2">
            {{ $t('message.SupplierGroupIndex.confirm') }}
          </h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body mx-4" >
          <h6>{{this.content}}</h6>
          <div class="col-12 d-flex justify-content-end mt-3">
            <button class="btn btn-danger mr-2" @click="deleteData()">
                <i class="fa fa-check mr-1"></i>
                {{ $t('message.button.confirm') }}
            </button>
            <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                <i class="fa fa-ban mr-1"></i>
                {{ $t('message.button.skip') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {$alert, $post} from "@/lib/utils";

export default {
  name: "ModalStopSuppliers",
  props: {},
  data () {
    return {
      data: [],
      status: '',
      content: '',
    };
  },
  methods: {
    async show(data, status) {
      this.data = [data];
      this.status = status;
      if(status == 'stop'){
        this.content = this.$t('message.SupplierGroupIndex.contentInActive');
      }else if(status == 'reActive'){
        this.content = this.$t('message.SupplierGroupIndex.contentReActive');
      }
      window.jQuery(this.$refs.modal).modal('show');
    },

    async deleteData(){
      const res = await $post('/v1/suppliers/Stopworking', { id: this.data, status: this.status});
      if(res.code === 200){
        $alert(res);
        this.$emit('inputData','1');
        window.jQuery(this.$refs.modal).modal('hide');
      }
    },
  }
}

</script>

<style scoped>

</style>