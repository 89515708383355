<template>
    <div class="date-time-picker w-100 position-relative">
        <input type="date" v-model="this.date" class="form-control inputDatetime">
        <span class="label__time pl-0"><input ref="input" v-on:blur="onInput($event)" v-model="this.time" class="w-100"></span>
        <i class="fas fa-clock ml-2 text-secondary" data-toggle="dropdown"></i>
        <ul class="dropdown-menu format__list-time">
            <template  v-for="(n, i) in 24" :key="i">
                <li class="pl-3 pb-2" @click="timeChange(('0' + i ).slice(-2) + ':00')">{{ ('0' + i ).slice(-2) + ':00' }}</li> 
                <li class="pl-3 pb-2" @click="timeChange(('0' + i ).slice(-2) + ':30')">{{ ('0' + i ).slice(-2) + ':30' }}</li>
            </template>
        </ul>
    </div>
</template>

<script>
import moment from 'moment';
import {$alert} from "@/lib/utils";
export default {
    name: "InputDateTimePicker",
    props: ['modelValue'],
    data(){
        return{
            model: this.modelValue,
            date: null,
            time: null,
        }
    },
    methods: {
        formatData(){
            this.date = this.model ? moment(this.model).format('YYYY-MM-DD') : null;
            this.time = this.model ? moment(this.model).format('HH:mm') : null;
        },
        timeChange(time){
            this.time = time;
        },
        onInput(){
            var isValid = /^(?:[01][0-9]|2[0-3]):[0-5][0-9](?::[0-5][0-9])?$/.test(this.time)
            if (isValid === false) {
                this.time = moment().format('HH:mm');
                $alert({code: 500, message: this.$t('message.common.inValidFormatTime')});
            }
        },  
    },
    created(){
        this.formatData();
    },
    watch: {
        date: {                
            handler(newDate){                          
                this.model = newDate + ' ' + this.time;
                this.$emit('update:modelValue', this.model);
            },
            deep: true
        },
        time: {                
            handler(newTime){                          
                this.model = this.date + ' ' + newTime;
                this.$emit('update:modelValue', this.model);
            },
            deep: true
        },
    }
}
</script>

<style scoped lang="scss">
.fa-clock {
    position: absolute;
    right: 5px;
    top: 10px;
    font: normal normal normal 14px/1;
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.label__time{
    width: 36px !important;
    top: 0.35rem !important;
    background: transparent;
    @media (max-width: 1600px) {
       right: 2rem !important;
    }
    @media (min-width: 1601px) {
       right: 5rem !important;
    }
}
.inputDatetime{
    @media (max-width: 1600px) {
       padding-right: 1rem !important;
    }
    @media (min-width: 1601px) {
        padding-right: 1.5rem !important;
    }
}
.label__time input{
    border: none;
    outline: none;
}
.format__list-time{
    position: absolute;
    will-change: transform;
    width: 100% !important;
    height: 200px !important;
    top: 0px;
    left: 0px;
    transform: translate3d(150px, 23px, 0px) !important;
    background-color: #fff;
}
</style>
