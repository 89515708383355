<template>
    <div ref="modal" class="modal mx-0 fade modal-second" tabindex="-1" role="dialog" id="modalRemoveCustomer">
        <div class="modal-dialog modal-lg" role="document" style="width: 700px">
            <div class="modal-content">
                <div class="modal-header py-1 mx-4 head-remove">
                    <h5 class="modal-title py-2"> {{ $t('message.PlanIndex.modal.title') }}</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body mx-4">
                    <template v-if="type == this.$constant.cancelPromotionWarning && !this.multiple">
                        {{ $t('message.PlanPurchaseIndex.modal.content_cancel') + ' ' + this.code + ' ' + '?' }}  
                    </template>
                    <template v-else-if="type == this.$constant.cancelPromotionWarning && this.multiple">
                        {{ $t('message.PlanPurchaseIndex.modal.content_cancel_multiple') }} 
                    </template>
                    <template v-else-if="type == this.$constant.delete && !this.multiple">
                        {{ $t('message.PlanPurchaseIndex.modal.content_delete') + ' ' + this.code + ' ' + '?' }}
                    </template>
                    <template v-else>
                        {{ $t('message.PlanPurchaseIndex.modal.content_delete_multiple') }}
                    </template>
                    
                    <div class="col-12 d-flex justify-content-end">
                        <button class="btn btn-danger mr-2" @click="cancel()" v-if="type == this.$constant.cancelPromotionWarning">
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.PlanIndex.modal.confirm') }}
                        </button>
                        <button class="btn btn-danger mr-2" @click="deleted()" v-else>
                            <i class="fa fa-check mr-1"></i>
                            {{ $t('message.PlanIndex.modal.confirm') }}
                        </button>
                        <button class="btn btn-secondary text-white" data-dismiss="modal" aria-label="Close">
                            <i class="fa fa-ban mr-1"></i>
                            {{ $t('message.PlanIndex.modal.nextStep') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { $post } from "@/lib/utils";
import toastr from 'toastr';
export default {
    name: 'ModalCancelOrDelete',
    props: {},
    data() {
        return {
            id: null,
            code: null,
            type: null,
            multiple: null,
            ids: []
        };
    },
    methods: {
        show(entry, type, multiple = null, ids = []) {
            this.multiple = multiple;
            if (this.multiple) {
                this.ids = ids;
            } else {
                this.id = entry ? entry.id : null;
                this.code = entry ? entry.code : null;
            }
            this.type = type;
           
            window.jQuery(this.$refs.modal).modal('show');
        },
        async cancel() {
            let result = [];
            if (this.multiple) {
                result = await $post('/v1/plan-purchases/cancel', {ids: this.ids})
            } else {
                result = await $post('/v1/plan-purchases/cancel', {id: this.id});
            }
            if (result.result.code == 404) {
                toastr.error('Không tìm thấy phiếu');
            } else if (result.result.code == 422) {
                toastr.error(result.result.message);
            } else {
                toastr.success(this.$t('message.PlanIndex.modal.success'));
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('updated', '');
            }
        },
        async deleted() {
            let result = [];
            if (this.multiple) {
                result = await $post('/v1/plan-purchases/delete', { ids: this.ids });
            } else {
                result = await $post('/v1/plan-purchases/delete', { id: this.id });
            }
            if (result.result.code == 404) {
                toastr.error('Không tìm thấy phiếu');
            } else if (result.result.code == 422) {
                toastr.error(result.result.message);
            } else {
                toastr.success(this.$t('message.PlanIndex.modal.success_delete'));
                window.jQuery(this.$refs.modal).modal('hide');
                this.$emit('updated', '');
            }
        }
    }
}
</script>
<style>
.head-remove {
    border: none !important;
}
</style>