import {createApp} from 'vue';
import App from './App.vue'
import router from "@/router/index";
import appMixin from "@/mixin";
import {$post} from "@/lib/utils";
import store from "./store";
import VueHtmlToPaper from "./lib/VueHtmlToPaper";
import filters from '@/filters/index';
// @ts-ignore
import excel from 'vue-excel-export';
import Constant from '@/configs/constant';
import Permissions from '@/configs/permissions';
// @ts-ignore
import CKEditor from '@mayasabha/ckeditor4-vue3';

import i18n from '@/setup/i18n.js';

(async () => {
    let res;
    try {
        if (location.pathname !== '/xadmin/login') {
            res = await $post('/v1/configs/index', {} , false);            
            if (res.code === 200) {
                store.commit('SetGlobalConfig', res.data);
                store.commit('SetPermissionInfo', res.data.permissionInfo)
            } else if (res.code === 3) {
                store.commit('RemoveAuth');
                location.replace('/xadmin/login')
                return;
            } else {
                alert(res.message);
            }
        }

    } catch (err) {
        console.error(err);
    }

    const app = createApp({
        extends: App,
    });
    app.mixin({
        methods: appMixin.methods
    });
    app.config.globalProperties.$constant = Constant;
    app.config.globalProperties.$permissions = Permissions;
    
    const clickOutside = {
        beforeMount: (el: any, binding: any) => {
            el.clickOutsideEvent = (event: any) => {
                // here I check that click was outside the el and his children
                if (!(el == event.target || el.contains(event.target))) {
                    // and if it did, call method provided in attribute value
                    binding.value();
                }
            };
            document.addEventListener("click", el.clickOutsideEvent);
        },
        unmounted: (el: any) => {
            document.removeEventListener("click", el.clickOutsideEvent);
        },
    };
    app.directive("click-outside", clickOutside)
    app.use(store);
    app.config.globalProperties.$filters = filters;
    app.config.globalProperties.$store = store;
    app.use(VueHtmlToPaper);
    app.use(excel);
    app.use(router)
    app.use(CKEditor)
    app.use(i18n)
        .mount('#app');
})();

