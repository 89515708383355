<template>
    <div class="container-fluid">
        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/permissions/index" class="btn btn-default">
                        Quay lại
                    </router-link>

                    <button class="btn btn-save" type="button" @click="save()">Lưu lại</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12">
                <div class="main-title">
                    <h1 v-text="entry.id ? 'Chỉnh sửa quyền: ' + entry.displayName : 'Thêm mới quyền'"> </h1>

                </div>
                <div class="row">
                    <div class="col-lg-6 col-sm-12">
                        <input v-model="entry.id" type="hidden" name="id">
                        <div class="form-group">
                            <label>Tên hiển thị</label>
                            <input  id="f_display_name" v-model="entry.displayName" name="display_name" class="form-control"
                                   placeholder="Tên hiển thị">
                            <error-label for="f_display_name" :errors="errors.displayName"></error-label>

                        </div>


                        <div class="form-group">
                            <label>Màn hình cha</label>
                            <QSelect v-model="entry.parentId" datasource="parentPermissions" readonly="true"/>
                            <error-label for="f_name" :errors="errors.parentId"></error-label>

                        </div>

                    </div>
                    <div class="col-lg-6 col-sm-12">
<!--                        <div class="form-group">-->
<!--                            <label>Class</label>-->
<!--                            <input readonly  id="f_class" v-model="entry.class" name="name" class="form-control"-->
<!--                                   placeholder="class">-->
<!--                            <error-label for="f_class" :errors="errors.class"></error-label>-->

<!--                        </div>-->
                        <div class="form-group">
                            <label>Tên</label>
                            <input id="f_name" v-model="entry.name" name="name" class="form-control"
                                   placeholder="name" readonly>
                            <error-label for="f_name" :errors="errors.name"></error-label>

                        </div>
                        <div class="form-group">
                            <label>Action</label>
                            <input id="f_action" v-model="entry.action" name="name" class="form-control"
                                   placeholder="action" readonly>
                            <error-label for="f_action" :errors="errors.action"></error-label>

                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>

</template>

<script>
    import {$post, $get} from "@/lib/utils";
    import toastr from 'toastr';
    import ErrorLabel from "../../components/ErrorLabel";
    import QSelect from "../../components/QSelect";

    export default {
        name: "PermissionsForm",
        components: {
            ErrorLabel, QSelect
        },
        data() {
            return {
                entry: {},
                isLoading: false,
                errors: {}
            }
        },
        mounted() {
            if (this.$route.query.id) {
                this.load();
            }
        },
        methods: {
            async load() {
                const res = await $get('/v1/permissions/show', {
                    id: this.$route.query.id
                });

                if (res.code === 404) {

                    location.replace('/404');
                    return;
                }

                this.entry = res.entry;
            },
            async save() {
                this.isLoading = true;
                const res = await $post('/v1/permissions/save', {entry: this.entry});
                this.isLoading = false;
                if (res.errors) {
                    this.errors = res.errors;
                    return;
                }
                if (res.code) {
                    toastr.error(res.message);
                } else {
                    this.errors = {};
                    toastr.success(res.message);

                    if (!this.entry.id) {
                        location.replace('/v1/permissions/form?id=' + res.id);
                    }

                }
            }
        }
    }
</script>

<style scoped>

</style>
