<template>
    <div class="" v-click-outside="hideDropDown" :class="[(disabled ? 'cursor-ban' : ''), (border ? 'border rounded p-1' : ''), (this.locationStyle == true ? 'prd-search-box2' : 'prd-search-box1')]">
        <form class="w-100" data-toggle="dropdown" aria-expanded="false" v-if="!disabled" @submit.prevent="null">
            <input type="text" :placeholder="placeholder" :title="title ? options.search : null" v-model="options.search" @input="onInputFilter" @click="showDropDown" :readonly="readonly" class="bg-transparent text-wrap input-select-search">
        </form>
        <form class="w-100" @submit.prevent="null" v-else>
            <input type="text" :placeholder="placeholder" :title="title ? options.search : null" v-model="options.search" @input="onInputFilter" @click="showDropDown" :readonly="readonly" :disabled="disabled" class="bg-transparent text-wrap input-select-search">
        </form>
        <i v-if="this.locationStyle == true" class="fa fa-sort-down" aria-hidden="true" style="position:absolute; right:25px; top:8px"></i> 
        <button v-if="!this.action" type="button" class="border-none bg-transparent btn-icon" v-show="options.search && !disabled && !readonly" @click.stop="clearValue()"
        :class="this.locationStyle ? 'prd-btn-icon' : '' ">
            <i  class="fa fa-times text-danger pr-1" ></i>              
        </button>
        <button v-if="this.readonly && !this.disabled" type="button" class="border-none bg-transparent btn-icon icon-arrow" :class="active ? 'active' : ''">            
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362" ><path d="M286.935 69.377c-3.614-3.617-7.898-5.424-12.848-5.424H18.274c-4.952 0-9.233 1.807-12.85 5.424C1.807 72.998 0 77.279 0 82.228c0 4.948 1.807 9.229 5.424 12.847l127.907 127.907c3.621 3.617 7.902 5.428 12.85 5.428s9.233-1.811 12.847-5.428L286.935 95.074c3.613-3.617 5.427-7.898 5.427-12.847 0-4.948-1.814-9.229-5.427-12.85z"></path></svg>
        </button>
        <div class="dropdown-menu w-100" :class="this.actionStyle == true ? 'dropdown-menu-content-plans' : 'dropdown-menu-content'" :id="!this.disabled ? 'dropdownContent' + this.label : ''">            
            <template v-if="this.options.filter?.length > 0">
                <a class="dropdown-item text-wrap py-2" href="javascript:;" v-for="o in this.options.filter" :key="o.id" :value="o.id" @click="chooseOption(o)" :class="this.model === o.id ? 'text-bold' :''">
                    {{o.detail ? o.detail : (o.label ? o.label : null)}}
                    <i class="fa fa-check" v-if="this.model == o.id"></i>
                </a>
               
            </template>
            <!-- <template v-else>
                <a class="dropdown-item" href="javascript:;">
                    Không có kết quả phù hợp
                </a>
            </template> -->
            <button type="button" class="bg-transparent btn-add w-100 p-1 border-none" @click="openModal">
                <div class="w-100 border p-1">
                    + {{$t('message.ModalCreateUpdatePickup.title')}}
                </div>
            </button>
        </div>
    </div>
    <ModalCreateUpdatePickup ref="modalCreateUpdatePickup" @updated="updateValue"></ModalCreateUpdatePickup>
</template>

<script>
import {removeVietnameseTones} from "@/lib/utils";
import ModalCreateUpdatePickup from "@/views/pickupaddress/ModalCreateUpdate";
import emitter from "@/lib/emitter";
export default {
    name: "SelectPickupAddress",
    components: {
        ModalCreateUpdatePickup
    },
    props:  ['placeholder', 'modelValue', 'defaultOption', 'label', 'disabled', 'action', 'readonly', 'actionStyle', 'locationStyle', 'border', 'title', 'defaultValue'],
    methods: {
        hideDropDown(){
            this.active = false;
        },
        showDropDown(){     
            this.active = true;
            // var dropDown = document.getElementById('dropdownContent' + this.label);
            // if(dropDown){
            //     dropDown.classList.add('d-block');
            // }
        },
        clearValue(){            
            if (!this.disabled) {
                this.model = null;
                this.options.search = null;
                this.options.filter = this.options.all;
                this.optionsName = null;
                this.$emit('update:modelValue', {model: this.model, label: this.label ?? null});
                this.$emit('getDataCustomer', null);
            }
            this.hideDropDown();
        },  
        chooseOption(o){     
            this.model = o.id ?? null;
            this.options.search = o.detail ? o.detail : (o.label ? o.label : null);
            this.options.search = o.detail ? o.detail : (o.label ? o.label : null);
            this.optionsName = o.detail ? o.detail : (o.label ? o.label : null);

            this.$emit('loadPrintTemplateId', o.id, o.detail);
            this.$emit('loadCusGroupId', o.id);
            this.$emit('loadCustomerId', o.id);
            this.$emit('loadPaymentMethod', o.id);
            this.$emit('update:modelValue', {model: this.model, label: this.label ?? null, optionsName: this.optionsName ?? null, currentOption: o});
            this.$emit('getDataCustomer', o);
            this.hideDropDown();
        },
        setValue(){            
            if (this.options.filter) {
                for (let index = 0; index < this.options.filter.length; index++) {
                    const element = this.options.filter[index];
                    if(element.id == this.model){
                        this.options.search = element.name ? element.name : (element.label ? element.label : (element.detail ? element.detail : null));
                        return;
                    }
                }
                this.options.search = null;
            }
        },
        onInputFilter(){            
            this.showDropDown();
            if(!this.options.search){
                this.options.filter = this.options.all;
                this.clearValue();
                return;
            }
            let arr = [];
            for (let index = 0; index < this.options.all.length; index++) {
                if (this.options.all[index]?.name && removeVietnameseTones(this.options.all[index]?.name.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }else if(this.options.all[index]?.label && removeVietnameseTones(this.options.all[index]?.label.toLowerCase()).indexOf(removeVietnameseTones(this.options.search.toLowerCase())) > -1){
                    arr.push(this.options.all[index]);
                }
            }
            this.options.filter = arr;
        },
        openModal(){
            this.$refs.modalCreateUpdatePickup.show(null, this.$constant.addressBranchCreate);
        },
        updateValue() {
            this.$emit('reloadData');
        }
    },
    watch: {
        modelValue: {                
            handler(newModelValue){                          
                this.model = newModelValue;
                this.setValue();
                
            },        
            deep: true,
            
        },
        defaultOption: {                
            handler(newDefaultOption){                        
                this.options.filter = this.options.all = newDefaultOption;                
                this.setValue();
               
            },
            deep: true
        }, 
        defaultValue(newValue) {
            this.model = newValue;
            this.setValue();
        }       
    },
    data() {
        return {
            model: this.modelValue ?? null,
            value: null,
            options: {
                filter: this.defaultOption ?? [],
                all: this.defaultOption ?? [],
                search: '',
            },
            active: false,
        }
    },
    created(){
        emitter.on("clearSelectSearchValue", () => {
            this.options.search = '';
            this.model = null;
        });
        if(this.defaultValue){
            this.model = this.defaultValue;
            this.setValue();
        }
    },

    mounted(){
        this.setValue(); 
    }
}
</script>

<style scoped>
.dropdown-menu-content{
    width: 100vh;
    max-height: 20rem;
    overflow: auto;
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
}
.dropdown-menu-content-plans{
    margin-top: 6px ;
    width: 100vh;
    max-height: 20rem;
    max-width: 94.5%;
    overflow: auto;
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
}
.locationStyle{
    margin-top: 6px ;
    width: 95vh;
    max-height: 20rem;
    max-width: 94%;
    overflow: auto;
    z-index: 1002;
    /* transform: translate3d(15px, 0px, 0px) !important; */
    background: white;
}
.btn-icon:focus{
    outline: none;
    border: none;
    border-radius: 50%;
    background: #F2F2F2 !important;
}
.btn-add:focus{
    outline: none;
    border: none;
}
input[type = 'text']{
    padding-left: 10px;
    padding-top: 2px;
}
.disabled{
    background-color: #e9ecef;
}
.dropdown-item>i{
  color: rgb(47, 161, 161);
  position: absolute;
  right: 0.5rem;
}
.icon-arrow{
    background-color: none;
}
.icon-arrow svg{
    fill: #212529;
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
}
.active > svg{
    transform: rotate(180deg);
}
.icon-arrow svg{
    width: 9px;
    height: 9px;
    fill: #666
}
.input-select-search{
    cursor: pointer;
}
.cursor-ban .input-select-search{
    cursor: not-allowed !important;
    pointer-events: all !important;
}
.prd-search-box1:active, .prd-search-box1:hover{
    border-bottom: 1px solid #ccc !important;
}
.prd-search-box2:active, .prd-search-box2:hover{
    border-bottom: 0px solid #ccc !important;
}
.prd-btn-icon{
    top: 7px !important;
    position: absolute !important;
    right: 20px !important;
}
</style>
