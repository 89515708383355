<template v-show="this.show"> 
<div class="row">
    <div class="col-12">
        <div v-html="this.data" class="px-2 pb-5 view-show d-none" style="height:750px;" id="productBarcode"></div>
    </div>
</div>         
</template>
<script>                
    export default {
        name: "PreviewTemplate",    
        components: {},   
        props: {
            editorDataClone: null         
        } ,
        data() {
            return {
                data:[],
                show: false,                                                 
            }
        },
        created(){            

        },
        watch: {
            editorDataClone(newData){
                this.data = newData;
            }
        },
        methods: {
            multiplePrint(data = null) {                 
                this.data = data;              
                this.show = true;                
                setTimeout(() => {
                    this.$htmlToPaper('productBarcode', {
                        name: '_blank',
                        specs: [
                            'fullscreen=yes',
                            'titlebar=yes',
                            'scrollbars=yes'
                        ],
                        autoClose: true,
                        windowTitle: window.document.title,
                        styles: [
                            '/assets/css/barcode.css'
                        ]
                    }, () => {
                        this.show = false;
                    });
                }, 500);
            },
        },
        mounted(){     
            
        }
    }
</script>