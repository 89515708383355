<template>
    <div v-if="isDataLoaded">
        <div class="content">
            <div class="row">
                <div class="col-lg-4">
                    <InputText v-model="entry.code"
                               :errors="errors.code"
                               :readonly="true"
                               label="Mã hóa đơn"/>

                    <InputDatePicker v-model="entry.purchaseDate"
                                     :errors="errors.purchaseDate"
                                     :timepicker="true"
                                     :readonly="isCancel"
                                     label="Thời gian"/>

                    <div class="form-group">
                        <label>{{$t('message.headers.customers')}}</label>
                        <div class="form-control" v-text="entry.customer ? entry.customer.name : ''" readonly></div>
                    </div>

                    <div class="form-group">
                        <label>Mã đặt hàng</label>
                        <div class="form-control" v-text="entry.order ? entry.order.code : ''" readonly></div>
                    </div>

                    <InputText v-model="entry.orderCrmCode"
                               :errors="errors.orderCrmCode"
                               label="Mã hàng CRM"/>
                </div>
                <div class="col-lg-4">
                    <div class="row">
                        <InputText :readonly="true"
                                   v-model="entry.statusValue"
                                   class="col-lg-6"
                                   label="Trạng thái"></InputText>

                        <InputSelect v-model="entry.isPack"
                                     :errors="errors.isPack"
                                     :options="listStatusPack"
                                     class="col-lg-6"
                                     label="Trạng thái đóng gói"/>
                    </div>

                    <div class="form-group">
                        <label>{{$t('message.common.branch')}}</label>
                        <div class="form-control" v-text="entry.branch ? entry.branch.name : ''" readonly></div>
                    </div>

                    <InputSelect v-model="entry.soldById"
                                 :options="branchAdmins"
                                 :errors="errors.soldById"
                                 :readonly="isCancel"
                                 label="Người bán"/>

                    <InputText v-model="entry.createdByName"
                               :errors="errors.createdByName"
                               :readonly="true"
                               label="Người tạo"/>
                    <InputSelect v-model="entry.saleChannelId"
                                 :errors="errors.saleChannelId"
                                 :options="saleChannels"
                                 :readonly="isCancel"
                                 label="Kênh bán"/>
                </div>
                <div class="col-lg-4" style="border-left: 1px solid">
                    <InputTextArea v-model="entry.description"
                                   :errors="errors.description"
                                   :readonly="isCancel"
                                   height="378px"
                                   :label="$t('message.ProductIndex.note')"/>
                </div>
            </div>

            <div class="row" v-if="delivery">
                <div class="col-lg-12 mt-4 mb-4" style="background: #E8E8E8">
                    <div style="margin: 15px 0; font-weight: 600; font-size: 18px">
                        <span>{{$t('message.InvoiceIndex.tabs.delivery.address_receive')}}: </span>
                        <span v-text="delivery.decode"></span>
                    </div>
                    <div class="row">
                        <div class="col-lg-4">
                            <InputText v-model="delivery.receiverName"
                                       :errors="deliveryErrors.receiverName"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.InvoiceIndex.tabs.delivery.receive_by')"/>

                            <InputText v-model="delivery.receiverPhone"
                                       :errors="deliveryErrors.receiverPhone"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.InvoiceIndex.tabs.delivery.contact_number')"/>

                            <InputText v-model="delivery.receiverAddress"
                                       :errors="deliveryErrors.receiverAddress"
                                       :readonly="isCancel || isComplete"
                                       label="Địa chỉ"/>

                            <InputLocationPicker v-model="receiverLocation"
                                                 :readonly="isCancel || isComplete"
                            ></InputLocationPicker>
                        </div>
                        <div class="col-lg-4">
                            <InputText v-model="delivery.code"
                                       :errors="deliveryErrors.code"
                                       :readonly="isCancel || isComplete"
                                       :label="$t('message.orderFromTab.delivery_code')"/>

                            <InputSelect v-model="delivery.status"
                                         label="Trạng thái giao"
                                         :options="listDeliveryStatus"
                                         :errors="deliveryErrors.status"
                            ></InputSelect>

                            <div class="form-group mb-0">
                                <label>{{$t('message.InvoiceIndex.tabs.delivery.weight')}}</label>
                                <div class="row">
                                    <div class="col-sm-6">
                                        <InputText v-model="delivery.weight"
                                                   :errors="deliveryErrors.weight"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.InvoiceIndex.tabs.delivery.weight')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputSelect v-model="delivery.weightUnit"
                                                     :readonly="isCancel || isComplete"
                                                     :options="listWeightUnit"
                                        ></InputSelect>
                                    </div>
                                </div>
                            </div>

                            <div class="form-group mb-0">
                                <label>{{$t('message.InvoiceIndex.tabs.delivery.size')}}</label>
                                <div class="row">
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.long"
                                                   :errors="deliveryErrors.long"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.long')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.width"
                                                   :errors="deliveryErrors.width"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.width')"
                                        ></InputText>
                                    </div>
                                    <div class="col-sm-4">
                                        <InputText v-model="delivery.height"
                                                   :errors="deliveryErrors.height"
                                                   :readonly="isCancel || isComplete"
                                                   :placeholder="$t('message.common.height')"
                                        ></InputText>
                                    </div>
                                </div>
                            </div>

                            <InputSelect v-model="delivery.serviceTypeId"
                                         :errors="deliveryErrors.serviceTypeId"
                                         :readonly="isCancel || isComplete"
                                         :options="listServiceType"
                                         label="Dịch vụ"
                            ></InputSelect>
                        </div>
                        <div class="col-lg-4">
                            <InputSelect v-model="delivery.transporterId"
                                         :errors="deliveryErrors.transporterId"
                                         :readonly="isCancel || isComplete"
                                         :options="listTransporter"
                                         label="Người giao"
                            ></InputSelect>

                            <InputSwitchButton v-model="delivery.usingPriceCod"
                                               label="Thu hộ tiền (COD)"
                                               :readonly="isCancel || isComplete"
                                               :detail="n(delivery.priceCodPayment)"
                            ></InputSwitchButton>

                            <InputDatePicker v-model="delivery.deliveryTime"
                                             :readonly="isCancel || isComplete"
                                             :errors="deliveryErrors.deliveryTime"
                                             :timepicker="true"
                                             :label="$t('message.orderFromTab.delivery_time')"
                            ></InputDatePicker>

                            <InputTextArea v-model="delivery.note"
                                           :errors="deliveryErrors.note"
                                           :readonly="isCancel || isComplete"
                                           :label="$t('message.ProductIndex.note')"
                                           height="212px"
                            ></InputTextArea>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-lg-12 mt-4 mb-4">
                    <table class="table table-index table-striped">
                        <thead>
                        <tr>
                            <th>{{$t('message.DetailOrdersCard.prodCode')}}</th>
                            <th>{{$t('message.DetailOrdersCard.prodName')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodUnit')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodQuantity')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodPrice')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.prodDiscount')}}</th>
                            <th class="text-center">{{$t('message.ProductIndex.price')}}</th>
                            <th class="text-center">{{$t('message.DetailOrdersCard.totalPrice')}}</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="item in invoiceItems" :key="item.productCode">
                            <td v-text="item.productCode"></td>
                            <td>
                                <span v-text="item.productName" class="font-weight-medium"></span>
                                <span v-if="getDiscountPrice(item)" class="discount-icon"></span>

                                <template v-if="item.product_batches && item.product_batches.length">
                                    <div class="product-batch-selected" v-for="batch in item.product_batches" :key="batch.id">
                                        <span v-text="batch.name + ' - ' + d(batch.expireDate) + ' - SL: ' + abs(batch.quantity) + ' - Tồn: ' + batch.quantityOrigin"></span>
                                    </div>
                                </template>
                            </td>
                            <td class="text-center" v-text="item.product_unit ? item.product_unit.unitName : ''"></td>
                            <td class="text-center" v-text="item.quantity"></td>
                            <td class="text-center" v-text="n(item.price)"></td>
                            <td class="text-center" v-text="n(getDiscountPrice(item))"></td>
                            <td class="text-center"
                                v-text="n(parseInt(item.subTotal))"></td>
                            <td class="text-center"
                                v-text="n(parseInt(item.subTotal) * parseInt(item.quantity))"></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="row justify-content-between">
                <div class="col-lg-5">
                    <div class="discount-content">
                        <h5>Chương trình khuyến mãi</h5>
                        <div class="pl-2">
                            Nội dung chương trình khuyến mãi
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-xl-3 order-bill">
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderSupplierIndex.total_quantity')}}</span>
                        <span v-text="calcTotalQuantity"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderSupplierIndex.total_origin')}}</span>
<!--                        <span v-text="n(calcTotalAmount)"></span>-->
                        <span v-text="n(entry.totalOrigin)"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderIndex.discount')}}</span>
                        <span v-text="n(getDiscountPrice(entry))"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>{{$t('message.OrderIndex.total')}}</span>
                        <span v-text="n(entry.total)"></span>
                    </div>
                    <div class="order-bill__item">
                        <span>Khách đã trả</span>
                        <span v-text="n(entry.totalPayment)"></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="action-bar">
            <div class="action-bar__container">
                <div class="action-bar__button">
                    <router-link to="/invoices/index" class="btn btn-warning">
                        <i class="fa fa-arrow-left" aria-hidden="true"></i>
                        Quay lại
                    </router-link>

                    <button v-if="!isCancel" class="btn btn-save" type="button" @click="update()">
                        <i class="fa fa-share"></i>
                        Cập nhật
                    </button>
                    <button v-if="!isCancel" class="btn btn-save" type="button" @click="save()">
                        <i class="fa fa-save"/>
                        Lưu lại
                    </button>
                    <button v-if="!isCancel" class="btn btn-save" type="button" @click="onReturn()">
                        <i class="fa fa-reply-all"></i>
                        Trả hàng
                    </button>
                    <button class="btn btn-save" type="button">
                        <i class="fa fa-print"/>
                        In phiếu
                    </button>
                    <button class="btn btn-save" type="button">
                        <i class="fas fa-file-export"></i>
                        Xuất file
                    </button>
                    <button v-if="!isCancel" class="btn btn-danger" type="button" @click="cancel()">
                        <i class="fa fa-times" aria-hidden="true"></i>
                        Hủy bỏ
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {$post, $alert, scrollToElement} from "@/lib/utils";
    import InputDatePicker from "../../../components/forms/InputDatePicker";
    import InputSelect from "../../../components/forms/InputSelect";
    import InputTextArea from "../../../components/forms/InputTextArea";
    import InputText from "../../../components/forms/InputText";
    import {DISCOUNT_TYPE_PERCENT, isEmpty} from "../../../lib/utils";
    import InputLocationPicker from "../../../components/forms/InputLocationPicker";
    import InputSwitchButton from "../../../components/forms/InputSwitchButton";
    import store from "../../../store";

    export default {
        name: "InvoiceTabInvoiceInfo",
        components: {InputSwitchButton, InputLocationPicker, InputDatePicker, InputSelect, InputTextArea, InputText},
        data() {
            return {
                entry: {},
                invoiceItems: [],
                saleChannels: store.state.GlobalConfig.saleChannels,
                branchAdmins: store.state.GlobalConfig.branchAdmins,
                listStatusPack: [],
                errors: {},

                delivery: {},
                receiverLocation: {
                    provinceId: 0,
                    districtId: 0,
                    wardId: 0
                },
                deliveryErrors: {},
                listWeightUnit: store.state.GlobalConfig.listWeightUnit,
                listServiceType: store.state.GlobalConfig.listServiceType,
                listTransporter: store.state.GlobalConfig.listTransporter,
                listDeliveryStatus: [],

                isDataLoaded: false,
            }
        },
        computed: {
            calcTotalQuantity() {
                let quantity = 0;

                this.invoiceItems.forEach((item) => {
                    quantity += parseInt(item.quantity);
                });

                return quantity;
            },
            calcTotalAmount() {
                let amount = 0;

                this.invoiceItems.forEach((item) => {
                    amount += Math.max(parseInt(item.quantity) * parseInt(item.subTotal), 0);
                });

                return amount;
            },
            isComplete() {
                return parseInt(this.entry.status) === 1;
            },
            isCancel() {
                return parseInt(this.entry.status) === 2;
            }
        },
        methods: {
            async save() {
                this.errors = {};

                if (!isEmpty(this.delivery)) {
                    this.delivery.receiverProvinceId = this.receiverLocation.provinceId;
                    this.delivery.receiverDistrictId = this.receiverLocation.districtId;
                    this.delivery.receiverWardId = this.receiverLocation.wardId;
                }

                const res = await $post('/v1/invoices/save', {
                    entry: this.entry,
                    delivery: this.delivery,
                });

                if (res.code === -1) {
                    this.errors = res.errors;
                    scrollToElement('.error-label')
                }

                if (res.redirect) {
                    location.replace(res.redirect);
                }

                $alert(res);
            },
            update() {
                window.open('/sale?updateInvoice=' + this.entry.code, '_blank');
            },
            onReturn() {
                window.open('/sale?returnInvoice=' + this.entry.code, '_blank');
            },
            async cancel() {
                if (!window.confirm('Bạn có chắc chắn muốn hủy hóa đơn ' + this.entry.code + ' này không?')) {
                    return false;
                }

                const res = await $post('/v1/invoices/saveStatus', {
                    id: this.entry.id,
                    action: 'cancel'
                });

                $alert(res);

                if (res.code === 200) {
                    window.location.reload();
                }
            },
            getDiscountPrice(entry) {
                let discountPrice = 0;
                if (entry.discountType == DISCOUNT_TYPE_PERCENT) {
                    discountPrice = Math.ceil(entry.totalOrigin * entry.discountValue / 100);
                } else {
                    discountPrice = entry.discountValue;
                }

                return discountPrice;
            }
        }
    }
</script>

<style scoped lang="scss">
@import "../../../assets/css/vars.scss";
    .product-batch-selected  {
        margin-bottom: 7px;
        margin-top: 3px;

        &>span {
            background-color: $base-color;
            color: white;
            font-weight: 600;

            padding: 1px 6px;
            display: inline-block;
            border-radius: 3px;
        }
    }
</style>
