<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document" style="width: 800px;">
            <div class="modal-content">
                <div class="modal-header py-1">
                    <h5 style="fotn" class="modal-title py-2">{{ $t('message.SupplierGroupIndex.titleCreate') }}</h5>
                    <button type="button" class="close" @click="closeModal">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            
                <div class="modal-body py-0 px-0">
                    <div class="tab-content" id="myTabContent">
                        <div class="tab-pane fade show active" id="homeInfoCustomer" role="tabpanel" aria-labelledby="home-tab">
                            <div class="row mx-4 my-4 mt-1">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.SupplierGroupIndex.name') }}<label class="text-danger px-0">*</label> </b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <input type="text" maxlength="100" v-model="form.name"  :placeholder="$t('message.SupplierGroupIndex.enterName')" class="form-control">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-2">
                                            <b>{{ $t('message.SupplierGroupIndex.note') }}</b>
                                        </div>
                                        <div class="col-10">
                                            <div class="form-group">
                                                <textarea class="form-control" name="" id="" v-model="form.note" cols="30" rows="7" :placeholder="$t('message.SupplierGroupIndex.note')"></textarea>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-5 mx-4 my-4">
                                <div class="col-lg-12 flex-end">
                                    <AppPermission :permission="[this.$permissions.supplierGroups.update ?? '', this.$permissions.supplierGroups.create ?? '']">                                        
                                        <button type="button" class="btn btn-save mr-2" @click="submitForm" :disabled="requestData">
                                            <i class="fa fa-save mr-1"></i>
                                            {{$t('message.button.save')}}
                                        </button>
                                    </AppPermission>
                                    <button type="button" class="btn btn-secondary text-white  mr-2"
                                        @click="closeModal">
                                        <i class="fa fa-ban mr-1"></i>
                                        {{$t('message.button.skip')}}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="hide()" :content="this.contentConfirm" :title="this.titleConfirm"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import {$post, $alert, scrollToElement, clone, isChangeData} from "@/lib/utils";
import toastr from 'toastr';
import ModalConfirm from "@/components/ModalConfirm.vue";
import AppPermission from '../AppPermission.vue';
export default {
    name: "ModalAddSuppliersGroup",
    components: { InputText, ModalConfirm, AppPermission },
    props: {
        openModal: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            active: 1,
            form: {
                'name': '',
                'note': '',
            },
            errors: {},
            default: {},
            contentConfirm: '',
            titleConfirm: '',
            requestData: false,
        }
    },
    methods: {
        show () {
            this.default = {...this.form};
            window.jQuery(this.$refs.modal).modal('show');
        },
        hide(){
            this.clearData();
            window.jQuery(this.$refs.modal).modal('hide');
        },
        async submitForm () {
            this.requestData = true;
            this.errors = {}
            const res = await $post('/v1/supplier-groups/save',{entry:this.form});

            if (res.code != 200) {
                this.requestData = false;
                this.errors = res.errors;
                scrollToElement('.error-label')
                $alert(res);
            }
            
            if(res.code == 200){
                $alert(res);
                this.$emit("updated", res);
                this.$emit('inputData','1');
                this.hide();
            }
        },
        closeModal(){
            const isChange = isChangeData(this.form, this.default);
            if(isChange == true){
                this.hide();
            }else{
                this.titleConfirm = this.$t('message.SupplierGroupIndex.confirm');
                this.contentConfirm = this.$t('message.SupplierGroupIndex.confirmCancelChange');
                this.$refs.ModalConfirm.show();
            }
            
        },
        clearData(){
            this.form.name = '';
            this.form.note = '';
            this.requestData = false;
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: auto !important;
    }
}

.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
</style>