<template>
    <div ref="modal" class="modal mx-0 fade" tabindex="-1" role="dialog" data-backdrop="static">        
        <div class="modal-dialog modal-xl" role="document" style="min-width: 70%;">
            <div class="modal-content">
                <div class="modal-header py-1" style="border-bottom:1px solid #ccc ;">
                    <h5 style="fotn" class="modal-title py-2">
                        {{$t("message.LocationIndex.titleUpdateLocation")}}
                    </h5>
                    <button type="button" class="close" aria-label="Close" @click="hide">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body py-0 px-3">
                  
                    <!-- Form nhập -->
                    
                    <div class="row">
                        <div class="col-lg-5" >
                            <div class="row mx-4 " style="">
                                <div class="col-xs-1"> <i class="fa fa-info-circle fa-lg" aria-hidden="true"></i></div>
                                <div class="col-xs-5 titleDiv"> 
                                    <h6 class="px-2 ">
                                        <b>{{$t("message.LocationIndex.info")}}</b>
                                    </h6> 
                                </div>                        
                            </div>
                            <div class="mx-4 pr-4 py-2 my-1" style="background:#f2f2f2; width:100%">
                                    <!-- Chi nhánh -->
                                    <div class="row w-100 mx-4 mt-4" >
                                        <div class="col-md-3 devTitle pl-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.branch")}}: </span>
                                            <span class="text-danger"><b> *</b></span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <input type="text" v-model="this.branchName" class="form-control inputDisabled" disabled>
                                        </div>                                
                                    </div>
                                    <!-- Danh mục -->
                                    <div class="row w-100 mx-4 my-3" >
                                        <div class="col-md-3 devTitle pl-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.categories")}}: </span>
                                            <span class="text-danger"><b> *</b></span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <input type="text" v-model="this.locationName" class="form-control inputDisabled" @click="showModalSelectLocations" disabled>
                                        </div>                                
                                    </div>    
                                    <!-- Tên vị trí -->
                                    <div class="row w-100 mx-4" >
                                        <div class="col-md-3 devTitle pl-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.name")}}: </span>
                                            <span class="text-danger"><b> *</b></span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <InputText v-model="this.entry.name" :placeholder="$t('message.LocationIndex.enterLocationName')" v-on:change="getIsEmptyData('name', this.entry.name, index)"                                            > 
                                            </InputText>                                            
                                        </div>                                
                                    </div>   

                                    <!-- Mã vị trí -->
                                    <div class="row w-100 mx-4 my-1" >
                                        <div class="col-md-3 devTitle pl-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.code")}}: </span>
                                            <span class="text-danger"><b> *</b></span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <InputText v-model="this.entry.code" :placeholder="$t('message.LocationIndex.enterLocationCode')"  v-on:change="getQRCode(this.entry.code, index)" > </InputText>                                            
                                        </div>                                
                                    </div>                         
                                    
                                    <!-- Mã vạch -->
                                    <div class="row w-100 mx-4" >
                                        <div class="col-md-3 devTitle pl-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.barcode")}}: </span>
                                            <span class="text-danger"><b> *</b></span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <InputText v-model="this.entry.qrCode" :placeholder="$t('message.LocationIndex.enterBarcode')"  v-on:change="qrCodeChangeData" > </InputText>
                                        </div>                                
                                    </div>   
                                
                                    <!-- Số lượng sản phẩm tối đa -->
                                    <div class="row w-100 mx-4" >
                                        <div class="col-md-3 devTitle pl-0 pr-0" >
                                            <span class="spanTitle">{{$t("message.LocationIndex.maxTotalQuantity")}}: </span>
                                        </div>
                                        <div class="col-md-9 mr-0">
                                            <!-- <InputNumber :min="0" v-model="this.entry.maxQuantityProducts"  v-on:change="numberChangeData" placeholder="Số lượng SP tối đa" @input="updateMaxQuantityProducts" > </InputNumber> -->
                                            <InputNumberFormat v-model="this.entry.maxQuantityProducts" 
                                            v-on:change="numberChangeData" :placeholder="$t('message.LocationIndex.enterMaxQuantity')"
                                            />
                                                
                                        </div>                                
                                    </div>  
                            </div> 
                        </div>
                        <!-- Bảng dữ liệu -->
                        <div class="col-lg-7"  >
                            <div class="row mx-1 titleDiv">
                                <div class="col-xs-1"> <i class="fa fa-list fa-lg" aria-hidden="true"></i></div>
                                <div class="col-xs-5 "> <h6 class="px-2 "><b>{{$t("message.LocationIndex.locationInFolder")}}</b></h6> </div>                        
                            </div>
                            <div class="row my-1">
                                <div class="col-12" style="height:500px" id="autoRows">
                                    <table class="table table-index table-striped table-index">
                                        <thead class="text-center sticky-top">
                                            <tr>
                                                <td>  {{$t("message.LocationIndex.barcode")}}  </td>
                                                <td>  {{$t("message.LocationIndex.name")}}  </td>
                                                <td>  {{$t("message.LocationIndex.code")}}  </td>
                                                <td>  {{$t("message.LocationIndex.totalProducts")}}  </td>
                                                <td>  {{$t("message.LocationIndex.totalQuantity")}}  </td>
                                                <td>  {{$t("message.LocationIndex.maxTotalQuantity")}}  </td>
                                            </tr>
                                        </thead>
                                        <tbody class="text-center">
                                            <template v-for="entry in this.entries" :key="entry.id">
                                                <tr style="height:35px;">
                                                    <td class="text-left">{{entry.qrCode}}  </td>
                                                    <td class="text-left">{{entry.name}}  </td>
                                                    <td class="text-left">{{entry.code}}  </td>
                                                    <td>{{entry.totalProducts}}  </td>
                                                    <td>{{entry.totalQuantityProducts}}  </td>
                                                    <td>{{entry.maxQuantityProducts}}  </td>
                                                </tr>    
                                            </template>
                                            <tr style="height:35px; border-bottom:1px solid #ccc">
                                                <td colspan="3" class="text-right">
                                                    <b>{{$t("message.LocationIndex.total")}}</b>
                                                </td>                                                
                                                <td><b>{{this.totalProducts}}</b></td>
                                                <td><b>{{this.totalQuantityProducts}}</b></td>
                                                <td></td>
                                            </tr>  
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <Pagination :value="paginate" :record="this.numberRecord" @change="pageChange"/>         
                        </div>
                    </div>
                    <!-- Các button xử lý -->
                    <div class="row mx-0 px-0 my-4" >
                        <div class="col-lg-12 text-right px-0 mx-0">
                            <button type="button" class="btn btn-save px-3 mx-2" @click="save">
                                <i class="fa fa-save mr-1"></i>
                                {{$t("message.button.save")}}
                            </button>
                            <button type="button" class="btn btn-secondary text-white"
                                @click="hide"> <i class="fa fa-ban mr-1"></i>
                                {{$t("message.button.cancel")}}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <ModalConfirm ref="ModalConfirm" @confirm="confirm"  :title="$t('message.common.confirm')" :content="$t('message.LocationIndex.confirmCancelUpdateLocation')"/>
    <ModalSelectLocations ref="ModalSelectLocations" @updateLocationsName="getLocationName" @loadLocationId="loadLocationId"/>
</template>
<script>
import InputText from "@/components/forms/InputText";
import ModalConfirm from "@/components/ModalConfirm.vue";
import store from "@/store";
import SelectSearch from "@/components/SelectSearch";
import { $get, $post } from "@/lib/utils";
import ModalSelectLocations from './ModalSelectLocations';
import toastr from 'toastr'
import Pagination from '@/components/Pagination';
import InputNumberFormat from '@/components/forms/InputNumberFormat';
export default {
    name: "ModalEditLocationDeatils",
    components: { InputText, InputNumberFormat , ModalConfirm, SelectSearch, ModalSelectLocations,Pagination },
    props: {
       
    },
    watch:{
        
    },
    data () {
        return {
            isEdit: false,
            currentUser: store.state.Auth.user,
            isEmptyData: [],
            totalProducts:0,
            totalQuantityProducts:0,
            
            entries: [],
            entry:
                {                   
                    name: null,
                    code: null,
                    qrCode: null,
                    maxQuantityProducts:0,
                    locationId: null,
                },
            input: 4,
            branchId: store.state.CurrentBranch.id,
            branchName: store.state.CurrentBranch.name,
            locationId: {
                locationLv1Id:null,
                locationLv2Id:null,
                locationLv3Id:null,
                locationLv4Id:null,
            },
            locationName: null,
            locationCode: null,
            isStoreName: [],
            isStoreCode: [],
            renderQrCode: true,
            id: null,
            paginate: 
            {
                currentPage: 1,
                lastPage: 1
            },
            numberRecord: 15,
        }
    },
    mounted() {
        
    },
    created() {
        
    },
    
    methods: {
        updateMaxQuantityProducts(input){
            if(input) {
                this.entry.maxQuantityProducts = input;    
            }
            this.entry.maxQuantityProducts = input;
        },
        async pageChange(page) {                 
        const res = await $get("/v1/location-details/data", {page: page, record: this.numberRecord, locationId: this.locationId.locationLv4Id});
            if(res && res.status.code == 200 && res.result.data.length > 0){
                this.entries = res.result.data;  
                this.paginate = res.result.paginate;      
            }        
        },

        show(entry, name, code){
            this.resetData();
            this.clearData();        
            this.locationCode = code;
            this.locationName = name;
            this.id = entry.id;
            this.entry.name =  entry.name;
            this.entry.code = entry.code;            
            this.entry.qrCode = entry.qrCode;  
            this.entry.locationId = entry.locationId;  
            this.entry.maxQuantityProducts = entry.maxQuantityProducts       
            this.locationId.locationLv4Id = entry.locationId;
            this.load();
            window.jQuery(this.$refs.modal).modal('show');
            
        },
        confirm(){
            this.$emit('reload', this.id);
            this.isEdit = false;          
            window.jQuery(this.$refs.modal).modal('hide');

        },
        resetData(){
            this.totalProducts = 0;
            this.totalQuantityProducts = 0;
        },
        hide() {     
            this.resetData();        
            if(this.isEdit == true) {
                this.$refs.ModalConfirm.show();
            }
            else {
               this.confirm(); 
            }
        },
        showModalSelectLocations(){
             this.$refs.ModalSelectLocations.show();
        },
        checkExistLocationName(id, name){
            let status  = false;
            this.entries.forEach(item => {
                if(item.name == name && item.id !== id) status = true;
            });                        
            return status;
        },
        checkExistLocationCode(code){
            let status  = false;
            this.entries.forEach(item => {
                if(item.code == code && item.id !== this.id) status = true;
            });            
            return status;
        },
        clearData(type){           
            switch (type) {
                        case 'name':
                            this.entry.name = [];   
                            break;
                        case 'code':
                            this.entry.code = [];
                            this.entry.qrCode = [];
                            break;                        
            }
        },
       
        clearCode(isStoreCode,input){
            isStoreCode.forEach((item, index) => {
                if(item == input){
                    this.isStoreCode.splice(index, 1);
                }
            });            
        },
        checkDataInputExist(type, input, inds){              
            let status = false;
            switch (type) {
                case 'name':
                    this.isStoreName.forEach((item, index) => {
                        if(index != inds && item == input) {
                            status = true;
                        }
                    });
                    break;
                case 'code':
                   
                    this.isStoreCode.forEach((item, index) => {
                       if(index != inds && item == input) {
                            status = true;
                        }
                    });
                    break;
            }

           
            return status;
        },
        updateDataInputChange(type, input, inds){     
            
            switch (type) {
                case 'name':                   
                    if(this.isStoreName.length == 0) this.isStoreName.push(input);
                    else {
                        this.isStoreName.splice(inds, 1);
                        this.isStoreName.splice(inds, 1, input );                       
                    }

                    break;
                case 'code':
                    if(this.isStoreCode.length == 0) this.isStoreCode.push(input);
                    else {
                        this.isStoreCode.splice(inds, 1);
                        this.isStoreCode.splice(inds, 1, input );                       
                    }
                    break;
            }
        },
        qrCodeChangeData(){            
            this.isEdit = true;
        },
        numberChangeData(){
            this.isEdit = true;            
        },
        getIsEmptyData(type, input, inds){
            this.isEdit = true;
           // Check id truoc push vao bien this.isEmptyData
            if(this.isEmptyData.includes(inds) == false) this.isEmptyData.push(inds);

            switch (type) {
                case 'name':
                    if(input !== null && input !== '') {                        
                        // Kiểm tra trùng tên trong DB
                        let statusExist = this.checkExistLocationName(this.id, input);
                        if(statusExist == true) {
                            toastr.error(this.$t('message.LocationIndex.existLocationName'));
                            this.clearData('name');
                            return;
                        }                        
                    }
                 
                    break;
                case 'code':
                    if(input) {                          
                        // Kiểm tra tồn tại trong DB
                        let statusExist = this.checkExistLocationCode(input);                                
                        if(statusExist == true) {
                            toastr.error(this.$t('message.LocationIndex.existLocationCode'));
                            this.clearData('code');
                            this.renderQrCode = false;
                            return;
                        }
                        else {
                            this.renderQrCode = true;
                        }
                    }
                    break;                       
            }
        },
        getQRCode(code, inds){
            this.isEdit = true;
            this.getIsEmptyData('code', code ,inds);            
            if(this.renderQrCode == true) {
                this.entry.qrCode = code? this.locationCode + '-' + code : this.locationCode;
                this.entry.locationId = this.locationId.locationLv4Id;
                // this.entry.totalQuantityProducts = 0;
                // this.entry.totalProducts = 0;
                this.entry.created_by = this.currentUser.id;
            }
            
            // Tự động get QRcode
            
          
        },
        async load(){
            const res = await $get("/v1/location-details/data", {locationId: this.locationId.locationLv4Id});
            if(res && res.status.code == 200 && res.result.data.length > 0){
                this.entries = res.result.data;
                this.entries.forEach(item => {
                    this.totalProducts = this.totalProducts + item.totalProducts;
                    this.totalQuantityProducts = this.totalQuantityProducts + item.totalQuantityProducts;
                    this.paginate = res.result.paginate;    
                });                
            }
            else {
               this.entries = [];
            }

        },
        getLocationName(input){           
            this.locationName = (input.nameLv1? input.nameLv1 : '' ) + (input.nameLv2?  ' > ' + input.nameLv2 : '' ) + (input.nameLv3? ' > ' + input.nameLv3 : '' ) + (input.nameLv4? ' > ' +  input.nameLv4: '' );
        },
        loadLocationId(id){
            this.locationId.locationLv4Id = id;
            this.load();
        },
        checkFieldEmpty(){
            if(this.isEmptyData.length == 0){
                toastr.error(this.$t('message.LocationIndex.emptyData'));
                return;
            }            
            this.isEmptyData.forEach(value => {
                this.entry.forEach((item, index) => {
                    if(index == value && item.name == '') {
                        toastr.error(this.$t('message.LocationIndex.emptyLocationName'));
                        return;
                    }
                    if(index == value && item.code == '') {
                        toastr.error(this.$t('message.LocationIndex.emptyLocationCode'));
                        return;
                    }                    
                });    
            });
        },
        getDataToInsert(){
            let data = [];
            this.isEmptyData.forEach(value => {
                this.entry.forEach((item, index) => {
                    if(index == value) {
                        data.push(item);
                    }
                    
                });    
            });            
            return data;
        },
        async save(){       
            
            if(this.entry.name == null || this.entry.name == '') {
                toastr.error(this.$t('message.LocationIndex.emptyLocationName'));
                return;
            }
            if(this.entry.code == null || this.entry.code == '') {
                toastr.error(this.$t('message.LocationIndex.emptyLocationCode'));
                return;
            }
            if(this.entry.qrCode == null || this.entry.qrCode == '') {
                toastr.error(this.$t('message.LocationIndex.emptyLocationBarCode'));
                return;
            }             
            const res = await $post("/v1/location-details/update", {entry: this.entry, id: this.id});            
            if(res && res.status.code == 200 && res.result){       
                if(!res.result.status && res.result.message){
                    toastr.error(res.result.message);
                }else{
                    toastr.success(this.$t('message.LocationIndex.alertUpdateSuccess'));
                    this.isEdit = false;               
                    this.hide();
                }         
            }
            else {
               toastr.error(this.$t('message.LocationIndex.alertUpdateError'));                
            }
        },
        addRow(){
            let newObject =  {
                    name: [],
                    code: [],
                    qrCode: [],
                    maxQuantityProducts: 0,
                }
            this.entry.push(newObject);            
        }
    }
}
</script>
<style scoped>
#modalChecked {
    overflow-x: hidden;
    overflow-y: auto;
}

#modalTableChecked thead th {
    border-bottom: 0 !important;
}

@media (max-width: 1280px) {
    .modal-xl {
        width: 95% !important;
    }
}
.table tbody tr {
    height: 35px !important;
}
.table thead th #modalChecked .modal-header {
    border-bottom: 0 !important;
}
.modal-header {
    border-bottom: none !important;
}
#leftNote {
 color:gray;
 font-style: italic;
}
.h1c1 {
    border: 0;
    border-bottom: 1px solid #ddd;    
}
.inputDisabled{
    background-color:#eee;
    cursor: not-allowed;
}

.devTitle {
    display: flex;
    align-items: center;
    height: 35px;
    
}
.spanTitle {
    font-weight: 700;
}
.titleDiv{
    max-width:max-content !important;
    border-bottom: 1px solid green !important;
    min-height: 5px;
}
</style>